import { Stack, Text } from '@fluentui/react';
import { useEffect } from 'react';
import { groupBy, map } from 'lodash';

import { addPatientAuditLoggingasList } from 'state/slices/patient/audit-log/auditLog.selectors';

import { format } from 'date-fns';

import useUserIdentities from 'hooks/store/useUserIdentities';

import { changeValueFormatFieldName } from 'state/slices/patient/audit-log/auditLog.reducers';
import { IAuditLog } from 'api/models/patient.model';
import { genderIdentitiesList } from 'state/slices/tenant/gender-identities.slice';
import { languagesData } from 'state/slices/tenant/language.slice';
import { sexualOrientationList } from 'state/slices/tenant/sexualOrientation.slice';
import formatPhoneNumber from 'utils/formatPhoneNumber';
import { usdCurrencyFormatter } from 'utils';
import { classicDateOnly } from 'utils/dateOnly';
import { useSelector } from 'hooks';

const fieldsToRemove = [
    'Signature',
    'Signature.SignatureSourceId',
    'Uds',
    'PhysicalAddress',
    'MailingAddress',
    'PhoneNumbers',
    'PhoneNumbers.IsBadNumber1',
    'PhoneNumbers.IsDeleted1',
    'PhoneNumbers.IsBadNumber2',
    'PhoneNumbers.IsDeleted2',
    'PhoneNumbers.IsBadNumber3',
    'PhoneNumbers.IsDeleted3',
];

function Logs(): JSX.Element {
    const auditLog = useSelector(addPatientAuditLoggingasList);

    const { getMissingUserIdentities, userIdentitiesData } = useUserIdentities();
    const auditLogReading = auditLog
        .filter((item) => !fieldsToRemove.includes(item.fieldName as string))
        .filter((item) => item.value !== '' || item.commandType !== 'Insert');

    useEffect(() => {
        if (auditLogReading?.length) {
            const modifiedAuditLog = auditLogReading.map((item) => item.modifiedBy) as string[];
            getMissingUserIdentities(modifiedAuditLog);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auditLogReading?.length]);

    const groupAuditLogDate = map(
        groupBy(auditLogReading, (item) => {
            const nameToUse = item.modifiedBy ? `${userIdentitiesData[item.modifiedBy]?.displayName}` : '';
            if (item.modifiedBy && item.modifiedOn) {
                return ` ${nameToUse} - ${format(new Date(item.modifiedOn), 'MM/dd/yyyy h:mm a')}`;
            }
        }),
        (entry, key) => ({
            heading: key,
            fields: entry,
        }),
    );

    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <Stack tokens={{ childrenGap: 5 }}>
                {groupAuditLogDate.map((audit, i) => {
                    const auditHeading = () => {
                        return (
                            <Stack>
                                <Text variant="large">{audit.heading}</Text>{' '}
                            </Stack>
                        );
                    };

                    return (
                        <Stack key={i} tokens={{ childrenGap: 5 }}>
                            {auditHeading()}
                            <Stack tokens={{ childrenGap: 5 }}>
                                {audit.fields.map((item, b) => (
                                    <AuditField key={b} {...item} />
                                ))}
                            </Stack>
                        </Stack>
                    );
                })}
            </Stack>
        </Stack>
    );
}

const changeCommandType = (item: IAuditLog) => {
    if (item.commandType === 'Update' && item.value === '') {
        return 'Deleted';
    }
    if (item.commandType === 'Update') {
        return 'Changed';
    } else return 'Created with';
};

function AuditField(field: IAuditLog): JSX.Element {
    const sexualOrientation = useSelector(sexualOrientationList);
    const genderIdentity = useSelector(genderIdentitiesList);
    const languages = useSelector(languagesData);

    const changeValueFormat = (audit: IAuditLog) => {
        const { fieldName, value } = audit;

        if (!value) return value;

        switch (fieldName) {
            case 'DateOfBirth': {
                return classicDateOnly(value);
            }
            case 'Signature.PrivacyNoticeSignedDate': {
                return classicDateOnly(value);
            }
            case 'BillingReleaseSignedDate': {
                return classicDateOnly(value);
            }
            case 'Signature.BenefitAssignmentSignedDate': {
                return classicDateOnly(value);
            }
            case 'PhoneNumbers.Number1': {
                return formatPhoneNumber(value);
            }
            case 'PhoneNumbers.Number2': {
                return formatPhoneNumber(value);
            }
            case 'PhoneNumbers.Number3': {
                return formatPhoneNumber(value);
            }
            case 'Uds.Income': {
                return usdCurrencyFormatter.format(parseInt(value));
            }
            case 'Uds.IncomePercentage': {
                return `${value}%`;
            }
            case 'PreferredLanguage': {
                const language = languages.find((language) => language.code === value);
                return language?.displayName ? language.displayName : value;
            }
            case 'GenderIdentityId': {
                const gender = genderIdentity.find((gi) => gi?.id === value);
                return gender?.displayName ? gender.displayName : value;
            }
            case 'SexualOrientationId': {
                const orientation = sexualOrientation.find((so) => so.id === value);
                return orientation?.displayName ? orientation.displayName : value;
            }
            default: {
                return value;
            }
        }
    };

    return (
        <Stack tokens={{ childrenGap: 5 }}>
            <Stack horizontal tokens={{ childrenGap: 5 }}>
                <li>
                    <Text>
                        {changeCommandType(field)}
                        <strong> {field.fieldName ? changeValueFormatFieldName[field.fieldName] : field.fieldName} </strong>{' '}
                        {field.commandType === 'Update' && field.value === '' ? '' : 'set to '}
                        {changeValueFormat(field)}
                    </Text>
                </li>
            </Stack>
        </Stack>
    );
}

export default Logs;
