import { ScrollablePane, ScrollbarVisibility, Stack } from '@fluentui/react';
import { ActivityPage, ContentPane } from 'components';
import { useChartDentitions } from 'hooks';
import usePermissions, { DentalPermissions } from 'hooks/store/usePermissions';
import { PatientDetailsBanner, AccessDenied } from 'pages/components';
import { useEffect } from 'react';
import PerioHistory from './components/PerioHistory';
import PerioToolbar from './components/PerioToolbar';

function Perio(): JSX.Element {
    const { getChartDentitions } = useChartDentitions();
    const [hasPerio] = usePermissions([DentalPermissions.Perio]);

    useEffect(() => {
        getChartDentitions();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ActivityPage disableOverflow title="Perio">
            <PatientDetailsBanner />
            {hasPerio ? (
                <Stack style={{ padding: 10, flex: 1 }} tokens={{ childrenGap: 10 }}>
                    <PerioToolbar />
                    <ContentPane>
                        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                            <Stack tokens={{ childrenGap: 12 }}>
                                <PerioHistory />
                            </Stack>
                            {/* {report && <Report {...report} />} */}
                        </ScrollablePane>
                    </ContentPane>
                </Stack>
            ) : (
                <AccessDenied />
            )}
        </ActivityPage>
    );
}

export default Perio;
