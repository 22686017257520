import { createSlice, Dictionary, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus, LoadingStatuses } from 'interfaces/loading-statuses';
import IPatientPrescription from 'api/models/patient-prescription';
import {
    addCurrentPatientPrescription,
    getAllPatientPrescriptions,
    searchMedicationsPrescriptionPanel,
} from './prescriptions-panel.actions';
import IPrescriptionItem from 'api/models/prescription.model';
import { IDiagnosis } from 'api/models/diagnosis.model';

export type PrescriptionsPanelState = {
    data: IPatientPrescription[];
    prescriptionResults: Dictionary<IPrescriptionItem>;
    prescriptionSearch: string;
    loading: LoadingStatuses;
    saving: LoadingStatuses;
    searchingPrescriptionItems: LoadingStatus;
    selectedPatientPrescription?: IPatientPrescription;
    isOpen: boolean;
    error?: any;
};

const initialState: PrescriptionsPanelState = {
    data: [],
    prescriptionResults: {},
    prescriptionSearch: '',
    isOpen: false,
    loading: LoadingStatus.Idle,
    saving: LoadingStatus.Idle,
    searchingPrescriptionItems: LoadingStatus.Idle,
};

const prescriptionsPanel = createSlice({
    name: 'presciptions-panel',
    initialState,
    reducers: {
        setMedicationSearch: (state: PrescriptionsPanelState, action: PayloadAction<string>) => {
            state.prescriptionSearch = action.payload;
        },
        cleanupPrescriptionsPanelState: (state: PrescriptionsPanelState) => {
            state.data = [];
            state.prescriptionResults = {};
            state.prescriptionSearch = '';
            state.selectedPatientPrescription = undefined;
        },
        cleanupPrescriptionsMedicationsSearch: (state) => {
            state.prescriptionResults = {};
            state.prescriptionSearch = '';
        },
        setPrescriptionsPanelOpen: (state: PrescriptionsPanelState, action: PayloadAction<boolean>) => {
            state.isOpen = action.payload;
        },
        setCurrentPrescription: (state: PrescriptionsPanelState, action: PayloadAction<IPatientPrescription>) => {
            //This preserves the selected dx when switching from one rx to another on the rx panel.
            const currentCode = state.selectedPatientPrescription?.diagnosisCode;
            const currentSnomed = state.selectedPatientPrescription?.snomedDiagnosisCode;

            state.selectedPatientPrescription = {
                ...action.payload,
                diagnosisCode: currentCode,
                snomedDiagnosisCode: currentSnomed,
            };
        },
        updateCurrentPrescription: (
            state: PrescriptionsPanelState,
            action: PayloadAction<{
                path: keyof IPatientPrescription;
                value: string | boolean | number | Dictionary<string> | undefined;
            }>,
        ) => {
            if (state.selectedPatientPrescription) {
                (state.selectedPatientPrescription[action.payload.path] as unknown) = action.payload.value;
            }
        },
        updateCurrentPrescriptionDiagnosis: (
            state: PrescriptionsPanelState,
            action: PayloadAction<IDiagnosis | undefined>,
        ): void => {
            const dx = action.payload;
            if (state.selectedPatientPrescription && dx?.snomedCode) {
                state.selectedPatientPrescription.diagnosisCode = dx.code;
                state.selectedPatientPrescription.snomedDiagnosisCode = +dx.snomedCode;
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllPatientPrescriptions.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(getAllPatientPrescriptions.fulfilled, (state, action) => {
                state.loading = LoadingStatus.Completed;
                state.data = action.payload;
            })
            .addCase(getAllPatientPrescriptions.rejected, (state, action) => {
                state.loading = LoadingStatus.Failed;
                state.error = action.payload;
            })
            .addCase(searchMedicationsPrescriptionPanel.pending, (state) => {
                state.searchingPrescriptionItems = LoadingStatus.Pending;
            })
            .addCase(searchMedicationsPrescriptionPanel.fulfilled, (state, action) => {
                state.searchingPrescriptionItems = LoadingStatus.Completed;
                state.prescriptionResults = action.payload;
            })
            .addCase(searchMedicationsPrescriptionPanel.rejected, (state, action) => {
                state.searchingPrescriptionItems = LoadingStatus.Failed;
                state.error = action.payload;
            })
            .addCase(addCurrentPatientPrescription.pending, (state) => {
                state.saving = LoadingStatus.Pending;
            })
            .addCase(addCurrentPatientPrescription.fulfilled, (state, action) => {
                state.saving = LoadingStatus.Completed;
            })
            .addCase(addCurrentPatientPrescription.rejected, (state, action) => {
                state.saving = LoadingStatus.Failed;
                state.error = action.payload;
            });
    },
});

const { reducer, actions } = prescriptionsPanel;

export const {
    setPrescriptionsPanelOpen,
    cleanupPrescriptionsPanelState,
    setMedicationSearch,
    cleanupPrescriptionsMedicationsSearch,
    setCurrentPrescription,
    updateCurrentPrescription,
    updateCurrentPrescriptionDiagnosis,
} = actions;

export default reducer;
