import { useCallback, useEffect } from 'react';

/**
 * useBeforeUnloadConfirmation
 * @description Shows a confirmation prompt to the user before the current window is unloaded.
 * @param {boolean} when Determines when the confirmation will show.
 * @param {string} confirmationText Determines when the confirmation will show.
 */
export default function useBeforeUnloadConfirmation(when: boolean, confirmationText: string) {
    const handleBeforeUnload = useCallback(
        (event: BeforeUnloadEvent) => {
            const e = event || window.event;
            const confirm = window.confirm(confirmationText);
            if (!confirm) {
                e.preventDefault();
                e.stopPropagation();
                //For legacy browsers
                e.returnValue = true;
            }
        },
        [confirmationText],
    );

    useEffect(() => {
        if (when) {
            addEventListener('beforeunload', handleBeforeUnload);
        } else {
            removeEventListener('beforeunload', handleBeforeUnload);
        }

        return () => {
            removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [when, handleBeforeUnload]);
}
