import Front from './Front';
import Tooth from './Tooth';
import * as PIXI from 'pixi.js-legacy';
import ToothOverlay from './ToothOverlay';

export default class AbcessPeriapical extends ToothOverlay {
    private circle = new PIXI.Graphics();
    private hollowCircle = new PIXI.Graphics();
    private front: Front;
    private _toothWidth: number;

    constructor(tooth: Tooth, front: Front) {
        super(tooth);
        this._toothWidth = tooth.getToothWidth;
        this.front = front;
        this.name = 'AbcessPeriapical';
        this._createGraphics();
    }

    private _createGraphics() {
        const hollowRadius = 8;
        const hollowDiameter = hollowRadius * 2;
        const lineThickness = 6;

        this.hollowCircle.lineStyle(lineThickness, 0x000000);
        this.hollowCircle.drawCircle(
            this.getIsBottomRow ? this._toothWidth / 2 - hollowRadius + 10 : this._toothWidth / 2 - hollowRadius,
            this.getIsBottomRow
                ? this.tooth.height + hollowDiameter * 2 + lineThickness * 2
                : this.front.y - this.front.height / 2 - hollowRadius,
            hollowRadius,
        );

        this.addChild(this.circle, this.hollowCircle);
    }
}
