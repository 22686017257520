import { Dropdown, Stack } from '@fluentui/react';
import dentalApi from 'api/dental.api';
import { EncounterStatus, IPatientEncounter } from 'api/models/encounter.model';
import { TModal } from 'components';
import { IActionButton } from 'components/ActionButton';
import { useSelector } from 'hooks';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { RouteParams } from 'interfaces/route-params';
import EncounterCorrespondenceNotes from 'pages/Landing/AdminHuddle/components/EncounterWorkLists/EncounterCorrespondenceNotes';
import {
    EncounterNoteModalHeader,
    lookupEditEncounterActionTextAndIcon,
} from 'pages/Landing/AdminHuddle/components/EncounterWorkLists/EncounterNoteModal';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    saveEncounterCorrespondenceAndSend,
    saveEncounterCorrespondenceEncounterWorkList,
} from 'state/slices/admin-huddle/worklists/encounter-worklists/encounter-worklists.actions';
import {
    selectEncounterWorkListNoteModalNote,
    selectEncounterWorkListNoteModalSaving,
} from 'state/slices/admin-huddle/worklists/encounter-worklists/encounter-worklists.selectors';
import { EncounterWorkListNoteModalAction } from 'state/slices/admin-huddle/worklists/encounter-worklists/encounter-worklists.state';
import {
    cleanupEncounterWorkListModal,
} from 'state/slices/admin-huddle/worklists/worklist.slice';
import {
    selectClaimHistoryEncounterNoteModalOpen,
    selectSelectedClaimHistoryEncounterSummary,
} from 'state/slices/ledger/claim-history/claim-history.selectors';
import { setClaimHistoryEncounterNoteModalOpen } from 'state/slices/ledger/ledger.slice';
import { selectSelectedPatient } from 'state/slices/patient/patient.selectors';

/**
 * Because this modal is not tied to a worklist, we need a new component that can handle loading the encounter.
 * This can probably be removed once the worklist encounter note modal is refactored.
 *
 * @export
 * @return {*}
 */
export default function ClaimHistoryEncounterNoteModal() {
    const dispatch = useDispatch();
    const { tenantId, patientId } = useParams<RouteParams>();

    const selectedEncounterSummary = useSelector(selectSelectedClaimHistoryEncounterSummary);
    const note = useSelector(selectEncounterWorkListNoteModalNote);
    const selectedPatient = useSelector(selectSelectedPatient);
    const savingEncounter = useSelector(selectEncounterWorkListNoteModalSaving);
    const isOpen = useSelector(selectClaimHistoryEncounterNoteModalOpen);

    const saving = savingEncounter === LoadingStatus.Pending;

    const [encounter, setEncounter] = useState<IPatientEncounter>();
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [action, setAction] = useState<EncounterWorkListNoteModalAction>();

    const getPatientEncounter = useCallback(async (tenantId: string, patientId: string, encounterId: string) => {
        setLoading(true);
        setErrorMessage(undefined);
        try {
            const res = await dentalApi.getPatientEncounterById(tenantId, patientId, encounterId);
            setEncounter(res.data);
        } catch (e) {
            setErrorMessage('Something went wrong fetching the patient encounter...');
        }
        setLoading(false);
    }, []);

    function onDismiss() {
        dispatch(setClaimHistoryEncounterNoteModalOpen(false));
    }

    useEffect(() => {
        if (savingEncounter === LoadingStatus.Completed || savingEncounter === LoadingStatus.Failed) {
            onDismiss();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [savingEncounter]);

    async function onSave() {
        try {
            if (encounter) {
                if (action) {
                    if (action !== 'Rebill') {
                        dispatch(
                            saveEncounterCorrespondenceAndSend({
                                patientId,
                                tenantId,
                                encounterStatus: action,
                                encounter,
                            }),
                        );
                    }
                } else {
                    dispatch(saveEncounterCorrespondenceEncounterWorkList({ tenantId, encounterId: encounter.id }));
                }
            }
        } catch (e) {
            setErrorMessage('Something went wrong while saving...');
        }
    }

    function onDismissed() {
        //Since we are using parts of state from encounter worklist, it makes sense to clean it up.
        dispatch(cleanupEncounterWorkListModal());
        setEncounter(undefined);
        setLoading(false);
        setErrorMessage(undefined);
        setAction(undefined);
    }

    useEffect(() => {
        if (isOpen && selectedEncounterSummary?.encounterId) {
            getPatientEncounter(tenantId, patientId, selectedEncounterSummary.encounterId);
        }
    }, [selectedEncounterSummary, isOpen, patientId, tenantId, getPatientEncounter]);

    const canSave = !loading && !!encounter && !errorMessage && !saving && !!note && note.length > 3;

    const mainButtonLabelAndIcon = action ? lookupEditEncounterActionTextAndIcon[action] : undefined;

    const mainButton: IActionButton = action
        ? {
            type: 'PrimaryButton',
            iconProps: { iconName: mainButtonLabelAndIcon?.iconName },
            text: saving ? 'Saving and Sending...' : mainButtonLabelAndIcon?.label,
            disabled: !canSave,
            onClick: onSave,
        }
        : {
            text: saving ? 'Saving...' : 'Save Note',
            iconProps: { iconName: 'Save' },
            disabled: !canSave,
            onClick: onSave,
        };

    return (
        <TModal
            isDraggable
            mainButtons={[
                mainButton,
                {
                    text: 'Cancel',
                    iconProps: { iconName: 'Cancel' },
                    onClick: onDismiss,
                },
            ]}
            title="Notes"
            modalProps={{ isOpen, onDismissed, onDismiss }}
        >
            <Stack styles={{ root: { padding: 10 } }}>
                <EncounterNoteModalHeader
                    patientName={selectedPatient ? `${selectedPatient.lastName}, ${selectedPatient?.firstName}` : 'N/A'}
                    encounterDate={selectedEncounterSummary?.dateOfService}
                    billingProviderId={selectedEncounterSummary?.provider}
                />

                <EncounterCorrespondenceNotes
                    encounterId={selectedEncounterSummary?.encounterId}
                    aboveNoteContent={
                        <Dropdown
                            options={[
                                {
                                    key: '',
                                    text: '(Select Destination/Action)',
                                },
                                {
                                    key: EncounterStatus.CorrectionAmend,
                                    text: 'Send for Provider Correction & Addendum',
                                },
                                {
                                    key: EncounterStatus.CorrectionsNeeded,
                                    text: 'Send for Non-Provider Correction & Reapproval',
                                },
                            ]}
                            onChange={(ev, option) => {
                                if (option?.key !== undefined) {
                                    setAction(option.key as EncounterWorkListNoteModalAction);
                                }
                            }}
                            disabled={saving}
                            selectedKey={action ?? ''}
                            placeholder="Select a Destination/Action"
                            label="Destination/Action"
                        />
                    }
                />
            </Stack>
        </TModal>
    );
}
