import {
    DetailsHeader,
    IColumn,
    IconButton,
    MessageBar,
    SelectionMode,
    Stack,
    Sticky,
    StickyPositionType,
    Text,
    TooltipHost,
} from '@fluentui/react';
import { IChartDentition } from 'api/models/chart-dentition.model';
import { SortableDetailsList } from 'components';
import { useChartDentitions } from 'hooks';
import useKeydown from 'hooks/useKeydown';
import { toothSpriteReferences } from 'pages/Charting/components/ToothCanvas/spriteList';
import { useEffect, useReducer } from 'react';
import movementsReducer, {
    movementsInitialState,
    populateTeethDentitions,
    rotateOcclusalTeeth,
    rotateTeeth,
    translateTeethX,
    translateTeethY,
} from './MovementsState';

type Props = {
    onChange: (dentitions: IChartDentition[]) => void;
    showTranslation?: boolean;
    showRotation?: boolean;
    selectedTeeth: number[];
};

export default function Movements({ showRotation = true, showTranslation = true, selectedTeeth, onChange }: Props) {
    const { dentitions } = useChartDentitions();
    const [state, dispatch] = useReducer(movementsReducer, movementsInitialState);

    useEffect(() => {
        const selectedDentitions = dentitions.filter(
            (d) => selectedTeeth.indexOf(+d.id) > -1 && state.findIndex((sD) => +sD.id !== +d.id) === -1,
        );
        dispatch(populateTeethDentitions(selectedDentitions));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTeeth.length, dentitions]);

    useEffect(() => {
        if (state && state.length) onChange(state);
        return () => {
            onChange([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    useKeydown('ArrowLeft', (e) => {
        e?.preventDefault();
        dispatch(translateTeethX('decrement', selectedTeeth));
    });
    useKeydown('ArrowRight', (e) => {
        e?.preventDefault();
        dispatch(translateTeethX('increment', selectedTeeth));
    });
    useKeydown('ArrowUp', (e) => {
        e?.preventDefault();
        dispatch(translateTeethY('increment', selectedTeeth));
    });
    useKeydown('ArrowDown', (e) => {
        e?.preventDefault();
        dispatch(translateTeethY('decrement', selectedTeeth));
    });

    function getRotationSurface(item: IChartDentition) {
        return item.facialDegree ? (item.facialDegree < 0 ? 'M' : 'F') : '';
    }

    function getXTranslationSurface(item: IChartDentition) {
        return item.xPosition ? (item.xPosition < 0 ? 'M' : 'F') : '';
    }

    const columns: IColumn[] = [
        {
            key: 'toothId',
            minWidth: 30,
            maxWidth: 30,
            name: 'Id',
            fieldName: 'id',
            onRender: (item: IChartDentition) => (
                <Text>{toothSpriteReferences.find((tooth) => tooth.id === +item.id)?.displayName}</Text>
            ),
        },
        {
            key: 'rotation',
            minWidth: 50,
            maxWidth: 95,
            name: 'Rotation',
            fieldName: 'rotation',
            onRender: (item: IChartDentition) => (
                <Text>
                    {item.facialDegree ? `${Math.abs(item.facialDegree)}°, ${getRotationSurface(item)}` : ''}
                    {item.occlusalDegree
                        ? `${item.facialDegree && item.occlusalDegree ? ' | ' : ''}${Math.abs(item.occlusalDegree)}°, O`
                        : ''}
                </Text>
            ),
        },
        {
            key: 'translationX',
            minWidth: 55,
            maxWidth: 60,
            name: 'X (mm)',
            onRender: (item: IChartDentition) =>
                item.xPosition ? (
                    <Text>
                        {Math.abs(+item.xPosition)}, {getXTranslationSurface(item)}
                    </Text>
                ) : null,
        },
        {
            key: 'translationY',
            minWidth: 50,
            maxWidth: 50,
            name: 'Occlusal (mm)',
            onRender: (item: IChartDentition) => (item.yPosition ? <Text>{-item.yPosition}</Text> : null),
        },
    ];

    if (!selectedTeeth.length) return null;

    return (
        <Stack grow>
            <Stack grow horizontalAlign="center">
                {showRotation && (
                    <Stack horizontal>
                        <TooltipHost content={'Rotate Occlusal Clockwise'}>
                            <IconButton
                                iconProps={{ iconName: 'Rotate90CounterClockwise' }}
                                onClick={() => dispatch(rotateOcclusalTeeth('decrement', selectedTeeth))}
                            />
                        </TooltipHost>
                        <TooltipHost content={'Rotate Occlusal Counter-Clockwise'}>
                            <IconButton
                                iconProps={{ iconName: 'Rotate90Clockwise' }}
                                onClick={() => dispatch(rotateOcclusalTeeth('increment', selectedTeeth))}
                            />
                        </TooltipHost>
                    </Stack>
                )}
                <Stack horizontal>
                    {showRotation && (
                        <TooltipHost content={'Rotate Mesial Tip'}>
                            <IconButton
                                iconProps={{ iconName: 'Rotate90CounterClockwise' }}
                                onClick={() => dispatch(rotateTeeth('decrement', selectedTeeth))}
                            />
                        </TooltipHost>
                    )}
                    {showTranslation && (
                        <TooltipHost content={'Translate Occlusal 1 MM'}>
                            <IconButton
                                iconProps={{ iconName: 'ChevronUpSmall' }}
                                onClick={() => dispatch(translateTeethY('decrement', selectedTeeth))}
                            />
                        </TooltipHost>
                    )}
                    {showRotation && (
                        <TooltipHost content={'Rotate Facial Tip'}>
                            <IconButton
                                iconProps={{ iconName: 'Rotate90Clockwise' }}
                                onClick={() => dispatch(rotateTeeth('increment', selectedTeeth))}
                            />
                        </TooltipHost>
                    )}
                </Stack>

                {showTranslation && (
                    <Stack horizontal>
                        <TooltipHost content={'Translate Mesial 1 MM'}>
                            <IconButton
                                iconProps={{ iconName: 'ChevronLeftSmall' }}
                                onClick={() => dispatch(translateTeethX('decrement', selectedTeeth))}
                            />
                        </TooltipHost>
                        <TooltipHost content={'Translate Occlusal 1 MM'}>
                            <IconButton
                                iconProps={{ iconName: 'ChevronDownSmall' }}
                                onClick={() => dispatch(translateTeethY('increment', selectedTeeth))}
                            />
                        </TooltipHost>
                        <TooltipHost content={'Translate Facial 1 MM'}>
                            <IconButton
                                iconProps={{ iconName: 'ChevronRightSmall' }}
                                onClick={() => dispatch(translateTeethX('increment', selectedTeeth))}
                            />
                        </TooltipHost>
                    </Stack>
                )}
            </Stack>
            <Stack style={{ maxHeight: 300, overflow: 'auto', position: 'relative' }} grow>
                {state.length ? (
                    <SortableDetailsList
                        selectionMode={SelectionMode.none}
                        onRenderDetailsHeader={(detailsHeaderProps) =>
                            detailsHeaderProps ? (
                                <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
                                    <DetailsHeader {...detailsHeaderProps} />
                                </Sticky>
                            ) : null
                        }
                        compact
                        items={state}
                        columns={columns}
                        sortOnMount
                        sortColumns={['toothId']}
                    />
                ) : (
                    <MessageBar>No movements found.</MessageBar>
                )}
            </Stack>
        </Stack>
    );
}
