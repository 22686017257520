import { Stack, Label, SearchBox } from '@fluentui/react';
import { useSelector } from 'hooks';
import { useDispatch } from 'react-redux';
import { selectWorkListFilters } from 'state/slices/admin-huddle/worklists/worklist.selectors';
import { setWorkListFilter } from 'state/slices/admin-huddle/worklists/worklist.slice';

export default function FindPatientFilter() {
    const dispatch = useDispatch();
    const { patient } = useSelector(selectWorkListFilters<any>);

    return (
        <Stack.Item grow>
            <Label>Find Patient</Label>
            <SearchBox
                title="Find Patient"
                value={patient ?? ''}
                onChange={(e, value) => {
                    dispatch(setWorkListFilter({ path: 'patient', value: value ?? '' }));
                }}
                placeholder="Filter Patient..."
            />
        </Stack.Item>
    );
}
