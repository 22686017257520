import { PrimaryButton, Stack, TextField } from '@fluentui/react';
import IWorkListEncounterView from 'api/models/worklist-encounter-view';
import { Field } from 'components';
import { useSelector, useTenantId } from 'hooks';
import PanelSectionHeader from 'pages/components/PanelSectionHeader';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    selectAmendmentNote,
    selectAmendmentId,
    selectAmendmentType,
    selectVisitSummaryAmendmentSaving,
    selectAmendmentTreatingProvider,
} from 'state/slices/admin-huddle/admin-huddle.selectors';
import {
    setAmendmentId,
    setAmendmentNote,
    setAmendmentTreatingProvider,
    setAmendmentType,
} from 'state/slices/admin-huddle/admin-huddle.slice';
import { createAmendmentNote } from 'state/slices/admin-huddle/worklists/encounter-worklists/encounter-worklists.actions';
import {
    selectCurrentWorkList,
    selectIsPostBilledWorkList,
    selectWorkListEditItem,
} from 'state/slices/admin-huddle/worklists/worklist.selectors';
import { amendmentReasonOptionsList } from 'state/slices/tenant/amendmentReason';
import { IAmendmentNote } from './VisitSummaryPanel';
import { EncounterStatus } from 'api/models/encounter.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { MissingSlipsWorkList } from 'state/slices/admin-huddle/worklists/worklist.state';
import { selectTreatingProviderOptions } from 'state/slices/tenant/providers.slice';

interface IVisitSummaryAmendmentProps {
    readOnly: boolean | undefined;
}

export default function VisitSummaryAmendment({ readOnly }: IVisitSummaryAmendmentProps): JSX.Element | null {
    const tenantId = useTenantId();

    const selectedView = useSelector(selectWorkListEditItem<IWorkListEncounterView>);

    const savingAmendmentStatus = useSelector(selectVisitSummaryAmendmentSaving);

    const isSavingAmendment = savingAmendmentStatus === LoadingStatus.Pending;
    const isPostBilledWorkList = useSelector(selectIsPostBilledWorkList);
    const worklist = useSelector(selectCurrentWorkList);

    const treatingProviderOptions = useSelector(selectTreatingProviderOptions);

    const amendmentId = useSelector(selectAmendmentId);
    const amendmentNote = useSelector(selectAmendmentNote);
    const amendmentType = useSelector(selectAmendmentType);
    const amendmentTreatingProvider = useSelector(selectAmendmentTreatingProvider);
    const amendmentTypeOptions = useSelector(amendmentReasonOptionsList);

    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedView?.treatingProviderId) dispatch(setAmendmentTreatingProvider(selectedView.treatingProviderId));
    }, [selectedView, dispatch]);

    useEffect(() => {
        if (amendmentType === '') {
            dispatch(setAmendmentNote(''));
        }
    }, [amendmentType, dispatch]);

    if (
        readOnly ||
        isPostBilledWorkList ||
        worklist === MissingSlipsWorkList.AwaitingAttestation ||
        worklist === MissingSlipsWorkList.OutstandingCheckoutAppointments ||
        selectedView?.encounter?.status === EncounterStatus.AmendRequired
    )
        return null;

    const _onSave = () => {
        if (selectedView) {
            const newAmendmentNote: IAmendmentNote = {
                providerId: amendmentTreatingProvider ?? '',
                patientId: selectedView.patientId ?? '',
                encounterId: selectedView?.encounterId ?? '',
                amendReason: amendmentType ?? '',
                amendNote: amendmentNote ?? '',
                amendReasonId: amendmentId ?? '',
            };

            dispatch(createAmendmentNote({ tenantId, queryObject: newAmendmentNote }));
        }
    };

    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <Stack>
                <PanelSectionHeader text="Amendment" />
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Stack.Item grow>
                        <Field.Dropdown
                            label="Reason for Amendment"
                            placeholder="Select"
                            selectedKey={amendmentId ?? ''}
                            required
                            options={[{ key: '', text: '(Select)' }, ...amendmentTypeOptions]}
                            disabled={isSavingAmendment}
                            onChange={(ev, option) => {
                                if (option) {
                                    dispatch(setAmendmentId(option.key as string));
                                    dispatch(setAmendmentType(option.text as string));
                                }
                            }}
                        />
                    </Stack.Item>
                    <Stack.Item grow>
                        <Field.SearchCombo
                            label="Route Amendment To"
                            required
                            options={treatingProviderOptions}
                            selectedKey={amendmentTreatingProvider ?? ''}
                            onChange={(ev, option) =>
                                dispatch(setAmendmentTreatingProvider((option?.key as string | undefined) ?? ''))
                            }
                        />
                    </Stack.Item>
                </Stack>
                <Stack.Item grow>
                    <TextField
                        label="Amendment Note"
                        prefix={`${amendmentType ? amendmentType + ':' : ''}`}
                        onChange={(e, value) => dispatch(setAmendmentNote(value ? value : ''))}
                        disabled={!amendmentType || isSavingAmendment}
                        value={amendmentNote ?? ''}
                    />
                </Stack.Item>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
                <PrimaryButton
                    text="Save"
                    onClick={_onSave}
                    disabled={!amendmentType || !amendmentTreatingProvider || isSavingAmendment}
                />
            </Stack>
        </Stack>
    );
}
