import { PanelType } from '@fluentui/react';
import { IPatientEncounter, IEncounterHistory } from 'api/models/encounter.model';
import IPatientImmunizations from 'api/models/patient-immunization.model';
import { IPatientLedger } from 'api/models/patient-ledger.model';
import IPatientProblems, { IProblem } from 'api/models/patient-problem.model';
import IPatient, { IPatientAudit } from 'api/models/patient.model';
import IAppointmentAllocations from 'api/models/Scheduling/allocation.model';
import { IUpcomingAppointment } from 'api/models/Scheduling/patientAppointment.model';
import { IPendingChartTreatmentPlanPhaseProcedure } from 'api/models/treatment-plan.model';
import IUserTask from 'api/models/user-task.model';
import { IPatientProblemsQuestion } from 'forms/Problems/types';
import { IValidationError } from 'hooks/useValidation';
import { LoadingStatuses, LoadingStatus } from 'interfaces/loading-statuses';
import { SyncStatus } from 'interfaces/syncing-statuses';
import { Dictionary } from 'lodash';
import { AllergiesState, initalAllergiesState } from './allergies/allergies.state';
import { MedicationsState, initialMedicationsState } from './medications/medications.state';
import { VitalsState, initialVitalsState } from './vitals/vitals.state';

export type PatientState = {
    selectedPatient?: IPatient; // selected patient
    loading: LoadingStatuses;
    saving: SyncStatus;
    patients: {
        loading: LoadingStatuses;
        data: IPatient[];
    };
    patientOverviewTasks: {
        data: IUserTask[];
        loading: LoadingStatuses;
    };
    problems: {
        data?: IPatientProblems;
        loading: LoadingStatuses;
        validationErrors: IValidationError[];
    };
    allergies: AllergiesState;
    medications: MedicationsState;
    vitals: VitalsState;
    immunizations: {
        data?: IPatientImmunizations;
        loading: LoadingStatuses;
        saving: LoadingStatuses;
        validationErrors: IValidationError[];
    };
    encounters: {
        data?: IPatientEncounter[];
        loading: LoadingStatuses;
        //loading statuses keyed off of the appointment id.
        createNewEncounterLoading: Dictionary<LoadingStatus>;
    };
    selectedOverviewItem: OverviewItem;
    findPatientOpen: boolean;
    currentAndFutureAppointments?: IAppointmentAllocations;
    upcomingPatientAppointments: {
        data?: IUpcomingAppointment[];
        loading: LoadingStatuses;
    };
    pendingChartTreatmentPlanPhaseProcedures: {
        data?: IPendingChartTreatmentPlanPhaseProcedure[];
        loading: LoadingStatuses;
    };
    pastEncounters: {
        data?: IEncounterHistory[];
        loading: LoadingStatuses;
    };
    ledger: {
        data?: IPatientLedger;
        loading: LoadingStatuses;
    };
    auditLog: {
        data?: IPatientAudit;
        loading: LoadingStatuses;
    };
    problemQuestionnarire?: IPatientProblemsQuestion;
    problemPanelOpen: boolean;

    healthHistory?: Dictionary<IProblem>;
};

export type OverviewItem = {
    item?: any;
    savingStatus?: LoadingStatuses;
    validationErrors: IValidationError[];
    isNewItem?: boolean;
    key?: number;
    displayName: string;
    type: string;
    panelType?: PanelType;
};

export const patientInitialState: PatientState = {
    loading: LoadingStatus.Idle,
    saving: SyncStatus.Idle,
    patients: {
        loading: LoadingStatus.Idle,
        data: [],
    },
    patientOverviewTasks: {
        data: [],
        loading: LoadingStatus.Idle,
    },

    problems: {
        data: undefined,
        loading: LoadingStatus.Idle,
        validationErrors: [],
    },
    allergies: initalAllergiesState,
    medications: initialMedicationsState,
    vitals: initialVitalsState,
    immunizations: {
        data: undefined,
        loading: LoadingStatus.Idle,
        saving: LoadingStatus.Idle,
        validationErrors: [],
    },
    selectedOverviewItem: {
        item: undefined,
        savingStatus: LoadingStatus.Idle,
        validationErrors: [],
        isNewItem: false,
        key: undefined,
        displayName: '',
        type: '',
    },
    encounters: {
        loading: LoadingStatus.Idle,
        createNewEncounterLoading: {},
    },

    findPatientOpen: false,
    upcomingPatientAppointments: {
        data: undefined,
        loading: LoadingStatus.Idle,
    },
    pendingChartTreatmentPlanPhaseProcedures: {
        data: undefined,
        loading: LoadingStatus.Idle,
    },
    pastEncounters: {
        data: undefined,
        loading: LoadingStatus.Idle,
    },
    ledger: {
        data: undefined,
        loading: LoadingStatus.Idle,
    },
    auditLog: {
        data: undefined,
        loading: LoadingStatus.Idle,
    },
    problemPanelOpen: false,

    healthHistory: {},
};
