import { Dictionary } from '@reduxjs/toolkit';
import ProcedureCodes from './procedureCodes';

const archProcedureCodeLookup: Dictionary<ProcedureCodes>[] = [
    {
        maxillary: ProcedureCodes.D1551,
        mandibular: ProcedureCodes.D1552,
    },
    {
        maxillary: ProcedureCodes.D1557,
        mandibular: ProcedureCodes.D1558,
    },
    {
        maxillary: ProcedureCodes.D5110,
        mandibular: ProcedureCodes.D5120,
    },
    {
        maxillary: ProcedureCodes.D5130,
        mandibular: ProcedureCodes.D5140,
    },
    {
        maxillary: ProcedureCodes.D5211,
        mandibular: ProcedureCodes.D5212,
    },
    {
        maxillary: ProcedureCodes.D5213,
        mandibular: ProcedureCodes.D5214,
    },
    {
        maxillary: ProcedureCodes.D5221,
        mandibular: ProcedureCodes.D5222,
    },
    {
        maxillary: ProcedureCodes.D5223,
        mandibular: ProcedureCodes.D5224,
    },
    {
        maxillary: ProcedureCodes.D5225,
        mandibular: ProcedureCodes.D5226,
    },
    {
        maxillary: ProcedureCodes.D5227,
        mandibular: ProcedureCodes.D5228,
    },
    {
        maxillary: ProcedureCodes.D5282,
        mandibular: ProcedureCodes.D5283,
    },
    {
        maxillary: ProcedureCodes.D5410,
        mandibular: ProcedureCodes.D5411,
    },
    {
        maxillary: ProcedureCodes.D5421,
        mandibular: ProcedureCodes.D5422,
    },
    {
        maxillary: ProcedureCodes.D5670,
        mandibular: ProcedureCodes.D5671,
    },
    {
        maxillary: ProcedureCodes.D5710,
        mandibular: ProcedureCodes.D5711,
    },
    {
        maxillary: ProcedureCodes.D5720,
        mandibular: ProcedureCodes.D5721,
    },
    {
        maxillary: ProcedureCodes.D5730,
        mandibular: ProcedureCodes.D5731,
    },
    {
        maxillary: ProcedureCodes.D5740,
        mandibular: ProcedureCodes.D5741,
    },
    {
        maxillary: ProcedureCodes.D5750,
        mandibular: ProcedureCodes.D5751,
    },
    {
        maxillary: ProcedureCodes.D5760,
        mandibular: ProcedureCodes.D5761,
    },
    {
        maxillary: ProcedureCodes.D5810,
        mandibular: ProcedureCodes.D5811,
    },
    {
        maxillary: ProcedureCodes.D5820,
        mandibular: ProcedureCodes.D5821,
    },
    {
        maxillary: ProcedureCodes.D5850,
        mandibular: ProcedureCodes.D5851,
    },
    {
        maxillary: ProcedureCodes.D5863,
        mandibular: ProcedureCodes.D5865,
    },
    {
        maxillary: ProcedureCodes.D5864,
        mandibular: ProcedureCodes.D5866,
    },
    {
        maxillary: ProcedureCodes.D5995,
        mandibular: ProcedureCodes.D5996,
    },
    {
        maxillary: ProcedureCodes.D6110,
        mandibular: ProcedureCodes.D6111,
    },
    {
        maxillary: ProcedureCodes.D6112,
        mandibular: ProcedureCodes.D6113,
    },
    {
        maxillary: ProcedureCodes.D6114,
        mandibular: ProcedureCodes.D6115,
    },
    {
        maxillary: ProcedureCodes.D6116,
        mandibular: ProcedureCodes.D6117,
    },
    {
        maxillary: ProcedureCodes.D7472,
        mandibular: ProcedureCodes.D7473,
    },
    {
        maxillary: ProcedureCodes.D7560,
    },
    {
        maxillary: ProcedureCodes.D7610,
        mandibular: ProcedureCodes.D7630,
    },
    {
        maxillary: ProcedureCodes.D7620,
        mandibular: ProcedureCodes.D7640,
    },
    {
        maxillary: ProcedureCodes.D7710,
        mandibular: ProcedureCodes.D7730,
    },
    {
        maxillary: ProcedureCodes.D7720,
        mandibular: ProcedureCodes.D7740,
    },
    {
        maxillary: ProcedureCodes.D8696,
        mandibular: ProcedureCodes.D8697,
    },
    {
        maxillary: ProcedureCodes.D8698,
        mandibular: ProcedureCodes.D8699,
    },
    {
        maxillary: ProcedureCodes.D8701,
        mandibular: ProcedureCodes.D8702,
    },
    {
        maxillary: ProcedureCodes.D8703,
        mandibular: ProcedureCodes.D8704,
    },
    {
        maxillary: ProcedureCodes.D9932,
        mandibular: ProcedureCodes.D9933,
    },
    {
        maxillary: ProcedureCodes.D9934,
        mandibular: ProcedureCodes.D9935,
    },
];
export default archProcedureCodeLookup;
