import { Component, ReactNode } from 'react';
import SSNField from './SSNField';
import PhoneField from './PhoneField';
import EmailField from './EmailField';
import DateField from './DateField';
import SearchComboField from './SearchComboField';
import AddressField from './AddressField';
import DropdownField from './DropdownField';
import ChoiceGroupField from './ChoiceGroupField';
import SearchField from './SearchField';
import TaxonomyField from './TaxonomyField';
import StateField from './StateField';
import ZipCodeField from './ZipCodeField';
import TypeaheadSearchField from './TypeaheadSearchField';
import FileInputField from './FileInputField';
import CurrencyField from './CurrencyField';
import DateRangeField from './DateRangeField';
import RelativeDateRangeField from './RelativeDateRangeField';
export default class Field extends Component {
    static SSN = SSNField;
    static Phone = PhoneField;
    static Email = EmailField;
    static Taxonomy = TaxonomyField;
    static Date = DateField;
    static Search = SearchField;
    static SearchCombo = SearchComboField;
    static TypeaheadSearch = TypeaheadSearchField;
    static Address = AddressField;
    static Dropdown = DropdownField;
    static ChoiceGroup = ChoiceGroupField;
    static State = StateField;
    static ZipCode = ZipCodeField;
    static FileInput = FileInputField;
    static Currency = CurrencyField;
    static RelativeDateRange = RelativeDateRangeField;
    static DateRange = DateRangeField;

    render(): ReactNode {
        return this.props.children;
    }
}
