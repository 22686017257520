import {
    Callout,
    ConstrainMode,
    DetailsListLayoutMode,
    DirectionalHint,
    Label,
    Link,
    Selection,
    Separator,
    Stack,
    Text,
    TooltipHost,
    SelectionMode,
    MessageBar,
    MarqueeSelection,
    ISelection,
    IObjectWithKey,
    ScrollablePane,
} from '@fluentui/react';
import { SortableDetailsList } from 'components';
import { useTenant, useChartProcedures, useChart, useSelector } from 'hooks';
import { find } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { classicDateOnly } from 'utils/dateOnly';
import ChartActionDeleteModal from './ChartActionDeleteModal';
import { toothSpriteReferences } from './ToothCanvas/spriteList';
import { IChartAction } from 'state/slices/charting/chartActionsList.pipeline';
import { ChartConditionStatus, ChartProcedureStatus, IChartCondition, IChartProcedure } from 'api/models/chart.model';
import useChartConditions from 'hooks/store/useChartConditions';
import { useBoolean } from '@uifabric/react-hooks';
import dentalApi from 'api/dental.api';
import { RouteParams } from 'interfaces/route-params';
import { EditDetailsColumn } from 'components/EditDetailsColumn';
import { useDispatch } from 'react-redux';
import { chartingActions, ProcedureActionType } from 'state/slices/charting/chart/chart.slice';
import { handleEditProcedureClick } from 'state/slices/charting/procedure-panel/procedure-panel.actions';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { openDiagnosesPanel } from 'state/slices/charting/diagnoses-panel/diagonses-panel.actions';
import { chartActionDateLookup, getSelectedChartActions } from 'state/slices/charting/chart/chart.selectors';
import { format } from 'date-fns';
import { handleEditCondition } from 'state/slices/charting/condition-panel/condition-panel.actions';
import { ProcedureBillingStatus, ProcedureStage } from 'api/models/procedure.model';
import getShorthandToothAreas from 'utils/getShorthandToothArea';
import { validate } from 'uuid';
import { EncounterStatus, IPatientEncounter } from 'api/models/encounter.model';
import { patientEncounterLookup } from 'state/slices/patient/patient.selectors';
import { userHasPermissionForEncounterAccess } from 'state/slices/encounter/encounter.selectors';
import IServiceUserAccount from 'api/models/account.model';
import { selectAccountData } from 'state/slices/account.slice';
import UserDisplayName from 'components/UserDisplayName';

const currencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

/**
 * Returns true of a given action cannot be edited.
 * Return false if a given chart action can be edited.
 *
 * @param {(IChartAction | undefined)} chartAction
 * @param {Record<string, IPatientEncounter>} billedEncounterLookup
 * @return {*}
 */
export const cannotEditChartAction = (
    chartAction: IChartAction | undefined,
    patientEncounterLookup: Record<string, IPatientEncounter>,
    account: IServiceUserAccount | undefined,
    encounterId: string | undefined,
) => {
    //If the chart action has no encounterId, we can edit the procedure regardless of any other conditions.
    if (!chartAction?.encounterId) return false;

    //Determine if the given chart action on a billed encounter is editable. (Both corrections needed and corrections completed are "in the category" of billed)
    function isNotEditableActionForBilledEncounter(): boolean {
        if (!encounterId) return true;

        //Encounter that is selected.
        const encounter = patientEncounterLookup[encounterId];

        if (encounter?.status === EncounterStatus.CorrectionsNeeded || encounter?.status === EncounterStatus.CorrectionAmend) {
            return chartAction?.encounterId !== encounterId;
        }

        return true;
    }

    if (
        //For completed chart actions
        chartAction?.status === ChartProcedureStatus.Completed &&
        //If the action is procedure action type treatment
        chartAction.procedureType === ProcedureActionType.Treatment
    ) {
        //Encounter of the given chart action.
        const actionEncounter = patientEncounterLookup[chartAction.encounterId];
        
        //Determine if the user doesn't have access to the encounter, or the encounter is billed, or the chart action is billed.
        if (
            !userHasPermissionForEncounterAccess(actionEncounter, account) ||
            actionEncounter?.status === EncounterStatus.Billed ||
            chartAction.billingStatus === ProcedureBillingStatus.Billed
        ) {
            return isNotEditableActionForBilledEncounter();
        }

        if(chartAction.billingStatus === ProcedureBillingStatus.Billable && chartAction.encounterId !== encounterId)
            return true
    }

    //Handle conversion imported chart procedures:
    //If the procedure is a string and not a guid, and the procedure is a treatment completed procedure, lock it down.
    const createdByNotGuid = chartAction?.createdBy ? !validate(chartAction.createdBy) && chartAction.createdBy !== 'Data-Exchange' : false;
    const modifiedByNotGuid = chartAction?.modifiedBy ? !validate(chartAction.modifiedBy) && chartAction.modifiedBy !== 'Data-Exchange' : false;

    if (
        chartAction?.status === ChartProcedureStatus.Completed &&
        chartAction.procedureType === ProcedureActionType.Treatment &&
        createdByNotGuid &&
        modifiedByNotGuid
    )
        return true;

    return false;
};

function ChartActions(): JSX.Element {
    const { deleteChartProcedures, chartProceduresList: chartProcedures, loading: proceduresLoading } = useChartProcedures();
    const { chartConditionsAsList, deleteChartConditions, loading: conditionsLoading } = useChartConditions();
    const { chartActionsList, isDeleteModalOpen, setDeleteModalOpen, filteredChartActions } = useChart();
    const { procedures, conditions, providers, getDiagnosesData } = useTenant();
    const { patientId, tenantId, encounterId } = useParams<RouteParams>();
    const account = useSelector(selectAccountData);
    const encounterLookup = useSelector(patientEncounterLookup);
    const dispatch = useDispatch();

    // const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

    const [initialSort, { setTrue: setInitialSortFinished, setFalse: setInitialSortFalse }] = useBoolean(false);
    useEffect(() => {
        setInitialSortFalse();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenantId, patientId]);
    const _chartActionDateLookup = useSelector(chartActionDateLookup);
    const selectedChartActions = useSelector(getSelectedChartActions);

    const chartProceduresSelection = useMemo(() => {
        return new Selection({
            onSelectionChanged: () => {
                const chartActions = _getChartActionSelectionDetails();
                dispatch(chartingActions.selectChartActions(chartActions));
            },
            getKey: (item) => item.actionId,
            items: chartActionsList,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function _getChartActionSelectionDetails() {
        return chartProceduresSelection.getSelection() as IChartAction[];
    }

    useEffect(() => {
        //Any time our data changes we want to deselect the currently selected chart actions
        if (selectedChartActions.length) chartProceduresSelection.selectToKey('', true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartActionsList]);

    function getProceduresFromActions(actions: IChartAction[]): IChartProcedure[] {
        return actions
            .filter((a) => a.actionType === 'Procedure')
            .map((a) => {
                const procedure = chartProcedures.find((p) => p.id === a.id);
                return procedure;
            })
            .filter((p) => p !== undefined) as IChartProcedure[];
    }

    function getConditionsFromActions(actions: IChartAction[]): IChartCondition[] {
        return actions
            .filter((a) => a.actionType === 'Condition')
            .map((a) => {
                const condition = chartConditionsAsList.find((p) => p.id === a.id);
                return condition;
            })
            .filter((p) => p !== undefined) as IChartCondition[];
    }

    const _handleItemOnClick = (item: IChartAction) => {
        if (item.actionType === 'Procedure' && !cannotEditChartAction(item, encounterLookup, account, encounterId))
            dispatch(handleEditProcedureClick(item));
        if (item.actionType === 'Condition') {
            dispatch(handleEditCondition(item));
        }
    };

    const _handleDiagnosesClick = (item: IChartAction) => {
        if (item.actionType === 'Procedure') {
            dispatch(openDiagnosesPanel(item));
        }
    };

    const _renderActionDescription = (item?: IChartAction) => {
        if (item) {
            const dataSource = item.actionType === 'Procedure' ? procedures.data : conditions.data;
            const itemData = dataSource ? dataSource[item.actionId] : undefined;
            if (itemData) {
                return (
                    <EditDetailsColumn
                        disabled={cannotEditChartAction(item, encounterLookup, account, encounterId)}
                        editOnClick={() => _handleItemOnClick(item)}
                        title={`${
                            item.actionType === 'Procedure'
                                ? itemData.displayName
                                    ? itemData.displayName
                                    : item.procedureDescription
                                : `${itemData.displayName}${item.conditionDescription ? ` - ${item.conditionDescription}` : ''}`
                        }`}
                    />
                );
            }
        }
        return '';
    };

    //Getting the correct date value.
    //Create a selector that is keyed off the id of the chart procedure, and has a date as the value.

    /**
     * Looking up all the encounters for the patient.
     * Try to find the id that matches the encounter id on the chart procedure.
     * If we found it, set the "date string" to the encounter date.
     *
     * IF NOT CORRECT STATUS THEN SHOW CREATEDON
     *
     * {
     *   chartProcedureId: "date string"
     *   chartProcedureId: "date string"
     *   chartProcedureId: "date string"
     * }
     *
     * The render function will handle making it look pretty.
     */

    const _getChartActionDate = (item?: IChartAction): string => {
        if (item) return _chartActionDateLookup[item.id] ? (_chartActionDateLookup[item.id] as string) : item.createdOn ?? '';
        return '';
    };

    //One for rending the date correctly:
    const _renderDate = (item?: IChartAction) => {
        if (item) {
            const dateToUse = _getChartActionDate(item);
            return <Text variant="smallPlus">{dateToUse ? classicDateOnly(dateToUse, 'MM/dd/yyyy') : ''}</Text>; // NOTE Stop using date-fns format for dates.
        }
        return '';
    };

    function onDismissDeleteModal() {
        setDeleteModalOpen(false);
    }

    function onContinueDeleteProcedures(actions: IChartAction[]) {
        onDismissDeleteModal();
        const procedures = getProceduresFromActions(actions);
        const conditions = getConditionsFromActions(actions);
        if (procedures?.length) deleteChartProcedures(procedures);
        if (conditions?.length) deleteChartConditions(conditions);
    }

    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const [itemInformation, setItemInformation] = useState<IChartAction>();

    return (
        <>
            <ChartActionDeleteModal
                isOpen={isDeleteModalOpen}
                onDismiss={onDismissDeleteModal}
                onContinue={onContinueDeleteProcedures}
                actions={selectedChartActions}
            />

            {proceduresLoading === LoadingStatus.Completed &&
            conditionsLoading === LoadingStatus.Completed &&
            !chartActionsList.length ? (
                <div style={{ width: '100%' }}>
                    <MessageBar>No items have been added to the tooth ledger yet.</MessageBar>
                </div>
            ) : (
                <Stack style={{ position: 'relative', display: 'flex', flex: 1 }}>
                    <ScrollablePane>
                        <MarqueeSelection selection={chartProceduresSelection as unknown as ISelection<IObjectWithKey>}>
                            <SortableDetailsList<IChartAction>
                                onItemInvoked={(item?: IChartAction) => {
                                    if (item) _handleItemOnClick(item);
                                }}
                                setKey="actionId"
                                selectionMode={SelectionMode.multiple}
                                styles={{ root: { minHeight: 500 } }}
                                items={filteredChartActions}
                                selection={chartProceduresSelection as unknown as ISelection<IObjectWithKey>}
                                layoutMode={DetailsListLayoutMode.justified}
                                isHeaderVisible
                                sortOnMount
                                onSort={(items) => {
                                    if (
                                        conditionsLoading === LoadingStatus.Completed &&
                                        proceduresLoading === LoadingStatus.Completed &&
                                        items.length &&
                                        !initialSort
                                    ) {
                                        setInitialSortFinished();
                                    }
                                }}
                                compact
                                enableShimmer={!chartActionsList.length}
                                sortColumns={['type', 'status', 'stage', 'date', 'tooth']}
                                initialSortDirection={['desc', 'asc', 'asc', 'desc', 'asc']}
                                constrainMode={ConstrainMode.unconstrained}
                                columns={[
                                    {
                                        key: 'date',
                                        name: 'Date',
                                        minWidth: 80,
                                        maxWidth: 80,
                                        onRender: _renderDate,
                                        getValueKey: (item) => _getChartActionDate(item),
                                    },
                                    {
                                        key: 'provider',
                                        name: 'Provider',
                                        minWidth: 130,
                                        maxWidth: 130,
                                        onRender: (item) => {
                                            if (item) {
                                                // const provider = find(providers.data, ['id', item.treatingProviderId]);
                                                // const label = provider ? `${provider.lastName} ${provider.suffix}, ${provider.firstName} ` : '';
                                                return (
                                                    <Text variant="mediumPlus">
                                                        <UserDisplayName userId={item.treatingProviderId} useSuffix />
                                                    </Text>
                                                );
                                            }
                                        },
                                        fieldName: 'provider',
                                        getValueKey: (item) => {
                                            if (item) {
                                                const provider = find(providers.data, ['id', item.treatingProviderId]);
                                                const label = provider
                                                    ? `${provider.lastName} ${provider.suffix}, ${provider.firstName} `
                                                    : '';
                                                return label;
                                            }
                                            return '';
                                        },
                                    },
                                    {
                                        key: 'code',
                                        name: 'Code',
                                        minWidth: 50,
                                        maxWidth: 50,
                                        fieldName: 'code',
                                        onRender: (item) => (
                                            <Text>
                                                {item?.actionType === 'Procedure' ? (
                                                    <Text variant="mediumPlus">{item?.code}</Text>
                                                ) : (
                                                    ''
                                                )}
                                            </Text>
                                        ),
                                    },
                                    {
                                        key: 'description',
                                        name: 'Description',
                                        minWidth: 150,
                                        maxWidth: 300,
                                        fieldName: 'description',
                                        onRender: _renderActionDescription,
                                        styles: {},
                                        getValueKey: (item) => {
                                            if (item) {
                                                const dataSource =
                                                    item.actionType === 'Procedure' ? procedures.data : conditions.data;
                                                const itemData = dataSource ? dataSource[item.actionId] : undefined;

                                                return itemData?.displayName
                                                    ? itemData?.displayName
                                                    : itemData?.description
                                                      ? itemData?.description
                                                      : '';
                                            }

                                            return '';
                                        },
                                    },
                                    {
                                        key: 'tooth',
                                        name: 'Tooth',
                                        minWidth: 80,
                                        maxWidth: 80,
                                        fieldName: 'toothIds',
                                        getValueKey: (item) => {
                                            if (item?.toothIds?.length) {
                                                return item.toothIds[0]?.toString();
                                            } else {
                                                return '0';
                                            }
                                        },
                                        onRender: (item) => {
                                            const teeth = item?.toothIds
                                                ?.map((id) => {
                                                    return toothSpriteReferences.find((tooth) => tooth.id === id)?.displayName;
                                                })
                                                ?.join(', ');
                                            return (
                                                <TooltipHost content={teeth} directionalHint={DirectionalHint.topRightEdge}>
                                                    <Text variant="mediumPlus">{teeth}</Text>
                                                </TooltipHost>
                                            );
                                        },
                                    },
                                    {
                                        key: 'areas',
                                        name: 'Area(s)',
                                        minWidth: 50,
                                        maxWidth: 50,
                                        onRender: (item) => {
                                            if (item && item.areas && item.areas.length) {
                                                const shortHandText = getShorthandToothAreas(item.areas);
                                                return (
                                                    <TooltipHost delay={0} content={shortHandText}>
                                                        <Text>{shortHandText}</Text>
                                                    </TooltipHost>
                                                );
                                            }
                                            return null;
                                        },
                                    },

                                    {
                                        key: 'type',
                                        name: 'Type',
                                        minWidth: 70,
                                        maxWidth: 70,
                                        onRender: (item) => {
                                            if (item?.actionType === 'Condition' || item?.procedureDescription === 'Missing') {
                                                return <Text variant="mediumPlus">Condition</Text>;
                                            } else {
                                                return <Text variant="mediumPlus">{item?.procedureType}</Text>;
                                            }
                                        },
                                        fieldName: 'procedureType',
                                        getValueKey: (item) => {
                                            if (!initialSort) {
                                                if (item?.procedureType)
                                                    switch (item.procedureType) {
                                                        case ProcedureActionType.Treatment:
                                                            return '17';
                                                        case ProcedureActionType.Referred:
                                                            return '16';
                                                        case ProcedureActionType.ExistingOther:
                                                            return '15';
                                                        case ProcedureActionType.Existing:
                                                            return '14';
                                                        default:
                                                            return '0';
                                                    }

                                                return '0';
                                            }

                                            return item?.procedureType ? item.procedureType : '';
                                        },
                                    },
                                    {
                                        key: 'stage',
                                        name: 'Stage',
                                        minWidth: 95,
                                        maxWidth: 95,
                                        onRender: (item) => {
                                            return <Text variant="mediumPlus">{item?.stage}</Text>;
                                        },
                                        fieldName: 'stage',
                                        getValueKey: (item) => {
                                            if (!initialSort) {
                                                if (item?.stage)
                                                    switch (item.stage) {
                                                        case ProcedureStage.One:
                                                            return '1';
                                                        case ProcedureStage.Two:
                                                            return '2';
                                                        case ProcedureStage.Three:
                                                            return '3';
                                                        case ProcedureStage.Four:
                                                            return '4';
                                                        case ProcedureStage.Final:
                                                            return '10';
                                                        default:
                                                            return '20';
                                                    }

                                                return '20';
                                            }

                                            return item?.stage ? item.stage : '';
                                        },
                                    },
                                    {
                                        key: 'status',
                                        name: 'Status',
                                        minWidth: 80,
                                        maxWidth: 80,
                                        fieldName: 'status',
                                        getValueKey: (item) => {
                                            if (!initialSort) {
                                                if (item?.status)
                                                    switch (item.status as ChartProcedureStatus | ChartConditionStatus) {
                                                        case ChartProcedureStatus.Pending: {
                                                            return '0';
                                                        }
                                                        case ChartProcedureStatus.Completed: {
                                                            return '1';
                                                        }
                                                        case ChartProcedureStatus.Declined: {
                                                            return '2';
                                                        }
                                                        case ChartConditionStatus.Active: {
                                                            return '3';
                                                        }
                                                        case ChartConditionStatus.Resolved: {
                                                            return '4';
                                                        }
                                                        default:
                                                            return '20';
                                                    }

                                                return '20';
                                            }
                                            return item?.status ? item.status : '';
                                        },
                                        onRender: (item) => <Text variant="mediumPlus">{item?.status}</Text>,
                                    },
                                    {
                                        key: 'billingStatus',
                                        name: 'Billing Status',
                                        minWidth: 80,
                                        maxWidth: 80,
                                        fieldName: 'billingStatus',

                                        onRender: (item) =>
                                            item?.procedureType === ProcedureActionType.Treatment ? (
                                                <Text variant="mediumPlus">{item?.billingStatus}</Text>
                                            ) : (
                                                ''
                                            ),
                                    },
                                    {
                                        key: 'notes',
                                        name: 'Notes',
                                        minWidth: 70,
                                        maxWidth: 70,
                                        fieldName: 'notes',
                                        onRender: (item) => (
                                            <Text variant="mediumPlus">
                                                <Link
                                                    disabled={cannotEditChartAction(item, encounterLookup, account, encounterId)}
                                                    title={item?.notes}
                                                    onClick={(e?: React.MouseEvent<HTMLElement>) => {
                                                        e?.preventDefault();
                                                        if (item) _handleItemOnClick(item);
                                                    }}
                                                >
                                                    {item?.notes ? item.notes : ''}
                                                </Link>
                                            </Text>
                                        ),
                                    },
                                    {
                                        key: 'dx',
                                        name: 'Dx',
                                        minWidth: 80,
                                        maxWidth: 80,
                                        onRender: (item) => {
                                            const disabled = cannotEditChartAction(item, encounterLookup, account, encounterId);
                                            if (
                                                item?.actionType === 'Condition' ||
                                                item?.procedureType === ProcedureActionType.Existing ||
                                                item?.procedureType === ProcedureActionType.ExistingOther
                                            ) {
                                                return '';
                                            }
                                            const diagnoses = item?.diagnosisCodes
                                                ?.map((dx) => {
                                                    return getDiagnosesData ? getDiagnosesData[dx.id]?.code : '';
                                                })
                                                ?.join(', ');
                                            return (
                                                <>
                                                    {item?.diagnosisCodes?.length ? (
                                                        <Text title={diagnoses} variant="mediumPlus">
                                                            <Link
                                                                disabled={disabled}
                                                                onClick={(e?: React.MouseEvent<HTMLElement>) => {
                                                                    e?.preventDefault();
                                                                    _handleDiagnosesClick(item);
                                                                }}
                                                            >
                                                                {diagnoses}
                                                            </Link>
                                                        </Text>
                                                    ) : (
                                                        <Text variant="mediumPlus">
                                                            <Link
                                                                disabled={disabled}
                                                                onClick={(e?: React.MouseEvent<HTMLElement>) => {
                                                                    e?.preventDefault();
                                                                    if (item) _handleDiagnosesClick(item);
                                                                }}
                                                            >
                                                                Add
                                                            </Link>
                                                        </Text>
                                                    )}
                                                </>
                                            );
                                        },
                                    },
                                    {
                                        key: 'fee',
                                        name: 'Fee',
                                        minWidth: 70,
                                        maxWidth: 70,
                                        onRender: (item) => {
                                            if (
                                                item &&
                                                item.actionType === 'Procedure' &&
                                                item.procedureType !== ProcedureActionType.Existing &&
                                                item.procedureType !== ProcedureActionType.ExistingOther &&
                                                item.procedureType !== ProcedureActionType.Referred &&
                                                item.status === ChartProcedureStatus.Pending
                                            ) {
                                                if (item)
                                                    return (
                                                        <Text variant="mediumPlus">
                                                            {currencyFormatter.format(item?.fee ?? 0)}
                                                        </Text>
                                                    );
                                            }
                                            if (item && item.stage) {
                                                return <Text variant="mediumPlus">{currencyFormatter.format(0)}</Text>;
                                            } else {
                                                return null;
                                            }
                                        },
                                        fieldName: 'fee',
                                    },
                                    {
                                        key: 'preAuth',
                                        name: 'Pre-Auth',
                                        minWidth: 90,
                                        maxWidth: 90,
                                        onRender: (item) => {
                                            return <Text variant="mediumPlus">{item?.preAuthorization ?? ''}</Text>;
                                        },
                                        fieldName: 'preAuthorization',
                                    },

                                    {
                                        key: 'chartedBy',
                                        name: `Add'l. Info`,
                                        minWidth: 70,
                                        maxWidth: 70,
                                        fieldName: `chartedBy`,
                                        onRender: (item) => {
                                            const _onClick = () => {
                                                if (item) {
                                                    setItemInformation(item);
                                                    toggleIsCalloutVisible();
                                                }
                                            };
                                            return (
                                                <Text variant="mediumPlus" onClick={_onClick} id={`additional-${item?.id}`}>
                                                    <Link>
                                                        {isCalloutVisible && itemInformation?.id === item?.id ? 'Less' : 'More'}
                                                        ...
                                                    </Link>
                                                </Text>
                                            );
                                        },
                                    },
                                ]}
                                stickyHeader={true}
                                keyToMoveToBottom={'status'}
                                itemStringFiltering={'Declined'}
                            />
                        </MarqueeSelection>
                    </ScrollablePane>
                </Stack>
            )}
            {isCalloutVisible && itemInformation && (
                <AdditionalInformationCallout item={itemInformation} toggleIsCalloutVisible={toggleIsCalloutVisible} />
            )}
        </>
    );
}

function AdditionalInformationCallout({
    item,
    toggleIsCalloutVisible,
}: {
    item: IChartAction;
    toggleIsCalloutVisible: () => void;
}) {
    const { tenantId } = useParams<RouteParams>();
    const [createdBy, setCreatedBy] = useState<string>('');
    const [modifiedBy, setModifiedBy] = useState<string>('');

    useEffect(() => {
        if (item.createdBy) {
            dentalApi.getAccountById(tenantId, item.createdBy).then((res) => {
                if (res.data.firstName || res.data.lastName) {
                    setCreatedBy(`${res.data.firstName} ${res.data.lastName}`);
                }
            });
        }
        if (item.modifiedBy) {
            dentalApi.getAccountById(tenantId, item.modifiedBy).then((res) => {
                if (res.data.firstName || res.data.lastName) {
                    setModifiedBy(`${res.data.firstName} ${res.data.lastName}`);
                }
            });
        }
    }, [item, tenantId]);
    return (
        <Callout
            role="alertdialog"
            gapSpace={0}
            target={`#additional-${item?.id}`}
            onDismiss={toggleIsCalloutVisible}
            setInitialFocus
            directionalHint={DirectionalHint.rightCenter}
            styles={{ calloutMain: { padding: 12 } }}
        >
            <Text block variant="mediumPlus">
                Additional Information
            </Text>
            <Separator />
            <Stack horizontal tokens={{ childrenGap: 12 }}>
                <Stack.Item>
                    <Stack>
                        <Label>Created By:</Label>
                        <Text>{createdBy}</Text>
                    </Stack>
                    <Stack>
                        <Label>Created On:</Label>
                        <Text>{item.createdOn ? format(new Date(item.createdOn), 'MM/dd/yyyy h:mm a') : ''}</Text>
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <Stack>
                        <Label>Last Modified By:</Label>
                        <Text>{modifiedBy}</Text>
                    </Stack>
                    <Stack>
                        <Label>Last Modified On:</Label>
                        <Text>{item.modifiedOn ? format(new Date(item.modifiedOn), 'MM/dd/yyyy h:mm a') : ''}</Text>
                    </Stack>
                </Stack.Item>
            </Stack>
        </Callout>
    );
}

export default ChartActions;
