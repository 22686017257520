import { RootState } from 'state/store';

import { ProviderSettingsState } from './provider.state';
import { createSelector } from '@reduxjs/toolkit';
import { flatten, forEach, isEmpty, map, orderBy, some } from 'lodash';
import { IProviderScheduleTimeBlock, IProviderPeriod, WeekDays } from 'api/models/provider.model';
import { isAfter } from 'date-fns';
import { TimeTableRange } from 'api/models/Scheduling/operatory.model';

export const selectEditPeriods = (state: RootState): IProviderPeriod[] => state.settings.providers.editPeriods || [];
export const selectEditPeriodExceptions = (state: RootState): IProviderScheduleTimeBlock | undefined => state.settings.providers.editException;
export const selectedIsAddingException = (state: RootState): boolean | undefined => state.settings.providers.isAddException;
export const selectProvidersSettings = (state: RootState): ProviderSettingsState => state.settings.providers;
export const selectProvidersScheduleModelOpen = (state: RootState) => !!state.settings.providers.providerPeriodId;
export const selectProvidersExpectionModelOpen = (state: RootState) => !!state.settings.providers.openExceptionModal;
export const selectProvidersEmergencyExpectionModelOpen = (state: RootState) => !!state.settings.providers.openEmergencyModal;
export const selectSelectedProviderPeriodId = (state: RootState) => state.settings.providers.providerPeriodId;

export const selectSelectedProviderExceptionIndex = (state: RootState) => state.settings.providers.providerExceptionIndex;

export const selectSelectedPeriod = createSelector(
    [selectEditPeriods, selectSelectedProviderPeriodId],
    (editPeriods, periodId) => {
        return editPeriods.find((period) => period.id === periodId);
    },
);

/**
 * We can't just assume that all these options are valid for all range fields on the page.
 * OR maybe we can?
 *
 * I don't think so because one start date field may have various valid times depending on when it was added?
 * I don't think we can just pass a new list of time options to each field
 * {
 *   sunday: timeoptions[]
 *   tuesday: timeoptions[]
 *   wednesday: timeoptions[]
 *   ...
 * }
 */
// export const selectSelectedPeriodTimeOptionsLookupByDay = createSelector(
//     [selectEditPeriods, selectSelectedProviderPeriodId],
//     (editPeriods, periodId) => {
//         return editPeriods.find((period) => period.id === periodId);
//     },
// );
export const selectSelectedPeriodExpection = createSelector(
    [selectSelectedPeriod, selectSelectedProviderExceptionIndex],
    (selectedPeriord, exceptionsIndex) => {
        return selectedPeriord?.exceptions && exceptionsIndex ? selectedPeriord?.exceptions[exceptionsIndex] : undefined;
    },
);

export const selectEditPeriodsIsValid = createSelector([selectSelectedPeriod], (editPeriods) => {
    const error = [];

    // no duplicates location of care
    const locationOfCare = editPeriods?.schedules?.map((schedule) => schedule.locationOfCareId);

    const isLocationofCareEmpty = locationOfCare?.map((location) => location === '');
    if (isLocationofCareEmpty?.includes(true)) {
        error.push('Location of care is required');
    }

    const uniqueLocationOfCare = [...new Set(locationOfCare)];
    if (uniqueLocationOfCare.length !== locationOfCare?.length) {
        error.push('Duplicate location of care');
    }

    const isStartTimeEmpty = editPeriods?.startDate === '';
    if (isStartTimeEmpty) {
        error.push('Start time is required');
    }
    const isEndTimeEmpty = editPeriods?.endDate === '';
    if (isEndTimeEmpty) {
        error.push('End time is required');
    }

    if (editPeriods?.startDate && editPeriods?.endDate) {
        const isStartTimeGreaterThanEndTime = isAfter(new Date(editPeriods?.startDate), new Date(editPeriods?.endDate));
        if (isStartTimeGreaterThanEndTime) {
            error.push('Start day must be less than end day');
        }
    }

    const timetable = editPeriods?.schedules?.map((schedule) => schedule.timeTables);

    const isTimeTableEmpty =
        some(timetable, (t) => isEmpty(t)) ||
        some(
            timetable?.map((t) => flatten(map(t, (range) => range))),
            (rangesBySchedule) => {
                return rangesBySchedule.length === 0;
            },
        );

    if (isTimeTableEmpty) {
        error.push('Timetable is required');
    }

    const lookupGreatestEndTimeForDay = () => {
        const result: { [day in WeekDays]?: { locId: string; endTime: string } } = {};
        forEach(WeekDays, (weekday) => {
            let timeRanges: (TimeTableRange & { locId: string | undefined })[] = [];
            editPeriods?.schedules?.forEach((schedule, index) => {
                if (schedule?.timeTables) {
                    const ranges = schedule.timeTables[weekday] ?? [];
                    const lastLoop = editPeriods.schedules?.length === index + 1;
                    const newRanges = [...timeRanges, ...ranges.map((r) => ({ ...r, locId: schedule.locationOfCareId }))];
                    timeRanges = newRanges;
                    if (lastLoop) {
                        timeRanges = orderBy(timeRanges, (range) => range.endTime, ['asc']);
                        const greatestTimeRangeEndDate = timeRanges[timeRanges.length - 1];
                        result[weekday] = {
                            endTime: greatestTimeRangeEndDate?.endTime ?? '',
                            locId: greatestTimeRangeEndDate?.locId ?? '',
                        };
                    }
                }
            });
        });

        return result;
    };

    lookupGreatestEndTimeForDay();

    /**
     * have to go through each schedule check
     */
    // const isTimeTableOverlapping =

    return error;
});
