import React from 'react';
import { RootState } from 'state/store';

export default function AdditionalComments({ state }: { state: RootState }) {
    return (
        <>
            <h3>Additional Comments:</h3>
            <div>Explained and discussed conditions, findings, and plan of care.</div>
        </>
    );
}
