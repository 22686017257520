import { Stack } from '@fluentui/react';
import { Section } from 'components';
import { Redirect, useParams } from 'react-router-dom';
import { selectGroupedWorkLists, selectWorkListsMenuLoading } from 'state/slices/admin-huddle/admin-huddle.selectors';
import { WorkListCategoryCard } from './WorkListCategoryCard';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { useSelector } from 'hooks';
import DashboardLastUpdated from './DashboardLastUpdated';

export function WorkListCategory(): JSX.Element {
    const { categoryId, tenantId } = useParams<{ tenantId: string; categoryId: string }>();
    const groupedWorkLists = useSelector((state) => selectGroupedWorkLists(state, tenantId))?.find(
        (workLists) => workLists.id === categoryId,
    );
    const workListsLoading = useSelector(selectWorkListsMenuLoading);
    const dashboardCards = groupedWorkLists?.workLists.map((workList) => (
        <WorkListCategoryCard key={workList.id} {...workList} />
    ));
    return (
        <Stack tokens={{ childrenGap: 5, padding: 10 }}>
            {dashboardCards && (
                <Section>
                    <DashboardLastUpdated />
                    <Stack horizontal wrap tokens={{ childrenGap: 20 }}>
                        {dashboardCards}
                    </Stack>
                </Section>
            )}
            {!dashboardCards?.length && workListsLoading === LoadingStatus.Completed && (
                <Redirect to={`/${tenantId}/administrative`} />
            )}
        </Stack>
    );
}
