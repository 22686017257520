import { useSelector } from 'hooks';
import { RouteParams } from 'interfaces/route-params';
import { CancelAppointmentModal } from 'pages/components';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updateWorkListAppointmentData } from 'state/slices/admin-huddle/worklists/appointment-worklists/appointment-worklists.actions';
import { selectCurrentWorkList, selectWorkListOpenLayers } from 'state/slices/admin-huddle/worklists/worklist.selectors';
import { cleanupWorkListOpenLayers } from 'state/slices/admin-huddle/worklists/worklist.slice';
import { closeAppointmentOverview } from 'state/slices/scheduling/scheduling.slice';

export default function WorkListCancelAppointmentModal() {
    const dispatch = useDispatch();
    const isOpen = !!useSelector(selectWorkListOpenLayers)['CancelAppointmentModal'];
    const workList = useSelector(selectCurrentWorkList);
    const { tenantId } = useParams<RouteParams>();

    const cleanupCancelAppointment = () => {
        dispatch(cleanupWorkListOpenLayers());
        dispatch(closeAppointmentOverview());
    };

    return (
        <CancelAppointmentModal
            isOpen={isOpen}
            onDismiss={cleanupCancelAppointment}
            onCancelAppointment={async (appointment) => {
                try {
                    if (workList && appointment) {
                        await dispatch(
                            updateWorkListAppointmentData({ tenantId, workList, appointment, recordId: appointment.id }),
                        );
                        cleanupCancelAppointment();
                    }
                } catch (e) {
                    console.log(e);
                }
            }}
        />
    );
}
