import { DefaultButton, IContextualMenuItem, Label, Spinner } from '@fluentui/react';
import { useSelector } from 'hooks';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { RouteParams } from 'interfaces/route-params';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { selectAccountData } from 'state/slices/account.slice';
import {
    RecentlyViewedWorkListItemResponse,
    getRecentlyViewedWorkListItem,
} from 'state/slices/admin-huddle/worklists/worklist.actions';
import {
    selectCurrentWorkList,
    selectRecentWorkListItemLoading,
    selectRecentWorkListItems,
    selectWorkListEditItem,
} from 'state/slices/admin-huddle/worklists/worklist.selectors';
import { setWorkListRecentItems } from 'state/slices/admin-huddle/worklists/worklist.slice';
import appLocalStorage, { RecentWorkListItem } from 'utils/appLocalStorage';

export type WorkListRecentlyViewedAction = Omit<IContextualMenuItem, 'onClick'> & {
    idFilterFieldName: string;
    getActionDisabled?: (item: RecentWorkListItem) => boolean;
};

export type WorkListRecentItemsProps<T> = {
    recentlyViewedActions?: WorkListRecentlyViewedAction[];
    getRecentlyViewedItemFromData?: (data: Partial<T>) => RecentWorkListItem;
    onViewRecentItem?: (item: RecentlyViewedWorkListItemResponse<T>, key: string) => void;
};

export default function WorkListRecentItems<T>({
    getRecentlyViewedItemFromData,
    onViewRecentItem,
    recentlyViewedActions,
}: WorkListRecentItemsProps<T>) {
    const dispatch = useAppDispatch();
    const { tenantId } = useParams<RouteParams>();

    const currentWorkList = useSelector(selectCurrentWorkList);
    const account = useSelector(selectAccountData);

    const workListEditItem = useSelector<T | undefined>(selectWorkListEditItem);
    const recentItems = useSelector(selectRecentWorkListItems);
    const loadingRecentItem = useSelector(selectRecentWorkListItemLoading) === LoadingStatus.Pending;

    /** Returns true of the recent item can be added or removed.*/
    const canAddOrRemoveRecentItem = useCallback(
        (editItem: T): boolean => {
            return !!getRecentlyViewedItemFromData || !!(editItem as unknown as RecentWorkListItem)?.id;
        },
        [getRecentlyViewedItemFromData],
    );

    /**Gets recent item data model from worklist data model */
    const getRecentItem = useCallback(
        (editItem: T): RecentWorkListItem => {
            return getRecentlyViewedItemFromData
                ? getRecentlyViewedItemFromData(editItem)
                : {
                      id: (editItem as unknown as RecentWorkListItem).id,
                      displayName: (editItem as unknown as RecentWorkListItem).displayName ?? '',
                  };
        },
        [getRecentlyViewedItemFromData],
    );

    //Handles adding an item to local state and to local storage.
    const addRecentItem = useCallback(
        (editItem: T) => {
            if (!canAddOrRemoveRecentItem(editItem)) return;

            if (account?.identity.id && currentWorkList) {
                const recentItem = getRecentItem(editItem);

                if (recentItem.id)
                    dispatch(
                        setWorkListRecentItems(
                            appLocalStorage.addRecentWorkListItem(tenantId, currentWorkList, account.identity.id, recentItem),
                        ),
                    );
            }
        },
        [tenantId, account, currentWorkList, dispatch, getRecentItem, canAddOrRemoveRecentItem],
    );

    //Handles setting the recent items unique to each tenant user and work list.
    useEffect(() => {
        if (account?.identity.id && currentWorkList) {
            dispatch(
                setWorkListRecentItems(appLocalStorage.getRecentWorkListItems(tenantId, currentWorkList, account.identity.id)),
            );
        }
    }, [currentWorkList, tenantId, account, dispatch]);

    //Whenever we set an edit item, add the item to the recent items list.
    useEffect(() => {
        if (workListEditItem) addRecentItem(workListEditItem);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workListEditItem]);

    /**Handles getting the recent item for the current worklist, and passing information to onViewRecentItem callback.*/
    async function _onViewRecentItem(item: RecentWorkListItem, key: string, idFieldName: string) {
        if (onViewRecentItem && item.id) {
            const response = await dispatch(getRecentlyViewedWorkListItem({ tenantId, idFieldName, item })).unwrap();
            onViewRecentItem(response as RecentlyViewedWorkListItemResponse<T>, key);
        }
    }

    const recentItemMenuItems: IContextualMenuItem[] = recentItems.map((item) => ({
        key: item.id,
        text: item.displayName,
        subMenuProps: {
            items:
                recentlyViewedActions?.map((action) => ({
                    ...action,
                    disabled: action.getActionDisabled ? action.getActionDisabled(item) : false,
                    key: action.key,
                    onClick: () => {
                        _onViewRecentItem(item, action.key, action.idFilterFieldName);
                    },
                })) ?? [],
        },
    }));

    return (
        <DefaultButton
            onRenderText={(props) => {
                return loadingRecentItem ? <Spinner labelPosition="right" label="Loading..." /> : <Label>{props?.text}</Label>;
            }}
            disabled={!recentItems.length || loadingRecentItem}
            text={`Recently Viewed (${recentItems.length || 'None'})`}
            menuProps={{ items: recentItemMenuItems, styles: { list: { maxHeight: '50vh' } } }}
        />
    );
}
