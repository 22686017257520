export const settings = {
    logicalWidth: 1920,
    logicalHeight: 1175,
    actionColors: {
        light: {
            treatment: 0xaa160e,
            referred: 0xff8100,
            existing: 0x0e6e07,
            completed: 0x2424ff,
        },
        dark: {
            treatment: 0x891f1f,
            referred: 0xff8100,
            existing: 0x0c5106,
            completed: 0x0000e0,
        },
    },
    toothPadding: 10,
};

export const dentition = {
    primary: Array.from(Array(20).keys()).map((num) => num + 33),
    permanent: Array.from(Array(32).keys()).map((num) => num + 1),
};
