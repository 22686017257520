import Tooth from './Tooth';
import ToothOverlay from './ToothOverlay';
import * as PIXI from 'pixi.js-legacy';
import { ChartProcedureStatus } from 'api/models/chart.model';
import { IChartRenderProcedure } from 'api/models/tooth.model';

export default class Extraction extends ToothOverlay {
    private line1: PIXI.Graphics = new PIXI.Graphics();
    private line2: PIXI.Graphics = new PIXI.Graphics();

    constructor(tooth: Tooth, chartProcedure: IChartRenderProcedure) {
        super(tooth);
        this._setColor(chartProcedure);
        if (!this.tooth.isPontic()) {
            if (chartProcedure.status !== ChartProcedureStatus.Completed) {
                this._createNotCompletedGraphics();
            } else {
                this._createCompletedGraphics();
            }
        }
    }

    private _createNotCompletedGraphics() {
        const offset = 100;

        let noBackOffset = 0;
        const back = this.tooth._backContainer;
        if (!back?.children.length) noBackOffset = 100;

        const height = this.getIsBottomRow ? -this.tooth.height - noBackOffset : this.tooth.height + noBackOffset;
        const startingY = this.getIsBottomRow ? this.tooth.height + offset + noBackOffset : -this.tooth.height - noBackOffset;

        this.line1.position.set(0, startingY);
        this.line1.lineStyle(5, this.color).lineTo(this.tooth.getToothWidth, height);

        this.line2.position.set(this.tooth.getToothWidth, startingY);
        this.line2.lineStyle(5, this.color).lineTo(-this.tooth.getToothWidth, height);

        this.addChild(this.line1, this.line2);
    }

    private _createCompletedGraphics() {
        if (this.tooth._frontContainer) {
            if (!this.tooth.isImplantSupportedCrown() && !this.tooth.getDentureProcedure() && !this.tooth.isPontic()) {
                this.tooth._frontContainer._crownSprite.showSprite = false;
            }
            if (!this.tooth.implantProcedure()) {
                this.tooth._frontContainer._rootSprite.showSprite = false;
            }
        }
        if (this.tooth._surfacesContainer) this.tooth._surfacesContainer.alpha = 0;
        if (this.tooth._backContainer) this.tooth._backContainer.alpha = 0;
    }
}
