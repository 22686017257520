import { BatchField } from 'pages/components/Batch/BatchField';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { batchActions } from 'state/slices/tenant/batches.slice';
import { selectHasRequireBatchForChargesFeatureFlag } from 'state/slices/tenant/feature-flags.slice';

export default function ReapproveEncounterBatchField() {
    const dispatch = useDispatch();

    const hasBatchApproveFeature = useSelector(selectHasRequireBatchForChargesFeatureFlag);

    useEffect(() => {
        if (hasBatchApproveFeature) {
            dispatch(batchActions.setBatchFilters({ path: 'dateOfEntry', value: new Date().toISOString() }));
            dispatch(batchActions.setDisableBatchesFilters(true));
        }
    }, [hasBatchApproveFeature, dispatch]);

    if (!hasBatchApproveFeature) return null;

    return <BatchField textfieldProps={{ label: 'Current Batch' }} />;
}
