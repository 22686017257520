import { IContextualMenuProps, Pivot, PivotItem, PrimaryButton, ScrollablePane, Stack } from '@fluentui/react';
import ContactInfo from './ContactInfo';
import Insurance from './Insurance';
import AppointmentInfo from './AppointmentInfo';
import { useSelector, useTenantId } from 'hooks';
import { selectSelectedAppointmentData, selectSelectedAppointmentPatient } from 'state/slices/scheduling/scheduling.selectors';
import { useDispatch } from 'react-redux';
import reportActionLookup from 'state/slices/reporting/reportActionLookup';
import { ReportType } from 'api/models/embed-report.model';
import { selectCurrentScheduleAppointment } from 'state/slices/scheduling/schedule-appointment/schedule-appointment.selectors';

function MiniPatientAccount(): JSX.Element {
    const patient = useSelector(selectSelectedAppointmentPatient);
    const tenantId = useTenantId();
    const patientAppointment = useSelector(selectSelectedAppointmentData);
    const dispatch = useDispatch();

    const onPrintPatientRouteSlip = () => {
        if (patientAppointment?.id)
            dispatch(reportActionLookup[ReportType.PatientRouteSlip]({ tenantId, appointmentId: patientAppointment.id }));
    };
    const _printWalkoutStatement = () => {
        if (patientAppointment?.encounterId)
            dispatch(
                reportActionLookup[ReportType.Walkout]({
                    tenantId,
                    encounterId: patientAppointment.encounterId,
                }),
            );
    };

    const menuProps: IContextualMenuProps = {
        items: [
            {
                key: 'print-route-slip',
                text: 'Route Slip',
                onClick: onPrintPatientRouteSlip,
            },
            // {
            //     key: 'walkoutStatement',
            //     text: 'Walkout Statement',
            //     onClick: _printWalkoutStatement,
            // },
        ],
    };

    return (
        <Stack styles={{ root: { position: 'relative' } }} grow>
            <ScrollablePane>
                <Stack horizontal horizontalAlign="end" grow>
                    <PrimaryButton iconProps={{ iconName: 'Print' }} text="Print" split menuProps={menuProps} />
                </Stack>
                <Pivot>
                    <PivotItem headerText="Appointment">
                        <AppointmentInfo />
                    </PivotItem>
                    <PivotItem headerText="Contact Info">
                        <ContactInfo patient={patient} />
                    </PivotItem>
                    <PivotItem headerText="Payer">
                        <Insurance patient={patient} />
                    </PivotItem>
                </Pivot>
            </ScrollablePane>
        </Stack>
    );
}

export default MiniPatientAccount;
