import { createSlice } from '@reduxjs/toolkit';
import { teachingReducers } from './teaching.reducers';
import initialTeachingState from './teaching.state';

const teachingSlice = createSlice({ name: 'teaching', initialState: initialTeachingState, reducers: { ...teachingReducers } });

const { reducer, actions } = teachingSlice;

export const { setCurrentTutorial, setNextTutorialStep, setTutorialComplete, setPageForCurrentStep } = actions;

export default reducer;
