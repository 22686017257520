import { IDropdownOption, Stack } from '@fluentui/react';
import { IBillingStatusReportQuery } from 'api/models/billing-status.model';
import { ReportType } from 'api/models/embed-report.model';
import { Field } from 'components';
import { useSelector, useTenantId } from 'hooks';
import { getValidationError } from 'hooks/useValidation';
import { orderBy } from 'lodash';
import { useDispatch } from 'react-redux';
import reportActionLookup from 'state/slices/reporting/reportActionLookup';
import { selectReportProperties, selectReportingValidationErrors } from 'state/slices/reporting/reporting.selectors';
import { setBillingStatusProp, toggleBillingStatusId } from 'state/slices/reporting/reporting.slice';
import { selectTreatingProviderOptions } from 'state/slices/tenant/providers.slice';
import { classicDateOnly } from 'utils/dateOnly';
import ReportWrapper from '../ReportWrapper';

function BillingStatusAnalysisDetails() {
    const dispatch = useDispatch();
    const tenantId = useTenantId();
    const _providerOptions = useSelector(selectTreatingProviderOptions);
    const query = useSelector(selectReportProperties) as IBillingStatusReportQuery | undefined;

    const errors = useSelector(selectReportingValidationErrors);
    const _onViewReport = () => {
        if (query) dispatch(reportActionLookup[ReportType.BillingStatusAnalysisDetails]({ tenantId, ...query }));
    };

    const billedStatusOptions: IDropdownOption[] = orderBy(
        [
            { key: 1, text: 'Ready To Bill' },
            { key: 2, text: 'Filed' },
            { key: 3, text: 'Claim Created' },
            { key: 4, text: 'Rejected' },
            { key: 5, text: 'Accepted' },
            { key: 6, text: 'Payer Complete' },
            { key: 7, text: 'Balance Forward' },
            { key: 8, text: 'Collection ' },
            { key: 9, text: 'Refund' },
            { key: 10, text: 'Bad Debt' },
            { key: 11, text: 'Paid-Deposit Remaining' },
        ],
        'text',
        'asc',
    );

    return (
        <ReportWrapper
            validationConfig={[
                { fieldName: 'Start Date', validation: ['required'], value: query?.startDate },
                { fieldName: 'End Date', validation: ['required'], value: query?.endDate },
            ]}
            onViewReport={_onViewReport}
        >
            <Stack tokens={{ childrenGap: 10 }} grow horizontal wrap>
                <Stack.Item>
                    <Field.Date
                        label="Start Date"
                        required
                        value={query?.startDate ? classicDateOnly(query?.startDate, 'MM/dd/yyyy') : ''}
                        onChange={(ev, value) => {
                            const newDate = value ? classicDateOnly(value, 'yyyy-MM-dd') : undefined;
                            dispatch(setBillingStatusProp({ path: 'startDate', value: newDate }));
                        }}
                        hasDatePicker
                        errorMessage={getValidationError(errors, 'Start Date') ? 'Start date is required.' : undefined}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.Date
                        label="End Date"
                        required
                        value={query?.endDate ? classicDateOnly(query?.endDate, 'MM/dd/yyyy') : ''}
                        onChange={(ev, value) => {
                            const newDate = value ? classicDateOnly(value, 'yyyy-MM-dd') : undefined;
                            dispatch(setBillingStatusProp({ path: 'endDate', value: newDate }));
                        }}
                        isReasonable
                        disabled={!query?.startDate}
                        minReasonableErrorMessage="End date must be after start date."
                        minReasonableDate={query?.startDate ? new Date(classicDateOnly(query?.startDate)) : undefined}
                        hasDatePicker
                        errorMessage={getValidationError(errors, 'End Date') ? 'End date is required.' : undefined}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.SearchCombo
                        placeholder="(Select Provider)"
                        label="Providers"
                        multiSelect
                        options={_providerOptions}
                        style={{ minWidth: 300 }}
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(toggleBillingStatusId({ path: 'responsibleProvider', id: option.key as string }));
                        }}
                        selectedKey={query?.responsibleProvider}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.SearchCombo
                        placeholder="(Select)"
                        label="Billing Status"
                        multiSelect
                        style={{ minWidth: 300 }}
                        maxResults={25}
                        options={billedStatusOptions}
                        onChange={(ev, option) => {
                            if (option?.key) dispatch(toggleBillingStatusId({ path: 'billStatus', id: option.key as string }));
                        }}
                        selectedKey={query?.billStatus}
                    />
                </Stack.Item>
            </Stack>
        </ReportWrapper>
    );
}

export default BillingStatusAnalysisDetails;
