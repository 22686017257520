import { Stack, Icon, Text } from '@fluentui/react';
import { NeutralColors } from '@fluentui/theme';
import { IClinicalAlert, ClinicalAlertType, clinicalAlertTypeDisplayName } from 'api/models/clinical-alert.model';
import { sortBy, map } from 'lodash';
import ClinicalAlertDataItem from './ClinicalAlertDataItem';

type AlertCalloutSectionProps = {
    alerts: IClinicalAlert[];
    type: ClinicalAlertType;
};

const alertIconLookup: Record<ClinicalAlertType, string | undefined> = {
    [ClinicalAlertType.PreMedication]: ClinicalAlertType.PreMedication,
    [ClinicalAlertType.PatientAlert]: 'UserWarning',
};

const sortClinicalAlertsByType: Record<ClinicalAlertType, (items: IClinicalAlert[]) => IClinicalAlert[]> = {
    [ClinicalAlertType.PreMedication]: (items: IClinicalAlert[]) => sortBy(items, (item) => item.references?.problemName),
    [ClinicalAlertType.PatientAlert]: (items: IClinicalAlert[]) => sortBy(items, (item) => item?.references?.descriptions),
};

export default function AlertCalloutSection({ alerts, type }: AlertCalloutSectionProps) {
    const alertsByType = sortClinicalAlertsByType[type](alerts.filter((alert) => alert.type === type));
    const sectionName = clinicalAlertTypeDisplayName[type] ?? 'Unknown Alerts';
    if (!alertsByType.length) return null;
    return (
        <Stack>
            <Stack
                horizontal
                verticalAlign="center"
                styles={{
                    root: {
                        background: '#eecb71c7',
                        padding: 2,
                        paddingLeft: 5,
                    },
                }}
            >
                {alertIconLookup[type] && (
                    <Icon iconName={alertIconLookup[type]} style={{ height: 15, width: 15, marginRight: 5 }} />
                )}
                <Text style={{ color: NeutralColors.gray150 }}>{sectionName}</Text>
            </Stack>
            <Stack tokens={{ childrenGap: 7 }}>
                {map(alertsByType, (data, index) => {
                    return <ClinicalAlertDataItem index={index} key={data.id} alert={data} />;
                })}
            </Stack>
        </Stack>
    );
}
