import IForm from 'api/models/form';

export interface ICariesRiskChildForm extends IForm {
    data: ICariesRiskChildQuestions;
}

export enum RiskType {
    None = '',
    Low = 'Low',
    Moderate = 'Moderate',
    High = 'High',
}

export interface ICariesRiskChildQuestions {
    //*Contributing Conditions
    fluorideExposure?: RiskType;
    sugaryStarchyFoodsOrDrinks?: RiskType;
    govtProgramsEligibility?: RiskType;
    eligibleForGovernmentPrograms?: RiskType;
    cariesLesions?: RiskType;
    dentalHome?: RiskType;

    //*General Health Conditions
    specialHealthcareNeeds?: RiskType;

    //*Clinical Conditions
    cariesExperience?: RiskType;
    visuallyEvidentRestorationsCariousLesions?: RiskType;
    nonCavitatedIncipientCariousLesions?: RiskType;
    cavitatedOrNonCavitatedIncipient?: RiskType;
    teethMissingDueToCaries?: RiskType;
    visiblePlaque?: RiskType;
    dentalOrthodonticAppliancesPresent?: RiskType;
    salivaryFlow?: RiskType;

    //*Patient Instructions
    additionalPatientInstructions?: string;

    score?: RiskType;
}
