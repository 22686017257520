import { RootState } from 'state/store';
import { createSelector, Dictionary } from '@reduxjs/toolkit';
import IPatientAllergies, { IAllergen, IAllergy, IAllergyReaction } from 'api/models/patient-allergy.model';
import { format } from 'date-fns';
import { LoadingStatuses } from 'interfaces/loading-statuses';
import { isArray, map, orderBy, sortBy } from 'lodash';
import { IDropdownOption } from '@fluentui/react';

export const selectPatientAllergiesState = (state: RootState) => state.patient.allergies;
export const selectPatientAllergies = (state: RootState): IPatientAllergies | undefined => state.patient.allergies.data;
export const selectAllergyReactions = (state: RootState): Dictionary<IAllergyReaction> =>
    state.patient.allergies.allergyReactions;
export const selectPatientNKDA = (state: RootState): boolean | undefined => state.patient.allergies.data?.nkda;
export const selectIsAllergyPanelOpen = (state: RootState): boolean => state.patient.allergies.isAllergyPanelOpen;
export const selectSavingAllergies = (state: RootState): LoadingStatuses => state.patient.allergies.saving;
export const selectAllergenLookupsLoading = (state: RootState): LoadingStatuses => state.patient.allergies.loadingLookups;
export const selectAllergiesError = (state: RootState) => state.patient.allergies.error;
export const selectAllergiesRequiredErrors = createSelector(selectAllergiesError, (error) => {
    if (isArray(error)) {
        return error.filter((e) => e?.type === 'Required').map((e) => e?.message);
    }
    return [];
});

export const selectAllergiesToAdd = (state: RootState): IAllergy[] => state.patient.allergies.allergiesToAdd;

export const selectAllergyReactionOptions = createSelector(selectAllergyReactions, (allergyReactions) => {
    return orderBy(
        map(
            allergyReactions,
            (reaction): IDropdownOption => ({
                key: reaction && reaction.snomedCode ? reaction.snomedCode : '',
                text: reaction?.name ? reaction.name : '',
            }),
        ),
        'text',
        'asc',
    );
});

export const showAllergyHistory = createSelector(selectPatientAllergiesState, (state) => state.showHistory);

export interface MappedAllergy {
    name: string;
    criticality: string;
    reaction: string;
    onsetDate: string;
    isDeleted: boolean;
}
export const selectAllergyTableData = createSelector(selectPatientAllergies, showAllergyHistory, (data, showHistory) => {
    const mappedAllergies = data?.allergies
        ? data.allergies.filter((allergy) => (showHistory ? true : !allergy?.isDeleted)).map(mapAllergyData)
        : [];
    return mappedAllergies;
});

function mapAllergyData(allergy: IAllergy): MappedAllergy {
    function getReactionString() {
        let value = '';
        if (allergy.reactions?.length) {
            allergy.reactions.forEach((reaction, index, arry) => {
                const severity = reaction.severity ? `, ${reaction.severity}` : ''; // Severity not required, don't show comma/space
                value += `${reaction.name}${severity}\n   ${index !== arry.length - 1 ? '| ' : ''}`;
            });
        }
        return value;
    }

    function getCriticalityString() {
        const lookupCriticalityDisplayName: Record<string, string> = {
            low: 'low',
            high: 'high',
            'unable-to-assess': 'unable to assess',
        };

        return allergy.criticality ? lookupCriticalityDisplayName[allergy.criticality] : '';
    }

    const mappedAllergy: MappedAllergy = {
        name: allergy.allergenName ?? '',
        reaction: getReactionString(),
        criticality: getCriticalityString(),
        onsetDate: allergy.onsetDate ? format(new Date(allergy.onsetDate), 'MM/dd/yyyy') : '',
        isDeleted: allergy.isDeleted,
    };

    return mappedAllergy;
}
