import Tooth from './Tooth';
import ToothOverlay from './ToothOverlay';
import * as PIXI from 'pixi.js-legacy';

export default class Denture extends ToothOverlay {
    constructor(tooth: Tooth) {
        super(tooth, undefined);
        this.zIndex = -1;

        const denture = this.tooth.getDentureProcedure();
        const pontic = this.tooth.isPontic();
        const abutment = this.tooth.isAbutmentRetainerCrown();

        if (denture) {
            this._setColor(denture);
        } else if (pontic) {
            this._setColor(pontic);
        } else if (abutment) this._setColor(abutment);

        if (
            this.tooth.nextTooth?.isPontic() ||
            this.tooth.nextTooth?.isAbutmentRetainerCrown() ||
            this.tooth.nextTooth?.hasDenture ||
            this.tooth.previousTooth?.isPontic() ||
            this.tooth.previousTooth?.isAbutmentRetainerCrown() ||
            this.tooth.previousTooth?.hasDenture
        )
            this._createGraphics(tooth);
    }

    private get getHeight() {
        return this.getIsBottomRow ? 390 : -270;
    }

    private _createGraphics(tooth: Tooth) {
        if (tooth._frontContainer) {
            this.tooth.addColorOverlayToToothPart(tooth._frontContainer._crownSprite);

            if (!tooth.hasDenture) {
                const line = new PIXI.Graphics();
                line.lineStyle(10, this.color).moveTo(0, this.getHeight).lineTo(this.tooth.getToothWidth, this.getHeight);
                this.addChild(line);
            }
        }
    }
}
