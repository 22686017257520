import { createAsyncThunk } from '@reduxjs/toolkit';
import IPatientAppointment, { IPatientAppointmentQuery } from 'api/models/Scheduling/patientAppointment.model';
import IAppointmentAllocations from 'api/models/Scheduling/allocation.model';
import schedulingApi from 'api/scheduling.api';
import { AppThunk, RootState } from 'state/store';
import { format } from 'date-fns';
import dentalApi from 'api/dental.api';
import { push } from 'connected-react-router';
import { setLoadingCreateEncounter } from '../patient/patient.slice';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { createNewEncounterLoadingLookup } from '../patient/patient.selectors';
import {
    addOrUpdatePatientAppointmentAllocation,
    updatePatientAppointmentAllocationsEncounterStatus,
} from './clinical-huddle.slice';
import UpdatedEncounter from 'api/models/updated-encounter.model';
import { getPatientIncompleteEncounters } from '../encounter/encounter.actions';
import { appInsights } from 'index';

export const getWeekOfAppointments = createAsyncThunk<IAppointmentAllocations, { tenantId: string }, { state: RootState }>(
    'getWeekOfAppointments',
    async ({ tenantId }, { getState }) => {
        const state = getState();

        const date = state.clinicalHuddle.weekOfAppointments.date;
        const dateToUse = date ? new Date(date) : new Date();
        const providerId = state.clinicalHuddle.weekOfAppointments.providerId;

        const query: IPatientAppointmentQuery = {
            date: format(dateToUse, 'yyyy-MM-dd'),
            providerId,
            includeAlerts: true,
        };
        const req = await schedulingApi.getPatientAppointmentsByParameters(tenantId, query);
        return req.data;
    },
);

export const onStartEncounter = createAsyncThunk<
    void,
    {
        tenantId: string;
        patientId: string;
        appointmentId: string;
        locationOfCareId: string;
        appointment?: IPatientAppointment;
    },
    { state: RootState }
>(
    'onStartEncounter',
    async ({ appointmentId, locationOfCareId, tenantId, patientId }, { dispatch, getState, rejectWithValue }) => {
        try {
            const _createNewEncounterLoadingLookup = createNewEncounterLoadingLookup(getState());
            const isLoadingCreateEncounter = _createNewEncounterLoadingLookup[appointmentId] === LoadingStatus.Pending;
            if (isLoadingCreateEncounter) return;

            dispatch(setLoadingCreateEncounter({ appointmentId, loadingStatus: LoadingStatus.Pending }));

            appInsights.trackEvent({ name: 'Start Encounter', properties: { tenantId, appointmentId } });

            //Create encounter
            const { data: encounterId } = await dentalApi.starPatientEncounter(tenantId, appointmentId, {
                locationOfCareId,
                patientId,
            });

            await dispatch(getPatientIncompleteEncounters({ tenantId, patientId }));
            dispatch(push(`/${tenantId}/patient/${patientId}/encounter/${encounterId}`));

            dispatch(setLoadingCreateEncounter({ appointmentId, loadingStatus: LoadingStatus.Completed }));
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

//SignalR hook can only take thunk actions :(
export const upsertPatientAppointmentAllocations =
    (appointment: IPatientAppointment): AppThunk<void> =>
    (dispatch) => {
        dispatch(addOrUpdatePatientAppointmentAllocation(appointment));
    };

export const upsertPatientAppointmentAllocationEncounterData =
    (encounter: UpdatedEncounter): AppThunk<void> =>
    (dispatch) => {
        dispatch(updatePatientAppointmentAllocationsEncounterStatus(encounter));
    };
