import { IChartProcedure } from 'api/models/chart.model';
import { flatten, isArray, uniqBy } from 'lodash';
import { createSelector } from 'reselect';
import { selectDiagnoses } from 'state/slices/tenant/diagnoses.slice';
import { RootState } from 'state/store';
import { PipelineError } from '../pipeline';
import { ConflictError } from '../procedure-panel/procedure-panel.selectors';
import ProcedureDiagnosisConflictRulesPipeline from '../procedureDiagnosisConflictRules/procedureDiagnosisConflictRules.pipeline';
import { chartProceduresData } from '../procedures/procedures.selectors';

export const diagnosesPanelIsOpen = (state: RootState) => state.charting.diagnosesPanel.isOpen;
export const diagnosesPanelSelectedChartProcedure = (state: RootState) => state.charting.diagnosesPanel.selectedChartProcedures;
export const selectDiagnosesPanel = (state: RootState) => state.charting.diagnosesPanel;

export const selectDiagnosisPanelConflictErrors = createSelector(
    diagnosesPanelSelectedChartProcedure,
    selectDiagnoses,
    chartProceduresData,
    selectDiagnosesPanel,
    (chartProcedure, diagnosisData, currentChartProcedures, panel) => {
        const errors: ConflictError[] = [];

        const currentProcs: IChartProcedure[] = chartProcedure
            ? isArray(chartProcedure)
                ? chartProcedure.map((p) => ({ ...p, diagnosisCodes: panel.selectedDiagnoses }))
                : [{ ...chartProcedure, diagnosisCodes: panel.selectedDiagnoses }]
            : [];
        const selectedTeeth = flatten(currentProcs.map((proc) => proc.toothIds ?? []));

        new ProcedureDiagnosisConflictRulesPipeline({
            chartProcedures: currentProcs,
            currentProcedures: currentChartProcedures,
            diagnosisData,
            selectedTeeth,
        }).next((items, pipelineErrors) => {
            if (pipelineErrors) {
                const ruleErrors: ConflictError[] = uniqBy(
                    pipelineErrors.map((e) => {
                        switch (e.type) {
                            case PipelineError.DiagnosisRequiresSupportingDiagnosis:
                                return {
                                    message: `${e.data.diagnosisCode} requires that another supporting DX, other than Z01.20, has been added.`,
                                };
                            default:
                                return { message: 'An unknown conflict error occurred' };
                        }
                    }),
                    'message',
                );
                errors.push(...ruleErrors);
            }
        });

        return errors;
    },
);
