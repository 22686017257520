import IWorkListAppointmentView from 'api/models/worklist-appointment-view';
import IWorkListEncounterView from 'api/models/worklist-encounter-view';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import { useSelector } from 'hooks';
import { selectLocationsOfCare } from 'state/slices/scheduling/scheduling.selectors';

const locationOfCareName: ISortableColumn<IWorkListEncounterView | IWorkListAppointmentView> = {
    key: 'locationOfCareName',
    minWidth: 100,
    maxWidth: 100,
    isResizable: true,
    name: 'Location Of Care',
    fieldName: 'locationOfCareName',
    onRender: RenderLocationOfCareName,
};

function RenderLocationOfCareName(item: IWorkListEncounterView | IWorkListAppointmentView | undefined) {
    const locationsOfCare = useSelector(selectLocationsOfCare);

    const getLocationOfCare = () => {
        return locationsOfCare.find((res) =>
            res.id ? res.id === (item as IWorkListEncounterView)?.encounter?.locationOfCareId : '',
        );
    };

    return item?.locationOfCareName ?? getLocationOfCare()?.displayName ?? 'N/A';
}

export default locationOfCareName;
