import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { IUserIdentity } from 'api/models/account.model';
import { IPatientEncounter } from 'api/models/encounter.model';
import IPatientPrescription from 'api/models/patient-prescription';
import IPatientAppointment from 'api/models/Scheduling/patientAppointment.model';
import UpdatedEncounter from 'api/models/updated-encounter.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { SyncStatus } from 'interfaces/syncing-statuses';
import { isEqual } from 'lodash';
import {
    getCurrentPatientEncounterAppointment,
    getPatientEncounterWithTasks,
    updatePatientEncounter,
    getPatientIncompleteEncounters,
    getEncounterPatientPrescriptions,
    updateEncounterProviders,
} from './encounter.actions';
import { EncounterState } from './encounter.state';

export const encounterReducers = {
    cleanupChartPatientEncounterAppointment(state: EncounterState): void {
        state.patientEncounterAppointment = undefined;
    },
    setEncounterAppointment: (state: EncounterState, { payload }: PayloadAction<IPatientAppointment>): void => {
        if (state.patientEncounterAppointment?.id === payload.id) state.patientEncounterAppointment = payload;
    },
    setEncounterDataProp: (
        state: EncounterState,
        action: PayloadAction<{ path: keyof IPatientEncounter; value: string | boolean | undefined }>,
    ): void => {
        const { path, value } = action.payload;
        if (state.patientEncounter) (state.patientEncounter[path] as unknown) = value;
    },
    cleanupPatientEncounter: (state: EncounterState): void => {
        state.patientEncounter = undefined;
        state.patientEncounterPrescriptions = [];
        state.patientEncounterUserIdentities = {};
        state.loadingIncompletePatientEncounters = LoadingStatus.Idle;
    },
    setEncounterUserIdentity: (state: EncounterState, action: PayloadAction<IUserIdentity>): void => {
        if (action.payload.id) {
            state.patientEncounterUserIdentities[action.payload.id] = action.payload;
        }
    },
    addPatientEncounterPrescription: (state: EncounterState, action: PayloadAction<IPatientPrescription>): void => {
        state.patientEncounterPrescriptions = [...state.patientEncounterPrescriptions, action.payload];
    },
    setPatientEncounter: (state: EncounterState, { payload: encounter }: PayloadAction<UpdatedEncounter>): void => {
        if (encounter.id == state.patientEncounter?.id) {
            //Remove all null properties from updated encounter model.
            Object.keys(encounter).forEach((key: string) => {
                const typedKey = key as keyof UpdatedEncounter;
                if (encounter[typedKey] === null) delete encounter[typedKey];
            });
            state.patientEncounter = { ...state.patientEncounter, ...encounter };
        }
    },
    updateIncompleteEncounter: (
        state: EncounterState,
        { payload: { encounter, index } }: PayloadAction<{ encounter: UpdatedEncounter; index: number }>,
    ): void => {
        if (state.patientIncompleteEncounters && state.patientIncompleteEncounters[index].encounter) {
            //Remove all null properties from updated encounter model.
            Object.keys(encounter).forEach((key: string) => {
                const typedKey = key as keyof UpdatedEncounter;
                if (encounter[typedKey] === null) delete encounter[typedKey];
            });

            state.patientIncompleteEncounters[index].encounter = {
                ...state.patientIncompleteEncounters[index].encounter,
                ...encounter,
            };
        }
    },
};

export const encounterExtraReducers = (
    builder: ActionReducerMapBuilder<EncounterState>,
): ActionReducerMapBuilder<EncounterState> =>
    builder
        .addCase(updateEncounterProviders.pending, (state) => {
            state.savingPatientEncounterProviders = LoadingStatus.Pending;
        })
        .addCase(updateEncounterProviders.fulfilled, (state, { payload }) => {
            state.savingPatientEncounterProviders = LoadingStatus.Completed;
            state.patientEncounter = payload;

            //Update the encounter with the new information.
            if (state.patientIncompleteEncounters?.length) {
                const encounterIndex = state.patientIncompleteEncounters.findIndex((item) => item.encounter?.id === payload.id);
                if (encounterIndex > -1) state.patientIncompleteEncounters[encounterIndex].encounter = payload;
            }
        })
        .addCase(updateEncounterProviders.rejected, (state) => {
            state.savingPatientEncounterProviders = LoadingStatus.Failed;
        })
        .addCase(getCurrentPatientEncounterAppointment.pending, (state) => {
            state.loadingPatientEncounterAppointment = LoadingStatus.Pending;
        })
        .addCase(getCurrentPatientEncounterAppointment.fulfilled, (state, action) => {
            state.loadingPatientEncounterAppointment = LoadingStatus.Completed;
            state.patientEncounterAppointment = action.payload;
        })
        .addCase(getCurrentPatientEncounterAppointment.rejected, (state) => {
            state.loadingPatientEncounterAppointment = LoadingStatus.Failed;
        })
        .addCase(getPatientEncounterWithTasks.pending, (state) => {
            state.loadingPatientEncounter = LoadingStatus.Pending;
        })
        .addCase(getPatientEncounterWithTasks.fulfilled, (state, action) => {
            state.loadingPatientEncounter = LoadingStatus.Completed;
            if (action.payload?.encounter) state.patientEncounter = action.payload.encounter;
            if (action.payload?.tasks) state.patientEncounterTasks = action.payload.tasks;
        })
        .addCase(getPatientEncounterWithTasks.rejected, (state) => {
            state.loadingPatientEncounter = LoadingStatus.Failed;
        })
        .addCase(updatePatientEncounter.pending, (state) => {
            state.loadingPatientEncounter = LoadingStatus.Pending;
            state.savingPatientEncounter = SyncStatus.Pending;
        })
        .addCase(updatePatientEncounter.fulfilled, (state, action) => {
            if (isEqual(state.patientEncounter, action.payload)) {
                state.patientEncounter = action.payload;
            } else if (state.patientEncounter) {
                state.patientEncounter = { ...state.patientEncounter, _etag: action.payload._etag };
            }
            state.loadingPatientEncounter = LoadingStatus.Completed;
            state.savingPatientEncounter = SyncStatus.Saved;
        })
        .addCase(updatePatientEncounter.rejected, (state) => {
            state.savingPatientEncounter = SyncStatus.Failed;
        })
        .addCase(getPatientIncompleteEncounters.pending, (state) => {
            state.loadingIncompletePatientEncounters = LoadingStatus.Pending;
        })
        .addCase(getPatientIncompleteEncounters.fulfilled, (state, action) => {
            const incompleteEncounters = action.payload;
            state.loadingIncompletePatientEncounters = LoadingStatus.Completed;
            state.patientIncompleteEncounters = incompleteEncounters;
        })
        .addCase(getPatientIncompleteEncounters.rejected, (state) => {
            state.loadingIncompletePatientEncounters = LoadingStatus.Failed;
        })
        .addCase(getEncounterPatientPrescriptions.pending, (state) => {
            state.loadingPatientEncounterPrescriptions = LoadingStatus.Pending;
        })
        .addCase(getEncounterPatientPrescriptions.fulfilled, (state, action) => {
            const incompleteEncounters = action.payload;
            state.loadingPatientEncounterPrescriptions = LoadingStatus.Completed;
            state.patientEncounterPrescriptions = incompleteEncounters;
        })
        .addCase(getEncounterPatientPrescriptions.rejected, (state) => {
            state.loadingPatientEncounterPrescriptions = LoadingStatus.Failed;
        });
