import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { IBillingProcedure } from 'api/models/billing-procedure.model';
import { ChartProcedureStatus } from 'api/models/chart.model';
import {
    IChartTreatmentPlan,
    IChartTreatmentPlanPhase,
    IChartTreatmentPlanPhaseProcedure,
} from 'api/models/treatment-plan.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { cloneDeep } from 'lodash';
import { v4 as uuid } from 'uuid';
import { ChartingState } from '../chart/chart.slice';
import {
    autoUpdateChartTreatmentPlan,
    createChartTreatmentPlan,
    getChartTreatmentPlanById,
    getChartTreatmentPlans,
    getTreatmentPlanBillingProcedures,
    updateChartTreatmentPlan,
    updateChartTreatmentPlans,
} from './treatmentPlans.actions';

import ErrorTypes from 'state/errorTypes';
import { reducers as predeterminationReducers } from './predetermination/predetermination.reducers';

const treatmentPlanReducers = {
    discardCurrentTreatmentPlan: (state: ChartingState): void => {
        if (state.treatmentPlans.currentTreatmentPlan) {
            state.treatmentPlans.currentTreatmentPlan.isDeleted = true;
        }
    },
    deleteTreatmentPlanPhase: (state: ChartingState, action: PayloadAction<IChartTreatmentPlanPhase>): void => {
        const phase = action.payload;
        if (state.treatmentPlans.currentTreatmentPlan) {
            state.treatmentPlans.currentTreatmentPlan.phases = state.treatmentPlans.currentTreatmentPlan?.phases
                ? state.treatmentPlans.currentTreatmentPlan?.phases
                      .filter((p) => p.id !== phase.id)
                      .map((phase, index) => ({ ...phase, displayName: `Phase ${index + 1}` }))
                : [];
            if (state.treatmentPlans.currentTreatmentPlan.procedures?.length)
                state.treatmentPlans.currentTreatmentPlan.procedures =
                    state.treatmentPlans.currentTreatmentPlan.procedures.filter((procedure) => procedure.phaseId !== phase.id);
        }
    },
    setSelectedTreatmentPlan: (state: ChartingState, action: PayloadAction<string>): void => {
        const treatmentPlanId = action.payload;
        state.treatmentPlans.selectedTreatmentPlan = treatmentPlanId;
    },
    setCurrentChartTreatmentPlan: (state: ChartingState, action: PayloadAction<IChartTreatmentPlan>): void => {
        const treatmentPlan = action.payload;
        state.treatmentPlans.currentTreatmentPlan = treatmentPlan;
    },
    setPatientReturnDate: (state: ChartingState, action: PayloadAction<{ phaseId: string; value: string }>): void => {
        const { phaseId, value } = action.payload;
        const indexOfPhase = state.treatmentPlans.currentTreatmentPlan?.phases?.findIndex((phase) => phase.id === phaseId) ?? -1;

        if (indexOfPhase > -1 && state.treatmentPlans.currentTreatmentPlan?.phases) {
            const phaseToUpdate: IChartTreatmentPlanPhase = {
                ...state.treatmentPlans.currentTreatmentPlan.phases[indexOfPhase],
                returnDate: value,
            };
            state.treatmentPlans.currentTreatmentPlan.phases[indexOfPhase] = phaseToUpdate;
        }
    },
    setPhaseProceduresStatus: (
        state: ChartingState,
        action: PayloadAction<{ procedureIds: string[]; status: keyof typeof ChartProcedureStatus }>,
    ): void => {
        const { procedureIds, status } = action.payload;
        procedureIds.forEach((procedureId) => {
            if (state.treatmentPlans.currentTreatmentPlan?.procedures) {
                const indexOfProcedure = state.treatmentPlans.currentTreatmentPlan.procedures?.findIndex(
                    (procedure) => procedure.id === procedureId,
                );
                state.treatmentPlans.currentTreatmentPlan.procedures[indexOfProcedure] = {
                    ...state.treatmentPlans.currentTreatmentPlan.procedures[indexOfProcedure],
                    status,
                };
            }
        });
    },
    moveProceduresToPhase: (
        state: ChartingState,
        action: PayloadAction<{
            procedures: IBillingProcedure[] | IChartTreatmentPlanPhaseProcedure[];
            phaseId?: string | 'pending';
        }>,
    ): void => {
        const { procedures, phaseId } = action.payload;
        const id = phaseId ?? uuid();
        if (phaseId === 'pending') {
            if (state.treatmentPlans.currentTreatmentPlan) {
                const phaseProcedures = cloneDeep(state.treatmentPlans.currentTreatmentPlan?.procedures);

                const filteredProcedures = phaseProcedures?.length
                    ? phaseProcedures?.filter((procedure) => !procedures.find((proc) => proc.id === procedure.id))
                    : [];

                state.treatmentPlans.currentTreatmentPlan.procedures = [...filteredProcedures];
            }
        } else {
            //update procedure.phaseIds
            const phaseProcedures = cloneDeep(state.treatmentPlans.currentTreatmentPlan?.procedures);
            const newProcedures: IChartTreatmentPlanPhaseProcedure[] = procedures.map((procedure) => {
                return { ...procedure, phaseId: id };
            });

            const filteredProcedures = phaseProcedures?.length
                ? phaseProcedures?.filter((procedure) => !newProcedures.find((proc) => proc.id === procedure.id))
                : [];
            if (state.treatmentPlans.currentTreatmentPlan) {
                if (!phaseId) {
                    // create phase
                    const newPhase: IChartTreatmentPlanPhase = {
                        id,
                        displayName: `Phase ${(state.treatmentPlans.currentTreatmentPlan.phases?.length ?? 0) + 1}`,
                        isDeleted: false,
                    };
                    state.treatmentPlans.currentTreatmentPlan.phases = [
                        ...(state.treatmentPlans.currentTreatmentPlan.phases ?? []),
                        newPhase,
                    ];
                }
                state.treatmentPlans.currentTreatmentPlan.procedures = [...filteredProcedures, ...newProcedures];
            }
        }
    },
    setFinancialNotes: (state: ChartingState, action: PayloadAction<string>): void => {
        const financialNotes = action.payload;
        if (state.treatmentPlans.currentTreatmentPlan)
            state.treatmentPlans.currentTreatmentPlan.financialOptionsNote = financialNotes;
    },
    setSignature: (state: ChartingState, action: PayloadAction<string>): void => {
        const signature = action.payload;
        if (state.treatmentPlans.currentTreatmentPlan) {
            if (state.treatmentPlans.currentTreatmentPlan.signature) {
                state.treatmentPlans.currentTreatmentPlan.signature = signature;
            } else {
                state.treatmentPlans.currentTreatmentPlan['signature'] = signature;
            }

            if (state.treatmentPlans.currentTreatmentPlan.signature && state.treatmentPlans.currentTreatmentPlan.refusedToSign) {
                state.treatmentPlans.currentTreatmentPlan.refusedToSign = false;
            }
        }
    },
    setTreatmentPlanIsSigning: (state: ChartingState, action: PayloadAction<boolean>): void => {
        state.treatmentPlans.isSigning = action.payload;
    },
    setPatientRefusedToSign: (state: ChartingState, action: PayloadAction<boolean>): void => {
        const refusedToSign = action.payload;
        if (state.treatmentPlans.currentTreatmentPlan) {
            if (typeof state.treatmentPlans.currentTreatmentPlan.refusedToSign === 'boolean') {
                if (refusedToSign) {
                    state.treatmentPlans.currentTreatmentPlan.signature = '';
                }
                state.treatmentPlans.currentTreatmentPlan.refusedToSign = refusedToSign;
            } else {
                state.treatmentPlans.currentTreatmentPlan['refusedToSign'] = refusedToSign;
            }
        }
    },
    setSignedDate: (state: ChartingState, action: PayloadAction<string>): void => {
        const signedDate = action.payload;
        if (state.treatmentPlans.currentTreatmentPlan) {
            if (state.treatmentPlans.currentTreatmentPlan.refusedToSign !== undefined) {
                state.treatmentPlans.currentTreatmentPlan.signedDate = signedDate;
            } else {
                state.treatmentPlans.currentTreatmentPlan['signedDate'] = signedDate;
            }
        }
    },
    cleanupPendingProcedures: (state: ChartingState): void => {
        state.treatmentPlans.loadingPendingProcedures = LoadingStatus.Idle;
        state.treatmentPlans.pendingProcedures = [];
    },
    cleanupCurrentTreatmentPlan: (state: ChartingState): void => {
        state.treatmentPlans.currentTreatmentPlan = undefined;
        state.treatmentPlans.saving = LoadingStatus.Idle;
        state.treatmentPlans.signedOrRefused = false;
    },
    cleanupChartTreatmentPlans: (state: ChartingState): void => {
        state.treatmentPlans.data = [];
    },

    setAddAndUpdateTreatmentPlan: (state: ChartingState, action: PayloadAction<IChartTreatmentPlan | undefined>): void => {
        if (state.treatmentPlans.currentTreatmentPlan?.id === action.payload?.id) {
            state.treatmentPlans.currentTreatmentPlan = action.payload;
        }
        state.treatmentPlans.data = state.treatmentPlans.data?.map((treatmentPlan) => {
            if (treatmentPlan.id === action.payload?.id) {
                return action.payload;
            }
            return treatmentPlan;
        });
    },
    ...predeterminationReducers,
};

export const treatmentPlanExtraReducers = (
    builder: ActionReducerMapBuilder<ChartingState>,
): ActionReducerMapBuilder<ChartingState> => {
    return (
        builder
            // [GET] Treatment Plans
            .addCase(getChartTreatmentPlans.pending, (state) => {
                state.treatmentPlans.loading = LoadingStatus.Pending;
            })
            .addCase(getChartTreatmentPlans.fulfilled, (state, action) => {
                state.treatmentPlans.loading = LoadingStatus.Completed;
                state.treatmentPlans.data = action.payload;
                state.treatmentPlans.error = undefined;
            })
            .addCase(getChartTreatmentPlans.rejected, (state) => {
                state.treatmentPlans.loading = LoadingStatus.Failed;
            })

            // [GET] Treatment Plan by Id
            .addCase(getChartTreatmentPlanById.pending, (state) => {
                state.treatmentPlans.loadingCurrentPlan = LoadingStatus.Pending;
            })
            .addCase(getChartTreatmentPlanById.fulfilled, (state, action) => {
                state.treatmentPlans.loadingCurrentPlan = LoadingStatus.Completed;
                state.treatmentPlans.currentTreatmentPlan = action.payload;
                state.treatmentPlans.error = undefined;
            })
            .addCase(getChartTreatmentPlanById.rejected, (state) => {
                state.treatmentPlans.loadingCurrentPlan = LoadingStatus.Failed;
            })

            // [POST] Create Treatment Plan
            .addCase(createChartTreatmentPlan.pending, (state) => {
                state.saving = LoadingStatus.Pending;
            })
            .addCase(createChartTreatmentPlan.fulfilled, (state, action) => {
                state.saving = LoadingStatus.Completed;
                state.error = undefined;
                state.treatmentPlans.currentTreatmentPlan = action.payload;
                if (state.treatmentPlans.data?.length) {
                    state.treatmentPlans.data = [...state.treatmentPlans.data, action.payload];
                } else {
                    state.treatmentPlans.data = [action.payload];
                }
            })
            .addCase(createChartTreatmentPlan.rejected, (state, action) => {
                state.saving = LoadingStatus.Failed;
                state.treatmentPlans.error = action.payload as ErrorTypes;
            })

            // [GET] Get billing procedures
            // .addCase(getBillingProcedures.pending, (state) => {
            //     state.treatmentPlans.loadingPendingProcedures = LoadingStatus.Pending;
            // })
            // .addCase(getBillingProcedures.fulfilled, (state, action) => {
            //     const pendingProcedures = action.payload;
            //     state.treatmentPlans.loadingPendingProcedures = LoadingStatus.Completed;
            //     state.treatmentPlans.pendingProcedures = pendingProcedures;
            // })
            // .addCase(getBillingProcedures.rejected, (state) => {
            //     state.treatmentPlans.loadingPendingProcedures = LoadingStatus.Failed;
            // })
            .addCase(getTreatmentPlanBillingProcedures.pending, (state) => {
                state.treatmentPlans.loadingPendingProcedures = LoadingStatus.Pending;
            })
            .addCase(getTreatmentPlanBillingProcedures.fulfilled, (state, action) => {
                const pendingProcedures = action.payload;
                state.treatmentPlans.loadingPendingProcedures = LoadingStatus.Completed;
                state.treatmentPlans.pendingProcedures = pendingProcedures;
            })
            .addCase(getTreatmentPlanBillingProcedures.rejected, (state) => {
                state.treatmentPlans.loadingPendingProcedures = LoadingStatus.Failed;
            })

            .addCase(autoUpdateChartTreatmentPlan.pending, (state) => {
                state.treatmentPlans.saving = LoadingStatus.Pending;
            })
            .addCase(autoUpdateChartTreatmentPlan.fulfilled, (state, action) => {
                if (action.payload) {
                    if (state.treatmentPlans && state.treatmentPlans.data.length) {
                        const indexOfTreatmentPlan = state.treatmentPlans.data.findIndex((t) => t.id === action.payload?.id);
                        if (state.treatmentPlans.data[indexOfTreatmentPlan])
                            state.treatmentPlans.data[indexOfTreatmentPlan]._etag = action.payload._etag;
                    }
                    if (state.treatmentPlans.currentTreatmentPlan)
                        state.treatmentPlans.currentTreatmentPlan._etag = action.payload?._etag;
                    state.treatmentPlans.saving = LoadingStatus.Completed;
                }
            })
            .addCase(autoUpdateChartTreatmentPlan.rejected, (state, action) => {
                state.treatmentPlans.saving = LoadingStatus.Failed;
                state.treatmentPlans.error = action.payload as ErrorTypes;
            })

            // [PUT] Update Treatment Plan
            .addCase(updateChartTreatmentPlan.pending, (state) => {
                state.treatmentPlans.saving = LoadingStatus.Pending;
            })
            .addCase(updateChartTreatmentPlan.fulfilled, (state, action) => {
                if (state.treatmentPlans && state.treatmentPlans.data.length) {
                    const indexOfTreatmentPlan = state.treatmentPlans.data.findIndex((t) => t.id === action.payload?.id);
                    if (action.payload) state.treatmentPlans.data[indexOfTreatmentPlan] = action.payload;
                }
                state.treatmentPlans.currentTreatmentPlan = action.payload;
                if (action.payload?.signature || action.payload?.refusedToSign) {
                    state.treatmentPlans.signedOrRefused = true;
                } else {
                    state.treatmentPlans.signedOrRefused = false;
                }
                state.treatmentPlans.saving = LoadingStatus.Completed;
                state.treatmentPlans.error = undefined;
            })
            .addCase(updateChartTreatmentPlan.rejected, (state, action) => {
                state.treatmentPlans.saving = LoadingStatus.Failed;
                state.treatmentPlans.error = action.payload as ErrorTypes;
            })

            // [PUT] Update Treatment Plans
            .addCase(updateChartTreatmentPlans.pending, (state) => {
                state.treatmentPlans.saving = LoadingStatus.Pending;
            })
            .addCase(updateChartTreatmentPlans.fulfilled, (state, action) => {
                action.payload?.forEach((treatmentPlan) => {
                    if (state.treatmentPlans && state.treatmentPlans.data.length) {
                        const indexOfTreatmentPlan = state.treatmentPlans.data.findIndex((t) => t.id === treatmentPlan?.id);
                        if (treatmentPlan) state.treatmentPlans.data[indexOfTreatmentPlan] = treatmentPlan;
                    }
                });
                state.treatmentPlans.saving = LoadingStatus.Completed;
            })
            .addCase(updateChartTreatmentPlans.rejected, (state) => {
                state.treatmentPlans.saving = LoadingStatus.Failed;
            })
    );
};

export default treatmentPlanReducers;
