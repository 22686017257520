import { IColumn, Link, MessageBar, Spinner, Text, TooltipHost } from '@fluentui/react';
import { IPatientChartMedication } from 'api/models/patient-medication.model';
import useOverview from 'hooks/store/useOverview';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { useSelector, useDispatch } from 'react-redux';
import { isActivePatientMedication, selectShowMedicationHistory } from 'state/slices/patient/overview/overview.selectors';
import { setMedicationModal } from 'state/slices/patient/patient.slice';
import { medicationStopReasonsOption } from 'state/slices/tenant/medications-stop-reasons-slice';
import { classicDateOnly } from 'utils/dateOnly';
import SharedDetailsList from '../Shared/SharedDetailsList';

function MedicationsDetailsList(): JSX.Element {
    const dispatch = useDispatch();

    const { medicationsList, medicationsLoading } = useOverview();
    const showHistory = useSelector(selectShowMedicationHistory);
    const stopReasonsOptions = useSelector(medicationStopReasonsOption);

    const handleMedicationModal = (medication: IPatientChartMedication) => {
        dispatch(setMedicationModal({ medication, setModal: true }));
    };

    function renderDeletedLabel(item: IPatientChartMedication, prop: keyof IPatientChartMedication) {
        return (
            <Text variant="mediumPlus">{!isActivePatientMedication(item, new Date()) ? <i>{item[prop]}</i> : item[prop]}</Text>
        );
    }

    const columns: IColumn[] = [
        {
            key: 'medicationName',
            minWidth: 150,
            maxWidth: 220,
            name: 'Name',
            fieldName: 'medicationName',
            onRender: (item?: IPatientChartMedication) => {
                if (item) {
                    return <TooltipHost content={item.medicationName}>{renderDeletedLabel(item, 'medicationName')}</TooltipHost>;
                }
            },
        },
        {
            key: 'unstructuredSignature',
            minWidth: 150,
            maxWidth: 300,
            name: 'Sig',
            fieldName: 'unstructuredSignature',
            onRender: (item?: IPatientChartMedication) => {
                if (item) {
                    return (
                        <TooltipHost content={item.unstructuredSignature}>
                            {renderDeletedLabel(item, 'unstructuredSignature')}
                        </TooltipHost>
                    );
                }
            },
        },
        {
            key: 'startDate',
            minWidth: 150,
            maxWidth: 150,
            name: 'Start Date',
            fieldName: 'startDate',
            onRender: (item?: IPatientChartMedication) => {
                if (item && item.startDate) {
                    const date = classicDateOnly(item.startDate, 'M/d/yyyy');
                    return (
                        <Text variant="mediumPlus">{!isActivePatientMedication(item, new Date()) ? <i>{date}</i> : date}</Text>
                    );
                }
            },
        },
        {
            key: 'stopDate',
            minWidth: 150,
            maxWidth: 150,
            name: 'Stop Date',
            fieldName: 'stopDate',
            onRender: (item?: IPatientChartMedication) => {
                if (!item) return null;
                if (item.stopDate) {
                    const date = classicDateOnly(item.stopDate, 'M/d/yyyy');
                    return (
                        <Text variant="mediumPlus">
                            {item.stopDate && !isActivePatientMedication(item, new Date()) ? <i>{date}</i> : date}
                        </Text>
                    );
                } else {
                    return (
                        <Text variant="mediumPlus">
                            <Link
                                onClick={() => {
                                    handleMedicationModal(item);
                                }}
                            >
                                Add
                            </Link>
                        </Text>
                    );
                }
            },
        },
    ];

    const showHistoryColumns: IColumn = {
        key: 'stopReason',
        minWidth: 150,
        name: 'Reason',
        fieldName: 'stopReason',
        onRender: (item?: IPatientChartMedication) => {
            if (!item) return null;
            if (!isActivePatientMedication(item, new Date())) {
                const reason = stopReasonsOptions.find((res) => res.key === item.stopReason);
                return (
                    <Text variant="mediumPlus">
                        <i>{reason?.text}</i>
                    </Text>
                );
            }
        },
    };

    return (
        <>
            {medicationsLoading === LoadingStatus.Pending ? (
                <Spinner label="Loading medications list..." labelPosition="right" />
            ) : medicationsList?.length && medicationsLoading === LoadingStatus.Completed ? (
                <SharedDetailsList
                    columns={showHistory ? [...columns, showHistoryColumns] : columns}
                    sortOnMount={true}
                    mountSortColumn={['medicationName']}
                    onActiveItemChanged={undefined}
                    items={medicationsList || []}
                />
            ) : !medicationsList?.length && medicationsLoading === LoadingStatus.Completed ? (
                <MessageBar>No medications have been added.</MessageBar>
            ) : null}
        </>
    );
}

export default MedicationsDetailsList;
