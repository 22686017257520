import { Stack, Text } from '@fluentui/react';
import { DocumentContext } from 'api/models/document.model';
import { Section } from 'components';
import Upload from 'components/Upload';
import { RouteParams } from 'interfaces/route-params';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getPatientDocuments } from 'state/slices/documents/documents.actions';
import { cleanupPendingDocumentsAndFiles } from 'state/slices/documents/documents.slice';

function Documents(): JSX.Element | null {
    const dispatch = useDispatch();
    const { tenantId, patientId } = useParams<RouteParams>();

    useEffect(() => {
        dispatch(getPatientDocuments({ tenantId, patientId }));
        return () => {
            dispatch(cleanupPendingDocumentsAndFiles());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientId]);

    return (
        <Section heading={<Text variant="xLarge">Documents</Text>}>
            <Stack grow>
                <Upload context={DocumentContext.Clinical} patientId={patientId} />
            </Stack>
        </Section>
    );
}

export default Documents;
