import { DocumentBase } from 'api/document-base.model';

export interface IDocument extends DocumentBase {
    displayName?: string;
    patientId?: string; // Remove when we make the switch
    extension?: string;
    context?: DocumentContext;
    typeId?: string;
    uploadedDate: string;
    expirationDate?: string;
    resourceType: 'Patient';
    resourceId: string;
    references?: Record<DocumentRefTypes, string>;
}

export enum DocumentRefTypes {
    PatientInsurance = 'patientInsuranceId'
} 

export enum DocumentContext {
    Patient = 'Patient',
    Payer = 'Payer',
    HouseholdIncome = 'HouseholdIncome',
    Insurance = 'Insurance',
    Clinical = 'Clinical',
    ImagingBridge = 'ImagingBridge',
}

export interface IFile {
    id: string;
    file: File;
}
