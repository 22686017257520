import { ChartProcedurePreAuth } from 'api/models/chart.model';
import { IPredeterminationProcedure  } from 'api/models/predeterminations.model';

export interface PredeterminedRequestsState {
    filters: PredeterminationFilters;
    selectedPredetermination?: IPredeterminationProcedure;
    editModalOpen: boolean;
}

export interface PredeterminationFilters {
    dosStart: string;
    dosEnd: string;
    authStatus: ChartProcedurePreAuth;
}
export const predeterminationFiltersInitialState: PredeterminationFilters = {
    dosStart: '',
    dosEnd: '',
    authStatus: ChartProcedurePreAuth.Required,
};
export const predeterminationsInitialState: PredeterminedRequestsState = {
    filters: predeterminationFiltersInitialState,
    editModalOpen: false,
};
