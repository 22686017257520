import {
    DefaultButton,
    getTheme,
    IButtonStyles,
    IconButton,
    mergeStyleSets,
    Modal,
    PrimaryButton,
    Stack,
    Text,
    TooltipHost,
} from '@fluentui/react';
import IPatient, { IPatientEligibility, IPatientInsurance } from 'api/models/patient.model';
import { Field } from 'components';
import { useAccount, useTenantId, useValidation } from 'hooks';
import { getValidationError, IValidationConfig, validateErrorMessage, ValidationType } from 'hooks/useValidation';
import TaskFieldLabel from 'pages/Scheduling/components/Checkin/TaskFieldLabel';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveInsurance } from 'state/slices/edit-patient/edit-patient.slice';
import { TaskType } from 'state/task-management/taskManagement.actions';
import { selectInsuranceFinancialInfoByTaskTypeLookup } from 'state/task-management/taskManagement.slice';
import EligibilityProperties from './EligibilityProperties';
import { getMissingUserIdentities } from 'state/slices/users-identities/user-identities.actions';
import { selectUserIdentitiesData } from 'state/slices/users-identities/user-identities.selectors';
import { classicDateOnly } from 'utils/dateOnly';
import { selectTenantPayersData } from 'state/slices/tenant/tenant-payers.slice';

type Props = {
    setInsurance: (value: IPatientInsurance | undefined) => void;
    insurance: IPatientInsurance | undefined;
    patient?: IPatient;
    showEligibilityModal: boolean;
    setShowEligibilityModal: (value: boolean) => void;
};

function EligibilityModal({ setInsurance, insurance, showEligibilityModal, setShowEligibilityModal }: Props): JSX.Element {
    const theme = getTheme();
    const dispatch = useDispatch();
    const tenantId = useTenantId();
    const { account } = useAccount();

    const userLookup = useSelector(selectUserIdentitiesData);

    const onSave = () => {
        if (insurance) dispatch(saveInsurance(insurance));
        setShowEligibilityModal(false);
    };
    const validationConfig: IValidationConfig = [
        {
            fieldName: 'Last Verified',
            validation: [ValidationType.Required, ValidationType.MinMaxDate, ValidationType.NotFutureDate],
            value: insurance?.eligibility?.lastVerified,
            itemOptions: {
                minDate: new Date('01/01/2001'),
            },
        },
        {
            fieldName: 'Benefit Period',
            validation: [ValidationType.Required],
            value: insurance?.eligibility?.benefitPeriod,
        },

        {
            fieldName: 'Preventive',
            validation: [ValidationType.Required],
            value: insurance?.eligibility?.preventativeBenefits,
        },
        {
            fieldName: 'Basic',
            validation: [ValidationType.Required],
            value: insurance?.eligibility?.basicBenefits,
        },
        {
            fieldName: 'Major',
            validation: [ValidationType.Required],
            value: insurance?.eligibility?.majorBenefits,
        },
        {
            fieldName: 'Status',
            validation: [ValidationType.Required],
            value: insurance?.eligibility?.eligibilityStatus,
        },
    ];

    if (insurance?.eligibility?.basicWaitingPeriod && insurance?.eligibility?.basicWaitingPeriod.length > 0) {
        validationConfig.push({
            fieldName: 'Basic Effective Date',
            validation: ['minMaxDate'],
            value: insurance?.eligibility.basicWaitingPeriod,
            itemOptions: { minDate: new Date('01/01/2001') },
        });
    }
    if (insurance?.eligibility?.majorWaitingPeriod && insurance?.eligibility?.majorWaitingPeriod.length > 0) {
        validationConfig.push({
            fieldName: 'Major Effective Date',
            validation: ['minMaxDate'],
            value: insurance?.eligibility.majorWaitingPeriod,
            itemOptions: { minDate: new Date('01/01/2001') },
        });
    }
    if (insurance?.eligibility?.lastFullXrayBenefits && insurance?.eligibility?.lastFullXrayBenefits.length > 0) {
        validationConfig.push({
            fieldName: 'Xray Date',
            validation: ['minMaxDate'],
            value: insurance?.eligibility.lastFullXrayBenefits,
            itemOptions: { minDate: new Date('01/01/2001') },
        });
    }

    const [errors, onSubmit, handleCleanupErrors] = useValidation(validationConfig, onSave);

    useEffect(() => {
        return () => {
            handleCleanupErrors();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!showEligibilityModal) handleCleanupErrors();
    }, [showEligibilityModal]);

    const insuranceTasksLookup = useSelector(selectInsuranceFinancialInfoByTaskTypeLookup);
    const payersLookup = useSelector(selectTenantPayersData);
    const insuranceTasks = insurance?.id ? insuranceTasksLookup[insurance?.id] : {};

    const payer = insurance?.insuranceId ? payersLookup[insurance.insuranceId] : undefined;
    const payerName = payer?.name;
    const payerAddress = `${payer?.street1 ?? ''} ${payer?.street2 ?? ''} ${payer?.city ?? ''}, ${payer?.state ?? ''} ${payer?.zip ?? ''
        }`;

    const iconButtonStyles: Partial<IButtonStyles> = {
        root: {
            color: theme.palette.magenta,
            marginLeft: 'auto',
            marginTop: '4px',
            marginRight: '2px',
        },
        rootHovered: {
            color: theme.palette.neutralDark,
        },
    };

    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                flexDirection: 'row',
                borderTop: `4px solid ${theme.palette.themePrimary}`,
                color: theme.palette.neutralPrimary,
                display: 'flex',
                alignItems: 'center',
                fontWeight: 600,
                padding: '12px 12px 12px 24px',
            },
        ],
        body: {
            flex: '4 4 auto',
            padding: '0 24px',
            overflowY: 'hidden',
        },
    });

    function newEligibilityPropChange(path: keyof IPatientEligibility, value: unknown) {
        if (insurance) {
            setInsurance({
                ...insurance,
                eligibility: {
                    ...insurance.eligibility,
                    [path]: value,
                },
            });
        }
    }
    function handleVerified(lastVerifiedBy: string, lastVerified: string) {
        if (insurance) {
            setInsurance({
                ...insurance,
                eligibility: {
                    ...insurance.eligibility,
                    lastVerifiedBy,
                    lastVerified,
                },
            });
        }
    }

    const verifiedByUserId = insurance?.eligibility?.lastVerifiedBy;

    useEffect(() => {
        if (verifiedByUserId && showEligibilityModal && tenantId) {
            dispatch(getMissingUserIdentities({ tenantId, userIds: [verifiedByUserId] }));
        }
    }, [showEligibilityModal, showEligibilityModal, tenantId]);

    return (
        <Modal
            isOpen={showEligibilityModal}
            containerClassName={contentStyles.container}
            onDismiss={() => setShowEligibilityModal(false)}
            isBlocking={true}
        >
            <Stack horizontal horizontalAlign="space-between" className={contentStyles.header} style={{ paddingBottom: '0px' }}>
                <Stack horizontal>
                    <Text variant="xLarge">Edit Eligibility - </Text>
                    <Text variant="xLarge">&nbsp;{payerName}</Text>
                </Stack>
                <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 20 }}>
                    <Stack horizontal verticalAlign="end">
                        <TooltipHost content="The date of the last verification must be within the past 7 days or less.">
                            <IconButton iconProps={{ iconName: 'Info' }} />
                        </TooltipHost>
                        <Field.Date
                            label={
                                verifiedByUserId && userLookup[verifiedByUserId]
                                    ? `Last Verified by ${userLookup[verifiedByUserId]?.firstName ?? ''} ${userLookup[verifiedByUserId]?.lastName ?? ''
                                    }`
                                    : 'Verified'
                            }
                            value={
                                insurance?.eligibility?.lastVerified ? classicDateOnly(insurance?.eligibility?.lastVerified) : ''
                            }
                            onChange={(ev, value) => {
                                if (account.data?.identity?.firstName && account.data?.identity?.id && value) {
                                    handleVerified(account.data?.identity.id, value);
                                }
                            }}
                            hasDatePicker
                            errorMessage={validateErrorMessage(getValidationError(errors, 'Last Verified'))}
                            onRenderLabel={(data) => (
                                <TaskFieldLabel
                                    label={data?.label}
                                    lookup={insuranceTasks}
                                    taskType={TaskType.InsuranceEligibilityVerification}
                                    required
                                />
                            )}
                        />
                    </Stack>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={{ iconName: 'Cancel' }}
                        text="Close"
                        onClick={() => setShowEligibilityModal(false)}
                    />
                </Stack>
            </Stack>
            <Stack horizontal>
                <Text variant="mediumPlus" style={{ paddingLeft: '24px' }}>
                    <strong>Payer ID:</strong> {payer?.payerId ?? 'N/A'}
                </Text>
                <Text variant="mediumPlus" style={{ paddingLeft: '24px' }}>
                    <strong>Address:</strong> {!payerAddress || payerAddress.trim() === ',' ? 'N/A' : payerAddress}
                </Text>
            </Stack>
            <Stack tokens={{ childrenGap: 10, padding: 20 }}>
                <EligibilityProperties
                    newEligibilityPropChange={newEligibilityPropChange}
                    insurance={insurance}
                    validationErrors={errors}
                />
            </Stack>

            <Stack horizontal tokens={{ childrenGap: 10 }} style={{ padding: 24 }}>
                <PrimaryButton
                    text="Update Eligibility"
                    onClick={() => {
                        onSubmit();
                    }}
                />
                <DefaultButton
                    text="Discard Eligibility"
                    onClick={() => {
                        setShowEligibilityModal(false);
                    }}
                />
            </Stack>
        </Modal>
    );
}

export default EligibilityModal;
