import { EncounterWorkList } from 'state/slices/admin-huddle/worklists/encounter-worklists/encounter-worklists.state';
import OpenBilledEncounters from './OpenBilledEncounters';

export default function BilledPatient() {
    return (
        <OpenBilledEncounters
            workLists={[
                EncounterWorkList.BilledPatient,
                EncounterWorkList.BilledPatientCorrectionNeeded,
                EncounterWorkList.BilledPatientCorrectionAmend,
            ]}
        />
    );
}
