import IPatient from 'api/models/patient.model';
import { IPatientAppointmentPatient } from 'api/models/Scheduling/patientAppointment.model';
import { RecentPatient } from './appLocalStorage';

export default function getFullName(value?: IPatient | IPatientAppointmentPatient | RecentPatient | undefined): string {
    if (value) {
        if (value?.chosenName) {
            return `${value.firstName} (${value.chosenName}) ${value.lastName}`;
        } else {
            return getFirstLastName(value);
        }
    } else {
        return '';
    }
}

export function getFirstLastName(value?: IPatient | IPatientAppointmentPatient | RecentPatient | undefined): string {
    if (value) {
        return `${value.firstName} ${value.lastName}`;
    } else {
        return '';
    }
}

export function getChosenName(value?: IPatient | IPatientAppointmentPatient | RecentPatient | undefined): string | undefined {
    return value?.chosenName ? value.chosenName : undefined;
}
