import { IChartProcedure } from 'api/models/chart.model';
import Rule from '../rule';
import RulesGenerator, { IRule } from '../ruleGenerator';
import ProcedureCodes from '../procedureCodes';
import ProcedureBusinessRulesPipeline from './procedureBusinessRules.pipeline';

type Args = {
    procedurePipeline: ProcedureBusinessRulesPipeline;
    chartProcedure: IChartProcedure;
    rules: { [key: string]: IRule<ProcedureBusinessRulesPipeline, IChartProcedure> };
};
/**
 * Runs relevant rules on specified IChartProcedure
 *
 * @export
 * @class ProcedureBusinessRuleGenerator
 * @extends {RulesGenerator<ProcedureBusinessRulesPipeline, IChartProcedure>}
 */
export default class ProcedureBusinessRuleGenerator extends RulesGenerator<ProcedureBusinessRulesPipeline, IChartProcedure> {
    constructor({ chartProcedure, procedurePipeline, rules }: Args) {
        super({ pipeline: procedurePipeline, item: chartProcedure, rules, ruleTypes: ProcedureCodes });
    }

    protected _runRules(pipeline: ProcedureBusinessRulesPipeline): void {
        const code = pipeline.getProcedure(this._item as IChartProcedure)?.code;

        if (code) {
            const rule = this._rules[code];
            if (rule !== undefined) {
                const { getItem, getShouldRemoveItem, getErrors } = new Rule<ProcedureBusinessRulesPipeline, IChartProcedure>({
                    pipeline,
                    item: this._item as IChartProcedure,
                    rule,
                });
                if (getErrors) this.addErrors(getErrors);
                this.setShouldRemoveItem = getShouldRemoveItem;
                this._item = getItem;
            }
        }
    }
}
