import { PayloadAction } from '@reduxjs/toolkit';
import { IAppointmentNoEncounter } from 'api/models/appointment-no-encounter.model';
import { IReportingState } from '../reporting.state';

export const appointmentsReportNoEncounterAssociatedReducers = {
    setAppointmentNoEncounterProp: (
        state: IReportingState,
        action: PayloadAction<{ path: keyof IAppointmentNoEncounter; value: string | number | undefined }>,
    ): void => {
        const { path, value } = action.payload;
        ((state.reportProperties as IAppointmentNoEncounter)[path] as string | number | undefined) = value;
    },
    toggleAppointmentNoEncounterListId: (
        state: IReportingState,
        action: PayloadAction<{ path: keyof IAppointmentNoEncounter; id: string }>,
    ): void => {
        const { id, path } = action.payload;
        if (!(state.reportProperties as IAppointmentNoEncounter)[path])
            ((state.reportProperties as IAppointmentNoEncounter)[path] as unknown[]) = [];

        const currentIds = (state.reportProperties as IAppointmentNoEncounter)[path] as string[];

        if (currentIds)
            if (currentIds?.includes(id)) {
                ((state.reportProperties as IAppointmentNoEncounter)[path] as string[]) = currentIds.filter((_id) => _id !== id);
            } else {
                ((state.reportProperties as IAppointmentNoEncounter)[path] as string[]) = [...currentIds, id];
            }
    },
};
