import { toothSpriteReferences } from 'pages/Charting/components/ToothCanvas/spriteList';
export default function getToothId(toothId: number): string {
    if (toothId > 42) {
        return (toothId - 23).toString();
    } else if (toothId >= 33 && toothId <= 42) {
        return (toothId - 29).toString();
    }
    return toothId.toString();
}

export function getTeethDisplayName(teethId?: number[]): string {
    const teeth = teethId?.map(getToothDisplayName);
    return teeth && teeth.length ? teeth.join(', ') : '';
}

export function getToothDisplayName(toothId?: number): string {
    return toothSpriteReferences.find((ref) => ref.id === toothId)?.displayName || '';
}

