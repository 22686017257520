import { Stack, IconButton, Link, useTheme, Nav, INavLinkGroup, INavLink } from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import { useSelector, useTenantId } from 'hooks';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { getWorkListsMenu } from 'state/slices/admin-huddle/admin-huddle.actions';
import { selectGroupedWorkLists } from 'state/slices/admin-huddle/admin-huddle.selectors';
import { cleanupWorkListsMenu } from 'state/slices/admin-huddle/admin-huddle.slice';

export function WorkListMenu(): JSX.Element {
    const { push, location } = useHistory();
    const { url } = useRouteMatch();
    const theme = useTheme();

    const tenantId = useTenantId();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getWorkListsMenu());
        return () => {
            dispatch(cleanupWorkListsMenu());
        };
    }, [dispatch]);

    const groupedWorkLists = useSelector((state) => selectGroupedWorkLists(state, tenantId));

    const [expanded, { toggle }] = useBoolean(true);
    const expanderIcon = {
        iconName: expanded ? 'DoubleChevronLeftMed' : 'DoubleChevronLeftMedMirrored',
    };

    const { neutralLighterAlt } = theme.palette;

    const navLinkGroups: INavLinkGroup[] = [
        {
            links: [
                {
                    name: 'Dashboard',
                    iconProps: { iconName: 'Home' },
                    key: url,
                    url,
                },
                ...(groupedWorkLists
                    ?.filter((category) => !!category.workLists.length)
                    .map((category) => {
                        const categoryURL = `${url}/${category.id}`;
                        return {
                            name: category.displayName,
                            url: categoryURL,
                            key: categoryURL,
                            isExpanded: true,
                            links: category.workLists.map((workList) => {
                                const workListURL = `${url}/${category.id}/${workList.id}`;
                                return {
                                    name: workList.displayName,
                                    url: workListURL,
                                    key: workListURL,
                                };
                            }),
                        };
                    }) ?? []),
            ],
        },
    ];

    function _onLinkClick(ev?: React.MouseEvent<HTMLElement>, item?: INavLink) {
        ev?.preventDefault();
        ev?.stopPropagation();
        if (item?.url) push(item.url);
    }

    return (
        <Stack style={{ width: expanded ? 200 : 48, background: neutralLighterAlt }}>
            <Stack.Item align={expanded ? 'end' : 'center'}>
                <IconButton onClick={toggle} iconProps={expanderIcon} styles={{ root: { padding: '0px 24px' } }} />
            </Stack.Item>
            {expanded ? (
                <>
                    <Nav
                        onLinkClick={_onLinkClick}
                        selectedKey={location.pathname}
                        ariaLabel="Nav basic example"
                        groups={navLinkGroups}
                    />
                </>
            ) : null}
        </Stack>
    );
}
