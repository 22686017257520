import { Stack } from '@fluentui/react';
import { Field } from 'components';
import { useSelector } from 'hooks';
import { useDispatch } from 'react-redux';
import { selectWorkListFilters } from 'state/slices/admin-huddle/worklists/worklist.selectors';
import { setWorkListFilter } from 'state/slices/admin-huddle/worklists/worklist.slice';

export default function SignedDateRangeFilter() {
    const dispatch = useDispatch();
    const { signedDateStart, signedDateEnd } = useSelector(selectWorkListFilters<any>);

    function updateSignedDateRangeField({
        field,
        date,
    }: {
        field: 'signedDateStart' | 'signedDateEnd';
        date: string | undefined;
    }) {
        dispatch(setWorkListFilter({ path: field, value: date }));
    }

    return (
        <Stack grow horizontal tokens={{ childrenGap: 10 }}>
            <Stack.Item grow>
                <Field.Date
                    label="Signed Date Start"
                    onChange={(ev, value) => {
                        updateSignedDateRangeField({ field: 'signedDateStart', date: value });
                    }}
                    isReasonable
                    maxReasonableDate={signedDateEnd ? new Date(signedDateEnd) : undefined}
                    maxReasonableErrorMessage="Start date must be before the given end date."
                    value={signedDateStart ?? ''}
                    hasDatePicker
                />
            </Stack.Item>
            <Stack.Item grow>
                <Field.Date
                    label="Signed Date End"
                    onChange={(ev, value) => {
                        updateSignedDateRangeField({ field: 'signedDateEnd', date: value });
                    }}
                    isReasonable
                    minReasonableDate={signedDateStart ? new Date(signedDateStart) : undefined}
                    minReasonableErrorMessage="End date must be after the given start date."
                    disabled={!signedDateStart}
                    value={signedDateEnd ?? ''}
                    hasDatePicker
                />
            </Stack.Item>
        </Stack>
    );
}
