import { PayloadAction } from '@reduxjs/toolkit';
import { AdjustmentsSummaryReportQuery } from 'api/models/adjustments-summary-report-query.model';
import { IReportingState } from '../reporting.state';

export const adjustmentDetailsSummaryReducers = {
    setAdjustmentDetailsSummaryProp: (
        state: IReportingState,
        action: PayloadAction<{ path: keyof AdjustmentsSummaryReportQuery; value: string | number | undefined }>,
    ): void => {
        const { path, value } = action.payload;
        ((state.reportProperties as AdjustmentsSummaryReportQuery)[path] as string | number | undefined) = value;
    },
    toggleAdjDetailsSummaryListId: (
        state: IReportingState,
        action: PayloadAction<{ path: keyof AdjustmentsSummaryReportQuery; id: string }>,
    ): void => {
        const { id, path } = action.payload;
        if (!(state.reportProperties as AdjustmentsSummaryReportQuery)[path])
            ((state.reportProperties as AdjustmentsSummaryReportQuery)[path] as unknown[]) = [];

        const currentIds = (state.reportProperties as AdjustmentsSummaryReportQuery)[path] as string[];

        if (currentIds)
            if (currentIds?.includes(id)) {
                ((state.reportProperties as AdjustmentsSummaryReportQuery)[path] as string[]) = currentIds.filter(
                    (_id) => _id !== id,
                );
            } else {
                ((state.reportProperties as AdjustmentsSummaryReportQuery)[path] as string[]) = [...currentIds, id];
            }
    },
};
