//Interface similar to surface code lookup
import ProcedureCodes from '../procedureCodes';

export interface IToothQuadrantProcedureLookup {
    code: ProcedureCodes;
    minToothCountPerQuad: number;
    orMore?: boolean;
}

const toothQuadProcedureCodeLookup: IToothQuadrantProcedureLookup[][] = [
    // Grinival flap
    [
        { code: ProcedureCodes.D4241, minToothCountPerQuad: 1 },
        { code: ProcedureCodes.D4241, minToothCountPerQuad: 2 },
        { code: ProcedureCodes.D4241, minToothCountPerQuad: 3 },
        { code: ProcedureCodes.D4240, minToothCountPerQuad: 4, orMore: true },
    ],
    [
        { code: ProcedureCodes.D4342, minToothCountPerQuad: 1 },
        { code: ProcedureCodes.D4342, minToothCountPerQuad: 2 },
        { code: ProcedureCodes.D4342, minToothCountPerQuad: 3 },
        { code: ProcedureCodes.D4341, minToothCountPerQuad: 4, orMore: true },
    ],
    // Osseius surgery
    [
        { code: ProcedureCodes.D4261, minToothCountPerQuad: 1 },
        { code: ProcedureCodes.D4261, minToothCountPerQuad: 2 },
        { code: ProcedureCodes.D4261, minToothCountPerQuad: 3 },
        { code: ProcedureCodes.D4260, minToothCountPerQuad: 4, orMore: true },
    ],
    // Alveoloplasty
    [
        { code: ProcedureCodes.D7321, minToothCountPerQuad: 1 },
        { code: ProcedureCodes.D7321, minToothCountPerQuad: 2 },
        { code: ProcedureCodes.D7321, minToothCountPerQuad: 3 },
        { code: ProcedureCodes.D7320, minToothCountPerQuad: 4, orMore: true },
    ],
    [
        { code: ProcedureCodes.D7311, minToothCountPerQuad: 1 },
        { code: ProcedureCodes.D7311, minToothCountPerQuad: 2 },
        { code: ProcedureCodes.D7311, minToothCountPerQuad: 3 },
        { code: ProcedureCodes.D7310, minToothCountPerQuad: 4, orMore: true },
    ],
];

export default toothQuadProcedureCodeLookup;
