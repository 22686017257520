import { IDropdownOption } from '@fluentui/react';
import { createAsyncThunk, createSelector, createSlice, Dictionary } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import { ICondition } from 'api/models/lookup.model';
import { AxiosResponse, AxiosError } from 'axios';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { LookupState } from 'interfaces/lookup-state';
import { RootState } from 'state/store';

const initialState: LookupState<ICondition> = {
    initialLoad: LoadingStatus.Idle,
    loading: LoadingStatus.Idle,
    data: {},
};

export const getConditions = createAsyncThunk<
    AxiosResponse<Dictionary<ICondition>>,
    void,
    {
        rejectValue: AxiosError;
    }
>('getConditions', async () => {
    const res = await dentalApi.getConditions();
    return res;
});

const conditionsSlice = createSlice({
    name: 'conditions',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getConditions.pending, (state) => {
                state.initialLoad = LoadingStatus.Pending;
            })
            .addCase(getConditions.fulfilled, (state, action) => {
                state.data = action.payload.data;
                state.initialLoad = LoadingStatus.Completed;
            })
            .addCase(getConditions.rejected, (state) => {
                state.initialLoad = LoadingStatus.Failed;
            });
    },
});

const { reducer } = conditionsSlice;
export const selectConditions = (state: RootState): LookupState<ICondition> => state.tenant.conditions;
export const selectConditionsData = createSelector(selectConditions, (state) => (state.data ? state.data : {}));
export const conditionsAsList = createSelector(selectConditions, (conditions) => {
    if (conditions && conditions.data && Object.keys(conditions.data).length > 0)
        return Object.keys(conditions.data)
            .filter((c) => c !== undefined)
            .map((key) => conditions.data![key])
            .filter((item) => !item?.isDeleted) as ICondition[];
    return [];
});

export const selectConditionOptions = createSelector(conditionsAsList, (conditions) => {
    const options: IDropdownOption[] = conditions.map((condition) => ({
        text: `${condition?.code} - ${condition?.description}`,
        key: condition.id,
    }));

    return options;
});

export default reducer;
