import { Dictionary } from '@reduxjs/toolkit';
import IReportingGroup, { IReportingCategory, IReportingType } from 'api/models/reporting-group.model';
import { LoadingStatus } from 'interfaces/loading-statuses';

export type ReportingGroupsState = {
    //Reporting Groups
    groups?: Dictionary<IReportingGroup>;
    selectedGroup?: IReportingGroup;
    selectedGroupId?: string;
    loadingGroups: LoadingStatus;
    loadingGroup: LoadingStatus;
    selectedReportingGroupModified: boolean;

    //Reporting Categories
    categories?: Dictionary<IReportingCategory>;
    selectedCategory?: IReportingCategory;
    loadingCategories: LoadingStatus;
    loadingCategory: LoadingStatus;

    //Reporting Types
    types?: Dictionary<IReportingType>;
    loadingTypes: LoadingStatus;
};

const reportingGroupsInitialState: ReportingGroupsState = {
    loadingGroups: LoadingStatus.Idle,
    loadingGroup: LoadingStatus.Idle,
    loadingCategories: LoadingStatus.Idle,
    loadingCategory: LoadingStatus.Idle,
    loadingTypes: LoadingStatus.Idle,
    selectedReportingGroupModified: false,
};

export default reportingGroupsInitialState;
