import { IChartCondition } from 'api/models/chart.model';
import { ApplicableArea, ICondition } from 'api/models/lookup.model';
import { every, forEach } from 'lodash';
import { createSelector } from 'reselect';
import { conditionsAsList } from 'state/slices/tenant/conditions.slice';
import { RootState } from 'state/store';
import ChartingConditionsPipeline from '../chartingConditions.pipeline';
import ConditionConflictRulesPipeline from '../conditionConflictRules.pipeline';
import { chartConditionsAsList } from '../conditions/conditions.selectors';
import { PipelineError } from '../pipeline';
import { ConditionPanelState } from './condition-panel.state';

export const selectConditionsPanel = (state: RootState): ConditionPanelState => state.charting.conditionPanel;
export const selectedChartCondition = createSelector(selectConditionsPanel, (state) => state.selectedChartCondition);

export const selectNewPanelConditions = createSelector(
    selectConditionsPanel,
    selectedChartCondition,
    (state, selectedCondition) => {
        const { panelTeethData, condition } = state;
        const selectedChartConditionId = selectedCondition ? [selectedCondition.id] : undefined;

        const conditions = [condition].filter((c) => c !== undefined) as ICondition[];

        const conditionsBySelectedTeeth: IChartCondition[] = [];

        forEach(panelTeethData, (data, tooth) => {
            const selectedTeeth = [+tooth];
            const surfaces = data.surfaces;

            new ChartingConditionsPipeline(conditions, selectedTeeth, surfaces, selectedChartConditionId).next((conditions) => {
                conditionsBySelectedTeeth.push(...conditions);
            });
        });

        return conditionsBySelectedTeeth;
    },
);

export const selectConditionValidationErrors = createSelector(
    [selectConditionsPanel, selectNewPanelConditions, conditionsAsList, chartConditionsAsList],
    (state, conditions, conditionsAsList, chartConditionsAsList) => {
        const errors: string[] = [];

        if (!state.condition) errors.push('Please select a condition');
        if (!state.selectedTeeth.length) errors.push('Please select teeth');
        if (
            state.condition?.applicableArea === (ApplicableArea.Surface as string) &&
            !every(state.panelTeethData, (d) => (d.surfaces.length ? true : false))
        ) {
            errors.push('Please select surfaces');
        }

        new ConditionConflictRulesPipeline({
            chartConditions: conditions,
            currentConditions: chartConditionsAsList,
            conditions: conditionsAsList,
            selectedTeeth: state.selectedTeeth,
        }).next((conditions, pipelineErrors) => {
            pipelineErrors?.forEach((err) => {
                switch (err.type) {
                    case PipelineError.OnePerTooth:
                        errors.push('This condition is already applied the selected tooth/teeth');
                        break;
                }
            });
        });

        return errors;
    },
);
