import { useRef, useEffect } from 'react';
import FullCalendar, { DateSelectArg } from '@fullcalendar/react';
import interactionPlugin, { EventReceiveArg, EventResizeDoneArg } from '@fullcalendar/interaction';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import {
    Dialog,
    DialogFooter,
    DialogType,
    mergeStyles,
    PrimaryButton,
    Stack,
    Text,
    TooltipDelay,
    TooltipHost,
    useTheme,
} from '@fluentui/react';
import { useSelector } from 'hooks';
import {
    cleanupSelectedAppointment,
    getLayoutsAndAllocations,
    setAlertDialogMessage,
    setDisableHIPAAView,
    setSelectedDate,
} from 'state/slices/scheduling/scheduling.slice';
import { batch, useDispatch } from 'react-redux';
import AppointmentPanel from './AppointmentPanel';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import {
    returnToCheckout,
    selectTimeSlot,
    updateDraggedBlockAllocations,
    updateDraggedPatientAllocations,
    updateSelectedCalendarEvent,
} from 'state/slices/scheduling/scheduling.actions';
import usePrevious from 'hooks/usePrevious';
import IBlockAppointment from 'api/models/Scheduling/blockAppointment.model';
import IPatientAppointment from 'api/models/Scheduling/patientAppointment.model';
import './Calendar.scss';
import CalendarEvent from './CalendarEvent';
import { RouteParams } from 'interfaces/route-params';
import CheckoutPanel from './Checkout/CheckoutPanel';
import CheckInPanel from './Checkin/CheckInPanel';
import {
    selectPatientOverviewOpen,
    selectSelectedLOC,
    selectSelectedDate,
    selectPatientAllocationsCount,
    selectPatientCancelledAllocations,
    selectDisableHIPAAView,
    isAlertDialogOpen,
    alertDialogMessage,
    IAppointmentContentArg,
    selectPreviousAppointmentData,
} from 'state/slices/scheduling/scheduling.selectors';
import {
    selectCurrentOperatoriesStartAndEndTime,
    selectCurrentOperatoriesAsResources,
} from 'state/slices/lookups/operatories/operatories.selectors';
import { selectCalendarAllocations } from 'state/slices/scheduling/schedule-appointment/schedule-appointment.selectors';
import { AppointmentType } from 'state/slices/scheduling/scheduling.state';
import { IThemeCustom } from 'state/slices/ui/ui.slice';

function Calendar(): JSX.Element {
    const dispatch = useDispatch();

    const theme = useTheme();

    const setButtonTheme = () => {
        if (theme) {
            document.querySelectorAll('.fc-header-toolbar button').forEach((button) => {
                const newButton = button as HTMLButtonElement;
                newButton.style.backgroundColor = (theme as IThemeCustom).palette.navbar;
            });
        }
    };

    useEffect(setButtonTheme, [theme]);
    const themeMode = useSelector((state) => state.ui.selectedTheme);

    const calendarRef = useRef<FullCalendar>(null);
    const calendarApi = calendarRef.current?.getApi();

    const { tenantId } = useParams<RouteParams>();

    const isPatientOverviewOpen = useSelector(selectPatientOverviewOpen);
    const selectedLOC = useSelector(selectSelectedLOC);
    const selectedDate = useSelector(selectSelectedDate);
    const prevSelectedDate = usePrevious(selectedDate);
    const calendarAllocations = useSelector(selectCalendarAllocations);
    const patientAllocationsCount = useSelector(selectPatientAllocationsCount);
    const patientCancelledAllocations = useSelector(selectPatientCancelledAllocations);
    const disableHIPAAView = useSelector(selectDisableHIPAAView);
    const currentOperatoriesStartAndEndTime = useSelector((state) => selectCurrentOperatoriesStartAndEndTime(state, tenantId));
    const currentOperatoriesAsResources = useSelector((state) =>
        selectCurrentOperatoriesAsResources(state, tenantId, selectedLOC?.id || ''),
    );
    const _selectPreviousAppointmentData = useSelector(selectPreviousAppointmentData);

    const date = format(new Date(selectedDate), 'yyyy-MM-dd');

    useEffect(() => {
        if (!isPatientOverviewOpen && selectedLOC) {
            batch(() => {
                //Every time the user closes a panel or screen this updates...
                dispatch(
                    getLayoutsAndAllocations({
                        tenantId,
                        date,
                        locationOfCareId: selectedLOC.id,
                    }),
                );
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPatientOverviewOpen, selectedLOC, selectedDate]);

    const currentDate = new Date();
    const currentTimeStamp = `${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`;

    useEffect(() => {
        if (selectedDate !== prevSelectedDate) {
            calendarApi?.gotoDate(selectedDate);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate]);

    const onSelectTimeSlot = (selection: DateSelectArg) => {
        dispatch(selectTimeSlot(calendarApi, selection));
    };

    const onPanelDismiss = () => {
        dispatch(cleanupSelectedAppointment());
        if (_selectPreviousAppointmentData) dispatch(returnToCheckout({ tenantId }));
    };

    const _updateSelectedCalendarEvent = (arg: EventResizeDoneArg) => {
        dispatch(updateSelectedCalendarEvent(tenantId, arg.event));
    };

    const getEventContent = (eventInfo: IAppointmentContentArg) => {
        return <CalendarEvent eventInfo={eventInfo} />;
    };

    const _onRenderTooltip = () => (
        <Stack tokens={{ childrenGap: 2 }}>
            {patientCancelledAllocations && patientCancelledAllocations.length ? (
                patientCancelledAllocations.map((item) => (
                    <Text key={`cancelled-alloc-${item.id}`} variant="xSmall">
                        Patient ID: {item.patient?.mrn ? item.patient?.mrn.substring(0, 10) : 'N/A'}
                    </Text>
                ))
            ) : (
                <Text variant="xSmall">There are no cancellations</Text>
            )}
        </Stack>
    );

    const handleDrop = (arg: EventReceiveArg) => {
        const { event } = arg;
        const apptType = event.extendedProps['type'];
        const newStartTime = event.start?.toTimeString().substring(0, 5) ?? '';
        const newEndTime = event.end?.toTimeString().substring(0, 5) ?? '';
        const newOperatoryId = event._def.resourceIds ? event._def.resourceIds[0] : '';
        const appointment = event._def.extendedProps as IPatientAppointment;

        if (apptType === AppointmentType.Block) {
            const updatedBlockAppt: IBlockAppointment = {
                ...appointment,
                id: appointment['id'],
                isDeleted: false,
                operatoryId: newOperatoryId,
                startTime: newStartTime,
                endTime: newEndTime,
            };
            dispatch(updateDraggedBlockAllocations(tenantId, updatedBlockAppt));
        } else {
            const updatedPatientAppt: IPatientAppointment = {
                ...appointment,
                id: appointment['id'],
                patientId: appointment['patientId'],
                isDeleted: false,
                operatoryId: newOperatoryId,
                startTime: newStartTime,
                endTime: newEndTime,
            };
            dispatch(updateDraggedPatientAllocations(tenantId, updatedPatientAppt));
        }
    };

    const resourceCustomClass = mergeStyles({
        overflow: 'hidden',
    });

    return (
        <Stack tokens={{ childrenGap: 10 }} style={{ flex: 1 }}>
            <FullCalendar
                schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
                plugins={[resourceTimeGridPlugin, interactionPlugin]}
                initialView="resourceTimeGridDay"
                ref={calendarRef}
                viewClassNames={themeMode}
                slotMinTime={currentOperatoriesStartAndEndTime.startTime}
                slotMaxTime={currentOperatoriesStartAndEndTime.endTime}
                slotDuration="00:15:00"
                nowIndicator={true}
                initialDate={selectedDate}
                scrollTime={currentTimeStamp.substring(0, 2) > '13' ? '08:00:00' : '06:00:00'}
                height="100%"
                expandRows={false}
                handleWindowResize={true}
                customButtons={{
                    customTodayButton: {
                        text: 'Today',
                        click: () => {
                            const d = new Date();
                            dispatch(setSelectedDate(d));
                        },
                    },
                    custom6MButton: {
                        text: '6M',
                        click: () => {
                            const d = new Date();
                            d.setMonth(d.getMonth() + 6);
                            d.setDate(d.getDate() + 1);
                            dispatch(setSelectedDate(d));
                        },
                    },
                    custom3MButton: {
                        text: '3M',
                        click: () => {
                            const d = new Date();
                            d.setMonth(d.getMonth() + 3);
                            d.setDate(d.getDate() + 1);
                            dispatch(setSelectedDate(d));
                        },
                    },
                    customPrevButton: {
                        text: '<',
                        click: () => {
                            const d = new Date(selectedDate);
                            d.setDate(d.getDate() - 1);
                            dispatch(setSelectedDate(d));
                        },
                    },
                    customNextButton: {
                        text: '>',
                        click: () => {
                            const d = new Date(selectedDate);
                            d.setDate(d.getDate() + 1);
                            dispatch(setSelectedDate(d));
                        },
                    },
                    customHIPAAButton: {
                        text: `${disableHIPAAView ? 'Enable' : 'Disable'} HIPAA View`,
                        click: () => {
                            dispatch(setDisableHIPAAView(!disableHIPAAView));
                        },
                    },
                }}
                headerToolbar={{
                    center: 'title',
                    left: 'customPrevButton,customNextButton customTodayButton custom6MButton custom3MButton',
                    end: 'customHIPAAButton',
                }}
                titleFormat={{
                    // Change this line to customize the title format
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    weekday: 'long',
                }}
                resourceOrder="displayOrder,displayName"
                resources={currentOperatoriesAsResources}
                resourceLabelClassNames={resourceCustomClass}
                resourceLabelContent={(props) => {
                    return <span title={props.resource.title}>{props.resource.title}</span>;
                }}
                selectable={true}
                select={onSelectTimeSlot}
                eventResize={_updateSelectedCalendarEvent}
                eventReceive={handleDrop}
                eventContent={getEventContent}
                eventBackgroundColor={themeMode === 'dark' ? '#1c1c1c' : 'white'}
                eventClassNames={themeMode === 'dark' ? 'event-border-dark' : 'event-border-light'}
                displayEventTime={false}
                events={calendarAllocations}
                eventOverlap={false}
            />
            <Stack.Item styles={{ root: { textAlign: 'center' } }}>
                <Stack tokens={{ childrenGap: 5 }} horizontal horizontalAlign="center">
                    <Text>{`${patientAllocationsCount} Patients Scheduled,`}</Text>
                    <TooltipHost
                        styles={{ root: { display: 'inline-block' } }}
                        delay={TooltipDelay.zero}
                        tooltipProps={{ onRenderContent: _onRenderTooltip }}
                    >
                        <Text>{`${patientCancelledAllocations.length} Cancellations`}</Text>
                    </TooltipHost>
                </Stack>
            </Stack.Item>
            <AppointmentPanel onDismiss={onPanelDismiss} />
            <CheckoutPanel />
            <CheckInPanel />
            <AlertDialog />
        </Stack>
    );
}

function AlertDialog() {
    const dispatch = useDispatch();
    const isOpen = useSelector(isAlertDialogOpen);

    const alertMessage = useSelector(alertDialogMessage);
    const onDismiss = () => dispatch(setAlertDialogMessage(undefined));

    return (
        <Dialog
            hidden={!isOpen}
            onDismiss={onDismiss}
            dialogContentProps={{
                type: DialogType.normal,
                title: 'Alert',
                closeButtonAriaLabel: 'Close',
                subText: alertMessage,
            }}
            modalProps={{
                isBlocking: true,
                styles: { main: { maxWidth: 450 } },
            }}
        >
            <DialogFooter>
                <PrimaryButton onClick={onDismiss} text="Ok" />
            </DialogFooter>
        </Dialog>
    );
}

export default Calendar;
