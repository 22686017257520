import { ChartProcedureStatus, IChartProcedure } from 'api/models/chart.model';
import { ConflictRuleType } from 'api/models/lookup.model';
import { IPipelineError, PipelineError } from '../pipeline';
import ProcedureConflictRulesPipeline from '../procedureConflictRules.pipeline';
import { createRule } from '../ruleGenerator';

//This rule will find two abutments on an arch and determine what teeth can have pontics charted in between.
const ProcedureRequiresCompletedExtractionOrMissingToComplete = createRule<ProcedureConflictRulesPipeline, IChartProcedure>({
    ruleTypes: [ConflictRuleType.requiresCompletedExtractionOrMissingToComplete],
    rule: (pipeline, item) => {
        const _errors: IPipelineError[] = [];
        if (item.toothIds && pipeline.statusOverride === ChartProcedureStatus.Completed)
            if (
                !pipeline.missingConditionOnTooth(item.toothIds[0]) &&
                !pipeline.completedExtractionExistsOnTooth(item.toothIds[0])
            )
                _errors.push({ type: PipelineError.RequiresCompletedExtractionOrMissing, data: pipeline.getProcedure(item) });

        return { shouldRemoveItem: !!_errors.length, errors: _errors };
    },
});

export default ProcedureRequiresCompletedExtractionOrMissingToComplete;
