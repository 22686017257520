import { useSelector } from 'hooks';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useTenantId from 'hooks/useTenantId';

import {
    getLookups,
    setSelectedLookup,
    createLookup,
    updateLookup,
    setSelectedLookupItemProp,
} from 'state/slices/lookups/lookups.slice';
import { selectLookups, selectLookupsList, selectSelectedLookup } from 'state/slices/lookups/lookups.selectors';
import { SelectedLookup } from 'state/slices/lookups/lookupsStateTypes';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { setLookupEntityAndSave } from 'state/slices/lookups/lookups.actions';

export default function useLookupsStore<T>(lookupType: string) {
    const dispatch = useDispatch();
    const tenantId = useTenantId();

    //updateLookupItem
    return {
        lookups: useSelector((state) => selectLookups(state, { tenantId, lookupType })),
        lookupsList: useSelector((state) => selectLookupsList<T>(state, { tenantId, lookupType })),
        selectedLookup: useSelector((state) => selectSelectedLookup(state, { tenantId, lookupType })),
        getLookups: useCallback(() => dispatch(getLookups({ tenantId, lookupType })), [dispatch, tenantId, lookupType]),
        setSelectedLookupItemProp: useCallback(
            (propPath: string, value: unknown) => dispatch(setSelectedLookupItemProp({ lookupType, tenantId, propPath, value })),
            [dispatch, tenantId, lookupType],
        ),
        setSelectedLookup: useCallback(
            (entity?: SelectedLookup) => dispatch(setSelectedLookup({ tenantId, lookupType, entity })),
            [dispatch, tenantId, lookupType],
        ),
        buildNewItem: (item: T): SelectedLookup => ({
            item,
            isNew: true,
            savingStatus: LoadingStatus.Idle,
        }),
        createLookup: useCallback(
            (entity: SelectedLookup) => dispatch(createLookup({ tenantId, lookupType, entity })),
            [dispatch, tenantId, lookupType],
        ),
        updateLookup: useCallback(
            (entity: SelectedLookup) => dispatch(updateLookup({ tenantId, lookupType, entity })),
            [dispatch, tenantId, lookupType],
        ),
        setLookupEntityAndSave: useCallback(
            (entityId: string, propPath: string, value: unknown) =>
                dispatch(setLookupEntityAndSave({ tenantId, lookupType, value, propPath, entityId })),
            [dispatch, tenantId, lookupType],
        ),
    };
}
