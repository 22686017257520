import { IDropdownOption } from '@fluentui/react';
import { EventContentArg, EventApi, EventInput } from '@fullcalendar/react';
import { Dictionary } from '@reduxjs/toolkit';
import { IBillingProcedure } from 'api/models/billing-procedure.model';
import { ChartProcedurePreAuth, ChartProcedureStatus } from 'api/models/chart.model';
import IPatient, { IPatientInsurance } from 'api/models/patient.model';
import IProvider from 'api/models/provider.model';
import IAppointmentAllocations from 'api/models/Scheduling/allocation.model';
import IBlockAppointment from 'api/models/Scheduling/blockAppointment.model';
import IOperatoryCalendarLayout from 'api/models/Scheduling/layout.model';
import ILocationOfCare from 'api/models/Scheduling/locationsOfCare.model';
import IPatientAppointment, { IAppointmentProcedure } from 'api/models/Scheduling/patientAppointment.model';
import { ToothArea } from 'api/models/tooth-area';
import {
    ChartTreatmentPlanStatus,
    IChartTreatmentPlan,
    IChartTreatmentPlanPhase,
    IChartTreatmentPlanPhaseProcedure,
} from 'api/models/treatment-plan.model';
import IUserTask from 'api/models/user-task.model';
import { LoadingStatus, LoadingStatuses } from 'interfaces/loading-statuses';
import { sortBy, groupBy, forEach, some, flatten, orderBy, map } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'state/store';
import { classicDateOnly } from 'utils/dateOnly';
import { getDayOfWeekString } from 'utils/getDayOfWeekString';
import { isDateBetween } from 'utils/isDateBetween';
import { selectSignedTreatmentPlans, selectAllTreatmentPlans } from '../charting/treatmentPlans/treatmentPlans.selectors';
import {
    getGroupedBillingProcedures,
    TreatmentPlanCreditPhaseViewProcedureGroup,
} from '../ledger/treatment-plan-credit-and-uac/treatment-plan-credit-and-uac.selectors';
import { selectProvidersSettings } from '../settings/providers/providers.selectors';
import { selectFilteredProviders, selectProvidersAsList } from '../tenant/providers.slice';
import { IPreviousAppointmentData } from './schedule-appointment/schedule-appointment.state';
import { IQuickViewSchedule } from './scheduling.slice';
import { AppointmentType, TrackerCount } from './scheduling.state';

export const selectSelectedDate = (state: RootState): Date => state.scheduling.selectedDate;
export const providerPatientAppointment = (state: RootState): IPatientAppointment[] | undefined =>
    state.scheduling.providerPatientAppointments;
export const selectSelectedLOC = (state: RootState): ILocationOfCare | undefined => state.scheduling.selectedLOC;
export const selectExpandSchedule = (state: RootState): boolean => state.scheduling.ui.expandSchedule;
export const selectToolbarButtonIsDisabled = (state: RootState): boolean => state.scheduling.ui.toolbarButtonIsDisabled;
export const selectPatientOverviewOpen = (state: RootState): boolean => state.scheduling.ui.setPatientOverviewOpen;
export const selectIsCheckoutPanelOpen = (state: RootState): boolean => state.scheduling.ui.isCheckoutPanelOpen;
export const selectCheckoutError = (state: RootState): string | undefined => state.scheduling.appointmentCheckout.error;
export const selectCheckoutLoading = (state: RootState): string | undefined => state.scheduling.appointmentCheckout.loading;
export const selectSchedulingUiState = (state: RootState) => state.scheduling.ui;
export const selectIsScheduleAppointmentPanelOpen = createSelector(
    selectSchedulingUiState,
    (ui) => ui.isScheduleAppointmentPanelOpen,
);
export const selectProviderAppointments = createSelector(
    [providerPatientAppointment, selectProvidersSettings],
    (providerPatientAppointments) => {
        return sortBy(
            providerPatientAppointments?.filter((appt) => appt.isDeleted),
            ['startTime', 'DESC'],
        );
    },
);

export const selectAppointmentType = (state: RootState): AppointmentType | undefined => state.scheduling.selectedAppointmentType;
export const selectFilteredOperatories = (state: RootState, tenantId: string, locationOfCareId: string): string[] | undefined => {
    const operatoryIds = (state.scheduling.selectedOperatories ?? {})[tenantId] ?? [];
    const operatoryTags = (state.scheduling.selectedOperatoryTags ?? {})[tenantId]?.[locationOfCareId] ?? [];

    return [...operatoryIds, ...operatoryTags];
};

export const selectFilteredOperatoryTags = (state: RootState): Dictionary<Dictionary<string[]>> | undefined =>
    state.scheduling.selectedOperatoryTags;

//Alert Dialog
export const isAlertDialogOpen = (state: RootState): boolean => !!state.scheduling.alertDialogMessage;
export const alertDialogMessage = (state: RootState): string | undefined => state.scheduling.alertDialogMessage;

//HIPAA View
export const selectDisableHIPAAView = (state: RootState): boolean => state.scheduling.ui.disableHIPAAView;

//Tracker
export const selectTracker = (state: RootState): TrackerCount | undefined => state.scheduling.tracker;

//Quickview Schedules
export const selectQuickViewSchedules = (state: RootState): IQuickViewSchedule[] | undefined =>
    state.scheduling.quickViewSchedules.data;

//AppointmentCheckout
export const selectFinishCheckoutLoading = (state: RootState): boolean =>
    state.scheduling.appointmentCheckout.finishCheckoutLoading === LoadingStatus.Pending;

export const selectAppointmentCheckouts = (state: RootState): IBillingProcedure[] | undefined =>
    state.scheduling.appointmentCheckout.data;

export const selectCompletedProceduresAsList = createSelector(selectAppointmentCheckouts, (procedures) => {
    if (procedures) return procedures.filter((item) => item.status === 'Completed');
    return [];
});

function getBillingProcedureTotals(billingProcedure: IBillingProcedure[]) {
    return [
        ...billingProcedure.map((procedure) => procedure.patientEstimate),
    ].reduce((fee1, fee2) => fee1 + fee2, 0);
}

export const selectUpcomingProceduresAsList = createSelector(selectAppointmentCheckouts, (procedures) => {
    if (procedures) return procedures.filter((item) => item.status !== 'Completed');
    return [];
});

export const selectUpcomingProceduresTreatingProviderIds = createSelector(selectUpcomingProceduresAsList, (procedures) => {
    return procedures.map((proc) => proc.treatingProviderId).filter((provider) => provider !== undefined) as string[];
});
export const selectUpcomingProceduresHygienistIds = createSelector(selectUpcomingProceduresAsList, (procedures) => {
    return procedures.map((proc) => proc.hygienistId).filter((provider) => provider !== undefined) as string[];
});
/**
 * group all upcoming procedures into phases, so Dictionary<IBillingProcedure[]>. Use the key (phaseId) to find the treatment plan name.
 *
 * The details list will work backwards, the first set of items in the expandable table will be an array of {treatmentPlanId, treatmentPlanName, phaseData, }
 */

// AppointmentCheckout Tasks
export const selectPatientCheckoutTasks = (state: RootState): { data: IUserTask[]; loading: LoadingStatuses } =>
    state.scheduling.patientCheckoutTasks;
export const selectPatientCheckoutTasksData = createSelector(selectPatientCheckoutTasks, (state) => state.data);

export interface IUpcomingTreatmentPlan {
    treatmentPlanId: string;
    treatmentPlanSignedDate?: string;
    treatmentPlanStatus?: ChartTreatmentPlanStatus;
    indexOfTreatmentPlan: number;
    phases: IUpcomingTreatmentPlanPhaseData[];
}

export interface IUpcomingTreatmentPlanPhaseData {
    phaseProcedures: IBillingProcedure[];
    patientCost: number;
    phaseId: string;
    phaseName: string;
    treatmentPlanId: string;
    preAuthStatus: ChartProcedurePreAuth;
    returnAppointment: IUserTask | undefined;
    groupedProcedures: TreatmentPlanCreditPhaseViewProcedureGroup[];
}

/**
 *
 * @export
 * @param {IBillingProcedure[]} billingProcedures
 * @param {IChartTreatmentPlan[]} treatmentPlans
 * @param {IUserTask[]} [tasks]
 * @return {*}  {IUpcomingTreatmentPlan[]}
 */
export function createTreatmentPlanPhaseViewModel(
    billingProcedures: IBillingProcedure[],
    treatmentPlans: IChartTreatmentPlan[],
    tasks?: IUserTask[],
): IUpcomingTreatmentPlan[] {
    const phases = groupBy(billingProcedures, (procedure) => procedure.phaseId);

    const result: IUpcomingTreatmentPlan[] = [];
    /**
     * TODO: Matt to come back to this sometime in the far far future.
     * const treatmentPlans = tp.filter(({procedures}) => some(procedures, (p) => billingProcedures.))
     * const phases =
     */
    forEach(phases, (phaseProcedures, phaseId) => {
        let treatmentPlanPhase: IChartTreatmentPlanPhase | undefined;

        const indexOfTP = treatmentPlans.findIndex((treatmentPlan) => {
            const indexOfPhase = treatmentPlan.phases ? treatmentPlan.phases.findIndex((phase) => phase.id === phaseId) : -1;
            if (indexOfPhase > -1 && treatmentPlan.phases) treatmentPlanPhase = treatmentPlan.phases[indexOfPhase];
            return indexOfPhase > -1;
        });

        const tp = treatmentPlans[indexOfTP];

        const patientCost = phaseProcedures.map((proc) => proc.commonPatientFee).reduce((a, b) => a + b, 0);

        const preAuthStatus = some(phaseProcedures, (procedure) => procedure.preAuthorization === ChartProcedurePreAuth.Required)
            ? ChartProcedurePreAuth.Required
            : ChartProcedurePreAuth.NotRequired;

        const returnAppointment = tasks?.find((task) => task.references?.chartTreatmentPlanPhaseId === phaseId);

        const upcomingPhaseProceduresGroup = getGroupedBillingProcedures(phaseProcedures);

        if (tp) {
            const indexOfTreatmentPlanInResult = result.findIndex((plan) => plan.treatmentPlanId === tp.id);
            if (indexOfTreatmentPlanInResult > -1) {
                result[indexOfTreatmentPlanInResult] = {
                    ...result[indexOfTreatmentPlanInResult],
                    phases: [
                        ...result[indexOfTreatmentPlanInResult].phases,
                        {
                            phaseId,
                            phaseName: treatmentPlanPhase?.displayName ?? '',
                            phaseProcedures,
                            patientCost,
                            preAuthStatus,
                            treatmentPlanId: tp.id,
                            returnAppointment,
                            groupedProcedures: upcomingPhaseProceduresGroup,
                        },
                    ],
                };
            } else {
                result.push({
                    phases: [
                        {
                            phaseId,
                            phaseName: treatmentPlanPhase?.displayName ?? '',
                            phaseProcedures,
                            patientCost,
                            preAuthStatus,
                            treatmentPlanId: tp.id,
                            returnAppointment,
                            groupedProcedures: upcomingPhaseProceduresGroup,
                        },
                    ],
                    treatmentPlanId: tp.id,
                    indexOfTreatmentPlan: indexOfTP,
                    treatmentPlanSignedDate: tp?.signedDate,
                    treatmentPlanStatus: tp.status,
                });
            }
        }
    });

    return result;
}

export function getGroupedProcedureCodes(procedureCodes: string[]) {
    const groupedCodes = groupBy(procedureCodes);
    return map(groupedCodes, (codes, code) => ({ code, count: codes.length }));
}

export function convertAppointmentProceduresToPhasedProcedures(
    treatmentPlanPhasedProcedures: IChartTreatmentPlanPhaseProcedure[],
    appointmentProcedures: IAppointmentProcedure[],
) {
    return treatmentPlanPhasedProcedures.filter((proc) => {
        return appointmentProcedures.findIndex((aProc) => proc.id === aProc.treatmentPlanPhaseProcedureId) > -1;
    });
}

export function getSelectedUpcomingPhaseData(
    upcomingTp: IUpcomingTreatmentPlan | undefined,
    appointmentProcedures: IAppointmentProcedure[],
): IUpcomingTreatmentPlanPhaseData[] {
    const selectedUpcomingTpPhaseData = upcomingTp?.phases
        .map((phase) => ({
            ...phase,
            phaseProcedures: phase.phaseProcedures.filter(
                (p) => appointmentProcedures.findIndex((aP) => aP.treatmentPlanPhaseProcedureId === p.id) > -1,
            ),
        }))
        .filter((phase) => phase.phaseProcedures?.length);

    return selectedUpcomingTpPhaseData ?? [];
}

export const selectUpcomingTreatmentPlans = createSelector(
    [selectUpcomingProceduresAsList, selectSignedTreatmentPlans, selectPatientCheckoutTasksData],
    (billingProcedures, treatmentPlans, checkoutTasks) => {
        return createTreatmentPlanPhaseViewModel(billingProcedures, treatmentPlans, checkoutTasks);
    },
);

export const selectSelectedCheckoutUpcomingPhases = (state: RootState): IUpcomingTreatmentPlanPhaseData[] =>
    state.scheduling.appointmentCheckout.selectedUpcomingPhases.filter((phase) => phase !== undefined);

export const selectSelectedCheckoutUpcomingPhaseBillingProcedures = createSelector(
    selectSelectedCheckoutUpcomingPhases,
    (phases) => flatten(phases.map((phase) => phase.phaseProcedures)),
);

export const selectCompletedProceduresTotal = createSelector(selectCompletedProceduresAsList, getBillingProcedureTotals);
export const selectUpcomingProceduresTotal = createSelector(selectUpcomingTreatmentPlans, (plans) =>
    plans.map((p) => p.phases.map((phase) => phase.patientCost).reduce((a, b) => a + b, 0)).reduce((a, b) => a + b, 0),
);

//Layouts
export const selectLayouts = (state: RootState): IOperatoryCalendarLayout[] => state.scheduling.layouts.data ?? [];

//Allocations
export const selectPatientAllocationsDragged = (state: RootState): IPatientAppointment[] =>
    state.scheduling.allocations.draggedPatient;
export const selectBlockAllocationsDragged = (state: RootState): IBlockAppointment[] => state.scheduling.allocations.draggedBlock;
export const selectAllocations = (state: RootState): IAppointmentAllocations | undefined => state.scheduling.allocations.data;

export const selectPatientAllocationsCount = createSelector(selectAllocations, (data) => {
    const appointments = data?.patients?.length ? data?.patients.filter((item) => !item.isDeleted) : [];
    if (appointments?.length) return appointments.length;
    return 0;
});
export const selectPatientCancelledAllocations = createSelector(selectAllocations, (data) => {
    const cancelledAppointments = data?.patients?.length ? data?.patients.filter((item) => item.isDeleted) : [];
    if (cancelledAppointments && cancelledAppointments.length) return cancelledAppointments;
    return [];
});

//Patient Appointment
export const selectSelectedAppointmentState = (state: RootState) => state.scheduling.selectedAppointment;
export const selectSelectedAppointmentData = createSelector(
    selectSelectedAppointmentState,
    (state) => state.data as IPatientAppointment | undefined,
);

export const selectPreviousAppointmentData = (state: RootState): IPreviousAppointmentData | undefined =>
    state.scheduling.selectedAppointment.previousAppointmentData;
export const selectSelectedAppointmentPatient = (state: RootState): IPatient | undefined =>
    state.scheduling.selectedAppointment.patient;
export const selectSelectedAppointmentPatientEncounter = createSelector(
    selectSelectedAppointmentState,
    (state) => state.encounter,
);

export const selectSelectedAppointmentPatientInsurances = (state: RootState): IPatientInsurance[] =>
    state.scheduling.selectedAppointment.patient?.insurances ?? [];
export const selectSelectedAppointmentPatientFilteredInsurances = createSelector(
    selectSelectedAppointmentPatientInsurances,
    (insurances) => insurances.filter((ins) => !ins.isDeleted),
);
export const selectSelectedAppointmentPrimaryInsurance = createSelector(selectSelectedAppointmentPatient, (patient) =>
    patient?.insurances?.length ? patient.insurances.filter((insurance) => !insurance.isDeleted)[0] : undefined,
);
export const selectActivePrimaryAppointmentPatientInsurance = createSelector(
    [selectSelectedAppointmentPrimaryInsurance, selectSelectedAppointmentPatientEncounter],
    (insurance, encounter) => {
        const patientInsuranceIsActive = isDateBetween({
            dateToCheck: encounter?.createdOn,
            start: insurance?.effectiveDate,
            end: insurance?.expirationDate,
        });
        return patientInsuranceIsActive ? insurance : undefined;
    },
);

export const selectSelectedAppointmentLoading = (state: RootState): LoadingStatuses =>
    state.scheduling.selectedAppointment.loading;

//Block Appointment
export const selectBlockAppointment = (state: RootState): IBlockAppointment | undefined =>
    state.scheduling.selectedAppointment.data as IBlockAppointment | undefined;

//LocationsOfCare
export const selectLocationsOfCare = (state: RootState): ILocationOfCare[] => state.scheduling.locationsOfCare.data ?? [];
export const selectLocationsOfCareLoading = (state: RootState): LoadingStatuses => state.scheduling.locationsOfCare.loading;
export const selectLocationOfCareOptions = createSelector(selectLocationsOfCare, (locs) => {
    const locationOfCareOptions = locs
        .filter((res) => !res.isDeleted)
        .map((loc) => {
            const opt: IDropdownOption = { key: loc.id, text: loc.displayName, data: loc };
            return opt;
        });

    return locationOfCareOptions;
});

//Calendar Events Selector

export interface IAppointmentContentArg extends EventContentArg {
    event: IAppointmentEventApi;
}

export interface IAppointmentEventApi extends EventApi {
    extendedProps: IPatientAppointmentView | IBlockAppointmentView;
}

export interface IPatientAppointmentEvent extends EventInput {
    extendedProps: IPatientAppointmentView;
}

export interface IBlockAppointmentEvent extends EventInput {
    extendedProps: IBlockAppointmentView;
}

export interface IBlockAppointmentView extends IBlockAppointment {
    type: AppointmentType;
    providers?: string[];
}

export interface IPatientAppointmentView extends IPatientAppointment {
    patientId: string;
    id: string;
    hygienistDisplayName?: string;
    procedureCodeList?: string[];
    patientFullName: string;
    providerDisplayName: string;
    providerType: string;
    providerColor: string | undefined;
    insuranceName: string | undefined;
    appointmentStatus: string | undefined;
    type: AppointmentType;
}

// For some reason using selectTreatmentPlans selector causes the app to infinitely loop :(
const selectAllActiveTreatmentPlans = createSelector(selectAllTreatmentPlans, (treatmentPlans) =>
    treatmentPlans.filter((tp) => !tp.isDeleted),
);

export const selectPendingChartTreatmentPlanPhaseProcedures = createSelector(selectAllActiveTreatmentPlans, (treatmentPlans) => {
    const pendingProcedures = (procedure: IChartTreatmentPlanPhaseProcedure) => procedure.status === ChartProcedureStatus.Pending;
    return flatten(treatmentPlans.map((treatmentPlan) => treatmentPlan.procedures ?? [])).filter(pendingProcedures);
});

export const selectAllChartTreatmentPlanPhaseProcedures = createSelector(selectAllActiveTreatmentPlans, (treatmentPlans) => {
    const allProcedures = (procedure: IChartTreatmentPlanPhaseProcedure) => procedure.status !== ChartProcedureStatus.Removed;
    return flatten(treatmentPlans.map((treatmentPlan) => treatmentPlan.procedures ?? [])).filter(allProcedures);
});

export const selectAllAppointmentPhaseProcedures = createSelector(
    selectAllChartTreatmentPlanPhaseProcedures,
    selectSelectedAppointmentData,
    (phaseProcedures, appointment) => {
        return convertAppointmentProceduresToPhasedProcedures(phaseProcedures, appointment?.procedures ?? []);
    },
);

export interface IPatientProcedures {
    id?: string;
    procedureId: string;
    code?: string;
    description: string | undefined;
    toothIds?: number[];
    areas?: (keyof typeof ToothArea)[];
    treatmentPlanDate?: string;
    treatingProvider: string;
    treatmentPlanPhase?: string;
    preAuthStatus?: string;
}

export const selectPatientAppointmentPhaseProcedures = createSelector(
    [
        selectPendingChartTreatmentPlanPhaseProcedures,
        selectAllActiveTreatmentPlans,
        selectSelectedAppointmentData,
        selectAllAppointmentPhaseProcedures,
    ],
    (pendingPhasedProcedures, treatmentPlans, appointment, apptPhaseProcedures) => {
        if (appointment) {
            const isAppointmentPast =
                new Date(classicDateOnly(appointment.date)) < new Date(classicDateOnly(new Date().toISOString()));

            const procedures: IChartTreatmentPlanPhaseProcedure[] = [];
            [...pendingPhasedProcedures, ...apptPhaseProcedures].forEach((proc) => {
                const proceduresForPhase = procedures.filter((p) => p.phaseId === proc.phaseId);
                const indexOfProc = proceduresForPhase.findIndex((p) => p.id === proc.id);
                if (indexOfProc === -1) procedures.push(proc);
            });
            const phasedProcedures = isAppointmentPast ? apptPhaseProcedures : procedures;
            return createTreatmentPlanPhaseViewModel(phasedProcedures, treatmentPlans);
        }
        return undefined;
    },
);

export const selectLatestSignedTreatmentPlanView = createSelector(
    selectPatientAppointmentPhaseProcedures,
    selectSelectedAppointmentData,
    (plans, selectedAppointment) => {
        if (plans && plans.length) {
            const treatmentPlans = plans
                //Get all signed TPs
                .filter((plan) => plan.treatmentPlanStatus === ChartTreatmentPlanStatus.Signed)
                //Get all TPs that have a signed date that is less than or equal to the current appt date.
                .filter((plan) => {
                    const treatmentPlanSignedDate = plan.treatmentPlanSignedDate;
                    const selectedAppointmentDate = selectedAppointment?.date;
                    if (treatmentPlanSignedDate && selectedAppointmentDate) {
                        return classicDateOnly(treatmentPlanSignedDate) <= classicDateOnly(selectedAppointmentDate);
                    }
                    return false;
                });
            //Order and return the latest appt
            return orderBy(treatmentPlans, ['treatmentPlanSignedDate'], ['desc'])[0] as IUpcomingTreatmentPlan | undefined;
        }
    },
);

export const selectAppointmentPatientProcedureTreatingProviderIds = createSelector(
    selectPendingChartTreatmentPlanPhaseProcedures,
    (procedures) => {
        return procedures.map((proc) => proc.treatingProviderId).filter((provider) => provider !== undefined) as string[];
    },
);

// [Scheduling Quickview] ==========================================
export const selectActiveProviders = createSelector(
    selectFilteredProviders,
    selectSelectedDate,
    selectSelectedLOC,
    (providers, date, loc) => {
        if (providers && loc && date) {
            const activeProviders = providers.filter(provideNotDeleted).filter((p) => providerAtLoc(p, loc.id, date));
            return activeProviders;
        }
        return [];
    },
);

type ProviderTypes =
    | 'isTreatingProvider'
    | 'isRegisteredDentalAssistant'
    | 'isSupervisingProvider'
    | 'isHygienist'
    | 'isResident'
    | 'isAttestingHygienist';
export const selectActiveProviderByType = createSelector(
    selectActiveProviders,
    (_: RootState, providerType: ProviderTypes[] | ProviderTypes) => providerType,
    (providers, providerType) => {
        if (Array.isArray(providerType)) {
            return providers
                .filter((p) => {
                    return some(providerType, (type) => p[type as keyof IProvider] === true);
                })
                .sort((a, b) => {
                    const nameA = `${a.lastName} ${a.firstName} `;
                    const nameB = `${b.lastName} ${b.firstName} `;
                    return nameA.localeCompare(nameB);
                });
        } else {
            return providers
                .filter((p) => p[providerType as keyof IProvider])
                .sort((a, b) => {
                    const nameA = `${a.lastName} ${a.firstName} `;
                    const nameB = `${b.lastName} ${b.firstName} `;
                    return nameA.localeCompare(nameB);
                });
        }
    },
);

// [Appointment Overview | Schedule Appointment] ==========================================
export const selectAppointmentOverviewActiveProviders = createSelector(
    selectFilteredProviders,
    selectSelectedLOC,
    selectSelectedAppointmentData,
    (providers, loc, appt) => {
        if (appt && appt.date) {
            const date = new Date(classicDateOnly(appt.date));

            if (providers && loc && date) {
                const activeProviders = providers.filter(provideNotDeleted).filter((p) => providerAtLoc(p, loc.id, date));
                return activeProviders;
            }
        }
        return [];
    },
);

function provideNotDeleted(p: IProvider) {
    return !p.isDeleted;
}
/**
 * Check if the provided provider is at the LOC and
 *
 * @param {IProvider} p
 * @param {string} locId
 * @param {WeekDays} dayOfWeek
 * @param {Date} date
 * @return {*}  {boolean}
 */
function providerAtLoc(p: IProvider, locId: string, date: Date): boolean {
    const dayOfWeek = getDayOfWeekString(date);

    let matchingProvider = false;
    if (p.providerSchedule && p.providerSchedule.periods && dayOfWeek) {
        p.providerSchedule.periods.forEach((period) => {
            // Provider's period is active for the given date
            const activeDuringPeriod = isDateBetween({
                dateToCheck: classicDateOnly(date.toISOString()),
                start: period.startDate,
                end: period.endDate,
            });

            if (activeDuringPeriod) {
                period.schedules?.forEach((schedule) => {
                    if (schedule.locationOfCareId === locId) {
                        // Provider has a schedule, timeTable, and the timeTable has a [day] key
                        if (schedule.timeTables && schedule.timeTables[dayOfWeek]) {
                            // The selected timeTable[day] has a length, ie: start/stop
                            matchingProvider = schedule.timeTables[dayOfWeek]?.length ? true : false;
                        }
                    }
                });
            }
        });
        return matchingProvider;
    }
    return matchingProvider;
}
