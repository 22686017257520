import { IChartProcedure } from 'api/models/chart.model';
import { ConflictRuleType } from 'api/models/lookup.model';
import { IPipelineError, PipelineError } from '../pipeline';
import ProcedureConflictRulesPipeline from '../procedureConflictRules.pipeline';
import { createRule } from '../ruleGenerator';

const ProcedurePrimaryToothOnly = createRule<ProcedureConflictRulesPipeline, IChartProcedure>({
    ruleTypes: [ConflictRuleType.permanentToothOnly],
    rule: (pipeline, item) => {
        const _errors: IPipelineError[] = [];
        if (item.toothIds?.length && !pipeline.getAreTeethPermanent(item.toothIds)) {
            _errors.push({ type: PipelineError.PermanentToothOnly, data: pipeline.getProcedure(item) });
        }

        return { shouldRemoveItem: _errors.length ? true : false, errors: _errors };
    },
});

export default ProcedurePrimaryToothOnly;
