import { FontIcon, mergeStyles, SelectionMode, Stack, Text, useTheme } from '@fluentui/react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectUpcomingTreatmentPlans,
    selectUpcomingProceduresTreatingProviderIds,
    selectSelectedCheckoutUpcomingPhases,
    IUpcomingTreatmentPlanPhaseData,
} from 'state/slices/scheduling/scheduling.selectors';
import { setSelectedCheckoutUpcomingPhases } from 'state/slices/scheduling/scheduling.slice';
import { selectTheme } from 'state/slices/ui/ui.slice';
import UnscheduledTreatmentPlanPhaseRow from './UnscheduledTreatmentPlanPhaseRow';

export default function CheckoutUnscheduledPhases(): JSX.Element {
    const dispatch = useDispatch();
    const themeType = useSelector(selectTheme);
    const theme = useTheme();
    const [expanded, setExpanded] = useState<boolean>(true);

    const upcomingTreatmentPlans = useSelector(selectUpcomingTreatmentPlans);
    const upcomingTreatingProviderIds = useSelector(selectUpcomingProceduresTreatingProviderIds);
    const selectedTreatmentPlanPhases = useSelector(selectSelectedCheckoutUpcomingPhases);

    function onItemsSelected(selectedPhases: IUpcomingTreatmentPlanPhaseData[]) {
        dispatch(setSelectedCheckoutUpcomingPhases(selectedPhases));
    }

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    const iconColumnClass = mergeStyles({
        fontSize: 15,
        fontWeight: 'bold !important',
        height: 24,
        width: 24,
        padding: '0 5px',
        cursor: 'pointer',
        color: theme.palette.black,
    });

    const name = `Treatment Plan ${(upcomingTreatmentPlans[0]?.indexOfTreatmentPlan ?? 0) + 1}`;

    return (
        <Stack>
            <Stack
                style={{
                    backgroundColor: themeType === 'dark' ? 'rgb(17, 17, 17)' : 'rgb(255, 255, 255)',
                    paddingLeft: 10,
                    paddingRight: 15,
                    paddingTop: 10,
                    paddingBottom: 10,
                    marginRight: 5,
                    marginLeft: 5,
                }}
                tokens={{ childrenGap: 15 }}
                horizontal
                grow
            >
                <Stack.Item
                    onClick={toggleExpanded}
                    style={{
                        cursor: 'pointer',
                    }}
                >
                    <FontIcon iconName={`${!expanded ? 'ChevronDown' : 'ChevronUp'}`} className={iconColumnClass} />
                </Stack.Item>
                <Stack.Item grow>
                    <Text style={{ fontWeight: 600 }}>{name}</Text>
                </Stack.Item>
            </Stack>
            <UnscheduledTreatmentPlanPhaseRow
                treatmentPlanId={upcomingTreatmentPlans[0]?.treatmentPlanId}
                treatingProviderIds={upcomingTreatingProviderIds}
                item={upcomingTreatmentPlans[0]}
                selectedTreatmentPlanPhases={selectedTreatmentPlanPhases}
                onItemsSelected={onItemsSelected}
                selectionMode={SelectionMode.multiple}
            />
        </Stack>
    );
}
