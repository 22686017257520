export type FormPropertyValidator = {
    property: string;
    isInvalid: () => boolean;
    errorMessage: string;
};

export const validateForm = (validators: FormPropertyValidator[], setHasError: (value: boolean) => void) => {
    for (const validator of validators) {
        if (validator.isInvalid()) {
            return setHasError(true);
        }
    }
    return setHasError(false);
};

export const validateProperty = (validators: FormPropertyValidator[], property: string) => {
    const propertyValidator = validators.filter((x) => x.property === property);
    for (const validator of propertyValidator) {
        if (validator.isInvalid()) {
            return validator.errorMessage;
        }
    }
    return undefined;
};
