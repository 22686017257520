import { ChoiceGroup, IChoiceGroupOption, Stack, Text, TextField, useTheme } from '@fluentui/react';
import { PaymentMethod } from 'api/models/patient.model';
import { useSelector } from 'hooks';
import { getValidationError } from 'hooks/useValidation';
import { FormEvent } from 'react';
import { useDispatch } from 'react-redux';
import { updatePatientPaymentSource } from 'state/slices/ledger/ledger.slice';
import {
    selectHidePatientPaymentSourceIdentifier,
    selectPatientPaymentMethodIsCheckOrAuth,
    selectPatientPaymentsAndAdjustmentsValidationErrors,
    selectPatientPaymentSource,
} from 'state/slices/ledger/patient-payments-and-adjustments/patient-payments-and-adjustments.selectors';

type Props = {
    required?: boolean;
};

export default function PaymentMethods({ required = true }: Props) {
    const dispatch = useDispatch();
    const { palette } = useTheme();

    const paymentSource = useSelector(selectPatientPaymentSource);
    const hideMethodIdentifier = useSelector(selectHidePatientPaymentSourceIdentifier);
    const methodIdentifierLabel = useSelector(selectPatientPaymentMethodIsCheckOrAuth);
    const validationErrors = useSelector(selectPatientPaymentsAndAdjustmentsValidationErrors);

    const paymentMethodStyles = {
        root: {
            display: 'inline-flex',
            paddingLeft: 10,
            marginTop: 0,
        },
    };

    const _onChangeMethod = (e?: FormEvent, option?: IChoiceGroupOption) => {
        if (paymentSource?.methodIdentifier !== option?.key) {
            dispatch(updatePatientPaymentSource({ path: 'methodIdentifier', value: '' }));
        }
        dispatch(updatePatientPaymentSource({ path: 'method', value: option?.key }));
    };

    const _onChangeMethodIdentifier = (e?: FormEvent, value?: string) =>
        dispatch(updatePatientPaymentSource({ path: 'methodIdentifier', value }));

    return (
        <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Stack>
                <ChoiceGroup
                    label="Payment Method"
                    required={required}
                    selectedKey={paymentSource?.method}
                    onChange={_onChangeMethod}
                    options={[
                        {
                            key: PaymentMethod.Visa,
                            text: PaymentMethod.Visa,
                            styles: paymentMethodStyles,
                        },
                        {
                            key: PaymentMethod.Mastercard,
                            text: PaymentMethod.Mastercard,
                            styles: paymentMethodStyles,
                        },
                        {
                            key: PaymentMethod.AmEx,
                            text: PaymentMethod.AmEx,
                            styles: paymentMethodStyles,
                        },
                        {
                            key: PaymentMethod.Discover,
                            text: PaymentMethod.Discover,
                            styles: paymentMethodStyles,
                        },
                        {
                            key: PaymentMethod.Cash,
                            text: PaymentMethod.Cash,
                            styles: paymentMethodStyles,
                        },
                        {
                            key: PaymentMethod.Check,
                            text: 'Check/ACH',
                            styles: paymentMethodStyles,
                        },
                        {
                            key: PaymentMethod.Credit,
                            text: PaymentMethod.Credit,
                            styles: paymentMethodStyles,
                        },
                    ]}
                />
                {required && getValidationError(validationErrors ?? [], 'Payment Method') ? (
                    <Text variant="small" style={{ color: palette.redDark }}>
                        Payment Method is required.
                    </Text>
                ) : null}
            </Stack>
            {!hideMethodIdentifier && (
                <Stack grow>
                    <TextField
                        required
                        label={methodIdentifierLabel}
                        prefix="#"
                        value={paymentSource?.methodIdentifier}
                        onChange={_onChangeMethodIdentifier}
                        errorMessage={
                            getValidationError(validationErrors ?? [], methodIdentifierLabel)
                                ? `${methodIdentifierLabel} is required.`
                                : undefined
                        }
                    />
                </Stack>
            )}
        </Stack>
    );
}
