import { Dictionary } from '@reduxjs/toolkit';
import IPrescriptionItem from 'api/models/prescription.model';
import { createSelector } from 'reselect';
import { RootState } from 'state/store';
import { PrescriptionsPanelState } from './prescriptions-panel.slice';
import { map } from 'lodash';

export const selectPrescriptionsPanel = (state: RootState): PrescriptionsPanelState => state.prescriptionsPanel;
export const selectPrescriptionItems = (state: RootState): Dictionary<IPrescriptionItem> =>
    state.prescriptionsPanel.prescriptionResults;
export const patientPrescriptionsList = createSelector(selectPrescriptionsPanel, (state) =>
    state.data.filter((item) => !item.isDeleted),
);

export const selectPrescriptionItemsList = createSelector(selectPrescriptionItems, (items) => {
    return (map(items, (r) => r).filter(itemExists) as IPrescriptionItem[]).filter(doesNotExistInPrescriptions);

    function doesNotExistInPrescriptions(item: IPrescriptionItem) {
        return true;
    }

    function itemExists(item?: IPrescriptionItem) {
        return item !== undefined && !item.isDeleted;
    }
});
