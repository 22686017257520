import { IDropdownOption } from '@fluentui/react';
import { useSelector } from 'react-redux';
import { selectCurrentOperatories } from 'state/slices/lookups/operatories/operatories.selectors';

export const timeOptions: IDropdownOption[] = [
    { key: '00:00', text: '12:00 AM' },
    { key: '00:10', text: '12:10 AM' },
    { key: '00:15', text: '12:15 AM' },
    { key: '00:20', text: '12:20 AM' },
    { key: '00:30', text: '12:30 AM' },
    { key: '00:40', text: '12:40 AM' },
    { key: '00:45', text: '12:45 AM' },
    { key: '00:50', text: '12:50 AM' },

    { key: '01:00', text: '01:00 AM' },
    { key: '01:10', text: '01:10 AM' },
    { key: '01:15', text: '01:15 AM' },
    { key: '01:20', text: '01:20 AM' },
    { key: '01:30', text: '01:30 AM' },
    { key: '01:40', text: '01:40 AM' },
    { key: '01:45', text: '01:45 AM' },
    { key: '01:50', text: '01:50 AM' },

    { key: '02:00', text: '02:00 AM' },
    { key: '02:10', text: '02:10 AM' },
    { key: '02:15', text: '02:15 AM' },
    { key: '02:20', text: '02:20 AM' },
    { key: '02:30', text: '02:30 AM' },
    { key: '02:40', text: '02:40 AM' },
    { key: '02:45', text: '02:45 AM' },
    { key: '02:50', text: '02:50 AM' },

    { key: '03:00', text: '03:00 AM' },
    { key: '03:10', text: '03:10 AM' },
    { key: '03:15', text: '03:15 AM' },
    { key: '03:20', text: '03:20 AM' },
    { key: '03:30', text: '03:30 AM' },
    { key: '03:40', text: '03:40 AM' },
    { key: '03:45', text: '03:45 AM' },
    { key: '03:50', text: '03:50 AM' },

    { key: '04:00', text: '04:00 AM' },
    { key: '04:10', text: '04:10 AM' },
    { key: '04:15', text: '04:15 AM' },
    { key: '04:20', text: '04:20 AM' },
    { key: '04:30', text: '04:30 AM' },
    { key: '04:40', text: '04:40 AM' },
    { key: '04:45', text: '04:45 AM' },
    { key: '04:50', text: '04:50 AM' },

    { key: '05:00', text: '05:00 AM' },
    { key: '05:10', text: '05:10 AM' },
    { key: '05:15', text: '05:15 AM' },
    { key: '05:20', text: '05:20 AM' },
    { key: '05:30', text: '05:30 AM' },
    { key: '05:40', text: '05:40 AM' },
    { key: '05:45', text: '05:45 AM' },
    { key: '05:50', text: '05:50 AM' },

    { key: '06:00', text: '06:00 AM' },
    { key: '06:10', text: '06:10 AM' },
    { key: '06:15', text: '06:15 AM' },
    { key: '06:20', text: '06:20 AM' },
    { key: '06:30', text: '06:30 AM' },
    { key: '06:40', text: '06:40 AM' },
    { key: '06:45', text: '06:45 AM' },
    { key: '06:50', text: '06:50 AM' },

    { key: '07:00', text: '07:00 AM' },
    { key: '07:10', text: '07:10 AM' },
    { key: '07:15', text: '07:15 AM' },
    { key: '07:20', text: '07:20 AM' },
    { key: '07:30', text: '07:30 AM' },
    { key: '07:40', text: '07:40 AM' },
    { key: '07:45', text: '07:45 AM' },
    { key: '07:50', text: '07:50 AM' },

    { key: '08:00', text: '08:00 AM' },
    { key: '08:10', text: '08:10 AM' },
    { key: '08:15', text: '08:15 AM' },
    { key: '08:20', text: '08:20 AM' },
    { key: '08:30', text: '08:30 AM' },
    { key: '08:40', text: '08:40 AM' },
    { key: '08:45', text: '08:45 AM' },
    { key: '08:50', text: '08:50 AM' },

    { key: '09:00', text: '09:00 AM' },
    { key: '09:10', text: '09:10 AM' },
    { key: '09:15', text: '09:15 AM' },
    { key: '09:20', text: '09:20 AM' },
    { key: '09:30', text: '09:30 AM' },
    { key: '09:40', text: '09:40 AM' },
    { key: '09:45', text: '09:45 AM' },
    { key: '09:50', text: '09:50 AM' },

    { key: '10:00', text: '10:00 AM' },
    { key: '10:10', text: '10:10 AM' },
    { key: '10:15', text: '10:15 AM' },
    { key: '10:20', text: '10:20 AM' },
    { key: '10:30', text: '10:30 AM' },
    { key: '10:40', text: '10:40 AM' },
    { key: '10:45', text: '10:45 AM' },
    { key: '10:50', text: '10:50 AM' },

    { key: '11:00', text: '11:00 AM' },
    { key: '11:10', text: '11:10 AM' },
    { key: '11:15', text: '11:15 AM' },
    { key: '11:20', text: '11:20 AM' },
    { key: '11:30', text: '11:30 AM' },
    { key: '11:40', text: '11:40 AM' },
    { key: '11:45', text: '11:45 AM' },
    { key: '11:50', text: '11:50 AM' },

    { key: '12:00', text: '12:00 PM' },
    { key: '12:10', text: '12:10 PM' },
    { key: '12:15', text: '12:15 PM' },
    { key: '12:20', text: '12:20 PM' },
    { key: '12:30', text: '12:30 PM' },
    { key: '12:40', text: '12:40 PM' },
    { key: '12:45', text: '12:45 PM' },
    { key: '12:50', text: '12:50 PM' },

    { key: '13:00', text: '01:00 PM' },
    { key: '13:10', text: '01:10 PM' },
    { key: '13:15', text: '01:15 PM' },
    { key: '13:20', text: '01:20 PM' },
    { key: '13:30', text: '01:30 PM' },
    { key: '13:40', text: '01:40 PM' },
    { key: '13:45', text: '01:45 PM' },
    { key: '13:50', text: '01:50 PM' },

    { key: '14:00', text: '02:00 PM' },
    { key: '14:10', text: '02:10 PM' },
    { key: '14:15', text: '02:15 PM' },
    { key: '14:20', text: '02:20 PM' },
    { key: '14:30', text: '02:30 PM' },
    { key: '14:40', text: '02:40 PM' },
    { key: '14:45', text: '02:45 PM' },
    { key: '14:50', text: '02:50 PM' },

    { key: '15:00', text: '03:00 PM' },
    { key: '15:10', text: '03:10 PM' },
    { key: '15:15', text: '03:15 PM' },
    { key: '15:20', text: '03:20 PM' },
    { key: '15:30', text: '03:30 PM' },
    { key: '15:40', text: '03:40 PM' },
    { key: '15:45', text: '03:45 PM' },
    { key: '15:50', text: '03:50 PM' },

    { key: '16:00', text: '04:00 PM' },
    { key: '16:10', text: '04:10 PM' },
    { key: '16:15', text: '04:15 PM' },
    { key: '16:20', text: '04:20 PM' },
    { key: '16:30', text: '04:30 PM' },
    { key: '16:40', text: '04:40 PM' },
    { key: '16:45', text: '04:45 PM' },
    { key: '16:50', text: '04:50 PM' },

    { key: '17:00', text: '05:00 PM' },
    { key: '17:10', text: '05:10 PM' },
    { key: '17:15', text: '05:15 PM' },
    { key: '17:20', text: '05:20 PM' },
    { key: '17:30', text: '05:30 PM' },
    { key: '17:40', text: '05:40 PM' },
    { key: '17:45', text: '05:45 PM' },
    { key: '17:50', text: '05:50 PM' },

    { key: '18:00', text: '06:00 PM' },
    { key: '18:10', text: '06:10 PM' },
    { key: '18:15', text: '06:15 PM' },
    { key: '18:20', text: '06:20 PM' },
    { key: '18:30', text: '06:30 PM' },
    { key: '18:40', text: '06:40 PM' },
    { key: '18:45', text: '06:45 PM' },
    { key: '18:50', text: '06:50 PM' },

    { key: '19:00', text: '07:00 PM' },
    { key: '19:10', text: '07:10 PM' },
    { key: '19:15', text: '07:15 PM' },
    { key: '19:20', text: '07:20 PM' },
    { key: '19:30', text: '07:30 PM' },
    { key: '19:40', text: '07:40 PM' },
    { key: '19:45', text: '07:45 PM' },
    { key: '19:50', text: '07:50 PM' },

    { key: '20:00', text: '08:00 PM' },
    { key: '20:10', text: '08:10 PM' },
    { key: '20:15', text: '08:15 PM' },
    { key: '20:20', text: '08:20 PM' },
    { key: '20:30', text: '08:30 PM' },
    { key: '20:40', text: '08:40 PM' },
    { key: '20:45', text: '08:45 PM' },
    { key: '20:50', text: '08:50 PM' },

    { key: '21:00', text: '09:00 PM' },
    { key: '21:10', text: '09:10 PM' },
    { key: '21:15', text: '09:15 PM' },
    { key: '21:20', text: '09:20 PM' },
    { key: '21:30', text: '09:30 PM' },
    { key: '21:40', text: '09:40 PM' },
    { key: '21:45', text: '09:45 PM' },
    { key: '21:50', text: '09:50 PM' },

    { key: '22:00', text: '10:00 PM' },
    { key: '22:10', text: '10:10 PM' },
    { key: '22:15', text: '10:15 PM' },
    { key: '22:20', text: '10:20 PM' },
    { key: '22:30', text: '10:30 PM' },
    { key: '22:40', text: '10:40 PM' },
    { key: '22:45', text: '10:45 PM' },
    { key: '22:50', text: '10:50 PM' },

    { key: '23:00', text: '11:00 PM' },
    { key: '23:10', text: '11:10 PM' },
    { key: '23:15', text: '11:15 PM' },
    { key: '23:20', text: '11:20 PM' },
    { key: '23:30', text: '11:30 PM' },
    { key: '23:40', text: '11:40 PM' },
    { key: '23:45', text: '11:45 PM' },
    { key: '23:50', text: '11:50 PM' },
];

export const getTimeOptions = (timeValue?: Date): IDropdownOption<string>[] =>
    timeOptions.map((option) => {
        const isSelected =
            timeValue?.toLocaleTimeString().substring(0, 5) === option.text.substring(0, 5) ||
            timeValue?.toLocaleTimeString().substring(0, 4) === option.text.substring(0, 4);
        option.selected = isSelected;
        return option;
    });

export const getTimeTextFromValue = (timeValue?: string): string | undefined =>
    timeOptions.find((option) => option.key === timeValue)?.text;

export const getTimeOptionsByTimeString = (timeValue?: string): IDropdownOption<string>[] =>
    timeOptions.map((option) => {
        option.selected = timeValue === option.key;
        return option;
    });
