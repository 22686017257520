import { DirectionalHint, IColumn, Link, Persona, PersonaSize, Stack, Text, Toggle, TooltipHost } from '@fluentui/react';
import IProvider, { Timetable } from 'api/models/provider.model';
import { isAfter, isBefore } from 'date-fns';
import useLookupsStore from 'hooks/store/useLookupsStore';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { Provider } from 'models/provider';
import WeekSchedule from 'pages/components/WeekSchedule/WeekSchedule';
import LookupDetailsList from '../Shared/LookupDetailsList';

type DetailsListProps = {
    searchTerm: string;
};

function DetailsList({ searchTerm }: DetailsListProps): JSX.Element {
    const { setSelectedLookup, updateLookup } = useLookupsStore<IProvider>('Providers');

    const renderWeekSchedule = (item: IProvider) => {
        if (item && item.providerSchedule?.periods) {
            let schedule: Timetable | undefined = undefined;
            item.providerSchedule.periods.forEach((period) => {
                const today = new Date();
                const start = new Date(period.startDate ?? '');
                const end = new Date(period.endDate ?? '');
                const _isAfter = isAfter(today, start);
                const _isBefore = isBefore(today, end);
                if (_isAfter && _isBefore) {
                    schedule = period.schedules?.find((schedule) => schedule.timeTables ?? {}) as Timetable;
                }
            });
            return <WeekSchedule schedule={schedule} compact readOnly />;
        }
        return '';
    };

    const columns: IColumn[] = [
        {
            key: 'scheduleColor',
            fieldName: 'scheduleColor',
            name: '',
            minWidth: 24,
            maxWidth: 24,
            onRender: (item: IProvider) => (
                <Persona
                    size={PersonaSize.size24}
                    hidePersonaDetails={true}
                    text={`${item.firstName} ${item.lastName}`}
                    coinProps={{
                        styles: {
                            initials: { background: `${item.providerSchedule?.scheduleColor} !important`, color: 'black' },
                        },
                    }}
                />
            ),
        },
        {
            key: 'displayName',
            fieldName: 'displayName',
            name: 'Name',
            minWidth: 100,
            maxWidth: 200,
            onRender: (item: Provider) => (
                <Text variant="smallPlus">
                    <Link
                        onClick={() => {
                            setSelectedLookup({ item, savingStatus: LoadingStatus.Idle });
                        }}
                    >
                        {item.lastName}, {item.firstName}
                    </Link>
                </Text>
            ),
            getValueKey: (item: IProvider) => `${item.lastName}, ${item.firstName}`,
        },
        {
            key: 'providerType',
            fieldName: 'providerType',
            name: 'Provider Type',
            minWidth: 200,
            maxWidth: 200,
            onRender: (item: IProvider) => (
                <Text variant="smallPlus">
                    {item?.isResident
                        ? 'Resident'
                        : item?.isHygienist
                        ? 'Hygienist'
                        : item?.isRegisteredDentalAssistant
                        ? 'Registered Dental Assistant'
                        : item?.isTreatingProvider
                        ? 'Treating Provider'
                        : null}
                </Text>
            ),
            getValueKey: (item: Provider) =>
                item?.isResident
                    ? 'Resident'
                    : item?.isTreatingProvider
                    ? 'Treating Provider'
                    : item?.isHygienist
                    ? 'Hygienist'
                    : item?.isRegisteredDentalAssistant
                    ? 'Registered Dental Assistant'
                    : '',
        },
        {
            key: 'isAttestingHygienist',
            fieldName: 'isAttestingHygienist',
            name: 'Attesting Hygienist',

            minWidth: 100,
            maxWidth: 200,
            onRender: (item: IProvider) => {
                return item.isHygienist ? (
                    <Stack horizontalAlign="start">
                        <TooltipHost
                            directionalHint={DirectionalHint.topCenter}
                            content="To ensure successful appointment integration, only users mapped in Membership can be set as an Attesting Hygienist"
                        >
                            <Toggle
                                onChange={(_, checked) =>
                                    updateLookup({ ...item, item: { ...item, isAttestingHygienist: checked } })
                                }
                                disabled={!item.mappedProviderId?.length || item.mappedProviderId === '000'}
                                checked={item?.isAttestingHygienist}
                            />
                        </TooltipHost>
                    </Stack>
                ) : null;
            },
            getValueKey: (item: IProvider) => `${item.isAttestingHygienist}`,
        },
    ];

    const filterHandler = (item?: IProvider): boolean => {
        searchTerm = searchTerm.toLowerCase();
        return (
            (item?.firstName ? item.firstName.toLowerCase().includes(searchTerm) : false) ||
            (item?.lastName ? item.lastName.toLowerCase().includes(searchTerm) : false)
        );
    };

    return (
        <LookupDetailsList<IProvider>
            lookupType="Providers"
            columns={columns}
            sortOnMount={true}
            mountSortColumn={['displayName']}
            searchTerm={searchTerm}
            filterHandler={filterHandler}
            onActiveItemChanged={undefined}
            onItemInvoked={(item: IProvider) => setSelectedLookup({ item, savingStatus: LoadingStatus.Idle })}
        />
    );
}

export default DetailsList;
