import { useSelector, useTenantId } from 'hooks';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import ConditionCodes from 'state/slices/charting/conditionCodes';
import { selectTenant, selectLookupLoaders } from 'state/slices/tenant';
import { conditionsAsList } from 'state/slices/tenant/conditions.slice';
import { diagnosesList } from 'state/slices/tenant/diagnoses.slice';
import { proceduresList } from 'state/slices/tenant/procedures.slice';
import { selectSlidingFeeProgramsList } from 'state/slices/tenant/sliding-fee.slice';
import {
    getTenantProductIds,
    selectTenantSubscriptionsData,
    selectTenantSubscriptionsLoading,
} from 'state/slices/tenant/subscriptions.slice';
import { getTenantAssets } from '../../state/slices/tenant';

function useTenant() {
    const dispatch = useDispatch();
    const tenantId = useTenantId();

    const _tenant = useSelector(selectTenant);
    const _lookupLoaders = useSelector(selectLookupLoaders);
    const _getTenantAssets = useCallback((tenantId: string) => dispatch(getTenantAssets(tenantId)), [dispatch]);

    const _getDiagnosesData = _tenant.diagnoses.data;
    const _getDiagnosesList = useSelector(diagnosesList);
    const _searchDiagnosesList = (search?: string) =>
        search
            ? _getDiagnosesList.filter((c) => {
                  if (c.displayName && search) {
                      return c.displayName.toLowerCase().indexOf(search.toLowerCase()) > -1;
                  }
                  return false;
              })
            : _getDiagnosesList;

    const _getProceduresData = _tenant.procedures.data ? _tenant.procedures.data : {};
    const _getProceduresList = useSelector(proceduresList);
    const _searchProceduresList = (search: string | undefined) =>
        search
            ? _getProceduresList.filter((procedure) => {
                  if (search) {
                      const displayNameFound = procedure.displayName
                          ? procedure?.displayName?.toLowerCase().indexOf(search.toLowerCase()) > -1
                          : false;
                      const descriptionFound = procedure?.description?.toLowerCase().indexOf(search.toLowerCase()) > -1;
                      return displayNameFound || descriptionFound;
                  }
              })
            : _getProceduresList;
    const _getConditionsAsList = useSelector(conditionsAsList);

    //procedure helpers
    function getProcedureIdByCode(code: string) {
        return _getProceduresList.find((p) => p.code === code)?.id || '';
    }

    function getConditionIdByCode(code: ConditionCodes) {
        return _getConditionsAsList.find((p) => p.code === code)?.id || '';
    }

    //Sliding Fee Programs
    const _slidingFeeProgramsData = _tenant.slidingFeePrograms.data ? _tenant.slidingFeePrograms.data : {};
    const _slidingFeeProgramsList = useSelector(selectSlidingFeeProgramsList);
    const _patientSlidingFeeData = _tenant.slidingFeePrograms.patientSlidingFeePlans;

    const _selectTenantSubscriptionsData = useSelector(selectTenantSubscriptionsData);
    const _selectTenantSubscriptionsLoading = useSelector(selectTenantSubscriptionsLoading);

    // const _getUserApps: BentoBoxTenantApp[] =

    const _getTenantSubscriptions = useCallback(() => {
        dispatch(getTenantProductIds({ tenantId }));
    }, [dispatch, tenantId]);

    return {
        lookupLoaders: _lookupLoaders,
        getTenantAssets: _getTenantAssets,

        getDiagnosesList: _getDiagnosesList,
        getDiagnosesData: _getDiagnosesData,
        searchDiagnosesList: _searchDiagnosesList,

        getProceduresData: _getProceduresData,
        getProceduresList: _getProceduresList,
        searchProceduresList: _searchProceduresList,
        getProcedureIdByCode,
        getConditionIdByCode,

        slidingFeeProgramsData: _slidingFeeProgramsData,
        slidingFeeProgramsList: _slidingFeeProgramsList,
        patientSlidingFeeData: _patientSlidingFeeData,

        selectTenantSubscriptionsData: _selectTenantSubscriptionsData,
        selectTenantSubscriptionsLoading: _selectTenantSubscriptionsLoading,
        getTenantSubscriptions: _getTenantSubscriptions,

        ..._tenant,
    };
}

export default useTenant;
