enum ErrorTypes {
    ServiceUnavailable = 'Service unavailable',

    ModifiedOutside = '412 Error',

}

export const errorTypeMessages: Record<ErrorTypes, string> = {
    [ErrorTypes.ServiceUnavailable]: 'Service is unavailable. Please try again later.',
    [ErrorTypes.ModifiedOutside]: 'Unable to save changes. Record modified by another user. Please copy your recent edits and',
};

export default ErrorTypes;
