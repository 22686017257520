import { useEffect, useReducer, FormEvent, useRef, useState } from 'react';
import { MaskedTextField, ITextFieldProps, IMaskedTextField, IconButton } from '@fluentui/react';

type Props = { handleError?: (error: string | undefined) => void } & ITextFieldProps;

const isValueEmpty = (value?: string) => value === '___-__-____';

const validSSN = (value: string | undefined) => {
    return (value && /^(?!(000|666|9))(\d{3}-?(?!(00))\d{2}-?(?!(0000))\d{4})$/.test(value)) ||
        value === '' ||
        isValueEmpty(value)
        ? true
        : false;
};
const SSNField = ({ value, onChange, style, handleError, ...props }: Props): JSX.Element => {
    const [internalValue, setInternalValue] = useState<string | undefined>(value ? value : '');
    const [revealed, setRevealed] = useState<boolean>(false);

    const ref = useRef<IMaskedTextField>(null);

    useEffect(() => {
        if (value) {
            setInternalValue(value);
        }
    }, [value]);

    const onGetErrorMessage = (value?: string) => {
        const ssnErrorMessage = validSSN(value) ? undefined : 'Invalid SSN';
        if (handleError) handleError(ssnErrorMessage);
        return ssnErrorMessage;
    };

    const handleChange = (e: FormEvent<HTMLTextAreaElement | HTMLInputElement>, value?: string) => {
        setInternalValue(value);
        if (value && onChange) {
            if (validSSN(value)) {
                onChange(e, ref.current?.value);
            } else {
                onChange(e, '');
            }
            if (isValueEmpty(internalValue)) {
                onChange(e, '');
            }
        }
    };

    return (
        <MaskedTextField
            {...props}
            type={revealed ? 'text' : 'password'}
            onRenderSuffix={() => (
                <IconButton
                    onClick={() => setRevealed(!revealed)}
                    iconProps={{ iconName: revealed ? 'Hide' : 'RedEye' }}
                    style={{ height: 28 }}
                />
            )}
            onClick={(ev) => {
                if (!internalValue) ev.currentTarget.setSelectionRange(0, 0);
            }}
            onBlur={() => setRevealed(false)}
            onChange={handleChange}
            label="SSN"
            validateOnFocusOut
            validateOnLoad={value ? true : false}
            onGetErrorMessage={onGetErrorMessage}
            value={internalValue}
            autoComplete="false"
            mask="999-99-9999"
            style={{ ...style }}
            styles={{ suffix: { padding: 0, background: 'transparent' } }}
            componentRef={ref}
        />
    );
};

export default SSNField;
