import { useSelector, useTenantId } from 'hooks';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { updatePatientNoteAndSave } from 'state/slices/patient/patient.actions';
import {
    selectSelectedPatient,
    patientSaving,
    primaryPatientInsurance,
    primaryPatientPayer,
    allPatientEncounters,
} from 'state/slices/patient/patient.selectors';

export default function usePatient() {
    const dispatch = useDispatch();
    const tenantId = useTenantId();

    const _currentPatient = useSelector(selectSelectedPatient);
    const _patientSaving = useSelector(patientSaving);
    const _primaryPatientInsurance = useSelector(primaryPatientInsurance);
    const _primaryPatientPayer = useSelector(primaryPatientPayer);
    const _currentPatientEncounters = useSelector(allPatientEncounters);

    const _updatePatientNoteAndSave = useCallback(
        (note: string | undefined) => {
            dispatch(updatePatientNoteAndSave(tenantId, note));
        },
        [tenantId],
    );

    return {
        //Selectors
        patientSaving: _patientSaving,
        currentPatient: _currentPatient,
        primaryPatientInsurance: _primaryPatientInsurance,
        primaryPatientPayer: _primaryPatientPayer,
        currentPatientEncounters: _currentPatientEncounters,

        //Actions
        updatePatientNoteAndSave: _updatePatientNoteAndSave,
    };
}
