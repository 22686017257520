import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { IAdjustmentCommonTransactionView } from 'api/models/adjustment-history.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { LedgerState } from '../ledger.state';
import { getPatientAdjustmentHistory, reverseSelectedPatientAdjustmentTransactions } from './adjustment-history.actions';
import { adjustmentHistoryInitialState } from './adjustment-history.state';

export const adjustmentHistoryReducers = {
    cleanupAdjustmentHistory: (state: LedgerState): void => {
        state.adjustmentHistory.data = undefined;
    },
    cleanupAdjustmentHistoryPage: (state: LedgerState): void => {
        state.adjustmentHistory = adjustmentHistoryInitialState;
    },
    cleanupAdjustmentHistoryReverseSavedStatusMessageBar: (state: LedgerState): void => {
        state.adjustmentHistory.reversalSaving = LoadingStatus.Idle;
    },
    setAdjustmentHistoryConfirmModalOpen: (state: LedgerState, action: PayloadAction<boolean>): void => {
        state.adjustmentHistory.confirmModalOpen = action.payload;
    },
    setAdjustmentHistoryAdjustmentReason: (state: LedgerState, action: PayloadAction<string | number | undefined>): void => {
        state.adjustmentHistory.filters['adjustmentReasonId'] = action.payload ?? '';
    },
    setAdjustmentHistoryAmount: (state: LedgerState, action: PayloadAction<number | undefined>): void => {
        state.adjustmentHistory.filters['amount'] = action.payload;
    },
    setAdjustmentHistoryTransactionDate: (state: LedgerState, action: PayloadAction<string | undefined>): void => {
        state.adjustmentHistory.filters['transactionDate'] = action.payload ?? '';
    },
    setAdjustmentHistoryEncounterDate: (state: LedgerState, action: PayloadAction<string | undefined>): void => {
        state.adjustmentHistory.filters['encounterDate'] = action.payload ?? '';
    },
    setAdjustmentHistoryDateOfEntry: (state: LedgerState, action: PayloadAction<string | undefined>): void => {
        state.adjustmentHistory.filters['dateOfEntry'] = action.payload ?? '';
    },
    setAdjustmentHistoryEncounterNumber: (state: LedgerState, action: PayloadAction<string | undefined>): void => {
        state.adjustmentHistory.filters['encounterNumber'] = action.payload ?? '';
    },
    toggleShowReversedAdjustments: (state: LedgerState): void => {
        state.adjustmentHistory.showReversedAdjustments = !state.adjustmentHistory.showReversedAdjustments;
    },
    clearAdjustmentHistoryFilters: (state: LedgerState): void => {
        state.adjustmentHistory.filters = adjustmentHistoryInitialState.filters;
    },
    setSelectedAdjustmentHistoryViews: (state: LedgerState, action: PayloadAction<IAdjustmentCommonTransactionView[]>): void => {
        state.adjustmentHistory.selectedAdjustmentViews = action.payload;
    },
};

export const adjustmentHistoryExtraReducers = (
    builder: ActionReducerMapBuilder<LedgerState>,
): ActionReducerMapBuilder<LedgerState> =>
    builder
        .addCase(getPatientAdjustmentHistory.pending, (state) => {
            state.adjustmentHistory.loading = LoadingStatus.Pending;
        })
        .addCase(getPatientAdjustmentHistory.fulfilled, (state, { payload }) => {
            state.adjustmentHistory.loading = LoadingStatus.Completed;
            state.adjustmentHistory.adjustmentHistoryError = undefined;
            state.adjustmentHistory.data = payload;
        })
        .addCase(getPatientAdjustmentHistory.rejected, (state, { error }) => {
            if (error.name !== 'AbortError') {
                state.adjustmentHistory.loading = LoadingStatus.Failed;
                state.adjustmentHistory.adjustmentHistoryError = error;
            } else {
                state.adjustmentHistory.loading = LoadingStatus.Idle;
            }
        })
        .addCase(reverseSelectedPatientAdjustmentTransactions.pending, (state) => {
            state.adjustmentHistory.reversalSaving = LoadingStatus.Pending;
        })
        .addCase(reverseSelectedPatientAdjustmentTransactions.fulfilled, (state) => {
            state.adjustmentHistory.reversalSaving = LoadingStatus.Completed;
            state.adjustmentHistory.confirmModalOpen = false;
        })
        .addCase(reverseSelectedPatientAdjustmentTransactions.rejected, (state) => {
            state.adjustmentHistory.reversalSaving = LoadingStatus.Failed;
            state.adjustmentHistory.confirmModalOpen = false;
        });
