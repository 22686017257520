import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { IPaymentSource } from 'api/models/payment-source.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { LedgerState } from '../ledger.state';
import {
    getPatientMostRecentTreatmentPlanCreditView,
    getPatientTreatmentPlanCreditUnappliedPayments,
    getProcedureCreditTransactions,
    saveTreatmentPlanCreditPaymentSourceAndTransactions,
} from './treatment-plan-credit-and-uac.actions';
import { IBatch } from 'api/models/batch.model';
import { forEach } from 'lodash';
import { TreatmentPlanTransactionLookup } from './treatment-plan-credit-and-uac.state';
import { IMostRecentTreatmentPlanPhaseView } from 'api/models/most-recent-treatment-plan-view.model';
import { MessageBarType } from '@fluentui/react';

export const treatmentPlanCreditAndUACReducers = {
    cleanupTreatmentPlanCreditTransactions: (state: LedgerState): void => {
        state.patientTreatmentPlanCreditAndUAC.treatmentPlanCreditTransactions = undefined;
    },
    cleanupTreatmentPlanCreditPaymentSource: (state: LedgerState): void => {
        state.patientTreatmentPlanCreditAndUAC.paymentSource = undefined;
        state.patientTreatmentPlanCreditAndUAC.allowOverpayment = undefined;
        state.patientTreatmentPlanCreditAndUAC.selectedPhaseView = undefined;
        state.patientTreatmentPlanCreditAndUAC.currentPhaseView = undefined;

        state.patientTreatmentPlanCreditAndUAC.modified = false;
    },
    cleanupTreatmentPlanCreditModified: (state: LedgerState): void => {
        state.patientTreatmentPlanCreditAndUAC.modified = false;
    },
    cleanupSelectedTreatmentPlanCreditView: (state: LedgerState): void => {
        state.patientTreatmentPlanCreditAndUAC.selectedPhaseView = undefined;
        state.patientTreatmentPlanCreditAndUAC.currentPhaseView = undefined;
        state.patientTreatmentPlanCreditAndUAC.saving = LoadingStatus.Idle;
    },
    cleanupSignedTreatmentPlan: (state: LedgerState): void => {
        state.patientTreatmentPlanCreditAndUAC.signedTreatmentPlanView = undefined;
    },
    cleanupTreatmentPlanCreditData: (state: LedgerState): void => {
        state.patientTreatmentPlanCreditAndUAC.selectedPhaseView = undefined;
        state.patientTreatmentPlanCreditAndUAC.loadingSignedTreatmentPlanView = LoadingStatus.Idle;
        state.patientTreatmentPlanCreditAndUAC.saving = LoadingStatus.Idle;

        state.patientTreatmentPlanCreditAndUAC.modified = false;
    },
    cleanupTreatmentPlanCreditMessageBar: (state: LedgerState): void => {
        state.patientTreatmentPlanCreditAndUAC.messageBarMessage = undefined;
        state.patientTreatmentPlanCreditAndUAC.messageBarType = undefined;
    },
    updateTreatmentPlanCreditPaymentSource: (
        state: LedgerState,
        action: PayloadAction<{ path: keyof IPaymentSource; value: unknown }>,
    ): void => {
        const { path, value } = action.payload;
        if (state.patientTreatmentPlanCreditAndUAC.paymentSource) {
            state.patientTreatmentPlanCreditAndUAC.paymentSource = {
                ...state.patientTreatmentPlanCreditAndUAC.paymentSource,
                [path]: value,
            };
        }
        if (path !== 'dateOfEntry') state.patientTreatmentPlanCreditAndUAC.modified = true;
    },
    updateTreatmentPlanCreditTransactionAmount: (
        state: LedgerState,
        action: PayloadAction<{ transactionId: string; amount: number }>,
    ): void => {
        if (state.patientTreatmentPlanCreditAndUAC.treatmentPlanCreditTransactions) {
            const { amount, transactionId } = action.payload;
            state.patientTreatmentPlanCreditAndUAC.treatmentPlanCreditTransactions[transactionId].amount = amount;
        }
    },
    setTreatmentPlanCreditPaymentSource: (state: LedgerState, action: PayloadAction<IPaymentSource>): void => {
        state.patientTreatmentPlanCreditAndUAC.paymentSource = action.payload;
    },
    setTreatmentPlanCreditTransactions: (state: LedgerState, action: PayloadAction<TreatmentPlanTransactionLookup>): void => {
        state.patientTreatmentPlanCreditAndUAC.treatmentPlanCreditTransactions = action.payload;
    },
    setTreatmentPlanCreditSelectedPhaseView: (
        state: LedgerState,
        action: PayloadAction<IMostRecentTreatmentPlanPhaseView | undefined>,
    ): void => {
        state.patientTreatmentPlanCreditAndUAC.selectedPhaseView = action.payload;
    },
    setTreatmentPlanCreditCurrentPhaseView: (
        state: LedgerState,
        action: PayloadAction<IMostRecentTreatmentPlanPhaseView | undefined>,
    ): void => {
        state.patientTreatmentPlanCreditAndUAC.currentPhaseView = action.payload;
    },
    updateAllTreamtentPlanCreditTransactionsBatchId: (state: LedgerState, action: PayloadAction<IBatch>): void => {
        const { id: batchId, dateOfEntry } = action.payload;
        if (state.patientTreatmentPlanCreditAndUAC.treatmentPlanCreditTransactions) {
            forEach(state.patientTreatmentPlanCreditAndUAC.treatmentPlanCreditTransactions, (transaction, transactionId) => {
                (state.patientTreatmentPlanCreditAndUAC.treatmentPlanCreditTransactions as TreatmentPlanTransactionLookup)[
                    transactionId
                ] = { ...transaction, batchId, dateOfEntry };
            });
        }
    },
    clearAllTreatmentPlanCreditTransactions: (state: LedgerState): void => {
        if (state.patientTreatmentPlanCreditAndUAC.treatmentPlanCreditTransactions) {
            forEach(state.patientTreatmentPlanCreditAndUAC.treatmentPlanCreditTransactions, (transaction, transactionId) => {
                (state.patientTreatmentPlanCreditAndUAC.treatmentPlanCreditTransactions as TreatmentPlanTransactionLookup)[
                    transactionId
                ].amount = 0;
            });
        }
    },
    payAllTreatmentPlanCreditTransactions: (state: LedgerState, action: PayloadAction<number>): void => {
        if (state.patientTreatmentPlanCreditAndUAC.treatmentPlanCreditTransactions) {
            const maxAmount = action.payload;

            if (maxAmount > 0) {
                const billingProcedures =
                    state.patientTreatmentPlanCreditAndUAC.currentPhaseView?.billingProcedures ??
                    state.patientTreatmentPlanCreditAndUAC.signedTreatmentPlanView?.phases
                        ?.map((ph) => ph.billingProcedures)
                        .flat();

                let remainingAmount = maxAmount;

                forEach(state.patientTreatmentPlanCreditAndUAC.treatmentPlanCreditTransactions, (transaction, transactionId) => {
                    //GRR won't distribute in UAC context because we don't have a current phase view...
                    //Need to build a list of billing procedures from signedTPView phases billing procedures

                    const billingProcedure = billingProcedures?.find(
                        (bp) => bp.id === transaction.chartProcedureId && transaction.treatmentPlanPhaseId === bp.phaseId,
                    );
                    if (billingProcedure) {
                        const commonFee = billingProcedure.commonPatientFee ?? 0;

                        const amount = remainingAmount < commonFee ? remainingAmount : commonFee;
                        if (amount > 0) {
                            (
                                state.patientTreatmentPlanCreditAndUAC
                                    .treatmentPlanCreditTransactions as TreatmentPlanTransactionLookup
                            )[transactionId].amount = amount;

                            remainingAmount -= amount;
                        }
                    }
                });
            }
        }
    },
    toggleTreatmentPlanCreditOverpayment: (state: LedgerState): void => {
        state.patientTreatmentPlanCreditAndUAC.allowOverpayment = !state.patientTreatmentPlanCreditAndUAC.allowOverpayment;
        state.patientTreatmentPlanCreditAndUAC.modified = true;
    },
};

export const treatmentPlanCreditAndUACExtraReducers = (
    builder: ActionReducerMapBuilder<LedgerState>,
): ActionReducerMapBuilder<LedgerState> =>
    builder
        .addCase(getPatientTreatmentPlanCreditUnappliedPayments.pending, (state) => {
            state.patientTreatmentPlanCreditAndUAC.loadingUnappliedPayments = LoadingStatus.Pending;
        })
        .addCase(getPatientTreatmentPlanCreditUnappliedPayments.fulfilled, (state, action) => {
            state.patientTreatmentPlanCreditAndUAC.loadingUnappliedPayments = LoadingStatus.Completed;
            state.patientTreatmentPlanCreditAndUAC.unappliedPaymentsError = undefined;
            state.patientTreatmentPlanCreditAndUAC.unnappliedPayments = action.payload;
        })
        .addCase(getPatientTreatmentPlanCreditUnappliedPayments.rejected, (state, action) => {
            if (action.error.name !== 'AbortError') {
                state.patientTreatmentPlanCreditAndUAC.loadingUnappliedPayments = LoadingStatus.Failed;
                state.patientTreatmentPlanCreditAndUAC.unappliedPaymentsError = action.error;
            } else {
                state.patientTreatmentPlanCreditAndUAC.loadingUnappliedPayments = LoadingStatus.Idle;
            }
        })
        .addCase(getProcedureCreditTransactions.pending, (state) => {
            state.patientTreatmentPlanCreditAndUAC.loadingProcedureCreditTransactions = LoadingStatus.Pending;
        })
        .addCase(getProcedureCreditTransactions.fulfilled, (state, action) => {
            state.patientTreatmentPlanCreditAndUAC.loadingProcedureCreditTransactions = LoadingStatus.Completed;
            state.patientTreatmentPlanCreditAndUAC.procedureCreditTransactionsError = undefined;
            state.patientTreatmentPlanCreditAndUAC.procedureCreditTransactions = action.payload;
        })
        .addCase(getProcedureCreditTransactions.rejected, (state, action) => {
            if (action.error.name !== 'AbortError') {
                state.patientTreatmentPlanCreditAndUAC.loadingProcedureCreditTransactions = LoadingStatus.Failed;
                state.patientTreatmentPlanCreditAndUAC.procedureCreditTransactionsError = action.error;
            } else {
                state.patientTreatmentPlanCreditAndUAC.loadingProcedureCreditTransactions = LoadingStatus.Idle;
            }
        })
        .addCase(saveTreatmentPlanCreditPaymentSourceAndTransactions.pending, (state) => {
            state.patientTreatmentPlanCreditAndUAC.saving = LoadingStatus.Pending;
        })
        .addCase(saveTreatmentPlanCreditPaymentSourceAndTransactions.fulfilled, (state) => {
            state.patientTreatmentPlanCreditAndUAC.messageBarMessage = 'Saved treatment plan prepayment/UAC successfully.';
            state.patientTreatmentPlanCreditAndUAC.messageBarType = MessageBarType.success;
            state.patientTreatmentPlanCreditAndUAC.saving = LoadingStatus.Completed;
        })
        .addCase(saveTreatmentPlanCreditPaymentSourceAndTransactions.rejected, (state) => {
            state.patientTreatmentPlanCreditAndUAC.messageBarMessage = 'Failed to save treatment plan prepayment/UAC.';
            state.patientTreatmentPlanCreditAndUAC.messageBarType = MessageBarType.error;
            state.patientTreatmentPlanCreditAndUAC.saving = LoadingStatus.Failed;
        })
        .addCase(getPatientMostRecentTreatmentPlanCreditView.pending, (state) => {
            state.patientTreatmentPlanCreditAndUAC.loadingSignedTreatmentPlanView = LoadingStatus.Pending;
        })
        .addCase(getPatientMostRecentTreatmentPlanCreditView.fulfilled, (state, { payload }) => {
            state.patientTreatmentPlanCreditAndUAC.loadingSignedTreatmentPlanView = LoadingStatus.Completed;
            if (payload) state.patientTreatmentPlanCreditAndUAC.signedTreatmentPlanView = payload;
            state.patientTreatmentPlanCreditAndUAC.signedTreatmentPlanViewError = undefined;
        })
        .addCase(getPatientMostRecentTreatmentPlanCreditView.rejected, (state, { error }) => {
            if (error.name !== 'AbortError') {
                state.patientTreatmentPlanCreditAndUAC.loadingSignedTreatmentPlanView = LoadingStatus.Failed;
                state.patientTreatmentPlanCreditAndUAC.signedTreatmentPlanViewError = error;
            } else {
                state.patientTreatmentPlanCreditAndUAC.loadingSignedTreatmentPlanView = LoadingStatus.Idle;
            }
        });
