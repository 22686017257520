import { Provider } from 'react-redux';
import App from 'pages/App';
import store, { history } from './state/store';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeInitializer, Loading } from 'components';
import { msalInstance } from 'api/auth/authProvider';
import { Helmet } from 'react-helmet';
import { CookiesProvider } from 'react-cookie';
import { MsalAuthenticationTemplate, MsalProvider, useAccount } from '@azure/msal-react';
import { AccountInfo, EventType, InteractionType } from '@azure/msal-browser';
import { useEffect } from 'react';
import { DentalApiConfig } from 'api/api.config';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { fetchDomainHint, setDomainHint } from 'utils/domainHint';
import isDev from 'utils/isDev';
import { appInsights } from 'index';

const tenant = `${isDev ? process.env.REACT_APP_TenantName : '#{TenantName}#'}`;
msalInstance.initialize().then(() => {
    // If multiple accounts are signed in, this will set the active account to the account that matches the tenant
    if (!activeAccountMatchesTenant(msalInstance.getActiveAccount()) && msalInstance.getAllAccounts().length > 0) {
        const accountByTenant = msalInstance.getAllAccounts().find((account) => account.environment.includes(tenant));
        if (accountByTenant) {
            msalInstance.setActiveAccount(accountByTenant);
        }
    }

    // Optional - This will update account state if a user signs in from another tab or window
    msalInstance.enableAccountStorageEvents();

    // On successful login, set the active account to the account that was used to log in
    const callbackId = msalInstance.addEventCallback((message) => {
        if (message.eventType === EventType.LOGIN_SUCCESS) {
            msalInstance.setActiveAccount((message.payload as any).account);
        }
    });
});

function activeAccountMatchesTenant(account: AccountInfo | null) {
    if (!account) return false;
    return account.environment.includes(tenant);
}

function Root(): JSX.Element {
    return (
        <CookiesProvider>
            <Provider store={store}>
                <Helmet>
                    <link rel="stylesheet" href="https://use.typekit.net/ols5nrq.css" />
                </Helmet>
                <ThemeInitializer>
                    <ConnectedRouter history={history}>
                        <MsalProvider instance={msalInstance}>
                            <Switch>
                                <Route path="/login" exact component={LoginReRoute} />
                                <Route path="/" component={() => <AppAuthenticationTemplates />} />
                            </Switch>
                        </MsalProvider>
                    </ConnectedRouter>
                </ThemeInitializer>
            </Provider>
        </CookiesProvider>
    );
}

export default Root;

function AppAuthenticationTemplates() {
    const account = useAccount();
    useEffect(() => {
        if (account && account.localAccountId) {
            appInsights.setAuthenticatedUserContext(account.username, account.localAccountId, true);
        }
    }, [account]);

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            loadingComponent={() => <Loading spinnerText="Logging In..." />}
            authenticationRequest={{
                scopes: DentalApiConfig.scopes,
                domainHint: fetchDomainHint(),
                account: account ?? undefined,
            }}
        >
            <App />
        </MsalAuthenticationTemplate>
    );
}

function LoginReRoute() {
    const account = useAccount();

    const { search } = useLocation();
    const domain_hint = new URLSearchParams(search);
    setDomainHint(domain_hint.get('domain_hint'));

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            loadingComponent={() => <Loading spinnerText="Logging In..." />}
            authenticationRequest={{
                scopes: DentalApiConfig.scopes,
                domainHint: fetchDomainHint(),
                account: account ?? undefined,
            }}
        >
            <Redirect />
        </MsalAuthenticationTemplate>
    );
}

function Redirect() {
    const { push } = useHistory();
    useEffect(() => {
        push('/');
    }, []);
    return <Loading spinnerText="Redirecting..." />;
}
