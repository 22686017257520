import { useEffect } from 'react';
import { ActivityPage } from 'components';
import { Spinner, SpinnerSize, Stack, useTheme, Callout, MessageBar, MessageBarType } from '@fluentui/react';
import './Charting.scss';
import ToothCanvas from './components/ToothCanvas/ToothCanvas';
import { useSelector } from 'hooks';
import ChartingMenu from './components/ChartingMenu';
import SectionChooser from './components/SectionChooser';
import { useParams } from 'react-router-dom';
import { RouteParams } from 'interfaces/route-params';
import ProcedureBrowserPanel from './components/ProcedureMenu/ProcedureBrowserPanel';
import ProcedurePanel from './components/ProcedureMenu/ProcedurePanel';
import { AccessDenied, PatientDetailsBanner } from 'pages/components';
import DiagnosesPanel from './components/DiagnosesPanel';
import usePermissions, { DentalPermissions } from 'hooks/store/usePermissions';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { isSectionChooserExpanded } from 'state/slices/charting/ui/chartingUi.selectors';
import { useDispatch } from 'react-redux';
import { getChartTreatmentPlans } from 'state/slices/scheduling/scheduling.slice';
import ProviderSelectionModal from './components/ProviderSelectionModal';
import { isModalOpen } from 'state/slices/charting/chart/chart.selectors';
import { setModal } from 'state/slices/charting/chart/chart.slice';
import { selectChartDentitions, selectChartDentitionsSaving } from 'state/slices/charting/dentition/dentition.selectors';
import { getPipelineErrorMessage } from 'state/slices/charting/procedure-panel/procedure-panel.selectors';
import { selectChartPipelineErrorData } from 'state/slices/charting/ui/chartingUi.selectors';
import { setChartingPipelineError } from 'state/slices/charting/chart/chart.slice';
import NoteBrowser from './components/NoteBrowser/NoteBrowser';
import { signalRGroupSubscriptionService, useSignalR } from 'hooks/signalr/useSignalr';
import { getChartConditions } from 'state/slices/charting/conditions/conditions.actions';
import { getChartDentitions } from 'state/slices/charting/dentition/dentition.actions';
import { selectChartProcedures } from 'state/slices/charting/procedures/procedures.selectors';
import { selectChartConditions } from 'state/slices/charting/conditions/conditions.selectors';
import { getChartProcedures } from 'state/slices/charting/procedures/procedures.actions';
import { getChartNotes } from 'state/slices/chart-notes/chart-notes.actions';
import BillingProviderModal from './components/BillingProviderModal';
import { SignalRGroup } from 'hooks/signalr/signalRGroupSubscriptionService';

function Charting(): JSX.Element {
    const theme = useTheme();
    const dispatch = useDispatch();

    const { patientId, tenantId } = useParams<RouteParams>();

    const { loading } = useSelector((state) => state.charting);
    const { loading: proceduresLoading } = useSelector(selectChartProcedures);
    const { loading: dentitionLoading } = useSelector(selectChartDentitions);
    const { loading: conditionsLoading } = useSelector(selectChartConditions);
    const dentitionsSaving = useSelector(selectChartDentitionsSaving);

    const sectionChooserExpanded = useSelector(isSectionChooserExpanded);
    const modalOpen = useSelector(isModalOpen);
    const errorData = useSelector(selectChartPipelineErrorData);

    const [hasCharting] = usePermissions([DentalPermissions.Odontogram]);

    useEffect(() => {
        if (patientId) {
            dispatch(getChartProcedures({ tenantId, patientId }));
            dispatch(getChartDentitions({ tenantId, patientId }));
            dispatch(getChartConditions({ tenantId, patientId }));
            dispatch(getChartNotes({ tenantId, patientId }));
            dispatch(getChartTreatmentPlans({ tenantId, patientId }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientId, tenantId]);

    useEffect(() => {
        return () => {
            signalRGroupSubscriptionService.unsubscribeFromGroups(tenantId, [SignalRGroup.Clinical]);
        }
    }, [])

    const upperSectionMaxHeight = 550;

    const _onCalloutDismiss = () => {
        dispatch(setChartingPipelineError(undefined));
    };

    return (
        <>
            {errorData && (
                <Callout
                    isBeakVisible={false}
                    gapSpace={4}
                    target={`#${errorData.buttonId}`}
                    onDismiss={_onCalloutDismiss}
                    setInitialFocus
                >
                    <MessageBar messageBarType={MessageBarType.blocked}>
                        {getPipelineErrorMessage(errorData.errors ?? []).map((error) => (
                            <li key={error.message}>{error.message}</li>
                        ))}
                    </MessageBar>
                </Callout>
            )}
            <ActivityPage title="Charting" className="charting" disableOverflow hideFooter>
                <PatientDetailsBanner />
                {hasCharting ? (
                    <Stack
                        style={{ padding: 12, flex: 1, display: 'flex', position: 'relative' }}
                        tokens={{ childrenGap: sectionChooserExpanded ? 0 : 12 }}
                        grow
                    >
                        <Stack
                            tokens={{ childrenGap: 12 }}
                            style={{ display: sectionChooserExpanded ? 'none' : 'flex' }}
                            horizontal
                        >
                            <Stack.Item
                                style={{
                                    position: 'relative',
                                    display: 'flex',
                                    maxHeight: upperSectionMaxHeight,
                                }}
                                grow={2}
                            >
                                {(loading !== LoadingStatus.Completed ||
                                    dentitionLoading !== LoadingStatus.Completed ||
                                    proceduresLoading !== LoadingStatus.Completed ||
                                    conditionsLoading !== LoadingStatus.Completed ||
                                    dentitionsSaving) && (
                                        <Stack
                                            horizontalAlign="center"
                                            verticalAlign="center"
                                            style={{
                                                background: `${theme.semanticColors.bodyBackground}CC`,
                                                padding: 10,
                                                position: 'absolute',
                                                height: '100%',
                                                width: '100%',
                                                zIndex: 10,
                                            }}
                                        >
                                            <Spinner size={SpinnerSize.large} label="Loading..." style={{}} />
                                        </Stack>
                                    )}
                                <ToothCanvas />
                            </Stack.Item>
                            <ChartingMenu />
                        </Stack>
                        <Stack.Item style={{ display: 'flex' }} grow>
                            <SectionChooser />
                        </Stack.Item>
                        <NoteBrowser />
                    </Stack>
                ) : (
                    <AccessDenied />
                )}
                <ProcedurePanel />
                <ProcedureBrowserPanel />
                <DiagnosesPanel />
                <ProviderSelectionModal isOpen={modalOpen} onDismiss={() => dispatch(setModal(false))} />
                <BillingProviderModal />
            </ActivityPage>
        </>
    );
}

export default Charting;
