import EditProperties from './EditProperties';
import LookupEditPanel, { EditComponentProps } from '../Shared/LookupEditPanel';
import { Provider } from 'models/provider';
import { PanelType } from '@fluentui/react';
import IProvider from 'api/models/provider.model';

const builder = ({ selectedLookup, setSelectedLookup, setHasError, setSelectedLookupItemProp }: EditComponentProps) => (
    <EditProperties
        setSelectedLookupItemProp={setSelectedLookupItemProp}
        selectedLookup={selectedLookup}
        setSelectedLookup={setSelectedLookup}
        setHasError={setHasError}
    />
);

const EditPanel = () => (
    <LookupEditPanel<IProvider>
        lookupType="Providers"
        displayName="Provider"
        editComponentBuilder={builder}
        panelType={PanelType.custom}
    />
);

export default EditPanel;
