import { LoadingStatus } from 'interfaces/loading-statuses';
import { MissingSlipsWorkList, WorkListConfirmModalType } from '../worklist.state';
import { IBillingProcedure } from 'api/models/billing-procedure.model';
import { IEncounterCorrespondence } from 'api/models/encounter-claim-summary.model';
import { EncounterStatus } from 'api/models/encounter.model';
import { ITag, MessageBarType } from '@fluentui/react';

export enum EncounterWorkList {
    ReadyToReview = 'ReadyToReview',
    ReadyToReviewOnHold = 'ReadyToReviewOnHold',
    BilledPatient = 'BilledPatient',
    BilledInsurance = 'BilledInsurance',
    BilledPatientCorrectionNeeded = 'BilledPatientCorrectionNeeded',
    BilledInsuranceCorrectionNeeded = 'BilledInsuranceCorrectionNeeded',
    BilledPatientCorrectionAmend = 'BilledPatientCorrectionAmend',
    BilledInsuranceCorrectionAmend = 'BilledInsuranceCorrectionAmend',
    ReadyToRebill = 'ReadyToRebill',
    ReadyToRebillOnHold = 'ReadyToRebillOnHold',
    Denials = 'Denials',
}

export enum EditEncounterWorkList {
    EditEncounters = 'EditEncounters',
    EditEncountersCorrectionAmend = 'EditEncountersCorrectionAmend',
    EditEncountersDenials = 'EditEncountersDenials',
}

export enum EncounterWorkListModalType {
    AdministrativeHold = 'administrativeHold',
    BillingNote = 'billingNote',
    CorrectionNote = 'correctionNote',
}

export type EncounterWorkLists = EncounterWorkList | EditEncounterWorkList | MissingSlipsWorkList.AmendRequireEncounters;

export type EncounterWorkListNoteModalAction =
    | EncounterStatus.CorrectionsCompleted
    | EncounterStatus.CorrectionAmend
    | EncounterStatus.ReBillOnHold
    | EncounterStatus.CorrectionsNeeded
    | EncounterStatus.Billed
    | 'Rebill';

export interface IEncounterWorkListFiltersQuery {
    dateOfServiceThrough?: string;
    locationsOfCare?: string[];
    providers?: string[];
    payers?: string[];
    reasonCodes?: ITag[];
    patient?: string;
    encounterId?: string;
}

export interface IEncounterWorkListsNoteModal {
    isOpen: boolean;
    currentNote?: string;
    correspondence?: IEncounterCorrespondence[];
    messageBarType?: MessageBarType;
    messageBarMessage?: string;
    selectedAction?: EncounterWorkListNoteModalAction;
    loadingNoteData: LoadingStatus;
    savingNoteData: LoadingStatus;
    savingIsRcm: LoadingStatus;
    type?: EncounterWorkListModalType;
}

export interface IEncounterWorkListState {
    //Encounter Send Confirmation modal
    confirmationModalIsOpen: boolean;
    confirmationModalType?: WorkListConfirmModalType;

    //Work list specific states if needed:
    voidProcedureSaving: LoadingStatus;
    billingProcedureToVoid?: IBillingProcedure;

    encounterWorkListNoteModal: IEncounterWorkListsNoteModal;
}

export const initialEncounterWorkListState: IEncounterWorkListState = {
    confirmationModalIsOpen: false,
    encounterWorkListNoteModal: {
        isOpen: false,
        loadingNoteData: LoadingStatus.Idle,
        savingNoteData: LoadingStatus.Idle,
        savingIsRcm: LoadingStatus.Idle,
    },
    voidProcedureSaving: LoadingStatus.Idle,
};
