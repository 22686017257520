import { CommandBar, ScrollablePane, Stack } from '@fluentui/react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import queryString from 'query-string';
import { useSelector } from 'hooks';
import PaymentsAndAdjustmentsList from './PaymentsAndAdjustmentsList';
import {
    selectLoadingPatientEncounterSummariesWithLineItems,
    selectPatientAdjustmentInfoSaving,
    selectPatientAdjustmentTransactions,
    selectPatientEncounterSummariesWithLineItems,
    selectPatientPaymentInfoSaving,
    selectPatientPaymentTransactions,
    selectPatientPaymentsAndAdjustmentsProceduresGroupedByEncounter,
    selectPatientPaymentsAndAdjustmentsProceduresGroupedByEncounterWithBalance,
    selectPaymentAndAdjustmentAmounts,
} from 'state/slices/ledger/patient-payments-and-adjustments/patient-payments-and-adjustments.selectors';
import {
    cleanupPatientAdjustmentsSavedStatusMessageBar,
    cleanupPatientPaymentsAndAdjustmentsData,
    cleanupPatientPaymentsSavedStatusMessageBar,
    updatePatientPaymentTransaction,
} from 'state/slices/ledger/ledger.slice';
import SaveStatusMessageBar from '../components/SaveStatusMessageBar';
import { useEffect } from 'react';
import {
    getPatientEncounterSummariesWithLineItemsView,
    getPatientPaymentsAndAdjustmentsBillingProcedures,
} from 'state/slices/ledger/patient-payments-and-adjustments/patient-payments-and-adjustments.actions';
import { RouteParams } from 'interfaces/route-params';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { Helmet } from 'react-helmet';

export default function PaymentsAndAdjustments() {
    const dispatch = useAppDispatch();
    const { push } = useHistory();
    const { url } = useRouteMatch();
    const { tenantId, patientId } = useParams<RouteParams>();

    const savingPayments = useSelector(selectPatientPaymentInfoSaving);
    const savingAdjustments = useSelector(selectPatientAdjustmentInfoSaving);

    const transactions = useSelector(selectPatientPaymentTransactions);
    const adjustmentTransactions = useSelector(selectPatientAdjustmentTransactions);

    const { paymentAmount, remainingPaymentAmount } = useSelector(selectPaymentAndAdjustmentAmounts);

    const allSummaries = useSelector(selectPatientPaymentsAndAdjustmentsProceduresGroupedByEncounter);
    const summariesWithAmount = useSelector(selectPatientPaymentsAndAdjustmentsProceduresGroupedByEncounterWithBalance);

    const loadingPatientEncounterSummariesWithLineItems = useSelector(selectLoadingPatientEncounterSummariesWithLineItems);

    const summaries = useSelector(selectPatientEncounterSummariesWithLineItems);

    const _onDismissPaymentsMessageBar = () => {
        dispatch(cleanupPatientPaymentsSavedStatusMessageBar());
    };
    const _onDismissAdjustmentsMessageBar = () => {
        dispatch(cleanupPatientAdjustmentsSavedStatusMessageBar());
    };

    useEffect(() => {
        const _getPatientEncounterSummaries = dispatch(getPatientEncounterSummariesWithLineItemsView({ tenantId, patientId }));
        const _getPatientPaymentsAndAdjustmentsBillingProcs = dispatch(
            getPatientPaymentsAndAdjustmentsBillingProcedures({ tenantId, patientId }),
        );

        return () => {
            _getPatientEncounterSummaries.abort('CancelPatientEncounterSummariesViewPageUnloaded');
            _getPatientPaymentsAndAdjustmentsBillingProcs.abort('CancelPatientPaymentsAndAdjustmentsBillingProcedures');
        };
    }, [patientId, tenantId, dispatch]);

    const commandBarDisabled = !summaries.length || loadingPatientEncounterSummariesWithLineItems;

    return (
        <Stack grow>
            <Helmet title="Ledger - Payments and Adjustments" />
            <SaveStatusMessageBar
                saving={savingPayments}
                onDismiss={_onDismissPaymentsMessageBar}
                messageConfig={{
                    completed: 'Saved payment successfully.',
                    failed: 'Failed to save payment.',
                }}
            />
            <SaveStatusMessageBar
                saving={savingAdjustments}
                onDismiss={_onDismissAdjustmentsMessageBar}
                messageConfig={{
                    completed: 'Saved adjustment successfully.',
                    failed: 'Failed to save adjustment.',
                }}
            />
            <CommandBar
                styles={{ root: { paddingLeft: 0 } }}
                items={[
                    {
                        key: 'make-payment',
                        text: 'Payment',
                        iconProps: {
                            iconName: 'Add',
                        },
                        disabled: commandBarDisabled,
                        onClick: () => {
                            push({
                                pathname: `${url}/make-payment-adjustment`,
                                search: queryString.stringify({ hideMakeAdjustment: true }),
                            });
                        },
                    },
                    {
                        key: 'make-adjustment',
                        text: 'Adjustment',
                        iconProps: {
                            iconName: 'Add',
                        },
                        disabled: commandBarDisabled,
                        onClick: () => {
                            push({
                                pathname: `${url}/make-payment-adjustment`,
                                search: queryString.stringify({ hideMakePayment: true }),
                            });
                        },
                    },
                    {
                        key: 'make-payment-and-adjustment',
                        text: 'Payment and Adjustment',
                        iconProps: {
                            iconName: 'Add',
                        },
                        disabled: commandBarDisabled,
                        onClick: () => {
                            push(`${url}/make-payment-adjustment`);
                        },
                    },
                ]}
            />
            <div style={{ position: 'relative', flex: 1, display: 'flex' }}>
                <ScrollablePane>
                    <PaymentsAndAdjustmentsList
                        transactions={transactions}
                        adjustmentTransactions={adjustmentTransactions}
                        allSummaries={allSummaries}
                        onChangePayment={(encounterId, transactionId, amount) => {
                            dispatch(updatePatientPaymentTransaction({ encounterId, transactionId, amount }));
                        }}
                        summariesWithAmount={summariesWithAmount}
                        paymentAmount={paymentAmount}
                        remainingPaymentAmount={remainingPaymentAmount}
                    />
                </ScrollablePane>
            </div>
        </Stack>
    );
}
