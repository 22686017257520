import { createSlice } from '@reduxjs/toolkit';
import { predeterminationsInitialState } from './predeterminations.state';
import { predeterminationsReducers, predeterminationExtraReducers } from './predeterminations.reducers';

const predetermination = createSlice({
    name: 'predetermination',
    initialState: predeterminationsInitialState,
    reducers: predeterminationsReducers,
    extraReducers: (builder) => {
        predeterminationExtraReducers(builder);
    },
});

const { actions, reducer } = predetermination;

export const {
    // Filters
    setAuthStatusFilter,
    setDosFilter,
    clearPredeterminationFilters,
    // Edit Modal
    setEditPredeterminationModalOpen,
    cleanupSelectedPredetermination,
} = actions;

export default reducer;
