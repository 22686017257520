import { DentitionMode, IChartDentition } from 'api/models/chart-dentition.model';
import { RouteParams } from 'interfaces/route-params';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    createChartDentition,
    createChartDentitions,
    getChartDentitions,
    setSelectedTeethMode,
    updateChartDentition,
    updateChartDentitions,
} from 'state/slices/charting/dentition/dentition.actions';
import { fullMouthDentition, selectChartDentitions } from 'state/slices/charting/dentition/dentition.selectors';

function useChartDentitions() {
    const { tenantId, patientId } = useParams<RouteParams>();
    const dispatch = useDispatch();

    const _fullMouthDentition = useSelector(fullMouthDentition);

    // Actions

    const _getChartDentitions = useCallback(() => {
        dispatch(getChartDentitions({ tenantId, patientId }));
    }, [dispatch, tenantId, patientId]);

    const _createChartDentition = useCallback(
        (dentition: IChartDentition) => {
            dispatch(createChartDentition({ tenantId, patientId, dentition }));
        },
        [dispatch, tenantId, patientId],
    );

    const _createChartDentitions = useCallback(
        (dentitions: IChartDentition[]) => {
            dispatch(createChartDentitions({ tenantId, patientId, dentitions }));
        },
        [dispatch, tenantId, patientId],
    );

    const _updateChartDentition = useCallback(
        (dentition: IChartDentition) => {
            dispatch(updateChartDentition({ tenantId, patientId, dentition }));
        },
        [dispatch, tenantId, patientId],
    );

    const _updateChartDentitions = useCallback(
        (dentitions: IChartDentition[]) => {
            dispatch(updateChartDentitions({ tenantId, patientId, dentitions }));
        },
        [dispatch, tenantId, patientId],
    );

    const { loading, saving, data: _dentitions } = useSelector(selectChartDentitions);

    const _setSelectedTeethMode = useCallback(
        (mode: keyof typeof DentitionMode) => {
            dispatch(setSelectedTeethMode(tenantId, patientId, mode, _dentitions));
        },
        [dispatch, tenantId, patientId, _dentitions],
    );

    return {
        //Selectors
        fullMouthDentition: _fullMouthDentition,

        // Actions
        getChartDentitions: _getChartDentitions,
        updateChartDentition: _updateChartDentition,
        updateChartDentitions: _updateChartDentitions,
        createChartDentition: _createChartDentition,
        createChartDentitions: _createChartDentitions,
        setSelectedTeethMode: _setSelectedTeethMode,

        // State
        loading,
        saving,
        dentitions: _dentitions,
    };
}

export default useChartDentitions;
