import { PickerDataProvider, IEditor } from 'roosterjs-editor-types-compatible';
import PickerComponent, {
    PickerComponentProps,
    PickerListItemRenderFunction,
    PickerSuggestionOption,
    Point,
} from './PickerComponent';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from 'state/store';
import { ThemeInitializer } from 'components';
import { cloneDeep } from 'lodash';

type CustomDataProviderProps<T> = {
    pickerId: string;
    suggestionContentGetter: PickerSuggestionContentGetterFunction<T>;
    suggestionOptions: PickerSuggestionOption<T>[];
    onRenderListItem?: PickerListItemRenderFunction<T>;
};

export type PickerSuggestionContentGetterFunction<T> = (option: PickerSuggestionOption<T>) => string;

const initialProps: Omit<PickerComponentProps<unknown>, 'suggestionContentGetter'> = {
    isPickerVisible: false,
    selectedIndex: 0,
    position: {
        x: 0,
        y: 0,
    },
    pickerId: '',
    //Filtered list of suggestionOptions.
    options: [],
};
export class CustomDataProvider<T> implements PickerDataProvider {
    insertNodeCallBack?: (node: HTMLElement) => void;

    setIsSuggestingCallback?: (isSuggesting: boolean) => void;

    /**
     * Function which is called when disposing the blur event handler for the picker plugin.
     *
     * @memberof CustomDataProvider
     */
    disposeBlur?: () => void;

    /**
     * Stored instance of the rooster js editor class instance.
     *
     * @type {IEditor}
     * @memberof CustomDataProvider
     */
    editor?: IEditor;

    suggestionContentGetter: PickerSuggestionContentGetterFunction<T>;
    onRenderListItem?: PickerListItemRenderFunction<T>;

    /**
     * Full unfiltered list of suggestion options.
     *
     * @type {PickerSuggestionOption<T>[]}
     * @memberof CustomDataProvider
     */
    suggestionOptions: PickerSuggestionOption<T>[] = [];

    /**
     * Props which are passed down to the picker component.
     *
     * @memberof CustomDataProvider
     */
    props = cloneDeep(initialProps as PickerComponentProps<T>);

    /**
     * Top level div to which all picker plugin divs are appended.
     *
     * @type {HTMLElement}
     * @memberof CustomDataProvider
     */
    mountDiv: HTMLElement;

    constructor({ pickerId, suggestionContentGetter, suggestionOptions, onRenderListItem }: CustomDataProviderProps<T>) {
        //Declare new mount div variable and attempt to get the picker layer div by id.
        let _mountDiv = document.getElementById(`picker-layer`);

        if (!_mountDiv) {
            //If the picker-layer div does not exist create a new one.
            _mountDiv = document.createElement('div');
            _mountDiv.setAttribute('id', `picker-layer`);

            //Append new div to the body tag.
            document.body.appendChild(_mountDiv);
        }

        //Set initial properties
        this.mountDiv = _mountDiv;
        this.props = {
            ...this.props,
            pickerId,
            options: suggestionOptions,
        };

        this.suggestionContentGetter = suggestionContentGetter;

        this.suggestionOptions = suggestionOptions;

        this.onRenderListItem = onRenderListItem;
    }

    //Setter functions used for updating this instance of data provider's properties from usePickerPlugin hook.
    set setSuggestionOptions(options: PickerSuggestionOption<T>[]) {
        this.suggestionOptions = options;
    }

    /**
     * Sets a prop on the picker component and rerenders it.
     *
     * @param {keyof PickerComponentProps<T>} prop
     * @param {(string | boolean | Point | number | PickerSuggestionOption<T>[] | PickerListItemRenderFunction<T> | undefined)} value
     * @memberof CustomDataProvider
     */
    setPickerProp(
        prop: keyof Omit<PickerComponentProps<T>, 'onRenderListItem' | 'suggestionContentGetter'>,
        value: string | boolean | Point | number | PickerSuggestionOption<T>[] | undefined,
    ) {
        (this.props[prop] as string | boolean | Point | number | PickerSuggestionOption<T>[] | undefined) = value;
        this.renderPickerComponent();
    }

    /**
     * Render the PickerComponent using ReactDOM to the mountDiv container.
     *
     * @memberof CustomDataProvider
     */
    renderPickerComponent() {
        ReactDOM.render(
            <Provider store={store}>
                <ThemeInitializer>
                    <PickerComponent<T>
                        onClick={this.handleElementClick}
                        {...this.props}
                        suggestionContentGetter={this.suggestionContentGetter}
                        onRenderListItem={this.onRenderListItem}
                    />
                </ThemeInitializer>
            </Provider>,
            this.mountDiv,
        );
    }

    onIsSuggestingChanged = (isSuggesting: boolean) => {
        this.setPickerProp('isPickerVisible', isSuggesting);
        if (!isSuggesting) {
            this.setPickerProp('selectedIndex', 0);
        }
    };

    queryStringUpdated = (query: string) => {
        if (query) {
            const filteredOptions = this.suggestionOptions.filter(
                (option) => option.displayName.toLowerCase().indexOf(query.toLowerCase()) > -1,
            );
            this.setPickerProp('options', filteredOptions);
            this.setSelectedIndex(0);
        } else {
            this.setPickerProp('options', this.suggestionOptions);
        }
    };

    onDispose() {
        this.props = cloneDeep(initialProps as PickerComponentProps<T>);

        this.setPickerProp('isPickerVisible', false);
        if (this.disposeBlur) this.disposeBlur();
    }

    onInitalize(
        insertNodeCallback: (node: HTMLElement) => void,
        setIsSuggestingCallback: (isSuggesting: boolean) => void,
        editor: IEditor | undefined,
    ) {
        this.insertNodeCallBack = insertNodeCallback;
        this.setIsSuggestingCallback = setIsSuggestingCallback;
        this.editor = editor;

        // Add blue event to hide picker, returns the remove event handler function.
        this.disposeBlur = editor?.addDomEventHandler('blur', this.handleBlur);
    }

    /**
     * Handles the blur event for the picker plugin. Looks for the related target to see if it is the suggestion item list.
     *
     * @param {*} e
     * @memberof CustomDataProvider
     */
    private handleBlur = (e: any) => {
        if (this.props.isPickerVisible) {
            // Check to see if related target is not the suggestion item list to prevent overriding this.handleElementClick
            if (!e?.relatedTarget || !e?.relatedTarget?.classList?.value?.includes('suggestion_list-item')) {
                this.setPickerProp('isPickerVisible', false);
            }
        }
    };

    /**
     * Handles the click event for the suggestion item list.
     *
     * @param {number} index
     * @memberof CustomDataProvider
     */
    handleElementClick = (index: number) => {
        this.props.selectedIndex = index;
        this.selectOption();
    };

    selectOption = () => {
        if (this.suggestionContentGetter) {
            const { options, selectedIndex } = this.props;
            const option = options[selectedIndex];
            const fragment = document.createRange().createContextualFragment(this.suggestionContentGetter(option));
            if (this.insertNodeCallBack) this.insertNodeCallBack(fragment as unknown as HTMLElement);
        }
    };

    setSelectedIndex(index: number) {
        this.setPickerProp('selectedIndex', index);
        const elementToFocus = document.getElementById(`ui-${index}`);
        if (elementToFocus) elementToFocus.scrollIntoView({ block: 'nearest' });
    }

    shiftHighlight = (isIncrement: boolean) => {
        // console.log(this.props.pickerId, this.props.options, this.suggestionOptions, isIncrement);
        if (isIncrement) {
            if (this.props.selectedIndex === this.props.options.length - 1) {
                this.setSelectedIndex(0);
            } else {
                this.setSelectedIndex(this.props.selectedIndex + 1);
            }
        } else {
            if (this.props.selectedIndex === 0) {
                this.setSelectedIndex(this.props.options.length - 1);
            } else {
                this.setSelectedIndex(this.props.selectedIndex - 1);
            }
        }
    };

    getSelectedIndex() {
        return this.props.selectedIndex;
    }

    onRemove(nodeRemoved: Node) {
        return nodeRemoved;
    }

    setCursorPoint(targetPoint: Point) {
        this.setPickerProp('position', targetPoint);
    }
}
