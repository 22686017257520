import { PayloadAction } from '@reduxjs/toolkit';
import { BatchReportQuery } from 'api/models/batch.model';
import { IReportingState } from '../reporting.state';

export const batchReducers = {
    setBatchProp: (
        state: IReportingState,
        action: PayloadAction<{ path: keyof BatchReportQuery; value: string | number | undefined }>,
    ): void => {
        const { path, value } = action.payload;
        ((state.reportProperties as BatchReportQuery)[path] as string | number | undefined) = value;
    },
};
