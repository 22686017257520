import { Stack, IconButton, useTheme } from '@fluentui/react';
import React from 'react';

export interface IActivityBarMenu {
    isExpanded: boolean;
    onToggleExpanded?: (expanded: boolean) => void;
    canShrink?: boolean;
}

export default function ActivityBarMenu({
    isExpanded,
    onToggleExpanded,
    children,
    canShrink = true,
}: React.PropsWithChildren<IActivityBarMenu>) {
    const theme = useTheme();

    const _toggleExpanded = () => {
        if (onToggleExpanded) onToggleExpanded(!isExpanded);
    };

    const { neutralLighterAlt } = theme.palette;
    const wrapperClassName = isExpanded || !canShrink ? 'activityBar activityBar--expanded' : 'activityBar';
    const wrapperStyle = {
        background: neutralLighterAlt,
        borderRight: `1px solid ${theme?.semanticColors.bodyDivider}`,
    };
    const expanderIcon = {
        iconName: isExpanded || !canShrink ? 'DoubleChevronLeftMed' : 'DoubleChevronLeftMedMirrored',
    };

    return (
        <Stack className={wrapperClassName} style={wrapperStyle}>
            {canShrink && (
                <Stack.Item align={isExpanded ? 'end' : 'center'}>
                    <IconButton
                        onClick={_toggleExpanded}
                        iconProps={expanderIcon}
                        styles={{ root: { padding: `0px ${canShrink ? '24px' : '0px'}` } }}
                    />
                </Stack.Item>
            )}
            <div style={{ flex: 1 }}>{children}</div>
        </Stack>
    );
}
