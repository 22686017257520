import { Text } from '@fluentui/react';
import { IBillingProcedure } from 'api/models/billing-procedure.model';
import { ToothArea } from 'api/models/tooth-area';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import { getShorthandToothAreas } from 'utils';

const toothArea: ISortableColumn<IBillingProcedure> = {
    key: 'toothArea',
    name: 'Area',
    fieldName: 'areas',
    minWidth: 100,
    maxWidth: 150,
    isResizable: true,
    onRender: (item) => {
        if (item && item.areas && item.areas.length) {
            return <Text>{getShorthandToothAreas(item.areas as ToothArea[])}</Text>;
        }
        return null;
    },
};

export default toothArea;
