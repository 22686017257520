import { mergeStyleSets, ScrollablePane, ScrollbarVisibility, Stack } from '@fluentui/react';
import { ActivityPage, ContentPane } from 'components';
import { usePatientId, useSelector, useTenantId } from 'hooks';
import { SignalRGroup } from 'hooks/signalr/signalRGroupSubscriptionService';
import { signalRGroupSubscriptionService, SignalRMessage, useSignalR } from 'hooks/signalr/useSignalr';
import { PatientDetailsBanner } from 'pages/components';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getPatientAuditLogging } from 'state/slices/patient/audit-log/auditLog.action';
import { setPatientAuditLog } from 'state/slices/patient/patient.slice';
import Logs from './Logs';
function AuditLogging() {
    const dispatch = useDispatch();
    const patientId = usePatientId();
    const tenantId = useTenantId();
    const { registerSignalRMessage } = useSignalR();

    const classNames = mergeStyleSets({
        wrapper: {
            height: '100vh',
            position: 'relative',
            maxHeight: 'inherit',
        },
    });

    useEffect(() => {
        registerSignalRMessage(SignalRMessage.UpdatedAudit, setPatientAuditLog);

        return () => {
            signalRGroupSubscriptionService.unsubscribeFromGroups(tenantId, [SignalRGroup.Clinical]);
        }
    }, []);

    useEffect(() => {
        if (patientId) {
            dispatch(getPatientAuditLogging({ tenantId, patientId }));
        }
    }, [tenantId, patientId]);

    return (
        <ActivityPage title="Audit Log">
            <PatientDetailsBanner />

            <div className={classNames.wrapper}>
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                    <Stack style={{ padding: 10 }} tokens={{ childrenGap: 10 }}>
                        <ContentPane>
                            <Stack grow tokens={{ childrenGap: 12 }}>
                                <Logs />
                            </Stack>
                        </ContentPane>
                    </Stack>
                </ScrollablePane>
            </div>
        </ActivityPage>
    );
}
export default AuditLogging;
