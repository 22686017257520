import { IDropdownOption, Dropdown, Text, useTheme } from '@fluentui/react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector, useAccount } from 'hooks';
import { selectTenantsAsList } from 'state/slices/account.slice';

const CustomerSelector = (): JSX.Element | null => {
    const history = useHistory();
    const { tenantsList } = useAccount();
    const adminOrgMatch = useRouteMatch('/admin/organizations');
    const adminTaxMatch = useRouteMatch('/admin/taxonomyCodes');
    const adminMatch = useRouteMatch('/admin');
    const adminTenantMatch = useRouteMatch<{ tenantId: string }>('/admin/:tenantId');
    const match = useRouteMatch<{ tenantId: string }>('/:tenantId');

    const _tenantsData = useSelector(selectTenantsAsList);

    const _onChange = (item: IDropdownOption) => {
        if (item.key !== 'selectAnother') {
            history.push(`/${item.key}`);
        } else {
            history.push(`/`);
        }
    };

    function getCustomerId() {
        if (!adminMatch) {
            return match ? match.params.tenantId : '';
        } else {
            return adminTenantMatch ? adminTenantMatch?.params.tenantId : '';
        }
    }

    const currentTenant = _tenantsData.find((tenant) => tenant.id === getCustomerId());

    const currentOrganizationOption: IDropdownOption = {
        key: currentTenant?.id as string,
        text: currentTenant?.displayName as string,
    };
    const options: IDropdownOption[] = _tenantsData
        ? [
              currentOrganizationOption,
              {
                  key: 'selectAnother',
                  text: 'Select Another Organization',
              },
          ]
        : [];

    if (!match?.params.tenantId || adminOrgMatch || adminTaxMatch) return null;

    return tenantsList.length === 1 ? (
        <Text style={{color:'white'}}>{currentTenant?.displayName}</Text>
    ) : (
        <Dropdown
            styles={{
                title: {
                    minWidth: 200,
                    backgroundColor: 'transparent',
                    border: 'none',
                    color: 'white',
                    height: 26,
                    lineHeight: 26,
                    paddingRight: 8,
                    selectors: {
                        ':hover': {
                            color: 'lightgray !important',
                        },
                        ':focus': {
                            color: 'lightgray !important',
                        },
                        ':active': {
                            color: 'lightgray !important',
                        },
                    },
                },
                dropdown: {
                    selectors: {
                        ':focus .ms-Field.Dropdown-title': {
                            color: 'lightgray !important',
                        },
                    },
                },
                caretDownWrapper: {
                    display: 'none',
                },
            }}
            label=""
            key={getCustomerId()}
            selectedKey={getCustomerId()}
            onChange={(e, option) => {
                if (option) _onChange(option);
            }}
            options={options}
        />
    );
};

export default CustomerSelector;
