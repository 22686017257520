import { Label, Stack } from '@fluentui/react';
import { Field } from 'components';
import { useState } from 'react';
import { useSelector, useTenantId } from 'hooks';
import { selectProvidersAsList } from 'state/slices/tenant/providers.slice';
import { useDispatch } from 'react-redux';
import { selectReportingValidationErrors, selectReportProperties } from 'state/slices/reporting/reporting.selectors';
import { IProviderProductivityReportProperties } from 'state/slices/reporting/reporting.state';
import { getValidationError, IValidationError } from 'hooks/useValidation';
import {
    cleanupProviderProductivityDates,
    setProviderProductivityDateRange,
    toggleProviderProductivityTreatingProvider,
} from 'state/slices/reporting/reporting.slice';
import { classicDateOnly } from 'utils/dateOnly';
import ReportWrapper from '../ReportWrapper';
import { ReportType } from 'api/models/embed-report.model';
import reportActionLookup from 'state/slices/reporting/reportActionLookup';

export default function ProviderProductivityReport() {
    const [dateSelectionType, setDateSelectionType] = useState<'relative' | 'fixed'>('relative');
    const dispatch = useDispatch();
    const tenantId = useTenantId();

    const errors = useSelector(selectReportingValidationErrors);

    const providersList = useSelector(selectProvidersAsList);
    const reportProperties = useSelector(selectReportProperties) as IProviderProductivityReportProperties;

    const onChangeRelativeDate = (
        ev: React.FormEvent<HTMLDivElement>,
        startDate: string | undefined,
        endDate: string | undefined,
    ) => {
        dispatch(setProviderProductivityDateRange({ startDate, endDate }));
    };

    const onViewReport = () => {
        if (reportProperties.startDate && reportProperties.endDate && reportProperties.treatingProvider) {
            const newStartDate = classicDateOnly(reportProperties.startDate, 'yyyy-MM-dd');
            const newEndDate = classicDateOnly(reportProperties.endDate, 'yyyy-MM-dd');
            dispatch(
                reportActionLookup[ReportType.ProviderProductivity]({
                    tenantId,
                    startDate: newStartDate,
                    endDate: newEndDate,
                    treatingProvider: reportProperties.treatingProvider,
                }),
            );
        }
    };

    const hasStartDateError = getValidationError(errors, 'Start Date');
    const hasEndDateError = getValidationError(errors, 'End Date');
    const hasTreatingProviderIds = getValidationError(errors, 'Treating Providers');

    return (
        <ReportWrapper
            validationConfig={[
                {
                    fieldName: 'Start Date',
                    validation: ['required'],
                    value: reportProperties.startDate,
                },
                {
                    fieldName: 'End Date',
                    validation: ['required'],
                    value: reportProperties.endDate,
                },
                {
                    fieldName: 'Treating Providers',
                    validation: ['required'],
                    value: reportProperties.treatingProvider,
                },
            ]}
            onViewReport={onViewReport}
        >
            <Stack tokens={{ childrenGap: 10 }} grow horizontal wrap>
                <Stack.Item>
                    <Label>Date Range</Label>
                    <Field.ChoiceGroup
                        options={[
                            {
                                key: 'relative',
                                text: 'Relative Date Range',
                            },
                            {
                                key: 'fixed',
                                text: 'Fixed Date Range',
                            },
                        ]}
                        selectedKey={dateSelectionType}
                        onChange={(ev, option) => {
                            if (option) setDateSelectionType(option.key as 'relative' | 'fixed');
                            dispatch(cleanupProviderProductivityDates());
                        }}
                    />
                </Stack.Item>
                <Stack.Item>
                    {dateSelectionType === 'fixed' ? (
                        <>
                            <Field.Date
                                errorMessage={hasStartDateError ? 'Start Date is required.' : undefined}
                                required
                                value={reportProperties.startDate}
                                onChange={(ev, value) => {
                                    dispatch(setProviderProductivityDateRange({ startDate: value }));
                                }}
                                label="Start Date"
                                hasDatePicker
                            />
                            <Field.Date
                                errorMessage={hasEndDateError ? 'End Date is required.' : undefined}
                                required
                                onChange={(ev, value) => {
                                    dispatch(setProviderProductivityDateRange({ endDate: value }));
                                }}
                                value={reportProperties.endDate}
                                label="End Date"
                                hasDatePicker
                            />
                        </>
                    ) : (
                        <Field.RelativeDateRange
                            label="Relative Date Range"
                            errorMessage={hasEndDateError || hasStartDateError ? 'Relative Date Range is required.' : undefined}
                            required
                            placeholder="(Select relative date range)"
                            onChange={onChangeRelativeDate}
                        />
                    )}
                </Stack.Item>
                <Stack.Item>
                    <Field.SearchCombo
                        options={providersList
                            .filter((provider) => !provider?.isDeleted)
                            .map((provider) => {
                                const name = `${provider?.lastName ?? ''}, ${provider?.firstName ?? ''}`;
                                return {
                                    key: provider?.id ?? '',
                                    text: name,
                                    title: name,
                                };
                            })}
                        onChange={(ev, option) => {
                            if (option) dispatch(toggleProviderProductivityTreatingProvider(option.key as string));
                        }}
                        multiSelect
                        errorMessage={hasTreatingProviderIds ? 'Treating Providers is required.' : undefined}
                        selectedKey={reportProperties.treatingProvider}
                        label="Treating Providers"
                        required
                    />
                </Stack.Item>
            </Stack>
        </ReportWrapper>
    );
}
