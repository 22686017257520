import { Dictionary } from '@reduxjs/toolkit';
import { DocumentBase } from 'api/document-base.model';
import { ChangeAuditSupport, ETagSupport, IdSupport } from 'api/models/support-types';
import { baseApi } from './baseApi';

const problemsApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getProblems: build.query<GetProblemsResult, void>({
            query: () => 'Problems',
        }),
        searchProblems: build.query<GetProblemsResult, string>({
            query: (queryString) => `Problems/Search/?query=${queryString}`,
        }),
    }),
    overrideExisting: false,
});

export const { useGetProblemsQuery } = problemsApi;

export interface IProblem extends DocumentBase {
    snomedCode?: string;
    name?: string;
    displayName?: string;
    description?: string;
    icd10?: string;
    isCritical: boolean;
    displayInHealthHistory: boolean;
    references?: Record<string, string>;
}

type GetProblemsResult = { [key: string]: IProblem };
