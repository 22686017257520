import { createAsyncThunk } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import { IDocument } from 'api/models/document.model';
import IDocumentType from 'api/models/documentType.model';
import axios from 'axios';
import { Dictionary } from 'lodash';
import ErrorTypes from 'state/errorTypes';
import { AppThunk, RootState } from 'state/store';

export const getDocumentsType = createAsyncThunk<Dictionary<IDocumentType>>('getDocumentsType', async () => {
    const req = await dentalApi.getDocumentsType();
    return req.data;
});

export const getPatientDocuments = createAsyncThunk<
    IDocument[],
    { tenantId: string; patientId: string },
    { rejectValue: string }
>('getPatientDocuments', async ({ tenantId, patientId }, { rejectWithValue }) => {
    try {
        const response = await dentalApi.getPatientDocuments(tenantId, patientId);
        return response.data;
    } catch (err) {
        if (axios.isAxiosError(err) && err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(JSON.stringify(err));
        }
    }
});

export const updateDocument = createAsyncThunk<IDocument, { tenantId: string; document: IDocument }, { rejectValue: string }>(
    'updateDocument',
    async ({ tenantId, document }, { rejectWithValue }) => {
        try {
            const { data } = await dentalApi.updateDocument(tenantId, document);
            return data;
        } catch (err) {
            if (axios.isAxiosError(err) && err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(JSON.stringify(err));
            }
        }
    },
);

export const updateDocuments = createAsyncThunk<
    IDocument[],
    { tenantId: string; documents: IDocument[] },
    { rejectValue: string }
>('updateDocuments', async ({ tenantId, documents }, { rejectWithValue }) => {
    try {
        const requests = documents.map((doc) => dentalApi.updateDocument(tenantId, doc));
        const responses = await axios.all(requests);
        return responses.map((res) => res.data);
    } catch (err) {
        if (axios.isAxiosError(err) && err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(JSON.stringify(err));
        }
    }
});

export const createDocumentsAndUploadFiles = createAsyncThunk<
    IDocument[],
    { tenantId: string },
    { rejectValue: string; state: RootState }
>('createDocumentsAndUploadFiles', async ({ tenantId }, { rejectWithValue, getState }) => {
    try {
        const files = getState().documents.pendingFiles;
        const documents = getState().documents.pendingDocuments;

        if (files.length && documents.length) {
            const uploadRequests = files.map(({ id, file }) => {
                const formData = new FormData();
                formData.append('file', file);
                dentalApi.uploadDocuments(tenantId, id, formData);
            });

            await axios.all(uploadRequests);

            const documentRequests = documents.map((document) => dentalApi.createDocument(tenantId, document));
            const documentResponses = await axios.all(documentRequests);

            return documentResponses.map((res) => res.data);
        }
        return [];
    } catch (err) {
        if (axios.isAxiosError(err) && err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(JSON.stringify(err));
        }
    }
});

export const deleteUploadedDocument =
    (tenantId: string, document: IDocument): AppThunk<void> =>
    (dispatch) => {
        const deletedDocument = { ...document, isDeleted: true };
        dispatch(updateDocument({ tenantId, document: deletedDocument }));
    };
