import { ChartConditionStatus, ChartProcedureStatus, IChartCondition, IChartProcedure } from 'api/models/chart.model';
import { DentitionMode, IChartDentition } from 'api/models/chart-dentition.model';
import { IChartRenderCondition, IChartRenderProcedure, ITooth } from 'api/models/tooth.model';
import Pipeline from './pipeline';
import { ApplicableArea, ICondition } from 'api/models/lookup.model';
import { toothSpriteReferences } from 'pages/Charting/components/ToothCanvas/spriteList';
import store from 'state/store';
import { IProcedure } from 'api/models/procedure.model';
import { ProcedureActionType } from './chart/chart.slice';

type ChartingRenderPipelineArgs = {
    dentition: number[];
    selectedTeeth: number[];
    procedures: IChartProcedure[];
    dentitions?: IChartDentition[];
    conditions: IChartCondition[];
    tenantConditions: ICondition[];
    chartDentitionMode?: DentitionMode;
    tenantProcedures: IProcedure[];
};

/**
 * Returns rendereable tooth data for canvas
 *
 * @class ChartingRenderPipeline
 * @extends {Pipeline}
 */
class ChartingRenderPipeline extends Pipeline<ITooth> {
    private teeth: number[];
    private _procedures: IChartProcedure[];
    private _conditions: IChartCondition[];
    private _dentitions: IChartDentition[];
    private _tenantConditions: ICondition[];
    private _tenantProcedures: IProcedure[];
    private _selectedTeeth: number[];
    private _chartDentitionMode: DentitionMode = DentitionMode.Permanent;

    constructor({
        procedures,
        dentition,
        selectedTeeth,
        dentitions,
        chartDentitionMode,
        conditions,
        tenantConditions,
        tenantProcedures,
    }: ChartingRenderPipelineArgs) {
        super([]);
        this._procedures = procedures.filter((procedure) => procedure.status !== ChartProcedureStatus.Declined);
        this.teeth = dentition;
        this._selectedTeeth = selectedTeeth;
        this._dentitions = dentitions || [];
        this._conditions = conditions;
        this._tenantConditions = tenantConditions;
        this._tenantProcedures = tenantProcedures;

        if (chartDentitionMode) this._chartDentitionMode = chartDentitionMode;

        this.generateRenderData();
    }
    private getToothIsMandibular(toothPosition: number) {
        return toothPosition >= 17;
    }

    private getToothPosition(toothId: number) {
        const tooth = toothSpriteReferences.find((t) => t.id === toothId);
        return tooth?.position;
    }

    private getProcedureById(id?: string) {
        const procs = store.getState().tenant.procedures.data;
        if (procs && id) return procs[id];
        return undefined;
    }

    /**
     * Gets tooth number based on dentition.
     *
     * @private
     * @param {number} toothId
     * @param {IChartDentition} [dentition]
     * @return {*}
     * @memberof ChartingRenderPipeline
     */
    private getToothId(toothId: number, dentition?: IChartDentition) {
        if (
            (dentition?.dentitionMode === 'Primary' || dentition?.dentitionMode === 'PrimarySupernumerary') &&
            ((toothId >= 4 && toothId <= 13) || (toothId >= 20 && toothId <= 29))
        ) {
            if (toothId >= 20) {
                return toothId + 23;
            } else {
                return toothId + 29;
            }
        }
        return toothId;
    }

    /**
     * Generates render data
     *
     * @private
     * @memberof ChartingRenderPipeline
     */
    private generateRenderData() {
        this.teeth.forEach((toothId) => {
            const currDentition = this._dentitions.find((d) => d.id === toothId.toString());
            const id = this.getToothId(toothId, currDentition);
            const proceduresFortooth = this._procedures
                .filter((proc) => {
                    const p = this.getProcedureById(proc.procedureId);
                    const toothPosition = this.getToothPosition(id);
                    const applicableArea = p?.applicableArea;

                    if (
                        (applicableArea === ApplicableArea.MaxillaryArch || applicableArea === ApplicableArea.MandibularArch) &&
                        toothPosition
                    ) {
                        if (applicableArea === ApplicableArea.MandibularArch && this.getToothIsMandibular(toothPosition))
                            return true;
                        if (applicableArea === ApplicableArea.MaxillaryArch && !this.getToothIsMandibular(toothPosition))
                            return true;
                    }

                    if (id && proc.toothIds) {
                        return proc.toothIds.indexOf(id) > -1;
                    }
                    return false;
                })
                .map((p) => ({
                    ...p,
                    renderRule: this._tenantProcedures.find((tP) => tP.id === p.procedureId)?.renderRule,
                })) as IChartRenderProcedure[];

            const conditionsForTooth = this._conditions
                .filter((c) => {
                    if (c.status === ChartConditionStatus.Resolved || c.status === ChartConditionStatus.Removed) return false;
                    if (id && c.toothId) {
                        return c.toothId === id;
                    }
                    return false;
                })
                .map((c) => ({
                    ...c,
                    renderRule: this._tenantConditions.find((tC) => tC.id === c.conditionId)?.renderRule,
                })) as IChartRenderCondition[];

            const isToothSelected = this._selectedTeeth.indexOf(id) > -1;

            const sortedProcedures = [
                ...proceduresFortooth.filter((proc) => proc.type !== ProcedureActionType.Treatment),
                ...proceduresFortooth.filter((proc) => proc.type === ProcedureActionType.Treatment),
            ];

            this.addItem({
                id,
                procedures: sortedProcedures,
                conditions: conditionsForTooth,
                selected: isToothSelected,
                dentitionMode: (currDentition
                    ? currDentition.dentitionMode
                    : this._chartDentitionMode) as keyof typeof DentitionMode,
                x: currDentition?.xPosition,
                y: currDentition?.yPosition,
                rotation: currDentition?.facialDegree,
                rotationOcclusal: currDentition?.occlusalDegree,
            });
        });
    }
}

export default ChartingRenderPipeline;
