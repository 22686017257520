import { ClinicalAlertType } from 'api/models/clinical-alert.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { map } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'state/store';

export const selectClinicalAlertState = (state: RootState) => state.clinicalAlerts;

export const selectAlertTypesLookupIsLoading = createSelector(selectClinicalAlertState, (state) => {
    return state.loadingAlertTypes === LoadingStatus.Pending;
});

export const selectCurrentClinicalAlertData = createSelector(selectClinicalAlertState, (state) => {
    return state.currentClinicalAlertData;
});

export const selectPatientClinicalAlertLookup = createSelector(selectClinicalAlertState, (state) => {
    return state.clinicalAlertsForPatients;
});

export const selectPatientClinicalAlerts = createSelector(selectClinicalAlertState, (state) => {
    return map(state.patientClinicalAlerts).filter((alert) => !alert.isDeleted);
});

export const selectPatientClinicalAlertsByType = createSelector(
    selectPatientClinicalAlerts,
    (_: RootState, type: ClinicalAlertType) => type,
    (alerts, type) => {
        return alerts.filter((alert) => alert.type === type);
    },
);

export const selectPatientClinicalAlertLookupHasError = createSelector(
    [selectClinicalAlertState, (state: RootState, patientId: string) => patientId],
    (state, patientId) => {
        return !!state.loadingClinicalAlertsErrors[patientId];
    },
);

export const selectPatientClinicalAlertLookupIsLoading = createSelector(
    [selectClinicalAlertState, (state: RootState, patientId: string) => patientId],
    (state, patientId) => {
        return state.loadingClinicalAlerts[patientId] === LoadingStatus.Pending;
    },
);

export const selectCurrentClinicalAlertPanelOpen = createSelector(selectClinicalAlertState, (state) => {
    return state.clinicalAlertPanelOpen;
});

export const selectCurrentClinicalAlertDataIsSaving = createSelector(selectClinicalAlertState, (state) => {
    return state.savingClinicalAlert === LoadingStatus.Pending;
});

// Will need a way to filter out deleted alerts on a per patient basis so we can only render the undeleted alerts for a patient
export const selectActivePatientClinicalAlertsByPatientIdByType = createSelector(
    [selectPatientClinicalAlertLookup, (state: RootState, params: { patientId: string; type: ClinicalAlertType }) => params],
    (lookup, { patientId, type }) => {
        return lookup[patientId] ? map(lookup[patientId]).filter((alert) => !alert.isDeleted && alert.type === type) : [];
    },
);

export const selectActivePatientClinicalAlertsByPatientId = createSelector(
    [selectPatientClinicalAlertLookup, (state: RootState, params: { patientId: string }) => params],
    (lookup, { patientId }) => {
        return lookup[patientId] ? map(lookup[patientId]).filter((ca) => !ca.isDeleted) : [];
    },
);

export const selectClinicalAlertsExistByPatientAndType = createSelector(
    [selectPatientClinicalAlertLookup, (state: RootState, params: { patientId: string }) => params],
    (lookup, { patientId }) => {
        return lookup[patientId] ? !!lookup[patientId] : false;
    },
);

export const clinicalAlertValidation = createSelector(selectClinicalAlertState, (state) => {
    return state.validationErrors;
});

export const selectIsEditingCurrentClinicalAlertData = createSelector([selectCurrentClinicalAlertData], (currentAlert) => {
    return !!currentAlert?.id;
});
