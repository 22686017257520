import { Stack, Text, IDropdownOption } from '@fluentui/react';
import { Field, TModal } from 'components';
import { useDispatch } from 'react-redux';
import { setModal } from 'state/slices/charting/chart/chart.slice';
import { selectFilteredProviders } from 'state/slices/tenant/providers.slice';
import { usePatientId, useSelector, useTenantId } from 'hooks';
import useEncounterId from 'hooks/useEncounterId';
import { useEffect, useState } from 'react';
import { readyForProviderClick } from 'state/slices/chart-notes/chart-notes.actions';
import { sortBy } from 'lodash';

type Props = {
    isOpen: boolean;
    onDismiss?: () => void;
};

export default function ProviderSelectionModal({ isOpen, onDismiss }: Props): JSX.Element {
    const dispatch = useDispatch();
    const _providers = useSelector(selectFilteredProviders);

    const tenantId = useTenantId();
    const patientId = usePatientId();
    const encounterId = useEncounterId();

    const treatingProviderOptions: IDropdownOption[] = sortBy(
        _providers
            .filter((p) => p?.isTreatingProvider && !p.isResident)
            .map((p) => {
                return {
                    key: p?.id ?? '',
                    text: `${p?.lastName} ${p?.suffix ? `${p?.suffix}` : ''}, ${p?.firstName}`,
                };
            }) ?? [],
        'text',
    );

    const [selectedSupervisor, setSelectedSupervisor] = useState<string | null>(null);

    useEffect(() => {
        setSelectedSupervisor(null);
    }, [onDismiss]);

    return (
        <TModal
            title={'Select Attending Provider'}
            mainButtons={[
                {
                    type: 'PrimaryButton',
                    text: 'Continue',
                    onClick: () => {
                        if (encounterId && patientId && selectedSupervisor) {
                            dispatch(readyForProviderClick(tenantId, patientId, selectedSupervisor));
                            dispatch(setModal(false));
                        }
                    },
                    disabled: !selectedSupervisor,
                },
                {
                    text: 'Cancel',
                    onClick: () => {
                        setSelectedSupervisor(null);
                        dispatch(setModal(false));
                    },
                },
            ]}
            modalProps={{
                isOpen,
                onDismiss,
                isBlocking: true,
            }}
        >
            <Stack grow>
                <Text styles={{ root: { padding: 10, color: 'red', fontStyle: 'italic' } }}>
                    Select Attending Provider before signing.
                </Text>
                <Stack styles={{ root: { paddingLeft: 10 } }}>
                    <Field.SearchCombo
                        selectedKey={selectedSupervisor}
                        styles={{ root: { width: 250 } }}
                        label="Attending Provider"
                        placeholder="(Select)"
                        options={treatingProviderOptions}
                        required={true}
                        onChange={(e, option) => {
                            if (option) {
                                setSelectedSupervisor(option.key as string);
                            }
                        }}
                    />
                </Stack>
            </Stack>
        </TModal>
    );
}
