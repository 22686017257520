import { MessageBar } from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import { cleanupTreatmentPlanCreditMessageBar } from 'state/slices/ledger/ledger.slice';
import { selectTreatmentPlanCreditAndUACMessageBar } from 'state/slices/ledger/treatment-plan-credit-and-uac/treatment-plan-credit-and-uac.selectors';

export default function TreatmentPlanCreditAndUACMessageBar() {
    const dispatch = useDispatch();
    const { messageBarMessage, messageBarType } = useSelector(selectTreatmentPlanCreditAndUACMessageBar);

    if (!messageBarType || !messageBarMessage) return null;

    return (
        <MessageBar
            onDismiss={() => {
                dispatch(cleanupTreatmentPlanCreditMessageBar());
            }}
            messageBarType={messageBarType}
        >
            {messageBarMessage}
        </MessageBar>
    );
}
