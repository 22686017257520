import { useSelector } from 'react-redux';
import { selectCurrentNote, selectRenderedCurrentNote } from 'state/slices/chart-notes/note-browser/note-browser.selectors';
import { TextEditor } from '../TextEditor';

function NoteViewerPreview() {
    const note = useSelector(selectRenderedCurrentNote);
    const currentNote = useSelector(selectCurrentNote);
    if (!note) return null;
    return <TextEditor key={currentNote?.id} value={note} disabled />;
}

export default NoteViewerPreview;
