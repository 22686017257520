import { createSelector } from '@reduxjs/toolkit';
import { IAppointmentHistory } from 'api/models/appointment-history.model';
import IPatientContact from 'api/models/patient-contact.model';
import IPatient, {
    IPatientAddress,
    IPatientInsurance,
    IPatientPhoneNumber,
    IPatientSlidingFee,
    IPatientUDS,
} from 'api/models/patient.model';
import { IUpcomingAppointment } from 'api/models/Scheduling/patientAppointment.model';
import { IPendingChartTreatmentPlanPhaseProcedure } from 'api/models/treatment-plan.model';
import { isAfter, isBefore } from 'date-fns';
import { IValidationError } from 'hooks/useValidation';
import { LoadingStatuses } from 'interfaces/loading-statuses';
import { flatten, map, orderBy, uniqBy } from 'lodash';
import ErrorTypes from 'state/errorTypes';
import { RootState } from 'state/store';

export const selectEditPatientPanelOpen = (state: RootState): boolean => state.editPatient.editPatientPanelOpen;
export const selectEditPatienIsNewPatient = (state: RootState): boolean | undefined => state.editPatient.isNewPatient;
export const selectEditPatientHasChange = (state: RootState): boolean | undefined => state.editPatient.patientHasChange;
export const selectEditPatientError = (state: RootState): ErrorTypes | undefined => state.editPatient.error;

export const selectEditPatientUnscheduledTreatmentPlanProcedures = (
    state: RootState,
): IPendingChartTreatmentPlanPhaseProcedure[] => state.editPatient.unscheduledTreatmentPlanProcedures ?? [];

export const selectEditPatientPastAppointments = (state: RootState): IAppointmentHistory[] =>
    state.editPatient.appointmentHistory ?? [];

export const selectEditPatient = (state: RootState): IPatient | undefined => state.editPatient.patient;
export const selectEditPatientPhoneNumbers = (state: RootState): IPatientPhoneNumber[] =>
    state.editPatient.patient?.phoneNumbers ?? [];
export const selectEditPatientInsurance = (state: RootState): IPatientInsurance[] | undefined =>
    state.editPatient.patient?.insurances ?? [];

export const selectedEditPatientSlidingFeePlans = (state: RootState): IPatientSlidingFee[] | undefined =>
    state.editPatient.patient?.slidingFees;

export const selectEditPatientContact = (state: RootState): IPatientContact[] | undefined => state.editPatient.contacts;
export const selectEditPatientUpcomingAppointments = (state: RootState): IUpcomingAppointment[] =>
    state.editPatient.upcomingAppointments ?? [];

export function isActiveInsurance(insurance: IPatientInsurance) {
    return !insurance.isDeleted && (insurance.expirationDate ? isAfter(new Date(insurance.expirationDate), new Date()) : true);
}

export const selectEditPatientActiveInsurances = createSelector(
    selectEditPatientInsurance,
    (insurances) => insurances?.filter(isActiveInsurance) ?? [],
);

export function isExpiredInsurance(insurance: IPatientInsurance) {
    return (insurance.expirationDate ? isBefore(new Date(insurance.expirationDate), new Date()) : false) && !insurance.isDeleted;
}

export const selectEditPatientExpiredInsurances = createSelector(selectEditPatientInsurance, (insurances) =>
    orderBy(insurances?.filter(isExpiredInsurance), ['expirationDate'], ['desc']),
);

export function isDeletedInsurance(insurance: IPatientInsurance) {
    return !!insurance.isDeleted;
}

export const selectEditPatientDeletedInsurances = createSelector(
    selectEditPatientInsurance,
    (insurances) => insurances?.filter(isDeletedInsurance) ?? [],
);

export const selectFilteredEditPatientUnscheduledTreatmentPlanProcedures = createSelector(
    selectEditPatientUpcomingAppointments,
    selectEditPatientUnscheduledTreatmentPlanProcedures,
    (appointments, procedures) => {
        const appointmentsUpcomingApptProcedures = uniqBy(
            flatten(
                appointments
                    .filter((appt) => !appt.isDeleted)
                    .filter((appt) => appt.procedures?.length)
                    .map((appt) => appt.procedures),
            ),
            (proc) => proc?.treatmentPlanPhaseProcedureId,
        ).map((proc) => proc?.treatmentPlanPhaseProcedureId) as string[];

        return procedures.filter((proc) => appointmentsUpcomingApptProcedures.indexOf(proc?.procedurePlanPhaseId ?? '') === -1);
    },
);
export const selectEditPatientValidationErrors = (state: RootState): IValidationError[] => state.editPatient.validationErrors;

export const selectEditPatientLoading = (state: RootState): LoadingStatuses => state.editPatient.loading;

export const filteredInsuranceList = createSelector([selectEditPatientInsurance], (insurance) => {
    const list = map(insurance, (p) => p) as IPatientInsurance[];
    return list.filter((p) => !p?.isDeleted);
});

export const selectEditPatientUDSData = (state: RootState): IPatientUDS | undefined => state.editPatient.patient?.uds;

export const selectEditPatientAddresses = (state: RootState): IPatientAddress | undefined =>
    state.editPatient.patient?.physicalAddress;
export const selectSameAddressAsPatient = createSelector(selectEditPatient, (patient) => {
    if (patient?.mailingAddressSameAsPhysicalAddress) {
        return patient.physicalAddress;
    }
    if (!patient?.mailingAddress?.streetAddress1) {
        return patient?.physicalAddress;
    }
    return patient?.mailingAddress;
});
