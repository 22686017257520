import { Text } from '@fluentui/react';
import { IBillingProcedure } from 'api/models/billing-procedure.model';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import { useSelector } from 'hooks';
import { selectProvidersData } from 'state/slices/tenant/providers.slice';

const completingProvider: ISortableColumn<IBillingProcedure> = {
    key: 'completingProvider',
    name: 'Completing Provider',

    isResizable: true,
    minWidth: 150,
    maxWidth: 150,
    onRender: OnRenderProviderColumn,
    fieldName: 'treatingProvider',
};

function useProviderData(providerId: string | undefined) {
    const data = useSelector(selectProvidersData);
    if (!providerId) return undefined;
    return data[providerId];
}

function OnRenderProviderColumn(item?: IBillingProcedure) {
    const provider = useProviderData(item?.treatingProviderId);

    if (!provider) return null;

    const label = `${provider.firstName} ${provider.lastName}`;
    return <Text variant="smallPlus">{label}</Text>;
}

export default completingProvider;
