import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { IVitalReading, VitalNameType } from 'api/models/patient-vital.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { createPatientVital, updatePatientVital } from './vitals.actions';
import { BloodPressureReading, newVitalsPanelData, VitalsPanelData } from './vitals.state';
import { v4 as uuid } from 'uuid';
import { PatientState } from '../patient.state';

export type BPNameTypes =
    | VitalNameType.Cuff
    | VitalNameType.Diastolic
    | VitalNameType.Systolic
    | VitalNameType.Refused
    | VitalNameType.RefusedReason
    | VitalNameType.Site
    | VitalNameType.Position;
export type NonBPNameTypes = Exclude<VitalNameType, BPNameTypes>;

export const vitalsReducers = {
    setVitalPanelOpen: (state: PatientState, action: PayloadAction<boolean>): void => {
        const isOpen = action.payload;
        state.vitals.vitalPanelOpen = isOpen;

        if (!isOpen) {
            state.vitals.vitalPanelData = newVitalsPanelData;
            state.vitals.vitalsToUpdate = [];
        }
    },
    setVitalsPanelData: (
        state: PatientState,
        action: PayloadAction<{ data: VitalsPanelData; vitalsToUpdate: IVitalReading[] }>,
    ): void => {
        const { data, vitalsToUpdate } = action.payload;
        state.vitals.vitalPanelOpen = true;
        state.vitals.vitalPanelData = data;
        state.vitals.vitalsToUpdate = vitalsToUpdate;
    },
    addBloodPressureReading: (state: PatientState): void => {
        const newBloodPressureReading: BloodPressureReading = {
            'VITALS.BLOODPRESSURE.CUFF': '',
            'VITALS.BLOODPRESSURE.DIASTOLIC': '',
            'VITALS.BLOODPRESSURE.REFUSED': false,
            'VITALS.BLOODPRESSURE.REFUSEDREASON': '',
            'VITALS.BLOODPRESSURE.SITE': '',
            'VITALS.BLOODPRESSURE.SYSTOLIC': '',
            'VITALS.BLOODPRESSURE.POSITION': '',
            readingId: uuid(),
        };
        if (state.vitals.vitalPanelData && state.vitals.vitalPanelData.BP) {
            state.vitals.vitalPanelData.BP = [...state.vitals.vitalPanelData.BP, newBloodPressureReading];
        }
    },
    heightRefused: (state: PatientState, action: PayloadAction<boolean>): void => {
        state.vitals.vitalPanelData[VitalNameType.HeightRefused] = action.payload;
        if (action.payload) {
            state.vitals.vitalPanelData[VitalNameType.Height] = '';
            state.vitals.vitalPanelData[VitalNameType.HeightInches] = '';
            state.vitals.vitalPanelData[VitalNameType.HeightType] = '';
        } else {
            state.vitals.vitalPanelData[VitalNameType.HeightRefusedReason] = '';
        }
    },
    weightRefused: (state: PatientState, action: PayloadAction<boolean>): void => {
        state.vitals.vitalPanelData[VitalNameType.WeightRefused] = action.payload;
        if (action.payload) {
            state.vitals.vitalPanelData[VitalNameType.Weight] = '';
            state.vitals.vitalPanelData[VitalNameType.WeightType] = '';
        } else {
            state.vitals.vitalPanelData[VitalNameType.WeightRefusedReason] = '';
        }
    },
    bpRefuesed: (state: PatientState, action: PayloadAction<{ checked: boolean; index: number }>): void => {
        const { checked, index } = action.payload;
        state.vitals.vitalPanelData.BP[index][VitalNameType.Refused] = checked;

        if (checked) {
            state.vitals.vitalPanelData.BP[index][VitalNameType.Cuff] = '';
            state.vitals.vitalPanelData.BP[index][VitalNameType.Diastolic] = '';
            state.vitals.vitalPanelData.BP[index][VitalNameType.Site] = '';
            state.vitals.vitalPanelData.BP[index][VitalNameType.Systolic] = '';
            state.vitals.vitalPanelData.BP[index][VitalNameType.Position] = '';
        } else {
            state.vitals.vitalPanelData.BP[index][VitalNameType.RefusedReason] = '';
        }
    },
    updateReading: (state: PatientState, action: PayloadAction<{ vitalType: NonBPNameTypes; value: string | boolean }>): void => {
        const { value, vitalType } = action.payload;
        if (state.vitals.vitalPanelData && state.vitals.vitalPanelData[vitalType] !== undefined) {
            if (typeof value === 'string' && typeof state.vitals.vitalPanelData[vitalType] === 'string') {
                (state.vitals.vitalPanelData[vitalType] as string) = value;
            }
            if (typeof value === 'boolean' && typeof state.vitals.vitalPanelData[vitalType] === 'boolean') {
                (state.vitals.vitalPanelData[vitalType] as boolean) = value;
            }
        }
    },
    updateBloodPressure: (
        state: PatientState,
        action: PayloadAction<{ index: number; vitalType: BPNameTypes; value: string | boolean }>,
    ): void => {
        const { value, vitalType, index } = action.payload;
        if (state.vitals.vitalPanelData?.BP) {
            if (typeof value === 'string' && typeof state.vitals.vitalPanelData.BP[index][vitalType] === 'string') {
                (state.vitals.vitalPanelData.BP[index][vitalType] as string) = value;
            }
            if (typeof value === 'boolean' && typeof state.vitals.vitalPanelData.BP[index][vitalType] === 'boolean') {
                (state.vitals.vitalPanelData.BP[index][vitalType] as boolean) = value;
            }
        }
    },
    removeBloodPressure: (state: PatientState, action: PayloadAction<number>): void => {
        state.vitals.vitalPanelData.BP = state.vitals.vitalPanelData.BP.filter((bp, index) => index !== action.payload);
    },
};

export const vitalsExtraReducers = (builder: ActionReducerMapBuilder<PatientState>): ActionReducerMapBuilder<PatientState> =>
    builder
        .addCase(updatePatientVital.pending, (state) => {
            state.vitals.saving = LoadingStatus.Pending;
        })
        .addCase(updatePatientVital.fulfilled, (state, action) => {
            state.vitals.saving = LoadingStatus.Completed;
            state.vitals.data = action.payload;
            state.vitals.vitalPanelOpen = false;
            state.vitals.vitalPanelData = newVitalsPanelData;
            state.vitals.vitalsToUpdate = [];
        })
        .addCase(updatePatientVital.rejected, (state) => {
            state.vitals.saving = LoadingStatus.Failed;
        })
        .addCase(createPatientVital.pending, (state) => {
            state.vitals.saving = LoadingStatus.Pending;
        })
        .addCase(createPatientVital.fulfilled, (state, action) => {
            state.vitals.saving = LoadingStatus.Completed;
            state.vitals.data = action.payload;
            state.vitals.vitalPanelOpen = false;
            state.vitals.vitalPanelData = newVitalsPanelData;
            state.vitals.vitalsToUpdate = [];
        })
        .addCase(createPatientVital.rejected, (state) => {
            state.vitals.saving = LoadingStatus.Failed;
        });
