import { Label, Stack, Text } from '@fluentui/react';
import { TModal } from 'components';
import { useValidation } from 'hooks';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { RouteParams } from 'interfaces/route-params';
import { BatchField } from 'pages/components/Batch/BatchField';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { cleanupConvertUACModal, setConvertUACModalOpen } from 'state/slices/ledger/ledger.slice';
import { convertOveragesToUAC } from 'state/slices/ledger/uac-distribution/uac-distribution.actions';
import {
    selectConvertUACModalOpen,
    selectUACDistributionCommonTransaction,
    selectUACDistributionSaving,
} from 'state/slices/ledger/uac-distribution/uac-distribution.selectors';
import { batchActions, selectSelectedBatch } from 'state/slices/tenant/batches.slice';
import { usdCurrencyFormatter } from 'utils';
import { classicDateOnly } from 'utils/dateOnly';

export default function ConvertCreditUACConfirmation() {
    const { tenantId, patientId } = useParams<RouteParams>();
    const dispatch = useDispatch();
    const isOpen = useSelector(selectConvertUACModalOpen);

    const selectedBatch = useSelector(selectSelectedBatch);

    const transaction = useSelector(selectUACDistributionCommonTransaction);
    const saving = useSelector(selectUACDistributionSaving) === LoadingStatus.Pending;

    useEffect(() => {
        if (isOpen) {
            dispatch(batchActions.setBatchFilters({ path: 'dateOfEntry', value: new Date().toISOString() }));
            dispatch(batchActions.setDisableBatchesFilters(true));
        }
    }, [isOpen]);

    const dateOfEntryErrorMessage = selectedBatch?.dateOfEntry
        ? classicDateOnly(selectedBatch.dateOfEntry) !== classicDateOnly(new Date().toISOString())
            ? "The selected batch's date must be today's date. Please select a different batch with a date of today's date."
            : undefined
        : undefined;

    const convertOverage = () => {
        if (selectedBatch && transaction && !dateOfEntryErrorMessage)
            dispatch(
                convertOveragesToUAC({
                    tenantId,
                    selectedBatch,
                    patientId,
                    transaction,
                }),
            );
    };

    const [errors, onSubmit, handleCleanupErrors] = useValidation(
        [{ fieldName: 'Batch', validation: ['required'], value: selectedBatch }],
        convertOverage,
    );

    const onDismiss = () => {
        dispatch(setConvertUACModalOpen(false));
    };

    const onDismissed = () => {
        dispatch(cleanupConvertUACModal());
        dispatch(batchActions.cleanupBatchFilters());
        handleCleanupErrors();
    };
    return (
        <TModal
            hideContentOverflow
            mainButtons={[
                {
                    text: saving ? 'Saving...' : 'Confirm',
                    type: 'PrimaryButton',
                    onClick: onSubmit,
                    disabled: saving,
                },
                {
                    text: 'Cancel',
                    onClick: onDismiss,
                    disabled: saving,
                },
            ]}
            title="Convert Credit Balance to UAC"
            modalProps={{ isOpen, onDismiss, onDismissed }}
            isDraggable
        >
            <Stack tokens={{ childrenGap: 10 }} styles={{ root: { padding: 10 } }}>
                <Stack tokens={{ childrenGap: 20 }} grow horizontal wrap>
                    <BannerDataItem
                        label="Date of Service"
                        value={transaction?.dateOfService ? classicDateOnly(transaction.dateOfService) : undefined}
                    />
                    <BannerDataItem label="Code" value={transaction?.procedureCode} />
                    {/* <BannerDataItem label='Payment Date' value={transaction?.}/> */}
                    <BannerDataItem
                        label="Amount"
                        value={transaction?.creditAmount ? usdCurrencyFormatter.format(transaction.creditAmount) : undefined}
                    />
                    <BannerDataItem label="Method" value={transaction?.method} />
                    <BannerDataItem label="Identifier" value={transaction?.paymentIdentifier} />
                    <BannerDataItem label="Note" value={transaction?.note} />
                </Stack>
                <BatchField
                    textfieldProps={{
                        label: 'Batch',
                        errorMessage: dateOfEntryErrorMessage,
                    }}
                    error={errors}
                />
                <Text>
                    By clicking confirm, you are agreeing to convert this credit balance amount to an unapplied credit (UAC).
                </Text>
            </Stack>
        </TModal>
    );
}

type BannerDataItemProps = {
    label: string;
    value: string | undefined;
};
function BannerDataItem({ label, value }: BannerDataItemProps) {
    return (
        <Stack.Item>
            <Label>{label}</Label>
            <Text
                style={{
                    display: 'flex !important',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    maxWidth: 200,
                }}
                title={value ?? ''}
            >
                {value ?? ''}
            </Text>
        </Stack.Item>
    );
}
