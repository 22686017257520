import { IconButton, Stack } from '@fluentui/react';
import { Field } from 'components';
import { FormEvent } from 'react';
import { usdCurrencyFormatter } from 'utils';

type Props = {
    onChange?: (e?: FormEvent, input?: number) => void;
    transactionAmount?: number;
    disabled?: boolean;
    max?: number | undefined;
    maxAmountButtonDisabled?: boolean;
};

export default function BaseTransactionField({ onChange, max, disabled, transactionAmount, maxAmountButtonDisabled }: Props) {
    const _onChange = (e?: FormEvent, input?: number) => {
        if (onChange) onChange(e, input);
    };

    return (
        <Stack title={usdCurrencyFormatter.format(transactionAmount ?? 0)} horizontal>
            <Field.Currency prefix="$" value={transactionAmount} onChange={_onChange} disabled={disabled} max={max} />
            <IconButton
                iconProps={{ iconName: 'Add' }}
                title="Add max amount"
                onClick={() => _onChange(undefined, max)}
                disabled={disabled || maxAmountButtonDisabled}
            />
        </Stack>
    );
}
