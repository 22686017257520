import { ISoftTissueExamQuestions } from 'forms/SoftTissueExam/types';
import { RootState } from 'state/store';

export default function SoftTissueExam({ state }: { state: RootState }): JSX.Element | null {
    const form = state.chartNotes.quickNotes.assets.forms.softTissueExam;
    const formData = form?.data as ISoftTissueExamQuestions;
    if (!form) return null;

    const showSoftTissueExamSection =
        formData.lymphNodes ||
        formData.tmj ||
        formData.lips ||
        formData.buccalMucosa ||
        formData.vestibularMucosa ||
        formData.tongue ||
        formData.floorOfMouth ||
        formData.softPalate ||
        formData.hardPalate ||
        formData.pharynx ||
        formData.tonsils ||
        formData.gingiva;

    const showOtherSection =
        formData.occlusion ||
        formData.midlines ||
        formData.overbite ||
        formData.overjet ||
        formData.oralHygiene ||
        formData.calculus ||
        formData.perioExam ||
        formData.crossbite;

    const showPSRUpperArch =
        formData.upperRight?.toString().length ||
        formData.upperCenter?.toString().length ||
        formData.upperLeft?.toString().length;
    const showPSRLowerArch =
        formData.lowerRight?.toString().length ||
        formData.lowerCenter?.toString().length ||
        formData.lowerLeft?.toString().length;

    return (
        <>
            <h3>Soft Tissue Exam:</h3>

            {showSoftTissueExamSection && (
                <>
                    <h5>Soft Tissue Exam</h5>
                    <ul>
                        {formData.lymphNodes && (
                            <li>
                                Lymph Nodes: {formData.lymphNodes}
                                <ul>{formData.lymphNodesNotes && <li>Notes: {formData.lymphNodesNotes}</li>}</ul>
                            </li>
                        )}
                        {formData.tmj && (
                            <li>
                                TMJ: {formData.tmj}
                                <ul>{formData.tmjNotes && <li>Notes: {formData.tmjNotes}</li>}</ul>
                            </li>
                        )}
                        {formData.lips && (
                            <li>
                                Lips: {formData.lips}
                                <ul>{formData.lipsNotes && <li>Notes: {formData.lipsNotes}</li>}</ul>
                            </li>
                        )}
                        {formData.buccalMucosa && (
                            <li>
                                Buccal Mucosa: {formData.buccalMucosa}
                                <ul>{formData.buccalMucosaNotes && <li>Notes: {formData.buccalMucosaNotes}</li>}</ul>
                            </li>
                        )}
                        {formData.vestibularMucosa && (
                            <li>
                                Vestibular Mucosa: {formData.vestibularMucosa}
                                <ul>{formData.vestibularMucosaNotes && <li>Notes: {formData.vestibularMucosaNotes}</li>}</ul>
                            </li>
                        )}
                        {formData.tongue && (
                            <li>
                                Tongue: {formData.tongue}
                                <ul>{formData.tongueNotes && <li>Notes: {formData.tongueNotes}</li>}</ul>
                            </li>
                        )}
                        {formData.floorOfMouth && (
                            <li>
                                Floor of Mouth: {formData.floorOfMouth}
                                <ul>{formData.floorOfMouthNotes && <li>Notes: {formData.floorOfMouthNotes}</li>}</ul>
                            </li>
                        )}
                        {formData.hardPalate && (
                            <li>
                                Hard Palate: {formData.hardPalate}
                                <ul>{formData.hardPalateNotes && <li>Notes: {formData.hardPalateNotes}</li>}</ul>
                            </li>
                        )}
                        {formData.softPalate && (
                            <li>
                                Soft Palate: {formData.softPalate}
                                <ul>{formData.softPalateNotes && <li>Notes: {formData.softPalateNotes}</li>}</ul>
                            </li>
                        )}
                        {formData.pharynx && (
                            <li>
                                Pharynx: {formData.pharynx}
                                <ul>{formData.pharynxNotes && <li>Notes: {formData.pharynxNotes}</li>}</ul>
                            </li>
                        )}
                        {formData.tonsils && (
                            <li>
                                Tonsils: {formData.tonsils}
                                <ul>{formData.tonsilsNotes && <li>Notes: {formData.tonsilsNotes}</li>}</ul>
                            </li>
                        )}
                        {formData.gingiva && (
                            <li>
                                Gingiva: {formData.gingiva}
                                <ul>{formData.gingivaNotes && <li>Notes: {formData.gingivaNotes}</li>}</ul>
                            </li>
                        )}
                    </ul>
                </>
            )}
            {showOtherSection && (
                <>
                    <h5>Other</h5>
                    <ul>
                        {formData.occlusion && <li>Occlusion: {formData.occlusion}</li>}
                        <li>
                            {formData.midlines && <span>Midlines: {formData.midlines}</span>}
                            <ul>
                                {formData.toLeft && <li>To Left: {formData.toLeft}</li>}
                                {formData.toRight && <li>To Right: {formData.toRight}</li>}
                            </ul>
                        </li>
                        {formData.overbite && <li>Overbite: {formData.overbite}</li>}
                        {formData.overjet && <li>Overjet: {formData.overjet}</li>}
                        {formData.oralHygiene && <li>Oral Hygiene: {formData.oralHygiene}</li>}
                        {formData.calculus && <li>Calculus: {formData.calculus}</li>}
                        {formData.perioExam && <li>Perio Exam: {formData.perioExam}</li>}
                        {formData.crossbite && <li>Crossbite: {formData.crossbite}</li>}
                    </ul>
                </>
            )}
            {showPSRUpperArch && (
                <>
                    <h5>PSR Upper Arch</h5>
                    <ul>
                        {formData.upperRight?.toString().length && <li>Upper Right: {formData.upperRight}</li>}
                        {formData.upperCenter?.toString().length && <li>Upper Center: {formData.upperCenter}</li>}
                        {formData.upperLeft?.toString().length && <li>Upper Left: {formData.upperLeft}</li>}
                    </ul>
                </>
            )}
            {showPSRLowerArch && (
                <>
                    <h5>PSR Lower Arch</h5>
                    <ul>
                        {formData.lowerRight?.toString().length && <li>Lower Right: {formData.lowerRight}</li>}
                        {formData.lowerCenter?.toString().length && <li>Lower Center: {formData.lowerCenter}</li>}
                        {formData.lowerLeft?.toString().length && <li>Lower Left: {formData.lowerLeft}</li>}
                    </ul>
                </>
            )}
        </>
    );
}
