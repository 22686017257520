import { ComboBox, IDropdownOption, Stack, TextField, Text } from '@fluentui/react';
import IPatient, { PatientStatus } from 'api/models/patient.model';
import { Field, Section } from 'components';
import { useSelector } from 'hooks';
import { getValidationError } from 'hooks/useValidation';
import { useDispatch } from 'react-redux';
import { selectEditPatient, selectEditPatientValidationErrors } from 'state/slices/edit-patient/edit-patient.selectors';
import { editPatientPropChange } from 'state/slices/edit-patient/edit-patient.slice';
import { languagesCategories } from 'state/slices/tenant/language.slice';
import { legalSexesOptionsList } from 'state/slices/tenant/legal-sexes.slice';
import { classicDateOnly } from 'utils/dateOnly';
import { BasicDetailsFields } from '../BasicDetails';
import PatientAddress from './PatientAddress';
import { PatientDetailSection } from './PatientDetailsSection';

const patientStatus: IDropdownOption[] = [
    {
        key: 'active',
        text: 'Active',
    },
    {
        key: 'inactive',
        text: 'Inactive',
    },
    {
        key: 'prospective',
        text: 'Prospective',
    },
];
function PatientInfo(): JSX.Element {
    const dispatch = useDispatch();
    const legalSexOptions = useSelector(legalSexesOptionsList);
    const patient = useSelector(selectEditPatient);
    const languages = useSelector(languagesCategories);

    const validationErrors = useSelector(selectEditPatientValidationErrors);

    const _propChange = (key: keyof IPatient, value: unknown): void => {
        dispatch(editPatientPropChange({ key, value }));
    };

    const getLegalSexText = (key?: string): string => {
        if (!key) return '';
        const option = legalSexOptions.find((o) => o.key === key);
        return option ? option.text : '';
    };

    const getPreferredLanguageText = (key?: string): string => {
        if (!key) return '';
        const option = languages.find((o) => o.key === key);
        return option ? option.text : '';
    };

    const getPatientStatusText = (key?: string): string => {
        if (!key) return '';
        const option = patientStatus.find((o) => o.key === key);
        return option ? option.text : '';
    }

    function obfuscateSSN(ssn?: string, obfuscate = true): string {
        if (!ssn) return '';

        if (obfuscate) {
            return `***-**-****`;
        } else {
            return formatSSN(ssn);
        }
        function formatSSN(ssn?: string): string {
            if (!ssn) return '';

            const cleanSSN = ssn.replace(/\D/g, '');
            if (cleanSSN.length !== 9) {
                return '';
            }

            return `${cleanSSN.slice(0, 3)}-${cleanSSN.slice(3, 5)}-${cleanSSN.slice(5)}`;
        }
    }


    return (
        <Section>
            <Stack tokens={{ childrenGap: 10 }}>
                <PatientDetailSection
                    sectionLabel="Identification"
                    items={[
                        [
                            { label: 'Legal first name', value: patient?.firstName },
                            { label: 'Middle name', value: patient?.middleName },
                            { label: 'Legal last name', value: patient?.lastName },
                            {
                                label: 'First name used',
                                value: patient?.chosenName,
                                tooltip: {
                                    content: (
                                        <Stack>
                                            <Text>First name used</Text>
                                            <Text variant="small">
                                                The patient&apos;s <strong>first name used</strong> will appear on their patient
                                                ID throughout athenaCollector and athenaClinicals. It may not reflect the
                                                patient&apos;s legal name.
                                            </Text>
                                        </Stack>
                                    ),
                                },
                            },
                            { label: 'Suffix', value: patient?.suffix },
                            { label: 'Legal sex', value: getLegalSexText(patient?.legalSexId) },
                        ],
                        [
                            { label: 'DOB', value: patient?.dateOfBirth ? classicDateOnly(patient.dateOfBirth) : '' },
                            {
                                label: 'SSN',
                                value: obfuscateSSN(patient?.ssn),
                                tooltip: {
                                    content: (
                                        <Stack>
                                            <Text>SSN</Text>
                                            <Text variant="small" styles={{ root: { userSelect: 'none' } }}>
                                                {obfuscateSSN(patient?.ssn, false)}
                                            </Text>
                                        </Stack>
                                    ),
                                    iconName: 'RedEye',
                                },
                            },
                            { label: 'Language', value: getPreferredLanguageText(patient?.preferredLanguage) },
                            {
                                label: 'Email',
                                value: patient?.emailAddress,
                            },
                            {
                                label: 'Patient status',
                                value: getPatientStatusText(patient?.patientStatus ?? PatientStatus.Active),
                            },
                        ],
                    ]}
                />
                <PatientAddress />
            </Stack>
        </Section>
    );
}

export default PatientInfo;
