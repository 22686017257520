import { IComboBoxOption } from '@fluentui/react';
import { createAsyncThunk, createSelector, createSlice, Dictionary } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import IProvider from 'api/models/provider.model';
import { AxiosError, AxiosResponse } from 'axios';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { LookupState } from 'interfaces/lookup-state';
import { isArray, map, orderBy } from 'lodash';
import { RootState } from 'state/store';
import { updateLookup } from '../lookups/lookups.reducers';

type ProvidersState = LookupState<IProvider>;

const initialState: ProvidersState = {
    initialLoad: LoadingStatus.Idle,
    loading: LoadingStatus.Idle,
    data: {},
};

export const getProviders = createAsyncThunk<
    AxiosResponse<Dictionary<IProvider>>,
    {
        tenantId: string;
    },
    {
        rejectValue: AxiosError;
    }
>('getProviders', async ({ tenantId }) => {
    const res = await dentalApi.getProviders(tenantId);
    return res;
});

const providersSlice = createSlice({
    name: 'providers',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getProviders.pending, (state) => {
                state.initialLoad = LoadingStatus.Pending;
            })
            .addCase(getProviders.fulfilled, (state, action) => {
                state.initialLoad = LoadingStatus.Completed;
                state.data = action.payload.data;
            })
            .addCase(getProviders.rejected, (state, action) => {
                state.initialLoad = LoadingStatus.Failed;
                state.errors = action.payload?.message;
            })
            //When a provider is updated via settings, then we definitely need to update the providers state to include the updated information.
            .addCase(updateLookup.fulfilled, (state, action) => {
                if (action.meta.arg.lookupType === 'Providers' && state.data) {
                    state.data[action.payload.id] = action.payload;
                }
            });
    },
});

const { reducer } = providersSlice;

/**
 * Create fluent provider option object from provider data.
 */
function createProviderOption(p: IProvider) {
    return {
        key: p.id,
        text: `${p.lastName}${p.suffix ? ` ${p.suffix}` : ''}, ${p.firstName}`,
    };
}

/**
 * Return a list of options that may contain a deleted provider in the options list only if that provider is selected.
 */
export function getOptionsWithSelectedProvider({
    selectedProviderId: selectedProvider,
    options,
    lookup,
}: {
    selectedProviderId: string | string[] | undefined;
    options: IComboBoxOption[];
    lookup: Dictionary<IProvider>;
}): IComboBoxOption[] {
    if (!selectedProvider) return options;
    if (isArray(selectedProvider)) {
        selectedProvider.forEach((providerId) => {
            const provider = lookup[providerId];
            if (provider?.isDeleted) options.push(createProviderOption(provider));
        });
    } else {
        const selectedProviderData = lookup[selectedProvider];
        if (selectedProviderData?.isDeleted) return [...options, createProviderOption(selectedProviderData)];
    }
    return options;
}

export const selectProviders = (state: RootState): ProvidersState => state.tenant.providers;
export const selectProvidersData = (state: RootState): Dictionary<IProvider> => state.tenant.providers.data;
export const selectProvidersAsList = createSelector(selectProvidersData, (providers) => {
    if (providers && Object.keys(providers).length > 0) {
        const users = map(providers).filter((provider) => provider !== undefined) as IProvider[];
        return orderBy(users, ['lastName', 'firstName'], ['asc', 'asc']);
    }
    return [];
});

/**
 * Filters isDeleted providers and orders those providers by last name. If last name doesn't exist the first name is used to order.
 * */
export const selectFilteredProviders = createSelector(selectProvidersAsList, (providers) => {
    return providers.filter((p) => !p.isDeleted);
});

//Filtered provider lists:
export const selectRDAProviders = createSelector(selectProvidersAsList, (providers) => {
    return providers.filter((provider) => provider.isRegisteredDentalAssistant);
});
export const selectProviderListWithoutRDA = createSelector(selectProvidersAsList, (providers) => {
    const users = providers.filter((provider) => !provider.isRegisteredDentalAssistant);
    return users;
});
export const selectTreatingProviders = createSelector(selectFilteredProviders, (providers) =>
    providers.filter((p) => p.isTreatingProvider || p.isAttestingHygienist),
);
export const selectTreatingProvidersOnly = createSelector(selectTreatingProviders, (providers) =>
    providers.filter((p) => !p.isHygienist && !p.isResident),
);
export const selectHygienistProviders = createSelector(selectFilteredProviders, (providers) =>
    providers.filter((p) => p.isHygienist),
);
export const selectReportingProviders = createSelector(selectFilteredProviders, (providers) =>
    providers.filter((p) => !p.isRegisteredDentalAssistant),
);

//Option lists based on filtered provider lists:
export const selectTreatingProviderOptions = createSelector(selectTreatingProviders, (providers) => {
    return providers.map(createProviderOption);
});
export const selectTreatingProviderOnlyOptions = createSelector(selectTreatingProvidersOnly, (providers) => {
    return providers.map(createProviderOption);
});
export const selectHygienistOptions = createSelector(selectHygienistProviders, (providers) => {
    return providers.map(createProviderOption);
});
export const selectRDAProviderOptions = createSelector(selectRDAProviders, (providers) => {
    return providers.map(createProviderOption);
});
export const selectTreatingProviderAndHygienistOptions = createSelector(
    selectTreatingProviderOptions,
    selectHygienistOptions,
    (treatingProviderOptions, hygienistOptions) => {
        return [
            ...treatingProviderOptions.map((o) => ({ ...o, groupName: 'Treating Provider' })),
            ...hygienistOptions.map((o) => ({ ...o, groupName: 'Hygienist' })),
        ];
    },
);
export const selectReportingProviderWithOptions = createSelector(selectReportingProviders, (providers) => {
    return providers.map(createProviderOption);
});
export const selectReportingProviderWithGroupNameOptions = createSelector(selectReportingProviders, (providers) => {
    return providers.map((p) => ({
        ...createProviderOption(p),
        groupName: p?.isTreatingProvider
            ? 'Treating Provider'
            : p?.isHygienist
                ? 'Hygienist'
                : p?.isResident
                    ? 'Resident'
                    : undefined,
    }));
});

export default reducer;
