import { ComboBox, Dropdown, IDropdownOption, Stack, TextField } from '@fluentui/react';
import IPatientContact from 'api/models/patient-contact.model';
import { Field } from 'components';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectContact } from 'state/slices/contacts/contacts.slice';
import { selectSameAddressAsPatient } from 'state/slices/edit-patient/edit-patient.selectors';
import { classicDateOnly } from 'utils/dateOnly';
import ContactAddressFields from './ContactAddressFields';

enum ContactType {
    EmergencyContact = 'EmergencyContact',
    Guarantor = 'Guarantor',
}

function ContactFields(): JSX.Element {
    const address = useSelector(selectSameAddressAsPatient);
    const contact = useSelector(selectContact);

    //For storing the initial contact data without any edits.
    const [initialContact, setInitialContact] = useState<IPatientContact>();

    useEffect(() => {
        if (contact && !initialContact) setInitialContact(contact);
    }, [contact]);

    useEffect(() => {
        return () => {
            setInitialContact(undefined);
        };
    }, []);

    const contactTypeOptions: IDropdownOption[] = [
        {
            key: '',
            text: '(Select Contact Type)',
        },
        {
            key: ContactType.Guarantor,
            text: ContactType.Guarantor,
        },
        {
            key: ContactType.EmergencyContact,
            text: 'Emergency Contact',
        },
    ];
    const getContactType = () => {
        if (contact) {
            if (contact.isGuarantor) return ContactType.Guarantor;
            if (contact.isEmergencyContact) return ContactType.EmergencyContact;
            return '';
        }

        return '';
    };

    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <Stack tokens={{ childrenGap: 10 }}>
                <Stack grow horizontal tokens={{ childrenGap: 10 }}>
                    <ComboBox
                        label="Patient's relationship to contact"
                        style={{ width: 220 }}
                        selectedKey={contact?.relationshipId}
                        allowFreeform
                        autoComplete="on"
                        options={contact?.relationshipId ? [{ key: contact.relationshipId, text: contact.relationshipId }] : []}
                        disabled
                    />

                    <Stack tokens={{ childrenGap: 10 }} horizontal verticalAlign="end">
                        <Dropdown label="Contact Type" selectedKey={getContactType()} disabled options={contactTypeOptions} />
                    </Stack>
                </Stack>
                <Stack tokens={{ childrenGap: 10 }} horizontal grow>
                    <Stack.Item>
                        <ComboBox
                            label="Title"
                            style={{ width: 120 }}
                            selectedKey={contact?.titleId}
                            allowFreeform
                            autoComplete="on"
                            disabled
                            options={[
                                {
                                    key: 'Mr.',
                                    text: 'Mr.',
                                },
                                {
                                    key: 'Miss.',
                                    text: 'Miss.',
                                },
                                {
                                    key: 'Mrs.',
                                    text: 'Mrs.',
                                },
                                {
                                    key: 'None',
                                    text: 'None',
                                },
                            ]}
                        />
                    </Stack.Item>
                    <Stack.Item grow>
                        <TextField label="First Name" value={contact?.firstName} autoComplete="off" disabled />
                    </Stack.Item>
                    <Stack.Item grow>
                        <TextField label="Middle Name" value={contact?.middleName} autoComplete="off" disabled />
                    </Stack.Item>
                    <Stack.Item grow>
                        <TextField label="Last Name" value={contact?.lastName} autoComplete="off" disabled />
                    </Stack.Item>
                </Stack>
                <Stack grow horizontal tokens={{ childrenGap: 10 }}>
                    <Field.Phone label="Home Number" value={contact?.homePhoneNumber} autoComplete="off" disabled />
                    <Field.Phone label="Mobile Number" value={contact?.mobilePhoneNumber} autoComplete="off" disabled />
                </Stack>
                {contact?.isGuarantor && (
                    <Stack.Item>
                        <Field.Date
                            label="Date of Birth"
                            value={contact?.dateOfBirth ? classicDateOnly(contact.dateOfBirth) : ''}
                            hasDatePicker
                            autoComplete="off"
                            disabled
                            allowFutureDates={false}
                        />
                    </Stack.Item>
                )}
            </Stack>
            {contact?.isGuarantor && (
                <Stack.Item grow>
                    <Field.Email
                        label="Email"
                        value={contact?.email}
                        autoComplete="off"
                        disabled
                        styles={{
                            root: {
                                width: 400,
                            },
                        }}
                    />
                </Stack.Item>
            )}
            {contact?.isGuarantor ? <ContactAddressFields address={address} contact={contact} /> : null}
        </Stack>
    );
}

export default ContactFields;
