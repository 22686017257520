import { Label, Stack, Toggle } from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import { selectShowMedicationHistory } from 'state/slices/patient/overview/overview.selectors';
import { setShowMedications } from 'state/slices/patient/patient.slice';

export function ToggleMedicationHistory(): JSX.Element {
    const dispatch = useDispatch();
    const showHistory = useSelector(selectShowMedicationHistory);

    const _toggleHistory = () => {
        dispatch(setShowMedications(!showHistory));
    };

    const _labelText = showHistory ? 'Hide history' : 'Show history';
    return (
        <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign="center" onClick={_toggleHistory}>
            <Toggle checked={showHistory} styles={{ root: { margin: 0 } }} />
            <Label style={{ cursor: 'pointer' }}>{_labelText}</Label>
        </Stack>
    );
}
