import { Stack } from '@fluentui/react';
import { Field } from 'components';
import { setWorkListFilter } from 'state/slices/admin-huddle/worklists/worklist.slice';
import { selectCurrentWorkList, selectWorkListFilters } from 'state/slices/admin-huddle/worklists/worklist.selectors';
import { useSelector } from 'hooks';
import { EncounterWorkList } from 'state/slices/admin-huddle/worklists/encounter-worklists/encounter-worklists.state';
import { useDispatch } from 'react-redux';

export default function DOSThroughFilter() {
    const dispatch = useDispatch();
    const encounterWorkList = useSelector(selectCurrentWorkList);
    const { dateOfServiceThrough } = useSelector(selectWorkListFilters<any>);

    return (
        <Stack.Item grow>
            <Field.Date
                label={
                    encounterWorkList === EncounterWorkList.BilledInsurance
                        ? 'DOS Through (Default: Last 30 Days Aging)'
                        : 'DOS Through'
                }
                hasDatePicker
                value={dateOfServiceThrough ? dateOfServiceThrough : ''}
                onChange={(e, value) => {
                    dispatch(setWorkListFilter({ path: 'dateOfServiceThrough', value: value ?? '' }));
                }}
            />
        </Stack.Item>
    );
}
