import {
    DefaultButton,
    Link,
    MessageBar,
    MessageBarType,
    Panel,
    PanelType,
    PrimaryButton,
    Spinner,
    Stack,
    Text,
} from '@fluentui/react';
import { generatePatientValidationConfig } from 'components/EditPatientPanel';
import { usePatientId, useSelector, useTenantId, useValidation } from 'hooks';
import { LoadingStatus } from 'interfaces/loading-statuses';
import PatientDetailsCard from 'pages/components/PatientDetailsCard';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    selectEditPatient,
    selectEditPatientLoading,
    selectEditPatientValidationErrors,
} from 'state/slices/edit-patient/edit-patient.selectors';
import { cleanupEditPatient, setEditPatientValidationErrors } from 'state/slices/edit-patient/edit-patient.slice';
import { finishCheckin, returnToScheduleFromCheckin } from 'state/slices/scheduling/check-in/check-in.actions';
import {
    selectfinishCheckinLoading,
    selectIsCheckInPanelError,
    selectIsCheckInPanelOpen,
} from 'state/slices/scheduling/check-in/check-in.selectors';
import { selectSelectedAppointmentData } from 'state/slices/scheduling/scheduling.selectors';
import { cleanupCheckInPanel, setAllCheckInSectionOpen, setIsCheckinPanelOpen } from 'state/slices/scheduling/scheduling.slice';
import { getSlidingFeeProgramByPatient } from 'state/slices/tenant/sliding-fee.slice';
import { getPatientTasksByGroup } from 'state/task-management/taskManagement.actions';
import {
    cleanupCheckoutCheckinTasks,
    selectCheckoutCheckinTasks,
    selectLoadingCheckoutCheckinTasks,
} from 'state/task-management/taskManagement.slice';
import AppointmentInfoBanner from '../AppointmentInfoBanner';
import BasicDetailsSection from './BasicDetailsSection';
import FinanceSection from './FinanceSection';
import PayersSection from './PayerSection';
import { TaskGroup } from 'api/models/user-tasks-by-group';
import ErrorTypeMessageBar from 'components/ErrorTypeMessageBar';
import { checkIn } from 'state/slices/scheduling/scheduling.actions';
import { appInsights } from 'index';
import { useFeatureFlag } from 'hooks/useFeatureFlags';
import { FeatureFlag } from 'state/slices/tenant/feature-flags.slice';

export default function CheckInPanel(): JSX.Element {
    const dispatch = useDispatch();
    const isOpen = useSelector(selectIsCheckInPanelOpen);
    const patient = useSelector(selectEditPatient);
    const patientAppointment = useSelector(selectSelectedAppointmentData);
    const loadingDempgrahics = useSelector(selectEditPatientLoading);
    const validationErrors = useSelector(selectEditPatientValidationErrors);
    const _selectfinishCheckinLoading = useSelector(selectfinishCheckinLoading);
    const _selectAllCheckoutCheckinTasks = useSelector(selectCheckoutCheckinTasks);
    const _tasksLoading = useSelector(selectLoadingCheckoutCheckinTasks) === LoadingStatus.Pending;
    const errors = useSelector(selectIsCheckInPanelError);

    const tenantId = useTenantId();

    const isLoading = loadingDempgrahics === LoadingStatus.Pending;

    const patientId = usePatientId();

    useEffect(() => {
        if (patientId) dispatch(getSlidingFeeProgramByPatient({ tenantId, patientId }));
    }, [tenantId, patientId]);

    useEffect(() => {
        return () => {
            _onDismiss();
            _onDismissed();
        };
    }, []);

    useEffect(() => {
        if (isOpen && patient?.id && patientAppointment) {
            dispatch(
                getPatientTasksByGroup({
                    tenantId,
                    patientId: patient.id,
                    //Checkin task group is the same as checkout group currently
                    group: TaskGroup.Checkout,
                    references: { appointmentId: patientAppointment.id },
                }),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, patient?.id, patientAppointment?.id]);

    const isPatientReadOnly = useFeatureFlag(FeatureFlag.PatientReadOnly);
    const validationConfig = generatePatientValidationConfig(patient, isPatientReadOnly);

    const [finishCheckinErrors, submitCheckin, cleanupCheckinErrors] = useValidation(validationConfig, _finishCheckIn);
    const [returnToSchedulingErrors, submitReturnToScheduling, cleanupReturnToSchedulingErrors] = useValidation(
        validationConfig,
        _backToSchedulingFromCheckIn,
    );

    useEffect(() => {
        dispatch(setEditPatientValidationErrors(finishCheckinErrors));
    }, [finishCheckinErrors]);
    useEffect(() => {
        dispatch(setEditPatientValidationErrors(returnToSchedulingErrors));
    }, [returnToSchedulingErrors]);

    function _onDismiss() {
        dispatch(setIsCheckinPanelOpen(false));
    }

    function _onDismissed() {
        dispatch(cleanupCheckInPanel());
        dispatch(cleanupEditPatient());
        dispatch(cleanupCheckoutCheckinTasks());

        cleanupCheckinErrors();
        cleanupReturnToSchedulingErrors();
    }

    function _backToSchedulingFromCheckIn() {
        if (patientAppointment && patient) {
            const eventInfo = {
                tenantId,
                appointmentId: patientAppointment.id,
            };
            appInsights.trackEvent({ name: 'Check-in Appt. (Save and close)', properties: eventInfo });
            dispatch(returnToScheduleFromCheckin({ tenantId, appointment: patientAppointment, patient }));
        }
    }

    function _finishCheckIn() {
        if (patientAppointment && patient) {
            const eventInfo = {
                tenantId,
                appointmentId: patientAppointment.id,
            };
            appInsights.trackEvent({ name: 'Check-in Appt. (Save)', properties: eventInfo });
            dispatch(finishCheckin({ tenantId, appointment: patientAppointment, patient }));
        }
    }

    useEffect(() => {
        if (validationErrors.length) {
            dispatch(setAllCheckInSectionOpen());
        }
    }, [validationErrors]);

    const onClickRefreash = () => {
        if (patientAppointment && patient)
            dispatch(checkIn({ tenantId, appointmentId: patientAppointment.id, patientId: patient?.id }));
    };

    return (
        <Panel
            headerText="Appointment Check In"
            onRenderHeader={(props) => (
                <Stack style={{ paddingLeft: 25, paddingBottom: 15 }} tokens={{ childrenGap: 10 }} grow>
                    <Text variant="xLarge">{props?.headerText}</Text>
                    <PatientDetailsCard showClinicalAlerts patient={patient} />
                </Stack>
            )}
            type={PanelType.custom}
            customWidth="800px"
            isOpen={isOpen}
            onDismiss={_onDismiss}
            onDismissed={_onDismissed}
            styles={{
                content: { overflowY: 'auto', overflowX: 'hidden', flex: 1, position: 'relative' },
                root: { overflow: 'hidden' },
                scrollableContent: { overflow: 'hidden', display: 'flex', flexDirection: 'column' },
            }}
            isFooterAtBottom={true}
            onRenderFooterContent={() => (
                <Stack tokens={{ childrenGap: 5 }}>
                    {validationErrors.length && (
                        <MessageBar messageBarType={MessageBarType.blocked}>
                            Missing required fields. ({validationErrors.map((error) => error.fieldName).join(', ')})
                        </MessageBar>
                    )}
                    {errors?.length && (
                        <Stack tokens={{ childrenGap: 2 }}>
                            <ErrorTypeMessageBar error={errors}>
                                <Link onClick={onClickRefreash}> Refresh </Link> to view the most current information.
                            </ErrorTypeMessageBar>
                        </Stack>
                    )}
                    <Stack horizontal tokens={{ childrenGap: 5 }} verticalAlign="center">
                        <PrimaryButton
                            onClick={submitCheckin}
                            text="Finish Check In"
                            disabled={isLoading || _selectfinishCheckinLoading}
                        />
                        <DefaultButton
                            onClick={submitReturnToScheduling}
                            text="Save & Close"
                            disabled={isLoading || _selectfinishCheckinLoading}
                        />
                        {_selectfinishCheckinLoading && <Spinner labelPosition="right" label="Saving..." />}
                    </Stack>
                </Stack>
            )}
        >
            {isLoading || _selectfinishCheckinLoading ? (
                <Spinner label={_selectfinishCheckinLoading ? 'Saving...' : 'Loading...'} />
            ) : (
                <Stack tokens={{ childrenGap: 10 }}>
                    <AppointmentInfoBanner showProcedures />
                    <Stack tokens={{ childrenGap: 5 }} grow>
                        {_tasksLoading ? (
                            <Spinner label="Loading Tasks..." />
                        ) : (
                            !_selectAllCheckoutCheckinTasks.length && (
                                <MessageBar>There are no tasks to complete here.</MessageBar>
                            )
                        )}
                        <BasicDetailsSection />
                        <PayersSection isCheckin />
                        <FinanceSection />
                    </Stack>
                </Stack>
            )}
        </Panel>
    );
}
