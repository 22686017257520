import { Dropdown, IDropdownOption, Stack } from '@fluentui/react';
import { IAppointmentNoEncounter } from 'api/models/appointment-no-encounter.model';
import { ReportType } from 'api/models/embed-report.model';
import { Field } from 'components';
import { useTenantId } from 'hooks';
import { getValidationError } from 'hooks/useValidation';
import { sortBy } from 'lodash';
import ReportWrapper from 'pages/Reporting/ReportWrapper';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import reportActionLookup from 'state/slices/reporting/reportActionLookup';
import {
    getAllLocationsOfCareForReports,
    getEncounterStatusOptions,
    getPatientTrackerOptions,
} from 'state/slices/reporting/reporting.actions';
import {
    encounterStatusOption,
    patientTrackerStatusOption,
    selectLocationsOfCareFromReporting,
    selectReportProperties,
    selectReportingValidationErrors,
} from 'state/slices/reporting/reporting.selectors';
import { setAppointmentNoEncounterProp, toggleAppointmentNoEncounterListId } from 'state/slices/reporting/reporting.slice';
import { selectHygienistOptions, selectTreatingProviderOptions } from 'state/slices/tenant/providers.slice';
import { classicDateOnly } from 'utils/dateOnly';

function AppointmentsReportNoEncounterAssociated() {
    const tenantId = useTenantId();
    const dispatch = useDispatch();

    const errors = useSelector(selectReportingValidationErrors);
    const locationsOfCare = useSelector(selectLocationsOfCareFromReporting);
    const _providerOptions = useSelector(selectTreatingProviderOptions);
    const _hygienistOptions = useSelector(selectHygienistOptions);

    const apptTrackingStatusOption = useSelector(patientTrackerStatusOption);

    const query = useSelector(selectReportProperties) as IAppointmentNoEncounter;

    const locationsOfCareOptions: IDropdownOption[] = locationsOfCare.map((loc) => ({
        key: loc.id,
        text: loc.displayName,
    }));

    useEffect(() => {
        dispatch(getAllLocationsOfCareForReports({ tenantId }));

        dispatch(getPatientTrackerOptions());
    }, []);
    const _onViewReport = () => {
        if (query) dispatch(reportActionLookup[ReportType.AppointmentsReportNoEncounterAssociated]({ tenantId, ...query }));
    };


    const groupOption: IDropdownOption[] = sortBy(
        [

            { key: '', text: '(Select Group Column)' },
            { key: 'Location Of Care', text: 'Location of Care' },
            { key: 'Operatory', text: 'Operatory' },
            { key: 'Treating Provider', text: 'Treating Provider' },
            { key: 'Hygienist', text: 'Hygienist' },
            { key: 'Appointment Reason', text: 'Reason for Encounter' },
            { key: 'Appointment Status', text: 'Confirmation Status' },
            { key: 'Tracker Status', text: 'Patient Tracker Status' },
        ],
        'text',
    );

    return (
        <ReportWrapper
            validationConfig={[
                {
                    fieldName: 'Start Date',
                    validation: ['required'],
                    value: query.apptStart,
                },
                {
                    fieldName: 'End Date',
                    validation: ['required'],
                    value: query.apptEnd,
                },
            ]}
            onViewReport={_onViewReport}
        >
            <Stack grow horizontal wrap tokens={{ childrenGap: 20 }}>
                <Stack.Item>
                    <Field.Date
                        label="Start Date"
                        required
                        value={query?.apptStart ? classicDateOnly(query.apptStart, 'MM/dd/yyyy') : ''}
                        onChange={(ev, value) => {
                            const newDate = value ? classicDateOnly(value, 'yyyy-MM-dd') : undefined;
                            dispatch(setAppointmentNoEncounterProp({ path: 'apptStart', value: newDate }));
                        }}
                        hasDatePicker
                        errorMessage={getValidationError(errors, 'Start Date') ? 'Start date is required.' : undefined}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.Date
                        label="End Date"
                        required
                        value={query?.apptEnd ? classicDateOnly(query.apptEnd, 'MM/dd/yyyy') : ''}
                        onChange={(ev, value) => {
                            const newDate = value ? classicDateOnly(value, 'yyyy-MM-dd') : undefined;
                            dispatch(setAppointmentNoEncounterProp({ path: 'apptEnd', value: newDate }));
                        }}
                        isReasonable
                        disabled={!query?.apptStart}
                        minReasonableErrorMessage="End date must be after start date."
                        minReasonableDate={query?.apptStart ? new Date(classicDateOnly(query.apptStart)) : undefined}
                        hasDatePicker
                        errorMessage={getValidationError(errors, 'End Date') ? 'End date is required.' : undefined}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.SearchCombo
                        placeholder="(Select Locations of Care)"
                        label="Locations of Care"
                        multiSelect
                        options={locationsOfCareOptions}
                        style={{ minWidth: 300 }}
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(
                                    toggleAppointmentNoEncounterListId({ id: option.key as string, path: 'locationOfCareIds' }),
                                );
                        }}
                        selectedKey={query?.locationOfCareIds}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.SearchCombo
                        placeholder="(Select Treatment Providers)"
                        label="Treating  Providers"
                        multiSelect
                        options={_providerOptions}
                        style={{ minWidth: 300 }}
                        selectedKey={query?.treatingProviderIds}
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(
                                    toggleAppointmentNoEncounterListId({ id: option.key as string, path: 'treatingProviderIds' }),
                                );
                        }}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.SearchCombo
                        placeholder="(Select Hygienists)"
                        label="Hygienists"
                        multiSelect
                        options={_hygienistOptions}
                        selectedKey={query?.hygienistIds}
                        style={{ minWidth: 300 }}
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(toggleAppointmentNoEncounterListId({ id: option.key as string, path: 'hygienistIds' }));
                        }}
                    />
                </Stack.Item>
            </Stack>

            <Stack grow horizontal wrap tokens={{ childrenGap: 20 }}>
                <Stack.Item>
                    <Field.SearchCombo
                        label="Patient Tracker Status"
                        options={apptTrackingStatusOption}
                        selectedKey={query?.trackerStatus}
                        placeholder="(Select Patient Tracker Status)"
                        multiSelect
                        style={{ minWidth: 300 }}
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(
                                    toggleAppointmentNoEncounterListId({
                                        path: 'trackerStatus',
                                        id: option.key as string,
                                    }),
                                );
                        }}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Dropdown
                        label="Group Column 1"
                        placeholder="(Select Group Column)"
                        options={groupOption}
                        onChange={(ev, option) => {
                            if (option) {
                                dispatch(
                                    setAppointmentNoEncounterProp({
                                        path: 'groupCol1',
                                        value: option.key,
                                    }),
                                );
                            }
                        }}
                        style={{ minWidth: 300 }}
                        selectedKey={query?.groupCol1}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Dropdown
                        label="Group Column 2"
                        placeholder="(Select Group Column)"
                        options={groupOption}
                        onChange={(ev, option) => {
                            if (option) {
                                dispatch(
                                    setAppointmentNoEncounterProp({
                                        path: 'groupCol2',
                                        value: option.key,
                                    }),
                                );
                            }
                        }}
                        selectedKey={query?.groupCol2}
                        style={{ minWidth: 300 }}
                    />
                </Stack.Item>
            </Stack>
        </ReportWrapper>
    );
}

export default AppointmentsReportNoEncounterAssociated;
