import { PayloadAction } from '@reduxjs/toolkit';
import { IReportingState, IToothLedgerReportProperties } from '../reporting.state';

export const toothLedgerReducers = {
    setToothLedgerProp: (
        state: IReportingState,
        action: PayloadAction<{ path: keyof IToothLedgerReportProperties; value: string | number | undefined }>,
    ): void => {
        const { path, value } = action.payload;
        ((state.reportProperties as IToothLedgerReportProperties)[path] as string | number | undefined) = value;
    },
};
