import { Stack } from '@fluentui/react';
import { ClinicalAlertDataItemComponentProps } from '../ClinicalAlertDataItem';
import BaseAlertDataItem from './BaseAlertDataItem';
import DataItemLabel from './DataItemLabel';

export default function PatientAlertDataItem({ data }: ClinicalAlertDataItemComponentProps) {
    return (
        <BaseAlertDataItem data={data}>
            {data.references?.description && (
                <Stack styles={{ root: { overflowWrap: 'break-word', maxWidth: 400 } }}>
                    <DataItemLabel text="Description:" /> {data.references?.description}
                </Stack>
            )}
        </BaseAlertDataItem>
    );
}
