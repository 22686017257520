import { ISoftTissueExamQuestions, SoftTissueExamValue } from './types';

export const formTitle = 'Soft Tissue Exam';

export const allWNL: Partial<{ [key in keyof ISoftTissueExamQuestions]: string }> = {
    buccalMucosa: SoftTissueExamValue.WithinNormalLimits,

    vestibularMucosa: SoftTissueExamValue.WithinNormalLimits,

    lymphNodes: SoftTissueExamValue.WithinNormalLimits,

    tmj: SoftTissueExamValue.WithinNormalLimits,

    lips: SoftTissueExamValue.WithinNormalLimits,

    tongue: SoftTissueExamValue.WithinNormalLimits,

    floorOfMouth: SoftTissueExamValue.WithinNormalLimits,

    hardPalate: SoftTissueExamValue.WithinNormalLimits,

    softPalate: SoftTissueExamValue.WithinNormalLimits,

    pharynx: SoftTissueExamValue.WithinNormalLimits,

    tonsils: SoftTissueExamValue.WithinNormalLimits,

    gingiva: SoftTissueExamValue.WithinNormalLimits,
};

export const softTissueExamSectionDefaults: Partial<{ [key in keyof ISoftTissueExamQuestions]: string }> = {
    buccalMucosa: SoftTissueExamValue.Empty,
    buccalMucosaNotes: '',

    vestibularMucosa: SoftTissueExamValue.Empty,
    vestibularMucosaNotes: '',

    lymphNodes: SoftTissueExamValue.Empty,
    lymphNodesNotes: '',

    tmj: SoftTissueExamValue.Empty,
    tmjNotes: '',

    lips: SoftTissueExamValue.Empty,
    lipsNotes: '',

    tongue: SoftTissueExamValue.Empty,
    tongueNotes: '',

    floorOfMouth: SoftTissueExamValue.Empty,
    floorOfMouthNotes: '',

    hardPalate: SoftTissueExamValue.Empty,
    hardPalateNotes: '',

    softPalate: SoftTissueExamValue.Empty,
    softPalateNotes: '',

    pharynx: SoftTissueExamValue.Empty,
    pharynxNotes: '',

    tonsils: SoftTissueExamValue.Empty,
    tonsilsNotes: '',

    gingiva: SoftTissueExamValue.Empty,
    gingivaNotes: '',
};

export const otherSectionDefaults: Partial<ISoftTissueExamQuestions> = {
    calculus: '',
    crossbite: '',
    gingivalBleeding: '',
    midlines: '',
    occlusion: '',
    oralHygiene: '',
    overbite: '',
    overjet: '',
    perioExam: '',
    toLeft: undefined,
    toRight: undefined,
};

export const psrUpperArchDefaults: Partial<ISoftTissueExamQuestions> = {
    upperCenter: undefined,
    upperRight: undefined,
    upperLeft: undefined,
};

export const psrLowerArchDefaults: Partial<ISoftTissueExamQuestions> = {
    lowerCenter: undefined,
    lowerRight: undefined,
    lowerLeft: undefined,
};

export const questions: { [key in keyof ISoftTissueExamQuestions]: unknown } = {
    ...softTissueExamSectionDefaults,
    ...otherSectionDefaults,
};

export const noteTransalations: { [key in keyof ISoftTissueExamQuestions]: string } = {};
