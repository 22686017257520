import { Dictionary } from '@reduxjs/toolkit';
import { SoftDeleteSupport } from '../support-types';
import { DocumentBase } from 'api/document-base.model';
import { EncounterStatus } from '../encounter.model';
import { ClinicalAlertType, IClinicalAlerts } from '../clinical-alert.model';

export interface AppointmentBase extends DocumentBase {
    operatoryId: string;
    locationOfCareId: string;
    locationOfCareName?: string;
    date: string;
    startTime: string;
    endTime: string;
}
export interface IPatientAppointment extends AppointmentBase {
    patientId: string;
    encounterId?: string;
    encounterNumber?: string;
    appointmentStatusId?: PatientAppointmentStatus;
    appointmentStatus?: string;
    mrn?: string;

    treatingProviderId: string;
    billingProviderId?: string;
    supervisingProviderId?: string;
    hygienistId?: string;
    registeredDentalAssistantId?: string;

    cancellationReason?: string;
    cancellationReasonId?: string;
    cancellationNote?: string;

    trackerStatusId?: TrackerStatusIds;
    notes?: string;
    procedures?: IAppointmentProcedure[];
    patient?: IPatientAppointmentPatient;
    encounterReason?: string;
    encounterStatus?: EncounterStatus;
    references?: IPatientAppointmentReferences;

    lastVerifiedMailingAddress?: string;
    lastVerifiedResidentialAddress?: string;
    lastVerifiedPrimaryPhoneNumber?: string;
    lastVerifiedHomelessStatus?: string;
    lastVerifiedHomelessDetail?: string;
    lastVerifiedIncome?: string;
    lastVerifiedInsurance?: string;
}

export interface IAppointmentProcedure {
    procedureId?: string;
    treatmentPlanPhaseProcedureId?: string;
    code?: string;
    toothIds?: number[];
}

export interface IPatientAppointmentPatient {
    id: string;
    isDeleted: boolean;
    mrn?: string;
    lastName?: string;
    firstName?: string;
    middleName?: string;
    dateOfBirth?: string;
    phoneNumber?: string;
    insuranceId?: string;
    references?: { [key: string]: string };
    chosenName?: string;
    genderIdentity?: string;
    sexAtBirthId?: string;
    alerts?: ClinicalAlertType[];
}

export type TrackerStatusIds =
    | 'arrived'
    | 'checkIn'
    | 'ready'
    | 'intake'
    | 'exam'
    | 'xray'
    | 'prophy'
    | 'chair'
    | 'treatment'
    | 'pending'
    | 'readyForCheckout'
    | 'readyForAttestation'
    | 'dismissed'
    | 'completed'
    | 'attested'
    | 'amendRequired'
    | 'canceled';

export default IPatientAppointment;

export interface IPatientAppointmentQuery {
    providerId?: string;
    patientId?: string;
    operatoryId?: string;
    trackerStatusId?: string;
    date?: string;
    currentAndFutureDatesOnly?: boolean;
    pastDatesOnly?: boolean;
    includeAlerts?: boolean;
}

type UpcomingAppointmentSupports = SoftDeleteSupport;

export interface IUpcomingAppointment extends UpcomingAppointmentSupports {
    id?: string;
    patientId?: string;
    procedureCodes?: string;
    procedures?: IAppointmentProcedures[];
    preAuthStatus?: string;
    provider?: string;
    date?: string;
    startTime?: string;
    endTime?: string;
    encounterId?: string;
    appointmentStatusId?: PatientAppointmentStatus;
    modifiedBy?: string;
    modifiedOn: string;
    cancellationReason?: string;
    cancellationNote?: string;
    cancellationReasonId?: string;
    cancellationUserId?: string;
    encounterReason?: string;
    locationOfCareId?: string;
    locationOfCareName?: string;
}

export interface IAppointmentProcedures {
    procedureId?: string;
    treatmentPlanPhaseProcedureId?: string;
    code?: string;
    toothIds?: string[];
}

export interface IPatientAppointmentReferences {
    encounterReason?: string;
}

export enum PatientAppointmentStatus {
    Unconfirmed = 'unconfirmed',
    ConfirmedTxt = 'confirmedTxt',
    ConfirmedPhone = 'confirmedPhone',
    LeftMsg = 'leftMsg',
    LeftVoicemail = 'leftVoicemail',
    Disconnected = 'disconnected',
    WrongNumber = 'wrongNumber',
    NoAnswer = 'noAnswer',
}

export const patientAppointmentStatusText: Dictionary<string> = {
    [PatientAppointmentStatus.Unconfirmed]: 'Unconfirmed',
    [PatientAppointmentStatus.ConfirmedTxt]: 'Confirmed Txt',
    [PatientAppointmentStatus.ConfirmedPhone]: 'Confirmed Phone',
    [PatientAppointmentStatus.LeftMsg]: 'Left Msg',
    [PatientAppointmentStatus.LeftVoicemail]: 'Left Voicemail',
    [PatientAppointmentStatus.Disconnected]: 'Disconnected',
    [PatientAppointmentStatus.WrongNumber]: 'Wrong Number',
    [PatientAppointmentStatus.NoAnswer]: 'No Answer',
};
