import { combineReducers, createSlice } from '@reduxjs/toolkit';
import { adminHuddleExtraReducers, adminHuddleReducers } from './admin-huddle.reducers';
import { initialState } from './admin-huddle.state';
import { default as workLists } from './worklists/worklist.slice';
import { default as predeterminations } from './worklists/predeterminations/predetermination.slice';

const generalAdminHuddleSlice = createSlice({
    name: 'admin-huddle',
    initialState,
    reducers: {
        ...adminHuddleReducers,
    },
    extraReducers: (builder) => {
        adminHuddleExtraReducers(builder);
    },
});

const { reducer: general, actions } = generalAdminHuddleSlice;
export const {
    setEncounterPatient,
    setAmendmentId,
    setAmendmentNote,
    setAmendmentType,
    setAmendmentTreatingProvider,
    cleanupAmendment,
    cleanupWorkListsMenu,
    setVisitSummaryPanelOpen,
    cleanupVisitSummaryMessageBar,
} = actions;

const adminHuddleReducer = combineReducers({
    general,
    workLists,
    predeterminations,
});

export default adminHuddleReducer;
