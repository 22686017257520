/**
 * The enum used for increase or decrease font size
 * Used by setFontSize API
 */
export enum CompatibleFontSizeChange {

    /**
     * Increase font size
     */
    Increase,

    /**
     * Decrease font size
     */
    Decrease,
}
