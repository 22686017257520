import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { IChartAction } from '../chartActionsList.pipeline';
import { fetchChart, updateChart } from './chart.actions';
import { ChartingState } from './chart.slice';

export const chartActionReducers = {
    selectChartActions: (state: ChartingState, action: PayloadAction<IChartAction[]>): void => {
        const chartActions = action.payload;
        state.selectedChartActions = chartActions;
    },
    setModal: (state: ChartingState, action: PayloadAction<boolean>): void => {
        state.modalOpen = action.payload;
    },
    toggleAttestModal: (state: ChartingState): void => {
        state.attestModalOpen = !state.attestModalOpen;
    },
};

export const chartActionsExtraReducers = (
    builder: ActionReducerMapBuilder<ChartingState>,
): ActionReducerMapBuilder<ChartingState> =>
    builder
        .addCase(fetchChart.pending, (state) => {
            state.loading = LoadingStatus.Pending;
        })
        .addCase(fetchChart.fulfilled, (state, action) => {
            state.loading = LoadingStatus.Completed;
            state.chart = action.payload.data;
        })
        .addCase(fetchChart.rejected, (state) => {
            state.loading = LoadingStatus.Failed;
        })
        .addCase(updateChart.pending, (state) => {
            state.saving = LoadingStatus.Pending;
        })
        .addCase(updateChart.fulfilled, (state, action) => {
            state.saving = LoadingStatus.Completed;
            state.chart = action.payload.data;
        })
        .addCase(updateChart.rejected, (state, action) => {
            state.saving = LoadingStatus.Failed;
            state.error = action.payload?.message ? action.payload.message : action.payload?.code;
        })
        // Clear teeth when a new patient is fetched
        .addCase('fetchPatient/fulfilled', (state) => {
            state.ui.selectedTeeth = [];
            state.ui.isSectionChooserExpanded = false;
        });
