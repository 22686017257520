import { Dictionary } from '@reduxjs/toolkit';
import IAppointmentCancellationReason from 'api/models/appointment-cancellation-reason.model';
import IPatientAppointment from 'api/models/Scheduling/patientAppointment.model';
import { LoadingStatus, LoadingStatuses } from 'interfaces/loading-statuses';
import { SyncStatus, SyncStatuses } from 'interfaces/syncing-statuses';

type AppointmentToCancelState = {
    data?: IPatientAppointment;
    appointmentToRemoveId?: string;
    isOpen?: boolean;
    reasonsForCancellation?: Dictionary<IAppointmentCancellationReason>;
    loadingCancelationReasons: LoadingStatuses;
    loading: LoadingStatuses;
    saving: SyncStatuses;
};

export const appointmentToCancelInitialState: AppointmentToCancelState = {
    loading: LoadingStatus.Idle,
    loadingCancelationReasons: LoadingStatus.Idle,
    saving: SyncStatus.Idle,
};

export default AppointmentToCancelState;
