import { IChartProcedure } from 'api/models/chart.model';
import { uniq, flatten, forEach, some, sortBy } from 'lodash';
import { Arch, archReferencesLookup } from 'pages/Charting/components/ToothCanvas/spriteList';
import ProcedureCodes from '../../procedureCodes';
import { createRule } from '../../ruleGenerator';
import ProcedureBusinessRulesPipeline from '../procedureBusinessRules.pipeline';
import { v4 as uuid } from 'uuid';

export function getArchOfTooth(toothNumber: number): Arch | undefined {
    if (archReferencesLookup[Arch.UA].find((ref) => ref.id === toothNumber)) return Arch.UA;
    if (archReferencesLookup[Arch.LA].find((ref) => ref.id === toothNumber)) return Arch.LA;
}
//This rule should only handle the splitting of the procedure based on selected teeth and arch
const partiallyGroupedTeethPerArchRule = createRule<ProcedureBusinessRulesPipeline, IChartProcedure>({
    ruleTypes: [ProcedureCodes.D5421, ProcedureCodes.D5422],
    rule: (pipeline, item) => {
        if (item) {
            const newProcedures: IChartProcedure[] = [];
            const appliedTeeth = uniq(flatten(item.toothIds?.filter((id) => id !== undefined)) as number[]);
            const teethPerArch: { [key: string]: number[] } = {};

            //Organize toothids from the given chart procedure into their respective arches
            forEach(appliedTeeth, (tooth) => {
                const arch = getArchOfTooth(tooth);
                if (arch)
                    teethPerArch[arch] = teethPerArch[arch]?.length
                        ? (teethPerArch[arch] = [...teethPerArch[arch], tooth])
                        : [tooth];
            });

            //Loop through arches, max of O(2) complexity O(1) if only teeth in one arch are selected.
            forEach(teethPerArch, (teeth, key) => {
                const arch: Arch = key as Arch;

                //We need all the teeth to be in order by position. Might as well make an object with the position already on it.
                const teethWithPosition = sortBy(
                    teeth.map((tooth) => ({
                        toothId: tooth,
                        position: pipeline.getTeethPosition([tooth])[0] as number,
                    })),
                    (tooth) => tooth.position,
                );

                //At this point we need to discover if teeth are next to each other. If they are group them together.
                const teethGroups: number[][] = [];
                let teethGroupIndex = 0;
                teethWithPosition.forEach((tooth) => {
                    const { toothId, position } = tooth;
                    //List of possible next (adjacent) teeth that could be selected.
                    const nextPossibleTeeth = [...archReferencesLookup[arch].filter((ref) => ref.position === position + 1)];
                    //Returns true if the currently selected teeth for this arch includes the next position
                    const selectedTeethIncludesNextPossibleTooth = some(
                        nextPossibleTeeth,
                        (ref) =>
                            teethWithPosition
                                .map((tooth) => tooth.position)
                                .findIndex((position) => {
                                    return position === ref.position;
                                }) > -1,
                    );
                    //Push tooth id to the current group.
                    if (!teethGroups[teethGroupIndex]) {
                        teethGroups[teethGroupIndex] = [toothId];
                    } else {
                        teethGroups[teethGroupIndex].push(toothId);
                    }
                    //If the next tooth is not adjacent we move on to a new group of teeth.
                    if (!selectedTeethIncludesNextPossibleTooth) teethGroupIndex++;
                });

                let procedureIndex = 0;
                teethGroups.forEach((newTeeth) => {
                    const firstOriginalProcedure = pipeline.originalProcedures[0];
                    const validChartProcedure = pipeline.getValidArchProcedureForArch({ ...item, toothIds: newTeeth });
                    const validProcedure = pipeline.getProcedure(validChartProcedure ?? item);
                    const newChartProcedure: IChartProcedure = {
                        ...item,
                        id: procedureIndex === 0 ? item.id : uuid(),
                        toothIds: newTeeth,
                        procedureId: validProcedure ? validProcedure.id : firstOriginalProcedure.id,
                    };
                    newProcedures.push(newChartProcedure);
                    procedureIndex++;
                });
            });

            return { updatedItems: newProcedures };
        }
        return { updatedItems: item };
    },
});

export default partiallyGroupedTeethPerArchRule;
