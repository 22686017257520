import { PayloadAction, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { format, sub } from 'date-fns';

import IPatient, {
    IPatientUDS,
    IPatientSlidingFee,
    IPatientSignature,
    IPatientAddress,
    IPatientPhoneNumber,
} from 'api/models/patient.model';
import { IValidationError } from 'hooks/useValidation';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { updatePatientAppointment } from '../scheduling/scheduling.slice';
import { getEditPatient, getPatientToEdit, updatePatient, getPovertyPercentage, getAnnualIncome } from './edit-patient.actions';
import { EditPatientState } from './edit-patient.state';
import { IUpcomingAppointment } from 'api/models/Scheduling/patientAppointment.model';
import { IEncounterHistory } from 'api/models/encounter.model';
import ErrorTypes from 'state/errorTypes';
import { finishCheckout, hasFinishCheckoutSucceeded } from '../scheduling/scheduling.actions';

const patientReducers = {
    setEditPatientPanelOpen: (state: EditPatientState, action: PayloadAction<boolean>): void => {
        state.editPatientPanelOpen = action.payload;
    },
    cleanupEditPatientPanel: (state: EditPatientState): void => {
        state.patient = undefined;
        state.appointmentHistory = undefined;
        state.unscheduledTreatmentPlanProcedures = undefined;
        state.slidingFeePlan = undefined;
        state.upcomingAppointments = undefined;
        state.patientHasChange = false;
        state.isNewPatient = false;
        state.error = undefined;
    },
    setEditPatientHasChange(state: EditPatientState, action: PayloadAction<boolean>): void {
        state.patientHasChange = action.payload;
    },
    cleanupEditPatient: (state: EditPatientState): void => {
        state.patient = undefined;
        state.patientHasChange = false;
        state.error = undefined;
    },
    setEditPatient(state: EditPatientState, action: PayloadAction<IPatient | undefined>): void {
        const patient = action.payload;
        state.patient = patient;
    },
    setIsNewPatient(state: EditPatientState, action: PayloadAction<boolean>): void {
        state.isNewPatient = action.payload;
    },
    editPatientSlidingFeePropChange: (
        state: EditPatientState,
        action: PayloadAction<{ index: number; key: keyof IPatientSlidingFee; value?: unknown }>,
    ): void => {
        const { key, value, index } = action.payload;

        if (state.patient && state.patient.slidingFees?.length) {
            state.patient.slidingFees[index] = { ...state.patient.slidingFees[index], [key]: value };
            state.patientHasChange = true;
        }
    },

    removePatientSlidingFee: (state: EditPatientState, action: PayloadAction<number>): void => {
        if (state.patient && state.patient.slidingFees?.length) {
            state.patient.slidingFees = state.patient.slidingFees.filter((_, index) => index !== action.payload);
        }
    },

    editPatientPropChange: (
        state: EditPatientState,
        action: PayloadAction<{ key: keyof IPatient; value: unknown; shouldNotChange?: boolean }>,
    ): void => {
        const { key, value, shouldNotChange } = action.payload;
        if (state.patient) {
            (state.patient[key] as unknown) = value;
            if (!shouldNotChange) state.patientHasChange = true;
            if (key === 'mailingAddressSameAsPhysicalAddress') {
                if (value) {
                    state.patient.mailingAddress = {
                        ...state.patient.mailingAddress,
                        streetAddress1: state.patient.physicalAddress?.streetAddress1,
                        streetAddress2: state.patient.physicalAddress?.streetAddress2,
                        city: state.patient.physicalAddress?.city,
                        state: state.patient.physicalAddress?.state,
                        zip: state.patient.physicalAddress?.zip,
                    };
                } else {
                    state.patient.mailingAddress = {
                        streetAddress1: '',
                        streetAddress2: '',
                        city: '',
                        state: '',
                        zip: '',
                    };
                }
            }
        }
    },
    setPatientPhoneNumbers: (state: EditPatientState, action: PayloadAction<IPatientPhoneNumber[]>): void => {
        if (state.patient) {
            state.patient.phoneNumbers = action.payload;
            state.patientHasChange = true;
        }
    },
    editPatientUDSPropChange: (
        state: EditPatientState,
        action: PayloadAction<{ key: keyof IPatientUDS; value: string | string[] | boolean | number | undefined }>,
    ): void => {
        const { key, value } = action.payload;
        if (state.patient) {
            ((state.patient['uds'] as IPatientUDS)[key] as string | string[] | boolean | number | undefined) = value;
            state.patientHasChange = true;
        }
    },
    editPatientSignaturePropChange: (
        state: EditPatientState,
        action: PayloadAction<{ key: keyof IPatientSignature; value: string | string[] | boolean | number }>,
    ): void => {
        const { key, value } = action.payload;

        if (state.patient && state.patient.signature) {
            (state.patient['signature'][key] as string | string[] | boolean | number) = value;
            state.patientHasChange = true;
            switch (key) {
                case 'privacyNoticeSigned':
                    if (value) {
                        // set todays date

                        state.patient.signature.privacyNoticeSignedDate = format(new Date(), 'MM/dd/yyyy, h:mm');
                    } else {
                        // Remove tdoday's date
                        state.patient.signature.privacyNoticeSignedDate = '';
                    }
                    break;
                case 'billingReleaseSigned':
                    if (value) {
                        // set todays date
                        const minimumDate = new Date();
                        minimumDate.setFullYear(minimumDate.getFullYear() + 1);
                        const expirationDate = sub(minimumDate, { days: 1 });
                        state.patient.signature.billingReleaseSignedDate = format(new Date(), 'MM/dd/yyyy, h:mm');
                        state.patient.signature.billingReleaseExpirationDate = format(
                            new Date(expirationDate),
                            'MM/dd/yyyy , h:mm',
                        );
                    } else {
                        // Remove tdoday's date
                        state.patient.signature.billingReleaseSignedDate = '';
                        state.patient.signature.billingReleaseExpirationDate = '';
                    }

                    break;
                case 'benefitAssignmentSigned':
                    if (value) {
                        // set todays date
                        const minimumDate = new Date();
                        minimumDate.setFullYear(minimumDate.getFullYear() + 1);
                        const expirationDate = sub(minimumDate, { days: 1 });
                        state.patient.signature.benefitAssignmentSignedDate = format(new Date(), 'MM/dd/yyyy, h:mm');
                        state.patient.signature.benefitAssignmentExpirationDate = format(
                            new Date(expirationDate),
                            'MM/dd/yyyy, h:mm',
                        );
                    } else {
                        // Remove tdoday's date
                        state.patient.signature.benefitAssignmentSignedDate = '';
                        state.patient.signature.benefitAssignmentExpirationDate = '';
                    }

                    break;
            }
        }
    },
    editPatientAddressPropChange: (
        state: EditPatientState,
        action: PayloadAction<{ key: keyof IPatientAddress; value: string | undefined }>,
    ): void => {
        const { key, value } = action.payload;
        if (state.patient && state.patient.physicalAddress) {
            state.patient['physicalAddress'][key] = value;
            state.patientHasChange = true;
        }
    },
    editPatientMailingAddressPropChange: (
        state: EditPatientState,
        action: PayloadAction<{ key: keyof IPatientAddress; value: string | undefined }>,
    ): void => {
        const { key, value } = action.payload;
        if (state.patient && state.patient.mailingAddress) {
            (state.patient['mailingAddress'][key] as string | undefined) = value;
            state.patientHasChange = true;
        }
    },
    setEditPatientValidationErrors(state: EditPatientState, action: PayloadAction<IValidationError[]>): void {
        state.validationErrors = action.payload;
    },
};

export default patientReducers;

export const editPatientExtraReducers = (
    builder: ActionReducerMapBuilder<EditPatientState>,
): ActionReducerMapBuilder<EditPatientState> =>
    builder
        .addCase(getEditPatient.pending, (state) => {
            state.loading = LoadingStatus.Pending;
        })
        .addCase(getEditPatient.fulfilled, (state, action) => {
            state.patient = action.payload;
            state.loading = LoadingStatus.Completed;
        })
        .addCase(getEditPatient.rejected, (state) => {
            state.loading = LoadingStatus.Failed;
        })

        .addCase(getPatientToEdit.pending, (state) => {
            state.loading = LoadingStatus.Pending;
        })
        .addCase(getPatientToEdit.fulfilled, (state, action) => {
            const { patient, appointmentHistory, unscheduledTreatmentPlanProcedures, upcomingAppointments } = action.payload;
            state.loading = LoadingStatus.Completed;

            state.patient = patient;
            state.appointmentHistory = appointmentHistory;
            state.unscheduledTreatmentPlanProcedures = unscheduledTreatmentPlanProcedures;
            state.upcomingAppointments = upcomingAppointments;
            state.error = undefined;
            state.patientHasChange = false;
        })
        .addCase(getPatientToEdit.rejected, (state) => {
            state.loading = LoadingStatus.Failed;
        })
        .addCase(updatePatient.pending, (state) => {
            state.loading = LoadingStatus.Pending;
        })
        .addCase(updatePatient.fulfilled, (state) => {
            state.loading = LoadingStatus.Completed;
            state.isNewPatient = false;
            state.editPatientPanelOpen = false;
            state.error = undefined;
        })
        .addCase(updatePatient.rejected, (state, { payload }) => {
            state.loading = LoadingStatus.Failed;
            state.error = payload as ErrorTypes;
        })
        .addCase(finishCheckout.fulfilled, (state, { payload }) => {
            const { patient } = payload;
            //If failed to checkout then we need to update the selected edit patient.
            if (patient && !hasFinishCheckoutSucceeded(payload)) state.patient = patient;
        })
        .addCase(getPovertyPercentage.pending, (state) => {
            state.loadingEditPatientPovertyPercentage = LoadingStatus.Pending;
        })
        .addCase(getPovertyPercentage.fulfilled, (state, action) => {
            state.loadingEditPatientPovertyPercentage = LoadingStatus.Completed;
            const incomePercentage = action.payload;
            if (state.patient?.uds) {
                state.patient.uds.incomePercentage = incomePercentage;
            }
        })
        .addCase(getPovertyPercentage.rejected, (state) => {
            state.loadingEditPatientPovertyPercentage = LoadingStatus.Failed;
        })
        .addCase(getAnnualIncome.pending, (state) => {
            state.loadingAnnualIncome = LoadingStatus.Pending;
        })
        .addCase(getAnnualIncome.fulfilled, (state, action) => {
            state.loadingAnnualIncome = LoadingStatus.Completed;
            const annualIncome = action.payload;
            if (state.patient?.uds) {
                state.patient.uds.annualIncome = annualIncome;
            }
        })
        .addCase(getAnnualIncome.rejected, (state) => {
            state.loadingAnnualIncome = LoadingStatus.Failed;
        })

        .addCase(updatePatientAppointment.fulfilled, (state, action) => {
            const patientAppointment = action.payload;
            function mapCancelledAppointmentData(
                data: IUpcomingAppointment | IEncounterHistory,
            ): IUpcomingAppointment | IEncounterHistory {
                return {
                    ...data,
                    cancellationReason: patientAppointment.cancellationReason,
                    cancellationReasonId: patientAppointment.cancellationReasonId,
                    cancellationNote: patientAppointment.cancellationNote,
                    isDeleted: true,
                };
            }

            if (patientAppointment.isDeleted) {
                if (state.upcomingAppointments?.length) {
                    const indexOfUpcomingAppointment = state.upcomingAppointments.findIndex(
                        (appt) => appt.id === patientAppointment.id,
                    );
                    if (indexOfUpcomingAppointment > -1)
                        state.upcomingAppointments[indexOfUpcomingAppointment] = mapCancelledAppointmentData(
                            state.upcomingAppointments[indexOfUpcomingAppointment],
                        );
                }
                if (state.appointmentHistory?.length) {
                    const indexOfEncounterHistory = state.appointmentHistory.findIndex(
                        (history) => history.appointmentId === patientAppointment.id,
                    );
                    if (indexOfEncounterHistory > -1)
                        state.appointmentHistory[indexOfEncounterHistory] = mapCancelledAppointmentData(
                            state.appointmentHistory[indexOfEncounterHistory],
                        );
                }
            }
        });
