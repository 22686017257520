import useSelector from 'hooks/useSelector';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
    selectSelectedPage,
    selectSettingsExpanded,
    setSelectedPage,
    setSettingsExpanded,
} from 'state/slices/settings/settings.slice';

function useSettings() {
    const dispatch = useDispatch();

    const settingsExpanded = useSelector(selectSettingsExpanded);
    const selectedPage = useSelector(selectSelectedPage);

    const _openSettings = useCallback(() => {
        dispatch(setSettingsExpanded(true));
    }, [dispatch]);

    const _closeSettings = useCallback(() => {
        dispatch(setSettingsExpanded(false));
    }, [dispatch]);
    const _toggleSettingsOpen = useCallback(() => {
        dispatch(setSettingsExpanded(!settingsExpanded));
    }, [dispatch, settingsExpanded]);

    const _setSelectedPage = useCallback(
        (page: string) => {
            dispatch(setSelectedPage(page));
        },
        [dispatch],
    );

    return {
        openSettings: _openSettings,
        closeSettings: _closeSettings,
        toggleSettingsOpen: _toggleSettingsOpen,

        setSelectedPage: _setSelectedPage,

        settingsExpanded,
        selectedPage,
    };
}

export default useSettings;
