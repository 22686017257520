import { useEffect } from 'react';
/**
 * useKeyPress
 * @param {string} key - the name of the key to respond to, compared against event.key
 * @param {function} action - the action to perform on key down
 */
export default function useKeydown(key: string, action: (e: KeyboardEvent) => void): void {
    useEffect(() => {
        function onKeyDown(e: KeyboardEvent) {
            if (e.key === key) action(e);
        }
        window.addEventListener('keydown', onKeyDown);
        return () => window.removeEventListener('keydown', onKeyDown);
    }, [action, key]);
}
