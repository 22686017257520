import Tooth, { ChartRenderAction } from './Tooth';
import ToothOverlay from './ToothOverlay';
import * as PIXI from 'pixi.js-legacy';

export default class ClassV extends ToothOverlay {
    private graphics: PIXI.Graphics = new PIXI.Graphics();

    constructor(tooth: Tooth, action?: ChartRenderAction) {
        super(tooth, tooth._frontContainer);
        if (action) this._setColor(action);
        this._createGraphics(action);
        this.zIndex = 5;
    }

    private _createGraphics(p?: ChartRenderAction) {
        const { flipClassV, classVYOffset, scaleFactor } = this.tooth.toothReference;
        this.graphics.lineStyle(12, p ? this.color : 0x000000);
        const radius = 30;
        const upwardArc = { startAngle: Math.PI + Math.PI / 8, endAngle: -Math.PI / 8 };
        const downwardArc = { startAngle: Math.PI / 8, endAngle: Math.PI - Math.PI / 8 };
        const scaledRadius = radius * (scaleFactor ? scaleFactor : 1);

        const crownSprite = this.tooth._frontContainer?.getChildByName('Crown');
        const crownHeight: number = (crownSprite as any)?._height ?? 0;

        if (this.getIsBottomRow) {
            this.graphics.arc(
                this.x + (this.tooth.getToothWidth * this.scale.x) / 2,
                this.y + crownHeight / 3 + (flipClassV ? scaledRadius : -scaledRadius) / 2 + (classVYOffset ? classVYOffset : 0),
                radius,
                !flipClassV ? upwardArc.startAngle : downwardArc.startAngle,
                !flipClassV ? upwardArc.endAngle : downwardArc.endAngle,
            );
        } else {
            this.graphics.arc(
                this.x + (this.tooth.getToothWidth * this.scale.x) / 2,
                this.y +
                    crownHeight -
                    crownHeight / 3 +
                    (!flipClassV ? scaledRadius : -scaledRadius) / 2 +
                    (classVYOffset ? classVYOffset : 0),
                radius,
                !flipClassV ? downwardArc.startAngle : upwardArc.startAngle,
                !flipClassV ? downwardArc.endAngle : upwardArc.endAngle,
            );
        }

        this.addChild(this.graphics);
    }
}
