import { PayloadAction } from '@reduxjs/toolkit';
import { IReportingState } from '../reporting.state';
import { IDepositSlipDetailQuery, IDepositSlipSummaryQuery } from 'api/models/deposit-slip.model';

export const depositSlipReducers = {
    setDepositSlipDetailProp: (
        state: IReportingState,
        action: PayloadAction<{ path: keyof IDepositSlipDetailQuery; value: string | number | undefined }>,
    ): void => {
        const { path, value } = action.payload;
        ((state.reportProperties as IDepositSlipDetailQuery)[path] as string | number | undefined) = value;
    },
    setDepositSlipSummaryProp: (
        state: IReportingState,
        action: PayloadAction<{ path: keyof IDepositSlipSummaryQuery; value: string | number | undefined }>,
    ): void => {
        const { path, value } = action.payload;
        ((state.reportProperties as IDepositSlipSummaryQuery)[path] as string | number | undefined) = value;
    },
    toggleDepositSlipDetailListId: (
        state: IReportingState,
        action: PayloadAction<{ path: keyof IDepositSlipDetailQuery; id: string }>,
    ): void => {
        const { id, path } = action.payload;
        if (!(state.reportProperties as IDepositSlipDetailQuery)[path])
            ((state.reportProperties as IDepositSlipDetailQuery)[path] as unknown[]) = [];

        const currentIds = (state.reportProperties as IDepositSlipDetailQuery)[path] as string[];

        if (currentIds)
            if (currentIds?.includes(id)) {
                ((state.reportProperties as IDepositSlipDetailQuery)[path] as string[]) = currentIds.filter((_id) => _id !== id);
            } else {
                ((state.reportProperties as IDepositSlipDetailQuery)[path] as string[]) = [...currentIds, id];
            }
    },
};
