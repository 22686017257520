import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { TooltipCards } from 'pages/components';
import { Switch, RouteProps, Redirect, RedirectProps } from 'react-router-dom';
import { AppLoader, NavBar, TModal } from 'components';
import './App.scss';
import routes, { redirects } from 'routes';
import { RouteWrapper } from 'routes/RouteWrapper';
import { Helmet } from 'react-helmet';
import Settings from './Settings/Settings';
import FormPanel from './components/FormPanel/FormPanel';
import PrescriptionsPanel from './components/PrescriptionsPanel/PrescriptionsPanel';
import PerioPanel from './Perio/components/PerioPanel';
import BentoBox from './BentoBox/BentoBox';
import GlobalBatchPanel from './components/Batch/GlobalBatchPanel';
import ImagingSourcePanel from './components/ImagingSource/ImagingSourcePanel';
import { useIdleTimer } from 'react-idle-timer';
import msalInstance from 'api/auth/authProvider';
import { Stack, Text } from '@fluentui/react';
import GlobalEditPatientPanel from 'components/GlobalEditPatientPanel';
import GlobalCancelAppointmentModal from './components/CancelAppointmentModal/GlobalCancelAppointmentModal';
import EncounterAccessPermissionHandler from './components/EncounterAccessPermissionHandler';
import isDev from 'utils/isDev';
import { useAccount } from '@azure/msal-react';
import { fetchDomainHint } from 'utils/domainHint';

const environment = '#{EnvironmentVar}#' as string;

function App(): JSX.Element {
    const routeList = routes.map((route: RouteProps, index: number) => <RouteWrapper key={index} {...route} />);
    const redirectList = redirects.map((route: RedirectProps, index: number) => <Redirect key={index} {...route} />);

    return (
        <AppLoader>
            <TooltipProvider>
                <Helmet defaultTitle="athenaOne Dental" titleTemplate="%s - athenaOne Dental" />
                {environment !== 'development' ? <AutoLogout /> : null}
                <NavBar preAppName={<BentoBox />} appName="Dental" />
                <EncounterAccessPermissionHandler />
                <Switch>
                    {redirectList}
                    {routeList}
                </Switch>
                <Settings />
                <GlobalCancelAppointmentModal />
                <FormPanel />
                <PrescriptionsPanel />
                <PerioPanel />
                <GlobalEditPatientPanel />
                <GlobalBatchPanel />
                <ImagingSourcePanel />
            </TooltipProvider>
        </AppLoader>
    );
}

export default App;

function TooltipProvider(props: PropsWithChildren<any>) {
    const tooltipRef = useRef(null);
    return (
        <React.Fragment>
            {props.children}
            <div style={{ position: 'absolute', right: 15, bottom: 3 }} ref={tooltipRef} />
            {tooltipRef.current ? <TooltipCards domNode={tooltipRef.current} /> : null}
        </React.Fragment>
    );
}

function AutoLogout() {
    const account = useAccount();
    const [warning, setWarning] = useState<boolean>(false);
    const [remaining, setRemaining] = useState<number>(0);

    const onIdle = () => {
        msalInstance.logoutRedirect({
            account: account,
        });
    };

    // Default timeout of 120 mins
    const timeout = 1000 * 60 * 120;

    // Prompt user 1 min before timeout
    const promptBeforeIdle = 1000 * 60;

    const { getRemainingTime, reset, message } = useIdleTimer({
        onIdle,
        promptBeforeIdle,
        onPrompt: () => setWarning(true),
        onMessage: (data) => {
            if (data === 'resetModal') {
                setWarning(false);
            }
        },
        timeout,
        throttle: 500,
        crossTab: true,
        syncTimers: 200,
        disabled: isDev, // turn off if in dev mode
    });

    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000));
        }, 500);

        return () => {
            clearInterval(interval);
        };
    });

    const _onPromptActive = () => {
        setWarning(false);
        message('resetModal');
        reset();
    };

    const _onPromptLogout = () => {
        msalInstance.logoutRedirect();
    };

    return (
        <>
            <TModal
                hideClose
                title="You have been inactive for a while"
                modalProps={{ isOpen: warning, isBlocking: true }}
                mainButtons={[
                    {
                        text: 'Keep me logged in',
                        primary: true,
                        onClick: _onPromptActive,
                    },
                    {
                        text: 'Log me out',
                        onClick: _onPromptLogout,
                    },
                ]}
            >
                <Stack style={{ padding: 10 }} tokens={{ childrenGap: 10 }} horizontalAlign="center">
                    <Text>
                        For security reasons, your session is about to expire. <br />
                        To continue your session please confirm that you are still here.
                    </Text>
                    <Text variant="large">
                        You will be automatically logged out in: <strong>{remaining}</strong>
                    </Text>
                </Stack>
            </TModal>
        </>
    );
}
