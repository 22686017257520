import { IChartProcedure } from 'api/models/chart.model';
import { ConflictRuleType } from 'api/models/lookup.model';
import { IPipelineError, PipelineError } from '../pipeline';
import ProcedureConflictRulesPipeline from '../procedureConflictRules.pipeline';
import { createRule } from '../ruleGenerator';

const ProcedureOnePerQuad = createRule<ProcedureConflictRulesPipeline, IChartProcedure>({
    ruleTypes: [ConflictRuleType.onePerQuad],
    rule: (pipeline: ProcedureConflictRulesPipeline, item) => {
        const newProcedure = { ...item };
        const _errors: IPipelineError[] = [];
        const sameProcedures = pipeline.getSameProcedures(item);
        const otherProcedureQuads: ('ul' | 'ur' | 'lr' | 'll')[] = [];
        const currentProcQuads = item.toothIds ? pipeline.getTeethQuads(item.toothIds) : [];

        if (sameProcedures?.length) {
            sameProcedures.forEach((p) => {
                if (p?.toothIds) {
                    const procedureQuads = pipeline.getTeethQuads(p.toothIds);
                    procedureQuads.forEach((q) => {
                        if (otherProcedureQuads.indexOf(q) > -1) otherProcedureQuads.push(q);
                    });
                }
            });
        }

        const matchingQuads = currentProcQuads.filter((q) => otherProcedureQuads.indexOf(q) > -1);

        if (matchingQuads.length) {
            _errors.push({ type: PipelineError.OnePerQuad, data: pipeline.getProcedure(item) });
        }
        return { shouldRemoveItem: matchingQuads.length ? true : false, errors: _errors, updatedItem: newProcedure };
    },
});

export default ProcedureOnePerQuad;
