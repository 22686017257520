import { ToothArea } from 'api/models/tooth-area';
import { toothSpriteReferences } from 'pages/Charting/components/ToothCanvas/spriteList';
import { createSelector } from 'reselect';
import { conditionsAsList } from 'state/slices/tenant/conditions.slice';
import { RootState } from 'state/store';
import ConditionCodes from '../conditionCodes';
import { chartConditionsAsList } from '../conditions/conditions.selectors';
import { chartDentitionsData } from '../dentition/dentition.selectors';
import { IChartingPipelineErrorData } from './ui.state';

export const selectedTeethSelector = (state: RootState): number[] => state.charting.ui.selectedTeeth;
export const selectedAreasSelector = (state: RootState): (keyof typeof ToothArea)[] => state.charting.ui.selectedAreas;
export const assetsLoadedSelector = (state: RootState): boolean => state.charting.ui.assetsLoaded;
export const isProcedureBrowserOpen = (state: RootState): boolean => state.charting.ui.isProcedureBrowserOpen;
export const isSectionChooserExpanded = (state: RootState): boolean => state.charting.ui.isSectionChooserExpanded;

export const selectChartPipelineErrorData = (state: RootState): IChartingPipelineErrorData | undefined =>
    state.charting.ui.chartingPipelineErrorData;

export const selectFirstToothPosteriorAnterior = createSelector(
    selectedTeethSelector,
    (selectedTeeth): 'Anterior' | 'Posterior' | undefined => {
        const firstTooth = selectedTeeth[0];
        if (firstTooth) {
            const toothPosition = toothSpriteReferences.find((tooth) => tooth.id === firstTooth)?.position;
            if (toothPosition && ((toothPosition >= 6 && toothPosition <= 11) || (toothPosition >= 22 && toothPosition <= 27))) {
                return 'Anterior';
            } else {
                return 'Posterior';
            }
        }
    },
);

export const selectFirstToothDentition = createSelector(
    selectedTeethSelector,
    chartDentitionsData,
    conditionsAsList,
    chartConditionsAsList,
    (selectedTeeth, dentitions, conditions, chartConditions) => {
        const firstTooth = selectedTeeth[0];

        const missingConditionId = conditions.find((p) => p.code === ConditionCodes.Missing)?.id || '';
        const toothIsMissing = chartConditions
            .filter((condition) => condition.conditionId === missingConditionId)
            .find((condition) => condition.toothId === firstTooth);

        const toothReference = toothSpriteReferences.find((tooth) => tooth.id === firstTooth);
        const toothDentition = dentitions.find((dentition) => dentition.id === `${toothReference?.id}`);
        if (toothIsMissing && toothReference && toothReference.id <= 32) {
            return 'Missing';
        } else {
            if (toothDentition) {
                return toothDentition.dentitionMode;
            }

            if (toothReference) {
                return toothReference?.id > 32 ? 'Primary' : 'Permanent';
            }
        }
    },
);
