import {
    ConstrainMode,
    DetailsRow,
    IObjectWithKey,
    ISelection,
    MessageBar,
    MessageBarType,
    ScrollablePane,
    Selection,
    SelectionMode,
    Stack,
    useTheme,
} from '@fluentui/react';
import { IAdjustmentCommonTransactionView } from 'api/models/adjustment-history.model';
import { SortableDetailsList } from 'components';
import { useSelector } from 'hooks';
import useUserIdentities from 'hooks/store/useUserIdentities';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
    selectAdjustmentHistoryAdjustmentUsers,
    selectAdjustmentHistoryCommonTransactionViews,
    selectAdjustmentHistoryError,
    selectAdjustmentHistoryFiltersExist,
    selectAdjustmentHistoryLoadingStatus,
    selectAdjustmentHistoryReversedTransactions,
    selectAdjustmentHistorySelectedViews,
    selectFilteredAdjustmentHistoryCommonTransactionViews,
} from 'state/slices/ledger/adjustment-history/adjustment-history.selectors';
import { setSelectedAdjustmentHistoryViews } from 'state/slices/ledger/ledger.slice';
import { selectAdjustmentReasonsData } from 'state/slices/tenant/adjustment-reasons.slice';
import { usdCurrencyFormatter } from 'utils';
import { classicDateOnly } from 'utils/dateOnly';
import { ChartProcedureLabel } from '../components/RenderProcedureSummaryTooltip';

const _renderAmount = (item?: IAdjustmentCommonTransactionView) => <span>{usdCurrencyFormatter.format(item?.amount ?? 0)}</span>;

export default function AdjustmentHistoryList() {
    const dispatch = useDispatch();
    const { palette } = useTheme();

    const { getMissingUserIdentities, userIdentitiesData } = useUserIdentities();

    const views = useSelector(selectAdjustmentHistoryCommonTransactionViews);
    const filteredViews = useSelector(selectFilteredAdjustmentHistoryCommonTransactionViews);
    const selectedViews = useSelector(selectAdjustmentHistorySelectedViews);

    const reversedTransactions = useSelector(selectAdjustmentHistoryReversedTransactions);

    const error = useSelector(selectAdjustmentHistoryError);
    const adjustmentReasonLookup = useSelector(selectAdjustmentReasonsData);
    const transactionUserIds = useSelector(selectAdjustmentHistoryAdjustmentUsers);
    const loadingStatus = useSelector(selectAdjustmentHistoryLoadingStatus);
    const filtersExist = useSelector(selectAdjustmentHistoryFiltersExist);

    useEffect(() => {
        if (transactionUserIds.length) getMissingUserIdentities(transactionUserIds);
    }, [transactionUserIds]);

    const adjustmentSourceSelection = useMemo(() => {
        return new Selection({
            onSelectionChanged: () => {
                const selectedViews = _getPhaseSelectionDetails();
                dispatch(setSelectedAdjustmentHistoryViews(selectedViews));
            },
            getKey: (item) => item.id,
            items: views,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        adjustmentSourceSelection.canSelectItem = canSelectItem;
    }, [reversedTransactions]);

    function _getPhaseSelectionDetails() {
        return adjustmentSourceSelection.getSelection() as IAdjustmentCommonTransactionView[];
    }

    function canSelectItem(item: IAdjustmentCommonTransactionView) {
        return !item.references?.reversedTransactionId && !reversedTransactions[item.id];
    }

    const _renderEncounterDate = (item?: IAdjustmentCommonTransactionView) => (
        <Stack>
            {item?.references?.reversedTransactionId && <b>Reversal</b>}
            {reversedTransactions[item?.id ?? ''] && <b>Reversed</b>}
            <span>{item?.encounterDate ?? ''}</span>
        </Stack>
    );

    useEffect(() => {
        if (!selectedViews.length) {
            adjustmentSourceSelection.setAllSelected(false);
        }
    }, [selectedViews.length, adjustmentSourceSelection]);

    const loading = loadingStatus === LoadingStatus.Pending;

    if (loadingStatus === LoadingStatus.Idle) return null;
    if (!loading && error)
        return <MessageBar messageBarType={MessageBarType.error}>Something went wrong while fetching data.</MessageBar>;
    if (!loading && !views.length) return <MessageBar>No adjustments found.</MessageBar>;
    if (!loading && !filteredViews.length && views.length && filtersExist)
        return <MessageBar>There are no adjustments that match the filter criteria.</MessageBar>;

    return (
        <div style={{ position: 'relative', flex: 1, display: 'flex' }}>
            <ScrollablePane>
                <SortableDetailsList<IAdjustmentCommonTransactionView>
                    selectionMode={SelectionMode.multiple}
                    stickyHeader
                    items={filteredViews}
                    enableShimmer={loading}
                    sortOnMount
                    showGrid
                    compact
                    sortColumns={['encounterDate']}
                    initialSortDirection={['desc']}
                    selection={adjustmentSourceSelection as unknown as ISelection<IObjectWithKey>}
                    constrainMode={ConstrainMode.unconstrained}
                    onRenderRow={(props) => {
                        if (props)
                            return (
                                <DetailsRow
                                    styles={{
                                        root: {
                                            backgroundColor:
                                                reversedTransactions[props.item.id] ||
                                                    props.item.references?.reversedTransactionId
                                                    ? palette.neutralLighterAlt
                                                    : undefined,
                                        },
                                    }}
                                    {...props}
                                />
                            );
                        return null;
                    }}
                    columns={[
                        {
                            key: 'encounterNumber',
                            minWidth: 50,
                            maxWidth: 80,
                            fieldName: 'encounterNumber',
                            name: 'Enc. #',
                        },
                        {
                            key: 'encounterDate',
                            minWidth: 100,
                            maxWidth: 120,
                            fieldName: 'encounterDate',
                            name: 'Encounter Date',
                            onRender: _renderEncounterDate,
                        },
                        {
                            name: 'Procedure',
                            key: 'procedure',
                            minWidth: 130,
                            maxWidth: 200,
                            onRender: (item) => {
                                if (item) {
                                    const label = `${item?.procedureCode} - ${item?.procedureDescription}`;
                                    return <ChartProcedureLabel label={label} chartProcedureStatus={item.procedureStatus} />;
                                }
                            },
                        },
                        {
                            name: 'Amount',
                            key: 'amount',
                            minWidth: 100,
                            maxWidth: 135,
                            onRender: _renderAmount,
                        },
                        {
                            name: 'Adjustment Reason',
                            key: 'adjustmentReason',
                            minWidth: 100,
                            maxWidth: 145,
                            onRender: (item) => (
                                <span>
                                    {adjustmentReasonLookup[item?.adjustmentReasonId ?? '']?.displayName ?? 'Unknown Reason'}
                                </span>
                            ),
                        },
                        {
                            name: 'Transaction Date',
                            key: 'transactionDate',
                            minWidth: 100,
                            maxWidth: 120,
                            onRender: (item) => {
                                if (item) {
                                    return <span>{item?.createdOn ? classicDateOnly(item.createdOn) : ''}</span>;
                                }
                            },
                        },
                        {
                            name: 'Batch Date',
                            key: 'batchDate',
                            minWidth: 100,
                            maxWidth: 120,
                            onRender: (item) => {
                                if (item) {
                                    return <span>{item.batchId ? classicDateOnly(item.dateOfEntry) : ''}</span>;
                                }
                            },
                        },
                        {
                            name: 'Batch Name',
                            key: 'batchName',
                            minWidth: 100,
                            maxWidth: 125,
                            fieldName: 'batchName',
                        },
                        {
                            name: 'User Name',
                            key: 'userName',
                            minWidth: 100,
                            maxWidth: 125,
                            onRender: (item) => {
                                if (item) {
                                    return (
                                        <span>
                                            {!item.isSystemGenerated
                                                ? item?.createdBy
                                                    ? userIdentitiesData[item?.createdBy]?.displayName
                                                    : 'Unknown User'
                                                : 'System Generated'}
                                        </span>
                                    );
                                }
                            },
                        },
                    ]}
                />
            </ScrollablePane>
        </div>
    );
}
