import { EncounterWorkList } from 'state/slices/admin-huddle/worklists/encounter-worklists/encounter-worklists.state';
import EncounterWorkListComponent from './EncounterWorkList';
import { MessageBar } from '@fluentui/react';

export default function Denials() {
    return (
        <EncounterWorkListComponent
            upperContent={
                <MessageBar>This worklist contains encounters that have not been reapproved within the last 30 Days.</MessageBar>
            }
            workLists={[EncounterWorkList.Denials]}
        />
    );
}
