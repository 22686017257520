import { DocumentBase } from 'api/document-base.model';
import { TimeTableRange } from './Scheduling/operatory.model';
export interface IProvider extends DocumentBase {
    firstName?: string;
    lastName?: string;
    middleName?: string;

    isTreatingProvider: boolean;
    isBillingProvider: boolean;
    isSupervisingProvider: boolean;
    isRegisteredDentalAssistant: boolean;
    isHygienist: boolean;
    isAttestingHygienist: boolean;
    isResident: boolean;

    providerSchedule?: IProviderSchedule;
    mappedProviderId?: string;
    suffix?: string;
}

export interface IProviderSchedule {
    periods?: IProviderPeriod[];
    scheduleColor?: string;
}

export enum ExceptionType {
    Block = 'block',
    AddHours = 'addHours',
}
export interface IProviderScheduleTimeBlock {
    startTime?: string;
    startDate?: string;
    endDate?: string;
    endTime?: string;
    recurring: boolean;
    locationOfCareId?: string;
    locationOfCareName?: string;
    exceptionType?: string;
    type?: string;
    notes?: string;
}

export enum WeekDays {
    Sunday = 'sunday',
    Monday = 'monday',
    Tuesday = 'tuesday',
    Wednesday = 'wednesday',
    Thursday = 'thursday',
    Friday = 'friday',
    Saturday = 'saturday',
}

// export interface IProviderRange {
//     startTime?: string;
//     endTime?: string;
// }

// export type Timetable = { [key in WeekDays]?: ITimeRange[] };
export type Timetable = Record<WeekDays, TimeTableRange[]>;

export interface IProviderTimetable {
    timeTables?: Timetable;
    locationOfCareId?: string;
    locationOfCareName?: string;
}

export interface IProviderPeriod {
    id?: string;
    startDate?: string;
    endDate?: string;

    exceptions?: IProviderScheduleTimeBlock[];
    schedules?: IProviderTimetable[];
}

export default IProvider;
