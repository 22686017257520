import { CustomThemeProps } from '../ui.slice';

export const lightTheme: CustomThemeProps = {
    palette: {
        themePrimary: '#006aff',
        themeLighterAlt: '#f5f9ff',
        themeLighter: '#d6e7ff',
        themeLight: '#b3d2ff',
        themeTertiary: '#66a6ff',
        themeSecondary: '#1f7cff',
        themeDarkAlt: '#0060e6',
        themeDark: '#0051c2',
        themeDarker: '#003b8f',
        neutralLighterAlt: '#f1f1f1',
        neutralLighter: '#e0e0e0',
        neutralLight: '#d9d9d9',
        neutralQuaternaryAlt: '#d3d3d3',
        neutralQuaternary: '#cacaca',
        neutralTertiaryAlt: '#c2c2c2',
        neutralTertiary: '#b2b2b2',
        neutralSecondary: '#999999',
        neutralPrimaryAlt: '#808080',
        neutralPrimary: '#1c1c1c',
        neutralDark: '#4e4e4e',
        black: '#353535',
        white: '#f7f7f7',
        navbar: '#1d375a',
        navbarLighter: '#28538e',
    },
    eventColors: {
        trackerStatus: {
            arrived: {
                backgroundColor: '#EDEAF3',
                highlight: '#8169A7',
                buttonColor: '#387D8C',
            },
            ready: {
                backgroundColor: '#EEF5ED',
                highlight: '#437a37',
                buttonColor: '#387D8C',
            },
            inTreatment: {
                backgroundColor: '#E6F3F7',
                highlight: '#3B7D8C',
                buttonColor: '#387D8C',
            },
            checkout: {
                backgroundColor: '#EFF1F1',
                highlight: '#68696A',
                buttonColor: '#107C10',
            },
            completed: {
                backgroundColor: '#8D8D8D',
                highlight: '#444',
                buttonColor: '#8D8D8D',
            },
            canceled: {
                backgroundColor: '#FFDBDB',
                highlight: '#7A0A04',
                buttonColor: '#7A0A04',
            },
        },
        trackerEncounterStatus: {
            created: {
                backgroundColor: '#8169A7',
                highlight: '#EDEAF3',
                buttonColor: '#387D8C',
                color: 'black',
            },
            readyForAttestation: {
                backgroundColor: '#437a37',
                highlight: '#EEF5ED',
                buttonColor: '#107C10',
                color: 'white',
            },
            attested: {
                backgroundColor: '#3B7D8C',
                highlight: '#E6F3F7',
                buttonColor: '#8169A7',
                color: 'white',
            },
            amendRequired: {
                backgroundColor: '#3B7D8C',
                highlight: '#E6F3F7',
                buttonColor: '#FFB900',
                color: 'white',
            },
            completed: {
                backgroundColor: '#3B7D8C',
                highlight: '#E6F3F7',
                buttonColor: '#8D8D8D',
                color: 'white',
            },
            conversionImported: {
                backgroundColor: '#3B7D8C',
                highlight: '#E6F3F7',
                buttonColor: '#8D8D8D',
                color: 'white',
            },
            canceled: {
                backgroundColor: '#FFDBDB',
                highlight: '#7A0A04',
                buttonColor: '#7A0A04',
                color: 'white',
            },
        },
    },
};
