import IForm from 'api/models/form';

export interface IDentalHistoryForm extends IForm {
    data: IDentalHistoryQuestions;
}

export enum ChiefComplaint {
    BadBreath = 'Bad Breath',
    BleedingGums = 'Bleeding Gums',
    LooseTeeth = 'Loose Teeth',
    MissingTeeth = 'Missing Teeth',
    ToothPain = 'Tooth Pain',
    ToothSenitivity = 'Tooth Sensitivity',
}

export enum VisitType {
    DentalSealants = 'Dental Sealants',
    Dentures = 'Dentures',
    FillingRestoration = 'Filling/Restoration',
    RootCanal = 'Root Canal',
    RoutineExam = 'Routine Exam',
    TeethCleaning = 'Teeth Cleaning',
    ToothExtraction = 'Tooth Extraction',
    WisdomTeethRemoval = 'Wisdom Teeth Removal',
}

export type YesNoValue = 'Yes' | 'No' | '';

export interface IDentalHistoryQuestions {
    // * Dental Information
    doYouHaveBleedingOrSoreGumsWhenYouBrushOrFloss?: YesNoValue;
    areYourTeethSensitiveToColdHotSweetsOrPressBiting?: YesNoValue;
    doYouHaveFoodImpactionOrFlossCatchBetweenYourTeeth?: YesNoValue;
    doYouHaveDryMouth?: YesNoValue;
    doYouHaveAnUnpleasantTasteOrBadBreath?: YesNoValue;
    doYouHaveBurningTongueOrLips?: YesNoValue;
    doYouHaveFrequentBlistersSoresOrUlcersOnLipsInMouth?: YesNoValue;
    doYouHaveSwellingOrLumpsInYourMouth?: YesNoValue;
    doYouHaveClikcingPoppingOrDiscomfortInTheJaw?: YesNoValue;
    doYouHaveDifficultyOpeningOrClosingYourJaw?: YesNoValue;
    doYouHaveLooseTeeth?: YesNoValue;
    doYouHaveAnyChippedOrBrokenTeeth?: YesNoValue;
    doYouHaveAnyShiftingOfYourTeeth?: YesNoValue;
    doYouHaveAnyChangeInYourBite?: YesNoValue;
    doYouHaveEarachesHeadachesOrNeckPain?: YesNoValue;
    haveYouEverHadOrthodonticTreatment?: YesNoValue;
    areYouCurrentlyExperiencingDentalPainOrDiscomfort?: YesNoValue;
    pleaseDescribeDentalPainOrDisomfort?: string;
    doYouClenchOrGrindYourTeeth?: YesNoValue;
    doYouBiteYourCheeksOrLips?: YesNoValue;
    doYouWearAnyRemovableDentalAppliances?: YesNoValue;
    dateTheyWereMade?: string;
    haveYouEverHadASeriousInjuryToYourHeadOrMouth?: YesNoValue;
    pleaseDescribeSeriousInjuryToYourHeadOrMouth?: string;
    doYouParticipateInActiveRecreationalActivities?: YesNoValue;
    haveYouEverHadAnyProblemsAssociatedWithPreviousDentalTreatment?: YesNoValue;
    haveYouEverHadAnyProblemsWithDentalAnesthetic?: YesNoValue;
    haveYouHadAnyPeriodontalTreatments?: YesNoValue;
    haveYouEverBeenToldYouNeededAntibioticPreMedicationForDentalTreatment?: YesNoValue;
    isYourHomeWaterSupplyFluoridated?: YesNoValue;
    doYouDrinkBottledOrFilteredWater?: YesNoValue;
    ifYesHowOften?: 'Daily' | 'Weekly' | 'Occasionally' | '';
    dateOfLastDentalExam?: string;
    dateOfLastDentalXrays?: string;
    previousDentist?: string;
    whatWasDoneAtThisTime?: string;
    pleaseRateYourComfortLevelWithReceivingDentalTreatment?:
        | 'Completely Comfortable'
        | 'Slightly Comfortable'
        | 'Moderately Comfortable'
        | 'Not Comfortable'
        | '';
    howDoYouFeelAboutYourSmile?: string;
    whatTypeOfTreatmentDoYouFeelYouNeed?: string;

    // * Oral Hygiene
    brush?: YesNoValue;
    brushHowManyTimesPerDay?: string;
    myBrushIs?: 'Soft' | 'Medium' | 'Hard' | '';
    electricBrush?: YesNoValue;
    dentalFloss?: YesNoValue;
    dentalFlossHowManyTimesPerDay?: string;
    fluorideRinse?: YesNoValue;
    fluorideRinseHowManyTimesPerDay?: string;
    other?: boolean;
    explainOther?: string;
    bleaching?: boolean;
    cosmeticDentistry?: boolean;
    dentures?: boolean;
    implants?: boolean;
    endodontics?: boolean;
    specialLearningNeedsIdentified?: boolean;
    otherAdditionalInformation?: boolean;
}
