import { Checkbox, ComboBox, IChoiceGroupOption, IDropdownOption, Stack } from '@fluentui/react';
import IPatient, { IPatientUDS } from 'api/models/patient.model';
import { Field, SubSection, TText } from 'components';
import PatientPhoneNumbers from 'components/PatientDemographics/BasicDetails/components/PatientPhoneNumbers';
import PatientAddress from 'components/PatientDemographics/BasicDetails/components/PatientAddress';
import UserTasksBadge from 'components/UserTasks/UserTasksBadge';
import { useSelector } from 'hooks';
import { useDispatch } from 'react-redux';
import { selectEditPatient, selectEditPatientValidationErrors } from 'state/slices/edit-patient/edit-patient.selectors';
import { editPatientPropChange, editPatientUDSPropChange } from 'state/slices/edit-patient/edit-patient.slice';
import { CheckInSections, updatePatientAppointmentProp } from 'state/slices/scheduling/scheduling.slice';
import { genderIdentitiesOptionsList } from 'state/slices/tenant/gender-identities.slice';
import { legalSexesOptionsList } from 'state/slices/tenant/legal-sexes.slice';
import { sexualOrientationOptionsList } from 'state/slices/tenant/sexualOrientation.slice';
import { TaskType } from 'state/task-management/taskManagement.actions';
import {
    selectPatientAppointmentBasicInfoByTaskTypeLookup,
    selectPatientAppointmentBasicInfoCheckInTasks,
    selectPatientBasicInfoByTaskTypeLookup,
    selectPatientBasicInfoCheckInTasks,
} from 'state/task-management/taskManagement.slice';
import CheckInSection from './CheckInSection';
import TaskFieldLabel from './TaskFieldLabel';
import MailingAddress from 'components/PatientDemographics/BasicDetails/components/MailingAddress';
import { selectSelectedAppointmentData } from 'state/slices/scheduling/scheduling.selectors';
import { classicDateOnly } from 'utils/dateOnly';
import { getValidationError } from 'hooks/useValidation';
import { BasicDetailsFields } from 'components/PatientDemographics/BasicDetails/BasicDetails';
import { racesOptionsList } from 'state/slices/tenant/races.slice';
import { ethnicityOptionsList } from 'state/slices/tenant/ethnicities.slice';
import { format } from 'date-fns';

export enum AgriculturalHomelessTypeFields {
    HomelessType = 'Homeless Type',
    AgriculturalType = 'Agricultural Type',
}

export default function BasicDetailsSection(): JSX.Element | null {
    const dispatch = useDispatch();
    const patient = useSelector(selectEditPatient);
    const patientBasicInfoTasks = useSelector(selectPatientBasicInfoCheckInTasks);
    const patientAppointmentBasicInfoTasks = useSelector(selectPatientAppointmentBasicInfoCheckInTasks);
    const validationErrors = useSelector(selectEditPatientValidationErrors);

    const basicInfoTasks = [...patientBasicInfoTasks, ...patientAppointmentBasicInfoTasks];

    const _legalSexesOptionsList = useSelector(legalSexesOptionsList);

    const legalSexOptions = useSelector(legalSexesOptionsList);
    const sexualOrientationOptions = useSelector(sexualOrientationOptionsList);
    const genderIdentityOptions = useSelector(genderIdentitiesOptionsList);
    const ethnicityOptions = useSelector(ethnicityOptionsList);
    const raceOptions = useSelector(racesOptionsList);

    const _currentAppointment = useSelector(selectSelectedAppointmentData);

    const lookupPatientBasicDetailTaskByType = useSelector(selectPatientBasicInfoByTaskTypeLookup);
    const _lookupPatientAppointmentBasicDetailTaskByType = useSelector(selectPatientAppointmentBasicInfoByTaskTypeLookup);

    if (!basicInfoTasks.length) return null;

    const _updatePatientRootProps = (key: keyof IPatient, value: string | number | boolean | string[]) => {
        dispatch(editPatientPropChange({ key, value }));
    };
    const _updatePatientUDSProps = (key: keyof IPatientUDS, value: string | number | boolean | string[] | undefined) => {
        dispatch(editPatientUDSPropChange({ key, value }));
    };

    const updateDate = new Date().toISOString();

    const pronouns: IDropdownOption[] = [
        {
            key: 'He/him',
            text: 'He/him',
        },

        {
            key: 'She/her',
            text: 'She/her',
        },

        {
            key: 'They/them',
            text: 'They/them',
        },

        {
            key: 'Other',
            text: 'Other',
        },
    ];

    const yesNoDeclined: IChoiceGroupOption[] = [
        {
            key: 'Yes',
            text: 'Yes',
        },
        {
            key: 'No',
            text: 'No',
        },
        {
            key: 'Patient Declined',
            text: 'Patient Declined',
        },
    ];
    const typeOfMigrantWorker: IDropdownOption[] = [
        {
            key: '',
            text: 'None',
        },
        {
            key: 'Migrant',
            text: 'Migrant',
        },
        {
            key: 'Seasonal',
            text: 'Seasonal',
        },
    ];
    const homelessStatus: IDropdownOption[] = [
        {
            key: '',
            text: 'None',
        },
        {
            key: 'DoublingUp',
            text: 'Doubling Up',
        },
        {
            key: 'HomelessShelter',
            text: 'Homeless Shelter',
        },
        {
            key: 'PermanentSupportiveHousing',
            text: 'Permanent Supportive Housing',
        },
        {
            key: 'Street',
            text: 'Street',
        },
        {
            key: 'Transitional',
            text: 'Transitional',
        },
        {
            key: 'Other',
            text: 'Other',
        },
        {
            key: 'Unknown',
            text: 'Unknown',
        },
    ];

    return (
        <CheckInSection
            leftContent={<>{basicInfoTasks.length ? <UserTasksBadge size="small" userTasks={basicInfoTasks} /> : null}</>}
            section={CheckInSections.BasicDetails}
            animation={false}
        >
            <Stack tokens={{ childrenGap: 5 }}>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    {!lookupPatientBasicDetailTaskByType[TaskType.LegalSexVerification] ? null : (
                        <ComboBox
                            options={_legalSexesOptionsList}
                            label="Legal Sex"
                            allowFreeform
                            placeholder="Select"
                            selectedKey={patient?.legalSexId ?? undefined}
                            onChange={(e, option) => {
                                if (option) dispatch(editPatientPropChange({ key: 'legalSexId', value: option.key as string }));
                            }}
                            onRenderLabel={(data) => (
                                <TaskFieldLabel
                                    label={data?.props.label}
                                    lookup={lookupPatientBasicDetailTaskByType}
                                    taskType={TaskType.LegalSexVerification}
                                    required
                                />
                            )}
                            errorMessage={
                                !patient?.legalSexId
                                    ? getValidationError(validationErrors, BasicDetailsFields.LegalSex)
                                        ? 'Legal Sex is required.'
                                        : ''
                                    : ''
                            }
                            useComboBoxAsMenuWidth
                        />
                    )}

                    {!lookupPatientBasicDetailTaskByType[TaskType.SexAssignedAtBirthVerification] ? null : (
                        <ComboBox
                            options={legalSexOptions}
                            label="Sex Assigned at Birth"
                            placeholder="Select"
                            selectedKey={patient?.uds?.sexAtBirthId ?? ''}
                            allowFreeform
                            onChange={(e, option) => {
                                if (option) _updatePatientUDSProps('sexAtBirthId', option.key);
                            }}
                            required={!!lookupPatientBasicDetailTaskByType[TaskType.SexAssignedAtBirthVerification]}
                            onRenderLabel={(data) => (
                                <TaskFieldLabel
                                    label={data?.props.label}
                                    lookup={lookupPatientBasicDetailTaskByType}
                                    taskType={TaskType.SexAssignedAtBirthVerification}
                                />
                            )}
                            useComboBoxAsMenuWidth
                        />
                    )}
                    {!lookupPatientBasicDetailTaskByType[TaskType.GenderIdentityVerification] ? null : (
                        <ComboBox
                            placeholder="Select"
                            label="Gender Identity"
                            selectedKey={patient?.genderIdentityId}
                            allowFreeform
                            useComboBoxAsMenuWidth
                            options={genderIdentityOptions}
                            onChange={(e, option) => {
                                if (option) {
                                    _updatePatientRootProps('genderIdentityId', option.key);
                                }
                            }}
                            onRenderLabel={(data) => (
                                <TaskFieldLabel
                                    label={data?.props.label}
                                    lookup={lookupPatientBasicDetailTaskByType}
                                    taskType={TaskType.GenderIdentityVerification}
                                />
                            )}
                        />
                    )}
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 10 }} grow wrap>
                    {!lookupPatientBasicDetailTaskByType[TaskType.PronounVerification] ? null : (
                        <Stack.Item grow>
                            <ComboBox
                                placeholder="Select"
                                label="Pronouns"
                                selectedKey={patient?.pronoun}
                                useComboBoxAsMenuWidth
                                allowFreeform
                                options={pronouns}
                                onChange={(e, option) => {
                                    if (option) {
                                        _updatePatientRootProps('pronoun', option.key);
                                    }
                                }}
                                onRenderLabel={(data) => (
                                    <TaskFieldLabel
                                        label={data?.props.label}
                                        lookup={lookupPatientBasicDetailTaskByType}
                                        taskType={TaskType.PronounVerification}
                                    />
                                )}
                            />
                        </Stack.Item>
                    )}
                    {!lookupPatientBasicDetailTaskByType[TaskType.SexualOrientationVarification] ? null : (
                        <Stack.Item grow>
                            <ComboBox
                                placeholder="Select"
                                label="Sexual Orientation"
                                selectedKey={patient?.sexualOrientationId}
                                useComboBoxAsMenuWidth
                                allowFreeform
                                options={sexualOrientationOptions}
                                onChange={(e, option) => {
                                    if (option) _updatePatientRootProps('sexualOrientationId', option.key);
                                }}
                                onRenderLabel={(props) => (
                                    <TaskFieldLabel
                                        label={props?.props.label}
                                        lookup={lookupPatientBasicDetailTaskByType}
                                        taskType={TaskType.SexualOrientationVarification}
                                    />
                                )}
                            />
                        </Stack.Item>
                    )}
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Stack tokens={{ childrenGap: 10 }}>
                        {lookupPatientBasicDetailTaskByType[TaskType.SchoolBasedHealthVerification] && (
                            <Field.ChoiceGroup
                                inline
                                label="School-based health center patient"
                                options={yesNoDeclined}
                                selectedKey={patient?.uds?.schoolbasedPatient ?? ''}
                                onChange={(e, option) => {
                                    if (option) {
                                        _updatePatientUDSProps('schoolbasedPatient', option.key);
                                        _updatePatientUDSProps('schoolbasedLastUpdated', updateDate);
                                    }
                                }}
                                preComponentContent={
                                    <TaskFieldLabel
                                        style={{ position: 'absolute', top: 2, left: 210 }}
                                        lookup={lookupPatientBasicDetailTaskByType}
                                        taskType={TaskType.SchoolBasedHealthVerification}
                                    />
                                }
                            />
                        )}
                    </Stack>
                    <Stack horizontal>
                        {lookupPatientBasicDetailTaskByType[TaskType.VeteranStatusVerification] && (
                            <Field.ChoiceGroup
                                inline
                                label="Veteran Status"
                                options={yesNoDeclined}
                                selectedKey={patient?.uds?.veteranStatus ?? ''}
                                onChange={(e, option) => {
                                    if (option) {
                                        _updatePatientUDSProps('veteranStatus', option.key);
                                        _updatePatientUDSProps('veteranStatusLastUpdated', updateDate);
                                    }
                                }}
                                preComponentContent={
                                    <TaskFieldLabel
                                        style={{ position: 'absolute', top: 2, left: 88 }}
                                        lookup={lookupPatientBasicDetailTaskByType}
                                        taskType={TaskType.VeteranStatusVerification}
                                    />
                                }
                            />
                        )}
                    </Stack>
                </Stack>
                {lookupPatientBasicDetailTaskByType[TaskType.AgriculturalWorkerVerification] ? (
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <Field.ChoiceGroup
                            inline
                            label="Agricultural Worker"
                            options={yesNoDeclined}
                            selectedKey={patient?.uds?.agriculturalWorker ?? ''}
                            onChange={(e, option) => {
                                if (option) {
                                    _updatePatientUDSProps('agriculturalWorker', option.key);
                                    _updatePatientUDSProps('agriculturalWorkerLastUpdated', updateDate);
                                    if (option.key !== 'Yes') _updatePatientUDSProps('agriculturalWorkerDetail', undefined);
                                }
                            }}
                            preComponentContent={
                                <TaskFieldLabel
                                    style={{ position: 'absolute', top: 2, left: 118 }}
                                    lookup={lookupPatientBasicDetailTaskByType}
                                    taskType={TaskType.AgriculturalWorkerVerification}
                                />
                            }
                        />

                        <Stack.Item grow>
                            <Field.Dropdown
                                style={{ minWidth: 200 }}
                                placeholder="Select"
                                label="Type"
                                selectedKey={patient?.uds?.agriculturalWorkerDetail ?? ''}
                                options={typeOfMigrantWorker}
                                onChange={(e, option) => {
                                    if (option) {
                                        _updatePatientUDSProps('agriculturalWorkerDetail', option.key);
                                        _updatePatientUDSProps('agriculturalWorkerLastUpdated', updateDate);
                                    }
                                }}
                                disabled={patient?.uds?.agriculturalWorker !== 'Yes'}
                                errorMessage={
                                    getValidationError(validationErrors, AgriculturalHomelessTypeFields.AgriculturalType)
                                        ? 'Agricultural  Type is required.'
                                        : undefined
                                }
                            />
                        </Stack.Item>
                    </Stack>
                ) : null}

                {lookupPatientBasicDetailTaskByType[TaskType.HomelessStatusValueVerification] ||
                _lookupPatientAppointmentBasicDetailTaskByType[TaskType.HomelessStatusVerification] ? (
                    <>
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <Stack.Item>
                                <Field.ChoiceGroup
                                    inline
                                    label="Homeless Status"
                                    options={yesNoDeclined}
                                    selectedKey={patient?.uds?.homelessStatus ?? ''}
                                    preComponentContent={
                                        <TaskFieldLabel
                                            style={{ position: 'absolute', top: 2, left: 100 }}
                                            lookup={lookupPatientBasicDetailTaskByType}
                                            taskType={TaskType.HomelessStatusValueVerification}
                                        />
                                    }
                                    onChange={(e, option) => {
                                        if (option) {
                                            _updatePatientUDSProps('homelessStatus', option.key);
                                            _updatePatientUDSProps('homelessStatusLastUpdated', updateDate);
                                            if (option.key !== 'Yes') {
                                                _updatePatientUDSProps('homelessStatusDetail', undefined);
                                                if (!_currentAppointment?.lastVerifiedHomelessDetail)
                                                    dispatch(
                                                        updatePatientAppointmentProp({
                                                            path: 'lastVerifiedHomelessStatus',
                                                            value: new Date().toISOString(),
                                                        }),
                                                    );
                                            } else if (!patient?.uds?.homelessStatusDetail) {
                                                dispatch(
                                                    updatePatientAppointmentProp({
                                                        path: 'lastVerifiedHomelessStatus',
                                                        value: undefined,
                                                    }),
                                                );
                                            }
                                        }
                                    }}
                                />
                            </Stack.Item>
                            <Stack.Item grow>
                                <Field.Dropdown
                                    style={{ minWidth: 200 }}
                                    placeholder="Select"
                                    label="Type"
                                    selectedKey={patient?.uds?.homelessStatusDetail ?? ''}
                                    options={homelessStatus}
                                    onChange={(e, option) => {
                                        if (option) {
                                            _updatePatientUDSProps('homelessStatusDetail', option.key);
                                            _updatePatientUDSProps('homelessStatusLastUpdated', updateDate);
                                            if (option.key && patient?.uds?.homelessStatus === 'Yes') {
                                                if (!_currentAppointment?.lastVerifiedHomelessDetail)
                                                    dispatch(
                                                        updatePatientAppointmentProp({
                                                            path: 'lastVerifiedHomelessStatus',
                                                            value: new Date().toISOString(),
                                                        }),
                                                    );
                                            }
                                        }
                                    }}
                                    disabled={patient?.uds?.homelessStatus !== 'Yes'}
                                    errorMessage={
                                        getValidationError(validationErrors, AgriculturalHomelessTypeFields.HomelessType)
                                            ? 'Homeless Type is required.'
                                            : undefined
                                    }
                                />
                            </Stack.Item>
                        </Stack>
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            {_lookupPatientAppointmentBasicDetailTaskByType[TaskType.HomelessStatusVerification] && (
                                <Checkbox
                                    label={`Verify homeless status${
                                        _currentAppointment?.lastVerifiedHomelessStatus
                                            ? ` (${format(
                                                  new Date(_currentAppointment.lastVerifiedHomelessStatus),
                                                  'MM/dd/yyyy ',
                                              )})`
                                            : ''
                                    }`}
                                    onRenderLabel={(data) => (
                                        <TaskFieldLabel
                                            label={data?.label}
                                            lookup={_lookupPatientAppointmentBasicDetailTaskByType}
                                            taskType={TaskType.HomelessStatusVerification}
                                        />
                                    )}
                                    disabled={patient?.uds?.homelessStatus === 'Yes' && !patient?.uds?.homelessStatusDetail}
                                    onChange={() => {
                                        dispatch(
                                            updatePatientAppointmentProp({
                                                path: 'lastVerifiedHomelessStatus',
                                                value: !_currentAppointment?.lastVerifiedHomelessStatus
                                                    ? new Date().toISOString()
                                                    : undefined,
                                            }),
                                        );
                                    }}
                                    checked={!!_currentAppointment?.lastVerifiedHomelessStatus}
                                />
                            )}
                        </Stack>
                    </>
                ) : null}
                <PatientPhoneNumbers isCheckoutCheckin />
                {(lookupPatientBasicDetailTaskByType[TaskType.RaceVerification] ||
                    lookupPatientBasicDetailTaskByType[TaskType.EthnicityVerification]) && (
                    <SubSection title="Basic Details">
                        <Stack horizontal grow tokens={{ childrenGap: 10 }}>
                            {lookupPatientBasicDetailTaskByType[TaskType.RaceVerification] && (
                                <Stack.Item grow>
                                    <Field.SearchCombo
                                        label="Race(s)"
                                        multiSelect
                                        allowFreeform
                                        selectedKey={patient?.raceIds ?? undefined}
                                        options={raceOptions}
                                        placeholder="Select Race(s)"
                                        threshold={0.3}
                                        onRenderOption={(props) => <TText>{props?.text}</TText>}
                                        onRenderLabel={(props) => (
                                            <TaskFieldLabel
                                                label={props?.props.label}
                                                lookup={lookupPatientBasicDetailTaskByType}
                                                taskType={TaskType.RaceVerification}
                                            />
                                        )}
                                        onChange={(e, option) => {
                                            if (option) {
                                                let newRaceIds: string[] = [];
                                                if (patient?.raceIds && patient?.raceIds?.length) {
                                                    const isRaceExist = patient.raceIds.includes(option.key as string);
                                                    if (isRaceExist) {
                                                        newRaceIds = patient.raceIds.filter((id) => id !== option.key);
                                                    } else {
                                                        newRaceIds = [...patient.raceIds, option.key as string];
                                                    }
                                                } else {
                                                    newRaceIds = [option.key as string];
                                                }
                                                _updatePatientRootProps('raceIds', newRaceIds);
                                            }
                                        }}
                                        useComboBoxAsMenuWidth
                                    />
                                </Stack.Item>
                            )}
                            {lookupPatientBasicDetailTaskByType[TaskType.EthnicityVerification] && (
                                <Stack.Item>
                                    <ComboBox
                                        style={{ width: 250 }}
                                        label="Ethnicity"
                                        placeholder="Select"
                                        allowFreeform
                                        selectedKey={patient?.ethnicityId ?? undefined}
                                        options={ethnicityOptions}
                                        onRenderLabel={(props) => (
                                            <TaskFieldLabel
                                                label={props?.props.label}
                                                lookup={lookupPatientBasicDetailTaskByType}
                                                taskType={TaskType.EthnicityVerification}
                                            />
                                        )}
                                        onChange={(e, option) => {
                                            if (option) _updatePatientRootProps('ethnicityId', option.key);
                                        }}
                                        useComboBoxAsMenuWidth
                                    />
                                </Stack.Item>
                            )}
                        </Stack>
                    </SubSection>
                )}
                {(_lookupPatientAppointmentBasicDetailTaskByType[TaskType.ResidentialAddressVerification] ||
                    _lookupPatientAppointmentBasicDetailTaskByType[TaskType.MailingAddressVerification]) && (
                    <>
                        <PatientAddress isCheckinCheckout />
                        <MailingAddress />
                    </>
                )}
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    {_lookupPatientAppointmentBasicDetailTaskByType[TaskType.ResidentialAddressVerification] && (
                        <Checkbox
                            label={`Verify residential address${
                                _currentAppointment?.lastVerifiedResidentialAddress
                                    ? ` (${format(new Date(_currentAppointment.lastVerifiedResidentialAddress), 'MM/dd/yyyy ')})`
                                    : ''
                            }`}
                            onRenderLabel={(data) => (
                                <TaskFieldLabel
                                    label={data?.label}
                                    lookup={_lookupPatientAppointmentBasicDetailTaskByType}
                                    taskType={TaskType.ResidentialAddressVerification}
                                />
                            )}
                            onChange={() => {
                                dispatch(
                                    updatePatientAppointmentProp({
                                        path: 'lastVerifiedResidentialAddress',
                                        value: !_currentAppointment?.lastVerifiedResidentialAddress
                                            ? new Date().toISOString()
                                            : undefined,
                                    }),
                                );
                            }}
                            checked={!!_currentAppointment?.lastVerifiedResidentialAddress}
                        />
                    )}
                    {_lookupPatientAppointmentBasicDetailTaskByType[TaskType.MailingAddressVerification] && (
                        <Checkbox
                            label={`Verify mailing address${
                                _currentAppointment?.lastVerifiedMailingAddress
                                    ? ` (${format(new Date(_currentAppointment.lastVerifiedMailingAddress), 'MM/dd/yyyy')})`
                                    : ''
                            }`}
                            onRenderLabel={(data) => (
                                <TaskFieldLabel
                                    label={data?.label}
                                    lookup={_lookupPatientAppointmentBasicDetailTaskByType}
                                    taskType={TaskType.MailingAddressVerification}
                                />
                            )}
                            onChange={() => {
                                dispatch(
                                    updatePatientAppointmentProp({
                                        path: 'lastVerifiedMailingAddress',
                                        value: !_currentAppointment?.lastVerifiedMailingAddress
                                            ? new Date().toISOString()
                                            : undefined,
                                    }),
                                );
                            }}
                            checked={!!_currentAppointment?.lastVerifiedMailingAddress}
                        />
                    )}
                </Stack>
            </Stack>
        </CheckInSection>
    );
}
