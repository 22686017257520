import { IDropdownOption, Stack } from '@fluentui/react';
import { NoteType } from 'api/models/chart-note.model';
import { TModal } from 'components';
import { IActionButton } from 'components/ActionButton';
import DropdownField from 'components/Field/DropdownField';
import { FormEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openNoteModal } from 'state/slices/chart-notes/note-browser/note-browser.actions';
import { selectNewNoteIsOpen } from 'state/slices/chart-notes/note-browser/note-browser.selectors';
import { noteBrowserActions, NoteBrowserModal } from 'state/slices/chart-notes/note-browser/note-browser.slice';

function NewNoteModal() {
    const isOpen = useSelector(selectNewNoteIsOpen);
    const dispatch = useDispatch();
    const onDismiss = () => dispatch(openNoteModal(NoteBrowserModal.CreateNote, false));

    const [noteType, setNoteType] = useState(NoteType.CareCoordination);
    const onChange = (e?: FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        if (!option) return;
        setNoteType(option.key as NoteType);
    };

    return (
        <TModal
            title="Add New Note"
            modalProps={{ isOpen, onDismiss }}
            hideContentOverflow
            mainButtons={NewNoteActions(noteType, onDismiss)}
        >
            <Stack style={{ padding: 10 }}>
                <DropdownField
                    label="New note type"
                    selectedKey={noteType}
                    options={[
                        { key: NoteType.PatientCommunication, text: 'Patient Communication' },
                        { key: NoteType.CareCoordination, text: 'Care Coordination' },
                        { key: NoteType.FollowUp, text: 'Follow Up' },
                    ]}
                    onChange={onChange}
                />
            </Stack>
        </TModal>
    );
}

function NewNoteActions(noteType: NoteType, onDismiss: () => void): IActionButton[] {
    const dispatch = useDispatch();

    const onCreate = () => dispatch(noteBrowserActions.createNewNote(noteType));

    const buttons: IActionButton[] = [
        {
            text: 'Create',
            primary: true,
            onClick: onCreate,
        },
        {
            text: 'Cancel',
            onClick: onDismiss,
        },
    ];

    return buttons;
}

export default NewNoteModal;
