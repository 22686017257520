import { ToothArea } from 'api/models/tooth-area';

/**
 * Need a new way to determine surface position
 *
 * Horizontally we have two surfaces: Distal and Mesial
 *
 * Positions 1 - 8 are D - M.
 * Positions 9 - 16 are M - D.
 *
 * Positions 25 - 32 are D - M.
 * Positions 17 - 24 are M - D.
 *
 * Vertically we have two surfaces but some surfaces change based on position: (Buccal/Facial) Lingual
 *
 * Positions 6 - 11 are F instead of B
 * Positions 22 - 27 are F instead of B
 *
 * Positions 1 - 16 are:
 * (B/F)
 * -----
 *   L
 *
 * Positions 17 - 32 are:
 *   L
 * -----
 * (B/F)
 *
 * Lingual always faces inward as shown above.
 *
 *
 */

export type SurfaceControlButton = { label: string; area: keyof typeof ToothArea; relatedArea?: keyof typeof ToothArea };

export type ControlPosition = 'top' | 'right' | 'bottom' | 'left' | 'center' | 'vestibularTop' | 'vestibularBottom';
export type SurfaceControlButtons = Record<ControlPosition, SurfaceControlButton>;

const surfaceControlButtonLookup: Record<string, SurfaceControlButton> = {
    [ToothArea.Lingual]: {
        label: 'L',
        area: 'Lingual',
        relatedArea: 'LingualVestibular',
    },
    [ToothArea.Buccal]: {
        label: 'B',
        area: 'Facial', // Buccal always treated as facial. Is translated to buccal in applicable areas pipe.
        relatedArea: 'FacialVestibular',
    },
    [ToothArea.Facial]: {
        label: 'F',
        area: 'Facial',
        relatedArea: 'FacialVestibular',
    },
    [ToothArea.Occlusal]: {
        label: 'O/I',
        area: 'Occlusal',
    },
    [ToothArea.Distal]: {
        label: 'D',
        area: 'Distal',
    },
    [ToothArea.Mesial]: {
        label: 'M',
        area: 'Mesial',
    },
    [ToothArea.LingualVestibular]: {
        label: 'LV',
        area: 'LingualVestibular',
        relatedArea: 'Lingual',
    },
    [ToothArea.FacialVestibular]: {
        label: 'FV',
        area: 'FacialVestibular',
        relatedArea: 'Facial',
    },
    [ToothArea.BuccalVestibular]: {
        label: 'BV',
        area: 'FacialVestibular', // Buccal always treated as facial. Is translated to buccal in applicable areas pipe.
        relatedArea: 'Facial',
    },
};

export function getControlsByToothPosition(position: number): SurfaceControlButtons {
    const buccalOrFacial =
        (position >= 6 && position <= 11) || (position >= 22 && position <= 27)
            ? {
                  surface: surfaceControlButtonLookup[ToothArea.Facial],
                  vestibular: surfaceControlButtonLookup[ToothArea.FacialVestibular],
              }
            : {
                  surface: surfaceControlButtonLookup[ToothArea.Buccal],
                  vestibular: surfaceControlButtonLookup[ToothArea.BuccalVestibular],
              };

    const isMaxillary = position >= 1 && position <= 16;
    const isMandibular = position >= 17 && position <= 32;

    const bottom = isMaxillary ? surfaceControlButtonLookup[ToothArea.Lingual] : buccalOrFacial.surface;
    const top = isMandibular ? surfaceControlButtonLookup[ToothArea.Lingual] : buccalOrFacial.surface;

    const vestibularBottom = isMaxillary ? surfaceControlButtonLookup[ToothArea.LingualVestibular] : buccalOrFacial.vestibular;
    const vestibularTop = isMandibular ? surfaceControlButtonLookup[ToothArea.LingualVestibular] : buccalOrFacial.vestibular;

    const left =
        (position >= 1 && position <= 8) || (position >= 25 && position <= 32)
            ? surfaceControlButtonLookup[ToothArea.Distal]
            : surfaceControlButtonLookup[ToothArea.Mesial];
    const right =
        (position >= 9 && position <= 16) || (position >= 17 && position <= 24)
            ? surfaceControlButtonLookup[ToothArea.Distal]
            : surfaceControlButtonLookup[ToothArea.Mesial];
    const center = surfaceControlButtonLookup[ToothArea.Occlusal];

    return {
        bottom,
        top,
        center,
        left,
        right,
        vestibularBottom,
        vestibularTop,
    };
}
