import { Label, Separator, Stack, Text, TextField, useTheme } from '@fluentui/react';
import { Section, Field } from 'components';
import { RiskType } from 'forms/CariesRiskChild/types';
import { useSelector } from 'hooks';
import useForms from 'hooks/store/useForms';
import { ICariesRiskChildQuestions } from './types';

export default function CariesRiskChild(): JSX.Element | null {
    const { currentlySelectedForm, setFormFieldDataAndSave, previousOfSelectedForm } = useForms();
    const { palette } = useTheme();
    const selectedTheme = useSelector((state) => state.ui.selectedTheme);

    if (!currentlySelectedForm) return null;

    const data = currentlySelectedForm.data as ICariesRiskChildQuestions;
    const previousExamData = previousOfSelectedForm?.data as ICariesRiskChildQuestions;

    const getTextStyle = (data?: ICariesRiskChildQuestions): React.CSSProperties => ({
        backgroundColor:
            data?.score === RiskType.High
                ? palette.redDark
                : data?.score === RiskType.Moderate
                ? palette.yellowDark
                : data?.score === RiskType.Low
                ? palette.greenLight
                : 'transparent',
        color:
            selectedTheme === 'light' && (data?.score === RiskType.High || data?.score === RiskType.Moderate)
                ? 'white'
                : selectedTheme === 'dark' && data?.score === RiskType.Low
                ? palette.white
                : palette.black,
        padding: 5,
        borderRadius: 2,
    });

    const isCompleted = currentlySelectedForm.completedDate ? true : false;
    const typedSetFieldData = (path: keyof ICariesRiskChildQuestions, value?: string) => setFormFieldDataAndSave(path, value);
    return (
        <Stack tokens={{ childrenGap: 10 }} grow>
            <Stack
                styles={{
                    root: {
                        position: 'sticky',
                        top: 0,
                        zIndex: 99,
                        backgroundColor: palette.white,
                        paddingTop: 5,
                        paddingBottom: 5,
                    },
                }}
                grow
                horizontal
                horizontalAlign="space-between"
            >
                {data?.score && (
                    <Text color={'white'} style={getTextStyle(data)}>
                        Patient risk is {data.score.toLowerCase()}
                    </Text>
                )}{' '}
                {previousExamData?.score && (
                    <Text color={'white'} style={getTextStyle(previousExamData)}>
                        Previous exam patient risk is {previousExamData.score.toLowerCase()}
                    </Text>
                )}
            </Stack>
            <Stack grow>
                <Section heading="Contributing Conditions">
                    <Stack id="fluorideQuestionCariesChild" grow>
                        <Stack grow>
                            <Label>Fluoride Exposure</Label>
                            <Text variant="small">
                                Through drinking water, supplements, professional applications, or toothpaste.
                            </Text>
                        </Stack>
                        <Field.ChoiceGroup
                            options={[
                                { key: RiskType.Low, text: 'Yes' },
                                { key: RiskType.Moderate, text: 'No' },
                            ]}
                            selectedKey={data.fluorideExposure}
                            inline
                            onChange={(ev, option) => typedSetFieldData('fluorideExposure', option?.key)}
                            disabled={isCompleted}
                        />
                    </Stack>
                    <Separator />
                    <Stack id="sugaryOrStarchyFoodsCariesChild" grow>
                        <Stack grow>
                            <Label>Sugary/Starchy Foods or Drinks</Label>
                            <Text variant="small">
                                Including juice, carbonated or non-carbonated soft drinks, energy drinks, and medicinal syrups.
                            </Text>
                        </Stack>
                        <Field.ChoiceGroup
                            options={[
                                { key: RiskType.Low, text: 'Primarily at mealtimes' },
                                { key: RiskType.Moderate, text: 'Frequent or prolonged between meal exposures per day' },
                                { key: RiskType.High, text: 'Bottle or sippy cup with anything other than water at bed time' },
                            ]}
                            selectedKey={data.sugaryStarchyFoodsOrDrinks}
                            inline
                            onChange={(ev, value) => typedSetFieldData('sugaryStarchyFoodsOrDrinks', value?.key)}
                            disabled={isCompleted}
                        />
                    </Stack>
                    <Separator />
                    <Stack id="govtProgramsChild" grow>
                        <Stack grow>
                            <Label>Eligible for government programs</Label>
                            <Text variant="small">(WIC, Head Start, Medicaid or SCHIP)</Text>
                        </Stack>
                        <Field.ChoiceGroup
                            options={[
                                { key: RiskType.Low, text: 'No' },
                                { key: RiskType.High, text: 'Yes' },
                            ]}
                            selectedKey={data.govtProgramsEligibility}
                            inline
                            onChange={(ev, value) => typedSetFieldData('govtProgramsEligibility', value?.key)}
                            disabled={isCompleted}
                        />
                    </Stack>
                    <Separator />
                    <Stack id="cariesExperienceCariesChild" grow>
                        <Stack grow>
                            <Label>Caries Experience</Label>
                            <Text variant="small">Caries experience of mother, caregiver and/or other siblings</Text>
                        </Stack>
                        <Field.ChoiceGroup
                            options={[
                                { key: RiskType.Low, text: 'No carious lesions in last 24 months' },
                                { key: RiskType.Moderate, text: 'Carious lesions in last 7-23 months' },
                                { key: RiskType.High, text: 'Carious lesions in last 6 months' },
                            ]}
                            selectedKey={data.cariesExperience}
                            inline
                            onChange={(ev, value) => typedSetFieldData('cariesLesions', value?.key)}
                            disabled={isCompleted}
                        />
                    </Stack>
                    <Separator />
                    <Stack id="dentalHomeCariesChild" grow>
                        <Stack grow>
                            <Label>Dental Home</Label>
                            <Text variant="small">
                                Established patient of record, receiving regular dental care in a dental office
                            </Text>
                        </Stack>
                        <Field.ChoiceGroup
                            options={[
                                { key: RiskType.Low, text: 'Yes' },
                                { key: RiskType.Moderate, text: 'No' },
                            ]}
                            selectedKey={data.dentalHome}
                            inline
                            onChange={(ev, value) => typedSetFieldData('dentalHome', value?.key)}
                            disabled={isCompleted}
                        />
                    </Stack>
                </Section>
                <Section heading="General Health Conditions">
                    <Stack id="specialHealthcareNeedsCariesChild" grow>
                        <Stack grow>
                            <Label>Special Healthcare Needs</Label>
                            <Text variant="small">
                                Patients with developmental, physical, medical or mental disabilities that prevent or limit
                                performance of adequate oral health care by themselves or caregivers.
                            </Text>
                        </Stack>
                        <Field.ChoiceGroup
                            options={[
                                { key: RiskType.Low, text: 'No' },
                                { key: RiskType.High, text: 'Yes' },
                            ]}
                            selectedKey={data.specialHealthcareNeeds}
                            inline
                            onChange={(ev, value) => typedSetFieldData('specialHealthcareNeeds', value?.key)}
                            disabled={isCompleted}
                        />
                    </Stack>
                </Section>
                <Section heading="Clinical Conditions">
                    <Stack id="cavitatedOrNonCavitatedIncipientCariesChild" grow>
                        <Stack grow>
                            <Label>Visual or Radiographically Evident Restorations/Cavitated Carious Lesions</Label>
                        </Stack>
                        <Field.ChoiceGroup
                            options={[
                                { key: RiskType.Low, text: 'No new carious lesions or restorations in  24 months' },
                                { key: RiskType.High, text: 'Carious lesions or restorations in last 24 months' },
                            ]}
                            selectedKey={data.cavitatedOrNonCavitatedIncipient}
                            inline
                            onChange={(ev, value) => typedSetFieldData('nonCavitatedIncipientCariousLesions', value?.key)}
                            disabled={isCompleted}
                        />
                    </Stack>
                    <Separator />
                    <Stack id="cavitatedOrNonCavitatedIncipientCariesChild" grow>
                        <Stack grow>
                            <Label>Non-cavitated (incipient) carious lesions</Label>
                        </Stack>
                        <Field.ChoiceGroup
                            options={[
                                { key: RiskType.Low, text: 'No new carious lesions or restorations in  24 months' },
                                { key: RiskType.High, text: 'New lesions in last 24 months' },
                            ]}
                            selectedKey={data.cavitatedOrNonCavitatedIncipient}
                            inline
                            onChange={(ev, value) => typedSetFieldData('cavitatedOrNonCavitatedIncipient', value?.key)}
                            disabled={isCompleted}
                        />
                    </Stack>
                    <Separator />
                    <Stack id="teethMissingDueToCariesCariesChild" grow>
                        <Field.ChoiceGroup
                            label="Teeth missing due to caries"
                            options={[
                                { key: RiskType.Low, text: 'No' },
                                { key: RiskType.High, text: 'Yes' },
                            ]}
                            selectedKey={data.teethMissingDueToCaries}
                            inline
                            onChange={(ev, value) => typedSetFieldData('teethMissingDueToCaries', value?.key)}
                            disabled={isCompleted}
                        />
                    </Stack>
                    <Separator />
                    <Stack id="visiblePlaqueCariesChild" grow>
                        <Field.ChoiceGroup
                            label="Visible plaque"
                            options={[
                                { key: RiskType.Low, text: 'No' },
                                { key: RiskType.Moderate, text: 'Yes' },
                            ]}
                            selectedKey={data.visiblePlaque}
                            inline
                            onChange={(ev, value) => typedSetFieldData('visiblePlaque', value?.key)}
                            disabled={isCompleted}
                        />
                    </Stack>
                    <Separator />
                    <Stack id="dentalAppliancesChild" grow>
                        <Field.ChoiceGroup
                            label="Dental/orthodontic appliances present (fixed or removable)"
                            options={[
                                { key: RiskType.Low, text: 'No' },
                                { key: RiskType.Moderate, text: 'Yes' },
                            ]}
                            selectedKey={data.dentalOrthodonticAppliancesPresent}
                            inline
                            onChange={(ev, value) => typedSetFieldData('dentalOrthodonticAppliancesPresent', value?.key)}
                            disabled={isCompleted}
                        />
                    </Stack>
                    <Separator />
                    <Stack id="salivaryFlowChild" grow>
                        <Field.ChoiceGroup
                            label="Salivary Flow"
                            options={[
                                { key: RiskType.Low, text: 'Visually adequate' },
                                { key: RiskType.High, text: 'Visually inadequate' },
                            ]}
                            selectedKey={data.salivaryFlow}
                            inline
                            onChange={(ev, value) => typedSetFieldData('salivaryFlow', value?.key)}
                            disabled={isCompleted}
                        />
                    </Stack>
                </Section>
                <Section heading="Patient Instructions">
                    <TextField
                        multiline
                        value={data.additionalPatientInstructions}
                        label="Additional instructions for the patient"
                        onChange={(ev, value) => typedSetFieldData('additionalPatientInstructions', value)}
                        disabled={isCompleted}
                    />
                </Section>
            </Stack>
        </Stack>
    );
}
