import { ReportType } from 'api/models/embed-report.model';
import { IUACReportQuery } from 'api/models/uac-report.model';
import { useSelector, useTenantId } from 'hooks';
import { useDispatch } from 'react-redux';
import reportActionLookup from 'state/slices/reporting/reportActionLookup';
import { selectReportProperties } from 'state/slices/reporting/reporting.selectors';
import UACBase from './UACBase';

const UACandOveragesSummaryReport = () => {
    const tenantId = useTenantId();
    const dispatch = useDispatch();

    const query = useSelector(selectReportProperties) as IUACReportQuery;

    const onViewReport = () => {
        dispatch(reportActionLookup[ReportType.UACandOveragesSummaryReport]({ tenantId, ...query }));
    };
    return <UACBase _onViewReport={onViewReport} />;
};

export default UACandOveragesSummaryReport;
