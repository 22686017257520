import { range } from 'lodash';

export const STAGE_WIDTH = 1000;
export const CELL_HEIGHT = 20;

const NUMBER_OF_DEPTH_LINES = 16;
export const LINE_SPACING = 8;

export const TOOTH_CONTAINER_WIDTH = STAGE_WIDTH / NUMBER_OF_DEPTH_LINES;
export const SPRITE_WIDTH = TOOTH_CONTAINER_WIDTH - 10;
export const SPRITE_CROWN_HEIGHT = LINE_SPACING * 4;
export const SPRITE_ROOT_HEIGHT = LINE_SPACING * 12;

export const TOOTH_START = (TOOTH_CONTAINER_WIDTH - SPRITE_WIDTH) / 2;
export const TOOTH_MIDLINE = TOOTH_START + TOOTH_CONTAINER_WIDTH / 2;
export const TOOTH_END = SPRITE_WIDTH + TOOTH_START;

export const STAGE_HEIGHT = LINE_SPACING * (NUMBER_OF_DEPTH_LINES * 2) + SPRITE_CROWN_HEIGHT * 3;
export const UPPER_POSITIONS = range(1, 17);
export const LOWER_POSITIONS = range(17, 33).reverse();
