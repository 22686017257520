import {
    DefaultButton,
    Dropdown,
    IDropdownOption,
    IPanelProps,
    Panel,
    PrimaryButton,
    Stack,
    TextField,
    Toggle,
} from '@fluentui/react';
import { Field } from 'components';
import { useChart, useQuickActions } from 'hooks';
import { useEffect, useState } from 'react';
import { ProcedureActionType } from 'state/slices/charting/chart/chart.slice';
import { ProviderDropdown } from 'pages/components';

type Props = { panel: IPanelProps; closePanel: () => void };

function AreaPanel({ panel, closePanel }: Props) {
    const { selectedQuickActionType } = useQuickActions();
    const { selectedTeeth } = useChart();
    const [data, setData] = useState<{
        selectedTeeth: number[];
        status: ProcedureActionType;
        date?: string;
        providerId: string;
        isReplacement?: boolean;
        replacementDate?: string;
    }>({
        selectedTeeth: selectedTeeth,
        status: selectedQuickActionType as ProcedureActionType,
        providerId: '',
        isReplacement: false,
    });

    const statusIsExisting = data.status === ProcedureActionType.Existing || data.status === ProcedureActionType.ExistingOther;

    useEffect(() => {
        if (statusIsExisting) {
            setData((d) => ({ ...d, date: undefined, isReplacement: false }));
        } else {
            setData((d) => ({ ...d, date: new Date().toISOString() }));
        }
    }, [statusIsExisting]);

    useEffect(() => {
        if (!data.isReplacement) {
            setData((data) => ({ ...data, replacementDate: '' }));
        }
    }, [data.isReplacement]);

    const _statusOptions = Object.keys(ProcedureActionType).map((k) => ({
        key: (ProcedureActionType as any)[k],
        text: (ProcedureActionType as any)[k],
    }));

    const setStatus = (event?: any, option?: IDropdownOption) => {
        const date =
            option?.key === ProcedureActionType.Existing || option?.key === ProcedureActionType.ExistingOther
                ? undefined
                : new Date().toISOString();
        if (option?.key) setData({ ...data, status: option.key as ProcedureActionType, date });
    };

    // const upperArchPermanentTeeth = () => {
    //     let teethButtons = [];
    //     for (let i = 1; i <= 16; i++) {
    //         const isSelected = data.selectedTeeth.indexOf(i) > -1;
    //         teethButtons.push(<ToothButton toothId={i} isSelected={isSelected} onClick={toggleTeeth} />);
    //     }
    //     return teethButtons;
    // };
    // const lowerArchPermanentTeeth = () => {
    //     let teethButtons = [];
    //     for (let i = 17; i <= 32; i++) {
    //         const isSelected = data.selectedTeeth.indexOf(i) > -1;
    //         teethButtons.push(<ToothButton toothId={i} isSelected={isSelected} onClick={toggleTeeth} />);
    //     }
    //     return teethButtons;
    // };

    const setProvider = (event?: any, option?: IDropdownOption) => {
        if (option?.key) {
            setData({ ...data, providerId: option.key as string });
        } else {
            setData({ ...data, providerId: '' });
        }
    };

    const setReplacementDate = (e?: any, value?: string) => {
        setData({ ...data, replacementDate: value ? value : '' });
    };

    const setIsReplacement = (e?: any, checked?: boolean) => setData({ ...data, isReplacement: checked });

    const upperArchIds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
    const lowerArchIds = [17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32];
    const upperRightQuadIds = [1, 2, 3, 4, 5, 6, 7, 8];
    const upperLeftQuadIds = [9, 10, 11, 12, 13, 14, 15, 16];
    const lowerLeftQuadIds = [17, 18, 19, 20, 21, 22, 23, 24];
    const lowerRightQuadIds = [25, 26, 27, 28, 29, 30, 31, 32];

    const setUpperArch = () => setData({ ...data, selectedTeeth: upperArchIds });
    const setLowerArch = () => setData({ ...data, selectedTeeth: lowerArchIds });
    const setUpperRightQuad = () => setData({ ...data, selectedTeeth: upperRightQuadIds });
    const setUpperLeftQuad = () => setData({ ...data, selectedTeeth: upperLeftQuadIds });
    const setLowerRightQuad = () => setData({ ...data, selectedTeeth: lowerRightQuadIds });
    const setLowerLeftQuad = () => setData({ ...data, selectedTeeth: lowerLeftQuadIds });

    // function toggleTeeth(toothId: number) {
    //     if (data.selectedTeeth.indexOf(toothId) > -1) {
    //         setData({ ...data, selectedTeeth: data.selectedTeeth.filter((tooth) => tooth !== toothId) });
    //     } else {
    //         setData({ ...data, selectedTeeth: [...data.selectedTeeth, toothId] });
    //     }
    // }

    const refreshState = () =>
        setData({
            selectedTeeth: selectedTeeth,
            status: selectedQuickActionType as ProcedureActionType,
            providerId: '',
            isReplacement: false,
            replacementDate: '',
        });

    return (
        <Panel
            {...panel}
            onDismissed={refreshState}
            isFooterAtBottom
            onRenderFooterContent={() => (
                <Stack horizontal tokens={{ childrenGap: 12 }}>
                    <PrimaryButton text="Save" />
                    <DefaultButton text="Cancel" onClick={closePanel} />
                </Stack>
            )}
        >
            <Stack>
                <Stack horizontal tokens={{ childrenGap: 12 }}>
                    <Stack.Item grow={1}>
                        <TextField label="Procedure" readOnly />
                    </Stack.Item>
                    <Stack.Item grow={1}>
                        <Dropdown label="Status" options={_statusOptions} selectedKey={data.status} onChange={setStatus} />
                    </Stack.Item>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 12 }}>
                    <Stack.Item grow={1}>
                        <ProviderDropdown selectedKey={data.providerId} onChange={setProvider} />
                    </Stack.Item>
                    <Stack.Item grow={1}>
                        <Field.Date label="Onset Date" value={data.date} disabled={!statusIsExisting} />
                    </Stack.Item>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 12 }}>
                    <Stack.Item grow>
                        <Toggle
                            label="Is replacement?"
                            onText="Yes, this is a replacement."
                            offText="No, this is a new procedure."
                            checked={data.isReplacement}
                            onChange={setIsReplacement}
                            disabled={statusIsExisting}
                        />
                    </Stack.Item>
                    <Stack.Item grow>
                        <Field.Date
                            label="Estimated date of original procedure"
                            value={data.replacementDate}
                            disabled={!data.isReplacement}
                            onChange={setReplacementDate}
                        />
                    </Stack.Item>
                </Stack>
                <Stack horizontal verticalAlign="end" tokens={{ childrenGap: 12 }}>
                    <Stack.Item grow={1}>
                        <TextField label="Tooth #" readOnly value={data.selectedTeeth.join(', ')} />
                    </Stack.Item>
                    <DefaultButton text="Deselect all" onClick={() => setData({ ...data, selectedTeeth: [] })} />
                </Stack>
                <Stack tokens={{ childrenGap: 12 }} styles={{ root: { paddingTop: 12 } }}>
                    <Stack
                        horizontal
                        horizontalAlign="center"
                        tokens={{ childrenGap: 12 }}
                        styles={{ root: { marginLeft: 105 } }}
                    >
                        <DefaultButton text="UR" onClick={setUpperRightQuad} />
                        <DefaultButton text="UL" onClick={setUpperLeftQuad} />
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 12 }}>
                        <DefaultButton text="UA" onClick={setUpperArch} />
                        <Stack horizontal tokens={{ childrenGap: 2 }} verticalAlign="center">
                            {/* {upperArchPermanentTeeth()} */}
                        </Stack>
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 12 }}>
                        <DefaultButton text="LA" onClick={setLowerArch} />
                        <Stack horizontal tokens={{ childrenGap: 2 }} verticalAlign="center" reversed>
                            {/* {lowerArchPermanentTeeth()} */}
                        </Stack>
                    </Stack>
                    <Stack
                        horizontal
                        horizontalAlign="center"
                        tokens={{ childrenGap: 12 }}
                        styles={{ root: { marginLeft: 105 } }}
                    >
                        <DefaultButton text="LR" onClick={setLowerRightQuad} />
                        <DefaultButton text="LL" onClick={setLowerLeftQuad} />
                    </Stack>
                </Stack>
            </Stack>
        </Panel>
    );
}

export default AreaPanel;
