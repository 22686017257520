import { IBillingProcedure } from 'api/models/billing-procedure.model';
import EncountersDetailsList from './EncountersDetailsList';
import IWorkListEncounterView from 'api/models/worklist-encounter-view';
import WorkListBuilder, { WorkListBuilderProps } from '../WorkListBuilder';
import { classicDateOnly } from 'utils/dateOnly';
import { RecentWorkListItem } from 'utils/appLocalStorage';

type Props = Pick<
    WorkListBuilderProps<IWorkListEncounterView, IBillingProcedure>,
    'workLists' | 'upperContent' | 'commandBarProps' | 'canSelectItem' | 'onRenderCheckBox'
>;

export function getRecentEncounterViewItemToAdd(data: Partial<IWorkListEncounterView>): RecentWorkListItem {
    return {
        id: data.encounter?.id ?? '',
        displayName: data.encounterDate
            ? `${classicDateOnly(data.encounterDate)} - ${`${data.patientLastName}, ${data.patientFirstName}`}`
            : 'Unknown Encounter',
    };
}

export default function EncounterWorkListComponent(props: Props) {
    return (
        <WorkListBuilder<IWorkListEncounterView, IBillingProcedure> {...props}>
            {(detailsListData) => {
                if (!detailsListData) return null;
                return <EncountersDetailsList {...detailsListData} />;
            }}
        </WorkListBuilder>
    );
}
