import { createSelector } from '@reduxjs/toolkit';
import { IProblem } from 'api/models/patient-problem.model';
import { map, sortBy } from 'lodash';
import { RootState } from 'state/store';
import { ProblemsState } from './problems.state';

export const selectProblems = (state: RootState): ProblemsState => state.problems;
export const selectHealthHistoryProblemsData = createSelector(selectProblems, (state) => state.healthHistoryProblemsData);
export const selectHealthHistoryProblemsAsList = createSelector(selectProblems, (state) => {
    const problems = (map(state.healthHistoryProblemsData, (data) => data) as IProblem[]).filter(
        (problem) => !problem.isDeleted && problem.isCritical === false,
    );

    return sortBy(problems, ['displayName'], ['desc']);
});

export const selectCriticalHealthHistoryProblemsAsList = createSelector(selectProblems, (state) => {
    const problems = (map(state.healthHistoryProblemsData, (data) => data) as IProblem[]).filter(
        (problem) => !problem.isDeleted && problem.isCritical === true,
    );

    return sortBy(problems, ['displayName'], ['desc']);
});

export const selectHealthHistoryProblemsLoading = createSelector(selectProblems, (state) => state.loadingHealthHistoryProblems);
