import { Dictionary } from '@reduxjs/toolkit';
import { DocumentBase } from 'api/document-base.model';
import {
    ChangeAuditSupport,
    DisplayNameSupport,
    EncounterSupport,
    ETagSupport,
    IdSupport,
    SoftDeleteSupport,
} from './support-types';

export interface IPatientAllergies extends DocumentBase {
    references?: Record<string, string>;
    nkda: boolean;
    lastUpdated?: string;
    allergies?: IAllergy[];
    lastModifiedBy?: string;
    noteLastModifiedDateTime?: string;
    noteLastModifiedBy?: string;
}

type AllergySupports = EncounterSupport & SoftDeleteSupport;
export interface IAllergy extends AllergySupports {
    encounterId?: string;
    categories?: string[];
    reactions?: IReaction[];
    allergenId: string;
    id: string; //elwood lookup id
    allergenName?: string;
    onsetDate: string;
    code?: string;
    criticality?: string;
}

export interface IReaction {
    name?: string;
    snomedcode: number;
    severitySnomedcode?: number;
    severity?: ReactionSeverity;
    isDeleted: boolean;
}

type AllergyReactionSupports = IdSupport & ETagSupport & ChangeAuditSupport & SoftDeleteSupport & DisplayNameSupport;
export interface IAllergyReaction extends AllergyReactionSupports {
    name?: string;
    snomedCode: number;
    description?: string;
    isActive: boolean;
    references?: Dictionary<unknown>[];
}

type AllergenSupports = IdSupport & ETagSupport & ChangeAuditSupport & SoftDeleteSupport & DisplayNameSupport;

export interface IAllergenReferences {
    athenaOne?: string;
}

export interface IAllergen extends AllergenSupports {
    name?: string;
    description?: string;
    snomedCode?: string;
    isActive: boolean;
    references?: IAllergenReferences;
}

export default IPatientAllergies;

export enum ReactionSeverity {
    Mild = 'mild',
    MildModerate = 'mild to moderate',
    Moderate = 'moderate',
    ModerateSevere = 'moderate to severe',
    Severe = 'severe',
    Fatal = 'fatal',
}
/**
 * Tenant: {
 *  Allergies: {
 *     severities: {loading, data},
 *     reactions: [{}],
 *     data: [{}],
 *  }
 * }
 */
