import LookupBase from './lookup-base.model';

export default interface IImagingSource extends LookupBase {
    urlFormat?: string;
    baseUrl?: string;
    appPath?: string;
    dropFolderPath?: string;
    imagingFolderPath?: string;
    genericImagingSourceId?: string;
}

export enum ImagingSourceType {
    Remote = 'Remote',
    Web = 'Web',
    Local = 'Local',
}

export interface IImagingSourceGeneric extends LookupBase {
    type?: ImagingSourceType;
    defaultDropFolderPath?: string;
    defaultAppPath?: string;
    defaultImagingFolderPath?: string;
    description?: string;
}
