import usePermissions, { DentalPermissions } from 'hooks/store/usePermissions';
import { RouteParams } from 'interfaces/route-params';
import { Redirect, useParams } from 'react-router';

const Landing = () => {
    const { tenantId } = useParams<RouteParams>();
    const [hasClinicalHuddle] = usePermissions([DentalPermissions.ClinicalHuddle]);
    const [hasAdminHuddle] = usePermissions([DentalPermissions.AdminHuddle]);

    return (
        <>
            {hasClinicalHuddle && <Redirect to={`/${tenantId}/clinical`} />}
            {hasAdminHuddle && <Redirect to={`/${tenantId}/administrative`} />}
        </>
    );
};

export default Landing;
