import { DocumentBase } from 'api/document-base.model';

export enum DentitionMode {
    Primary = 'primary',
    Permanent = 'permanent',
    PrimarySupernumerary = 'primary-supernumerary',
    PermanentSupernumerary = 'permanent-supernumerary',
}

export interface IChartDentition extends DocumentBase {
    dentitionMode?: keyof typeof DentitionMode;
    facialDegree: number;
    occlusalDegree: number;
    xPosition: number;
    yPosition: number;
}
