import { Stage } from '@inlet/react-pixi/legacy';
import { SurfacesNumber } from 'api/models/perio-exam.model';
import { useSelector } from 'hooks';
import { find, forEach } from 'lodash';
import { Provider } from 'react-redux';
import { createSelector } from 'reselect';
import {
    currentPerioExamTeeth,
    IExtendedPerioTooth,
    selectArchType,
} from 'state/slices/charting/perio-exams/perio-exams.selectors';
import store from 'state/store';
import {
    UPPER_POSITIONS,
    STAGE_WIDTH,
    STAGE_HEIGHT,
    LINE_SPACING,
    SPRITE_CROWN_HEIGHT,
    LOWER_POSITIONS,
} from '../perio-settings';
import Arch from './PerioCanvas/Arch';

const gridLineSelector = createSelector(currentPerioExamTeeth, selectArchType, (perioTeeth, archType) => {
    const facialProbing: number[][] = [];
    const lingualProbing: number[][] = [];
    const facialGingivalMargin: number[][] = [];
    const lingualGingivalMargin: number[][] = [];
    const positions = archType === 'Maxillary' ? UPPER_POSITIONS : LOWER_POSITIONS;

    forEach(positions, (position) => {
        const tooth = find(perioTeeth, ['position', position]);
        if (tooth) {
            facialProbing.push(returnValuesAsArray(tooth, tooth.facial?.probing));
            lingualProbing.push(returnValuesAsArray(tooth, tooth.lingual?.probing));
            facialGingivalMargin.push(returnValuesAsArray(tooth, tooth.facial?.gingivalMargin));
            lingualGingivalMargin.push(returnValuesAsArray(tooth, tooth.lingual?.gingivalMargin));
        } else {
            facialProbing.push([0, 0, 0]);
            lingualProbing.push([0, 0, 0]);
            facialGingivalMargin.push([0, 0, 0]);
            lingualGingivalMargin.push([0, 0, 0]);
        }
    });
    return { facialProbing, lingualProbing, facialGingivalMargin, lingualGingivalMargin };
});

function returnValuesAsArray(tooth: IExtendedPerioTooth, dataField?: SurfacesNumber) {
    if (dataField) {
        const { distal, middle, mesial } = dataField;
        if (tooth.position < 9 || tooth.position > 24) {
            return [distal || 0, middle || 0, mesial || 0];
        } else {
            return [mesial || 0, middle || 0, distal || 0];
        }
    } else {
        return [0, 0, 0];
    }
}

function PerioCanvas(): JSX.Element {
    const { facialProbing, lingualProbing, lingualGingivalMargin, facialGingivalMargin } = useSelector(gridLineSelector);
    const upperY = 0 + UPPER_POSITIONS.length * LINE_SPACING;
    const upperScaling = { x: 1, y: -1 };

    const lowerY = STAGE_HEIGHT / 2 + SPRITE_CROWN_HEIGHT * 2;
    const lowerScaling = { x: 1, y: 1 };

    return (
        <Stage width={STAGE_WIDTH} height={STAGE_HEIGHT} options={{ backgroundColor: 0xeef1f5 }}>
            <Provider store={store}>
                <Arch
                    container={{ y: lowerY, scale: lowerScaling }}
                    probingDepths={lingualProbing}
                    marginalDepths={lingualGingivalMargin}
                />
                <Arch
                    container={{ y: upperY, scale: upperScaling }}
                    probingDepths={facialProbing}
                    marginalDepths={facialGingivalMargin}
                />
            </Provider>
        </Stage>
    );
}

export default PerioCanvas;
