import { getTheme, IPalette, ITheme } from '@fluentui/react';
import { ITooth } from 'api/models/tooth.model';
import { map } from 'lodash';
import * as PIXI from 'pixi.js-legacy';
import { settings } from './settings';
import spriteSheets, { SpriteReference, toothPolygons } from './spriteList';
import Tooth from './Tooth';

export class ChartApp extends PIXI.Application {
    public static theme: ITheme | undefined;
    public static themeMode: 'light' | 'dark' | undefined;

    public _data?: ITooth[] = [];
    public _onTeethInitialized?: () => void;

    private onToothHover?: (isHovering: boolean, data: ITooth, ref: SpriteReference, position: { x: number; y: number }) => void;
    private _mainContainer: PIXI.Container = new PIXI.Container();

    public startApp(
        callback?: () => void,
        onToothHover?: (isHovering: boolean, data: ITooth, ref: SpriteReference, position: { x: number; y: number }) => void,
    ): void {
        this.onToothHover = onToothHover;

        if ((!(window as any).PIXI || !map(PIXI.Loader.shared.resources).length) && typeof callback === 'function') {
            PIXI.Loader.shared.destroy();
            // PIXI.utils.destroyTextureCache();
            if (!PIXI.Loader.shared.loading) {
                spriteSheets.forEach((sheet, i) => {
                    PIXI.Loader.shared.add(`atlas${i}`, sheet);
                });
                PIXI.Loader.shared.add('toothPolys', toothPolygons);
                PIXI.Loader.shared.load(() => {
                    callback();
                    (window as any).PIXI = PIXI;
                });
            }
        }
        if (!this.ticker.started) {
            this.start();
            ChartApp.theme = getTheme();
        }
        this._mainContainer.pivot.set(0.5);

        this._mainContainer.interactive = true;
    }

    public stopApp = (callback?: () => void): void => {
        if (callback) callback();
        this.stop();
    };

    public resizeView(width?: number, height?: number): void {
        if (width && height) {
            this._mainContainer.scale.set(Math.min(width / settings.logicalWidth, height / settings.logicalHeight));
            this._mainContainer.y = -15;
            if (this._mainContainer.x >= 0) this._mainContainer.x = width / 2 - this._mainContainer.width / 2;

            this.renderer.view.height = height;
            this.renderer.view.width = width;

            // Set initial resolution.
            if (!this.renderer.resolution)
                this.renderer.resolution = Math.min(width / settings.logicalWidth) / Math.min(height / settings.logicalHeight);
        }
    }

    public static getThemePalleteItem(key: keyof IPalette): number {
        return parseInt(ChartApp.theme ? ChartApp.theme.palette[key].replace(/^#/, '') : '', 16);
    }

    public renderChart(data?: ITooth[], canvasSize?: { width?: number; height?: number }): void {
        this._data = data;

        this.cleanupMainContainer();
        this.createTeeth();
        this.addContainerToStage();

        if (canvasSize && canvasSize.width && canvasSize.height) this.resizeView(canvasSize.width, canvasSize.height);
    }

    public changeTheme(theme: ITheme | undefined, themeMode?: 'light' | 'dark' | undefined): void {
        if (theme) {
            ChartApp.theme = theme;
            ChartApp.themeMode = themeMode;
            this.renderChart(this._data);
        }
    }

    private cleanupMainContainer() {
        if (this._mainContainer.children.length) this._mainContainer.removeChildren();
    }

    private createTeeth() {
        if (this._data && this._data.length) {
            const generatedTeeth = this._data.map((toothData, index) => {
                return new Tooth(toothData, index, this.onToothHover);
            });

            generatedTeeth.forEach((tooth, index) => {
                const nextTooth = generatedTeeth[index + 1];
                const prevTooth = generatedTeeth[index - 1];

                tooth.setNextTooth(nextTooth);
                tooth.setPreviousTooth(prevTooth);

                tooth.renderTooth();
            });

            this._mainContainer.addChild(...generatedTeeth);
            if (this._onTeethInitialized) this._onTeethInitialized();
        }
    }

    private addContainerToStage() {
        this.stage.addChild(this._mainContainer);
    }
}

PIXI.utils.skipHello();

const chartApp = new ChartApp({
    width: settings.logicalWidth,
    height: settings.logicalHeight,
    antialias: true,
    forceCanvas: false,
    backgroundAlpha: 0,
    autoStart: false,
});

export default chartApp;
