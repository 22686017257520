import { IChartPerioExamRecord } from 'api/models/perio-exam.model';
import useSelector from 'hooks/useSelector';
import { RouteParams } from 'interfaces/route-params';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setFocusedDepthField, setPerioExamHistorySearch } from 'state/slices/charting/chart/chart.slice';
import {
    createPerioExam,
    getPerioExamById,
    getPerioExams,
    setProbingDepth,
    setToothFieldAndUpdate,
    updatePerioExam,
    updateProbingDepth,
} from 'state/slices/charting/perio-exams/perio-exams.actions';
import {
    currentPerioExam,
    currentPerioExamTeeth,
    currentPerioExamToothData,
    selectedPerioDepthFieldData,
    selectPerioExams,
    selectPerioExamsData,
    selectUniqPerioExamsDates,
} from 'state/slices/charting/perio-exams/perio-exams.selectors';
import { ISelectedPerioDepthField } from 'state/slices/charting/perio-exams/perio-exams.state';

function usePerioExams() {
    const dispatch = useDispatch();
    const { loading, saving } = useSelector(selectPerioExams);
    const _currentPerioExamTeeth = useSelector(currentPerioExamTeeth);
    const _currentPerioExam = useSelector(currentPerioExam);
    const _perioExamsData = useSelector(selectPerioExamsData);
    const _selectUniqPerioExamsDates = useSelector(selectUniqPerioExamsDates);
    const _currentPerioExamToothData = (position: string) => useSelector((state) => currentPerioExamToothData(state, position));

    const _selectedPerioDepthFieldData = useSelector(selectedPerioDepthFieldData);

    const { tenantId, patientId, encounterId } = useParams<RouteParams>();

    const _getPerioExams = useCallback(() => {
        dispatch(getPerioExams({ tenantId, patientId }));
    }, [dispatch, tenantId, patientId]);

    const _createPerioExam = useCallback(() => {
        if (encounterId) {
            dispatch(createPerioExam({ tenantId, patientId, encounterId }));
        }
    }, [dispatch, tenantId, patientId, encounterId]);

    const _setToothFieldAndUpdate = useCallback(
        (props: {
            toothId: number;
            value: any;
            surface?: 'distal' | 'mesial' | 'middle';
            arch: 'facial' | 'lingual';
            perioField: keyof IChartPerioExamRecord;
        }) => {
            dispatch(setToothFieldAndUpdate({ ...props, tenantId, patientId }));
        },
        [dispatch, tenantId, patientId],
    );

    const _setProbingDepth = useCallback(
        ({ value }: { value: number }) => {
            dispatch(setProbingDepth({ value, tenantId, patientId }));
        },
        [dispatch, tenantId, patientId],
    );

    const _updateProbingDepth = useCallback(
        (toothId: number, isfacial: boolean, surface: 'distal' | 'middle' | 'mesial', value: number | undefined) => {
            dispatch(updateProbingDepth(tenantId, patientId, toothId, isfacial, surface, value));
        },
        [dispatch, tenantId, patientId],
    );

    const _updatePerioExam = useCallback(() => {
        dispatch(updatePerioExam({ tenantId, patientId, perioExam: _currentPerioExam }));
    }, [dispatch, tenantId, patientId, _currentPerioExam]);

    const _getPerioExamById = useCallback(
        (procedureId: string) => {
            dispatch(getPerioExamById({ tenantId, patientId, procedureId }));
        },
        [dispatch, tenantId, patientId],
    );

    const _setFocusedDepthFieldData = useCallback(
        (data: ISelectedPerioDepthField | undefined) => {
            dispatch(setFocusedDepthField(data));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [dispatch],
    );

    const _setPerioExamHistorySearch = useCallback(
        (search: string | undefined) => {
            dispatch(setPerioExamHistorySearch(search));
        },
        [dispatch],
    );

    return {
        perioExams: _perioExamsData,
        loading,
        saving,

        setToothFieldAndUpdate: _setToothFieldAndUpdate,
        currentPerioExam: _currentPerioExam,
        currentPerioExamTeeth: _currentPerioExamTeeth,
        currentPerioExamToothData: _currentPerioExamToothData,
        setProbingDepth: _setProbingDepth,
        selectedPerioDepthFieldData: _selectedPerioDepthFieldData,
        getPerioExams: _getPerioExams,
        createPerioExam: _createPerioExam,
        updatePerioExam: _updatePerioExam,
        getPerioExamById: _getPerioExamById,
        updateProbingDepth: _updateProbingDepth,
        setFocusedDepthFieldData: _setFocusedDepthFieldData,
        selectUniqPerioExamsDates: _selectUniqPerioExamsDates,
        setPerioExamHistorySearch: _setPerioExamHistorySearch,
    };
}

export default usePerioExams;
