import IPatient, { PatientStatus } from 'api/models/patient.model';
import { Middleware } from 'redux';
import { setLocationArray, setProviderArray } from 'state/slices/clinical-huddle/clinical-huddle.slice';
import { updatePatient } from 'state/slices/edit-patient/edit-patient.actions';
import { fetchPatient, FetchPatientPayload } from 'state/slices/patient/patient.actions';
import { toggleFilterOperatory } from 'state/slices/scheduling/scheduling.slice';
import {
    setActivityBarExpanded,
    setPatientBannerExpanded,
    setSchedulingToolBarExpanded,
    setTheme,
} from 'state/slices/ui/ui.slice';
import appLocalStorage from 'utils/appLocalStorage';

import { RootState } from '../store';

const localStorageMiddleware: Middleware<
    unknown, // Most middleware do not modify the dispatch return value
    RootState
> = (storeApi) => (next) => (action) => {
    if (action) {
        switch (action.type) {
            case fetchPatient.fulfilled.type: {
                const { patient } = action.payload as FetchPatientPayload;
                switch (patient.patientStatus) {
                    case PatientStatus.Merged: {
                        appLocalStorage.removeMergedPatient(patient);
                        break;
                    }
                    case PatientStatus.Deleted: {
                        appLocalStorage.removeMergedPatient(patient);
                        break;
                    }
                    default: {
                        appLocalStorage.addRecentPatient(patient);
                        break;
                    }
                }

                break;
            }
            case updatePatient.fulfilled.type: {
                const patient = action.payload as IPatient;
                appLocalStorage.addRecentPatient(patient);
                break;
            }
            case setTheme.type: {
                appLocalStorage.theme = action.payload;
                break;
            }
            case setPatientBannerExpanded.type: {
                appLocalStorage.patientBannerExpanded = action.payload;
                break;
            }
            case setSchedulingToolBarExpanded.type: {
                appLocalStorage.schedulingToolbarExpanded = action.payload;
                break;
            }
            case setActivityBarExpanded.type: {
                appLocalStorage.activityBarExpanded = action.payload;
                break;
            }
            case toggleFilterOperatory.type: {
                appLocalStorage.toggleFilterOperatory = action.payload;
                break;
            }
            case setProviderArray.type: {
                appLocalStorage.clinicHuddleProvider = action.payload;
                break;
            }
            case setLocationArray.type: {
                appLocalStorage.clinicHuddleLocation = action.payload;
                break;
            }
        }
        try {
            next(action);
        } catch (err) {
            console.error('Something wrong with action', err, action);
        }
    }
};

export default localStorageMiddleware;
