import { useId } from '@uifabric/react-hooks';
import { IBillingProcedure, ICarcRarcAdjustmentLine } from 'api/models/billing-procedure.model';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';

const reasonCodes: ISortableColumn<IBillingProcedure> = {
    key: 'reasonCodes',
    name: 'Reason Codes',
    minWidth: 150,
    onRender: CarcRarcDetailColumn,
};

function CarcRarcDetailColumn(item: IBillingProcedure | undefined) {
    const id = useId();

    const carcRarcLineData = item?.carcRarcInfos
        ?.map((info) => info.adjustmentLines?.map((line) => line))
        .flat()
        .filter((item) => !!item) as ICarcRarcAdjustmentLine[];

    const codes = carcRarcLineData.map((line, index) => (
        <span key={`${id}-${index}`} title={`${line.reasonCode ?? ''}${line.description ? `: ${line.description}` : ''}`}>{`${
            line.reasonCode ?? ''
        }${index !== carcRarcLineData.length - 1 && carcRarcLineData.length > 1 ? ', ' : ''}`}</span>
    ));

    return <>{codes}</>;
}

export default reasonCodes;
