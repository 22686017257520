import { Link, Stack, Text, TooltipHost } from '@fluentui/react';
import ILocationOfCare from 'api/models/Scheduling/locationsOfCare.model';
import IOperatory from 'api/models/Scheduling/operatory.model';
import { Timetable } from 'api/models/provider.model';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import StatusCircle from 'components/StatusCircle';
import { isAfter, isBefore } from 'date-fns';
import useLookupsStore from 'hooks/store/useLookupsStore';
import { LoadingStatus } from 'interfaces/loading-statuses';
import WeekSchedule from 'pages/components/WeekSchedule/WeekSchedule';
import { useEffect } from 'react';
import LookupDetailsList from '../Shared/LookupDetailsList';

import { useSelector, useTenantId } from 'hooks';
import { toggleActiveOperatory } from 'state/slices/lookups/operatories/operatories.selectors';

type DetailsListProps = {
    searchTerm: string;
    active: boolean;
};
/**
 *
 *
 * @param {DetailsListProps} { searchTerm }
 * @return {*}  {JSX.Element}
 */
function DetailsList({ searchTerm, active }: DetailsListProps): JSX.Element {
    const { lookupsList: locationsOfCareList, getLookups: getLocationsOfCare } =
        useLookupsStore<ILocationOfCare>('LocationsOfCare');
    const { setSelectedLookup } = useLookupsStore<ILocationOfCare>('Operatories');
    const tenantId = useTenantId();
    const operatories = useSelector((state) => toggleActiveOperatory(state, tenantId));

    useEffect(() => {
        getLocationsOfCare();
    }, [getLocationsOfCare]);

    const renderWeekSchedule = (item: IOperatory | undefined) => {
        if (item && item.periods) {
            let schedule: Timetable | undefined = undefined;
            item.periods.forEach((period) => {
                const today = new Date();
                const start = new Date(period.startDate);
                const end = new Date(period.endDate);
                const _isAfter = isAfter(today, start);
                const _isBefore = isBefore(today, end);
                if (_isAfter && _isBefore) {
                    schedule = period.timetables;
                }
            });
            return <WeekSchedule schedule={schedule} compact readOnly operators={item} />;
        }
        return '';
    };

    const onItemInvoked = (item?: IOperatory) => {
        setSelectedLookup({ item, savingStatus: LoadingStatus.Idle });
    };

    const filterHandler = (item?: IOperatory): boolean => {
        searchTerm = searchTerm.toLowerCase();
        return item?.displayName ? item.displayName.toLowerCase().includes(searchTerm) : false;
    };

    const columns: ISortableColumn<IOperatory>[] = [
        {
            key: 'displayOrder',
            fieldName: 'displayOrder',
            name: 'Order',
            minWidth: 50,
            maxWidth: 70,
            onRender: (item) => (
                <Text variant="smallPlus">{item?.isDeleted ? <i>{item?.displayOrder}</i> : item?.displayOrder}</Text>
            ),
        },
        {
            key: 'displayName',
            fieldName: 'displayName',
            name: 'Name',
            minWidth: 100,
            maxWidth: 320,
            onRender: (item) => (
                <Text variant="smallPlus">
                    <Link
                        onClick={() => {
                            setSelectedLookup({ item, savingStatus: LoadingStatus.Idle });
                        }}
                    >
                        {item?.isDeleted ? <i>{item?.displayName}</i> : item?.displayName}
                    </Link>
                </Text>
            ),
        },
        {
            key: 'schedule',
            name: 'Schedule',
            minWidth: 200,
            onRender: renderWeekSchedule,
        },
        {
            key: 'locationOfCare',
            fieldName: 'locationOfCareId',
            name: 'Location of Care',
            getValueKey: (item) => {
                const locationOfCare = locationsOfCareList.find((locationOfCare) => locationOfCare.id === item?.locationOfCareId);
                return locationOfCare?.displayName ?? '';
            },
            minWidth: 200,
            onRender: (item) => {
                const locationOfCare = locationsOfCareList.find((locationOfCare) => locationOfCare.id === item?.locationOfCareId);
                return (
                    <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 6 }}>
                        <Text variant="smallPlus">
                            {item?.isDeleted ? <i>{locationOfCare?.displayName}</i> : locationOfCare?.displayName}
                        </Text>
                    </Stack>
                );
            },
        },
        {
            key: 'tags',
            fieldName: 'tags',
            getValueKey: (item) => (item?.tags ?? [])[0],
            name: 'Tag(s)',
            minWidth: 220,
            onRender: (item) => <span title={item?.tags?.join(', ')}>{item?.tags?.join(', ')}</span>,
        },
        {
            key: 'description',
            fieldName: 'description',
            name: 'Description',
            minWidth: 200,
        },
        {
            key: 'status',
            fieldName: 'status',
            name: 'Status',
            minWidth: 100,
            getValueKey: (item) => (item?.isDeleted ? 'Disabled' : 'Active'),
            onRender: (item) => (
                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 6 }}>
                    <StatusCircle isActive={!item?.isDeleted} />
                    <TooltipHost id={item?.id} content={item?.id}>
                        <Text variant="smallPlus" style={{ cursor: 'help' }}>
                            {item?.isDeleted ? 'Disabled' : 'Active'}
                        </Text>
                    </TooltipHost>
                </Stack>
            ),
        },
    ];

    return (
        <LookupDetailsList<IOperatory>
            lookupType="Operatories"
            columns={columns}
            sortOnMount={true}
            mountSortColumn={['displayOrder', 'displayName']}
            filterHandler={filterHandler}
            searchTerm={searchTerm}
            items={!active ? operatories : operatories.filter((operatory) => !operatory.isDeleted)}
        />
    );
}

export default DetailsList;
