import { IconButton, MessageBar, SelectionMode } from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import { IReportingCategory } from 'api/models/reporting-group.model';
import { SortableDetailsList } from 'components';
import { EditDetailsColumn } from 'components/EditDetailsColumn';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import { useTenantId } from 'hooks';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteTenantReportingCategory } from 'state/slices/reporting/reporting-groups/reporting-groups.actions';
import {
    reportingCategoriesLoading,
    selectedReportingGroupCategoriesList,
    selectSelectedReportingGroup,
} from 'state/slices/reporting/reporting-groups/reporting-groups.selectors';
import { setSelectedReportingCategory } from 'state/slices/reporting/reporting.slice';
import DeleteSelectedReportGroupCategoryDialog from './DeletedReportingGroupDialog';

export default function ReportingCategoryList(): JSX.Element | null {
    const _selectedReportingGroupCategoriesList = useSelector(selectedReportingGroupCategoriesList);
    const _selectedReportingGroup = useSelector(selectSelectedReportingGroup);
    const tenantId = useTenantId();
    const _loading = useSelector(reportingCategoriesLoading);
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [selectedDeletedCategory, setDeleteReportingCategory] = useState<IReportingCategory | undefined>(undefined);
    const dispatch = useDispatch();

    const deleteReportingGroupCategory = () => {
        toggleHideDialog();
    };

    const _deleteReportingGroupCategory = () => {
        if (selectedDeletedCategory?.id) {
            toggleHideDialog();
            dispatch(deleteTenantReportingCategory({ tenantId, model: selectedDeletedCategory }));
            setDeleteReportingCategory(undefined);
        }
    };

    const columns: ISortableColumn<IReportingCategory>[] = [
        {
            key: 'displayName',
            name: 'Category Name',
            minWidth: 70,
            fieldName: 'displayName',
            onRender: (item) => {
                return (
                    <EditDetailsColumn
                        title={item?.displayName ?? ''}
                        titleProps={{ variant: 'small' }}
                        editOnClick={() => {
                            if (item) {
                                dispatch(setSelectedReportingCategory(item));
                            }
                        }}
                    />
                );
            },
        },
        {
            key: 'delete',
            name: 'Delete',
            minWidth: 70,
            maxWidth: 200,

            onRender: (item) => {
                return (
                    // eslint-disable-next-line react/jsx-no-undef
                    <IconButton
                        onClick={() => {
                            if (item) {
                                setDeleteReportingCategory(item);
                                deleteReportingGroupCategory();
                            }
                        }}
                        iconProps={{ iconName: 'Delete' }}
                    />
                );
            },
        },
    ];

    if (!_selectedReportingGroupCategoriesList?.length)
        return <MessageBar>No {_selectedReportingGroup?.displayName ?? 'Unknown'} categories exist.</MessageBar>;

    return (
        <>
            <SortableDetailsList<IReportingCategory>
                enableShimmer={_loading === LoadingStatus.Pending}
                columns={columns}
                items={_selectedReportingGroupCategoriesList ?? []}
                selectionPreservedOnEmptyClick={true}
                selectionMode={SelectionMode.none}
                sortOnMount
                sortColumns={['displayName']}
                initialSortDirection={['asc']}
            />

            <DeleteSelectedReportGroupCategoryDialog
                toggleHideDialog={toggleHideDialog}
                hideDialog={hideDialog}
                deleteReportingGroupCategory={_deleteReportingGroupCategory}
                setDeleteReportingCategory={setDeleteReportingCategory}
            />
        </>
    );
}
