import { RootState } from 'state/store';
import { ProcedureNoteTemplate } from './CompletedProcedures';

export default function ReferredProcedures({ state }: { state: RootState }): JSX.Element | null {
    const procedures = state.chartNotes.quickNotes.assets.chartProcedures.referred;
    if (procedures.length <= 0) return null;
    return (
        <>
            <h3>Referred Procedures</h3>
            <ProcedureNoteTemplate chartProcedures={procedures} state={state} />
        </>
    );
}
