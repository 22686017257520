import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import IPatientContact from 'api/models/patient-contact.model';
import axios, { AxiosResponse } from 'axios';
import { LoadingStatus, LoadingStatuses } from 'interfaces/loading-statuses';
import { cloneDeep, some } from 'lodash';
import ErrorTypes from 'state/errorTypes';
import { AppThunk, RootState } from 'state/store';
import { v4 as uuid } from 'uuid';

type ContactsState = {
    data?: IPatientContact[];
    loading: LoadingStatuses;
    contact: {
        data?: IPatientContact;
        index?: number;
        loading: LoadingStatuses;
    };
    phoneSearch: {
        data?: IPatientContact[];
        loading: LoadingStatuses;
        query: string;
    };
    error?: ErrorTypes;
    openContactModal: boolean;
};
const initialState: ContactsState = {
    data: undefined,
    loading: LoadingStatus.Idle,
    contact: {
        data: undefined,
        loading: LoadingStatus.Idle,
    },
    phoneSearch: {
        data: undefined,
        loading: LoadingStatus.Idle,
        query: '',
    },
    openContactModal: false,
};

export const getPatientContact = createAsyncThunk<
    IPatientContact,
    { tenantId: string; contactId: string; parentId: string },
    { rejectValue: string }
>(
    'getPatientContact',
    async ({ tenantId, contactId, parentId }: { tenantId: string; contactId: string; parentId: string }, { rejectWithValue }) => {
        try {
            const res = await dentalApi.getPatientContact(tenantId, parentId, contactId);
            return res.data;
        } catch (err) {
            if (axios.isAxiosError(err)) {
                if (err.response?.status === 503) return rejectWithValue(ErrorTypes.ServiceUnavailable);
                return rejectWithValue(err.message);
            } else {
                return rejectWithValue('Unkonwn error');
            }
        }
    },
);


export const getAllPatientContacts = createAsyncThunk(
    'getAllPatientContacts',
    async ({ tenantId, patientId }: { tenantId: string; patientId: string }) => {
        const res = await dentalApi.getAllPatientContacts(tenantId, patientId);
        return res.data;
    },
);

export const getPatientContacts = createAsyncThunk(
    'getPatientContacts',
    async ({ tenantId, query }: { tenantId: string; query: string }) => {
        const res = await dentalApi.getPatientContacts(tenantId, query);
        return res.data;
    },
);

const contactsSlice = createSlice({
    name: 'contacts',
    initialState,
    reducers: {
        setContact: (state, action: PayloadAction<IPatientContact | undefined>) => {
            const contact = action.payload;
            state.contact.data = contact;
        },
        upsertPatientContact: (state, action: PayloadAction<{ contact: IPatientContact; patientId: string }>) => {
            const { contact, patientId } = action.payload;
            if (state.data && contact.patientId === patientId) {
                const index = state.data.findIndex((c) => c.id === contact.id);
                if (index !== -1) {
                    state.data[index] = contact;

                    if (state.contact.data?.id === contact.id) {
                        state.error = ErrorTypes.ModifiedOutside;
                    }
                } else {
                    state.data = [...state.data, contact];
                }
            }
        },
        setOpenContactModel: (state, action: PayloadAction<boolean>) => {
            state.openContactModal = action.payload;
        },
        setIndex: (state, action: PayloadAction<number | undefined>) => {
            state.contact.index = action.payload;
        },
        cleanupPhoneSearch: (state) => {
            state.phoneSearch.data = undefined;
            state.phoneSearch.loading = LoadingStatus.Idle;
            state.phoneSearch.query = '';
        },
        setPhoneSearchQuery: (state, action) => {
            state.phoneSearch.query = action.payload;
        },
        setPatientContacts: (state, action) => {
            state.data = action.payload;
        },
        cleanupPatientContactsError: (state) => {
            state.error = undefined;
        },
        cleanupPatientContacts: (state) => {
            state.data = initialState.data;
            state.loading = initialState.loading;
            state.contact.data = undefined;
            state.contact.index = undefined;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPatientContact.pending, (state) => {
                state.contact.loading = LoadingStatus.Pending;
            })
            .addCase(getPatientContact.fulfilled, (state, action) => {
                state.contact.loading = LoadingStatus.Completed;
                state.contact.data = action.payload;
                state.error = undefined;
            })
            .addCase(getPatientContact.rejected, (state) => {
                state.contact.loading = LoadingStatus.Failed;
            })
            .addCase(getAllPatientContacts.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(getAllPatientContacts.fulfilled, (state, action) => {
                state.loading = LoadingStatus.Completed;
                state.data = action.payload;
            })
            .addCase(getAllPatientContacts.rejected, (state) => {
                state.loading = LoadingStatus.Failed;
            })
            .addCase(getPatientContacts.pending, (state) => {
                state.phoneSearch.loading = LoadingStatus.Pending;
            })
            .addCase(getPatientContacts.fulfilled, (state, action) => {
                state.phoneSearch.loading = LoadingStatus.Completed;
                state.phoneSearch.data = action.payload;
            })
            .addCase(getPatientContacts.rejected, (state) => {
                state.phoneSearch.loading = LoadingStatus.Failed;
            });
    },
});

const { reducer, actions } = contactsSlice;

export const {
    setContact,
    cleanupPhoneSearch,
    setPhoneSearchQuery,
    setPatientContacts,
    cleanupPatientContacts,
    setIndex,
    upsertPatientContact,
    setOpenContactModel,
    cleanupPatientContactsError,
} = actions;


export const insertContact =
    (contact: IPatientContact, patientId: string): AppThunk<void> =>
        async (dispatch) => {
            dispatch(upsertPatientContact({ contact, patientId }));
        };

export const contactsState = (state: RootState): ContactsState => state.contacts;
export const contactErrorMessages = createSelector(contactsState, (state) => state.error);
export const selectContact = createSelector(contactsState, (state) => state.contact.data);
export const selectContactsLoading = createSelector(contactsState, (state) => state.loading);
export const selectAllPatientContacts = createSelector(contactsState, (state) => state.data);
export const selectActivePatientContacts = createSelector(contactsState, (state) =>
    (state.data ?? []).filter((contact) => !contact.isDeleted),
);
export const openContactModal = createSelector(contactsState, (state) => state.openContactModal);

// search results for phone number
export const selectPhoneSearchPatientContacts = createSelector(contactsState, (state) => state.phoneSearch.data);
export const selectPatientContactsLoading = createSelector(contactsState, (state) => state.phoneSearch.loading);
export const selectPhoneNumberQuery = createSelector(contactsState, (state) => state.phoneSearch.query);

export const selectPatientHasGuarantorInContacts = createSelector(selectActivePatientContacts, (contacts) =>
    some(contacts, (contact) => contact.isGuarantor),
);

export default reducer;
