import { DefaultEffects, IconButton, Label, Stack, TooltipHost, useTheme } from '@fluentui/react';
import { ActivityPage } from 'components';
import { usePerioExams, useSelector } from 'hooks';
import { PatientDetailsBanner, SaveStatusIcon } from 'pages/components';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { cleanupPerioExamsSave, setArchType } from 'state/slices/charting/chart/chart.slice';
import { selectArchType, selectPerioSaveStatus } from 'state/slices/charting/perio-exams/perio-exams.selectors';
import { LOWER_POSITIONS, UPPER_POSITIONS } from '../perio-settings';
import ToothContainer from './EditPerioExam/ToothContainer';
import PerioCanvas from './PerioCanvas';
import PerioToolbar from './PerioToolbar';

export default function EditPerioExam(): JSX.Element {
    const { getPerioExamById, setFocusedDepthFieldData } = usePerioExams();
    const { perioId } = useParams<{ perioId: string }>();
    const { semanticColors, palette } = useTheme();
    const archType = useSelector(selectArchType);
    const saveStatus = useSelector(selectPerioSaveStatus);
    const dispatch = useDispatch();

    useEffect(() => {
        getPerioExamById(perioId);
        return () => {
            setFocusedDepthFieldData(undefined);
            dispatch(cleanupPerioExamsSave());
            dispatch(setArchType('Maxillary'));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setFocusedDepthFieldData, getPerioExamById, perioId]);

    const toothPositions = archType === 'Maxillary' ? UPPER_POSITIONS : LOWER_POSITIONS;
    const upperTeeth = toothPositions.map((position) => (
        <ToothContainer isUpper position={position} key={`tooth-container-${position}-upper`} />
    ));

    const lowerTeeth = toothPositions.map((position) => (
        <ToothContainer position={position} key={`tooth-container-${position}-upper`} />
    ));

    return (
        <ActivityPage title="Edit Perio Exam">
            <PatientDetailsBanner />
            <PerioToolbar />

            <Stack grow horizontalAlign="center" verticalAlign="center" horizontal>
                <Stack
                    horizontal
                    style={{
                        boxShadow: DefaultEffects.elevation16,
                        borderRadius: DefaultEffects.roundedCorner6,
                        overflow: 'hidden',
                    }}
                >
                    <Stack
                        styles={{
                            root: {
                                backgroundColor: palette.neutralLight,
                                borderRight: '1px solid',
                                borderColor: palette.neutralLight,
                            },
                        }}
                    >
                        <Stack grow={1} verticalAlign="center" horizontalAlign="center">
                            <Label>Facial</Label>
                        </Stack>
                        <Stack
                            horizontalAlign="center"
                            tokens={{ childrenGap: 5 }}
                            style={{
                                borderTop: `1px solid ${palette.neutralQuaternary}`,
                                borderBottom: `1px solid ${palette.neutralQuaternary}`,
                                padding: 10,
                            }}
                        >
                            <ArchSwitcherIcon />
                            <SaveStatusIcon saveStatus={saveStatus} />
                        </Stack>
                        <Stack grow={1} verticalAlign="center" horizontalAlign="center">
                            <Label>Lingual</Label>
                        </Stack>
                    </Stack>
                    <PerioLabels />
                    <Stack style={{ padding: '0 10px', backgroundColor: semanticColors.bodyBackground }}>
                        <Stack horizontal>{upperTeeth}</Stack>
                        <Stack>
                            <PerioCanvas />
                        </Stack>
                        <Stack horizontal>{lowerTeeth}</Stack>
                    </Stack>
                </Stack>
            </Stack>
        </ActivityPage>
    );
}

function ArchSwitcherIcon() {
    const archType = useSelector(selectArchType);
    const dispatch = useDispatch();

    const _isMaxillary = archType === 'Maxillary';
    const _icon = _isMaxillary ? 'ChevronDown' : 'ChevronUp';
    const _handleSwitchArch = () => {
        dispatch(setArchType(_isMaxillary ? 'Mandibular' : 'Maxillary'));
    };
    const _tooltipContent = `Switch to ${_isMaxillary ? 'mandibular' : 'maxillary'} arch`;
    return (
        <Stack horizontalAlign="center">
            <Label>{_isMaxillary ? 'Max.' : 'Man.'}</Label>
            <TooltipHost content={_tooltipContent}>
                <IconButton iconProps={{ iconName: _icon }} onClick={_handleSwitchArch} />
            </TooltipHost>
        </Stack>
    );
}

function PerioLabels() {
    const { palette } = useTheme();
    const _labelStyle = {
        height: 20,
        margin: 0,
        padding: 0,
    };
    function LabelStack({ isUpper }: { isUpper?: boolean }) {
        return (
            <Stack style={{ padding: 10 }} tokens={{ childrenGap: 10 }} reversed={!isUpper}>
                <Stack.Item>
                    <Label style={_labelStyle}>Tooth Id</Label>
                </Stack.Item>
                <Stack.Item>
                    <Label style={_labelStyle}>Mobility</Label>
                </Stack.Item>
                <Stack.Item>
                    <Label style={_labelStyle}>Suppuration</Label>
                </Stack.Item>
                <Stack.Item>
                    <Label style={_labelStyle}>Furcation</Label>
                </Stack.Item>
                <Stack.Item>
                    <Label style={_labelStyle}>Plaque</Label>
                </Stack.Item>
                <Stack.Item>
                    <Label style={_labelStyle}>Bleeding</Label>
                </Stack.Item>
                <Stack.Item>
                    <Label style={_labelStyle}>Gingival Margin</Label>
                </Stack.Item>
                <Stack.Item>
                    <Label style={_labelStyle}>Probing Depth</Label>
                </Stack.Item>
            </Stack>
        );
    }
    return (
        <Stack style={{ backgroundColor: palette.neutralLighterAlt, borderRight: `1px solid ${palette.neutralLight}` }}>
            <Stack
                grow={1}
                verticalAlign="start"
                style={{
                    borderBottom: `1px solid ${palette.neutralLight}`,
                }}
            >
                <LabelStack isUpper />
            </Stack>
            <Stack grow={1} verticalAlign="end">
                <LabelStack />
            </Stack>
        </Stack>
    );
}
