import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { PaymentMethod } from 'api/models/patient.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { LedgerState } from '../ledger.state';
import {
    getNegativePaymentReversalReasons,
    getPaymentHistory,
    reverseSelectedPaymentHistoryPaymentSource,
} from './payment-history.actions';
import { PaymentHistoryTableView } from './payment-history.selectors';
import { paymentHistoryInitialState } from './payment-history.state';

export const paymentHistoryReducers = {
    // Modal
    setPaymentHistoryConfirmModalOpen: (state: LedgerState, action: PayloadAction<boolean>): void => {
        state.paymentHistory.confirmModalOpen = action.payload;
    },
    togglePaymentHistoryShowReversals: (state: LedgerState): void => {
        state.paymentHistory.showReversedPayments = !state.paymentHistory.showReversedPayments;
    },
    setPaymentHistoryModalOpen: (state: LedgerState, action: PayloadAction<boolean>): void => {
        state.paymentHistory.confirmModalOpen = action.payload;
    },
    cleanupPaymentHistoryReverseSavedStatusMessageBar: (state: LedgerState): void => {
        state.paymentHistory.reversalSaving = LoadingStatus.Idle;
    },
    cleanupPaymentHistory: (state: LedgerState): void => {
        state.paymentHistory = paymentHistoryInitialState;
    },
    cleanupConfirmNegativeReversal: (state: LedgerState): void => {
        state.paymentHistory.selectedPaymentSources = [];
        state.paymentHistory.negativeReversalReasonId = undefined;
        state.paymentHistory.negativeReversalNote = undefined;
    },
    setSelectPaymentHistoryRows: (state: LedgerState, action: PayloadAction<PaymentHistoryTableView>): void => {
        state.paymentHistory.selectedPaymentSources = action.payload;
    },
    //Filters
    setPaymentHistoryMethod: (state: LedgerState, action: PayloadAction<PaymentMethod | ''>): void => {
        state.paymentHistory.filters['method'] = action.payload;
    },
    setPaymentHistoryMethodIdentifier: (state: LedgerState, action: PayloadAction<string | undefined>): void => {
        state.paymentHistory.filters['methodIdentifier'] = action.payload ?? '';
    },
    setPaymentHistoryDateOfEntry: (state: LedgerState, action: PayloadAction<string | undefined>): void => {
        state.paymentHistory.filters['dateOfEntry'] = action.payload ?? '';
    },
    setPaymentHistoryDateOfService: (state: LedgerState, action: PayloadAction<string | undefined>): void => {
        state.paymentHistory.filters['dateOfService'] = action.payload ?? '';
    },
    setPaymentHistoryTransactionDate: (state: LedgerState, action: PayloadAction<string | undefined>): void => {
        state.paymentHistory.filters['transactionDate'] = action.payload ?? '';
    },
    clearPaymentHistoryFilters: (state: LedgerState): void => {
        state.paymentHistory.filters = paymentHistoryInitialState.filters;
    },
    //Negative Reversal
    setNegativePaymentReversalId: (state: LedgerState, action: PayloadAction<string | undefined>): void => {
        state.paymentHistory.negativeReversalReasonId = action.payload;
    },
    setNegativeReversalNote: (state: LedgerState, action: PayloadAction<string | undefined>): void => {
        state.paymentHistory.negativeReversalNote = action.payload;
    },
};

export const paymentHistoryExtraReducers = (
    builder: ActionReducerMapBuilder<LedgerState>,
): ActionReducerMapBuilder<LedgerState> =>
    builder
        .addCase(getPaymentHistory.pending, (state) => {
            state.paymentHistory.loading = LoadingStatus.Pending;
        })
        .addCase(getPaymentHistory.fulfilled, (state, { payload }) => {
            state.paymentHistory.loading = LoadingStatus.Completed;
            state.paymentHistory.data = payload;
            state.paymentHistory.paymentHistoryError = undefined;
        })
        .addCase(getPaymentHistory.rejected, (state, { error }) => {
            if (error.name !== 'AbortError') {
                state.paymentHistory.loading = LoadingStatus.Failed;
                state.paymentHistory.paymentHistoryError = error;
            } else {
                state.paymentHistory.loading = LoadingStatus.Idle;
            }
        })
        .addCase(reverseSelectedPaymentHistoryPaymentSource.pending, (state) => {
            state.paymentHistory.reversalSaving = LoadingStatus.Pending;
        })
        .addCase(reverseSelectedPaymentHistoryPaymentSource.fulfilled, (state, { payload }) => {
            state.paymentHistory.reversalSaving = LoadingStatus.Completed;
            if (state.paymentHistory.data) {
                const { paymentSources, payments } = payload[0];
                if (state.paymentHistory.data.paymentSources && paymentSources) {
                    state.paymentHistory.data.paymentSources = [...state.paymentHistory.data.paymentSources, ...paymentSources];
                }
                if (state.paymentHistory.data.payments && payments) {
                    state.paymentHistory.data.payments = [...state.paymentHistory.data.payments, ...payments];
                }
            }
            state.paymentHistory.confirmModalOpen = false;
        })
        .addCase(reverseSelectedPaymentHistoryPaymentSource.rejected, (state) => {
            state.paymentHistory.reversalSaving = LoadingStatus.Failed;
            state.paymentHistory.confirmModalOpen = false;
        })
        .addCase(getNegativePaymentReversalReasons.pending, (state) => {
            state.paymentHistory.loadingNegativeReversalReasons = LoadingStatus.Pending;
        })
        .addCase(getNegativePaymentReversalReasons.fulfilled, (state, { payload }) => {
            state.paymentHistory.loadingNegativeReversalReasons = LoadingStatus.Completed;
            state.paymentHistory.negativeReversals = payload;
        })
        .addCase(getNegativePaymentReversalReasons.rejected, (state) => {
            state.paymentHistory.loadingNegativeReversalReasons = LoadingStatus.Failed;
        });
