import { IAdjustmentCommonTransactionView } from 'api/models/adjustment-history.model';
import { IPatientEncounter } from 'api/models/encounter.model';
import { createSelector } from 'reselect';
import { allPatientEncounters } from 'state/slices/patient/patient.selectors';
import { RootState } from 'state/store';
import convertDashedDateString from 'utils/convertDateStringToLocal';
import { classicDateOnly } from 'utils/dateOnly';

export const selectAdjustmentHistoryState = (state: RootState) => state.ledger.adjustmentHistory;

export const selectAdjustmentHistoryLoadingStatus = createSelector(selectAdjustmentHistoryState, ({ loading }) => loading);
export const selectAdjustmentHistoryReversalSavingStatus = createSelector(
    selectAdjustmentHistoryState,
    ({ reversalSaving }) => reversalSaving,
);

export const selectAdjustmentHistoryFilters = createSelector(selectAdjustmentHistoryState, ({ filters }) => filters);
export const selectAdjustmentHistoryFiltersExist = createSelector(
    selectAdjustmentHistoryFilters,
    ({ adjustmentReasonId, amount, dateOfEntry, encounterDate, transactionDate, encounterNumber }) =>
        !!adjustmentReasonId || !!amount || !!dateOfEntry || !!encounterDate || !!transactionDate || !!encounterNumber,
);

export const selectAdjustmentHistoryReverseConfirmationModalOpen = createSelector(
    selectAdjustmentHistoryState,
    ({ confirmModalOpen }) => confirmModalOpen,
);
export const selectAdjustmentHistoryError = createSelector(
    selectAdjustmentHistoryState,
    ({ adjustmentHistoryError }) => adjustmentHistoryError,
);

export const selectAdjustmentHistoryShowReversedAdjustments = createSelector(
    selectAdjustmentHistoryState,
    ({ showReversedAdjustments }) => showReversedAdjustments,
);

export const selectAdjustmentHistoryAdjustmentsList = createSelector(
    selectAdjustmentHistoryState,
    ({ data }) => data?.adjustments ?? [],
);

export const selectAdjustmentHistoryAdjustmentUsers = createSelector(selectAdjustmentHistoryAdjustmentsList, (adjustments) => {
    const users = new Set<string>();

    adjustments.forEach((adjustment) => {
        if (adjustment.createdBy) users.add(adjustment.createdBy);
    });

    return Array.from(users);
});

export const selectAdjustmentHistoryReversedTransactions = createSelector(
    selectAdjustmentHistoryAdjustmentsList,
    (adjustments) => {
        const reversedSources: Record<string, boolean> = {};
        if (adjustments.length) {
            adjustments?.forEach((transaction) => {
                if (transaction.references?.reversedTransactionId) {
                    reversedSources[transaction.references?.reversedTransactionId] = true;
                }
            });

            return reversedSources;
        }

        return reversedSources;
    },
);

export const selectAdjustmentHistoryCommonTransactionViews = createSelector(
    selectAdjustmentHistoryAdjustmentsList,
    allPatientEncounters,
    selectAdjustmentHistoryReversedTransactions,
    selectAdjustmentHistoryShowReversedAdjustments,
    (adjustments, encounters, reversedTransactionIds, showReversedAdjustments) => {
        const encountersById: Record<string, IPatientEncounter | undefined> = {};
        function getEncounterById(encounterId: string | undefined) {
            if (!encounterId) return undefined;
            if (encountersById[encounterId]) {
                return encountersById[encounterId];
            } else {
                const encounter = encounters.find((e) => e.id === encounterId);
                encountersById[encounterId] = encounter;
                return encounter;
            }
        }

        const views = adjustments
            .filter((adjustment) =>
                showReversedAdjustments
                    ? true
                    : !reversedTransactionIds[adjustment.id] && !adjustment.references?.reversedTransactionId,
            )
            .map((adjustment) => {
                const encounter = getEncounterById(adjustment.encounterId);
                const encounterDate = convertDashedDateString(encounter?.encounterDate ?? '');
                return { ...adjustment, encounterDate, encounterNumber: encounter?.encounterNumber };
            });

        return views;
    },
);

export const selectFilteredAdjustmentHistoryCommonTransactionViews = createSelector(
    selectAdjustmentHistoryCommonTransactionViews,
    selectAdjustmentHistoryFilters,
    (views, filters) => {
        const { amount, adjustmentReasonId, encounterNumber } = filters;

        function filterByDate(
            item: IAdjustmentCommonTransactionView,
            dateField: 'dateOfEntry' | 'encounterDate' | 'transactionDate',
        ) {
            const itemField = dateField === 'transactionDate' ? 'createdOn' : dateField;
            return filters[dateField]
                ? (item[itemField] ? classicDateOnly(item[itemField] as string) : '').indexOf(
                      classicDateOnly(filters[dateField]),
                  ) > -1
                : true;
        }

        function filterAmount(v: IAdjustmentCommonTransactionView, amount: number | undefined) {
            if (!amount) return true;

            const searchAmount = amount?.toString();
            const indexOfAmount = v.amount.toString().indexOf(searchAmount);

            return indexOfAmount > -1;
        }

        function filterAdjustmentReason(v: IAdjustmentCommonTransactionView, reasonId: string | number | undefined) {
            if (!reasonId) return true;
            return v.adjustmentReasonId === reasonId;
        }

        function filterEncounterNumber(v: IAdjustmentCommonTransactionView, encounterNumber: string | undefined) {
            if (!encounterNumber) return true;
            return v.encounterNumber ? v.encounterNumber?.indexOf(encounterNumber) > -1 : false;
        }

        return views.filter(
            (v) =>
                filterByDate(v, 'dateOfEntry') &&
                filterByDate(v, 'encounterDate') &&
                filterByDate(v, 'transactionDate') &&
                filterAdjustmentReason(v, adjustmentReasonId) &&
                filterAmount(v, amount) &&
                filterEncounterNumber(v, encounterNumber),
        );
    },
);

export const selectAdjustmentHistorySelectedViews = createSelector(
    selectAdjustmentHistoryState,
    ({ selectedAdjustmentViews }) => selectedAdjustmentViews ?? [],
);
