import { createAsyncThunk } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import IPatientCommunication, { PatientCommunicationNoteType } from 'api/models/patient-communication.model';
import { AppThunk } from 'state/store';
import { v4 as uuid } from 'uuid';
import { cleanupCommunications, setEditCommunication } from './communication.slice';

export const getPatientCommunication = createAsyncThunk(
    'getPatientCommunication',
    async ({ tenantId, patientId, communicationId }: { tenantId: string; patientId: string; communicationId: string }) => {
        const res = await dentalApi.getPatientCommunicationById(tenantId, patientId, communicationId);
        return res.data;
    },
);

export const addPatientCommunication = createAsyncThunk(
    'addPatientCommunication',
    async ({ tenantId, patientId, model }: { tenantId: string; patientId: string; model: IPatientCommunication }) => {
        const res = await dentalApi.addPatientCommunication(tenantId, patientId, model);
        return res.data;
    },
);

export const updatePatientCommunication = createAsyncThunk(
    'updatePatientCommunication',
    async ({ tenantId, parentId, model }: { tenantId: string; parentId: string; model: IPatientCommunication }) => {
        const res = await dentalApi.updatePatientCommunication(tenantId, parentId, model);
        return res.data;
    },
);

export const getAllPatientCommunications = createAsyncThunk(
    'getAllPatientCommunications',
    async ({ tenantId, patientId }: { tenantId: string; patientId: string }) => {
        const res = await dentalApi.getAllPatientCommunication(tenantId, patientId);
        return res.data;
    },
);

export const getPatientCommunicationByEncounterId = createAsyncThunk(
    'getPatientCommunicationByEncounterId',
    async ({ tenantId, patientId, encounterId }: { tenantId: string; patientId: string; encounterId: string }) => {
        const res = await dentalApi.getPatientCommunicationByEncounterId(tenantId, patientId, encounterId);
        return res.data;
    },
);

export const editNewCommunication =
    (patientId: string, noteType: PatientCommunicationNoteType): AppThunk<void> =>
    (dispatch) => {
        const newCommunicationModel: IPatientCommunication = {
            id: uuid(),
            isDeleted: false,
            patientId,
            noteType,
        };
        dispatch(setEditCommunication(newCommunicationModel));
    };

export const addOrUpdateCommunication =
    (tenantId: string, patientId?: string): AppThunk<void> =>
    (dispatch, getState) => {
        if (patientId) {
            const communication = getState().communication.editCommunication;
            if (communication)
                if (communication?._etag) {
                    dispatch(updatePatientCommunication({ tenantId, parentId: patientId, model: communication }));
                } else {
                    dispatch(addPatientCommunication({ tenantId, patientId, model: communication }));
                }
        }
    };
