import { MessageBar } from '@fluentui/react';
import { useSelector } from 'hooks';
import { useDispatch } from 'react-redux';
import { selectVisitSummaryMessageBar } from 'state/slices/admin-huddle/admin-huddle.selectors';
import { cleanupVisitSummaryMessageBar } from 'state/slices/admin-huddle/admin-huddle.slice';

export default function VisitSummaryMessageBar() {
    const dispatch = useDispatch();
    const { messageBar, messageBarType } = useSelector(selectVisitSummaryMessageBar);

    if (!messageBar || !messageBarType) return null;
    return (
        <MessageBar onDismiss={() => dispatch(cleanupVisitSummaryMessageBar())} messageBarType={messageBarType}>
            {messageBar}
        </MessageBar>
    );
}

