import { Link, Stack, Text } from '@fluentui/react';
import { useSelector, useTenantId } from 'hooks';
import { forEach } from 'lodash';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { selectGroupedWorkLists } from 'state/slices/admin-huddle/admin-huddle.selectors';

export function WorkListHeader(): JSX.Element {
    return (
        <Stack tokens={{ childrenGap: 10, padding: 5 }} horizontal verticalAlign="center">
            <HeaderNavigation />
        </Stack>
    );
}

function HeaderNavigation(): JSX.Element | null {
    const tenantId = useTenantId();
    const workListMenu = useSelector((state) => selectGroupedWorkLists(state, tenantId));

    const { push } = useHistory();
    const dashboard = useRouteMatch('/:tenantId/administrative');
    const category = useRouteMatch<{ categoryId: string }>('/:tenantId/administrative/:categoryId');
    const workList = useRouteMatch<{ categoryId: string; workListId: string }>(
        '/:tenantId/administrative/:categoryId/:workListId',
    );

    const titles: Record<string, { displayName: string; path?: string }> = {
        dashboard: { displayName: 'Administrative Overview', path: '' },
        favorites: { displayName: 'My Favorites', path: '/favorites' },
    };

    forEach(workListMenu, (category) => {
        titles[category.id] = { displayName: category.displayName, path: `/${category.id}` };
        forEach(category.workLists, (workList) => {
            titles[workList.id] = {
                displayName: workList.displayName,
            };
        });
    });

    const CrumbLink = ({ titleId }: { titleId: string }): JSX.Element | null => {
        if (!titles[titleId]) return null;
        const _onClick = () => push(`${dashboard?.url}${titles[titleId].path}`);
        return (
            <Text variant="xLarge">
                <Link onClick={_onClick}>{titles[titleId].displayName}</Link>
            </Text>
        );
    };

    const CrumbText = ({ titleId }: { titleId: string }) => {
        if (!titles[titleId]) return null;
        return <Text variant="xLarge">{titles[titleId].displayName}</Text>;
    };
    const Divider = <Text variant="large">/</Text>;

    const Crumbs = (): JSX.Element => {
        if (dashboard?.isExact) {
            return <CrumbText titleId="dashboard" />;
        }
        if (category?.isExact) {
            return (
                <>
                    <CrumbLink titleId="dashboard" />
                    {Divider}
                    <CrumbText titleId={category.params.categoryId} />
                </>
            );
        }
        if (workList?.isExact) {
            const { categoryId, workListId } = workList.params;
            return (
                <>
                    <CrumbLink titleId="dashboard" />
                    {Divider}
                    <CrumbLink titleId={categoryId} />
                    {Divider}
                    <CrumbText titleId={workListId} />
                </>
            );
        }
        return <></>;
    };

    return (
        <Stack horizontal tokens={{ childrenGap: 15 }}>
            <Crumbs />
        </Stack>
    );
}
