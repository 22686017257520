import { Stack, Dropdown, TextField, IDropdownOption } from '@fluentui/react';
import IBlockAppointment from 'api/models/Scheduling/blockAppointment.model';
import IProvider from 'api/models/provider.model';
import { Field } from 'components';
import { ISearchComboBoxOption } from 'components/Field/SearchComboField';
import { sortBy } from 'lodash';
import { FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAppointmentOverviewActiveProviders } from 'state/slices/scheduling/scheduling.selectors';
import { setScheduleBlockAppointmentRootProp, setScheduleAppointmentNotes } from 'state/slices/scheduling/scheduling.slice';
import { getOptionsWithSelectedProvider, selectProvidersData } from 'state/slices/tenant/providers.slice';

//todo: get real block type options
const blockTypeOptions: IDropdownOption[] = [
    { key: 'option-1', text: 'Lunch' },
    { key: 'option-2', text: 'Continuing Education' },
    { key: 'option-3', text: 'Private Appointment' },
    { key: 'option-4', text: 'Meeting' },
    { key: 'option-5', text: 'Other' },
];

type Props = {
    blockAppt?: IBlockAppointment;
};

function BlockAppointmentFields({ blockAppt }: Props): JSX.Element | null {
    const dispatch = useDispatch();
    const providers = useSelector(selectAppointmentOverviewActiveProviders);
    const providerLookup = useSelector(selectProvidersData);

    const _providerOptions: ISearchComboBoxOption[] = getOptionsWithSelectedProvider({
        selectedProviderId: blockAppt?.providers,
        lookup: providerLookup,
        options: providers.map((p) => ({
            key: p.id,
            text: `${p?.lastName} ${p?.suffix ? `${p?.suffix}` : ''}, ${p?.firstName}`,
            groupName: p?.isTreatingProvider
                ? 'Treating Provider'
                : p?.isHygienist
                    ? 'Hygienist'
                    : p?.isResident
                        ? 'Resident'
                        : p?.isRegisteredDentalAssistant
                            ? 'RDA'
                            : undefined,
        })),
    });

    if (!blockAppt) return null;

    const _handleSelectBlockType = (_: FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
        if (item && item.key) dispatch(setScheduleBlockAppointmentRootProp({ path: 'typeId', value: item.key as string }));
    };

    const _handleSelectProvider = (_: FormEvent<HTMLDivElement>, item?: ISearchComboBoxOption) => {
        if (item && item.key) dispatch(setScheduleBlockAppointmentRootProp({ path: 'providers', value: item.key as string }));
    };

    const _handleNotesOnChange = (_: FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
        dispatch(setScheduleAppointmentNotes(value ?? ''));
    };

    return (
        <Stack>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
                <Stack.Item grow>
                    <Dropdown
                        label="Block Type"
                        options={blockTypeOptions}
                        selectedKey={blockAppt.typeId}
                        placeholder="Select Block Type"
                        onChange={_handleSelectBlockType}
                    />
                </Stack.Item>
                <Stack.Item grow>
                    <Field.SearchCombo
                        placeholder="(Select Provider)"
                        label="Providers"
                        multiSelect
                        options={_providerOptions}
                        selectedKey={blockAppt?.providers}
                        onChange={(e, option) => {
                            if (option) {
                                let newProviderIds: string[] = [];
                                if (blockAppt.providers && blockAppt.providers.length) {
                                    const isRaceExist = blockAppt.providers.includes(option.key as string);
                                    if (isRaceExist) {
                                        newProviderIds = blockAppt.providers.filter((id) => id !== option.key);
                                    } else {
                                        newProviderIds = [...blockAppt.providers, option.key as string];
                                    }
                                } else {
                                    newProviderIds = [option.key as string];
                                }
                                dispatch(setScheduleBlockAppointmentRootProp({ path: 'providers', value: newProviderIds }));
                            }
                        }}
                    />
                </Stack.Item>
            </Stack>
            <TextField label="Notes" multiline value={blockAppt.notes} onChange={_handleNotesOnChange} />
        </Stack>
    );
}

export default BlockAppointmentFields;
