import { WorkList } from 'state/slices/admin-huddle/worklists/worklist.state';
import { useSelector } from 'hooks';
import { selectCurrentWorkList } from 'state/slices/admin-huddle/worklists/worklist.selectors';
import { MessageBar } from '@fluentui/react';
import { EncounterWorkList } from 'state/slices/admin-huddle/worklists/encounter-worklists/encounter-worklists.state';
import EncounterWorkListComponent from '../EncounterWorkLists/EncounterWorkList';

type Props = {
    workLists: WorkList[];
};

const lookupOpenBilledEncountersMessageBar: Partial<Record<WorkList, string>> = {
    [EncounterWorkList.BilledInsurance]: 'insurance balance > $0.00.',
    [EncounterWorkList.BilledPatient]: 'patient balance > $0.00.',
};
//Should create wrapper specific to encounter worklist...
export default function OpenBilledEncounters({ workLists: encounterWorkLists }: Props) {
    const encounterWorkList = useSelector(selectCurrentWorkList);

    const messageBarText = encounterWorkList ? lookupOpenBilledEncountersMessageBar[encounterWorkList] : undefined;

    return (
        <EncounterWorkListComponent
            upperContent={
                messageBarText ? (
                    <MessageBar>
                        This worklist contains encounters where the <b>{messageBarText}</b>
                    </MessageBar>
                ) : null
            }
            workLists={encounterWorkLists}
        />
    );
}
