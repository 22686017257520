import './Loading.scss';
import { ITheme, Spinner, SpinnerSize, Stack, MessageBar, MessageBarType, Text } from '@fluentui/react';
import { useSelector } from 'react-redux';
import { authStateSelector } from 'state/slices/auth.slice';
import authProvider from 'api/auth/authProvider';

type ITest = {
    palette: {
        navbar: string;
    };
} & ITheme;

type Props = {
    theme?: ITest;
    spinnerText?: string;
    disableSpinner?: boolean;
    errorMessage?: string;
};

function Loading(props: Props): JSX.Element {
    const auth = useSelector(authStateSelector);

    const _login = () => {
        authProvider.loginPopup();
    };
    return (
        <Stack
            className="loading-wrapper"
            style={{
                background: ` #514A79`,
                color: 'white',
            }}
        >
            <Stack horizontalAlign="center" tokens={{ childrenGap: 20 }}>
                <Stack.Item>
                    <Text variant="xxLargePlus" className="loading-text">
                        <strong style={{ color: 'white' }}>athenaOne &#174;</strong> Dental
                    </Text>
                </Stack.Item>
                {!auth.initializing && (
                    <>
                        {!props.disableSpinner && (
                            <Stack.Item>
                                <Spinner
                                    size={SpinnerSize.large}
                                    styles={{ label: { color: 'white' } }}
                                    label={props.spinnerText}
                                />
                            </Stack.Item>
                        )}
                        {props.errorMessage ? (
                            <MessageBar messageBarType={MessageBarType.error} styles={{ root: { width: 400 } }}>
                                {props.errorMessage}
                            </MessageBar>
                        ) : null}
                    </>
                )}

                {/* {auth.initialized && auth.state === AuthenticationState.Unauthenticated && (
                    <>
                        <TText color="neutralLight">Unable to authenticate.</TText>
                        <ActionButton onClick={_login}>Try Again</ActionButton>
                        {authProvider.get()?.errorCode && (
                            <MessageBar messageBarType={MessageBarType.error} styles={{ root: { width: 400 } }}>
                                {authProvider.getError()?.errorMessage}
                            </MessageBar>
                        )}
                    </>
                )} */}
            </Stack>
        </Stack>
    );
}

export default Loading;
