import { TModal } from 'components';
import {
    PivotItem,
    Pivot,
    Stack,
    useTheme,
    CommandBar,
    IContextualMenuItem,
    TooltipHost,
    Tooltip,
    IconButton,
    DirectionalHint,
    CommandBarButton,
    Text,
} from '@fluentui/react';
import { ChartConditionStatus, ChartProcedurePreAuth, ChartProcedureStatus } from 'api/models/chart.model';
import { EncounterStatus } from 'api/models/encounter.model';
import { useChart, useChartProcedures, useEncounter, useSelector } from 'hooks';
import useChartConditions from 'hooks/store/useChartConditions';
import useChartNotes from 'hooks/store/useChartNotes';
import usePatient from 'hooks/store/usePatient';
import useEncounterId from 'hooks/useEncounterId';
import { RouteParams } from 'interfaces/route-params';
import { every, map, some } from 'lodash';
import { PatientNotes } from 'pages/PatientOverview/components';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { readyForProviderClick, setClinicalNoteAttested } from 'state/slices/chart-notes/chart-notes.actions';
import { selectQuickNotes } from 'state/slices/chart-notes/quick-notes/quick-notes.selectors';
import {
    canEditSelectedChartProcedureActions,
    getSelectedChartActions,
    getSelectedChartProcedureActions,
} from 'state/slices/charting/chart/chart.selectors';
import {
    ProcedureActionType,
    setChartingSection,
    chartingActions,
    setModal,
    toggleAttestModal,
    toggleChartActionFilterVisibility,
} from 'state/slices/charting/chart/chart.slice';
import { openDiagnosesPanel } from 'state/slices/charting/diagnoses-panel/diagonses-panel.actions';
import { selectSignedTreatmentPlanProceduresLookup } from 'state/slices/charting/treatmentPlans/treatmentPlans.selectors';
import { isSectionChooserExpanded } from 'state/slices/charting/ui/chartingUi.selectors';
import { ChartingSection } from 'state/slices/charting/ui/ui.types';
import ChartActions from './ChartActions';
import ClinicalNote from './ClinicalNote';
import usePermissions, { DentalPermissions } from 'hooks/store/usePermissions';
import { useBoolean } from '@uifabric/react-hooks';
import {
    isCurrentNoteSaved,
    selectChartNoteModified,
    selectCurrentChartNoteHasEmptyRichTextContent,
} from 'state/slices/chart-notes/chart-notes.selectors';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { addAddendumAndPreviewNote } from 'state/slices/chart-notes/note-browser/note-browser.actions';
import { appInsights } from 'index';
import {
    selectCanAmendPatientEncounter,
    selectPatientEncounter,
    selectShouldConfirmAttestEncounter,
} from 'state/slices/encounter/encounter.selectors';
import ChartActionFilters from './ChartActionFilter/ChartActionFilters';
import { ProcedureBillingStatus } from 'api/models/procedure.model';
import { FeatureFlag, selectActiveFeatureFlagsLookup } from 'state/slices/tenant/feature-flags.slice';
import { useFeatureFlag } from 'hooks/useFeatureFlags';

function SectionChooser(): JSX.Element {
    const selectedSection = useSelector((state) => state.charting.ui.section);
    const sectionChooserExpanded = useSelector(isSectionChooserExpanded);
    const chartNoteModified = useSelector(selectChartNoteModified);
    const { palette } = useTheme();
    const { currentPatient } = usePatient();
    const featureFlags = useSelector(selectActiveFeatureFlagsLookup);

    const sections = {
        [ChartingSection.ToothLedger]: ChartActions,
        [ChartingSection.ClinicalNote]: ClinicalNote,
        [ChartingSection.PatientNotes]: PatientNotes,
    };

    const sectionToolbar = {
        [ChartingSection.ToothLedger]: ToothLedgerToolbar,
        [ChartingSection.ClinicalNote]: ClinicalNoteToolbar,
        [ChartingSection.PatientNotes]: () => null,
    };

    const SectionToRender = sections[selectedSection];
    const ToolbarToRender = sectionToolbar[selectedSection];

    function PivotItems() {
        const dispatch = useDispatch();
        const _handleLinkClick = (item?: PivotItem) => {
            if (item) {
                const section = item.props.itemKey as ChartingSection;
                if (selectedSection === ChartingSection.ClinicalNote && chartNoteModified) {
                    //If the user decides to cancel moving away from the clinical notes tab, don't dispatch anything. Otherwise dispatch the action.
                    if (
                        window.confirm(
                            'To avoid loss of data, please wait for the clinical note to save before leaving this page. Are you sure you want to leave?',
                        )
                    )
                        dispatch(setChartingSection(section));
                } else {
                    dispatch(setChartingSection(section));
                }
                appInsights.trackPageView({ name: `Charting - ${section} - athenaOne Dental`, pageType: 'tabView' });
            }
        };
        const _handleExpandSectionChooser = () => {
            dispatch(chartingActions.toggleSectionChooserExpanded());
        };
        return (
            <Stack style={{ position: 'relative' }} horizontalAlign="end" horizontal verticalAlign="center">
                <Pivot aria-label="Links of Tab Style Pivot Example" onLinkClick={_handleLinkClick} selectedKey={selectedSection}>
                    <PivotItem headerText="Tooth Ledger" itemKey={ChartingSection.ToothLedger} />
                    <PivotItem headerText="Clinical Note" itemKey={ChartingSection.ClinicalNote} />
                    <PivotItem
                        itemIcon={currentPatient?.note?.note ? 'Info' : undefined}
                        headerText="Patient Notes"
                        itemKey={ChartingSection.PatientNotes}
                    />
                </Pivot>
                <TooltipHost
                    content={sectionChooserExpanded ? 'Minimize' : 'Maximize'}
                    directionalHint={DirectionalHint.topAutoEdge}
                >
                    <IconButton
                        style={{ marginRight: 5 }}
                        iconProps={{ iconName: sectionChooserExpanded ? 'MiniContract' : 'MiniExpand' }}
                        onClick={_handleExpandSectionChooser}
                    />
                </TooltipHost>
            </Stack>
        );
    }
    const _styles = {
        root: { background: palette.white },
        heigt: '100%',
    };
    return (
        <Stack id="section-chooser" grow styles={_styles}>
            <Stack id="toolbar-container" horizontal style={{ borderBottom: `1px solid ${palette.neutralQuaternaryAlt}` }}>
                <Stack>
                    <ToolbarToRender />
                </Stack>
                <Stack style={{ flex: 1 }} id="pivot-items-container">
                    <PivotItems />
                </Stack>
            </Stack>
            {featureFlags.has(FeatureFlag.ChartLedgerFilters) && <ChartActionFilters />}
            <Stack grow id="sectionToRender-container">
                <SectionToRender />
            </Stack>
        </Stack>
    );
}

export default SectionChooser;

function ToothLedgerToolbar(): JSX.Element {
    const encounterId = useEncounterId();

    const { updateChartConditionStatuses } = useChartConditions();
    const { updateProcedureStatuses, updateProcedurePreAuths, canSetProceduresCompleted } = useChartProcedures();
    const { setDeleteModalOpen } = useChart();
    const isChartFilterEnabled = useFeatureFlag(FeatureFlag.ChartLedgerFilters);

    const selectedChartActions = useSelector(getSelectedChartActions);
    const patientEncounter = useSelector(selectPatientEncounter);
    const signedTPProcedures = useSelector(selectSignedTreatmentPlanProceduresLookup);
    const selectedChartProcedures = useSelector(getSelectedChartProcedureActions);
    const someProceduresCannotBeEdited = useSelector(canEditSelectedChartProcedureActions);

    const dispatch = useDispatch();

    //Controls behaviors based on encounter correction workflow
    const patientEncounterCorrectionAmendOrCorrectionsNeeded =
        patientEncounter?.status === EncounterStatus.CorrectionAmend ||
        patientEncounter?.status === EncounterStatus.CorrectionsNeeded;

    const _actionsAreSelected = selectedChartActions.length > 0;

    const allProcedures = every(selectedChartActions, ['actionType', 'Procedure']);

    const allProcsHaveDxCodes = some(selectedChartProcedures, (proc) => proc.diagnosisCodes && proc.diagnosisCodes.length > 0);

    const someSignedTPProceduresSelected = some(selectedChartProcedures, (proc) => !!signedTPProcedures[proc.id]);

    //For correction amend and corrections needed only:
    const correctionsWorkflowCanSelectStatus = patientEncounterCorrectionAmendOrCorrectionsNeeded
        ? some(
              selectedChartProcedures,
              (proc) => patientEncounter.id === proc.encounterId && proc.billingStatus === ProcedureBillingStatus.Billed,
          )
        : true;

    /**
     * Essentially we need to do a some that looks for some procedures of type treatment that are selected that are phased in a signed TP.
     */
    const everyProcedureReferred = every(
        selectedChartActions?.filter((p) => p.actionType === 'Procedure' && p.diagnosisCodes?.length),
        ['procedureType', 'Referred'],
    );

    const referredFilter = selectedChartActions?.filter(
        (p) =>
            p.actionType === 'Procedure' &&
            (p.procedureType === 'Referred' || p.procedureType === 'Treatment') &&
            p.diagnosisCodes,
    );
    const checkIfReferredHasSomeDiagnosis = some(referredFilter, (res) => {
        return res?.diagnosisCodes?.length === 0;
    });
    const allConditions = every(selectedChartActions, ['actionType', 'Condition']);

    const hasExisting =
        selectedChartActions.filter(
            ({ procedureType }) =>
                procedureType === ProcedureActionType.Existing || procedureType === ProcedureActionType.ExistingOther,
        ).length > 0;

    /**
     * If no actions are selected, return disabled.
     * - OR -
     * If actions are selected and they are not all procedures or not all condtions, return disabled.
     *
     */
    const _statusDisabled =
        !_actionsAreSelected ||
        (_actionsAreSelected && !allProcedures && !allConditions) ||
        hasExisting ||
        someProceduresCannotBeEdited ||
        !correctionsWorkflowCanSelectStatus;

    const _completeDisabled =
        !allProcsHaveDxCodes ||
        !_actionsAreSelected ||
        hasExisting ||
        !allProcedures ||
        !canSetProceduresCompleted ||
        someProceduresCannotBeEdited ||
        (!encounterId && !everyProcedureReferred) ||
        (everyProcedureReferred && checkIfReferredHasSomeDiagnosis);

    const _preauthDisabled =
        !_actionsAreSelected || (_actionsAreSelected && !allProcedures) || hasExisting || someProceduresCannotBeEdited;

    const _updateProcedureStatuses = (status: ChartProcedureStatus) => {
        if (_actionsAreSelected) {
            updateProcedureStatuses(selectedChartActions, status, true);
        }
    };

    const _updateProcedureComplete = (status: ChartProcedureStatus) => {
        if (_actionsAreSelected) {
            updateProcedureStatuses(status === ChartProcedureStatus.Completed ? selectedChartActions : [], status, true);
        }
    };

    const _updateChartActionProcedurePreAuth = (preauth: ChartProcedurePreAuth) => {
        if (_actionsAreSelected) {
            updateProcedurePreAuths(selectedChartActions, preauth);
        }
    };

    const _updateConditionStatuses = (status: ChartConditionStatus) => {
        if (_actionsAreSelected) {
            updateChartConditionStatuses(selectedChartActions, status);
        }
    };

    const _updateProcedureDx = () => {
        if (_actionsAreSelected) {
            dispatch(openDiagnosesPanel(selectedChartActions));
            // updateDiagnosisIds(selectedChartActionsAsList, item);
        }
    };

    const disabledStatusContextItem = (status: string) => {
        if (status === 'Completed') return _completeDisabled;
        return false;
    };

    const procedureStatusMenuItems: IContextualMenuItem[] = Object.keys(ChartProcedureStatus)
        //Filter status options to only include void when status is corrections needed or correction amend.
        .filter((key) =>
            patientEncounterCorrectionAmendOrCorrectionsNeeded ? key === 'Void' : key !== 'Removed' && key !== 'Void',
        )
        .map((status) => ({
            key: status,
            text: status,
            disabled: disabledStatusContextItem(status),
            onClick: () => _updateProcedureStatuses(status as ChartProcedureStatus),
        }));

    const procedurePreAuthMenuItems: IContextualMenuItem[] = map(ChartProcedurePreAuth, (preAuth, key) => {
        return {
            key: key,
            text: preAuth,
            onClick: () => {
                _updateChartActionProcedurePreAuth(preAuth);
            },
        };
    });

    const conditionStatusMenuItems: IContextualMenuItem[] = Object.keys(ChartConditionStatus)
        .filter((status) => status !== ChartConditionStatus.Removed)
        .map((status) => ({
            key: status,
            text: status,
            onClick: () => _updateConditionStatuses(status as ChartConditionStatus),
        }));
    const toggleFilterVisibility = () => {
        dispatch(toggleChartActionFilterVisibility());
    };

    const commandBarItems = [
        {
            key: 'set-chart-action-completed',
            text: 'Set Complete',
            iconProps: { iconName: 'Completed' },

            tooltipHostProps: {
                content: 'Update procedure status(es)',
            },
            disabled: _completeDisabled,
            onClick: () => {
                _updateProcedureComplete(ChartProcedureStatus.Completed);
            },
        },
        {
            key: 'set-chart-action-status',
            text: 'Set Status',
            iconProps: { iconName: 'BulletedList2' },
            subMenuProps: {
                items: allProcedures ? procedureStatusMenuItems : conditionStatusMenuItems,
            },
            tooltipHostProps: {
                content: 'Update procedure status(es)',
            },
            disabled: _statusDisabled,
        },
        {
            key: 'set-chart-action-preauth',
            text: 'Set Pre-Auth',
            iconProps: { iconName: 'BulletedList2' },
            subMenuProps: {
                items: procedurePreAuthMenuItems,
            },
            tooltipHostProps: {
                content: 'Update procedure pre-auth(s)',
            },
            disabled: _preauthDisabled,
        },
        {
            key: 'open-delete-modal',
            text: 'Delete',
            iconProps: { iconName: 'trash' },
            disabled:
                !_actionsAreSelected ||
                patientEncounterCorrectionAmendOrCorrectionsNeeded ||
                someProceduresCannotBeEdited ||
                someSignedTPProceduresSelected,
            onRender: (item: any) => {
                return (
                    <TooltipHost
                        content={
                            someSignedTPProceduresSelected
                                ? 'Some of the selected procedures have been signed in a treatment plan and cannot be deleted.'
                                : undefined
                        }
                        delay={0}
                    >
                        <CommandBarButton {...item} styles={{ root: { height: '100%' } }} />
                    </TooltipHost>
                );
            },
            onClick: () => setDeleteModalOpen(true),
        },
        {
            key: 'open-diagnoses-modal',
            text: 'Dx',
            iconProps: { iconName: 'Clipboardlist' },
            disabled: !_actionsAreSelected || someProceduresCannotBeEdited || !allProcedures,
            onClick: () => _updateProcedureDx(),
        },
    ];

    const commandBarItemsWithFilter =
        isChartFilterEnabled === true
            ? [
                  ...commandBarItems,
                  {
                      key: 'filter-chart-actions',
                      iconProps: { iconName: 'Filter' },
                      iconOnly: true,
                      title: 'Filter',
                      tooltipHostProps: { title: 'Filter Chart', content: 'Filter' },
                      renderedInOverflow: false,
                      onClick: toggleFilterVisibility,
                      visible: false,
                  },
              ]
            : commandBarItems;

    return (
        <Stack horizontal verticalAlign="center" id="tooth-ledger-toolbar-wrapper">
            <Stack.Item grow>
                <CommandBar items={commandBarItemsWithFilter} />
            </Stack.Item>
        </Stack>
    );
}

function ClinicalNoteToolbar(): JSX.Element | null {
    const dispatch = useDispatch();
    const { tenantId, patientId } = useParams<RouteParams>();
    const {
        patientEncounter,
        isTreatingProvider,
        isTreatingProviderResident,
        isAttestingHygienist,
        patientEncounterAppointment: appointment,
    } = useEncounter();
    const canAmendEncounter = useSelector(selectCanAmendPatientEncounter);
    const shouldShowConfirmAttestModal = useSelector(selectShouldConfirmAttestEncounter);

    const { currentNote, saving } = useChartNotes();

    const { assets } = useSelector(selectQuickNotes);
    const _isCurrentNoteSaved = useSelector(isCurrentNoteSaved);
    const currentNoteHasEmptyContent = useSelector(selectCurrentChartNoteHasEmptyRichTextContent);

    const [attestMouseOver, setAttestMouseOver] = useState<boolean>(false);
    const [addendumMouseOver, setAddendumMouseOver] = useState<boolean>(false);
    const [signMouseOver, { setTrue: signMouseOverTrue, setFalse: signMouseOverFalse }] = useBoolean(false);
    const [residentUser, globalAdmin] = usePermissions([DentalPermissions.Resident]);

    const [showConfrimAttestModal, setShowConfirmAttestModal] = useState<boolean>(false);

    const isCurrentNoteFromPatientEncounter = currentNote?.encounterId === patientEncounter?.id;

    const isAttested = patientEncounter?.status === EncounterStatus.Attested;
    const isCorrectionAmend = patientEncounter?.status === EncounterStatus.CorrectionAmend;
    const isCorrectionsNeeded = patientEncounter?.status === EncounterStatus.CorrectionsNeeded;
    const isAmendmentRequired = patientEncounter?.status === EncounterStatus.AmendRequired;

    const chartNoteSaving = saving === LoadingStatus.Pending;
    const hasCompletedProcedures = !!assets.chartProcedures.completed.length;

    const signDisabled =
        //Note saving
        chartNoteSaving ||
        //Patient Encounter
        currentNoteHasEmptyContent ||
        !patientEncounter ||
        !isCurrentNoteFromPatientEncounter ||
        //Is the note currently attested.
        isAttested ||
        //Encounter status
        isAmendmentRequired ||
        isCorrectionAmend ||
        isCorrectionsNeeded;

    // Override for global admin privileges
    // NOTE: Need to re-asses after go live
    const residentOrGlobal = globalAdmin ? false : residentUser;

    function canAttestChartNote() {
        //If the current user can amend the encounter, then we will allow the user to attest.
        if (canAmendEncounter) return true;
        //If you are the treating provider, but you are a resident, then you cannot attest
        if (isTreatingProvider && isTreatingProviderResident) return false;
        //If you are the treating provider or an attesting hygienist
        if (isTreatingProvider || isAttestingHygienist) return true;

        return false;
    }

    const _attestDisabled =
        //Note saving
        chartNoteSaving ||
        !_isCurrentNoteSaved ||
        //Patient Encounter does not exist
        currentNoteHasEmptyContent ||
        !patientEncounter ||
        //patient note does not belong to the currently selected encounterId;
        !isCurrentNoteFromPatientEncounter ||
        !canAttestChartNote() ||
        //If you are a global admin then do nothing, otherwise check if you are a resident, if you are, then disable attest.
        residentOrGlobal ||
        //If we are in the sent correction workflow disable attest.
        isCorrectionAmend ||
        isCorrectionsNeeded ||
        //Ensure that there are completed chart procedures on associated to this encounter.
        !hasCompletedProcedures ||
        //Is the encounter currently attested
        isAttested;

    const _addendumDisabled = !isAttested && !isCorrectionAmend;

    function onAttestationClick() {
        if (shouldShowConfirmAttestModal) {
            //show modal
            setShowConfirmAttestModal(true);
        } else {
            _onAttestEncounter();
        }
    }

    function _onAttestEncounter() {
        if (!isAttestingHygienist) {
            if (tenantId && patientId && appointment)
                dispatch(setClinicalNoteAttested(tenantId, patientId, appointment.treatingProviderId));
        } else {
            dispatch(toggleAttestModal());
        }
    }

    function onReadyForProviderClick() {
        if (residentUser) {
            dispatch(setModal(true));
        } else {
            dispatch(readyForProviderClick(tenantId, patientId));
        }
    }

    function onAddendumClick() {
        dispatch(addAddendumAndPreviewNote());
    }

    const attestButton = document.getElementById('attestButton') ?? undefined;
    const addendumButton = document.getElementById('addendumButton') ?? undefined;
    const signButton = document.getElementById('signButton') ?? undefined;

    useEffect(() => {
        if (attestButton) {
            attestButton.addEventListener('mouseover', () => {
                setAttestMouseOver(true);
            });
            attestButton.addEventListener('mouseleave', () => {
                setAttestMouseOver(false);
            });
        }
        if (addendumButton) {
            addendumButton.addEventListener('mouseover', () => {
                setAddendumMouseOver(true);
            });
            addendumButton.addEventListener('mouseleave', () => {
                setAddendumMouseOver(false);
            });
        }
        if (signButton) {
            signButton.addEventListener('mouseover', signMouseOverTrue);
            signButton.addEventListener('mouseleave', signMouseOverFalse);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attestButton, signButton, addendumButton]);

    return (
        <>
            <ConfirmAttestModal
                setShowConfirmAttestModal={setShowConfirmAttestModal}
                onAttestEncounter={_onAttestEncounter}
                showConfirmAttestModal={showConfrimAttestModal}
            />
            <Stack verticalAlign="center">
                {!hasCompletedProcedures ? (
                    <Tooltip
                        targetElement={attestButton}
                        content={'Attesting requires that there is at least one completed procedure for the current encounter'}
                        hidden={!attestMouseOver}
                    />
                ) : undefined}
                <Stack.Item grow>
                    <CommandBar
                        items={[
                            {
                                key: 'sign',
                                text: 'Sign',
                                id: 'signButton',
                                iconProps: { iconName: 'InboxCheck' },
                                disabled: signDisabled,
                                onClick: onReadyForProviderClick,
                            },
                            {
                                key: 'attestation',
                                text: 'Attest',
                                id: 'attestButton',
                                iconProps: { iconName: 'PenWorkspace' },
                                disabled: _attestDisabled,
                                onClick: onAttestationClick,
                            },
                            {
                                key: 'addendum',
                                text: 'Addendum',
                                id: 'addendumButton',
                                iconProps: { iconName: 'Add' },
                                disabled: _addendumDisabled,
                                onClick: onAddendumClick,
                            },
                        ]}
                    />
                    <Tooltip
                        targetElement={signButton}
                        content={`Signing the clinical note will send it to the treating or attending provider's queue for attestation`}
                        hidden={!signMouseOver}
                    />
                    <Tooltip
                        targetElement={addendumButton}
                        content={`Addendum will allow you to add a new  note to the current encounter`}
                        hidden={!addendumMouseOver}
                    />
                </Stack.Item>
            </Stack>
        </>
    );
}

function ConfirmAttestModal({
    showConfirmAttestModal,
    setShowConfirmAttestModal,
    onAttestEncounter,
}: {
    setShowConfirmAttestModal: (show: boolean) => void;
    onAttestEncounter: () => void;
    showConfirmAttestModal: boolean;
}) {
    const _onDismiss = () => {
        setShowConfirmAttestModal(false);
    };

    const _onAttest = () => {
        onAttestEncounter();
        _onDismiss();
    };

    return (
        <TModal
            modalProps={{ isOpen: showConfirmAttestModal, onDismiss: _onDismiss }}
            isDraggable
            mainButtons={[
                { text: 'Confirm', primary: true, onClick: _onAttest },
                { text: 'Cancel', onClick: _onDismiss },
            ]}
            title={'WARNING: Attestation Confirmation'}
        >
            <div style={{ padding: 10 }}>
                <div>
                    <Text variant={'mediumPlus'}>
                        <strong>WARNING:</strong> You are not the treating provider on the clinical note you are about to attest.
                    </Text>
                </div>
                <div>
                    <Text variant={'mediumPlus'}>Please click cancel if this was an accident.</Text>
                </div>
            </div>
        </TModal>
    );
}
