import Tooth from './Tooth';
import ToothSprite from './ToothSprite';
import { ToothArea } from 'api/models/tooth-area';
import { OutlineFilter } from '@pixi/filter-outline';
import { IChartSurfaceData } from './SurfaceContainer';
import { getSurfaceName } from './getSurfaceName';

/**
 * Instance of a surface sprite on a tooth
 *
 * @class Surface
 * @extends {ToothSprite}
 */
class Surface extends ToothSprite {
    private _tooth: Tooth;
    private _surfaceData: IChartSurfaceData;

    constructor(tooth: Tooth, surfaceData: IChartSurfaceData) {
        super(surfaceData.sprite, tooth);

        this._surfaceData = surfaceData;
        this._tooth = tooth;

        this._setupSurfaceSize();
        if (tooth.isToothVisible) {
            this._handleConditions();
            this._handleSurfaceColor();
        }
    }

    private get _surfaceName() {
        return getSurfaceName(this._tooth, this._surfaceData);
    }

    private get _canRenderColors() {
        return !this._tooth.isPontic() && !this._tooth.isAbutmentRetainerCrown();
    }

    private _handleSurfaceColor() {
        const actions = this._tooth.getAreaActions(this._surfaceName);
        const implantSupportedCrown = this._tooth.isImplantSupportedCrown();
        const abutmentThreeQuarterCrown = this._tooth.isAbutmentThreeQuarterCrown();
        if (this._canRenderColors)
            if (implantSupportedCrown) {
                this._tooth.handleActionColor([implantSupportedCrown], { sprite: this });
            } else if (abutmentThreeQuarterCrown) {
                this._tooth.handleActionColor([abutmentThreeQuarterCrown], { sprite: this });
            } else {
                this._tooth.handleActionColor(actions, { sprite: this });
            }
    }

    private _handleConditions() {
        const actions = this._tooth.getAreaActions(this._surfaceName);
        if (this._canRenderColors)
            this._tooth.data.conditions.forEach((c) => {
                const renderRule = c.renderRule;
                if (c.areas) {
                    if (renderRule === 'defective-restoration' || renderRule === 'incipient-caries')
                        if (c.areas.indexOf(this._surfaceName as keyof typeof ToothArea) > -1)
                            if (actions.length) {
                                this._addConditionOutlineToSurface();
                            } else {
                                this._addConditionFillToSurface();
                            }
                }
            });
    }

    /**
     * Adds black condition outline to surface
     *
     * @private
     * @memberof Surface
     */
    private _addConditionOutlineToSurface() {
        this.zIndex = 1;
        (this.filters as unknown[]) = [new OutlineFilter(1.5, 0x000, 0.5)];
    }

    /**
     * Adds black condition fill color to surface.
     *
     * @private
     * @memberof Surface
     */
    private _addConditionFillToSurface() {
        this.zIndex = 1;
        this.tint = 0x000;
        this.alpha = 0.75;
    }

    /**
     * Setup the size and aspect ration of this surface
     *
     * @private
     * @memberof Surface
     */
    private _setupSurfaceSize = () => {
        const aspectRatio = this.width / this.height;
        this.width = this._tooth.getToothWidth;
        this.height = this._tooth.getToothWidth / aspectRatio;
    };
}

export default Surface;
