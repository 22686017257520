import { DefaultEffects, Panel, Stack, PanelType, Text, Link, Label, SpinnerSize, Spinner, SharedColors } from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import dentalApi from 'api/dental.api';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import { useAccount, useSelector } from 'hooks';
import { RouteParams } from 'interfaces/route-params';
import WorkListBuilder from 'pages/Landing/AdminHuddle/components/WorkListBuilder';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { selectAccount } from 'state/slices/account.slice';
import { } from 'state/slices/admin-huddle/worklists/worklist.selectors';
import { setWorkListFilter, setWorkListReadOnly } from 'state/slices/admin-huddle/worklists/worklist.slice';
import { classicDateOnly } from 'utils/dateOnly';
import { EditEncounterWorkList } from 'state/slices/admin-huddle/worklists/encounter-worklists/encounter-worklists.state';
import IWorkListEncounterView from 'api/models/worklist-encounter-view';
import EncountersDetailsList from 'pages/Landing/AdminHuddle/components/EncounterWorkLists/EncountersDetailsList';
import { OnRenderEncounterCorrespondenceColumn } from 'pages/Landing/AdminHuddle/components/ColumnComponents/EncounterCorrespondenceColumn';

function CorrectionAmendments(): JSX.Element {
    const { tenantId } = useParams<RouteParams>();
    const account = useSelector(selectAccount);
    const userId = account.data?.identity.id;

    const [count, setCount] = useState<number>();
    const [countLoading, { setTrue: setIsLoadingTrue, setFalse: setIsLoadingFalse }] = useBoolean(true);
    const [isOpen, { setTrue: setIsOpen, setFalse: setIsClosed }] = useBoolean(false);

    useEffect(() => {
        if (tenantId && userId) {
            setIsLoadingTrue();
            dentalApi
                .getWorkListDataByWorkList<number>({
                    tenantId,
                    method: 'CorrectionAmendEncountersCount',
                    filters: { providers: [userId] },
                })
                .then((res) => {
                    setCount(res.data);
                    setIsLoadingFalse();
                })
                .catch(() => {
                    setIsLoadingFalse();
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenantId, userId]);

    const boxStyles = {
        root: {
            background: SharedColors.orange10,
            width: 120,
            height: 120,
            color: '#FFF',
            cursor: 'pointer',
            selectors: { ':hover': { boxShadow: DefaultEffects.elevation8 } },
        },
    };

    const textStyles: React.CSSProperties = {
        cursor: 'pointer',
        background: 'rgba(0,0,0,0.2)',
        textAlign: 'center',
    };

    return (
        <Stack styles={boxStyles} verticalAlign="center" horizontalAlign="center" onClick={setIsOpen}>
            <Stack grow style={{ fontSize: 48 }} verticalAlign="center">
                {countLoading ? <Spinner size={SpinnerSize.large} /> : <span>{count}</span>}
            </Stack>
            <Stack horizontalAlign="center" style={textStyles}>
                <Label style={{ color: 'white' }}>Correction Addendums</Label>
            </Stack>
            <CorrectionAmendmentsPanel isOpen={isOpen} onDismiss={setIsClosed} />
        </Stack>
    );
}

type CorrectionAmendmentsPanelProps = {
    onDismiss: () => void;
    isOpen: boolean;
};

function CorrectionAmendmentsPanel({ isOpen, onDismiss }: CorrectionAmendmentsPanelProps) {
    const dispatch = useDispatch();
    const { tenantId } = useParams<RouteParams>();
    const { account } = useAccount();
    const { push } = useHistory();

    useEffect(() => {
        if (isOpen) {
            //Treating provider should only be able to read the notes, but not add.
            dispatch(setWorkListReadOnly(true));
            //Load only this treating provider's correction amend encounters:
            if (account.data?.identity) {
                dispatch(setWorkListFilter({ path: 'providers', value: [account.data?.identity.id] as string[] }));
            }
        }
    }, [isOpen, dispatch, tenantId, account]);

    const _handlePush = (item?: IWorkListEncounterView) => {
        if (item) {
            push(`/${tenantId}/patient/${item?.patientId}/encounter/${item.encounter?.id}/charting`);
        }
    };

    const columns: ISortableColumn<IWorkListEncounterView>[] = [
        {
            key: 'encounterDate',
            name: 'Enc. Date',
            fieldName: 'encounterDate',
            minWidth: 60,
            maxWidth: 100,
            isResizable: true,
            onRender: (item) => {
                if (item)
                    return (
                        <Link onClick={() => _handlePush(item)}>
                            {item.encounterDate ? classicDateOnly(item.encounterDate) : 'N/A'}
                        </Link>
                    );
            },
        },
        {
            key: 'patientName',
            name: 'Patient',
            fieldName: 'patientName',
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            onRender: (item) => {
                if (!item) return null;
                const _patientName = `${item.patientLastName}, ${item.patientFirstName}`;
                return <Text variant="small">{_patientName}</Text>;
            },
            getValueKey: (item) => {
                return item?.patientLastName ?? '';
            },
        },
        {
            key: 'notes',
            name: 'Correction Notes',
            minWidth: 25,
            maxWidth: 25,
            fieldName: 'notes',
            onRender: OnRenderEncounterCorrespondenceColumn,
        },
    ];

    return (
        <Panel
            type={PanelType.custom}
            customWidth={'40vw'}
            isOpen={isOpen}
            onDismiss={onDismiss}
            styles={{
                contentInner: { flex: 1 },
                scrollableContent: { display: 'flex', flex: 1, flexDirection: 'column' },
                content: { display: 'flex', flexDirection: 'column', flex: 1 },
            }}
            headerText="Correction Addendums"
        >
            <Stack grow>
                {isOpen && (
                    <WorkListBuilder<IWorkListEncounterView>
                        hideFilters
                        hideRecentItems
                        workLists={[EditEncounterWorkList.EditEncountersCorrectionAmend]}
                    >
                        {(detailsListData) => <EncountersDetailsList {...detailsListData} columns={columns} />}
                    </WorkListBuilder>
                )}
            </Stack>
        </Panel>
    );
}

export default CorrectionAmendments;
