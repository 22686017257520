import { PayloadAction } from '@reduxjs/toolkit';
import { map } from 'lodash';
import { CheckInSections } from '../scheduling.slice';
import { SchedulingState } from '../scheduling.state';

const checkInReducers = {
    cleanupCheckInPanel: (state: SchedulingState): void => {
        state.checkin.visibleSections = [];
    },
    setIsCheckinPanelOpen: (state: SchedulingState, action: PayloadAction<boolean>): void => {
        state.checkin.isCheckInPanelOpen = action.payload;
    },
    toggleCheckInSecitonVisible: (state: SchedulingState, action: PayloadAction<CheckInSections>): void => {
        const exist = state.checkin.visibleSections.indexOf(action.payload) > -1;
        if (exist) {
            state.checkin.visibleSections = state.checkin.visibleSections.filter((res) => res !== action.payload);
        } else {
            state.checkin.visibleSections = [...state.checkin.visibleSections, action.payload];
        }
    },
    setAllCheckInSectionOpen: (state: SchedulingState): void => {
        state.checkin.visibleSections = map(CheckInSections);
    },
};

export default checkInReducers;
