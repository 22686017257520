import { INavLinkGroup, Layer, Stack, useTheme } from '@fluentui/react';
import useSettings from 'hooks/store/useSettings';
import useKeypress from 'hooks/useKeypress';
import ManageOperatories from './pages/ManageOperatories/ManageOperatories';
import ManageProviders from './pages/ManageProviders/ManageProviders';
import QuickActionSettings from './pages/QuickActionSettings/QuickActionSettings';
import SettingsNav from './SettingsNav';
import usePermissions, { DentalPermissions } from 'hooks/store/usePermissions';
import ProcedureTemplates from './pages/ProcedureTemplates/ProcedureTemplates';
import ReportingGroupSettings from './pages/ManageReportingGroups/ReportingGroupsSettings';

const settingsPages: { [key: string]: () => JSX.Element } = {
    'manage-operatories': ManageOperatories,
    'manage-providers': ManageProviders,
    'quick-actions': QuickActionSettings,
    'manage-reporting-groups': ReportingGroupSettings,
    'procedure-templates': ProcedureTemplates,
};

const practiceSettingLinks = {
    name: 'Practice Settings',
    links: [
        {
            name: 'Operatories',
            url: `manage-operatories`,
            key: 'manage-operatories',
            iconProps: { iconName: 'GroupedList' },
        },
        {
            name: 'Providers',
            url: `manage-providers`,
            key: 'manage-providers',
            iconProps: { iconName: 'GroupedList' },
        },
        {
            name: 'Reporting Groups',
            url: `manage-reporting-groups`,
            key: 'manage-reporting-groups',
            iconProps: { iconName: 'GroupedList' },
        },
    ],
};

const chartingSettingLinks = {
    name: 'Charting',
    links: [
        {
            name: 'Procedure Templates',
            url: `procedure-templates`,
            key: 'procedure-templates',
            iconProps: { iconName: 'GroupedList' },
        },
        {
            name: 'Quick Actions',
            url: `quick-actions`,
            key: 'quick-actions',
            iconProps: { iconName: 'GroupedList' },
        },
    ],
};

function Settings(): JSX.Element | null {
    const { settingsExpanded, closeSettings, selectedPage, setSelectedPage } = useSettings();

    const _theme = useTheme();
    const [hasPracticeSetings] = usePermissions([DentalPermissions.PracticeSettings]);
    const [hasChartingSettings] = usePermissions([DentalPermissions.ChartSettings]);

    const navLinks: INavLinkGroup[] = [];
    if (hasPracticeSetings) navLinks.push(practiceSettingLinks);
    if (hasChartingSettings) navLinks.push(chartingSettingLinks);

    useKeypress('Escape', closeSettings);

    if (!settingsExpanded) return null;

    const componentToRender = (): JSX.Element | null => {
        if (selectedPage) {
            const Comp = settingsPages[selectedPage];
            if (Comp) return <Comp />;
            else return null;
        }
        return null;
    };

    return (
        <Layer styles={{ content: { height: '100%', backgroundColor: _theme.palette.neutralLight } }}>
            <Stack horizontal style={{ flex: 1, height: '100%' }}>
                <SettingsNav
                    navItems={navLinks}
                    setSelectedPage={setSelectedPage}
                    selectedPage={selectedPage}
                    closeSettings={closeSettings}
                />
                <Stack style={{ flex: 1, overflowX: 'hidden' }}>{componentToRender()}</Stack>
            </Stack>
        </Layer>
    );
}

export default Settings;
