import { Dropdown, IDropdownOption, Stack, Text, TextField } from '@fluentui/react';
import { ChartProcedurePreAuth } from 'api/models/chart.model';
import { IPredeterminationProcedure } from 'api/models/predeterminations.model';
import { TModal } from 'components';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { RouteParams } from 'interfaces/route-params';
import { map } from 'lodash';
import PanelSectionHeader from 'pages/components/PanelSectionHeader';
import { Dispatch, FormEvent, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { savePredetermination } from 'state/slices/admin-huddle/worklists/predeterminations/predetermination.actions';
import {
    selectEditPredeterminationModalOpen,
} from 'state/slices/admin-huddle/worklists/predeterminations/predetermination.selectors';
import { cleanupSelectedPredetermination } from 'state/slices/admin-huddle/worklists/predeterminations/predetermination.slice';
import { selectWorkListEditItem, selectWorkListSaving } from 'state/slices/admin-huddle/worklists/worklist.selectors';
import { getTeethDisplayName } from 'utils';
import convertDashedDateString from 'utils/convertDateStringToLocal';
import { classicDateOnly } from 'utils/dateOnly';

export function EditPredeterminationModal(): JSX.Element {
    const dispatch = useDispatch();

    const { tenantId } = useParams<RouteParams>();

    const isOpen = useSelector(selectEditPredeterminationModalOpen);
    const saving = useSelector(selectWorkListSaving) === LoadingStatus.Pending;
    const procedure = useSelector(selectWorkListEditItem<IPredeterminationProcedure>);

    const onDismiss = () => dispatch(cleanupSelectedPredetermination());

    const [preAuth, setPreauth] = useState<ChartProcedurePreAuth>(ChartProcedurePreAuth.Submitted);
    const [preAuthCode, setPreauthCode] = useState<string>('');

    const _updatePredetermination = () => dispatch(savePredetermination({ tenantId, preAuth, preAuthCode }));

    useEffect(() => {
        if (!isOpen) {
            setPreauth(ChartProcedurePreAuth.Submitted);
            setPreauthCode('');
        } else if (procedure?.preAuthorization) {
            setPreauth(procedure.preAuthorization);
        } else {
            setPreauth(ChartProcedurePreAuth.Submitted);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    return (
        <TModal
            title="Edit Authorization"
            modalProps={{ isOpen, onDismiss }}
            isDraggable
            mainButtons={[
                {
                    text: saving ? 'Saving...' : 'Save',
                    onClick: _updatePredetermination,
                    type: 'PrimaryButton',
                    disabled: saving,
                },
                {
                    text: 'Cancel',
                    onClick: onDismiss,
                },
            ]}
        >
            <EditPredetermination
                setPreauth={setPreauth}
                preAuth={preAuth}
                setPreauthCode={setPreauthCode}
                preAuthCode={preAuthCode}
            />
        </TModal>
    );
}

type EditPredeterminationProps = {
    preAuth: ChartProcedurePreAuth;
    setPreauth: Dispatch<SetStateAction<ChartProcedurePreAuth>>;
    preAuthCode: string;
    setPreauthCode: Dispatch<SetStateAction<string>>;
};
function EditPredetermination({
    preAuth,
    setPreauth,
    preAuthCode,
    setPreauthCode,
}: EditPredeterminationProps): JSX.Element | null {
    const procedure = useSelector(selectWorkListEditItem<IPredeterminationProcedure>);

    if (!procedure) return null;

    const preAuthOptions: IDropdownOption[] = map(ChartProcedurePreAuth, (auth) => ({
        key: auth,
        text: auth,
    }));

    const _onPreauthChange = (e: FormEvent, option?: IDropdownOption) => setPreauth(option?.key as ChartProcedurePreAuth);
    const _onPreauthCodeChange = (e: FormEvent, value?: string) => setPreauthCode(value ?? '');

    return (
        <Stack grow style={{ padding: 10 }} tokens={{ childrenGap: 10 }}>
            <Stack tokens={{ childrenGap: 10 }}>
                <Stack grow>
                    <PanelSectionHeader text="Procedure details" />
                    <Text>
                        <strong>Date:</strong> {procedure.createdOn ? classicDateOnly(procedure.createdOn) : 'n/a'}
                    </Text>
                    <Text>
                        <strong>Patient:</strong> {procedure.patientName} {procedure.mrn ? `(${procedure.mrn})` : ""}
                    </Text>
                    <Text>
                        <strong>Date of Birth:</strong> {procedure.dateOfBirth ? convertDashedDateString(procedure.dateOfBirth) : "Unknown DOB"}
                    </Text>
                    <Text>
                        <strong>Insurance:</strong> {procedure.primaryPayerName}
                    </Text>
                    <Text style={{ width: 350 }}>
                        <strong>CDT Code:</strong> {`${procedure.procedureCode} - ${procedure.procedureDescription} ${procedure.stage ? `(Stage: ${procedure.stage})` : ""}`}
                    </Text>
                    {procedure.toothIds?.length && (
                        <Text>
                            <strong>Tooth:</strong> {getTeethDisplayName(procedure.toothIds)}
                        </Text>
                    )}
                </Stack>
                <Stack grow>
                    <PanelSectionHeader text="Current Status" />
                    <Text>
                        <strong>Status:</strong> {procedure.preAuthorization}
                    </Text>
                    <Text>
                        <strong>Authorization:</strong> {procedure.preAuthorizationCode ? procedure.preAuthorizationCode : 'N/A'}
                    </Text>
                </Stack>
            </Stack>
            <Stack style={{ minWidth: 200 }}>
                <PanelSectionHeader text="New status" />
                <Stack>
                    <Stack grow>
                        <Dropdown label="Status" options={preAuthOptions} onChange={_onPreauthChange} selectedKey={preAuth} />
                    </Stack>
                    <Stack grow>
                        <TextField label="Authorization" value={preAuthCode} onChange={_onPreauthCodeChange} />
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
}
