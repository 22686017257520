import { INavProps, Nav, useTheme } from '@fluentui/react';

export default function TreeNav(props: INavProps) {
    const { palette } = useTheme();

    return (
        <Nav
            styles={{
                link: {
                    padding: 0,
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                },
                linkText: {
                    color: palette.black,
                },
            }}
            {...props}
        />
    );
}
