import { Link } from '@fluentui/react';
import IWorkListEncounterView from 'api/models/worklist-encounter-view';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import { useDispatch, useSelector } from 'react-redux';
import { EncounterWorkListModalType } from 'state/slices/admin-huddle/worklists/encounter-worklists/encounter-worklists.state';
import { selectWorkListReadOnly } from 'state/slices/admin-huddle/worklists/worklist.selectors';
import { setEncounterWorkListModalOpen } from 'state/slices/admin-huddle/worklists/worklist.slice';

const billingNote: ISortableColumn<IWorkListEncounterView> = {
    key: 'billingNote',
    name: 'Billing Note',
    minWidth: 100,
    onRender: OnRenderBillingNote,
};

function OnRenderBillingNote(encounter?: IWorkListEncounterView): JSX.Element | null {
    const dispatch = useDispatch();
    const readOnly = useSelector(selectWorkListReadOnly);

    if (!encounter) return null;
    const _openModal = () =>
        dispatch(
            setEncounterWorkListModalOpen({
                isOpen: true,
                type: EncounterWorkListModalType.BillingNote,
                editEncounter: encounter,
            }),
        );
    const _billingNote = encounter?.encounter?.billingNote ?? 'Add';
    return (
        <Link
            disabled={readOnly}
            onClick={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                _openModal();
            }}
            title={_billingNote}
        >
            {_billingNote}
        </Link>
    );
}

export default billingNote;
