import ToothSprite from './ToothSprite';
import Tooth from './Tooth';
import { ToothAreaGroup } from './ToothAreaGroup';

export class Back extends ToothAreaGroup {
    constructor(tooth: Tooth) {
        super(tooth, 'back');
        this._createAndAddBack();
    }

    private _createAndAddBack() {
        if (this._tooth.toothReference.back) {
            const back = new ToothSprite(this._tooth.toothReference.back, this._tooth);
            const aspectRatio = back.width / back.height;
            back.width = this._toothWidth;
            back.height = this._toothWidth / aspectRatio;
            this.y = this.getIsBottomRow ? 90 : 25 - back.height * this._toothScale;

            if (!this._tooth.missingRetainedRoot && !this._tooth.hasDenture) {
                const marylandBridge = this._tooth.isAbutmentMarylandBridge();
                if (marylandBridge) {
                    this._tooth.handleActionColor([marylandBridge], { sprite: back });
                }
                this.addChild(back);
            }
        }
    }
}
