import { ISearchBoxProps, SearchBox } from '@fluentui/react';

interface IUsersSearchBoxProps extends ISearchBoxProps {
    searchTerm: string;
    placeholder: string;
    onSearchChange: (value: string) => void;
}

const SettingsSearchBox = (props: IUsersSearchBoxProps) => {
    const { searchTerm, onSearchChange, styles } = props;
    return (
        <SearchBox
            styles={styles ? styles : { root: { width: 200 } }}
            showIcon={true}
            disableAnimation={true}
            defaultValue=""
            value={searchTerm}
            onChange={(_, value) => onSearchChange(value!)}
            onClear={(event) => {
                event.preventDefault();
                onSearchChange('');
            }}
            {...props}
        />
    );
};

export default SettingsSearchBox;
