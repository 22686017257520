import ToothSprite from './ToothSprite';
import Tooth from './Tooth';
import { ToothArea } from 'api/models/tooth-area';
/**
 * Instance of a root sprite on the front of a tooth
 *
 * @class Root
 * @extends {ToothSprite}
 */
class Root extends ToothSprite {
    constructor(tooth: Tooth) {
        super(tooth.toothReference.root, tooth);

        // NOTE: Name of tooth sprite is important for other classes to be able to reference root as a child of front container class.
        this.name = 'Root';

        const implant = tooth.implantProcedure();
        if (implant) this.setTexture = tooth.toothReference.implant;

        if (implant) {
            tooth.handleActionColor([implant], { sprite: this });
        } else {
            const actions = tooth.getAreaActions(ToothArea.Root);
            tooth.handleActionColor(actions, { sprite: this });
        }
        this.zIndex = -1;
        this._handleDenturesImplants(tooth);
    }

    private _handleDenturesImplants(tooth: Tooth) {
        if ((tooth && tooth.hasDenture) || (tooth.isImplantSupportedCrown() && !tooth.implantProcedure())) {
            this.alpha = 0;
        }
    }
}

export default Root;
