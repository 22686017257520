import { ChartProcedureDiagnosis, IChartProcedure } from 'api/models/chart.model';
import { IProcedure } from 'api/models/procedure.model';

export const diagnosesPanelInitialState: DiagnosesPanelState = {
    isOpen: false,
    selectedChartProcedures: undefined,
    selectedDiagnoses: undefined,
    selectedProcedures: undefined,
};

export type DiagnosesPanelState = {
    selectedChartProcedures?: IChartProcedure | IChartProcedure[];
    selectedDiagnoses?: ChartProcedureDiagnosis[];
    selectedProcedures?: IProcedure | IProcedure[];
    isOpen: boolean;
};
