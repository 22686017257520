import { DocumentBase } from 'api/document-base.model';
import { ETagSupport, IdSupport, ChangeAuditSupport, SoftDeleteSupport } from './support-types';
import { LookupCodeBase } from './lookup-base.model';

interface IPatient extends DocumentBase {
    mrn?: string;
    title?: string;
    lastName?: string;
    firstName?: string;
    middleName?: string;
    suffix?: string;
    dateOfBirth?: string;
    chosenName?: string;
    patientStatus?: string;
    maidenName?: string;
    dateOfDeath?: string;
    ssn?: string;
    legalSexId?: string;
    genderIdentityId?: string;
    sexualOrientationId?: string;
    pronoun?: string;
    preferredLanguage?: string;
    isInterpreterNeeded: boolean;
    raceIds?: string[];
    ethnicityId?: string;
    preferredContactMethod?: string;
    phoneNumbers?: IPatientPhoneNumber[];
    emailAddress?: string;
    declinedToProvideEmailAddress?: boolean;
    defaultPharmacy?: IPatientDefaultPharmacy;
    physicalAddress?: IPatientAddress;
    mailingAddress?: IPatientAddress;
    mailingAddressSameAsPhysicalAddress: boolean;
    guarantors?: IPatientGuarantor[];
    insurances?: IPatientInsurance[];
    slidingFees?: IPatientSlidingFee[];
    isSelfPay?: boolean;
    selfPayAdjustmentType?: string;
    references?: Record<string, string>;
    uds?: IPatientUDS;
    signature?: IPatientSignature;
    documents?: string[];
    clinicalDocuments?: string[];
    note?: IPatientNote;
    imagingSoftwareId?: string;
    guarantorAddressSameAsPatient: boolean;
    holdStatement?: string;
    defaultLocationOfCareId?: string;
}

export enum PatientPharmacyType {
    Retail = 'retail',
    MailOrder = 'mailorder',
}

export interface IIncomeReference {
    incomePercentage: number;
    familySize: number;
    familySizeDeclined: boolean;
    incomeDeclined: boolean;
    incomePayPeriod: string;
    income: number;
    incomeRangeDeclined: boolean;
}

export interface IPatientDefaultPharmacy {
    pharmacyType?: PatientPharmacyType;
    state?: string;
    city?: string;
    receiverType?: string;
    acceptFax?: string;
    clinicalProviderId?: string;
    zip?: string;
    phoneNumber?: string;
    pharmacyName?: string;
    address1?: string;
    faxNumber?: string;
}

export interface IPatientInsurance extends DocumentBase {
    insuranceId?: string;
    planId?: string;
    memberId?: string;
    groupNumber?: string;
    groupName?: string;
    effectiveDate: string;
    expirationDate?: string;
    allocationSet?: string;
    type?: string;
    policyHolderType: string;
    employer?: string;
    policyHolder: IPolicyHolder;
    eligibility?: IPatientEligibility;
    documents?: string[];
}

export interface IPatientSlidingFee {
    adjustmentType?: string;
    slidingFeeProgram?: string;
    slidingFeePlan?: string;
    documentType?: string;
    effectiveDate?: string;
    expirationDate?: string;
    isRyanWhite: boolean;
}

export interface IPolicyHolder {
    id: string;
    lastName?: string;
    firstName?: string;
    middleName?: string;
    chosenName?: string;
    suffix?: string;
    title?: string;
    dateOfBirth?: string;
    sexAtBirthId?: string;
    ssn?: string;
    relationship?: string;
    policyHolderAddressSameAsPatient: boolean;
    streetAddress1?: string;
    streetAddress2?: string;
    city?: string;
    state?: string;
    zip?: string;
}

export interface IPatientEligibility {
    annualMax?: number;
    deductible?: number;
    familyAnnualMax?: number;
    familyDeductible?: number;
    deductibleAmountRemaining?: number;
    insuranceAmountUsed?: number;
    insuranceAmountPending?: number;
    insuranceAmountAvailable?: number;
    preventativeBenefits?: number;
    basicBenefits?: number;
    majorBenefits?: number;
    sealantBenefits?: boolean;
    fluorideBenefits?: number;
    posteriorCompositeBenefits?: string;
    oralSurgeryBenefits?: string;
    majorServices?: string;
    waitingPeriod?: string;
    missingToothClause?: boolean;
    eligibilityLastChecked?: string;
    eligibilityMessage?: string;
    eligibilityReason?: string;
    eligibilityStatus?: string;
    benefitPeriod?: string;
    basicWaitingPeriod?: string;
    majorWaitingPeriod?: string;
    majorPeriod?: string;
    prophyBenefits?: number;
    bitewingBenefits?: number;
    fullXrayBenefits?: number;
    lastFullXrayBenefits?: string;
    hasFluorideAgeLimit?: boolean;
    fluorideAgeLimit?: number;
    hasSealantAgeLimit?: boolean;
    sealantAgeLimit?: number;
    lastVerified?: string;
    lastVerifiedBy?: string;
}

export enum PhoneNumberType {
    Mobile = 'Mobile',
    Home = 'Home',
    Work = 'Work',
}
export interface IPatientPhoneNumber {
    type: PhoneNumberType;
    number?: string;

    isPrimary: boolean;
    hasPermissionToText: boolean;
    hasPermissionToCall: boolean;
    hasPermissionToLeaveVoiceMail: boolean;
    isBadNumber: boolean;
    description?: string;
    isDeleted: boolean;
    extension?: string;
}

export interface IPatientAddress {
    streetAddress1?: string;
    streetAddress2?: string;
    city?: string;
    state?: string;
    zip?: string;
}

export interface IPatientNote {
    note?: string;
    modifiedOn?: string;
    modifiedBy?: string;
}

export interface IAddress {
    id?: string;
    streetAddress1: string;
    streetAddress2: string;
    city: string;
    state: string;
    zip: string;
    isDeleted?: boolean;
    typeId?: string;
    effectiveDate?: string;
    endDate?: string;
    description?: string;
}

export interface IPatientGuarantor {
    id: string;
    createdOn: string;
    createdBy?: string;
    modifiedOn: string;
    modifiedBy?: string;
    isDeleted: boolean;
    eTag?: string;
    lastName?: string;
    firstName?: string;
    middleName?: string;
    suffix?: string;
    chosenName?: string;
    titleId?: string;
    pronounId?: string;
    dateOfBirth?: string;
    sexAtBirthId?: string;
    phoneNumbers?: { [key: string]: string };
    emailAddress?: string;
    ssn?: string;
    relationshipId?: string;
    address: IPatientAddress;
}

export interface IPatientEmail {
    id: string;
    email: string;
    canContact: boolean;
    description: string;
    isDeleted: boolean;
}

type PatientUDSSupports = IdSupport & ETagSupport & ChangeAuditSupport & SoftDeleteSupport;
export interface IPatientUDS extends PatientUDSSupports {
    agriculturalWorker?: string;
    agriculturalWorkerDetail?: string;
    agriculturalWorkerLastUpdated?: string;
    homelessStatus?: string;
    homelessStatusDetail?: string;
    homelessStatusLastUpdated?: string;
    schoolbasedPatient?: string;
    schoolbasedLastUpdated?: string;
    veteranStatus?: string;
    veteranStatusLastUpdated?: string;
    publicHousing?: string;
    publicHousingLastUpdated?: string;
    familySize?: number;
    familySizeDeclined?: boolean;
    familySizeLastUpdated?: string;
    income?: number;
    incomePayPeriod?: string;
    annualIncome?: number;
    slidingFeeIncomeStatus?: string;
    incomePercentage?: number;
    incomeLastUpdated?: string;
    documents?: string[];
    sexAtBirthId?: string;
    incomeLevelReference?: IIncomeReference;
}

export interface IPatientSignature {
    privacyNoticeSignedDate?: string;
    billingReleaseSignedDate?: string;
    billingReleaseExpirationDate?: string;
    benefitAssignmentSignedDate?: string;
    benefitAssignmentExpirationDate?: string;
    signatureSourceId?: string;
    privacyNoticeSigned: boolean;
    billingReleaseSigned: boolean;
    benefitAssignmentSigned: boolean;
}

export interface IPovertyPercentage {
    personCount: number;
    totalIncome: number;
}

export interface IAnnualIncome {
    income: number;
    payPeriod: string;
}

/**
 * Imaging search model
 *
 * @export
 * @interface IPatientSearchResult
 *
 *
 *  id: string; <br />
 *   mrn: string; <br/>
 *   lastName: string;
 *   firstName: string;
 *   dateOfBirth: string //yyyy-MM-dd
 *
 */
export interface IPatientSearchResult extends DocumentBase {
    mrn?: string;
    lastName?: string;
    firstName?: string;
    chosenName?: string;
    dateOfBirth?: string; //yyyy-MM-dd
}

export interface IPatientImagingModel {
    patientId: string;
    mrn?: string;
    lastName?: string;
    firstName?: string;
    gender?: string;
    imagingSourceId?: string;
    dateOfBirth?: string;
}

export interface IAuditLog {
    entityType?: string;
    fieldName?: string;
    value?: string;
    modifiedBy?: string;
    modifiedOn: string;
    commandType?: string;
}
export interface IPatientAudit extends DocumentBase {
    audits?: IAuditLog[];
}

export enum PaymentMethod {
    Visa = 'Visa',
    Mastercard = 'Mastercard',
    AmEx = 'AmEx',
    Discover = 'Discover',
    Cash = 'Cash',
    Check = 'Check',
    Credit = 'Care Credit',
}

export type PaymentTypes = (typeof PaymentMethod)[keyof typeof PaymentMethod];

export enum PaymentModalType {
    Payment = 'Payment',
    PrePayment = 'Pre Payment',
    PaymentTotal = 'Payment Total',
    PaymentTotalPrePayment = 'Pre Payment - Payment Total ',
}
export type PaymentModalTypes = (typeof PaymentModalType)[keyof typeof PaymentModalType];
export interface IPayment {
    id?: string;
    postDate: string;
    locationOfCareId?: string;
    amount: number;
    paymentType?: PaymentTypes;
    checkNumber?: string;
    authNumber?: string;
    adjustmentType?: string;
    appointmentId?: string;
    encounterId?: string;
    typeOfPayment?: string;
    treatmentPlanProcedurePayments?: ITreatmentPlanProcedurePayment[];
}

export interface ITreatmentPlanProcedurePayment {
    treatmentPlanPhaseId?: string;
    treatmentPlanProcedureId?: string;
    treatmentPlanId?: string;
    insurancePayment: number;
    patientPayment: number;
}

export enum PatientStatus {
    Active = 'active',
    Inactive = 'inactive',
    Merged = 'merged',
    Deleted = 'deleted',
    Deceased = 'deceased',
    Prospective = 'prospective',
}

export default IPatient;
