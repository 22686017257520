import { IComboBoxOption } from '@fluentui/react';
import { IEncounterReason } from 'api/models/encounter.model';
import { LoadingStatuses } from 'interfaces/loading-statuses';
import { map, sortBy } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'state/store';

const encountersReasons = (state: RootState) => state.encounterReasons;
export const encounterReasonsLoading = (state: RootState): LoadingStatuses => state.encounterReasons.loading;
export const encountersReasonsData = createSelector(encountersReasons, (state) => (state.data ? state.data : {}));
export const encountersSearch = (state: RootState): string | undefined => state.encounterReasons.encountersSearch;

export const encountersReasonsAsList = createSelector([encountersReasonsData], (res) => {
    return map(res).filter((res) => res !== undefined) as IEncounterReason[];
});

export const encounterReasonsAsOptions = createSelector(
    [encountersReasonsData],
    (data) =>
        map(
            sortBy(data, (item) => item?.displayName.toLowerCase(), 'desc').filter((res) => res?.departmentType === 'Dental'),
        ).map((value) => ({
            key: value?.displayName,
            text: value?.displayName,
        })) as IComboBoxOption[],
);
