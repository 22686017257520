import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from '@fluentui/react';

const dialogContentProps = {
    type: DialogType.normal,
    title: 'Confirm',
    subText: 'You have modified this information. If you leave all entered data will be lost!',
};

function NoteCancellationModal() {
    const onConfirm = () => {
        //
    };
    const onCancel = () => {
        //
    };
    return (
        <Dialog dialogContentProps={dialogContentProps} hidden={true}>
            <DialogFooter>
                <DefaultButton onClick={onCancel} text="Cancel" />
                <PrimaryButton onClick={onConfirm} text="Confirm" />
            </DialogFooter>
        </Dialog>
    );
}

export default NoteCancellationModal;
