import {
    DefaultButton,
    MessageBar,
    MessageBarType,
    Panel,
    PanelType,
    PrimaryButton,
    Spinner,
    Stack,
    Text,
    TooltipHost,
    useTheme,
} from '@fluentui/react';
import { IBillingProcedure } from 'api/models/billing-procedure.model';
import { ReportType } from 'api/models/embed-report.model';
import { Section } from 'components';
import { generatePatientValidationConfig } from 'components/EditPatientPanel';
import { useSelector, useTenantId } from 'hooks';
import { SignalRMessage, useSignalR } from 'hooks/signalr/useSignalr';
import useValidation from 'hooks/useValidation';
import { Cookies } from 'interfaces/cookies';
import PatientDetailsCard from 'pages/components/PatientDetailsCard';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { cleanupChartTreatmentPlans } from 'state/slices/charting/chart/chart.slice';
import { getChartTreatmentPlans } from 'state/slices/charting/treatmentPlans/treatmentPlans.actions';
import { selectEditPatient, selectEditPatientValidationErrors } from 'state/slices/edit-patient/edit-patient.selectors';
import { cleanupEditPatientPanel, setEditPatientValidationErrors } from 'state/slices/edit-patient/edit-patient.slice';
import { handleCheckoutCalculationUpdate, initializePaymentModal } from 'state/slices/ledger/ledger.actions';
import { PostPaymentModalContext } from 'state/slices/ledger/ledger.state';
import { isSelectedUpcomingPhaseValidForAppointmentOperatory } from 'state/slices/lookups/operatories/operatories.selectors';
import { getPatientLedger } from 'state/slices/patient/ledger/ledger.actions';
import reportActionLookup from 'state/slices/reporting/reportActionLookup';
import { finishCheckout } from 'state/slices/scheduling/scheduling.actions';
import {
    selectCheckoutError,
    selectFinishCheckoutLoading,
    selectIsCheckoutPanelOpen,
    selectSelectedAppointmentData,
    selectSelectedCheckoutUpcomingPhaseBillingProcedures,
    selectSelectedCheckoutUpcomingPhases,
    selectUpcomingProceduresTotal,
} from 'state/slices/scheduling/scheduling.selectors';
import {
    cleanupCheckout,
    cleanupSelectedAppointment,
    returnToSchedulingFromCheckout,
    setAllCheckInSectionOpen,
    setIsCheckoutPanelOpen,
} from 'state/slices/scheduling/scheduling.slice';
import { getSlidingFeeProgramByPatient } from 'state/slices/tenant/sliding-fee.slice';
import { executeTask, getPatientTasksByGroup } from 'state/task-management/taskManagement.actions';
import {
    cleanupCheckoutCheckinTasks,
    selectCheckoutCheckinTasks,
    selectLoadingCheckoutCheckinTasks,
} from 'state/task-management/taskManagement.slice';
import { usdCurrencyFormatter } from 'utils';
import AppointmentInfoBanner from '../AppointmentInfoBanner';
import BasicDetailsSection from '../Checkin/BasicDetailsSection';
import FinanceSection from '../Checkin/FinanceSection';
import PayersSection from '../Checkin/PayerSection';
import CheckoutUnscheduledPhases from './CheckoutUnscheduledPhases';
import { PostPaymentModal } from './Payment/PostPaymentModal';
import { LoadingStatus } from 'interfaces/loading-statuses';
import ReportModal from 'pages/Reporting/ReportModal';
import { selectReportingState } from 'state/slices/reporting/reporting.selectors';
import { TaskGroup } from 'api/models/user-tasks-by-group';
import { appInsights } from 'index';
import CheckoutCompletedProcedures from './CheckoutCompletedProcedures';
import { useFeatureFlag } from 'hooks/useFeatureFlags';
import { FeatureFlag } from 'state/slices/tenant/feature-flags.slice';

//Do not use usePatientId as the patient id here because the id of the patient in checkout could be different than that of the url.
export default function CheckoutPanel(): JSX.Element {
    const dispatch = useDispatch();
    const tenantId = useTenantId();
    const date = new Date().toISOString();
    const theme = useTheme();

    const isCheckoutPanelOpen = useSelector(selectIsCheckoutPanelOpen);
    const _selectFinishCheckoutLoading = useSelector(selectFinishCheckoutLoading);
    const checkoutError = useSelector(selectCheckoutError);
    const editPatient = useSelector(selectEditPatient);
    const patientAppointment = useSelector(selectSelectedAppointmentData);

    const upcomingProceduresTotal = useSelector(selectUpcomingProceduresTotal);

    const _selectAllCheckoutCheckinTasks = useSelector(selectCheckoutCheckinTasks);

    const _tasksLoading = useSelector(selectLoadingCheckoutCheckinTasks) === LoadingStatus.Pending;

    const _selectSelectedCheckoutUpcomingPhases = useSelector(selectSelectedCheckoutUpcomingPhases);
    const selectedUpcomingPhaseBillingProcedures = useSelector(selectSelectedCheckoutUpcomingPhaseBillingProcedures);

    const _isSelectedUpcomingPhaseValidForAppointment = useSelector((state) =>
        isSelectedUpcomingPhaseValidForAppointmentOperatory(state, tenantId),
    );
    const validationErrors = useSelector(selectEditPatientValidationErrors);

    const { registerSignalRMessage, disposeSignalRMessage } = useSignalR();

    const { loadingSelectedReport } = useSelector(selectReportingState);
    const isLoadingReport = loadingSelectedReport === LoadingStatus.Pending;
    useEffect(() => {
        if (isCheckoutPanelOpen && editPatient && patientAppointment) {
            registerSignalRMessage(SignalRMessage.LedgerCalculationUpdate, (data) =>
                handleCheckoutCalculationUpdate(data, tenantId, editPatient.id, patientAppointment?.id),
            );
        } else {
            disposeSignalRMessage(SignalRMessage.LedgerCalculationUpdate);
        }
    }, [editPatient?.id, patientAppointment?.id, isCheckoutPanelOpen, tenantId]);

    useEffect(() => {
        if (isCheckoutPanelOpen && editPatient?.id) {
            dispatch(getSlidingFeeProgramByPatient({ tenantId, patientId: editPatient.id }));
            dispatch(getPatientLedger({ tenantId, patientId: editPatient.id }));
            dispatch(getChartTreatmentPlans({ tenantId, patientId: editPatient.id }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editPatient?.id, isCheckoutPanelOpen, tenantId]);

    useEffect(() => {
        return () => {
            _onDismiss();
            _onDismissed();
        };
    }, []);

    useEffect(() => {
        if (isCheckoutPanelOpen && patientAppointment && editPatient) {
            dispatch(
                getPatientTasksByGroup({
                    tenantId,
                    patientId: editPatient.id,
                    group: TaskGroup.Checkout,
                    references: { appointmentId: patientAppointment.id },
                }),
            );
        }
        // dispatch(getTaskByPatientAppointment({ tenantId, patientId: editPatient.id, appointmentId: patientAppointment?.id }));
    }, [editPatient?.id, isCheckoutPanelOpen, patientAppointment?.id, tenantId]);

    const onFinishCheckout = () => {
        if (patientAppointment) {
            appInsights.trackEvent({ name: 'Check-out (Save)', properties: { tenantId, appointmentId: patientAppointment.id } });
            dispatch(finishCheckout({ tenantId }));
        }
    };

    const _backToSchedulingFromCheckOut = () => {
        if (patientAppointment && editPatient) {
            appInsights.trackEvent({
                name: 'Check-out (Save and close)',
                properties: { tenantId, appointmentId: patientAppointment.id },
            });
            dispatch(returnToSchedulingFromCheckout({ tenantId, appointment: patientAppointment, patient: editPatient }));
        }
    };

    const _printWalkoutStatement = () => {
        if (patientAppointment?.encounterId)
            dispatch(
                reportActionLookup[ReportType.Walkout]({
                    tenantId,
                    encounterId: patientAppointment.encounterId,
                }),
            );
    };

    const patientReceipt = () => {
        if (editPatient?.id)
            dispatch(
                reportActionLookup[ReportType.PatientReceipt]({
                    date: [date],
                    tenantId,
                    patientId: editPatient.id,
                }),
            );
    };

    const isPatientReadOnly = useFeatureFlag(FeatureFlag.PatientReadOnly);
    const validationConfig = generatePatientValidationConfig(editPatient, isPatientReadOnly);

    const [finishCheckoutErrors, submitCheckout, cleanupFinishCheckoutErrors] = useValidation(validationConfig, onFinishCheckout);
    const [returnToSchedulingErrors, submitReturnToScheduling, cleanupReturnToSchedulingErrors] = useValidation(
        validationConfig,
        _backToSchedulingFromCheckOut,
    );

    useEffect(() => {
        dispatch(setEditPatientValidationErrors(finishCheckoutErrors));
    }, [finishCheckoutErrors]);
    useEffect(() => {
        dispatch(setEditPatientValidationErrors(returnToSchedulingErrors));
    }, [returnToSchedulingErrors]);

    useEffect(() => {
        if (validationErrors.length) {
            dispatch(setAllCheckInSectionOpen());
        }
    }, [validationErrors]);

    const _onDismiss = () => {
        dispatch(setIsCheckoutPanelOpen(false));
        dispatch(cleanupSelectedAppointment());
    };
    function _onDismissed() {
        // dispatch(cleanupPatientTasks());
        dispatch(cleanupCheckoutCheckinTasks());
        dispatch(cleanupEditPatientPanel());
        dispatch(cleanupCheckout());
        dispatch(cleanupChartTreatmentPlans());

        cleanupFinishCheckoutErrors();
        cleanupReturnToSchedulingErrors();
    }

    const [cookies] = useCookies();
    const handleOpenPaymentModal = (billingProcedures: IBillingProcedure[]) => {
        if (patientAppointment?.encounterId)
            dispatch(
                initializePaymentModal(
                    billingProcedures,
                    cookies[Cookies.SelectedBatch],
                    patientAppointment.patientId,
                    PostPaymentModalContext.Checkout,
                ),
            );
    };

    /**
     * Post for multiple phases.
     * Multi select phases from unscheduled phases.
     *
     * Problem is that you can't schedule a return appt if more than one phase is selected.
     *
     *
     */

    return (
        <Panel
            headerText="Appointment Checkout"
            type={PanelType.custom}
            customWidth="800px"
            isOpen={isCheckoutPanelOpen}
            onDismiss={_onDismiss}
            onDismissed={_onDismissed}
            isLightDismiss={false}
            styles={{
                content: { overflowY: 'auto', overflowX: 'hidden', flex: 1, position: 'relative' },
                root: { overflow: 'hidden' },
                scrollableContent: { overflow: 'hidden', display: 'flex', flexDirection: 'column' },
            }}
            isFooterAtBottom={true}
            onRenderFooterContent={() => (
                <Stack tokens={{ childrenGap: 5 }}>
                    {checkoutError && <MessageBar messageBarType={MessageBarType.error}>{checkoutError}</MessageBar>}
                    {validationErrors.length && (
                        <MessageBar messageBarType={MessageBarType.blocked}>
                            Missing required fields. ({validationErrors.map((error) => error.fieldName).join(', ')})
                        </MessageBar>
                    )}
                    <Stack horizontal horizontalAlign="space-between">
                        <Stack horizontal tokens={{ childrenGap: 5 }} verticalAlign="center">
                            <PrimaryButton
                                text="Finish Checkout"
                                onClick={submitCheckout}
                                disabled={_selectFinishCheckoutLoading}
                            />
                            <DefaultButton
                                text="Save & Close"
                                onClick={submitReturnToScheduling}
                                disabled={_selectFinishCheckoutLoading}
                            />
                            {_selectFinishCheckoutLoading && <Spinner label="Saving..." labelPosition="right" />}
                        </Stack>
                        <Stack horizontal tokens={{ childrenGap: 5 }}>
                            {isLoadingReport && <Spinner />}
                            <DefaultButton
                                text="Print Walkout Statement"
                                iconProps={{ iconName: 'Print' }}
                                disabled={isLoadingReport}
                                onClick={_printWalkoutStatement}
                            />
                        </Stack>
                    </Stack>
                </Stack>
            )}
        >
            <Stack tokens={{ childrenGap: 10 }}>
                {isCheckoutPanelOpen && <ReportModal />}
                <PatientDetailsCard showClinicalAlerts patient={editPatient} />
                <AppointmentInfoBanner showHygienist />
                <CheckoutCompletedProcedures
                    editPatient={editPatient}
                    handleOpenPaymentModal={handleOpenPaymentModal}
                    patientReceipt={patientReceipt}
                />

                {/* 
                    Ensure that we are taking the latest "SIGNED" treatment plan
                */}
                <Section
                    heading="Treatment Plan Phases"
                    headingRightContent={
                        <Stack grow tokens={{ childrenGap: 10 }} horizontal>
                            <DefaultButton
                                text="Enter PrePayment"
                                disabled={!_selectSelectedCheckoutUpcomingPhases[0]}
                                onClick={() => handleOpenPaymentModal(selectedUpcomingPhaseBillingProcedures)}
                            />

                            <TooltipHost
                                content={
                                    !_isSelectedUpcomingPhaseValidForAppointment
                                        ? 'Cannot schedule the patient appointment because the return date is outside the current operatory operating dates.'
                                        : undefined
                                }
                                delay={0}
                            >
                                <DefaultButton
                                    disabled={
                                        !_selectSelectedCheckoutUpcomingPhases.length ||
                                        !_selectSelectedCheckoutUpcomingPhases[0]?.returnAppointment?.references?.returnDate ||
                                        !_isSelectedUpcomingPhaseValidForAppointment
                                    }
                                    onClick={() => {
                                        if (_selectSelectedCheckoutUpcomingPhases[0]?.returnAppointment)
                                            dispatch(
                                                executeTask(
                                                    _selectSelectedCheckoutUpcomingPhases[0].returnAppointment,
                                                    _selectSelectedCheckoutUpcomingPhases[0],
                                                ),
                                            );
                                    }}
                                >
                                    Schedule Next Appt.
                                </DefaultButton>
                            </TooltipHost>
                        </Stack>
                    }
                >
                    <Stack tokens={{ childrenGap: 10 }}>
                        <CheckoutUnscheduledPhases />
                        <Stack
                            tokens={{ childrenGap: 10 }}
                            horizontal
                            horizontalAlign="end"
                            styles={{ root: { background: theme.palette.white, padding: 5 } }}
                        >
                            <Text>Patient Total</Text>
                            <Text>{usdCurrencyFormatter.format(upcomingProceduresTotal)}</Text>
                        </Stack>
                    </Stack>
                </Section>
            </Stack>
            <Stack tokens={{ childrenGap: 5 }}>
                {_tasksLoading ? (
                    <Spinner label="Loading Tasks..." />
                ) : (
                    !_selectAllCheckoutCheckinTasks.length && <MessageBar>There are no tasks to complete here.</MessageBar>
                )}
                <BasicDetailsSection />
                <PayersSection isCheckout />
                <FinanceSection />
            </Stack>
            <PostPaymentModal patientId={patientAppointment?.patientId} />
        </Panel>
    );
}
