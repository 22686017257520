import React, { useState, useEffect, useMemo } from 'react';
import {
    Panel,
    PrimaryButton,
    Stack,
    SelectionMode,
    PanelType,
    Selection,
    ISelection,
    IObjectWithKey,
    MessageBar,
    MessageBarType,
} from '@fluentui/react';
import { SortableDetailsList } from 'components';
import {
    getReversedPaymentSourcesFromPaymentSourceData,
    PaymentHistoryTableRow,
} from 'state/slices/ledger/payment-history/payment-history.selectors';
import dateOnly, { classicDateOnly } from 'utils/dateOnly';
import { usdCurrencyFormatter } from 'utils';
import { IPaymentSource } from 'api/models/payment-source.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import dentalApi from 'api/dental.api';
import { useParams } from 'react-router-dom';
import { RouteParams } from 'interfaces/route-params';
import { useSelector } from 'hooks';
import { selectReportingState } from 'state/slices/reporting/reporting.selectors';
import { useAppDispatch } from 'hooks/useAppDispatch';
import reportActionLookup from 'state/slices/reporting/reportActionLookup';
import { ReportType } from 'api/models/embed-report.model';

interface PrintReceiptsPanelProps {
    isOpen: boolean;
    onDismiss: () => void;
    paymentData: {
        id: string;
        transDate: string;
        batchName: string;
        amount: string;
    }[];
}

const _renderDateOfEntry = (item?: PaymentHistoryTableRow) => <span>{item ? classicDateOnly(item.dateOfEntry) : ''}</span>;
const _renderAmount = (item?: PaymentHistoryTableRow) => <span>{usdCurrencyFormatter.format(item?.amount ?? 0)}</span>;

const PrintReceiptsPanel: React.FC<PrintReceiptsPanelProps> = ({ isOpen, onDismiss }) => {
    const dispatch = useAppDispatch();
    const { tenantId, patientId } = useParams<RouteParams>();

    const [paymentSources, setPaymentSources] = useState<IPaymentSource[]>([]);
    const [selectedPaymentSources, setSelectedPaymentSources] = useState<IPaymentSource[]>([]);
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.Idle);

    const isLoading = loadingStatus === LoadingStatus.Pending;

    const { loadingSelectedReport: loadingPrintReport } = useSelector(selectReportingState);
    const isLoadingPrintReport = loadingPrintReport === LoadingStatus.Pending;

    const selection = useMemo(
        () =>
            new Selection({
                onSelectionChanged: () => {
                    setSelectedPaymentSources(selection.getSelection() as IPaymentSource[]);
                },
                items: paymentSources,
                getKey: (item) => item.id,
            }),
        [paymentSources],
    );

    useEffect(() => {
        if (isOpen) {
            getPaymentSources().then((data) => {
                const reversedPaymentSources = getReversedPaymentSourcesFromPaymentSourceData(data);
                setPaymentSources(
                    data.filter((source) => !reversedPaymentSources[source.id] && !source.references?.reversedPaymentSourceId),
                );
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    async function getPaymentSources(): Promise<IPaymentSource[]> {
        setLoadingStatus(LoadingStatus.Pending);
        try {
            const { data } = await dentalApi.getPatientPaymentHistory(tenantId, patientId);
            setLoadingStatus(LoadingStatus.Completed);
            return data.paymentSources ?? [];
        } catch (e) {
            setLoadingStatus(LoadingStatus.Failed);
            return [];
        }
    }

    const onDismissed = () => {
        setLoadingStatus(LoadingStatus.Idle);
        setPaymentSources([]);
        setSelectedPaymentSources([]);
    };

    const print = () => {
        if (patientId) {
            const date = selectedPaymentSources
                .filter((source) => !!source.createdOn)
                .map((source) => new Date(source.createdOn as string).toISOString());

            dispatch(
                reportActionLookup[ReportType.PatientReceipt]({
                    tenantId,
                    patientId,
                    date,
                }),
            ).then(() => {
                onDismiss();
            });
        }
    };

    const _renderCreatedOn = (item?: PaymentHistoryTableRow) => (
        <span>{item && item.createdOn ? dateOnly(item?.createdOn, 'Pp') : ''}</span>
    );

    return (
        <Panel
            type={PanelType.custom}
            headerText="Print Receipts"
            customWidth={'40vw'}
            isOpen={isOpen}
            onDismiss={onDismiss}
            onDismissed={onDismissed}
            closeButtonAriaLabel="Close"
        >
            <Stack tokens={{ childrenGap: 10 }}>
                {loadingStatus === LoadingStatus.Completed && !paymentSources.length ? (
                    <MessageBar messageBarType={MessageBarType.info}>No receipts available to print.</MessageBar>
                ) : (
                    <div style={{ overflowY: 'auto', maxHeight: 'auto' }}>
                        <SortableDetailsList<PaymentHistoryTableRow>
                            compact
                            selectionMode={SelectionMode.multiple}
                            selection={selection as unknown as ISelection<IObjectWithKey>}
                            items={paymentSources}
                            shimmerLines={3}
                            enableShimmer={isLoading}
                            columns={[
                                {
                                    key: 'createdOn',
                                    name: 'Trans. Date',
                                    fieldName: 'createdOn',
                                    minWidth: 150,
                                    maxWidth: 170,
                                    onRender: _renderCreatedOn,
                                },
                                {
                                    key: 'dateOfEntry',
                                    name: 'Batch Date',
                                    fieldName: 'dateOfEntry',
                                    minWidth: 120,
                                    maxWidth: 120,
                                    onRender: _renderDateOfEntry,
                                },
                                {
                                    key: 'amount',
                                    name: 'Amount',
                                    fieldName: 'amount',
                                    minWidth: 100,
                                    maxWidth: 130,
                                    onRender: _renderAmount,
                                },
                            ]}
                        />
                    </div>
                )}
                <PrimaryButton
                    text={isLoadingPrintReport ? 'Loading...' : 'Print'}
                    onClick={print}
                    disabled={!selectedPaymentSources.length || isLoadingPrintReport}
                />
            </Stack>
        </Panel>
    );
};

export default PrintReceiptsPanel;
