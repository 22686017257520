import { useLocation } from 'react-router';

import { SearchBox, Stack, IContextualMenuItem, useTheme, ITheme } from '@fluentui/react';
import './NavBar.scss';
import AvatarContainer from '../Avatar/Avatar.container';
import Logo from './Logo';
import PatientSelector from 'components/PatientSelector/PatientSelector';
import CustomerSelector from './CustomerSelector';
import LandingPageSelector from './LandingPageButton';
import SchedulingButton from './SchedulingButton';
import { PropsWithChildren } from 'react';
import ReportingButton from './ReportingButton';

type Props = {
    avatarMenuItems?: IContextualMenuItem[];
    preAppName?: JSX.Element;
    appName?: string;
    appNameLink?: string;
};
type CustomTheme = ITheme & { palette: { navbar: string } };

function NavBar(props: PropsWithChildren<Props>): JSX.Element {
    const location = useLocation();
    const isTenantSelected = location.pathname !== '/';
    const theme = useTheme();

    return (
        <Stack
            horizontal
            horizontalAlign="space-between"
            verticalAlign="center"
            className="app-nav-bar"
            style={{ background: (theme as CustomTheme).palette.navbar }}
        >
            <Stack
                horizontal
                horizontalAlign="start"
                tokens={{ childrenGap: 10 }}
                verticalAlign="center"
                style={{ height: '100%' }}
            >
                <Logo preAppName={props.preAppName} appName={props.appName} appNameLink={props.appNameLink} variant="medium" />
                {isTenantSelected && props.children}
                <Stack.Item>
                    <CustomerSelector />
                </Stack.Item>
            </Stack>
            <Stack tokens={{ childrenGap: 10 }} horizontal horizontalAlign="end" verticalAlign="center">
                <ReportingButton />
                <SchedulingButton />
                <LandingPageSelector />
                {isTenantSelected && <PatientSelector />}
                <AvatarContainer menuItems={props.avatarMenuItems} />
            </Stack>
        </Stack>
    );
}

export default NavBar;
