import { createAsyncThunk } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import { IBatch } from 'api/models/batch.model';
import { IBillingProcedure } from 'api/models/billing-procedure.model';
import {
    EncounterSummaryWithLineItems,
    EncounterSummaryWithLineItemsView,
    ICommonTransaction,
    TransactionSource,
    TransactionType,
} from 'api/models/encounter-ledger.model';
import { IPaymentSource } from 'api/models/payment-source.model';
import { batch } from 'react-redux';
import { AppThunk, RootState } from 'state/store';
import {
    cleanupAdjustmentInformation,
    cleanupAllowOverpaymentOrAdjustment,
    cleanupPaymentInformation,
    setPatientAdjustmentTransactions,
    setPatientEstimateTransactions,
    setPatientPaymentsAndAdjustmentsValidationErrors,
    setPatientPaymentSource,
    setPatientPaymentTransactions,
    updateAllPatientTransactionsBatchId,
    updatePatientAdjustmentTransaction,
    updatePatientEstimateTransaction,
    updatePatientPaymentSource,
    updatePatientPaymentTransaction,
} from '../ledger.slice';
import { v4 as uuid } from 'uuid';
import { ChartProcedureStatus } from 'api/models/chart.model';
import {
    selectPatientAdjustmentTransactions,
    selectPatientAdjustmentTransactionsAsList,
    selectPatientPaymentsAndAdjustmentsBillingProcedures,
    selectPatientPaymentsEstimateBillingProcedures,
    selectPatientPaymentSource,
    selectPatientPaymentTransactions,
    selectPatientPaymentTransactionsAsList,
} from './patient-payments-and-adjustments.selectors';
import { BillingProcedureWithTransactionIds, TransactionLookup } from './patient-payments-and-adjustments.state';
import { push } from 'connected-react-router';
import { getLedgerTotalsView } from '../ledger.actions';
import { selectSignalRIsConnected } from 'state/slices/signalr.slice';
import { orderBy } from 'lodash';
import { allPatientEncountersLookup } from 'state/slices/patient/patient.selectors';
import { IPatientEncounter } from 'api/models/encounter.model';
import axios from 'axios';

export const getPatientPaymentsAndAdjustmentsBillingProcedures = createAsyncThunk<
    IBillingProcedure[],
    { tenantId: string; patientId: string },
    { rejectValue: string }
>('getPatientPaymentsAndAdjustmentsBillingProcedures', async ({ tenantId, patientId }, { rejectWithValue, signal }) => {
    try {
        const source = axios.CancelToken.source();
        signal.addEventListener('abort', () => {
            source.cancel();
        });
        const { data } = await dentalApi.getBillingProceduresByType(tenantId, patientId, 'LedgerBillingProcedures', source.token);
        return data;
    } catch (e) {
        return rejectWithValue(e as string);
    }
});

export const getPatientEncounterSummariesWithLineItemsView = createAsyncThunk<
    EncounterSummaryWithLineItemsView,
    { tenantId: string; patientId: string },
    { rejectValue: string }
>('getPatientEncounterSummariesWithLineItemsView', async ({ tenantId, patientId }, { rejectWithValue, signal }) => {
    try {
        const source = axios.CancelToken.source();
        signal.addEventListener('abort', () => {
            source.cancel();
        });
        const { data } = await dentalApi.getPatientEncounterSummariesWithLineItemsView(tenantId, patientId, source.token);
        return data;
    } catch (e) {
        return rejectWithValue(e as string);
    }
});

export function getTransactionsToSave(transactions: ICommonTransaction[]): ICommonTransaction[] {
    return transactions
        .map((transaction) => ({ ...transaction, amount: -transaction.amount, createdOn: new Date().toISOString() }))
        .filter((transaction) => transaction.amount !== 0);
}

type SavePaymentInfoResponse = { paymentSource: IPaymentSource; transactions: ICommonTransaction[] };
export const savePatientPaymentSourceAndTransactions = createAsyncThunk<
    SavePaymentInfoResponse,
    { tenantId: string },
    { rejectValue: string; state: RootState }
>('savePatientPaymentsAndTransactions', async ({ tenantId }, { rejectWithValue, getState }) => {
    try {
        const patientPaymentSource = selectPatientPaymentSource(getState()) as IPaymentSource;
        const paymentTransactions = getTransactionsToSave(selectPatientPaymentTransactionsAsList(getState()));

        const { data: paymentSource } = await dentalApi.createPaymentSource(tenantId, patientPaymentSource);
        const { data: transactions } = await dentalApi.createLedgerTransaction(tenantId, paymentTransactions);

        return { paymentSource, transactions };
    } catch (e) {
        return rejectWithValue(e as string);
    }
});

export const savePatientAdjustmentTransactions = createAsyncThunk<
    ICommonTransaction[],
    { tenantId: string },
    { rejectValue: string; state: RootState }
>('savePatientAdjustmentTransactions', async ({ tenantId }, { rejectWithValue, getState }) => {
    try {
        const adjustmentTransactions = getTransactionsToSave(
            selectPatientAdjustmentTransactionsAsList(getState()) as ICommonTransaction[],
        );
        const { data: transactions } = await dentalApi.createLedgerTransaction(tenantId, adjustmentTransactions);

        return transactions;
    } catch (e) {
        return rejectWithValue(e as string);
    }
});

export const savePatientPaymentInfoAndAdjustmentInfo =
    (
        tenantId: string,
        patientId: string,
        encounterId: string | undefined,
        hideMakeAdjustments: boolean,
        hideMakePayments: boolean,
    ): AppThunk<void> =>
        async (dispatch, getState) => {
            const returnUrl = encounterId
                ? `/${tenantId}/patient/${patientId}/encounter/${encounterId}/ledger/patient-payments-and-adjustments`
                : `/${tenantId}/patient/${patientId}/ledger/patient-payments-and-adjustments`;
            try {
                await batch(async () => {
                    if (!hideMakePayments) await dispatch(savePatientPaymentSourceAndTransactions({ tenantId })).unwrap();
                    if (!hideMakeAdjustments) await dispatch(savePatientAdjustmentTransactions({ tenantId })).unwrap();
                });

                dispatch(push(returnUrl));
                const isSignalRConnected = selectSignalRIsConnected(getState());
                if (!isSignalRConnected) dispatch(getLedgerTotalsView({ patientId, tenantId }));
            } catch (e) {
                dispatch(push(returnUrl));
                console.error(e);
            }
        };

export const createPatientPaymentOrAdjustmentInformation =
    (patientId: string, hideMakePayments?: boolean, hideMakeAdjustments?: boolean, selectedBatch?: IBatch): AppThunk<void> =>
        (dispatch) => {
            if (!hideMakePayments) dispatch(createPatientPaymentInformation(patientId, selectedBatch));
            if (!hideMakeAdjustments) dispatch(createPatientAdjustmentInformation(patientId, selectedBatch));
        };

export const getTransactionLookup = ({
    billingProcedures,
    dateOfEntry,
    patientEncountersLookup,
    patientId,
    paymentSourceId,
    batchId,
    type,
}: {
    patientId: string;
    paymentSourceId: string;
    dateOfEntry: string;
    billingProcedures: BillingProcedureWithTransactionIds[];
    patientEncountersLookup: Record<string, IPatientEncounter>;
    batchId?: string;
    type?: TransactionType;
}) => {
    const transactions: TransactionLookup = {};

    orderBy(billingProcedures, (item) => patientEncountersLookup[item.encounterId ?? '']?.encounterDate ?? '').forEach(
        (procedure) => {
            if (procedure.encounterId && procedure.paymentTransactionId) {
                if (!transactions[procedure.encounterId]) transactions[procedure.encounterId] = {};

                //build chart procedure
                if (!transactions[procedure.encounterId][procedure.paymentTransactionId])
                    transactions[procedure.encounterId][procedure.paymentTransactionId] = {
                        amount: 0,
                        batchId,
                        chartProcedureId: procedure.id,
                        encounterId: procedure.status === ChartProcedureStatus.Completed ? procedure.encounterId : undefined,
                        patientId,
                        procedureCode: procedure.procedureCode,
                        dateOfEntry,
                        treatmentPlanId: procedure.treatmentPlanId,
                        treatmentPlanPhaseId: procedure.phaseId,
                        id: procedure.paymentTransactionId, //transaction id we added to the billing procedures on load
                        paymentSourceId: paymentSourceId,
                        source: TransactionSource.Patient,
                        type: type ?? TransactionType.Payment,
                        treatingProviderId: procedure.treatingProviderId,
                    };
            }
        },
    );

    return transactions;
};

export const createEstimateTransactions =
    (patientId: string, paymentSourceId: string, dateOfEntry: string, batchId?: string): AppThunk<void> =>
        (dispatch, getState) => {
            const billingProcedures = selectPatientPaymentsEstimateBillingProcedures(getState());
            const patientEncountersLookup = allPatientEncountersLookup(getState());

            dispatch(
                setPatientEstimateTransactions(
                    getTransactionLookup({
                        patientId,
                        paymentSourceId,
                        dateOfEntry,
                        billingProcedures,
                        patientEncountersLookup,
                        batchId,
                    }),
                ),
            );
        };

export const createPaymentTransactions =
    (patientId: string, paymentSourceId: string, dateOfEntry: string, batchId?: string): AppThunk<void> =>
        (dispatch, getState) => {
            const billingProcedures = selectPatientPaymentsAndAdjustmentsBillingProcedures(getState());
            const patientEncountersLookup = allPatientEncountersLookup(getState());

            dispatch(
                setPatientPaymentTransactions(
                    getTransactionLookup({
                        patientId,
                        paymentSourceId,
                        dateOfEntry,
                        billingProcedures,
                        patientEncountersLookup,
                        batchId,
                    }),
                ),
            );
        };

export const createPatientPaymentInformation =
    (patientId: string, selectedBatch?: IBatch): AppThunk<void> =>
        (dispatch) => {
            const dateOfEntry = selectedBatch?.dateOfEntry ?? '';

            const paymentSource: IPaymentSource = {
                amount: 0,
                id: uuid(),
                dateOfEntry,
                isDeleted: false,
                paymentDate: dateOfEntry,
            };

            batch(() => {
                dispatch(createPaymentTransactions(patientId, paymentSource.id, dateOfEntry, selectedBatch?.id));
                dispatch(setPatientPaymentSource(paymentSource));
            });
        };

export const createPatientAdjustmentInformation =
    (patientId: string, selectedBatch?: IBatch): AppThunk<void> =>
        (dispatch, getState) => {
            const billingProcedures = selectPatientPaymentsAndAdjustmentsBillingProcedures(getState());
            const patientEncountersLookup = allPatientEncountersLookup(getState());
            const dateOfEntry = selectedBatch?.dateOfEntry ?? '';

            const transactions: TransactionLookup = {};
            orderBy(billingProcedures, (item) => patientEncountersLookup[item.encounterId ?? '']?.encounterDate ?? '').forEach(
                (procedure) => {
                    if (procedure.encounterId && procedure.adjustmentTransactionId) {
                        if (!transactions[procedure.encounterId]) transactions[procedure.encounterId] = {};

                        //build chart procedure
                        if (!transactions[procedure.encounterId][procedure.adjustmentTransactionId])
                            transactions[procedure.encounterId][procedure.adjustmentTransactionId] = {
                                amount: 0,
                                batchId: selectedBatch?.id,
                                chartProcedureId: procedure.id,
                                encounterId: procedure.status === ChartProcedureStatus.Completed ? procedure.encounterId : undefined,
                                patientId,
                                procedureCode: procedure.procedureCode,
                                dateOfEntry,
                                treatmentPlanId: procedure.treatmentPlanId,
                                treatmentPlanPhaseId: procedure.phaseId,
                                id: procedure.adjustmentTransactionId, //transaction id we added to the billing procedures on load
                                source: TransactionSource.Patient,
                                type: TransactionType.Adjustment,
                            };
                    }
                },
            );

            dispatch(setPatientAdjustmentTransactions(transactions));
        };

/**
 * Called to cleanup payment/adjustment info whenever the PaymentsAndAdjustmentsList component is unloaded.
 *
 * @return {*}  {AppThunk<void>}
 */
export const cleanupMakePatientPaymentsOrAdjustmentsPage = (): AppThunk<void> => (dispatch) => {
    batch(() => {
        dispatch(cleanupAllowOverpaymentOrAdjustment());
        dispatch(cleanupAdjustmentInformation());
        dispatch(cleanupPaymentInformation());
        dispatch(setPatientPaymentsAndAdjustmentsValidationErrors([]));
    });
};

export const updatePatientPaymentSourceAndTransactionsBatchId =
    (selectedBatch: IBatch | undefined): AppThunk<void> =>
        (dispatch) => {
            if (selectedBatch) {
                dispatch(updatePatientPaymentSource({ path: 'dateOfEntry', value: selectedBatch.dateOfEntry }));
                dispatch(updateAllPatientTransactionsBatchId(selectedBatch));
            }
        };

export const distributeAndUpdatePatientPaymentTransactions =
    (
        totalPayment: number,
        encounterSummary: EncounterSummaryWithLineItems,
        feeField: 'patientBalance' | 'patientEstimate',
    ): AppThunk<void> =>
        (dispatch, getState) => {
            const { encounterId } = encounterSummary;

            if (encounterId !== undefined) {
                const adjustmentTransactions = selectPatientAdjustmentTransactions(getState());
                let totalPaymentAmountRemaining = totalPayment;

                encounterSummary.procedureSummaries?.forEach((procedureSummary) => {
                    const transactionId = procedureSummary.patientTransactionId ?? '';
                    const adjustmentTransactionId = procedureSummary.adjustmentTransactionId ?? '';
                    const encounterId = procedureSummary.encounterId ?? '';

                    const adjustmenTransactionForLineItem =
                        adjustmentTransactions?.[encounterId]?.[adjustmentTransactionId]?.amount ?? 0;

                    const fee = (procedureSummary[feeField] ?? 0) - adjustmenTransactionForLineItem;

                    if (fee > 0) {
                        const transactionAmount = Number(
                            (fee > totalPaymentAmountRemaining ? totalPaymentAmountRemaining : fee).toFixed(2),
                        );

                        totalPaymentAmountRemaining -= transactionAmount;

                        const updatePatientBalanceEstimateTransactionActionLookup = {
                            patientBalance: updatePatientPaymentTransaction,
                            patientEstimate: updatePatientEstimateTransaction,
                        };

                        dispatch(
                            updatePatientBalanceEstimateTransactionActionLookup[feeField]({
                                encounterId,
                                transactionId: transactionId,
                                amount: transactionAmount,
                            }),
                        );
                    }
                });
            }
        };

export const distributeAndUpdatePatientAdjustmentTransactions =
    (totalPayment: number, encounterSummary: EncounterSummaryWithLineItems): AppThunk<void> =>
        (dispatch, getState) => {
            const { encounterId } = encounterSummary;

            if (encounterId !== undefined) {
                const paymentTransactions = selectPatientPaymentTransactions(getState());
                let totalPaymentAmountRemaining = totalPayment;

                encounterSummary.procedureSummaries?.forEach((procedureSummary) => {
                    const transactionId = procedureSummary.adjustmentTransactionId ?? '';
                    const paymentTransactionId = procedureSummary.patientTransactionId ?? '';
                    const encounterId = procedureSummary.encounterId ?? '';

                    const paymentTransactionForLineItem = paymentTransactions?.[encounterId]?.[paymentTransactionId]?.amount ?? 0;
                    const patientBalance = (procedureSummary.patientBalance ?? 0) - paymentTransactionForLineItem;

                    if (patientBalance > 0) {
                        const transactionAmount = Number(
                            (patientBalance > totalPaymentAmountRemaining ? totalPaymentAmountRemaining : patientBalance).toFixed(2),
                        );

                        totalPaymentAmountRemaining -= transactionAmount;

                        dispatch(
                            updatePatientAdjustmentTransaction({
                                encounterId,
                                transactionId: transactionId,
                                amount: transactionAmount,
                            }),
                        );
                    }
                });
            }
        };
