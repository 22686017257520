import Tooth from './Tooth';
import * as PIXI from 'pixi.js-legacy';
import PixiText from './PixiText';

export class Watch extends PIXI.Container {
    private _tooth: Tooth;
    private _toothWidth: number;

    constructor(tooth: Tooth) {
        super();
        this.name = "Watch"
        this._tooth = tooth;
        this._toothWidth = tooth.getToothWidth;
        this.setParent(tooth);
        this._createWatch();
    }

    public get getIsBottomRow() {
        return this._tooth.getToothPosition - 1 >= 16;
    }

    private _createWatch() {
        const text = new PixiText('W');

        text.anchor.set(0.5);
        text.y = !this.getIsBottomRow ? -this._tooth.height + 60 : this._tooth.height + 50
        text.x = this._toothWidth / 2;
        this.addChild(text)
    }
}