import { MessageBar, Stack } from '@fluentui/react';
import { Section } from 'components';
import TreatmentPlanPhase from './TreatmentPlanPhase';
import { useSelector } from 'hooks';
import { selectCurrentTreatmentPlanPhases } from 'state/slices/charting/treatmentPlans/treatmentPlans.selectors';
import TreatmentPlanPhaseFinancials from './TreatmentPlanPhaseFinancials';

function TreatmentPlanPhases(): JSX.Element {
    const phasesList = useSelector(selectCurrentTreatmentPlanPhases);

    const _phases = phasesList.map((treatmentPlanPhase) => (
        <TreatmentPlanPhase phase={treatmentPlanPhase} key={treatmentPlanPhase.id} />
    ));

    return _phases.length ? (
        <Section>
            <Stack tokens={{ childrenGap: 20 }} grow>
                <Stack tokens={{ childrenGap: 10 }}>{_phases}</Stack>
                <TreatmentPlanPhaseFinancials />
            </Stack>
        </Section>
    ) : (
        <MessageBar>No phases have been added to this treatment plan yet.</MessageBar>
    );
}

export default TreatmentPlanPhases;
