import { ITextFieldProps, TextField } from '@fluentui/react';
import { useDebounce } from 'hooks';
import { useEffect, useState, FormEvent } from 'react';
// https://usehooks.com/useDebounce/
type Props = {
    onValueChange: (value?: string) => void;
    initialValue?: string;
} & ITextFieldProps;
export default function DebouncedTextField({ onValueChange, initialValue, ...props }: Props): JSX.Element {
    const [value, setValue] = useState<string>();
    const debouncedValue = useDebounce(value, 200);

    useEffect(() => {
        setValue(initialValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (debouncedValue !== undefined) onValueChange(debouncedValue);
    }, [debouncedValue, onValueChange]);

    const _onChange = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
        setValue(value);
    };

    return <TextField onChange={_onChange} autoComplete="off" {...props} value={value} />;
}
