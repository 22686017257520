import { IChartProcedure } from 'api/models/chart.model';
import { find, flatten, intersectionBy, sortBy, uniq, uniqBy } from 'lodash';
import { RootState } from 'state/store';
import { getTeethDisplayName } from 'utils';
import getShorthandToothArea from 'utils/getShorthandToothArea';

export default function CompletedProcedures({ state }: { state: RootState }): JSX.Element | null {
    const procedures = state.chartNotes.quickNotes.assets.chartProcedures.completed;

    if (procedures.length === 0) return null;
    return (
        <>
            <h3>Completed Procedures</h3>
            <ProcedureNoteTemplate chartProcedures={procedures} state={state} />
        </>
    );
}

export function ProcedureNoteTemplate({
    chartProcedures,
    state,
}: {
    chartProcedures: IChartProcedure[];
    state: RootState;
}): JSX.Element {
    const procedures = state.tenant.procedures.data;
    const procedureListItems = uniqBy(chartProcedures, 'procedureId').map((chartProcedure, i) => {
        const procedure = find(procedures, (p) => p?.id === chartProcedure.procedureId);

        const procedureDisplayText = procedure ? `${procedure.code} - ${procedure.displayName}` : 'Procedure';
        const teethAndAreasText = getTeethAndArea(chartProcedure.procedureId);
        const diagnosisCodeText = getDiagnosisCodes(chartProcedure.procedureId);
        const stagesText = getStageText(chartProcedure.procedureId);

        return (
            <li key={`${chartProcedure.id}-${i}`}>
                {procedureDisplayText}
                <ul>
                    {teethAndAreasText && <li>Teeth: {teethAndAreasText}</li>}
                    {stagesText && <li>Stages: {stagesText}</li>}
                    {diagnosisCodeText && <li>Diagnoses: {diagnosisCodeText}</li>}
                </ul>
            </li>
        );
    });

    function getTeethAndArea(procedureId?: string) {
        const matchingChartProcedures = chartProcedures.filter((chartProcedure) => chartProcedure.procedureId === procedureId);
        const procArray: IChartProcedure[] = [];

        const procedures = matchingChartProcedures.filter((p) => !p.stage);

        // Filter out stage procedures that might have duplicate toothIds, we only want one "set" regardless of the amount of ChartProcedures included in the stages
        const stageProcedures = intersectionBy(
            matchingChartProcedures.filter((p) => p.stage !== undefined),
            (a) => a.toothIds?.toString(),
        );

        sortAndAddProcedures(procedures);
        sortAndAddProcedures(stageProcedures);

        const items = procArray
            .map((chartProcedure) => {
                const teethDisplayNames = getTeethDisplayName(chartProcedure.toothIds);
                const areas = getShorthandToothArea(chartProcedure.areas);
                return `${teethDisplayNames ?? ''}${areas ? ` (${areas})` : ''}`;
            })
            .filter((item) => item !== '');

        return items.length ? items.join(' | ') : '';

        // Sort procedures by tooth count and push sequentially to array so display string shows teeth "ascending"
        function sortAndAddProcedures(procedures: IChartProcedure[]) {
            procedures
                .map((proc) => ({ ...proc, toothCount: proc.toothIds?.reduce((a, b) => a + b, 0) }))
                .sort((a, b) => (a.toothCount ?? 0) - (b.toothCount ?? 0))
                .forEach((p) => procArray.push(p));
        }
    }

    function getDiagnosisCodes(procedureId?: string) {
        const matchingChartProcedures = chartProcedures.filter((chartProcedure) => chartProcedure.procedureId === procedureId);

        const diagnosisCodes = uniq(
            flatten(
                matchingChartProcedures.map((proc) => {
                    return proc.diagnosisCodes?.map((dxId) => find(state.tenant.diagnoses.data, ['id', dxId])?.code);
                }),
            ),
        );
        return diagnosisCodes.join(', ');
    }

    function getStageText(procedureId?: string) {
        const orderedProceduresStages = {
            One: 1,
            Two: 2,
            Three: 3,
            Four: 4,
            Final: 5,
        };
        const matchingChartProcedures = chartProcedures
            .filter((chartProcedure) => chartProcedure.procedureId === procedureId)
            .filter((chartProcedures) => chartProcedures.stage);

        const stages = sortBy(flatten(matchingChartProcedures.map((proc) => proc.stage)), (s) => {
            if (s) return orderedProceduresStages[s];
        });

        return stages.join(', ');
    }

    return <ul>{procedureListItems}</ul>;
}
