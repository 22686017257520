import { Graphics } from '@inlet/react-pixi/legacy';
import { forEach } from 'lodash';
import { Graphics as IGraphics } from 'pixi.js-legacy';
import { useCallback } from 'react';
import { TOOTH_START, LINE_SPACING, TOOTH_CONTAINER_WIDTH, TOOTH_MIDLINE, TOOTH_END, STAGE_WIDTH } from '../../perio-settings';

function DataLines({
    probingDepths,
    marginalDepths,
}: {
    position?: number;
    probingDepths?: number[][];
    marginalDepths?: number[][];
}): JSX.Element {
    const draw = useCallback(
        (g: IGraphics) => {
            g.clear();

            if (probingDepths?.length && marginalDepths?.length) {
                const generatedVertices: number[] = [];
                forEach(probingDepths, (pDepth, index) => {
                    const d1 = pDepth[0] * LINE_SPACING + (marginalDepths?.length ? marginalDepths[index][0] * LINE_SPACING : 0);
                    const d2 = pDepth[1] * LINE_SPACING + (marginalDepths?.length ? marginalDepths[index][1] * LINE_SPACING : 0);
                    const d3 = pDepth[2] * LINE_SPACING + (marginalDepths?.length ? marginalDepths[index][2] * LINE_SPACING : 0);

                    const INDEX_WIDTH = TOOTH_CONTAINER_WIDTH * index;
                    const x1 = TOOTH_START + INDEX_WIDTH;
                    const x2 = TOOTH_MIDLINE + INDEX_WIDTH;
                    const x3 = TOOTH_END + INDEX_WIDTH;

                    generatedVertices.push(x1, d1, x2, d2, x3, d3);
                });

                for (let i = marginalDepths.length - 1; i >= 0; i--) {
                    const mDepth = marginalDepths[i];

                    const d1 = mDepth[0] * LINE_SPACING;
                    const d2 = mDepth[1] * LINE_SPACING;
                    const d3 = mDepth[2] * LINE_SPACING;

                    const INDEX_WIDTH = TOOTH_CONTAINER_WIDTH * i;
                    const x1 = TOOTH_START + INDEX_WIDTH;
                    const x2 = TOOTH_MIDLINE + INDEX_WIDTH;
                    const x3 = TOOTH_END + INDEX_WIDTH;

                    generatedVertices.push(x3, d3, x2, d2, x1, d1);
                }

                g.beginFill(0x4287f5, 0.5);
                g.drawPolygon(generatedVertices);
                g.endFill();
            }

            if (probingDepths?.length) {
                g.lineStyle(3, 0x0000ff, 0.5);
                g.moveTo(TOOTH_START, probingDepths[0][0] * LINE_SPACING);

                forEach(probingDepths, (depth, index) => {
                    const d1 = depth[0] * LINE_SPACING + (marginalDepths?.length ? marginalDepths[index][0] * LINE_SPACING : 0);
                    const d2 = depth[1] * LINE_SPACING + (marginalDepths?.length ? marginalDepths[index][1] * LINE_SPACING : 0);
                    const d3 = depth[2] * LINE_SPACING + (marginalDepths?.length ? marginalDepths[index][2] * LINE_SPACING : 0);

                    const INDEX_WIDTH = TOOTH_CONTAINER_WIDTH * index;
                    const x1 = TOOTH_START + INDEX_WIDTH;
                    const x2 = TOOTH_MIDLINE + INDEX_WIDTH;
                    const x3 = TOOTH_END + INDEX_WIDTH;

                    g.lineTo(x1, d1);
                    g.lineTo(x2, d2);
                    g.lineTo(x3, d3);
                });
            } else {
                g.lineTo(STAGE_WIDTH, 0);
            }

            if (marginalDepths?.length) {
                g.lineStyle(3, 0xff0000, 0.5);
                g.moveTo(TOOTH_START, marginalDepths[0][0] * LINE_SPACING);
                forEach(marginalDepths, (depth, index) => {
                    const d1 = depth[0] * LINE_SPACING;
                    const d2 = depth[1] * LINE_SPACING;
                    const d3 = depth[2] * LINE_SPACING;

                    const INDEX_WIDTH = TOOTH_CONTAINER_WIDTH * index;
                    const x1 = TOOTH_START + INDEX_WIDTH;
                    const x2 = TOOTH_MIDLINE + INDEX_WIDTH;
                    const x3 = TOOTH_END + INDEX_WIDTH;

                    g.lineTo(x1, d1);
                    g.lineTo(x2, d2);
                    g.lineTo(x3, d3);
                });
            } else {
                g.lineTo(STAGE_WIDTH, 0);
            }
        },

        [probingDepths, marginalDepths],
    );

    return <Graphics draw={draw} />;
}

export default DataLines;
