import { DocumentBase } from 'api/document-base.model';
import { DisplayNameSupport, SoftDeleteSupport } from 'models/contracts';
import { IBillingProcedure } from './billing-procedure.model';
import { IdSupport } from './support-types';


export enum ChartTreatmentPlanStatus {
    Pending = 'Pending',
    Finalized = 'Finalized',
    Signed = 'Signed',
    Declined = 'Declined',
}

export interface IChartTreatmentPlan extends DocumentBase {
    encounterId?: string;
    patientId?: string;
    status?: ChartTreatmentPlanStatus;
    coordinatorId?: string;
    financialOptionsNote?: string;
    disclaimer?: string;
    signedDate?: string;
    refusedToSign?: boolean;
    signature?: string;
    phases?: IChartTreatmentPlanPhase[];
    procedures?: IChartTreatmentPlanPhaseProcedure[];
}

export enum ChartTreatmentPlanPhaseStatus {
    Completed = 'Completed',
}

// nullability

type ChartTreatmentPlanPhaseSupports = IdSupport & DisplayNameSupport & SoftDeleteSupport;
export interface IChartTreatmentPlanPhase extends ChartTreatmentPlanPhaseSupports {
    completedDate?: string;
    status?: ChartTreatmentPlanPhaseStatus;
    returnDate?: string;
}

export interface IChartTreatmentPlanPhaseProcedure extends IBillingProcedure {
    phaseId?: string;
}

export interface IPendingChartTreatmentPlanPhaseProcedure {
    procedurePlanPhaseId?: string;
    treatmentPlanPhaseId?: string;
    treatmentPlanPhaseDisplayName?: string;
    signedDate?: string;
    procedureCode?: string;
    procedureDescription?: string;
    preAuthStatus?: string;
    patientCost?: number;
    provider?: string;
}
