import { IChartDentition } from 'api/models/chart-dentition.model';

const mixedDentitions: IChartDentition[] = [
    // Upper Arch
    {
        id: '1',
        isDeleted: false,
        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '2',
        isDeleted: false,
        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '4',
        isDeleted: false,
        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '5',
        isDeleted: false,
        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '6',
        isDeleted: false,
        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '11',
        isDeleted: false,
        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '12',
        isDeleted: false,
        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '13',
        isDeleted: false,
        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '15',
        isDeleted: false,
        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '16',
        isDeleted: false,
        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    //Lower Arch
    {
        id: '17',
        isDeleted: false,
        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '18',
        isDeleted: false,
        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '20',
        isDeleted: false,
        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '21',
        isDeleted: false,
        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '22',
        isDeleted: false,
        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '27',
        isDeleted: false,
        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '28',
        isDeleted: false,
        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '29',
        isDeleted: false,
        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '31',
        isDeleted: false,
        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '32',
        isDeleted: false,
        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
];

export default mixedDentitions;
