import { CheckInSections } from 'state/slices/scheduling/scheduling.slice';
import CheckInSection from './CheckInSection';
import UDS from 'components/PatientDemographics/Finance';
import { useSelector } from 'hooks';
import {
    selectFinanceInfoCheckoutCheckInTasks,
    selectPatientAppointmentFinanceCheckoutCheckinTasks,
} from 'state/task-management/taskManagement.slice';
import UserTasksBadge from 'components/UserTasks/UserTasksBadge';

export default function FinanceSection(): JSX.Element | null {
    const financeInfoTasks = useSelector(selectFinanceInfoCheckoutCheckInTasks);
    const _selectPatientAppointmentFinanceTasks = useSelector(selectPatientAppointmentFinanceCheckoutCheckinTasks);

    const financeTasks = [..._selectPatientAppointmentFinanceTasks, ...financeInfoTasks];

    return (
        <CheckInSection
            leftContent={<>{financeTasks.length ? <UserTasksBadge size="small" userTasks={financeTasks} /> : null}</>}
            section={CheckInSections.uds}
            animation={false}
        >
            <UDS />
        </CheckInSection>
    );
}
