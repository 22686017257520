import { IColumn, MessageBar, Spinner, Text } from '@fluentui/react';
import { useSelector } from 'hooks';
import useOverview from 'hooks/store/useOverview';
import { LoadingStatus } from 'interfaces/loading-statuses';
import {
    MappedAllergy,
    selectAllergyTableData,
    selectPatientAllergies,
    showAllergyHistory,
} from 'state/slices/patient/allergies/allergies.selectors';
import SharedDetailsList from '../Shared/SharedDetailsList';

function AllergiesDetailsList(): JSX.Element {
    const allergyTableData = useSelector(selectAllergyTableData);
    const { allergiesLoading } = useOverview();
    const patientAllergies = useSelector(selectPatientAllergies);
    const showHistory = useSelector(showAllergyHistory);

    function renderColumnLabel(item: MappedAllergy, prop: keyof MappedAllergy) {
        return <Text variant="mediumPlus">{item.isDeleted ? <i>{item[prop]}</i> : item[prop]}</Text>;
    }

    const columns: IColumn[] = [
        {
            key: 'allergenName',
            minWidth: 150,
            maxWidth: 150,
            name: 'Name',
            fieldName: 'name',
            onRender: (item?: MappedAllergy) => {
                if (item) {
                    return renderColumnLabel(item, 'name');
                }
            },
        },
        {
            key: 'criticality',
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            name: 'Criticality',
            fieldName: 'criticality',
            onRender: (item?: MappedAllergy) => {
                if (item) {
                    return renderColumnLabel(item, 'criticality');
                }
            },
        },
        {
            key: 'reaction',
            minWidth: 300,
            maxWidth: 500,
            isResizable: true,
            name: 'Reaction(s)',
            fieldName: 'reaction',
            onRender: (item?: MappedAllergy) => {
                if (item) {
                    return renderColumnLabel(item, 'reaction');
                }
            },
        },
        {
            key: 'onsetDate',
            minWidth: 200,
            maxWidth: 150,
            name: 'Onset Date',
            fieldName: 'onsetDate',
            onRender: (item?: MappedAllergy) => {
                if (item) {
                    return renderColumnLabel(item, 'onsetDate');
                }
            },
        },
    ];

    if (showHistory)
        columns.push({
            key: 'reason',
            minWidth: 100,
            maxWidth: 200,
            name: 'Reason',
            fieldName: 'isDeleted',
            onRender: (item?: MappedAllergy) => (
                <Text variant="mediumPlus">
                    <i>{item?.isDeleted ? 'Added in error' : ''}</i>
                </Text>
            ),
        });

    if (!allergyTableData.length && allergiesLoading === LoadingStatus.Completed) {
        const text = patientAllergies?.nkda ? 'No known drug allergies' : 'No allergies have been added.';
        return <MessageBar>{text}</MessageBar>;
    }
    return (
        <>
            {allergiesLoading === LoadingStatus.Pending ? (
                <Spinner label="Loading allergies list..." labelPosition="right" />
            ) : (
                <SharedDetailsList
                    columns={columns}
                    sortOnMount={true}
                    mountSortColumn={['allergenName']}
                    onActiveItemChanged={undefined}
                    items={allergyTableData || []}
                />
            )}
        </>
    );
}

export default AllergiesDetailsList;
