import { ActionReducerMapBuilder, PayloadAction, SerializedError, createAsyncThunk } from '@reduxjs/toolkit';
import { LookupsState } from './lookupsStateTypes';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { GetLookupsRequest, GetLookupRequest, UpsertLookupRequest, SetSelectedLookupRequest } from 'models/requestTypes';
import lookupsApi from 'api/lookups.api';
import axios from 'axios';

const getLookupsFromState = (state: LookupsState, { tenantId, lookupType }: GetLookupsRequest) => {
    state.tenants[tenantId] = state.tenants?.[tenantId] ?? {};
    state.tenants[tenantId][lookupType] = state.tenants[tenantId][lookupType] ?? {};
    return state.tenants[tenantId][lookupType];
};

type QueryItemsActionType = {
    requestId: string;
    requestStatus: string;
    arg: GetLookupsRequest;
};

type UpsertActionType = {
    requestId: string;
    requestStatus: string;
    arg: UpsertLookupRequest;
};

type UpsertActionsType = {
    requestId: string;
    requestStatus: string;
    arg: UpsertLookupRequest[];
};

export const reducers = {
    setSelectedLookupItemProp: (
        state: LookupsState,
        action: PayloadAction<{ tenantId: string; lookupType: string; propPath: string; value: unknown }>,
    ) => {
        const lookups = getLookupsFromState(state, action.payload);
        const { propPath, value } = action.payload;
        if (lookups.selectedItem?.item) {
            lookups.selectedItem.item[propPath] = value;
        }
    },
    setSelectedLookup: (state: LookupsState, action: PayloadAction<SetSelectedLookupRequest>) => {
        const lookups = getLookupsFromState(state, action.payload);
        lookups.selectedItem = action.payload.entity;
    },
    updateLookupItem: (
        state: LookupsState,
        action: PayloadAction<{ tenantId: string; lookupType: string; entityId: string; propPath: string; value: any }>,
    ) => {
        const { lookupType, tenantId, entityId, propPath, value } = action.payload;
        if (state.tenants[tenantId] && state.tenants[tenantId][lookupType]) {
            const currentItem = state.tenants[tenantId][lookupType].items[entityId];
            state.tenants[tenantId][lookupType].items[entityId] = { ...currentItem, [propPath]: value };
        }
    },
};

export const getLookups = createAsyncThunk<any, GetLookupsRequest>('getLookups', async (request) => {
    const result = await lookupsApi.getLookups(request);
    return result.data;
});

export const getLookupById = createAsyncThunk<any, GetLookupRequest>('getLookupById', async (request) => {
    const result = await lookupsApi.getLookupById(request);
    return result.data;
});

export const createLookup = createAsyncThunk<any, UpsertLookupRequest>('createLookup', async (request) => {
    const result = await lookupsApi.postLookup(request);
    return result.data;
});

export const updateLookup = createAsyncThunk<any, UpsertLookupRequest>('updateLookup', async (request) => {
    const result = await lookupsApi.putLookup(request);
    return result.data;
});

export const updateLookups = createAsyncThunk<any[], UpsertLookupRequest[]>('updateLookups', async (requests) => {
    const mappedRequests = requests.map((r) => lookupsApi.putLookup(r));
    const result = await axios.all(mappedRequests);
    const tenantId = requests[0].tenantId;
    const lookupType = requests[0].lookupType;
    return result.map((r) => ({ tenantId, lookupType, data: r.data }));
});

const getLookupsPending = (state: LookupsState, action: PayloadAction<any, string, QueryItemsActionType, never>) => {
    const lookups = getLookupsFromState(state, action.meta.arg);
    lookups.loadingStatus = LoadingStatus.Pending;
};

const getLookupsCompleted = (state: LookupsState, action: PayloadAction<any, string, QueryItemsActionType, never>) => {
    const lookups = getLookupsFromState(state, action.meta.arg);

    lookups.items = action.payload;
    lookups.errors = undefined;
    lookups.loadingStatus = LoadingStatus.Completed;
};

const getLookupsRejected = (state: LookupsState, action: PayloadAction<any, string, QueryItemsActionType, SerializedError>) => {
    const lookups = getLookupsFromState(state, action.meta.arg);
    lookups.items = {};
    lookups.errors = [action.error];
    lookups.loadingStatus = LoadingStatus.Failed;
};

const upsertLookupPending = (state: LookupsState, action: PayloadAction<any, string, UpsertActionType, never>) => {
    const lookups = getLookupsFromState(state, action.meta.arg);
    lookups.selectedItem = {
        ...lookups.selectedItem!,
        errors: undefined,
        savingStatus: LoadingStatus.Pending,
    };
};

const upsertLookupCompleted = (state: LookupsState, action: PayloadAction<any, string, UpsertActionType, never>) => {
    const lookups = getLookupsFromState(state, action.meta.arg);
    const item = action.payload;
    const { id } = item;
    lookups.items[id] = item;
    lookups.selectedItem = undefined;
};

const upsertLookupRejected = (state: LookupsState, action: PayloadAction<any, string, UpsertActionType, SerializedError>) => {
    const lookups = getLookupsFromState(state, action.meta.arg);
    lookups.selectedItem = {
        ...lookups.selectedItem!,
        errors: [action.error],
        savingStatus: LoadingStatus.Failed,
    };
};

const upsertLookupsPending = (state: LookupsState, action: PayloadAction<any, string, UpsertActionsType, never>) => {
    action.meta.arg.forEach((arg) => {
        const lookups = getLookupsFromState(state, arg);
        lookups.selectedItem = {
            ...lookups.selectedItem!,
            errors: undefined,
            savingStatus: LoadingStatus.Pending,
        };
    });
};

const upsertLookupsCompleted = (
    state: LookupsState,
    action: PayloadAction<{ tenantId: string; lookupType: string; data: any }[], string, UpsertActionsType, never>,
) => {
    action.payload.forEach((arg) => {
        const lookups = getLookupsFromState(state, arg);
        const item = arg.data;
        const { id } = item;
        lookups.items[id] = item;
        lookups.selectedItem = undefined;
    });
};

const upsertLookupsRejected = (state: LookupsState, action: PayloadAction<any, string, UpsertActionsType, SerializedError>) => {
    action.meta.arg.forEach((arg) => {
        const lookups = getLookupsFromState(state, arg);
        lookups.selectedItem = {
            ...lookups.selectedItem!,
            errors: [action.error],
            savingStatus: LoadingStatus.Failed,
        };
    });
};

export const extraReducers = (builder: ActionReducerMapBuilder<LookupsState>) =>
    builder
        // Get all lookups by lookup type.
        .addCase(getLookups.pending, getLookupsPending)
        .addCase(getLookups.fulfilled, getLookupsCompleted)
        .addCase(getLookups.rejected, getLookupsRejected)

        // Create a lookup.
        .addCase(createLookup.pending, upsertLookupPending)
        .addCase(createLookup.fulfilled, upsertLookupCompleted)
        .addCase(createLookup.rejected, upsertLookupRejected)

        // Update a lookup.
        .addCase(updateLookup.pending, upsertLookupPending)
        .addCase(updateLookup.fulfilled, upsertLookupCompleted)
        .addCase(updateLookup.rejected, upsertLookupRejected)

        // Update lookups
        .addCase(updateLookups.pending, upsertLookupsPending)
        .addCase(updateLookups.fulfilled, upsertLookupsCompleted)
        .addCase(updateLookups.rejected, upsertLookupsRejected);
