import { IDropdownOption } from '@fluentui/react';
import { createAsyncThunk, createSelector, createSlice, Dictionary } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import { BenefitPlans, IBenefitPlan } from 'api/models/benefit-plan.model';

import { LoadingStatus, LoadingStatuses } from 'interfaces/loading-statuses';
import { map, orderBy, uniqBy } from 'lodash';

import { RootState } from 'state/store';

const initialState: BenefitPlanState = {
    initialLoad: LoadingStatus.Idle,
    loading: LoadingStatus.Idle,
    data: {},
};
export const getBenefitPlans = createAsyncThunk<BenefitPlans>('getBenefitPlan', async () => {
    const res = await dentalApi.getBenefitPlans();
    return res.data;
});

const benefitPlanSlice = createSlice({
    name: 'benefitPlans',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getBenefitPlans.pending, (state) => {
                state.initialLoad = LoadingStatus.Pending;
            })
            .addCase(getBenefitPlans.fulfilled, (state, action) => {
                state.data = action.payload;
                state.initialLoad = LoadingStatus.Completed;
            })
            .addCase(getBenefitPlans.rejected, (state) => {
                state.initialLoad = LoadingStatus.Failed;
            });
    },
});
const { reducer } = benefitPlanSlice;
export const selectBenefitPlan = (state: RootState) => state.tenant.benefitPlans;
export const benefitPlanData = createSelector(selectBenefitPlan, (state) => state?.data ?? {});

export const benefitPlanList = createSelector([benefitPlanData], (benefitPlanData) => {
    const list = map(benefitPlanData, (p) => p).filter((plan) => !plan?.isDeleted) as IBenefitPlan[];
    return list;
});

export const benefitPlanCategories = createSelector(benefitPlanList, (benefitPlans) => {
    if (benefitPlans && benefitPlans.length) {
        const options: IDropdownOption[] = orderBy(
            uniqBy(
                benefitPlans.map((benefitPlan) => ({
                    key: benefitPlan?.id,
                    text: benefitPlan.displayName,
                })),
                'key',
            ),
            'key',
            'asc',
        );
        return options;
    } else {
        return [];
    }
});

type BenefitPlanState = {
    initialLoad: LoadingStatuses;
    loading: LoadingStatuses;
    data?: Dictionary<IBenefitPlan>;
    errors?: string;
};

export default reducer;
