import { createAsyncThunk, Dispatch } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import IChart from 'api/models/chart.model';
import { AxiosResponse, AxiosError } from 'axios';

export const updateChart = createAsyncThunk<
    AxiosResponse<IChart>,
    {
        tenantId: string;
        entity: IChart;
    },
    {
        dispatch: Dispatch;
        rejectValue: AxiosError<any>;
    }
>('updateChart', async ({ tenantId, entity }, { rejectWithValue }) => {
    try {
        const res = await dentalApi.updateChart(tenantId, entity);
        return res;
    } catch (e: any) {
        return rejectWithValue(e);
    }
});

export const fetchChart = createAsyncThunk<
    AxiosResponse<IChart>,
    {
        tenantId: string;
        patientId: string;
    },
    {
        dispatch: Dispatch;
    }
>('fetchChart', async ({ tenantId, patientId }, { dispatch }) => {
    const res = await dentalApi.getChartByPatientId(tenantId, patientId);
    return res;
});
