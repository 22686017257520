/* eslint-disable no-console */
import { InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser';
import Axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { getIdToken } from './auth/authProvider';
import axiosRetry from 'axios-retry';

export type IApiEndpointConfig = {
    baseUrl: string;
    authProvider: PublicClientApplication;
    scopes: string[];
    useAccessToken?: boolean;
};

/**
 * Axios API Client
 * @description API Client to connect to Azure AD / Azure APIs
 * @class ApiClient
 */
class ApiClient {
    public client: AxiosInstance;
    public config: IApiEndpointConfig;
    public authProvider: PublicClientApplication;

    constructor(clientConfig: IApiEndpointConfig) {
        this.client = Axios.create({
            baseURL: clientConfig.baseUrl,
        });
        axiosRetry(this.client, { retries: 3 });
        this.authProvider = clientConfig.authProvider;
        this.config = clientConfig;
        this.client.interceptors.request.use(this.requestSuccess, this.requestFail);
        this.client.interceptors.response.use(this.responseSuccess, this.responseFail);
    }

    /**
     * Request Success
     * @param {AxiosRequestConfig} config
     * @memberof ApiClient
     */
    requestSuccess = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
        let token;
        const { scopes } = this.config;

        try {
            token = await getIdToken(scopes);
        } catch (e) {
            console.log(e instanceof InteractionRequiredAuthError);
            console.group('TOKEN REQUEST FAILURE');
            console.error(e);
            console.error(config);
            console.groupEnd();
            await this.authProvider.acquireTokenRedirect({ scopes });
        }

        config.headers = {
            ...config.headers,
            Authorization: `Bearer ${token}`,
        };
        return config;
    };

    requestFail(error: AxiosError): Promise<AxiosError> {
        console.log(error);
        return Promise.reject(error);
    }

    responseSuccess(res: AxiosResponse): AxiosResponse<AxiosResponse> {
        return res;
    }

    responseFail(res: AxiosError): Promise<AxiosError> {
        // eslint-disable-next-line no-console
        // console.error(res);
        return Promise.reject(res);
    }
}

export default ApiClient;
