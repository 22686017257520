import { MessageBar, DetailsList, IGroup, SelectionMode, Text, Stack } from '@fluentui/react';
import { useSelector } from 'hooks';
import useOverview from 'hooks/store/useOverview';
import { selectPrescriptionsPAM } from 'state/slices/patient/overview/overview.selectors';

export interface IDetailsListGroupedExampleItem {
    key: string;
    name: string;
    color: string;
}

export interface IDetailsListGroupedExampleState {
    items: IDetailsListGroupedExampleItem[];
    groups: IGroup[];
}

export default function Pam(): JSX.Element {
    const { selectProblemAsList, allergiesList, medicationsList } = useOverview();

    const { items, groups } = useSelector(selectPrescriptionsPAM);

    const _missingProblemsText = !selectProblemAsList ? 'No problems' : '';
    const _missingAllergiesText = !allergiesList || !allergiesList.length ? 'No allergies' : '';
    const _missingMedicationsText = !medicationsList ? 'No medications' : '';

    return (
        <div>
            <DetailsList
                items={items}
                groups={groups}
                groupProps={{
                    showEmptyGroups: false,
                }}
                columns={[{ key: 'name', name: '', fieldName: 'name', minWidth: 100 }]}
                compact={true}
                selectionMode={SelectionMode.none}
                isHeaderVisible={false}
            />
            <Stack tokens={{ childrenGap: 2 }} grow>
                {_missingProblemsText && <MessageBar>{_missingProblemsText}</MessageBar>}
                {_missingAllergiesText && <MessageBar>{_missingAllergiesText}</MessageBar>}
                {_missingMedicationsText && <MessageBar>{_missingMedicationsText}</MessageBar>}
            </Stack>
        </div>
    );
}
