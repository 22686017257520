import IWorkListView from 'api/models/worklist-view.model';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import UserDisplayName from 'components/UserDisplayName';

const treatingProvider: ISortableColumn<IWorkListView> = {
    key: 'treatingProvider',
    minWidth: 100,
    maxWidth: 100,
    isResizable: true,
    name: 'Treating Provider',
    onRender: (item) => <UserDisplayName userId={item?.treatingProviderId} />,
};

export default treatingProvider;
