import { getObjectKeys } from 'roosterjs-editor-dom';
import type { Emoji } from '../type/Emoji';

const Common1 = createEmoji('1f60a', ':) :-)');
const common2 = createEmoji('1f609', ';) ;-)');
const common3 = createEmoji('02764', '<3');
const common4 = createEmoji('1f61e', ':( :-(');
const common5 = createEmoji('1f603', ':D :-D');

/**
 * @internal
 */
export const MoreEmoji: Emoji = {
    key: 'more',
    description: 'emjDMore',
    codePoint: '',
};

/**
 * @internal
 */
export const CommonEmojis = [Common1, common2, common3, common4, common5, MoreEmoji];

/**
 * @internal
 */
export const enum EmojiFamilyKeys {
    People = 'People',
    Nature = 'Nature',
    Activities = 'Activities',
    Food = 'Food',
    Travel = 'Travel',
    Symbols = 'Symbols',
    Objects = 'Objects',
}

/**
 * @internal
 * NOTE: name for an emoji should be in lower case
 */
export const EmojiList = {
    [EmojiFamilyKeys.People]: [
        createEmoji('1f601'),
        createEmoji('1f602'),
        common5,
        createEmoji('1f604'),
        createEmoji('1f605'),
        createEmoji('1f606'),
        createEmoji('1f607'),
        common2,
        Common1,
        createEmoji('1f642'),
        createEmoji('1f60b'),
        createEmoji('1f60c'),
        createEmoji('1f60d'),
        createEmoji('1f618'),
        createEmoji('1f61a'),
        createEmoji('1f61c'),
        createEmoji('1f61d', ':p :P :-p :-P'),
        createEmoji('1f60e', 'B) B-)'),
        createEmoji('1f60f'),
        createEmoji('1f636'),
        createEmoji('1f610', ':| :-|'),
        createEmoji('1f612'),
        createEmoji('1f633'),
        common4,
        createEmoji('1f620'),
        createEmoji('1f621'),
        createEmoji('1f614'),
        createEmoji('1f623'),
        createEmoji('1f616'),
        createEmoji('1f62b'),
        createEmoji('1f629'),
        createEmoji('1f624'),
        createEmoji('1f631'),
        createEmoji('1f628'),
        createEmoji('1f630'),
        createEmoji('1f625'),
        createEmoji('1f622'),
        createEmoji('1f62a'),
        createEmoji('1f613'),
        createEmoji('1f62d'),
        createEmoji('1f635'),
        createEmoji('1f632', ':o :O :-o :-O'),
        createEmoji('1f637'),
        createEmoji('1f4a4'),
        createEmojiWithNoKeyword('1f608'),
        createEmoji('1f47f'),
        createEmojiWithNoKeyword('1f479'),
        createEmojiWithNoKeyword('1f47a'),
        createEmoji('1f480'),
        createEmoji('1f47b'),
        createEmoji('1f47d'),
        createEmoji('1f63a'),
        createEmoji('1f638'),
        createEmoji('1f639'),
        createEmoji('1f63b'),
        createEmoji('1f63c'),
        createEmoji('1f63d'),
        createEmojiWithNoKeyword('1f640'),
        createEmoji('1f63f'),
        createEmoji('1f63e'),
        createEmoji('1f64c'),
        createEmoji('1f44f'),
        createEmoji('1f44b'),
        createEmoji('1f44d'),
        createEmoji('1f44e'),
        createEmoji('1f44a'),
        createEmoji('0270a'),
        createEmoji('0270b'),
        createEmoji('0270c'),
        createEmoji('1f44c'),
        createEmojiWithNoKeyword('1f450'),
        createEmojiWithNoKeyword('1f596'),
        createEmoji('1f4aa'),
        createEmoji('1f64f'),
        createEmoji('1f446'),
        createEmoji('1f447'),
        createEmoji('1f448'),
        createEmoji('1f449'),
        createEmoji('1f485'),
        createEmoji('1f444'),
        createEmoji('1f445'),
        createEmoji('1f442'),
        createEmoji('1f443'),
        createEmoji('1f440'),
        createEmojiWithNoKeyword('1f464'),
        createEmoji('1f476'),
        createEmoji('1f466'),
        createEmoji('1f467'),
        createEmoji('1f468'),
        createEmoji('1f469'),
        createEmoji('1f471'),
        createEmoji('1f474'),
        createEmoji('1f475'),
        createEmojiWithNoKeyword('1f472'),
        createEmojiWithNoKeyword('1f473'),
        createEmoji('1f46e'),
        createEmoji('1f477'),
        createEmoji('1f482'),
        createEmoji('1f385'),
        createEmoji('1f47c'),
        createEmoji('1f478'),
        createEmoji('1f470'),
        createEmoji('1f6b6'),
        createEmoji('1f3c3'),
        createEmoji('1f483'),
        createEmoji('1f46f'),
        createEmoji('1f46b'),
        createEmojiWithNoKeyword('1f647'),
        createEmoji('1f481'),
        createEmoji('1f645'),
        createEmoji('1f646'),
        createEmoji('1f64b'),
        createEmoji('1f64e'),
        createEmoji('1f64d'),
        createEmoji('1f487'),
        createEmoji('1f486'),
        createEmoji('1f491'),
        createEmoji('1f48f'),
        createEmoji('1f46a'),
        createEmoji('1f45a'),
        createEmoji('1f455'),
        createEmoji('1f456'),
        createEmoji('1f454'),
        createEmoji('1f457'),
        createEmoji('1f459'),
        createEmoji('1f458'),
        createEmoji('1f484'),
        createEmoji('1f48b'),
        createEmoji('1f463'),
        createEmojiWithNoKeyword('1f460'),
        createEmoji('1f461'),
        createEmoji('1f462'),
        createEmoji('1f45e'),
        createEmoji('1f45f'),
        createEmoji('1f452'),
        createEmoji('1f3a9'),
        createEmoji('1f393'),
        createEmoji('1f451'),
        createEmoji('1f392'),
        createEmoji('1f45d'),
        createEmoji('1f45b'),
        createEmoji('1f45c'),
        createEmoji('1f4bc'),
        createEmoji('1f453'),
        createEmoji('1f48d'),
        createEmoji('1f302'),
    ],
    [EmojiFamilyKeys.Nature]: [
        createEmoji('1f436'),
        createEmoji('1f431'),
        createEmoji('1f42d'),
        createEmoji('1f439'),
        createEmoji('1f430'),
        createEmoji('1f43b'),
        createEmoji('1f43c'),
        createEmojiWithNoKeyword('1f428'),
        createEmoji('1f42f'),
        createEmoji('1f42e'),
        createEmoji('1f437'),
        createEmoji('1f43d'),
        createEmoji('1f438'),
        createEmoji('1f419'),
        createEmoji('1f435'),
        createEmoji('1f648'),
        createEmoji('1f649'),
        createEmoji('1f64a'),
        createEmoji('1f412'),
        createEmoji('1f427'),
        createEmoji('1f426'),
        createEmojiWithNoKeyword('1f424'),
        createEmoji('1f423'),
        createEmoji('1f425'),
        createEmoji('1f43a'),
        createEmoji('1f417'),
        createEmoji('1f434'),
        createEmoji('1f41d'),
        createEmoji('1f41b'),
        createEmoji('1f40c'),
        createEmoji('1f41e'),
        createEmoji('1f41c'),
        createEmoji('1f40d'),
        createEmoji('1f422'),
        createEmoji('1f420'),
        createEmoji('1f41f'),
        createEmoji('1f421'),
        createEmoji('1f42c'),
        createEmoji('1f433'),
        createEmoji('1f414'),
        createEmoji('1f42b'),
        createEmoji('1f418'),
        createEmoji('1f411'),
        createEmoji('1f40e'),
        createEmoji('1f429'),
        createEmoji('1f43e'),
        createEmoji('1f432'),
        createEmoji('1f335'),
        createEmoji('1f384'),
        createEmoji('1f334'),
        createEmoji('1f331'),
        createEmoji('1f33f'),
        createEmoji('1f340'),
        createEmojiWithNoKeyword('1f38d'),
        createEmojiWithNoKeyword('1f38b'),
        createEmoji('1f343'),
        createEmoji('1f342'),
        createEmoji('1f341'),
        createEmoji('1f33e'),
        createEmoji('1f33a'),
        createEmoji('1f33b'),
        createEmoji('1f339'),
        createEmoji('1f33c'),
        createEmoji('1f337'),
        createEmoji('1f338'),
        createEmoji('1f344'),
        createEmoji('1f490'),
        createEmoji('1f330'),
        createEmoji('1f383'),
        createEmoji('1f41a'),
        createEmojiWithNoKeyword('1f30f'),
        createEmoji('1f315'),
        createEmoji('1f311'),
        createEmoji('1f313'),
        createEmoji('1f314'),
        createEmoji('1f31b'),
        createEmoji('1f319'),
        createEmoji('02b50'),
        createEmoji('1f31f'),
        createEmojiWithNoKeyword('1f4ab'),
        createEmoji('02728'),
        createEmojiWithNoKeyword('02600'),
        createEmojiWithNoKeyword('026c5'),
        createEmoji('02601'),
        createEmoji('026a1'),
        createEmoji('1f525'),
        createEmoji('1f4a5'),
        createEmoji('02744'),
        createEmoji('026c4'),
        createEmoji('1f4a8'),
        createEmoji('02614'),
        createEmojiWithNoKeyword('1f4a7'),
        createEmojiWithNoKeyword('1f4a6'),
        createEmoji('1f30a'),
    ],
    [EmojiFamilyKeys.Activities]: [
        createEmoji('026bd'),
        createEmoji('1f3c0'),
        createEmoji('1f3c8'),
        createEmoji('026be'),
        createEmoji('1f3be'),
        createEmoji('1f3b1'),
        createEmoji('026f3'),
        createEmoji('1f3bf'),
        createEmoji('1f3c2'),
        createEmoji('1f3a3'),
        createEmoji('1f3ca'),
        createEmoji('1f3c4'),
        createEmoji('1f3c6'),
        createEmoji('1f3bd'),
        createEmoji('1f3ab'),
        createEmoji('1f3ad'),
        createEmoji('1f3a8'),
        createEmoji('1f3aa'),
        createEmoji('1f3a4'),
        createEmoji('1f3a7'),
        createEmoji('1f3bc'),
        createEmoji('1f3b9'),
        createEmoji('1f3b7'),
        createEmoji('1f3ba'),
        createEmoji('1f3b8'),
        createEmoji('1f3bb'),
        createEmoji('1f3ac'),
        createEmoji('1f3ae'),
        createEmoji('1f47e'),
        createEmoji('1f3af'),
        createEmoji('1f3b2'),
        createEmoji('1f3b0'),
        createEmoji('1f3b3'),
    ],
    [EmojiFamilyKeys.Food]: [
        createEmoji('1f34f'),
        createEmoji('1f34e'),
        createEmoji('1f34a'),
        createEmoji('1f34c'),
        createEmoji('1f349'),
        createEmoji('1f347'),
        createEmoji('1f353'),
        createEmoji('1f348'),
        createEmoji('1f352'),
        createEmoji('1f351'),
        createEmoji('1f34d'),
        createEmoji('1f345'),
        createEmoji('1f346'),
        createEmoji('1f33d'),
        createEmoji('1f360'),
        createEmoji('1f35e'),
        createEmoji('1f357'),
        createEmoji('1f356'),
        createEmoji('1f364'),
        createEmoji('1f373'),
        createEmoji('1f354'),
        createEmoji('1f35f'),
        createEmoji('1f355'),
        createEmoji('1f35d'),
        createEmoji('1f35c'),
        createEmoji('1f372'),
        createEmoji('1f365'),
        createEmoji('1f363'),
        createEmoji('1f371'),
        createEmoji('1f35b'),
        createEmoji('1f359'),
        createEmoji('1f35a'),
        createEmoji('1f358'),
        createEmoji('1f362'),
        createEmoji('1f361'),
        createEmojiWithNoKeyword('1f367'),
        createEmoji('1f368'),
        createEmoji('1f366'),
        createEmoji('1f370'),
        createEmoji('1f36f'),
        createEmoji('1f382'),
        createEmoji('1f36e'),
        createEmoji('1f36c'),
        createEmoji('1f36d'),
        createEmoji('1f36b'),
        createEmoji('1f369'),
        createEmoji('1f36a'),
        createEmoji('1f37a'),
        createEmoji('1f37b'),
        createEmoji('1f377'),
        createEmoji('1f378'),
        createEmoji('1f379'),
        createEmoji('1f376'),
        createEmoji('1f375'),
        createEmoji('02615'),
        createEmoji('1f374'),
    ],
    [EmojiFamilyKeys.Travel]: [
        createEmoji('1f697'),
        createEmoji('1f695'),
        createEmoji('1f687'),
        createEmojiWithNoKeyword('1f699'),
        createEmoji('1f68c'),
        createEmoji('1f693'),
        createEmoji('1f69a'),
        createEmojiWithNoKeyword('1f691'),
        createEmoji('1f692'),
        createEmoji('1f6b2'),
        createEmoji('1f6a8'),
        createEmoji('1f683'),
        createEmoji('1f684'),
        createEmojiWithNoKeyword('1f685'),
        createEmoji('1f689'),
        createEmoji('02708'),
        createEmoji('026f5'),
        createEmojiWithNoKeyword('1f6a4'),
        createEmoji('1f680'),
        createEmoji('1f4ba'),
        createEmoji('02693'),
        createEmoji('1f6a7'),
        createEmoji('026fd'),
        createEmoji('1f68f'),
        createEmoji('1f6a5'),
        createEmoji('1f3c1'),
        createEmoji('1f6a2'),
        createEmoji('1f3a1'),
        createEmoji('1f3a2'),
        createEmoji('1f3a0'),
        createEmoji('1f301'),
        createEmojiWithNoKeyword('1f5fc'),
        createEmoji('1f3ed'),
        createEmoji('026f2'),
        createEmojiWithNoKeyword('1f391'),
        createEmojiWithNoKeyword('1f5fb'),
        createEmojiWithNoKeyword('1f30b'),
        createEmojiWithNoKeyword('1f5fe'),
        createEmoji('1f305'),
        createEmoji('1f304'),
        createEmoji('1f307'),
        createEmoji('1f306'),
        createEmoji('1f303'),
        createEmoji('1f309'),
        createEmoji('1f30c'),
        createEmoji('1f387'),
        createEmoji('1f386'),
        createEmoji('1f308'),
        createEmoji('1f3f0'),
        createEmojiWithNoKeyword('1f3ef'),
        createEmoji('1f5fd'),
        createEmoji('1f3e0'),
        createEmoji('1f3e1'),
        createEmoji('1f3e2'),
        createEmoji('1f3ec'),
        createEmoji('026fa'),
        createEmojiWithNoKeyword('1f3e3'),
        createEmojiWithNoKeyword('1f3e5'),
        createEmoji('1f3e6'),
        createEmoji('1f3e8'),
        createEmoji('1f3ea'),
        createEmoji('1f3eb'),
        createEmojiWithNoKeyword('1f3e9'),
        createEmoji('1f492'),
        createEmoji('026ea'),
        createEmoji('1f320'),
    ],
    [EmojiFamilyKeys.Symbols]: [
        common3,
        createEmoji('1f49b'),
        createEmoji('1f49a'),
        createEmoji('1f499'),
        createEmoji('1f494'),
        createEmoji('1f49c'),
        createEmoji('1f495'),
        createEmoji('1f493'),
        createEmoji('1f49e'),
        createEmoji('1f497'),
        createEmoji('1f498'),
        createEmoji('1f496'),
        createEmoji('1f49d'),
        createEmoji('1f49f'),
        createEmojiWithNoKeyword('1f52f'),
        createEmojiWithNoKeyword('026ce'),
        createEmoji('02648'),
        createEmoji('02649'),
        createEmoji('0264a'),
        createEmoji('0264b'),
        createEmoji('0264c'),
        createEmoji('0264d'),
        createEmoji('0264e'),
        createEmoji('0264f'),
        createEmoji('02650'),
        createEmoji('02651'),
        createEmoji('02652'),
        createEmoji('02653'),
        createEmojiWithNoKeyword('1f194'),
        createEmojiWithNoKeyword('1f4f4'),
        createEmojiWithNoKeyword('1f4f3'),
        createEmojiWithNoKeyword('1f19a'),
        createEmojiWithNoKeyword('1f4ae'),
        createEmojiWithNoKeyword('1f18e'),
        createEmojiWithNoKeyword('1f191'),
        createEmojiWithNoKeyword('1f198'),
        createEmojiWithNoKeyword('026d4'),
        createEmoji('1f4db'),
        createEmoji('1f6ab'),
        createEmojiWithNoKeyword('0274c'),
        createEmojiWithNoKeyword('02b55'),
        createEmojiWithNoKeyword('1f4a2'),
        createEmoji('02668'),
        createEmojiWithNoKeyword('1f51e'),
        createEmojiWithNoKeyword('02757'),
        createEmojiWithNoKeyword('02755'),
        createEmojiWithNoKeyword('02753'),
        createEmojiWithNoKeyword('02754'),
        createEmojiWithNoKeyword('02049'),
        createEmojiWithNoKeyword('1f4af'),
        createEmoji('1f531'),
        createEmojiWithNoKeyword('0303d'),
        createEmoji('026a0'),
        createEmojiWithNoKeyword('1f530'),
        createEmojiWithNoKeyword('1f22f'),
        createEmojiWithNoKeyword('1f4b9'),
        createEmoji('02733'),
        createEmojiWithNoKeyword('0274e'),
        createEmojiWithNoKeyword('02705'),
        createEmojiWithNoKeyword('1f4a0'),
        createEmoji('1f300'),
        createEmoji('1f3e7'),
        createEmoji('0267f'),
        createEmoji('1f6ad'),
        createEmojiWithNoKeyword('1f6be'),
        createEmoji('02734'),
        createEmoji('1f17f'),
        createEmoji('1f6b9'),
        createEmoji('1f6ba'),
        createEmojiWithNoKeyword('1f6bc'),
        createEmoji('1f6bb'),
        createEmoji('1f3a6'),
        createEmoji('1f4f6'),
        createEmojiWithNoKeyword('1f201'),
        createEmojiWithNoKeyword('1f196'),
        createEmojiWithNoKeyword('0267b'),
        createEmojiWithNoKeyword('1f197'),
        createEmojiWithNoKeyword('1f192'),
        createEmoji('02747'),
        createEmojiWithNoKeyword('1f195'),
        createEmojiWithNoKeyword('1f193'),
        createEmojiWithNoKeyword('1f51f'),
        createEmojiWithNoKeyword('1f522'),
        createEmojiWithNoKeyword('023ea'),
        createEmojiWithNoKeyword('023e9'),
        createEmojiWithNoKeyword('1f53c'),
        createEmojiWithNoKeyword('1f53d'),
        createEmojiWithNoKeyword('023eb'),
        createEmojiWithNoKeyword('023ec'),
        createEmojiWithNoKeyword('1f199'),
        createEmojiWithNoKeyword('02139'),
        createEmojiWithNoKeyword('1f524'),
        createEmojiWithNoKeyword('1f521'),
        createEmojiWithNoKeyword('1f520'),
        createEmojiWithNoKeyword('1f523'),
        createEmoji('1f3b5'),
        createEmoji('1f3b6'),
        createEmojiWithNoKeyword('03030'),
        createEmojiWithNoKeyword('027bf'),
        createEmojiWithNoKeyword('02714'),
        createEmoji('1f503'),
        createEmojiWithNoKeyword('02795'),
        createEmojiWithNoKeyword('02796'),
        createEmojiWithNoKeyword('02797'),
        createEmojiWithNoKeyword('02716'),
        createEmojiWithNoKeyword('027b0'),
        createEmojiWithNoKeyword('1f4b2'),
        createEmojiWithNoKeyword('1f4b1'),
        createEmojiWithNoKeyword('1f51a'),
        createEmojiWithNoKeyword('1f519'),
        createEmojiWithNoKeyword('1f51b'),
        createEmojiWithNoKeyword('1f51d'),
        createEmojiWithNoKeyword('1f51c'),
        createEmojiWithNoKeyword('02611'),
        createEmojiWithNoKeyword('1f518'),
        createEmojiWithNoKeyword('026ab'),
        createEmojiWithNoKeyword('1f534'),
        createEmojiWithNoKeyword('1f535'),
        createEmojiWithNoKeyword('1f539'),
        createEmojiWithNoKeyword('1f538'),
        createEmojiWithNoKeyword('1f536'),
        createEmojiWithNoKeyword('1f537'),
        createEmojiWithNoKeyword('1f53a'),
        createEmojiWithNoKeyword('1f53b'),
        createEmojiWithNoKeyword('02b1b'),
        createEmojiWithNoKeyword('02b1c'),
        createEmojiWithNoKeyword('1f532'),
        createEmojiWithNoKeyword('1f533'),
        createEmojiWithNoKeyword('1f50a'),
        createEmoji('1f4e3'),
        createEmojiWithNoKeyword('1f4e2'),
        createEmoji('1f514'),
        createEmojiWithNoKeyword('1f004'),
        createEmoji('1f0cf'),
        createEmojiWithNoKeyword('1f3b4'),
        createEmojiWithNoKeyword('1f4ac'),
        createEmoji('1f550'),
        createEmoji('1f551'),
        createEmoji('1f552'),
        createEmoji('1f553'),
        createEmoji('1f554'),
        createEmoji('1f555'),
        createEmoji('1f556'),
        createEmoji('1f557'),
        createEmoji('1f558'),
        createEmoji('1f559'),
        createEmoji('1f55a'),
        createEmoji('1f55b'),
        createEmojiWithNoKeyword('1f236'),
        createEmojiWithNoKeyword('1f250'),
        createEmojiWithNoKeyword('1f239'),
        createEmojiWithNoKeyword('1f21a'),
        createEmojiWithNoKeyword('1f232'),
        createEmojiWithNoKeyword('1f251'),
        createEmojiWithNoKeyword('1f234'),
        createEmojiWithNoKeyword('1f233'),
        createEmojiWithNoKeyword('1f23a'),
        createEmojiWithNoKeyword('1f235'),
    ],
    [EmojiFamilyKeys.Objects]: [
        createEmojiWithNoKeyword('0231a'),
        createEmoji('1f4f1'),
        createEmojiWithNoKeyword('1f4f2'),
        createEmoji('1f4bb'),
        createEmojiWithNoKeyword('1f4bd'),
        createEmoji('1f4be'),
        createEmoji('1f4bf'),
        createEmoji('1f4c0'),
        createEmoji('1f4fc'),
        createEmoji('1f4f7'),
        createEmoji('1f4f9'),
        createEmoji('1f3a5'),
        createEmoji('1f4de'),
        createEmojiWithNoKeyword('0260e'),
        createEmoji('1f4df'),
        createEmoji('1f4e0'),
        createEmoji('1f4fa'),
        createEmoji('1f4fb'),
        createEmojiWithNoKeyword('023f0'),
        createEmojiWithNoKeyword('0231b'),
        createEmojiWithNoKeyword('023f3'),
        createEmoji('1f4e1'),
        createEmoji('1f50b'),
        createEmoji('1f50c'),
        createEmoji('1f4a1'),
        createEmoji('1f526'),
        createEmojiWithNoKeyword('1f4b8'),
        createEmoji('1f4b5'),
        createEmoji('1f4b4'),
        createEmoji('1f4b0'),
        createEmoji('1f4b3'),
        createEmoji('1f48e'),
        createEmoji('1f527'),
        createEmoji('1f528'),
        createEmoji('1f529'),
        createEmojiWithNoKeyword('1f52b'),
        createEmoji('1f4a3'),
        createEmoji('1f52a'),
        createEmoji('1f6ac'),
        createEmoji('1f52e'),
        createEmoji('1f488'),
        createEmoji('1f48a'),
        createEmoji('1f489'),
        createEmoji('1f516'),
        createEmoji('1f6bd'),
        createEmoji('1f6c0'),
        createEmoji('1f511'),
        createEmoji('1f6aa'),
        createEmojiWithNoKeyword('1f5ff'),
        createEmoji('1f388'),
        createEmojiWithNoKeyword('1f38f'),
        createEmoji('1f380'),
        createEmoji('1f381'),
        createEmoji('1f38a'),
        createEmoji('1f389'),
        createEmojiWithNoKeyword('1f38e'),
        createEmoji('1f390'),
        createEmojiWithNoKeyword('1f38c'),
        createEmojiWithNoKeyword('1f3ee'),
        createEmoji('02709'),
        createEmoji('1f4e9'),
        createEmoji('1f4e8'),
        createEmoji('1f48c'),
        createEmoji('1f4e7'),
        createEmoji('1f4ee'),
        createEmoji('1f4ea'),
        createEmoji('1f4eb'),
        createEmoji('1f4e6'),
        createEmojiWithNoKeyword('1f4e5'),
        createEmojiWithNoKeyword('1f4e4'),
        createEmojiWithNoKeyword('1f4dc'),
        createEmojiWithNoKeyword('1f4c3'),
        createEmojiWithNoKeyword('1f4d1'),
        createEmoji('1f4ca'),
        createEmoji('1f4c8'),
        createEmoji('1f4c9'),
        createEmojiWithNoKeyword('1f4c4'),
        createEmoji('1f4c5'),
        createEmojiWithNoKeyword('1f4c6'),
        createEmojiWithNoKeyword('1f4c7'),
        createEmoji('1f4cb'),
        createEmoji('1f4c1'),
        createEmojiWithNoKeyword('1f4c2'),
        createEmoji('1f4f0'),
        createEmoji('1f4d3'),
        createEmojiWithNoKeyword('1f4d5'),
        createEmojiWithNoKeyword('1f4d7'),
        createEmojiWithNoKeyword('1f4d8'),
        createEmojiWithNoKeyword('1f4d9'),
        createEmojiWithNoKeyword('1f4d4'),
        createEmoji('1f4d2'),
        createEmoji('1f4da'),
        createEmojiWithNoKeyword('1f4d6'),
        createEmojiWithNoKeyword('1f517'),
        createEmoji('1f4ce'),
        createEmoji('02702'),
        createEmojiWithNoKeyword('1f4d0'),
        createEmoji('1f4cf'),
        createEmoji('1f4cc'),
        createEmoji('1f4cd'),
        createEmojiWithNoKeyword('1f6a9'),
        createEmoji('1f510'),
        createEmoji('1f512'),
        createEmoji('1f513'),
        createEmoji('1f50f'),
        createEmojiWithNoKeyword('02712'),
        createEmoji('1f4dd'),
        createEmoji('0270f'),
        createEmoji('1f50d'),
        createEmojiWithNoKeyword('1f50e'),
    ],
};

/**
 * @internal
 */
export const EmojiFabricIconCharacterMap = {
    [EmojiFamilyKeys.Activities]: 'Soccer',
    [EmojiFamilyKeys.Food]: 'EatDrink',
    [EmojiFamilyKeys.Nature]: 'FangBody',
    [EmojiFamilyKeys.Objects]: 'Lightbulb',
    [EmojiFamilyKeys.People]: 'Emoji2',
    [EmojiFamilyKeys.Symbols]: 'Heart',
    [EmojiFamilyKeys.Travel]: 'Car',
};

/**
 * @internal
 */
export function forEachEmojiFamily(
    callback: (emojis: Emoji[], family: EmojiFamilyKeys) => boolean
): void {
    const families = getObjectKeys(EmojiList);
    for (const family of families) {
        if (!callback(EmojiList[family], family)) {
            break;
        }
    }
}

/**
 * @internal
 */
export function forEachEmoji(callback: (emoji: Emoji) => boolean): void {
    forEachEmojiFamily(emojis => {
        for (const emoji of emojis) {
            if (!callback(emoji)) {
                return false;
            }
        }
        return true;
    });
}

// get emoji code point from an emoji key
function getEmojiCodePoint(key: string): string | null {
    const unicode = parseInt(key, 16);
    if (isNaN(unicode)) {
        return null;
    }

    // All emojis have codepoints between the following ranges:
    // 0x1F7000 - 0x1F700 -> has surrogate pairs
    // 0x00023 - 0x04000 -> does not have surrogate pairs
    let surrogatePairs: number[];
    if (unicode >= 0x1f000 && unicode <= 0x1f700) {
        const hi = Math.floor((unicode - 0x10000) / 0x400) + 0xd800;
        const lo = ((unicode - 0x10000) % 0x400) + 0xdc00;
        surrogatePairs = [hi, lo];
    } else if (unicode >= 0x00023 && unicode <= 0x04000) {
        surrogatePairs = [unicode];
    }

    return String.fromCharCode(...surrogatePairs!);
}

function createEmoji(key: string, shortcut?: string): Emoji {
    return {
        key,
        description: `emjD${key}`,
        keywords: `emjK${key}`,
        shortcut,
        codePoint: getEmojiCodePoint(key) ?? undefined,
    };
}

function createEmojiWithNoKeyword(key: string): Emoji {
    return { key, description: `emjD${key}`, codePoint: getEmojiCodePoint(key) ?? undefined };
}
