import { CSSProperties, PropsWithChildren } from 'react';
import { Stack, Link, Text, ITextStyles, useTheme } from '@fluentui/react';
import { IThemeCustom } from 'state/slices/ui/ui.slice';

export type Props = { styles?: CSSProperties };

function Banner(props: PropsWithChildren<Props>): JSX.Element {
    const theme = useTheme();

    return (
        <Stack
            horizontal
            verticalAlign="center"
            style={{
                backgroundColor: (theme as IThemeCustom).palette.navbarLighter,
                ...props.styles,
            }}
            className="banner"
            // tokens={{ childrenGap: '5px 20px' }}
        >
            {props.children}
        </Stack>
    );
}

export default Banner;

export function BannerItem(props: {
    label: string;
    value?: string | number;
    onItemClick?: () => void;
    href?: string;
    textStyles?: ITextStyles;
    labelStyles?: ITextStyles;
    inline?: boolean;
    lightText?: boolean;
}): JSX.Element {
    const onClick = props?.onItemClick;
    const theme = useTheme();

    const textStyles: ITextStyles = {
        root: {
            color: props.lightText ? 'white' : theme.semanticColors.bodyText,
        },
        ...props.textStyles,
    };
    const labelStyles: ITextStyles = {
        root: {
            color: props.lightText ? '#DDD' : theme.semanticColors.bodySubtext,
        },
        ...props.labelStyles,
    };

    return (
        <Stack tokens={{ childrenGap: props.inline ? 5 : -3 }} horizontal={props.inline}>
            <Text styles={labelStyles} variant="small" block={!props.inline}>
                {props.label}
                {props.inline ? ': ' : ''}
            </Text>
            {onClick ? (
                <Link onClick={() => onClick()} href={props.href}>
                    <Text styles={textStyles}>
                        <strong>{props.value ? props.value : 'N/A'}</strong>
                    </Text>
                </Link>
            ) : (
                <Text styles={textStyles}>{props.value ? props.value : 'N/A'}</Text>
            )}
        </Stack>
    );
}
