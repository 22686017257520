import { DefaultEffects, Icon, ITextField, Stack, TextField, useTheme } from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import { Furcation } from 'api/models/perio-exam.model';
import { usePerioExams } from 'hooks';
import { TOOTH_CONTAINER_WIDTH } from 'pages/Perio/perio-settings';
import { FormEvent, useEffect, useRef } from 'react';
import { IExtendedPerioTooth } from 'state/slices/charting/perio-exams/perio-exams.selectors';

const icon: { [key: string]: string } = {
    [Furcation.None]: '',
    [Furcation.Class1]: 'CircleRing',
    [Furcation.Class2]: 'CircleHalfFull',
    [Furcation.Class3]: 'CircleFill',
};
const numberToFurcation: any = {
    '1': Furcation.Class1,
    '2': Furcation.Class2,
    '3': Furcation.Class3,
};
const furcationToNumber: any = {
    [Furcation.None]: '',
    [Furcation.Class1]: '1',
    [Furcation.Class2]: '2',
    [Furcation.Class3]: '3',
};

type Props = {
    tooth: IExtendedPerioTooth;
    isUpper?: boolean;
    isDisabled?: boolean;
};
function FuractionField({ tooth, isUpper, isDisabled }: Props): JSX.Element {
    const { setToothFieldAndUpdate } = usePerioExams();
    const { palette } = useTheme();
    const ref = useRef<ITextField>(null);

    const [focused, { setTrue: setFocusedTrue, setFalse: setFocusedFalse }] = useBoolean(false);
    const [hovered, { setTrue: setHoveredTrue, setFalse: setHoveredFalse }] = useBoolean(false);

    useEffect(() => {
        if (focused) {
            ref.current?.select();
        }
    }, [focused]);

    const furcationValue = isUpper ? tooth.facial?.furcation : tooth.lingual?.furcation;
    const displayValue = furcationToNumber[furcationValue as any];

    const _onChange = (e?: FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
        const newValue = value && numberToFurcation[value] ? numberToFurcation[value] : Furcation.None;

        setToothFieldAndUpdate({
            toothId: tooth.id,
            value: newValue,
            arch: isUpper ? 'facial' : 'lingual',
            perioField: 'furcation',
        });
        setTimeout(setFocusedFalse, 250);
    };

    const _handleFocus = () => {
        setFocusedTrue();
    };

    const _styles: any = {
        root: { display: focused ? 'inherit' : 'none', width: TOOTH_CONTAINER_WIDTH / 2 },
        field: { paddingLeft: 0, paddingRight: 0, fontSize: 12, textAlign: 'center', lineHeight: 12 },
        fieldGroup: { height: 20 },
    };

    const _containerStyle = {
        width: TOOTH_CONTAINER_WIDTH / 2,
        height: 20,
        background: displayValue ? 'none' : palette.neutralLighter,
        borderRadius: 3,
        transition: 'all .15s',
        boxShadow: hovered ? DefaultEffects.elevation8 : '',
    };

    return isDisabled ? (
        <Stack horizontalAlign="center">
            <Stack horizontalAlign="center" style={_containerStyle}>
                {!focused && (
                    <Icon
                        iconName={icon[furcationValue as any]}
                        style={{
                            fontSize: 20,
                        }}
                    />
                )}
                <TextField
                    componentRef={ref}
                    onBlur={setFocusedFalse}
                    maxLength={1}
                    value={displayValue}
                    onChange={_onChange}
                    styles={_styles}
                    disabled={isDisabled}
                />
            </Stack>
        </Stack>
    ) : (
        <Stack
            horizontalAlign="center"
            style={{ cursor: 'pointer' }}
            onClick={_handleFocus}
            onMouseOver={setHoveredTrue}
            onMouseOut={setHoveredFalse}
        >
            <Stack horizontalAlign="center" style={_containerStyle}>
                {!focused && (
                    <Icon
                        iconName={icon[furcationValue as any]}
                        style={{
                            fontSize: 20,
                        }}
                    />
                )}
                <TextField
                    componentRef={ref}
                    onBlur={setFocusedFalse}
                    maxLength={1}
                    value={displayValue}
                    onChange={_onChange}
                    styles={_styles}
                    disabled={isDisabled}
                />
            </Stack>
        </Stack>
    );
}

export default FuractionField;
