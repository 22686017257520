import {
    DefaultButton,
    IComboBox,
    IComboBoxOption,
    IDropdownOption,
    Panel,
    PanelType,
    PrimaryButton,
    Stack,
    TooltipHost,
} from '@fluentui/react';
import { ReportType } from 'api/models/embed-report.model';
import { useTenantId } from 'hooks';
import { ProviderDropdown } from 'pages/components';
import { LocationsOfCareDropdown } from 'pages/components/LocationsOfCareDropdown';
import PanelSectionHeader from 'pages/components/PanelSectionHeader';
import { FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    cleanupPrintPredetermination,
    setPrintPredeterminationDropdownValues,
    setPrintPredeterminationOpen,
    setPrintPredeterminationSelectedTreatmentPlan,
} from 'state/slices/charting/chart/chart.slice';
import {
    selectPredeterminationPrintDisabled,
    selectPrintPredeterminationIsOpen,
    selectPrintPredeterminationLocId,
    selectPrintPredeterminationProviderId,
    selectPrintPredeterminationQueryParams,
    selectPrintPredeterminationSelectedChartActions,
} from 'state/slices/charting/treatmentPlans/predetermination/predetermination.selectors';
import reportActionLookup from 'state/slices/reporting/reportActionLookup';
import PrintPredeterminationTable from './PredeterminationTable';

function PrintPredeterminationPanel() {
    const dispatch = useDispatch();
    const isOpen = useSelector(selectPrintPredeterminationIsOpen);

    const _onDismiss = () => dispatch(setPrintPredeterminationOpen(false));
    const _onDismissed = () => {
        dispatch(cleanupPrintPredetermination());
    };
    return (
        <Panel
            headerText="Print Predetermination Form"
            isOpen={isOpen}
            onDismiss={_onDismiss}
            onDismissed={_onDismissed}
            type={PanelType.custom}
            customWidth={'850px'}
            onRenderFooterContent={() => <PrintPredeterminationButtons />}
            isFooterAtBottom
        >
            <Stack tokens={{ childrenGap: 10 }}>
                <Stack>
                    <PanelSectionHeader text="Select procedures to be printed (no more than 10 at a time)" />
                    <PrintPredeterminationTable />
                </Stack>
                <Stack>
                    <PanelSectionHeader text="Select Location of Care and Provider" />
                    <PrintPredeterminationDropdowns />
                </Stack>
            </Stack>
        </Panel>
    );
}

function PrintPredeterminationButtons() {
    const dispatch = useDispatch();
    const tenantId = useTenantId();
    const predeterminationQueryParams = useSelector(selectPrintPredeterminationQueryParams);

    const _dismissPanel = () => {
        dispatch(setPrintPredeterminationOpen(false));
        dispatch(setPrintPredeterminationSelectedTreatmentPlan(undefined));
    };
    const _printDisabled = useSelector(selectPredeterminationPrintDisabled);
    const _onClickPrint = () => {
        if (!predeterminationQueryParams) return;
        dispatch(reportActionLookup[ReportType.PrintPredetermination]({ tenantId, ...predeterminationQueryParams }));
    };

    return (
        <Stack horizontal tokens={{ childrenGap: 10 }}>
            <TooltipHost content={<TooltipContent />}>
                <PrimaryButton text="Print" iconProps={{ iconName: 'Print' }} disabled={_printDisabled} onClick={_onClickPrint} />
            </TooltipHost>

            <DefaultButton text="Cancel" onClick={_dismissPanel} />
        </Stack>
    );
}

function TooltipContent() {
    const locId = useSelector(selectPrintPredeterminationLocId);
    const providerId = useSelector(selectPrintPredeterminationProviderId);
    const selectedChartActions = useSelector(selectPrintPredeterminationSelectedChartActions);

    const MissingLocId = !locId ? 'Please select a Location of Care.' : '';
    const missingProiderId = !providerId ? 'Please select a Provider.' : '';
    const selectedProcedures =
        selectedChartActions && selectedChartActions.length === 0
            ? 'Please select at least one procedure.'
            : selectedChartActions && selectedChartActions.length > 10
            ? 'Please select fewer procedures.'
            : '';
    return (
        <Stack>
            <Stack>{MissingLocId}</Stack>
            <Stack>{missingProiderId}</Stack>
            <Stack>{selectedProcedures}</Stack>
            {!MissingLocId && !missingProiderId && !selectedProcedures && 'Print Predetermination Form'}
        </Stack>
    );
}

function PrintPredeterminationDropdowns() {
    const dispatch = useDispatch();
    const locId = useSelector(selectPrintPredeterminationLocId);
    const providerId = useSelector(selectPrintPredeterminationProviderId);

    const _onChangeProviderId = (e?: FormEvent<IComboBox>, option?: IComboBoxOption) => {
        if (option && option.key) {
            dispatch(setPrintPredeterminationDropdownValues({ path: 'provider', value: option.key as string }));
        }
    };
    const _onChangeLocId = (e?: FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        if (option && option.key) {
            dispatch(setPrintPredeterminationDropdownValues({ path: 'loc', value: option.key as string }));
        }
    };
    return (
        <Stack horizontal tokens={{ childrenGap: 10 }}>
            <LocationsOfCareDropdown selectedKey={locId} onChange={_onChangeLocId} label="Location of Care" required />
            <ProviderDropdown
                selectedKey={providerId}
                onChange={_onChangeProviderId}
                label="Provider"
                hideRDAs
                placeholder="Select provider..."
                required
            />
        </Stack>
    );
}

export default PrintPredeterminationPanel;
