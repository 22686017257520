export const authConfig: IAuthConfig = {
    clientId: process.env.NODE_ENV === 'development' ? `${process.env.REACT_APP_AppClientId}` : '#{AppClientId}#',
    graphEndpoint: 'https://graph.microsoft.com',
    authority:
        process.env.NODE_ENV === 'development'

            ? `https://${process.env.REACT_APP_TenantName}.b2clogin.com/${process.env.REACT_APP_TenantName}.onmicrosoft.com/${process.env.REACT_APP_PolicyName}`
            : 'https://#{TenantName}#.b2clogin.com/#{TenantName}#.onmicrosoft.com/#{PolicyName}#',
    redirectUri: process.env.NODE_ENV === 'development' ? `${process.env.REACT_APP_AppRedirectUri}` : '#{AppRedirectUri}#',
};

interface IAuthConfig {
    clientId: string;
    graphEndpoint: string;
    authority: string;
    redirectUri: string;
}

export default authConfig;
