import * as PIXI from 'pixi.js-legacy';
import { ColorOverlayFilter } from '@pixi/filter-color-overlay';
import { settings } from './settings';
import store from 'state/store';
import { ProcedureActionType, selectTooth } from 'state/slices/charting/chart/chart.slice';
import { SpriteReference, toothSpriteReferences } from './spriteList';
import Front from './Front';
import { OutlineFilter } from '@pixi/filter-outline';
import { ChartApp } from './ChartApp';
import { IChartCondition, IChartProcedure } from 'api/models/chart.model';
import { Surfaces } from './Surfaces';
import { Back } from './Back';
import { TextCircle } from './TextCircle';
import { intersection, isArray } from 'lodash';
import { IChartRenderCondition, IChartRenderProcedure, ITooth } from 'api/models/tooth.model';
import { ToothArea } from 'api/models/tooth-area';
import Impacted from './Impacted';
import AbscessPeriodontal from './AbscessPeriodontal';
import { Watch } from './Watch';
import OpenContact from './OpenContact';
import Drifted from './Drifted';
import PostCore from './PostCore';
import Extraction from './Extraction';
import { ProcedureRenderRule } from 'api/models/procedure.model';
import RootCanal from './RootCanal';
import { DisplayObject, Filter } from 'pixi.js-legacy';
import ClassV from './ClassV';
import AbcessPeriapical from './AbcessPeriapical';
import AbutmentRetainerCrown from './AbutmentRetainerCrown';
import Pontic from './Pontic';
import Denture from './Denture';
import { IChartSurfaceData } from './SurfaceContainer';
import Broken from './Broken';
import Fractured from './Fractured';
import GingivalRecession from './GingivalRecession';
import CrownRenderRule from './CrownRenderRule';
import { PartiallyErupted } from './PartiallyErupted';

export const molars = [1, 2, 3, 14, 15, 16, 17, 18, 19, 30, 31, 32];
export const childMolars = [4, 5, 12, 13, 29, 28, 20, 21];

export interface IToothReference {
    id: number;
    position: number;
    occlusal: string;
    crown: string;
    root: string;
    front: string;
    back: string;
    broken: string;
    fractured: string;
    gingivalRecession: string;
    abcessPeriapicalCrown: string;
    abcessPeriapicalRoot: string;
    scaleFactor?: number;
    flipClassV?: boolean;
    classVYOffset?: number;
    occlusalSurfaces: IChartSurfaceData[];
}

export type ChartRenderAction = IChartRenderProcedure | IChartRenderCondition;

export const getOutlineFilter = (color: number, thickness: number, quality = 0.5) =>
    new OutlineFilter(thickness, color, quality) as unknown as PIXI.Filter;

/**
 * Instance of a tooth in the chart
 *
 * @class Tooth
 * @extends {PIXI.Container}
 */
class Tooth extends PIXI.Container {
    public toothIndex: number;

    private _toothWidthRatio = 1 / 16;
    public _toothScale: number;

    public _frontContainer: Front | undefined;
    public _surfacesContainer: Surfaces | undefined;
    public _backContainer: Back | undefined;
    public _positionTextContainer: PIXI.Container = new PIXI.Container();

    public _impactedContainer: PIXI.Container = new PIXI.Container();
    public _textOverlayContainer: PIXI.Container = new PIXI.Container();
    public _driftedContainer: PIXI.Container = new PIXI.Container();
    public _postCore: PIXI.Container = new PIXI.Container();
    public _extraction: PIXI.Container = new PIXI.Container();
    public _rootCanal: PIXI.Container = new PIXI.Container();
    public _classV: PIXI.Container = new PIXI.Container();

    private _abcessPeriapicalContainer: PIXI.Container = new PIXI.Container();
    private _broken: PIXI.Container = new PIXI.Container();
    private _fractured: PIXI.Container = new PIXI.Container();
    private _gingivalRecession: PIXI.Container = new PIXI.Container();
    private _AbutmentRetainerCrown: PIXI.Container = new PIXI.Container();
    private _pontic: PIXI.Container = new PIXI.Container();
    private _denture: PIXI.Container = new PIXI.Container();

    public _abscessPeriodontalContainer: PIXI.Container[] = [];
    public _openContactContainer: PIXI.Container[] = [];

    public missingRetainedRoot = false;
    public hasDenture = false;
    public hasVeneer = false;

    public _onToothHover?: (isHovering: boolean, data: ITooth, ref: SpriteReference, position: { x: number; y: number }) => void;

    public nextTooth?: Tooth;
    public previousTooth?: Tooth;

    /**
     * Chart tooth data from the server
     *
     * @type {ITooth}
     * @memberof Tooth
     */
    public data: ITooth;

    /**
     * Static tooth information reference that does not change based on data.
     *
     * @type {SpriteReference}
     * @memberof Tooth
     */
    public toothReference: SpriteReference;

    /**
     *
     * True if the tooth is selected.
     *
     * @private
     * @type {boolean}
     * @memberof Tooth
     */
    private _selected: boolean;

    constructor(
        tooth: ITooth,
        index: number,
        onToothHover?: (isHovering: boolean, data: ITooth, ref: SpriteReference, position: { x: number; y: number }) => void,
    ) {
        super();

        this.toothIndex = index;
        this.data = tooth;
        this.toothReference = toothSpriteReferences.find((t) => t.id === tooth.id) as any;
        this._toothScale = this.toothReference.scaleFactor ? this.toothReference.scaleFactor : 1;

        this._selected = tooth.selected || false;
        this.name = `tooth-${this.data.id}`;

        this.zIndex = 1;
        this.buttonMode = true;
        this.interactive = true;
        this.sortableChildren = true;
        this.accessible = true;

        this._onToothHover = onToothHover;
    }

    public renderTooth(): void {
        this.handleToothPosition();

        this._handleDenture();
        if (this.isToothVisible) {
            this._handleVeneer();
            this._handleMissingRetainedRootCondition();
        }

        this._frontContainer = this._createFront();
        this._backContainer = this._createBack();
        this._surfacesContainer = this._createSurfaces();
        this._positionTextContainer = this._createTextCircle();

        this.removeListener('mouseover', this._mouseOver);
        this.removeListener('mouseout', this._mouseOut);
        this.removeListener('mousedown', this._mouseDown);
        this.removeListener('tap', this._mouseDown);

        this.removeListener('pointerover', this._pointerOver);
        this.removeListener('pointerout', this._pointerOut);

        this.on('mouseover', this._mouseOver)
            .on('mouseout', this._mouseOut)
            .on('mousedown', this._mouseDown)
            .on('tap', this._mouseDown)
            .on('pointerover', this._pointerOver)
            .on('pointerout', this._pointerOut);

        this.addChild(this._surfacesContainer, this._frontContainer, this._positionTextContainer, this._backContainer);

        this._handleProcedures();
        this._handleConditions();
        this._handleDrifted();
        this._handleSelected();
    }

    public setNextTooth(tooth: Tooth | undefined): void {
        this.nextTooth = tooth;
    }

    public setPreviousTooth(tooth: Tooth | undefined): void {
        this.previousTooth = tooth;
    }

    public isSealantPerTooth(): IChartRenderProcedure | undefined {
        return this.data.procedures.find((p) => p.renderRule === ProcedureRenderRule.SealantPerTooth);
    }

    public isSealantRepairPerTooth(): IChartRenderProcedure | undefined {
        return this.data.procedures.find((p) => p.renderRule === ProcedureRenderRule.SealantRepairPerTooth);
    }

    public isStainlessSteelCrown(): IChartRenderProcedure | undefined {
        return this.data.procedures.find((p) => p.renderRule === ProcedureRenderRule.StainlessSteelCrown);
    }

    public isPontic(): IChartRenderProcedure | undefined {
        return this.data.procedures.find((p) => p.renderRule === ProcedureRenderRule.Pontic);
    }

    public isCrownRenderRule(): IChartRenderProcedure | undefined {
        return this.data.procedures.find((p) => p.renderRule === ProcedureRenderRule.Crown);
    }

    // Abutments
    public isAbutmentSupportedCrown(): IChartRenderProcedure | undefined {
        return this.data.procedures.find((p) => p.renderRule === ProcedureRenderRule.AbutmentSupportedCrown);
    }
    public isAbutmentRetainerCrown(): IChartRenderProcedure | undefined {
        return this.data.procedures.find((p) => p.renderRule === ProcedureRenderRule.AbutmentRetainerCrown);
    }
    public isAbutmentThreeQuarterCrown(): IChartRenderProcedure | undefined {
        return this.data.procedures.find((p) => p.renderRule === ProcedureRenderRule.AbutmentThreeQuarterCrown);
    }
    public isAbutmentMarylandBridge(): IChartRenderProcedure | undefined {
        return this.data.procedures.find((p) => p.renderRule === ProcedureRenderRule.AbutmentMarylandBridge);
    }

    public isMissing(): IChartCondition | undefined {
        return this.data.conditions.find((c) => c.renderRule === 'do-not-display');
    }

    public get hasConditionWithVestibular(): boolean {
        return (
            this.data.conditions
                .filter(
                    (c) =>
                        c.renderRule === 'incipient-caries' ||
                        c.renderRule === 'defective-restoration' ||
                        c.renderRule === 'fractured',
                )
                .findIndex((c) => this._renderItemIsVestiular(c)) > -1
        );
    }

    public get hasProcedureWithVestibular(): boolean {
        return (
            this.data.procedures
                .filter((p) => p.renderRule === ProcedureRenderRule.Restoration)
                .findIndex((p) => this._renderItemIsVestiular(p)) > -1
        );
    }

    private _renderItemIsVestiular(item: ChartRenderAction): boolean {
        if (!item.areas?.length) return false;
        return (
            item.areas?.includes('Vestibular') ||
            item.areas?.includes('BuccalVestibular') ||
            item.areas?.includes('FacialVestibular') ||
            item.areas?.includes('LingualVestibular')
        );
    }

    private _handleDrifted() {
        if (this.data.x || this.data.y) this._driftedContainer = new Drifted(this);
    }

    private _handleProcedures() {
        if (this._frontContainer && this._backContainer && this._surfacesContainer)
            this.data.procedures.forEach((p) => {
                const renderRule = p.renderRule;
                switch (renderRule) {
                    case ProcedureRenderRule.Extraction: {
                        this._extraction = new Extraction(this, p);
                        break;
                    }
                    case ProcedureRenderRule.PostCore: {
                        this._postCore = new PostCore(this, this._frontContainer as Front, p);
                        break;
                    }
                    case ProcedureRenderRule.PostRemoval: {
                        this._postCore.removeChildren();
                        break;
                    }
                    case ProcedureRenderRule.RootCanal: {
                        this._rootCanal = new RootCanal(this, p);
                        break;
                    }
                    case ProcedureRenderRule.Crown: {
                        new CrownRenderRule(this, p);
                        break;
                    }
                    case ProcedureRenderRule.Restoration: {
                        //Ensure that vestibular conditions always win.
                        if (this._renderItemIsVestiular(p)) this._classV = new ClassV(this, p);
                        break;
                    }
                    case ProcedureRenderRule.AbutmentRetainerCrown: {
                        this._AbutmentRetainerCrown = new AbutmentRetainerCrown(this);
                        this._denture = new Denture(this);
                        break;
                    }
                    case ProcedureRenderRule.Pontic: {
                        this._pontic = new Pontic(this);
                        this._denture = new Denture(this);
                        break;
                    }
                    case ProcedureRenderRule.PartialDenture: {
                        this._denture = new Denture(this);
                        break;
                    }
                    case ProcedureRenderRule.MaxillaryDenture: {
                        this._denture = new Denture(this);
                        break;
                    }
                    case ProcedureRenderRule.MandibularDenture: {
                        this._denture = new Denture(this);
                        break;
                    }
                }
            });
    }

    private _handleConditions() {
        if (this._frontContainer)
            this.data.conditions.forEach((c) => {
                const renderRule = c.renderRule;
                if (renderRule === 'impacted-bony-distal') this._impactedContainer = new Impacted(this, 'BD');
                if (renderRule === 'impacted-bony-mesial') this._impactedContainer = new Impacted(this, 'BM');
                if (renderRule === 'impacted-vertical') this._impactedContainer = new Impacted(this, 'V');
                if (renderRule === 'impacted-partial-bony-mesial') this._impactedContainer = new Impacted(this, 'PBM');
                if (renderRule === 'impacted-partial-bony-distal') this._impactedContainer = new Impacted(this, 'PBD');
                if (renderRule === 'impacted-horizontal') this._impactedContainer = new Impacted(this, 'H');
                if (renderRule === 'abscess-periodontal-distal')
                    this._abscessPeriodontalContainer.push(new AbscessPeriodontal(this, 'Distal'));
                if (renderRule === 'abscess-periodontal-mesial')
                    this._abscessPeriodontalContainer.push(new AbscessPeriodontal(this, 'Mesial'));
                if (renderRule === 'open-contact-distal') this._openContactContainer.push(new OpenContact(this, 'Distal'));
                if (renderRule === 'open-contact-mesial') this._openContactContainer.push(new OpenContact(this, 'Mesial'));
                if (renderRule === 'watch') this._textOverlayContainer = new Watch(this);
                if (renderRule === 'partially-erupted') this._textOverlayContainer = new PartiallyErupted(this);
                if (renderRule === 'abcess-periapical')
                    this._abcessPeriapicalContainer = new AbcessPeriapical(this, this._frontContainer as Front);
                if (renderRule === 'broken') this._broken = new Broken(this);
                if (renderRule === 'fractured') this._fractured = new Fractured(this);
                if (renderRule === 'gingival-recession') this._broken = new GingivalRecession(this);
                if (this.hasConditionWithVestibular && !this.hasProcedureWithVestibular) this._classV = new ClassV(this, c);
            });
    }

    private handleToothPosition() {
        const maxXTranslation = 10;
        const translationX = this.data.x
            ? this.getIsLeftMouth
                ? -this.data.x < -maxXTranslation
                    ? -maxXTranslation
                    : -this.data.x
                : this.data.x > maxXTranslation
                ? maxXTranslation
                : this.data.x
            : 0;
        const translationY = this.data.y ? (this.getIsBottomRow ? this.data.y : -this.data.y) : 0;

        this.x = this.getXPosition() + translationX;
        this.y =
            this.toothReference.position <= 16
                ? settings.logicalHeight / 2 - 35 + translationY
                : settings.logicalHeight / 2 + 35 + translationY;
        this.height = this.height * this._toothScale;
    }

    public getToothExtraction(): IChartRenderProcedure | undefined {
        return this.data.procedures.find((proc) => {
            return proc.renderRule === ProcedureRenderRule.Extraction;
        });
    }

    public implantProcedure(): IChartRenderProcedure | undefined {
        return this.data.procedures.find((proc) => {
            return proc.renderRule === ProcedureRenderRule.Implant;
        });
    }

    public isImplantSupportedCrown(): IChartRenderProcedure | undefined {
        return this.data.procedures.find((proc) => {
            return proc.renderRule === ProcedureRenderRule.ImplantSupportedCrown;
        });
    }

    public getDentureProcedure(): IChartRenderProcedure | undefined {
        return this.data.procedures.find((proc) => {
            return (
                proc.renderRule === ProcedureRenderRule.PartialDenture ||
                proc.renderRule === ProcedureRenderRule.MaxillaryDenture ||
                proc.renderRule === ProcedureRenderRule.MandibularDenture
            );
        });
    }

    public getVeneerProcedure(): IChartRenderProcedure | undefined {
        return this.data.procedures.find((proc) => {
            return proc.renderRule === ProcedureRenderRule.LabialVeneer;
        });
    }

    private _handleVeneer() {
        if (this.getVeneerProcedure()) {
            this.hasVeneer = true;
        }
    }

    private _handleDenture() {
        if (this.getDentureProcedure()) {
            this.hasDenture = true;
        }
    }

    private _handleMissingRetainedRootCondition() {
        this.data.conditions.forEach((c) => {
            const renderRule = c.renderRule;
            if (renderRule === 'missing-retained-root') {
                this.missingRetainedRoot = true;
                this.createToothImages();
            }
        });
    }

    private createToothImages() {
        this._frontContainer = this._createFront();
        this._backContainer = this._createBack();
        this._surfacesContainer = this._createSurfaces();
    }

    public get getToothWidth(): number {
        return this._toothWidthRatio * settings.logicalWidth;
    }

    public get getIsBottomRow(): boolean {
        const positionIndex = this.getToothPosition - 1;
        return positionIndex >= 16 || positionIndex >= 43;
    }

    public get getIsLeftMouth(): boolean {
        const position = this.getToothPosition - 1;
        return position <= 7 || position >= 24;
    }

    public get getIsPrimary(): boolean {
        return this.data.dentitionMode === 'Primary' || this.data.dentitionMode === 'PrimarySupernumerary';
    }

    public get isToothVisible(): boolean {
        const dentitionMode = this.data.dentitionMode;

        const isPontic = this.isPontic();
        const isAbutmentRetainerCrown = this.isAbutmentRetainerCrown();
        const toothExtraction = this.getToothExtraction();

        if (this.isMissing() && !isPontic && !isAbutmentRetainerCrown) return false;
        if (dentitionMode === undefined) return false;
        if (this.isMolar && (dentitionMode === 'Primary' || dentitionMode === 'PrimarySupernumerary')) return false;
        if (toothExtraction?.status === 'Completed' && !isPontic && !isAbutmentRetainerCrown) return false;
        if (
            (toothExtraction?.type === ProcedureActionType.Existing ||
                toothExtraction?.type === ProcedureActionType.ExistingOther) &&
            !isPontic &&
            !isAbutmentRetainerCrown
        )
            return false;

        return true;
    }

    /**
     * Returns a display object by name in the context of this tooth
     *
     * @param {string} name
     * @return {*}
     * @memberof Tooth
     */
    public getToothChildByName(name: string): DisplayObject {
        return this.getChildByName(name);
    }

    /**
     * Is this tooth a molar
     *
     * @param {number} toothNumber
     * @return {*}
     * @memberof Tooth
     */
    public get isMolar(): boolean {
        return molars.indexOf(this.data.id) > -1;
    }

    /**
     * Handle whether a tooth is selected in multiselect mode
     *
     * @private
     * @memberof Tooth
     */
    private _handleSelected = () => {
        if (this._selected) {
            this._circleHighlight();
            this._handleSelectedGlow();
        } else {
            this._circleNormal();
        }
    };

    /**
     * Adds a filter to the given container
     *
     * @protected
     * @param {(PIXI.Container | undefined)} container
     * @param {Filter} filter
     * @memberof Tooth
     */
    protected addContainerFilter(container: PIXI.Container | undefined, filter: Filter): void {
        if (container) {
            if (container?.filters) {
                container.filters = [...(container.filters as Filter[]), filter];
            } else {
                container.filters = [filter];
            }
        }
    }

    /**
     * Removes a specific filter from a container
     *
     * @protected
     * @param {PIXI.Container} container
     * @param {number} filterId
     * @memberof Tooth
     */
    protected cleanupContainerFilter(container: PIXI.Container, filterId: number): void {
        if (container?.filters)
            container.filters = [...(container.filters as Filter[]).filter((filter) => filter.program.id !== filterId)];
    }

    public outlineFilter = getOutlineFilter(0x000000, 1);

    /**
     * Used to apply the tooth outline to specific containers instead of whole tooth.
     *
     * @private
     * @memberof Tooth
     */
    private applyToothOutlineFilters() {
        if (this._surfacesContainer) this.addContainerFilter(this._surfacesContainer, this.outlineFilter);
        if (this._frontContainer) this.addContainerFilter(this._frontContainer, this.outlineFilter);
        if (this._positionTextContainer) this.addContainerFilter(this._positionTextContainer, this.outlineFilter);
        if (this._backContainer) this.addContainerFilter(this._backContainer, this.outlineFilter);
    }

    /**
     * Used to cleanup tooth outline filter from specific tooth conatiners.
     *
     * @private
     * @memberof Tooth
     */
    private cleanupToothOutlineFilters() {
        if (this._surfacesContainer) this.cleanupContainerFilter(this._surfacesContainer, this.outlineFilter.program.id);
        if (this._frontContainer) this.cleanupContainerFilter(this._frontContainer, this.outlineFilter.program.id);
        if (this._positionTextContainer) this.cleanupContainerFilter(this._positionTextContainer, this.outlineFilter.program.id);
        if (this._backContainer) this.cleanupContainerFilter(this._backContainer, this.outlineFilter.program.id);
    }

    /**
     * Handle tooth selection glow filter
     *
     * @private
     * @memberof Tooth
     */
    private _handleSelectedGlow() {
        if (this._selected) {
            this.applyToothOutlineFilters();
        }
    }

    public _pointerOver(): void {
        if (this._onToothHover && this.worldTransform.tx && this.worldTransform.ty) {
            this._onToothHover(true, this.data, this.toothReference, {
                x: this.worldTransform.tx + 7,
                y: this.worldTransform.ty + (this.getIsBottomRow ? this.height / 15 : this.height / 8),
            });
        }
    }

    public _pointerOut(): void {
        if (this._onToothHover) {
            this._onToothHover(false, this.data, this.toothReference, {
                x: this.worldTransform.tx + 7,
                y: this.worldTransform.ty + (this.getIsBottomRow ? this.height / 15 : this.height / 8),
            });
        }
    }

    /**
     * Handle mouse down event on tooth
     *
     * @private
     * @memberof Tooth
     */
    private _mouseDown = () => {
        store.dispatch(selectTooth({ position: this.data.id }));
    };

    /**
     * Handle mouse over event on tooth
     *
     * @private
     * @memberof Tooth
     */
    private _mouseOver = () => {
        if (!this._selected) {
            //We need to add the outeline filters to specific peices not the whole tooth
            this.applyToothOutlineFilters();
        }
        this._circleHighlight();
    };

    /**
     * Remove Glow and change text badge
     *
     * @private
     * @memberof ToothSprite
     */
    private _mouseOut = () => {
        if (!this._selected) {
            this.cleanupToothOutlineFilters();
            this._circleNormal();
        }
    };

    /**
     * Create the front facing tooth
     *
     * @private
     * @returns {PIXI.Sprite} Occlusal Tooth Sprite
     * @memberof ToothSprite
     */
    private _createFront() {
        return new Front(this);
    }

    /**
     * Create Occlusal Surfaces
     *
     * @private
     * @returns {PIXI.Sprite[]} Container of surface sprites
     * @memberof ToothSprite
     */
    private _createSurfaces(): Surfaces {
        return new Surfaces(this, this.toothReference.occlusalSurfaces);
    }

    private _createBack(): Back {
        return new Back(this);
    }
    /**
     * Create the text circle/badge
     *
     * @private
     * @returns {PIXI.Container} Text Circle Container
     * @memberof ToothSprite
     */
    private _createTextCircle = (): TextCircle => {
        return new TextCircle(this);
    };

    /**
     * Create tooth circle highlight
     *
     * @private
     * @memberof Tooth
     */
    private _circleHighlight = () => {
        const text = this._positionTextContainer.getChildByName('text');
        const circle = this._positionTextContainer.getChildByName('circle');

        (circle as PIXI.Graphics).tint = ChartApp.getThemePalleteItem('themeSecondary');
        (text as PIXI.Text).style.fill = ChartApp.getThemePalleteItem('neutralTertiary');
    };

    /**
     * Create regular tooth circle
     *
     * @private
     * @memberof Tooth
     */
    private _circleNormal = () => {
        const text = this._positionTextContainer.getChildByName('text');
        const circle = this._positionTextContainer.getChildByName('circle');

        (circle as PIXI.Graphics).tint = ChartApp.getThemePalleteItem('themeLight');
        (text as PIXI.Text).style.fill = ChartApp.getThemePalleteItem('black');
    };

    /**
     * Get X Position
     *
     * @private
     * @returns {number} Calculated X Position
     * @memberof ToothSprite
     */
    public getXPosition(): number {
        const currentToothPosition = this.getToothPosition;
        const zeroedToothPosition = Math.abs(16 - (32 - Math.abs(currentToothPosition - 32)));
        return currentToothPosition <= 16
            ? (currentToothPosition - 1) * this.getToothWidth
            : settings.logicalWidth - zeroedToothPosition * this.getToothWidth;
    }

    /**
     * Removes file extention from file name
     *
     * @static
     * @param {string} name
     * @returns
     * @memberof Tooth
     */
    public static getTextureName(name: string): string {
        return name.split('.')[0];
    }

    /**
     * Gets the current position of the tooth on the chart.
     *
     * @readonly
     * @memberof Tooth
     */
    public get getToothPosition(): number {
        const position = this.toothReference.position;
        return position > 32 ? (position >= 42 ? 3 + Math.abs(32 - position) : 23 + (Math.abs(32 - position) - 16)) : position;
    }

    private getRenderArea(area: keyof typeof ToothArea): keyof typeof ToothArea {
        //View vestibular surfaces the same as regular surfaces so they show on the odonotogram.
        if (area === ToothArea.BuccalVestibular) return ToothArea.Buccal;
        if (area === ToothArea.LingualVestibular) return ToothArea.Lingual;
        if (area === ToothArea.FacialVestibular) return ToothArea.Facial;
        return area;
    }

    /**
     * Gets a list of procedures for a given ToothArea
     *
     * @param {(ToothArea | undefined)} area
     * @returns
     * @memberof Tooth
     */
    public getAreaActions(area: ToothArea | (keyof typeof ToothArea)[] | undefined): ChartRenderAction[] {
        if (!area) return [];
        //Order matters here, conditions must be mapped first to be overwritten visually by procedures on same areas.
        const actions = [...(this.data.conditions ?? []), ...(this.data.procedures ?? [])];

        if (isArray(area)) {
            return actions
                ? actions.filter((action) => {
                      return (
                          intersection(
                              action.areas?.map((area) => this.getRenderArea(area)),
                              area,
                          ).length > 0
                      );
                  })
                : [];
        } else {
            return actions
                ? actions.filter((procedure) =>
                      procedure.areas ? procedure.areas.map((area) => this.getRenderArea(area)).indexOf(area) > -1 : false,
                  )
                : [];
        }
    }

    /**
     * Get height with respect to aspect ratio to the tooth
     *
     * @param {PIXI.Sprite} sprite
     * @returns
     * @memberof Tooth
     */
    public _getHeight(sprite: PIXI.Sprite): number {
        const aspectRatio = sprite.width / sprite.height;
        return this.getToothWidth / aspectRatio / 2;
    }

    /**
     * Change the tint of a sprite based on procedure status and type
     *
     * @param {PIXI.Sprite} sprite
     * @param {IChartProcedure[]} actions
     * @memberof Tooth
     */
    public handleActionColor(actions: ChartRenderAction[], item: { sprite?: PIXI.Sprite; graphicsItem?: PIXI.Graphics }): void {
        const canHandleColor = (action: IChartRenderProcedure) => {
            return action.renderRule !== ProcedureRenderRule.LabialVeneer && action.renderRule !== ProcedureRenderRule.RootCanal;
        };

        const setActionColor = (action: ChartRenderAction) => {
            const color = this.fetchActionColor(action);
            if (item.sprite) {
                item.sprite.alpha = 0.75;
                item.sprite.tint = color;
            }
            if (item.graphicsItem) item.graphicsItem.tint = color;
        };

        actions.forEach((action) => {
            if (this.isActionChartRenderProcedure(action)) {
                if (canHandleColor(action)) setActionColor(action);
            } else {
                setActionColor(action);
            }
        });
    }

    public isActionChartRenderProcedure(action: ChartRenderAction): action is IChartRenderProcedure {
        return !!(action as IChartRenderProcedure)?.type;
    }

    public fetchActionColor(action: ChartRenderAction | ChartRenderAction[]): number {
        const themeMode = ChartApp.themeMode ? ChartApp.themeMode : 'light';
        const getColor = (action: ChartRenderAction) => {
            if (this.isActionChartRenderProcedure(action)) {
                if (action?.status === 'Completed') {
                    return settings.actionColors[themeMode].completed;
                }
                if (action?.type === 'Treatment') {
                    return settings.actionColors[themeMode].treatment;
                }
                if (action?.type === 'Referred') {
                    return settings.actionColors[themeMode].referred;
                }
                if (action?.type === 'Existing') {
                    return settings.actionColors[themeMode].existing;
                }
                if (action?.type === 'Existing Other') {
                    return settings.actionColors[themeMode].existing;
                }
            } else {
                return 0x000000;
            }
            return 0xffffff;
        };
        if (!isArray(action)) {
            return getColor(action);
        } else {
            const lastProc = action[action.length];
            return getColor(lastProc);
        }
    }

    public addColorOverlayToToothPart(item: PIXI.Sprite | PIXI.Container, proc?: IChartRenderProcedure): void {
        if (item && proc) {
            const fillColor = this.fetchActionColor(proc);
            item.filters = [new ColorOverlayFilter(fillColor, 1)];
        }
    }
}

export default Tooth;
