import { Stack, useTheme } from '@fluentui/react';
import { ActionButton } from 'components';
import { usePerioExams } from 'hooks';
import { TOOTH_CONTAINER_WIDTH } from 'pages/Perio/perio-settings';
import { IExtendedPerioTooth } from 'state/slices/charting/perio-exams/perio-exams.selectors';

type Props = {
    tooth: IExtendedPerioTooth;
    isUpper?: boolean;
    isDisabled?: boolean;
};

function SuppurationField({ tooth, isUpper, isDisabled }: Props) {
    const { setToothFieldAndUpdate } = usePerioExams();
    const { palette } = useTheme();

    const suppurationValue = isUpper ? tooth.facial?.suppuration : tooth.lingual?.suppuration;

    const _toggleSuppuration = () => {
        const newValue = !suppurationValue;
        setToothFieldAndUpdate({
            toothId: tooth.id,
            value: newValue,
            arch: isUpper ? 'facial' : 'lingual',
            perioField: 'suppuration',
        });
    };

    const _styles: React.CSSProperties = {
        minWidth: TOOTH_CONTAINER_WIDTH / 2,
        padding: 0,
        height: 20,
        color: suppurationValue ? palette.redDark : palette.neutralSecondary,
    };

    return (
        <Stack horizontalAlign="center">
            <ActionButton onClick={_toggleSuppuration} style={_styles} disabled={isDisabled}>
                S
            </ActionButton>
        </Stack>
    );
}

export default SuppurationField;
