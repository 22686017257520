import { MessageBar, MessageBarType } from '@fluentui/react';
import { IValidationError, ValidationType } from 'hooks/useValidation';

export type Props = {
    errors: IValidationError[];
};

const getPlurality = (errorCount: number, single: string, multiple: string) => {
    return errorCount > 1 ? multiple : single;
};
const getFieldPlurality = (errorCount: number, errorField?: boolean) => {
    return getPlurality(errorCount, errorField ? 'Error' : 'Field', errorField ? 'Errors' : 'Fields');
};

const addAnd = (value: string) => {
    const indexLengthOfValue = value.length - 1;
    const indexOfLastComma =
        indexLengthOfValue -
        value
            .split('')
            .reverse()
            .findIndex((char) => char === ',');

    if (indexOfLastComma !== indexLengthOfValue + 1) {
        const firstSection = value.slice(0, indexOfLastComma).toString();
        const lastSection = value.slice(indexOfLastComma + 1).toString();
        return [firstSection, 'and', lastSection].join(' ');
    }
    return value;
};

export function ValidationBar({ errors }: Props): JSX.Element | null {
    if (errors.length) {
        const requiredFields = errors.filter((error) => error.errorTypes.indexOf(ValidationType.Required) > -1);
        const phone = errors.filter((error) => error.errorTypes.indexOf(ValidationType.Phone) > -1);
        const email = errors.filter((error) => error.errorTypes.indexOf(ValidationType.Email) > -1);
        const ssn = errors.filter((error) => error.errorTypes.indexOf(ValidationType.SSN) > -1);
        const date = errors.filter((error) => error.errorTypes.indexOf(ValidationType.Date) > -1);
        const notFutureDate = errors.filter((error) => error.errorTypes.indexOf(ValidationType.NotFutureDate) > -1);
        const zip = errors.filter((error) => error.errorTypes.indexOf(ValidationType.Zip) > -1);
        const taxonomy = errors.filter((error) => error.errorTypes.indexOf(ValidationType.Taxonomy) > -1);
        const time = errors.filter((error) => error.errorTypes.indexOf(ValidationType.Time) > -1);
        const start = errors.filter((error) => error.errorTypes.indexOf(ValidationType.Start) > -1);
        const end = errors.filter((error) => error.errorTypes.indexOf(ValidationType.End) > -1);
        const equalTime = errors.filter((error) => error.errorTypes.indexOf(ValidationType.EqualTime) > -1);

        const requiredString = requiredFields.length
            ? addAnd(
                  `${getFieldPlurality(requiredFields.length)}: ${requiredFields
                      .map((error) => error.fieldName.toLowerCase())
                      .join(', ')} ${getPlurality(errors.length, 'is required', 'are required')}.`,
              )
            : undefined;
        const phoneString = phone.length
            ? addAnd(
                  `${getFieldPlurality(phone.length)}: ${phone
                      .map((error) => error.fieldName.toLowerCase())
                      .join(', ')} ${getPlurality(phone.length, 'is an invalid phone number', 'have invalid phone numbers')}.`,
              )
            : undefined;
        const emailString = email.length
            ? addAnd(
                  `${getFieldPlurality(email.length)}: ${email
                      .map((error) => error.fieldName.toLowerCase())
                      .join(', ')} ${getPlurality(email.length, 'is an invalid email', 'have invalid emails')}.`,
              )
            : undefined;
        const ssnString = ssn.length
            ? addAnd(
                  `${getFieldPlurality(ssn.length)}: ${ssn
                      .map((error) => error.fieldName.toLowerCase())
                      .join(', ')} ${getPlurality(ssn.length, 'is an invalid SSN', 'have invalid SSNs')}.`,
              )
            : undefined;
        const dateString = date.length
            ? addAnd(
                  `${getFieldPlurality(date.length)}: ${date
                      .map((error) => error.fieldName.toLowerCase())
                      .join(', ')} ${getPlurality(date.length, 'is an invalid date', 'have invalid dates')}.`,
              )
            : undefined;
        const notFutureDateString = notFutureDate.length
            ? addAnd(
                  `${getFieldPlurality(notFutureDate.length)}: ${notFutureDate
                      .map((error) => error.fieldName.toLowerCase())
                      .join(', ')} ${getPlurality(date.length, 'cannot be a future date', 'cannot have future dates')}.`,
              )
            : undefined;
        const zipString = zip.length
            ? addAnd(
                  `${getFieldPlurality(zip.length)}: ${zip
                      .map((error) => error.fieldName.toLowerCase())
                      .join(', ')} ${getPlurality(zip.length, 'is an invalid zip code', 'have invalid zip codes')}.`,
              )
            : undefined;
        const taxonomyString = taxonomy.length
            ? addAnd(
                  `${getFieldPlurality(taxonomy.length)}: ${taxonomy
                      .map((error) => error.fieldName.toLowerCase())
                      .join(', ')} ${getPlurality(
                      taxonomy.length,
                      'is an invalid taxonomy code',
                      'have invalid taxonomy codes',
                  )}.`,
              )
            : undefined;
        const timeString = time.length
            ? addAnd(
                  `${getFieldPlurality(time.length)}: ${errors.map((error) => error.fieldName).join(', ')} ${getPlurality(
                      time.length,
                      'is an invalid time',
                      'have invalid time values',
                  )}.`,
              )
            : undefined;
        const startEndString =
            start.length + end.length
                ? addAnd(
                      `${getFieldPlurality(start.length + end.length, true)}: ${errors
                          .map((error) => error.fieldName)
                          .join(', ')} ${getPlurality(
                          start.length + end.length,
                          'time must be earlier than end time',
                          'have invalid time values',
                      )}.`,
                  )
                : undefined;

        const isEquals = equalTime.length
            ? addAnd(
                  `${getFieldPlurality(equalTime.length, true)}: ${equalTime
                      .map((error) => error.fieldName)
                      .join(', ')} ${getPlurality(
                      equalTime.length,
                      'time cant be equal to end time',
                      'have invalid time values',
                  )}.`,
              )
            : undefined;
        const getErrorString = () => {
            const errors: string[] = [];
            [
                requiredString,
                phoneString,
                emailString,
                ssnString,
                dateString,
                notFutureDateString,
                zipString,
                taxonomyString,
                timeString,
                startEndString,
                isEquals,
            ].forEach((item) => {
                if (item) errors.push(item);
            });

            return errors.join(' ');
        };

        return (
            <MessageBar isMultiline messageBarType={MessageBarType.blocked}>
                {getErrorString()}
            </MessageBar>
        );
    }
    return null;
}

export default ValidationBar;
