import { SerializedError } from '@reduxjs/toolkit';
import { EncounterSummaryWithLineItems, ICommonTransaction } from 'api/models/encounter-ledger.model';
import { IPaymentSource } from 'api/models/payment-source.model';
import { IValidationError } from 'hooks/useValidation';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { BillingProcedureWithTransactionId } from '../treatment-plan-credit-and-uac/treatment-plan-credit-and-uac.state';

export type TransactionLookup = Record<string, Record<string, ICommonTransaction>>; //{[encuonterId]: {[transactionId: string]: ICommonTransaction}}
export type BillingProcedureWithTransactionIds = {
    adjustmentTransactionId?: string;
} & BillingProcedureWithTransactionId;

//Keeping ledger page logic away from the modal logic.
export type PatientPaymentsAndAdjustmentsState = {
    /**
     * Stores the current payment source for making payment.
     *
     * @type {IPaymentSource}
     */
    paymentSource?: IPaymentSource;
    /**
     * Determines if the user is making an overpayment/adjustment
     *
     * @type {boolean}
     */
    allowOverpaymentOrAdjustment: boolean;
    /**
     * Determines if the user is making an overpayment on estimate procedures
     *
     * @type {boolean}
     */
    allowEstimateOverpayment: boolean;
    /**
     * Transactions specifically related to a payment.
     * {[encounterId]: {[chartProcedureId]: ICommonTransaction}}
     *
     * @type {TransactionLookup}
     */
    paymentTransactions?: TransactionLookup;
    /**
     * Transactions specifically related to a estimate payment.
     * {[encounterId]: {[chartProcedureId]: ICommonTransaction}}
     *
     * @type {TransactionLookup}
     */
    estimateTransactions?: TransactionLookup;

    /**
     * Set to true when the payment info is modified.
     *
     * @type {boolean}
     */
    paymentsModified: boolean;
    /**
     * Set to true when the estimate payment info is modified.
     *
     * @type {boolean}
     */
    estimatePaymentsModified: boolean;

    /**
     * Transactions specifically related to an adjustment.
     * {[encounterId]: {[chartProcedureId]: ICommonTransaction}}
     *
     * @type {TransactionLookup}
     */
    adjustmentTransactions?: TransactionLookup;

    /**
     * Max amount all adjustment transactions can add up to.
     *
     * @type {number}
     */
    totalAdjustmentAmount?: number;

    /**
     * Set to true when the adjustment info is modified.
     *
     * @type {boolean}
     */
    adjustmentsModified: boolean;

    /**
     * Determines the loading status of payment/adjustment related billing procedures.
     *
     * @type {LoadingStatus}
     */
    loadingLedgerbillingProcedures: LoadingStatus;
    /**
     * Billing procedures that are relevant for making a payment/adjustment.
     * In this case it is treatment plan completed status procedures.
     *
     * @type {BillingProcedureWithTransactionIds[]}
     */
    paymentsAndAdjustmentsBillingProcedures?: BillingProcedureWithTransactionIds[];
    /**
     * Billing procedures that are relevant for making a payment/adjustment.
     * In this case it is treatment plan completed status procedures.
     *
     * @type {BillingProcedureWithTransactionIds[]}
     */
    estimateBillingProcedures?: BillingProcedureWithTransactionIds[];
    /**
     * Stores the network error for making a completed billing procedures request in state.
     *
     * @type {SerializedError}
     */
    ledgerBillingProcedureError?: SerializedError;
    /**
     *Determines the loading status of the encounter summaries with line items list.
     *
     * @type {LoadingStatus}
     */
    loadingEncounterSummariesWithLineItems: LoadingStatus;
    /**
     * List of encounter summaries view models with their respective procedures.
     * Used to create the table of data for payments/adjustments.
     *
     * @type {EncounterSummaryWithLineItems[]}
     */
    encounterSummariesWithLineItems?: EncounterSummaryWithLineItems[];
    /**
     * Stores the network error for making a encounter summaries with line items view request in state.
     *
     * @type {SerializedError}
     */
    encounterSummariesWithLineItemsError?: SerializedError;
    /**
     * Determines the status of the saving state of patient payment information
     *
     * @type {LoadingStatus}
     */
    savingPaymentInformation: LoadingStatus;
    /**
     * Determines the status of the saving state of patient payment information
     *
     * @type {LoadingStatus}
     */
    savingAdjustmentInformation: LoadingStatus;
    /**
     * Stores current validation errors for making a payment/adjustment.
     *
     * @type {IValidationError[]}
     */
    validationErrors: IValidationError[];
};

const initialPatientPaymentsAndAdjustmentsState: PatientPaymentsAndAdjustmentsState = {
    loadingLedgerbillingProcedures: LoadingStatus.Idle,
    loadingEncounterSummariesWithLineItems: LoadingStatus.Idle,
    savingAdjustmentInformation: LoadingStatus.Idle,
    savingPaymentInformation: LoadingStatus.Idle,
    validationErrors: [],
    adjustmentsModified: false,
    paymentsModified: false,
    estimatePaymentsModified: false,
    allowOverpaymentOrAdjustment: false,
    allowEstimateOverpayment: false,
};

export default initialPatientPaymentsAndAdjustmentsState;
