import { IButtonProps, DefaultButton } from '@fluentui/react';
import { useId } from '@uifabric/react-hooks';

export default function SmallDefaultButton(props: IButtonProps) {
    const id = useId();

    return (
        <DefaultButton
            {...props}
            id={id}
            style={{
                maxWidth: 50,
            }}
        />
    );
}
