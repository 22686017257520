import { IconButton } from '@fluentui/react';
import IWorkListAppointmentView from 'api/models/worklist-appointment-view';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import { useDispatch } from 'react-redux';
import { setSelectedWorkListEditItem, setWorkListLayerOpen } from 'state/slices/admin-huddle/worklists/worklist.slice';
import { setCancelAppointmentModalOpen } from 'state/slices/scheduling/scheduling.slice';

const cancelAppointment: ISortableColumn<IWorkListAppointmentView> = {
    key: 'cancelAppointment',
    minWidth: 60,
    maxWidth: 60,
    isResizable: true,
    name: 'Cancel Appt.',
    onRender: CancelAppointmentColumn,
};

function CancelAppointmentColumn(item: IWorkListAppointmentView | undefined) {
    const dispatch = useDispatch();

    if (!item) return null;

    return (
        <IconButton
            iconProps={{ iconName: 'Delete' }}
            styles={{ root: { height: 20, width: 20 } }}
            onClick={() => {
                //We need to set the appointment to remove id in the same area of state we use for the cancellation modal.
                dispatch(setCancelAppointmentModalOpen({ appointmentToRemoveId: item.id }));
                //This will open the modal in the context of the worklist cancellation modal component.
                dispatch(setWorkListLayerOpen({ layer: 'CancelAppointmentModal', isOpen: true }));
                //Need to set this so recent items gets populated...
                dispatch(setSelectedWorkListEditItem(item));
            }}
        />
    );
}

export default cancelAppointment;
