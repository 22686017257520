import { Link, MessageBar, SelectionMode, Spinner, Text, TooltipHost } from '@fluentui/react';
import { VitalType } from 'api/models/patient-vital.model';
import SortableDetailsList, { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import { getTime } from 'date-fns';
import useEncounterId from 'hooks/useEncounterId';
import { LoadingStatus } from 'interfaces/loading-statuses';

import { useDispatch, useSelector } from 'react-redux';
import { setVitalPanelDataFromMappedVital } from 'state/slices/patient/vitals/vitals.actions';
import { MappedVital, selectPatientVitalTableData, selectVitalsLoading } from 'state/slices/patient/vitals/vitals.selectors';
import { convertFromMilitaryTime } from 'utils/convertFromMilitaryTime';

function VitalsDetailsList(): JSX.Element {
    const encounterId = useEncounterId();
    const dispatch = useDispatch();
    const vitalsData = useSelector(selectPatientVitalTableData);
    const vitalsLoading = useSelector(selectVitalsLoading);

    // Test patient with decent data
    //https://localhost:3000/4a318e3e/patient/cac91a32-9975-4468-8e2d-0e9c54ce2a27/encounter/b47683ce-0172-4365-b716-feeb7c863b55

    const _openEncounterVitals = (item?: MappedVital) => {
        if (item && item.encounterId === encounterId) dispatch(setVitalPanelDataFromMappedVital(item));
    };

    const columns: ISortableColumn<MappedVital>[] = [
        {
            key: 'date',
            minWidth: 160,
            maxWidth: 160,
            name: 'Date/Time',
            fieldName: 'date',
            onRender: (item) => {
                if (item) {
                    const date = item.date;
                    const time = item.appointmentStartTime ? ` - ${convertFromMilitaryTime(item.appointmentStartTime)}` : '';
                    const dateTimeText = `${date}${time}`;
                    if (item.encounterId === encounterId) {
                        return (
                            <Text variant="mediumPlus" title={`Reading(s) taken: ${dateTimeText} - Current encounter`}>
                                <Link onClick={() => _openEncounterVitals(item)}>{dateTimeText} - Current encounter</Link>
                            </Text>
                        );
                    } else {
                        return (
                            <Text variant="mediumPlus" title={`Reading(s) taken: ${dateTimeText}`}>
                                {dateTimeText}
                            </Text>
                        );
                    }
                }
            },
            getValueKey: (item) => {
                if (item) {
                    return item?.date ? getTime(new Date(item.date)).toString() : '';
                }
                return '';
            },
        },
        {
            key: 'Bp',
            minWidth: 180,
            maxWidth: 180,
            name: 'BP',
            fieldName: VitalType.BloodPressure,
            onRender: (item?: MappedVital) => {
                if (item) {
                    return (
                        <Text variant="mediumPlus" title={`Blood pressure: ${item.BP}`}>
                            {item.BP}
                        </Text>
                    );
                }
            },
        },

        {
            key: 'Ht',
            minWidth: 50,
            maxWidth: 50,
            name: 'Ht',
            fieldName: VitalType.Height,
            onRender: (item?: MappedVital) => {
                if (item) {
                    return <Text variant="mediumPlus">{item.Ht}</Text>;
                }
            },
        },

        {
            key: 'Wt',
            minWidth: 50,
            maxWidth: 50,
            name: 'Wt',
            fieldName: VitalType.Weight,
            onRender: (item?: MappedVital) => {
                if (item) {
                    return <Text variant="mediumPlus">{item.Wt}</Text>;
                }
            },
        },
        {
            key: 'T',
            minWidth: 80,
            maxWidth: 80,
            name: 'Temp',
            fieldName: VitalType.Tempurature,
            onRender: (item?: MappedVital) => {
                if (item) {
                    return <Text variant="mediumPlus">{item.T}</Text>;
                }
            },
        },
        {
            key: '02Sat',
            minWidth: 80,
            maxWidth: 80,
            name: '02Sat',
            fieldName: VitalType.OxygenSaturation,
            onRender: (item?: MappedVital) => {
                if (item) {
                    return <Text variant="mediumPlus">{item['02Sat']}</Text>;
                }
            },
        },
        {
            key: 'Pulse',
            minWidth: 80,
            maxWidth: 80,
            name: 'Pulse',
            fieldName: VitalType.Pulse,
            onRender: (item?: MappedVital) => {
                if (item) {
                    return <Text variant="mediumPlus">{item.Pulse}</Text>;
                }
            },
        },
        {
            key: 'RR',
            minWidth: 80,
            maxWidth: 80,
            name: 'RR',
            fieldName: VitalType.RespiratoryRate,
            onRender: (item?: MappedVital) => {
                if (item) {
                    return <Text variant="mediumPlus">{item.RR}</Text>;
                }
            },
        },
    ];

    return (
        <>
            {vitalsLoading === LoadingStatus.Pending ? (
                <Spinner label="Loading vitals list..." labelPosition="right" />
            ) : vitalsData.length && vitalsLoading === LoadingStatus.Completed ? (
                <SortableDetailsList<MappedVital>
                    columns={columns}
                    sortOnMount={true}
                    onActiveItemChanged={undefined}
                    items={vitalsData.slice(0, 10)}
                    selectionMode={SelectionMode.none}
                    sortColumns={['date']}
                    initialSortDirection={['asc']}
                    onItemInvoked={(item) => _openEncounterVitals(item)}
                />
            ) : vitalsLoading === LoadingStatus.Completed && !vitalsData.length ? (
                <MessageBar>No vitals have been added.</MessageBar>
            ) : null}
        </>
    );
}

export default VitalsDetailsList;
