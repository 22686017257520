import { PropsWithChildren } from 'react';
import { IModalProps, Stack, Modal, IDragOptions, ContextualMenu } from '@fluentui/react';
import { ActivityHeader } from '.';
import ActionButton, { IActionButton } from './ActionButton';

type Props = {
    title: string;
    modalProps: IModalProps;
    subTitleContent?: JSX.Element;
    mainButtons?: IActionButton[];
    hideClose?: boolean;
    isDraggable?: boolean;
    rightFooterContent?: JSX.Element;
    hideContentOverflow?: boolean;
};

const TModal = ({
    title,
    modalProps,
    subTitleContent,
    mainButtons,
    hideClose,
    isDraggable,
    children,
    rightFooterContent,
    hideContentOverflow,
}: PropsWithChildren<Props>): JSX.Element => {
    const _hasMainButtons = mainButtons && mainButtons.length;
    const _mainButtons = _hasMainButtons
        ? mainButtons?.map((button, index) => {
              return <ActionButton {...button} key={index} />;
          })
        : null;

    const activityHeaderMainButtons: IActionButton[] = [
        {
            type: 'IconButton',
            iconProps: {
                iconName: 'Cancel',
            },
            onClick: () => (modalProps.onDismiss ? modalProps.onDismiss() : {}),
        },
    ];

    const dragOptions: IDragOptions = {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Cancel',
        menu: ContextualMenu,
        dragHandleSelector: '.Header',
    };

    return (
        <Modal
            styles={{
                scrollableContent: { overflow: 'hidden', display: 'flex' },
                main: { overflow: 'hidden', display: 'flex' },
            }}
            {...modalProps}
            dragOptions={isDraggable ? dragOptions : undefined}
        >
            <Stack
                style={{
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    height: hideContentOverflow ? '100%' : 'inherit',
                }}
                grow
            >
                <div className="Header">
                    <ActivityHeader mainButtons={!hideClose ? activityHeaderMainButtons : undefined} title={title} />
                </div>
                {subTitleContent && <div>{subTitleContent}</div>}
                <div
                    style={{
                        flex: 1,
                        overflow: hideContentOverflow ? 'hidden' : 'auto',
                        height: hideContentOverflow ? '100%' : 'inherit',
                    }}
                >
                    <Stack
                        style={{
                            flex: 1,
                            display: 'flex',
                            overflow: hideContentOverflow ? 'hidden' : 'auto',
                            height: hideContentOverflow ? '100%' : 'inherit',
                        }}
                        grow
                    >
                        {children}
                    </Stack>
                </div>
                {_hasMainButtons ? (
                    <Stack styles={{ root: { padding: 10 } }} tokens={{ childrenGap: 12 }} horizontal verticalAlign="center">
                        <Stack grow horizontal tokens={{ childrenGap: 10 }}>
                            {_mainButtons}
                        </Stack>
                        <Stack verticalAlign="center" grow>
                            {rightFooterContent}
                        </Stack>
                    </Stack>
                ) : null}
            </Stack>
        </Modal>
    );
};

export default TModal;
