import { Dropdown, IDropdownOption, Stack } from '@fluentui/react';
import { ChartProcedurePreAuth } from 'api/models/chart.model';
import { useSelector } from 'hooks';
import { map } from 'lodash';
import { useDispatch } from 'react-redux';
import { selectWorkListFilters } from 'state/slices/admin-huddle/worklists/worklist.selectors';
import { setWorkListFilter } from 'state/slices/admin-huddle/worklists/worklist.slice';

const PreAuthStatusOptions: IDropdownOption[] = map(ChartProcedurePreAuth, (status) => ({
    key: status,
    text: status,
})).filter(option => option.key !== ChartProcedurePreAuth.Approved && option.key !== ChartProcedurePreAuth.NotRequired);

export default function PreAuthorizationStatusFilter() {
    const dispatch = useDispatch();
    const { preAuthorizationStatus } = useSelector(selectWorkListFilters<any>);

    return (
        <Stack.Item grow>
            <Dropdown
                label="Pre Auth Status"
                selectedKey={preAuthorizationStatus ?? ''}
                placeholder="(Select Pre Auth Status)"
                options={PreAuthStatusOptions}
                onChange={(e, option) => {
                    if (option) dispatch(setWorkListFilter({ path: 'preAuthorizationStatus', value: option.key }));
                }}
            />
        </Stack.Item>
    );
}
