import IWorkListEncounterView from 'api/models/worklist-encounter-view';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';

const attestingProviderName: ISortableColumn<IWorkListEncounterView> = {
    key: 'attestingProviderName',
    name: 'Attesting Provider',
    fieldName: 'attestingProviderName',
    minWidth: 100,
    maxWidth: 150,
    isResizable: true,
};

export default attestingProviderName;

