import { cloneDeep, find } from 'lodash';
import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { IAllergy, IReaction, ReactionSeverity } from 'api/models/patient-allergy.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { createPatientAllergies, getAllergyReactions, updatePatientAllergies } from './allergies.actions';
import { PatientState } from '../patient.state';

export const allergiesReducers = {
    setAllergiesPanelOpen: (state: PatientState, action: PayloadAction<boolean>): void => {
        const isOpen = action.payload;
        state.allergies.isAllergyPanelOpen = isOpen;

        if (!isOpen) {
            state.allergies.allergiesToAdd = [];
        }
    },
    clearAllergiesErrors: (state: PatientState): void => {
        state.allergies.error = undefined;
    },
    addAllergy: (
        state: PatientState,
        action: PayloadAction<{
            allergenId: string;
            athenaId: string;
            encounterId: string;
            allergenName?: string;
            snomedCode?: string;
        }>,
    ): void => {
        const { allergenId, encounterId, athenaId, allergenName, snomedCode } = action.payload;

        const allergyToAdd: IAllergy = {
            id: allergenId,
            allergenId: athenaId,
            allergenName,
            encounterId,
            categories: [],
            reactions: [
                {
                    isDeleted: false,
                    name: '',
                    snomedcode: 0,
                },
            ],
            onsetDate: '',
            code: snomedCode,
            isDeleted: false,
        };
        if (state.allergies.allergiesToAdd.length) {
            state.allergies.allergiesToAdd = [...state.allergies.allergiesToAdd, allergyToAdd];
        } else {
            state.allergies.allergiesToAdd = [allergyToAdd];
        }
    },
    deleteAllergy: (state: PatientState, action: PayloadAction<number>): void => {
        const allergyIndex = action.payload;
        state.allergies.allergiesToAdd = state.allergies.allergiesToAdd.filter((allergy, index) => index !== allergyIndex);
    },
    updateAllergyCriticality: (
        state: PatientState,
        action: PayloadAction<{ indexOfAllergy: number; value: string | undefined }>,
    ): void => {
        const { indexOfAllergy, value } = action.payload;

        state.allergies.allergiesToAdd[indexOfAllergy].criticality = value;
    },
    addReactionToAllergy: (state: PatientState, action: PayloadAction<number>): void => {
        const indexOfAllergy = action.payload;
        const newReaction: IReaction = {
            isDeleted: false,
            name: '',
            snomedcode: 0,
        };
        const allergies = state.allergies.allergiesToAdd[indexOfAllergy].reactions;
        if (allergies?.length) {
            state.allergies.allergiesToAdd[indexOfAllergy].reactions = [...allergies, newReaction];
        } else {
            state.allergies.allergiesToAdd[indexOfAllergy].reactions = [newReaction];
        }
    },
    deleteReactionToAllergy: (
        state: PatientState,
        action: PayloadAction<{ allergyIndex: number; reactionIndex: number }>,
    ): void => {
        const { allergyIndex, reactionIndex } = action.payload;
        const reactions = state.allergies.allergiesToAdd[allergyIndex].reactions?.filter(
            (reaction, index) => index !== reactionIndex,
        );
        state.allergies.allergiesToAdd[allergyIndex].reactions = reactions && reactions.length ? reactions : [];
    },
    updateAllergyReaction: (
        state: PatientState,
        action: PayloadAction<{ snomedcode: number; allergyIndex: number; reactionIndex: number }>,
    ): void => {
        const { snomedcode, allergyIndex, reactionIndex } = action.payload;

        const allergy = state.allergies.allergiesToAdd[allergyIndex];
        if (allergy.reactions) {
            const currentReaction = allergy.reactions[reactionIndex];
            const allergyReaction = find(state.allergies.allergyReactions, ['snomedCode', snomedcode]);
            if (allergyReaction && currentReaction) {
                const newReaction: IReaction = {
                    ...currentReaction,
                    name: allergyReaction.name,
                    snomedcode: allergyReaction.snomedCode,
                };

                allergy.reactions[reactionIndex] = newReaction;
            }
        }
    },
    updateAllergyReactionSeverity: (
        state: PatientState,
        action: PayloadAction<{ severity: ReactionSeverity; allergyIndex: number; reactionIndex: number }>,
    ): void => {
        const { severity, allergyIndex, reactionIndex } = action.payload;
        const allergy = state.allergies.allergiesToAdd[allergyIndex];
        if (allergy.reactions) {
            const currentReaction = allergy.reactions[reactionIndex];
            if (currentReaction) {
                const newReaction: IReaction = {
                    ...currentReaction,
                    severity: severity,
                };

                allergy.reactions[reactionIndex] = newReaction;
            }
        }
    },
    setAllergyOnsetDate: (state: PatientState, action: PayloadAction<{ allergyIndex: number; date: string }>): void => {
        const { allergyIndex, date } = action.payload;
        state.allergies.allergiesToAdd[allergyIndex]['onsetDate'] = date;
    },
    toggleShowAllergyHistory: (state: PatientState): void => {
        state.allergies.showHistory = !state.allergies.showHistory;
    },
};

export const allergiesExtraReducers = (builder: ActionReducerMapBuilder<PatientState>): ActionReducerMapBuilder<PatientState> =>
    builder
        .addCase(getAllergyReactions.pending, (state) => {
            state.allergies.loadingLookups = LoadingStatus.Pending;
        })
        .addCase(getAllergyReactions.fulfilled, (state, action) => {
            const allergyReactions = action.payload;

            state.allergies.loadingLookups = LoadingStatus.Completed;

            state.allergies.allergyReactions = allergyReactions;
        })
        .addCase(getAllergyReactions.rejected, (state) => {
            state.allergies.loadingLookups = LoadingStatus.Failed;
        })

        .addCase(updatePatientAllergies.pending, (state) => {
            state.allergies.saving = LoadingStatus.Pending;
        })
        .addCase(updatePatientAllergies.fulfilled, (state, action) => {
            state.allergies.data = action.payload;
            state.allergies.allergiesToAdd = [];
            state.allergies.saving = LoadingStatus.Completed;
            state.allergies.error = undefined;
            state.allergies.isAllergyPanelOpen = false;
        })
        .addCase(updatePatientAllergies.rejected, (state, action) => {
            state.allergies.saving = LoadingStatus.Failed;
            state.allergies.error = action.payload;
        })

        // Create PatientAllergy
        .addCase(createPatientAllergies.pending, (state) => {
            state.allergies.saving = LoadingStatus.Pending;
        })
        .addCase(createPatientAllergies.fulfilled, (state, action) => {
            state.allergies.data = action.payload;
            state.allergies.allergiesToAdd = [];
            state.allergies.saving = LoadingStatus.Completed;
            state.allergies.error = undefined;
            state.allergies.isAllergyPanelOpen = false;
        })
        .addCase(createPatientAllergies.rejected, (state, action) => {
            state.allergies.saving = LoadingStatus.Failed;
            state.allergies.error = action.payload;
        });
