import { IColumn, IDetailsListProps, SelectionMode } from '@fluentui/react';
import { SortableDetailsList } from 'components';

type CommonListParameters = {
    columns: IColumn[];
    items: any[];
    sortOnMount?: boolean;
    mountSortColumn?: string[];
    searchTerm?: string;
    filterHandler?: (item: any) => boolean;
} & Omit<IDetailsListProps, 'items'>;

function SharedDetailsList(props: CommonListParameters): JSX.Element {
    const { columns, items, sortOnMount, mountSortColumn } = props;
    return (
        <SortableDetailsList
            selectionMode={SelectionMode.none}
            sortOnMount={sortOnMount}
            sortColumns={mountSortColumn}
            initialSortDirection={['asc']}
            {...props}
            columns={columns}
            items={items}
        />
    );
}

export default SharedDetailsList;
