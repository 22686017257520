import { additionalInstructionsList } from 'state/slices/tenant/additionalInstructions';
import { dosageFrequencyList } from 'state/slices/tenant/dosageFrequency';
import { dosageQuantityList } from 'state/slices/tenant/dosageQuantity';
import { RootState } from 'state/store';

export default function Rx({ state }: { state: RootState }): JSX.Element | null {
    const { data } = state.tenant.providers;
    const _dosageFrequencyOptionsList = dosageFrequencyList(state);

    const _dosageQuantityOptionsList = dosageQuantityList(state);
    const _additionalInstructionsOptionsList = additionalInstructionsList(state);

    if (!state.encounter.patientEncounterPrescriptions.length) return null;

    const rxList = state.encounter.patientEncounterPrescriptions.map((prescription) => {
        const _dosageQuantity = _dosageQuantityOptionsList.find((dq) => dq.id === prescription.dosageQuantityUnit);
        const _dosageFrequency = _dosageFrequencyOptionsList.find((dq) => dq.id === prescription.frequency);
        const _additionalInstructions = _additionalInstructionsOptionsList.find(
            (dq) => dq.id === prescription.additionalInstructions,
        );
        const prescriber = prescription?.prescriberId ? data[prescription.prescriberId] : undefined;
        const byRoute = () => {
            if (prescription) {
                if (prescription.displayName?.includes('nasal')) {
                    return 'nasal';
                }
                if (prescription.displayName?.includes('dental')) {
                    return 'dental';
                }
                if (prescription.displayName?.includes('injection')) {
                    return 'injection';
                }
                if (prescription.displayName?.includes('mucosal')) {
                    return 'mucosal';
                }
                if (prescription.displayName?.includes('lozenges')) {
                    return 'mucous ';
                } else return 'oral';
            }
        };

        return (
            <div key={prescription.id}>
                <ul>
                    <li>Medication/Dose: {prescription.displayName}</li>
                    <ul>
                        {!prescription.unstructuredSig ? (
                            <li>
                                Sig: Take {prescription.dosageQuantity}&nbsp;
                                {_dosageQuantity?.displayName ?? ''} &nbsp;
                                {_dosageFrequency?.displayName ?? ''} by {byRoute()} route{' '}
                                {_additionalInstructions?.displayName ?? ''} for {prescription.duration} days
                            </li>
                        ) : (
                            <li>Unstructured Sig: {prescription.unstructuredSig}</li>
                        )}
                        {prescription.numberOfRefills !== undefined && (
                            <li>
                                {prescription.numberOfRefills} Refill{prescription.numberOfRefills !== 1 ? 's' : ''}
                            </li>
                        )}
                        <li>Associated Diagnosis: {prescription.diagnosisCode}</li>
                        {data && prescription.prescriberId && (
                            <li>
                                Prescriber: {prescriber?.firstName} {prescriber?.lastName}
                                {prescriber?.suffix ? `, ${prescriber.suffix}` : ''}
                            </li>
                        )}
                    </ul>
                </ul>
            </div>
        );
    });

    return (
        <>
            <h3>Rx</h3>
            {rxList}
        </>
    );
}
