import { Panel, PanelType, Stack, PrimaryButton, DefaultButton, Spinner, MessageBar, MessageBarType } from '@fluentui/react';
import { VitalNameType } from 'api/models/patient-vital.model';
import { usePatientId, useTenantId, useSelector } from 'hooks';
import { LoadingStatus } from 'interfaces/loading-statuses';

import { useDispatch } from 'react-redux';
import { setVitalPanelOpen } from 'state/slices/patient/patient.slice';
import { createReadingsFromVitalsPanel, updateReadingsFromVitalsPanel } from 'state/slices/patient/vitals/vitals.actions';
import {
    selectHasVitalsData,
    selectHasVitalsToUpdate,
    selectVitalsPanelOpen,
    selectVitalsSaving,
} from 'state/slices/patient/vitals/vitals.selectors';
import { RootState } from 'state/store';
import VitalsEditProperties from './VitalsEditProperties';

export default function VitalsPanel(): JSX.Element {
    const dispatch = useDispatch();

    const isOpen = useSelector(selectVitalsPanelOpen);
    const hasVitalsToUpdate = useSelector(selectHasVitalsToUpdate);
    const hasVitalsData = useSelector(selectHasVitalsData);
    const saving = useSelector(selectVitalsSaving);
    const newVitals = useSelector((state: RootState) => state.patient.vitals.vitalPanelData);
    const isSaving = saving === LoadingStatus.Pending;

    const tenantId = useTenantId();
    const patientId = usePatientId();

    const _handleSaveNewForm = () => {
        if (patientId) {
            if (hasVitalsData || hasVitalsToUpdate) {
                dispatch(updateReadingsFromVitalsPanel(tenantId, patientId));
                
            } else {
                dispatch(createReadingsFromVitalsPanel(tenantId, patientId));
            }
        }
    };

    const heightValidation = parseInt(newVitals[VitalNameType.HeightInches]) >= 12 ? true : false;
    const O2Validation =
        (100 >= parseInt(newVitals[VitalNameType.Saturation]) && parseInt(newVitals[VitalNameType.Saturation]) >= 60) ||
        newVitals[VitalNameType.Saturation] === ''
            ? false
            : true;
    const tempValidation =
        (120 >= parseInt(newVitals[VitalNameType.Temperature]) && parseInt(newVitals[VitalNameType.Temperature]) >= 60) ||
        newVitals[VitalNameType.Temperature] === ''
            ? false
            : true;
    const vitalsValidation = () => {
        if (heightValidation) {
            return <MessageBar messageBarType={MessageBarType.blocked}>Height inches must be less than 12</MessageBar>;
        }
        if (O2Validation) {
            return (
                <MessageBar messageBarType={MessageBarType.blocked}>
                    O2 Saturation must be greater than 60 and less than 100
                </MessageBar>
            );
        }
        if (tempValidation) {
            return (
                <MessageBar messageBarType={MessageBarType.blocked}>
                    Temperature must be greater than 60 and less than 120
                </MessageBar>
            );
        }
    };

    const saveButtonText = hasVitalsToUpdate ? 'Update' : 'Save';
    const disabledButton = heightValidation || isSaving || O2Validation || tempValidation;

    const onRenderFooterContent = () => (
        <Stack tokens={{ childrenGap: 10 }}>
            {vitalsValidation()}

            <Stack horizontal tokens={{ childrenGap: 10 }}>
                <PrimaryButton text={saveButtonText} title="Save" disabled={disabledButton} onClick={_handleSaveNewForm} />
                <DefaultButton text="Cancel" title="Cancel" onClick={_onDismiss} />
                {isSaving && <Spinner label="Saving..." labelPosition="right" />}
            </Stack>
        </Stack>
    );

    const _onDismiss = () => dispatch(setVitalPanelOpen(false));

    return (
        <Panel
            headerText="Vitals"
            isOpen={isOpen}
            styles={{
                content: { overflowY: 'auto', overflowX: 'hidden', flex: 1, position: 'relative' },
                root: { overflow: 'hidden' },
                scrollableContent: { overflow: 'hidden', display: 'flex', flexDirection: 'column' },
            }}
            type={PanelType.medium}
            customWidth={'800px'}
            isFooterAtBottom={true}
            onDismiss={_onDismiss}
            onRenderFooterContent={onRenderFooterContent}
        >
            <VitalsEditProperties />
        </Panel>
    );
}
