import React from 'react';
import { Field, Section } from 'components';
import { Dropdown, Stack, TextField } from '@fluentui/react';

enum ElementType {
    ChoiceGroup = 'ChoiceGroup',
    DropDown = 'DropDown',
}

type ElementTypes = typeof ElementType[keyof typeof ElementType];

function Questionnaire(): JSX.Element {
    const selectedOverviewItem = {
        admitted: 'Yes',
        admittedNotes: 'test note one',

        emergencyVisit: 'option one',
        emergencyVisitNotes: 'test note two',
    };

    return (
        <Section>
            <Stack tokens={{ childrenGap: 10 }}>
                <PastMedicalHistoryQuestion
                    label="Question One?"
                    element={ElementType.ChoiceGroup}
                    value={selectedOverviewItem.admitted}
                    note={selectedOverviewItem.admittedNotes}
                />
                <PastMedicalHistoryQuestion
                    label="Question Two?"
                    element={ElementType.DropDown}
                    value={selectedOverviewItem.emergencyVisit}
                    note={selectedOverviewItem.emergencyVisitNotes}
                />
            </Stack>
        </Section>
    );
}

function PastMedicalHistoryQuestion(props: { label: string; element: ElementTypes; value: string; note: string }) {
    const { label, element, value, note } = props;

    // const isCompleted = currentlySelectedForm.completedDate ? true : false;

    const editProperties: { [key: string]: () => JSX.Element } = {
        ChoiceGroup: () => ChoiceGroupComponent(label, value),
        DropDown: () => DropDownComponent(label, value),
    };

    const componentToRender = (): JSX.Element | null => {
        const Comp = editProperties[element];
        if (Comp) return <Comp />;
        else return null;
    };

    return (
        <Stack grow horizontal tokens={{ childrenGap: 10 }}>
            <Stack.Item styles={{ root: { maxWidth: 200, minWidth: 200 } }}>{componentToRender()}</Stack.Item>
            <Stack.Item grow>
                <TextField label="Notes" value={note} />
            </Stack.Item>
        </Stack>
    );
}

function ChoiceGroupComponent(label: string, value: string) {
    return (
        <Field.ChoiceGroup
            label={label}
            options={[
                { key: 'Yes', text: 'Yes' },
                { key: 'No', text: 'No' },
            ]}
            defaultSelectedKey={'No'}
            selectedKey={value}
            inline
        />
    );
}

function DropDownComponent(label: string, value: string) {
    return (
        <Dropdown
            label={label}
            options={[
                { key: 'option one', text: 'option one' },
                { key: 'option two', text: 'option two' },
            ]}
            selectedKey={value}
        />
    );
}

export default Questionnaire;
