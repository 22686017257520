import {
    DefaultButton,
    DetailsList,
    IComboBoxOption,
    IDragDropContext,
    IDragDropEvents,
    mergeStyles,
    Panel,
    PanelType,
    PrimaryButton,
    Separator,
    Stack,
    TextField,
    useTheme,
    Selection,
} from '@fluentui/react';
import { ICondition } from 'api/models/lookup.model';
import { IQuickAction } from 'api/models/quick-action.model';
import { IProcedure } from 'api/models/procedure.model';
import { Field, Section, TText } from 'components';
import { useQuickActions, useSelector, useTenant } from 'hooks';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { EditDetailsColumn } from 'components/EditDetailsColumn';
import { proceduresOptions, selectProceduresData } from 'state/slices/tenant/procedures.slice';
import { selectConditionOptions, selectConditionsData } from 'state/slices/tenant/conditions.slice';

type Props = {
    quickAction: IQuickAction | null;
    setQuickAction: (quickAction: IQuickAction | null) => void;
    isOpen: boolean;
    toggleIsOpen: () => void;
};

function EditQuickActionPanel({ quickAction, setQuickAction, isOpen, toggleIsOpen }: Props): JSX.Element {
    const { createQuickAction, updateQuickAction, selectedCategory, selectedQuickAction, quickActionItems } = useQuickActions();

    const [actionType, setActionType] = useState<string>('Procedure');
    const [procedureList, setProcedureList] = useState<IProcedure[]>([]);
    const [procedureListChanged, setProcedureListChanged] = useState<boolean>(false);

    const _procedureOptions = useSelector(proceduresOptions).filter((arr) =>
        procedureList.every((filter) => filter.id !== arr.key),
    );

    const _conditionOptions = useSelector(selectConditionOptions);

    const conditions = useSelector(selectConditionsData);
    const procedures = useSelector(selectProceduresData);

    const theme = useTheme();
    const dragEnterClass = mergeStyles({
        backgroundColor: theme.palette.neutralLight,
    });

    let _draggedItem: IProcedure | undefined = undefined;
    let _draggedIndex = 0;

    const _selection: Selection = new Selection();

    const _saveUpdateQuickAction = (e: any) => {
        if (quickAction && quickActionItems) {
            const newQuickAction = { ...quickActionItems.actions };
            const index = quickActionItems?.actions.findIndex((res) => res.id === quickAction.id);
            newQuickAction[index] = quickAction;
            const arrayOfQuickAction = Object.keys(newQuickAction).map((res) => newQuickAction[res as any]);
            const newObject = {
                ...quickActionItems,
                actions: arrayOfQuickAction,
                displayName: quickAction.displayName ?? 'QuickAction',
                createdOn: new Date().toISOString(),
            };
            quickActionItems?._etag ? updateQuickAction(newObject) : createQuickAction(newObject);
            toggleIsOpen();
        }
    };

    const _closePanel = () => {
        setQuickAction(null);
        toggleIsOpen();
    };

    const _updateQuickAction = (e: any, path: keyof IQuickAction) => {
        if (e && quickAction) {
            const { value } = e.target;
            setQuickAction({ ...quickAction, [path]: value });
        }
    };

    const _handleOnActionTypeChange = (e: any, item?: IComboBoxOption) => {
        if (item) setActionType(item?.key as string);
    };

    const _handleAddProcedure = (e: any, i?: IComboBoxOption) => {
        if (quickAction && i) {
            const procedures =
                quickAction.procedures && quickAction.procedures.length
                    ? [...quickAction.procedures, { id: i.key as string }]
                    : [{ id: i.key as string }];
            setQuickAction({
                ...quickAction,
                procedures,
            });
        }
    };

    const _handleAddconditions = (e: any, i?: IComboBoxOption) => {
        if (quickAction && i) {
            const conditions =
                quickAction.conditions && quickAction.conditions.length
                    ? [...quickAction.conditions, { id: i.key as string }]
                    : [{ id: i.key as string }];
            setQuickAction({
                ...quickAction,
                conditions,
            });
        }
    };

    useEffect(() => {
        if (quickAction && quickAction.procedures) {
            const items: IProcedure[] = [];
            quickAction.procedures.forEach((procedure) => {
                if (procedures[procedure.id]) {
                    items.push(procedures[procedure.id] as IProcedure);
                }
            });
            setProcedureList(items);
            setProcedureListChanged(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quickAction]);

    const _conditionDetailListItems = (): ICondition[] => {
        if (quickAction && quickAction.procedures) {
            const items: ICondition[] = [];
            quickAction.conditions.forEach((condition) => {
                if (conditions && conditions[condition.id]) {
                    items.push(conditions[condition.id] as ICondition);
                }
            });
            return items;
        }
        return [];
    };

    const _localStateChanged = isEqual(quickAction, selectedQuickAction);

    const _canSaveAction = () => {
        const hasDisplayName = quickAction?.displayName ? true : false;
        const hasProcedures = quickAction?.procedures && quickAction.procedures.length ? true : false;
        const hasConditions = quickAction?.conditions && quickAction.conditions.length ? true : false;
        return hasDisplayName && (hasProcedures || hasConditions);
    };

    const _insertBeforeItem = (item: IProcedure) => {
        const draggedItems = [_draggedItem!];

        const insertIndex = procedureList.indexOf(item);

        const items = procedureList.filter((itm) => draggedItems.indexOf(itm) === -1);

        items.splice(insertIndex, 0, ...draggedItems);

        setProcedureList(items);
    };

    const _getDragDropEvents = () => {
        return {
            canDrop: (dropContext?: IDragDropContext, dragContext?: IDragDropContext) => {
                return true;
            },
            canDrag: (item?: any) => {
                return true;
            },
            onDragEnter: (item?: any, event?: DragEvent) => {
                // return string is the css classes that will be added to the entering element.
                return dragEnterClass;
            },
            onDragLeave: (item?: any, event?: DragEvent) => {
                return;
            },
            onDrop: (item?: any, event?: DragEvent) => {
                if (_draggedItem) {
                    _insertBeforeItem(item);
                    setProcedureListChanged(true);
                }
            },
            onDragStart: (item?: any, itemIndex?: number, selectedItems?: any[], event?: MouseEvent) => {
                _draggedItem = item;
                _draggedIndex = itemIndex!;
            },
            onDragEnd: (item?: any, event?: DragEvent) => {
                _draggedItem = undefined;
                _draggedIndex = 0;
                if (quickAction) {
                    const newProcedures: any = [];
                    procedureList.forEach((res) => newProcedures.push({ id: res.id as string }));
                    setQuickAction({ ...quickAction, procedures: newProcedures });
                }
            },
        };
    };
    const dragDropEvents: IDragDropEvents = _getDragDropEvents();

    const _saveDisabled = (_localStateChanged && _canSaveAction() && !procedureListChanged) || !procedureList.length;

    const _deleteQuickAction = (item: IQuickAction) => {
        const alteredList = procedureList.filter((res) => res.id != item.id);
        if (quickAction) {
            setProcedureList(alteredList);
            const newProcedures: any = [];
            alteredList.forEach((res) => newProcedures.push({ id: res.id as string }));
            setQuickAction({ ...quickAction, procedures: newProcedures });
        }
    };
    return (
        <Panel
            isOpen={isOpen}
            headerText="Add Quick Action"
            type={PanelType.medium}
            isBlocking={false}
            onRenderFooterContent={() => {
                return (
                    <Stack tokens={{ childrenGap: 10 }} horizontal>
                        <PrimaryButton text="Save" onClick={_saveUpdateQuickAction} disabled={_saveDisabled} />
                        <DefaultButton text="Cancel" onClick={_closePanel} />
                    </Stack>
                );
            }}
            onDismiss={toggleIsOpen}
            isFooterAtBottom
        >
            <TextField
                value={quickAction ? quickAction.displayName : ''}
                onChange={(e) => _updateQuickAction(e, 'displayName')}
                label="Name"
                required
                autoFocus
            />
            <Field.Dropdown
                label="Type"
                options={[
                    {
                        key: 'Procedure',
                        text: 'Procedure',
                    },
                ]}
                selectedKey={actionType}
                onChange={_handleOnActionTypeChange}
            />
            <TextField value={selectedCategory?.displayName} label="Category" disabled readOnly />
            <Separator />
            <TText variant="large">Add Procedures</TText>

            {actionType === 'Condition' && (
                // will leave this code in for now since we will add conditions later on
                <Field.SearchCombo label="Search Conditions" options={_conditionOptions} onChange={_handleAddconditions} />
            )}
            {actionType === 'Procedure' && (
                <Field.SearchCombo
                    virutalized
                    label="Search Procedures"
                    placeholder="Search and add procedure"
                    options={_procedureOptions}
                    onChange={_handleAddProcedure}
                    selectedKey={null}
                    altColor={true}
                />
            )}
            <Separator />
            {quickAction && quickAction.procedures && quickAction.procedures.length > 0 && (
                <Section heading="Procedures">
                    <DetailsList
                        items={procedureList}
                        columns={[
                            {
                                key: 'name',
                                name: 'Name',
                                minWidth: 100,
                                fieldName: 'displayName',
                            },
                            {
                                key: 'code',
                                name: 'Code',
                                minWidth: 100,
                                fieldName: 'code',
                            },
                            {
                                key: '',
                                name: '',
                                minWidth: 10,
                                fieldName: '',
                                onRender: (item: IQuickAction) => (
                                    <EditDetailsColumn
                                        menuProps={{
                                            items: [
                                                {
                                                    key: 'delete',
                                                    iconProps: { iconName: 'Delete' },
                                                    text: 'Delete',
                                                    onClick: (e) => _deleteQuickAction(item),
                                                },
                                            ],
                                        }}
                                        title={''}
                                    />
                                ),
                            },
                        ]}
                        dragDropEvents={dragDropEvents}
                        selection={_selection}
                        selectionPreservedOnEmptyClick={true}
                    ></DetailsList>
                </Section>
            )}
            {quickAction && quickAction.conditions && quickAction.conditions.length > 0 && (
                <Section heading="Conditions">
                    <DetailsList
                        items={_conditionDetailListItems()}
                        columns={[
                            {
                                key: 'name',
                                name: 'Name',
                                minWidth: 100,
                                fieldName: 'displayName',
                            },
                            {
                                key: 'code',
                                name: 'Code',
                                minWidth: 100,
                                fieldName: 'code',
                            },
                        ]}
                    ></DetailsList>
                </Section>
            )}
        </Panel>
    );
}

export default EditQuickActionPanel;
