import { PrimaryButton } from '@fluentui/react';
import useQuickAction from 'hooks/store/useQuickActions';
import { v4 as uuid } from 'uuid';
import { useState } from 'react';
import SettingsSearchBox from '../../../pages/components/SettingsSearchBox';

function QuickActionCommandBar({ handler }: any) {
    const { createQuickActionCategory, searchCategories } = useQuickAction();
    const [search, setSearch] = useState<string>('');

    const testFunction = (value: string) => {
        handler(value);
        setSearch(value);
    };

    return (
        <>
            <SettingsSearchBox placeholder="Search categories" onSearchChange={(e) => testFunction(e)} searchTerm={search} />
            <PrimaryButton
                text="New Category"
                iconProps={{ iconName: 'Add' }}
                title="Create new quick action category"
                onClick={() => {
                    createQuickActionCategory({
                        id: uuid(),
                        isDeleted: false,
                        displayName: 'New Category',
                        description: '',
                    });
                }}
            ></PrimaryButton>
        </>
    );
}

export default QuickActionCommandBar;
