import { format } from 'date-fns';
import { IPatientProblemFormData, IPatientProblemsQuestion } from 'forms/Problems/types';

import { RootState } from 'state/store';

const Problems = ({ state }: { state: RootState }): JSX.Element | null => {
    const { patientEncounter, patientEncounterUserIdentities } = state.encounter;

    const problems = state.chartNotes.quickNotes.assets.pamv.problems;
    const form = state.chartNotes.quickNotes.assets.forms.patientProblem;
    const formData = form?.data as IPatientProblemFormData;

    const formProblemQuestionAsList = formData?.questions;

    if (!patientEncounter || (!patientEncounter.problemsReviewedDate && !problems && !form)) return null;

    const problemsReviewedBy = patientEncounter.problemsReviewedBy
        ? patientEncounterUserIdentities[patientEncounter.problemsReviewedBy]
        : undefined;
    return (
        <>
            <h3>Problems</h3>
            <ul>
                {problemsReviewedBy && (
                    <li>
                        Problems reviewed by: {problemsReviewedBy.firstName} {problemsReviewedBy.lastName}
                    </li>
                )}
                {patientEncounter.problemsReviewedDate && (
                    <li>
                        Problems reviewed on:{' '}
                        {format(new Date(patientEncounter.problemsReviewedDate ?? ''), 'MM/dd/yyyy hh:mm aaa')}
                    </li>
                )}

                {problems?.problems ? problems.problems.map((problem) => <li key={problem.problemId}>{problem.name}</li>) : null}

                {formProblemQuestionAsList
                    ? Object.entries(formProblemQuestionAsList).map(([key, value]) => {
                          const question = value as IPatientProblemsQuestion;
                          if (question.value === 'Yes') {
                              return <li key={key}>{question.displayName}</li>;
                          }
                          return null;
                      })
                    : null}

                {formData ? formData.note && <li>{formData.note}</li> : null}
            </ul>
        </>
    );
};

export default Problems;
