import { FormName } from 'api/models/form';
import { filter, map, orderBy } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'state/store';
import { FormState } from 'state/slices/forms/forms.slice';
import { IPatientProblemFormData, IPatientProblemsQuestion, YesNoValue } from 'forms/Problems/types';

export const selectForms = (state: RootState): FormState => state.forms;
export const formsAsList = createSelector(selectForms, (formsState) => formsState.data.filter((form) => !form.isDeleted));
export const currentlySelectedForm = createSelector(selectForms, (formsState) => formsState.selectedForm);
export const formSyncing = createSelector(selectForms, (formsState) => formsState.syncing);
//selects the specific problems form and arranges the data into an array for display on the overview page.

//We need to filter out the patient problems form from the forms list.
export const patientFormsAsList = createSelector(formsAsList, (forms) =>
    forms.filter((form) => form.formName !== FormName.PatientProblem),
);

export const patientProblemsForm = createSelector(formsAsList, (forms) => {
    const problemsForm = forms.find((form) => form.formName === FormName.PatientProblem);
    return problemsForm;
});

export const patientProblemsFormValuesAsList = createSelector(patientProblemsForm, (form) => {
    if (!form) return [];
    return map((form.data as IPatientProblemFormData).questions, (problem, snomedCode) => ({ ...problem, snomedCode })).filter(
        (problem) => problem?.value !== undefined && problem?.value !== YesNoValue.No && problem?.value !== YesNoValue.Empty,
    ) as IPatientProblemsQuestion[];
});

export const previousOfSelectedForm = createSelector(currentlySelectedForm, formsAsList, (selectedForm, formsAsList) => {
    const sameCompletedForms = orderBy(
        formsAsList
            .filter((item) => item.formName === selectedForm?.formName)
            .filter((item) => (selectedForm ? (item.completedDate ? true : false) : false)),
        (item) => item.completedDate,
        ['desc'],
    )
        .filter((item) => {
            return item?.completedDate && selectedForm?.completedDate
                ? new Date(item.completedDate) < new Date(selectedForm.completedDate)
                : true;
        })
        .filter((item) => item.id !== selectedForm?.id);
    return selectedForm && sameCompletedForms.length ? sameCompletedForms[0] : undefined;
});
