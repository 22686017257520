import Rule from '../rule';
import RulesGenerator, { IRule } from '../ruleGenerator';
import ProcedureCodes from '../procedureCodes';
import ProcedureDiagnosisPipeline, { IProcedureWithDiagnoses } from './procedureDiagnosisPipeline.pipeline';

type Args = {
    procedurePipeline: ProcedureDiagnosisPipeline;
    procedure: IProcedureWithDiagnoses;
    rules: { [key: string]: IRule<ProcedureDiagnosisPipeline, IProcedureWithDiagnoses> };
};

/**
 * Generates and runs rule logic for a specific IProcedureWithDiagnoses
 *
 * @export
 * @class DiagnosisBusinessRuleGenerator
 * @extends {RulesGenerator<ProcedureDiagnosisPipeline, IProcedureWithDiagnoses>}
 */
export default class DiagnosisBusinessRuleGenerator extends RulesGenerator<ProcedureDiagnosisPipeline, IProcedureWithDiagnoses> {
    constructor({ procedure, procedurePipeline, rules }: Args) {
        super({ pipeline: procedurePipeline, item: procedure, rules, ruleTypes: ProcedureCodes });
    }

    /**
     * Gets and runs rules based on the passed rule dictionary
     *
     * @protected
     * @param {ProcedureDiagnosisPipeline} pipeline
     * @memberof DiagnosisBusinessRuleGenerator
     */
    protected _runRules(pipeline: ProcedureDiagnosisPipeline): void {
        const code = pipeline.getNewProcedure(this._item as IProcedureWithDiagnoses)?.code;

        if (code) {
            const rule = this._rules[code];
            if (rule !== undefined) {
                const { getItem, getShouldRemoveItem, getErrors } = new Rule<ProcedureDiagnosisPipeline, IProcedureWithDiagnoses>(
                    {
                        pipeline,
                        item: this._item as IProcedureWithDiagnoses,
                        rule,
                    },
                );
                if (getErrors) this.addErrors(getErrors);
                this.setShouldRemoveItem = getShouldRemoveItem;
                this._item = getItem;
            }
        }
    }
}
