import { DetailsRow, FontIcon, IDetailsRowProps, Stack, useTheme, mergeStyles, SelectionMode } from '@fluentui/react';
import SortableDetailsList, { ISortableDetailsListProps } from 'components/SortableDetailsList/SortableDetailsList';
import { useEffect, useState } from 'react';

export interface IExpandableDetailsListProps<T> extends ISortableDetailsListProps<T> {
    canExpandAll?: boolean;
    expandedRowIndexes?: number[];
    allowMultipleRowsExpanded?: boolean;
    onRenderExpandedRowContent?: (props: IDetailsRowProps | undefined) => JSX.Element | null;
    firstRowExpanded?: boolean;
    canExpandRow?: (item: T, index?: number) => boolean;
}

export default function ExpandableDetailsList<T>(props: IExpandableDetailsListProps<T>): JSX.Element {
    const {
        canExpandAll,
        expandedRowIndexes,
        onRenderExpandedRowContent,
        allowMultipleRowsExpanded,
        items,
        canExpandRow,
        onRenderRow,
        selectionMode,
    } = props;
    const theme = useTheme();

    const [allExpanded, setAllExpanded] = useState<boolean>(false);

    const [expandedRows, setExpandedRows] = useState<number[]>([
        ...(props.firstRowExpanded ? [0] : []), //If the first row is expanded init [0]
        ...(expandedRowIndexes ?? []), //Initial expanded rows
    ]);

    const _canExpandRow = (item: T, index?: number) => {
        if (canExpandRow) {
            return canExpandRow(item, index);
        }
        return true;
    };

    //Ensure that if our props change we update our state of expanded rows.
    useEffect(() => {
        if (expandedRowIndexes?.length) setExpandedRows(expandedRowIndexes);
    }, [expandedRowIndexes]);

    const iconColumnClass = mergeStyles({
        fontSize: 13,
        fontWeight: 'bold !important',
        paddingLeft: 5,
        userSelect: 'none',
        color: theme.palette.black,
    });

    const iconRowClass = mergeStyles({
        fontSize: 13,
        marginLeft: -4,
        fontWeight: 'bold !important',
        userSelect: 'none',
        color: theme.palette.black,
    });

    //Gets if a row index is expanded.
    const rowIndexExpanded = (index: number | undefined) => (index !== undefined ? expandedRows?.includes(index) : false);

    //Handles expanding a row based on allowMultipleRowsExpanded.
    function _setExpandedRow(rowIndex: number | undefined) {
        if (rowIndex !== undefined) {
            const newExpandedRows = expandedRows ?? [];
            if (allExpanded) setAllExpanded(false); //If we're changing an individual row, set all expanded false.
            if (allowMultipleRowsExpanded) {
                if (newExpandedRows.includes(rowIndex)) {
                    setExpandedRows(newExpandedRows.filter((row) => row !== rowIndex));
                } else {
                    setExpandedRows(
                        allExpanded
                            ? //If all was expanded and we are adding trying to add a new one, add all indexes back into expandedRows, and remove the clicked rowIndex.
                              items.map((item, index) => index).filter((row) => row !== rowIndex)
                            : [...newExpandedRows, rowIndex],
                    );
                }
            } else {
                if (newExpandedRows.includes(rowIndex)) {
                    setExpandedRows([]);
                } else {
                    setExpandedRows([rowIndex]);
                }
            }
        }
    }

    function _toggleAllExpanded() {
        const newAllExpanded = !allExpanded;
        if (newAllExpanded) {
            if (expandedRows.length) setExpandedRows([]);
            setAllExpanded(true);
        } else {
            setAllExpanded(false);
        }
    }

    useEffect(() => {
        if (expandedRows.length === items.length && canExpandAll && !allExpanded) {
            _toggleAllExpanded();
        }
    }, [expandedRows, items]);

    useEffect(() => {
        if (expandedRows.length) setExpandedRows([]);
        if (allExpanded) setAllExpanded(false);
    }, [items.length]);

    const _onRenderRow = (props: IDetailsRowProps | undefined): JSX.Element | null => {
        if (props) {
            return (
                <div data-selection-toggle={selectionMode !== SelectionMode.none}>
                    {onRenderRow ? onRenderRow(props) : <DetailsRow {...props} />}
                    <div onClick={(ev) => ev.stopPropagation()}>
                        {onRenderExpandedRowContent
                            ? (rowIndexExpanded(props.itemIndex) || allExpanded) && _canExpandRow(props.item)
                                ? onRenderExpandedRowContent(props)
                                : null
                            : null}
                    </div>
                </div>
            );
        }
        return null;
    };

    return (
        <SortableDetailsList
            {...props}
            onRenderRow={_onRenderRow}
            onColumnHeaderClick={(ev, column) => {
                if (column?.key === 'all') {
                    _toggleAllExpanded();
                }
            }}
            columns={[
                {
                    key: 'all',
                    name: 'All',
                    fieldName: '',
                    minWidth: 30,
                    maxWidth: 30,
                    onRenderHeader: () => {
                        if (!canExpandAll) return null;
                        return (
                            <div style={{ textAlign: 'center', cursor: 'pointer' }}>
                                <FontIcon
                                    iconName={`${!allExpanded ? 'ChevronDown' : 'ChevronUp'}`}
                                    className={iconColumnClass}
                                />
                            </div>
                        );
                    },
                    onRender: (item, index) => {
                        if (item) {
                            if (!_canExpandRow(item, index)) return null;
                            return (
                                <div
                                    onClick={(ev) => {
                                        ev.preventDefault();
                                        ev.stopPropagation();
                                        _setExpandedRow(index);
                                    }}
                                    style={{
                                        textAlign: 'center',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        cursor: 'pointer',
                                        height: '100%',
                                    }}
                                >
                                    <FontIcon
                                        iconName={`${!rowIndexExpanded(index) && !allExpanded ? 'ChevronDown' : 'ChevronUp'}`}
                                        className={iconRowClass}
                                    />
                                </div>
                            );
                        }
                    },
                },
                ...(props.columns ?? []),
            ]}
        />
    );
}
