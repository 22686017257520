import { createSlice } from '@reduxjs/toolkit';
import { adjustmentHistoryExtraReducers, adjustmentHistoryReducers } from './adjustment-history/adjustment-history.reducers';
import claimsHistoryReducers, { claimHistoryExtraReducers } from './claim-history/claim-history.reducers';
import { extraReducers, reducers } from './ledger.reducer';
import { initialState } from './ledger.state';
import {
    patientPaymentsAndAdjustmentsExtraReducers,
    patientPaymentsAndAdjustmentsReducers,
} from './patient-payments-and-adjustments/patient-payments-and-adjustments.reducer';
import { paymentHistoryExtraReducers, paymentHistoryReducers } from './payment-history/payment-history.reducers';
import {
    treatmentPlanCreditAndUACExtraReducers,
    treatmentPlanCreditAndUACReducers,
} from './treatment-plan-credit-and-uac/treatment-plan-credit-and-uac.reducers';
import { uacDistributionExtraReducers, uacDistributionReducers } from './uac-distribution/uac-distribution.reducer';

const ledgerState = createSlice({
    name: 'ledger',
    initialState,
    reducers: {
        ...reducers,
        ...paymentHistoryReducers,
        ...patientPaymentsAndAdjustmentsReducers,
        ...treatmentPlanCreditAndUACReducers,
        ...adjustmentHistoryReducers,
        ...claimsHistoryReducers,
        ...uacDistributionReducers,
    },
    extraReducers: (builder) => {
        extraReducers(builder);
        paymentHistoryExtraReducers(builder);
        patientPaymentsAndAdjustmentsExtraReducers(builder);
        treatmentPlanCreditAndUACExtraReducers(builder);
        adjustmentHistoryExtraReducers(builder);
        claimHistoryExtraReducers(builder);
        uacDistributionExtraReducers(builder);
    },
});

const { reducer, actions } = ledgerState;

export default reducer;

export const {
    // cleanupEncounterLedgerState,
    // cleanupPatientLedgerState,
    cleanupSummaryLedgerState,
    cleanupCurrentTransactionsAndSource,
    cleanupTotalsModal,
    updateTransactionAmount,
    updateAdjustmentReasons,
    setCurrentPaymentSource,
    setCurrentPaymentTransactions,
    updateTransactionsBatchIdAndDateOfEntry,
    updateCurrentPaymentSourceProps,
    setCurrentBillingProcedures,
    setEncounterLedgerSummaryFilter,
    setFilteredEncounterSummaries,

    // Modals
    setPostPaymentModalContext,
    setPostPaymentModalOpen,

    setAdjustmentModalOpen,
    setTotalAdjustmentAmount,
    adjustAllTransactions,

    setPrepaymentModalOpen,

    // Payment Distribution Button Actions
    distributePaymentEvenly,
    clearTransactions,
    payAllTransactions,

    // Payment History Actions
    setPaymentHistoryDateOfEntry,
    setPaymentHistoryMethod,
    setPaymentHistoryMethodIdentifier,
    setPaymentHistoryTransactionDate,
    setPaymentHistoryDateOfService,
    setSelectPaymentHistoryRows,
    setNegativePaymentReversalId,
    setNegativeReversalNote,
    cleanupConfirmNegativeReversal,
    togglePaymentHistoryShowReversals,
    cleanupPaymentHistoryReverseSavedStatusMessageBar,
    setSelectedAdjustmentHistoryViews,

    setPaymentHistoryConfirmModalOpen,
    setPaymentHistoryModalOpen,

    cleanupPaymentHistory,
    clearPaymentHistoryFilters,

    //Adjustment History Actions
    cleanupAdjustmentHistory,
    cleanupAdjustmentHistoryReverseSavedStatusMessageBar,
    clearAdjustmentHistoryFilters,
    setAdjustmentHistoryAdjustmentReason,
    setAdjustmentHistoryAmount,
    setAdjustmentHistoryConfirmModalOpen,
    setAdjustmentHistoryTransactionDate,
    setAdjustmentHistoryDateOfEntry,
    setAdjustmentHistoryEncounterDate,
    setAdjustmentHistoryEncounterNumber,
    toggleShowReversedAdjustments,
    cleanupAdjustmentHistoryPage,

    // Claims History Actions
    toggleShowClaimHistoryFilters,
    toggleClaimEncounterInsuranceBalanceGreaterThanZeroFilter,
    setClaimEncounterDateRangeFilters,
    clearAllClaimsHistoryFilters,
    setClaimEncounterBillingProvidersFilter,
    setSelectedClaimHistoryEncounterSummary,
    cleanupSelectedClaimHistoryEncounterSummary,
    setClaimHistoryDateFilters,
    updateSelectedEncounterSummaryStatus,
    setClaimHistoryEncounterNoteModalOpen,

    // Patient Payment and Adjustment Actions
    cleanupAdjustmentInformation,
    cleanupPaymentInformation,
    cleanupPatientBillingProcedures,
    cleanupAllowOverpaymentOrAdjustment,
    clearEstimatePayments,
    clearAdjustments,
    clearPayments,
    createTransactionAmount,
    setPatientAdjustmentTransactions,
    setPatientPaymentSource,
    setPatientPaymentTransactions,
    setPatientPaymentsAndAdjustmentsValidationErrors,
    setPatientEstimateTransactions,
    updatePatientEstimateTransaction,
    updatePatientAdjustmentReasons,
    updatePatientPaymentSource,
    updatePatientAdjustmentTransaction,
    updatePatientPaymentTransaction,
    updatePatientTotalAdjustmentAmount,
    toggleAllowOveradjustmentOrPayment,
    togglePatientEstimateOverpayment,
    updateAllPatientTransactionsBatchId,
    payAllPatientPayments,
    adjustAllPatientAdjustments,
    payAllPatientEstimates,
    cleanupPatientPaymentsSavedStatusMessageBar,
    cleanupPatientAdjustmentsSavedStatusMessageBar,
    cleanupPatientPaymentsAndAdjustmentsData,

    // Patient Treatment Plan Credit and UAC Actions
    cleanupTreatmentPlanCreditTransactions,
    setTreatmentPlanCreditPaymentSource,
    setTreatmentPlanCreditTransactions,
    cleanupTreatmentPlanCreditMessageBar,
    setTreatmentPlanCreditSelectedPhaseView,
    cleanupSelectedTreatmentPlanCreditView,
    cleanupTreatmentPlanCreditData,
    updateTreatmentPlanCreditPaymentSource,
    cleanupTreatmentPlanCreditPaymentSource,
    updateAllTreamtentPlanCreditTransactionsBatchId,
    clearAllTreatmentPlanCreditTransactions,
    payAllTreatmentPlanCreditTransactions,
    updateTreatmentPlanCreditTransactionAmount,
    toggleTreatmentPlanCreditOverpayment,
    cleanupTreatmentPlanCreditModified,
    setTreatmentPlanCreditCurrentPhaseView,
    cleanupSignedTreatmentPlan,

    //UAC Distribution
    cleanupUACDistribution,
    cleanupConvertUACModal,
    setConvertUACModalOpen,
    setPaymentSourceIdToConvertToUAC,
    setUACDistributionSectionOpen,
} = actions;
