import { IBillingProcedure } from 'api/models/billing-procedure.model';
import { IChartTreatmentPlan } from 'api/models/treatment-plan.model';
import { LoadingStatus, LoadingStatuses } from 'interfaces/loading-statuses';
import { ChartEndpoint } from '../chart/chart.types';
import { IChartAction } from '../chartActionsList.pipeline';
import ErrorTypes from 'state/errorTypes';

export const chartTreatmentPlansInitialState: ChartTreatmentPlanState = {
    data: [],
    loading: LoadingStatus.Idle,
    saving: LoadingStatus.Idle,
    signedOrRefused: false,
    pendingProcedures: [],
    isSigning: false,
    loadingCurrentPlan: LoadingStatus.Idle,
    loadingPendingProcedures: LoadingStatus.Idle,
    printPredetermination: {
        isOpen: false,
    },
};

export interface ChartTreatmentPlanState extends ChartEndpoint<IChartTreatmentPlan> {
    selectedTreatmentPlan?: string;
    signedOrRefused: boolean;
    currentTreatmentPlan?: IChartTreatmentPlan;
    pendingProcedures: IBillingProcedure[];
    isSigning: boolean;
    loadingCurrentPlan: LoadingStatus;
    loadingPendingProcedures: LoadingStatuses;
    printPredetermination: PrintPredeterminationState;
    error?: ErrorTypes;
}

export type PrintPredeterminationState = {
    isOpen: boolean;
    selectedTreatmentPlan?: IChartTreatmentPlan;
    selectedChartActions?: IChartAction[];
    locId?: string;
    providerId?: string;
};
