import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import IAppointmentAllocations from 'api/models/Scheduling/allocation.model';
import IPatientAppointment from 'api/models/Scheduling/patientAppointment.model';
import UpdatedEncounter from 'api/models/updated-encounter.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { convertDateStringToDashed } from 'utils/convertDateStringToLocal';
import { classicDateOnly } from 'utils/dateOnly';
import { getLocationsOfCare } from '../scheduling/scheduling.slice';
import { getWeekOfAppointments } from './clinical-huddle.actions';
import { ClinicalHuddleState } from './clinical-huddle.slice';

export const clinicalHuddleReducers = {
    setDate: (state: ClinicalHuddleState, action: PayloadAction<string>): void => {
        const date = action.payload;
        state.weekOfAppointments.date = date;
    },
    setProviderId: (state: ClinicalHuddleState, action: PayloadAction<string | undefined>): void => {
        const provider = action.payload;
        state.weekOfAppointments.providerId = provider;
    },
    setProviderArray: (state: ClinicalHuddleState, action: PayloadAction<string[] | undefined>): void => {
        const provider = action.payload;
        state.weekOfAppointments.providerIdArray = provider;
    },
    setLocationArray: (state: ClinicalHuddleState, action: PayloadAction<string[] | undefined>): void => {
        const location = action.payload;
        state.weekOfAppointments.locationOfCareArray = location;
    },
    toggleExpandedLocationOfCare: (state: ClinicalHuddleState, action: PayloadAction<string | undefined>): void => {
        const locId = action.payload;
        if (locId) {
            const arrayToFiltered = state.weekOfAppointments.expandedLocationOfCareIds.includes(locId)
                ? state.weekOfAppointments.expandedLocationOfCareIds.filter((loc) => loc !== locId)
                : [...state.weekOfAppointments.expandedLocationOfCareIds, locId];
            state.weekOfAppointments.expandedLocationOfCareIds = arrayToFiltered;
        }
    },
    setExpandedLocationOfCares: (state: ClinicalHuddleState, action: PayloadAction<string[]>): void => {
        state.weekOfAppointments.expandedLocationOfCareIds = [
            ...state.weekOfAppointments.expandedLocationOfCareIds,
            ...action.payload,
        ];
    },
    addOrUpdatePatientAppointmentAllocation: (state: ClinicalHuddleState, action: PayloadAction<IPatientAppointment>): void => {
        if (state.weekOfAppointments.appointments.data) {
            const indexOfAppointment = state.weekOfAppointments.appointments.data.findIndex(
                (app) => app.id === action.payload.id,
            );
            if (indexOfAppointment > -1) {
                state.weekOfAppointments.appointments.data[indexOfAppointment] = action.payload;
            } else if (state.weekOfAppointments.date) {
                if (convertDateStringToDashed(classicDateOnly(state.weekOfAppointments.date)) === action.payload.date)
                    state.weekOfAppointments.appointments.data = [...state.weekOfAppointments.appointments.data, action.payload];
            }
        }
    },
    updatePatientAppointmentAllocationsEncounterStatus: (
        state: ClinicalHuddleState,
        action: PayloadAction<UpdatedEncounter>,
    ): void => {
        const list = state.weekOfAppointments.appointments.data;

        if (list?.length && action.payload.id) {
            const indexOfAppt = list?.findIndex((item) => item.id === action.payload.appointmentId);
            if (indexOfAppt > -1) list[indexOfAppt].encounterStatus = action.payload.status;

            state.weekOfAppointments.appointments.data = list;
        }
    },
    toggleActiveAppointment: (state: ClinicalHuddleState) => {
        state.weekOfAppointments.showActiveOnly = !state.weekOfAppointments.showActiveOnly;
    },
    setPatientSearch: (state: ClinicalHuddleState, action: PayloadAction<string>) => {
        state.weekOfAppointments.patientSearch = action.payload;
    },
};

export const clinicalHuddleExtraReducers = (
    builder: ActionReducerMapBuilder<ClinicalHuddleState>,
): ActionReducerMapBuilder<ClinicalHuddleState> =>
    builder
        .addCase(getWeekOfAppointments.pending, (state) => {
            state.weekOfAppointments.appointments.loading = LoadingStatus.Pending;
        })
        .addCase(getWeekOfAppointments.fulfilled, (state, action: PayloadAction<IAppointmentAllocations>) => {
            const { patients: appointments } = action.payload;

            state.weekOfAppointments.appointments.loading = LoadingStatus.Completed;
            state.weekOfAppointments.appointments.data = appointments;
        })
        .addCase(getWeekOfAppointments.rejected, (state) => {
            state.weekOfAppointments.appointments.loading = LoadingStatus.Failed;
        })
        .addCase(getLocationsOfCare.fulfilled, (state, action) => {
            state.weekOfAppointments.expandedLocationOfCareIds = action.payload
                .filter((loc) => !loc.isDeleted)
                .map((loc) => loc.id);
        });
