import { Checkbox, DefaultButton, IDropdownOption, Label, PrimaryButton, Stack, Text, TextField } from '@fluentui/react';
import IProvider, { IProviderScheduleTimeBlock } from 'api/models/provider.model';
import { Field, TModal } from 'components';
import { differenceInDays } from 'date-fns';
import { format } from 'date-fns/esm';
import { useValidation } from 'hooks';
import { getValidationError, IValidationConfig, ValidationType } from 'hooks/useValidation';
import { flattenDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLocationOfCareOptions } from 'state/slices/scheduling/scheduling.selectors';
import { upsertExceptionToPeriod } from 'state/slices/settings/providers/provider.actions';
import {
    selectEditPeriodExceptions,
    selectEditPeriods,
    selectProvidersExpectionModelOpen as selectProvidersExceptionsModelOpen,
} from 'state/slices/settings/providers/providers.selectors';
import { openExceptionModal, updateProviderExceptionProp } from 'state/slices/settings/settings.slice';
import { classicDateOnly } from 'utils/dateOnly';
import { timeOptions } from 'utils/getTimeOptions';
import RecurrencePattern from './RecurrencePattern';

type Props = {
    provider: IProvider | undefined;
};

export default function ScheduleExceptionsModel({ provider }: Props): JSX.Element | null {
    const locationsOfCareOptions = useSelector(selectLocationOfCareOptions);
    const [isChecked, setIsChecked] = useState(false);
    const [dateError, setDateError] = useState<string>();

    const editSelectedPeriods = useSelector(selectEditPeriods);

    const editPeriodExceptions = useSelector(selectEditPeriodExceptions);

    const isOpen = useSelector(selectProvidersExceptionsModelOpen);
    const dispatch = useDispatch();

    const blockExceptionTypeOptions: IDropdownOption[] = [
        { key: '', text: 'Select Exception' },
        { key: 'appointment', text: 'Appointment' },
        { key: 'holiday', text: 'Holiday' },
        { key: 'vacation', text: 'Vacation' },
        { key: 'other', text: 'Other' },
    ];
    const addExceptionTypeOptions: IDropdownOption[] = [
        { key: '', text: 'Select Exception' },
        { key: 'add', text: 'Adding LOC Hours' },
        { key: 'other', text: 'Other' },
    ];
    const validationConfig: IValidationConfig = [
        {
            fieldName: 'Exception Type',
            validation: [ValidationType.Required],
            value: editPeriodExceptions?.exceptionType,
        },
        {
            fieldName: 'Start Date',
            validation: [ValidationType.Required],
            value: editPeriodExceptions?.startDate,
        },
        {
            fieldName: 'Start Time',
            validation: [ValidationType.Required],
            value: editPeriodExceptions?.startTime,
        },
        {
            fieldName: 'End Time',
            validation: [ValidationType.Required],
            value: editPeriodExceptions?.endTime,
        },
        {
            fieldName: 'End Date',
            validation: [ValidationType.Required],
            value: editPeriodExceptions?.endDate,
        },
    ];

    if (editPeriodExceptions?.type === 'addHours') {
        validationConfig.push({
            fieldName: 'Location of Care',

            validation: [ValidationType.Required],
            value: editPeriodExceptions?.locationOfCareId,
        });
    }
    const onDismiss = () => {
        dispatch(openExceptionModal(false));
        cleanupErrors();
        setIsChecked(false);
    };
    const onSave = () => {
        dispatch(upsertExceptionToPeriod());
        dispatch(openExceptionModal(false));
        setIsChecked(false);
    };

    const [errors, onSubmit, cleanupErrors] = useValidation(validationConfig, onSave);

    useEffect(() => {
        cleanupErrors();
    }, []);

    const providerTypeLabel = () => {
        if (provider?.isTreatingProvider === true) return 'Treating Providers';
        if (provider?.isHygienist === true) return 'Hygienists';
        if (provider?.isRegisteredDentalAssistant === true) return 'Registered Dental Assistants';
        else return '';
    };

    const editProviderDate = editSelectedPeriods.find((e) => e);

    const editProviderExceptionsLocationName = flattenDeep(
        editSelectedPeriods
            .filter((period) => period)
            .map((period) => period.schedules?.map((schedule) => schedule.locationOfCareName)),
    );

    const is2weeks =
        differenceInDays(new Date(editProviderDate?.endDate ?? ''), new Date(editProviderDate?.startDate ?? '')) > 14;

    function newExceptionPropChange(path: keyof IProviderScheduleTimeBlock, value: unknown) {
        dispatch(updateProviderExceptionProp({ path, value }));
    }

    const hasStartDateError = getValidationError(errors, 'Start Date');
    const hasEndDateError = getValidationError(errors, 'End Date');
    const hasStartTimeError = getValidationError(errors, 'Start Time');
    const hasEndTimeError = getValidationError(errors, 'End Time');
    const hasExceptionTypeError = getValidationError(errors, 'Exception Type');

    const dateValidation =
        editPeriodExceptions?.endDate && editPeriodExceptions?.startDate
            ? editPeriodExceptions.endDate < editPeriodExceptions.startDate
            : false;
    const timeValidation =
        editPeriodExceptions?.endTime &&
        editPeriodExceptions?.startTime &&
        editPeriodExceptions?.endDate === editPeriodExceptions?.startDate
            ? editPeriodExceptions.endTime < editPeriodExceptions.startTime
            : false;

    const isDateValid = dateValidation ? 'End Date must be after Start Date' : '';
    const isTimeValid = timeValidation ? 'End Time must be after Start Time' : '';

    const today = new Date();
    const maxPeriodDate = new Date(editProviderDate?.endDate ?? '');

    return (
        <TModal
            title={
                editPeriodExceptions?.type === 'addHours'
                    ? 'Schedule Exceptions (Add Hours)'
                    : editPeriodExceptions?.type === 'block'
                    ? 'Schedule Exceptions (Block Hours)'
                    : 'Schedule Exceptions(Emergency)'
            }
            modalProps={{ isOpen: isOpen, onDismiss: onDismiss }}
        >
            <Stack tokens={{ padding: 20, childrenGap: 20 }}>
                <Stack horizontal grow tokens={{ childrenGap: 20 }}>
                    <Stack>
                        <Label>{providerTypeLabel()}</Label>
                        <Text>{`${provider?.firstName} ${provider?.lastName}`}</Text>
                    </Stack>
                    <Stack>
                        <Label>Schedule Date Range:</Label>
                        <Text>{`${
                            editProviderDate?.startDate ? format(new Date(editProviderDate?.startDate), 'MM/dd/yyyy') : ''
                        } - ${
                            editProviderDate?.endDate ? format(new Date(editProviderDate?.endDate), 'MM/dd/yyyy') : ''
                        } `}</Text>
                    </Stack>
                    <Stack>
                        <Label>Location(s) of Care:</Label>
                        <Text>{`${editProviderExceptionsLocationName.join(', ')}`}</Text>
                    </Stack>
                </Stack>

                <Stack>
                    <Stack tokens={{ childrenGap: 10 }}>
                        <Field.Dropdown
                            label="Location of Care"
                            selectedKey={editPeriodExceptions?.locationOfCareId}
                            options={locationsOfCareOptions}
                            placeholder="Select a Location of Care"
                            required
                            onChange={(e, option) => {
                                if (option) newExceptionPropChange('locationOfCareId', option.key);
                            }}
                            errorMessage={
                                getValidationError(errors, 'Location of Care') ? 'Location of Care is required.' : undefined
                            }
                        />

                        <Field.Dropdown
                            label="Exception Type"
                            selectedKey={editPeriodExceptions?.exceptionType}
                            options={
                                editPeriodExceptions?.type === 'addHours' ? addExceptionTypeOptions : blockExceptionTypeOptions
                            }
                            placeholder="Select an exception type"
                            required
                            onChange={(e, option) => {
                                if (option) newExceptionPropChange('exceptionType', option.key);
                            }}
                            errorMessage={hasExceptionTypeError ? 'Exception Type Required ' : ''}
                        />
                    </Stack>

                    <Stack tokens={{ childrenGap: 10 }}>
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <Field.Date
                                hasDatePicker
                                label="Start Date"
                                autoComplete="off"
                                isReasonable
                                minReasonableDate={new Date(classicDateOnly(today.toISOString()))}
                                maxReasonableDate={maxPeriodDate ? maxPeriodDate : new Date()}
                                handleError={(error) => {
                                    setDateError(error);
                                }}
                                invalidDateErrorMessage="Invalid Start Date"
                                minReasonableErrorMessage="Start Date must be on or before the start date of the Period"
                                maxReasonableErrorMessage="Start Date cant be on or after the end date of the Period"
                                value={editPeriodExceptions?.startDate}
                                required
                                onChange={(e, date) => {
                                    if (date) newExceptionPropChange('startDate', classicDateOnly(date));
                                }}
                                errorMessage={hasStartDateError ? 'Start Date Required ' : dateError}
                            />
                            <Field.Dropdown
                                options={timeOptions}
                                label="Start Time"
                                styles={{ dropdown: { width: 100 } }}
                                selectedKey={editPeriodExceptions?.startTime}
                                required
                                onChange={(e, option) => {
                                    if (option) newExceptionPropChange('startTime', option.key);
                                }}
                                errorMessage={hasStartTimeError ? 'Start Time Required ' : ''}
                            />
                        </Stack>
                        <Stack horizontal tokens={{ childrenGap: 14 }}>
                            <Stack>
                                <Field.Date
                                    value={editPeriodExceptions?.endDate}
                                    hasDatePicker
                                    label="End Date"
                                    autoComplete="off"
                                    isReasonable
                                    minReasonableDate={new Date(classicDateOnly(today.toISOString()))}
                                    maxReasonableDate={maxPeriodDate ? maxPeriodDate : new Date()}
                                    handleError={(error) => {
                                        setDateError(error);
                                    }}
                                    invalidDateErrorMessage="Invalid End Date"
                                    minReasonableErrorMessage="End Date must be on or before the start date of the Period"
                                    maxReasonableErrorMessage="End Date cant be on or after the end date of the Period"
                                    required
                                    onChange={(e, date) => {
                                        if (date) newExceptionPropChange('endDate', classicDateOnly(date));
                                    }}
                                    errorMessage={hasEndDateError ? 'End Date Required ' : dateError || isDateValid}
                                />
                            </Stack>
                            <Stack>
                                <Field.Dropdown
                                    options={timeOptions}
                                    selectedKey={editPeriodExceptions?.endTime}
                                    label="End Time"
                                    styles={{ dropdown: { width: 100 } }}
                                    required
                                    onChange={(e, option) => {
                                        if (option) newExceptionPropChange('endTime', option.key);
                                    }}
                                    errorMessage={hasEndTimeError ? 'End Time Required ' : isTimeValid}
                                />
                            </Stack>
                            {is2weeks ? (
                                <Stack verticalAlign="end">
                                    <Checkbox
                                        label="Make Recurring"
                                        checked={isChecked}
                                        onChange={(ev, checked) => {
                                            setIsChecked(!!checked);
                                            newExceptionPropChange('recurring', !!checked);
                                        }}
                                    />
                                </Stack>
                            ) : null}
                        </Stack>
                    </Stack>
                </Stack>

                {isChecked ? <RecurrencePattern /> : null}

                <Stack tokens={{ childrenGap: 10 }}>
                    <TextField
                        label="Notes"
                        value={editPeriodExceptions?.notes}
                        multiline
                        rows={3}
                        onChange={(e, value) => {
                            newExceptionPropChange('notes', value);
                        }}
                    />
                </Stack>

                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <PrimaryButton
                        text="Save"
                        title="Save"
                        onClick={() => {
                            onSubmit();
                        }}
                    />
                    <DefaultButton text="Cancel" title="Cancel" onClick={onDismiss} />
                </Stack>
            </Stack>
        </TModal>
    );
}
