import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import IChartNote, { IChartNoteWithData, NoteType } from 'api/models/chart-note.model';
import { initialState, NoteBrowserState, NoteBrowserType } from '../note-browser/note-browser.state';
import { extraReducers } from './note-browser.reducers';

export enum NoteBrowserModal {
    NoteBrowser,
    CreateNote,
    SignAndSave,
    CancelConfirmation,
}

const noteBrowserSlice = createSlice({
    name: 'noteBrowser',
    initialState,
    reducers: {
        setNoteModalOpen: (state: NoteBrowserState, { payload }: PayloadAction<{ modal: NoteBrowserModal; isOpen: boolean }>) => {
            const { modal, isOpen } = payload;
            switch (modal) {
                case NoteBrowserModal.CreateNote: {
                    state.newNoteIsOpen = isOpen;
                    break;
                }
                case NoteBrowserModal.SignAndSave: {
                    state.signAndSaveIsOpen = isOpen;
                    if(!isOpen) {
                        state.saveError = undefined;
                    }
                    break;
                }
                case NoteBrowserModal.CancelConfirmation: {
                    state.cancelConfirmationIsOpen = isOpen;
                    break;
                }
                default: {
                    state.isOpen = isOpen;
                    if (!isOpen) {
                        state.selectedChartNote = undefined;
                        state.newNote = undefined;
                        state.saveError = undefined;
                        state.signAndSaveIsOpen = false;
                        state.newNoteIsOpen = false;
                    }
                    break;
                }
            }
        },
        setNewNoteText: (state: NoteBrowserState, { payload: note }: PayloadAction<string>) => {
            if (note && state.newNote) {
                state.newNote.note = note;
            }
        },
        /**
         * @description Create a new note, empty parameter creates an addendum
         * @example
         * // Create an addendum, default to empty parameters
         * dispatch(createNewNote())
         * // Create a note, add the note type
         * dispatch(createNewNote(NoteType.CareCoordination))
         */
        createNewNote: (state: NoteBrowserState, { payload: noteType }: PayloadAction<NoteType | undefined>) => {
            state.newNoteIsOpen = false;
            if (!noteType) {
                state.newNote = {
                    note: '',
                    noteBrowserType: NoteBrowserType.Addendum,
                };
            } else {
                state.newNote = {
                    note: '',
                    noteType,
                    noteBrowserType: NoteBrowserType.Note,
                };
                state.selectedChartNote = undefined;
            }
        },
        cancelNewNote: (state: NoteBrowserState) => {
            state.newNote = undefined;
        },
        setChartNote: (state: NoteBrowserState, { payload: note }: PayloadAction<IChartNote | IChartNoteWithData<any> | undefined>) => {
            state.selectedChartNote = note;
        },
        setSaveError: (state: NoteBrowserState, { payload: error }: PayloadAction<string | undefined>) => {
            state.saveError = error;
        },
    },
    extraReducers: (builder) => {
        extraReducers(builder);
    }
});

const { actions, reducer } = noteBrowserSlice;

export const noteBrowserActions = actions;

export default reducer;
