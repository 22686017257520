import { forwardRef } from 'react';
import UACDistributionSection from './UACDistributionSection';
import PaymentsAndAdjustmentsList from '../PaymentsAndAdjustments/PaymentsAndAdjustmentsList';
import { useSelector } from 'hooks';
import {
    selectPatientEstimateOverpayment,
    selectPatientEstimateProceduresGroupedByEncounter,
    selectPatientEstimateProceduresGroupedByEncounterWithEstimate,
    selectPatientEstimateTransactions,
    selectPatientPaymentsAndAdjustmentsBillingProceduresLoadingStatus,
} from 'state/slices/ledger/patient-payments-and-adjustments/patient-payments-and-adjustments.selectors';
import {
    selectUACDistributionOpenSections,
    selectUACDistributionPatientEstimateAmounts,
    selectUACDistributionPaymentSource,
    selectUACDistributionTotalRemaining,
} from 'state/slices/ledger/uac-distribution/uac-distribution.selectors';
import { useDispatch } from 'react-redux';
import {
    clearEstimatePayments,
    payAllPatientEstimates,
    setUACDistributionSectionOpen,
    togglePatientEstimateOverpayment,
    updatePatientEstimateTransaction,
} from 'state/slices/ledger/ledger.slice';

const UACDistributionPatientEstimateSection = forwardRef<HTMLDivElement, { onLinkClick?: () => void }>(({ onLinkClick }, ref) => {
    const dispatch = useDispatch();
    const data = useSelector(selectUACDistributionPaymentSource);

    const totalAmountRemaining = useSelector(selectUACDistributionTotalRemaining);

    const { patientEstimateOpen } = useSelector(selectUACDistributionOpenSections);

    const allSummaries = useSelector(selectPatientEstimateProceduresGroupedByEncounter);
    const summariesWithAmount = useSelector(selectPatientEstimateProceduresGroupedByEncounterWithEstimate);

    const transactions = useSelector(selectPatientEstimateTransactions);

    const { totalPatientEstimate, estimateTransactionsTotal } = useSelector(selectUACDistributionPatientEstimateAmounts);

    const loadingTotals = useSelector(selectPatientPaymentsAndAdjustmentsBillingProceduresLoadingStatus);
    const overpayment = useSelector(selectPatientEstimateOverpayment);

    const _onClearAll = () => {
        dispatch(clearEstimatePayments());
    };

    const _toggleExpandSection = () => {
        dispatch(setUACDistributionSectionOpen('patientEstimateOpen'));
    };

    return (
        <UACDistributionSection
            isExpanded={!!patientEstimateOpen}
            toggleExpanded={_toggleExpandSection}
            onClear={_onClearAll}
            loading={loadingTotals}
            total={totalPatientEstimate}
            title="Patient Estimate"
            onLinkClick={onLinkClick}
            ref={ref}
            allowOverpayment={overpayment}
            disableClear={estimateTransactionsTotal <= 0}
            disableDistribute={overpayment}
            onToggleAllowOverpayment={() => {
                dispatch(togglePatientEstimateOverpayment());
            }}
            onDistribute={() => {
                dispatch(payAllPatientEstimates({ maxAmount: totalAmountRemaining }));
            }}
        >
            <PaymentsAndAdjustmentsList
                remainingPaymentAmount={totalAmountRemaining}
                paymentAmount={data?.amount ?? 0}
                transactions={transactions}
                allSummaries={allSummaries}
                summariesWithAmount={summariesWithAmount}
                makingPaymentOrAdjustment
                hideMakeAdjustment
                overpayment={overpayment}
                feeProp="patientEstimate"
                onChangePayment={(encounterId, transactionId, amount) => {
                    dispatch(updatePatientEstimateTransaction({ encounterId, transactionId, amount }));
                }}
                tableType="patientEstimate"
            />
        </UACDistributionSection>
    );
});

UACDistributionPatientEstimateSection.displayName = 'UACDistributionPatientEstimateSection';

export default UACDistributionPatientEstimateSection;
