import { ActionReducerMapBuilder, Dictionary, PayloadAction } from '@reduxjs/toolkit';
import { IChartPerioExamTooth, IChartPerioExamRecord, SurfacesBoolean, SurfacesNumber } from 'api/models/perio-exam.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { isEqual } from 'lodash';
import { ChartingState } from '../chart/chart.slice';
import { createPerioExam, getPerioExamById, getPerioExams, printPerioExams, updatePerioExam } from './perio-exams.actions';
import { ArchType } from './perio-exams.selectors';
import { ISelectedPerioDepthField } from './perio-exams.state';

export const perioExamsReducers = {
    setPerioExamHistorySearch: (state: ChartingState, action: PayloadAction<string | undefined>): void => {
        state.perioExams.historySearch = action.payload;
    },
    setFocusedDepthField: (state: ChartingState, action: PayloadAction<ISelectedPerioDepthField | undefined>): void => {
        state.perioExams.selectedPerioDepthFieldData = action.payload;
    },
    clearPrintExams: (state: ChartingState): void => {
        state.perioExams.printExam = { loading: LoadingStatus.Idle, data: undefined, dates: [], mrn: undefined };
    },
    cleanupPerioExamsSave: (state: ChartingState): void => {
        state.perioExams.saving = LoadingStatus.Idle;
    },

    setToothField: (
        state: ChartingState,
        action: PayloadAction<{
            toothId: number;
            value: unknown;
            surface?: 'distal' | 'mesial' | 'middle';
            arch: 'facial' | 'lingual';
            perioField: keyof IChartPerioExamRecord;
        }>,
    ): void => {
        const { toothId, value, surface, arch, perioField } = action.payload;
        const exam = state.perioExams.currentPerioExam;
        let newvalue: number | null | undefined | unknown = null;
        switch (perioField) {
            case 'gingivalMargin': {
                newvalue = isNaN(parseInt(value as string)) ? undefined : parseInt(value as string);
                break;
            }
            case 'probing': {
                newvalue = isNaN(parseInt(value as string)) ? undefined : parseInt(value as string);
                break;
            }
            default: {
                newvalue = value;
            }
        }

        if (exam && exam.teeth && exam.teeth[toothId]) {
            if (surface) {
                exam.teeth = {
                    ...exam.teeth,
                    [toothId]: {
                        ...exam.teeth[toothId],
                        [arch]: {
                            ...(exam.teeth[toothId] as IChartPerioExamTooth)[arch],
                            [perioField]: {
                                ...((exam.teeth[toothId] as IChartPerioExamTooth)[arch][perioField] as
                                    | SurfacesNumber
                                    | SurfacesBoolean),
                                [surface]: newvalue,
                            },
                        },
                    },
                } as Dictionary<IChartPerioExamTooth>;
            } else {
                exam.teeth = {
                    ...exam.teeth,
                    [toothId]: {
                        ...exam.teeth[toothId],
                        [arch]: {
                            ...(exam.teeth[toothId] as IChartPerioExamTooth)[arch],
                            [perioField]: newvalue,
                        },
                    },
                } as Dictionary<IChartPerioExamTooth>;
            }
            state.perioExams.modified = true;
        }
    },
    setArchType: (state: ChartingState, action: PayloadAction<ArchType>): void => {
        state.perioExams.selectedArchType = action.payload;
    },
};

export const perioExamsExtraReducers = (
    builder: ActionReducerMapBuilder<ChartingState>,
): ActionReducerMapBuilder<ChartingState> =>
    builder
        // [GET] Perio Exams
        .addCase(getPerioExams.pending, (state) => {
            state.perioExams.loading = LoadingStatus.Pending;
        })
        .addCase(getPerioExams.fulfilled, (state, action) => {
            state.perioExams.data = action.payload;
            state.perioExams.loading = LoadingStatus.Completed;
        })
        .addCase(getPerioExams.rejected, (state) => {
            state.perioExams.loading = LoadingStatus.Failed;
        })

        // [GET] Perio Exam by Id
        .addCase(getPerioExamById.pending, (state) => {
            state.perioExams.loading = LoadingStatus.Pending;
        })
        .addCase(getPerioExamById.fulfilled, (state, action) => {
            state.perioExams.currentPerioExam = action.payload;
            state.perioExams.loading = LoadingStatus.Completed;
        })
        .addCase(getPerioExamById.rejected, (state) => {
            state.perioExams.loading = LoadingStatus.Failed;
        })

        // [POST] Perio Exam
        .addCase(createPerioExam.pending, (state) => {
            state.perioExams.saving = LoadingStatus.Pending;
        })
        .addCase(createPerioExam.fulfilled, (state, action) => {
            state.saving = LoadingStatus.Completed;
            if (state.perioExams && state.perioExams.data.length) {
                state.perioExams.data = [...state.perioExams.data, action.payload];
            } else {
                state.perioExams.data = [action.payload];
            }
        })
        .addCase(createPerioExam.rejected, (state) => {
            state.perioExams.saving = LoadingStatus.Failed;
        })

        // [PUT] Perio Exam
        .addCase(updatePerioExam.pending, (state) => {
            state.perioExams.saving = LoadingStatus.Pending;
        })
        .addCase(updatePerioExam.fulfilled, (state, action) => {
            state.perioExams.saving = LoadingStatus.Completed;
            state.perioExams.modified = false;
            if (isEqual(state.perioExams.currentPerioExam, action.payload)) {
                state.perioExams.currentPerioExam = action.payload;
            } else if (state.perioExams.currentPerioExam) {
                state.perioExams.currentPerioExam = { ...state.perioExams.currentPerioExam, _etag: action.payload._etag };
            }
            state.perioExams.currentPerioExam = action.payload;
        })
        .addCase(updatePerioExam.rejected, (state) => {
            state.perioExams.saving = LoadingStatus.Failed;
        })

        // [GET] Perio Reports to Print
        .addCase(printPerioExams.pending, (state) => {
            state.perioExams.printExam.loading = LoadingStatus.Pending;
        })
        .addCase(printPerioExams.fulfilled, (state, action) => {
            state.perioExams.printExam.data = action.payload;
            state.perioExams.printExam.loading = LoadingStatus.Completed;
        })
        .addCase(printPerioExams.rejected, (state) => {
            state.perioExams.printExam.loading = LoadingStatus.Failed;
        });
