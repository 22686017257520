import IForm from 'api/models/form';

export interface ICariesRiskAdultForm extends IForm {
    data: ICariesRiskAdultQuestions;
}

export enum RiskType {
    None = '',
    Low = 'Low',
    Moderate = 'Moderate',
    High = 'High',
}

export interface ICariesRiskAdultQuestions {
    //*Contributing Conditions
    fluorideExposure?: RiskType;
    sugaryStarchyFoodsOrDrinks?: RiskType;
    cariesExperience?: RiskType;
    dentalHome?: RiskType;

    //*General Health Conditions
    specialHealthcareNeeds?: RiskType;
    chemoRadioationTherapy?: RiskType;
    eatingDisorders?: RiskType;
    smokelessTobaccoUse?: RiskType;
    medicationsThatReduceSalivaryFlow?: RiskType;
    drugAlchoholAbuse?: RiskType;

    //*Clinical Conditions
    cavitatedOrNonCavitatedIncipient?: RiskType;
    teethMissingDueToCaries?: RiskType;
    visiblePlaque?: RiskType;
    unusualToothMorphologyThatComprimisesOralHygiene?: RiskType;
    interproximalRestorations1OrMore?: RiskType;
    exposedRootSurfacesPresent?: RiskType;
    restorationsWithOverhangsAndOrOpenMargins?: RiskType;
    severeDryMouth?: RiskType;

    //*Patient Instructions
    additionalPatientInstructions?: string;

    score?: RiskType;
}
