import { ConstrainMode, MessageBar, MessageBarType, SelectionMode } from '@fluentui/react';
import { EncounterSummaryWithLineItems, ProcedureSummary } from 'api/models/encounter-ledger.model';
import { ExpandableDetailsList } from 'components';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import { useSelector } from 'hooks';
import useQuery from 'hooks/useQuery';
import { useCallback } from 'react';
import { getLedgerLineItemTotals } from 'state/slices/ledger/ledger.selectors';
import {
    selectLoadingPatientEncounterSummariesWithLineItems,
    selectPatientEncounterSummariesWithLineItemsError,
    selectPatientPaymentsAndAdjustmentsBillingProceduresError,
    selectPatientPaymentsAndAdjustmentsBillingProceduresIsLoading,
} from 'state/slices/ledger/patient-payments-and-adjustments/patient-payments-and-adjustments.selectors';
import { usdCurrencyFormatter } from 'utils';
import { classicDateOnly } from 'utils/dateOnly';
import { getIsLastIndex, onRenderColumn } from '../LedgerUtils';
import { EncounterLedgerTotalsRow } from '../LedgerView/EncounterLedger';
import AdjustmentEncounterTransactionField from './AdjustmentEncounterTransactionField';
import PaymentEncounterTransactionField from './PaymentEncounterTransactionField';
import PaymentsAndAdjustmentsLineItemsList from './PaymentsAndAdjustmentsLineItemsList';
import { TransactionLookup } from 'state/slices/ledger/patient-payments-and-adjustments/patient-payments-and-adjustments.state';

export type PatientPaymentsAndAdjustmentsTableType = 'patientEstimate' | 'patientBalance';

function getPatientBalancePatientEstimateColumns(
    items: EncounterSummaryWithLineItems[],
    tableType: PatientPaymentsAndAdjustmentsTableType,
    boldedColumns?: string[],
): ISortableColumn<EncounterSummaryWithLineItems>[] {
    const lookup: Record<PatientPaymentsAndAdjustmentsTableType, ISortableColumn<EncounterSummaryWithLineItems>[]> = {
        patientEstimate: [
            {
                key: 'patientEstimate',
                fieldName: 'patientEstimate',
                minWidth: 75,
                maxWidth: 200,
                onRender: (item, index, col) =>
                    onRenderColumn(
                        usdCurrencyFormatter.format(item?.patientEstimate ?? 0),
                        items.length,
                        index,
                        boldedColumns,
                        col?.key,
                    ),
                name: 'Pt. Estimate',
            },
            {
                key: 'totalEstimate',
                fieldName: 'totalEstimate',
                minWidth: 85,
                maxWidth: 200,
                name: 'Total Estimate',
                onRender: (item, index, col) =>
                    onRenderColumn(
                        usdCurrencyFormatter.format(item?.totalEstimate ?? 0),
                        items.length,
                        index,
                        boldedColumns,
                        col?.key,
                    ),
            },
        ],
        patientBalance: [
            {
                key: 'patientBalance',
                fieldName: 'totalCommonPatientFee',
                minWidth: 75,
                maxWidth: 200,
                onRender: (item, index, col) =>
                    onRenderColumn(
                        usdCurrencyFormatter.format(item?.totalCommonPatientFee ?? 0),
                        items.length,
                        index,
                        boldedColumns,
                        col?.key,
                    ),
                name: 'Pt. Balance',
            },
            {
                key: 'totalBalance',
                fieldName: 'totalBalance',
                minWidth: 85,
                maxWidth: 200,
                name: 'Total Balance',
                onRender: (item, index, col) =>
                    onRenderColumn(
                        usdCurrencyFormatter.format(item?.totalBalance ?? 0),
                        items.length,
                        index,
                        boldedColumns,
                        col?.key,
                    ),
            },
        ],
    };

    return lookup[tableType];
}

const getPaymentsAndAdjustmentsColumns = ({
    items,
    boldedColumns,
    tableType = 'patientBalance',
}: {
    items: EncounterSummaryWithLineItems[];
    tableType?: PatientPaymentsAndAdjustmentsTableType;
    boldedColumns?: string[];
}): ISortableColumn<EncounterSummaryWithLineItems>[] => [
    {
        key: 'encounterNumber',
        fieldName: 'encounterNumber',
        minWidth: 40,
        maxWidth: 70,
        name: 'Enc#',
    },
    {
        key: 'dos',
        fieldName: 'dateOfService',
        minWidth: 60,
        maxWidth: 80,
        name: 'DOS',
        onRender: (item, index, col) => {
            if (item?.dateOfService)
                return onRenderColumn(classicDateOnly(item.dateOfService), items.length, index, boldedColumns, col?.key);
        },
    },
    {
        key: 'locationOfCare',
        fieldName: 'locationOfCare',
        minWidth: 100,
        maxWidth: 150,
        name: 'Location of Care',
    },
    {
        key: 'provider',
        fieldName: 'provider',
        minWidth: 100,
        maxWidth: 150,
        name: 'Provider',
        getValueKey: (item) => {
            const lastName = item?.provider?.split(' ')[1];
            return lastName ?? '';
        },
        onRender: (item, index, col) => {
            if (item?.provider) {
                if (item.provider === 'Totals:') {
                    return onRenderColumn(item.provider, items.length, index, undefined, col?.key);
                }
                const nameParts = item.provider.split(' ');
                const lastName = nameParts.pop(); // Remove and store the last name
                const firstName = nameParts.shift(); // Remove and store the first name
                let professionalSuffix = nameParts.join(' '); // The remaining parts are considered the suffix

                if (professionalSuffix.endsWith(',')) {
                    professionalSuffix = professionalSuffix.slice(0, -1); // Remove trailing comma
                    const formattedName = `${lastName} ${professionalSuffix}, ${firstName}`;
                    return onRenderColumn(formattedName, items.length, index, undefined, col?.key);
                } else {
                    const formattedName = `${lastName}${professionalSuffix}, ${firstName}`;
                    return onRenderColumn(formattedName, items.length, index, undefined, col?.key);
                }
            }
            return ''; // Handle cases where the provider name is not formatted as expected
        },
    },
    {
        key: 'totalCharges',
        fieldName: 'totalCharges',
        minWidth: 90,
        maxWidth: 200,
        name: 'Total Charges',
        onRender: (item, index, col) =>
            onRenderColumn(usdCurrencyFormatter.format(item?.totalCharges ?? 0), items.length, index, boldedColumns, col?.key),
    },
    {
        key: 'insurancePayments',
        fieldName: 'insurancePayments',
        minWidth: 70,
        maxWidth: 200,
        name: 'Ins. Paid',
        onRender: (item, index, col) =>
            onRenderColumn(
                usdCurrencyFormatter.format(item?.insurancePayments ?? 0),
                items.length,
                index,
                boldedColumns,
                col?.key,
            ),
    },
    {
        key: 'insuranceAdjustements',
        fieldName: 'insuranceAdjustements',
        minWidth: 100,
        maxWidth: 200,
        name: 'Ins. Adjustments',
        onRender: (item, index, col) =>
            onRenderColumn(
                usdCurrencyFormatter.format(item?.insuranceAdjustments ?? 0),
                items.length,
                index,
                boldedColumns,
                col?.key,
            ),
    },
    {
        key: 'patientPayments',
        fieldName: 'patientPayments',
        minWidth: 120,
        maxWidth: 200,
        onRender: (item, index, col) =>
            onRenderColumn(usdCurrencyFormatter.format(item?.patientPayments ?? 0), items.length, index, boldedColumns, col?.key),
        name: 'Pt. Applied Pymts.',
    },
    {
        key: 'patientAdjustments',
        fieldName: 'patientAdjustments',
        minWidth: 100,
        maxWidth: 200,
        onRender: (item, index, col) =>
            onRenderColumn(
                usdCurrencyFormatter.format(item?.patientAdjustments ?? 0),
                items.length,
                index,
                boldedColumns,
                col?.key,
            ),
        name: 'Patient Adjustments',
    },
    {
        key: 'insuranceBalance',
        fieldName: 'insuranceBalance',
        minWidth: 80,
        maxWidth: 200,
        name: 'Ins. Balance',
        onRender: (item, index, col) =>
            onRenderColumn(
                usdCurrencyFormatter.format(item?.insuranceBalance ?? 0),
                items.length,
                index,
                boldedColumns,
                col?.key,
            ),
    },
    ...getPatientBalancePatientEstimateColumns(items, tableType, boldedColumns),
    {
        key: 'patientReservePayments',
        fieldName: 'patientReservePayments',
        minWidth: 120,
        maxWidth: 200,
        name: 'Pt. Reserve Pymts.',
        onRender: (item, index, col) =>
            onRenderColumn(
                usdCurrencyFormatter.format(Math.abs(item?.patientReservePayments ?? 0)),
                items.length,
                index,
                boldedColumns,
                col?.key,
            ),
    },
];

type Props = {
    makingPaymentOrAdjustment?: boolean;
    hideMakePayment?: boolean;
    hideMakeAdjustment?: boolean;

    allSummaries: EncounterSummaryWithLineItems[];
    summariesWithAmount: EncounterSummaryWithLineItems[];

    tableType?: PatientPaymentsAndAdjustmentsTableType;

    transactions: TransactionLookup;
    adjustmentTransactions?: TransactionLookup;

    onChangePayment?: (encounterId: string, transactionId: string, amount: number) => void;
    overpayment?: boolean;

    paymentAmount?: number;
    remainingPaymentAmount?: number;

    feeProp?: 'patientEstimate' | 'commonPatientFee';
};

export default function PaymentsAndAdjustmentsList({
    makingPaymentOrAdjustment,
    hideMakePayment,
    hideMakeAdjustment,
    paymentAmount = 0,
    remainingPaymentAmount = 0,
    overpayment,

    allSummaries,
    summariesWithAmount,

    transactions,
    adjustmentTransactions,
    onChangePayment,
    feeProp,

    tableType = 'patientBalance',
}: Props) {
    const loadingPatientEncounterSummariesWithLineItems = useSelector(selectLoadingPatientEncounterSummariesWithLineItems);
    const loadingPatientBillingProcedures = useSelector(selectPatientPaymentsAndAdjustmentsBillingProceduresIsLoading);

    const patientBillingProceduresError = useSelector(selectPatientPaymentsAndAdjustmentsBillingProceduresError);
    const patientSummariesError = useSelector(selectPatientEncounterSummariesWithLineItemsError);

    const query = useQuery();
    const _hideMakePayment = hideMakePayment !== undefined ? hideMakePayment : !!query.get('hideMakePayment');
    const _hideMakeAdjustment = hideMakeAdjustment !== undefined ? hideMakeAdjustment : !!query.get('hideMakeAdjustment');

    const getItems = useCallback(() => {
        const items = !makingPaymentOrAdjustment
            ? [...allSummaries]
            : !overpayment
            ? [...summariesWithAmount]
            : [...allSummaries];

        const {
            insuranceBalance,
            insuranceAdjustments,
            insurancePayments,
            patientPayments,
            patientAdjustments,
            patientBalance,
            patientEstimate,
            totalBalance,
            totalCharges,
            patientReservePayments,
            totalCommonPatientFee,
            totalEstimate,
        } = getLedgerLineItemTotals(items);

        items.push({
            provider: 'Totals:',
            dateOfService: '',
            insuranceAdjustments,
            insuranceBalance,
            insurancePayments,
            patientAdjustments,
            patientBalance,
            patientPayments,
            totalBalance,
            totalCharges,
            patientEstimate,
            totalEstimate,

            totalCommonPatientFee,
            procedureSummaries: [],

            //Not being shown
            insuranceCharges: 0,
            patientCharges: 0,
            patientReservePayments,
        });
        return items;
    }, [allSummaries, summariesWithAmount, overpayment, makingPaymentOrAdjustment]);

    const getBoldedColumns = useCallback(() => {
        const paymentColumns =
            tableType === 'patientEstimate' ? ['patientEstimate', 'totalEstimate'] : ['patientBalance', 'totalBalance'];
        const adjustmentColumns = ['patientAdjustments', 'patientPayments', 'patientBalance'];

        const boldedColumns = [];
        if (makingPaymentOrAdjustment) {
            if (!_hideMakeAdjustment) boldedColumns.push(...adjustmentColumns);
            if (!_hideMakePayment) boldedColumns.push(...paymentColumns);
        }

        return boldedColumns;
    }, [_hideMakePayment, _hideMakeAdjustment, makingPaymentOrAdjustment, tableType]);

    const getColumns = useCallback(() => {
        const columns = [
            ...getPaymentsAndAdjustmentsColumns({ items: getItems(), tableType, boldedColumns: getBoldedColumns() }),
        ];
        if (makingPaymentOrAdjustment) {
            if (!_hideMakeAdjustment) {
                columns.unshift({
                    key: 'adjustment',
                    name: 'Adjustments',
                    minWidth: 150,
                    maxWidth: 150,
                    onRender: (item) =>
                        item?.encounterId ? <AdjustmentEncounterTransactionField encounterSummary={item} /> : undefined,
                });
            }
            if (!_hideMakePayment) {
                columns.unshift({
                    key: 'payment',
                    name: 'Payments',
                    minWidth: 150,
                    maxWidth: 150,
                    onRender: (item) =>
                        item?.encounterId ? (
                            <PaymentEncounterTransactionField
                                transactions={transactions}
                                adjustmentTransactions={adjustmentTransactions}
                                paymentAmount={paymentAmount}
                                remainingPaymentAmount={remainingPaymentAmount}
                                encounterSummary={item}
                                overpayment={overpayment}
                                feeProp={feeProp}
                            />
                        ) : undefined,
                });
            }
        }

        return columns;
    }, [
        getItems,
        getBoldedColumns,
        _hideMakeAdjustment,
        _hideMakePayment,
        makingPaymentOrAdjustment,
        paymentAmount,
        feeProp,
        remainingPaymentAmount,
        transactions,
        adjustmentTransactions,
        overpayment,
        tableType,
    ]);

    const noBillingProcedures = !loadingPatientEncounterSummariesWithLineItems && !allSummaries.length;

    if ((patientBillingProceduresError || patientSummariesError) && !loadingPatientEncounterSummariesWithLineItems)
        return <MessageBar messageBarType={MessageBarType.error}>Something went wrong while fetching data.</MessageBar>;
    if (noBillingProcedures) return <MessageBar>There are no encounters with completed billing procedures.</MessageBar>;

    return (
        <ExpandableDetailsList<any>
            items={getItems()}
            selectionMode={SelectionMode.none}
            allowMultipleRowsExpanded
            canExpandAll
            canSortRow={(item, index) => !getIsLastIndex(getItems().length, index)}
            canExpandRow={(item) => !!item.encounterId}
            enableShimmer={loadingPatientEncounterSummariesWithLineItems || loadingPatientBillingProcedures}
            sortColumns={['dos']}
            initialSortDirection={['asc']}
            sortOnMount
            showGrid
            compact
            constrainMode={ConstrainMode.unconstrained}
            onRenderExpandedRowContent={(props) => {
                if (props) {
                    return (
                        <PaymentsAndAdjustmentsLineItemsList
                            encounterSummary={props.item}
                            onChangePayment={onChangePayment}
                            transactions={transactions}
                            adjustmentTransactions={adjustmentTransactions}
                            hideMakeAdjustment={_hideMakeAdjustment}
                            hideMakePayment={_hideMakePayment}
                            makingPaymentOrAdjustment={makingPaymentOrAdjustment}
                            paymentAmount={paymentAmount}
                            feeProp={feeProp}
                            remainingPaymentAmount={remainingPaymentAmount}
                            tableType={tableType}
                        />
                    );
                }
                return null;
            }}
            onRenderRow={(props) => {
                if (props) return <EncounterLedgerTotalsRow {...props} items={getItems()} />;
                return null;
            }}
            columns={getColumns()}
        />
    );
}
