import { Callout, MessageBar, MessageBarType, Stack, TextField } from '@fluentui/react';
import { usePerioExams } from 'hooks';
import { TOOTH_CONTAINER_WIDTH } from 'pages/Perio/perio-settings';
import { FormEvent, useState } from 'react';
import { IExtendedPerioTooth } from 'state/slices/charting/perio-exams/perio-exams.selectors';

type Props = {
    tooth: IExtendedPerioTooth;
    isUpper?: boolean;
    position: number;
    isDisabled?: boolean;
};

function MobilityField({ tooth, isUpper, position, isDisabled }: Props): JSX.Element {
    const { setToothFieldAndUpdate } = usePerioExams();
    const [blockedCallout, setBlockedCallout] = useState(false);
    const _styles: any = {
        root: { width: TOOTH_CONTAINER_WIDTH / 2 },
        field: { paddingLeft: 0, paddingRight: 0, fontSize: 12, textAlign: 'center', lineHeight: 12 },
        fieldGroup: { height: 20 },
    };

    const _onChange = (e?: FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
        if ((value && isNaN(+value)) || (value && +value > 3)) {
            setBlockedCallout(true);
            return;
        }

        setBlockedCallout(false);
        setToothFieldAndUpdate({
            toothId: tooth.id,
            value,
            arch: isUpper ? 'facial' : 'lingual',
            perioField: 'mobility',
        });
    };

    function _onCalloutDismiss() {
        setBlockedCallout(false);
    }

    const mobilityValue = isUpper ? tooth.facial?.mobility : tooth.lingual?.mobility;

    return (
        <Stack horizontalAlign="center">
            {blockedCallout && (
                <Callout
                    isBeakVisible={false}
                    gapSpace={4}
                    target={`#mobility-${isUpper}-${position}`}
                    onDismiss={_onCalloutDismiss}
                    setInitialFocus
                >
                    <MessageBar messageBarType={MessageBarType.blocked}>The value of mobility must be 3 or less.</MessageBar>
                </Callout>
            )}
            <TextField
                autoComplete="off"
                id={`mobility-${isUpper}-${position}`}
                max={3}
                maxLength={1}
                onChange={_onChange}
                value={mobilityValue?.toString() ? mobilityValue?.toString() : ''}
                styles={_styles}
                disabled={isDisabled}
            />
        </Stack>
    );
}

export default MobilityField;
