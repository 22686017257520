import { IBillingProcedure } from 'api/models/billing-procedure.model';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import { ChartProcedureLabel } from 'pages/Ledger/components/RenderProcedureSummaryTooltip';

const procedureCode: ISortableColumn<IBillingProcedure> = {
    key: 'procedureCode',
    name: 'Procedure Code',
    fieldName: 'procedureCode',
    minWidth: 70,
    maxWidth: 70,
    isResizable: true,
    onRender: (item) => {
        if (item)
            return (
                <ChartProcedureLabel chartProcedureStatus={item.status} label={item.procedureCode ? item.procedureCode : 'N/A'} />
            );
    },
};

export default procedureCode;
