import { createAsyncThunk, Dictionary } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import { IProblem } from 'api/models/patient-problem.model';
import ErrorTypes from 'state/errorTypes';

export const getHealthHistoryProblems = createAsyncThunk<Dictionary<IProblem>, undefined, { rejectValue: string }>(
    'problems/getHealthHistory',
    async (_, { rejectWithValue }) => {
        try {
            const { data: diagnoses } = await dentalApi.getHealthHistoryProblems();
            return diagnoses;
        } catch (err: any) {
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(err.toString());
            }
        }
    },
);
