import { AppThunk } from 'state/store';
import { VitalsPanelData, BloodPressureReading } from './vitals.state';
import { v4 as uuid } from 'uuid';
import { format } from 'date-fns';
import IPatientVitals, { abbreviationLookup, IVitalReading, VitalNameType, VitalType } from 'api/models/patient-vital.model';
import { map, flatten, groupBy, filter } from 'lodash';
import { createAsyncThunk, Dictionary } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import ErrorTypes from 'state/errorTypes';
import { MappedVital } from './vitals.selectors';
import { setVitalsPanelData } from '../patient.slice';
import { AxiosError } from 'axios';
import { IPatientEncounter } from 'api/models/encounter.model';
import { classicDateOnly } from 'utils/dateOnly';

export const updatePatientVital = createAsyncThunk<
    IPatientVitals,
    {
        tenantId: string;
        patientId: string;
        encounterId: string;
        item: IPatientVitals;
    },
    { rejectValue: string }
>('updatePatientVital', async ({ tenantId, patientId, item, encounterId }, { rejectWithValue }) => {
    try {
        const response = await dentalApi.updatePatientVitals(tenantId, patientId, item, encounterId);
        return response.data;
    } catch (err) {
        const error = err as AxiosError;
        if (error.response && error.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(error.toString());
        }
    }
});

export const createPatientVital = createAsyncThunk<
    IPatientVitals,
    {
        tenantId: string;
        patientId: string;
        encounterId: string;
        item: IPatientVitals;
    },
    { rejectValue: string }
>('createPatientVital', async ({ tenantId, patientId, item, encounterId }, { rejectWithValue }) => {
    try {
        const response = await dentalApi.createPatientVitals(tenantId, patientId, item, encounterId);
        return response.data;
    } catch (err) {
        const error = err as AxiosError;
        if (error.response && error.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(error.toString());
        }
    }
});

export const setVitalPanelDataFromMappedVital =
    (mappedVital: MappedVital): AppThunk<void> =>
    (dispatch) => {
        const mappedReadings: Dictionary<string | boolean | undefined> = {};
        mappedVital.data
            .filter((reading) => reading.encounterId)
            .forEach((reading) => {
                if (reading.vitalName) {
                    let value;
                    if (reading.value === 'true' || reading.value === 'false') {
                        value = reading.value === 'true' ? true : false;
                    } else {
                        value = reading.value;
                    }

                    mappedReadings[reading.vitalName as VitalNameType] = value;
                }
            });

        // Height Calculations convert CM -> Feet & Inches
        const cm = mappedVital.data.find((reading) => reading.vitalName === VitalNameType.Height)?.value ?? '0';
        const totalInches = parseInt(cm) * 0.3937;
        let inches = Math.round(totalInches % 12);
        let feet = Math.floor(totalInches / 12);
        if (inches === 12) {
            feet += 1;
            inches = 0;
        }
        const feetValue = feet ? `${feet}` : '';
        const inchesValue = inches ? `${inches}` : '';

        // Conver Weight in Grams to Weight in Lbs
        const gramsReading = mappedVital.data.find((reading) => reading.vitalName === VitalNameType.Weight)?.value ?? '0';
        const grams = Math.round(parseFloat(gramsReading) * 0.0022046);
        const gramsValue = grams ? `${grams}` : '';

        const bloodPressureReadings = mappedVital.data.filter(
            (reading) => reading.abbreviation === VitalType.BloodPressure || (reading.vitalName as string) !== 'readingId',
        );
        const groupedReadings = filter(groupBy(bloodPressureReadings, 'readingId'), (readingGroup, key) => key !== 'undefined');
        const bpArray: BloodPressureReading[] = map(groupedReadings, (data) => {
            const readingId = data.find((r) => r.readingId !== undefined)?.readingId;
            const cuff = data.find((r) => r.vitalName === VitalNameType.Cuff);
            const diastolic = data.find((r) => r.vitalName === VitalNameType.Diastolic);
            const position = data.find((r) => r.vitalName === VitalNameType.Position);
            const refused = data.find((r) => r.vitalName === VitalNameType.Refused);
            const refusedReason = data.find((r) => r.vitalName === VitalNameType.RefusedReason);
            const site = data.find((r) => r.vitalName === VitalNameType.Site);
            const systolic = data.find((r) => r.vitalName === VitalNameType.Systolic);

            return {
                'VITALS.BLOODPRESSURE.CUFF': cuff?.value ?? '',
                'VITALS.BLOODPRESSURE.DIASTOLIC': diastolic?.value ?? '',
                'VITALS.BLOODPRESSURE.POSITION': position?.value ?? '',
                'VITALS.BLOODPRESSURE.REFUSED': refused?.value === 'true' ? true : false,
                'VITALS.BLOODPRESSURE.REFUSEDREASON': refusedReason?.value ?? '',
                'VITALS.BLOODPRESSURE.SITE': site?.value ?? '',
                'VITALS.BLOODPRESSURE.SYSTOLIC': systolic?.value ?? '',
                readingId: readingId ?? '',
            };
        });
        // This is making a "readingId" VitalReady
        const vitalsPanelData: VitalsPanelData = {
            BP: bpArray,
            'VITALS.HEIGHT': `${feetValue}` ?? '',
            'VITALS.HEIGHT.INCHES': `${inchesValue}` ?? '',
            'VITALS.HEIGHT.REFUSED': (mappedReadings[VitalNameType.HeightRefused] as boolean) ?? false,
            'VITALS.HEIGHT.REFUSEDREASON': (mappedReadings[VitalNameType.HeightRefusedReason] as string) ?? '',
            'VITALS.HEIGHT.TYPE': (mappedReadings[VitalNameType.HeightType] as string) ?? '',
            'VITALS.O2SATURATION': (mappedReadings[VitalNameType.Saturation] as string) ?? '',
            'VITALS.O2SATURATION.AIRTYPE': (mappedReadings[VitalNameType.SaturationAirtype] as string) ?? '',
            'VITALS.PULSE.LOCATION': (mappedReadings[VitalNameType.PulseLocation] as string) ?? '',
            'VITALS.PULSE.RATE': (mappedReadings[VitalNameType.PulseRate] as string) ?? '',
            'VITALS.PULSE.TYPE': (mappedReadings[VitalNameType.PulseType] as string) ?? '',
            'VITALS.RESPIRATIONRATE': (mappedReadings[VitalNameType.RespirationRate] as string) ?? '',
            'VITALS.TEMPERATURE': (mappedReadings[VitalNameType.Temperature] as string) ?? '',
            'VITALS.TEMPERATURE.TYPE': (mappedReadings[VitalNameType.TemperatureType] as string) ?? '',
            'VITALS.WEIGHT': gramsValue ?? '',
            'VITALS.WEIGHT.OUTOFRANGE': (mappedReadings[VitalNameType.OutOfRange] as boolean) ?? false,
            'VITALS.WEIGHT.REFUSED': (mappedReadings[VitalNameType.WeightRefused] as boolean) ?? false,
            'VITALS.WEIGHT.REFUSEDREASON': (mappedReadings[VitalNameType.WeightRefusedReason] as string) ?? '',
            'VITALS.WEIGHT.TYPE': (mappedReadings[VitalNameType.WeightType] as string) ?? '',
        };

        dispatch(setVitalsPanelData({ data: vitalsPanelData, vitalsToUpdate: mappedVital.data }));
    };

/**
 * [ACTION] updateReadingsFromVitalsPanel
 *
 * Updates reading values based on visitsToUpdate and creates new readings if they don't exist.
 *
 * @param {string} tenantId
 * @param {string} patientId
 * @return {*}  {AppThunk<void>}
 */
export const updateReadingsFromVitalsPanel =
    (tenantId: string, patientId: string): AppThunk<void> =>
    async (dispatch, getState): Promise<void> => {
        const { data: vitals } = await dentalApi.getPatientVitals(tenantId, patientId);
        const { vitalsToUpdate, vitalPanelData } = getState().patient.vitals;
        const patientEncounter = getState().encounter.patientEncounter;
        if (patientEncounter) {
            const { id: encounterId } = patientEncounter;
            const locationOfCareId = getState().encounter.patientEncounter?.locationOfCareId;

            if (!locationOfCareId || !encounterId) return;

            // Assume at least one reading exists with valid date for update in dental
            const readingTaken = patientEncounter.encounterDate ? classicDateOnly(patientEncounter.encounterDate) : '';

            // Filter all vitalsToUpdate out of PatientVitals
            const filteredVitals = vitals.readings
                ? vitals.readings?.filter((v) => vitalsToUpdate.findIndex((vitalToUpdate) => v.id === vitalToUpdate.id) === -1)
                : [];

            // Handle nonBP vitals ----------------------
            const newVitalsToUpdate = map(vitalPanelData, (reading, key) => {
                if (key !== VitalType.BloodPressure) {
                    const vitalToUpdate = vitalsToUpdate.find((reading) => reading.vitalName === key);
                    if (vitalToUpdate) {
                        return updateReading(vitalToUpdate, reading as string | boolean, key, locationOfCareId);
                    } else {
                        return createReading(reading as string | boolean, key, readingTaken, encounterId, locationOfCareId);
                    }
                }
            }).filter((v) => v !== undefined) as IVitalReading[];

            // Handle bp vitals
            const bpReadingsToUpdate = flatten(
                map(vitalPanelData.BP, (bp) => {
                    return map(bp, (value, key) => {
                        const vitalToUpdate = vitalsToUpdate.find((reading) => {
                            return reading.vitalName === key && bp.readingId === reading.readingId;
                        });
                        if (vitalToUpdate) {
                            return updateReading(vitalToUpdate, value as string | boolean, key, locationOfCareId);
                        } else {
                            return createReading(
                                value as string | boolean,
                                key,
                                readingTaken,
                                encounterId,
                                locationOfCareId,
                                bp.readingId,
                            );
                        }
                    });
                }),
            )
                // Filter out undefined, and any reading with vitalName of "readingId" since they are generated off of the object key of BloodPressureReadings
                .filter((data) => data !== undefined && (data.vitalName as string) !== 'readingId') as IVitalReading[];

            // Map updated/created vitals to PatientVitals ----------------------
            const updatedVitalReadings = [...newVitalsToUpdate, ...bpReadingsToUpdate].filter(removeFalseOrEmptyReadings);
            const newVitals: IPatientVitals = { ...vitals, readings: [...filteredVitals, ...updatedVitalReadings] };

            if (encounterId) {
                dispatch(updatePatientVital({ tenantId, patientId, item: newVitals, encounterId }));
            }
        }
        function removeFalseOrEmptyReadings(reading: IVitalReading) {
            if ((reading.value || reading.value === 'true') && reading.value !== 'false') {
                return true;
            }
            return false;
        }
        /**
         * Find the vitalToUpdate and use the vitals panel data to populate the new value
         *
         * @param {IVitalReading} vitalToUpdate
         * @param {(string | boolean)} reading
         * @param {string} key
         * @return {*}  {IVitalReading}
         */
        function updateReading(
            vitalToUpdate: IVitalReading,
            reading: string | boolean,
            key: string,
            locationOfCareId: string,
        ): IVitalReading {
            const value = getVitalValue(key, reading as string | boolean, vitalPanelData);
            return { ...vitalToUpdate, value, locationOfCareId };
        }

        /**
         * Create a new reading based on the reading value and key (VitalNameType)
         *
         * @param {(string | boolean)} reading
         * @param {string} key
         * @return {*}  {(IVitalReading | undefined)}
         */
        function createReading(
            reading: string | boolean,
            key: string,
            readingTaken: string,
            encounterId: string,
            locationOfCareId: string,
            readingId?: string,
        ): IVitalReading | undefined {
            if (key === 'readingId') return undefined;
            const value = getVitalValue(key, reading as string | boolean, vitalPanelData);
            const newVital: IVitalReading = {
                id: uuid(),
                value,
                locationOfCareId,
                vitalName: key as VitalNameType,
                unit: unitsLookup[key] ? unitsLookup[key] : '',
                encounterId,
                readingTaken, // Assume a vital to update has the correct date.
                abbreviation: abbreviationLookup[key] as VitalType | undefined,
                readingId,
            };

            return value ? newVital : undefined;
        }
    };

/**
 * [ACTION] createReadingsFromVitalsPanel
 *
 * Creates new readings from state and adds/saves them to the vitals model
 *
 * @param {string} tenantId
 * @param {string} patientId
 * @return {*}  {AppThunk<void>}
 */
export const createReadingsFromVitalsPanel =
    (tenantId: string, patientId: string): AppThunk<void> =>
    async (dispatch, getState): Promise<void> => {
        const vitalsPanelData = getState().patient.vitals.vitalPanelData;
        const encounter = getState().encounter.patientEncounter;
        const locationOfCareId = getState().encounter.patientEncounter?.locationOfCareId;
        if (encounter && locationOfCareId) {
            const createdReadings = createReadingsFromPanelData(vitalsPanelData, locationOfCareId, encounter);

            const newVital: IPatientVitals = {
                id: patientId,
                createdOn: new Date().toISOString(),
                modifiedOn: new Date().toISOString(),
                isDeleted: false,
                readings: createdReadings,
            };
            dispatch(createPatientVital({ tenantId, patientId, item: newVital, encounterId: encounter.id }));
        }
    };

export function getVitalValue(key: string, value: string | boolean, vitalsPanelData: VitalsPanelData): string {
    switch (key) {
        // Return height in "cm"
        case VitalNameType.Height: {
            const feet = value as string;
            const inches = vitalsPanelData[VitalNameType.HeightInches] as string;

            if (feet === '' && inches === '') return '';

            const feetValue = feet ? parseInt(feet) : 0;
            const inchesValue = inches ? parseInt(inches) : 0;
            const centimeters = (feetValue * 12 + inchesValue) * 2.54;

            return !isNaN(centimeters) ? `${centimeters}` : '0';
        }
        // Return weight in "g"
        case VitalNameType.Weight: {
            const weightInPounds = +(value as string);
            const grams = weightInPounds * 453.59237;
            return weightInPounds ? `${grams}` : '';
        }
        default: {
            return `${value}`;
        }
    }
}

const unitsLookup: { [key: string]: string } = {
    [VitalNameType.Height]: 'cm',
    [VitalNameType.Weight]: 'g',
    [VitalNameType.Temperature]: 'F',
    [VitalNameType.PulseRate]: 'bpm',
    [VitalNameType.Saturation]: '%',
};

/**
 * [HELPER] createReadingsFromPanelData
 *
 * Maps new vital readings from vitalsPanelData
 *
 * @param {VitalsPanelData} vitalsPanelData
 * @param {string} encounterId
 * @return {*}  {IVitalReading[]}
 */
function createReadingsFromPanelData(
    vitalsPanelData: VitalsPanelData,

    locationOfCareId: string,
    encounter: IPatientEncounter,
): IVitalReading[] {
    const arrayOfNonBPReadings: IVitalReading[] = map(vitalsPanelData, (data, key) => {
        if (key !== VitalType.BloodPressure && data.toString() !== '') {
            const value = getVitalValue(key, data as string | boolean, vitalsPanelData);
            const newVital: IVitalReading = {
                id: uuid(),
                value,
                vitalName: key as VitalNameType,
                unit: unitsLookup[key] ? unitsLookup[key] : '',
                encounterId: encounter.id,
                locationOfCareId,
                readingTaken: encounter.encounterDate ? classicDateOnly(encounter.encounterDate) : '',
                abbreviation: abbreviationLookup[key] as VitalType | undefined,
            };
            return newVital;
        }
    }).filter((data) => data !== undefined) as IVitalReading[];

    const arrayOfBPReadings: IVitalReading[] = flatten(
        vitalsPanelData.BP.map(
            (reading) =>
                map(reading, (data, key) =>
                    data && key !== 'readingId'
                        ? {
                              id: uuid(),
                              value: `${data}`,
                              vitalName: key as VitalNameType,
                              encounterId: encounter.id,
                              readingTaken: encounter.encounterDate ? classicDateOnly(encounter.encounterDate) : '',
                              abbreviation: VitalType.BloodPressure,
                              readingId: reading?.readingId,
                          }
                        : undefined,
                ).filter((data) => data !== undefined) as IVitalReading[],
        ),
    );

    return [...arrayOfNonBPReadings, ...arrayOfBPReadings].filter((reading) => reading.vitalName !== VitalNameType.HeightInches);
}
