import Tooth from './Tooth';
import * as PIXI from 'pixi.js-legacy';
import PixiText from './PixiText';
import ToothOverlay from './ToothOverlay';

export class PartiallyErupted extends ToothOverlay {
    constructor(tooth: Tooth) {
        super(tooth, tooth);
        this.name = 'PartiallyErupted';
        this._createPartiallyErupted();
    }

    private _createPartiallyErupted() {
        const text = new PixiText('PE');

        text.anchor.set(0.5);
        text.y = !this.getIsBottomRow ? -this.tooth.height + 60 : this.tooth.height + 50;
        text.x = this.tooth.getToothWidth / 2;
        this.addChild(text);
    }
}
