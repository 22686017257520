import { AnyAction, createAsyncThunk, Dispatch, ThunkDispatch } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import { IPerioExam, IChartPerioExamTooth, IChartPerioExamRecord, SurfacesNumber } from 'api/models/perio-exam.model';
import { push } from 'connected-react-router';
import { AppThunk, RootState } from 'state/store';
import { v4 as uuid } from 'uuid';
import { generateTeethFromDentition, ProcedureActionType, setToothField } from '../chart/chart.slice';
import { setFocusedDepthField } from 'state/slices/charting/chart/chart.slice';
import { IEmbedReportModel } from 'api/models/embed-report.model';
import { ChartProcedureStatus } from 'api/models/chart.model';
import { getIsActiveChartProcedure } from '../procedures/procedures.selectors';

export const getPerioExams = createAsyncThunk<
    IPerioExam[],
    {
        tenantId: string;
        patientId: string;
    }
>('getPerioExams', async ({ tenantId, patientId }) => {
    const result = await dentalApi.getPerioExams(tenantId, patientId);
    return result.data;
});

export const getPerioExamById = createAsyncThunk<
    IPerioExam,
    {
        tenantId: string;
        patientId: string;
        procedureId: string;
    }
>('getPerioExamById', async ({ tenantId, patientId, procedureId }) => {
    const result = await dentalApi.getPerioExamById(tenantId, patientId, procedureId);
    return result.data;
});

export const createPerioExam = createAsyncThunk<
    IPerioExam,
    {
        tenantId: string;
        patientId: string;
        encounterId: string;
    },
    {
        dispatch: Dispatch;
        state: RootState;
    }
>('createPerioExam', async ({ tenantId, patientId, encounterId }, { dispatch }) => {
    const dentitions = await dentalApi.getChartDentitions(tenantId, patientId);
    const conditions = await dentalApi.getChartConditions(tenantId, patientId);
    const procedures = await dentalApi.getChartProcedures(tenantId, patientId);
    const { data: conditionsLookup } = await dentalApi.getConditions();
    const { data: proceduresLookup } = await dentalApi.getProcedures(tenantId);
    const conditionsAsList = conditions.data.filter((c) => !c.isDeleted);
    const proceduresAsList = procedures.data.filter(getIsActiveChartProcedure).filter((p) => {
        if (p.status === ChartProcedureStatus.Completed && p.type === ProcedureActionType.Treatment) {
            return true;
        }
        if (p.status === ChartProcedureStatus.Completed && p.type === ProcedureActionType.Referred) {
            return true;
        }
        if (p.type === ProcedureActionType.Existing || p.type === ProcedureActionType.ExistingOther) {
            return true;
        } else {
            return false;
        }
    });
    const perioExam: IPerioExam = {
        id: uuid(),
        isDeleted: false,
        encounterId,
        patientId,
        teeth: generateTeethFromDentition(
            dentitions.data,
            conditionsAsList,
            conditionsLookup,
            proceduresAsList,
            proceduresLookup,
        ),
    };

    const result = await dentalApi.createPerioExam(tenantId, patientId, perioExam);

    if (result.data) dispatch(push(`/${tenantId}/patient/${patientId}/encounter/${encounterId}/perio/${result.data.id}`));

    return result.data;
});

export const printPerioExams = createAsyncThunk<
    IEmbedReportModel,
    { tenantId: string; patientId: string; examIds: string[] },
    { rejectValue: string }
>('printPerioExams', async ({ patientId, examIds, tenantId }, { rejectWithValue }) => {
    try {
        const { data: report } = await dentalApi.getPerioReport(tenantId, patientId, examIds);
        return report;
    } catch (e) {
        return rejectWithValue(e as string);
    }
});

export const updatePerioExam = createAsyncThunk<
    IPerioExam,
    {
        tenantId: string;
        patientId: string;
        perioExam: IPerioExam;
    }
>('updatePerioExam', async ({ tenantId, patientId, perioExam }) => {
    const result = await dentalApi.updatePerioExam(tenantId, patientId, perioExam);
    return result.data;
});

export const setProbingDepth =
    (props: { value: number; tenantId: string; patientId: string }): AppThunk<void> =>
    async (dispatch, getState) => {
        const selectedPerioDepthFieldData = getState().charting.perioExams.selectedPerioDepthFieldData;
        if (selectedPerioDepthFieldData) {
            const { toothId, facialLingual, surface } = selectedPerioDepthFieldData;
            dispatch(
                setToothFieldAndUpdate({
                    ...props,
                    toothId,
                    perioField: 'probing',
                    arch: facialLingual as 'facial' | 'lingual',
                    surface,
                }),
            );
            dispatch(setFocusedDepthField(undefined));
        }
    };

let perioUpdateTimer: NodeJS.Timeout | null = null;
export const setToothFieldAndUpdate =
    ({
        toothId,
        value,
        surface,
        arch,
        perioField,
        tenantId,
        patientId,
    }: {
        toothId: number;
        value: unknown;
        surface?: 'distal' | 'mesial' | 'middle';
        arch: 'facial' | 'lingual';
        perioField: keyof IChartPerioExamRecord;
        tenantId: string;
        patientId: string;
    }) =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>, getState: () => RootState): Promise<void> => {
        await dispatch(setToothField({ toothId, value, surface, arch, perioField }));
        if (perioUpdateTimer) clearTimeout(perioUpdateTimer);
        perioUpdateTimer = setTimeout(() => {
            const exam = getState().charting.perioExams.currentPerioExam;
            perioUpdateTimer = null;
            if (exam) dispatch(updatePerioExam({ tenantId, patientId, perioExam: exam }));
        }, 4000);
    };

export const updateProbingDepth =
    (
        tenantId: string,
        patientId: string,
        toothId: number,
        isfacial: boolean,
        surface: 'distal' | 'middle' | 'mesial',
        value: number | undefined,
    ) =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>, getState: () => RootState): Promise<void> => {
        const exam = getState().charting.perioExams.currentPerioExam;
        if (exam) {
            const newExam = JSON.parse(JSON.stringify(exam)) as IPerioExam;
            if (newExam.teeth && newExam.teeth[toothId]) {
                if (!(newExam.teeth[toothId] as IChartPerioExamTooth)[isfacial ? 'facial' : 'lingual']['probing']) {
                    (newExam.teeth[toothId] as IChartPerioExamTooth)[isfacial ? 'facial' : 'lingual']['probing'] = {};
                }
                if ((newExam.teeth[toothId] as IChartPerioExamTooth)[isfacial ? 'facial' : 'lingual'].probing)
                    ((newExam.teeth[toothId] as IChartPerioExamTooth)[isfacial ? 'facial' : 'lingual'].probing as SurfacesNumber)[
                        surface
                    ] = value;
                dispatch(updatePerioExam({ tenantId, patientId, perioExam: newExam }));
            }
        }
    };

export const updateGingivalMargin =
    (
        tenantId: string,
        patientId: string,
        toothId: number,
        isfacial: boolean,
        surface: 'distal' | 'middle' | 'mesial',
        value: number | undefined,
    ) =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>, getState: () => RootState): Promise<void> => {
        const exam = getState().charting.perioExams.currentPerioExam;
        if (exam) {
            const newExam = JSON.parse(JSON.stringify(exam)) as IPerioExam;
            if (newExam.teeth && newExam.teeth[toothId]) {
                if (!(newExam.teeth[toothId] as IChartPerioExamTooth)[isfacial ? 'facial' : 'lingual']['gingivalMargin']) {
                    (newExam.teeth[toothId] as IChartPerioExamTooth)[isfacial ? 'facial' : 'lingual']['gingivalMargin'] = {};
                }
                if ((newExam.teeth[toothId] as IChartPerioExamTooth)[isfacial ? 'facial' : 'lingual'].gingivalMargin)
                    (
                        (newExam.teeth[toothId] as IChartPerioExamTooth)[isfacial ? 'facial' : 'lingual']
                            .gingivalMargin as SurfacesNumber
                    )[surface] = value;
                dispatch(updatePerioExam({ tenantId, patientId, perioExam: newExam }));
            }
        }
    };
