import { useSelector } from 'hooks';
import { RouteParams } from 'interfaces/route-params';
import AppointmentOverview from 'pages/Scheduling/components/AppointmentOverview/AppointmentOverview';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updateWorkListAppointmentData } from 'state/slices/admin-huddle/worklists/appointment-worklists/appointment-worklists.actions';
import { selectCurrentWorkList } from 'state/slices/admin-huddle/worklists/worklist.selectors';
import { setWorkListLayerOpen } from 'state/slices/admin-huddle/worklists/worklist.slice';
import { getPatientToEdit } from 'state/slices/edit-patient/edit-patient.actions';
import { closeAppointmentOverview, setCancelAppointmentModalOpen } from 'state/slices/scheduling/scheduling.slice';

export default function WorkListAppointmentOverview() {
    const dispatch = useDispatch();
    const { tenantId } = useParams<RouteParams>();
    const workList = useSelector(selectCurrentWorkList);

    useEffect(() => {
        return () => {
            dispatch(closeAppointmentOverview());
        };
    }, [dispatch]);

    return (
        <AppointmentOverview
            onSaveAppointmentOverview={(updatedAppointment) => {
                if (workList)
                    dispatch(
                        updateWorkListAppointmentData({
                            tenantId,
                            workList,
                            appointment: updatedAppointment,
                            recordId: updatedAppointment.id,
                        }),
                    );
            }}
            onEditPatient={({ tenantId, patientId }) => {
                dispatch(getPatientToEdit({ patientId, tenantId }));
                dispatch(setWorkListLayerOpen({ layer: 'EditPatientPanel', isOpen: true }));
            }}
            onCancelAppointment={(appointmentToRemoveId) => {
                dispatch(setCancelAppointmentModalOpen({ appointmentToRemoveId }));
                dispatch(setWorkListLayerOpen({ layer: 'CancelAppointmentModal', isOpen: true }));
            }}
        />
    );
}
