import { createSlice } from '@reduxjs/toolkit';
import { encounterExtraReducers, encounterReducers } from './encounter.reducer';
import { encounterInitialState } from './encounter.state';

const encounter = createSlice({
    name: 'encounter',
    initialState: encounterInitialState,
    reducers: {
        ...encounterReducers,
    },
    extraReducers: (builder) => {
        encounterExtraReducers(builder);
    },
});

const { reducer, actions } = encounter;

export const {
    cleanupChartPatientEncounterAppointment,
    setEncounterDataProp,
    setEncounterUserIdentity,
    addPatientEncounterPrescription,
    setEncounterAppointment,
    cleanupPatientEncounter,
    setPatientEncounter,
    updateIncompleteEncounter,
} = actions;

export default reducer;
