import conditions, { getConditions } from './conditions.slice';
import procedures, { getProcedures } from './procedures.slice';
import diagnoses, { getDiagnoses } from './diagnoses.slice';
import procedureCategories, { getProcedureCategories } from './procedureCategories.slice';
import quickActions, { getQuickActionCategories, getQuickActions } from './quickActions/quickActions.slice';
import { AnyAction, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { RootState } from 'state/store';
import { batch } from 'react-redux';
import providers, { getProviders } from './providers.slice';
import slidingFeePrograms, { getSlidingFeePrograms } from './sliding-fee.slice';
import subscriptions, { getTenantProductIds } from './subscriptions.slice';
import { getAllEncounterReasons } from 'state/slices/encounter-reasons/encounter-reasons.actions';
import benefitPlans, { getBenefitPlans } from './benefit-plan.slice';
import tenantPayers, { getTenantPayers } from './tenant-payers.slice';
import { getLanguages } from './language.slice';
import sexualOrientations, { getSexualOrientations } from './sexualOrientation.slice';
import ethnicities, { getEthnicities } from './ethnicities.slice';
import legalSexes, { getLegalSexes } from './legal-sexes.slice';
import genderIdentities, { getGenderIdentities } from './gender-identities.slice';
import races, { getRaces } from './races.slice';
import { getLookups } from '../lookups/lookups.reducers';
import encounterStatus, { getPatientEncounterStatus } from './encounter-status.slice';
import dosageQuantity, { getDosageQuantity } from './dosageQuantity';
import dosageFrequency, { getDosageFrequency } from './dosageFrequency';
import additionalInstructions, { getAdditionalInstructions } from './additionalInstructions';
import batches, { getBatches } from './batches.slice';
import imagingSource, { getImagingSourcesByTenant } from './imaging-source.slice';
import adjustmentReasons, { getAllAdjustmentReasons } from './adjustment-reasons.slice';
import { getDocumentsType } from '../documents/documents.actions';
import amendmentReasons, { getAmendmentReasons } from './amendmentReason';
import medicationStopReasons, { getMedicationsStopReasons } from './medications-stop-reasons-slice';
import featureFlags, { getActiveFeatureFlagsForTenant } from './feature-flags.slice';
import payPeriods, { getPayPeriods } from './payPeriod.slice';
import { getAlertTypes } from '../clinical-alert/clincal-alerts.actions';
import { getHoldingStatement } from './holding-statement.slice';

export const getTenantAssets = createAsyncThunk<
    void,
    string,
    {
        state: RootState;
        dispatch: ThunkDispatch<RootState, unknown, AnyAction>;
    }
>('getTenantAssets', async (tenantId, thunkApi) => {
    const { dispatch } = thunkApi;

    if (tenantId) {
        batch(() => {
            dispatch(getActiveFeatureFlagsForTenant({ tenantId }));
            dispatch(getQuickActionCategories({ tenantId }));
            dispatch(getQuickActions({ tenantId }));
            dispatch(getDiagnoses({ tenantId }));
            dispatch(getProcedures(tenantId));
            dispatch(getLookups({ tenantId, lookupType: 'Operatories' }));
            dispatch(getLookups({ tenantId, lookupType: 'ProcedureDetails' }));
            dispatch(getConditions());
            dispatch(getProviders({ tenantId }));
            dispatch(getSlidingFeePrograms({ tenantId }));
            dispatch(getTenantProductIds({ tenantId }));
            dispatch(getProcedureCategories());
            dispatch(getAllEncounterReasons({ tenantId }));
            dispatch(getBenefitPlans());
            dispatch(getTenantPayers({ tenantId }));
            dispatch(getLanguages());
            dispatch(getHoldingStatement());
            dispatch(getPayPeriods());
            dispatch(getSexualOrientations());
            dispatch(getEthnicities());
            dispatch(getLegalSexes());
            dispatch(getGenderIdentities());
            dispatch(getRaces());
            dispatch(getDosageFrequency());
            dispatch(getDosageQuantity());
            dispatch(getPatientEncounterStatus());
            dispatch(getAdditionalInstructions());
            //dispatch(getBatches(tenantId));
            dispatch(getImagingSourcesByTenant(tenantId));
            dispatch(getAllAdjustmentReasons());
            dispatch(getDocumentsType());
            dispatch(getAmendmentReasons());
            dispatch(getMedicationsStopReasons());
            dispatch(getAlertTypes());
        });
    }
});

export const selectTenant = (state: RootState): TenantState => state.tenant;
export const selectTenantDiagnoses = createSelector(selectTenant, (state) => {
    return state?.diagnoses?.data ? state.diagnoses.data : {};
});

export const selectLookupLoaders = createSelector(selectTenant, (tenant) => {
    const {
        diagnoses,
        procedures,
        quickActions,
        conditions,
        providers,
        slidingFeePrograms,
        procedureCategories,
        benefitPlans,
        tenantPayers,
        sexualOrientations,
        amendmentReasons,
        ethnicities,
        legalSexes,
        genderIdentities,
        races,
        dosageFrequency,
        dosageQuantity,
        additionalInstructions,
        medicationStopReasons,
        payPeriods,
        featureFlags,
    } = tenant;
    return [
        diagnoses.initialLoad,
        procedures.initialLoad,
        quickActions?.items?.initialLoad,
        quickActions?.categories?.initialLoad,
        conditions.initialLoad,
        providers.initialLoad,
        slidingFeePrograms.initialLoad,
        procedureCategories.initialLoad,
        benefitPlans.initialLoad,
        tenantPayers.initialLoad,
        sexualOrientations.initialLoad,
        ethnicities.initialLoad,
        legalSexes.initialLoad,
        genderIdentities.initialLoad,
        races.initialLoad,
        dosageFrequency.initialLoad,
        dosageQuantity.initialLoad,
        additionalInstructions.initialLoad,
        medicationStopReasons.initialLoad,
        amendmentReasons.initialLoad,
        payPeriods.initialLoad,
        featureFlags.loadingActiveFeatureFlags,
    ];
});

export type TenantState = ReturnType<typeof tenantReducer>;

const tenantReducer = combineReducers({
    conditions,
    procedures,
    diagnoses,
    quickActions,
    providers,
    slidingFeePrograms,
    subscriptions,
    procedureCategories,
    benefitPlans,
    tenantPayers,
    sexualOrientations,
    ethnicities,
    legalSexes,
    genderIdentities,
    races,
    encounterStatus,
    amendmentReasons,
    payPeriods,

    dosageFrequency,
    dosageQuantity,
    additionalInstructions,

    batches,
    imagingSource,
    adjustmentReasons,
    medicationStopReasons,

    featureFlags,
});

export default tenantReducer;
