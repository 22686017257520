import { createSlice } from '@reduxjs/toolkit';
import { documentsExtraReducers, reducers } from './documents.reducers';
import { initialState } from './documents.state';

const documentsSlice = createSlice({
    name: 'documents',
    initialState,
    reducers,
    extraReducers: (builder) => {
        documentsExtraReducers(builder);
    },
});

export default documentsSlice.reducer;

export const {
    updatePendingDocument,
    deletePendingDocumentAndFile,
    addPendingDocuments,
    cleanupPendingDocumentsAndFiles,
    cleanupDocumentData,
} = documentsSlice.actions;
