import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import authProvider from 'api/auth/authProvider';
import { RootState } from 'state/store';
const baseUrl = process.env.NODE_ENV === 'development' ? `${process.env.REACT_APP_AppApiUri}/api/` : '#{AppApiUri}#/api/';
const scopes = ['https://elwooddev.onmicrosoft.com/product/user_impersonation'];

const baseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders: async (headers, { getState }) => {
        let token = (getState() as RootState).auth.idToken?.rawIdToken;

        if (!token) {
            const accounts = await authProvider.getAllAccounts();
            const response = await authProvider.acquireTokenSilent({ scopes, account: accounts[0] });
            token = response.idToken;
        }

        headers.set('authorization', `Bearer ${token}`);
        return headers;
    },
});

export const baseApi = createApi({
    reducerPath: 'dentalApi',
    baseQuery,
    endpoints: () => ({}),
});
