import LookupBase from './lookup-base.model';
import {
    ChangeAuditSupport,
    CodeSupport,
    DisplayNameSupport,
    EffectiveDateRange,
    IdSupport,
    SoftDeleteSupport,
} from './support-types';

export type ProcedureSupports = IdSupport &
    ChangeAuditSupport &
    DisplayNameSupport &
    CodeSupport &
    SoftDeleteSupport &
    EffectiveDateRange;

export enum ConflictRuleType {
    anteriorOnly = 'anterior-only',
    posteriorOnly = 'posterior-only',

    molarOnly = 'molar-only',
    bicuspidOnly = 'bicuspid-only',

    onePerArea = 'one-per-area',
    onePerEncounter = 'one-per-encounter',
    onePerSurface = 'one-per-surface',
    onePerTooth = 'one-per-tooth',
    onePerQuad = 'one-per-quad',

    twoOrMoreSurfacesOnly = 'two-or-more-surfaces-only',
    twoSurfacesOnly = 'two-surfaces-only',

    threeSurfacesOnly = 'three-surfaces-only',
    threeOrMoreSurfacesOnly = 'three-or-more-surfaces-only',

    fourSurfacesOnly = 'four-surfaces-only',
    fourOrMoreSurfacesOnly = 'four-or-more-surfaces-only',

    betweenAbutmentsOnly = 'between-abutments-only',
    requiresCompletedExtractionOrMissingToComplete = 'requires-completed-extraction-or-missing-to-complete',

    //TODO: Need rule logic for these for these conflict rules
    primaryToothOnly = 'primary-tooth-only',
    permanentToothOnly = 'permanent-tooth-only',

    maxillaryOnly = 'maxillary-only',
    mandibularOnly = 'mandibular-only',

    partialArch = 'partial-arch',

    procedureRequiredToChartProcedure = 'procedure-required-to-chart-procedure',
}

export enum ApplicableArea {
    Translate = 'translate',
    Rotate = 'rotate',
    Surface = 'surface',

    Tooth = 'tooth',

    Mouth = 'mouth',

    MaxillaryArch = 'maxillaryArch', // UA
    MandibularArch = 'mandibularArch', //UL

    Arch = 'arch',
    Quadrant = 'quadrant',

    QuadOrArch = 'quadOrArch',

    Crown = 'crown',
    Root = 'root',
    Exam = 'exam',
}

export interface ICondition extends LookupBase {
    description: string;
    isDeleted: boolean;
    applicableArea?: keyof typeof ApplicableArea;
    conflictRules?: ConflictRuleType[];
    code?: string;
    class?: string;
    renderRule?: string;
    icd10?: string;
    snomedCode?: string;
}

export type DentalLookupEntities =
    | 'Procedures'
    | 'Diagnoses'
    | 'QuickActionCategories'
    | 'QuickActions'
    | 'Conditions'
    | 'Providers'
    | 'Payers'
    | 'SlidingFeePrograms'
    | 'Subscriptions';
