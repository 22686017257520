import { Label, Persona, PersonaSize, Stack, Text, useTheme } from '@fluentui/react';
import IProvider, { IProviderPeriod } from 'api/models/provider.model';
import { TimeTableRange } from 'api/models/Scheduling/operatory.model';
import { useSelector } from 'hooks';
import useLookupsStore from 'hooks/store/useLookupsStore';
import { useDispatch } from 'react-redux';
import {
    selectActiveProviderByType,
    selectAllocations,
    selectSelectedDate,
    selectSelectedLOC,
} from 'state/slices/scheduling/scheduling.selectors';
import { clearPeriods } from 'state/slices/settings/settings.slice';
import { getDayOfWeekString } from 'utils/getDayOfWeekString';
import { timeOptions } from 'utils/getTimeOptions';
import EmergencyExceptionsModel from './EmergencyExceptionModal';
import { isDateBetween } from 'utils/isDateBetween';
import { classicDateOnly } from 'utils/dateOnly';

function ScheduleQuickView(): JSX.Element {
    const allocations = useSelector(selectAllocations);

    const _theme = useTheme();
    const selectedDate = useSelector(selectSelectedDate);
    const selectedLoc = useSelector(selectSelectedLOC);
    const dispatch = useDispatch();
    const { selectedLookup, updateLookup } = useLookupsStore<IProvider>('Providers');

    const item = selectedLookup?.item as IProvider;

    const providers = useSelector((state) =>
        selectActiveProviderByType(state, ['isTreatingProvider', 'isRegisteredDentalAssistant', 'isHygienist']),
    );

    const onPeriodSave = (period: IProviderPeriod) => {
        let currentPeriods = [...(item?.providerSchedule?.periods ?? [])];
        const indexOfPeriod = currentPeriods.findIndex((periods) => periods.id === period.id);

        if (indexOfPeriod > -1) {
            currentPeriods[indexOfPeriod] = period;
        } else {
            currentPeriods = [...currentPeriods, period];
        }

        updateLookup({
            ...selectedLookup,
            item: {
                ...item,
                providerSchedule: { ...item?.providerSchedule, periods: currentPeriods },
            } as IProvider,
        });

        dispatch(clearPeriods());
    };

    const providersScheduleQuickView = providers.map((provider) => {
        const patientAllocationCount =
            allocations &&
            allocations?.patients &&
            allocations?.patients.length &&
            allocations?.patients
                .filter((res) => !res.isDeleted)
                .filter(
                    (x) =>
                        x.hygienistId === provider?.id ||
                        x.registeredDentalAssistantId === provider?.id ||
                        x.treatingProviderId === provider?.id,
                ).length;

        const timeRanges = () => {
            const dayOfWeek = getDayOfWeekString(selectedDate);

            let timeTable: TimeTableRange[] | undefined;

            provider.providerSchedule?.periods?.forEach((period) => {
                const activeDuringPeriod = isDateBetween({
                    dateToCheck: classicDateOnly(selectedDate.toISOString()),
                    start: period.startDate,
                    end: period.endDate,
                });
                if (activeDuringPeriod) {
                    period.schedules?.forEach((schedule) => {
                        if (schedule.locationOfCareId === selectedLoc?.id) {
                            if (dayOfWeek && schedule && schedule.timeTables) timeTable = schedule.timeTables[dayOfWeek];
                        }
                    });
                }
            });

            if (Array.isArray(timeTable) && timeTable.length) {
                return (
                    <ProviderItem
                        appointmentCount={patientAllocationCount}
                        provider={provider}
                        timeTable={timeTable}
                        key={provider.id}
                    />
                );
            } else {
                return null;
            }
        };
        return timeRanges();
    });

    return (
        <Stack styles={{ root: { minWidth: '220px', backgroundColor: _theme.palette.neutralLighterAlt } }}>
            <Stack.Item>
                <Stack>
                    <Stack.Item>
                        <Stack
                            styles={{
                                root: { paddingLeft: 10, backgroundColor: _theme.palette.white },
                            }}
                        >
                            <Label>Schedule Quickview</Label>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack
                            tokens={{ childrenGap: 10 }}
                            styles={{ root: { padding: 10, overflow: 'hidden', overflowY: 'auto' } }}
                            grow
                        >
                            {providersScheduleQuickView}
                        </Stack>
                    </Stack.Item>
                </Stack>
            </Stack.Item>
            <EmergencyExceptionsModel onSave={onPeriodSave} provider={item} />
        </Stack>
    );
}

export default ScheduleQuickView;

function ProviderItem({
    provider,
    appointmentCount = 0,
    timeTable,
}: {
    provider: IProvider;
    timeTable: TimeTableRange[];
    appointmentCount: number | undefined;
}): JSX.Element {
    return (
        <Stack tokens={{ childrenGap: 2 }}>
            <Stack horizontal horizontalAlign="space-between">
                <Stack>
                    <Persona
                        size={PersonaSize.size24}
                        coinProps={{
                            styles: {
                                initials: {
                                    background: `${provider?.providerSchedule?.scheduleColor} !important`,
                                    color: 'black',
                                },
                            },
                        }}
                        text={`${provider?.firstName} ${provider?.lastName}`}
                        onRenderPrimaryText={() => (
                            <Text variant="small">{`${provider?.lastName} ${provider?.suffix ? `${provider?.suffix}` : ''}, ${
                                provider?.firstName
                            } (${appointmentCount})`}</Text>
                        )}
                    />

                    <Stack tokens={{ childrenGap: 2, padding: '0px 32px' }}>
                        <TimeRanges timeTable={timeTable} />
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
}

function TimeRanges({ timeTable }: { timeTable: TimeTableRange[] }): JSX.Element {
    const items = timeTable.map((range, index: number) => {
        const startTime = timeOptions.find((t) => t.key === range.startTime)?.text;
        const endTime = timeOptions.find((t) => t.key === range.endTime)?.text;

        return <Text key={index} variant="xSmall">{`${startTime} - ${endTime}`}</Text>;
    });
    return <>{items}</>;
}
