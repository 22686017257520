import { ActionReducerMapBuilder, Dictionary, PayloadAction } from '@reduxjs/toolkit';
import { VitalNameType, VitalType } from 'api/models/patient-vital.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { isEqual } from 'lodash';
import { fetchPatientOverviewTasks } from '../patient.actions';
import { PatientState, OverviewItem } from '../patient.state';
import {
    createOverviewItem,
    createPatientImmunization,
    updateOverviewItem,
    updatePatientImmunizations,
} from './overview.actions';

const getCleanPatientOverview = () => ({
    item: undefined,
    savingStatus: LoadingStatus.Idle,
    validationErrors: [],
    isNewItem: false,
    key: 0,
    displayName: '',
    type: '',
});

const getPatientOverviewFromState = (state: PatientState, type: string) => {
    const lowercaseType = type.toLowerCase();
    switch (lowercaseType) {
        case 'allergies':
            return state['allergies'];
        case 'problems':
            return state['problems'];
        case 'medications':
            return state['medications'];
        case 'vitals':
            return state['vitals'];
        case 'immunizations':
            return state['immunizations'];
        default:
            break;
    }
};

const overviewReducers = {
    setOverviewItemDataProp: (
        state: PatientState,
        action: PayloadAction<{
            type: string;
            path: string;
            value: string | boolean | number | Dictionary<string> | undefined;
        }>,
    ): void => {
        const { type, path, value } = action.payload;
        if ((state as any)[type].data) {
            ((state as any)[type].data as any)[path] = value;
        } else if ((state as any)[type].loading === LoadingStatus.Completed) {
            (state as any)[type].data = { [path]: value };
        }
    },
    setSelectedOverviewItem: (state: PatientState, action: PayloadAction<OverviewItem>): void => {
        state.selectedOverviewItem = action.payload;
    },
    cleanupSelectedOverviewItem: (state: PatientState): void => {
        state.selectedOverviewItem = getCleanPatientOverview();
    },
};

export default overviewReducers;

export const overviewExtraReducers = (builder: ActionReducerMapBuilder<PatientState>): ActionReducerMapBuilder<PatientState> =>
    builder
        .addCase(createOverviewItem.pending, (state, action) => {
            const type = action.meta.arg.type;
            const getState = getPatientOverviewFromState(state, type);

            if (getState) {
                getState.loading = LoadingStatus.Pending;
            }
        })
        .addCase(createOverviewItem.fulfilled, (state, action) => {
            const type = action.payload.type;
            const getState = getPatientOverviewFromState(state, type);
            if (getState) {
                getState.data = action.payload.data;
                getState.loading = LoadingStatus.Completed;
            }

            state.selectedOverviewItem = getCleanPatientOverview();
        })
        .addCase(createOverviewItem.rejected, (state, action) => {
            const type = action.meta.arg.type;
            const getState = getPatientOverviewFromState(state, type);
            if (getState) {
                getState.loading = LoadingStatus.Pending;
            }
        })
        .addCase(updateOverviewItem.pending, (state, action) => {
            const type = action.meta.arg.type;
            const getState = getPatientOverviewFromState(state, type);
            if (getState) {
                getState.loading = LoadingStatus.Pending;
            }
        })
        .addCase(updateOverviewItem.fulfilled, (state, action) => {
            const type = action.payload.type;
            const getState = getPatientOverviewFromState(state, type);
            if (getState) {
                getState.loading = LoadingStatus.Completed;
                if (getState.data && isEqual(getState.data, action.payload.data)) {
                    getState.data = { ...getState.data, _etag: action.payload.data._etag };
                } else if (getState.data) {
                    getState.data = action.payload.data;
                }
            }

            state.selectedOverviewItem = getCleanPatientOverview();
        })
        .addCase(updateOverviewItem.rejected, (state, action) => {
            const type = action.meta.arg.type;
            const getState = getPatientOverviewFromState(state, type);
            if (getState) {
                getState.loading = LoadingStatus.Pending;
            }
        })
        .addCase(fetchPatientOverviewTasks.pending, (state) => {
            state.patientOverviewTasks.loading = LoadingStatus.Pending;
        })
        .addCase(fetchPatientOverviewTasks.fulfilled, (state, action) => {
            const tasks = action.payload;
            state.patientOverviewTasks.data = tasks;
            state.patientOverviewTasks.loading = LoadingStatus.Completed;
        })
        .addCase(fetchPatientOverviewTasks.rejected, (state) => {
            state.patientOverviewTasks.loading = LoadingStatus.Failed;
        })
        .addCase(createPatientImmunization.pending, (state) => {
            state.immunizations.loading = LoadingStatus.Pending;
            state.immunizations.saving = LoadingStatus.Pending;
        })
        .addCase(createPatientImmunization.fulfilled, (state, action) => {
            state.immunizations.data = action.payload;
            state.immunizations.loading = LoadingStatus.Completed;
            state.immunizations.saving = LoadingStatus.Completed;
        })
        .addCase(createPatientImmunization.rejected, (state) => {
            state.immunizations.loading = LoadingStatus.Failed;
            state.immunizations.saving = LoadingStatus.Failed;
        })
        .addCase(updatePatientImmunizations.pending, (state) => {
            state.immunizations.loading = LoadingStatus.Pending;
            state.immunizations.saving = LoadingStatus.Pending;
        })
        .addCase(updatePatientImmunizations.fulfilled, (state, action) => {
            state.immunizations.data = action.payload;
            state.immunizations.loading = LoadingStatus.Completed;
            state.immunizations.saving = LoadingStatus.Completed;
        })
        .addCase(updatePatientImmunizations.rejected, (state) => {
            state.immunizations.loading = LoadingStatus.Failed;
            state.immunizations.saving = LoadingStatus.Failed;
        });
