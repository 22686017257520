import IForm, { FormName, FormStatus } from 'api/models/form';
import { useDispatch, useSelector } from 'react-redux';
import {
    cleanupSelectedForm,
    setBaseFormDataProp,
    setEditingCompletedForm,
    setFormFieldData,
} from 'state/slices/forms/forms.slice';
import { useCallback } from 'react';
import {
    createFormFromSelectedForm,
    getAllPatientForms,
    getPatientFormById,
    saveSelectedForm,
    setBaseFormDataPropAndSave,
    setFormFieldDataAndSave,
    setNewForm,
    setSelectedFormDataAndSave,
} from 'state/slices/forms/forms.actions';
import {
    currentlySelectedForm,
    formsAsList,
    patientFormsAsList,
    patientProblemsForm,
    patientProblemsFormValuesAsList,
    previousOfSelectedForm,
    selectForms,
} from 'state/slices/forms/forms.selectors';
import { usePatientId, useTenantId } from 'hooks';
import useEncounterId from 'hooks/useEncounterId';

function useForms() {
    const dispatch = useDispatch();
    const tenantId = useTenantId();
    const patientId = usePatientId();
    const encounterId = useEncounterId();

    const { data, loading, syncing, editingCompletedForm } = useSelector(selectForms);
    const _formsAsList = useSelector(formsAsList);
    const _patientFormsAsList = useSelector(patientFormsAsList);
    const _currentlySelectedForm = useSelector(currentlySelectedForm);
    const _previousOfSelectedForm = useSelector(previousOfSelectedForm);
    const _patientProblemsFormValuesAsList = useSelector(patientProblemsFormValuesAsList);
    const _patientProblemsForm = useSelector(patientProblemsForm);

    const _setNewForm = useCallback(
        (form: FormName) => {
            if (patientId) dispatch(setNewForm(form, patientId, encounterId));
        },
        [dispatch, patientId, encounterId],
    );

    const _setFormFieldData = useCallback(
        <T>(path: keyof T, value: any) => {
            dispatch(setFormFieldData({ path, value }));
        },
        [dispatch],
    );

    const _setSelectedFormDataAndSave = useCallback(
        (value: any) => {
            if (patientId && tenantId) dispatch(setSelectedFormDataAndSave(value, tenantId, patientId));
        },
        [dispatch, patientId, tenantId],
    );

    const _getAllPatientForms = useCallback(() => {
        if (patientId && tenantId) dispatch(getAllPatientForms({ tenantId: tenantId, patientId }));
    }, [dispatch, patientId, tenantId]);

    const _getPatientFormById = useCallback(
        (formId: string) => {
            if (patientId && tenantId) dispatch(getPatientFormById({ tenantId: tenantId, patientId, formId }));
        },
        [dispatch, patientId, tenantId],
    );

    const _setFormFieldDataAndSave = useCallback(
        (path: any, value: any) => {
            if (patientId && tenantId) dispatch(setFormFieldDataAndSave(path, value, tenantId, patientId));
        },
        [dispatch, patientId, tenantId],
    );

    const _createFormFromSelectedForm = useCallback(
        (status?: FormStatus, save?: boolean) => {
            if (encounterId && patientId) dispatch(createFormFromSelectedForm(tenantId, patientId, encounterId, status, save));
        },
        [dispatch, tenantId, patientId, encounterId],
    );

    const _setBaseFormDataProp = useCallback(
        (path: keyof IForm, value: any) => {
            dispatch(setBaseFormDataProp({ path, value }));
        },
        [dispatch],
    );

    const _setBaseFormDataPropAndSave = useCallback(
        (path: keyof IForm, value: any) => {
            if (patientId && tenantId) dispatch(setBaseFormDataPropAndSave(path, value, tenantId, patientId, encounterId));
        },
        [dispatch, patientId, tenantId, encounterId],
    );

    const _saveSelectedForm = useCallback(() => {
        if (patientId && tenantId) dispatch(saveSelectedForm(tenantId, patientId));
    }, [patientId, tenantId, dispatch]);

    const _cleanupSelectedForm = useCallback(() => {
        dispatch(cleanupSelectedForm());
    }, [dispatch]);

    const _setEditingCompletedForm = useCallback(
        (editing: boolean) => {
            dispatch(setEditingCompletedForm(editing));
        },
        [dispatch],
    );

    return {
        forms: data,
        loading,
        syncing,
        editingCompletedForm,
        formsAsList: _formsAsList,
        currentlySelectedForm: _currentlySelectedForm,
        previousOfSelectedForm: _previousOfSelectedForm,
        patientFormsAsList: _patientFormsAsList,
        patientProblemsFormValuesAsList: _patientProblemsFormValuesAsList,
        patientProblemsForm: _patientProblemsForm,

        setNewForm: _setNewForm,
        setFormFieldData: _setFormFieldData,
        cleanupSelectedForm: _cleanupSelectedForm,
        saveSelectedForm: _saveSelectedForm,
        setSelectedFormDataAndSave: _setSelectedFormDataAndSave,
        setFormFieldDataAndSave: _setFormFieldDataAndSave,
        setBaseFormDataPropAndSave: _setBaseFormDataPropAndSave,
        getPatientFormById: _getPatientFormById,
        getAllPatientForms: _getAllPatientForms,
        createFormFromSelectedForm: _createFormFromSelectedForm,
        setBaseFormDataProp: _setBaseFormDataProp,
        setEditingCompletedForm: _setEditingCompletedForm,
    };
}

export default useForms;
