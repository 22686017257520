import { DefaultButton, Dialog, DialogFooter, PrimaryButton } from '@fluentui/react';
import { IReportingCategory } from 'api/models/reporting-group.model';

type Props = {
    toggleHideDialog: () => void;
    deleteReportingGroupCategory: () => void;
    hideDialog: boolean;
    setDeleteReportingCategory: (category: IReportingCategory | undefined) => void;
};

function DeleteSelectedReportGroupCategoryDialog({
    toggleHideDialog,
    deleteReportingGroupCategory,
    hideDialog,
    setDeleteReportingCategory,
}: Props) {
    const _onDismiss = () => {
        setDeleteReportingCategory(undefined);
        toggleHideDialog();
    };

    return (
        <Dialog
            dialogContentProps={{
                title: 'Delete Reporting Group Category',
                subText: `Do you want to delete the selected reporting group category?`,
            }}
            hidden={hideDialog}
            onDismiss={_onDismiss}
        >
            <DialogFooter>
                <PrimaryButton text="Delete" onClick={deleteReportingGroupCategory} />
                <DefaultButton onClick={_onDismiss} text="Cancel" />
            </DialogFooter>
        </Dialog>
    );
}

export default DeleteSelectedReportGroupCategoryDialog;
