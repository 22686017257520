import { ICommonTransaction } from 'api/models/encounter-ledger.model';
import { IPaymentSource } from 'api/models/payment-source.model';
import { LoadingStatus } from 'interfaces/loading-statuses';

/**
 * Getting data for uac distribution:
 *
 * User will click a uac transaction.
 *
 * Push the id of the transaction into the url, and attempt to load it from the patient ledger.
 *
 * If the transaction is found:
 *
 * Load the payment source of the transaction into state.
 *
 * Otherwise:
 *
 * Push the user back to the TP/UAC screen.
 *
 */

/**
 * Saving distributed transactions:
 *
 * There will be a single endpoint for this.
 *
 * We will pass the transactions created and then also specify the specific uac we are affecting.
 *
 * What will be returned is the new payments and the payment source.
 * - If we are pushing the user back to the page, we probably don't even care about what is returned since the data will be reloaded.
 *s
 * Push the user back to the TP and UAC page on success.
 * If failed, then we can show a message.
 */

export type UACDistributionOpenSections = {
    patientBalanceOpen: boolean;
    patientEstimateOpen: boolean;
    treatmentPlanRemainingOpen: boolean;
};

export const uacDistributionInitOpenSections: UACDistributionOpenSections = {
    patientBalanceOpen: false,
    patientEstimateOpen: false,
    treatmentPlanRemainingOpen: false,
};

export type UACDistributionState = {
    saving: LoadingStatus;
    loading: LoadingStatus;
    paymentSource?: IPaymentSource;
    transaction?: ICommonTransaction;

    transactionToConvertId?: string;

    convertModalOpen?: boolean;

    openSections: UACDistributionOpenSections;
};

const uacDistributionState: UACDistributionState = {
    loading: LoadingStatus.Idle,
    saving: LoadingStatus.Idle,
    openSections: uacDistributionInitOpenSections,
};

export default uacDistributionState;
