import { createSlice } from '@reduxjs/toolkit';
import { communicationExtraReducers, communmicationReducers } from './communication.reducers';
import { initialState } from './communication.state';

const communicationSlice = createSlice({
    name: 'communication',
    initialState,
    reducers: communmicationReducers,
    extraReducers: (builder) => {
        communicationExtraReducers(builder);
    },
});

const { reducer, actions } = communicationSlice;

export const { cleanupEditCommunication, cleanupCommunications, setEditCommunication, editCommunicationChangeProp } = actions;

export default reducer;
