import { RootState } from 'state/store';
import { classicDateOnly } from 'utils/dateOnly';
import { convertFromMilitaryTime } from 'utils/convertFromMilitaryTime';
import { map } from 'lodash';

const EncounterReasons = ({ state }: { state: RootState }): JSX.Element | null => {
    const { patientEncounterAppointment: appointment } = state.encounter;

    if (!appointment || !appointment.encounterReason) return null;
    const _timeText = appointment && appointment.startTime ? convertFromMilitaryTime(appointment.startTime) : '';
    const _dateText = appointment && appointment.date ? classicDateOnly(appointment.date) : '';

    const treatingProviderId = state.encounter.patientEncounterAppointment?.treatingProviderId;
    const treatingProviders = map(state.tenant.providers.data, (p) => p);
    const treatingProvider = treatingProviders.find((provider) => provider?.id === treatingProviderId);

    return (
        <>
            <h3>Reason for Encounter</h3>
            <ul>
                <li>{appointment.encounterReason}</li>

                <li>
                    Encounter Date: {_timeText} - {_dateText}
                </li>
                {treatingProvider && (
                    <li>
                        Treating Provider:{" "}
                        {treatingProvider?.firstName} {treatingProvider?.lastName}
                        {treatingProvider?.suffix ? `, ${treatingProvider.suffix}` : ''}
                    </li>
                )}
            </ul>
        </>
    );
};

export default EncounterReasons;
