import { PayloadAction } from '@reduxjs/toolkit';
import { IReportingState } from 'state/slices/reporting/reporting.state';
import { FinancialTransactionDetailsReportQuery } from './model';

export const financialTransactionDetailsReportReducers = {
    toggleFinancialTransactionDetailsId: (
        state: IReportingState,
        action: PayloadAction<{ path: keyof FinancialTransactionDetailsReportQuery; id: string }>,
    ): void => {
        const { id, path } = action.payload;
        if (!(state.reportProperties as FinancialTransactionDetailsReportQuery)[path])
            ((state.reportProperties as FinancialTransactionDetailsReportQuery)[path] as unknown[]) = [];

        const currentIds = (state.reportProperties as FinancialTransactionDetailsReportQuery)[path] as string[];

        if (currentIds)
            if (currentIds?.includes(id)) {
                ((state.reportProperties as FinancialTransactionDetailsReportQuery)[path] as string[]) = currentIds.filter(
                    (_id) => _id !== id,
                );
            } else {
                ((state.reportProperties as FinancialTransactionDetailsReportQuery)[path] as string[]) = [...currentIds, id];
            }
    },
    setFinancialTransactionDetailsProp: (
        state: IReportingState,
        action: PayloadAction<{ path: keyof FinancialTransactionDetailsReportQuery; value: string | number | undefined }>,
    ): void => {
        const { path, value } = action.payload;
        ((state.reportProperties as FinancialTransactionDetailsReportQuery)[path] as string | number | undefined) = value;
    },
};
