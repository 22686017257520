import { INavLink, INavLinkGroup, MessageBar, SearchBox, Spinner, Stack, Text, useTheme } from '@fluentui/react';
import { ReportType } from 'api/models/embed-report.model';
import { ActivityPage } from 'components';
import ActivityBarMenu from 'components/ActivityBarMenu/ActivityBarMenu';
import TreeNav from 'components/TreeNav';
import { useTenantId } from 'hooks';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { RouteParams } from 'interfaces/route-params';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { getAllReports } from 'state/slices/reporting/reporting.actions';
import { selectFinancialReportsList, selectReportingState } from 'state/slices/reporting/reporting.selectors';
import { cleanupSelectedReportProperties } from 'state/slices/reporting/reporting.slice';
import * as ReportComponents from './components';
import ReportModal from './ReportModal';
import { cleanupPatientEncounter } from 'state/slices/encounter/encounter.slice';
import { useFuseSearch } from 'hooks/useFuseSearch';

function ReportingMenu(): JSX.Element {
    const { push } = useHistory();
    const { palette } = useTheme();
    const dispatch = useDispatch();
    const tenantId = useTenantId();

    const { loading } = useSelector(selectReportingState);
    const _selectFinancialReportsList = useSelector(selectFinancialReportsList);

    const { results, search, setSearch } = useFuseSearch({
        list: _selectFinancialReportsList,
        fuseOptions: { threshold: 0.1, keys: ['displayName'] },
    });

    const groups: INavLinkGroup[] = [
        {
            links: [
                ...(search ? results : _selectFinancialReportsList)
                    .filter((report) => report.key !== ReportType.ProviderProductivity)
                    .map(
                        (report) =>
                            ({
                                name: report.displayName ?? '',
                                url: `/${tenantId}/reporting/${report.key}`,
                                onClick: (ev) => {
                                    ev?.preventDefault();
                                    push(`/${tenantId}/reporting/${report.key}`);
                                    dispatch(cleanupSelectedReportProperties());
                                },
                            } as INavLink),
                    ),
            ],
        },
    ];

    return (
        <ActivityBarMenu canShrink={false} isExpanded={false}>
            <SearchBox
                placeholder="Search for reports"
                value={search}
                onClear={() => {
                    setSearch('');
                }}
                onChange={(ev, value) => {
                    setSearch(value ?? '');
                }}
            />
            {search && !results.length && <MessageBar>No reports found from search.</MessageBar>}
            {loading === LoadingStatus.Pending ? (
                <Spinner label="Loading..." />
            ) : (
                <TreeNav
                    styles={{
                        link: {
                            padding: 0,
                            paddingLeft: 5,
                            whiteSpace: 'wrap',
                            lineHeight: 17,
                            color: palette.black,
                            ':hover': {
                                color: palette.black,
                            },
                        },
                        root: {
                            overflowY: 'auto',

                            height: '90vh',
                        },
                    }}
                    groups={groups}
                />
            )}
        </ActivityBarMenu>
    );
}

export default function Reporting(): JSX.Element {
    const dispatch = useDispatch();
    const { push } = useHistory();

    const { loading } = useSelector(selectReportingState);
    const _selectFinancialReportsList = useSelector(selectFinancialReportsList);
    const tenantId = useTenantId();
    // const {reportKey} = useParams<RouteParams>()
    const match = useRouteMatch<RouteParams>({ path: '/:tenantId/reporting/:reportKey', exact: true });

    useEffect(() => {
        dispatch(cleanupPatientEncounter());
        dispatch(getAllReports({ tenantId }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (loading === LoadingStatus.Completed && _selectFinancialReportsList.length && !match?.params.reportKey) {
            push(`/${tenantId}/reporting/${_selectFinancialReportsList[0].key ?? ''}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    const Component = (ReportComponents as { [key: string]: () => JSX.Element })[match?.params.reportKey as string];

    //get the display name of the matching report
    const displayName = _selectFinancialReportsList.find((report) => report.key === match?.params.reportKey)?.displayName;

    return (
        <>
            <ReportModal />
            <Stack horizontal grow>
                <ReportingMenu />
                <ActivityPage title={'Reporting'} hasPageHeader hideFooter>
                    <Stack style={{ padding: 5 }} tokens={{ childrenGap: 10 }}>
                        <Text variant="xLarge">{displayName}</Text>
                        <Stack grow>
                            {loading === LoadingStatus.Completed ? Component ? <Component /> : null : <Spinner label="Loading" />}
                        </Stack>
                    </Stack>
                </ActivityPage>
            </Stack>
        </>
    );
}
