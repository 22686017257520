import {
    Stack,
    MessageBar,
    SelectionMode,
    DetailsRow,
    useTheme,
    IDetailsRowProps,
    ConstrainMode,
    TooltipHost,
    Link,
} from '@fluentui/react';
import { IEncounterSummary } from 'api/models/encounter-ledger.model';
import ExpandableDetailsList from 'components/ExpandableDetailsList/ExpandableDetailsList';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import { useSelector } from 'hooks';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { RouteParams } from 'interfaces/route-params';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getLedgerTotalsView } from 'state/slices/ledger/ledger.actions';
import {
    getLedgerLineItemTotals,
    selectFilteredEncounterLedgerTotalsView,
    selectEncounterLedgerViewLoading,
    selectEncounterLedgerViewLoadingStatus,
} from 'state/slices/ledger/ledger.selectors';
import { usdCurrencyFormatter } from 'utils';
import { classicDateOnly } from 'utils/dateOnly';
import EncounterTransactionList from './EncounterTransactionList';
import { getIsLastIndex, onRenderColumn } from '../LedgerUtils';
import { encounterStatusText } from 'api/models/encounter.model';
import { useAppDispatch } from 'hooks/useAppDispatch';

type EncounterClickFunction = (ev: React.MouseEvent | undefined, item: IEncounterSummary | undefined) => void;

/**
 * @return {*}  {(JSX.Element | null)}
 */
export const getEncounterSummaryColumns = (
    items: IEncounterSummary[],
    onEncounterClick?: EncounterClickFunction,
): ISortableColumn<IEncounterSummary>[] => [
        {
            key: 'encounterNumber',
            fieldName: 'encounterNumber',
            minWidth: 40,
            maxWidth: 50,
            name: 'Enc#',
            onRender: (item) => {
                const text = `${item?.encounterNumber ?? ''}`;
                return (
                    <TooltipHost
                        delay={0}
                        content={
                            item?.encounterStatus || item?.claimStatus ? (
                                <Stack verticalAlign="center" tokens={{ childrenGap: 5 }} >
                                    <Stack>
                                        <Stack>
                                            <b>Approval Status:</b>
                                            <span>{item?.encounterStatus ? encounterStatusText[item.encounterStatus] : 'None'}</span>
                                        </Stack>
                                        <Stack>
                                            <b>Claim Status:</b>
                                            <span>{item?.claimStatus ?? 'None'}</span>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            ) : undefined
                        }
                    >
                        <Stack horizontal verticalAlign="center">
                            {onEncounterClick ? (
                                <Link onClick={(ev) => onEncounterClick(ev, item)}>{text}</Link>
                            ) : (
                                <span>{text}</span>
                            )}

                        </Stack>
                    </TooltipHost>
                );
            },
        },
        {
            key: 'dos',
            fieldName: 'dateOfService',
            minWidth: 100,
            maxWidth: 100,
            name: 'Enc. Date',
            onRender: (item, index, col) => {
                if (item?.dateOfService)
                    return onRenderColumn(classicDateOnly(item.dateOfService), items.length, index, undefined, col?.key);
            },
        },
        {
            key: 'locationOfCare',
            fieldName: 'locationOfCare',
            minWidth: 100,
            maxWidth: 150,
            name: 'Location of Care',
            onRender: (item, index, col) => onRenderColumn(item?.locationOfCare ?? '', items.length, index, undefined, col?.key),
        },
        {
            key: 'provider',
            fieldName: 'provider',
            minWidth: 100,
            maxWidth: 150,
            name: 'Provider',
            getValueKey: (item) => {
                const lastName = item?.provider?.split(' ')[1];
                return lastName ?? '';
            },
            onRender: (item, index, col) => {
                if (item?.provider) {
                    if (item.provider === 'Totals:') {
                        return onRenderColumn(item.provider, items.length, index, undefined, col?.key);
                    }
                    const nameParts = item.provider.split(' ');
                    const lastName = nameParts.pop(); // Remove and store the last name
                    const firstName = nameParts.shift(); // Remove and store the first name
                    let professionalSuffix = nameParts.join(' '); // The remaining parts are considered the suffix

                    if (professionalSuffix.endsWith(',')) {
                        professionalSuffix = professionalSuffix.slice(0, -1); // Remove trailing comma
                        const formattedName = `${lastName} ${professionalSuffix}, ${firstName}`;
                        return onRenderColumn(formattedName, items.length, index, undefined, col?.key);
                    } else {
                        const formattedName = `${lastName}${professionalSuffix}, ${firstName}`;
                        return onRenderColumn(formattedName, items.length, index, undefined, col?.key);
                    }
                }
                return ''; // Handle cases where the provider name is not formatted as expected
            },
        },

        {
            key: 'totalCharges',
            fieldName: 'totalCharges',
            minWidth: 100,
            maxWidth: 200,
            name: 'Total Charges',
            onRender: (item, index, col) =>
                onRenderColumn(usdCurrencyFormatter.format(item?.totalCharges ?? 0), items.length, index, undefined, col?.key),
        },
        {
            key: 'insurancePayments',
            fieldName: 'insurancePayments',
            minWidth: 100,
            maxWidth: 200,
            name: 'Insurance Paid',
            onRender: (item, index, col) =>
                onRenderColumn(usdCurrencyFormatter.format(item?.insurancePayments ?? 0), items.length, index, undefined, col?.key),
        },
        {
            key: 'insuranceAdjustements',
            fieldName: 'insuranceAdjustements',
            minWidth: 100,
            maxWidth: 200,
            name: 'Insurance Adjustments',
            onRender: (item, index, col) =>
                onRenderColumn(
                    usdCurrencyFormatter.format(item?.insuranceAdjustments ?? 0),
                    items.length,
                    index,
                    undefined,
                    col?.key,
                ),
        },
        {
            key: 'patientPayments',
            fieldName: 'patientPayments',
            minWidth: 120,
            maxWidth: 200,
            onRender: (item, index, col) =>
                onRenderColumn(usdCurrencyFormatter.format(item?.patientPayments ?? 0), items.length, index, undefined, col?.key),
            name: 'Pt. Applied Pymts.',
        },
        {
            key: 'patientAdjustments',
            fieldName: 'patientAdjustments',
            minWidth: 100,
            maxWidth: 200,
            onRender: (item, index, col) =>
                onRenderColumn(usdCurrencyFormatter.format(item?.patientAdjustments ?? 0), items.length, index, undefined, col?.key),
            name: 'Patient Adjustments',
        },
        {
            key: 'insuranceBalance',
            fieldName: 'insuranceBalance',
            minWidth: 100,
            maxWidth: 200,
            name: 'Insurance Balance',
            onRender: (item, index, col) =>
                onRenderColumn(usdCurrencyFormatter.format(item?.insuranceBalance ?? 0), items.length, index, undefined, col?.key),
        },
        {
            key: 'patientBalance',
            fieldName: 'patientBalance',
            minWidth: 100,
            maxWidth: 200,
            onRender: (item, index, col) =>
                onRenderColumn(usdCurrencyFormatter.format(item?.patientBalance ?? 0), items.length, index, undefined, col?.key),
            name: 'Patient Balance',
        },
        {
            key: 'totalBalance',
            fieldName: 'totalBalance',
            minWidth: 100,
            maxWidth: 200,
            name: 'Total Balance',
            onRender: (item, index, col) =>
                onRenderColumn(usdCurrencyFormatter.format(item?.totalBalance ?? 0), items.length, index, undefined, col?.key),
        },
        {
            key: 'patientReservePayments',
            fieldName: 'patientReservePayments',
            minWidth: 120,
            maxWidth: 200,
            name: 'Pt. Reserve Pymts.',
            onRender: (item, index, col) =>
                onRenderColumn(
                    usdCurrencyFormatter.format(Math.abs(item?.patientReservePayments ?? 0)),
                    items.length,
                    index,
                    undefined,
                    col?.key,
                ),
        },
    ];

export const EncounterLedgerTotalsRow = (props: { items: IEncounterSummary[]; showTotals?: boolean } & IDetailsRowProps) => {
    const { palette } = useTheme();
    const { items, showTotals } = props;
    return props ? (
        <DetailsRow
            {...props}
            styles={
                getIsLastIndex(items.length, props.itemIndex) && showTotals
                    ? {
                        root: {
                            background: palette.neutralQuaternaryAlt,
                            borderTop: `1px solid ${palette.neutralLighterAlt}`,
                        },
                    }
                    : props.styles
            }
        />
    ) : null;
};

type Props = {
    views: IEncounterSummary[];
    onEncounterClick?: EncounterClickFunction;
    showTotals?: boolean;
    fetchTotalsView?: boolean;
    constrainMode?: ConstrainMode;
};

export default function EncounterLedger({
    views,
    onEncounterClick,
    showTotals = true,
    fetchTotalsView = true,
    constrainMode = ConstrainMode.unconstrained,
}: Props): JSX.Element {
    const dispatch = useAppDispatch();
    const { tenantId, patientId } = useParams<RouteParams>();

    const _selectEncounterLedgerTotalsView = useSelector(selectFilteredEncounterLedgerTotalsView);

    const loading = useSelector(selectEncounterLedgerViewLoading);
    const loadingStatus = useSelector(selectEncounterLedgerViewLoadingStatus);

    useEffect(() => {
        if (!fetchTotalsView || loadingStatus !== LoadingStatus.Completed) return;

        const _getLedgerTotalsView = dispatch(getLedgerTotalsView({ patientId, tenantId }));

        return () => {
            _getLedgerTotalsView.abort('CancelGetLedgerTotalsViewPageUnloaded');
        };
    }, [patientId, tenantId, dispatch]);

    //Better to use callback, than to regenerate the items on every render...
    const getItems = useCallback(() => {
        const items = [...views];
        if (showTotals) {
            const {
                insuranceBalance,
                insuranceAdjustments,
                insurancePayments,
                patientPayments,
                patientAdjustments,
                patientBalance,
                totalBalance,
                totalCharges,
                patientReservePayments,
            } = getLedgerLineItemTotals(items);

            items.push({
                provider: 'Totals:',
                dateOfService: '',
                insuranceAdjustments,
                insuranceBalance,
                insurancePayments,
                patientAdjustments,
                patientBalance,
                patientPayments,
                totalBalance,
                totalCharges,

                //Not being shown
                insuranceCharges: 0,
                patientCharges: 0,
                totalEstimate: 0,
                totalCommonPatientFee: 0,
                patientEstimate: 0,
                patientReservePayments,
            });
        }

        return items;
    }, [_selectEncounterLedgerTotalsView, views, showTotals]);

    const items = getItems();

    if (!loading && !_selectEncounterLedgerTotalsView.length)
        return <MessageBar>There are no encounters with completed billing procedures.</MessageBar>;
    if (!loading && _selectEncounterLedgerTotalsView.length && !views.length)
        return <MessageBar>There are no encounters that match the search/filter criteria.</MessageBar>;

    return (
        <ExpandableDetailsList<IEncounterSummary>
            items={items}
            selectionMode={SelectionMode.none}
            constrainMode={constrainMode}
            showGrid
            compact
            onRenderExpandedRowContent={(props) => {
                if (props) {
                    return <EncounterTransactionList encounterId={props.item.encounterId} />;
                }
                return null;
            }}
            stickyHeader
            canSortRow={(item, index) => !getIsLastIndex(items.length, index) && showTotals}
            canExpandRow={(item) => !!item.encounterId}
            sortColumns={['dos']}
            sortOnMount
            enableShimmer={loading}
            initialSortDirection={['asc']}
            onRenderRow={(props) => {
                if (props) return <EncounterLedgerTotalsRow {...props} showTotals={showTotals} items={getItems()} />;
                return null;
            }}
            onItemInvoked={(item) => {
                if (onEncounterClick) onEncounterClick(undefined, item);
            }}
            columns={getEncounterSummaryColumns(items, onEncounterClick)}
        />
    );
}

//https://localhost:3000/4a318e3e/patient/66c1d005-54a0-4052-9af8-bc47c999e64d/ledger
