import { Tutorial } from '../teaching.state';

const imagingSourceTutorial: Tutorial = {
    One: {
        headline: "Let's get started with imaging bridge!",
        target: '#ImagingSourceDownload',
        isWide: true,
        hasCloseButton: true,
        pages: 4,
    },
};

export default imagingSourceTutorial;
