import { DocumentBase } from 'api/document-base.model';
import { EncounterSupport, IdSupport } from './support-types';

export enum VitalNameType {
    Systolic = 'VITALS.BLOODPRESSURE.SYSTOLIC',
    Diastolic = 'VITALS.BLOODPRESSURE.DIASTOLIC',
    Position = 'VITALS.BLOODPRESSURE.POSITION',
    Site = 'VITALS.BLOODPRESSURE.SITE',
    Cuff = 'VITALS.BLOODPRESSURE.CUFF',
    Refused = 'VITALS.BLOODPRESSURE.REFUSED',
    RefusedReason = 'VITALS.BLOODPRESSURE.REFUSEDREASON',

    HeightType = 'VITALS.HEIGHT.TYPE',
    Height = 'VITALS.HEIGHT',
    HeightInches = 'VITALS.HEIGHT.INCHES',
    HeightRefused = 'VITALS.HEIGHT.REFUSED',
    HeightRefusedReason = 'VITALS.HEIGHT.REFUSEDREASON',

    WeightType = 'VITALS.WEIGHT.TYPE',
    Weight = 'VITALS.WEIGHT',

    WeightRefused = 'VITALS.WEIGHT.REFUSED',
    WeightRefusedReason = 'VITALS.WEIGHT.REFUSEDREASON',
    OutOfRange = 'VITALS.WEIGHT.OUTOFRANGE',

    PulseType = 'VITALS.PULSE.TYPE',
    PulseRate = 'VITALS.PULSE.RATE',
    PulseLocation = 'VITALS.PULSE.LOCATION',

    TemperatureType = 'VITALS.TEMPERATURE.TYPE',
    Temperature = 'VITALS.TEMPERATURE',

    RespirationRate = 'VITALS.RESPIRATIONRATE',

    Saturation = 'VITALS.O2SATURATION',
    SaturationAirtype = 'VITALS.O2SATURATION.AIRTYPE',
}

export const abbreviationLookup: { [key: string]: string } = {
    [VitalNameType.Height]: 'Ht',
    [VitalNameType.HeightInches]: 'Ht',
    [VitalNameType.HeightType]: 'Ht',
    [VitalNameType.HeightRefusedReason]: 'Ht',
    [VitalNameType.HeightRefused]: 'Ht',

    [VitalNameType.Weight]: 'Wt',
    [VitalNameType.WeightRefused]: 'Wt',
    [VitalNameType.WeightType]: 'Wt',
    [VitalNameType.WeightRefusedReason]: 'Wt',
    [VitalNameType.OutOfRange]: 'Wt',

    [VitalNameType.SaturationAirtype]: 'O2Sat',
    [VitalNameType.Saturation]: 'O2Sat',

    [VitalNameType.PulseRate]: 'Pulse',
    [VitalNameType.PulseLocation]: 'Pulse',
    [VitalNameType.PulseType]: 'Pulse',

    [VitalNameType.Temperature]: 'T',
    [VitalNameType.TemperatureType]: 'T',

    [VitalNameType.RespirationRate]: 'RR',
};

export const vitalNameTypeDisplayName: { [key in VitalNameType]: string } = {
    [VitalNameType.Systolic]: 'Systolic',
    [VitalNameType.Diastolic]: 'Diastolic',
    [VitalNameType.Position]: 'Position',
    [VitalNameType.Site]: 'Site',
    [VitalNameType.Cuff]: 'Cuff',
    [VitalNameType.Refused]: 'Refused',
    [VitalNameType.RefusedReason]: 'Refused Reason',
    [VitalNameType.HeightType]: 'Height Type',
    [VitalNameType.Height]: 'Height',
    [VitalNameType.HeightInches]: 'Height Inches',
    [VitalNameType.HeightRefused]: 'Height Refused',
    [VitalNameType.HeightRefusedReason]: 'Height Refused Reason',
    [VitalNameType.WeightType]: 'Weight Type',
    [VitalNameType.Weight]: 'Weight',

    [VitalNameType.WeightRefused]: 'Weight Refused',
    [VitalNameType.WeightRefusedReason]: 'Weight Refused Reason',
    [VitalNameType.OutOfRange]: 'Out of Range',
    [VitalNameType.PulseType]: 'Pulse Type',
    [VitalNameType.PulseRate]: 'Pulse Rate',
    [VitalNameType.PulseLocation]: 'Pulse Location',
    [VitalNameType.TemperatureType]: 'Temperature Type',
    [VitalNameType.Temperature]: 'Temperature',
    [VitalNameType.RespirationRate]: 'Respiration Rate',
    [VitalNameType.Saturation]: 'O2 Saturation',
    [VitalNameType.SaturationAirtype]: 'O2 Saturation Air Type',
};

export type VitalNameTypes = (typeof VitalNameType)[keyof typeof VitalNameType];

export enum VitalType {
    Height = 'Ht',
    Weight = 'Wt',
    BloodPressure = 'BP',
    Pulse = 'Pulse',
    Tempurature = 'T',
    RespiratoryRate = 'RR',
    OxygenSaturation = '02Sat',
}
export type VitalTypes = (typeof VitalType)[keyof typeof VitalType];

type PatientVitalReadingSupports = EncounterSupport;

interface IPatientVitals extends DocumentBase {
    references?: Record<string, string>;
    readings?: IVitalReading[];
}
export interface IVitalReading extends PatientVitalReadingSupports {
    // new props that were added after schema update
    id?: string;
    encounterId?: string;
    readingId?: string;
    abbreviation?: VitalTypes;
    vitalName?: VitalNameTypes;
    key?: string;
    readingTaken?: string;
    source?: string;
    client?: string;
    value?: string;
    unit?: string;
    notPerformed?: boolean;
    notPerformedReason?: string;
    outOfRange?: boolean;
    percentileDetails?: IPercentileDetails;
    locationOfCareId: string;
    sourceEncounterId?: string;
    appointmentStartTime?: string;
}

export interface IPercentileDetails {
    high: number;
    highOrdinal?: string;
    low: number;
    lowOrdinal?: string;
    prefix?: string;
}

export default IPatientVitals;
