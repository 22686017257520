import { Checkbox, Icon, IconButton, Link, Separator, Stack, Text, TextField, TooltipHost } from '@fluentui/react';
import { VitalNameType } from 'api/models/patient-vital.model';
import { Field, SubSection } from 'components';
import { useSelector } from 'hooks';
import { useDispatch } from 'react-redux';
import {
    addBloodPressureReading,
    bpRefuesed,
    removeBloodPressure,
    updateBloodPressure,
} from 'state/slices/patient/patient.slice';
import { BPNameTypes } from 'state/slices/patient/vitals/vitals.reducers';
import { BloodPressureReading } from 'state/slices/patient/vitals/vitals.state';

function BPVitals(): JSX.Element {
    const dispatch = useDispatch();
    const bpReadings = useSelector((state) => state.patient.vitals.vitalPanelData.BP);
    const hasRefused = !!bpReadings.some((reading) => reading[VitalNameType.Refused] === true);
    const bpItems = bpReadings.map((reading, index) => <BpVitalsProperties bpReading={reading} index={index} key={index} />);
    const addBloodPressure = () => {
        dispatch(addBloodPressureReading());
    };
    return (
        <SubSection
            title="Blood Pressure"
            headingCenterContent={
                <Stack horizontal>
                    <TooltipHost content="Add Blood Pressure">
                        <IconButton iconProps={{ iconName: 'Add' }} onClick={addBloodPressure} disabled={hasRefused} />
                    </TooltipHost>
                </Stack>
            }
        >
            <Stack tokens={{ childrenGap: 35 }}>{bpItems}</Stack>
        </SubSection>
    );
}

export default BPVitals;

type Props = { bpReading: BloodPressureReading; index: number };
function BpVitalsProperties({ bpReading, index }: Props): JSX.Element {
    const dispatch = useDispatch();

    const systolic = bpReading[VitalNameType.Systolic];
    const diastolic = bpReading[VitalNameType.Diastolic];
    const cuffSize = bpReading[VitalNameType.Cuff];
    const position = bpReading[VitalNameType.Position];
    const site = bpReading[VitalNameType.Site];
    const refusedReason = bpReading[VitalNameType.RefusedReason];
    const bpRefused = bpReading[VitalNameType.Refused];

    const handleOnChange = (vitalType: BPNameTypes, value?: string | boolean): void => {
        dispatch(updateBloodPressure({ vitalType, index, value: value ?? '' }));
    };

    const isChecked = !!bpRefused;

    const handleRefused = (ev?: React.FormEvent, checked?: boolean) => {
        dispatch(bpRefuesed({ checked: checked ? true : false, index }));
    };

    const handleRemoveBPReading = () => {
        dispatch(removeBloodPressure(index));
    };

    const bpOptions1 = [
        {
            key: 'Sitting',
            text: 'Sitting',
        },
        {
            key: 'Standing',
            text: 'Standing',
        },
        {
            key: 'Supine',
            text: 'Supine',
        },
        {
            key: 'Lying on Side',
            text: 'Lying on Side',
        },
        {
            key: 'Prone',
            text: 'Prone',
        },
        {
            key: 'None',
            text: 'None',
        },
    ];

    const bpOptions2 = [
        {
            key: 'L arm',
            text: 'L arm',
        },
        {
            key: 'R arm',
            text: 'R arm',
        },
        {
            key: 'L leg',
            text: 'L Leg',
        },
        {
            key: 'R leg',
            text: 'R leg',
        },
        {
            key: 'L wrist',
            text: 'L wrist',
        },
        {
            key: 'R wrist',
            text: 'R wrist',
        },
        {
            key: 'None',
            text: 'None',
        },
    ];

    const bpOptions3 = [
        {
            key: 'small pediatric',
            text: 'small pediatric',
        },
        {
            key: 'pediatric',
            text: 'pediatric',
        },
        {
            key: 'small adult',
            text: 'small adult',
        },
        {
            key: 'adult',
            text: 'adult',
        },
        {
            key: 'large adult',
            text: 'large adult',
        },
        {
            key: 'child thigh',
            text: 'child thigh',
        },
        {
            key: 'adult thigh',
            text: 'adult thigh',
        },
        {
            key: 'None',
            text: 'None',
        },
    ];
    const performedOptions = [
        {
            key: 'Not Indicated',
            text: 'Not Indicated',
        },
        {
            key: 'Not Tolerated',
            text: 'Not Tolerated',
        },
        {
            key: 'Patient Refused',
            text: 'Patient Refused',
        },
    ];
    const indexGreaterThan0 = index > 0;
    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <Stack tokens={{ childrenGap: 10 }} horizontal verticalAlign="center" grow>
                <Stack.Item>
                    <TextField
                        label=""
                        autoComplete="off"
                        placeholder="Systolic"
                        value={systolic}
                        disabled={isChecked}
                        maxLength={3}
                        onChange={(ev, value) => handleOnChange(VitalNameType.Systolic, value)}
                    />
                </Stack.Item>
                <Text>/</Text>
                <Stack.Item>
                    <TextField
                        label=""
                        autoComplete="off"
                        placeholder="Diastolic"
                        value={diastolic}
                        disabled={isChecked}
                        maxLength={3}
                        onChange={(ev, value) => handleOnChange(VitalNameType.Diastolic, value)}
                    />
                </Stack.Item>
                {indexGreaterThan0 && (
                    <Stack grow horizontalAlign="end" verticalAlign="center">
                        <Link onClick={handleRemoveBPReading}>
                            <Icon iconName="trash" />
                        </Link>
                    </Stack>
                )}
            </Stack>
            <Stack tokens={{ childrenGap: 10 }} horizontal grow>
                <Stack.Item grow>
                    <Field.Dropdown
                        options={bpOptions1}
                        label=""
                        placeholder="BP Position"
                        selectedKey={position}
                        disabled={isChecked}
                        onChange={(event, option, index) =>
                            option && handleOnChange(VitalNameType.Position, option.key as string)
                        }
                    />
                </Stack.Item>
                <Stack.Item grow>
                    <Field.Dropdown
                        options={bpOptions2}
                        label=""
                        selectedKey={site}
                        disabled={isChecked}
                        placeholder="BP Site"
                        onChange={(event, option, index) => option && handleOnChange(VitalNameType.Site, option.key as string)}
                    />
                </Stack.Item>
                <Stack.Item grow>
                    <Field.Dropdown
                        options={bpOptions3}
                        label=""
                        selectedKey={cuffSize}
                        disabled={isChecked}
                        onChange={(event, option, index) => option && handleOnChange(VitalNameType.Cuff, option.key as string)}
                    />
                </Stack.Item>
            </Stack>

            <Stack tokens={{ childrenGap: 10 }} horizontal verticalAlign="end" grow>
                <Stack.Item>
                    <Checkbox label="Not Performed" checked={isChecked} onChange={handleRefused} />
                </Stack.Item>
                {isChecked && (
                    <Stack.Item grow>
                        <Field.Dropdown
                            options={performedOptions}
                            label=""
                            // disabled={!isChecked}
                            selectedKey={refusedReason}
                            placeholder="Select reason"
                            onChange={(event, option, index) =>
                                option && handleOnChange(VitalNameType.RefusedReason, option.key as string)
                            }
                        />
                    </Stack.Item>
                )}
            </Stack>
        </Stack>
    );
}
