import { IContextualMenuProps, Stack, DefaultEffects, Icon, ContextualMenu, Link, useTheme } from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import React, { useCallback, useState } from 'react';

export function IconContextualMenu(props: IContextualMenuProps): JSX.Element {
    const linkRef = React.useRef(null);
    const [showContextualMenu, setShowContextualMenu] = useState(false);
    const onShowContextualMenu = useCallback((ev: React.MouseEvent<HTMLElement>) => {
        ev.preventDefault();
        setShowContextualMenu(true);
    }, []);
    const onHideContextualMenu = useCallback(() => setShowContextualMenu(false), []);
    const [isHovered, { toggle }] = useBoolean(false);
    const { semanticColors } = useTheme();
    return (
        <Stack horizontalAlign="center">
            <Link
                ref={linkRef}
                onClick={onShowContextualMenu}
                onMouseEnter={toggle}
                onMouseLeave={toggle}
                style={{
                    height: 32,
                    width: 32,
                    textAlign: 'center',
                    borderRadius: DefaultEffects.roundedCorner2,
                    backgroundColor: isHovered || showContextualMenu ? semanticColors.primaryButtonBackground : 'transparent',
                    color:
                        isHovered || showContextualMenu
                            ? semanticColors.primaryButtonText
                            : semanticColors.primaryButtonBackground,
                }}
            >
                <Icon iconName="MoreVertical" />
            </Link>
            <ContextualMenu
                hidden={!showContextualMenu}
                {...props}
                target={linkRef}
                onItemClick={onHideContextualMenu}
                onDismiss={onHideContextualMenu}
            />
        </Stack>
    );
}
