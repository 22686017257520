import { Stack, ComboBox, useTheme, IComboBoxOption, IconButton, SearchBox, DefaultButton } from '@fluentui/react';
import { uniq, uniqBy } from 'lodash';
import { chartActions } from 'state/slices/charting/chart/chart.selectors';
import {
    cleanupChartActionFilters,
    setChartActionFilterDates,
    setChartActionFilterProvider,
    setChartActionFilterStatus,
    setChartActionFilterTeeth,
    setChartActionFilterType,
} from 'state/slices/charting/chart/chart.slice';
import { createSelector } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { IChartAction } from 'state/slices/charting/chartActionsList.pipeline';
import {
    selectChartActionFilterDates,
    selectChartActionFilterProvider,
    selectChartActionFilterStatus,
    selectChartActionFilterTeeth,
    selectChartActionFilterType,
    selectChartActionFilterVisibile,
} from './selectors';
import { selectUserIdentitiesData } from 'state/slices/users-identities/user-identities.selectors';
import { Field } from 'components';
import { getTeethDisplayName } from 'utils';
import { getToothDisplayName } from 'utils/getToothId';

function ChartActionFilters() {
    const { palette, effects } = useTheme();
    const dispatch = useDispatch();
    const filtersVisible = useSelector(selectChartActionFilterVisibile);

    if (!filtersVisible) return null;

    return (
        <Stack
            className={filtersVisible ? 'ms-motion-slideDownIn' : 'ms-motion-slideUpOut'}
            style={{
                width: '100%',
                background: palette.neutralLighterAlt,
                padding: 10,
                borderBottom: `1px solid ${palette.neutralLight}`,
                boxShadow: `${effects.elevation16} inset`,
            }}
        >
            <Stack horizontal tokens={{ childrenGap: 10 }}>
                <ChartActionDates />
                <ChartActionStatuses />
                <ChartActionTypes />
                <ChartActionProviders />
                <ChartActionTeeth />
                <DefaultButton
                    onClick={() => dispatch(cleanupChartActionFilters())}
                    text="Clear all"
                    iconProps={{ iconName: 'Clear' }}
                    title="Clear filters"
                />
            </Stack>
        </Stack>
    );
}

export default ChartActionFilters;

const selectActiveChartActionTypeOptions = createSelector(chartActions, (chartActions) => {
    const typeOptions: IComboBoxOption[] = uniqBy<IChartAction>(
        chartActions.filter((a) => a.procedureType !== undefined),
        'procedureType',
    ).map((action) => ({
        key: action.procedureType as string,
        text: action.procedureType as string,
    }));

    if (chartActions.some((action) => action.actionType === 'Condition')) {
        const conditionType: IComboBoxOption = { key: 'Condition', text: 'Condition' };
        return [...typeOptions, conditionType];
    }

    return typeOptions;
});

const ChartActionTypes = () => {
    const dispatch = useDispatch();

    const chartActionTypeOptions = useSelector(selectActiveChartActionTypeOptions);
    const chartActionFilterType = useSelector(selectChartActionFilterType);

    const _onChange = (_: unknown, option?: IComboBoxOption) => {
        if (option) {
            dispatch(setChartActionFilterType(option.key as string));
        }
    };
    return (
        <ComboBox
            options={chartActionTypeOptions}
            placeholder="Types: All"
            selectedKey={chartActionFilterType}
            useComboBoxAsMenuWidth
            multiSelect
            allowFreeform={false}
            autoComplete="off"
            onChange={_onChange}
        />
    );
};

const selectActiveChartActionStatusOptions = createSelector(chartActions, (chartActions) => {
    const statusOptions: IComboBoxOption[] = uniqBy<IChartAction>(
        chartActions.filter((a) => a.status !== undefined),
        'status',
    )
        .map((action) => ({
            key: action.status as string,
            text: action.status as string,
        }))
        .sort((a, b) => a.text.localeCompare(b.text));
    return statusOptions;
});

const ChartActionStatuses = () => {
    const dispatch = useDispatch();

    const chartActionStatusOptions = useSelector(selectActiveChartActionStatusOptions);
    const chartActionFilterStatus = useSelector(selectChartActionFilterStatus);

    const _onChange = (_: unknown, option?: IComboBoxOption, index?: number, value?: string) => {
        if (option) {
            dispatch(setChartActionFilterStatus(option.key as string));
        }
    };
    return (
        <ComboBox
            options={chartActionStatusOptions}
            placeholder="Statuses: All"
            selectedKey={chartActionFilterStatus}
            useComboBoxAsMenuWidth
            multiSelect
            allowFreeform={false}
            autoComplete="off"
            onChange={_onChange}
        />
    );
};

const selectActiveChartActionProviderOptions = createSelector(
    chartActions,
    selectUserIdentitiesData,
    (chartActions, identities) => {
        const providerOptions: IComboBoxOption[] = uniqBy<IChartAction>(
            chartActions.filter((a) => a.treatingProviderId !== undefined),
            'treatingProviderId',
        )
            .map((action) => {
                const user = action.treatingProviderId ? identities[action.treatingProviderId] : undefined;

                const suffix = user && user.professionalSuffix ? ` ${user.professionalSuffix}` : '';
                const userName = user ? `${user.lastName}${suffix}, ${user.firstName}` : 'N/A';
                return {
                    key: action.treatingProviderId as string,
                    text: userName,
                };
            })
            .sort((a, b) => a.text.localeCompare(b.text));
        return providerOptions;
    },
);
const ChartActionProviders = () => {
    const dispatch = useDispatch();

    const chartActionProviderOptions = useSelector(selectActiveChartActionProviderOptions);
    const chartActionFilterProvider = useSelector(selectChartActionFilterProvider);

    const _onChange = (_: unknown, option?: IComboBoxOption) => {
        if (option) {
            dispatch(setChartActionFilterProvider(option.key as string));
        }
    };

    return (
        <>
            <ComboBox
                options={chartActionProviderOptions}
                placeholder="Providers: All"
                selectedKey={chartActionFilterProvider}
                multiSelect
                allowFreeform={false}
                autoComplete="off"
                onChange={_onChange}
            />
        </>
    );
};

const ChartActionDates = () => {
    const dispatch = useDispatch();
    const { startDate, endDate } = useSelector(selectChartActionFilterDates);
    const setStartDate = (_: unknown, value?: string) => {
        dispatch(setChartActionFilterDates({ path: 'startDate', value }));
    };
    const setEndDate = (_: unknown, value?: string) => {
        dispatch(setChartActionFilterDates({ path: 'endDate', value }));
    };
    return (
        <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Field.Date prefix="Start" style={{ width: 90 }} value={startDate} onChange={setStartDate} hasDatePicker />
            <Field.Date
                prefix="End"
                style={{ width: 90 }}
                value={endDate}
                onChange={setEndDate}
                minReasonableDate={startDate ? new Date(startDate) : new Date()}
                minReasonableErrorMessage="End date must be after start date"
                isReasonable
                hasDatePicker
                disabled={!startDate}
            />
        </Stack>
    );
};

const selectActiveChartActionTeethOptions = createSelector(
    chartActions,

    (chartActions) => {
        const teethOptions: IComboBoxOption[] = uniq(
            chartActions
                .map((action) => action.toothIds)
                .flat()
                .sort((a, b) => (a ?? 0) - (b ?? 0)),
        ).map((toothId) => ({
            key: toothId as number,
            text: getToothDisplayName(toothId),
        }));
        return teethOptions;
    },
);
const ChartActionTeeth = () => {
    const dispatch = useDispatch();

    const chartActionTeethOptions = useSelector(selectActiveChartActionTeethOptions);
    const chartActionFilterTeeth = useSelector(selectChartActionFilterTeeth);

    const _onChange = (_: unknown, option?: IComboBoxOption) => {
        if (option) {
            dispatch(setChartActionFilterTeeth(option.key as number));
        }
    };

    return (
        <>
            <ComboBox
                options={chartActionTeethOptions}
                placeholder="Teeth: All"
                selectedKey={chartActionFilterTeeth}
                multiSelect
                allowFreeform={false}
                autoComplete="off"
                onChange={_onChange}
                dropdownWidth={80}
            />
        </>
    );
};
