import {
    Charting,
    Landing,
    NoMatch,
    PatientOverview,
    Scheduling,
    EditTreatmentPlan,
    TreatmentPlans,
    Perio,
    CustomerSelection,
    ClinicalHuddle,
    AdminHuddle,
    Ledger,
    AuditLog,
} from 'pages';
import EditPerioExam from 'pages/Perio/components/EditPerioExam';
import Reporting from 'pages/Reporting/Reporting';
import AddTreatmentPlan from 'pages/TreatmentPlans/AddTreatmentPlan';

import { RouteProps } from 'react-router';
import { RedirectProps } from 'react-router-dom';

export interface IExtendedRouteProps extends RouteProps {
    routes?: IExtendedRouteProps[];
    layout?: 'noSideBar' | 'sideBar' | 'default';
}

export const redirects: RedirectProps[] = [];

const routes: IExtendedRouteProps[] = [
    {
        path: `/`,
        component: CustomerSelection,
        exact: true,
        layout: 'noSideBar',
    },
    {
        path: `/:tenantId`,
        component: Landing,
        exact: true,
        layout: 'noSideBar',
    },
    // ===== Dashboards/Huddles =====
    {
        path: `/:tenantId/clinical`,
        component: ClinicalHuddle,
        exact: true,
        layout: 'noSideBar',
    },
    {
        path: `/:tenantId/administrative`,
        component: AdminHuddle,
        layout: 'default',
    },
    // ===== Reporting =====
    {
        path: `/:tenantId/reporting`,
        component: Reporting,
        layout: 'sideBar',
    },
    {
        path: `/:tenantId/reporting/:reportKey`,
        exact: true,
        component: Reporting,
        layout: 'sideBar',
    },
    // ===== Patient Overview =====
    {
        path: `/:tenantId/patient/:patientId`,
        component: PatientOverview,
        exact: true,
        layout: 'sideBar',
    },
    {
        path: `/:tenantId/patient/:patientId/encounter/:encounterId`,
        component: PatientOverview,
        exact: true,
        layout: 'sideBar',
    },

    // ===== Treatment Plans List =====
    {
        path: `/:tenantId/patient/:patientId/encounter/:encounterId/treatment-plans`,
        component: TreatmentPlans,
        exact: true,
        layout: 'sideBar',
    },
    {
        path: `/:tenantId/patient/:patientId/treatment-plans`,
        component: TreatmentPlans,
        exact: true,
        layout: 'sideBar',
    },
    // ===== Edit/View Treatment Plan =====

    {
        path: `/:tenantId/patient/:patientId/encounter/:encounterId/treatment-plans/new-treatment-plan`,
        component: AddTreatmentPlan,
        exact: true,
        layout: 'sideBar',
    },
    {
        path: `/:tenantId/patient/:patientId/encounter/:encounterId/treatment-plans/:treatmentPlanId`,
        component: EditTreatmentPlan,
        exact: true,
        layout: 'sideBar',
    },
    {
        path: `/:tenantId/patient/:patientId/treatment-plans/:treatmentPlanId`,
        component: EditTreatmentPlan,
        exact: true,
        layout: 'sideBar',
    },

    // ===== Charting =====
    {
        path: `/:tenantId/patient/:patientId/charting`,
        component: Charting,
        exact: true,
        layout: 'sideBar',
    },
    {
        path: `/:tenantId/patient/:patientId/encounter/:encounterId/charting`,
        component: Charting,
        exact: true,
        layout: 'sideBar',
    },
    // ===== Perio Exams List =====
    {
        path: `/:tenantId/patient/:patientId/encounter/:encounterId/perio`,
        component: Perio,
        exact: true,
        layout: 'sideBar',
    },
    {
        path: `/:tenantId/patient/:patientId/perio`,
        component: Perio,
        exact: true,
        layout: 'sideBar',
    },
    // ===== View Edit Perio Exam =====
    {
        path: `/:tenantId/patient/:patientId/encounter/:encounterId/perio/:perioId`,
        component: EditPerioExam,
        exact: true,
        layout: 'sideBar',
    },
    {
        path: `/:tenantId/patient/:patientId/perio/:perioId`,
        component: EditPerioExam,
        exact: true,
        layout: 'sideBar',
    },

    {
        path: `/:tenantId/scheduling/:patientId`,
        component: Scheduling,
        exact: true,
        layout: 'sideBar',
    },
    {
        path: `/:tenantId/scheduling`,
        component: Scheduling,
        layout: 'sideBar',
    },
    // Ledger
    {
        path: `/:tenantId/patient/:patientId/ledger`,
        component: Ledger,
        layout: 'sideBar',
    },
    {
        path: `/:tenantId/patient/:patientId/encounter/:encounterId/ledger`,
        component: Ledger,
        layout: 'sideBar',
    },
    //Audit Logging
    {
        path: `/:tenantId/patient/:patientId/audit`,
        component: AuditLog,
        exact: true,
        layout: 'sideBar',
    },
    {
        path: `/:tenantId/patient/:patientId/audit/:encounterId`,
        component: AuditLog,
        exact: true,
        layout: 'sideBar',
    },
    {
        path: `/:tenantId/patient/:patientId/encounter/:encounterId/audit`,
        component: AuditLog,
        exact: true,
        layout: 'sideBar',
    },
    {
        path: '*',
        exact: true,
        component: NoMatch,
        layout: 'noSideBar',
    },
];

export default routes;
