export enum ToothArea {
    Lingual = 'Lingual',
    Root = 'Root',
    Buccal = 'Buccal',
    Mesial = 'Mesial',
    Distal = 'Distal',
    Occlusal = 'Occlusal',
    Incisal = 'Incisal',
    Crown = 'Crown',
    Facial = 'Facial',
    BL = 'BL',
    FL = 'FL',
    //Vestibular
    Vestibular = 'Vestibular', //Old area. Not to be used.
    BuccalVestibular = 'BuccalVestibular',
    FacialVestibular = 'FacialVestibular',
    LingualVestibular = 'LingualVestibular',
    //Arches
    UA = 'UA',
    LA = 'LA',
    //Quads
    UL = 'UL',
    UR = 'UR',
    LR = 'LR',
    LL = 'LL',
}
