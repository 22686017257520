import { Text, memoizeFunction, mergeStyleSets, Stack, useTheme, ITheme } from '@fluentui/react';

type PatientDetailsHeaderProps = {
    text?: string;
};

export function PatientDetailsHeader({ text }: PatientDetailsHeaderProps) {
    const theme = useTheme();
    const classes = getClasses(theme);

    return (
        <div className={classes.root}>
            <Stack horizontal tokens={{ childrenGap: 5 }}>
                <Text className={classes.text} variant="mediumPlus">
                    {text}
                </Text>
            </Stack>
        </div>
    );
}

const classNames = {
    root: 'section-header_wrapper',
    text: 'section-header_text',
};

const getClasses = memoizeFunction(({ palette }: ITheme) =>
    mergeStyleSets({
        root: [
            classNames.root,
            {
                padding: '5px 10px',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: palette.neutralLighter,
                borderTop: `1px solid ${palette.neutralQuaternary}`,
            },
        ],
        text: [
            classNames.text,
            {
                // color: athenaColors.sectionHeaderText,
            },
        ],
    }),
);
