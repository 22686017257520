import IWorkListAppointmentView from 'api/models/worklist-appointment-view';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';

const documentationStatus: ISortableColumn<IWorkListAppointmentView> = {
    key: 'documentationStatus',
    minWidth: 140,
    maxWidth: 140,
    isResizable: true,
    name: 'Documentation Status',
    fieldName: 'appointmentConfirmationStatus',
};

export default documentationStatus;
