import { Checkbox, Label, Spinner, Stack } from '@fluentui/react';
import { useEncounter, useAccount, usePatientId, useTenantId, useSelector } from 'hooks';
import useOverview from 'hooks/store/useOverview';
import useEncounterId from 'hooks/useEncounterId';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { SyncStatus } from 'interfaces/syncing-statuses';
import { useDispatch } from 'react-redux';
import { selectPatientEncounterSaving } from 'state/slices/encounter/encounter.selectors';
import { setMedicationViewPropAndSave } from 'state/slices/patient/medications/medications.actions';
import { selectPatientMedicationPanelSaving } from 'state/slices/patient/medications/medications.selectors';

export default function MedicationsReviewed(): JSX.Element | null {
    const dispatch = useDispatch();
    const encounterId = useEncounterId();
    const patientId = usePatientId();
    const tenantId = useTenantId();
    const { setCurrentEncounterDataProp, patientEncounter, saveCurrentEncounter } = useEncounter();
    const savingPatientEncounter = useSelector(selectPatientEncounterSaving);

    const medicationSaving = useSelector(selectPatientMedicationPanelSaving);
    const { medications, medicationsList } = useOverview();
    const { account } = useAccount();

    const isSaving = savingPatientEncounter === SyncStatus.Pending;
    const isMedicationSaving = medicationSaving === LoadingStatus.Pending;

    if (!encounterId || !patientEncounter) return null;

    function onMedicationsListReviewedClick() {
        if (!patientEncounter?.isMedicationsReviewed === true) {
            setCurrentEncounterDataProp('medicationsReviewedDate', new Date().toISOString());
            setCurrentEncounterDataProp('medicationsReviewedBy', account.data?.identity?.id);
        } else {
            setCurrentEncounterDataProp('medicationsReviewedDate', undefined);
            setCurrentEncounterDataProp('medicationsReviewedBy', undefined);
        }
        setCurrentEncounterDataProp('isMedicationsReviewed', !patientEncounter?.isMedicationsReviewed);
        saveCurrentEncounter();
    }

    function onNoMedicationsClick() {
        // setOverviewPropDataAndSave('medications', 'noMedicationsReported', !medications?.noMedicationsReported);
        if (encounterId && patientId)
            dispatch(
                setMedicationViewPropAndSave({
                    encounterId,
                    patientId,
                    tenantId,
                    type: 'medications',
                    path: 'noMedicationsReported',
                    value: !medications?.noMedicationsReported,
                }),
            );
    }

    return (
        <Stack tokens={{ childrenGap: 10 }} horizontal grow>
            {isSaving ? (
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Spinner />
                    <Label>Medication list reviewed</Label>
                </Stack>
            ) : (
                <Checkbox
                    label="Medication list reviewed"
                    onChange={onMedicationsListReviewedClick}
                    checked={!!patientEncounter?.isMedicationsReviewed}
                />
            )}

            {medicationsList?.length ? null : isMedicationSaving ? (
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Spinner />
                    <Label>No medications reported</Label>
                </Stack>
            ) : (
                <Checkbox
                    label="No medications reported"
                    onChange={onNoMedicationsClick}
                    checked={!!medications?.noMedicationsReported}
                />
            )}
        </Stack>
    );
}
