import { createSlice, Dictionary } from '@reduxjs/toolkit';
import { IUserIdentity } from 'api/models/account.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { usersExtraReducers, usersReducers } from './user-identities.reducers';

const initialState: UsersState = {
    data: {},
    userIdsLoading: [],
    loading: LoadingStatus.Idle,
};

const userIdentities = createSlice({
    name: 'user-identities',
    initialState,
    reducers: {
        ...usersReducers,
    },
    extraReducers: (builder) => {
        usersExtraReducers(builder);
    },
});

export const { cleanupUserIdentitiesData, setUsersLoading } = userIdentities.actions;

export default userIdentities.reducer;

export type UsersState = {
    data: Dictionary<IUserIdentity>;
    userIdsLoading: string[];
    loading: LoadingStatus;
};
