import { Stack, TextField, Checkbox } from '@fluentui/react';
import { VitalNameType } from 'api/models/patient-vital.model';
import { SubSection, Field } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { updateReading, weightRefused } from 'state/slices/patient/patient.slice';
import { NonBPNameTypes } from 'state/slices/patient/vitals/vitals.reducers';
import { RootState } from 'state/store';

const WtVitalsProperties = (): JSX.Element => {
    const dispatch = useDispatch();
    const newVitals = useSelector((state: RootState) => state.patient.vitals.vitalPanelData);

    const weight = newVitals[VitalNameType.Weight];

    const weightType = newVitals[VitalNameType.WeightType];
    const weightRefusedValue = newVitals[VitalNameType.WeightRefused];
    const weightRefusedReason = newVitals[VitalNameType.WeightRefusedReason];
    const weightOutOfRange = newVitals[VitalNameType.OutOfRange];

    const wtOptions = [
        {
            key: 'Stated',
            text: 'Stated',
        },
        {
            key: 'Dry',
            text: 'Dry',
        },
        {
            key: 'Preoperative',
            text: 'Preoperative',
        },
        {
            key: 'With Clothes',
            text: 'With Clothes',
        },
        {
            key: 'Without Clothes',
            text: 'Without Clothes',
        },
        {
            key: 'First',
            text: 'First',
        },
        {
            key: 'None',
            text: 'None',
        },
    ];
    const performedOptions = [
        {
            key: 'Not Indicated',
            text: 'Not Indicated',
        },
        {
            key: 'Not Tolerated',
            text: 'Not Tolerated',
        },
        {
            key: 'Patient Refused',
            text: 'Patient Refused',
        },
    ];

    const handleOnChange = (vitalType: NonBPNameTypes, value?: string): void => {
        dispatch(updateReading({ vitalType, value: value ?? '' }));
    };

    const handleOutOfRange = (vitalType: NonBPNameTypes, checked?: boolean) => {
        dispatch(updateReading({ vitalType, value: checked ? true : false }));
    };

    const handleRefused = (ev?: React.FormEvent, checked?: boolean) => {
        dispatch(weightRefused(checked ? true : false));
    };

    // const convertPoundsToGrams = (ibs: any) => {
    //     const grams = ibs * 453.59237;
    //     return grams;
    // };

    const isChecked = !!weightRefusedValue;

    return (
        <Stack tokens={{ childrenGap: 5 }}>
            <SubSection title="Weight">
                <Stack tokens={{ childrenGap: 10 }}>
                    <Stack tokens={{ childrenGap: 10 }} horizontal verticalAlign="end" grow>
                        <Stack.Item>
                            <TextField
                                label=""
                                suffix="lbs"
                                autoComplete="off"
                                disabled={isChecked}
                                placeholder="Pounds"
                                value={weight}
                                maxLength={3}
                                onChange={(ev, value) => {
                                    handleOnChange(VitalNameType.Weight, value);
                                }}
                            />
                        </Stack.Item>

                        <Stack.Item grow>
                            <Field.Dropdown
                                options={wtOptions}
                                label=""
                                placeholder=" Weight Option"
                                selectedKey={weightType}
                                disabled={isChecked}
                                onChange={(event, option) => handleOnChange(VitalNameType.WeightType, option?.key as string)}
                            />
                        </Stack.Item>
                    </Stack>
                    <Stack tokens={{ childrenGap: 10 }} horizontal verticalAlign="end" grow>
                        <Stack.Item>
                            <Checkbox label="Not Performed" checked={isChecked} onChange={handleRefused} />
                        </Stack.Item>
                        {isChecked && (
                            <Stack.Item grow>
                                <Field.Dropdown
                                    options={performedOptions}
                                    label=""
                                    placeholder="Select reason"
                                    disabled={!isChecked}
                                    selectedKey={weightRefusedReason}
                                    onChange={(event, option) =>
                                        option && handleOnChange(VitalNameType.WeightRefusedReason, option.key as string)
                                    }
                                />
                            </Stack.Item>
                        )}
                        <Stack.Item>
                            <Checkbox
                                label="Out of Range"
                                checked={weightOutOfRange}
                                onChange={(ev?, checked?: boolean): void => {
                                    handleOutOfRange(VitalNameType.OutOfRange, checked);
                                }}
                            />
                        </Stack.Item>
                    </Stack>
                </Stack>
            </SubSection>
        </Stack>
    );
};

export default WtVitalsProperties;
