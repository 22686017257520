import { Stack, useTheme } from '@fluentui/react';
import NoteActions from './NoteActions';
import NoteViewerNewNote from './NoteViewerNewNote';
import NoteViewerPreview from './NoteViewerPreview';

function NoteViewer() {
    const theme = useTheme();
    return (
        <Stack style={{ overflow: 'hidden' }} grow>
            <Stack style={{ padding: 10 }}>
                <NoteActions />
            </Stack>
            <Stack
                style={{ overflow: 'auto', backgroundColor: theme.palette.neutralLight, padding: 10 }}
                tokens={{ childrenGap: 10 }}
                horizontal
                grow
            >
                <NoteViewerNewNote />
                <NoteViewerPreview />
            </Stack>
        </Stack>
    );
}

export default NoteViewer;
