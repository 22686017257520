import { ChartProcedureStatus } from 'api/models/chart.model';
import { ProcedureBillingStatus } from 'api/models/procedure.model';
import { ChartTreatmentPlanStatus } from 'api/models/treatment-plan.model';
import { createSelector } from 'reselect';
import { IPredeterminationFormProperties } from 'state/slices/reporting/reporting.state';
import { RootState } from 'state/store';
import { number } from 'yargs';
import { chartActions } from '../../chart/chart.selectors';
import { ProcedureActionType } from '../../chart/chart.slice';
import { IChartAction } from '../../chartActionsList.pipeline';

const selectPrintPredetermination = (state: RootState) => state.charting.treatmentPlans.printPredetermination;
export const selectPrintPredeterminationIsOpen = createSelector(selectPrintPredetermination, ({ isOpen }) => isOpen);
export const selectPrintPredeterminationLocId = createSelector(selectPrintPredetermination, ({ locId }) => locId);
export const selectPrintPredeterminationProviderId = createSelector(selectPrintPredetermination, ({ providerId }) => providerId);
export const selectPrintPredeterminationQueryParams = createSelector(
    selectPrintPredetermination,
    ({ selectedTreatmentPlan, selectedChartActions, providerId, locId }) => {
        if (!selectedTreatmentPlan?.id) return;
        if (!selectedChartActions?.length) return;
        if (!locId) return;
        if (!providerId) return;

        const query: IPredeterminationFormProperties = {
            chartTreatmentPlanId: selectedTreatmentPlan?.id,
            chartTreatmentPlanProcedureId: selectedChartActions.map((proc) => proc.id),
            locationOfCareId: locId,
            treatingProviderId: providerId,
        };
        return query;
    },
);

export const selectPredeterminationSelectedTreatmentPlan = createSelector(
    selectPrintPredetermination,
    ({ selectedTreatmentPlan }) => selectedTreatmentPlan,
);

export const selectPrintPredeterminationSelectedChartActions = createSelector(
    selectPrintPredetermination,
    ({ selectedChartActions }) => selectedChartActions,
);

export const selectChartActionsFromTreatmentPlan = createSelector(
    chartActions,
    selectPredeterminationSelectedTreatmentPlan,
    (chartActions, treatmentPlan) => {
        if (!treatmentPlan) return [];
        const filteredChartActions = chartActions
            .filter(
                (procs) =>
                    procs.status === ChartProcedureStatus.Pending &&
                    procs.billingStatus === ProcedureBillingStatus.Billable &&
                    procs.procedureType === ProcedureActionType.Treatment,
            )
            .filter((action) => treatmentPlan.procedures?.find((proc) => proc.id === action.id)) as IChartActionWithPhase[];
        return filteredChartActions.map((chartAction) => {
            const tpProcedure = treatmentPlan.procedures?.find((p) => chartAction.id === p.id);

            const phase = treatmentPlan.phases?.findIndex((phase) => tpProcedure?.phaseId === phase.id);
            return { ...chartAction, phase: typeof phase === 'number' ? phase + 1 : 'N/A' };
        });
    },
);

export interface IChartActionWithPhase extends IChartAction {
    phase?: string;
}

export const selectPredeterminationPrintDisabled = createSelector(
    selectPrintPredetermination,
    ({ locId, providerId, selectedChartActions }) => {
        return (
            !locId ||
            !providerId ||
            (selectedChartActions && selectedChartActions?.length === 0) ||
            (selectedChartActions && selectedChartActions?.length > 10)
        );
    },
);
