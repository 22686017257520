import { createAsyncThunk } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import { IBillingProcedure } from 'api/models/billing-procedure.model';
import { ChartProcedureStatus, IChartProcedure } from 'api/models/chart.model';
import {
    ChartTreatmentPlanPhaseStatus,
    ChartTreatmentPlanStatus,
    IChartTreatmentPlan,
    IChartTreatmentPlanPhase,
    IChartTreatmentPlanPhaseProcedure,
} from 'api/models/treatment-plan.model';
import axios from 'axios';
import { push } from 'connected-react-router';
import { cloneDeep, every } from 'lodash';
import { AppThunk, RootState } from 'state/store';
import { v4 as uuid } from 'uuid';
import {
    deleteTreatmentPlanPhase,
    discardCurrentTreatmentPlan,
    moveProceduresToPhase,
    setAddAndUpdateTreatmentPlan,
    setCurrentChartTreatmentPlan,
    setFinancialNotes,
    setPatientReturnDate,
    setPhaseProceduresStatus,
} from '../chart/chart.slice';
import { calculatedTreatmentPlanProcedures } from './treatmentPlans.selectors';
import ErrorTypes from 'state/errorTypes';

export const getChartTreatmentPlans = createAsyncThunk<
    IChartTreatmentPlan[],
    {
        tenantId: string;
        patientId: string;
    }
>('getChartTreatmentPlans', async ({ tenantId, patientId }) => {
    const result = await dentalApi.getChartTreatmentPlans(tenantId, patientId);
    return result.data;
});

export const getTreatmentPlanBillingProcedures = createAsyncThunk<
    IBillingProcedure[],
    {
        tenantId: string;
        patientId: string;
        treatmentPlanId: string;
    }
>('getTreatmentPlanBillingProcedures', async ({ tenantId, patientId, treatmentPlanId }) => {
    const result = await dentalApi.getTreatmentPlanBillingProcedures(tenantId, patientId, { treatmentPlanId });
    return result.data;
});

export const getChartTreatmentPlanById = createAsyncThunk<
    IChartTreatmentPlan,
    {
        tenantId: string;
        patientId: string;
        treatmentPlanId: string;
    },
    {
        state: RootState;
    }
>('getChartTreatmentPlanById', async ({ tenantId, patientId, treatmentPlanId }, { getState, dispatch }) => {
    const { data: treatmentPlan } = await dentalApi.getChartTreatmentPlanById(tenantId, patientId, treatmentPlanId);
    if (treatmentPlan.status !== ChartTreatmentPlanStatus.Pending) return treatmentPlan;
    /**
     * If the treatment Plan is pending find any updated billing procedures and remap them to the pending treatmentplan.
     */
    await dispatch(getTreatmentPlanBillingProcedures({ tenantId, patientId, treatmentPlanId: treatmentPlan.id }));
    // const calculatedProcedures = calculatedTreatmentPlanProcedures(getState());
    const state = getState();
    const pendingProcs = state.charting.treatmentPlans.pendingProcedures;

    const calculatedProcedures = treatmentPlan.procedures?.map((proc) => {
        const calculatedProcedure = pendingProcs.find((p) => p.id === proc.id);
        return calculatedProcedure ? { ...proc, ...calculatedProcedure } : proc;
    });

    const { data: updatedTreatmentPlan } = await dentalApi.updateChartTreatmentPlan(tenantId, patientId, {
        ...treatmentPlan,
        procedures: calculatedProcedures,
    });

    return updatedTreatmentPlan;
});

export const createChartTreatmentPlan = createAsyncThunk<
    IChartTreatmentPlan,
    {
        tenantId: string;
        patientId: string;
        treatmentPlan: IChartTreatmentPlan;
    }
>('createChartTreatmentPlan', async ({ tenantId, patientId, treatmentPlan }, { dispatch, rejectWithValue }) => {
    try {
        if (!treatmentPlan.patientId) {
            treatmentPlan['patientId'] = patientId;
        }
        const result = await dentalApi.createChartTreatmentPlan(tenantId, patientId, treatmentPlan);
        if (result.data) {
            if (result.data.encounterId) {
                dispatch(
                    push(
                        `/${tenantId}/patient/${patientId}/encounter/${result.data.encounterId}/treatment-plans/${result.data.id}`,
                    ),
                );
            } else {
                dispatch(push(`/${tenantId}/patient/${patientId}/treatment-plans/${result.data.id}`));
            }
        }
        return result.data;
    } catch (err) {
        if (axios.isAxiosError(err) && err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue('Unkonwn error');
        }
    }
});

export const createNewChartTreatmentPlan =
    (encounterId: string, patientId: string, tenantId: string): AppThunk<void> =>
    (dispatch) => {
        const id = uuid();

        const treatmentPlan: IChartTreatmentPlan = {
            id,
            patientId,
            encounterId,
            isDeleted: false,
            procedures: [],
            phases: [],
            status: ChartTreatmentPlanStatus.Pending,
            signature: '',
            signedDate: new Date().toISOString(),
            refusedToSign: false,
        };

        dispatch(setCurrentChartTreatmentPlan(treatmentPlan));
        dispatch(getTreatmentPlanBillingProcedures({ patientId, tenantId, treatmentPlanId: id }));
    };

export const updateChartTreatmentPlan = createAsyncThunk<
    IChartTreatmentPlan | undefined,
    {
        tenantId: string;
        patientId: string;
        treatmentPlan: IChartTreatmentPlan;
    },
    {
        state: RootState;
    }
>('updateChartTreatmentPlan', async ({ tenantId, patientId, treatmentPlan }, { dispatch, getState, rejectWithValue }) => {
    try {
        const _treatmentPlan = cloneDeep(treatmentPlan);
        if (_treatmentPlan) {
            const result = await dentalApi.updateChartTreatmentPlan(tenantId, patientId, _treatmentPlan);

            if (_treatmentPlan.status === ChartTreatmentPlanStatus.Pending)
                await dispatch(getTreatmentPlanBillingProcedures({ tenantId, patientId, treatmentPlanId: treatmentPlan.id }));

            return result.data;
        }
    } catch (err) {
        if (axios.isAxiosError(err) && err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        }
        if (axios.isAxiosError(err) && err.response && err.response.status === 412) {
            return rejectWithValue(ErrorTypes.ModifiedOutside);
        } else {
            return rejectWithValue('Unkonwn error');
        }
    }
});

export const updateChartTreatmentPlans = createAsyncThunk<
    IChartTreatmentPlan[] | undefined,
    {
        tenantId: string;
        patientId: string;
        treatmentPlans: IChartTreatmentPlan[];
    },
    {
        state: RootState;
    }
>('updateChartTreatmentPlans', async ({ tenantId, patientId, treatmentPlans }) => {
    if (treatmentPlans.length) {
        const calls = treatmentPlans.map((tp) => dentalApi.updateChartTreatmentPlan(tenantId, patientId, tp));
        const results = await axios.all(calls);

        return results.map((res) => res.data);
    }
});

export const autoUpdateChartTreatmentPlan = createAsyncThunk<
    IChartTreatmentPlan | undefined,
    {
        tenantId: string;
        patientId: string;
        treatmentPlan: IChartTreatmentPlan;
    },
    {
        state: RootState;
    }
>('autoUpdateChartTreatmentPlan', async ({ tenantId, patientId, treatmentPlan }, { rejectWithValue }) => {
    try {
        const _treatmentPlan = cloneDeep(treatmentPlan);
        if (_treatmentPlan) {
            const result = await dentalApi.updateChartTreatmentPlan(tenantId, patientId, _treatmentPlan);

            return result.data;
        }
    } catch (err) {
        if (axios.isAxiosError(err) && err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        }
        if (axios.isAxiosError(err) && err.response && err.response.status === 412) {
            return rejectWithValue(ErrorTypes.ModifiedOutside);
        } else {
            return rejectWithValue('Unkonwn error');
        }
    }
});

export function getAutoUpdatedStatusTreatmentPlan(
    state: RootState,
    procedures: IChartProcedure[],
    treatmentPlanOverride?: IChartTreatmentPlan,
): IChartTreatmentPlan[] {
    const treatmentPlans = state.charting.treatmentPlans.data.filter((tp) => !tp.isDeleted);
    if (!treatmentPlans?.length && !treatmentPlanOverride) return [];

    const treatmentPlansToUpdate: IChartTreatmentPlan[] = [];

    //Loop through existing treatment plans
    (treatmentPlanOverride ? [treatmentPlanOverride] : treatmentPlans).forEach((tp) => {
        //Determines if we need to update current tp
        let hasChange = false;
        //Mutable treatment plan
        const newTreatmentPlan = cloneDeep(tp);
        //Loop through given procedures and update statuses for TP procedure, and phase status to completed/undefined when applicable
        procedures.forEach((p) => {
            if (tp.procedures?.length) {
                const indexOfProcedure = tp.procedures.findIndex((procedure) => procedure.id === p.id);
                if (indexOfProcedure > -1) {
                    const procedure: IChartTreatmentPlanPhaseProcedure = {
                        ...tp.procedures[indexOfProcedure],
                        status: p.status,
                        statusChangedDate: p.statusChangedDate,
                    };

                    if (newTreatmentPlan.procedures?.length) {
                        //Update tp procedure
                        newTreatmentPlan.procedures[indexOfProcedure] = procedure;

                        //Logic to update phase completed status when all procs for phase are not pending.
                        if (tp?.phases && newTreatmentPlan?.phases) {
                            const phaseIndex = tp.phases
                                .filter((phase) => !phase.isDeleted)
                                .findIndex((phase) => phase.id === procedure.phaseId);

                            if (phaseIndex > -1) {
                                const phase = tp.phases[phaseIndex];
                                const phaseProcedures = newTreatmentPlan.procedures.filter(
                                    (phaseProc) => phaseProc.phaseId === phase.id,
                                );
                                const phaseCompleted = every(phaseProcedures, (procedure) => procedure.status !== 'Pending');
                                newTreatmentPlan.phases[phaseIndex] = {
                                    ...phase,
                                    status: phaseCompleted ? ChartTreatmentPlanPhaseStatus.Completed : undefined,
                                    completedDate: phaseCompleted ? new Date().toISOString() : undefined,
                                };
                            }
                            //Set treatment plan has been modified
                            hasChange = true;
                        }
                    }
                }
            }
        });
        //If has been changed, push the new tp to list of tps to update
        if (hasChange) treatmentPlansToUpdate.push(newTreatmentPlan);
    });
    return treatmentPlansToUpdate;
}

export const updateChartTreatmentPlansProceduresStatuses =
    (
        tenantId: string,
        patientId: string,
        procedures: IChartProcedure[],
        treatmentPlanOverride?: IChartTreatmentPlan,
    ): AppThunk<void> =>
    async (dispatch, getState) => {
        const treatmentPlansToUpdate = getAutoUpdatedStatusTreatmentPlan(getState(), procedures, treatmentPlanOverride);

        //If we have tps to update, the make async call to update tps.
        if (treatmentPlansToUpdate.length) {
            dispatch(updateChartTreatmentPlans({ tenantId, patientId, treatmentPlans: treatmentPlansToUpdate }));
        }
    };

export const updateOrCreateTreatmentPlan =
    ({
        tenantId,
        patientId,
        encounterId,
        treatmentPlan,
        isAutoUpdate,
    }: {
        tenantId: string;
        patientId: string;
        treatmentPlan?: IChartTreatmentPlan;
        encounterId?: string;
        isAutoUpdate?: boolean;
    }): AppThunk<void> =>
    async (dispatch, getState) => {
        const { data: treatmentPlans } = getState().charting.treatmentPlans;
        const hasTreatmentPlan = treatmentPlans.findIndex((plan) => plan.id === treatmentPlan?.id) > -1;

        if (treatmentPlan) {
            const newTreatmentPlan = {
                ...treatmentPlan,
                encounterId: treatmentPlan?.encounterId ?? encounterId,
            };
            if (hasTreatmentPlan) {
                if (!isAutoUpdate) {
                    dispatch(
                        updateChartTreatmentPlan({
                            tenantId,
                            patientId,
                            treatmentPlan: newTreatmentPlan,
                        }),
                    );
                } else {
                    dispatch(
                        autoUpdateChartTreatmentPlan({
                            tenantId,
                            patientId,
                            treatmentPlan: newTreatmentPlan,
                        }),
                    );
                }
            } else {
                dispatch(
                    createChartTreatmentPlan({
                        tenantId,
                        patientId,
                        treatmentPlan: newTreatmentPlan,
                    }),
                );
            }
        }
    };

let timer: NodeJS.Timeout | null = null;
export const onSetFinancialNotes =
    ({
        patientId,
        encounterId,
        tenantId,
        value,
    }: {
        tenantId: string;
        patientId: string;
        encounterId: string;
        value: string;
    }): AppThunk<void> =>
    (dispatch, getState): void => {
        dispatch(setFinancialNotes(value));
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            const { currentTreatmentPlan } = getState().charting.treatmentPlans;
            dispatch(
                updateOrCreateTreatmentPlan({
                    tenantId,
                    patientId,
                    encounterId,
                    treatmentPlan: currentTreatmentPlan,
                    isAutoUpdate: true,
                }),
            );
        }, 2000);
    };

export const onSetPatientReturnDate =
    ({
        tenantId,
        patientId,
        phaseId,
        encounterId,
        value,
    }: {
        tenantId: string;
        patientId: string;
        encounterId: string;
        phaseId: string;
        value: string;
    }): AppThunk<void> =>
    async (dispatch, getState) => {
        await dispatch(setPatientReturnDate({ phaseId, value }));
        const { currentTreatmentPlan } = getState().charting.treatmentPlans;
        dispatch(updateOrCreateTreatmentPlan({ tenantId, patientId, encounterId, treatmentPlan: currentTreatmentPlan }));
    };

export const onSetPhaseProceduresStatus =
    ({
        tenantId,
        patientId,
        encounterId,
        procedures,
        status,
    }: {
        tenantId: string;
        patientId: string;
        encounterId: string;
        procedures: IChartTreatmentPlanPhaseProcedure[] | IBillingProcedure[];
        status: keyof typeof ChartProcedureStatus;
    }): AppThunk<void> =>
    async (dispatch, getState) => {
        await dispatch(setPhaseProceduresStatus({ procedureIds: procedures.map((p) => p.id), status }));
        const { currentTreatmentPlan } = getState().charting.treatmentPlans;
        if (currentTreatmentPlan) {
            const newTreatmentPlan = { ...currentTreatmentPlan, encounterId: encounterId ?? currentTreatmentPlan.encounterId };

            const updatedStatusTreatmentPlan = getAutoUpdatedStatusTreatmentPlan(
                getState(),
                currentTreatmentPlan.procedures ?? [],
                newTreatmentPlan,
            );

            dispatch(
                updateOrCreateTreatmentPlan({
                    tenantId,
                    patientId,
                    treatmentPlan: updatedStatusTreatmentPlan.length ? updatedStatusTreatmentPlan[0] : newTreatmentPlan,
                }),
            );
        }
    };

export const onDeleteTreatmentPlanPhase =
    ({ tenantId, patientId, phase }: { tenantId: string; patientId: string; phase: IChartTreatmentPlanPhase }): AppThunk<void> =>
    async (dispatch, getState) => {
        await dispatch(deleteTreatmentPlanPhase(phase));
        const { currentTreatmentPlan } = getState().charting.treatmentPlans;
        dispatch(updateOrCreateTreatmentPlan({ tenantId, patientId, treatmentPlan: currentTreatmentPlan }));
    };

export const onDiscardCurrentTreatmentPlan =
    ({ tenantId, patientId, encounterId }: { tenantId: string; patientId: string; encounterId?: string }): AppThunk<void> =>
    async (dispatch, getState) => {
        await dispatch(discardCurrentTreatmentPlan());
        const { currentTreatmentPlan } = getState().charting.treatmentPlans;
        if (currentTreatmentPlan) {
            await dentalApi.updateChartTreatmentPlan(tenantId, patientId, currentTreatmentPlan);

            if (encounterId) {
                dispatch(push(`/${tenantId}/patient/${patientId}/encounter/${encounterId}/treatment-plans`));
            } else {
                dispatch(push(`/${tenantId}/patient/${patientId}/treatment-plans`));
            }
        }
    };

export const onMoveProceduresToPhase =
    ({
        procedures,
        phaseId,
        tenantId,
        encounterId,
        patientId,
    }: {
        tenantId: string;
        patientId: string;
        encounterId: string;
        procedures: IBillingProcedure[] | IChartTreatmentPlanPhaseProcedure[];
        phaseId?: string | 'pending';
    }): AppThunk<void> =>
    async (dispatch, getState) => {
        await dispatch(moveProceduresToPhase({ procedures, phaseId }));
        const { currentTreatmentPlan } = getState().charting.treatmentPlans;
        await dispatch(updateOrCreateTreatmentPlan({ tenantId, patientId, encounterId, treatmentPlan: currentTreatmentPlan }));
    };

export const signOrDeclineTreatmentPlan =
    ({ tenantId, patientId, encounterId }: { tenantId: string; patientId: string; encounterId: string }): AppThunk<void> =>
    async (dispatch, getState) => {
        const treatmentPlan = cloneDeep(getState().charting.treatmentPlans.currentTreatmentPlan);
        if (treatmentPlan) {
            if (treatmentPlan.refusedToSign) {
                treatmentPlan.status = ChartTreatmentPlanStatus.Declined;
            } else {
                treatmentPlan.status = ChartTreatmentPlanStatus.Signed;
            }
            //Ensure we have the latest calculated procedures...
            await dispatch(getTreatmentPlanBillingProcedures({ tenantId, patientId, treatmentPlanId: treatmentPlan.id }));
            const procedures = calculatedTreatmentPlanProcedures(getState());
            dispatch(
                updateOrCreateTreatmentPlan({
                    tenantId,
                    patientId,
                    encounterId,
                    treatmentPlan: { ...treatmentPlan, procedures },
                }),
            );
        }
    };

export const insertTreatmentPlan =
    (treatmentPlan: IChartTreatmentPlan): AppThunk<void> =>
    (dispatch, getState) => {
        dispatch(setAddAndUpdateTreatmentPlan(treatmentPlan));
        if (treatmentPlan.status === ChartTreatmentPlanStatus.Pending) {
            const tenantId = getState().router.location.pathname.substring(1).split('/')[0];
            const treatmentPlanId = getState().charting.treatmentPlans.currentTreatmentPlan;

            if (treatmentPlanId) {
                if (treatmentPlanId.id === treatmentPlan.id)
                    dispatch(
                        getTreatmentPlanBillingProcedures({
                            tenantId,
                            patientId: treatmentPlan.patientId as string,
                            treatmentPlanId: treatmentPlan.id,
                        }),
                    );
            }
        }
    };
