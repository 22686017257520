import { Stack, Text } from '@fluentui/react';
import { EncounterStatus } from 'api/models/encounter.model';
import IWorkListEncounterView from 'api/models/worklist-encounter-view';
import { TModal } from 'components';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { RouteParams } from 'interfaces/route-params';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    saveEncounterCorrespondenceAndSend,
    saveEncounterCorrespondenceEncounterWorkList,
} from 'state/slices/admin-huddle/worklists/encounter-worklists/encounter-worklists.actions';
import { selectEncounterWorkListNoteModalEncounterCorrespondenceSaving } from 'state/slices/admin-huddle/worklists/encounter-worklists/encounter-worklists.selectors';
import {
    selectWorkListConfirmationModalType,
    selectWorkListConfirmationModalIsOpen,
    selectWorkListEditItem,
    selectWorkListSaving,
} from 'state/slices/admin-huddle/worklists/worklist.selectors';
import {
    setWorkListConfirmationModalOpen,
    cleanupWorkListConfirmationModal,
    setEncounterWorkListModalMessageBar,
} from 'state/slices/admin-huddle/worklists/worklist.slice';
import { WorkListConfirmModalType } from 'state/slices/admin-huddle/worklists/worklist.state';
import { useApproveEncounters } from '../Hooks/useApproveEncounters';
import {
    BATCH_NOT_FOR_TODAY_ERROR_MESSAGE_BAR,
    NO_BATCH_ERROR_MESSAGE_BAR,
} from '../Hooks/useApproveWorklistCommandBar';

const titleLookup: Record<WorkListConfirmModalType, string> = {
    Rebill: 'Rebill Confirmation',
    [EncounterStatus.Billed]: 'Abort Rebill Confirmation',
    [EncounterStatus.CorrectionsCompleted]: 'Send to Ready to Reapprove Confirmation',
};

const contentLookup: Record<WorkListConfirmModalType, string> = {
    Rebill: 'You are about to rebill this encounter. Do you wish to continue?',
    [EncounterStatus.Billed]:
        'Warning:  This will return the status of the encounter back to "Approved" without rebilling it.  Before proceeding, the current data on the encounter must be an exact match to the previously billed data.',
    [EncounterStatus.CorrectionsCompleted]:
        'You are about to send this encounter to the Ready to Reapprove work list. Do you wish to continue?',
};

export default function EditEncountersConfirmationModal() {
    const savingStatus = useSelector(selectEncounterWorkListNoteModalEncounterCorrespondenceSaving);
    const worklistSavingStatus = useSelector(selectWorkListSaving);
    const dispatch = useDispatch();

    const { tenantId } = useParams<RouteParams>();
    const savingNoteData = savingStatus === LoadingStatus.Pending;
    const saving = worklistSavingStatus === LoadingStatus.Pending;
    const encounterView = useSelector(selectWorkListEditItem<IWorkListEncounterView>);
    const type = useSelector(selectWorkListConfirmationModalType);
    const isOpen = useSelector(selectWorkListConfirmationModalIsOpen);

    const [errors, approve] = useApproveEncounters([encounterView?.encounterId], "reapprove", onApproved);

    function onApproved() {
        dispatch(saveEncounterCorrespondenceEncounterWorkList({ tenantId }));
    }

    useEffect(() => {
        if (errors.length) {
            if (errors[0].fieldName === 'NoBatchSelected')
                dispatch(setEncounterWorkListModalMessageBar(NO_BATCH_ERROR_MESSAGE_BAR));
            if (errors[0].fieldName === 'BatchSameDay')
                dispatch(setEncounterWorkListModalMessageBar(BATCH_NOT_FOR_TODAY_ERROR_MESSAGE_BAR));

            onDismiss();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors, dispatch]);

    useEffect(() => {
        if (savingStatus === LoadingStatus.Completed || savingStatus === LoadingStatus.Failed) {
            onDismiss();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [savingStatus]);

    if (!type) return null;

    function onDismiss() {
        dispatch(setWorkListConfirmationModalOpen({ isOpen: false }));
    }
    function onDismissed() {
        dispatch(cleanupWorkListConfirmationModal());
    }

    async function onConfirm() {
        if (type === 'Rebill') {
            approve();
        } else if (type && encounterView?.patientId) {
            dispatch(saveEncounterCorrespondenceAndSend({ tenantId, patientId: encounterView.patientId, encounterStatus: type }));
        }
    }

    return (
        <TModal
            mainButtons={[
                {
                    text: savingNoteData ? 'Saving...' : 'Continue',
                    type: 'PrimaryButton',
                    onClick: onConfirm,
                    disabled: savingNoteData || saving,
                },
                {
                    text: 'Cancel',
                    onClick: onDismiss,
                    disabled: savingNoteData || saving,
                },
            ]}
            isDraggable
            title={titleLookup[type]}
            modalProps={{ isOpen, onDismiss, onDismissed }}
        >
            <Stack styles={{ root: { padding: 10 } }}>
                <Text styles={{ root: { maxWidth: 400 } }}>{contentLookup[type]}</Text>
            </Stack>
        </TModal>
    );
}
