import IChartNote, { ClinicalNote } from 'api/models/chart-note.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import quickNotesInitialState, { IQuickNotesState } from './quick-notes/quick-notes.state';

export interface IChartNotesState {
    currentClinicalNote?: ClinicalNote;
    data?: IChartNote[];
    modifiedClincalNote: boolean;
    filterToothId?: number;
    filterCreatedDate?: string;
    loading: LoadingStatus;
    loadingCurrentNote: LoadingStatus;
    saving: LoadingStatus;
    quickNotes: IQuickNotesState;
}

const initialState: IChartNotesState = {
    loading: LoadingStatus.Idle,
    loadingCurrentNote: LoadingStatus.Idle,
    saving: LoadingStatus.Idle,
    quickNotes: quickNotesInitialState,
    modifiedClincalNote: false,
};

export default initialState;
