import { AnyAction, createAsyncThunk, Dictionary, ThunkDispatch } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import IAdjustmentReason from 'api/models/adjustment-reason.model';
import { IReport, IEmbedReportModel, ReportType } from 'api/models/embed-report.model';
import LookupBase, { LookupCodeBase } from 'api/models/lookup-base.model';
import ILocationOfCare from 'api/models/Scheduling/locationsOfCare.model';
import schedulingApi from 'api/scheduling.api';
import axios, { AxiosError, AxiosResponse } from 'axios';
import ErrorTypes from 'state/errorTypes';
import { RootState } from 'state/store';

export const getAllReports = createAsyncThunk<Dictionary<IReport>, { tenantId: string }, { rejectValue: string }>(
    'getReports',
    async ({ tenantId }, { rejectWithValue }) => {
        try {
            const response = await dentalApi.getReports(tenantId);
            return response.data;
        } catch (err) {
            if (axios.isAxiosError(err) && err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue('Unknown error');
            }
        }
    },
);

export const getAllLocationsOfCareForReports = createAsyncThunk<Dictionary<ILocationOfCare>, { tenantId: string }>(
    'getAllLocationsOfCareForReports',
    async ({ tenantId }, { rejectWithValue }) => {
        try {
            const response = await schedulingApi.getLocationsOfCare(tenantId);
            return response.data;
        } catch (err) {
            if (axios.isAxiosError(err) && err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue('Unknown error');
            }
        }
    },
);

export const getAdjustmentReasonsForReports = createAsyncThunk<Dictionary<IAdjustmentReason>>(
    'getAdjustmentReasonsForReports',
    async (_, { rejectWithValue }) => {
        try {
            const response = await dentalApi.getAllAdjustmentReasons();
            return response.data;
        } catch (err) {
            if (axios.isAxiosError(err) && err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue('Unknown error');
            }
        }
    },
);

export type ReportApiMethods = 'post' | 'get';

/**
 * @description Parameteres for report action builder
 *
 * Q - Query object type. Translates to querystring or json depending on if post/get.
 *
 * T - Extra params that appear in the url path of the endpoint url.
 */
export type ReportQuery<Q> = Q & { tenantId: string; extraParamsQueryString?: boolean };

export const buildQueryReportAction = <T extends ReportQuery<Record<string, any> | undefined>>(type?: ReportType) =>
    createAsyncThunk<IEmbedReportModel, T, { rejectValue: string; dispatch: ThunkDispatch<RootState, unknown, AnyAction> }>(
        'queryReport',
        async (params, { rejectWithValue }) => {
            if (type) {
                try {
                    const response = await dentalApi.queryReport(params, type);
                    return response.data;
                } catch (err) {
                    if (axios.isAxiosError(err) && err.response && err.response.status === 503) {
                        return rejectWithValue(ErrorTypes.ServiceUnavailable);
                    } else {
                        return rejectWithValue('Unknown error');
                    }
                }
            }
            return rejectWithValue('No builder params specified');
        },
    );

export const getEncounterStatusOptions = createAsyncThunk<
    AxiosResponse<Dictionary<LookupCodeBase>>,
    void,
    {
        rejectValue: AxiosError;
    }
>('getEncounterStatusOptions', async () => {
    const res = await dentalApi.getEncounterStatusOptions();
    return res;
});
export const getPatientTrackerOptions = createAsyncThunk<
    AxiosResponse<Dictionary<LookupCodeBase>>,
    void,
    {
        rejectValue: AxiosError;
    }
>('getPatientTrackerOptions', async () => {
    const res = await dentalApi.getPatientTrackerOptions();
    return res;
});
