import { CommandBar, ScrollablePane, Stack, mergeStyles, useTheme } from '@fluentui/react';
import { Dictionary } from '@reduxjs/toolkit';
import React, { createRef, useCallback, useEffect, useRef } from 'react';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import { RouteParams } from 'interfaces/route-params';
import { useSelector } from 'react-redux';
import {
    clearAllUACDistributionTransactions,
    createUACDistributionTransactions,
    distributeAllUACDistributionTransactions,
    getAllUACDistributionSectionData,
    getUACDistributionTransactionAndPaymentSource,
    saveUACDistributionTransactions,
} from 'state/slices/ledger/uac-distribution/uac-distribution.actions';
import UACToDistributeCard from './UACToDistributeCard';
import UACPatientBalanceCard from './UACPatientBalanceCard';
import UACDistributionPatientBalanceSection from './UACDistributionPatientBalanceSection';
import { UACDistributionTreatmentPlanSection } from './UACDistributionTreatmentPlanSection';
import UACTreatmentPlanRemainingCard from './UACTreatmentPlanRemainingCard';
import { cleanupUACDistribution, setUACDistributionSectionOpen } from 'state/slices/ledger/ledger.slice';
import {
    selectUACDistributionOpenSections,
    selectUACDistributionTransactionLoading,
    selectUACDistributionSaving,
    selectUACDistributionTransactionAmount,
    selectUACDistributionDistributedAmount,
    selectUACDistributionIsLoading,
    selectAllUACDistributionSectionsOverpaying,
    selectUACDistributionModified,
    selectUACDistributionTotalRemaining,
} from 'state/slices/ledger/uac-distribution/uac-distribution.selectors';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { UACDistributionOpenSections } from 'state/slices/ledger/uac-distribution/uac-distribution.state';
import { useAppDispatch } from 'hooks/useAppDispatch';
import UACDistributionPatientEstimateSection from './UACDistributionPatientEstimateSection';
import UACDistributionPatientEstimateCard from './UACDistributionPatientEstimateCard';
import { Helmet } from 'react-helmet';

export default function UACDistribution() {
    const dispatch = useAppDispatch();

    const { palette } = useTheme();
    const { goBack } = useHistory();
    const { patientId, tenantId, uacTransactionId } = useParams<RouteParams & { uacTransactionId: string }>();

    const saving = useSelector(selectUACDistributionSaving);
    const openSections = useSelector(selectUACDistributionOpenSections);

    const isLoadingData = useSelector(selectUACDistributionIsLoading);
    const uacTransactionLoading = useSelector(selectUACDistributionTransactionLoading);
    const uacTransactionAmount = useSelector(selectUACDistributionTransactionAmount);
    const distributedAmount = useSelector(selectUACDistributionDistributedAmount);
    const uacRemainingAmount = useSelector(selectUACDistributionTotalRemaining);

    const modified = useSelector(selectUACDistributionModified);

    const isSaving = saving === LoadingStatus.Pending;

    const allSectionsOverpaying = useSelector(selectAllUACDistributionSectionsOverpaying);

    const refs = useRef<Dictionary<React.RefObject<HTMLDivElement>>>({
        patientEstimate: createRef(),
        patientBalance: createRef(),
        treatmentPlanRemaining: createRef(),
    });

    const scrollSectionIntoView = useCallback(
        async (strRef: string, highlightOnClick?: boolean) => {
            const openSectionActionMapping: Record<string, keyof UACDistributionOpenSections> = {
                patientBalance: 'patientBalanceOpen',
                patientEstimate: 'patientEstimateOpen',
                treatmentPlanRemaining: 'treatmentPlanRemainingOpen',
            };

            const openSectionMapping = openSectionActionMapping[strRef];

            //If the section is not open, then we open it.
            if (!openSections[openSectionMapping]) {
                //Need to await this, because otherwise scroll into view offset doesn't account for the changed vertical space occasionally.
                await dispatch(setUACDistributionSectionOpen(openSectionActionMapping[strRef]));
            }

            refs.current[strRef]?.current?.scrollIntoView({
                block: 'start',
                inline: 'start',
            });

            //Highlight the section we scrolled to
            if (refs.current[strRef]?.current && highlightOnClick === undefined ? true : highlightOnClick) {
                const transitionClass = mergeStyles({
                    transition: 'all 1s',
                    zIndex: 99,
                });

                const highlightClass = mergeStyles({
                    boxShadow: `0px 0px 8px 2px ${palette.themePrimary} !important`,
                    zIndex: 99,
                });

                if (refs?.current[strRef]) {
                    ((refs.current[strRef] as React.RefObject<HTMLDivElement>).current as HTMLDivElement).className =
                        `${transitionClass} ${highlightClass}`;
                    setTimeout(() => {
                        ((refs.current[strRef] as React.RefObject<HTMLDivElement>).current as HTMLDivElement).className =
                            `${transitionClass}`;
                    }, 1000);
                }
            }
        },
        [refs, palette, openSections, dispatch],
    );

    const initializeUACDistributionData = useCallback(async () => {
        if (patientId) {
            //Get the uac transaction and the corresponding payment source. Unwrap the promise so we can get the data here.
            const transactionRes = dispatch(
                getUACDistributionTransactionAndPaymentSource({ tenantId, patientId, transactionId: uacTransactionId }),
            ).unwrap();

            //Await getting all the procedures and summaries for all distribution sections.
            await dispatch(getAllUACDistributionSectionData(tenantId, patientId));

            //After we have the uac transaction and all the section data, initialize the transactions.
            transactionRes.then(({ transaction }) => {
                if (transaction.batchId) dispatch(createUACDistributionTransactions(patientId, transaction.batchId));
            });
        }
    }, [patientId, tenantId, uacTransactionId, dispatch]);

    useEffect(() => {
        //If there is no money to distribute, then we just push the user out of the distribution flow.
        if (uacTransactionLoading === LoadingStatus.Completed && uacTransactionAmount === 0) goBack();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uacTransactionLoading, uacTransactionAmount]);

    useEffect(() => {
        initializeUACDistributionData();

        return () => {
            dispatch(cleanupUACDistribution());
        };
    }, []);

    return (
        <Stack tokens={{ childrenGap: 10 }} grow>
            <Helmet title="Ledger - UAC Distribution" />
            <Prompt
                when={modified && saving !== LoadingStatus.Completed && saving !== LoadingStatus.Failed}
                message={'Are you sure you want to leave? Any unsaved information will be lost.'}
            />
            <Stack tokens={{ childrenGap: 10 }} horizontal wrap grow>
                <UACToDistributeCard />
                <UACPatientBalanceCard onLinkClick={() => scrollSectionIntoView('patientBalance')} />
                <UACDistributionPatientEstimateCard onLinkClick={() => scrollSectionIntoView('patientEstimate')} />
                <UACTreatmentPlanRemainingCard onLinkClick={() => scrollSectionIntoView('treatmentPlanRemaining')} />
            </Stack>
            <Stack grow>
                <CommandBar
                    styles={{ root: { padding: 0 } }}
                    items={[
                        {
                            key: 'distributeAll',
                            text: 'Distribute All',
                            iconProps: { iconName: 'AddToShoppingList' },
                            disabled: isSaving || isLoadingData || allSectionsOverpaying || uacRemainingAmount <= 0,
                            onClick: () => {
                                dispatch(distributeAllUACDistributionTransactions());
                            },
                        },
                        {
                            key: 'clearAll',
                            text: 'Clear All',
                            iconProps: { iconName: 'Cancel' },
                            onClick: () => {
                                dispatch(clearAllUACDistributionTransactions());
                            },
                            disabled: isSaving || isLoadingData || distributedAmount <= 0,
                        },
                    ]}
                    farItems={[
                        {
                            key: 'save',
                            text: isSaving ? 'Saving...' : 'Save',
                            iconProps: { iconName: 'Save' },
                            disabled: isSaving || distributedAmount === 0 || isLoadingData,
                            onClick: () => {
                                dispatch(saveUACDistributionTransactions({ tenantId, uacTransactionId }));
                            },
                        },
                        {
                            key: 'cancel',
                            text: 'Cancel',
                            onClick: goBack,
                            disabled: isSaving,
                        },
                    ]}
                />
                <Stack grow styles={{ root: { position: 'relative' } }}>
                    <ScrollablePane>
                        <Stack grow tokens={{ childrenGap: 5 }}>
                            <UACDistributionPatientBalanceSection
                                onLinkClick={() => scrollSectionIntoView('patientBalance', false)}
                                ref={refs.current.patientBalance}
                            />
                            <UACDistributionPatientEstimateSection
                                onLinkClick={() => scrollSectionIntoView('patientEstimate', false)}
                                ref={refs.current.patientEstimate}
                            />
                            <UACDistributionTreatmentPlanSection
                                onLinkClick={() => scrollSectionIntoView('treatmentPlanRemaining', false)}
                                ref={refs.current.treatmentPlanRemaining}
                            />
                        </Stack>
                    </ScrollablePane>
                </Stack>
            </Stack>
        </Stack>
    );
}
