import { IEncounterSummary, ProcedureSummaryWithEncounterIdAndTransactionId } from 'api/models/encounter-ledger.model';
import { isEmpty } from 'lodash';
import { FormEvent } from 'react';
import { useSelector } from 'react-redux';
import { selectPatientOverpaymentOrOverAdjustment } from 'state/slices/ledger/patient-payments-and-adjustments/patient-payments-and-adjustments.selectors';
import BaseTransactionField from '../components/BaseTransactionField';
import { getMaxPayment, getTransactionAmount } from '../LedgerUtils';
import { TransactionLookup } from 'state/slices/ledger/patient-payments-and-adjustments/patient-payments-and-adjustments.state';

type Props = {
    encounterSummary: IEncounterSummary | undefined;
    procedureSummary: ProcedureSummaryWithEncounterIdAndTransactionId | undefined;

    transactions: TransactionLookup;
    adjustmentTransactions?: TransactionLookup;

    onChangePayment?: (encounterId: string, transactionId: string, amount: number) => void;

    paymentAmount: number;
    remainingPaymentAmount: number;

    feeProp?: 'patientEstimate' | 'commonPatientFee';
};

export default function PaymentTransactionField({
    encounterSummary,
    procedureSummary,
    paymentAmount,
    remainingPaymentAmount,
    onChangePayment,
    transactions,
    adjustmentTransactions,
    feeProp = 'commonPatientFee',
}: Props) {
    const overpayment = useSelector(selectPatientOverpaymentOrOverAdjustment);

    const encounterId = encounterSummary?.encounterId ?? '';
    const procedureTransactionId = procedureSummary?.patientTransactionId;
    const adjustmentTransactionId = procedureSummary?.adjustmentTransactionId;

    const transaction =
        procedureTransactionId && encounterId && !isEmpty(transactions)
            ? transactions[encounterId][procedureTransactionId]
            : undefined;
    const adjustmentTransaction =
        adjustmentTransactionId && encounterId && !isEmpty(adjustmentTransactions)
            ? adjustmentTransactions[encounterId][adjustmentTransactionId]
            : undefined;

    const _onChange = (e?: FormEvent, input?: number | undefined) => {
        if (transaction && procedureSummary) {
            const inputValue = input ?? 0;
            const adjustmentAmount = adjustmentTransaction?.amount ?? 0;
            const patientFee = procedureSummary[feeProp] - adjustmentAmount;
            const newFee = patientFee < 0 ? 0 : patientFee;

            const amount = getTransactionAmount({
                input: inputValue,
                //For now, until we handle positive adjustments:
                patientFee: overpayment ? remainingPaymentAmount + transaction.amount : newFee,
                remainingPaymentAmount,
                transactionAmount: transaction.amount,
            });

            if (onChangePayment) onChangePayment(encounterId, transaction.id, amount);
        }
    };

    const disabled = !paymentAmount && !transaction?.amount;

    return (
        <BaseTransactionField
            disabled={disabled}
            max={getMaxPayment({ overpayment, paymentAmount: procedureSummary ? procedureSummary[feeProp] : undefined })}
            transactionAmount={transaction?.amount}
            onChange={_onChange}
            maxAmountButtonDisabled={overpayment}
        />
    );
}
