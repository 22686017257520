import {
    Icon,
    IIconProps,
    ILinkProps,
    ITheme,
    Label,
    Link,
    memoizeFunction,
    mergeStyleSets,
    Stack,
    useTheme,
} from '@fluentui/react';
import React, { MouseEvent } from 'react';

export interface ITileStyles {
    title?: React.CSSProperties;
    root?: React.CSSProperties;
    link?: React.CSSProperties;
    tile?: React.CSSProperties;
}

export enum TileSize {
    Small = 125,
    Medium = 150,
    Large = 175,
}

export interface ITileProps {
    title?: string;
    body?: JSX.Element | string;
    linkProps?: ILinkProps;
    linkText?: string;
    iconProps?: IIconProps;
    styles?: ITileStyles;
    onClick?: (ev: MouseEvent<HTMLDivElement> | undefined) => void;
    tileSize?: TileSize;
    disabled?: boolean;
}

export default function Tile(props: ITileProps): JSX.Element {
    const theme = useTheme();

    const { styles, title, body, iconProps, linkProps, linkText, onClick, tileSize = TileSize.Small, disabled } = props;

    const { linkText: linkTextClass, root, title: titleClass } = getClasses(theme, tileSize, onClick, disabled);

    const _onClick = (ev: MouseEvent<HTMLDivElement> | undefined) => {
        if (onClick && !disabled) onClick(ev);
    };

    return (
        <div className={root} style={styles?.root} onClick={_onClick}>
            <Stack verticalAlign="center" horizontalAlign="center" grow>
                {title && (
                    <Stack.Item>
                        <Label className={titleClass} style={styles?.title}>
                            {title}
                        </Label>
                    </Stack.Item>
                )}
                <Stack horizontalAlign="center" grow>
                    {body && (
                        <Stack.Item
                            style={{
                                color: disabled ? theme.palette.neutralTertiaryAlt : 'white',
                            }}
                        >
                            {body}
                        </Stack.Item>
                    )}
                    {iconProps && (
                        <Stack.Item>
                            <Icon
                                {...iconProps}
                                style={{
                                    fontSize: 40,
                                    color: disabled ? theme.palette.neutralTertiaryAlt : 'white',
                                    ...iconProps.style,
                                }}
                            />
                        </Stack.Item>
                    )}
                    {(linkProps || linkText) && (
                        <Link disabled={disabled} className={linkTextClass} style={styles?.link} {...linkProps}>
                            {linkText}
                        </Link>
                    )}
                </Stack>
            </Stack>
        </div>
    );
}

const classNames = {
    root: 'tile',
    title: 'tile_title',
    linkText: 'tile_link_text',
};

const getClasses = memoizeFunction(
    (theme: ITheme, tileSize: TileSize, onClick?: (ev: MouseEvent<HTMLDivElement>) => void, disabled?: boolean) =>
        mergeStyleSets({
            root: [
                classNames.root,
                {
                    backgroundColor: !disabled ? theme.palette.themePrimary : `${theme.palette.neutralQuaternary} !important`,
                    padding: 20,
                    paddingTop: 5,
                    paddingBottom: 5,
                    borderRadius: 4,
                    width: tileSize,
                    transition: 'text-shadow .15s',
                    display: 'flex',
                    alignItems: 'center',
                    selectors: {
                        ':hover': {
                            cursor: onClick && !disabled ? 'pointer' : undefined,
                            textShadow: !disabled ? '0 3px 3px rgba(0,0,0,0.5)' : undefined,
                        },
                    },
                },
            ],
            title: [
                classNames.title,
                {
                    textAlign: 'center',
                    color: disabled ? theme.palette.neutralTertiary : 'white',
                    pointerEvents: 'none',
                    borderRadius: 3,
                    padding: '3px 10px',
                },
            ],
            linkText: [
                classNames.linkText,
                {
                    textAlign: 'center',
                    color: disabled ? theme.palette.neutralTertiary : 'white',
                    selectors: {
                        ':focus': {
                            color: 'white',
                        },
                    },
                },
            ],
        }),
);
