import { Spinner, Text, SelectionMode } from '@fluentui/react';
import { ClinicalAlertType, IClinicalAlert, clinicalAlertTypeDisplayName } from 'api/models/clinical-alert.model';
import { useSelector } from 'hooks';
import { useParams } from 'react-router-dom';
import { RouteParams } from 'interfaces/route-params';
import {
    selectPatientClinicalAlertLookupIsLoading,
    selectPatientClinicalAlertsByType,
} from 'state/slices/clinical-alert/clinical-alerts.selectors';
import { classicDateOnly } from 'utils/dateOnly';
import { useDispatch } from 'react-redux';
import { selectUserIdentitiesData } from 'state/slices/users-identities/user-identities.selectors';
import { useEffect } from 'react';
import { getMissingUserIdentities } from 'state/slices/users-identities/user-identities.actions';
import { setEditClinicalAlertData } from 'state/slices/clinical-alert/clinical-alerts.slice';
import { IUserIdentity } from 'api/models/account.model';
import { Dictionary } from '@reduxjs/toolkit';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import { SubSection, SortableDetailsList } from 'components';
import { EditDetailsColumn } from 'components/EditDetailsColumn';

export function getCreatedByClinicalAlert(
    item: IClinicalAlert | undefined,
    users: Dictionary<IUserIdentity>,
    isFirstNameFirst?: boolean,
) {
    const user = users[item?.createdBy ?? ''];
    if (user?.displayName)
        return isFirstNameFirst ? `${user?.firstName ?? ''}${user?.lastName ? ` ${user.lastName}` : ''}` : user.displayName;
    if (item?.createdBy === 'data-exchange-service-id') return 'athenaOne';
    if (item?.createdBy === 'ConversionImported') return 'Conversion Imported';
    if (item?.createdBy) return item?.createdBy;
    return '';
}

const columnsLookup: Record<ClinicalAlertType, ISortableColumn<IClinicalAlert>[] | undefined> = {
    [ClinicalAlertType.PreMedication]: [
        {
            key: 'associatedProblem',
            minWidth: 150,
            maxWidth: 350,
            name: 'Associated Problem',
            fieldName: 'problem',
            getValueKey: (item) => item?.references?.problemName ?? '',
            onRender: (item) => (
                <Text variant="mediumPlus">
                    <span title={item?.references?.problemName ?? ''}>{item?.references?.problemName ?? ''}</span>
                </Text>
            ),
        },
    ],
    [ClinicalAlertType.PatientAlert]: [
        {
            key: 'description',
            minWidth: 150,
            maxWidth: 350,
            name: 'Description',
            fieldName: 'description',
            getValueKey: (item) => item?.references?.description ?? '',
            onRender: (item) => (
                <Text variant="mediumPlus">
                    <span title={item?.references?.description ?? ''}>{item?.references?.description ?? ''}</span>
                </Text>
            ),
        },
    ],
};
type Props = {
    type: ClinicalAlertType;
};

export default function AlertsDetailsList({ type }: Props) {
    const dispatch = useDispatch();
    const { patientId, tenantId } = useParams<RouteParams>();

    const clinicalAlerts = useSelector((state) => selectPatientClinicalAlertsByType(state, type));
    const clinicalAlertsLoading = useSelector((state) => selectPatientClinicalAlertLookupIsLoading(state, patientId));
    const users = useSelector(selectUserIdentitiesData);

    useEffect(() => {
        if (clinicalAlerts.length)
            dispatch(getMissingUserIdentities({ tenantId, userIds: [...clinicalAlerts.map((ca) => ca.createdBy ?? '')] }));
    }, [tenantId, clinicalAlerts, dispatch]);

    const onEdit = (clinicalAlert: IClinicalAlert) => dispatch(setEditClinicalAlertData({ clinicalAlert }));

    //data-exchange-service-id
    const columns: ISortableColumn<IClinicalAlert>[] = [
        {
            key: 'date',
            minWidth: 80,
            maxWidth: 110,
            name: 'Date',
            fieldName: 'createdOn',
            onRender: (item) => {
                if (!item) return null;
                return (
                    <EditDetailsColumn
                        editOnClick={() => onEdit(item)}
                        title={item?.createdOn ? classicDateOnly(item.createdOn) : 'N/A'}
                    />
                );
            },
        },
        {
            key: 'createdBy',
            minWidth: 100,
            maxWidth: 200,
            name: 'Created By',
            fieldName: 'createdBy',
            onRender: (item) => <EditDetailsColumn title={getCreatedByClinicalAlert(item, users)} />,
        },
        {
            key: 'note',
            minWidth: 150,
            maxWidth: 350,
            name: 'Note',
            fieldName: 'note',
            onRender: (item) => <EditDetailsColumn title={item?.note ?? ''} titleProps={{ title: item?.note ?? '' }} />,
        },
        ...(columnsLookup[type] ?? []),
    ];

    if (!clinicalAlerts.length) return null;

    return (
        <SubSection title={clinicalAlertTypeDisplayName[type]}>
            {clinicalAlertsLoading ? (
                <Spinner label="Loading Alerts..." labelPosition="right" />
            ) : (
                <SortableDetailsList
                    columns={columns}
                    sortOnMount
                    sortColumns={['date']}
                    initialSortDirection={['desc']}
                    selectionMode={SelectionMode.none}
                    onActiveItemChanged={undefined}
                    items={clinicalAlerts}
                />
            )}
        </SubSection>
    );
}
