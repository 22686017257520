import { IChartDentition } from 'api/models/chart-dentition.model';

const primaryDentition: IChartDentition[] = [
    {
        id: '1',
        isDeleted: false,
        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '2',
        isDeleted: false,
        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '3',
        isDeleted: false,
        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '4',
        isDeleted: false,

        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '5',
        isDeleted: false,

        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '6',
        isDeleted: false,

        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '7',
        isDeleted: false,

        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '8',
        isDeleted: false,

        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '9',
        isDeleted: false,

        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '10',
        isDeleted: false,

        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '11',
        isDeleted: false,

        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '12',
        isDeleted: false,

        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '13',
        isDeleted: false,

        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '14',
        isDeleted: false,

        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '15',
        isDeleted: false,

        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '16',
        isDeleted: false,

        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '17',
        isDeleted: false,

        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '18',
        isDeleted: false,

        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '19',
        isDeleted: false,

        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '20',
        isDeleted: false,

        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '21',
        isDeleted: false,

        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '22',
        isDeleted: false,

        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '23',
        isDeleted: false,

        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '24',
        isDeleted: false,

        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '25',
        isDeleted: false,

        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '26',
        isDeleted: false,

        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '27',
        isDeleted: false,

        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '28',
        isDeleted: false,

        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '29',
        isDeleted: false,

        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '30',
        isDeleted: false,

        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '31',
        isDeleted: false,

        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
    {
        id: '32',
        isDeleted: false,

        dentitionMode: 'Primary',
        facialDegree: 0,
        occlusalDegree: 0,
        xPosition: 0,
        yPosition: 0,
    },
];

export default primaryDentition;
