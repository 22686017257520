import { createSlice } from '@reduxjs/toolkit';
import { adjustmentDetailsSummaryReducers } from './adjustment-details-summary/adjustment-details-summary.reducers';
import { batchReducers } from './batch/batch.reducers';
import { chargeAndPaymentsReducers } from './charge-and-payments/charge-and-payments.reducers';
import { clinicalMeasureDetailsSummaryReducers } from './clinical-measure/clinical-measure.reducers';
import { clinicalNoteReportReducers } from './clinical-notes-reports/clinical-notes-reports.reducers';
import { dailyBalanceReducers } from './daily-balance/daily-balance.reducers';
import { encounterDetailSummaryReducers } from './encounter/encounter-reports.reducers';
import { proceduresDateAgingReducers } from './procedures-Date-Aging-Report/procedures-date-aging-report.reducer';
import reportingGroupsReducers, { reportingGroupsExtraReducers } from './reporting-groups/reporting-groups.reducers';
import { reportingExtraReducers, reportingReducers } from './reporting.reducers';
import initialReportingState from './reporting.state';
import slidingFeeEncounterDetailsReducers from './sliding-fee-encounter-details/sliding-fee-encounter-details.reducers';
import timeOfServiceReducers from './time-of-service/time-of-service.reducers';
import { udsTableReducers } from './uds-table/uds-table.reducers';
import { depositSlipReducers } from './deposit-slip/deposit-slip.report.reducers';
import { billingStatusReducers } from './billing-status/billing-status.reducers';
import { wrapReducers } from './wrap/wrap.reducers';
import { toothLedgerReducers } from './tooth-ledger/toothLedger.reducers';
import { appointmentsReportNoEncounterAssociatedReducers } from './appointments-no-encounter/appointmentsReportNoEncounterAssociated.reducers';
import { treatmentPlanPhaseOneReducers } from './tx-plan-phase-1/tx-plan-phase-1.reducers';
import dentalSealantReducers from './dental-sealants/dental-sealants.reducers';
import { bulkPatientStatementReducers } from './bulk-patient-statement/bulk-patient-statement.reducers';
import { uacReducers } from './uac/uac.reducers';
import { financialTransactionDetailsReportReducers } from 'pages/Reporting/components/financial-transaction-details/reducer';
import { statementValidationReducers } from 'pages/Reporting/components/statement-validation/reducer';

const reportingSlice = createSlice({
    name: 'reporting',
    reducers: {
        ...reportingReducers,
        ...clinicalNoteReportReducers,
        ...adjustmentDetailsSummaryReducers,
        ...chargeAndPaymentsReducers,
        ...dailyBalanceReducers,
        ...slidingFeeEncounterDetailsReducers,
        ...timeOfServiceReducers,
        ...batchReducers,
        ...proceduresDateAgingReducers,
        ...reportingGroupsReducers,
        ...encounterDetailSummaryReducers,
        ...clinicalMeasureDetailsSummaryReducers,
        ...udsTableReducers,
        ...depositSlipReducers,
        ...billingStatusReducers,
        ...wrapReducers,
        ...toothLedgerReducers,
        ...appointmentsReportNoEncounterAssociatedReducers,
        ...treatmentPlanPhaseOneReducers,
        ...dentalSealantReducers,
        ...bulkPatientStatementReducers,
        ...uacReducers,
        ...statementValidationReducers,
        ...financialTransactionDetailsReportReducers,
    },
    initialState: initialReportingState,
    extraReducers: (builder) => {
        reportingExtraReducers(builder);
        reportingGroupsExtraReducers(builder);
    },
});

const { actions, reducer } = reportingSlice;

export default reducer;

export const {
    cleanupSelectedReport,
    setProviderProductivityDateRange,
    toggleProviderProductivityTreatingProvider,
    cleanupProviderProductivityDates,
    cleanupSelectedReportProperties,
    setReportValidationErrors,

    //appointment report
    setAppointmentsDetailsProp,
    toggleAppointmentId,

    //Clinical note reports
    setCurrentlySelectedClinicalNote,
    toggleSelectedClinicalNoteInQueue,
    setClinicalPanelOpen,
    cleanupClinicalNoteReportQueue,
    cleanupCurrentlySelectedClinicalNoteReport,
    //Financial Summary
    setFinanceSummaryDateGroup,
    setFinanceSummaryGroupCol,
    setFinanceSummaryDateType,
    setFinanceSummaryEndDate,
    setFinanceSummaryStartDate,

    toggleProceduresFinancialSummaryId,
    //Daily Balance
    setDailyBalanceProp,
    toggleDailyBalanceListId,

    //Adjustment Details Summary
    toggleAdjDetailsSummaryListId,
    setAdjustmentDetailsSummaryProp,

    //Payments and Changes
    setChartAndPaymentsProp,
    toggleChargeAndPaymentsListId,

    //Sliding Fee Encounter Details
    setSlidingFeeEncounterDetailsProp,
    updateSlidingFeeEncounterDetailQueryProp,
    //Time of service
    setTimeOfServiceProp,
    toggleTimeOfServiceQueryItem,
    //Batching
    setBatchProp,

    //Procedures Date Aging Report
    setProceduresDateAgingProp,
    toggleProceduresDateAgingId,

    //Encounter Detail Summary
    setEncounterDetailProp,
    setEncounterSummaryProp,
    toggleEncounterDetailsId,
    toggleEncounterSummaryId,
    //Reporting Groups
    addSelectedReportingCategoryItem,
    removeSelectedReportingCategoryItem,
    setSelectedReportingCategoryItems,
    setSelectedReportingCategory,
    setSelectedReportingGroup,
    updateSelectedReportingCategoryProp,
    updateSelectedReportingGroupProp,
    cleanupReportingGroup,
    cleanupSelectedReportingCategory,
    removeSelectedReportingCategory,

    setSelectedReportingCategoryFromId,
    setSelectedReportingGroupFromId,
    //Clinical Measure Detail
    setClinicalMeasureSummaryProp,
    setClinicalMeasureDetailsProp,

    //UDS Table
    setUDSTableProp,

    //Deposit Slip
    setDepositSlipDetailProp,
    setDepositSlipSummaryProp,
    toggleDepositSlipDetailListId,

    //Billing Status
    setBillingStatusProp,
    toggleBillingStatusId,

    //wrap report
    setWrapProp,
    toggleWrapId,
    //Tooth Ledger
    setToothLedgerProp,

    //encounter Status
    setEncounterStatusProp,
    toggleEncounterStatusId,

    //appointments report no encounter associated
    setAppointmentNoEncounterProp,
    toggleAppointmentNoEncounterListId,
    //treatment plan phase 1 report
    setTreatmentPlanPhase1BaseProp,
    setTreatmentPlanPhase1DetailProp,
    toggleTreatmentPlanPhase1BaseIds,
    setTreatmentPlanPhase1BaseDenominatorCodes,
    toggleTreatmentPlanPhase1DetailIds,

    //dental sealants
    setDentalSealantDetailsProp,
    updateDentalSealantDetailQueryProp,
    setDentalSealantSummaryProp,
    updateDentalSealantSummaryQueryProp,

    //bulk patient statement
    setBulkPatientStatementProp,
    toggleBulkPatientStatementListId,

    //uac
    setUACProp,

    // Financial Transaction Details
    setFinancialTransactionDetailsProp,
    toggleFinancialTransactionDetailsId,
    //Statement Validation
    setStatementValidationProp,
} = actions;
