import { ConstrainMode, MessageBar, MessageBarType, Stack } from '@fluentui/react';
import { useSelector } from 'hooks';
import useRouter from 'hooks/store/useRouter';
import { useEffect } from 'react';
import {
    selectEncounterClaimsSummaryError,
    selectSelectedClaimHistoryEncounterSummary,
} from 'state/slices/ledger/claim-history/claim-history.selectors';
import EncounterLedger from '../LedgerView/EncounterLedger';
import ClaimHistoryList from './ClaimHistoryList';
import EncounterCorrespondence from './EncounterCorrespondence';
import ReadyToBillHistory from './ReadyToBillHistory';
import SelectedClaimHistoryCommandBar from './SelectedClaimHistoryCommandBar';
import { useParams } from 'react-router';
import { getEncounterClaimsSummary } from 'state/slices/ledger/claim-history/claim-history.actions';
import { setSelectedClaimHistoryEncounterSummary } from 'state/slices/ledger/ledger.slice';
import { selectEncounterLedgerTotalsViews, selectEncounterLedgerViewLoadingStatus } from 'state/slices/ledger/ledger.selectors';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { Helmet } from 'react-helmet';

export default function ClaimHistoryDetail() {
    const dispatch = useAppDispatch();
    const { tenantId, claimHistoryEncounterId } = useParams<{ tenantId: string; claimHistoryEncounterId: string }>();

    const selectedEncounterSummary = useSelector(selectSelectedClaimHistoryEncounterSummary);
    const encounterSummaryError = useSelector(selectEncounterClaimsSummaryError);

    const views = useSelector(selectEncounterLedgerTotalsViews);
    const loading = useSelector(selectEncounterLedgerViewLoadingStatus);

    const { pushWithBaseParameters } = useRouter();

    useEffect(() => {
        if (loading === LoadingStatus.Completed) {
            const view = views.find((v) => v.encounterId === claimHistoryEncounterId);
            if (view) {
                dispatch(setSelectedClaimHistoryEncounterSummary(view));
            } else {
                pushWithBaseParameters(`ledger/claim-history`);
            }
        }
    }, [pushWithBaseParameters, claimHistoryEncounterId, views, loading, dispatch]);

    useEffect(() => {
        if (!selectedEncounterSummary?.encounterId) return;

        const _getEncounterClaimsSummary = dispatch(
            getEncounterClaimsSummary({ tenantId, encounterId: selectedEncounterSummary.encounterId }),
        );

        return () => {
            _getEncounterClaimsSummary.abort('CancelGetEncounterClaimsSummaryPageUnloaded');
            dispatch(setSelectedClaimHistoryEncounterSummary(undefined));
        };
    }, [tenantId, selectedEncounterSummary, dispatch]);

    return (
        <Stack tokens={{ childrenGap: 10 }} grow>
            <Helmet title="Ledger - Claim History - Details" />
            <MessageBar messageBarType={MessageBarType.warning}>
                The Claims History Tab is currently in BETA testing. The information being displayed may not accurately reflect
                the actual claim data.
            </MessageBar>
            <Stack>
                <SelectedClaimHistoryCommandBar />
                {encounterSummaryError && (
                    <MessageBar messageBarType={MessageBarType.error}>
                        Something went wrong while loading claim history.
                    </MessageBar>
                )}
            </Stack>
            <EncounterLedger
                fetchTotalsView={false}
                showTotals={false}
                constrainMode={ConstrainMode.horizontalConstrained}
                views={selectedEncounterSummary ? [selectedEncounterSummary] : []}
            />
            <Stack styles={{ root: { overflow: 'auto' } }} tokens={{ childrenGap: 10 }} horizontal>
                {/* <Stack.Item grow>
                    <CurrentStatusSummary />
                </Stack.Item> */}
                <Stack.Item styles={{ root: { maxWidth: '50%' } }} grow={2}>
                    <ReadyToBillHistory />
                </Stack.Item>
                <Stack.Item grow={1}>
                    <EncounterCorrespondence />
                </Stack.Item>
            </Stack>
            <ClaimHistoryList />
        </Stack>
    );
}
