import { ToothArea } from 'api/models/tooth-area';
import { IChartSurfaceData } from './SurfaceContainer';
import Tooth from './Tooth';

/**
 *  Get the tooth area name of the current sprite
 *
 * @type {(ToothArea | undefined)}
 */
export function getSurfaceName(tooth: Tooth, surfaceData: IChartSurfaceData): ToothArea | undefined {
    const position = tooth.getToothPosition;
    const isMolar = position <= 5 || position >= 28 || (position >= 12 && position <= 21);
    const isUpper = position <= 16;
    switch (surfaceData.id) {
        case 1:
            if (!isUpper) {
                return ToothArea.Lingual;
            } else if (isMolar) {
                return ToothArea.Buccal;
            } else {
                return ToothArea.Facial;
            }
        case 2:
            if (isUpper) {
                if (position <= 8) {
                    return ToothArea.Mesial;
                }
                if (position >= 28) {
                    return ToothArea.Distal;
                } else {
                    return ToothArea.Mesial;
                }
            } else {
                return ToothArea.Mesial;
            }

        case 3:
            return isUpper ? ToothArea.Lingual : isMolar ? ToothArea.Buccal : ToothArea.Facial;
        case 4:
            if (isUpper) {
                if (position >= 28) {
                    return ToothArea.Mesial;
                } else {
                    return ToothArea.Distal;
                }
            } else {
                return ToothArea.Distal;
            }

        case 5:
            return isMolar ? ToothArea.Occlusal : ToothArea.Incisal;
        default:
            return undefined;
    }
}
