import { IconButton } from '@fluentui/react';
import IWorkListAppointmentView from 'api/models/worklist-appointment-view';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import { RouteParams } from 'interfaces/route-params';
import { getDisableCancelAppointment } from 'pages/components/CancelAppointmentModal/CancelAppointmentModal';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setWorkListLayerOpen, setSelectedWorkListEditItem } from 'state/slices/admin-huddle/worklists/worklist.slice';
import { setCancelAppointmentModalOpen } from 'state/slices/scheduling/scheduling.slice';

const outstandingCheckinAction: ISortableColumn<IWorkListAppointmentView> = {
    key: 'cancelAppointment',
    minWidth: 20,
    maxWidth: 20,
    isResizable: true,
    name: '',
    onRender: OutstandingCheckinActionsColumn,
};

export default outstandingCheckinAction;

function OutstandingCheckinActionsColumn(item: IWorkListAppointmentView | undefined) {
    const dispatch = useDispatch();
    const { tenantId } = useParams<RouteParams>();

    if (!item) return null;

    return (
        <IconButton
            styles={{ root: { height: 20, width: 20 }, menuIcon: { fontWeight: 'bolder !important' } }}
            onClick={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
            }}
            menuIconProps={{ iconName: 'MoreVertical' }}
            menuProps={{
                items: [
                    {
                        key: 'cancelAppointment',
                        text: 'Cancel Appointment',
                        iconProps: { iconName: 'Delete' },
                        disabled: getDisableCancelAppointment(item.encounterId, item.trackerStatus),
                        onClick: () => {
                            //We need to set the appointment to remove id in the same area of state we use for the cancellation modal.
                            dispatch(setCancelAppointmentModalOpen({ appointmentToRemoveId: item.id }));
                            //This will open the modal in the context of the worklist cancellation modal component.
                            dispatch(setWorkListLayerOpen({ layer: 'CancelAppointmentModal', isOpen: true }));
                            //Need to set this so recent items gets populated...
                            dispatch(setSelectedWorkListEditItem(item));
                        },
                    },
                    {
                        key: 'navigateToLedger',
                        text: 'Go to Patient Ledger',
                        iconProps: { iconName: 'Money' },
                        onClick: () => {
                            if (item.patientId) {
                                window.open(`/${tenantId}/patient/${item.patientId}/ledger`);
                            }
                        },
                    },
                ],
            }}
        />
    );
}
