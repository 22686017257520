import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { ChartProcedurePreAuth } from 'api/models/chart.model';
import { IPredeterminationProcedure } from 'api/models/predeterminations.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { IWorkListsState } from '../worklist.state';
import { savePredetermination } from './predetermination.actions';
import { PredeterminedRequestsState, predeterminationFiltersInitialState } from './predeterminations.state';

export const predeterminationsReducers = {
    // Set Filters
    setDosFilter: (
        state: PredeterminedRequestsState,
        action: PayloadAction<{ path: 'dosStart' | 'dosEnd'; value: string }>,
    ): void => {
        state.filters[action.payload.path] = action.payload.value;
    },
    setAuthStatusFilter: (state: PredeterminedRequestsState, action: PayloadAction<ChartProcedurePreAuth>): void => {
        state.filters.authStatus = action.payload;
    },
    clearPredeterminationFilters: (state: PredeterminedRequestsState): void => {
        state.filters = predeterminationFiltersInitialState;
    },

    // Edit Modal
    setEditPredeterminationModalOpen: (state: PredeterminedRequestsState, action: PayloadAction<boolean>): void => {
        state.editModalOpen = action.payload;
    },
    cleanupSelectedPredetermination: (state: PredeterminedRequestsState): void => {
        state.editModalOpen = false;
        state.selectedPredetermination = undefined;
    },
};
export const predeterminationExtraReducers = (
    builder: ActionReducerMapBuilder<PredeterminedRequestsState>,
): ActionReducerMapBuilder<PredeterminedRequestsState> =>
    builder.addCase(savePredetermination.fulfilled, (state, action) => {
        if (action.payload) {
            state.selectedPredetermination = undefined;
            state.editModalOpen = false;
        }
    });

export const worklistPredeterminationExtraReducers = (
    builder: ActionReducerMapBuilder<IWorkListsState>,
): ActionReducerMapBuilder<IWorkListsState> =>
    builder
        .addCase(savePredetermination.pending, (state) => {
            state.saving = LoadingStatus.Pending;
        })
        .addCase(savePredetermination.fulfilled, (state, action) => {
            state.saving = LoadingStatus.Completed;
            if (action.payload && state.data) {
                const indexOfProcedure = (state.data as IPredeterminationProcedure[] | undefined)?.findIndex(
                    (p) => p.id === action.payload?.id,
                );
                if (
                    indexOfProcedure !== undefined &&
                    indexOfProcedure > -1 &&
                    (state.data as IPredeterminationProcedure[] | undefined)
                ) {
                    (state.data as IPredeterminationProcedure[])[indexOfProcedure] = action.payload;
                }
            }
        })
        .addCase(savePredetermination.rejected, (state) => {
            state.saving = LoadingStatus.Failed;
        });
