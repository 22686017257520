import { DefaultButton, MessageBar, MessageBarType, Panel, PanelType, PrimaryButton, Stack } from '@fluentui/react';
import useLookupsStore from 'hooks/store/useLookupsStore';
import { IValidationError } from 'hooks/useValidation';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { useEffect, useState } from 'react';
import { SelectedLookup } from 'state/slices/lookups/lookupsStateTypes';
import { v4 as uuid } from 'uuid';

export type EditComponentProps = {
    selectedLookup: SelectedLookup;
    setSelectedLookup: (entity?: SelectedLookup) => void;
    setSelectedLookupItemProp: (propPath: string, value: any) => void;
    setHasError: (hasError: boolean) => void;
    errors?: IValidationError[];
};

type CommonListParameters = {
    lookupType: string;
    displayName: string;
    editComponentBuilder: (props: EditComponentProps) => JSX.Element;
    panelType?: PanelType;
    onSave?: () => void;
    errors?: IValidationError[];
    isNewItem?: boolean;
    footerRightContent?: JSX.Element;
};

function LookupEditPanel<T>(props: CommonListParameters) {
    const { lookupType, displayName, editComponentBuilder, panelType, errors } = props;
    const { createLookup, updateLookup, selectedLookup, setSelectedLookup, setSelectedLookupItemProp } =
        useLookupsStore<T>(lookupType);

    const cleanupSelectedLookup = () => setSelectedLookup(undefined);
    const [hasError, setHasError] = useState<boolean>(false);

    useEffect(() => {
        if (selectedLookup?.savingStatus === LoadingStatus.Completed) {
            cleanupSelectedLookup();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLookup]);

    const isNewItem = !!selectedLookup?.isNew;

    const onSave = () => {
        if (props.onSave) {
            props.onSave();
            return;
        }
        if (selectedLookup)
            if (isNewItem) {
                createLookup({
                    ...selectedLookup,
                    item: {
                        ...selectedLookup.item,
                        id: selectedLookup.item.id ? selectedLookup.item.id : uuid(),
                    },
                });
            } else {
                updateLookup({
                    ...selectedLookup,
                });
            }
    };

    const isSaving = selectedLookup!.savingStatus === LoadingStatus.Pending;
    const onRenderFooterContent = () => (
        <Stack horizontal tokens={{ childrenGap: 10 }}>
            <PrimaryButton text="Save" title="Save" onClick={onSave} disabled={hasError || isSaving} />
            <DefaultButton text="Cancel" title="Cancel" onClick={cleanupSelectedLookup} disabled={isSaving} />
            <Stack grow horizontal horizontalAlign="end">
                {props.footerRightContent ?? null}
            </Stack>
        </Stack>
    );

    const panelText = isNewItem ? `Create ${displayName}` : `Edit ${displayName}`;
    const errorsList = (
        <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
            <strong>Error occurred while saving:</strong>
            <ul>
                {selectedLookup!.errors?.map((error, index) => (
                    <li key={`lookup-save-error-${index}`}>{error.message}</li>
                ))}
            </ul>
        </MessageBar>
    );

    return (
        <Panel
            headerText={panelText}
            type={panelType ? panelType : PanelType.medium}
            customWidth={'40vw'}
            isFooterAtBottom={true}
            isOpen={true}
            onDismiss={cleanupSelectedLookup}
            onRenderFooterContent={onRenderFooterContent}
        >
            {editComponentBuilder({
                selectedLookup,
                setSelectedLookup,
                setHasError,
                setSelectedLookupItemProp,
                errors,
            } as EditComponentProps)}
            {selectedLookup!.savingStatus === LoadingStatus.Failed && errorsList}
        </Panel>
    );
}

export default LookupEditPanel;
