import {
    SelectionMode,
    MessageBar,
    Selection,
    ScrollablePane,
    ConstrainMode,
    Stack,
    ISelection,
    IObjectWithKey,
    DetailsRow,
    useTheme,
    MessageBarType,
} from '@fluentui/react';
import { ExpandableDetailsList } from 'components';
import { useSelector } from 'hooks';
import useUserIdentities from 'hooks/store/useUserIdentities';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { setSelectPaymentHistoryRows } from 'state/slices/ledger/ledger.slice';
import {
    paymentHistoryPaymentSourceUserIds,
    paymentHistoryTableFilteredViews,
    PaymentHistoryTableRow,
    PaymentHistoryTableView,
    selectNegativeReversalsLookup,
    selectPaymentHistoryError,
    selectPaymentHistoryFilters,
    selectPaymentHistoryLoading,
    selectPaymentHistoryLoadingStatus,
    selectPaymentHistoryReversedPaymentSources,
    selectPaymentHistorySelectedViews,
    selectPaymentHistoryTableView,
} from 'state/slices/ledger/payment-history/payment-history.selectors';
import { usdCurrencyFormatter } from 'utils';
import dateOnly, { classicDateOnly } from 'utils/dateOnly';
import PaymentHistoryPaymentList from './PaymentHistoryPaymentList';

const _renderDateOfEntry = (item?: PaymentHistoryTableRow) => <span>{item ? classicDateOnly(item.dateOfEntry) : ''}</span>;
const _renderAmount = (item?: PaymentHistoryTableRow) => <span>{usdCurrencyFormatter.format(item?.amount ?? 0)}</span>;
const _renderNote = (item?: PaymentHistoryTableRow) => <span title={item?.note ?? ''}>{item?.note ?? ''}</span>;

export default function PaymentHistoryList() {
    const dispatch = useDispatch();
    const { palette } = useTheme();

    const { getMissingUserIdentities, userIdentitiesData } = useUserIdentities();

    const filters = useSelector(selectPaymentHistoryFilters);
    const paymentHistory = useSelector(selectPaymentHistoryTableView);
    const filteredPaymentHistory = useSelector(paymentHistoryTableFilteredViews);
    const paymentSourceUserIds = useSelector(paymentHistoryPaymentSourceUserIds);
    const loading = useSelector(selectPaymentHistoryLoading);
    const loadingStatus = useSelector(selectPaymentHistoryLoadingStatus);
    const error = useSelector(selectPaymentHistoryError);
    const selectedPaymentSourceViews = useSelector(selectPaymentHistorySelectedViews);
    const reversedPaymentSources = useSelector(selectPaymentHistoryReversedPaymentSources);
    const negativeReversalLookup = useSelector(selectNegativeReversalsLookup);

    const { dateOfEntry, method, methodIdentifier, transactionDate } = filters;
    const filtersExist = !!dateOfEntry || !!method || !!methodIdentifier || !!transactionDate;

    function canSelectItem(item: PaymentHistoryTableRow) {
        return !item.references?.reversedPaymentSourceId && !reversedPaymentSources[item.id];
    }

    const paymentSourceSelection = useMemo(() => {
        return new Selection({
            onSelectionChanged: () => {
                const selectedRows = _getPhaseSelectionDetails();
                dispatch(setSelectPaymentHistoryRows(selectedRows));
            },
            getKey: (item) => item.id,
            items: paymentHistory,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (paymentSourceUserIds.length) getMissingUserIdentities(paymentSourceUserIds);
    }, [paymentSourceUserIds]);

    useEffect(() => {
        paymentSourceSelection.canSelectItem = canSelectItem;
    }, [reversedPaymentSources]);

    useEffect(() => {
        if (!selectedPaymentSourceViews.length) {
            paymentSourceSelection.setAllSelected(false);
        }
    }, [selectedPaymentSourceViews.length, paymentSourceSelection]);

    const _renderCreatedOn = (item?: PaymentHistoryTableRow) => (
        <Stack>
            {item?.references?.reversedPaymentSourceId && <b>Reversal</b>}
            {negativeReversalLookup[item?.negativePaymentReasonId ?? '']?.displayName ? (
                <span>({negativeReversalLookup[item?.negativePaymentReasonId ?? '']?.displayName})</span>
            ) : null}
            {reversedPaymentSources[item?.id ?? ''] && <b>Reversed</b>}
            <span>{item && item.createdOn ? dateOnly(item?.createdOn, 'Pp') : ''}</span>
        </Stack>
    );

    function _getPhaseSelectionDetails() {
        return paymentSourceSelection.getSelection() as PaymentHistoryTableView;
    }

    if (loadingStatus === LoadingStatus.Idle) return null;
    if (!loading && error)
        return <MessageBar messageBarType={MessageBarType.error}>Something went wrong while fetching data.</MessageBar>;
    if (!loading && !paymentHistory.length) return <MessageBar>No payments found.</MessageBar>;
    if (!loading && !filteredPaymentHistory.length && paymentHistory.length && filtersExist)
        return <MessageBar>There are no payments that match the filter criteria.</MessageBar>;

    return (
        <div style={{ position: 'relative', flex: 1, display: 'flex' }}>
            <ScrollablePane>
                <ExpandableDetailsList<PaymentHistoryTableRow>
                    items={filteredPaymentHistory}
                    allowMultipleRowsExpanded
                    stickyHeader
                    constrainMode={ConstrainMode.unconstrained}
                    canExpandAll
                    showGrid
                    sortColumns={['createdOn']}
                    selection={paymentSourceSelection as unknown as ISelection<IObjectWithKey>}
                    sortOnMount={true}
                    enableShimmer={loading}
                    initialSortDirection="desc"
                    compact
                    onRenderRow={(props) => {
                        if (props)
                            return (
                                <DetailsRow
                                    styles={{
                                        root: {
                                            backgroundColor:
                                                reversedPaymentSources[props.item.id] ||
                                                props.item.references?.reversedPaymentSourceId
                                                    ? palette.neutralLighterAlt
                                                    : undefined,
                                        },
                                    }}
                                    {...props}
                                />
                            );
                        return null;
                    }}
                    canExpandRow={(item) => !!item.payments.length}
                    onRenderExpandedRowContent={(props) => {
                        if (props) {
                            return <PaymentHistoryPaymentList payments={props.item.payments} />;
                        }
                        return null;
                    }}
                    columns={[
                        {
                            key: 'createdOn',
                            name: 'Trans. Date',
                            fieldName: 'createdOn',
                            minWidth: 150,
                            maxWidth: 170,
                            onRender: _renderCreatedOn,
                        },
                        {
                            key: 'dateOfEntry',
                            name: 'Batch Date',
                            fieldName: 'dateOfEntry',
                            minWidth: 120,
                            maxWidth: 120,
                            onRender: _renderDateOfEntry,
                        },
                        {
                            key: 'batchName',
                            name: 'Batch Name',
                            fieldName: 'batchName',
                            minWidth: 120,
                            maxWidth: 120,
                        },
                        {
                            name: 'User Name',
                            key: 'userName',
                            minWidth: 100,
                            maxWidth: 125,
                            onRender: (item) => {
                                if (item) {
                                    return (
                                        <span>
                                            {item?.createdBy ? userIdentitiesData[item?.createdBy]?.displayName : 'Unknown User'}
                                        </span>
                                    );
                                }
                            },
                        },
                        { key: 'method', name: 'Method', fieldName: 'method', minWidth: 100, maxWidth: 120 },
                        {
                            key: 'identifier',
                            name: 'Identifier',
                            fieldName: 'methodIdentifier',
                            minWidth: 100,
                            maxWidth: 120,
                        },
                        {
                            key: 'amount',
                            name: 'Amount',
                            fieldName: 'amount',
                            minWidth: 100,
                            maxWidth: 130,
                            onRender: _renderAmount,
                        },
                        {
                            key: 'notes',
                            name: 'Notes',
                            fieldName: 'note',
                            minWidth: 100,
                            onRender: _renderNote,
                        },
                    ]}
                    selectionMode={SelectionMode.single}
                />
            </ScrollablePane>
        </div>
    );
}
