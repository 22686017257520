import { Stack } from '@fluentui/react';
import { ClinicalAlertType, IClinicalAlert, clinicalAlertTypeDisplayName } from 'api/models/clinical-alert.model';
import { TModal } from 'components';
import { useSelector } from 'hooks';
import useValidation, { IValidationConfig } from 'hooks/useValidation';
import { RouteParams } from 'interfaces/route-params';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    selectCurrentClinicalAlertData,
    selectCurrentClinicalAlertDataIsSaving,
    selectCurrentClinicalAlertPanelOpen,
    selectIsEditingCurrentClinicalAlertData,
} from 'state/slices/clinical-alert/clinical-alerts.selectors';
import {
    cleanUpCurrentClinicalAlertData,
    closeCurrentClinicalAlert,
    setClinicalAlertValidationErrors,
} from 'state/slices/clinical-alert/clinical-alerts.slice';
import PreMedicationModal from './ClinicalAlertModalComponents/PreMedicationModal';
import AlertModal from './ClinicalAlertModalComponents/AlertModal';
import { addUpdateClinicalAlert } from 'state/slices/clinical-alert/clincal-alerts.actions';

function getValidationConfig(clinicalAlert?: IClinicalAlert): IValidationConfig {
    let config: IValidationConfig = [];

    switch (clinicalAlert?.type) {
        case ClinicalAlertType.PreMedication:
            config = [
                {
                    fieldName: 'Note',
                    validation: clinicalAlert?.references?.snomedCode ? ['characterLimit'] : ['required', 'characterLimit'],
                    value: clinicalAlert.note,
                    itemOptions: { characterLimit: 150 },
                },
            ];
            break;
        case ClinicalAlertType.PatientAlert: {
            config = [
                {
                    fieldName: 'Note',
                    validation: clinicalAlert?.references?.description ? ['characterLimit'] : ['required', 'characterLimit'],
                    value: clinicalAlert.note,
                    itemOptions: { characterLimit: 150 },
                },
                {
                    fieldName: 'Description',
                    validation: ['characterLimit'],
                    value: clinicalAlert?.references?.description,
                    itemOptions: { characterLimit: 80 },
                },
            ];
            break;
        }
    }

    return config;
}

const alertComponentLookup: Record<ClinicalAlertType, () => JSX.Element | null> = {
    [ClinicalAlertType.PreMedication]: PreMedicationModal,
    [ClinicalAlertType.PatientAlert]: AlertModal,
};

//Getting 400s OOHHH, our old save action used to generate a new guid for the alert...

export default function ClinicalAlertModal() {
    const dispatch = useDispatch();
    const { tenantId } = useParams<RouteParams>();

    const saving = useSelector(selectCurrentClinicalAlertDataIsSaving);
    const isOpen = useSelector(selectCurrentClinicalAlertPanelOpen);

    const clinicalAlertData = useSelector(selectCurrentClinicalAlertData);

    const isEditing = useSelector((state) => selectIsEditingCurrentClinicalAlertData(state));

    const _onDismiss = () => {
        dispatch(closeCurrentClinicalAlert());
    };

    const _onDismissed = () => {
        dispatch(cleanUpCurrentClinicalAlertData());
        cleanupError();
    };

    const onSave = () => {
        if (clinicalAlertData) dispatch(addUpdateClinicalAlert({ tenantId, clinicalAlert: clinicalAlertData }));
    };

    const [error, submit, cleanupError] = useValidation(getValidationConfig(clinicalAlertData), onSave);

    useEffect(() => {
        dispatch(setClinicalAlertValidationErrors(error));
    }, [error, dispatch]);

    const Component = clinicalAlertData?.type ? alertComponentLookup[clinicalAlertData.type] : undefined;

    return (
        <TModal
            title={`${isEditing ? 'Edit' : 'Add'} ${
                clinicalAlertData ? clinicalAlertTypeDisplayName[clinicalAlertData.type] : ''
            } Alert`}
            isDraggable
            modalProps={{
                isBlocking: false,
                isOpen,
                onDismiss: _onDismiss,
                onDismissed: _onDismissed,
            }}
            mainButtons={[
                {
                    type: 'PrimaryButton',
                    text: 'Save',
                    onClick: () => {
                        submit();
                    },
                    disabled: saving,
                },
                {
                    type: 'DefaultButton',
                    text: 'Cancel',
                    onClick: _onDismiss,
                },
            ]}
        >
            <Stack style={{ padding: 10 }}>{Component ? <Component /> : null}</Stack>
        </TModal>
    );
}
