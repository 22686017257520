import { createAsyncThunk } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import { ICommonTransaction } from 'api/models/encounter-ledger.model';
import { IPaymentSource } from 'api/models/payment-source.model';
import { AppThunk, RootState } from 'state/store';
import {
    createEstimateTransactions,
    createPaymentTransactions,
    getPatientEncounterSummariesWithLineItemsView,
    getPatientPaymentsAndAdjustmentsBillingProcedures,
    getTransactionsToSave,
} from '../patient-payments-and-adjustments/patient-payments-and-adjustments.actions';
import {
    selectUACDistributionPaymentSource,
    selectUACDistributionTotalRemaining,
    selectUACDistributionTransactions,
} from './uac-distribution.selectors';
import {
    clearAllTreatmentPlanCreditTransactions,
    clearEstimatePayments,
    clearPayments,
    payAllPatientEstimates,
    payAllPatientPayments,
    payAllTreatmentPlanCreditTransactions,
} from '../ledger.slice';
import {
    createAddTreatmentPlanCreditTransactions,
    getPatientTreatmentPlanCreditUnappliedPayments,
} from '../treatment-plan-credit-and-uac/treatment-plan-credit-and-uac.actions';
import {
    selectSignedTreatmentPlanViewProceduresWithTransactions,
    selectTreatmentPlanCreditAllowOverpayment,
} from '../treatment-plan-credit-and-uac/treatment-plan-credit-and-uac.selectors';
import { ITransaction } from 'api/models/patient-ledger.model';
import { IBatch } from 'api/models/batch.model';
import { goBack } from 'connected-react-router';
import IProcedureCreditTransaction from 'api/models/procedure-credit-transaction';
import {
    selectPatientEstimateOverpayment,
    selectPatientOverpaymentOrOverAdjustment,
} from '../patient-payments-and-adjustments/patient-payments-and-adjustments.selectors';

export const getUACDistributionTransactionAndPaymentSource = createAsyncThunk<
    { transaction: ICommonTransaction; paymentSource?: IPaymentSource },
    { tenantId: string; patientId: string; transactionId: string },
    { rejectValue: string }
>('getUACDistributionPaymentSource', async ({ tenantId, patientId, transactionId }, { rejectWithValue }) => {
    try {
        const { data: transaction } = await dentalApi.getPatientLedgerTransaction(tenantId, patientId, transactionId);

        if (transaction.paymentSourceId) {
            const { data: paymentSource } = await dentalApi.getPaymentSourceById(tenantId, transaction.paymentSourceId);
            return { transaction, paymentSource };
        }

        return { transaction };
    } catch (e) {
        return rejectWithValue(e as string);
    }
});

export const saveUACDistributionTransactions = createAsyncThunk<
    ICommonTransaction[],
    { tenantId: string; uacTransactionId: string },
    { rejectValue: string; state: RootState }
>('saveUACDistributionTransactions', async ({ tenantId, uacTransactionId }, { rejectWithValue, getState, dispatch }) => {
    try {
        const uacTransactions = getTransactionsToSave(selectUACDistributionTransactions(getState()));

        //Calls apply uac transactions endpoint. Automatically handles updating the uac transaction amount based on new transactions total amount.
        const { data: transactions } = await dentalApi.createAppliedFromUACLedgerTransactions(
            tenantId,
            uacTransactionId,
            uacTransactions,
        );

        dispatch(goBack());

        return transactions;
    } catch (e) {
        return rejectWithValue(e as string);
    }
});

export const convertOveragesToUAC = createAsyncThunk<
    ITransaction[],
    { tenantId: string; patientId: string; selectedBatch: IBatch; transaction: IProcedureCreditTransaction },
    { rejectValue: string; state: RootState }
>('convertOveragesToUAC', async ({ tenantId, transaction, selectedBatch, patientId }, { rejectWithValue, dispatch }) => {
    try {
        const { id: batchId } = selectedBatch;

        const { data } = await dentalApi.unapplyPatientCredit(tenantId, { ...transaction, batchId });

        dispatch(getPatientTreatmentPlanCreditUnappliedPayments({ tenantId, patientId }));

        return data;
    } catch (e) {
        return rejectWithValue(e as string);
    }
});

/**
 * Handles initializing all transactions for uac distribution.
 *
 * @param {string} patientId
 * @param {IBatch} [selectedBatch]
 * @return {*}  {AppThunk<void>}
 */
export const createUACDistributionTransactions =
    (patientId: string, batchId: string): AppThunk<void> =>
    (dispatch, getState) => {
        const paymentSource = selectUACDistributionPaymentSource(getState());
        const billingProcedures = selectSignedTreatmentPlanViewProceduresWithTransactions(getState());

        if (paymentSource) {
            dispatch(createPaymentTransactions(patientId, paymentSource.id, paymentSource.dateOfEntry, batchId));
            dispatch(createEstimateTransactions(patientId, paymentSource.id, paymentSource.dateOfEntry, batchId));
            dispatch(
                createAddTreatmentPlanCreditTransactions(
                    patientId,
                    billingProcedures,
                    paymentSource.id,
                    paymentSource.dateOfEntry,
                    batchId,
                ),
            );
        }
    };

export const clearAllUACDistributionTransactions = (): AppThunk<void> => (dispatch) => {
    dispatch(clearPayments());
    dispatch(clearEstimatePayments());
    dispatch(clearAllTreatmentPlanCreditTransactions());
};

export const distributeAllUACDistributionTransactions = (): AppThunk<void> => async (dispatch, getState) => {
    //Need to refetch the total remaining for each action with the most recent state.
    //This ensures that the remaining amount is accurate going into the next executed "pay all" action.
    const patientBalanceOverpayment = selectPatientOverpaymentOrOverAdjustment(getState());
    if (!patientBalanceOverpayment)
        dispatch(payAllPatientPayments({ maxAmount: selectUACDistributionTotalRemaining(getState()) }));

    const patientEstimateOverpayment = selectPatientEstimateOverpayment(getState());
    if (!patientEstimateOverpayment)
        dispatch(payAllPatientEstimates({ maxAmount: selectUACDistributionTotalRemaining(getState()) }));

    const tpOverpayment = selectTreatmentPlanCreditAllowOverpayment(getState());
    if (!tpOverpayment) dispatch(payAllTreatmentPlanCreditTransactions(selectUACDistributionTotalRemaining(getState())));
};

export const getAllUACDistributionSectionData =
    (tenantId: string, patientId: string): AppThunk<void> =>
    async (dispatch) => {
        await Promise.all([
            dispatch(getPatientPaymentsAndAdjustmentsBillingProcedures({ tenantId, patientId })).unwrap(),
            dispatch(getPatientEncounterSummariesWithLineItemsView({ tenantId, patientId })).unwrap(),
        ]);
    };
