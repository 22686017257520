import { useSelector } from 'hooks';
import { FormEvent } from 'react';
import { useDispatch } from 'react-redux';
import { updateTreatmentPlanCreditTransactionAmount } from 'state/slices/ledger/ledger.slice';
import {
    selectTreamentPlanCreditTransactions,
    selectTreatmentPlanCreditAllowOverpayment,
} from 'state/slices/ledger/treatment-plan-credit-and-uac/treatment-plan-credit-and-uac.selectors';
import { BillingProcedureWithTransactionId } from 'state/slices/ledger/treatment-plan-credit-and-uac/treatment-plan-credit-and-uac.state';
import BaseTransactionField from '../components/BaseTransactionField';
import { getMaxPayment, getTransactionAmount } from '../LedgerUtils';

type Props = {
    item: BillingProcedureWithTransactionId | undefined;
    remainingAmount: number | undefined;
};

export default function TreatmentPlanCreditTransactionField({ item, remainingAmount = 0 }: Props) {
    const dispatch = useDispatch();

    const overpayment = useSelector(selectTreatmentPlanCreditAllowOverpayment);
    const transactions = useSelector(selectTreamentPlanCreditTransactions);

    const transactionAmount =
        transactions && item?.paymentTransactionId ? transactions[item.paymentTransactionId]?.amount ?? 0 : 0;

    const _updateTreatmentPlanCreditTransactionAmount = (e?: FormEvent, value?: number | undefined) => {
        if (item?.paymentTransactionId) {
            const inputValue = value ?? 0;
            const patientFee = item.commonPatientFee ?? 0;
            const newFee = patientFee < 0 ? 0 : patientFee;

            const amount = getTransactionAmount({
                input: inputValue,
                patientFee: overpayment ? remainingAmount + transactionAmount : newFee,
                remainingPaymentAmount: remainingAmount,
                transactionAmount,
            });
            dispatch(updateTreatmentPlanCreditTransactionAmount({ transactionId: item.paymentTransactionId, amount }));
        }
    };

    return (
        <BaseTransactionField
            onChange={_updateTreatmentPlanCreditTransactionAmount}
            transactionAmount={transactionAmount}
            max={getMaxPayment({ overpayment, paymentAmount: item?.commonPatientFee })}
            maxAmountButtonDisabled={overpayment}
        />
    );
}
