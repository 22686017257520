import { createAsyncThunk } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import IPatientMedicationsView, { IPatientChartMedication } from 'api/models/patient-medication.model';
import { AxiosError } from 'axios';
import { cloneDeep } from 'lodash';
import ErrorTypes from 'state/errorTypes';
import { AppThunk, RootState } from 'state/store';
import { setOverviewItemDataProp, updateMedicationsView } from '../patient.slice';

export const createOrUpdatePatientMedicationView = createAsyncThunk<
    IPatientMedicationsView, // Testing remove any after done
    {
        tenantId: string;
        patientId: string;
        encounterId?: string;
    },
    {
        rejectWithValue: string;
        state: RootState;
    }
>('createOrUpdatePatientMedicationview', async ({ tenantId, patientId, encounterId }, { rejectWithValue, getState }) => {
    try {
        const medicationsState = getState().patient.medications;

        const medications = medicationsState.patientMedicationsToAdd;
        const patientMedicationsView = medicationsState.data;

        const medicationsWithEncounterId = cloneDeep(medications).map((medication) => ({ ...medication, encounterId }));
        if (patientMedicationsView?.id) {
            // [PUT] Update PatientMedicationsView

            const newPatientMedicationView = cloneDeep(patientMedicationsView) as IPatientMedicationsView; // Or we just make this do both.
            const updatedMedications = medicationsWithEncounterId ?? [];

            newPatientMedicationView['medications'] = updatedMedications;
            if (updatedMedications.length) newPatientMedicationView['noMedicationsReported'] = false;

            const response = await dentalApi.updatePatientMedications(tenantId, patientId, newPatientMedicationView);
            return response.data;
        } else {
            // [POST] Create PatientMedicationsView
            const newPatientMedicationView: IPatientMedicationsView = {
                id: patientId,
                isDeleted: false,
                medications: medicationsWithEncounterId,
                //This can exist before creating the record in the DB
                noMedicationsReported: medicationsWithEncounterId.length
                    ? false
                    : patientMedicationsView?.noMedicationsReported ?? false,
            };
            const response = await dentalApi.createPatientMedications(tenantId, patientId, newPatientMedicationView);
            return response.data;
        }
    } catch (err) {
        const error = err as AxiosError;
        if (error.response && error.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(error.toString());
        }
    }
});

export const updatePatientMedication = createAsyncThunk<
    IPatientMedicationsView,
    {
        tenantId: string;
        patientId: string;
        encounterId?: string;
    },
    {
        rejectWithValue: string;
        state: RootState;
    }
>('updatePatientMedication', async ({ tenantId, patientId, encounterId }, { rejectWithValue, getState }) => {
    try {
        const medicationsState = getState().patient.medications;
        const medication = medicationsState.medicationToUpdate;
        const patientMedicationsView = medicationsState.data;

        const newPatientMedicationView = cloneDeep(patientMedicationsView) as IPatientMedicationsView;
        if (patientMedicationsView?.medications?.length) {
            const medicationsWithEncounterId = {
                ...medication,
                encounterId,
                modifiedOn: new Date().toISOString(),
            };

            const updatedPatientMedicationView = {
                ...newPatientMedicationView,
                medications: [medicationsWithEncounterId],
                id: patientId,
            } as IPatientMedicationsView;

            newPatientMedicationView['id'] = patientId;
            const response = await dentalApi.updatePatientMedications(tenantId, patientId, updatedPatientMedicationView);
            return response.data;
        } else {
            const response = await dentalApi.updatePatientMedications(tenantId, patientId, newPatientMedicationView);
            return response.data;
        }
    } catch (err) {
        const error = err as AxiosError;
        if (error.response && error.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(error.toString());
        }
    }
});

export const setMedicationViewPropAndSave =
    ({
        path,
        patientId,
        tenantId,
        encounterId,
        type,
        value,
    }: {
        type: string;
        path: string;
        value: string | boolean | undefined;
        tenantId: string;
        encounterId: string;
        patientId: string;
    }): AppThunk<void> =>
    async (dispatch) => {
        await dispatch(setOverviewItemDataProp({ type, path, value }));
        dispatch(createOrUpdatePatientMedicationView({ tenantId, patientId, encounterId }));
    };

export const insertMedications =
    (patientMedicationsView: IPatientMedicationsView): AppThunk<void> =>
    (dispatch) => {
        dispatch(updateMedicationsView(patientMedicationsView));
    };
