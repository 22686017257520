import { FormEvent, useEffect } from 'react';
import { ActivityPage, Field, TText } from 'components';
import { Dropdown, IComboBox, IComboBoxOption, IDropdownOption, Stack } from '@fluentui/react';
import Calendar from './components/Calendar';
import './Scheduling.scss';
import CalendarDatepicker from './components/CalendarDatepicker';
import Tracker from './components/Tracker';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    cleanupSelectedAppointment,
    getLocationsOfCare,
    setSelectedOperatories,
    setSelectedOperatoryTags,
    toggleFilterOperatory,
} from 'state/slices/scheduling/scheduling.slice';
import { setSelectedLOC } from 'state/slices/scheduling/scheduling.slice';
import ScheduleQuickView from './components/ScheduleQuickView';
import appLocalStorage from 'utils/appLocalStorage';
import {
    selectFilteredOperatories,
    selectIsCheckoutPanelOpen,
    selectLocationsOfCare,
    selectSelectedLOC,
} from 'state/slices/scheduling/scheduling.selectors';
import AppointmentOverview from './components/AppointmentOverview/AppointmentOverview';
import { signalRGroupSubscriptionService, useSignalR } from 'hooks/signalr/useSignalr';
import ReportModal from 'pages/Reporting/ReportModal';
import { getLookups } from 'state/slices/lookups/lookups.reducers';
import { selectOperatoriyOptionsByLOC } from 'state/slices/lookups/operatories/operatories.selectors';
import { useSelector, useTenantId } from 'hooks';
import { validate as validateIsId } from 'uuid';
import { schedulingSignalR } from 'hooks/signalr/signalr.config';
import { cleanupPatientEncounter } from 'state/slices/encounter/encounter.slice';
import { SignalRGroup, SignalRGroupSubscriptionService } from 'hooks/signalr/signalRGroupSubscriptionService';

/**
 * Operatory settings are disconnected from the operatories that are loaded when the scheduling page is first rendered.
 *
 * Goal: Have operatory updates sync back on the calendar when they change successfully.
 *
 * Ideas:
 *  1. Async actions from settings sync back to operatories state.
 *
 *  2. Have only one operatories selector file that points to the lookups state.
 *     Operatories in settings are loaded immediately.
 */

function Scheduling(): JSX.Element {
    const { tenantId } = useParams<{ tenantId: string; patientId: string }>();

    const { registerSignalRConfig } = useSignalR();

    const dispatch = useDispatch();

    const isCheckoutPanelOpen = useSelector(selectIsCheckoutPanelOpen);

    useEffect(() => {
        dispatch(cleanupPatientEncounter());
        dispatch(getLocationsOfCare({ tenantId }));
        dispatch(getLookups({ tenantId, lookupType: 'Operatories' }));

        dispatch(setSelectedOperatories(appLocalStorage.getToggleFilterOperatory));
        dispatch(setSelectedOperatoryTags(appLocalStorage.getToggleFilterOperatoryTags));

        registerSignalRConfig(schedulingSignalR);

        return () => {
            dispatch(cleanupSelectedAppointment());
        };
    }, [dispatch, tenantId]);

    useEffect(() => {
        return function() {
            signalRGroupSubscriptionService.unsubscribeFromGroups(tenantId, [SignalRGroup.Clinical]);
        };
    }, []);

    return (
        <>
            {!isCheckoutPanelOpen && <ReportModal />}
            <ActivityPage title="Scheduling" disableOverflow hideFooter>
                <Stack tokens={{ childrenGap: 10, padding: 10 }} horizontal styles={{ root: { height: '100%' } }}>
                    <Stack tokens={{ childrenGap: 10 }}>
                        <Stack tokens={{ childrenGap: 10 }}>
                            <LOCDropdown />
                            <OperatoryBox />
                            <CalendarDatepicker />
                        </Stack>
                        <Stack
                            tokens={{ childrenGap: 10 }}
                            styles={{ root: { height: '100%', overflow: 'hidden', overflowY: 'auto' } }}
                        >
                            <Tracker />
                            <ScheduleQuickView />
                        </Stack>
                    </Stack>
                    <Calendar />
                    <AppointmentOverview />
                </Stack>
            </ActivityPage>
        </>
    );
}

export default Scheduling;

function LOCDropdown() {
    const dispatch = useDispatch();
    const tenantId = useTenantId();

    const selectedLOC = useSelector(selectSelectedLOC);
    const locationsOfCare = useSelector(selectLocationsOfCare);

    const locationOptions =
        locationsOfCare && locationsOfCare.length
            ? locationsOfCare
                .filter((loc) => !loc.isDeleted)
                .map((loc) => {
                    const opt: IDropdownOption = { key: loc.id, text: loc.displayName, data: loc };
                    return opt;
                })
            : [];

    const onLocationChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        if (option) {
            dispatch(setSelectedLOC(option.data));
            appLocalStorage.loc = { tenantId, locId: option.data };
        }
    };

    return (
        <Dropdown
            placeholder="Select Location"
            options={locationOptions}
            selectedKey={selectedLOC?.id}
            styles={{ root: { width: '100%' }, title: { borderColor: 'transparent !important' } }}
            onChange={onLocationChange}
            id="loc-dropdown"
        />
    );
}

function OperatoryBox() {
    const dispatch = useDispatch();
    const selectedLOC = useSelector(selectSelectedLOC);
    const tenantId = useTenantId();

    const operatoryOptionsByLOC = useSelector((state) => selectOperatoriyOptionsByLOC(state, tenantId));
    const _selectedFilteredOperatories = useSelector((state) =>
        selectFilteredOperatories(state, tenantId, selectedLOC?.id || ''),
    );

    const onFilterOperatoriesChange = (ev: FormEvent<IComboBox>, option?: IComboBoxOption) => {
        if (option) {
            //dispatch reducer
            dispatch(toggleFilterOperatory({ operatoryId: option.key as string, tenantId, locationOfCareId: selectedLOC?.id }));
        }
    };
    return (
        <Field.SearchCombo
            onRenderOption={(props) => (
                <TText>
                    {props?.text ?? ''}
                    {props?.key && !validateIsId(props?.key as string) ? ' (View)' : ''}
                </TText>
            )}
            multiSelect
            selectedKey={_selectedFilteredOperatories}
            placeholder="Select Operatory Views"
            onChange={onFilterOperatoriesChange}
            options={operatoryOptionsByLOC}
            useComboBoxAsMenuWidth
            optionName="Options"
            maxResults={50}
        ></Field.SearchCombo>
    );
}
