import { Dropdown, IDropdownOption, Stack } from '@fluentui/react';
import { ReportType } from 'api/models/embed-report.model';
import { Field } from 'components';
import { useTenantId } from 'hooks';
import { getValidationError } from 'hooks/useValidation';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectReportingValidationErrors } from 'state/slices/reporting/reporting.selectors';
import reportActionLookup from 'state/slices/reporting/reportActionLookup';
import { selectReportProperties } from 'state/slices/reporting/reporting.selectors';
import { setBatchProp } from 'state/slices/reporting/reporting.slice';
import { IBatchReportProperties } from 'state/slices/reporting/reporting.state';

import { classicDateOnly } from 'utils/dateOnly';
import ReportWrapper from '../ReportWrapper';

export default function BatchReport() {
    const query = useSelector(selectReportProperties) as IBatchReportProperties;

    const dispatch = useDispatch();

    const tenantId = useTenantId();

    const errors = useSelector(selectReportingValidationErrors);

    const _onViewReport = () => {
        if (query) dispatch(reportActionLookup[ReportType.Batch]({ tenantId, ...query }));
    };

    const statusOptions: IDropdownOption[] = [
        { key: '99', text: 'All' },
        { key: 2, text: 'Closed' },
        { key: 3, text: 'Hard closed' },
        { key: 1, text: 'Open' },
    ];

    return (
        <ReportWrapper
            validationConfig={[
                { fieldName: 'Start Date', validation: ['required'], value: query?.startDate },
                { fieldName: 'End Date', validation: ['required'], value: query?.endDate },
            ]}
            onViewReport={_onViewReport}
        >
            <Stack tokens={{ childrenGap: 20 }} grow horizontal wrap>
                <Stack.Item>
                    <Field.Date
                        label="Start Date"
                        required
                        value={query?.startDate ? classicDateOnly(query?.startDate, 'MM/dd/yyyy') : ''}
                        onChange={(ev, value) => {
                            const newDate = value ? classicDateOnly(value, 'yyyy-MM-dd') : undefined;
                            dispatch(setBatchProp({ path: 'startDate', value: newDate }));
                        }}
                        hasDatePicker
                        errorMessage={getValidationError(errors, 'Start Date') ? 'Start date is required.' : undefined}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.Date
                        label="End Date"
                        required
                        value={query?.endDate ? classicDateOnly(query?.endDate, 'MM/dd/yyyy') : ''}
                        onChange={(ev, value) => {
                            const newDate = value ? classicDateOnly(value, 'yyyy-MM-dd') : undefined;
                            dispatch(setBatchProp({ path: 'endDate', value: newDate }));
                        }}
                        isReasonable
                        disabled={!query?.startDate}
                        minReasonableErrorMessage="End date must be after start date."
                        minReasonableDate={query?.startDate ? new Date(classicDateOnly(query?.startDate)) : undefined}
                        hasDatePicker
                        errorMessage={getValidationError(errors, 'End Date') ? 'End date is required.' : undefined}
                    />
                </Stack.Item>

                <Stack.Item>
                    <Dropdown
                        label="Batch Status"
                        placeholder="(Select Batch Status)"
                        options={statusOptions}
                        onChange={(ev, option) => {
                            if (option) {
                                dispatch(
                                    setBatchProp({
                                        path: 'batchStatus',
                                        value: option.key,
                                    }),
                                );
                            }
                        }}
                        style={{ minWidth: 150 }}
                        required
                        selectedKey={query?.batchStatus}
                        errorMessage={getValidationError(errors, 'Batch Status') ? 'Batch Status is required.' : undefined}
                    />
                </Stack.Item>
            </Stack>
        </ReportWrapper>
    );
}
