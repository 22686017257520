import { Dropdown, IDropdownOption, Stack, TextField } from '@fluentui/react';
import IPatientAppointment from 'api/models/Scheduling/patientAppointment.model';
import IProvider from 'api/models/provider.model';
import { Field, Section } from 'components';
import { useSelector, useTenantId } from 'hooks';
import { IValidationError, getValidationError } from 'hooks/useValidation';
import { FormEvent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { encounterReasonsAsOptions } from 'state/slices/encounter-reasons/encounter-reasons.selectors';
import { selectCurrentOperatoriesAppointmentPanelOptions } from 'state/slices/lookups/operatories/operatories.selectors';
import { selectAppointmentOverviewActiveProviders } from 'state/slices/scheduling/scheduling.selectors';
import {
    setScheduleAppointmentNotes,
    setScheduleAppointmentOperatoryId,
    setSchedulePatientAppointmentRootProp,
} from 'state/slices/scheduling/scheduling.slice';
import ScheduleAppointmentProcedures from './ScheduleAppointmentProcedures';
import { ISearchComboBoxOption } from 'components/Field/SearchComboField';
import { getOptionsWithSelectedProvider, selectProvidersData } from 'state/slices/tenant/providers.slice';

type Props = {
    patientAppt?: IPatientAppointment;
    errors: IValidationError[];
};

function PatientAppointmentFields({ patientAppt, errors }: Props): JSX.Element | null {
    const dispatch = useDispatch();
    const tenantId = useTenantId();

    const encounterReasons = useSelector(encounterReasonsAsOptions);
    const operatoryOptions = useSelector((state) => selectCurrentOperatoriesAppointmentPanelOptions(state, tenantId));

    const providers = useSelector(selectAppointmentOverviewActiveProviders);
    const providerData = useSelector(selectProvidersData);

    const isTreatingProviderAttendingHygienist = patientAppt?.treatingProviderId
        ? providerData[patientAppt.treatingProviderId]?.isAttestingHygienist
        : false;

    useEffect(() => {
        if (isTreatingProviderAttendingHygienist)
            dispatch(setSchedulePatientAppointmentRootProp({ path: 'hygienistId', value: '' }));
    }, [isTreatingProviderAttendingHygienist, dispatch]);

    if (!patientAppt) return null;

    const treatingProviderOptions: ISearchComboBoxOption[] = getOptionsWithSelectedProvider({
        options: providers.filter((p) => p?.isTreatingProvider || p?.isAttestingHygienist).map(mapProviderToDropdown),
        selectedProviderId: patientAppt.treatingProviderId,
        lookup: providerData,
    });

    const hygienistProviderOptions: IDropdownOption[] = getOptionsWithSelectedProvider({
        options: providers.filter((p) => p?.isHygienist).map(mapProviderToDropdown),
        selectedProviderId: patientAppt.hygienistId,
        lookup: providerData,
    });

    const rdaProviderOptions: IDropdownOption[] = providers
        .filter((p) => p?.isRegisteredDentalAssistant)
        .map(mapProviderToDropdown);

    return (
        <>
            <Stack.Item grow>
                <Field.SearchCombo
                    label="Treating Provider"
                    placeholder="Select Treating Provider"
                    options={treatingProviderOptions}
                    selectedKey={patientAppt?.treatingProviderId ?? ''}
                    onChange={(_, item) =>
                        dispatch(
                            setSchedulePatientAppointmentRootProp({
                                path: 'treatingProviderId',
                                value: (item?.key as string) ?? '',
                            }),
                        )
                    }
                    required
                    errorMessage={getValidationError(errors, 'Treating Provider') ? 'Treating Provider is required.' : undefined}
                />
            </Stack.Item>
            <Stack tokens={{ childrenGap: 12 }} horizontal horizontalAlign="space-evenly">
                <Stack.Item grow>
                    <Dropdown
                        label="Operatory"
                        options={operatoryOptions}
                        selectedKey={patientAppt?.operatoryId ?? ''}
                        onChange={(_: FormEvent, item?: IDropdownOption) => {
                            dispatch(setScheduleAppointmentOperatoryId((item?.key as string) ?? ''));
                        }}
                    />
                </Stack.Item>
                <Stack.Item grow>
                    <Dropdown
                        selectedKey={patientAppt?.hygienistId ?? ''}
                        placeholder="Select a Hygienist"
                        label="Hygienist"
                        options={[{ key: '', text: 'Select a Hygienist' }, ...hygienistProviderOptions]}
                        disabled={!hygienistProviderOptions.length || isTreatingProviderAttendingHygienist}
                        onChange={(_: FormEvent, item?: IDropdownOption) =>
                            dispatch(
                                setSchedulePatientAppointmentRootProp({
                                    path: 'hygienistId',
                                    value: (item?.key as string) ?? '',
                                }),
                            )
                        }
                    />
                </Stack.Item>
                <Stack.Item grow>
                    <Dropdown
                        selectedKey={patientAppt?.registeredDentalAssistantId ?? ''}
                        placeholder="Select an RDA"
                        label="RDA"
                        options={[{ key: '', text: 'Select an RDA' }, ...rdaProviderOptions]}
                        disabled={!rdaProviderOptions.length}
                        onChange={(_: FormEvent, item?: IDropdownOption) =>
                            dispatch(
                                setSchedulePatientAppointmentRootProp({
                                    path: 'registeredDentalAssistantId',
                                    value: (item?.key as string) ?? '',
                                }),
                            )
                        }
                    />
                </Stack.Item>
            </Stack>
            <Stack>
                <Stack.Item style={{ width: 200 }}>
                    <Dropdown
                        placeholder="Select Encounter Reason"
                        label="Reason For Encounter"
                        options={encounterReasons}
                        selectedKey={patientAppt?.encounterReason}
                        required
                        onChange={(ev, option) => {
                            dispatch(
                                setSchedulePatientAppointmentRootProp({
                                    path: 'encounterReason',
                                    value: option?.key as string,
                                }),
                            );
                        }}
                        errorMessage={
                            getValidationError(errors, 'Reason For Encounter') ? 'Reason For Encounter is required.' : undefined
                        }
                    />
                </Stack.Item>
            </Stack>
            <Section heading="Treatment Plan Phases">
                <ScheduleAppointmentProcedures />
            </Section>
            <Stack>
                <TextField
                    label="Notes"
                    multiline
                    value={patientAppt?.notes}
                    onChange={(event, newValue) => dispatch(setScheduleAppointmentNotes(newValue as string))}
                />
            </Stack>
        </>
    );
}

export default PatientAppointmentFields;

function mapProviderToDropdown(p: IProvider): ISearchComboBoxOption {
    return {
        key: p.id,
        text: `${p.lastName}${p.suffix ? ` ${p.suffix}` : ''}, ${p.firstName}`,
        groupName: p.isTreatingProvider ? 'Treating Provider' : p.isAttestingHygienist ? 'Hygienist' : undefined,
    };
}
