import { createSelector } from '@reduxjs/toolkit';
import { ClinicalNote } from 'api/models/chart-note.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { forEach } from 'lodash';
import { RootState } from 'state/store';
import RenderNote from 'utils/renderChartNote';

const selectNoteBrowser = (state: RootState) => state.noteBrowser;
export const selectIsOpen = createSelector(selectNoteBrowser, ({ isOpen }) => isOpen);
export const selectNewNoteIsOpen = createSelector(selectNoteBrowser, ({ newNoteIsOpen }) => newNoteIsOpen);
export const selectCurrentNote = createSelector(selectNoteBrowser, ({ selectedChartNote }) => selectedChartNote);
export const selectRenderedCurrentNote = createSelector(selectNoteBrowser, ({ selectedChartNote }) => {
    if (!selectedChartNote) return;
    return new RenderNote(selectedChartNote as ClinicalNote).buildNoteString();
});

export const selectNewNote = createSelector(selectNoteBrowser, ({ newNote }) => newNote);
export const selectAddendumDisabled = createSelector(
    selectNoteBrowser,
    ({ selectedChartNote, newNote }) => selectedChartNote === undefined || newNote?.note !== undefined,
);
export const selectMainActionsVisible = createSelector(selectNoteBrowser, ({ newNote }) => newNote !== undefined);

export const selectNoteText = createSelector(selectNewNote, (newNote) => newNote?.note);

export const selectNoteSaveErrors = createSelector(selectNoteBrowser, (noteBrowser) => noteBrowser.saveError);

export const selectConfirmationIsOpen = createSelector(selectNoteBrowser, ({ signAndSaveIsOpen }) => signAndSaveIsOpen);

export const selectClinicalNoteList = createSelector(selectNoteBrowser, ({ clinicalNotes }) => {
    const _clinicalNotes = { ...clinicalNotes };
    forEach(_clinicalNotes, (notesForDate, date) => {
        if (_clinicalNotes) {
            //Filter out any notes that are deleted
            const filteredNotesForDate = notesForDate.filter(note => !note.chartNote?.isDeleted);

            if (!filteredNotesForDate.length) {
                //If all chart notes for a given date have been removed due to being isDeleted, then we remove the key for the current date.
                delete _clinicalNotes[date]
            } else {
                //Otherwise we just update the notes for the current date with the filtered notes.
                _clinicalNotes[date] = filteredNotesForDate
            }
        }
    })

    return _clinicalNotes
});
export const selectClinicalNoteListLoading = createSelector(
    selectNoteBrowser,
    ({ clinicalNotesLoading }) => clinicalNotesLoading === LoadingStatus.Pending,
);
