import { uniqBy } from 'lodash';

export enum PipelineError {
    InvalidArea = 'invalidArea',
    DuplicateProcedure = 'duplicateProcedure',
    AnteriorOnly = 'anteriorOnly',
    PosteriorOnly = 'posteriorOnly',
    MolarOnly = 'molarOnly',
    OnePerTooth = 'onePerTooth',
    OnePerArea = 'onePerArea',
    onePerEncounter = 'onePerEncounter',
    OnePerSurface = 'onePerSurface',
    BicuspidOnly = 'bicuspidOnly',
    MaxillaryOnly = 'maxillaryOnly',
    MandibularOnly = 'mandibularOnly',
    OnePerQuad = 'onePerQuad',
    ThreeSurfacesOnly = 'threeSurfacesOnly',
    FourSurfacesOnly = 'fourSurfacesOnly',
    FourOrMoreSurfacesOnly = 'fourOrMoreSurfacesOnly',
    BetweenAbutmentsOnly = 'betweenAbutmentsOnly',
    RequiresCompletedExtractionOrMissing = 'requiresCompletedExtractionOrMissing',
    PrimaryToothOnly = 'primaryToothOnly',
    PermanentToothOnly = 'permanentToothOnly',
    TwoSurfacesOrMoreOnly = 'twoSurfacesOrMore',
    TwoSurfacesOnly = 'twoSurfacesOnly',
    ThreeSurfacesOrMoreOnly = 'threeSurfacesOrMore',
    DiagnosisRequiresSupportingDiagnosis = 'DiagnosisRequiresSupportingDiagnosis',
    ProcedureRequiresToChartProcedure = 'ProcedureRequiresToChartProcedure',
    PartialArch = 'partialArch',
}
/**
 * type: Pipeline error type.
 * data: Any data to return with the error.
 *
 * @export
 * @interface IPipelineError
 */
export interface IPipelineError {
    type: PipelineError;
    data: any;
}

export type ConditionalIdSupport = {
    id?: string | number;
};
/**
 *
 * Extendable class for creating a pipeline.
 * Includes helper functions for item/error handling
 *
 * @export
 * @class Pipeline
 * @template T
 */
export default class Pipeline<T extends ConditionalIdSupport> {
    protected items: T[];
    protected errors: IPipelineError[] = [];

    constructor(items: T[]) {
        this.items = items;
    }
    /**
     * Adds a pipeline error to the list of errors.
     *
     * @protected
     * @param {PipelineError} type
     * @param {unknown} [data]
     * @memberof Pipeline
     */
    protected addError(type: PipelineError, data?: unknown): void {
        this.errors.push({ type, data: data ? data : 'unknown' });
    }
    /**
     * Adds multiple pipeline errors to the list of errors
     *
     * @protected
     * @param {{ type: PipelineError; data?: T }[]} errors
     * @memberof Pipeline
     */
    protected addErrors(errors: { type: PipelineError; data?: T }[]): void {
        errors.forEach((err) => {
            this.errors.push({ type: err.type, data: err.data ? err.data : 'unknown' });
        });
    }

    /**
     * Adds given pipeline errors to the list of errors.
     *
     * @protected
     * @param {IPipelineError[]} errors
     * @memberof Pipeline
     */
    protected addExistingErrors(errors: IPipelineError[]): void {
        this.errors = [...this.errors, ...errors];
    }

    /**
     * Add a T item to the list of returned items
     *
     * @protected
     * @param {T} item
     * @memberof Pipeline
     */
    protected addItem(item: T): void {
        this.items.push(item);
    }

    /**
     * Add multiple T items to the list of returned items
     *
     * @protected
     * @param {T[]} items
     * @memberof Pipeline
     */
    protected addItems(items: T[]): void {
        this.items.push(...items);
    }
    /**
     * Set the pipeline items list
     *
     * @protected
     * @param {T[]} items
     * @memberof Pipeline
     */
    protected setItems(items: T[]): void {
        this.items = items;
    }

    /**
     * Update given T item if it has an id
     *
     * @protected
     * @param {T} item
     * @memberof Pipeline
     */
    protected updateItem(item: T): void {
        if (item.id && this.items.length) {
            const index = this.items.findIndex((i) => i.id === item.id);
            this.items[index] = item;
        }
    }

    /**
     * Remove given T item if it has an id
     *
     * @protected
     * @param {T} item
     * @memberof Pipeline
     */
    protected removeItem(item: T): void {
        if (item.id && this.items.length) this.setItems(this.items.filter((i) => i.id !== item.id));
    }
    /**
     * Update multiple T items if they have ids
     *
     * @protected
     * @param {T[]} items
     * @memberof Pipeline
     */
    protected updateItems(items: T[]): void {
        this.items = uniqBy([...items, ...this.items], 'id');
    }

    /**
     * Get items in pipeline
     *
     * @readonly
     * @type {T[]}
     * @memberof Pipeline
     */
    public get getItems(): T[] {
        return this.items;
    }

    /**
     * Get errors in pipeline
     *
     * @readonly
     * @type {IPipelineError[]}
     * @memberof Pipeline
     */
    public get getErrors(): IPipelineError[] {
        return this.errors;
    }

    /**
     * Runs any process after the pipeline has been created and ran.
     *
     * @param {(items: T[], errors?: IPipelineError[]) => void} doNext
     * @return {*}  {Pipeline<T>}
     * @memberof Pipeline
     */
    public next(doNext: (items: T[], errors?: IPipelineError[]) => void): Pipeline<T> {
        doNext(this.items, this.errors);
        return this;
    }
}
