import { useSelector } from 'react-redux';
import { selectPatientPaymentsAndAdjustmentsBillingProceduresLoadingStatus } from 'state/slices/ledger/patient-payments-and-adjustments/patient-payments-and-adjustments.selectors';
import { selectUACDistributionPatientBalanceAmounts } from 'state/slices/ledger/uac-distribution/uac-distribution.selectors';
import { usdCurrencyFormatter } from 'utils';
import UACDistributionCard, { UACDistributionCardProps } from './UACDistributionCard';

export default function UACPatientBalanceCard({ onLinkClick }: Pick<UACDistributionCardProps, 'onLinkClick'>) {
    const loading = useSelector(selectPatientPaymentsAndAdjustmentsBillingProceduresLoadingStatus);
    const { patientBalancePaymentAmount, patientBalanceRemainingAmount, patientBalanceTotal } = useSelector(
        selectUACDistributionPatientBalanceAmounts,
    );

    return (
        <UACDistributionCard onLinkClick={onLinkClick} title="Patient Balance" loading={loading}>
            <span>Total: {usdCurrencyFormatter.format(patientBalanceTotal ?? 0)}</span>
            <span>Distributed: {usdCurrencyFormatter.format(patientBalancePaymentAmount ?? 0)}</span>
            <span>Remaining: {usdCurrencyFormatter.format(patientBalanceRemainingAmount ?? 0)}</span>
        </UACDistributionCard>
    );
}
