import Tooth, { ChartRenderAction } from './Tooth';
import * as PIXI from 'pixi.js-legacy';
import { IChartCondition, IChartProcedure } from 'api/models/chart.model';
import ToothSprite from './ToothSprite';

export default class ToothOverlay extends PIXI.Container {
    public tooth: Tooth;
    public color = 0xffffff;

    constructor(tooth: Tooth, parent?: PIXI.Container) {
        super();
        this.tooth = tooth;
        this.zIndex = 0;
        if (!parent) {
            this.setParent(tooth);
        } else {
            this.setParent(parent);
        }
    }

    public _setColor(item: ChartRenderAction): void {
        if (item) this.color = this.tooth.fetchActionColor(item);
    }

    public setScaleToTooth(): void {
        if (this.tooth.toothReference.scaleFactor) {
            this.scale.x = this.tooth.toothReference.scaleFactor;
            this.scale.y = this.tooth.toothReference.scaleFactor;
        }
    }

    public get getIsBottomRow(): boolean {
        return this.tooth.getToothPosition - 1 >= 16;
    }

    public createSpriteFromTexture(texture: string) {
        const sprite = new ToothSprite(texture, this.tooth);
        this.addChild(sprite);
    }
}
