import { IconButton, Label, MessageBar, Stack, Text, useTheme, TooltipHost, Spinner } from '@fluentui/react';
import { FormName } from 'api/models/form';
import { IPatientProblem } from 'api/models/patient-problem.model';
import { format } from 'date-fns';
import { IPatientProblemsQuestion } from 'forms/Problems/types';
import useForms from 'hooks/store/useForms';
import { LoadingStatus } from 'interfaces/loading-statuses';
import SharedDetailsList from '../Shared/SharedDetailsList';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import { useSelector } from 'react-redux';
import { selectProblemAsList, selectProblemsLoading } from 'state/slices/patient/overview/overview.selectors';
import { useCallback } from 'react';

function ProblemsDetailsList(): JSX.Element {
    const _problemsLoading = useSelector(selectProblemsLoading);
    const _selectProblemAsList = useSelector(selectProblemAsList)
    const { patientProblemsFormValuesAsList, setNewForm, getPatientFormById, patientProblemsForm } = useForms();

    const theme = useTheme();

    const _renderDate = (date?: IPatientProblemsQuestion) => {
        if (date) {
            return <Text variant="mediumPlus">{format(new Date(date.date), 'M/dd/yyyy')}</Text>;
        }
        return '';
    };

    const getProblemEventStartDates = useCallback((item: IPatientProblem | undefined) => {
        return item?.events?.map((m) => m.startDate ? new Date(m.startDate) : undefined).filter(m => m !== undefined) as Date[]
    }, [])

    const existingProblemsColumns: ISortableColumn<IPatientProblem>[] = [
        {
            key: 'name',
            minWidth: 150,
            maxWidth: 350,
            name: 'Name',
            fieldName: 'name',
            onRender: (item) => (
                <Text variant="mediumPlus">
                    <span>{item?.name}</span>
                </Text>
            ),
        },
        {
            key: 'date',
            minWidth: 150,
            maxWidth: 350,
            name: 'Date',
            getValueKey: (item) => {
                const startDates = getProblemEventStartDates(item);
                if (!startDates?.length) return "";
                return startDates[0].toISOString();
            },
            onRender: (item) => {
                const startDates = getProblemEventStartDates(item);
                if (!startDates?.length) return "";
                return (
                    <Text variant="mediumPlus">
                        <span>{format(startDates[0], 'M/dd/yyyy')}</span>
                    </Text>
                )
            }
        },
    ];

    //Notes are coming from the form and not the patient.
    const patientReportedProblemsColumns: ISortableColumn<IPatientProblemsQuestion>[] = [
        {
            key: 'name',
            minWidth: 150,
            maxWidth: 350,
            name: 'Name',
            fieldName: 'name',
            onRender: (item) => {
                return (
                    <Text variant="mediumPlus">
                        <span>{item?.displayName ?? ''}</span>
                    </Text>
                );
            },
        },
        {
            key: 'date',
            minWidth: 150,
            maxWidth: 350,
            name: 'Date',
            fieldName: 'date',
            onRender: _renderDate,
        },
    ];

    const onClickAdd = () => {
        if (patientProblemsForm) {
            getPatientFormById(patientProblemsForm.id);
        } else {
            setNewForm(FormName.PatientProblem);
        }
    };

    return (
        <>
            <Stack tokens={{ childrenGap: 10 }}>
                <Stack>
                    <Label>Existing Problems </Label>
                    {_problemsLoading === LoadingStatus.Pending ? (
                        <Spinner label="Loading existing problems list..." labelPosition="right" />
                    ) : _selectProblemAsList?.length && _problemsLoading === LoadingStatus.Completed ? (
                        <SharedDetailsList
                            columns={existingProblemsColumns}
                            sortOnMount={true}
                            mountSortColumn={['name']}
                            onActiveItemChanged={undefined}
                            items={_selectProblemAsList ? _selectProblemAsList : []}
                        />
                    ) : !_selectProblemAsList?.length && _problemsLoading === LoadingStatus.Completed ? (
                        <MessageBar>No existing problems have been added.</MessageBar>
                    ) : null}
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 5 }}>
                    <Label> Patient Reported Problems </Label>
                    <TooltipHost content={patientProblemsForm ? 'Edit patient problems' : 'Add patient problems'}>
                        <IconButton
                            styles={{
                                rootHovered: {
                                    color: theme.palette.themePrimary,
                                },
                            }}
                            iconProps={{ iconName: 'Add' }}
                            onClick={onClickAdd}
                        />
                    </TooltipHost>
                </Stack>

                {_problemsLoading === LoadingStatus.Pending ? (
                    <Spinner label="Loading patient reported problems list..." labelPosition="right" />
                ) : patientProblemsFormValuesAsList?.length && _problemsLoading === LoadingStatus.Completed ? (
                    <SharedDetailsList
                        columns={patientReportedProblemsColumns}
                        sortOnMount={true}
                        mountSortColumn={['name']}
                        onActiveItemChanged={undefined}
                        items={patientProblemsFormValuesAsList ? patientProblemsFormValuesAsList : []}
                    />
                ) : !patientProblemsFormValuesAsList?.length && _problemsLoading === LoadingStatus.Completed ? (
                    <MessageBar>No patient reported problems have been added.</MessageBar>
                ) : null}
                {patientProblemsForm?.data?.note ? (
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <Stack.Item>
                            <Label> Notes:</Label>
                        </Stack.Item>
                        <Stack.Item align="center">
                            <Text> {patientProblemsForm?.data?.note} </Text>
                        </Stack.Item>
                    </Stack>
                ) : (
                    ''
                )}
            </Stack>
        </>
    );
}

export default ProblemsDetailsList;
