import { useEffect } from 'react';

import { INavLink, Spinner, Stack, useTheme } from '@fluentui/react';
import { ActivityPage } from 'components';
import { useSelector, useTenantId } from 'hooks';
import { useDispatch } from 'react-redux';
import {
    getTenantReportingCategories,
    getTenantReportingGroups,
} from 'state/slices/reporting/reporting-groups/reporting-groups.actions';
import {
    navReportingGroupList,
    loadingReportingGroups,
    selectSelectedReportingGroup,
    reportingGroupList,
} from 'state/slices/reporting/reporting-groups/reporting-groups.selectors';
import { cleanupReportingGroup, setSelectedReportingGroupFromId } from 'state/slices/reporting/reporting.slice';
import SettingsVerticalNav from '../components/SettingsVerticalNav';
import EditSelectedGroup from './EditSelectedGroup';
import { getAllLocationsOfCareForReports } from 'state/slices/reporting/reporting.actions';
import { LoadingStatus } from 'interfaces/loading-statuses';

export default function ReportingGroupSettings(): JSX.Element {
    const dispatch = useDispatch();
    const tenantId = useTenantId();
    const { palette } = useTheme();

    const _selectSelectedReportingGroupId = useSelector(selectSelectedReportingGroup);
    const loadingGroups = useSelector(loadingReportingGroups);
    const nav = useSelector(navReportingGroupList);
    const groups = useSelector(reportingGroupList);

    useEffect(() => {
        return () => {
            dispatch(cleanupReportingGroup());
        };
    }, [dispatch]);

    useEffect(() => {
        dispatch(getTenantReportingGroups({ tenantId }));
        dispatch(getTenantReportingCategories({ tenantId }));
        dispatch(getAllLocationsOfCareForReports({ tenantId }));
    }, [dispatch, tenantId]);

    useEffect(() => {
        if (loadingGroups === LoadingStatus.Completed && groups.length)
            dispatch(setSelectedReportingGroupFromId(groups[0]?.id ?? ''));
    }, [loadingGroups]);

    function _onLinkClick(ev?: React.MouseEvent<HTMLElement | MouseEvent>, item?: INavLink) {
        if (item?.id) {
            dispatch(setSelectedReportingGroupFromId(item.id));
        }
    }

    return (
        <ActivityPage title="Manage Reporting Groups" hasPageHeader>
            <Stack horizontal className="quick-action-settings">
                <Stack horizontalAlign="center" style={{ backgroundColor: palette.neutralLighterAlt, minWidth: 155 }}>
                    {loadingGroups === LoadingStatus.Pending ? (
                        <Spinner label="Loading" />
                    ) : (
                        <SettingsVerticalNav
                            links={nav}
                            onLinkClick={_onLinkClick}
                            selectedKey={_selectSelectedReportingGroupId?.id}
                        />
                    )}
                </Stack>
                <Stack style={{ flex: 1 }} className="edit-category">
                    <EditSelectedGroup />
                </Stack>
            </Stack>
        </ActivityPage>
    );
}
