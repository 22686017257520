import { Stack, SelectionMode, Text } from '@fluentui/react';
import {
    EncounterSummaryWithLineItems,
    ProcedureSummary,
    ProcedureSummaryWithEncounterIdAndTransactionId,
} from 'api/models/encounter-ledger.model';
import { SortableDetailsList } from 'components';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import { usdCurrencyFormatter } from 'utils';
import RenderProcedureSummaryTooltip from '../components/RenderProcedureSummaryTooltip';
import AdjustmentTransactionField from './AdjustmentTransactionField';
import PaymentTransactionField from './PaymentTransactionField';
import { TransactionLookup } from 'state/slices/ledger/patient-payments-and-adjustments/patient-payments-and-adjustments.state';
import { PatientPaymentsAndAdjustmentsTableType } from './PaymentsAndAdjustmentsList';

type Props = {
    encounterSummary: EncounterSummaryWithLineItems;
    hideMakePayment: boolean;
    hideMakeAdjustment: boolean;
    makingPaymentOrAdjustment: boolean | undefined;

    tableType?: PatientPaymentsAndAdjustmentsTableType;

    paymentAmount: number;
    remainingPaymentAmount: number;

    overpayment?: boolean;

    transactions: TransactionLookup;
    adjustmentTransactions?: TransactionLookup;

    onChangePayment?: (encounterId: string, transactionId: string, amount: number) => void;

    feeProp?: 'patientEstimate' | 'commonPatientFee';
};

export const getPatientPaymentsAndAdjustmentLineItemColumns = (
    tableType: PatientPaymentsAndAdjustmentsTableType,
): ISortableColumn<ProcedureSummary>[] => [
    {
        key: 'codeDescription',
        minWidth: 100,
        maxWidth: 120,
        name: 'Code/Desc.',
        fieldName: 'procedureCode',
        onRender: (item) => <RenderProcedureSummaryTooltip item={item} />,
    },
    {
        key: 'fee',
        minWidth: 80,
        maxWidth: 100,
        name: 'Fee',
        fieldName: 'ucr',
        onRender: (item) => <Text variant="smallPlus">{usdCurrencyFormatter.format(item?.ucr ?? 0)}</Text>,
    },
    {
        key: 'insPaid',
        minWidth: 80,
        maxWidth: 100,
        name: 'Ins. Paid',
        fieldName: 'insuranceAmount',
        onRender: (item) => <Text variant="smallPlus">{usdCurrencyFormatter.format(item?.insurancePayments ?? 0)}</Text>,
    },
    {
        key: 'insAdjustment',
        minWidth: 80,
        maxWidth: 100,
        name: 'Ins. Adjustments',
        fieldName: 'insuranceAdjustments',
        onRender: (item) => <Text variant="smallPlus">{usdCurrencyFormatter.format(item?.insuranceAdjustments ?? 0)}</Text>,
    },
    {
        key: 'patientPaid',
        minWidth: 110,
        maxWidth: 120,
        name: 'Pt. Applied Pymts.',
        fieldName: 'patientPayments',
        onRender: (item) => <Text variant="smallPlus">{usdCurrencyFormatter.format(item?.patientPayments ?? 0)}</Text>,
    },
    {
        key: 'patientAdjustment',
        minWidth: 80,
        maxWidth: 100,
        name: 'Pt. Adjustments',
        fieldName: 'patientAdjustments',
        onRender: (item) => <Text variant="smallPlus">{usdCurrencyFormatter.format(item?.patientAdjustments ?? 0)}</Text>,
    },
    {
        key: 'insBalance',
        minWidth: 80,
        maxWidth: 100,
        name: 'Ins. Balance',
        fieldName: 'insuranceBalance',
        onRender: (item) => <Text variant="smallPlus">{usdCurrencyFormatter.format(item?.insuranceBalance ?? 0)}</Text>,
    },
    tableType === 'patientBalance'
        ? {
              key: 'patientBalance',
              minWidth: 80,
              maxWidth: 100,
              name: 'Pt. Balance',
              fieldName: 'commonPatientFee',
              onRender: (item) => <Text variant="smallPlus">{usdCurrencyFormatter.format(item?.commonPatientFee ?? 0)}</Text>,
          }
        : {
              key: 'patientEstimate',
              minWidth: 80,
              maxWidth: 100,
              name: 'Pt. Estimate',
              fieldName: 'patientEstimate',
              onRender: (item) => <Text variant="smallPlus">{usdCurrencyFormatter.format(item?.patientEstimate ?? 0)}</Text>,
          },
    {
        key: 'transferTo',
        minWidth: 80,
        maxWidth: 100,
        name: 'Resp. Party',
        fieldName: 'transferTo',
    },
    {
        key: 'reservePymts',
        minWidth: 80,
        name: 'Pt. Reserve Pymts',
        fieldName: 'patientReservePayments',
        onRender: (item) => (
            <Text variant="smallPlus">{usdCurrencyFormatter.format(Math.abs(item?.patientReservePayments ?? 0))}</Text>
        ),
    },
];

export default function PaymentsAndAdjustmentsLineItemsList({
    encounterSummary,
    hideMakeAdjustment,
    hideMakePayment,
    makingPaymentOrAdjustment,
    paymentAmount,
    remainingPaymentAmount,
    transactions,
    adjustmentTransactions,
    onChangePayment,
    feeProp,
    tableType = 'patientBalance',
}: Props) {
    const { procedureSummaries: proceduresSummaries } = encounterSummary;

    const _columns = [...getPatientPaymentsAndAdjustmentLineItemColumns(tableType)];

    const paymentColumn: ISortableColumn<ProcedureSummary> = {
        key: 'payment',
        name: 'Payments',
        minWidth: 150,
        maxWidth: 150,
        onRender: (item) => (
            <PaymentTransactionField
                feeProp={feeProp}
                onChangePayment={onChangePayment}
                remainingPaymentAmount={remainingPaymentAmount}
                transactions={transactions}
                adjustmentTransactions={adjustmentTransactions}
                paymentAmount={paymentAmount}
                procedureSummary={item}
                encounterSummary={encounterSummary}
            />
        ),
    };

    const adjustmentColumn: ISortableColumn<ProcedureSummary> = {
        key: 'adjustment',
        name: 'Adjustments',
        minWidth: 150,
        maxWidth: 150,
        onRender: (item) => <AdjustmentTransactionField procedureSummary={item} encounterSummary={encounterSummary} />,
    };

    if (makingPaymentOrAdjustment) {
        if (!hideMakeAdjustment) _columns.unshift(adjustmentColumn);
        if (!hideMakePayment) _columns.unshift(paymentColumn);
    }

    return (
        <Stack style={{ paddingLeft: 10 }}>
            <SortableDetailsList<ProcedureSummaryWithEncounterIdAndTransactionId>
                selectionMode={SelectionMode.none}
                items={proceduresSummaries ?? []}
                showGrid
                compact
                columns={_columns}
            />
        </Stack>
    );
}
