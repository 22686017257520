import { useDispatch } from 'react-redux';
import { selectNewNote } from 'state/slices/chart-notes/note-browser/note-browser.selectors';
import { noteBrowserActions } from 'state/slices/chart-notes/note-browser/note-browser.slice';
import { TextEditor } from '../TextEditor';
import { usePickerPlugin } from '../usePickerPlugin';
import { useSelector, useTenantId } from 'hooks';
import { IProcedureDetail } from 'api/models/procedure-detail.model';
import { selectProcedureTemplatesLookup } from 'state/slices/lookups/lookups.selectors';
import { Text } from '@fluentui/react';
import { PROCEDURE_TEMPLATE_PICKER_TRIGGER_CHAR } from '../ClinicalNote';

function NoteViewerNewNote() {
    const dispatch = useDispatch();

    const tenantId = useTenantId();
    const newNote = useSelector(selectNewNote);
    const procedureTemplates = useSelector((state) => selectProcedureTemplatesLookup(state, tenantId));

    const procedureTemplatePicker = usePickerPlugin<IProcedureDetail>({
        triggerCharacter: PROCEDURE_TEMPLATE_PICKER_TRIGGER_CHAR,
        suggestionOptions: procedureTemplates.map((p) => ({
            displayName: `${p.code}: ${p.displayName}`,
            key: p.id,
            data: p,
        })),
        onGetSuggestionToInsert: (option) => {
            return option.data?.template ?? '';
        },
        onRenderListItem: (item) => {
            return (
                <>
                    <Text>
                        <strong>{item.data?.code}</strong>
                    </Text>{' '}
                    <Text style={{ textOverflow: 'ellipsis' }}>{item.data?.displayName}</Text>
                </>
            );
        },
    });

    if (!newNote) return null;

    const onChange = (value: string) => dispatch(noteBrowserActions.setNewNoteText(value));

    return <TextEditor
        placeholder={`Add notes... (Type the character '${PROCEDURE_TEMPLATE_PICKER_TRIGGER_CHAR}' to search/add procedure templates)`}
        plugins={[procedureTemplatePicker]}
        value={newNote.note}
        onChange={onChange}
    />;
}

export default NoteViewerNewNote;
