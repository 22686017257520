import { Container, _ReactPixi } from '@inlet/react-pixi';
import { useSelector } from 'hooks';
import { getPerioTeethPositions } from 'state/slices/charting/perio-exams/perio-exams.selectors';

import { UPPER_POSITIONS } from '../../perio-settings';
import DataLines from './DataLines';
import DepthLine from './DepthLine';
import PerioTooth from './PerioTooth';

function Arch({
    container,
    marginalDepths,
    probingDepths,
}: {
    container?: _ReactPixi.IContainer;
    marginalDepths?: number[][];
    probingDepths?: number[][];
}): JSX.Element {
    const toothPositions = useSelector(getPerioTeethPositions);

    const upperLines = UPPER_POSITIONS.map((line, index) => <DepthLine isUpper position={index} key={`line-${line}`} />);

    const upperTeeth = toothPositions.map((position, index) => {
        return <PerioTooth position={position ? position : undefined} key={`tooth=${index}`} />;
    });

    return (
        <Container {...container}>
            <Container>{upperTeeth}</Container>
            <Container>{upperLines}</Container>
            <DataLines probingDepths={probingDepths} marginalDepths={marginalDepths} />
        </Container>
    );
}

export default Arch;
