import { useSelector } from 'hooks';
import { ContentEdit, EditorPlugin, HtmlSanitizer, Paste } from 'roosterjs';
import {
    createRibbonPlugin,
    createUpdateContentPlugin,
    getButtons,
    KnownRibbonButtonKey,
    Ribbon,
    RibbonButton,
    Rooster,
    UpdateMode,
} from 'roosterjs-react';
import { ITheme, Stack, memoizeFunction, mergeStyleSets, useTheme } from '@fluentui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { useId } from '@fluentui/react-hooks';

const ribbonPlugin = createRibbonPlugin();
const contentEdit = new ContentEdit();
const paste = new Paste();

const buttons = getButtons([
    KnownRibbonButtonKey.Bold,
    KnownRibbonButtonKey.Italic,
    KnownRibbonButtonKey.Underline,
    KnownRibbonButtonKey.BulletedList,
    KnownRibbonButtonKey.NumberedList,
    KnownRibbonButtonKey.AlignRight,
    KnownRibbonButtonKey.AlignLeft,
    KnownRibbonButtonKey.Header,
    KnownRibbonButtonKey.Heading,
    KnownRibbonButtonKey.ClearFormat,
    KnownRibbonButtonKey.IncreaseIndent,
    KnownRibbonButtonKey.DecreaseIndent,
]) as RibbonButton<string>[];

type TextEditorNewProps = {
    value: string | undefined;
    onChange?: (value: string) => void;
    ribbonButtons?: RibbonButton<string>[];
    disabled?: boolean;
    plugins?: EditorPlugin[];
    placeholder?: string;
};

//Determine if the passed value has content for text editor div.
export function getEmptyRichTextContent(content: string | undefined) {
    const trimmedContent = content?.trim();
    return !trimmedContent || trimmedContent === '<br>' || trimmedContent === '<div></div>';
}

export function TextEditor({ value, onChange, disabled, ribbonButtons = [], plugins, placeholder }: TextEditorNewProps) {
    const isDarkMode = useSelector((state) => state.ui.selectedTheme) === 'dark';
    const theme = useTheme();
    const editorId = useId();

    const _onChange = (content: string) => {
        const sanitizedContent = HtmlSanitizer.sanitizeHtml(content ?? '', {
            additionalTagReplacements: { img: '' },
        });
        if (onChange) onChange(sanitizedContent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceOnChange = useCallback(debounce(_onChange, 800), []);

    //Flag used to determine when the editor has content. Updates immediately without waiting for debounceOnChange.
    const [contentHasValue, setContentHasValue] = useState<boolean>(false);

    const handleUpdateContentHasValue = useCallback((content: string | undefined) => {
        if (getEmptyRichTextContent(content)) {
            setContentHasValue(false);
        } else {
            setContentHasValue(true);
        }
    }, []);

    useEffect(() => {
        //Value may not be available until data is loaded. Ensure bool is set properly.
        handleUpdateContentHasValue(value);
    }, [value]);

    const handleContentChanged = useCallback((content: string) => {
        debounceOnChange(content);
        //Update whether content exists immediately, rather than waiting for debounce.
        handleUpdateContentHasValue(content);
    }, []);

    const contentUpdatePlugins = useMemo(
        () => [
            createUpdateContentPlugin(UpdateMode.OnContentChangedEvent, handleContentChanged),
            createUpdateContentPlugin(UpdateMode.OnUserInput, handleContentChanged),
        ],
        [debounceOnChange],
    );
    const defaultPlugins = useMemo(() => [contentEdit, paste, ribbonPlugin], []);
    const classes = getClasses(theme);

    return (
        <>
            <Stack className={classes.root} grow>
                {!disabled && (
                    <Ribbon plugin={ribbonPlugin} buttons={[...buttons, ...ribbonButtons]} className={classes.ribbon} />
                )}
                <Stack className={classes.roosterWrapper}>
                    {!contentHasValue && placeholder ? <span className={classes.roosterPlaceholder}>{placeholder}</span> : null}
                    <Rooster
                        dangerouslySetInnerHTML={{ __html: value ?? '' }}
                        plugins={[...defaultPlugins, ...contentUpdatePlugins, ...(plugins ?? [])]}
                        initialContent={value}
                        style={{ overflow: 'auto' }}
                        className={classes.roosterComponent}
                        inDarkMode={isDarkMode}
                        contentEditable={!disabled}
                        id={editorId}
                        dir="ltr"
                    />
                </Stack>
            </Stack>
        </>
    );
}
const classNames = {
    root: 'text-editor_wrapper',
    ribbon: 'rooster_ribbon',
    roosterWrapper: 'rooster_wrapper',
    roosterComponent: 'rooster_component',
    roosterPlaceholder: 'rooster_placeholder',
};

const getClasses = memoizeFunction(({ palette }: ITheme) =>
    mergeStyleSets({
        root: [
            classNames.root,
            {
                position: 'relative',
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                tabIndex: 0,
                width: '100%',
            },
        ],
        disabled: [
            `disabled`,
            {
                background: palette.neutralLighterAlt,
                border: palette.neutralQuaternaryAlt,
                overflowY: 'auto',
            },
        ],
        ribbon: [
            classNames.ribbon,
            {
                top: 0,
                left: 0,
                zIndex: 4,
                width: '100%',
                borderBottom: '1px solid #DDD',
                position: 'sticky',
            },
        ],
        roosterWrapper: [classNames.roosterWrapper, { flex: 1, overflowY: 'auto', position: 'relative' }],
        roosterComponent: [classNames.roosterComponent, { minHeight: 100, padding: 5 }],
        roosterPlaceholder: [
            classNames.roosterPlaceholder,
            { position: 'absolute', top: 5, left: 7, color: palette.black, opacity: 0.4, pointerEvents: 'none' },
        ],
    }),
);
