import { patientAppointmentStatusText } from 'api/models/Scheduling/patientAppointment.model';
import IWorkListAppointmentView from 'api/models/worklist-appointment-view';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';

const appointmentConfirmationStatus: ISortableColumn<IWorkListAppointmentView> = {
    key: 'appointmentConfirmationStatus',
    minWidth: 140,
    maxWidth: 140,
    isResizable: true,
    name: 'Confirmation Status',
    fieldName: 'appointmentConfirmationStatus',
    onRender: (item) =>
        item?.appointmentConfirmationStatus ? (
            <span>{patientAppointmentStatusText[item.appointmentConfirmationStatus]}</span>
        ) : null,
};
export default appointmentConfirmationStatus;
