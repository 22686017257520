import React, { useEffect } from 'react';
import {
    DefaultButton,
    DirectionalHint,
    Link,
    MessageBar,
    Panel,
    PanelType,
    SelectionMode,
    Spinner,
    Stack,
    Toggle,
    TooltipHost,
    useTheme,
} from '@fluentui/react';
import IImagingSource from 'api/models/imaging-source.model';
import { SortableDetailsList } from 'components';
import { useTenantId } from 'hooks';
import usePermissions, { DentalPermissions } from 'hooks/store/usePermissions';
import { useDownloadFile } from 'hooks/useDocumentLink';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccountData } from 'state/slices/account.slice';
import { setCurrentTutorial } from 'state/slices/teaching/teaching.slice';
import { TutorialSteps } from 'state/slices/teaching/teaching.state';
import {
    getGenericImagingSources,
    getImagingSourceById,
    imagingSourceActions,
    imagingSourceLoading,
    imagingSourceShowHistory,
    selectGenericImagingSourceMenuItems,
    selectImagingSourceAsList,
    selectImagingSourcePanelOpen,
} from 'state/slices/tenant/imaging-source.slice';
import { classicDateOnly } from 'utils/dateOnly';
import PanelSectionHeader from '../PanelSectionHeader';
import Tutorial from '../Tutorial/Tutorial';
import { ImagingSourceModal } from './ImagingSourceModal';

export default function ImagingSourcePanel(): JSX.Element {
    const dispatch = useDispatch();
    const tenantId = useTenantId();
    const theme = useTheme();
    const isOpen = useSelector(selectImagingSourcePanelOpen);
    const account = useSelector(selectAccountData);
    const loading = useSelector(imagingSourceLoading);
    const showHistory = useSelector(imagingSourceShowHistory);
    const imagingSources = useSelector(selectImagingSourceAsList);
    const _selectGenericImagingSourceMenuItems = useSelector(selectGenericImagingSourceMenuItems);
    const [_imagingBridgePermission] = usePermissions([DentalPermissions.ConfigureImaging]);

    const { downloadImagingBridge } = useDownloadFile();

    // Effect to fetch generic imaging sources when the panel is open
    useEffect(() => {
        if (isOpen) {
            dispatch(getGenericImagingSources());
        }
    }, [isOpen, dispatch]);

    const onDismiss = () => {
        dispatch(imagingSourceActions.setImagingSourcePanelOpen(false));
    };

    const toggleShowHistory = () => dispatch(imagingSourceActions.toggleShowImagingSourceHistory());

    const _onItemInvoked = (item?: IImagingSource) => {
        if (item && _imagingBridgePermission) dispatch(getImagingSourceById({ tenantId, imagingSourceId: item.id }));
    };

    const _createImagingSource = (itemId: string) => dispatch(imagingSourceActions.createInitlaImagingSource(itemId));

    const openTutorial = () => {
        if (account?.identity?.id)
            dispatch(
                setCurrentTutorial({
                    tutorial: 'ImagingSourceTutorial',
                    step: TutorialSteps.One,
                    userId: account?.identity?.id,
                }),
            );
    };

    return (
        <Panel
            headerText="Manage Imaging Bridge"
            isOpen={isOpen}
            type={PanelType.custom}
            onDismiss={onDismiss}
            customWidth="600px"
            isLightDismiss={false}
        >
            <Tutorial />
            <Stack tokens={{ childrenGap: 10 }}>
                {_imagingBridgePermission ? (
                    <Stack.Item grow>
                        <TooltipHost
                            content={
                                !_selectGenericImagingSourceMenuItems.length
                                    ? 'There are no available imaging sources to configure.'
                                    : undefined
                            }
                            directionalHint={DirectionalHint.leftCenter}
                            styles={{ root: { minWidth: '100%', display: 'flex' } }}
                        >
                            <DefaultButton
                                title="Configure Imaging bridge"
                                iconProps={{ iconName: 'Add' }}
                                text="Configure Imaging bridge"
                                menuProps={{
                                    items: _selectGenericImagingSourceMenuItems,
                                    useTargetWidth: true,
                                    onItemClick: (ev?, item?) => {
                                        if (item) _createImagingSource(item.key);
                                    },
                                    styles: {
                                        root: {
                                            backgroundColor: theme.palette.neutralLight,
                                        },
                                    },
                                }}
                                disabled={!_selectGenericImagingSourceMenuItems.length}
                                styles={{
                                    root: {
                                        width: '100%',
                                        display: 'flex !imporant',
                                    },
                                }}
                            />
                        </TooltipHost>
                    </Stack.Item>
                ) : null}
                <DefaultButton
                    onClick={() => {
                        openTutorial();
                        downloadImagingBridge();
                    }}
                    id="ImagingSourceDownload"
                    title="Download Imaging bridge"
                    text="Download Imaging bridge"
                />
                <Stack>
                    <PanelSectionHeader
                        text="Tenant Imaging Sources"
                        rightContent={
                            <Toggle
                                styles={{ root: { marginBottom: 0 } }}
                                label="Show History"
                                onChange={toggleShowHistory}
                                checked={showHistory}
                                inlineLabel
                            />
                        }
                    />
                    {loading ? (
                        <Spinner label="Loading imaging sources..." style={{ marginTop: 10 }} />
                    ) : imagingSources.length ? (
                        <SortableDetailsList<IImagingSource>
                            selectionMode={SelectionMode.none}
                            sortOnMount={true}
                            onItemInvoked={_onItemInvoked}
                            columns={[
                                {
                                    key: 'createdOn',
                                    minWidth: 100,
                                    maxWidth: 100,
                                    name: 'Created On',
                                    fieldName: 'createdOn',
                                    onRender: (item) => {
                                        return (
                                            <span>
                                                {item?.isDeleted ? (
                                                    <i>{item?.createdOn ? classicDateOnly(item?.createdOn) : 'N/A'}</i>
                                                ) : (
                                                    <>{item?.createdOn ? classicDateOnly(item?.createdOn) : 'N/A'}</>
                                                )}
                                            </span>
                                        );
                                    },
                                },
                                {
                                    key: 'displayName',
                                    minWidth: 100,
                                    name: 'Name',
                                    fieldName: 'displayName',
                                    onRender: (item) => {
                                        return (
                                            <Link disabled={!_imagingBridgePermission} onClick={() => _onItemInvoked(item)}>
                                                {item?.isDeleted ? (
                                                    <i>{item?.displayName ?? ''}</i>
                                                ) : (
                                                    <>{item?.displayName ?? ''}</>
                                                )}
                                            </Link>
                                        );
                                    },
                                },
                            ]}
                            items={imagingSources}
                        />
                    ) : (
                        <MessageBar>No active imaging sources exist.</MessageBar>
                    )}
                </Stack>
            </Stack>
            <ImagingSourceModal />
        </Panel>
    );
}
