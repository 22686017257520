import { IPredeterminationProcedure } from 'api/models/predeterminations.model';
import { RecentWorkListItem } from 'utils/appLocalStorage';
import { WorkListRecentlyViewedAction } from '../WorkListRecentItems';

export const getRecentlyViewedPredeterminationItemFromData = (data: Partial<IPredeterminationProcedure>): RecentWorkListItem => ({
    id: data?.id ?? '',
    displayName: `${data.patientName}${data.procedureCode ? ` (${data.procedureCode})` : ''}`,
});

export const predeterminationRecentItemActions: WorkListRecentlyViewedAction[] = [
    {
        key: 'patientProfile',
        iconProps: {
            iconName: 'Contact',
        },
        idFilterFieldName: 'id',
        text: 'Patient Profile',
    },
    {
        key: 'editPredetermination',
        iconProps: {
            iconName: 'Edit',
        },
        idFilterFieldName: 'id',
        text: 'Edit Authorization',
    },
];
