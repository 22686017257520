import { format, isBefore } from 'date-fns';
import { RootState } from 'state/store';

const Medications = ({ state }: { state: RootState }): JSX.Element | null => {
    const { patientEncounter, patientEncounterUserIdentities } = state.encounter;
    const { data } = state.patient.medications;
    const medications = state.chartNotes.quickNotes.assets.pamv.medications;
    const tomorrowsDate = new Date().setDate(new Date().getDate() + 1);
    if (!patientEncounter) return null;

    const medicationsReviewedBy = patientEncounter.medicationsReviewedBy
        ? patientEncounterUserIdentities[patientEncounter.medicationsReviewedBy]
        : undefined;

    return (
        <>
            <h3>Medications</h3>
            <ul>
                {data && data.noMedicationsReported && <li>No medications reported</li>}
                {medicationsReviewedBy && (
                    <li>
                        Medications reviewed by: {medicationsReviewedBy.firstName} {medicationsReviewedBy.lastName}
                    </li>
                )}
                {patientEncounter.medicationsReviewedDate && (
                    <li>
                        Medications reviewed on:{' '}
                        {format(new Date(patientEncounter.medicationsReviewedDate ?? ''), 'MM/dd/yyyy hh:mm aaa')}
                    </li>
                )}
                {medications && medications.medications
                    ? medications.medications
                          .filter(
                              (med) =>
                                  !med.isDeleted &&
                                  (med.stopDate ? (isBefore(new Date(med.stopDate), tomorrowsDate) ? false : true) : true),
                          )
                          .map((medication) => <li key={medication.id}>{medication.medicationName}</li>)
                    : null}
            </ul>
        </>
    );
};

export default Medications;
