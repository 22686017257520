import ReactDOM from 'react-dom';
import Root from './Root';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import { ApplicationInsights, DistributedTracingModes } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import { registerIcons } from '@fluentui/react';
import PerioIcon from 'components/PerioIcon';
import BrokenToothIcon from 'components/BrokenTooth';
import Bridge from 'components/BridgeIcon';
import PreMedIcon from 'components/PreMedIcon';
import RcmIcon from 'components/RcmIcom';
//import reportWebVitals from 'reportWebVitals';

registerIcons({
    icons: {
        Perio: <PerioIcon />,
        BrokenTooth: <BrokenToothIcon />,
        Bridge: <Bridge />,
        preMedication: <PreMedIcon />,
        RcmIcon: <RcmIcon />,
    },
});

const instrumentationKey =
    process.env.NODE_ENV === 'production' ? '#{AIInstrumentationKey}#' : 'cf4829b5-378c-4ad8-b36e-3ce1f5f360fe';

export const reactPlugin = new ReactPlugin();
export const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey,
        enableAutoRouteTracking: true,
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        extensions: [reactPlugin],
    },
});
appInsights.loadAppInsights();

ReactDOM.render(<Root />, document.getElementById('root'));

// reportWebVitals(console.log);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
