import {
    Checkbox,
    DefaultButton,
    IconButton,
    IPanelStyles,
    MessageBar,
    Modal,
    Panel,
    PanelType,
    PrimaryButton,
    Stack,
    StackItem,
} from '@fluentui/react';
import Report from 'components/Report';
import { useSelector } from 'hooks';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { orderBy } from 'lodash';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { chartingActions, setPerioExamsToCompare, setPerioPanelOpen } from 'state/slices/charting/chart/chart.slice';
import { printPerioExams } from 'state/slices/charting/perio-exams/perio-exams.actions';
import { selectPerioPrintExams, selectPrintExams } from 'state/slices/charting/perio-exams/perio-exams.selectors';
import { selectPerioPanel } from 'state/slices/charting/perio-panel/perio-panel.selectors';
import { selectSelectedPatient } from 'state/slices/patient/patient.selectors';
import { usePatientId, useTenantId } from 'hooks';
import useEncounterId from 'hooks/useEncounterId';
import { convertFromMilitaryTime } from 'utils/convertFromMilitaryTime';

const _panelStyles: Partial<IPanelStyles> = {
    scrollableContent: { overflow: 'hidden', height: '100%' },
    content: { height: '100%', paddingRight: 0, overflow: 'hidden' },
};

function PerioPanel(): JSX.Element {
    const { isOpen, isComparing } = useSelector(selectPerioPanel);
    const perioExams = useSelector(selectPerioPrintExams);
    const patient = useSelector(selectSelectedPatient);

    const [examList, setExamList] = useState<string[]>([]);

    const { loading } = useSelector(selectPrintExams);
    const { push } = useHistory();
    const tenantId = useTenantId();
    const patientId = usePatientId();
    const encounterId = useEncounterId();

    const dispatch = useDispatch();

    const dismissPanel = () => {
        dispatch(setPerioPanelOpen(false));
    };

    const dismissedPanel = () => {
        setExamList([]);
    };

    const _printExams = () => {
        if (patient && examList.length) {
            dispatch(printPerioExams({ tenantId, patientId: patient.id, examIds: examList }));
        }
    };

    const _compareExams = () => {
        if (examList.length) {
            const filteredList = perioExams.filter((res) => examList.includes(res.id));
            const latestPerio = orderBy(filteredList, ['createdOn'], ['desc']);
            dispatch(setPerioPanelOpen(false));
            dispatch(setPerioExamsToCompare(filteredList));
            if (encounterId) {
                push(`/${tenantId}/patient/${patientId}/encounter/${encounterId}/perio/${latestPerio[0].id}`);
            } else {
                push(`/${tenantId}/patient/${patientId}/perio/${latestPerio[0].id}`);
            }
        }
    };

    const _footerContent = () => (
        <Stack horizontal tokens={{ childrenGap: 10 }}>
            <PrimaryButton
                iconProps={{ iconName: 'Print' }}
                text={loading === LoadingStatus.Pending ? 'Loading...' : 'Print'}
                disabled={!examList.length}
                onClick={_printExams}
            />
            <DefaultButton text="Cancel" onClick={dismissPanel} />
        </Stack>
    );

    const _footerCompareContent = () => (
        <Stack horizontal tokens={{ childrenGap: 12 }}>
            <PrimaryButton
                text={loading === LoadingStatus.Pending ? 'Loading...' : 'Compare'}
                disabled={examList.length <= 1}
                onClick={_compareExams}
            />
            <DefaultButton text="Cancel" onClick={dismissPanel} />
        </Stack>
    );

    const _handleAddExamId = (examId?: string) => {
        if (examId) {
            const examIndex = examList.findIndex((d) => d === examId);
            if (examIndex > -1) {
                setExamList(examList.filter((d) => d !== examId));
            } else {
                setExamList([...examList, examId]);
            }
        }
    };

    const printPerioExamCheckboxes = perioExams.map((perioExam) => {
        const examId = perioExam.id;
        const date = perioExam.encounter?.encounterDate ? perioExam.encounter.encounterDate : 'Unknown Date';
        const time = perioExam?.appointmentStartTime ? ` (${convertFromMilitaryTime(perioExam.appointmentStartTime)})` : ''; 
        return (
            <Checkbox
                key={perioExam.id}
                label={`${perioExam.encounter?.encounterDate ? `${date}${time}`: 'Unknown Date'} `}
                onChange={() => _handleAddExamId(examId)}
            />
        );
    });

    return (
        <>
            <PrintModal />
            {isComparing ? (
                <Panel
                    isOpen={isOpen}
                    headerText="Compare Perio Exams"
                    closeButtonAriaLabel="Close"
                    customWidth={'25vw'}
                    onRenderFooterContent={_footerCompareContent}
                    onDismiss={dismissPanel}
                    onDismissed={dismissedPanel}
                    type={PanelType.custom}
                    styles={_panelStyles}
                    isFooterAtBottom={true}
                >
                    <Stack tokens={{ childrenGap: 10 }}>
                        <StackItem grow>
                            <MessageBar>Select two probing dates to compare.</MessageBar>
                        </StackItem>
                        <Stack tokens={{ childrenGap: 5 }}>{printPerioExamCheckboxes}</Stack>
                    </Stack>
                </Panel>
            ) : (
                <Panel
                    isOpen={isOpen}
                    headerText="Print Perio Exam"
                    closeButtonAriaLabel="Close"
                    customWidth={'25vw'}
                    onRenderFooterContent={_footerContent}
                    onDismiss={dismissPanel}
                    onDismissed={dismissedPanel}
                    type={PanelType.custom}
                    styles={_panelStyles}
                    isFooterAtBottom={true}
                >
                    <Stack tokens={{ childrenGap: 10 }}>
                        <StackItem grow>
                            <MessageBar>Select Probing Dates to be Printed:</MessageBar>
                        </StackItem>
                        <Stack tokens={{ childrenGap: 5 }}>{printPerioExamCheckboxes}</Stack>
                    </Stack>
                </Panel>
            )}
        </>
    );
}

export default PerioPanel;

function PrintModal(): JSX.Element | null {
    const printExam = useSelector(selectPrintExams);
    const loaded = printExam.loading === LoadingStatus.Completed;
    const dispatch = useDispatch();
    const closeModal = () => {
        dispatch(chartingActions.clearPrintExams());
    };

    const { data: report } = printExam;
    return (
        <Modal styles={{ main: { width: 1368, height: 768 } }} isOpen={loaded} onDismiss={closeModal} isBlocking={false}>
            <Stack horizontalAlign="end">
                <IconButton iconProps={{ iconName: 'cancel' }} ariaLabel="Close popup modal" onClick={closeModal} />
            </Stack>
            {report && <Report {...report} />}
        </Modal>
    );
}
