import LookupSettingPage from '../Shared/LookupSettingPage';
import DetailsList from './DetailsList';
import EditPanel from './EditPanel';
import SettingsSearchBox from '../components/SettingsSearchBox';
import { useState, useEffect } from 'react';
import { Provider } from 'models/provider';
import { useTenantId } from 'hooks';
import { useDispatch } from 'react-redux';
import { getUsers } from 'state/slices/settings/users/users.actions';

const newItem: Provider = {
    id: '',
    firstName: '',
    lastName: '',
    isTreatingProvider: false,
    isRegisteredDentalAssistant: false,
    isHygienist: false,
    isResident: false,
    isDeleted: false,
    providerSchedule: undefined,
};

const ManageProviders = (): JSX.Element => {
    const [searchTerm, setSearchTerm] = useState<string>('');
    const tenantId = useTenantId();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUsers({ tenantId }));
    }, [tenantId, dispatch]);

    return (
        <LookupSettingPage<Provider>
            lookupType="Providers"
            displayName="Provider"
            newItem={newItem}
            listComponent={<DetailsList searchTerm={searchTerm} />}
            editComponent={<EditPanel />}
            searchBoxComponent={
                <SettingsSearchBox searchTerm={searchTerm} placeholder="Search Providers" onSearchChange={setSearchTerm} />
            }
        />
    );
};

export default ManageProviders;
