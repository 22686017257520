import { ICariesRiskChildQuestions, RiskType } from "./types"

export const formTitle = "Caries Risk Assessment";

export const questions: { [key in keyof ICariesRiskChildQuestions]: string } = {
    additionalPatientInstructions: RiskType.None,
    dentalHome: RiskType.None,
    fluorideExposure: RiskType.None,
    specialHealthcareNeeds: RiskType.None,
    sugaryStarchyFoodsOrDrinks: RiskType.None,
    teethMissingDueToCaries: RiskType.None,
    visiblePlaque: RiskType.None,
    cariesLesions: RiskType.None,
    dentalOrthodonticAppliancesPresent: RiskType.None,
    eligibleForGovernmentPrograms: RiskType.None,
    nonCavitatedIncipientCariousLesions: RiskType.None,
    salivaryFlow: RiskType.None,
    visuallyEvidentRestorationsCariousLesions: RiskType.None,
    govtProgramsEligibility: RiskType.None
}

export const noteTransalations: { [key in keyof ICariesRiskChildQuestions]: string } = {}