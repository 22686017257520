import { RootState } from 'state/store';
import dateOnly from 'utils/dateOnly';

export default function DentalHistory({ state }: { state: RootState }): JSX.Element | null {
    const form = state.chartNotes.quickNotes.assets.forms?.dentalHistory;

    if (!form) return null;

    const createdBy = form.createdBy ? state.userIdentities.data[form.createdBy] : undefined;
    const dateAndTime = form.modifiedOn ? dateOnly(form.modifiedOn, 'MM/dd/yyyy h:m aaa') : null;

    return (
        <>
            <h3>Dental History: </h3>
            <ul>
                {
                    <li>
                        Dental history reviewed by: {createdBy?.firstName} {createdBy?.lastName} {createdBy?.professionalSuffix}
                    </li>
                }
                {<li>Dental history reviewed on: {dateAndTime}</li>}
            </ul>
        </>
    );
}
