import { IChartProcedure } from 'api/models/chart.model';
import { IProcedureDetail, ProcedureDetails } from 'api/models/procedure-detail.model';
import { uniqBy } from 'lodash';
import { toothSpriteReferences } from 'pages/Charting/components/ToothCanvas/spriteList';
import { RootState } from 'state/store';
import getShorthandToothArea from 'utils/getShorthandToothArea';
import { selectCompletedProcedures } from '../quick-notes.selectors';

export default function ProcedureNoteTemplates({ state, tenantId }: { state: RootState; tenantId?: string }): JSX.Element | null {
    const completedChartProcedures = state.chartNotes.quickNotes.assets.chartProcedures.completed;

    const { patientEncounter } = state.encounter;

    if (!patientEncounter || !completedChartProcedures.length || !tenantId) return null;

    return (
        <>
            <h3>Completed Procedures (cont.)</h3>
            <ProcedureNotesTemplate tenantId={tenantId} chartProcedures={completedChartProcedures} state={state} />
        </>
    );
}

export function ProcedureNoteTemplateCode({
    code,
    template,
    index = 0,
    teethAndAreasText,
}: {
    code?: string;
    template?: string;
    index?: number;
    teethAndAreasText?: string[];
}) {
    //If a procedure detail doesn't have a template, there is no reason to try and render the template.
    if (!template) return null;

    return (
        <>
            {index !== 0 && <br />}
            <ul>
                <li>
                    {code ? <strong>{code}</strong> : null} {teethAndAreasText ? teethAndAreasText : ''}
                </li>
            </ul>
            <div style={{ paddingLeft: 40 }} dangerouslySetInnerHTML={{ __html: template }}></div>
        </>
    );
}

function getTeethAndArea(chartProcedures: IChartProcedure[] | undefined, procedureId?: string) {
    const matchingChartProcedures = chartProcedures?.filter((chartProcedure) => chartProcedure.procedureId === procedureId);

    const items = matchingChartProcedures?.map((chartProcedure) => {
        const teethDisplayNames = chartProcedure.toothIds?.map(
            (toothId) => toothSpriteReferences.find((ref) => ref.id === toothId)?.displayName,
        );

        const areas = getShorthandToothArea(chartProcedure.areas);
        if (teethDisplayNames === undefined || areas === undefined) {
            return '';
        }
        return `  #${teethDisplayNames?.join('') ?? ''}${areas ? ` (${areas})` : ''}`;
    });

    return items;
}

export function ProcedureNotesTemplateFromProcedureDetail({
    procedureDetail,
    state,
}: {
    procedureDetail: IProcedureDetail | undefined;
    state: RootState;
}) {
    const completedProcedures = selectCompletedProcedures(state)?.filter(
            (p) => p.procedureId === procedureDetail?.id,
        );
    const teethAndAreasText = getTeethAndArea(completedProcedures, procedureDetail?.id);

    return (
        <ProcedureNoteTemplateCode
            teethAndAreasText={teethAndAreasText}
            code={procedureDetail?.code}
            template={procedureDetail?.template}
        />
    );
}

export function ProcedureNotesTemplate({
    chartProcedures,
    state,
    tenantId,
}: {
    chartProcedures?: IChartProcedure[];
    state: RootState;
    tenantId?: string;
}): JSX.Element | null {
    if (!tenantId) return null;

    const procedureDetailLookup: ProcedureDetails = state.lookups.tenants[tenantId]?.ProcedureDetails?.items;

    const procedureListItems = uniqBy(chartProcedures, 'procedureId').map((chartProcedure, index) => {
        const procedureDetail = chartProcedure.procedureId ? procedureDetailLookup[chartProcedure.procedureId] : undefined;
        const teethAndAreasText = getTeethAndArea(chartProcedures, chartProcedure.procedureId);

        return (
            <ProcedureNoteTemplateCode
                key={`proc-template-code-item-${index}`}
                index={index}
                teethAndAreasText={teethAndAreasText}
                code={procedureDetail?.code}
                template={procedureDetail?.template}
            />
        );
    });

    return <div>{procedureListItems}</div>;
}
