import React from 'react';
import { ProgressIndicator } from '@fluentui/react';
import { useSelector } from 'react-redux';
import { patientLoading } from 'state/slices/patient/patient.selectors';

function PatientDetailsLoadingIndicator() {
    const _loading = useSelector(patientLoading);
    if (_loading) {
        return <ProgressIndicator />;
    } else {
        return null;
    }
}

export default PatientDetailsLoadingIndicator;
