import { PrimaryButton, Spinner, Stack } from '@fluentui/react';
import { ReportType } from 'api/models/embed-report.model';
import { Section } from 'components';
import { useSelector } from 'hooks';
import useValidation, { IValidationConfig, IValidationError, IValidationOptions } from 'hooks/useValidation';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { PropsWithChildren, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { selectReportingState } from 'state/slices/reporting/reporting.selectors';
import { cleanupSelectedReportProperties, setReportValidationErrors } from 'state/slices/reporting/reporting.slice';

type Props = {
    onViewReport: () => void;
    validationConfig?: IValidationConfig;
    validationOption?: IValidationOptions;
};

export default function ReportWrapper({ onViewReport, validationConfig, children }: PropsWithChildren<Props>) {
    const dispatch = useDispatch();

    const { loadingSelectedReport } = useSelector(selectReportingState);
    const [errors, onSubmit, cleanupErrors] = useValidation(validationConfig ?? [], onViewReport);

    const onValidationError = (errs: IValidationError[]) => {
        dispatch(setReportValidationErrors(errs));
    };

    useEffect(() => {
        if (onValidationError) onValidationError(errors);
    }, [errors]);

    useEffect(() => {
        return () => {
            cleanupErrors();
            dispatch(cleanupSelectedReportProperties());
        };
    }, []);

    const _loadingSelectedReport = loadingSelectedReport === LoadingStatus.Pending;

    return (
        <Stack tokens={{ childrenGap: 10 }} grow>
            <Section>
                <Stack grow tokens={{ childrenGap: 10 }}>
                    <Stack tokens={{ childrenGap: 10 }} grow>
                        {children}
                    </Stack>
                    <Stack tokens={{ childrenGap: 10 }} horizontalAlign="end" horizontal grow>
                        {_loadingSelectedReport && <Spinner />}
                        <PrimaryButton disabled={_loadingSelectedReport} text="View Report" onClick={onSubmit} />
                    </Stack>
                </Stack>
            </Section>
        </Stack>
    );
}
