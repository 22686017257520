import { RootState } from 'state/store';
import { ProcedureNoteTemplate } from './CompletedProcedures';

export default function TreatmentPlannedProcedures({ state }: { state: RootState }): JSX.Element | null {
    const procedures = state.chartNotes.quickNotes.assets.chartProcedures.treatmentPlanned;
    if (!procedures.length) return null;
    return (
        <>
            <h3>Treatment Planned Procedures</h3>
            <ProcedureNoteTemplate chartProcedures={procedures} state={state} />
        </>
    );
}
