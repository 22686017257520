import { ITheme, Label, memoizeFunction, mergeStyleSets, Shimmer, Spinner, Stack, Text, useTheme } from '@fluentui/react';
import DashboardBoardTotals from 'api/models/dashboard-totals.model';
import { IWorkListMenu, WorkListComponents } from 'api/models/worklist-menu.model';
import { useSelector, useTenantId } from 'hooks';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { useHistory } from 'react-router-dom';
import { selectWorkListDetailData, selectWorkListDetailDataLoading } from 'state/slices/admin-huddle/worklists/worklist.selectors';
import currencyFormatter from 'utils/currencyFormatter';

const CardDetails = ({ detail }: { detail: keyof DashboardBoardTotals }) => {
    const theme = useTheme();
    const detailsData = useSelector(selectWorkListDetailData);
    const detailLoading = useSelector(selectWorkListDetailDataLoading);

    const detailData = detailsData ? detailsData[detail] as number ?? 0 : 0;

    const { text } = getClasses(theme);

    return (
        <Stack style={{ flex: 1, pointerEvents: 'none' }}>
            {detailLoading === LoadingStatus.Pending ? (
                <Spinner />
            ) : (
                <Text className={text} variant="xLargePlus">
                    {currencyFormatter.format(detailData)}
                </Text>
            )}
        </Stack>
    );
};

const lookupCardDetails: Partial<Record<WorkListComponents, JSX.Element>> = {
    readyToReview: <CardDetails detail="readyToReviewEncounterTotal"/>,
    denials: <CardDetails detail="deniedEncounterTotal"/>,
    billedInsurance: <CardDetails detail="insuranceOpenBilledEncounterTotal" />,
    billedPatient: <CardDetails detail="patientOpenBilledEncounterTotal" />,
    readyToRebill: <CardDetails detail="readyToReBillEncounterTotal" />,
    editEncounters: <CardDetails detail="editEncounterTotal" />,
};

export function WorkListCategoryCard(props: IWorkListMenu): JSX.Element | null {
    const theme = useTheme();
    const tenantId = useTenantId();
    const { push } = useHistory();

    const { root, child, text } = getClasses(theme);

    const _onClick = () => push(`/${tenantId}/administrative/${props.categoryId}/${props.id}`);

    const Count = () => {
        if (props.count === undefined) return null;

        return (
            <Stack style={{ flex: 1, pointerEvents: 'none' }} horizontalAlign="center" verticalAlign="center">
                <Stack className={child}>
                    <Text className={text} variant="superLarge">
                        {props.count}
                    </Text>
                </Stack>
            </Stack>
        );
    };

    const detail = lookupCardDetails[props.component];

    return (
        <Stack
            className={root}
            horizontalAlign="center"
            onClick={_onClick}
            verticalAlign={props.count !== undefined ? 'start' : 'center'}
        >
            <Stack horizontalAlign="center">
                <Label className={text}>{props.displayName}</Label>
                {detail ? detail : null}
            </Stack>

            <Count />
        </Stack>
    );
}

const classNames = {
    root: 'card',
    child: 'card_count',
    text: 'card_text',
};

const getClasses = memoizeFunction((theme: ITheme) =>
    mergeStyleSets({
        root: [
            classNames.root,
            {
                width: 200,
                height: 150,
                padding: 10,
                background: theme.palette.themeSecondary,
                cursor: 'pointer',
                borderRadius: 3,
                transition: 'background .15s',
                [`:hover`]: {
                    background: theme.palette.themePrimary,
                },
            },
        ],

        child: [
            classNames.child,
            {
                pointerEvents: 'none',
                transition: 'text-shadow .15s',
                borderRadius: 3,
                padding: '3px 10px',
                [`.${classNames.root}:hover &`]: {
                    textShadow: '0 3px 3px rgba(0,0,0,0.5)',
                },
            },
        ],
        text: [
            classNames.text,
            {
                color: theme.palette.white,
            },
        ],
    }),
);
