export const initialState: ChartActionFilters = {
    search: null,
    status: [],
    type: [],
    providers: [],
    filtersVisible: false,
    startDate: '',
    endDate: '',
    teeth: [],
};

export type ChartActionFilters = {
    search: string | null;
    status: string[];
    type: string[];
    providers: string[];
    filtersVisible: boolean;
    startDate: string;
    endDate: string;
    teeth: number[];
};
