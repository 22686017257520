import { AnyAction, createAsyncThunk, ThunkDispatch } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import IPatientImmunizations from 'api/models/patient-immunization.model';
import { cloneDeep } from 'lodash';
import ErrorTypes from 'state/errorTypes';
import { AppThunk, RootState } from 'state/store';
import { setOverviewItemDataProp } from '../patient.slice';

export const createOverviewItem = createAsyncThunk<
    any,
    {
        tenantId: string;
        item: any;
        type: string;
    },
    { rejectValue: string }
>('createOverviewItem', async ({ tenantId, item, type }, { rejectWithValue }) => {
    try {
        const patientId = item.id;
        const response = await dentalApi.createOverviewItem(tenantId, patientId, item, type);
        return { data: response.data, type: type };
    } catch (err: any) {
        if (err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(err.toString());
        }
    }
});

export const updateOverviewItem = createAsyncThunk<
    {
        data: any;
        type: string;
    },
    {
        tenantId: string;
        item: any;
        type: string;
    },
    { rejectValue: string }
>('updateOverviewItem', async ({ tenantId, item, type }, { rejectWithValue }) => {
    try {
        const patientId = item.id;
        const response = await dentalApi.updateOverviewItem(tenantId, patientId, item, type);
        return { data: response.data, type: type };
    } catch (err: any) {
        if (err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(err.toString());
        }
    }
});

let timer: NodeJS.Timeout | null = null;
export const saveOrCreateOverviewItem =
    (tenantId: string, patientId: string, type: string, item: any) =>
    (dispatch: ThunkDispatch<RootState, null, AnyAction>): void => {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            if (item?.id) {
                dispatch(updateOverviewItem({ tenantId, item, type }));
            } else {
                dispatch(createOverviewItem({ tenantId, type, item: { id: patientId, ...item } }));
            }
        }, 2000);
    };

export const setOverviewDataPropAndSave =
    (type: string, path: string, value: string | boolean | undefined, tenantId: string, patientId: string) =>
    async (dispatch: ThunkDispatch<RootState, null, AnyAction>, getState: () => RootState): Promise<void> => {
        await dispatch(setOverviewItemDataProp({ type, path, value }));
        const item = (getState().patient as any)[type]?.data;
        dispatch(saveOrCreateOverviewItem(tenantId, patientId, type, item));
    };
export const createPatientImmunization = createAsyncThunk<
    IPatientImmunizations,
    {
        tenantId: string;
        patientId: string;
        immunization: IPatientImmunizations;
    },
    { rejectValue: string }
>('createPatientImmunization', async ({ tenantId, patientId, immunization }, { rejectWithValue }) => {
    try {
        const { data: patientImmunization } = await dentalApi.createPatientImmunizations(tenantId, patientId, immunization);

        return patientImmunization;
    } catch (err: any) {
        if (err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(err.toString());
        }
    }
});

export const updatePatientImmunizations = createAsyncThunk<
    IPatientImmunizations,
    {
        tenantId: string;
        patientId: string;
        immunization: IPatientImmunizations;
    },
    { rejectValue: string }
>('updatePatientImmunizations', async ({ tenantId, patientId, immunization }, { rejectWithValue }) => {
    try {
        const { data: patientImmunization } = await dentalApi.updatePatientImmunizations(tenantId, patientId, immunization);

        return patientImmunization;
    } catch (err: any) {
        if (err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(err.toString());
        }
    }
});

export const updateReviewedCheckbox =
    (tenantId: string, patientId: string, reviewed: boolean): AppThunk<void> =>
    (dispatch, getState) => {
        const state = getState();

        const patientImmunizations = cloneDeep(state.patient.immunizations.data);
        const account = state.account.data?.identity;
        if (patientImmunizations && typeof patientImmunizations.isReviewed === 'boolean') {
            patientImmunizations.isReviewed = reviewed;
            patientImmunizations.reviewedDate = new Date().toString();
            patientImmunizations.reviewedBy = `${account?.firstName} ${account?.lastName}`;
            dispatch(updatePatientImmunizations({ tenantId, patientId, immunization: patientImmunizations }));
        } else {
            // Create PatientImmunization if one isn't present
            const newPatientImmunization: IPatientImmunizations = {
                id: patientId,
                isDeleted: false,
                isReviewed: reviewed,
                immunizations: [],
                reviewedBy: `${account?.firstName} ${account?.lastName}`,
                reviewedDate: new Date().toString(),
                type: '',
            };
            dispatch(createPatientImmunization({ tenantId, patientId, immunization: newPatientImmunization }));
        }
    };
