import { IChartProcedure } from 'api/models/chart.model';
import { ApplicableArea } from 'api/models/lookup.model';
import { IProcedure } from 'api/models/procedure.model';
import { ToothArea } from 'api/models/tooth-area';
import { toothSpriteReferences, getQuadrantOfTooth, getArchOfTooth } from 'pages/Charting/components/ToothCanvas/spriteList';
import Pipeline from './pipeline';
import { minTeethPerQuadProcedureRuleCodes } from './procedureCodeBusinessRules/business-rules/minTeethPerQuadProcedure.rule';
import { quadrantFirstAndAdditionalSiteCodes } from './procedureCodeBusinessRules/business-rules/quadrantFirstAndAdditionalSite.rule';

type PipelineArgs = {
    procedures: IProcedure[];
    chartProcedures: IChartProcedure[];
    toothAreas?: (keyof typeof ToothArea)[];
};

export function getArchAreaFromTeeth(procedure: IChartProcedure) {
    if (procedure?.toothIds?.length) {
        return getArchOfTooth(procedure?.toothIds[0]);
    }
    return undefined;
}

export function getQuadAreaFromTeeth(procedure: IChartProcedure) {
    if (procedure?.toothIds?.length) {
        return getQuadrantOfTooth(procedure?.toothIds[0]);
    }
    return undefined;
}

export default class ProcedureApplicableAreasPipeline extends Pipeline<IChartProcedure> {
    protected toothAreas?: (keyof typeof ToothArea)[];
    private procedures: IProcedure[];

    constructor({ procedures, chartProcedures, toothAreas }: PipelineArgs) {
        super(chartProcedures);

        this.toothAreas = toothAreas;
        this.procedures = procedures;
        this.generateProceduresWithAreas();
    }

    private getProcedure(chartProcedure: IChartProcedure) {
        return this.procedures.find((p) => p.id === chartProcedure.procedureId);
    }

    private hasApplicableArea(chartProcedure: IChartProcedure, area: ApplicableArea) {
        const procedure = this.getProcedure(chartProcedure);
        if (procedure && procedure.applicableArea) return (procedure.applicableArea as ApplicableArea) === area;
        return false;
    }

    private getTeethPosition(selectedTeeth: number[]): (number | undefined)[] {
        const positions = selectedTeeth.map((toothId) => toothSpriteReferences.find((ref) => ref.id === toothId)?.position);
        return positions;
    }

    private getIsToothPosterior(selectedTooth: number) {
        const position = this.getTeethPosition([selectedTooth])[0];
        return position ? position <= 5 || position >= 28 || (position >= 12 && position <= 21) : false;
    }

    private generateProceduresWithAreas() {
        if (this.items) {
            const procedures: IChartProcedure[] = [];
            this.items.forEach((chartProcedure: IChartProcedure) => {
                const newProcedure: IChartProcedure = { ...chartProcedure, areas: [] };

                const allToothAreas = this.toothAreas?.length ? this.toothAreas : [];
                // For surfaces
                if (this.hasApplicableArea(chartProcedure, ApplicableArea.Surface)) {
                    if (allToothAreas?.length)
                        allToothAreas.forEach((area) => {
                            if (chartProcedure.toothIds?.length === 1) {
                                const isPosterior = this.getIsToothPosterior(chartProcedure.toothIds[0]);
                                if (area === 'Occlusal') {
                                    newProcedure.areas?.push(isPosterior ? 'Occlusal' : 'Incisal');
                                } else if (area === 'Facial') {
                                    newProcedure.areas?.push(isPosterior ? 'Buccal' : 'Facial');
                                } else if (area === 'FacialVestibular') {
                                    newProcedure.areas?.push(isPosterior ? 'BuccalVestibular' : 'FacialVestibular');
                                } else {
                                    newProcedure.areas?.push(area);
                                }
                            } else {
                                newProcedure.areas?.push(area);
                            }
                        });
                }

                if (this.hasApplicableArea(chartProcedure, ApplicableArea.Crown)) newProcedure.areas?.push('Crown');
                if (this.hasApplicableArea(chartProcedure, ApplicableArea.Root)) newProcedure.areas?.push('Root');

                if (this.hasApplicableArea(chartProcedure, ApplicableArea.Arch)) {
                    newProcedure.areas?.push(...(chartProcedure.areas ?? []));
                }
                //TODO: Needs rule to create respective mandibular procedure if max procedure is being charted but mandibular teeth are selected.

                // if (
                //     this.hasApplicableArea(chartProcedure, ApplicableArea.MandibularArch) ||
                //     this.hasApplicableArea(chartProcedure, ApplicableArea.MaxillaryArch)
                // ) {
                //     const arch = getArchAreaFromTeeth(chartProcedure);
                //     if (arch) newProcedure.areas?.push(arch);
                // }

                if (this.hasApplicableArea(chartProcedure, ApplicableArea.Quadrant)) {
                    //Check to see if a rule is responsible for adding the chart procedure's areas.
                    const isProcedureAreaHandledByRule =
                        [...minTeethPerQuadProcedureRuleCodes, ...quadrantFirstAndAdditionalSiteCodes].findIndex(
                            (code) => code === this.getProcedure(chartProcedure)?.code,
                        ) > -1;
                    if (isProcedureAreaHandledByRule) {
                        const quad = getQuadAreaFromTeeth(chartProcedure);
                        if (quad) newProcedure.areas?.push(quad);
                    }
                }

                procedures.push(newProcedure);
            });
            this.updateItems(procedures);
        }
    }
}
