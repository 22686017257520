import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import ClinicalAlertsState from './clinical-alerts.state';
import {
    getAlertTypes,
    getPatientClinicalAlerts,
    getPatientsClinicalAlertsLookup,
    addUpdateClinicalAlert,
} from './clincal-alerts.actions';
import { LoadingStatus } from 'interfaces/loading-statuses';

import { IClinicalAlerts, ClinicalAlertType, IClinicalAlert } from 'api/models/clinical-alert.model';
import { IValidationError } from 'hooks/useValidation';
import { isEmpty } from 'lodash';

export const clinicalAlertsReducers = {
    cleanupClinicalAlertsForPatients: (state: ClinicalAlertsState, action: PayloadAction<string>): void => {
        const { payload: patientId } = action;
        delete state.clinicalAlertsForPatients[patientId];
    },
    addNewClinicalAlert: (
        state: ClinicalAlertsState,
        { payload }: PayloadAction<{ type: ClinicalAlertType; patientId: string }>,
    ): void => {
        const { type, patientId } = payload;

        state.currentClinicalAlertData = {
            id: '',
            isDeleted: false,
            note: '',
            type,
            patientId,
        };

        state.clinicalAlertPanelOpen = true;
    },
    setEditClinicalAlertData: (
        state: ClinicalAlertsState,
        { payload }: PayloadAction<{ clinicalAlert: IClinicalAlert }>,
    ): void => {
        const { clinicalAlert } = payload;
        const { id, isDeleted, note, references, type, patientId } = clinicalAlert;

        state.currentClinicalAlertData = {
            id,
            patientId,
            isDeleted,
            note,
            references,
            type,
        };

        state.clinicalAlertPanelOpen = true;
    },
    setClinicalAlertValidationErrors: (state: ClinicalAlertsState, action: PayloadAction<IValidationError[]>): void => {
        state.validationErrors = action.payload;
    },
    setClinicalAlertDataProp: (
        state: ClinicalAlertsState,
        action: PayloadAction<{
            path: keyof IClinicalAlert;
            value: string | boolean | Record<string, string> | undefined;
        }>,
    ): void => {
        const { path, value } = action.payload;
        if (state.currentClinicalAlertData) {
            (state.currentClinicalAlertData[path] as string | boolean | Record<string, string> | undefined) = value;
        }
    },
    cleanUpCurrentClinicalAlertData: (state: ClinicalAlertsState): void => {
        state.currentClinicalAlertData = undefined;
        state.savingClinicalAlert = LoadingStatus.Idle;
        state.savingClinicalAlertError = undefined;
    },
    closeCurrentClinicalAlert: (state: ClinicalAlertsState): void => {
        state.clinicalAlertPanelOpen = false;
    },
    upsertPatientClinicalAlert: (
        state: ClinicalAlertsState,
        action: PayloadAction<{ alert: IClinicalAlert; currentPatientId?: string }>,
    ): void => {
        const { alert, currentPatientId } = action.payload;
        const { patientId, id: alertId } = alert;

        if (currentPatientId && patientId === currentPatientId) {
            if (Object.keys(state.patientClinicalAlerts).length) {
                if (state.patientClinicalAlerts[alertId]) {
                    state.patientClinicalAlerts[alertId] = alert;
                } else {
                    state.patientClinicalAlerts = { ...state.patientClinicalAlerts, [alertId]: alert };
                }
            } else {
                state.patientClinicalAlerts = { [alertId]: alert };
            }
        }

        if (state.clinicalAlertsForPatients) {
            if (state.clinicalAlertsForPatients[patientId]) {
                if (state.clinicalAlertsForPatients[patientId][alertId]) {
                    // Patient exists, has current alert
                    state.clinicalAlertsForPatients[patientId][alertId] = alert;
                } else {
                    // Patient exists, does not have current alert
                    state.clinicalAlertsForPatients[patientId] = {
                        ...state.clinicalAlertsForPatients[patientId],
                        [alertId]: alert,
                    };
                }
            } else {
                // Patient does not exist
                state.clinicalAlertsForPatients = { ...state.clinicalAlertsForPatients, [patientId]: { [alertId]: alert } };
            }
        } else {
            // Root object does not exist
            state.clinicalAlertsForPatients = { [patientId]: { [alertId]: alert } };
        }
    },
};

export const clinicalAlertReducers = (builder: ActionReducerMapBuilder<ClinicalAlertsState>) => {
    builder
        // GET - Alert Types
        .addCase(getAlertTypes.pending, (state) => {
            state.loadingAlertTypes = LoadingStatus.Pending;
        })
        .addCase(getAlertTypes.fulfilled, (state, { payload }) => {
            state.loadingAlertTypes = LoadingStatus.Completed;
            state.alertTypes = payload.data;
        })
        .addCase(getAlertTypes.rejected, (state) => {
            state.loadingAlertTypes = LoadingStatus.Failed;
        })

        // GET - Patient Clinical Alerts by type
        .addCase(getPatientsClinicalAlertsLookup.pending, (state, { meta }) => {
            state.loadingClinicalAlerts[meta.arg.patientId] = LoadingStatus.Pending;
        })
        .addCase(getPatientsClinicalAlertsLookup.fulfilled, (state, { payload, meta }) => {
            state.loadingClinicalAlerts[meta.arg.patientId] = LoadingStatus.Completed;
            if (meta.arg.patientId && !isEmpty(payload)) {
                if (!state.clinicalAlertsForPatients[meta.arg.patientId])
                    state.clinicalAlertsForPatients[meta.arg.patientId] = {};
                state.clinicalAlertsForPatients[meta.arg.patientId] = payload;
            }
            state.loadingClinicalAlertsErrors[meta.arg.patientId] = undefined;
        })
        .addCase(getPatientsClinicalAlertsLookup.rejected, (state, { error, meta }) => {
            state.loadingClinicalAlerts[meta.arg.patientId] = LoadingStatus.Failed;
            state.loadingClinicalAlertsErrors[meta.arg.patientId] = error;
        })

        // GET - Patient Clinical Alerts
        .addCase(getPatientClinicalAlerts.pending, (state, { meta }) => {
            state.loadingClinicalAlerts[meta.arg.patientId] = LoadingStatus.Pending;
        })
        .addCase(getPatientClinicalAlerts.fulfilled, (state, { payload, meta }) => {
            state.loadingClinicalAlerts[meta.arg.patientId] = LoadingStatus.Completed;
            state.patientClinicalAlerts = payload ?? {};

            //Also load the clinical alerts for the current patient into our lookup.
            if (meta.arg.patientId && !isEmpty(payload)) {
                if (!state.clinicalAlertsForPatients[meta.arg.patientId])
                    state.clinicalAlertsForPatients[meta.arg.patientId] = {};
                state.clinicalAlertsForPatients[meta.arg.patientId] = payload;
            }

            state.loadingClinicalAlertsErrors[meta.arg.patientId] = undefined;
        })
        .addCase(getPatientClinicalAlerts.rejected, (state, { error, meta }) => {
            state.loadingClinicalAlerts[meta.arg.patientId] = LoadingStatus.Failed;
            state.loadingClinicalAlertsErrors[meta.arg.patientId] = error;
        })

        // PUT - Update patient clinical alert
        .addCase(addUpdateClinicalAlert.pending, (state) => {
            state.savingClinicalAlert = LoadingStatus.Pending;
        })
        .addCase(addUpdateClinicalAlert.fulfilled, (state, { payload }) => {
            state.savingClinicalAlert = LoadingStatus.Pending;
            if (payload.patientId && payload.type && state.clinicalAlertsForPatients[payload.patientId])
                (state.clinicalAlertsForPatients[payload.patientId] as IClinicalAlerts)[payload.id] = payload;

            if (Object.keys(state.patientClinicalAlerts).length) {
                // Existing alert, update
                if (state.patientClinicalAlerts[payload.id]) {
                    state.patientClinicalAlerts[payload.id] = payload;
                } else {
                    // Alerts object has alerts, but new one does not exist yet
                    state.patientClinicalAlerts = { ...state.patientClinicalAlerts, [payload.id]: payload };
                }
            } else {
                // No alerts for patient, create brand new object
                state.patientClinicalAlerts = { [payload.id]: payload };
            }
            state.savingClinicalAlertError = undefined;
            state.clinicalAlertPanelOpen = false;
        })
        .addCase(addUpdateClinicalAlert.rejected, (state, { error }) => {
            state.savingClinicalAlert = LoadingStatus.Failed;
            state.savingClinicalAlertError = error;
        });
};
