import { Label, Stack, Text, useTheme } from '@fluentui/react';
import { IDateFieldProps } from './DateField';
import Field from './Field';

export interface IDateRangeFieldProps {
    startDateFieldProps?: IDateFieldProps;
    endDateFieldProps?: IDateFieldProps;
    label?: string;
    required?: boolean;
}

export default function DateRangeField({ startDateFieldProps, endDateFieldProps, label, required }: IDateRangeFieldProps) {
    const { palette } = useTheme();

    return (
        <Stack tokens={{ childrenGap: 4 }}>
            <Label style={{ borderBottom: `1px solid ${palette.neutralSecondaryAlt}`, padding: 0 }} required={required}>
                {label}
            </Label>
            <Stack tokens={{ childrenGap: 10 }} horizontal>
                <Field.Date
                    onChange={(ev, value) => {
                        if (startDateFieldProps?.onChange) startDateFieldProps.onChange(ev, value);
                    }}
                    {...startDateFieldProps}
                    maxReasonableDate={endDateFieldProps?.value ? new Date(endDateFieldProps.value) : undefined}
                    maxReasonableErrorMessage={endDateFieldProps?.value ? 'Start date must be before end date.' : undefined}
                    isReasonable
                    hasDatePicker
                />
                <Field.Date
                    onChange={(ev, value) => {
                        if (endDateFieldProps?.onChange) endDateFieldProps.onChange(ev, value);
                    }}
                    {...endDateFieldProps}
                    isReasonable
                    hasDatePicker
                    minReasonableDate={startDateFieldProps?.value ? new Date(startDateFieldProps.value) : undefined}
                    minReasonableErrorMessage="End date must be after start date."
                    disabled={!startDateFieldProps?.value}
                />
            </Stack>
        </Stack>
    );
}
