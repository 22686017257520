import { PayloadAction } from '@reduxjs/toolkit';
import IPatientCommunication from 'api/models/patient-communication.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { ExtraReducer, ReducerObject } from 'state/store';
import {
    getPatientCommunication,
    updatePatientCommunication,
    addPatientCommunication,
    getAllPatientCommunications,
    getPatientCommunicationByEncounterId,
} from './communication.actions';
import { CommunicationState } from './communication.state';

export const communmicationReducers: ReducerObject<CommunicationState> = {
    setEditCommunication: (state, action: PayloadAction<IPatientCommunication>) => {
        state.editCommunication = action.payload;
    },
    editCommunicationChangeProp: (state, action: PayloadAction<{ key: keyof IPatientCommunication; value: unknown }>) => {
        if (state.editCommunication) {
            const { key, value } = action.payload;
            (state.editCommunication[key] as unknown) = value;
        }
    },
    cleanupEditCommunication: (state) => {
        state.editCommunication = undefined;
        state.loadingEditCommunication = LoadingStatus.Idle;
    },
    cleanupCommunications: (state) => {
        state.communications = undefined;
        state.loading = LoadingStatus.Idle;
    },
};

export const communicationExtraReducers: ExtraReducer<CommunicationState> = (builder) =>
    builder

        // Returns single communication [tenantId, patientId, communicationId]
        .addCase(getPatientCommunication.pending, (state) => {
            state.loadingEditCommunication = LoadingStatus.Pending;
        })
        .addCase(getPatientCommunication.fulfilled, (state, action) => {
            state.loadingEditCommunication = LoadingStatus.Completed;
            state.editCommunication = action.payload;
        })
        .addCase(getPatientCommunication.rejected, (state) => {
            state.loadingEditCommunication = LoadingStatus.Failed;
        })

        // Returns single communication for a patient with a give encounterId
        // [tenantId, patientId, encounterId, communicationId]
        .addCase(getPatientCommunicationByEncounterId.pending, (state) => {
            state.loading = LoadingStatus.Pending;
        })
        .addCase(getPatientCommunicationByEncounterId.fulfilled, (state, action) => {
            state.loading = LoadingStatus.Completed;
            state.editCommunication = action.payload;
        })
        .addCase(getPatientCommunicationByEncounterId.rejected, (state) => {
            state.loading = LoadingStatus.Failed;
        })

        // Adds a single patient communication [tenantId, patientId, communicationModel]
        .addCase(addPatientCommunication.pending, (state) => {
            state.loadingEditCommunication = LoadingStatus.Pending;
        })
        .addCase(addPatientCommunication.fulfilled, (state, action) => {
            state.loadingEditCommunication = LoadingStatus.Completed;
            state.communications = state.communications ? [...state.communications, action.payload] : [action.payload];
            state.editCommunication = action.payload;
        })
        .addCase(addPatientCommunication.rejected, (state) => {
            state.loadingEditCommunication = LoadingStatus.Failed;
        })

        // Updates single communication
        .addCase(updatePatientCommunication.pending, (state) => {
            state.loadingEditCommunication = LoadingStatus.Pending;
        })
        .addCase(updatePatientCommunication.fulfilled, (state, action) => {
            state.loadingEditCommunication = LoadingStatus.Completed;
            const communication = action.payload;
            state.editCommunication = communication;
            if (state.communications) {
                const indexOfCommunication = state.communications.findIndex((com) => com.id === communication.id);
                if (indexOfCommunication > -1) state.communications[indexOfCommunication] = communication;
            }

            state.editCommunication = undefined;
            state.loadingEditCommunication = LoadingStatus.Idle;
        })
        .addCase(updatePatientCommunication.rejected, (state) => {
            state.loadingEditCommunication = LoadingStatus.Failed;
        })

        // Returns all patient communications
        .addCase(getAllPatientCommunications.pending, (state) => {
            state.loading = LoadingStatus.Pending;
        })
        .addCase(getAllPatientCommunications.fulfilled, (state, action) => {
            state.loading = LoadingStatus.Completed;
            state.communications = action.payload;
        })
        .addCase(getAllPatientCommunications.rejected, (state) => {
            state.loading = LoadingStatus.Failed;
        });
