import { Checkbox, ChoiceGroup, IChoiceGroupOption, IComboBoxOption, Label, Stack, Text, TextField } from '@fluentui/react';
import { Field } from 'components';
import { differenceInDays } from 'date-fns';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectEditPeriods } from 'state/slices/settings/providers/providers.selectors';
import { classicDateOnly } from 'utils/dateOnly';

const RecurrencePattern = () => {
    const [isRecurrencePatternChecked, setIsRecurrencePatternChecked] = useState<string | undefined>('');

    const editSelectedPeriods = useSelector(selectEditPeriods);

    const editProviderDate = editSelectedPeriods.find((e) => e);

    const is2month =
        differenceInDays(
            new Date(editProviderDate?.endDate ? classicDateOnly(editProviderDate.endDate) : ""),
            new Date(editProviderDate?.startDate ? classicDateOnly(editProviderDate.startDate) : ""),
        ) > 60;

    const is13month =
        differenceInDays(
            new Date(editProviderDate?.endDate ? classicDateOnly(editProviderDate.endDate) : ""),
            new Date(editProviderDate?.startDate ? classicDateOnly(editProviderDate.startDate) : ""),
        ) > 395;

    const options: IChoiceGroupOption[] = [
        { key: 'weekly', text: 'Weekly' },
        { key: 'monthly', text: 'Monthly', disabled: !is2month },

        { key: 'yearly', text: 'Yearly', disabled: !is13month },
    ];
    const numberOptions: IComboBoxOption[] = [
        { key: 'first', text: 'First' },
        { key: 'second', text: 'Second' },

        { key: 'Third', text: 'Third' },
        { key: 'fourth', text: 'Fourth' },
        { key: 'last', text: 'Last' },
    ];
    const dayOptions: IComboBoxOption[] = [
        { key: 'day', text: 'Day' },
        { key: 'weekday', text: 'Weekdays' },

        { key: 'sunday', text: 'Sunday' },
        { key: 'monday', text: 'Monday' },
        { key: 'tuesday', text: 'Tuesday' },
        { key: 'wednesday', text: 'Wednesday' },
        { key: 'thursday', text: 'Thursday' },
        { key: 'friday', text: 'Friday' },
        { key: 'saturday', text: 'Saturday' },
    ];

    const monthOptions: IComboBoxOption[] = [
        { key: 'january', text: 'January' },
        { key: 'february', text: 'February' },
        { key: 'march', text: 'March' },
        { key: 'april', text: 'April' },
        { key: 'may', text: 'May' },
        { key: 'june', text: 'June' },
        { key: 'july', text: 'July' },
        { key: 'august', text: 'August' },
        { key: 'september', text: 'September' },
        { key: 'october', text: 'October' },
        { key: 'november', text: 'November' },
        { key: 'december', text: 'December' },
    ];

    return (
        <Stack>
            <Label>Recurrence Pattern</Label>
            <Stack horizontal tokens={{ childrenGap: 30 }}>
                <Stack>
                    <ChoiceGroup
                        options={options}
                        required={true}
                        onChange={(ev, option) => {
                            if (option) {
                                setIsRecurrencePatternChecked(option.key);
                            }
                        }}
                    />
                </Stack>
                {isRecurrencePatternChecked === 'weekly' && (
                    <Stack verticalAlign="center" tokens={{ childrenGap: 5 }}>
                        <Stack horizontal verticalAlign="center">
                            <Text>Recur every</Text>
                            <TextField
                                underlined
                                styles={{
                                    root: {
                                        width: '30px',
                                    },
                                }}
                            />
                            <Text>week(s) on :</Text>
                        </Stack>
                        <Stack horizontal tokens={{ childrenGap: 5 }}>
                            <Checkbox label="Monday" />
                            <Checkbox label="Tuesday" />
                            <Checkbox label="Wednesday" />
                            <Checkbox label="Thursday" />
                            <Checkbox label="Friday" />
                            <Checkbox label="Saturday" />
                            <Checkbox label="Sunday" />
                        </Stack>
                    </Stack>
                )}
                {isRecurrencePatternChecked === 'monthly' && is2month ? (
                    <Stack tokens={{ childrenGap: 10 }}>
                        <Stack horizontal verticalAlign="center">
                            <Text>Day</Text>
                            <TextField
                                underlined
                                styles={{
                                    root: {
                                        width: '30px',
                                    },
                                }}
                            />
                            <Text>of every :</Text>
                            <TextField
                                underlined
                                styles={{
                                    root: {
                                        width: '30px',
                                    },
                                }}
                            />
                            <Text>month(s)</Text>
                        </Stack>
                        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }}>
                            <Text>The</Text>
                            <Field.SearchCombo allowFreeform options={numberOptions} threshold={0.3} useComboBoxAsMenuWidth />
                            <Field.SearchCombo allowFreeform options={dayOptions} threshold={0.3} useComboBoxAsMenuWidth />
                            <Text>of every :</Text>
                            <TextField
                                underlined
                                styles={{
                                    root: {
                                        width: '30px',
                                    },
                                }}
                            />
                            <Text>month(s)</Text>
                        </Stack>
                    </Stack>
                ) : null}
                {isRecurrencePatternChecked === 'yearly' && (
                    <Stack verticalAlign="center" tokens={{ childrenGap: 10 }}>
                        <Stack horizontal verticalAlign="center">
                            <Text>Recur every</Text>
                            <TextField
                                underlined
                                styles={{
                                    root: {
                                        width: '30px',
                                    },
                                }}
                            />
                            <Text>year(s)</Text>
                        </Stack>

                        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }}>
                            <Text>On the</Text>

                            <Field.SearchCombo allowFreeform options={numberOptions} threshold={0.3} useComboBoxAsMenuWidth />

                            <Field.SearchCombo allowFreeform options={dayOptions} threshold={0.3} useComboBoxAsMenuWidth />
                            <Stack>
                                <Text>of</Text>
                            </Stack>
                            <Stack>
                                <Field.SearchCombo allowFreeform options={monthOptions} threshold={0.3} useComboBoxAsMenuWidth />
                            </Stack>
                        </Stack>
                    </Stack>
                )}
            </Stack>
        </Stack>
    );
};

export default RecurrencePattern;
