import { SelectionMode, Stack } from '@fluentui/react';
import { IPaymentCommonTransactionView } from 'api/models/payment-history-model';
import { SortableDetailsList } from 'components';
import { usdCurrencyFormatter } from 'utils';
import { ChartProcedureLabel } from '../components/RenderProcedureSummaryTooltip';
import { classicDateOnly } from 'utils/dateOnly';
import { selectNegativeReversalsLookup } from 'state/slices/ledger/payment-history/payment-history.selectors';
import { useSelector } from 'react-redux';

type Props = {
    payments: IPaymentCommonTransactionView[];
};

export default function PaymentHistoryPaymentList({ payments }: Props) {
    const negativePaymentReasons = useSelector(selectNegativeReversalsLookup);

    return (
        <Stack styles={{ root: { paddingLeft: 10 } }}>
            <SortableDetailsList<IPaymentCommonTransactionView>
                selectionMode={SelectionMode.none}
                showGrid
                compact
                columns={[
                    {
                        name: 'Trans. Date',
                        key: 'transactionDate',
                        minWidth: 100,
                        maxWidth: 120,
                        fieldName: 'createdOn',
                        onRender: (item) => (item?.createdOn ? <span>{classicDateOnly(item.createdOn, 'Pp')}</span> : null),
                    },
                    {
                        name: 'Enc. Date',
                        key: 'encounterDate',
                        minWidth: 100,
                        maxWidth: 120,
                        fieldName: 'dateOfService',
                        onRender: (item) => (item?.dateOfService ? <span>{classicDateOnly(item.dateOfService)}</span> : null),
                    },
                    {
                        name: 'Batch Name',
                        key: 'batchName',
                        minWidth: 100,
                        maxWidth: 120,
                        fieldName: 'batchName',
                        isResizable: true,
                    },
                    {
                        name: 'Reversal Reason',
                        key: 'reversalReason',
                        minWidth: 100,
                        maxWidth: 120,
                        onRender: (item) => {
                            const displayName = item?.negativePaymentReasonId
                                ? negativePaymentReasons[item.negativePaymentReasonId]?.displayName ?? ''
                                : '';
                            return displayName ? <span title={displayName}>{displayName}</span> : <span>N/A</span>;
                        },
                    },
                    {
                        name: 'Procedure',
                        key: 'procedure',
                        minWidth: 100,
                        onRender: (item) => {
                            if (item) {
                                const label = item?.procedureCode && item?.procedureDescription ? `${item.procedureCode} - ${item.procedureDescription}` : undefined;
                                //Show a pre label in parenthesis that will display when the procedure transaction is now a UAC.
                                return <Stack horizontal tokens={{ childrenGap: 7 }}>
                                    {!item.encounterId && !item.treatmentPlanId ? <strong>(UAC Created)</strong> : ""}<ChartProcedureLabel label={label} chartProcedureStatus={item.procedureStatus} />
                                </Stack>;
                            }
                        },
                    },
                    {
                        name: 'Amount',
                        key: 'amount',
                        minWidth: 100,
                        onRender: (item) => {
                            if (item) {
                                return <span>{usdCurrencyFormatter.format(item?.amount)}</span>;
                            }
                        },
                    },
                ]}
                items={payments}
            />
        </Stack>
    );
}
