import IWorkListAppointmentView from 'api/models/worklist-appointment-view';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import UserDisplayName from 'components/UserDisplayName';

const amendingProviderColumn: ISortableColumn<IWorkListAppointmentView> = {
    key: 'amendingProviderColumn',
    name: 'Amendment Routed To',
    minWidth: 120,
    maxWidth: 150,
    isResizable: true,
    onRender: (item) => {
        return <UserDisplayName userId={item?.amendingProviderId} />;
    },
};

export default amendingProviderColumn;
