import { ComboBox, IChoiceGroupOption, IDropdownOption, Stack } from '@fluentui/react';
import IPatient, { IPatientUDS } from 'api/models/patient.model';
import { SubSection, TText } from 'components';
import Field from 'components/Field/Field';
import { useSelector } from 'hooks';
import { getValidationError } from 'hooks/useValidation';
import { AgriculturalHomelessTypeFields } from 'pages/Scheduling/components/Checkin/BasicDetailsSection';
import TaskFieldLabel from 'pages/Scheduling/components/Checkin/TaskFieldLabel';
import { useDispatch } from 'react-redux';
import { selectEditPatient, selectEditPatientValidationErrors } from 'state/slices/edit-patient/edit-patient.selectors';
import { editPatientPropChange, editPatientUDSPropChange } from 'state/slices/edit-patient/edit-patient.slice';
import { ethnicityOptionsList } from 'state/slices/tenant/ethnicities.slice';
import { genderIdentitiesOptionsList } from 'state/slices/tenant/gender-identities.slice';
import { legalSexesOptionsList } from 'state/slices/tenant/legal-sexes.slice';
import { racesOptionsList } from 'state/slices/tenant/races.slice';
import { sexualOrientationOptionsList } from 'state/slices/tenant/sexualOrientation.slice';
import { TaskType } from 'state/task-management/taskManagement.actions';
import { selectPatientBasicInfoByTaskTypeLookup } from 'state/task-management/taskManagement.slice';
import { classicDateOnly } from 'utils/dateOnly';

const pronouns: IDropdownOption[] = [
    {
        key: 'He/him',
        text: 'He/him',
    },

    {
        key: 'She/her',
        text: 'She/her',
    },

    {
        key: 'They/them',
        text: 'They/them',
    },

    {
        key: 'Other',
        text: 'Other',
    },
];

const yesNoDeclined: IChoiceGroupOption[] = [
    {
        key: 'Yes',
        text: 'Yes',
    },
    {
        key: 'No',
        text: 'No',
    },
    {
        key: 'Patient Declined',
        text: 'Patient Declined',
    },
];
const typeOfMigrantWorker: IDropdownOption[] = [
    {
        key: '',
        text: 'None',
    },
    {
        key: 'Migrant',
        text: 'Migrant',
    },
    {
        key: 'Seasonal',
        text: 'Seasonal',
    },
];
const homelessStatus: IDropdownOption[] = [
    {
        key: '',
        text: 'None',
    },
    {
        key: 'DoublingUp',
        text: 'Doubling Up',
    },
    {
        key: 'HomelessShelter',
        text: 'Homeless Shelter',
    },
    {
        key: 'PermanentSupportiveHousing',
        text: 'Permanent Supportive Housing',
    },
    {
        key: 'Street',
        text: 'Street',
    },
    {
        key: 'Transitional',
        text: 'Transitional',
    },
    {
        key: 'Other',
        text: 'Other',
    },
    {
        key: 'Unknown',
        text: 'Unknown',
    },
];

function PatientDetails(): JSX.Element | null {
    const patient = useSelector(selectEditPatient);
    const dispatch = useDispatch();
    const ethnicityOptions = useSelector(ethnicityOptionsList);
    const raceOptions = useSelector(racesOptionsList);
    const legalSexOptions = useSelector(legalSexesOptionsList);
    const sexualOrientationOptions = useSelector(sexualOrientationOptionsList);
    const genderIdentityOptions = useSelector(genderIdentitiesOptionsList);
    const lookupBasicInfoByType = useSelector(selectPatientBasicInfoByTaskTypeLookup);
    const validationErrors = useSelector(selectEditPatientValidationErrors);

    const _updatePatientUDSProps = (key: keyof IPatientUDS, value: string | number | boolean | string[] | undefined) => {
        dispatch(editPatientUDSPropChange({ key, value }));
    };
    const _updatePatientRootProps = (key: keyof IPatient, value: string | number | boolean | string[]) => {
        dispatch(editPatientPropChange({ key, value }));
    };

    if (!patient) return null;

    return (
        <SubSection title="Basic Details">
            <Stack tokens={{ childrenGap: 10 }}>
                <Stack tokens={{ childrenGap: 10 }} horizontal grow>
                    <Stack.Item grow>
                        <Field.SearchCombo
                            label="Race(s)"
                            multiSelect
                            allowFreeform
                            selectedKey={patient?.raceIds ?? undefined}
                            options={raceOptions}
                            placeholder="Select Race(s)"
                            threshold={0.3}
                            onRenderOption={(props) => <TText>{props?.text}</TText>}
                            onChange={(e, option) => {
                                if (option) {
                                    let newRaceIds: string[] = [];
                                    if (patient.raceIds && patient.raceIds.length) {
                                        const isRaceExist = patient.raceIds.includes(option.key as string);
                                        if (isRaceExist) {
                                            newRaceIds = patient.raceIds.filter((id) => id !== option.key);
                                        } else {
                                            newRaceIds = [...patient.raceIds, option.key as string];
                                        }
                                    } else {
                                        newRaceIds = [option.key as string];
                                    }
                                    _updatePatientRootProps('raceIds', newRaceIds);
                                }
                            }}
                            useComboBoxAsMenuWidth
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <ComboBox
                            style={{ width: 250 }}
                            label="Ethnicity"
                            placeholder="Select"
                            allowFreeform
                            selectedKey={patient?.ethnicityId ?? undefined}
                            options={ethnicityOptions}
                            onChange={(e, option) => {
                                if (option) _updatePatientRootProps('ethnicityId', option.key);
                            }}
                            useComboBoxAsMenuWidth
                        />
                    </Stack.Item>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 10 }} grow wrap>
                    <Stack.Item>
                        <ComboBox
                            options={legalSexOptions}
                            label="Sex Assigned at Birth"
                            allowFreeform
                            placeholder="Select"
                            selectedKey={patient.uds?.sexAtBirthId ?? ''}
                            onChange={(e, option) => {
                                if (option) _updatePatientUDSProps('sexAtBirthId', option.key);
                            }}
                            useComboBoxAsMenuWidth
                        />
                    </Stack.Item>
                    <Stack.Item grow>
                        <ComboBox
                            placeholder="Select"
                            label="Gender Identity"
                            selectedKey={patient.genderIdentityId}
                            allowFreeform
                            useComboBoxAsMenuWidth
                            options={genderIdentityOptions}
                            onChange={(e, option) => {
                                if (option) {
                                    _updatePatientRootProps('genderIdentityId', option.key);
                                }
                            }}
                            onRenderLabel={(data) => (
                                <TaskFieldLabel
                                    label={data?.props?.label}
                                    lookup={lookupBasicInfoByType}
                                    taskType={TaskType.GenderIdentityVerification}
                                />
                            )}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <ComboBox
                            placeholder="Select"
                            label="Pronouns"
                            selectedKey={patient.pronoun}
                            allowFreeform
                            useComboBoxAsMenuWidth
                            options={pronouns}
                            onChange={(e, option) => {
                                if (option) {
                                    _updatePatientRootProps('pronoun', option.key);
                                }
                            }}
                            onRenderLabel={(data) => (
                                <TaskFieldLabel
                                    label={data?.props?.label}
                                    lookup={lookupBasicInfoByType}
                                    taskType={TaskType.PronounVerification}
                                />
                            )}
                        />
                    </Stack.Item>
                    <Stack.Item grow>
                        <ComboBox
                            placeholder="Select"
                            label="Sexual Orientation"
                            selectedKey={patient.sexualOrientationId}
                            options={sexualOrientationOptions}
                            useComboBoxAsMenuWidth
                            allowFreeform
                            onChange={(e, option) => {
                                if (option) _updatePatientRootProps('sexualOrientationId', option.key);
                            }}
                            onRenderLabel={(props) => (
                                <TaskFieldLabel
                                    label={props?.props?.label}
                                    lookup={lookupBasicInfoByType}
                                    taskType={TaskType.SexualOrientationVarification}
                                />
                            )}
                        />
                    </Stack.Item>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    {patient?.dateOfDeath?.length ? (
                        <Stack.Item
                            styles={{
                                root: {
                                    maxWidth: 150,
                                },
                            }}
                        >
                            <Field.Date
                                label="Deceased Date"
                                value={patient?.dateOfDeath ? classicDateOnly(patient.dateOfDeath) : ''}
                                onChange={(e, value) => {
                                    _updatePatientRootProps('dateOfDeath', value ? value : '');
                                }}
                                hasDatePicker
                                autoComplete="off"
                                allowFutureDates={true}
                                isReasonable
                                minReasonableDate={new Date('01/1/1900')}
                                disabled={true}
                            />
                        </Stack.Item>
                    ) : null}
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Stack tokens={{ childrenGap: 10 }}>
                        <Field.ChoiceGroup
                            inline
                            label="School-based health center patient"
                            options={yesNoDeclined}
                            selectedKey={patient.uds?.schoolbasedPatient ?? ''}
                            onChange={(e, option) => {
                                if (option) {
                                    _updatePatientUDSProps('schoolbasedPatient', option.key);
                                    _updatePatientUDSProps('schoolbasedLastUpdated', new Date().toISOString());
                                }
                            }}
                            preComponentContent={
                                <TaskFieldLabel
                                    style={{ position: 'absolute', top: 3, left: 210 }}
                                    lookup={lookupBasicInfoByType}
                                    taskType={TaskType.SchoolBasedHealthVerification}
                                />
                            }
                        />
                    </Stack>
                    <Stack horizontal>
                        <Field.ChoiceGroup
                            inline
                            label="Veteran Status"
                            options={yesNoDeclined}
                            selectedKey={patient.uds?.veteranStatus ?? ''}
                            onChange={(e, option) => {
                                if (option) {
                                    _updatePatientUDSProps('veteranStatus', option.key);
                                    _updatePatientUDSProps('veteranStatusLastUpdated', new Date().toISOString());
                                }
                            }}
                            preComponentContent={
                                <TaskFieldLabel
                                    style={{ position: 'absolute', top: 3, left: 88 }}
                                    lookup={lookupBasicInfoByType}
                                    taskType={TaskType.VeteranStatusVerification}
                                />
                            }
                        />
                    </Stack>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Stack horizontal>
                        <Field.ChoiceGroup
                            inline
                            label="Agricultural Worker"
                            options={yesNoDeclined}
                            selectedKey={patient.uds?.agriculturalWorker ?? ''}
                            onChange={(e, option) => {
                                if (option) {
                                    _updatePatientUDSProps('agriculturalWorker', option.key);
                                    _updatePatientUDSProps('agriculturalWorkerLastUpdated', new Date().toISOString());
                                    if (option.key !== 'Yes') _updatePatientUDSProps('agriculturalWorkerDetail', undefined);
                                }
                            }}
                            preComponentContent={
                                <TaskFieldLabel
                                    style={{ position: 'absolute', top: 3, left: 118 }}
                                    lookup={lookupBasicInfoByType}
                                    taskType={TaskType.AgriculturalWorkerVerification}
                                />
                            }
                        />
                    </Stack>
                    <Stack.Item grow>
                        <Field.Dropdown
                            style={{ minWidth: 200 }}
                            placeholder="Select"
                            label="Type"
                            selectedKey={patient.uds?.agriculturalWorkerDetail ?? ''}
                            options={typeOfMigrantWorker}
                            onChange={(e, option) => {
                                if (option) {
                                    _updatePatientUDSProps('agriculturalWorkerDetail', option.key);
                                    _updatePatientUDSProps('agriculturalWorkerLastUpdated', new Date().toISOString());
                                }
                            }}
                            disabled={patient?.uds?.agriculturalWorker !== 'Yes'}
                            errorMessage={
                                getValidationError(validationErrors, AgriculturalHomelessTypeFields.AgriculturalType)
                                    ? 'Agricultural  Type is required.'
                                    : undefined
                            }
                        />
                    </Stack.Item>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Stack horizontal>
                        <Field.ChoiceGroup
                            inline
                            label="Homeless Status"
                            options={yesNoDeclined}
                            selectedKey={patient.uds?.homelessStatus ?? ''}
                            onChange={(e, option) => {
                                if (option) {
                                    _updatePatientUDSProps('homelessStatus', option.key);
                                    _updatePatientUDSProps('homelessStatusLastUpdated', new Date().toISOString());
                                    if (option.key !== 'Yes') _updatePatientUDSProps('homelessStatusDetail', undefined);
                                }
                            }}
                            preComponentContent={
                                <TaskFieldLabel
                                    style={{ position: 'absolute', top: 3, left: 100 }}
                                    lookup={lookupBasicInfoByType}
                                    taskType={TaskType.HomelessStatusVerification}
                                />
                            }
                        />
                    </Stack>
                    <Stack.Item grow>
                        <Field.Dropdown
                            style={{ minWidth: 200 }}
                            placeholder="Select"
                            label="Type"
                            selectedKey={patient.uds?.homelessStatusDetail ?? ''}
                            options={homelessStatus}
                            onChange={(e, option) => {
                                if (option) {
                                    _updatePatientUDSProps('homelessStatusDetail', option.key);
                                    _updatePatientUDSProps('homelessStatusLastUpdated', new Date().toISOString());
                                }
                            }}
                            disabled={patient.uds?.homelessStatus !== 'Yes'}
                            errorMessage={
                                getValidationError(validationErrors, AgriculturalHomelessTypeFields.HomelessType)
                                    ? 'Homeless Type is required.'
                                    : undefined
                            }
                        />
                    </Stack.Item>
                </Stack>
            </Stack>
        </SubSection>
    );
}

export default PatientDetails;
