import { IExtendedRouteProps } from '.';
import { Route } from 'react-router-dom';
import { SidebarLayout, NoSidebarLayout, DefaultLayout } from './layouts';

export const RouteWrapper = ({
    component: Component,
    layout = 'noSideBar',
    ...rest
}: IExtendedRouteProps): JSX.Element | null => {
    if (!Component) {
        return null;
    }

    const layouts = {
        noSideBar: NoSidebarLayout,
        sideBar: SidebarLayout,
        default: DefaultLayout,
    };

    const Layout = layouts[layout];
    return (
        <Route
            {...rest}
            render={(props) => (
                <Layout {...props}>
                    <Component {...props} />
                </Layout>
            )}
        />
    );
};
