import { SelectionMode, Stack, Text } from '@fluentui/react';
import SortableDetailsList, { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import { useCallback, useEffect } from 'react';
import { BillingProcedureWithTransactionId } from 'state/slices/ledger/treatment-plan-credit-and-uac/treatment-plan-credit-and-uac.state';
import { getTeethDisplayName, getShorthandToothAreas, usdCurrencyFormatter } from 'utils';
import { ChartProcedureLabel } from '../components/RenderProcedureSummaryTooltip';
import TreatmentPlanCreditTransactionField from './TreatmentPlanCreditTransactionField';
import { selectIsPatientNonSlide } from 'state/slices/ledger/ledger.selectors';
import { useSelector } from 'hooks';
import { selectUserIdentitiesData } from 'state/slices/users-identities/user-identities.selectors';
import useUserIdentities from 'hooks/store/useUserIdentities';

export default function AddTreatmentPlanPrepaymentList({
    billingProcedures,
    remainingAmount,
}: {
    billingProcedures: BillingProcedureWithTransactionId[];
    remainingAmount: number | undefined;
}) {
    const nonSlide = useSelector(selectIsPatientNonSlide);
    const userIdentitiesData = useSelector(selectUserIdentitiesData);

    const { getMissingUserIdentities } = useUserIdentities();

    const getTreatingProviderIds = useCallback(() => {
        return {
            treatingProviderIds: billingProcedures.map((p) => p.treatingProviderId).filter((p) => p !== undefined) as string[],
        };
    }, [billingProcedures]);

    useEffect(() => {
        const { treatingProviderIds } = getTreatingProviderIds();
        getMissingUserIdentities([...treatingProviderIds]);
    }, [getTreatingProviderIds()]);

    const getColumns = useCallback(() => {
        const columns: ISortableColumn<BillingProcedureWithTransactionId>[] = [
            {
                key: 'payment',
                minWidth: 150,
                maxWidth: 150,
                name: 'Payment',
                onRender: (item) => <TreatmentPlanCreditTransactionField remainingAmount={remainingAmount} item={item} />,
            },
            {
                key: 'tooth',
                minWidth: 100,
                maxWidth: 120,
                name: 'Tooth',
                fieldName: 'toothIds',
                onRender: (item) => {
                    const displayNames = getTeethDisplayName(item?.toothIds);
                    return <span title={displayNames}>{displayNames}</span>;
                },
            },
            {
                key: 'code',
                minWidth: 75,
                maxWidth: 100,
                name: 'Code',
                fieldName: 'procedureCode',
                onRender: (item) => {
                    return <ChartProcedureLabel label={item?.procedureCode} chartProcedureStatus={item?.status} />;
                },
            },
            {
                key: 'description',
                minWidth: 150,
                maxWidth: 195,
                name: 'Desc.',
                fieldName: 'procedureDescription',
                onRender: (item) => <span title={item?.procedureDescription ?? ''}>{item?.procedureDescription ?? ''}</span>,
            },
            {
                key: 'provider',
                minWidth: 120,
                maxWidth: 150,
                name: 'Provider',
                fieldName: 'treatingProviderId',
                onRender: (item) => {
                    const treatingProvider = userIdentitiesData[item?.treatingProviderId ?? '']?.displayName;
                    return <span title={treatingProvider}>{treatingProvider}</span>;
                },
            },
            {
                key: 'stage',
                minWidth: 75,
                maxWidth: 100,
                name: 'Stage',
                fieldName: 'stage',
            },
            {
                key: 'area',
                minWidth: 100,
                name: 'Area',
                fieldName: 'areas',
                onRender: (item) => <span>{getShorthandToothAreas(item?.areas)}</span>,
            },
            {
                name: 'Prepayments',
                key: 'prepayments',
                onRender: (item) => <span>{usdCurrencyFormatter.format(item?.patientReservePayments ?? 0)}</span>,
                minWidth: 125,
                maxWidth: 135,
            },
            {
                name: 'Remaining Pt. Est.',
                key: 'patientEst',
                onRender: (item) => (
                    <span>
                        <b>{usdCurrencyFormatter.format(item?.commonPatientFee ?? 0)}</b>
                    </span>
                ),
                minWidth: 125,
                maxWidth: 135,
            },
        ];

        if (nonSlide) {
            columns.splice(6, 0, {
                name: 'Ins. Est.',
                key: 'insuranceEstimate',
                onRender: (item) => <span>{usdCurrencyFormatter.format(item?.insuranceEstimate ?? 0)}</span>,
                minWidth: 100,
                maxWidth: 100,
            });
        } else {
            columns.splice(6, 0, {
                key: 'additionalOrLabFee',
                minWidth: 100,
                name: 'Lab/Material Fee.',
                fieldName: 'additionalOrLabFee',
                onRender: (item) => {
                    return (
                        <Stack grow>
                            <Text variant="smallPlus">{usdCurrencyFormatter.format(item?.additionalOrLabFee ?? 0)}</Text>
                        </Stack>
                    );
                },
            });
        }

        return columns;
    }, [nonSlide, remainingAmount, userIdentitiesData]);

    return (
        <SortableDetailsList<BillingProcedureWithTransactionId>
            selectionMode={SelectionMode.none}
            items={billingProcedures}
            stickyHeader
            showGrid
            columns={getColumns()}
        />
    );
}
