import { IPatientEncounter } from 'api/models/encounter.model';
import { INegativeReversal } from 'api/models/negative-reversal-reason.model';
import { IPaymentCommonTransactionView } from 'api/models/payment-history-model';
import { IPaymentSource } from 'api/models/payment-source.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { map, uniq } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'state/store';
import { classicDateOnly } from 'utils/dateOnly';
import { LedgerPaymentHistoryState } from './payment-history.state';

export const selectPaymentHistory = (state: RootState): LedgerPaymentHistoryState => state.ledger.paymentHistory;

export const selectPaymentHistoryLoadingStatus = createSelector(selectPaymentHistory, ({ loading }) => loading);
export const selectPaymentHistoryLoading = createSelector(
    selectPaymentHistoryLoadingStatus,
    (loading) => loading === LoadingStatus.Pending,
);
export const selectPaymentHistoryError = createSelector(selectPaymentHistory, ({ paymentHistoryError }) => paymentHistoryError);
export const selectNegativeReversalSavingStatus = createSelector(selectPaymentHistory, ({ reversalSaving }) => reversalSaving);
export const selectNegativeReversalSaving = createSelector(
    selectNegativeReversalSavingStatus,
    (reversalSavingStatus) => reversalSavingStatus === LoadingStatus.Pending,
);
export const selectPaymentHistoryFilters = createSelector(selectPaymentHistory, ({ filters }) => filters);

export const selectPaymentHistoryData = createSelector(selectPaymentHistory, ({ data }) => data);
export const selectSelectedPaymentHistoryNegativeReversalReasonId = createSelector(
    selectPaymentHistory,
    ({ negativeReversalReasonId }) => negativeReversalReasonId,
);
export const selectSelectedPaymentHistoryNegativeReversalNote = createSelector(
    selectPaymentHistory,
    ({ negativeReversalNote }) => negativeReversalNote,
);
export const selectNegativeReversalsLookup = createSelector(
    selectPaymentHistory,
    ({ negativeReversals }) => negativeReversals ?? {},
);
export const selectNegativeReversalsAsList = createSelector(
    selectNegativeReversalsLookup,
    (negativeReversals) => map(negativeReversals).filter((o) => !o?.isDeleted && !o?.isSystemUseOnly) as INegativeReversal[],
);
export const selectPaymentHistoryShowReversedPayments = createSelector(
    selectPaymentHistory,
    ({ showReversedPayments }) => showReversedPayments,
);
export const selectPaymentHistoryConfirmModalOpen = createSelector(
    selectPaymentHistory,
    ({ confirmModalOpen }) => confirmModalOpen,
);

export type PaymentHistoryTableView = PaymentHistoryTableRow[];
export type PaymentHistoryTableRow = IPaymentSource & {
    payments: IPaymentCommonTransactionView[];
    paymentsWithoutChartProcedureId?: IPaymentCommonTransactionView[];
    batchName?: string;
};

export function getReversedPaymentSourcesFromPaymentSourceData(paymentSources: IPaymentSource[] | undefined) {
    const reversedSources: Record<string, boolean> = {};
    paymentSources?.forEach((source) => {
        if (source.references?.reversedPaymentSourceId) {
            reversedSources[source.references.reversedPaymentSourceId] = true;
        }
    });

    return reversedSources;
}

export const selectPaymentHistoryReversedPaymentSources = createSelector(selectPaymentHistoryData, (data) => {
    if (data) {
        return getReversedPaymentSourcesFromPaymentSourceData(data.paymentSources);
    }

    return {};
});

export const selectPaymentHistoryTableView = createSelector(
    selectPaymentHistoryData,
    selectPaymentHistoryShowReversedPayments,
    selectPaymentHistoryReversedPaymentSources,
    (data, showReversedPayments, reversedSourcesSet) => {
        if (data) {
            const { paymentSources, payments } = data;

            const tableData: PaymentHistoryTableView = paymentSources
                ? paymentSources
                      .filter((ps) =>
                          showReversedPayments ? true : !reversedSourcesSet[ps.id] && !ps.references?.reversedPaymentSourceId,
                      )
                      .map((ps) => {
                          const paymentSourcePayments = payments ? payments.filter((p) => p.paymentSourceId === ps.id) : [];
                          const paymentsAmount = paymentSourcePayments.map((p) => p.amount).reduce((a, b) => a + b, 0);

                          return {
                              ...ps,
                              dateOfEntry: classicDateOnly(ps.dateOfEntry),
                              batchName: paymentSourcePayments?.[0]?.batchName,
                              payments: paymentSourcePayments,
                              amount: paymentsAmount,
                          };
                      })
                : [];

            return tableData;
        }
        return [];
    },
);

export const paymentHistoryPaymentSourceUserIds = createSelector(selectPaymentHistoryTableView, (views) => {
    const users = new Set<string>();

    views.forEach((view) => {
        if (view?.createdBy) users.add(view.createdBy);
    });

    return Array.from(users);
});

export const paymentHistoryTableFilteredViews = createSelector(
    selectPaymentHistoryTableView,
    selectPaymentHistoryFilters,
    (views, { dateOfEntry, method, methodIdentifier, transactionDate, dateOfService }) => {
        const filterDateOfEntry = (view: PaymentHistoryTableRow) =>
            dateOfEntry ? classicDateOnly(view.dateOfEntry).indexOf(classicDateOnly(dateOfEntry)) > -1 : true;
        const filterTransactionDate = (view: PaymentHistoryTableRow) =>
            transactionDate
                ? (view.createdOn ? classicDateOnly(view.createdOn) : '').indexOf(classicDateOnly(transactionDate)) > -1
                : true;
        const filterMethod = (view: PaymentHistoryTableRow) =>
            method ? (view.method ? view.method?.indexOf(method) : -1) > -1 : true;

        const filterMethodIdentifer = (view: PaymentHistoryTableRow) =>
            methodIdentifier ? (view.methodIdentifier ? view.methodIdentifier.indexOf(methodIdentifier) : -1) > -1 : true;

        const filterPaymentsByDateOfService = (payments: IPaymentCommonTransactionView[]) => {
            return payments.filter((p) => {
                if (!p.dateOfService) return false;
                return classicDateOnly(p.dateOfService) === classicDateOnly(dateOfService);
            });
        };

        const filterDateOfService = (view: PaymentHistoryTableRow) =>
            dateOfService ? !!filterPaymentsByDateOfService(view.payments).length : true;

        //Ensure we we only show payments that are for this date of service
        const viewsWithFilteredPayments = dateOfService
            ? views.map((v) => ({ ...v, payments: filterPaymentsByDateOfService(v.payments) }))
            : views;

        return viewsWithFilteredPayments.filter(
            (v) =>
                filterDateOfService(v) &&
                filterDateOfEntry(v) &&
                filterTransactionDate(v) &&
                filterMethod(v) &&
                filterMethodIdentifer(v),
        );
    },
);

export const selectPaymentHistorySelectedViews = createSelector(selectPaymentHistory, (state) => state.selectedPaymentSources);
export const selectPaymentHistorySelectedViewsPaymentsPatientIds = createSelector(selectPaymentHistorySelectedViews, (views) =>
    uniq(
        views
            .map((v) => [
                ...v.payments.map((p) => p.patientId ?? ''),
                ...(v.paymentsWithoutChartProcedureId ? v.paymentsWithoutChartProcedureId.map((p) => p.patientId ?? '') : []),
            ])
            .flat(),
    ),
);
export const selectPaymentHistorySelectedPaymentSource = createSelector(
    selectPaymentHistorySelectedViews,
    selectPaymentHistoryData,
    (selected, data) => {
        //Since we can only select one for now:
        return data?.paymentSources?.find((p) => p.id === selected?.[0]?.id);
    },
);
