import { AnyAction, Dictionary, PayloadAction } from '@reduxjs/toolkit';
import { ChartProcedureDiagnosis, ChartProcedurePreAuth, ChartProcedureStatus, IChartProcedure } from 'api/models/chart.model';
import { IDiagnosis } from 'api/models/diagnosis.model';
import { ICondition } from 'api/models/lookup.model';
import { IProcedure } from 'api/models/procedure.model';
import IPatientAppointment from 'api/models/Scheduling/patientAppointment.model';
import { ToothArea } from 'api/models/tooth-area';
import { map, some } from 'lodash';
import { ThunkDispatch } from 'redux-thunk';
import { AppThunk, RootState } from 'state/store';
import { chartingActions, ProcedureActionType } from '../chart/chart.slice';
import { IChartAction } from '../chartActionsList.pipeline';
import ChartingProceduresPipeline, { createsManyProcedures } from '../chartingProcedures.pipeline';
import ProcedureApplicableAreasPipeline from '../procedureApplicableAreasPipeline';
import ProcedureBusinessRulesPipeline, {
    IChartProcedureWithOriginalProcedure,
} from '../procedureCodeBusinessRules/procedureBusinessRules.pipeline';
import ProcedureDiagnosisPipeline, {
    IProcedureWithDiagnoses,
} from '../procedureDiagnosisPipeline/procedureDiagnosisPipeline.pipeline';
import { createChartProcedures, updateChartProcedures } from '../procedures/procedures.actions';
import { IPanelProcedure } from './procedure-panel.state';
import { IPatientEncounter } from 'api/models/encounter.model';

export type EditProcedurePanelPayload = {
    procedure: IProcedure;
    chartProcedure: IChartProcedure;
    actionType?: ProcedureActionType;
    provider?: string;
    date?: string;
    areas?: (keyof typeof ToothArea)[];
    selectedStatus: ChartProcedureStatus;
    selectedPreAuth: ChartProcedurePreAuth;
    selectedAuthorization: string;
    diagnosisCodes?: ChartProcedureDiagnosis[];
    notes?: string;
};

export type ProceduresPanelPayload = {
    conditions?: ICondition[];
    procedures?: IProcedure[];
    proceduresData: Dictionary<IProcedure>;
    diagnosisData: Dictionary<IDiagnosis>;
    encounterId?: string;
    selectedActionType: ProcedureActionType;
    selectedPreAuth?: ChartProcedurePreAuth;
    selectedAuthorization?: string;
    providerId?: string;
    panelTeethData?: Dictionary<IPanelProcedure>;
};
export type SetProceduresPanelPayload = PayloadAction<ProceduresPanelPayload>;
export type ProceduresToEdit = IChartProcedure & {
    procedure: IProcedure;
};

export function autoSelectProvider(encounterAppointment?: IPatientEncounter): string | undefined {
    if (encounterAppointment) {
        if (encounterAppointment.treatingProviderId) {
            return encounterAppointment.treatingProviderId;
        }
        if (encounterAppointment.hygienistId) {
            return encounterAppointment.hygienistId;
        }
    } else {
        return;
    }
}

/**
 * In this thunk we only every update 1 procedure at a time.
 *
 * We don't want to translate our surfaces to incisial here...
 *
 * Maybe we have two different pipelines that handle different things?
 *
 * One that handles initial setting of a procedure's surfaces (Root/Crown)
 * One that handles translating the surfaces to their intended "areas"?
 *
 */

//I don't need a applicable areas pipe here.
export const onUpdateProcedurePanelSurfaces =
    (surfaces: (keyof typeof ToothArea)[], procedureId: string, toothOrArea: number | string) =>
    (dispatch: ThunkDispatch<unknown, unknown, AnyAction>, getState: () => RootState): void => {
        const panelState = getState().charting.procedurePanel;
        const proceduresData = getState().tenant.procedures.data;
        const diagnosesData = getState().tenant.diagnoses.data;
        const selectedTeethByProcedure = getState().charting.procedurePanel.selectedProceduresTeeth;
        const chartProceduresList = getState().charting.procedures.data.filter((p) => !p.isDeleted);

        if (proceduresData && diagnosesData) {
            const selectedChartProcedureId = panelState.selectedChartProcedure
                ? [panelState.selectedChartProcedure.id]
                : undefined;
            dispatch(
                chartingActions.setProcPanelSurfaces({
                    surfaces,
                    procedureId,
                    toothOrArea,
                }),
            );

            const createsManyProceduresList = panelState.procedures.filter(createsManyProcedures);
            const proceduresList = map(proceduresData, (p) => p) as IProcedure[];
            const diagonsesList = map(diagnosesData, (p) => p) as IDiagnosis[];

            const isToothNumber = !isNaN(+toothOrArea);
            new ChartingProceduresPipeline({
                newProcedures: createsManyProceduresList,
                type: ProcedureActionType.Treatment,
                selectedTeeth: isToothNumber ? [+toothOrArea] : undefined,
                currentChartProcedures: chartProceduresList,
                selectedProcedureTeeth: selectedTeethByProcedure,
                respectiveChartProcedureIds: selectedChartProcedureId,
            }).next((procs) => {
                const newProceduresWithAreas = procs.map((p) => {
                    const areasPipe = new ProcedureApplicableAreasPipeline({
                        procedures: createsManyProceduresList,
                        chartProcedures: [p],
                        toothAreas: surfaces,
                    });
                    return { ...p, areas: areasPipe.getItems[0].areas };
                });

                new ProcedureBusinessRulesPipeline({
                    chartProcedures: newProceduresWithAreas,
                    procedures: proceduresList,
                    selectedTeeth: isToothNumber ? [+toothOrArea] : undefined,
                    diagnoses: diagonsesList,
                    chartProceduresList,
                    originalProcedures: createsManyProceduresList,
                }).next((procs) => {
                    const chartProcedure = procs[0];
                    if (chartProcedure?.procedureId) {
                        dispatch(
                            chartingActions.updateProcedurePanelProcedure({
                                procedureId,
                                toothOrArea: toothOrArea,
                                procedure: proceduresData[chartProcedure.procedureId] as IProcedure,
                            }),
                        );
                    }
                });
            });
        }
    };

export const handleProcedureBrowserClick =
    (procedureId: string) =>
    (dispatch: ThunkDispatch<unknown, unknown, AnyAction>, getState: () => RootState): void => {
        const state = getState();
        const quickActions = state.tenant.quickActions;
        const selectedQuickActionType = quickActions.selectedQuickActionType;
        const { data: procedures } = state.tenant.procedures;
        const encounter = state.encounter.patientEncounter;
        if (procedures) {
            const diagnosisData = state.tenant.diagnoses.data;
            const proceduresData = state.tenant.procedures.data;
            const encounterId = state.encounter.patientEncounter?.id;

            const selectedTeeth = state.charting.ui.selectedTeeth;
            const proceduresToAdd = [procedures[procedureId] as IProcedure];

            const payload: ProceduresPanelPayload = {
                procedures: proceduresToAdd,
                selectedActionType: selectedQuickActionType,
                providerId: autoSelectProvider(encounter),
                diagnosisData,
                proceduresData,
                encounterId,
                selectedPreAuth:
                    (procedures[procedureId] as IProcedure).preAuthStatus === ChartProcedurePreAuth.Required
                        ? ChartProcedurePreAuth.Required
                        : ChartProcedurePreAuth.NotRequired,
                panelTeethData: generatePanelTeethData(state, selectedTeeth, proceduresToAdd),
            };
            dispatch(chartingActions.setProcedureBrowserOpen(false));
            dispatch(chartingActions.setProcedurePanelItems(payload));
        }
    };

export const handleProcedurePanelSave =
    (tenantId: string, patientId: string, procedures: IChartProcedure[], encounterId?: string): AppThunk<void> =>
    (dispatch, getState): void => {
        const { isEditing, selectedChartProcedure, selectedStatus, selectedPreAuth, notes, date, selectedAuthorization } =
            getState().charting.procedurePanel;
        const encounterProcedures = procedures.map((p) => ({
            ...p,
            encounterId,
            notes,
            status: selectedStatus,
            preAuthorization: selectedPreAuth,
            preAuthorizationCode: selectedAuthorization,
            onSetDate: date,
        }));

        if (isEditing && selectedChartProcedure) {
            const newChartProcedure: IChartProcedure = {
                ...selectedChartProcedure,
                ...encounterProcedures[0],
                id: selectedChartProcedure.id,
            };
            dispatch(updateChartProcedures({ tenantId, patientId, procedures: [newChartProcedure] }));
        } else {
            dispatch(createChartProcedures({ tenantId, patientId, procedures: encounterProcedures }));
        }
    };

export const handleEditProcedureClick =
    (chartAction: IChartAction) =>
    (dispatch: ThunkDispatch<unknown, unknown, AnyAction>, getState: () => RootState): void => {
        const state = getState();

        const { data: procedures } = state.tenant.procedures;
        const { data: chartProcedures } = state.charting.procedures;
        if (chartAction.actionType === 'Procedure') {
            const chartProcedure = chartProcedures.find((proc) => proc.id === chartAction.id);
            if (procedures && chartProcedure?.procedureId) {
                const procedure = procedures[chartProcedure.procedureId] as IProcedure;

                const payload: EditProcedurePanelPayload = {
                    procedure,
                    chartProcedure,
                    actionType: chartProcedure.type,
                    provider: chartProcedure.treatingProviderId,
                    date: chartProcedure.onSetDate,
                    areas: chartProcedure.areas,
                    selectedStatus: chartProcedure.status as ChartProcedureStatus,
                    selectedPreAuth: chartProcedure.preAuthorization as ChartProcedurePreAuth,
                    selectedAuthorization: chartProcedure.preAuthorizationCode ?? '',
                    notes: chartProcedure.notes,
                    diagnosisCodes: chartProcedure.diagnosisCodes,
                };

                dispatch(chartingActions.setProcedurePanelEditItem(payload));
            }
        }
    };
/**
 * Used to get the key of the panelTeethData.
 *
 * If there are toothIds, then a tooth id is used for the key.
 *
 * Otherwise,
 * Logic will determine if the procedure is based on an area and a stage procedure
 * and build the key appropriately.
 *
 * @param {IChartProcedure} chartProcedure
 * @return {*}
 */
export const getAreaTeethDataProp = (chartProcedure: IChartProcedure) => {
    const toothNumber = +(chartProcedure.toothIds ?? [])[0];
    const doesHaveToothNumber = !!toothNumber && !isNaN(toothNumber);
    if (doesHaveToothNumber) return toothNumber;

    if (!chartProcedure?.areas) return undefined;
    if (chartProcedure?.stage && chartProcedure.areas[0]) {
        return `${chartProcedure.areas[0]}-${chartProcedure.stage}`;
    }
    return chartProcedure.areas[0];
};

// I need the applicable areas pipe here because we generate initial surfaces when procs are being added. (For Root or Crown procs)
function generatePanelTeethData(
    state: RootState,
    selectedTeeth?: number[],
    overrideProcedures?: IProcedure[],
    selectedTeethByProcedure?: Dictionary<number[]>,
    areas?: (keyof typeof ToothArea)[],
): Dictionary<IPanelProcedure> {
    const proceduresData = state.tenant.procedures.data;
    const diagnosisData = state.tenant.diagnoses.data;
    const proceduresList = map(proceduresData, (d) => d) as IProcedure[];
    const diagnosesList = map(diagnosisData, (d) => d) as IDiagnosis[];

    const encounterId = state.encounter.patientEncounter?.id;

    const chartProceduresList = state.charting.procedures.data.filter((p) => !p.isDeleted);

    const newProcedures = overrideProcedures ? overrideProcedures : state.charting.procedurePanel.procedures;

    const selectedChartProcedureId = state.charting.procedurePanel.selectedChartProcedure
        ? [state.charting.procedurePanel.selectedChartProcedure.id]
        : undefined;

    const type = state.charting.procedurePanel.selectedActionType
        ? state.charting.procedurePanel.selectedActionType
        : ProcedureActionType.Treatment;

    const proceduresWithDiagnosis: IProcedureWithDiagnoses[] = [];
    new ProcedureDiagnosisPipeline({
        newProcedures,
        chartProceduresList,
        proceduresData,
        encounterId,
        diagnosisData,
    }).next((items) => {
        proceduresWithDiagnosis.push(...items);
    });

    //Generate dummy list of procedures based on business rules.
    const proceduresBySelectedTeeth: IChartProcedureWithOriginalProcedure[] = [];

    // NOTE The ChartingProceduresPipeline should handle knowing what the procedures are and how to sort and generate them
    new ChartingProceduresPipeline({
        selectedTeeth,
        selectedProcedureTeeth: selectedTeethByProcedure,
        newProcedures,
        areas,
        currentChartProcedures: chartProceduresList,
        type,
        proceduresWithDiagnosis,
        respectiveChartProcedureIds: selectedChartProcedureId,
    }).next((procs) => {
        new ProcedureApplicableAreasPipeline({
            procedures: newProcedures,
            chartProcedures: procs,
        }).next((procs) => {
            new ProcedureBusinessRulesPipeline({
                chartProcedures: procs,
                procedures: proceduresList,
                selectedTeeth,
                diagnoses: diagnosesList,
                chartProceduresList,
                originalProcedures: newProcedures,
                encounter: state.encounter.patientEncounter,
            }).next((procs) => {
                proceduresBySelectedTeeth.push(...procs);
            });
        });
    });

    const teethData: Dictionary<IPanelProcedure> = {};
    proceduresBySelectedTeeth.forEach((chartProcedure) => {
        const currentProcedure =
            state.tenant.procedures.data && chartProcedure.procedureId
                ? state.tenant.procedures.data[chartProcedure.procedureId]
                : undefined;

        const groupedTeeth = chartProcedure.toothIds && chartProcedure.toothIds?.length > 1 ? chartProcedure.toothIds : undefined;

        if (chartProcedure?.originalProcedureId) {
            const toothDataProp = getAreaTeethDataProp(chartProcedure);
            const procedureWithDx = proceduresWithDiagnosis.find((p) => p.id === currentProcedure?.id);
            const diagnosisCodes: ChartProcedureDiagnosis[] = chartProcedure?.diagnosisCodes?.length
                ? chartProcedure.diagnosisCodes
                : procedureWithDx
                ? procedureWithDx.diagnosisItems.map((d) => ({
                      id: d.id,
                      description: d.description,
                      displayName: d.code,
                  }))
                : [];

            const currentToothData = state.charting.procedurePanel.panelTeethData[chartProcedure.originalProcedureId];

            if (!teethData[chartProcedure.originalProcedureId]) {
                teethData[chartProcedure.originalProcedureId] = {
                    generalDiagnosisCodes: !toothDataProp ? currentToothData?.generalDiagnosisCodes ?? diagnosisCodes : [],
                    data: {},
                };
            }
            const panelProcedure = teethData[chartProcedure.originalProcedureId] as IPanelProcedure;
            if (toothDataProp && chartProcedure.originalProcedureId) {
                const toothData = currentToothData?.data[toothDataProp];
                teethData[chartProcedure.originalProcedureId] = {
                    ...panelProcedure,
                    data: {
                        ...panelProcedure.data,
                        [toothDataProp]: toothData
                            ? {
                                  ...toothData,
                                  procedure: currentProcedure,
                                  groupedTeeth,
                              }
                            : {
                                  procedure: currentProcedure,
                                  surfaces: chartProcedure.areas,
                                  diagnosisCodes,
                                  groupedTeeth,
                              },
                    },
                };
            }
        }
    });

    return teethData;
}

export const handleSetSelectedTeeth =
    (selectedTeeth: number[], procedureId?: string) =>
    (dispatch: ThunkDispatch<unknown, unknown, AnyAction>, getState: () => RootState) => {
        const state = getState();
        const selectedTeethByProcedure: Dictionary<number[]> = { ...state.charting.procedurePanel.selectedProceduresTeeth };
        if (!procedureId) {
            dispatch(chartingActions.setProcPanelSelectedTeeth(selectedTeeth));
        } else {
            dispatch(chartingActions.setProcPanelProcedureSelectedTeeth({ selectedTeeth, procedureId }));
            selectedTeethByProcedure[procedureId] = selectedTeeth;
        }

        const teethData = generatePanelTeethData(
            state,
            !procedureId ? selectedTeeth : state.charting.procedurePanel.selectedTeeth,
            undefined,
            selectedTeethByProcedure,
        );
        dispatch(chartingActions.setProcedureTeethData(teethData));
    };
export const handleSetSelectedAreas =
    (areas: (keyof typeof ToothArea)[]) => (dispatch: ThunkDispatch<unknown, unknown, AnyAction>, getState: () => RootState) => {
        const state = getState();
        const selectedTeethByProcedure: Dictionary<number[]> = { ...state.charting.procedurePanel.selectedProceduresTeeth };

        dispatch(chartingActions.setProcPanelSelectedAreas(areas));

        const teethData = generatePanelTeethData(
            state,
            state.charting.procedurePanel.selectedTeeth,
            undefined,
            selectedTeethByProcedure,
            areas,
        );
        dispatch(chartingActions.setProcedureTeethData(teethData));
    };

export const handleProcedureMenuClick =
    (quickActionId: string) =>
    (dispatch: ThunkDispatch<unknown, unknown, AnyAction>, getState: () => RootState): void => {
        const state = getState();
        const quickActions = state.tenant.quickActions;
        const encounter = state.encounter.patientEncounter;
        const encounterId = state.encounter.patientEncounter?.id;

        const selectedQuickActionType = quickActions.selectedQuickActionType;

        const { data: actions } = quickActions.items;
        const { data: conditions } = state.tenant.conditions;
        const { data: procedures } = state.tenant.procedures;

        if (actions) {
            const selectedAction = actions.actions.find((res) => res.id === quickActionId); //actions[quickActionId];
            if (selectedAction) {
                const diagnosisData = state.tenant.diagnoses.data;
                const proceduresData = state.tenant.procedures.data;

                const actionProcedureIds = selectedAction.procedures;
                const actionConditionIds = selectedAction.conditions;

                const hasProcedures = actionProcedureIds.length > 0;
                const hasConditions = actionConditionIds.length > 0;

                const proceduresToReturn: IProcedure[] = [];
                const conditionsToReturn: ICondition[] = [];

                if (hasProcedures && procedures && Object.keys(procedures).length > 0) {
                    actionProcedureIds.forEach((procedure) => {
                        if (procedures[procedure.id]) proceduresToReturn.push(procedures[procedure.id] as IProcedure);
                    });
                }

                const selectedTeeth = state.charting.ui.selectedTeeth;

                const payload: ProceduresPanelPayload = {
                    selectedActionType: selectedQuickActionType,
                    providerId: autoSelectProvider(encounter),
                    encounterId,
                    diagnosisData,
                    proceduresData,
                    //!We may not want this to set all procedures to required preauth if one is required.
                    selectedPreAuth: some(proceduresToReturn, (p) => p.preAuthStatus === ChartProcedurePreAuth.Required)
                        ? ChartProcedurePreAuth.Required
                        : ChartProcedurePreAuth.NotRequired,
                    panelTeethData: generatePanelTeethData(state, selectedTeeth, proceduresToReturn),
                };

                if (hasConditions && conditions && Object.keys(conditions).length > 0) {
                    actionConditionIds.forEach((condition) => {
                        if (conditions[condition.id]) conditionsToReturn.push(conditions[condition.id] as ICondition);
                    });
                }

                payload['procedures'] = proceduresToReturn;
                payload['conditions'] = conditionsToReturn;
                dispatch(chartingActions.setProcedurePanelItems(payload));
            }
        }
    };
