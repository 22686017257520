import { createSlice } from '@reduxjs/toolkit';
import { ReadyForAttestationEncounterView } from 'api/models/clinical-huddle';
import IPatientAppointment from 'api/models/Scheduling/patientAppointment.model';
import { startOfDay } from 'date-fns/esm';
import { LoadingStatus, LoadingStatuses } from 'interfaces/loading-statuses';
import { clinicalHuddleExtraReducers, clinicalHuddleReducers } from './clinical-huddle.reducers';

export interface IQuickViewSchedule {
    name: string;
    color: string;
    hours: string[];
}

export type ClinicalHuddleState = {
    beacon?: unknown;
    weekOfAppointments: {
        providerId?: string;
        providerIdArray?: string[];
        locationOfCareArray?: string[];
        patientSearch: string;
        expandedLocationOfCareIds: string[];
        showActiveOnly: boolean;
        date?: string;
        appointments: {
            loading: LoadingStatuses;
            data?: IPatientAppointment[];
        };
    };
};

const initialState: ClinicalHuddleState = {
    weekOfAppointments: {
        date: startOfDay(new Date()).toISOString(),
        showActiveOnly: false,
        expandedLocationOfCareIds: [],
        patientSearch: '',
        appointments: {
            loading: LoadingStatus.Idle,
        },
    },
};

const clinicalHuddleSlice = createSlice({
    name: 'clinical-huddle',
    initialState,
    reducers: {
        ...clinicalHuddleReducers,
    },
    extraReducers: (builder) => {
        clinicalHuddleExtraReducers(builder);
    },
});

const { reducer, actions } = clinicalHuddleSlice;
export const {
    setDate,
    setProviderId,
    setProviderArray,
    setLocationArray,
    toggleExpandedLocationOfCare,
    setExpandedLocationOfCares,
    addOrUpdatePatientAppointmentAllocation,
    updatePatientAppointmentAllocationsEncounterStatus,
    toggleActiveAppointment,
    setPatientSearch
} = actions;

export default reducer;
