import { createAsyncThunk, Dictionary } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import IPrescriptionItem from 'api/models/prescription.model';
import IPatientPrescription, { PatientPrescriptionOrderIngMode } from 'api/models/patient-prescription';
import { AppThunk } from 'state/store';
import { v4 as uuid } from 'uuid';
import { addPatientEncounterPrescription } from '../encounter/encounter.slice';
import { setCurrentPrescription, setPrescriptionsPanelOpen } from './prescriptions-panel.slice';

export const getAllPatientPrescriptions = createAsyncThunk(
    'getAllPatientPrescriptions',
    async ({ tenantId, patientId }: { tenantId: string; patientId: string }) => {
        const res = await dentalApi.getAllPatientPrescriptions(tenantId, patientId);
        return res.data;
    },
);

export const addCurrentPatientPrescription = createAsyncThunk<
    IPatientPrescription,
    { tenantId: string; patientId: string; model: IPatientPrescription }
>('addCurrentPatientPrescription', async ({ tenantId, patientId, model }, { dispatch }) => {
    const res = await dentalApi.addPatientPrescription(tenantId, patientId, model);
    if (res.status === 200) {
        dispatch(setPrescriptionsPanelOpen(false));
        dispatch(addPatientEncounterPrescription(res.data));
    }
    return res.data;
});

export const searchMedicationsPrescriptionPanel = createAsyncThunk<Dictionary<IPrescriptionItem>, { query: string }>(
    'searchMedicationsPrescriptionPanel',
    async ({ query }) => {
        const res = await dentalApi.searchPrescriptionItems(query);
        return res.data;
    },
);

export const setNewPrescription =
    (prescription: IPrescriptionItem, patientId: string, encounterId?: string): AppThunk<void> =>
    (dispatch, getState): void => {
        const encounterAppointment = getState().encounter.patientEncounterAppointment;

        const newPrescription: IPatientPrescription = {
            id: uuid(),
            patientId,
            displayName: prescription.displayName,
            isDeleted: false,
            encounterId,
            isDocumentationOnly: false,
            prescriberId: encounterAppointment?.treatingProviderId,
            orderTypeId: prescription.references?.athenaOne,
            orderingMode: PatientPrescriptionOrderIngMode.Prescribe,
            dispenseAsWritten: false,
        };

        dispatch(setCurrentPrescription(newPrescription));
    };
