import { IChartProcedure } from 'api/models/chart.model';
import { ConflictRuleType } from 'api/models/lookup.model';
import { IPipelineError, PipelineError } from '../pipeline';
import ProcedureConflictRulesPipeline from '../procedureConflictRules.pipeline';
import { createRule } from '../ruleGenerator';

const onePerEncounter = createRule<ProcedureConflictRulesPipeline, IChartProcedure>({
    ruleTypes: [ConflictRuleType.onePerEncounter],
    rule: (pipeline, item) => {
        const errors: IPipelineError[] = [];
        const proceduresFromEncounter = [...pipeline.getItems, ...pipeline.getEncounterChartProcedures()].filter((p) => p.id !== item.id);
        const sameProcedure = proceduresFromEncounter.find((p) => p.procedureId === item.procedureId);
        if (sameProcedure) errors.push({ data: pipeline.getProcedure(item), type: PipelineError.onePerEncounter });

        return { shouldRemoveItem: !!errors.length, errors };
    },
});

export default onePerEncounter;
