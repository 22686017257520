import { IChartProcedure } from 'api/models/chart.model';
import RulesGenerator, { IRule } from './ruleGenerator';
import ProcedureConflictRulesPipeline from './procedureConflictRules.pipeline';
import { ConflictRuleType } from 'api/models/lookup.model';
import { isArray } from 'lodash';
import Rule from './rule';

type Args = {
    procedurePipeline: ProcedureConflictRulesPipeline;
    chartCondition: IChartProcedure;
    rules: { [key: string]: IRule<ProcedureConflictRulesPipeline, IChartProcedure> };
};
/**
 * Generates and runs rules based on conflictRules list for the passed chartCondition
 *
 * @export
 * @class ConditionConflictRuleGenerator
 */
export default class ProcedureConflictRuleGenerator extends RulesGenerator<ProcedureConflictRulesPipeline, IChartProcedure> {
    constructor({ procedurePipeline, chartCondition, rules }: Args) {
        super({ pipeline: procedurePipeline, item: chartCondition, rules, ruleTypes: ConflictRuleType });
    }

    protected _runRules(pipeline: ProcedureConflictRulesPipeline): void {
        function getProcedure(chartProcedure: IChartProcedure) {
            return pipeline.newProcedures.find((p) => p.id === chartProcedure.procedureId);
        }

        if (!isArray(this._item)) {
            const conflictRules = getProcedure(this._item)?.conflictRules ?? [];

            for (let i = 0; i < conflictRules.length; i++) {
                const r = conflictRules[i];
                const rule = this._rules[r];
                if (rule !== undefined) {
                    const ruleData: Rule<ProcedureConflictRulesPipeline, IChartProcedure> = new Rule<
                        ProcedureConflictRulesPipeline,
                        IChartProcedure
                    >({ item: this._item as IChartProcedure, pipeline, rule });
                    const { getErrors, getShouldRemoveItem, getItem } = ruleData;
                    if (getErrors) this.addErrors(getErrors);

                    if (!this.shouldRemoveItem) this.setShouldRemoveItem = getShouldRemoveItem;
                    if (!this.shouldRemoveItem) {
                        this._item = getItem;
                    }
                }
            }
        }
    }
}
