import { IChartProcedure } from 'api/models/chart.model';
import { ConflictRuleType } from 'api/models/lookup.model';
import { IPipelineError, PipelineError } from '../pipeline';
import ProcedureConflictRulesPipeline from '../procedureConflictRules.pipeline';
import { createRule } from '../ruleGenerator';

const ProcedurePosteriorOnly = createRule<ProcedureConflictRulesPipeline, IChartProcedure>({
    ruleTypes: [ConflictRuleType.posteriorOnly],
    rule: (pipeline, item) => {
        let newProcedure = { ...item };
        const _errors: IPipelineError[] = [];
        if (item.toothIds) {
            item.toothIds.forEach((id) => {
                if (!pipeline.getIsToothPosterior(id)) {
                    newProcedure = pipeline.removeToothIdFromChartProcedure(newProcedure, id);
                    _errors.push({ type: PipelineError.PosteriorOnly, data: pipeline.getProcedure(item) });
                }
            });
        }

        if (!newProcedure.toothIds?.length) return { shouldRemoveItem: true, errors: _errors };
        if (_errors.length) return { shouldRemoveItem: false, errors: _errors, updatedItem: newProcedure };
        return { shouldRemoveItem: false, updatedItem: newProcedure };
    },
});

export default ProcedurePosteriorOnly;
