import IWorkListView from "api/models/worklist-view.model";

export function worklistItemCanBeApprovedErrors(item: IWorkListView | undefined) {
    const missingPropertiesErrorMessages = [];

    if (!item?.patientFirstName) missingPropertiesErrorMessages.push('Patient is missing first name.');
    if (!item?.patientLastName) missingPropertiesErrorMessages.push('Patient is missing last name.');
    if (!item?.dob) missingPropertiesErrorMessages.push('Patient is missing date of birth.');
    if (!item?.legalSex) missingPropertiesErrorMessages.push('Patient is missing legal sex.');

    return missingPropertiesErrorMessages
}

export function canSelectWorklistViewItemForApproval(item: IWorkListView | undefined) {
    return !worklistItemCanBeApprovedErrors(item).length;
}
