import { Stack, PrimaryButton, DefaultButton, Spinner, Panel, PanelType, Separator, TextField } from '@fluentui/react';
import IMedication from 'api/models/medications.model';
import { IPatientChartMedication } from 'api/models/patient-medication.model';
import { Field } from 'components';
import { useSelector } from 'hooks';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { RouteParams } from 'interfaces/route-params';
import PanelSectionHeader from 'pages/components/PanelSectionHeader';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { createOrUpdatePatientMedicationView } from 'state/slices/patient/medications/medications.actions';
import {
    selectPatientMedicationPanelOpen,
    selectPatientMedicationPanelSaving,
    selectPatientMedicationsToAdd,
} from 'state/slices/patient/medications/medications.selectors';
import {
    addChartMedication,
    setPatientMedicationsPanelOpen,
    removeChartMedication,
    updateChartMedicationField,
} from 'state/slices/patient/patient.slice';
import MedicationDropdown from './MedicationDropdown';

// if you want to rip and replace where applicable
export default function MedicationsPanel(): JSX.Element {
    const { patientId, tenantId, encounterId } = useParams<RouteParams>();
    const dispatch = useDispatch();

    const medicationsToAdd = useSelector(selectPatientMedicationsToAdd);

    const isOpen = useSelector(selectPatientMedicationPanelOpen);
    const saving = useSelector(selectPatientMedicationPanelSaving);

    const isSaving = saving === LoadingStatus.Pending;

    const hasMedicationsToAdd = !!medicationsToAdd.length;

    const _onDismiss = () => dispatch(setPatientMedicationsPanelOpen(false));
    const _handleAddMedication = (medication?: IMedication) => {
        if (medication && medication.id) {
            dispatch(addChartMedication(medication));
        }
    };

    const onRenderFooterContent = () => (
        <Stack horizontal tokens={{ childrenGap: 10 }}>
            <PrimaryButton
                text="Save"
                title="Save"
                disabled={isSaving || !hasMedicationsToAdd}
                onClick={_handleCreateOrUpdatePatientMedicationView}
            />
            <DefaultButton text="Cancel" title="Cancel" onClick={_onDismiss} />
            {isSaving && <Spinner label="Saving..." labelPosition="right" />}
        </Stack>
    );

    const _handleCreateOrUpdatePatientMedicationView = () => {
        if (hasMedicationsToAdd) dispatch(createOrUpdatePatientMedicationView({ tenantId, patientId, encounterId }));
    };

    const medicationFields = medicationsToAdd.map((medication, index) => (
        <MedicationInputs key={index} medication={medication} />
    ));

    return (
        <Panel
            headerText="Add reported medications"
            isOpen={isOpen}
            styles={{
                content: { overflowY: 'auto', overflowX: 'hidden', flex: 1, position: 'relative' },
                root: { overflow: 'hidden' },
                scrollableContent: { overflow: 'hidden', display: 'flex', flexDirection: 'column' },
            }}
            type={PanelType.medium}
            customWidth={'800px'}
            isFooterAtBottom={true}
            onDismiss={_onDismiss}
            onRenderFooterContent={onRenderFooterContent}
        >
            <Stack tokens={{ childrenGap: 10 }}>
                <MedicationDropdown placeholder={'(Select medication)'} addMedication={_handleAddMedication} />
                {medicationFields}
            </Stack>
        </Panel>
    );
}

type MedicationInputProps = { medication: IPatientChartMedication };
function MedicationInputs({ medication }: MedicationInputProps): JSX.Element {
    const dispatch = useDispatch();

    const _handleDeleteMedication = () => dispatch(removeChartMedication({ medicationToRemove: medication }));

    const _handleUpdateStartDate = (ev?: React.FormEvent, value?: string) =>
        dispatch(updateChartMedicationField({ medication, path: 'startDate', value }));
    const _handleUpdateStopDate = (ev?: React.FormEvent, value?: string) =>
        dispatch(updateChartMedicationField({ medication, path: 'stopDate', value }));
    const _handleUpdateSig = (ev?: React.FormEvent, value?: string) =>
        dispatch(updateChartMedicationField({ medication, path: 'unstructuredSignature', value }));

    const medicationText = medication.medicationName;
    return (
        <>
            <Separator />
            <Stack tokens={{ childrenGap: 10 }} className="ms-motion-fadeIn">
                <Stack>
                    <PanelSectionHeader
                        text={medicationText ?? ''}
                        rightAction={{
                            text: 'Remove Medication',
                            onClick: _handleDeleteMedication,
                        }}
                    />
                    <Stack>
                        <Stack verticalAlign="end">
                            <TextField label="Sig" multiline onChange={_handleUpdateSig} />
                        </Stack>
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 10 }} grow>
                        <Stack.Item grow>
                            <Field.Date
                                value={medication.startDate}
                                label="Start Date"
                                style={{ minWidth: 215 }}
                                onChange={_handleUpdateStartDate}
                                hasDatePicker
                            />
                        </Stack.Item>
                        <Stack.Item grow>
                            <Field.Date
                                value={medication.stopDate}
                                label="Stop Date"
                                style={{ minWidth: 215 }}
                                onChange={_handleUpdateStopDate}
                                hasDatePicker
                                disabled={!medication.startDate}
                            />
                        </Stack.Item>
                    </Stack>
                </Stack>
            </Stack>
        </>
    );
}
