import { Dictionary } from '@reduxjs/toolkit';
import IForm from 'api/models/form';

export interface IPatientProblemsFrom extends IForm {
    data: IPatientProblemFormData;
}

export interface IPatientProblemFormData {
    questions: Dictionary<IPatientProblemsQuestion>;
    note: string;
}

export interface IPatientProblemsQuestion {
    displayName: string;
    date: string;
    name: string;
    value?: YesNoValue;
    snomedCode?: string;
}

export enum YesNoValue {
    Yes = 'Yes',
    No = 'No',
    Empty = '',
}

//Todo: Use problem health issues from the API to generate this question for problems.

// Whenever the patient problems panel is open, we need to request these.

// const test: Questions = {
//     id: {
//        value: YesNoValue
//        name: string;
//     }
//    ...
//
// }
