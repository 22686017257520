import { DirectionalHint } from '@fluentui/react';
import { Dictionary } from '@reduxjs/toolkit';
import * as Tutorials from './tutorials';
export interface ITutorialStep {
    target: string;
    hasCloseButton?: boolean;
    headline: string;
    isWide?: boolean;
    directionalHint?: DirectionalHint;
    pages?: number;
}

//Limits us to 3 steps per fluent ui standard.
export enum TutorialSteps {
    One = 'One',
    Two = 'Two',
    Three = 'Three',
}

export type Tutorial = { [key in TutorialSteps]?: ITutorialStep };
export type TutorialsLookup = Dictionary<Tutorial>;
export const allTutorials: TutorialsLookup = Tutorials;

export type TutorialTypes = keyof typeof Tutorials;

export interface ITeachingState {
    currentPage?: number; //page within a step
    currentStep?: TutorialSteps; //Step withing a tutorial
    currentTutorial?: TutorialTypes;
}

const initialTeachingState: ITeachingState = {};

export default initialTeachingState;
