import IAppointmentCancellationReason from 'api/models/appointment-cancellation-reason.model';
import IPatientAppointment from 'api/models/Scheduling/patientAppointment.model';
import { LoadingStatuses } from 'interfaces/loading-statuses';
import { filter } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'state/store';
import AppointmentToCancelState from './appointment-to-cancel.state';

export const selectAppointmentToCancelState = (state: RootState): AppointmentToCancelState =>
    state.scheduling.appointmentToCancel;

//Patient Appointment to Remove
export const selectAppointmentToCancel = (state: RootState): IPatientAppointment | undefined =>
    state.scheduling.appointmentToCancel.data;
export const selectAppointmentToCancelLoading = (state: RootState): LoadingStatuses =>
    state.scheduling.appointmentToCancel.loading;
export const selectAppointmentIdToCancel = (state: RootState): string | undefined =>
    state.scheduling.appointmentToCancel.appointmentToRemoveId;
export const selectIsCancelApptModalOpen = (state: RootState): boolean => !!state.scheduling.appointmentToCancel.isOpen;

//Cancellation Reasons
export const selectCancellationReasonsData = createSelector(selectAppointmentToCancelState, (state) => {
    return state.reasonsForCancellation ?? {};
});
export const selectCancellationReasons = createSelector(selectCancellationReasonsData, (data) => {
    return data ? (filter(data, (reason) => !reason?.isDeleted) as IAppointmentCancellationReason[]) : [];
});
export const selectCancellationReasonsLoading = createSelector(selectAppointmentToCancelState, (state) => {
    return state.loadingCancelationReasons;
});
