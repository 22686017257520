import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { IBatch } from 'api/models/batch.model';
import { IPaymentSource } from 'api/models/payment-source.model';
import { IValidationError } from 'hooks/useValidation';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { forEach } from 'lodash';
import { LedgerState } from '../ledger.state';
import {
    getPatientEncounterSummariesWithLineItemsView,
    getPatientPaymentsAndAdjustmentsBillingProcedures,
    savePatientAdjustmentTransactions,
    savePatientPaymentSourceAndTransactions,
} from './patient-payments-and-adjustments.actions';
import initialPatientPaymentsAndAdjustmentsState, {
    BillingProcedureWithTransactionIds,
    TransactionLookup,
} from './patient-payments-and-adjustments.state';
import { v4 as uuid } from 'uuid';
import { TransferTo } from 'api/models/encounter-ledger.model';

export const patientPaymentsAndAdjustmentsReducers = {
    cleanupPatientBillingProcedures: (state: LedgerState) => {
        state.patientPaymentsAndAdjustments.paymentsAndAdjustmentsBillingProcedures = [];
    },
    cleanupPatientPaymentsSavedStatusMessageBar: (state: LedgerState): void => {
        state.patientPaymentsAndAdjustments.savingPaymentInformation = LoadingStatus.Idle;
    },
    cleanupPatientAdjustmentsSavedStatusMessageBar: (state: LedgerState): void => {
        state.patientPaymentsAndAdjustments.savingAdjustmentInformation = LoadingStatus.Idle;
    },
    cleanupPaymentInformation: (state: LedgerState) => {
        state.patientPaymentsAndAdjustments.paymentSource = undefined;
        state.patientPaymentsAndAdjustments.paymentTransactions = undefined;

        state.patientPaymentsAndAdjustments.paymentsModified = false;
    },
    cleanupAdjustmentInformation: (state: LedgerState) => {
        state.patientPaymentsAndAdjustments.adjustmentTransactions = undefined;
        state.patientPaymentsAndAdjustments.totalAdjustmentAmount = undefined;

        state.patientPaymentsAndAdjustments.adjustmentsModified = false;
    },
    cleanupAllowOverpaymentOrAdjustment: (state: LedgerState) => {
        state.patientPaymentsAndAdjustments.allowOverpaymentOrAdjustment = false;
    },
    cleanupPatientPaymentsAndAdjustmentsData: (state: LedgerState) => {
        state.patientPaymentsAndAdjustments = initialPatientPaymentsAndAdjustmentsState;
    },
    clearEstimatePayments: (state: LedgerState) => {
        if (state.patientPaymentsAndAdjustments.estimateTransactions) {
            forEach(state.patientPaymentsAndAdjustments.estimateTransactions, (transactions, encounterId) => {
                forEach(transactions, (transaction, chartProcedureId) => {
                    (state.patientPaymentsAndAdjustments.estimateTransactions as TransactionLookup)[encounterId][
                        chartProcedureId
                    ] = { ...transaction, amount: 0 };
                });
            });

            state.patientPaymentsAndAdjustments.paymentsModified = true;
        }
    },
    clearPayments: (state: LedgerState) => {
        if (state.patientPaymentsAndAdjustments.paymentTransactions) {
            forEach(state.patientPaymentsAndAdjustments.paymentTransactions, (transactions, encounterId) => {
                forEach(transactions, (transaction, chartProcedureId) => {
                    (state.patientPaymentsAndAdjustments.paymentTransactions as TransactionLookup)[encounterId][
                        chartProcedureId
                    ] = { ...transaction, amount: 0 };
                });
            });

            state.patientPaymentsAndAdjustments.paymentsModified = true;
        }
    },
    clearAdjustments: (state: LedgerState) => {
        if (state.patientPaymentsAndAdjustments.adjustmentTransactions) {
            forEach(state.patientPaymentsAndAdjustments.adjustmentTransactions, (transactions, encounterId) => {
                forEach(transactions, (transaction, chartProcedureId) => {
                    (state.patientPaymentsAndAdjustments.adjustmentTransactions as TransactionLookup)[encounterId][
                        chartProcedureId
                    ] = { ...transaction, amount: 0 };
                });
            });

            state.patientPaymentsAndAdjustments.adjustmentsModified = true;
        }
    },
    updatePatientPaymentSource: (
        state: LedgerState,
        action: PayloadAction<{ path: keyof IPaymentSource; value: unknown }>,
    ): void => {
        const { path, value } = action.payload;
        if (state.patientPaymentsAndAdjustments.paymentSource) {
            state.patientPaymentsAndAdjustments.paymentSource = {
                ...state.patientPaymentsAndAdjustments.paymentSource,
                [path]: value,
            };

            state.patientPaymentsAndAdjustments.paymentsModified = true;
        }
    },
    setPatientPaymentSource: (state: LedgerState, action: PayloadAction<IPaymentSource | undefined>): void => {
        state.patientPaymentsAndAdjustments.paymentSource = action.payload;
    },
    setPatientPaymentTransactions: (state: LedgerState, action: PayloadAction<TransactionLookup>): void => {
        state.patientPaymentsAndAdjustments.paymentTransactions = action.payload;
    },
    setPatientEstimateTransactions: (state: LedgerState, action: PayloadAction<TransactionLookup>): void => {
        state.patientPaymentsAndAdjustments.estimateTransactions = action.payload;
    },
    updatePatientEstimateTransaction: (
        state: LedgerState,
        action: PayloadAction<{ encounterId: string; transactionId: string; amount: number }>,
    ): void => {
        if (state.patientPaymentsAndAdjustments.estimateTransactions) {
            const { amount, encounterId, transactionId } = action.payload;
            state.patientPaymentsAndAdjustments.estimateTransactions[encounterId][transactionId].amount = amount;
        }
    },
    updatePatientPaymentTransaction: (
        state: LedgerState,
        action: PayloadAction<{ encounterId: string; transactionId: string; amount: number }>,
    ): void => {
        if (state.patientPaymentsAndAdjustments.paymentTransactions) {
            const { amount, encounterId, transactionId } = action.payload;
            state.patientPaymentsAndAdjustments.paymentTransactions[encounterId][transactionId].amount = amount;
            state.patientPaymentsAndAdjustments.paymentsModified = true;
        }
    },
    updatePatientTotalAdjustmentAmount: (state: LedgerState, action: PayloadAction<number | undefined>): void => {
        state.patientPaymentsAndAdjustments.totalAdjustmentAmount = action.payload;
        state.patientPaymentsAndAdjustments.adjustmentsModified = true;
    },
    setPatientAdjustmentTransactions: (state: LedgerState, action: PayloadAction<TransactionLookup>): void => {
        state.patientPaymentsAndAdjustments.adjustmentTransactions = action.payload;
    },
    updatePatientAdjustmentTransaction: (
        state: LedgerState,
        action: PayloadAction<{ encounterId: string; transactionId: string; amount: number }>,
    ): void => {
        if (state.patientPaymentsAndAdjustments.adjustmentTransactions) {
            const { amount, encounterId, transactionId } = action.payload;
            state.patientPaymentsAndAdjustments.adjustmentTransactions[encounterId][transactionId].amount = amount;
        }
    },
    updatePatientAdjustmentReasons: (state: LedgerState, action: PayloadAction<string>): void => {
        if (state.patientPaymentsAndAdjustments.adjustmentTransactions) {
            forEach(state.patientPaymentsAndAdjustments.adjustmentTransactions, (transactions, encounterId) => {
                forEach(transactions, (transaction, chartProcedureId) => {
                    (state.patientPaymentsAndAdjustments.adjustmentTransactions as TransactionLookup)[encounterId][
                        chartProcedureId
                    ] = { ...transaction, adjustmentReasonId: action.payload };
                });
            });

            state.patientPaymentsAndAdjustments.adjustmentsModified = true;
        }
    },
    toggleAllowOveradjustmentOrPayment: (state: LedgerState): void => {
        state.patientPaymentsAndAdjustments.allowOverpaymentOrAdjustment =
            !state.patientPaymentsAndAdjustments.allowOverpaymentOrAdjustment;
        if (!state.patientPaymentsAndAdjustments.allowOverpaymentOrAdjustment) {
            state.patientPaymentsAndAdjustments.totalAdjustmentAmount = 0;
            if (state.patientPaymentsAndAdjustments.paymentSource?.amount) {
                state.patientPaymentsAndAdjustments.paymentSource.amount = 0;
            }
        }
    },
    togglePatientEstimateOverpayment: (state: LedgerState): void => {
        state.patientPaymentsAndAdjustments.allowEstimateOverpayment =
            !state.patientPaymentsAndAdjustments.allowEstimateOverpayment;
    },
    updateAllPatientTransactionsBatchId: (state: LedgerState, action: PayloadAction<IBatch>): void => {
        const { id: batchId, dateOfEntry } = action.payload;
        if (state.patientPaymentsAndAdjustments.paymentTransactions) {
            forEach(state.patientPaymentsAndAdjustments.paymentTransactions, (transactions, encounterId) => {
                forEach(transactions, (transaction, chartProcedureId) => {
                    (state.patientPaymentsAndAdjustments.paymentTransactions as TransactionLookup)[encounterId][
                        chartProcedureId
                    ] = { ...transaction, batchId, dateOfEntry };
                });
            });
        }
        if (state.patientPaymentsAndAdjustments.adjustmentTransactions) {
            forEach(state.patientPaymentsAndAdjustments.adjustmentTransactions, (transactions, encounterId) => {
                forEach(transactions, (transaction, chartProcedureId) => {
                    (state.patientPaymentsAndAdjustments.adjustmentTransactions as TransactionLookup)[encounterId][
                        chartProcedureId
                    ] = { ...transaction, batchId, dateOfEntry };
                });
            });
        }
    },
    setPatientPaymentsAndAdjustmentsValidationErrors: (state: LedgerState, action: PayloadAction<IValidationError[]>) => {
        state.patientPaymentsAndAdjustments.validationErrors = action.payload;
    },
    payAllPatientPayments: (state: LedgerState, action: PayloadAction<{ maxAmount: number }>): void => {
        distributeAmountToTransactionsLookup({
            amount: action.payload.maxAmount,
            billingProcedures: state.patientPaymentsAndAdjustments.paymentsAndAdjustmentsBillingProcedures,
            transactions: state.patientPaymentsAndAdjustments.paymentTransactions,
            negativeOffsetTransactions: state.patientPaymentsAndAdjustments.adjustmentTransactions,
            overpayment: state.patientPaymentsAndAdjustments.allowOverpaymentOrAdjustment,
        });

        state.patientPaymentsAndAdjustments.paymentsModified = true;
    },
    adjustAllPatientAdjustments: (state: LedgerState, action: PayloadAction<{ maxAmount: number }>): void => {
        distributeAmountToTransactionsLookup({
            amount: action.payload.maxAmount,
            billingProcedures: state.patientPaymentsAndAdjustments.paymentsAndAdjustmentsBillingProcedures,
            transactions: state.patientPaymentsAndAdjustments.adjustmentTransactions,
            negativeOffsetTransactions: state.patientPaymentsAndAdjustments.paymentTransactions,
            overpayment: state.patientPaymentsAndAdjustments.allowOverpaymentOrAdjustment,
            paymentType: "adjustment"
        });

        state.patientPaymentsAndAdjustments.adjustmentsModified = true;
    },
    payAllPatientEstimates: (state: LedgerState, action: PayloadAction<{ maxAmount: number }>): void => {
        distributeAmountToTransactionsLookup({
            amount: action.payload.maxAmount,
            billingProcedures: state.patientPaymentsAndAdjustments.estimateBillingProcedures,
            transactions: state.patientPaymentsAndAdjustments.estimateTransactions,
            overpayment: state.patientPaymentsAndAdjustments.allowEstimateOverpayment,
            feeField: 'patientEstimate',
        });

        state.patientPaymentsAndAdjustments.estimatePaymentsModified = true;
    },
};

function distributeAmountToTransactionsLookup({
    amount,
    transactions,
    billingProcedures,
    negativeOffsetTransactions,
    overpayment,
    feeField = 'commonPatientFee',
    paymentType = 'payment',
}: {
    amount: number;
    billingProcedures: BillingProcedureWithTransactionIds[] | undefined;
    transactions: TransactionLookup | undefined;
    feeField?: 'commonPatientFee' | 'patientEstimate';
    //These are transactions that will subtract, and therefore negatively offset the full amount being distributed to the line item.
    negativeOffsetTransactions?: TransactionLookup | undefined;
    paymentType?: "payment" | "adjustment";
    overpayment?: boolean;
}) {
    let remainingAmount = amount;

    if (transactions) {
        forEach(transactions, (t, encounterId) => {
            forEach(t, (transaction) => {
                const billingProcedure = billingProcedures?.find(
                    (bp) => bp.id === transaction.chartProcedureId && bp.encounterId === encounterId,
                );
                if (billingProcedure) {
                    const adjustmentTransactionId = billingProcedure.adjustmentTransactionId ?? '';
                    const paymentTransactionId = billingProcedure.paymentTransactionId ?? '';

                    const transactionIdToUse = paymentType === "payment" ? paymentTransactionId : adjustmentTransactionId;
                    const negativeTransactionIdToUse = paymentType === "payment" ? adjustmentTransactionId : paymentTransactionId;

                    const negativeOffsetTransaction = negativeOffsetTransactions
                        ? negativeOffsetTransactions[encounterId][negativeTransactionIdToUse]
                        : undefined;

                    const fee = (billingProcedure[feeField] ?? 0) - (negativeOffsetTransaction?.amount ?? 0);
                    const paymentAmount = remainingAmount < fee ? remainingAmount : fee;

                    const nonZeroPayment = paymentAmount < 0 ? 0 : paymentAmount;

                    const finalPaymentAmount = overpayment ? paymentAmount : nonZeroPayment;

                    remainingAmount = Number((remainingAmount - finalPaymentAmount).toFixed(2));

                    (transactions as TransactionLookup)[encounterId][transactionIdToUse].amount =
                        finalPaymentAmount > 0 ? finalPaymentAmount : transaction?.amount;
                }
            });
        });
    }
}

export const patientPaymentsAndAdjustmentsExtraReducers = (
    builder: ActionReducerMapBuilder<LedgerState>,
): ActionReducerMapBuilder<LedgerState> =>
    builder
        .addCase(getPatientPaymentsAndAdjustmentsBillingProcedures.pending, (state) => {
            state.patientPaymentsAndAdjustments.loadingLedgerbillingProcedures = LoadingStatus.Pending;
        })
        .addCase(getPatientPaymentsAndAdjustmentsBillingProcedures.fulfilled, (state, action) => {
            state.patientPaymentsAndAdjustments.loadingLedgerbillingProcedures = LoadingStatus.Completed;
            state.patientPaymentsAndAdjustments.paymentsAndAdjustmentsBillingProcedures = action.payload
                .filter((p) => p.status === 'Completed')
                .map((p) => ({ ...p, paymentTransactionId: uuid(), adjustmentTransactionId: uuid() }));

            state.patientPaymentsAndAdjustments.estimateBillingProcedures = action.payload
                .filter((p) => p.status === 'Completed' && p.transferTo === TransferTo.Insurance)
                .map((p) => ({ ...p, paymentTransactionId: uuid() }));
            state.patientPaymentsAndAdjustments.ledgerBillingProcedureError = undefined;
        })
        .addCase(getPatientPaymentsAndAdjustmentsBillingProcedures.rejected, (state, action) => {
            if (action.error.name !== 'AbortError') {
                state.patientPaymentsAndAdjustments.loadingLedgerbillingProcedures = LoadingStatus.Failed;
                state.patientPaymentsAndAdjustments.ledgerBillingProcedureError = action.error;
            } else {
                state.patientPaymentsAndAdjustments.loadingLedgerbillingProcedures = LoadingStatus.Idle;
            }
        })
        .addCase(getPatientEncounterSummariesWithLineItemsView.pending, (state) => {
            state.patientPaymentsAndAdjustments.loadingEncounterSummariesWithLineItems = LoadingStatus.Pending;
        })
        .addCase(getPatientEncounterSummariesWithLineItemsView.fulfilled, (state, action) => {
            state.patientPaymentsAndAdjustments.loadingEncounterSummariesWithLineItems = LoadingStatus.Completed;
            state.patientPaymentsAndAdjustments.encounterSummariesWithLineItems = action.payload.encounterSummaries;
            state.patientPaymentsAndAdjustments.encounterSummariesWithLineItemsError = undefined;
        })
        .addCase(getPatientEncounterSummariesWithLineItemsView.rejected, (state, action) => {
            if (action.error.name !== 'AbortError') {
                state.patientPaymentsAndAdjustments.loadingEncounterSummariesWithLineItems = LoadingStatus.Failed;
                state.patientPaymentsAndAdjustments.encounterSummariesWithLineItemsError = action.error;
            } else {
                state.patientPaymentsAndAdjustments.loadingEncounterSummariesWithLineItems = LoadingStatus.Idle;
            }
        })
        .addCase(savePatientPaymentSourceAndTransactions.pending, (state) => {
            state.patientPaymentsAndAdjustments.savingPaymentInformation = LoadingStatus.Pending;
        })
        .addCase(savePatientPaymentSourceAndTransactions.fulfilled, (state) => {
            state.patientPaymentsAndAdjustments.savingPaymentInformation = LoadingStatus.Completed;
        })
        .addCase(savePatientPaymentSourceAndTransactions.rejected, (state) => {
            state.patientPaymentsAndAdjustments.savingPaymentInformation = LoadingStatus.Failed;
        })
        .addCase(savePatientAdjustmentTransactions.pending, (state) => {
            state.patientPaymentsAndAdjustments.savingAdjustmentInformation = LoadingStatus.Pending;
        })
        .addCase(savePatientAdjustmentTransactions.fulfilled, (state) => {
            state.patientPaymentsAndAdjustments.savingAdjustmentInformation = LoadingStatus.Completed;
        })
        .addCase(savePatientAdjustmentTransactions.rejected, (state) => {
            state.patientPaymentsAndAdjustments.savingAdjustmentInformation = LoadingStatus.Failed;
        });
