import React, { useRef, CSSProperties } from 'react';
import {
    Persona,
    PersonaSize,
    ContextualMenu,
    DirectionalHint,
    IContextualMenuProps,
    useTheme,
    Stack,
    TooltipHost,
} from '@fluentui/react';
import usePermissions from 'hooks/store/usePermissions';

type Props = {
    photo: string;
    contextMenu: IContextualMenuProps;
};

const Avatar = (props: Props): JSX.Element => {
    const { photo, contextMenu } = props;
    const linkRef = useRef(null);

    const [showContextualMenu, setShowContextualMenu] = React.useState(false);
    const _toggleShowContextMenu = () => setShowContextualMenu(!showContextualMenu);
    const _onHideContextualMenu = () => setShowContextualMenu(false);

    return (
        <div style={{ marginRight: '10px', position: 'relative' }}>
            <div ref={linkRef} style={{ cursor: 'pointer' }}>
                <Persona
                    imageUrl={photo ? photo : ''}
                    size={PersonaSize.size32}
                    hidePersonaDetails
                    onClick={_toggleShowContextMenu}
                />
            </div>

            <ContextualMenu
                directionalHint={DirectionalHint.topCenter}
                isBeakVisible
                hidden={!showContextualMenu}
                target={linkRef.current}
                onItemClick={_onHideContextualMenu}
                onDismiss={_onHideContextualMenu}
                {...contextMenu}
            />
        </div>
    );
};

function AdminBadge(): JSX.Element | null {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [p, isGlobalAdmin] = usePermissions();
    const { palette } = useTheme();
    if (!isGlobalAdmin) return null;
    const style: CSSProperties = {
        position: 'absolute',
        background: palette.redDark,
        color: palette.white,
        right: -8,
        top: -8,
        padding: 5,
        width: 20,
        height: 20,
        borderRadius: '50%',
        fontSize: 10,
        lineHeight: 10,
    };

    return (
        <TooltipHost content="You are a global admin." calloutProps={{ target: '#is-admin' }}>
            <Stack style={style} horizontalAlign="center" verticalAlign="center" id="is-admin">
                <span>A</span>
            </Stack>
        </TooltipHost>
    );
}

export default Avatar;
