import * as PIXI from 'pixi.js-legacy';

export default class PixiText extends PIXI.Text {
    constructor(text: string, style?: Partial<PIXI.TextStyle>) {
        super(
            text,
            style ? {
                ...new PIXI.TextStyle({ fontFamily: 'Segoe UI', fontSize: 30, align: 'center', fontWeight: 'bold' }),
                ...style
            }
                : new PIXI.TextStyle({ fontFamily: 'Segoe UI', fontSize: 30, align: 'center', fontWeight: 'bold' })
        );
    }
}
