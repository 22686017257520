import {
    DefaultButton,
    Dropdown,
    IBaseButtonProps,
    IButtonStyles,
    IContextualMenuItem,
    IDropdownOption,
    IconButton,
    Label,
    Stack,
    TooltipHost,
    mergeStyles,
    useTheme,
    Text,
} from '@fluentui/react';
import { useId } from '@uifabric/react-hooks';
import { useSelector } from 'react-redux';
import { selectTheme } from 'state/slices/ui/ui.slice';

export type PageSelectorProps = {
    currentPage?: number;
    onChangePage?: (page: number) => void;
    onChangePageSize?: (pageSize: number) => void;
    pageSize?: number;
    pageCount?: number;
    maxDisplayedPages?: number;
    itemsPerPageOptions?: IDropdownOption<number>[];
    disabled?: boolean;

    disableNext?: boolean;
    disablePrevious?: boolean;
};

export default function PageSelector({
    pageSize = 25,
    currentPage = 1,
    maxDisplayedPages = 15,
    pageCount,
    onChangePageSize,
    onChangePage,
    itemsPerPageOptions,
    disabled,
    disableNext,
    disablePrevious,
}: PageSelectorProps) {
    const { palette } = useTheme();
    const id = useId();

    const newCurrentPage = pageCount && currentPage > pageCount ? pageCount : currentPage < 1 ? 1 : currentPage;

    const _onChangePage = (page: number) => {
        if (onChangePage && page !== currentPage) onChangePage(page);
    };
    const _onChangePageSize = (size: number) => {
        if (onChangePageSize && size !== pageSize) onChangePageSize(size);
    };

    const navigateToNextPage = () => {
        _onChangePage(newCurrentPage + 1);
    };

    const navigateToPreviousPage = () => {
        _onChangePage(newCurrentPage - 1);
    };

    function PageSelectorButtons() {
        if (!pageCount) return <PageSelectorButton pageNumber={1} disabled isSelected />;

        const itemsSet = [];
        const preItems: IContextualMenuItem[] = [];
        const overflowItemsSet: IContextualMenuItem[] = [];

        const calculatedMaxPages = maxDisplayedPages > pageCount ? pageCount : maxDisplayedPages;

        const offset = Math.ceil(calculatedMaxPages / 2);
        const initialPageCount =
            newCurrentPage > offset
                ? newCurrentPage + offset > pageCount
                    ? pageCount - calculatedMaxPages
                    : newCurrentPage - offset
                : 0;

        for (let i = 0; i < initialPageCount; i++) {
            const pageNumber = i + 1;
            const item: IContextualMenuItem = {
                key: `${id}-${pageNumber}`,
                text: pageNumber.toString(),
                onClick: () => _onChangePage(pageNumber),
            };

            preItems.push(item);
        }

        for (let i = initialPageCount; i < pageCount; i++) {
            const pageNumber = i + 1;
            const item = {
                key: `${id}-${pageNumber}`,
                text: pageNumber.toString(),
                onClick: () => _onChangePage(pageNumber),
            };
            if (pageNumber - initialPageCount > maxDisplayedPages) {
                overflowItemsSet.push(item);
            } else {
                itemsSet.push(item);
            }
        }

        const overflowIconButtonStyles: Partial<IButtonStyles> = {
            root: {
                minWidth: 0,
                padding: '0 4px',
                alignSelf: 'stretch',
                height: 'auto',
                background: 'transparent',
                color: palette.black,
            },
        };
        const pageButtons = itemsSet.map((item) => {
            const pageNumber = +item.text;
            const isPageSelected = pageNumber === newCurrentPage;

            return (
                <PageSelectorButton
                    pageNumber={pageNumber}
                    isSelected={isPageSelected}
                    key={`${id}-page-${pageNumber}`}
                    disabled
                />
            );
        });

        return (
            <Stack horizontal wrap>
                {preItems.length ? (
                    <>
                        <PageSelectorButton isSelected={1 === newCurrentPage} pageNumber={1} disabled />
                        <IconButton
                            menuIconProps={{ iconName: 'More' }}
                            title={`${preItems.length} More Pages`}
                            styles={overflowIconButtonStyles}
                            disabled
                            menuProps={{ items: preItems }}
                        />
                    </>
                ) : null}
                {pageButtons}
                {overflowItemsSet.length && (
                    <>
                        <IconButton
                            title={`${overflowItemsSet.length} More Pages`}
                            styles={overflowIconButtonStyles}
                            disabled
                            menuIconProps={{ iconName: 'More' }}
                        />
                        <PageSelectorButton isSelected={pageCount === newCurrentPage} pageNumber={pageCount} disabled />
                    </>
                )}
            </Stack>
        );
    }

    return (
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 20 }} grow wrap>
            <Stack tokens={{ childrenGap: 3 }} verticalAlign="center" horizontal>
                <TooltipHost content="Previous Page">
                    <IconButton
                        key={`${id}-previous`}
                        onClick={navigateToPreviousPage}
                        disabled={disabled || disablePrevious}
                        iconProps={{ iconName: 'ChevronLeft', style: { color: palette.neutralPrimary } }}
                    />
                </TooltipHost>
                {pageCount ? <PageSelectorButtons /> : <Text>Page {currentPage}</Text>}
                <TooltipHost content="Next Page">
                    <IconButton
                        key={`${id}-next`}
                        onClick={navigateToNextPage}
                        disabled={disabled || disableNext}
                        iconProps={{ iconName: 'ChevronRight', style: { color: palette.neutralPrimary } }}
                    />
                </TooltipHost>
            </Stack>
            <Stack verticalAlign="center" tokens={{ childrenGap: 5 }} horizontal>
                <Label>Items Per Page: </Label>
                <Dropdown
                    selectedKey={pageSize}
                    options={
                        itemsPerPageOptions ?? [
                            {
                                key: 25,
                                text: '25',
                            },
                            {
                                key: 50,
                                text: '50',
                            },
                        ]
                    }
                    disabled={disabled}
                    onChange={(ev, option) => {
                        if (option?.key) _onChangePageSize(option.key as number);
                    }}
                ></Dropdown>
            </Stack>
        </Stack>
    );
}

type PageSelectorButtonProps = Omit<IBaseButtonProps, 'text'> & {
    pageNumber: number;
    isSelected?: boolean;
};

function PageSelectorButton(props: PageSelectorButtonProps) {
    const { palette } = useTheme();
    const theme = useSelector(selectTheme);
    const { pageNumber, disabled, isSelected } = props;

    const PageSelectorButton = mergeStyles({
        background: 'transparent',
        border: 'none',
        borderBottom: `2px solid ${isSelected ? palette.themePrimary : 'transparent'}`,
        padding: 0,
        minWidth: 25,
        color: palette.black,
        '&:hover': {
            background: !disabled ? (theme === 'dark' ? palette.neutralLighter : palette.whiteTranslucent40) : undefined,
        },
    });

    return (
        <DefaultButton
            {...props}
            style={{ color: isSelected ? `${palette.themePrimary}` : '' }}
            className={PageSelectorButton}
            text={`${pageNumber}`}
        />
    );
}
