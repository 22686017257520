import IWorkListAppointmentView from 'api/models/worklist-appointment-view';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import { format } from 'date-fns';

const appointmentDate: ISortableColumn<IWorkListAppointmentView> = {
    key: 'appointmentDate',
    minWidth: 130,
    maxWidth: 130,
    isResizable: true,
    name: 'Appt. Date/Time',
    fieldName: 'startDateTime',
    onRender: (item) => (item?.startDateTime ? <span>{format(new Date(item.startDateTime), 'Pp')}</span> : null),
};

export default appointmentDate;
