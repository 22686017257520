import { ITheme, memoizeFunction, mergeStyleSets, Stack, useTheme } from '@fluentui/react';
import { TModal } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { openNoteModal } from 'state/slices/chart-notes/note-browser/note-browser.actions';
import { selectIsOpen } from 'state/slices/chart-notes/note-browser/note-browser.selectors';
import { NoteBrowserModal } from 'state/slices/chart-notes/note-browser/note-browser.slice';
import NewNoteModal from './NewNoteModal';
import NoteCancellationModal from './NoteCancellationModal';
import NoteList from './NoteList';
import NoteSignModal from './NoteSignModal';
import NoteViewer from './NoteViewer';

const tokens = { childrenGap: 10 };

function NoteBrowser() {
    const dispatch = useDispatch();
    const theme = useTheme();
    const { main, container, leftPane, scrollableContent } = getClasses(theme);
    const isOpen = useSelector(selectIsOpen);

    const onDismiss = () => dispatch(openNoteModal(NoteBrowserModal.NoteBrowser, false));

    return (
        <TModal
            title="Note Browser"
            modalProps={{ isOpen, styles: { main, scrollableContent }, onDismiss }}
            isDraggable
            hideContentOverflow
        >
            <Stack horizontal className={container} grow>
                <Stack tokens={tokens} className={leftPane}>
                    <NoteList />
                </Stack>
                <Stack grow>
                    <NoteViewer />
                </Stack>
            </Stack>
            <NewNoteModal />
            <NoteCancellationModal />
            <NoteSignModal />
        </TModal>
    );
}

const classNames = {
    main: 'note-browser__layer',
    scrollableContent: 'note-browser__scrollable-content',
    container: 'note-browser__container',
    leftPane: 'note-browser__left-pane',
};

const getClasses = memoizeFunction((theme: ITheme) =>
    mergeStyleSets({
        main: [classNames.main, { width: '90%', height: '80%' }],
        scrollableContent: [classNames.scrollableContent, { height: '100%' }],
        container: [classNames.container, { height: '100%', flex: 1 }],
        leftPane: [
            classNames.leftPane,
            {
                paddingLeft: 10,
                paddingTop: 10,
                paddingRight: 10,
                backgroundColor: theme.palette.neutralLighterAlt,
                height: '100%',
                borderRight: `1px solid ${theme.palette.neutralLight}`,
                minWidth: 250,
                overflowY: 'auto',
            },
        ],
    }),
);

export default NoteBrowser;
