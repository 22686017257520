import { DocumentBase } from 'api/document-base.model';
import LookupBase from './lookup-base.model';

export interface IClinicalAlert extends DocumentBase {
    type: ClinicalAlertType;
    patientId: string;
    note?: string;
    references?: Record<string, string>;
}

export type IClinicalAlerts = Record<string, IClinicalAlert>;

export enum ClinicalAlertType {
    PatientAlert = 'patientAlert',
    PreMedication = 'preMedication',
}

export const clinicalAlertTypeDisplayName: Record<ClinicalAlertType, string> = {
    [ClinicalAlertType.PreMedication]: 'Pre-medication',
    [ClinicalAlertType.PatientAlert]: 'Patient',
};

export type IClinicalAlertType = LookupBase & { id: ClinicalAlertType };

export type ClinicalAlertTypeLookup = Record<string, IClinicalAlertType>;
