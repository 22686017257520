import { PayloadAction } from '@reduxjs/toolkit';
import { IChartTreatmentPlan } from 'api/models/treatment-plan.model';
import { ChartingState } from '../../chart/chart.slice';
import { IChartAction } from '../../chartActionsList.pipeline';

export const reducers = {
    setPrintPredeterminationOpen: (state: ChartingState, { payload: isOpen }: PayloadAction<boolean>) => {
        state.treatmentPlans.printPredetermination.isOpen = isOpen;
    },
    setPrintPredeterminationSelectedTreatmentPlan: (
        state: ChartingState,
        { payload: treatmentPlan }: PayloadAction<IChartTreatmentPlan | undefined>,
    ) => {
        state.treatmentPlans.printPredetermination.selectedTreatmentPlan = treatmentPlan;
    },
    selectPrintPredeterminationChartActions: (
        state: ChartingState,
        { payload: chartActions }: PayloadAction<IChartAction[] | undefined>,
    ) => {
        state.treatmentPlans.printPredetermination.selectedChartActions = chartActions;
    },
    setPrintPredeterminationDropdownValues: (
        state: ChartingState,
        { payload }: PayloadAction<{ path: 'loc' | 'provider'; value: string }>,
    ) => {
        const { path, value } = payload;
        switch (path) {
            case 'loc': {
                state.treatmentPlans.printPredetermination.locId = value;
                break;
            }
            case 'provider': {
                state.treatmentPlans.printPredetermination.providerId = value;
                break;
            }
        }
    },
    cleanupPrintPredetermination: (state: ChartingState) => {
        state.treatmentPlans.printPredetermination.isOpen = false;
        state.treatmentPlans.printPredetermination.locId = undefined;
        state.treatmentPlans.printPredetermination.providerId = undefined;
        state.treatmentPlans.printPredetermination.selectedTreatmentPlan = undefined;
        state.treatmentPlans.printPredetermination.selectedChartActions = undefined;

    }
};
