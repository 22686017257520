import { createSelector } from 'reselect';
import { RootState } from 'state/store';
import { IClinicalNoteReportsState } from './clinical-notes-reports.state';

export const clinicalNoteReportsState = (state: RootState): IClinicalNoteReportsState => state.reporting.clinicalNoteReports;

export const clinicalNoteReportPanel = createSelector(clinicalNoteReportsState, (state) => state.clinicalNoteReportPanel);

export const currentlySelectedClinicalNoteReport = createSelector(
    clinicalNoteReportsState,
    (state) => state.currentlySelectedClinicalNoteReport,
);

export const listOfQueuedClincalNoteReports = createSelector(
    clinicalNoteReportsState,
    (state) => state.queuedClincalNoteReports ?? [],
);
