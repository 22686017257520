import { DocumentContext } from 'api/models/document.model';
import IDocumentType from 'api/models/documentType.model';
import { DocumentItemReference } from 'components/Upload';
import { map, sortBy } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'state/store';

export const documentsState = (state: RootState) => state.documents;
export const documentType = (state: RootState) => state.documents.documentType;

export const documentTypeList = createSelector(documentType, (state) => sortBy(map(state) as IDocumentType[], 'order'));
export const documentsData = createSelector(documentsState, (state) =>
    state.data ? state.data.filter((doc) => !doc.isDeleted) : [],
);

export const documentsLoading = createSelector(documentsState, (state) => state.loading);
export const documentsUploading = createSelector(documentsState, (state) => state.uploading);

export const selectPendingDocuments = createSelector(
    documentsState,
    (_: RootState, context: { context: DocumentContext; reference?: DocumentItemReference }) => context,
    (state, { context }) => {
        return state.pendingDocuments.filter((doc) => doc.context === context);
    },
);

export const selectPatientDocumentsByContext = createSelector(
    documentsData,
    (_: RootState, context: { context: DocumentContext; reference?: DocumentItemReference }) => context,
    (documents, { context, reference }) => {
        const contextualDocuments = documents.filter((document) => document.context === context);

        if (reference) {
            const { type, id } = reference;
            return contextualDocuments.filter((doc) => (doc.references ? doc.references[type] === id : false));
        }

        return contextualDocuments;
    },
);

export const selectDocumentTypesByContext = createSelector(
    documentTypeList,
    (_: RootState, context: DocumentContext) => context,
    (documentTypes, context) => documentTypes.filter((type) => type.category === context),
);
