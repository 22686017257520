import { Dictionary, SerializedError } from '@reduxjs/toolkit';
import { INegativeReversal } from 'api/models/negative-reversal-reason.model';
import { PaymentMethod } from 'api/models/patient.model';
import { IPatientPaymentHistoryView } from 'api/models/payment-history-model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { PaymentHistoryTableView } from './payment-history.selectors';

export type LedgerPaymentHistoryState = {
    loading: LoadingStatus;
    loadingNegativeReversalReasons: LoadingStatus;
    reversalSaving: LoadingStatus;
    filters: PaymentHistoryFilters;
    data?: IPatientPaymentHistoryView;
    paymentHistoryError?: SerializedError;
    modalOpen: boolean;
    confirmModalOpen: boolean;
    selectedPaymentSources: PaymentHistoryTableView;
    showReversedPayments: boolean;
    negativeReversalReasonId?: string;
    negativeReversalNote?: string;
    negativeReversals?: Dictionary<INegativeReversal>;
};

export type PaymentHistoryFilters = {
    dateOfEntry: string;
    dateOfService: string;
    method: PaymentMethod | '';
    methodIdentifier: string;
    transactionDate: string;
};

export const paymentHistoryInitialState: LedgerPaymentHistoryState = {
    loading: LoadingStatus.Idle,
    reversalSaving: LoadingStatus.Idle,
    loadingNegativeReversalReasons: LoadingStatus.Idle,
    filters: {
        dateOfEntry: '',
        dateOfService: '',
        method: '',
        methodIdentifier: '',
        transactionDate: '',
    },
    data: undefined,
    selectedPaymentSources: [],
    confirmModalOpen: false,
    showReversedPayments: false,
    modalOpen: false,
};
