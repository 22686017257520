import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from 'state/store';
import { updateLookups } from './lookups.reducers';
import { updateLookupItem } from './lookups.slice';

let updateLookupItemTimer: NodeJS.Timeout | null = null;
let updateLookupItemIdList: string[] = [];

export const setLookupEntityAndSave =
    ({
        value,
        entityId,
        tenantId,
        propPath,
        lookupType,
    }: {
        propPath: string;
        value: any;
        entityId: string;
        tenantId: string;
        lookupType: string;
    }) =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>, getState: () => RootState) => {
        await dispatch(updateLookupItem({ tenantId, lookupType, entityId, value, propPath }));
        const isAlreadyUpdatingEntity = updateLookupItemIdList.findIndex((i) => i === entityId) > -1;
        if (!isAlreadyUpdatingEntity) updateLookupItemIdList.push(entityId);
        if (updateLookupItemTimer) clearTimeout(updateLookupItemTimer);
        updateLookupItemTimer = setTimeout(() => {
            const entities = updateLookupItemIdList.map((id) => ({
                tenantId,
                lookupType,
                entity: { item: getState().lookups.tenants[tenantId][lookupType].items[id] },
            }));
            if (entities) dispatch(updateLookups(entities));
            updateLookupItemIdList = [];
            updateLookupItemTimer = null;
        }, 2000);
    };
