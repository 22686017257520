import { EncounterSummaryWithLineItems } from 'api/models/encounter-ledger.model';
import { FormEvent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { distributeAndUpdatePatientPaymentTransactions } from 'state/slices/ledger/patient-payments-and-adjustments/patient-payments-and-adjustments.actions';
import BaseTransactionField from '../components/BaseTransactionField';
import { getTransactionAmount } from '../LedgerUtils';
import { TransactionLookup } from 'state/slices/ledger/patient-payments-and-adjustments/patient-payments-and-adjustments.state';

type Props = {
    encounterSummary: EncounterSummaryWithLineItems;
    paymentAmount: number;
    remainingPaymentAmount: number;

    overpayment?: boolean;

    transactions: TransactionLookup;
    adjustmentTransactions?: TransactionLookup;

    feeProp?: 'patientEstimate' | 'commonPatientFee';
};

const totalFeePropMapping: Record<string, 'patientEstimate' | 'patientBalance'> = {
    patientEstimate: 'patientEstimate',
    commonPatientFee: 'patientBalance',
};

export default function PaymentEncounterTransactionField({
    encounterSummary,
    paymentAmount,
    remainingPaymentAmount,
    transactions,
    adjustmentTransactions,
    overpayment,
    feeProp = 'commonPatientFee',
}: Props) {
    const dispatch = useDispatch();

    const totalTransactionAmount = useCallback(() => {
        const procedures = encounterSummary.procedureSummaries?.filter((p) => p[feeProp] > 0);
        const encounterId = encounterSummary.encounterId ?? '';
        const encounterPaymentTransactionAmounts = procedures?.map(
            (procedure) => transactions[encounterId]?.[procedure.patientTransactionId ?? '']?.amount ?? 0,
        );
        const encounterAdjustmentTransactionAmounts = procedures?.map((procedure) =>
            adjustmentTransactions
                ? adjustmentTransactions[encounterId]?.[procedure.adjustmentTransactionId ?? '']?.amount ?? 0
                : 0,
        );

        return {
            paymentTransaction: encounterPaymentTransactionAmounts?.reduce((t1, t2) => t1 + t2, 0),
            adjustmentTransactions: encounterAdjustmentTransactionAmounts?.reduce((t1, t2) => t1 + t2, 0),
        };
    }, [encounterSummary, transactions, adjustmentTransactions, feeProp]);

    const _onChangeAmount = (e?: FormEvent, input?: number) => {
        const { adjustmentTransactions, paymentTransaction } = totalTransactionAmount();

        const feeFieldMapping = totalFeePropMapping[feeProp];
        const patientFee = encounterSummary[feeFieldMapping] - (adjustmentTransactions ?? 0);

        const amount = getTransactionAmount({
            input,
            patientFee,
            remainingPaymentAmount,
            transactionAmount: paymentTransaction,
        });

        dispatch(distributeAndUpdatePatientPaymentTransactions(amount, encounterSummary, feeFieldMapping));
    };

    const disabled = overpayment || !paymentAmount;

    return (
        <BaseTransactionField
            transactionAmount={totalTransactionAmount().paymentTransaction}
            max={encounterSummary[totalFeePropMapping[feeProp]]}
            onChange={_onChangeAmount}
            disabled={disabled}
        />
    );
}
