import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { LedgerState } from '../ledger.state';
import {
    convertOveragesToUAC,
    getUACDistributionTransactionAndPaymentSource,
    saveUACDistributionTransactions,
} from './uac-distribution.actions';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { UACDistributionOpenSections, uacDistributionInitOpenSections } from './uac-distribution.state';
import { MessageBarType } from '@fluentui/react';

export const uacDistributionReducers = {
    cleanupUACDistribution: (state: LedgerState): void => {
        state.uacDistribution.paymentSource = undefined;
        state.uacDistribution.transaction = undefined;
        state.uacDistribution.loading = LoadingStatus.Idle;
        state.uacDistribution.saving = LoadingStatus.Idle;
        state.uacDistribution.openSections = uacDistributionInitOpenSections;

        state.patientPaymentsAndAdjustments.allowOverpaymentOrAdjustment = false;
        state.patientPaymentsAndAdjustments.paymentsModified = false;
        state.patientPaymentsAndAdjustments.estimatePaymentsModified = false;

        state.patientTreatmentPlanCreditAndUAC.allowOverpayment = false;
        state.patientTreatmentPlanCreditAndUAC.modified = false;
    },
    setPaymentSourceIdToConvertToUAC: (state: LedgerState, { payload }: PayloadAction<string>): void => {
        state.uacDistribution.transactionToConvertId = payload;
        state.uacDistribution.convertModalOpen = true;
    },
    setConvertUACModalOpen: (state: LedgerState, { payload }: PayloadAction<boolean>): void => {
        state.uacDistribution.convertModalOpen = payload;
    },
    cleanupConvertUACModal: (state: LedgerState): void => {
        state.uacDistribution.transactionToConvertId = undefined;
        state.uacDistribution.saving = LoadingStatus.Idle;
    },
    setUACDistributionSectionOpen: (state: LedgerState, action: PayloadAction<keyof UACDistributionOpenSections>): void => {
        state.uacDistribution.openSections[action.payload] = !state.uacDistribution.openSections[action.payload];
    },
};

export const uacDistributionExtraReducers = (
    builder: ActionReducerMapBuilder<LedgerState>,
): ActionReducerMapBuilder<LedgerState> =>
    builder
        .addCase(convertOveragesToUAC.pending, (state) => {
            state.uacDistribution.saving = LoadingStatus.Pending;
        })
        .addCase(
            convertOveragesToUAC.fulfilled,
            (
                state,
                {
                    meta: {
                        arg: { transaction },
                    },
                },
            ) => {
                //We have to use the args here because the transaction returned is a new transaction, not the original. (It has a new id)
                if (state.patientTreatmentPlanCreditAndUAC.procedureCreditTransactions) {
                    const indexOfTransaction = state.patientTreatmentPlanCreditAndUAC.procedureCreditTransactions?.findIndex(
                        (t) => t.id === transaction.id,
                    );

                    if (indexOfTransaction > -1)
                        state.patientTreatmentPlanCreditAndUAC.procedureCreditTransactions = [
                            ...state.patientTreatmentPlanCreditAndUAC.procedureCreditTransactions.slice(0, indexOfTransaction),
                            ...state.patientTreatmentPlanCreditAndUAC.procedureCreditTransactions.slice(indexOfTransaction + 1),
                        ];
                }

                state.patientTreatmentPlanCreditAndUAC.messageBarMessage =
                    'Applied credit balance has been converted to a UAC successfully.';
                state.patientTreatmentPlanCreditAndUAC.messageBarType = MessageBarType.success;

                state.uacDistribution.saving = LoadingStatus.Completed;
                state.uacDistribution.convertModalOpen = false;
            },
        )
        .addCase(convertOveragesToUAC.rejected, (state) => {
            state.uacDistribution.saving = LoadingStatus.Failed;
        })
        .addCase(getUACDistributionTransactionAndPaymentSource.pending, (state) => {
            state.uacDistribution.loading = LoadingStatus.Pending;
        })
        .addCase(getUACDistributionTransactionAndPaymentSource.fulfilled, (state, { payload }) => {
            const { transaction, paymentSource } = payload;
            state.uacDistribution.paymentSource = paymentSource;
            state.uacDistribution.transaction = transaction;
            state.uacDistribution.loading = LoadingStatus.Completed;
        })
        .addCase(getUACDistributionTransactionAndPaymentSource.rejected, (state) => {
            state.uacDistribution.loading = LoadingStatus.Failed;
        })
        .addCase(saveUACDistributionTransactions.pending, (state) => {
            state.uacDistribution.saving = LoadingStatus.Pending;
        })
        .addCase(saveUACDistributionTransactions.fulfilled, (state, { payload, meta }) => {
            //If our payments exist we want to update those items.
            if (state.patientTreatmentPlanCreditAndUAC.unnappliedPayments?.payments) {
                //Find the index of the uac transaction
                const indexOfTransaction = state.patientTreatmentPlanCreditAndUAC.unnappliedPayments.payments.findIndex(
                    (t) => t.id === meta.arg.uacTransactionId,
                );
                //If the uac transaction can be found in state.
                if (indexOfTransaction > -1) {
                    const updatedUACTransaction = payload.find((t) => t.id === meta.arg.uacTransactionId);
                    if (updatedUACTransaction) {
                        //If the uac transaction can be found in the returned payload, update it.
                        state.patientTreatmentPlanCreditAndUAC.unnappliedPayments.payments[indexOfTransaction] =
                            updatedUACTransaction;
                    } else {
                        //Otherwise remove the uac transaction.
                        state.patientTreatmentPlanCreditAndUAC.unnappliedPayments.payments = [
                            ...state.patientTreatmentPlanCreditAndUAC.unnappliedPayments.payments.slice(0, indexOfTransaction),
                            ...state.patientTreatmentPlanCreditAndUAC.unnappliedPayments.payments.slice(indexOfTransaction + 1),
                        ];
                    }
                }
            }
            state.patientTreatmentPlanCreditAndUAC.messageBarMessage = 'UAC has been applied successfully.';
            state.patientTreatmentPlanCreditAndUAC.messageBarType = MessageBarType.success;

            state.uacDistribution.saving = LoadingStatus.Completed;
        })
        .addCase(saveUACDistributionTransactions.rejected, (state) => {
            state.uacDistribution.saving = LoadingStatus.Failed;
        });
