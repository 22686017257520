import { PayloadAction } from '@reduxjs/toolkit';
import { ApplicableArea, ICondition } from 'api/models/lookup.model';
import { ToothArea } from 'api/models/tooth-area';
import { forEach } from 'lodash';
import { ChartingState } from '../chart/chart.slice';
import { EditConditionPanelPayload } from './condition-panel.actions';

const conditionPanelReducers = {
    setConditionPanelEditItem: (state: ChartingState, action: PayloadAction<EditConditionPanelPayload>): void => {
        const { condition, chartCondition, date, note } = action.payload;

        const selectedTeeth = chartCondition?.toothId ? [chartCondition.toothId] : [];
        state.conditionPanel.condition = condition;
        state.conditionPanel.selectedChartCondition = chartCondition;
        state.conditionPanel.isOpen = true;
        state.conditionPanel.isEditing = true;
        state.conditionPanel.selectedTeeth = selectedTeeth;
        state.conditionPanel.note = note;
        state.conditionPanel.date = date;

        selectedTeeth.forEach((tooth) => {
            state.conditionPanel.panelTeethData[tooth] = { surfaces: chartCondition.areas ?? [] };
        });
    },

    cleanupConditionPanel: (state: ChartingState): void => {
        state.conditionPanel.isEditing = false;
        state.conditionPanel.condition = undefined;
        state.conditionPanel.note = undefined;
        state.conditionPanel.date = undefined;
        state.conditionPanel.panelTeethData = {};
        state.conditionPanel.selectedTeeth = [];
        state.conditionPanel.selectedChartCondition = undefined;
    },
    setCondition: (state: ChartingState, action: PayloadAction<ICondition | undefined>): void => {
        state.conditionPanel.condition = action.payload;

        if (action.payload?.applicableArea !== (ApplicableArea.Surface as string))
            forEach(state.conditionPanel.panelTeethData, (data, tooth) => {
                state.conditionPanel.panelTeethData[+tooth].surfaces = [];
            });
    },
    setConditionPanelOpen: (state: ChartingState, action: PayloadAction<boolean>): void => {
        const isOpen = action.payload;
        state.conditionPanel.isOpen = isOpen;
        state.conditionPanel.selectedTeeth = state.ui.selectedTeeth;
        if (!isOpen) {
            state.conditionPanel.selectedChartCondition = undefined;
        }

        const selectedTeethData = state.conditionPanel.panelTeethData;
        forEach(state.ui.selectedTeeth, (tooth) => {
            state.conditionPanel.panelTeethData[tooth] = selectedTeethData[tooth] ? selectedTeethData[tooth] : { surfaces: [] };
        });
    },
    setConditionPanelSelectedTeeth: (state: ChartingState, action: PayloadAction<number[]>): void => {
        const selectedTeeth = action.payload;
        state.conditionPanel.selectedTeeth = selectedTeeth;

        const selectedTeethData = state.conditionPanel.panelTeethData;
        forEach(state.conditionPanel.selectedTeeth, (tooth) => {
            state.conditionPanel.panelTeethData[tooth] = selectedTeethData[tooth] ? selectedTeethData[tooth] : { surfaces: [] };
        });

        forEach(state.conditionPanel.panelTeethData, (data, tooth) => {
            if (!state.conditionPanel.selectedTeeth.includes(+tooth)) delete state.conditionPanel.panelTeethData[+tooth];
        });
    },
    setConditionPanelSelectedSurfaces: (
        state: ChartingState,
        action: PayloadAction<{ surfaces: (keyof typeof ToothArea)[]; tooth: number }>,
    ): void => {
        const surfaces = action.payload.surfaces;
        state.conditionPanel.panelTeethData[action.payload.tooth];
        state.conditionPanel.panelTeethData[action.payload.tooth].surfaces = surfaces;
    },

    setCondPanelNotes: (state: ChartingState, action: PayloadAction<string | undefined>): void => {
        const notes = action.payload;
        state.conditionPanel.note = notes;
    },
};

export default conditionPanelReducers;
