import { Stack, Label, useTheme } from '@fluentui/react';
import React from 'react';

type Props = {
    heading: string;
};

export default function ClaimsHistoryHeader({ heading }: Props) {
    const { palette } = useTheme();
    return (
        <Stack style={{ backgroundColor: palette.neutralLighterAlt, padding: 5, paddingTop: 2, paddingBottom: 2 }}>
            <Label>{heading}</Label>
        </Stack>
    );
}
