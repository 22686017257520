import { createSelector, Dictionary } from '@reduxjs/toolkit';
import { IPatientEncounter, IEncounterHistory } from 'api/models/encounter.model';
import IPatient, { PatientStatus } from 'api/models/patient.model';
import IPatientAppointment from 'api/models/Scheduling/patientAppointment.model';
import { LoadingStatus, LoadingStatuses } from 'interfaces/loading-statuses';
import { SyncStatus } from 'interfaces/syncing-statuses';
import { RootState } from 'state/store';
import { isDateBetween } from 'utils/isDateBetween';
import { selectPatientEncounter } from '../encounter/encounter.selectors';
import { calculateAgeInYears } from 'utils/dateOnly';
import { forEach } from 'lodash';
import { selectTenantPayersData } from '../tenant/tenant-payers.slice';

// Selected Patient
export const selectSelectedPatient = (state: RootState): IPatient | undefined => state.patient.selectedPatient;
export const selectPatientState = (state: RootState) => state.patient;

export const selectSelectedPatientDefaultPharmacy = createSelector(selectSelectedPatient, (patient) => patient?.defaultPharmacy);

//PATIENT INSURANCE
export const primaryPatientInsurance = createSelector(selectSelectedPatient, (patient) =>
    patient?.insurances?.length ? patient.insurances.filter((insurance) => !insurance.isDeleted)[0] : undefined,
);
export const primaryPatientPayer = createSelector([primaryPatientInsurance, selectTenantPayersData], (insurance, payerData) =>
    insurance?.insuranceId && payerData ? payerData[insurance.insuranceId] : undefined,
);

export const selectActivePrimaryPatientInsurance = createSelector(
    [primaryPatientInsurance, selectPatientEncounter],
    (insurance, encounter) => {
        const patientInsuranceIsActive = isDateBetween({
            dateToCheck: encounter?.createdOn,
            start: insurance?.effectiveDate,
            end: insurance?.expirationDate,
        });

        return patientInsuranceIsActive ? insurance : undefined;
    },
);

export const selectPrimaryPatientEligibility = createSelector(selectActivePrimaryPatientInsurance, (insurance) => {
    return insurance?.eligibility;
});

// Patients List
export const selectPatients = (state: RootState): IPatient[] => state.patient.patients.data;
export const selectPatientsLoading = (state: RootState): LoadingStatuses => state.patient.patients.loading;

export const patientLoading = (state: RootState): LoadingStatuses => state.patient.loading;
export const patientSaving = (state: RootState): SyncStatus => state.patient.saving;

// Patient Encounters
export const allPatientEncounters = (state: RootState): IPatientEncounter[] => state.patient.encounters.data ?? [];
export const allPatientEncountersLookup = createSelector(allPatientEncounters, (encounters) => {
    const encounterLookup: Record<string, IPatientEncounter> = {};
    encounters.forEach((encounter) => {
        encounterLookup[encounter.id] = encounter;
    });

    return encounterLookup;
});

export const patientEncounterLookup = createSelector(allPatientEncounters, (encounters) => {
    const lookup: Record<string, IPatientEncounter> = {};
    encounters.forEach((encounter) => {
        lookup[encounter.id] = encounter;
    });
    return lookup;
});

export const createNewEncounterLoadingLookup = (state: RootState): Dictionary<LoadingStatuses> =>
    state.patient.encounters.createNewEncounterLoading;

export const isAnyCreateNewEncounterLoadingPending = createSelector(createNewEncounterLoadingLookup, (lookup) => {
    let isLoading = false;
    forEach(lookup, (encounterLoading) => {
        if (encounterLoading === LoadingStatus.Pending) isLoading = true;
    });

    return isLoading;
});

export const allPatientEncounteresLoading = (state: RootState): LoadingStatuses => state.patient.encounters.loading;
// Selected Patient Past Encounters
export const selectSelectedPatientPastEncounters = (state: RootState): IEncounterHistory[] | undefined =>
    state.patient.pastEncounters.data?.filter(
        (encounter) =>
            !encounter.isDeleted ||
            isDateBetween({
                dateToCheck: new Date().toISOString(),
                start: encounter.date,
            }),
    );

// Patient Appointments
export const currentAndFuturePatientAppointments = (state: RootState): IPatientAppointment[] =>
    state.patient.currentAndFutureAppointments?.patients ?? [];

export const selectPatientStatusIsMerged = createSelector(
    selectSelectedPatient,
    (patient) => patient?.patientStatus === PatientStatus.Merged,
);
export const selectPatientStatusIsDeleted = createSelector(
    selectSelectedPatient,
    (patient) => patient?.patientStatus === PatientStatus.Deleted,
);

export const selectPatientIsMergedOrDeleted = createSelector(
    selectPatientStatusIsMerged,
    selectPatientStatusIsDeleted,
    (merged, deleted) => merged || deleted,
);

export const selectMergedPatientId = createSelector(selectSelectedPatient, (patient) => {
    return patient?.references?.mergedPatientToId_Elwood;
});
export const selectMergedPatientMrn = createSelector(selectSelectedPatient, (patient) => {
    return patient?.references?.mergedPatientToMrn_athenaOne;
});

export const selectPatientAge = createSelector(selectSelectedPatient, (patient) => {
    return calculateAgeInYears(patient?.dateOfBirth);
});
