import { Dropdown, IDropdownOption, Spinner, useTheme } from '@fluentui/react';
import { usePatientId, useSelector, useTenantId } from 'hooks';
import { encounterReasonsAsOptions } from 'state/slices/encounter-reasons/encounter-reasons.selectors';
import { isAfter, isToday } from 'date-fns';
import { setCurrentEncounterDataPropAndSave, updatePatientEncounterReason } from 'state/slices/encounter/encounter.actions';
import useEncounterId from 'hooks/useEncounterId';
import { useDispatch } from 'react-redux';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { EncounterStatus } from 'api/models/encounter.model';
import { encounterState, selectPatientEncounter, selectPatientEncounterAppointment } from 'state/slices/encounter/encounter.selectors';

function EncounterReasons(): JSX.Element {
    const dispatch = useDispatch();

    const tenantId = useTenantId();
    const encounterId = useEncounterId();
    const patientId = usePatientId();

    const theme = useTheme();

    const encounterReasons = useSelector(encounterReasonsAsOptions);
    const encounter = useSelector(selectPatientEncounter);
    const appointment = useSelector(selectPatientEncounterAppointment);
    const { loadingPatientEncounterAppointment } = useSelector(encounterState);

    const patient = useSelector((state) => state.patient);

    const _loading = loadingPatientEncounterAppointment === LoadingStatus.Pending;

    const encounterReasonKey = (reason: string) => {
        return encounterReasons.find((res) => res.text === reason);
    };

    const presetEncounterReason = () => {
        const todaysDate = new Date();

        const futureEncounter = patient.currentAndFutureAppointments?.patients?.filter((res) => {
            const patientDate = new Date(`${res.date}T${res.startTime}`);
            if (isToday(patientDate)) {
                return res;
            } else {
                return isAfter(patientDate, todaysDate);
            }
        });
        const firstValue = futureEncounter?.filter((res) => {
            return res.encounterReason?.length;
        });
        return firstValue?.length ? firstValue[0].encounterReason : '';
    };

    const changeEncounter = (value: string) => {
        if (patientId)
            dispatch(setCurrentEncounterDataPropAndSave('encounterReason', value, tenantId, patientId));
        if (appointment && encounterId) {
            dispatch(
                updatePatientEncounterReason({
                    tenantId,
                    appointmentId: appointment.id,
                    encounterReason: value,
                    encounterId,
                }),
            );
        }
    };
    return (
        <>
            {_loading ? (
                <Spinner />
            ) : (
                <Dropdown
                    style={{ display: 'inline' }}
                    styles={{
                        title: {
                            backgroundColor: 'transparent',
                            color: theme.semanticColors.link,
                            height: 20,
                            lineHeight: 20,
                            paddingRight: 0,
                            border: 'none',
                            display: 'inline',
                            selectors: {
                                ':hover': {
                                    color: theme.semanticColors.linkHovered,
                                    textDecoration: 'underline',
                                },
                                ':focus': {
                                    border: 'none',
                                },
                                ':active': {
                                    border: 'none',
                                },
                            },
                        },
                        dropdown: {
                            selectors: {
                                ':focus .ms-Field.Dropdown-title': {},
                            },
                        },
                        caretDownWrapper: {
                            height: 15,
                            /* line-height: 20px; */
                            paddingTop: 1,
                            position: 'absolute',
                            right: -18,
                            top: 2,
                            cursor: 'pointer',
                            width: 15,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: 3,
                        },
                        caretDown: {
                            color: theme.semanticColors.link,
                        },
                        label: {
                            height: 20,
                            padding: 0,
                        },
                        root: {
                            height: 20,
                        },
                    }}
                    selectedKey={
                        encounter?.encounterReason ? encounterReasonKey(encounter.encounterReason)?.key : presetEncounterReason()
                    }
                    placeholder="Select Encounter Reason"
                    label="Reason: "
                    disabled={encounter?.status === EncounterStatus.Billed}
                    options={encounterReasons}
                    onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
                        if (option) changeEncounter(option.text);
                    }}
                />
            )}
        </>
    );
}

export default EncounterReasons;
