import { ChartConditionStatus, IChartCondition } from 'api/models/chart.model';
import { ICondition } from 'api/models/lookup.model';
import Pipeline from './pipeline';
import { v4 as uuid } from 'uuid';
import { ToothArea } from 'api/models/tooth-area';
import { toothSpriteReferences } from 'pages/Charting/components/ToothCanvas/spriteList';
import ConditionCodes from './conditionCodes';

export default class ChartingConditionsPipeline extends Pipeline<IChartCondition> {
    private conditions: ICondition[];
    private selectedTeeth: number[] = [];
    private toothAreas?: (keyof typeof ToothArea)[];
    private respectiveConditionIds?: string[];
    private encounterId?: string;

    constructor(
        conditions: ICondition[],
        selectedTeeth: number[],
        toothAreas?: (keyof typeof ToothArea)[],
        respectiveConditionIds?: string[],
        encounterId?: string,
    ) {
        super([]);
        this.conditions = conditions;
        this.selectedTeeth = selectedTeeth;
        this.toothAreas = toothAreas;
        this.respectiveConditionIds = respectiveConditionIds;
        this.encounterId = encounterId;

        this.createChartConditions();
    }

    private getTeethPosition(selectedTeeth: number[]): (number | undefined)[] {
        const positions = selectedTeeth.map((toothId) => toothSpriteReferences.find((ref) => ref.id === toothId)?.position);
        return positions;
    }

    private getIsToothPosterior(selectedTooth: number) {
        const position = this.getTeethPosition([selectedTooth])[0];
        return position ? position <= 5 || position >= 28 || (position >= 12 && position <= 21) : false;
    }

    private createChartConditions() {
        const conditions: IChartCondition[] = [];
        this.conditions.forEach((c) => {
            this.selectedTeeth.forEach((toothId, index) => {
                const newToothAreas: (keyof typeof ToothArea)[] = [];
                if (this.toothAreas?.length) {
                    this.toothAreas.forEach((area) => {
                        if (toothId) {
                            const isPosterior = this.getIsToothPosterior(toothId);
                            if (area === 'Occlusal') {
                                newToothAreas?.push(isPosterior ? 'Occlusal' : 'Incisal');
                            } else if (area === 'Facial') {
                                newToothAreas?.push(isPosterior ? 'Buccal' : 'Facial');
                            } else if (area === 'FacialVestibular') {
                                newToothAreas?.push(isPosterior ? 'BuccalVestibular' : 'FacialVestibular');
                            } else {
                                newToothAreas?.push(area);
                            }
                        } else {
                            newToothAreas?.push(area);
                        }
                    });
                } else {
                    switch (c.code) {
                        case ConditionCodes.OpenContactDistal:
                            newToothAreas.push('Distal');
                            break;
                        case ConditionCodes.OpenContactMesial:
                            newToothAreas.push('Mesial');
                            break;
                        case ConditionCodes.AbscessPeriodontalDistal:
                            newToothAreas.push('Distal');
                            break;
                        case ConditionCodes.AbscessPeriodontalMesial:
                            newToothAreas.push('Mesial');
                            break;
                    }
                }

                const chartCondition: IChartCondition = {
                    id: this.respectiveConditionIds?.length ? this.respectiveConditionIds[index] : uuid(),
                    isDeleted: false,
                    toothId,
                    conditionId: c.id,
                    areas: newToothAreas,
                    encounterId: this.encounterId,
                    status: ChartConditionStatus.Active,
                };

                conditions.push(chartCondition);
            });
        });
        this.setItems(conditions);
    }
}
