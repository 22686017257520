import {
    CommandBarButton,
    DefaultButton,
    DetailsListLayoutMode,
    IconButton,
    IContextualMenuProps,
    Link,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    SelectionMode,
    Stack,
    Text,
    TooltipHost,
    useTheme,
} from '@fluentui/react';
import IProvider, { IProviderScheduleTimeBlock, IProviderPeriod, Timetable, WeekDays, IProviderTimetable } from 'api/models/provider.model';
import { Field, SortableDetailsList, SubSection, TModal } from 'components';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import { useTenantId } from 'hooks';
import { map } from 'lodash';
import EditTimetables from 'pages/components/Period/EditTimetables';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectLocationOfCareOptions } from 'state/slices/scheduling/scheduling.selectors';
import { getLocationsOfCare } from 'state/slices/scheduling/scheduling.slice';
import {
    selectEditPeriodsIsValid,
    selectProvidersScheduleModelOpen,
    selectSelectedPeriod,
} from 'state/slices/settings/providers/providers.selectors';
import {
    addProviderSchedule,
    addRange,
    clearPeriods,
    createNewExpections,
    editExpection,
    editTimetable,
    openExceptionModal,
    openProviderModel,
    removeProviderException,
    removeProviderSchedule,
    setLOC,
    updateProviderPeriodProp,
} from 'state/slices/settings/settings.slice';
import { classicDateOnly } from 'utils/dateOnly';
import { isDateBetween } from 'utils/isDateBetween';
import ScheduleExceptionsModel from './ScheduleExceptionsModel';

export const toCapitalize = (value: string): string => `${value.charAt(0).toUpperCase()}${value.substring(1)}`;

export interface IRange {
    startTime: string;
    endTime: string;
    defaultTreatingProviderId?: string;
    defaultBillingProviderId?: string;
    defaultSupervisingProviderId?: string;
}

export interface ITimetables {
    [dayId: string]: IRange[];
}

type Props = {
    onSave: (periods: IProviderPeriod) => void;
    provider: IProvider | undefined;
};

export default function EditProviderPeriod({ onSave, provider }: Props): JSX.Element | null {
    const theme = useTheme();
    const tenantId = useTenantId();
    const dispatch = useDispatch();

    const [hasConflictError, setHasConflictError] = useState<boolean>(false);

    const isOpen = useSelector(selectProvidersScheduleModelOpen);
    const locationsOfCareOptions = useSelector(selectLocationOfCareOptions);
    const selectedEditPeriod = useSelector(selectSelectedPeriod);

    const selectedEditPeriodIsValid = useSelector(selectEditPeriodsIsValid);

    const updateDates = (path: keyof IProviderPeriod, value: unknown) => {
        dispatch(updateProviderPeriodProp({ periodId: selectedEditPeriod?.id ?? '', path, value }));
    };

    //Filter location of care options for dropdown to ensure the user cannot add a duplicate schedule for the same location of care.
    const getAvailableLocationsOfCareOptions = useCallback((schedule: IProviderTimetable) => {
        return locationsOfCareOptions.filter(loc => (schedule ? schedule.locationOfCareId === loc.key : true) || (selectedEditPeriod?.schedules?.findIndex(s => s.locationOfCareId === loc.key) ?? -1) === -1)
    }, [selectedEditPeriod])

    //Prevent the user from adding more schedules than there are available locations of care.
    const disableAddNewLocationOfCareSchedule = useMemo(() => {
        return (selectedEditPeriod?.schedules?.length ?? 0) >= locationsOfCareOptions.length
    }, [selectedEditPeriod, locationsOfCareOptions])

    const today = new Date().toISOString();


    const _renderDate = (item?: IProviderScheduleTimeBlock) => {

        const hasDatePast = item && item.startDate ? classicDateOnly(today) <= classicDateOnly(item.startDate) : false;
        if (item && hasDatePast) {
            const index = selectedEditPeriod?.exceptions?.indexOf(item);

            return (
                <Text>
                    <Link
                        onClick={() => {
                            if (item && index !== undefined) {
                                dispatch(editExpection({ exception: item, index: index }));
                                dispatch(openExceptionModal(true));
                            }
                        }}
                    >
                        {item.startDate ? classicDateOnly(item?.startDate) : 'N/A'}
                    </Link>
                </Text>
            );
        }
        return <Text> {item?.startDate ? classicDateOnly(item.startDate) : 'N/A'} </Text>;
    };

    const _deleteException = (item?: IProviderScheduleTimeBlock) => {
        const hasDatePast = item && item.startDate ? classicDateOnly(today) <= classicDateOnly(item.startDate) : false;
        if (item && hasDatePast) {
            const index = selectedEditPeriod?.exceptions?.indexOf(item);

            return (
                <TooltipHost content="Remove period.">
                    <IconButton
                        iconProps={{ iconName: 'delete' }}
                        onClick={() => {
                            if (item && index !== undefined && selectedEditPeriod) {
                                dispatch(removeProviderException({ periodId: selectedEditPeriod.id ?? '', index }));
                            }
                        }}
                    />
                </TooltipHost>
            );
        }
    };

    useEffect(() => {
        dispatch(getLocationsOfCare({ tenantId }));
    }, [dispatch, tenantId]);

    const savePeriod = () => {
        if (selectedEditPeriod) {
            onSave(selectedEditPeriod);
        }
        dispatch(openProviderModel(''));
        dispatch(clearPeriods());
    };

    const isDuiplicatePeriod =
        provider?.providerSchedule?.periods &&
        provider?.providerSchedule?.periods
            .filter((period) => selectedEditPeriod?.id !== period.id)
            .some((period) =>
                isDateBetween({
                    dateToCheck: selectedEditPeriod?.startDate,
                    start: period.startDate,
                    end: period.endDate,
                }),
            );

    const isDuplicatePeriodError = isDuiplicatePeriod ? 'This period overlaps with another period' : '';

    const disabledSave = isDuiplicatePeriod || hasConflictError || selectedEditPeriodIsValid.length ? true : false;

    const secondaryHeaderStyle = { border: 'none', padding: 0 };

    const addRangeMenuItems = (day: WeekDays) => {
        if (selectedEditPeriod?.schedules) {
            const menuProps: IContextualMenuProps = {
                items: selectedEditPeriod.schedules.map((schedule) => ({
                    key: schedule.locationOfCareId ?? '',
                    text: schedule.locationOfCareName ?? '',
                    onClick: () => {
                        if (schedule.locationOfCareId && selectedEditPeriod.id)
                            dispatch(
                                addRange({
                                    periodId: selectedEditPeriod.id,
                                    locationOfCareId: schedule.locationOfCareId,
                                    day: day,
                                }),
                            );
                    },
                })),
            };

            return menuProps;
        } else {
            return;
        }
    };

    const periodColumns: ISortableColumn<IProviderScheduleTimeBlock>[] = [
        {
            key: 'startDate',
            fieldName: 'startDate',
            name: 'Start',
            minWidth: 80,
            maxWidth: 80,
            onRender: _renderDate,
        },
        {
            key: 'endDate',
            fieldName: 'endDate',
            name: 'End',
            minWidth: 80,
            maxWidth: 80,
            onRender: (item) => <Text> {item?.startDate ? classicDateOnly(item?.startDate) : 'N/A'} </Text>,
        },
        {
            key: 'recurring',
            fieldName: 'recurring',
            name: 'Recurring',
            minWidth: 80,
            maxWidth: 80,
            onRender: (item) => <Text>{item?.recurring ? 'Yes' : 'No'}</Text>,
        },
        {
            key: 'type',
            fieldName: 'type',
            name: 'Type',
            minWidth: 80,
            maxWidth: 80,
            onRender: (item) => (
                <Text> {item?.type === 'addHours' ? 'Add Hours' : item?.type === 'block' ? 'Block Hours' : 'Emergency'}</Text>
            ),
        },
        {
            key: 'exceptionType',
            fieldName: 'exceptionType',
            name: 'Exception Type',
            minWidth: 100,
            maxWidth: 100,
            onRender: (item) => (
                <Text
                    styles={{
                        root: {
                            textTransform: 'capitalize',
                        },
                    }}
                >
                    {item?.exceptionType === 'add' ? 'Add Loc Hours' : item?.exceptionType}
                </Text>
            ),
        },

        {
            key: 'remove',
            name: 'Remove',
            minWidth: 24,
            maxWidth: 24,
            onRender: _deleteException,
        },
    ];

    return (
        <TModal
            title={'Manage Provider Hours'}
            modalProps={{
                isOpen: isOpen,
                isBlocking: true,
                onDismiss: () => {
                    dispatch(openProviderModel(''));
                },
            }}
        >
            <Stack tokens={{ padding: 30 }}>
                <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 10 }}>
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <Stack.Item>
                            <Field.Date
                                label="Start Date"
                                value={selectedEditPeriod?.startDate ?? ''}
                                hasDatePicker
                                autoComplete="off"
                                required
                                allowFutureDates={false}
                                isReasonable
                                onChange={(ev, value) => {
                                    if (value) updateDates('startDate', classicDateOnly(value));
                                }}
                                errorMessage={isDuiplicatePeriod ? isDuplicatePeriodError : ''}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <Field.Date
                                label="End Date"
                                value={selectedEditPeriod?.endDate ? selectedEditPeriod.endDate : ''}
                                onChange={(ev, value) => {
                                    if (value) updateDates('endDate', classicDateOnly(value));
                                }}
                                hasDatePicker
                                autoComplete="off"
                                required
                                allowFutureDates={false}
                                isReasonable
                            />
                        </Stack.Item>
                        <Stack verticalAlign="end">
                            <TooltipHost content={disableAddNewLocationOfCareSchedule ? "Max number of schedules reached. You may only add as many provider schedules as available locations of care." : undefined}>
                                <CommandBarButton
                                    style={{ height: 30, marginBottom: 5 }}
                                    iconProps={{ iconName: 'Add' }}
                                    text="Add location of care"
                                    disabled={disableAddNewLocationOfCareSchedule}
                                    onClick={() => {
                                        if (selectedEditPeriod?.id)
                                            dispatch(addProviderSchedule({ periodId: selectedEditPeriod.id }));
                                    }}
                                />
                            </TooltipHost>
                        </Stack>
                    </Stack>
                    {/* <DefaultButton text="Schedule Exceptions" menuProps={menuProps} allowDisabledFocus /> */}
                </Stack>

                <Stack tokens={{ childrenGap: 20 }}>
                    <Stack.Item>
                        {selectedEditPeriod?.schedules?.map((schedule, index) => {
                            const setTimetable = (timetables: Timetable) => {
                                if (selectedEditPeriod?.id && schedule.locationOfCareId)
                                    dispatch(
                                        editTimetable({
                                            periodId: selectedEditPeriod.id,
                                            locationOfCareId: schedule.locationOfCareId,
                                            timetables,
                                        }),
                                    );
                            };

                            return (
                                <Stack key={index} horizontal tokens={{ childrenGap: 5 }}>
                                    <Stack grow horizontal verticalAlign="center">
                                        <IconButton
                                            onClick={() => {
                                                dispatch(
                                                    removeProviderSchedule({
                                                        periodId: selectedEditPeriod.id ?? '',
                                                        scheduleIndex: index
                                                    }),
                                                );
                                            }}
                                            iconProps={{
                                                iconName: 'delete',
                                                styles: { root: { height: 14, width: 14, fontSize: 14 } },
                                            }}
                                        />
                                        <Field.Dropdown
                                            style={{ width: 200 }}
                                            options={getAvailableLocationsOfCareOptions(schedule)}
                                            selectedKey={schedule.locationOfCareId ?? ''}
                                            label="Location of care"
                                            onChange={(e, value) => {
                                                dispatch(
                                                    setLOC({ periodId: selectedEditPeriod.id ?? '', index, loc: value?.data }),
                                                );
                                            }}
                                        />
                                    </Stack>

                                    <Stack tokens={{ childrenGap: 5 }} horizontal horizontalAlign="center">
                                        {map(WeekDays, (day) => (
                                            <SubSection
                                                key={day}
                                                title={index === 0 ? toCapitalize(day) : ''}
                                                style={{
                                                    border: `1px solid ${theme.palette.neutralLighter}`,
                                                    borderRadius: 2,
                                                }}
                                                headerStyle={index >= 1 ? secondaryHeaderStyle : undefined}
                                                labelStyle={{ fontSize: 14 }}
                                                headingEndContent={
                                                    index === 0 && (
                                                        <Stack horizontal tokens={{ childrenGap: 5 }}>
                                                            <TooltipHost content="Add Range">
                                                                <IconButton
                                                                    menuProps={addRangeMenuItems(day)}
                                                                    iconProps={{
                                                                        iconName: 'Add',
                                                                        styles: {
                                                                            root: { height: 14, width: 14, fontSize: 14 },
                                                                        },
                                                                    }}
                                                                    disabled={!schedule.locationOfCareId}
                                                                />
                                                            </TooltipHost>
                                                        </Stack>
                                                    )
                                                }
                                            >
                                                <Stack
                                                    styles={{
                                                        root: {
                                                            width: 270,
                                                        },
                                                    }}
                                                >
                                                    {schedule?.timeTables && schedule?.timeTables[day]?.length ? (
                                                        <>
                                                            <EditTimetables
                                                                day={day}
                                                                timetables={schedule?.timeTables}
                                                                setTimetables={setTimetable}
                                                                setHasConflictError={setHasConflictError}
                                                            />
                                                        </>
                                                    ) : (
                                                        <Stack horizontal wrap>
                                                            <MessageBar>
                                                                No hours have been added for{' '}
                                                                {`${schedule?.locationOfCareName
                                                                    ? schedule?.locationOfCareName
                                                                    : schedule.locationOfCareId
                                                                    }`}
                                                                .
                                                            </MessageBar>
                                                        </Stack>
                                                    )}
                                                </Stack>
                                            </SubSection>
                                        ))}
                                    </Stack>
                                </Stack>
                            );
                        })}
                    </Stack.Item>

                    <Stack.Item>
                        {selectedEditPeriod?.exceptions && selectedEditPeriod?.exceptions.length > 0 && (
                            <Stack tokens={{ childrenGap: 5 }}>
                                <Text variant="large">Schedule Exceptions </Text>
                                <SortableDetailsList<IProviderScheduleTimeBlock>
                                    compact={true}
                                    items={selectedEditPeriod?.exceptions}
                                    columns={periodColumns}
                                    layoutMode={DetailsListLayoutMode.justified}
                                    selectionMode={SelectionMode.none}
                                    sortOnMount={true}
                                    sortColumns={['startDate']}
                                />
                            </Stack>
                        )}
                    </Stack.Item>
                </Stack>
                <ScheduleExceptionsModel provider={provider} />
            </Stack>

            <Stack>
                {selectedEditPeriodIsValid.length && (
                    <MessageBar messageBarType={MessageBarType.blocked}>
                        <ul style={{ margin: 0 }}>
                            {selectedEditPeriodIsValid.map((error) => (
                                <li key={error}>{error}</li>
                            ))}
                        </ul>
                    </MessageBar>
                )}

                <Stack horizontal tokens={{ childrenGap: 10 }} style={{ padding: 24 }}>
                    <PrimaryButton
                        text="Save"
                        title="Save"
                        onClick={() => {
                            savePeriod();
                        }}
                        disabled={disabledSave}
                    />
                    <DefaultButton
                        text="Cancel"
                        title="Cancel"
                        onClick={() => {
                            dispatch(clearPeriods());
                            dispatch(openProviderModel(''));
                        }}
                    />
                </Stack>
            </Stack>
        </TModal>
    );
}
