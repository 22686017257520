import { DefaultButton, Label, Separator, Stack } from '@fluentui/react';
import { FormName } from 'api/models/form';
import { usePatientPrescriptionsPanel } from 'hooks';
import useForms from 'hooks/store/useForms';
import useEncounterId from 'hooks/useEncounterId';
import AnteriorComposite from './AnteriorComposite';
import PosteriorComposite from './PosteriorComposite';

export default function InstantQuickMenu() {
    const { setNewForm } = useForms();
    const { setPrescriptionsPanelOpen } = usePatientPrescriptionsPanel();


    const encounterId = useEncounterId();

    const _statusDisabled = !encounterId;

    return (
        <Stack>
            <Label>Posterior Composite</Label>
            <Stack grow>
                <PosteriorComposite />
            </Stack>
            <Label>Anterior Composite</Label>
            <Stack>
                <AnteriorComposite />
                <Stack grow>
                    <Separator />
                    <Stack horizontal tokens={{ childrenGap: 5 }}>
                        <DefaultButton
                            style={{
                                maxWidth: 'fit-content',
                            }}
                            onClick={() => setNewForm(FormName.SoftTissueExam)}
                        >
                            Soft Tissue Exam
                        </DefaultButton>
                        <DefaultButton
                            style={{
                                maxWidth: 'fit-content',
                            }}
                            onClick={() => setPrescriptionsPanelOpen(true)}
                            disabled={_statusDisabled}
                        >
                            +Rx
                        </DefaultButton>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
}
