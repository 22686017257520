import { Checkbox, Stack, TextField } from '@fluentui/react';
import IPatient, { IPatientAddress } from 'api/models/patient.model';
import { Field } from 'components';
import states from 'components/Field/StateOptions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'hooks';
import { selectEditPatient, selectEditPatientValidationErrors } from 'state/slices/edit-patient/edit-patient.selectors';
import { editPatientAddressPropChange, editPatientPropChange } from 'state/slices/edit-patient/edit-patient.slice';
import { getValidationError } from 'hooks/useValidation';
import { BasicDetailsFields } from '../BasicDetails';
import { updatePatientAppointmentProp } from 'state/slices/scheduling/scheduling.slice';
import IPatientAppointment from 'api/models/Scheduling/patientAppointment.model';
import { selectSelectedAppointmentData } from 'state/slices/scheduling/scheduling.selectors';

type Props = {
    isCheckinCheckout?: boolean;
};

function PatientAddress({ isCheckinCheckout }: Props): JSX.Element {
    const dispatch = useDispatch();
    const patient = useSelector(selectEditPatient);
    const validationErrors = useSelector(selectEditPatientValidationErrors);
    const _currentAppointment = useSelector(selectSelectedAppointmentData);

    function residentialAddressPropChange(key: keyof IPatientAddress, value: string | undefined) {
        dispatch(editPatientAddressPropChange({ key, value }));
        verifyPatientAddress('lastVerifiedResidentialAddress');
    }

    const verifyPatientAddress = (path: keyof IPatientAppointment) => {
        if (isCheckinCheckout && !(_currentAppointment as IPatientAppointment)[path])
            dispatch(
                updatePatientAppointmentProp({
                    path,
                    value: new Date().toISOString(),
                }),
            );
    };

    const _propChange = (key: keyof IPatient, value: unknown): void => {
        dispatch(editPatientPropChange({ key, value }));
        verifyPatientAddress('lastVerifiedMailingAddress');
    };

    const validTextInput = (value?: string): boolean => {
        const res = value ? /^(?!\s)[a-zA-Z\s]*$/.test(value) : true;
        return res;
    };

    const stateType = states.find((state) => patient?.physicalAddress?.state === state.key)?.data?.type;

    return (
        <Stack tokens={{ childrenGap: 10 }} grow>
            <Stack horizontal tokens={{ childrenGap: 10 }} grow>
                <Stack.Item grow>
                    <TextField
                        label=" Residential Address Line 1"
                        value={patient?.physicalAddress?.streetAddress1 ?? ''}
                        autoComplete="off"
                        onChange={(ev, value) => residentialAddressPropChange('streetAddress1', value)}
                        required
                        errorMessage={
                            getValidationError(validationErrors, BasicDetailsFields.StreetAddress1)
                                ? 'Street Address 1 is required.'
                                : undefined
                        }
                    />
                </Stack.Item>
                <Stack.Item grow>
                    <TextField
                        label="Residential Address Line 2"
                        value={patient?.physicalAddress?.streetAddress2 ?? ''}
                        autoComplete="off"
                        onChange={(ev, value) => residentialAddressPropChange('streetAddress2', value)}
                    />
                </Stack.Item>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 10 }} grow>
                <Stack.Item grow>
                    <TextField
                        label="City"
                        value={patient?.physicalAddress?.city ?? ''}
                        autoComplete="off"
                        onChange={(ev, value) => {
                            if (validTextInput(value)) {
                                residentialAddressPropChange('city', value ? value : '');
                            }
                        }}
                        required
                        errorMessage={
                            getValidationError(validationErrors, BasicDetailsFields.City) ? 'City is required.' : undefined
                        }
                    />
                </Stack.Item>
                <Stack.Item grow>
                    <Field.SearchCombo
                        allowFreeform
                        label="State"
                        placeholder="(Select State)"
                        selectedKey={patient?.physicalAddress?.state ?? undefined}
                        options={states}
                        threshold={0.2}
                        onChange={(e, option) => {
                            if (option) residentialAddressPropChange('state', option.key as string);
                        }}
                        useComboBoxAsMenuWidth
                        caretDownButtonStyles={{ root: { display: 'none' } }}
                        styles={{ callout: { minHeight: 35, maxHeight: 300, overflow: 'auto' } }}
                        required
                        errorMessage={
                            getValidationError(validationErrors, BasicDetailsFields.State) ? 'State is required.' : undefined
                        }
                    />
                </Stack.Item>
                <Field.ZipCode
                    label="Zip"
                    value={patient?.physicalAddress?.zip ?? ''}
                    autoComplete="off"
                    onChange={(e, value) => residentialAddressPropChange('zip', value)}
                    required
                    stateType={stateType}
                    errorMessage={getValidationError(validationErrors, BasicDetailsFields.Zip) ? 'Zip is required.' : undefined}
                />
            </Stack>
            <Checkbox
                label="Mailing address is the same as residential address"
                checked={!!patient?.mailingAddressSameAsPhysicalAddress}
                onChange={(e, checked) => {
                    _propChange('mailingAddressSameAsPhysicalAddress', checked);
                }}
            />
        </Stack>
    );
}

export default PatientAddress;
