import { ChartConditionStatus, IChartCondition } from 'api/models/chart.model';
import { ToothArea } from 'api/models/tooth-area';
import { RouteParams } from 'interfaces/route-params';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { IChartAction } from 'state/slices/charting/chartActionsList.pipeline';
import {
    addConditionByIds,
    addConditions,
    createChartCondition,
    deleteConditions,
    getChartConditionById,
    getChartConditions,
    updateChartCondition,
    updateConditionStatuses,
} from 'state/slices/charting/conditions/conditions.actions';
import { chartConditionsAsList, selectChartConditions } from 'state/slices/charting/conditions/conditions.selectors';
import { updateChartProcedureDiagnoses } from 'state/slices/charting/diagnoses-panel/diagonses-panel.actions';

function useChartConditions() {
    const { tenantId, patientId } = useParams<RouteParams>();
    const dispatch = useDispatch();

    // Actions
    const _getChartConditions = useCallback(() => {
        dispatch(getChartConditions({ tenantId, patientId }));
    }, [dispatch, tenantId, patientId]);

    const _getChartConditionById = useCallback(
        (conditionId: string) => {
            dispatch(getChartConditionById({ tenantId, patientId, conditionId }));
        },
        [dispatch, tenantId, patientId],
    );
    const _createChartCondition = useCallback(
        (condition: IChartCondition) => {
            dispatch(createChartCondition({ tenantId, patientId, condition }));
        },
        [dispatch, tenantId, patientId],
    );
    const _updateChartCondition = useCallback(
        (condition: IChartCondition) => {
            dispatch(updateChartCondition({ tenantId, patientId, condition }));
        },
        [dispatch, tenantId, patientId],
    );

    const _updateConditionStatuses = useCallback(
        (chartAction: IChartAction | IChartAction[], status: ChartConditionStatus) => {
            dispatch(updateConditionStatuses(tenantId, patientId, chartAction, status));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [dispatch, tenantId, patientId],
    );

    const _addConditions = useCallback(
        (conditions: IChartCondition[]) => {
            dispatch(addConditions(tenantId, patientId, conditions));
        },
        [dispatch, tenantId, patientId],
    );

    const _addConditionsById = useCallback(
        (conditionIds: string[], encounterId?: string, surfaces?: (keyof typeof ToothArea)[]) => {
            dispatch(addConditionByIds(tenantId, patientId, conditionIds, encounterId, surfaces));
        },
        [dispatch, tenantId, patientId],
    );

    const _deleteConditions = useCallback(
        (conditions: IChartCondition[]) => {
            dispatch(deleteConditions(tenantId, patientId, conditions));
        },
        [dispatch, tenantId, patientId],
    );

    const { loading, saving, data: _conditions } = useSelector(selectChartConditions);
    const _chartConditionsAsList = useSelector(chartConditionsAsList);

    return {
        // Actions
        getChartConditions: _getChartConditions,
        getChartConditionById: _getChartConditionById,
        createChartCondition: _createChartCondition,
        addConditions: _addConditions,
        updateChartCondition: _updateChartCondition,
        updateChartConditionStatuses: _updateConditionStatuses,
        deleteChartConditions: _deleteConditions,
        addConditionsById: _addConditionsById,

        // State
        loading,
        saving,
        conditions: _conditions,
        chartConditionsAsList: _chartConditionsAsList,
    };
}

export default useChartConditions;
