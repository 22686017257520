import { IChartCondition } from 'api/models/chart.model';
import { ICondition } from 'api/models/lookup.model';
import { ToothArea } from 'api/models/tooth-area';

export interface IConditionPanelTooth {
    surfaces: (keyof typeof ToothArea)[];
}

export const conditionPanelInitialState: ConditionPanelState = {
    selectedTeeth: [],
    isOpen: false,
    panelTeethData: {},
    isEditing: false,
    note: undefined,
};

export interface ConditionPanelState {
    isEditing: boolean;
    selectedChartCondition?: IChartCondition;
    isOpen: boolean;
    condition?: ICondition;
    selectedTeeth: number[];
    note?: string;
    date?: string;
    panelTeethData: {
        [key: number]: IConditionPanelTooth;
    };
}
