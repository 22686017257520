import { Callout, DirectionalHint, Icon, mergeStyles, Shimmer, Stack, Text, useTheme } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { useBoolean } from '@uifabric/react-hooks';
import { ClinicalAlertType } from 'api/models/clinical-alert.model';
import { useSelector, useTenantId } from 'hooks';
import { map } from 'lodash';
import { selectActivePatientClinicalAlertsByPatientId } from 'state/slices/clinical-alert/clinical-alerts.selectors';
import { MouseEvent, useEffect } from 'react';
import AlertCalloutSection from './ClinicalAlertCalloutSection';
import { useDispatch } from 'react-redux';
import { cleanupClinicalAlertsForPatients } from 'state/slices/clinical-alert/clinical-alerts.slice';
import { getNonExistingClinicalAlertsByPatientAndType } from 'state/slices/clinical-alert/clincal-alerts.actions';

type ClinicalAlertCalloutProps = {
    patientId: string;
    iconOnly?: boolean;
    iconSize?: number;
};

export function ClinicalAlertCalloutIcon({ iconSize = 20, patientId }: { iconSize?: number; patientId: string }) {
    const { semanticColors } = useTheme();
    const clinicalAlerts = useSelector((state) => selectActivePatientClinicalAlertsByPatientId(state, { patientId }));

    if (!clinicalAlerts.length) return null;

    return (
        <Icon
            styles={{
                root: {
                    color: semanticColors.errorIcon,
                    fontSize: iconSize,
                    padding: 5,
                    userSelect: 'none',
                    cursor: 'pointer',
                },
            }}
            iconName={'WarningSolid'}
        />
    );
}

export default function ClinicalAlertCallout({ iconOnly, patientId, iconSize }: ClinicalAlertCalloutProps) {
    const dispatch = useDispatch();
    const tenantId = useTenantId();
    const { semanticColors } = useTheme();

    const [isCalloutVisible, { setFalse: setCalloutNotVisible, toggle }] = useBoolean(false);

    const id = useId();

    const clinicalAlerts = useSelector((state) => selectActivePatientClinicalAlertsByPatientId(state, { patientId }));

    useEffect(() => {
        dispatch(
            getNonExistingClinicalAlertsByPatientAndType({
                patientId,
                tenantId,
            }),
        );
        return () => {
            if (clinicalAlerts.length) dispatch(cleanupClinicalAlertsForPatients(patientId));
        };
    }, [tenantId, patientId]);

    if (!clinicalAlerts.length) return null;

    const alertClass = mergeStyles({
        cursor: 'pointer',
        backgroundColor: semanticColors.warningBackground,
        border: '1px solid #eecb71c7',
        padding: `2px 3px 2px`,
        borderRadius: 2,
    });

    const toggleCallout = (ev?: MouseEvent<HTMLElement>) => {
        ev?.stopPropagation();
        toggle();
    };

    const calloutSections = map(ClinicalAlertType, (type) => (
        <AlertCalloutSection alerts={clinicalAlerts} type={type} key={`${type}-${patientId}`} />
    ));

    return (
        <Stack id={id} onClick={toggleCallout} style={{ cursor: 'pointer' }}>
            {!iconOnly ? (
                <Stack
                    horizontal
                    verticalAlign="center"
                    tokens={{ childrenGap: 5 }}
                    className={alertClass}
                    onClick={toggleCallout}
                    style={{ padding: 5 }}
                >
                    <ClinicalAlertCalloutIcon patientId={patientId} />
                    <Text style={{ color: 'black', whiteSpace: 'nowrap', userSelect: 'none' }}>
                        <strong>Alerts</strong>
                    </Text>
                </Stack>
            ) : (
                <ClinicalAlertCalloutIcon iconSize={iconSize} patientId={patientId} />
            )}
            {isCalloutVisible && (
                <Callout
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    gapSpace={0}
                    backgroundColor={semanticColors.warningBackground}
                    isBeakVisible={true}
                    onDismiss={setCalloutNotVisible}
                    target={`#${id}`}
                    calloutMaxHeight={500}
                >
                    <Stack tokens={{ childrenGap: 10 }} styles={{ root: { padding: 5, overflowY: 'auto' } }}>
                        {calloutSections}
                    </Stack>
                </Callout>
            )}
        </Stack>
    );
}
