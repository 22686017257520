import ImmunizationsDetailsList from './ImmunizationsDetailsList';
import SharedSubSection from '../Shared/SharedSubSection';
import useOverview from 'hooks/store/useOverview';
import IPatientImmunizations from 'api/models/patient-immunization.model';
import { Checkbox, Label, Spinner, Stack } from '@fluentui/react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'hooks';
import { selectImmunizationsSaving } from 'state/slices/patient/overview/overview.selectors';
import { LoadingStatus } from 'interfaces/loading-statuses';

const newItem: IPatientImmunizations = {
    id: '',
    type: '',
    isDeleted: false,
    isReviewed: false,
};

function Immunizations() {
    const { immunizations, immunizationsLoading, updateReviewedCheckbox } = useOverview();
    const item = immunizations ? immunizations : newItem;
    const immunizationSaving = useSelector(selectImmunizationsSaving);
    const dispatch = useDispatch();

    const isSaving = immunizationSaving === LoadingStatus.Pending;

    const handleOnChange = () => {
        dispatch(updateReviewedCheckbox(!item.isReviewed));
    };
    return (
        <SharedSubSection
            type="Vaccines"
            displayName="Vaccines"
            addShown={false}
            newItem={item}
            hasItems={immunizations && immunizations.immunizations && immunizations.immunizations.length ? true : false}
            loadingStatus={immunizationsLoading}
            listComponent={<ImmunizationsDetailsList />}
            headingEndContent={
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    {isSaving ? (
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <Spinner />
                            <Label>Mark as reviewed</Label>
                        </Stack>
                    ) : (
                        <Checkbox checked={item.isReviewed} label={'Mark as reviewed'} onChange={handleOnChange} />
                    )}
                </Stack>
            }
        />
    );
}

export default Immunizations;
