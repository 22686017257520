import { useCallback } from 'react';
import { Graphics as IGraphics } from 'pixi.js-legacy';
import { Graphics } from '@inlet/react-pixi/legacy';
import { STAGE_WIDTH, LINE_SPACING } from '../../perio-settings';

function DepthLine({ isUpper, position }: { isUpper?: boolean; position: number }) {
    const lineColor = position === 0 ? 0x000000 : 0xbbbbbb;
    const draw = useCallback(
        (g: IGraphics) => {
            g.clear();
            g.lineStyle(1, lineColor);
            g.lineTo(STAGE_WIDTH, 0);
        },
        [lineColor],
    );
    return <Graphics draw={draw} y={position * LINE_SPACING} />;
}

export default DepthLine;
