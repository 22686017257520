import { SelectionMode } from '@fluentui/react';
import { IClaimProcedure } from 'api/models/encounter-claim-summary.model';
import { SortableDetailsList } from 'components';
import { format } from 'date-fns';
import { usdCurrencyFormatter } from 'utils';

type Props = {
    items: IClaimProcedure[];
};

export default function ClaimHistoryDetailsList({ items }: Props) {
    return (
        <SortableDetailsList<IClaimProcedure>
            items={items}
            compact
            showGrid
            selectionMode={SelectionMode.none}
            columns={[
                {
                    key: 'procedureCode',
                    fieldName: 'procedureCode',
                    minWidth: 100,
                    maxWidth: 120,
                    name: 'Code',
                },
                {
                    key: 'fee',
                    minWidth: 100,
                    maxWidth: 120,
                    name: 'Fee',
                    onRender: (item) => {
                        return usdCurrencyFormatter.format(item?.fee ?? 0);
                    },
                },
                {
                    key: 'allowed',
                    minWidth: 100,
                    maxWidth: 120,
                    name: 'Allowed',
                    onRender: (item) => {
                        return usdCurrencyFormatter.format(item?.allowed ?? 0);
                    },
                },
                {
                    key: 'paid',
                    minWidth: 100,
                    maxWidth: 120,
                    name: 'Paid',
                    onRender: (item) => {
                        return usdCurrencyFormatter.format(item?.paid ?? 0);
                    },
                },
                {
                    key: 'adjustments',
                    minWidth: 100,
                    maxWidth: 120,
                    name: 'Adjustments',
                    onRender: (item) => {
                        return usdCurrencyFormatter.format(item?.adjustments ?? 0);
                    },
                },
                {
                    key: 'deductible',
                    minWidth: 100,
                    maxWidth: 120,
                    name: 'Deductible',
                    onRender: (item) => {
                        return usdCurrencyFormatter.format(item?.deductible ?? 0);
                    },
                },
                {
                    key: 'coins',
                    minWidth: 100,
                    maxWidth: 120,
                    name: 'Coins.',
                    fieldName: 'coinsurance',
                    onRender: (item) => {
                        return usdCurrencyFormatter.format(item?.coinsurance ?? 0);
                    },
                },
                {
                    key: 'transferToOtherPayer',
                    minWidth: 200,
                    maxWidth: 120,
                    name: 'Transfer to Other Payer',
                    onRender: (item) => {
                        return usdCurrencyFormatter.format(item?.transferred ?? 0);
                    },
                },
                {
                    key: 'payerBalance',
                    minWidth: 100,
                    maxWidth: 150,
                    name: 'Payer Balance',
                    onRender: (item) => {
                        return usdCurrencyFormatter.format(item?.payerBalance ?? 0);
                    },
                },
                {
                    key: 'reasonCodes',
                    minWidth: 120,
                    maxWidth: 150,
                    name: 'Reason Codes',
                    fieldName: 'reasonCodes',
                },
                {
                    key: 'batchInfo',
                    minWidth: 140,
                    maxWidth: 150,
                    name: 'Batch Info',
                    fieldName: 'depositDate',
                    onRender: (item) => {
                        const value = `
                        ${item?.batchName ?? 'Unknown batch'}
                        ${item?.depositDate ? ` - ${format(new Date(item.depositDate), 'MM/dd/yyyy')}` : ''}`;

                        return <span title={value}>{value}</span>;
                    },
                },
                {
                    key: 'depositDate',
                    minWidth: 150,
                    name: 'Deposit Date/Check #/Check Date',
                    fieldName: 'depositDate',
                    onRender: (item) => {
                        const valueList = [
                            item?.depositDate?.trim() ? format(new Date(item.depositDate), 'MM/dd/yyyy') : 'None',
                            item?.methodIdentifier?.trim() ?? 'None',
                            item?.checkDate?.trim() ? format(new Date(item.checkDate), 'MM/dd/yyyy') : 'None',
                        ];
                        const value = valueList.join(' / ');

                        return <span title={value}>{value}</span>;
                    },
                },
            ]}
        />
    );
}
