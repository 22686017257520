import { IChartProcedure } from 'api/models/chart.model';
import { createRule } from '../../ruleGenerator';
import ProcedureCodes from '../../procedureCodes';
import ProcedureBusinessRulesPipeline from '../procedureBusinessRules.pipeline';
import { v4 as uuid } from 'uuid';
import { filter, map } from 'lodash';
import { el } from 'date-fns/locale';

const oneChartProcedurePerQuad = createRule<ProcedureBusinessRulesPipeline, IChartProcedure>({
    ruleTypes: [ProcedureCodes.D5282, ProcedureCodes.D5283, ProcedureCodes.D5284, ProcedureCodes.D5286],
    rule: (pipeline, item) => {
        if (item) {
            const procedure = pipeline.getProcedure(item);
            const quads = pipeline.getTeethIdsInQuads(item.toothIds);
            const activeQuads = filter(quads, (toothIds) => toothIds.length > 0);
            let updatedItems: IChartProcedure[] = [];

            if (procedure?.code === ProcedureCodes.D5282 || procedure?.code === ProcedureCodes.D5283) {
                updatedItems = map(activeQuads, (toothIds) => getProceduresBySelectedArchTeeth(toothIds)).filter(
                    (chartProc) => chartProc !== undefined,
                ) as IChartProcedure[];
            } else {
                updatedItems = map(activeQuads, (toothIds) => ({
                    ...item,
                    id: uuid(),
                    toothIds,
                }));
            }

            return { updatedItems };
        }
        return { updatedItems: item };

        function getProceduresBySelectedArchTeeth(teethIds: number[]): IChartProcedure | undefined {
            if (teethIds.length) {
                // TODO: Fix sorting of teethIds here
                const chartProcedure: IChartProcedure = { ...item, id: uuid(), toothIds: teethIds.sort() };
                return pipeline.getValidArchProcedureForArch(chartProcedure);
            }
        }
    },
});

export default oneChartProcedurePerQuad;
