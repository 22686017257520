import { Checkbox, Dropdown, IDropdownOption, Stack, TextField } from '@fluentui/react';
import { ReportType } from 'api/models/embed-report.model';
import { useSelector, useTenantId } from 'hooks';
import { getValidationError } from 'hooks/useValidation';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getAllLocationsOfCareForReports } from 'state/slices/reporting/reporting.actions';
import {
    selectLocationsOfCareFromReporting,
    selectReportProperties,
    selectReportingValidationErrors,
} from 'state/slices/reporting/reporting.selectors';
import { setProceduresDateAgingProp, toggleProceduresDateAgingId } from 'state/slices/reporting/reporting.slice';

import { ProceduresDateAgingReportQuery } from 'api/models/procedure-date-aging.model';
import { Field } from 'components';
import reportActionLookup from 'state/slices/reporting/reportActionLookup';
import { proceduresOptions } from 'state/slices/tenant/procedures.slice';
import { selectTreatingProviderOptions } from 'state/slices/tenant/providers.slice';
import { classicDateOnly } from 'utils/dateOnly';
import ReportWrapper from '../../ReportWrapper';
import { sortBy } from 'lodash';
import { reportingGroupOptions } from 'state/slices/reporting/reporting-groups/reporting-groups.selectors';
import { getTenantReportingGroups } from 'state/slices/reporting/reporting-groups/reporting-groups.actions';
import { selectTenantPayerOptions } from 'state/slices/tenant/tenant-payers.slice';

export default function ProceduresDateAgingReport() {
    const tenantId = useTenantId();
    const dispatch = useDispatch();

    const errors = useSelector(selectReportingValidationErrors);
    const locationsOfCare = useSelector(selectLocationsOfCareFromReporting);
    const _providerOptions = useSelector(selectTreatingProviderOptions);
    const _payerOptions = useSelector(selectTenantPayerOptions);
    const _procedureOptions = useSelector(proceduresOptions);
    const _groupByOptions = useSelector(reportingGroupOptions);

    const query = useSelector(selectReportProperties) as ProceduresDateAgingReportQuery;

    useEffect(() => {
        dispatch(getAllLocationsOfCareForReports({ tenantId }));
        dispatch(getTenantReportingGroups({ tenantId }));
    }, []);

    const locationsOfCareOptions: IDropdownOption[] = locationsOfCare.map((loc) => ({
        key: loc.id,
        text: loc.displayName,
    }));

    const groupByOption: IDropdownOption[] = [
        { key: 'LocationOfCareId', text: 'Location Of Care' },
        { key: 'TreatingProviderId', text: 'Treating Provider' },
        { key: 'PrimaryInsuranceCarrierId', text: 'Primary Insurance Carrier' },
        { key: 'PatientId', text: 'Patient' },
        { key: 'PatientVisitId', text: 'Patient Visit' },
    ];

    const dateTypeOptions: IDropdownOption[] = [
        { key: 'DOS', text: 'Service' },
        { key: 'DOE', text: 'Entry' },
    ];

    const _onViewReport = () => {
        if (query) dispatch(reportActionLookup[ReportType.ProcedureDateAging]({ tenantId, ...query }));
    };

    useEffect(() => {
        if (!query.interval) dispatch(setProceduresDateAgingProp({ path: 'interval', value: '4' }));
        if (!query.span) dispatch(setProceduresDateAgingProp({ path: 'span', value: '30' }));
    }, []);

    const extraPayarOptions: IDropdownOption[] = [
        {
            key: '-1',
            text: 'Self Pay',
        },
        {
            key: '-2',
            text: 'Sliding Fee',
        },
    ];

    const payerOption = sortBy([..._payerOptions, ...extraPayarOptions], 'text');

    useEffect(() => {
        dispatch(setProceduresDateAgingProp({ path: 'percentage', value: 'true' }));
    }, []);

    return (
        <ReportWrapper
            validationConfig={[
                {
                    fieldName: 'Date Type',
                    validation: ['required'],
                    value: query?.dateType,
                },
                {
                    fieldName: 'As Of Date',
                    validation: ['required'],
                    value: query?.asOfDate,
                },
                {
                    fieldName: 'Number of Aging Buckets',
                    validation: ['required'],
                    value: query?.interval ? parseInt(query.interval) : undefined,
                },
                {
                    fieldName: 'Number of Days Per Aging Buckets',
                    validation: ['required'],
                    value: query?.span ? parseInt(query.span) : undefined,
                },
                {
                    fieldName: 'Group By',
                    validation: ['required'],
                    value: query?.groupBy,
                },
            ]}
            onViewReport={_onViewReport}
            validationOption={{ shouldZerosNotFulfillRequired: true }}
        >
            <Stack grow horizontal wrap tokens={{ childrenGap: 20 }}>
                <Stack.Item>
                    <Dropdown
                        label="Date Type"
                        options={dateTypeOptions}
                        required
                        style={{ minWidth: 150 }}
                        selectedKey={query?.dateType}
                        placeholder="(Select)"
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(
                                    setProceduresDateAgingProp({
                                        path: 'dateType',
                                        value: option.key as string,
                                    }),
                                );
                        }}
                        errorMessage={getValidationError(errors, 'Date Type') ? 'Date type is required.' : undefined}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.Date
                        label="As Of Date"
                        required
                        isReasonable
                        hasDatePicker
                        value={query?.asOfDate ? classicDateOnly(query?.asOfDate, 'MM/dd/yyyy') : ''}
                        onChange={(ev, value) => {
                            const newDate = value ? classicDateOnly(value, 'yyyy-MM-dd') : undefined;
                            dispatch(setProceduresDateAgingProp({ path: 'asOfDate', value: newDate }));
                        }}
                        errorMessage={getValidationError(errors, 'As Of Date') ? 'As Of Date is required.' : undefined}
                    />
                </Stack.Item>
                <Stack.Item>
                    <TextField
                        label="Number of Aging Buckets"
                        value={query?.interval ?? ''}
                        required
                        onChange={(ev, value) => {
                            if (value && isNaN(parseInt(value))) return;
                            dispatch(setProceduresDateAgingProp({ path: 'interval', value }));
                        }}
                        errorMessage={
                            getValidationError(errors, 'Number of Aging Buckets') ? 'Number of Aging Buckets is required.' : ''
                        }
                    />
                </Stack.Item>
                <Stack.Item>
                    <TextField
                        label="Number of Days Per Aging Bucket"
                        value={query?.span ?? ''}
                        required
                        onChange={(ev, value) => {
                            if (value && isNaN(parseInt(value))) return;
                            dispatch(setProceduresDateAgingProp({ path: 'span', value }));
                        }}
                        errorMessage={
                            getValidationError(errors, 'Number of Day Per Aging Bucket')
                                ? 'Number of Day Per Aging Bucket is required.'
                                : ''
                        }
                    />
                </Stack.Item>
            </Stack>

            <Stack grow horizontal wrap tokens={{ childrenGap: 10 }}>
                <Stack.Item>
                    <Field.SearchCombo
                        placeholder="(Select Locations of Care)"
                        label="Locations of Care"
                        multiSelect
                        style={{ minWidth: 300 }}
                        options={locationsOfCareOptions}
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(toggleProceduresDateAgingId({ id: option.key as string, path: 'locationOfCareIds' }));
                        }}
                        selectedKey={query?.locationOfCareIds}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.SearchCombo
                        placeholder="(Select Provider)"
                        label="Providers"
                        multiSelect
                        style={{ minWidth: 300 }}
                        options={_providerOptions}
                        selectedKey={query?.treatingProviderIds}
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(toggleProceduresDateAgingId({ id: option.key as string, path: 'treatingProviderIds' }));
                        }}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.SearchCombo
                        placeholder="(Select Carriers)"
                        label="Carrier"
                        multiSelect
                        style={{ minWidth: 300 }}
                        options={payerOption}
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(toggleProceduresDateAgingId({ id: option.key as string, path: 'primaryInsIds' }));
                        }}
                        selectedKey={query?.primaryInsIds}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.SearchCombo
                        placeholder="(Select Procedures)"
                        label="Procedures"
                        options={_procedureOptions}
                        multiSelect
                        styles={{ callout: { maxWidth: 475 } }}
                        style={{ minWidth: 300 }}
                        maxResults={100}
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(toggleProceduresDateAgingId({ id: option.key as string, path: 'procedureIds' }));
                        }}
                        selectedKey={query?.procedureIds}
                    />
                </Stack.Item>
            </Stack>
            <Stack grow verticalAlign="center" horizontal wrap tokens={{ childrenGap: 10 }}>
                <Stack.Item>
                    <Dropdown
                        label="Group By "
                        options={[..._groupByOptions, ...groupByOption]}
                        selectedKey={query?.groupBy}
                        required
                        style={{ minWidth: 300 }}
                        placeholder="(Select)"
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(
                                    setProceduresDateAgingProp({
                                        path: 'groupBy',
                                        value: option.key as string,
                                    }),
                                );
                        }}
                        errorMessage={getValidationError(errors, 'Group By') ? 'Group By is required.' : undefined}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Dropdown
                        label="Group By 2 "
                        options={[{ key: '', text: '(Select Group Column)' }, ..._groupByOptions, ...groupByOption]}
                        selectedKey={query?.groupBy2}
                        style={{ minWidth: 300 }}
                        placeholder="(Select)"
                        onChange={(ev, option) => {
                            if (option)
                                dispatch(
                                    setProceduresDateAgingProp({
                                        path: 'groupBy2',
                                        value: option.key as string,
                                    }),
                                );
                        }}
                    />
                </Stack.Item>
                <Stack>
                    <Checkbox
                        label="Show Percentage"
                        checked={!!query?.percentage}
                        styles={{ root: { marginTop: 30 } }}
                        onChange={(ev, checked) => {
                            dispatch(setProceduresDateAgingProp({ path: 'percentage', value: checked }));
                        }}
                    />
                </Stack>
            </Stack>
        </ReportWrapper>
    );
}
