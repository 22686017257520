enum ProcedureCodes {
    D0230 = 'D0230',

    D0220 = 'D0220',
    D0240 = 'D0240',
    D0274 = 'D0274',
    D0330 = 'D0330',

    D4341 = 'D4341',
    D4342 = 'D4342',

    //One Surface Only
    D2140 = 'D2140',
    D2330 = 'D2330',
    D2391 = 'D2391',
    D2410 = 'D2410',
    D2510 = 'D2510',
    D2610 = 'D2610',
    D2650 = 'D2650',

    //Two Surfaces Only
    D2150 = 'D2150',
    D2331 = 'D2331',
    D2392 = 'D2392',
    D2420 = 'D2420',
    D2520 = 'D2520',
    D2542 = 'D2542',
    D2642 = 'D2642',
    D2620 = 'D2620',
    D2651 = 'D2651',
    D2662 = 'D2662',

    //Three Surfaces Only
    D2160 = 'D2160',
    D2332 = 'D2332',
    D2393 = 'D2393',
    D2430 = 'D2430',
    D2530 = 'D2530',
    D2630 = 'D2630',
    D2652 = 'D2652',
    D2543 = 'D2543',
    D2643 = 'D2643',
    D2663 = 'D2663',

    //Four Surfaces Only
    D2161 = 'D2161',
    D2335 = 'D2335',
    D2394 = 'D2394',
    D2544 = 'D2544',
    D2644 = 'D2644',
    D2664 = 'D2664',

    //Arch Only
    D1551 = 'D1551',
    D1552 = 'D1552',

    D1557 = 'D1557',
    D1558 = 'D1558',

    D5110 = 'D5110',
    D5120 = 'D5120',

    D5130 = 'D5130',
    D5140 = 'D5140',

    D5211 = 'D5211',
    D5212 = 'D5212',

    D5213 = 'D5213',
    D5214 = 'D5214',

    D5221 = 'D5221',
    D5222 = 'D5222',

    D5223 = 'D5223',
    D5224 = 'D5224',

    D5225 = 'D5225',
    D5226 = 'D5226',
    D5227 = 'D5227',
    D5282 = 'D5282',
    D5228 = 'D5228',
    D5283 = 'D5283',

    D5410 = 'D5410',
    D5411 = 'D5411',

    D5421 = 'D5421',
    D5422 = 'D5422',

    D5670 = 'D5670',
    D5671 = 'D5671',
    D5765 = 'D5765',

    D5710 = 'D5710',
    D5711 = 'D5711',

    D5720 = 'D5720',
    D5721 = 'D5721',

    D5730 = 'D5730',
    D5731 = 'D5731',

    D5740 = 'D5740',
    D5741 = 'D5741',

    D5750 = 'D5750',
    D5751 = 'D5751',

    D5760 = 'D5760',
    D5761 = 'D5761',

    D5810 = 'D5810',
    D5811 = 'D5811',

    D5820 = 'D5820',
    D5821 = 'D5821',

    D5850 = 'D5850',
    D5851 = 'D5851',

    D5863 = 'D5863',
    D5865 = 'D5865',

    D5864 = 'D5864',
    D5866 = 'D5866',
    D5899 = 'D5899',

    D5995 = 'D5995',
    D5996 = 'D5996',

    D6110 = 'D6110',
    D6111 = 'D6111',

    D6112 = 'D6112',
    D6113 = 'D6113',

    D6114 = 'D6114',
    D6115 = 'D6115',

    D6116 = 'D6116',
    D6117 = 'D6117',

    D7472 = 'D7472',
    D7473 = 'D7473',

    D7560 = 'D7560',

    D7610 = 'D7610',
    D7630 = 'D7630',

    D7620 = 'D7620',
    D7640 = 'D7640',

    D7710 = 'D7710',
    D7730 = 'D7730',

    D7720 = 'D7720',
    D7740 = 'D7740',

    D8696 = 'D8696',
    D8697 = 'D8697',

    D8698 = 'D8698',
    D8699 = 'D8699',

    D8701 = 'D8701',
    D8702 = 'D8702',

    D8703 = 'D8703',
    D8704 = 'D8704',

    D9932 = 'D9932',
    D9933 = 'D9933',

    D9934 = 'D9934',
    D9935 = 'D9935',

    // Extraction Codes
    D7111 = 'D7111',
    D7140 = 'D7140',
    D7210 = 'D7210',
    D7220 = 'D7220',
    D7230 = 'D7230',
    D7240 = 'D7240',
    D7241 = 'D7241',
    D7250 = 'D7250',

    // Pontic Codes
    D6205 = 'D6205',
    D6210 = 'D6210',
    D6211 = 'D6211',
    D6212 = 'D6212',
    D6214 = 'D6214',
    D6240 = 'D6240',
    D6241 = 'D6241',
    D6242 = 'D6242',
    D6243 = 'D6243',
    D6245 = 'D6245',
    D6250 = 'D6250',
    D6251 = 'D6251',
    D6252 = 'D6252',
    D6253 = 'D6253',

    D6600 = 'D6600',
    D6601 = 'D6601',
    D6602 = 'D6602',
    D6603 = 'D6603',
    D6604 = 'D6604',
    D6605 = 'D6605',
    D6606 = 'D6606',
    D6607 = 'D6607',
    D6608 = 'D6608',
    D6609 = 'D6609',
    D6610 = 'D6610',
    D6611 = 'D6611',
    D6612 = 'D6612',
    D6613 = 'D6613',
    D6614 = 'D6614',
    D6615 = 'D6615',
    //Sealant
    D1351 = 'D1351',
    //Caries Risk
    D0601 = 'D0601',
    D0602 = 'D0602',
    D0603 = 'D0603',

    D4240 = 'D4240',
    D4241 = 'D4241',

    //Osseius surgery

    D4261 = 'D4261',
    D4260 = 'D4260',

    // Alveoloplasty
    D7320 = 'D7320',
    D7321 = 'D7321',

    D7310 = 'D7310',
    D7311 = 'D7311',
    D4263 = 'D4263',
    D4264 = 'D4264',

    // Codes that require quads/arches
    D0364 = 'D0364',
    D0380 = 'D0380',
    D1206 = 'D1206',
    D1208 = 'D1208',
    D1510 = 'D1510',
    D1520 = 'D1520',
    D1553 = 'D1553',
    D1556 = 'D1556',
    D1575 = 'D1575',
    D1999 = 'D1999',
    D4210 = 'D4210',
    D4211 = 'D4211',
    D4230 = 'D4230',
    D4231 = 'D4231',
    D4921 = 'D4921',
    D5284 = 'D5284',
    D5286 = 'D5286',
    D5867 = 'D5867',
    D5875 = 'D5875',
    D5876 = 'D5876',
    D5931 = 'D5931',
    D5932 = 'D5932',
    D5933 = 'D5933',
    D5936 = 'D5936',
    D5937 = 'D5937',
    D5852 = 'D5852',
    D5853 = 'D5853',
    D5960 = 'D5960',
    D5982 = 'D5982',
    D5983 = 'D5983',
    D5984 = 'D5984',
    D5985 = 'D5985',
    D5986 = 'D5986',
    D5987 = 'D5987',
    D5988 = 'D5988',
    D5991 = 'D5991',
    D5992 = 'D5992',
    D5993 = 'D5993',
    D6190 = 'D6190',
    D6920 = 'D6920',
    D6985 = 'D6985',
    D6999 = 'D6999',
    D7260 = 'D7260',
    D7261 = 'D7261',
    D7285 = 'D7285',
    D7286 = 'D7286',
    D7287 = 'D7287',
    D7288 = 'D7288',
    D7292 = 'D7292',
    D7293 = 'D7293',
    D7294 = 'D7294',
    D7295 = 'D7295',
    D7296 = 'D7296',
    D7297 = 'D7297',
    D7298 = 'D7298',
    D7299 = 'D7299',
    D7300 = 'D7300',
    D7340 = 'D7340',
    D7350 = 'D7350',
    D7410 = 'D7410',
    D7411 = 'D7411',
    D7412 = 'D7412',
    D7413 = 'D7413',
    D7414 = 'D7414',
    D7415 = 'D7415',
    D7440 = 'D7440',
    D7441 = 'D7441',
    D7450 = 'D7450',
    D7451 = 'D7451',
    D7460 = 'D7460',
    D7461 = 'D7461',
    D7465 = 'D7465',
    D7471 = 'D7471',
    D7485 = 'D7485',
    D7490 = 'D7490',
    D7510 = 'D7510',
    D7511 = 'D7511',
    D7520 = 'D7520',
    D7521 = 'D7521',
    D7550 = 'D7550',
    D7953 = 'D7953',
    D7955 = 'D7955',
    D7961 = 'D7961',
    D7962 = 'D7962',
    D7963 = 'D7963',
    D7970 = 'D7970',
    D7972 = 'D7972',
    D8010 = 'D8010',
    D8020 = 'D8020',
    D8030 = 'D8030',
    D8040 = 'D8040',
    D8210 = 'D8210',
    D8220 = 'D8220',
    D8680 = 'D8680',
    D8695 = 'D8695',
    D9613 = 'D9613',
    D9941 = 'D9941',
    D9942 = 'D9942',
    D9943 = 'D9943',
    D9944 = 'D9944',
    D9945 = 'D9945',
    D9946 = 'D9945',
    D9947 = 'D9947',
    D9948 = 'D9948',
    D9949 = 'D9949',
    D9950 = 'D9950',
}

export const cariesRiskExamCodes = [ProcedureCodes.D0601, ProcedureCodes.D0602, ProcedureCodes.D0603];

export const extractionCodes = [
    ProcedureCodes.D7111,
    ProcedureCodes.D7140,
    ProcedureCodes.D7210,
    ProcedureCodes.D7220,
    ProcedureCodes.D7230,
    ProcedureCodes.D7240,
    ProcedureCodes.D7241,
    ProcedureCodes.D7250,
];

export const ponticCodes = [
    ProcedureCodes.D6205,
    ProcedureCodes.D6210,
    ProcedureCodes.D6211,
    ProcedureCodes.D6212,
    ProcedureCodes.D6214,
    ProcedureCodes.D6240,
    ProcedureCodes.D6241,
    ProcedureCodes.D6242,
    ProcedureCodes.D6243,
    ProcedureCodes.D6245,
    ProcedureCodes.D6250,
    ProcedureCodes.D6251,
    ProcedureCodes.D6252,
    ProcedureCodes.D6253,
];

export default ProcedureCodes;
