import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { ChartProcedureDiagnosis } from 'api/models/chart.model';
import { ChartingState } from '../chart/chart.slice';
import { updateChartProcedure, updateChartProcedures } from '../procedures/procedures.actions';
import { diagnosesPanelInitialState } from './diagnoses-panel.state';
import { OpenDiagnosesPanelPayload, updateChartProcedureDiagnoses } from './diagonses-panel.actions';

export const diagnosesPanelReducers = {
    setDiagnosesPanelOpen: (state: ChartingState, action: PayloadAction<OpenDiagnosesPanelPayload>) => {
        const { selectedChartProcedure, selectedDiagnoses, selectedProcedure } = action.payload;
        state.diagnosesPanel.isOpen = true;
        state.diagnosesPanel.selectedChartProcedures = selectedChartProcedure;
        state.diagnosesPanel.selectedDiagnoses = selectedDiagnoses;
        state.diagnosesPanel.selectedProcedures = selectedProcedure;
    },
    setDiagnosesPanelClosed: (state: ChartingState) => {
        state.diagnosesPanel.isOpen = false;
    },
    setDxPanelSelectedChartProcedure: (state: ChartingState, action: PayloadAction<string | undefined>) => {
        const procedureId = action.payload;
        if (procedureId) {
            const procedure = state.procedures.data.find((procedure) => procedure.id === procedureId);
            state.diagnosesPanel.selectedChartProcedures = procedure;
        } else {
            state.diagnosesPanel.selectedChartProcedures = undefined;
        }
    },
    clearDiagnosesPanel: (state: ChartingState) => {
        state.diagnosesPanel = diagnosesPanelInitialState;
    },
    updateDiagonsisCodes: (state: ChartingState, action: PayloadAction<ChartProcedureDiagnosis[]>) => {
        const diagnosisCodes = action.payload;
        state.diagnosesPanel.selectedDiagnoses = diagnosisCodes;
    },
};
