import { PrimaryButton, Spinner, Stack, TextField } from '@fluentui/react';
import IReportingGroup, { IReportingCategory, ReportingGroupTypes } from 'api/models/reporting-group.model';
import { Section } from 'components';
import { useSelector, useTenantId, useValidation } from 'hooks';
import { getValidationError, ValidationType } from 'hooks/useValidation';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateTenantReportingGroup } from 'state/slices/reporting/reporting-groups/reporting-groups.actions';
import {
    loadingReportingSelectedGroup,
    reportingGroupList,
    selectSelectedReportingGroup,
} from 'state/slices/reporting/reporting-groups/reporting-groups.selectors';
import { setSelectedReportingCategory, updateSelectedReportingGroupProp } from 'state/slices/reporting/reporting.slice';
import { v4 as uuid } from 'uuid';
import ReportingCategoryList from './ReportingCatergoryList';
import ReportingCategoryPanel from './ReportingGroupCategoryPanel';

export default function EditSelectedGroup() {
    const dispatch = useDispatch();
    const tenantId = useTenantId();
    const _selectedReportingGroup = useSelector(selectSelectedReportingGroup);
    const _groupList = useSelector(reportingGroupList);
    const _loadingSelectedReportingGroup = useSelector(loadingReportingSelectedGroup);

    const [group, setGroup] = useState<IReportingGroup | undefined>(_selectedReportingGroup);
    useEffect(() => {
        setGroup(_selectedReportingGroup);
    }, [_selectedReportingGroup?.id]);

    const isModified = isEqual(group, _selectedReportingGroup);

    const _updateReportingGroup = () => {
        if (_selectedReportingGroup?.id) dispatch(updateTenantReportingGroup({ tenantId, model: _selectedReportingGroup }));
    };

    const validationList = _groupList
        .filter((g) => g?.id !== _selectedReportingGroup?.id)
        ?.filter((g) => g?.type === _selectedReportingGroup?.type)
        .map((g) => g?.displayName ?? '');

    const [errors, submit, cleanupErrors] = useValidation(
        [
            {
                fieldName: 'Display Name',
                validation: ['required', 'notDuplicateItem'],
                value: _selectedReportingGroup?.displayName,
                itemOptions: {
                    itemList: validationList,
                },
            },
            {
                fieldName: 'Description',
                validation: ['characterLimit'],
                value: _selectedReportingGroup?.description,
            },
        ],
        _updateReportingGroup,
    );

    useEffect(() => {
        if (errors.length) cleanupErrors();

        return () => {
            cleanupErrors();
        };
    }, [_selectedReportingGroup]);

    const getDispayNameErrors = useCallback(() => {
        const errorType = getValidationError(errors, 'Display Name')?.errorTypes[0];
        switch (errorType) {
            case ValidationType.Required:
                return 'Display Name is Required';
            case ValidationType.NotDuplicateItem:
                return 'Display Name is already being used for this group type.';
            default:
                return '';
        }
    }, [errors]);

    if (!_selectedReportingGroup) return null;

    const loading = _loadingSelectedReportingGroup === LoadingStatus.Pending;

    const disableSave = loading || isModified;

    return (
        <div>
            <Section heading={`${_selectedReportingGroup.displayName} Settings`}>
                <Stack tokens={{ childrenGap: 10 }}>
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <Stack.Item grow>
                            <TextField
                                label="Display Name"
                                value={_selectedReportingGroup?.displayName}
                                required
                                errorMessage={getDispayNameErrors()}
                                onChange={(e, value) => {
                                    if (value !== undefined)
                                        dispatch(updateSelectedReportingGroupProp({ path: 'displayName', value }));
                                }}
                            />
                        </Stack.Item>
                        <Stack.Item grow>
                            <TextField
                                label={`Description`}
                                value={_selectedReportingGroup?.description}
                                onChange={(e, value) => {
                                    if (value !== undefined)
                                        dispatch(updateSelectedReportingGroupProp({ path: 'description', value }));
                                }}
                                description={`Used ${_selectedReportingGroup?.description?.length ?? 0} of 100 characters.`}
                                errorMessage={
                                    getValidationError(errors, 'Description') ? 'Character limit must be below 100.' : ''
                                }
                            />
                        </Stack.Item>
                    </Stack>
                    <Stack.Item align="end">
                        <Stack tokens={{ childrenGap: 10 }} horizontal>
                            {loading && <Spinner />}
                            <PrimaryButton text="Save" disabled={disableSave} onClick={submit} />
                        </Stack>
                    </Stack.Item>
                </Stack>
            </Section>
            <AddCategories />
        </div>
    );
}

function AddCategories() {
    const _reportingCategoriesListByGroup = useSelector(selectSelectedReportingGroup);
    const _selectedReportingGroup = useSelector(selectSelectedReportingGroup);

    const dispatch = useDispatch();

    const addCategories = () => {
        const newCategory: IReportingCategory = {
            id: uuid(),
            displayName: '',
            description: '',
            isDeleted: false,
        };

        dispatch(setSelectedReportingCategory(newCategory));
    };

    const buttonText = () => {
        if (ReportingGroupTypes.LocationOfCare === _reportingCategoriesListByGroup?.type) return 'Add Location of Care Category';
        if (ReportingGroupTypes.Payer === _reportingCategoriesListByGroup?.type) return 'Add Payer Category';
        if (ReportingGroupTypes.TreatingProvider === _reportingCategoriesListByGroup?.type) return 'Add Provider Category';
    };

    return (
        <Section
            heading={`${_selectedReportingGroup?.displayName ?? 'Unknown Group'} Categories`}
            sectionButtons={[{ text: buttonText(), onClick: addCategories }]}
        >
            <ReportingCategoryList />
            <ReportingCategoryPanel />
        </Section>
    );
}
