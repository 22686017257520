import { Dictionary, SerializedError } from '@reduxjs/toolkit';
import { IClinicalAlertType, IClinicalAlert, IClinicalAlerts } from 'api/models/clinical-alert.model';
import { IValidationError } from 'hooks/useValidation';
import { LoadingStatus } from 'interfaces/loading-statuses';

export type PatientsClinicalAlertsLookup = Record<string, IClinicalAlerts>;

type ClinicalAlertsState = {
    loadingAlertTypes: LoadingStatus;
    alertTypes?: Dictionary<IClinicalAlertType>;

    //Keyed by patient id.
    loadingClinicalAlerts: Record<string, LoadingStatus>;
    loadingClinicalAlertsErrors: Record<string, SerializedError | undefined>;
    clinicalAlertsForPatients: PatientsClinicalAlertsLookup;
    patientClinicalAlerts: IClinicalAlerts;
    currentClinicalAlertData?: IClinicalAlert;
    clinicalAlertPanelOpen: boolean;
    savingClinicalAlert: LoadingStatus;

    savingClinicalAlertError?: SerializedError;

    validationErrors: IValidationError[];
};

export const initialClinicalAlertState: ClinicalAlertsState = {
    loadingAlertTypes: LoadingStatus.Idle,
    loadingClinicalAlertsErrors: {},
    loadingClinicalAlerts: {},
    clinicalAlertsForPatients: {},
    patientClinicalAlerts: {},
    alertTypes: {},
    clinicalAlertPanelOpen: false,
    savingClinicalAlert: LoadingStatus.Idle,
    validationErrors: [],
};

export default ClinicalAlertsState;
