import React, { useRef, useEffect } from 'react';
import { Stack } from '@fluentui/react';
import { Documents, Questionnaires } from './components';
import SectionScroller, { ISectionScrollerItem } from 'components/SectionScroller';
import { AccessDenied, PatientDetailsBanner } from 'pages/components';
import Allergies from './Allergies/Allergies';
import Problems from './Problems/Problems';
import SharedEditPanel from './Shared/SharedEditPanel';
import useOverview from 'hooks/store/useOverview';
import Medications from './Medications/Medications';
import Vitals from './Vitals/Vitals';
import Immunizations from './Immunizations/Immunizations';
import usePermissions, { DentalPermissions } from 'hooks/store/usePermissions';
import { useEncounter, usePatientId, useTenantId } from 'hooks';
import useEncounterId from 'hooks/useEncounterId';
import { useDispatch, useSelector } from 'react-redux';
import EncounterHistoryList from './EncounterReasonsHistory/EncounterReasonsHistoryList';
import { fetchPatientOverview, fetchPatientOverviewTasks, getPastEncounters } from 'state/slices/patient/patient.actions';
import {
    selectPatientOverviewQuestionnaireTasks,
    selectPatientOverviewTasks,
    selectPatientOverviewTasksState,
} from 'state/slices/patient/overview/overview.selectors';
import Beacon from 'pages/components/Beacon';
import { patientLoading, selectPatientIsMergedOrDeleted } from 'state/slices/patient/patient.selectors';
import MergedPatientWarning from 'components/MergedPatientWarning';
import ClinicalAlertModal from 'pages/components/ClinicalAlerts/ClinicalAlertModal';
import Alerts from './Alerts/Alerts';
import { signalRGroupSubscriptionService, SignalRMessage, useSignalR } from 'hooks/signalr/useSignalr';
import { upsertPatientClinicalAlertAction } from 'state/slices/clinical-alert/clincal-alerts.actions';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { Helmet } from 'react-helmet';
import { SignalRGroup } from 'hooks/signalr/signalRGroupSubscriptionService';

function PatientOverview(): JSX.Element {
    const patientId = usePatientId();
    const tenantId = useTenantId();
    const encounterId = useEncounterId();
    const dispatch = useDispatch();

    const { selectedOverviewItem } = useOverview();
    const { getPatientEncounter } = useEncounter();

    const { loading: tasksLoading } = useSelector(selectPatientOverviewTasksState);
    const patientOverviewTasks = useSelector(selectPatientOverviewTasks);
    const loadingPatient = useSelector(patientLoading);

    const patientIsMergedOrDeleted = useSelector(selectPatientIsMergedOrDeleted);

    const { registerSignalRMessage } = useSignalR();

    useEffect(() => {
        registerSignalRMessage(SignalRMessage.UpdatedClinicalAlert, upsertPatientClinicalAlertAction);

        return () => {
            signalRGroupSubscriptionService.unsubscribeFromGroups(tenantId, [SignalRGroup.Clinical])
        }
    }, []);

    useEffect(() => {
        getPatientEncounter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientId, encounterId]);

    useEffect(() => {
        if (patientId) {
            //If the patient is currently loading, we know we are about to get the overview information at the same time.
            //This is a temp fix. For the future we probably want to just call another thunk that gets patient overview info from the fetch patient call, instead of making the same api calls.
            if (loadingPatient !== LoadingStatus.Pending && loadingPatient !== LoadingStatus.Idle)
                dispatch(fetchPatientOverview({ tenantId, patientId }));
            dispatch(getPastEncounters({ tenantId, patientId }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientId, tenantId]);

    useEffect(() => {
        if (patientId) dispatch(fetchPatientOverviewTasks({ tenantId, patientId }));
    }, [patientId, tenantId, dispatch]);

    const [hasOverview] = usePermissions([DentalPermissions.PatientOverview]);

    return (
        <Stack tokens={{ childrenGap: 10 }} grow>
            <Helmet title="Patient Overview" />
            <PatientDetailsBanner />
            {hasOverview ? (
                <>
                    <Beacon items={patientOverviewTasks} loadingReferenceKey="appointmentId" loading={tasksLoading} />
                    <MergedPatientWarning />
                    {!patientIsMergedOrDeleted && <PatientOverviewSections />}
                </>
            ) : (
                <AccessDenied />
            )}
            {selectedOverviewItem.item && (
                <SharedEditPanel
                    type={selectedOverviewItem.type}
                    displayName={selectedOverviewItem.displayName}
                    panelType={selectedOverviewItem.panelType}
                />
            )}
        </Stack>
    );
}

export default PatientOverview;

function PatientOverviewSections() {
    const questionnaireTasks = useSelector(selectPatientOverviewQuestionnaireTasks);
    const refs = {
        alerts: useRef<HTMLDivElement>(null),
        problems: useRef<HTMLDivElement>(null),
        allergies: useRef<HTMLDivElement>(null),
        medications: useRef<HTMLDivElement>(null),
        vitals: useRef<HTMLDivElement>(null),
        pastMedicalHistory: useRef<HTMLDivElement>(null),
        socialHistory: useRef<HTMLDivElement>(null),
        encounterHistory: useRef<HTMLDivElement>(null),
        questionnaires: useRef<HTMLDivElement>(null),
        immunizations: useRef<HTMLDivElement>(null),
        documents: useRef<HTMLDivElement>(null),
    };
    const sectionScrollerItems: ISectionScrollerItem[] = [
        { text: 'Alerts', iconName: 'PageHeader', ref: refs.alerts },
        { text: 'Problems', iconName: 'PageHeader', ref: refs.problems },
        { text: 'Allergies', iconName: 'PageHeader', ref: refs.allergies },
        { text: 'Medications', iconName: 'PageHeader', ref: refs.medications },
        { text: 'Vitals', iconName: 'PageHeader', ref: refs.vitals },
        { text: 'Dental Encounter History', iconName: 'PageHeader', ref: refs.encounterHistory },
        { text: 'Questionnaires', iconName: 'Bro', ref: refs.questionnaires, tasks: questionnaireTasks },
        { text: 'Vaccines', iconName: 'PageHeader', ref: refs.immunizations },
        { text: 'Clinical Documents', iconName: 'PageHeader', ref: refs.documents },
    ];

    const _pageStyle: React.CSSProperties = {
        margin: '0 10px 10px 10px',
        flex: 1,
        overflow: 'hidden',
    };

    return (
        <SectionScroller items={sectionScrollerItems} expanded hasNoFlex>
            <ClinicalAlertModal />
            <Stack style={_pageStyle}>
                <div ref={refs.alerts}>
                    <Alerts />
                </div>
                <div ref={refs.problems}>
                    <Problems />
                </div>
                <div ref={refs.allergies}>
                    <Allergies />
                </div>
                <div ref={refs.medications}>
                    <Medications />
                </div>
                <div ref={refs.vitals}>
                    <Vitals />
                </div>
                {/* <div ref={refs.pastMedicalHistory}>
        <PastMedicalHistory />
    </div>
    <div ref={refs.socialHistory}>
        <SocialHistory />
    </div> */}
                <div ref={refs.encounterHistory}>
                    <EncounterHistoryList />
                </div>
                <div ref={refs.questionnaires}>
                    <Questionnaires />
                </div>
                <div ref={refs.immunizations}>
                    <Immunizations />
                </div>
                <div ref={refs.documents}>
                    <Documents />
                </div>
            </Stack>
        </SectionScroller>
    );
}
function registerSignalRConfig(NewClinicalAlert: SignalRMessage, upsertPatientClinicalAlert: any) {
    throw new Error('Function not implemented.');
}
