import {
    DefaultEffects,
    IPersonaProps,
    IRenderFunction,
    Link,
    Persona,
    PersonaSize,
    Shimmer,
    Stack,
    Text,
    useTheme,
    TooltipHost,
    MotionAnimations,
    IPalette,
    IconButton,
} from '@fluentui/react';
import IPatient from 'api/models/patient.model';
import { useTenantId, useSelector } from 'hooks';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { useDispatch } from 'react-redux';
import { getPatientToEditAndOpenPanel } from 'state/slices/edit-patient/edit-patient.actions';
import formatPhoneNumber from 'utils/formatPhoneNumber';
import getFullName from 'utils/getPatientFullName';
import PatientAlertsCard from '../Scheduling/components/PatientAlertsCard';
import { find } from 'lodash';
import { useEffect, useState } from 'react';
import { selectVitalsReadings } from 'state/slices/patient/vitals/vitals.selectors';
import { genderIdentitiesList } from 'state/slices/tenant/gender-identities.slice';
import { calculateFullAge, classicDateOnly } from 'utils/dateOnly';
import schedulingApi from 'api/scheduling.api';
import { compareAsc, format, isAfter } from 'date-fns';
import { VitalNameType } from 'api/models/patient-vital.model';
import ClinicalAlertCallout from './ClinicalAlerts/ClinicalAlertCallout';
import { BannerItem } from 'components/Banner/Banner';
import { getInsuranceTypes } from './PatientDetailsBanner';
import { useHistory } from 'react-router-dom';
import { IUpcomingAppointment } from 'api/models/Scheduling/patientAppointment.model';
import { selectTenantPayersData } from 'state/slices/tenant/tenant-payers.slice';

type Props = {
    patient?: IPatient;
    upcomingAppointments?: IUpcomingAppointment[];
    renderSecondaryText?: IRenderFunction<IPersonaProps>;
    onEditPatient?: (params: { tenantId: string; patientId: string }) => void;
    enableEditPatient?: boolean;
    showPAMI?: boolean;
    showClinicalAlerts?: boolean;
    showHeightWeight?: boolean;
    showInsurances?: boolean;
    loading?: LoadingStatus;
    onPageNavigationClick?: (page: 'charting' | 'treatment-plans' | 'ledger') => void;
};

export const getBannerCardStyles = (palette: IPalette) => ({
    root: {
        backgroundColor: palette.neutralLighterAlt,
        border: `1px solid ${palette.neutralLighter}`,
        borderRadius: DefaultEffects.roundedCorner4,
    },
});

function PatientDetailsCard({
    patient,
    upcomingAppointments,
    renderSecondaryText,
    enableEditPatient,
    onEditPatient,
    showPAMI = false,
    showClinicalAlerts: showPreMedications,
    showHeightWeight = false,
    showInsurances = true,
    loading = undefined,
    onPageNavigationClick,
}: Props): JSX.Element {
    const { palette } = useTheme();
    const dispatch = useDispatch();
    const tenantId = useTenantId();
    const genderIdentities = useSelector(genderIdentitiesList);
    const readings = useSelector(selectVitalsReadings);
    const payersLookup = useSelector(selectTenantPayersData);

    const { push } = useHistory();

    const _editPatient = () => {
        if (patient) {
            if (onEditPatient) {
                onEditPatient({ tenantId, patientId: patient.id });
            } else {
                dispatch(getPatientToEditAndOpenPanel({ tenantId, patientId: patient.id }));
            }
        }
    };

    const renderPatientName = (): JSX.Element => {
        if (enableEditPatient) {
            return (
                <Text variant="large" styles={{ root: { fontWeight: 'bold' } }}>
                    <Link onClick={_editPatient}>{getFullName(patient)}</Link>
                </Text>
            );
        } else {
            return (
                <Text variant="large" styles={{ root: { fontWeight: 'bold' } }}>
                    {getFullName(patient)}
                </Text>
            );
        }
    };
    const assignedSexGenderIdentity = () => {
        if (patient) {
            return `${patient.legalSexId} | ${
                patient.genderIdentityId ? find(genderIdentities, ['id', patient.genderIdentityId])?.code : ''
            }`;
        }
    };

    const getGenderIdentity = () => {
        if (patient) {
            const genderIdentity = find(genderIdentities, ['id', patient.genderIdentityId]);
            return genderIdentity ? genderIdentity.code : '';
        }
    };
    const tooltipContent = () => {
        if (patient) {
            return (
                <Stack tokens={{ childrenGap: 10 }}>
                    <Text>
                        Age: {patient?.dateOfBirth ? <strong>{calculateFullAge(patient?.dateOfBirth ?? '')}</strong> : ''}
                    </Text>
                    <Text>Legal Sex: {patient.legalSexId ? <strong>{patient.legalSexId}</strong> : ''}</Text>
                    <Text>
                        Gender Identity: <strong>{getGenderIdentity()}</strong>
                    </Text>
                </Stack>
            );
        }
    };

    const { currentInsurance, isSelfPay, secondaryInsurance, slidingFee, tertiaryInsurance } = getInsuranceTypes(
        patient,
        payersLookup,
    );

    function getPatientWeight() {
        const weight = readings?.find((v) => v.vitalName === VitalNameType.Weight)?.value ?? '0';
        const weightInKg = Math.round(parseFloat(weight) * 0.001);
        return `${weightInKg} kg`;
    }
    function getPatientHeight() {
        const height = readings?.find((v) => v.vitalName === VitalNameType.Height)?.value ?? '0';

        return `${parseFloat(height).toFixed(2)} cm`;
    }

    function _onPageNavigationClick(page: 'charting' | 'treatment-plans' | 'ledger') {
        if (patient) {
            push(`/${tenantId}/patient/${patient.id}/${page}`);
            if (onPageNavigationClick) {
                onPageNavigationClick(page);
            }
        }
    }

    const patientId = patient?.mrn ? patient.mrn.substring(0, 10) : undefined;
    const phoneNumber = patient?.phoneNumbers?.length
        ? formatPhoneNumber(
              patient.phoneNumbers.find((res) => res.isPrimary)?.number
                  ? patient.phoneNumbers.find((res) => res.isPrimary)?.number
                  : patient.phoneNumbers.find((res) => res.type === 'Home')?.number,
          )
        : undefined;
    const ageSex = `(
        ${patient && patient.dateOfBirth ? calculateFullAge(patient.dateOfBirth) : ''}
        ${assignedSexGenderIdentity() ? assignedSexGenderIdentity() : 'N/A'} )`;
    const dob = patient?.dateOfBirth ? classicDateOnly(patient?.dateOfBirth, 'M/d/yyyy') : 'N/A';

    return (
        <Stack
            tokens={{ childrenGap: 10, padding: 12 }}
            styles={getBannerCardStyles(palette)}
            style={{ position: 'relative' }}
            horizontal
            horizontalAlign="space-between"
            wrap
        >
            <Stack>
                {(patient && !loading) || (loading === LoadingStatus.Completed && patient) ? (
                    <Stack grow style={{ animation: MotionAnimations.slideUpIn }}>
                        <Stack grow verticalAlign="center" wrap tokens={{ childrenGap: 10 }}>
                            <Stack grow horizontal tokens={{ childrenGap: 10 }} verticalAlign="center">
                                <Persona
                                    text={patient ? `${patient.firstName} ${patient.lastName}` : ''}
                                    onRenderSecondaryText={renderSecondaryText}
                                    size={PersonaSize.size32}
                                    hidePersonaDetails
                                />
                                <strong style={{ paddingRight: '17rem' }}>{renderPatientName()} </strong>
                                {showPreMedications && patient && <ClinicalAlertCallout patientId={patient.id} iconOnly />}
                                <Stack horizontal grow tokens={{ childrenGap: 5 }}>
                                    <TooltipHost content="Charting" id="tooth-chart">
                                        <IconButton
                                            iconProps={{ iconName: 'Teeth' }}
                                            onClick={() => _onPageNavigationClick('charting')}
                                            style={{ cursor: 'pointer', fontSize: '24px' }}
                                        />
                                    </TooltipHost>
                                    <TooltipHost content="Treatment Plans" id="treatment-plans">
                                        <IconButton
                                            iconProps={{ iconName: 'ClipboardList' }}
                                            onClick={() => _onPageNavigationClick('treatment-plans')}
                                            style={{ cursor: 'pointer', fontSize: '24px' }}
                                        />
                                    </TooltipHost>
                                    <TooltipHost content="Ledger" id="ledger">
                                        <IconButton
                                            iconProps={{ iconName: 'Money' }}
                                            onClick={() => _onPageNavigationClick('ledger')}
                                            style={{ cursor: 'pointer', fontSize: '24px' }}
                                        />
                                    </TooltipHost>
                                </Stack>
                            </Stack>

                            <Stack grow horizontal tokens={{ childrenGap: `5px 20px` }} wrap>
                                <BannerItem label="Patient Id" value={patientId} />
                                <BannerItem label="Date of birth" value={dob} />

                                <Stack>
                                    <TooltipHost delay={0} content={tooltipContent()}>
                                        <BannerItem label="Age/Sex" value={ageSex} />
                                    </TooltipHost>
                                </Stack>
                                <BannerItem label="Pronoun" value={patient?.pronoun} />
                                <BannerItem label="Phone" value={phoneNumber} />
                                <NextAppointment patient={patient} upcomingAppointments={upcomingAppointments} />
                                {showHeightWeight && (
                                    <>
                                        <BannerItem label="Height" value={getPatientHeight()} />
                                        <BannerItem label="Weight" value={getPatientWeight()} />
                                    </>
                                )}
                                {showInsurances && (
                                    <>
                                        {currentInsurance && <BannerItem label="Primary" value={currentInsurance} />}
                                        {secondaryInsurance && <BannerItem label="Secondary" value={secondaryInsurance} />}
                                        {tertiaryInsurance && <BannerItem label="Tertiary" value={tertiaryInsurance} />}
                                        {slidingFee && !tertiaryInsurance && (
                                            <BannerItem label="Pt. Discount" value={slidingFee} />
                                        )}
                                        {isSelfPay && <BannerItem label="Self Pay" value="Self Pay" />}
                                    </>
                                )}
                            </Stack>
                        </Stack>
                    </Stack>
                ) : (
                    <Shimmer style={{ animation: MotionAnimations.slideUpOut }} styles={{ shimmerWrapper: { height: 27 } }} />
                )}
            </Stack>

            {showPAMI && (
                <Stack horizontal horizontalAlign="space-between">
                    <PatientAlertsCard patient={patient} />
                </Stack>
            )}
        </Stack>
    );
}

export default PatientDetailsCard;

function NextAppointment({
    patient,
    upcomingAppointments,
}: {
    patient?: IPatient;
    upcomingAppointments?: IUpcomingAppointment[];
}): JSX.Element {
    const [nextAppointment, setNextAppointment] = useState<string>('');
    const tenantId = useTenantId();

    function _setNextAppointmentFromUpcomingAppts(upcomingAppointments: IUpcomingAppointment[]) {
        const dates: Date[] = [];
        upcomingAppointments.forEach((upcomingAppt) => {
            upcomingAppt.isDeleted ? null : dates.push(new Date(`${upcomingAppt.date}T${upcomingAppt.startTime}`));
        });
        const sortedDate = dates.sort(compareAsc);
        const nextAppointments = sortedDate.filter((currentDate) => {
            return isAfter(currentDate, new Date());
        });
        setNextAppointment(nextAppointments.length ? format(nextAppointments[0], 'M/d/yyyy h:mm aaa') : 'N/A');
    }

    useEffect(() => {
        if (upcomingAppointments) {
            _setNextAppointmentFromUpcomingAppts(upcomingAppointments);
        } else {
            if (patient)
                schedulingApi
                    .getUpcomingPatientAppointments(tenantId, patient.id)
                    .then((res) => {
                        _setNextAppointmentFromUpcomingAppts(res.data);
                    })
                    .catch();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patient, upcomingAppointments]);

    return <BannerItem label="Next Appt." value={nextAppointment} />;
}
