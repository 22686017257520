import { Dropdown, IDropdownProps } from '@fluentui/react';
import IPatientAppointment from 'api/models/Scheduling/patientAppointment.model';

export enum TrackerStatus {
    Arrived = 'arrived',
    CheckIn = 'checkIn',
    Ready = 'ready',
    Intake = 'intake',
    Exam = 'exam',
    Xray = 'xray',
    Prophy = 'prophy',
    Treatment = 'treatment',
    ReadyForCheckout = 'readyForCheckout',
    Dismissed = 'dismissed',
    Completed = 'completed',
}

export const trackerStatusText = {
    [TrackerStatus.Arrived]: 'Arrived',
    [TrackerStatus.CheckIn]: 'Check in',
    [TrackerStatus.Ready]: 'Ready - In Lobby',
    [TrackerStatus.Intake]: 'Intake',
    [TrackerStatus.Exam]: 'Exam',
    [TrackerStatus.Xray]: 'X-rays',
    [TrackerStatus.Prophy]: 'Prophy',
    [TrackerStatus.Treatment]: 'In treatment',
    [TrackerStatus.ReadyForCheckout]: 'Ready for checkout',

    [TrackerStatus.Dismissed]: 'With checkout',
    [TrackerStatus.Completed]: 'Checked Out',
};

type TrackerStatusDropdownProps = {
    appointment?: IPatientAppointment;
} & Omit<IDropdownProps, 'options'>;

function TrackerStatusDropdown({ appointment, ...props }: TrackerStatusDropdownProps): JSX.Element {
    const hasEncounter = !!appointment?.encounterId;
    const isCheckoutDisabled = !appointment?.trackerStatusId;
    const arrival = appointment?.trackerStatusId === 'arrived';
    const checkIn = appointment?.trackerStatusId === 'checkIn';
    const ready = appointment?.trackerStatusId === 'ready';
    const intake = appointment?.trackerStatusId === 'intake';
    const exam = appointment?.trackerStatusId === 'exam';
    const xray = appointment?.trackerStatusId === 'xray';
    const prophy = appointment?.trackerStatusId === 'prophy';
    const treatment = appointment?.trackerStatusId === 'treatment';
    const readyForCheckout = appointment?.trackerStatusId === 'readyForCheckout';
    const dismissed = appointment?.trackerStatusId === 'dismissed';
    const completed = appointment?.trackerStatusId === 'completed';

    const _options = [
        {
            key: TrackerStatus.Arrived,
            text: trackerStatusText[TrackerStatus.Arrived],
            disabled: !isCheckoutDisabled && !checkIn,
        },
        {
            key: TrackerStatus.CheckIn,
            text: trackerStatusText[TrackerStatus.CheckIn],
            disabled: !arrival && !checkIn,
        },
        {
            key: TrackerStatus.Ready,
            text: trackerStatusText[TrackerStatus.Ready],
            disabled:
                isCheckoutDisabled ||
                checkIn ||
                ready ||
                xray ||
                treatment ||
                prophy ||
                exam ||
                intake ||
                readyForCheckout ||
                dismissed ||
                arrival ||
                completed,
        },
        {
            key: TrackerStatus.Intake,
            text: trackerStatusText[TrackerStatus.Intake],
            disabled: !ready && !xray && !treatment && !prophy && !exam && !readyForCheckout,
        },
        {
            key: TrackerStatus.Exam,
            text: trackerStatusText[TrackerStatus.Exam],
            disabled: !ready && !xray && !treatment && !prophy && !intake && !readyForCheckout,
        },
        {
            key: TrackerStatus.Xray,
            text: trackerStatusText[TrackerStatus.Xray],
            disabled: !ready && !prophy && !treatment && !exam && !intake && !readyForCheckout,
        },
        {
            key: TrackerStatus.Prophy,
            text: trackerStatusText[TrackerStatus.Prophy],
            disabled: !ready && !xray && !treatment && !exam && !intake && !readyForCheckout,
        },
        {
            key: TrackerStatus.Treatment,
            text: trackerStatusText[TrackerStatus.Treatment],
            disabled: !ready && !xray && !prophy && !exam && !intake && !readyForCheckout,
        },
        {
            key: TrackerStatus.ReadyForCheckout,
            text: trackerStatusText[TrackerStatus.ReadyForCheckout],
            disabled: (!xray && !treatment && !prophy && !exam && !intake && !readyForCheckout) || !hasEncounter,
        },
        {
            key: TrackerStatus.Dismissed,
            text: trackerStatusText[TrackerStatus.Dismissed],
            disabled: isCheckoutDisabled || !dismissed,
        },
        {
            key: TrackerStatus.Completed,
            text: trackerStatusText[TrackerStatus.Completed],
            disabled: isCheckoutDisabled || !dismissed || !completed,
        },
    ].map((trackerOption) => {
        if (trackerOption.key === TrackerStatus.Dismissed || trackerOption.key === TrackerStatus.Completed) {
            return { ...trackerOption };
        } else {
            return trackerOption;
        }
    });
    return (
        <Dropdown
            selectedKey={appointment?.trackerStatusId}
            label="Patient Tracker"
            placeholder="Select Tracker Status"
            {...props}
            options={_options}
        />
    );
}

/**
 * Return the string associated with the trackerStatusId
 *
 * @export
 * @param {TrackerStatus} [trackerStatus]
 * @return {*}  {string}
 */
export function getTrackerStatusText(trackerStatus?: TrackerStatus): string {
    const trackerText = trackerStatus ? trackerStatusText[trackerStatus] : null;
    return trackerText ? trackerText : '';
}

export default TrackerStatusDropdown;
