import IPatient from 'api/models/patient.model';
import IUserTask from 'api/models/user-task.model';
import { IWorkListsMenu } from 'api/models/worklist-menu.model';
import { LoadingStatuses } from 'interfaces/loading-statuses';
import { createSelector } from 'reselect';
import { RootState } from 'state/store';
import { selectIsAccountGlobalAdmin, selectTenantClaimsAndUserRoles } from '../account.slice';
import { intersection } from 'lodash';
import { selectActiveFeatureFlagsLookup } from '../tenant/feature-flags.slice';

export const selectAdminHuddleTasks = (state: RootState): { data: IUserTask[]; loading: LoadingStatuses } =>
    state.adminHuddle.general.adminHuddleTasks;

export const selectEncounterPatient = (state: RootState): IPatient | undefined => state.adminHuddle.general.encounterPatient.data;

export const selectVisitSummaryPanelOpen = (state: RootState) => state.adminHuddle.general.visitSummary.isPanelOpen;

export const selectVisitSummaryState = (state: RootState) => state.adminHuddle.general.visitSummary;
export const selectVisitSummaryAmendmentSaving = createSelector(selectVisitSummaryState, (state) => state.savingAmendment);
export const selectVisitSummaryBillingProviderSaving = createSelector(
    selectVisitSummaryState,
    (state) => state.savingBillingProvider,
);
export const selectVisitSummaryMessageBar = createSelector(selectVisitSummaryState, (state) => ({
    messageBar: state.messageBarMessage,
    messageBarType: state.messageBarType,
}));

export const selectAmendmentId = (state: RootState): string | undefined => state.adminHuddle.general.visitSummary.amendmentId;
export const selectAmendmentNote = (state: RootState): string | undefined => state.adminHuddle.general.visitSummary.amendmentNote;
export const selectAmendmentType = (state: RootState): string | undefined => state.adminHuddle.general.visitSummary.amendmentType;
export const selectAmendmentTreatingProvider = (state: RootState): string | undefined => state.adminHuddle.general.visitSummary.amendmentTreatingProvider;

export const selectWorkListsMenu = (state: RootState): { data?: IWorkListsMenu; loading: LoadingStatuses } =>
    state.adminHuddle.general.menu.workLists;
export const selectWorkListsMenuLoading = (state: RootState) => state.adminHuddle.general.menu.workLists.loading;

export const selectWorkListsWithAccess = createSelector(
    selectWorkListsMenu,
    selectTenantClaimsAndUserRoles,
    selectIsAccountGlobalAdmin,
    selectActiveFeatureFlagsLookup,
    ({ data }, tenantClaimsAndRoles, isGlobalAdmin, featureFlags) => {
        if (!data) return [];

        const { workLists } = data;

        if (isGlobalAdmin) return workLists;

        const workListsWithPermission = workLists.filter(
            (worklist) =>
                (worklist.permissions?.length ? !!intersection(worklist.permissions, tenantClaimsAndRoles).length : true) &&
                (worklist.featureFlag ? !!featureFlags.has(worklist.featureFlag) : true),
        );

        return workListsWithPermission;
    },
);

export const selectGroupedWorkLists = createSelector(
    selectWorkListsMenu,
    selectWorkListsWithAccess,
    ({ data }, worklistsByPermission) => {
        if (!data) return;

        const { categories } = data;

        return categories.map((category) => {
            const workListItems = worklistsByPermission.filter((workList) => workList.categoryId === category.id);
            return {
                ...category,
                workLists: workListItems,
            };
        });
    },
);
