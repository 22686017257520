import { Icon, Stack } from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import { useTenantId } from 'hooks';
import BentoBoxPanel from './BentoBoxPanel';

export default function BentoBox(): JSX.Element | null {
    const tenantId = useTenantId();
    const [isOpen, { toggle: toggleIsOpen, setFalse: onDismiss }] = useBoolean(false);

    const WaffleStyle = {
        root: { color: '#fff', transform: 'scale(2)', marginLeft: 10, cursor: 'pointer' },
    };

    if (!tenantId) return null;

    return (
        <Stack horizontal>
            <Icon iconName="Waffle" onClick={toggleIsOpen} styles={WaffleStyle} />
            <BentoBoxPanel onDismiss={onDismiss} isOpen={isOpen} />
        </Stack>
    );
}
