import { Text, mergeStyleSets, useTheme, Icon } from '@fluentui/react';
import IWorkListEncounterView from 'api/models/worklist-encounter-view';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import { useSelector } from 'hooks';
import { selectIsCurrentWorkListEditEncounterWorkList } from 'state/slices/admin-huddle/worklists/worklist.selectors';
import { classicDateOnly } from 'utils/dateOnly';

const encounterDate: ISortableColumn<IWorkListEncounterView> = {
    key: 'encounterDate',
    name: 'Enc. Date',
    fieldName: 'encounterDate',
    minWidth: 75,
    maxWidth: 75,
    isResizable: true,
    onRender: EncounterDateRenderComponent,
};

function EncounterDateRenderComponent(item?: IWorkListEncounterView) {
    const isEditEncounterWorklist = useSelector(selectIsCurrentWorkListEditEncounterWorkList);

    const hasRCMBadge = isEditEncounterWorklist && item?.isRcm;

    const { wrapperDiv, rcmFlagIconWrapper } = mergeStyleSets({
        wrapperDiv: {
            position: 'relative',
        },
        rcmFlagIconWrapper: {
            position: 'absolute',
            top: -3,
            right: -6,
       },
    });

    if (item)
        return (
            <div className={wrapperDiv}>
                {hasRCMBadge && (
                    <span title="Billing Services" className={rcmFlagIconWrapper}>
                        <Icon iconName="RcmIcon"></Icon>
                    </span>
                )}
                <Text variant="small"> {item.encounterDate ? classicDateOnly(item.encounterDate) : 'N/A'} </Text>
            </div>
        );
}

export default encounterDate;
