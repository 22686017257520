import { RootState } from 'state/store';
import { GetLookupsRequest } from 'models/requestTypes';
import { SoftDeleteSupport } from 'models/contracts';
import { createSelector } from 'reselect';
import { proceduresList, selectProceduresData } from '../tenant/procedures.slice';
import { map } from 'lodash';
import { IProcedureDetail } from 'api/models/procedure-detail.model';
import { IComboBoxOption } from '@fluentui/react';

export const selectLookups = (state: RootState, { tenantId, lookupType }: GetLookupsRequest) =>
    state.lookups?.tenants?.[tenantId]?.[lookupType] ?? {};

export const selectLookupsList = function <T>(state: RootState, { tenantId, lookupType }: GetLookupsRequest): T[] {
    const lookups = selectLookups(state, { tenantId, lookupType });
    const items: T[] = [];

    for (const id in lookups.items) {
        items.push(lookups.items[id] as T);
    }

    return items.filter((item) => !(item as unknown as SoftDeleteSupport)?.isDeleted);
};

export const selectSelectedLookup = (state: RootState, { tenantId, lookupType }: GetLookupsRequest) =>
    state.lookups?.tenants?.[tenantId]?.[lookupType]?.selectedItem;
export const selectProcedureDetailLookup = (state: RootState, tenantId: string) =>
    state.lookups?.tenants?.[tenantId]?.['ProcedureDetails'].items ?? {};

export const selectProcedureTemplatesLookup = (state: RootState, tenantId: string) =>
    state.lookups?.tenants?.[tenantId]?.['ProcedureDetails']
        ? (map(state.lookups?.tenants?.[tenantId]?.['ProcedureDetails'].items)
              .filter((p: IProcedureDetail) => !p?.isDeleted)
              .filter((p: IProcedureDetail) => !!p?.template) as IProcedureDetail[])
        : [];

export const selectProcedureDetailOptions = createSelector(
    selectProcedureDetailLookup,
    proceduresList,
    (procedureDetails, procedures): IComboBoxOption[] => {
        function getProcedureDetail(procedureId: string) {
            const procedureDetail: IProcedureDetail | undefined = procedureDetails[procedureId];
            if (procedureDetail?.isDeleted) return undefined;
            return procedureDetail;
        }

        return procedures
            .filter((p) => !getProcedureDetail(p.id)?.template)
            .map((p) => {
                const textValue = `${p.code} - ${p.displayName}`;
                const text = textValue.length >= 75 ? `${textValue.slice(0, 75)}...` : textValue;
                return {
                    key: p.id,
                    text,
                    data: { displayName: p?.displayName, code: p?.code, procedureDetail: getProcedureDetail(p.id) },
                    title: textValue,
                };
            });
    },
) as (state: RootState, tenantId: string) => IComboBoxOption[];
