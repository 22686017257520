import IForm from 'api/models/form';

export interface ICariesRiskChildForm extends IForm {
    data: ISoftTissueExamQuestions;
}

export enum SoftTissueExamValue {
    Abnormal = 'Abnormal',
    WithinNormalLimits = 'Within Normal Limits',
    None = 'None',
    Empty = '',
}

export interface ISoftTissueExamQuestions {
    // * Soft Tissue Exam
    lymphNodes?: SoftTissueExamValue;
    lymphNodesNotes?: string;

    tmj?: SoftTissueExamValue;
    tmjNotes?: string;

    lips?: SoftTissueExamValue;
    lipsNotes?: string;

    buccalMucosa?: SoftTissueExamValue;
    buccalMucosaNotes?: string;

    vestibularMucosa?: SoftTissueExamValue;
    vestibularMucosaNotes?: string;

    floorOfMouth?: SoftTissueExamValue;
    floorOfMouthNotes?: string;

    tongue?: SoftTissueExamValue;
    tongueNotes?: string;

    hardPalate?: SoftTissueExamValue;
    hardPalateNotes?: string;

    softPalate?: SoftTissueExamValue;
    softPalateNotes?: string;

    pharynx?: SoftTissueExamValue;
    pharynxNotes?: string;

    tonsils?: SoftTissueExamValue;
    tonsilsNotes?: string;

    gingiva?: SoftTissueExamValue;
    gingivaNotes?: string;

    //* Other
    occlusion?: 'Class 1' | 'Class 2' | 'Class 3' | '';
    midlines?: 'On' | 'Off' | '';
    toLeft?: number;
    toRight?: number;
    overbite?: string;
    overjet?: string;
    oralHygiene?: 'Excellent' | 'Good' | 'Fair' | 'Poor' | '';
    calculus?: 'None' | 'Little' | 'Moderate' | 'Heavy' | '';
    gingivalBleeding?: 'Localized' | 'General' | 'None' | '';
    perioExam?: 'Yes' | 'No' | '';
    crossbite?: 'Right' | 'Left' | 'Both' | '';

    // * PSR Upper Arch
    upperRight?: number;
    upperCenter?: number;
    upperLeft?: number;

    // * PSR Lower Arch
    lowerRight?: number;
    lowerCenter?: number;
    lowerLeft?: number;
}
