import { Stack, Text, TooltipHost, mergeStyles, useTheme } from '@fluentui/react';
import { ChartProcedureStatus } from 'api/models/chart.model';
import { ProcedureSummary } from 'api/models/encounter-ledger.model';
import { getTeethDisplayName, getShorthandToothAreas } from 'utils';

const RenderProcedureSummaryTooltip = ({ item }: { item?: ProcedureSummary }) => {
    const areasNotToBeIncluded = item?.areas?.includes('Root') || item?.areas?.includes('Crown');
    const noToolTip =
        !item?.toothIds?.length &&
        !item?.areas?.length &&
        !item?.stage &&
        !item?.preAuthorizationCode &&
        !item?.procedureDescription;

    return (
        <TooltipHost
            content={
                !noToolTip ? (
                    <Stack>
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            {item?.toothIds?.length ? (
                                <Stack>
                                    <Text variant="smallPlus">
                                        <strong>Tooth </strong>
                                    </Text>
                                    <Text variant="smallPlus">{getTeethDisplayName(item?.toothIds) ?? ''}</Text>
                                </Stack>
                            ) : null}
                            {item?.areas?.length && !areasNotToBeIncluded ? (
                                <Stack>
                                    <Text variant="smallPlus">
                                        <strong>Area </strong>
                                    </Text>
                                    <Text variant="smallPlus">{getShorthandToothAreas(item?.areas) ?? ''}</Text>
                                </Stack>
                            ) : null}
                            {item?.stage && (
                                <Stack>
                                    <Text variant="smallPlus">
                                        <strong>Stage </strong>
                                    </Text>
                                    <Text variant="smallPlus">{item?.stage ?? ''}</Text>
                                </Stack>
                            )}
                            {item?.preAuthorizationCode && (
                                <Stack>
                                    <Text variant="smallPlus">
                                        <strong>Auth# </strong>
                                    </Text>
                                    <Text variant="smallPlus">{item?.preAuthorizationCode ?? ''}</Text>
                                </Stack>
                            )}
                        </Stack>
                        {item?.procedureDescription && (
                            <Stack>
                                <Text variant="smallPlus">
                                    <strong>Description </strong>
                                </Text>
                                <Text variant="smallPlus">{item?.procedureDescription ?? ''}</Text>
                            </Stack>
                        )}
                    </Stack>
                ) : undefined
            }
            // Give the user more time to interact with the tooltip before it closes
            closeDelay={500}
        >
            <ChartProcedureLabel label={item?.procedureCode} chartProcedureStatus={item?.procedureStatus} />
        </TooltipHost>
    );
};

type Props = {
    label: string | undefined;
    chartProcedureStatus: (ChartProcedureStatus | keyof typeof ChartProcedureStatus) | undefined;
};

export function ChartProcedureLabel({ chartProcedureStatus, label }: Props) {
    const { palette } = useTheme();

    const textWrapper = mergeStyles({
        position: 'relative',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipses',
    });

    const voidLabelWrapper = mergeStyles({
        transform: 'rotate(30deg)',
    });

    const voidText = mergeStyles({
        color: palette.magenta,
    });

    return (
        <Stack tokens={{ childrenGap: 5 }} horizontal>
            <Text className={textWrapper} variant="smallPlus">
                {label ?? 'Unknown procedure'}
            </Text>
            {chartProcedureStatus === ChartProcedureStatus.Void && (
                <div className={voidLabelWrapper}>
                    <Text className={voidText} variant="small">
                        <b>Void</b>
                    </Text>
                </div>
            )}
        </Stack>
    );
}

export default RenderProcedureSummaryTooltip;
