import { IPredeterminationProcedure } from "api/models/predeterminations.model"
import { ISortableColumn } from "components/SortableDetailsList/SortableDetailsList"

const PreAuthorizationColumn: ISortableColumn<IPredeterminationProcedure> = {
    name: "Status",
    minWidth: 130,
    key: "preauth",
    fieldName: 'preAuthorization',
}

export default PreAuthorizationColumn
