import { DefaultButton, Stack } from '@fluentui/react';
import IWorkListEncounterView from 'api/models/worklist-encounter-view';
import { useTenantId } from 'hooks';
import usePermissions from 'hooks/store/usePermissions';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectEncounterPatient } from 'state/slices/admin-huddle/admin-huddle.selectors';
import { EncounterWorkList } from 'state/slices/admin-huddle/worklists/encounter-worklists/encounter-worklists.state';
import { selectCurrentWorkList, selectWorkListEditItem } from 'state/slices/admin-huddle/worklists/worklist.selectors';
import { MissingSlipsWorkList } from 'state/slices/admin-huddle/worklists/worklist.state';
import { FeatureFlag, selectActiveFeatureFlagsLookup } from 'state/slices/tenant/feature-flags.slice';

export default function VisitSummaryGoToClaimHistoryButton() {
    const tenantId = useTenantId();
    const patient = useSelector(selectEncounterPatient);

    const [_, isGlobalAdmin] = usePermissions();
    const featureFlags = useSelector(selectActiveFeatureFlagsLookup);

    const selectedView = useSelector(selectWorkListEditItem<IWorkListEncounterView>);
    const currentWorkList = useSelector(selectCurrentWorkList);

    const hasClaimsHistoryAccess = useCallback(() => {
        if (isGlobalAdmin) return true;
        return !!featureFlags.has(FeatureFlag.ClaimHistory);
    }, [isGlobalAdmin, featureFlags]);

    if (
        !hasClaimsHistoryAccess() ||
        (currentWorkList && !!MissingSlipsWorkList[currentWorkList as MissingSlipsWorkList]) ||
        currentWorkList === EncounterWorkList.ReadyToReview ||
        currentWorkList === EncounterWorkList.ReadyToReviewOnHold
    )
        return null;

    const _navigateToPatientChartWithEncounter = () => {
        if (!selectedView) return;
        const selectedViewEncounterId = selectedView.encounter?.id ?? selectedView.encounterId;
        window.open(`/${tenantId}/patient/${patient?.id}/ledger/claim-history/${selectedViewEncounterId}`);
    };

    return (
        <Stack.Item>
            <DefaultButton
                onClick={_navigateToPatientChartWithEncounter}
                iconProps={{ iconName: 'NavigateExternalInline' }}
                text="Go to Encounter Claim History"
                disabled={!selectedView}
            />
        </Stack.Item>
    );
}
