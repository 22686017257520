import { Checkbox, Stack, TextField } from '@fluentui/react';
import IPatient, { IPatientAddress, IPatientInsurance, IPolicyHolder } from 'api/models/patient.model';
import { Field, SubSection } from 'components';
import states from 'components/Field/StateOptions';
import ZipCodeField from 'components/Field/ZipCodeField';
import { getValidationError, IValidationError } from 'hooks/useValidation';

type Props = {
    insurance?: IPatientInsurance;
    newInsurancePropChange: (path: keyof IPatientInsurance, value: unknown) => void;
    newPolicyHolderPropChange: (path: keyof IPolicyHolder, value: unknown) => void;
    validationErrors: IValidationError[];

    address?: IPatientAddress;
};

function PolicyHolderAddressFields({
    insurance,
    newInsurancePropChange,
    newPolicyHolderPropChange,
    validationErrors,
    address,
}: Props): JSX.Element {
    const policyHolderAddressStateType = states.find((state) => insurance?.policyHolder?.state === state.key)?.data?.type;
    return (
        <Stack tokens={{ childrenGap: 5 }}>
            <SubSection
                title="Mailing Address"
                headingCenterContent={
                    <Stack horizontal>
                        <Checkbox
                            label={
                                insurance?.policyHolderType === 'Other Contact'
                                    ? 'Use patient mailing address on file'
                                    : 'Use mailing address on file'
                            }
                            checked={insurance?.policyHolder.policyHolderAddressSameAsPatient}
                            onChange={(e, checked) => {
                                let data: IPolicyHolder | undefined = undefined;
                                if (insurance) {
                                    if (!checked) {
                                        data = {
                                            ...insurance.policyHolder,

                                            policyHolderAddressSameAsPatient: false,
                                            streetAddress1: '',
                                            streetAddress2: '',
                                            city: '',
                                            state: '',
                                            zip: '',
                                        };
                                    } else {
                                        data = {
                                            ...insurance.policyHolder,
                                            policyHolderAddressSameAsPatient: true,
                                            ...address,
                                        };
                                    }
                                }
                                newInsurancePropChange('policyHolder', data);
                            }}
                        />
                    </Stack>
                }
            >
                <Stack>
                    <Stack tokens={{ childrenGap: 10 }} grow>
                        <Stack horizontal tokens={{ childrenGap: 10 }} grow>
                            <Stack.Item grow>
                                <TextField
                                    label="Address Line 1"
                                    value={insurance?.policyHolder?.streetAddress1}
                                    autoComplete="off"
                                    onChange={(ev, value) => newPolicyHolderPropChange('streetAddress1', value)}
                                    required
                                    disabled={insurance?.policyHolder?.policyHolderAddressSameAsPatient}
                                    errorMessage={
                                        getValidationError(validationErrors, 'Address Line 1')
                                            ? 'Address Line 1 is required.'
                                            : undefined
                                    }
                                />
                            </Stack.Item>
                            <Stack.Item grow>
                                <TextField
                                    label=" Address Line 2"
                                    value={insurance?.policyHolder?.streetAddress2}
                                    autoComplete="off"
                                    onChange={(ev, value) => newPolicyHolderPropChange('streetAddress2', value)}
                                    disabled={insurance?.policyHolder?.policyHolderAddressSameAsPatient}
                                />
                            </Stack.Item>
                        </Stack>
                        <Stack horizontal tokens={{ childrenGap: 10 }} grow>
                            <Stack.Item grow>
                                <TextField
                                    label="City"
                                    placeholder=""
                                    value={insurance?.policyHolder?.city}
                                    autoComplete="off"
                                    required
                                    disabled={insurance?.policyHolder?.policyHolderAddressSameAsPatient}
                                    onChange={(ev, value) => newPolicyHolderPropChange('city', value)}
                                    errorMessage={getValidationError(validationErrors, 'City') ? 'City is required.' : undefined}
                                />
                            </Stack.Item>
                            <Field.SearchCombo
                                label="State"
                                placeholder="(Select State)"
                                onChange={(e, option) => {
                                    const value = option ? (option.key as string).toUpperCase() : '';
                                    newPolicyHolderPropChange('state', value);
                                }}
                                selectedKey={
                                    insurance?.policyHolder?.state !== undefined
                                        ? insurance?.policyHolder?.state.toUpperCase()
                                        : undefined
                                }
                                options={states}
                                required
                                disabled={insurance?.policyHolder?.policyHolderAddressSameAsPatient}
                                errorMessage={getValidationError(validationErrors, 'State') ? 'State is required.' : undefined}
                            />
                            <ZipCodeField
                                label="Zip"
                                autoComplete="off"
                                onChange={(ev, value) => newPolicyHolderPropChange('zip', value)}
                                required
                                stateType={policyHolderAddressStateType}
                                value={insurance?.policyHolder?.zip}
                                disabled={insurance?.policyHolder?.policyHolderAddressSameAsPatient}
                                errorMessage={getValidationError(validationErrors, 'Zip') ? 'Zip is required.' : undefined}
                            />
                        </Stack>
                    </Stack>
                </Stack>
            </SubSection>
        </Stack>
    );
}

export default PolicyHolderAddressFields;
