import { Stack } from '@fluentui/react';
import { TModal } from 'components';
import { useSelector, useValidation } from 'hooks';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { RouteParams } from 'interfaces/route-params';
import { BatchField } from 'pages/components/Batch/BatchField';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { reverseSelectedPatientAdjustmentTransactions } from 'state/slices/ledger/adjustment-history/adjustment-history.actions';
import {
    selectAdjustmentHistoryReversalSavingStatus,
    selectAdjustmentHistoryReverseConfirmationModalOpen,
} from 'state/slices/ledger/adjustment-history/adjustment-history.selectors';
import { setAdjustmentHistoryConfirmModalOpen, setSelectedAdjustmentHistoryViews } from 'state/slices/ledger/ledger.slice';
import { selectSelectedBatch } from 'state/slices/tenant/batches.slice';

export default function ConfirmAdjustmentReversalModal() {
    const dispatch = useDispatch();
    const isOpen = useSelector(selectAdjustmentHistoryReverseConfirmationModalOpen);
    const savingStatus = useSelector(selectAdjustmentHistoryReversalSavingStatus);

    const { tenantId, patientId } = useParams<RouteParams>();

    const selectedBatch = useSelector(selectSelectedBatch);

    const _onDismiss = () => {
        dispatch(setAdjustmentHistoryConfirmModalOpen(false));
    };

    const onSubmit = () => {
        if (selectedBatch) dispatch(reverseSelectedPatientAdjustmentTransactions({ tenantId, patientId, selectedBatch }));
    };

    const [errors, submit, cleanupValidation] = useValidation(
        [
            {
                fieldName: 'Batch',
                validation: ['required'],
                value: selectedBatch,
            },
        ],
        onSubmit,
    );

    const _onDismissed = () => {
        cleanupValidation();
        dispatch(setSelectedAdjustmentHistoryViews([]));
    };

    const disableSave = savingStatus === LoadingStatus.Pending;

    return (
        <TModal
            title="Adjustment Reversal"
            isDraggable
            mainButtons={[
                {
                    type: 'PrimaryButton',
                    text: 'Save',
                    onClick: submit,
                    disabled: disableSave,
                },
                {
                    text: 'Cancel',
                    onClick: _onDismiss,
                },
            ]}
            modalProps={{ isOpen, onDismiss: _onDismiss, onDismissed: _onDismissed }}
        >
            <Stack styles={{ root: { padding: 10 } }}>
                <BatchField error={errors} textfieldProps={{ label: 'Batch' }} />
            </Stack>
        </TModal>
    );
}
