import { ComboBox, IDropdownOption, Stack, TextField } from '@fluentui/react';
import IPatient from 'api/models/patient.model';
import { Field, Section } from 'components';
import { useSelector } from 'hooks';
import { useFeatureFlag } from 'hooks/useFeatureFlags';
import { getValidationError, validateErrorMessage } from 'hooks/useValidation';
import { useDispatch } from 'react-redux';
import { selectEditPatient, selectEditPatientValidationErrors } from 'state/slices/edit-patient/edit-patient.selectors';
import { editPatientPropChange } from 'state/slices/edit-patient/edit-patient.slice';
import { FeatureFlag } from 'state/slices/tenant/feature-flags.slice';
import { legalSexesOptionsList } from 'state/slices/tenant/legal-sexes.slice';
import { classicDateOnly } from 'utils/dateOnly';
import { BasicDetailsFields } from '../BasicDetails';
import PatientAddress from './PatientAddress';
import PatientInfoReadOnly from './PatientInfoReadOnly';

function PatientInfo(): JSX.Element {
    const dispatch = useDispatch();
    const legalSexOptions = useSelector(legalSexesOptionsList);
    const patient = useSelector(selectEditPatient);

    const validationErrors = useSelector(selectEditPatientValidationErrors);

    const _propChange = (key: keyof IPatient, value: unknown): void => {
        dispatch(editPatientPropChange({ key, value }));
    };

    const suffixOptions: IDropdownOption[] = [
        {
            key: '',
            text: 'None',
        },
        {
            key: 'Jr.',
            text: 'Jr.',
        },
        {
            key: 'Sr.',
            text: 'Sr.',
        },
        {
            key: 'I',
            text: 'I',
        },
        {
            key: 'II',
            text: 'II',
        },
        {
            key: 'III',
            text: 'III',
        },
        {
            key: 'IV',
            text: 'IV',
        },
        {
            key: 'V',
            text: 'V',
        },
    ];

    const validTextInput = (value?: string): boolean => {
        const res = value ? /^(?!\s)[a-zA-Z\s-']*$/.test(value) : true;
        return res;
    };

    return (
        <Section>
            <Stack tokens={{ childrenGap: 10 }}>
                <Stack tokens={{ childrenGap: 10 }} horizontal grow>
                    <Stack.Item grow>
                        <TextField
                            label="First Name"
                            placeholder="Enter first name"
                            value={patient?.firstName ?? ''}
                            autoComplete="off"
                            onChange={(ev, value) => {
                                if (validTextInput(value)) {
                                    _propChange('firstName', value ? value : '');
                                }
                            }}
                            required
                            errorMessage={
                                getValidationError(validationErrors, BasicDetailsFields.FirstName)
                                    ? 'First Name is required.'
                                    : undefined
                            }
                        />
                    </Stack.Item>
                    <Stack.Item grow>
                        <TextField
                            label="Middle Name"
                            placeholder="Enter middle name"
                            value={patient?.middleName ?? ''}
                            autoComplete="off"
                            onChange={(ev, value) => {
                                if (validTextInput(value)) {
                                    _propChange('middleName', value ? value : '');
                                }
                            }}
                        />
                    </Stack.Item>
                    <Stack.Item grow>
                        <TextField
                            label="Last Name"
                            placeholder="Enter last name"
                            value={patient?.lastName ?? ''}
                            autoComplete="off"
                            onChange={(ev, value) => {
                                if (validTextInput(value)) {
                                    _propChange('lastName', value ? value : '');
                                }
                            }}
                            required
                            errorMessage={
                                getValidationError(validationErrors, BasicDetailsFields.LastName)
                                    ? 'Last Name is required.'
                                    : undefined
                            }
                        />
                    </Stack.Item>
                    <Stack.Item style={{ width: 150 }}>
                        <Field.Dropdown
                            label="Suffix"
                            placeholder="Suffix"
                            selectedKey={patient?.suffix ?? undefined}
                            options={suffixOptions}
                            onChange={(ev, option) => {
                                if (option) _propChange('suffix', option.key);
                            }}
                        />
                    </Stack.Item>
                </Stack>
                <Stack tokens={{ childrenGap: 10 }} horizontal grow>
                    <Stack.Item grow>
                        <TextField
                            label="Chosen Name"
                            placeholder="Enter chosen name"
                            value={patient?.chosenName ?? ''}
                            autoComplete="off"
                            onChange={(ev, value) => {
                                if (validTextInput(value)) {
                                    _propChange('chosenName', value ? value : '');
                                }
                            }}
                        />
                    </Stack.Item>

                    <Stack.Item grow>
                        <Field.Date
                            label="Date of Birth"
                            value={patient?.dateOfBirth ? classicDateOnly(patient.dateOfBirth) : ''}
                            onChange={(e, value) => {
                                _propChange('dateOfBirth', value ? classicDateOnly(value, 'yyyy-MM-dd') : '');
                            }}
                            hasDatePicker
                            autoComplete="off"
                            required
                            errorMessage={validateErrorMessage(
                                getValidationError(validationErrors, BasicDetailsFields.DateOfBirth),
                            )}
                        />
                    </Stack.Item>

                    <Stack.Item grow>
                        <ComboBox
                            options={legalSexOptions}
                            label="Legal Sex"
                            required
                            placeholder="Select"
                            allowFreeform
                            selectedKey={patient?.legalSexId ?? undefined}
                            onChange={(e, option) => {
                                if (option) _propChange('legalSexId', option.key);
                            }}
                            useComboBoxAsMenuWidth
                            errorMessage={
                                !patient?.legalSexId
                                    ? getValidationError(validationErrors, BasicDetailsFields.LegalSex)
                                        ? 'Legal Sex is required.'
                                        : ''
                                    : ''
                            }
                        />
                    </Stack.Item>
                </Stack>
                <PatientAddress />
            </Stack>
        </Section>
    );
}

function NegotiatePatientInfo() {
    const isPatientReadOnly = useFeatureFlag(FeatureFlag.PatientReadOnly);
    return isPatientReadOnly == true ? <PatientInfoReadOnly /> : <PatientInfo />;
}
export default NegotiatePatientInfo;
