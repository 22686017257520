import { IChartProcedure } from 'api/models/chart.model';
import { createRule } from '../../ruleGenerator';
import ProcedureCodes from '../../procedureCodes';
import ProcedureBusinessRulesPipeline from '../procedureBusinessRules.pipeline';
import { v4 as uuid } from 'uuid';

const groupTeethPerArch = createRule<ProcedureBusinessRulesPipeline, IChartProcedure>({
    ruleTypes: [
        ProcedureCodes.D5211,
        ProcedureCodes.D5212,

        // TODO: Ensure the rest of these codes exist in archProcedureCodeLookup
        // TODO: Ensure the TenantProcedure codes have "partial-arch" conflictRule
        // TODO: Fix one-per-area rule so it picks out ANY tooth not where it matches exactly.

        ProcedureCodes.D5213,
        ProcedureCodes.D5214,

        ProcedureCodes.D5221,
        ProcedureCodes.D5222,

        ProcedureCodes.D5223,
        ProcedureCodes.D5224,

        ProcedureCodes.D5225,
        ProcedureCodes.D5226,

        ProcedureCodes.D5227,
        ProcedureCodes.D5228,

        ProcedureCodes.D5720,
        ProcedureCodes.D5721,

        ProcedureCodes.D5740,
        ProcedureCodes.D5741,

        ProcedureCodes.D5760,
        ProcedureCodes.D5761,

        ProcedureCodes.D5820,
        ProcedureCodes.D5821,

        ProcedureCodes.D5864,
        ProcedureCodes.D5866,
    ],
    rule: (pipeline, item) => {
        if (item) {
            //do stuff
            const { UA, LA } = pipeline.getToothIdsByArch(item);

            const uaProcedure = getProceduresBySelectedArchTeeth(UA);
            const laProcedure = getProceduresBySelectedArchTeeth(LA);
            const updatedItems: IChartProcedure[] = [uaProcedure, laProcedure].filter(
                (procedure) => procedure !== undefined,
            ) as IChartProcedure[];

            return { updatedItems };
        }
        //output may be two procedures
        return { updatedItems: item };

        function getProceduresBySelectedArchTeeth(teethIds: number[]): IChartProcedure | undefined {
            if (teethIds.length) {
                // TODO: Fix sorting of teethIds here
                const chartProcedure: IChartProcedure = { ...item, id: uuid(), toothIds: teethIds.sort() };
                return pipeline.getValidArchProcedureForArch(chartProcedure);
            }
        }
    },
});

export default groupTeethPerArch;
