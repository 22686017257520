import { Stack } from '@fluentui/react';
import { ReportType } from 'api/models/embed-report.model';
import { IUDSTable9DReportQuery } from 'api/models/udsTable.model';
import { Field } from 'components';
import { useSelector, useTenantId } from 'hooks';
import { getValidationError } from 'hooks/useValidation';
import ReportWrapper from 'pages/Reporting/ReportWrapper';
import React from 'react';
import { useDispatch } from 'react-redux';
import reportActionLookup from 'state/slices/reporting/reportActionLookup';
import { selectReportProperties, selectReportingValidationErrors } from 'state/slices/reporting/reporting.selectors';
import { setUDSTableProp } from 'state/slices/reporting/reporting.slice';
import { classicDateOnly } from 'utils/dateOnly';

function UDSTable9DDetails() {
    const tenantId = useTenantId();
    const dispatch = useDispatch();
    const errors = useSelector(selectReportingValidationErrors);
    const query = useSelector(selectReportProperties) as IUDSTable9DReportQuery;

    const _onViewReport = () => {
        if (query) dispatch(reportActionLookup[ReportType.UDSTable9DDetails]({ tenantId, ...query }));
    };

    return (
        <ReportWrapper
            validationConfig={[
                {
                    fieldName: 'Start Date',
                    validation: ['required'],
                    value: query?.startDate,
                },
                {
                    fieldName: 'End Date',
                    validation: ['required'],
                    value: query?.endDate,
                },
            ]}
            onViewReport={_onViewReport}
        >
            <Stack tokens={{ childrenGap: 10 }} grow horizontal wrap>
                <Stack.Item>
                    <Field.Date
                        label="Start Date"
                        required
                        isReasonable
                        hasDatePicker
                        value={query?.startDate ? classicDateOnly(query?.startDate, 'MM/dd/yyyy') : ''}
                        onChange={(ev, value) => {
                            const newDate = value ? classicDateOnly(value, 'yyyy-MM-dd') : undefined;
                            dispatch(setUDSTableProp({ path: 'startDate', value: newDate }));
                        }}
                        errorMessage={getValidationError(errors, 'Start Date') ? 'Start Date is required.' : undefined}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.Date
                        label="End Date"
                        required
                        isReasonable
                        hasDatePicker
                        value={query?.endDate ? classicDateOnly(query?.endDate, 'MM/dd/yyyy') : ''}
                        onChange={(ev, value) => {
                            const newDate = value ? classicDateOnly(value, 'yyyy-MM-dd') : undefined;
                            dispatch(setUDSTableProp({ path: 'endDate', value: newDate }));
                        }}
                        errorMessage={getValidationError(errors, 'End Date') ? 'End Date is required.' : undefined}
                    />
                </Stack.Item>
            </Stack>
        </ReportWrapper>
    );
}

export default UDSTable9DDetails;
