import { Stack, SelectionMode, DetailsRowCheck, DetailsRow, useTheme } from '@fluentui/react';
import { IMostRecentTreatmentPlanPhaseView } from 'api/models/most-recent-treatment-plan-view.model';
import { SortableDetailsList } from 'components';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import { useDispatch, useSelector } from 'react-redux';
import { setTreatmentPlanCreditSelectedPhaseView } from 'state/slices/ledger/ledger.slice';
import { selectTreatmentPlanCreditSelectedPhaseView } from 'state/slices/ledger/treatment-plan-credit-and-uac/treatment-plan-credit-and-uac.selectors';
import { usdCurrencyFormatter } from 'utils';
import TreatmentPlanCreditPhaseViewTooltip from './TreatmentPlanCreditPhaseViewTooltip';

type Props = {
    phases: IMostRecentTreatmentPlanPhaseView[] | undefined;
};

export default function TreatmentPlanPhaseViewList({ phases }: Props) {
    const dispatch = useDispatch();

    const { palette } = useTheme();

    const selectedPhaseView = useSelector(selectTreatmentPlanCreditSelectedPhaseView);

    function onSelectItem(item: IMostRecentTreatmentPlanPhaseView | undefined) {
        if (selectedPhaseView?.id === item?.id) {
            dispatch(setTreatmentPlanCreditSelectedPhaseView(undefined));
        } else {
            dispatch(setTreatmentPlanCreditSelectedPhaseView(item));
        }
    }

    function isItemSelected(item: IMostRecentTreatmentPlanPhaseView | undefined) {
        return selectedPhaseView?.id === item?.id;
    }

    const columns: ISortableColumn<IMostRecentTreatmentPlanPhaseView>[] = [
        {
            key: 'checkbox',
            minWidth: 48,
            maxWidth: 48,
            name: '',
            onRender: (item) => {
                //Nested details list will infinitely grow if we use selectionmode.single on the details list itself. Must custom render this to avoid it.
                return (
                    <DetailsRowCheck
                        onClick={() => {
                            onSelectItem(item);
                        }}
                        styles={{ root: { padding: 0 } }}
                        canSelect
                        compact
                        isVisible
                        selected={isItemSelected(item)}
                        selectionMode={SelectionMode.single}
                    />
                );
            },
        },
        {
            key: 'phaseName',
            minWidth: 100,
            maxWidth: 120,
            name: 'Phase',
            onRender: (item) => {
                return <TreatmentPlanCreditPhaseViewTooltip phase={item} />;
            },
        },
        {
            key: 'preAuthStatus',
            minWidth: 100,
            maxWidth: 120,
            name: 'Pre-Auth Status',
            fieldName: 'preAuthStatus',
        },
        {
            key: 'prePayments',
            minWidth: 100,
            maxWidth: 150,
            name: 'Prepayments',
            fieldName: 'prePayments',
            onRender: (item) => <span>{usdCurrencyFormatter.format(item?.prePayments ?? 0)}</span>,
        },
        {
            key: 'remainingPatientEstimate',
            minWidth: 100,
            name: 'Remaining Pt. Estimate',
            fieldName: 'remainingPatientEstimate',
            onRender: (item) => <span>{usdCurrencyFormatter.format(item?.remainingPatientEstimate ?? 0)}</span>,
        },
    ];

    return (
        <Stack style={{ paddingLeft: 10 }}>
            <SortableDetailsList<IMostRecentTreatmentPlanPhaseView>
                selectionMode={SelectionMode.none}
                sortColumns={['phaseName']}
                initialSortDirection={['asc']}
                onRenderRow={(props) => {
                    if (props)
                        return (
                            <div
                                onClick={() => {
                                    onSelectItem(props.item);
                                }}
                            >
                                <DetailsRow
                                    {...props}
                                    styles={{
                                        root: {
                                            backgroundColor: isItemSelected(props.item)
                                                ? palette.neutralLighter
                                                : undefined,
                                        },
                                    }}
                                ></DetailsRow>
                            </div>
                        );
                    return null;
                }}
                compact
                columns={columns}
                items={phases ?? []}
            />
        </Stack>
    );
}
