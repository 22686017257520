import LookupSettingPage from '../Shared/LookupSettingPage';
import DetailsList from './DetailsList';
import EditPanel from './EditPanel';
import { IProcedureDetail } from 'api/models/procedure-detail.model';
import { useState } from 'react';
import SettingsSearchBox from '../components/SettingsSearchBox';

const newItem: IProcedureDetail = {
    id: '',
    code: '',
    displayName: '',
    isDeleted: false,
    effectiveDate: '',
};

const ManageProcedureTemplates = () => {
    const [searchTerm, setSearchTerm] = useState<string>('');

    return (
        <LookupSettingPage<IProcedureDetail>
            lookupType="ProcedureDetails"
            displayName="Template"
            newItem={newItem}
            listComponent={<DetailsList searchTerm={searchTerm} />}
            editComponent={<EditPanel />}
            searchBoxComponent={
                <SettingsSearchBox
                    styles={{ root: { width: 275 } }}
                    searchTerm={searchTerm}
                    placeholder="Find procedures (Code or Description)"
                    onSearchChange={setSearchTerm}
                />
            }
        />
    );
};

export default ManageProcedureTemplates;
