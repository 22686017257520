import LookupBase from './lookup-base.model';

export enum PatientPrescriptionOrderIngMode {
    Prescribe = 'prescribe',
    Administer = 'administer',
    Dispense = 'dispense',
}

export default interface IPatientPrescription extends LookupBase {
    patientId?: string;
    encounterId?: string;
    snomedDiagnosisId?: string;
    snomedDiagnosisCode?: number;
    diagnosisCode?: string;
    ndcId?: string;
    route?: string;
    dosageQuantity?: number;
    dosageQuantityUnit?: string;
    totalQuantity?: number;
    totalQuantityUnit?: string;
    pharmacyId?: string;
    numberOfRefills?: number;
    pharmacyNote?: string;
    providerNote?: string;
    patientNote?: string;
    earliestFillDate?: string;
    prescriberId?: string | null;
    frequency?: string;
    duration?: number;
    unstructuredSig?: string;
    additionalInstructions?: string;
    isDocumentationOnly?: boolean;
    orderingMode?: PatientPrescriptionOrderIngMode;
    orderTypeId?: string; // Kristy will do try parse on back end (original type: number)
    dispenseAsWritten?: boolean;
    references?: Record<string, string | undefined>;
}

export interface IStructuredSignature {
    dosageAction?: string;
    dosageAdditionalInstructions?: string;
    dosageDurationUnit?: string;
    dosageDurationValue?: string;
    dosageFrequencyDescription?: string;
    dosageFrequencyUnit?: string;
    dosageFrequencyValue?: string;
    dosageQuantityUnit?: string;
    dosageQuantityValue?: string;
    dosageRoute?: string;
}
