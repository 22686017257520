import { IServiceUserAccountTenant } from 'api/models/account.model';
import useSelector from 'hooks/useSelector';
import useTenantId from 'hooks/useTenantId';
import { intersection, map, some } from 'lodash';

import { selectAccountData, selectIsAccountGlobalAdmin } from 'state/slices/account.slice';

export enum GlobalPermissions {
    GlobalAdmin = 'global-admin',
    JuniorAdmin = 'junior-admin',
    SeniorAdmin = 'senior-admin',
    JuniorClinical = 'junior-clinical',
    SeniorClinical = 'senior-clinical',
}
export enum DentalPermissions {
    TreatmentPlan = 'TreatmentPlan',
    Odontogram = 'Odontogram',
    Ledger = 'Ledger',
    ClinicalHuddle = 'ClinicalHuddle',
    ePrescribe = 'ePrescribe',
    Perio = 'Perio',
    AdminHuddle = 'AdminHuddle',
    Scheduling = 'Scheduling',
    PracticeSettings = 'PracticeSettings',
    ChartSettings = 'ChartSettings',
    PatientOverview = 'PatientOverview',
    AuditLogging = 'AuditLogging',
    Resident = 'ResidentProvider',
    Reporting = 'Reporting',
    ConfigureImaging = 'ConfigureImaging',
    ReopenBatches = 'ReopenBatches',
    ReadyToRebill = 'ReadyToRebill',
    Coder = 'Coder',
}

export enum MembershipPermissions {
    MembershipSubscription = 'Subscriptions.Membership.Manage',
    DentalSubscription = 'Subscriptions.Dental.Manage',
    LocationsOfCare = 'LocationsOfCare.Manage',
    Payers = 'Payers.Manage',
    EncounterReasons = 'EncounterReasons.Manage',
    Users = 'Users.Manage',
    Programs = 'Programs.Manage',
    ProcedureDetails = 'ProcedureDetails.Manage',
}

export const membershipPermissionsList = map(MembershipPermissions);

/**
 * usePermissions
 *
 * A hook used to check to see if the passed permissions exist for a given user.
 *
 * @param {DentalPermissions[]} permissions Array of permissions to check
 * @param {boolean} [exact] If the list of permissions for a user has to match the permissions arg
 * @return {boolean[]} [requestedPermissions, isGlobalAdmin]
 */
function usePermissions(permissions?: (DentalPermissions | MembershipPermissions)[], exact?: boolean): boolean[] {
    const tenantId = useTenantId();
    const account = useSelector(selectAccountData);
    const currentTenant: IServiceUserAccountTenant | undefined | null =
        account && account.tenants ? account.tenants[tenantId] : null;

    const isGlobalAdmin = useSelector(selectIsAccountGlobalAdmin);
    let hasPermissions = false;

    if (isGlobalAdmin) {
        hasPermissions = true;
    } else {
        if (permissions?.length) {
            if (exact) {
                hasPermissions = intersection(currentTenant?.permissions, permissions).length === permissions.length;
            } else {
                hasPermissions = some(currentTenant?.permissions, (perm) => permissions.find((p) => perm === p) !== undefined);
            }
        } else {
            hasPermissions = false;
        }
    }

    return [hasPermissions, isGlobalAdmin];
}

export default usePermissions;
