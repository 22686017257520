import { createSlice } from '@reduxjs/toolkit';
import workListReducers, { workListExtraReducers } from './worklist.reducers';
import { workListsState } from './worklist.state';
import { encounterWorkListExtraReducers, encounterWorkListsReducers } from './encounter-worklists/encounter-worklists.reducers';
import { appointmentWorkListsExtraReducers } from './appointment-worklists/appointment-worklists.reducers';
import { worklistPredeterminationExtraReducers } from './predeterminations/predeterminations.reducers';

const workListSlice = createSlice({
    name: 'workList',
    initialState: workListsState,
    reducers: { ...workListReducers, ...encounterWorkListsReducers },
    extraReducers: (builder) => {
        workListExtraReducers(builder);
        encounterWorkListExtraReducers(builder);
        appointmentWorkListsExtraReducers(builder);
        worklistPredeterminationExtraReducers(builder)
    },
});

const { actions, reducer } = workListSlice;

export const {
    setSelectedWorkListEditItem,
    setCurrentWorkList,
    setWorkListMessageBar,
    setWorkListReadOnly,
    setWorkListHasSearched,
    setWorkListCurrentPageNumber,
    setWorkListItemsPerPage,
    setWorkListFilter,
    setWorklistFilters,
    setWorkListLayerOpen,
    setWorkListConfirmationModalOpen,
    setBillingProcedureToVoid,
    setWorkListSelectedItems,
    setWorkListRecentItems,
    setWorkListRecentItemLoading,

    removeWorkListDataAtIndex,
    updateWorkListDataAtIndex,
    addWorkListData,

    cleanupWorkListData,
    cleanupWorkListFilters,
    cleanupWorkListPagination,
    cleanupWorkListOpenLayers,
    cleanupWorkListContinuationTokens,
    cleanupWorkListConfirmationModal,
    cleanupWorkListMessageBar,

    toggleWorkListFilterPropItem,

    //encounter worklist specific
    cleanupEncounterWorkListModal,
    cleanupEncounterWorkListMessageBar,
    insertEncounterWorkListCorrespondence,
    setEncounterWorkListModalMessageBar,
    setEncounterWorkListModalNote,
    setEncounterWorkListModalAction,
    setEncounterWorkListModalOpen,
} = actions;

export default reducer;
