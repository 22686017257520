import { Stack } from '@fluentui/react';
import ClaimRejectionsDetailsList from './ClaimRejectionsDetailsList';
import ClaimRejectionsFilters from './ClaimRejectionsFilters';

export default function ClaimRejections() {
    return (
        <Stack tokens={{ childrenGap: 10 }} style={{ padding: 10 }} grow>
            <ClaimRejectionsFilters />
            <ClaimRejectionsDetailsList />
        </Stack>
    );
}
