import { Stack, Text } from '@fluentui/react';

type Props = {
    label: string
    text: string | JSX.Element
}

export default function BannerInfoDetailItem({label, text}: Props) {

    return (
        <Text>
            <Stack>
                <b>{label}</b>
                {text}
            </Stack>
        </Text>
    );
}
