import Tooth from './Tooth';
import ToothOverlay from './ToothOverlay';
import * as PIXI from 'pixi.js-legacy';
import { IChartRenderProcedure } from 'api/models/tooth.model';

export default class RootCanal extends ToothOverlay {
    private graphics: PIXI.Graphics = new PIXI.Graphics();

    constructor(tooth: Tooth, chartProcedure: IChartRenderProcedure) {
        super(tooth);
        this._setColor(chartProcedure);
        this.setScaleToTooth();
        this._createGraphics();
    }

    private _createGraphics() {
        const lineLength = 100;
        const yOffset = 30;
        const yPosition = this.getIsBottomRow
            ? (this.tooth.height + yOffset) / this.scale.y
            : (-this.tooth.height + 90) / this.scale.y;

        const xPosition = this.tooth.getToothWidth / 2 / this.scale.y;
        const toYPosition = this.getIsBottomRow ? -lineLength / this.scale.y : lineLength / this.scale.y;

        this.graphics.position.set(xPosition, yPosition);
        this.graphics.lineStyle(8, this.color).lineTo(0, toYPosition);

        this.addChild(this.graphics);
    }
}
