import { createSelector } from 'reselect';
import { selectWorkListState, selectWorkListData, selectSelectedItemsWorkList } from '../worklist.selectors';
import IWorkListEncounterView from 'api/models/worklist-encounter-view';
import { IPatientEncounter } from 'api/models/encounter.model';

// Selected items for admin encounter view.
export const selectedSelectedEncounterIdsWorkList = createSelector(selectSelectedItemsWorkList, (selectedItems) => {
    return (selectedItems as IWorkListEncounterView[])
        .filter((e) => !!e?.encounter)
        ?.map((e) => (e?.encounter as IPatientEncounter)?.id);
});

//Void Billing Procedures
export const selectEncounterWorkListVoidProcedureConfirmationIsOpen = createSelector(
    selectWorkListState,
    (state) => !!state.billingProcedureToVoid,
);
export const selectEncounterWorkListVoidProcedure = createSelector(selectWorkListState, (state) => state.billingProcedureToVoid);
export const selectEncounterWorkListVoidProcedurePatientId = createSelector(
    selectEncounterWorkListVoidProcedure,
    selectWorkListData,
    (voidProcedure, views) =>
        (views as (IWorkListEncounterView | undefined)[])?.find((view) => view?.encounter?.id === voidProcedure?.encounterId)
            ?.patientId,
);
export const selectEncounterWorkListVoidProcedureSaving = createSelector(
    selectWorkListState,
    (state) => state.voidProcedureSaving,
);

// Note Modal
export const selectEncounterWorkListNoteModalIsOpen = createSelector(
    selectWorkListState,
    (state) => state.encounterWorkListNoteModal.isOpen,
);
export const selectEncounterWorkListNoteModalNote = createSelector(
    selectWorkListState,
    (state) => state.encounterWorkListNoteModal.currentNote,
);
export const selectEncounterWorklistNoteModalSavingIsRCM = createSelector(
    selectWorkListState,
    (state) => state.encounterWorkListNoteModal.savingIsRcm,
);
export const selectEncounterWorklistNoteModalMessageBar = createSelector(selectWorkListState, (state) => ({
    messageBarType: state.encounterWorkListNoteModal.messageBarType,
    messageBarMessage: state.encounterWorkListNoteModal.messageBarMessage,
}));
export const selectEncounterWorkListNoteModalAction = createSelector(
    selectWorkListState,
    (state) => state.encounterWorkListNoteModal.selectedAction,
);
export const selectEncounterWorkListNoteModalLoading = createSelector(
    selectWorkListState,
    (state) => state.encounterWorkListNoteModal.loadingNoteData,
);
export const selectEncounterWorkListNoteModalSaving = createSelector(
    selectWorkListState,
    (state) => state.encounterWorkListNoteModal.savingNoteData,
);
export const selectEncounterWorkListNoteModalType = createSelector(
    selectWorkListState,
    (state) => state.encounterWorkListNoteModal.type,
);

// Note Modal encounter correspondence
export const selectEncounterWorkListNoteModalEncounterCorrespondence = createSelector(selectWorkListState, (state) =>
    [...(state.encounterWorkListNoteModal.correspondence ?? [])].reverse(),
);
export const selectEncounterWorkListNoteModalEncounterCorrespondenceLoading = createSelector(
    selectWorkListState,
    (state) => state.encounterWorkListNoteModal.loadingNoteData,
);
export const selectEncounterWorkListNoteModalEncounterCorrespondenceSaving = createSelector(
    selectWorkListState,
    (state) => state.encounterWorkListNoteModal.savingNoteData,
);
