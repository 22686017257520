import { IComboBoxOption } from '@fluentui/react';
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import LookupBase from 'api/models/lookup-base.model';
import { AxiosError } from 'axios';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { map, orderBy } from 'lodash';
import { RootState } from 'state/store';

export interface IDosageQuantity extends LookupBase {
    isActive: boolean;
    references?: Record<string, string>;
}

type DosageQuantityState = {
    initialLoad: LoadingStatus;
    loading: LoadingStatus;
    data?: Record<string, IDosageQuantity>;
    errors?: string;
};

const initialState: DosageQuantityState = {
    initialLoad: LoadingStatus.Idle,
    loading: LoadingStatus.Idle,
    data: {},
};

export const getDosageQuantity = createAsyncThunk<
    Record<string, IDosageQuantity>,
    void,
    {
        rejectValue: AxiosError;
    }
>('getDosageQuantity', async () => {
    const res = await dentalApi.getDosageQuantity();
    return res.data;
});

const dosageQuantitySlice = createSlice({
    name: 'races',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDosageQuantity.pending, (state) => {
                state.initialLoad = LoadingStatus.Pending;
            })
            .addCase(getDosageQuantity.fulfilled, (state, action) => {
                state.initialLoad = LoadingStatus.Completed;
                state.data = action.payload;
            })
            .addCase(getDosageQuantity.rejected, (state, action) => {
                state.initialLoad = LoadingStatus.Failed;
                state.errors = action.payload?.message;
            });
    },
});

const { reducer } = dosageQuantitySlice;

export const selectDosageQuantityObject = (state: RootState): Record<string, IDosageQuantity> | undefined =>
    state.tenant.dosageQuantity.data;
export const selectDosageQuantityData = createSelector(selectDosageQuantityObject, (data) => (data ? data : {}));

export const selectDosageQuantity = createSelector(selectDosageQuantityData, (lookups) => {
    return lookups;
});

export const dosageQuantityList = createSelector(selectDosageQuantity, (lookups) => {
    if (lookups) {
        return map(lookups).filter((lookup) => !lookup.isDeleted);
    }
    return [];
});

export const dosageQuantityOptionsList = createSelector(
    dosageQuantityList,
    (lookupsList) =>
        orderBy(lookupsList, 'order').map((dx) => ({
            key: dx?.id ?? '',
            text: dx?.displayName ? `${dx.displayName.length > 35 ? `${dx.displayName.slice(0, 35)}...` : dx.displayName}` : '',
            title: `${dx.displayName}`,
        })) as IComboBoxOption[],
);

export default reducer;
