import { Section } from 'components';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cleanupPendingDocumentsAndFiles } from 'state/slices/documents/documents.slice';
import { selectEditPatient } from 'state/slices/edit-patient/edit-patient.selectors';
import Insurance from './components/Insurance';
import PracticesAndSignature from './components/PracticesAndSignature';
import PayersDocuments from './PayersDocuments';
import HoldingStatement from './components/HoldingStatement';
import { getStatementConfigurations, statementConfig } from 'state/slices/tenant/statementConfig.slice';
import { useTenantId } from 'hooks';
import { selectHasStatementConfigurationFeatureFlag } from 'state/slices/tenant/feature-flags.slice';

function Payers(): JSX.Element {
    const dispatch = useDispatch();
    const patient = useSelector(selectEditPatient);
    const tenantId = useTenantId();

    const hasStatementConfigFeatureFlag = useSelector(selectHasStatementConfigurationFeatureFlag);

    const config = useSelector(statementConfig);

    useEffect(() => {
        dispatch(cleanupPendingDocumentsAndFiles());
    }, [dispatch]);

    useEffect(() => {
        if (hasStatementConfigFeatureFlag) dispatch(getStatementConfigurations({ tenantId }));
    }, [hasStatementConfigFeatureFlag, tenantId, dispatch]);

    return (
        <Section>
            {patient && <PracticesAndSignature />}
            {!config?.isDeleted && hasStatementConfigFeatureFlag && <HoldingStatement />}
            <Insurance />
            <PayersDocuments />
        </Section>
    );
}

export default Payers;
