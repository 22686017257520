export default function convertDashedDateString(date?: string): string {
    if (!date) return '';
    const splitDate = date.split('-');
    return `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`;
}

export function convertDateStringToDashed(date?: string): string {
    if (!date) return '';
    const splitDate = date.split('/');
    return `${splitDate[2]}-${splitDate[0]}-${splitDate[1]}`;
}
