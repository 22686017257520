import { Label, Stack, Text } from '@fluentui/react';
import { Section } from 'components';
import { useSelector, useTenantId } from 'hooks';
import { selectOperatoriesAsList } from 'state/slices/lookups/operatories/operatories.selectors';
import { selectSelectedAppointmentData } from 'state/slices/scheduling/scheduling.selectors';
import { selectProceduresData } from 'state/slices/tenant/procedures.slice';
import { selectProvidersData } from 'state/slices/tenant/providers.slice';
import { convertFromMilitaryTime } from 'utils/convertFromMilitaryTime';
import { classicDateOnly } from 'utils/dateOnly';

interface IAppointmentInfoBannerProps {
    showProcedures?: boolean;
    showHygienist?: boolean;
}

export default function AppointmentInfoBanner({ showProcedures, showHygienist }: IAppointmentInfoBannerProps) {
    const tenantId = useTenantId();
    const patientAppointment = useSelector(selectSelectedAppointmentData);
    const _providerData = useSelector(selectProvidersData);
    const _proceduresData = useSelector(selectProceduresData);
    const _operatories = useSelector((state) => selectOperatoriesAsList(state, tenantId));

    const treatingProvider = () => {
        if (patientAppointment?.treatingProviderId) {
            const provider = _providerData[patientAppointment.treatingProviderId];
            return `${provider?.firstName} ${provider?.lastName}`;
        }
        return 'N/A';
    };
    const getHygienistName = () => {
        if (patientAppointment?.hygienistId) {
            const hygienist = _providerData[patientAppointment.hygienistId];
            return `${hygienist?.firstName} ${hygienist?.lastName}`;
        }
        return 'N/A';
    };

    const operatories = () => {
        const operatory = _operatories?.find((res) => res.id === patientAppointment?.operatoryId)?.displayName;
        return operatory ?? 'N/A';
    };

    const patientApptProcedures = patientAppointment?.procedures?.map((p) =>
        p.procedureId ? _proceduresData[p.procedureId]?.code ?? 'Unknown Procedure' : 'Unknown Procedure',
    );

    return (
        <Section>
            <Stack horizontal>
                <Stack grow>
                    <Label>Date</Label>
                    <Text>{patientAppointment?.date ? classicDateOnly(patientAppointment?.date, 'M/d/yyyy') : ''}</Text>
                </Stack>

                <Stack grow>
                    <Label>Start Time</Label>
                    <Text>{convertFromMilitaryTime(patientAppointment?.startTime) ?? 'Not set'}</Text>
                </Stack>

                <Stack grow>
                    <Label>Treating Provider</Label>
                    <Text>{treatingProvider()}</Text>
                </Stack>
                {showHygienist && (
                    <Stack grow>
                        <Label>Hygienist</Label>
                        <Text>{getHygienistName()}</Text>
                    </Stack>
                )}
                <Stack grow>
                    <Label>Operatory</Label>
                    <Text>{operatories()}</Text>
                </Stack>
                {showProcedures && (
                    <Stack grow>
                        <Label>Procedures</Label>
                        <Text>{patientApptProcedures?.length ? patientApptProcedures.join(', ') : 'N/A'}</Text>
                    </Stack>
                )}
            </Stack>
        </Section>
    );
}
