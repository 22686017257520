import { createAsyncThunk, Dispatch } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import { IQuickActions, IQuickAction } from 'api/models/quick-action.model';
import { IQuickActionCategories } from 'api/models/quick-action-category-model';
import { AxiosResponse, AxiosError } from 'axios';
import { AppThunk } from 'state/store';

// Quick Actions ============================
export const getQuickActions = createAsyncThunk<
    AxiosResponse<IQuickActions>,
    {
        tenantId: string;
    },
    {
        dispatch: Dispatch;
        rejectValue: AxiosError;
    }
>('getQuickActionItems', async ({ tenantId }) => {
    const res = await dentalApi.getQuickActions(tenantId);
    return res;
});

export const createQuickAction = createAsyncThunk<
    AxiosResponse<IQuickActions>,
    {
        tenantId: string;
        entity: IQuickActions;
    },
    {
        dispatch: Dispatch;
        rejectValue: AxiosError;
    }
>('createQuickAction', async ({ tenantId, entity }) => {
    const res = await dentalApi.createQuickAction(tenantId, entity);
    return res;
});

export const updateQuickAction = createAsyncThunk<
    AxiosResponse<IQuickActions>,
    {
        tenantId: string;
        entity: IQuickActions;
    },
    {
        dispatch: Dispatch;
        rejectValue: AxiosError;
    }
>('updateQuickAction', async ({ tenantId, entity }) => {
    const res = await dentalApi.updateQuickAction(tenantId, entity);
    return res;
});

// Quick Action Categories ============================
export const getQuickActionCategories = createAsyncThunk<
    IQuickActionCategories,
    {
        tenantId: string;
    }
>('getQuickActionCategories', async ({ tenantId }) => {
    const res = await dentalApi.getQuickActionCategories(tenantId);
    return res.data;
});

export const updateQuickActionCategories = createAsyncThunk<
    IQuickActionCategories,
    { tenantId: string; entity: IQuickActionCategories }
>('updateQuickActionCategory', async ({ tenantId, entity }) => {
    const res = await dentalApi.updateQuickActionCategory(tenantId, entity);
    return res.data;
});

export const deleteQuickActions =
    (tenantId: string, actions: IQuickAction | IQuickAction[]): AppThunk<void> =>
    async (dispatch, getState): Promise<void> => {
        const quickActions = getState().tenant.quickActions.items.data;
        if (!quickActions) return;

        const allActions = getState().tenant.quickActions.items.data?.actions;

        const newAction = [...(allActions ?? [])];

        const actionsArray = Array.isArray(actions) ? actions : [actions];
        actionsArray.forEach((action) => deleteQuickAction(action));

        function deleteQuickAction(action: IQuickAction) {
            const index = newAction.findIndex((a) => action.id === a.id);
            if (index !== -1) {
                newAction[index] = { ...action, isDeleted: true };
            }
        }

        const newDocument: IQuickActions = { ...quickActions, actions: newAction };
        dispatch(updateQuickAction({ tenantId, entity: newDocument }));
    };
export const moveQuickActionsToAnotherCategory =
    (tenantId: string, actions: IQuickAction | IQuickAction[], categoryId: string): AppThunk<void> =>
    async (dispatch, getState): Promise<void> => {
        const quickActions = getState().tenant.quickActions.items.data;
        if (!quickActions) return;

        const allActions = getState().tenant.quickActions.items.data?.actions;

        const newAction = [...(allActions ?? [])];

        const actionsArray = Array.isArray(actions) ? actions : [actions];
        actionsArray.forEach((action) => updateQuickActionCategoryById(action));

        function updateQuickActionCategoryById(action: IQuickAction) {
            const index = newAction.findIndex((a) => action.id === a.id);
            if (index !== -1) {
                newAction[index] = { ...action, categoryId };
            }
        }

        const newDocument: IQuickActions = { ...quickActions, actions: newAction };
        dispatch(updateQuickAction({ tenantId, entity: newDocument }));
    };

export const updateQuickActionsDocument =
    (tenantId: string, updatedActions: IQuickAction[]): AppThunk<void> =>
    async (dispatch, getState): Promise<void> => {
        const action = getState().tenant.quickActions.items.data;
        const newAction = updatedActions ? [...updatedActions] : [];
        if (action) {
            const newDocument: IQuickActions = { ...action, actions: newAction };
            dispatch(updateQuickAction({ tenantId, entity: newDocument }));
        }
    };
