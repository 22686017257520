import { Label, Stack, Text, useTheme } from '@fluentui/react';
import { Badge } from 'components';
import { BadgeSize } from 'components/Badge';
import { useSelector } from 'hooks';
import { selectTracker } from 'state/slices/scheduling/scheduling.selectors';
import { getTrackerStatusText, TrackerStatus } from './TrackerStatusDropdown';

function Tracker(): JSX.Element {
    const tracker = useSelector(selectTracker);
    const _theme = useTheme();

    return (
        <Stack styles={{ root: { minWidth: '220px', maxHeight: 290, backgroundColor: _theme.palette.neutralLighterAlt } }} grow>
            <Stack.Item>
                <Stack>
                    <Stack.Item>
                        <Stack
                            styles={{
                                root: { paddingLeft: 10, backgroundColor: _theme.palette.white },
                            }}
                        >
                            <Label>Patient Tracker</Label>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack tokens={{ childrenGap: 5 }} styles={{ root: { padding: 10 } }}>
                            <TrackerItem
                                label={getTrackerStatusText(TrackerStatus.Arrived)}
                                value={tracker && tracker.arrived ? tracker.arrived.toString() : '0'}
                            />
                            <TrackerItem
                                label={getTrackerStatusText(TrackerStatus.CheckIn)}
                                value={tracker && tracker.checkIn ? tracker.checkIn.toString() : '0'}
                            />
                            <TrackerItem
                                label={getTrackerStatusText(TrackerStatus.Ready)}
                                value={tracker && tracker.ready ? tracker.ready.toString() : '0'}
                            />
                            <TrackerItem
                                label={getTrackerStatusText(TrackerStatus.Intake)}
                                value={tracker && tracker.intake ? tracker.intake.toString() : '0'}
                            />
                            <TrackerItem
                                label={getTrackerStatusText(TrackerStatus.Exam)}
                                value={tracker && tracker.exam ? tracker.exam.toString() : '0'}
                            />
                            <TrackerItem
                                label={getTrackerStatusText(TrackerStatus.Prophy)}
                                value={tracker && tracker.Prophy ? tracker.Prophy.toString() : '0'}
                            />
                            <TrackerItem label={'X-Rays'} value={tracker && tracker.xray ? tracker.xray.toString() : '0'} />
                            <TrackerItem
                                label={getTrackerStatusText(TrackerStatus.Treatment)}
                                value={tracker && tracker.treatment ? tracker.treatment.toString() : '0'}
                            />
                            <TrackerItem
                                label={getTrackerStatusText(TrackerStatus.ReadyForCheckout)}
                                value={tracker && tracker.readyForCheckout ? tracker.readyForCheckout.toString() : '0'}
                            />
                            <TrackerItem
                                label={getTrackerStatusText(TrackerStatus.Dismissed)}
                                value={tracker && tracker.dismissed ? tracker.dismissed.toString() : '0'}
                            />
                            <TrackerItem
                                label={getTrackerStatusText(TrackerStatus.Completed)}
                                value={tracker && tracker.completed ? tracker.completed.toString() : '0'}
                            />
                        </Stack>
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        </Stack>
    );
}

function TrackerItem(props: { label: string; value: string }) {
    return (
        <Stack.Item>
            <Stack horizontal verticalAlign="center" horizontalAlign="space-between">
                <Text variant="small">{props.label}</Text>
                <Badge size={BadgeSize.size16} value={props.value} />
            </Stack>
        </Stack.Item>
    );
}

export default Tracker;
