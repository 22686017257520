import { Dictionary, PayloadAction } from '@reduxjs/toolkit';
import { ChartProcedureDiagnosis, ChartProcedurePreAuth, ChartProcedureStatus } from 'api/models/chart.model';
import { IProcedure } from 'api/models/procedure.model';
import { ToothArea } from 'api/models/tooth-area';
import { isEmpty, isEqual } from 'lodash';
import { ChartingState, ProcedureActionType } from '../chart/chart.slice';
import {
    createsManyProcedures,
    createsManyProceduresPerArea,
    createsProceduresWithCombinedToothIds,
    doesNotHaveApplicableArea,
    hasGeneralApplicableArea,
} from '../chartingProcedures.pipeline';
import ProcedureDiagnosisPipeline from '../procedureDiagnosisPipeline/procedureDiagnosisPipeline.pipeline';
import { EditProcedurePanelPayload, SetProceduresPanelPayload, getAreaTeethDataProp } from './procedure-panel.actions';

import { IPanelProcedure } from './procedure-panel.state';

export const procedurePanelReducers = {
    setProcedurePanelEditItem: (state: ChartingState, action: PayloadAction<EditProcedurePanelPayload>): void => {
        const {
            procedure,
            chartProcedure,
            provider,
            actionType,
            date,
            areas,
            selectedStatus,
            notes,
            diagnosisCodes,
            selectedPreAuth,
            selectedAuthorization,
        } = action.payload;

        const selectedTeeth = chartProcedure?.toothIds?.length ? chartProcedure.toothIds : [];
        const selectedAreas = chartProcedure?.areas ?? [];
        const dataProp = getAreaTeethDataProp(chartProcedure);

        state.procedurePanel.procedures = [procedure] || [];
        state.procedurePanel.selectedChartProcedure = chartProcedure;
        state.procedurePanel.isOpen = true;
        state.procedurePanel.isEditing = true;
        state.procedurePanel.selectedTeeth = selectedTeeth;
        if (createsManyProceduresPerArea(procedure)) state.procedurePanel.selectedAreas = selectedAreas;
        state.procedurePanel.providerId = provider;
        state.procedurePanel.selectedActionType = actionType;
        state.procedurePanel.date = date;
        state.procedurePanel.selectedStatus = selectedStatus;
        state.procedurePanel.selectedPreAuth = selectedPreAuth;
        state.procedurePanel.notes = notes;
        state.procedurePanel.selectedAuthorization = selectedAuthorization;
        state.procedurePanel.openProcedureSections = [procedure.id];

        state.procedurePanel.panelTeethData[procedure.id] = {
            data: {},
            generalDiagnosisCodes: [],
        };

        const _createsProceduresWithCombinedToothIds = createsProceduresWithCombinedToothIds(procedure);
        if (hasGeneralApplicableArea(procedure) || doesNotHaveApplicableArea(procedure)) {
            if (diagnosisCodes)
                (state.procedurePanel.panelTeethData[procedure.id] as IPanelProcedure).generalDiagnosisCodes = diagnosisCodes;
        } else if (
            createsManyProcedures(procedure) ||
            _createsProceduresWithCombinedToothIds ||
            createsManyProceduresPerArea(procedure)
        ) {
            if (dataProp)
                state.procedurePanel.panelTeethData[procedure.id] = {
                    ...(state.procedurePanel.panelTeethData[procedure.id] as IPanelProcedure),
                    data: {
                        ...(state.procedurePanel.panelTeethData[procedure.id] as IPanelProcedure).data,
                        [dataProp]: {
                            surfaces: areas,
                            diagnosisCodes,
                            groupedTeeth: _createsProceduresWithCombinedToothIds ? selectedTeeth : undefined,
                        },
                    },
                };
        }
    },
    setProcedurePanelItems: (state: ChartingState, action: SetProceduresPanelPayload): void => {
        const {
            procedures,
            conditions,
            selectedActionType,
            providerId,
            selectedPreAuth,
            proceduresData,
            encounterId,
            diagnosisData,
            panelTeethData,
        } = action.payload;
        const selectedTeeth = state.ui.selectedTeeth;
        state.procedurePanel.conditions = conditions || [];
        state.procedurePanel.procedures = procedures || [];
        state.procedurePanel.isOpen = true;
        state.procedurePanel.selectedActionType = selectedActionType;
        state.procedurePanel.providerId = providerId;
        state.procedurePanel.selectedTeeth = selectedTeeth;
        state.procedurePanel.selectedPreAuth = selectedPreAuth;
        state.procedurePanel.openProcedureSections = procedures?.map((p) => p.id) ?? [];

        if (selectedActionType === ProcedureActionType.Treatment || selectedActionType === ProcedureActionType.Referred) {
            state.procedurePanel.selectedStatus = ChartProcedureStatus.Pending;
        }

        if (selectedActionType === ProcedureActionType.Existing || selectedActionType === ProcedureActionType.ExistingOther) {
            state.procedurePanel.selectedPreAuth = undefined;
            state.procedurePanel.selectedStatus = undefined;
        }

        const filteredChartProcedures = state.procedures.data.filter((p) => !p.isDeleted);

        const proceduresWithDiagnoses = new ProcedureDiagnosisPipeline({
            newProcedures: procedures || [],
            chartProceduresList: filteredChartProcedures,
            proceduresData,
            encounterId,
            diagnosisData,
        }).getItems;

        if (panelTeethData && !isEmpty(panelTeethData)) {
            state.procedurePanel.panelTeethData = panelTeethData;
        } else {
            proceduresWithDiagnoses.forEach((procedure) => {
                selectedTeeth.forEach((tooth) => {
                    const diagnosisCodes: ChartProcedureDiagnosis[] = procedure.diagnosisItems.map((d) => ({
                        id: d.id,
                        description: d.description,
                        displayName: d.code,
                    }));
                    if (state.procedurePanel.panelTeethData[procedure.id])
                        (state.procedurePanel.panelTeethData[procedure.id] as IPanelProcedure).data[tooth] = {
                            surfaces: [],
                            diagnosisCodes,
                            procedure,
                        };
                });
            });
        }
    },
    //toogle proc section action here
    toggleOpenProcedureSection: (state: ChartingState, action: PayloadAction<string>): void => {
        if (state.procedurePanel.openProcedureSections.includes(action.payload)) {
            state.procedurePanel.openProcedureSections = state.procedurePanel.openProcedureSections.filter(
                (proc) => proc !== action.payload,
            );
        } else {
            state.procedurePanel.openProcedureSections = [...state.procedurePanel.openProcedureSections, action.payload];
        }
    },
    setOpenProcedureSections: (state: ChartingState, action: PayloadAction<string[]>): void => {
        state.procedurePanel.openProcedureSections = action.payload;
    },
    toggleExpandAllProcedureSections: (state: ChartingState): void => {
        const procedures = state.procedurePanel.procedures.map((p) => p.id);
        if (isEqual(state.procedurePanel.openProcedureSections, procedures)) {
            state.procedurePanel.openProcedureSections = [];
        } else {
            state.procedurePanel.openProcedureSections = procedures;
        }
    },
    setProcedurePanelOpen: (state: ChartingState, action: PayloadAction<boolean>): void => {
        const isOpen = action.payload;
        state.procedurePanel.isOpen = isOpen;
        if (!isOpen) {
            state.procedurePanel.selectedChartProcedure = undefined;
            state.procedurePanel.panelTeethData = {};
        }
    },
    cleanupProcedurePanelState: (state: ChartingState): void => {
        state.procedurePanel.isEditing = false;
        state.procedurePanel.selectedTeeth = [];
        state.procedurePanel.selectedAreas = [];
        state.procedurePanel.selectedProceduresTeeth = {};
        state.procedurePanel.panelTeethData = {};
        state.procedurePanel.selectedPreAuth = undefined;
        state.procedurePanel.notes = undefined;
        state.procedurePanel.providerId = undefined;
        state.procedurePanel.openProcedureSections = [];
        state.procedurePanel.selectedStatus = undefined;
    },
    setProcedurePanelProcedures: (state: ChartingState, action: PayloadAction<IProcedure[]>): void => {
        state.procedurePanel.procedures = action.payload;
    },
    setProcedurePanelCategories: (state: ChartingState, action: PayloadAction<string[]>): void => {
        const categories = action.payload;
        state.procedurePanel.selectedCategories = categories;
    },
    setProcedureTeethData: (state: ChartingState, action: PayloadAction<Dictionary<IPanelProcedure>>): void => {
        state.procedurePanel.panelTeethData = action.payload;
    },
    setProcPanelSurfaces(
        state: ChartingState,
        action: PayloadAction<{ surfaces: (keyof typeof ToothArea)[]; procedureId: string; toothOrArea: number | string }>,
    ): void {
        const surfaces = action.payload.surfaces;
        const { toothOrArea, procedureId } = action.payload;
        if (state.procedurePanel.panelTeethData[procedureId]) {
            (state.procedurePanel.panelTeethData[procedureId] as IPanelProcedure).data[toothOrArea].surfaces = surfaces;
        }
    },
    updateProcedurePanelProcedure(
        state: ChartingState,
        action: PayloadAction<{ procedureId?: string; toothOrArea: number | string; procedure: IProcedure }>,
    ): void {
        const { procedureId, procedure, toothOrArea } = action.payload;
        if (procedureId) {
            (state.procedurePanel.panelTeethData[procedureId] as IPanelProcedure).data[toothOrArea].procedure = procedure;
        }
    },
    setProcPanelSelectedTeeth(state: ChartingState, action: PayloadAction<number[]>): void {
        const selectedTeeth = action.payload;
        state.procedurePanel.selectedTeeth = selectedTeeth;
    },
    setProcPanelSelectedAreas(state: ChartingState, action: PayloadAction<(keyof typeof ToothArea)[]>): void {
        const selectedAreas = action.payload;
        state.procedurePanel.selectedAreas = selectedAreas;
    },
    setProcPanelProcedureSelectedTeeth(
        state: ChartingState,
        action: PayloadAction<{ selectedTeeth: number[]; procedureId: string }>,
    ): void {
        const { selectedTeeth, procedureId } = action.payload;
        state.procedurePanel.selectedProceduresTeeth[procedureId] = selectedTeeth;
    },
    setProcPanelProviderId(state: ChartingState, action: PayloadAction<string>): void {
        const providerId = action.payload;
        state.procedurePanel.providerId = providerId;
    },
    setProcPanelActionType(state: ChartingState, action: PayloadAction<ProcedureActionType>): void {
        const actionType = action.payload;
        state.procedurePanel.date = undefined;
        state.procedurePanel.selectedActionType = actionType;

        //Existing procedures will not have preauth/status.
        if (actionType === ProcedureActionType.Existing || actionType === ProcedureActionType.ExistingOther) {
            state.procedurePanel.selectedPreAuth = undefined;
            state.procedurePanel.selectedStatus = undefined;

            //Auto populate status to pending for treatment/referred.
        } else if (actionType === ProcedureActionType.Referred || actionType === ProcedureActionType.Treatment) {
            state.procedurePanel.selectedStatus = ChartProcedureStatus.Pending;
        }
    },
    setProcPanelDiagnosisCodes(
        state: ChartingState,
        action: PayloadAction<{ dx: ChartProcedureDiagnosis[]; procedureId: string; toothOrArea?: number | string }>,
    ): void {
        const { dx, procedureId, toothOrArea: toothOrArea } = action.payload;
        if (state.procedurePanel.panelTeethData[procedureId])
            if (toothOrArea) {
                (state.procedurePanel.panelTeethData[procedureId] as IPanelProcedure).data[toothOrArea].diagnosisCodes = dx;
            } else {
                (state.procedurePanel.panelTeethData[procedureId] as IPanelProcedure).generalDiagnosisCodes = dx;
            }
    },
    setProcPanelStatus(state: ChartingState, action: PayloadAction<ChartProcedureStatus>): void {
        const status = action.payload;
        state.procedurePanel.selectedStatus = status;
    },
    setProcPanelPreAuth(state: ChartingState, action: PayloadAction<ChartProcedurePreAuth>): void {
        const preAuthStatus = action.payload;
        state.procedurePanel.selectedPreAuth = preAuthStatus;
    },
    setProcPanelPreAuthCode(state: ChartingState, action: PayloadAction<string | undefined>): void {
        state.procedurePanel.selectedAuthorization = action.payload ?? '';
    },
    setProcPanelOnSetDate(state: ChartingState, action: PayloadAction<string | undefined>): void {
        const date = action.payload;
        state.procedurePanel.date = date;
    },
    setProcPanelNotes(state: ChartingState, action: PayloadAction<string | undefined>): void {
        const notes = action.payload;
        state.procedurePanel.notes = notes;
    },
};
