import { createAsyncThunk } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import { IClinicalAlert, IClinicalAlerts, ClinicalAlertTypeLookup } from 'api/models/clinical-alert.model';
import { AxiosResponse } from 'axios';
import { AppThunk, RootState } from 'state/store';
import { selectClinicalAlertsExistByPatientAndType, selectPatientClinicalAlertLookupIsLoading } from './clinical-alerts.selectors';
import { upsertPatientClinicalAlert } from './clinical-alerts.slice';
import { v4 as uuid } from 'uuid';

export const getAlertTypes = createAsyncThunk<AxiosResponse<ClinicalAlertTypeLookup>>(
    'getAlertTypes',
    async (_, { rejectWithValue }) => {
        try {
            const res = await dentalApi.getAlertTypes();
            return res;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const getNonExistingClinicalAlertsByPatientAndType =
    ({ patientId, tenantId }: { tenantId: string; patientId: string }): AppThunk<void> =>
    (dispatch, getState) => {
        const clinicalAlertsExist = selectClinicalAlertsExistByPatientAndType(getState(), { patientId });
        const isLoadingAlertsForPatient = selectPatientClinicalAlertLookupIsLoading(getState(), patientId)
        if (!clinicalAlertsExist && !isLoadingAlertsForPatient) {
            dispatch(getPatientsClinicalAlertsLookup({ tenantId, patientId }));
        }
    };

export const getPatientClinicalAlerts = createAsyncThunk<
    IClinicalAlerts,
    {
        tenantId: string;
        patientId: string;
    },
    { rejectValue: unknown; state: RootState }
>('getPatientClinicalAlerts', async ({ tenantId, patientId }, { rejectWithValue, getState }) => {
    try {
        const { data } = await dentalApi.getPatientClinicalAlerts(tenantId, patientId);
        return data;
    } catch (e) {
        return rejectWithValue(e);
    }
});

export const getPatientsClinicalAlertsLookup = createAsyncThunk<
    IClinicalAlerts,
    {
        tenantId: string;
        patientId: string;
    },
    { rejectValue: unknown; state: RootState }
>('getPatientsClinicalAlertsLookup', async ({ tenantId, patientId }, { rejectWithValue }) => {
    try {
        const { data } = await dentalApi.getPatientClinicalAlerts(tenantId, patientId);
        return data;
    } catch (e) {
        return rejectWithValue(e);
    }
});

export const addUpdateClinicalAlert = createAsyncThunk<
    IClinicalAlert,
    {
        tenantId: string;
        clinicalAlert: IClinicalAlert;
    },
    { rejectValue: unknown }
>('updateClinicalAlert', async ({ tenantId, clinicalAlert }, { rejectWithValue }) => {
    try {
        const clinicalAlertPayload = clinicalAlert.id ? clinicalAlert : { ...clinicalAlert, id: uuid() };
        const { data } = await dentalApi.addUpdateClinicalAlert(tenantId, clinicalAlertPayload);
        return data;
    } catch (e) {
        return rejectWithValue(e);
    }
});

export const upsertPatientClinicalAlertAction =
    (alert: IClinicalAlert): AppThunk<void> =>
    (dispatch, getState) => {
        const currentPatientId = getState().patient.selectedPatient?.id;
        dispatch(upsertPatientClinicalAlert({ alert, currentPatientId }));
    };
