import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import { IBillingProcedure } from 'api/models/billing-procedure.model';
import {
    ICommonTransaction,
    IEncounterLedgerTransaction,
    ILedgerTotalsView,
    ILedgerTransactionSummaryView,
    TransactionSource,
    TransactionType,
} from 'api/models/encounter-ledger.model';
import { AppThunk, RootState } from 'state/store';
import {
    cleanupCurrentTransactionsAndSource,
    setCurrentBillingProcedures,
    setCurrentPaymentSource,
    setCurrentPaymentTransactions,
    setPostPaymentModalContext,
    setPostPaymentModalOpen,
    updateCurrentPaymentSourceProps,
    updateTransactionAmount,
    updateTransactionsBatchIdAndDateOfEntry,
} from './ledger.slice';
import { v4 as uuid } from 'uuid';
import { IPaymentSource } from 'api/models/payment-source.model';
import { find, flatten, forEach, some, uniq, uniqBy } from 'lodash';
import axios from 'axios';
import { getCheckoutBillingProcedures } from '../scheduling/scheduling.slice';
import { ITransaction } from 'api/models/patient-ledger.model';
import { selectCurrentTransactions } from './ledger.selectors';
import { ChartProcedureStatus } from 'api/models/chart.model';
import { PostPaymentModalContext } from './ledger.state';
import { selectSignalRIsConnected } from '../signalr.slice';
import ILedgerCalculationUpdate from 'api/models/ledger-calculation-update.model';
import {
    getPatientMostRecentTreatmentPlanCreditView,
    getPatientTreatmentPlanCreditUnappliedPayments,
} from './treatment-plan-credit-and-uac/treatment-plan-credit-and-uac.actions';
import { IBatch } from 'api/models/batch.model';

// Main patient ledger table (list of encounter ledger totals)
export const getLedgerTotalsView = createAsyncThunk<
    ILedgerTotalsView,
    {
        tenantId: string;
        patientId: string;
    },
    { state: RootState }
>('getLedgerTotalsView', async ({ tenantId, patientId }, { signal, rejectWithValue }) => {
    try {
        const source = axios.CancelToken.source();
        signal.addEventListener('abort', () => {
            source.cancel();
        });

        const { data } = await dentalApi.getLedgerTotalsView(tenantId, patientId, source.token);

        return data;
    } catch (e) {
        rejectWithValue(e);
    }
});

// Sub-encounter procedure summaries
export const getEncounterLedgerSummary = createAsyncThunk<
    ILedgerTransactionSummaryView,
    {
        tenantId: string;
        encounterId: string;
    }
>('getEncounterLedgerSummary', async ({ tenantId, encounterId }, { signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
        source.cancel();
    });
    const { data } = await dentalApi.getEncounterLedgerTransactionSummary(tenantId, encounterId, source.token);
    return data;
});

export const getPaymentSource = createAsyncThunk<IPaymentSource, { tenantId: string; id: string }>(
    'getPaymentSource',
    async ({ tenantId, id }) => {
        const { data } = await dentalApi.getPaymentSourceById(tenantId, id);
        return data;
    },
);

export const createPaymentSource = createAsyncThunk<IPaymentSource, { tenantId: string; model: IPaymentSource }>(
    'createPaymentSource',
    async ({ tenantId, model }) => {
        const { data } = await dentalApi.createPaymentSource(tenantId, model);
        return data;
    },
);

export const updatePaymentSource = createAsyncThunk<IPaymentSource, { tenantId: string; model: IPaymentSource }>(
    'updatePaymentSource',
    async ({ tenantId, model }) => {
        const { data } = await dentalApi.updatePaymentSource(tenantId, model);
        return data;
    },
);

export const onSelectedBatchChanged =
    (selectedBatch: IBatch | undefined): AppThunk<void> =>
        (dispatch) => {
            if (selectedBatch) {
                dispatch(updateCurrentPaymentSourceProps({ path: 'dateOfEntry', value: selectedBatch.dateOfEntry }));
                dispatch(updateTransactionsBatchIdAndDateOfEntry(selectedBatch));
            }
        };

export const initializePaymentModal =
    (
        billingProcedures: IBillingProcedure[],
        batchId: string,
        patientId: string,
        context: PostPaymentModalContext,
    ): AppThunk<void> =>
        (dispatch, getState) => {
            const selectedBatch = getState().tenant.batches.selectedBatch;
            const dateOfEntry = selectedBatch?.dateOfEntry ?? '';

            const paymentSource: IPaymentSource = {
                amount: 0,
                id: uuid(),
                dateOfEntry,
                isDeleted: false,
                paymentDate: dateOfEntry,
            };

            const transactions: ICommonTransaction[] = billingProcedures.map((procedure) => ({
                amount: 0,
                batchId,
                chartProcedureId: procedure.id,
                // Only use encounterId on Completed procedures, the rest should be undefined and sent to patientLedger
                encounterId: procedure.status === ChartProcedureStatus.Completed ? procedure.encounterId : undefined,
                patientId,
                procedureCode: procedure.procedureCode,
                treatmentPlanId: procedure.treatmentPlanId,
                treatmentPlanPhaseId: procedure.phaseId,
                dateOfEntry,
                id: uuid(),
                paymentSourceId: paymentSource.id,
                source: TransactionSource.Patient,
                type: TransactionType.Payment,
                treatingProviderId: procedure.treatingProviderId,
            }));

            dispatch(setPostPaymentModalContext(context));
            dispatch(setCurrentBillingProcedures(billingProcedures));
            dispatch(setCurrentPaymentTransactions(transactions));
            dispatch(setCurrentPaymentSource(paymentSource));
            dispatch(setPostPaymentModalOpen(true));
        };

//Absolutely flawless, unable to improve :)
export const savePaymentAndTransactions = createAsyncThunk<
    void,
    { tenantId: string; patientId: string },
    { state: RootState; rejectValue: string }
>('savePaymentAndTransactions', async ({ tenantId, patientId }, { getState, dispatch, rejectWithValue }) => {
    const { currentTransactions, currentPaymentSource, procedureSummaries } = getState().ledger;
    const { data: appointment } = getState().scheduling.selectedAppointment;
    try {
        if (currentPaymentSource && currentTransactions.length) {
            await dentalApi.createPaymentSource(tenantId, currentPaymentSource);
            await dentalApi.createLedgerTransaction(
                tenantId,
                currentTransactions
                    .map((transaction) => ({ ...transaction, amount: -transaction.amount, createdOn: new Date().toISOString() })) // Map negative amount when saving a patient payment
                    .filter((transaction) => transaction.amount !== 0),
            );

            const isSignalRConnected = selectSignalRIsConnected(getState());
            if (!isSignalRConnected)
                if (appointment?.id) {
                    // Refetch Checkout billing procedures
                    await dispatch(getCheckoutBillingProcedures({ patientId, tenantId, appointmentId: appointment.id }));
                } else {
                    // Refetch patient ledger totals
                    dispatch(getLedgerTotalsView({ tenantId, patientId }));

                    // Refetch the active procedureSummaries
                    if (Object.keys(procedureSummaries).length) {
                        forEach(procedureSummaries, (procSummary, encounterId) =>
                            dispatch(getEncounterLedgerSummary({ tenantId, encounterId })),
                        );
                    }
                }
            dispatch(cleanupCurrentTransactionsAndSource());
        }
    } catch (e) {
        return rejectWithValue(e as string);
    }
});

export const saveAdjustmentTransactions = createAsyncThunk<
    void,
    { tenantId: string; patientId: string },
    { state: RootState; rejectValue: string }
>('saveAdjustmentTransactions', async ({ tenantId, patientId }, { getState, dispatch, rejectWithValue }) => {
    const { currentTransactions } = getState().ledger;
    try {
        if (currentTransactions.length) {
            const newTransactions = currentTransactions
                .map((transaction) => ({ ...transaction, amount: -transaction.amount, createdOn: new Date().toISOString() })) // Map negative amount when saving a patient payment
                .filter((transaction) => transaction.amount !== 0);

            await dentalApi.createLedgerTransaction(tenantId, newTransactions);
        }
        const isSignalRConnected = selectSignalRIsConnected(getState());
        // Refetch patient ledger totals
        if (!isSignalRConnected) {
            dispatch(getLedgerTotalsView({ tenantId, patientId }));
        }

        dispatch(cleanupCurrentTransactionsAndSource());
    } catch (e) {
        return rejectWithValue(e as string);
    }
});

function mapToCommonTransaction(transactions: ITransaction[] | IEncounterLedgerTransaction[]): ICommonTransaction[] {
    const isTransaction = (t: ITransaction | IEncounterLedgerTransaction): t is ITransaction => 'chartProcedureId' in t;
    return transactions.map((transaction) => {
        if (isTransaction(transaction)) {
            return {
                id: transaction.id,
                batchId: transaction.batchId,
                chartProcedureId: transaction.chartProcedureId,
                dateOfEntry: transaction.dateOfEntry,
                amount: transaction.amount,
                procedureCode: transaction.procedureCode,
                type: transaction.type,
                source: transaction.source,
                paymentSourceId: transaction.paymentSourceId,
                treatmentPlanId: transaction.treatmentPlanId,
                treatmentPlanPhaseId: transaction.treatmentPlanPhaseId,
            } as ICommonTransaction;
        } else {
            return {
                id: transaction.id,
                batchId: transaction.batchId,
                amount: transaction.totalFee,
                chartProcedureId: transaction.billingEncounterProcedureId,
                dateOfEntry: transaction.dateOfEntry,
                encounterId: transaction.encounterId,
                patientId: transaction.patientId,
                source: transaction.source,
                type: transaction.type,
                paymentSourceId: transaction.paymentSourceId,
            } as ICommonTransaction;
        }
    });
}

/**
 * Fetch all EncounterLedgerTransactions associated through encounterId
 * Fetch all payments associated to fetched EncounterLedgerTransactions
 * Group PaymentSources with associated Transactions
 *
 *  PS = PaymentSource | T = Transaction
 *  PS1
 *  - T1
 *  - T2
 *
 *  PS2
 *  - T2
 *
 *  PS3
 *  - T3
 */
type TotalModalPayload = { commonTransactions: ICommonTransaction[]; paymentSources: IPaymentSource[] };
export const initializeTotalsModal = createAsyncThunk<
    TotalModalPayload,
    { billingProcedures: IBillingProcedure[]; tenantId: string; patientId: string },
    { state: RootState; rejectValue: string }
>('initializeTotalsModal', async ({ tenantId, billingProcedures, patientId }, { dispatch, rejectWithValue }) => {
    const filterPatientPayment = ({ type, source }: ITransaction | IEncounterLedgerTransaction) =>
        type === TransactionType.Payment && source === TransactionSource.Patient;

    try {
        const isPatientLedger = some(billingProcedures, (proc) => proc.status !== ChartProcedureStatus.Completed);
        const isEncounterLedger = some(billingProcedures, (proc) => proc.encounterId);

        const commonTransactions: ICommonTransaction[] = [];
        const paymentSources: IPaymentSource[] = [];

        // If any billing procedure DOES NOT have encounterId fetch patient ledger
        if (isPatientLedger) {
            const { data: patientLedger } = await dentalApi.getPatientLedger(tenantId, patientId);
            const patientLedgerTransactionPayments = patientLedger.transactions?.filter(filterPatientPayment);
            const patientLedgerTransactions = mapToCommonTransaction(patientLedgerTransactionPayments ?? []);
            if (patientLedgerTransactions.length) {
                commonTransactions.push(...patientLedgerTransactions);
            }
        }

        // Find all billing procedures with encounterIds and fetch encounterLedger transactions
        if (isEncounterLedger) {
            const encounterIds = billingProcedures
                .filter((bp) => bp.status === ChartProcedureStatus.Completed)
                .map((proc) => proc.encounterId)
                .filter((id) => id !== undefined) as string[];
            const uniqueEncounterIds = uniq(encounterIds);
            const encounterLedgerRequests = uniqueEncounterIds.map((encounterId) =>
                dentalApi.getEncounterLedger(tenantId, patientId, encounterId),
            );
            if (encounterLedgerRequests.length) {
                const encounterLedgerResponses = await axios.all(encounterLedgerRequests);
                const encounterLedgerTransactions = flatten(
                    encounterLedgerResponses.map(({ data }) => {
                        const encounterLedgerPatientPayments = data?.transactions
                            ? data.transactions.filter(filterPatientPayment)
                            : [];
                        return mapToCommonTransaction(encounterLedgerPatientPayments);
                    }),
                );
                if (encounterLedgerTransactions.length) {
                    commonTransactions.push(...encounterLedgerTransactions);
                }
            }
        }

        // Fetch ALL transaction's associated PaymentSource(s)
        if (commonTransactions.length) {
            const paymentSourceRequests = uniqBy(commonTransactions, 'paymentSourceId')
                .filter((transaction) => !!transaction.paymentSourceId)
                .map(({ paymentSourceId }) => dentalApi.getPaymentSourceById(tenantId, paymentSourceId as string));
            const paymentSourceResults = await axios.all(paymentSourceRequests);
            if (paymentSourceResults.length) {
                paymentSourceResults.forEach(({ data: paymentSource }) => {
                    paymentSources.push(paymentSource);
                });
                dispatch(setCurrentBillingProcedures(billingProcedures));
            }
        }

        return {
            commonTransactions,
            paymentSources,
        };
    } catch (e) {
        rejectWithValue(e as string);
    }

    // Try catch failed, return empty object? Errors without this here.
    return {
        commonTransactions: [],
        paymentSources: [],
    };
});

/**
 * Distributes totalPayment to all transaction with given encounterId.
 *
 * Will add highest transaction amount to first procedure, then move on to the next, and so on.
 *
 * @param {number} totalPayment
 * @param {string} encounterId
 * @param {IBillingProcedure[]} billingProcedures
 * @return {*}  {AppThunk<void>}
 */
export const distributePaymentAndUpdateTransactions =
    (totalPayment: number, encounterId: string, billingProcedures: IBillingProcedure[]): AppThunk<void> =>
        (dispatch, getState) => {
            const currentTransactions = selectCurrentTransactions(getState());

            const viewTransactions = currentTransactions.filter(
                (t) => billingProcedures.findIndex((p) => p.id === t.chartProcedureId) > -1 && t.encounterId === encounterId,
            );

            let totalPaymentAmountRemaining = totalPayment;

            viewTransactions.forEach((transaction) => {
                const billingProcedure = billingProcedures.find((p) => p.id === transaction.chartProcedureId);
                const patientBalance = billingProcedure?.patientBalance ?? 0;
                if (patientBalance > 0) {
                    const transactionAmount = Number(
                        (patientBalance > totalPaymentAmountRemaining ? totalPaymentAmountRemaining : patientBalance).toFixed(2),
                    );

                    totalPaymentAmountRemaining -= transactionAmount;

                    dispatch(updateTransactionAmount({ id: transaction.id, amount: transactionAmount }));
                }
            });
        };

enum LedgerCalculationUpdateActionType {
    ledgerTotalsView = 'ledgerTotalsView',
    recentTPView = 'recentTPView',
    uac = 'uac',
}

/**
 * Lookup actions based on LedgerCalculationUpdateActions
 * @type {*}
 * */
const ledgerCalculationUpdateActionLookup: Record<
    LedgerCalculationUpdateActionType,
    AsyncThunk<any, { patientId: string; tenantId: string }, { state: RootState }>
> = {
    [LedgerCalculationUpdateActionType.ledgerTotalsView]: getLedgerTotalsView,
    [LedgerCalculationUpdateActionType.recentTPView]: getPatientMostRecentTreatmentPlanCreditView,
    [LedgerCalculationUpdateActionType.uac]: getPatientTreatmentPlanCreditUnappliedPayments,
};

/**
 * Lookup to map the update actions based on the transaction type.
 * @type {*}
 *  */
const calculationLedgerUpdateLookup: Partial<Record<TransactionType, LedgerCalculationUpdateActionType[]>> = {
    [TransactionType.Payment]: [LedgerCalculationUpdateActionType.ledgerTotalsView],
    [TransactionType.Adjustment]: [LedgerCalculationUpdateActionType.ledgerTotalsView],
    [TransactionType.NegativePayment]: [
        LedgerCalculationUpdateActionType.ledgerTotalsView,
        LedgerCalculationUpdateActionType.uac,
        LedgerCalculationUpdateActionType.recentTPView,
    ],
    [TransactionType.UnappliedCredit]: [LedgerCalculationUpdateActionType.uac],
    [TransactionType.PrePayment]: [LedgerCalculationUpdateActionType.recentTPView],
};

export const handleLedgerCalculationUpdate =
    ({ patientId: calculatedPatientId, types }: ILedgerCalculationUpdate, tenantId: string, patientId: string): AppThunk<void> =>
        (dispatch) => {
            if (calculatedPatientId === patientId) {
                const actions = new Set<LedgerCalculationUpdateActionType>(...types.map((t) => calculationLedgerUpdateLookup[t]));
                //All actions will be unique

                if (actions.size > 0)
                    actions.forEach((action) => {
                        dispatch(ledgerCalculationUpdateActionLookup[action]({ patientId, tenantId }));
                    });
            }
        };
export const handleCheckoutCalculationUpdate =
    (
        { patientId: calculatedPatientId, types }: ILedgerCalculationUpdate,
        tenantId: string,
        patientId: string,
        appointmentId: string | undefined,
    ): AppThunk<void> =>
        (dispatch) => {
            if (calculatedPatientId === patientId) {
                if (types.indexOf(TransactionType.PrePayment) > -1 || types.indexOf(TransactionType.Payment) > -1)
                    dispatch(getCheckoutBillingProcedures({ patientId, tenantId, appointmentId }));
            }
        };

export const rebillEncounterById = createAsyncThunk(
    'billing/rebillEncounterById',
    async ({ encounterId, tenantId, note }: { encounterId: string; tenantId: string; note: string }, { rejectWithValue }) => {
        try {
            const { data } = await dentalApi.rebillEncounterById(tenantId, encounterId, note);
            return data;
        } catch (e) {
            rejectWithValue(e as string);
        }
    },
);
