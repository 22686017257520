import {
    DirectionalHint,
    IComboBox,
    IComboBoxOption,
    Label,
    Spinner,
    Stack,
    Text,
    TextField,
    TooltipHost,
} from '@fluentui/react';
import { AppointmentCancellationDepartmentType } from 'api/models/appointment-cancellation-reason.model';
import IOperatory from 'api/models/Scheduling/operatory.model';
import schedulingApi from 'api/scheduling.api';
import { Field, TModal } from 'components';
import { useSelector, useTenantId, useValidation } from 'hooks';
import { getValidationError } from 'hooks/useValidation';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { FormEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    cancelAppointment,
    getAppointmentToCancel,
    getCancellationReasons,
} from 'state/slices/scheduling/appointment-to-cancel/appointment-to-cancel.actions';
import {
    selectAppointmentIdToCancel,
    selectAppointmentToCancel,
    selectAppointmentToCancelLoading,
    selectCancellationReasons,
} from 'state/slices/scheduling/appointment-to-cancel/appointment-to-cancel.selectors';
import {
    cleanupCancelAppointment,
    editAppointmentToCancelProp,
    setCancelAppointmentModalOpen,
} from 'state/slices/scheduling/scheduling.slice';
import { getMissingUserIdentities } from 'state/slices/users-identities/user-identities.actions';
import { selectUserIdentitiesData } from 'state/slices/users-identities/user-identities.selectors';
import convertDashedDateString from 'utils/convertDateStringToLocal';
import { getTimeTextFromValue } from 'utils/getTimeOptions';
import CancelAppointmentPatientCard from './CancelAppointmentPatientCard';
import IPatientAppointment, { TrackerStatusIds } from 'api/models/Scheduling/patientAppointment.model';
import { TrackerStatus } from 'pages/Scheduling/components/TrackerStatusDropdown';

const getOperatory = async (tenantId: string, operatoryId: string) => {
    const res = await schedulingApi.getOperatoriesById(tenantId, operatoryId);
    return res.data;
};

enum CancelAppointmentFields {
    ReasonForCancellation = 'Reason for Cancellation',
}

type CancelAppointmentModalProps = {
    onCancelAppointment?: (appointment: IPatientAppointment | undefined) => void;
    onDismiss?: () => void;
    isOpen: boolean;
};
function CancelAppointmentModal({ isOpen, onCancelAppointment, onDismiss }: CancelAppointmentModalProps) {
    const dispatch = useDispatch();

    const appointmentId = useSelector(selectAppointmentIdToCancel);
    const appointment = useSelector(selectAppointmentToCancel);
    const isAppointmentToCancelLoading = useSelector(selectAppointmentToCancelLoading);
    const cancellationReasons = useSelector(selectCancellationReasons);
    const usersData = useSelector(selectUserIdentitiesData);

    const tenantId = useTenantId();

    const isLoadingCancelAppt = isAppointmentToCancelLoading === LoadingStatus.Pending;

    const disableCancelAppt =
        isLoadingCancelAppt || getDisableCancelAppointment(appointment?.encounterId, appointment?.trackerStatusId);

    const [operatory, setOperatory] = useState<IOperatory | undefined>();

    //Handle get operatory and TP data
    useEffect(() => {
        if (appointmentId && isOpen) {
            dispatch(getAppointmentToCancel({ tenantId, appointmentId }));
            dispatch(getCancellationReasons({ tenantId, type: AppointmentCancellationDepartmentType.Dental }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appointmentId, isOpen]);

    useEffect(() => {
        if (appointment) {
            getOperatory(tenantId, appointment.operatoryId).then((o) => {
                setOperatory(o);
            });

            if (appointment.treatingProviderId)
                dispatch(getMissingUserIdentities({ tenantId, userIds: [appointment.treatingProviderId] }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appointment?.id]);

    const _cancelAppointment = () => {
        if (appointment) {
            const updatedAppt = { ...appointment, isDeleted: true };
            if (onCancelAppointment) {
                onCancelAppointment(updatedAppt);
            } else {
                dispatch(cancelAppointment(tenantId, updatedAppt));
            }
        }
    };

    const [errors, submit, cleanupErrors] = useValidation(
        [{ fieldName: 'Reason for Cancellation', validation: ['required'], value: appointment?.cancellationReason }],
        //Can override the normal cancel appointment function
        _cancelAppointment,
    );

    const _onDismissed = () => {
        dispatch(cleanupCancelAppointment());
        setOperatory(undefined);
        cleanupErrors();
    };

    const _onDismiss = () => {
        if (onDismiss) {
            onDismiss();
        } else {
            dispatch(setCancelAppointmentModalOpen({ isOpen: false }));
        }
    };

    const _onChangeCancellationReason = (ev: FormEvent<IComboBox>, value: IComboBoxOption | undefined) => {
        dispatch(editAppointmentToCancelProp({ key: 'cancellationReasonId', value: value?.key as string }));
        dispatch(editAppointmentToCancelProp({ key: 'cancellationReason', value: value?.text as string }));
        cleanupErrors();
    };

    const _onChangeCancellationNote = (ev: FormEvent<HTMLTextAreaElement | HTMLInputElement>, value: string | undefined) => {
        dispatch(editAppointmentToCancelProp({ key: 'cancellationNote', value }));
    };

    const _cancellationReasonOptions: IComboBoxOption[] = cancellationReasons.map((reason) => ({
        key: reason.id,
        text: reason.displayName,
    }));

    const _appointmentProcedures = appointment?.procedures ? appointment?.procedures.map((proc) => proc.code) : [];

    const _appointmentInfo = [
        {
            label: 'Date',
            value: appointment?.date ? convertDashedDateString(appointment.date) : 'N/A',
        },
        {
            label: 'Start Time',
            value: appointment?.startTime ? getTimeTextFromValue(appointment.startTime) : 'N/A',
        },
        {
            label: 'Treating Provider',
            value:
                appointment?.treatingProviderId && usersData[appointment.treatingProviderId]
                    ? usersData[appointment.treatingProviderId]?.displayName
                    : 'N/A',
        },
        {
            label: 'Operatory',
            value: operatory ? operatory.displayName : 'N/A',
        },
        {
            label: 'Procedures',
            hasTooltip: true,
            rawValue: _appointmentProcedures,
            value: _appointmentProcedures?.length
                ? `${_appointmentProcedures.slice(0, 3).join(', ')}${_appointmentProcedures.length > 3 ? '...' : ''}`
                : 'N/A',
        },
    ].map((data, index) => (
        <Stack key={index} horizontalAlign="center" verticalAlign="center" grow>
            <Label>{data.label}</Label>
            {!data.hasTooltip ? (
                <Text>{data.value}</Text>
            ) : (
                <TooltipHost
                    directionalHint={DirectionalHint.bottomCenter}
                    content={data.rawValue ? data.rawValue.join(', ') : ''}
                >
                    <Text>{data.value}</Text>
                </TooltipHost>
            )}
        </Stack>
    ));

    return (
        <TModal
            title="Cancel Appointment"
            isDraggable
            modalProps={{ isBlocking: false, onDismissed: _onDismissed, onDismiss: _onDismiss, isOpen }}
            mainButtons={[
                {
                    type: 'PrimaryButton',
                    text: 'Cancel Appointment',
                    onClick: submit,
                    disabled: disableCancelAppt,
                },
                {
                    text: 'Close',
                    onClick: _onDismiss,
                },
            ]}
        >
            <Stack tokens={{ childrenGap: 5 }} style={{ padding: 10 }} grow>
                <CancelAppointmentPatientCard onDismiss={_onDismiss} />
                <Stack horizontal tokens={{ childrenGap: 5 }} grow>
                    {_appointmentInfo}
                </Stack>
                {isLoadingCancelAppt ? (
                    <Spinner labelPosition="right" label="Loading..." />
                ) : (
                    <Stack tokens={{ childrenGap: 5 }} grow>
                        <Field.SearchCombo
                            onChange={_onChangeCancellationReason}
                            selectedKey={appointment?.cancellationReasonId ?? ''}
                            required
                            options={_cancellationReasonOptions}
                            label="Reason for Cancellation"
                            maxResults={100}
                            errorMessage={
                                getValidationError(errors, CancelAppointmentFields.ReasonForCancellation)
                                    ? 'Missing required field.'
                                    : undefined
                            }
                        />
                        <TextField
                            onChange={_onChangeCancellationNote}
                            value={appointment?.cancellationNote ?? ''}
                            label="Note"
                        />
                    </Stack>
                )}
            </Stack>
        </TModal>
    );
}

export function getDisableCancelAppointment(
    appointmentEncounterId: string | undefined,
    trackerStatusId: TrackerStatusIds | undefined,
): boolean {
    return !!appointmentEncounterId && trackerStatusId !== 'arrived' && !!trackerStatusId;
}

export default CancelAppointmentModal;
