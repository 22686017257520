import Tooth, { childMolars, molars } from './Tooth';
import * as PIXI from 'pixi.js-legacy';
import { matchPath } from 'react-router';

/**
 * Used to create a grouping of sprites for a tooth.
 * Also positions container X,Y relative to tooth container
 *
 * @export
 * @class ToothAreaGroup
 * @extends {PIXI.Container}
 */
export class ToothAreaGroup extends PIXI.Container {
    public _loader = PIXI.Loader.shared;
    public _tooth: Tooth;
    public _toothWidth: number;
    public _toothScale: number;
    public originalScaleX: number;
    public originalScaleY: number;

    constructor(tooth: Tooth, name: string, parent = true) {
        super();
        //Setup tooth data
        this._tooth = tooth;
        this._toothWidth = this._tooth.getToothWidth;
        this._toothScale = this._tooth.toothReference.scaleFactor ? this._tooth.toothReference.scaleFactor : 1;
        this.originalScaleX = this.scale.x;
        this.originalScaleY = this.scale.y;

        if (parent) this.setParent(tooth);

        // Setup the container
        this.name = `${name}${tooth.data.id}`;
        let scale = this._toothScale;

        const { dentitionMode } = this._tooth.data;
        // Handle scaling of primary teeth
        if (dentitionMode === 'Primary' || dentitionMode === 'PrimarySupernumerary') {
            if (this.isMolar) {
                this.alpha = 0;
            } else {
                scale = this._toothScale / 1.2;
            }
        }
        this.setScale(scale);
        // Based on scale, always keep these containers centered in the parent tooth container.
    }

    public get isMolar(): boolean {
        return molars.indexOf(this._tooth.getToothPosition) > -1;
    }
    public get isChildMolar(): boolean {
        return childMolars.indexOf(this._tooth.getToothPosition) > -1;
    }

    public get getRouteParams(): { tenantId: string; patientId: string } {
        const params = matchPath<{ tenantId: string; patientId: string }>(window.location.pathname, {
            path: '/:tenantId/:page/:patientId',
        })?.params;
        return { tenantId: params ? params.tenantId : '', patientId: params ? params.patientId : '' };
    }

    /**
     * Translate container relative to current position
     *
     * @private
     * @memberof Tooth
     */
    public setTranslate(x?: number, y?: number): void {
        if (x) this.x = this.x + (this.getIsLeftMouth ? -x : x);
        if (y) this.y = this.y + y;
    }

    public setRotation(value?: number): void {
        if (value)
            this.angle = this.getIsBottomRow ? (this.getIsLeftMouth ? -value : value) : this.getIsLeftMouth ? value : -value;
    }

    public setScale(value: number): void {
        this.scale.set(this.originalScaleX * value, this.originalScaleY * value);
        this.x = (1 - value) * (this._toothWidth / 2);
    }

    public get getIsBottomRow(): boolean {
        const toothIndexPosition = this._tooth.getToothPosition - 1;
        return toothIndexPosition >= 16 || toothIndexPosition >= 43;
    }

    public get getIsLeftMouth(): boolean {
        const position = this._tooth.getToothPosition - 1;
        return position <= 7 || position >= 24;
    }
}
