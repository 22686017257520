import IChart, { IChartProcedure } from 'api/models/chart.model';
import { IProcedure } from 'api/models/procedure.model';
import { find, flatten, forEach, isArray, range, uniq } from 'lodash';
import Pipeline from '../pipeline';
import ProcedureBusinessRuleGenerator from './procedureBusinessRuleGenerator';
import * as rules from './business-rules';
import { getArchOfTooth, SpriteReference, toothSpriteReferences } from 'pages/Charting/components/ToothCanvas/spriteList';
import { IDiagnosis } from 'api/models/diagnosis.model';
import archProcedureCodeLookup from '../archProcedureCodeLookup';
import { IPatientEncounter } from 'api/models/encounter.model';

type PipelineArgs = {
    chartProcedures: IChartProcedure[];
    chartProceduresList: IChartProcedure[];
    originalProcedures: IProcedure[];
    procedures: IProcedure[];
    diagnoses: IDiagnosis[];
    selectedTeeth?: number[];
    encounter?: IPatientEncounter;
};

export interface IChartProcedureWithOriginalProcedure extends IChartProcedure {
    originalProcedureId?: string;
}

/**
 * Runs rules on specified set of IChartProcedures
 *
 * @export
 * @class ProcedureBusinessRulesPipeline
 * @extends {Pipeline<IChartProcedure>}
 */
export default class ProcedureBusinessRulesPipeline extends Pipeline<IChartProcedureWithOriginalProcedure> {
    public selectedTeeth: number[] = [];
    public procedures: IProcedure[];
    public chartProceduresList: IChartProcedure[];
    public originalProcedures: IProcedure[];
    public encounter?: IPatientEncounter;
    private diagnosesList: IDiagnosis[];

    constructor({
        procedures,
        chartProcedures,
        selectedTeeth,
        diagnoses,
        chartProceduresList,
        originalProcedures,
        encounter,
    }: PipelineArgs) {
        //We need to preserve the original procedure id.
        super(chartProcedures);

        if (selectedTeeth) this.selectedTeeth = selectedTeeth;
        this.procedures = procedures;
        this.diagnosesList = diagnoses ?? [];
        this.chartProceduresList = chartProceduresList;
        this.originalProcedures = originalProcedures;
        this.encounter = encounter;
        this.runProcedureBusinessRules();
    }

    public updateChartProcedure(item: IChartProcedure) {
        this.updateItem(item)
    }

    public getChartProcedureListByCurrentEncounter() {
        const encounterId = this.encounter?.id
        if(encounterId){
            return this.chartProceduresList.filter(p => p.encounterId === encounterId)
        }
        return [] 
    }

    public getToothIdsByArch(item: IChartProcedure) {
        const appliedTeeth = uniq(flatten(item.toothIds?.filter((id) => id !== undefined)) as number[]);
        const teethPerArch: Record<'UA' | 'LA', number[]> = { UA: [], LA: [] };

        //Organize toothids from the given chart procedure into their respective arches
        forEach(appliedTeeth, (tooth) => {
            const arch = getArchOfTooth(tooth);
            if (arch)
                teethPerArch[arch] = teethPerArch[arch]?.length ? (teethPerArch[arch] = [...teethPerArch[arch], tooth]) : [tooth];
        });

        return teethPerArch;
    }

    public getArchProcedureCodeGroup(originalProcedure: IProcedure) {
        return find(archProcedureCodeLookup, (lookupObj) => {
            return originalProcedure?.code
                ? lookupObj.maxillary === originalProcedure.code || lookupObj.mandibular === originalProcedure.code
                : false;
        });
    }

    public get firstOriginalProcedure() {
        return this.originalProcedures[0];
    }

    public getValidArchProcedureForArch(item: IChartProcedure): IChartProcedure | undefined {
        const { code } = this.firstOriginalProcedure;

        const procedcureCodeGroup = find(archProcedureCodeLookup, (lookupObj) => {
            return code ? lookupObj.maxillary === code || lookupObj.mandibular === code : false;
        });

        const tooth = item.toothIds ? item.toothIds[item.toothIds.length - 1] : undefined;
        if (procedcureCodeGroup && tooth) {
            const arch = this.getIsToothMaxillary(tooth) ? 'maxillary' : 'mandibular';
            const code = procedcureCodeGroup[arch] as string;
            const newProcedure = this.getProcedureFromCode(code);
            const newChartProcedure: IChartProcedure = { ...item, procedureId: newProcedure?.id };
            return newChartProcedure.procedureId ? newChartProcedure : item;
        }

        return undefined;
    }

    /**
     * Get dx from specified code
     *
     * @param {string} [code]
     * @return {*}  {(IDiagnosis | undefined)}
     * @memberof ProcedureBusinessRulesPipeline
     */
    public getDXFromCode(code?: string): IDiagnosis | undefined {
        if (!code || !this.diagnosesList) return;
        return find(this.diagnosesList, (d) => d.code === code);
    }

    /**
     * Get IProcedure from specified code
     *
     * @param {string} [code]
     * @return {*}  {(IProcedure | undefined)}
     * @memberof ProcedureBusinessRulesPipeline
     */
    public getProcedureFromCode(code?: string): IProcedure | undefined {
        if (!this.procedures) return undefined;
        return find(this.procedures, (p) => p?.code === code);
    }

    /**
     * Get IProcedure id from specified code
     *
     * @param {string} [code]
     * @return {*}  {(string | undefined)}
     * @memberof ProcedureBusinessRulesPipeline
     */
    public getProcedureIdFromCode(code?: string): string | undefined {
        if (!this.procedures) return undefined;
        return find(this.procedures, (p) => p?.code === code)?.id;
    }

    /**
     * Get IProcedure from speicfied IChartProcedure
     *
     * @param {IChartProcedure} procedure
     * @return {*}  {(IProcedure | undefined)}
     * @memberof ProcedureBusinessRulesPipeline
     */
    public getProcedure(procedure: IChartProcedure): IProcedure | undefined {
        if (!this.procedures) return undefined;
        return find(this.procedures, (p) => p?.id === procedure.procedureId);
    }

    /**
     * Creates a rule generated for specified IChartProcedure, and runs relevant rules
     *
     * @private
     * @param {IChartProcedure} chartProcedure
     * @return {*}
     * @memberof ProcedureBusinessRulesPipeline
     */
    private runBusinessRules(chartProcedure: IChartProcedure) {
        const procedureResult = new ProcedureBusinessRuleGenerator({ chartProcedure, rules, procedurePipeline: this });
        this.addErrors(procedureResult.getErrors);
        if (!procedureResult.shouldRemoveItem) return procedureResult.getItem;
    }

    public getTeethIdsInQuads(selectedTeeth?: number[]) {
        if (!selectedTeeth?.length) return undefined;
        const quads: Record<string, number[]> = {
            ul: [1, 2, 3, 4, 5, 6, 7, 8],
            ur: [9, 10, 11, 12, 13, 14, 15, 16],
            lr: [17, 18, 19, 20, 21, 22, 23, 24],
            ll: [25, 26, 27, 28, 29, 30, 31, 32],
        };

        const references = this.getTeethReferences(selectedTeeth);
        const teethIdsInQuads: Record<string, number[]> = {
            ul: [],
            ur: [],
            lr: [],
            ll: [],
        };

        forEach(references, (ref) => {
            if (ref) {
                forEach(quads, (toothPositions, key) => {
                    if (toothPositions.indexOf(ref.position) > -1) {
                        teethIdsInQuads[key].push(ref.id);
                    }
                });
            }
        });

        return teethIdsInQuads;
    }

    /**
     * Returns an array of tooth positions based on given teeth
     *
     * @param {number[]} selectedTeeth
     * @return {*}  {((number | undefined)[])}
     * @memberof ProcedureBusinessRulesPipeline
     */
    public getTeethPosition(selectedTeeth: number[]): (number | undefined)[] {
        const positions = selectedTeeth.map((toothId) => toothSpriteReferences.find((ref) => ref.id === toothId)?.position);
        return positions;
    }

    public getTeethReferences(selectedTeeth: number[]): (SpriteReference | undefined)[] {
        const positions = selectedTeeth.map((toothId) => toothSpriteReferences.find((ref) => ref.id === toothId));
        return positions;
    }
    /**
     * Determines if the given tooth number is maxillary
     *
     * @param {number} selectedTooth
     * @return {*}  {boolean}
     * @memberof ProcedureBusinessRulesPipeline
     */
    public getIsToothMaxillary(selectedTooth: number): boolean {
        const position = this.getTeethPosition([selectedTooth])[0];
        return position ? range(0, 17).indexOf(position) > -1 : false;
    }

    /**
     * Runs procedure business rules and sets pipeline items
     *
     * @private
     * @memberof ProcedureBusinessRulesPipeline
     */
    private runProcedureBusinessRules() {
        if (this.items) {
            const procedures: IChartProcedure[] = [];
            this.items.forEach((chartProcedure: IChartProcedure) => {
                const newProcedures = this.runBusinessRules(chartProcedure);
                if (newProcedures) {
                    if (isArray(newProcedures)) {
                        procedures.push(...newProcedures);
                    } else {
                        procedures.push(newProcedures);
                    }
                }
            });
            this.setItems(procedures);
        }
    }
}
