import { FontIcon, mergeStyles, MessageBar, SelectionMode, Stack, Text, useTheme } from '@fluentui/react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentAppointmentSelectedPhasesLookup } from 'state/slices/scheduling/schedule-appointment/schedule-appointment.selectors';
import {
    IUpcomingTreatmentPlanPhaseData,
    selectAppointmentPatientProcedureTreatingProviderIds,
    selectLatestSignedTreatmentPlanView,
} from 'state/slices/scheduling/scheduling.selectors';
import { setSelectedCurrentAppointmentPhases } from 'state/slices/scheduling/scheduling.slice';
import { selectTheme } from 'state/slices/ui/ui.slice';
import UnscheduledTreatmentPlanPhaseRow from '../Checkout/UnscheduledTreatmentPlanPhaseRow';

/**
 * Thinking appointment procedures through a bit more:
 *
 * Firstly, on the schedule appt panel we only want the most recently signed TP procedures.
 *
 * Secondly, on the patient overview, we are looking at an appt that is already scheduled.
 * So we can't assume that the most recently signed TP is the correct data for a previously scheduled appt.
 *
 *
 * For appt overview:
 *      Instead of looking at whatever is the most recently signed TP. we will need to find all TPs that are signed up until
 *      the date of the appt. Find the latest signed TP relative to that date.
 *
 * Maybe we do this for both overview + appt panel.
 * When we schedule a new appt, we have the date. SOOO, why not?
 */

export default function ScheduleAppointmentProcedures(): JSX.Element {
    const dispatch = useDispatch();
    const themeType = useSelector(selectTheme);
    const theme = useTheme();

    const [expanded, setExpanded] = useState<boolean>(true);
    const _selectLatestSignedTreatmentPlanView = useSelector(selectLatestSignedTreatmentPlanView);
    const _selectAppointmentPatientProcedureTreatingProviderIds = useSelector(
        selectAppointmentPatientProcedureTreatingProviderIds,
    );
    const _selectCurrentAppointmentSelectedPhasesLookup = useSelector(selectCurrentAppointmentSelectedPhasesLookup);

    function onItemsSelected(selectedPhases: IUpcomingTreatmentPlanPhaseData[], treatmentPlanId?: string) {
        if (treatmentPlanId) dispatch(setSelectedCurrentAppointmentPhases({ phaseData: selectedPhases, treatmentPlanId }));
    }

    if (!_selectLatestSignedTreatmentPlanView?.phases?.length)
        return <MessageBar>No unscheduled treatment plan phases exist.</MessageBar>;

    const name = `Treatment Plan ${(_selectLatestSignedTreatmentPlanView?.indexOfTreatmentPlan ?? 0) + 1}`;

    const iconColumnClass = mergeStyles({
        fontSize: 15,
        fontWeight: 'bold !important',
        height: 24,
        width: 24,
        padding: '0 5px',
        cursor: 'pointer',
        color: theme.palette.black,
    });

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    return (
        <Stack>
            <Stack
                style={{
                    backgroundColor: themeType === 'dark' ? 'rgb(17, 17, 17)' : 'rgb(255, 255, 255)',
                    paddingLeft: 10,
                    paddingRight: 15,
                    paddingTop: 10,
                    paddingBottom: 10,
                    marginRight: 5,
                    marginLeft: 5,
                }}
                tokens={{ childrenGap: 15 }}
                horizontal
                grow
            >
                <Stack.Item
                    onClick={toggleExpanded}
                    style={{
                        cursor: 'pointer',
                    }}
                >
                    <FontIcon iconName={`${!expanded ? 'ChevronDown' : 'ChevronUp'}`} className={iconColumnClass} />
                </Stack.Item>
                <Stack.Item grow>
                    <Text style={{ fontWeight: 600 }}>{name}</Text>
                </Stack.Item>
            </Stack>
            {expanded && (
                <UnscheduledTreatmentPlanPhaseRow
                    treatmentPlanId={_selectLatestSignedTreatmentPlanView.treatmentPlanId}
                    treatingProviderIds={_selectAppointmentPatientProcedureTreatingProviderIds}
                    item={_selectLatestSignedTreatmentPlanView}
                    selectedTreatmentPlanPhases={
                        _selectCurrentAppointmentSelectedPhasesLookup[_selectLatestSignedTreatmentPlanView.treatmentPlanId]
                    }
                    onItemsSelected={onItemsSelected}
                    selectionMode={SelectionMode.multiple}
                />
            )}
        </Stack>
    );
}
