import {
    Checkbox,
    IContextualMenuItem,
    Label,
    MessageBar,
    MessageBarType,
    Separator,
    Stack,
    Text,
    TextField,
    useTheme,
} from '@fluentui/react';
import { ChartProcedureDiagnosis } from 'api/models/chart.model';
import IPatientPrescription from 'api/models/patient-prescription';
import { Field, SubSection } from 'components';
import { usePatientPrescriptionsPanel, useSelector, useTenant } from 'hooks';
import { IValidationError, getValidationError } from 'hooks/useValidation';
import { useDispatch } from 'react-redux';
import { chartProcedureDiagnosesByCurrentEncounter } from 'state/slices/encounter/encounter.selectors';
import { selectSelectedPatientDefaultPharmacy } from 'state/slices/patient/patient.selectors';
import { selectPrescriptionItemsList } from 'state/slices/prescriptions-panel/prescriptions-panel.selectors';
import { updateCurrentPrescriptionDiagnosis } from 'state/slices/prescriptions-panel/prescriptions-panel.slice';
import { DiagnosisSelector, ProviderDropdown } from '..';
import PanelSectionHeader from '../PanelSectionHeader';
import { selectProvidersData } from 'state/slices/tenant/providers.slice';

type Props = {
    validationErrors: IValidationError[];
};

export default function PrescriptionsMedications({ validationErrors }: Props): JSX.Element {
    const dispatch = useDispatch();
    const { palette } = useTheme();

    const {
        searchMedicationsPrescriptionPanel,
        setMedicationSearch,
        medicationSearch,
        searchingMedications,
        cleanupPrescriptionsMedicationsSearch,
        setNewPrescription,
        selectedPatientPrescription,
        updateCurrentPrescription,
    } = usePatientPrescriptionsPanel();

    const _providerLookup = useSelector(selectProvidersData);
    const _chartProcedureDiagnosesByCurrentEncounter = useSelector(chartProcedureDiagnosesByCurrentEncounter);

    const _selectPrescriptionItemOptions = useSelector(selectPrescriptionItemsList);

    const _selectedPatientDefaultPharmacy = useSelector(selectSelectedPatientDefaultPharmacy);

    const { getDiagnosesList, getDiagnosesData } = useTenant();

    function onChangeSearch(ev: React.FormEvent, value?: string | undefined) {
        if (value !== undefined) setMedicationSearch(value);
    }

    function onSearch(search: string) {
        searchMedicationsPrescriptionPanel(search);
    }

    const menuItems: IContextualMenuItem[] = _selectPrescriptionItemOptions.map((r) => ({
        key: r?.id ?? '',
        text: r?.displayName ?? '',
        onClick: () => {
            if (r) setNewPrescription(r);
        },
    }));

    function getDiagnosisIdListFromCode(diagnosisCode?: string): ChartProcedureDiagnosis[] | undefined {
        if (!diagnosisCode) return undefined;
        const dx = getDiagnosesList.find((d) => d.code === diagnosisCode);
        if (dx) return [dx];
    }

    function onUpdateNumericField(path: keyof IPatientPrescription, value?: string) {
        const isNumber = value !== undefined ? !isNaN(+value) : false;
        const numericalValue = value !== undefined && value !== '' ? +value : undefined;

        if (isNumber || value === '') {
            updateCurrentPrescription(path, numericalValue);
        }
    }

    const hasUnstructuredSigError = getValidationError(validationErrors, 'Unstructured Sig');
    const associatedDiagnosisError = getValidationError(validationErrors, 'Associated Diagnosis');

    const prescriber = _providerLookup[selectedPatientPrescription?.prescriberId ?? ''];
    const prescriberDisplayName = prescriber ? `${prescriber.firstName} ${prescriber.lastName}` : '';

    return (
        <Stack tokens={{ childrenGap: 10 }} grow>
            <PanelSectionHeader text="Prescription" />
            <Stack style={{ position: 'relative' }}>
                <Field.TypeaheadSearch
                    onSearch={onSearch}
                    placeholder="Search medications and select prescription"
                    onChange={onChangeSearch}
                    value={medicationSearch}
                    canClear
                    menuItems={menuItems}
                    onClear={cleanupPrescriptionsMedicationsSearch}
                    loading={searchingMedications}
                    autoSearch
                    autoSearchDelay={300}
                />
            </Stack>
            {selectedPatientPrescription && (
                <Stack grow>
                    <SubSection title={selectedPatientPrescription.displayName}>
                        <Stack verticalAlign="start">
                            <Stack>
                                <Label required>Unstructured SIG</Label>
                                <TextField
                                    value={
                                        selectedPatientPrescription.unstructuredSig
                                            ? selectedPatientPrescription.unstructuredSig
                                            : ''
                                    }
                                    onChange={(ev, value) => updateCurrentPrescription('unstructuredSig', value)}
                                    errorMessage={hasUnstructuredSigError ? 'Unstructured SIG is required.' : ''}
                                />
                            </Stack>
                        </Stack>
                        <Separator />
                        <Stack grow>
                            <Stack grow horizontal tokens={{ childrenGap: 10 }}>
                                <Stack horizontal grow tokens={{ childrenGap: 4 }} wrap verticalAlign="start">
                                    <TextField
                                        value={
                                            selectedPatientPrescription.numberOfRefills !== undefined
                                                ? selectedPatientPrescription.numberOfRefills?.toString()
                                                : ''
                                        }
                                        onChange={(ev, value) => onUpdateNumericField('numberOfRefills', value)}
                                        style={{ maxWidth: 40 }}
                                    />

                                    <Text>Refill(s)</Text>
                                </Stack>
                                <Checkbox
                                    label="DAW"
                                    checked={!!selectedPatientPrescription.dispenseAsWritten}
                                    onChange={(ev, checked) => {
                                        updateCurrentPrescription(
                                            'dispenseAsWritten',
                                            !selectedPatientPrescription.dispenseAsWritten,
                                        );
                                    }}
                                />
                            </Stack>
                        </Stack>
                        <Separator />
                        <Label>Send to</Label>
                        <Stack tokens={{ childrenGap: 5 }} grow>
                            <MessageBar messageBarType={MessageBarType.warning}>
                                {` ${
                                    _selectedPatientDefaultPharmacy?.pharmacyName
                                        ? `Please verify that this is the patient's default pharmacy.`
                                        : ''
                                } Use internal note to record new
                                pharmacy details (i.e. name and location) so that athenaOne can be updated.`}
                            </MessageBar>
                            <Stack>
                                <Label>{_selectedPatientDefaultPharmacy?.pharmacyName ?? ''}</Label>
                                <Text>{_selectedPatientDefaultPharmacy?.address1 ?? ''}</Text>
                                <Text>
                                    {_selectedPatientDefaultPharmacy?.city ? `${_selectedPatientDefaultPharmacy.city}, ` : ''}
                                    {_selectedPatientDefaultPharmacy?.state ? `${_selectedPatientDefaultPharmacy.state} ` : ''}
                                    {_selectedPatientDefaultPharmacy?.zip ? `${_selectedPatientDefaultPharmacy.zip} ` : ''}
                                </Text>
                            </Stack>

                            <TextField
                                label="Internal Note"
                                onChange={(ev, value) => updateCurrentPrescription('providerNote', value)}
                                value={selectedPatientPrescription.providerNote ? selectedPatientPrescription.providerNote : ''}
                                autoComplete={'off'}
                            />
                        </Stack>
                        <Separator />
                        <Label required>Associated Diagnosis</Label>
                        <DiagnosisSelector
                            noDiagnosisPlaceholder="No diagnoses found."
                            suggestionDiagnoses={_chartProcedureDiagnosesByCurrentEncounter}
                            updateDiagnoses={(dx) => {
                                if (getDiagnosesData)
                                    dispatch(updateCurrentPrescriptionDiagnosis(getDiagnosesData[dx[dx.length > 1 ? 1 : 0].id]));
                            }}
                            selectedDiagnoses={getDiagnosisIdListFromCode(selectedPatientPrescription.diagnosisCode)}
                            shouldAutoApply
                        />
                        {associatedDiagnosisError && (
                            <Text variant="small" styles={{ root: { color: palette.redDark } }}>
                                Associated Diagnosis is required.
                            </Text>
                        )}
                        <Separator />
                        <TextField
                            label="Note to Pharmacy"
                            value={selectedPatientPrescription.pharmacyNote ? selectedPatientPrescription.pharmacyNote : ''}
                            onChange={(ev, value) => updateCurrentPrescription('pharmacyNote', value)}
                        />

                        <TextField
                            label="Note to Patient"
                            value={selectedPatientPrescription.patientNote ? selectedPatientPrescription.patientNote : ''}
                            onChange={(ev, value) => updateCurrentPrescription('patientNote', value)}
                        />
                        <Separator />
                        <TextField disabled label="Prescriber" value={prescriberDisplayName} />
                    </SubSection>
                </Stack>
            )}
        </Stack>
    );
}
