import Tooth, { getOutlineFilter } from './Tooth';
import { ToothAreaGroup } from './ToothAreaGroup';
import Surface from './Surface';
import { getSurfaceName } from './getSurfaceName';
import { Surfaces } from './Surfaces';
import { ToothArea } from 'api/models/tooth-area';
import PixiText from './PixiText';
import * as PIXI from 'pixi.js-legacy';
import { IChartRenderProcedure } from 'api/models/tooth.model';

export interface IChartSurfaceData {
    id: number;
    sprite: string;
}

export default class SurfaceContainer extends ToothAreaGroup {
    private _surfaceData: IChartSurfaceData;

    constructor(tooth: Tooth, surfaceData: IChartSurfaceData, surfacesContainer: Surfaces) {
        super(tooth, getSurfaceName(tooth, surfaceData) ?? '', false);
        this._surfaceData = surfaceData;
        const surface = new Surface(tooth, surfaceData);
        this.setScale(0.9);
        this.setParent(surfacesContainer);

        this.addChild(surface);
        if (tooth.isToothVisible) this._handleTextProcedures();
        this.zIndex = 0;
    }

    private get _getLookupSealantProceduresOnTooth() {
        return {
            sealantRepair: this._tooth.isSealantRepairPerTooth(),
            sealant: this._tooth.isSealantPerTooth(),
            stainlessSteel: this._tooth.isStainlessSteelCrown(),
        };
    }

    private get _surfaceName() {
        return getSurfaceName(this._tooth, this._surfaceData);
    }

    private get _getIsOcclusalOrIncisialSurface() {
        return this._surfaceName === ToothArea.Occlusal || this._surfaceName === ToothArea.Incisal;
    }

    private _handleTextProcedures() {
        if (this._getIsOcclusalOrIncisialSurface) {
            const sealantRepair = this._getLookupSealantProceduresOnTooth.sealantRepair;
            const sealant = this._getLookupSealantProceduresOnTooth.sealant;
            const stainlessSteel = this._getLookupSealantProceduresOnTooth.stainlessSteel;

            const size = 75;

            if (sealantRepair) {
                this._addCenterText('SR', sealantRepair, size);
            } else if (sealant) {
                /* Sealant implementation */
                this._addCenterText('S', sealant, size);
            } else if (stainlessSteel) {
                /* Stainless Steel implementation */
                this._addCenterText('SS', stainlessSteel, size);
            }
        }
    }

    private _addCenterText(value: string, procedure: IChartRenderProcedure, fontSize = 40) {
        const txt = new PixiText(value, new PIXI.TextStyle({ fontSize, fontWeight: 'bolder' }));
        txt.style.fill = this._tooth.fetchActionColor(procedure);
        txt.scale.set(0.5, 0.5);
        txt.filters = [getOutlineFilter(0xe4e4e4, 2, 1)];
        txt.alpha = 0.9;

        txt.position.set(
            this.parent.width / this.parent.scale.x / 2 - (txt.width - 10) / 2,
            this.parent.height / this.parent.scale.y / 2 - (txt.height - 9) / 2,
        );

        this.zIndex = 3;
        txt.zIndex = 4;
        this.addChild(txt);
    }
}
