import {
    mergeStyleSets,
    getFocusStyle,
    getTheme,
    ITheme,
    FontSizes,
    FocusZone,
    FocusZoneDirection,
    List,
    Stack,
    Text,
    Spinner,
    SpinnerSize,
    SearchBox,
} from '@fluentui/react';
import { IServiceUserAccountTenant } from 'api/models/account.model';
import { useFuseSearch } from 'hooks/useFuseSearch';
import { LoadingStatus, LoadingStatuses } from 'interfaces/loading-statuses';

export type Props = {
    items?: IServiceUserAccountTenant[];
    onClick: (item: IServiceUserAccountTenant) => void;
    loading: LoadingStatuses;
};

const theme: ITheme = getTheme();
const { palette, semanticColors } = theme;

export default function CustomerSelection({ onClick, items, loading }: Props): JSX.Element {
    const { results, onSearch, search, onSearchChange } = useFuseSearch<IServiceUserAccountTenant>({
        fuseOptions: { keys: ['displayName', 'id'], threshold: 0.3 },
        list: items ?? [],
    });

    const classNames: any = mergeStyleSets({
        item: [
            getFocusStyle(theme, { inset: -1 }),
            {
                padding: 10,
                boxSizing: 'border-box',
                borderBottom: `1px solid ${semanticColors.bodyDivider}`,
                display: 'flex',
                fontSize: FontSizes.xLargePlus,
                selectors: {
                    '&:hover': {
                        background: palette.themePrimary,
                        color: palette.white,
                        cursor: 'pointer',
                    },
                },
            },
        ],
        itemContainer: {
            overflow: 'hidden',
            flexGrow: 1,
        },
    });

    const _onRenderCell = (item: IServiceUserAccountTenant | undefined): JSX.Element => {
        return item ? (
            <div className={classNames.itemContainer} data-is-focusable={true}>
                <div className={classNames.item} id={`tenant-${item.displayName}-${item.id}`} onClick={() => onClick(item)}>
                    {item.displayName} ({item.id})
                </div>
            </div>
        ) : (
            <></>
        );
    };

    const isLoadingTenants = loading === LoadingStatus.Pending;

    return (
        <Stack tokens={{ childrenGap: 10, padding: '40px' }} horizontalAlign="center" grow>
            <Stack>
                <Text style={{ fontSize: FontSizes.size32, fontWeight: 'bold' }}>athenaOne Dental</Text>
            </Stack>
            <Stack>
                <Text style={{ fontSize: FontSizes.size20 }}>Select an organization</Text>
            </Stack>
            <Stack styles={{ root: { width: '600px' } }}>
                {isLoadingTenants && (
                    <Spinner
                        style={{ width: 600, height: 150 }}
                        label="Loading account..."
                        title="Loading account..."
                        size={SpinnerSize.large}
                    />
                )}
                <FocusZone direction={FocusZoneDirection.vertical}>
                    <SearchBox
                        title="Search tenants"
                        value={search}
                        onChange={onSearchChange}
                        onSearch={onSearch}
                        placeholder="Filter tenants..."
                    />

                    <Stack style={{ overflow: 'auto', maxHeight: 500 }}>
                        <List items={results && search ? results : items} onRenderCell={_onRenderCell} />
                    </Stack>
                </FocusZone>
            </Stack>
        </Stack>
    );
}
