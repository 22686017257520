import { ColorOverlayFilter } from '@pixi/filter-color-overlay';
import Tooth, { ChartRenderAction } from './Tooth';

export default class CrownRenderRule {
    constructor(tooth: Tooth, action: ChartRenderAction) {
        const color = tooth.fetchActionColor(action);
        //Set color of buccal/crown
        if (tooth._frontContainer?._crownSprite) {
            tooth._frontContainer._crownSprite.filters = [new ColorOverlayFilter(color, 1)];
        }
        //Set color of occlusal surfaces
        if (tooth._surfacesContainer?.children)
            tooth._surfacesContainer.children.forEach((child) => {
                child.filters = [new ColorOverlayFilter(color, 1)];
            });
        //Set lingual surface
        if (tooth._backContainer) {
            tooth._backContainer.filters = [new ColorOverlayFilter(color, 1)];
        }
    }
}
