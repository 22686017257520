import { IChartProcedure } from 'api/models/chart.model';
import DiagnosisCodes from '../../DiagnosisCodes';
import { PipelineError } from '../../pipeline';
import { createRule } from '../../ruleGenerator';
import ProcedureDiagnosisConflictRulesPipeline from '../procedureDiagnosisConflictRules.pipeline';

const Z0121Rule = createRule<ProcedureDiagnosisConflictRulesPipeline, IChartProcedure>({
    ruleTypes: [DiagnosisCodes['Z01.21']],
    rule: (pipeline, item) => {
        const unsupportedDiagnoses = item.diagnosisCodes?.filter((dx) => {
            const dxCode = pipeline.diagnosisData[dx.id]?.code;
            return dxCode !== DiagnosisCodes['Z01.21'] && dxCode !== DiagnosisCodes['Z01.20'];
        });

        if (!unsupportedDiagnoses?.length) {
            return {
                shouldRemoveItem: true,
                errors: [
                    {
                        data: { item, diagnosisCode: DiagnosisCodes['Z01.21'] },
                        type: PipelineError.DiagnosisRequiresSupportingDiagnosis,
                    },
                ],
            };
        }

        return { updatedItems: item };
    },
});

export default Z0121Rule;
