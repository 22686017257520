import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { IWorkListsState, WorkList, WorkListConfirmModalType, WorkListOpenLayers } from './worklist.state';
import {
    getDashboardTotalFees,
    getWorkListData,
    getRecentlyViewedWorkListItem,
    getWorkListTotalItemCount,
} from './worklist.actions';
import { ITag, MessageBarType } from '@fluentui/react';
import { RecentWorkListItem } from 'utils/appLocalStorage';
import { isEmpty } from 'lodash';

const workListReducers = {
    //Set worklist state values.

    /**Sets the current worklist */
    setCurrentWorkList: (state: IWorkListsState, action: PayloadAction<WorkList>): void => {
        state.currentWorkList = action.payload;
    },
    /**Sets the current worklist recently viewed items */
    setWorkListRecentItems: (state: IWorkListsState, action: PayloadAction<RecentWorkListItem[]>): void => {
        state.recentWorkListItems = action.payload;
    },
    /**Sets the loading state for recently viewed items */
    setWorkListRecentItemLoading: (state: IWorkListsState, action: PayloadAction<LoadingStatus>): void => {
        state.loadingRecentWorkListItem = action.payload;
    },
    /**Sets a worklist layer to be open */
    setWorkListLayerOpen: (
        state: IWorkListsState,
        { payload }: PayloadAction<{ layer: keyof WorkListOpenLayers; isOpen: boolean }>,
    ): void => {
        const { isOpen, layer } = payload;
        state.openLayers[layer] = isOpen;
    },
    /**Sets worklist edit data item */
    setSelectedWorkListEditItem: (state: IWorkListsState, action: PayloadAction<unknown | undefined>): void => {
        state.editWorkListItem = action.payload;
    },
    /**Set worklist to be readonly true/false. */
    setWorkListReadOnly: (state: IWorkListsState, action: PayloadAction<boolean>): void => {
        state.readOnly = action.payload;
    },
    /**Set whether the user has searched to be true/false. */
    setWorkListHasSearched: (state: IWorkListsState, action: PayloadAction<boolean>): void => {
        state.hasSearched = action.payload;
    },
    /**Set the current worklist page number */
    setWorkListCurrentPageNumber: (state: IWorkListsState, action: PayloadAction<number>): void => {
        state.currentPageNumber = action.payload;
    },
    /**Set the current worklist items per page. Should be limited to the options of 25/50 */
    setWorkListItemsPerPage: (state: IWorkListsState, action: PayloadAction<number>): void => {
        state.itemsPerPage = action.payload;
        state.continuationTokens = undefined;
    },
    /**Set worklist message bar to be displayed to the user. */
    setWorkListMessageBar: (
        state: IWorkListsState,
        action: PayloadAction<{ message: string; messageBarType: MessageBarType }>,
    ): void => {
        const { message, messageBarType } = action.payload;
        state.messageBarMessage = message;
        state.messageBarType = messageBarType;
    },
    /**Sets the worklist selected items. */
    setWorkListSelectedItems: (state: IWorkListsState, action: PayloadAction<unknown[]>): void => {
        state.selectedWorkListItems = action.payload;
    },
    /** Set worklist confirmation modal open/closed, and confirmation modal type */
    setWorkListConfirmationModalOpen: (
        state: IWorkListsState,
        action: PayloadAction<{ isOpen: boolean; type?: WorkListConfirmModalType }>,
    ): void => {
        const { isOpen, type } = action.payload;
        state.confirmationModalIsOpen = isOpen;
        state.confirmationModalType = type;
    },
    /**Set worklist filter to a specified value */
    setWorkListFilter: (
        state: IWorkListsState,
        action: PayloadAction<{ path: string; value: string | string[] | ITag[] | number | boolean | undefined }>,
    ): void => {
        const { value, path } = action.payload;
        (state.filters[path] as string | string[] | ITag[] | number | boolean | undefined) = value;
    },
    /**Set entire worklist filter object*/
    setWorklistFilters: (
        state: IWorkListsState,
        action: PayloadAction<{ filters?: Record<string, unknown>; initialFilters?: Record<string, unknown> }>,
    ): void => {
        if (action.payload?.filters) state.filters = action.payload?.filters;

        const initialFilters = action.payload.initialFilters ?? {};
        state.initialFilters = initialFilters;
        if (isEmpty(state.filters)) state.filters = initialFilters;
    },

    //Remove, update, and add worklist data items.

    /**Removes a worklist data item at the specified index */
    removeWorkListDataAtIndex: (state: IWorkListsState, action: PayloadAction<number>): void => {
        const indexOfData = action.payload;
        if (state.totalItems !== undefined) state.totalItems -= 1;
        if (state.data) state.data = [...state.data.slice(0, indexOfData), ...state.data.slice(indexOfData + 1)];
    },
    /**Updates/replaces data at the specified index, with given data */
    updateWorkListDataAtIndex: (state: IWorkListsState, action: PayloadAction<{ data: unknown; index: number }>): void => {
        const { index, data } = action.payload;
        if (state.data) state.data[index] = data;
    },
    /**Add a new worklist data item to end of worklist data */
    addWorkListData: (state: IWorkListsState, action: PayloadAction<unknown>): void => {
        const item = action.payload;
        if (state.totalItems !== undefined) state.totalItems += 1;
        if (state.data) {
            state.data = [...state.data, item];
        } else {
            state.data = [item];
        }
    },

    //Cleanup worklist state.

    /**Cleans up the worklist message bar */
    cleanupWorkListMessageBar: (state: IWorkListsState): void => {
        state.messageBarMessage = undefined;
        state.messageBarType = undefined;
    },
    /**Cleans up all worklist pagination state */
    cleanupWorkListPagination: (state: IWorkListsState): void => {
        state.currentPageNumber = 1;
        state.totalItems = undefined;
        state.continuationTokens = undefined;
    },
    /**Cleans up all worklist open layers */
    cleanupWorkListOpenLayers: (state: IWorkListsState): void => {
        state.openLayers = {};
    },
    /**Cleans up current worklist continuation tokens */
    cleanupWorkListContinuationTokens: (state: IWorkListsState): void => {
        state.continuationTokens = undefined;
    },
    /**Cleans up current worklist state. Used when the user changes worklists. */
    cleanupWorkListData: (state: IWorkListsState): void => {
        // For some reason can't use inital state for encounter worklist to cleanup :(
        state.editWorkListItem = undefined;
        state.selectedWorkListItems = undefined;
        state.continuationTokens = undefined;
        state.data = undefined;
        state.currentPageNumber = 1;
        state.totalItems = undefined;
        state.itemsPerPage = 25;
        state.loading = LoadingStatus.Idle;
        state.saving = LoadingStatus.Idle;
        state.voidProcedureSaving = LoadingStatus.Idle;
        state.readOnly = false;
        state.currentWorkList = undefined;
        state.filters = {};
        state.initialFilters = {};
        state.openLayers = {};
        state.recentWorkListItems = undefined;
        state.hasSearched = false;
        state.messageBarMessage = undefined;
        state.messageBarType = undefined;
    },
    /**Cleans up worklist confirmation modal state */
    cleanupWorkListConfirmationModal: (state: IWorkListsState): void => {
        state.confirmationModalType = undefined;
        state.encounterWorkListNoteModal.savingNoteData = LoadingStatus.Idle;
    },
    /**Cleans up worklist filters */
    cleanupWorkListFilters: (state: IWorkListsState): void => {
        state.filters = state.initialFilters;
    },

    /**Toggle a filter value at a specified path. */
    toggleWorkListFilterPropItem: (
        state: IWorkListsState,
        action: PayloadAction<{ path: string; value: string | number }>,
    ): void => {
        const { path, value } = action.payload;
        if (state.filters[path]) {
            const items = state.filters[path] as (string | number)[];
            const indexOfItem = items.indexOf(value);
            if (indexOfItem > -1) {
                if ((state.filters[path] as (string | number)[]).length === 1) {
                    state.filters[path] = undefined;
                } else {
                    (state.filters[path] as (string | number)[]) = [
                        ...items.slice(0, indexOfItem),
                        ...items.slice(indexOfItem + 1),
                    ];
                }
            } else {
                (state.filters[path] as (string | number)[]) = [...items, value];
            }
        } else {
            (state.filters[path] as (string | number)[]) = [value];
        }
    },
};

export default workListReducers;

export const workListExtraReducers = (builder: ActionReducerMapBuilder<IWorkListsState>) => {
    builder
        .addCase(getWorkListData.pending, (state) => {
            state.loading = LoadingStatus.Pending;
        })
        .addCase(getWorkListData.fulfilled, (state, action) => {
            const { views, continuationToken } = action.payload;
            state.loading = LoadingStatus.Completed;
            //Handle no content return. Data state prop should always contain array.
            state.data = !views ? [] : views;
            state.pageCount = action.payload.pageCount;
            if (action.payload.totalCount) state.totalItems = action.payload.totalCount;

            //If tokens exists and the incoming continuation token is falsy and the continuation tokens length is greater than the current page number.
            if (
                state.continuationTokens?.length &&
                !continuationToken &&
                state.currentPageNumber < state.continuationTokens.length
            ) {
                //index of the token for the current page.
                const indexOfPageToken = state.currentPageNumber - 1;
                //remove all tokens after and including the current page token index
                state.continuationTokens = [...state.continuationTokens.slice(0, indexOfPageToken)];
            }

            if (!state.continuationTokens) {
                state.continuationTokens = [continuationToken];
            } else {
                if (state.continuationTokens.indexOf(continuationToken) === -1)
                    state.continuationTokens = [...state.continuationTokens, continuationToken];
            }
        })
        .addCase(getWorkListData.rejected, (state, { error }) => {
            //If we're not aborting the request, handle the error normally
            if (error.name !== 'AbortError') {
                state.loading = LoadingStatus.Failed;
                state.data = [];
            } else {
                state.loading = LoadingStatus.Idle;
            }
        })
        .addCase(getWorkListTotalItemCount.pending, (state) => {
            state.loadingTotalItems = LoadingStatus.Pending;
        })
        .addCase(getWorkListTotalItemCount.fulfilled, (state, action) => {
            state.loadingTotalItems = LoadingStatus.Completed;
            state.totalItems = action.payload;
        })
        .addCase(getWorkListTotalItemCount.rejected, (state) => {
            state.loadingTotalItems = LoadingStatus.Failed;
        })
        .addCase(getDashboardTotalFees.pending, (state, action) => {
            state.detailDataLoading = LoadingStatus.Pending;
        })
        .addCase(getDashboardTotalFees.fulfilled, (state, action) => {
            state.detailData = action.payload;
            state.detailDataLoading = LoadingStatus.Completed;
        })
        .addCase(getDashboardTotalFees.rejected, (state, action) => {
            state.detailDataLoading = LoadingStatus.Failed;
        })
        .addCase(getRecentlyViewedWorkListItem.pending, (state) => {
            state.loadingRecentWorkListItem = LoadingStatus.Pending;
        })
        .addCase(getRecentlyViewedWorkListItem.fulfilled, (state) => {
            state.loadingRecentWorkListItem = LoadingStatus.Completed;
        })
        .addCase(getRecentlyViewedWorkListItem.rejected, (state) => {
            state.loadingRecentWorkListItem = LoadingStatus.Failed;
            state.messageBarMessage = 'Something went wrong.';
            state.messageBarType = MessageBarType.error;
        });
};
