import { useHistory } from 'react-router-dom';
import CustomerSelection from './CustomerSelection';
import { useAccount } from 'hooks';
import { IServiceUserAccountTenant } from 'api/models/account.model';

const CustomerSelectionContainer = (): JSX.Element => {
    const history = useHistory();
    const { loading, tenantsList } = useAccount();

    const _onTenantSelect = (item: IServiceUserAccountTenant) => history.push(`/${item.id}`);

    if (tenantsList.length === 1) {
        const firstTenant = tenantsList[0];
        history.push(`/${firstTenant.id}`);
    }
    return <CustomerSelection items={tenantsList} loading={loading} onClick={_onTenantSelect} />;
};

export default CustomerSelectionContainer;
