import { useTheme, mergeStyles, TooltipHost, Stack, Icon, DetailsRowCheck } from '@fluentui/react';
import IWorkListEncounterView from 'api/models/worklist-encounter-view';
import { IExtendedDetailsRowCheckProps } from '../WorkListDetailListRow';
import { worklistItemCanBeApprovedErrors } from './canWorklistItemBeApproved';
import ApproveWorklistItemMessage from './ApproveWorklistItemMessage';

const worklistUnableToApproveError = mergeStyles({
    height: 42,
    width: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

export function ApprovalWorklistCheckbox(props: IExtendedDetailsRowCheckProps<IWorkListEncounterView>) {
    const { palette } = useTheme();

    const unableToApproveIcon = mergeStyles({
        color: palette.orange,
        fontSize: 18,
    });

    const errorMessages = worklistItemCanBeApprovedErrors(props.item);
    if (errorMessages.length) {
        return (
            <TooltipHost
                content={
                    <Stack>
                        <ApproveWorklistItemMessage item={props.item} />
                    </Stack>
                }
            >
                <div className={worklistUnableToApproveError}>
                    <Icon className={unableToApproveIcon} iconName="Warning" />
                </div>
            </TooltipHost>
        );
    }

    return <DetailsRowCheck {...props} />;
}
