import ToothSprite from './ToothSprite';
import Tooth from './Tooth';
import { ToothArea } from 'api/models/tooth-area';
import { OutlineFilter } from '@pixi/filter-outline';
import { ColorOverlayFilter } from '@pixi/filter-color-overlay';
import * as PIXI from 'pixi.js-legacy';

export default class Crown extends ToothSprite {
    constructor(tooth: Tooth) {
        super(tooth.toothReference.crown, tooth);
        // NOTE: Name of tooth sprite is important for other classes to be able to reference crown as a child of front container class.
        this.name = 'Crown';
        if (!tooth.isAbutmentMarylandBridge()) {
            tooth.handleActionColor(tooth.getAreaActions(ToothArea.Crown), { sprite: this });
        }
        this._handleProcedureRenderCases(tooth);
    }

    private _handleProcedureRenderCases(tooth: Tooth) {
        if (tooth.missingRetainedRoot) this.showSprite = false;
        if (tooth.isImplantSupportedCrown() || tooth.getDentureProcedure() || tooth.isPontic()) {
            this.showSprite = true;
        }

        if (tooth.hasDenture) {
            const dentureProcedure = tooth.getDentureProcedure();
            if (dentureProcedure) tooth.handleActionColor([dentureProcedure], { sprite: this });
        } else if (tooth.hasVeneer) {
            const veneerProcedure = tooth.getVeneerProcedure();
            if (veneerProcedure) {
                this.filters = [new OutlineFilter(1, (tooth.fetchActionColor(veneerProcedure), 0.5))];
            }
        }

        const abutmentSupportCrown = tooth.isAbutmentSupportedCrown();
        if (abutmentSupportCrown) {
            this.filters = [new ColorOverlayFilter(tooth.fetchActionColor(abutmentSupportCrown), 1) as unknown as PIXI.Filter];
        }
    }
}
