import { CommandBar, Label, Separator, Stack, Text } from '@fluentui/react';
import useRouter from 'hooks/store/useRouter';
import ClaimHistoryEncounterNoteModal from './ClaimHistoryEncounterNoteModal';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedClaimHistoryEncounterSummary } from 'state/slices/ledger/claim-history/claim-history.selectors';
import { EncounterStatus, encounterStatusText } from 'api/models/encounter.model';
import { WorkListMessageBar } from 'pages/Landing/AdminHuddle/components/WorkListBuilder';
import { SignalRMessage, useSignalR } from 'hooks/signalr/useSignalr';
import { handleUpdateClaimHistoryEncounterStatus } from 'state/slices/ledger/claim-history/claim-history.actions';
import { setClaimHistoryEncounterNoteModalOpen } from 'state/slices/ledger/ledger.slice';
import { cleanupWorkListMessageBar } from 'state/slices/admin-huddle/worklists/worklist.slice';

export default function SelectedClaimHistoryCommandBar() {
    const { pushWithBaseParameters } = useRouter();
    const { registerSignalRMessage } = useSignalR();
    const dispatch = useDispatch();

    const selectedEncounterSummary = useSelector(selectSelectedClaimHistoryEncounterSummary);
    const encounterStatus = selectedEncounterSummary?.encounterStatus;
    const encounterClaimStatus = selectedEncounterSummary?.claimStatus ?? 'None';

    const goBack = () => {
        pushWithBaseParameters('ledger/claim-history');
    };

    const canAddNote = encounterStatus === EncounterStatus.Billed;

    useEffect(() => {
        registerSignalRMessage(SignalRMessage.UpdatedEncounterStatus, handleUpdateClaimHistoryEncounterStatus);
        return () => {
            dispatch(cleanupWorkListMessageBar());
        };
    }, [dispatch, registerSignalRMessage]);

    return (
        <>
            <ClaimHistoryEncounterNoteModal />
            <WorkListMessageBar />
            <CommandBar
                styles={{ root: { paddingLeft: 0, paddingRight: 10 } }}
                items={[
                    { key: 'back', text: 'Back', iconProps: { iconName: 'Back' }, onClick: goBack },
                    {
                        key: 'addNote',
                        text: 'Add Correction Notes/Send for Correction',
                        iconProps: { iconName: 'AddNotes' },
                        disabled: !canAddNote,
                        onClick: () => {
                            dispatch(setClaimHistoryEncounterNoteModalOpen(true));
                        },
                    },
                ]}
                farItems={[
                    {
                        key: 'approvalStatus',
                        text: encounterStatus ? `${encounterStatusText[encounterStatus]}` : '',
                        onRender: (item) => (
                            <Stack grow verticalAlign="center">
                                <Label styles={{root: {padding: 0}}}>Approval Status:</Label>
                                <Text title={item.text}>{item.text}</Text>
                            </Stack>
                        ),
                    },

                    {
                        key: 'divider',
                        onRender: () => <Separator styles={{ root: { paddingLeft: 10, paddingRight: 10 } }} vertical />,
                    },
                    {
                        key: 'billingStatus',
                        text: encounterClaimStatus,
                        onRender: (item) => (
                            <Stack grow verticalAlign="center">
                                <Label styles={{root: {padding: 0}}}>Claim Status:</Label>
                                <Text title={item.text}>{item.text}</Text>
                            </Stack>
                        ),
                    },
                ]}
            />
        </>
    );
}
