import { format } from 'date-fns';
import { RootState } from 'state/store';

const Allergies = ({ state }: { state: RootState }): JSX.Element | null => {
    const { patientEncounter, patientEncounterUserIdentities } = state.encounter;
    const { data } = state.patient.allergies;

    const allergies = state.chartNotes.quickNotes.assets.pamv.allergies;

    if (!patientEncounter || (!patientEncounter.allergiesReviewedDate && !allergies)) return null;

    const allergiesReviewedBy = patientEncounter.allergiesReviewedBy
        ? patientEncounterUserIdentities[patientEncounter.allergiesReviewedBy]
        : undefined;

    return (
        <>
            <h3>Allergies</h3>
            <ul>
                {data && data.nkda && <li>No known drug allergies</li>}
                {allergiesReviewedBy && (
                    <li>
                        Allergies reviewed by: {allergiesReviewedBy.firstName} {allergiesReviewedBy.lastName}
                    </li>
                )}
                {patientEncounter.allergiesReviewedDate && (
                    <li>
                        Allergies reviewed on:{' '}
                        {format(new Date(patientEncounter.allergiesReviewedDate ?? ''), 'MM/dd/yyyy hh:mm aaa')}
                    </li>
                )}
                {allergies
                    ? allergies.allergies?.map((allergy) => <li key={allergy.allergenId}>{allergy.allergenName}</li>)
                    : null}
            </ul>
        </>
    );
};

export default Allergies;
