import { IComboBoxOption } from '@fluentui/react';
import { createAsyncThunk, createSelector, createSlice, Dictionary } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import LookupBase from 'api/models/lookup-base.model';
import { AxiosError, AxiosResponse } from 'axios';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { LookupState } from 'interfaces/lookup-state';
import { orderBy } from 'lodash';
import { RootState } from 'state/store';

type AmendmentReasonState = LookupState<LookupBase>;

const initialState: AmendmentReasonState = {
    initialLoad: LoadingStatus.Idle,
    loading: LoadingStatus.Idle,
    data: {},
};

export const getAmendmentReasons = createAsyncThunk<
    AxiosResponse<Dictionary<LookupBase>>,
    void,
    {
        rejectValue: AxiosError;
    }
>('getAmendmentReasons', async () => {
    const res = await dentalApi.getAmendmentReasons();
    return res;
});

const amendmentReasonSlice = createSlice({
    name: 'amendmentReasons',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAmendmentReasons.pending, (state) => {
                state.initialLoad = LoadingStatus.Pending;
            })
            .addCase(getAmendmentReasons.fulfilled, (state, action) => {
                state.initialLoad = LoadingStatus.Completed;
                state.data = action.payload.data;
            })
            .addCase(getAmendmentReasons.rejected, (state, action) => {
                state.initialLoad = LoadingStatus.Failed;
                state.errors = action.payload?.message;
            });
    },
});

const { reducer } = amendmentReasonSlice;

export const selectAmendmentReasonsObject = (state: RootState): Dictionary<LookupBase> => state.tenant.amendmentReasons.data;
export const selectAmendmentReasonData = createSelector(selectAmendmentReasonsObject, (data) => (data ? data : {}));

export const selectAmendmentReason = createSelector(selectAmendmentReasonData, (lookups) => {
    return lookups;
});

export const amendmentReasonList = createSelector(selectAmendmentReason, (lookups) => {
    if (lookups) {
        const list = Object.keys(lookups).map((key) => lookups[key]);
        return list.filter((lookup) => !lookup?.isDeleted) as LookupBase[];
    }
    return [];
});

export const amendmentReasonOptionsList = createSelector(
    amendmentReasonList,
    (lookupsList) =>
        orderBy(lookupsList, 'displayName').map((dx) => ({
            key: dx?.id ?? '',
            text: `${dx.displayName.length > 35 ? `${dx.displayName.slice(0, 35)}...` : dx.displayName}`,
            title: `${dx.displayName}`,
        })) as IComboBoxOption[],
);

export default reducer;
