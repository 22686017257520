import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { IUserIdentity } from 'api/models/account.model';
import { ITenantUser } from 'api/models/tenant.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { SettingsState } from '../settings.slice';
import { getUsers, getUserById } from './users.actions';

const usersReducers = {
    setSelectedUser: (state: SettingsState, action: PayloadAction<ITenantUser>): void => {
        state.users.selectedUser = action.payload;
    },
    updateIdentityProperty: (
        state: SettingsState,
        action: PayloadAction<{ path: keyof IUserIdentity; value: string & string[] }>,
    ): void => {
        const { path, value } = action.payload;
        if (state.users.selectedUser && state.users.selectedUser.identity) state.users.selectedUser.identity[path] = value;
    },
    updateUserProperty: (
        state: SettingsState,
        action: PayloadAction<{ path: keyof ITenantUser; value: string & { [key: string]: any } }>,
    ): void => {
        const { path, value } = action.payload;
        if (state.users.selectedUser) (state.users.selectedUser as any)[path] = value;
    },
    cleanupUsersState: (state: SettingsState): void => {
        state.users.accounts = [];
        state.users.data = {};
        state.users.loading = LoadingStatus.Idle;
        state.users.saving = LoadingStatus.Idle;
        state.users.selectedUser = undefined;
    },
};

export default usersReducers;

export const userExtraReducers = (builder: ActionReducerMapBuilder<SettingsState>): ActionReducerMapBuilder<SettingsState> => {
    return (
        builder
            // [GET] Settings Users ==================================
            .addCase(getUsers.pending, (state) => {
                state.users.loading = LoadingStatus.Pending;
                state.users.accounts = [];
                state.users.data = {};
            })
            .addCase(getUsers.fulfilled, (state, action) => {
                state.users.loading = LoadingStatus.Completed;
                state.users.data = action.payload;
            })
            .addCase(getUsers.rejected, (state) => {
                state.users.loading = LoadingStatus.Failed;
            })

            // [GET] Settings User by Id ==================================
            .addCase(getUserById.pending, (state) => {
                state.users.loading = LoadingStatus.Pending;
            })
            .addCase(getUserById.fulfilled, (state, action) => {
                const user = action.payload;
                state.users.data[user.id] = user;
            })
            .addCase(getUserById.rejected, (state) => {
                state.users.loading = LoadingStatus.Failed;
            })
    );
};
