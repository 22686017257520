import { PropsWithChildren, useEffect } from 'react';
import { ClinicalAlertDataItemComponentProps } from '../ClinicalAlertDataItem';
import { Persona, PersonaSize, Stack, Text } from '@fluentui/react';
import { getCreatedByClinicalAlert } from 'pages/PatientOverview/Alerts/AlertsDetailList';
import dateOnly from 'utils/dateOnly';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserIdentitiesData } from 'state/slices/users-identities/user-identities.selectors';
import { RouteParams } from 'interfaces/route-params';
import { useParams } from 'react-router';
import { getMissingUserIdentities } from 'state/slices/users-identities/user-identities.actions';
import DataItemLabel from './DataItemLabel';

export const dataItemTextStyles: React.CSSProperties = { whiteSpace: 'pre-wrap', color: 'black' };
export default function BaseAlertDataItem({
    children,
    data,
    belowContent,
}: PropsWithChildren<ClinicalAlertDataItemComponentProps & { belowContent?: JSX.Element }>) {
    const { tenantId } = useParams<RouteParams>();

    const dispatch = useDispatch();
    const users = useSelector(selectUserIdentitiesData);

    useEffect(() => {
        dispatch(getMissingUserIdentities({ tenantId, userIds: [data.createdBy ?? ''] }));
    }, [tenantId, data.createdBy, dispatch]);

    return (
        <Stack>
            <Stack tokens={{ childrenGap: 10 }} horizontal verticalAlign="center">
                <Stack style={{ width: 180, height: '100%' }}>
                    <Persona
                        size={PersonaSize.size24}
                        text={getCreatedByClinicalAlert(data, users, true)}
                        secondaryText={data.createdOn ? `${dateOnly(data.createdOn)}` : ''}
                        onRenderPrimaryText={(props) => (
                            <Stack>
                                <Text style={dataItemTextStyles} variant="smallPlus">
                                    {props?.text ?? 'Unknown User'}
                                </Text>
                                <Text variant="xSmall" style={dataItemTextStyles}>
                                    {props?.secondaryText}
                                </Text>
                            </Stack>
                        )}
                    />
                </Stack>
                {children}
            </Stack>
            {data.note && (
                <Stack styles={{ root: { overflowWrap: 'break-word', maxWidth: 400 } }}>
                    <Text style={dataItemTextStyles}>
                        <DataItemLabel text="Note:" /> {data.note}
                    </Text>
                </Stack>
            )}
            {belowContent && belowContent}
        </Stack>
    );
}
