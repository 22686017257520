import { PayloadAction } from '@reduxjs/toolkit';
import { ITeachingState, TutorialSteps, TutorialTypes } from './teaching.state';
import appLocalStorage from 'utils/appLocalStorage';

export const teachingReducers = {
    setCurrentTutorial: (
        state: ITeachingState,
        action: PayloadAction<{ userId: string; tutorial: TutorialTypes; step: TutorialSteps }>,
    ): void => {
        const { step, tutorial, userId } = action.payload;
        const hasCompletedTutorial = appLocalStorage.getCompletedTutorialsByUser(userId)?.findIndex((t) => t === tutorial) === -1;

        if (!hasCompletedTutorial) {
            state.currentTutorial = tutorial;
            state.currentStep = step;
        }
    },
    setNextTutorialStep: (state: ITeachingState, action: PayloadAction<TutorialSteps | undefined>): void => {
        if (state.currentTutorial) {
            state.currentStep = action.payload;
            if (!action.payload) {
                state.currentPage = undefined;
            }
        }
    },
    setPageForCurrentStep: (state: ITeachingState, action: PayloadAction<number | undefined>): void => {
        if (state.currentTutorial) {
            state.currentPage = action.payload;
        }
    },
    //action payload is needed for localhost middleware...
    setTutorialComplete: (state: ITeachingState, action: PayloadAction<{ userId: string; tutorial: TutorialTypes }>): void => {
        state.currentStep = undefined;
        state.currentTutorial = undefined;
        appLocalStorage.completedTutorialsByUser = action.payload;
    },
};
