import { IBillingProcedure } from 'api/models/billing-procedure.model';
import { EncounterSummaryWithLineItems, IEncounterSummary, TransferTo } from 'api/models/encounter-ledger.model';
import { PaymentMethod } from 'api/models/patient.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { map } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'state/store';
import { BillingProcedureWithTransactionIds } from './patient-payments-and-adjustments.state';

export const selectPatientPaymentsAndAdjustmentsState = (state: RootState) => state.ledger.patientPaymentsAndAdjustments;

// completed billing procedures
export const selectPatientPaymentsAndAdjustmentsBillingProcedures = createSelector(
    selectPatientPaymentsAndAdjustmentsState,
    ({ paymentsAndAdjustmentsBillingProcedures }) => paymentsAndAdjustmentsBillingProcedures ?? [],
);

export const selectPatientPaymentsEstimateBillingProcedures = createSelector(
    selectPatientPaymentsAndAdjustmentsState,
    ({ estimateBillingProcedures }) => estimateBillingProcedures ?? [],
);

export const selectPatientPaymentsAndAdjustmentsBillingProceduresLoadingStatus = createSelector(
    selectPatientPaymentsAndAdjustmentsState,
    ({ loadingLedgerbillingProcedures: loadingCompletedBillingProcedures }) => loadingCompletedBillingProcedures,
);
export const selectPatientPaymentsAndAdjustmentsBillingProceduresIsLoading = createSelector(
    selectPatientPaymentsAndAdjustmentsBillingProceduresLoadingStatus,
    (loading) => loading === LoadingStatus.Pending,
);
export const selectPatientPaymentInfoSaving = createSelector(
    selectPatientPaymentsAndAdjustmentsState,
    ({ savingPaymentInformation }) => savingPaymentInformation,
);

export const selectPatientAdjustmentInfoSaving = createSelector(
    selectPatientPaymentsAndAdjustmentsState,
    ({ savingAdjustmentInformation }) => savingAdjustmentInformation,
);

export const selectPatientPaymentsAndAdjustmentsInfoSaving = createSelector(
    selectPatientPaymentInfoSaving,
    selectPatientAdjustmentInfoSaving,
    (savingPaymentInfo, savingAdjustmentInfo) =>
        savingPaymentInfo === LoadingStatus.Pending || savingAdjustmentInfo === LoadingStatus.Pending,
);

export const selectPatientPaymentsAndAdjustmentsBillingProceduresError = createSelector(
    selectPatientPaymentsAndAdjustmentsState,
    ({ ledgerBillingProcedureError: completedBillingProcedureError }) => completedBillingProcedureError,
);

export const selectPatientPaymentSource = createSelector(
    selectPatientPaymentsAndAdjustmentsState,
    ({ paymentSource }) => paymentSource,
);

export const selectPatientPaymentTransactions = createSelector(
    selectPatientPaymentsAndAdjustmentsState,
    ({ paymentTransactions }) => paymentTransactions ?? {},
);

export const selectPatientEstimateTransactions = createSelector(
    selectPatientPaymentsAndAdjustmentsState,
    ({ estimateTransactions }) => estimateTransactions ?? {},
);

export const selectPatientPaymentTransactionsAsList = createSelector(selectPatientPaymentTransactions, (transactionsLookup) =>
    map(transactionsLookup, (transactions) => map(transactions)).flat(),
);

export const selectPatientOverpaymentOrOverAdjustment = createSelector(
    selectPatientPaymentsAndAdjustmentsState,
    ({ allowOverpaymentOrAdjustment }) => allowOverpaymentOrAdjustment,
);

export const selectPatientEstimateOverpayment = createSelector(
    selectPatientPaymentsAndAdjustmentsState,
    ({ allowEstimateOverpayment }) => allowEstimateOverpayment,
);

export const selectPatientAdjustmentTransactions = createSelector(
    selectPatientPaymentsAndAdjustmentsState,
    ({ adjustmentTransactions }) => adjustmentTransactions ?? {},
);

export const selectPatientAdjustmentTransactionsAsList = createSelector(
    selectPatientAdjustmentTransactions,
    (transactionsLookup) => map(transactionsLookup, (transactions) => map(transactions)).flat(),
);

export const selectPatientTotalAdjustmentAmount = createSelector(
    selectPatientPaymentsAndAdjustmentsState,
    ({ totalAdjustmentAmount }) => totalAdjustmentAmount ?? 0,
);

export const selectPatientPaymentsAndAdjustmentsValidationErrors = createSelector(
    selectPatientPaymentsAndAdjustmentsState,
    ({ validationErrors }) => validationErrors,
);

export const selectPatientPaymentsOrAdjustmentsModified = createSelector(
    selectPatientPaymentsAndAdjustmentsState,
    ({ adjustmentsModified, paymentsModified }) => adjustmentsModified || paymentsModified,
);
export const selectPatientPaymentsModified = createSelector(
    selectPatientPaymentsAndAdjustmentsState,
    ({ paymentsModified }) => paymentsModified,
);
export const selectPatientEstimatePaymentsModified = createSelector(
    selectPatientPaymentsAndAdjustmentsState,
    ({ estimatePaymentsModified }) => estimatePaymentsModified,
);
export const selectPatientAdjustmentModified = createSelector(
    selectPatientPaymentsAndAdjustmentsState,
    ({ adjustmentsModified }) => adjustmentsModified,
);

export const selectPatientEncounterSummariesWithLineItems = createSelector(
    selectPatientPaymentsAndAdjustmentsState,
    ({ encounterSummariesWithLineItems }) => encounterSummariesWithLineItems ?? [],
);

export const selectLoadingPatientEncounterSummariesWithLineItems = createSelector(
    selectPatientPaymentsAndAdjustmentsState,
    ({ loadingEncounterSummariesWithLineItems }) => loadingEncounterSummariesWithLineItems === LoadingStatus.Pending,
);
export const selectPatientEncounterSummariesWithLineItemsError = createSelector(
    selectPatientPaymentsAndAdjustmentsState,
    ({ encounterSummariesWithLineItemsError }) => encounterSummariesWithLineItemsError,
);

export const selectHidePatientPaymentSourceIdentifier = createSelector(selectPatientPaymentSource, (paymentSource) => {
    return paymentSource?.method === undefined || paymentSource?.method === PaymentMethod.Cash;
});

export const selectPatientPaymentMethodIsCheckOrAuth = createSelector(selectPatientPaymentSource, (paymentSource) => {
    return paymentSource?.method === PaymentMethod.Check ? 'Check' : 'Auth';
});

export const selectPaymentsAndAdjustmentTotalPayment = createSelector(
    selectPatientPaymentsAndAdjustmentsBillingProcedures,
    (procedures) =>
        Number((procedures.length ? procedures.map((bp) => bp.commonPatientFee).reduce((a, b) => a + b, 0) : 0).toFixed(2)),
);

export const selectPaymentsTotalEstimate = createSelector(selectPatientPaymentsEstimateBillingProcedures, (procedures) =>
    Number((procedures.length ? procedures.map((bp) => bp.patientEstimate).reduce((a, b) => a + b, 0) : 0).toFixed(2)),
);

export const selectPatientEstimateTransactionsAsList = createSelector(selectPatientEstimateTransactions, (transactionsLookup) =>
    map(transactionsLookup, (transactions) => map(transactions)).flat(),
);

export const selectPatientEstimateTransactionsTotal = createSelector(
    selectPatientEstimateTransactionsAsList,
    (transactions) => transactions?.map((t) => t.amount)?.reduce((t1, t2) => t1 + t2, 0) ?? 0,
);

export const selectPaymentAndAdjustmentAmounts = createSelector(
    selectPatientPaymentsAndAdjustmentsState,
    selectPatientPaymentTransactionsAsList,
    selectPatientAdjustmentTransactionsAsList,
    ({ paymentSource, totalAdjustmentAmount }, paymentTransactions, adjustmentTransactions) => {
        // Amounts
        const paymentAmount = Number((paymentSource?.amount ?? 0).toFixed(2));
        const adjustmentAmount = Number((totalAdjustmentAmount ?? 0).toFixed(2));

        const paymentTransactionsTotal = paymentTransactions.length
            ? paymentTransactions.map((t) => t.amount).reduce((a, b) => a + b)
            : 0;

        const adjustmentTransactionsTotal = adjustmentTransactions.length
            ? adjustmentTransactions.map((t) => t.amount).reduce((a, b) => a + b)
            : 0;

        const remainingPaymentAmount = Number((paymentAmount - paymentTransactionsTotal).toFixed(2));
        const remainingAdjustmentAmount = Number((adjustmentAmount - adjustmentTransactionsTotal).toFixed(2));

        // Conditionals
        const paymentNotEmpty = paymentAmount > 0;
        const transactionMoreThanPayment = paymentTransactionsTotal > paymentAmount;

        const paymentAdjustmentsValid =
            (paymentAmount ? Number(paymentTransactionsTotal.toFixed(2)) === paymentAmount : true) &&
            (adjustmentAmount ? Number(adjustmentTransactionsTotal.toFixed(2)) === adjustmentAmount : true);

        return {
            paymentAmount,
            adjustmentAmount,

            paymentTransactionsTotal,
            adjustmentTransactionsTotal,

            remainingPaymentAmount,
            remainingAdjustmentAmount,

            paymentNotEmpty,
            transactionMoreThanPayment,
            paymentAdjustmentsValid,
        };
    },
);

function mapEncounterSummaryLineItemWithTransactionInfo(
    encounterSummary: EncounterSummaryWithLineItems,
    billingProcedures: BillingProcedureWithTransactionIds[],
    //This is who is responsible for paying the balance.
    transferTo?: TransferTo,
): EncounterSummaryWithLineItems | undefined {
    let procedureSummaries =
        encounterSummary.procedureSummaries?.map((procedureSummary) => {
            const bp = billingProcedures.find(
                (bp) => bp.id === procedureSummary.id && bp.encounterId === procedureSummary.encounterId,
            );
            return {
                ...procedureSummary,
                patientTransactionId: bp?.paymentTransactionId,
                adjustmentTransactionId: bp?.adjustmentTransactionId,
            };
        }) ?? [];

    if (transferTo && procedureSummaries.length) {
        procedureSummaries = procedureSummaries.filter((p) => p.transferTo === transferTo);
    }

    if (!procedureSummaries.length) return undefined;

    return {
        ...encounterSummary,
        //Need to figure out what procedures summaries are an estimate vs. a balance here
        procedureSummaries,
    };
}

export function mapAllEncounterSummaryLineItemsWithTransactionInfo(
    encounterSummaries: EncounterSummaryWithLineItems[],
    billingProcedures: BillingProcedureWithTransactionIds[],
    //This is who is responsible for paying the balance.
    transferTo?: TransferTo,
) {
    return encounterSummaries
        .map((encounterSummary) =>
            mapEncounterSummaryLineItemWithTransactionInfo(encounterSummary, billingProcedures, transferTo),
        )
        .filter((s) => s !== undefined) as EncounterSummaryWithLineItems[];
}

export const selectPatientEstimateProceduresGroupedByEncounter = createSelector(
    selectPatientPaymentsEstimateBillingProcedures,
    selectPatientEncounterSummariesWithLineItems,
    (billingProcedures, encounterSummaries) => {
        if (billingProcedures.length) {
            return mapAllEncounterSummaryLineItemsWithTransactionInfo(
                encounterSummaries,
                billingProcedures,
                TransferTo.Insurance,
            );
        } else {
            return encounterSummaries;
        }
    },
);

export const selectPatientEstimateProceduresGroupedByEncounterWithEstimate = createSelector(
    selectPatientEstimateProceduresGroupedByEncounter,
    (summaries) => {
        return summaries.filter((s) => s.totalEstimate > 0);
    },
);

//Specifically for the adjustments/payments pages. Not for UAC distribution page.
export const selectPatientPaymentsAndAdjustmentsProceduresGroupedByEncounter = createSelector(
    selectPatientPaymentsAndAdjustmentsBillingProcedures,
    selectPatientEncounterSummariesWithLineItems,
    (billingProcedures, encounterSummaries) => {
        if (billingProcedures.length) {
            return mapAllEncounterSummaryLineItemsWithTransactionInfo(encounterSummaries, billingProcedures);
        } else {
            return encounterSummaries;
        }
    },
);

export const selectPatientPaymentsAndAdjustmentsProceduresGroupedByEncounterWithBalance = createSelector(
    selectPatientPaymentsAndAdjustmentsProceduresGroupedByEncounter,
    (summaries) => {
        return summaries.filter((s) => s.totalCommonPatientFee > 0);
    },
);
