import { DefaultButton, IDropdownOption, Label, PrimaryButton, Stack, Text, TextField } from '@fluentui/react';
import IProvider, { IProviderScheduleTimeBlock, IProviderPeriod } from 'api/models/provider.model';
import { Field, TModal } from 'components';
import useLookupsStore from 'hooks/store/useLookupsStore';
import useValidation, { getValidationError, IValidationConfig, ValidationType } from 'hooks/useValidation';
import { RouteParams } from 'interfaces/route-params';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectProviderAppointments, selectSelectedLOC } from 'state/slices/scheduling/scheduling.selectors';
import { getProviderAppointments } from 'state/slices/scheduling/scheduling.slice';
import { upsertExceptionToPeriod } from 'state/slices/settings/providers/provider.actions';
import {
    selectEditPeriodExceptions,
    selectProvidersEmergencyExpectionModelOpen,
    selectSelectedPeriod,
} from 'state/slices/settings/providers/providers.selectors';
import {
    openEmergencyExceptionModal,
    openProviderModel,
    updateProviderExceptionProp,
} from 'state/slices/settings/settings.slice';
import { classicDateOnly } from 'utils/dateOnly';
import { timeOptions } from 'utils/getTimeOptions';
import { isDateBetween } from 'utils/isDateBetween';
import EmergencyExceptionConfirmationModal from './EmergencyExceptionConfirmation';

type Props = {
    onSave: (periods: IProviderPeriod) => void;
    provider: IProvider | undefined;
};

export default function EmergencyExceptionsModel({ provider, onSave }: Props): JSX.Element | null {
    const selectedLoc = useSelector(selectSelectedLOC);
    const [confimationIsOpen, setIsOpen] = useState<boolean>(false);
    const { setSelectedLookup } = useLookupsStore<IProvider>('Providers');

    const selectedEditPeriod = useSelector(selectSelectedPeriod);
    const editPeriodExceptions = useSelector(selectEditPeriodExceptions);

    const dispatch = useDispatch();

    const [dateError, setDateError] = useState<string>();

    const isOpen = useSelector(selectProvidersEmergencyExpectionModelOpen);

    const onDismiss = () => {
        dispatch(openEmergencyExceptionModal(false));
        cleanupErrors();
        setSelectedLookup(undefined);
    };

    const providerTypeLabel = () => {
        if (provider?.isTreatingProvider === true) return 'Treating Providers';
        if (provider?.isHygienist === true) return 'Hygienists';
        if (provider?.isRegisteredDentalAssistant === true) return 'Registered Dental Assistants';
        else return '';
    };

    function newExceptionPropChange(path: keyof IProviderScheduleTimeBlock, value: unknown) {
        dispatch(updateProviderExceptionProp({ path, value }));
    }

    const onExpectionSave = () => {
        dispatch(upsertExceptionToPeriod());
        setIsOpen(true);
    };

    const addExceptionTypeOptions: IDropdownOption[] = [
        { key: '', text: '' },
        { key: 'sick', text: 'Sick' },
        { key: 'emergency', text: 'Emergency' },
        { key: 'other', text: 'Other' },
    ];
    const validationConfig: IValidationConfig = [
        {
            fieldName: 'Exception Type',
            validation: [ValidationType.Required],
            value: editPeriodExceptions?.exceptionType,
        },
        {
            fieldName: 'Start Date',
            validation: [ValidationType.Required],
            value: editPeriodExceptions?.startDate,
        },
        {
            fieldName: 'Start Time',
            validation: [ValidationType.Required],
            value: editPeriodExceptions?.startTime,
        },
        {
            fieldName: 'End Time',
            validation: [ValidationType.Required],
            value: editPeriodExceptions?.endTime,
        },
        {
            fieldName: 'End Date',
            validation: [ValidationType.Required],
            value: editPeriodExceptions?.endDate,
        },
    ];

    const [errors, onSubmit, cleanupErrors] = useValidation(validationConfig, onExpectionSave);

    useEffect(() => {
        cleanupErrors();
    }, []);

    const hasStartDateError = getValidationError(errors, 'Start Date');
    const hasEndDateError = getValidationError(errors, 'End Date');
    const hasStartTimeError = getValidationError(errors, 'Start Time');
    const hasEndTimeError = getValidationError(errors, 'End Time');
    const hasExceptionTypeError = getValidationError(errors, 'Exception Type');

    const dateValidation =
        editPeriodExceptions?.endDate && editPeriodExceptions?.startDate
            ? editPeriodExceptions.endDate < editPeriodExceptions.startDate
            : false;
    const timeValidation =
        editPeriodExceptions?.endTime &&
        editPeriodExceptions?.startTime &&
        editPeriodExceptions?.endDate === editPeriodExceptions?.startDate
            ? editPeriodExceptions.endTime < editPeriodExceptions.startTime
            : false;

    const isDateValid = dateValidation ? 'End Date must be after Start Date' : '';
    const isTimeValid = timeValidation ? 'End Time must be after Start Time' : '';

    const today = new Date();
    const maxPeriodDate = new Date(selectedEditPeriod?.endDate ?? '');

    return (
        <TModal
            title="Emergency Exceptions"
            modalProps={{
                isOpen: isOpen,
                onDismiss: onDismiss,
                onDismissed: () => {
                    dispatch(openProviderModel(''));
                },
            }}
        >
            <Stack tokens={{ padding: 20, childrenGap: 20 }}>
                <Stack horizontal grow tokens={{ childrenGap: 20 }}>
                    <Stack>
                        <Label>{providerTypeLabel()}</Label>
                        <Text>{`${provider?.firstName} ${provider?.lastName}`}</Text>
                    </Stack>

                    <Stack>
                        <Label>Location(s) of Care:</Label>
                        <Text>{selectedLoc?.displayName}</Text>
                    </Stack>
                </Stack>

                <Stack tokens={{ childrenGap: 10 }}>
                    <Field.Dropdown
                        label="Exception Type"
                        selectedKey={editPeriodExceptions?.exceptionType}
                        options={addExceptionTypeOptions}
                        placeholder="Select an exception type"
                        required
                        onChange={(e, option) => {
                            if (option) newExceptionPropChange('exceptionType', option.key);
                        }}
                        errorMessage={hasExceptionTypeError ? 'Exception Type Required ' : ''}
                    />
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <Field.Date
                            hasDatePicker
                            label="Start Date"
                            autoComplete="off"
                            isReasonable
                            value={editPeriodExceptions?.startDate}
                            minReasonableDate={new Date(classicDateOnly(today.toISOString()))}
                            maxReasonableDate={maxPeriodDate ? maxPeriodDate : new Date()}
                            handleError={(error) => {
                                setDateError(error);
                            }}
                            invalidDateErrorMessage="Invalid Start Date"
                            minReasonableErrorMessage="Start Date must be on or before the start date of the Period"
                            maxReasonableErrorMessage="Start Date cant be on or after the end date of the Period"
                            onChange={(e, date) => {
                                if (date) newExceptionPropChange('startDate', classicDateOnly(date));
                            }}
                            errorMessage={hasStartDateError ? 'Start Date Required ' : dateError}
                        />
                        <Field.Dropdown
                            options={timeOptions}
                            label="Start Time"
                            selectedKey={editPeriodExceptions?.startTime}
                            styles={{ dropdown: { width: 100 } }}
                            onChange={(e, option) => {
                                if (option) newExceptionPropChange('startTime', option.key);
                            }}
                            errorMessage={hasStartTimeError ? 'Start Time Required ' : ''}
                        />
                    </Stack>
                    <Stack horizontal tokens={{ childrenGap: 14 }}>
                        <Stack>
                            <Field.Date
                                value={editPeriodExceptions?.endDate}
                                hasDatePicker
                                label="End Date"
                                autoComplete="off"
                                isReasonable
                                minReasonableDate={new Date(classicDateOnly(today.toISOString()))}
                                maxReasonableDate={maxPeriodDate ? maxPeriodDate : new Date()}
                                handleError={(error) => {
                                    setDateError(error);
                                }}
                                invalidDateErrorMessage="Invalid End Date"
                                minReasonableErrorMessage="End Date must be on or before the start date of the Period"
                                maxReasonableErrorMessage="End Date cant be on or after the end date of the Period"
                                onChange={(e, date) => {
                                    if (date) newExceptionPropChange('endDate', classicDateOnly(date));
                                }}
                                errorMessage={hasEndDateError ? 'End Date Required ' : dateError || isDateValid}
                            />
                        </Stack>
                        <Stack>
                            <Field.Dropdown
                                options={timeOptions}
                                label="End Time"
                                selectedKey={editPeriodExceptions?.endTime}
                                styles={{ dropdown: { width: 100 } }}
                                onChange={(e, option) => {
                                    if (option) newExceptionPropChange('endTime', option.key);
                                }}
                                errorMessage={hasEndTimeError ? 'End Time Required ' : isTimeValid}
                            />
                        </Stack>
                    </Stack>
                </Stack>

                <EmergencyExceptionConfirmationModal
                    isOpens={confimationIsOpen}
                    setIsOpen={setIsOpen}
                    provider={provider}
                    onSave={onSave}
                />

                <Stack tokens={{ childrenGap: 10 }}>
                    <TextField label="Notes" multiline rows={3} />
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <PrimaryButton text="Generate Exception" onClick={onSubmit} />
                    <DefaultButton text="Cancel" title="Cancel" onClick={onDismiss} />
                </Stack>
            </Stack>
        </TModal>
    );
}
