import { DocumentBase } from 'api/document-base.model';
import { DisplayNameSupport, IdSupport } from 'models/contracts';
import { ProcedureActionType } from 'state/slices/charting/chart/chart.slice';
import { DentitionMode } from './chart-dentition.model';
import { ProcedureBillingStatus, ProcedureStage } from './procedure.model';
import { ToothArea } from './tooth-area';

// Chart ========================================
export interface IChart extends DocumentBase {
    dentitionMode?: DentitionMode;
}

// Chart Visit ========================================
export interface IChartVisit extends DocumentBase {
    visitDateTime?: string;
    status?: keyof typeof ChartVisitStatus;
}

export enum ChartVisitStatus {
    Scheduled = 'Scheduled',
    InProgress = 'InProgress',
}

// Chart Condition ========================================
export interface IChartCondition extends DocumentBase {
    conditionId?: string;
    onSetDate?: string;
    toothId: number;
    areas?: (keyof typeof ToothArea)[];
    note?: string;
    status?: ChartConditionStatus;
    encounterId?: string;
}

// Chart Procedure ========================================
export interface IChartProcedure extends DocumentBase {
    procedureId?: string;
    /**@property {string | undefined} encounterId References the encounter that contains the procedure.*/
    encounterId?: string;
    type?: ProcedureActionType;
    status?: keyof typeof ChartProcedureStatus;
    billingStatus?: ProcedureBillingStatus;

    toothIds?: number[];
    areas?: (keyof typeof ToothArea)[];
    diagnosisCodes?: ChartProcedureDiagnosis[];
    treatmentPlannedDate?: string;
    notes?: string;
    /**@property {ChartProcedurePreAuth | undefined} preAuthorization Options: Not Required, Required, Submitted, Approved, Pending Documents, Denied, Resolved */
    preAuthorization?: ChartProcedurePreAuth;
    preAuthorizationCode?: string;
    statusChangedDate?: string;
    treatmentPlanId?: string;
    phaseId?: string;

    hygienistId?: string;
    treatingProviderId?: string;
    billingProviderId?: string;
    supervisingProviderId?: string;

    onSetDate?: string;
    onCompletedDate?: string;

    patientId?: string;
    stage?: ProcedureStage;
}

export type ChartProcedureDiagnosis = IdSupport & DisplayNameSupport & { description?: string };

export enum ChartConditionStatus {
    Active = 'Active',
    Resolved = 'Resolved',
    Removed = 'Removed',
}
export enum ChartProcedureStatus {
    Pending = 'Pending',
    Completed = 'Completed',
    Declined = 'Declined',
    Removed = 'Removed',
    Void = 'Void',
}

export enum ChartProcedurePreAuth {
    NotRequired = 'Not Required',
    Required = 'Required',
    Submitted = 'Submitted',
    Approved = 'Approved',
    Pending = 'Pending',
    Documents = 'Documents',
    Denied = 'Denied',
    Resolved = 'Resolved',
}

export type TenantEntities = 'QuickNoteTemplate';

export type ChartEntities = 'Procedures' | 'Conditions' | 'TreatmentPlans' | 'Visits' | 'Dentitions' | 'PerioExams' | 'Notes';

export default IChart;
