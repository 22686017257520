import { TooltipHost, IconButton, mergeStyles, useTheme } from '@fluentui/react';
import IWorkListAppointmentView from 'api/models/worklist-appointment-view';
import IWorkListEncounterView from 'api/models/worklist-encounter-view';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import { useTenantId } from 'hooks';
import { useDispatch } from 'react-redux';
import { getEncounterPatient } from 'state/slices/admin-huddle/admin-huddle.actions';
import { setVisitSummaryPanelOpen } from 'state/slices/admin-huddle/admin-huddle.slice';
import { setSelectedWorkListEditItem } from 'state/slices/admin-huddle/worklists/worklist.slice';

const visitSummary: ISortableColumn<IWorkListAppointmentView | IWorkListEncounterView> = {
    key: 'visitSummary',
    name: '',
    fieldName: '',
    minWidth: 22,
    maxWidth: 25,
    onRender: VisitSummaryColumn,
};

const iconButtonWrapperStyle = mergeStyles({
    position: 'relative',
});

function VisitSummaryColumn(item?: IWorkListAppointmentView | IWorkListEncounterView): JSX.Element | null {
    const tenantId = useTenantId();
    const dispatch = useDispatch();
    const { palette } = useTheme();

    if (!((item as IWorkListEncounterView)?.encounter?.id ?? item?.encounterId)) return null;

    const onViewVisitSummary = (item: IWorkListAppointmentView | IWorkListEncounterView | undefined) => {
        if (item) {
            const patientId = item.patientId;
            dispatch(setSelectedWorkListEditItem(item));
            if (patientId) dispatch(getEncounterPatient({ tenantId, patientId }));
            dispatch(setVisitSummaryPanelOpen(true));
        }
    };

    function isEncounterView(
        item: IWorkListEncounterView | IWorkListAppointmentView | undefined,
    ): item is IWorkListEncounterView {
        return !!(item as IWorkListEncounterView).encounter;
    }

    const appointmentCountStyle = mergeStyles({
        position: 'absolute',
        top: -10,
        right: -8,
        minHeight: 14,
        minWidth: 14,
        textAlign: 'center',
        verticalAlign: 'center',
        borderRadius: '50%',
        backgroundColor: palette.neutralLighter,
        cursor: 'pointer',
    });

    return (
        <TooltipHost content="Visit Summary" id="view">
            <div className={iconButtonWrapperStyle}>
                {isEncounterView(item) && item.appointmentCount && item.appointmentCount > 1 ? (
                    <span
                        title={`There are ${item.appointmentCount ?? 0} appointments for this encounter date.`}
                        className={appointmentCountStyle}
                    >
                        {item.appointmentCount ?? 0}
                    </span>
                ) : null}
                <IconButton
                    iconProps={{ iconName: 'OpenPane' }}
                    styles={{ root: { height: 20, width: 20 } }}
                    onClick={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        onViewVisitSummary(item);
                    }}
                />
            </div>
        </TooltipHost>
    );
}

export default visitSummary;
