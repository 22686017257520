import { ContextualMenu, DirectionalHint, IconButton, IContextualMenuItem, useTheme } from '@fluentui/react';
import { useRef, useState } from 'react';
import usePermissions, { DentalPermissions } from 'hooks/store/usePermissions';
import useTenantId from 'hooks/useTenantId';
import { useHistory } from 'react-router';
import { useId } from '@uifabric/react-hooks';

function LandingPageSelector(): JSX.Element | null {
    const tenantId = useTenantId();
    const { push } = useHistory();

    const [hasClinicalHuddle] = usePermissions([DentalPermissions.ClinicalHuddle]);
    const [hasAdminHuddle] = usePermissions([DentalPermissions.AdminHuddle]);
    const hasBothLandingPermisions = hasClinicalHuddle && hasAdminHuddle;

    const adminPath = `/${tenantId}/administrative`;
    const clinicalPath = `/${tenantId}/clinical`;

    const urlPath = hasAdminHuddle ? adminPath : clinicalPath;
    const _handleHomeClick = (path: string) => {
        push(path);
    };
    const buttonRef = useRef(null);
    const [showContextualMenu, setShowContextualMenu] = useState(false);
    const compId = useId('landing-page-selector');
    const theme = useTheme();

    if (!tenantId) return null;

    if (hasBothLandingPermisions) {
        const _toggleShowContextMenu = () => setShowContextualMenu(!showContextualMenu);
        const _onHideContextualMenu = () => setShowContextualMenu(false);
        const contextMenu: IContextualMenuItem[] = [
            {
                key: 'clinical',
                text: 'Clinical',
                onClick: () => _handleHomeClick(clinicalPath),
                iconProps: {
                    iconName: 'Health',
                },
            },
            {
                key: 'administrative',
                text: 'Administrative',
                onClick: () => _handleHomeClick(adminPath),
                iconProps: {
                    iconName: 'Admin',
                },
            },
        ];

        return (
            <>
                <IconButton
                    styles={{
                        root: {
                            color: '#FFF',
                        },
                        rootHovered: {
                            color: theme.palette.themePrimary,
                        },
                    }}
                    ref={buttonRef}
                    id={compId}
                    style={{ maxWidth: 32, minWidth: 32 }}
                    onClick={_toggleShowContextMenu}
                    iconProps={{ iconName: 'Home' }}
                    title="Dashboard"
                />

                <ContextualMenu
                    directionalHint={DirectionalHint.bottomRightEdge}
                    isBeakVisible
                    hidden={!showContextualMenu}
                    target={`#${compId}`}
                    onItemClick={_onHideContextualMenu}
                    onDismiss={_onHideContextualMenu}
                    items={contextMenu}
                />
            </>
        );
    }

    return (
        <IconButton
            styles={{
                root: {
                    color: '#FFF',
                },
                rootHovered: {
                    color: theme.palette.themePrimary,
                },
            }}
            style={{ maxWidth: 32, minWidth: 32 }}
            onClick={() => _handleHomeClick(urlPath)}
            iconProps={{ iconName: 'Home' }}
        />
    );
}

export default LandingPageSelector;
