import { Stack } from '@fluentui/react';
import { TModal, TText } from 'components';
import { LoadingStatus } from 'interfaces/loading-statuses';

type Props = {
    title: string;
    confirmationMessage: string;
    isOpen: boolean;
    saving?: LoadingStatus;
    confirmButtonText?: string;
    onContinue: () => void;
    onDismiss: () => void;
};

export default function ConfirmationModal({
    title,
    confirmationMessage,
    isOpen,
    onDismiss,
    onContinue,
    saving,
    confirmButtonText = 'Continue',
}: Props): JSX.Element {
    const isSaving = saving === LoadingStatus.Pending;

    return (
        <TModal
            title={title}
            modalProps={{
                isBlocking: false,
                isOpen,
                onDismiss,
            }}
            isDraggable
            mainButtons={[
                {
                    type: 'PrimaryButton',
                    text: isSaving ? 'Saving...' : confirmButtonText,
                    disabled: isSaving,
                    onClick: onContinue,
                },
                {
                    type: 'DefaultButton',
                    text: 'Cancel',
                    disabled: isSaving,
                    onClick: onDismiss,
                },
            ]}
        >
            <Stack styles={{ root: { padding: 10 } }} grow>
                <TText>{confirmationMessage}</TText>
            </Stack>
        </TModal>
    );
}
