import { IChartCondition } from 'api/models/chart.model';
import { ConflictRuleType } from 'api/models/lookup.model';
import ConditionConflictRulesPipeline from '../conditionConflictRules.pipeline';
import { IPipelineError, PipelineError } from '../pipeline';
import { createRule } from '../ruleGenerator';

const ConflictRuleWithType = createRule<ConditionConflictRulesPipeline, IChartCondition>({
    ruleTypes: [ConflictRuleType.onePerTooth],
    rule: (pipeline, item) => {
        const sameConditions = pipeline.getConditionsWithSameProcedureId(item);

        let isOnTooth = false;
        const errors: IPipelineError[] = [];
        sameConditions.forEach((proc) => {
            if (proc.id !== item.id) {
                const foundOnTooth = proc?.toothId === item.toothId;
                if (foundOnTooth) {
                    isOnTooth = true;
                    errors.push({ type: PipelineError.OnePerTooth, data: pipeline.getCondition(item) });
                }
            }
        });
        return { shouldRemoveItem: isOnTooth, errors };
    },
});

export default ConflictRuleWithType;
