import { Checkbox, ComboBox, DefaultButton, IDropdownOption, Link, PrimaryButton, Stack, Text, TextField } from '@fluentui/react';
import { IPatientPhoneNumber, PhoneNumberType } from 'api/models/patient.model';
import { Field, TModal } from 'components';
import { useSelector, useValidation } from 'hooks';
import { getValidationError, IValidationConfig, PhoneValidationPlan, ValidationType, ValidationTypes } from 'hooks/useValidation';
import { find, forEach, map } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { selectEditPatientPhoneNumbers } from 'state/slices/edit-patient/edit-patient.selectors';
import { setPatientPhoneNumbers } from 'state/slices/edit-patient/edit-patient.slice';

type Props = {
    showPhoneNumberModal: boolean;
    setShowPhoneNumberModal: (value: boolean) => void;
    editingPhoneNumber?: IPatientPhoneNumber;
    setEditingPhoneNumber: (value: IPatientPhoneNumber | undefined) => void;
};

const getNewPhoneNumber = (type: PhoneNumberType): IPatientPhoneNumber => ({
    isDeleted: false,
    number: '',
    hasPermissionToText: false,
    hasPermissionToCall: false,
    hasPermissionToLeaveVoiceMail: false,
    isBadNumber: false,
    isPrimary: false,
    type,
    description: '',
    extension: '',
});

const initialPhoneModalState: Record<PhoneNumberType, IPatientPhoneNumber | undefined> = {
    [PhoneNumberType.Home]: getNewPhoneNumber(PhoneNumberType.Home),
    [PhoneNumberType.Mobile]: getNewPhoneNumber(PhoneNumberType.Mobile),
    [PhoneNumberType.Work]: getNewPhoneNumber(PhoneNumberType.Work),
};

function PhoneNumberModal({
    showPhoneNumberModal,
    setShowPhoneNumberModal,
    editingPhoneNumber,
    setEditingPhoneNumber,
}: Props): JSX.Element {
    const dispatch = useDispatch();

    const phoneNumbers = useSelector(selectEditPatientPhoneNumbers);

    //Edit state for patient phone numbers.
    const [patientPhoneNumbers, _setPatientPhoneNumbers] =
        useState<Record<PhoneNumberType, IPatientPhoneNumber | undefined>>(initialPhoneModalState);

    //Keep track of which phone number is primary.
    const [primaryPhoneType, setPrimaryPhoneType] = useState<PhoneNumberType | ''>('');

    useEffect(() => {
        if (showPhoneNumberModal) {
            if (phoneNumbers.length) {
                const data = { ...patientPhoneNumbers };
                phoneNumbers.forEach((phone) => {
                    data[phone.type] = phone;
                    //Making sure we set the primary phone when the modal opens.
                    if (phone.isPrimary) setPrimaryPhoneType(phone.type);
                });

                //If we  haven't found a primary phone, default to home phone.
                // if (!primaryPhoneType && data.Home) {
                //     setPrimaryPhoneType(PhoneNumberType.Home);
                //     data.Home = { ...data.Home, isPrimary: true };
                // }

                _setPatientPhoneNumbers(data);
            } else {
                //If no phone numbers exist initially, set home as the the primary phone number by default.
                setPatientPhoneNumberProp(PhoneNumberType.Home, 'isPrimary', true);
            }
        }
    }, [showPhoneNumberModal]);

    const setPatientPhoneNumberProp = (type: PhoneNumberType, path: keyof IPatientPhoneNumber, value: unknown) => {
        if (patientPhoneNumbers[type]) {
            const data = {
                ...patientPhoneNumbers,
                [type]: { ...(patientPhoneNumbers[type] as IPatientPhoneNumber), [path]: value },
            };
            _setPatientPhoneNumbers(data);
        }
    };

    const setPhoneNumberPrimary = (type: PhoneNumberType | '') => {
        const data = { ...patientPhoneNumbers };
        forEach(PhoneNumberType, (phoneType) => {
            if (phoneType !== type && data[phoneType]) {
                data[phoneType] = { ...(data[phoneType] as IPatientPhoneNumber), isPrimary: false };
            }
        });
        //Remerge stuff
        if (data[type as PhoneNumberType]) {
            data[type as PhoneNumberType] = { ...(data[type as PhoneNumberType] as IPatientPhoneNumber), isPrimary: true };
            setPrimaryPhoneType(type);
        } else {
            setPrimaryPhoneType(type);
        }
        _setPatientPhoneNumbers(data);
    };

    const isEditing = !!editingPhoneNumber || !!phoneNumbers.length;

    const onDismissed = () => {
        cleanupValidationErrors();
        setEditingPhoneNumber(undefined);
        setPrimaryPhoneType('');
        _setPatientPhoneNumbers(initialPhoneModalState);
    };

    const onDismiss = () => {
        setShowPhoneNumberModal(false);
    };

    const onSave = () => {
        dispatch(
            setPatientPhoneNumbers(
                map(patientPhoneNumbers).filter((phone) => phone !== undefined && !!phone.number) as IPatientPhoneNumber[],
            ),
        );
        onDismiss();
    };

    const getValidationConfig = useCallback(() => {
        const validationConfig: IValidationConfig = [
            {
                fieldName: 'Primary Phone',
                validation: ['required'],
                value: primaryPhoneType,
            },
        ];

        forEach(patientPhoneNumbers, (phone) => {
            if (phone) {
                const validationTypes: ValidationTypes[] = [];
                if (patientPhoneNumbers[phone.type]?.number) validationTypes.push('phone');
                if (phone.type === PhoneNumberType.Home || phone.isPrimary) validationTypes.push('required');
                if (validationTypes.length) {
                    validationConfig.push({
                        fieldName: `${phone.type} Phone`,
                        validation: validationTypes,
                        value: patientPhoneNumbers[phone.type]?.number,
                        itemOptions: { phoneValidationPlan: PhoneValidationPlan.NorthAmericanNumberingPlan },
                    });
                }
            }
        });

        return validationConfig;
    }, [patientPhoneNumbers]);

    const [errors, submit, cleanupValidationErrors] = useValidation(getValidationConfig(), onSave);

    const getPhoneNumberErrorMessageByType = useCallback(
        (type: PhoneNumberType) => {
            const errorMessageLookup: Partial<Record<string, string>> = {
                [ValidationType.Phone]: 'Invalid phone number.',
                [ValidationType.Required]: `${type} Phone required.`,
            };

            const validationErrorType = getValidationError(errors, `${type} Phone`)?.errorTypes[0];

            if (validationErrorType) {
                return errorMessageLookup[validationErrorType];
            }

            return undefined;
        },
        [errors],
    );

    const phoneNumberTypes: IDropdownOption[] = [
        {
            key: '',
            text: '(Select Primary Phone)',
        },
        ...map(PhoneNumberType, (type) => ({
            key: type,
            text: type,
        })),
    ].filter(
        (option) =>
            !option.key || option.key === PhoneNumberType.Home || !!patientPhoneNumbers[option.key as PhoneNumberType]?.number,
    );

    const phoneNumbersToRender = map(
        editingPhoneNumber ? [patientPhoneNumbers[editingPhoneNumber.type]] : patientPhoneNumbers,
        (phone: IPatientPhoneNumber) => {
            const phoneError = getPhoneNumberErrorMessageByType(phone.type);
            return phone ? (
                <Stack key={`${phone.type}`}>
                    <Stack grow horizontal tokens={{ childrenGap: 10 }}>
                        <Field.Phone
                            label={`${phone.type} Phone`}
                            value={phone.number}
                            onChange={(e, value) => setPatientPhoneNumberProp(phone.type, 'number', value)}
                            required={phone.type === PhoneNumberType.Home || phone.isPrimary}
                            errorMessage={phoneError}
                            autoComplete={'off'}
                        />
                        {phone.type === 'Work' ? (
                            <TextField
                                label="EXT"
                                value={phone.extension}
                                onChange={(e, value) => setPatientPhoneNumberProp(phone.type, 'extension', value)}
                                style={{ maxWidth: 40 }}
                            />
                        ) : null}
                        <Stack tokens={{ childrenGap: 10 }} horizontal verticalAlign={phoneError ? 'center' : 'end'}>
                            {phone.number?.length ? (
                                phone.type === 'Home' ? (
                                    <Checkbox
                                        label="Can Leave Message"
                                        checked={phone.hasPermissionToLeaveVoiceMail}
                                        onChange={(e, checked) =>
                                            setPatientPhoneNumberProp(phone.type, 'hasPermissionToLeaveVoiceMail', checked)
                                        }
                                    />
                                ) : (
                                    <>
                                        <Checkbox
                                            label="Can Text"
                                            checked={!!phone.hasPermissionToText}
                                            onChange={(e, checked) =>
                                                setPatientPhoneNumberProp(phone.type, 'hasPermissionToText', checked)
                                            }
                                        />
                                        <Checkbox
                                            label="Can Call"
                                            checked={!!phone.hasPermissionToCall}
                                            onChange={(e, checked) =>
                                                setPatientPhoneNumberProp(phone.type, 'hasPermissionToCall', checked)
                                            }
                                        />
                                    </>
                                )
                            ) : null}
                        </Stack>
                    </Stack>
                    {phone.type === PhoneNumberType.Mobile && (
                        <Text>
                            <Link
                                disabled={
                                    !!getPhoneNumberErrorMessageByType(PhoneNumberType.Home) || !patientPhoneNumbers.Home?.number
                                }
                                onClick={() => {
                                    setPatientPhoneNumberProp(phone.type, 'number', patientPhoneNumbers.Home?.number);
                                }}
                            >
                                Copy from home
                            </Link>
                        </Text>
                    )}
                </Stack>
            ) : null;
        },
    );

    return (
        <TModal
            title={`${!isEditing ? 'Add' : 'Edit'} ${
                !isEditing ? 'Phone Numbers' : editingPhoneNumber?.type ? editingPhoneNumber?.type + ` Number` : 'Phone Numbers'
            }`}
            modalProps={{
                isOpen: showPhoneNumberModal,
                onDismiss: onDismiss,
                onDismissed: onDismissed,
                isBlocking: true,
            }}
            isDraggable
        >
            <Stack tokens={{ childrenGap: 10, padding: 20 }}>
                {phoneNumbersToRender}
                <ComboBox
                    label="Primary Phone"
                    style={{ minWidth: 120 }}
                    selectedKey={primaryPhoneType ?? ''}
                    allowFreeform
                    autoComplete="on"
                    errorMessage={getValidationError(errors, 'Primary Phone') ? 'Primary Phone is required.' : undefined}
                    onChange={(e, option) => {
                        if (option) setPhoneNumberPrimary(option.key as PhoneNumberType);
                    }}
                    options={phoneNumberTypes}
                    required
                />
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <PrimaryButton text={!isEditing ? 'Add' : 'Update'} onClick={submit} />
                    <DefaultButton text="Cancel" onClick={onDismiss} />
                </Stack>
            </Stack>
        </TModal>
    );
}

export default PhoneNumberModal;
