import { map } from 'lodash';
import Pipeline, { IPipelineError } from './pipeline';

export interface IRule<P extends Pipeline<T>, T> {
    ruleTypes: string[];
    rule: RuleFunction<P, T>;
}

type Args<P extends Pipeline<T>, T> = {
    pipeline: P;
    item: T;
    rules: { [key: string]: IRule<P, T> };
    ruleTypes: { [key: string]: string };
};

export type RuleFunctionParams<P extends Pipeline<T>, T> = Parameters<
    (pipeline: P, item: T, options: RulePropOptions<T>) => void
>;

export type RuleFunction<P extends Pipeline<T>, T> = (
    pipeline: P,
    item: T,
) => { shouldRemoveItem?: boolean; errors?: IPipelineError[]; updatedItems?: T | T[] };

export type RulePropOptions<T> = {
    setError: (error: IPipelineError) => void;
    setItem: (item: T) => void;
    setShouldRemoveItem: (remove: boolean) => void;
};

export default abstract class RulesGenerator<P extends Pipeline<T>, T> {
    private _shouldRemoveItem = false;
    private _errors: IPipelineError[] = [];
    protected _item: T | T[];
    protected _rules: { [key: string]: RuleFunction<P, T> } = {};
    protected _ruleTypes: { [key: string]: string | number };

    constructor({ pipeline, item, rules, ruleTypes }: Args<P, T>) {
        this._item = item;
        this._ruleTypes = ruleTypes;
        this._rules = this._getRulesReferences(rules);
        this._runRules(pipeline);
    }

    private _getRulesReferences(_rules: { [key: string]: IRule<P, T> }) {
        const referenceObject: { [key: string]: RuleFunction<P, T> } = {};
        const rules = map(_rules);
        map(this._ruleTypes).forEach((type) => {
            const ruleRef = rules.find((r) => r.ruleTypes.includes(type.toString()));
            // If we find a rule in the list
            if (ruleRef) referenceObject[type as string] = ruleRef.rule;
        });
        return referenceObject;
    }

    protected abstract _runRules(pipeline: P): void;

    protected set setShouldRemoveItem(remove: boolean) {
        this._shouldRemoveItem = remove;
    }

    public get shouldRemoveItem(): boolean {
        return this._shouldRemoveItem;
    }

    protected addErrors(errors: IPipelineError[]): void {
        this._errors = errors;
    }

    public get getErrors(): IPipelineError[] {
        return this._errors;
    }

    public get getItem(): T | T[] {
        return this._item;
    }
}

type CreateRuleArgs<P extends Pipeline<T>, T> = { rule: RuleFunction<P, T>; ruleTypes: string[] };

/**
 *
 *
 * @export
 * @template P - Pipeline type
 * @template T - Item type
 * @param {CreateRuleArgs<P, T>} { ruleType, rule }
 * @return {*}  {IRule<P, T>}
 */
export function createRule<P extends Pipeline<T>, T>({ ruleTypes, rule }: CreateRuleArgs<P, T>): IRule<P, T> {
    return {
        ruleTypes,
        rule,
    };
}
