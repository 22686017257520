import { DefaultButton, Panel, PanelType, Stack } from '@fluentui/react';
import { useSelector, useTenantId } from 'hooks';
import PatientDetailsCard from 'pages/components/PatientDetailsCard';
import { useDispatch } from 'react-redux';
import { selectEncounterPatient, selectVisitSummaryPanelOpen } from 'state/slices/admin-huddle/admin-huddle.selectors';
import {
    cleanupAmendment,
    cleanupVisitSummaryMessageBar,
    setEncounterPatient,
    setVisitSummaryPanelOpen,
} from 'state/slices/admin-huddle/admin-huddle.slice';
import VisitSummaryAmendment from './VisitSummaryAmendment';
import VisitSummaryNotes from './VisitSummaryNotes';
import { selectWorkListEditItem } from 'state/slices/admin-huddle/worklists/worklist.selectors';
import { setSelectedWorkListEditItem } from 'state/slices/admin-huddle/worklists/worklist.slice';
import IWorkListEncounterView from 'api/models/worklist-encounter-view';
import VisitSummaryMessageBar from './VisitSummaryMessageBar';
import VisitSummaryFinancialInformation from './VisitSummaryFinancialInformation';
import { VisitSummaryEncounterInfo } from './VisitSummaryEncounterInfo';
import VisitSummaryAppointment from './VisitSummaryAppointments';

export interface IAmendmentNote {
    providerId: string;
    patientId: string;
    encounterId: string;
    amendReason: string;
    amendNote: string;
    amendReasonId: string;
}

type Props = {
    readOnly?: boolean;
};

//This component is for cases where we want the visit summary panel to be read only no matter the worklist readonly state.
export function ReadOnlyVisitSummaryPanel() {
    return <VisitSummaryPanel readOnly={true} />;
}

export default function VisitSummaryPanel({ readOnly }: Props): JSX.Element | null {
    const tenantId = useTenantId();
    const dispatch = useDispatch();

    const isOpen = useSelector(selectVisitSummaryPanelOpen);
    const patient = useSelector(selectEncounterPatient);
    const selectedView = useSelector(selectWorkListEditItem<IWorkListEncounterView>);

    if (!selectedView) return null;

    const _onDismiss = () => {
        dispatch(setVisitSummaryPanelOpen(false));
    };

    const _onDismissed = () => {
        dispatch(setEncounterPatient(undefined));
        dispatch(setSelectedWorkListEditItem(undefined));
        dispatch(cleanupAmendment());
        dispatch(cleanupVisitSummaryMessageBar());
    };

    const _navigateToPatientChartWithEncounter = () => {
        const selectedViewEncounterId = selectedView.encounter?.id ?? selectedView.encounterId;
        window.open(`/${tenantId}/patient/${patient?.id}/encounter/${selectedViewEncounterId}/charting `);
    };

    return (
        <Panel
            isOpen={isOpen}
            type={PanelType.custom}
            onDismiss={_onDismiss}
            onDismissed={_onDismissed}
            customWidth={'800px'}
            headerText="Visit Summary"
            isLightDismiss={false}
            isFooterAtBottom
            styles={{ scrollableContent: { overflow: 'hidden' }, content: { overflowY: 'auto' } }}
            onRenderFooter={() => (
                <Stack horizontal tokens={{ childrenGap: 10, padding: 10 }}>
                    <DefaultButton text="Close" onClick={_onDismiss} />
                </Stack>
            )}
        >
            <Stack tokens={{ childrenGap: 10 }}>
                <PatientDetailsCard patient={patient} showInsurances={false} showClinicalAlerts />
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <DefaultButton
                        onClick={_navigateToPatientChartWithEncounter}
                        iconProps={{ iconName: 'NavigateExternalInline' }}
                        text="Go to patient chart"
                    />
                </Stack>{' '}
                <VisitSummaryMessageBar />
                <VisitSummaryAppointment />
                <VisitSummaryFinancialInformation />
                <VisitSummaryEncounterInfo readOnly={readOnly} />
                <VisitSummaryAmendment readOnly={readOnly} />
                <VisitSummaryNotes selectedEncounterWorklistView={selectedView} />
            </Stack>
        </Panel>
    );
}
