import { Stack, TextField } from '@fluentui/react';
import { IPatientEligibility, IPatientInsurance } from 'api/models/patient.model';
import { Field } from 'components';
import { getValidationError, IValidationError } from 'hooks/useValidation';

type EligibilityProps = {
    insurance: IPatientInsurance | undefined;
    newEligibilityPropChange: (path: keyof IPatientEligibility, value: unknown) => void;
    validationErrors: IValidationError[];
};

function EligibilityGeneral(props: EligibilityProps): JSX.Element {
    const { insurance, newEligibilityPropChange, validationErrors } = props;

    return (
        <Stack tokens={{ childrenGap: 10 }} grow>
            <Stack.Item grow>
                <Stack tokens={{ childrenGap: 10 }} horizontal grow>
                    <Stack.Item grow>
                        <Field.Dropdown
                            label="Benefit Period"
                            placeholder="(Select Benefit Period)"
                            options={[
                                {
                                    key: 'Calendar Year',
                                    text: 'Calendar Year',
                                },
                                {
                                    key: 'Plan Year',
                                    text: 'Plan Year',
                                },
                            ]}
                            selectedKey={insurance?.eligibility?.benefitPeriod ?? null}
                            onChange={(e, option) => {
                                if (option) newEligibilityPropChange('benefitPeriod', option?.key);
                            }}
                            errorMessage={
                                getValidationError(validationErrors, 'Benefit Period') ? 'Benefit Period is required.' : undefined
                            }
                            required
                        />
                    </Stack.Item>

                    <Stack.Item grow>
                        <Field.Dropdown
                            label="Status"
                            placeholder="(Select Status)"
                            options={[
                                {
                                    key: 'Active',
                                    text: 'Active',
                                },
                                {
                                    key: 'Inactive',
                                    text: 'Inactive',
                                },
                            ]}
                            selectedKey={insurance?.eligibility?.eligibilityStatus ?? null}
                            onChange={(e, option) => {
                                if (option) newEligibilityPropChange('eligibilityStatus', option?.key);
                            }}
                            required
                            errorMessage={getValidationError(validationErrors, 'Status') ? 'Status is required.' : undefined}
                        />
                    </Stack.Item>
                </Stack>
            </Stack.Item>
            <Stack.Item>
                <Stack tokens={{ childrenGap: 10 }} horizontal grow>
                    <Stack.Item grow>
                        <TextField
                            label="Annual Max"
                            value={insurance?.eligibility?.annualMax?.toString() ?? ''}
                            onChange={(ev, value) => newEligibilityPropChange('annualMax', value)}
                            autoComplete="off"
                        />
                    </Stack.Item>
                    <Stack.Item grow>
                        <TextField
                            label="Individual Deductible"
                            value={insurance?.eligibility?.deductible?.toString() ?? ''}
                            onChange={(ev, value) => newEligibilityPropChange('deductible', value)}
                            autoComplete="off"
                        />
                    </Stack.Item>
                    <Stack.Item grow>
                        <TextField
                            label="Family Deductible"
                            value={insurance?.eligibility?.familyDeductible?.toString() ?? ''}
                            onChange={(ev, value) => newEligibilityPropChange('familyDeductible', value)}
                            autoComplete="off"
                        />
                    </Stack.Item>
                    <Stack.Item grow>
                        <TextField
                            label="Deductible Amt Remaining"
                            value={insurance?.eligibility?.deductibleAmountRemaining?.toString() ?? ''}
                            onChange={(ev, value) => newEligibilityPropChange('deductibleAmountRemaining', value)}
                            autoComplete="off"
                        />
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        </Stack>
    );
}

export default EligibilityGeneral;
