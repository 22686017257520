import { Icon, Stack, Text } from "@fluentui/react";
import { format } from "date-fns";
import { LoadingStatus } from "interfaces/loading-statuses";
import { useSelector } from "react-redux";
import { selectWorkListDetailData, selectWorkListDetailDataLoading } from "state/slices/admin-huddle/worklists/worklist.selectors";

export default function DashboardLastUpdated() {
    const detailData = useSelector(selectWorkListDetailData);
    const detailDataLoading = useSelector(selectWorkListDetailDataLoading) === LoadingStatus.Pending;
   
    if(detailDataLoading) return <span>Loading...</span>

    const lastUpdatedDate = detailData?.lastCalculatedDateTime ? format(new Date(detailData.lastCalculatedDateTime), "MM/dd/yyyy") : ""
    const lastUpdatedTime = detailData?.lastCalculatedDateTime ? format(new Date(detailData.lastCalculatedDateTime), "h:mm aa") : ""
    
    return (
        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }}>
            <Icon iconName="Info"/>
            <Text>Calculations last refreshed at {lastUpdatedTime} on {lastUpdatedDate}</Text>
        </Stack>
    )
}
