import { Stack } from '@fluentui/react';
import { IPatientEligibility, IPatientInsurance } from 'api/models/patient.model';
import { IValidationError } from 'hooks/useValidation';
import EligibilityCoinsurance from './EligibilityCoinsurance';
import EligibilityGeneral from './EligibilityGeneral';
import EligibilityLimitations from './EligibilityLimitations';

type EligibilityProps = {
    insurance: IPatientInsurance | undefined;
    newEligibilityPropChange: (path: keyof IPatientEligibility, value: unknown) => void;
    validationErrors: IValidationError[];
};

function EligibilityProperties(props: EligibilityProps): JSX.Element {
    const { insurance, newEligibilityPropChange, validationErrors } = props;
    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <EligibilityGeneral
                insurance={insurance}
                newEligibilityPropChange={newEligibilityPropChange}
                validationErrors={validationErrors}
            />
            <EligibilityCoinsurance
                insurance={insurance}
                newEligibilityPropChange={newEligibilityPropChange}
                validationErrors={validationErrors}
            />
            <EligibilityLimitations
                insurance={insurance}
                newEligibilityPropChange={newEligibilityPropChange}
                validationErrors={validationErrors}
            />
        </Stack>
    );
}

export default EligibilityProperties;
