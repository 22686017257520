import { Stack, Text, TextField, Toggle } from '@fluentui/react';
import IImagingSource, { ImagingSourceType } from 'api/models/imaging-source.model';
import { TModal } from 'components';
import { useTenantId, useValidation } from 'hooks';
import { IValidationConfig, getValidationError } from 'hooks/useValidation';
import { useDispatch, useSelector } from 'react-redux';
import {
    saveUpdateImagingSource,
    imagingSourceActions,
    selectSelectedImagingSource,
    selectImagingSourceAsList,
} from 'state/slices/tenant/imaging-source.slice';

export function ImagingSourceModal(): JSX.Element {
    const dispatch = useDispatch();
    const tenantId = useTenantId();

    const selectedImagingSource = useSelector(selectSelectedImagingSource);
    const imagingSources = useSelector(selectImagingSourceAsList);

    const _dismiss = () => dispatch(imagingSourceActions.setSelectedImagingSource(undefined));

    const _handleSave = () => dispatch(saveUpdateImagingSource(tenantId));

    const isNew = selectedImagingSource?.id === '';
    const modalTitle = isNew ? 'Create Imaging Source' : 'Update Imaging Source';
    const saveText = isNew ? 'Save' : 'Update';

    const type = selectedImagingSource?.displayName
        ? (selectedImagingSource.displayName.split('-')[1] as ImagingSourceType)
        : undefined;

    const _onChange = (path: keyof IImagingSource, value: unknown) => {
        dispatch(imagingSourceActions.editSelectedImagingSource({ path, value }));
    };

    const isDropFolderRequred = type === ImagingSourceType.Remote && !selectedImagingSource?.isDeleted;
    const isAppPathRequired = type === ImagingSourceType.Local && !selectedImagingSource?.isDeleted;

    const validationConfig: IValidationConfig = [];

    if (isDropFolderRequred)
        validationConfig.push({
            fieldName: 'Drop Folder Path',
            validation: ['required'],
            value: selectedImagingSource?.dropFolderPath,
        });

    if (isAppPathRequired)
        validationConfig.push({
            fieldName: 'App Path',
            validation: ['required'],
            value: selectedImagingSource?.appPath,
        });

    const [errors, onSubmit, cleanupErrors] = useValidation(validationConfig, _handleSave);

    const _onDimissed = () => {
        cleanupErrors();
    };

    //imagingSourceActions.editSelectedImagingSource
    return (
        <TModal
            title={modalTitle}
            mainButtons={[
                { text: saveText, onClick: onSubmit, primary: true },
                { text: 'Cancel', onClick: _dismiss },
            ]}
            isDraggable
            modalProps={{ isOpen: selectedImagingSource !== undefined, onDismiss: _dismiss, onDismissed: _onDimissed }}
        >
            <Stack style={{ padding: 10 }}>
                <Stack tokens={{ childrenGap: 10 }} grow verticalAlign="end" horizontal>
                    <TextField label="Name" readOnly value={selectedImagingSource?.displayName}></TextField>
                    <Toggle
                        label={selectedImagingSource?.isDeleted ? 'Deactivated' : 'Activated'}
                        onChange={(e, value) => {
                            _onChange('isDeleted', !value);
                        }}
                        disabled={isNew || imagingSources.length <= 1}
                        styles={{ root: { marginBottom: 0 } }}
                        checked={!selectedImagingSource?.isDeleted}
                        inlineLabel
                    />
                </Stack>
                <TextField
                    label="Base Url"
                    onChange={(e, value) => {
                        _onChange('baseUrl', value);
                    }}
                    value={selectedImagingSource?.baseUrl}
                ></TextField>
                <TextField
                    label="App Path"
                    onChange={(e, value) => {
                        _onChange('appPath', value);
                    }}
                    value={selectedImagingSource?.appPath}
                    required={isAppPathRequired}
                    errorMessage={getValidationError(errors, 'App Path') ? 'App Path Folder is required.' : undefined}
                ></TextField>
                <TextField
                    label="Drop Folder Path"
                    onChange={(e, value) => {
                        _onChange('dropFolderPath', value);
                    }}
                    value={selectedImagingSource?.dropFolderPath}
                    required={isDropFolderRequred}
                    errorMessage={getValidationError(errors, 'Drop Folder Path') ? 'Drop Folder Path is required.' : undefined}
                ></TextField>
                <TextField
                    label="Imaging Folder Path"
                    onChange={(e, value) => {
                        _onChange('imagingFolderPath', value);
                    }}
                    value={selectedImagingSource?.imagingFolderPath}
                ></TextField>
            </Stack>
        </TModal>
    );
}
