import { PayloadAction } from '@reduxjs/toolkit';
import IBlockAppointment from 'api/models/Scheduling/blockAppointment.model';
import IPatientAppointment from 'api/models/Scheduling/patientAppointment.model';
import { format } from 'date-fns';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { IUpcomingTreatmentPlanPhaseData } from '../scheduling.selectors';
import { SchedulingState } from '../scheduling.state';

const scheduleAppointmentReducers = {
    setSchedulePatientAppointment: (
        state: SchedulingState,
        action: PayloadAction<IPatientAppointment | IBlockAppointment>,
    ): void => {
        state.selectedAppointment.data = action.payload;
    },
    cleanupSelectedAppointment: (state: SchedulingState): void => {
        state.selectedAppointment.data = undefined;
        state.selectedAppointment.loading = LoadingStatus.Idle;
        state.selectedAppointment.patient = undefined;
        state.selectedAppointment.encounter = undefined;
        state.ui.isScheduleAppointmentPanelOpen = false;
        state.selectedAppointmentType = undefined;
    },
    setScheduleAppointmentNotes: (state: SchedulingState, action: PayloadAction<string>): void => {
        if (state.selectedAppointment.data) state.selectedAppointment.data.notes = action.payload;
    },
    setScheduleAppointmentOperatoryId: (state: SchedulingState, action: PayloadAction<string>): void => {
        if (state.selectedAppointment.data) state.selectedAppointment.data.operatoryId = action.payload;
    },
    setScheduleAppointmentTime: (
        state: SchedulingState,
        action: PayloadAction<{ type: 'startTime' | 'endTime'; value: string }>,
    ): void => {
        const { type, value } = action.payload;
        if (state.selectedAppointment.data) state.selectedAppointment.data[type] = value;
    },
    setScheduleAppointmentSelectedDate: (state: SchedulingState, action: PayloadAction<Date | null | undefined>): void => {
        const appointment = state.selectedAppointment.data;
        const date = action.payload;
        if (appointment && date) {
            if (state.selectedAppointment.data) {
                state.selectedAppointment.data.date = date ? format(date, 'yyyy-MM-dd') : '';
            }
        }
    },
    setSchedulePatientAppointmentRootProp: (
        state: SchedulingState,
        action: PayloadAction<{
            path: keyof IPatientAppointment;
            value: unknown;
        }>,
    ): void => {
        if (state.selectedAppointment.data)
            ((state.selectedAppointment.data as IPatientAppointment)[action.payload.path] as unknown) = action.payload.value;
    },
    setScheduleBlockAppointmentRootProp: (
        state: SchedulingState,
        action: PayloadAction<{ path: keyof IBlockAppointment; value: string | boolean | string[] | undefined }>,
    ): void => {
        if (state.selectedAppointment.data)
            ((state.selectedAppointment.data as IBlockAppointment)[action.payload.path] as
                | string
                | boolean
                | string[]
                | undefined) = action.payload.value;
    },
    //Handling grouped phase procedures on appt
    setSelectedCurrentAppointmentPhases: (
        state: SchedulingState,
        action: PayloadAction<{ treatmentPlanId: string; phaseData: IUpcomingTreatmentPlanPhaseData[] }>,
    ): void => {
        const { phaseData, treatmentPlanId } = action.payload;
        state.selectedAppointment.selectedPhases[treatmentPlanId] = phaseData;
    },
    cleanupSelectedCurrentAppointmentTreatmentPlan: (state: SchedulingState, action: PayloadAction<string>): void => {
        state.selectedAppointment.selectedPhases[action.payload] = [];
    },
    cleanupSelectedCurrentAppointmentPhases: (state: SchedulingState): void => {
        state.selectedAppointment.selectedPhases = {};
    },
};

export default scheduleAppointmentReducers;
