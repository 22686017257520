import { IChartNoteWithData, IClinicalNoteData } from 'api/models/chart-note.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { SyncStatus } from 'interfaces/syncing-statuses';
import { filter, orderBy, uniqBy } from 'lodash';
import { getEmptyRichTextContent } from 'pages/Charting/components/TextEditor';
import { createSelector } from 'reselect';
import { RootState } from 'state/store';
import dateOnly from 'utils/dateOnly';
import { allPatientEncounters } from '../patient/patient.selectors';
import { IChartNotesState } from './chart-notes.state';

export const selectChartNotesState = (state: RootState): IChartNotesState => state.chartNotes;
export const selectCurrentChartNote = createSelector(selectChartNotesState, (state) => state.currentClinicalNote);

export const selectCurrentChartNoteHasEmptyRichTextContent = createSelector(
    selectCurrentChartNote,
    (currentNote) => getEmptyRichTextContent(currentNote?.data?.value),
);

export const selectChartNoteModified = (state: RootState): boolean | undefined => state.chartNotes.modifiedClincalNote;
export const selectChartNoteSaving = (state: RootState): LoadingStatus => state.chartNotes.saving;

export const selectCurrentClinicalNote = createSelector(selectChartNotesState, (state) => state.currentClinicalNote);
export const selectCurrentClinicalNoteSignatures = createSelector(
    selectCurrentClinicalNote,
    (note) => note?.data.signatures || [],
);

export const clinicalNotesList = createSelector(selectChartNotesState, (state) =>
    state.data?.length
        ? (orderBy(
            state.data.filter((item) => !item.isDeleted),
            (item) => {
                if (item?.encounterDate) return item.encounterDate;
                if (item?.createdOn) return item.createdOn;
            },
            ['desc'],
        ) as IChartNoteWithData<IClinicalNoteData>[])
        : [],
);

export const isCurrentNoteSaved = createSelector(
    clinicalNotesList,
    selectCurrentClinicalNote,
    (notes, currentNote) => notes.findIndex((note) => note.id === currentNote?.id) > -1,
);

export const uniqueClinicalNoteDates = createSelector(clinicalNotesList, (notesList) => {
    const notes = uniqBy(notesList, (note) => {
        return note.createdOn ? dateOnly(note.createdOn) : '';
    });
    const data = notes.map((note) => ({
        createdOn: note.createdOn,
        attestation: note.attestationDate,
    }));

    return data.length > 5 ? data.slice(0, 4) : data;
});

export const filteredClinicalNotesList = createSelector([clinicalNotesList, selectChartNotesState], (list, state) => {
    const { filterToothId, filterCreatedDate } = state;
    const newList = list.map((note) => ({ ...note, createdOn: note.createdOn ? dateOnly(note.createdOn) : '' }));
    let filteredList: IChartNoteWithData<IClinicalNoteData>[] = [...newList];
    if (filterCreatedDate) {
        filteredList = filter(filteredList, { createdOn: dateOnly(filterCreatedDate) });
    }
    if (filterToothId) {
        filteredList = filteredList.filter((item) => {
            if (item.data.toothId) {
                return item.data.toothId === filterToothId;
            } else {
                return item.data.value ? item.data.value?.indexOf(`#${filterToothId.toString()}`) > -1 : false;
            }
        });
    }

    return filteredList;
});

export const selectClinicalNotesWithEncounterDates = createSelector(
    [filteredClinicalNotesList, allPatientEncounters],
    (notes, encounters) =>
        orderBy(
            notes.map((note) => {
                const encounter = encounters.find((e) => e.id === note.encounterId);

                return {
                    ...note,
                    encounterDate: encounter?.encounterDate ?? '',
                };
            }),
            ['encounterDate'],
            ['desc'],
        ),
);

export const selectChartNoteSaveStatus = createSelector(selectChartNoteSaving, (saving): SyncStatus => {
    if (saving === LoadingStatus.Idle || saving === LoadingStatus.Completed) {
        return SyncStatus.Saved;
    } else if (saving === LoadingStatus.Pending) {
        return SyncStatus.Pending;
    } else {
        return SyncStatus.Failed;
    }
});
