import { IBillingProcedure } from 'api/models/billing-procedure.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { isEmpty, map } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'state/store';
import { v4 as uuid } from 'uuid';
import { BillingProcedureWithTransactionId } from './treatment-plan-credit-and-uac.state';
import { IPaymentCommonTransactionView } from 'api/models/payment-history-model';

export const selectTreatmentPlanCreditAndUACState = (state: RootState) => state.ledger.patientTreatmentPlanCreditAndUAC;

export const selectTreatmentPlanCreditAndUACMessageBar = createSelector(
    selectTreatmentPlanCreditAndUACState,
    ({ messageBarMessage, messageBarType }) => ({
        messageBarMessage,
        messageBarType,
    }),
);

export const selectSignedTreatmentPlanView = createSelector(
    selectTreatmentPlanCreditAndUACState,
    ({ signedTreatmentPlanView }) => signedTreatmentPlanView,
);

export const selectSignedTreatmentPlanViewPhasePrepaymentTotal = createSelector(selectSignedTreatmentPlanView, (view) =>
    (view?.phases.map((ph) => ph.prePayments) ?? []).reduce((a, b) => a + b, 0),
);

export const selectSignedTreatmentPlanViewPhasesWithProcedureTransactions = createSelector(
    selectSignedTreatmentPlanView,
    (view) => {
        return (
            view?.phases?.map((ph) => ({
                ...ph,
                billingProcedures: getBillingProceduresWithTransactionId(ph.billingProcedures),
            })) ?? []
        );
    },
);

export const selectSignedTreatmentPlanViewProceduresWithTransactions = createSelector(
    selectSignedTreatmentPlanViewPhasesWithProcedureTransactions,
    (phases) => (phases?.map((ph) => ph.billingProcedures) ?? []).flat(),
);

export const selectSignedTreatmentPlanViewPatientEstimateBalance = createSelector(selectSignedTreatmentPlanView, (view) => {
    return view?.phases?.map((ph) => ph.remainingPatientEstimate).reduce((est1, est2) => est1 + est2) ?? 0;
});

export const selectSignedTreatmentPlanViewGroupedProceduresByPhaseId = createSelector(selectSignedTreatmentPlanView, (view) => {
    const groupedProceduresByPhase: Record<string, TreatmentPlanCreditPhaseViewProcedureGroup[] | undefined> = {};

    if (view) {
        view.phases.forEach((ph) => {
            groupedProceduresByPhase[ph.id] = getGroupedBillingProcedures(ph.billingProcedures);
        });
    }

    return groupedProceduresByPhase;
});

export const selectTreatmentPlanViewLoading = createSelector(
    selectTreatmentPlanCreditAndUACState,
    ({ loadingSignedTreatmentPlanView }) => loadingSignedTreatmentPlanView,
);

export const selectTreatmentViewError = createSelector(
    selectTreatmentPlanCreditAndUACState,
    ({ signedTreatmentPlanViewError: treatmentPlansError }) => treatmentPlansError,
);

export const selectSignedTreatmentPlanViewHasError = createSelector(selectTreatmentViewError, (tpError) => !!tpError);

export const selectTreamentPlanCreditPaymentSource = createSelector(
    selectTreatmentPlanCreditAndUACState,
    ({ paymentSource }) => paymentSource,
);

export const selectTreamentPlanCreditUnappliedPaymentsLoading = createSelector(
    selectTreatmentPlanCreditAndUACState,
    ({ loadingUnappliedPayments }) => loadingUnappliedPayments,
);

export const selectTreamentPlanCreditUnappliedPaymentsError = createSelector(
    selectTreatmentPlanCreditAndUACState,
    ({ unappliedPaymentsError }) => unappliedPaymentsError,
);

export const selectTreamentPlanCreditTransactions = createSelector(
    selectTreatmentPlanCreditAndUACState,
    ({ treatmentPlanCreditTransactions }) => treatmentPlanCreditTransactions ?? {},
);

export const selectTreamentPlanCreditTransactionsAsList = createSelector(
    selectTreatmentPlanCreditAndUACState,
    ({ treatmentPlanCreditTransactions }) => (treatmentPlanCreditTransactions ? map(treatmentPlanCreditTransactions) : []),
);

export const selectTreatmentPlanCreditTransactionsTotal = createSelector(
    selectTreamentPlanCreditTransactionsAsList,
    (transactions) => transactions.map((t) => t.amount).reduce((a, b) => a + b, 0),
);

export const selectTreamentPlanCreditTransactionsExist = createSelector(
    selectTreatmentPlanCreditAndUACState,
    ({ treatmentPlanCreditTransactions }) => treatmentPlanCreditTransactions && !isEmpty(treatmentPlanCreditTransactions),
);

export const selectTreatmentPlanCreditSelectedPhaseView = createSelector(
    selectTreatmentPlanCreditAndUACState,
    ({ selectedPhaseView }) => selectedPhaseView,
);

export const selectTreatmentPlanCreditCurrentPhaseView = createSelector(
    selectTreatmentPlanCreditAndUACState,
    ({ currentPhaseView }) => currentPhaseView,
);

export const selectTreamtentPlanCreditModified = createSelector(
    selectTreatmentPlanCreditAndUACState,
    ({ modified }) => !!modified,
);

export const selectTreatmentPlanCreditAllowOverpayment = createSelector(
    selectTreatmentPlanCreditAndUACState,
    ({ allowOverpayment }) => allowOverpayment,
);

export const selectselectAppliedCreditBalanceTransactionsLoading = createSelector(
    selectTreatmentPlanCreditAndUACState,
    ({ loadingProcedureCreditTransactions }) => {
        return loadingProcedureCreditTransactions;
    },
);

export const selectAppliedCreditBalanceViews = createSelector(
    selectTreatmentPlanCreditAndUACState,
    ({ procedureCreditTransactions }) => {
        return procedureCreditTransactions ?? [];
    },
);

export const selectTreatmentPlanCreditSaving = createSelector(selectTreatmentPlanCreditAndUACState, ({ saving }) => saving);

export function getBillingProceduresWithTransactionId(procedures: IBillingProcedure[]): BillingProcedureWithTransactionId[] {
    return procedures.map((p) => ({
        ...p,
        paymentTransactionId: uuid(),
    }));
}

export const selectTreatmentPlanCreditCurrentPhaseViewBillingProcedures = createSelector(
    selectTreatmentPlanCreditCurrentPhaseView,
    (view) => {
        return getBillingProceduresWithTransactionId(view?.billingProcedures ?? []);
    },
);

export const selectSelectedTreatmentPlanCreditPhaseBalanceRemaining = createSelector(
    selectTreamentPlanCreditPaymentSource,
    selectTreatmentPlanCreditTransactionsTotal,
    (paymentSource, transactionsTotal) => {
        return Number(((paymentSource?.amount ?? 0) - transactionsTotal).toFixed(2));
    },
);

export const selectSignedTreatmentPlanIsLoading = createSelector(
    selectTreatmentPlanViewLoading,
    (treatmentPlanViewLoading) => treatmentPlanViewLoading === LoadingStatus.Pending,
);

export const selectTreatmentPlanCreditUnappliedPaymentsNotOnTP = createSelector(
    selectTreatmentPlanCreditAndUACState,
    selectSignedTreatmentPlanView,
    ({ unnappliedPayments }) => {
        return unnappliedPayments?.payments
            ? unnappliedPayments?.payments?.filter((p) => {
                  return !p.treatmentPlanId;
              })
            : [];
    },
);

export const selectTreatmentPlanCreditReversedUACPaymentSources = createSelector(
    selectTreatmentPlanCreditAndUACState,
    ({ unnappliedPayments }) => {
        const reversedSources: Record<string, boolean> = {};
        if (unnappliedPayments) {
            const { paymentSources } = unnappliedPayments;
            paymentSources?.forEach((source) => {
                if (source.references?.reversedPaymentSourceId) {
                    reversedSources[source.references.reversedPaymentSourceId] = true;
                }
            });
        }

        return reversedSources;
    },
);

export type UACAppliedProcedureCreditView = IPaymentCommonTransactionView & {
    method?: string;
    identifier?: string;
    note?: string;
    paymentDate?: string;
};

export const selectTreamentPlanCreditUnappliedPaymentsViews = createSelector(
    selectTreatmentPlanCreditAndUACState,
    selectTreatmentPlanCreditUnappliedPaymentsNotOnTP,
    selectTreatmentPlanCreditReversedUACPaymentSources,
    ({ unnappliedPayments }, payments, reversedSourcesLookup) => {
        const paymentViews = payments
            .map((p) => {
                const paymentSource = unnappliedPayments?.paymentSources?.find((source) => source.id === p.paymentSourceId);
                if (
                    !paymentSource ||
                    (reversedSourcesLookup[paymentSource.id] && paymentSource.references?.reversedPaymentSourceId)
                )
                    return undefined;

                return {
                    ...p,
                    method: paymentSource?.method,
                    identifier: paymentSource?.methodIdentifier,
                    note: paymentSource?.note,
                    paymentDate: paymentSource.paymentDate,
                };
            })
            .filter((v) => v !== undefined) as UACAppliedProcedureCreditView[];

        return paymentViews;
    },
);

export const selectTreatmentPlanCreditUnappliedPaymentsTotals = createSelector(
    selectTreamentPlanCreditUnappliedPaymentsViews,
    (sourceView) => {
        return sourceView.map((v) => v.amount).reduce((a, b) => a + b, 0);
    },
);

export const selectTreatmentPlanCrediAppliedCreditBalanceTotals = createSelector(selectAppliedCreditBalanceViews, (views) =>
    views.map((v) => v.creditAmount).reduce((a, b) => a + b, 0),
);

export interface TreatmentPlanCreditPhaseViewProcedureGroup {
    procedureDescription?: string;
    procedureCode: string | undefined;
    count: number;
    treatingProviderId: string | undefined;
    hygienistId: string | undefined;
}

export function getGroupedBillingProcedures(procedures: IBillingProcedure[]) {
    const groupedProcedures: Record<string, TreatmentPlanCreditPhaseViewProcedureGroup> = {};

    procedures.forEach((p) => {
        if (p.procedureId) {
            if (!groupedProcedures[p.procedureId]) {
                groupedProcedures[p.procedureId] = {
                    count: 1,
                    procedureCode: p.procedureCode,
                    hygienistId: p.hygienistId,
                    treatingProviderId: p.treatingProviderId,
                    procedureDescription: p.procedureDescription,
                };
            } else {
                groupedProcedures[p.procedureId].count++;
            }
        }
    });

    return map(groupedProcedures);
}
