import { Stack } from '@fluentui/react';
import { ClinicalAlertType, IClinicalAlert } from 'api/models/clinical-alert.model';
import PreMedAlertDataItem from './ClinicalAlertComponents/PreMedAlertDataItem';
import PatientAlertDataItem from './ClinicalAlertComponents/PatientAlertDataItem';

export type ClinicalAlertDataItemComponentProps = {
    data: IClinicalAlert;
};

type Props = {
    alert: IClinicalAlert;
    index: number;
};

const clinicalAlertComponents: Record<
    ClinicalAlertType,
    (props: ClinicalAlertDataItemComponentProps) => JSX.Element | undefined
> = {
    [ClinicalAlertType.PreMedication]: PreMedAlertDataItem,
    [ClinicalAlertType.PatientAlert]: PatientAlertDataItem,
};

export default function ClinicalAlertDataItem({ alert, index }: Props) {
    const Component = alert.type
        ? (clinicalAlertComponents as Record<ClinicalAlertType, (props: ClinicalAlertDataItemComponentProps) => JSX.Element>)[
              alert.type
          ]
        : undefined;

    if (!Component) return null;

    return (
        <Stack
            styles={{
                root: {
                    borderTop: index !== 0 ? `1px solid #9d750d` : undefined,
                    paddingTop: 5,
                },
            }}
        >
            <Component data={alert} />
        </Stack>
    );
}
