import { PayloadAction } from '@reduxjs/toolkit';
import { TimeOfServiceReportQuery } from 'api/models/time-of-service-report-query.model';
import { IReportingState } from '../reporting.state';

const timeOfServiceReducers = {
    setTimeOfServiceProp: (
        state: IReportingState,
        action: PayloadAction<{ path: keyof TimeOfServiceReportQuery; value: string | object | undefined }>,
    ): void => {
        const { path, value } = action.payload;
        ((state.reportProperties as TimeOfServiceReportQuery)[path] as string | object | undefined) = value;
    },
    toggleTimeOfServiceQueryItem: (
        state: IReportingState,
        action: PayloadAction<{ path: keyof Omit<TimeOfServiceReportQuery, 'startDate' | 'endDate'>; value: string | undefined }>,
    ): void => {
        const { path, value } = action.payload;
        if (value) {
            const list = (state.reportProperties as TimeOfServiceReportQuery as TimeOfServiceReportQuery)[path] ?? [];
            const indexOfItem = list.findIndex((item) => item === value);
            if (indexOfItem > -1) {
                (state.reportProperties as TimeOfServiceReportQuery)[path] = [
                    ...list.slice(0, indexOfItem),
                    ...list.slice(indexOfItem + 1),
                ];
            } else {
                (state.reportProperties as TimeOfServiceReportQuery)[path] = [...list, value];
            }
        }
    },
};

export default timeOfServiceReducers;
