import { createAsyncThunk } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import { AppThunk, RootState } from 'state/store';
import { MissingSlipsWorkList, WorkList } from './worklist.state';
import {
    selectCurrentWorkList,
    selectWorkListContinuationTokens,
    selectWorkListCurrentPageNumber,
    selectWorkListData,
    selectWorkListEditItem,
    selectWorkListFilters,
    selectWorkListHasFilters,
    selectWorkListItemsPerPage,
    selectWorkListSaving,
} from './worklist.selectors';
import {
    removeWorkListDataAtIndex,
    addWorkListData,
    setWorkListRecentItems,
    setEncounterWorkListModalOpen,
    setSelectedWorkListEditItem,
    setWorkListMessageBar,
    updateWorkListDataAtIndex,
    setWorkListLayerOpen,
} from './worklist.slice';
import axios, { CancelToken } from 'axios';
import appLocalStorage, { RecentWorkListItem } from 'utils/appLocalStorage';
import { selectAccountData } from 'state/slices/account.slice';
import { MessageBarType } from '@fluentui/react';
import { batch } from 'react-redux';
import { getPatientToEditAndOpenPanel } from 'state/slices/edit-patient/edit-patient.actions';
import { getEncounterPatient } from '../admin-huddle.actions';
import { setVisitSummaryPanelOpen } from '../admin-huddle.slice';
import {
    EditEncounterWorkList,
    EncounterWorkList,
    EncounterWorkListModalType,
} from './encounter-worklists/encounter-worklists.state';
import { getAppointmentData } from 'state/slices/scheduling/scheduling.actions';
import { AppointmentType } from 'state/slices/scheduling/scheduling.state';
import {
    WorkListEncounterUpdateParams,
    updateEncounterWorkListMappings,
    updateWorkListEncounterData,
} from './encounter-worklists/encounter-worklists.actions';
import {
    WorkListAppointmentUpdateParams,
    updateAppointmentWorkListMappings,
    updateWorkListAppointmentData,
} from './appointment-worklists/appointment-worklists.actions';
import { setCancelAppointmentModalOpen } from 'state/slices/scheduling/scheduling.slice';
import { LoadingStatus } from 'interfaces/loading-statuses';
import DashboardBoardTotals from 'api/models/dashboard-totals.model';
import { WorkListPayerSource } from 'api/models/worklist-encounter-view';
import { getDisableCancelAppointment } from 'pages/components/CancelAppointmentModal/CancelAppointmentModal';
import { setEditPredeterminationModalOpen } from './predeterminations/predetermination.slice';

const workListMethodLookup = {
    [MissingSlipsWorkList.AmendRequireEncounters]: 'AmendRequiredEncounters',
    [EncounterWorkList.ReadyToReview]: 'ReadyToReview?isOnHold=false',
    [EncounterWorkList.ReadyToReviewOnHold]: 'ReadyToReview?isOnHold=true',
    [EncounterWorkList.BilledPatient]: 'OpenBilledEncounters/Patient',
    [EncounterWorkList.BilledInsurance]: 'OpenBilledEncounters/Insurance',
    [EncounterWorkList.BilledInsuranceCorrectionNeeded]: 'CorrectionNeededEncounters',
    [EncounterWorkList.BilledPatientCorrectionNeeded]: 'CorrectionNeededEncounters',
    [EncounterWorkList.ReadyToRebill]: 'CorrectionsCompletedEncounters',
    [EncounterWorkList.ReadyToRebillOnHold]: 'ReBillOnHoldEncounters',
    [EncounterWorkList.BilledInsuranceCorrectionAmend]: 'CorrectionAmendEncounters',
    [EncounterWorkList.BilledPatientCorrectionAmend]: 'CorrectionAmendEncounters',
    [EncounterWorkList.Denials]: 'DeniedEncounters',
    //Edit Encounters
    [EditEncounterWorkList.EditEncounters]: 'CorrectionNeededEncounters',
    [EditEncounterWorkList.EditEncountersCorrectionAmend]: 'CorrectionAmendEncounters',
    [EditEncounterWorkList.EditEncountersDenials]: 'DeniedEncounters',

    [MissingSlipsWorkList.OutstandingCheckInAppointments]: 'OutstandingCheckInAppointments',
    [MissingSlipsWorkList.OutstandingCheckoutAppointments]: 'OutstandingCheckoutAppointments',
    [MissingSlipsWorkList.AwaitingAttestation]: 'AwaitingAttestation',

    Predeterminations: 'PredeterminationProcedure',
};

//Since some worklists do not have a count endpoint, we need to explicitly define those endpoints.
const workListCountMethodLookup: Partial<Record<WorkList, string>> = {
    [EncounterWorkList.BilledPatient]: 'OpenBilledEncountersCount/Patient',
    [EncounterWorkList.BilledInsurance]: 'OpenBilledEncountersCount/Insurance',
    [EncounterWorkList.Denials]: 'DeniedEncounterCount',
    [EncounterWorkList.ReadyToReview]: 'ReadyToReviewCount?isOnHold=false',
    [EncounterWorkList.ReadyToReviewOnHold]: 'ReadyToReviewCount?isOnHold=true',
    [EncounterWorkList.ReadyToRebill]: 'CorrectionCompletedEncountersCount',
    [EncounterWorkList.ReadyToRebillOnHold]: 'ReBillOnHoldEncountersCount',
    [EditEncounterWorkList.EditEncountersCorrectionAmend]: 'CorrectionAmendEncountersCount',
    [EncounterWorkList.BilledInsuranceCorrectionAmend]: 'CorrectionAmendEncountersCount',
    [EncounterWorkList.BilledPatientCorrectionAmend]: 'CorrectionAmendEncountersCount',
    [EditEncounterWorkList.EditEncounters]: 'CorrectionNeededEncounterCount',
    [EncounterWorkList.BilledInsuranceCorrectionNeeded]: 'CorrectionNeededEncounterCount',
    [EncounterWorkList.BilledPatientCorrectionNeeded]: 'CorrectionNeededEncounterCount',
    [EditEncounterWorkList.EditEncountersDenials]: 'DeniedEncounterCount',
    [MissingSlipsWorkList.AwaitingAttestation]: 'AwaitingAttestationCount',
    [MissingSlipsWorkList.AmendRequireEncounters]: 'AmendRequiredEncounterCount',
    [MissingSlipsWorkList.OutstandingCheckInAppointments]: 'OutstandingCheckInAppointmentsCount',
    [MissingSlipsWorkList.OutstandingCheckoutAppointments]: 'OutstandingCheckoutAppointmentsCount',
    Predeterminations: 'PredeterminationProcedureCount',
};

const worklistPayerSourceLookup: Partial<Record<WorkList, WorkListPayerSource>> = {
    [EncounterWorkList.BilledInsuranceCorrectionNeeded]: WorkListPayerSource.Insurance,
    [EncounterWorkList.BilledInsuranceCorrectionAmend]: WorkListPayerSource.Insurance,
    [EncounterWorkList.BilledPatientCorrectionNeeded]: WorkListPayerSource.Patient,
    [EncounterWorkList.BilledPatientCorrectionAmend]: WorkListPayerSource.Patient,
};

export type GetWorkListDataByWorkListParams = {
    tenantId: string;
    method: string;
    worklistPayerSource?: WorkListPayerSource;
    pageNumber?: number;
    pageSize?: number;
    cancelToken?: CancelToken;
    continuationToken?: string | null;
    filters?: Record<string, unknown>;
};

/**Gets data for given worklist.*/
export const getWorkListData = createAsyncThunk<
    { pageCount?: number; totalCount?: number; views: unknown[]; continuationToken: string | null },
    {
        tenantId: string;
        workList: WorkList;
    },
    {
        state: RootState;
    }
>('getWorkListData', async ({ tenantId, workList }, { getState, rejectWithValue, signal, dispatch }) => {
    try {
        const filterData = selectWorkListFilters(getState());
        const currentPage = selectWorkListCurrentPageNumber(getState());
        const currentPageForToken = currentPage - 2;
        const itemsPerPage = selectWorkListItemsPerPage(getState());

        const continuationTokens = selectWorkListContinuationTokens(getState());

        const requestedContinuationToken = continuationTokens[currentPageForToken];

        const source = axios.CancelToken.source();
        signal.addEventListener('abort', () => {
            source.cancel();
        });

        const params: GetWorkListDataByWorkListParams = {
            tenantId,
            method: workListMethodLookup[workList],
            worklistPayerSource: worklistPayerSourceLookup[workList],
            pageNumber: currentPage,
            pageSize: itemsPerPage,
            cancelToken: source.token,
            continuationToken: requestedContinuationToken,
            filters: filterData,
        };

        //Get the total items count for view worklists only.
        dispatch(getWorkListTotalItemCount({ ...params, workList }));

        const encounterRes = await dentalApi.getWorkListDataByWorkList(params);

        const paginationHeaders = JSON.parse(encounterRes.headers['x-pagination']);
        const continuationToken = paginationHeaders?.ContinuationToken; //Continuation token for fetching the next page.
        const pageCount = paginationHeaders?.PageCount; //Total page count
        const totalCount = paginationHeaders?.TotalCount; //Total item count (Only returned for non-view worklists)

        return { totalCount, pageCount, views: encounterRes.data, continuationToken };
    } catch (e) {
        return rejectWithValue(e);
    }
});

/**Gets the item count of worklist. (For view based worklists only)*/
export const getWorkListTotalItemCount = createAsyncThunk<
    number | undefined,
    GetWorkListDataByWorkListParams & { workList: WorkList },
    {
        state: RootState;
    }
>('getWorkListTotalItemCount', async (params, { rejectWithValue, signal }) => {
    try {
        const { workList } = params;

        const method = workListCountMethodLookup[workList];

        if (!method) return;

        const source = axios.CancelToken.source();
        signal.addEventListener('abort', () => {
            source.cancel();
        });

        const { data } = await dentalApi.getWorkListDataByWorkList<number>({ ...params, method });

        return data;
    } catch (e) {
        return rejectWithValue(e);
    }
});

/**Get details about worklist. Used for getting financial totals displayed on worklist cards. */
export const getDashboardTotalFees = createAsyncThunk<
    DashboardBoardTotals,
    {
        tenantId: string;
    }
>('getWorkListDetail', async ({ tenantId }, { rejectWithValue }) => {
    try {
        const { data } = await dentalApi.getDashboardTotalFees(tenantId);
        return data;
    } catch (e) {
        return rejectWithValue(e);
    }
});

export type RecentlyViewedWorkListItemResponse<T = any> = { data?: T; isValid: boolean };

/**Handles getting a recently viewed worklist item*/
export const getRecentlyViewedWorkListItem = createAsyncThunk<
    RecentlyViewedWorkListItemResponse,
    { tenantId: string; idFieldName: string; item: RecentWorkListItem },
    { state: RootState }
>('getRecentlyViewedWorkListItem', async ({ item, tenantId, idFieldName }, { getState, rejectWithValue, dispatch }) => {
    try {
        //Get current worklist and check if it exists.
        const workList = selectCurrentWorkList(getState());
        if (workList) {
            //Need to fetch the worklist item to ensure it is still on the current worklist being viewed.
            const { data: encounterViews } = await dentalApi.getWorkListDataByWorkList({
                tenantId,
                method: workListMethodLookup[workList],
                filters: { [idFieldName]: item.id },
            });

            //If we found results, then the item is valid.
            const isValid = encounterViews.length > 0;
            const data = encounterViews[0];

            //If not valid then remove the worklist item from recent items.
            if (!isValid) {
                dispatch(removeCurrentWorkListRecentItems(tenantId, [item.id]));
            }

            return { data, isValid };
        }
        return { isValid: false };
    } catch (e) {
        return rejectWithValue(e);
    }
});

/**
 * Determines which worklist update action to use.
 *
 * @param {(WorkListEncounterUpdateParams & WorkListAppointmentUpdateParams)} params
 * @return {*}  {AppThunk<void>}
 */
export const updateWorkListDataItem =
    (params: WorkListEncounterUpdateParams & WorkListAppointmentUpdateParams): AppThunk<void> =>
        (dispatch, getState) => {
            const workList = selectCurrentWorkList(getState());

            if (workList) {
                //Depending on if the current worklist is appointment or encounter based, fire the correct update endpoint.
                if (updateEncounterWorkListMappings[workList]) dispatch(updateWorkListEncounterData(params));
                if (updateAppointmentWorkListMappings[workList]) dispatch(updateWorkListAppointmentData(params));
            }
        };

/**
 * Grab nested data prop from data and return it.
 *
 * @param {(any | undefined)} data
 * @param {string} path
 * @return {*}
 */
function getNestedPropertyData(data: any | undefined, path: string) {
    if (!data) return;
    const splitPath = path.split('.');
    return splitPath.reduce((p, props) => p[props], data);
}

export type UpsertWorkListItemArgs = {
    tenantId: string;
    /**
     * Period separated path to id for the work list data type.
     */
    dataItemIdPath?: string;
    /**
     * Incoming signalR data item.
     */
    data: any;
    /**
     * Determines if the incoming signalR data item should remove the corresponding item from the work list.
     */
    getShouldRemoveItem?: (workListData: any[], data: any, indexOfData: number) => boolean;
    /**
     * Determines if the incoming signalR data item belongs to the currently selected work list.
     * Soon to be removed. This is for older worklists that work directly with data rather than views.
     */
    getDataBelongsToWorkList?: (data: any, workList: WorkList) => boolean;
    /**
     * Callback to handle events that may happen when an item is removed from the work list.
     */
    onRemoveWorkListItem?: (data: any, editItem: any, savingStatus: LoadingStatus) => void;
};

/**
 * Handles SignalR messages for encounter work lists.
 *
 * @param {string} tenantId
 * @param {IAdminEncounterView} data
 * @return {*}  {AppThunk<void>}
 */
export const upsertWorkListItem =
    ({
        tenantId,
        data,
        dataItemIdPath = 'id',
        onRemoveWorkListItem,
        getShouldRemoveItem,
        getDataBelongsToWorkList,
    }: UpsertWorkListItemArgs): AppThunk<void> =>
        (dispatch, getState) => {
            const state = getState();

            const currentWorkList = selectCurrentWorkList(state);
            //If no work list is selected, do nothing.
            if (!currentWorkList) return;

            const workListData = selectWorkListData(state);

            const workListDataLength = workListData.length;
            const dataId = getNestedPropertyData(data, dataItemIdPath);
            const indexOfWorkListDataItem = workListData.findIndex(
                (workListItemData) => getNestedPropertyData(workListItemData, dataItemIdPath) === dataId,
            );

            //Is the incoming message data included on in our currently loaded data.
            if (indexOfWorkListDataItem > -1) {
                //should remove view is based on if the status of the encounter has been changed.
                const shouldRemoveItem = getShouldRemoveItem
                    ? getShouldRemoveItem(workListData, data, indexOfWorkListDataItem)
                    : undefined;
                console.log((workListData as any)[indexOfWorkListDataItem]?.encount?.status, data.status, data);
                if (shouldRemoveItem) {
                    //If we are about to remove the last item on the page refetch the page data, otherwise remove the view.
                    if (workListDataLength === 1) {
                        dispatch(getWorkListData({ tenantId, workList: currentWorkList }));
                    } else {
                        dispatch(removeWorkListDataAtIndex(indexOfWorkListDataItem));
                    }

                    //If we are removing an item and the on remove worklist item callback exists.
                    if (onRemoveWorkListItem) {
                        const editItem = selectWorkListEditItem(getState());
                        const savingStatus = selectWorkListSaving(getState());
                        onRemoveWorkListItem(data, editItem, savingStatus); //Removes the edit worklist item from the list of worklist data.
                        dispatch(removeCurrentWorkListRecentItems(tenantId, [dataId])); //Removes the recent worklist item from local storage and state.
                    }
                } else {
                    //update the item
                    dispatch(
                        updateWorkListDataAtIndex({
                            data,
                            index: indexOfWorkListDataItem,
                        }),
                    );
                }

                // Need to know if we should actually be adding it to the current work list first...
            } else if (getDataBelongsToWorkList && getDataBelongsToWorkList(data, currentWorkList)) {
                const itemsPerPage = selectWorkListItemsPerPage(state);
                //Determine if the view can be added based on the page size.
                if (workListDataLength + 1 < itemsPerPage) {
                    //look at filters next
                    const hasFilters = selectWorkListHasFilters(state);
                    //If there are no filters, then we can add it.
                    if (!hasFilters) {
                        //add the view.
                        dispatch(addWorkListData(data));
                    }
                }
            }
        };

/**Removes recent work list items from local storage and updates recent item state accordingly.*/
export const removeCurrentWorkListRecentItems =
    (tenantId: string, recentItemIds: string[]): AppThunk<void> =>
        (dispatch, getState) => {
            const workList = selectCurrentWorkList(getState());
            const account = selectAccountData(getState());
            if (workList && account?.identity.id) {
                //The local storage method removeRecentWorkListItems returns the new array of recent items in local storage, so we use that to set the state.
                dispatch(
                    setWorkListRecentItems(
                        appLocalStorage.removeRecentWorkListItems(tenantId, workList, account.identity.id, recentItemIds),
                    ),
                );
            }
        };

/**Fires correct action based for the clicked recent worklist item.*/
export const onViewRecentWorkListItem =
    (tenantId: string, item: RecentlyViewedWorkListItemResponse, key: string): AppThunk<void> =>
        (dispatch) => {
            const { isValid, data } = item;

            if (isValid) {
                if (data) {
                    switch (key) {
                        case 'encounterCorrespondence': {
                            dispatch(
                                setEncounterWorkListModalOpen({
                                    isOpen: true,
                                    type: EncounterWorkListModalType.CorrectionNote,
                                    editEncounter: data,
                                }),
                            );
                            break;
                        }
                        case 'adminHold': {
                            dispatch(
                                setEncounterWorkListModalOpen({
                                    isOpen: true,
                                    type: EncounterWorkListModalType.AdministrativeHold,
                                    editEncounter: data,
                                }),
                            );
                            break;
                        }
                        case 'billNote': {
                            dispatch(
                                setEncounterWorkListModalOpen({
                                    isOpen: true,
                                    type: EncounterWorkListModalType.BillingNote,
                                    editEncounter: data,
                                }),
                            );
                            break;
                        }
                        case 'patientProfile': {
                            batch(() => {
                                if (data.patientId) {
                                    dispatch(getPatientToEditAndOpenPanel({ tenantId, patientId: data.patientId }));
                                    dispatch(setSelectedWorkListEditItem(data));
                                }
                            });
                            break;
                        }
                        case 'visitSummary': {
                            if ((item.data.encounterId ?? item.data.encounter?.id) && item.data.patientId) {
                                batch(() => {
                                    dispatch(setSelectedWorkListEditItem(item.data));
                                    dispatch(getEncounterPatient({ tenantId, patientId: item.data.patientId }));
                                    dispatch(setVisitSummaryPanelOpen(true));
                                });
                            } else {
                                dispatch(
                                    setWorkListMessageBar({
                                        message:
                                            "Unable to open visit summary because the specified worklist item doesn't have an associated encounter.",
                                        messageBarType: MessageBarType.blocked,
                                    }),
                                );
                            }
                            break;
                        }
                        case 'cancelAppointment': {
                            if (!getDisableCancelAppointment(data?.encounterId, data?.trackerStatusId)) {
                                //We need to set the appointment to remove id in the same area of state we use for the cancellation modal.
                                dispatch(setCancelAppointmentModalOpen({ appointmentToRemoveId: data.id }));
                                //This will open the modal in the context of the worklist cancellation modal component.
                                dispatch(setWorkListLayerOpen({ layer: 'CancelAppointmentModal', isOpen: true }));
                                // dispatch(setCancelAppointmentModalOpen({isOpen: false, appointmentToRemoveId: data.id}));
                                dispatch(setSelectedWorkListEditItem(data));
                            } else {
                                dispatch(
                                    setWorkListMessageBar({
                                        message: 'Unable to cancel appointment. This appointment has an encounter associated.',
                                        messageBarType: MessageBarType.blocked,
                                    }),
                                );
                            }

                            break;
                        }
                        case 'goToPatientLedger': {
                            //Open ledger in a new tab if patient id exists on the worklist data item.
                            if (item.data.patientId) window.open(`/${tenantId}/patient/${item.data.patientId}/ledger`);
                            break;
                        }
                        case 'appointmentOverview': {
                            //If the patient id exists on the current worklist data item, then get the appt data.
                            if (item.data.patientId) {
                                dispatch(getAppointmentData(tenantId, item.data.id, AppointmentType.Patient));
                                //set the selected worklist edit item.
                                dispatch(setSelectedWorkListEditItem(item.data));
                            }
                            break;
                        }
                        case 'editPredetermination': {
                            if (item.data) {
                                dispatch(setSelectedWorkListEditItem(item.data));
                                dispatch(setEditPredeterminationModalOpen(true));
                            }
                            break;
                        }
                    }
                }
            } else {
                //Otherwise show a blocked message that the worklist item was moved.
                dispatch(
                    setWorkListMessageBar({
                        message: 'The worklist item you recently viewed has been moved.',
                        messageBarType: MessageBarType.blocked,
                    }),
                );
            }
        };
