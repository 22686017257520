import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import ITenantSubscription, { IProductServiceSubscription } from 'api/models/tenant-subscription.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { forEach, isDate } from 'lodash';
import { RootState } from 'state/store';
import { isDateBetween } from 'utils/isDateBetween';

type TenantSubscriptionsState = {
    loading: LoadingStatus;
    data?: ITenantSubscription[];
};

const initialState: TenantSubscriptionsState = {
    loading: LoadingStatus.Idle,
};

export const getTenantProductIds = createAsyncThunk<
    ITenantSubscription[],
    {
        tenantId: string;
    }
>('getTenantProductIds', async ({ tenantId }) => {
    const { data: subscriptions } = await dentalApi.getSubscriptions(tenantId);

    return subscriptions;
});

const subscriptionsSlice = createSlice({
    name: 'subscriptions',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getTenantProductIds.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(getTenantProductIds.fulfilled, (state, action) => {
                state.data = action.payload;
                state.loading = LoadingStatus.Completed;
            })
            .addCase(getTenantProductIds.rejected, (state) => {
                state.loading = LoadingStatus.Failed;
            });
    },
});

const { reducer } = subscriptionsSlice;

export default reducer;

export const selectTenantSubscriptions = (state: RootState): TenantSubscriptionsState => state.tenant.subscriptions;
export const selectTenantSubscriptionsData = createSelector(selectTenantSubscriptions, (state) => (state.data ? state.data : []));
export const selectActiveTenantDentalSubscriptions = createSelector(selectTenantSubscriptionsData, (data) => {
    return data.filter(
        (sub) =>
            !sub.isDeleted &&
            isDateBetween({ dateToCheck: new Date().toISOString(), start: sub.startDate, end: sub.endDate }) &&
            sub.productId === 'dental',
    );
});

export const selectDentalActiveTenantSubscriptionServices = createSelector(selectActiveTenantDentalSubscriptions, (subs) => {
    const services: IProductServiceSubscription[] = [];

    subs.forEach((sub) => {
        forEach(sub.services, (service) => {
            if (
                !service.isDeleted &&
                isDateBetween({ dateToCheck: new Date().toISOString(), start: service.startDate, end: service.endDate })
            )
                services.push(service);
        });
    });

    return services;
});

export const selectTenantSubscriptionsLoading = createSelector(selectTenantSubscriptions, (state) => state.loading);
