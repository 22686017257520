import { Link, Persona, PersonaSize, Stack, Text, TooltipHost, useTheme } from '@fluentui/react';
import { Banner } from 'components';
import { compareAsc, format, isAfter, isBefore } from 'date-fns';
import { useSelector } from 'hooks';
import { SignalRMessage, useSignalR } from 'hooks/signalr/useSignalr';
import { RouteParams } from 'interfaces/route-params';
import { find } from 'lodash';
import { MouseEvent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router-dom';
import { getPatientDocuments } from 'state/slices/documents/documents.actions';
import { genderIdentitiesList } from 'state/slices/tenant/gender-identities.slice';
import { calculateFullAge, classicDateOnly } from 'utils/dateOnly';
import formatPhoneNumber from 'utils/formatPhoneNumber';
import getFullName, { getChosenName, getFirstLastName } from 'utils/getPatientFullName';
import ClinicalAlertCallout from './ClinicalAlerts/ClinicalAlertCallout';
import EncounterTracker from './EncounterTracker';
import { upsertPatientClinicalAlertAction } from 'state/slices/clinical-alert/clincal-alerts.actions';
import IPatient, { IPatientInsurance } from 'api/models/patient.model';
import { BannerItem } from 'components/Banner/Banner';
import { getPatientToEditAndOpenPanel } from 'state/slices/edit-patient/edit-patient.actions';
import { IThemeCustom } from 'state/slices/ui/ui.slice';
import { ITenantPayer } from 'api/models/payer.model';
import { selectTenantPayersData } from 'state/slices/tenant/tenant-payers.slice';

function PatientDetailsBanner(): JSX.Element {
    const now = new Date();
    const patient = useSelector((state) => state.patient);
    const _dispatch = useDispatch();
    const theme = useTheme() as IThemeCustom;
    const { tenantId, patientId } = useParams<RouteParams>();
    const payersLookup = useSelector(selectTenantPayersData);

    const genderIdentities = useSelector(genderIdentitiesList);
    const matchLedger = useRouteMatch({
        path: ['/:tenantId/patient/:patientId/ledger', '/:tenantId/patient/:patientId/encounter/:encounterId/ledger'],
    });

    const { registerSignalRMessage } = useSignalR();

    useEffect(() => {
        registerSignalRMessage(SignalRMessage.UpdatedClinicalAlert, upsertPatientClinicalAlertAction);
    }, []);

    const openEditPatientPanel = (e?: MouseEvent<any>) => {
        e?.preventDefault();
        if (patientId) {
            _dispatch(getPatientToEditAndOpenPanel({ tenantId, patientId }));
            _dispatch(getPatientDocuments({ tenantId, patientId }));
        }
    };

    const { currentInsurance, isSelfPay, secondaryInsurance, slidingFee, tertiaryInsurance } = getInsuranceTypes(
        patient.selectedPatient,
        payersLookup,
    );

    const assignedSexGenderIdentity = () => {
        if (patient.selectedPatient) {
            return `${patient.selectedPatient.legalSexId} | ${patient.selectedPatient.genderIdentityId
                    ? find(genderIdentities, ['id', patient.selectedPatient.genderIdentityId])?.code
                    : ''
                }`;
        }
    };

    const getGenderIdentity = () => {
        if (patient.selectedPatient) {
            const genderIdentity = find(genderIdentities, ['id', patient.selectedPatient.genderIdentityId]);
            return genderIdentity ? genderIdentity.code : '';
        }
    };

    const fetchNextAppointment = () => {
        const dates: Date[] = [];
        (patient?.currentAndFutureAppointments?.patients || []).forEach((currentPatient) => {
            currentPatient.isDeleted ? null : dates.push(new Date(`${currentPatient.date}T${currentPatient.startTime}`));
        });
        const sortedDate = dates.sort(compareAsc);
        const nextAppointments = sortedDate.filter((currentDate) => {
            return isAfter(currentDate, now);
        });
        return nextAppointments.length ? format(nextAppointments[0], 'M/d/yyyy h:mm aaa') : 'N/A';
    };

    const dateOfBirth = patient.selectedPatient?.dateOfBirth
        ? classicDateOnly(patient.selectedPatient?.dateOfBirth, 'M/d/yyyy')
        : undefined;
    const ageSex = `(
        ${patient.selectedPatient && patient.selectedPatient.dateOfBirth
            ? calculateFullAge(patient.selectedPatient.dateOfBirth)
            : ''
        }
        ${assignedSexGenderIdentity() ? assignedSexGenderIdentity() : 'N/A'} )`;

    const tooltipContent = () => {
        if (patient?.selectedPatient) {
            return (
                <Stack>
                    <Text>
                        Age: <strong>{calculateFullAge(patient?.selectedPatient?.dateOfBirth ?? '')}</strong>
                    </Text>
                    <Text>
                        Legal Sex: <strong>{patient.selectedPatient.legalSexId}</strong>
                    </Text>
                    <Text>
                        Gender Identity: <strong>{getGenderIdentity()}</strong>
                    </Text>
                </Stack>
            );
        }
    };

    const firstLastName = getFirstLastName(patient.selectedPatient);
    const chosenName = getChosenName(patient.selectedPatient);
    const phoneNumber = patient.selectedPatient?.phoneNumbers
        ? formatPhoneNumber(
            patient.selectedPatient.phoneNumbers.find((res) => res.isPrimary)?.number
                ? patient.selectedPatient.phoneNumbers.find((res) => res.isPrimary)?.number
                : patient.selectedPatient.phoneNumbers.find((res) => res.type === 'Home')?.number,
        )
        : undefined;

    return (
        <Banner>
            <Stack horizontal grow>
                <Stack style={{ paddingRight: 10 }} horizontal verticalAlign="center" grow>
                    <Stack style={{ padding: `5px 10px 5px` }} horizontal tokens={{ childrenGap: 20 }} grow={1}>
                        <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign="center">
                            <Persona
                                styles={{
                                    root: {
                                        cursor: 'pointer',
                                    },
                                    primaryText: {
                                        color: '#FFF',
                                    },
                                }}
                                size={PersonaSize.size32}
                                text={getFullName(patient.selectedPatient)}
                                hidePersonaDetails={true}
                            />
                            <Link onClick={openEditPatientPanel} underline style={{ whiteSpace: 'nowrap' }}>
                                <Text
                                    styles={{
                                        root: {
                                            fontWeight: 'bold',
                                            color: 'white',
                                            ':hover': {
                                                color: theme.palette.white,
                                            },
                                        },
                                    }}
                                    variant="large"
                                    block
                                >
                                    {firstLastName}
                                </Text>
                                {chosenName && <Text styles={{ root: { color: 'white' } }}>({chosenName})</Text>}
                            </Link>
                        </Stack>
                        <Stack horizontal tokens={{ childrenGap: '5px 20px' }} verticalAlign="center" wrap>
                            <BannerItem label="Patient Id" value={patient.selectedPatient?.mrn} lightText />
                            <BannerItem label="Date of birth" value={dateOfBirth} lightText />
                            <Stack>
                                <TooltipHost delay={0} content={tooltipContent()}>
                                    <BannerItem label="Age/Sex" value={ageSex} lightText />
                                </TooltipHost>
                            </Stack>
                            <BannerItem label="Pronoun" value={patient.selectedPatient?.pronoun} lightText />
                            <BannerItem label="Phone" value={phoneNumber} lightText />
                            <BannerItem label="Next Appt" value={fetchNextAppointment()} lightText />

                            {currentInsurance && <BannerItem label="Primary" value={currentInsurance} lightText />}
                            {secondaryInsurance && <BannerItem label="Secondary" value={secondaryInsurance} lightText />}
                            {tertiaryInsurance && <BannerItem label="Tertiary" value={tertiaryInsurance} lightText />}
                            {slidingFee && !tertiaryInsurance && <BannerItem label="Pt. Discount" value={slidingFee} lightText />}
                            {isSelfPay && <BannerItem label="Self Pay" value="Self Pay" lightText />}
                        </Stack>
                    </Stack>
                    {patient.selectedPatient && <ClinicalAlertCallout patientId={patient.selectedPatient.id} />}
                </Stack>
                {!matchLedger && (
                    <Stack
                        grow={1}
                        horizontal
                        verticalAlign="center"
                        style={{
                            backgroundColor: theme.palette.neutralLighterAlt,
                            paddingRight: 10,
                            paddingLeft: 10,
                            paddingTop: 5,
                            paddingBottom: 5,
                            borderBottom: `1px solid ${theme.palette.neutralQuaternary}`,
                        }}
                    >
                        <EncounterTracker />
                    </Stack>
                )}
            </Stack>
        </Banner>
    );
}

export default PatientDetailsBanner;

export function getInsuranceTypes(patient?: IPatient, payers?: Record<string, ITenantPayer>) {
    const defaultInsuranceObject = {
        currentInsurance: undefined,
        secondaryInsurance: undefined,
        tertiaryInsurance: undefined,
        slidingFee: undefined,
        isSelfPay: false,
    };

    if (!patient) return defaultInsuranceObject;
    if (!payers) return defaultInsuranceObject;

    const insurances = patient.insurances?.filter(
        (insurance) =>
            !insurance.isDeleted &&
            (insurance.expirationDate ? isBefore(new Date(), new Date(insurance.expirationDate ?? '')) : true),
    );

    const insuranceDisplayNames = insurances?.length
        ? insurances?.map((insurance) => getInsuranceDisplayName(insurance))
        : undefined;

    const currentInsurance = insuranceDisplayNames && insuranceDisplayNames[0] ? insuranceDisplayNames[0] : undefined;
    const secondaryInsurance = insuranceDisplayNames && insuranceDisplayNames[1] ? insuranceDisplayNames[1] : undefined;
    const tertiaryInsurance = insuranceDisplayNames && insuranceDisplayNames[2] ? insuranceDisplayNames[2] : undefined;

    function getInsuranceDisplayName(insurance?: IPatientInsurance) {
        if (!insurance) return 'N/A';

        const payer = insurance?.insuranceId && payers ? payers[insurance.insuranceId] : undefined;

        if (!payer) return 'N/A';
        return payer.name ? payer.name : 'N/A';
    }
    const slidingFee =
        patient.slidingFees?.length &&
            patient.slidingFees?.[0]?.slidingFeeProgram &&
            isBefore(new Date(), new Date(patient.slidingFees?.[0]?.expirationDate ?? ''))
            ? patient?.slidingFees?.[0]?.slidingFeePlan
            : null;

    const isSelfPay = !currentInsurance && !secondaryInsurance && !tertiaryInsurance && !slidingFee;

    return {
        currentInsurance,
        secondaryInsurance,
        tertiaryInsurance,
        slidingFee,
        isSelfPay,
    };
}
