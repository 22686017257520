import { IconButton, useTheme } from '@fluentui/react';
import { useTenantId } from 'hooks';
import usePermissions, { DentalPermissions } from 'hooks/store/usePermissions';
import { useHistory } from 'react-router-dom';

function SchedulingButton(): JSX.Element | null {
    const tenantId = useTenantId();
    const { push } = useHistory();
    const theme = useTheme();
    const reportingPath = `/${tenantId}/reporting`;
    const [hasReporting] = usePermissions([DentalPermissions.Reporting]);
    if (!hasReporting || !tenantId) {
        return null;
    }
    return (
        <IconButton
            styles={{
                root: {
                    color: '#FFF',
                },
                rootHovered: {
                    color: theme.palette.themePrimary,
                },
            }}
            iconProps={{ iconName: 'ReportDocument' }}
            style={{ maxWidth: 32, minWidth: 32 }}
            onClick={() => push(reportingPath)}
            title="Reporting"
        />
    );
}
export default SchedulingButton;
