import {
    ContextualMenu,
    IContextualMenuItem,
    IContextualMenuItemProps,
    MessageBar,
    Spinner,
    SpinnerSize,
    Stack,
} from '@fluentui/react';
import { useId } from '@uifabric/react-hooks';
import { Field } from 'components';
import { useEffect, useState } from 'react';
import { ISearchFieldProps } from './SearchField';

export interface IAsyncSearchComboField extends ISearchFieldProps {
    menuItems?: IContextualMenuItem[];
    staticMenuItems?: IContextualMenuItem[];
    onClear?: () => void;
    loading?: string | boolean;
    noItemsFoundMessage?: string;
}

function TypeaheadSearchField({
    onSearch,
    onChangeSearch,
    onClear,
    loading,
    menuItems,
    staticMenuItems,
    noItemsFoundMessage,
    value,
    ...props
}: IAsyncSearchComboField): JSX.Element {
    const isLoading = loading === 'pending' || (typeof loading === 'boolean' && !!loading);
    const [canOpen, setCanOpen] = useState<boolean>(false);
    const [lastSearched, setLastSearched] = useState<string>('');

    const id = useId();

    useEffect(() => {
        return () => {
            _onClear();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function _onSearch(search: string) {
        if (onSearch) {
            setCanOpen(true);
            onSearch(search ?? '');
            setLastSearched(search);
        }
    }

    function _onChangeSearch(search: string) {
        if (onChangeSearch) {
            setCanOpen(true);
            onChangeSearch(search);
            setLastSearched(search);
        }
    }

    function _onClear() {
        if (onClear) onClear();
        setLastSearched('');
    }

    function onContextMenuDismissed() {
        setCanOpen(false);
    }

    function getFormattedText(text?: string): JSX.Element | null {
        if (text) {
            const trimmedText = text.trim();
            const search = lastSearched.trim().toLowerCase();
            const startIndex = trimmedText.toLowerCase().indexOf(search);
            const endIndex = startIndex + search.length;

            const firstSection = <>{trimmedText.slice(0, startIndex)}</>;
            const middleSeciton = <strong>{trimmedText.slice(startIndex, endIndex)}</strong>;
            const lastSection = <>{trimmedText.slice(endIndex, trimmedText.length)}</>;

            return (
                <span title={trimmedText} style={{ textOverflow: 'ellipsis', display: 'inline-block' }}>
                    {firstSection}
                    {middleSeciton}
                    {lastSection}
                </span>
            );
        } else {
            return null;
        }
    }

    const newMenuItems: IContextualMenuItem[] = menuItems?.length
        ? menuItems.map((item) => ({
              ...item,
              onRenderContent: (props: IContextualMenuItemProps) => getFormattedText(props.item.text),
          }))
        : [];

    const newStaticMenuItems = staticMenuItems
        ? staticMenuItems.map((item) => ({
              ...item,
          }))
        : [];

    return (
        <Stack>
            {canOpen && (
                <ContextualMenu
                    gapSpace={1}
                    onDismiss={onContextMenuDismissed}
                    useTargetWidth
                    target={`#${id}`}
                    items={[...newMenuItems, ...newStaticMenuItems]}
                    shouldFocusOnMount={false}
                />
            )}
            <Field.Search
                {...props}
                id={id}
                onFocus={() => setCanOpen(true)}
                onClick={() => setCanOpen(true)}
                onBlur={onContextMenuDismissed}
                onSearch={_onSearch}
                value={value}
                onChangeSearch={_onChangeSearch}
                onClear={_onClear}
                onRenderDescription={() => {
                    if (!isLoading && !menuItems?.length && lastSearched === value && value.length > 1 && canOpen)
                        return (
                            <MessageBar styles={{ root: { marginTop: 5 } }}>
                                {noItemsFoundMessage ?? 'No matching items have been found.'}
                            </MessageBar>
                        );
                    return null;
                }}
                onRenderSuffix={() => (isLoading ? <Spinner size={SpinnerSize.small} style={{ paddingRight: 2 }} /> : <></>)}
            />
        </Stack>
    );
}

export default TypeaheadSearchField;
