import { Dropdown, IDropdownOption, Stack } from '@fluentui/react';
import { ReportType } from 'api/models/embed-report.model';
import {
    SlidingFeeEncounterDetailsGroupCol,
    SlidingFeeEncounterDetailsQuery,
} from 'api/models/sliding-fee-encounter-details-report.model';
import { Field } from 'components';
import { useSelector, useTenantId } from 'hooks';
import { getValidationError } from 'hooks/useValidation';
import { map } from 'lodash';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import reportActionLookup from 'state/slices/reporting/reportActionLookup';
import { getAllLocationsOfCareForReports } from 'state/slices/reporting/reporting.actions';
import {
    selectLocationsOfCareFromReporting,
    selectReportProperties,
    selectReportingValidationErrors,
} from 'state/slices/reporting/reporting.selectors';
import {
    setSlidingFeeEncounterDetailsProp,
    updateSlidingFeeEncounterDetailQueryProp,
} from 'state/slices/reporting/reporting.slice';
import { selectTreatingProviderOptions } from 'state/slices/tenant/providers.slice';
import { classicDateOnly } from 'utils/dateOnly';
import ReportWrapper from '../ReportWrapper';

export default function SlidingFeeEncounterDetails() {
    const tenantId = useTenantId();
    const dispatch = useDispatch();

    const errors = useSelector(selectReportingValidationErrors);

    const locationsOfCare = useSelector(selectLocationsOfCareFromReporting);
    const _providerOptions = useSelector(selectTreatingProviderOptions);

    const reportProperties = useSelector(selectReportProperties) as SlidingFeeEncounterDetailsQuery | undefined;

    const locationsOfCareOptions: IDropdownOption[] = locationsOfCare.map((loc) => ({
        key: loc.id,
        text: loc.displayName,
    }));

    const slidingFeeEncounterDetailsGroupOptions: IDropdownOption[] = map(SlidingFeeEncounterDetailsGroupCol, (group) => ({
        key: group,
        text: group,
    }));

    useEffect(() => {
        dispatch(getAllLocationsOfCareForReports({ tenantId }));
    }, []);

    const _onViewReport = () => {
        if (tenantId && reportProperties) {
            dispatch(
                reportActionLookup[ReportType.SlidingFeeEncounterDetails]({
                    tenantId,
                    ...reportProperties,
                }),
            );
        }
    };

    return (
        <ReportWrapper
            validationConfig={[
                { fieldName: 'Start Date', validation: ['required'], value: reportProperties?.startDate },
                { fieldName: 'End Date', validation: ['required'], value: reportProperties?.endDate },
            ]}
            onViewReport={_onViewReport}
        >
            <Stack tokens={{ childrenGap: 10 }} grow>
                <Stack grow horizontal wrap tokens={{ childrenGap: 10 }}>
                    <Stack.Item>
                        <Field.Date
                            label="Start Date"
                            required
                            hasDatePicker
                            onChange={(ev, value) => {
                                const newDate = value ? classicDateOnly(value, 'yyyy-MM-dd') : undefined;
                                dispatch(setSlidingFeeEncounterDetailsProp({ path: 'startDate', value: newDate }));
                            }}
                            value={reportProperties?.startDate ? classicDateOnly(reportProperties?.startDate, 'MM/dd/yyyy') : ''}
                            errorMessage={getValidationError(errors, 'Start Date') ? 'Start date is required.' : undefined}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <Field.Date
                            label="End Date"
                            required
                            isReasonable
                            hasDatePicker
                            value={reportProperties?.endDate ? classicDateOnly(reportProperties?.endDate, 'MM/dd/yyyy') : ''}
                            onChange={(ev, value) => {
                                const newDate = value ? classicDateOnly(value, 'yyyy-MM-dd') : undefined;
                                dispatch(setSlidingFeeEncounterDetailsProp({ path: 'endDate', value: newDate }));
                            }}
                            disabled={!reportProperties?.startDate}
                            minReasonableErrorMessage="End date must be after start date."
                            minReasonableDate={
                                reportProperties?.startDate ? new Date(classicDateOnly(reportProperties?.startDate)) : undefined
                            }
                            errorMessage={getValidationError(errors, 'End Date') ? 'End date is required.' : undefined}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <Field.SearchCombo
                            placeholder="(Select Provider)"
                            label="Providers"
                            multiSelect
                            options={_providerOptions}
                            onChange={(ev, option) => {
                                if (option?.key)
                                    dispatch(
                                        updateSlidingFeeEncounterDetailQueryProp({
                                            value: option.key as string,
                                            path: 'treatingProviderIds',
                                            toggle: true,
                                        }),
                                    );
                            }}
                            selectedKey={reportProperties?.treatingProviderIds}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <Field.SearchCombo
                            placeholder="(Select Locations of Care)"
                            label="Locations of Care"
                            multiSelect
                            options={locationsOfCareOptions}
                            onChange={(ev, option) => {
                                if (option?.key)
                                    dispatch(
                                        updateSlidingFeeEncounterDetailQueryProp({
                                            value: option.key as string,
                                            path: 'locationOfCareIds',
                                            toggle: true,
                                        }),
                                    );
                            }}
                            selectedKey={reportProperties?.locationOfCareIds}
                        />
                    </Stack.Item>
                    <Stack.Item grow>
                        <Dropdown
                            label="Group Column 1"
                            placeholder="(Select Group Column)"
                            options={[{ key: '', text: '(Select Group Column)' }, ...slidingFeeEncounterDetailsGroupOptions]}
                            onChange={(ev, option) => {
                                if (option) {
                                    dispatch(
                                        setSlidingFeeEncounterDetailsProp({
                                            path: 'groupCol1',
                                            value: option.key as string,
                                        }),
                                    );
                                }
                            }}
                            selectedKey={reportProperties?.groupCol1}
                        />
                    </Stack.Item>
                    <Stack.Item grow>
                        <Dropdown
                            label="Group Column 2"
                            placeholder="(Select Group Column)"
                            options={[{ key: '', text: '(Select Group Column)' }, ...slidingFeeEncounterDetailsGroupOptions]}
                            onChange={(ev, option) => {
                                if (option) {
                                    dispatch(
                                        setSlidingFeeEncounterDetailsProp({
                                            path: 'groupCol2',
                                            value: option.key as string,
                                        }),
                                    );
                                }
                            }}
                            selectedKey={reportProperties?.groupCol2}
                        />
                    </Stack.Item>
                </Stack>
            </Stack>
        </ReportWrapper>
    );
}
