import { Stack, Text, Label, HoverCardType, HoverCard, Icon, IPlainCardProps, Spinner, SpinnerSize } from '@fluentui/react';
import dentalApi from 'api/dental.api';
import { IAllergy } from 'api/models/patient-allergy.model';
import { IPatientProblem } from 'api/models/patient-problem.model';
import IPatient from 'api/models/patient.model';
import { useEffect, useState } from 'react';
import { useSelector, useTenantId } from 'hooks';
import { LoadingStatuses, LoadingStatus } from 'interfaces/loading-statuses';
import { selectTheme } from 'state/slices/ui/ui.slice';

const getAllergiesAndProblems = async (tenantId: string, patientId: string) => {
    const { data: allergiesData } = await dentalApi.getPatientAllergies(tenantId, patientId);
    const { data: problemsData } = await dentalApi.getPatientProblems(tenantId, patientId);

    return { allergies: allergiesData.allergies, problems: problemsData.problems };
};

type Props = {
    patient?: IPatient;
};

function PatientAlertsCard({ patient }: Props): JSX.Element | null {
    const [problems, setProblems] = useState<IPatientProblem[] | undefined>([]);
    const [allergies, setAllergies] = useState<IAllergy[] | undefined>([]);
    const [loading, setLoading] = useState<LoadingStatuses>(LoadingStatus.Idle);
    const selectedTheme = useSelector(selectTheme);

    const tenantId = useTenantId();

    useEffect(() => {
        if (patient) {
            setLoading(LoadingStatus.Pending);
            getAllergiesAndProblems(tenantId, patient.id)
                .then((data) => {
                    setProblems(data.problems);
                    setAllergies(data.allergies);
                    setLoading(LoadingStatus.Completed);
                })
                .catch(() => {
                    setLoading(LoadingStatus.Failed);
                });
        }
    }, [patient?.id, tenantId]);

    if (loading === LoadingStatus.Pending) return <Spinner size={SpinnerSize.large} />;
    if (!problems?.length && !allergies?.length) return null;

    const onRenderPlainCard = (): JSX.Element => <AllergiesProblemsCallout allergies={allergies} problems={problems} />;
    const plainCardProps: IPlainCardProps = {
        onRenderPlainCard: onRenderPlainCard,
    };
    // Darkmode flag #F7D58B
    const flagColor = selectedTheme === 'dark' ? '#F7D58B' : '#ff9900';
    return (
        <HoverCard instantOpenOnClick type={HoverCardType.plain} plainCardProps={plainCardProps}>
            <Stack style={{ color: flagColor, fontSize: 30, height: '100%' }} verticalAlign="center" horizontalAlign="center">
                <Icon iconName="Flag" style={{ cursor: 'pointer' }} />
            </Stack>
        </HoverCard>
    );
}
type CalloutProps = {
    allergies?: IAllergy[];
    problems?: IPatientProblem[];
};
function AllergiesProblemsCallout({ allergies, problems }: CalloutProps): JSX.Element {
    return (
        <Stack horizontal tokens={{ childrenGap: 6 }} styles={{ root: { paddingTop: 6 } }}>
            {!!allergies?.length && (
                <Stack.Item grow styles={{ root: { padding: 10 } }}>
                    <Stack tokens={{ childrenGap: 12 }}>
                        <Label>Allergies: </Label>
                        {allergies?.map((allergy) => (
                            <Text key={`allergy-${allergy.allergenId}`} styles={{ root: { textTransform: 'capitalize' } }}>
                                {allergy?.allergenName || ''}
                            </Text>
                        ))}
                    </Stack>
                </Stack.Item>
            )}
            {!!problems?.length && (
                <Stack.Item grow styles={{ root: { padding: 10 } }}>
                    <Stack tokens={{ childrenGap: 12 }}>
                        <Label>Existing Problems: </Label>
                        {problems?.map((problem) => (
                            <Text key={`problem-${problem.problemId}`} styles={{ root: { textTransform: 'capitalize' } }}>
                                {problem?.name || ''}
                            </Text>
                        ))}
                    </Stack>
                </Stack.Item>
            )}
        </Stack>
    );
}
export default PatientAlertsCard;
