import { AnyAction, createAsyncThunk, ThunkDispatch } from '@reduxjs/toolkit';
import IBlockAppointment from 'api/models/Scheduling/blockAppointment.model';
import IPatientAppointment from 'api/models/Scheduling/patientAppointment.model';
import { TrackerStatus } from 'pages/Scheduling/components/TrackerStatusDropdown';
import { batch } from 'react-redux';
import { AppThunk, RootState } from 'state/store';
import {
    cleanupSelectedAppointment,
    createBlockAppointment,
    createPatientAppointment,
    fetchPatientCheckoutTasks,
    getCheckoutBillingProcedures,
    getBlockAppointment,
    getChartTreatmentPlans,
    getPatientAndAppointment,
    setAlertDialogMessage,
    setAppointmentType,
    setBlockAllocationsDragged,
    setIsAppointmentPanelOpen,
    updatePatientAllocations,
    setIsCheckinPanelOpen,
    setIsCheckoutPanelOpen,
    setPatientAllocationsDragged,
    setPatientOverviewOpen,
    setSchedulePatientAppointment,
    updateBlockAllocation,
    updateBlockAppointment,
    updatePatientAppointment,
    setCheckoutError,
    setSelectedDate,
    setPreviousAppointmentData,
    getPatientAppointmentEncounter,
    setSelectedCurrentAppointmentPhases,
    setUpdatePatientAppointment,
} from './scheduling.slice';

import { CalendarApi, DateSelectArg, EventApi } from '@fullcalendar/react';
import { addHours, format, isAfter, isWithinInterval } from 'date-fns';
import IPatient from 'api/models/patient.model';
import { v4 as uuid } from 'uuid';
import convertDateToTimeString from 'utils/convertDateTimeToString';
import { getChartProcedures } from '../charting/procedures/procedures.actions';
import IOperatoryCalendarLayout from 'api/models/Scheduling/layout.model';
import { cloneDeep, some } from 'lodash';
import { convertStringTimeToDate } from 'utils/convertStringTimeToDate';
import { cleanupEditPatient } from '../edit-patient/edit-patient.slice';
import { AppointmentType } from './scheduling.state';
import { getEditPatient } from '../edit-patient/edit-patient.actions';
import {
    getSelectedUpcomingPhaseData,
    IPatientAppointmentView,
    selectLatestSignedTreatmentPlanView,
    selectPreviousAppointmentData,
} from './scheduling.selectors';
import { selectEditPatient } from '../edit-patient/edit-patient.selectors';
import { IOperatoryRange } from 'api/models/Scheduling/operatory.model';
import { selectCurrentAppointmentSelectedProcedures } from './schedule-appointment/schedule-appointment.selectors';
import { selectOperatoriesAsList } from '../lookups/operatories/operatories.selectors';
import dentalApi from 'api/dental.api';
import { IAppointmentCheckoutModel } from 'api/models/appointment-checkout.model';

export const getAppointmentData =
    (tenantId: string, appointmentId: string, apptType?: AppointmentType): AppThunk<void> =>
    async (dispatch) => {
        if (apptType && apptType === AppointmentType.Patient) {
            batch(() => {
                dispatch(setPatientOverviewOpen(true));
                dispatch(cleanupSelectedAppointment());

                dispatch(setAppointmentType(AppointmentType.Patient));
            });
            const { appointment } = await dispatch(getPatientAndAppointment({ tenantId, appointmentId })).unwrap();

            if (appointment?.patientId) {
                dispatch(getChartProcedures({ tenantId, patientId: appointment.patientId }));
                dispatch(getChartTreatmentPlans({ tenantId, patientId: appointment.patientId }));
                if (appointment.encounterId) {
                    dispatch(
                        getPatientAppointmentEncounter({
                            tenantId,
                            patientId: appointment.patientId,
                            encounterId: appointment.encounterId,
                        }),
                    );
                }
                dispatch(getAndSetPatientAppointmentProcedures(appointment));
            }
        } else {
            dispatch(setPatientOverviewOpen(false));
            dispatch(setAppointmentType(AppointmentType.Block));
            dispatch(getBlockAppointment({ tenantId, appointmentId }));
            dispatch(setIsAppointmentPanelOpen(true));
        }
    };

export const getAndSetPatientAppointmentProcedures =
    (appointment: IPatientAppointment): AppThunk<void> =>
    (dispatch, getState) => {
        const latestTPView = selectLatestSignedTreatmentPlanView(getState());
        //Grab the selected phases & procedures from latest TP view...
        const selectedPhases = getSelectedUpcomingPhaseData(latestTPView, appointment.procedures ?? []);
        if (latestTPView)
            dispatch(
                setSelectedCurrentAppointmentPhases({
                    treatmentPlanId: latestTPView.treatmentPlanId,
                    phaseData: selectedPhases,
                }),
            );
    };

export const updateSelectedCalendarEvent =
    (tenantId: string, event: EventApi): AppThunk<void> =>
    (dispatch, getState) => {
        const appointment = getState().scheduling.selectedAppointment.data;

        const apptType = event.extendedProps.type;
        const newStartTime = event.start?.toTimeString().substring(0, 5) ?? '';
        const newEndTime = event.end?.toTimeString().substring(0, 5) ?? '';
        const newOperatoryId = event._def.resourceIds ? event._def.resourceIds[0] : '';

        const apptToUpdate =
            apptType === AppointmentType.Block ? (appointment as IBlockAppointment) : (appointment as IPatientAppointment);

        if (apptToUpdate) {
            const updateAppt: IBlockAppointment | IPatientAppointment = {
                ...apptToUpdate,
                operatoryId: newOperatoryId,
                startTime: newStartTime,
                endTime: newEndTime,
            };
            if (apptType === AppointmentType.Block) {
                dispatch(updateBlockAppointment({ tenantId, appointment: updateAppt as IBlockAppointment }));
            } else {
                dispatch(
                    updatePatientAppointment({
                        tenantId,
                        appointment: updateAppt as IPatientAppointment,
                    }),
                );
            }
        }
    };

export const updateOrCreateAppointment =
    (tenantId: string): AppThunk<void> =>
    async (dispatch, getState) => {
        const appointment = getState().scheduling.selectedAppointment.data;
        const existingPatientAllocations =
            getState().scheduling.allocations.data?.patients?.filter((allocation) => !allocation.isDeleted) ?? [];
        const existingBlocks = getState().scheduling.allocations.data?.blocks?.filter((block) => !block.isDeleted) ?? [];
        const type = getState().scheduling.selectedAppointmentType;
        const locationOfCare = getState().scheduling.selectedLOC?.id;
        const procedures = selectCurrentAppointmentSelectedProcedures(getState());

        if (appointment) {
            if (type === AppointmentType.Block) {
                if (existingBlocks.findIndex((block) => block.id === appointment.id) != -1) {
                    await dispatch(updateBlockAppointment({ tenantId, appointment: appointment as IBlockAppointment }));
                } else {
                    await dispatch(
                        createBlockAppointment({
                            tenantId,
                            appointment: { ...appointment, locationOfCareId: locationOfCare } as IBlockAppointment,
                        }),
                    );
                }
            } else {
                if (existingPatientAllocations.findIndex((allocation) => allocation.id === appointment.id)) {
                    await dispatch(
                        createPatientAppointment({
                            tenantId,
                            appointment: { ...(appointment as IPatientAppointment), procedures },
                        }),
                    );
                } else {
                    await dispatch(
                        updatePatientAppointment({
                            tenantId,
                            appointment: { ...(appointment as IPatientAppointment), procedures },
                        }),
                    );
                }
            }

            const previousAppointmentData = selectPreviousAppointmentData(getState());
            if (previousAppointmentData) dispatch(returnToCheckout({ tenantId }));
        }
    };

function createPatientAppointmentData(
    startTime: Date,
    endTime: Date,
    operatoryId: string,
    patient?: IPatient,
    locationOfCareId?: string,
): IPatientAppointment | IBlockAppointment {
    if (patient && locationOfCareId) {
        const patientAppointment: Omit<IPatientAppointment, 'treatingProviderId'> = {
            id: uuid(),
            operatoryId,
            isDeleted: false,
            startTime: convertDateToTimeString(startTime),
            endTime: convertDateToTimeString(endTime),
            //This date format is relied upon to get proper time format. More you know...
            date: format(startTime, 'yyyy-MM-dd'),
            patientId: patient.id,
            locationOfCareId,
        };
        return patientAppointment;
    } else {
        const blockAppointment: IBlockAppointment = {
            id: uuid(),
            operatoryId,
            locationOfCareId,
            typeId: '',
            providers: [],
            notes: '',
            startTime: convertDateToTimeString(startTime),
            endTime: convertDateToTimeString(endTime),
            date: format(startTime, 'yyyy-MM-dd'),
            isDeleted: false,
        };

        return blockAppointment;
    }
}

export const isSelectionWithinBusinessHours = (
    selectedDate: Date,
    layouts: IOperatoryCalendarLayout[],
    startTime: Date,
    endTime: Date,
    operatoryId?: string,
): boolean => {
    const currentOperatory = layouts?.find((l) => l.operatoryId === operatoryId);
    const currentOperatoryRanges = currentOperatory?.ranges ?? [];

    const ranges: IOperatoryRange[] = [];
    //Build an adjusted list of ranges based whether a time range end time matches exactly another time range start time.
    currentOperatoryRanges.forEach((range) => {
        const newRange = cloneDeep(range);
        const indexOfMatchingEndTimeRange = ranges.findIndex((r) => r.endTime === range.startTime);
        //If we find a time range that has a matching end time range to this range's start time.
        if (indexOfMatchingEndTimeRange > -1) {
            //Adjust the currently existing range's end time to match the current one.
            ranges[indexOfMatchingEndTimeRange].endTime = newRange.endTime;
        } else {
            ranges.push(newRange);
        }
    });

    return some(ranges, (range) => {
        if (range.startTime && range.endTime) {
            const opStartDate = convertStringTimeToDate(selectedDate, range.startTime);
            const opEndDate = convertStringTimeToDate(selectedDate, range.endTime);

            return (
                isWithinInterval(startTime, { start: opStartDate, end: opEndDate }) &&
                isWithinInterval(endTime, { start: opStartDate, end: opEndDate }) &&
                isAfter(opEndDate, opStartDate)
            );
        } else {
            return false;
        }
    });
};

export const selectTimeSlot =
    (calendarApi: CalendarApi | undefined, selection: DateSelectArg): AppThunk<void> =>
    (dispatch, getState) => {
        const selectedDate = getState().scheduling.selectedDate;
        const layouts = getState().scheduling.layouts.data ?? [];

        const locationOfCareId = getState().scheduling.selectedLOC?.id;
        const patient = getState().patient.selectedPatient;

        if (selectedDate) {
            const isValidSelection = isSelectionWithinBusinessHours(
                selectedDate,
                layouts,
                selection.start,
                selection.end,
                selection.resource?.id,
            );
            const allDay = selection.allDay;
            const allDayStartTime = addHours(new Date(selection.start), 8);
            const allDayEndTime = addHours(new Date(selection.start), 19);
            if (isValidSelection || allDay) {
                const startTime = allDay ? allDayStartTime : selection.start;
                const endTime = allDay ? allDayEndTime : selection.end;

                const operatoryId = selection.resource?.id;
                if (patient && allDay) {
                    calendarApi?.unselect();
                    dispatch(setAlertDialogMessage('Selection is only for block appointments.'));
                } else {
                    const selectedAppointmentType = patient ? AppointmentType.Patient : AppointmentType.Block;

                    dispatch(setAppointmentType(selectedAppointmentType));
                    if (operatoryId && locationOfCareId) {
                        dispatch(
                            setSchedulePatientAppointment(
                                createPatientAppointmentData(startTime, endTime, operatoryId, patient, locationOfCareId),
                            ),
                        );
                        dispatch(setIsAppointmentPanelOpen(true));
                    }
                }
            } else {
                calendarApi?.unselect();
                dispatch(setAlertDialogMessage('Selection is outside of business hours.'));
            }
        }
    };

export const cleanupAppointmentOverviewPage =
    (calendarApi: CalendarApi | undefined): AppThunk<void> =>
    (dispatch) => {
        dispatch(cleanupEditPatient());

        calendarApi?.unselect();
        const events = calendarApi?.getEvents();
        events?.forEach((e) => e.setProp('classNames', ['event-border-dark']));
    };

export const updateDraggedPatientAllocations =
    (tenantId: string, patientAppointment: IPatientAppointment) =>
    async (dispatch: ThunkDispatch<RootState, null, AnyAction>, getState: () => RootState): Promise<void> => {
        await batch(() => {
            dispatch(setPatientAllocationsDragged(patientAppointment));
            dispatch(updatePatientAllocations(patientAppointment));
        });
        const state = getState();
        state.scheduling.allocations.draggedPatient.forEach((appt: IPatientAppointment) => {
            dispatch(
                updatePatientAppointment({
                    tenantId,
                    appointment: appt,
                }),
            );
        });
    };

export const updateDraggedBlockAllocations =
    (tenantId: string, blockAppointment: IBlockAppointment) =>
    async (dispatch: ThunkDispatch<RootState, null, AnyAction>, getState: () => RootState): Promise<void> => {
        await batch(() => {
            dispatch(setBlockAllocationsDragged(blockAppointment));
            dispatch(updateBlockAllocation(blockAppointment));
        });
        const state = getState();
        state.scheduling.allocations.draggedBlock.forEach((appt: IBlockAppointment) => {
            dispatch(updateBlockAppointment({ tenantId, appointment: appt }));
        });
    };

export function hasFinishCheckoutSucceeded(checkoutData: IAppointmentCheckoutModel) {
    return !checkoutData.userTasksAppointment?.length && !checkoutData.userTasksPatient?.length;
}

export const finishCheckout = createAsyncThunk<
    IAppointmentCheckoutModel,
    { tenantId: string },
    { state: RootState; rejectValue: string }
>('finishCheckout', async ({ tenantId }, { getState, dispatch, rejectWithValue }) => {
    const patientAppointment = getState().scheduling.selectedAppointment.data as IPatientAppointment;
    const patient = selectEditPatient(getState());

    try {
        const { data: checkoutData } = await dentalApi.checkoutAppointment(tenantId, { patient, patientAppointment });

        if (hasFinishCheckoutSucceeded(checkoutData)) {
            dispatch(setCheckoutError(undefined));
        } else {
            dispatch(setCheckoutError('Please complete all remaining tasks before checkout.'));
        }

        return checkoutData;
    } catch (e) {
        return rejectWithValue(e as string);
    }
});

export const insertAppointment =
    (appointment: IPatientAppointment | IBlockAppointment): AppThunk<void> =>
    (dispatch, getState) => {
        const isPatientAppointment = (
            appointment: IPatientAppointment | IBlockAppointment,
        ): appointment is IPatientAppointment => {
            return (<IPatientAppointment>appointment).patientId !== undefined;
        };

        const { selectedLOC, selectedDate } = getState().scheduling;
        const { date, locationOfCareId } = appointment;
        const formattedDate = format(selectedDate, 'yyyy-MM-dd');

        if (date === formattedDate && selectedLOC?.id === locationOfCareId) {
            if (isPatientAppointment(appointment)) {
                dispatch(updatePatientAllocations(appointment));
            } else {
                dispatch(updateBlockAllocation(appointment));
            }
        }
    };

export const updatePatientAppointmentProperty =
    ({
        tenantId,
        appointment,
        prop,
        value,
    }: {
        tenantId: string;
        appointment: IPatientAppointment;
        prop: keyof IPatientAppointment;
        value: unknown;
    }): AppThunk<Promise<IPatientAppointment | undefined>> =>
    async (dispatch) => {
        const updateAppointment: IPatientAppointment = {
            ...appointment,
            [prop]: value,
        };

        const data = await dispatch(
            updatePatientAppointment({
                tenantId,
                appointment: updateAppointment,
            }),
        ).unwrap();

        return data;
    };

export const checkIn =
    ({ tenantId, patientId, appointmentId }: { tenantId: string; patientId: string; appointmentId: string }): AppThunk<void> =>
    async (dispatch) => {
        dispatch(setPatientOverviewOpen(false));
        dispatch(setIsCheckinPanelOpen(true));
        dispatch(getEditPatient({ tenantId, patientId }));
        await dispatch(getPatientAndAppointment({ tenantId, appointmentId }));
    };

/**
 * Use this to open checkout panel. Handles initial logic on setting the checkout panel to be open.
 *
 * @param {({
 *         appointment: IPatientAppointmentView | IPatientAppointment;
 *         tenantId: string;
 *     })} {
 *         tenantId,
 *         appointment,
 *     }
 * @return {*}  {AppThunk<void>}
 */
const setCheckoutPanelOpen =
    ({
        tenantId,
        appointment,
    }: {
        appointment: IPatientAppointmentView | IPatientAppointment;
        tenantId: string;
    }): AppThunk<void> =>
    async (dispatch) => {
        if (appointment.trackerStatusId !== TrackerStatus.Dismissed) {
            dispatch(
                updatePatientAppointmentProperty({
                    appointment,
                    tenantId,
                    prop: 'trackerStatusId',
                    value: TrackerStatus.Dismissed,
                }),
            );
        }
        dispatch(setIsCheckoutPanelOpen(true));
    };

export const returnToCheckout =
    ({ tenantId }: { tenantId: string }): AppThunk<void> =>
    async (dispatch, getState) => {
        const returnToCheckoutData = getState().scheduling.selectedAppointment.previousAppointmentData;
        if (returnToCheckoutData) {
            const { patient, data, date } = returnToCheckoutData;
            dispatch(setPatientOverviewOpen(false));
            await dispatch(setSelectedDate(new Date(date)));
            const { appointment } = await dispatch(getPatientAndAppointment({ tenantId, appointmentId: data.id })).unwrap();
            await batch(() => {
                dispatch(getEditPatient({ tenantId, patientId: patient.id }));
                dispatch(
                    getCheckoutBillingProcedures({
                        tenantId,
                        patientId: patient.id,
                        appointmentId: data.id,
                    }),
                );
                dispatch(fetchPatientCheckoutTasks({ tenantId, patientId: patient.id }));
            });
            await dispatch(setPreviousAppointmentData(undefined));

            dispatch(setCheckoutPanelOpen({ tenantId, appointment }));
        }
    };
export const withCheckOut =
    ({ tenantId, patientId, appointmentId }: { tenantId: string; patientId: string; appointmentId: string }): AppThunk<void> =>
    async (dispatch) => {
        dispatch(setPatientOverviewOpen(false));
        //Take the appointment info returned here and just use that below.
        const { appointment } = await dispatch(getPatientAndAppointment({ tenantId, appointmentId })).unwrap();
        await batch(() => {
            dispatch(getEditPatient({ tenantId, patientId }));
            if (appointment.encounterId)
                dispatch(getPatientAppointmentEncounter({ tenantId, patientId, encounterId: appointment.encounterId }));
        });

        dispatch(
            getCheckoutBillingProcedures({
                tenantId,
                patientId,
                appointmentId: appointment?.id,
            }),
        );

        dispatch(fetchPatientCheckoutTasks({ tenantId, patientId }));
        dispatch(setCheckoutPanelOpen({ tenantId, appointment }));
    };

export const updateLOCWhenOperatoryChanges =
    (tenantId: string, value: string, patientAppointment: IPatientAppointment): AppThunk<void> =>
    (dispatch, getState) => {
        const state = getState();
        const operatories = selectOperatoriesAsList(state, tenantId);
        const operatory = operatories.find((op) => op.id === value);
        if (operatory?.locationOfCareId) {
            dispatch(
                setUpdatePatientAppointment({
                    ...patientAppointment,
                    locationOfCareId: operatory.locationOfCareId,
                    operatoryId: operatory.id,
                }),
            );
        }
    };
