import { PayloadAction } from '@reduxjs/toolkit';
import { ChargesAndPaymentsReportQuery } from 'api/models/charges-and-payments-report-query.model';
import { IReportingState } from '../reporting.state';

export const chargeAndPaymentsReducers = {
    setChartAndPaymentsProp: (
        state: IReportingState,
        action: PayloadAction<{ path: keyof ChargesAndPaymentsReportQuery; value: string | number | undefined }>,
    ): void => {
        const { path, value } = action.payload;
        ((state.reportProperties as ChargesAndPaymentsReportQuery)[path] as string | number | undefined) = value;
    },
    toggleChargeAndPaymentsListId: (
        state: IReportingState,
        action: PayloadAction<{ path: keyof ChargesAndPaymentsReportQuery; id: string }>,
    ): void => {
        const { id, path } = action.payload;
        if (!(state.reportProperties as ChargesAndPaymentsReportQuery)[path])
            ((state.reportProperties as ChargesAndPaymentsReportQuery)[path] as unknown[]) = [];

        const currentIds = (state.reportProperties as ChargesAndPaymentsReportQuery)[path] as string[];

        if (currentIds)
            if (currentIds?.includes(id)) {
                ((state.reportProperties as ChargesAndPaymentsReportQuery)[path] as string[]) = currentIds.filter(
                    (_id) => _id !== id,
                );
            } else {
                ((state.reportProperties as ChargesAndPaymentsReportQuery)[path] as string[]) = [...currentIds, id];
            }
    },
};
