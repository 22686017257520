import { IComboBoxOption, Label, Stack, Text, useTheme } from '@fluentui/react';
import { EditComponentProps } from '../Shared/LookupEditPanel';
import { IProcedureDetail } from 'api/models/procedure-detail.model';
import { Field } from 'components';
import { useDebounce, useSelector, useTenantId } from 'hooks';
import { selectProcedureDetailOptions } from 'state/slices/lookups/lookups.selectors';
import useLookupsStore from 'hooks/store/useLookupsStore';
import { useEffect, useState } from 'react';
import { getValidationError } from 'hooks/useValidation';
import { TextEditor } from 'pages/Charting/components/TextEditor';

export default function EditProperties(props: EditComponentProps): JSX.Element {
    const { selectedLookup, errors } = props;
    const theme = useTheme();
    const tenantId = useTenantId();
    const item = selectedLookup.item as IProcedureDetail | undefined;

    const [value, setValue] = useState<string | undefined>(item?.template);
    const debouncedTextValue = useDebounce(value, 200);

    const templateError = getValidationError(errors ?? [], 'Procedure Template');
    const procedureError = getValidationError(errors ?? [], 'Selected Procedure');

    const procedureTemplateErrorMessage = templateError ? 'Procedure template text is required.' : undefined;
    const procedureErrorMessage = procedureError ? 'Selected procedure is required.' : undefined;

    useEffect(() => {
        setSelectedLookupItemProp('template', debouncedTextValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedTextValue]);

    const { setSelectedLookupItemProp, setSelectedLookup } = useLookupsStore<IProcedureDetail>('ProcedureDetails');

    const options = useSelector((state) => selectProcedureDetailOptions(state, tenantId));

    const onChangeProcedure = (option?: IComboBoxOption) => {
        //If we are creating a new procedure detail from this panel.
        if (selectedLookup.isNew && option) {
            const { key, data } = option;
            const procedureDetail: IProcedureDetail = {
                ...(data?.procedureDetail ?? {}),
                id: key as string,
                displayName: data?.displayName,
                code: data?.code,
                isDeleted: false,
            };
            setSelectedLookup({ ...selectedLookup, item: procedureDetail });
        }
    };

    return (
        <Stack tokens={{ childrenGap: 10 }} grow>
            {selectedLookup.isNew && (
                <Field.SearchCombo
                    label="Selected Procedure"
                    placeholder="Find procedures (Code or Description)"
                    onChange={(ev, option) => onChangeProcedure(option)}
                    options={options}
                    virutalized
                    maxResults={1000}
                    selectedKey={item?.id}
                    errorMessage={procedureErrorMessage}
                    required
                />
            )}

            {item?.id && (
                <Stack grow>
                    <Label required>{`Template${item.displayName ? ` - (${item.code}) ${item.displayName}` : ''}`}</Label>
                    <TextEditor placeholder="Enter template..." onChange={setValue} value={value} />
                    {procedureTemplateErrorMessage && (
                        <Text variant="small" style={{ color: theme.palette.redDark }}>
                            {procedureTemplateErrorMessage}
                        </Text>
                    )}
                </Stack>
            )}
        </Stack>
    );
}
