import { DetailsRow, DirectionalHint, IconButton, MessageBar, SelectionMode, Stack, TooltipHost } from '@fluentui/react';
import { IUpcomingAppointment } from 'api/models/Scheduling/patientAppointment.model';
import { Section, SortableDetailsList } from 'components';
import { EditDetailsColumn } from 'components/EditDetailsColumn';
import { useSelector, useTenantId } from 'hooks';
import { useEffect } from 'react';
import { batch, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { selectEditPatientHasChange } from 'state/slices/edit-patient/edit-patient.selectors';
import { setEditPatientPanelOpen } from 'state/slices/edit-patient/edit-patient.slice';
import { getAppointmentData } from 'state/slices/scheduling/scheduling.actions';
import { selectLocationsOfCare } from 'state/slices/scheduling/scheduling.selectors';
import { setCancelAppointmentModalOpen } from 'state/slices/scheduling/scheduling.slice';
import { setPatientOverviewOpen, setSelectedDate } from 'state/slices/scheduling/scheduling.slice';
import { AppointmentType } from 'state/slices/scheduling/scheduling.state';
import { getMissingUserIdentities } from 'state/slices/users-identities/user-identities.actions';

import { classicDateOnly } from 'utils/dateOnly';
import { timeOptions } from 'utils/getTimeOptions';
import DispositionTooltip from './DispositionTooltip';

type Props = {
    items?: IUpcomingAppointment[];
    setIsAlertOpen: (value: boolean) => void;
    setAlertMessage: (value: string) => void;
};

function UpcomingAppts({ items, setIsAlertOpen, setAlertMessage }: Props): JSX.Element {
    const tenantId = useTenantId();
    const dispatch = useDispatch();
    const { push } = useHistory();
    const locationsOfCare = useSelector(selectLocationsOfCare);
    const location = useLocation();

    const routePieces = location.pathname.split('/').filter((string) => string !== '/' && string !== '');
    const route = routePieces[1];

    const hasChanged = useSelector(selectEditPatientHasChange);

    useEffect(() => {
        if (items?.length) {
            dispatch(
                getMissingUserIdentities({
                    tenantId,
                    userIds: items.map((appt) => appt.modifiedBy ?? ''),
                }),
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);

    const handlePatientInvoked = (item?: IUpcomingAppointment) => {
        if (hasChanged) {
            setIsAlertOpen(true);
            setAlertMessage(
                'There are unsaved changes for the patient demographics. Would you like to save and continue to the appointment panel?',
            );
        } else {
            if (item && item.patientId && item.id && item.date) {
                const patientId = item.patientId as string;
                const appointmentId = item.id as string;
                const appointmentDate = item.date;

                batch(() => {
                    dispatch(setSelectedDate(new Date(`${appointmentDate}T00:00:00`)));
                    dispatch(getAppointmentData(tenantId, appointmentId, AppointmentType.Patient));
                    if (route !== 'scheduling') {
                        push(`/${tenantId}/scheduling/${patientId}`);
                    }
                    dispatch(setPatientOverviewOpen(true));
                });
                dispatch(setEditPatientPanelOpen(false));
            }
        }
    };

    const onCancelAppointment = (upcomingAppointment?: IUpcomingAppointment) => {
        if (upcomingAppointment)
            dispatch(setCancelAppointmentModalOpen({ isOpen: true, appointmentToRemoveId: upcomingAppointment.id }));
    };

    if (!items?.length) return <MessageBar>There are no upcoming appointments.</MessageBar>;

    const tooltipContent = (item: IUpcomingAppointment) => (
        <Stack grow>
            <div>Procedures: {item.procedures?.length ? item.procedures.map((proc) => proc.code).join(', ') : 'N/A'}</div>
            <div>Reason for Encounter: {item.encounterReason ? item.encounterReason : 'N/A'}</div>
        </Stack>
    );

    const getDateContent = (item: IUpcomingAppointment | undefined) => {
        const startTime = item?.startTime ? timeOptions.find((t) => t.key === item?.startTime)?.text : null;
        const endTime = item?.endTime ? timeOptions.find((t) => t.key === item?.endTime)?.text : null;
        const date = item?.date ? classicDateOnly(item?.date) : '';

        return `${date} | ${`${startTime} - ${endTime}`}`;
    };

    const getLocationOfCareContent = (item: IUpcomingAppointment | undefined) => {
        return locationsOfCare.find((res) => (res.id ? res.id === item?.locationOfCareId : ''))?.displayName ?? '';
    };

    return (
        <Section>
            <SortableDetailsList<IUpcomingAppointment>
                compact
                selectionMode={SelectionMode.none}
                items={items || []}
                sortOnMount={true}
                sortColumns={['date']}
                onRenderRow={(props) => {
                    if (props) {
                        const { item } = props;
                        return (
                            <TooltipHost delay={0} directionalHint={DirectionalHint.leftCenter} content={tooltipContent(item)}>
                                <DetailsRow {...props} />
                            </TooltipHost>
                        );
                    }

                    return null;
                }}
                columns={[
                    {
                        key: 'date',
                        minWidth: 170,
                        maxWidth: 170,
                        name: 'Date',
                        fieldName: 'date',
                        getValueKey: (item) => getDateContent(item),
                        onRender: (item) => {
                            if (item) {
                                const content = getDateContent(item);
                                if (!item.isDeleted) {
                                    return <EditDetailsColumn title={content} editOnClick={() => handlePatientInvoked(item)} />;
                                } else {
                                    return <i>{content}</i>;
                                }
                            }
                        },
                    },
                    {
                        key: 'provider',
                        minWidth: 130,
                        maxWidth: 130,
                        name: 'Treating Provider',
                        fieldName: 'provider',
                        onRender: (item) => (item ? item?.isDeleted ? <i>{item.provider}</i> : <span>{item.provider}</span> : ''),
                    },
                    {
                        key: 'locationOfCare',
                        minWidth: 130,
                        maxWidth: 130,
                        name: 'Location of Care',
                        getValueKey: (item) => getLocationOfCareContent(item),
                        onRender: (item) => {
                            if (item) {
                                const locationDisplayName = getLocationOfCareContent(item);
                                return item.isDeleted ? <i> {locationDisplayName} </i> : <span> {locationDisplayName} </span>;
                            }
                        },
                    },

                    {
                        key: 'encounterReason',
                        minWidth: 140,
                        maxWidth: 140,
                        name: 'Reason for Encounter',
                        fieldName: 'encounterReason',
                        onRender: (item) =>
                            item ? item?.isDeleted ? <i>{item.encounterReason}</i> : <span>{item.encounterReason}</span> : '',
                    },

                    {
                        key: 'appointmentStatusId',
                        minWidth: 120,
                        name: 'Disposition',
                        onRender: (item) => {
                            return <DispositionTooltip item={item} />;
                        },
                    },
                    {
                        key: 'reasonForCancellation',
                        minWidth: 140,
                        name: 'Cancellation Reason ',
                        fieldName: 'cancellationReason',
                        onRender: (item) => {
                            return <span title={item?.cancellationReason ?? ''}>{item?.cancellationReason ?? ''}</span>;
                        },
                    },
                    {
                        key: 'cancel',
                        minWidth: 30,
                        maxWidth: 30,
                        name: '',
                        onRender: (item) => {
                            if (item?.isDeleted || !!item?.encounterId) return null;
                            return (
                                <Stack horizontal horizontalAlign="end" grow>
                                    <IconButton
                                        onClick={() => onCancelAppointment(item)}
                                        title="Cancel Appointment"
                                        styles={{ root: { maxHeight: 20, width: 20 } }}
                                        iconProps={{ iconName: 'Trash' }}
                                    />
                                </Stack>
                            );
                        },
                    },
                ]}
            />
        </Section>
    );
}

export default UpcomingAppts;
