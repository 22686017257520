import { DocumentBase } from 'api/document-base.model';
import { ToothArea } from './tooth-area';
import { ChartProcedureStatus } from './chart.model';
import { ChangeAuditSupport } from 'models/contracts';
import { IdSupport, ReferencesSupport } from './support-types';

export interface IEncounterLedger extends DocumentBase {
    patientId?: string;
    encounterNumber?: string;
    transactions?: IEncounterLedgerTransaction[];
}
/**
 * Model Additions/Changes:
 * PaymentMethod (Discover Card, Check, etc...)
 *
 *
 * If you're in checkout you always have an encounter...
 *
 * You need an encounter to TP a chart procedure.
 * You need an encounter to complete a chart procedure.
 */
export interface IEncounterLedgerTransaction {
    id?: string;
    patientId?: string;
    encounterId?: string;
    encounterNumber?: string;
    billingEncounterProcedureId?: string;
    paymentSourceId?: string;
    source?: string;
    target?: string;
    dateOfEntry?: string;
    type?: TransactionType;
    treatingProvider?: string;
    batchId: string;
    procedureCode?: string;
    payerName?: string;
    totalFee: number;
    insuranceAmount: number;
    patientAmount: number;
    void: boolean;
    modifiedOn: string;
    modifiedBy?: string;
    references?: Record<string, string>;
}

export interface IEncounterSummary {
    encounterId?: string;
    encounterNumber?: string;
    encounterStatus?: string;
    claimStatus?: string;
    dateOfService: string;
    locationOfCare?: string;
    provider?: string;
    insuranceCharges: number;
    insurancePayments: number;
    insuranceAdjustments: number;
    patientPayments: number;
    patientReservePayments: number;
    patientAdjustments: number;
    patientCharges: number;
    insuranceBalance: number;
    patientBalance: number;
    patientEstimate: number;
    totalBalance: number;
    totalCharges: number;
    totalEstimate: number;
    totalCommonPatientFee: number;
    reBillHistory?: IEncounterSummaryReBillHistory[];
}

export interface IEncounterSummaryReBillHistory {
    reBilledOn: string;
    reBilledBy: string;
    note?: string;
}

export interface EncounterSummaryWithLineItemsView {
    patientId?: string;
    encounterSummaries?: EncounterSummaryWithLineItems[];
}

export type EncounterSummaryWithLineItems = {
    procedureSummaries?: ProcedureSummaryWithEncounterIdAndTransactionId[];
    totalCommonPatientFee: number;
} & IEncounterSummary;

export interface ILedgerTotalsView {
    patientId?: string;
    encounterSummaries?: IEncounterSummary[];
}

export interface ILedgerTransactionSummaryView {
    encounterId?: string;
    procedureSummaries?: ProcedureSummary[];
}

export enum TransferTo {
    Patient = 'Patient',
    Insurance = 'Insurance',
}

export interface ProcedureSummary {
    id?: string;
    procedureCode?: string;

    insuranceContracted: number;
    insuranceCharges: number;
    insurancePayments: number;
    insuranceAdjustments: number;
    insuranceEstimatedAdjustments: number;
    insuranceEstimate: number;
    insuranceBalance: number;

    patientPayments: number;
    patientReservePayments: number;
    patientAdjustments: number;
    patientCharges: number;
    patientEstimate: number;
    patientBalance: number;

    totalPatientPayments: number;
    totalCharges: number;
    totalBalance: number;
    totalEstimate: number;
    totalAdjustmentAmounts: number;

    additionalOrLabFee: number;
    transferTo?: TransferTo;
    ucr: number;

    commonPatientFee: number;

    toothIds?: number[];
    areas?: (keyof typeof ToothArea)[];
    stage?: string;
    preAuthorizationCode?: string;
    procedureDescription?: string;
    procedureStatus?: ChartProcedureStatus;
}

export type ProcedureSummaryWithEncounterId = {
    encounterId?: string;
} & ProcedureSummary;

export type ProcedureSummaryWithEncounterIdAndTransactionId = {
    patientTransactionId?: string;
    adjustmentTransactionId?: string;
} & ProcedureSummaryWithEncounterId;

export enum TransactionType {
    Payment = 'Payment',
    NegativePayment = 'NegativePayment',
    Adjustment = 'Adjustment',
    Charge = 'Charge',
    Transfer = 'Transfer',
    UnappliedCredit = 'UnappliedCredit',
    PrePayment = 'PrePayment',
    Refund = 'Refund',
    Redistribute = 'Redistribute',
}

export enum TransactionSource {
    Patient = 'Patient',
}

type CommonTransactionSupports = ChangeAuditSupport & ReferencesSupport & IdSupport;

export interface ICommonTransaction extends CommonTransactionSupports {
    patientId?: string;
    amount: number;
    paymentSourceId?: string;
    batchId?: string;
    source?: TransactionSource;
    type?: TransactionType;
    dateOfEntry: string;
    dateOfService?: string;

    chartProcedureId?: string;
    procedureCode?: string;
    encounterId?: string; // Existance of this determines EncounterLedger vs PatientLedger

    treatmentPlanId?: string;
    treatmentPlanPhaseId?: string;

    adjustmentReasonId?: string;
    reversedTransactionId?: string;
    negativePaymentReasonId?: string;

    isSystemGenerated?: boolean;

    treatingProviderId?: string;

    createdOn?: string;
    createdBy?: string;
}

// export interface PaymentTransaction extends Omit<ICommonTransaction, 'treatmentPlanId' | 'treatmentPlanPhaseId'> {
//     chartProcedureId: string;
//     procedureCode: string;
//     encounterId: string;
// }

// export interface PrePaymentTransaction extends Omit<ICommonTransaction, 'encounterId'> {
//     treatmentPlanId: string;
//     treatmentPlanPhaseId: string;
//     chartProcedureId: string;
//     procedureCode: string;
// }

// export type AdjustmentTransaction = Omit<
//     ICommonTransaction,
//     'encounterId' | 'chartProcedureId' | 'procedureCode' | 'treatmentPlanId' | 'treatmentPlanPhaseId'
// >;

/**
 * Payment Adjustment Data Requirements
 * - EncounterLedger for the encounter
 * - All PaymentSources associated to the current transactions
 *
 *  When you click the "Payment" total, fetch the associated EncounterLedger, then fetch all associated PaymentSources
 *
 * [Ledger State]
 * {
 *      encounterLedger: IEncounterLedger;
 *      paymentSources: IPaymentSources[];
 *      patientLedger: IPatientLedger;
 *
 *      loadingEncounterLedger?: LoadingStatus;
 *      loadingPatientLedger?: LoadingStatus;
 *      loadingLedgerSummary?: LoadingStatus;
 *
 *      currentPaymentSource?: PaymentSource;
 *      currentTransactions?: ICommonTransaction[];
 *
 *      ledgerSummary: IEncounterLedgerSummary
 *      procedureSummaries: Dictionary<ILedgerSummary>
 * }
 *
 * addTransactions = (tenantId: string) => async (dispatch, getState) => {
 *  const {currentTransactions} = getState().encounterLedger
 *  currentTransactions.forEach(transaction, )
 * }
 *
 * const savePaymentSourceAndTransactions = createAsyncThunk((transactions, sources, tenantId) => {
 * })
 *
 * savePayment = () => async() => {
 *  // Get batchId and distribute to current transactions
 *  // Save payment source
 *  // Save the transactions
 *  // Refetch PatientCheckout billing procs
 *  await dispatch(savePaymentSourceAndTransactions)
 * }
 *
 * type PaymentTransactionView = IPaymentSource & { transactions: IEncounterLedgerTransactions[]}[]
 * selectLedgerAdjustments:
 *
 *
 * {
 *     [key: sourceId]: transactions[];
 * }
 */
