import { PropsWithChildren } from 'react';
import '@fluentui/react/dist/sass/Fabric.scss';
import { createTheme, initializeIcons, ThemeProvider } from '@fluentui/react';
import { useSelector } from 'hooks';
import { getSelectedTheme } from 'state/slices/ui/ui.slice';

initializeIcons();

const ThemeInitializer = (props: PropsWithChildren<any>): JSX.Element => {
    const theme = useSelector(getSelectedTheme);
    
    const fontExtendedTheme = createTheme({...theme, defaultFontStyle: {fontFamily: 'source-sans-pro, sans-serif'}});

    return (
        <ThemeProvider theme={fontExtendedTheme} style={{ height: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
            {props.children}
        </ThemeProvider>
    );
};

export default ThemeInitializer;
