import { Text } from '@fluentui/react';
import { IBillingProcedure } from 'api/models/billing-procedure.model';
import { ChartProcedureDiagnosis } from 'api/models/chart.model';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import { useSelector } from 'react-redux';
import { selectDiagnoses } from 'state/slices/tenant/diagnoses.slice';

const diagnosisCodes: ISortableColumn<IBillingProcedure> = {
    key: 'diagnosisCodes',
    name: 'Diagnosis Codes',
    fieldName: 'diagnosisCodes',
    minWidth: 100,
    maxWidth: 150,
    isResizable: true,
    onRender: (item) => {
        if (item) return <RenderDiagnosisCodesColumn diagnosisCodes={item.diagnosisCodes} />;
    },
};

function RenderDiagnosisCodesColumn({ diagnosisCodes }: { diagnosisCodes?: ChartProcedureDiagnosis[] }): JSX.Element | null {
    const diagnoses = useSelector(selectDiagnoses);

    if (!diagnosisCodes || !diagnosisCodes.length) return <Text variant="smallPlus">N/A</Text>;
    const diagnosisCodesList = diagnosisCodes
        ? (diagnosisCodes
              .map((dx) => {
                  return diagnoses[dx.id]?.code ?? '';
              })
              .filter((code) => code !== undefined) as string[])
        : [];
    const dxString = diagnosisCodesList.join(', ');
    return <Text variant="smallPlus">{dxString}</Text>;
}

export default diagnosisCodes;
