import { MessageBar } from '@fluentui/react';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
    selectPatientOverpaymentOrOverAdjustment,
    selectPaymentAndAdjustmentAmounts,
    selectPaymentsAndAdjustmentTotalPayment,
} from 'state/slices/ledger/patient-payments-and-adjustments/patient-payments-and-adjustments.selectors';
import { usdCurrencyFormatter } from 'utils';

export default function PaymentAndAdjustmentResultMessageBar() {
    const { paymentAmount, adjustmentAmount } = useSelector(selectPaymentAndAdjustmentAmounts);
    const totalPaymentDue = useSelector(selectPaymentsAndAdjustmentTotalPayment);
    const overpaymentOrAdjustment = useSelector(selectPatientOverpaymentOrOverAdjustment);

    const getMessage = useCallback(() => {
        let message = 'The patient balance, after a ';

        if (paymentAmount) message += `${usdCurrencyFormatter.format(paymentAmount)} payment`;
        if (paymentAmount && adjustmentAmount) message += ' and a ';
        if (adjustmentAmount) message += `-${usdCurrencyFormatter.format(adjustmentAmount)} adjustment`;

        message += `, will be reduced to ${usdCurrencyFormatter.format(
            parseFloat((totalPaymentDue - paymentAmount - adjustmentAmount).toFixed(2)) || 0,
        )}.`;

        return message;
    }, [paymentAmount, adjustmentAmount, totalPaymentDue]);

    if (overpaymentOrAdjustment || (!paymentAmount && !adjustmentAmount)) return null;

    return <MessageBar>{getMessage()}</MessageBar>;
}
