import { useEffect } from 'react';
import { Stack, Spinner, SpinnerSize, MessageBar, MessageBarType, useTheme } from '@fluentui/react';
import { ActivityPage } from 'components';
import { IActionButton } from 'components/ActionButton';
import useLookupsStore from 'hooks/store/useLookupsStore';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { Lookups } from 'state/slices/lookups/lookupsStateTypes';

const onBuildLoadingSpinner = () => (
    <Stack style={{ paddingBottom: 12 }}>
        <Spinner size={SpinnerSize.large} label="Loading..." labelPosition="right" />
    </Stack>
);

const onBuildErrors = (lookups: Lookups) => (
    <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
        <strong>Error occurred while loading:</strong>
        <ul>
            {lookups.errors?.map((error, index) => (
                <li key={`lookup-error-${index}`}>{error.message}</li>
            ))}
        </ul>
    </MessageBar>
);

type CommonListParameters<T> = {
    lookupType: string;
    displayName: string;
    newItem: T;
    listComponent: JSX.Element;
    editComponent: JSX.Element;
    searchBoxComponent?: JSX.Element;
};

function LookupSettingPage<T>(props: CommonListParameters<T>): JSX.Element {
    const { lookupType, displayName, newItem, listComponent, editComponent, searchBoxComponent } = props;
    const { getLookups, lookups, selectedLookup, setSelectedLookup, buildNewItem, lookupsList } = useLookupsStore<T>(lookupType);
    const { palette } = useTheme();

    useEffect(() => {
        getLookups();
    }, [getLookups]);

    const onBuildTopButtons = () => {
        const buttons = [
            {
                text: `Create ${displayName}`,
                type: 'PrimaryButton',
                iconProps: { iconName: 'Add' },
                onClick: () => setSelectedLookup(buildNewItem({ ...newItem })),
            } as IActionButton,
        ];
        return lookups.loadingStatus === LoadingStatus.Failed ? [] : buttons;
    };

    const plurality: { [key: string]: string } = {
        Operatory: 'Operatories',
        Provider: 'Providers',
        'Locations of Care': 'Locations of Care',
        Template: 'Procedure Templates',
    };

    return (
        <ActivityPage
            title={`Manage ${plurality[displayName]}`}
            mainButtons={onBuildTopButtons()}
            centerContent={searchBoxComponent}
            hasPageHeader
            hideFooter
        >
            <div style={{ margin: 12, background: palette.white }}>
                {lookups.loadingStatus === LoadingStatus.Pending && onBuildLoadingSpinner()}
                {lookups.loadingStatus === LoadingStatus.Failed && onBuildErrors(lookups)}
                {lookups.loadingStatus === LoadingStatus.Completed ? (
                    lookupsList.length ? (
                        listComponent
                    ) : (
                        <MessageBar>No {plurality[displayName]} have been added.</MessageBar>
                    )
                ) : null}
            </div>
            {selectedLookup?.item && editComponent}
        </ActivityPage>
    );
}

export default LookupSettingPage;
