import { Stack, useTheme, Text } from '@fluentui/react';
import { isArray, map } from 'lodash';
import { toothSpriteReferences } from 'pages/Charting/components/ToothCanvas/spriteList';

type Props = {
    items: number | number[] | string | undefined;
};

export default function ProcedureSectionItemLabel({ items }: Props): JSX.Element {
    const theme = useTheme();
    const teethNames = !isArray(items)
        ? typeof items === 'number'
            ? toothSpriteReferences.find((t) => t.id === items)?.displayName
            : items
        : map(items, (tooth) => {
              return toothSpriteReferences.find((t) => t.id === tooth)?.displayName;
          }).join(', ');

    function getFormattedLabel(): { name: string | undefined; stage: string | undefined } {
        //If we are dealing with an area item, then we can attempt to get the stage of the procedure separate from the area name
        if (typeof items === 'string') {
            const [name, stage] = (teethNames ?? '').split('-');
            return { name, stage };
        }
        //Otherwise just keep the same names:
        return { name: teethNames, stage: undefined };
    }

    const { name, stage } = getFormattedLabel();

    return (
        <Stack
            styles={{
                root: {
                    padding: 5,
                    background: theme.palette.neutralLighterAlt,
                    border: `1px solid ${theme.palette.neutralLighter}`,
                },
            }}
            verticalAlign="center"
            horizontalAlign="center"
            grow
        >
            <Text>{isArray(items) ? 'Teeth' : typeof items === 'number' ? 'Tooth' : 'Area'}</Text>
            <Text variant={isArray(items) ? 'large' : 'xLargePlus'}>{name}</Text>
            {stage && <Text>Stage: {stage}</Text>}
        </Stack>
    );
}
