import { IconButton, mergeStyleSets, Stack, Text, useTheme } from '@fluentui/react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'hooks';
import { setUpdatePatientAppointment } from 'state/slices/scheduling/scheduling.slice';
import { selectSelectedAppointmentData } from 'state/slices/scheduling/scheduling.selectors';

type PillDetail = {
    procedureId: string;
    code?: string;
};

export type PillButtonProps = {
    pillDetail: PillDetail;
    onClick?: (procedureId: string) => void;
};

function PillButton({ pillDetail, onClick }: PillButtonProps): JSX.Element {
    const _theme = useTheme();
    const _dispatch = useDispatch();
    const patientAppointment = useSelector(selectSelectedAppointmentData);

    const classNames = mergeStyleSets({
        listGridTile: {
            cursor: 'pointer',
            textAlign: 'center',
            outline: 'none',
            position: 'relative',
            background: _theme.palette.neutralLighter,
        },
    });

    const removeProcedureClick = () => {
        if (onClick) {
            onClick(pillDetail.procedureId);
        } else {
            if (patientAppointment && patientAppointment.procedures) {
                _dispatch(
                    setUpdatePatientAppointment({
                        ...patientAppointment,
                        procedures: [...patientAppointment.procedures.filter((sp) => sp.procedureId !== pillDetail.procedureId)],
                    }),
                );
            }
        }
    };

    return (
        <Stack horizontal className={classNames.listGridTile} verticalAlign="center" onClick={removeProcedureClick}>
            <IconButton iconProps={{ iconName: 'cancel' }} styles={{ icon: { fontSize: 13 } }} />
            <Text styles={{ root: { paddingRight: 10 } }}>{pillDetail.code}</Text>
        </Stack>
    );
}

export default PillButton;
