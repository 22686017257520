import { IChartProcedure } from 'api/models/chart.model';
import { ConflictRuleType } from 'api/models/lookup.model';
import { IPipelineError, PipelineError } from '../pipeline';
import ProcedureConflictRulesPipeline from '../procedureConflictRules.pipeline';
import { createRule } from '../ruleGenerator';

const ProcedurePartialArch = createRule<ProcedureConflictRulesPipeline, IChartProcedure>({
    ruleTypes: [ConflictRuleType.partialArch],
    rule: (pipeline, item) => {
        const _errors: IPipelineError[] = [];
        const procedure = pipeline.getProcedure(item);
        const allTeethInArchSelected = procedure ? pipeline.allArchTeethSelected(procedure) : false;
        if (allTeethInArchSelected) {
            _errors.push({
                type: PipelineError.PartialArch,
                data: procedure,
            });
        }
        return { shouldRemoveItem: allTeethInArchSelected, errors: _errors };
    },
});

export default ProcedurePartialArch;
