import { IComboBoxOption } from '@fluentui/react';
import { IEncounterSummary } from 'api/models/encounter-ledger.model';
import { isAfter, isBefore, isEqual } from 'date-fns';
import { filter, isEmpty } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'state/store';
import { classicDateOnly } from 'utils/dateOnly';
import { selectFilteredEncounterLedgerTotalsView } from '../ledger.selectors';

export const selectClaimHistoryState = (state: RootState) => state.ledger.claimHistory;

export const selectSelectedClaimHistoryEncounterSummary = createSelector(
    selectClaimHistoryState,
    (state) => state.selectedEncounterSummary,
);

export const selectClaimHistoryEncounterNoteModalOpen = createSelector(
    selectClaimHistoryState,
    (state) => state.encounterNoteModalOpen,
);

export const selectShowClaimsHistoryFilters = createSelector(selectClaimHistoryState, (state) => state.showFilters);
export const selectClaimsHistoryFilters = createSelector(selectClaimHistoryState, (state) => state.filters);

export const selectEncounterClaimsSummaryLoadingStatus = createSelector(
    selectClaimHistoryState,
    (state) => state.loadingEncounterClaimSummary,
);
export const selectEncounterClaimsSummaryError = createSelector(
    selectClaimHistoryState,
    (state) => state.encounterClaimSummaryError,
);
export const selectEncounterClaimsSummary = createSelector(selectClaimHistoryState, (state) => state.encounterClaimSummary);
export const selectEncounterClaimsSummaryCorrespondence = createSelector(
    selectEncounterClaimsSummary,
    (summary) => summary?.encounterCorrespondence ?? [],
);
export const selectEncounterClaimsSummaryReadyToBillHistory = createSelector(
    selectEncounterClaimsSummary,
    (summary) => summary?.readyToBillHistory ?? [],
);
export const selectEncounterClaimsSummaryClaimHistory = createSelector(
    selectEncounterClaimsSummary,
    (summary) => summary?.claimsHistory ?? [],
);

export const selectClaimsHistoryActiveFiltersCount = createSelector(selectClaimsHistoryFilters, (filters) => {
    const count = filter(
        filters,
        (filter: string | string[] | boolean | undefined, key) =>
            (filters.relativeDateType ? key !== 'startDate' && key !== 'endDate' : true) &&
            filter !== undefined &&
            (typeof filter === 'boolean' ? filter !== false : !isEmpty(filter)),
    ).length;

    return count;
});

const claimsHistoryFilters = {
    insuranceBalanceGreaterThanZero: (item: IEncounterSummary) => item.insuranceBalance > 0,
    startDate: (item: IEncounterSummary, value: string) => {
        const dos = new Date(classicDateOnly(item.dateOfService));
        const valueDate = new Date(value);
        return isAfter(dos, valueDate) || isEqual(dos, valueDate);
    },
    endDate: (item: IEncounterSummary, value: string) => {
        const dos = new Date(classicDateOnly(item.dateOfService));
        const valueDate = new Date(value);
        return isBefore(dos, valueDate) || isEqual(dos, valueDate);
    },
    billingProviders: (item: IEncounterSummary, value: string[]) => {
        return value.indexOf(item.provider ?? '') > -1;
    },
};

export const selectFilteredClaimsHistoryEncounterSummaries = createSelector(
    selectFilteredEncounterLedgerTotalsView,
    selectClaimsHistoryFilters,
    (views, { endDate, insuranceBalanceGreaterThanZero, startDate, billingProviders }) => {
        return views.filter((view) => {
            return (
                (startDate ? claimsHistoryFilters.startDate(view, startDate) : true) &&
                (endDate ? claimsHistoryFilters.endDate(view, endDate) : true) &&
                (insuranceBalanceGreaterThanZero ? claimsHistoryFilters.insuranceBalanceGreaterThanZero(view) : true) &&
                (billingProviders?.length ? claimsHistoryFilters.billingProviders(view, billingProviders) : true)
            );
        });
    },
);


export const selectClaimsHistoryEncounterUniqueProvidersAsOptions = createSelector(selectFilteredEncounterLedgerTotalsView, (views) => {
        const options: IComboBoxOption[] = Array.from(new Set(views.map((views) => views.provider)))
            .map((provider) => ({
                key: provider ?? '',
                text: provider ?? '',
            }))
            .filter((option) => !!option.key);
        return options;
    },
);
