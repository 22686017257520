import { encounterStatusText } from 'api/models/encounter.model';
import IWorkListAppointmentView from 'api/models/worklist-appointment-view';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';

const encounterStatus: ISortableColumn<IWorkListAppointmentView> = {
    key: 'encounterStatus',
    minWidth: 140,
    maxWidth: 140,
    isResizable: true,
    name: 'Encounter Status',
    fieldName: 'encounterStatus',
    onRender: (item) =>
        item?.encounterStatus ? (
            <span title={encounterStatusText[item.encounterStatus]}>{encounterStatusText[item.encounterStatus]}</span>
        ) : null,
};

export default encounterStatus;
