import { HubConnectionState } from '@microsoft/signalr';
import { createAsyncThunk } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import { IUserIdentity } from 'api/models/account.model';
import IChartNote, {
    AttestSignature,
    ChartNoteSignatureType,
    ClinicalNote,
    ClinicalNoteData,
    IChartNoteWithValueData,
    NoteType,
} from 'api/models/chart-note.model';
import { EncounterStatus } from 'api/models/encounter.model';
import { format } from 'date-fns';
import { AppThunk } from 'state/store';
import { v4 as uuid } from 'uuid';
import { setChartingSection } from '../charting/chart/chart.slice';
import { ChartingSection } from '../charting/ui/ui.types';
import { getPatientEncounterWithTasks } from '../encounter/encounter.actions';
import { selectPatientEncounter } from '../encounter/encounter.selectors';
import { selectSignalRConnectionStatus } from '../signalr.slice';
import { getMissingUserIdentities } from '../users-identities/user-identities.actions';
import { setClincalNoteData, setClincalNoteDataProp, setPartialClinicalNote } from './chart-notes.slice';
import { selectUserIdentitiesData } from '../users-identities/user-identities.selectors';
import { selectProvidersData } from '../tenant/providers.slice';

export const getChartNotes = createAsyncThunk<
    IChartNote[],
    {
        tenantId: string;
        patientId: string;
    }
>('getChartNotes', async ({ tenantId, patientId }) => {
    const result = await dentalApi.getChartNotes(tenantId, patientId);
    return result.data;
});

export const getChartNoteById = createAsyncThunk<
    IChartNote,
    {
        tenantId: string;
        patientId: string;
        noteId: string;
    }
>('getChartNoteById', async ({ tenantId, patientId, noteId }, { dispatch }) => {
    const result = await dentalApi.getChartNoteById(tenantId, patientId, noteId);
    const data = result.data;
    if (data.noteType === NoteType.ClinicalNote) dispatch(setChartingSection(ChartingSection.ClinicalNote));

    return data;
});

export const getNewChartNote = (encounterId: string, noteType: NoteType) => {
    return {
        id: uuid(),
        isDeleted: false,
        noteType,
        encounterId,
        data: { value: '<div></div>', signatures: [] },
    };
};

export const getChartNoteByEncounterId = createAsyncThunk<
    IChartNote,
    {
        tenantId: string;
        patientId: string;
        encounterId: string;
    }
>('getChartNoteByEncounterId', async ({ tenantId, patientId, encounterId }) => {
    const result = await dentalApi.getChartNoteByEncounterId(tenantId, patientId, encounterId);
    const data = result.data;

    if (result.status === 204) {
        return getNewChartNote(encounterId, NoteType.ClinicalNote);
    }

    return data;
});

function getAmendText(displayName: string, isHygieneAttesting?: boolean, billingDisplayName?: string) {
    return `<div>Amendment: This clinical note was amended by ${displayName} on ${format(new Date(), 'Pp')}.</div> ${
        isHygieneAttesting ? `<div> Billing Provider: ${billingDisplayName}` : ''
    } </div></div>`;
}

const attestationTextBuilder = ({
    userDisplayName,
    isAttestingHygienist,
    billingProviderDisplayName,
    encounterStatus,
}: SignatureTextBuilderParams) => {
    switch (encounterStatus) {
        case EncounterStatus.AmendRequired: {
            return getAmendText(userDisplayName, isAttestingHygienist, billingProviderDisplayName);
        }
        case EncounterStatus.CorrectionAmend: {
            return getAmendText(userDisplayName, isAttestingHygienist, billingProviderDisplayName);
        }
        default: {
            return `<b><i><div>I attest that the treatment rendered today was completed.<div>Electronically signed by ${userDisplayName} 
            on ${format(new Date(), 'Pp').toString()}.</div> ${
                isAttestingHygienist ? `<div> Billing Provider: ${billingProviderDisplayName}` : ''
            } </div></div></i></b>`;
        }
    }
};

const providerTextBuilder = ({ encounterStatus, userDisplayName, attendingProviderDisplayName }: SignatureTextBuilderParams) => {
    switch (encounterStatus) {
        case EncounterStatus.AmendRequired: {
            return getAmendText(userDisplayName);
        }
        case EncounterStatus.CorrectionAmend: {
            return getAmendText(userDisplayName);
        }
        default: {
            return `<div><b><i><div>Electronically signed by ${userDisplayName} on ${format(
                new Date(),
                'Pp',
            ).toString()}.</div> ${
                attendingProviderDisplayName ? `<div>Attending Provider: ${attendingProviderDisplayName}</div>` : ''
            }</i></b></div>`;
        }
    }
};

let currentNoteUpdateTimer: NodeJS.Timeout | null = null;

export const upsertChartNote = createAsyncThunk<
    IChartNote,
    {
        tenantId: string;
        patientId: string;
        chartNote: IChartNote;
    }
>('upsertChartNote', async ({ tenantId, patientId, chartNote }, { rejectWithValue }) => {
    try {
        if (currentNoteUpdateTimer) currentNoteUpdateTimer = null; //In case of user taking the direct action to save. Rather than auto save.
        const result = await dentalApi.updateChartNote(tenantId, patientId, chartNote);
        return result.data;
    } catch (e) {
        return rejectWithValue(e);
    }
});

export const setCurrentChartNoteDataAndSave =
    ({
        path,
        propPath,
        value,
        tenantId,
        patientId,
    }: {
        path?: string;
        propPath?: string | number | symbol;
        value: unknown;
        tenantId: string;
        patientId: string;
    }): AppThunk<void> =>
    async (dispatch, getState): Promise<void> => {
        const encounter = getState().encounter.patientEncounter;
        if (encounter?.status === EncounterStatus.Attested) return;

        // Updates a path under the node.data model
        if (propPath) dispatch(setClincalNoteDataProp({ path: propPath, value }));
        if (path) dispatch(setClincalNoteData({ path: path as keyof IChartNote, value }));

        if (currentNoteUpdateTimer) clearTimeout(currentNoteUpdateTimer);
        currentNoteUpdateTimer = setTimeout(() => {
            const chartNote = getState().chartNotes.currentClinicalNote;
            currentNoteUpdateTimer = null;
            if (chartNote)
                dispatch(
                    upsertChartNote({
                        tenantId,
                        patientId,
                        chartNote,
                    }),
                );
        }, 6000);
    };

export const setClinicalNoteAttested =
    (tenantId: string, patientId: string, billingProviderId?: string): AppThunk<void> =>
    async (dispatch, getState) => {
        const encounter = selectPatientEncounter(getState());

        if (encounter?.status === EncounterStatus.Attested) return;

        if (billingProviderId) await dispatch(getMissingUserIdentities({ tenantId, userIds: [billingProviderId] }));

        const userIdentitiesData = selectUserIdentitiesData(getState());

        const userIdentity = getState().account.data?.identity;
        const currentValue = getState().chartNotes.currentClinicalNote?.data as IChartNoteWithValueData;

        //Provider lookup used to lookup provider information by id.
        const providerLookup = selectProvidersData(getState());

        //Determine if the current user is an attesting hygienist
        const isAttestingHygienist = userIdentity?.id ? !!providerLookup[userIdentity.id]?.isAttestingHygienist : false;

        //Must be an attesting hygienist to set the billing provider id.
        const _billingProviderId = isAttestingHygienist ? billingProviderId : undefined;

        //User identity of the above billing provider
        const billingProviderIdentity = _billingProviderId ? userIdentitiesData[_billingProviderId] : undefined;

        const signatureType =
            encounter?.status === EncounterStatus.AmendRequired || encounter?.status === EncounterStatus.CorrectionAmend
                ? ChartNoteSignatureType.Amend
                : ChartNoteSignatureType.Attest;

        const newSignature: AttestSignature = makeClinicalSignature({
            signatureTextBuilder: attestationTextBuilder,
            userIdentity,
            encounterStatus: encounter?.status,
            signatureType,
            isAttestingHygienist,
            billingProviderIdentity,
        });

        const data: ClinicalNoteData = makeClinicalNoteData(newSignature, currentValue, _billingProviderId);
        const updatedClinicalNote: Partial<ClinicalNote> = { attestationDate: new Date().toISOString(), data };

        dispatch(setPartialClinicalNote(updatedClinicalNote));

        const latestChartNote = getState().chartNotes.currentClinicalNote;
        if (latestChartNote) {
            try {
                await dispatch(upsertChartNote({ tenantId, patientId, chartNote: latestChartNote })).unwrap();

                const isSignalRConnected = selectSignalRConnectionStatus(getState()) === HubConnectionState.Connected;
                if (!isSignalRConnected && encounter) {
                    await dispatch(getPatientEncounterWithTasks({ tenantId, patientId, encounterId: encounter.id })).unwrap();
                }
                // Backend should handle updating the Encounter's providerId and Status change when chartNote Changes
                // We used to set this here, so we didn't have to worry about the status not being updated to attested...
                // dispatch(setCurrentEncounterDataPropAndSave('status', EncounterStatus.Attested, tenantId, patientId));
            } catch (err) {
                console.log(err);
            }
        }
    };

export const readyForProviderClick =
    (tenantId: string, patientId: string, supervisingProviderId?: string): AppThunk<void> =>
    async (dispatch, getState) => {
        const encounter = getState().encounter.patientEncounter;

        const identity = getState().account.data?.identity;
        const currentValue = getState().chartNotes.currentClinicalNote?.data as ClinicalNoteData | undefined;

        if (supervisingProviderId) await dispatch(getMissingUserIdentities({ tenantId, userIds: [supervisingProviderId] }));

        const userIdentitiesData = selectUserIdentitiesData(getState());
        const supervisingProviderIdentity = supervisingProviderId ? userIdentitiesData[supervisingProviderId] : undefined;

        const signatureType = ChartNoteSignatureType.Sign;

        const newSignature: AttestSignature = makeClinicalSignature({
            signatureTextBuilder: providerTextBuilder,
            userIdentity: identity,
            encounterStatus: encounter?.status,
            signatureType,
            supervisingProviderIdentity,
        });

        const data: ClinicalNoteData = makeClinicalNoteData(newSignature, currentValue, undefined, supervisingProviderId);

        dispatch(setClincalNoteData({ path: 'data', value: data }));

        const latestChartNote = getState().chartNotes.currentClinicalNote;

        if (latestChartNote) {
            try {
                await dispatch(upsertChartNote({ tenantId, patientId, chartNote: latestChartNote })).unwrap();
            } catch (err) {
                console.error(err);
            }
        }
    };

type SignatureTextBuilderParams = {
    userDisplayName: string;
    encounterStatus: EncounterStatus | undefined;
    isAttestingHygienist?: boolean | undefined;
    billingProviderDisplayName?: string;
    attendingProviderDisplayName?: string;
};

type MakeClinicalNoteSignatureParams = {
    signatureTextBuilder: (params: SignatureTextBuilderParams) => string;
    userIdentity?: IUserIdentity;
    encounterStatus?: EncounterStatus;
    signatureType?: ChartNoteSignatureType;
    isAttestingHygienist?: boolean;
    billingProviderIdentity?: IUserIdentity;
    supervisingProviderIdentity?: IUserIdentity;
};

function makeClinicalSignature({
    signatureTextBuilder,
    signatureType,
    encounterStatus,
    userIdentity,
    isAttestingHygienist,
    billingProviderIdentity,
    supervisingProviderIdentity,
}: MakeClinicalNoteSignatureParams): AttestSignature {
    const userDisplayName = createDisplayNameFromUserIdentity(userIdentity);
    const billingProviderDisplayName = createDisplayNameFromUserIdentity(billingProviderIdentity);
    const attendingProviderDisplayName = createDisplayNameFromUserIdentity(supervisingProviderIdentity);

    const signature = signatureTextBuilder({
        userDisplayName,
        encounterStatus,
        isAttestingHygienist,
        billingProviderDisplayName,
        attendingProviderDisplayName,
    });

    return {
        treatingProviderId: userIdentity?.id,
        signature,
        date: new Date().toISOString(),
        signatureType,
    };
}

function createDisplayNameFromUserIdentity(identity: IUserIdentity | undefined) {
    if (!identity) return '';
    return `${identity?.firstName} ${identity?.lastName}${
        identity?.professionalSuffix ? `, ${identity?.professionalSuffix}` : ''
    }`;
}

function makeClinicalNoteData(
    signature: AttestSignature,
    data?: ClinicalNoteData,
    billingProviderId?: string,
    supervisingProviderId?: string,
): ClinicalNoteData {
    return {
        ...data,
        value: data?.value || '',
        signatures: data?.signatures?.length ? [...data.signatures, signature] : [signature],
        billingProviderId: (billingProviderId ? billingProviderId : data?.billingProviderId) || '',
        supervisingProviderId: (supervisingProviderId ? supervisingProviderId : data?.supervisingProviderId) || '',
    };
}
