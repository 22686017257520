import { createAsyncThunk, Dictionary } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import { IEncounterReason } from 'api/models/encounter.model';

export const getAllEncounterReasons = createAsyncThunk<
    Dictionary<IEncounterReason>,
    { tenantId: string },
    { rejectValue: string }
>('encounters/getAllEncounterReasons', async ({ tenantId }) => {
    const request = await dentalApi.getEncounterReasons(tenantId);
    return request.data;
});

export const updateEncounter = createAsyncThunk<IEncounterReason, { tenantId: string; model: IEncounterReason }>(
    'payers/updateEncounter',
    async ({ tenantId, model }) => {
        const request = await dentalApi.updateEncounterReason(tenantId, model);
        return request.data;
    },
);
