import { DefaultButton, IconButton, MessageBar, PrimaryButton, Stack, TooltipHost, useTheme } from '@fluentui/react';
import { WeekDays } from 'api/models/provider.model';
import { IOperatoryPeriod, IOperatoryRange, OperatoryTimetable, TimeTableRange } from 'api/models/Scheduling/operatory.model';
import { Field, SubSection, TModal } from 'components';
import { map } from 'lodash';
import { useEffect, useState } from 'react';
import EditTimetables from './EditTimetables';
import { classicDateOnly } from 'utils/dateOnly';
import convertDashedDateString from 'utils/convertDateStringToLocal';
import { useValidation } from 'hooks';
import { getValidationError } from 'hooks/useValidation';

export const toCapitalize = (value: string): string => `${value.charAt(0).toUpperCase()}${value.substring(1)}`;

type Props = {
    period?: IOperatoryPeriod;
    setPeriod: (period?: IOperatoryPeriod) => void;
    onSave: () => void;
};

export default function EditOperatoryPeriod({ period, setPeriod, onSave }: Props): JSX.Element | null {
    const theme = useTheme();
    const [hasConflictError, setHasConflictError] = useState<boolean>(false);
    //NOTE: Probably should create better way to do this type of validation using the useValidation hook instead of relying on internal date field validation
    const [startDateRangeError, setStartDateRangeError] = useState<boolean>(false);
    const [endDateRangeError, setEndDateRangeError] = useState<boolean>(false);

    const startDateChangeHandler = (ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string | undefined) => {
        if (value && period) {
            setPeriod({ ...period, startDate: classicDateOnly(value, 'yyyy-MM-dd') });
        }
    };

    const endDateChangeHandler = (ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string | undefined) => {
        if (value && period) {
            setPeriod({ ...period, endDate: classicDateOnly(value, 'yyyy-MM-dd') });
        }
    };

    const setTimetables = (timetables: Record<WeekDays, TimeTableRange[]>) => {
        if (period && timetables) setPeriod({ ...period, timetables });
    };

    const _onSave = () => {
        if (!hasConflictError && !startDateRangeError && !endDateRangeError) {
            onSave();
        }
    };

    const [errors, submit, cleanupValidationErrors] = useValidation(
        [
            {
                fieldName: 'Start Date',
                value: period?.startDate,
                validation: ['required'],
            },
            {
                fieldName: 'End Date',
                value: period?.endDate,
                validation: ['required'],
            },
        ],
        _onSave,
    );

    useEffect(() => {
        return () => {
            cleanupValidationErrors();
        };
    }, []);

    const onAddRange = (weekday: WeekDays) => {
        const timetablesClone = { ...period?.timetables };
        if (timetablesClone) {
            const timetablesLength = timetablesClone[weekday] ? (timetablesClone[weekday] as TimeTableRange[]).length : 0;

            const range = timetablesClone[weekday] && (timetablesClone[weekday] ?? [])[timetablesLength - 1]; //last range in array
            const startTime = timetablesClone[weekday] && timetablesLength ? range?.endTime : '08:00';
            const newRange: IOperatoryRange = {
                startTime: startTime ?? '08:00',
                endTime: '18:00',
            };

            timetablesClone[weekday] = timetablesClone[weekday]
                ? [...(timetablesClone[weekday] as IOperatoryRange[]), newRange]
                : [newRange];
            setTimetables(timetablesClone as OperatoryTimetable);
        }
    };

    const startDate = convertDashedDateString(period?.startDate ?? '');
    const endDate = convertDashedDateString(period?.endDate ?? '');

    return (
        <TModal
            title={'Manage Operatory Hours'}
            modalProps={{ isOpen: !!period, isBlocking: true, onDismiss: () => setPeriod(undefined) }}
        >
            <Stack tokens={{ childrenGap: 10, padding: 20 }}>
                <Stack verticalAlign={'center'} horizontal tokens={{ childrenGap: 10 }}>
                    <Stack.Item>
                        <Field.Date
                            required
                            label="Start Date"
                            placeholder="Select a date..."
                            value={startDate}
                            onChange={startDateChangeHandler}
                            isReasonable
                            hasDatePicker
                            maxReasonableDate={endDate ? new Date(endDate) : undefined}
                            maxReasonableErrorMessage="Start date must be less than the End Date"
                            errorMessage={getValidationError(errors, 'Start Date') ? 'Start Date is required.' : undefined}
                            handleError={(error) => {
                                setStartDateRangeError(!!error);
                            }}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <Field.Date
                            required
                            label="End Date"
                            placeholder="Select a date..."
                            value={endDate}
                            onChange={endDateChangeHandler}
                            isReasonable
                            hasDatePicker
                            minReasonableDate={startDate ? new Date(startDate) : undefined}
                            maxReasonableErrorMessage="End Date must be greater than the Start Date"
                            errorMessage={getValidationError(errors, 'End Date') ? 'End Date is required.' : undefined}
                            handleError={(error) => {
                                setEndDateRangeError(!!error);
                            }}
                        />
                    </Stack.Item>
                </Stack>

                <Stack tokens={{ childrenGap: 5 }} horizontal wrap>
                    {map(WeekDays, (day) => (
                        <SubSection
                            key={day}
                            title={toCapitalize(day)}
                            style={{ border: `1px solid ${theme.palette.neutralLighter}`, padding: 5, borderRadius: 2 }}
                            headingEndContent={
                                <Stack horizontal>
                                    <Stack.Item>
                                        <TooltipHost content="Add Range">
                                            <IconButton iconProps={{ iconName: 'Add' }} onClick={() => onAddRange(day)} />
                                        </TooltipHost>
                                    </Stack.Item>
                                </Stack>
                            }
                        >
                            {period?.timetables && period?.timetables[day]?.length ? (
                                <EditTimetables
                                    day={day}
                                    timetables={period?.timetables}
                                    setTimetables={setTimetables}
                                    setHasConflictError={setHasConflictError}
                                />
                            ) : (
                                <MessageBar>
                                    No hours have been added for {`${day[0].toUpperCase()}${day.slice(1, day.length)}`}.
                                </MessageBar>
                            )}
                        </SubSection>
                    ))}
                </Stack>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 10 }} style={{ padding: 24 }}>
                <PrimaryButton text="Save" title="Save" onClick={submit} />
                <DefaultButton text="Cancel" title="Cancel" onClick={() => setPeriod(undefined)} />
            </Stack>
        </TModal>
    );
}
