import ProblemsDetailsList from './ProblemsDetailsList';

import ProblemsReviewed from './ProblemsReviewed';
import { Section } from 'components';
import { Stack, Text } from '@fluentui/react';

function Problems(): JSX.Element {
    return (
        <Section
            heading={
                <Stack tokens={{ childrenGap: 10 }} horizontal grow>
                    <Text variant="xLarge">Problems</Text>
                </Stack>
            }
            headingRightContent={<ProblemsReviewed />}
        >
            <ProblemsDetailsList />
        </Section>
    );
}

export default Problems;
