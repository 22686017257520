import ProcedureCodes from './procedureCodes';

export interface ISurfaceProcedureCodeLookup {
    code: ProcedureCodes;
    surfaceCount: number;
    orMore?: boolean;
}

export const surfaceProcedureCodeLookup: ISurfaceProcedureCodeLookup[][] = [
    // Onlay
    [
        { code: ProcedureCodes.D2542, surfaceCount: 2 },
        { code: ProcedureCodes.D2543, surfaceCount: 3 },
        { code: ProcedureCodes.D2544, surfaceCount: 4, orMore: true },
    ],
    [
        { code: ProcedureCodes.D2642, surfaceCount: 2 },
        { code: ProcedureCodes.D2643, surfaceCount: 3 },
        { code: ProcedureCodes.D2644, surfaceCount: 4, orMore: true },
    ],
    [
        { code: ProcedureCodes.D2662, surfaceCount: 2 },
        { code: ProcedureCodes.D2663, surfaceCount: 3 },
        { code: ProcedureCodes.D2664, surfaceCount: 4, orMore: true },
    ],
    // Amalgam Codes
    [
        { code: ProcedureCodes.D2140, surfaceCount: 1 },
        { code: ProcedureCodes.D2150, surfaceCount: 2 },
        { code: ProcedureCodes.D2160, surfaceCount: 3 },
        { code: ProcedureCodes.D2161, surfaceCount: 4, orMore: true },
    ],
    // Anterior Composite Codes
    [
        { code: ProcedureCodes.D2330, surfaceCount: 1 },
        { code: ProcedureCodes.D2331, surfaceCount: 2 },
        { code: ProcedureCodes.D2332, surfaceCount: 3 },
        { code: ProcedureCodes.D2335, surfaceCount: 4, orMore: true },
    ],
    // Posterior Composite Codes
    [
        { code: ProcedureCodes.D2391, surfaceCount: 1 },
        { code: ProcedureCodes.D2392, surfaceCount: 2 },
        { code: ProcedureCodes.D2393, surfaceCount: 3 },
        { code: ProcedureCodes.D2394, surfaceCount: 4, orMore: true },
    ],
    [
        { code: ProcedureCodes.D2410, surfaceCount: 1 },
        { code: ProcedureCodes.D2420, surfaceCount: 2 },
        { code: ProcedureCodes.D2430, surfaceCount: 3, orMore: true },
    ],
    [
        { code: ProcedureCodes.D2510, surfaceCount: 1 },
        { code: ProcedureCodes.D2520, surfaceCount: 2 },
        { code: ProcedureCodes.D2530, surfaceCount: 3, orMore: true },
    ],
    [
        { code: ProcedureCodes.D2610, surfaceCount: 1 },
        { code: ProcedureCodes.D2620, surfaceCount: 2 },
        { code: ProcedureCodes.D2630, surfaceCount: 3, orMore: true },
    ],
    [
        { code: ProcedureCodes.D2650, surfaceCount: 1 },
        { code: ProcedureCodes.D2651, surfaceCount: 2 },
        { code: ProcedureCodes.D2652, surfaceCount: 3, orMore: true },
    ],
    // Other
    [
        { code: ProcedureCodes.D6600, surfaceCount: 2 },
        { code: ProcedureCodes.D6601, surfaceCount: 3, orMore: true },
    ],
    [
        { code: ProcedureCodes.D6602, surfaceCount: 2 },
        { code: ProcedureCodes.D6603, surfaceCount: 3, orMore: true },
    ],
    [
        { code: ProcedureCodes.D6604, surfaceCount: 2 },
        { code: ProcedureCodes.D6605, surfaceCount: 3, orMore: true },
    ],
    [
        { code: ProcedureCodes.D6606, surfaceCount: 2 },
        { code: ProcedureCodes.D6607, surfaceCount: 3, orMore: true },
    ],
    [
        { code: ProcedureCodes.D6608, surfaceCount: 2 },
        { code: ProcedureCodes.D6609, surfaceCount: 3, orMore: true },
    ],
    [
        { code: ProcedureCodes.D6610, surfaceCount: 2 },
        { code: ProcedureCodes.D6611, surfaceCount: 3, orMore: true },
    ],
    [
        { code: ProcedureCodes.D6612, surfaceCount: 2 },
        { code: ProcedureCodes.D6613, surfaceCount: 3, orMore: true },
    ],
    [
        { code: ProcedureCodes.D6614, surfaceCount: 2 },
        { code: ProcedureCodes.D6615, surfaceCount: 3, orMore: true },
    ],
];
