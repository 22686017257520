import dentalApi from 'api/dental.api';
import IPatient from 'api/models/patient.model';
import IPatientAppointment from 'api/models/Scheduling/patientAppointment.model';
import { useSelector, useTenantId } from 'hooks';
import { useEffect, useState } from 'react';
import { selectAppointmentToCancel } from 'state/slices/scheduling/appointment-to-cancel/appointment-to-cancel.selectors';
import PatientDetailsCard from '../PatientDetailsCard';

const getAppointmentPatient = async (tenantId: string, appointment: IPatientAppointment) => {
    const { data: patient } = await dentalApi.getPatient(tenantId, appointment.patientId);
    return patient;
};

export default function CancelAppointmentPatientCard({ onDismiss }: { onDismiss: () => void }) {
    const appointment = useSelector(selectAppointmentToCancel);

    const tenantId = useTenantId();

    const [patient, setPatient] = useState<IPatient>();

    useEffect(() => {
        if (appointment) {
            getAppointmentPatient(tenantId, appointment).then((p) => setPatient(p));
        } else {
            setPatient(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appointment?.id]);

    return <PatientDetailsCard onPageNavigationClick={onDismiss} showClinicalAlerts patient={patient} />;
}
