import { Dictionary } from '@reduxjs/toolkit';
import { IPatientEncounter } from 'api/models/encounter.model';
import IPatient from 'api/models/patient.model';
import IBlockAppointment from 'api/models/Scheduling/blockAppointment.model';
import IPatientAppointment from 'api/models/Scheduling/patientAppointment.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { IUpcomingTreatmentPlanPhaseData } from '../scheduling.selectors';

const schedulingInitialState: ISelectedAppointmentState = {
    data: undefined,
    loading: LoadingStatus.Idle,
    loadingEncounter: LoadingStatus.Idle,
    selectedPhases: {},
};

export interface IPreviousAppointmentData {
    patient: IPatient;
    data: IPatientAppointment;
    date: Date;
}
export interface ISelectedAppointmentState {
    data?: IPatientAppointment | IBlockAppointment;
    loading: LoadingStatus;
    patient?: IPatient;
    encounter?: IPatientEncounter;
    loadingEncounter: LoadingStatus;
    previousAppointmentData?: IPreviousAppointmentData;
    //selected phases dictionary based of the treatment plan id.
    selectedPhases: Dictionary<IUpcomingTreatmentPlanPhaseData[]>;
}

export default schedulingInitialState;
