import { useEffect, useState } from 'react';
import { DefaultButton, Panel, PanelType, PrimaryButton, Stack } from '@fluentui/react';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { usePatientId } from 'hooks';
import { ValidationBar } from 'components';
import { IValidationError } from 'hooks/useValidation';
import ProblemsEditProperties from '../Problems/ProblemsEditProperties';
import useOverview from 'hooks/store/useOverview';
import MedicationsEditProperties from '../Medications/MedicationsEditProperties';
import VitalsEditProperties from '../Vitals/VitalsEditProperties';
import ImmunizationsEditProperties from '../Immunizations/ImmunizationsEditProperties';
import PMHEditProperties from '../PastMedicalHistory/PMHEditProperties';
import useEncounterId from 'hooks/useEncounterId';

type CommonListParameters = {
    type: string;
    displayName: string;
    panelType?: PanelType;
};

function SharedEditPanel(props: CommonListParameters) {
    const { displayName, type, panelType } = props;
    const { selectedOverviewItem, cleanupSelectedOverviewItem, createOverviewItem, updateOverviewItem } = useOverview();
    const patientId = usePatientId() as string;
    const encounterId = useEncounterId();

    const [validationErrors] = useState<IValidationError[]>([]);
    const isNewItem = selectedOverviewItem.isNewItem;

    useEffect(() => {
        if (selectedOverviewItem!.savingStatus === LoadingStatus.Completed) {
            cleanupSelectedOverviewItem();
        }
    }, [selectedOverviewItem, cleanupSelectedOverviewItem]);

    const onSave = () => {
        if (isNewItem) {
            const item = {
                ...selectedOverviewItem!.item,
                id: patientId,
                encounterId,
            };
            createOverviewItem(item, type);
        } else {
            const item = {
                ...selectedOverviewItem!.item,
                encounterId,
            };
            updateOverviewItem(item, type);
        }
    };

    const isSaving = selectedOverviewItem!.savingStatus === LoadingStatus.Pending;
    const hasError = validationErrors.length ? true : false;
    const onRenderFooterContent = () => (
        <Stack horizontal tokens={{ childrenGap: 10 }}>
            <PrimaryButton text="Save" title="Save" onClick={onSave} disabled={hasError || isSaving} />
            <DefaultButton text="Cancel" title="Cancel" onClick={() => cleanupSelectedOverviewItem()} disabled={isSaving} />
        </Stack>
    );

    const panelText = isNewItem
        ? `Create ${displayName}`
        : selectedOverviewItem.key
        ? `Edit ${displayName}`
        : `Add ${displayName}`;

    const editProperties: { [key: string]: () => JSX.Element } = {
        Problems: ProblemsEditProperties,
        // Allergies: AllergiesEditProperties,
        Medications: MedicationsEditProperties,
        Vitals: VitalsEditProperties,
        PastMedicalHistory: PMHEditProperties,
        Immunizations: ImmunizationsEditProperties,
    };

    const componentToRender = (): JSX.Element | null => {
        if (selectedOverviewItem.item) {
            const Comp = editProperties[selectedOverviewItem.type];
            if (Comp) return <Comp />;
            else return null;
        }
        return null;
    };

    return (
        <Panel
            headerText={panelText}
            styles={{
                content: { overflowY: 'auto', overflowX: 'hidden', flex: 1, position: 'relative' },
                root: { overflow: 'hidden' },
                scrollableContent: { overflow: 'hidden', display: 'flex', flexDirection: 'column' },
            }}
            type={panelType ? panelType : PanelType.medium}
            customWidth={'800px'}
            isFooterAtBottom={true}
            isOpen={true}
            onDismiss={() => cleanupSelectedOverviewItem()}
            onRenderFooterContent={onRenderFooterContent}
        >
            <Stack tokens={{ childrenGap: 10 }}>
                <ValidationBar errors={validationErrors} />
                {componentToRender()}
            </Stack>
        </Panel>
    );
}

export default SharedEditPanel;
