import { ComboBox, IComboBox, IComboBoxOption, IconButton, Stack, Text, Toggle, TooltipHost, useTheme } from '@fluentui/react';
import { Field } from 'components';
import { RelativeDateType } from 'components/Field/RelativeDateRangeField';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectClaimsHistoryActiveFiltersCount,
    selectClaimsHistoryEncounterUniqueProvidersAsOptions,
    selectClaimsHistoryFilters,
    selectShowClaimsHistoryFilters,
} from 'state/slices/ledger/claim-history/claim-history.selectors';
import { LedgerClaimsHistoryFilters } from 'state/slices/ledger/claim-history/claim-history.state';
import {
    clearAllClaimsHistoryFilters,
    setClaimEncounterBillingProvidersFilter,
    setClaimEncounterDateRangeFilters,
    setClaimHistoryDateFilters,
    toggleClaimEncounterInsuranceBalanceGreaterThanZeroFilter,
    toggleShowClaimHistoryFilters,
} from 'state/slices/ledger/ledger.slice';
import { classicDateOnly } from 'utils/dateOnly';

export default function ClaimHistoryFilters() {
    const dispatch = useDispatch();
    const { palette } = useTheme();

    const { insuranceBalanceGreaterThanZero, relativeDateType, billingProviders, endDate, startDate } =
        useSelector(selectClaimsHistoryFilters);

    const showFilters = useSelector(selectShowClaimsHistoryFilters);
    const providerOptions = useSelector(selectClaimsHistoryEncounterUniqueProvidersAsOptions);
    const filtersCount = useSelector(selectClaimsHistoryActiveFiltersCount);

    const clearAllFilters = () => {
        dispatch(clearAllClaimsHistoryFilters());
    };

    const toggleShowClaimsHistoryFilters = () => {
        dispatch(toggleShowClaimHistoryFilters());
    };

    useEffect(() => {
        return () => {
            clearAllFilters();
            toggleShowClaimsHistoryFilters();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!showFilters) return null;

    const setBillingProviders = (ev: React.FormEvent<IComboBox>, option?: IComboBoxOption | undefined) => {
        if (option) dispatch(setClaimEncounterBillingProvidersFilter(option.key as string));
    };

    const toggleInsuranceBalanceGreaterThanZero = () => {
        dispatch(toggleClaimEncounterInsuranceBalanceGreaterThanZeroFilter());
    };

    const setDateRange = ({ startDate, endDate, value }: { startDate?: string; endDate?: string; value: RelativeDateType }) => {
        dispatch(setClaimEncounterDateRangeFilters({ startDate, endDate, relativeDateType: value }));
    };

    const setDate = ({
        path,
        value,
    }: {
        path: keyof Pick<LedgerClaimsHistoryFilters, 'startDate' | 'endDate'>;
        value: string | undefined;
    }) => {
        dispatch(setClaimHistoryDateFilters({ path, value }));
    };

    return (
        <Stack
            className="ms-motion-slideUpIn"
            horizontal
            tokens={{ childrenGap: 10 }}
            styles={{ root: { backgroundColor: palette.neutralLighterAlt, padding: 5, borderRadius: 5 } }}
        >
            <Stack tokens={{ childrenGap: 10 }} verticalAlign="end" horizontal wrap>
                <Stack.Item>
                    <Toggle
                        onClick={toggleInsuranceBalanceGreaterThanZero}
                        checked={insuranceBalanceGreaterThanZero}
                        inlineLabel
                        label="Insurance Balance > 0"
                        styles={{ root: { margin: 0 } }}
                    ></Toggle>
                </Stack.Item>
                <Stack.Item>
                    <Field.Date
                        onChange={(ev, value) => setDate({ path: 'startDate', value })}
                        value={startDate}
                        label="DOS From"
                        isReasonable
                        maxReasonableErrorMessage="DOS From date must be before DOS Through date."
                        maxReasonableDate={endDate ? new Date(classicDateOnly(endDate)) : undefined}
                        hasDatePicker
                    ></Field.Date>
                </Stack.Item>
                <Stack.Item>
                    <Field.Date
                        onChange={(ev, value) => setDate({ path: 'endDate', value })}
                        value={endDate}
                        label="DOS Through"
                        isReasonable
                        minReasonableErrorMessage="DOS Through date must be after DOS From date."
                        minReasonableDate={startDate ? new Date(classicDateOnly(startDate)) : undefined}
                        hasDatePicker
                    ></Field.Date>
                </Stack.Item>
                <Stack.Item>
                    <Field.RelativeDateRange
                        label="DOS Range"
                        placeholder="(Select)"
                        styles={{ root: { minWidth: 170 } }}
                        selectedKey={relativeDateType ?? ''}
                        onChange={(ev, startDate, endDate, value) => setDateRange({ startDate, endDate, value })}
                    />
                </Stack.Item>
                <Stack.Item>
                    <ComboBox
                        placeholder="(Select)"
                        multiSelect
                        label="Billing Providers"
                        options={providerOptions}
                        selectedKey={billingProviders ?? []}
                        onChange={setBillingProviders}
                        onRenderOption={(props) => <Text>{props?.text ?? ''}</Text>}
                    />
                </Stack.Item>

                <TooltipHost delay={0} content="Clear all filters">
                    <IconButton onClick={clearAllFilters} iconProps={{ iconName: 'Cancel' }} disabled={filtersCount === 0} />
                </TooltipHost>
            </Stack>
        </Stack>
    );
}
