import { Graphics as IGraphics } from 'pixi.js-legacy';
import { Graphics } from '@inlet/react-pixi/legacy';
import { useCallback } from 'react';
import { SPRITE_CROWN_HEIGHT, SPRITE_ROOT_HEIGHT, SPRITE_WIDTH, TOOTH_CONTAINER_WIDTH, TOOTH_START } from '../../perio-settings';

function PerioTooth({ position }: { position?: number }): JSX.Element | null {
    const draw = useCallback((g: IGraphics) => {
        g.clear();
        g.beginFill(0xf5dca2);
        g.drawRect(0, 0, SPRITE_WIDTH, SPRITE_ROOT_HEIGHT);
        g.endFill();
        g.beginFill(0xcccccc);
        g.drawRect(0, -SPRITE_CROWN_HEIGHT, SPRITE_WIDTH, SPRITE_CROWN_HEIGHT);
        g.endFill();
    }, []);
    if (position === undefined) return null;

    // Mandibular if position greater than 16, otherwise maxillary
    const calculatedPosition = position > 16 ? Math.abs(position - 32) : position - 1;
    return <Graphics draw={draw} y={0} x={calculatedPosition * TOOTH_CONTAINER_WIDTH + TOOTH_START} />;
}

export default PerioTooth;
