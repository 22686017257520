import { IColumn, Link, MessageBar, SelectionMode, Spinner, Text } from '@fluentui/react';
import { IImmunization } from 'api/models/patient-immunization.model';
import useOverview from 'hooks/store/useOverview';
import { LoadingStatus } from 'interfaces/loading-statuses';
import SharedDetailsList from '../Shared/SharedDetailsList';
import { format } from 'date-fns';
import { classicDateOnly } from 'utils/dateOnly';
import { SortableDetailsList } from 'components';

function ImmunizationsDetailsList() {
    const { immunizations, immunizationsList, immunizationsLoading, setSelectedOverviewItem } = useOverview();

    const getItem = (item: IImmunization | undefined) => ({
        item: immunizations,
        savingStatus: LoadingStatus.Idle,
        isNewItem: false,
        key: item?.id,
        displayName: 'Vaccine',
        type: 'Vaccines',
    });
    const columns: IColumn[] = [
        {
            key: 'vaccineDisplay',
            minWidth: 150,
            maxWidth: 600,
            name: 'Name',
            fieldName: 'vaccineDisplay',
            onRender: (item?: IImmunization) => <Text variant="mediumPlus">{item?.vaccineDisplay}</Text>,
        },
        {
            key: 'date',
            minWidth: 150,
            name: 'Date',
            fieldName: 'date',
            onRender: (item?: IImmunization) => <Text variant="mediumPlus">{item?.date ? classicDateOnly(item.date) : 'N/A'}</Text>,
        },
    ];

    return (
        <>
            {immunizations?.isReviewed && immunizations?.reviewedDate ? (
                <MessageBar>
                    Reviewed with Patient by {immunizations?.reviewedBy} on {format(new Date(immunizations?.reviewedDate), 'P')}{' '}
                    at {format(new Date(immunizations?.reviewedDate), 'p')}
                </MessageBar>
            ) : null}

            {immunizationsLoading === LoadingStatus.Pending ? (
                <Spinner label="Loading vaccines list..." labelPosition="right" />
            ) : immunizationsList?.length && immunizationsLoading === LoadingStatus.Completed ? (
                <>
                    <SortableDetailsList
                        selectionMode={SelectionMode.none}
                        sortOnMount={true}
                        sortColumns={['date']}
                        initialSortDirection={['desc']}
                        columns={columns}
                        items={immunizationsList || []}
                    />
                </>
            ) : !immunizationsList?.length && immunizationsLoading === LoadingStatus.Completed ? (
                <MessageBar>No vaccines have been added.</MessageBar>
            ) : null}
        </>
    );
}

export default ImmunizationsDetailsList;
