import IWorkListView from 'api/models/worklist-view.model';
import { worklistItemCanBeApprovedErrors } from './canWorklistItemBeApproved';

export default function ApproveWorklistItemMessage({ item }: { item: IWorkListView | undefined }) {
    const errorMessages = worklistItemCanBeApprovedErrors(item);
    if (!errorMessages.length) return null;
    return (
        <>
            <span>
                Please navigate to athenaOne, fill out the missing information, and then return to this page to approve/re-approve
                the encounter:
            </span>
            {errorMessages.map((message, index) => (
                <li key={`${index}-${item?.id}-missing-pt-info`}>{message}</li>
            ))}
        </>
    );
}
