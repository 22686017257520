import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useSelector } from 'hooks';
import { RouteParams } from 'interfaces/route-params';
import { useParams } from 'react-router-dom';
import { getChartProcedures } from 'state/slices/charting/procedures/procedures.actions';
import {
    pendingProcedures,
    selectCurrentTreatmentPlanSignedOrSigning,
} from 'state/slices/charting/treatmentPlans/treatmentPlans.selectors';
import EditBillingProcedureTable from './EditBillingProcedureTable';

export default function PendingProceduresTable(): JSX.Element {
    const dispatch = useDispatch();
    const { tenantId, patientId } = useParams<RouteParams>();

    const isSigned = useSelector(selectCurrentTreatmentPlanSignedOrSigning);
    const _pendingProcedures = useSelector(pendingProcedures);

    useEffect(() => {
        if (!isSigned) {
            dispatch(getChartProcedures({ tenantId, patientId }));
        }
    }, [dispatch, isSigned, patientId, tenantId]);

    return <EditBillingProcedureTable procedures={_pendingProcedures} />;
}
