import {
    SelectionMode,
    Selection,
    ISelection,
    IObjectWithKey,
    Stack,
    Separator,
    TooltipHost,
    IDetailsRowProps,
} from '@fluentui/react';
import { ExpandableDetailsList, SortableDetailsList } from 'components';
import useUserIdentities from 'hooks/store/useUserIdentities';
import { useEffect, useMemo } from 'react';
import { TreatmentPlanCreditPhaseViewProcedureGroup } from 'state/slices/ledger/treatment-plan-credit-and-uac/treatment-plan-credit-and-uac.selectors';
import { IUpcomingTreatmentPlanPhaseData } from 'state/slices/scheduling/scheduling.selectors';
import { usdCurrencyFormatter } from 'utils';
import { classicDateOnly } from 'utils/dateOnly';

type Props = {
    treatmentPlanId?: string;
    treatingProviderIds?: string[];
    hygienistIds?: string[];
    selectedTreatmentPlanPhases?: IUpcomingTreatmentPlanPhaseData[];
    selectionMode?: SelectionMode;
    onItemsSelected?: (selectedPhases: IUpcomingTreatmentPlanPhaseData[], treatmentPlanId?: string) => void;
} & Omit<IDetailsRowProps, 'selectionMode' | 'selection' | 'columns' | 'itemIndex'>;

export default function UnscheduledTreatmentPlanPhaseRow(props: Props) {
    const { getMissingUserIdentities, userIdentitiesData } = useUserIdentities();
    const { hygienistIds, treatingProviderIds, selectedTreatmentPlanPhases, selectionMode, onItemsSelected, treatmentPlanId } =
        props;

    const items = props?.item?.phases ?? [];

    useEffect(() => {
        getMissingUserIdentities([...(hygienistIds ?? []), ...(treatingProviderIds ?? [])]);
    }, [treatingProviderIds, hygienistIds, getMissingUserIdentities]);

    const phaseSelection = useMemo(() => {
        return new Selection({
            onSelectionChanged: () => {
                const selectedPhases = _getPhaseSelectionDetails();
                if (onItemsSelected) onItemsSelected(selectedPhases, treatmentPlanId);
            },
            getKey: (item) => item.phaseId,
            items: selectedTreatmentPlanPhases,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function _getPhaseSelectionDetails() {
        return phaseSelection.getSelection() as IUpcomingTreatmentPlanPhaseData[];
    }

    useEffect(() => {
        if (selectedTreatmentPlanPhases?.length) {
            const keys = selectedTreatmentPlanPhases.map((phase) => phase.phaseId);
            keys.forEach((key) => {
                phaseSelection.setKeySelected(key, true, true);
            });
        }
    }, [selectedTreatmentPlanPhases]);

    return (
        <div style={{ paddingLeft: 5, paddingRight: 5 }}>
            <ExpandableDetailsList<IUpcomingTreatmentPlanPhaseData>
                compact
                items={items}
                selectionMode={selectionMode ?? SelectionMode.single}
                selection={phaseSelection as unknown as ISelection<IObjectWithKey>}
                sortOnMount
                sortColumns={['phase']}
                initialSortDirection={['asc']}
                onRenderExpandedRowContent={(props: IDetailsRowProps | undefined) => {
                    const item = props?.item as IUpcomingTreatmentPlanPhaseData;
                    if (!item.groupedProcedures) {
                        return null;
                    }

                    // Filter expandedItems to only include procedures of the current phase
                    const expandedItems = item.groupedProcedures.map((procedure) => ({
                        ...procedure,
                        phaseName: item.phaseName,
                        returnAppointment: item.returnAppointment,
                        preAuthStatus: item.preAuthStatus,
                        patientCost: item.patientCost,
                    }));

                    return (
                        <SortableDetailsList<TreatmentPlanCreditPhaseViewProcedureGroup>
                            compact
                            items={expandedItems}
                            selectionMode={SelectionMode.none}
                            columns={[
                                {
                                    key: 'procedureCode',
                                    name: 'Code',
                                    minWidth: 80,
                                    maxWidth: 100,
                                    isResizable: true,
                                    onRender: (item) => {
                                        if (item) {
                                            const count = item.count && item.count > 1 ? ` (${item.count})` : '';
                                            return (
                                                <span>
                                                    {item.procedureCode}
                                                    {count}
                                                </span>
                                            );
                                        }
                                    },
                                },
                                {
                                    key: 'procedureDescription',
                                    name: 'Description',
                                    minWidth: 200,
                                    maxWidth: 250,
                                    isResizable: true,
                                    onRender: (item) => (
                                        <TooltipHost content={item?.procedureDescription}>
                                            <span>{item?.procedureDescription}</span>
                                        </TooltipHost>
                                    ),
                                },
                                {
                                    key: 'treatingProvider',
                                    name: 'Treating Provider',
                                    minWidth: 110,
                                    isResizable: true,
                                    onRender: (item) => {
                                        const treatingProviderId = item?.treatingProviderId ?? '';
                                        const treatingProvider = userIdentitiesData[treatingProviderId]?.displayName;
                                        return <span>{treatingProvider}</span>;
                                    },
                                },
                            ]}
                        />
                    );
                }}
                columns={[
                    {
                        key: 'phase',
                        name: 'Phase',
                        minWidth: 50,
                        maxWidth: 80,
                        fieldName: 'phaseName',
                        onRender: (item) => <span>{item?.phaseName ?? ''}</span>,
                    },
                    {
                        key: 'returnDate',
                        name: 'Return Date',
                        minWidth: 50,
                        maxWidth: 80,
                        onRender: (item) => (
                            <span>
                                {item?.returnAppointment?.references?.returnDate
                                    ? classicDateOnly(item.returnAppointment.references.returnDate)
                                    : null}
                            </span>
                        ),
                    },
                    {
                        key: 'preAuthStatus',
                        name: 'Pre-Auth Status',
                        minWidth: 50,
                        maxWidth: 100,
                        fieldName: 'preAuthStatus',
                    },
                    {
                        key: 'patientCost',
                        name: 'Patient Cost',
                        minWidth: 50,
                        getValueKey: (item) => item?.patientCost?.toString() ?? '',
                        onRender: (item) => <span>{usdCurrencyFormatter.format(item?.patientCost ?? 0)}</span>,
                    },
                ]}
            />
        </div>
    );
}
