import { Stack, TextField } from '@fluentui/react';
import { VitalNameType } from 'api/models/patient-vital.model';
import { SubSection } from 'components';
import { useSelector } from 'hooks';
import { useDispatch } from 'react-redux';
import { updateReading } from 'state/slices/patient/patient.slice';
import { NonBPNameTypes } from 'state/slices/patient/vitals/vitals.reducers';

const RRVitalsProperties = (): JSX.Element => {
    const dispatch = useDispatch();
    const newVitals = useSelector((state) => state.patient.vitals.vitalPanelData);

    const respirationRate = newVitals[VitalNameType.RespirationRate];

    const handleOnChange = (vitalType: NonBPNameTypes, value?: string): void => {
        dispatch(updateReading({ vitalType, value: value ?? '' }));
    };

    return (
        <Stack tokens={{ childrenGap: 5 }}>
            <SubSection title="Respiratory Rate">
                <Stack tokens={{ childrenGap: 10 }}>
                    <Stack tokens={{ childrenGap: 10 }} horizontal verticalAlign="center" grow>
                        <Stack.Item>
                            <TextField
                                label=""
                                autoComplete="off"
                                value={respirationRate}
                                maxLength={2}
                                onChange={(ev, value) => handleOnChange(VitalNameType.RespirationRate, value)}
                            />
                        </Stack.Item>
                    </Stack>
                </Stack>
            </SubSection>
        </Stack>
    );
};

export default RRVitalsProperties;
