import { IconButton, TooltipHost, mergeStyleSets, useTheme } from '@fluentui/react';
import IWorkListEncounterView from 'api/models/worklist-encounter-view';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import { useDispatch, useSelector } from 'react-redux';
import { EncounterWorkListModalType } from 'state/slices/admin-huddle/worklists/encounter-worklists/encounter-worklists.state';
import {
    selectIsCurrentWorkListEditEncounterWorkList,
    selectWorkListReadOnly,
} from 'state/slices/admin-huddle/worklists/worklist.selectors';
import { setEncounterWorkListModalOpen } from 'state/slices/admin-huddle/worklists/worklist.slice';

const encounterCorrespondence: ISortableColumn<IWorkListEncounterView> = {
    key: 'encounterCorrespondence',
    name: '',
    minWidth: 25,
    maxWidth: 25,
    onRender: OnRenderEncounterCorrespondenceColumn,
};

export function getEncounterCorrespondenceButtonText(readOnly: boolean, isEditEncounterWorklist: boolean) {
    return readOnly
        ? 'View Correction Notes'
        : isEditEncounterWorklist
        ? 'View/Add Notes & Route Encounter'
        : 'Add Correction Notes/Send for Correction';
}

export function OnRenderEncounterCorrespondenceColumn(item?: IWorkListEncounterView) {
    const dispatch = useDispatch();
    const readOnly = useSelector(selectWorkListReadOnly);
    const isEditEncounterWorklist = useSelector(selectIsCurrentWorkListEditEncounterWorkList);

    const _openModal = () => {
        dispatch(
            setEncounterWorkListModalOpen({
                isOpen: true,
                type: EncounterWorkListModalType.CorrectionNote,
                editEncounter: item,
            }),
        );
    };

    return (
        <TooltipHost content={getEncounterCorrespondenceButtonText(readOnly, isEditEncounterWorklist)}>
            <IconButton
                onClick={(ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    _openModal();
                }}
                styles={{
                    root: {
                        height: 20,
                        width: 20,
                    },
                }}
                iconProps={{ iconName: readOnly ? 'EntryView' : 'AddNotes' }}
            />
        </TooltipHost>
    );
}

export default encounterCorrespondence;
