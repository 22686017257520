import { Dropdown, IDropdownOption, Stack } from '@fluentui/react';
import { ReportType } from 'api/models/embed-report.model';
import {
    FinancialSummaryDateGroup,
    FinancialSummaryGroup,
    FinancialSummaryReportQuery,
    lookupFinancialSummaryGroupDisplayName,
} from 'api/models/financial-summary-report-query.model';
import { Field } from 'components';
import { useSelector, useTenantId } from 'hooks';
import { getValidationError } from 'hooks/useValidation';
import { map, sortBy } from 'lodash';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import reportActionLookup from 'state/slices/reporting/reportActionLookup';
import { getTenantReportingGroups } from 'state/slices/reporting/reporting-groups/reporting-groups.actions';
import { reportingGroupOptions } from 'state/slices/reporting/reporting-groups/reporting-groups.selectors';
import { getAllLocationsOfCareForReports } from 'state/slices/reporting/reporting.actions';
import {
    selectLocationsOfCareFromReporting,
    selectReportProperties,
    selectReportingValidationErrors,
} from 'state/slices/reporting/reporting.selectors';
import {
    setFinanceSummaryDateGroup,
    setFinanceSummaryEndDate,
    setFinanceSummaryGroupCol,
    setFinanceSummaryStartDate,
    toggleProceduresFinancialSummaryId,
} from 'state/slices/reporting/reporting.slice';
import { proceduresOptions } from 'state/slices/tenant/procedures.slice';
import { selectTreatingProviderOptions } from 'state/slices/tenant/providers.slice';
import { selectTenantPayerOptions } from 'state/slices/tenant/tenant-payers.slice';
import { classicDateOnly } from 'utils/dateOnly';
import ReportWrapper from '../ReportWrapper';

export default function FinancialSummary() {
    const dispatch = useDispatch();
    const tenantId = useTenantId();

    const errors = useSelector(selectReportingValidationErrors);

    const locationsOfCare = useSelector(selectLocationsOfCareFromReporting);
    const _payerOptions = useSelector(selectTenantPayerOptions);
    const _procedureOptions = useSelector(proceduresOptions);
    const _providerOptions = useSelector(selectTreatingProviderOptions);
    const _groupByOptions = useSelector(reportingGroupOptions);

    const query = useSelector(selectReportProperties) as FinancialSummaryReportQuery | undefined;

    const viewReport = () => {
        if (query)
            dispatch(
                reportActionLookup[ReportType.FinancialSummary]({
                    tenantId,
                    ...query,
                }),
            );
    };

    useEffect(() => {
        dispatch(getAllLocationsOfCareForReports({ tenantId }));
        dispatch(getTenantReportingGroups({ tenantId }));
    }, []);

    const locationsOfCareOptions: IDropdownOption[] = locationsOfCare.map((loc) => ({
        key: loc.id,
        text: loc.displayName,
    }));

    const extraPayerOptions: IDropdownOption[] = [
        {
            key: '-1',
            text: 'Self Pay',
        },
        {
            key: '-2',
            text: 'Sliding Fee',
        },
    ];

    const payerOption = sortBy([..._payerOptions, ...extraPayerOptions], 'text');

    return (
        <ReportWrapper
            validationConfig={[
                {
                    fieldName: 'Date Group',
                    validation: ['required'],
                    value: query?.dateGroup,
                },
                {
                    fieldName: 'Start Date',
                    validation: ['required'],
                    value: query?.startDate,
                },
                {
                    fieldName: 'End Date',
                    validation: ['required'],
                    value: query?.endDate,
                },
            ]}
            onViewReport={viewReport}
        >
            <Stack tokens={{ childrenGap: 10 }} horizontal grow wrap>
                <Stack.Item>
                    <Dropdown
                        label="Date Group"
                        options={map(FinancialSummaryDateGroup, (type) => ({ key: type, text: type }))}
                        placeholder="(Select)"
                        selectedKey={query?.dateGroup}
                        required
                        errorMessage={getValidationError(errors, 'Date Group') ? 'Date group is required.' : undefined}
                        onChange={(ev, option) => {
                            if (option?.key) dispatch(setFinanceSummaryDateGroup(option.key as FinancialSummaryDateGroup));
                        }}
                        style={{ minWidth: 150 }}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.Date
                        label="Start Date"
                        required
                        value={query?.startDate ? classicDateOnly(query?.startDate, 'MM/dd/yyyy') : ''}
                        onChange={(ev, value) => {
                            const newDate = value ? classicDateOnly(value, 'yyyy-MM-dd') : undefined;
                            dispatch(setFinanceSummaryStartDate(newDate));
                        }}
                        hasDatePicker
                        errorMessage={getValidationError(errors, 'Start Date') ? 'Start date is required.' : undefined}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.Date
                        label="End Date"
                        required
                        value={query?.endDate ? classicDateOnly(query?.endDate, 'MM/dd/yyyy') : ''}
                        onChange={(ev, value) => {
                            const newDate = value ? classicDateOnly(value, 'yyyy-MM-dd') : undefined;
                            dispatch(setFinanceSummaryEndDate(newDate));
                        }}
                        isReasonable
                        disabled={!query?.startDate}
                        minReasonableErrorMessage="End date must be after start date."
                        minReasonableDate={query?.startDate ? new Date(classicDateOnly(query?.startDate)) : undefined}
                        hasDatePicker
                        errorMessage={getValidationError(errors, 'End Date') ? 'End date is required.' : undefined}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.SearchCombo
                        placeholder="(Select Locations of Care)"
                        label="Locations of Care"
                        multiSelect
                        style={{ minWidth: 300 }}
                        options={locationsOfCareOptions}
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(
                                    toggleProceduresFinancialSummaryId({ id: option.key as string, path: 'locationOfCareIds' }),
                                );
                        }}
                        selectedKey={query?.locationOfCareIds ? query?.locationOfCareIds : []}
                    />
                </Stack.Item>
            </Stack>
            <Stack tokens={{ childrenGap: 10 }} horizontal grow wrap>
                <Stack.Item>
                    <Field.SearchCombo
                        placeholder="(Select Provider)"
                        label="Providers"
                        multiSelect
                        style={{ minWidth: 300 }}
                        options={_providerOptions}
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(
                                    toggleProceduresFinancialSummaryId({ id: option.key as string, path: 'treatingProviderIds' }),
                                );
                        }}
                        selectedKey={query?.treatingProviderIds ? query?.treatingProviderIds : []}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.SearchCombo
                        placeholder="(Select Carriers)"
                        label="Carrier"
                        multiSelect
                        style={{ minWidth: 300 }}
                        options={payerOption}
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(
                                    toggleProceduresFinancialSummaryId({ id: option.key as string, path: 'insuranceCarrierIds' }),
                                );
                        }}
                        selectedKey={query?.insuranceCarrierIds ? query?.insuranceCarrierIds : []}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.SearchCombo
                        placeholder="(Select Procedures)"
                        label="Procedures"
                        options={_procedureOptions}
                        styles={{ callout: { maxWidth: 475 } }}
                        multiSelect
                        style={{ minWidth: 300 }}
                        maxResults={100}
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(toggleProceduresFinancialSummaryId({ id: option.key as string, path: 'procedureIds' }));
                        }}
                        selectedKey={query?.procedureIds ? query?.procedureIds : []}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Dropdown
                        label="Group By"
                        style={{ minWidth: 300 }}
                        options={[
                            { key: '', text: '(Select)' },
                            ..._groupByOptions,

                            ...map(FinancialSummaryGroup, (type) => ({
                                key: type,
                                text: lookupFinancialSummaryGroupDisplayName[type],
                            })),
                        ]}
                        placeholder="(Select)"
                        selectedKey={query?.groupCol}
                        onChange={(ev, option) => {
                            if (option) dispatch(setFinanceSummaryGroupCol(option.key as string));
                        }}
                    />
                </Stack.Item>
            </Stack>
        </ReportWrapper>
    );
}
