export const completedCodeArray = [
    'D0145',
    'D0150',
    'D0210',
    'D0272',
    'D0273',
    'D0274',
    'D0277',
    'D0330',
    'D1110',
    'D1120',
    'D1206',
    'D1310',
    'D1330',
    'D1351',
    'D1510',
    'D1515',
    'D1520',
    'D1525',
    'D1550',
    'D1555',
    'D2140',
    'D2150',
    'D2160',
    'D2161',
    'D2330',
    'D2331',
    'D2332',
    'D2335',
    'D2390',
    'D2391',
    'D2392',
    'D2393',
    'D2394',
    'D2930',
    'D2934',
    'D2940',
    'D2950',
    'D2951',
    'D2954',
    'D3110',
    'D3120',
    'D3220',
    'D3310',
    'D3320',
    'D3330',
    'D4341',
    'D4342',
    'D4355',
    'D4910',
    'D7111',
    'D7140',
    'D7210',
];
