import IWorkListAppointmentView from 'api/models/worklist-appointment-view';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';

const reasonForEncounter: ISortableColumn<IWorkListAppointmentView> = {
    key: 'reasonForEncounter',
    minWidth: 140,
    maxWidth: 140,
    isResizable: true,
    name: 'Reason for Encounter',
    fieldName: 'encounterReason',
    onRender: (item) => {
        return <span title={item?.notes ? `Appointment Notes: ${item?.notes}` : undefined}>{item?.encounterReason}</span>;
    },
};

export default reasonForEncounter;
