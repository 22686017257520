import { createSlice } from '@reduxjs/toolkit';
import { problemsExtraReducers } from './problems.reducers';
import { problemsInitialState } from './problems.state';

const problemsSlice = createSlice({
    name: 'problems',
    reducers: {},
    initialState: problemsInitialState,
    extraReducers: (builder) => {
        problemsExtraReducers(builder);
    },
});

export default problemsSlice.reducer;
