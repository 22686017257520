import { Dictionary } from '@reduxjs/toolkit';
import IPatient from 'api/models/patient.model';
import ILocationsOfCare from 'api/models/Scheduling/locationsOfCare.model';
import { WorkList } from 'state/slices/admin-huddle/worklists/worklist.state';
import { TutorialTypes } from 'state/slices/teaching/teaching.state';
import store from 'store';
import { validate as validateIsId } from 'uuid';

export const STORE_NAME = 'elwood-dental';
export type RecentPatient = Pick<IPatient, 'id' | 'firstName' | 'lastName' | 'chosenName' | 'middleName'> & {
    lastAccessed: string;
    tenantId: string;
};

export type StoredTenantEntity = {
    id: string;
    tenantId: string;
};

type DentalLocalStorage = {
    ui: UiLocalStorage;
    account: AccountLocalStorage;
    teaching: TeachingLocalStorage;
    workLists: WorkListsLocalStorage;
};

//key of work list local storage data should be a combination of the logged in  tenantId, userId, and workListType.
type WorkListsLocalStorage = Record<string, WorkListItemLocalStorage>;
type WorkListItemLocalStorage = {
    recentWorkListItems: RecentWorkListItem[];
};
export type RecentWorkListItem = {
    id: string;
    displayName: string;
    additionalInfo?: Record<string, unknown>;
};

type UiLocalStorage = {
    theme: 'dark' | 'light';
    patientBannerExpanded: boolean;
    activityBarExpanded: boolean;
    schedulingToolbarExpanded: boolean;
};
type TeachingLocalStorage = {
    completedTutorialsByUser: Dictionary<TutorialTypes[]>;
};

interface AccountLocalStorage {
    recentPatients: RecentPatient[];
    locs?: { tenantId: string; loc: ILocationsOfCare }[];
    toggleFilterOperatory?: Record<string, string[]>;
    toggleFilterOperatoryTags?: Dictionary<Dictionary<string[]>>;
    clinicHuddleProvider?: StoredTenantEntity[];
    clinicHuddleLocation?: StoredTenantEntity[];
}

const newLocalStorage: DentalLocalStorage = {
    ui: {
        theme: 'light',
        patientBannerExpanded: false,
        activityBarExpanded: true,
        schedulingToolbarExpanded: false,
    },
    account: {
        recentPatients: [],
        locs: [],
        toggleFilterOperatory: {},
        toggleFilterOperatoryTags: {},
        clinicHuddleProvider: [],
        clinicHuddleLocation: [],
    },
    teaching: {
        completedTutorialsByUser: {},
    },
    workLists: {},
};

function getTenantId() {
    const href = window.location.pathname;
    const [, tenantId] = href.split('/');
    return tenantId;
}

class AppStorage {
    store: DentalLocalStorage;
    constructor() {
        this.store = store.get(STORE_NAME, newLocalStorage);
        this.migrateLoc(); // New property to existing localstorage object
    }

    private save() {
        store.set(STORE_NAME, this.store);
    }

    get theme() {
        return this.store.ui.theme;
    }
    set theme(theme: 'dark' | 'light') {
        this.store.ui.theme = theme;
        this.save();
    }

    get patientBannerExpanded() {
        return this.store.ui.patientBannerExpanded;
    }
    set patientBannerExpanded(expanded: boolean) {
        this.store.ui.patientBannerExpanded = expanded;
        this.save();
    }

    get activityBarExpanded() {
        return this.store.ui.activityBarExpanded;
    }
    set activityBarExpanded(expanded: boolean) {
        this.store.ui.activityBarExpanded = expanded;
        this.save();
    }

    get schedulingToolbarExpanded() {
        return this.store.ui.schedulingToolbarExpanded;
    }
    set schedulingToolbarExpanded(expanded: boolean) {
        this.store.ui.schedulingToolbarExpanded = expanded;
        this.save();
    }

    set recentPatients(patients: RecentPatient[]) {
        this.store.account.recentPatients = patients;
        this.save();
    }
    get recentPatients(): RecentPatient[] {
        return this.store.account.recentPatients;
    }
    set loc({ tenantId, locId }: { tenantId: string; locId: ILocationsOfCare }) {
        const locs = this.store.account.locs?.filter((loc) => loc.tenantId !== tenantId);
        this.store['account']['locs'] = [...(locs as any), { tenantId, loc: locId }];

        this.save();
    }

    getLastLoc(tenantId: string) {
        return this.store.account.locs?.find((loc) => loc.tenantId === tenantId)?.loc;
    }

    set toggleFilterOperatory({
        tenantId,
        operatoryId,
        locationOfCareId,
    }: {
        tenantId: string;
        operatoryId: string;
        locationOfCareId?: string;
    }) {
        const isId = validateIsId(operatoryId);

        if (isId) {
            if (!this.store['account']['toggleFilterOperatory']) this.store['account']['toggleFilterOperatory'] = {};
            const currentTenantOperatoryIds = { ...this.store['account']['toggleFilterOperatory'] };
            currentTenantOperatoryIds[tenantId] = [...(currentTenantOperatoryIds[tenantId] ?? [])];

            if (currentTenantOperatoryIds[tenantId]?.includes(operatoryId)) {
                currentTenantOperatoryIds[tenantId] = currentTenantOperatoryIds[tenantId].filter((id) => id !== operatoryId);
            } else {
                currentTenantOperatoryIds[tenantId]?.push(operatoryId);
            }

            this.store['account']['toggleFilterOperatory'] = currentTenantOperatoryIds;
            this.save();
        } else if (locationOfCareId) {
            //If not a valid id, the operatoryId is a tag
            if (!this.store['account']['toggleFilterOperatoryTags']) this.store['account']['toggleFilterOperatoryTags'] = {};
            const currentTenantOperatoryTags = { ...this.store['account']['toggleFilterOperatoryTags'] };
            const currentTenantOperatoryTagsPerTenant = { ...this.store['account']['toggleFilterOperatoryTags'][tenantId] };

            if (!currentTenantOperatoryTags[tenantId]) currentTenantOperatoryTags[tenantId] = {};
            if (currentTenantOperatoryTagsPerTenant[locationOfCareId]) currentTenantOperatoryTagsPerTenant[locationOfCareId] = [];

            currentTenantOperatoryTagsPerTenant[locationOfCareId] = [
                ...((currentTenantOperatoryTags[tenantId] as Dictionary<string[]>)[locationOfCareId] ?? []),
            ];

            if (currentTenantOperatoryTagsPerTenant[locationOfCareId]?.includes(operatoryId)) {
                currentTenantOperatoryTagsPerTenant[locationOfCareId] = currentTenantOperatoryTagsPerTenant[
                    locationOfCareId
                ]?.filter((id) => id !== operatoryId);
            } else {
                currentTenantOperatoryTagsPerTenant[locationOfCareId]?.push(operatoryId);
            }

            currentTenantOperatoryTags[tenantId] = currentTenantOperatoryTagsPerTenant;
            this.store['account']['toggleFilterOperatoryTags'] = currentTenantOperatoryTags;
            this.save();
        }
    }

    get getToggleFilterOperatory() {
        return this.store.account.toggleFilterOperatory ?? {};
    }

    get getToggleFilterOperatoryTags() {
        return this.store.account.toggleFilterOperatoryTags ?? {};
    }

    get getClinicHuddleProvider() {
        const providers = (this.getAccountLocalStorageProp('clinicHuddleProvider') as StoredTenantEntity[]) ?? [];
        return this.getFilteredStoredTenantEntities(providers);
    }

    set clinicHuddleProvider(providers: string[]) {
        const tenantId = getTenantId();
        const diffTenantProviders =
            this.store.account.clinicHuddleProvider?.filter((provider) => provider.tenantId !== tenantId) ?? [];
        const storedProvider: StoredTenantEntity[] = providers.map((id) => ({
            tenantId,
            id,
        }));

        this.setAccountLocalStorageProp('clinicHuddleProvider', [...diffTenantProviders, ...storedProvider]);
    }

    get getClinicHuddleLocation() {
        const loc = (this.getAccountLocalStorageProp('clinicHuddleLocation') as StoredTenantEntity[]) ?? [];
        return this.getFilteredStoredTenantEntities(loc);
    }

    set clinicHuddleLocation(locations: string[]) {
        const tenantId = getTenantId();
        const diffTenantLoc = this.store.account.clinicHuddleLocation?.filter((loc) => loc.tenantId !== tenantId) ?? [];
        if (!this.getAccountLocalStorageProp('clinicHuddleLocation')) this.setAccountLocalStorageProp('clinicHuddleLocation', []);

        const storedLocations: StoredTenantEntity[] = locations.map((id) => ({
            tenantId,
            id,
        }));

        this.setAccountLocalStorageProp('clinicHuddleLocation', [...diffTenantLoc, ...storedLocations]);
    }

    getFilteredStoredTenantEntities(entities: StoredTenantEntity[]): string[] {
        const tenantId = getTenantId();
        return entities.filter((loc) => loc.tenantId === tenantId).map((loc) => loc.id);
    }

    getAccountLocalStorageProp(prop: keyof AccountLocalStorage) {
        return this.store['account'][prop];
    }

    setAccountLocalStorageProp(prop: keyof AccountLocalStorage, value: unknown) {
        (this.store['account'][prop] as unknown) = value;
        this.save();
    }

    /**
     * Add Patient to Recently Viewed list
     *
     * @param {(IPatient | RecentPatient)} patient
     * @memberof AppStorage
     */
    addRecentPatient(patient: IPatient | RecentPatient) {
        const tenantId = getTenantId();

        const patientToAdd: RecentPatient = {
            id: patient?.id,
            lastAccessed: new Date().toISOString(),
            firstName: patient?.firstName,
            middleName: patient?.middleName,
            lastName: patient?.lastName,
            chosenName: patient?.chosenName,
            tenantId,
        };

        const recentPatients = this.recentPatients;
        const newPatientList = recentPatients.filter((p) => p.id !== patientToAdd.id).filter((p) => p.firstName !== undefined);

        if (patientToAdd.id && patientToAdd.tenantId) {
            if (recentPatients.length) {
                if (recentPatients.length >= 15) {
                    this.recentPatients = [patientToAdd, ...newPatientList.slice(0, 13)];
                } else {
                    this.recentPatients = [patientToAdd, ...newPatientList];
                }
            } else {
                this.recentPatients = [patientToAdd];
            }
        }
    }
    removeMergedPatient(patient?: IPatient) {
        const recentPatients = this.recentPatients;
        const newPatientList = recentPatients.filter((p) => p.id !== patient?.id);
        this.recentPatients = newPatientList;
    }
    migrateLoc() {
        if (!this.store.account?.locs) {
            this.store.account['locs'] = [];
            this.save();
        }
    }

    public getCompletedTutorialsByUser(userId: string) {
        return this.store.teaching?.completedTutorialsByUser[userId] as TutorialTypes[] | undefined;
    }
    set completedTutorialsByUser({ tutorial, userId }: { userId: string; tutorial: TutorialTypes }) {
        if (!this.store.teaching) this.store.teaching = { completedTutorialsByUser: {} };
        if (!this.store.teaching.completedTutorialsByUser[userId]) this.store.teaching.completedTutorialsByUser[userId] = [];

        const completedTutorials = this.store.teaching.completedTutorialsByUser[userId] as TutorialTypes[];
        (this.store.teaching.completedTutorialsByUser[userId] as TutorialTypes[]) = [...completedTutorials, tutorial];
    }

    //Work list local storage

    /**Get composite key for worklist local storage*/
    private getRecentWorkListItemKey(tenantId: string, workList: WorkList, userId: string) {
        return `${tenantId}/${workList}/${userId}`;
    }
    /**Returns an array with the specified recent item removed for the composed worklist key.*/
    private getRecentWorkListItemsWithRemovedItem(key: string, recentItem: RecentWorkListItem) {
        if (!this.store.workLists[key]?.recentWorkListItems) return;

        const indexOfExistingItem = this.store.workLists[key].recentWorkListItems.findIndex((item) => item.id === recentItem.id);
        //Remove the item from local storage if it exists.
        if (indexOfExistingItem > -1) {
            return [...this.store.workLists[key].recentWorkListItems.filter((_, index) => index !== indexOfExistingItem)];
        }

        return [...this.store.workLists[key].recentWorkListItems];
    }
    /**Get all recent worklist items from local storage for specified tenant, worklist, and user.*/
    public getRecentWorkListItems(tenantId: string, workList: WorkList, userId: string) {
        //Build composite key for accessing worklist local storage data.
        const key = this.getRecentWorkListItemKey(tenantId, workList, userId);
        if (!this.store.workLists) return [];
        return this.store.workLists[key]?.recentWorkListItems ?? [];
    }

    /**Check if recent worklist items exist for specified tenant, worklist, and user.*/
    private recentWorklistItemsExist(key: string): boolean {
        if (!this.store.workLists) return false;
        if (!this.store.workLists[key]) return false;
        if (!this.store.workLists[key].recentWorkListItems) return false;

        return true;
    }

    /**Removes a recent worklist item to local storage for specified tenant, worklist, and user. Returns list of new recent worklist items.*/
    public removeRecentWorkListItems(
        tenantId: string,
        workList: WorkList,
        userId: string,
        recentItems: string[],
    ): RecentWorkListItem[] {
        const key = this.getRecentWorkListItemKey(tenantId, workList, userId);

        if (!this.recentWorklistItemsExist(key)) return [];

        //Filter the recent items, removing the recent items that are passed in.
        const newItems = this.store.workLists[key].recentWorkListItems.filter((item) => recentItems.indexOf(item.id) === -1);
        if (newItems) this.store.workLists[key].recentWorkListItems = newItems;

        //Save to local storage.
        this.save();

        return this.store.workLists[key].recentWorkListItems; //Return recent worklist items list after updates
    }
    /**Adds a recent worklist item to local storage for specified tenant, worklist, and user. Returns list of new recent worklist items.*/
    public addRecentWorkListItem(tenantId: string, workList: WorkList, userId: string, recentItem: RecentWorkListItem) {
        //If worklist local storage object doesn't exist, create it.
        if (!this.store?.workLists) this.store['workLists'] = {};

        //Create a composite key based on tentantId, worklist, and userId.
        const key = this.getRecentWorkListItemKey(tenantId, workList, userId);

        //If the local storage for worklists does not contain the key then create it along with adding the recent item.
        if (!this.store.workLists[key]?.recentWorkListItems) {
            //Create new object and recent items list
            this.store.workLists[key] = { recentWorkListItems: [recentItem] };
        } else {
            //Remove the recent item so we can move it to the top of the recent items.
            const newItems = this.getRecentWorkListItemsWithRemovedItem(key, recentItem);
            if (newItems) this.store.workLists[key]['recentWorkListItems'] = newItems;

            //Add item to beginning of list.
            this.store.workLists[key]['recentWorkListItems']?.unshift(recentItem);
            //Limit recent items for each list to 20 items.
            if (this.store.workLists[key].recentWorkListItems.length > 20) this.store.workLists[key].recentWorkListItems.pop();
        }

        //Save to local storage.
        this.save();

        //Return new recent items.
        return this.store.workLists[key].recentWorkListItems; //Return recent worklist items list after updates
    }
}

const appLocalStorage = new AppStorage();
export default appLocalStorage;
