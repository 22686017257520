import { DefaultButton, Dropdown, IDropdownOption, Stack } from '@fluentui/react';
import dentalApi from 'api/dental.api';
import { ImagingSourceType } from 'api/models/imaging-source.model';
import { IPatientImagingModel } from 'api/models/patient.model';
import { format } from 'date-fns';
import { useSelector, useTenantId } from 'hooks';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { selectSelectedPatient } from 'state/slices/patient/patient.selectors';
import {
    getGenericImagingSources,
    imagingSourceActions,
    selecteSelectedGenericImagingSource,
    selectImagingSourceAsListForTenant,
    selectSelectedImagingSourceForTenant,
} from 'state/slices/tenant/imaging-source.slice';
import dateOnly from 'utils/dateOnly';

export default function Xrays(): JSX.Element {
    const dispatch = useDispatch();
    const patient = useSelector(selectSelectedPatient);
    const tenantId = useTenantId();

    const selectedImagingSource = useSelector(selectSelectedImagingSourceForTenant);
    const selectedGenericImagingSource = useSelector(selecteSelectedGenericImagingSource);

    useEffect(() => {
        dispatch(getGenericImagingSources());
    }, []);

    const onHandleImaging = () => {
        if (patient && patient.mrn && selectedImagingSource) {
            const patientQuery: IPatientImagingModel = {
                patientId: patient.id,
                mrn: patient.mrn,
                dateOfBirth: patient.dateOfBirth ? format(new Date(dateOnly(patient.dateOfBirth)), 'yyyy-MM-dd') : '',
                firstName: patient.firstName,
                lastName: patient.lastName,
                imagingSourceId: selectedImagingSource.id,
                gender: patient.legalSexId,
            };

            dentalApi
                .getImagingUrl(tenantId, patientQuery)
                .then(({ data }) => {
                    const url = decodeURIComponent(data);
                    if (selectedGenericImagingSource?.type !== ImagingSourceType.Web) {
                        window.open(url, '_blank');
                    } else {
                        window.open(data, '_blank');
                    }
                })
                .catch((err) => console.log(err));
        }
    };
    return (
        <Stack tokens={{ childrenGap: 5 }} verticalAlign="end" grow horizontal>
            <ImagingSourcesDropdown />
            <DefaultButton text="Launch Imaging" primary iconProps={{ iconName: 'Camera' }} onClick={onHandleImaging} />
        </Stack>
    );
}

function ImagingSourcesDropdown() {
    const dispatch = useDispatch();
    const imagingSources = useSelector(selectImagingSourceAsListForTenant);
    const selectedImagingSource = useSelector(selectSelectedImagingSourceForTenant);

    const options: IDropdownOption[] = imagingSources.map((source) => ({
        key: source.id,
        text: source.displayName,
    }));

    useEffect(() => {
        dispatch(imagingSourceActions.setSelectedImagingSourceForTenant(imagingSources[0]));
    }, []);

    function onChangeImagingSource(sourceId?: string) {
        const imagingSourcedata = imagingSources.find((source) => source.id === sourceId);
        if (imagingSourcedata) dispatch(imagingSourceActions.setSelectedImagingSourceForTenant(imagingSourcedata));
    }

    return (
        <Dropdown
            options={options}
            onChange={(ev, option) => onChangeImagingSource(option?.key as string | undefined)}
            selectedKey={selectedImagingSource?.id}
            label="Imaging Source"
        />
    );
}
