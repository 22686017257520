import { Label, Separator, Stack, Text, TextField, useTheme } from '@fluentui/react';
import { Field, Section } from 'components';
import { useSelector } from 'hooks';
import useForms from 'hooks/store/useForms';
import { ICariesRiskAdultQuestions, RiskType } from './types';

export default function CariesRiskAdult(): JSX.Element | null {
    const { currentlySelectedForm, setFormFieldDataAndSave, previousOfSelectedForm } = useForms();
    const { palette } = useTheme();
    const selectedTheme = useSelector((state) => state.ui.selectedTheme);
    if (!currentlySelectedForm) return null;

    const data = currentlySelectedForm.data as ICariesRiskAdultQuestions;
    const previousExamData = previousOfSelectedForm?.data as ICariesRiskAdultQuestions;
    const typedSetFieldData = (path: keyof ICariesRiskAdultQuestions, value?: string) => setFormFieldDataAndSave(path, value);

    const getTextStyle = (data?: ICariesRiskAdultQuestions): React.CSSProperties => {
        return {
            backgroundColor:
                data?.score === RiskType.High
                    ? palette.redDark
                    : data?.score === RiskType.Moderate
                    ? palette.yellowDark
                    : data?.score === RiskType.Low
                    ? palette.greenLight
                    : 'transparent',
            color:
                selectedTheme === 'light' && (data?.score === RiskType.High || data?.score === RiskType.Moderate)
                    ? 'white'
                    : selectedTheme === 'dark' && data?.score === RiskType.Low
                    ? palette.white
                    : palette.black,
            padding: 5,
            borderRadius: 2,
        };
    };

    const isCompleted = currentlySelectedForm.completedDate ? true : false;
    return (
        <Stack tokens={{ childrenGap: 10 }} grow>
            <Stack
                styles={{
                    root: {
                        position: 'sticky',
                        top: 0,
                        zIndex: 99,
                        backgroundColor: palette.white,
                        paddingTop: 5,
                        paddingBottom: 5,
                    },
                }}
                grow
                horizontal
                horizontalAlign="space-between"
            >
                {data?.score && <Text style={getTextStyle(data)}>Patient risk is {data.score.toLowerCase()}</Text>}{' '}
                {previousExamData?.score && (
                    <Text style={getTextStyle(previousExamData)}>
                        Previous exam patient risk is {previousExamData.score.toLowerCase()}
                    </Text>
                )}
            </Stack>
            <Stack grow>
                <Section heading="Contributing Conditions">
                    <Stack id="fluorideQuestionCariesAdult" grow>
                        <Stack grow>
                            <Label>Fluoride Exposure</Label>
                            <Text variant="small">
                                Through drinking water, supplements, professional applications, or toothpaste.
                            </Text>
                        </Stack>
                        <Field.ChoiceGroup
                            options={[
                                { key: RiskType.Low, text: 'Yes' },
                                { key: RiskType.Moderate, text: 'No' },
                            ]}
                            defaultSelectedKey={data.fluorideExposure}
                            selectedKey={data.fluorideExposure}
                            inline
                            onChange={(ev, option) => typedSetFieldData('fluorideExposure', option?.key)}
                            disabled={isCompleted}
                        />
                    </Stack>
                    <Separator />
                    <Stack id="sugaryOrStarchyFoodsCariesAdult" grow>
                        <Stack grow>
                            <Label>Sugary/Starchy Foods or Drinks</Label>
                            <Text variant="small">
                                Including juice, carbonated or non-carbonated soft drinks, energy drinks, and medicinal syrups.
                            </Text>
                        </Stack>
                        <Field.ChoiceGroup
                            options={[
                                { key: RiskType.Low, text: 'Primarily at mealtimes' },
                                { key: RiskType.High, text: 'Frequent or prolonged between meal exposures per day' },
                            ]}
                            selectedKey={data.sugaryStarchyFoodsOrDrinks}
                            inline
                            onChange={(ev, value) => typedSetFieldData('sugaryStarchyFoodsOrDrinks', value?.key)}
                            disabled={isCompleted}
                        />
                    </Stack>
                    <Separator />
                    <Stack id="cariesExperienceCariesAdult" grow>
                        <Stack grow>
                            <Label>Caries Experience</Label>
                            <Text variant="small">Mother, Caregiver, and/or other Siblings. (For patients ages 6- 14)</Text>
                        </Stack>
                        <Field.ChoiceGroup
                            options={[
                                { key: RiskType.Low, text: 'None in last 24 months' },
                                { key: RiskType.Moderate, text: 'Lesions in last 7-23 months' },
                                { key: RiskType.High, text: 'Lesions in last 6 months' },
                            ]}
                            selectedKey={data.cariesExperience}
                            inline
                            onChange={(ev, value) => typedSetFieldData('cariesExperience', value?.key)}
                            disabled={isCompleted}
                        />
                    </Stack>
                    <Separator />
                    <Stack id="dentalHomeCariesAdult" grow>
                        <Stack grow>
                            <Label>Dental Home</Label>
                            <Text variant="small">
                                Established patient of record, receiving regular dental in a dental office
                            </Text>
                        </Stack>
                        <Field.ChoiceGroup
                            options={[
                                { key: RiskType.Low, text: 'Yes' },
                                { key: RiskType.Moderate, text: 'No' },
                            ]}
                            selectedKey={data.dentalHome}
                            inline
                            onChange={(ev, value) => typedSetFieldData('dentalHome', value?.key)}
                            disabled={isCompleted}
                        />
                    </Stack>
                </Section>

                <Section heading="General Health Conditions">
                    <Stack id="specialHealthcareNeedsCariesAdult" grow>
                        <Stack grow>
                            <Label>Special Healthcare Needs</Label>
                            <Text variant="small">
                                Patients with developmental, physical, medical or mental disabilities that prevent or limit
                                performance of adequate oral health care by themselves or caregivers.
                            </Text>
                        </Stack>
                        <Field.ChoiceGroup
                            options={[
                                { key: RiskType.Low, text: 'No' },
                                { key: RiskType.Moderate, text: 'Yes (over 14)' },
                                { key: RiskType.High, text: 'Yes (age 6-14)' },
                            ]}
                            selectedKey={data.specialHealthcareNeeds}
                            inline
                            onChange={(ev, value) => typedSetFieldData('specialHealthcareNeeds', value?.key)}
                            disabled={isCompleted}
                        />
                    </Stack>
                    <Separator />
                    <Stack id="chemoRadioationTherapyCariesAdult" grow>
                        <Field.ChoiceGroup
                            label="Chemo/Radiation Therapy"
                            options={[
                                { key: RiskType.Low, text: 'No' },
                                { key: RiskType.High, text: 'Yes' },
                            ]}
                            selectedKey={data.chemoRadioationTherapy}
                            inline
                            onChange={(ev, value) => typedSetFieldData('chemoRadioationTherapy', value?.key)}
                            disabled={isCompleted}
                        />
                    </Stack>
                    <Separator />
                    <Stack id="eatingDisordersCariesAdult" grow>
                        <Field.ChoiceGroup
                            label="Eating Disorders"
                            options={[
                                { key: RiskType.Low, text: 'No' },
                                { key: RiskType.Moderate, text: 'Yes' },
                            ]}
                            selectedKey={data.eatingDisorders}
                            inline
                            onChange={(ev, value) => typedSetFieldData('eatingDisorders', value?.key)}
                            disabled={isCompleted}
                        />
                    </Stack>
                    <Separator />
                    <Stack id="medicationsThatReduceSalivaryFlowCariesAdult" grow>
                        <Field.ChoiceGroup
                            label="Medications that reduce salivary flow"
                            options={[
                                { key: RiskType.Low, text: 'No' },
                                { key: RiskType.Moderate, text: 'Yes' },
                            ]}
                            selectedKey={data.medicationsThatReduceSalivaryFlow}
                            inline
                            onChange={(ev, value) => typedSetFieldData('medicationsThatReduceSalivaryFlow', value?.key)}
                            disabled={isCompleted}
                        />
                    </Stack>
                    <Separator />
                    <Stack id="drugAlchoholAbuseCariesAdult" grow>
                        <Field.ChoiceGroup
                            label="Drug/Alchohol Abuse"
                            options={[
                                { key: RiskType.Low, text: 'No' },
                                { key: RiskType.Moderate, text: 'Yes' },
                            ]}
                            selectedKey={data.drugAlchoholAbuse}
                            inline
                            onChange={(ev, value) => typedSetFieldData('drugAlchoholAbuse', value?.key)}
                            disabled={isCompleted}
                        />
                    </Stack>
                    {/* <Stack id="activeMedicationsCariesAdult" grow>
                    <Label>Active Medications</Label>
                    {data.activeMedications?.length ? (
                        <ul>
                            {data.questions.activeMedications.map((m: any, index: number) => (
                                <li key={`med-${index}`}>
                                    <Text>{m}</Text>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <MessageBar>No active patient medications</MessageBar>
                    )}
                </Stack> */}
                </Section>
                <Section heading="Clinical Conditions">
                    <Stack id="cavitatedOrNonCavitatedIncipientCariesAdult" grow>
                        <Stack grow>
                            <Label>Cavitated or Non-Cavitated (incipient)</Label>
                            <Text variant="small">Carious Lesions or Restorations. (visually or radiographically evident)</Text>
                        </Stack>
                        <Field.ChoiceGroup
                            options={[
                                { key: RiskType.Low, text: 'No new carious lesions or restorations in last 36 months' },
                                { key: RiskType.Moderate, text: '1 or 2 new carious lesions or restorations in last 36 months' },
                                { key: RiskType.High, text: '3 or more carious lesions or restorations in last 36 months' },
                            ]}
                            selectedKey={data.cavitatedOrNonCavitatedIncipient}
                            inline
                            onChange={(ev, value) => typedSetFieldData('cavitatedOrNonCavitatedIncipient', value?.key)}
                            disabled={isCompleted}
                        />
                    </Stack>
                    <Separator />
                    <Stack id="teethMissingDueToCariesCariesAdult" grow>
                        <Field.ChoiceGroup
                            label="Teeth missing due to caries in past 36 months"
                            options={[
                                { key: RiskType.Low, text: 'No' },
                                { key: RiskType.High, text: 'Yes' },
                            ]}
                            selectedKey={data.teethMissingDueToCaries}
                            inline
                            onChange={(ev, value) => typedSetFieldData('teethMissingDueToCaries', value?.key)}
                            disabled={isCompleted}
                        />
                    </Stack>
                    <Separator />
                    <Stack id="visiblePlaqueCariesAdult" grow>
                        <Field.ChoiceGroup
                            label="Visible plaque"
                            options={[
                                { key: RiskType.Low, text: 'No' },
                                { key: RiskType.Moderate, text: 'Yes' },
                            ]}
                            selectedKey={data.visiblePlaque}
                            inline
                            onChange={(ev, value) => typedSetFieldData('visiblePlaque', value?.key)}
                            disabled={isCompleted}
                        />
                    </Stack>
                    <Separator />
                    <Stack id="unusualToothMorphologyThatComprimisesOralHygieneCariesAdult" grow>
                        <Field.ChoiceGroup
                            label="Unusual tooth morphology that compromises oral hygiene"
                            options={[
                                { key: RiskType.Low, text: 'No' },
                                { key: RiskType.Moderate, text: 'Yes' },
                            ]}
                            selectedKey={data.unusualToothMorphologyThatComprimisesOralHygiene}
                            inline
                            onChange={(ev, value) =>
                                typedSetFieldData('unusualToothMorphologyThatComprimisesOralHygiene', value?.key)
                            }
                            disabled={isCompleted}
                        />
                    </Stack>
                    <Separator />
                    <Stack id="interproximalRestorations1OrMoreCariesAdult" grow>
                        <Field.ChoiceGroup
                            label="Interproximal restorations - 1 or more"
                            options={[
                                { key: RiskType.Low, text: 'No' },
                                { key: RiskType.Moderate, text: 'Yes' },
                            ]}
                            selectedKey={data.interproximalRestorations1OrMore}
                            inline
                            onChange={(ev, value) => typedSetFieldData('interproximalRestorations1OrMore', value?.key)}
                            disabled={isCompleted}
                        />
                    </Stack>
                    <Separator />
                    <Stack id="exposedRootSurfacesPresentCariesAdult" grow>
                        <Field.ChoiceGroup
                            label="Exposed root surfaces present"
                            options={[
                                { key: RiskType.Low, text: 'No' },
                                { key: RiskType.Moderate, text: 'Yes' },
                            ]}
                            selectedKey={data.exposedRootSurfacesPresent}
                            inline
                            onChange={(ev, value) => typedSetFieldData('exposedRootSurfacesPresent', value?.key)}
                            disabled={isCompleted}
                        />
                    </Stack>
                    <Stack id="restorationsWithOverhangsAndOrOpenMarginsCariesAdult" grow>
                        <Field.ChoiceGroup
                            label="Restorations with overhangs and/or open margins; open contacts with food impaction"
                            options={[
                                { key: RiskType.Low, text: 'No' },
                                { key: RiskType.Moderate, text: 'Yes' },
                            ]}
                            selectedKey={data.restorationsWithOverhangsAndOrOpenMargins}
                            inline
                            onChange={(ev, value) => typedSetFieldData('restorationsWithOverhangsAndOrOpenMargins', value?.key)}
                            disabled={isCompleted}
                        />
                    </Stack>
                    <Stack id="severeDryMouthCariesAdult" grow>
                        <Field.ChoiceGroup
                            label="Severe dry mouth (Xerostomia)"
                            options={[
                                { key: RiskType.Low, text: 'No' },
                                { key: RiskType.High, text: 'Yes' },
                            ]}
                            selectedKey={data.severeDryMouth}
                            inline
                            onChange={(ev, value) => typedSetFieldData('severeDryMouth', value?.key)}
                            disabled={isCompleted}
                        />
                    </Stack>
                </Section>
                <Section heading="Patient Instructions">
                    <TextField
                        multiline
                        label="Additional instructions for the patient"
                        value={data.additionalPatientInstructions}
                        onChange={(ev, value) => typedSetFieldData('additionalPatientInstructions', value)}
                        disabled={isCompleted}
                    />
                </Section>
            </Stack>
        </Stack>
    );
}
