import {
    Callout,
    ColorPicker,
    CommandBarButton,
    DefaultButton,
    DetailsListLayoutMode,
    Dropdown,
    FontWeights,
    IColor,
    IColorPickerStyles,
    IComboBoxOption,
    IconButton,
    IDropdown,
    IDropdownOption,
    Label,
    Link,
    mergeStyleSets,
    MessageBar,
    MessageBarType,
    SelectionMode,
    Stack,
    TextField,
    Toggle,
    TooltipHost,
} from '@fluentui/react';
import { useBoolean, useId } from '@uifabric/react-hooks';
import IProvider, { IProviderPeriod } from 'api/models/provider.model';
import { ITenantUser } from 'api/models/tenant.model';
import { Field, SortableDetailsList, SubSection } from 'components';
import SearchComboField from 'components/Field/SearchComboField';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import { getTime } from 'date-fns';
import { useSelector } from 'hooks';
import useLookupsStore from 'hooks/store/useLookupsStore';
import { DentalPermissions } from 'hooks/store/usePermissions';
import { find, isEqual, map, sortBy } from 'lodash';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addPeriod, clearPeriods, setInitialPeriods } from 'state/slices/settings/settings.slice';
import { selectUsersAsList } from 'state/slices/settings/users/users.selectors';
import { classicDateOnly } from 'utils/dateOnly';
import { validateForm, validateProperty } from '../Shared/formValidator';
import { EditComponentProps } from '../Shared/LookupEditPanel';
import EditProviderPeriod from './EditProviderPeriod';

export default function EditProperties(props: EditComponentProps): JSX.Element {
    const dispatch = useDispatch();
    const { lookups } = useLookupsStore('Providers');
    const providers = lookups.items;

    const { selectedLookup, setSelectedLookup } = props;
    const item = selectedLookup.item as IProvider | undefined;
    const isNewItem = item?.id ? false : true;
    const usersAsList = useSelector(selectUsersAsList);

    const filteredUsersAsList = sortBy(usersAsList, ['identity.firstName']).filter((user) => {
        return !user.isDeleted && user.id !== item?.id && !find(providers, { id: user.id });
    });

    const selectedUser = find(usersAsList, { id: item?.id });

    const [showFields, setShowFields] = useState<boolean>(!isNewItem);
    const providerTypeRef = useRef<IDropdown | null>(null);

    const updateSelectedLookup = (updatedItem: unknown) => setSelectedLookup({ ...selectedLookup, item: updatedItem });
    const providerTypeChangeHandler = (_: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) =>
        updateSelectedLookup({ ...item, ...option?.data });

    const userOptions: IComboBoxOption[] = map(filteredUsersAsList, (user) => {
        return {
            key: user.id,
            text: `${user.identity?.firstName} ${user.identity?.professionalSuffix
                    ? `${user.identity?.lastName}, ${user.identity.professionalSuffix}`
                    : `${user.identity?.lastName}`
                } | ${user.identity?.email}`,
            data: user,
        };
    });

    const validators = [
        {
            property: 'Provider Type',
            isInvalid: () => !item?.isTreatingProvider && !item?.isHygienist && !item?.isRegisteredDentalAssistant,
            errorMessage: 'Provider type is required.',
        },
    ];

    validateForm(validators, props.setHasError);

    const onColorPickerChange = (ev: React.SyntheticEvent<HTMLElement, Event>, color: IColor) => {
        if (selectedLookup && item) {
            const data: IProvider = {
                ...item,
                providerSchedule: {
                    ...item.providerSchedule,
                    scheduleColor: color.str,
                },
            };
            updateSelectedLookup(data);
        }
    };

    const onPeriodRowClicked = (periodId: string) => {
        const periods = item?.providerSchedule?.periods ?? [];

        if (periodId) {
            const period = periods.find((period) => period.id === periodId);
            if (period) dispatch(setInitialPeriods([period]));
        } else {
            dispatch(setInitialPeriods(periods));
        }
    };

    const onRemovePeriod = (period: IProviderPeriod) => {
        if (item?.providerSchedule?.periods) {
            const periods = item.providerSchedule.periods.filter((item) => item.id !== period.id);

            setSelectedLookup({
                ...selectedLookup,
                item: {
                    ...item,
                    providerSchedule: { ...item.providerSchedule, periods },
                } as IProvider,
            });
        }
    };

    const onPeriodSave = (period: IProviderPeriod) => {
        let currentPeriods = [...(item?.providerSchedule?.periods ?? [])];
        const indexOfPeriod = currentPeriods.findIndex((p) => p.id === period.id);

        if (indexOfPeriod > -1) {
            currentPeriods[indexOfPeriod] = period;
        } else {
            currentPeriods = [...currentPeriods, period];
        }

        const periods = [...currentPeriods];

        setSelectedLookup({
            ...selectedLookup,

            item: {
                ...item,
                providerSchedule: { ...item?.providerSchedule, periods },
            } as IProvider,
        });

        dispatch(clearPeriods());
    };

    //save schedule to periods
    const periodColumns: ISortableColumn<IProviderPeriod>[] = [
        {
            key: 'startDate',
            fieldName: 'startDate',
            name: 'Start',
            minWidth: 80,
            maxWidth: 80,
            onRender: (item) =>
                item ? (
                    <Link
                        onClick={() => {
                            if (item) onPeriodRowClicked(item.id ?? '');
                        }}
                    >
                        {item.startDate ? classicDateOnly(item.startDate) : 'N/A'}
                    </Link>
                ) : null,
            getValueKey: (item) => {
                if (item) {
                    return item?.startDate ? getTime(new Date(item.startDate)).toString() : '';
                }
                return '';
            },
        },
        {
            key: 'endDate',
            fieldName: 'endDate',
            name: 'End',
            minWidth: 80,
            maxWidth: 80,
            onRender: (item) =>
                item ? (
                    <Link
                        onClick={() => {
                            if (item) onPeriodRowClicked(item.id ?? '');
                        }}
                    >
                        {classicDateOnly(item.endDate ?? '')}
                    </Link>
                ) : null,
            getValueKey: (item) => {
                if (item) {
                    return item?.endDate ? getTime(new Date(item.endDate)).toString() : '';
                }
                return '';
            },
        },
        // {
        //     key: 'timetables',
        //     fieldName: 'timetables',
        //     name: 'Timetables',
        //     minWidth: 100,
        //     onRender: (period) => {
        //         if (period) {
        //             const timeTables: string[] = [];
        //             for (const key in period.timetables) {
        //                 if (period.timetables[key] && period.timetables[key].length > 0) {
        //                     timeTables.push(toCapitalize(key.substring(0, 3)));
        //                 }
        //             }
        //             return <Link>{timeTables.join(', ')}</Link>;
        //         }
        //         return null;
        //     },
        // },
        {
            key: 'remove',
            name: '',
            minWidth: 24,
            maxWidth: 24,
            onRender: (period) =>
                period ? (
                    <TooltipHost content="Remove period.">
                        <IconButton iconProps={{ iconName: 'Cancel' }} onClick={() => onRemovePeriod(period)} />
                    </TooltipHost>
                ) : null,
        },
    ];

    const colorPickerStyles: Partial<IColorPickerStyles> = {
        panel: { padding: 0 },
        colorRectangle: { width: 100, height: 100 },
        table: {
            display: 'none',
        },
    };

    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const buttonId = useId('callout-button');
    const labelId = useId('callout-label');
    const descriptionId = useId('callout-description');
    const styles = mergeStyleSets({
        button: {
            width: 200,
        },
        callout: {
            width: 320,
            maxWidth: '90%',
            padding: '20px 24px',
        },
        title: {
            marginBottom: 12,
            fontWeight: FontWeights.semilight,
        },
        link: {
            display: 'block',
            marginTop: 20,
        },
    });

    const mappedProviderTypeOptions: IDropdownOption[] = [
        {
            key: 'Treating Provider',
            text: 'Treating Provider',
            data: {
                isTreatingProvider: true,
                isHygienist: false,
                isRegisteredDentalAssistant: false,
                isResident: false,
                isAttestingHygienist: false,
            },
        },
        {
            key: 'Resident',
            text: 'Treating Provider (Resident)',
            data: {
                isResident: true,
                isTreatingProvider: true,
                isHygienist: false,
                isRegisteredDentalAssistant: false,
                isAttestingHygienist: false,
            },
        },
        {
            key: 'Hygienist',
            text: 'Hygienist',
            data: {
                isTreatingProvider: false,
                isHygienist: true,
                isRegisteredDentalAssistant: false,
                isResident: false,
                isAttestingHygienist: false,
            },
        },
    ];

    const nonMappedProviderTypeOptions: IDropdownOption[] = [
        {
            key: 'Hygienist',
            text: 'Hygienist',
            data: {
                isTreatingProvider: false,
                isHygienist: true,
                isRegisteredDentalAssistant: false,
                isResident: false,
                isAttestingHygienist: false,
            },
        },
        {
            key: 'Registered Dental Assistant',
            text: 'Registered Dental Assistant',
            data: {
                isTreatingProvider: false,
                isHygienist: false,
                isRegisteredDentalAssistant: true,
                isResident: false,
                isAttestingHygienist: false,
            },
        },
    ];

    return (
        <Stack tokens={{ childrenGap: 5 }}>
            <Stack tokens={{ childrenGap: 10 }}>
                {isNewItem && (
                    <Stack.Item grow>
                        <SearchComboField
                            maxResults={100}
                            options={userOptions}
                            placeholder="Select User"
                            label={`Only providers who are mapped and have the status of "Active" in Membership will display here`}
                            useComboBoxAsMenuWidth={true}
                            selectedKey={''}
                            onChange={(event, option) => {
                                if (option && option.key !== '') {
                                    const identity = option?.data['identity'];
                                    const user = option?.data as ITenantUser;

                                    const isResident = user.claims ? user.claims.indexOf(DentalPermissions.Resident) > -1 : false;

                                    const data: IProvider = {
                                        firstName: identity.firstName,
                                        lastName: identity.lastName,
                                        isTreatingProvider: false,
                                        isRegisteredDentalAssistant: false,
                                        isHygienist: false,
                                        isResident,
                                        isSupervisingProvider: false,
                                        isBillingProvider: false,
                                        isAttestingHygienist: false,
                                        isDeleted: false,
                                        providerSchedule: undefined,
                                        mappedProviderId: identity.providerId,
                                        id: identity.id,
                                    };

                                    updateSelectedLookup(data);
                                    setShowFields(true);
                                    setTimeout(() => {
                                        providerTypeRef.current?.focus();
                                    }, 200);
                                }
                            }}
                        />
                    </Stack.Item>
                )}
            </Stack>

            {showFields && (
                <Stack tokens={{ childrenGap: 10 }} verticalAlign="start">
                    <Stack tokens={{ childrenGap: 2 }} horizontal verticalAlign="start">
                        <Stack.Item grow>
                            <TextField label="Name" value={`${item?.firstName} ${item?.lastName}`} maxLength={20} readOnly />
                        </Stack.Item>
                        <Stack horizontal tokens={{ childrenGap: 2 }} grow>
                            <Stack verticalAlign="center">
                                <TooltipHost content="Not seeing the provider type you are looking for? The Treating Provider and Resident options will only appear for users mapped in Membership to ensure successful appointment integration">
                                    <IconButton iconProps={{ iconName: 'Info' }} />
                                </TooltipHost>
                            </Stack>
                            <Stack.Item grow>
                                <Field.Dropdown
                                    componentRef={providerTypeRef}
                                    options={
                                        item?.mappedProviderId?.length && item.mappedProviderId !== '000'
                                            ? mappedProviderTypeOptions
                                            : nonMappedProviderTypeOptions
                                    }
                                    label="Provider Type"
                                    placeholder="(Select Type)"
                                    selectedKey={
                                        item?.isResident
                                            ? 'Resident'
                                            : item?.isHygienist
                                                ? 'Hygienist'
                                                : item?.isRegisteredDentalAssistant
                                                    ? 'Registered Dental Assistant'
                                                    : item?.isTreatingProvider
                                                        ? 'Treating Provider'
                                                        : null
                                    }
                                    onChange={providerTypeChangeHandler}
                                    style={{ minWidth: 215 }}
                                    errorMessage={validateProperty(validators, 'Provider Type')}
                                />
                            </Stack.Item>
                        </Stack>
                    </Stack>
                    <Stack grow verticalAlign="end">
                        <DefaultButton
                            id={buttonId}
                            onClick={toggleIsCalloutVisible}
                            text="Select Provider Color"
                            className={styles.button}
                            iconProps={{
                                iconName: 'CircleFill',
                                style: { color: item?.providerSchedule?.scheduleColor ?? '#fff' },
                            }}
                        />
                        {isCalloutVisible && (
                            <Callout
                                className={styles.callout}
                                ariaLabelledBy={labelId}
                                ariaDescribedBy={descriptionId}
                                gapSpace={0}
                                target={`#${buttonId}`}
                                onDismiss={toggleIsCalloutVisible}
                                setInitialFocus
                            >
                                <Stack horizontalAlign="center">
                                    <ColorPicker
                                        color={item?.providerSchedule?.scheduleColor ?? '#fff'}
                                        onChange={onColorPickerChange}
                                        alphaType="none"
                                        styles={colorPickerStyles}
                                        strings={{
                                            hueAriaLabel: 'Hue',
                                        }}
                                    />
                                </Stack>
                            </Callout>
                        )}
                    </Stack>

                    {item?.isHygienist && (
                        <Stack horizontal tokens={{ childrenGap: 2 }}>
                            <Toggle
                                label="Attesting Hygienist"
                                checked={item?.isAttestingHygienist ?? false}
                                disabled={!item.mappedProviderId?.length || item.mappedProviderId === '000'}
                                onChange={(_, checked) => updateSelectedLookup({ ...item, isAttestingHygienist: checked })}
                            />
                            <Stack verticalAlign="start">
                                <TooltipHost content="To ensure successful appointment integration, only users mapped in Membership can be set as an Attesting Hygienist">
                                    <IconButton iconProps={{ iconName: 'Info' }} />
                                </TooltipHost>
                            </Stack>
                        </Stack>
                    )}

                    <SubSection title="Hours of Operation">
                        <Stack tokens={{ childrenGap: 10 }}>
                            <Stack>
                                <Stack.Item>
                                    <Label>Provider Hours</Label>
                                    {!item?.providerSchedule && !(item?.providerSchedule?.periods ?? []).length && (
                                        <MessageBar messageBarType={MessageBarType.error} truncated={true}>
                                            Missing Provider Hours
                                        </MessageBar>
                                    )}
                                </Stack.Item>

                                <Stack.Item>
                                    <CommandBarButton
                                        style={{ height: 30 }}
                                        iconProps={{ iconName: 'Add' }}
                                        text="Add Provider Hours"
                                        onClick={() => {
                                            dispatch(addPeriod());
                                        }}
                                    />
                                </Stack.Item>
                            </Stack>

                            {item?.providerSchedule?.periods && item?.providerSchedule.periods.length > 0 && (
                                <SortableDetailsList<IProviderPeriod>
                                    compact={true}
                                    items={item.providerSchedule.periods}
                                    columns={periodColumns}
                                    layoutMode={DetailsListLayoutMode.justified}
                                    selectionMode={SelectionMode.none}
                                    sortOnMount={true}
                                    sortColumns={['startDate']}
                                />
                            )}

                            <EditProviderPeriod onSave={onPeriodSave} provider={item} />
                        </Stack>
                    </SubSection>
                </Stack>
            )}
        </Stack>
    );
}
