import { PayloadAction } from '@reduxjs/toolkit';
import {
    TreatmentPlanPhaseOneBaseProperties,
    TreatmentPlanPhaseOneDetailProperties,
    TreatmentPlanPhaseOneSummaryProperties,
} from 'api/models/tx-plan-phase-1.model';
import { IReportingState } from '../reporting.state';
import { toggleTreatmentPlanPhase1BaseIds } from '../reporting.slice';

export const treatmentPlanPhaseOneReducers = {
    setTreatmentPlanPhase1BaseProp: (
        state: IReportingState,
        action: PayloadAction<{ path: keyof TreatmentPlanPhaseOneSummaryProperties; value: string | number | undefined }>,
    ): void => {
        const { path, value } = action.payload;
        ((state.reportProperties as TreatmentPlanPhaseOneSummaryProperties)[path] as string | number | undefined) = value;
    },
    setTreatmentPlanPhase1DetailProp: (
        state: IReportingState,
        action: PayloadAction<{ path: keyof TreatmentPlanPhaseOneSummaryProperties; value: string | number | undefined }>,
    ): void => {
        const { path, value } = action.payload;
        ((state.reportProperties as TreatmentPlanPhaseOneSummaryProperties)[path] as string | number | undefined) = value;
    },

    toggleTreatmentPlanPhase1BaseIds: (
        state: IReportingState,
        action: PayloadAction<{ path: keyof TreatmentPlanPhaseOneBaseProperties; id: string }>,
    ): void => {
        const { id, path } = action.payload;
        if (!(state.reportProperties as TreatmentPlanPhaseOneBaseProperties)[path])
            ((state.reportProperties as TreatmentPlanPhaseOneBaseProperties)[path] as unknown[]) = [];

        const currentIds = (state.reportProperties as TreatmentPlanPhaseOneBaseProperties)[path] as string[];

        if (currentIds)
            if (currentIds?.includes(id)) {
                ((state.reportProperties as TreatmentPlanPhaseOneBaseProperties)[path] as string[]) = currentIds.filter(
                    (_id) => _id !== id,
                );
            } else {
                ((state.reportProperties as TreatmentPlanPhaseOneBaseProperties)[path] as string[]) = [...currentIds, id];
            }
    },
    toggleTreatmentPlanPhase1DetailIds: (
        state: IReportingState,
        action: PayloadAction<{ path: keyof TreatmentPlanPhaseOneDetailProperties; id: string }>,
    ): void => {
        const { id, path } = action.payload;
        if (!(state.reportProperties as TreatmentPlanPhaseOneDetailProperties)[path])
            ((state.reportProperties as TreatmentPlanPhaseOneDetailProperties)[path] as unknown[]) = [];

        const currentIds = (state.reportProperties as TreatmentPlanPhaseOneDetailProperties)[path] as string[];

        if (currentIds)
            if (currentIds?.includes(id)) {
                ((state.reportProperties as TreatmentPlanPhaseOneDetailProperties)[path] as string[]) = currentIds.filter(
                    (_id) => _id !== id,
                );
            } else {
                ((state.reportProperties as TreatmentPlanPhaseOneDetailProperties)[path] as string[]) = [...currentIds, id];
            }
    },
    setTreatmentPlanPhase1BaseDenominatorCodes: (state: IReportingState): void => {
        (state.reportProperties as TreatmentPlanPhaseOneBaseProperties).denominatorCodes = ['D0120', 'D0145', 'D0150'];
    },
};
