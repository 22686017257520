import { EncounterSummaryWithLineItems } from 'api/models/encounter-ledger.model';
import { FormEvent, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { distributeAndUpdatePatientAdjustmentTransactions } from 'state/slices/ledger/patient-payments-and-adjustments/patient-payments-and-adjustments.actions';
import {
    selectPatientAdjustmentTransactions,
    selectPatientOverpaymentOrOverAdjustment,
    selectPatientPaymentTransactions,
    selectPaymentAndAdjustmentAmounts,
} from 'state/slices/ledger/patient-payments-and-adjustments/patient-payments-and-adjustments.selectors';
import BaseTransactionField from '../components/BaseTransactionField';
import { getMaxPayment, getTransactionAmount } from '../LedgerUtils';

type Props = {
    encounterSummary: EncounterSummaryWithLineItems;
};

export default function AdjustmentEncounterTransactionField({ encounterSummary }: Props) {
    const dispatch = useDispatch();
    const overadjustment = useSelector(selectPatientOverpaymentOrOverAdjustment);
    const { remainingAdjustmentAmount, adjustmentAmount } = useSelector(selectPaymentAndAdjustmentAmounts);

    const paymentTransactions = useSelector(selectPatientPaymentTransactions);
    const adjustmentTransactionsLookup = useSelector(selectPatientAdjustmentTransactions);

    const totalTransactionAmount = useCallback(() => {
        const procedures = encounterSummary.procedureSummaries?.filter((p) => p.commonPatientFee > 0);
        const encounterId = encounterSummary.encounterId ?? '';
        const encounterPaymentTransactionAmounts = procedures?.map(
            (procedure) => paymentTransactions[encounterId]?.[procedure.patientTransactionId ?? '']?.amount ?? 0,
        );
        const encounterAdjustmentTransactionAmounts = procedures?.map(
            (procedure) => adjustmentTransactionsLookup[encounterId]?.[procedure.adjustmentTransactionId ?? '']?.amount ?? 0,
        );

        return {
            paymentTransaction: encounterPaymentTransactionAmounts?.reduce((t1, t2) => t1 + t2, 0),
            adjustmentTransactions: encounterAdjustmentTransactionAmounts?.reduce((t1, t2) => t1 + t2, 0),
        };
    }, [encounterSummary, paymentTransactions, adjustmentTransactionsLookup]);

    const _onChangeAmount = (e?: FormEvent, input?: number) => {
        const { adjustmentTransactions, paymentTransaction } = totalTransactionAmount();
        const amount = getTransactionAmount({
            input,
            patientFee: encounterSummary.totalCommonPatientFee - (paymentTransaction ?? 0),
            remainingPaymentAmount: remainingAdjustmentAmount,
            transactionAmount: adjustmentTransactions,
        });

        dispatch(distributeAndUpdatePatientAdjustmentTransactions(amount, encounterSummary));
    };

    const disabled = overadjustment || !adjustmentAmount;

    return (
        <BaseTransactionField
            transactionAmount={totalTransactionAmount().adjustmentTransactions}
            max={getMaxPayment({ overpayment: overadjustment, paymentAmount: encounterSummary.totalCommonPatientFee })}
            onChange={_onChangeAmount}
            disabled={disabled}
        />
    );
}
