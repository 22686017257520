import { MessageBarType } from '@fluentui/react';
import { EncounterStatus } from 'api/models/encounter.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import {
    EditEncounterWorkList,
    EncounterWorkList,
    IEncounterWorkListState,
    initialEncounterWorkListState,
} from './encounter-worklists/encounter-worklists.state';
import { RecentWorkListItem } from 'utils/appLocalStorage';
import { WorkListLayerComponents } from 'pages/Landing/AdminHuddle/components/WorkListComponentLookup';
import DashboardBoardTotals from 'api/models/dashboard-totals.model';

export enum WorkListDetail {
    ReadyToReviewFeeSum = 'readyToReviewFeeSum',
    PatientOpenBilledEncountersSum = 'patientOpenBilledEncountersSum',
    InsuranceOpenBilledEncountersSum = 'insuranceOpenBilledEncountersSum',
    DeniedEncountersFeeSum = 'deniedEncountersFeeSum',
    ReadyToRebillEncountersFeesSum = 'readyToRebillEncountersFeesSum',
    ReadyToRebillEncountersOnHoldFeesSum = 'readyToRebillEncountersOnHoldFeesSum',
    EditEncountersFeeSum = 'editEncountersFeeSum',
}

export enum MissingSlipsWorkList {
    AmendRequireEncounters = 'AmendRequireEncounters',
    AwaitingAttestation = 'AwaitingAttestation',
    OutstandingCheckInAppointments = 'OutstandingCheckInAppointments',
    OutstandingCheckoutAppointments = 'OutstandingCheckoutAppointments',
}

type AdditionalWorkListState = IEncounterWorkListState;

export type WorkList = EncounterWorkList | EditEncounterWorkList | MissingSlipsWorkList | 'Predeterminations';
export type WorkListDetailData = Record<WorkListDetail, IWorkListDetailDataItem>;
export type WorkListConfirmModalType = EncounterStatus.CorrectionsCompleted | EncounterStatus.Billed | 'Rebill';

export interface IWorkListDetailDataItem {
    data?: number;
    loading: LoadingStatus;
}

export type WorkListOpenLayers = Pick<Record<WorkListLayerComponents, boolean>, 'CancelAppointmentModal' | 'EditPatientPanel'>;

export interface IWorkListsState extends AdditionalWorkListState {
    //Pagination
    currentPageNumber: number;
    itemsPerPage: number;
    totalItems?: number;
    pageCount?: number;
    continuationTokens?: (string | null)[];

    //Work list data
    data?: unknown[];

    //Current work list item to edit
    editWorkListItem?: unknown;

    //Selected work list items
    selectedWorkListItems?: unknown[];

    //Detail data to be displayed on admin huddle.
    detailData?: DashboardBoardTotals;
    detailDataLoading: LoadingStatus;

    //Currently viewed work list
    currentWorkList?: WorkList;

    //Recently viewed work list item.
    recentWorkListItems?: RecentWorkListItem[];
    loadingRecentWorkListItem: LoadingStatus;

    //Loading/Saving for work list
    loadingTotalItems: LoadingStatus;
    loading: LoadingStatus;
    saving: LoadingStatus;

    //Message bar for WorkListMessageBar component
    messageBarMessage?: string;
    messageBarType?: MessageBarType;

    //Flags
    readOnly: boolean;

    //Filters
    filters: Record<string, unknown>;
    initialFilters: Record<string, unknown>;
    hasSearched: boolean;

    //Open work list layer components
    openLayers: Partial<WorkListOpenLayers>;
}

//Initial state:
export const workListsState: IWorkListsState = {
    //Pagination
    currentPageNumber: 1,
    itemsPerPage: 25,

    readOnly: false,

    //Work list loading/saving.
    loadingRecentWorkListItem: LoadingStatus.Idle,
    loadingTotalItems: LoadingStatus.Idle,
    loading: LoadingStatus.Idle,
    saving: LoadingStatus.Idle,

    //Filters
    filters: {},
    initialFilters: {},
    hasSearched: false,

    detailDataLoading: LoadingStatus.Idle,
    //Open work list layer components
    openLayers: {},

    //Additional state for specific work lists.
    ...initialEncounterWorkListState,
};
