import { IPredeterminationProcedure } from "api/models/predeterminations.model"
import { ISortableColumn } from "components/SortableDetailsList/SortableDetailsList"
import { classicDateOnly } from "utils/dateOnly"

const SignedDateColumn: ISortableColumn<IPredeterminationProcedure> = {
    name: "Signed Date",
    minWidth: 100,
    maxWidth: 100,
    key: "signedDate",
    fieldName: 'signedDate',
    onRender: (item) => {
        return <span>{item?.signedDate ? classicDateOnly(item.signedDate) : ''}</span>
    }
}

export default SignedDateColumn
