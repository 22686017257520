import { Path } from 'history';
import { RouteParams } from 'interfaces/route-params';
import { useHistory, useParams } from 'react-router-dom';

export default function useRouter() {
    const { tenantId, patientId, encounterId } = useParams<RouteParams>();
    const { push: pushHistory } = useHistory();

    /**
     * Returns the base parameters in path format that exist in the current url path.
     * Ex: /:tenantId/patient/:patientId/encounter/:encounterId
     */
    function getBaseParameterPath() {
        let urlPath = `/${tenantId}`;
        if (patientId) urlPath += `/patient/${patientId}`;
        if (encounterId) urlPath += `/encounter/${encounterId}`;
        return urlPath;
    }

    function pushWithBaseParameters(path: Path) {
        pushHistory(`${getBaseParameterPath()}/${path}`);
    }

    return { getBaseParameterPath, pushWithBaseParameters };
}
