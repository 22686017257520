import { IChartProcedure } from 'api/models/chart.model';
import { ToothArea } from 'api/models/tooth-area';
import { flatten, some } from 'lodash';
import {
    ULReferences,
    URReferences,
    LLReferences,
    LRReferences,
    SpriteReference,
    getQuadrantOfTooth,
} from 'pages/Charting/components/ToothCanvas/spriteList';
import ProcedureCodes from '../../procedureCodes';
import { createRule } from '../../ruleGenerator';
import ProcedureBusinessRulesPipeline from '../procedureBusinessRules.pipeline';

export const quadrantFirstAndAdditionalSiteCodes = [ProcedureCodes.D4263, ProcedureCodes.D4264];

/**
 * Gets all the tooth numbers in a quad determined by the given tooth.
 *
 * @export
 * @param {number} toothNumber
 * @return {*}  {number[]}
 */
export function getQuadrantOfToothReferences(toothNumber: number): number[] {
    function mapReferencesToTeeth(refs: SpriteReference[]) {
        return flatten(refs.map((tooth) => tooth.id));
    }
    if (ULReferences.find((ref) => ref.id === toothNumber)) return mapReferencesToTeeth(ULReferences);
    if (URReferences.find((ref) => ref.id === toothNumber)) return mapReferencesToTeeth(URReferences);
    if (LLReferences.find((ref) => ref.id === toothNumber)) return mapReferencesToTeeth(LLReferences);
    if (LRReferences.find((ref) => ref.id === toothNumber)) return mapReferencesToTeeth(LRReferences);
    return [];
}

const quadrantFirstAndAdditionalSite = createRule<ProcedureBusinessRulesPipeline, IChartProcedure>({
    ruleTypes: quadrantFirstAndAdditionalSiteCodes,
    rule: (pipeline, item) => {
        function getChartProceduresByQuad(chartProcedures: IChartProcedure[], teethInQuadrant: number[]): IChartProcedure[] {
            return chartProcedures.filter((p) => (p.toothIds?.length ? teethInQuadrant.includes(p.toothIds[0]) : false));
        }

        if (item?.toothIds) {
            const tooth = item.toothIds[0];
            const teethInQuadrant = getQuadrantOfToothReferences(tooth);
            if (teethInQuadrant.length) {
                // We need to make sure we find any procedures of type D4263 that aren't this one for this quad...

                const pipelineProceduresByQuad = getChartProceduresByQuad(pipeline.getItems, teethInQuadrant);
                const chartProceduresListByQuad = getChartProceduresByQuad(pipeline.chartProceduresList, teethInQuadrant);

                const chartProceduresWithoutPipelineProcs = chartProceduresListByQuad.filter(
                    (procedure) => pipeline.getItems.findIndex((p) => procedure.id === p.id) === -1,
                );
                const nonCurrentItemPipelineChartProcedures = pipelineProceduresByQuad.filter(
                    (procedure) => procedure.id !== item.id,
                );

                const chartProceduresForQuad = [...chartProceduresWithoutPipelineProcs, ...nonCurrentItemPipelineChartProcedures];

                const D4263Procedure = pipeline.getProcedureFromCode('D4263');
                const D4264Procedure = pipeline.getProcedureFromCode('D4264');
                //The on change method for the tooth selector no longer sorts its callback array internally. This is necessary
                //for code, like the logic below, to understand which tooth was the first clicked.

                //We need to come at this from two perspectives, one being that the user initially selected a D4263 the other being that user initially selected a D4264...
                const D4263ExistsInQuad =
                    some(chartProceduresForQuad, ['procedureId', D4263Procedure?.id]) &&
                    pipelineProceduresByQuad.filter((procedure) => procedure.procedureId === D4263Procedure?.id)[0]?.id !==
                        item.id;

                const D4264TransformedToD4263ExistsInQuad =
                    pipelineProceduresByQuad.filter((procedure) => procedure.procedureId === D4264Procedure?.id)[0]?.id ===
                    item.id;

                const quad = getQuadrantOfTooth(teethInQuadrant[0]);
                const areas = (quad ? [quad] : []) as ToothArea[];

                return {
                    updatedItems: D4263ExistsInQuad
                        ? { ...item, procedureId: D4264Procedure?.id ?? item.procedureId, areas }
                        : D4264TransformedToD4263ExistsInQuad
                        ? { ...item, procedureId: D4263Procedure?.id ?? item.procedureId, areas }
                        : item,
                };
            }
        }

        return { updatedItems: item };
    },
});

export default quadrantFirstAndAdditionalSite;
