import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'state/store';
import { PredeterminedRequestsState } from './predeterminations.state';

export const selectPredeterminations = (state: RootState): PredeterminedRequestsState => state.adminHuddle.predeterminations;

export const selectPredeterminationFilters = createSelector(selectPredeterminations, ({ filters }) => filters);

// Edit Predeterminations
export const selectEditPredeterminationModalOpen = createSelector(selectPredeterminations, ({ editModalOpen }) => editModalOpen);
export const selectSelectedPredetermination = createSelector(
    selectPredeterminations,
    ({ selectedPredetermination }) => selectedPredetermination,
);
