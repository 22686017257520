import { DocumentBase } from 'api/document-base.model';
import { DisplayNameSupport } from 'models/contracts';

type ReportingGroupSupports = DocumentBase & DisplayNameSupport;

export enum ReportingGroupTypes {
    Payer = 'Payer',
    LocationOfCare = 'Location of Care',
    TreatingProvider = 'Provider',
}

export interface IReportingGroup extends ReportingGroupSupports {
    description?: string;
    type?: string; // id of reporting group type
    reportingCategories?: string[]; // guid ids that refence reporting categories.
}

export interface IReportingCategory extends ReportingGroupSupports {
    description?: string;
    items?: string[]; // ids of whatever type this is (payer id/loc id/provider id)
}

export interface IReportingType extends DocumentBase {
    displayName: ReportingGroupTypes;
}

export const reportingGroupTypes: (ReportingGroupTypes | string)[] = [
    ReportingGroupTypes.LocationOfCare,
    ReportingGroupTypes.Payer,
    ReportingGroupTypes.TreatingProvider,
];

export default IReportingGroup;
