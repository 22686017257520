import Tooth from './Tooth';
import * as PIXI from 'pixi.js-legacy';
import PixiText from './PixiText';
import ToothOverlay from './ToothOverlay';

export default class Impacted extends ToothOverlay {
    private graphics = new PIXI.Graphics();
    private _toothWidth;

    constructor(tooth: Tooth, text?: string) {
        super(tooth);
        this.name = 'impacted';
        this.tooth = tooth;
        this._toothWidth = tooth.getToothWidth;
        this.zIndex = 2;
        this.interactive = false;
        this._createGraphics(text);
    }

    private _createGraphics(informationText?: string) {
        this.graphics.lineStyle(3, 0x000000);
        this.graphics.drawEllipse(
            this._toothWidth / 2,
            !this.getIsBottomRow ? -this.tooth.height / 2 + 32 : this.tooth.height / 2 + 88,
            this._toothWidth / 2 - 2,
            !this.getIsBottomRow ? this.tooth.height / 2 : this.tooth.height / 2,
        );

        const text = new PixiText(`${informationText}`);
        text.anchor.set(0.5);
        text.y = !this.getIsBottomRow ? -this.tooth.height + 60 : this.tooth.height + 50;
        text.x = this._toothWidth / 2;
        text.name = 'impacted-surfaces';

        this.addChild(this.graphics, text);
    }
}
