import { Dictionary } from '@reduxjs/toolkit';
import IPatientPrescription from 'api/models/patient-prescription';
import { usePatientId, useSelector, useTenantId } from 'hooks';
import useEncounterId from 'hooks/useEncounterId';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { chartProcedureDiagnosesByCurrentEncounter } from 'state/slices/encounter/encounter.selectors';
import IPrescriptionItem from 'api/models/prescription.model';
import {
    addCurrentPatientPrescription,
    getAllPatientPrescriptions,
    searchMedicationsPrescriptionPanel,
    setNewPrescription,
} from 'state/slices/prescriptions-panel/prescriptions-panel.actions';
import {
    patientPrescriptionsList,
    selectPrescriptionsPanel,
} from 'state/slices/prescriptions-panel/prescriptions-panel.selectors';
import {
    cleanupPrescriptionsMedicationsSearch,
    cleanupPrescriptionsPanelState,
    setCurrentPrescription,
    setMedicationSearch,
    setPrescriptionsPanelOpen,
    updateCurrentPrescription,
} from 'state/slices/prescriptions-panel/prescriptions-panel.slice';

function usePatientPrescriptionsPanel() {
    const _dispatch = useDispatch();

    const patientId = usePatientId();
    const tenantId = useTenantId();
    const encounterId = useEncounterId();

    const {
        loading,
        isOpen,
        error,
        prescriptionResults: medicationsResults,
        prescriptionSearch: medicationSearch,
        searchingPrescriptionItems: searchingMedications,
        selectedPatientPrescription,
    } = useSelector(selectPrescriptionsPanel);
    const _patientPrescriptionsList = useSelector(patientPrescriptionsList);

    const _setCurrentPrescription = useCallback(
        (prescription: IPatientPrescription) => {
            _dispatch(setCurrentPrescription(prescription));
        },
        [_dispatch],
    );

    const _getAllPatientPrescriptions = useCallback(() => {
        if (patientId) _dispatch(getAllPatientPrescriptions({ patientId, tenantId }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientId, tenantId, encounterId]);

    const _addCurrentPatientPrescription = useCallback(() => {
        if (patientId && selectedPatientPrescription)
            _dispatch(addCurrentPatientPrescription({ patientId, tenantId, model: selectedPatientPrescription }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientId, tenantId, selectedPatientPrescription]);

    const _searchMedicationsPrescriptionPanel = useCallback(
        (query: string) => {
            _dispatch(searchMedicationsPrescriptionPanel({ query }));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [_dispatch],
    );

    const _cleanupPrescriptionsMedicationsSearch = useCallback(() => {
        _dispatch(cleanupPrescriptionsMedicationsSearch());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_dispatch]);

    const _cleanupPrescriptionsPanelState = useCallback(() => {
        _dispatch(cleanupPrescriptionsPanelState());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_dispatch]);

    const _setPrescriptionsPanelOpen = useCallback(
        (isOpen: boolean) => {
            _dispatch(setPrescriptionsPanelOpen(isOpen));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [_dispatch],
    );

    const _setMedicationSearch = useCallback(
        (medicationSearch: string) => {
            _dispatch(setMedicationSearch(medicationSearch));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [_dispatch],
    );

    const _setNewPrescription = useCallback(
        (prescriptionItem: IPrescriptionItem) => {
            if (patientId && encounterId) _dispatch(setNewPrescription(prescriptionItem, patientId, encounterId));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [_dispatch, patientId, encounterId],
    );

    //updateCurrentPrescription

    const _updateCurrentPrescription = useCallback(
        (path: keyof IPatientPrescription, value: string | boolean | Dictionary<string> | number | undefined) => {
            _dispatch(updateCurrentPrescription({ path, value }));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [_dispatch],
    );

    return {
        // Selectors
        selectedPatientPrescription,
        patientPrescriptionsList: _patientPrescriptionsList,
        medicationsResults,
        medicationSearch,
        searchingMedications,
        loading,
        isOpen,
        error,

        //Actions
        getAllPatientPrescriptions: _getAllPatientPrescriptions,
        cleanupPrescriptionsPanelState: _cleanupPrescriptionsPanelState,
        cleanupPrescriptionsMedicationsSearch: _cleanupPrescriptionsMedicationsSearch,
        setPrescriptionsPanelOpen: _setPrescriptionsPanelOpen,
        searchMedicationsPrescriptionPanel: _searchMedicationsPrescriptionPanel,
        setMedicationSearch: _setMedicationSearch,
        setNewPrescription: _setNewPrescription,
        updateCurrentPrescription: _updateCurrentPrescription,
        addCurrentPatientPrescription: _addCurrentPatientPrescription,
        setCurrentPrescription: _setCurrentPrescription,
    };
}

export default usePatientPrescriptionsPanel;
