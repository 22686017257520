import { CSSProperties, ReactNode } from 'react';
import { Stack, StackItem, Label, useTheme } from '@fluentui/react';

type Props = {
    text: string;
    headerStyle?: CSSProperties;
    labelStyle?: CSSProperties;
    headingCenterContent?: ReactNode;
    headingEndContent?: ReactNode;
    isRequired?: boolean;
};

const SubSectionHeader = (props: Props): JSX.Element => {
    const theme = useTheme();
    const headerStyles: CSSProperties = {
        padding: '5px 5px 5px 10px',
        borderBottom: '1px solid' + theme.palette.neutralQuaternaryAlt,
        display: 'flex',
        alignItems: 'center',
        zIndex: 20,
        ...props.headerStyle,
    };

    return (
        <Stack style={{ ...headerStyles }} horizontal tokens={{ childrenGap: 20 }}>
            <StackItem>
                <Label style={{ padding: 0, fontSize: 18, ...props.labelStyle }} required={props.isRequired}>
                    {props.text}
                </Label>
            </StackItem>
            <StackItem grow={1}>
                <Stack>{props.headingCenterContent}</Stack>
            </StackItem>
            <StackItem>
                <Stack horizontal style={{ justifyContent: 'flex-end', display: 'flex' }} tokens={{ childrenGap: 10 }}>
                    {props.headingEndContent}
                </Stack>
            </StackItem>
        </Stack>
    );
};

export default SubSectionHeader;
