import { Dictionary } from '@reduxjs/toolkit';
import { IProcedureCategory } from './procedure-category.model';
import { ChangeAuditSupport, DisplayNameSupport, ETagSupport, IdSupport, SoftDeleteSupport } from './support-types';

type BenefitPlanSupports = IdSupport & ChangeAuditSupport & SoftDeleteSupport & ETagSupport & DisplayNameSupport;

export interface IBenefitPlan extends BenefitPlanSupports {
    categories?: IProcedureCategory[];
    insuranceClasses?: IInsuranceClass[];
}

export interface IInsuranceClass {
    id?: string;
    displayName?: BenefitPlanInsuranceClassName;
    percentage: number;
}

export enum BenefitPlanInsuranceClassName {
    Preventative = 'Preventative',
    Basic = 'Basic',
    Major = 'Major',
    Ortho = 'Ortho',
}

export type BenefitPlans = Dictionary<IBenefitPlan>;
