import { PayloadAction } from '@reduxjs/toolkit';
import { SlidingFeeEncounterDetailsQuery } from 'api/models/sliding-fee-encounter-details-report.model';
import { isArray } from 'lodash';
import { IReportingState } from '../reporting.state';

const slidingFeeEncounterDetailsReducers = {
    setSlidingFeeEncounterDetailsProp: (
        state: IReportingState,
        action: PayloadAction<{ path: keyof SlidingFeeEncounterDetailsQuery; value: string | object | undefined }>,
    ): void => {
        const { path, value } = action.payload;
        ((state.reportProperties as SlidingFeeEncounterDetailsQuery)[path] as string | object | undefined) = value;
    },
    updateSlidingFeeEncounterDetailQueryProp: (
        state: IReportingState,
        action: PayloadAction<{ path: keyof SlidingFeeEncounterDetailsQuery; value: string | undefined; toggle?: boolean }>,
    ): void => {
        const { path, value, toggle } = action.payload;
        if (value) {
            const queryProp = (state.reportProperties as SlidingFeeEncounterDetailsQuery as SlidingFeeEncounterDetailsQuery)[
                path
            ];

            if (toggle) {
                const list: string[] = (queryProp as string[] | undefined) ?? [];
                const indexOfItem = list.findIndex((item) => item === value);
                if (indexOfItem > -1) {
                    ((state.reportProperties as SlidingFeeEncounterDetailsQuery as SlidingFeeEncounterDetailsQuery)[
                        path
                    ] as string[]) = [...list.slice(0, indexOfItem), ...list.slice(indexOfItem + 1)];
                } else {
                    ((state.reportProperties as SlidingFeeEncounterDetailsQuery as SlidingFeeEncounterDetailsQuery)[
                        path
                    ] as string[]) = [...list, value];
                }
            } else {
                ((state.reportProperties as SlidingFeeEncounterDetailsQuery as SlidingFeeEncounterDetailsQuery)[path] as string) =
                    value;
            }
        }
    },
};

export default slidingFeeEncounterDetailsReducers;
