import { Stack, IconButton, IButtonStyles, Text, IContextualMenuItem } from '@fluentui/react';
import { ClinicalAlertType, clinicalAlertTypeDisplayName } from 'api/models/clinical-alert.model';
import { Section } from 'components';
import { RouteParams } from 'interfaces/route-params';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { addNewClinicalAlert } from 'state/slices/clinical-alert/clinical-alerts.slice';
import AlertsDetailsList from './AlertsDetailList';
import { map, sortBy } from 'lodash';

const menuItems: IContextualMenuItem[] = map(ClinicalAlertType, (type) => ({
    key: type,
    text: clinicalAlertTypeDisplayName[type],
}));

export default function Alerts() {
    const dispatch = useDispatch();
    const { patientId } = useParams<RouteParams>();
    const menuButtonStyles: IButtonStyles = {
        root: {
            height: 25,
            maxWidth: 25,
        },
        flexContainer: {
            selectors: {
                '.ms-Button-menuIcon': {
                    display: 'none',
                },
            },
        },
    };

    const alertLists = map(sortBy(ClinicalAlertType), (type) => <AlertsDetailsList type={type} key={type} />);

    return (
        <Section
            heading={
                <Stack tokens={{ childrenGap: 10 }} horizontal verticalAlign="center" grow>
                    <Text variant="xLarge">Alerts</Text>
                    <IconButton
                        menuProps={{
                            items: menuItems,
                            onItemClick: (ev, item) => {
                                dispatch(addNewClinicalAlert({ type: item?.key as ClinicalAlertType, patientId }));
                            },
                        }}
                        styles={menuButtonStyles}
                        iconProps={{ iconName: 'Add' }}
                    />
                </Stack>
            }
        >
            {alertLists}
        </Section>
    );
}
