import { Stack } from '@fluentui/react';
import BpVitalsProperties from './BpVitalsProperties';
import HtVitalsProperties from './HtVitalsProperties';
import PulseVitalsProperties from './PulseVitalsProperties';
import RRVitalsProperties from './RRVitalsProperties';
import SatVitalsProperties from './SatVitalsProperties';
import TVitalsProperties from './TVitalsProperties';
import WtVitalsProperties from './WtVitalsProperties';

export default function VitalsEditProperties(): JSX.Element {    

    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <BpVitalsProperties />
            <HtVitalsProperties />
            <WtVitalsProperties />

            <PulseVitalsProperties />
            <TVitalsProperties />
            <RRVitalsProperties />
            <SatVitalsProperties />
        </Stack>
    );
}
