import CombinedShape from './CombinedShape';
import Tooth from './Tooth';
import * as PIXI from 'pixi.js-legacy';

class ToothSprite extends PIXI.Sprite {
    constructor(textureName: string, tooth: Tooth, showSprite?: boolean) {
        super();
        this.setTexture = textureName;

        this.showSprite = showSprite === undefined ? tooth.isToothVisible : showSprite;

        this.zIndex = 0;
        this.width = tooth.getToothWidth;
        this.height = tooth._getHeight(this);
    }

    public set showSprite(show: boolean | undefined) {
        if (show) {
            this.alpha = 255;
        } else {
            this.alpha = 0;
        }
    }

    public set setTexture(textureName: string) {
        this.name = textureName;
        const spriteTexture = PIXI.Texture.from(textureName);

        const spriteShape = PIXI.Loader.shared.resources.toothPolys.data[Tooth.getTextureName(textureName)];
        if (spriteShape) {
            const composed = CombinedShape.parse(spriteShape.shapes[0].points);
            this.hitArea = composed;
        }

        this.texture = spriteTexture;
    }
}

export default ToothSprite;
