import { Dropdown, IDropdownProps } from '@fluentui/react';
import { useSelector, useTenantId } from 'hooks';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { selectLocationOfCareOptions, selectLocationsOfCareLoading } from 'state/slices/scheduling/scheduling.selectors';
import { cleanupLocationsOfCare, getLocationsOfCare } from 'state/slices/scheduling/scheduling.slice';

export function LocationsOfCareDropdown(props: Partial<IDropdownProps>): JSX.Element {
    const dispatch = useDispatch();
    const tenantId = useTenantId();
    const locOptions = useSelector(selectLocationOfCareOptions);
    const loading = useSelector(selectLocationsOfCareLoading);

    useEffect(() => {
        dispatch(getLocationsOfCare({ tenantId }));
        return () => {
            dispatch(cleanupLocationsOfCare());
        };
    }, []);
    const disabled = loading !== LoadingStatus.Completed;
    const placeHolderText = disabled ? 'Loading...' : 'Select location...';
    return <Dropdown {...props} placeholder={placeHolderText} options={locOptions} disabled={disabled} />;
}
