enum ConditionCodes {
    OpenContactDistal = '3',
    OpenContactMesial = '4',

    AbscessPeriodontalDistal = '19',
    AbscessPeriodontalMesial = '2',

    Missing = '27',
}

export default ConditionCodes;
