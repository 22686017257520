import { DefaultButton, IButtonStyles, IconButton, PrimaryButton, Stack, Text, useTheme } from '@fluentui/react';
import { useSelector } from 'hooks';
import { RouteParams } from 'interfaces/route-params';
import PatientDetailsCard from 'pages/components/PatientDetailsCard';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    selectCurrentAppointmentSelectedProcedures,
    selectCurrentScheduleAppointmentValidationErrors,
} from 'state/slices/scheduling/schedule-appointment/schedule-appointment.selectors';
import {
    selectPatientOverviewOpen,
    selectSelectedAppointmentData,
    selectSelectedAppointmentLoading,
    selectSelectedAppointmentPatient,
    selectSelectedAppointmentPatientEncounter,
} from 'state/slices/scheduling/scheduling.selectors';
import {
    closeAppointmentOverview,
    setCancelAppointmentModalOpen,
    updatePatientAppointment,
} from 'state/slices/scheduling/scheduling.slice';
import MiniPatientAccount from './components/MiniPatientAccount';
import { EncounterStatus } from 'api/models/encounter.model';
import IPatientAppointment from 'api/models/Scheduling/patientAppointment.model';
import { useCallback, useEffect } from 'react';
import { getDisableCancelAppointment } from 'pages/components/CancelAppointmentModal/CancelAppointmentModal';

type AppointmentOverviewProps = {
    onEditPatient?: (params: { tenantId: string; patientId: string }) => void;
} & AppointmentOverviewButtonProps;

function AppointmentOverview({
    onEditPatient,
    onSaveAppointmentOverview,
    onCancelAppointment,
}: AppointmentOverviewProps): JSX.Element | null {
    const theme = useTheme();
    const dispatch = useDispatch();

    const isPatientOverviewOpen = useSelector(selectPatientOverviewOpen);

    const onClose = useCallback(() => {
        dispatch(closeAppointmentOverview());
    }, [dispatch]);

    useEffect(() => {
        return () => {
            onClose();
        };
    }, [onClose]);

    if (!isPatientOverviewOpen) return null;

    const iconButtonStyles: Partial<IButtonStyles> = {
        root: {
            color: theme.palette.magenta,
            marginLeft: 'auto',
            marginTop: '4px',
            marginRight: '2px',
        },
        rootHovered: {
            color: theme.palette.neutralDark,
        },
    };

    return (
        <Stack
            tokens={{ childrenGap: 5 }}
            styles={{
                root: { width: 600, backgroundColor: theme.palette.white, padding: 10, paddingTop: 0 },
            }}
        >
            <Stack.Item>
                <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
                    <Stack.Item>
                        <Text variant="xLarge">Appointment Overview</Text>
                    </Stack.Item>
                    <Stack.Item>
                        <IconButton styles={iconButtonStyles} iconProps={{ iconName: 'Cancel' }} text="Close" onClick={onClose} />
                    </Stack.Item>
                </Stack>
            </Stack.Item>
            <PatientDetailContainer onEditPatient={onEditPatient} />
            <MiniPatientAccount />
            <AppointmentOverviewButtons
                onCancelAppointment={onCancelAppointment}
                onSaveAppointmentOverview={onSaveAppointmentOverview}
            />
        </Stack>
    );
}

export default AppointmentOverview;

type AppointmentOverviewButtonProps = {
    onSaveAppointmentOverview?: (updatedAppointment: IPatientAppointment) => void;
    onCancelAppointment?: (appointmentToRemoveId: string) => void;
};

function AppointmentOverviewButtons({ onSaveAppointmentOverview, onCancelAppointment }: AppointmentOverviewButtonProps) {
    const dispatch = useDispatch();
    const { tenantId } = useParams<RouteParams>();

    const patientAppointment = useSelector(selectSelectedAppointmentData);
    const procedures = useSelector(selectCurrentAppointmentSelectedProcedures);
    const _validationErrors = useSelector(selectCurrentScheduleAppointmentValidationErrors);
    const encounter = useSelector(selectSelectedAppointmentPatientEncounter);

    const disabledIfCompleted = encounter?.status === EncounterStatus.Billed;

    const onSave = () => {
        if (patientAppointment) {
            const updatedPatientAppointment = { ...patientAppointment, procedures };
            if (onSaveAppointmentOverview) {
                onSaveAppointmentOverview(updatedPatientAppointment);
            } else {
                dispatch(
                    updatePatientAppointment({
                        tenantId,
                        appointment: updatedPatientAppointment,
                    }),
                );
            }
            dispatch(onClose());
        }
    };

    const _cancelAppointment = () => {
        if (patientAppointment) {
            if (onCancelAppointment) {
                onCancelAppointment(patientAppointment.id);
            } else {
                dispatch(setCancelAppointmentModalOpen({ isOpen: true, appointmentToRemoveId: patientAppointment.id }));
            }
        }
    };

    const onClose = () => {
        dispatch(closeAppointmentOverview());
    };

    return (
        <Stack.Item>
            <Stack horizontal horizontalAlign="space-between">
                <Stack.Item>
                    <Stack horizontal tokens={{ childrenGap: 5 }}>
                        <PrimaryButton
                            text="Save"
                            onClick={onSave}
                            disabled={
                                !patientAppointment?.treatingProviderId || _validationErrors.length >= 1 || disabledIfCompleted
                            }
                        />

                        <DefaultButton text="Discard Changes" onClick={onClose} disabled={disabledIfCompleted} />
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <DefaultButton
                        text="Cancel Appointment"
                        onClick={_cancelAppointment}
                        disabled={getDisableCancelAppointment(
                            patientAppointment?.encounterId,
                            patientAppointment?.trackerStatusId,
                        )}
                    />
                </Stack.Item>
            </Stack>
        </Stack.Item>
    );
}

function PatientDetailContainer({
    onEditPatient,
}: {
    onEditPatient?: (params: { tenantId: string; patientId: string }) => void;
}) {
    const patient = useSelector(selectSelectedAppointmentPatient);
    const loading = useSelector(selectSelectedAppointmentLoading);

    return (
        <PatientDetailsCard
            showClinicalAlerts
            onEditPatient={onEditPatient}
            patient={patient}
            enableEditPatient
            loading={loading}
        />
    );
}
