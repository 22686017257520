import { Stack, MessageBar, MessageBarType, Link, Icon } from '@fluentui/react';
import { useTenantId } from 'hooks';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    selectMergedPatientId,
    selectSelectedPatient,
    selectPatientIsMergedOrDeleted,
    selectPatientStatusIsMerged,
    selectMergedPatientMrn,
} from 'state/slices/patient/patient.selectors';

function MergedPatientWarning() {
    const mergedPatientId = useSelector(selectMergedPatientId);
    const mergedPatientMrn = useSelector(selectMergedPatientMrn);
    const patient = useSelector(selectSelectedPatient);
    const patientIsMergedOrDeleted = useSelector(selectPatientIsMergedOrDeleted);
    const patientIsMerged = useSelector(selectPatientStatusIsMerged);
    const tenantId = useTenantId();
    const { push } = useHistory();
    const _onClick = () => {
        push(`/${tenantId}/patient/${mergedPatientId}`);
    };
    if (!patientIsMergedOrDeleted) return null;

    return (
        <Stack style={{ marginLeft: 10, marginRight: 10 }}>
            <MessageBar messageBarType={MessageBarType.severeWarning}>
                WARNING: This patient has been {patient?.patientStatus}.
                {patientIsMerged && (
                    <>
                        <br />{' '}
                        <Link onClick={_onClick}>
                            Please use this link to navigate to the merged patient. <strong>Patient Id: {mergedPatientMrn}</strong>.{' '}
                            <Icon iconName="Link" />
                        </Link>
                    </>
                )}
            </MessageBar>
        </Stack>
    );
}

export default MergedPatientWarning;
