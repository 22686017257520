import React, { PropsWithChildren } from 'react';
import {
    Stack,
    IconButton,
    TooltipHost,
    DirectionalHint,
    ScrollablePane,
    ScrollbarVisibility,
    Link,
    useTheme,
    mergeStyleSets,
    Text,
} from '@fluentui/react';
import UserTasksBadge from './UserTasks/UserTasksBadge';
import IUserTask from 'api/models/user-task.model';

export interface ISectionScrollerItem {
    text: string;
    iconName: string;
    ref: React.RefObject<HTMLDivElement>;
    visible?: boolean;
    tasks?: IUserTask[];
}

type SectionScrollerProps = {
    items: ISectionScrollerItem[];
    expanded?: boolean;
    hasNoFlex?: boolean;
};

function SectionScroller(props: PropsWithChildren<SectionScrollerProps>): JSX.Element | null {
    const theme = useTheme();

    const classNames = mergeStyleSets({
        link: {
            height: 40,
            width: '100%',
            display: 'flex',
            textDecoration: 'none !important',
            justifyContent: 'start',
            alignItems: 'center',
            backgroundOrigin: 'content-box',
            position: 'relative',
            color: theme.palette.neutralPrimary,
            userSelect: 'none',
            transition: 'background .15s',
            fontSize: '16px',
            paddingLeft: 24,
            borderLeft: 'none',
            selectors: {
                '&:hover': {
                    background: theme.palette.neutralLight,
                    cursor: 'pointer',
                },
                '&:focus': {
                    background: theme.palette.neutralQuaternaryAlt,
                    borderLeft: '3px solid' + theme.palette.themePrimary,
                },
            },
        },
        linkText: {
            marginRight: 10,
        },
        itemsContainer: {
            width: 200,
            background: theme.palette.neutralLighterAlt,
            borderRight: `1px solid ${theme.semanticColors.bodyDivider}`,
        },
    });

    if (props.items.length) {
        const items = props.items
            .filter((item) => item.visible !== false)
            .map((item, index) => {
                const _onClick = () => {
                    if (item.ref && item.ref.current) {
                        item.ref.current.scrollIntoView({
                            behavior: 'smooth',
                            block: 'start',
                            inline: 'start',
                        });
                    }
                };

                return (
                    <div key={index}>
                        {props.expanded ? (
                            <Link underline={false} className={classNames.link} onClick={_onClick}>
                                <Text className={classNames.linkText}>{item.text}</Text>
                                {item.tasks?.length ? (
                                    <UserTasksBadge
                                        size="small"
                                        showTaskCount={false}
                                        style={{ paddingBottom: 2 }}
                                        userTasks={item.tasks}
                                    />
                                ) : null}
                            </Link>
                        ) : (
                            <TooltipHost content={item.text} directionalHint={DirectionalHint.rightCenter}>
                                <IconButton iconProps={{ iconName: item.iconName }} onClick={_onClick} />
                            </TooltipHost>
                        )}
                    </div>
                );
            });
        return (
            <Stack styles={{ root: { display: 'flex', paddingLeft: 10 } }} horizontal grow>
                <Stack tokens={{ childrenGap: 6 }} className={classNames.itemsContainer}>
                    {items}
                </Stack>
                <Stack grow>
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            height: '100%',
                            position: 'relative',
                        }}
                    >
                        <ScrollablePane
                            styles={!props.hasNoFlex ? { contentContainer: { display: 'flex' } } : undefined}
                            scrollbarVisibility={ScrollbarVisibility.auto}
                        >
                            {props.children}
                        </ScrollablePane>
                    </div>
                </Stack>
            </Stack>
        );
    } else {
        return null;
    }
}

export default SectionScroller;
