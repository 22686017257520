import { CommandBar, ScrollablePane, Stack } from '@fluentui/react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { cleanupTreatmentPlanCreditData, setTreatmentPlanCreditCurrentPhaseView } from 'state/slices/ledger/ledger.slice';
import {
    selectSignedTreatmentPlanIsLoading,
    selectTreatmentPlanCreditSelectedPhaseView,
} from 'state/slices/ledger/treatment-plan-credit-and-uac/treatment-plan-credit-and-uac.selectors';
import TreatmentPlanCreditList from './TreatmentPlanCreditList';
import UnappliedCreditList from './UnappliedCreditList';
import Overages from './Overages';
import TreatmentPlanCreditAndUACMessageBar from './TreatmentPlanCreditAndUACMessageBar';
import { Helmet } from 'react-helmet';

export default function TreatmentPlanCreditAndUAC() {
    const dispatch = useDispatch();
    const { push } = useHistory();

    const selectedPhaseView = useSelector(selectTreatmentPlanCreditSelectedPhaseView);
    const loading = useSelector(selectSignedTreatmentPlanIsLoading);

    useEffect(() => {
        return () => {
            dispatch(cleanupTreatmentPlanCreditData());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { url } = useRouteMatch();

    return (
        <Stack styles={{ inner: { overflowY: 'auto' } }} grow>
            <Helmet title="Ledger - Prepayment and UAC" />
            <TreatmentPlanCreditAndUACMessageBar />
            <Stack tokens={{ childrenGap: 10 }} styles={{ root: { position: 'relative' } }} grow={1}>
                <ScrollablePane>
                    <Stack tokens={{ childrenGap: 10 }} grow>
                        <Stack>
                            <CommandBar
                                styles={{ root: { paddingLeft: 0 } }}
                                items={[
                                    {
                                        key: 'addTreatmentPlanPrepayment',
                                        text: 'Add Treatment Plan Prepayment',
                                        iconProps: { iconName: 'PaymentCard' },
                                        disabled: !selectedPhaseView || loading,
                                        onClick: () => {
                                            dispatch(setTreatmentPlanCreditCurrentPhaseView(selectedPhaseView));
                                            push(`${url}/add-treatment-plan-prepayment`);
                                        },
                                    },
                                    {
                                        key: 'addUAC',
                                        text: 'Add Unapplied Credit',
                                        iconProps: { iconName: 'Add' },
                                        disabled: loading,
                                        onClick: () => {
                                            push(`${url}/add-unapplied-credit`);
                                        },
                                    },
                                ]}
                            />
                            <TreatmentPlanCreditList />
                        </Stack>
                        <UnappliedCreditList />
                        <Overages />
                    </Stack>
                </ScrollablePane>
            </Stack>
        </Stack>
    );
}
