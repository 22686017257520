import IWorkListAppointmentView from 'api/models/worklist-appointment-view';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';

const primaryPayerId: ISortableColumn<IWorkListAppointmentView> = {
    key: 'primaryPayerId',
    name: 'Prim. Ins. Id',
    fieldName: 'primaryPayerMemberId',
    minWidth: 100,
    maxWidth: 100,
    isResizable: true,
};

export default primaryPayerId;
