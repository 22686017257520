import { ChangeEvent, useState } from 'react';
import { Field } from 'components';
import { classicDateOnly } from 'utils/dateOnly';
import {
    ComboBox,
    DefaultButton,
    DetailsList,
    DetailsListLayoutMode,
    IColumn,
    Icon,
    IconButton,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    SelectionMode,
    Stack,
    TextField,
    TooltipHost,
} from '@fluentui/react';
import { IDocument, DocumentContext } from 'api/models/document.model';
import { getFileExt, getFileName, UpdatePendingDocumentPath } from 'state/slices/documents/documents.reducers';
import {
    addPendingDocuments,
    cleanupPendingDocumentsAndFiles,
    deletePendingDocumentAndFile,
    updatePendingDocument,
} from 'state/slices/documents/documents.slice';
import { useDispatch } from 'react-redux';
import { renderFileIcon, DocumentItemReference } from 'components/Upload';
import { useSelector, useTenantId } from 'hooks';
import {
    selectPendingDocuments,
    selectDocumentTypesByContext,
    documentsUploading,
} from 'state/slices/documents/documents.selectors';
import { createDocumentsAndUploadFiles } from 'state/slices/documents/documents.actions';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { every, some } from 'lodash';

type Props = {
    patientId: string;
    context: DocumentContext;
    disabled?: boolean;
    reference?: DocumentItemReference;
};

const UploadFiles = ({ disabled, context, patientId, reference }: Props): JSX.Element => {
    const dispatch = useDispatch();
    const tenantId = useTenantId();

    const uploading = useSelector(documentsUploading);
    const pendingDocuments = useSelector((state) => selectPendingDocuments(state, { context }));
    const documentTypes = useSelector((state) => selectDocumentTypesByContext(state, context));

    const [badFilesSize, setBadFilesSize] = useState<File[]>([]);
    const [badFilesExtention, setBadExtention] = useState<File[]>([]);

    function _onChangeFile(id: string, path: UpdatePendingDocumentPath, value: string | undefined) {
        dispatch(updatePendingDocument({ id, path, value }));
    }

    function _onRemoveFile(document: IDocument) {
        dispatch(deletePendingDocumentAndFile(document.id));
    }

    const columns: IColumn[] = [
        {
            key: 'column 0',
            name: 'Document Type',
            data: 'string',
            fieldName: 'displayName',
            iconName: '',
            isIconOnly: true,
            minWidth: 35,
            maxWidth: 35,
            isPadded: true,
            onRender: (item: IDocument) => (
                <TooltipHost content={`Remove '${item.displayName}.${item.extension}'`}>
                    <IconButton iconProps={{ iconName: 'PageRemove' }} onClick={() => _onRemoveFile(item)} />
                </TooltipHost>
            ),
        },
        {
            key: 'column1',
            name: 'Document Type',
            data: 'string',
            fieldName: 'displayName',
            iconName: 'Page',
            isIconOnly: true,
            minWidth: 18,
            maxWidth: 18,
            isPadded: true,
            onRender: (item: IDocument) => (
                <Icon style={{ fontSize: '16px' }} title={item.extension} iconName={renderFileIcon(item.extension)} />
            ),
        },
        {
            key: 'column2',
            name: 'File Name',
            data: 'string',
            fieldName: 'displayName',
            minWidth: 100,
            maxWidth: 190,
            isPadded: true,
            onRender: (item: IDocument) => (
                <div className="d-flex align-items-end">
                    <TextField
                        value={item.displayName}
                        autoComplete="off"
                        onChange={(e, value) => {
                            if ((e.nativeEvent as any).data === '.') {
                                e.preventDefault();
                            } else {
                                _onChangeFile(item.id, 'displayName', value);
                            }
                        }}
                        errorMessage={!item.displayName ? 'File name is required' : undefined}
                    />
                </div>
            ),
        },
        {
            key: 'column3',
            name: 'Document Type',
            data: 'object',
            fieldName: 'type',
            minWidth: 160,
            maxWidth: 190,
            isPadded: true,
            onRender: (item: IDocument) => (
                <ComboBox
                    placeholder="Select document type"
                    options={
                        documentTypes
                            ? [
                                  ...documentTypes.map((o) => ({
                                      key: o.id,
                                      text: o.displayName,
                                  })),
                              ]
                            : []
                    }
                    onChange={(e, option) => {
                        if (option) _onChangeFile(item.id, 'typeId', option.key as string);
                    }}
                    selectedKey={item.typeId}
                    errorMessage={!item.typeId ? 'Document type is required' : undefined}
                />
            ),
        },
        {
            key: 'column 5',
            name: 'Expiration Date (Optional)',
            data: 'string',
            fieldName: 'expirationDate',
            minWidth: 150,
            maxWidth: 190,
            isPadded: true,
            onRender: (item: IDocument) => (
                <Field.Date
                    value={item.expirationDate}
                    onChange={(e, value) => _onChangeFile(item.id, 'expirationDate', value)}
                    hasDatePicker
                />
            ),
        },
        {
            key: 'column 4',
            name: 'Upload Date',
            data: 'string',
            fieldName: 'uploadedDate',
            minWidth: 120,
            maxWidth: 170,
            isPadded: true,
            onRender: (item: IDocument) => <span>{item.uploadedDate ? classicDateOnly(item.uploadedDate) : 'Unknown Date'}</span>,
        },
    ];

    const _onFileSelect = (e: ChangeEvent<HTMLInputElement>) => {
        const { files } = e.target;
        const goodExtention = ['pdf', 'jpg', 'jpeg', 'png', 'txt', 'xml'];

        if (files) {
            const newFiles = [...files];
            const badFileSize = newFiles.filter((file) => file.size > 20971520);
            const badFileExtension = newFiles.filter((file) => {
                const extension = getFileExt(file.name);

                if (extension) return !goodExtention.includes(extension.toLowerCase());
                return true;
            });

            const goodFiles = newFiles.filter((file) => {
                const extension = getFileExt(file.name);
                if (extension) {
                    return file.size <= 20971520 && goodExtention.includes(extension.toLowerCase());
                }
                return false;
            });

            setBadFilesSize(badFileSize);
            setBadExtention(badFileExtension);

            dispatch(addPendingDocuments({ patientId, context, files: goodFiles, reference }));
        }
    };

    const _onSaveFiles = () => {
        dispatch(createDocumentsAndUploadFiles({ tenantId }));
        setBadFilesSize([]);
        setBadExtention([]);
    };

    const _clearAllDocumentsAndFiles = () => {
        dispatch(cleanupPendingDocumentsAndFiles());
        setBadFilesSize([]);
        setBadExtention([]);
    };

    const filesMissingDocType = !every(pendingDocuments, (doc) => doc.typeId);

    return (
        <>
            <div>
                <Field.FileInput disabled={disabled} onChange={_onFileSelect} />
            </div>
            {badFilesSize.length > 0 && (
                <MessageBar messageBarType={MessageBarType.error} isMultiline onDismiss={() => setBadFilesSize([])}>
                    Warning: File exceeds size limit. Please try again with a file size less than 20Mb
                    <br />
                    <strong>File(s): </strong>
                    {badFilesSize.map((file) => `${file.name}`)}
                </MessageBar>
            )}

            {badFilesExtention.length > 0 && (
                <MessageBar messageBarType={MessageBarType.error} isMultiline onDismiss={() => setBadExtention([])}>
                    Warning: File type not supported. Please try again with a file type of .pdf, .jpg, .jpeg, .png, or .txt
                    <br />
                    <strong>File(s): </strong>
                    {badFilesExtention.map((file) => `${file.name} `)}
                </MessageBar>
            )}
            {pendingDocuments?.length ? (
                <Stack tokens={{ childrenGap: 10 }}>
                    <DetailsList
                        items={pendingDocuments}
                        columns={columns}
                        selectionMode={SelectionMode.none}
                        layoutMode={DetailsListLayoutMode.justified}
                    />

                    <Stack tokens={{ childrenGap: 5 }} horizontal horizontalAlign="end">
                        <PrimaryButton
                            text="Save files"
                            onClick={_onSaveFiles}
                            disabled={uploading === LoadingStatus.Pending || filesMissingDocType}
                        />
                        <DefaultButton text="Clear all" onClick={_clearAllDocumentsAndFiles} />
                    </Stack>
                </Stack>
            ) : null}
        </>
    );
};

export default UploadFiles;
