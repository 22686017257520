import IWorkListAppointmentView from 'api/models/worklist-appointment-view';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';

const amendReason: ISortableColumn<IWorkListAppointmentView> = {
    key: 'amendReason',
    minWidth: 140,
    maxWidth: 140,
    isResizable: true,
    name: 'Amend Reason',
    fieldName: 'amendReason',
    onRender: AmendReasonColumn,
};

function AmendReasonColumn(item?: IWorkListAppointmentView) {
    const amendReasonText = item?.amendReason ?? '';

    return <span title={amendReasonText}>{amendReasonText}</span>;
}

export default amendReason;
