import { RootState } from 'state/store';
import { classicDateOnly } from 'utils/dateOnly';
import { timeOptions } from 'utils/getTimeOptions';

export default function NextAppointment({ state }: { state: RootState }): JSX.Element | null {
    const nextAppt = state.chartNotes.quickNotes.assets.schedule.nextAppointment;
    const providers = state.tenant.providers.data;
    if (!nextAppt) return null;

    const time = timeOptions.find((t) => t.key === nextAppt.startTime);
    const provider = providers[nextAppt.treatingProviderId];
    const hygienist = nextAppt.hygienistId ? providers[nextAppt.hygienistId] : '';
    const hygienistName = hygienist ? `${hygienist?.firstName} ${hygienist?.lastName} ${hygienist?.suffix ?? ''}` : '';
    const providerName = `${provider?.firstName} ${provider?.lastName} ${provider?.suffix ?? ''}`;

    return (
        <>
            <h3>Follow-Up/Next Visit:</h3>
            <ul>
                {nextAppt && (
                    <>
                        <li>
                            Next appointment date is {nextAppt.date ? classicDateOnly(nextAppt.date) : '(Unknown Date)'} at{' '}
                            {time?.text}
                        </li>
                        <li>Treating Provider: {providerName}</li>
                        {hygienistName ? <li>Hygienist: {hygienistName}</li> : null}
                        <li>Reason For Encounter: {nextAppt.encounterReason}</li>
                    </>
                )}
            </ul>
        </>
    );
}
