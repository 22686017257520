import { Dictionary } from '@reduxjs/toolkit';
import queryString from 'query-string';
import { DentalApiConfig } from './api.config';
import ApiClient from './apiClient';
import IAppointmentCancellationReason, {
    AppointmentCancellationDepartmentType,
} from './models/appointment-cancellation-reason.model';
import { IBillingProcedure } from './models/billing-procedure.model';
import IAppointmentAllocations, { ISchedule } from './models/Scheduling/allocation.model';
import IBlockAppointment from './models/Scheduling/blockAppointment.model';
import IOperatoryCalendarLayout from './models/Scheduling/layout.model';
import ILocationOfCare from './models/Scheduling/locationsOfCare.model';
import IOperatory from './models/Scheduling/operatory.model';
import IPatientAppointment, {
    IUpcomingAppointment,
    IPatientAppointmentQuery,
} from './models/Scheduling/patientAppointment.model';
import { ScheduleRequestQuery } from 'state/slices/scheduling/scheduling.slice';

class SchedulingApi extends ApiClient {
    constructor() {
        super(DentalApiConfig);
    }

    // Appointments
    /**
     * @description Returns a list of operatory calendar layout in a tenant based on the provided parameters.
     */
    getLayouts(tenantId: string, locationOfCareId: string, date: string) {
        return this.client.get<IOperatoryCalendarLayout[]>(
            `Tenants/${tenantId}/Appointments/Layouts?LocationOfCareId=${locationOfCareId}&Date=${date}`,
        );
    }
    /**
     * @description Returns the patient and block appointment list in a tenant based on the provided parameters.
     */
    getAllocations(tenantId: string, locationOfCareId: string, date: string) {
        return this.client.get<IAppointmentAllocations>(
            `Tenants/${tenantId}/Appointments/Allocations?LocationOfCareId=${locationOfCareId}&Date=${date}&IncludeAlerts=true`,
        );
    }
    getSchedule(tenantId: string, query: ScheduleRequestQuery) {
        const queryStr = queryString.stringify(query);
        return this.client.get<ISchedule>(`Tenants/${tenantId}/Appointments/Schedule?${queryStr}`);
    }
    /**
     * @description Returns a patient appointment in a tenant by appointment id.
     */
    getPatientAppointment(tenantId: string, appointmentId: string) {
        return this.client.get<IPatientAppointment>(`Tenants/${tenantId}/Appointments/Patients/${appointmentId}`);
    }
    /**
     * @description Adds a patient appointment in a tenant.
     */
    createPatientAppointment(tenantId: string, appointment: IPatientAppointment) {
        return this.client.post<IPatientAppointment>(`Tenants/${tenantId}/Appointments/Patients`, appointment);
    }

    createPatientAppointmentAllocation(tenantId: string, appointment: IPatientAppointment) {
        return this.client.post<IPatientAppointment>(`Tenants/${tenantId}/Appointments/Patients/Allocation`, appointment);
    }

    getPatientAppointmentsByParameters(tenantId: string, queryObject: IPatientAppointmentQuery) {
        const query = queryString.stringify(queryObject);
        return this.client.get<IAppointmentAllocations>(`Tenants/${tenantId}/Appointments/AllocationsByParameters?${query}`);
    }
    /**
     * @description Updates a patient appointment in a tenant.
     */
    updatePatientAppointment(tenantId: string, appointment: IPatientAppointment) {
        return this.client.put<IPatientAppointment>(`Tenants/${tenantId}/Appointments/Patients`, appointment);
    }

    updatePatientAppointmentAllocation(tenantId: string, appointment: IPatientAppointment) {
        return this.client.put<IPatientAppointment>(`Tenants/${tenantId}/Appointments/Patients/Allocation`, appointment);
    }
    /**
     * @description Returns a block appointment in a tenant by appointment id.
     */
    getBlockAppointment(tenantId: string, appointmentId: string) {
        return this.client.get<IBlockAppointment>(`Tenants/${tenantId}/Appointments/Blocks/${appointmentId}`);
    }
    /**
     * @description Adds a patient appointment in a tenant.
     */
    createBlockAppointment(tenantId: string, appointment: IBlockAppointment) {
        return this.client.post<IBlockAppointment>(`Tenants/${tenantId}/Appointments/Blocks`, appointment);
    }
    /**
     * @description Updates a patient appointment in a tenant.
     */
    updateBlockAppointment(tenantId: string, appointment: IBlockAppointment) {
        return this.client.put(`Tenants/${tenantId}/Appointments/Blocks`, appointment);
    }

    // LocationsOfCare
    /**
     * @description Returns a dictionary of location of cares in the tenant.
     */
    getLocationsOfCare(tenantId: string) {
        return this.client.get<Dictionary<ILocationOfCare>>(`Tenants/${tenantId}/LocationsOfCare`);
    }

    // Operatories
    /**
     * @description Returns a dictionary of operatories in the tenant.
     */
    getOperatories(tenantId: string) {
        return this.client.get<IOperatory[]>(`Tenants/${tenantId}/Operatories`);
    }
    /**
     * @description Returns a dictionary of operatories in the tenant.
     */
    getOperatoriesById(tenantId: string, operatoryId: string) {
        return this.client.get<IOperatory>(`Tenants/${tenantId}/Operatories/${operatoryId}`);
    }

    // GetTracker
    /**
     * @description Returns a patient profile from a patient appointment.
     */
    getTracker(tenantId: string, locationOfCareId: string, date: string) {
        return this.client.get<any>(`Tenants/${tenantId}/Appointments/Tracker?LocationOfCareId=${locationOfCareId}&Date=${date}`);
    }

    // Appointment Checkout
    /**
     * @description Returns a list of procedures in a tenant for a patient for checkout.
     */
    getAppointmentCheckout(tenantId: string, appointmentId: string) {
        return this.client.get<IBillingProcedure[]>(`Tenants/${tenantId}/Patient?appointmentId=${appointmentId}`);
    }

    // Upcoming Patient Appointments
    /**
     * @description Returns all upcoming patient appointments in a tenant by patient id.
     */
    getUpcomingPatientAppointments(tenantId: string, patientId: string) {
        return this.client.get<IUpcomingAppointment[]>(
            `Tenants/${tenantId}/Appointments/UpcomingPatientAppointments/${patientId}`,
        );
    }

    /**
     * Gets list of appt cancellation reasons by department type
     *
     * @param {string} tenantId
     * @param {AppointmentCancellationDepartmentType} type
     * @return {*}
     * @memberof SchedulingApi
     */
    getAppointmentCancellationReasonsByType(tenantId: string, type: AppointmentCancellationDepartmentType) {
        return this.client.get<Dictionary<IAppointmentCancellationReason>>(
            `Tenants/${tenantId}/AppointmentCancellationReasons/ByType/${type}`,
        );
    }
}

const schedulingApi = new SchedulingApi();
export default schedulingApi;
