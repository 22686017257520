import { Checkbox, MessageBar, MessageBarType, Stack, Text } from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import { TModal } from 'components';
import { IActionButton } from 'components/ActionButton';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { RouteParams } from 'interfaces/route-params';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectChartNoteSaving } from 'state/slices/chart-notes/chart-notes.selectors';
import { openNoteModal, signAndSaveNewNote } from 'state/slices/chart-notes/note-browser/note-browser.actions';
import { selectConfirmationIsOpen, selectNoteSaveErrors } from 'state/slices/chart-notes/note-browser/note-browser.selectors';
import { NoteBrowserModal } from 'state/slices/chart-notes/note-browser/note-browser.slice';

function NoteSignModal() {
    const dispatch = useDispatch();

    const isOpen = useSelector(selectConfirmationIsOpen);
    const [checked, { toggle, setFalse }] = useBoolean(false);

    const onDismiss = () => dispatch(openNoteModal(NoteBrowserModal.SignAndSave, false));
    const onDismissed = () => setFalse();
    const saveError = useSelector(selectNoteSaveErrors);

    return (
        <TModal
            title="Sign & Save"
            modalProps={{ isOpen, onDismiss, onDismissed, styles: { main: { width: 400 } } }}
            mainButtons={NoteSignActions(checked, onDismiss)}
        >
            <Stack style={{ padding: 10 }} tokens={{ childrenGap: 10 }}>
                <Text>
                    By clicking the box below you acknowledge that the information in this note is true to the best of your
                    knowledge.
                </Text>
                <Checkbox label="I agree" checked={checked} onChange={toggle} />
                {saveError && saveError.length && <MessageBar messageBarType={MessageBarType.error}>{saveError}</MessageBar>}
            </Stack>
        </TModal>
    );
}

function NoteSignActions(agreeChecked: boolean, onDismiss: () => void): IActionButton[] {
    const { tenantId, patientId } = useParams<RouteParams>();
    const dispatch = useDispatch();
    const signAndSave = () => dispatch(signAndSaveNewNote(tenantId, patientId));
    const saveError = useSelector(selectNoteSaveErrors);
    const saving = useSelector(selectChartNoteSaving);
    const isSaving = saving === LoadingStatus.Pending;

    const buttons: IActionButton[] = [
        {
            text: isSaving ? 'Saving...' : saveError ? 'Try again' : 'Sign & Save',
            primary: true,
            onClick: signAndSave,
            disabled: !agreeChecked || isSaving,
            iconProps: { iconName: 'Save' },
        },
        {
            text: 'Cancel',
            onClick: onDismiss,
        },
    ];

    return buttons;
}

export default NoteSignModal;
