import { MessageBar, SearchBox, SelectionMode, Spinner, Text } from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import IPatient from 'api/models/patient.model';
import { SortableDetailsList } from 'components';
import { EditDetailsColumn } from 'components/EditDetailsColumn';
import { isValid, parse } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { useSelector } from 'hooks';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { some } from 'lodash';
import queryString from 'query-string';
import { FormEvent } from 'react';
import { batch, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getPatients, switchPatient } from 'state/slices/patient/patient.actions';
import { selectPatients, selectPatientsLoading } from 'state/slices/patient/patient.selectors';
import { clearPatients } from 'state/slices/patient/patient.slice';
import { RecentPatient } from 'utils/appLocalStorage';
import { classicDateOnly } from 'utils/dateOnly';

function FindPatient({ setSearchClosed }: { setSearchClosed: () => void }): JSX.Element {
    const dispatch = useDispatch();
    const location = useLocation();
    const [messageBarVisible, {setTrue: openMessageBar, setFalse: closeMessageBar}] = useBoolean(false);

    const routePieces = location.pathname.split('/').filter((string) => string !== '/' && string !== '');
    const tenantId = routePieces[0];

    const _patients = useSelector(selectPatients);
    const _patientsLoading = useSelector(selectPatientsLoading);
    let timeout: any = null;
    const _handlePatientInvoked = (patient?: IPatient | RecentPatient) => {
        if (patient) {
            batch(() => {
                dispatch(switchPatient(patient));
                setSearchClosed();
            });
        }
    };

    const _handlePatientSearch = (e?: FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
        let patientQuery = '';
        if (value) {
            openMessageBar()
            const isMrn = parseInt(value) ? false : value.indexOf(':') !== -1;
            if (isMrn && value.length >= 2) {
                patientQuery = queryString.stringify({ mrn: value.replace(':', '') });
            } else {
                if (value.length >= 3) {
                    const isFirstName = parseInt(value) ? false : value.split(',')[0].length > 0 ? false : true;
                    const isLastName = parseInt(value)
                        ? false
                        : value.indexOf(',') === -1 && value.indexOf(':') === -1
                          ? true
                          : false;
                    const isFullName = parseInt(value)
                        ? false
                        : value.split(',')[0].length > 0
                          ? value.indexOf(',') !== -1
                          : false;

                    const isDoB = (): string => {
                        if (value && !isMrn) {
                            const numbers = value?.match(/\d+/g)?.join('');
                            if (numbers?.length === 6 || numbers?.length === 8) {
                                const date = numbers.replace(/(\d{2})(\d{2})(\d{2}|\d{4})/, `$1/$2/$3`);
                                const parsedDate = parse(date, 'P', new Date(), { locale: enUS });
                                const isValidDate = isValid(parsedDate);

                                if (isValidDate) {
                                    return date;
                                }
                            }
                        }
                        return '';
                    };

                    patientQuery = queryString.stringify(
                        {
                            LastName: isFullName ? value.split(',')[0].trim() : isLastName ? value : '',
                            FirstName: isFullName ? value.split(',')[1].trim() : isFirstName ? value.split(',')[1].trim() : '',
                            DateOfBirth: isDoB(),
                        },
                        { skipEmptyString: true },
                    );
                }
            }

            clearTimeout(timeout);
            if (patientQuery) {
                timeout = setTimeout(() => {
                    dispatch(
                        getPatients({
                            tenantId: tenantId,
                            query: patientQuery,
                        }),
                    );
                }, 600);
            }
        } else {
            closeMessageBar()
        }
    };
    const filteredPatients = _patients.filter((res) => res.isDeleted != true);
    return (
        <>
            {messageBarVisible && <MessageBar styles={{}}>Search by last name, first name or DOB or :PatientID</MessageBar>}
            <SearchBox
                onChange={_handlePatientSearch}
                autoComplete="off"
                placeholder="Search by last name, first name or DOB or :PatientID"
                onClear={() => {
                    dispatch(clearPatients());
                }}
                autoFocus
            />

            <PatientTable patients={filteredPatients} onItemInvoked={_handlePatientInvoked} />

            {_patientsLoading === LoadingStatus.Pending ? <Spinner label="Searching patients" /> : null}
            {_patients.length === 0 && _patientsLoading === LoadingStatus.Completed ? (
                <MessageBar styles={{ root: { marginTop: 10 } }}>No matching patients found</MessageBar>
            ) : null}
        </>
    );
}

function PatientTable({
    patients,
    onItemInvoked,
}: {
    patients: (IPatient | RecentPatient)[];
    onItemInvoked: (patient?: IPatient | RecentPatient) => void;
}) {
    if (!patients.length) return null;
    return (
        <SortableDetailsList<IPatient | RecentPatient>
            compact
            selectionMode={SelectionMode.none}
            items={patients}
            sortOnMount={true}
            sortColumns={['lastName', 'firstName', 'dob']}
            columns={[
                {
                    key: 'lastName',
                    minWidth: 70,
                    maxWidth: 150,
                    fieldName: 'lastName',
                    name: 'Last',
                    isResizable: true,
                    onRender: (item) => {
                        if (item) return <EditDetailsColumn title={`${item.lastName}`} editOnClick={() => onItemInvoked(item)} />;
                    },
                },
                {
                    key: 'firstName',
                    minWidth: 70,
                    maxWidth: 150,
                    fieldName: 'firstName',
                    name: 'First',
                    isResizable: true,
                    onRender: (item) => {
                        if (item)
                            return (
                                <EditDetailsColumn
                                    title={`${item.firstName} ${item.chosenName ? `(${item.chosenName})` : ''}`}
                                    editOnClick={() => onItemInvoked(item)}
                                />
                            );
                    },
                },
                {
                    key: 'middleInitial',
                    minWidth: 30,
                    maxWidth: 50,
                    fieldName: 'middleName',
                    name: 'MI',
                    isResizable: true,
                    onRender: (item) => {
                        const middleName = (item as IPatient).middleName || '';
                        const middleInitial = middleName ? middleName.charAt(0) : '';
                        return <EditDetailsColumn title={middleInitial} editOnClick={() => onItemInvoked(item)} />;
                    },
                },
                {
                    key: 'dob',
                    minWidth: 100,
                    fieldName: 'dateOfBirth',
                    name: 'Date of Birth',
                    isResizable: true,
                    onRender: (item) => {
                        if ((item as IPatient)?.dateOfBirth) {
                            //Date will always be the original DOB regardless of the timezone.
                            const patientDob = (item as IPatient).dateOfBirth;
                            const dob = patientDob ? classicDateOnly(patientDob) : 'N/A';
                            return <Text variant="smallPlus">{dob}</Text>;
                        }
                    },
                },
                { key: 'patientId', minWidth: 100, isResizable: true, fieldName: 'mrn', name: 'Patient ID' },
            ]}
            onItemInvoked={onItemInvoked}
        />
    );
}

export default FindPatient;
