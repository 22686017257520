export type DailyBalanceReporyQuery = {
    startDate?: string;
    endDate?: string;
    treatingProviderIds?: string[];
    locationOfCareIds?: string[];
    insuranceCarrier?: string[];
    transactionType?: DailyBalanceTransactionType;
};

export enum DailyBalanceTransactionType {
    Charges = 'Charges',
    Payments = 'Payments',
    Adjustments = 'Adjustments',
    Other = 'Other',
}
