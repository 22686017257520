import { IContextualMenuItem } from '@fluentui/react';
import { createAsyncThunk, createSlice, Dictionary, PayloadAction } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import IImagingSource, { IImagingSourceGeneric } from 'api/models/imaging-source.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { LookupState } from 'interfaces/lookup-state';
import { map, sortBy } from 'lodash';
import { createSelector } from 'reselect';
import { AppThunk, RootState } from 'state/store';
import { v4 as uuid } from 'uuid';

type ImagingSourceState = LookupState<IImagingSource> & {
    panelOpen: boolean;
    selectedImagingSource?: IImagingSource;
    genericImagingSource: Dictionary<IImagingSourceGeneric>;
    loadingGenericImageSoruces: LoadingStatus;
    loadingSelectedImagingSource: LoadingStatus;
    showHistory: boolean;
    selectedImagingSourceForTenant?: IImagingSource;
};

const initialState: ImagingSourceState = {
    data: {},
    genericImagingSource: {},
    initialLoad: LoadingStatus.Idle,
    loading: LoadingStatus.Idle,
    loadingSelectedImagingSource: LoadingStatus.Idle,
    loadingGenericImageSoruces: LoadingStatus.Idle,
    panelOpen: false,
    showHistory: false,
};

export const getGenericImagingSources = createAsyncThunk<Dictionary<IImagingSourceGeneric>, void>(
    'getGenericImagingSources',
    async () => {
        const res = await dentalApi.getImagingSourceGeneric();
        return res.data;
    },
);
export const getImagingSourceById = createAsyncThunk<IImagingSource, { tenantId: string; imagingSourceId: string }>(
    'getImagingSourceById',
    async ({ tenantId, imagingSourceId }) => {
        const res = await dentalApi.getImagingSourceById(tenantId, imagingSourceId);
        return res.data;
    },
);

export const getImagingSourcesByTenant = createAsyncThunk<Dictionary<IImagingSource>, string>(
    'getImagingSourcesByTenant',
    async (tenantId) => {
        const res = await dentalApi.getImagingSourcesByTenant(tenantId);
        return res.data;
    },
);

export const createImagingSource = createAsyncThunk<IImagingSource, { tenantId: string; imagingSource: IImagingSource }>(
    'createImagingSource',
    async ({ tenantId, imagingSource }) => {
        const res = await dentalApi.createImagingSource(tenantId, imagingSource);
        return res.data;
    },
);

export const updateImagingSource = createAsyncThunk<IImagingSource, { tenantId: string; imagingSource: IImagingSource }>(
    'updateImagingSource',
    async ({ tenantId, imagingSource }) => {
        const res = await dentalApi.updateImagingSource(tenantId, imagingSource);
        return res.data;
    },
);

const imagingSlice = createSlice({
    name: 'imagingSource',
    initialState,
    reducers: {
        setImagingSourcePanelOpen: (state, action: PayloadAction<boolean>) => {
            state.panelOpen = action.payload;
        },
        createInitlaImagingSource: (state, action: PayloadAction<string>) => {
            const genericImagingSource = state.genericImagingSource[action.payload];
            if (genericImagingSource) {
                const { displayName, type, id } = genericImagingSource;

                state.selectedImagingSource = {
                    id: ``,
                    isDeleted: false,
                    genericImagingSourceId: id,
                    displayName: `${displayName}-${type}`,
                    dropFolderPath: genericImagingSource.defaultDropFolderPath,
                    appPath: genericImagingSource.defaultAppPath,
                    imagingFolderPath: genericImagingSource.defaultImagingFolderPath,
                };
            }
        },
        toggleShowImagingSourceHistory: (state): void => {
            state.showHistory = !state.showHistory;
        },
        setSelectedImagingSource: (state, action: PayloadAction<IImagingSource | undefined>) => {
            state.selectedImagingSource = action.payload;
        },
        setSelectedImagingSourceForTenant: (state, action: PayloadAction<IImagingSource | undefined>) => {
            state.selectedImagingSourceForTenant = action.payload;
        },
        editSelectedImagingSource: (state, action: PayloadAction<{ path: keyof IImagingSource; value: unknown }>) => {
            const { path, value } = action.payload;
            if (state.selectedImagingSource) {
                (state.selectedImagingSource[path] as unknown) = value;
            }
        },
    },
    extraReducers: (builder) => {
        builder
            //getGeneric
            .addCase(getGenericImagingSources.pending, (state) => {
                state.loadingGenericImageSoruces = LoadingStatus.Pending;
            })
            .addCase(getGenericImagingSources.rejected, (state) => {
                state.loadingGenericImageSoruces = LoadingStatus.Failed;
            })
            .addCase(getGenericImagingSources.fulfilled, (state, action) => {
                state.loadingGenericImageSoruces = LoadingStatus.Completed;
                state.genericImagingSource = action.payload;
            })
            //get all by tenant
            .addCase(getImagingSourcesByTenant.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(getImagingSourcesByTenant.rejected, (state) => {
                state.loading = LoadingStatus.Failed;
            })
            .addCase(getImagingSourcesByTenant.fulfilled, (state, action) => {
                state.loading = LoadingStatus.Completed;
                state.data = action.payload;
            })
            //get
            .addCase(getImagingSourceById.pending, (state) => {
                state.loadingSelectedImagingSource = LoadingStatus.Pending;
            })
            .addCase(getImagingSourceById.rejected, (state) => {
                state.loadingSelectedImagingSource = LoadingStatus.Failed;
            })
            .addCase(getImagingSourceById.fulfilled, (state, action) => {
                state.loadingSelectedImagingSource = LoadingStatus.Completed;
                state.selectedImagingSource = action.payload;
            })
            //create
            .addCase(createImagingSource.pending, (state) => {
                state.loadingSelectedImagingSource = LoadingStatus.Pending;
            })
            .addCase(createImagingSource.rejected, (state) => {
                state.loadingSelectedImagingSource = LoadingStatus.Failed;
            })
            .addCase(createImagingSource.fulfilled, (state, action) => {
                state.loadingSelectedImagingSource = LoadingStatus.Completed;
                state.data[action.payload.id] = action.payload;
                state.selectedImagingSource = undefined;
            })
            //update
            .addCase(updateImagingSource.pending, (state) => {
                state.loadingSelectedImagingSource = LoadingStatus.Pending;
            })
            .addCase(updateImagingSource.rejected, (state) => {
                state.loadingSelectedImagingSource = LoadingStatus.Failed;
            })
            .addCase(updateImagingSource.fulfilled, (state, action) => {
                state.loadingSelectedImagingSource = LoadingStatus.Completed;
                state.data[action.payload.id] = action.payload;
                state.selectedImagingSource = undefined;

                if (state.selectedImagingSourceForTenant && state.selectedImagingSourceForTenant.id === action.payload.id) {
                    state.selectedImagingSourceForTenant = action.payload;
                }
            });
    },
});

export const selectImagingSourcePanelOpen = (state: RootState): boolean => state.tenant.imagingSource.panelOpen;
export const selectImagingSourceState = (state: RootState): ImagingSourceState => state.tenant.imagingSource;
export const imagingSourceLoading = createSelector(selectImagingSourceState, ({ loading }) => loading === LoadingStatus.Pending);
export const selectedImagingSourceLoading = createSelector(
    selectImagingSourceState,
    ({ loadingSelectedImagingSource }) => loadingSelectedImagingSource === LoadingStatus.Pending,
);
export const imagingSourceShowHistory = createSelector(selectImagingSourceState, ({ showHistory }) => showHistory);
export const selectImagingSourceAsList = createSelector(selectImagingSourceState, ({ data, showHistory }) => {
    const results = map(data).filter((imagingSource) => imagingSource !== undefined) as IImagingSource[];
    return showHistory ? results : results.filter((result) => !result.isDeleted);
});

export const selectImagingSourceAsListForTenant = createSelector(selectImagingSourceState, ({ data }) => {
    const results = map(data).filter((imagingSource) => imagingSource !== undefined) as IImagingSource[];
    return results.filter((result) => !result.isDeleted);
});
export const selectGenericImagingSourceAsList = createSelector(selectImagingSourceState, ({ genericImagingSource }) => {
    return (map(genericImagingSource).filter((imagingSource) => imagingSource !== undefined) as IImagingSourceGeneric[]).filter(
        (source) => !source.isDeleted,
    );
});
export const selectGenericImagingSourceMenuItems = createSelector(
    selectGenericImagingSourceAsList,
    selectImagingSourceAsListForTenant,
    (sources, sourcesForTenant) =>
        sortBy(
            sources
                .map((source) => ({ key: source.id, text: `${source.displayName} | ${source.type}` }))
                .filter((res) => sourcesForTenant.findIndex((sourceF) => sourceF.genericImagingSourceId === res.key) === -1),
            ['text'],
        ),
);
export const selectSelectedImagingSource = (state: RootState): IImagingSource | undefined =>
    state.tenant.imagingSource.selectedImagingSource;

export const selectSelectedImagingSourceForTenant = (state: RootState): IImagingSource | undefined =>
    state.tenant.imagingSource.selectedImagingSourceForTenant;

export const selecteSelectedGenericImagingSource = createSelector(
    selectSelectedImagingSourceForTenant,
    selectImagingSourceState,
    (imagingSource, { genericImagingSource }) =>
        imagingSource?.genericImagingSourceId ? genericImagingSource[imagingSource.genericImagingSourceId] : undefined,
);

export const saveUpdateImagingSource =
    (tenantId: string): AppThunk<void> =>
    (dispatch, getState) => {
        const imagingSource = getState().tenant.imagingSource.selectedImagingSource;

        if (imagingSource) {
            if (imagingSource.id) {
                dispatch(updateImagingSource({ tenantId, imagingSource: imagingSource }));
            } else {
                dispatch(createImagingSource({ tenantId, imagingSource: { ...imagingSource, id: uuid() } }));
            }
        }
    };

export const { actions: imagingSourceActions, reducer } = imagingSlice;

export default reducer;
