export function convertFromMilitaryTime(time: string | null | undefined): string | null {
    if (!time) return null;

    const timeSegments = time ? time.split(':') : [];
    const hours = +timeSegments[0];
    const minutes = timeSegments[1];
    const amOrPM = hours >= 12 ? 'PM' : 'AM';
    const newHours = hours > 12 ? hours - 12 : hours;
    return `${newHours}:${minutes} ${amOrPM}`;
}
