import { CommandBar, Separator, Stack, Toggle } from '@fluentui/react';
import { useSelector } from 'hooks';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { RouteParams } from 'interfaces/route-params';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { getPatientAdjustmentHistory } from 'state/slices/ledger/adjustment-history/adjustment-history.actions';
import {
    selectAdjustmentHistoryReversalSavingStatus,
    selectAdjustmentHistorySelectedViews,
    selectAdjustmentHistoryShowReversedAdjustments,
} from 'state/slices/ledger/adjustment-history/adjustment-history.selectors';
import {
    cleanupAdjustmentHistoryPage,
    cleanupAdjustmentHistoryReverseSavedStatusMessageBar,
    setAdjustmentHistoryConfirmModalOpen,
    toggleShowReversedAdjustments,
} from 'state/slices/ledger/ledger.slice';
import SaveStatusMessageBar from '../components/SaveStatusMessageBar';
import AdjustmentHistoryFilters from './AdjustmentHistoryFilters';
import AdjustmentHistoryList from './AdjustmentHistoryList';
import ConfirmAdjustmentReversalModal from './ConfirmAdjustmentReversalModal';

export default function AdjustmentHistory() {
    const dispatch = useAppDispatch();
    const { patientId, tenantId } = useParams<RouteParams>();

    const selectedViews = useSelector(selectAdjustmentHistorySelectedViews);
    const showReversedAdjustments = useSelector(selectAdjustmentHistoryShowReversedAdjustments);
    const savingStatus = useSelector(selectAdjustmentHistoryReversalSavingStatus);

    const dismissSavedMessageBar = () => {
        dispatch(cleanupAdjustmentHistoryReverseSavedStatusMessageBar());
    };

    useEffect(() => {
        const _getPatientAdjustmentHistory = dispatch(getPatientAdjustmentHistory({ tenantId, patientId }));

        return () => {
            dispatch(cleanupAdjustmentHistoryPage());
            _getPatientAdjustmentHistory.abort('CancelGetPatientAdjustmentHistoryPageUnloaded');
        };
    }, []);

    const _onReverseSelected = () => {
        dispatch(setAdjustmentHistoryConfirmModalOpen(true));
    };

    const _onToggleShowReversedAdjustments = () => {
        dispatch(toggleShowReversedAdjustments());
    };

    const disableReverseSelected = !selectedViews?.length;

    return (
        <>
            <Helmet title="Ledger - Adjustment History" />
            <SaveStatusMessageBar
                saving={savingStatus}
                onDismiss={dismissSavedMessageBar}
                messageConfig={{
                    completed: 'Reversal of the selected adjustment(s) was successful.',
                    failed: 'Failed to reverse the selected adjustment(s).',
                }}
            />
            <CommandBar
                items={[
                    {
                        key: 'reverseSelected',
                        text: 'Reverse Selected',
                        iconProps: { iconName: 'RevToggleKey' },
                        disabled: disableReverseSelected,
                        onClick: _onReverseSelected,
                    },
                    {
                        key: 'separator',
                        onRender: () => <Separator vertical styles={{ root: { paddingLeft: 20, paddingRight: 20 } }} />,
                    },
                    {
                        key: 'showReversed',
                        onRender: () => (
                            <Stack verticalAlign="center" styles={{ root: { paddingLeft: 10 } }}>
                                <Toggle
                                    onClick={_onToggleShowReversedAdjustments}
                                    checked={showReversedAdjustments}
                                    styles={{ root: { paddingTop: 5 } }}
                                    label="Show Reversed Adjustments"
                                    inlineLabel
                                />
                            </Stack>
                        ),
                    },
                ]}
                styles={{ root: { paddingLeft: 0 } }}
            />
            <AdjustmentHistoryFilters />
            <AdjustmentHistoryList />
            <ConfirmAdjustmentReversalModal />
        </>
    );
}
