import { PayloadAction } from '@reduxjs/toolkit';
import { IReportingState } from 'state/slices/reporting/reporting.state';
import { StatementValidationQuery } from './statement-validation.model';

export const statementValidationReducers = {
    setStatementValidationProp: (
        state: IReportingState,
        action: PayloadAction<{ path: keyof StatementValidationQuery; value: string | number | undefined }>,
    ): void => {
        const { path, value } = action.payload;
        ((state.reportProperties as StatementValidationQuery)[path] as string | number | undefined) = value;
    },
};
