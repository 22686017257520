import { useTheme, FontSizes, TooltipHost } from '@fluentui/react';
import { CSSProperties } from 'react';

export default function ToothButton({
    toothId,
    displayName,
    isSelected,
    onClick,
    missingOrExtracted,
}: {
    toothId?: number;
    displayName?: string;
    isSelected?: boolean;
    onClick?: (toothId: number) => void;
    disabled?: boolean;
    missingOrExtracted?: boolean;
}): JSX.Element {
    const theme = useTheme();
    const styles: CSSProperties = {
        width: 28,
        height: 28,
        borderRadius: '50%',
        background: isSelected
            ? theme.palette.themePrimary
            : missingOrExtracted
            ? theme.palette.neutralLighter
            : theme.palette.white,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: `1px solid ${isSelected ? theme.semanticColors.primaryButtonBorder : theme.semanticColors.buttonBorder}`,
        color: isSelected ? theme.palette.white : theme.semanticColors.bodyText,
        fontSize: FontSizes.smallPlus,
        cursor: 'pointer',
        userSelect: 'none',
    };

    if (!toothId) {
        return <div style={{ width: 28, height: 28 }}></div>;
    }

    const _handleClick = () => {
        if (onClick) onClick(toothId);
    };
    const Tooth = () => (
        <div style={styles} onClick={_handleClick}>
            {displayName ? displayName : toothId}
        </div>
    );
    return missingOrExtracted ? (
        <TooltipHost content="Missing/Extracted">
            <Tooth />
        </TooltipHost>
    ) : (
        <Tooth />
    );
}
