import React from 'react';
import { ITheme, Icon, TooltipHost, DirectionalHint, useTheme } from '@fluentui/react';
import { NavLink } from 'react-router-dom';
import './ActivityBarLink.scss';
import { Badge, TText } from '..';
import { BadgeSize } from '../Badge';
import { useBoolean } from '@uifabric/react-hooks';

export type IActivityBarLinkProps = {
    iconName?: string;
    icon?: JSX.Element;
    theme?: ITheme;
    exact?: boolean;
    path: string;
    tooltip: string;
    expanded?: boolean;
    notifications?: number;
    onClick?: (event: React.MouseEvent<HTMLAnchorElement | MouseEvent>) => void;
    hidden?: boolean;
};

/**
 * Activity Bar Link
 * Wrapper for react-router-dom NavLink to pass in expanded property and add text when expanded
 * @export
 * @param {IActivityBarLinkProps} props
 * @returns
 */
function ActivityBarLink(props: IActivityBarLinkProps): JSX.Element | null {
    const theme = useTheme();
    const { themePrimary, neutralPrimary, neutralLight, neutralQuaternaryAlt } = theme.palette;
    const activeLinkStyles = { background: neutralQuaternaryAlt };

    const [hovered, { toggle }] = useBoolean(false);

    if (props.hidden) return null;

    const linkStyles: React.CSSProperties = {
        color: neutralPrimary,
        userSelect: 'none',
        background: hovered ? neutralLight : '',
        transition: 'background .15s',
        cursor: hovered ? 'pointer' : '',
        fontSize: '16px',
    };

    return (
        <TooltipHost content={props.tooltip} directionalHint={DirectionalHint.rightCenter} hidden={props.expanded}>
            <NavLink
                className={props.expanded ? 'activityLink activityLink--expanded' : 'activityLink'}
                to={props.path}
                exact={props.exact}
                activeStyle={activeLinkStyles}
                activeClassName="activityLink--active"
                style={linkStyles}
                onMouseEnter={toggle}
                onMouseLeave={toggle}
                onClick={props.onClick}
            >
                <div className="activitiyLink__activeBar" style={{ background: themePrimary }}></div>
                <Icon iconName={props.iconName} className="activityLink__icon" />
                {props.icon && <>{props.icon}</>}
                <TText className="activityLink__text">{props.expanded ? props.tooltip : null}</TText>
                {props.notifications && (
                    <Badge value={props.notifications} className="activityLink__badge" size={BadgeSize.size16} />
                )}
            </NavLink>
        </TooltipHost>
    );
}

export default ActivityBarLink;
