import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import IStatementConfig from 'api/models/statement-config.model';
import { de } from 'date-fns/locale';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { RootState } from 'state/store';

export const initialState: PatientStatementState = {
    loading: LoadingStatus.Idle,
};

export type PatientStatementState = {
    statementConfigurations?: IStatementConfig;
    loading: LoadingStatus;
};

export const getStatementConfigurations = createAsyncThunk<IStatementConfig, { tenantId: string }>(
    'statementConfigurations/getStatementConfigurations',
    async ({ tenantId }) => {
        const statementConfigurations = await dentalApi.getStatementConfigurations(tenantId);
        return statementConfigurations.data;
    },
);

export const statementConfigurationState = (state: RootState) => state.statementConfiguration;

export const statementConfig = createSelector(statementConfigurationState, (state) => state.statementConfigurations);

const statementConfigurationSlice = createSlice({
    name: 'statementConfiguration',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getStatementConfigurations.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(getStatementConfigurations.fulfilled, (state, action) => {
                state.statementConfigurations = action.payload;
                state.loading = LoadingStatus.Completed;
            })
            .addCase(getStatementConfigurations.rejected, (state) => {
                state.loading = LoadingStatus.Failed;
            });
    },
});

export default statementConfigurationSlice.reducer;
