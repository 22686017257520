import { useSelector } from 'react-redux';
import { selectProviderListWithoutRDA, selectProviders, selectProvidersAsList } from 'state/slices/tenant/providers.slice';

function useProviders() {
    const { data: _providers, loading } = useSelector(selectProviders);
    const providersAsList = useSelector(selectProvidersAsList);
    const providersWithoutRDAAsList = useSelector(selectProviderListWithoutRDA);

    return {
        providers: _providers,
        loading,
        providersAsList,
        providersWithoutRDAAsList,
    };
}

export default useProviders;
