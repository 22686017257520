import { ITag, Stack } from '@fluentui/react';
import CarcRarcPicker from './CarcRarcPicker';
import { selectWorkListFilters } from 'state/slices/admin-huddle/worklists/worklist.selectors';
import { useSelector } from 'hooks';
import { useDispatch } from 'react-redux';
import { setWorkListFilter } from 'state/slices/admin-huddle/worklists/worklist.slice';

export default function ReasonCodesFilter() {
    const dispatch = useDispatch();
    const { reasonCodes } = useSelector(selectWorkListFilters<any>);

    const handleCarcRarcCodeMultiSelected = (codeTags: ITag[] | undefined) => {
        dispatch(
            setWorkListFilter({
                path: 'reasonCodes',
                value: codeTags ?? [],
            }),
        );
    };

    return (
        <Stack.Item grow>
            <CarcRarcPicker
                selectedItems={reasonCodes ?? []}
                onChange={(tags) => handleCarcRarcCodeMultiSelected(tags)}
                label="Reason Codes"
            />
        </Stack.Item>
    );
}
