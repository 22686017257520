import { IconButton, Stack, TextField, TooltipHost } from '@fluentui/react';
import Field from 'components/Field/Field';

import { IPatientSlidingFee } from 'api/models/patient.model';
import { useDispatch } from 'react-redux';
import { editPatientSlidingFeePropChange, removePatientSlidingFee } from 'state/slices/edit-patient/edit-patient.slice';
import SubSection from 'components/SubSection';
import { getValidationError, validateErrorMessage } from 'hooks/useValidation';
import { useSelector } from 'hooks';
import { selectEditPatientValidationErrors } from 'state/slices/edit-patient/edit-patient.selectors';

type Props = {
    index: number;
    slidingFee?: IPatientSlidingFee;
};

export function SlidingFeeItem({ slidingFee, index }: Props): JSX.Element | null {
    const dispatch = useDispatch();
    const validationErrors = useSelector(selectEditPatientValidationErrors);
    const _onSlidingFeePropChange = (index: number, key: keyof IPatientSlidingFee, value?: string | boolean) => {
        dispatch(editPatientSlidingFeePropChange({ index, key, value }));
    };

    const removeSlidingFee = (index: number) => {
        dispatch(removePatientSlidingFee(index));
    };
    if (!slidingFee) return null;
    return (
        <SubSection
            key={index}
            title={slidingFee.isRyanWhite ? 'Ryan White' : 'Sliding Fee'}
            headingCenterContent={
                slidingFee.isRyanWhite && (
                    <Stack horizontal>
                        <TooltipHost content="Delete">
                            <IconButton iconProps={{ iconName: 'trash' }} onClick={() => removeSlidingFee(index)} />
                        </TooltipHost>
                    </Stack>
                )
            }
        >
            <Stack>
                <Stack tokens={{ childrenGap: 10 }} horizontal grow wrap>
                    <Stack.Item grow>
                        <TextField
                            label="Sliding Fee Program"
                            value={slidingFee.slidingFeeProgram}
                            disabled
                            onChange={(e, value) => _onSlidingFeePropChange(index, 'slidingFeeProgram', value)}
                        />
                    </Stack.Item>
                    <Stack.Item grow>
                        <TextField
                            label="Sliding Fee Plan"
                            value={slidingFee.slidingFeePlan}
                            disabled
                            onChange={(e, value) => _onSlidingFeePropChange(index, 'slidingFeePlan', value)}
                        />
                    </Stack.Item>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Stack.Item grow>
                        <Field.Date
                            label="Effective Date"
                            value={slidingFee.effectiveDate ?? ''}
                            onChange={(e, value) => _onSlidingFeePropChange(index, 'effectiveDate', value)}
                            hasDatePicker
                            autoComplete="off"
                            errorMessage={validateErrorMessage(getValidationError(validationErrors, 'SF Effective Date'))}
                        />
                    </Stack.Item>

                    <Stack.Item grow>
                        <Field.Date
                            label="Expiration Date"
                            value={slidingFee.expirationDate ?? ''}
                            onChange={(e, value) => _onSlidingFeePropChange(index, 'expirationDate', value)}
                            hasDatePicker
                            autoComplete="off"
                            errorMessage={validateErrorMessage(getValidationError(validationErrors, 'SF Expiration Date'))}
                        />
                    </Stack.Item>
                </Stack>
            </Stack>
        </SubSection>
    );
}
