import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import IServiceUserAccount, { IServiceUserAccountTenant } from 'api/models/account.model';
import {
    DentalPermissions,
    GlobalPermissions,
    MembershipPermissions,
    membershipPermissionsList,
} from 'hooks/store/usePermissions';
import { LoadingStatus, LoadingStatuses } from 'interfaces/loading-statuses';
import { isEmpty, map, some, sortBy } from 'lodash';
import { RootState } from 'state/store';
import { selectTenantSubscriptionsData } from './tenant/subscriptions.slice';

export type AccountState = {
    loading: LoadingStatuses;
    data?: IServiceUserAccount;
};

const initialState: AccountState = {
    loading: LoadingStatus.Idle,
};

export const getAccount = createAsyncThunk<IServiceUserAccount, void>('getAccount', async () => {
    const res = await dentalApi.getAccount();
    return res.data;
});

const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAccount.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(getAccount.fulfilled, (state, action: PayloadAction<IServiceUserAccount>) => {
                state.loading = LoadingStatus.Completed;
                state.data = action.payload;
            })
            .addCase(getAccount.rejected, (state) => {
                state.loading = LoadingStatus.Failed;
            });
    },
});

export const selectAccount = (state: RootState): AccountState => state.account;
export const selectAccountData = (state: RootState) => state.account.data;
export const selectAccountTenants = (state: RootState) => state.account.data?.tenants;
export const selectAccountSystemRoles = (state: RootState) => state.account.data?.identity.systemRoles ?? [];

/**
 * Returns true if the passed user account has at least one of the specified global permissions
 *
 * @export
 * @param {IServiceUserAccount} account
 * @param {GlobalPermissions[]} permissions
 * @return {*}  {boolean}
 */
export function accountHasGlobalPermissions(account: IServiceUserAccount, permissions: GlobalPermissions[]): boolean {
    return some(permissions, (perm) => (account.identity.systemRoles ?? []).indexOf(perm) > -1); // why no this in dev
}

export const selectHasReporting = (state: RootState) => {
    if (state.account?.data?.identity.systemRoles) {
        const systemRoles = state.account.data.identity.systemRoles;
        const hasRoles = [
            systemRoles.indexOf(GlobalPermissions.GlobalAdmin) > -1,
            systemRoles.indexOf(GlobalPermissions.JuniorAdmin) > -1,
            systemRoles.indexOf(GlobalPermissions.JuniorClinical) > -1,
            systemRoles.indexOf(GlobalPermissions.SeniorClinical) > -1,
        ];

        return some(hasRoles, (role) => role === true);
    }
    return false;
};

export const selectIsAccountGlobalAdmin = (state: RootState) =>
    state.account?.data?.identity.systemRoles
        ? state.account.data.identity.systemRoles.indexOf(GlobalPermissions.GlobalAdmin) > -1
        : false;

export const selectTenantsAsList = createSelector(selectAccount, (account) => {
    if (account.data && account.data.tenants) {
        const tenants: (IServiceUserAccountTenant | undefined)[] = map(
            sortBy(account.data.tenants, ['displayName']),
            (data) => data,
        );
        const filteredTenants = tenants.length ? tenants.filter((t) => t !== undefined) : [];
        return filteredTenants as IServiceUserAccountTenant[];
    }
    return [];
});

const getTenantApps = (tenantId: string): Record<string, { url: string; permissions?: MembershipPermissions[] }> => ({
    membership: { url: `#{MembershipClientUrl}#/${tenantId}`, permissions: membershipPermissionsList },
    platform: { url: `#{PlatformClientUrl}#/tenants/${tenantId}` },
    credentialing: { url: `#{CredentialingClientUrl}#/${tenantId}/profile` },
});

export const selectTenantClaimsAndUserRoles = createSelector(
    [selectAccountTenants, selectAccountSystemRoles, (_: RootState, tenantId: string) => tenantId],
    (tenants, systemRoles, tenantId) => {
        return [...(tenants && tenants[tenantId]?.permissions ? tenants[tenantId]?.permissions ?? [] : []), ...systemRoles];
    },
);

export type BentoBoxTenantApp = {
    displayName: string;
    url: string;
    permissions?: (MembershipPermissions | DentalPermissions)[] | undefined;
};

export const selectAccountTenantApps = createSelector(
    [
        selectAccountTenants,
        selectTenantSubscriptionsData,
        selectIsAccountGlobalAdmin,
        (_: RootState, tenantId: string) => tenantId,
    ],
    (accountTenants, subscriptionsData, isGlobalAdmin, tenantId) => {
        const tenantApps = getTenantApps(tenantId);

        const apps: BentoBoxTenantApp[] = isGlobalAdmin
            ? map(tenantApps, (app, key) => ({ url: app.url, displayName: `${key[0].toUpperCase()}${key.slice(1, key.length)}` }))
            : map(
                  subscriptionsData
                      .filter((sub) => sub?.product?.id !== 'dental')
                      .filter((subscription) => {
                          // Filter all product ids in subscription where account.tenants[tenantId].products exist
                          if (!isEmpty(accountTenants) && subscription.product?.id) {
                              const productIds = accountTenants[tenantId]?.products ?? [];
                              return productIds.findIndex((productId) => productId === subscription.product?.id) > -1;
                          }
                          return false;
                      }),
                  (subscription) => {
                      const { url, permissions } = tenantApps[subscription.product?.id ?? ''];
                      return {
                          url,
                          displayName: subscription?.product?.displayName ?? '',
                          permissions,
                      };
                  },
              );
        return apps;
    },
);

export const selectTenantsLoading = (state: RootState): LoadingStatuses => state.account.loading;

const { reducer } = accountSlice;
export default reducer;
