import { PayloadAction } from '@reduxjs/toolkit';
import { IReportingState } from '../reporting.state';
import { IBillingStatusReportQuery } from 'api/models/billing-status.model';

export const billingStatusReducers = {
    setBillingStatusProp: (
        state: IReportingState,
        action: PayloadAction<{ path: keyof IBillingStatusReportQuery; value: string | number | undefined }>,
    ): void => {
        const { path, value } = action.payload;
        ((state.reportProperties as IBillingStatusReportQuery)[path] as string | number | undefined) = value;
    },

    toggleBillingStatusId: (
        state: IReportingState,
        action: PayloadAction<{ path: keyof IBillingStatusReportQuery; id: string }>,
    ): void => {
        const { id, path } = action.payload;
        if (!(state.reportProperties as IBillingStatusReportQuery)[path])
            ((state.reportProperties as IBillingStatusReportQuery)[path] as unknown[]) = [];

        const currentIds = (state.reportProperties as IBillingStatusReportQuery)[path] as string[];

        if (currentIds)
            if (currentIds?.includes(id)) {
                ((state.reportProperties as IBillingStatusReportQuery)[path] as string[]) = currentIds.filter(
                    (_id) => _id !== id,
                );
            } else {
                ((state.reportProperties as IBillingStatusReportQuery)[path] as string[]) = [...currentIds, id];
            }
    },
};
