import { Stack, TextField } from '@fluentui/react';
import BaseClinicalAlert from './BaseClinicalAlert';
import { useSelector } from 'hooks';
import { clinicalAlertValidation, selectCurrentClinicalAlertData } from 'state/slices/clinical-alert/clinical-alerts.selectors';
import { useDispatch } from 'react-redux';
import { setClinicalAlertDataProp } from 'state/slices/clinical-alert/clinical-alerts.slice';
import { getValidationError } from 'hooks/useValidation';

const errorMessageLookup: Record<string, string> = {
    required: 'Description is required',
    characterLimit: 'Description length must be below 80 characters.',
};

export default function AlertModal() {
    const dispatch = useDispatch();
    const currentClinicalAlert = useSelector(selectCurrentClinicalAlertData);
    const error = useSelector(clinicalAlertValidation);

    const descriptionValidationError = getValidationError(error, 'Description');

    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <TextField
                label="Description"
                value={currentClinicalAlert?.references?.description}
                errorMessage={
                    descriptionValidationError ? errorMessageLookup[descriptionValidationError.errorTypes[0]] : undefined
                }
                onChange={(ev, value) => {
                    dispatch(
                        setClinicalAlertDataProp({
                            path: 'references',
                            value: {
                                description: value ?? '',
                            },
                        }),
                    );
                }}
                description={`${currentClinicalAlert?.references?.description?.length ?? 0} of 80 characters used.`}
            />
            <BaseClinicalAlert notesRequired={!currentClinicalAlert?.references?.description} />
        </Stack>
    );
}
