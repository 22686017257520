import { Text } from '@fluentui/react';
import { IBillingProcedure } from 'api/models/billing-procedure.model';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';

const authNumber: ISortableColumn<IBillingProcedure> = {
    key: 'authNumber',
    name: 'Auth Number',
    fieldName: 'authNumber',
    minWidth: 70,
    maxWidth: 70,
    isResizable: true,
    onRender: (item) => {
        if (item) return <Text variant="small">{item.preAuthorizationCode ? item.preAuthorizationCode : 'N/A'}</Text>;
    },
};

export default authNumber;
