import { DocumentBase } from 'api/document-base.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
export interface IPatientCommunicationState {
    data?: IPatientCommunication;
    loading: LoadingStatus;
    saving: LoadingStatus;
}

export enum PatientCommunicationNoteType {
    Patient = 'Patient',
}

interface IPatientCommunication extends DocumentBase {
    patientId?: string;
    user?: string;
    noteType?: PatientCommunicationNoteType;
    subject?: string;
    message?: string;
    encounterId?: string;
}

export default IPatientCommunication;
