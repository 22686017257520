import { IStackStyles, Stack } from '@fluentui/react';
import { ActivityPage, ContentPane } from 'components';
import { useTenantId } from 'hooks';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { getLocationsOfCare } from 'state/slices/scheduling/scheduling.slice';
import { AdminDashboard } from './AdminDashboard';
import { WorkList } from './WorkList';
import { WorkListCategory } from './WorkListCategory';
import { WorkListHeader } from './WorkListHeader';
import { WorkListMenu } from './WorkListMenu';
import { cleanupPatientEncounter } from 'state/slices/encounter/encounter.slice';
import { getDashboardTotalFees } from 'state/slices/admin-huddle/worklists/worklist.actions';

const containerStyles: IStackStyles = {
    root: {
        flex: 1,
        height: '100%',
        overflow: 'hidden',
    },
};

function AdminHuddle(): JSX.Element {
    const { path } = useRouteMatch();
    const tenantId = useTenantId();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(cleanupPatientEncounter());
        dispatch(getLocationsOfCare({ tenantId }));
        dispatch(getDashboardTotalFees({ tenantId }));
}, [tenantId, dispatch]);

    return (
        <ActivityPage title={'Worklists'} hasPageHeader={false}>
            <Stack styles={containerStyles}>
                <WorkListHeader />
                <Stack horizontal styles={containerStyles}>
                    <WorkListMenu />
                    <ContentPane>
                        <Switch>
                            <Route path={'/:tenantId/administrative'} exact component={AdminDashboard} />
                            <Route path={`${path}/:categoryId`} exact component={WorkListCategory} />
                            <Route path={`${path}/:categoryId/:workListId`} component={WorkList} />
                        </Switch>
                    </ContentPane>
                </Stack>
            </Stack>
        </ActivityPage>
    );
}

// function MyFavorites() {
//     return <>My Favorites</>;
// }

export default AdminHuddle;
