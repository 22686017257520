import { Stack } from '@fluentui/react';
import { useSelector } from 'react-redux';
import { selectCurrentTutorialStepPage } from 'state/slices/teaching/teaching.selectors';

export default function ImagingSourceTutorial() {
    return (
        <div>
            <One />
        </div>
    );
}

function One() {
    const currentPage = useSelector(selectCurrentTutorialStepPage);

    const headerStyles: React.CSSProperties = {
        margin: 0,
    };

    const pageComponentLookup: { [key: number]: JSX.Element } = {
        1: (
            <Stack tokens={{ childrenGap: 10 }}>
                <h3 style={headerStyles}>Step 1</h3>
                <span>
                    Imaging bridge should be downloading below. If not please try again. Otherwise please contact support.
                </span>
            </Stack>
        ),
        2: (
            <Stack tokens={{ childrenGap: 10 }}>
                <h3 style={headerStyles}>Step 2</h3>
                <span>Run as admin to install where you want it to be installed.</span>
            </Stack>
        ),
        3: (
            <Stack tokens={{ childrenGap: 10 }}>
                <h3 style={headerStyles}>Step 3</h3>
                <span>
                    Test the success of the installation by navigating to a patient chart and clicking the &quot;launch x
                    rays&quot; button.
                </span>
            </Stack>
        ),
        4: (
            <Stack tokens={{ childrenGap: 10 }}>
                <h3 style={headerStyles}>Step 4</h3>
                <span>Contact your athenaOne Dental representative with issues.</span>
                <span>Happy Imaging!</span>
            </Stack>
        ),
    };

    const Component = currentPage ? pageComponentLookup[currentPage] : undefined;

    return <div>{Component ?? null}</div>;
}
