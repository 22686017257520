import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppointmentCancellationDepartmentType } from 'api/models/appointment-cancellation-reason.model';
import schedulingApi from 'api/scheduling.api';
import { AppThunk } from 'state/store';
import { closeAppointmentOverview, setCancelAppointmentModalOpen, updatePatientAppointment } from '../scheduling.slice';
import IPatientAppointment from 'api/models/Scheduling/patientAppointment.model';
/**
 * [ACTION]
 * Gets an appointment to remove/cancel by id
 * @type {*}
 * */
export const getAppointmentToCancel = createAsyncThunk(
    'getAppointmentToRemove',
    async ({ tenantId, appointmentId }: { tenantId: string; appointmentId: string }) => {
        const { data: appointment } = await schedulingApi.getPatientAppointment(tenantId, appointmentId);
        return appointment;
    },
);
/**
 *  [ACTION]
 *  Gets all appointment cancellation reasons by department type
 *  @type {*}
 * */
export const getCancellationReasons = createAsyncThunk(
    'getCancellationReasons',
    async ({ tenantId, type }: { tenantId: string; type: AppointmentCancellationDepartmentType }) => {
        const { data: appointment } = await schedulingApi.getAppointmentCancellationReasonsByType(tenantId, type);
        return appointment;
    },
);
/**
 * [ACTION]
 * Cancels a given appointment
 * @param {string} tenantId
 * @return {*}  {AppThunk<void>}
 */
export const cancelAppointment =
    (tenantId: string, appointment: IPatientAppointment | undefined): AppThunk<void> =>
    (dispatch, getState) => {
        if (appointment) {
            dispatch(
                updatePatientAppointment({
                    appointment: { ...appointment, isDeleted: true },
                    tenantId,
                }),
            );
            dispatch(setCancelAppointmentModalOpen({ isOpen: false }));
            if (getState().scheduling.selectedAppointment && !getState().editPatient.patient)
                dispatch(closeAppointmentOverview());
        }
    };
