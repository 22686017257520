import { MissingSlipsWorkList, WorkList } from 'state/slices/admin-huddle/worklists/worklist.state';
import WorkListBuilder, { WorkListDetailsListDataForChildren } from '../WorkListBuilder';
import { useSelector } from 'react-redux';
import { selectCurrentWorkList } from 'state/slices/admin-huddle/worklists/worklist.selectors';
import React from 'react';
import { format } from 'date-fns';
import { RecentWorkListItem } from 'utils/appLocalStorage';
import MissingSlipsBaseDetailsList from './MissingSlipsBaseDetailsList';
import IWorkListAppointmentView from 'api/models/worklist-appointment-view';
import EncountersDetailsList from '../EncounterWorkLists/EncountersDetailsList';

const listComponent: Partial<Record<WorkList, React.FunctionComponent<WorkListDetailsListDataForChildren>>> = {
    [MissingSlipsWorkList.AmendRequireEncounters]: EncountersDetailsList,
    [MissingSlipsWorkList.OutstandingCheckInAppointments]: MissingSlipsBaseDetailsList,
    [MissingSlipsWorkList.OutstandingCheckoutAppointments]: MissingSlipsBaseDetailsList,
    [MissingSlipsWorkList.AwaitingAttestation]: MissingSlipsBaseDetailsList,
};

export function MissingSlipsList(props: WorkListDetailsListDataForChildren) {
    const currentWorkList = useSelector(selectCurrentWorkList);

    const ListComponent = currentWorkList ? listComponent[currentWorkList] ?? null : null;

    if (!ListComponent) return null;
    return <ListComponent {...props} />;
}

export const getRecentlyViewedAppointmentItemFromData = (data: Partial<IWorkListAppointmentView>): RecentWorkListItem => ({
    id: data?.id ?? '',
    displayName: `${data.patientName}${data.startDateTime ? ` - ${format(new Date(data.startDateTime), 'Pp')}` : ''}`,
});

export default function MissingSlips() {
    return (
        <WorkListBuilder<IWorkListAppointmentView>
            workLists={[
                MissingSlipsWorkList.OutstandingCheckInAppointments,
                MissingSlipsWorkList.OutstandingCheckoutAppointments,
                MissingSlipsWorkList.AwaitingAttestation,
                MissingSlipsWorkList.AmendRequireEncounters,
            ]}
        >
            {(detailsListData) => {
                if (!detailsListData) return null;
                return <MissingSlipsList {...detailsListData} />;
            }}
        </WorkListBuilder>
    );
}
