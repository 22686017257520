import { PayloadAction } from '@reduxjs/toolkit';
import { IReportingState } from '../reporting.state';
import { IUACReportQuery } from 'api/models/uac-report.model';

export const uacReducers = {
    setUACProp: (
        state: IReportingState,
        action: PayloadAction<{ path: keyof IUACReportQuery; value: string | number | undefined }>,
    ): void => {
        const { path, value } = action.payload;
        ((state.reportProperties as IUACReportQuery)[path] as string | number | undefined) = value;
    },
};
