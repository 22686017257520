import {
    DefaultEffects,
    Panel,
    Spinner,
    SpinnerSize,
    Stack,
    useTheme,
    Text,
    PanelType,
    Link,
    Label,
    SelectionMode,
} from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import dentalApi from 'api/dental.api';
import IWorkListAppointmentView from 'api/models/worklist-appointment-view';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import { RouteParams } from 'interfaces/route-params';
import { MissingSlipsList } from 'pages/Landing/AdminHuddle/components/MissingSlipsWorkLists/MissingSlips';
import WorkListBuilder from 'pages/Landing/AdminHuddle/components/WorkListBuilder';
import { MouseEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { selectAccount } from 'state/slices/account.slice';
import { setWorkListReadOnly, setWorkListFilter } from 'state/slices/admin-huddle/worklists/worklist.slice';
import { MissingSlipsWorkList } from 'state/slices/admin-huddle/worklists/worklist.state';
import { classicDateOnly } from 'utils/dateOnly';

function Amendments(): JSX.Element {
    const dispatch = useDispatch();
    const theme = useTheme();
    const { tenantId } = useParams<RouteParams>();

    const account = useSelector(selectAccount);
    const userId = account.data?.identity.id;

    const [count, setCount] = useState<number>();
    const [countLoading, { setTrue: setIsLoadingTrue, setFalse: setIsLoadingFalse }] = useBoolean(true);

    const [isOpen, { setTrue: setIsOpen, setFalse: setIsClosed }] = useBoolean(false);
    const { push } = useHistory();

    useEffect(() => {
        if (tenantId && userId) {
            setIsLoadingTrue();
            dentalApi
                .getWorkListDataByWorkList<number>({
                    tenantId,
                    method: 'AmendRequiredEncounterCount',
                    filters: { providers: [userId], providerIdType: 'amendingProviderId' },
                })
                .then((res) => {
                    setCount(res.data);
                    setIsLoadingFalse();
                })
                .catch(() => {
                    setIsLoadingFalse();
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenantId, userId]);

    const columns: ISortableColumn<IWorkListAppointmentView>[] = [
        {
            key: 'patient-name',
            name: 'Patient',
            minWidth: 100,
            maxWidth: 150,
            fieldName: 'patientName',
            onRender: (item) => {
                if (item && item.patientId && item.encounterId) {
                    return (
                        <Link
                            onClick={(e?: MouseEvent<HTMLAnchorElement | HTMLElement | HTMLButtonElement>) => {
                                e?.preventDefault();
                                push(`/${tenantId}/patient/${item.patientId}/encounter/${item.encounterId}/charting`);
                            }}
                        >
                            {item?.patientName}
                        </Link>
                    );
                }
            },
        },

        {
            key: 'encounter-date',
            name: 'Encounter Date',
            minWidth: 100,
            maxWidth: 150,
            fieldName: 'encounterDate',
            onRender: (item) => {
                if (item && item.encounterDate) {
                    const date = classicDateOnly(item.encounterDate);
                    return <Text variant="smallPlus">{date}</Text>;
                }
            },
        },
        {
            key: 'amendment-reason',
            name: 'Reason',
            minWidth: 100,
            maxWidth: 150,
            fieldName: 'amendment-reason',
            onRender: (item) => {
                const taskReason = item?.amendReason;
                return taskReason;
            },
        },
        {
            key: 'notes',
            name: 'Notes',
            minWidth: 100,
            maxWidth: 150,
            fieldName: 'notes',
            onRender: (item) => {
                const amendNote = item?.amendNotes ?? '';
                return <span title={amendNote}>{amendNote}</span>;
            },
        },
    ];

    const boxStyles = {
        root: {
            background: theme.palette.yellow,
            width: 120,
            height: 120,
            color: '#FFF',
            cursor: 'pointer',
            selectors: { ':hover': { boxShadow: DefaultEffects.elevation8 } },
        },
    };

    const textStyles = {
        cursor: 'pointer',
        background: theme.palette.yellowDark,
        width: '100%',
        paddingTop: 5,
        paddingBottom: 5,
    };
    const _handleOpenPanel = () => {
        setIsOpen();
    };

    useEffect(() => {
        if (isOpen) {
            //Treating provider should only be able to read the notes, but not add.
            dispatch(setWorkListReadOnly(true));
            //Load only this treating provider's correction amend encounters:
            if (userId) {
                dispatch(setWorkListFilter({ path: 'providers', value: [userId] }));
                dispatch(setWorkListFilter({ path: 'providerIdType', value: 'amendingProviderId' }));
            }
        }
    }, [isOpen, dispatch, tenantId, userId]);

    return (
        <Stack styles={boxStyles} horizontalAlign="center" onClick={_handleOpenPanel}>
            <Stack grow style={{ fontSize: 48 }} verticalAlign="center">
                {countLoading ? <Spinner size={SpinnerSize.large} /> : <span>{count}</span>}
            </Stack>
            <Stack horizontalAlign="center" style={textStyles}>
                <Label style={{ color: 'white' }}>Amendments</Label>
            </Stack>
            <Panel
                type={PanelType.custom}
                customWidth={'40vw'}
                isOpen={isOpen}
                onDismiss={setIsClosed}
                isLightDismiss
                styles={{
                    contentInner: { flex: 1 },
                    scrollableContent: { display: 'flex', flex: 1, flexDirection: 'column' },
                    content: { display: 'flex', flexDirection: 'column', flex: 1 },
                }}
                headerText="Amendments"
            >
                <Stack grow>
                    {isOpen && (
                        <WorkListBuilder<IWorkListAppointmentView>
                            hideFilters
                            hideRecentItems
                            workLists={[MissingSlipsWorkList.AmendRequireEncounters]}
                        >
                            {(detailsListData) => {
                                if (!detailsListData) return null;
                                return (
                                    <MissingSlipsList
                                        {...detailsListData}
                                        expandedRowColumns={undefined}
                                        selectionMode={SelectionMode.none}
                                        columns={columns}
                                    />
                                );
                            }}
                        </WorkListBuilder>
                    )}
                </Stack>
            </Panel>
        </Stack>
    );
}

export default Amendments;
