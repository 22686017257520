import { IChartTreatmentPlan } from 'api/models/treatment-plan.model';
import { AppThunk } from 'state/store';
import { setPrintPredeterminationOpen, setPrintPredeterminationSelectedTreatmentPlan } from '../../chart/chart.slice';

export const openTreatmentPlanPredetermination =
    (treatmentPlan: IChartTreatmentPlan | undefined): AppThunk<void> =>
    (dispatch) => {
        dispatch(setPrintPredeterminationOpen(true));
        dispatch(setPrintPredeterminationSelectedTreatmentPlan(treatmentPlan));
    };
