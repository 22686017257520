import { IColumn, IDetailsListProps, MessageBar, SelectionMode } from '@fluentui/react';
import { SortableDetailsList } from 'components';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import useLookupsStore from 'hooks/store/useLookupsStore';
import { LoadingStatus } from 'interfaces/loading-statuses';

type CommonListParameters<T> = {
    lookupType: string;
    columns: ISortableColumn<T>[];
    sortOnMount?: boolean;
    mountSortColumn?: string[];
    searchTerm?: string;
    items?: T[];
    filterHandler?: (item?: T) => boolean;
} & Omit<IDetailsListProps, 'items'>;

function LookupDetailsList<T>(props: CommonListParameters<T>): JSX.Element {
    const { lookupType, sortOnMount, mountSortColumn, filterHandler, items } = props;
    const { lookupsList, setSelectedLookup } = useLookupsStore<T>(lookupType);
    const filteredList = filterHandler
        ? (items !== undefined ? items : lookupsList).filter(filterHandler)
        : items !== undefined
        ? items
        : lookupsList;

    if (props.searchTerm && !filteredList?.length) return <MessageBar>No items match search criteria.</MessageBar>;

    return (
        <SortableDetailsList
            {...props}
            selectionMode={SelectionMode.none}
            sortOnMount={sortOnMount}
            sortColumns={mountSortColumn}
            initialSortDirection={['asc']}
            onItemInvoked={(item?: T) => {
                setSelectedLookup({ item, savingStatus: LoadingStatus.Idle });
            }}
            items={filteredList}
        />
    );
}

export default LookupDetailsList;
