import isDev from 'utils/isDev';

const imageAssetsBaseUrl = isDev
    ? `${process.env.REACT_APP_AppBlobStorageUrl}/dental-client-assets/images`
    : `#{AppBlobStorageUrl}#/dental-client-assets/images`;

const spriteSheets = [
    imageAssetsBaseUrl + '/spritesheet-0.json',
    imageAssetsBaseUrl + '/spritesheet-1.json',
    imageAssetsBaseUrl + '/spritesheet-2.json',
    imageAssetsBaseUrl + '/spritesheet-3.json',
    imageAssetsBaseUrl + '/spritesheet-4.json',
    imageAssetsBaseUrl + '/spritesheet-5.json',
    imageAssetsBaseUrl + '/spritesheet-6.json',
    imageAssetsBaseUrl + '/spritesheet-7.json',
    imageAssetsBaseUrl + '/spritesheet-8.json',
    imageAssetsBaseUrl + '/spritesheet-9.json',
    imageAssetsBaseUrl + '/spritesheet-10.json',
    imageAssetsBaseUrl + '/spritesheet-11.json',
    imageAssetsBaseUrl + '/spritesheet-12.json',
    imageAssetsBaseUrl + '/spritesheet-13.json',
];

export const toothPolygons = imageAssetsBaseUrl + '/tooth-polys.json';

export const toothSpriteReferences: SpriteReference[] = [
    {
        id: 1,
        displayName: '1',
        position: 1,
        front: '1.png',
        back: '1b.png',
        occlusal: '1o.png',
        crown: '1f-c.png',
        root: '1f-r.png',
        implant: '1f-i.png',
        broken: '1f-broken.png',
        fractured: '1f-c-fractured.png',
        gingivalRecession: '1f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        classVYOffset: 27,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '1o-s1.png',
            },
            {
                id: 2,
                sprite: '1o-s2.png',
            },
            {
                id: 3,
                sprite: '1o-s3.png',
            },
            {
                id: 4,
                sprite: '1o-s4.png',
            },
            {
                id: 5,
                sprite: '1o-s5.png',
            },
        ],
    },
    {
        id: 2,
        displayName: '2',
        position: 2,
        front: '2.png',
        back: '2b.png',
        occlusal: '2o.png',
        crown: '2f-c.png',
        root: '2f-r.png',
        implant: '2f-i.png',
        broken: '2f-broken.png',
        fractured: '2f-c-fractured.png',
        gingivalRecession: '2f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.9,
        classVYOffset: 30,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '2o-s1.png',
            },
            {
                id: 2,
                sprite: '2o-s2.png',
            },
            {
                id: 3,
                sprite: '2o-s3.png',
            },
            {
                id: 4,
                sprite: '2o-s4.png',
            },
            {
                id: 5,
                sprite: '2o-s5.png',
            },
        ],
    },
    {
        id: 3,
        displayName: '3',
        position: 3,
        front: '3.png',
        back: '3b.png',
        occlusal: '3o.png',
        crown: '3f-c.png',
        root: '3f-r.png',
        implant: '3f-i.png',
        broken: '3f-broken.png',
        fractured: '3f-c-fractured.png',
        gingivalRecession: '3f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.85,
        classVYOffset: 28,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '3o-s1.png',
            },
            {
                id: 2,
                sprite: '3o-s2.png',
            },
            {
                id: 3,
                sprite: '3o-s3.png',
            },
            {
                id: 4,
                sprite: '3o-s4.png',
            },
            {
                id: 5,
                sprite: '3o-s5.png',
            },
        ],
    },
    {
        id: 4,
        displayName: '4',
        position: 4,
        primaryId: 33,
        front: '4.png',
        back: '4b.png',
        occlusal: '4o.png',
        crown: '4f-c.png',
        root: '4f-r.png',
        implant: '4f-i.png',
        broken: '5f-broken.png',
        fractured: '4f-c-fractured.png',
        gingivalRecession: '4f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.6,
        classVYOffset: 20,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '4o-s1.png',
            },
            {
                id: 2,
                sprite: '4o-s2.png',
            },
            {
                id: 3,
                sprite: '4o-s3.png',
            },
            {
                id: 4,
                sprite: '4o-s4.png',
            },
            {
                id: 5,
                sprite: '4o-s5.png',
            },
        ],
    },
    {
        id: 5,
        displayName: '5',
        position: 5,
        primaryId: 34,
        front: '5.png',
        back: '5b.png',
        occlusal: '5o.png',
        crown: '5f-c.png',
        root: '5f-r.png',
        implant: '5f-i.png',
        broken: '5f-broken.png',
        fractured: '5f-c-fractured.png',
        gingivalRecession: '5f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.6,
        classVYOffset: 29,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '5o-s1.png',
            },
            {
                id: 2,
                sprite: '5o-s2.png',
            },
            {
                id: 3,
                sprite: '5o-s3.png',
            },
            {
                id: 4,
                sprite: '5o-s4.png',
            },
            {
                id: 5,
                sprite: '5o-s5.png',
            },
        ],
    },
    {
        id: 6,
        displayName: '6',
        position: 6,
        primaryId: 35,
        front: '6.png',
        occlusal: '6o.png',
        crown: '6f-c.png',
        root: '6f-r.png',
        implant: '6f-i.png',
        broken: '6f-broken.png',
        fractured: '6f-c-fractured.png',
        gingivalRecession: '6f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.52,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '6o-s1.png',
            },
            {
                id: 2,
                sprite: '6o-s2.png',
            },
            {
                id: 3,
                sprite: '6o-s3.png',
            },
            {
                id: 4,
                sprite: '6o-s4.png',
            },
            {
                id: 5,
                sprite: '6o-s5.png',
            },
        ],
    },
    {
        id: 7,
        displayName: '7',
        position: 7,
        primaryId: 36,
        front: '7.png',
        occlusal: '7o.png',
        crown: '7f-c.png',
        root: '7f-r.png',
        implant: '7f-i.png',
        broken: '7f-broken.png',
        fractured: '7f-c-fractured.png',
        gingivalRecession: '7f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.54,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '7o-s1.png',
            },
            {
                id: 2,
                sprite: '7o-s2.png',
            },
            {
                id: 3,
                sprite: '7o-s3.png',
            },
            {
                id: 4,
                sprite: '7o-s4.png',
            },
            {
                id: 5,
                sprite: '7o-s5.png',
            },
        ],
    },
    {
        id: 8,
        displayName: '8',
        position: 8,
        primaryId: 37,
        front: '8.png',
        occlusal: '8o.png',
        crown: '8f-c.png',
        root: '8f-r.png',
        implant: '8f-i.png',
        broken: '8f-broken.png',
        fractured: '8f-c-fractured.png',
        gingivalRecession: '8f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.65,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '8o-s1.png',
            },
            {
                id: 2,
                sprite: '8o-s2.png',
            },
            {
                id: 3,
                sprite: '8o-s3.png',
            },
            {
                id: 4,
                sprite: '8o-s4.png',
            },
            {
                id: 5,
                sprite: '8o-s5.png',
            },
        ],
    },
    {
        id: 9,
        displayName: '9',
        position: 9,
        primaryId: 38,
        front: '9.png',
        occlusal: '9o.png',
        crown: '9f-c.png',
        root: '9f-r.png',
        implant: '8f-i.png',
        flipImplant: true,
        broken: '8f-broken.png',
        fractured: '8f-c-fractured.png',
        gingivalRecession: '8f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.65,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '8o-s1.png',
            },
            {
                id: 2,
                sprite: '8o-s4.png',
            },
            {
                id: 3,
                sprite: '8o-s3.png',
            },
            {
                id: 4,
                sprite: '8o-s2.png',
            },
            {
                id: 5,
                sprite: '8o-s5.png',
            },
        ],
    },
    {
        id: 10,
        displayName: '10',
        position: 10,
        primaryId: 39,
        front: '10.png',
        occlusal: '10o.png',
        crown: '10f-c.png',
        root: '10f-r.png',
        implant: '7f-i.png',
        flipImplant: true,
        broken: '7f-broken.png',
        fractured: '7f-c-fractured.png',
        gingivalRecession: '7f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.54,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '7o-s1.png',
            },
            {
                id: 2,
                sprite: '7o-s4.png',
            },
            {
                id: 3,
                sprite: '7o-s3.png',
            },
            {
                id: 4,
                sprite: '7o-s2.png',
            },
            {
                id: 5,
                sprite: '7o-s5.png',
            },
        ],
    },
    {
        id: 11,
        displayName: '11',
        position: 11,
        primaryId: 40,
        front: '11.png',
        occlusal: '11o.png',
        crown: '11f-c.png',
        root: '11f-r.png',
        implant: '6f-i.png',
        flipImplant: true,
        broken: '6f-broken.png',
        fractured: '6f-c-fractured.png',
        gingivalRecession: '6f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.54,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '6o-s1.png',
            },
            {
                id: 2,
                sprite: '6o-s4.png',
            },
            {
                id: 3,
                sprite: '6o-s3.png',
            },
            {
                id: 4,
                sprite: '6o-s2.png',
            },
            {
                id: 5,
                sprite: '6o-s5.png',
            },
        ],
    },
    {
        id: 12,
        displayName: '12',
        position: 12,
        primaryId: 41,
        front: '12.png',
        back: '12b.png',
        occlusal: '12o.png',
        crown: '12f-c.png',
        root: '12f-r.png',
        implant: '5f-i.png',
        flipImplant: true,
        broken: '5f-broken.png',
        fractured: '5f-c-fractured.png',
        gingivalRecession: '5f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.6,
        flipClassV: true,
        classVYOffset: 29,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '5o-s1.png',
            },
            {
                id: 2,
                sprite: '5o-s4.png',
            },
            {
                id: 3,
                sprite: '5o-s3.png',
            },
            {
                id: 4,
                sprite: '5o-s2.png',
            },
            {
                id: 5,
                sprite: '5o-s5.png',
            },
        ],
    },
    {
        id: 13,
        displayName: '13',
        position: 13,
        primaryId: 42,
        front: '13.png',
        back: '13b.png',
        occlusal: '13o.png',
        crown: '13f-c.png',
        root: '13f-r.png',
        implant: '4f-i.png',
        flipImplant: true,
        broken: '5f-broken.png',
        fractured: '4f-c-fractured.png',
        gingivalRecession: '4f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.6,
        flipClassV: true,
        classVYOffset: 29,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '4o-s1.png',
            },
            {
                id: 2,
                sprite: '4o-s4.png',
            },
            {
                id: 3,
                sprite: '4o-s3.png',
            },
            {
                id: 4,
                sprite: '4o-s2.png',
            },
            {
                id: 5,
                sprite: '4o-s5.png',
            },
        ],
    },
    {
        id: 14,
        displayName: '14',
        position: 14,
        front: '14.png',
        back: '14b.png',
        occlusal: '14o.png',
        crown: '14f-c.png',
        root: '14f-r.png',
        implant: '3f-i.png',
        flipImplant: true,
        broken: '3f-broken.png',
        fractured: '3f-c-fractured.png',
        gingivalRecession: '3f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.85,
        classVYOffset: 28,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '3o-s1.png',
            },
            {
                id: 2,
                sprite: '3o-s4.png',
            },
            {
                id: 3,
                sprite: '3o-s3.png',
            },
            {
                id: 4,
                sprite: '3o-s2.png',
            },
            {
                id: 5,
                sprite: '3o-s5.png',
            },
        ],
    },
    {
        id: 15,
        displayName: '15',
        position: 15,
        front: '15.png',
        back: '15b.png',
        occlusal: '15o.png',
        crown: '15f-c.png',
        root: '15f-r.png',
        implant: '2f-i.png',
        flipImplant: true,
        broken: '2f-broken.png',
        fractured: '2f-c-fractured.png',
        gingivalRecession: '2f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.9,
        classVYOffset: 30,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '2o-s1.png',
            },
            {
                id: 2,
                sprite: '2o-s4.png',
            },
            {
                id: 3,
                sprite: '2o-s3.png',
            },
            {
                id: 4,
                sprite: '2o-s2.png',
            },
            {
                id: 5,
                sprite: '2o-s5.png',
            },
        ],
    },
    {
        id: 16,
        displayName: '16',
        position: 16,
        front: '16.png',
        back: '16b.png',
        occlusal: '16o.png',
        crown: '16f-c.png',
        root: '16f-r.png',
        implant: '1f-i.png',
        flipImplant: true,
        broken: '1f-broken.png',
        fractured: '1f-c-fractured.png',
        gingivalRecession: '1f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        classVYOffset: 27,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '1o-s1.png',
            },
            {
                id: 2,
                sprite: '1o-s4.png',
            },
            {
                id: 3,
                sprite: '1o-s3.png',
            },
            {
                id: 4,
                sprite: '1o-s2.png',
            },
            {
                id: 5,
                sprite: '1o-s5.png',
            },
        ],
    },
    {
        id: 17,
        displayName: '17',
        position: 17,
        front: '17.png',
        back: '17b.png',
        occlusal: '17o.png',
        crown: '17f-c.png',
        root: '17f-r.png',
        implant: '32f-i.png',
        flipImplant: true,
        broken: '32f-broken.png',
        fractured: '32f-c-fractured.png',
        gingivalRecession: '32f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        classVYOffset: -30,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '32o-s1.png',
            },
            {
                id: 2,
                sprite: '32o-s4.png',
            },
            {
                id: 3,
                sprite: '32o-s3.png',
            },
            {
                id: 4,
                sprite: '32o-s2.png',
            },
            {
                id: 5,
                sprite: '32o-s5.png',
            },
        ],
    },
    {
        id: 18,
        displayName: '18',
        position: 18,
        front: '18.png',
        back: '18b.png',
        occlusal: '18o.png',
        crown: '18f-c.png',
        root: '18f-r.png',
        implant: '31f-i.png',
        flipImplant: true,
        broken: '31f-broken.png',
        fractured: '31f-c-fractured.png',
        gingivalRecession: '31f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.9,
        classVYOffset: -30,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '31o-s1.png',
            },
            {
                id: 2,
                sprite: '31o-s4.png',
            },
            {
                id: 3,
                sprite: '31o-s3.png',
            },
            {
                id: 4,
                sprite: '31o-s2.png',
            },
            {
                id: 5,
                sprite: '31o-s5.png',
            },
        ],
    },
    {
        id: 19,
        displayName: '19',
        position: 19,
        front: '19.png',
        back: '19b.png',
        occlusal: '19o.png',
        crown: '19f-c.png',
        root: '19f-r.png',
        implant: '30f-i.png',
        flipImplant: true,
        broken: '30f-broken.png',
        fractured: '30f-c-fractured.png',
        gingivalRecession: '30f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.85,
        flipClassV: true,
        classVYOffset: -15,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '30o-s1.png',
            },
            {
                id: 2,
                sprite: '30o-s4.png',
            },
            {
                id: 3,
                sprite: '30o-s3.png',
            },
            {
                id: 4,
                sprite: '30o-s2.png',
            },
            {
                id: 5,
                sprite: '30o-s5.png',
            },
        ],
    },
    {
        id: 20,
        displayName: '20',
        position: 20,
        primaryId: 43,
        front: '20.png',
        back: '20b.png',
        occlusal: '20o.png',
        crown: '20f-c.png',
        root: '20f-r.png',
        implant: '29f-i.png',
        flipImplant: true,
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.6,
        classVYOffset: -30,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s4.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s2.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 21,
        displayName: '21',
        position: 21,
        primaryId: 44,
        front: '21.png',
        back: '21b.png',
        occlusal: '21o.png',
        crown: '21f-c.png',
        root: '21f-r.png',
        implant: '28f-i.png',
        flipImplant: true,
        broken: '28f-broken.png',
        fractured: '28f-c-fractured.png',
        gingivalRecession: '28f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '28o-s1.png',
            },
            {
                id: 2,
                sprite: '28o-s4.png',
            },
            {
                id: 3,
                sprite: '28o-s3.png',
            },
            {
                id: 4,
                sprite: '28o-s2.png',
            },
            {
                id: 5,
                sprite: '28o-s5.png',
            },
        ],
    },
    {
        id: 22,
        displayName: '22',
        position: 22,
        primaryId: 45,
        front: '22.png',
        occlusal: '22o.png',
        crown: '22f-c.png',
        root: '22f-r.png',
        implant: '27f-i.png',
        flipImplant: true,
        broken: '27f-broken.png',
        fractured: '27f-c-fractured.png',
        gingivalRecession: '27f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.48,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '27o-s1.png',
            },
            {
                id: 2,
                sprite: '27o-s4.png',
            },
            {
                id: 3,
                sprite: '27o-s3.png',
            },
            {
                id: 4,
                sprite: '27o-s2.png',
            },
            {
                id: 5,
                sprite: '27o-s5.png',
            },
        ],
    },
    {
        id: 23,
        displayName: '23',
        position: 23,
        primaryId: 46,
        front: '23.png',
        occlusal: '23o.png',
        crown: '23f-c.png',
        root: '23f-r.png',
        implant: '26f-i.png',
        flipImplant: true,
        broken: '26f-broken.png',
        fractured: '26f-c-fractured.png',
        gingivalRecession: '26f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.47,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '26o-s1.png',
            },
            {
                id: 2,
                sprite: '26o-s4.png',
            },
            {
                id: 3,
                sprite: '26o-s3.png',
            },
            {
                id: 4,
                sprite: '26o-s2.png',
            },
            {
                id: 5,
                sprite: '26o-s5.png',
            },
        ],
    },
    {
        id: 24,
        displayName: '24',
        position: 24,
        primaryId: 47,
        front: '24.png',
        occlusal: '24o.png',
        crown: '24f-c.png',
        root: '24f-r.png',
        implant: '25f-i.png',
        flipImplant: true,
        broken: '25f-broken.png',
        fractured: '25f-c-fractured.png',
        gingivalRecession: '25f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.45,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '25o-s1.png',
            },
            {
                id: 2,
                sprite: '25o-s4.png',
            },
            {
                id: 3,
                sprite: '25o-s3.png',
            },
            {
                id: 4,
                sprite: '25o-s2.png',
            },
            {
                id: 5,
                sprite: '25o-s5.png',
            },
        ],
    },
    {
        id: 25,
        displayName: '25',
        position: 25,
        primaryId: 48,
        front: '25.png',
        occlusal: '25o.png',
        crown: '25f-c.png',
        root: '25f-r.png',
        implant: '25f-i.png',
        broken: '25f-broken.png',
        fractured: '25f-c-fractured.png',
        gingivalRecession: '25f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.45,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '25o-s1.png',
            },
            {
                id: 2,
                sprite: '25o-s2.png',
            },
            {
                id: 3,
                sprite: '25o-s3.png',
            },
            {
                id: 4,
                sprite: '25o-s4.png',
            },
            {
                id: 5,
                sprite: '25o-s5.png',
            },
        ],
    },
    {
        id: 26,
        displayName: '26',
        position: 26,
        primaryId: 49,
        front: '26.png',
        occlusal: '26o.png',
        crown: '26f-c.png',
        root: '26f-r.png',
        implant: '26f-i.png',
        broken: '26f-broken.png',
        fractured: '26f-c-fractured.png',
        gingivalRecession: '26f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.47,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '26o-s1.png',
            },
            {
                id: 2,
                sprite: '26o-s2.png',
            },
            {
                id: 3,
                sprite: '26o-s3.png',
            },
            {
                id: 4,
                sprite: '26o-s4.png',
            },
            {
                id: 5,
                sprite: '26o-s5.png',
            },
        ],
    },
    {
        id: 27,
        displayName: '27',
        position: 27,
        primaryId: 50,
        front: '27.png',
        occlusal: '27o.png',
        crown: '27f-c.png',
        root: '27f-r.png',
        implant: '27f-i.png',
        broken: '27f-broken.png',
        fractured: '27f-c-fractured.png',
        gingivalRecession: '27f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.48,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '27o-s1.png',
            },
            {
                id: 2,
                sprite: '27o-s2.png',
            },
            {
                id: 3,
                sprite: '27o-s3.png',
            },
            {
                id: 4,
                sprite: '27o-s4.png',
            },
            {
                id: 5,
                sprite: '27o-s5.png',
            },
        ],
    },
    {
        id: 28,
        displayName: '28',
        position: 28,
        primaryId: 51,
        front: '28.png',
        back: '28b.png',
        occlusal: '28o.png',
        crown: '28f-c.png',
        root: '28f-r.png',
        implant: '28f-i.png',
        broken: '28f-broken.png',
        fractured: '28f-c-fractured.png',
        gingivalRecession: '28f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '28o-s1.png',
            },
            {
                id: 2,
                sprite: '28o-s2.png',
            },
            {
                id: 3,
                sprite: '28o-s3.png',
            },
            {
                id: 4,
                sprite: '28o-s4.png',
            },
            {
                id: 5,
                sprite: '28o-s5.png',
            },
        ],
    },
    {
        id: 29,
        displayName: '29',
        position: 29,
        primaryId: 52,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.6,
        classVYOffset: -30,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 30,
        displayName: '30',
        position: 30,
        front: '30.png',
        back: '30b.png',
        occlusal: '30o.png',
        crown: '30f-c.png',
        root: '30f-r.png',
        implant: '30f-i.png',
        broken: '30f-broken.png',
        fractured: '30f-c-fractured.png',
        gingivalRecession: '30f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.85,
        flipClassV: true,
        classVYOffset: -15,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '30o-s1.png',
            },
            {
                id: 2,
                sprite: '30o-s2.png',
            },
            {
                id: 3,
                sprite: '30o-s3.png',
            },
            {
                id: 4,
                sprite: '30o-s4.png',
            },
            {
                id: 5,
                sprite: '30o-s5.png',
            },
        ],
    },
    {
        id: 31,
        displayName: '31',
        position: 31,
        front: '31.png',
        back: '31b.png',
        occlusal: '31o.png',
        crown: '31f-c.png',
        root: '31f-r.png',
        implant: '31f-i.png',
        broken: '31f-broken.png',
        fractured: '31f-c-fractured.png',
        gingivalRecession: '31f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.9,
        classVYOffset: -30,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '31o-s1.png',
            },
            {
                id: 2,
                sprite: '31o-s2.png',
            },
            {
                id: 3,
                sprite: '31o-s3.png',
            },
            {
                id: 4,
                sprite: '31o-s4.png',
            },
            {
                id: 5,
                sprite: '31o-s5.png',
            },
        ],
    },
    {
        id: 32,
        displayName: '32',
        position: 32,
        front: '32.png',
        back: '32b.png',
        occlusal: '32o.png',
        crown: '32f-c.png',
        root: '32f-r.png',
        implant: '32f-i.png',
        broken: '32f-broken.png',
        fractured: '32f-c-fractured.png',
        gingivalRecession: '32f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        classVYOffset: -30,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '32o-s1.png',
            },
            {
                id: 2,
                sprite: '32o-s2.png',
            },
            {
                id: 3,
                sprite: '32o-s3.png',
            },
            {
                id: 4,
                sprite: '32o-s4.png',
            },
            {
                id: 5,
                sprite: '32o-s5.png',
            },
        ],
    },
    {
        id: 33,
        displayName: 'A',
        position: 4,
        primaryId: 33,
        front: '4.png',
        back: '3b.png',
        occlusal: '4o.png',
        crown: 'af-c.png',
        root: 'af-r.png',
        implant: '3f-i.png',
        broken: '4f-broken.png',
        fractured: '4f-c-fractured.png',
        gingivalRecession: '2f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.8,
        classVYOffset: 25,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: 'ao-s1.png',
            },
            {
                id: 2,
                sprite: 'ao-s2.png',
            },
            {
                id: 3,
                sprite: 'ao-s3.png',
            },
            {
                id: 4,
                sprite: 'ao-s4.png',
            },
            {
                id: 5,
                sprite: 'ao-s5.png',
            },
        ],
    },
    {
        id: 34,
        displayName: 'B',
        position: 5,
        primaryId: 34,
        front: '5.png',
        back: '2b.png',
        occlusal: '5o.png',
        crown: 'bf-c.png',
        root: 'bf-r.png',
        implant: '4f-i.png',
        broken: '2f-broken.png',
        fractured: '5f-c-fractured.png',
        gingivalRecession: '2f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.8,
        classVYOffset: 29,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: 'bo-s1.png',
            },
            {
                id: 2,
                sprite: 'bo-s2.png',
            },
            {
                id: 3,
                sprite: 'bo-s3.png',
            },
            {
                id: 4,
                sprite: 'bo-s4.png',
            },
            {
                id: 5,
                sprite: 'bo-s5.png',
            },
        ],
    },
    {
        id: 35,
        displayName: 'C',
        position: 6,
        primaryId: 35,
        front: '6.png',
        back: '4b.png',
        occlusal: '6o.png',
        crown: 'cf-c.png',
        root: 'cf-r.png',
        implant: '6f-i.png',
        broken: '6f-broken.png',
        fractured: '6f-c-fractured.png',
        gingivalRecession: '6f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.52,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: 'co-s1.png',
            },
            {
                id: 2,
                sprite: 'co-s2.png',
            },
            {
                id: 3,
                sprite: 'co-s3.png',
            },
            {
                id: 4,
                sprite: 'co-s4.png',
            },
            {
                id: 5,
                sprite: 'co-s5.png',
            },
        ],
    },
    {
        id: 36,
        displayName: 'D',
        position: 7,
        primaryId: 36,
        front: '7.png',
        occlusal: '7o.png',
        crown: 'df-c.png',
        root: 'df-r.png',
        implant: '7f-i.png',
        broken: '7f-broken.png',
        fractured: '7f-c-fractured.png',
        gingivalRecession: '7f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.54,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: 'do-s1.png',
            },
            {
                id: 2,
                sprite: 'do-s2.png',
            },
            {
                id: 3,
                sprite: 'do-s3.png',
            },
            {
                id: 4,
                sprite: 'do-s4.png',
            },
            {
                id: 5,
                sprite: 'do-s5.png',
            },
        ],
    },
    {
        id: 37,
        displayName: 'E',
        position: 8,
        primaryId: 37,
        front: '8.png',
        occlusal: '8o.png',
        crown: 'ef-c.png',
        root: 'ef-r.png',
        implant: '8f-i.png',
        broken: '8f-broken.png',
        fractured: '8f-c-fractured.png',
        gingivalRecession: '8f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.65,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: 'eo-s1.png',
            },
            {
                id: 2,
                sprite: 'eo-s2.png',
            },
            {
                id: 3,
                sprite: 'eo-s3.png',
            },
            {
                id: 4,
                sprite: 'eo-s4.png',
            },
            {
                id: 5,
                sprite: 'eo-s5.png',
            },
        ],
    },
    {
        id: 38,
        displayName: 'F',
        position: 9,
        primaryId: 38,
        front: '9.png',
        occlusal: '9o.png',
        crown: 'ff-c.png',
        root: 'ff-r.png',
        implant: '8f-i.png',
        flipImplant: true,
        broken: '8f-broken.png',
        fractured: '8f-c-fractured.png',
        gingivalRecession: '8f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.65,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: 'fo-s1.png',
            },
            {
                id: 2,
                sprite: 'fo-s4.png',
            },
            {
                id: 3,
                sprite: 'fo-s3.png',
            },
            {
                id: 4,
                sprite: 'fo-s2.png',
            },
            {
                id: 5,
                sprite: 'fo-s5.png',
            },
        ],
    },
    {
        id: 39,
        displayName: 'G',
        position: 10,
        primaryId: 39,
        front: '10.png',
        occlusal: '10o.png',
        crown: 'gf-c.png',
        root: 'gf-r.png',
        implant: '7f-i.png',
        flipImplant: true,
        broken: '7f-broken.png',
        fractured: '7f-c-fractured.png',
        gingivalRecession: '7f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.54,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: 'go-s1.png',
            },
            {
                id: 2,
                sprite: 'go-s4.png',
            },
            {
                id: 3,
                sprite: 'go-s3.png',
            },
            {
                id: 4,
                sprite: 'go-s2.png',
            },
            {
                id: 5,
                sprite: 'go-s5.png',
            },
        ],
    },
    {
        id: 40,
        displayName: 'H',
        position: 11,
        primaryId: 40,
        front: '11.png',
        back: '13b.png',
        occlusal: '11o.png',
        crown: 'hf-c.png',
        root: 'hf-r.png',
        implant: '6f-i.png',
        flipImplant: true,
        broken: '6f-broken.png',
        fractured: '6f-c-fractured.png',
        gingivalRecession: '6f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.54,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: 'ho-s1.png',
            },
            {
                id: 2,
                sprite: 'ho-s4.png',
            },
            {
                id: 3,
                sprite: 'ho-s3.png',
            },
            {
                id: 4,
                sprite: 'ho-s2.png',
            },
            {
                id: 5,
                sprite: 'ho-s5.png',
            },
        ],
    },
    {
        id: 41,
        displayName: 'I',
        position: 12,
        primaryId: 41,
        front: '12.png',
        back: '14b.png',
        occlusal: '12o.png',
        crown: 'if-c.png',
        root: 'if-r.png',
        implant: '4f-i.png',
        flipImplant: true,
        broken: '2f-broken.png',
        fractured: '5f-c-fractured.png',
        gingivalRecession: '3f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.8,
        flipClassV: true,
        classVYOffset: 29,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: 'io-s1.png',
            },
            {
                id: 2,
                sprite: 'io-s4.png',
            },
            {
                id: 3,
                sprite: 'io-s3.png',
            },
            {
                id: 4,
                sprite: 'io-s2.png',
            },
            {
                id: 5,
                sprite: 'io-s5.png',
            },
        ],
    },
    {
        id: 42,
        displayName: 'J',
        position: 13,
        primaryId: 42,
        front: '13.png',
        back: '15b.png',
        occlusal: '13o.png',
        crown: 'jf-c.png',
        root: 'jf-r.png',
        implant: '3f-i.png',
        flipImplant: true,
        broken: '4f-broken.png',
        fractured: '4f-c-fractured.png',
        gingivalRecession: '4f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.8,
        flipClassV: true,
        classVYOffset: 29,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: 'jo-s1.png',
            },
            {
                id: 2,
                sprite: 'jo-s4.png',
            },
            {
                id: 3,
                sprite: 'jo-s3.png',
            },
            {
                id: 4,
                sprite: 'jo-s2.png',
            },
            {
                id: 5,
                sprite: 'jo-s5.png',
            },
        ],
    },
    {
        id: 43,
        displayName: 'K',
        position: 20,
        primaryId: 43,
        front: '20.png',
        back: '19b.png',
        occlusal: '20o.png',
        crown: 'kf-c.png',
        root: 'kf-r.png',
        implant: '30f-i.png',
        flipImplant: true,
        broken: '30f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '30f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.8,
        classVYOffset: -25,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: 'ko-s1.png',
            },
            {
                id: 2,
                sprite: 'ko-s4.png',
            },
            {
                id: 3,
                sprite: 'ko-s3.png',
            },
            {
                id: 4,
                sprite: 'ko-s2.png',
            },
            {
                id: 5,
                sprite: 'ko-s5.png',
            },
        ],
    },
    {
        id: 44,
        displayName: 'L',
        position: 21,
        primaryId: 44,
        front: '21.png',
        back: '18b.png',
        occlusal: '21o.png',
        crown: 'lf-c.png',
        root: 'lf-r.png',
        implant: '30f-i.png',
        flipImplant: true,
        broken: '31f-broken.png',
        fractured: '28f-c-fractured.png',
        gingivalRecession: '31f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.8,
        classVYOffset: -5,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: 'lo-s1.png',
            },
            {
                id: 2,
                sprite: 'lo-s4.png',
            },
            {
                id: 3,
                sprite: 'lo-s3.png',
            },
            {
                id: 4,
                sprite: 'lo-s2.png',
            },
            {
                id: 5,
                sprite: 'lo-s5.png',
            },
        ],
    },
    {
        id: 45,
        displayName: 'M',
        position: 22,
        primaryId: 45,
        front: '22.png',
        back: '20b.png',
        occlusal: '22o.png',
        crown: 'mf-c.png',
        root: 'mf-r.png',
        implant: '27f-i.png',
        flipImplant: true,
        broken: '28f-broken.png',
        fractured: '27f-c-fractured.png',
        gingivalRecession: '28f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.48,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: 'mo-s1.png',
            },
            {
                id: 2,
                sprite: 'mo-s4.png',
            },
            {
                id: 3,
                sprite: 'mo-s3.png',
            },
            {
                id: 4,
                sprite: 'mo-s2.png',
            },
            {
                id: 5,
                sprite: 'mo-s5.png',
            },
        ],
    },
    {
        id: 46,
        displayName: 'N',
        position: 23,
        primaryId: 46,
        front: '23.png',
        occlusal: '23o.png',
        crown: 'nf-c.png',
        root: 'nf-r.png',
        implant: '26f-i.png',
        flipImplant: true,
        broken: '26f-broken.png',
        fractured: '26f-c-fractured.png',
        gingivalRecession: '26f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.48,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: 'no-s1.png',
            },
            {
                id: 2,
                sprite: 'no-s4.png',
            },
            {
                id: 3,
                sprite: 'no-s3.png',
            },
            {
                id: 4,
                sprite: 'no-s2.png',
            },
            {
                id: 5,
                sprite: 'no-s5.png',
            },
        ],
    },
    {
        id: 47,
        displayName: 'O',
        position: 24,
        primaryId: 47,
        front: '24.png',
        occlusal: '24o.png',
        crown: 'of-c.png',
        root: 'of-r.png',
        implant: '25f-i.png',
        flipImplant: true,
        broken: '25f-broken.png',
        fractured: '25f-c-fractured.png',
        gingivalRecession: '25f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.45,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: 'oo-s1.png',
            },
            {
                id: 2,
                sprite: 'oo-s4.png',
            },
            {
                id: 3,
                sprite: 'oo-s3.png',
            },
            {
                id: 4,
                sprite: 'oo-s2.png',
            },
            {
                id: 5,
                sprite: 'oo-s5.png',
            },
        ],
    },
    {
        id: 48,
        displayName: 'P',
        position: 25,
        primaryId: 48,
        front: '25.png',
        occlusal: '25o.png',
        crown: 'pf-c.png',
        root: 'pf-r.png',
        implant: '25f-i.png',
        broken: '25f-broken.png',
        fractured: '25f-c-fractured.png',
        gingivalRecession: '25f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.45,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: 'po-s1.png',
            },
            {
                id: 4,
                sprite: 'po-s4.png',
            },
            {
                id: 3,
                sprite: 'po-s3.png',
            },
            {
                id: 2,
                sprite: 'po-s2.png',
            },
            {
                id: 5,
                sprite: 'po-s5.png',
            },
        ],
    },
    {
        id: 49,
        displayName: 'Q',
        position: 26,
        primaryId: 49,
        front: '26.png',
        occlusal: '26o.png',
        crown: 'qf-c.png',
        root: 'qf-r.png',
        implant: '26f-i.png',
        broken: '26f-broken.png',
        fractured: '26f-c-fractured.png',
        gingivalRecession: '26f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.48,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: 'qo-s1.png',
            },
            {
                id: 2,
                sprite: 'qo-s2.png',
            },
            {
                id: 3,
                sprite: 'qo-s3.png',
            },
            {
                id: 4,
                sprite: 'qo-s4.png',
            },
            {
                id: 5,
                sprite: 'qo-s5.png',
            },
        ],
    },
    {
        id: 50,
        displayName: 'R',
        position: 27,
        primaryId: 50,
        front: '27.png',
        back: '29b.png',
        occlusal: '27o.png',
        crown: 'rf-c.png',
        root: 'rf-r.png',
        implant: '27f-i.png',
        broken: '28f-broken.png',
        fractured: '27f-c-fractured.png',
        gingivalRecession: '28f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.48,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: 'ro-s1.png',
            },
            {
                id: 2,
                sprite: 'ro-s2.png',
            },
            {
                id: 3,
                sprite: 'ro-s3.png',
            },
            {
                id: 4,
                sprite: 'ro-s4.png',
            },
            {
                id: 5,
                sprite: 'ro-s5.png',
            },
        ],
    },
    {
        id: 51,
        displayName: 'S',
        position: 28,
        primaryId: 51,
        front: '28.png',
        occlusal: '28o.png',
        back: '31b.png',
        crown: 'sf-c.png',
        root: 'sf-r.png',
        implant: '30f-i.png',
        broken: '31f-broken.png',
        fractured: '28f-c-fractured.png',
        gingivalRecession: '31f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.8,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: 'so-s1.png',
            },
            {
                id: 2,
                sprite: 'so-s2.png',
            },
            {
                id: 3,
                sprite: 'so-s3.png',
            },
            {
                id: 4,
                sprite: 'so-s4.png',
            },
            {
                id: 5,
                sprite: 'so-s5.png',
            },
        ],
    },
    {
        id: 52,
        displayName: 'T',
        position: 29,
        primaryId: 52,
        front: '29.png',
        back: '30b.png',
        occlusal: '29o.png',
        crown: 'tf-c.png',
        root: 'tf-r.png',
        implant: '30f-i.png',
        broken: '30f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '30f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.8,
        flipClassV: true,
        classVYOffset: -25,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: 'to-s1.png',
            },
            {
                id: 2,
                sprite: 'to-s2.png',
            },
            {
                id: 3,
                sprite: 'to-s3.png',
            },
            {
                id: 4,
                sprite: 'to-s4.png',
            },
            {
                id: 5,
                sprite: 'to-s5.png',
            },
        ],
    },
    // * Supernumerary Teeth
    {
        id: 53,
        displayName: '51',
        position: 1,
        supernumeraryId: 51,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 54,
        displayName: '52',
        position: 2,
        supernumeraryId: 52,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 55,
        displayName: '53',
        position: 3,
        supernumeraryId: 53,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 56,
        displayName: '54',
        position: 4,
        supernumeraryId: 54,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 57,
        displayName: '55',
        position: 5,
        supernumeraryId: 55,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 58,
        displayName: '56',
        position: 6,
        supernumeraryId: 56,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 59,
        displayName: '57',
        position: 7,
        supernumeraryId: 57,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 60,
        displayName: '58',
        position: 8,
        supernumeraryId: 58,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 61,
        displayName: '59',
        position: 9,
        supernumeraryId: 59,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 62,
        displayName: '60',
        position: 10,
        supernumeraryId: 60,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 63,
        displayName: '61',
        position: 11,
        supernumeraryId: 61,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 64,
        displayName: '62',
        position: 12,
        supernumeraryId: 62,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 65,
        displayName: '63',
        position: 13,
        supernumeraryId: 63,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 66,
        displayName: '64',
        position: 14,
        supernumeraryId: 64,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 67,
        displayName: '65',
        position: 15,
        supernumeraryId: 65,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 68,
        displayName: '66',
        position: 16,
        supernumeraryId: 66,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    // * Lower supernumerary
    {
        id: 69,
        displayName: '67',
        position: 17,
        supernumeraryId: 67,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 70,
        displayName: '68',
        position: 18,
        supernumeraryId: 68,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 71,
        displayName: '69',
        position: 19,
        supernumeraryId: 69,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 72,
        displayName: '70',
        position: 20,
        supernumeraryId: 70,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 73,
        displayName: '71',
        position: 21,
        supernumeraryId: 71,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 74,
        displayName: '72',
        position: 22,
        supernumeraryId: 72,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 75,
        displayName: '73',
        position: 23,
        supernumeraryId: 73,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 76,
        displayName: '74',
        position: 24,
        supernumeraryId: 74,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 77,
        displayName: '75',
        position: 25,
        supernumeraryId: 75,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 78,
        displayName: '76',
        position: 26,
        supernumeraryId: 76,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 79,
        displayName: '77',
        position: 27,
        supernumeraryId: 77,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 80,
        displayName: '78',
        position: 28,
        supernumeraryId: 78,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 81,
        displayName: '79',
        position: 29,
        supernumeraryId: 79,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 82,
        displayName: '80',
        position: 30,
        supernumeraryId: 80,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 83,
        displayName: '81',
        position: 31,
        supernumeraryId: 81,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 84,
        displayName: '82',
        position: 32,
        supernumeraryId: 82,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    // * Primary Supernumerary Teeth
    // * Upper Arch
    {
        id: 85,
        displayName: 'AS',
        position: 4,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 86,
        displayName: 'BS',
        position: 5,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 87,
        displayName: 'CS',
        position: 6,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 88,
        displayName: 'DS',
        position: 7,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 89,
        displayName: 'ES',
        position: 8,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 90,
        displayName: 'FS',
        position: 9,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 91,
        displayName: 'GS',
        position: 10,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 92,
        displayName: 'HS',
        position: 11,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 93,
        displayName: 'IS',
        position: 12,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 94,
        displayName: 'JS',
        position: 13,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 95,
        displayName: 'KS',
        position: 20,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 96,
        displayName: 'LS',
        position: 21,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 97,
        displayName: 'MS',
        position: 22,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 98,
        displayName: 'NS',
        position: 23,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 99,
        displayName: 'OS',
        position: 24,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 100,
        displayName: 'PS',
        position: 25,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 101,
        displayName: 'QS',
        position: 26,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 102,
        displayName: 'RS',
        position: 27,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 103,
        displayName: 'SS',
        position: 28,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
    {
        id: 104,
        displayName: 'TS',
        position: 29,
        front: '29.png',
        back: '29b.png',
        occlusal: '29o.png',
        crown: '29f-c.png',
        root: '29f-r.png',
        implant: '29f-i.png',
        broken: '29f-broken.png',
        fractured: '29f-c-fractured.png',
        gingivalRecession: '29f-r-gingival-recession.png',
        abcessPeriapicalCrown: 'f-c-abscess-periapical.png',
        abcessPeriapicalRoot: 'f-r-abscess-periapical.png',
        scaleFactor: 0.55,
        flipClassV: true,
        occlusalSurfaces: [
            {
                id: 1,
                sprite: '29o-s1.png',
            },
            {
                id: 2,
                sprite: '29o-s2.png',
            },
            {
                id: 3,
                sprite: '29o-s3.png',
            },
            {
                id: 4,
                sprite: '29o-s4.png',
            },
            {
                id: 5,
                sprite: '29o-s5.png',
            },
        ],
    },
];

export const PERMANENT_TEETH_REFS: SpriteReference[] = toothSpriteReferences.filter((tooth) => tooth.id < 33);
export const UL_PERMANENT_TEETH_REFS = PERMANENT_TEETH_REFS.slice(0, 8);
export const UR_PERMANENT_TEETH_REFS = PERMANENT_TEETH_REFS.slice(8, 16);
export const LR_PERMANENT_TEETH_REFS = PERMANENT_TEETH_REFS.slice(16, 24);
export const LL_PERMANENT_TEETH_REFS = PERMANENT_TEETH_REFS.slice(24, 32);

export const PRIMARY_TEETH_REFS: SpriteReference[] = toothSpriteReferences.filter((tooth) => tooth.id > 32 && tooth.id < 53);
export const UL_PRIMARY_TEETH_REFS = PRIMARY_TEETH_REFS.slice(0, 5);
export const UR_PRIMARY_TEETH_REFS = PRIMARY_TEETH_REFS.slice(5, 10);
export const LR_PRIMARY_TEETH_REFS = PRIMARY_TEETH_REFS.slice(10, 15);
export const LL_PRIMARY_TEETH_REFS = PRIMARY_TEETH_REFS.slice(15, 20);

export const PERM_SUPERNUM_TEETH_REFS: SpriteReference[] = toothSpriteReferences.filter(
    (tooth) => tooth.id > 52 && tooth.id < 85,
);
export const UL_PERM_SUPERNUM_TEETH_REFS = PERM_SUPERNUM_TEETH_REFS.slice(0, 8);
export const UR_PERM_SUPERNUM_TEETH_REFS = PERM_SUPERNUM_TEETH_REFS.slice(8, 16);
export const LR_PERM_SUPERNUM_TEETH_REFS = PERM_SUPERNUM_TEETH_REFS.slice(16, 24);
export const LL_PERM_SUPERNUM_TEETH_REFS = PERM_SUPERNUM_TEETH_REFS.slice(24, 32);

export const PRIM_SUPERNUM_TEETH_REFS: SpriteReference[] = toothSpriteReferences.filter(
    (tooth) => tooth.id > 84 && tooth.id < 105,
);
export const UL_PRIM_SUPERNUM_TEETH_REFS = PRIMARY_TEETH_REFS.slice(0, 5);
export const UR_PRIM_SUPERNUM_TEETH_REFS = PRIMARY_TEETH_REFS.slice(5, 10);
export const LR_PRIM_SUPERNUM_TEETH_REFS = PRIMARY_TEETH_REFS.slice(10, 15);
export const LL_PRIM_SUPERNUM_TEETH_REFS = PRIMARY_TEETH_REFS.slice(15, 20);

export const ULReferences = [
    ...UL_PERM_SUPERNUM_TEETH_REFS,
    ...UL_PERMANENT_TEETH_REFS,
    ...UL_PRIMARY_TEETH_REFS,
    ...UL_PRIM_SUPERNUM_TEETH_REFS,
];

export const URReferences = [
    ...UR_PERM_SUPERNUM_TEETH_REFS,
    ...UR_PERMANENT_TEETH_REFS,
    ...UR_PRIMARY_TEETH_REFS,
    ...UR_PRIM_SUPERNUM_TEETH_REFS,
];

export const LLReferences = [
    ...LL_PERM_SUPERNUM_TEETH_REFS,
    ...LL_PERMANENT_TEETH_REFS,
    ...LL_PRIMARY_TEETH_REFS,
    ...LL_PRIM_SUPERNUM_TEETH_REFS,
];

export const LRReferences = [
    ...LR_PERM_SUPERNUM_TEETH_REFS,
    ...LR_PERMANENT_TEETH_REFS,
    ...LR_PRIMARY_TEETH_REFS,
    ...LR_PRIM_SUPERNUM_TEETH_REFS,
];

export enum Arch {
    UA = 'UA',
    LA = 'LA',
}

export const archReferencesLookup: { [key in Arch]: SpriteReference[] } = {
    [Arch.LA]: [...LLReferences, ...LRReferences],
    [Arch.UA]: [...ULReferences, ...URReferences],
};

//FUTURE JAMES, please fix miss named variables for quad references.
export function getQuadrantOfTooth(toothNumber?: number) {
    if (ULReferences.find((ref) => ref.id === toothNumber)) return 'UR';
    if (URReferences.find((ref) => ref.id === toothNumber)) return 'UL';
    if (LLReferences.find((ref) => ref.id === toothNumber)) return 'LR';
    if (LRReferences.find((ref) => ref.id === toothNumber)) return 'LL';
}

export function getArchOfTooth(toothNumber?: number) {
    if (archReferencesLookup[Arch.UA].find((ref) => ref.id === toothNumber)) return 'UA';
    if (archReferencesLookup[Arch.LA].find((ref) => ref.id === toothNumber)) return 'LA';
}

export interface SpriteReference {
    id: number;
    displayName: string;
    position: number;
    supernumeraryId?: number;
    classVYOffset?: number;
    primaryId?: number;
    front: string;
    back?: string;
    occlusal: string;
    crown: string;
    root: string;
    implant: string;
    flipImplant?: boolean;
    broken: string;
    fractured: string;
    gingivalRecession: string;
    abcessPeriapicalCrown: string;
    abcessPeriapicalRoot: string;
    scaleFactor?: number;
    flipClassV?: boolean;
    occlusalSurfaces: {
        id: number;
        sprite: string;
    }[];
}
type TeethRefs = {
    permanent: SpriteReference[];
    primary: SpriteReference[];
    superNumeraryPermanent: SpriteReference[];
    superNumeraryPrimary: SpriteReference[];
};
export const TEETH_REFS: TeethRefs = {
    permanent: PERMANENT_TEETH_REFS,
    primary: PRIMARY_TEETH_REFS,
    superNumeraryPermanent: PERM_SUPERNUM_TEETH_REFS,
    superNumeraryPrimary: PRIM_SUPERNUM_TEETH_REFS,
};

export default spriteSheets;
