import { useTheme } from '@fluentui/react';

function PerioIcon(): JSX.Element {
    const { semanticColors } = useTheme();
    return (
        <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            style={{ height: 24 }}
            viewBox="0 0 271.95 235.44"
        >
            <path
                style={{ fill: semanticColors.bodyText }}
                className="cls-1"
                d="M.85,50.52,85.73,22.11s10.1-3.48,13.06,8.12,15.85,59.18,15.85,59.18,2.64,7.67,5,10L132.51,112l-5.49,5.6L115.28,106.1c-2.27-2.23-5.82-8-7.24-15.14S93.53,32,93.53,32s-.87-6.62-8.28-4S2.77,53.34,2.77,53.34A2,2,0,0,1,.29,52.8,1.63,1.63,0,0,1,.85,50.52Z"
                transform="translate(0 -21.62)"
            />
            <rect
                style={{ fill: semanticColors.bodyText }}
                x="194.96"
                y="102.62"
                width="18.4"
                height="173.32"
                transform="translate(-74.04 178.18) rotate(-45)"
            />
        </svg>
    );
}

export default PerioIcon;
