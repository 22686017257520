import { IDropdownOption, IconButton, SelectionMode, Stack } from '@fluentui/react';
import { Field, SortableDetailsList } from 'components';
import { useSelector } from 'hooks';
import { useDispatch } from 'react-redux';
import {
    selectLocationsOfCareForReportingGroups,
    selectSelectedReportingCategoryItems,
} from 'state/slices/reporting/reporting-groups/reporting-groups.selectors';
import {
    addSelectedReportingCategoryItem,
    removeSelectedReportingCategoryItem,
    setSelectedReportingCategoryItems,
} from 'state/slices/reporting/reporting.slice';

export default function ReportingGroupCategoryLocationsOfCare() {
    const _locationsOfCare = useSelector(selectLocationsOfCareForReportingGroups);

    const _selectSelectedReportingCategoryItems = useSelector(selectSelectedReportingCategoryItems);

    const dispatch = useDispatch();

    const locationsOfCareOptions: IDropdownOption[] = _locationsOfCare.map((loc) => ({
        key: loc.id,
        text: loc.displayName,
    }));

    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <Stack.Item grow>
                <Field.SearchCombo
                    label="Search Location of Care"
                    selectedKey={_selectSelectedReportingCategoryItems}
                    options={locationsOfCareOptions}
                    onChange={(ev, option) => {
                        if (option) {
                            const itemId = option.key as string;
                            if (_selectSelectedReportingCategoryItems.includes(itemId)) {
                                //remove
                                dispatch(removeSelectedReportingCategoryItem(itemId));
                            } else {
                                //add
                                dispatch(addSelectedReportingCategoryItem(itemId));
                            }
                        }
                    }}
                    onClear={() => {
                        dispatch(setSelectedReportingCategoryItems([]));
                    }}
                    multiSelect
                    maxResults={200}
                />
            </Stack.Item>
            {_selectSelectedReportingCategoryItems.length && (
                <SortableDetailsList<string>
                    columns={[
                        {
                            key: 'displayName',
                            name: 'Location of Care Name',
                            fieldName: 'displayName',
                            minWidth: 70,
                            maxWidth: 200,
                            onRender: (item) => {
                                if (item) {
                                    const option = _locationsOfCare.find((c) => c.id === item);
                                    return option?.displayName ?? '';
                                }
                            },
                            getValueKey: (item) => {
                                if (item) {
                                    const option = _locationsOfCare.find((c) => c.id === item);
                                    return option?.displayName ?? '';
                                }
                                return '';
                            },
                        },
                        {
                            key: 'address',
                            name: 'Address',
                            minWidth: 70,
                            fieldName: 'address',
                            onRender: (item) => {
                                if (item) {
                                    const option = _locationsOfCare.find((c) => c.id === item);
                                    return option
                                        ? `${option?.address1 ?? ''} ${option?.address2 ?? ''} ${option?.city ?? ''} ${
                                              option?.state ?? ''
                                          } ${option?.zip ?? ''}`
                                        : '';
                                }
                            },
                            getValueKey: (item) => {
                                if (item) {
                                    const option = _locationsOfCare.find((c) => c.id === item);
                                    return option
                                        ? `${option?.address1 ?? ''} ${option?.address2 ?? ''} ${option?.city ?? ''} ${
                                              option?.state ?? ''
                                          } ${option?.zip ?? ''}`
                                        : '';
                                }
                                return '';
                            },
                        },
                        {
                            key: 'delete',
                            name: 'Delete',
                            minWidth: 70,
                            maxWidth: 200,
                            onRender: (item) => {
                                return (
                                    <IconButton
                                        onClick={() => {
                                            if (item) dispatch(removeSelectedReportingCategoryItem(item));
                                        }}
                                        iconProps={{ iconName: 'Delete' }}
                                    />
                                );
                            },
                        },
                    ]}
                    items={_selectSelectedReportingCategoryItems}
                    selectionMode={SelectionMode.none}
                    sortColumns={['displayName']}
                    initialSortDirection={['asc']}
                    sortOnMount
                />
            )}
        </Stack>
    );
}
