import VitalsDetailsList from './VitalsDetailsList';
import { Section } from 'components';
import { IButtonStyles, IconButton, Stack, Text, TooltipHost } from '@fluentui/react';
import useEncounterId from 'hooks/useEncounterId';
import { useDispatch } from 'react-redux';
import { setVitalPanelOpen } from 'state/slices/patient/patient.slice';
import VitalsPanel from './VitalsPanel';
import useSelector from 'hooks/useSelector';
import { vitalsForEncounterAlreadyExist } from 'state/slices/patient/vitals/vitals.selectors';

function Vitals(): JSX.Element {
    const dispatch = useDispatch();
    const _vitalsForEncounterAlreadyExist = useSelector(vitalsForEncounterAlreadyExist)
    const encounterId = useEncounterId();

    const menuButtonStyles: IButtonStyles = {
        root: {
            height: 25,
            maxWidth: 25,
        },
        flexContainer: {
            selectors: {
                '.ms-Button-menuIcon': {
                    display: 'none',
                },
            },
        },
    };

    const handleAddVitals = () => {
        dispatch(setVitalPanelOpen(true));
    };
    return (
        <Section
            heading={
                <Stack tokens={{ childrenGap: 10 }} horizontal grow>
                    <Text variant="xLarge">Vitals</Text>
                    {encounterId && (
                        <TooltipHost content={_vitalsForEncounterAlreadyExist ? 'Vitals are already entered for the current encounter.' : undefined}> 
                        <IconButton styles={menuButtonStyles} iconProps={{ iconName: 'Add' }} onClick={handleAddVitals} disabled={_vitalsForEncounterAlreadyExist} />
                        </TooltipHost>
                    )}
                </Stack>
            }
        >
            <VitalsPanel />
            <VitalsDetailsList />
        </Section>
    );
}

export default Vitals;
