import { IContextualMenuItem } from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import dentalApi from 'api/dental.api';
import { IAllergen } from 'api/models/patient-allergy.model';
import { Field } from 'components';
import { IAsyncSearchComboField } from 'components/Field/TypeaheadSearchField';
import { useState } from 'react';
import { Dictionary, sortBy, map } from 'lodash';
import { useSelector } from 'hooks';
import { selectAllergiesToAdd, selectPatientAllergies } from 'state/slices/patient/allergies/allergies.selectors';

function AllergenDropdown(props: Partial<IAsyncSearchComboField> & { addAllergy: (item?: IAllergen) => void }): JSX.Element {
    const allergiesToAdd = useSelector(selectAllergiesToAdd);
    const patientAllergies = useSelector(selectPatientAllergies);

    const [searchQuery, setSearchQuery] = useState<string>('');
    const [results, setResults] = useState<Dictionary<IAllergen | undefined>>({});
    const [loading, { setFalse, setTrue }] = useBoolean(false);

    // Return a list excluding already added or about to be added allergies (PatientAllergies || allergiesToAdd)
    const menuItems = (): IContextualMenuItem[] => {
        const allergensList = (map(results) as IAllergen[]) ?? [];

        if (!allergensList.length) return [];

        const allergies = patientAllergies?.allergies;
        const options = allergensList.filter(notInAllergiesToAdd).filter(notInPatientAllergies).map(mapAllergenOptions);

        return sortBy(options, ['text'], ['desc']);

        function mapAllergenOptions(allergen: IAllergen): IContextualMenuItem {
            return {
                key: allergen.id,
                text: allergen.displayName,
                onClick: () => {
                    props.addAllergy(allergen);
                    setSearchQuery('');
                    setResults({});
                },
            };
        }

        function notInAllergiesToAdd(allergen: IAllergen) {
            if (allergiesToAdd.length) {
                const allergyToAddExists = allergiesToAdd?.find((allergy) => allergy.allergenId === allergen.id);
                return allergyToAddExists ? false : true;
            } else {
                return true;
            }
        }
        function notInPatientAllergies(allergen: IAllergen) {
            if (allergies?.length) {
                const allergyExists = allergies.find(
                    (allergy) => allergy.allergenId === allergen.references?.athenaOne || allergy.allergenId === allergen.id,
                );
                return allergyExists ? false : true;
            } else {
                return true;
            }
        }
    };

    const _onSearch = async () => {
        if (searchQuery.length > 1) {
            setTrue();
            const results = await searchAllergens(searchQuery);
            if (results) setResults(results);
            setFalse();
        }
    };

    return (
        <Field.TypeaheadSearch
            {...props}
            placeholder="Search allergens..."
            menuItems={menuItems()}
            onSearch={_onSearch}
            value={searchQuery}
            onChange={(ev, value) => setSearchQuery(value ?? '')}
            canClear
            onClear={() => setResults({})}
            autoComplete="off"
            loading={loading}
            autoSearch
            autoSearchDelay={300}
            noItemsFoundMessage="No matching allergies found."
        />
    );
}

async function searchAllergens(query: string) {
    const { data: allergens } = await dentalApi.searchAllergens(query);

    return allergens;
}

export default AllergenDropdown;
