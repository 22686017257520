import { Dictionary } from '@reduxjs/toolkit';
import {
    ChartProcedureDiagnosis,
    ChartProcedurePreAuth,
    ChartProcedureStatus,
    IChartCondition,
    IChartProcedure,
} from 'api/models/chart.model';
import { ICondition } from 'api/models/lookup.model';
import { IProcedure, ProcedureBillingStatus } from 'api/models/procedure.model';
import { GeneralSupports } from 'api/models/support-types';
import { ToothArea } from 'api/models/tooth-area';
import { ProcedureActionType } from './chart/chart.slice';
import Pipeline from './pipeline';

export enum ChartActionType {
    Procedure = 'Procedure',
    Condition = 'Condition',
}

export interface IChartAction extends GeneralSupports {
    areas?: (keyof typeof ToothArea)[];
    stage?: string;
    visitId?: string;
    toothIds?: number[];
    actionId: string;
    actionType: keyof typeof ChartActionType;
    status?: keyof typeof ChartProcedureStatus;
    preAuthorization?: ChartProcedurePreAuth;
    diagnosisCodes?: ChartProcedureDiagnosis[];
    notes?: string;
    procedureType?: ProcedureActionType;
    treatmentPlanId?: string;
    treatingProviderId?: string;
    billingProviderId?: string;
    supervisingProviderId?: string;
    onSetDate?: string;
    fee?: number;
    encounterId?: string;
    code?: string;
    procedureDescription?: string;
    conditionDescription?: string;
    onCompletedDate?: string;
    billingStatus?: ProcedureBillingStatus;
    statusChangedDate?: string;
}

type ChartActionsListArgs = {
    conditions?: IChartCondition[];
    chartProcedures?: IChartProcedure[];
    procedures?: Dictionary<IProcedure>;
    conditionLookup?: Dictionary<ICondition>;
};

export default class ChartingActionsList extends Pipeline<IChartAction> {
    private _conditions: IChartCondition[] | undefined;
    private _chartProcedures: IChartProcedure[] | undefined;
    private _procedureLookup: Dictionary<IProcedure> | undefined;
    private _conditionLookup: Dictionary<ICondition> | undefined;

    constructor({ conditions, chartProcedures, procedures, conditionLookup }: ChartActionsListArgs) {
        super([]);
        this._conditions = conditions;
        this._chartProcedures = chartProcedures;
        this._procedureLookup = procedures;
        this._conditionLookup = conditionLookup;
        this.generateActionsList();
    }

    private generateActionsList() {
        const items: (IChartCondition | IChartProcedure)[] = [];

        if (this._conditions) items.push(...this._conditions);
        if (this._chartProcedures) items.push(...this._chartProcedures);

        const list: IChartAction[] = items.map((a) => {
            const actionType = (a as any).conditionId ? 'Condition' : 'Procedure';
            return {
                id: a.id,
                isDeleted: a.isDeleted,
                areas: a.areas,
                createdBy: a.createdBy,
                createdOn: a.createdOn,
                modifiedBy: a.modifiedBy,
                modifiedOn: a.modifiedOn,
                toothIds: (a as any).toothIds ? [...(a as any).toothIds] : (a as any).toothId ? [(a as any).toothId] : [],
                actionId: (a as any).conditionId ? (a as any).conditionId : (a as any).procedureId ? (a as any).procedureId : '',
                actionType,
                encounterId: a.encounterId,
                status: (a as IChartProcedure).status ? (a as IChartProcedure).status : undefined,
                preAuthorization: (a as IChartProcedure).preAuthorization ? (a as IChartProcedure).preAuthorization : undefined,
                diagnosisCodes: (a as IChartProcedure).diagnosisCodes ? (a as IChartProcedure).diagnosisCodes : undefined,
                notes: (a as IChartProcedure).notes
                    ? (a as IChartProcedure).notes
                    : (a as IChartCondition).note
                    ? (a as IChartCondition).note
                    : undefined,
                treatmentPlanId: (a as IChartProcedure).treatmentPlanId ? (a as IChartProcedure).treatmentPlanId : undefined,
                treatingProviderId: (a as IChartProcedure).treatingProviderId
                    ? (a as IChartProcedure).treatingProviderId
                    : undefined,
                supervisingProviderId: (a as IChartProcedure).supervisingProviderId
                    ? (a as IChartProcedure).supervisingProviderId
                    : undefined,
                billingProviderId: (a as IChartProcedure).billingProviderId
                    ? (a as IChartProcedure).billingProviderId
                    : undefined,
                procedureType: (a as IChartProcedure).type ? (a as IChartProcedure).type : undefined,
                onSetDate: (a as IChartCondition).onSetDate ? (a as IChartCondition).onSetDate : undefined,
                onCompletedDate: (a as IChartProcedure).onCompletedDate ? (a as IChartProcedure).onCompletedDate : undefined,
                statusChangedDate: (a as IChartProcedure).statusChangedDate
                    ? (a as IChartProcedure).statusChangedDate
                    : undefined,
                fee:
                    this._procedureLookup && (a as IChartProcedure).procedureId && !(a as IChartProcedure).stage
                        ? this._procedureLookup[(a as IChartProcedure).procedureId as string]?.fee
                        : this._procedureLookup && (a as IChartProcedure).procedureId && (a as IChartProcedure).stage === 'Final'
                        ? this._procedureLookup[(a as IChartProcedure).procedureId as string]?.fee
                        : undefined,
                code:
                    actionType === 'Procedure'
                        ? this._procedureLookup && (a as IChartProcedure)
                            ? this._procedureLookup[(a as IChartProcedure).procedureId as string]?.code
                            : undefined
                        : this._conditionLookup && (a as IChartCondition)
                        ? this._conditionLookup[(a as IChartCondition).conditionId as string]?.code
                        : undefined,
                procedureDescription:
                    this._procedureLookup && (a as IChartProcedure)
                        ? this._procedureLookup[(a as IChartProcedure).procedureId as string]?.description
                        : undefined,
                conditionDescription:
                    this._conditionLookup && (a as IChartCondition)
                        ? this._conditionLookup[(a as IChartCondition).conditionId as string]?.description
                        : undefined,
                billingStatus:
                    actionType === 'Procedure' && !(a as IChartProcedure).stage
                        ? (a as IChartProcedure).billingStatus
                        : actionType === 'Procedure' && (a as IChartProcedure).stage === 'Final'
                        ? ProcedureBillingStatus.Billable
                        : actionType === 'Procedure' && (a as IChartProcedure).stage != 'Final'
                        ? ProcedureBillingStatus.NotBillable
                        : undefined,
                stage: (a as IChartProcedure).stage ? (a as IChartProcedure).stage : undefined,
            };
        });
        this.setItems(list);
    }
}
