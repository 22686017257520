import { createSlice, Dictionary, PayloadAction } from '@reduxjs/toolkit';
import { IUserIdentity } from 'api/models/account.model';
import { ITenantUser } from 'api/models/tenant.model';
import { AxiosError } from 'axios';
import { LoadingStatus, LoadingStatuses } from 'interfaces/loading-statuses';

import { RootState } from 'state/store';
import { providerSettingReducers } from './providers/provider.reducers';
import { providerSettingsInitalState, ProviderSettingsState } from './providers/provider.state';
import usersReducers, { userExtraReducers } from './users/users.reducers';

const initialState: SettingsState = {
    expanded: false,
    groups: {
        loading: LoadingStatus.Idle,
        saving: LoadingStatus.Idle,
    },
    users: {
        data: {},
        accounts: [],
        loading: LoadingStatus.Idle,
        saving: LoadingStatus.Idle,
    },
    providers: providerSettingsInitalState,
    selectedPage: 'manage-locationsOfCare',
};

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setSettingsExpanded(state, action: PayloadAction<boolean>) {
            state.expanded = action.payload;
        },
        setSelectedPage(state, action: PayloadAction<string>) {
            state.selectedPage = action.payload;
        },
        ...usersReducers,
        ...providerSettingReducers,
    },
    extraReducers: (builder) => {
        userExtraReducers(builder);
    },
});

const { actions, reducer } = settingsSlice;

export const {
    setSettingsExpanded,
    setSelectedPage,
    setSelectedUser,
    updateIdentityProperty,
    updateUserProperty,
    cleanupUsersState,
    addProviderSchedule,
    addRange,
    // removeRange,
    removeProviderSchedule,
    clearPeriods,
    setLOC,
    editTimetable,
    setInitialPeriods,
    setEndDate,
    setStartDate,
    openProviderModel,
    addPeriod,
    updateProviderPeriodProp,
    saveScheduleException,
    updateProviderExceptionProp,
    openExceptionModal,
    openEmergencyExceptionModal,

    addExceptionToPeriod,
    createNewExpections,
    editExpection,
    updateExpceptionOnPeriod,
    removeProviderException,
} = actions;

export const selectSettingsExpanded = (state: RootState) => state.settings.expanded;
export const selectSelectedPage = (state: RootState) => state.settings.selectedPage;

export default reducer;

export type SettingsState = {
    expanded: boolean;
    groups: {
        loading: LoadingStatuses;
        saving: LoadingStatuses;
        error?: AxiosError;
    };
    users: {
        selectedUser?: ITenantUser;
        data: Dictionary<ITenantUser>;
        accounts: IUserIdentity[];
        loading: LoadingStatuses;
        saving: LoadingStatuses;
        error?: AxiosError;
    };
    providers: ProviderSettingsState;
    selectedPage: string;
};
