import { Dropdown, IDropdownOption, Stack, TextField } from '@fluentui/react';
import { IClinicalMeasureSummaryReportProperties } from 'api/models/clinical-measure-detail.model';
import { ReportType } from 'api/models/embed-report.model';
import { useTenantId } from 'hooks';
import { getValidationError } from 'hooks/useValidation';
import ReportWrapper from 'pages/Reporting/ReportWrapper';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import reportActionLookup from 'state/slices/reporting/reportActionLookup';
import { selectReportingValidationErrors, selectReportProperties } from 'state/slices/reporting/reporting.selectors';
import { setClinicalMeasureSummaryProp } from 'state/slices/reporting/reporting.slice';

export default function ClinicalMeasureSummaryReport() {
    const query = useSelector(selectReportProperties) as IClinicalMeasureSummaryReportProperties;

    const dispatch = useDispatch();

    const tenantId = useTenantId();

    const errors = useSelector(selectReportingValidationErrors);

    const _onViewReport = () => {
        if (query) dispatch(reportActionLookup[ReportType.ClinicalMeasureSummary]({ tenantId, ...query }));
    };

    const groupingOptions: IDropdownOption[] = [
        { key: 'Legal Sex', text: 'Legal Sex' },
        { key: 'Gender Identity', text: 'Gender Identity' },
        { key: 'Sexual Orientation', text: 'Sexual Orientation' },
        { key: 'Ethnicity', text: 'Ethnicity' },
        { key: 'Race', text: 'Race' },
    ];

    const measureYearOptions: IDropdownOption[] = [
        { key: '2020', text: '2020' },
        { key: '2021', text: '2021' },
        { key: '2022', text: '2022' },
        { key: '2023', text: '2023' },
    ];

    return (
        <ReportWrapper
            validationConfig={[{ fieldName: 'Measure Year', validation: ['required'], value: query?.measureYear }]}
            onViewReport={_onViewReport}
        >
            <Stack grow horizontal wrap tokens={{ childrenGap: 10 }}>
                <Stack.Item grow>
                    <Dropdown
                        label="Measure Year"
                        required
                        options={measureYearOptions}
                        selectedKey={query?.measureYear}
                        placeholder="(Select)"
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(
                                    setClinicalMeasureSummaryProp({
                                        path: 'measureYear',
                                        value: option.key as string,
                                    }),
                                );
                        }}
                        errorMessage={getValidationError(errors, 'Measure Year') ? 'Measure Year is required.' : undefined}
                    />
                </Stack.Item>
                <Stack.Item grow>
                    <Dropdown
                        label="Grouping"
                        options={groupingOptions}
                        selectedKey={query?.groupCol}
                        placeholder="(Select)"
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(
                                    setClinicalMeasureSummaryProp({
                                        path: 'groupCol',
                                        value: option.key as string,
                                    }),
                                );
                        }}
                    />
                </Stack.Item>
            </Stack>
        </ReportWrapper>
    );
}
