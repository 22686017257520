import { useDispatch } from 'react-redux';
import TXPlanPhase1Base from './TXPlanPhase1Base';
import { TreatmentPlanPhaseOneBaseProperties, TreatmentPlanPhaseOneSummaryProperties } from 'api/models/tx-plan-phase-1.model';
import { useSelector, useTenantId } from 'hooks';
import { selectReportProperties } from 'state/slices/reporting/reporting.selectors';
import reportActionLookup from 'state/slices/reporting/reportActionLookup';
import { ReportType } from 'api/models/embed-report.model';
import { IDropdownOption, Stack, Dropdown } from '@fluentui/react';
import { setTreatmentPlanPhase1DetailProp } from 'state/slices/reporting/reporting.slice';

const groupingOptions: IDropdownOption[] = [
    { key: '', text: '(Select)' },
    { key: 'Ethnicity', text: 'Ethnicity' },
    { key: 'Gender Identity', text: 'Gender Identity' },
    { key: 'Legal Sex', text: 'Legal Sex' },
    { key: 'Location', text: 'Location' },
    { key: 'Provider', text: 'Provider' },
    { key: 'Race', text: 'Race' },
    { key: 'Sexual Orientation', text: 'Sexual Orientation' },
];

export default function TXPlanPhase1Summary() {
    const tenantId = useTenantId();
    const dispatch = useDispatch();

    const query = useSelector(selectReportProperties) as TreatmentPlanPhaseOneSummaryProperties;

    const onViewReport = () => {
        if (query) dispatch(reportActionLookup[ReportType.TreatmentPlanPhaseOneSummary]({ tenantId, ...query }));
    };

    return (
        <TXPlanPhase1Base onViewReport={onViewReport}>
            <Stack.Item grow>
                <Dropdown
                    label="Group Column 1"
                    options={groupingOptions}
                    selectedKey={query?.groupCol}
                    placeholder="(Select)"
                    onChange={(ev, option) => {
                        if (option?.key !== undefined)
                            dispatch(
                                setTreatmentPlanPhase1DetailProp({
                                    path: 'groupCol',
                                    value: option.key as string,
                                }),
                            );
                    }}
                />
            </Stack.Item>
            <Stack.Item grow>
                <Dropdown
                    label="Group Column 2"
                    options={groupingOptions}
                    selectedKey={query?.groupCol2}
                    placeholder="(Select)"
                    onChange={(ev, option) => {
                        if (option?.key !== undefined)
                            dispatch(
                                setTreatmentPlanPhase1DetailProp({
                                    path: 'groupCol2',
                                    value: option.key as string,
                                }),
                            );
                    }}
                />
            </Stack.Item>
        </TXPlanPhase1Base>
    );
}
