import { DefaultButton, IStackTokens, Stack } from '@fluentui/react';
import { ToothArea } from 'api/models/tooth-area';
import { useCallback, useEffect } from 'react';

const tokens: IStackTokens = {
    childrenGap: 10,
};

type Props = {
    selectedAreas: (keyof typeof ToothArea)[];
    onChange?: (selectedAreas: (keyof typeof ToothArea)[]) => void;
    multiselect?: boolean;
};

export default function GeneralAreaSelector({ selectedAreas, multiselect = true, onChange }: Props) {
    useEffect(() => {
        if (onChange) onChange(selectedAreas);
    }, []);

    const getSelectedAreaIndex = useCallback(
        (selectedArea: ToothArea) => {
            return selectedAreas.findIndex((area) => area === selectedArea);
        },
        [selectedAreas],
    );

    const toggleArea = (area: ToothArea) => {
        if (onChange) {
            let newSelectedAreas = multiselect ? [...selectedAreas] : [area];
            if (multiselect) {
                const indexOfSelectedArea = getSelectedAreaIndex(area);
                if (indexOfSelectedArea > -1) {
                    newSelectedAreas = [
                        ...newSelectedAreas.slice(0, indexOfSelectedArea),
                        ...newSelectedAreas.slice(indexOfSelectedArea + 1),
                    ];
                } else {
                    newSelectedAreas.push(area);
                }
            }
            onChange(newSelectedAreas);
        }
    };

    const deselectTeeth = () => {
        if (onChange) onChange([]);
    };

    return (
        <Stack tokens={tokens} verticalAlign="center" horizontalAlign="center" horizontal grow>
            <Stack tokens={tokens}>
                <DefaultButton
                    text="UA"
                    onClick={() => toggleArea(ToothArea.UA)}
                    primary={getSelectedAreaIndex(ToothArea.UA) > -1}
                />
                <DefaultButton
                    text="LA"
                    onClick={() => toggleArea(ToothArea.LA)}
                    primary={getSelectedAreaIndex(ToothArea.LA) > -1}
                />
            </Stack>
            <DefaultButton text="Deselect All" onClick={deselectTeeth} />
        </Stack>
    );
}
