import { MessageBar, Stack } from '@fluentui/react';
import { usePatientId, useSelector, useTenantId } from 'hooks';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getAllPatientCommunications } from 'state/slices/communication/communication.actions';
import { cleanupCommunications } from 'state/slices/communication/communication.slice';
import { selectEditPatienIsNewPatient, selectEditPatient } from 'state/slices/edit-patient/edit-patient.selectors';
import CommunicationNotes from './components/CommunicationNotes';
import EditCommunication from './components/EditCommunication';

function Communication(): JSX.Element {
    const isNewPatient = useSelector(selectEditPatienIsNewPatient);
    const patient = useSelector(selectEditPatient);
    const patientId = patient?.id;

    const tenantId = useTenantId();

    const dispatch = useDispatch();

    useEffect(() => {
        if (tenantId && patientId) dispatch(getAllPatientCommunications({ patientId, tenantId }));
        return () => {
            dispatch(cleanupCommunications({}));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientId, tenantId]);

    return (
        <Stack tokens={{ childrenGap: 10 }}>
            {isNewPatient && <MessageBar>Save new patient before entering communications.</MessageBar>}
            <EditCommunication />
            <CommunicationNotes />
        </Stack>
    );
}

export default Communication;
