import { PayloadAction } from '@reduxjs/toolkit';
import IPatient from 'api/models/patient.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { SyncStatus } from 'interfaces/syncing-statuses';
import { ExtraReducer, ReducerObject } from 'state/store';
import { updatePatientEncounter } from '../encounter/encounter.actions';
import { fetchPatient, autoUpdatePatient, getPatients, getPastEncounters, fetchPatientOverview } from './patient.actions';
import { PatientState } from './patient.state';

export const patientReducers = {
    clearPatients(state: PatientState) {
        state.patients.data = [];
        state.patients.loading = LoadingStatus.Idle;
    },
    setSelectedPatient: (state: PatientState, action: PayloadAction<IPatient | undefined>) => {
        state.selectedPatient = action.payload;
    },
    clearSelectedPatient: (state: PatientState) => {
        state.selectedPatient = undefined;
        state.loading = LoadingStatus.Idle;
    },
    setFindPatientOpen: (state: PatientState, action: PayloadAction<boolean>) => {
        const isOpen = action.payload;
        state.findPatientOpen = isOpen;
    },
    setPatientNote: (state: PatientState, action: PayloadAction<string | undefined>) => {
        const note = action.payload;
        if (state.selectedPatient)
            if (state.selectedPatient.note) {
                state.selectedPatient.note.note = note;
            } else {
                state.selectedPatient.note = { note };
            }
    },
    setPatientMrn: (state: PatientState, action: PayloadAction<string>) => {
        if (state.selectedPatient) state.selectedPatient['mrn'] = action.payload;
    },
    setLoadingCreateEncounter: (
        state: PatientState,
        action: PayloadAction<{ appointmentId: string; loadingStatus: LoadingStatus }>,
    ) => {
        state.encounters.createNewEncounterLoading[action.payload.appointmentId] = action.payload.loadingStatus;
    },
};

export const patientExtraReducers: ExtraReducer<PatientState> = (builder) =>
    builder
        .addCase(fetchPatient.pending, (state) => {
            state.loading = LoadingStatus.Pending;
            state.problems.loading = LoadingStatus.Pending;
            state.allergies.loading = LoadingStatus.Pending;
            state.medications.loading = LoadingStatus.Pending;
            state.immunizations.loading = LoadingStatus.Pending;
            state.encounters.loading = LoadingStatus.Pending;
            state.vitals.loading = LoadingStatus.Pending;
        })
        .addCase(fetchPatient.fulfilled, (state, action) => {
            const {
                patient,
                patientProblems: problems,
                allergies,
                medications,
                vitals,
                immunizations,
                appointments,
                encounters,
                communication,
            } = action.payload;
            state.loading = LoadingStatus.Completed;
            state.selectedPatient = patient;
            state.problems.loading = LoadingStatus.Completed;
            state.problems.data = problems;
            state.allergies.loading = LoadingStatus.Completed;
            state.allergies.data = allergies;
            state.medications.loading = LoadingStatus.Completed;
            state.medications.data = medications;
            state.immunizations.loading = LoadingStatus.Completed;
            state.immunizations.data = immunizations;
            state.vitals.loading = LoadingStatus.Completed;
            state.vitals.data = vitals;
            state.currentAndFutureAppointments = appointments;
            state.encounters.data = encounters;
            state.encounters.loading = LoadingStatus.Completed;
        })
        .addCase(fetchPatient.rejected, (state) => {
            state.loading = LoadingStatus.Failed;
            // state.editPatient.loading = LoadingStatus.Failed;
            state.problems.loading = LoadingStatus.Failed;
            state.allergies.loading = LoadingStatus.Failed;
            state.vitals.loading = LoadingStatus.Failed;
            state.medications.loading = LoadingStatus.Failed;
            state.immunizations.loading = LoadingStatus.Failed;
            state.encounters.loading = LoadingStatus.Failed;
        })
        .addCase(fetchPatientOverview.pending, (state) => {
            state.loading = LoadingStatus.Pending;
            state.problems.loading = LoadingStatus.Pending;
            state.allergies.loading = LoadingStatus.Pending;
            state.medications.loading = LoadingStatus.Pending;
            state.immunizations.loading = LoadingStatus.Pending;
            state.encounters.loading = LoadingStatus.Pending;
            state.vitals.loading = LoadingStatus.Pending;
        })
        .addCase(fetchPatientOverview.fulfilled, (state, action) => {
            const { patientProblems, allergies, medications, vitals, immunizations, appointments, encounters } = action.payload;
            state.loading = LoadingStatus.Completed;
            state.problems.loading = LoadingStatus.Completed;
            state.problems.data = patientProblems;
            state.allergies.loading = LoadingStatus.Completed;
            state.allergies.data = allergies;
            state.medications.loading = LoadingStatus.Completed;
            state.medications.data = medications;
            state.immunizations.loading = LoadingStatus.Completed;
            state.immunizations.data = immunizations;
            state.vitals.loading = LoadingStatus.Completed;
            state.vitals.data = vitals;
            state.currentAndFutureAppointments = appointments;
            state.encounters.data = encounters;
            state.encounters.loading = LoadingStatus.Completed;
        })
        .addCase(fetchPatientOverview.rejected, (state) => {
            state.loading = LoadingStatus.Failed;
            // state.editPatient.loading = LoadingStatus.Failed;
            state.problems.loading = LoadingStatus.Failed;
            state.allergies.loading = LoadingStatus.Failed;
            state.vitals.loading = LoadingStatus.Failed;
            state.medications.loading = LoadingStatus.Failed;
            state.immunizations.loading = LoadingStatus.Failed;
            state.encounters.loading = LoadingStatus.Failed;
        })
        .addCase(autoUpdatePatient.pending, (state) => {
            state.saving = SyncStatus.Pending;
        })
        .addCase(autoUpdatePatient.fulfilled, (state, action) => {
            state.saving = SyncStatus.Saved;
            if (state.selectedPatient) state.selectedPatient._etag = action.payload._etag;
        })
        .addCase(autoUpdatePatient.rejected, (state) => {
            state.saving = SyncStatus.Failed;
        })
        // [GET] Patients
        .addCase(getPatients.pending, (state) => {
            state.patients.loading = LoadingStatus.Pending;
        })
        .addCase(getPatients.fulfilled, (state, action) => {
            state.patients.loading = LoadingStatus.Completed;
            state.patients.data = action.payload.data;
        })
        .addCase(getPatients.rejected, (state) => {
            state.patients.loading = LoadingStatus.Failed;
        })
        .addCase(updatePatientEncounter.fulfilled, (state, action) => {
            const updatedEncounter = action.payload;
            if (state.encounters.data?.find((res) => res.id === updatedEncounter.id)) {
                state.encounters.data?.map((res) =>
                    res.id === updatedEncounter.id ? (res.encounterReason = updatedEncounter.encounterReason) : null,
                );
            } else {
                state.encounters.data?.concat(updatedEncounter);
            }
        })
        .addCase(getPastEncounters.pending, (state) => {
            state.pastEncounters.loading = LoadingStatus.Pending;
        })
        .addCase(getPastEncounters.fulfilled, (state, action) => {
            state.pastEncounters.loading = LoadingStatus.Completed;
            state.pastEncounters.data = action.payload;
        })
        .addCase(getPastEncounters.rejected, (state) => {
            state.pastEncounters.loading = LoadingStatus.Failed;
        });
