import { ComboBox, Stack, TextField } from '@fluentui/react';
import { IAnnualIncome, IPatientUDS, IPovertyPercentage } from 'api/models/patient.model';
import Field from 'components/Field/Field';
import { HouseholdIncomeFields } from 'components/PatientDemographics/Finance';
import { useTenantId } from 'hooks';
import { getValidationError } from 'hooks/useValidation';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAnnualIncome, getPovertyPercentage } from 'state/slices/edit-patient/edit-patient.actions';
import { selectEditPatient, selectEditPatientValidationErrors } from 'state/slices/edit-patient/edit-patient.selectors';
import { editPatientUDSPropChange } from 'state/slices/edit-patient/edit-patient.slice';
import { payPeriodOptionsList } from 'state/slices/tenant/payPeriod.slice';

import { usdCurrencyFormatter } from 'utils';

function PatientIncomePercent() {
    const patient = useSelector(selectEditPatient);
    const validationErrors = useSelector(selectEditPatientValidationErrors);
    const tenantId = useTenantId();
    const payPeriodsList = useSelector(payPeriodOptionsList);

    const dispatch = useDispatch();

    function handlePercentOfPoverty(familySize: number, income: number) {
        const model: IPovertyPercentage = {
            personCount: familySize ?? 0,
            totalIncome: income,
        };
        dispatch(getPovertyPercentage({ model, tenantId }));
    }

    function handleAnnualIncome(income: number, payPeriod: string) {
        const model: IAnnualIncome = {
            income: income,
            payPeriod: payPeriod,
        };
        dispatch(getAnnualIncome({ model, tenantId }));
    }
    const _updatePatientUDSProps = (key: keyof IPatientUDS, value: string | number | boolean | string[] | undefined) => {
        dispatch(editPatientUDSPropChange({ key, value }));
    };

    const validNumberInput = (value?: string): boolean => {
        const res = value ? /^[1-9][0-9]*$/.test(value) : true;
        return res;
    };

    useEffect(() => {
        if (patient?.uds?.familySize !== undefined && patient?.uds.annualIncome !== undefined)
            handlePercentOfPoverty(patient?.uds.familySize, patient?.uds.annualIncome);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patient?.uds?.familySize, patient?.uds?.annualIncome]);

    useEffect(() => {
        if (patient?.uds?.income !== undefined && patient?.uds?.incomePayPeriod !== undefined)
            handleAnnualIncome(patient?.uds.income, patient?.uds.incomePayPeriod);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patient?.uds?.income, patient?.uds?.incomePayPeriod]);

    const fieldsRequired = patient?.uds?.slidingFeeIncomeStatus === 'Slide';

    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <Stack horizontal tokens={{ childrenGap: 5 }}></Stack>
            <Stack tokens={{ childrenGap: 10 }} verticalAlign="start" grow horizontal wrap>
                <Stack.Item>
                    <TextField
                        label="Family Size"
                        placeholder="Enter family size"
                        value={patient?.uds?.familySize !== undefined ? patient?.uds?.familySize?.toString() : ''}
                        autoComplete="off"
                        maxLength={2}
                        style={{ width: '125px' }}
                        required={fieldsRequired}
                        onChange={(ev, value) => {
                            if (validNumberInput(value)) {
                                _updatePatientUDSProps(
                                    'familySize',
                                    value !== undefined && value !== '' ? parseInt(value) : undefined,
                                );
                                _updatePatientUDSProps('familySizeLastUpdated', new Date().toISOString());
                            }
                        }}
                        errorMessage={
                            getValidationError(validationErrors, HouseholdIncomeFields.FamilySize)
                                ? 'Family Size is required.'
                                : undefined
                        }
                    />
                </Stack.Item>
                <Stack.Item grow>
                    <Field.Currency
                        size={3}
                        label="Income"
                        placeholder="Enter income"
                        value={patient?.uds?.income}
                        prefix="$"
                        autoComplete="off"
                        onChange={(ev, value) => {
                            _updatePatientUDSProps('income', value);
                            _updatePatientUDSProps('incomeLastUpdated', new Date().toISOString());
                        }}
                    />
                </Stack.Item>
                <Stack.Item>
                    <ComboBox
                        label="Pay Period"
                        placeholder="Select Pay Period"
                        allowFreeform
                        options={payPeriodsList}
                        selectedKey={patient?.uds?.incomePayPeriod ?? ''}
                        onChange={(ev, value) => {
                            _updatePatientUDSProps('incomePayPeriod', value?.key);
                        }}
                    />
                </Stack.Item>

                <Stack.Item>
                    <TextField
                        prefix="%"
                        label="% of Poverty"
                        value={patient?.uds?.incomePercentage?.toString() ?? ''}
                        disabled
                    />
                </Stack.Item>
                <Stack.Item>
                    <TextField
                        label="Annual Income"
                        value={usdCurrencyFormatter.format(patient?.uds?.annualIncome ?? 0)}
                        disabled
                    />
                </Stack.Item>
            </Stack>
        </Stack>
    );
}
export default PatientIncomePercent;
