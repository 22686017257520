import {
    DefaultButton,
    getTheme,
    IButtonStyles,
    IconButton,
    mergeStyleSets,
    Modal,
    Stack,
    Text,
} from '@fluentui/react';
import { useSelector } from 'hooks';
import { useDispatch } from 'react-redux';
import {
    cleanupPatientContactsError,
    openContactModal,
    selectContact,
    setContact,
    setOpenContactModel,
} from 'state/slices/contacts/contacts.slice';
import ContactFields from './ContactFields';

function ContactModal(): JSX.Element {
    const theme = getTheme();
    const dispatch = useDispatch();
    const isOpen = useSelector(openContactModal);
    const contact = useSelector(selectContact);

    const iconButtonStyles: Partial<IButtonStyles> = {
        root: {
            color: theme.palette.magenta,
            marginLeft: 'auto',
            marginTop: '4px',
            marginRight: '2px',
        },
        rootHovered: {
            color: theme.palette.neutralDark,
        },
    };

    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            theme.fonts.xLarge,
            {
                flex: '1 1 auto',
                flexDirection: 'row',
                borderTop: `4px solid ${theme.palette.themePrimary}`,
                color: theme.palette.neutralPrimary,
                display: 'flex',
                alignItems: 'center',
                fontWeight: 600,
                padding: '12px 12px 12px 24px',
            },
        ],
        body: {
            flex: '4 4 auto',
            padding: '0 24px',
            overflowY: 'hidden',
        },
    });

    const onDismissed = () => {
        dispatch(setContact(undefined));
        dispatch(cleanupPatientContactsError());
    };

    return (
        <Modal
            isOpen={isOpen}
            containerClassName={contentStyles.container}
            onDismiss={() => dispatch(setOpenContactModel(false))}
            onDismissed={onDismissed}
            isBlocking={true}
        >
            <Stack className={contentStyles.header}>
                <Text variant="xLarge">{`${!contact?.id ? 'Add' : 'View'} Patient Contact`}</Text>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={{ iconName: 'Cancel' }}
                    text="Close"
                    onClick={() => dispatch(setOpenContactModel(false))}
                />
            </Stack>
            <Stack tokens={{ childrenGap: 10, padding: 20 }}>
                <ContactFields />
            </Stack>
            <Stack tokens={{ childrenGap: 10 }} style={{ padding: 24 }}>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <DefaultButton
                        text="Close"
                        onClick={() => {
                            dispatch(setOpenContactModel(false));
                        }}
                    />
                </Stack>
            </Stack>
        </Modal>
    );
}

export default ContactModal;
