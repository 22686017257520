import { IContextualMenuItem, IContextualMenuProps, PanelType, Stack, TooltipHost } from '@fluentui/react';
import { useQuickActions, useTenant } from 'hooks';
import { chunk, filter, map, orderBy } from 'lodash';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { useBoolean } from '@uifabric/react-hooks';
import AreaPanel from './AreaPanel';
import { handleProcedureMenuClick } from 'state/slices/charting/procedure-panel/procedure-panel.actions';
import { useDispatch } from 'react-redux';
import { chartingActions } from 'state/slices/charting/chart/chart.slice';

function ProcedureMenu(): JSX.Element {
    const dispatch = useDispatch();
    const { quickActionCategories, quickActionItems } = useQuickActions();
    const { getProceduresData } = useTenant();

    const [isAreaOpen, { setFalse: closeAreaPanel, toggle: toggleAreaOpen }] = useBoolean(false);

    const _handleQuickActionClick = (quickActionId?: string) => {
        if (quickActionId) dispatch(handleProcedureMenuClick(quickActionId));
    };

    const _openProcedureBrowser = () => dispatch(chartingActions.setProcedureBrowserOpen(true));

    const procedureButtons = map(
        orderBy(
            filter(quickActionCategories, (cat) => !cat.isDeleted),
            ['order'],
            ['asc'],
        ),
        (cat) => {
            const quickActions = filter(quickActionItems?.actions, (item) => item?.categoryId === cat.id && !item?.isDeleted);
            const subItems: IContextualMenuProps = {
                items: quickActions.map(
                    (action) =>
                        ({
                            key: action?.id,
                            text:
                                action && action?.procedures.length > 1
                                    ? action?.displayName
                                    : `${action ? getProceduresData[action.procedures[0].id]?.code : ''} - ${
                                          action?.displayName
                                      }`,
                            onClick: () => _handleQuickActionClick(action?.id),
                        } as IContextualMenuItem),
                ),
            };
            let mainButton;
            if (subItems.items.length) {
                mainButton = (
                    <DefaultButton
                        split={subItems.items.length ? true : false}
                        onClick={() => _handleQuickActionClick(cat.defaultQuickActionId)}
                        text={cat.displayName}
                        key={cat.id}
                        menuProps={subItems ? subItems : undefined}
                        styles={{ root: { width: 100 } }}
                    />
                );
            } else {
                mainButton = (
                    <DefaultButton
                        styles={{ root: { width: 100 } }}
                        onClick={() => _handleQuickActionClick(cat.defaultQuickActionId)}
                        text={cat.displayName}
                        key={cat.id}
                    />
                );
            }

            return <TooltipHost content={cat.description}>{mainButton}</TooltipHost>;
        },
    );
    const procedureStacks =
        procedureButtons && procedureButtons.length
            ? chunk(procedureButtons, 2).map((stack, index) => {
                  const buttons = stack.map((button, index) => (
                      <Stack grow={1} key={`proc-button-${index}`} styles={{ root: { flexBasis: 0 } }}>
                          {button}
                      </Stack>
                  ));
                  return (
                      <Stack tokens={{ childrenGap: 12 }} key={`button-wrapper-${index}`} horizontal grow horizontalAlign="start">
                          {buttons}
                      </Stack>
                  );
              })
            : null;
    return (
        <>
            <Stack tokens={{ childrenGap: 12 }}>
                <Stack horizontal wrap tokens={{ childrenGap: 12 }}>
                    {procedureStacks}
                </Stack>
                <DefaultButton text="Procedure List" iconProps={{ iconName: 'List' }} onClick={_openProcedureBrowser} />
            </Stack>
            <AreaPanel
                panel={{
                    isOpen: isAreaOpen,
                    type: PanelType.medium,
                    onDismiss: toggleAreaOpen,
                    headerText: 'Add area based procedure',
                }}
                closePanel={closeAreaPanel}
            />
        </>
    );
}

export default ProcedureMenu;
