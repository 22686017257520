import {
    DirectionalHint,
    Icon,
    IconButton,
    mergeStyles,
    MessageBar,
    Separator,
    Stack,
    TooltipHost,
    useTheme,
} from '@fluentui/react';
import { ChartProcedureDiagnosis } from 'api/models/chart.model';
import { ApplicableArea } from 'api/models/lookup.model';
import { IProcedureDiagnosis, ProcedureRenderRule } from 'api/models/procedure.model';
import { ToothArea } from 'api/models/tooth-area';
import { Section } from 'components';
import { useSelector } from 'hooks';
import { map } from 'lodash';
import { IProcedure } from 'models/procedure';
import { ToothLabel, SurfaceControls, DiagnosisSelector } from 'pages/components';
import PanelSectionHeader from 'pages/components/PanelSectionHeader';
import { forwardRef, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { chartingActions } from 'state/slices/charting/chart/chart.slice';
import {
    createsManyProcedures,
    createsProceduresWithCombinedToothIds,
    hasGeneralApplicableArea,
    doesNotHaveApplicableArea,
    createsManyProceduresPerArea,
} from 'state/slices/charting/chartingProcedures.pipeline';
import {
    handleSetSelectedTeeth,
    onUpdateProcedurePanelSurfaces,
} from 'state/slices/charting/procedure-panel/procedure-panel.actions';
import {
    selectOpenProcedureSections,
    selectProcedurePanel,
    selectProcedurePanelConflicts,
} from 'state/slices/charting/procedure-panel/procedure-panel.selectors';
import { selectTenant } from 'state/slices/tenant';
import { selectProceduresData } from 'state/slices/tenant/procedures.slice';
import DiagnosisDropdown from './DiagnosisDropdown';
import { ConflictMessages } from './ProcedurePanel';
import ToothSelector from './ToothSelector';
import GeneralAreaSelector from './GeneralAreaSelector';

type ProcedureSectionProps = {
    children: React.ReactNode;
    procedure: IProcedure | undefined;
};

export const ProcedureSection = forwardRef<HTMLDivElement, ProcedureSectionProps>(
    ({ children, procedure }, ref): JSX.Element | null => {
        const dispatch = useDispatch();
        const _openProcedureSections = useSelector(selectOpenProcedureSections);
        const { selectedProceduresTeeth } = useSelector(selectProcedurePanel);
        const selectedTeeth = procedure?.id ? selectedProceduresTeeth[procedure.id] ?? [] : [];
        const toggleIsOpen = () => {
            if (procedure) dispatch(chartingActions.toggleOpenProcedureSection(procedure.id));
        };
        const isOpen = procedure ? _openProcedureSections.includes(procedure.id) : false;
        const targetRef = useRef<HTMLDivElement>(null);
        const { palette } = useTheme();

        const { procedureErrors } = useSelector(selectProcedurePanelConflicts);
        const procedureSectionErrorsList = procedure ? procedureErrors[procedure.id] ?? [] : [];

        if (!procedure) return null;

        const setTeeth = (teeth: number[]) => {
            dispatch(handleSetSelectedTeeth(teeth, procedure.id));
        };

        const sectionClass = mergeStyles({
            height: isOpen ? 'auto' : 0,
            transition: 'height 100ms ease-in',
            overflow: 'hidden',
        });

        return (
            <div id={procedure.id}>
                <PanelSectionHeader
                    ref={ref}
                    isPrimary
                    onClick={toggleIsOpen}
                    style={{ cursor: 'pointer' }}
                    rightContent={
                        <Stack tokens={{ childrenGap: 5 }} horizontal verticalAlign="center" grow>
                            {selectedTeeth.length ? (
                                <TooltipHost content="Deselect all teeth for this procedure.">
                                    <IconButton
                                        iconProps={{ iconName: 'Clear' }}
                                        styles={{ root: { height: 26, width: 26 } }}
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            ev.stopPropagation();
                                            setTeeth([]);
                                        }}
                                    />
                                </TooltipHost>
                            ) : null}
                            {procedureSectionErrorsList.length && !isOpen ? (
                                <Stack.Item grow>
                                    <TooltipHost
                                        content={
                                            <div>
                                                {procedureSectionErrorsList.map((error, index) => (
                                                    <span key={index}>- {error.message}</span>
                                                ))}
                                            </div>
                                        }
                                    >
                                        <Icon
                                            style={{ color: palette.red, fontSize: 16, paddingTop: 5, cursor: 'pointer' }}
                                            iconName="Warning12"
                                        />
                                    </TooltipHost>
                                </Stack.Item>
                            ) : null}
                            <Icon
                                style={{ color: palette.themePrimary, paddingTop: 7, paddingBottom: 6 }}
                                iconName={isOpen ? 'ChevronUp' : 'ChevronDown'}
                            />
                        </Stack>
                    }
                    text={`${procedure?.code ?? ''} - ${procedure?.displayName ?? ''}`}
                />
                {procedureSectionErrorsList.length && isOpen ? <ConflictMessages errors={procedureSectionErrorsList} /> : null}
                <Stack className={sectionClass}>
                    <div ref={targetRef}>
                        <Section>{children}</Section>
                    </div>
                </Stack>
            </div>
        );
    },
);
ProcedureSection.displayName = 'ProcedureSection';

type PanelProcedureProps = {
    procedureId?: string;
};

const PanelProcedure = forwardRef<HTMLDivElement, PanelProcedureProps>(({ procedureId }, ref): JSX.Element | null => {
    const dispatch = useDispatch();
    const proceduresData = useSelector(selectProceduresData);
    const originalProcedure = procedureId ? proceduresData[procedureId] : undefined;

    const { diagnoses } = useSelector(selectTenant);

    const { panelTeethData, isEditing, selectedProceduresTeeth, procedures } = useSelector(selectProcedurePanel);

    if (!originalProcedure) return null;

    const selectedTeeth = procedureId ? selectedProceduresTeeth[procedureId] ?? [] : [];

    const panelProcedure = procedureId ? panelTeethData[procedureId] : undefined;

    const setSurfaces = (tooth: number | string, surfaces: (keyof typeof ToothArea)[]) => {
        if (procedureId) dispatch(onUpdateProcedurePanelSurfaces(surfaces, procedureId, tooth));
    };

    const setDiagnosisCodes = (toothOrArea?: number | string, dx?: ChartProcedureDiagnosis[]) => {
        const newDx = dx ? dx : [];
        if (procedureId)
            dispatch(chartingActions.setProcPanelDiagnosisCodes({ dx: newDx, procedureId, toothOrArea: toothOrArea }));
    };

    const nonSuggestedGeneralProcedureDiagnoses: IProcedureDiagnosis[] = (
        panelProcedure?.generalDiagnosisCodes?.map((dx) => ({
            code: diagnoses.data[dx.id]?.code ?? '',
        })) ?? []
    ).filter((dx) => originalProcedure?.diagnoses?.findIndex((d) => d.code === dx.code) === -1);

    const setTeeth = (teeth: number[]) => {
        dispatch(handleSetSelectedTeeth(teeth, procedureId));
    };

    const panelProcedureDataItems = map(panelProcedure?.data, (p, toothOrArea) => ({
        ...p,
        tooth: !isNaN(+toothOrArea) ? +toothOrArea : undefined,
        area: isNaN(+toothOrArea) ? toothOrArea : undefined,
    }));

    const needsSurfaceSelector = originalProcedure?.applicableArea === ApplicableArea.Surface;

    const needsToothSelector =
        createsManyProcedures(originalProcedure) || createsProceduresWithCombinedToothIds(originalProcedure);

    const needsGeneralAreaSelector = createsManyProceduresPerArea(originalProcedure);
    const hasExtraction = originalProcedure.renderRule === ProcedureRenderRule.Extraction;

    const teethSurfacesAndDiagnoses = panelProcedureDataItems.map((data, index) => {
        const dataProp = data.tooth ?? data.area;
        const currentProcedure = data.procedure;
        const teethData = data.groupedTeeth ? data.groupedTeeth : dataProp ?? data.area;
        const suggestedDx = currentProcedure?.diagnoses ?? [];
        const nonSuggestedProcedureDiagnoses: IProcedureDiagnosis[] = (
            data?.diagnosisCodes?.map((dx) => ({
                code: diagnoses.data[dx.id]?.code ?? '',
            })) ?? []
        ).filter((dx) => suggestedDx.findIndex((d) => d.code === dx.code) === -1);
        const maxDiagnosesAssociated = data?.diagnosisCodes?.length ?? 0;

        return (
            <Stack tokens={{ childrenGap: 5 }} key={index} grow>
                {currentProcedure?.id !== originalProcedure.id && (
                    <MessageBar>
                        <TooltipHost content={`${currentProcedure?.code} - ${currentProcedure?.displayName}`}>
                            {currentProcedure?.code}
                        </TooltipHost>{' '}
                        will overwrite the original procedure{' '}
                        <TooltipHost content={`${originalProcedure.code} - ${originalProcedure.displayName}`}>
                            {originalProcedure.code}
                        </TooltipHost>
                    </MessageBar>
                )}
                <Stack tokens={{ childrenGap: 10 }} verticalAlign="center" horizontal grow>
                    <Stack.Item>{<ToothLabel items={teethData} />}</Stack.Item>
                    {needsSurfaceSelector && (
                        <SurfaceControls
                            surfaces={data?.surfaces ?? []}
                            setSurfaces={(surfaces) => {
                                if (dataProp) setSurfaces(dataProp, surfaces);
                            }}
                            toothId={dataProp}
                        />
                    )}
                    <Stack tokens={{ childrenGap: 5 }} style={{ maxWidth: 220 }}>
                        <Stack.Item>
                            {/* Suggested DX */}

                            <DiagnosisSelector
                                suggestionDiagnoses={[...suggestedDx, ...nonSuggestedProcedureDiagnoses]}
                                selectedDiagnoses={data?.diagnosisCodes}
                                updateDiagnoses={(ids) => setDiagnosisCodes(dataProp, ids)}
                                shouldAutoApply={!isEditing}
                            />
                        </Stack.Item>
                        {/* Extra DX */}
                        <>
                            {maxDiagnosesAssociated >= 4 ? (
                                <TooltipHost
                                    directionalHint={DirectionalHint.leftCenter}
                                    delay={0}
                                    content={`No more than 4 diagnoses may be associated to this procedure.`}
                                >
                                    <DiagnosisDropdown
                                        selectedDiagnoses={data?.diagnosisCodes}
                                        updateDiagnoses={(dx) => setDiagnosisCodes(dataProp, dx)}
                                        isDisabled={maxDiagnosesAssociated >= 4}
                                    />
                                </TooltipHost>
                            ) : (
                                <DiagnosisDropdown
                                    selectedDiagnoses={data?.diagnosisCodes}
                                    updateDiagnoses={(dx) => setDiagnosisCodes(dataProp, dx)}
                                />
                            )}
                        </>
                    </Stack>
                </Stack>
                {index + 1 !== panelProcedureDataItems.length && <Separator />}
            </Stack>
        );
    });

    const isGeneralProcedure = hasGeneralApplicableArea(originalProcedure) || doesNotHaveApplicableArea(originalProcedure);

    return (
        <ProcedureSection ref={ref} procedure={originalProcedure}>
            <Stack tokens={{ childrenGap: 10 }} grow>
                <Stack tokens={{ childrenGap: 10 }} grow>
                    {isGeneralProcedure ? (
                        <>
                            <Stack.Item>
                                <DiagnosisSelector
                                    suggestionDiagnoses={[
                                        ...(originalProcedure?.diagnoses ?? []),
                                        ...nonSuggestedGeneralProcedureDiagnoses,
                                    ]}
                                    selectedDiagnoses={panelProcedure?.generalDiagnosisCodes}
                                    updateDiagnoses={(ids) => setDiagnosisCodes(undefined, ids)}
                                    shouldAutoApply={false}
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <DiagnosisDropdown
                                    selectedDiagnoses={panelProcedure?.generalDiagnosisCodes}
                                    updateDiagnoses={(ids) => setDiagnosisCodes(undefined, ids)}
                                />
                            </Stack.Item>
                        </>
                    ) : null}
                </Stack>
                {needsToothSelector && procedures?.length > 1 && !isEditing && (
                    <ToothSelector
                        multiselect
                        canSelectSupernumerary={hasExtraction}
                        selectedTeeth={selectedTeeth}
                        onChange={setTeeth}
                    />
                )}
                {teethSurfacesAndDiagnoses}
                {!teethSurfacesAndDiagnoses.length && !isGeneralProcedure && (
                    <MessageBar>
                        No {needsSurfaceSelector ? 'teeth' : needsGeneralAreaSelector ? 'areas' : 'items'} are selected.
                    </MessageBar>
                )}
            </Stack>
        </ProcedureSection>
    );
});

PanelProcedure.displayName = 'PanelProcedure';

export default PanelProcedure;
