import Tooth from './Tooth';
import * as PIXI from 'pixi.js-legacy';
import ToothOverlay from './ToothOverlay';
import { IChartRenderProcedure } from 'api/models/tooth.model';

export default class AbutmentRetainerCrown extends ToothOverlay {
    private line1 = new PIXI.Graphics();
    private line2 = new PIXI.Graphics();

    constructor(tooth: Tooth) {
        super(tooth);
        this.name = 'AbutmentRetainerCrown';

        const supportedProcedures = [tooth.isAbutmentRetainerCrown()].filter(
            (item) => item !== undefined,
        ) as IChartRenderProcedure[];

        if (supportedProcedures[0]) {
            this._createGraphics(supportedProcedures[0], tooth);
        }
    }

    private _createGraphics(procedure: IChartRenderProcedure, tooth: Tooth) {
        this._setColor(procedure);

        if (tooth._frontContainer && tooth._surfacesContainer && tooth._backContainer) {
            this.tooth.addColorOverlayToToothPart(tooth._backContainer, procedure);
            this.tooth.addColorOverlayToToothPart(tooth._surfacesContainer, procedure);

            // tooth._frontContainer._rootSprite.alpha = 0;

            if (this.tooth.previousTooth?.isPontic() || this.tooth.nextTooth?.isPontic()) {
                const linePadding = 8;

                const surfacesX = tooth._surfacesContainer.x;
                const surfacesHeight = tooth._surfacesContainer.height;
                const surfacesWidth = tooth._surfacesContainer.width;
                const surfacesY = tooth._surfacesContainer.y;
                const surfacesTopY = surfacesY - linePadding;
                const surfacesBottomY = surfacesY + surfacesHeight + linePadding;

                this.line1.position.set(surfacesX, surfacesTopY);
                this.line1.lineStyle(8, this.color).lineTo(surfacesWidth, 0);

                this.line2.position.set(surfacesX, surfacesBottomY);
                this.line2.lineStyle(8, this.color).lineTo(surfacesWidth, 0);

                this.addChild(this.line1, this.line2);
            }
        }
    }
}
