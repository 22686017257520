import {
    ComboBox,
    DefaultButton,
    IComboBoxOption,
    IconButton,
    PrimaryButton,
    SelectionMode,
    Stack,
    Text,
    TooltipHost,
} from '@fluentui/react';
import { IProblemEvent, IPatientProblem } from 'api/models/patient-problem.model';
import { Field, SortableDetailsList, SubSection } from 'components';
import SearchComboField from 'components/Field/SearchComboField';
import { format } from 'date-fns';
import useOverview from 'hooks/store/useOverview';
import { map } from 'lodash';
import { useState } from 'react';
import { useGetProblemsQuery } from 'state/api/problems';
import { OverviewItem } from 'state/slices/patient/patient.state';

export default function ProblemsEditProperties(): JSX.Element {
    const { selectedOverviewItem, setSelectedOverviewItem } = useOverview();
    const isNewItem = selectedOverviewItem.isNewItem;
    const item: IPatientProblem =
        !isNewItem &&
        selectedOverviewItem &&
        selectedOverviewItem.item.problems &&
        selectedOverviewItem.item.problems.length &&
        selectedOverviewItem.item.problems.find((problem: IPatientProblem) => problem.problemId === selectedOverviewItem.key);
    const [showFields, setShowFields] = useState<boolean>(false);
    const [problemEvent, setProblemEvent] = useState<IProblemEvent | undefined>(undefined);

    const updateSelectedPatientOverview = (updatedItem: OverviewItem) => setSelectedOverviewItem(updatedItem);

    const statusOptions = [
        {
            key: 'CHRONIC',
            text: 'CHRONIC',
        },
        {
            key: 'OTHER',
            text: 'OTHER',
        },
    ];

    const typeOptions = [
        {
            key: 'START',
            text: 'START',
        },
        {
            key: 'OTHER',
            text: 'OTHER',
        },
    ];

    const filteredProblems: IPatientProblem[] =
        !isNewItem &&
        selectedOverviewItem &&
        selectedOverviewItem.item.problems?.filter((problem: IPatientProblem) => problem.problemId !== selectedOverviewItem.key);

    const setOnSetDate = (_key: unknown, value?: string) => {
        const date = value ? value : undefined;
        setProblemEvent({ ...problemEvent, onSetDate: date });
    };

    const getUpdatedProblem = (events: IProblemEvent[]) => ({
        ...item,
        events,
    });

    const getUpdatedItem = (problems: IPatientProblem[]) => ({
        ...selectedOverviewItem,
        item: {
            ...selectedOverviewItem.item,
            problems,
        },
    });

    const handleOnChange = (option: IComboBoxOption | undefined, problemEvent: IProblemEvent, key: keyof IProblemEvent) => {
        if (option && item?.events) {
            const insertIndex = item.events.indexOf(problemEvent);
            const updatedEvent: IProblemEvent = {
                ...problemEvent,
                [key]: option.key as string,
            };
            const events = item.events.filter((_value, i: number) => i !== insertIndex);
            if (updatedEvent) {
                events.splice(insertIndex, 0, updatedEvent);
            }
            const updatedProblem = getUpdatedProblem(events);
            const updatedItem = getUpdatedItem([...filteredProblems, updatedProblem]);
            updateSelectedPatientOverview(updatedItem);
        }
    };

    const { data, isLoading } = useGetProblemsQuery();
    const _options: IComboBoxOption[] = map(data, (problem) => {
        return {
            key: problem.snomedCode ? problem.snomedCode : '',
            text: problem.displayName ? problem.displayName : '',
            data: problem,
        };
    });

    return (
        <Stack tokens={{ childrenGap: 5 }}>
            <SearchComboField
                options={_options}
                placeholder={isLoading ? 'Loading problems list...' : 'Select Problem'}
                useComboBoxAsMenuWidth={true}
                selectedKey={item && item.code ? item.code : null}
                maxResults={15}
                disabled={isLoading}
                onItemClick={(event, problemOption, index) => {
                    const id = index;
                    if (problemOption?.data) {
                        const newProblem: IPatientProblem = {
                            problemId: problemOption.data.id,
                            events: [],
                            bestMatchCode: '',
                            code: problemOption.key as string,
                            codeset: problemOption.data.codeset,
                            deactivatedDate: '',
                            deactivatedUser: '',
                            lastModifiedBy: '',
                            modifiedBy: '',
                            modifiedOn: '',
                            mostRecentDiagnosisNote: '',
                            name: problemOption.text,
                            isDeleted: false,
                        };

                        const updatedItem = {
                            ...selectedOverviewItem,
                            key: id,
                            item: {
                                ...selectedOverviewItem.item,
                                problems: [...selectedOverviewItem.item.problems, newProblem],
                            },
                        };
                        updateSelectedPatientOverview(updatedItem);
                    }
                }}
            />
            {item && (
                <SubSection
                    title="Events"
                    headingCenterContent={
                        <Stack horizontal>
                            <TooltipHost content="Add Event">
                                <IconButton
                                    iconProps={{ iconName: 'Add' }}
                                    disabled={showFields}
                                    onClick={() => {
                                        setProblemEvent({
                                            eventType: '',
                                            note: '',
                                            onSetDate: '',
                                            status: '',
                                        });
                                        setShowFields(true);
                                    }}
                                />
                            </TooltipHost>
                        </Stack>
                    }
                >
                    {showFields && (
                        <Stack tokens={{ childrenGap: 10 }}>
                            <Stack tokens={{ childrenGap: 10 }} horizontal verticalAlign="end" grow>
                                <Stack.Item grow>
                                    <Field.Date label="Onset Date" onChange={setOnSetDate} />
                                </Stack.Item>
                                <Stack.Item grow>
                                    <Field.Dropdown
                                        options={statusOptions}
                                        label="Status"
                                        placeholder="(Select Status)"
                                        style={{ minWidth: 215 }}
                                        onChange={(event, option) => {
                                            if (option && problemEvent)
                                                setProblemEvent({ ...problemEvent, status: option.key as string });
                                        }}
                                    />
                                </Stack.Item>
                                <Stack.Item grow>
                                    <Field.Dropdown
                                        options={typeOptions}
                                        label="Type"
                                        placeholder="(Select Type)"
                                        style={{ minWidth: 215 }}
                                        onChange={(event, option) => {
                                            if (option && problemEvent)
                                                setProblemEvent({ ...problemEvent, eventType: option.key as string });
                                        }}
                                    />
                                </Stack.Item>
                            </Stack>
                            <Stack tokens={{ childrenGap: 5 }} horizontal horizontalAlign="end">
                                <PrimaryButton
                                    text="Save Event"
                                    onClick={() => {
                                        if (item && item.events && problemEvent) {
                                            const updatedProblem = getUpdatedProblem([...item.events, problemEvent]);
                                            const updatedItem = getUpdatedItem([...filteredProblems, updatedProblem]);
                                            updateSelectedPatientOverview(updatedItem);
                                            setProblemEvent(undefined);
                                            setShowFields(false);
                                        }
                                    }}
                                />
                                <DefaultButton
                                    text="Discard Event"
                                    onClick={() => {
                                        setProblemEvent(undefined);
                                        setShowFields(false);
                                    }}
                                />
                            </Stack>
                        </Stack>
                    )}
                </SubSection>
            )}
            {item && item.events && item.events.length && (
                <SortableDetailsList<IProblemEvent>
                    selectionMode={SelectionMode.none}
                    items={item.events}
                    sortOnMount={true}
                    sortColumns={['onSetDate']}
                    columns={[
                        {
                            key: 'onSetDate',
                            minWidth: 100,
                            maxWidth: 150,
                            fieldName: 'onSetDate',
                            name: 'Onset Date',
                            isPadded: true,
                            onRender: (problemEvent) => {
                                if (problemEvent && problemEvent.onSetDate) {
                                    const date = format(new Date(problemEvent.onSetDate), 'yyyy-MM-dd');
                                    return <Text variant="smallPlus">{date}</Text>;
                                }
                            },
                        },
                        {
                            key: 'status',
                            minWidth: 100,
                            maxWidth: 150,
                            fieldName: 'status',
                            name: 'Status',
                            isPadded: true,
                            onRender: (problemEvent) =>
                                problemEvent ? (
                                    <ComboBox
                                        placeholder="(Select Status)"
                                        options={statusOptions}
                                        onChange={(e, option) => handleOnChange(option, problemEvent, 'status')}
                                        selectedKey={problemEvent.status}
                                    />
                                ) : null,
                        },
                        {
                            key: 'eventType',
                            minWidth: 100,
                            fieldName: 'eventType',
                            name: 'Type',
                            isPadded: true,
                            onRender: (problemEvent) =>
                                problemEvent ? (
                                    <ComboBox
                                        placeholder="(Select Type)"
                                        options={typeOptions}
                                        onChange={(e, option) => handleOnChange(option, problemEvent, 'eventType')}
                                        selectedKey={problemEvent.eventType}
                                    />
                                ) : null,
                        },
                    ]}
                />
            )}
        </Stack>
    );
}
