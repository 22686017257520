import { createSelector } from 'reselect';
import { RootState } from 'state/store';
import { allTutorials, ITeachingState } from './teaching.state';
export const selectTeachingState = (state: RootState): ITeachingState => state.teaching;
export const selectCurrentTutorialStep = createSelector(selectTeachingState, (state) => state.currentStep);
export const selectCurrentTutorialStepPage = createSelector(selectTeachingState, (state) => state.currentPage);
export const selectCurrentTutorial = createSelector(selectTeachingState, (state) => state.currentTutorial);

export const selectCurrentTutorialData = createSelector(selectCurrentTutorial, (tutorial) => {
    return tutorial && allTutorials ? allTutorials[tutorial] : undefined;
});
export const selectCurrentTutorialDataStep = createSelector(
    selectCurrentTutorialData,
    selectCurrentTutorialStep,
    (data, step) => {
        return data && step ? data[step] : undefined;
    },
);
