import { createSlice, Dictionary } from '@reduxjs/toolkit';
import { IEncounterReason } from 'api/models/encounter.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import encountersReducers, { encountersExtraReducers } from './encounter-reasons.reducers';

export type EncounterReasonsState = {
    data?: Dictionary<IEncounterReason>;
    loading: LoadingStatus;
    saving: LoadingStatus;
    encountersSearch?: string;
};

const initialState: EncounterReasonsState = {
    data: undefined,
    loading: LoadingStatus.Idle,
    saving: LoadingStatus.Idle,
    encountersSearch: undefined,
};

const encounterSlice = createSlice({
    name: 'encounterReasons',
    initialState,
    reducers: { ...encountersReducers },
    extraReducers: encountersExtraReducers,
});

const { actions, reducer } = encounterSlice;

export const { setEncountersSearch } = actions;

export default reducer;
