import { Stack, IconButton, mergeStyles } from '@fluentui/react';
import { Section } from 'components';
import PanelSectionHeader from 'pages/components/PanelSectionHeader';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';

type Props = {
    title?: string;
    children: React.ReactNode;
    leftContent?: JSX.Element;
    isOpen?: boolean;
    toggleExpanded?: () => void;
    animation?: boolean;
};

export default function ExpandableSectionHeader({
    children,
    animation = true,
    leftContent,
    title,
    isOpen,
    toggleExpanded,
}: Props): JSX.Element {
    const targetRef = useRef<HTMLDivElement>(null);
    const [sectionContentHeight, setSectionContentHeight] = useState(0);

    const _toggleExpanded = () => {
        if (toggleExpanded) toggleExpanded();
    };

    useLayoutEffect(() => {
        if (targetRef.current) setSectionContentHeight(targetRef.current.offsetHeight);
    }, [children]);

    const sectionClass = mergeStyles({
        height: !isOpen ? 0 : animation ? sectionContentHeight : 'auto',
        transition: 'height 100ms ease-in',
        overflow: 'hidden',
    });

    return (
        <Stack grow>
            <PanelSectionHeader
                text={title ?? ''}
                leftContent={leftContent}
                rightContent={
                    <Stack horizontal verticalAlign="center" grow>
                        <IconButton
                            onClick={() => {
                                _toggleExpanded();
                            }}
                            style={{ maxHeight: 24, maxWidth: 24 }}
                            iconProps={{ iconName: isOpen ? 'ChevronUp' : 'ChevronDown' }}
                        />
                    </Stack>
                }
            />
            <Stack className={sectionClass}>
                <div ref={targetRef}>
                    <Section>{children}</Section>
                </div>
            </Stack>
        </Stack>
    );
}
