import {
    Checkbox,
    IChoiceGroupOption,
    IDropdownOption,
    Label,
    Separator,
    Stack,
    TextField,
    DefaultButton,
    PrimaryButton,
} from '@fluentui/react';
import { Field, Section } from 'components';
import useForms from 'hooks/store/useForms';
import { map } from 'lodash';
import { IDentalHistoryQuestions, ChiefComplaint, VisitType } from './types';

const chiefComplaintOptions: IDropdownOption[] = map(ChiefComplaint, (d) => ({ key: d, text: d }));
const visitTypeOptions: IDropdownOption[] = map(VisitType, (d) => ({ key: d, text: d }));

const choiceGroupOptions: IChoiceGroupOption[] = [
    {
        key: 'Yes',
        text: 'Yes',
    },
    {
        key: 'No',
        text: 'No',
    },
];

export default function DentalHistory(): JSX.Element | null {
    const { currentlySelectedForm, setFormFieldDataAndSave, editingCompletedForm } = useForms();
    if (!currentlySelectedForm) return null;

    const data = currentlySelectedForm.data as IDentalHistoryQuestions;
    const typedSetFieldData = (path: keyof IDentalHistoryQuestions, value?: string | boolean) =>
        setFormFieldDataAndSave(path, value);

    const disableForm = editingCompletedForm ? false : currentlySelectedForm.completedDate ? true : false;

    const setDefaultValues = () => {
        const defaultData: Partial<IDentalHistoryQuestions> = {
            doYouHaveBleedingOrSoreGumsWhenYouBrushOrFloss: 'No',
            areYourTeethSensitiveToColdHotSweetsOrPressBiting: 'No',
            doYouHaveFoodImpactionOrFlossCatchBetweenYourTeeth: 'No',
            doYouHaveDryMouth: 'No',
            doYouHaveAnUnpleasantTasteOrBadBreath: 'No',
            doYouHaveBurningTongueOrLips: 'No',
            doYouHaveFrequentBlistersSoresOrUlcersOnLipsInMouth: 'No',
            doYouHaveSwellingOrLumpsInYourMouth: 'No',
            doYouHaveClikcingPoppingOrDiscomfortInTheJaw: 'No',
            doYouHaveDifficultyOpeningOrClosingYourJaw: 'No',
            doYouHaveLooseTeeth: 'No',
            doYouHaveAnyChippedOrBrokenTeeth: 'No',
            doYouHaveAnyShiftingOfYourTeeth: 'No',
            doYouHaveAnyChangeInYourBite: 'No',
            doYouHaveEarachesHeadachesOrNeckPain: 'No',
            haveYouEverHadOrthodonticTreatment: 'No',
            areYouCurrentlyExperiencingDentalPainOrDiscomfort: 'No',
            pleaseDescribeDentalPainOrDisomfort: '',
            doYouClenchOrGrindYourTeeth: 'No',
            doYouBiteYourCheeksOrLips: 'No',
            doYouWearAnyRemovableDentalAppliances: 'No',
            dateTheyWereMade: '',
            haveYouEverHadASeriousInjuryToYourHeadOrMouth: 'No',
            pleaseDescribeSeriousInjuryToYourHeadOrMouth: '',
            doYouParticipateInActiveRecreationalActivities: 'No',
            haveYouEverHadAnyProblemsAssociatedWithPreviousDentalTreatment: 'No',
            haveYouEverHadAnyProblemsWithDentalAnesthetic: 'No',
            haveYouHadAnyPeriodontalTreatments: 'No',
            haveYouEverBeenToldYouNeededAntibioticPreMedicationForDentalTreatment: 'No',
            isYourHomeWaterSupplyFluoridated: 'No',
            doYouDrinkBottledOrFilteredWater: 'No',
            ifYesHowOften: '',
            dateOfLastDentalExam: '',
            dateOfLastDentalXrays: '',
            previousDentist: '',
            whatWasDoneAtThisTime: '',
            pleaseRateYourComfortLevelWithReceivingDentalTreatment: '',
            howDoYouFeelAboutYourSmile: '',
            whatTypeOfTreatmentDoYouFeelYouNeed: '',
            brush: 'No',
            brushHowManyTimesPerDay: '',
            myBrushIs: '',
            electricBrush: 'No',
            dentalFloss: 'No',
            dentalFlossHowManyTimesPerDay: '',
            fluorideRinse: 'No',
            fluorideRinseHowManyTimesPerDay: '',
            other: false,
            explainOther: '',
            bleaching: false,
            cosmeticDentistry: false,
            dentures: false,
            implants: false,
            endodontics: false,
            specialLearningNeedsIdentified: false,
            otherAdditionalInformation: false,
        };

        for (const key in defaultData) {
            // eslint-disable-next-line no-prototype-builtins
            if (defaultData.hasOwnProperty(key)) {
                typedSetFieldData(key as keyof IDentalHistoryQuestions, defaultData[key as keyof IDentalHistoryQuestions]);
            }
        }
    };

    return (
        <Stack grow>
            <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }}>
                <Label>Dental Information</Label>
                <Stack.Item grow>
                    <span />
                </Stack.Item>
                <PrimaryButton text="Set Answers to No" onClick={setDefaultValues} disabled={disableForm} />
            </Stack>
            <Section>
                <Field.ChoiceGroup
                    inline
                    label="Do you have bleeding or sore gums when you brush or floss?"
                    options={choiceGroupOptions}
                    selectedKey={data.doYouHaveBleedingOrSoreGumsWhenYouBrushOrFloss}
                    onChange={(ev, option) => {
                        if (option) typedSetFieldData('doYouHaveBleedingOrSoreGumsWhenYouBrushOrFloss', option.key as string);
                    }}
                    disabled={disableForm}
                />
                <Separator />
                <Field.ChoiceGroup
                    inline
                    label="Are your teeth sensitive to cold, hot, sweets, or pressure/biting?"
                    options={choiceGroupOptions}
                    selectedKey={data.areYourTeethSensitiveToColdHotSweetsOrPressBiting}
                    onChange={(ev, option) => {
                        if (option) typedSetFieldData('areYourTeethSensitiveToColdHotSweetsOrPressBiting', option.key as string);
                    }}
                    disabled={disableForm}
                />
                <Separator />
                <Field.ChoiceGroup
                    inline
                    label="Do you have food impaction or floss catch between your teeth?"
                    options={choiceGroupOptions}
                    selectedKey={data.doYouHaveFoodImpactionOrFlossCatchBetweenYourTeeth}
                    onChange={(ev, option) => {
                        if (option) typedSetFieldData('doYouHaveFoodImpactionOrFlossCatchBetweenYourTeeth', option.key as string);
                    }}
                    disabled={disableForm}
                />
                <Separator />
                <Field.ChoiceGroup
                    inline
                    label="Do you have dry mouth?"
                    options={choiceGroupOptions}
                    selectedKey={data.doYouHaveDryMouth}
                    onChange={(ev, option) => {
                        if (option) typedSetFieldData('doYouHaveDryMouth', option.key as string);
                    }}
                    disabled={disableForm}
                />
                <Separator />
                <Field.ChoiceGroup
                    inline
                    label="Do you have an unpleasant taste or bad breath?"
                    options={choiceGroupOptions}
                    selectedKey={data.doYouHaveAnUnpleasantTasteOrBadBreath}
                    onChange={(ev, option) => {
                        if (option) typedSetFieldData('doYouHaveAnUnpleasantTasteOrBadBreath', option.key as string);
                    }}
                    disabled={disableForm}
                />
                <Separator />
                <Field.ChoiceGroup
                    inline
                    label="Do you have a burning tongue or lips?"
                    options={choiceGroupOptions}
                    selectedKey={data.doYouHaveBurningTongueOrLips}
                    onChange={(ev, option) => {
                        if (option) typedSetFieldData('doYouHaveBurningTongueOrLips', option.key as string);
                    }}
                    disabled={disableForm}
                />
                <Separator />
                <Field.ChoiceGroup
                    inline
                    label="Do you have frequent blisters, sores, or ulcers on lips or in mouth?"
                    options={choiceGroupOptions}
                    selectedKey={data.doYouHaveFrequentBlistersSoresOrUlcersOnLipsInMouth}
                    onChange={(ev, option) => {
                        if (option)
                            typedSetFieldData('doYouHaveFrequentBlistersSoresOrUlcersOnLipsInMouth', option.key as string);
                    }}
                    disabled={disableForm}
                />
                <Separator />
                <Field.ChoiceGroup
                    inline
                    label="Do you have swelling or lumps in your mouth?"
                    options={choiceGroupOptions}
                    selectedKey={data.doYouHaveSwellingOrLumpsInYourMouth}
                    onChange={(ev, option) => {
                        if (option) typedSetFieldData('doYouHaveSwellingOrLumpsInYourMouth', option.key as string);
                    }}
                    disabled={disableForm}
                />
                <Separator />
                <Field.ChoiceGroup
                    inline
                    label="Do you have clicking, popping, or discomfort in the jaw?"
                    options={choiceGroupOptions}
                    selectedKey={data.doYouHaveClikcingPoppingOrDiscomfortInTheJaw}
                    onChange={(ev, option) => {
                        if (option) typedSetFieldData('doYouHaveClikcingPoppingOrDiscomfortInTheJaw', option.key as string);
                    }}
                    disabled={disableForm}
                />
                <Separator />
                <Field.ChoiceGroup
                    inline
                    label="Do you have difficulty opening or closing your jaw?"
                    options={choiceGroupOptions}
                    selectedKey={data.doYouHaveDifficultyOpeningOrClosingYourJaw}
                    onChange={(ev, option) => {
                        if (option) typedSetFieldData('doYouHaveDifficultyOpeningOrClosingYourJaw', option.key as string);
                    }}
                    disabled={disableForm}
                />
                <Separator />
                <Field.ChoiceGroup
                    inline
                    label="Do you have loose teeth?"
                    options={choiceGroupOptions}
                    selectedKey={data.doYouHaveLooseTeeth}
                    onChange={(ev, option) => {
                        if (option) typedSetFieldData('doYouHaveLooseTeeth', option.key as string);
                    }}
                    disabled={disableForm}
                />
                <Separator />
                <Field.ChoiceGroup
                    inline
                    label="Do you have any chipped or broken teeth?"
                    options={choiceGroupOptions}
                    selectedKey={data.doYouHaveAnyChippedOrBrokenTeeth}
                    onChange={(ev, option) => {
                        if (option) typedSetFieldData('doYouHaveAnyChippedOrBrokenTeeth', option.key as string);
                    }}
                    disabled={disableForm}
                />
                <Separator />
                <Field.ChoiceGroup
                    inline
                    label="Do you have any shifting of your teeth?"
                    options={choiceGroupOptions}
                    selectedKey={data.doYouHaveAnyShiftingOfYourTeeth}
                    onChange={(ev, option) => {
                        if (option) typedSetFieldData('doYouHaveAnyShiftingOfYourTeeth', option.key as string);
                    }}
                    disabled={disableForm}
                />
                <Separator />
                <Field.ChoiceGroup
                    inline
                    label="Do you have a change in your bite?"
                    options={choiceGroupOptions}
                    selectedKey={data.doYouHaveAnyChangeInYourBite}
                    onChange={(ev, option) => {
                        if (option) typedSetFieldData('doYouHaveAnyChangeInYourBite', option.key as string);
                    }}
                    disabled={disableForm}
                />
                <Separator />
                <Field.ChoiceGroup
                    inline
                    label="Do you have earaches, headaches, or neck pain?"
                    options={choiceGroupOptions}
                    selectedKey={data.doYouHaveEarachesHeadachesOrNeckPain}
                    onChange={(ev, option) => {
                        if (option) typedSetFieldData('doYouHaveEarachesHeadachesOrNeckPain', option.key as string);
                    }}
                    disabled={disableForm}
                />
                <Separator />
                <Field.ChoiceGroup
                    inline
                    label="Have you ever had orthodontic (braces) treatment?"
                    options={choiceGroupOptions}
                    selectedKey={data.haveYouEverHadOrthodonticTreatment}
                    onChange={(ev, option) => {
                        if (option) typedSetFieldData('haveYouEverHadOrthodonticTreatment', option.key as string);
                    }}
                    disabled={disableForm}
                />
                <Separator />
                <Field.ChoiceGroup
                    inline
                    label="Are you currently experiencing dental pain or discomfort?"
                    options={choiceGroupOptions}
                    selectedKey={data.areYouCurrentlyExperiencingDentalPainOrDiscomfort}
                    onChange={(ev, option) => {
                        if (option) typedSetFieldData('areYouCurrentlyExperiencingDentalPainOrDiscomfort', option.key as string);
                    }}
                    disabled={disableForm}
                />
                {/* Need logic here for enabling */}
                <TextField
                    label="Please Describe"
                    multiline
                    value={data.pleaseDescribeDentalPainOrDisomfort}
                    onChange={(ev, value) => {
                        typedSetFieldData('pleaseDescribeDentalPainOrDisomfort', value);
                    }}
                    disabled={
                        disableForm ||
                        data.areYouCurrentlyExperiencingDentalPainOrDiscomfort === 'No' ||
                        !data.areYouCurrentlyExperiencingDentalPainOrDiscomfort
                    }
                />
                <Separator />
                <Field.ChoiceGroup
                    inline
                    label="Do you clench or grind your teeth?"
                    options={choiceGroupOptions}
                    selectedKey={data.doYouClenchOrGrindYourTeeth}
                    onChange={(ev, option) => {
                        if (option) typedSetFieldData('doYouClenchOrGrindYourTeeth', option.key as string);
                    }}
                    disabled={disableForm}
                />
                <Separator />
                <Field.ChoiceGroup
                    inline
                    label="Do you bite your cheeks or lips?"
                    options={choiceGroupOptions}
                    selectedKey={data.doYouBiteYourCheeksOrLips}
                    onChange={(ev, option) => {
                        if (option) typedSetFieldData('doYouBiteYourCheeksOrLips', option.key as string);
                    }}
                    disabled={disableForm}
                />
                <Separator />
                <Field.ChoiceGroup
                    inline
                    label="Do you wear any removable dental appliances (complete or partial dentures)?"
                    options={choiceGroupOptions}
                    selectedKey={data.doYouWearAnyRemovableDentalAppliances}
                    onChange={(ev, option) => {
                        if (option) typedSetFieldData('doYouWearAnyRemovableDentalAppliances', option.key as string);
                    }}
                    disabled={disableForm}
                />
                <Field.Date
                    label="Date they were made"
                    hasDatePicker
                    value={data.dateTheyWereMade}
                    onChange={(ev, value) => {
                        typedSetFieldData('dateTheyWereMade', value);
                    }}
                    disabled={
                        disableForm ||
                        data.doYouWearAnyRemovableDentalAppliances === 'No' ||
                        !data.doYouWearAnyRemovableDentalAppliances
                    }
                />
                <Separator />
                <Field.ChoiceGroup
                    inline
                    label="Have you ever had a serious injury to your head or mouth?"
                    options={choiceGroupOptions}
                    selectedKey={data.haveYouEverHadASeriousInjuryToYourHeadOrMouth}
                    onChange={(ev, option) => {
                        if (option) typedSetFieldData('haveYouEverHadASeriousInjuryToYourHeadOrMouth', option.key as string);
                    }}
                    disabled={disableForm}
                />
                <TextField
                    label="Please Describe"
                    multiline
                    value={data.pleaseDescribeSeriousInjuryToYourHeadOrMouth}
                    onChange={(ev, value) => {
                        typedSetFieldData('pleaseDescribeSeriousInjuryToYourHeadOrMouth', value);
                    }}
                    disabled={
                        disableForm ||
                        data.haveYouEverHadASeriousInjuryToYourHeadOrMouth === 'No' ||
                        !data.haveYouEverHadASeriousInjuryToYourHeadOrMouth
                    }
                />
                <Separator />
                <Field.ChoiceGroup
                    inline
                    label="Do you participate in active recreational activities?"
                    options={choiceGroupOptions}
                    selectedKey={data.doYouParticipateInActiveRecreationalActivities}
                    onChange={(ev, option) => {
                        if (option) typedSetFieldData('doYouParticipateInActiveRecreationalActivities', option.key as string);
                    }}
                    disabled={disableForm}
                />
                <Separator />
                <Field.ChoiceGroup
                    inline
                    label="Have you ever had any problems associated with previous dental treatment?"
                    options={choiceGroupOptions}
                    selectedKey={data.haveYouEverHadAnyProblemsAssociatedWithPreviousDentalTreatment}
                    onChange={(ev, option) => {
                        if (option)
                            typedSetFieldData(
                                'haveYouEverHadAnyProblemsAssociatedWithPreviousDentalTreatment',
                                option.key as string,
                            );
                    }}
                    disabled={disableForm}
                />
                <Separator />
                <Field.ChoiceGroup
                    inline
                    label="Have you ever had any problems with dental anesthetic?"
                    options={choiceGroupOptions}
                    selectedKey={data.haveYouEverHadAnyProblemsWithDentalAnesthetic}
                    onChange={(ev, option) => {
                        if (option) typedSetFieldData('haveYouEverHadAnyProblemsWithDentalAnesthetic', option.key as string);
                    }}
                    disabled={disableForm}
                />
                <Separator />
                <Field.ChoiceGroup
                    inline
                    label="Have you had any periodontal (gum) treatments?"
                    options={choiceGroupOptions}
                    selectedKey={data.haveYouHadAnyPeriodontalTreatments}
                    onChange={(ev, option) => {
                        if (option) typedSetFieldData('haveYouHadAnyPeriodontalTreatments', option.key as string);
                    }}
                    disabled={disableForm}
                />
                <Separator />
                <Field.ChoiceGroup
                    inline
                    label="Have you ever been told you needed antibiotic pre-medication for dental treatment?"
                    options={choiceGroupOptions}
                    selectedKey={data.haveYouEverBeenToldYouNeededAntibioticPreMedicationForDentalTreatment}
                    onChange={(ev, option) => {
                        if (option)
                            typedSetFieldData(
                                'haveYouEverBeenToldYouNeededAntibioticPreMedicationForDentalTreatment',
                                option.key as string,
                            );
                    }}
                    disabled={disableForm}
                />
                <Separator />
                <Field.ChoiceGroup
                    inline
                    label="Is your home water supply fluoridated?"
                    options={choiceGroupOptions}
                    selectedKey={data.isYourHomeWaterSupplyFluoridated}
                    onChange={(ev, option) => {
                        if (option) typedSetFieldData('isYourHomeWaterSupplyFluoridated', option.key as string);
                    }}
                    disabled={disableForm}
                />
                <Separator />
                <Field.ChoiceGroup
                    inline
                    label="Do you drink bottled or filtered water?"
                    options={choiceGroupOptions}
                    selectedKey={data.doYouDrinkBottledOrFilteredWater}
                    onChange={(ev, option) => {
                        if (option) typedSetFieldData('doYouDrinkBottledOrFilteredWater', option.key as string);
                    }}
                    disabled={disableForm}
                />
                <Field.ChoiceGroup
                    inline
                    label="If yes, how often?"
                    options={[
                        { key: 'Daily', text: 'Daily' },
                        { key: 'Weekly', text: 'Weekly' },
                        { key: 'Occasionally', text: 'Occasionally' },
                    ]}
                    selectedKey={data.ifYesHowOften}
                    onChange={(ev, option) => {
                        if (option) typedSetFieldData('ifYesHowOften', option.key as string);
                    }}
                    disabled={
                        disableForm || data.doYouDrinkBottledOrFilteredWater === 'No' || !data.doYouDrinkBottledOrFilteredWater
                    }
                />
                <Separator />
                <Stack grow horizontal tokens={{ childrenGap: 10 }}>
                    <Stack.Item grow>
                        <Field.Date
                            label="Date of your last dental exam"
                            hasDatePicker
                            value={data.dateOfLastDentalExam}
                            onChange={(ev, value) => {
                                typedSetFieldData('dateOfLastDentalExam', value);
                            }}
                            disabled={disableForm}
                        />
                    </Stack.Item>
                    <Stack.Item grow>
                        <Field.Date
                            label="Date of last dental x-rays"
                            hasDatePicker
                            value={data.dateOfLastDentalXrays}
                            onChange={(ev, value) => {
                                typedSetFieldData('dateOfLastDentalXrays', value);
                            }}
                            disabled={disableForm}
                        />
                    </Stack.Item>
                </Stack>
                <TextField
                    label="Previous Dentist"
                    value={data.previousDentist}
                    onChange={(ev, value) => {
                        typedSetFieldData('previousDentist', value);
                    }}
                    disabled={disableForm}
                />
                <TextField
                    label="What was done at this time?"
                    multiline
                    value={data.whatWasDoneAtThisTime}
                    onChange={(ev, value) => {
                        typedSetFieldData('whatWasDoneAtThisTime', value);
                    }}
                    disabled={disableForm}
                />
                <Separator />
                <Field.ChoiceGroup
                    inline
                    label="Please rate your comfort level with receiving dental treatment."
                    options={[
                        { key: 'Completely Comfortable', text: 'Completely Comfortable' },
                        { key: 'Moderately Comfortable', text: 'Moderately Comfortable' },
                        { key: 'Slightly Uncomfortable', text: 'Slightly Uncomfortable' },
                        { key: 'Uncomfortable', text: 'Uncomfortable' },
                    ]}
                    selectedKey={data.pleaseRateYourComfortLevelWithReceivingDentalTreatment}
                    onChange={(ev, option) => {
                        if (option)
                            typedSetFieldData('pleaseRateYourComfortLevelWithReceivingDentalTreatment', option.key as string);
                    }}
                    disabled={disableForm}
                />
                <Separator />
                <TextField
                    label="How do you feel about your smile?"
                    multiline
                    value={data.howDoYouFeelAboutYourSmile}
                    onChange={(ev, value) => {
                        typedSetFieldData('howDoYouFeelAboutYourSmile', value);
                    }}
                    disabled={disableForm}
                />
                <Separator />
                <TextField
                    label="What type of dental treatment do you feel you need?"
                    multiline
                    value={data.whatTypeOfTreatmentDoYouFeelYouNeed}
                    onChange={(ev, value) => {
                        typedSetFieldData('whatTypeOfTreatmentDoYouFeelYouNeed', value);
                    }}
                    disabled={disableForm}
                />
            </Section>
            <Section heading="Oral Hygiene">
                <Field.ChoiceGroup
                    inline
                    label="Brush?"
                    options={choiceGroupOptions}
                    selectedKey={data.brush}
                    onChange={(ev, option) => {
                        if (option) typedSetFieldData('brush', option.key as string);
                    }}
                    disabled={disableForm}
                />
                <Separator />
                <Field.ChoiceGroup
                    inline
                    label="How many times per day?"
                    options={[
                        {
                            key: '1',
                            text: '1',
                        },
                        {
                            key: '2',
                            text: '2',
                        },
                        {
                            key: '3',
                            text: '3',
                        },
                        {
                            key: '4',
                            text: '4',
                        },
                    ]}
                    selectedKey={data.brushHowManyTimesPerDay}
                    onChange={(ev, option) => {
                        if (option) typedSetFieldData('brushHowManyTimesPerDay', option.key as string);
                    }}
                    disabled={disableForm}
                />
                <Separator />
                <Field.ChoiceGroup
                    inline
                    label="My brush is?"
                    options={[
                        { key: 'Soft', text: 'Soft' },
                        { key: 'Medium', text: 'Medium' },
                        { key: 'Hard', text: 'Hard' },
                    ]}
                    selectedKey={data.myBrushIs}
                    onChange={(ev, option) => {
                        if (option) typedSetFieldData('myBrushIs', option.key as string);
                    }}
                    disabled={disableForm}
                />
                <Field.ChoiceGroup
                    inline
                    label="Electric?"
                    options={choiceGroupOptions}
                    selectedKey={data.electricBrush}
                    onChange={(ev, option) => {
                        if (option) typedSetFieldData('electricBrush', option.key as string);
                    }}
                    disabled={disableForm}
                />
                <Separator />
                <Field.ChoiceGroup
                    inline
                    label="Dental Floss?"
                    options={choiceGroupOptions}
                    selectedKey={data.dentalFloss}
                    onChange={(ev, option) => {
                        if (option) typedSetFieldData('dentalFloss', option.key as string);
                    }}
                    disabled={disableForm}
                />
                <Field.ChoiceGroup
                    inline
                    label="How many times per day?"
                    options={[
                        {
                            key: '1',
                            text: '1',
                        },
                        {
                            key: '2',
                            text: '2',
                        },
                        {
                            key: '3',
                            text: '3',
                        },
                        {
                            key: '4',
                            text: '4',
                        },
                    ]}
                    selectedKey={data.dentalFlossHowManyTimesPerDay}
                    onChange={(ev, option) => {
                        if (option) typedSetFieldData('dentalFlossHowManyTimesPerDay', option.key as string);
                    }}
                    disabled={disableForm || data.dentalFloss === 'No' || !data.dentalFloss}
                />
                <Separator />
                <Field.ChoiceGroup
                    inline
                    label="Fluoride Rinse?"
                    options={choiceGroupOptions}
                    selectedKey={data.fluorideRinse}
                    onChange={(ev, option) => {
                        if (option) typedSetFieldData('fluorideRinse', option.key as string);
                    }}
                    disabled={disableForm}
                />
                <Field.ChoiceGroup
                    inline
                    label="How many times per day?"
                    options={[
                        {
                            key: '1',
                            text: '1',
                        },
                        {
                            key: '2',
                            text: '2',
                        },
                        {
                            key: '3',
                            text: '3',
                        },
                        {
                            key: '4',
                            text: '4',
                        },
                    ]}
                    selectedKey={data.fluorideRinseHowManyTimesPerDay}
                    onChange={(ev, option) => {
                        if (option) typedSetFieldData('fluorideRinseHowManyTimesPerDay', option.key as string);
                    }}
                    disabled={disableForm || data.fluorideRinse === 'No' || !data.fluorideRinse}
                />
                <Separator />
                <Checkbox
                    label="Other"
                    checked={data.other}
                    onChange={() => typedSetFieldData('other', !data.other)}
                    disabled={disableForm}
                />
                <TextField
                    label="Explain"
                    multiline
                    value={data.explainOther}
                    onChange={(ev, value) => {
                        typedSetFieldData('explainOther', value);
                    }}
                    disabled={disableForm || !data.other}
                />
                <Separator />
                <Label>I would like additional information about</Label>
                <Stack grow horizontal wrap tokens={{ childrenGap: 10 }}>
                    <Checkbox
                        label="Bleaching"
                        checked={data.bleaching}
                        onChange={() => typedSetFieldData('bleaching', !data.bleaching)}
                        disabled={disableForm}
                    />
                    <Checkbox
                        label="Cosmetic Dentistry"
                        checked={data.cosmeticDentistry}
                        onChange={() => typedSetFieldData('cosmeticDentistry', !data.cosmeticDentistry)}
                        disabled={disableForm}
                    />
                    <Checkbox
                        label="Dentures"
                        checked={data.dentures}
                        onChange={() => typedSetFieldData('dentures', !data.dentures)}
                        disabled={disableForm}
                    />
                    <Checkbox
                        label="Implants"
                        checked={data.implants}
                        onChange={() => typedSetFieldData('implants', !data.implants)}
                        disabled={disableForm}
                    />
                    <Checkbox
                        label="Endodontics"
                        checked={data.endodontics}
                        onChange={() => typedSetFieldData('endodontics', !data.endodontics)}
                        disabled={disableForm}
                    />
                    <Checkbox
                        label="Special learning needs identified"
                        checked={data.specialLearningNeedsIdentified}
                        disabled={disableForm}
                        onChange={() => typedSetFieldData('specialLearningNeedsIdentified', !data.specialLearningNeedsIdentified)}
                    />
                    <Checkbox
                        label="Other"
                        checked={data.otherAdditionalInformation}
                        disabled={disableForm}
                        onChange={() => typedSetFieldData('otherAdditionalInformation', !data.otherAdditionalInformation)}
                    />
                </Stack>
            </Section>
        </Stack>
    );
}
