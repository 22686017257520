import LookupDetailsList from '../Shared/LookupDetailsList';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import { IProcedureDetail } from 'api/models/procedure-detail.model';
import { Link, Stack } from '@fluentui/react';
import useLookupsStore from 'hooks/store/useLookupsStore';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { useSelector, useTenantId } from 'hooks';
import { selectProcedureTemplatesLookup } from 'state/slices/lookups/lookups.selectors';

type DetailsListProps = {
    searchTerm: string;
};

function DetailsList({ searchTerm }: DetailsListProps): JSX.Element {
    const tenantId = useTenantId();
    const { setSelectedLookup } = useLookupsStore<IProcedureDetail>('ProcedureDetails');

    const items = useSelector((state) => selectProcedureTemplatesLookup(state, tenantId));

    const columns: ISortableColumn<IProcedureDetail>[] = [
        {
            key: 'code',
            fieldName: 'code',
            name: 'Code',
            minWidth: 50,
            maxWidth: 70,
        },
        {
            key: 'displayName',
            fieldName: 'displayName',
            name: 'Description',
            minWidth: 100,
            maxWidth: 270,
            onRender: (item) => {
                return <span title={item?.displayName}>{item?.displayName ?? ''}</span>;
            },
        },
        {
            key: 'template',
            fieldName: 'template',
            name: 'Template',
            minWidth: 100,
            onRender: (item) =>
                item?.template ? (
                    <Stack grow>
                        <Link onClick={() => setSelectedLookup({ item, savingStatus: LoadingStatus.Idle })}>
                            <div style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: item.template }} />
                        </Link>
                    </Stack>
                ) : null,
        },
    ];

    const filterHandler = (item?: IProcedureDetail): boolean => {
        searchTerm = searchTerm.toLowerCase();
        return (
            (item?.code ? item.code.toLowerCase().includes(searchTerm) : false) ||
            (item?.displayName ? item.displayName.toLowerCase().includes(searchTerm) : false)
        );
    };

    return (
        <LookupDetailsList<IProcedureDetail>
            lookupType="ProcedureDetails"
            columns={columns}
            items={items}
            searchTerm={searchTerm}
            sortOnMount={true}
            filterHandler={filterHandler}
            mountSortColumn={['code']}
        />
    );
}

export default DetailsList;
