import { RouteParams } from 'interfaces/route-params';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { createNewChartTreatmentPlan } from 'state/slices/charting/treatmentPlans/treatmentPlans.actions';
import TreatmentPlan from './components/TreatmentPlan';

function AddTreatmentPlan(): JSX.Element | null {
    const { encounterId, patientId, tenantId } = useParams<RouteParams>();
    const dispatch = useDispatch();

    useEffect(() => {
        if (encounterId) dispatch(createNewChartTreatmentPlan(encounterId, patientId, tenantId));
    }, [encounterId, dispatch, patientId, tenantId]);

    return <TreatmentPlan />;
}

/**
 * isSigning state & selector
 * - if True
 *      - Hides Pending Procedures
 *      - Disables ability to edit phase
 *      - Shows Signing Components
 *
 * Add
 * - Common Component
 *
 * Edit
 * - Common Component
 *
 * Common Component {
 * - Banners Component
 *   - Patient
 *   - Insurance
 *
 * - Pending Procedures Component
 * - Phase Block Component
 * - Financial Options, Estimated Totals, Individual Benefits
 *
 *
 * - Additional Tables Component {
 *      - Reffered
 *      - Declined
 *      - PreAuth Tables
 *   }
 * }
 */

export default AddTreatmentPlan;
