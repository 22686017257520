import dentalApi from "api/dental.api";
import axios, { AxiosResponse } from "axios";
import { SignalRMessage, connection } from "./useSignalr";

export enum SignalRGroup {
    Tenant = '', //Doesn't technically have a group, so just pass empty string
    General = 'General',
    Clinical = 'Clinical',
}

export const signalRMessageToGroupMap: Record<SignalRMessage, SignalRGroup> = {
    //Universal messages:
    ClosedBatch: SignalRGroup.General,
    HardClosedBatch: SignalRGroup.General,
    UpdatedEncounterCorrespondence: SignalRGroup.General,

    //Clinical messages:
    NewAppointment: SignalRGroup.Clinical,
    UpdatedPatientAppointmentAllocation: SignalRGroup.Clinical,
    UpdatedEncounter: SignalRGroup.Clinical,
    CancelledAppointment: SignalRGroup.Clinical,
    UpdatedClinicalAlert: SignalRGroup.Clinical,
    UpdatedMedication: SignalRGroup.Clinical,
    UpdatedChartTreatmentPlan: SignalRGroup.Clinical,
    UpdatedPatientContact: SignalRGroup.Clinical,
    UpdatedAudit: SignalRGroup.Clinical,
    LedgerCalculationUpdate: SignalRGroup.Clinical,
    UpdatedEncounterStatus: SignalRGroup.Clinical,
    NewPatientSourceId: SignalRGroup.Clinical,

    //Worklist messages:
    DeletedPredeterminationProcedurePlanView: SignalRGroup.Tenant,
    UpdatedPredeterminationProcedurePlanView: SignalRGroup.Tenant,
    DeletedAmendRequiredEncounterView: SignalRGroup.Tenant,
    UpdatedAmendRequiredEncounterView: SignalRGroup.Tenant,
    DeletedOpenBilledEncounterView: SignalRGroup.Tenant,
    UpdatedOpenBilledEncounterView: SignalRGroup.Tenant,
    DeletedReBillOnHoldEncounterView: SignalRGroup.Tenant,
    UpdatedReBillOnHoldEncounterView: SignalRGroup.Tenant,
    DeletedReadyToReviewEncounterView: SignalRGroup.Tenant,
    UpdatedReadyToReviewEncounterView: SignalRGroup.Tenant,
    DeletedCorrectionsAmendEncounterView: SignalRGroup.Tenant,
    UpdatedCorrectionsAmendEncounterView: SignalRGroup.Tenant,
    DeletedCorrectionsNeededEncounterView: SignalRGroup.Tenant,
    UpdatedCorrectionsNeededEncounterView: SignalRGroup.Tenant,
    DeletedCorrectionsCompletedEncounterView: SignalRGroup.Tenant,
    DeletedOutstandingCheckInAppointmentView: SignalRGroup.Tenant,
    UpdatedCorrectionsCompletedEncounterView: SignalRGroup.Tenant,
    UpdatedOutstandingCheckInAppointmentView: SignalRGroup.Tenant,
    DeletedAwaitingAttestationAppointmentView: SignalRGroup.Tenant,
    DeletedOutstandingCheckoutAppointmentView: SignalRGroup.Tenant,
    UpdatedAwaitingAttestationAppointmentView: SignalRGroup.Tenant,
    UpdatedOutstandingCheckoutAppointmentView: SignalRGroup.Tenant,
    UpdatedRefundView: SignalRGroup.Tenant,
    DeletedRefundView: SignalRGroup.Tenant,
}

//Unsub from group calls cannot be made automatically when a component is unloaded. 
//If a higher level component subs, then a lower comp also subs on the same message, 
//what happens if the lower comp is unloaded and unsubed from? The higher level component was not unloaded,
//but the sub still is needed at that level for other components. Unsub should be manually called only when needed.

//Subscription messages can be made automatically. Since we can check if we are currently subscribed to a group,
//we can call sub as may times as we want from any level.
export class SignalRGroupSubscriptionService {
    private groupConnections: Set<SignalRGroup> = new Set();
    private connectionId: string | undefined;

    private processQueue: (() => Promise<AxiosResponse<any>[]>)[] = [];
    private isProcessing = false;

    private MAX_QUEUE_LENGTH = 25; //Max number of async queue requests.
    private MAX_RETRIES = 4; //Max number of retries for a given sub/unsub request.
    private RETRY_DELAY = 2000; //Time between retries in milliseconds.

    public set setConnectionId(connectionId: string | undefined) {
        if (this.groupConnections.size) this.groupConnections.clear();
        this.connectionId = connectionId;

        if (connectionId)
            //When the connection is established. Begin processing group subscription/unsubscription requests.
            this.processAll();
    }

    public subscribeToGroupsByMessages(tenantId: string, messages: SignalRMessage[]) {
        if(!tenantId) return;
        if (this.processQueue.length + 1 > this.MAX_QUEUE_LENGTH) {
            //Assume that we have a lost/slow connection if we are not processing requests fast enough, and unload the queue. (Prevent infinite growth of the queue)
            //Unlikely to be triggered
            this.unloadProcessQueue();
        }
        this.processQueue.push(() => this._subscribeToGroups(tenantId, messages.map(message => signalRMessageToGroupMap[message])));
        this.processAll();
    }

    public unsubscribeFromGroups(tenantId: string, groups: SignalRGroup[]) {
        if(!tenantId) return;
        if (this.processQueue.length + 1 > this.MAX_QUEUE_LENGTH) {
            //Assume that we have a lost/slow connection if we are not processing requests fast enough, and unload the queue. (Prevent infinite growth of the queue)
            //Unlikely to be triggered
            this.unloadProcessQueue();
        }
        this.processQueue.push(() => this._unsubscribeFromGroups(tenantId, groups));
        this.processAll();
    }

    private unloadProcessQueue() {
        this.processQueue = [];
    }

    private async processAll() {
        if (this.isProcessing || !this.connectionId) return;
        this.isProcessing = true;

        while (this.processQueue.length) {
            await this.processNext();
        }

        this.isProcessing = false;
    }

    private async processNext() {
        await this.processQueue[0]();
        this.processQueue.shift();
    }

    private async waitToRetry() {
        return await new Promise((resolve) => setTimeout(resolve, this.RETRY_DELAY));
    }

    private async _subscribeToGroups(tenantId: string, groups: SignalRGroup[]) {
        if (!groups.length) return [];

        const filteredGroups = [...new Set(groups)].filter(group => !this.groupConnections.has(group));
        const groupSubscribeRequests = filteredGroups.map(group => dentalApi.getSubscribeToGroup(tenantId, connection.connectionId, group))

        if (!groupSubscribeRequests.length) return [];

        //If we fail to connect to a group, then we will retry three times, each request delayed by the retry delay.
        let retries = this.MAX_RETRIES;

        while (retries > 0) {
            try {
                const res = await axios.all(groupSubscribeRequests);
                filteredGroups.forEach(group => this.groupConnections.add(group));
                //console.log(`Subscribed to groups: ${filteredGroups.join(", ")}. (TenantId: ${tenantId})`);
                //console.log('Connected groups', [...this.groupConnections])
                return res;
            } catch (e) {
                console.error(`Failed to subscribe group(s): ${filteredGroups.join(", ")}. (TenantId: ${tenantId}): ${e as string}`)
            }
            await this.waitToRetry();
            retries--;
        }

        return []
    }

    private async _unsubscribeFromGroups(tenantId: string, groups: SignalRGroup[]) {
        if (!tenantId || !groups.length) return [];

        const filteredGroups = [...new Set(groups)].filter(group => this.groupConnections.has(group));
        const groupUnubscribeRequests = filteredGroups.map(group => dentalApi.getUnsubscribeFromGroup(tenantId, connection.connectionId, group))

        if (!groupUnubscribeRequests.length) return [];

        //If we fail to disconnect from a group, then we will retry three times, each request delayed by the retry delay.
        let retries = this.MAX_RETRIES;

        while (retries > 0) {
            try {
                const res = await axios.all(groupUnubscribeRequests);
                filteredGroups.forEach(group => this.groupConnections.delete(group));
                //console.log(`Unsubscribed from groups: ${filteredGroups.join(", ")}. (TenantId: ${tenantId})`);
                //console.log('Connected groups', [...this.groupConnections])
                return res
            } catch (e) {
                console.error(`Failed to unsubscribed group(s): ${filteredGroups.join(", ")}. (TenantId: ${tenantId}): ${e as string}`)
            }
            await this.waitToRetry();
            retries--;
        }

        return []
    }
}
