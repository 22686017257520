import IWorkListView from 'api/models/worklist-view.model';
import EditPatientPanel from 'components/EditPatientPanel';
import { useSelector } from 'hooks';
import { RouteParams } from 'interfaces/route-params';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updateWorkListDataItem } from 'state/slices/admin-huddle/worklists/worklist.actions';
import {
    selectCurrentWorkList,
    selectWorkListEditItem,
    selectWorkListOpenLayers,
} from 'state/slices/admin-huddle/worklists/worklist.selectors';
import { setWorkListLayerOpen } from 'state/slices/admin-huddle/worklists/worklist.slice';
import { selectEditPatient } from 'state/slices/edit-patient/edit-patient.selectors';

export default function WorkListEditPatientPanel() {
    const { tenantId } = useParams<RouteParams>();
    const workList = useSelector(selectCurrentWorkList);
    const patient = useSelector(selectEditPatient);
    const editItem = useSelector(selectWorkListEditItem<IWorkListView>);

    const dispatch = useDispatch();
    const isOpen = !!useSelector(selectWorkListOpenLayers)['EditPatientPanel'];

    return (
        <EditPatientPanel
            onSaveEditPatient={async () => {
                if (workList && patient && editItem) {
                    dispatch(updateWorkListDataItem({ tenantId, workList, patient, recordId: editItem.id }));
                }
            }}
            isOpen={isOpen}
            onDismiss={() => dispatch(setWorkListLayerOpen({ layer: 'EditPatientPanel', isOpen: false }))}
        />
    );
}
