import { Dropdown, IDropdownOption, Stack } from '@fluentui/react';
import {
    AdjustmentDetailsGroup,
    AdjustmentSource,
    AdjustmentsSummaryReportQuery,
    adjustmentSources,
} from 'api/models/adjustments-summary-report-query.model';
import { ReportType } from 'api/models/embed-report.model';
import { Field } from 'components';
import { useSelector, useTenantId } from 'hooks';
import { getValidationError } from 'hooks/useValidation';
import { map, sortBy } from 'lodash';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import reportActionLookup from 'state/slices/reporting/reportActionLookup';
import { getAdjustmentReasonsForReports, getAllLocationsOfCareForReports } from 'state/slices/reporting/reporting.actions';
import {
    selectAdjustmentReasonsFromReporting,
    selectLocationsOfCareFromReporting,
    selectReportProperties,
    selectReportingValidationErrors,
} from 'state/slices/reporting/reporting.selectors';
import { setAdjustmentDetailsSummaryProp, toggleAdjDetailsSummaryListId } from 'state/slices/reporting/reporting.slice';
import { proceduresOptions } from 'state/slices/tenant/procedures.slice';
import { selectTreatingProviderOptions } from 'state/slices/tenant/providers.slice';
import { classicDateOnly } from 'utils/dateOnly';
import ReportWrapper from '../../ReportWrapper';
import { reportingGroupOptions } from 'state/slices/reporting/reporting-groups/reporting-groups.selectors';
import { getTenantReportingGroups } from 'state/slices/reporting/reporting-groups/reporting-groups.actions';
import { selectTenantPayerOptions } from 'state/slices/tenant/tenant-payers.slice';

type Props = {
    type: ReportType.AdjustmentDetails | ReportType.AdjustmentSummary;
};

export default function AdjustmentSummaryAndDetails({ type }: Props) {
    const tenantId = useTenantId();
    const dispatch = useDispatch();

    const errors = useSelector(selectReportingValidationErrors);
    const locationsOfCare = useSelector(selectLocationsOfCareFromReporting);
    const _providerOptions = useSelector(selectTreatingProviderOptions);
    const _payerOptions = useSelector(selectTenantPayerOptions);
    const _procedureOptions = useSelector(proceduresOptions);
    const _adjustmentReasons = useSelector(selectAdjustmentReasonsFromReporting);
    const _groupByOptions = useSelector(reportingGroupOptions);

    const query = useSelector(selectReportProperties) as AdjustmentsSummaryReportQuery | undefined;

    useEffect(() => {
        dispatch(getAllLocationsOfCareForReports({ tenantId }));
        dispatch(getTenantReportingGroups({ tenantId }));
        if (!_adjustmentReasons.length) dispatch(getAdjustmentReasonsForReports());
    }, []);

    const locationsOfCareOptions: IDropdownOption[] = locationsOfCare.map((loc) => ({
        key: loc.id,
        text: loc.displayName,
    }));

    const adjustmentReasonOptions: IDropdownOption[] = sortBy(
        _adjustmentReasons.map((reason) => ({
            key: reason.id,
            text: reason.displayName,
        })),
        'text',
    );

    const adjustmentSourceOptions: IDropdownOption[] = sortBy(
        map(adjustmentSources, (source, key) => ({
            key: key,
            text: AdjustmentSource[key],
        })),
        'text',
    );

    const adjustmentDetailsGroupOptions: IDropdownOption[] = sortBy(
        map(AdjustmentDetailsGroup, (group) => ({
            key: group,
            text: group,
        })),
        'text',
    );

    const onViewReport = () => {
        if (query) dispatch(reportActionLookup[type]({ tenantId, ...query }));
    };

    return (
        <ReportWrapper
            validationConfig={[
                {
                    fieldName: 'Start Date',
                    validation: ['required'],
                    value: query?.startDate,
                },
                {
                    fieldName: 'End Date',
                    validation: ['required'],
                    value: query?.endDate,
                },
                {
                    fieldName: 'Adjustment Source',
                    validation: ['required'],
                    value: query?.adjustmentSource,
                },
            ]}
            onViewReport={onViewReport}
        >
            <Stack grow horizontal wrap tokens={{ childrenGap: 20 }}>
                <Stack.Item>
                    <Field.Date
                        label="Start Date"
                        required
                        hasDatePicker
                        onChange={(ev, value) => {
                            const newDate = value ? classicDateOnly(value, 'yyyy-MM-dd') : undefined;
                            dispatch(setAdjustmentDetailsSummaryProp({ path: 'startDate', value: newDate }));
                        }}
                        value={query?.startDate ? classicDateOnly(query?.startDate, 'MM/dd/yyyy') : ''}
                        errorMessage={getValidationError(errors, 'Start Date') ? 'Start date is required.' : undefined}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.Date
                        label="End Date"
                        required
                        isReasonable
                        hasDatePicker
                        value={query?.endDate ? classicDateOnly(query?.endDate, 'MM/dd/yyyy') : ''}
                        onChange={(ev, value) => {
                            const newDate = value ? classicDateOnly(value, 'yyyy-MM-dd') : undefined;
                            dispatch(setAdjustmentDetailsSummaryProp({ path: 'endDate', value: newDate }));
                        }}
                        disabled={!query?.startDate}
                        minReasonableErrorMessage="End date must be after start date."
                        minReasonableDate={query?.startDate ? new Date(classicDateOnly(query?.startDate)) : undefined}
                        errorMessage={getValidationError(errors, 'End Date') ? 'End date is required.' : undefined}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Dropdown
                        label="Adjustment Source"
                        placeholder="(Select Adjustment Source)"
                        options={adjustmentSourceOptions}
                        onChange={(ev, option) => {
                            if (option) {
                                dispatch(
                                    setAdjustmentDetailsSummaryProp({
                                        path: 'adjustmentSource',
                                        value: option.key,
                                    }),
                                );
                            }
                        }}
                        selectedKey={query?.adjustmentSource}
                        required
                        errorMessage={
                            getValidationError(errors, 'Adjustment Source') ? 'Adjustment source is required.' : undefined
                        }
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.SearchCombo
                        placeholder="(Select Adjustment Type)"
                        label="Adjustment Types"
                        multiSelect
                        style={{ minWidth: 300 }}
                        options={adjustmentReasonOptions}
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(toggleAdjDetailsSummaryListId({ id: option.key as string, path: 'adjustmentTypes' }));
                        }}
                        selectedKey={query?.adjustmentTypes ?? ''}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.SearchCombo
                        placeholder="(Select Locations of Care)"
                        label="Locations of Care"
                        multiSelect
                        style={{ minWidth: 300 }}
                        options={locationsOfCareOptions}
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(toggleAdjDetailsSummaryListId({ id: option.key as string, path: 'locationOfCareIds' }));
                        }}
                        selectedKey={query?.locationOfCareIds}
                    />
                </Stack.Item>
            </Stack>

            <Stack grow horizontal wrap tokens={{ childrenGap: 20 }}>
                <Stack.Item>
                    <Field.SearchCombo
                        placeholder="(Select Provider)"
                        label="Providers"
                        multiSelect
                        style={{ minWidth: 300 }}
                        options={_providerOptions}
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(
                                    toggleAdjDetailsSummaryListId({ id: option.key as string, path: 'treatingProviderIds' }),
                                );
                        }}
                        selectedKey={query?.treatingProviderIds}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.SearchCombo
                        placeholder="(Select Carriers)"
                        label="Carrier"
                        multiSelect
                        options={_payerOptions}
                        style={{ minWidth: 300 }}
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(
                                    toggleAdjDetailsSummaryListId({ id: option.key as string, path: 'insuranceCarrierIds' }),
                                );
                        }}
                        selectedKey={query?.insuranceCarrierIds}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.SearchCombo
                        placeholder="(Select Procedures)"
                        label="Procedures"
                        options={_procedureOptions}
                        multiSelect
                        maxResults={100}
                        styles={{ callout: { maxWidth: 475 } }}
                        style={{ minWidth: 300 }}
                        onChange={(ev, option) => {
                            if (option?.key) {
                                dispatch(toggleAdjDetailsSummaryListId({ id: option.key as string, path: 'procedureIds' }));
                            }
                        }}
                        selectedKey={query?.procedureIds}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Dropdown
                        label="Group Column 1"
                        placeholder="(Select Group Column)"
                        options={[
                            { key: '', text: '(Select Group Column)' },
                            ..._groupByOptions,
                            ...adjustmentDetailsGroupOptions,
                        ]}
                        style={{ minWidth: 300 }}
                        onChange={(ev, option) => {
                            if (option) {
                                dispatch(
                                    setAdjustmentDetailsSummaryProp({
                                        path: 'groupCol1',
                                        value: option.key,
                                    }),
                                );
                            }
                        }}
                        selectedKey={query?.groupCol1}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Dropdown
                        label="Group Column 2"
                        placeholder="(Select Group Column)"
                        options={[{ key: '', text: '(Select Group Column)' }, ...adjustmentDetailsGroupOptions]}
                        onChange={(ev, option) => {
                            if (option) {
                                dispatch(
                                    setAdjustmentDetailsSummaryProp({
                                        path: 'groupCol2',
                                        value: option.key,
                                    }),
                                );
                            }
                        }}
                        style={{ minWidth: 300 }}
                        selectedKey={query?.groupCol2}
                    />
                </Stack.Item>
            </Stack>
        </ReportWrapper>
    );
}
