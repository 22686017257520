import { IChartProcedure } from 'api/models/chart.model';
import { ConflictRuleType } from 'api/models/lookup.model';
import { IPipelineError, PipelineError } from '../pipeline';
import ProcedureConflictRulesPipeline from '../procedureConflictRules.pipeline';
import { createRule } from '../ruleGenerator';

const ProcedureAnteriorOnly = createRule<ProcedureConflictRulesPipeline, IChartProcedure>({
    ruleTypes: [ConflictRuleType.anteriorOnly],
    rule: (pipeline, item) => {
        let newProcedure = { ...item };
        const _errors: IPipelineError[] = [];
        if (item.toothIds) {
            item.toothIds.forEach((id) => {
                if (pipeline.getIsToothPosterior(id)) {
                    newProcedure = pipeline.removeToothIdFromChartProcedure(newProcedure, id);
                    _errors.push({ type: PipelineError.AnteriorOnly, data: pipeline.getProcedure(item) });
                }
            });
        }

        return { shouldRemoveItem: !newProcedure.toothIds?.length, errors: _errors, updatedItems: newProcedure };
    },
});

export default ProcedureAnteriorOnly;
