import { IChartDentition } from 'api/models/chart-dentition.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { ChartEndpoint } from '../chart/chart.types';

export type ChartDentitionState = ChartEndpoint<IChartDentition> & { savingDentitions: LoadingStatus };

export const chartDentitionsInitialState: ChartDentitionState = {
    data: [],
    loading: LoadingStatus.Idle,
    saving: LoadingStatus.Idle,
    savingDentitions: LoadingStatus.Idle,
};
