import { PayloadAction, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import IMedication from 'api/models/medications.model';
import { createOrUpdatePatientMedicationView, updatePatientMedication } from './medications.actions';
import IPatientMedicationsView, { IPatientChartMedication } from 'api/models/patient-medication.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { findIndex, uniqBy } from 'lodash';
import { PatientState } from '../patient.state';

const medicationReducers = {
    addChartMedication: (state: PatientState, action: PayloadAction<IMedication>): void => {
        const medication = action.payload;

        const medicationToAdd: IPatientChartMedication = {
            id: medication.id,
            medicationName: medication.displayName,
            medicationId: medication.references?.athenaOne,
            isDeleted: false,
            encounterId: '', // empty encounterId createUpdate action handles adding this
            modifiedOn: new Date().toISOString(),
        };
        state.medications.patientMedicationsToAdd = [...state.medications.patientMedicationsToAdd, medicationToAdd];
    },
    removeChartMedication: (
        state: PatientState,
        action: PayloadAction<{ medicationToRemove: IPatientChartMedication }>,
    ): void => {
        const { medicationToRemove } = action.payload;
        state.medications.patientMedicationsToAdd = state.medications.patientMedicationsToAdd.filter(
            (mediation) => mediation.id !== medicationToRemove.id,
        );
    },
    updateChartMedicationField: (
        state: PatientState,
        action: PayloadAction<{
            medication: IPatientChartMedication;
            path: 'unstructuredSignature' | 'startDate' | 'stopDate'; // These are the only editable field. Gotcha
            value: string | undefined; // Do we have the post/put actions created
        }>,
    ): void => {
        const { medication, path, value } = action.payload;

        const medicationIndex = findIndex(state.medications.patientMedicationsToAdd, ['id', medication.id]);
        state.medications.patientMedicationsToAdd[medicationIndex][path] = value;
    },
    setPatientMedicationsPanelOpen: (state: PatientState, action: PayloadAction<boolean>): void => {
        state.medications.isMedicationPanelOpen = action.payload;
        if (!action.payload) {
            state.medications.patientMedicationsToAdd = [];
        }
    },
    setShowMedications: (state: PatientState, { payload }: PayloadAction<boolean>): void => {
        state.medications.showMedicationHistory = payload;
    },
    setMedicationModal: (
        state: PatientState,
        action: PayloadAction<{ medication?: IPatientChartMedication; setModal: boolean }>,
    ): void => {
        const { medication, setModal } = action.payload;

        state.medications.isMedicationStopModalOpen = setModal;
        if (medication) {
            state.medications.medicationToUpdate = { ...medication, ['stopDate']: new Date().toISOString() };
        }
    },
    updateMedicationField: (
        state: PatientState,
        action: PayloadAction<{
            key: keyof IPatientChartMedication;
            value: string | boolean | number;
        }>,
    ): void => {
        const { key, value } = action.payload;
        if (state.medications.medicationToUpdate) {
            state.medications.medicationToUpdate = { ...state.medications.medicationToUpdate, [key]: value };
        }
    },
    updateMedicationsView: (state: PatientState, { payload }: PayloadAction<IPatientMedicationsView>): void => {
        if (state.medications.data && state.medications.data.id === payload.id) {
            state.medications.data = {
                ...state.medications.data,
                //Only replace data from payload that exist. Some props are null from signalr message
                id: payload.id,
                isDeleted: payload.isDeleted,
                medications: state.medications.data.medications
                    ? uniqBy([...(payload.medications ?? []), ...state.medications.data.medications], 'id')
                    : payload.medications,
            };
        }
    },
};

export const medicationsExtraReducers = (builder: ActionReducerMapBuilder<PatientState>): ActionReducerMapBuilder<PatientState> =>
    builder
        .addCase(createOrUpdatePatientMedicationView.pending, (state) => {
            state.medications.saving = LoadingStatus.Pending;
            state.medications.loading = LoadingStatus.Pending;
        })
        .addCase(createOrUpdatePatientMedicationView.fulfilled, (state, action) => {
            state.medications.data = action.payload;
            state.medications.saving = LoadingStatus.Completed;
            state.medications.loading = LoadingStatus.Completed;
            state.medications.isMedicationPanelOpen = false;
            state.medications.patientMedicationsToAdd = [];
        })
        .addCase(createOrUpdatePatientMedicationView.rejected, (state) => {
            state.medications.saving = LoadingStatus.Failed;
            state.medications.loading = LoadingStatus.Failed;
        })
        .addCase(updatePatientMedication.pending, (state) => {
            state.medications.saving = LoadingStatus.Pending;
            state.medications.loading = LoadingStatus.Pending;
        })
        .addCase(updatePatientMedication.fulfilled, (state, action) => {
            state.medications.data = action.payload;
            state.medications.saving = LoadingStatus.Completed;
            state.medications.loading = LoadingStatus.Completed;
            state.medications.isMedicationStopModalOpen = false;
            state.medications.medicationToUpdate = undefined;
        })
        .addCase(updatePatientMedication.rejected, (state) => {
            state.medications.saving = LoadingStatus.Failed;
            state.medications.loading = LoadingStatus.Failed;
        });

export default medicationReducers;
