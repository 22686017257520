import { IChartProcedure } from 'api/models/chart.model';
import { createRule } from '../../ruleGenerator';
import ProcedureCodes from '../../procedureCodes';
import ProcedureBusinessRulesPipeline from '../procedureBusinessRules.pipeline';
import { getQuadrantOfTooth } from 'pages/Charting/components/ToothCanvas/spriteList';
import { find, flatten, forEach, uniq } from 'lodash';
import toothQuadProcedureCodeLookup from '../toothQuadrantProcedureCodeLookup';
import { v4 as uuid } from 'uuid';
import { getQuadAreaFromTeeth } from '../../procedureApplicableAreasPipeline';

export const minTeethPerQuadProcedureRuleCodes = [
    ProcedureCodes.D4240,
    ProcedureCodes.D4241,
    ProcedureCodes.D4260,
    ProcedureCodes.D4261,
    ProcedureCodes.D4341,
    ProcedureCodes.D4342,
    ProcedureCodes.D7310,
    ProcedureCodes.D7311,
    ProcedureCodes.D7320,
    ProcedureCodes.D7321,
];

const minTeethPerQuadProcedureRule = createRule<ProcedureBusinessRulesPipeline, IChartProcedure>({
    ruleTypes: minTeethPerQuadProcedureRuleCodes,
    rule: (pipeline, item) => {
        if (item) {
            const newProcedures: IChartProcedure[] = [];
            const appliedTeeth = uniq(flatten(item.toothIds?.filter((id) => id !== undefined)) as number[]);
            const teethPerQuad: { [key: string]: number[] } = {};

            //Organize toothids from the given chart procedure into their respective quadrants
            forEach(appliedTeeth, (tooth) => {
                const quad = getQuadrantOfTooth(tooth);
                if (quad)
                    teethPerQuad[quad] = teethPerQuad[quad]?.length
                        ? (teethPerQuad[quad] = [...teethPerQuad[quad], tooth])
                        : [tooth];
            });

            // We need to group the teeth that are a selected by quad.
            // Then discover how many teeth are selected in each quad.
            // Possibly change the procedure that is being charted based on number of teeth in that quadrant.
            // return new updated or added procedures.
            let indexOfTeethPerQuad = 0;
            forEach(teethPerQuad, (teeth) => {
                const procedure = pipeline.getProcedure(item);

                const keyedProcArray = find(
                    toothQuadProcedureCodeLookup,
                    (codes) => codes.findIndex((obj) => obj.code === procedure?.code) > -1,
                );

                if (keyedProcArray && teeth.length) {
                    const codeObj = find(keyedProcArray, (proc) => proc.minToothCountPerQuad === teeth.length);

                    const maxToothCount = Math.max(...keyedProcArray.map((p) => p.minToothCountPerQuad));
                    const isMore = teeth.length > maxToothCount;
                    const moreProcedure = find(keyedProcArray, (proc) => (proc.orMore ? true : false));

                    const toothProcedureCode = isMore ? moreProcedure : codeObj;

                    const procedure = pipeline.getProcedureFromCode(toothProcedureCode?.code);

                    const firstOriginalProcedure = pipeline.originalProcedures[0];

                    const newChartProcedure: IChartProcedure = {
                        ...item,
                        id: indexOfTeethPerQuad === 0 ? item.id : uuid(),
                        toothIds: teeth,
                        procedureId: procedure ? procedure.id : firstOriginalProcedure.id,
                    };

                    const quad = getQuadAreaFromTeeth(newChartProcedure);
                    if (quad) newChartProcedure.areas = [quad];

                    newProcedures.push(newChartProcedure.procedureId ? newChartProcedure : item);
                } else {
                    return { updatedItems: item };
                }
                indexOfTeethPerQuad++;
            });
            return { updatedItems: newProcedures };
        }

        return { updatedItems: item };
    },
});

export default minTeethPerQuadProcedureRule;
