import { createAsyncThunk } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import { IPatientEncounterWithAppointment, IPatientEncounter, ProviderIdType } from 'api/models/encounter.model';
import IPatientAppointment from 'api/models/Scheduling/patientAppointment.model';
import schedulingApi from 'api/scheduling.api';
import { clone, orderBy, uniq } from 'lodash';
import { TrackerStatus } from 'pages/Scheduling/components/TrackerStatusDropdown';
import { AppThunk, RootState } from 'state/store';
import {
    cleanupChartPatientEncounterAppointment,
    cleanupPatientEncounter,
    setEncounterDataProp,
    setEncounterUserIdentity,
    setPatientEncounter,
    updateIncompleteEncounter,
} from './encounter.slice';
import axios from 'axios';
import UpdatedEncounter from 'api/models/updated-encounter.model';
import { push } from 'connected-react-router';
import { batch } from 'react-redux';
import { selectIncompleteEncounters } from './encounter.selectors';
import IWorkListEncounterView from 'api/models/worklist-encounter-view';
import { getMissingUserIdentities } from '../users-identities/user-identities.actions';
import { selectUserIdentitiesData } from '../users-identities/user-identities.selectors';
import IPatientEncounterWithTasks from 'api/models/patient-encounter-with-tasks.model';

export const getCurrentPatientEncounterAppointment = createAsyncThunk<
    IPatientAppointment,
    {
        tenantId: string;
        encounterId: string;
    }
>('getCurrentPatientEncounterAppointment', async ({ tenantId, encounterId }) => {
    const appointmentsRes = await dentalApi.getPatientAppointmentByEncounterId(tenantId, encounterId);

    const currentEncounterAppointment = orderBy(
        appointmentsRes.data.filter((p) => p.encounterId === encounterId),
        ['date', 'startTime'],
        ['desc'],
    )[0];

    return currentEncounterAppointment;
});

export const markEncountersAsBilled = createAsyncThunk<
    { data: IWorkListEncounterView[]; encounterIds: string[] },
    {
        tenantId: string;
        encounterIds: string[];
        batchId?: string;
    }
>('markEncountersAsBilled', async ({ tenantId, encounterIds, batchId }) => {
    let data: IWorkListEncounterView[] = [];
    if (!batchId) {
        const { data: responseData } = await dentalApi.markEncountersAsBilled(tenantId, encounterIds);
        data = responseData;
    } else {
        const { data: responseData } = await dentalApi.markEncountersAsBilledWithBatch(tenantId, encounterIds, batchId);
        data = responseData;
    }

    return { data, encounterIds };
});

export const getPatientEncounterWithTasks = createAsyncThunk<
    IPatientEncounterWithTasks,
    {
        tenantId: string;
        patientId: string;
        encounterId: string;
    },
    { state: RootState }
>('getPatientEncounterWithTasks', async ({ tenantId, patientId, encounterId }, { dispatch, getState }) => {
    const response = await dentalApi.getPatientEncounterWithTasks(tenantId, patientId, encounterId);

    const encounterData = response.data.encounter;

    const userIds = [
        encounterData?.allergiesReviewedBy,
        encounterData?.problemsReviewedBy,
        encounterData?.medicationsReviewedBy,
    ].filter((d) => (d ? true : false)) as string[];

    if (userIds.length > 0) {
        await dispatch(getMissingUserIdentities({ tenantId, userIds }));
        const userIdentitiesLookup = selectUserIdentitiesData(getState());

        userIds.forEach((userId) => {
            const user = userIdentitiesLookup[userId];
            if (user) dispatch(setEncounterUserIdentity(user));
        });
    }

    dispatch(getEncounterPatientPrescriptions({ tenantId, patientId, encounterId }));

    return response.data;
});

export const getEncounterPatientPrescriptions = createAsyncThunk(
    'getEncounterPatientPrescriptions',
    async ({ tenantId, patientId, encounterId }: { tenantId: string; patientId: string; encounterId: string }) => {
        const res = await dentalApi.getEncounterPatientPrescriptions(tenantId, patientId, encounterId);
        return res.data;
    },
);

export const updatePatientEncounter = createAsyncThunk<
    IPatientEncounter,
    {
        tenantId: string;
        patientId: string;
        encounter: IPatientEncounter;
    }
>('updatePatientEncounter', async ({ tenantId, patientId, encounter }) => {
    const encounterRes = await dentalApi.updatePatientEncounter(tenantId, patientId, encounter);
    return encounterRes.data;
});

export const saveCurrentEncounter =
    (tenantId: string, patientId: string): AppThunk<void> =>
    async (dispatch, getState) => {
        const { patientEncounter } = getState().encounter;
        if (patientEncounter)
            await dispatch(updatePatientEncounter({ tenantId, patientId, encounter: patientEncounter })).unwrap();
    };

export const setCurrentEncounterDataPropAndSave =
    (path: keyof IPatientEncounter, value: string | boolean | undefined, tenantId: string, patientId: string): AppThunk<void> =>
    async (dispatch) => {
        try {
            Promise.resolve(dispatch(setEncounterDataProp({ path, value }))).then(() =>
                dispatch(saveCurrentEncounter(tenantId, patientId)),
            );
        } catch (err) {
            console.log(err);
        }
    };

export type UpdateEncounterProvidersPayload = Partial<Record<ProviderIdType, string>>;

export const updateEncounterProviders = createAsyncThunk<
    IPatientEncounter,
    {
        tenantId: string;
        patientId: string;
        encounterId: string;
        providers: UpdateEncounterProvidersPayload;
    }
>('patientEncounter/updateEncounterProviders', async ({ tenantId, patientId, encounterId, providers }, { rejectWithValue }) => {
    try {
        const encounterRes = await dentalApi.updatePatientEncounterProviderIdsByProviderIdType(
            tenantId,
            patientId,
            encounterId,
            providers,
        );

        return encounterRes.data;
    } catch (e) {
        return rejectWithValue(e);
    }
});

export const getPatientIncompleteEncounters = createAsyncThunk<
    IPatientEncounterWithAppointment[],
    {
        tenantId: string;
        patientId: string;
    }
>('getPatientIncompleteEncounters', async ({ tenantId, patientId }) => {
    const encounterRes = await dentalApi.getPatientIncompleteEncounters(tenantId, patientId);
    return encounterRes.data;
});

export const updateTrackerStatus = createAsyncThunk<
    void,
    {
        appointmentId: string;
        status: TrackerStatus;
        tenantId: string;
        encounterId: string;
    },
    { state: RootState; dispatch: any }
>('updateTrackerStatus', async ({ appointmentId, status, tenantId, encounterId }, { dispatch }) => {
    //Ensure we have the latest appointment :)
    const { data: appointment } = await schedulingApi.getPatientAppointment(tenantId, appointmentId);
    const updateAppointment = clone(appointment);
    updateAppointment.trackerStatusId = status;
    const res = await schedulingApi.updatePatientAppointment(tenantId, updateAppointment);

    if (res.data) {
        dispatch(getCurrentPatientEncounterAppointment({ tenantId, encounterId }));
    }
});

export const updatePatientEncounterReason = createAsyncThunk<
    void,
    {
        appointmentId: string;
        encounterReason: string;
        tenantId: string;
        encounterId: string;
    },
    { state: RootState; dispatch: any }
>('updatePatientEncounterReason', async ({ appointmentId, encounterReason, tenantId, encounterId }, { dispatch }) => {
    //Ensure we have the latest appointment :)
    const { data: appointment } = await schedulingApi.getPatientAppointment(tenantId, appointmentId);
    const updateAppointment = clone(appointment);
    updateAppointment.encounterReason = encounterReason;
    const res = await schedulingApi.updatePatientAppointment(tenantId, updateAppointment);

    if (res.data) {
        dispatch(getCurrentPatientEncounterAppointment({ tenantId, encounterId }));
    }
});

export const updateAppointmentSupervisor = createAsyncThunk<
    void,
    {
        appointment: IPatientAppointment;
        supervisingProviderId: string;
        tenantId: string;
        encounterId: string;
    },
    { state: RootState; dispatch: any }
>('updatePatientEncounterReason', async ({ appointment, supervisingProviderId, tenantId, encounterId }, { dispatch }) => {
    const updateAppointment = clone(appointment);
    updateAppointment.supervisingProviderId = supervisingProviderId;
    const res = await schedulingApi.updatePatientAppointment(tenantId, updateAppointment);

    if (res.data) {
        dispatch(getCurrentPatientEncounterAppointment({ tenantId, encounterId }));
    }
});
export const updateBillingProviderId = createAsyncThunk<
    void,
    {
        appointment: IPatientAppointment;
        billingProviderId: string;
        tenantId: string;
        encounterId: string;
    },
    { state: RootState; dispatch: any }
>('updateBillingProviderId', async ({ appointment, billingProviderId, tenantId, encounterId }, { dispatch }) => {
    const updateAppointment = clone(appointment);
    updateAppointment.billingProviderId = billingProviderId;
    const res = await schedulingApi.updatePatientAppointment(tenantId, updateAppointment);

    if (res.data) {
        dispatch(getCurrentPatientEncounterAppointment({ tenantId, encounterId }));
    }
});

export const deselectPatientEncounter = (): AppThunk<void> => (dispatch, getState) => {
    const location = getState().router.location;
    const splitPathName = location.pathname.split('/');
    const indexOfEncounter = splitPathName.indexOf('encounter');
    if (indexOfEncounter > -1) splitPathName.splice(indexOfEncounter, 2);
    const pathnameWithoutEncounter = splitPathName.join('/');

    batch(async () => {
        await dispatch(push(pathnameWithoutEncounter));
        dispatch(cleanupPatientEncounter());
        dispatch(cleanupChartPatientEncounterAppointment());
    });
};
export const insertEncounter =
    (encounter: UpdatedEncounter): AppThunk<void> =>
    (dispatch, getState) => {
        dispatch(setPatientEncounter(encounter));

        //Handle updating incomplete encounter if it exists.
        //This will handle dropping a user out of a correction amend encounter once it is signed.
        const incompleteEncounters = selectIncompleteEncounters(getState());
        if (incompleteEncounters?.length) {
            const index = incompleteEncounters.findIndex((e) => e.encounter?.id === encounter.id);
            if (index > -1) dispatch(updateIncompleteEncounter({ encounter, index }));
        }
    };
