import { createSlice } from '@reduxjs/toolkit';
import { initialClinicalAlertState } from './clinical-alerts.state';
import { clinicalAlertReducers, clinicalAlertsReducers } from './clincal-alerts.reducers';

const clinicalAlertsSlice = createSlice({
    name: 'clinicalAlerts',
    initialState: initialClinicalAlertState,
    reducers: { ...clinicalAlertsReducers },
    extraReducers: (builder) => {
        clinicalAlertReducers(builder);
    },
});

const { actions, reducer } = clinicalAlertsSlice;

export const {
    cleanupClinicalAlertsForPatients,
    setClinicalAlertValidationErrors,
    addNewClinicalAlert,
    closeCurrentClinicalAlert,
    cleanUpCurrentClinicalAlertData,
    setEditClinicalAlertData,
    setClinicalAlertDataProp,
    upsertPatientClinicalAlert,
} = actions;

export default reducer;
