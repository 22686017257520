import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { IEncounterSummary } from 'api/models/encounter-ledger.model';
import { RelativeDateType } from 'components/Field/RelativeDateRangeField';
import { LedgerState } from '../ledger.state';
import { LedgerClaimsHistoryFilters } from './claim-history.state';
import { getEncounterClaimsSummary } from './claim-history.actions';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { isArray } from 'lodash';
import { EncounterStatus } from 'api/models/encounter.model';
import {
    isWorkListEncounterView,
    saveEncounterCorrespondenceEncounterWorkList,
    saveEncounterStatusEncounterWorkList,
} from 'state/slices/admin-huddle/worklists/encounter-worklists/encounter-worklists.actions';

const claimsHistoryReducers = {
    toggleShowClaimHistoryFilters: (state: LedgerState): void => {
        state.claimHistory.showFilters = !state.claimHistory.showFilters;
    },
    toggleClaimEncounterInsuranceBalanceGreaterThanZeroFilter: (state: LedgerState): void => {
        state.claimHistory.filters.insuranceBalanceGreaterThanZero = !state.claimHistory.filters.insuranceBalanceGreaterThanZero;
    },
    setClaimEncounterDateRangeFilters: (
        state: LedgerState,
        action: PayloadAction<{ startDate?: string; endDate?: string; relativeDateType: RelativeDateType }>,
    ): void => {
        const { startDate, endDate, relativeDateType } = action.payload;

        state.claimHistory.filters.relativeDateType = relativeDateType;
        state.claimHistory.filters.startDate = startDate;
        state.claimHistory.filters.endDate = endDate;
    },
    setClaimHistoryDateFilters: (
        state: LedgerState,
        action: PayloadAction<{
            path: keyof Pick<LedgerClaimsHistoryFilters, 'startDate' | 'endDate'>;
            value: string | undefined;
        }>,
    ): void => {
        const { path, value } = action.payload;
        state.claimHistory.filters[path] = value;
        if (state.claimHistory.filters.relativeDateType) state.claimHistory.filters.relativeDateType = undefined;
    },
    setClaimEncounterBillingProvidersFilter: (state: LedgerState, action: PayloadAction<string>): void => {
        if (!state.claimHistory.filters.billingProviders) {
            state.claimHistory.filters.billingProviders = [action.payload];
        } else {
            const indexOfProvider = state.claimHistory.filters.billingProviders.indexOf(action.payload);
            if (indexOfProvider > -1) {
                state.claimHistory.filters.billingProviders = [
                    ...state.claimHistory.filters.billingProviders.slice(0, indexOfProvider),
                    ...state.claimHistory.filters.billingProviders.slice(indexOfProvider + 1),
                ];
            } else {
                state.claimHistory.filters.billingProviders = [...state.claimHistory.filters.billingProviders, action.payload];
            }
        }
    },
    setSelectedClaimHistoryEncounterSummary: (state: LedgerState, action: PayloadAction<IEncounterSummary | undefined>): void => {
        state.claimHistory.selectedEncounterSummary = action.payload;
    },
    updateSelectedEncounterSummaryStatus: (state: LedgerState, action: PayloadAction<EncounterStatus>): void => {
        if (state.claimHistory.selectedEncounterSummary)
            state.claimHistory.selectedEncounterSummary.encounterStatus = action.payload;
    },
    setClaimHistoryEncounterNoteModalOpen: (state: LedgerState, action: PayloadAction<boolean>): void => {
        state.claimHistory.encounterNoteModalOpen = action.payload;
    },
    clearAllClaimsHistoryFilters: (state: LedgerState): void => {
        state.claimHistory.filters = {};
    },
    cleanupSelectedClaimHistoryEncounterSummary: (state: LedgerState): void => {
        state.claimHistory.selectedEncounterSummary = undefined;
    },
};

export default claimsHistoryReducers;

export const claimHistoryExtraReducers = (builder: ActionReducerMapBuilder<LedgerState>): ActionReducerMapBuilder<LedgerState> =>
    builder
        .addCase(getEncounterClaimsSummary.pending, (state) => {
            state.claimHistory.loadingEncounterClaimSummary = LoadingStatus.Pending;
            state.claimHistory.encounterClaimSummary = undefined;
            state.claimHistory.encounterClaimSummaryError = undefined;
        })
        .addCase(getEncounterClaimsSummary.fulfilled, (state, action) => {
            state.claimHistory.loadingEncounterClaimSummary = LoadingStatus.Completed;
            state.claimHistory.encounterClaimSummary = action.payload;
        })
        .addCase(getEncounterClaimsSummary.rejected, (state, action) => {
            if (action.error.name !== 'AbortError') {
                state.claimHistory.loadingEncounterClaimSummary = LoadingStatus.Failed;
                state.claimHistory.encounterClaimSummaryError = action.error;
            } else {
                state.claimHistory.loadingEncounterClaimSummary = LoadingStatus.Idle;
            }
        })
        .addCase(saveEncounterStatusEncounterWorkList.fulfilled, (state, action) => {
            const encounterView = action.payload;
            if (state.claimHistory.selectedEncounterSummary && encounterView && !isArray(encounterView)) {
                if (isWorkListEncounterView(encounterView)) {
                    if (encounterView.encounter?.status)
                        state.claimHistory.selectedEncounterSummary.encounterStatus = encounterView.encounter?.status;
                } else {
                    if (encounterView?.status)
                        state.claimHistory.selectedEncounterSummary.encounterStatus = encounterView?.status;
                }
            }

            if (state.ledgerSummary && encounterView) {
                const index = state.ledgerSummary.findIndex((view) => view.encounterId === encounterView?.id);
                if (index !== -1)
                    if (isWorkListEncounterView(encounterView)) {
                        state.ledgerSummary[index].encounterStatus = encounterView?.encounter?.status;
                    } else {
                        state.ledgerSummary[index].encounterStatus = encounterView?.status;
                    }
            }
        })
        .addCase(saveEncounterCorrespondenceEncounterWorkList.fulfilled, (state, action) => {
            const encounterCorrespondence = action.payload;
            if (state.claimHistory.encounterClaimSummary?.encounterCorrespondence && encounterCorrespondence)
                state.claimHistory.encounterClaimSummary.encounterCorrespondence = encounterCorrespondence;
        });
