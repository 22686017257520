import { Stack, TeachingBubble } from '@fluentui/react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccountData } from 'state/slices/account.slice';
import {
    selectCurrentTutorial,
    selectCurrentTutorialDataStep,
    selectCurrentTutorialStep,
    selectCurrentTutorialStepPage,
} from 'state/slices/teaching/teaching.selectors';
import { setNextTutorialStep, setPageForCurrentStep, setTutorialComplete } from 'state/slices/teaching/teaching.slice';
import { TutorialSteps } from 'state/slices/teaching/teaching.state';
import * as Components from './components';

export default function Tutorial(): JSX.Element | null {
    const dispatch = useDispatch();

    const account = useSelector(selectAccountData);
    const stepData = useSelector(selectCurrentTutorialDataStep);
    const currentTutorial = useSelector(selectCurrentTutorial);
    const currentPage = useSelector(selectCurrentTutorialStepPage);
    const step = useSelector(selectCurrentTutorialStep);

    const isLastStep = step ? step === TutorialSteps.Three : false;

    const onDismiss = () => {
        if (isLastStep && !!currentTutorial && !!account?.identity?.id) {
            dispatch(setTutorialComplete({ userId: account.identity.id, tutorial: currentTutorial }));
        } else {
            dispatch(setNextTutorialStep(undefined));
        }
    };

    const isMultiPage = stepData?.pages ? stepData.pages > 1 : false;

    useEffect(() => {
        if (isMultiPage && !currentPage) dispatch(setPageForCurrentStep(1));
    }, [isMultiPage]);

    if (!stepData || !currentTutorial) return null;

    const { headline, target, hasCloseButton, isWide, directionalHint, pages } = stepData;
    const Component = Components[currentTutorial];

    const page = currentPage ?? 0;
    const definedPages = pages ?? 0;

    const nextPage = () => {
        if (page < definedPages) dispatch(setPageForCurrentStep(page + 1));
    };

    const previousPage = () => {
        if (page > 1) dispatch(setPageForCurrentStep(page - 1));
    };

    return (
        <TeachingBubble
            calloutProps={{ setInitialFocus: true, directionalHint }}
            focusTrapZoneProps={{
                forceFocusInsideTrap: false,
            }}
            onDismiss={onDismiss}
            headline={headline}
            target={target}
            hasCloseButton={hasCloseButton}
            isWide={isWide}
            primaryButtonProps={isMultiPage ? { text: 'Next', onClick: nextPage } : undefined}
            secondaryButtonProps={isMultiPage ? { text: 'Previous', onClick: previousPage } : undefined}
            footerContent={isMultiPage ? `${currentPage} of ${pages}` : undefined}
        >
            <div style={{ minWidth: '100%' }}>{Component ? <Component /> : null}</div>
        </TeachingBubble>
    );
}
