import { Dictionary } from '@reduxjs/toolkit';
import { IChartProcedure } from 'api/models/chart.model';
import { IDiagnosis } from 'api/models/diagnosis.model';
import { IProcedure } from 'api/models/procedure.model';
import { find, forEach, isArray, map } from 'lodash';
import Pipeline from '../pipeline';
import * as rules from './diagnosis-rules';
import DiagnosisBusinessRuleGenerator from './procedureBusinessRuleGenerator';

type PipelineArgs = {
    newProcedures: IProcedure[];
    chartProceduresList: IChartProcedure[];
    diagnosisData: Dictionary<IDiagnosis>;
    proceduresData: Dictionary<IProcedure>;
    encounterId?: string;
};

export interface IProcedureWithDiagnoses extends IProcedure {
    diagnosisItems: IDiagnosis[];
}
/**
 *
 * This will now handle all auto association of procedure dx.
 * Also handles advanced rules for DX
 *
 * @export
 * @class ProcedureDiagnosisPipeline
 * @extends {Pipeline<IProcedureWithDiagnoses>}
 */
export default class ProcedureDiagnosisPipeline extends Pipeline<IProcedureWithDiagnoses> {
    private diagnosisData: Dictionary<IDiagnosis>;
    private proceduresData: Dictionary<IProcedure>;
    public chartProceduresList: IChartProcedure[];
    public encounterId?: string;

    constructor({ newProcedures, diagnosisData, chartProceduresList, proceduresData, encounterId }: PipelineArgs) {
        super(newProcedures.map((p) => ({ ...p, diagnosisItems: [] })) as IProcedureWithDiagnoses[]);
        this.diagnosisData = diagnosisData;
        this.chartProceduresList = chartProceduresList;
        this.proceduresData = proceduresData;
        this.encounterId = encounterId;

        this.runProcedureBusinessRules();
        this.addAutoApplyDx();
    }
    /**
     * Returns a diagnosis based on a specified dx code.
     *
     * @param {string} [code]
     * @return {*}  {(IDiagnosis | undefined)}
     * @memberof ProcedureDiagnosisPipeline
     */
    public getDXFromCode(code?: string): IDiagnosis | undefined {
        return find(this.diagnosisData, (d) => d?.code === code);
    }

    /**
     * Return IProcedure from specified IChartProcedure
     *
     * @param {IChartProcedure} procedure
     * @return {*}  {(IProcedure | undefined)}
     * @memberof ProcedureDiagnosisPipeline
     */
    public getProcedure(procedure: IChartProcedure): IProcedure | undefined {
        return procedure.procedureId ? this.proceduresData[procedure.procedureId] : undefined;
    }

    /**
     * Return search for a IProcedureWithDiagnoses model
     *
     * @param {IProcedureWithDiagnoses} procedure
     * @return {*}  {(IProcedureWithDiagnoses | undefined)}
     * @memberof ProcedureDiagnosisPipeline
     */
    public getNewProcedure(procedure: IProcedureWithDiagnoses): IProcedureWithDiagnoses | undefined {
        if (!this.items) return undefined;
        return find(this.items, (p) => p?.id === procedure.id);
    }

    /**
     * Handles the auto apply dx functionality
     *
     * @private
     * @memberof ProcedureDiagnosisPipeline
     */
    private addAutoApplyDx() {
        forEach(this.items, (p) => {
            const codes = p.diagnoses ? p.diagnoses : [];
            if (!codes.length) return [];
            const dx =
                this.diagnosisData && !p.diagnosisItems.length
                    ? (map(
                          codes.filter((c) => c.mode === 'auto-apply'),
                          (c) => {
                              const diag = find(this.diagnosisData, (d) => d?.code === c.code);
                              return diag;
                          },
                      ).filter((d) => d !== undefined) as IDiagnosis[])
                    : p.diagnosisItems;

            this.setItems(this.items.map((item) => ({ ...item, diagnosisItems: dx })));
        });
    }

    /**
     * Creates a business rule generator that will create and run rules for the specified procedure.
     *
     * @private
     * @param {IProcedureWithDiagnoses} procedure
     * @return {*}
     * @memberof ProcedureDiagnosisPipeline
     */
    private runBusinessRules(procedure: IProcedureWithDiagnoses) {
        const procedureResult = new DiagnosisBusinessRuleGenerator({ procedure, rules, procedurePipeline: this });
        this.addErrors(procedureResult.getErrors);
        if (!procedureResult.shouldRemoveItem) return procedureResult.getItem;
    }

    /**
     * Handles the setting the pipeline items based on business rules
     *
     * @private
     * @memberof ProcedureDiagnosisPipeline
     */
    private runProcedureBusinessRules() {
        if (this.items) {
            const procedures: IProcedureWithDiagnoses[] = [];
            this.items.forEach((procedure) => {
                const newProcedures = this.runBusinessRules(procedure);
                if (newProcedures) {
                    if (isArray(newProcedures)) {
                        procedures.push(...newProcedures);
                    } else {
                        procedures.push(newProcedures);
                    }
                }
            });
            this.setItems(procedures);
        }
    }
}
