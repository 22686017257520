import { useSelector } from 'react-redux';
import { selectUACDistributionTreatmentPlanAmounts } from 'state/slices/ledger/uac-distribution/uac-distribution.selectors';
import { usdCurrencyFormatter } from 'utils';
import UACDistributionCard, { UACDistributionCardProps } from './UACDistributionCard';
import {
    selectSignedTreatmentPlanView,
    selectTreatmentPlanViewLoading,
} from 'state/slices/ledger/treatment-plan-credit-and-uac/treatment-plan-credit-and-uac.selectors';
import { LoadingStatus } from 'interfaces/loading-statuses';

export default function UACTreatmentPlanRemainingCard({ onLinkClick }: Pick<UACDistributionCardProps, 'onLinkClick'>) {
    const loading = useSelector(selectTreatmentPlanViewLoading);
    const signedTP = useSelector(selectSignedTreatmentPlanView);
    const { treatmentPlanRemainingAmount, treatmentPlanRemainingTotalBalance, treatmentPlanRemainingTotalPayment } = useSelector(
        selectUACDistributionTreatmentPlanAmounts,
    );

    if (!signedTP && loading !== LoadingStatus.Pending) return null;

    return (
        <UACDistributionCard onLinkClick={onLinkClick} title="Treatment Plan Remaining" loading={loading}>
            <span>Total: {usdCurrencyFormatter.format(treatmentPlanRemainingTotalBalance ?? 0)}</span>
            <span>Distributed: {usdCurrencyFormatter.format(treatmentPlanRemainingTotalPayment ?? 0)}</span>
            <span>Remaining: {usdCurrencyFormatter.format(treatmentPlanRemainingAmount ?? 0)}</span>
        </UACDistributionCard>
    );
}
