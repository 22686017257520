import { MessageBar, MessageBarType } from '@fluentui/react';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { useCallback } from 'react';

type SavingMessageBarConfig = Partial<Record<LoadingStatus, string>>;

type Props = {
    saving: LoadingStatus;
    messageConfig: SavingMessageBarConfig;
    onDismiss?: () => void;
};

export default function SaveStatusMessageBar({ saving, onDismiss, messageConfig }: Props) {
    const _onDismiss = () => {
        if (onDismiss) onDismiss();
    };

    const getMessageBarConfig = useCallback(() => {
        const messageBarLookup: Record<LoadingStatus, { type: MessageBarType; message: string } | undefined> = {
            [LoadingStatus.Idle]: undefined,
            [LoadingStatus.Completed]: {
                type: MessageBarType.success,
                message: messageConfig.completed === undefined ? '' : messageConfig.completed,
            },
            [LoadingStatus.Failed]: {
                type: MessageBarType.error,
                message: messageConfig.failed === undefined ? '' : messageConfig.failed,
            },
            [LoadingStatus.Pending]: undefined,
        };

        return messageBarLookup;
    }, [messageConfig]);

    const messageBarData = getMessageBarConfig()[saving];
    if (!messageBarData) return null;

    return (
        <MessageBar onDismiss={_onDismiss} messageBarType={messageBarData.type}>
            {messageBarData.message}
        </MessageBar>
    );
}
