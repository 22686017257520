import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { IUserIdentity } from 'api/models/account.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { getUserIdentities } from './user-identities.actions';
import { UsersState } from './user-indentities.slice';

export const usersReducers = {
    cleanupUserIdentitiesData: (state: UsersState): void => {
        state.data = {};
        state.userIdsLoading = [];
    },
    setUsersLoading: (state: UsersState, action: PayloadAction<string[]>): void => {
        state.userIdsLoading = [...state.userIdsLoading, ...action.payload];
    },
};

export const usersExtraReducers = (builder: ActionReducerMapBuilder<UsersState>): ActionReducerMapBuilder<UsersState> =>
    builder
        .addCase(getUserIdentities.pending, (state) => {
            state.loading = LoadingStatus.Pending;
        })
        .addCase(getUserIdentities.fulfilled, (state, action) => {
            state.loading = LoadingStatus.Completed;
            const mappedIdenties: Record<string, IUserIdentity> = {};
            action.payload.forEach((identity) => {
                if (identity.id) {
                    mappedIdenties[identity.id] = identity;
                    const indexOfId = state.userIdsLoading.indexOf(identity.id);
                    if (indexOfId > -1) {
                        state.userIdsLoading = [
                            ...state.userIdsLoading.slice(0, indexOfId),
                            ...state.userIdsLoading.slice(indexOfId + 1),
                        ];
                    }
                }
            });
            state.data = { ...state.data, ...mappedIdenties };
        })
        .addCase(getUserIdentities.rejected, (state) => {
            state.loading = LoadingStatus.Failed;
        });
