import { IChartProcedure } from 'api/models/chart.model';
import { ApplicableArea, ConflictRuleType } from 'api/models/lookup.model';
import { flatten, isEqual, some } from 'lodash';
import { IPipelineError, PipelineError } from '../pipeline';
import ProcedureConflictRulesPipeline from '../procedureConflictRules.pipeline';
import { createRule } from '../ruleGenerator';

const ProcedureOnePerArea = createRule<ProcedureConflictRulesPipeline, IChartProcedure>({
    ruleTypes: [ConflictRuleType.onePerArea],
    rule: (pipeline, item) => {
        let newProcedure = { ...item };
        const _errors: IPipelineError[] = [];

        const originalProcedure = pipeline.getChartProcedure(newProcedure);
        const isNewProcedureAppliedToNewTeeth = isEqual(newProcedure.toothIds, originalProcedure?.toothIds);

        if (!isNewProcedureAppliedToNewTeeth) {
            const applicableArea = pipeline.getProcedure(newProcedure)?.applicableArea;
            if (item.toothIds && applicableArea) {
                item.toothIds.forEach((id) => {
                    if (applicableArea === ApplicableArea.Surface) {
                        const proceduresWithSameSurfaces = pipeline.currentProcedures
                            .filter((p) => isEqual(p.toothIds, newProcedure.toothIds))
                            .filter((p) => p.procedureId === newProcedure.procedureId)
                            .filter((p) =>
                                some(p.areas, (area) => {
                                    if (newProcedure.areas) {
                                        const indexOfArea = newProcedure.areas.findIndex((a) => a === area);
                                        return indexOfArea > -1;
                                    }
                                    return false;
                                }),
                            );

                        if (proceduresWithSameSurfaces.length > 0)
                            _errors.push({
                                type: PipelineError.OnePerArea,
                                data: {
                                    procedure: pipeline.getProcedure(item),
                                    areas: flatten(
                                        proceduresWithSameSurfaces.map((p) =>
                                            p.areas?.filter((a) => (item?.areas ? item.areas.indexOf(a) > -1 : false)),
                                        ),
                                    ),
                                },
                            });
                        return proceduresWithSameSurfaces.length > 0;
                    } else {
                        // * If we have any procedures that exist with the same applicable area, remove the tooth id.
                        const chartProceduresForArea = pipeline.currentProcedures
                            .filter((p) => isEqual(p.toothIds, newProcedure.toothIds))
                            .filter((p) => p.procedureId === newProcedure.procedureId)
                            .filter((p) => {
                                const applicableAreaOther = pipeline.getProcedure(p)?.applicableArea;
                                return applicableArea === applicableAreaOther;
                            })
                            .filter((p) => p !== undefined);
                        if (chartProceduresForArea.length > 0) {
                            newProcedure = pipeline.removeToothIdFromChartProcedure(newProcedure, id);
                            _errors.push({
                                type: PipelineError.OnePerArea,
                                data: {
                                    procedure: pipeline.getProcedure(item),
                                    areas: flatten(chartProceduresForArea.map((p) => p.areas)),
                                },
                            });
                        }
                    }
                });
            }
        }
        return { shouldRemoveItem: !newProcedure.toothIds?.length, updatedItem: newProcedure, errors: _errors };
    },
});

export default ProcedureOnePerArea;
