import { IContextualMenuProps, IFontStyles, ITextProps, Link, Stack, Text } from '@fluentui/react';
import { IconContextualMenu } from './IconContextualMenu';

type EditDetailsColumnProps = {
    menuProps?: IContextualMenuProps;
    title: string | JSX.Element;
    titleProps?: ITextProps;
    editOnClick?: () => void;
    disabled?: boolean;
    variant?: keyof IFontStyles;
};

export function EditDetailsColumn({
    title: displayField,
    menuProps,
    editOnClick: onClick,
    disabled,
    titleProps,
    variant,
}: EditDetailsColumnProps): JSX.Element {
    const _title = onClick ? (
        <Link
            disabled={disabled}
            onClick={onClick}
            title={typeof displayField === 'string' ? displayField : ''}
            style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '95%' }}
        >
            {displayField}
        </Link>
    ) : (
        displayField
    );
    return (
        <Stack horizontal>
            <Stack grow={1} style={{ width: '100%', textOverflow: 'ellipsis' }}>
                <Text
                    nowrap
                    variant={variant ?? 'mediumPlus'}
                    style={{ textOverflow: 'ellipsis' }}
                    title={typeof _title === 'string' ? _title : ''}
                    {...titleProps}
                >
                    {_title}
                </Text>
            </Stack>
            {menuProps && !disabled && (
                <Stack
                    grow
                    horizontalAlign="end"
                    verticalAlign="center"
                    style={{
                        position: 'absolute',
                        right: 0,
                        height: '100%',
                        top: 0,
                    }}
                >
                    <IconContextualMenu {...menuProps} />
                </Stack>
            )}
        </Stack>
    );
}
