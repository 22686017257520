import {
    IChoiceGroupProps,
    ChoiceGroup as FluentChoiceGroup,
    IStyleFunction,
    IChoiceGroupStyleProps,
    IChoiceGroupStyles,
} from '@fluentui/react';

type Props = {
    inline?: boolean;
    preComponentContent?: JSX.Element;
} & IChoiceGroupProps;

const choiceGroupStyles: IStyleFunction<IChoiceGroupStyleProps, IChoiceGroupStyles> = () => ({
    root: {
        flexDirection: 'row',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
    },
    flexContainer: {
        flexDirection: 'row',
        flex: 1,
        display: 'flex',
        flexWrap: 'wrap',
        selectors: {
            '.ms-ChoiceField': {
                marginRight: 20,
            },
        },
    },
    label: { marginRight: 20, marginTop: 6, padding: 0 },
});

function ChoiceGroupField({ inline = false, preComponentContent, ...choiceGroup }: Props): JSX.Element {
    return (
        <div style={{ position: 'relative' }}>
            {preComponentContent}
            <FluentChoiceGroup {...choiceGroup} styles={inline ? choiceGroupStyles : undefined} />
        </div>
    );
}

export default ChoiceGroupField;
