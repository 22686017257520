import {
    DefaultButton,
    DirectionalHint,
    Link,
    MessageBar,
    MessageBarType,
    Panel,
    PanelType,
    PrimaryButton,
    Spinner,
    Stack,
    TooltipHost,
} from '@fluentui/react';
import { EncounterStatus, ProviderIdType } from 'api/models/encounter.model';
import { Field } from 'components';
import UserDisplayName from 'components/UserDisplayName';
import { useSelector, useValidation } from 'hooks';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { getValidationError, IValidationConfig } from 'hooks/useValidation';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { RouteParams } from 'interfaces/route-params';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { updateEncounterProviders, UpdateEncounterProvidersPayload } from 'state/slices/encounter/encounter.actions';
import {
    selectPatientEncounter,
    selectPatientEncounterAppointment,
    selectPatientEncounterProvidersSaving,
    selectPatientEncounterTreatingProvider,
} from 'state/slices/encounter/encounter.selectors';
import {
    getOptionsWithSelectedProvider,
    selectHygienistOptions,
    selectProvidersData,
    selectRDAProviderOptions,
    selectTreatingProviderOnlyOptions,
    selectTreatingProviderOptions,
} from 'state/slices/tenant/providers.slice';
import convertDashedDateString from 'utils/convertDateStringToLocal';
import { getTimeTextFromValue } from 'utils/getTimeOptions';

export default function EditEncounterProviders() {
    const [isPanelOpen, setIsPanelOpen] = useState<boolean>(false);
    const encounter = useSelector(selectPatientEncounter);

    function setPanelOpen() {
        setIsPanelOpen(true);
    }

    function dismissPanel() {
        setIsPanelOpen(false);
    }

    return (
        <Stack horizontal>
            <EditEncounterProvidersPanel onDismiss={dismissPanel} isOpen={isPanelOpen} />
            <TooltipHost
                delay={0}
                directionalHint={DirectionalHint.leftCenter}
                content={
                    <Stack tokens={{ childrenGap: 5 }}>
                        <Stack horizontal tokens={{ childrenGap: 3 }}>
                            <b>Treating Provider: </b>
                            <UserDisplayName userId={encounter?.treatingProviderId} />
                        </Stack>
                        <Stack horizontal tokens={{ childrenGap: 3 }}>
                            <b>Billing Provider: </b>
                            <UserDisplayName userId={encounter?.billingProviderId} />
                        </Stack>
                        <Stack horizontal tokens={{ childrenGap: 3 }}>
                            <b>Attending Provider: </b>
                            <UserDisplayName userId={encounter?.supervisingProviderId} />
                        </Stack>
                        <Stack horizontal tokens={{ childrenGap: 3 }}>
                            <b>Hygienist: </b>
                            <UserDisplayName userId={encounter?.hygienistId} />
                        </Stack>
                        <Stack horizontal tokens={{ childrenGap: 3 }}>
                            <b>RDA: </b>
                            <UserDisplayName userId={encounter?.registeredDentalAssistantId} />
                        </Stack>
                    </Stack>
                }
            >
                <Link onClick={setPanelOpen}>(Edit Providers)</Link>
            </TooltipHost>
        </Stack>
    );
}

type EditEncounterProvidersPanelProps = { isOpen: boolean; onDismiss: () => void };

export function EditEncounterProvidersPanel({ isOpen, onDismiss }: EditEncounterProvidersPanelProps) {
    const dispatch = useAppDispatch();
    const { tenantId, patientId, encounterId } = useParams<RouteParams>();

    //Saving state:
    const savingEncounterStatus = useSelector(selectPatientEncounterProvidersSaving);
    const isSavingEncounter = savingEncounterStatus === LoadingStatus.Pending;

    const providerData = useSelector(selectProvidersData);

    //Combo box provider options:
    const treatingProviderOptions = useSelector(selectTreatingProviderOptions);
    const treatingProviderOnlyOptions = useSelector(selectTreatingProviderOnlyOptions);
    const hygienistOptions = useSelector(selectHygienistOptions);
    const rdaProviderOptions = useSelector(selectRDAProviderOptions);

    //Encounter information:
    const encounter = useSelector(selectPatientEncounter);
    const encounterTreatingProvider = useSelector(selectPatientEncounterTreatingProvider);
    const encounterAppointment = useSelector(selectPatientEncounterAppointment);

    const [providers, setProviders] = useState<UpdateEncounterProvidersPayload>({});
    const [hasError, setHasError] = useState<boolean>(false);

    //Set initial provider data from current encounter.
    useEffect(() => {
        //Assume that the encounter is already loaded when this panel is opened since it is only accessible through the
        //encounter tracker component, which is only visible when an encounter is selected.
        if (isOpen)
            setProviders({
                registeredDentalAssistantId: encounter?.registeredDentalAssistantId ?? undefined,
                treatingProviderId: encounter?.treatingProviderId ?? undefined,
                hygienistId: encounter?.hygienistId ?? undefined,
                billingProviderId: encounter?.billingProviderId ?? undefined,
                supervisingProviderId: encounter?.supervisingProviderId ?? undefined,
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const validationConfig = useMemo<IValidationConfig>(() => {
        const config: IValidationConfig = [
            { value: providers.treatingProviderId, fieldName: 'treatingProviderId', validation: ['required'] },
        ];

        if (encounterTreatingProvider?.isResident && encounter?.status !== EncounterStatus.Created) {
            config.push({
                value: providers.supervisingProviderId,
                fieldName: 'supervisingProviderId',
                validation: ['required'],
            });
        }

        if (
            encounterTreatingProvider?.isAttestingHygienist &&
            !(encounter?.status === EncounterStatus.Created || encounter?.status === EncounterStatus.ReadyForAttestation)
        ) {
            config.push({
                value: providers.billingProviderId,
                fieldName: 'billingProviderId',
                validation: ['required'],
            });
        }

        return config;
    }, [encounterTreatingProvider, providers, encounter]);

    const [errors, onSubmit, cleanupValidationErrors] = useValidation(validationConfig, onSave);

    function getIsRequiredField(field: keyof UpdateEncounterProvidersPayload) {
        return !!validationConfig.find((c) => c.fieldName === field && c.validation.includes('required'));
    }

    function onDismissed() {
        cleanupValidationErrors();
    }

    function setProviderId(providerIdType: ProviderIdType, providerId: string) {
        setProviders({ ...providers, [providerIdType]: providerId });
    }

    async function onSave() {
        if (encounterId) {
            try {
                await dispatch(updateEncounterProviders({ tenantId, patientId, encounterId, providers })).unwrap();
                onDismiss();
            } catch (e) {
                setHasError(true);
            }
        }
    }

    return (
        <Panel
            type={PanelType.medium}
            headerText={`Edit Providers (${getTimeTextFromValue(encounterAppointment?.startTime) ?? 'Unknown Time'
                } - ${convertDashedDateString(encounterAppointment?.date)})`}
            onDismissed={onDismissed}
            isOpen={isOpen}
            onDismiss={onDismiss}
        >
            <Stack tokens={{ childrenGap: 10 }}>
                <Field.SearchCombo
                    placeholder="(Select a Provider)"
                    label="Treating Provider"
                    required={getIsRequiredField(ProviderIdType.TreatingProviderId)}
                    errorMessage={
                        getValidationError(errors, 'treatingProviderId') ? 'Treating Provider is a required field.' : ''
                    }
                    selectedKey={providers.treatingProviderId}
                    options={getOptionsWithSelectedProvider({
                        selectedProviderId: providers.treatingProviderId,
                        options: treatingProviderOptions,
                        lookup: providerData,
                    })}
                    onChange={(e, option) => {
                        setProviderId(ProviderIdType.TreatingProviderId, option?.key as string);
                    }}
                />
                <Field.SearchCombo
                    placeholder="(Select a Provider)"
                    label="Billing Provider"
                    selectedKey={providers.billingProviderId}
                    required={getIsRequiredField(ProviderIdType.BillingProviderId)}
                    errorMessage={
                        getValidationError(errors, ProviderIdType.BillingProviderId)
                            ? 'Billing Provider is a required field.'
                            : ''
                    }
                    options={getOptionsWithSelectedProvider({
                        selectedProviderId: providers.billingProviderId,
                        options: treatingProviderOnlyOptions,
                        lookup: providerData,
                    })}
                    onChange={(e, option) => {
                        setProviderId(ProviderIdType.BillingProviderId, option?.key as string);
                    }}
                />
                <Field.SearchCombo
                    placeholder="(Select a Provider)"
                    label="Attending Provider"
                    selectedKey={providers.supervisingProviderId}
                    required={getIsRequiredField(ProviderIdType.SupervisingProviderId)}
                    errorMessage={
                        getValidationError(errors, ProviderIdType.SupervisingProviderId)
                            ? 'Attending Provider is a required field.'
                            : ''
                    }
                    options={getOptionsWithSelectedProvider({
                        selectedProviderId: providers.attestingProviderId,
                        options: treatingProviderOnlyOptions,
                        lookup: providerData,
                    })}
                    onChange={(e, option) => {
                        setProviderId(ProviderIdType.SupervisingProviderId, option?.key as string);
                    }}
                />
                <Field.SearchCombo
                    placeholder="(Select a Provider)"
                    label="Hygienist"
                    required={getIsRequiredField(ProviderIdType.HygienistId)}
                    errorMessage={getValidationError(errors, ProviderIdType.HygienistId) ? 'Hygienist is a required field.' : ''}
                    options={getOptionsWithSelectedProvider({
                        selectedProviderId: providers.hygienistId,
                        options: hygienistOptions,
                        lookup: providerData,
                    })}
                    selectedKey={providers.hygienistId}
                    onChange={(e, option) => {
                        setProviderId(ProviderIdType.HygienistId, option?.key as string);
                    }}
                />
                <Field.SearchCombo
                    placeholder="(Select a Provider)"
                    label="RDA"
                    required={getIsRequiredField(ProviderIdType.RegisteredDentalAssistantId)}
                    errorMessage={
                        getValidationError(errors, ProviderIdType.RegisteredDentalAssistantId) ? 'RDA is a required field.' : ''
                    }
                    options={getOptionsWithSelectedProvider({
                        selectedProviderId: providers.registeredDentalAssistantId,
                        options: rdaProviderOptions,
                        lookup: providerData,
                    })}
                    selectedKey={providers.registeredDentalAssistantId}
                    onChange={(e, option) => {
                        setProviderId(ProviderIdType.RegisteredDentalAssistantId, option?.key as string);
                    }}
                />
                {hasError && (
                    <MessageBar messageBarType={MessageBarType.error} onDismiss={() => setHasError(false)}>
                        Something went wrong.
                    </MessageBar>
                )}
                <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign="space-between" style={{ paddingTop: '2rem' }}>
                    <Stack horizontal>
                        <PrimaryButton disabled={isSavingEncounter} text={'Save'} onClick={onSubmit} />
                        {isSavingEncounter && <Spinner labelPosition="right" label="Saving..." />}
                    </Stack>
                    <DefaultButton disabled={isSavingEncounter} text={'Close'} onClick={onDismiss} />
                </Stack>
            </Stack>
        </Panel>
    );
}
