import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { ChartProcedureStatus, IChartProcedure } from 'api/models/chart.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { ChartingState } from '../chart/chart.slice';
import { diagnosesPanelInitialState } from '../diagnoses-panel/diagnoses-panel.state';
import { chartProcedurePanelInitialState } from '../procedure-panel/procedure-panel.state';
import {
    createChartProcedure,
    createChartProcedures,
    deleteChartProceduresAsync,
    getChartProcedures,
    updateChartProcedure,
    updateChartProcedures,
} from './procedures.actions';

export const proceduresReducers = {
    applyActionToTeeth(state: ChartingState, action: PayloadAction<IChartProcedure[]>): void {
        if (state.chart) {
            state.procedures.data = state.procedures.data ? [...state.procedures.data, ...action.payload] : [];
        }
    },
    moveActions: (
        state: ChartingState,
        action: PayloadAction<{ procedures: IChartProcedure[]; treatmentPlanId?: string }>,
    ): void => {
        const { procedures, treatmentPlanId } = action.payload;
        if (state.chart) {
            const newProceduresWithId = procedures.map((proc) => ({
                ...proc,
                treatmentPlanId: treatmentPlanId ? treatmentPlanId : undefined,
            }));
            if (state.procedures.data.length) {
                const newProcedures = state.procedures.data.filter(
                    (proc) => procedures.findIndex((p) => p.id === proc.id) === -1,
                );
                state.procedures.data = [...newProcedures, ...newProceduresWithId];
            }
            state.procedures.data = [...newProceduresWithId];
        }
    },
    setActionsStatus: (
        state: ChartingState,
        action: PayloadAction<{
            procedures: IChartProcedure[];
            status: ChartProcedureStatus;
        }>,
    ): void => {
        const { procedures, status } = action.payload;
        if (state.chart && state.procedures.data?.length) {
            const newProcedures = state.procedures.data.map((proc) => {
                const procedureExists = procedures.findIndex((p) => p.id === proc.id) > -1;
                if (procedureExists) {
                    return {
                        ...proc,
                        status,
                    };
                }
                return proc;
            });
            state.procedures.data = [...newProcedures];
        }
    },
    deleteActions: (state: ChartingState, action: PayloadAction<IChartProcedure[]>): void => {
        const procedures = action.payload;
        if (state.procedures.data.length) {
            state.procedures.data = state.procedures.data.filter((proc) => {
                const procedureExists = procedures.findIndex((p) => p.id === proc.id) > -1;
                return !procedureExists;
            });
        }
    },
};

export const procedureExtraReducers = (builder: ActionReducerMapBuilder<ChartingState>) => {
    return builder
        .addCase(getChartProcedures.pending, (state) => {
            state.procedures.loading = LoadingStatus.Pending;
        })
        .addCase(getChartProcedures.fulfilled, (state, action: PayloadAction<IChartProcedure[]>) => {
            state.procedures.data = action.payload;
            state.procedures.loading = LoadingStatus.Completed;
        })
        .addCase(getChartProcedures.rejected, (state, action: PayloadAction<any>) => {
            state.procedures.errors = action.payload;
            state.procedures.loading = LoadingStatus.Failed;
        })
        .addCase(createChartProcedure.pending, (state) => {
            state.procedures.saving = LoadingStatus.Pending;
        })
        .addCase(createChartProcedure.fulfilled, (state, action: PayloadAction<IChartProcedure>) => {
            const procedureIndex = state.procedures.data.findIndex((p) => p.id === action.payload.id);
            if (procedureIndex > -1) state.procedures.data[procedureIndex] = action.payload;
            state.procedures.saving = LoadingStatus.Completed;
        })
        .addCase(createChartProcedure.rejected, (state, action: PayloadAction<any>) => {
            state.procedures.errors = action.payload;
            state.procedures.saving = LoadingStatus.Failed;
        })

        .addCase(createChartProcedures.pending, (state) => {
            state.procedures.saving = LoadingStatus.Pending;
        })
        .addCase(createChartProcedures.fulfilled, (state, action: PayloadAction<IChartProcedure[]>) => {
            if (
                state.procedures.data.filter((p) => {
                    return action.payload.findIndex((item) => item.id === p.id) > -1;
                }).length === 0
            )
                state.procedures.data = [...state.procedures.data, ...action.payload];
            state.procedures.saving = LoadingStatus.Completed;

            if (state.procedurePanel.isOpen) {
                state.procedurePanel = chartProcedurePanelInitialState;
            }
        })
        .addCase(createChartProcedures.rejected, (state, action: PayloadAction<any>) => {
            state.procedures.errors = action.payload;
            state.procedures.saving = LoadingStatus.Failed;
        })
        .addCase(updateChartProcedure.pending, (state) => {
            state.procedures.saving = LoadingStatus.Pending;
        })
        .addCase(updateChartProcedure.fulfilled, (state, action: PayloadAction<IChartProcedure>) => {
            const procedureIndex = state.procedures.data.findIndex((p) => p.id === action.payload.id);
            if (procedureIndex > -1) state.procedures.data[procedureIndex] = action.payload;
            state.procedures.saving = LoadingStatus.Completed;
            state.selectedChartActions = undefined;

            // Close diagnoses selection panel if open
            if (state.diagnosesPanel.isOpen) {
                state.diagnosesPanel = diagnosesPanelInitialState;
            }
            if (state.diagnosesPanel.isOpen) {
                state.diagnosesPanel = diagnosesPanelInitialState;
            }
        })
        .addCase(updateChartProcedure.rejected, (state, action: PayloadAction<any>) => {
            state.procedures.errors = action.payload;
            state.procedures.saving = LoadingStatus.Failed;
        })
        .addCase(updateChartProcedures.pending, (state) => {
            state.procedures.saving = LoadingStatus.Pending;
        })
        .addCase(updateChartProcedures.fulfilled, (state, action: PayloadAction<IChartProcedure[]>) => {
            action.payload.forEach((procedure) => {
                const procedureIndex = state.procedures.data.findIndex((p) => p.id === procedure.id);
                if (procedureIndex > -1) state.procedures.data[procedureIndex] = procedure;
            });
            state.procedures.saving = LoadingStatus.Completed;
            state.selectedChartActions = undefined;

            if (state.procedurePanel.isOpen) {
                state.procedurePanel = chartProcedurePanelInitialState;
            }
            if (state.diagnosesPanel.isOpen) {
                state.diagnosesPanel = diagnosesPanelInitialState;
            }
        })
        .addCase(updateChartProcedures.rejected, (state, action: PayloadAction<any>) => {
            state.procedures.errors = action.payload;
            state.procedures.saving = LoadingStatus.Failed;
        })
        .addCase(deleteChartProceduresAsync.pending, (state) => {
            state.procedures.saving = LoadingStatus.Pending;
        })
        .addCase(deleteChartProceduresAsync.fulfilled, (state, action: PayloadAction<IChartProcedure[]>) => {
            state.procedures.saving = LoadingStatus.Completed;
            state.selectedChartActions = undefined;
            action.payload.forEach((procedure) => {
                const procedureIndex = state.procedures.data.findIndex((p) => p.id === procedure.id);
                if (procedureIndex > -1) state.procedures.data[procedureIndex] = procedure;
            });
        })
        .addCase(deleteChartProceduresAsync.rejected, (state, action: PayloadAction<any>) => {
            state.procedures.saving = LoadingStatus.Failed;
            state.procedures.errors = action.payload;
        });
};
