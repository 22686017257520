import { Dropdown, IDropdownOption, Stack } from '@fluentui/react';
import { IDentalSealantsDetailQuery, IDentalSealantsSummaryQuery } from 'api/models/dental-sealants-report.model';
import { ReportType } from 'api/models/embed-report.model';
import { Field } from 'components';
import { useTenantId } from 'hooks';
import { getValidationError } from 'hooks/useValidation';
import ReportWrapper from 'pages/Reporting/ReportWrapper';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import reportActionLookup from 'state/slices/reporting/reportActionLookup';
import { getAllLocationsOfCareForReports } from 'state/slices/reporting/reporting.actions';
import {
    selectLocationsOfCareFromReporting,
    selectReportProperties,
    selectReportingValidationErrors,
} from 'state/slices/reporting/reporting.selectors';
import {
    setDentalSealantDetailsProp,
    setDentalSealantSummaryProp,
    updateDentalSealantDetailQueryProp,
    updateDentalSealantSummaryQueryProp,
} from 'state/slices/reporting/reporting.slice';
import { selectTreatingProviderOptions } from 'state/slices/tenant/providers.slice';
import { classicDateOnly } from 'utils/dateOnly';

export default function DentalSealantsForChildrenSummary() {
    const tenantId = useTenantId();
    const dispatch = useDispatch();

    const errors = useSelector(selectReportingValidationErrors);

    const locationsOfCare = useSelector(selectLocationsOfCareFromReporting);
    const _providerOptions = useSelector(selectTreatingProviderOptions);

    const reportProperties = useSelector(selectReportProperties) as IDentalSealantsSummaryQuery;

    const locationsOfCareOptions: IDropdownOption[] = locationsOfCare.map((loc) => ({
        key: loc.id,
        text: loc.displayName,
    }));

    const filterGroupOptions: IDropdownOption[] = [
        { key: 'All Encounters', text: 'All Encounters' },
        { key: 'First Encounters', text: 'First Encounters' },
    ];
    useEffect(() => {
        dispatch(getAllLocationsOfCareForReports({ tenantId }));
    }, []);

    useEffect(() => {
        dispatch(setDentalSealantSummaryProp({ value: 'All Encounter' as string, path: 'filterType' }));
    }, []);

    const _onViewReport = () => {
        if (tenantId && reportProperties) {
            dispatch(
                reportActionLookup[ReportType.DentalSealantsForChildrenSummary]({
                    tenantId,
                    ...reportProperties,
                }),
            );
        }
    };

    useEffect(() => {
        dispatch(setDentalSealantSummaryProp({ value: 'All Encounters' as string, path: 'filterType' }));
    }, []);

    const groupByOptions: IDropdownOption[] = [
        { key: '', text: '(Select Group Column)' },
        { key: 'Ethnicity', text: 'Ethnicity' },
        { key: 'Legal Sex', text: 'Legal Sex' },
        { key: 'Location', text: 'Location' },
        { key: 'Provider', text: 'Provider' },
        { key: 'Race', text: 'Race' },
        { key: 'Sexual Orientation', text: 'Sexual Orientation' },
    ];

    return (
        <ReportWrapper
            validationConfig={[
                { fieldName: 'Start Date', validation: ['required'], value: reportProperties?.startDate },
                { fieldName: 'End Date', validation: ['required'], value: reportProperties?.endDate },
            ]}
            onViewReport={_onViewReport}
        >
            <Stack tokens={{ childrenGap: 10 }} grow>
                <Stack grow horizontal wrap tokens={{ childrenGap: 10 }}>
                    <Stack.Item>
                        <Field.Date
                            label="Start Date"
                            required
                            hasDatePicker
                            onChange={(ev, value) => {
                                const newDate = value ? classicDateOnly(value, 'yyyy-MM-dd') : undefined;
                                dispatch(setDentalSealantSummaryProp({ path: 'startDate', value: newDate }));
                            }}
                            value={reportProperties?.startDate ? classicDateOnly(reportProperties?.startDate, 'MM/dd/yyyy') : ''}
                            errorMessage={getValidationError(errors, 'Start Date') ? 'Start date is required.' : undefined}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <Field.Date
                            label="End Date"
                            required
                            isReasonable
                            hasDatePicker
                            value={reportProperties?.endDate ? classicDateOnly(reportProperties?.endDate, 'MM/dd/yyyy') : ''}
                            onChange={(ev, value) => {
                                const newDate = value ? classicDateOnly(value, 'yyyy-MM-dd') : undefined;
                                dispatch(setDentalSealantSummaryProp({ path: 'endDate', value: newDate }));
                            }}
                            disabled={!reportProperties?.startDate}
                            minReasonableErrorMessage="End date must be after start date."
                            minReasonableDate={
                                reportProperties?.startDate ? new Date(classicDateOnly(reportProperties?.startDate)) : undefined
                            }
                            errorMessage={getValidationError(errors, 'End Date') ? 'End date is required.' : undefined}
                        />
                    </Stack.Item>

                    <Stack.Item>
                        <Dropdown
                            label="Filter Type"
                            placeholder="(Select Filter Type)"
                            selectedKey={reportProperties?.filterType}
                            options={filterGroupOptions}
                            required
                            style={{ minWidth: 300 }}
                            onChange={(ev, value) => {
                                if (value)
                                    dispatch(setDentalSealantSummaryProp({ path: 'filterType', value: value.key as string }));
                            }}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <Field.SearchCombo
                            placeholder="(Select Provider)"
                            label="Providers"
                            multiSelect
                            style={{ minWidth: 300 }}
                            options={_providerOptions}
                            onChange={(ev, option) => {
                                if (option?.key)
                                    dispatch(
                                        updateDentalSealantSummaryQueryProp({ id: option.key as string, path: 'providerIds' }),
                                    );
                            }}
                            selectedKey={reportProperties?.providerIds}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <Field.SearchCombo
                            placeholder="(Select Locations of Care)"
                            label="Locations of Care"
                            multiSelect
                            style={{ minWidth: 300 }}
                            options={locationsOfCareOptions}
                            onChange={(ev, option) => {
                                if (option?.key)
                                    dispatch(
                                        updateDentalSealantSummaryQueryProp({
                                            id: option.key as string,
                                            path: 'locationOfCareIds',
                                        }),
                                    );
                            }}
                            selectedKey={reportProperties?.locationOfCareIds}
                        />
                    </Stack.Item>
                </Stack>
                <Stack grow horizontal wrap tokens={{ childrenGap: 10 }}>
                    <Stack.Item>
                        <Dropdown
                            label="Group Column 1"
                            placeholder="(Select Group Column )"
                            options={groupByOptions}
                            style={{ minWidth: 300 }}
                            selectedKey={reportProperties?.groupCol}
                            onChange={(ev, value) => {
                                if (value)
                                    dispatch(setDentalSealantSummaryProp({ path: 'groupCol', value: value.key as string }));
                            }}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <Dropdown
                            label="Group Column 2"
                            placeholder="(Select Group Column)"
                            selectedKey={reportProperties?.groupCol2}
                            style={{ minWidth: 300 }}
                            options={groupByOptions}
                            onChange={(ev, value) => {
                                if (value)
                                    dispatch(setDentalSealantSummaryProp({ path: 'groupCol2', value: value.key as string }));
                            }}
                        />
                    </Stack.Item>
                </Stack>
            </Stack>
        </ReportWrapper>
    );
}
