import { useTheme, Stack, INavLink } from '@fluentui/react';
import { useQuickActions, useTenantId } from 'hooks';
import React from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { setCategoryOrderAndSave } from 'state/slices/tenant/quickActions/quickActions.slice';
import SettingsVerticalNav from '../../components/SettingsVerticalNav';

type ListProps = {
    searchString: string;
};

function CategoriesList({ searchString }: ListProps): JSX.Element {
    const { setSelectedCategory, searchCategories, selectedCategoryId } = useQuickActions();
    const _theme = useTheme();
    const tenantId = useTenantId();
    const dispatch = useDispatch();

    const _onLinkClick = (ev?: React.MouseEvent<HTMLElement | MouseEvent>, item?: INavLink) => {
        if (item?.id) setSelectedCategory(item.id);
    };

    const _categoryNavItems: INavLink[] | null = searchCategories(searchString).map((c) => ({
        name: c.displayName,
        id: c.id,
        url: c.id,
    }));

    function onDragEnd(result: DropResult) {
        const { destination, draggableId } = result;
        if (destination) dispatch(setCategoryOrderAndSave(tenantId, draggableId, destination.index));
    }

    return (
        <Stack
            tokens={{ childrenGap: 10 }}
            className="category-items"
            style={{ borderColor: `${_theme.palette.neutralQuaternary}`, backgroundColor: _theme.palette.neutralLighterAlt }}
        >
            <SettingsVerticalNav
                onDragEnd={onDragEnd}
                isNavItemDraggable={true}
                onLinkClick={_onLinkClick}
                links={_categoryNavItems}
                selectedKey={selectedCategoryId}
            />
        </Stack>
    );
}

export default CategoriesList;
