import { createAsyncThunk, Dictionary } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import IPatientAllergies, { IAllergy, IAllergyReaction } from 'api/models/patient-allergy.model';
import axios from 'axios';
import { cloneDeep } from 'lodash';
import ErrorTypes from 'state/errorTypes';
import { AppThunk } from 'state/store';

export const getAllergyReactions = createAsyncThunk<Dictionary<IAllergyReaction>, void, { rejectValue: string }>(
    'getAllergyReactions',
    async (_, { rejectWithValue }) => {
        try {
            const { data: allergyReactions } = await dentalApi.getAllAllergyReactions();
            return allergyReactions;
        } catch (err) {
            if (axios.isAxiosError(err) && err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(JSON.stringify(err));
            }
        }
    },
);

export const updatePatientAllergies = createAsyncThunk<
    IPatientAllergies,
    {
        tenantId: string;
        patientId: string;
        allergies: IPatientAllergies;
    }
>('updatePatientAllergies', async ({ tenantId, patientId, allergies }, { rejectWithValue }) => {
    try {
        const { data: patientAllergies } = await dentalApi.updatePatientAllergies(tenantId, patientId, allergies);

        return patientAllergies;
    } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
            if (err.response.status === 503) return rejectWithValue(ErrorTypes.ServiceUnavailable);
            return rejectWithValue(err.response.data.details);
        } else {
            return rejectWithValue(JSON.stringify(err));
        }
    }
});

function removeEmptyReactions(allergy: IAllergy) {
    return {
        ...allergy,
        reactions: allergy.reactions?.filter((reaction) => reaction.snomedcode !== 0),
    };
}

export const createPatientAllergies = createAsyncThunk<
    IPatientAllergies,
    {
        tenantId: string;
        patientId: string;
        allergies: IPatientAllergies;
    },
    { rejectValue: string }
>('createPatientAllergies', async ({ tenantId, patientId, allergies }, { rejectWithValue }) => {
    try {
        allergies.allergies = allergies.allergies?.map(removeEmptyReactions);
        const { data: patientAllergies } = await dentalApi.createPatientAllergies(tenantId, patientId, allergies);

        return patientAllergies;
    } catch (err) {
        if (axios.isAxiosError(err) && err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(JSON.stringify(err));
        }
    }
});

export const createOrUpdatePatientAllergies =
    (tenantId: string, patientId: string): AppThunk =>
    async (dispatch, getState) => {
        const state = getState();
        const { allergiesToAdd, data: patientAllergies } = state.patient.allergies;

        const updatedAllergiesToAdd = allergiesToAdd.map(removeEmptyReactions);

        if (patientAllergies?.allergies) {
            // Update PatientAllergy
            const updatedPatientAllergies = cloneDeep(patientAllergies);

            // Filter out empty allergy reactions

            updatedPatientAllergies.allergies = [...patientAllergies.allergies, ...updatedAllergiesToAdd];
            dispatch(updatePatientAllergies({ tenantId, patientId, allergies: updatedPatientAllergies }));
        } else {
            // Create PatientAllergy
            const newPatientAllergies: IPatientAllergies = {
                id: patientId,
                isDeleted: false,
                nkda: false,
                allergies: allergiesToAdd,
            };
            dispatch(createPatientAllergies({ tenantId, patientId, allergies: newPatientAllergies }));
        }
    };

export const updateNKDACheckbox =
    (tenantId: string, patientId: string, nkdaValue: boolean): AppThunk<void> =>
    (dispatch, getState) => {
        const state = getState();
        const patientAllergies = cloneDeep(state.patient.allergies.data);

        if (patientAllergies && typeof patientAllergies.nkda === 'boolean') {
            patientAllergies.nkda = nkdaValue;
            dispatch(updatePatientAllergies({ tenantId, patientId, allergies: patientAllergies }));
        } else {
            // Create PatientAllergy
            const newPatientAllergies: IPatientAllergies = {
                id: patientId,
                isDeleted: false,
                nkda: nkdaValue,
                allergies: [],
            };
            dispatch(createPatientAllergies({ tenantId, patientId, allergies: newPatientAllergies }));
        }
    };
