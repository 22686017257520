import { Stack } from '@fluentui/react';
import { ReportType } from 'api/models/embed-report.model';
import { Field } from 'components';
import { useTenantId } from 'hooks';
import { getValidationError, validateErrorMessage } from 'hooks/useValidation';
import ReportWrapper from 'pages/Reporting/ReportWrapper';
import { useDispatch, useSelector } from 'react-redux';
import reportActionLookup from 'state/slices/reporting/reportActionLookup';
import { selectReportingValidationErrors, selectReportProperties } from 'state/slices/reporting/reporting.selectors';
import { setStatementValidationProp } from 'state/slices/reporting/reporting.slice';
import { classicDateOnly } from 'utils/dateOnly';
import { IStatmentValidationProperties } from './statement-validation.model';

function StatementValidation() {
    const tenantId = useTenantId();
    const dispatch = useDispatch();

    const query = useSelector(selectReportProperties) as IStatmentValidationProperties;
    const errors = useSelector(selectReportingValidationErrors);

    const _onViewReport = () => {
        if (query) dispatch(reportActionLookup[ReportType.StatementValidation]({ tenantId, ...query }));
    };
    console.log(getValidationError(errors, 'Start Date'));
    return (
        <ReportWrapper
            onViewReport={_onViewReport}
            validationConfig={[{ fieldName: 'Start Date', validation: ['required', 'notFutureDate'], value: query?.reportDate }]}
        >
            <Stack tokens={{ childrenGap: 20 }} grow horizontal wrap>
                <Stack.Item>
                    <Field.Date
                        label="Statements sent on (or current date)"
                        required
                        value={query?.reportDate ? classicDateOnly(query?.reportDate, 'MM/dd/yyyy') : ''}
                        onChange={(ev, value) => {
                            const newDate = value ? classicDateOnly(value, 'yyyy-MM-dd') : undefined;
                            dispatch(setStatementValidationProp({ path: 'reportDate', value: newDate }));
                        }}
                        hasDatePicker
                        allowFutureDates={false}
                        errorMessage={
                            getValidationError(errors, 'Start Date')
                                ? validateErrorMessage(getValidationError(errors, 'Start Date'))
                                : undefined
                        }
                    />
                </Stack.Item>
            </Stack>
        </ReportWrapper>
    );
}

export default StatementValidation;
