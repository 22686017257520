import { DefaultButton, MessageBar, SelectionMode, Stack, Text } from '@fluentui/react';
import { IPendingChartTreatmentPlanPhaseProcedure } from 'api/models/treatment-plan.model';
import { Section, SortableDetailsList } from 'components';
import { useSelector, useTenantId } from 'hooks';
import { batch, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { getChartProcedures } from 'state/slices/charting/procedures/procedures.actions';
import {
    selectEditPatient,
    selectEditPatientHasChange,
    selectFilteredEditPatientUnscheduledTreatmentPlanProcedures,
} from 'state/slices/edit-patient/edit-patient.selectors';
import { setEditPatientPanelOpen } from 'state/slices/edit-patient/edit-patient.slice';
import { getChartTreatmentPlans } from 'state/slices/scheduling/scheduling.slice';

import { setAppointmentType, setIsAppointmentPanelOpen, setSelectedDate } from 'state/slices/scheduling/scheduling.slice';
import { AppointmentType } from 'state/slices/scheduling/scheduling.state';
import { classicDateOnly } from 'utils/dateOnly';

type Props = {
    setIsAlertOpen: (value: boolean) => void;
    setAlertMessage: (value: string) => void;
    buttonsHidden?: boolean;
};

function UnscheduledTXPlanProcedures({ setIsAlertOpen, setAlertMessage, buttonsHidden }: Props): JSX.Element {
    const patient = useSelector(selectEditPatient);
    const tenantId = useTenantId();
    const dispatch = useDispatch();
    const { push } = useHistory();

    const location = useLocation();
    const routePieces = location.pathname.split('/').filter((string) => string !== '/' && string !== '');
    const route = routePieces[1];
    const hasChanged = useSelector(selectEditPatientHasChange);

    const items = useSelector(selectFilteredEditPatientUnscheduledTreatmentPlanProcedures);

    const handleNewAppt = () => {
        if (hasChanged) {
            setIsAlertOpen(true);
            setAlertMessage(
                'There are unsaved changes for the patient demographics. Would you like to save and continue to create appointment panel?',
            );
        } else {
            if (patient) {
                const patientId = patient?.id;
                batch(() => {
                    dispatch(setSelectedDate(new Date()));
                    dispatch(getChartProcedures({ tenantId, patientId }));
                    dispatch(getChartTreatmentPlans({ tenantId, patientId }));
                    dispatch(setAppointmentType(AppointmentType.Patient));

                    dispatch(setIsAppointmentPanelOpen(true));
                    if (route !== 'scheduling') {
                        push(`/${tenantId}/scheduling/${patientId}`);
                    }
                });
                dispatch(setEditPatientPanelOpen(false));
            }
        }
    };

    if (!items.length) return <MessageBar>There are no unscheduled TX plan procedures.</MessageBar>;
    return (
        <Section>
            <Stack tokens={{ childrenGap: 10 }}>
                <SortableDetailsList<IPendingChartTreatmentPlanPhaseProcedure>
                    compact
                    selectionMode={SelectionMode.none}
                    items={items || []}
                    sortOnMount={true}
                    sortColumns={['signedDate']}
                    columns={[
                        {
                            key: 'date',
                            minWidth: 100,
                            maxWidth: 100,
                            name: 'Date',
                            fieldName: 'signedDate',
                            onRender: (item) => {
                                if (item)
                                    return (
                                        <Text variant="small">{item?.signedDate ? classicDateOnly(item?.signedDate) : null}</Text>
                                    );
                            },
                        },
                        {
                            key: 'code',
                            minWidth: 100,
                            maxWidth: 100,
                            name: 'Code',
                            fieldName: 'procedureCode',
                        },
                        {
                            key: 'description',
                            minWidth: 150,
                            maxWidth: 150,
                            name: 'Description',
                            isResizable: true,
                            fieldName: 'procedureDescription',
                        },
                        {
                            key: 'auth',
                            minWidth: 150,
                            maxWidth: 150,
                            name: 'Pre-Auth Status',
                            fieldName: 'preAuthStatus',
                        },
                        {
                            key: 'cost',
                            minWidth: 100,
                            maxWidth: 100,
                            name: 'Patient Cost',
                            fieldName: 'patientCost',
                        },
                        {
                            key: 'provider',
                            minWidth: 150,
                            maxWidth: 150,
                            name: 'Treating Provider',
                            fieldName: 'provider',
                        },
                    ]}
                />
                {/* {!buttonsHidden && (
                    <Stack tokens={{ childrenGap: 10 }} horizontal horizontalAlign="end"> */}
                {/* <DefaultButton onClick={handleNewAppt}>Schedule New Appt.</DefaultButton> */}
                {/* <DefaultButton>Post Pre Payment</DefaultButton>
                    </Stack>
                )} */}
            </Stack>
        </Section>
    );
}

export default UnscheduledTXPlanProcedures;
