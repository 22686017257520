import React, { useState, useRef } from 'react';
import {
    Stack,
    Label,
    IStackTokens,
    ComboBox,
    IComboBoxOption,
    Text,
    ICommandBarProps,
    CommandBar,
    TooltipHost,
    IconButton,
    IContextualMenuItem,
    TextField,
    styled,
    ITheme,
    IStyleFunctionOrObject,
    Callout,
    ITooltipHostStyles,
    TooltipDelay,
    ActionButton,
    DirectionalHint,
    Calendar,
    FocusTrapZone,
    ICalendarProps,
} from '@fluentui/react';
import { findIndex } from 'lodash';
import './WeekSchedule.scss';
import IWeekSchedule, { ITimeRange } from 'api/models/weekSchedule.model';
import InnerCard from '../InnerCard/InnerCard';
import { useBoolean } from '@uifabric/react-hooks';
import DayPickerStrings from 'components/Field/DateFieldCalendar.config';
import { timeOptions } from 'utils/getTimeOptions';
import IOperatory from 'api/models/Scheduling/operatory.model';

type Props = {
    schedule?: IWeekSchedule;
    commandBarProps?: ICommandBarProps | undefined;
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<any, any>;
    onChange?: (schedule: IWeekSchedule) => void;
    onCalendarChange?: (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement> | undefined,
        newValue?: string | undefined,
    ) => void;
    readOnly?: boolean;
    compact?: boolean;
    operators?: IOperatory;
};

const weekScheduleOptions = () => {
    const options: IComboBoxOption[] = [
        {
            key: '',
            text: '',
        },
    ];

    for (let i = 1; i <= 49; i++) {
        const timeInHalfHours = i <= 25 && i !== 1 ? i * 30 : (i - 24) * 30;
        const currentHour = Math.floor(timeInHalfHours / 60);
        const currentHalfHour = i % 2 === 0 ? '00' : '30';
        if (currentHour > 0 && i !== 1) {
            const timeValue = `${currentHour}:${currentHalfHour} ${i > 23 ? (i === 48 || i === 49 ? 'AM' : 'PM') : 'AM'}`;
            options.push({
                key: timeValue,
                text: timeValue,
            });
        }
    }

    return options;
};

const WeekSchedule = (props: Props) => {
    const [callouts, setCallouts] = useState<string[]>([]);
    const [showCalendar, { toggle: toggleShowCalendar }] = useBoolean(false);
    const calendarButtonElement = useRef<any>();

    const stackTokens: IStackTokens = {
        childrenGap: props.compact ? 5 : 10,
    };

    const isCalloutOpen = (callout: string) => callouts.findIndex((c) => c === callout) > -1;
    const toggleCallout = (callout: string) => {
        if (isCalloutOpen(callout)) {
            const calloutIndex = callouts.findIndex((c) => c === callout);
            const newCallouts = callouts;
            newCallouts.splice(calloutIndex, 1);
            setCallouts([...newCallouts]);
        } else {
            const newCallouts = callouts;
            newCallouts.push(callout);
            setCallouts([...newCallouts]);
        }
    };

    const updateSchedule = (dateOfTheWeek: keyof IWeekSchedule, fromTo?: keyof ITimeRange, value?: any | undefined) => {
        const scheduleObject: IWeekSchedule = props.schedule ? { ...props.schedule } : {};
        const timeRangeValue = scheduleObject[dateOfTheWeek];
        let newSchedule: IWeekSchedule;
        if (typeof timeRangeValue !== 'string' && fromTo) {
            newSchedule = {
                ...props.schedule,
                [dateOfTheWeek]: {
                    ...timeRangeValue,
                    [fromTo]: value ? value : '',
                },
            };
        } else {
            newSchedule = {
                ...props.schedule,
                [dateOfTheWeek]: value ? value : '',
            };
        }
        if (fromTo) if (props.onChange && newSchedule && !getErrorMessage(value as string)) props.onChange(newSchedule);
        if (props.onChange && newSchedule) props.onChange(newSchedule);
    };

    const getErrorMessage = (value: number | string | boolean) => {
        const indexOfOption = findIndex(weekScheduleOptions(), ['key', value]);
        if (indexOfOption === -1 && value !== '' && value !== undefined) {
            if (typeof value === 'boolean') return '';
            return 'Enter a valid option';
        }
        return '';
    };

    const toggleClosed = (dateOfTheWeek: keyof IWeekSchedule, value: boolean | undefined) => {
        const scheduleObject: IWeekSchedule = props.schedule ? { ...props.schedule } : {};
        const newSchedule: IWeekSchedule = {
            ...props.schedule,
            [dateOfTheWeek]: {
                to: value ? (scheduleObject[dateOfTheWeek] ? (scheduleObject[dateOfTheWeek] as ITimeRange).to : '') : '',
                from: value ? (scheduleObject[dateOfTheWeek] ? (scheduleObject[dateOfTheWeek] as ITimeRange).from : '') : '',
                closed: value,
            },
        };
        if (props.onChange) props.onChange(newSchedule);
    };

    const renderWeekDay = (label: string, key: keyof IWeekSchedule) => {
        const getTimeRange: ITimeRange | undefined = key !== 'exceptions' && props.schedule ? props.schedule[key] : undefined;
        const fromTime = getTimeRange ? getTimeRange.from : undefined;
        const toTime = getTimeRange ? getTimeRange.to : undefined;
        const closed = getTimeRange ? getTimeRange.closed : undefined;

        const dateOptions: IContextualMenuItem[] = [
            {
                key: 'closed',
                text: 'Closed',
                canCheck: true,
                checked: closed,
                onClick: () => toggleClosed(key, !closed),
            },
        ];

        return (
            <InnerCard style={{ paddingTop: 0, paddingRight: 0 }}>
                <Stack tokens={stackTokens} grow>
                    <Stack horizontalAlign="space-between" horizontal>
                        <Label>{label}</Label>
                        <TooltipHost content="Quick Actions">
                            <IconButton
                                menuProps={{
                                    items: dateOptions,
                                    isBeakVisible: false,
                                }}
                                iconProps={{ iconName: 'MoreVertical' }}
                                styles={{
                                    flexContainer: {
                                        selectors: {
                                            '.ms-Button-menuIcon': {
                                                display: 'none',
                                            },
                                        },
                                    },
                                }}
                                disabled={props.readOnly}
                            />
                        </TooltipHost>
                    </Stack>
                    <div
                        style={{
                            paddingRight: 10,
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        {!closed ? (
                            <>
                                <Stack.Item styles={{ root: { maxWidth: 110 } }}>
                                    <ComboBox
                                        label="From"
                                        autoComplete="off"
                                        allowFreeform
                                        selectedKey={fromTime}
                                        onChange={(ev, option) => {
                                            if (option) updateSchedule(key, 'from', option.key.toString());
                                        }}
                                        errorMessage={getErrorMessage(fromTime as string)}
                                        options={weekScheduleOptions()}
                                        disabled={props.readOnly}
                                    />
                                </Stack.Item>
                                <Stack.Item styles={{ root: { maxWidth: 110 } }}>
                                    <ComboBox
                                        label="To"
                                        autoComplete="off"
                                        allowFreeform
                                        selectedKey={toTime}
                                        onChange={(ev, option) => {
                                            if (option) updateSchedule(key, 'to', option.key.toString());
                                        }}
                                        errorMessage={getErrorMessage(toTime as string)}
                                        options={weekScheduleOptions()}
                                        disabled={props.readOnly}
                                    />
                                </Stack.Item>
                            </>
                        ) : (
                            <div
                                style={{
                                    display: 'flex',
                                    minWidth: 110,
                                    flex: 1,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Text styles={{ root: { textAlign: 'center' } }} variant="medium">
                                    CLOSED
                                </Text>
                            </div>
                        )}
                    </div>
                </Stack>
            </InnerCard>
        );
    };

    const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };

    //change back color using theme and switch to use theme colors

    const daysOfTheWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day, index) => {
        const getTimeRange: any | null =
            day !== 'exceptions' && props.schedule ? (props.schedule as any)[day.toLowerCase()] : null;

        const timeRange = () => (
            <Stack tokens={stackTokens} horizontal>
                <span>{getTimeRange?.from}</span>
                <span>-</span>
                <span>{getTimeRange?.to}</span>
            </Stack>
        );

        const timeRanges = (): JSX.Element[] =>
            getTimeRange.map((range: any, index: any) => {
                const startTime = timeOptions.find((t) => t.key === range?.startTime)?.text;
                const endTime = timeOptions.find((t) => t.key === range?.endTime)?.text;

                return (
                    <Stack key={index} tokens={stackTokens} horizontal>
                        <span>{startTime}</span>
                        <span>-</span>
                        <span>{endTime}</span>
                    </Stack>
                );
            });

        const _onRenderTooltip = () => (
            <Stack tokens={stackTokens}>
                <Label>{day}</Label>

                {getTimeRange && getTimeRange.length ? (
                    !getTimeRange?.closed ? (
                        typeof getTimeRange === 'object' ? (
                            timeRanges()
                        ) : (
                            timeRange()
                        )
                    ) : (
                        <span>CLOSED</span>
                    )
                ) : (
                    'No schedule recorded.'
                )}
            </Stack>
        );

        return !props.compact ? (
            <Stack.Item key={index}>{renderWeekDay(day, (day as any).toLowerCase())}</Stack.Item>
        ) : (
            <Stack.Item key={index}>
                <TooltipHost
                    styles={hostStyles}
                    delay={TooltipDelay.zero}
                    tooltipProps={{ onRenderContent: _onRenderTooltip }}
                    hidden={isCalloutOpen(day) ? true : undefined}
                >
                    <div
                        className="ms-depth-4 compact-weekday-button"
                        style={{
                            display: 'flex',
                            width: 22,
                            height: 22,
                            justifyContent: 'center',
                            alignItems: 'center',
                            color:
                                getTimeRange && getTimeRange.length && !getTimeRange?.closed
                                    ? props.theme?.palette.white
                                    : props.theme?.palette.neutralSecondaryAlt,
                            backgroundColor:
                                getTimeRange && getTimeRange.length && !getTimeRange?.closed && props.operators?.isDeleted
                                    ? '#898785'
                                    : getTimeRange && getTimeRange.length && !getTimeRange?.closed
                                    ? props.theme?.palette.themePrimary
                                    : 'transparent',
                            fontWeight: 'bold',
                        }}
                        id={`weekday-${day}`}
                        onClick={!props.readOnly ? () => toggleCallout(day) : undefined}
                    >
                        {day.charAt(0).toUpperCase()}
                    </div>
                </TooltipHost>
                {!props.readOnly && isCalloutOpen(day) && (
                    <Callout onDismiss={() => toggleCallout(day)} target={`#weekday-${day}`}>
                        {renderWeekDay(day, (day as any).toLowerCase())}
                    </Callout>
                )}
            </Stack.Item>
        );
    });

    const calendarProps: ICalendarProps = {
        showMonthPickerAsOverlay: false,
        isDayPickerVisible: true,
        isMonthPickerVisible: true,
        showGoToToday: false,
        highlightCurrentMonth: true,
        highlightSelectedMonth: true,
        strings: DayPickerStrings,
        // value: state.value ? new Date(state.value) : undefined,
        onDismiss: toggleShowCalendar,
        // minDate: isReasonable ? (minDate ? minDate : getDefaultReasonableDate()) : undefined,
        // maxDate: isReasonable ? (allowFutureDates ? undefined : maxDate ? maxDate : new Date()) : undefined,
        onSelectDate: (date) => {
            if (props.onCalendarChange) props.onCalendarChange(undefined, date.toISOString());
            toggleShowCalendar();
        },
    };

    return (
        <Stack tokens={stackTokens} grow>
            {props.commandBarProps ? (
                <InnerCard>
                    <CommandBar {...props.commandBarProps} />
                </InnerCard>
            ) : null}
            <Stack tokens={stackTokens} horizontal wrap grow>
                {daysOfTheWeek}
            </Stack>
            <Stack>
                {!props.compact && (
                    <InnerCard style={{ paddingTop: 0 }}>
                        <Stack horizontal>
                            <Label>Schedule Exceptions</Label>
                            <div ref={calendarButtonElement}>
                                <ActionButton
                                    type="IconButton"
                                    iconProps={{ iconName: 'Calendar' }}
                                    onClick={toggleShowCalendar}
                                    style={{
                                        maxHeight: 30,
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                />
                            </div>
                            {showCalendar && (
                                <Callout
                                    isBeakVisible={false}
                                    className="ms-DatePicker-callout"
                                    gapSpace={0}
                                    doNotLayer={false}
                                    target={calendarButtonElement}
                                    directionalHint={DirectionalHint.bottomLeftEdge}
                                    onDismiss={toggleShowCalendar}
                                    setInitialFocus
                                >
                                    <FocusTrapZone firstFocusableSelector="ms-DatePicker-day--today" isClickableOutsideFocusTrap>
                                        <Calendar {...calendarProps} />
                                    </FocusTrapZone>
                                </Callout>
                            )}
                        </Stack>
                        <TextField
                            onChange={(ev, value) => updateSchedule('exceptions', undefined, value)}
                            value={props.schedule?.exceptions}
                            multiline
                            rows={7}
                            disabled={props.readOnly}
                        />
                    </InnerCard>
                )}
            </Stack>
        </Stack>
    );
};

export default styled<Props, any, any>(WeekSchedule, {});
