import { Label, MessageBar, MessageBarType, SelectionMode, Stack } from '@fluentui/react';
import { IChartTreatmentPlanPhaseProcedure } from 'api/models/treatment-plan.model';
import { useSelector } from 'react-redux';
import { selectSelectedPatient, selectActivePrimaryPatientInsurance } from 'state/slices/patient/patient.selectors';
import { BillingProcedureDetailsList } from './EditBillingProcedureTable';

interface ICustomTreatmentTableProps {
    displayName: string;
    items: IChartTreatmentPlanPhaseProcedure[];
    missingText?: string;
}

function CustomTreatmentTable(props: ICustomTreatmentTableProps): JSX.Element {
    const { displayName, items, missingText } = props;
    const patient = useSelector(selectSelectedPatient);
    const patientInsurance = useSelector(selectActivePrimaryPatientInsurance);

    const procedureTable =
        items.length >= 1 ? (
            <BillingProcedureDetailsList
                patient={patient}
                activePatientInsurance={patientInsurance}
                procedures={items}
                selectionMode={SelectionMode.none}
            />
        ) : (
            <MessageBar messageBarType={MessageBarType.info}>{missingText ? missingText : 'No items to display'}</MessageBar>
        );
    return (
        <Stack>
            <Label>{displayName}</Label>
            {procedureTable}
        </Stack>
    );
}

export default CustomTreatmentTable;
