import { createAsyncThunk, createSelector, createSlice, Dictionary } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';

import ISlidingFeeProgram, { ISlidingFeePlan } from 'api/models/sliding-fee-program.model';
import { AxiosError, AxiosResponse } from 'axios';
import { LoadingStatus } from 'interfaces/loading-statuses';
import ErrorTypes from 'state/errorTypes';
import { RootState } from 'state/store';

type SlidingFeeProgramState = {
    initialLoad: LoadingStatus;
    loading: LoadingStatus;
    data?: Dictionary<ISlidingFeeProgram>;
    patientSlidingFeePlans?: ISlidingFeePlan;
};

const initialState: SlidingFeeProgramState = {
    initialLoad: LoadingStatus.Idle,
    loading: LoadingStatus.Idle,
    data: {},
    patientSlidingFeePlans: {},
};

export const getSlidingFeePrograms = createAsyncThunk<
    AxiosResponse<Dictionary<ISlidingFeeProgram>>,
    {
        tenantId: string;
    },
    {
        rejectValue: AxiosError;
    }
>('getSlidingFeePrograms', async ({ tenantId }) => {
    const res = await dentalApi.getSlidingFeePrograms(tenantId);
    return res;
});

export const getSlidingFeeProgramByPatient = createAsyncThunk<
    ISlidingFeePlan,
    {
        tenantId: string;
        patientId: string;
    },
    {
        rejectValue: string;
    }
>('getSlidingFeePrograms/getPatientSlidingFeePrograms', async ({ tenantId, patientId }, { rejectWithValue }) => {
    try {
        const { data: SlidingFee } = await dentalApi.getSlidingFeeProgramByPatient(tenantId, patientId);
        return SlidingFee;
    } catch (err: any) {
        if (err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(err.toString());
        }
    }
});
export const getSlidingFeeProgramByPatientPercentage = createAsyncThunk<
    ISlidingFeePlan,
    {
        tenantId: string;
        patientId: string;
        percentage: number;
    },
    {
        rejectValue: string;
    }
>(
    'getSlidingFeePrograms/getPatientPercentageSlidingFeePrograms',
    async ({ tenantId, patientId, percentage }, { rejectWithValue }) => {
        try {
            const { data: SlidingFee } = await dentalApi.getSlidingFeeProgramByPatientPercentage(tenantId, patientId, percentage);
            return SlidingFee;
        } catch (err: any) {
            if (err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue(err.toString());
            }
        }
    },
);

const slidingFeeProgramsSlice = createSlice({
    name: 'slidingFeePrograms',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getSlidingFeePrograms.pending, (state) => {
                state.initialLoad = LoadingStatus.Pending;
            })
            .addCase(getSlidingFeePrograms.fulfilled, (state, action) => {
                state.initialLoad = LoadingStatus.Completed;
                state.data = action.payload.data;
            })
            .addCase(getSlidingFeePrograms.rejected, (state, action) => {
                state.initialLoad = LoadingStatus.Failed;
            })
            .addCase(getSlidingFeeProgramByPatient.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(getSlidingFeeProgramByPatient.fulfilled, (state, action) => {
                state.loading = LoadingStatus.Completed;
                state.patientSlidingFeePlans = action.payload;
            })
            .addCase(getSlidingFeeProgramByPatient.rejected, (state, action) => {
                state.loading = LoadingStatus.Failed;
            })
            .addCase(getSlidingFeeProgramByPatientPercentage.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(getSlidingFeeProgramByPatientPercentage.fulfilled, (state, action) => {
                state.loading = LoadingStatus.Completed;
                state.patientSlidingFeePlans = action.payload;
            })
            .addCase(getSlidingFeeProgramByPatientPercentage.rejected, (state, action) => {
                state.loading = LoadingStatus.Failed;
            });
    },
});

const { reducer } = slidingFeeProgramsSlice;

export const selectSlidingFeePrograms = (state: RootState): Dictionary<ISlidingFeeProgram> | undefined =>
    state.tenant.slidingFeePrograms.data;
export const selectPatientSlidingFeePrograms = (state: RootState): ISlidingFeePlan | undefined =>
    state.tenant.slidingFeePrograms.patientSlidingFeePlans;
export const selectSlidingFeeProgramsData = createSelector(selectSlidingFeePrograms, (data) => (data ? data : {}));
export const selectPatientSlidingFeeProgramsData = createSelector(selectPatientSlidingFeePrograms, (state) => state);
export const selectSlidingFeeProgramsList = createSelector(selectSlidingFeePrograms, (data) => {
    if (data) return Object.keys(data).map((key) => data[key]);
    return [];
});

export default reducer;
