import { Checkbox, Stack, TextField } from '@fluentui/react';
import IPatientContact from 'api/models/patient-contact.model';
import { IPatientAddress } from 'api/models/patient.model';
import { Field, SubSection } from 'components';
import states from 'components/Field/StateOptions';
import ZipCodeField from 'components/Field/ZipCodeField';
import { useDispatch } from 'react-redux';
import { setContact } from 'state/slices/contacts/contacts.slice';

type Props = {
    address?: IPatientAddress;
    contact: IPatientContact;
};

function ContactAddressFields({ address, contact }: Props): JSX.Element {
    const dispatch = useDispatch();

    const contactAddressStateType = states.find((state) => address?.state === state.key)?.data?.type;

    return (
        <Stack tokens={{ childrenGap: 5 }}>
            <SubSection
                title="Mailing Address"
                headingCenterContent={
                    <Stack horizontal>
                        <Checkbox
                            label="Same address as patient"
                            checked={contact?.addressSameAsPatient}
                            disabled
                            onChange={(e, checked) => {
                                let data: IPatientContact | undefined = undefined;
                                if (!checked) {
                                    data = {
                                        ...contact,
                                        addressSameAsPatient: false,
                                        streetAddress1: '',
                                        streetAddress2: '',
                                        city: '',
                                        state: '',
                                        zip: '',
                                    };
                                } else {
                                    data = {
                                        ...contact,
                                        addressSameAsPatient: true,
                                        ...address,
                                    };
                                }
                                dispatch(setContact(data));
                            }}
                        />
                    </Stack>
                }
            >
                <Stack>
                    <Stack tokens={{ childrenGap: 10 }} grow>
                        <Stack horizontal tokens={{ childrenGap: 10 }} grow>
                            <Stack.Item grow>
                                <TextField
                                    label="Address Line 1"
                                    value={contact?.streetAddress1}
                                    autoComplete="off"
                                    disabled
                                />
                            </Stack.Item>
                            <Stack.Item grow>
                                <TextField
                                    label=" Address Line 2"
                                    value={contact?.streetAddress2}
                                    autoComplete="off"
                                    disabled
                                />
                            </Stack.Item>
                        </Stack>
                        <Stack horizontal tokens={{ childrenGap: 10 }} grow>
                            <Stack.Item grow>
                                <TextField
                                    label="City"
                                    placeholder=""
                                    value={contact?.city}
                                    autoComplete="off"
                                    disabled
                                />
                            </Stack.Item>
                            <Field.SearchCombo
                                label="State"
                                placeholder="(Select State)"
                                selectedKey={contact?.state !== undefined ? contact?.state.toUpperCase() : undefined}
                                options={states}
                                disabled
                            />
                            <ZipCodeField
                                label="Zip"
                                autoComplete="off"
                                maxLength={10}
                                stateType={contactAddressStateType}
                                value={contact?.zip}
                                disabled
                            />
                        </Stack>
                    </Stack>
                </Stack>
            </SubSection>
        </Stack>
    );
}

export default ContactAddressFields;
