import { Dictionary } from '@reduxjs/toolkit';
import { ChartProcedureDiagnosis, ChartProcedurePreAuth, ChartProcedureStatus, IChartProcedure } from 'api/models/chart.model';
import { ICondition } from 'api/models/lookup.model';
import { IProcedure } from 'api/models/procedure.model';
import { ToothArea } from 'api/models/tooth-area';
import { ProcedureActionType } from '../chart/chart.slice';

export const chartProcedurePanelInitialState: ChartProcedurePanelState = {
    selectedTeeth: [],
    selectedAreas: [],
    selectedProceduresTeeth: {},
    procedures: [],
    conditions: [],
    conflictMessages: [],
    isOpen: false,
    selectedCategories: [],
    isEditing: false,
    selectedChartProcedure: undefined,
    selectedStatus: ChartProcedureStatus.Pending,
    selectedAuthorization: '',
    notes: undefined,
    openProcedureSections: [],
    panelTeethData: {},
};

export interface IPanelProcedure {
    generalDiagnosisCodes: ChartProcedureDiagnosis[];
    data: { [key: number | string]: IProcedurePanelToothData };
}

export interface IProcedurePanelToothData {
    diagnosisCodes?: ChartProcedureDiagnosis[];
    procedure?: IProcedure;
    surfaces?: (keyof typeof ToothArea)[];
    groupedTeeth?: number[];
}

export interface ChartProcedurePanelState {
    isEditing: boolean;
    selectedChartProcedure?: IChartProcedure;
    conflictMessages: any[];
    isOpen: boolean;
    procedures: IProcedure[];
    conditions: ICondition[];
    selectedTeeth: number[];
    selectedAreas: (keyof typeof ToothArea)[];
    selectedProceduresTeeth: Dictionary<number[]>;
    selectedActionType?: ProcedureActionType;
    selectedCategories: string[];
    date?: string;
    providerId?: string;
    selectedStatus?: ChartProcedureStatus;
    selectedPreAuth?: ChartProcedurePreAuth;
    selectedAuthorization: string;
    notes?: string;
    openProcedureSections: string[];
    panelTeethData: Dictionary<IPanelProcedure>;
}
