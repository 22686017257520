import { IComboBoxOption } from '@fluentui/react';
import { createAsyncThunk, createSelector, createSlice, Dictionary } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import LookupBase from 'api/models/lookup-base.model';
import { AxiosResponse, AxiosError } from 'axios';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { LookupState } from 'interfaces/lookup-state';
import { orderBy } from 'lodash';
import { RootState } from 'state/store';

export interface Race extends LookupBase {
    order: number;
}

type RacesState = LookupState<Race>;

const initialState: RacesState = {
    initialLoad: LoadingStatus.Idle,
    loading: LoadingStatus.Idle,
    data: {},
};

export const getRaces = createAsyncThunk<
    Dictionary<Race>,
    void,
    {
        rejectValue: string;
    }
>('getRaces', async () => {
    const res = await dentalApi.getRaces();
    return res.data;
});

const racesSlice = createSlice({
    name: 'races',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getRaces.pending, (state) => {
                state.initialLoad = LoadingStatus.Pending;
            })
            .addCase(getRaces.fulfilled, (state, action) => {
                state.initialLoad = LoadingStatus.Completed;
                state.data = action.payload;
            })
            .addCase(getRaces.rejected, (state, action) => {
                state.initialLoad = LoadingStatus.Failed;
                state.errors = action.payload;
            });
    },
});

const { reducer } = racesSlice;

export const selectRacesObject = (state: RootState): Dictionary<LookupBase> => state.tenant.races.data;
export const selectRacesData = createSelector(selectRacesObject, (data) => (data ? data : {}));

export const selectRaces = createSelector(selectRacesData, (lookups) => {
    return lookups;
});

export const racesList = createSelector(selectRaces, (lookups) => {
    if (lookups) {
        const list = Object.keys(lookups).map((key) => lookups[key]);
        return list.filter((lookup) => !lookup?.isDeleted) as LookupBase[];
    }
    return [];
});

export const racesOptionsList = createSelector(
    racesList,
    (lookupsList) =>
        orderBy(lookupsList, 'order').map((dx) => ({
            key: dx?.id ?? '',
            text: `${dx.displayName.length > 35 ? `${dx.displayName.slice(0, 35)}...` : dx.displayName}`,
            title: `${dx.displayName}`,
        })) as IComboBoxOption[],
);

export default reducer;
