import { PayloadAction } from '@reduxjs/toolkit';
import IPatientAppointment from 'api/models/Scheduling/patientAppointment.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { SyncStatus } from 'interfaces/syncing-statuses';
import { ExtraReducer } from 'state/store';
import { SchedulingState } from '../scheduling.state';
import { getAppointmentToCancel, getCancellationReasons } from './appointment-to-cancel.actions';

export const appointmentsToRemoveReducers = {
    cleanupCancelAppointment: (state: SchedulingState) => {
        state.appointmentToCancel.data = undefined;
        state.appointmentToCancel.appointmentToRemoveId = undefined;
        state.appointmentToCancel.isOpen = false;
        state.appointmentToCancel.loadingCancelationReasons = LoadingStatus.Idle;
        state.appointmentToCancel.loading = LoadingStatus.Idle;
        state.appointmentToCancel.saving = SyncStatus.Idle;
    },
    setCancelAppointmentModalOpen: (
        state: SchedulingState,
        action: PayloadAction<{ isOpen?: boolean; appointmentToRemoveId?: string }>,
    ): void => {
        state.appointmentToCancel.appointmentToRemoveId = action.payload.appointmentToRemoveId;
        state.appointmentToCancel.isOpen = action.payload.isOpen;
    },
    editAppointmentToCancelProp: (
        state: SchedulingState,
        action: PayloadAction<{ key: keyof IPatientAppointment; value: unknown }>,
    ) => {
        const { key, value } = action.payload;
        if (state.appointmentToCancel.data) (state.appointmentToCancel.data[key] as unknown) = value;
    },
};

export const appointmentToRemoveExtraReducers: ExtraReducer<SchedulingState> = (builder) =>
    builder
        // [GET] Appointment to Remove
        .addCase(getAppointmentToCancel.pending, (state) => {
            state.appointmentToCancel.loading = LoadingStatus.Pending;
        })
        .addCase(getAppointmentToCancel.fulfilled, (state, action) => {
            state.appointmentToCancel.loading = LoadingStatus.Completed;
            state.appointmentToCancel.data = action.payload;
        })
        .addCase(getAppointmentToCancel.rejected, (state) => {
            state.appointmentToCancel.loading = LoadingStatus.Failed;
        })

        // [GET] Appointment cancellation reasons
        .addCase(getCancellationReasons.pending, (state) => {
            state.appointmentToCancel.loadingCancelationReasons = LoadingStatus.Pending;
        })
        .addCase(getCancellationReasons.fulfilled, (state, action) => {
            state.appointmentToCancel.loadingCancelationReasons = LoadingStatus.Completed;
            state.appointmentToCancel.reasonsForCancellation = action.payload;
        })
        .addCase(getCancellationReasons.rejected, (state) => {
            state.appointmentToCancel.loadingCancelationReasons = LoadingStatus.Failed;
        });
