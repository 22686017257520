import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import { IProcedureCategories, IProcedureCategory } from 'api/models/procedure-category.model';
import { AxiosResponse, AxiosError } from 'axios';
import { LoadingStatus, LoadingStatuses } from 'interfaces/loading-statuses';
import { RootState } from 'state/store';

type ProcedureCategoriesState = {
    initialLoad: LoadingStatuses
    loading: LoadingStatuses
    data?: IProcedureCategories
    errors?: string;
};

const initialState: ProcedureCategoriesState = {
    initialLoad: LoadingStatus.Idle,
    loading: LoadingStatus.Idle,
};

export const getProcedureCategories = createAsyncThunk<
    AxiosResponse<IProcedureCategories>,
    void,
    {
        rejectValue: AxiosError;
    }
>('getProcedureCategories', async () => {
    const res = await dentalApi.getProcedureCategories();
    return res;
});

const procedureCategoriesSlice = createSlice({
    name: 'procedureCategories',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getProcedureCategories.pending, (state) => {
                state.initialLoad = LoadingStatus.Pending;
            })
            .addCase(getProcedureCategories.fulfilled, (state, action) => {
                state.initialLoad = LoadingStatus.Completed;
                state.data = action.payload.data;
            })
            .addCase(getProcedureCategories.rejected, (state, action) => {
                state.initialLoad = LoadingStatus.Failed;
                state.errors = action.payload?.message;
            });
    },
});

const { reducer } = procedureCategoriesSlice;

export const selectProcedureCategories = (state: RootState): IProcedureCategory[] | undefined => state.tenant.procedureCategories.data?.categories;

export default reducer;
