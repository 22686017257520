import {
    Panel,
    PanelType,
    Stack,
    PrimaryButton,
    DefaultButton,
    Spinner,
    IDropdownOption,
    IconButton,
    Separator,
    useTheme,
    MessageBar,
    MessageBarType,
    Dropdown,
} from '@fluentui/react';
import { useSelector } from 'hooks';
import { useDispatch } from 'react-redux';
import {
    selectAllergiesRequiredErrors,
    selectAllergiesToAdd,
    selectAllergyReactionOptions,
    selectIsAllergyPanelOpen,
    selectPatientNKDA,
    selectSavingAllergies,
} from 'state/slices/patient/allergies/allergies.selectors';
import { createOrUpdatePatientAllergies, getAllergyReactions } from 'state/slices/patient/allergies/allergies.actions';
import {
    addAllergy,
    setAllergiesPanelOpen,
    deleteAllergy,
    addReactionToAllergy,
    deleteReactionToAllergy,
    updateAllergyReaction,
    updateAllergyReactionSeverity,
    setAllergyOnsetDate,
    updateAllergyCriticality,
    clearAllergiesErrors,
} from 'state/slices/patient/patient.slice';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { useEffect } from 'react';
import Field from 'components/Field/Field';
import { IAllergen, IAllergy, ReactionSeverity } from 'api/models/patient-allergy.model';
import { map } from 'lodash';
import PanelSectionHeader from 'pages/components/PanelSectionHeader';
import { useParams } from 'react-router';
import { RouteParams } from 'interfaces/route-params';
import AllergenDropdown from './AllergenDropdown';
import { isFuture } from 'date-fns';

export default function AllergiesPanel(): JSX.Element {
    const { patientId, tenantId, encounterId } = useParams<RouteParams>();
    const dispatch = useDispatch();

    const allergies = useSelector(selectAllergiesToAdd);

    const nkda = useSelector(selectPatientNKDA);
    const isOpen = useSelector(selectIsAllergyPanelOpen);
    const saving = useSelector(selectSavingAllergies);
    const errors = useSelector(selectAllergiesRequiredErrors);

    useEffect(() => {
        if (isOpen) {
            dispatch(getAllergyReactions());
        } else {
            dispatch(clearAllergiesErrors());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const allergyOnsetDate = allergies.find((a) => isFuture(new Date(a.onsetDate))) ? true : false;

    const isSaving = saving === LoadingStatus.Pending;

    const disableButton = isSaving || allergyOnsetDate;

    const _onDismiss = () => dispatch(setAllergiesPanelOpen(false));
    const _handleAddAllergy = (allergen?: IAllergen) => {
        if (allergen && allergen.id && encounterId && allergen.references?.athenaOne) {
            dispatch(
                addAllergy({
                    allergenId: allergen.id as string,
                    athenaId: allergen.references?.athenaOne,
                    encounterId,
                    snomedCode: allergen.snomedCode,
                    allergenName: allergen.name,
                }),
            );
        }
    };

    const onRenderFooterContent = () => (
        <Stack tokens={{ childrenGap: 10 }}>
            {errors.length && <MessageBar messageBarType={MessageBarType.error}>{errors.join(', ')}</MessageBar>}
            <Stack horizontal tokens={{ childrenGap: 10 }}>
                <PrimaryButton text="Save" title="Save" disabled={disableButton} onClick={_handleCreateOrSavePatientAllergies} />
                <DefaultButton text="Cancel" title="Cancel" onClick={_onDismiss} />
                {isSaving && <Spinner label="Saving..." labelPosition="right" />}
            </Stack>
        </Stack>
    );

    const _handleCreateOrSavePatientAllergies = () => {
        dispatch(createOrUpdatePatientAllergies(tenantId, patientId));
    };

    const allergyFields = allergies.length
        ? allergies.map((allergy, index) => <AllergyInputs key={index} index={index} allergy={allergy} />)
        : null;

    return (
        <Panel
            headerText="Add Allergies"
            isOpen={isOpen}
            styles={{
                content: { overflowY: 'auto', overflowX: 'hidden', flex: 1, position: 'relative' },
                root: { overflow: 'hidden' },
                scrollableContent: { overflow: 'hidden', display: 'flex', flexDirection: 'column' },
            }}
            type={PanelType.medium}
            customWidth={'800px'}
            isFooterAtBottom={true}
            onDismiss={_onDismiss}
            onRenderFooterContent={onRenderFooterContent}
        >
            <Stack tokens={{ childrenGap: 10 }}>
                <AllergenDropdown placeholder={'(Select allergy)'} addAllergy={_handleAddAllergy} />
                {nkda && (
                    <MessageBar messageBarType={MessageBarType.warning}>
                        Ensure this list does not include any drug allergies
                    </MessageBar>
                )}
                {allergyFields}
            </Stack>
        </Panel>
    );
}

type AllergyInputProps = { allergy: IAllergy; index: number };
function AllergyInputs({ allergy, index }: AllergyInputProps): JSX.Element {
    const dispatch = useDispatch();
    const theme = useTheme();
    const reactionOptions = useSelector(selectAllergyReactionOptions);

    const severityOptions: IDropdownOption[] = map(ReactionSeverity, (severity) => ({
        key: severity,
        text: severity,
    }));

    const _handleDeleteAllergy = () => dispatch(deleteAllergy(index));

    const _handleAddReaction = () => {
        dispatch(addReactionToAllergy(index));
    };

    const _handleDeleteReaction = (reactionIndex: number) => {
        dispatch(deleteReactionToAllergy({ allergyIndex: index, reactionIndex }));
    };

    const _handleUpdateAllergyReaction = (reactionIndex: number, snomedcode: number) => {
        dispatch(updateAllergyReaction({ allergyIndex: index, reactionIndex, snomedcode }));
    };

    const _handleUpdateAllergReactionSeverity = (reactionIndex: number, severity: ReactionSeverity) => {
        dispatch(updateAllergyReactionSeverity({ allergyIndex: index, reactionIndex, severity }));
    };

    const _handleUpdateOnsetDate = (ev?: React.FormEvent, value?: string) => {
        dispatch(setAllergyOnsetDate({ allergyIndex: index, date: value ? value : '' }));
    };

    const reactionFields = allergy.reactions?.length
        ? allergy.reactions.map((reaction, reactionIndex) => {
              const firstIndex = reactionIndex === 0;
              const filteredReactionOptions = reactionOptions.filter((react) =>
                  react.key === reaction.snomedcode
                      ? true
                      : allergy.reactions?.findIndex((r) => r.snomedcode === react.key) === -1,
              );
              return (
                  <Stack key={reactionIndex} className="ms-motion-fadeIn">
                      <Stack tokens={{ childrenGap: 10 }} horizontal verticalAlign="end" grow>
                          <Stack.Item grow>
                              <Field.SearchCombo
                                  options={filteredReactionOptions}
                                  label={firstIndex ? 'Reaction' : ''}
                                  placeholder="(Select Reaction)"
                                  style={{ minWidth: 215 }}
                                  maxResults={50}
                                  selectedKey={reaction.snomedcode}
                                  onChange={(event, option) => {
                                      if (option && option.key) _handleUpdateAllergyReaction(reactionIndex, option.key as number);
                                  }}
                              />
                          </Stack.Item>
                          <Stack.Item grow>
                              <Field.Dropdown
                                  options={severityOptions}
                                  label={firstIndex ? 'Severity' : ''}
                                  placeholder="(Select Severity)"
                                  style={{ minWidth: 215 }}
                                  selectedKey={reaction.severity}
                                  disabled={!reaction.snomedcode}
                                  onChange={(event, option) => {
                                      if (option && option.key)
                                          _handleUpdateAllergReactionSeverity(reactionIndex, option.key as ReactionSeverity);
                                  }}
                              />
                          </Stack.Item>
                          <Stack.Item>
                              <IconButton
                                  iconProps={{ iconName: 'Trash' }}
                                  onClick={() => _handleDeleteReaction(reactionIndex)}
                              />
                          </Stack.Item>
                      </Stack>
                  </Stack>
              );
          })
        : null;

    const allergyText = allergy.allergenName;

    const onSetDateValidation = isFuture(new Date(allergy?.onsetDate)) ? 'Date cannot be in the future' : '';
    return (
        <>
            <Separator />
            <Stack tokens={{ childrenGap: 10 }} className="ms-motion-fadeIn">
                <Stack>
                    <PanelSectionHeader
                        text={allergyText ?? ''}
                        rightAction={{
                            text: 'Remove Allergy',
                            onClick: _handleDeleteAllergy,
                        }}
                    />
                    <Stack tokens={{ childrenGap: 10 }} grow>
                        <Stack.Item grow>
                            <Field.Date
                                value={allergy.onsetDate}
                                label="Onset Date"
                                style={{ minWidth: 215 }}
                                onChange={_handleUpdateOnsetDate}
                                errorMessage={onSetDateValidation}
                            />
                        </Stack.Item>
                        <Dropdown
                            selectedKey={allergy?.criticality ?? ''}
                            onChange={(ev, option) => {
                                if (option?.key !== undefined) {
                                    dispatch(updateAllergyCriticality({ indexOfAllergy: index, value: option.key as string }));
                                }
                            }}
                            label="Criticality"
                            options={[
                                {
                                    key: '',
                                    text: '(Select criticality)',
                                },
                                {
                                    key: 'low',
                                    text: 'low',
                                },
                                {
                                    key: 'high',
                                    text: 'high',
                                },
                                {
                                    key: 'unable-to-assess',
                                    text: 'unable to assess',
                                },
                            ]}
                        ></Dropdown>
                    </Stack>
                </Stack>
                <Stack>
                    <PanelSectionHeader
                        subHeader
                        text="Reactions"
                        rightAction={{
                            text: 'Add Reaction',
                            onClick: _handleAddReaction,
                        }}
                    />
                    <Stack
                        tokens={{ childrenGap: 5 }}
                        style={{ backgroundColor: theme.palette.neutralLighterAlt, padding: '0 10px 10px 10px' }}
                    >
                        {reactionFields}
                    </Stack>
                </Stack>
            </Stack>
        </>
    );
}
