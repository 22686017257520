import { useSelector } from 'hooks';
import { Redirect, useParams } from 'react-router-dom';
import { selectWorkListsMenuLoading, selectWorkListsWithAccess } from 'state/slices/admin-huddle/admin-huddle.selectors';
import ClaimRejections from './components/ClaimRejections/ClaimRejections';
import Predeterminations from './components/Predeterminations/Predeterminations';
import ReadyToReview from './components/EncounterWorkLists/ReadyToReview';

import EditEncounters from './components/EncounterWorkLists/EditEncounters';
import ReadyToRebill from './components/EncounterWorkLists/ReadyToRebill';
import { WorkListComponents } from 'api/models/worklist-menu.model';
import BilledInsurance from './components/OpenBilledEncounters/BilledInsurance';
import BilledPatient from './components/OpenBilledEncounters/BilledPatient';
import { LoadingStatus } from 'interfaces/loading-statuses';
import MissingSlips from './components/MissingSlipsWorkLists/MissingSlips';
import Denials from './components/EncounterWorkLists/Denials';
import { Helmet } from 'react-helmet';

const workListComponents: Record<WorkListComponents, () => JSX.Element | null> = {
    appointmentRecare: PlaceholderWorklist,
    claimRejections: ClaimRejections,
    codingReview: PlaceholderWorklist,
    creditBalances: PlaceholderWorklist,
    noActivity: PlaceholderWorklist,
    noShows: PlaceholderWorklist,
    patientCollections: PlaceholderWorklist,
    paymentPlans: PlaceholderWorklist,
    predeterminedRequests: Predeterminations,
    readyToReview: ReadyToReview,
    referrals: PlaceholderWorklist,
    denials: Denials,
    billedInsurance: BilledInsurance,
    billedPatient: BilledPatient,
    editEncounters: EditEncounters,
    readyToRebill: ReadyToRebill,
    missingSlips: MissingSlips,
};

export function WorkList(): JSX.Element | null {
    const { tenantId, workListId } = useParams<{ workListId: string; tenantId: string }>();
    const workLists = useSelector((state) => selectWorkListsWithAccess(state, tenantId));
    const workListsLoading = useSelector(selectWorkListsMenuLoading);

    const workList = workLists.find((workList) => workList.id === workListId);

    if (!workList && (workListsLoading === LoadingStatus.Completed || workListsLoading === LoadingStatus.Failed))
        return <Redirect to={`/${tenantId}/administrative`}></Redirect>;


    if (!workList) return null;
    const RenderWorklist = workListComponents[workList.component];

    return <>
        <Helmet title={`Worklist - ${workList.displayName}`} />
        <RenderWorklist /></>;
}

function PlaceholderWorklist() {
    return <div>Placeholder Worklist</div>;
}
