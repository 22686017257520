import { FormName } from 'api/models/form';
import CariesRiskAdult from './CariesRiskAdult/CariesRiskAdult';
import CariesRiskChild from './CariesRiskChild/CariesRiskChild';
import { questions as adultQuestions, noteTransalations as adultNoteTranslations } from './CariesRiskAdult/constants';
import { questions as childQuestions, noteTransalations as childNoteTranslations } from './CariesRiskChild/constants';
import { questions as softTissueQuestions, noteTransalations as softTissueNoteTranslations } from './SoftTissueExam/constants';
import {
    questions as dentalHistoryQuestions,
    noteTransalations as dentalHistoryNoteTranslations,
} from './Dental History/constants';
import { questions as patientProblemQuestions, noteTransalations as patientProblemNoteTranslations } from './Problems/constants';
import cariesRiskAdultScore from './CariesRiskAdult/score';
import cariesRiskChildScore from './CariesRiskChild/score';
import SoftTissueExam from './SoftTissueExam/SoftTissueExam';
import DentalHistory from './Dental History/DentalHistory';
import { DefaultFormPanelFooter } from 'pages/components/FormPanel/FormPanel';
import DentalHistoryFooter from './Dental History/DentalHistoryFooter';
import PatientProblems from './Problems/PatientProblems';
import ProblemFooter from './Problems/ProblemsFooter';

export interface IFormReference {
    title: string;
    questions?: Record<string, unknown>;
    noteTranslations: Record<string, unknown>;
    component: () => JSX.Element | null;
    footerComponent: () => JSX.Element | null;
    calculateScore?: (data: Record<string, unknown>) => string | number | undefined;
}
export interface IFormReferenceListItem extends IFormReference {
    formName: FormName;
}

export type FormsLookup = {
    [key: string]: IFormReference;
};

const forms: FormsLookup = {
    [FormName.CariesRiskAdult]: {
        title: 'Caries Risk Assessment (Age > 6)',
        questions: adultQuestions,
        noteTranslations: adultNoteTranslations,
        component: CariesRiskAdult,
        footerComponent: DefaultFormPanelFooter,
        calculateScore: cariesRiskAdultScore,
    },
    [FormName.CariesRiskChild]: {
        title: 'Caries Risk Assessment (0 - 6 years)',
        questions: childQuestions,
        noteTranslations: childNoteTranslations,
        component: CariesRiskChild,
        footerComponent: DefaultFormPanelFooter,
        calculateScore: cariesRiskChildScore,
    },
    [FormName.SoftTissueExam]: {
        title: 'Soft Tissue Exam',
        questions: softTissueQuestions,
        noteTranslations: softTissueNoteTranslations,
        footerComponent: DefaultFormPanelFooter,
        component: SoftTissueExam,
    },
    [FormName.DentalHistory]: {
        title: 'Dental History',
        questions: dentalHistoryQuestions,
        noteTranslations: dentalHistoryNoteTranslations,
        footerComponent: DentalHistoryFooter,
        component: DentalHistory,
    },
    [FormName.PatientProblem]: {
        title: 'Patient Reported Problems',
        questions: patientProblemQuestions,
        noteTranslations: patientProblemNoteTranslations,
        footerComponent: ProblemFooter,
        component: PatientProblems,
    },
};

export const formsList: IFormReferenceListItem[] = Object.keys(forms).map((key) => ({
    ...forms[key],
    formName: key as FormName,
}));

export default forms;
