import IWorkListAppointmentView from 'api/models/worklist-appointment-view';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import formatPhoneNumber from 'utils/formatPhoneNumber';

const patientPrimaryPhone: ISortableColumn<IWorkListAppointmentView> = {
    key: 'patientPrimaryPhone',
    minWidth: 100,
    maxWidth: 100,
    isResizable: true,
    name: 'Pt. Primary Phone',
    fieldName: 'primaryPhone',
    onRender: (item) => {
        return item?.primaryPhone ? formatPhoneNumber(item.primaryPhone) : '';
    },
};

export default patientPrimaryPhone;
