import { IColumn, MessageBar, Text, TooltipHost } from '@fluentui/react';
import { IEncounterHistory } from 'api/models/encounter.model';
import { Section } from 'components';
import { useSelector } from 'hooks';
import { selectSelectedPatientPastEncounters } from 'state/slices/patient/patient.selectors';
import convertDashedDateString from 'utils/convertDateStringToLocal';
import SharedDetailsList from '../Shared/SharedDetailsList';
import { selectEncounterStatusList } from 'state/slices/tenant/encounter-status.slice';
import useUserIdentities from 'hooks/store/useUserIdentities';
import UserDisplayName from 'components/UserDisplayName';
import { convertFromMilitaryTime } from 'utils/convertFromMilitaryTime';

function EncounterHistoryList(): JSX.Element {
    // Need to filter out appointments from this list since we grab both appointments and encounters at the same time
    const pastEncounters = useSelector(selectSelectedPatientPastEncounters)?.filter((res) => res.encounterId);

    const encounterStatus = useSelector(selectEncounterStatusList);
    const { userIdentitiesData } = useUserIdentities();
    const columns: IColumn[] = [
        {
            key: 'encounterDate',
            minWidth: 150,
            isResizable: true,
            name: 'Encounter Date/Time',
            fieldName: 'date',
            onRender: (item?: IEncounterHistory) => {
                if (item) {
                    const date = convertDashedDateString(item.date);
                    const startTime = item.appointmentStartTime ? ` - ${convertFromMilitaryTime(item.appointmentStartTime)}` : '';
                    return (
                        <Text variant="mediumPlus" title={`${date}${startTime}`}>
                            {date}
                            {startTime}
                        </Text>
                    );
                }
            },
        },
        {
            key: 'locationOfCare',
            minWidth: 150,
            isResizable: true,
            name: 'Location of Care',
            fieldName: 'locationOfCareName',
            onRender: (item?: IEncounterHistory) => {
                if (item) {
                    return (
                        <Text variant="mediumPlus">{item.locationOfCareName ? item.locationOfCareName : 'Unknown Location'}</Text>
                    );
                }
            },
        },

        {
            key: 'treatingProvider',
            minWidth: 150,
            isResizable: true,
            name: 'Treating Provider',
            getValueKey: (item) => {
                const lastName = userIdentitiesData[item.providerId]?.lastName;
                return lastName ?? '';
            },
            onRender: (item?: IEncounterHistory) => {
                if (item) {
                    return (
                        <Text variant="mediumPlus">
                            <UserDisplayName userId={item.providerId} useSuffix />
                        </Text>
                    );
                }
            },
        },
        {
            key: 'attendingProvider',
            minWidth: 150,
            isResizable: true,
            name: 'Attending Provider',
            getValueKey: (item) => {
                const lastName = userIdentitiesData[item.providerId]?.lastName;
                return lastName ?? '';
            },
            onRender: (item?: IEncounterHistory) => {
                if (item) {
                    return (
                        <Text variant="mediumPlus">
                            <UserDisplayName userId={item.supervisingProviderId} useSuffix />
                        </Text>
                    );
                }
            },
        },
        {
            key: 'billingProvider',
            minWidth: 150,
            isResizable: true,
            name: 'Billing Provider',
            getValueKey: (item) => {
                const lastName = userIdentitiesData[item.billingProviderId]?.lastName;
                return lastName ?? '';
            },
            onRender: (item?: IEncounterHistory) => {
                if (item) {
                    return (
                        <Text variant="mediumPlus">
                            <UserDisplayName userId={item.billingProviderId} useSuffix />
                        </Text>
                    );
                }
            },
        },
        {
            key: 'hygienist',
            minWidth: 150,
            isResizable: true,
            name: 'Hygienist',
            getValueKey: (item) => {
                const lastName = userIdentitiesData[item.hygienistId]?.lastName;
                return lastName ?? '';
            },
            onRender: (item?: IEncounterHistory) => {
                if (item) {
                    console.log(item.hygienistId);
                    return (
                        <Text variant="mediumPlus">
                            <UserDisplayName userId={item.hygienistId} useSuffix />
                        </Text>
                    );
                }
            },
        },
        {
            key: 'RDA',
            minWidth: 150,
            isResizable: true,
            name: 'RDA',
            getValueKey: (item) => {
                const lastName = userIdentitiesData[item.providerId]?.lastName;
                return lastName ?? '';
            },
            onRender: (item?: IEncounterHistory) => {
                if (item) {
                    return (
                        <Text variant="mediumPlus">
                            <UserDisplayName userId={item.registeredDentalAssistantId} useSuffix />
                        </Text>
                    );
                }
            },
        },
        {
            key: 'reasonForEncounter',
            minWidth: 150,
            isResizable: true,
            name: 'Reason For Encounter',
            fieldName: 'encounterReason',
            onRender: (item?: IEncounterHistory) => {
                if (item) {
                    return (
                        <TooltipHost content={item.encounterReason}>
                            <Text variant="mediumPlus">{item.encounterReason}</Text>
                        </TooltipHost>
                    );
                }
            },
        },
        {
            key: 'status',
            minWidth: 150,
            isResizable: true,
            name: 'Encounter Status',
            fieldName: 'status',
            onRender: (item?: IEncounterHistory) => {
                const displayName =
                    encounterStatus?.find((encStatus) => encStatus?.code === item?.status)?.displayName ?? 'Unknown Status';
                return <Text variant="mediumPlus">{displayName}</Text>;
            },
        },
    ];

    return (
        <Section heading={<Text variant="xLarge">Dental Encounter History</Text>}>
            {pastEncounters && pastEncounters.length > 0 ? (
                <SharedDetailsList columns={columns} sortOnMount={true} items={pastEncounters ?? []} />
            ) : (
                <MessageBar>No encounter history.</MessageBar>
            )}
        </Section>
    );
}

export default EncounterHistoryList;
