import { Checkbox, Stack, Text, TextField } from '@fluentui/react';
import { VitalNameType } from 'api/models/patient-vital.model';
import { Field, SubSection } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { heightRefused, updateReading } from 'state/slices/patient/patient.slice';

import { NonBPNameTypes } from 'state/slices/patient/vitals/vitals.reducers';
import { RootState } from 'state/store';

const HtVitalsProperties = (): JSX.Element => {
    const dispatch = useDispatch();
    const newVitals = useSelector((state: RootState) => state.patient.vitals.vitalPanelData);

    const heightFeet = newVitals[VitalNameType.Height];
    const heigthInches = newVitals[VitalNameType.HeightInches];
    const heightType = newVitals[VitalNameType.HeightType];
    const heightRefusedValue = newVitals[VitalNameType.HeightRefused];
    const heightRefusedReason = newVitals[VitalNameType.HeightRefusedReason];

    const heightOptions = [
        {
            key: 'Stated',
            text: 'Stated',
        },
        {
            key: 'Lying',
            text: 'Lying',
        },
        {
            key: 'Standing',
            text: 'Standing',
        },
        {
            key: 'Preoperative',
            text: 'Preoperative',
        },
        {
            key: 'None',
            text: 'None',
        },
    ];

    const handleOnChange = (vitalType: NonBPNameTypes, value?: string): void => {
        dispatch(updateReading({ vitalType, value: value ?? '' }));
    };

    const handleRefused = (ev?: React.FormEvent, checked?: boolean) => {
        dispatch(heightRefused(checked ? true : false));
    };

    const performedOptions = [
        {
            key: 'Not Indicated',
            text: 'Not Indicated',
        },
        {
            key: 'Not Tolerated',
            text: 'Not Tolerated',
        },
        {
            key: 'Patient Refused',
            text: 'Patient Refused',
        },
    ];

    const isChecked = !!heightRefusedValue;

    return (
        <Stack tokens={{ childrenGap: 5 }}>
            <SubSection title="Height">
                <Stack tokens={{ childrenGap: 10 }}>
                    <Stack tokens={{ childrenGap: 10 }} horizontal verticalAlign="end" grow>
                        <Stack.Item styles={{ root: { maxWidth: 125 } }}>
                            <TextField
                                label=""
                                autoComplete="off"
                                placeholder="Feet"
                                value={heightFeet}
                                disabled={isChecked}
                                maxLength={1}
                                onChange={(ev, value) => {
                                    handleOnChange(VitalNameType.Height, value);
                                }}
                            />
                        </Stack.Item>
                        <Text>/</Text>
                        <Stack.Item styles={{ root: { maxWidth: 125 } }}>
                            <TextField
                                autoComplete="off"
                                placeholder="Inches"
                                value={heigthInches}
                                maxLength={2}
                                disabled={isChecked}
                                onChange={(ev, value) => {
                                    handleOnChange(VitalNameType.HeightInches, value);
                                }}
                            />
                        </Stack.Item>
                        <Stack.Item grow>
                            <Field.Dropdown
                                options={heightOptions}
                                label=""
                                placeholder="Height Option"
                                disabled={isChecked}
                                selectedKey={heightType}
                                onChange={(event, option, index) => {
                                    handleOnChange(VitalNameType.HeightType, option?.key as string);
                                }}
                            />
                        </Stack.Item>
                    </Stack>
                    <Stack tokens={{ childrenGap: 10 }} horizontal verticalAlign="end" grow>
                        <Stack.Item>
                            <Checkbox label="Not Performed" checked={isChecked} onChange={handleRefused} />
                        </Stack.Item>
                        {isChecked && (
                            <Stack.Item grow>
                                <Field.Dropdown
                                    options={performedOptions}
                                    label=""
                                    placeholder="Select reason"
                                    disabled={!isChecked}
                                    selectedKey={heightRefusedReason}
                                    onChange={(event, option) =>
                                        option && handleOnChange(VitalNameType.HeightRefusedReason, option.key as string)
                                    }
                                />
                            </Stack.Item>
                        )}
                    </Stack>
                </Stack>
            </SubSection>
        </Stack>
    );
};

export default HtVitalsProperties;
