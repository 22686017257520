import { Dictionary } from '@reduxjs/toolkit';
import LookupBase from '../lookup-base.model';

interface ILocationOfCare extends LookupBase {
    description?: string;
    placeOfServiceFacility?: string;
    placeOfServiceTypeId?: string;
    placeOfServiceTypeName?: string;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zip?: string;
    phone?: string;
    timeZone?: string;
    timeZoneName?: string;
    timeZoneOffset?: string;
    clinicals?: string;
    departmentType?: LocationOfCareDepartmentType;
    references?: Dictionary<unknown>;
}

export enum LocationOfCareDepartmentType {
    Dental = 'dental',
}

export default ILocationOfCare;
