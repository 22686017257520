import { IColumn, Text } from '@fluentui/react';
import { IProcedure } from 'api/models/procedure.model';
import { EditDetailsColumn } from 'components/EditDetailsColumn';
import { useSelector } from 'hooks';
import { useDispatch } from 'react-redux';
import { handleProcedureBrowserClick } from 'state/slices/charting/procedure-panel/procedure-panel.actions';
import { selectFilteredProcedureBrowserProcedures } from 'state/slices/charting/procedure-panel/procedure-panel.selectors';
import { CategoryItemBrowser } from 'pages/components';
import { chartingActions } from 'state/slices/charting/chart/chart.slice';

const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

function ProcedureBrowserList(): JSX.Element {
    const dispatch = useDispatch();
    const _proceduresList = useSelector(selectFilteredProcedureBrowserProcedures);
    const _onClick = (item?: IProcedure) => {
        if (item) dispatch(handleProcedureBrowserClick(item.id));
    };
    const _columns: IColumn[] = [
        {
            key: 'category',
            minWidth: 100,
            maxWidth: 100,
            name: 'Category',
            fieldName: 'category',
        },
        {
            key: 'description',
            minWidth: 200,
            name: 'Description',
            onRender: (item?: IProcedure) => {
                if (item) {
                    return <EditDetailsColumn variant="smallPlus" title={item?.displayName} editOnClick={() => _onClick(item)} />;
                }
            },
            fieldName: 'displayName',
        },
        {
            key: 'code',
            minWidth: 50,
            maxWidth: 50,
            name: 'Code',
            fieldName: 'code',
        },
        {
            key: 'fee',
            minWidth: 70,
            maxWidth: 70,
            name: 'Fee',
            onRender: (item?: IProcedure) => {
                if (item) {
                    return <Text variant="smallPlus">{formatter.format(item?.fee ?? 0)}</Text>;
                }
                return null;
            },
            fieldName: 'fee',
        },
    ];

    return (
        <CategoryItemBrowser<IProcedure>
            items={_proceduresList}
            columns={_columns}
            onItemInvoked={_onClick}
            searchKeys={['displayName', 'code']}
            mountSortColumns={['code']}
            searchPlaceholder={'Find procedures (Code or Description)'}
            onCategoryChange={(cat) => dispatch(chartingActions.setProcedurePanelCategories(cat))}
            threshold={0.0}
            categories={[
                'All',
                'Diagnostic',
                'Preventive',
                'Restorative',
                'Endodontics',
                'Periodontics',
                'Prosthodontics, removable',
                'Maxillofacial Prosthetics',
                'Implant Services',
                'Prosthodontics, fixed',
                'Oral & Maxillofacial Surgery',
                'Orthodontics',
                'Adjunctive General Services',
                'Sleep Apnea Services',
            ]}
        />
    );
}

export default ProcedureBrowserList;
