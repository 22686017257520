import { Dropdown, IDropdownOption, IDropdownProps } from '@fluentui/react';
import { useSelector } from 'hooks';
import { FormEvent } from 'react';
import { adjustmentReasonOptions } from 'state/slices/tenant/adjustment-reasons.slice';

type Props = Omit<IDropdownProps, 'options' | 'onChange'> & {
    onChange: (adjustmentReasonId?: string | number) => void;
};
export function AdjustmentReasonDropdown({ onChange, ...props }: Props): JSX.Element {
    const _adjustmentReasonOptions = useSelector(adjustmentReasonOptions);
    const _onChange = (e: FormEvent, option?: IDropdownOption) => onChange(option?.key);

    return <Dropdown label="Adjustment Reason" onChange={_onChange} {...props} options={_adjustmentReasonOptions} />;
}
