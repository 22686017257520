import Tooth from './Tooth';
import * as PIXI from 'pixi.js-legacy';
import { toothSpriteReferences } from './spriteList';
import PixiText from './PixiText';
import { ChartApp } from './ChartApp';

export class TextCircle extends PIXI.Container {
    private _tooth: Tooth;
    private _toothWidth: number;

    constructor(tooth: Tooth) {
        super();
        this._tooth = tooth;
        this._toothWidth = this._tooth.getToothWidth;

        this.pivot.set(0.5);
        this.position.set(this.position.x + this._toothWidth / 2);
        this._createAndAddTextCircle();
        this.accessible = true;
        this.interactive = true;
    }

    private get getToothName() {
        const toothId = this._tooth.data.id;
        const dentitionMode = this._tooth.data.dentitionMode;

        if (this._tooth.isMolar && (dentitionMode === 'Primary' || dentitionMode === 'PrimarySupernumerary')) return '';

        return toothSpriteReferences.find((tooth) => tooth.id === toothId)?.displayName;
    }

    private _createAndAddTextCircle() {
        const text = new PixiText(`${this.getToothName}`, { fill: ChartApp.getThemePalleteItem('black') });
        const circle = new PIXI.Graphics();

        text.anchor.set(0.5);
        text.name = 'text';

        circle.position.set(text.x, text.y);
        circle.beginFill(ChartApp.getThemePalleteItem('themeLight'));
        circle.drawCircle(0, 0, 25);
        circle.endFill();
        circle.name = 'circle';

        this.addChild(circle, text);
    }
}
