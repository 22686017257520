import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { IPatientAudit } from 'api/models/patient.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { PatientState } from '../patient.state';

import { getPatientAuditLogging, updatePatientAuditLogging } from './auditLog.action';

export const changeValueFieldName = {
    FirstName: 'First Name',
    MiddleName: 'MiddleName',
};

export const changeValueFormatFieldName: Record<string, string> = {
    FirstName: 'First Name',
    MiddleName: 'Middle Name',
    LastName: 'Last Name',
    ChosenName: 'Chosen Name',
    DateOfBirth: 'Date of Birth',
    EmailAddress: 'Email Address ',

    'Signature.PrivacyNoticeSignedDate': 'Signature Privacy Notice Signed Date',
    'Signature.PrivacyNoticeSigned': 'Signature Privacy Notice Signed',
    BillingReleaseSignedDate: 'Signature Privacy BillingRelease Signed Date',
    'Signature.BillingReleaseSigned': 'Signature Privacy BillingRelease Signed',
    'Signature.BenefitAssignmentSignedDate': 'Signature Benefit Assignment Signed Date',
    'Signature.BenefitAssignmentSigned': 'Signature Benefit Assignment Signed',

    'PhoneNumbers.Number1': 'Phone Number 1',
    'PhoneNumbers.HasPermissionToText1': 'Has Permission To Text Phone Number 1',
    'PhoneNumbers.HasPermissionToCall1': 'Has Permission To Call Phone Number 1',
    'PhoneNumbers.HasPermissionToLeaveVoiceMail1': 'Has Permission To Leave Voicemail on Phone Number 1',
    'PhoneNumbers.IsDeleted1': 'Is Deleted',
    'PhoneNumbers.Description1': 'Phone Number Description',
    'PhoneNumbers.IsBadNumber1': 'Is Bad Number',

    'PhoneNumbers.Number2': 'Phone Number 2',
    'PhoneNumbers.HasPermissionToText2': 'Has Permission To Text Phone Number 2',
    'PhoneNumbers.HasPermissionToCall2': 'Has Permission To Call Phone Number 2',
    'PhoneNumbers.HasPermissionToLeaveVoiceMail2': 'Has Permission To Leave Voicemail on Phone Number 2',
    'PhoneNumbers.IsDeleted2': 'Is Deleted',
    'PhoneNumbers.Description2': 'Phone Number Description',
    'PhoneNumbers.IsBadNumber2': 'Is Bad Number',

    'PhoneNumbers.Number3': 'Phone Number 3',
    'PhoneNumbers.HasPermissionToText3': 'Has Permission To Text Phone Number 3',
    'PhoneNumbers.HasPermissionToCall3': 'Has Permission To Call Phone Number 3',
    'PhoneNumbers.HasPermissionToLeaveVoiceMail3': 'Has Permission To Leave Voicemail on Phone Number 3',
    'PhoneNumbers.IsDeleted3': 'Is Deleted',
    'PhoneNumbers.Description3': 'Phone Number Description',
    'PhoneNumbers.IsBadNumber3': 'Is Bad Number',

    'PhysicalAddress.StreetAddress1': ' Physical Address Street Address 1',
    'PhysicalAddress.StreetAddress2': ' Physical AddressStreet Address 2',
    'PhysicalAddress.City': 'Physical Address City',
    'PhysicalAddress.State': ' Physical Address State',
    'PhysicalAddress.Zip': 'Physical Address Zip',

    'MailingAddress.StreetAddress1': ' Mailing Address Street Address 1',
    'MailingAddress.StreetAddress2': ' Mailing AddressStreet Address 2',
    'MailingAddress.City': 'Mailing Address City',
    'MailingAddress.State': ' Mailing Address State',
    'MailingAddress.Zip': 'Mailing Address Zip',

    SexualOrientationId: 'Sexual Orientation',
    GenderIdentityId: 'Gender Identity',
    PreferredLanguage: 'Preferred Language',

    'Uds.VeteranStatus': 'Veteran Status',
    'Uds.AgriculturalWorker': 'Agricultural Worker',
    'Uds.AgriculturalWorkerDetail': 'Agricultural Worker Detail',
    'Uds.HomelessStatus': 'Homeless Status',
    'Uds.HomelessStatusDetail': 'Homeless Status Detail',
    'Uds.SchoolbasedPatient': 'School-based health center patient',
    'Uds.SexAtBirthId': 'Sex At Birth',
    'Uds.GenderIdentityId': 'Gender Identity',
    'Uds.FamilySize': 'Family Size',
    'Uds.Income': 'Income',
    'Uds.IncomePercentage': 'Income Percentage ',
};

const auditLogReducers = {
    setPatientAuditLog: (state: PatientState, action: PayloadAction<IPatientAudit | undefined>): void => {
        if (state?.selectedPatient?.id === action.payload?.id) {
            state.auditLog.data = action.payload;
        }
    },
};
export default auditLogReducers;
export const auditLogExtraReducers = (builder: ActionReducerMapBuilder<PatientState>): ActionReducerMapBuilder<PatientState> =>
    builder
        .addCase(getPatientAuditLogging.pending, (state) => {
            state.loading = LoadingStatus.Pending;
        })
        .addCase(getPatientAuditLogging.fulfilled, (state, action) => {
            state.loading = LoadingStatus.Completed;
            state.auditLog.data = action.payload;
        })
        .addCase(getPatientAuditLogging.rejected, (state) => {
            state.loading = LoadingStatus.Failed;
        })
        .addCase(updatePatientAuditLogging.pending, (state) => {
            state.loading = LoadingStatus.Pending;
        })
        .addCase(updatePatientAuditLogging.fulfilled, (state, action) => {
            state.auditLog.data = action.payload;
            state.loading = LoadingStatus.Completed;
        })
        .addCase(updatePatientAuditLogging.rejected, (state) => {
            state.loading = LoadingStatus.Failed;
        });
