import {
    useTheme,
    mergeStyleSets,
    Stack,
    DefaultButton,
    TooltipHost,
    TooltipDelay,
    IconButton,
    Text,
    Shimmer,
    Link,
    Toggle,
} from '@fluentui/react';
import { useSelector } from 'hooks';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { forwardRef, PropsWithChildren } from 'react';
import { selectUACDistributionTotalRemaining } from 'state/slices/ledger/uac-distribution/uac-distribution.selectors';
import { usdCurrencyFormatter } from 'utils';

type UACDistributionSectionProps = {
    onClear?: () => void;
    onDistribute?: () => void;
    toggleExpanded: () => void;
    onLinkClick?: () => void;
    onToggleAllowOverpayment?: () => void;
    title: string;
    loading?: LoadingStatus;
    total?: number;
    overpayLabel?: string;
    isExpanded: boolean;
    disableClear?: boolean;
    disableDistribute?: boolean;
    allowOverpayment?: boolean;
};

const UACDistributionSection = forwardRef<HTMLDivElement, PropsWithChildren<UACDistributionSectionProps>>(
    (
        {
            title,
            total = 0,
            children,
            onDistribute,
            onClear,
            loading,
            isExpanded,
            toggleExpanded,
            disableClear,
            disableDistribute,

            overpayLabel,
            onToggleAllowOverpayment,
            allowOverpayment,

            onLinkClick,
        },
        ref,
    ) => {
        const { palette } = useTheme();

        const totalAmountRemaining = useSelector(selectUACDistributionTotalRemaining);

        const UACDistributionSectionStyles = mergeStyleSets({
            body: {
                overflow: 'auto',
                position: 'relative',
                backgroundColor: palette.neutralLighterAlt,
                maxHeight: 700,
                padding: 10,
                paddingTop: 0,
            },
            header: {
                backgroundColor: palette.neutralLighterAlt,
                paddingLeft: 10,
                borderBottom: `1px solid ${palette.neutralLighter}`,
            },
            sectionButton: {
                height: 24,
                paddingLeft: 0,
                paddingRight: 0,
            },
        });

        const isLoading = loading === LoadingStatus.Pending;

        const _disableDistribute = isLoading || !totalAmountRemaining || disableDistribute;

        return (
            <Stack grow>
                <div ref={ref}>
                    <Stack
                        className={UACDistributionSectionStyles.header}
                        horizontal
                        horizontalAlign="space-between"
                        verticalAlign="center"
                        grow
                        wrap
                    >
                        <Stack verticalAlign="center" tokens={{ childrenGap: 20 }} horizontal>
                            <Text variant="mediumPlus">
                                <Link
                                    onClick={() => {
                                        if (onLinkClick) onLinkClick();
                                    }}
                                    styles={{ root: { textDecoration: 'underline' } }}
                                >
                                    <strong>{title}</strong>
                                </Link>
                            </Text>
                            {isLoading ? <Shimmer width={60} /> : <Text>Total: {usdCurrencyFormatter.format(total ?? 0)}</Text>}
                        </Stack>
                        <Stack horizontal horizontalAlign="end">
                            <Stack horizontal tokens={{ childrenGap: 100 }} grow>
                                <Stack verticalAlign="center" horizontal tokens={{ childrenGap: 5 }}>
                                    {onToggleAllowOverpayment && (
                                        <Toggle
                                            styles={{
                                                root: { marginBottom: 0, marginRight: 20 },
                                                label: { padding: 0 },
                                            }}
                                            label={overpayLabel ?? 'Allow Overpayment'}
                                            inlineLabel
                                            onChange={() => {
                                                onToggleAllowOverpayment();
                                            }}
                                            disabled={isLoading}
                                            checked={allowOverpayment}
                                        />
                                    )}
                                    <DefaultButton
                                        className={UACDistributionSectionStyles.sectionButton}
                                        iconProps={{ iconName: 'AddToShoppingList' }}
                                        onClick={() => {
                                            if (onDistribute) onDistribute();
                                        }}
                                        disabled={_disableDistribute}
                                    >
                                        Distribute
                                    </DefaultButton>
                                    <DefaultButton
                                        className={UACDistributionSectionStyles.sectionButton}
                                        iconProps={{ iconName: 'Cancel' }}
                                        onClick={() => {
                                            if (onClear) onClear();
                                        }}
                                        disabled={isLoading || disableClear}
                                    >
                                        Clear
                                    </DefaultButton>
                                </Stack>
                                <TooltipHost delay={TooltipDelay.long} content="Expand">
                                    <IconButton
                                        onClick={() => {
                                            if (!isExpanded && onLinkClick) {
                                                onLinkClick();
                                            } else {
                                                toggleExpanded();
                                            }
                                        }}
                                        iconProps={{ iconName: isExpanded ? 'ChevronUp' : 'ChevronDown' }}
                                    ></IconButton>
                                </TooltipHost>
                            </Stack>
                        </Stack>
                    </Stack>
                </div>
                {isExpanded && (
                    <Stack
                        className={` ${isExpanded ? 'ms-motion-slideUpIn' : 'ms-motion-slideUpOut'} ${
                            UACDistributionSectionStyles.body
                        }
                `}
                        grow
                    >
                        {children}
                    </Stack>
                )}
            </Stack>
        );
    },
);

UACDistributionSection.displayName = 'UACDistributionSection';

export default UACDistributionSection;
