import { MessageBar, Separator, Spinner, Stack, Text, TextField, Toggle } from '@fluentui/react';
import { Dictionary } from '@reduxjs/toolkit';
import { IProblem } from 'api/models/patient-problem.model';
import useForms from 'hooks/store/useForms';
import useProblems from 'hooks/store/useProblem';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { chunk } from 'lodash';
import { FormEvent, useEffect } from 'react';
import { IPatientProblemFormData, IPatientProblemsQuestion, YesNoValue } from './types';

export default function PatientProblems(): JSX.Element | null {
    const { currentlySelectedForm, setFormFieldData } = useForms();
    const {
        getHealthHistoryProblems,
        selectHealthHistoryProblemsAsList,
        selectCriticalHealthHistoryProblemsAsList,
        selectHealthHistoryProblemsData,
        selectHealthHistoryProblemsLoading,
    } = useProblems();

    useEffect(() => {
        if (selectHealthHistoryProblemsLoading === LoadingStatus.Idle) {
            getHealthHistoryProblems();
        }
    }, [selectHealthHistoryProblemsLoading, getHealthHistoryProblems]);

    if (!currentlySelectedForm) return null;

    const data = currentlySelectedForm.data as IPatientProblemFormData;
    const typedSetProblemsData = (path: keyof IPatientProblemFormData, value?: Dictionary<IPatientProblemsQuestion> | string) =>
        setFormFieldData(path, value);

    const _onChangeQuestion = (questionId: string, option?: YesNoValue | undefined) => {
        if (option) {
            const questions = data.questions ? { ...data.questions } : ({} as Dictionary<IPatientProblemsQuestion>);

            const lookupQuestion = selectHealthHistoryProblemsData[questionId];

            const questionName = lookupQuestion?.name;
            const questionDisplayName = lookupQuestion?.displayName;
            const questionDate = new Date().toISOString();

            if (questionName && questionDisplayName && questionDate) {
                questions[questionId] = {
                    name: questionName,
                    value: option,
                    displayName: questionDisplayName,
                    date: questionDate,
                };
                typedSetProblemsData('questions', questions);
            }
        }
    };

    const _onChangeNotes = (ev?: FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string): void => {
        typedSetProblemsData('note', value);
    };

    return (
        <>
            {selectHealthHistoryProblemsLoading === LoadingStatus.Pending ? (
                <Spinner label="Loading problems list..." labelPosition="right" />
            ) : (
                <Stack tokens={{ childrenGap: 10 }}>
                    <MessageBar>
                        Indicate <strong>YES</strong> if the patient reports currently having any of the following diseases or
                        problems.
                    </MessageBar>
                    <Stack horizontal grow>
                        <ProblemToggles problemsList={selectCriticalHealthHistoryProblemsAsList} onChange={_onChangeQuestion} />
                    </Stack>
                    <Separator />
                    <Stack horizontal grow>
                        <ProblemToggles problemsList={selectHealthHistoryProblemsAsList} onChange={_onChangeQuestion} />
                    </Stack>
                    <Stack.Item grow>
                        <TextField
                            label="Notes"
                            placeholder="Record problems that are not listed above."
                            onChange={_onChangeNotes}
                            value={data?.note}
                            multiline
                            rows={3}
                        />
                    </Stack.Item>
                </Stack>
            )}
        </>
    );
}

function ProblemToggles({
    problemsList,
    onChange,
}: {
    problemsList: IProblem[];
    onChange: (questionId: string, option?: YesNoValue | undefined) => void;
}): JSX.Element | null {
    const { currentlySelectedForm } = useForms();

    if (!currentlySelectedForm) return null;

    const data = currentlySelectedForm.data as IPatientProblemFormData;

    const items = chunk(problemsList, problemsList.length / 2).map((chunk, index) => {
        const subItems = chunk.map((question, index) => {
            const checked = data?.questions ? (data?.questions[question.id]?.value === YesNoValue.Yes ? true : false) : undefined;
            return (
                <Toggle
                    key={index}
                    label={question.displayName}
                    checked={checked}
                    offText="No"
                    onText="Yes"
                    onChange={(ev, checked) => {
                        const option = checked ? YesNoValue.Yes : YesNoValue.No;
                        onChange(question.id, option);
                    }}
                />
            );
        });
        return (
            <Stack key={index} grow>
                {subItems}
            </Stack>
        );
    });

    return <>{items}</>;
}
