import { FormName } from 'api/models/form';
import { ICariesRiskAdultQuestions } from 'forms/CariesRiskAdult/types';
import { ICariesRiskChildQuestions } from 'forms/CariesRiskChild/types';
import { RootState } from 'state/store';

export default function CariesRisk({ state }: { state: RootState }): JSX.Element | null {
    const form = state.chartNotes.quickNotes.assets.forms.cariesRisk;
    const formData = form?.data as ICariesRiskAdultQuestions & ICariesRiskChildQuestions;
    const adultChild =
        state.chartNotes.quickNotes.assets.forms.cariesRisk?.formName === FormName.CariesRiskAdult ? 'Adult' : 'Child';
    if (!form) return null;

    return (
        <>
            <h3>Caries Risk {adultChild} Assessment:</h3>
            <ul>{formData.score && <li>Patient Risk is {formData.score}</li>}</ul>
        </>
    );
}
