import { ComboBox, Label, MessageBar, Stack } from '@fluentui/react';
import { ClinicalAlertType } from 'api/models/clinical-alert.model';
import { useSelector } from 'hooks';
import { RouteParams } from 'interfaces/route-params';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectCurrentClinicalAlertData } from 'state/slices/clinical-alert/clinical-alerts.selectors';
import { setClinicalAlertDataProp } from 'state/slices/clinical-alert/clinical-alerts.slice';
import { selectPatientProblemsPremedOptions } from 'state/slices/patient/overview/overview.selectors';
import BaseClinicalAlert from './BaseClinicalAlert';

export default function PreMedicationModal() {
    const dispatch = useDispatch();

    const { patientId } = useParams<RouteParams>();

    const currentClinicalAlert = useSelector(selectCurrentClinicalAlertData);
    const options = useSelector((state) =>
        selectPatientProblemsPremedOptions(state, { patientId, type: ClinicalAlertType.PreMedication }),
    );

    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <Stack>
                <Label>Associated Problem</Label>
                {options.length ? (
                    <ComboBox
                        placeholder="(Select Associated Problem)"
                        selectedKey={currentClinicalAlert?.references?.snomedCode ?? ''}
                        options={options}
                        onChange={(ev, option) => {
                            if (option)
                                dispatch(
                                    setClinicalAlertDataProp({
                                        path: 'references',
                                        value: {
                                            snomedCode: option.key as string,
                                            problemName: option.text,
                                        },
                                    }),
                                );
                        }}
                    />
                ) : (
                    <MessageBar>There are no patient problems to associate.</MessageBar>
                )}
            </Stack>
            <BaseClinicalAlert notesRequired={!currentClinicalAlert?.references?.snomedCode} />
        </Stack>
    );
}
