import { Dictionary } from '@reduxjs/toolkit';
import { ChartProcedureStatus, IChartProcedure } from 'api/models/chart.model';
import { ApplicableArea } from 'api/models/lookup.model';
import { IProcedure } from 'api/models/procedure.model';
import { every, flatten, isEmpty, isEqual, map, sortBy, uniqBy } from 'lodash';
import { toothSpriteReferences } from 'pages/Charting/components/ToothCanvas/spriteList';
import { createSelector } from 'reselect';
import { selectConditionsData } from 'state/slices/tenant/conditions.slice';
import { diagnosesList, selectDiagnoses } from 'state/slices/tenant/diagnoses.slice';
import { proceduresList, selectEffectiveProceduresAsList, selectProceduresData } from 'state/slices/tenant/procedures.slice';
import { RootState } from 'state/store';
import { ProcedureActionType } from '../chart/chart.slice';
import ChartingProceduresPipeline, {
    createsManyProcedures,
    createsManyProceduresPerArea,
    createsProceduresWithCombinedToothIds,
} from '../chartingProcedures.pipeline';
import { chartConditionsAsList } from '../conditions/conditions.selectors';
import { IPipelineError, PipelineError } from '../pipeline';
import { getTeethReference } from '../dentition/dentition.selectors';
import ProcedureApplicableAreasPipeline from '../procedureApplicableAreasPipeline';
import ProcedureBusinessRulesPipeline, {
    IChartProcedureWithOriginalProcedure,
} from '../procedureCodeBusinessRules/procedureBusinessRules.pipeline';
import ProcedureConflictRulesPipeline from '../procedureConflictRules.pipeline';
import ProcedureDiagnosisConflictRulesPipeline from '../procedureDiagnosisConflictRules/procedureDiagnosisConflictRules.pipeline';
import { chartProceduresData } from '../procedures/procedures.selectors';
import { ChartProcedurePanelState, IPanelProcedure } from './procedure-panel.state';
import { getAreaTeethDataProp } from './procedure-panel.actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectPatientAge } from 'state/slices/patient/patient.selectors';
import { selectPatientEncounter } from 'state/slices/encounter/encounter.selectors';

export const selectProcedurePanel = (state: RootState): ChartProcedurePanelState => state.charting.procedurePanel;
export const selectProcedurePanelSelectedTeeth = (state: RootState): number[] => state.charting.procedurePanel.selectedTeeth;
export const selectProcedurePanelSelectedAreas = (state: RootState) => state.charting.procedurePanel.selectedAreas;
export const selectSelectedCategories = (state: RootState): string[] => state.charting.procedurePanel.selectedCategories;
export const selectSelectedChartProcedure = (state: RootState) => state.charting.procedurePanel.selectedChartProcedure;

export const selectOpenProcedureSections = createSelector(selectProcedurePanel, (state) => state.openProcedureSections);
export const selectAllSectionsOpen = createSelector(selectOpenProcedureSections, selectProcedurePanel, (openSections, state) => {
    const procedures = state.procedures.map((p) => p.id);
    return isEqual(procedures, openSections);
});

export const selectProcedurePanelTeethValues = createSelector(selectProcedurePanelSelectedTeeth, (selectedTeeth) =>
    sortBy(
        selectedTeeth.map((tooth) => {
            const name = toothSpriteReferences.find((t) => t.id === tooth)?.displayName ?? '';
            const numericName = +name;
            if (!isNaN(numericName)) {
                return numericName;
            } else {
                return name;
            }
        }),
    )
        .map((tooth) => tooth.toString())
        .join(', '),
);

export const selectFilteredProcedureBrowserProcedures = createSelector(
    selectEffectiveProceduresAsList,
    selectSelectedCategories,
    (procedures, categories) => {
        if (categories.length) {
            return procedures.filter((proc) => categories.some((f) => f === proc.category));
        } else {
            return procedures;
        }
    },
);

function getChartProceduresWithApplicableAreas(
    procedures: IProcedure[],
    panelTeethData: Dictionary<IPanelProcedure>,
    chartProcedures: IChartProcedure[],
): IChartProcedure[] {
    return chartProcedures.map((p) => {
        const tooth = p.toothIds ? p.toothIds[0] : undefined;
        const areas = tooth && p.procedureId ? panelTeethData[p.procedureId]?.data[tooth]?.surfaces : [];
        const areasPipe = new ProcedureApplicableAreasPipeline({
            procedures,
            chartProcedures: [p],
            toothAreas: areas,
        });
        return { ...p, areas: areasPipe.getItems[0].areas };
    });
}
/**
 *  Maps the both the selected and/or the auto applied diagnoses to the procedures from panelTeethData
 *
 * @param {IChartProcedureWithOriginalProcedure[]} procs
 * @param {Dictionary<IPanelProcedure>} panelTeethData
 * @return {*}
 */
function mapDiagnosesToChartProcedures(
    procs: IChartProcedureWithOriginalProcedure[],
    panelTeethData: Dictionary<IPanelProcedure>,
): IChartProcedure[] {
    return procs.map((p) => {
        const toothOrArea = getAreaTeethDataProp(p);

        const diagnosisCodes = p.originalProcedureId
            ? panelTeethData[p.originalProcedureId]?.generalDiagnosisCodes.length
                ? panelTeethData[p.originalProcedureId]?.generalDiagnosisCodes
                : toothOrArea
                    ? panelTeethData[p.originalProcedureId]?.data[toothOrArea]?.diagnosisCodes
                    : []
            : [];
        return { ...p, diagnosisCodes };
    });
}

//Need to translate surfaces here... Occlusal to Incisial when necissary | Facial to Buccal when necissary.
//Shouldn't touch the surfaces that are already there outside of this translation.
export const selectChartProcedures = createSelector(
    selectProcedurePanel,
    proceduresList,
    diagnosesList,
    chartProceduresData,
    selectPatientEncounter,
    (panelState, allProcedures, diagnoses, chartProceduresList, patientEncounter): IChartProcedure[] => {
        const {
            selectedActionType,
            panelTeethData,
            providerId,
            selectedProceduresTeeth,
            selectedTeeth,
            selectedAreas,
            procedures,
            selectedChartProcedure,
            date,
        } = panelState;
        const proceduresBySelectedTeeth: IChartProcedure[] = [];
        //Run pipelines to generate chart procedures.
        new ChartingProceduresPipeline({
            newProcedures: procedures,
            areas: selectedAreas,
            type: selectedActionType ? selectedActionType : ProcedureActionType.Treatment,
            selectedTeeth,
            treatingProviderId: providerId,
            selectedProcedureTeeth: selectedProceduresTeeth,
            currentChartProcedures: chartProceduresList,
            respectiveChartProcedureIds: selectedChartProcedure ? [selectedChartProcedure.id] : undefined,
            onSetDate: date,
        }).next((procs) => {
            const newProceduresWithAreas = getChartProceduresWithApplicableAreas(procedures, panelTeethData, procs);
            new ProcedureBusinessRulesPipeline({
                chartProcedures: newProceduresWithAreas,
                originalProcedures: procedures, //original procedures being charted
                procedures: allProcedures, //list of all procedures
                selectedTeeth,
                diagnoses,
                chartProceduresList,
                encounter: patientEncounter,
            }).next((procs) => {
                //Map the changed values from state onto the generated procedures
                const newProcedures = mapDiagnosesToChartProcedures(procs, panelTeethData);
                proceduresBySelectedTeeth.push(...newProcedures);
            });
        });

        return proceduresBySelectedTeeth;
    },
);

export const selectProcedurePanelConflicts = createSelector(
    [
        selectProcedurePanel,
        chartProceduresData,
        selectChartProcedures,
        selectProceduresData,
        selectConditionsData,
        chartConditionsAsList,
        selectDiagnoses,
        getTeethReference,
        selectPatientAge,
        selectPatientEncounter,
    ],
    (
        panelState,
        currentProcedures,
        chartProcedures,
        proceduresData,
        conditionsData,
        chartConditions,
        diagnosisData,
        teethReference,
        patientAge,
        patientEncounter,
    ): { generalErrors: ConflictError[]; procedureErrors: Dictionary<ConflictError[]>; procedureIdsWithErrors: string[] } => {
        let generalErrors: ConflictError[] = [];
        const procedureErrors: Dictionary<ConflictError[]> = {};

        const {
            selectedTeeth: globalSelectedTeeth,
            selectedProceduresTeeth,
            providerId,
            selectedStatus,
            panelTeethData,
            procedures,
        } = panelState;

        if (!providerId && !typeIsExisting(panelState.selectedActionType))
            generalErrors = [...generalErrors, { message: 'A provider must be selected before saving.' }];

        if (procedures.some((p) => p.code === 'D0145') && patientAge > 3) {
            generalErrors = [
                ...generalErrors,
                {
                    message:
                        'D0145 is only applicable for patients under the age of 3. Please choose D0120, D0140, D0150 as an alternative, or return to the Procedure Browser to select a different procedure.',
                },
            ];
        }

        if (
            selectedStatus === ChartProcedureStatus.Completed &&
            chartProcedures.length === 1 &&
            !chartProcedures[0].diagnosisCodes?.length
        )
            generalErrors = [...generalErrors, { message: 'A diagnosis must be selected before completing the procedure.' }];
        //Each section will have its own array of errors. We don't want duplicates. So use this function to add procedure errors.
        function addProcedureConflictError(procedureId: string, error: ConflictError) {
            if (procedureErrors[procedureId]?.findIndex((err) => err.message === error.message) === -1) {
                procedureErrors[procedureId]?.push(error);
            }
        }

        procedures.forEach((procedure) => {
            if (!procedureErrors[procedure.id]) procedureErrors[procedure.id] = [];

            const panelProcedure = panelTeethData[procedure.id];

            const panelProcedureProceduresList = (
                panelProcedure?.data ? map(panelProcedure.data, (data) => data.procedure) : [proceduresData[procedure.id]]
            ) as IProcedure[];

            const panelProceduresWithConflictRules = panelProcedureProceduresList.filter(hasConflictRules);

            const selectedTeeth = selectedProceduresTeeth[procedure.id]?.length
                ? selectedProceduresTeeth[procedure.id] ?? globalSelectedTeeth
                : globalSelectedTeeth;

            // If procedure requires surfaces but not all procedures have surfaces.
            if (
                procedure.applicableArea === ApplicableArea.Surface &&
                !every(panelProcedure?.data, (panelProcedureData) => {
                    if (panelProcedureData) return !!panelProcedureData.surfaces?.length;
                    return false;
                })
            ) {
                addProcedureConflictError(procedure.id, { message: 'Requires selected surfaces' });
            }

            // If procedure requires teeth and no teeth are selected
            if (
                (createsManyProcedures(procedure) || createsProceduresWithCombinedToothIds(procedure)) &&
                isEmpty(panelProcedure?.data)
            ) {
                addProcedureConflictError(procedure.id, { message: 'Requires selected teeth' });
            }
            if (createsManyProceduresPerArea(procedure) && isEmpty(panelProcedure?.data)) {
                addProcedureConflictError(procedure.id, { message: 'Requires selected areas' });
            }

            if (panelProcedureProceduresList.length && panelProceduresWithConflictRules.length)
                new ProcedureConflictRulesPipeline({
                    newProcedures: panelProceduresWithConflictRules,
                    selectedTeeth,
                    chartProcedures,
                    currentProcedures,
                    procedureData: proceduresData,
                    conditionsData,
                    currentConditions: chartConditions,
                    statusOverride: selectedStatus,
                    teethReference,
                    patientEncounterId: patientEncounter?.id,
                }).next((items, pipelineErrors) => {
                    if (pipelineErrors) {
                        const ruleErrors: ConflictError[] = getPipelineErrorMessage(pipelineErrors);
                        procedureErrors[procedure.id]?.push(...ruleErrors);
                    }
                });

            new ProcedureDiagnosisConflictRulesPipeline({
                chartProcedures,
                currentProcedures,
                diagnosisData,
                selectedTeeth,
            }).next((items, pipelineErrors) => {
                if (pipelineErrors) {
                    const ruleErrors: ConflictError[] = uniqBy(
                        pipelineErrors.map((e) => {
                            switch (e.type) {
                                case PipelineError.DiagnosisRequiresSupportingDiagnosis:
                                    return {
                                        message: `${e.data.diagnosisCode} requires that another supporting DX, other than Z01.20, has been added.`,
                                    };
                                default:
                                    return { message: 'An unknown conflict error occurred' };
                            }
                        }),
                        'message',
                    );

                    procedureErrors[procedure.id]?.push(...ruleErrors);
                }
            });
        });

        const proceduresWithErrors = flatten(
            map(procedureErrors, (error, procedureId) => (procedureErrors[procedureId]?.length ? procedureId : undefined)),
        ).filter((procedureId) => procedureId !== undefined) as string[];

        return { generalErrors, procedureErrors, procedureIdsWithErrors: proceduresWithErrors };
    },
);

export function getPipelineErrorMessage(pipelineErrors: IPipelineError[]): ConflictError[] {
    return uniqBy(
        pipelineErrors.map((e) => {
            switch (e.type) {
                case PipelineError.OnePerTooth:
                    return { message: 'This procedure is already applied the selected teeth/tooth' };
                case PipelineError.MolarOnly:
                    return { message: 'Must select molars only' };
                case PipelineError.PosteriorOnly:
                    return { message: 'Must select posterior teeth' };
                case PipelineError.AnteriorOnly:
                    return { message: 'Must select anterior teeth' };
                case PipelineError.OnePerArea:
                    return {
                        message: `This procedure is already applied to the selected teeth/tooth on the given area(s): ${e.data.areas.join(
                            ', ',
                        )}`,
                    };
                case PipelineError.OnePerSurface: {
                    const toothIds = (e.data.toothIds as string[])
                        .map((id) => {
                            return toothSpriteReferences.find((tooth) => tooth.id === +id)?.displayName;
                        })
                        .join(', ');

                    return {
                        message: `This procedure conflicts with a charted procedure (${e.data.code}) on ${e.data.toothIds.length > 1 ? 'teeth' : 'tooth'
                            } ${toothIds} on the given area(s): ${e.data.areas.join(', ')}`,
                    };
                }
                case PipelineError.BicuspidOnly:
                    return { message: 'Must select bicuspid teeth' };
                case PipelineError.ThreeSurfacesOnly:
                    return { message: `${e.data.code} requires that no more than 3 surfaces are selected.` };
                case PipelineError.FourSurfacesOnly:
                    return { message: `${e.data.code} requires that no more than 4 surfaces are selected.` };
                case PipelineError.PrimaryToothOnly:
                    return { message: `${e.data.code} requires that the selected teeth/tooth are primary.` };
                case PipelineError.PermanentToothOnly:
                    return {
                        message: `${e.data.code} requires that the selected teeth/tooth are permanent.`,
                    };
                case PipelineError.TwoSurfacesOrMoreOnly:
                    return {
                        message: `${e.data.code} requires that there are two or more surfaces selected.`,
                    };
                case PipelineError.TwoSurfacesOnly:
                    return { message: `${e.data.code} requires that there are only two surfaces selected.` };
                case PipelineError.ThreeSurfacesOrMoreOnly:
                    return {
                        message: `${e.data.code} requires that there are three or more surfaces selected.`,
                    };
                case PipelineError.FourOrMoreSurfacesOnly:
                    return {
                        message: `${e.data.code} requires that there are four or more surfaces selected.`,
                    };
                case PipelineError.MaxillaryOnly:
                    return { message: `${e.data.code} requires that selected teeth are maxillary.` };
                case PipelineError.MandibularOnly:
                    return { message: `${e.data.code} requires that selected teeth are mandibular.` };
                case PipelineError.BetweenAbutmentsOnly:
                    return {
                        message: `${e.data.code} requires that selected teeth are adjacent to an abutment or pontic.`,
                    };
                case PipelineError.RequiresCompletedExtractionOrMissing:
                    return {
                        message: `${e.data.code} requires that selected teeth have a completed, existing, or existing other extraction procedure or a missing a condition to have a status of completed.`,
                    };
                case PipelineError.onePerEncounter:
                    return {
                        message: `${e.data.code} has already been charted on the current encounter.`,
                    };
                case PipelineError.ProcedureRequiresToChartProcedure: {
                    const codes = (e.data.codeRequirements as string[]).map((code, index) =>
                        `${e.data.codeRequirements.length > 1 && index === e.data.codeRequirements.length - 1 ? `or ${code}` : code}`
                    ).join(", ")

                    return {
                        message: `${e.data.code} requires a procedure code of ${codes} to be charted.`
                    }
                }

                default:
                    return { message: 'An unknown conflict error occurred' };
            }
        }),
        'message',
    );
}

export function hasConflictRules(proc: IProcedure): boolean {
    return proc?.conflictRules !== undefined && !!proc?.conflictRules.length;
}

function typeIsExisting(selectedActionType?: ProcedureActionType) {
    return selectedActionType === ProcedureActionType.Existing || selectedActionType === ProcedureActionType.ExistingOther;
}

export type ConflictError = { message: string };
