import { PayloadAction } from '@reduxjs/toolkit';
import { IPatientInsurance } from 'api/models/patient.model';
import { EditPatientState } from '../edit-patient.state';

const insurancesReducers = {
    setInsurancePrimary: (state: EditPatientState, action: PayloadAction<IPatientInsurance>): void => {
        const selectedInsurance = action.payload;

        if (state.patient?.insurances?.length) {
            const primaryInsurance = selectedInsurance;

            const filteredPrimaryInsurances = state.patient.insurances.filter(
                (insurance) => insurance.id !== primaryInsurance.id,
            );

            state.patient.insurances = [primaryInsurance, ...filteredPrimaryInsurances];
        }
        state.patientHasChange = true;
    },
    toggleInsuranceActive: (state: EditPatientState, action: PayloadAction<IPatientInsurance>): void => {
        const insurance = action.payload;

        const deactivatedInsurance: IPatientInsurance = { ...insurance, isDeleted: !insurance.isDeleted ? true : false };
        const filteredInsurances = state.patient?.insurances?.filter((i) => i.id !== insurance.id);
        if (state.patient?.insurances?.length) {
            if (filteredInsurances?.length) {
                state.patient.insurances = [...filteredInsurances, deactivatedInsurance];
            } else {
                state.patient.insurances = [deactivatedInsurance];
            }
            state.patientHasChange = true;
        }
    },
    saveInsurance: (state: EditPatientState, action: PayloadAction<IPatientInsurance>): void => {
        const insurance = action.payload;
        if (state.patient && insurance) {
            if (state.patient && state.patient.insurances?.length) {
                const newInsurances = [...state.patient.insurances];
                const indexOfInsurance = state.patient?.insurances.findIndex((i) => i.id === insurance.id);
                if (indexOfInsurance > -1) {
                    newInsurances[indexOfInsurance] = insurance;
                    state.patient.insurances = newInsurances;
                } else {
                    state.patient.insurances = [...newInsurances, insurance];
                }
            } else {
                state.patient.insurances = [insurance];
            }
            state.patientHasChange = true;
        }
    },
};

export default insurancesReducers;
