import React, { CSSProperties, ReactNode } from 'react';
import { Stack, useTheme } from '@fluentui/react';

type Props = {
    children: ReactNode;
};

const NoSidebarLayout = (props: Props) => {
    const theme = useTheme();
    const _pageStyle: CSSProperties = {
        background: theme.palette.neutralLight,
        color: theme.palette.neutralPrimary,
        position: 'relative',
        display: 'flex',
        flex: 1,
        height: '100%',
        width: '100%',
        overflow: 'hidden',
    };

    return <div style={_pageStyle}>{props.children}</div>;
};

export default NoSidebarLayout;
