import { createSelector, createSlice, Dictionary } from '@reduxjs/toolkit';
import { LoadingStatus } from 'interfaces/loading-statuses';
import {
    PatientBasicInfoTarget,
    PayersTaskTargets,
    FinanceTaskTargets,
    getTaskByEncounter,
    TaskType,
    getPatientTasksByGroup,
} from './taskManagement.actions';

import initialState from './taskManagement.state';
import { RootState } from 'state/store';
import IUserTask from 'api/models/user-task.model';
import { selectSelectedAppointmentPatientInsurances } from 'state/slices/scheduling/scheduling.selectors';
import { finishCheckout, hasFinishCheckoutSucceeded } from 'state/slices/scheduling/scheduling.actions';

const taskManagment = createSlice({
    name: 'taskManagement',
    initialState,
    reducers: {
        cleanupPatientTasks: (state) => {
            state.patientTasks = [];
        },
        cleanupPatientAppointmentTasks: (state) => {
            state.patientAppointmentTasks = [];
        },
        cleanupCheckoutCheckinTasks: (state) => {
            state.checkoutCheckinTasks = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPatientTasksByGroup.pending, (state) => {
                state.loadingCheckoutTasks = LoadingStatus.Pending;
            })
            .addCase(getPatientTasksByGroup.fulfilled, (state, action) => {
                state.loadingCheckoutTasks = LoadingStatus.Completed;
                if (state.checkoutCheckinTasks.length) {
                    state.checkoutCheckinTasks = [...state.checkoutCheckinTasks, ...action.payload];
                } else {
                    state.checkoutCheckinTasks = action.payload;
                }
            })
            .addCase(getPatientTasksByGroup.rejected, (state) => {
                state.loadingCheckoutTasks = LoadingStatus.Failed;
            })
            .addCase(getTaskByEncounter.pending, (state) => {
                state.loadingPatientTasks = LoadingStatus.Pending;
            })
            .addCase(getTaskByEncounter.fulfilled, (state, action) => {
                state.loadingPatientTasks = LoadingStatus.Completed;
                state.patientTasks = action.payload;
            })
            .addCase(getTaskByEncounter.rejected, (state) => {
                state.loadingPatientTasks = LoadingStatus.Failed;
            })
            .addCase(finishCheckout.fulfilled, (state, { payload }) => {
                if (!hasFinishCheckoutSucceeded(payload)) {
                    state.checkoutCheckinTasks = [...(payload.userTasksPatient ?? []), ...(payload.userTasksAppointment ?? [])];
                }
            });
    },
});

const { reducer, actions } = taskManagment;
export const { cleanupPatientTasks, cleanupPatientAppointmentTasks, cleanupCheckoutCheckinTasks } = actions;

export const selectTaskManagementState = (state: RootState) => state.taskManagement;

export const selectPatientTasks = createSelector(selectTaskManagementState, (state) => {
    return state.patientTasks;
});

//Checkout/Checkin:
export const selectCheckoutCheckinTasks = createSelector(selectTaskManagementState, (state) => {
    return state.checkoutCheckinTasks;
});
export const selectCheckoutCheckinTasksLookup = createSelector(selectCheckoutCheckinTasks, (tasks) => {
    const lookup: Dictionary<IUserTask> = {};
    tasks.forEach((task) => {
        if (task.type) lookup[task.type] = task;
    });
    return lookup;
});
export const selectLoadingCheckoutCheckinTasks = createSelector(selectTaskManagementState, (state) => {
    return state.loadingCheckoutTasks;
});

//Fixes bad data issues where appointment tasks may not have had an appointmentId.
export const filteredCheckoutCheckinPatientTasks = createSelector(selectCheckoutCheckinTasks, (tasks) => {
    return tasks.filter((task) => !task.references?.appointmentId);
});
export const filteredCheckoutCheckinPatientAppointmentTasks = createSelector(selectCheckoutCheckinTasks, (tasks) => {
    return tasks.filter((task) => !!task.references?.appointmentId);
});
export const checkoutCheckinPatientAppointmentTasksLookup = createSelector(
    filteredCheckoutCheckinPatientAppointmentTasks,
    (data) => {
        const lookup: Record<string, IUserTask> = {};
        data.forEach((task) => {
            if (task?.type) lookup[task.type] = task;
        });
        return lookup;
    },
);
//Basic info tasks for patient/patient appointment

export const selectPatientBasicInfoCheckInTasks = createSelector(filteredCheckoutCheckinPatientTasks, (tasks) => {
    const basicInfoTasks = tasks.filter((task) => (task.target ? PatientBasicInfoTarget.includes(task.target) : false));
    return basicInfoTasks;
});

export const selectPatientAppointmentBasicInfoCheckInTasks = createSelector(
    filteredCheckoutCheckinPatientAppointmentTasks,
    (tasks) => {
        const basicInfoTasks = tasks.filter((task) => (task.target ? PatientBasicInfoTarget.includes(task.target) : false));
        return basicInfoTasks;
    },
);

export const selectBasicInfoTasks = createSelector(
    selectPatientBasicInfoCheckInTasks,
    selectPatientAppointmentBasicInfoCheckInTasks,
    (patientTasks, apptTasks) => [...patientTasks, ...apptTasks],
);

export const selectPatientBasicInfoByTaskTypeLookup = createSelector(selectPatientBasicInfoCheckInTasks, (basicInfoTasks) => {
    const lookup: Dictionary<IUserTask> = {};
    basicInfoTasks.forEach((task) => {
        if (task.type) lookup[task.type] = task;
    });
    return lookup;
});

export const selectPatientAppointmentBasicInfoByTaskTypeLookup = createSelector(
    selectPatientAppointmentBasicInfoCheckInTasks,
    (basicInfoTasks) => {
        const lookup: Dictionary<IUserTask> = {};
        basicInfoTasks.forEach((task) => {
            if (task.type) lookup[task.type] = task;
        });
        return lookup;
    },
);

export const selectAmendementTasks = createSelector(selectPatientTasks, (patientTasks) =>
    patientTasks.filter((task) => task.type === TaskType.Amendment),
);

//Financial tasks for patient/patient appointment

export const selectFinanceInfoCheckoutCheckInTasks = createSelector(filteredCheckoutCheckinPatientTasks, (tasks) =>
    tasks.filter((task) => (task.target ? FinanceTaskTargets.includes(task.target) : false)),
);

export const selectPatientAppointmentFinanceCheckoutCheckinTasks = createSelector(
    filteredCheckoutCheckinPatientAppointmentTasks,
    (tasks) => tasks.filter((task) => (task.target ? FinanceTaskTargets.includes(task.target) : false)),
);

export const selectFinancialCheckoutCheckinTasks = createSelector(
    selectFinanceInfoCheckoutCheckInTasks,
    selectPatientAppointmentFinanceCheckoutCheckinTasks,
    (patientTasks, apptTasks) => [...patientTasks, ...apptTasks],
);

export const selectFinanceInfoByTaskTypeLookup = createSelector(selectFinanceInfoCheckoutCheckInTasks, (udsInfoTasks) => {
    const lookup: Dictionary<IUserTask> = {};
    udsInfoTasks.forEach((task) => {
        if (task.type) lookup[task.type] = task;
    });
    return lookup;
});

export const selectPatientAppointmentFinanceInfoByTaskTypeLookup = createSelector(
    selectPatientAppointmentFinanceCheckoutCheckinTasks,
    (financeInfoTasks) => {
        const lookup: Dictionary<IUserTask> = {};
        financeInfoTasks.forEach((task) => {
            if (task.type) lookup[task.type] = task;
        });
        return lookup;
    },
);

//Insurance info tasks

export const selectPayersInsuranceInfoTasks = createSelector(
    filteredCheckoutCheckinPatientTasks,
    filteredCheckoutCheckinPatientAppointmentTasks,
    selectSelectedAppointmentPatientInsurances,
    (checkInTasks, appointmentCheckInTasks, insurances) => {
        return [...checkInTasks, ...appointmentCheckInTasks]
            .filter((task) => (task.target ? PayersTaskTargets.includes(task.target) : false))
            .filter((task) => {
                const insuranceId = task?.references?.patientInsuranceId ?? '';
                const insurance = insurances.find((ins) => ins.id === insuranceId);
                return !insurance?.isDeleted;
            })
            .filter((task) => task?.references?.patientInsuranceId && task?.references?.patientInsuranceId !== '');
    },
);

export const selectPayersInfoTasks = createSelector(
    filteredCheckoutCheckinPatientTasks,
    filteredCheckoutCheckinPatientAppointmentTasks,
    (checkInTasks, appointmentCheckInTasks) => {
        return [...checkInTasks, ...appointmentCheckInTasks]
            .filter((task) => (task.target ? PayersTaskTargets.includes(task.target) : false))
            .filter((task) => {
                return !task?.references?.patientInsuranceId;
            });
    },
);

export const selectRootFinancialInfoByTaskTypeLookup = createSelector(selectCheckoutCheckinTasks, (financialInfoTasks) => {
    const lookup: Dictionary<IUserTask> = {};
    financialInfoTasks.forEach((task) => {
        if (task.type) lookup[task.type] = task;
    });
    return lookup;
});

export const selectRootFinancialInfoTasksAsList = createSelector(
    selectPayersInsuranceInfoTasks,
    selectPayersInfoTasks,
    (insuranceTasks, payersTasks) => [...insuranceTasks, ...payersTasks],
);

export const selectRootFinancialInsuranceInfoByTaskTypeLookup = createSelector(
    selectPayersInsuranceInfoTasks,
    (financialInfoTasks) => {
        const lookup: Dictionary<IUserTask> = {};
        financialInfoTasks.forEach((task) => {
            if (task.type) lookup[task.type] = task;
        });
        return lookup;
    },
);

export const selectInsuranceFinancialInfoByTaskTypeLookup = createSelector(
    selectPayersInsuranceInfoTasks,
    selectSelectedAppointmentPatientInsurances,
    (financialInfoTasks, insurances) => {
        const lookup: { [key: string]: Dictionary<IUserTask> } = {};
        financialInfoTasks
            .filter((task) => !!task?.references?.patientInsuranceId)
            .forEach((task) => {
                const insuranceId = task?.references?.patientInsuranceId ?? '';
                const insurance = insurances.find((ins) => ins.id === insuranceId);
                if (!insurance?.isDeleted) {
                    if (!lookup[insuranceId]) lookup[insuranceId] = {};
                    if (task.type) lookup[insuranceId][task.type] = task;
                }
            });
        return lookup;
    },
);

export default reducer;
