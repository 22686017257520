import { Dictionary } from '@reduxjs/toolkit';
import ApiClient from './apiClient';
import { GetLookupsRequest, GetLookupRequest, UpsertLookupRequest } from 'models/requestTypes';
import { DentalApiConfig } from './api.config';

class Api extends ApiClient {
    constructor() {
        super(DentalApiConfig);
    }

    getLookups = (request: GetLookupsRequest) => this.client.get<Dictionary<any>>(this.getUrl(request));

    getLookupById = (request: GetLookupRequest) => this.client.get<any>(this.getUrlWithId(request));

    postLookup = (request: UpsertLookupRequest) => this.client.post<any>(this.getUrl(request), request.entity.item);

    putLookup = (request: UpsertLookupRequest) => this.client.put<any>(this.getUrl(request), request.entity.item);

    private getUrl = ({ tenantId, lookupType }: GetLookupsRequest) => {
        if (lookupType === 'ProcedureDetails') return `${lookupType}?tenantId=${tenantId}`;
        return `Tenants/${tenantId}/${lookupType}`;
    };
    private getUrlWithId = ({ tenantId, lookupType, id }: GetLookupRequest) => `Tenants/${tenantId}/${lookupType}/${id}`;
}

export default new Api();
