import { MessageBar, Pivot, PivotItem, Stack } from '@fluentui/react';
import { IPatientInsurance } from 'api/models/patient.model';
import { map } from 'lodash';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectPatientHasGuarantorInContacts } from 'state/slices/contacts/contacts.slice';
import {
    selectEditPatient,
    selectEditPatientActiveInsurances,
    selectEditPatientDeletedInsurances,
    selectEditPatientExpiredInsurances,
} from 'state/slices/edit-patient/edit-patient.selectors';
import { calculateAgeInYears } from 'utils/dateOnly';
import InsuranceCard from './InsuranceCard';

export type PatientInsuranceCallbackFunction = (insurance: IPatientInsurance) => void;

export interface IInsuranceComponentBaseProps {
    editInsurance: PatientInsuranceCallbackFunction;
    editEligibility: PatientInsuranceCallbackFunction;
    deleteInsurance: PatientInsuranceCallbackFunction;
    activateInsurance: PatientInsuranceCallbackFunction;
    expireInsurance: PatientInsuranceCallbackFunction;
}

enum InsuranceCardView {
    Active = 'Active',
    Expired = 'Expired',
    Deleted = 'Deleted',
}

const insuranceSelectors = {
    [InsuranceCardView.Active]: selectEditPatientActiveInsurances,
    [InsuranceCardView.Deleted]: selectEditPatientDeletedInsurances,
    [InsuranceCardView.Expired]: selectEditPatientExpiredInsurances,
};

export default function InsuranceCardViews(props: IInsuranceComponentBaseProps) {
    const [selectedView, setSelectedView] = useState<InsuranceCardView>(InsuranceCardView.Active);

    const activeInsuranceCount = useSelector(insuranceSelectors[InsuranceCardView.Active]).length;
    const deletedInsuranceCount = useSelector(insuranceSelectors[InsuranceCardView.Deleted]).length;
    const expiredInsuranceCount = useSelector(insuranceSelectors[InsuranceCardView.Expired]).length;

    const viewInsuranceCountLookup = useMemo(
        () => ({
            [InsuranceCardView.Active]: activeInsuranceCount,
            [InsuranceCardView.Deleted]: deletedInsuranceCount,
            [InsuranceCardView.Expired]: expiredInsuranceCount,
        }),
        [activeInsuranceCount, deletedInsuranceCount, expiredInsuranceCount],
    );

    const views = map(InsuranceCardView, (view) => (
        <PivotItem itemKey={view} headerText={`${view} Insurances (${viewInsuranceCountLookup[view]})`}>
            <RenderInsuranceCardView viewType={selectedView} {...props} />
        </PivotItem>
    ));

    return (
        <>
            <InsuranceCardViewsGuarantorMessageBar />
            <Pivot
                onLinkClick={(item) => {
                    if (item?.props.itemKey) setSelectedView(item.props.itemKey as InsuranceCardView);
                }}
            >
                {views}
            </Pivot>
        </>
    );
}

function InsuranceCardViewsMessageBar({ viewType }: IInsuranceCardViewTypeComponentProps) {
    const hasInsurances = useSelector(insuranceSelectors[viewType]).length;
    const messages = [];

    if (viewType === InsuranceCardView.Deleted)
        messages.push(
            <li>
                {
                    'Insurances displayed here were entered in error or marked as deleted in error. They are not valid for any encounters. Select "Activate" to reinstate this insurance if it was deleted in error'
                }
            </li>,
        );

    if (viewType === InsuranceCardView.Expired)
        messages.push(
            <li>
                {
                    'Insurances displayed here were active for dates of service between the Effective and Expiration Dates displayed. Select "Edit" to remove or correct the expiration date if it is not accurate.'
                }
            </li>,
        );

    if (!hasInsurances) messages.push(<li>{`Patient has no ${viewType.toLowerCase()} insurances.`}</li>);

    if (messages.length) return <MessageBar>{messages}</MessageBar>;

    return null;
}

function InsuranceCardViewsGuarantorMessageBar() {
    const patient = useSelector(selectEditPatient);
    const hasGuarantor = useSelector(selectPatientHasGuarantorInContacts);
    const hasInsurances = useSelector(insuranceSelectors[InsuranceCardView.Active]).length;

    if (!hasGuarantor || !hasInsurances)
        return (
            <MessageBar>
                {!hasGuarantor && patient?.dateOfBirth && calculateAgeInYears(patient.dateOfBirth) < 18 && (
                    <li>Patient is under 18 years old. Guarantor other than patient is required.</li>
                )}
                {!hasGuarantor && (
                    <li>
                        No other guarantor was identified. Therefore, the patient will be the guarantor and responsible for the
                        services rendered.
                    </li>
                )}
                {!hasInsurances && <li>Patient has no active insurance. Guarantor may be responsible for services rendered.</li>}
            </MessageBar>
        );

    return null;
}

interface IInsuranceCardViewTypeComponentProps {
    viewType: InsuranceCardView;
}

type RenderInsuranceCardViewProps = IInsuranceCardViewTypeComponentProps & IInsuranceComponentBaseProps;

function RenderInsuranceCardView(props: RenderInsuranceCardViewProps) {
    const insurances = useSelector(insuranceSelectors[props.viewType]);

    return (
        <Stack horizontal wrap tokens={{ childrenGap: 10 }}>
            <InsuranceCardViewsMessageBar viewType={props.viewType} />
            {insurances.map((insurance) => (
                <InsuranceCard {...props} insurance={insurance} key={`${insurance.id}-insurance-card`} />
            ))}
        </Stack>
    );
}
