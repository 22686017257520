import * as PIXI from 'pixi.js-legacy';

/**
 * A class that contains tools to get PIXI vertex data for sprite hitareas
 *
 * @export
 * @class CombinedShape
 */
export default class CombinedShape {
    shape: PIXI.Polygon[];

    constructor(shape: PIXI.Polygon[]) {
        this.shape = shape;
    }

    contains(x: number, y: number): boolean {
        if (!this.shape || this.shape.length === 0) return false;

        const count = this.shape.length;
        for (let i = 0; i < count; i++) {
            if (this.shape[i].contains(x, y))
                return true;
        }

        return false;
    }

    static parse(shapeData: { points: number[] }, scale?: number): CombinedShape {
        let points: unknown[] = [];

        if (Array.isArray(shapeData))
            points = shapeData;
        else if (shapeData.points)
            points = shapeData.points;

        if (!Array.isArray(points))
            throw Error("Points can't array!");

        if (!Array.isArray(points[0]) || points.length === 2)
            points = [points];

        //unpack [[x,y]]
        if (points[0].length === 2)
            points = points.map((p) => p.flat());

        if (scale) {
            points = points.map((p) => {
                return p.map((el: number) => el * scale);
            });
        }

        const polygons = points.map((p) => (new PIXI.Polygon(p)));

        return new this(polygons);
    }
}