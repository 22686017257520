import { Dictionary } from '@reduxjs/toolkit';
import IChart, { IChartCondition, IChartProcedure } from 'api/models/chart.model';
import { flatten, some } from 'lodash';
import { cannotEditChartAction } from 'pages/Charting/components/ChartActions';
import { dentition } from 'pages/Charting/components/ToothCanvas/settings';
import { createSelector } from 'reselect';
import { allPatientEncounters, patientEncounterLookup } from 'state/slices/patient/patient.selectors';
import { proceduresList, selectProcedures, selectProceduresData } from 'state/slices/tenant/procedures.slice';
import { RootState } from 'state/store';
import { conditionsAsList, selectConditionsData } from '../../tenant/conditions.slice';
import ChartingActionsList, { IChartAction } from '../chartActionsList.pipeline';
import ChartingRenderPipeline from '../chartingRender.pipeline';
import ConditionCodes from '../conditionCodes';
import { chartConditionsAsList } from '../conditions/conditions.selectors';
import { chartDentitionsData } from '../dentition/dentition.selectors';
import ProcedureCodes, { ponticCodes, extractionCodes } from '../procedureCodes';
import { chartProceduresData } from '../procedures/procedures.selectors';
import { selectedTeethSelector } from '../ui/chartingUi.selectors';
import { ChartingState, ProcedureActionType } from './chart.slice';
import { selectAccountData } from 'state/slices/account.slice';
import { selectPatientEncounter } from 'state/slices/encounter/encounter.selectors';

export const selectCharting = (state: RootState): ChartingState => state.charting;
export const getSelectedChartActions = (state: RootState): IChartAction[] => state.charting.selectedChartActions ?? [];
export const getSelectedChartProcedureActions = createSelector(getSelectedChartActions, (selectedChartActions) =>
    selectedChartActions?.filter((p) => p.actionType === 'Procedure'),
);
export const canEditSelectedChartProcedureActions = createSelector(
    getSelectedChartProcedureActions,
    patientEncounterLookup,
    selectAccountData,
    selectPatientEncounter,
    (procedureActions, encounterLookup, account, encounter) =>
        some(procedureActions, (item) => cannotEditChartAction(item, encounterLookup, account, encounter?.id)),
);
export const chartData = (state: RootState): IChart | undefined => state.charting.chart;
export const chartDentitionMode = createSelector([chartData], (data) => data?.dentitionMode);
export const isModalOpen = (state: RootState): boolean => state.charting.modalOpen;
export const isAttestModalOpen = (state: RootState): boolean => state.charting.attestModalOpen;

export const chartActionDateLookup = createSelector(
    chartConditionsAsList,
    chartProceduresData,
    allPatientEncounters,
    (chartConditions, chartProcedures, encounters) => {
        const lookup: Dictionary<string> = {};
        [...chartConditions, ...chartProcedures].forEach((chartItem) => {
            const isProcedure = !!(chartItem as IChartProcedure).type;
            if (isProcedure) {
                const chartProcedure = chartItem as IChartProcedure;
                if (
                    chartProcedure.type === ProcedureActionType.Treatment ||
                    chartProcedure.type === ProcedureActionType.Referred ||
                    chartProcedure.type === ProcedureActionType.Existing ||
                    chartProcedure.type === ProcedureActionType.ExistingOther
                ) {
                    const chartItemEncounter = encounters.find((encounter) => encounter.id === chartItem.encounterId);
                    if (chartItemEncounter) {
                        lookup[chartItem.id] = chartItemEncounter.encounterDate;
                    } else {
                        lookup[chartItem.id] = chartItem.modifiedOn;
                    }
                }
            } else {
                const chartCondition = chartItem as IChartCondition;
                const chartItemEncounter = encounters.find((encounter) => encounter.id === chartItem.encounterId);
                if (chartItemEncounter) {
                    lookup[chartItem.id] = chartItemEncounter.encounterDate;
                } else {
                    lookup[chartItem.id] = chartCondition.modifiedOn;
                }
            }
        });

        return lookup;
    },
);

export const chartActions = createSelector(
    [selectProcedures, chartProceduresData, chartConditionsAsList, selectConditionsData],
    (procedures, chartProcedures, conditions, conditionLookup) => {
        return new ChartingActionsList({ procedures, chartProcedures, conditions, conditionLookup }).getItems;
    },
);

export const chartActionProcedures = createSelector([selectProcedures, chartProceduresData], (procedures, chartProcedures) => {
    return new ChartingActionsList({ procedures, chartProcedures }).getItems;
});

export const chartRenderDataSelector = createSelector(
    [chartProceduresData, selectedTeethSelector, chartDentitionsData, chartConditionsAsList, conditionsAsList, proceduresList],
    (procedures, selectedTeeth, dentitions, conditions, tenantConditions, tenantProcedures) => {
        const renderPipeline = new ChartingRenderPipeline({
            dentition: dentition.permanent,
            selectedTeeth,
            procedures,
            conditions,
            dentitions,
            tenantConditions,
            tenantProcedures,
        });

        return renderPipeline.getItems;
    },
);

export const canSetProceduresCompleted = createSelector(
    [chartActions, getSelectedChartActions, selectProceduresData],
    (chartActionsList, selectedChartActions, proceduresData) => {
        const selectedActions = selectedChartActions ? selectedChartActions : [];
        const uncompletePonticTeeth = flatten(
            selectedActions
                .filter((p) => p.actionType === 'Procedure')
                .filter((p) => {
                    const procedure = p.actionId ? proceduresData[p.actionId] : undefined;
                    if (procedure) return ponticCodes.includes(procedure.code as ProcedureCodes);
                    return false;
                })
                .map((p) => p.toothIds)
                .filter((t) => t !== undefined) as number[][],
        );

        const completedExistingExtractionTeeth = flatten(
            chartActionsList
                .filter((a) => (a.code ? extractionCodes.includes(a.code as ProcedureCodes) : false))
                .filter(
                    (a) =>
                        a.status === 'Completed' ||
                        a.procedureType === ProcedureActionType.Existing ||
                        a.procedureType === ProcedureActionType.ExistingOther,
                )
                .map((a) => a.toothIds)
                .filter((t) => t !== undefined) as number[][],
        );

        const missingTeeth = flatten(
            chartActionsList
                .filter((a) => a.actionType === 'Condition')
                .filter((a) => a.code === ConditionCodes.Missing)
                .map((a) => a.toothIds)
                .filter((t) => t !== undefined) as number[][],
        );

        const errorTeeth: number[] = uncompletePonticTeeth.filter(
            (t) => !completedExistingExtractionTeeth.includes(t) && !missingTeeth.includes(t),
        );

        return !errorTeeth.length;
    },
);
