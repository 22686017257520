import { useSelector } from 'hooks';
import { getValidationError } from 'hooks/useValidation';
import { AdjustmentReasonDropdown } from 'pages/Ledger/components/AdjustmentReasonDropdown';
import { useDispatch } from 'react-redux';
import { updatePatientAdjustmentReasons } from 'state/slices/ledger/ledger.slice';
import {
    selectPatientAdjustmentTransactions,
    selectPatientPaymentsAndAdjustmentsValidationErrors,
} from 'state/slices/ledger/patient-payments-and-adjustments/patient-payments-and-adjustments.selectors';

export default function AdjustmentReason() {
    const dispatch = useDispatch();

    const transactions = useSelector(selectPatientAdjustmentTransactions);

    const firstEncounterId = transactions ? Object.keys(transactions)[0] : '';
    const firstTransactionId = transactions && firstEncounterId ? Object.keys(transactions[firstEncounterId])[0] : '';

    const adjustmentReasonFromTransaction =
        firstEncounterId && firstTransactionId
            ? transactions[firstEncounterId][firstTransactionId]?.adjustmentReasonId
            : undefined;
    const errors = useSelector(selectPatientPaymentsAndAdjustmentsValidationErrors);

    const _onChangeReason = (adjustmentReasonId?: string | number) => {
        if (adjustmentReasonId) {
            dispatch(updatePatientAdjustmentReasons(adjustmentReasonId as string));
        }
    };

    return (
        <AdjustmentReasonDropdown
            selectedKey={adjustmentReasonFromTransaction}
            onChange={_onChangeReason}
            placeholder="(Select)"
            dropdownWidth={0}
            styles={{ root: { minWidth: 150 } }}
            errorMessage={getValidationError(errors ?? [], 'Adjustment Reason') ? 'Adjustment reason is required.' : undefined}
            required
        />
    );
}
