import {
    IButtonProps,
    PrimaryButton,
    DefaultButton,
    IconButton,
    CommandBarButton,
    CompoundButton,
    MessageBarButton,
} from '@fluentui/react';

const ButtonTypes = {
    PrimaryButton,
    DefaultButton,
    IconButton,
    CommandBarButton,
    CompoundButton,
    MessageBarButton,
};

export interface IActionButton extends IButtonProps {
    type?: keyof typeof ButtonTypes;
}

type Props = IActionButton;

const ActionButton = ({ type, ...button }: Props): JSX.Element => {
    const Button = type ? ButtonTypes[type] : ButtonTypes['DefaultButton'];
    return <Button {...button} />;
};

export default ActionButton;
