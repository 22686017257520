import { Stack, TextField } from '@fluentui/react';
import { IPatientEligibility, IPatientInsurance } from 'api/models/patient.model';
import { Field, SubSectionHeader } from 'components';
import { IValidationError, getValidationError, validateErrorMessage } from 'hooks/useValidation';

type EligibilityProps = {
    insurance: IPatientInsurance | undefined;
    newEligibilityPropChange: (path: keyof IPatientEligibility, value: unknown) => void;
    validationErrors: IValidationError[];
};

function EligibilityLimitations(props: EligibilityProps): JSX.Element {
    const { insurance, newEligibilityPropChange, validationErrors } = props;
    const convertBooleanValue = (key: keyof IPatientEligibility) => {
        return insurance?.eligibility && insurance?.eligibility[key] ? 'true' : 'false';
    };

    const validNumberInput = (value?: string): boolean => {
        const res = value ? /^[1-9][0-9]*$/.test(value) : true;
        return res;
    };

    return (
        <Stack tokens={{ childrenGap: 10 }} grow>
            <SubSectionHeader text="Frequency Limitations" />
            <Stack tokens={{ childrenGap: 10 }} horizontal grow>
                <Stack.Item grow>
                    <TextField
                        label="Prophy/Exam per year"
                        value={insurance?.eligibility?.prophyBenefits?.toString() ?? ''}
                        onChange={(ev, value) => {
                            if (validNumberInput(value)) newEligibilityPropChange('prophyBenefits', value);
                        }}
                        autoComplete="off"
                    />
                </Stack.Item>
                <Stack.Item grow>
                    <TextField
                        label="Bitewing per year"
                        value={insurance?.eligibility?.bitewingBenefits?.toString() ?? ''}
                        onChange={(ev, value) => {
                            if (validNumberInput(value)) newEligibilityPropChange('bitewingBenefits', value);
                        }}
                        autoComplete="off"
                    />
                </Stack.Item>
                <Stack.Item grow>
                    <TextField
                        label="Full Mouth Xray once every"
                        suffix="mos."
                        value={insurance?.eligibility?.fullXrayBenefits?.toString() ?? ''}
                        onChange={(ev, value) => newEligibilityPropChange('fullXrayBenefits', value)}
                        autoComplete="off"
                    />
                </Stack.Item>
                <Stack.Item grow>
                    <Field.Date
                        label="Last Full Mouth Xray"
                        hasDatePicker
                        value={insurance?.eligibility?.lastFullXrayBenefits ?? ''}
                        onChange={(ev, value) => newEligibilityPropChange('lastFullXrayBenefits', value)}
                        errorMessage={validateErrorMessage(getValidationError(validationErrors, 'Xray Date'))}
                    />
                </Stack.Item>
            </Stack>
            <Stack tokens={{ childrenGap: 10 }} horizontal grow>
                <Stack.Item grow>
                    <TextField
                        label="Fluoride Varnish per year"
                        value={insurance?.eligibility?.fluorideBenefits?.toString() ?? ''}
                        onChange={(ev, value) => newEligibilityPropChange('fluorideBenefits', value)}
                        autoComplete="off"
                    />
                </Stack.Item>
                <Stack.Item grow>
                    <Field.Dropdown
                        label="Age Limit for Fluoride?"
                        placeholder="(Select)"
                        options={[
                            {
                                key: 'true',
                                text: 'Yes',
                            },
                            {
                                key: 'false',
                                text: 'No',
                            },
                        ]}
                        selectedKey={convertBooleanValue('hasFluorideAgeLimit')}
                        onChange={(e, option) => {
                            if (option) {
                                if (option.key === 'true') {
                                    newEligibilityPropChange('hasFluorideAgeLimit', true);
                                }
                                if (option.key === 'false') {
                                    newEligibilityPropChange('hasFluorideAgeLimit', false);
                                }
                            }
                        }}
                    />
                </Stack.Item>
                <Stack.Item grow>
                    <TextField
                        label="Age?"
                        value={insurance?.eligibility?.fluorideAgeLimit?.toString() ?? ''}
                        onChange={(ev, value) => newEligibilityPropChange('fluorideAgeLimit', value)}
                        autoComplete="off"
                    />
                </Stack.Item>
            </Stack>
            <Stack tokens={{ childrenGap: 10 }} horizontal grow>
                <Stack.Item grow>
                    <Field.Dropdown
                        label="Is there Sealant Coverage?"
                        placeholder="(Select)"
                        options={[
                            {
                                key: 'true',
                                text: 'Yes',
                            },
                            {
                                key: 'false',
                                text: 'No',
                            },
                        ]}
                        selectedKey={convertBooleanValue('sealantBenefits')}
                        onChange={(e, option) => {
                            if (option) {
                                if (option.key === 'true') {
                                    newEligibilityPropChange('sealantBenefits', true);
                                }
                                if (option.key === 'false') {
                                    newEligibilityPropChange('sealantBenefits', false);
                                }
                            }
                        }}
                    />
                </Stack.Item>
                <Stack.Item grow>
                    <Field.Dropdown
                        label="Age Limit for Sealants?"
                        placeholder="(Select)"
                        options={[
                            {
                                key: 'true',
                                text: 'Yes',
                            },
                            {
                                key: 'false',
                                text: 'No',
                            },
                        ]}
                        selectedKey={convertBooleanValue('hasSealantAgeLimit')}
                        onChange={(e, option) => {
                            if (option) {
                                if (option.key === 'true') {
                                    newEligibilityPropChange('hasSealantAgeLimit', true);
                                }
                                if (option.key === 'false') {
                                    newEligibilityPropChange('hasSealantAgeLimit', false);
                                }
                            }
                        }}
                    />
                </Stack.Item>
                <Stack.Item grow>
                    <TextField
                        label="Age?"
                        value={insurance?.eligibility?.sealantAgeLimit?.toString() ?? ''}
                        onChange={(ev, value) => newEligibilityPropChange('sealantAgeLimit', value)}
                        autoComplete="off"
                    />
                </Stack.Item>
            </Stack>
            <Field.Dropdown
                label="Missing Tooth Clause?"
                placeholder="(Select)"
                options={[
                    {
                        key: 'true',
                        text: 'Yes',
                    },
                    {
                        key: 'false',
                        text: 'No',
                    },
                ]}
                selectedKey={convertBooleanValue('missingToothClause')}
                onChange={(e, option) => {
                    if (option) {
                        newEligibilityPropChange(
                            'missingToothClause',
                            option?.key === 'true' ? true : option?.key === 'false' ? false : undefined,
                        );
                    }
                }}
            />
            <TextField
                label="Eligibility Message"
                multiline
                rows={5}
                value={insurance?.eligibility?.eligibilityMessage ?? ''}
                onChange={(ev, value) => newEligibilityPropChange('eligibilityMessage', value)}
                autoComplete="off"
            />
        </Stack>
    );
}

export default EligibilityLimitations;
