import { IPerioExam } from 'api/models/perio-exam.model';

export const perioPanelInitialState: PerioPanelState = {
    isOpen: false,
    isComparing: false,
    examsToCompare: [],
};

export interface PerioPanelState {
    isOpen: boolean;
    isComparing: boolean;
    examsToCompare?: IPerioExam[];
}
