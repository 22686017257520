import { ITheme, MessageBar, Stack, Text, memoizeFunction, mergeStyleSets, useTheme } from '@fluentui/react';
import { PickerSuggestionContentGetterFunction } from './CustomDataProvider';
import { useWindowHeight } from 'hooks/useWindowHeight';

export type PickerSuggestionOption<T> = { displayName: string; key: string; data?: T };

export type Point = {
    x: number;
    y: number;
};

export type PickerListItemRenderFunction<T> = (
    option: PickerSuggestionOption<T>,
    index: number,
    selectedIndex: number,
) => React.ReactNode | null;

export type PickerComponentProps<T> = {
    selectedIndex: number;
    isPickerVisible: boolean;
    position: Point;
    pickerId: string;
    options: PickerSuggestionOption<T>[];
    onClick?: (index: number) => void;

    onRenderListItem?: PickerListItemRenderFunction<T>;
    suggestionContentGetter: PickerSuggestionContentGetterFunction<T>;
};

const CONTAINER_HEIGHT = 230;
const CONTAINER_OFFSET = 15;

export default function PickerComponent<T>({
    isPickerVisible,
    pickerId,
    position,
    selectedIndex,
    options,
    onClick,
    onRenderListItem,
    suggestionContentGetter,
}: PickerComponentProps<T>) {
    const theme = useTheme();
    const classes = getClasses(theme);

    const windowHeight = useWindowHeight();

    const htmlPreview = options[selectedIndex] ? suggestionContentGetter(options[selectedIndex]) : '';

    return (
        <div
            id={`${pickerId}-wrapper`}
            className={`${classes.root} ${isPickerVisible ? 'visible' : ''} ms-motion-slideUpIn ms-depth-8`}
            style={{
                top:
                    position.y + CONTAINER_OFFSET + CONTAINER_HEIGHT > windowHeight
                        ? position.y - CONTAINER_HEIGHT - CONTAINER_OFFSET
                        : position.y + CONTAINER_OFFSET,
                left: position.x,
            }}
        >
            <div className={classes.listWrapper}>
                {options.length ? (
                    <ul id={pickerId} className={classes.list} role="listbox">
                        {options.map((option, i) => {
                            return (
                                <li
                                    onClick={() => {
                                        if (onClick) onClick(i);
                                    }}
                                    key={`picker-${option.key}-${i}`}
                                    className={`${classes.listItem} ${selectedIndex === i ? 'focused' : ''}`}
                                    id={`ui-${i}`}
                                    role="option"
                                    title={option.displayName}
                                    tabIndex={0}
                                >
                                    {onRenderListItem ? (
                                        onRenderListItem(option, i, selectedIndex)
                                    ) : (
                                        <Text title={option.displayName} className={classes.listItemText}>
                                            {option.displayName}
                                        </Text>
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                ) : (
                    <MessageBar>No results for your search</MessageBar>
                )}
            </div>
            {options.length > 0 && options[selectedIndex] ? (
                <Stack grow>
                    <div className={classes.previewLabel}>
                        <Text>Preview: </Text>
                    </div>
                    {htmlPreview ? (
                        <div className={classes.previewContent} dangerouslySetInnerHTML={{ __html: htmlPreview }} />
                    ) : (
                        <div className={classes.previewContent}>
                            <MessageBar>No content found.</MessageBar>
                        </div>
                    )}
                </Stack>
            ) : null}
        </div>
    );
}

const classNames = {
    root: 'suggestion-wrapper',
    list: 'suggestion_list',
    listWrapper: 'suggestion_list-wrapper',
    listItem: 'suggestion_list-item',
    listItemText: 'suggestion_list-item-text',
    noResults: 'suggestion_no-results',
    previewLabel: 'suggestion_preview-label',
    previewContent: 'suggestion_preview-content',
};

const getClasses = memoizeFunction((theme: ITheme) =>
    mergeStyleSets({
        root: [
            classNames.root,
            {
                background: theme.palette.white,
                display: 'none',
                position: 'absolute',
                height: CONTAINER_HEIGHT,
                overflowY: 'auto',
                borderRadius: 3,
                zIndex: 1000001,
                border: `1px solid ${theme.palette.neutralSecondaryAlt}`,

                '&.visible': {
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                },
            },
        ],
        listWrapper: [
            classNames.listWrapper,
            {
                overflowY: 'auto',
                flexGrow: 2,
            },
        ],
        list: [
            classNames.list,
            {
                listStyle: 'none',
                margin: 0,
                width: 300,
                padding: 0,
            },
        ],
        listItem: [
            classNames.listItem,
            {
                margin: 0,
                padding: '2px 5px',
                textOverflow: 'ellipsis',
                textWrap: 'nowrap',
                overflow: 'hidden',
                cursor: 'pointer',
                '&.focused': {
                    background: theme.palette.themeLight,
                },
                ':hover': {
                    background: theme.palette.neutralLight,
                    color: theme.palette.white,
                },
            },
        ],
        listItemText: [
            classNames.listItemText,
            {
                pointerEvents: 'none',
            },
        ],
        noResults: [
            classNames.noResults,
            {
                paddingLeft: 5,
                fontStyle: 'italic',
            },
        ],
        previewLabel: [
            classNames.previewLabel,
            {
                fontWeight: 'bold',
                background: theme.palette.neutralLighter,
                padding: '2px 5px',
            },
        ],
        previewContent: [
            classNames.previewContent,
            {
                overflow: 'auto',
                maxHeight: CONTAINER_HEIGHT,
                width: 400,
                padding: 10,
            },
        ],
    }),
);
