import { IStackTokens, Stack } from '@fluentui/react';
import { ToothArea } from 'api/models/tooth-area';
import { useChart, useChartProcedures, useSelector, useTenant } from 'hooks';
import { selectConditionsPanel } from 'state/slices/charting/condition-panel/condition-panel.selectors';
import { selectFirstToothPosteriorAnterior } from 'state/slices/charting/ui/chartingUi.selectors';
import getToothId from 'utils/getToothId';
import SmallDefaultButton from './SmallDefaultButton';

export default function AnteriorComposite(): JSX.Element {
    const { addProcedures } = useChartProcedures();
    const { getProcedureIdByCode } = useTenant();

    const { selectedTeeth } = useChart();

    const noneSelected = selectedTeeth.length <= 0;

    const firstToothPosteriorAnterior = useSelector(selectFirstToothPosteriorAnterior);
    const _buttonDisabled = noneSelected || firstToothPosteriorAnterior === 'Posterior';

    const stackTokens: IStackTokens = {
        childrenGap: 5,
    };
    function addDL(e: React.MouseEvent<HTMLDivElement>) {
        const buttonId = e.currentTarget?.id;

        addProcedures({
            procedureIds: [getProcedureIdByCode('D2331')],
            toothAreas: ['Distal', 'Lingual'],
            addAutoApplyDiagnoses: true,
            elementId: buttonId,
        });
    }

    function addMDL(e: React.MouseEvent<HTMLDivElement>) {
        const buttonId = e.currentTarget?.id;

        addProcedures({
            procedureIds: [getProcedureIdByCode('D2332')],
            toothAreas: ['Mesial', 'Distal', 'Lingual'],
            addAutoApplyDiagnoses: true,
            elementId: buttonId,
        });
    }

    function addML(e: React.MouseEvent<HTMLDivElement>) {
        const buttonId = e.currentTarget?.id;

        addProcedures({
            procedureIds: [getProcedureIdByCode('D2331')],
            toothAreas: ['Mesial', 'Lingual'],
            addAutoApplyDiagnoses: true,
            elementId: buttonId,
        });
    }

    function addD2330(e: React.MouseEvent<HTMLDivElement>, area: keyof typeof ToothArea) {
        const buttonId = e.currentTarget?.id;

        addProcedures({
            procedureIds: [getProcedureIdByCode('D2330')],
            toothAreas: [area],
            addAutoApplyDiagnoses: true,
            elementId: buttonId,
        });
    }

    return (
        <Stack tokens={stackTokens} horizontalAlign="center" grow>
            <Stack tokens={stackTokens} horizontal grow>
                <SmallDefaultButton text="DL" disabled={_buttonDisabled} onClick={addDL} />
                <SmallDefaultButton text="MDL" disabled={_buttonDisabled} onClick={addMDL} />
                <SmallDefaultButton text="ML" disabled={_buttonDisabled} onClick={addML} />
            </Stack>
            <Stack tokens={stackTokens} horizontal grow>
                <SmallDefaultButton
                    text="I"
                    disabled={_buttonDisabled}
                    onClick={(e: React.MouseEvent<HTMLDivElement>) => addD2330(e, 'Incisal')}
                />
                <SmallDefaultButton
                    text="L"
                    disabled={_buttonDisabled}
                    onClick={(e: React.MouseEvent<HTMLDivElement>) => addD2330(e, 'Lingual')}
                />
                <SmallDefaultButton
                    text="F"
                    disabled={_buttonDisabled}
                    onClick={(e: React.MouseEvent<HTMLDivElement>) => addD2330(e, 'Facial')}
                />
            </Stack>
        </Stack>
    );
}
