import { Stack, Link, IStackTokens } from '@fluentui/react';
import queryString from 'query-string';
import PanelSectionHeader from 'pages/components/PanelSectionHeader';
import { useHistory } from 'react-router-dom';
import { Field } from 'components';
import AdjustmentReason from './AdjustmentReason';
import { getValidationError } from 'hooks/useValidation';
import {
    selectPatientAdjustmentInfoSaving,
    selectPatientAdjustmentModified,
    selectPatientOverpaymentOrOverAdjustment,
    selectPatientPaymentInfoSaving,
    selectPatientPaymentsAndAdjustmentsValidationErrors,
    selectPatientTotalAdjustmentAmount,
    selectPaymentAndAdjustmentAmounts,
    selectPaymentsAndAdjustmentTotalPayment,
} from 'state/slices/ledger/patient-payments-and-adjustments/patient-payments-and-adjustments.selectors';
import { useSelector } from 'hooks';
import { cleanupAdjustmentInformation, updatePatientTotalAdjustmentAmount } from 'state/slices/ledger/ledger.slice';
import { useDispatch } from 'react-redux';
import { FormEvent, useCallback, useEffect } from 'react';
import { usdCurrencyFormatter } from 'utils';
import { LoadingStatus } from 'interfaces/loading-statuses';

type Props = {
    isMakingPaymentAndAdjustment: boolean;
};

const stackTokens: IStackTokens = { childrenGap: 10 };

export default function AdjustmentInfoSection({ isMakingPaymentAndAdjustment }: Props) {
    const dispatch = useDispatch();
    const { push } = useHistory();

    const errors = useSelector(selectPatientPaymentsAndAdjustmentsValidationErrors);
    const totalAdjustmentAmount = useSelector(selectPatientTotalAdjustmentAmount);
    const { remainingAdjustmentAmount, paymentAmount } = useSelector(selectPaymentAndAdjustmentAmounts);
    const totalPaymentDue = useSelector(selectPaymentsAndAdjustmentTotalPayment);
    const overadjustment = useSelector(selectPatientOverpaymentOrOverAdjustment);
    const adjustmentsModified = useSelector(selectPatientAdjustmentModified);
    const savingAdjustmentInfo = useSelector(selectPatientAdjustmentInfoSaving);

    const makePayment = () => {
        const shouldConfirm =
            savingAdjustmentInfo !== LoadingStatus.Completed &&
            savingAdjustmentInfo !== LoadingStatus.Failed &&
            adjustmentsModified;
        const confirm = shouldConfirm
            ? window.confirm('Are you sure that you want to remove adjustment info? Any unsaved information will be lost.')
            : true;
        if (confirm) push({ search: queryString.stringify({ hideMakeAdjustment: true }) });
    };

    useEffect(() => {
        return () => {
            dispatch(cleanupAdjustmentInformation());
        };
    }, []);

    const _onChangeAdjustmentAmount = (ev?: FormEvent, input?: number | undefined) => {
        dispatch(updatePatientTotalAdjustmentAmount(input));
    };

    const getMaxPayment = useCallback(() => {
        if (overadjustment) return undefined;
        const totalFee = totalPaymentDue - paymentAmount;

        if (totalFee < 0) {
            return 0;
        } else {
            return totalFee;
        }
    }, [totalPaymentDue, paymentAmount, overadjustment]);

    return (
        <Stack>
            <PanelSectionHeader
                text="Adjustment Info"
                rightContent={isMakingPaymentAndAdjustment ? <Link onClick={makePayment}>Remove Adjustment</Link> : undefined}
                leftContent={<span>(Unallocated Amount: {usdCurrencyFormatter.format(remainingAdjustmentAmount)})</span>}
            />
            <Stack tokens={stackTokens} horizontal>
                <Field.Currency
                    prefix="$"
                    label="Adjustment Amount"
                    required
                    value={totalAdjustmentAmount}
                    onChange={_onChangeAdjustmentAmount}
                    max={getMaxPayment()}
                    errorMessage={getValidationError(errors, 'Adjustment Amount') ? 'Adjustment Amount is required.' : undefined}
                />
                <AdjustmentReason />
            </Stack>
        </Stack>
    );
}
