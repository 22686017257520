import { Label, Link, MessageBar, ProgressIndicator, Stack } from '@fluentui/react';
import { ClinicalNoteData, IChartNoteWithData, NoteType } from 'api/models/chart-note.model';
import UserDisplayName from 'components/UserDisplayName';
import { usePatientId, useTenantId } from 'hooks';
import { map } from 'lodash';
import { MouseEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clinicalNotesList } from 'state/slices/chart-notes/chart-notes.selectors';
import { getNoteBrowserClinicalNotes, setCurrentNoteById } from 'state/slices/chart-notes/note-browser/note-browser.actions';
import {
    selectClinicalNoteList,
    selectClinicalNoteListLoading,
    selectCurrentNote,
    selectMainActionsVisible,
} from 'state/slices/chart-notes/note-browser/note-browser.selectors';
import { noteBrowserActions } from 'state/slices/chart-notes/note-browser/note-browser.slice';
import { convertFromMilitaryTime } from 'utils/convertFromMilitaryTime';
import { classicDateOnly } from 'utils/dateOnly';

export const noteListTitleText: Partial<Record<NoteType, string>> = {
    [NoteType.ClinicalNote]: 'Encounter Notes',
    [NoteType.CareCoordination]: 'Care Coordination',
    [NoteType.FollowUp]: 'Follow up',
    [NoteType.PatientCommunication]: 'Patient Communication',
};

const noteListTypes = [NoteType.PatientCommunication, NoteType.CareCoordination, NoteType.FollowUp];

function NoteList() {
    const noteLists = noteListTypes.map((noteType) => {
        return <ChartNotesList noteType={noteType} key={noteType} />;
    });
    return (
        <Stack>
            <ClinicalNotesList />
            {noteLists}
        </Stack>
    );
}

function ClinicalNotesList() {
    const dispatch = useDispatch();
    const tenantId = useTenantId();
    const patientId = usePatientId();

    const _clinicalNotesList = useSelector(selectClinicalNoteList);
    const _clinicalNoteListLoading = useSelector(selectClinicalNoteListLoading);
    const currentNote = useSelector(selectCurrentNote);

    useEffect(() => {
        if (tenantId && patientId) dispatch(getNoteBrowserClinicalNotes({ tenantId, patientId }));
    }, [tenantId, patientId, dispatch]);

    const clinicalNoteLinks = map(_clinicalNotesList, (note, date) => {
        return (
            <Stack key={date}>
                <Stack>
                    <Label style={{ paddingBottom: 0 }}>{classicDateOnly(date)}</Label>
                    {note.map((note) => {
                        const selectNote = (e?: MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLElement>) => {
                            e?.preventDefault();
                            if (note.id) dispatch(setCurrentNoteById(note.id));
                        };
                        return (
                            <Link
                                key={note.id}
                                style={{ fontWeight: currentNote?.id === note.id ? 'bold' : 'inherit', userSelect: 'none' }}
                                onClick={selectNote}
                            >
                                {note.displayName}
                            </Link>
                        );
                    })}
                </Stack>
            </Stack>
        );
    });

    return (
        <Stack tokens={{ childrenGap: 5 }}>
            <Stack tokens={{ childrenGap: 2 }}>
                <Label>{noteListTitleText[NoteType.ClinicalNote]}</Label>
                {_clinicalNoteListLoading ? (
                    <ProgressIndicator />
                ) : clinicalNoteLinks.length ? (
                    clinicalNoteLinks
                ) : (
                    <MessageBar>No encounter notes found</MessageBar>
                )}
            </Stack>
        </Stack>
    );
}

type ChartNoteBrowserListsProps = {
    noteType: NoteType;
};
function ChartNotesList({ noteType }: ChartNoteBrowserListsProps) {
    const _clinicalNotesList = useSelector(clinicalNotesList);
    const currentNote = useSelector(selectCurrentNote);
    const noteList = _clinicalNotesList.filter((note) => note.noteType === noteType);

    if (!noteList.length) return null;

    return (
        <Stack tokens={{ childrenGap: 5 }}>
            <Stack tokens={{ childrenGap: 2 }}>
                <Label>{noteListTitleText[noteType]}</Label>
                {noteList.map((note) => (
                    <NoteListItem isSelected={currentNote?.id === note.id} note={note} key={note.id} />
                ))}
            </Stack>
        </Stack>
    );
}

/**
 * Note List Item
 *
 * - Encounter Date
 * - Title of the Note [Appt. Start Time, Appt. Encounter-reason, Appt. Treating Provider]
 */
type NoteListItemProps = {
    note: IChartNoteWithData<ClinicalNoteData>;
    isSelected: boolean;
};
function NoteListItem({ note, isSelected }: NoteListItemProps) {
    const dispatch = useDispatch();
    const linksDisabled = useSelector(selectMainActionsVisible);
    const selectChartNote = (e?: MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLElement>) => {
        e?.preventDefault();
        dispatch(noteBrowserActions.setChartNote(note));
    };
    return (
        <Link
            style={{ fontWeight: isSelected ? 'bold' : 'inherit', userSelect: 'none' }}
            onClick={selectChartNote}
            disabled={linksDisabled}
        >
            {note.encounterDate ? classicDateOnly(note.encounterDate) : note.createdOn ? classicDateOnly(note.createdOn) : ''}
        </Link>
    );
}

export default NoteList;
