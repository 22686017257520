import { IChartProcedure } from 'api/models/chart.model';
import { createRule } from '../../ruleGenerator';
import ProcedureCodes from '../../procedureCodes';
import ProcedureBusinessRulesPipeline from '../procedureBusinessRules.pipeline';
import { surfaceProcedureCodeLookup } from '../../surfaceProcedureCodeLookup';
import { find } from 'lodash';

const surfaceOnlyBusinessRule = createRule<ProcedureBusinessRulesPipeline, IChartProcedure>({
    ruleTypes: [
        ProcedureCodes.D2140,
        ProcedureCodes.D2330,
        ProcedureCodes.D2391,
        ProcedureCodes.D2410,
        ProcedureCodes.D2510,
        ProcedureCodes.D2610,
        ProcedureCodes.D2650,
        ProcedureCodes.D2150,
        ProcedureCodes.D2331,
        ProcedureCodes.D2392,
        ProcedureCodes.D2420,
        ProcedureCodes.D2520,
        ProcedureCodes.D2620,
        ProcedureCodes.D2651,
        ProcedureCodes.D2160,
        ProcedureCodes.D2332,
        ProcedureCodes.D2393,
        ProcedureCodes.D2430,
        ProcedureCodes.D2530,
        ProcedureCodes.D2630,
        ProcedureCodes.D2652,
        ProcedureCodes.D2161,
        ProcedureCodes.D2335,
        ProcedureCodes.D2394,

        ProcedureCodes.D2542,
        ProcedureCodes.D2543,
        ProcedureCodes.D2544,

        ProcedureCodes.D2642,
        ProcedureCodes.D2643,
        ProcedureCodes.D2644,

        ProcedureCodes.D2662,
        ProcedureCodes.D2663,
        ProcedureCodes.D2664,

        ProcedureCodes.D6600,
        ProcedureCodes.D6601,
        ProcedureCodes.D6602,
        ProcedureCodes.D6603,
        ProcedureCodes.D6604,
        ProcedureCodes.D6605,
        ProcedureCodes.D6606,
        ProcedureCodes.D6607,
        ProcedureCodes.D6608,
        ProcedureCodes.D6609,
        ProcedureCodes.D6610,
        ProcedureCodes.D6611,
        ProcedureCodes.D6612,
        ProcedureCodes.D6613,
        ProcedureCodes.D6614,
        ProcedureCodes.D6615,
    ],
    rule: (pipeline, item) => {
        if (item) {
            const surfaceCount = item.areas?.length;
            const procedure = pipeline.getProcedure(item);

            const keyedProcArray = find(
                surfaceProcedureCodeLookup,
                (codes) => codes.findIndex((obj) => obj.code === procedure?.code) > -1,
            );

            if (keyedProcArray && surfaceCount) {
                const codeObj = find(keyedProcArray, (proc) => proc.surfaceCount === surfaceCount);

                const maxSurfaceCount = Math.max(...keyedProcArray.map((p) => p.surfaceCount));
                const isMore = surfaceCount > maxSurfaceCount;
                const moreProcedure = find(keyedProcArray, (proc) => (proc.orMore ? true : false));

                const surfaceProcedureCode = isMore ? moreProcedure : codeObj;

                const newProcedure = pipeline.getProcedureFromCode(surfaceProcedureCode?.code);

                const firstOriginalProcedure = pipeline.originalProcedures[0];
                const newChartProcedure: IChartProcedure = {
                    ...item,
                    procedureId: newProcedure ? newProcedure.id : firstOriginalProcedure.id,
                };

                return { updatedItems: newChartProcedure.procedureId ? newChartProcedure : item };
            } else {
                return { updatedItems: item };
            }
        }

        return { updatedItems: item };
    },
});

export default surfaceOnlyBusinessRule;
