import { IChartProcedure, ChartProcedureStatus } from 'api/models/chart.model';
import { ConflictRuleType } from 'api/models/lookup.model';
import { intersection, uniq } from 'lodash';
import { ProcedureActionType } from '../chart/chart.slice';
import { IPipelineError, PipelineError } from '../pipeline';
import ProcedureConflictRulesPipeline from '../procedureConflictRules.pipeline';
import { createRule } from '../ruleGenerator';

const ProcedureOnePerSurface = createRule<ProcedureConflictRulesPipeline, IChartProcedure>({
    ruleTypes: [ConflictRuleType.onePerSurface],
    rule: (pipeline, item) => {
        const _errors: IPipelineError[] = [];
        const matchingProcedures = pipeline.getProceduresWithSameProcedureId(item.procedureId);
        const relatedProcedures = pipeline.getRelatedProcedures(item);
        const combinedProcedures = [...matchingProcedures, ...relatedProcedures]
            .filter((p) => p.id !== item.id) //Make sure that the current item is not included
            .filter((p) => p.type === ProcedureActionType.Treatment)
            .filter((p) => p.status === ChartProcedureStatus.Pending);

        if (combinedProcedures.length && item.status === ChartProcedureStatus.Pending) {
            const proceduresWithIntersectingTeeth = combinedProcedures.filter(
                (p) => intersection(p.toothIds, item.toothIds).length,
            );

            const intersectedProcedures = proceduresWithIntersectingTeeth.filter((p) => intersection(p.areas, item.areas).length);

            if (intersectedProcedures.length) {
                const intersectedAreas = uniq(intersectedProcedures.map((p) => intersection(p.areas, item.areas)).flat());
                const conflictProcedure = intersectedProcedures[0];
                const code = pipeline.getProcedure(conflictProcedure)?.code ?? 'Unknown Code';
                _errors.push({
                    type: PipelineError.OnePerSurface,
                    data: { code, toothIds: conflictProcedure.toothIds ?? [], areas: intersectedAreas ?? [] },
                });
            }
        }

        return { shouldRemoveItem: !!_errors.length, errors: _errors };
    },
});

export default ProcedureOnePerSurface;
