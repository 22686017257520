import { SelectionMode, Stack, Text } from '@fluentui/react';
import { ProcedureSummary } from 'api/models/encounter-ledger.model';
import { SortableDetailsList } from 'components';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import { useSelector } from 'hooks';
import { RouteParams } from 'interfaces/route-params';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getEncounterLedgerSummary } from 'state/slices/ledger/ledger.actions';
import {
    selectEncounterLedgerTransactionsLoading,
    selectEncounterLedgerTransactionsLookup,
} from 'state/slices/ledger/ledger.selectors';
import { usdCurrencyFormatter } from 'utils';
import RenderProcedureSummaryTooltip from '../components/RenderProcedureSummaryTooltip';

export const procedureSummaryColumns: ISortableColumn<ProcedureSummary>[] = [
    {
        key: 'codeDescription',
        minWidth: 100,
        maxWidth: 120,
        name: 'Code/Desc.',
        fieldName: 'procedureCode',
        onRender: (item) => <RenderProcedureSummaryTooltip item={item} />,
    },
    {
        key: 'fee',
        minWidth: 80,
        maxWidth: 100,
        name: 'Fee',
        fieldName: 'ucr',
        onRender: (item) => <Text variant="smallPlus">{usdCurrencyFormatter.format(item?.ucr ?? 0)}</Text>,
    },
    {
        key: 'insPaid',
        minWidth: 80,
        maxWidth: 100,
        name: 'Ins. Paid',
        fieldName: 'insuranceAmount',
        onRender: (item) => <Text variant="smallPlus">{usdCurrencyFormatter.format(item?.insurancePayments ?? 0)}</Text>,
    },
    {
        key: 'insAdjustment',
        minWidth: 80,
        maxWidth: 100,
        name: 'Ins. Adjustments',
        fieldName: 'insuranceAdjustments',
        onRender: (item) => <Text variant="smallPlus">{usdCurrencyFormatter.format(item?.insuranceAdjustments ?? 0)}</Text>,
    },
    // {
    //     key: 'patientFee',
    //     minWidth: 80,
    //     maxWidth: 100,
    //     name: 'Pt. Est.',
    //     fieldName: 'patientEstimate',
    //     onRender: (item) => <span>{usdCurrencyFormatter.format(item?.patientEstimate ?? 0)}</span>,
    // },
    {
        key: 'patientPaid',
        minWidth: 110,
        maxWidth: 120,
        name: 'Pt. Applied Pymts.',
        fieldName: 'patientPayments',
        onRender: (item) => <Text variant="smallPlus">{usdCurrencyFormatter.format(item?.patientPayments ?? 0)}</Text>,
    },
    {
        key: 'patientAdjustment',
        minWidth: 80,
        maxWidth: 100,
        name: 'Pt. Adjustments',
        fieldName: 'patientAdjustments',
        onRender: (item) => <Text variant="smallPlus">{usdCurrencyFormatter.format(item?.patientAdjustments ?? 0)}</Text>,
    },
    {
        key: 'insBalance',
        minWidth: 80,
        maxWidth: 100,
        name: 'Ins. Balance',
        fieldName: 'insuranceBalance',
        onRender: (item) => <Text variant="smallPlus">{usdCurrencyFormatter.format(item?.insuranceBalance ?? 0)}</Text>,
    },
    {
        key: 'patientBalance',
        minWidth: 80,
        maxWidth: 100,
        name: 'Pt. Balance',
        fieldName: 'patientBalance',
        onRender: (item) => <Text variant="smallPlus">{usdCurrencyFormatter.format(item?.patientBalance ?? 0)}</Text>,
    },
    {
        key: 'transferTo',
        minWidth: 80,
        maxWidth: 100,
        name: 'Resp. Party',
        fieldName: 'transferTo',
    },
    {
        key: 'reservePymts',
        minWidth: 80,
        name: 'Pt. Reserve Pymts',
        fieldName: 'patientReservePayments',
        onRender: (item) => (
            <Text variant="smallPlus">{usdCurrencyFormatter.format(Math.abs(item?.patientReservePayments ?? 0))}</Text>
        ),
    },
];

interface IEncounterTransactionListProps {
    encounterId: string;
}

export default function EncounterTransactionList({ encounterId }: IEncounterTransactionListProps): JSX.Element {
    const dispatch = useDispatch();
    const { tenantId, patientId } = useParams<RouteParams>();
    const _selectEncounterLedgerTransactionsLookup = useSelector(selectEncounterLedgerTransactionsLookup);
    const loading = useSelector(selectEncounterLedgerTransactionsLoading);

    const summary = _selectEncounterLedgerTransactionsLookup[encounterId];

    useEffect(() => {
        dispatch(getEncounterLedgerSummary({ tenantId, encounterId }));
    }, [tenantId, patientId, encounterId, dispatch]);

    return (
        <Stack style={{ paddingLeft: 10 }}>
            <SortableDetailsList<ProcedureSummary>
                compact
                items={summary?.procedureSummaries ?? []}
                selectionMode={SelectionMode.none}
                columns={procedureSummaryColumns}
                shimmerLines={2}
                enableShimmer={loading}
            />
        </Stack>
    );
}
