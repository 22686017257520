import { useSelector } from 'react-redux';
import {
    selectUACDistributionTransactionLoading,
    selectUACDistributionTotalRemaining,
    selectUACDistributionTransactionAmount,
} from 'state/slices/ledger/uac-distribution/uac-distribution.selectors';
import { usdCurrencyFormatter } from 'utils';
import UACDistributionCard from './UACDistributionCard';

export default function UACToDistributeCard() {
    const loading = useSelector(selectUACDistributionTransactionLoading);
    const amount = useSelector(selectUACDistributionTransactionAmount);
    const remainingAmount = useSelector(selectUACDistributionTotalRemaining);

    return (
        <UACDistributionCard title="UAC" loading={loading}>
            <span>Total: {usdCurrencyFormatter.format(amount)}</span>
            <span>Remaining: {usdCurrencyFormatter.format(remainingAmount)}</span>
        </UACDistributionCard>
    );
}
