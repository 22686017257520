import { Stack } from '@fluentui/react';
import { Field, TText } from 'components';
import { useSelector } from 'hooks';
import { useDispatch } from 'react-redux';
import { selectWorkListFilters } from 'state/slices/admin-huddle/worklists/worklist.selectors';
import { toggleWorkListFilterPropItem } from 'state/slices/admin-huddle/worklists/worklist.slice';
import { selectTreatingProviderAndHygienistOptions } from 'state/slices/tenant/providers.slice';

export default function ProvidersFilter() {
    const dispatch = useDispatch();
    const providerOptions = useSelector(selectTreatingProviderAndHygienistOptions);

    const { providers } = useSelector(selectWorkListFilters<any>);

    const handleProviderMultiSelect = (providerId: string) => {
        dispatch(toggleWorkListFilterPropItem({ path: 'providers', value: providerId }));
    };

    return (
        <Stack.Item grow>
            <Field.SearchCombo
                placeholder="Select"
                multiSelect={true}
                label="Providers"
                selectedKey={providers ?? []}
                options={providerOptions}
                onChange={(e, option) => {
                    handleProviderMultiSelect(option?.key as string);
                }}
                onRenderOption={(props) => (props?.itemType ? <span>{props.text}</span> : <TText>{props?.text}</TText>)}
            ></Field.SearchCombo>
        </Stack.Item>
    );
}
