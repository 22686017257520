export type SlidingFeeEncounterDetailsQuery = {
    startDate: string;
    endDate: string;
    treatingProviderIds?: string[];
    locationOfCareIds?: string[];
    slidingFeePlan?: string;
    groupCol1?: SlidingFeeEncounterDetailsGroupCol;
    groupCol2?: SlidingFeeEncounterDetailsGroupCol;
};

export enum SlidingFeeEncounterDetailsGroupCol {
    LocationOfCare = 'Location Of Care',
    TreatingProvider = 'Treating Provider',
    SlidingFeeProgramPlan = 'Sliding Fee Program Plan',
    SlidingFeePlan = 'Sliding Fee Plan',
}
