import { Stack, useTheme, DefaultButton, mergeStyles } from '@fluentui/react';

import './SurfaceControls.scss';
import { ActionButton } from 'components';
import { CSSProperties, useEffect, useRef } from 'react';
import { ToothArea } from 'api/models/tooth-area';
import { toothSpriteReferences } from 'pages/Charting/components/ToothCanvas/spriteList';
import { every } from 'lodash';
import { ControlPosition, getControlsByToothPosition, SurfaceControlButtons } from './SurfaceControlsAreas';

type Props = {
    surfaces: (keyof typeof ToothArea)[];
    setSurfaces: (areas: (keyof typeof ToothArea)[]) => void;
    toothId?: number | string;
};

// Teeth 6-11 Get charted as F instead of B
// 22-27
// Those same teeth if O is selected chart as Incisal

function getControls(toothPosition?: number | null): SurfaceControlButtons | undefined {
    if (toothPosition) return getControlsByToothPosition(toothPosition);
}

const allSurfaces: (keyof typeof ToothArea)[] = ['Mesial', 'Facial', 'Occlusal', 'Distal', 'Lingual'];

function SurfaceControls({ surfaces: selectedAreas, setSurfaces: setAreas, toothId }: Props): JSX.Element | null {
    const theme = useTheme();

    const bottomRef = useRef<SVGPathElement | null>(null);
    const toothPosition = toothId ? toothSpriteReferences.find((tooth) => tooth.id === toothId)?.position : null;
    const controls = getControls(toothPosition);

    //Since Incisal and Buccal get flip-flopped based on isPosterior teeth. We need a way to translate those areas initially for our
    //list of selected areas:
    useEffect(() => {
        let newAreas: (keyof typeof ToothArea)[] = [...selectedAreas];
        if (newAreas.indexOf('Incisal') > -1) newAreas = [...newAreas.filter((area) => area !== 'Incisal'), 'Occlusal'];
        if (newAreas.indexOf('Buccal') > -1) newAreas = [...newAreas.filter((area) => area !== 'Buccal'), 'Facial'];
        if (newAreas.indexOf('BuccalVestibular') > -1)
            newAreas = [...newAreas.filter((area) => area !== 'BuccalVestibular'), 'FacialVestibular'];
        setAreas(newAreas);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!controls) return null;

    const toggleArea = (controlPosition: ControlPosition) => {
        const { area, relatedArea } = controls[controlPosition];
        let areas: (keyof typeof ToothArea)[] = [...selectedAreas];

        const indexOfArea = selectedAreas.indexOf(area);
        if (indexOfArea > -1) {
            areas = [...selectedAreas.slice(0, indexOfArea), ...selectedAreas.slice(indexOfArea + 1)];
        } else {
            if (relatedArea) {
                const indexOfRelatedArea = selectedAreas.indexOf(relatedArea);
                if (indexOfRelatedArea > -1) {
                    areas = [...selectedAreas.slice(0, indexOfRelatedArea), ...selectedAreas.slice(indexOfRelatedArea + 1)];
                }
            }
            areas.push(area);
        }

        return areas;
    };

    //Checks to see if all the allSurface strings are currently selected.
    const allSurfacesSelected = every(allSurfaces, (area) => selectedAreas.findIndex((a) => a === area) > -1);

    const _selectAllSurfaces = () => {
        if (!allSurfacesSelected) {
            //Select the allSurfaces. Keep vestibular if it selected:
            setAreas([...allSurfaces]);
        }
    };

    const deselectDisabled = !selectedAreas.length;

    const _deselectAllSurfaces = () => {
        setAreas([]);
    };

    function _onControlClicked(controlPosition: ControlPosition) {
        setAreas([...toggleArea(controlPosition)]);
    }

    function _surfaceSelected(area: keyof typeof ToothArea) {
        if (selectedAreas && selectedAreas.length) {
            return selectedAreas.indexOf(area) > -1;
        } else {
            return false;
        }
    }

    const _labelActiveStyle = (area: keyof typeof ToothArea) => {
        return _surfaceSelected(area) ? { color: theme?.palette.white } : { color: theme.semanticColors.bodyText };
    };

    const surfaceLabels = Object.keys(controls)
        .filter((key) => key !== 'vestibularTop' && key !== 'vestibularBottom')
        .map((key) => {
            const centpointOfSurfaceControl = 60;
            const centerpointOfVerticalControl = 13;
            const charWidthSize = 6;
            const charHeightSize = 8;
            const labelSize = controls[key as ControlPosition].label.length * charWidthSize;

            const surfaceLabelClass = mergeStyles({
                bottom: key === 'bottom' ? 13 : undefined,
                top: key !== 'bottom' ? (key === 'top' ? charHeightSize : centpointOfSurfaceControl - charHeightSize) : undefined,
                right: key === 'right' ? centerpointOfVerticalControl : undefined,
                left:
                    key === 'top' || key === 'bottom' || key === 'center'
                        ? centpointOfSurfaceControl - labelSize / 2
                        : key === 'left'
                        ? centerpointOfVerticalControl
                        : undefined,
            });

            return (
                <span
                    key={key}
                    className={`surface-label ${surfaceLabelClass}`}
                    style={_labelActiveStyle(controls[key as ControlPosition].area)}
                >
                    {controls[key as ControlPosition].label}
                </span>
            );
        });

    const controlStyles: CSSProperties = {
        stroke: theme.palette.black,
        strokeWidth: '2px',
        strokeLinecap: 'round',
    };

    const getStyles = (toothArea: keyof typeof ToothArea): CSSProperties => {
        return { ...controlStyles, fill: _surfaceSelected(toothArea) ? theme.palette.themePrimary : theme.palette.white };
    };

    return (
        <Stack grow={2} horizontalAlign="center" horizontal tokens={{ childrenGap: 10 }}>
            <Stack.Item>
                <div className="controls-wrapper">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 436.97 436.97">
                        <path
                            id="bottom"
                            onClick={() => _onControlClicked('bottom')}
                            style={{ ...getStyles(controls['bottom'].area) }}
                            className={`surface-control surface-control_bottom  ${_surfaceSelected('Facial')}`}
                            d="M146.45,298.25,77.31,367.39a10,10,0,0,0,.72,14.8c79.78,66.38,196.13,66.38,275.91,0a10,10,0,0,0,.72-14.8l-69.14-69.14a10,10,0,0,0-12.77-1.15,99,99,0,0,1-113.53,0A10,10,0,0,0,146.45,298.25Z"
                            ref={bottomRef}
                        />
                        <path
                            id="left"
                            onClick={() => _onControlClicked('left')}
                            style={{ ...getStyles(controls['left'].area) }}
                            className={`surface-control surface-control_left  ${_surfaceSelected('Distal')}`}
                            d="M133.73,146.45,64.58,77.31A10,10,0,0,0,49.79,78c-66.38,79.78-66.39,196.13,0,275.92a10,10,0,0,0,14.79.71l69.15-69.14a10,10,0,0,0,1.14-12.77,99,99,0,0,1,0-113.53A10,10,0,0,0,133.73,146.45Z"
                        />
                        <path
                            id="top"
                            onClick={() => _onControlClicked('top')}
                            style={{ ...getStyles(controls['top'].area) }}
                            className={`surface-control surface-control_top  ${_surfaceSelected('Lingual')}`}
                            d="M285.52,133.73l69.14-69.15A10,10,0,0,0,354,49.79C274.16-16.6,157.81-16.59,78,49.79a10,10,0,0,0-.72,14.79l69.14,69.15a10,10,0,0,0,12.77,1.14,99,99,0,0,1,113.53,0A10,10,0,0,0,285.52,133.73Z"
                        />
                        <path
                            id="right"
                            onClick={() => _onControlClicked('right')}
                            style={{ ...getStyles(controls['right'].area) }}
                            className={`surface-control surface-control_right  ${_surfaceSelected('Mesial')}`}
                            d="M297.1,159.22a99,99,0,0,1,0,113.53,10,10,0,0,0,1.15,12.77l69.14,69.14a10,10,0,0,0,14.8-.72c66.38-79.78,66.38-196.13,0-275.91a10,10,0,0,0-14.8-.72l-69.14,69.14A10,10,0,0,0,297.1,159.22Z"
                        />
                        <circle
                            id="center"
                            onClick={() => _onControlClicked('center')}
                            style={{ ...getStyles(controls['center'].area) }}
                            className={`surface-control surface-control_center  ${_surfaceSelected('Occlusal')}`}
                            cx="215.99"
                            cy="215.99"
                            r="81"
                        />
                    </svg>
                    {surfaceLabels}
                </div>
            </Stack.Item>
            <Stack tokens={{ childrenGap: 10 }} verticalAlign="center">
                <ActionButton
                    type={_surfaceSelected(controls.vestibularTop.area) ? 'PrimaryButton' : 'DefaultButton'}
                    onClick={() => _onControlClicked('vestibularTop')}
                >
                    {controls.vestibularTop?.label}
                </ActionButton>
                <ActionButton
                    type={_surfaceSelected(controls.vestibularBottom.area) ? 'PrimaryButton' : 'DefaultButton'}
                    onClick={() => _onControlClicked('vestibularBottom')}
                >
                    {controls.vestibularBottom?.label}
                </ActionButton>
                <ActionButton type={allSurfacesSelected ? 'PrimaryButton' : 'DefaultButton'} onClick={_selectAllSurfaces}>
                    Select All
                </ActionButton>
                <DefaultButton disabled={deselectDisabled} onClick={_deselectAllSurfaces}>
                    Deselect All
                </DefaultButton>
            </Stack>
        </Stack>
    );
}

export default SurfaceControls;
