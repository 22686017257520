import {
    Checkbox,
    IButtonStyles,
    IconButton,
    IContextualMenuItem,
    MessageBar,
    MessageBarType,
    Stack,
    Text,
    TooltipHost,
} from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import { IPatientPhoneNumber } from 'api/models/patient.model';
import { ContentCard, SubSection, TText } from 'components';
import ConfirmationModal from './PatientPhoneConfirmationModal';
import { useDispatch, useSelector } from 'react-redux';
import { setPatientPhoneNumbers } from 'state/slices/edit-patient/edit-patient.slice';
import { getValidationError } from 'hooks/useValidation';
import { useState } from 'react';
import { selectEditPatient, selectEditPatientValidationErrors } from 'state/slices/edit-patient/edit-patient.selectors';
import formatPhoneNumber from 'utils/formatPhoneNumber';
import { BasicDetailsFields } from '../BasicDetails';
import PhoneNumberModal from './PhoneNumberModal';
import { selectPatientAppointmentBasicInfoByTaskTypeLookup } from 'state/task-management/taskManagement.slice';
import TaskFieldLabel from 'pages/Scheduling/components/Checkin/TaskFieldLabel';
import { TaskType } from 'state/task-management/taskManagement.actions';
import { updatePatientAppointmentProp } from 'state/slices/scheduling/scheduling.slice';

import { selectSelectedAppointmentData } from 'state/slices/scheduling/scheduling.selectors';

import IPatientAppointment from 'api/models/Scheduling/patientAppointment.model';
import { format } from 'date-fns';

interface IPatientPhoneNumbersProps {
    isCheckoutCheckin?: boolean;
}

function PatientPhoneNumbers({ isCheckoutCheckin }: IPatientPhoneNumbersProps): JSX.Element | null {
    const [editingPhoneNumber, setEditingPhoneNumber] = useState<IPatientPhoneNumber | undefined>(undefined);

    const [showPhoneNumberModal, setShowPhoneNumberModal] = useState<boolean>(false);
    const dispatch = useDispatch();
    const patient = useSelector(selectEditPatient);

    const [isOpen, { setTrue: setIsOpenTrue, setFalse: setIsOpenFalse }] = useBoolean(false);
    const validationErrors = useSelector(selectEditPatientValidationErrors);
    const _currentAppointment = useSelector(selectSelectedAppointmentData);
    const _lookupPatientAppointmentBasicDetailTaskByType = useSelector(selectPatientAppointmentBasicInfoByTaskTypeLookup);

    if (isCheckoutCheckin && !_lookupPatientAppointmentBasicDetailTaskByType[TaskType.PrimaryPhoneNumberVerification])
        return null;

    const menuButtonStyles: IButtonStyles = {
        flexContainer: {
            selectors: {
                '.ms-Button-menuIcon': {
                    display: 'none',
                },
            },
        },
    };

    const primaryPhone = patient?.phoneNumbers?.find((res) => res.isPrimary);
    const newPhoneArray = patient?.phoneNumbers?.filter((res) => !res.isPrimary);
    const homeNumber = patient?.phoneNumbers?.find((res) => res.type === 'Home');

    if (primaryPhone) {
        newPhoneArray?.unshift(primaryPhone);
    }

    const verifyPatientPhone = (path: keyof IPatientAppointment) => {
        if (isCheckoutCheckin)
            dispatch(
                updatePatientAppointmentProp({
                    path,
                    value: new Date().toISOString(),
                }),
            );
    };

    const editPhone = (item: IPatientPhoneNumber) => {
        setShowPhoneNumberModal(true);
        setEditingPhoneNumber(item);
        if (item.isPrimary) verifyPatientPhone('lastVerifiedPrimaryPhoneNumber');
    };

    const phoneCards = newPhoneArray
        ?.filter((res) => res?.number?.length)
        .map((item, index) => {
            const permissions = [];
            if (item.isPrimary) permissions.push('Primary');
            if (item.hasPermissionToText) permissions.push('Can text');
            if (item.hasPermissionToCall) permissions.push('Can call');
            if (item.hasPermissionToLeaveVoiceMail) permissions.push('Can leave message');

            const cardMenuItems: IContextualMenuItem[] =
                patient?.phoneNumbers?.filter((res) => res?.number?.length).length !== 1
                    ? [
                          {
                              key: 'editItem',
                              text: 'Edit',
                              onClick: () => editPhone(item),
                          },
                          {
                              key: 'deactivateItem',
                              text: !item.isDeleted ? 'Deactivate' : 'Activate',
                              onClick: () => {
                                  setIsOpenTrue();
                                  setEditingPhoneNumber(item);
                                  if (item.isPrimary) verifyPatientPhone('lastVerifiedPrimaryPhoneNumber');
                              },
                          },
                      ]
                    : [
                          {
                              key: 'editItem',
                              text: 'Edit',
                              onClick: () => {
                                  editPhone(item);
                              },
                          },
                      ];

            return (
                <ContentCard
                    menuButtonProps={{
                        menuProps: {
                            items: cardMenuItems,
                        },
                        styles: menuButtonStyles,
                    }}
                    key={index}
                    isActive={!item.isDeleted}
                    title={''}
                >
                    <Stack grow verticalAlign="center">
                        <Text variant="mediumPlus" block>
                            {item.type}: {formatPhoneNumber(item.number)}
                        </Text>
                        <Text variant="mediumPlus" block>
                            {item.type === 'Work' && item?.extension?.length ? `EXT: ${item.extension}` : null}
                        </Text>
                        <TText variant="small">{permissions.join(', ')}</TText>
                        {item.isPrimary &&
                            isCheckoutCheckin &&
                            _lookupPatientAppointmentBasicDetailTaskByType[TaskType.PrimaryPhoneNumberVerification] && (
                                <Checkbox
                                    label={`Verify primary phone number${
                                        _currentAppointment?.lastVerifiedPrimaryPhoneNumber
                                            ? ` (${format(
                                                  new Date(_currentAppointment.lastVerifiedPrimaryPhoneNumber),
                                                  'MM/dd/yyyy',
                                              )} )`
                                            : ''
                                    }`}
                                    onRenderLabel={(data) => (
                                        <TaskFieldLabel
                                            label={data?.label}
                                            lookup={_lookupPatientAppointmentBasicDetailTaskByType}
                                            taskType={TaskType.PrimaryPhoneNumberVerification}
                                        />
                                    )}
                                    checked={!!_currentAppointment?.lastVerifiedPrimaryPhoneNumber}
                                    onChange={() => {
                                        dispatch(
                                            updatePatientAppointmentProp({
                                                path: 'lastVerifiedPrimaryPhoneNumber',
                                                value: !_currentAppointment?.lastVerifiedPrimaryPhoneNumber
                                                    ? new Date().toISOString()
                                                    : undefined,
                                            }),
                                        );
                                    }}
                                />
                            )}
                    </Stack>
                </ContentCard>
            );
        });

    const phoneNumbersHasError = getValidationError(validationErrors, BasicDetailsFields.PhoneNumbers)
        ? 'At least one phone number is required.'
        : '';

    return (
        <SubSection
            title="Phone Numbers"
            isRequired
            headingCenterContent={
                <Stack horizontal>
                    <TooltipHost content="Add Phone Number">
                        <Stack horizontal>
                            <IconButton
                                iconProps={{ iconName: 'AddPhone' }}
                                onClick={() => {
                                    setShowPhoneNumberModal(true);
                                    setEditingPhoneNumber(undefined);
                                }}
                            />
                            {isCheckoutCheckin && (
                                <TaskFieldLabel
                                    lookup={_lookupPatientAppointmentBasicDetailTaskByType}
                                    taskType={TaskType.PrimaryPhoneNumberVerification}
                                />
                            )}
                        </Stack>
                    </TooltipHost>
                </Stack>
            }
        >
            {phoneNumbersHasError && <MessageBar messageBarType={MessageBarType.error}>{phoneNumbersHasError}</MessageBar>}
            <Stack tokens={{ childrenGap: 10 }}>
                {isCheckoutCheckin &&
                _lookupPatientAppointmentBasicDetailTaskByType[TaskType.PrimaryPhoneNumberVerification] &&
                !homeNumber ? (
                    <MessageBar messageBarType={MessageBarType.error}>
                        Home number needs to be added prior to selecting a Primary Phone.
                    </MessageBar>
                ) : null}
                <Stack.Item>
                    <Stack horizontal wrap tokens={{ childrenGap: 10 }}>
                        {phoneCards}
                    </Stack>
                </Stack.Item>
            </Stack>
            <PhoneNumberModal
                showPhoneNumberModal={showPhoneNumberModal}
                setShowPhoneNumberModal={setShowPhoneNumberModal}
                editingPhoneNumber={editingPhoneNumber}
                setEditingPhoneNumber={setEditingPhoneNumber}
            />
            <ConfirmationModal
                title="Deactivate Phone Number"
                confirmationMessage="This action cannot be undone. Are you sure you want to deactivate this number?"
                isOpen={isOpen}
                onDismiss={() => {
                    setIsOpenFalse(), setEditingPhoneNumber(undefined);
                }}
                onContinue={(res) => {
                    if (res && patient?.phoneNumbers) {
                        const toEdit = [...res];
                        const index = toEdit.findIndex((res) => res.type === editingPhoneNumber?.type);
                        toEdit.splice(index, 1);
                        dispatch(setPatientPhoneNumbers(toEdit));
                    }
                    setIsOpenFalse();
                    setEditingPhoneNumber(undefined);
                }}
                dropdownShown={editingPhoneNumber?.isPrimary === true}
                editingPhoneNumber={editingPhoneNumber}
            />
        </SubSection>
    );
}

export default PatientPhoneNumbers;
