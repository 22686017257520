import { usePatientId } from 'hooks';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createAddTreatmentPlanCreditInformation } from 'state/slices/ledger/treatment-plan-credit-and-uac/treatment-plan-credit-and-uac.actions';
import { selectSelectedBatch } from 'state/slices/tenant/batches.slice';
import TreatmentPlanCreditAndUACSection from './TreatmentPlanCreditAndUACSection';

export default function AddUnappliedCredit() {
    const dispatch = useDispatch();

    const patientId = usePatientId();

    const selectedBatch = useSelector(selectSelectedBatch);

    useEffect(() => {
        if (patientId) dispatch(createAddTreatmentPlanCreditInformation(patientId, selectedBatch));
    }, []);

    return <TreatmentPlanCreditAndUACSection hasNotes heading="Add Unapplied Credit" />;
}
