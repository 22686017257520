import { Stack, TextField } from '@fluentui/react';
import { VitalNameType } from 'api/models/patient-vital.model';
import { SubSection, Field } from 'components';
import useSelector from 'hooks/useSelector';
import { useDispatch } from 'react-redux';
import { updateReading } from 'state/slices/patient/patient.slice';
import { NonBPNameTypes } from 'state/slices/patient/vitals/vitals.reducers';

const PulseVitalsProperties = () => {
    const dispatch = useDispatch();
    const newVitals = useSelector((state) => state.patient.vitals.vitalPanelData);

    const pulseRate = newVitals[VitalNameType.PulseRate];
    const pulseType = newVitals[VitalNameType.PulseType];

    const pulseOptions = [
        {
            key: 'regular',
            text: 'regular',
        },
        {
            key: 'irregular',
            text: 'irregular',
        },
        {
            key: 'regularly irregular',
            text: 'regularly irregular',
        },
        {
            key: 'irregularly irregular',
            text: 'irregularly irregular',
        },
        {
            key: 'None',
            text: 'None',
        },
    ];

    const handleOnChange = (vitalType: NonBPNameTypes, value?: string): void => {
        dispatch(updateReading({ vitalType, value: value ?? '' }));
    };

    return (
        <Stack tokens={{ childrenGap: 5 }}>
            <SubSection title="Pulse">
                <Stack tokens={{ childrenGap: 10 }}>
                    <Stack tokens={{ childrenGap: 10 }} horizontal verticalAlign="center" grow>
                        <Stack.Item>
                            <TextField
                                label=""
                                autoComplete="off"
                                suffix="bpm"
                                value={pulseRate}
                                maxLength={3}
                                onChange={(ev, value) => handleOnChange(VitalNameType.PulseRate, value)}
                            />
                        </Stack.Item>
                        <Stack.Item grow>
                            <Field.Dropdown
                                options={pulseOptions}
                                label=""
                                selectedKey={pulseType}
                                onChange={(event, option, index) =>
                                    option && handleOnChange(VitalNameType.PulseType, option.key as string)
                                }
                            />
                        </Stack.Item>
                    </Stack>
                </Stack>
            </SubSection>
        </Stack>
    );
};

export default PulseVitalsProperties;
