import { Stack, Text, TextField } from '@fluentui/react';
import { SelectionMode } from '@fluentui/utilities';
import { useBoolean } from '@uifabric/react-hooks';
import { SubSection, SortableDetailsList } from 'components';
import { useSelector } from 'hooks';
import { RouteParams } from 'interfaces/route-params';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { onSetFinancialNotes } from 'state/slices/charting/treatmentPlans/treatmentPlans.actions';
import {
    getTreatmentPlanFeeTotals,
    selectCurrentTreatmentPlan,
    selectCurrentTreatmentPlanSignedOrSigning,
    selectedFinancialNotes,
} from 'state/slices/charting/treatmentPlans/treatmentPlans.selectors';
import { selectPatientEncounter } from 'state/slices/encounter/encounter.selectors';
import { selectSelectedPatient } from 'state/slices/patient/patient.selectors';
import currencyFormatter from 'utils/currencyFormatter';
import { isDateBetween } from 'utils/isDateBetween';

function TreatmentPlanPhaseFinancials(): JSX.Element {
    const patient = useSelector(selectSelectedPatient);
    const patientEncounter = useSelector(selectPatientEncounter);
    const patientInsurance = patient?.insurances?.find((insurance) => {
        const patientInsuranceIsActive = isDateBetween({
            dateToCheck: patientEncounter?.createdOn,
            start: insurance?.effectiveDate,
            end: insurance?.expirationDate,
        });
        return patientInsuranceIsActive && !insurance?.isDeleted;
    });
    return (
        <Stack horizontal tokens={{ childrenGap: 20 }} wrap grow>
            <Stack.Item grow={6}>
                <FinancialOptions />
            </Stack.Item>
            <Stack.Item grow={2}>
                <EstimatedTotals />
            </Stack.Item>

            <Stack.Item grow={1}>{patientInsurance && <IndividualInsuranceBenefits />}</Stack.Item>
        </Stack>
    );
}

export default TreatmentPlanPhaseFinancials;

function FinancialOptions(): JSX.Element {
    const { patientId, tenantId } = useParams<RouteParams>();
    const dispatch = useDispatch();
    const notes = useSelector(selectedFinancialNotes);
    const isSigned = useSelector(selectCurrentTreatmentPlanSignedOrSigning);
    const currentPlan = useSelector(selectCurrentTreatmentPlan);
    const encounterId = currentPlan?.encounterId;
    // Not sure if this is best strategy for removing the placeholder text here. Maybe needs to be implemented in a global component?
    const [isPatientNotesFocused, { setTrue: patientNotesFocused, setFalse: patientNotesUnfocused }] = useBoolean(false);

    const isDisabled = isSigned || currentPlan?.isDeleted;

    const _handleOnChange = (e?: React.FormEvent, value?: string) => {
        if (encounterId) dispatch(onSetFinancialNotes({ tenantId, patientId, encounterId, value: value ?? '' }));
    };

    return (
        <SubSection headerStyle={{ paddingLeft: 0, minWidth: 300, width: 540 }} title="Notes for Patient">
            {isDisabled ? (
                <Text>{notes}</Text>
            ) : (
                <TextField
                    placeholder={!isPatientNotesFocused ? 'Enter notes for the patient, including financial options.' : ''}
                    multiline
                    onFocus={patientNotesFocused}
                    onBlur={patientNotesUnfocused}
                    rows={5}
                    onChange={_handleOnChange}
                    readOnly={isDisabled}
                    autoComplete="off"
                    value={notes}
                />
            )}
        </SubSection>
    );
}

function EstimatedTotals(): JSX.Element {
    const { feeTotal, insuranceFeeTotal, patientFeeTotal } = useSelector(getTreatmentPlanFeeTotals);

    return (
        <SubSection headerStyle={{ paddingLeft: 0 }} title="Estimated Totals">
            <SortableDetailsList
                compact
                selectionMode={SelectionMode.none}
                columns={[
                    {
                        key: 'fee',
                        name: 'Fee',
                        minWidth: 100,
                        onRender: () => {
                            return <Text variant="smallPlus">{currencyFormatter.format(feeTotal)}</Text>;
                        },
                    },
                    {
                        key: 'insEst',
                        name: 'Ins. Est.',
                        minWidth: 100,
                        onRender: () => {
                            return <Text variant="smallPlus">{currencyFormatter.format(insuranceFeeTotal)}</Text>;
                        },
                    },
                    {
                        key: 'patEst',
                        name: 'Patient Est.',
                        minWidth: 100,
                        onRender: () => {
                            return <Text variant="smallPlus">{currencyFormatter.format(patientFeeTotal)}</Text>;
                        },
                    },
                ]}
                items={[
                    {
                        // fee: proceduresInPhasesFees,
                    },
                ]}
            />
        </SubSection>
    );
}
/**
 *
 *
 * @return {*}  {JSX.Element}
 */
function IndividualInsuranceBenefits(): JSX.Element {
    const patient = useSelector(selectSelectedPatient);
    const patientEncounter = useSelector(selectPatientEncounter);
    const patientInsurance = patient?.insurances?.find((insurance) => {
        const patientInsuranceIsActive = isDateBetween({
            dateToCheck: patientEncounter?.createdOn,
            start: insurance?.effectiveDate,
            end: insurance?.expirationDate,
        });
        return patientInsuranceIsActive && !insurance?.isDeleted;
    });
    return (
        <SubSection headerStyle={{ paddingLeft: 0 }} title="Individual Ins. Benefits">
            <SortableDetailsList
                compact
                selectionMode={SelectionMode.none}
                sortOnMount={true}
                columns={[
                    { key: 'insInfo', name: 'Ins. Info', minWidth: 100, fieldName: 'label' },
                    {
                        key: 'amount',
                        name: 'Amount',
                        minWidth: 100,
                        fieldName: 'amount',
                        onRender: (item?: { label: string; amount: string | number }) => {
                            if (item)
                                return (
                                    <Text variant="smallPlus">
                                        {!isNaN(+item.amount) && typeof item.amount === 'number'
                                            ? currencyFormatter.format(item.amount)
                                            : ''}
                                    </Text>
                                );
                        },
                    },
                ]}
                items={[
                    {
                        label: 'Annual Max:',
                        amount: patientInsurance?.eligibility?.annualMax ?? '',
                    },
                    {
                        label: 'Individual Deductible:',
                        amount: patientInsurance?.eligibility?.deductible ?? '',
                    },
                    {
                        label: 'Deductible Amount Remaining:',
                        amount: patientInsurance?.eligibility?.deductibleAmountRemaining ?? '',
                    },
                ]}
            />
        </SubSection>
    );
}
