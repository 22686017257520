import { Stack, TooltipHost, IconButton } from '@fluentui/react';
import IWorkListEncounterView from 'api/models/worklist-encounter-view';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { RouteParams } from 'interfaces/route-params';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { recalculateEncounter } from 'state/slices/admin-huddle/worklists/encounter-worklists/encounter-worklists.actions';
import { selectWorkListSaving } from 'state/slices/admin-huddle/worklists/worklist.selectors';

const recalculateEncounterButton = {
    key: 'recalculateEncounterButton',
    name: '',
    minWidth: 28,
    maxWidth: 28,
    onRender: OnRenderRecalculateColumn,
};

export function OnRenderRecalculateColumn(item?: IWorkListEncounterView) {
    const { tenantId } = useParams<RouteParams>();
    const saving = useSelector(selectWorkListSaving) === LoadingStatus.Pending;
    const dispatch = useDispatch();

    if (!item) return null;
    return (
        <Stack grow horizontalAlign="center">
            <TooltipHost content={item.isRecalculation ? 'Rerun calculation' : 'Recalculate encounter'}>
                <IconButton
                    iconProps={{ iconName: item.isRecalculation ? 'Rerun' : 'Calculator' }}
                    styles={{ root: { height: 20, width: 20 } }}
                    onClick={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        dispatch(recalculateEncounter({ tenantId, encounter: item }));
                    }}
                    disabled={saving}
                />
            </TooltipHost>
        </Stack>
    );
}

export default recalculateEncounterButton;
