import { INavLink, mergeStyles, Nav, Stack, useTheme } from '@fluentui/react';
import React from 'react';
import { DragDropContext, Droppable, Draggable, DropResult, ResponderProvided } from 'react-beautiful-dnd';

type Props = {
    links: INavLink[];
    onLinkClick?: (ev?: React.MouseEvent<HTMLElement | MouseEvent>, item?: INavLink) => void;
    onDragEnd?: (result: DropResult, provided: ResponderProvided) => void;
    isNavItemDraggable?: boolean;
    selectedKey?: string;
};

export default function SettingsVerticalNav(props: Props): JSX.Element {
    const { palette } = useTheme();

    const getItemStyle = (isDragging: boolean, draggableStyle?: React.CSSProperties): React.CSSProperties => ({
        ...(draggableStyle ?? {}),
        userSelect: 'none',
        position: 'static',
    });

    const className = mergeStyles({
        color: `${palette.black}`,
        transition: 'background-color .1s',
        borderRadius: 2,
        paddingTop: 10,
        paddingBottom: 10,
        display: 'block',
        ':hover': {
            background: `transparent`,
            color: `${palette.themeSecondary}`,
        },
    });

    const getActiveStyles = (key?: string) => {
        if (key === props.selectedKey)
            return {
                borderLeft: `2px solid ${palette.themeSecondary}`,
                fontWeight: 'bold',
                background: `${palette.neutralLight}`,
            } as React.CSSProperties;

        return { borderLeft: `3px solid transparent` } as React.CSSProperties;
    };

    function _onDragEnd(result: DropResult, provided: ResponderProvided) {
        if (props.onDragEnd) props.onDragEnd(result, provided);
    }

    function _onLinkClick(ev?: React.MouseEvent<HTMLElement | MouseEvent>, item?: INavLink) {
        ev?.preventDefault();
        if (props.onLinkClick) props.onLinkClick(ev, item);
    }

    if (!props.isNavItemDraggable)
        return <Nav selectedKey={props.selectedKey} onLinkClick={_onLinkClick} groups={[{ links: props.links }]} />;
    return (
        <DragDropContext onDragEnd={_onDragEnd}>
            <Droppable droppableId="categoryDroppable">
                {(provided, snapshot) => (
                    <div {...provided.droppableProps} style={{ width: '100%' }} ref={provided.innerRef}>
                        {props.links.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                {(provided, snapshot) => (
                                    <div
                                        className={className}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        onClick={(ev) => _onLinkClick(ev, item)}
                                        style={{
                                            ...getItemStyle(snapshot.isDragging, provided.draggableProps.style),
                                            ...getActiveStyles(item.id),
                                        }}
                                    >
                                        <span style={{ paddingLeft: 20, paddingRight: 20 }}>{item.name}</span>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}
