import { createAsyncThunk } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import { IPatientAudit } from 'api/models/patient.model';

export const getPatientAuditLogging = createAsyncThunk<
    IPatientAudit,
    {
        tenantId: string;
        patientId: string;
    },
    { rejectValue: string }
>('getPatientAuditLogging', async ({ tenantId, patientId }, { rejectWithValue }) => {
    const response = await dentalApi.getPatientAuditLogging(tenantId, patientId);
    return response.data;
});
export const addPatientAuditLogging = createAsyncThunk<
    IPatientAudit,
    {
        tenantId: string;
        patientId: string;
    },
    { rejectValue: string }
>('addPatientAuditLogging', async ({ tenantId, patientId }, { rejectWithValue }) => {
    const response = await dentalApi.addPatientAuditLogging(tenantId, patientId);
    return response.data;
});
export const updatePatientAuditLogging = createAsyncThunk<
    IPatientAudit,
    {
        tenantId: string;
        patientId: string;
    },
    { rejectValue: string }
>('updatePatientAuditLogging', async ({ tenantId, patientId }, { rejectWithValue }) => {
    const response = await dentalApi.updatePatientAuditLogging(tenantId, patientId);
    return response.data;
});
