import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import { IBillingProcedure } from 'api/models/billing-procedure.model';
import { IPatientEncounter } from 'api/models/encounter.model';
import IPatient from 'api/models/patient.model';
import IAppointmentAllocations from 'api/models/Scheduling/allocation.model';
import IBlockAppointment from 'api/models/Scheduling/blockAppointment.model';
import ILocationOfCare, { LocationOfCareDepartmentType } from 'api/models/Scheduling/locationsOfCare.model';
import IPatientAppointment, { IPatientAppointmentQuery } from 'api/models/Scheduling/patientAppointment.model';
import { IChartTreatmentPlan, IChartTreatmentPlanPhaseProcedure } from 'api/models/treatment-plan.model';
import IUserTask from 'api/models/user-task.model';
import schedulingApi from 'api/scheduling.api';
import { format } from 'date-fns';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { SyncStatus } from 'interfaces/syncing-statuses';
import { filter, sortBy } from 'lodash';
import { TrackerStatus } from 'pages/Scheduling/components/TrackerStatusDropdown';
import { AppThunk, RootState } from 'state/store';
import { TaskTargets } from 'state/task-management/taskManagement.actions';
import appLocalStorage from 'utils/appLocalStorage';
import { updatePatient } from '../edit-patient/edit-patient.actions';
import { cleanupEditPatient } from '../edit-patient/edit-patient.slice';
import {
    appointmentsToRemoveReducers,
    appointmentToRemoveExtraReducers,
} from './appointment-to-cancel/appointment-to-cancel.reducers';
import { finishCheckin, returnToScheduleFromCheckin } from './check-in/check-in.actions';
import checkInReducers from './check-in/check-in.reducers';
import scheduleAppointmentReducers from './schedule-appointment/schedule-appointment.reducers';
import { finishCheckout, hasFinishCheckoutSucceeded } from './scheduling.actions';
import schedulingReducers from './scheduling.reducers';
import initialState from './scheduling.state';
import ErrorTypes from 'state/errorTypes';

export type ScheduleRequestQuery = {
    locationOfCareId: string;
    date: string;
    includeAlerts: boolean;
};
// Appointments
export const getLayoutsAndAllocations = createAsyncThunk(
    'getLayoutsAndAllocations',
    async ({ tenantId, locationOfCareId, date }: { tenantId: string; locationOfCareId: string; date: string }) => {
        const { data } = await schedulingApi.getSchedule(tenantId, { locationOfCareId, date, includeAlerts: true });
        return data;
    },
);

export const getAppointmentPatient = createAsyncThunk(
    'getAppointmentPatient',
    async ({ tenantId, patientId }: { tenantId: string; patientId: string }) => {
        const { data: patient } = await dentalApi.getPatient(tenantId, patientId);
        return patient;
    },
);

export const getPatientAppointmentEncounter = createAsyncThunk<
    IPatientEncounter,
    { tenantId: string; patientId: string; encounterId: string },
    { state: RootState }
>('getPatientAppointmentEncounter', async ({ tenantId, patientId, encounterId }) => {
    const { data: encounter } = await dentalApi.getPatientEncounterById(tenantId, patientId, encounterId);
    return encounter;
});

/**
 * Notes about loading procedures.
 * The appt info panel doesn't seem to load treatment plans. It should...
 */
export const getPatientAndAppointment = createAsyncThunk<
    {
        appointment: IPatientAppointment;
        patient: IPatient;
    },
    { tenantId: string; appointmentId: string },
    { state: RootState }
>('getPatientAndAppointment', async ({ tenantId, appointmentId }, { dispatch, getState }) => {
    const { data: appointment } = await schedulingApi.getPatientAppointment(tenantId, appointmentId);
    await dispatch(getChartTreatmentPlans({ tenantId, patientId: appointment.patientId }));
    const { data: patient } = await dentalApi.getPatient(tenantId, appointment.patientId);

    const result = {
        appointment,
        patient,
    };
    return result;
});
export const getProviderAppointments = createAsyncThunk<
    IAppointmentAllocations,
    { tenantId: string; providerId: string },
    { state: RootState }
>('getProviderAppointments', async ({ tenantId, providerId }) => {
    const query: IPatientAppointmentQuery = {
        currentAndFutureDatesOnly: true,
        providerId,
    };

    const req = await schedulingApi.getPatientAppointmentsByParameters(tenantId, query);
    return req.data;
});

export const createPatientAppointment = createAsyncThunk<
    IPatientAppointment,
    {
        tenantId: string;
        appointment: IPatientAppointment;
    }
>('createPatientAppointment', async ({ tenantId, appointment }) => {
    const patientAppointment = await schedulingApi.createPatientAppointmentAllocation(tenantId, appointment);
    return patientAppointment.data;
});

export const updatePatientAppointment = createAsyncThunk<
    IPatientAppointment,
    {
        tenantId: string;
        appointment: IPatientAppointment;
    }
>('updatePatientAppointment', async ({ tenantId, appointment }) => {
    const latestPatientAppointment = await schedulingApi.getPatientAppointment(tenantId, appointment.id);
    const newPatientAppointment: IPatientAppointment = {
        ...latestPatientAppointment.data,
        ...appointment,
        _etag: latestPatientAppointment.data._etag,
    };
    const patientAppointment = await schedulingApi.updatePatientAppointmentAllocation(tenantId, newPatientAppointment);

    return patientAppointment.data;
});

export const getBlockAppointment = createAsyncThunk(
    'getBlockAppointment',
    async ({ tenantId, appointmentId }: { tenantId: string; appointmentId: string }) => {
        const res = await schedulingApi.getBlockAppointment(tenantId, appointmentId);
        return res;
    },
);

export const createBlockAppointment = createAsyncThunk<
    IBlockAppointment,
    {
        tenantId: string;
        appointment: IBlockAppointment;
    }
>('createBlockAppointment', async ({ tenantId, appointment }) => {
    const result = await schedulingApi.createBlockAppointment(tenantId, appointment);
    return result.data;
});

export const updateBlockAppointment = createAsyncThunk<
    IBlockAppointment,
    {
        tenantId: string;
        appointment: IBlockAppointment;
    }
>('updateBlockAppointment', async ({ tenantId, appointment }) => {
    const result = await schedulingApi.updateBlockAppointment(tenantId, appointment);
    return result.data;
});

// LocationsOfCare
export const getLocationsOfCare = createAsyncThunk('getLocationsOfCare', async ({ tenantId }: { tenantId: string }) => {
    const { data } = await schedulingApi.getLocationsOfCare(tenantId);
    const filetedData = sortBy(
        filter(data, (loc) => loc?.departmentType === LocationOfCareDepartmentType.Dental),
        'displayName',
    ).filter((d) => d !== undefined) as ILocationOfCare[];
    return filetedData;
});

export const getCheckoutBillingProcedures = createAsyncThunk<
    IBillingProcedure[],
    {
        tenantId: string;
        patientId: string;
        appointmentId?: string;
    }
>('getCheckoutBillingProcedures', async ({ tenantId, patientId, appointmentId }) => {
    const result = await dentalApi.getCheckoutBillingProcedures(tenantId, patientId, { appointmentId });
    return result.data;
});

export const fetchPatientCheckoutTasks = createAsyncThunk<
    IUserTask[],
    {
        tenantId: string;
        patientId: string;
    }
>('fetchPatientCheckoutTasks', async ({ tenantId, patientId }) => {
    const patientCheckoutTasks = await dentalApi.getTasksByTarget(tenantId, TaskTargets.PatientCheckout, { patientId });
    return patientCheckoutTasks.data;
});

export const getChartTreatmentPlans = createAsyncThunk<
    IChartTreatmentPlan[],
    {
        tenantId: string;
        patientId: string;
    }
>('getChartTreatmentPlans', async ({ tenantId, patientId }) => {
    const result = await dentalApi.getChartTreatmentPlans(tenantId, patientId);
    return result.data;
});

export const closeAppointmentOverview = (): AppThunk<void> => (dispatch) => {
    dispatch(setPatientOverviewOpen(false));
    dispatch(cleanupEditPatient());
    dispatch(cleanupChartTreatmentPlanPhaseProcedures());
    dispatch(cleanupSelectedAppointment());
    dispatch(cleanupSelectedCurrentAppointmentPhases());
};

export const returnToSchedulingFromCheckout = createAsyncThunk<
    void,
    { tenantId: string; appointment: IPatientAppointment; patient: IPatient },
    { state: RootState }
>('returnToSchedulingFromCheckout', async ({ tenantId, appointment, patient }, { getState, dispatch }) => {
    if (patient && appointment) {
        const newApptData = { ...appointment, trackerStatusId: TrackerStatus.Dismissed };
        dispatch(
            updatePatientAppointment({
                tenantId,
                appointment: newApptData,
            }),
        );
        await dispatch(updatePatient({ tenantId, model: patient }));
        dispatch(setIsCheckoutPanelOpen(false));
    }
});

export interface IQuickViewSchedule {
    name: string;
    color: string;
    hours: string[];
}

export enum CheckInSections {
    BasicDetails = 'basicDetails',
    uds = 'uds',
    Financial = 'financial',
}

const schedulingSlice = createSlice({
    name: 'scheduling',
    initialState,
    reducers: {
        ...schedulingReducers,
        ...checkInReducers,
        ...scheduleAppointmentReducers,
        ...appointmentsToRemoveReducers,
    },
    extraReducers: (builder) => {
        builder
            // [GET] Layouts & Allocations
            .addCase(getLayoutsAndAllocations.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(getLayoutsAndAllocations.fulfilled, (state, action) => {
                state.loading = LoadingStatus.Completed;
                state.layouts.data = action.payload.layouts;
                state.allocations.data = { blocks: action.payload.blocks, patients: action.payload.patients };
                state.tracker = action.payload.trackerData;
            })
            .addCase(getLayoutsAndAllocations.rejected, (state) => {
                state.loading = LoadingStatus.Failed;
            })
            // [GET] Patient Appointment
            .addCase(getPatientAndAppointment.pending, (state) => {
                state.selectedAppointment.loading = LoadingStatus.Pending;
            })
            .addCase(getPatientAndAppointment.fulfilled, (state, action) => {
                const { appointment, patient } = action.payload;
                state.selectedAppointment.loading = LoadingStatus.Completed;
                state.selectedAppointment.data = appointment;
                state.selectedAppointment.patient = patient;
            })
            .addCase(getPatientAndAppointment.rejected, (state) => {
                state.selectedAppointment.loading = LoadingStatus.Failed;
            })
            // [GET] Appointment Patient
            .addCase(getAppointmentPatient.pending, (state) => {
                state.selectedAppointment.loading = LoadingStatus.Pending;
            })
            .addCase(getAppointmentPatient.fulfilled, (state, action) => {
                state.selectedAppointment.loading = LoadingStatus.Completed;
                state.selectedAppointment.patient = action.payload;
            })
            .addCase(getAppointmentPatient.rejected, (state) => {
                state.selectedAppointment.loading = LoadingStatus.Failed;
            })
            // [GET] Appointment Encounter
            .addCase(getPatientAppointmentEncounter.pending, (state) => {
                state.selectedAppointment.loading = LoadingStatus.Pending;
            })
            .addCase(getPatientAppointmentEncounter.fulfilled, (state, action) => {
                state.selectedAppointment.loading = LoadingStatus.Completed;
                state.selectedAppointment.encounter = action.payload;
            })
            .addCase(getPatientAppointmentEncounter.rejected, (state) => {
                state.selectedAppointment.loading = LoadingStatus.Failed;
            })

            // [GET] Providr Appointments
            .addCase(getProviderAppointments.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(getProviderAppointments.fulfilled, (state, action: PayloadAction<IAppointmentAllocations>) => {
                const { patients: appointments } = action.payload;
                state.loading = LoadingStatus.Completed;
                state.providerPatientAppointments = appointments;
            })
            .addCase(getProviderAppointments.rejected, (state) => {
                state.loading = LoadingStatus.Failed;
            })

            // [POST] Patient Appointment
            .addCase(createPatientAppointment.pending, (state) => {
                state.selectedAppointment.loading = LoadingStatus.Pending;
            })
            .addCase(createPatientAppointment.fulfilled, (state, action) => {
                state.selectedAppointment.loading = LoadingStatus.Completed;

                /**
                 * Need to add only allocations that do NOT exist in state already...
                 * In case of signalR disonnecting...
                 */
                const patientAppointment = action.payload;
                if (
                    state.allocations.data &&
                    state.allocations.data.patients?.findIndex((allocation) => allocation.id === patientAppointment.id) === -1
                ) {
                    state.allocations.data.patients = [...(state.allocations?.data.patients ?? []), patientAppointment];
                }

                state.selectedAppointment.data = undefined;
                state.selectedAppointment.selectedPhases = {};
                state.ui.isScheduleAppointmentPanelOpen = false;
            })
            .addCase(createPatientAppointment.rejected, (state) => {
                state.selectedAppointment.loading = LoadingStatus.Failed;
            })
            .addCase(updatePatient.fulfilled, (state) => {
                state.checkin.isCheckInPanelOpen = false;
            })
            // [PUT] Patient Appointment
            .addCase(updatePatientAppointment.pending, (state) => {
                state.selectedAppointment.loading = LoadingStatus.Pending;
                state.appointmentToCancel.saving = SyncStatus.Pending;
            })
            .addCase(updatePatientAppointment.fulfilled, (state, action) => {
                state.selectedAppointment.loading = LoadingStatus.Completed;

                if (state.selectedAppointment.data?.id === action.payload.id)
                     state.selectedAppointment.data = { ...state.selectedAppointment.data, ...action.payload };

                if (state.allocations.data?.patients) {
                    const indexOfAppointment = state.allocations.data.patients?.findIndex(
                        (allocation) => allocation.id === action.payload.id,
                    );
                    if (indexOfAppointment > -1)
                        state.allocations.data.patients[indexOfAppointment] = {
                            ...state.allocations.data.patients[indexOfAppointment],
                            ...action.payload,
                        };
                }

                if (action.payload.trackerStatusId === TrackerStatus.Completed) state.ui.isCheckoutPanelOpen = false;

                state.ui.isScheduleAppointmentPanelOpen = false;
                state.allocations.draggedPatient = state.allocations.draggedPatient.filter((x) => x.id !== action.payload?.id);

                state.appointmentToCancel.saving = SyncStatus.Saved;
                state.appointmentToCancel.appointmentToRemoveId = undefined;
            })
            .addCase(updatePatientAppointment.rejected, (state) => {
                state.selectedAppointment.loading = LoadingStatus.Failed;
                state.appointmentToCancel.saving = SyncStatus.Failed;
            })
            // [GET] Block Appointment
            .addCase(getBlockAppointment.pending, (state) => {
                state.selectedAppointment.loading = LoadingStatus.Pending;
            })
            .addCase(getBlockAppointment.fulfilled, (state, action) => {
                state.selectedAppointment.loading = LoadingStatus.Completed;
                state.selectedAppointment.data = action.payload.data;
            })
            .addCase(getBlockAppointment.rejected, (state) => {
                state.selectedAppointment.loading = LoadingStatus.Failed;
            })
            // [POST] Block Appointment
            .addCase(createBlockAppointment.pending, (state) => {
                state.selectedAppointment.loading = LoadingStatus.Pending;
            })
            .addCase(createBlockAppointment.fulfilled, (state) => {
                state.selectedAppointment.loading = LoadingStatus.Completed;
                state.selectedAppointment.data = undefined;

                // if (state.allocations.data?.blocks) {
                //     state.allocations.data.blocks = [...state.allocations.data.blocks, action.payload];
                // }
                state.ui.isScheduleAppointmentPanelOpen = false;
            })
            .addCase(createBlockAppointment.rejected, (state) => {
                state.selectedAppointment.loading = LoadingStatus.Failed;
            })
            // [PUT] Block Appointment
            .addCase(updateBlockAppointment.pending, (state) => {
                state.selectedAppointment.loading = LoadingStatus.Pending;
            })
            .addCase(updateBlockAppointment.fulfilled, (state, action) => {
                state.selectedAppointment.loading = LoadingStatus.Completed;
                state.selectedAppointment.data = action.payload;

                if (state.allocations.data?.blocks) {
                    state.allocations.data.blocks = [
                        ...state.allocations.data.blocks.filter((b) => b.id !== action.payload.id),
                        action.payload,
                    ];
                }
                state.ui.isScheduleAppointmentPanelOpen = false;
                state.allocations.draggedBlock = state.allocations.draggedBlock.filter((x) => x.id !== action.payload.id);
            })
            .addCase(updateBlockAppointment.rejected, (state) => {
                state.selectedAppointment.loading = LoadingStatus.Failed;
            })

            // [GET] LocationsOfCare
            .addCase(getLocationsOfCare.pending, (state) => {
                state.locationsOfCare.loading = LoadingStatus.Pending;
            })
            .addCase(getLocationsOfCare.fulfilled, (state, action) => {
                state.locationsOfCare.loading = LoadingStatus.Completed;
                const locs = [];
                for (const [, value] of Object.entries(action.payload)) {
                    locs.push(value);
                }

                const lastUsedTenant = appLocalStorage.getLastLoc(action.meta.arg.tenantId);
                state.locationsOfCare.data = locs;
                state.selectedLOC = lastUsedTenant ? lastUsedTenant : locs[0];
            })
            .addCase(getLocationsOfCare.rejected, (state) => {
                state.locationsOfCare.loading = LoadingStatus.Failed;
            })

            .addCase(getCheckoutBillingProcedures.pending, (state) => {
                state.appointmentCheckout.loading = LoadingStatus.Pending;
            })
            .addCase(getCheckoutBillingProcedures.fulfilled, (state, action) => {
                state.appointmentCheckout.loading = LoadingStatus.Completed;
                state.appointmentCheckout.data = action.payload;
            })
            .addCase(getCheckoutBillingProcedures.rejected, (state) => {
                state.appointmentCheckout.loading = LoadingStatus.Failed;
            })
            .addCase(fetchPatientCheckoutTasks.pending, (state) => {
                state.patientCheckoutTasks.loading = LoadingStatus.Pending;
            })
            .addCase(fetchPatientCheckoutTasks.fulfilled, (state, action) => {
                const tasks = action.payload;
                state.patientCheckoutTasks.data = tasks;
                state.patientCheckoutTasks.loading = LoadingStatus.Completed;
            })
            .addCase(fetchPatientCheckoutTasks.rejected, (state) => {
                state.patientCheckoutTasks.loading = LoadingStatus.Failed;
            })
            // [GET] Treatment Plans
            .addCase(getChartTreatmentPlans.pending, (state) => {
                state.treatmentPlans.loading = LoadingStatus.Pending;
            })
            .addCase(getChartTreatmentPlans.fulfilled, (state, action) => {
                state.treatmentPlans.loading = LoadingStatus.Completed;
                state.treatmentPlans.data = action.payload;
            })
            .addCase(getChartTreatmentPlans.rejected, (state) => {
                state.treatmentPlans.loading = LoadingStatus.Failed;
            })
            .addCase(finishCheckout.pending, (state) => {
                state.appointmentCheckout.finishCheckoutLoading = LoadingStatus.Pending;
            })
            .addCase(finishCheckout.fulfilled, (state, { payload }) => {
                const { patientAppointment } = payload;
                //If failed to checkout then we need to update the selected appointment.
                if (patientAppointment && !hasFinishCheckoutSucceeded(payload)) {
                    state.selectedAppointment.data = patientAppointment;
                }

                if (hasFinishCheckoutSucceeded(payload)) {
                    if (state.allocations.data?.patients && payload.patientAppointment) {
                        const indexOfAppointment = state.allocations.data.patients?.findIndex(
                            (allocation) => allocation.id === payload.patientAppointment?.id,
                        );
                        if (indexOfAppointment > -1)
                            state.allocations.data.patients[indexOfAppointment] = {
                                ...state.allocations.data.patients[indexOfAppointment],
                                ...payload.patientAppointment,
                            };
                    }

                    state.ui.isCheckoutPanelOpen = false;
                }

                state.appointmentCheckout.finishCheckoutLoading = LoadingStatus.Completed;
            })
            .addCase(finishCheckout.rejected, (state) => {
                state.appointmentCheckout.finishCheckoutLoading = LoadingStatus.Failed;
            })
            .addCase(returnToSchedulingFromCheckout.pending, (state) => {
                state.appointmentCheckout.finishCheckoutLoading = LoadingStatus.Pending;
            })
            .addCase(returnToSchedulingFromCheckout.fulfilled, (state) => {
                state.appointmentCheckout.finishCheckoutLoading = LoadingStatus.Completed;
                state.checkin.error = undefined;
            })
            .addCase(returnToSchedulingFromCheckout.rejected, (state, { payload }) => {
                state.appointmentCheckout.finishCheckoutLoading = LoadingStatus.Failed;
                state.checkin.error = payload as ErrorTypes;
            })
            .addCase(returnToScheduleFromCheckin.pending, (state) => {
                state.checkin.finishCheckinLoading = LoadingStatus.Pending;
            })
            .addCase(returnToScheduleFromCheckin.fulfilled, (state) => {
                state.checkin.finishCheckinLoading = LoadingStatus.Completed;
                state.checkin.error = undefined;
            })
            .addCase(returnToScheduleFromCheckin.rejected, (state, { payload }) => {
                state.checkin.finishCheckinLoading = LoadingStatus.Failed;
                state.checkin.error = payload as ErrorTypes;
            })
            .addCase(finishCheckin.pending, (state) => {
                state.checkin.finishCheckinLoading = LoadingStatus.Pending;
            })
            .addCase(finishCheckin.fulfilled, (state) => {
                state.checkin.finishCheckinLoading = LoadingStatus.Completed;
            })
            .addCase(finishCheckin.rejected, (state, { payload }) => {
                state.checkin.finishCheckinLoading = LoadingStatus.Failed;
                state.checkin.error = payload as ErrorTypes;
            });

        appointmentToRemoveExtraReducers(builder);
    },
});

const { reducer, actions } = schedulingSlice;
export const {
    setSelectedDate,
    setExpandSchedule,
    setToolbarButtonIsDisabled,
    setPatientOverviewOpen,
    setSelectedLOC,
    setUpdatePatientAppointment,
    cleanupChartTreatmentPlanPhaseProcedures,
    setDisableHIPAAView,
    updatePatientAppointmentNotes,
    setPatientAllocationsDragged,
    setBlockAllocationsDragged,
    updatePatientAllocations,
    updateBlockAllocation,
    setAppointmentType,
    setIsAppointmentPanelOpen,
    cleanupPaymentInformation,
    setAlertDialogMessage,
    // [Filter Operatories]
    toggleFilterOperatory,
    setSelectedOperatories,
    setSelectedOperatoryTags,
    // [Selected Appointment]
    setScheduleBlockAppointmentRootProp,
    setSchedulePatientAppointmentRootProp,
    setSchedulePatientAppointment,
    setScheduleAppointmentOperatoryId,
    setScheduleAppointmentSelectedDate,
    setScheduleAppointmentTime,
    setScheduleAppointmentNotes,
    cleanupSelectedAppointment,
    updatePatientAppointmentProp,
    cleanupSelectedCurrentAppointmentTreatmentPlan,
    setSelectedCurrentAppointmentPhases,
    cleanupSelectedCurrentAppointmentPhases,
    // [Check In]
    setIsCheckinPanelOpen,
    toggleCheckInSecitonVisible,
    cleanupCheckInPanel,
    setAllCheckInSectionOpen,
    // [Appointment to Remove]
    cleanupCancelAppointment,
    editAppointmentToCancelProp,
    setCancelAppointmentModalOpen,
    // [Checkout]
    setIsCheckoutPanelOpen,
    setCheckoutError,
    cleanupCheckout,
    setSelectedCheckoutUpcomingPhases,
    setPreviousAppointmentData,
    cleanupLocationsOfCare,
} = actions;

export default reducer;
