import { IWorkListAwaitingAttestationView } from 'api/models/worklist-appointment-view';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import UserDisplayName from 'components/UserDisplayName';

const attendingProvider: ISortableColumn<IWorkListAwaitingAttestationView> = {
    key: 'attendingProvider',
    minWidth: 100,
    maxWidth: 100,
    isResizable: true,
    name: 'Attending Provider',
    onRender: (item) => <UserDisplayName userId={item?.supervisingProviderId} />,
};

export default attendingProvider;
