import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from '@fluentui/react';
import { AppointmentCancellationDepartmentType } from 'api/models/appointment-cancellation-reason.model';
import { IUpcomingAppointment } from 'api/models/Scheduling/patientAppointment.model';
import { Section, SubSection } from 'components';
import { useTenantId } from 'hooks';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { upsertEditPatient } from 'state/slices/edit-patient/edit-patient.actions';
import { getCancellationReasons } from 'state/slices/scheduling/appointment-to-cancel/appointment-to-cancel.actions';
import { selectCancellationReasonsData } from 'state/slices/scheduling/appointment-to-cancel/appointment-to-cancel.selectors';
import ApptEncounterHistory from './components/ApptEncounterHistory';
import UnscheduledTXPlanProcedures from './components/UnscheduledTXPlanProcedures';
import UpcomingAppts from './components/UpcomingAppts';
import { IAppointmentHistory } from 'api/models/appointment-history.model';

export type VisitHistoryProps = {
    upcomingPatientAppointments?: IUpcomingAppointment[];
    pastAppointments?: IAppointmentHistory[];
    isNewPatient?: boolean;
};

function VisitHistory({
    upcomingPatientAppointments,
    pastAppointments: pastEncounters,
    isNewPatient,
}: VisitHistoryProps): JSX.Element {
    const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState('');
    const tenantId = useTenantId();
    const dispatch = useDispatch();

    const cancellationReasonsData = useSelector(selectCancellationReasonsData);

    useEffect(() => {
        if (isEmpty(cancellationReasonsData)) {
            dispatch(getCancellationReasons({ tenantId, type: AppointmentCancellationDepartmentType.Dental }));
        }
    }, []);

    const onSave = () => {
        dispatch(upsertEditPatient(tenantId));
        setAlertMessage('');
        setIsAlertOpen(false);
    };

    const onAlertDialogDismissed = () => {
        setAlertMessage('');
        setIsAlertOpen(false);
    };

    return (
        <Section>
            <SubSection title="Upcoming Appts.">
                <UpcomingAppts
                    items={upcomingPatientAppointments}
                    setIsAlertOpen={setIsAlertOpen}
                    setAlertMessage={setAlertMessage}
                />
            </SubSection>
            <SubSection title="Unscheduled TX Plan Procedures">
                <UnscheduledTXPlanProcedures
                    setIsAlertOpen={setIsAlertOpen}
                    setAlertMessage={setAlertMessage}
                    buttonsHidden={isNewPatient}
                />
            </SubSection>
            <SubSection title="Appt. History">
                <ApptEncounterHistory items={pastEncounters} />
            </SubSection>
            <Dialog
                hidden={!isAlertOpen}
                onDismiss={onAlertDialogDismissed}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: 'Alert',
                    closeButtonAriaLabel: 'Close',
                    subText: alertMessage,
                }}
                modalProps={{
                    isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}
            >
                <DialogFooter>
                    <PrimaryButton onClick={onSave} text="Save" />
                    <DefaultButton onClick={onAlertDialogDismissed} text="Close" />
                </DialogFooter>
            </Dialog>
        </Section>
    );
}

export default VisitHistory;
