import { Stack, TextField } from '@fluentui/react';
import { IPatientAddress } from 'api/models/patient.model';
import IPatientAppointment from 'api/models/Scheduling/patientAppointment.model';
import { Field, SubSection } from 'components';
import states from 'components/Field/StateOptions';
import { useSelector } from 'hooks';
import { getValidationError } from 'hooks/useValidation';
import { useDispatch } from 'react-redux';
import { selectEditPatient, selectEditPatientValidationErrors } from 'state/slices/edit-patient/edit-patient.selectors';
import { editPatientMailingAddressPropChange } from 'state/slices/edit-patient/edit-patient.slice';
import { selectSelectedAppointmentData } from 'state/slices/scheduling/scheduling.selectors';
import { updatePatientAppointmentProp } from 'state/slices/scheduling/scheduling.slice';
import { BasicDetailsFields } from '../BasicDetails';

type Props = {
    isCheckinCheckout?: boolean;
};

function MailingAddress({ isCheckinCheckout }: Props): JSX.Element | null {
    const dispatch = useDispatch();
    const patient = useSelector(selectEditPatient);
    const validationErrors = useSelector(selectEditPatientValidationErrors);
    const _currentAppointment = useSelector(selectSelectedAppointmentData);

    const stateType = states.find((state) => patient?.mailingAddress?.state === state.key)?.data?.type;

    function newAddressPropChange(key: keyof IPatientAddress, value: string | undefined) {
        dispatch(editPatientMailingAddressPropChange({ key, value }));
        verifyPatientAddress('lastVerifiedMailingAddress');
    }

    const verifyPatientAddress = (path: keyof IPatientAppointment) => {
        if (isCheckinCheckout && !(_currentAppointment as IPatientAppointment)[path])
            dispatch(
                updatePatientAppointmentProp({
                    path,
                    value: new Date().toISOString(),
                }),
            );
    };

    const validTextInput = (value?: string): boolean => {
        const res = value ? /^(?!\s)[a-zA-Z\s]*$/.test(value) : true;
        return res;
    };

    if (patient?.mailingAddressSameAsPhysicalAddress) return null;

    return (
        <SubSection title="Mailing Address" isRequired>
            <Stack tokens={{ childrenGap: 10 }} grow>
                <Stack horizontal tokens={{ childrenGap: 10 }} grow>
                    <Stack.Item grow>
                        <TextField
                            label=" Mailing Address Line 1"
                            value={patient?.mailingAddress?.streetAddress1 ?? ''}
                            autoComplete="off"
                            onChange={(ev, value) => newAddressPropChange('streetAddress1', value)}
                            required
                            errorMessage={
                                getValidationError(validationErrors, BasicDetailsFields.MailingStreet1)
                                    ? 'Mailing Address 1 is required.'
                                    : undefined
                            }
                        />
                    </Stack.Item>
                    <Stack.Item grow>
                        <TextField
                            label=" Mailing Address Line 2"
                            value={patient?.mailingAddress?.streetAddress2 ?? ''}
                            autoComplete="off"
                            onChange={(ev, value) => newAddressPropChange('streetAddress2', value)}
                        />
                    </Stack.Item>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 10 }} grow>
                    <Stack.Item grow>
                        <TextField
                            label=" Mailing City"
                            value={patient?.mailingAddress?.city ?? ''}
                            autoComplete="off"
                            onChange={(ev, value) => {
                                if (validTextInput(value)) {
                                    newAddressPropChange('city', value ? value : '');
                                }
                            }}
                            required
                            errorMessage={
                                getValidationError(validationErrors, BasicDetailsFields.MailingCity)
                                    ? 'Mailing City is required.'
                                    : undefined
                            }
                        />
                    </Stack.Item>
                    <Stack.Item grow>
                        <Field.SearchCombo
                            allowFreeform
                            label="Mailing State"
                            placeholder="(Select State)"
                            selectedKey={patient?.mailingAddress?.state ?? undefined}
                            options={states}
                            threshold={0.2}
                            onChange={(e, option) => {
                                if (option) newAddressPropChange('state', option.key as string);
                            }}
                            useComboBoxAsMenuWidth
                            caretDownButtonStyles={{ root: { display: 'none' } }}
                            styles={{ callout: { minHeight: 35, maxHeight: 300, overflow: 'auto' } }}
                            required
                            errorMessage={
                                getValidationError(validationErrors, BasicDetailsFields.MailingState)
                                    ? 'Mailing State is required.'
                                    : undefined
                            }
                        />
                    </Stack.Item>

                    <Stack horizontal tokens={{ childrenGap: 10 }} grow>
                        <Stack.Item grow>
                            <Field.ZipCode
                                label="Mailing Zip"
                                value={patient?.mailingAddress?.zip ?? ''}
                                autoComplete="off"
                                onChange={(ev, value) => {
                                    newAddressPropChange('zip', value ? value : '');
                                }}
                                required
                                stateType={stateType}
                                errorMessage={
                                    getValidationError(validationErrors, BasicDetailsFields.MailingZip)
                                        ? 'Mailing Zip is required.'
                                        : undefined
                                }
                            />
                        </Stack.Item>
                    </Stack>
                </Stack>
            </Stack>
        </SubSection>
    );
}

export default MailingAddress;
