import { createSelector } from '@reduxjs/toolkit';
import { orderBy, sortBy } from 'lodash';
import { RootState } from 'state/store';

export const communicationState = (state: RootState) => state.communication;
export const selectAllCommunications = createSelector(communicationState, (state) =>
    orderBy(state.communications, ['createdOn'], ['desc'])?.filter((c) => !c.isDeleted),
);
export const selectAllCommunicationsLoading = createSelector(communicationState, (state) => state.loading);

export const selectEditCommunication = createSelector(communicationState, (state) => state.editCommunication);
export const selectEditCommunicationsLoading = createSelector(communicationState, (state) => state.loadingEditCommunication);
