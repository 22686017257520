import { Stack, Text } from '@fluentui/react';
import { ClinicalAlertDataItemComponentProps } from '../ClinicalAlertDataItem';
import BaseAlertDataItem from './BaseAlertDataItem';
import DataItemLabel from './DataItemLabel';

const textStyles: React.CSSProperties = { whiteSpace: 'pre-wrap', color: 'black' };
export default function PreMedAlertDataItem({ data }: ClinicalAlertDataItemComponentProps) {
    return (
        <BaseAlertDataItem data={data}>
            {data?.references?.problemName && (
                <Stack>
                    <DataItemLabel text="AssociatedProblem:" />
                    <Text style={textStyles}>{data.references.problemName}</Text>
                </Stack>
            )}
        </BaseAlertDataItem>
    );
}
