import { IChartProcedure } from 'api/models/chart.model';
import { ConflictRuleType } from 'api/models/lookup.model';
import { ProcedureRenderRule } from 'api/models/procedure.model';
import { IPipelineError, PipelineError } from '../pipeline';
import ProcedureConflictRulesPipeline from '../procedureConflictRules.pipeline';
import { createRule } from '../ruleGenerator';
import { range, every } from 'lodash';

//Get all teeth
const abutmentCrownRules = [
    ProcedureRenderRule.AbutmentRetainerCrown,
    ProcedureRenderRule.AbutmentMarylandBridge,
    ProcedureRenderRule.AbutmentThreeQuarterCrown,
    ProcedureRenderRule.AbutmentSupportedCrown,
];
const ProcedureBetweenAbutmentOnly = createRule<ProcedureConflictRulesPipeline, IChartProcedure>({
    ruleTypes: [ConflictRuleType.betweenAbutmentsOnly],
    rule: (pipeline, item) => {
        let newProcedure = { ...item };
        const _errors: IPipelineError[] = [];
        const abutmentProcedureTeeth = [...pipeline.currentProcedures, ...pipeline.getItems]
            .filter((p) => {
                const renderRule = pipeline.getProcedure(p)?.renderRule;
                if (!renderRule) return false;
                return abutmentCrownRules.includes(renderRule);
            })
            .map((p) => (p.toothIds ? p.toothIds[0] : undefined))
            .filter((p) => p !== undefined) as number[];

        const ponticProcedureTeeth = [
            ...[...pipeline.currentProcedures, ...pipeline.getItems].filter((p) => {
                return pipeline.getProcedure(p)?.renderRule === ProcedureRenderRule.Pontic;
            }),
        ]
            .map((p) => (p.toothIds ? p.toothIds[0] : undefined))
            .filter((p) => p !== undefined) as number[];

        const toothId = (item.toothIds ?? [])[0];
        const toothPosition = pipeline.getTeethPosition([toothId])[0] as number;
        const maxillaryAbutmentTeeth = [...abutmentProcedureTeeth.filter((t) => pipeline.getIsToothMaxillary(t))];
        const mandibularAbutmentTeeth = [...abutmentProcedureTeeth.filter((t) => !pipeline.getIsToothMaxillary(t))];

        const maxillaryPonticTeeth = [...ponticProcedureTeeth.filter((t) => pipeline.getIsToothMaxillary(t))];
        const mandibularPonticTeeth = [...ponticProcedureTeeth.filter((t) => !pipeline.getIsToothMaxillary(t))];
        //Maybe we need to do this recursively. Check from current proc to next proc in both directions, if we find a valid procedure, keep going until we find an abutment...
        //If we don't find an abutment then we stop and return invalid.
        const abutmentTeeth = new Set(
            pipeline.getTeethPosition(pipeline.getIsToothMaxillary(toothId) ? maxillaryAbutmentTeeth : mandibularAbutmentTeeth),
        );
        const ponticTeeth = new Set(
            pipeline.getTeethPosition(pipeline.getIsToothMaxillary(toothId) ? maxillaryPonticTeeth : mandibularPonticTeeth),
        );
        //Recursive func that first looks in the positive direction for pontic procedures, and if it finds an abutment procedure connected to the chain of pontics, it returns true.
        //If the abutment cannot be found in the positive direction, we look in the negative direction.
        function getValidPontic(position: number): boolean | undefined {
            if (abutmentTeeth.has(position)) return true;
            //We want to go in one direction, and then in the other.
            if (position >= toothPosition) {
                //Move in the positive direction
                if (ponticTeeth.has(position)) {
                    return getValidPontic(position + 1);
                } else {
                    //Otherwise swap to moving in the negative direction
                    return getValidPontic(toothPosition - 1);
                }
            } else {
                if (ponticTeeth.has(position)) {
                    //Move in the negative direction
                    return getValidPontic(position - 1);
                }
            }
        }
        if (!getValidPontic(toothPosition)) {
            newProcedure = pipeline.removeToothIdFromChartProcedure(newProcedure, toothId);
            _errors.push({ type: PipelineError.BetweenAbutmentsOnly, data: pipeline.getProcedure(item) });
        }
        return { shouldRemoveItem: !newProcedure.toothIds?.length, errors: _errors };
    },
});

export default ProcedureBetweenAbutmentOnly;
