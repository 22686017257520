import { AppThunk } from 'state/store';
import { addExceptionToPeriod, updateExpceptionOnPeriod } from '../settings.slice';

export const upsertExceptionToPeriod = (): AppThunk<void> => (dispatch, getState) => {
    const state = getState();
    const isAdding = state.settings.providers.isAddException;
    const periodId = state.settings.providers.providerPeriodId;
    const exception = state.settings.providers.editException;
    const index = state.settings.providers.providerExceptionIndex;

    if (exception && periodId)
        if (isAdding) {
            dispatch(
                addExceptionToPeriod({
                    periodId,
                    exception,
                }),
            );
        } else {
            if (index !== undefined)
                dispatch(
                    updateExpceptionOnPeriod({
                        periodId,
                        exception,
                        index,
                    }),
                );
        }
};
