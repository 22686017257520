import { Checkbox, DefaultButton, IDropdownOption, Label, PrimaryButton, Separator, Stack, TextField } from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import { Field, Section } from 'components';
import useForms from 'hooks/store/useForms';
import { useState } from 'react';

import {
    allWNL,
    otherSectionDefaults,
    psrLowerArchDefaults,
    psrUpperArchDefaults,
    softTissueExamSectionDefaults,
} from './constants';
import { ISoftTissueExamQuestions, SoftTissueExamValue } from './types';

type SoftTissueExamQuestionProps = {
    softTissueProp?: keyof ISoftTissueExamQuestions;
    softTissueNoteProp?: keyof ISoftTissueExamQuestions;
    label: string;
};

function SoftTissueExamQuestion({ label, softTissueProp, softTissueNoteProp }: SoftTissueExamQuestionProps) {
    const { currentlySelectedForm, setFormFieldDataAndSave } = useForms();

    if (!currentlySelectedForm) return null;

    const data = currentlySelectedForm.data as ISoftTissueExamQuestions;
    const typedSetFieldData = (path: keyof ISoftTissueExamQuestions, value?: string) => setFormFieldDataAndSave(path, value);

    const softTissueValue = softTissueProp ? data[softTissueProp] : undefined;
    const noteValue = softTissueNoteProp ? (data[softTissueNoteProp] as string) : undefined;

    function onClick(dropdownValue: SoftTissueExamValue) {
        if (softTissueProp && dropdownValue) {
            if (softTissueValue === dropdownValue) {
                typedSetFieldData(softTissueProp, SoftTissueExamValue.Empty);
            } else {
                typedSetFieldData(softTissueProp, dropdownValue);
            }
        }
    }

    const isCompleted = currentlySelectedForm.completedDate ? true : false;

    return (
        <Stack grow horizontal tokens={{ childrenGap: 10 }}>
            <Stack.Item
                styles={{ root: { maxWidth: softTissueValue === SoftTissueExamValue.Abnormal ? 375 : undefined, minWidth: 375 } }}
                grow
            >
                <Label>{label}</Label>
                <Stack horizontal tokens={{ childrenGap: 3 }}>
                    <DefaultButton
                        primary={softTissueValue === SoftTissueExamValue.WithinNormalLimits}
                        text="Within Normal Limits"
                        onClick={() => onClick(SoftTissueExamValue.WithinNormalLimits)}
                    />

                    <DefaultButton
                        text="Abnormal"
                        primary={softTissueValue === SoftTissueExamValue.Abnormal}
                        onClick={() => onClick(SoftTissueExamValue.Abnormal)}
                    />
                </Stack>
            </Stack.Item>
            {softTissueValue === SoftTissueExamValue.Abnormal && (
                <Stack.Item grow>
                    <TextField
                        label="Notes"
                        onChange={(ev, value) => {
                            if (softTissueNoteProp) typedSetFieldData(softTissueNoteProp, value);
                        }}
                        value={noteValue}
                        disabled={isCompleted}
                    />
                </Stack.Item>
            )}
        </Stack>
    );
}

export default function SoftTissueExam(): JSX.Element | null {
    const { currentlySelectedForm, setFormFieldDataAndSave, previousOfSelectedForm, setSelectedFormDataAndSave } = useForms();
    if (!currentlySelectedForm) return null;

    const data = currentlySelectedForm.data as ISoftTissueExamQuestions;
    const _previousSelectedForm = previousOfSelectedForm?.data as ISoftTissueExamQuestions | undefined;

    const previousPSRLowerArch: ISoftTissueExamQuestions = {
        lowerRight: _previousSelectedForm?.lowerRight,
        lowerCenter: _previousSelectedForm?.lowerCenter,
        lowerLeft: _previousSelectedForm?.lowerLeft,
    };

    const previousPSRUpperArch: ISoftTissueExamQuestions = {
        upperRight: _previousSelectedForm?.upperRight,
        upperCenter: _previousSelectedForm?.upperCenter,
        upperLeft: _previousSelectedForm?.upperLeft,
    };

    const previousOtherQuestions: ISoftTissueExamQuestions = {
        occlusion: _previousSelectedForm?.occlusion,
        midlines: _previousSelectedForm?.midlines,
        toLeft: _previousSelectedForm?.toLeft,
        toRight: _previousSelectedForm?.toRight,
        overbite: _previousSelectedForm?.overbite,
        overjet: _previousSelectedForm?.overjet,
        oralHygiene: _previousSelectedForm?.oralHygiene,
        calculus: _previousSelectedForm?.calculus,
        perioExam: _previousSelectedForm?.perioExam,
        crossbite: _previousSelectedForm?.crossbite,
    };

    const previousSoftTissueExamQuestions: ISoftTissueExamQuestions = {
        lymphNodes: _previousSelectedForm?.lymphNodes,
        lymphNodesNotes: _previousSelectedForm?.lymphNodesNotes,
        tmjNotes: _previousSelectedForm?.tmjNotes,
        tmj: _previousSelectedForm?.tmj,
        lips: _previousSelectedForm?.lips,
        lipsNotes: _previousSelectedForm?.lipsNotes,
        buccalMucosa: _previousSelectedForm?.buccalMucosa,
        buccalMucosaNotes: _previousSelectedForm?.buccalMucosaNotes,
        vestibularMucosa: _previousSelectedForm?.vestibularMucosa,
        vestibularMucosaNotes: _previousSelectedForm?.vestibularMucosaNotes,
        tongue: _previousSelectedForm?.tongue,
        tongueNotes: _previousSelectedForm?.tongueNotes,
        floorOfMouth: _previousSelectedForm?.floorOfMouth,
        floorOfMouthNotes: _previousSelectedForm?.floorOfMouthNotes,
        hardPalate: _previousSelectedForm?.hardPalate,
        hardPalateNotes: _previousSelectedForm?.hardPalateNotes,
        softPalate: _previousSelectedForm?.softPalate,
        softPalateNotes: _previousSelectedForm?.softPalateNotes,
        pharynx: _previousSelectedForm?.pharynx,
        pharynxNotes: _previousSelectedForm?.pharynxNotes,
        tonsils: _previousSelectedForm?.tonsils,
        tonsilsNotes: _previousSelectedForm?.tonsilsNotes,
        gingiva: _previousSelectedForm?.gingiva,
        gingivaNotes: _previousSelectedForm?.gingivaNotes,
    };

    const setPreviousExamFieldsForSection = (section: 'softTissueExam' | 'other' | 'psrLower' | 'psrUpper') => {
        if (section === 'softTissueExam') setSelectedFormDataAndSave({ ...data, ...previousSoftTissueExamQuestions });
        if (section === 'other') setSelectedFormDataAndSave({ ...data, ...previousOtherQuestions });
        if (section === 'psrUpper') setSelectedFormDataAndSave({ ...data, ...previousPSRUpperArch });
        if (section === 'psrLower') setSelectedFormDataAndSave({ ...data, ...previousPSRLowerArch });
    };

    const clearExamFieldsForSection = (section: 'softTissueExam' | 'other' | 'psrLower' | 'psrUpper') => {
        if (section === 'softTissueExam') setSelectedFormDataAndSave({ ...data, ...softTissueExamSectionDefaults });
        if (section === 'other') setSelectedFormDataAndSave({ ...data, ...otherSectionDefaults });
        if (section === 'psrUpper') setSelectedFormDataAndSave({ ...data, ...psrUpperArchDefaults });
        if (section === 'psrLower') setSelectedFormDataAndSave({ ...data, ...psrLowerArchDefaults });
    };

    const setAllWNL = () => {
        setSelectedFormDataAndSave({ ...data, ...allWNL });
    };

    const typedSetFieldData = (path: keyof ISoftTissueExamQuestions, value?: string | number | undefined) =>
        setFormFieldDataAndSave(path, value);

    const setNumericFieldData = (path: keyof ISoftTissueExamQuestions, value?: string) => {
        if (value === undefined || !isNaN(+value)) {
            typedSetFieldData(path, +(value as string));
        } else {
            setFormFieldDataAndSave(path, value);
        }
    };
    const options: IDropdownOption[] = [
        {
            key: 'None',
            text: 'None',
        },
        {
            key: 0,
            text: '0',
        },
        {
            key: 1,
            text: '1',
        },
        {
            key: 2,
            text: '2',
        },
        {
            key: 3,
            text: '3',
        },
        {
            key: 4,
            text: '4',
        },
        {
            key: 5,
            text: '5',
        },
        {
            key: 6,
            text: '6',
        },
    ];

    const isCompleted = currentlySelectedForm.completedDate ? true : false;

    return (
        <Stack grow>
            <Section
                heading="Soft Tissue Exam"
                headingRightContent={
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <DefaultButton text="All WNL" disabled={isCompleted} onClick={setAllWNL} />
                        <DefaultButton
                            text="Previous"
                            disabled={!_previousSelectedForm || isCompleted}
                            onClick={() => setPreviousExamFieldsForSection('softTissueExam')}
                        />
                        <DefaultButton
                            text="Clear"
                            disabled={isCompleted}
                            onClick={() => clearExamFieldsForSection('softTissueExam')}
                        />
                    </Stack>
                }
            >
                <SoftTissueExamQuestion
                    label="Lymph Nodes"
                    softTissueNoteProp={'lymphNodesNotes'}
                    softTissueProp={'lymphNodes'}
                />
                <Separator />
                <SoftTissueExamQuestion label="TMJ" softTissueNoteProp={'tmjNotes'} softTissueProp={'tmj'} />
                <Separator />
                <SoftTissueExamQuestion label="Lips" softTissueNoteProp={'lipsNotes'} softTissueProp={'lips'} />
                <Separator />
                <SoftTissueExamQuestion
                    label="Buccal Mucosa"
                    softTissueNoteProp={'buccalMucosaNotes'}
                    softTissueProp={'buccalMucosa'}
                />
                <Separator />
                <SoftTissueExamQuestion
                    label="Vestibular Mucosa"
                    softTissueNoteProp={'vestibularMucosaNotes'}
                    softTissueProp={'vestibularMucosa'}
                />
                <Separator />
                <SoftTissueExamQuestion label="Tongue" softTissueNoteProp={'tongueNotes'} softTissueProp={'tongue'} />
                <Separator />
                <SoftTissueExamQuestion
                    label="Floor of Mouth"
                    softTissueNoteProp={'floorOfMouthNotes'}
                    softTissueProp={'floorOfMouth'}
                />
                <Separator />
                <SoftTissueExamQuestion
                    label="Hard Palate"
                    softTissueNoteProp={'hardPalateNotes'}
                    softTissueProp={'hardPalate'}
                />
                <Separator />
                <SoftTissueExamQuestion
                    label="Soft Palate"
                    softTissueNoteProp={'softPalateNotes'}
                    softTissueProp={'softPalate'}
                />
                <Separator />
                <SoftTissueExamQuestion label="Pharynx" softTissueNoteProp={'pharynxNotes'} softTissueProp={'pharynx'} />
                <Separator />
                <SoftTissueExamQuestion label="Tonsils" softTissueNoteProp={'tonsilsNotes'} softTissueProp={'tonsils'} />
                <Separator />
                <SoftTissueExamQuestion label="Gingiva" softTissueNoteProp={'gingivaNotes'} softTissueProp={'gingiva'} />
            </Section>
            <Section
                heading="Other"
                headingRightContent={
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <DefaultButton
                            text="Previous"
                            disabled={!_previousSelectedForm ?? isCompleted}
                            onClick={() => setPreviousExamFieldsForSection('other')}
                        />
                        <DefaultButton text="Clear" onClick={() => clearExamFieldsForSection('other')} disabled={isCompleted} />
                    </Stack>
                }
            >
                <Label>Occlusion</Label>
                <Stack horizontal tokens={{ childrenGap: 15 }}>
                    <Checkbox
                        checked={data.occlusion === 'Class 1'}
                        onChange={(e, checked) => {
                            if (!checked) {
                                typedSetFieldData('occlusion', '' as string);
                            } else {
                                typedSetFieldData('occlusion', 'Class 1' as string);
                            }
                        }}
                        label="Class I"
                        disabled={isCompleted}
                    ></Checkbox>

                    <Checkbox
                        checked={data.occlusion === 'Class 2'}
                        onChange={(e, checked) => {
                            if (!checked) {
                                typedSetFieldData('occlusion', '' as string);
                            } else {
                                typedSetFieldData('occlusion', 'Class 2' as string);
                            }
                        }}
                        label="Class II"
                        disabled={isCompleted}
                    ></Checkbox>
                    <Checkbox
                        checked={data.occlusion === 'Class 3'}
                        onChange={(e, checked) => {
                            if (!checked) {
                                typedSetFieldData('occlusion', '' as string);
                            } else {
                                typedSetFieldData('occlusion', 'Class 3' as string);
                            }
                        }}
                        label="Class III"
                        disabled={isCompleted}
                    ></Checkbox>
                </Stack>
                <Separator />
                <Stack tokens={{ childrenGap: 10 }} verticalAlign="end" horizontal grow>
                    <Stack>
                        <Label>Midlines</Label>
                        <Stack horizontal tokens={{ childrenGap: 15 }}>
                            <Checkbox
                                checked={data.midlines === 'On'}
                                onChange={(e, checked) => {
                                    if (!checked) {
                                        typedSetFieldData('midlines', '' as string);
                                    } else {
                                        typedSetFieldData('midlines', 'On' as string);
                                    }
                                }}
                                label="On"
                                disabled={isCompleted}
                            ></Checkbox>

                            <Checkbox
                                checked={data.midlines === 'Off'}
                                onChange={(e, checked) => {
                                    if (!checked) {
                                        typedSetFieldData('midlines', '' as string);
                                    } else {
                                        typedSetFieldData('midlines', 'Off' as string);
                                    }
                                }}
                                label="Off"
                                disabled={isCompleted}
                            ></Checkbox>
                        </Stack>
                    </Stack>
                    <Stack horizontal grow horizontalAlign="end">
                        <TextField
                            styles={{ root: { maxWidth: 150 } }}
                            label="To Left"
                            inputMode="numeric"
                            value={data.toLeft ? data.toLeft.toString() : ''}
                            onRenderSuffix={() => <span>mm</span>}
                            onChange={(ev, value) => setNumericFieldData('toLeft', value)}
                            disabled={isCompleted}
                        />
                        <TextField
                            styles={{ root: { maxWidth: 150 } }}
                            label="To Right"
                            inputMode="numeric"
                            value={data.toRight ? data.toRight.toString() : ''}
                            onRenderSuffix={() => <span>mm</span>}
                            onChange={(ev, value) => setNumericFieldData('toRight', value)}
                            disabled={isCompleted}
                        />
                    </Stack>
                </Stack>
                <Separator />
                <Stack horizontal tokens={{ childrenGap: 10 }} grow>
                    <Stack.Item grow>
                        <TextField
                            label="Overbite"
                            value={data.overbite}
                            onChange={(ev, value) => typedSetFieldData('overbite', value)}
                            disabled={isCompleted}
                        />
                    </Stack.Item>
                    <Stack.Item grow>
                        <TextField
                            label="Overjet"
                            value={data.overjet}
                            onChange={(ev, value) => typedSetFieldData('overjet', value)}
                            disabled={isCompleted}
                        />
                    </Stack.Item>
                </Stack>
                <Separator />
                <Label>Oral Hygiene</Label>
                <Stack horizontal tokens={{ childrenGap: 15 }}>
                    <Checkbox
                        checked={data.oralHygiene === 'Excellent'}
                        onChange={(e, checked) => {
                            if (!checked) {
                                typedSetFieldData('oralHygiene', '' as string);
                            } else {
                                typedSetFieldData('oralHygiene', 'Excellent' as string);
                            }
                        }}
                        label="Excellent"
                        disabled={isCompleted}
                    ></Checkbox>
                    <Checkbox
                        checked={data.oralHygiene === 'Good'}
                        onChange={(e, checked) => {
                            if (!checked) {
                                typedSetFieldData('oralHygiene', '' as string);
                            } else {
                                typedSetFieldData('oralHygiene', 'Good' as string);
                            }
                        }}
                        label="Good"
                        disabled={isCompleted}
                    ></Checkbox>
                    <Checkbox
                        checked={data.oralHygiene === 'Fair'}
                        onChange={(e, checked) => {
                            if (!checked) {
                                typedSetFieldData('oralHygiene', '' as string);
                            } else {
                                typedSetFieldData('oralHygiene', 'Fair' as string);
                            }
                        }}
                        label="Fair"
                        disabled={isCompleted}
                    ></Checkbox>
                    <Checkbox
                        checked={data.oralHygiene === 'Poor'}
                        onChange={(e, checked) => {
                            if (!checked) {
                                typedSetFieldData('oralHygiene', '' as string);
                            } else {
                                typedSetFieldData('oralHygiene', 'Poor' as string);
                            }
                        }}
                        label="Poor"
                        disabled={isCompleted}
                    ></Checkbox>
                </Stack>
                <Separator />
                <Label>Calculus</Label>
                <Stack horizontal tokens={{ childrenGap: 15 }}>
                    <Checkbox
                        checked={data.calculus === 'None'}
                        onChange={(e, checked) => {
                            if (!checked) {
                                typedSetFieldData('calculus', '' as string);
                            } else {
                                typedSetFieldData('calculus', 'None' as string);
                            }
                        }}
                        label="None"
                        disabled={isCompleted}
                    ></Checkbox>

                    <Checkbox
                        checked={data.calculus === 'Little'}
                        onChange={(e, checked) => {
                            if (!checked) {
                                typedSetFieldData('calculus', '' as string);
                            } else {
                                typedSetFieldData('calculus', 'Little' as string);
                            }
                        }}
                        label="Little"
                        disabled={isCompleted}
                    ></Checkbox>
                    <Checkbox
                        checked={data.calculus === 'Moderate'}
                        onChange={(e, checked) => {
                            if (!checked) {
                                typedSetFieldData('calculus', '' as string);
                            } else {
                                typedSetFieldData('calculus', 'Moderate' as string);
                            }
                        }}
                        label="Moderate"
                        disabled={isCompleted}
                    ></Checkbox>
                    <Checkbox
                        checked={data.calculus === 'Heavy'}
                        onChange={(e, checked) => {
                            if (!checked) {
                                typedSetFieldData('calculus', '' as string);
                            } else {
                                typedSetFieldData('calculus', 'Heavy' as string);
                            }
                        }}
                        label="Heavy"
                        disabled={isCompleted}
                    ></Checkbox>
                </Stack>
                <Separator />
                <Stack>
                    <Label>Perio Exam</Label>
                    <Stack horizontal tokens={{ childrenGap: 15 }}>
                        <Checkbox
                            checked={data.perioExam === 'Yes'}
                            onChange={(e, checked) => {
                                if (!checked) {
                                    typedSetFieldData('perioExam', '' as string);
                                } else {
                                    typedSetFieldData('perioExam', 'Yes' as string);
                                }
                            }}
                            label="Yes"
                            disabled={isCompleted}
                        ></Checkbox>

                        <Checkbox
                            checked={data.perioExam === 'No'}
                            onChange={(e, checked) => {
                                if (!checked) {
                                    typedSetFieldData('perioExam', '' as string);
                                } else {
                                    typedSetFieldData('perioExam', 'No' as string);
                                }
                            }}
                            label="No"
                            disabled={isCompleted}
                        ></Checkbox>
                    </Stack>
                </Stack>
                <Separator />
                <Stack>
                    <Label>Crossbite</Label>
                    <Stack horizontal tokens={{ childrenGap: 15 }}>
                        <Checkbox
                            checked={data.crossbite === 'Right'}
                            onChange={(e, checked) => {
                                if (!checked) {
                                    typedSetFieldData('crossbite', '' as string);
                                } else {
                                    typedSetFieldData('crossbite', 'Right' as string);
                                }
                            }}
                            label="Right"
                            disabled={isCompleted}
                        ></Checkbox>

                        <Checkbox
                            checked={data.crossbite === 'Left'}
                            onChange={(e, checked) => {
                                if (!checked) {
                                    typedSetFieldData('crossbite', '' as string);
                                } else {
                                    typedSetFieldData('crossbite', 'Left' as string);
                                }
                            }}
                            label="Left"
                            disabled={isCompleted}
                        ></Checkbox>
                        <Checkbox
                            checked={data.crossbite === 'Both'}
                            onChange={(e, checked) => {
                                if (!checked) {
                                    typedSetFieldData('crossbite', '' as string);
                                } else {
                                    typedSetFieldData('crossbite', 'Both' as string);
                                }
                            }}
                            label="Both"
                            disabled={isCompleted}
                        ></Checkbox>
                    </Stack>
                </Stack>
            </Section>
            <Stack tokens={{ childrenGap: 10 }} horizontal grow>
                <Stack.Item grow>
                    <Section
                        heading="PSR Upper Arch"
                        headingRightContent={
                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                                <DefaultButton
                                    text="Previous"
                                    disabled={!_previousSelectedForm || isCompleted}
                                    onClick={() => setPreviousExamFieldsForSection('psrUpper')}
                                />
                                <DefaultButton
                                    text="Clear"
                                    onClick={() => clearExamFieldsForSection('psrUpper')}
                                    disabled={isCompleted}
                                />
                            </Stack>
                        }
                    >
                        <Stack grow horizontal tokens={{ childrenGap: 10 }}>
                            <Stack.Item grow>
                                <Field.Dropdown
                                    label="Upper Right"
                                    options={options}
                                    selectedKey={data.upperRight === undefined ? null : data.upperRight}
                                    onChange={(ev, option) => {
                                        if (option) setNumericFieldData('upperRight', option.key as string);
                                    }}
                                    disabled={isCompleted}
                                />
                            </Stack.Item>
                            <Stack.Item grow>
                                <Field.Dropdown
                                    label="Upper Center"
                                    options={options}
                                    selectedKey={data.upperCenter === undefined ? null : data.upperCenter}
                                    onChange={(ev, option) => {
                                        if (option) setNumericFieldData('upperCenter', option.key as string);
                                    }}
                                    disabled={isCompleted}
                                />
                            </Stack.Item>
                            <Stack.Item grow>
                                <Field.Dropdown
                                    label="Upper Left"
                                    options={options}
                                    selectedKey={data.upperLeft === undefined ? null : data.upperLeft}
                                    onChange={(ev, option) => {
                                        if (option) setNumericFieldData('upperLeft', option.key as string);
                                    }}
                                    disabled={isCompleted}
                                />
                            </Stack.Item>
                        </Stack>
                    </Section>
                </Stack.Item>
                <Stack.Item grow>
                    <Section
                        heading="PSR Lower Arch"
                        headingRightContent={
                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                                <DefaultButton
                                    text="Previous"
                                    disabled={!_previousSelectedForm || isCompleted}
                                    onClick={() => setPreviousExamFieldsForSection('psrLower')}
                                />
                                <DefaultButton
                                    text="Clear"
                                    onClick={() => clearExamFieldsForSection('psrLower')}
                                    disabled={isCompleted}
                                />
                            </Stack>
                        }
                    >
                        <Stack grow horizontal tokens={{ childrenGap: 10 }}>
                            <Stack.Item grow>
                                <Field.Dropdown
                                    label="Lower Right"
                                    options={options}
                                    selectedKey={data.lowerRight === undefined ? null : data.lowerRight}
                                    onChange={(ev, option) => {
                                        if (option) setNumericFieldData('lowerRight', option.key as string);
                                    }}
                                    disabled={isCompleted}
                                />
                            </Stack.Item>
                            <Stack.Item grow>
                                <Field.Dropdown
                                    label="Lower Center"
                                    options={options}
                                    selectedKey={data.lowerCenter === undefined ? null : data.lowerCenter}
                                    onChange={(ev, option) => {
                                        if (option) setNumericFieldData('lowerCenter', option.key as string);
                                    }}
                                    disabled={isCompleted}
                                />
                            </Stack.Item>
                            <Stack.Item grow>
                                <Field.Dropdown
                                    label="Lower Left"
                                    options={options}
                                    selectedKey={data.lowerLeft === undefined ? null : data.lowerLeft}
                                    onChange={(ev, option) => {
                                        if (option) setNumericFieldData('lowerLeft', option.key as string);
                                    }}
                                    disabled={isCompleted}
                                />
                            </Stack.Item>
                        </Stack>
                    </Section>
                </Stack.Item>
            </Stack>
        </Stack>
    );
}
