import { Dictionary } from '@reduxjs/toolkit';
import IPatientAppointment from 'api/models/Scheduling/patientAppointment.model';
import { forEach, groupBy, map, orderBy, some, sortBy, uniqBy } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'state/store';
import { selectProvidersAsList } from '../tenant/providers.slice';
import { selectLocationsOfCare } from '../scheduling/scheduling.selectors';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { EncounterStatus } from 'api/models/encounter.model';
import { enUS } from 'date-fns/locale';
import { format, isValid, parse } from 'date-fns';

export const selectTreatingProviders = createSelector(selectProvidersAsList, (providers) => {
    return providers.filter((provider) => provider?.isTreatingProvider || provider?.isHygienist);
});
export const selectWeekOfData = (state: RootState) => state.clinicalHuddle.weekOfAppointments;
export const selectWeekOfProvider = (state: RootState): string | undefined => state.clinicalHuddle.weekOfAppointments.providerId;

//Search filters
export const selectWeekOfProviderArray = (state: RootState): string[] | undefined =>
    state.clinicalHuddle.weekOfAppointments.providerIdArray;
export const selectLocationOfCareArray = (state: RootState): string[] | undefined =>
    state.clinicalHuddle.weekOfAppointments.locationOfCareArray;
export const selectPatientSearch = createSelector(selectWeekOfData, (weekOfData) => weekOfData.patientSearch);

export const selectWeekOfDate = (state: RootState): string | undefined => state.clinicalHuddle.weekOfAppointments.date;
export const selectWeekOfAppointments = (state: RootState): IPatientAppointment[] | undefined =>
    state.clinicalHuddle.weekOfAppointments.appointments.data;

export const selectWeekOfLoading = (state: RootState): boolean =>
    state.clinicalHuddle.weekOfAppointments.appointments.loading === LoadingStatus.Pending;

export const showActiveOnlyAppointment = (state: RootState): boolean => state.clinicalHuddle.weekOfAppointments.showActiveOnly;

export const selectSelectedProviderOption = createSelector(
    [selectProvidersAsList, selectWeekOfProvider],
    (providers, providerId) => {
        if (providerId) return providers.find((provider) => provider?.id === providerId);
        return undefined;
    },
);

export const selectWeekOfFilteredAppointments = createSelector(
    [selectWeekOfAppointments, showActiveOnlyAppointment],
    (appointments, showActiveOnly) => {
        const list = map(sortBy(appointments, ['startTime', 'DESC']), (p) => p) as IPatientAppointment[];

        return showActiveOnly ? list : list.filter((p) => !p?.isDeleted);
    },
);

export const filteredWeekOfAppointments = createSelector(
    [selectWeekOfProviderArray, selectLocationOfCareArray, selectPatientSearch, selectWeekOfFilteredAppointments],
    (providerArray, locationArray, patientSearch, filteredAppointments) => {
        let appointments: IPatientAppointment[] = [...filteredAppointments];

        if (locationArray?.length) {
            appointments = filteredAppointments.filter((res) => {
                if (res.locationOfCareId && locationArray.includes(res.locationOfCareId)) {
                    return true;
                }
            });
        }
        if (providerArray?.length) {
            const filteredHygienist: IPatientAppointment[] = appointments.filter((res) => {
                return res.hygienistId && providerArray?.includes(res.hygienistId);
            });
            const filteredProvider: IPatientAppointment[] = appointments.filter((res) => {
                return res.treatingProviderId && providerArray?.includes(res.treatingProviderId);
            });

            appointments = [...filteredProvider, ...filteredHygienist];
            // return [...filteredProvider, ...filteredHygienist];
        }

        if (patientSearch.length) {
            const isMrn = parseInt(patientSearch) ? false : patientSearch.indexOf(':') !== -1;
            const isFirstName = !isNaN(parseInt(patientSearch)) ? false : patientSearch.split(',')[0].length > 0 ? false : true;
            const isLastName = !isNaN(parseInt(patientSearch))
                ? false
                : patientSearch.indexOf(',') === -1 && patientSearch.indexOf(':') === -1
                  ? true
                  : false;
            const isFullName = parseInt(patientSearch)
                ? false
                : patientSearch.split(',')[0].length > 0
                  ? patientSearch.indexOf(',') !== -1
                  : false;

            const isDoB = (): string => {
                if (patientSearch && !isMrn) {
                    const numbers = patientSearch?.match(/\d+/g)?.join('');
                    if (numbers?.length === 8) {
                        const date = numbers.replace(/(\d{2})(\d{2})(\d{2}|\d{4})/, `$1/$2/$3`);
                        const parsedDate = parse(date, 'P', new Date(), { locale: enUS });
                        const isValidDate = isValid(parsedDate);

                        if (isValidDate) {
                            return date;
                        }
                    }
                }
                return '';
            };

            if (isMrn) {
                appointments = appointments.filter((res) => {
                    return res.patient?.mrn?.includes(patientSearch.substring(1));
                });
            }

            if ((isFirstName || isLastName) && !isFullName) {
                appointments = appointments.filter((res) => {
                    return (
                        res.patient?.lastName?.toLowerCase().includes(patientSearch.toLowerCase()) ||
                        res.patient?.firstName?.toLowerCase().includes(patientSearch.toLowerCase())
                    );
                });
            }
            if (isFullName) {
                appointments = appointments.filter((res) => {
                    return (
                        res.patient?.lastName?.toLowerCase().includes(patientSearch.split(',')[0].toLowerCase()) &&
                        res.patient?.firstName?.toLowerCase().includes(patientSearch.split(',')[1].substring(1).toLowerCase())
                    );
                });
            }

            if (isDoB()) {
                appointments = appointments.filter((res) => {
                    return res.patient?.dateOfBirth === format(new Date(isDoB()), 'yyyy-MM-dd');
                });
            }
        }

        if (locationArray?.length || providerArray?.length || patientSearch.length) {
            return appointments;
        } else {
            return filteredAppointments;
        }
    },
);

export const filteredWeekOfAppointmentsByLOC = createSelector(
    filteredWeekOfAppointments,
    selectLocationsOfCare,
    (appointments, locations) => {
        function getLocDisplayName(locId: string) {
            return locations.find((loc) => loc.id === locId)?.displayName ?? '';
        }
        const sortedByLOCAsc = orderBy(
            appointments,
            [(appointment) => getLocDisplayName(appointment.locationOfCareId).toLowerCase()],
            ['asc'],
        );
        const uniqByAppointmentId = uniqBy(sortedByLOCAsc, (appointment) => appointment.id);
        return groupBy(uniqByAppointmentId, (appointment) => appointment.locationOfCareId);
    },
);

export const selectExpandedLocIds = (state: RootState): string[] =>
    state.clinicalHuddle.weekOfAppointments.expandedLocationOfCareIds;

export const dayHasEncounterCreatedForPatient = createSelector(filteredWeekOfAppointments, (appointments) => {
    const result: Dictionary<boolean> = {};
    const groupedAppointments = groupBy(appointments, (appt) => appt.patientId);
    const uniquePatientIds = uniqBy(appointments, (appt) => appt.patientId).map((appt) => appt.patientId);

    forEach(uniquePatientIds, (patientId) => {
        const appointments = groupedAppointments[patientId];
        result[patientId] = some(appointments, (appointment) => appointment.encounterId);
    });

    return result;
});

