import { CommandBar, MessageBar, Separator, Stack, Toggle } from '@fluentui/react';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { RouteParams } from 'interfaces/route-params';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    cleanupPaymentHistory,
    cleanupPaymentHistoryReverseSavedStatusMessageBar,
    setPaymentHistoryConfirmModalOpen,
    togglePaymentHistoryShowReversals,
} from 'state/slices/ledger/ledger.slice';
import {
    getNegativePaymentReversalReasons,
    getPaymentHistory,
} from 'state/slices/ledger/payment-history/payment-history.actions';
import {
    selectNegativeReversalSavingStatus,
    selectPaymentHistoryFilters,
    selectPaymentHistorySelectedViews,
    selectPaymentHistoryShowReversedPayments,
} from 'state/slices/ledger/payment-history/payment-history.selectors';
import SaveStatusMessageBar from '../components/SaveStatusMessageBar';
import PaymentHistoryFilterInputs from './PaymentHistoryFilters';
import PaymentHistoryList from './PaymentHistoryList';
import PaymentHistoryReversalConfirmation from './PaymentHistoryReversalConfirmation';

export default function PaymentHistory() {
    const dispatch = useAppDispatch();
    const { tenantId, patientId } = useParams<RouteParams>();

    const savingStatus = useSelector(selectNegativeReversalSavingStatus);
    const selectedPaymentSourceViews = useSelector(selectPaymentHistorySelectedViews);
    const showReversals = useSelector(selectPaymentHistoryShowReversedPayments);

    const filters = useSelector(selectPaymentHistoryFilters);

    const hasDateOfServiceFilter = !!filters.dateOfService;

    useEffect(() => {
        if (!patientId) return;
        const _getPaymentHistory = dispatch(getPaymentHistory({ tenantId, patientId }));
        dispatch(getNegativePaymentReversalReasons({ tenantId }));

        return () => {
            dispatch(cleanupPaymentHistory());
            _getPaymentHistory.abort('CancelGetPaymentHistoryPageUnloaded');
        };
    }, [dispatch, tenantId, patientId]);

    const dismissSavedMessageBar = () => {
        dispatch(cleanupPaymentHistoryReverseSavedStatusMessageBar());
    };

    const _onReversePayment = () => {
        dispatch(setPaymentHistoryConfirmModalOpen(true));
    };

    const _toggleShowReveresals = () => {
        dispatch(togglePaymentHistoryShowReversals());
    };

    const disableReverseSelected = !selectedPaymentSourceViews.length || hasDateOfServiceFilter;

    return (
        <>
            <Helmet title="Ledger - Payment History" />
            <SaveStatusMessageBar
                saving={savingStatus}
                onDismiss={dismissSavedMessageBar}
                messageConfig={{
                    completed: 'Reversal of the selected payment source was successful.',
                    failed: 'Failed to reverse the selected payment.',
                }}
            />
            <CommandBar
                items={[
                    {
                        key: 'reverseSelected',
                        text: 'Reverse Selected',
                        iconProps: { iconName: 'RevToggleKey' },
                        disabled: disableReverseSelected,
                        onClick: _onReversePayment,
                    },
                    {
                        key: 'separator',
                        onRender: () => <Separator vertical styles={{ root: { paddingLeft: 20, paddingRight: 20 } }} />,
                    },
                    {
                        key: 'showReversed',
                        onRender: () => (
                            <Stack verticalAlign="center" styles={{ root: { paddingLeft: 10 } }}>
                                <Toggle
                                    onClick={_toggleShowReveresals}
                                    checked={showReversals}
                                    styles={{ root: { paddingTop: 5 } }}
                                    label="Show Reversed Payments"
                                    inlineLabel
                                />
                            </Stack>
                        ),
                    },
                ]}
                styles={{ root: { paddingLeft: 0 } }}
            />
            {hasDateOfServiceFilter && (
                <MessageBar>While using the Date of Service filter, you are unable to reverse a selected payment.</MessageBar>
            )}
            <PaymentHistoryFilterInputs />
            <PaymentHistoryList />
            <PaymentHistoryReversalConfirmation />
        </>
    );
}
