import { Text } from '@fluentui/react';
import { IBillingProcedure } from 'api/models/billing-procedure.model';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';

const procedureDescription: ISortableColumn<IBillingProcedure> = {
    key: 'procedureDescription',
    name: 'Description',
    fieldName: 'procedureDescription',
    minWidth: 100,
    maxWidth: 225,
    isResizable: true,
    onRender: (item) => {
        if (item) {
            const desc = item.procedureDescription ? `${item.procedureDescription}${item.stage ? ` (Stage: ${item.stage})` : ""}` : 'N/A';
            return (
                <Text variant="small" title={desc}>
                    {desc}
                </Text>
            );
        }

        return null
    },
};

export default procedureDescription;
