import { IPerioExam, IChartPerioExamTooth } from 'api/models/perio-exam.model';
import { isAfter, isEqual } from 'date-fns';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { SyncStatus } from 'interfaces/syncing-statuses';
import { find, forEach, orderBy, sortBy, uniqBy } from 'lodash';
import { toothSpriteReferences } from 'pages/Charting/components/ToothCanvas/spriteList';
import { UPPER_POSITIONS, LOWER_POSITIONS } from 'pages/Perio/perio-settings';
import { createSelector } from 'reselect';
import { allPatientEncounters } from 'state/slices/patient/patient.selectors';
import { RootState } from 'state/store';
import convertDashedDateString from 'utils/convertDateStringToLocal';
import { convertFromMilitaryTime } from 'utils/convertFromMilitaryTime';
import { ChartPerioExamsState, IPrintPerioExam } from './perio-exams.state';

export const selectPerioExams = (state: RootState): ChartPerioExamsState => state.charting.perioExams;
export const selectArchType = (state: RootState): ArchType => state.charting.perioExams.selectedArchType;

export const selectPerioExamModified = (state: RootState): boolean => state.charting.perioExams.modified;
export const selectPerioExamSaving = (state: RootState): LoadingStatus => state.charting.perioExams.saving;

export const selectPerioExamsData = createSelector(selectPerioExams, (state) => {
    return state.data.filter((item) => !item.isDeleted);
});

export const selectPerioExamHistory = createSelector(selectPerioExams, (state) => state.historySearch);

export const selectPerioExamsWithEncounterDate = createSelector(selectPerioExamsData, allPatientEncounters, (exams, encounters) =>
    orderBy(
        exams
            .map((exam) => {
                const encounter = encounters.find((e) => e.id === exam.encounterId);
                return {
                    ...exam,
                    encounter: encounter
                        ? { ...encounter, encounterDate: convertDashedDateString(encounter?.encounterDate) }
                        : undefined,
                };
            })
            .filter((res) => res.encounter?.encounterDate),
        (item) => {
            const date = item.encounter?.encounterDate;
            return date ? new Date(date) : '';
        },
        'desc',
    ),
);

export const selectPerioPrintExams = createSelector(selectPerioExamsWithEncounterDate, (perioExams) => perioExams.slice(0, 8));

export const selectUniqPerioExamsDates = createSelector(selectPerioExamsWithEncounterDate, selectPerioExamHistory, (exams) => {
    return uniqBy(exams, (item) => item?.encounter?.encounterDate as string).map((exam) => {
        const date = exam?.encounter?.encounterDate;
        const time = exam?.appointmentStartTime ? ` - ${convertFromMilitaryTime(exam.appointmentStartTime)}` : '';
        return { key: date, text: `${date}${time}` };
    }) as { key: string; text: string }[];
});

export const selectPerioSaveStatus = createSelector(
    selectPerioExamModified,
    selectPerioExamSaving,
    (modified, saving): SyncStatus => {
        if ((saving === LoadingStatus.Idle || saving === LoadingStatus.Completed) && !modified) {
            return SyncStatus.Saved;
        } else if (saving === LoadingStatus.Pending || modified) {
            return SyncStatus.Pending;
        } else {
            return SyncStatus.Failed;
        }
    },
);

export const currentPerioExam = createSelector(selectPerioExams, (perioExams) =>
    perioExams.currentPerioExam ? perioExams.currentPerioExam : ({} as IPerioExam),
);

export const selectCurrentPerioExamEncounter = createSelector(currentPerioExam, allPatientEncounters, (perioExam, encounters) => {
    return encounters.find((e) => e.id === perioExam.encounterId);
});

export const selectedPerioDepthFieldData = createSelector(
    selectPerioExams,
    (perioExams) => perioExams.selectedPerioDepthFieldData,
);
export const currentPerioExamTeeth = createSelector(selectPerioExams, (perioExams) => {
    const teethForPerio: { [key: string]: IExtendedPerioTooth } = {};
    if (perioExams.currentPerioExam?.teeth) {
        forEach(perioExams.currentPerioExam.teeth, (tooth, toothId: string) => {
            const position = find(toothSpriteReferences, ['id', +toothId])?.position;
            if (tooth && position) teethForPerio[toothId] = { ...tooth, position, id: +toothId };
        });
    }
    return teethForPerio;
});

export const currentPerioExamToothData = createSelector(
    [currentPerioExamTeeth, (_: unknown, toothPosition: string) => toothPosition],
    (teeth, toothPosition) => {
        return teeth[toothPosition] ? teeth[toothPosition] : null;
    },
);

export type IExtendedPerioTooth = IChartPerioExamTooth & {
    id: number;
    position: number;
};

export type ArchType = 'Maxillary' | 'Mandibular';

export const getPerioTeethPositions = createSelector(currentPerioExamTeeth, selectArchType, (perioExam, selectedArchType) => {
    const toothPositions: (undefined | number)[] = [];
    const positions = selectedArchType === 'Maxillary' ? UPPER_POSITIONS : LOWER_POSITIONS;
    positions.forEach((position) => {
        const tooth = find(perioExam, ['position', position])?.position;
        toothPositions.push(tooth);
    });
    return toothPositions;
});

export const selectPrintExams = (state: RootState): IPrintPerioExam => state.charting.perioExams.printExam;
