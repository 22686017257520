import { SerializedError } from '@reduxjs/toolkit';
import IEncounterClaimSummary from 'api/models/encounter-claim-summary.model';
import { IEncounterSummary } from 'api/models/encounter-ledger.model';
import { RelativeDateType } from 'components/Field/RelativeDateRangeField';
import { LoadingStatus } from 'interfaces/loading-statuses';

export type LedgerClaimHistoryState = {
    showFilters?: boolean;
    encounterNoteModalOpen: boolean;
    filters: LedgerClaimsHistoryFilters;
    selectedEncounterSummary?: IEncounterSummary;
    encounterClaimSummary?: IEncounterClaimSummary;
    encounterClaimSummaryError?: SerializedError;
    loadingEncounterClaimSummary: LoadingStatus;
};

export type LedgerClaimsHistoryFilters = {
    insuranceBalanceGreaterThanZero?: boolean;
    startDate?: string;
    endDate?: string;
    relativeDateType?: RelativeDateType;
    billingProviders?: string[];
};

const initialClaimHistoryState: LedgerClaimHistoryState = {
    showFilters: false,
    encounterNoteModalOpen: false,
    filters: {},
    loadingEncounterClaimSummary: LoadingStatus.Idle,
};

export default initialClaimHistoryState;
