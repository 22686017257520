import IWorkListView from 'api/models/worklist-view.model';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import UserDisplayName from 'components/UserDisplayName';

const attestingProvider: ISortableColumn<IWorkListView> = {
    key: 'providerFullName',
    name: 'Attesting Provider',
    fieldName: 'providerFullName',
    minWidth: 100,
    maxWidth: 150,
    isResizable: true,
    onRender: (item) => <UserDisplayName userId={item?.attestingProviderId} />,
};

export default attestingProvider;
