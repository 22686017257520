import { Icon, IIconStyleProps, IIconStyles, IStyleFunctionOrObject, Stack, useTheme } from '@fluentui/react';
import { IChartPerioExamRecord, SurfacesBoolean, SurfacesNumber } from 'api/models/perio-exam.model';
import { usePerioExams } from 'hooks';
import { isBoolean } from 'lodash';
import { TOOTH_CONTAINER_WIDTH } from 'pages/Perio/perio-settings';
import { IExtendedPerioTooth } from 'state/slices/charting/perio-exams/perio-exams.selectors';

function IconBooleanField({
    position,
    isUpper,
    perioField,
    tooth,
    icon,
    isDisabled,
}: {
    tooth: IExtendedPerioTooth;
    position: number;
    isUpper?: boolean;
    perioField: keyof IChartPerioExamRecord;
    icon: { iconName: string; hoverColor: string; activeColor: string };
    isDisabled?: boolean;
}) {
    const { palette } = useTheme();
    const { setToothFieldAndUpdate } = usePerioExams();

    const flipDistalMesial = position < 8 || position > 24;
    const distalProperty: keyof SurfacesNumber = flipDistalMesial ? 'distal' : 'mesial';
    const mesialProperty = flipDistalMesial ? 'mesial' : 'distal';

    const facialOrLingual = isUpper ? 'facial' : 'lingual';

    const onClick = (surface: keyof SurfacesBoolean, value?: boolean) => {
        if (isBoolean(value)) {
            setToothFieldAndUpdate({
                toothId: tooth.id,
                value: !value,
                surface,
                arch: facialOrLingual,
                perioField,
            });
        }
    };
    const dataField = tooth[facialOrLingual] ? (tooth[facialOrLingual][perioField] as SurfacesBoolean | undefined) : undefined;

    const iconStyles: (value: boolean) => IStyleFunctionOrObject<IIconStyleProps, IIconStyles> = (value) => ({
        root: {
            color: !value ? palette.neutralLight : icon.activeColor,
            ':hover': {
                color: icon.hoverColor,
                cursor: 'pointer',
                transition: 'all .15s',
            },
        },
    });

    const disabledIconStyles: (value: boolean) => IStyleFunctionOrObject<IIconStyleProps, IIconStyles> = (value) => ({
        root: {
            color: !value ? palette.neutralLight : icon.activeColor,
        },
    });

    const icon1Value = dataField && dataField[distalProperty] ? dataField[distalProperty] : false;
    const icon2Value = dataField && dataField.middle ? dataField.middle : false;
    const icon3Value = dataField && dataField[mesialProperty] ? dataField[mesialProperty] : false;

    return isDisabled ? (
        <Stack style={{ width: TOOTH_CONTAINER_WIDTH }} horizontal horizontalAlign="space-around">
            <Icon
                style={{
                    ...{ fontSize: 20 },
                }}
                styles={disabledIconStyles(icon1Value)}
                iconName={icon.iconName}
            />
            <Icon
                style={{
                    ...{ fontSize: 20 },
                }}
                styles={disabledIconStyles(icon2Value)}
                iconName={icon.iconName}
            />
            <Icon
                style={{
                    ...{ fontSize: 20 },
                }}
                styles={disabledIconStyles(icon3Value)}
                iconName={icon.iconName}
            />
        </Stack>
    ) : (
        <Stack style={{ width: TOOTH_CONTAINER_WIDTH }} horizontal horizontalAlign="space-around">
            <Icon
                style={{
                    ...{ fontSize: 20 },
                }}
                onClick={() => onClick(distalProperty, icon1Value)}
                styles={iconStyles(icon1Value)}
                iconName={icon.iconName}
            />
            <Icon
                style={{
                    ...{ fontSize: 20 },
                }}
                styles={iconStyles(icon2Value)}
                onClick={() => onClick('middle', icon2Value)}
                iconName={icon.iconName}
            />
            <Icon
                style={{
                    ...{ fontSize: 20 },
                }}
                styles={iconStyles(icon3Value)}
                onClick={() => onClick(mesialProperty, icon3Value)}
                iconName={icon.iconName}
            />
        </Stack>
    );
}

export default IconBooleanField;
