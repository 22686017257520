import { WorkListRecentlyViewedAction } from '../WorkListRecentItems';

export const outstandingCheckInAppointmentsActions: WorkListRecentlyViewedAction[] = [
    {
        key: 'patientProfile',
        iconProps: {
            iconName: 'Contact',
        },
        idFilterFieldName: 'appointmentId',
        text: 'Patient Profile',
    },
    {
        key: 'cancelAppointment',
        iconProps: {
            iconName: 'Delete',
        },
        idFilterFieldName: 'appointmentId',
        text: 'Cancel Appointment',
    },
    {
        key: 'goToPatientLedger',
        iconProps: {
            iconName: 'Money',
        },
        idFilterFieldName: 'appointmentId',
        text: 'Go to Patient Ledger',
    },
];

export const outstandingCheckoutAppointments: WorkListRecentlyViewedAction[] = [
    {
        key: 'appointmentOverview',
        iconProps: { iconName: 'PreviewLink' },
        idFilterFieldName: 'appointmentId',
        text: 'Appointment Overview',
    },
    {
        key: 'visitSummary',
        iconProps: { iconName: 'OpenPane' },
        idFilterFieldName: 'appointmentId',
        text: 'Visit Summary',
    },
    {
        key: 'patientProfile',
        iconProps: {
            iconName: 'Contact',
        },
        idFilterFieldName: 'appointmentId',
        text: 'Patient Profile',
    },
];

export const amendRequireEncounters: WorkListRecentlyViewedAction[] = [
    {
        key: 'visitSummary',
        iconProps: { iconName: 'OpenPane' },
        idFilterFieldName: 'encounterId',
        text: 'Visit Summary',
    },
];
