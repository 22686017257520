import IPatientVitals, { IVitalReading, VitalNameType, VitalType } from 'api/models/patient-vital.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { v4 as uuid } from 'uuid';

export type VitalsState = {
    loading: LoadingStatus;
    saving: LoadingStatus;
    data?: IPatientVitals;
    vitalPanelOpen: boolean;
    vitalPanelData: VitalsPanelData;
    vitalsToUpdate: IVitalReading[];
};
export interface BloodPressureReading {
    readingId: string;
    [VitalNameType.Systolic]: string;
    [VitalNameType.Diastolic]: string;
    [VitalNameType.Site]: string;
    [VitalNameType.Cuff]: string;
    [VitalNameType.Refused]: boolean;
    [VitalNameType.RefusedReason]: string;
    [VitalNameType.Position]: string;
}

export interface VitalsPanelData {
    [VitalType.BloodPressure]: BloodPressureReading[];

    [VitalNameType.Height]: string;
    [VitalNameType.HeightInches]: string;
    [VitalNameType.HeightType]: string;
    [VitalNameType.HeightRefused]: boolean;
    [VitalNameType.HeightRefusedReason]: string;

    [VitalNameType.Weight]: string;
    [VitalNameType.WeightRefused]: boolean;
    [VitalNameType.WeightType]: string;
    [VitalNameType.WeightRefusedReason]: string;
    [VitalNameType.OutOfRange]: boolean;

    [VitalNameType.PulseRate]: string;
    [VitalNameType.PulseLocation]: string;
    [VitalNameType.PulseType]: string;

    [VitalNameType.Temperature]: string;
    [VitalNameType.TemperatureType]: string;

    [VitalNameType.RespirationRate]: string;

    [VitalNameType.Saturation]: string;
    [VitalNameType.SaturationAirtype]: string;
}

export const newVitalsPanelData: VitalsPanelData = {
    BP: [
        {
            'VITALS.BLOODPRESSURE.CUFF': '',
            'VITALS.BLOODPRESSURE.DIASTOLIC': '',
            'VITALS.BLOODPRESSURE.REFUSED': false,
            'VITALS.BLOODPRESSURE.REFUSEDREASON': '',
            'VITALS.BLOODPRESSURE.SITE': '',
            'VITALS.BLOODPRESSURE.SYSTOLIC': '',
            'VITALS.BLOODPRESSURE.POSITION': '',
            readingId: uuid(),
        },
    ],

    'VITALS.HEIGHT': '',
    'VITALS.HEIGHT.INCHES': '',
    'VITALS.HEIGHT.REFUSED': false,
    'VITALS.HEIGHT.REFUSEDREASON': '',
    'VITALS.HEIGHT.TYPE': '',

    'VITALS.O2SATURATION': '',
    'VITALS.O2SATURATION.AIRTYPE': '',

    'VITALS.PULSE.LOCATION': '',
    'VITALS.PULSE.RATE': '',
    'VITALS.PULSE.TYPE': '',

    'VITALS.RESPIRATIONRATE': '',

    'VITALS.TEMPERATURE': '',
    'VITALS.TEMPERATURE.TYPE': '',

    'VITALS.WEIGHT': '',
    'VITALS.WEIGHT.REFUSED': false,
    'VITALS.WEIGHT.REFUSEDREASON': '',
    'VITALS.WEIGHT.TYPE': '',
    'VITALS.WEIGHT.OUTOFRANGE': false,
};

export const initialVitalsState: VitalsState = {
    loading: LoadingStatus.Idle,
    vitalPanelOpen: false,
    saving: LoadingStatus.Idle,
    vitalPanelData: newVitalsPanelData,
    vitalsToUpdate: [],
};
