import {
    DetailsRow,
    DirectionalHint,
    IDetailsRowProps,
    TooltipDelay,
    TooltipHost,
    mergeStyles,
    useTheme,
    IDetailsRowCheckProps,
} from '@fluentui/react';
import { useSelector } from 'hooks';
import { selectRecentWorkListItems } from 'state/slices/admin-huddle/worklists/worklist.selectors';

export interface IExtendedDetailsRowCheckProps<T> extends IDetailsRowCheckProps {
    item?: T;
}

type Props<T> = IDetailsRowProps & { id: string | undefined; onRenderCheck?: (props: IExtendedDetailsRowCheckProps<T>) => JSX.Element };

export default function WorkListDetailListRow<T>(props: Props<T>) {
    const theme = useTheme();
    const recentWorkListItems = useSelector(selectRecentWorkListItems);

    if (!props.id) return null;

    const idOfItem = props.id;
    if (idOfItem) {
        const indexOfItem = recentWorkListItems.findIndex((recentItem) => recentItem.id === idOfItem);
        const itemExistsInRecents = indexOfItem > -1;

        const wrapperStyle = mergeStyles({
            position: 'relative',
        });

        return (
            <TooltipHost
                directionalHint={DirectionalHint.leftTopEdge}
                content={itemExistsInRecents ? 'You recently viewed this item.' : undefined}
                delay={TooltipDelay.long}
            >
                <div className={wrapperStyle}>
                    {itemExistsInRecents && (
                        <div
                            style={{
                                height: '100%',
                                width: 4,
                                backgroundColor: theme.palette.themePrimary,
                                position: 'absolute',
                                left: 0,
                                zIndex: 1,
                            }}
                        />
                    )}
                    <DetailsRow {...props} />
                </div>
            </TooltipHost>
        );
    }
    return null;
}
