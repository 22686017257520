import { createSlice, Dictionary } from '@reduxjs/toolkit';
import IChart, { ChartProcedureStatus, IChartCondition, IChartProcedure } from 'api/models/chart.model';
import { LoadingStatus, LoadingStatuses } from 'interfaces/loading-statuses';
import treatmentPlanReducers, { treatmentPlanExtraReducers } from '../treatmentPlans/treatmentPlans.reducers';
import { procedureExtraReducers, proceduresReducers } from '../procedures/procedures.reducers';
import { conditionsExtraReducers } from '../conditions/conditions.reducers';
import uiReducers from '../ui/ui.reducers';
import { IChartDentition } from 'api/models/chart-dentition.model';
import dentitionReducers, { dentitionExtraReducers } from '../dentition/dentition.reducers';
import { IChartPerioExamTooth } from 'api/models/perio-exam.model';
import { perioExamsExtraReducers, perioExamsReducers } from '../perio-exams/perio-exams.reducers';
import { chain, find, range } from 'lodash';
import { TEETH_REFS } from 'pages/Charting/components/ToothCanvas/spriteList';
import { ChartingUiState, uiInitialState } from '../ui/ui.state';
import { chartDentitionsInitialState, ChartDentitionState } from '../dentition/dentitions.state';
import { chartConditionsInitialState, ChartConditionsState } from '../conditions/conditions.state';
import { chartProceduresInitialState, ChartProceduresState } from '../procedures/procedures.state';
import { chartTreatmentPlansInitialState, ChartTreatmentPlanState } from '../treatmentPlans/treatment-plans.state';
import { chartPerioExamsInitialState, ChartPerioExamsState } from '../perio-exams/perio-exams.state';
import { chartActionReducers, chartActionsExtraReducers } from './chart.reducers';
import { chartProcedurePanelInitialState, ChartProcedurePanelState } from '../procedure-panel/procedure-panel.state';
import { procedurePanelReducers } from '../procedure-panel/procedure-panel.reducers';
import { diagnosesPanelInitialState, DiagnosesPanelState } from '../diagnoses-panel/diagnoses-panel.state';
import { diagnosesPanelReducers } from '../diagnoses-panel/diagnoses-panel.reducers';
import { IChartAction } from '../chartActionsList.pipeline';
import { conditionPanelInitialState, ConditionPanelState } from '../condition-panel/condition-panel.state';
import conditionPanelReducers from '../condition-panel/condition-panel.reducers';
import { ICondition } from 'api/models/lookup.model';
import ConditionCodes from '../conditionCodes';
import { perioPanelInitialState, PerioPanelState } from '../perio-panel/perio.panel.state';
import perioPanelReducers from '../perio-panel/perio-panel.reducers';
import { IProcedure, ProcedureRenderRule } from 'api/models/procedure.model';
import { ChartActionFilters, initialState as chartFilterInitialState } from 'pages/Charting/components/ChartActionFilter/state';
import { chartActionFilterReducers } from 'pages/Charting/components/ChartActionFilter/reducers';

export enum ProcedureActionType {
    Existing = 'Existing',
    ExistingOther = 'Existing Other',
    Treatment = 'Treatment',
    Referred = 'Referred',
}

const initialState: ChartingState = {
    modalOpen: false,
    attestModalOpen: false,
    loading: LoadingStatus.Idle,
    saving: LoadingStatus.Idle,
    ui: uiInitialState,
    dentitions: chartDentitionsInitialState,
    conditions: chartConditionsInitialState,
    procedures: chartProceduresInitialState,
    treatmentPlans: chartTreatmentPlansInitialState,
    perioExams: chartPerioExamsInitialState,
    procedurePanel: chartProcedurePanelInitialState,
    diagnosesPanel: diagnosesPanelInitialState,
    conditionPanel: conditionPanelInitialState,
    perioPanel: perioPanelInitialState,
    selectedChartActions: [],
    chartActionFilters: chartFilterInitialState,
};

const chartingSlice = createSlice({
    name: 'charting',
    initialState,
    reducers: {
        ...chartActionReducers,
        ...uiReducers,
        ...proceduresReducers,
        ...treatmentPlanReducers,
        ...dentitionReducers,
        ...perioExamsReducers,
        ...procedurePanelReducers,
        ...diagnosesPanelReducers,
        ...conditionPanelReducers,
        ...perioPanelReducers,
        ...chartActionFilterReducers,
    },
    extraReducers: (builder) => {
        chartActionsExtraReducers(builder);
        perioExamsExtraReducers(builder);
        treatmentPlanExtraReducers(builder);
        procedureExtraReducers(builder);
        conditionsExtraReducers(builder);
        dentitionExtraReducers(builder);
    },
});

const { reducer, actions } = chartingSlice;
export const {
    setAssetsLoaded,
    cleanupChart,
    selectTooth,
    setMenu,
    deselectAllTeeth: deselectTeeth,
    selectAllTeeth,
    setChartingPipelineError,
    setChartingSection,
    toggleAttestModal,

    toggleSelectedAreas,
    clearSelectedAreas,

    applyActionToTeeth,

    setSelectedTreatmentPlan,
    setSignature,
    setPatientRefusedToSign,
    setSignedDate,

    moveActions,
    deleteActions,

    setFinancialNotes,
    setFocusedDepthField,
    setToothField,
    setArchType,

    setCondition,
    setConditionPanelOpen,
    setConditionPanelSelectedTeeth,
    cleanupConditionPanel,
    setConditionPanelSelectedSurfaces,

    setPerioPanelOpen,
    setPerioExamHistorySearch,
    setPerioComparePanelOpen,
    setPerioExamsToCompare,
    resetPerioExamPanel,
    cleanupPerioExamsSave,

    setCurrentChartTreatmentPlan,
    moveProceduresToPhase,
    deleteTreatmentPlanPhase,
    cleanupChartTreatmentPlans,
    discardCurrentTreatmentPlan,
    setPatientReturnDate,
    cleanupPendingProcedures,
    setPhaseProceduresStatus,
    setTreatmentPlanIsSigning,
    cleanupCurrentTreatmentPlan,
    setModal,
    setAddAndUpdateTreatmentPlan,

    // Print Predeterminations
    setPrintPredeterminationOpen,
    setPrintPredeterminationSelectedTreatmentPlan,
    selectPrintPredeterminationChartActions,
    setPrintPredeterminationDropdownValues,
    cleanupPrintPredetermination,

    //Chart Action Filter
    setChartActionFilterType,
    setChartActionFilterProvider,
    setChartActionFilterStatus,
    cleanupChartActionFilters,
    setChartActionFilterDates,
    toggleChartActionFilterVisibility,
    setChartActionFilterTeeth,
} = actions;

export function generateTeethFromDentition(
    dentitions: IChartDentition[],
    conditionsAsList: IChartCondition[],
    conditionsLookup: Dictionary<ICondition>,
    proceduresAsList?: IChartProcedure[],
    proceduresLookup?: Dictionary<IProcedure>,
): Dictionary<IChartPerioExamTooth> {
    const teethPosition = range(1, 33);
    const perioExamTeeth: { [key: string]: IChartPerioExamTooth } = {};

    const teethArrayForPerioExam = chain(teethPosition)
        .filter(missingTeeth)
        .filter(extractedTeeth)
        .map(getToothIdFromPosition)
        .reject(isNotDefined);

    teethArrayForPerioExam.value().forEach((tooth) => (perioExamTeeth[`${tooth}`] = { facial: {}, lingual: {} }));
    return perioExamTeeth;

    function missingTeeth(position: number): boolean {
        const toothId = getToothIdFromPosition(position);
        if (toothId) {
            const toothMissing = find(conditionsAsList, (c) => {
                if (c.conditionId) {
                    const condition = conditionsLookup[c.conditionId];

                    return c.toothId === toothId && condition?.code === ConditionCodes.Missing;
                }
                return false;
            });
            return !toothMissing;
        }
        return true;
    }

    function extractedTeeth(position: number): boolean {
        const toothId = getToothIdFromPosition(position);
        if (toothId) {
            if (proceduresAsList && proceduresLookup) {
                const toothExtracted = find(proceduresAsList, (c) => {
                    if (c.procedureId) {
                        const procedure = proceduresLookup[c.procedureId];
                        return c.toothIds?.includes(toothId) && procedure?.renderRule === ProcedureRenderRule.Extraction;
                    }
                    return false;
                });
                return !toothExtracted;
            }
        }
        return true;
    }

    function getToothIdFromPosition(position: number): number | undefined {
        const toothOverride = find(dentitions, ['id', `${position}`]);
        if (toothOverride) {
            const isPrimaryTooth =
                toothOverride.dentitionMode === 'Primary' || toothOverride.dentitionMode === 'PrimarySupernumerary';
            if (isPrimaryTooth) {
                const primaryTooth = find(TEETH_REFS.primary, ['position', position]);
                return primaryTooth ? primaryTooth.id : undefined;
            } else {
                const permTooth = find(TEETH_REFS.permanent, ['position', position]);
                return permTooth ? permTooth.id : undefined;
            }
        }
        return position;
    }

    function isNotDefined(tooth?: number): boolean {
        return tooth === undefined;
    }
}

export default reducer;
export const chartingActions = actions;

export type ChartingState = {
    chart?: IChart;
    loading: LoadingStatuses;
    saving: LoadingStatuses;
    error?: string;
    modalOpen: boolean;
    attestModalOpen: boolean;
    ui: ChartingUiState;
    dentitions: ChartDentitionState;
    procedures: ChartProceduresState;
    treatmentPlans: ChartTreatmentPlanState;
    conditions: ChartConditionsState;
    perioExams: ChartPerioExamsState;
    procedurePanel: ChartProcedurePanelState;
    diagnosesPanel: DiagnosesPanelState;
    conditionPanel: ConditionPanelState;
    perioPanel: PerioPanelState;
    selectedChartActions?: IChartAction[];
    chartActionFilters: ChartActionFilters;
};
