import {
    Pivot,
    PivotItem,
    Selection,
    SelectionMode,
    Stack,
    IObjectWithKey,
    ISelection,
    ProgressIndicator,
} from '@fluentui/react';
import { SortableDetailsList } from 'components';
import useLoadTestDataAsync from 'hooks/useLoadTestDataAsync';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { useEffect, useMemo, useState } from 'react';
import { usdCurrencyFormatter } from 'utils';
import ClaimRejectionFields from './ClaimRejectionFields';
import SelectedClaimRejection from './SelectedClaimRejection';
import { IRejection, testRejections } from './test-data';

export type DenialsRejectionsTabs = 'rejections' | 'denials';

export default function ClaimRejectionsDetailsList() {
    const [selectedMenuItemKey, setSelectedMenuItemKey] = useState<DenialsRejectionsTabs>('rejections');
    const [selectedRejections, setSelectedRejections] = useState<IRejection[]>([]);
    const [rejectionsData, loadingTestData, loadTestData] = useLoadTestDataAsync(testRejections, 500);

    useEffect(() => {
        loadTestData();
        setSelectedRejections([]);
    }, [selectedMenuItemKey]);

    const rejectionsSelection = useMemo(() => {
        return new Selection({
            onSelectionChanged: () => {
                const selectedRejections = getRejectionsSelectionDetails();
                setSelectedRejections(selectedRejections);
            },
            getKey: (item) => item.id,
            items: selectedRejections,
        });
    }, []);

    const getRejectionsSelectionDetails = () => {
        const rejections = rejectionsSelection.getSelection();
        return rejections;
    };

    function _onLinkClick(item?: PivotItem | undefined) {
        if (item) setSelectedMenuItemKey(item.props.itemKey as DenialsRejectionsTabs);
    }

    const selectionRejection = selectedRejections[0];
    const loading = loadingTestData === LoadingStatus.Pending;

    return (
        <Stack grow>
            <Pivot onLinkClick={_onLinkClick} selectedKey={selectedMenuItemKey}>
                <PivotItem headerText={`Rejections (${testRejections.length})`} itemKey="rejections" />
                <PivotItem headerText="Denials (22)" itemKey="denials" />
            </Pivot>
            <ClaimRejectionFields />
            <Stack tokens={{ childrenGap: 10 }}>
                {loading ? (
                    <ProgressIndicator />
                ) : (
                    <Stack.Item style={{ overflow: 'hidden' }} grow>
                        <SortableDetailsList<IRejection>
                            showGrid
                            selectionMode={SelectionMode.single}
                            compact
                            items={rejectionsData ?? []}
                            selection={rejectionsSelection as unknown as ISelection<IObjectWithKey>}
                            columns={[
                                { key: 'carc', name: 'CARC', minWidth: 75, maxWidth: 80, fieldName: 'carc' },
                                { key: 'rarc', name: 'RARC', minWidth: 120, maxWidth: 130, fieldName: 'rarc' },
                                { key: 'dos', name: 'DOS', minWidth: 120, maxWidth: 130, fieldName: 'dateOfService' },
                                { key: 'deniedDate', name: 'Denied Date', minWidth: 120, maxWidth: 130, fieldName: 'deniedDate' },
                                {
                                    key: 'encounterNumber',
                                    name: 'Encounter #',
                                    minWidth: 120,
                                    maxWidth: 130,
                                    fieldName: 'encounterId',
                                },
                                { key: 'loc', name: 'LOC', minWidth: 120, maxWidth: 130, fieldName: 'locationOfCareId' },
                                {
                                    key: 'treatingProvider',
                                    name: 'Treating Provider',
                                    minWidth: 120,
                                    fieldName: 'treatingProviderId',
                                },
                                { key: 'patientId', name: 'Patient ID', minWidth: 70, maxWidth: 70, fieldName: 'patientId' },
                                {
                                    key: 'claimBalance',
                                    name: 'Claim Balance',
                                    minWidth: 120,
                                    fieldName: 'claimBalance',
                                    onRender: (item) => usdCurrencyFormatter.format(item?.claimBalance ?? 0),
                                },
                                { key: 'currentPayer', name: 'Current Payer', minWidth: 120, fieldName: 'currentBalance' },
                                { key: 'billingOrder', name: 'Billing Order', minWidth: 120, fieldName: 'billingOrder' },
                                { key: 'firstFiledDate', name: 'First Filed Date', minWidth: 120, fieldName: 'firstFiledDate' },
                                { key: 'lastFiledDate', name: 'Last Filed Date', minWidth: 120, fieldName: 'lastFiledDate' },
                                {
                                    key: 'tags',
                                    name: 'Tag(s)',
                                    minWidth: 120,
                                    fieldName: 'tags',
                                    onRender: (item) => item?.tags?.join(', '),
                                },
                            ]}
                        />
                    </Stack.Item>
                )}
                {selectionRejection && <SelectedClaimRejection />}
            </Stack>
        </Stack>
    );
}
