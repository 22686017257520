import { useLocation } from 'react-router-dom';
import { validate } from 'uuid';

function usePatientId(): string | undefined {
    const { pathname } = useLocation();
    const [tenantId, page, patientId] = pathname.substring(1).split('/');
    const patientPages = ['scheduling', 'patient'];
    const isPatientPage = validate(patientId) && patientPages.indexOf(page) > -1;
    if (!isPatientPage) return;
    return patientId;
}
export default usePatientId;
