import { IDragOptions, ContextualMenu, Stack, Text } from '@fluentui/react';
import { TModal } from 'components';
import { IChartAction } from 'state/slices/charting/chartActionsList.pipeline';

type Props = {
    isOpen: boolean;
    onDismiss?: () => void;
    actions?: IChartAction[];
    onContinue?: (items: IChartAction[]) => void;
};

export default function ChartActionDeleteModal({ isOpen, onDismiss, actions: procedures, onContinue }: Props): JSX.Element {
    const dragOptions: IDragOptions = {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Cancel',
        menu: ContextualMenu,
        dragHandleSelector: '#Header',
    };

    return (
        <TModal
            title="Delete action"
            mainButtons={[
                {
                    type: 'PrimaryButton',
                    text: 'Continue',
                    onClick: () => {
                        if (onContinue && procedures) onContinue(procedures);
                    },
                },
                {
                    text: 'Cancel',
                    onClick: onDismiss,
                },
            ]}
            modalProps={{
                isOpen,
                onDismiss,
                dragOptions,
                isBlocking: true,
            }}
        >
            <Stack tokens={{ childrenGap: 10 }} styles={{ root: { padding: 10 } }} grow>
                <Text>Would you like to delete the selected action(s)?</Text>
            </Stack>
        </TModal>
    );
}
