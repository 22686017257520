import { Dictionary, PayloadAction } from '@reduxjs/toolkit';
import ILocationOfCare from 'api/models/Scheduling/locationsOfCare.model';
import IPatientAppointment from 'api/models/Scheduling/patientAppointment.model';
import IBlockAppointment from 'api/models/Scheduling/blockAppointment.model';
import { AppointmentType, SchedulingState } from './scheduling.state';
import { IUpcomingTreatmentPlanPhaseData } from './scheduling.selectors';
import { IPreviousAppointmentData } from './schedule-appointment/schedule-appointment.state';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { isArray } from 'lodash';
import { validate as validateIsId } from 'uuid';

const schedulingReducers = {
    setExpandSchedule: (state: SchedulingState, action: PayloadAction<boolean>): void => {
        state.ui.expandSchedule = action.payload;
    },
    setToolbarButtonIsDisabled: (state: SchedulingState, action: PayloadAction<boolean>): void => {
        state.ui.toolbarButtonIsDisabled = action.payload;
    },
    setPatientOverviewOpen: (state: SchedulingState, action: PayloadAction<boolean>): void => {
        const isOpen = action.payload;
        state.ui.setPatientOverviewOpen = isOpen;
        if (!isOpen) {
            state.selectedAppointment.data = undefined;
            state.selectedAppointment.patient = undefined;
        }
    },
    setIsCheckoutPanelOpen: (state: SchedulingState, action: PayloadAction<boolean>): void => {
        state.ui.isCheckoutPanelOpen = action.payload;
    },
    setSelectedCheckoutUpcomingPhases: (
        state: SchedulingState,
        action: PayloadAction<IUpcomingTreatmentPlanPhaseData[]>,
    ): void => {
        state.appointmentCheckout.selectedUpcomingPhases = action.payload;
    },
    setCheckoutError: (state: SchedulingState, action: PayloadAction<string | undefined>): void => {
        state.appointmentCheckout.error = action.payload;
    },
    setPreviousAppointmentData: (state: SchedulingState, action: PayloadAction<IPreviousAppointmentData | undefined>): void => {
        state.selectedAppointment.previousAppointmentData = action.payload;
    },
    cleanupCheckout: (state: SchedulingState): void => {
        state.appointmentCheckout.error = undefined;
        state.checkin.visibleSections = [];
        state.appointmentCheckout.selectedUpcomingPhases = [];
        state.appointmentCheckout.data = [];
        state.selectedAppointment.patient = undefined;
    },
    setSelectedDate: (state: SchedulingState, action: PayloadAction<Date>): void => {
        state.selectedDate = action.payload;
    },

    setSelectedLOC: (state: SchedulingState, action: PayloadAction<ILocationOfCare>): void => {
        state.selectedLOC = action.payload;
    },
    setUpdatePatientAppointment: (state: SchedulingState, action: PayloadAction<IPatientAppointment>): void => {
        state.selectedAppointment.data = action.payload;
    },
    updatePatientAppointmentProp: (
        state: SchedulingState,
        action: PayloadAction<{ path: keyof IPatientAppointment; value: unknown }>,
    ): void => {
        if (state.selectedAppointment.data)
            ((state.selectedAppointment.data as IPatientAppointment)[action.payload.path] as unknown) = action.payload.value;
    },
    updatePatientAppointmentNotes: (state: SchedulingState, action: PayloadAction<string | undefined>): void => {
        const notes = action.payload;
        if (state.selectedAppointment.data) {
            if (state.selectedAppointment.data?.notes) {
                state.selectedAppointment.data.notes = notes;
            } else {
                state.selectedAppointment.data['notes'] = notes;
            }
        }
    },
    cleanupChartTreatmentPlanPhaseProcedures: (state: SchedulingState): void => {
        state.treatmentPlans.data = undefined;
        state.selectedAppointment.data = undefined;
    },
    setDisableHIPAAView: (state: SchedulingState, action: PayloadAction<boolean>): void => {
        state.ui.disableHIPAAView = action.payload;
    },
    setPatientAllocationsDragged: (state: SchedulingState, action: PayloadAction<IPatientAppointment>): void => {
        if (state.allocations.draggedPatient.length) {
            state.allocations.draggedPatient = [...state.allocations.draggedPatient, action.payload];
        } else {
            state.allocations.draggedPatient = [action.payload];
        }
    },
    setBlockAllocationsDragged: (state: SchedulingState, action: PayloadAction<IBlockAppointment>): void => {
        if (state.allocations.draggedBlock.length) {
            state.allocations.draggedBlock = [...state.allocations.draggedBlock, action.payload];
        } else {
            state.allocations.draggedBlock = [action.payload];
        }
    },
    updatePatientAllocations: (state: SchedulingState, action: PayloadAction<IPatientAppointment>): void => {
        if (state.allocations.data?.patients) {
            state.allocations.data.patients = [
                ...state.allocations.data.patients.filter((appointment) => appointment.id !== action.payload.id),
                action.payload,
            ];
        }
    },
    updateBlockAllocation: (state: SchedulingState, action: PayloadAction<IBlockAppointment>): void => {
        if (state.allocations.data?.blocks) {
            state.allocations.data.blocks = [
                ...state.allocations.data.blocks.filter((b) => b.id !== action.payload.id),
                action.payload,
            ];
        }
    },
    setAppointmentType: (state: SchedulingState, action: PayloadAction<AppointmentType | undefined>): void => {
        state.selectedAppointmentType = action.payload;
    },
    setIsAppointmentPanelOpen: (state: SchedulingState, action: PayloadAction<boolean>): void => {
        state.ui.isScheduleAppointmentPanelOpen = action.payload;
    },
    cleanupPaymentInformation: (): void => {
        // state.selectedTreatmentPlanPhases.data = undefined;
    },
    setAlertDialogMessage: (state: SchedulingState, action: PayloadAction<string | undefined>): void => {
        state.alertDialogMessage = action.payload;
    },
    cleanupLocationsOfCare: (state: SchedulingState): void => {
        state.locationsOfCare.data = undefined;
        state.locationsOfCare.loading = LoadingStatus.Idle;
    },
    setSelectedOperatories: (state: SchedulingState, action: PayloadAction<Record<string, string[]>>): void => {
        state.selectedOperatories = action.payload;
    },
    setSelectedOperatoryTags: (state: SchedulingState, action: PayloadAction<Dictionary<Dictionary<string[]>>>): void => {
        state.selectedOperatoryTags = action.payload;
    },
    toggleFilterOperatory: (
        state: SchedulingState,
        action: PayloadAction<{ tenantId: string; operatoryId: string; locationOfCareId?: string }>,
    ): void => {
        const { operatoryId, tenantId, locationOfCareId } = action.payload;

        const isId = validateIsId(operatoryId);

        if (isId) {
            if (!state.selectedOperatories[tenantId]) state.selectedOperatories[tenantId] = [];
            if ((state.selectedOperatories[tenantId] as string[])?.includes(operatoryId)) {
                state.selectedOperatories[tenantId] = (state.selectedOperatories[tenantId] as string[]).filter(
                    (id) => id !== operatoryId,
                );
            } else {
                state.selectedOperatories[tenantId] = [...(state.selectedOperatories[tenantId] as string[]), operatoryId];
            }
        } else {
            // If operatoryId is not an id we assume it is a tag

            if (locationOfCareId) {
                if (!state.selectedOperatoryTags[tenantId]) state.selectedOperatoryTags[tenantId] = {};

                if (state.selectedOperatoryTags[tenantId]) {
                    if (!(state.selectedOperatoryTags[tenantId] as Dictionary<string[]>)[locationOfCareId])
                        (state.selectedOperatoryTags[tenantId] as Dictionary<string[]>)[locationOfCareId] = [];

                    if (
                        (state.selectedOperatoryTags[tenantId] as Dictionary<string[]>)[locationOfCareId]?.includes(operatoryId)
                    ) {
                        (state.selectedOperatoryTags[tenantId] as Dictionary<string[]>)[locationOfCareId] = (
                            state.selectedOperatoryTags[tenantId] as Dictionary<string[]>
                        )[locationOfCareId]?.filter((id) => id !== operatoryId);
                    } else {
                        (state.selectedOperatoryTags[tenantId] as Dictionary<string[]>)[locationOfCareId] = (
                            state.selectedOperatoryTags[tenantId] as Dictionary<string[]>
                        )[locationOfCareId] = [
                            ...((state.selectedOperatoryTags[tenantId] as Dictionary<string[]>)[locationOfCareId] ?? []),
                            operatoryId,
                        ];
                    }
                }
            }
        }
    },
};

export default schedulingReducers;
