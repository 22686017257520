import IWorkListView from 'api/models/worklist-view.model';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import UserDisplayName from 'components/UserDisplayName';

const billingProvider: ISortableColumn<IWorkListView> = {
    key: 'billingProviderFullName',
    name: 'Billing Provider',
    fieldName: 'billingProviderFullName',
    minWidth: 100,
    maxWidth: 150,
    onRender: (item) => <UserDisplayName userId={item?.billingProviderId} />,
};

export default billingProvider;
