import { useTheme, Stack, TooltipHost, DirectionalHint, Icon, Shimmer, Text } from '@fluentui/react';
import { useSelector } from 'react-redux';
import { selectEditPatient } from 'state/slices/edit-patient/edit-patient.selectors';

export type PatientDetailsLabelProps = {
    label: string;
    value?: string;
    labelWidth?: number;
    tooltip?: { content?: string | JSX.Element; iconName?: string };
};

export function PatientDetailsLabel({ label, value: text, labelWidth = 120, tooltip }: PatientDetailsLabelProps) {
    const theme = useTheme();
    const editPatientLoading = useSelector(selectEditPatient);
    return (
        <Stack
            horizontal
            verticalAlign="center"
            style={{ paddingLeft: 10, borderTop: `1px solid ${theme.semanticColors.bodyDivider}` }}
        >
            <Stack style={{ width: labelWidth }} horizontal verticalAlign="center" tokens={{ childrenGap: 5 }}>
                <Text variant="smallPlus">{label}</Text>
                {tooltip?.content && (
                    <Text variant="mediumPlus">
                        <TooltipHost
                            content={tooltip.content}
                            styles={{
                                root: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
                            }}
                            directionalHint={DirectionalHint.rightCenter}
                        >
                            <Icon
                                style={{ cursor: 'help', color: theme.palette.themePrimary }}
                                iconName={tooltip.iconName ?? 'Info'}
                            />
                        </TooltipHost>
                    </Text>
                )}
            </Stack>
            {!editPatientLoading ? (
                <Stack style={{ height: 23 }} verticalAlign="center" grow={1}>
                    <Shimmer width="100%" height={13} />
                </Stack>
            ) : (
                <Text variant="mediumPlus">{text && text !== '' ? text : `${'\u00A0'}`}</Text>
            )}
        </Stack>
    );
}
