import {
    DefaultButton,
    IComboBoxOption,
    MessageBar,
    MessageBarType,
    Panel,
    PanelType,
    PrimaryButton,
    SelectionMode,
    Separator,
    Stack,
    Text,
    TooltipHost,
} from '@fluentui/react';
import { DentitionMode, IChartDentition } from 'api/models/chart-dentition.model';
import { IChartCondition } from 'api/models/chart.model';
import { ApplicableArea } from 'api/models/lookup.model';
import { Field, SortableDetailsList } from 'components';
import { useChartDentitions, useConditionsPanel, useSelector } from 'hooks';
import useConditions from 'hooks/store/useConditions';
import { map, sortBy } from 'lodash';
import { SurfaceControls, ToothLabel } from 'pages/components';
import PanelSectionHeader from 'pages/components/PanelSectionHeader';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { chartingActions } from 'state/slices/charting/chart/chart.slice';
import { selectNewPanelConditions } from 'state/slices/charting/condition-panel/condition-panel.selectors';
import { getShorthandToothAreas } from 'utils';
import Movements from '../../../components/Movements/Movements';
import DebouncedTextField from '../ProcedureMenu/DebouncedTextField';
import ToothSelector from '../ProcedureMenu/ToothSelector';
import { toothSpriteReferences } from '../ToothCanvas/spriteList';

export default function ConditionsPanel(): JSX.Element {
    const { conditionsAsList, selectConditionsData } = useConditions();
    const {
        condition,
        isOpen,
        isEditing,
        selectedTeeth,
        note,
        setConditionPanelOpen,
        setConditionPanelSelectedTeeth,
        setCondition,
        panelTeethData,
        selectConditionValidationErrors,
        setConditionPanelSelectedSurfaces,
        cleanupConditionPanel,
        handleConditionPanelSave,
    } = useConditionsPanel();

    const _selectNewPanelConditions = useSelector(selectNewPanelConditions);

    const { updateChartDentitions, createChartDentitions, dentitions: dentitionsAsList } = useChartDentitions();

    const [dentitions, setDentitions] = useState<IChartDentition[]>([]);
    const dispatch = useDispatch();

    const conditionsOptions: IComboBoxOption[] = sortBy(
        conditionsAsList.map((c) => ({
            key: c.id,
            text: `${c.displayName}${c.description ? ` - ${c.description}` : ''}`,
        })),
        ['text'],
    );

    function onChangeMovements(_dentitions: IChartDentition[]) {
        setDentitions(_dentitions);
    }

    function onChangeConditionOption(ev: any, option: IComboBoxOption | undefined) {
        if (option) {
            setCondition(selectConditionsData[option.key as string]);
        }
    }

    function onSave() {
        if (_selectNewPanelConditions.length) handleConditionPanelSave(_selectNewPanelConditions);
        //* Realizing this will need to change in the future. If a condition is not added due to a conflict rule
        //* we don't want to update the dentition.
        if (dentitions.length) {
            const filteredDentitionsList = dentitionsAsList.filter((d) => !d.isDeleted);
            const dentitionsToUpdate = dentitions.filter((dentition) => filteredDentitionsList.includes(dentition));
            const dentitionsToCreate = dentitions.filter((dentition) => !filteredDentitionsList.includes(dentition));
            if (dentitionsToUpdate.length) updateChartDentitions(dentitionsToUpdate);
            if (dentitionsToCreate.length)
                createChartDentitions(dentitionsToCreate.map((d) => ({ ...d, dentitionMode: 'Permanent' })));
        }
        onDismiss();
    }

    function onDismiss() {
        setConditionPanelOpen(false);
    }

    function onDismissed() {
        cleanupConditionPanel();
        setConditionPanelSelectedTeeth([]);
    }

    const setActionNotes = (value?: string) => {
        dispatch(chartingActions.setCondPanelNotes(value));
    };

    const showSurfaces = condition?.applicableArea ? condition?.applicableArea.indexOf(ApplicableArea.Surface) > -1 : false;
    const showTranslation = condition?.applicableArea ? condition?.applicableArea.indexOf(ApplicableArea.Translate) > -1 : false;
    const showRotations = condition?.applicableArea ? condition?.applicableArea.indexOf(ApplicableArea.Rotate) > -1 : false;
    const showWatch = condition?.renderRule === 'watch';
    const saveDisabled = selectConditionValidationErrors.length ? true : false || !condition;
    const headerText = isEditing ? 'Edit condition' : 'Add condition';
    const items = map(panelTeethData, (p, tooth) => ({ ...p, tooth }));

    const teethSurfaces = showSurfaces
        ? items.map((data, index) => {
              const tooth = +data.tooth;

              return (
                  <Stack key={`tooth-diag-${condition?.id}-${index}`} tokens={{ childrenGap: 5 }} grow>
                      <Stack tokens={{ childrenGap: 10 }} verticalAlign="center" horizontal grow>
                          <Stack.Item>
                              <ToothLabel items={tooth} />
                          </Stack.Item>
                          {showSurfaces && (
                              <Stack tokens={{ childrenGap: 10 }}>
                                  <SurfaceControls
                                      surfaces={data?.surfaces}
                                      setSurfaces={(surfaces) => setConditionPanelSelectedSurfaces({ tooth, surfaces })}
                                      toothId={tooth}
                                  />
                              </Stack>
                          )}
                      </Stack>
                      <Separator />
                  </Stack>
              );
          })
        : null;

    return (
        <Panel
            headerText={headerText}
            isOpen={isOpen}
            onDismiss={onDismiss}
            onDismissed={onDismissed}
            type={PanelType.medium}
            isFooterAtBottom={true}
            styles={{
                content: { overflowY: 'auto', overflowX: 'hidden', flex: 1 },
                root: { overflow: 'hidden' },
                scrollableContent: { overflow: 'hidden', display: 'flex', flexDirection: 'column' },
            }}
            onRenderFooterContent={() => (
                <Stack tokens={{ childrenGap: 10 }}>
                    <ConflictMessages />
                    <Stack horizontal tokens={{ childrenGap: 12 }}>
                        <PrimaryButton onClick={onSave} text="Save" disabled={saveDisabled} />
                        <DefaultButton text="Cancel" onClick={onDismiss} />
                    </Stack>
                </Stack>
            )}
        >
            <Stack tokens={{ childrenGap: 10 }} grow>
                <Field.SearchCombo
                    label="Condition"
                    options={conditionsOptions}
                    selectedKey={condition?.id}
                    onChange={onChangeConditionOption}
                    maxResults={100}
                />
                <Stack>
                    <PanelSectionHeader text={'Select teeth'} />
                    <ToothSelector
                        multiselect={!isEditing}
                        selectedTeeth={selectedTeeth}
                        onChange={setConditionPanelSelectedTeeth}
                    />
                </Stack>
                {showSurfaces && selectedTeeth.length && <PanelSectionHeader text={`Select Surfaces`} />}
                {teethSurfaces}
                {(showRotations || showTranslation) && (
                    <Movements
                        onChange={onChangeMovements}
                        showTranslation={showTranslation}
                        showRotation={showRotations}
                        selectedTeeth={selectedTeeth}
                    />
                )}
                {showWatch && <DebouncedTextField label="Note" multiline defaultValue={note} onValueChange={setActionNotes} />}
                <PanelSectionHeader text="Results" isPrimary />
                <SortableDetailsList<IChartCondition>
                    items={_selectNewPanelConditions}
                    selectionMode={SelectionMode.none}
                    compact
                    columns={[
                        {
                            key: 'name',
                            minWidth: 100,
                            maxWidth: 150,
                            name: 'Condition',
                            fieldName: 'condition',
                            onRender: (item) =>
                                item?.conditionId ? (
                                    <span>
                                        {selectConditionsData[item.conditionId]?.displayName} -{' '}
                                        {selectConditionsData[item.conditionId]?.description}
                                    </span>
                                ) : null,
                        },
                        {
                            key: 'tooth',
                            minWidth: 100,
                            maxWidth: 120,
                            name: 'Tooth',
                            onRender: (item) => (
                                <Text>
                                    {item?.toothId
                                        ? toothSpriteReferences.find((tooth) => tooth.id === item.toothId)?.displayName
                                        : null}
                                </Text>
                            ),
                        },
                        {
                            key: 'areas',
                            minWidth: 100,
                            name: 'Surfaces',
                            onRender: (item) => (
                                <TooltipHost delay={0} content={item?.areas?.join(', ')}>
                                    <Text>{getShorthandToothAreas(item?.areas)}</Text>
                                </TooltipHost>
                            ),
                        },
                    ]}
                    sortOnMount={true}
                    sortColumns={['condition']}
                />
            </Stack>
        </Panel>
    );
}

function ConflictMessages(): JSX.Element {
    const { selectConditionValidationErrors } = useConditionsPanel();

    const _conflictMessages = selectConditionValidationErrors.length
        ? selectConditionValidationErrors.map((conflict, index) => <li key={`conflic-${index}`}>{conflict}</li>)
        : null;
    const classNames = _conflictMessages?.length ? 'ms-motion-fadeIn' : 'ms-motion-fadeOut';
    return (
        <Stack>
            <MessageBar className={classNames} messageBarType={MessageBarType.blocked}>
                <ul style={{ margin: 0 }}>{_conflictMessages}</ul>
            </MessageBar>
        </Stack>
    );
}
