import { CommandBar, Dropdown, IDropdownOption, ScrollablePane, SearchBox, Separator, Stack } from '@fluentui/react';
import { ReportType } from 'api/models/embed-report.model';
import { IEncounterSummary } from 'api/models/encounter-ledger.model';
import { useSelector } from 'hooks';
import { RouteParams } from 'interfaces/route-params';
import { isNaN, map } from 'lodash';
import { FormEvent, useMemo, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectEncounterLedgerFilters, selectFilteredEncounterLedgerTotalsView, selectEncounterLedgerTotalsViewDisplay } from 'state/slices/ledger/ledger.selectors';
import { setEncounterLedgerSummaryFilter, setFilteredEncounterSummaries } from 'state/slices/ledger/ledger.slice';
import { EncounterStatusFilterOptions } from 'state/slices/ledger/ledger.state';
import reportActionLookup from 'state/slices/reporting/reportActionLookup';
import { usdCurrencyFormatter } from 'utils';
import { classicDateOnly } from 'utils/dateOnly';
import EncounterLedger from './EncounterLedger';

const encounterStatusFilterOptions: IDropdownOption[] = map(EncounterStatusFilterOptions, (option) => ({
    key: option,
    text: option,
}));

export default function LedgerView() {
    const dispatch = useDispatch();
    const { tenantId, patientId } = useParams<RouteParams>();


    const _selectEncounterLedgerTotalsView = useSelector(selectFilteredEncounterLedgerTotalsView);
    const _selectEncounterLedgerTotalsViewDisplay = useSelector(selectEncounterLedgerTotalsViewDisplay);

    const { encounterBillingStatus } = useSelector(selectEncounterLedgerFilters);

    const [search, setSearch] = useState<string>();

    const results = useMemo(() => {
        const lowercaseSearch = search?.toLowerCase();

        function filterByProp(encounter: IEncounterSummary, prop: keyof IEncounterSummary) {
            if (lowercaseSearch && encounter[prop])
                return (encounter[prop] as string | number).toString().toLowerCase().startsWith(lowercaseSearch);
            return false;
        }

        function filterProvider(encounter: IEncounterSummary) {
            if (lowercaseSearch && isNaN(+lowercaseSearch) && encounter.provider) {
                return encounter.provider.toLowerCase().indexOf(lowercaseSearch) > -1;
            }
            return false;
        }

        function filterLocationOfCare(encounter: IEncounterSummary) {
            if (lowercaseSearch && isNaN(+lowercaseSearch) && encounter.locationOfCare) {
                return filterByProp(encounter, 'locationOfCare');
            }
            return false;
        }

        function filterEncounterNumber(encounter: IEncounterSummary) {
            if (lowercaseSearch && !isNaN(+lowercaseSearch) && encounter.encounterNumber) {
                return filterByProp(encounter, 'encounterNumber');
            }
            return false;
        }

        function filterDateOfService(encounter: IEncounterSummary) {
            if (lowercaseSearch && encounter.dateOfService){
                return classicDateOnly(encounter.dateOfService).startsWith(lowercaseSearch);
            }
            return false;
        }

        function filterPatientBalance(encounter: IEncounterSummary) {
            if (lowercaseSearch?.length && lowercaseSearch.charAt(0) === '$' && encounter.patientBalance !== undefined)
                return usdCurrencyFormatter.format(encounter.patientBalance).startsWith(lowercaseSearch);
            return false;
        }

        if (lowercaseSearch) {
            return _selectEncounterLedgerTotalsView.filter((encounter) => {
                return (
                    filterProvider(encounter) ||
                    filterDateOfService(encounter) ||
                    filterEncounterNumber(encounter) ||
                    filterLocationOfCare(encounter) ||
                    filterPatientBalance(encounter)
                );
            });
        }

        return [];
    }, [search, _selectEncounterLedgerTotalsView]);

    const _onSearchChange = (ev?: React.ChangeEvent, value?: string) => {
        setSearch(value ?? '');
    };

    useEffect(() => {
        return () => {
            dispatch(
                setEncounterLedgerSummaryFilter({
                    path: 'encounterBillingStatus',
                    value: EncounterStatusFilterOptions.All
                }),
            )
            dispatch(setFilteredEncounterSummaries(undefined))
        }
    }, [])

    //Ensure that we set the filtered encounter summaries. This is so the overall patient balance display takes into account the fuse search.
    useEffect(() => {
        if (search && results.length) {
            dispatch(setFilteredEncounterSummaries(results))
        } else {
            dispatch(setFilteredEncounterSummaries(undefined))
        }
    }, [search])

    const _onEncounterStatusFilterChange = (ev?: FormEvent, option?: IDropdownOption) => {
        if (option)
            dispatch(
                setEncounterLedgerSummaryFilter({
                    path: 'encounterBillingStatus',
                    value: option.key as EncounterStatusFilterOptions,
                }),
            );
    };

    return (
        <Stack grow>
            <CommandBar
                styles={{ root: { paddingLeft: 0 } }}
                items={[
                    {
                        key: 'print-invoice',
                        iconProps: { iconName: 'Print' },
                        text: 'Print Invoice',
                        onClick: () => {
                            if (patientId) {
                                dispatch(
                                    reportActionLookup[ReportType.PatientStatement]({
                                        tenantId,
                                        patientId,
                                    }),
                                );
                            }
                        },
                    },
                    {
                        key: 'divider',
                        onRender: () => <Separator styles={{ root: { paddingLeft: 20, paddingRight: 20 } }} vertical />,
                    },
                    {
                        key: 'filters',
                        onRender: () => (
                            <Stack horizontal tokens={{ childrenGap: 10 }} style={{ display: 'flex', alignItems: 'center' }}>
                                <SearchBox
                                    styles={{ root: { width: 375 } }}
                                    value={search}
                                    onChange={_onSearchChange}
                                    placeholder={'Search by Enc. #, LOC, Provider, Patient Balance, or DOS'}
                                />
                            </Stack>
                        ),
                    },
                ]}
            />
            <Stack styles={{ root: { padding: 5 } }} horizontal>
                <Dropdown
                    styles={{ root: { width: 120 }, label: { padding: 0 } }}
                    options={encounterStatusFilterOptions}
                    selectedKey={encounterBillingStatus}
                    label="Approval Status"
                    onChange={_onEncounterStatusFilterChange}
                />
            </Stack>
            <div style={{ position: 'relative', flex: 1, display: 'flex' }}>
                <ScrollablePane>
                    <EncounterLedger views={_selectEncounterLedgerTotalsViewDisplay} />
                </ScrollablePane>
            </div>
        </Stack>
    );
}
