import { AnyAction, createAsyncThunk, ThunkDispatch } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import IPatientAppointment, { IAppointmentProcedure } from 'api/models/Scheduling/patientAppointment.model';
import IUserTask from 'api/models/user-task.model';
import schedulingApi from 'api/scheduling.api';
import { AxiosError } from 'axios';
import { push } from 'connected-react-router';
import ErrorTypes from 'state/errorTypes';
import { AppThunk, RootState } from 'state/store';
import { v4 as uuid } from 'uuid';
import { calculateAgeInYears } from 'utils/dateOnly';
import IForm, { FormName, FormStatus } from 'api/models/form';
import forms from 'forms/forms';
import { setSelectedForm } from 'state/slices/forms/forms.slice';
import { onStartEncounter } from 'state/slices/clinical-huddle/clinical-huddle.actions';
import {
    getAppointmentPatient,
    setAppointmentType,
    setIsAppointmentPanelOpen,
    setIsCheckoutPanelOpen,
    setSchedulePatientAppointment,
    setPreviousAppointmentData,
    setSelectedDate,
} from 'state/slices/scheduling/scheduling.slice';
import { AppointmentType } from 'state/slices/scheduling/scheduling.state';
import {
    IUpcomingTreatmentPlanPhaseData,
    selectSelectedAppointmentData,
    selectSelectedAppointmentPatient,
    selectSelectedDate,
} from 'state/slices/scheduling/scheduling.selectors';
import convertDashedDateString from 'utils/convertDateStringToLocal';
import { selectOperatoriesAsList } from 'state/slices/lookups/operatories/operatories.selectors';
import { TaskGroup } from 'api/models/user-tasks-by-group';

export enum TaskTargets {
    PatientOverview = 'patient-overview',
    PatientCheckout = 'patient-checkout',
    AdminHuddle = 'admin-huddle',
    PatientBasicInfo = 'patient-basic-info',
    //Patient UDS
    patientUdsSexualOrientation = 'patient-uds-sexualOrientation',

    patientUdsHomelessStatusValue = 'patient-uds-homelessStatusValue',
    patientUdsHomelessStatusType = 'patient-uds-homelessStatusType',
    patientUdsHomelessStatus = 'patient-uds-homelessStatus',

    patientUdsPronoun = 'patient-uds-pronoun',
    patientUdsGenderIdentity = 'patient-uds-genderIdentity',
    patientUdsVeteranStatus = 'patient-uds-veteranStatus',
    patientUdsSchoolBasedHealth = 'patient-uds-schoolBasedHealth',
    patientUdsAgriculturalWorkerType = 'patient-uds-agriculturalWorkerType',
    patientUdsAgriculturalWorker = 'patient-uds-agriculturalWorker',
    patientUdsIncome = 'patient-uds-income',
    patientUdsFamilySize = 'patient-uds-familySize',
    //Patient Financial
    PatientFinancialInsuranceEligibility = 'patient-financial-insuranceEligibility',
    //Non specific insurance task.
    PatientFinancialPrivacyNotice = 'patient-financial-privacyNotice',
    PatientFinancialPrivacyNoticeDate = 'patient-financial-privacyNoticeDate',
    PatientFinancialBillingRelease = 'patient-financial-billingRelease',

    PatientFinancialBillingReleaseEffectiveDate = 'patient-financial-billingReleaseEffectiveDate',
    PatientFinancialBenefitAssignmentEffectiveDate = 'patient-financial-benefitAssignmentEffectiveDate',

    PatientFinancialBillingReleaseExpiration = 'patient-financial-billingReleaseExpiration',
    PatientFinancialBenefitAssignment = 'patient-financial-benefitAssignment',
    PatientFinancialBenefitAssignmentExpiration = 'patient-financial-benefitAssignmentExpiration',
    PatientFinancialSignatureSource = 'patient-financial-signatureSource',
    //Insurance specific
    PatientFinancialPayerSelection = 'patient-financial-payerSelection',
    PatientFinancialPatientInsuranceType = 'patient-financial-patientInsuranceType',
    PatientFinancialPatientInsurancePlan = 'patient-financial-patientInsurancePlan',
    PatientFinancialPatientInsuranceMemberId = 'patient-financial-patientInsuranceMemberId',
    PatientFinancialPatientInsuranceGroupNumber = 'patient-financial-patientInsuranceGroupNumber',
    PatientFinancialEffectiveDate = 'patient-financial-patientInsuranceEffectiveDate',

    appointmentFinancialInsuranceVerification = 'appointment-financial-insuranceVerification',
}

export enum TaskType {
    //Appointment
    ScheduleNextAppointment = 'schedule-next-appointment',
    //Amend
    Amendment = 'encounter-amend-required',
    // Patient Overview
    CreateEncounter = 'create-encounter',
    CarriesRiskAssessmentVerification = 'carriesRiskAssessment-verification',
    SexAssignedAtBirthVerification = 'sexAssignedAtBirth-verification',
    LegalSexVerification = 'legalSex-verification',
    SexualOrientationVarification = 'sexualOrientation-verification',
    PronounVerification = 'pronoun-verification',
    GenderIdentityVerification = 'genderIdentity-verification',
    VeteranStatusVerification = 'veteranStatus-verification',
    SchoolBasedHealthVerification = 'schoolBasedHealth-verification',
    HomelessStatusVerification = 'homelessStatus-verification',
    HomelessStatusValueVerification = 'homelessStatusValue-verification',
    AgriculturalWorkerVerification = 'agriculturalWorker-verification',
    RaceVerification = 'race-verification',
    EthnicityVerification = 'ethnicity-verification',
    //Income Tasks
    IncomeVerificationDropdown = 'income-verification-dropdown',
    IncomeInputVerification = 'income-input-verification',
    IncomeVerification = 'income-verification',
    IncomeLastUpdatedVerification = 'income-last-updated-verification',

    FamilySizeVerification = 'familySize-verification',

    ResidentialAddressVerification = 'residentialAddress-verification',
    MailingAddressVerification = 'mailingAddress-verification',
    PrimaryPhoneNumberVerification = 'primaryPhoneNumber-verification',

    //Patient Financial
    InsuranceEligibilityVerification = 'insuranceEligibility-verification',
    privacyNoticeVerification = 'privacyNotice-verification',
    privacyNoticeDateVerification = 'privacyNoticeDate-verification',
    insuranceVerification = 'insurance-verification',
    billingReleaseVerification = 'billingRelease-verification',
    billingReleaseEffectiveDateVerification = 'billingReleaseEffectiveDate-verification',
    billingReleaseExpirationVerification = 'billingReleaseExpiration-verification',
    benefitAssignmentVerification = 'benefitAssignment-verification',
    benefitAssignmentEffectiveDateVerification = 'benefitAssignmentEffectiveDate-verification',
    benefitAssignmentExpirationVerification = 'benefitAssignmentExpiration-verification',
    signatureSourceVerification = 'signatureSource-verification',
    //Insurance specific
    payerSelectionVerification = 'payerSelection-verification',
    patientInsuranceTypeVerification = 'patientInsuranceType-verification',
    patientInsuranceGroupNumberVerification = 'patientInsuranceGroupNumber-verification',
    patientFinancialEffectiveDate = 'patientInsuranceEffectiveDate-verification',
}

export const appointmentTasks = [
    TaskType.IncomeVerification,
    TaskType.HomelessStatusVerification,
    TaskType.PrimaryPhoneNumberVerification,
    TaskType.MailingAddressVerification,
    TaskType.ResidentialAddressVerification,
    TaskType.insuranceVerification,
];

export const patientOverviewQuestionnaireTasks = [TaskType.CarriesRiskAssessmentVerification];
export const patientOverviewQuestionnaireTaskLookupByForm: Partial<Record<FormName, TaskType>> = {
    [FormName.CariesRiskAdult]: TaskType.CarriesRiskAssessmentVerification,
    [FormName.CariesRiskChild]: TaskType.CarriesRiskAssessmentVerification,
};

interface CreateEncounterTask extends IUserTask {
    references: {
        tenantId: string;
        providerId: string;
        patientId: string;
        appointmentId: string;
    };
}

interface CarriesRiskAssessmentTask extends IUserTask {
    references: {
        tenantId: string;
        encounterId: string;
        patientId: string;
    };
}
interface SchedulePatientAppointmentTask extends IUserTask {
    references: {
        tenantId: string;
        patientId: string;
        returnDate: string;
        chartTreatmentPlanPhaseId: string;
    };
}

export const PatientBasicInfoTarget = [
    TaskTargets.patientUdsSexualOrientation,
    TaskTargets.patientUdsPronoun,
    TaskTargets.patientUdsGenderIdentity,
    TaskTargets.patientUdsVeteranStatus,
    TaskTargets.patientUdsSchoolBasedHealth,
    TaskTargets.patientUdsHomelessStatusType,
    TaskTargets.patientUdsHomelessStatus,
    TaskTargets.patientUdsAgriculturalWorkerType,
    TaskTargets.patientUdsAgriculturalWorker,
    TaskTargets.PatientBasicInfo,
    TaskTargets.patientUdsHomelessStatusValue,
];

export const PayersTaskTargets = [
    TaskTargets.PatientFinancialPrivacyNotice,
    TaskTargets.PatientFinancialPrivacyNoticeDate,

    TaskTargets.PatientFinancialBillingReleaseEffectiveDate,

    TaskTargets.PatientFinancialBillingRelease,
    TaskTargets.PatientFinancialBillingReleaseExpiration,
    TaskTargets.PatientFinancialBillingReleaseEffectiveDate,

    TaskTargets.PatientFinancialBenefitAssignment,
    TaskTargets.PatientFinancialBenefitAssignmentEffectiveDate,
    TaskTargets.PatientFinancialBenefitAssignmentExpiration,

    TaskTargets.PatientFinancialSignatureSource,
    TaskTargets.PatientFinancialInsuranceEligibility,
    //Appointment specific
    TaskTargets.appointmentFinancialInsuranceVerification,

    //Insurance specific
    TaskTargets.PatientFinancialPayerSelection,
    TaskTargets.PatientFinancialPatientInsuranceType,
    TaskTargets.PatientFinancialPatientInsurancePlan,
    TaskTargets.PatientFinancialPatientInsuranceMemberId,
    TaskTargets.PatientFinancialPatientInsuranceGroupNumber,
    TaskTargets.PatientFinancialEffectiveDate,
];

export const FinanceTaskTargets = [TaskTargets.patientUdsIncome, TaskTargets.patientUdsFamilySize];

//Each view will know what task targets it needs to fetch.

export const getPatientTasksByGroup = createAsyncThunk<
    IUserTask[],
    { tenantId: string; patientId: string; group: TaskGroup; references?: Record<string, string> },
    { rejectValue: string }
>('taskManagement/getPatientTasksByGroup', async ({ tenantId, patientId, group, references }, { rejectWithValue }) => {
    try {
        const response = await dentalApi.getTasksByGroupForPatient(tenantId, patientId, group, references);
        return response.data;
    } catch (err: unknown) {
        const error = err as AxiosError;
        if (error.response && error.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(error.toString());
        }
    }
});

export const getTaskByEncounter = createAsyncThunk<
    IUserTask[],
    { tenantId: string; patientId: string; encounterId: string },
    { rejectValue: string }
>('taskManagement/getTaskByEncounter', async ({ patientId, encounterId, tenantId }, { rejectWithValue }) => {
    try {
        const response = await dentalApi.getTasksByReferences(tenantId, { patientId, encounterId });
        return response.data;
    } catch (err: unknown) {
        const error = err as AxiosError;
        if (error.response && error.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue(error.toString());
        }
    }
});

const taskManagementActions: { [key in TaskType]?: (task: IUserTask, extraParams?: unknown) => AppThunk<void> } = {
    [TaskType.CreateEncounter]:
        (task: IUserTask): AppThunk<void> =>
        async (dispatch, getState) => {
            const { appointmentId, patientId, tenantId } = (task as CreateEncounterTask).references;

            const operatories = selectOperatoriesAsList(getState(), tenantId);

            const { data: appointment } = await schedulingApi.getPatientAppointment(tenantId, appointmentId);
            const locationOfCareId = operatories?.find((operatory) => operatory.id === appointment.operatoryId)?.locationOfCareId;

            if (locationOfCareId)
                dispatch(onStartEncounter({ tenantId, patientId, appointmentId: appointment.id, locationOfCareId, appointment }));
        },
    [TaskType.CarriesRiskAssessmentVerification]:
        (task: IUserTask): AppThunk<void> =>
        async (dispatch, getState) => {
            const patient = getState().patient;
            const patientAge = calculateAgeInYears(patient.selectedPatient?.dateOfBirth);
            const patientIsChild = patientAge <= 6;
            const formName = patientIsChild ? FormName.CariesRiskChild : FormName.CariesRiskAdult;
            const { encounterId, patientId, tenantId } = (task as CarriesRiskAssessmentTask).references;
            const formData = forms[formName];

            const model: IForm = {
                appointmentId: '',
                formName,
                patientId,
                encounterId,
                id: uuid(),
                status: FormStatus.Pending,
                isDeleted: false,
                data: formData.questions,
            };

            dispatch(setSelectedForm(model));
            dispatch(push(`/${tenantId}/patient/${patientId}/encounter/${encounterId}`));
            dispatch({ type: 'SET_PATIENT_AGE', age: patientAge });
        },
    [TaskType.ScheduleNextAppointment]:
        (task: IUserTask, extraParams): AppThunk<void> =>
        async (dispatch, getState) => {
            const locationOfCareId = getState().scheduling.selectedLOC?.id;
            const operatoryId = getState().scheduling.selectedAppointment.data?.operatoryId;

            const { patientId, returnDate, tenantId } = (task as SchedulePatientAppointmentTask).references;
            const { phaseProcedures } = extraParams as IUpcomingTreatmentPlanPhaseData;

            if (locationOfCareId && operatoryId) {
                const apptData = selectSelectedAppointmentData(getState());
                const patient = selectSelectedAppointmentPatient(getState());
                const currentDate = selectSelectedDate(getState());
                if (apptData && patient && currentDate)
                    await dispatch(setPreviousAppointmentData({ data: apptData, patient, date: currentDate }));

                const date = new Date(convertDashedDateString(returnDate));

                const procedures: IAppointmentProcedure[] = phaseProcedures.map((proc) => ({
                    code: proc.procedureCode,
                    procedureId: proc.procedureId,
                    toothIds: proc.toothIds,
                    treatmentPlanPhaseProcedureId: proc.id,
                }));

                const appointment: Omit<IPatientAppointment, 'startTime' | 'endTime' | 'treatingProviderId'> = {
                    id: uuid(),
                    isDeleted: false,
                    locationOfCareId,
                    patientId,
                    date: returnDate,
                    operatoryId,
                    procedures,
                };

                await dispatch(setSelectedDate(date));
                await dispatch(setIsCheckoutPanelOpen(false));
                await dispatch(getAppointmentPatient({ tenantId, patientId }));

                dispatch(setAppointmentType(AppointmentType.Patient));
                dispatch(setSchedulePatientAppointment(appointment));
                dispatch(setIsAppointmentPanelOpen(true));
            }
        },
};

export const executeTask =
    (task: IUserTask, extraParams?: unknown) =>
    async (dispatch: ThunkDispatch<RootState, null, AnyAction>): Promise<void> => {
        if (task.type) {
            const action = taskManagementActions[task.type];
            if (action) dispatch(action(task, extraParams));
        }
    };

export default taskManagementActions;
