import { ITag, Label, Stack, TagPicker, useTheme } from '@fluentui/react';
import dentalApi from 'api/dental.api';
import { filter } from 'lodash';
import { useCallback } from 'react';

type Props = {
    label?: string;
    onChange?: (tags: ITag[] | undefined) => void;
    selectedItems: ITag[];
};

/**Carc Rarc Picker field for searching and picking multiple carc rarc codes. */
export default function CarcRarcPicker({ label, onChange, selectedItems }: Props) {
    const { palette } = useTheme();

    /** Search carc rarcs by code. */
    const filterSuggestedTags = useCallback(async (filterText: string, selectedTags?: ITag[]) => {
        if (filterText) {
            const response = await dentalApi.searchCarcRarcsDetails(filterText);

            //Don't include any codes in the results that we have already selected.
            const filteredResults: ITag[] = filter(
                response.data,
                (item) => item.isDenial && selectedTags?.findIndex((sItem) => sItem.key === item.code) === -1,
            ).map((item) => ({
                key: item.code,
                name: `${item.code} - ${item.displayName}`,
            }));

            return filteredResults;
        }
        return [];
    }, []);

    function _onChange(tags: ITag[] | undefined) {
        if (onChange) onChange(tags);
    }

    return (
        <Stack grow>
            {label && <Label>{label}</Label>}
            <TagPicker
                onChange={_onChange}
                styles={{ root: { backgroundColor: palette.white, minWidth: 185 } }}
                pickerSuggestionsProps={{ noResultsFoundText: 'No CARC/RARCs found.', loadingText: 'Searching...' }}
                inputProps={{ placeholder: 'Search by code' }}
                onResolveSuggestions={filterSuggestedTags}
                selectedItems={selectedItems}
            ></TagPicker>
        </Stack>
    );
}
