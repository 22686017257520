import TXPlanPhase1Base from './TXPlanPhase1Base';
import { useDispatch } from 'react-redux';
import { useSelector, useTenantId } from 'hooks';
import { TreatmentPlanPhaseOneBaseProperties, TreatmentPlanPhaseOneDetailProperties } from 'api/models/tx-plan-phase-1.model';
import { selectReportProperties } from 'state/slices/reporting/reporting.selectors';
import { ReportType } from 'api/models/embed-report.model';
import reportActionLookup from 'state/slices/reporting/reportActionLookup';
import { completionCodesOption } from 'state/slices/tenant/procedures.slice';

import { Stack } from '@fluentui/react';
import { Field } from 'components';
import { toggleTreatmentPlanPhase1DetailIds } from 'state/slices/reporting/reporting.slice';
import { completedCodeArray } from './CompletionCodes';

export default function TXPlanPhase1Details() {
    const tenantId = useTenantId();
    const dispatch = useDispatch();
    const completedCodeOptions = useSelector((state) => completionCodesOption(state, completedCodeArray));

    const query = useSelector(selectReportProperties) as TreatmentPlanPhaseOneDetailProperties;

    const onViewReport = () => {
        dispatch(reportActionLookup[ReportType.TreatmentPlanPhaseOneDetails]({ tenantId, ...query }));
    };

    return (
        <TXPlanPhase1Base onViewReport={onViewReport}>
            <Stack.Item>
                <Field.SearchCombo
                    placeholder="(Select Pending Codes)"
                    label="Pending Codes"
                    options={completedCodeOptions}
                    multiSelect
                    onChange={(ev, option) => {
                        if (option?.key)
                            dispatch(toggleTreatmentPlanPhase1DetailIds({ id: option.key as string, path: 'pendingCodes' }));
                    }}
                    selectedKey={query?.pendingCodes}
                />
            </Stack.Item>
        </TXPlanPhase1Base>
    );
}
