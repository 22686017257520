import { DefaultButton, Dialog, DialogFooter, PrimaryButton } from '@fluentui/react';
import { IQuickAction } from 'api/models/quick-action.model';

type Props = {
    toggleHideDialog: () => void;
    deleteQuickActions: () => void;
    hideDialog: boolean;
    selectedQuickActions: IQuickAction[];
};

function DeleteQuickActionsDialog({ toggleHideDialog, deleteQuickActions, hideDialog, selectedQuickActions }: Props) {
    return (
        <Dialog
            dialogContentProps={{
                title: 'Delete Quick Action',
                subText: `Do you want to delete the selected quick action(s)?`,
            }}
            hidden={hideDialog}
        >
            <DialogFooter>
                <PrimaryButton text="Delete" onClick={deleteQuickActions} />
                <DefaultButton onClick={toggleHideDialog} text="Cancel" />
            </DialogFooter>
        </Dialog>
    );
}

export default DeleteQuickActionsDialog;
