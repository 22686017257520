import { MessageBarType } from '@fluentui/react';
import { SerializedError } from '@reduxjs/toolkit';
import { IBillingProcedure } from 'api/models/billing-procedure.model';
import { ICommonTransaction } from 'api/models/encounter-ledger.model';
import {
    IMostRecentTreatmentPlanPhaseView,
    IMostRecentTreatmentPlanView,
} from 'api/models/most-recent-treatment-plan-view.model';
import { IPatientPaymentHistoryView } from 'api/models/payment-history-model';
import { IPaymentSource } from 'api/models/payment-source.model';
import IProcedureCreditTransaction from 'api/models/procedure-credit-transaction';
import { LoadingStatus } from 'interfaces/loading-statuses';

export type TreatmentPlanTransactionLookup = Record<string, ICommonTransaction>; // {[key: string transactionId]: ICommonTransaction}

export type BillingProcedureWithTransactionId = {
    paymentTransactionId?: string;
} & IBillingProcedure;

export type TreatmentPlanCreditAndUACState = {
    /**
     * Recently signed treatment plan view
     *
     * @type {IMostRecentTreatmentPlanView}
     */
    signedTreatmentPlanView?: IMostRecentTreatmentPlanView;
    /**
     * Loading status for signed treatment plans
     *
     * @type {LoadingStatus}
     */
    loadingSignedTreatmentPlanView: LoadingStatus;

    /**
     * Saving status for data related to TP Credit/UAC.
     *
     * @type {LoadingStatus}
     */
    saving: LoadingStatus;
    /**
     * Stores the error for loading signed treatment plans.
     *
     * @type {SerializedError}
     */
    signedTreatmentPlanViewError?: SerializedError;

    /**
     * Transactions for adding treatment plan credit.
     *
     * @type {ICommonTransaction[]}
     */
    treatmentPlanCreditTransactions?: TreatmentPlanTransactionLookup;
    /**
     * Treatment plan credit payment source. Used for both UAC and regular TP Credit.
     *
     * @type {IPaymentSource}
     */
    paymentSource?: IPaymentSource;
    /**
     * The selected phase view.
     *
     * @type {TreatmentPlanCreditPhaseView}
     */
    selectedPhaseView?: IMostRecentTreatmentPlanPhaseView;
    /**
     * Current phase view used when creating a tp prepayment
     *
     * @type {TreatmentPlanCreditPhaseView}
     */
    currentPhaseView?: IMostRecentTreatmentPlanPhaseView;
    /**
     * Determines if fields on UAC/Add TP credit have been modified.
     *
     * @type {boolean}
     */
    modified?: boolean;
    /**
     * Allows the user to enter an amount greater than the patient balance
     *
     * @type {boolean}
     */
    allowOverpayment?: boolean;
    /**
     * Loading status of unapplied payments
     *
     * @type {LoadingStatus}
     */
    loadingUnappliedPayments: LoadingStatus;

    /**
     * Place to store an error from attempting to load unapplied payments
     *
     * @type {SerializedError}
     */
    unappliedPaymentsError?: SerializedError;
    /**
     * Unapplied payments data
     *
     * @type {IPatientPaymentHistoryView}
     */
    unnappliedPayments?: IPatientPaymentHistoryView;
    /**
     * Loading status of procedure credit transactions
     *
     * @type {LoadingStatus}
     */
    loadingProcedureCreditTransactions: LoadingStatus;
    /**
     * Place to store an error from attempting to load procedure credit transactions
     *
     * @type {SerializedError}
     */
    procedureCreditTransactionsError?: SerializedError;
    /**
     * Procedure credit transaction data
     *
     * @type {IProcedureCreditTransaction[]}
     */
    procedureCreditTransactions?: IProcedureCreditTransaction[];
    /**
     * Treatment plan and UAC message bar message string.
     *
     * @type {string}
     */
    messageBarMessage?: string;
    /**
     * Treatment plan and UAC message bar type.
     *
     * @type {MessageBarType}
     */
    messageBarType?: MessageBarType;
};

export const initialTreatmentPlanCreditAndUACState: TreatmentPlanCreditAndUACState = {
    loadingSignedTreatmentPlanView: LoadingStatus.Idle,
    loadingUnappliedPayments: LoadingStatus.Idle,
    loadingProcedureCreditTransactions: LoadingStatus.Idle,
    saving: LoadingStatus.Idle,
};

/**
 * Maybe we push the phaseid to the url?
 *
 * Then get the treatment plan from the phase treatment plan id...
 *
 * If the treatment plan is not signed and not the latest TP,
 * then redirect the user to the main page.
 *
 * Otherwise load the view data for the phase...
 *
 * Too complex...
 *
 * Just let the existence of transactions determine what to show.
 */
