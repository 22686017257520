import { Stack, TextField } from '@fluentui/react';
import { IPatientAddress, IPatientInsurance, IPolicyHolder } from 'api/models/patient.model';
import { Field, SubSection } from 'components';
import { useSelector } from 'hooks';
import { getValidationError, IValidationError, validateErrorMessage } from 'hooks/useValidation';
import { legalSexesOptionsList } from 'state/slices/tenant/legal-sexes.slice';
import { classicDateOnly } from 'utils/dateOnly';
import PolicyHolderAddressFields from './PolicyHolderAddress';

type Props = {
    insurance?: IPatientInsurance;
    newInsurancePropChange: (path: keyof IPatientInsurance, value: unknown) => void;
    newPolicyHolderPropChange: (path: keyof IPolicyHolder, value: unknown) => void;
    validationErrors: IValidationError[];
    address?: IPatientAddress;
};

function GeneralInformation({
    insurance,
    newInsurancePropChange,
    newPolicyHolderPropChange,
    validationErrors,
    address,
}: Props): JSX.Element {
    const legalSexOptions = useSelector(legalSexesOptionsList);

    const validTextInput = (value?: string): boolean => {
        const res = value ? /^(?!\s)[a-zA-Z\s-']*$/.test(value) : true;
        return res;
    };

    return (
        <SubSection title="General Information">
            <Stack tokens={{ childrenGap: 10 }}>
                <Stack horizontal tokens={{ childrenGap: 10 }} grow>
                    <Stack.Item grow>
                        <Field.Dropdown
                            label={
                                insurance?.policyHolderType === 'Patient'
                                    ? 'Policy Holder'
                                    : `Patient's Relationship to Policy Holder`
                            }
                            placeholder="(Select)"
                            options={[
                                {
                                    key: 'Child',
                                    text: 'Child',
                                },
                                {
                                    key: 'Self',
                                    text: 'Self',
                                },
                                {
                                    key: 'Spouse',
                                    text: 'Spouse',
                                },
                                {
                                    key: 'Other',
                                    text: 'Other',
                                },
                            ]}
                            selectedKey={insurance?.policyHolder?.relationship}
                            onChange={(e, option) => {
                                if (option) newPolicyHolderPropChange('relationship', option?.key);
                            }}
                            disabled={insurance?.policyHolderType === 'Patient'}
                            required={insurance?.policyHolderType === 'Other Contact'}
                            errorMessage={
                                getValidationError(validationErrors, 'Relationship')
                                    ? `Patient's relationship is required.`
                                    : undefined
                            }
                        />
                    </Stack.Item>
                    <Stack.Item grow>
                        <TextField
                            label="Employer"
                            placeholder="Enter employer"
                            value={insurance?.employer}
                            onChange={(ev, value) => newInsurancePropChange('employer', value)}
                            autoComplete="off"
                        />
                    </Stack.Item>
                </Stack>
                <Stack tokens={{ childrenGap: 10 }} horizontal grow>
                    <Stack.Item grow>
                        <TextField
                            label="First Name"
                            placeholder="Enter first name"
                            value={insurance?.policyHolder?.firstName}
                            onChange={(ev, value) => {
                                if (validTextInput(value)) newPolicyHolderPropChange('firstName', value);
                            }}
                            autoComplete="off"
                            required
                            errorMessage={
                                getValidationError(validationErrors, 'First Name') ? 'First Name is required.' : undefined
                            }
                        />
                    </Stack.Item>
                    <Stack.Item grow>
                        <TextField
                            label="Middle Name"
                            placeholder="Enter middle name"
                            value={insurance?.policyHolder?.middleName}
                            onChange={(ev, value) => {
                                if (validTextInput(value)) newPolicyHolderPropChange('middleName', value);
                            }}
                            autoComplete="off"
                        />
                    </Stack.Item>
                    <Stack.Item grow>
                        <TextField
                            label="Last Name"
                            placeholder="Enter last name"
                            value={insurance?.policyHolder?.lastName}
                            onChange={(ev, value) => {
                                if (validTextInput(value)) newPolicyHolderPropChange('lastName', value);
                            }}
                            autoComplete="off"
                            required
                            errorMessage={
                                getValidationError(validationErrors, 'Last Name') ? 'Last name is required.' : undefined
                            }
                        />
                    </Stack.Item>
                </Stack>
                <Stack tokens={{ childrenGap: 10 }} horizontal grow>
                    <Stack.Item grow>
                        <Field.Date
                            label="Date of Birth"
                            value={
                                insurance?.policyHolder?.dateOfBirth ? classicDateOnly(insurance?.policyHolder?.dateOfBirth) : ''
                            }
                            onChange={(e, value) => newPolicyHolderPropChange('dateOfBirth', value)}
                            hasDatePicker
                            autoComplete="off"
                            required
                            allowFutureDates={false}
                            errorMessage={validateErrorMessage(getValidationError(validationErrors, 'Date of Birth'))}
                        />
                    </Stack.Item>
                    <Stack.Item grow>
                        <Field.SSN
                            label="SSN (Social Security Number)"
                            value={insurance?.policyHolder?.ssn}
                            onChange={(e, value) => newPolicyHolderPropChange('ssn', value)}
                            autoComplete="off"
                        />
                    </Stack.Item>
                    <Stack.Item grow>
                        <Field.Dropdown
                            options={legalSexOptions}
                            label="Legal Sex"
                            placeholder="(Select Sex)"
                            selectedKey={insurance?.policyHolder?.sexAtBirthId}
                            onChange={(e, option) => {
                                if (option) newPolicyHolderPropChange('sexAtBirthId', option.key);
                            }}
                            required
                            errorMessage={
                                getValidationError(validationErrors, 'Sex at Birth') ? 'Legal Sex is required.' : undefined
                            }
                        />
                    </Stack.Item>
                </Stack>

                <PolicyHolderAddressFields
                    insurance={insurance}
                    newInsurancePropChange={newInsurancePropChange}
                    newPolicyHolderPropChange={newPolicyHolderPropChange}
                    validationErrors={validationErrors}
                    address={address}
                />
            </Stack>
        </SubSection>
    );
}
export default GeneralInformation;
