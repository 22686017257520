import { ICariesRiskAdultQuestions, RiskType } from './types';

export const questions: { [key in keyof ICariesRiskAdultQuestions]: string } = {
    additionalPatientInstructions: RiskType.None,
    cariesExperience: RiskType.None,
    cavitatedOrNonCavitatedIncipient: RiskType.None,
    chemoRadioationTherapy: RiskType.None,
    dentalHome: RiskType.None,
    drugAlchoholAbuse: RiskType.None,
    eatingDisorders: RiskType.None,
    exposedRootSurfacesPresent: RiskType.None,
    fluorideExposure: RiskType.None,
    interproximalRestorations1OrMore: RiskType.None,
    medicationsThatReduceSalivaryFlow: RiskType.None,
    restorationsWithOverhangsAndOrOpenMargins: RiskType.None,
    severeDryMouth: RiskType.None,
    smokelessTobaccoUse: RiskType.None,
    specialHealthcareNeeds: RiskType.None,
    sugaryStarchyFoodsOrDrinks: RiskType.None,
    teethMissingDueToCaries: RiskType.None,
    unusualToothMorphologyThatComprimisesOralHygiene: RiskType.None,
    visiblePlaque: RiskType.None,
};

export const noteTransalations: { [key in keyof ICariesRiskAdultQuestions]: string } = {};
