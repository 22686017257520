import { getEncounterCorrespondenceButtonText } from '../ColumnComponents/EncounterCorrespondenceColumn';
import { WorkListRecentlyViewedAction } from '../WorkListRecentItems';

export const getBaseEncounterRecentItemActions = ({
    readOnly,
    isEditEncounterWorklist,
}: {
    readOnly?: boolean;
    isEditEncounterWorklist?: boolean;
}): WorkListRecentlyViewedAction[] => [
    {
        key: 'encounterCorrespondence',
        iconProps: {
            iconName: readOnly ? 'EntryView' : 'AddNotes',
        },
        idFilterFieldName: 'encounterId',
        text: getEncounterCorrespondenceButtonText(!!readOnly, !!isEditEncounterWorklist),
    },
    {
        key: 'visitSummary',
        iconProps: {
            iconName: 'OpenPane',
        },
        idFilterFieldName: 'encounterId',
        text: 'Visit Summary',
    },
    {
        key: 'patientProfile',
        iconProps: {
            iconName: 'Contact',
        },
        idFilterFieldName: 'encounterId',
        text: 'Patient Profile',
        disabled: readOnly,
    },
];

export const readyToRebillActions: WorkListRecentlyViewedAction[] = [
    ...getBaseEncounterRecentItemActions({ readOnly: false, isEditEncounterWorklist: false }).slice(1),
    {
        key: 'adminHold',
        iconProps: {
            iconName: 'QuickNote',
        },
        idFilterFieldName: 'encounterId',
        text: 'Add/Edit Hold Note',
    },
    {
        key: 'billNote',
        iconProps: {
            iconName: 'QuickNote',
        },
        idFilterFieldName: 'encounterId',
        text: 'Add/Edit Billing Note',
    },
];
