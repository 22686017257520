import { Field } from 'components';
import { ISearchComboFieldProps } from 'components/Field/SearchComboField';
import useProviders from 'hooks/store/useProviders';
import { sortBy } from 'lodash';

function ProviderDropdown(props: Omit<ISearchComboFieldProps, 'options'> & { hideRDAs?: boolean }): JSX.Element {
    const { label } = props;
    const { providersAsList, providersWithoutRDAAsList } = useProviders();
    const providers = props.hideRDAs ? providersWithoutRDAAsList : providersAsList;

    const providerOptions = providers
        .filter((p) => !p.isDeleted)
        .map((p) => ({
            key: p.id,
            text: `${p.lastName}${p.suffix ? ` ${p.suffix}` : ''}, ${p.firstName}`,
        }));

    return <Field.SearchCombo label={label !== undefined ? label : 'Provider'} {...props} options={providerOptions} />;
}

export default ProviderDropdown;
