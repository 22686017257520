import React, { PropsWithChildren, CSSProperties } from 'react';
import { Stack, Separator, StackItem, IconButton, IButtonProps, useTheme } from '@fluentui/react';
import TText from './TText';

type Props = {
    title: string | React.ReactNode;
    menuButtonProps?: IButtonProps;
    isActive?: boolean;
    rightContent?: React.ReactNode;
};

const ContentCard = (props: PropsWithChildren<Props>): JSX.Element => {
    const theme = useTheme();
    const { title, children, isActive, menuButtonProps, rightContent } = props;
    const _cardStyle: CSSProperties = {
        padding: 10,
        backgroundColor: theme.palette.white,
        display: 'flex',
    };
    const _inactiveCardStyle: CSSProperties = {
        backgroundColor: theme.palette.neutralLight,
    };
    const _cardHeader: CSSProperties = {};
    const _cardFooter: CSSProperties = {};
    const _calculatedCardStyle = () => {
        if (isActive || isActive === undefined) {
            return { ..._cardStyle };
        } else {
            return { ..._cardStyle, ..._inactiveCardStyle };
        }
    };

    // const _activeIcon: string = isActive ? 'SkypeCircleCheck' : 'SkypeCircleMinus';
    // const _activeIconColor = isActive ? theme.palette.green : theme.palette.redDark;

    return (
        <div className={isActive === false ? '' : 'ms-depth-8'} style={_calculatedCardStyle()}>
            <Stack horizontal tokens={{ childrenGap: 5 }} verticalFill={true} grow>
                <Stack grow>
                    <div style={_cardHeader}>
                        <TText color="neutralPrimaryAlt" variant="small">
                            {title}
                        </TText>
                    </div>
                    <StackItem grow>{children}</StackItem>
                    <div style={_cardFooter}></div>
                </Stack>
                {(rightContent || menuButtonProps) && (
                    <Stack horizontal>
                        <Separator vertical />
                        <Stack verticalAlign="center" horizontalAlign="center" grow>
                            {rightContent && <StackItem>{rightContent}</StackItem>}
                            {/* {isActive !== undefined ? (
                            <StackItem grow={1}>
                                <TooltipHost content={isActive ? 'Active' : 'Inactive'}>
                                    <Icon
                                        iconName={_activeIcon}
                                        styles={{
                                            root: { color: _activeIconColor, cursor: 'default' },
                                        }}
                                    />
                                </TooltipHost>
                            </StackItem>
                        ) : null} */}
                            {menuButtonProps && <IconButton iconProps={{ iconName: 'MoreVertical' }} {...menuButtonProps} />}
                        </Stack>
                    </Stack>
                )}
            </Stack>
        </div>
    );
};

export default ContentCard;
