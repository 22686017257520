import IWorkListAppointmentView from 'api/models/worklist-appointment-view';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';

const secondaryPayerId: ISortableColumn<IWorkListAppointmentView> = {
    key: 'secondaryPayerId',
    name: 'Sec. Ins. Id',
    fieldName: 'secondaryPayerMemberId',
    minWidth: 100,
    isResizable: true,
};

export default secondaryPayerId;
