import dentalApi from 'api/dental.api';
import useTenantId from './useTenantId';

export default async function getTenantDocumentLink(tenantId: string, fileId: string): Promise<string> {
    try {
        const response = await dentalApi.getTenantDocumentsLink(tenantId, fileId);
        return response.data;
    } catch (e) {
        return JSON.stringify(e);
    }
}

export async function getImagingBridgeLink(): Promise<string> {
    try {
        const response = await dentalApi.getImagingBridgeLink();
        return response.data;
    } catch (e) {
        return JSON.stringify(e);
    }
}

export function useDownloadFile() {
    const tenantId = useTenantId();

    function downloadDocumentById(documentId: string) {
        getTenantDocumentLink(tenantId, documentId).then((link) => {
            if (link) {
                window.open(link, '_blank', 'noopener,noreferrer');
            }
        });
    }

    function downloadImagingBridge() {
        getImagingBridgeLink().then((link) => {
            if (link) {
                location.href = link;
            }
        });
    }

    return {
        downloadDocumentById,
        downloadImagingBridge,
    };
}
