import { Dropdown, IDropdownOption, Stack } from '@fluentui/react';
import { IClinicalMeasureDetailsReportProperties } from 'api/models/clinical-measure-detail.model';
import { ReportType } from 'api/models/embed-report.model';
import { Field } from 'components';
import { useTenantId } from 'hooks';
import { getValidationError } from 'hooks/useValidation';
import ReportWrapper from 'pages/Reporting/ReportWrapper';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import reportActionLookup from 'state/slices/reporting/reportActionLookup';
import { selectReportingValidationErrors, selectReportProperties } from 'state/slices/reporting/reporting.selectors';
import { setClinicalMeasureDetailsProp } from 'state/slices/reporting/reporting.slice';
import { classicDateOnly } from 'utils/dateOnly';

export default function ClinicalMeasureDetailsReport() {
    const query = useSelector(selectReportProperties) as IClinicalMeasureDetailsReportProperties;

    const dispatch = useDispatch();

    const tenantId = useTenantId();

    const errors = useSelector(selectReportingValidationErrors);

    const _onViewReport = () => {
        if (query) dispatch(reportActionLookup[ReportType.ClinicalMeasureDetails]({ tenantId, ...query }));
    };

    const measureOptions: IDropdownOption[] = [{ key: 'UDSDentalSealants6-9', text: 'UDS DentalSealants 6-9' }];

    useEffect(() => {
        if (!query?.measure)
            dispatch(
                setClinicalMeasureDetailsProp({
                    path: 'measure',
                    value: 'UDSDentalSealants6-9',
                }),
            );
    }, []);

    return (
        <ReportWrapper
            validationConfig={[
                { fieldName: 'Start Date', validation: ['required'], value: query?.startDate },
                { fieldName: 'End Date', validation: ['required'], value: query?.endDate },
                { fieldName: 'Measure', validation: ['required'], value: query?.measure },
            ]}
            onViewReport={_onViewReport}
        >
            <Stack grow horizontal wrap tokens={{ childrenGap: 10 }}>
                <Stack.Item grow>
                    <Field.Date
                        label="Start Date"
                        required
                        hasDatePicker
                        onChange={(ev, value) => {
                            const newDate = value ? classicDateOnly(value, 'yyyy-MM-dd') : undefined;
                            dispatch(setClinicalMeasureDetailsProp({ path: 'startDate', value: newDate }));
                        }}
                        value={query?.startDate ? classicDateOnly(query?.startDate, 'MM/dd/yyyy') : ''}
                        errorMessage={getValidationError(errors, 'Start Date') ? 'Start date is required.' : undefined}
                    />
                </Stack.Item>
                <Stack.Item grow>
                    <Field.Date
                        label="End Date"
                        required
                        isReasonable
                        hasDatePicker
                        value={query?.endDate ? classicDateOnly(query?.endDate, 'MM/dd/yyyy') : ''}
                        onChange={(ev, value) => {
                            const newDate = value ? classicDateOnly(value, 'yyyy-MM-dd') : undefined;
                            dispatch(setClinicalMeasureDetailsProp({ path: 'endDate', value: newDate }));
                        }}
                        disabled={!query?.startDate}
                        minReasonableErrorMessage="End date must be after start date."
                        minReasonableDate={query?.startDate ? new Date(classicDateOnly(query?.startDate)) : undefined}
                        errorMessage={getValidationError(errors, 'End Date') ? 'End date is required.' : undefined}
                    />
                </Stack.Item>
                <Stack.Item grow>
                    <Dropdown
                        label="Measure"
                        options={measureOptions}
                        required
                        selectedKey={query?.measure}
                        placeholder="(Select)"
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(
                                    setClinicalMeasureDetailsProp({
                                        path: 'measure',
                                        value: option.key as string,
                                    }),
                                );
                        }}
                        errorMessage={getValidationError(errors, 'Measure') ? 'Measure is required.' : undefined}
                    />
                </Stack.Item>
            </Stack>
        </ReportWrapper>
    );
}
