import useSelector from 'hooks/useSelector';
import { useDispatch } from 'react-redux';
import {
    encounterState,
    selectIncompleteEncountersWithPermission,
    selectIsAttestingHygienist,
    selectIsTreatingProvider,
    selectIsTreatingProviderResident,
    selectPatientEncounter,
    selectPatientEncounterAppointment,
    selectPatientEncounterLoading,
    selectPatientEncounterPrescriptions,
} from 'state/slices/encounter/encounter.selectors';
import { useCallback } from 'react';
import {
    getCurrentPatientEncounterAppointment,
    getPatientEncounterWithTasks,
    setCurrentEncounterDataPropAndSave,
    getPatientIncompleteEncounters,
    saveCurrentEncounter,
} from 'state/slices/encounter/encounter.actions';
import {
    cleanupChartPatientEncounterAppointment,
    cleanupPatientEncounter,
    setEncounterDataProp,
} from 'state/slices/encounter/encounter.slice';
import { usePatientId, useTenantId } from 'hooks';
import useEncounterId from 'hooks/useEncounterId';
import { IPatientEncounter } from 'api/models/encounter.model';

function useEncounter() {
    const dispatch = useDispatch();
    const tenantId = useTenantId();
    const patientId = usePatientId();
    const encounterId = useEncounterId();

    const { loadingPatientEncounterAppointment: _loadingPatientEnocunterAppointment } = useSelector(encounterState);

    const _patientEncounterAppointment = useSelector(selectPatientEncounterAppointment);
    const _selectPatientEncounterPrescriptions = useSelector(selectPatientEncounterPrescriptions);
    const _patientEncounter = useSelector(selectPatientEncounter);
    const _incomepleteEncounters = useSelector(selectIncompleteEncountersWithPermission);
    const _selectPatientEncounterLoading = useSelector(selectPatientEncounterLoading);

    const _getCurrentPatientEncounterAppointment = useCallback(() => {
        if (encounterId) dispatch(getCurrentPatientEncounterAppointment({ tenantId, encounterId }));
    }, [dispatch, tenantId, encounterId]);

    const _getPatientEncounter = useCallback(() => {
        if (encounterId && patientId) dispatch(getPatientEncounterWithTasks({ tenantId, patientId, encounterId }));
    }, [dispatch, tenantId, patientId, encounterId]);

    const _setCurrentEncounterDataPropAndSave = useCallback(
        (path: keyof IPatientEncounter, value: string | boolean | undefined) => {
            if (patientId) dispatch(setCurrentEncounterDataPropAndSave(path, value, tenantId, patientId));
        },
        [dispatch, tenantId, patientId],
    );
    const _setCurrentEncounterDataProp = useCallback(
        (path: keyof IPatientEncounter, value: string | boolean | undefined) => {
            if (patientId) dispatch(setEncounterDataProp({ path, value }));
        },
        [dispatch, tenantId, patientId],
    );
    const _saveCurrentEncounter = useCallback(() => {
        if (patientId) dispatch(saveCurrentEncounter(tenantId, patientId));
    }, [dispatch, tenantId, patientId]);

    const _getIncompleteEncounters = useCallback(() => {
        if (patientId) dispatch(getPatientIncompleteEncounters({ tenantId, patientId }));
    }, [dispatch, tenantId, patientId]);

    const _cleanupChartPatientEncounterAppointment = useCallback(() => {
        dispatch(cleanupChartPatientEncounterAppointment());
    }, [dispatch]);

    const _cleanupPatientEncounter = useCallback(() => {
        dispatch(cleanupPatientEncounter());
    }, [dispatch]);

    const _isTreatingProvider = useSelector(selectIsTreatingProvider);
    const _isTreatingProviderResident = useSelector(selectIsTreatingProviderResident);
    const _isAttestingHygienist = useSelector(selectIsAttestingHygienist);
    return {
        //Selectors
        loadingEncounterAppointment: _loadingPatientEnocunterAppointment,
        patientEncounterAppointment: _patientEncounterAppointment,
        patientEncounter: _patientEncounter,
        incomepleteEncounters: _incomepleteEncounters,
        selectPatientEncounterPrescriptions: _selectPatientEncounterPrescriptions,

        // readyToReviewEncounters: _readyToReviewEncounters,
        selectPatientEncounterLoading: _selectPatientEncounterLoading,
        isTreatingProvider: _isTreatingProvider,
        isTreatingProviderResident: _isTreatingProviderResident,
        isAttestingHygienist: _isAttestingHygienist,

        //Async Actions
        getCurrentPatientEncounterAppointment: _getCurrentPatientEncounterAppointment,
        getPatientEncounter: _getPatientEncounter,
        setCurrentEncounterDataPropAndSave: _setCurrentEncounterDataPropAndSave,
        getIncompleteEncounters: _getIncompleteEncounters,

        //Actions
        cleanupChartPatientEncounterAppointment: _cleanupChartPatientEncounterAppointment,
        cleanupPatientEncounter: _cleanupPatientEncounter,

        setCurrentEncounterDataProp: _setCurrentEncounterDataProp,
        saveCurrentEncounter: _saveCurrentEncounter,
    };
}

export default useEncounter;
