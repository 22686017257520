import { CustomThemeProps } from '../ui.slice';

export const athenaTheme: CustomThemeProps = {
    palette: {
        themePrimary: '#0275d4',
        themeLighterAlt: '#f3f9fd',
        themeLighter: '#d1e6f8',
        themeLight: '#aad2f2',
        themeTertiary: '#5da8e5',
        themeSecondary: '#1c84d9',
        themeDarkAlt: '#026abe',
        themeDark: '#0259a1',
        themeDarker: '#014277',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#a19f9d',
        neutralSecondary: '#605e5c',
        neutralPrimaryAlt: '#3b3a39',
        neutralPrimary: '#323130',
        neutralDark: '#201f1e',
        black: '#000000',
        white: '#ffffff',
        navbar: '#514b7b',
        navbarLighter: '#393556',
    },
    eventColors: {
        trackerStatus: {
            arrived: {
                backgroundColor: '#EDEAF3',
                highlight: '#8169A7',
                buttonColor: '#387D8C',
            },
            ready: {
                backgroundColor: '#EEF5ED',
                highlight: '#437a37',
                buttonColor: '#387D8C',
            },
            inTreatment: {
                backgroundColor: '#E6F3F7',
                highlight: '#3B7D8C',
                buttonColor: '#387D8C',
            },
            checkout: {
                backgroundColor: '#EFF1F1',
                highlight: '#68696A',
                buttonColor: '#107C10',
            },
            completed: {
                backgroundColor: '#8D8D8D',
                highlight: '#444',
                buttonColor: '#444444',
            },
            canceled: {
                backgroundColor: '#FFDBDB',
                highlight: '#7A0A04',
                buttonColor: '#7A0A04',
            },
        },
        trackerEncounterStatus: {
            created: {
                backgroundColor: '#8169A7',
                highlight: '#EDEAF3',
                buttonColor: '#387D8C',
                color: 'white',
            },
            readyForAttestation: {
                backgroundColor: '#437a37',
                highlight: '#EEF5ED',
                buttonColor: '#107C10',
                color: 'white',
            },
            attested: {
                backgroundColor: '#3B7D8C',
                highlight: '#E6F3F7',
                buttonColor: '#8169A7',
                color: 'white',
            },
            amendRequired: {
                backgroundColor: '#3B7D8C',
                highlight: '#E6F3F7',
                buttonColor: '#FFB900',
                color: 'white',
            },
            completed: {
                backgroundColor: '#3B7D8C',
                highlight: '#E6F3F7',
                buttonColor: '#444444',
                color: 'white',
            },
            conversionImported: {
                backgroundColor: '#3B7D8C',
                highlight: '#E6F3F7',
                buttonColor: '#444444',
                color: 'white',
            },
            canceled: {
                backgroundColor: '#FFDBDB',
                highlight: '#7A0A04',
                buttonColor: '#7A0A04',
                color: 'white',
            },
        },
    },
};
