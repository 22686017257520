import { IChartProcedure } from 'api/models/chart.model';
import { ConflictRuleType } from 'api/models/lookup.model';
import { IPipelineError, PipelineError } from '../pipeline';
import ProcedureConflictRulesPipeline from '../procedureConflictRules.pipeline';
import { createRule } from '../ruleGenerator';

const ProcedureOnePerTooth = createRule<ProcedureConflictRulesPipeline, IChartProcedure>({
    ruleTypes: [ConflictRuleType.onePerTooth],
    rule: (pipeline, item) => {
        const sameProcedures = pipeline.getProceduresWithSameProcedureId(item.procedureId);

        let isOnTooth = false;
        const errors: IPipelineError[] = [];
        sameProcedures.forEach((proc) => {
            if (proc.id !== item.id) {
                const foundOnTooth = proc?.toothIds?.findIndex((id) => item.toothIds?.includes(id));
                if (foundOnTooth !== undefined && foundOnTooth > -1) {
                    isOnTooth = true;
                    errors.push({ type: PipelineError.OnePerTooth, data: pipeline.getProcedure(item) });
                }
            }
        });
        return { shouldRemoveItem: isOnTooth, errors };
    },
});

export default ProcedureOnePerTooth;
