import { IThemeCustom } from 'state/slices/ui/ui.slice';
import { useTheme } from '@fluentui/react';
import { TrackerStatusIds } from 'api/models/Scheduling/patientAppointment.model';
import { Dictionary } from '@reduxjs/toolkit';
import { TrackerStatus } from 'pages/Scheduling/components/TrackerStatusDropdown';
import { EncounterStatus } from 'api/models/encounter.model';

const trackerStatusColorLookup: Dictionary<string> = {
    [TrackerStatus.Arrived]: 'arrived',
    [TrackerStatus.CheckIn]: 'arrived',
    [TrackerStatus.Ready]: 'ready',
    [TrackerStatus.Exam]: 'inTreatment',
    [TrackerStatus.Xray]: 'inTreatment',
    [TrackerStatus.Treatment]: 'inTreatment',
    [TrackerStatus.Intake]: 'inTreatment',
    [TrackerStatus.Prophy]: 'inTreatment',
    [TrackerStatus.Dismissed]: 'checkout',
    [TrackerStatus.ReadyForCheckout]: 'checkout',
    [TrackerStatus.Completed]: 'completed',
    canceled: 'canceled',
};

const encounterStatusLookupForColor: Dictionary<string> = {
    [EncounterStatus.Created]: 'created',
    [EncounterStatus.ReadyForAttestation]: 'readyForAttestation',
    [EncounterStatus.Attested]: 'attested',
    [EncounterStatus.AmendRequired]: 'amendRequired',
    [EncounterStatus.CorrectionAmend]: 'amendRequired',
    [EncounterStatus.Completed]: 'completed',
    [EncounterStatus.Billed]: 'completed',
    [EncounterStatus.ConversionImported]: 'conversionImported',
    canceled: 'canceled',
};

function useTrackerBackgroundColor(trackerStatusId?: TrackerStatusIds): string[] {
    const theme = useTheme() as IThemeCustom;

    const backgroundColor = 'transparent';
    const highlight = 'transparent';

    if (trackerStatusId) {
        const colorKey = trackerStatusColorLookup[trackerStatusId];
        if (colorKey) {
            const colorOption = (theme as IThemeCustom).eventColors.trackerStatus[colorKey];
            if (colorOption) {
                const { backgroundColor, highlight } = colorOption;

                return [backgroundColor, highlight];
            }
        }
    }

    return [backgroundColor, highlight];
}

export function useTrackerButtonColor(encounterStatus?: EncounterStatus | 'canceled'): string[] {
    const theme = useTheme() as IThemeCustom;

    const buttonColor = theme.palette.themePrimary;
    const highlight = 'transparent';
    const color = 'white';

    if (encounterStatus) {
        const colorKey = encounterStatusLookupForColor[encounterStatus];
        if (colorKey) {
            const colorOption = (theme as IThemeCustom).eventColors.trackerEncounterStatus[colorKey];
            if (colorOption) {
                const { buttonColor, color, highlight } = colorOption;

                return [buttonColor, color, highlight];
            }
        }
    }

    return [buttonColor, color, highlight];
}

export default useTrackerBackgroundColor;
