import { MessageBar, SelectionMode, Stack } from '@fluentui/react';
import { SortableDetailsList } from 'components';
import ClaimsHistoryHeader from './ClaimsHistoryHeader';
import { useSelector } from 'hooks';
import {
    selectEncounterClaimsSummaryLoadingStatus,
    selectEncounterClaimsSummaryReadyToBillHistory,
} from 'state/slices/ledger/claim-history/claim-history.selectors';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { IReadyToBillHistory } from 'api/models/encounter-claim-summary.model';
import { format } from 'date-fns';
import UserDisplayName from 'components/UserDisplayName';
import { useCallback } from 'react';
import { selectSelectedPatient } from 'state/slices/patient/patient.selectors';
import { selectTenantPayersData } from 'state/slices/tenant/tenant-payers.slice';

export default function ReadyToBillHistory() {
    const loading = useSelector(selectEncounterClaimsSummaryLoadingStatus) === LoadingStatus.Pending;
    const readyToBillHistoryItems = useSelector(selectEncounterClaimsSummaryReadyToBillHistory);

    const patient = useSelector(selectSelectedPatient);
    const payerLookup = useSelector(selectTenantPayersData);

    const getPatientInsuranceName = useCallback(
        (patientInsuranceId: string | undefined) => {
            if (!patientInsuranceId) return '';
            //In the case that patientInsuranceId is not a guid, return the value as it came in.
            return payerLookup[patientInsuranceId] ? payerLookup[patientInsuranceId]?.name ?? '' : patientInsuranceId ?? '';
        },
        [patient, payerLookup],
    );

    return (
        <Stack grow>
            <ClaimsHistoryHeader heading="Approval History" />
            {!loading && !readyToBillHistoryItems.length ? (
                <MessageBar>No encounter correspondence items to show.</MessageBar>
            ) : (
                <SortableDetailsList<IReadyToBillHistory>
                    selectionMode={SelectionMode.none}
                    items={readyToBillHistoryItems}
                    showGrid
                    compact
                    enableShimmer={loading}
                    shimmerLines={2}
                    columns={[
                        {
                            key: 'date',
                            fieldName: 'date',
                            name: 'Approved Date',
                            minWidth: 80,
                            maxWidth: 120,
                            onRender: (item) => {
                                return <span>{item?.date ? format(new Date(item.date), 'MM/dd/yyyy h:mm a') : ''}</span>;
                            },
                        },
                        {
                            key: 'user',
                            fieldName: 'user',
                            name: 'User',
                            minWidth: 80,
                            maxWidth: 180,
                            onRender: (item) => {
                                return <UserDisplayName userId={item?.user} />;
                            },
                        },
                        {
                            key: 'primary',
                            fieldName: 'primary',
                            name: 'Primary',
                            minWidth: 80,
                            maxWidth: 150,
                            onRender: (item) => {
                                const name = getPatientInsuranceName(item?.primary);
                                return <span title={name}>{name}</span>;
                            },
                        },
                        {
                            key: 'secondary',
                            fieldName: 'secondary',
                            name: 'Secondary',
                            minWidth: 80,
                            maxWidth: 150,
                            onRender: (item) => {
                                const name = getPatientInsuranceName(item?.secondary);
                                return <span title={name}>{name}</span>;
                            },
                        },
                        {
                            key: 'tertiary',
                            fieldName: 'tertiary',
                            name: 'Tertiary',
                            minWidth: 80,
                            maxWidth: 150,
                            onRender: (item) => {
                                const name = getPatientInsuranceName(item?.tertiary);
                                return <span title={name}>{name}</span>;
                            },
                        },
                    ]}
                />
            )}
        </Stack>
    );
}
