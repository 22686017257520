import { Shimmer } from '@fluentui/react';
import { useSelector, useTenantId } from 'hooks';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getMissingUserIdentities } from 'state/slices/users-identities/user-identities.actions';
import { selectUserIdentitiesData, selectUserIdentitiesState } from 'state/slices/users-identities/user-identities.selectors';
import { validate as validUUID } from 'uuid';

export type UserDisplayNameProps = { userId?: string; useSuffix?: boolean };

export function useUserIdentity({ userId, useSuffix }: UserDisplayNameProps) {
    //Removed deps on userIdentitiesData hook
    const tenantId = useTenantId();
    const dispatch = useDispatch();

    const userIdentitiesData = useSelector(selectUserIdentitiesData);

    useEffect(() => {
        if (userId && validUUID(userId) && !userIdentitiesData[userId]) {
            dispatch(getMissingUserIdentities({ tenantId, userIds: [userId] }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);

    if (!userId) return { userName: 'N/A', user: undefined };

    const user = userIdentitiesData[userId];

    const userName = user
        ? useSuffix && user.professionalSuffix
            ? `${user.lastName} ${user.professionalSuffix}, ${user.firstName}`
            : user.displayName ?? 'N/A'
        : userId;

    return { userName, user, firstName: user?.firstName, lastName: user?.lastName, professionalSuffix: user?.professionalSuffix };
}

export default function UserDisplayName({ userId, useSuffix }: UserDisplayNameProps) {
    const { loading } = useSelector(selectUserIdentitiesState);
    const { userName, user } = useUserIdentity({ userId, useSuffix });

    // No user yet, but it's a valid UUID assume we're still loading identities
    if (!user && validUUID(userId ?? '') && loading === LoadingStatus.Pending) {
        return <Shimmer />;
    }

    return <span title={userName}>{userName}</span>;
}
