import { Dropdown, IDropdownOption, Stack, TextField } from '@fluentui/react';
import { IDepositSlipDetailQuery } from 'api/models/deposit-slip.model';
import { ReportType } from 'api/models/embed-report.model';
import { Field } from 'components';
import { useTenantId } from 'hooks';
import { getValidationError } from 'hooks/useValidation';
import ReportWrapper from 'pages/Reporting/ReportWrapper';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import reportActionLookup from 'state/slices/reporting/reportActionLookup';
import { selectReportProperties, selectReportingValidationErrors } from 'state/slices/reporting/reporting.selectors';
import { setDepositSlipDetailProp, toggleDepositSlipDetailListId } from 'state/slices/reporting/reporting.slice';
import { selectTreatingProviderOptions } from 'state/slices/tenant/providers.slice';
import { classicDateOnly } from 'utils/dateOnly';

function DepositSlipDetails() {
    const tenantId = useTenantId();
    const dispatch = useDispatch();

    const errors = useSelector(selectReportingValidationErrors);
    const _providerOptions = useSelector(selectTreatingProviderOptions);

    const query = useSelector(selectReportProperties) as IDepositSlipDetailQuery;

    const _onViewReport = () => {
        if (query) dispatch(reportActionLookup[ReportType.DepositSlipDetails]({ tenantId, ...query }));
    };

    const dateTypeOptions: IDropdownOption[] = [
        { key: 'DepositDate', text: 'Deposit Date' },
        { key: 'EntryDate', text: 'Entry Date' },
    ];

    const groupColOptions: IDropdownOption[] = [
        { key: '', text: '(Select Group Column)' },
        { key: 'Batch', text: 'Batch' },
        { key: 'LocationOfCare', text: 'Location of Care' },
        { key: 'ResponsibleProvider', text: ' Treating Provider' },
    ];

    useEffect(() => {
        if (!query?.dateType) {
            dispatch(setDepositSlipDetailProp({ path: 'dateType', value: 'EntryDate' }));
        }

        dispatch(setDepositSlipDetailProp({ path: 'groupCol', value: 'Batch' }));
    }, []);

    return (
        <ReportWrapper
            validationConfig={[
                {
                    fieldName: 'Date Type',
                    validation: ['required'],
                    value: query.dateType,
                },
                {
                    fieldName: 'Start Date',
                    validation: ['required'],
                    value: query.startDate,
                },
                {
                    fieldName: 'End Date',
                    validation: ['required'],
                    value: query.endDate,
                },
            ]}
            onViewReport={_onViewReport}
        >
            <Stack tokens={{ childrenGap: 10 }}>
                <Stack horizontal grow tokens={{ childrenGap: 10 }}>
                    <Stack.Item>
                        <Dropdown
                            label="Date Type"
                            options={dateTypeOptions}
                            required
                            style={{ minWidth: 150 }}
                            selectedKey={query?.dateType}
                            placeholder="(Select)"
                            onChange={(ev, option) => {
                                if (option?.key)
                                    dispatch(
                                        setDepositSlipDetailProp({
                                            path: 'dateType',
                                            value: option.key as string,
                                        }),
                                    );
                            }}
                            errorMessage={getValidationError(errors, 'Date Type') ? 'Date type is required.' : undefined}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <Field.Date
                            label="Start Date"
                            required
                            value={query?.startDate ? classicDateOnly(query?.startDate, 'MM/dd/yyyy') : ''}
                            onChange={(ev, value) => {
                                const newDate = value ? classicDateOnly(value, 'yyyy-MM-dd') : undefined;
                                dispatch(setDepositSlipDetailProp({ path: 'startDate', value: newDate }));
                            }}
                            hasDatePicker
                            errorMessage={getValidationError(errors, 'Start Date') ? 'Start date is required.' : undefined}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <Field.Date
                            label="End Date"
                            required
                            value={query?.endDate ? classicDateOnly(query?.endDate, 'MM/dd/yyyy') : ''}
                            onChange={(ev, value) => {
                                const newDate = value ? classicDateOnly(value, 'yyyy-MM-dd') : undefined;
                                dispatch(setDepositSlipDetailProp({ path: 'endDate', value: newDate }));
                            }}
                            isReasonable
                            disabled={!query?.startDate}
                            minReasonableErrorMessage="End date must be after start date."
                            minReasonableDate={query?.startDate ? new Date(classicDateOnly(query?.startDate)) : undefined}
                            hasDatePicker
                            errorMessage={getValidationError(errors, 'End Date') ? 'End date is required.' : undefined}
                        />
                    </Stack.Item>

                    <Stack.Item>
                        <Field.SearchCombo
                            placeholder="(Select Providers)"
                            label="Providers"
                            multiSelect
                            style={{ minWidth: 300 }}
                            options={_providerOptions}
                            selectedKey={query?.responsibleProvider}
                            onChange={(ev, option) => {
                                if (option?.key)
                                    dispatch(
                                        toggleDepositSlipDetailListId({ id: option.key as string, path: 'responsibleProvider' }),
                                    );
                            }}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField
                            label="Check Number"
                            value={query?.checkNumber}
                            style={{ minWidth: 200 }}
                            onChange={(ev, value) => {
                                dispatch(setDepositSlipDetailProp({ path: 'checkNumber', value }));
                            }}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <Dropdown
                            label="Group Column"
                            options={groupColOptions}
                            selectedKey={query?.groupCol}
                            style={{ minWidth: 150 }}
                            placeholder="(Select)"
                            onChange={(ev, option) => {
                                if (option)
                                    dispatch(
                                        setDepositSlipDetailProp({
                                            path: 'groupCol',
                                            value: option.key as string,
                                        }),
                                    );
                            }}
                        />
                    </Stack.Item>
                </Stack>
            </Stack>
        </ReportWrapper>
    );
}

export default DepositSlipDetails;
