import { createAsyncThunk } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import { IBatch } from 'api/models/batch.model';
import { ICommonTransaction, TransactionSource, TransactionType } from 'api/models/encounter-ledger.model';
import { IMostRecentTreatmentPlanView } from 'api/models/most-recent-treatment-plan-view.model';
import { IPatientPaymentHistoryView } from 'api/models/payment-history-model';
import { IPaymentSource } from 'api/models/payment-source.model';
import { push } from 'connected-react-router';
import { batch } from 'react-redux';
import { selectSignalRIsConnected } from 'state/slices/signalr.slice';
import { AppThunk, RootState } from 'state/store';
import { v4 as uuid } from 'uuid';
import {
    cleanupTreatmentPlanCreditModified,
    setTreatmentPlanCreditPaymentSource,
    setTreatmentPlanCreditTransactions,
    updateAllTreamtentPlanCreditTransactionsBatchId,
    updateTreatmentPlanCreditPaymentSource,
} from '../ledger.slice';
import { getTransactionsToSave } from '../patient-payments-and-adjustments/patient-payments-and-adjustments.actions';
import {
    selectTreamentPlanCreditPaymentSource,
    selectTreamentPlanCreditTransactionsAsList,
    selectTreamentPlanCreditTransactionsExist,
} from './treatment-plan-credit-and-uac.selectors';
import { BillingProcedureWithTransactionId, TreatmentPlanTransactionLookup } from './treatment-plan-credit-and-uac.state';
import { orderBy } from 'lodash';
import { allPatientEncountersLookup } from 'state/slices/patient/patient.selectors';
import IProcedureCreditTransaction from 'api/models/procedure-credit-transaction';
import axios from 'axios';

export const getPatientTreatmentPlanCreditUnappliedPayments = createAsyncThunk<
    IPatientPaymentHistoryView,
    { tenantId: string; patientId: string },
    { state: RootState }
>('getPatientTreatmentPlanCredtiUnappliedPayments', async ({ tenantId, patientId }, { rejectWithValue, signal }) => {
    try {
        const source = axios.CancelToken.source();
        signal.addEventListener('abort', () => {
            source.cancel();
        });
        const { data } = await dentalApi.getPatientUnappliedPayments(tenantId, patientId, source.token);
        return data;
    } catch (e) {
        return rejectWithValue(e);
    }
});

export const getProcedureCreditTransactions = createAsyncThunk<
    IProcedureCreditTransaction[],
    { tenantId: string; patientId: string },
    { rejectValue: string }
>('getProcedureCreditTransactions', async ({ tenantId, patientId }, { rejectWithValue, signal }) => {
    try {
        const source = axios.CancelToken.source();
        signal.addEventListener('abort', () => {
            source.cancel();
        });
        const { data } = await dentalApi.getProcedureCreditTransactions(tenantId, patientId, source.token);
        return data;
    } catch (e) {
        return rejectWithValue(e as string);
    }
});

export const getPatientMostRecentTreatmentPlanCreditView = createAsyncThunk<
    IMostRecentTreatmentPlanView | undefined,
    { tenantId: string; patientId: string },
    { state: RootState }
>('getPatientMostRecentTreatmentPlanCreditView', async ({ tenantId, patientId }, { rejectWithValue, signal }) => {
    try {
        const { data } = await dentalApi.getMostRecentTreatmentPlanView(tenantId, patientId);
        const source = axios.CancelToken.source();
        signal.addEventListener('abort', () => {
            source.cancel();
        });
        return data;
    } catch (e) {
        return rejectWithValue(e);
    }
});

export const updateTreatmentPlanCreditPaymentSourceAndTransactionsBatchId =
    (selectedBatch: IBatch | undefined): AppThunk<void> =>
        (dispatch, getState) => {
            if (selectedBatch) {
                dispatch(updateTreatmentPlanCreditPaymentSource({ path: 'dateOfEntry', value: selectedBatch.dateOfEntry }));
                const transactionsExist = selectTreamentPlanCreditTransactionsExist(getState());
                if (transactionsExist) dispatch(updateAllTreamtentPlanCreditTransactionsBatchId(selectedBatch));
            }
        };

type SavePaymentInfoResponse = { paymentSource: IPaymentSource; transactions?: ICommonTransaction[] };
export const saveTreatmentPlanCreditPaymentSourceAndTransactions = createAsyncThunk<
    SavePaymentInfoResponse,
    { tenantId: string; patientId: string; encounterId: string | undefined; batchId: string },
    { rejectValue: string; state: RootState }
>(
    'saveTreatmentPlanCreditPaymentSourceAndTransactions',
    async ({ tenantId, patientId, encounterId, batchId }, { rejectWithValue, getState, dispatch }) => {
        const returnUrl = encounterId
            ? `/${tenantId}/patient/${patientId}/encounter/${encounterId}/ledger/treatment-plan-prepayment-uac`
            : `/${tenantId}/patient/${patientId}/ledger/treatment-plan-prepayment-uac`;
        try {
            const creditPaymentSource = selectTreamentPlanCreditPaymentSource(getState()) as IPaymentSource;
            const creditTransactions = getTransactionsToSave(selectTreamentPlanCreditTransactionsAsList(getState()));

            const { data: paymentSource } = await dentalApi.createPaymentSource(tenantId, creditPaymentSource);

            let transactions: ICommonTransaction[] | undefined = undefined;
            if (creditTransactions.length) {
                const { data } = await dentalApi.createLedgerTransaction(tenantId, creditTransactions);
                transactions = data;

                const isSignalRConnected = selectSignalRIsConnected(getState());
                if (!isSignalRConnected)
                    batch(() => {
                        dispatch(getPatientMostRecentTreatmentPlanCreditView({ tenantId, patientId }));
                    });
            } else {
                const uacTransaction: ICommonTransaction[] = getTransactionsToSave([
                    {
                        id: uuid(),
                        amount: creditPaymentSource.amount,
                        dateOfEntry: creditPaymentSource.dateOfEntry,
                        batchId,
                        patientId,
                        paymentSourceId: creditPaymentSource.id,
                        type: TransactionType.Payment,
                    },
                ]);
                const { data } = await dentalApi.createLedgerTransaction(tenantId, uacTransaction);
                transactions = data;
            }
            await dispatch(cleanupTreatmentPlanCreditModified());

            dispatch(push(returnUrl));

            return { paymentSource, transactions };
        } catch (e) {
            await dispatch(cleanupTreatmentPlanCreditModified());
            dispatch(push(returnUrl));
            return rejectWithValue(e as string);
        }
    },
);

export const createAddTreatmentPlanCreditTransactions =
    (
        patientId: string,
        phaseProcedures: BillingProcedureWithTransactionId[],
        paymentSourceId: string,
        dateOfEntry: string,
        batchId?: string,
    ): AppThunk<void> =>
        (dispatch, getState) => {
            const treatmentPlanTransactionLookup: TreatmentPlanTransactionLookup = {};
            const patientEncountersLookup = allPatientEncountersLookup(getState());

            orderBy(phaseProcedures, (item) => patientEncountersLookup[item.encounterId ?? '']?.encounterDate ?? '').forEach(
                (procedure) => {
                    //build chart procedure
                    if (procedure.paymentTransactionId) {
                        treatmentPlanTransactionLookup[procedure.paymentTransactionId] = {
                            amount: 0,
                            batchId,
                            chartProcedureId: procedure.id,
                            treatmentPlanId: procedure.treatmentPlanId,
                            treatmentPlanPhaseId: procedure.phaseId,
                            patientId,
                            procedureCode: procedure.procedureCode,
                            dateOfEntry,
                            id: procedure.paymentTransactionId ?? '', //transaction id we added to the billing procedures on load
                            paymentSourceId,
                            source: TransactionSource.Patient,
                            type: TransactionType.Payment,
                            treatingProviderId: procedure.treatingProviderId,
                        };
                    }
                },
            );

            dispatch(setTreatmentPlanCreditTransactions(treatmentPlanTransactionLookup));
        };

export const createAddTreatmentPlanCreditInformation =
    (patientId: string, selectedBatch?: IBatch, phaseProcedures?: BillingProcedureWithTransactionId[]): AppThunk<void> =>
        (dispatch) => {
            const dateOfEntry = selectedBatch?.dateOfEntry ?? '';

            const paymentSource: IPaymentSource = {
                amount: 0,
                id: uuid(),
                dateOfEntry,
                isDeleted: false,
                paymentDate: dateOfEntry,
            };

            dispatch(setTreatmentPlanCreditPaymentSource(paymentSource));
            if (phaseProcedures?.length) {
                dispatch(
                    createAddTreatmentPlanCreditTransactions(
                        patientId,
                        phaseProcedures,
                        paymentSource.id,
                        dateOfEntry,
                        selectedBatch?.id,
                    ),
                );
            }
        };
