import { ClinicalNote, NoteType } from 'api/models/chart-note.model';

class RenderNote {
    chartNote: ClinicalNote;
    constructor(chartNote: ClinicalNote) {
        this.chartNote = chartNote;
    }

    get charNoteData() {
        return this.chartNote.data;
    }

    get isClinicalNote() {
        return this.chartNote.noteType === NoteType.ClinicalNote;
    }

    public buildNoteString() {
        const baseNote = this.charNoteData.value;
        const baseSignatures = this.getBaseSignatures;
        const addendums = this.getAddendums;

        return `${baseNote} ${baseSignatures ?? ''} ${addendums ?? ''}`;
    }

    get getBaseSignatures() {
        if (!this.charNoteData.signatures) return '<br />';
        if (!this.charNoteData.signatures.length) return '<br />';
        return this.charNoteData.signatures.map((signature) => `<strong>${signature.signature}</strong>`).join('<br />');
    }

    get getAddendums() {
        if (!this.charNoteData.addendums) return;
        if (!this.charNoteData.addendums.length) return;
        const addendumSpacer = '<br /><br />';
        const addendums = this.charNoteData.addendums
            .map((addendum) => `<strong>--- ${addendum.signature?.signature} --- </strong> <br /> ${addendum.note} `)
            .join('<br />');
        return `${addendumSpacer} ${addendums}`;
    }
}

export default RenderNote;
