import { Dropdown, IComboBoxOption, Stack } from '@fluentui/react';
import { TText } from 'components';
import { useSelector } from 'hooks';
import { TrackerStatus, trackerStatusText } from 'pages/Scheduling/components/TrackerStatusDropdown';
import { useDispatch } from 'react-redux';
import { selectWorkListFilters } from 'state/slices/admin-huddle/worklists/worklist.selectors';
import { setWorkListFilter } from 'state/slices/admin-huddle/worklists/worklist.slice';

const trackerStatusFilterOptions: IComboBoxOption[] = Object.keys(TrackerStatus).map((statusKey) => {
    const status = TrackerStatus[statusKey as keyof typeof TrackerStatus];
    return {
        key: status,
        text: trackerStatusText[status],
    };
});

export default function TrackerStatusFilter() {
    const dispatch = useDispatch();

    const { trackerStatus } = useSelector(selectWorkListFilters<any>);

    const handleTrackerFilterSelect = (trackerStatus: string) => {
        dispatch(setWorkListFilter({ path: 'trackerStatus', value: trackerStatus }));
    };

    return (
        <Stack.Item grow>
            <Dropdown
                onChange={(e, option) => {
                    handleTrackerFilterSelect(option?.key as string);
                }}
                options={trackerStatusFilterOptions}
                label="Tracker Status"
                selectedKey={trackerStatus ?? ''}
                placeholder="Select Tracker Status"
                onRenderOption={(props) => (props?.itemType ? <span>{props.text}</span> : <TText>{props?.text}</TText>)}
            />
        </Stack.Item>
    );
}
