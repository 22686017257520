import { IComboBoxOption } from '@fluentui/react';
import { Dictionary, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import IHoldingStatement from 'api/models/holding-statement.model';
import ILanguage from 'api/models/languages.model';
import { AxiosResponse } from 'axios';
import { LoadingStatus, LoadingStatuses } from 'interfaces/loading-statuses';
import { map } from 'lodash';
import { RootState } from 'state/store';

const initialState: HoldingStatementState = {
    initialLoad: LoadingStatus.Idle,
    loading: LoadingStatus.Idle,
    data: {},
};

export const getHoldingStatement = createAsyncThunk<AxiosResponse<Dictionary<IHoldingStatement>>>(
    'getHoldingStatement',
    async () => {
        const res = await dentalApi.getHoldingStatement();
        return res;
    },
);

const holdingStatementSlice = createSlice({
    name: 'holdingStatement',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getHoldingStatement.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(getHoldingStatement.fulfilled, (state, action) => {
                state.loading = LoadingStatus.Completed;
                state.data = action.payload.data;
            })
            .addCase(getHoldingStatement.rejected, (state, action) => {
                state.loading = LoadingStatus.Failed;
            });
    },
});

const { reducer } = holdingStatementSlice;

export const selectHoldingStatement = (state: RootState): Dictionary<IHoldingStatement> => state.holdingStatement.data;

export const holdingStatementData = createSelector(selectHoldingStatement, (state) =>
    state ? (map(state, (dx) => dx) as IHoldingStatement[]).filter((dx) => !dx.isDeleted).filter((dx) => dx.displayName) : [],
);

export const holdingStatementList = createSelector(
    holdingStatementData,
    (statement) =>
        statement.map((dx) => ({
            key: dx?.id ?? '',
            text: dx?.displayName ?? '',
        })) as IComboBoxOption[],
);

type HoldingStatementState = {
    initialLoad: LoadingStatuses;
    loading: LoadingStatuses;
    data: Dictionary<IHoldingStatement>;
};

export default reducer;
