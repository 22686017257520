import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { NoteBrowserState } from './note-browser.state';
import { getNoteBrowserClinicalNotes } from './note-browser.actions';
import { LoadingStatus } from 'interfaces/loading-statuses';

export const extraReducers = (builder: ActionReducerMapBuilder<NoteBrowserState>): void => {
    builder
        .addCase(getNoteBrowserClinicalNotes.pending, (state) => {
            state.clinicalNotesLoading = LoadingStatus.Pending;
        })
        .addCase(getNoteBrowserClinicalNotes.fulfilled, (state, { payload: clinicalNotes }) => {
            state.clinicalNotesLoading = LoadingStatus.Idle;
            state.clinicalNotes = clinicalNotes;
        })
        .addCase(getNoteBrowserClinicalNotes.rejected, (state) => {
            state.clinicalNotesLoading = LoadingStatus.Failed;
            state.clinicalNotes = undefined;
        });
};
