import { NeutralColors, Text } from '@fluentui/react';

type Props = {
    text: string;
};

export default function DataItemLabel({ text }: Props) {
    return (
        <Text variant="smallPlus" style={{ color: NeutralColors.gray150 }}>
            <strong>{text}</strong>
        </Text>
    );
}
