import { Link, Stack, Text } from '@fluentui/react';
import IPatientContact from 'api/models/patient-contact.model';
import { ContentCard } from 'components';
import { useSelector } from 'hooks';
import { useDispatch } from 'react-redux';
import {
    cleanupPhoneSearch,
    selectAllPatientContacts,
    setContact,
    setIndex,
    setOpenContactModel,
} from 'state/slices/contacts/contacts.slice';
import formatPhoneNumber from 'utils/formatPhoneNumber';

type Props = {
    showActiveOnly: boolean;
};

function ContactCards({ showActiveOnly }: Props): JSX.Element {
    const allPatientContacts = useSelector(selectAllPatientContacts);

    const dispatch = useDispatch();

    const onEditContact = (contact: IPatientContact, index: number) => {
        dispatch(setOpenContactModel(true));
        dispatch(setContact(contact));
        dispatch(setIndex(index));
        dispatch(cleanupPhoneSearch());
    };

    const filteredContacts = showActiveOnly ? allPatientContacts?.filter((contact) => !contact.isDeleted) : allPatientContacts;

    const contactCards = filteredContacts?.map((item, index) => {
        return (
            <ContentCard key={index} isActive={!item.isDeleted} title={''}>
                <Stack grow verticalAlign="center" styles={{ root: { maxWidth: 250 } }}>
                    <Text variant="mediumPlus" block>
                        <Link
                            onClick={() => onEditContact(item, index)}
                        >{`${item.lastName}, ${item.firstName}${item.isGuarantor ? ' (Guarantor)' : item.isEmergencyContact ? ' (Emergency Contact)' : ''}`}</Link>
                    </Text>
                    {item.mobilePhoneNumber ? (
                        <Text variant="small" block>
                            phone: {formatPhoneNumber(item.mobilePhoneNumber)}
                        </Text>
                    ) : (
                        <Text variant="small" block>
                            phone: {formatPhoneNumber(item.homePhoneNumber)}
                        </Text>
                    )}
                    {item.email && (
                        <Text variant="small" block>
                            email: {item.email}
                        </Text>
                    )}
                </Stack>
            </ContentCard>
        );
    });
    return (
        <Stack horizontal wrap tokens={{ childrenGap: 10 }} styles={{ root: { marginTop: 10 } }}>
            {contactCards}
        </Stack>
    );
}

export default ContactCards;
