import { createAsyncThunk } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import { IUserIdentity } from 'api/models/account.model';
import axios from 'axios';
import { uniq } from 'lodash';
import { RootState } from 'state/store';
import { setUsersLoading } from './user-indentities.slice';
import { validate as validGUID } from 'uuid';

export const getUserIdentities = createAsyncThunk<
    IUserIdentity[],
    {
        tenantId: string;
        userIds: string[];
    }
>('getUserIdentities', async ({ tenantId, userIds }) => {
    const requests = userIds.map(id => dentalApi.getAccountById(tenantId, id));
    const response = await axios.all(requests);

    return response.map(res => res.data);
});

export const getMissingUserIdentities = createAsyncThunk<
    IUserIdentity[],
    {
        tenantId: string;
        userIds: string[];
    },
    { state: RootState }
>('getMissingUserIdentities', async ({ tenantId, userIds }, { dispatch, getState }) => {
    const { data, userIdsLoading } = getState().userIdentities;
    const usersToFetch = uniq(userIds.filter((id) => id !== '' && validGUID(id))).filter(
        (id) => !data[id] && !userIdsLoading.includes(id),
    );

    if (usersToFetch.length) {
        dispatch(setUsersLoading(usersToFetch));
        const res = await dispatch(getUserIdentities({ tenantId, userIds: usersToFetch })).unwrap();
        return res;
    }

    return [];
});
