import { forwardRef } from 'react';
import UACDistributionSection from './UACDistributionSection';
import AddTreatmentPlanPrepaymentList from '../TreatmentPlanCreditAndUAC/AddTreatmentPlanPrepaymentList';
import { useSelector } from 'hooks';
import {
    selectSignedTreatmentPlanView,
    selectSignedTreatmentPlanViewPhasesWithProcedureTransactions,
    selectTreatmentPlanCreditAllowOverpayment,
    selectTreatmentPlanViewLoading,
} from 'state/slices/ledger/treatment-plan-credit-and-uac/treatment-plan-credit-and-uac.selectors';
import { Stack } from '@fluentui/react';
import {
    selectUACDistributionOpenSections,
    selectUACDistributionTotalRemaining,
    selectUACDistributionTreatmentPlanAmounts,
} from 'state/slices/ledger/uac-distribution/uac-distribution.selectors';
import { useDispatch } from 'react-redux';
import {
    clearAllTreatmentPlanCreditTransactions,
    payAllTreatmentPlanCreditTransactions,
    setUACDistributionSectionOpen,
    toggleTreatmentPlanCreditOverpayment,
} from 'state/slices/ledger/ledger.slice';
import { LoadingStatus } from 'interfaces/loading-statuses';

export const UACDistributionTreatmentPlanSection = forwardRef<HTMLDivElement, { onLinkClick?: () => void }>(
    ({ onLinkClick }, ref) => {
        const dispatch = useDispatch();

        const totalAmountRemaining = useSelector(selectUACDistributionTotalRemaining);
        const signedTP = useSelector(selectSignedTreatmentPlanView);
        const { treatmentPlanRemainingTotalBalance, treatmentPlanRemainingTotalPayment } = useSelector(
            selectUACDistributionTreatmentPlanAmounts,
        );
        const phaseProceduresWithTransactionIds = useSelector(selectSignedTreatmentPlanViewPhasesWithProcedureTransactions);

        const { treatmentPlanRemainingOpen } = useSelector(selectUACDistributionOpenSections);
        const loading = useSelector(selectTreatmentPlanViewLoading);

        const overpayment = useSelector(selectTreatmentPlanCreditAllowOverpayment);

        const _clearAll = () => {
            dispatch(clearAllTreatmentPlanCreditTransactions());
        };

        const _payAll = () => {
            dispatch(payAllTreatmentPlanCreditTransactions(totalAmountRemaining));
        };

        const _toggleExpandSection = () => {
            dispatch(setUACDistributionSectionOpen('treatmentPlanRemainingOpen'));
        };

        const _toggleOverpayment = () => {
            dispatch(toggleTreatmentPlanCreditOverpayment());
        };

        if (!signedTP && loading !== LoadingStatus.Pending) return null;

        return (
            <UACDistributionSection
                isExpanded={!!treatmentPlanRemainingOpen}
                toggleExpanded={_toggleExpandSection}
                onClear={_clearAll}
                onDistribute={_payAll}
                loading={loading}
                disableClear={treatmentPlanRemainingTotalPayment <= 0}
                disableDistribute={overpayment}
                ref={ref}
                onLinkClick={onLinkClick}
                total={treatmentPlanRemainingTotalBalance}
                title={`Treatment Plan Remaining ${signedTP?.displayName ? `(${signedTP.displayName})` : ''}`}
                allowOverpayment={overpayment}
                onToggleAllowOverpayment={_toggleOverpayment}
            >
                {phaseProceduresWithTransactionIds?.map((ph) => (
                    <Stack key={ph.id} grow>
                        <span>{ph.displayName}</span>
                        <AddTreatmentPlanPrepaymentList
                            remainingAmount={totalAmountRemaining}
                            key={ph.id}
                            billingProcedures={ph.billingProcedures}
                        />
                    </Stack>
                ))}
            </UACDistributionSection>
        );
    },
);

UACDistributionTreatmentPlanSection.displayName = 'UACDistributionTreatmentPlanSection';
