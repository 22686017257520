import { ScrollablePane, Stack } from '@fluentui/react';
import usePatient from 'hooks/store/usePatient';
import { TextEditor } from 'pages/Charting/components/TextEditor';
import { SaveStatusIcon } from 'pages/components';

function PatientNotes(): JSX.Element {
    const { currentPatient, updatePatientNoteAndSave, patientSaving } = usePatient();
    return (
        <Stack style={{ position: 'relative', padding: 5 }} id="patient-note" grow>
            <Stack id="patient-note_wrapper" style={{ position: 'relative', display: 'flex', flex: 1 }} grow>
                {currentPatient?.note && (
                    <SaveStatusIcon
                        style={{ position: 'absolute', right: 0, top: 15, zIndex: 5 }}
                        itemName="Note"
                        saveStatus={patientSaving}
                    />
                )}
            </Stack>
            <ScrollablePane style={{ paddingBottom: 10 }} styles={{ contentContainer: { display: 'flex', flex: 1 } }}>
                <TextEditor value={currentPatient?.note?.note} onChange={updatePatientNoteAndSave} />
            </ScrollablePane>
        </Stack>
    );
}

export default PatientNotes;
