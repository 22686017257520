import { AnyAction, createAsyncThunk, ThunkDispatch } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import { DentitionMode, IChartDentition } from 'api/models/chart-dentition.model';
import axios from 'axios';
import { AppThunk, RootState } from 'state/store';
import getToothId from 'utils/getToothId';
import { deselectTeeth } from '../chart/chart.slice';
import { calculateAgeInYears } from 'utils/dateOnly';
import IPatient from 'api/models/patient.model';
import primaryDentition from './primaryDentition';
import mixedDentitions from './mixedDentition';

export const getChartDentitions = createAsyncThunk<
    IChartDentition[],
    {
        tenantId: string;
        patientId: string;
    },
    { dispatch: ThunkDispatch<RootState, unknown, AnyAction> }
>('getChartDentitions', async ({ tenantId, patientId }, { dispatch }) => {
    const result = await dentalApi.getChartDentitions(tenantId, patientId);
    const data = result.data;

    if (!data?.length) {
        const response = await dentalApi.getPatient(tenantId, patientId);
        const model = response.data;
        if (model) {
            const dentitions = getNewPatientDentition(model);
            if (dentitions) dispatch(createChartDentitions({ tenantId, patientId: model.id, dentitions }));
        }
    }
    return data;
});

const getNewPatientDentition = (model: IPatient): IChartDentition[] | undefined => {
    const age = calculateAgeInYears(model.dateOfBirth as string);
    if (age) {
        if (age < 7) return primaryDentition;
        if (age >= 7 && age < 12) return mixedDentitions;
    }
};

export const createChartDentition = createAsyncThunk<
    IChartDentition,
    {
        tenantId: string;
        patientId: string;
        dentition: IChartDentition;
    }
>('createChartDentition', async ({ tenantId, patientId, dentition }) => {
    const result = await dentalApi.createChartDentition(tenantId, patientId, dentition);
    return result.data;
});

export const createChartDentitions = createAsyncThunk<
    IChartDentition[],
    {
        tenantId: string;
        patientId: string;
        dentitions: IChartDentition[];
    }
>('createChartDentitions', async ({ tenantId, patientId, dentitions }) => {
    const createRes = dentitions.map((d) => dentalApi.createChartDentition(tenantId, patientId, d));
    const result = await axios.all(createRes);
    return result.map((d) => d.data);
});

export const updateChartDentition = createAsyncThunk<
    IChartDentition,
    {
        tenantId: string;
        patientId: string;
        dentition: IChartDentition;
    }
>('updateChartDentition', async ({ tenantId, patientId, dentition }) => {
    const result = await dentalApi.updateChartDentition(tenantId, patientId, dentition);
    return result.data;
});

export const updateChartDentitions = createAsyncThunk<
    IChartDentition[],
    {
        tenantId: string;
        patientId: string;
        dentitions: IChartDentition[];
    }
>('updateChartDentitions', async ({ tenantId, patientId, dentitions }) => {
    const updateRes = dentitions.map((d) => dentalApi.updateChartDentition(tenantId, patientId, d));
    const result = await axios.all(updateRes);
    return result.map((d) => d.data);
});

export const setSelectedTeethMode =
    (
        tenantId: string,
        patientId: string,
        mode: keyof typeof DentitionMode,
        dentitions: IChartDentition[],
        toothIds?: number[],
    ): AppThunk<void> =>
    (dispatch, getState) => {
        const selectedTeeth = toothIds ? toothIds : getState().charting.ui.selectedTeeth;

        if (selectedTeeth.length) {
            const updateDentitions: IChartDentition[] = dentitions
                .filter((d) => d.dentitionMode !== mode)
                .map((d) => ({ ...d, dentitionMode: mode }))
                .filter((d) => selectedTeeth.findIndex((toothId) => getToothId(toothId) === d.id) > -1);
            const createdDentitions: IChartDentition[] = selectedTeeth
                .map((toothId) => {
                    const dentition: IChartDentition = {
                        id: getToothId(toothId),
                        isDeleted: false,
                        createdOn: new Date().toISOString(),
                        dentitionMode: mode,
                        facialDegree: 0,
                        occlusalDegree: 0,
                        xPosition: 0,
                        yPosition: 0,
                    };
                    return dentition;
                })
                .filter((d) => dentitions.findIndex((sD) => sD.id === d.id) === -1);
            if (updateDentitions.length) dispatch(updateChartDentitions({ tenantId, patientId, dentitions: updateDentitions }));
            //create dentitions
            if (createdDentitions.length) dispatch(createChartDentitions({ tenantId, patientId, dentitions: createdDentitions }));

            dispatch(deselectTeeth());
        }
    };
