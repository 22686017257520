import {
    CommandBar,
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    IComboBoxOption,
    ICommandBarItemProps,
    IContextualMenuItem,
    PrimaryButton,
    Stack,
    TextField,
} from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import { IQuickAction } from 'api/models/quick-action.model';
import { IQuickActionCategory } from 'api/models/quick-action-category-model';
import { ActivityPage, Field, Section } from 'components';
import { useQuickActions, useTenantId } from 'hooks';
import { isEqual, some } from 'lodash';
import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import DeleteQuickActionsDialog from './components/DeleteQuickActionsDialog';
import EditQuickActionPanel from './components/EditQuickActionPanel';
import QuickActionCommandBar from './components/QuickActionCommandBar';
import QuickActionsList from './components/QuickActionsList';
import { deleteQuickActions, moveQuickActionsToAnotherCategory } from 'state/slices/tenant/quickActions/quickActions.actions';
import './QuickActionSettings.scss';
import { useDispatch } from 'react-redux';
import { deleteCategoryAndSave } from 'state/slices/tenant/quickActions/quickActions.slice';
import CategoriesList from './components/CategoryList';

function QuickActionSettings(): JSX.Element {
    const { cleanupQuickActions } = useQuickActions();
    useEffect(() => {
        return () => {
            cleanupQuickActions();
        };
    }, [cleanupQuickActions]);

    const [search, setSearch] = useState<string>('');

    const handleSearch = (value: string) => {
        setSearch(value);
    };

    return (
        <ActivityPage
            title="Quick Action Settings"
            hasPageHeader
            rightContent={<QuickActionCommandBar handler={(res: string) => handleSearch(res)} />}
        >
            <Stack horizontal className="quick-action-settings">
                <CategoriesList searchString={search} />
                <Stack style={{ flex: 1 }} className="edit-category">
                    <EditQuickActionCategory />
                </Stack>
            </Stack>
        </ActivityPage>
    );
}

function EditQuickActionCategory() {
    const dispatch = useDispatch();
    const tenantId = useTenantId();
    const { selectedCategory, updateQuickActionCategory, filteredQuickActionItems } = useQuickActions();
    const [category, setCategory] = useState<IQuickActionCategory | null>(selectedCategory);
    const [isHidden, { setFalse: setDialogVisible, setTrue: setDialogHidden }] = useBoolean(true);
    useEffect(() => {
        setCategory(selectedCategory);
    }, [selectedCategory]);

    if (!selectedCategory) return null;

    const _handleDisplayNameChange = (e: any, value?: string) => {
        if (selectedCategory) {
            setCategory({ ...selectedCategory, displayName: value ? value : '' });
        }
    };
    const _handleDescriptionChange = (e: any, value?: string) => {
        if (selectedCategory) {
            setCategory({ ...selectedCategory, description: value ? value : '' });
        }
    };

    const _udpateCategory = () => {
        if (category) {
            updateQuickActionCategory(category);
        }
    };

    const _handleDefaultActionChange = (e: any, option?: IComboBoxOption) => {
        if (category && option) {
            setCategory({ ...selectedCategory, defaultQuickActionId: option.key as string });
        }
    };

    const _localStateChanged = isEqual(selectedCategory, category);
    const _missingDisplayName = category?.displayName ? false : true;
    const _saveDisable = () => some([_localStateChanged, _missingDisplayName], (i) => i === true);

    const _handleCategoryDeleteClick = () => {
        setDialogVisible();
    };
    const _confirmCategoryDelete = () => {
        dispatch(deleteCategoryAndSave(tenantId, selectedCategory));
        setDialogHidden();
    };

    return (
        <div>
            <Section heading="Category Settings">
                <Stack tokens={{ childrenGap: 10 }}>
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <Stack.Item grow>
                            <TextField
                                label="Display Name"
                                value={category?.displayName}
                                required
                                onChange={_handleDisplayNameChange}
                                errorMessage={!category?.displayName ? 'Display name is required' : ''}
                            />
                        </Stack.Item>
                        <Stack.Item grow>
                            <TextField label="Description" value={category?.description} onChange={_handleDescriptionChange} />
                        </Stack.Item>
                        <Stack.Item grow>
                            <Field.SearchCombo
                                label="Default Action for Category"
                                selectedKey={category?.defaultQuickActionId}
                                options={filteredQuickActionItems.map((action: any) => ({
                                    key: action?.id ?? '',
                                    text: action?.displayName ?? '',
                                }))}
                                onChange={_handleDefaultActionChange}
                            />
                        </Stack.Item>
                    </Stack>
                    <Stack.Item align="end">
                        <Stack tokens={{ childrenGap: 10 }} horizontal>
                            <PrimaryButton text="Save" disabled={_saveDisable()} onClick={_udpateCategory} />
                            <DefaultButton text="Delete" onClick={_handleCategoryDeleteClick} />
                        </Stack>
                    </Stack.Item>
                </Stack>
            </Section>
            <AddQuickAction />
            <Dialog
                hidden={isHidden}
                onDismiss={setDialogHidden}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: 'Delete the current category?',
                    subText: 'Please confirm you want to delete the selected category.',
                }}
            >
                <DialogFooter>
                    <PrimaryButton text="Delete" onClick={_confirmCategoryDelete} />
                    <DefaultButton text="Cancel" onClick={setDialogHidden} />
                </DialogFooter>
            </Dialog>
        </div>
    );
}

function AddQuickAction() {
    const { filteredQuickActionItems, selectedCategoryId, unselectedCategories, setSelectedQuickAction } = useQuickActions();
    const [quickAction, setQuickAction] = useState<IQuickAction | null>(null);
    const [isOpen, { toggle: toggleIsOpen }] = useBoolean(false);
    const [selectedQuickActions, setSelectedQuickActions] = useState<IQuickAction[]>([]);

    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const tenantId = useTenantId();
    const dispatch = useDispatch();
    const _newQuickAction = () => {
        toggleIsOpen();
        if (selectedCategoryId) {
            setQuickAction({
                id: uuid(),
                categoryId: selectedCategoryId,
                description: '',
                displayName: '',
                isUserSpecific: false,
                procedures: [],
                conditions: [],
                iconId: '',
                isDeleted: false,
                createdOn: new Date().toISOString(),
            });
        }
    };

    const _moveActionToCategory = (category: string) => {
        dispatch(moveQuickActionsToAnotherCategory(tenantId, selectedQuickActions, category));
    };

    const moveToItems: IContextualMenuItem[] = unselectedCategories.map((category) => ({
        text: category.displayName,
        key: category.id,
        onClick: () => {
            _moveActionToCategory(category.id);
        },
    }));

    function onItemsSelected(items: IQuickAction[]) {
        setSelectedQuickActions(items);
    }

    const editQuickAction = (quickAction: IQuickAction) => {
        if (quickAction.id) {
            setQuickAction(quickAction);
            setSelectedQuickAction(quickAction.id);
            toggleIsOpen();
        }
    };

    const _deleteSelectedQuickActions = () => {
        if (selectedQuickActions && selectedQuickActions.length) {
            toggleHideDialog();
        }
    };

    const _deleteQuickActions = () => {
        dispatch(deleteQuickActions(tenantId, selectedQuickActions));
        toggleHideDialog();
    };

    const _items: ICommandBarItemProps[] = [
        {
            key: 'moveTo',
            text: 'Move To',
            iconProps: { iconName: 'MoveToFolder' },
            disabled: !selectedQuickActions.length,
            subMenuProps: {
                items: moveToItems,
            },
        },
        {
            key: 'delete',
            text: 'Delete',
            iconProps: { iconName: 'Delete' },
            disabled: !selectedQuickActions.length,
            onClick: _deleteSelectedQuickActions,
        },
    ];

    return (
        <Section heading="Quick Actions" sectionButtons={[{ text: 'Add Quick Action', onClick: _newQuickAction }]}>
            <CommandBar items={_items} />
            {filteredQuickActionItems && filteredQuickActionItems.length ? (
                <QuickActionsList
                    toggleHideDialog={toggleHideDialog}
                    onItemsSelected={onItemsSelected}
                    onEdit={editQuickAction}
                />
            ) : null}
            <EditQuickActionPanel
                isOpen={isOpen}
                toggleIsOpen={toggleIsOpen}
                quickAction={quickAction}
                setQuickAction={setQuickAction}
            />
            <DeleteQuickActionsDialog
                toggleHideDialog={toggleHideDialog}
                hideDialog={hideDialog}
                selectedQuickActions={selectedQuickActions}
                deleteQuickActions={_deleteQuickActions}
            />
        </Section>
    );
}

export default QuickActionSettings;
