import { RouteParams } from 'interfaces/route-params';
import { batch, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { handleShouldCloseEncounterNoteModal } from 'state/slices/admin-huddle/worklists/encounter-worklists/encounter-worklists.actions';
import {
    EditEncounterWorkList,
    EncounterWorkList,
} from 'state/slices/admin-huddle/worklists/encounter-worklists/encounter-worklists.state';
import { SignalRMessageAction, SignalRMessage, SignalRActionConfig } from './useSignalr';
import { MissingSlipsWorkList, WorkList } from 'state/slices/admin-huddle/worklists/worklist.state';
import { useCallback, useEffect, useState } from 'react';
import { UpsertWorkListItemArgs, upsertWorkListItem } from 'state/slices/admin-huddle/worklists/worklist.actions';
import store from 'state/store';
import { MessageBarType } from '@fluentui/react';
import {
    cleanupWorkListOpenLayers,
    setSelectedWorkListEditItem,
    setWorkListMessageBar,
} from 'state/slices/admin-huddle/worklists/worklist.slice';
import {
    closeAppointmentOverview,
    setIsCheckoutPanelOpen,
    setIsCheckinPanelOpen,
} from 'state/slices/scheduling/scheduling.slice';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { cleanupSelectedPredetermination } from 'state/slices/admin-huddle/worklists/predeterminations/predetermination.slice';

//Adds a few functions for making decisions when handling signalr messages. Adds workListMappings, which is a list of worklists
//that are mapped to signalR message.
type WorkListSignalRActionMapping = Pick<SignalRMessageAction, 'message'> & { workListMappings: string[] } & Pick<
    UpsertWorkListItemArgs,
    'onRemoveWorkListItem' | 'getShouldRemoveItem' | 'dataItemIdPath' | 'getDataBelongsToWorkList'
>;

const workListSignalRActionMappings: WorkListSignalRActionMapping[] = [
    //Anything below here are mappings that are for the "new" worklists that use view data, rather than IAdminEncounterView (For encounter worklists).
    {
        message: SignalRMessage.UpdatedPredeterminationProcedurePlanView,
        workListMappings: ['Predeterminations'],
    },
    {
        message: SignalRMessage.DeletedPredeterminationProcedurePlanView,
        workListMappings: ['Predeterminations'],
        getShouldRemoveItem: shouldRemoveDeletedWorkListItem,
        onRemoveWorkListItem: onRemovePredeterminationWorklistItem,
    },
    {
        message: SignalRMessage.UpdatedAmendRequiredEncounterView,
        workListMappings: [MissingSlipsWorkList.AmendRequireEncounters],
    },
    {
        message: SignalRMessage.DeletedAmendRequiredEncounterView,
        workListMappings: [MissingSlipsWorkList.AmendRequireEncounters],
        getShouldRemoveItem: shouldRemoveDeletedWorkListItem,
        onRemoveWorkListItem: onRemoveEncounterWorkListItem,
    },
    {
        message: SignalRMessage.UpdatedOutstandingCheckoutAppointmentView,
        workListMappings: [MissingSlipsWorkList.OutstandingCheckoutAppointments],
    },
    {
        message: SignalRMessage.DeletedOutstandingCheckoutAppointmentView,
        workListMappings: [MissingSlipsWorkList.OutstandingCheckoutAppointments],
        getShouldRemoveItem: shouldRemoveDeletedWorkListItem,
        onRemoveWorkListItem: onRemoveAppointmentWorkListItem,
    },
    {
        message: SignalRMessage.UpdatedOutstandingCheckInAppointmentView,
        workListMappings: [MissingSlipsWorkList.OutstandingCheckInAppointments],
    },
    {
        message: SignalRMessage.DeletedOutstandingCheckInAppointmentView,
        workListMappings: [MissingSlipsWorkList.OutstandingCheckInAppointments],
        getShouldRemoveItem: shouldRemoveDeletedWorkListItem,
        onRemoveWorkListItem: onRemoveAppointmentWorkListItem,
    },
    {
        message: SignalRMessage.UpdatedAwaitingAttestationAppointmentView,
        workListMappings: [MissingSlipsWorkList.AwaitingAttestation],
    },
    {
        message: SignalRMessage.DeletedAwaitingAttestationAppointmentView,
        workListMappings: [MissingSlipsWorkList.AwaitingAttestation],
        getShouldRemoveItem: shouldRemoveDeletedWorkListItem,
        onRemoveWorkListItem: onRemoveAppointmentWorkListItem,
    },
    {
        message: SignalRMessage.UpdatedOpenBilledEncounterView,
        workListMappings: [EncounterWorkList.BilledInsurance, EncounterWorkList.BilledPatient],
    },
    {
        message: SignalRMessage.DeletedOpenBilledEncounterView,
        workListMappings: [EncounterWorkList.BilledInsurance, EncounterWorkList.BilledPatient],
        getShouldRemoveItem: shouldRemoveDeletedWorkListItem,
        onRemoveWorkListItem: onRemoveEncounterWorkListItem,
    },
    {
        message: SignalRMessage.UpdatedReadyToReviewEncounterView,
        workListMappings: [EncounterWorkList.ReadyToReview, EncounterWorkList.ReadyToReviewOnHold],
    },
    {
        message: SignalRMessage.DeletedReadyToReviewEncounterView,
        workListMappings: [EncounterWorkList.ReadyToReview, EncounterWorkList.ReadyToReviewOnHold],
        getShouldRemoveItem: shouldRemoveDeletedWorkListItem,
        onRemoveWorkListItem: onRemoveEncounterWorkListItem,
    },
    {
        message: SignalRMessage.UpdatedCorrectionsCompletedEncounterView,
        workListMappings: [EncounterWorkList.ReadyToRebill, EncounterWorkList.ReadyToRebillOnHold],
    },
    {
        message: SignalRMessage.DeletedCorrectionsCompletedEncounterView,
        workListMappings: [EncounterWorkList.ReadyToRebill, EncounterWorkList.ReadyToRebillOnHold],
        getShouldRemoveItem: shouldRemoveDeletedWorkListItem,
        onRemoveWorkListItem: onRemoveEncounterWorkListItem,
    },
    {
        message: SignalRMessage.UpdatedCorrectionsNeededEncounterView,
        workListMappings: [
            EncounterWorkList.BilledPatientCorrectionNeeded,
            EncounterWorkList.BilledInsuranceCorrectionNeeded,
            EditEncounterWorkList.EditEncounters,
        ],
    },
    {
        message: SignalRMessage.DeletedCorrectionsNeededEncounterView,
        workListMappings: [
            EncounterWorkList.BilledPatientCorrectionNeeded,
            EncounterWorkList.BilledInsuranceCorrectionNeeded,
            EditEncounterWorkList.EditEncounters,
        ],
        getShouldRemoveItem: shouldRemoveDeletedWorkListItem,
        onRemoveWorkListItem: onRemoveEncounterWorkListItem,
    },
    {
        message: SignalRMessage.UpdatedCorrectionsAmendEncounterView,
        workListMappings: [
            EncounterWorkList.BilledPatientCorrectionAmend,
            EncounterWorkList.BilledInsuranceCorrectionAmend,
            EditEncounterWorkList.EditEncountersCorrectionAmend,
        ],
    },
    {
        message: SignalRMessage.DeletedCorrectionsAmendEncounterView,
        workListMappings: [
            EncounterWorkList.BilledPatientCorrectionAmend,
            EncounterWorkList.BilledInsuranceCorrectionAmend,
            EditEncounterWorkList.EditEncountersCorrectionAmend,
        ],
        getShouldRemoveItem: shouldRemoveDeletedWorkListItem,
        onRemoveWorkListItem: onRemoveEncounterWorkListItem,
    },
];
/**Handles what happens when an ENCOUNTER worklist item is removed by a signalr message. */
function onRemoveEncounterWorkListItem(data: any, editItem: any) {
    store.dispatch(handleShouldCloseEncounterNoteModal(data, editItem));
}

function onRemovePredeterminationWorklistItem() {
    store.dispatch(cleanupSelectedPredetermination());
    store.dispatch(
        setWorkListMessageBar({
            message: 'The predetermination worklist item you were editing was removed by another user.',
            messageBarType: MessageBarType.blocked,
        }),
    );
}

/**Handles what happens when an APPOINTMENT worklist item is removed by a signalr message. */
function onRemoveAppointmentWorkListItem(data: any, editItem: any, saveStatus: LoadingStatus) {
    if (editItem && editItem.id === data.id && saveStatus !== LoadingStatus.Pending) {
        batch(() => {
            store.dispatch(closeAppointmentOverview());
            store.dispatch(setIsCheckoutPanelOpen(false));
            store.dispatch(setIsCheckinPanelOpen(false));
            store.dispatch(setSelectedWorkListEditItem(undefined));
            store.dispatch(cleanupWorkListOpenLayers());
        });
        store.dispatch(
            setWorkListMessageBar({
                message: 'The appointment you were editing has been removed from the worklist by another user.',
                messageBarType: MessageBarType.blocked,
            }),
        );
    }
}

function shouldRemoveDeletedWorkListItem() {
    return true;
}
/**
 * Returns a SignalRActionConfig based on the current worklist.
 *
 * @param {WorkList} workList
 * @return {*}  {SignalRActionConfig}
 */
const useWorkListSignalRConfig = (workList: WorkList): SignalRActionConfig => {
    const dispatch = useDispatch();

    const { tenantId } = useParams<RouteParams>();
    //Holds the current singalr configuration.
    const [config, setConfig] = useState<SignalRActionConfig>([]);

    //Since there are many worklists, some worklists may use the same configuration/signalr messages. So we will look at the mappings made above to find the configuration
    //using this get config callback function.
    const getWorkListSignalRConfig = useCallback<(workList: WorkList) => SignalRActionConfig>(
        (workList) => {
            return (
                workListSignalRActionMappings
                    //Filter signalR config based on the workList.
                    .filter((mapping) => mapping.workListMappings.indexOf(workList) > -1)
                    //Map functions, message and action to SignalR action handler.
                    .map(({ message, dataItemIdPath, getShouldRemoveItem, onRemoveWorkListItem, getDataBelongsToWorkList }) => ({
                        action: (data: any) => {
                            dispatch(
                                upsertWorkListItem({
                                    tenantId,
                                    data,
                                    dataItemIdPath,
                                    getShouldRemoveItem,
                                    getDataBelongsToWorkList,
                                    onRemoveWorkListItem,
                                }),
                            );
                        },
                        message,
                    }))
            );
        },
        [dispatch, tenantId],
    );

    //Any time the worklist changes, update the signalr configuration being used.
    useEffect(() => {
        setConfig(getWorkListSignalRConfig(workList));
    }, [workList, getWorkListSignalRConfig]);

    return config; //returns the current configuration from state.
};

export default useWorkListSignalRConfig;
