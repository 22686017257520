import { IChartProcedure } from 'api/models/chart.model';
import { ConflictRuleType } from 'api/models/lookup.model';
import { IPipelineError, PipelineError } from '../pipeline';
import ProcedureConflictRulesPipeline from '../procedureConflictRules.pipeline';
import { createRule } from '../ruleGenerator';

const ProcedureTwoSurfacesOrMore = createRule<ProcedureConflictRulesPipeline, IChartProcedure>({
    ruleTypes: [ConflictRuleType.twoOrMoreSurfacesOnly],
    rule: (pipeline, item) => {
        const _errors: IPipelineError[] = [];

        if (item.areas && item.areas.length < 2) {
            _errors.push({ type: PipelineError.TwoSurfacesOrMoreOnly, data: pipeline.getProcedure(item) });
        }

        return { shouldRemoveItem: !!_errors.length, errors: _errors };
    },
});

export default ProcedureTwoSurfacesOrMore;
