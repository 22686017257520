import { PickerPlugin } from 'roosterjs-editor-plugins';
import { CustomDataProvider } from './CustomDataProvider';
import { useEffect, useMemo, useState } from 'react';
import { useId } from '@uifabric/react-hooks';
import { PickerListItemRenderFunction, PickerSuggestionOption } from './PickerComponent';
type PickerPluginArgs<T> = {
    triggerCharacter: string;
    /**
     * Array of suggestion options, required keys: [key, displayName]
     * @example
     * ```
     * [
     *   {
     *    key: "key1",
     *    displayName: "displayName1",
     *   }
     * ]
     * ```
     */
    suggestionOptions: PickerSuggestionOption<T>[];
    /**
     * Function to get the suggestion to insert, should be an string of HTML
     */
    onGetSuggestionToInsert: (option: PickerSuggestionOption<T>) => string;
    /**
     * Optional function to render the picker suggestion list item
     * @example
     * ```
     * const onRenderListItem = (option: PickerSuggestionOption<T>, index: number, selectedIndex: number) => {
     *  return <div>{option.name}</div>
     * }
     * ```
     */
    onRenderListItem?: PickerListItemRenderFunction<T>;
};
export function usePickerPlugin<T>({
    triggerCharacter,
    suggestionOptions,
    onGetSuggestionToInsert,
    onRenderListItem,
}: PickerPluginArgs<T>) {
    //Id used to identify this picker plugin in the dom.
    const pickerId = useId();

    const pickerDataProvider = useMemo(() => {
        return new CustomDataProvider<T>({
            pickerId,
            suggestionContentGetter: onGetSuggestionToInsert,
            suggestionOptions,
            onRenderListItem,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [picker] = useState<PickerPlugin>(
        new PickerPlugin(pickerDataProvider, {
            //The character that triggers the picker to show/hide.
            triggerCharacter,
            //This is the top level div where all picker plugin overlay layers are rendered in the dom.
            elementIdPrefix: 'picker-layer',
            //Identifier for change event in rooster js editor.
            changeSource: `PickerChange`,
            handleAutoComplete: true,
            suggestionsLabel: pickerId,
            //Suggestion list item prefix identifier
            suggestionLabelPrefix: `ui-`,
        }),
    );

    //Use effects used to update data provider class props. Rooster JS will only instantiate plugins once.
    //So we must update these class level properties in memory rather than declaring a new instance of the data provider class.
    useEffect(() => {
        pickerDataProvider.setSuggestionOptions = suggestionOptions;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [suggestionOptions]);

    return picker;
}
