import Pipeline, { IPipelineError } from './pipeline';
import { RuleFunction } from './ruleGenerator';

export interface IRuleArgs<P extends Pipeline<T>, T> {
    pipeline: P;
    item: T;
    rule: RuleFunction<P, T>;
}

/**
 * Rule to be run against an item or list of items.
 *
 * @export
 * @class Rule
 * @template P - Pipline type
 * @template T - Item type
 */
export default class Rule<P extends Pipeline<T>, T> {
    protected _pipeline: P;
    private _errors?: IPipelineError[];
    private _item: T | T[];
    private _shouldRemoveItem: boolean;

    constructor({ item, pipeline, rule }: IRuleArgs<P, T>) {
        this._pipeline = pipeline;
        this._item = item;
        this._shouldRemoveItem = false;

        const { shouldRemoveItem, errors, updatedItems } = rule(this._pipeline, this._item);
        if (shouldRemoveItem) this.setShouldRemoveItem = shouldRemoveItem;
        if (errors?.length) this.setErrors = errors;
        if (updatedItems) this._item = updatedItems;
    }
    /**
     * Set rule item
     *
     * @memberof Rule
     */
    public set setItem(item: T) {
        this._item = item;
    }

    /**
     * Flag to determine if the item should be removed from its containing list
     *
     * @protected
     * @memberof Rule
     */
    protected set setShouldRemoveItem(remove: boolean) {
        this._shouldRemoveItem = remove;
    }

    /**
     * Set rule errors
     *
     * @protected
     * @memberof Rule
     */
    protected set setErrors(errors: IPipelineError[] | undefined) {
        this._errors = errors;
    }

    /**
     * Get rule errors
     *
     * @readonly
     * @type {(IPipelineError[] | undefined)}
     * @memberof Rule
     */
    public get getErrors(): IPipelineError[] | undefined {
        return this._errors;
    }

    /**
     * Get flag to see if the rule item should be removed from the containing list
     *
     * @readonly
     * @type {boolean}
     * @memberof Rule
     */
    public get getShouldRemoveItem(): boolean {
        return this._shouldRemoveItem;
    }

    public get getItem(): T | T[] {
        return this._item;
    }
}
