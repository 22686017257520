import { IApiEndpointConfig } from "./apiClient";
import authProvider, { msalInstance } from "./auth/authProvider";

const isDevelopment = process.env.NODE_ENV === 'development';
const baseUrl = isDevelopment ? `${process.env.REACT_APP_AppApiUri}/api/` : '#{AppApiUri}#/api/';
const scopes = isDevelopment ? [`${process.env.REACT_APP_ApiAuthScope}`] : ['#{ApiAuthScope}#'];

export const DentalApiConfig: IApiEndpointConfig = {
    baseUrl,
    scopes,
    authProvider: msalInstance,
};
