import { createAsyncThunk } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import { ClinicalAlertType } from 'api/models/clinical-alert.model';
import { IPatientEncounter, IEncounterHistory } from 'api/models/encounter.model';
import IPatientAllergies from 'api/models/patient-allergy.model';
import IPatientCommunication from 'api/models/patient-communication.model';
import IPatientImmunizations from 'api/models/patient-immunization.model';
import IPatientMedicationsView from 'api/models/patient-medication.model';
import IPatientProblems, { IProblem } from 'api/models/patient-problem.model';
import IPatientVitals from 'api/models/patient-vital.model';
import IPatient from 'api/models/patient.model';
import IAppointmentAllocations from 'api/models/Scheduling/allocation.model';
import IUserTask from 'api/models/user-task.model';
import schedulingApi from 'api/scheduling.api';
import axios, { AxiosResponse } from 'axios';
import { push } from 'connected-react-router';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import ErrorTypes from 'state/errorTypes';
import { AppThunk, RootState } from 'state/store';
import { TaskTargets } from 'state/task-management/taskManagement.actions';
import { RecentPatient } from 'utils/appLocalStorage';
import { cleanupPatientEncounter } from '../encounter/encounter.slice';
import { getAllPatientForms } from '../forms/forms.actions';
import { setPatientMrn, setPatientNote } from './patient.slice';
import { getPatientClinicalAlerts, getPatientsClinicalAlertsLookup } from '../clinical-alert/clincal-alerts.actions';
import { patientLoading } from './patient.selectors';
import { LoadingStatus } from 'interfaces/loading-statuses';

// Patient search
export const getPatients = createAsyncThunk('getPatients', async ({ tenantId, query }: { tenantId: string; query: string }) => {
    const res = await dentalApi.getPatients(tenantId, query);
    return res;
});

export type FetchPatientPayload = {
    patient: IPatient;
    patientProblems: IPatientProblems;
    allergies: IPatientAllergies;
    medications: IPatientMedicationsView;
    vitals: IPatientVitals;
    immunizations: IPatientImmunizations;
    appointments: IAppointmentAllocations;
    encounters: IPatientEncounter[];
    communication: IPatientCommunication[];
};

export const fetchPatient = createAsyncThunk<
    FetchPatientPayload,
    {
        tenantId: string;
        patientId: string;
    },
    { state: RootState }
>('fetchPatient', async ({ tenantId, patientId }, { dispatch }) => {
    const patient = await dentalApi.getPatient(tenantId, patientId);
    const requests = [
        dentalApi.getPatientProblems(tenantId, patientId),
        dentalApi.getPatientAllergies(tenantId, patientId),
        dentalApi.getPatientMedications(tenantId, patientId),
        dentalApi.getPatientVitals(tenantId, patientId),
        dentalApi.getPatientImmunizations(tenantId, patientId),
        schedulingApi.getPatientAppointmentsByParameters(tenantId, { patientId, currentAndFutureDatesOnly: true }),
        dentalApi.getPatientEncounters(tenantId, patientId),
        dentalApi.getAllPatientCommunication(tenantId, patientId),
    ];
    const [
        { data: patientProblems },
        { data: allergies },
        { data: medications },
        { data: vitals },
        { data: immunizations },
        { data: appointments },
        { data: encounters },
        { data: communication },
    ] =
        await axios.all<
            AxiosResponse<
                | IAppointmentAllocations
                | IPatientProblems
                | IPatientAllergies
                | IPatientMedicationsView
                | IPatientImmunizations
                | IPatientVitals
                | IPatientEncounter[]
                | IPatientCommunication[]
            >
        >(requests);

    await dispatch(getAllPatientForms({ tenantId, patientId }));
    dispatch(getPatientClinicalAlerts({ tenantId, patientId }));
    await dispatch(getAllPatientForms({ tenantId, patientId }));

    const payload: FetchPatientPayload = {
        patient: patient.data,
        patientProblems: patientProblems as IPatientProblems,
        allergies: allergies as IPatientAllergies,
        medications: medications as IPatientMedicationsView,
        vitals: vitals as IPatientVitals,
        immunizations: immunizations as IPatientImmunizations,
        appointments: appointments as IAppointmentAllocations,
        encounters: encounters as IPatientEncounter[],
        communication: communication as IPatientCommunication[],
    };
    return payload;
});

export type FetchPatientOverviewPayload = Omit<FetchPatientPayload, 'patient'>;

export const fetchPatientOverview = createAsyncThunk<
    FetchPatientOverviewPayload,
    {
        tenantId: string;
        patientId: string;
    },
    { state: RootState; rejectValue: string }
>('fetchPatientOverview', async ({ tenantId, patientId }, { dispatch, rejectWithValue, getState }) => {
    try {
        const requests = [
            dentalApi.getPatientProblems(tenantId, patientId),
            dentalApi.getPatientAllergies(tenantId, patientId),
            dentalApi.getPatientMedications(tenantId, patientId),
            dentalApi.getPatientVitals(tenantId, patientId),
            dentalApi.getPatientImmunizations(tenantId, patientId),
            schedulingApi.getPatientAppointmentsByParameters(tenantId, { patientId, currentAndFutureDatesOnly: true }),
            dentalApi.getPatientEncounters(tenantId, patientId),
            dentalApi.getAllPatientCommunication(tenantId, patientId),
        ];
        const [
            { data: patientProblems },
            { data: allergies },
            { data: medications },
            { data: vitals },
            { data: immunizations },
            { data: appointments },
            { data: encounters },
            { data: communication },
        ] =
            await axios.all<
                AxiosResponse<
                    | IAppointmentAllocations
                    | IPatientProblems
                    | IPatientAllergies
                    | IPatientMedicationsView
                    | IPatientImmunizations
                    | IPatientVitals
                    | IPatientEncounter[]
                    | IPatientCommunication[]
                >
            >(requests);

        await dispatch(getAllPatientForms({ tenantId, patientId }));

        const payload: FetchPatientOverviewPayload = {
            patientProblems: patientProblems as IPatientProblems,
            allergies: allergies as IPatientAllergies,
            medications: medications as IPatientMedicationsView,
            vitals: vitals as IPatientVitals,
            immunizations: immunizations as IPatientImmunizations,
            appointments: appointments as IAppointmentAllocations,
            encounters: encounters as IPatientEncounter[],
            communication: communication as IPatientCommunication[],
        };
        return payload;
    } catch (err) {
        if (axios.isAxiosError(err) && err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue('Unkonwn error');
        }
    }
});

export const fetchPatientOverviewTasks = createAsyncThunk<
    IUserTask[],
    {
        tenantId: string;
        patientId: string;
    }
>('fetchPatientOverviewTasks', async ({ tenantId, patientId }) => {
    const patientOverviewTasks = await dentalApi.getTasksByTarget(tenantId, TaskTargets.PatientOverview, { patientId });
    return patientOverviewTasks.data;
});

export const fetchPatientCRA = createAsyncThunk<
    IUserTask[],
    {
        tenantId: string;
        patientId: string;
        encounterId: string;
    }
>('fetchPatientCraTasks', async ({ tenantId, patientId, encounterId }) => {
    const patientOverviewTasks = await dentalApi.getTasksByTarget(tenantId, TaskTargets.PatientOverview, {
        patientId,
        tenantId,
        encounterId,
    });
    return patientOverviewTasks.data;
});

export const addPatient = createAsyncThunk<
    IPatient,
    {
        tenantId: string;
        patient: IPatient;
    },
    { rejectValue: string; dispatch: ThunkDispatch<RootState, unknown, AnyAction> }
>('addPatient', async ({ tenantId, patient }, { rejectWithValue, dispatch }) => {
    try {
        const response = await dentalApi.addPatient(tenantId, patient);
        dispatch(switchPatient(patient));
        return response.data;
    } catch (err) {
        if (axios.isAxiosError(err) && err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue('Unknown error');
        }
    }
});

export const autoUpdatePatient = createAsyncThunk<
    IPatient,
    {
        tenantId: string;
        model: IPatient;
    },
    { rejectValue: string }
>('autoUpdatePatient', async ({ tenantId, model }, { rejectWithValue }) => {
    try {
        const response = await dentalApi.updatePatient(tenantId, model);
        return response.data;
    } catch (err) {
        if (axios.isAxiosError(err) && err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue('Unkonwn error');
        }
    }
});

let updatePatientNoteAndSaveTimer: NodeJS.Timeout | null = null;
export const updatePatientNoteAndSave =
    (tenantId: string, note?: string): AppThunk =>
    async (dispatch, getState): Promise<void> => {
        await dispatch(setPatientNote(note));

        if (updatePatientNoteAndSaveTimer) clearTimeout(updatePatientNoteAndSaveTimer);
        updatePatientNoteAndSaveTimer = setTimeout(() => {
            const patient = getState().patient.selectedPatient;
            updatePatientNoteAndSaveTimer = null;
            if (patient) dispatch(autoUpdatePatient({ tenantId, model: patient }));
        }, 2000);
    };

export const switchPatient =
    (patient: IPatient | RecentPatient): AppThunk =>
    (dispatch, getState): void => {
        const buildPatientRoute = (pathArray: string[], patientId: string): string => {
            const currentPatientId = pathArray[2];

            const samePatient = currentPatientId === patientId;
            const hasTenantId = pathArray.length > 1;
            const notHuddlePage = pathArray[1] !== 'clinical' && pathArray[1] !== 'administrative';
            const isSchedulingPage = pathArray[1] === 'scheduling';

            if ((hasTenantId && notHuddlePage && samePatient) || isSchedulingPage) {
                const newPath = pathArray.map((pathPart) => `/${pathPart}`);
                newPath[2] = `/${patientId}`;

                return `${newPath.join('')}`;
            } else {
                const tenantId = pathArray[0];
                const newPath = `/${tenantId}/patient/${patientId}/`;

                return newPath;
            }
        };
        const { location } = getState().router;
        const routePieces = location.pathname.split('/').filter((string) => string !== '/' && string !== '');
        const route = buildPatientRoute(routePieces, patient.id);

        dispatch(cleanupPatientEncounter());
        dispatch(push(route));
    };

export const getPatientNotes = createAsyncThunk<
    IPatientCommunication[],
    {
        tenantId: string;
        patientId: string | undefined;
    }
>('getPatientNotes', async ({ tenantId, patientId }) => {
    const res = await dentalApi.getAllPatientCommunication(tenantId, patientId!);
    return res.data;
});

export const getPastEncounters = createAsyncThunk<
    IEncounterHistory[],
    {
        tenantId: string;
        patientId: string;
    }
>('getPastEncounters', async ({ tenantId, patientId }) => {
    const res = await dentalApi.getPastEncounters(tenantId, patientId);
    return res.data;
});

export const updatePatientMrn =
    (data: { mrn: string; id: string; _etag: string }): AppThunk<void> =>
    (dispatch, getState) => {
        if (getState().patient.selectedPatient?.id === data.id) {
            dispatch(setPatientMrn(data.mrn));
        }
    };
