import { Stack } from '@fluentui/react';
import { Field } from 'components';
import { useSelector } from 'react-redux';
import {
    EncounterWorkList,
    IEncounterWorkListFiltersQuery,
} from 'state/slices/admin-huddle/worklists/encounter-worklists/encounter-worklists.state';
import { selectCurrentWorkList, selectWorkListFilters } from 'state/slices/admin-huddle/worklists/worklist.selectors';
import { WorkList } from 'state/slices/admin-huddle/worklists/worklist.state';
import { classicDateOnly } from 'utils/dateOnly';

function getDateFromDaysAgo(days: number, relativeDate?: string) {
    const negotiatedDate = relativeDate ? new Date(relativeDate) : new Date();
    negotiatedDate.setDate(negotiatedDate.getDate() - days);
    return classicDateOnly(new Date(negotiatedDate).toISOString());
}

export default function DOSFromEncounterWorkListFilter() {
    const encounterWorkList = useSelector(selectCurrentWorkList);

    const { dateOfServiceThrough } = useSelector(selectWorkListFilters<IEncounterWorkListFiltersQuery>);

    if (!encounterWorkList) return null;

    const fromDateLookup: Partial<Record<WorkList, string>> = {
        [EncounterWorkList.BilledInsurance]: getDateFromDaysAgo(30, dateOfServiceThrough),
        [EncounterWorkList.BilledPatient]: getDateFromDaysAgo(90, dateOfServiceThrough),
    };

    return (
        <Stack.Item grow>
            <Field.Date label="DOS From" hasDatePicker disabled value={fromDateLookup[encounterWorkList]} />
        </Stack.Item>
    );
}
