import { ProgressIndicator, Stack } from '@fluentui/react';
import { DocumentContext, DocumentRefTypes } from 'api/models/document.model';
import { SubSection } from 'components';
import { useSelector } from 'hooks';
import { documentsUploading } from 'state/slices/documents/documents.selectors';
import UploadedFiles from './UploadFiles/UploadedFiles';
import UploadFiles from './UploadFiles/UploadFiles';

export function renderFileIcon(fileExtension?: string): string {
    switch (fileExtension) {
        case 'docx':
            return 'WordDocument';
        case 'ppt':
            return 'PowerPointDocument';
        case 'pdf':
            return 'PDF';
        case 'jpg':
            return 'FileImage';
        case 'png':
            return 'FileImage';
        case 'txt':
            return 'TextDocument';
        default:
            return 'Page';
    }
}

export type DocumentItemReference = { type: DocumentRefTypes; id: string };

type Props = {
    patientId: string;
    context: DocumentContext;
    disabled?: boolean;
    isFileNameReadOnly?: boolean;
    reference?: DocumentItemReference;
};

/**
 * @description Upload, view, and download patient documents.
 * @export
 * @param {Props} { patientId, context, disabled, isFileNameReadOnly, reference }
 * @return {*}  {JSX.Element}
 *
 *
 * @example
 * <Upload
 *  patientId={patientId}
 *  //Context in which the upload is being used
 *  context={DocumentContext.Clinical}
 *  //If you need to attach to specific data item within context, example: specific PatientInsurance
 *  reference={{type: DocumentRefTypes.PatientInsurance, id: insurance.id}}
 * />
 */
export default function Upload({ patientId, context, disabled, isFileNameReadOnly, reference }: Props): JSX.Element {
    const uploading = useSelector(documentsUploading);

    return (
        <Stack tokens={{ childrenGap: 10 }} grow>
            <UploadFiles patientId={patientId} context={context} disabled={disabled} reference={reference} />
            {uploading === 'pending' && <ProgressIndicator label="Uploading..." />}
            <SubSection title="Uploaded Documents">
                <UploadedFiles
                    context={context}
                    disabled={disabled}
                    isFileNameReadOnly={isFileNameReadOnly}
                    reference={reference}
                />
            </SubSection>
        </Stack>
    );
}
