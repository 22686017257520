import { useValidation } from 'hooks';
import { IValidationConfig, IValidationError } from 'hooks/useValidation';
import { RouteParams } from 'interfaces/route-params';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { markEncountersAsRebilled } from 'state/slices/admin-huddle/worklists/encounter-worklists/encounter-worklists.actions';
import { markEncountersAsBilled } from 'state/slices/encounter/encounter.actions';
import { selectSelectedBatch } from 'state/slices/tenant/batches.slice';
import { selectHasRequireBatchForChargesFeatureFlag } from 'state/slices/tenant/feature-flags.slice';
import dateOnly from 'utils/dateOnly';

const approveEncounterActionLookup = {
    reapprove: markEncountersAsRebilled,
    approve: markEncountersAsBilled,
};

export function useApproveEncounters(
    encounterIds: (string | undefined)[],
    approvalType: 'reapprove' | 'approve' | undefined,
    onApproved?: () => void,
): [IValidationError[], () => void] {
    const { tenantId } = useParams<RouteParams>();
    const dispatch = useDispatch();
    const selectedBatch = useSelector(selectSelectedBatch);

    const hasBatchApproveFeature = useSelector(selectHasRequireBatchForChargesFeatureFlag);

    const onApproveAction = () => {
        if (approvalType) {
            const idsToApprove = encounterIds.filter((id) => id !== undefined) as string[];
            dispatch(
                approveEncounterActionLookup[approvalType]({
                    tenantId,
                    encounterIds: idsToApprove,
                    batchId: hasBatchApproveFeature ? selectedBatch?.id : undefined,
                }),
            );
            if (onApproved) {
                onApproved();
            }
        }
    };

    const validationConfig: IValidationConfig = hasBatchApproveFeature
        ? [
            {
                fieldName: 'NoBatchSelected',
                validation: ['required'],
                value: selectedBatch,
            },
            {
                fieldName: 'BatchSameDay',
                validation: ['required'],
                value: selectedBatch?.dateOfEntry
                    ? dateOnly(selectedBatch.dateOfEntry) === dateOnly(new Date().toISOString())
                        ? true
                        : undefined
                    : undefined,
            },
        ]
        : [];

    const [errors, approve, cleanupValidationErrors] = useValidation(validationConfig, onApproveAction);

    useEffect(() => {
        return () => {
            cleanupValidationErrors();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [errors, approve];
}
