import { DefaultButton, Panel, PanelType, PrimaryButton, Spinner, Stack, TextField } from '@fluentui/react';
import { ReportingGroupTypes } from 'api/models/reporting-group.model';
import { useSelector, useTenantId, useValidation } from 'hooks';
import { useDispatch } from 'react-redux';
import {
    reportingCategories,
    reportingSelectedCategoryLoading,
    selectedReportingGroupCategoriesList,
    selectSelectedReportingCategory,
    selectSelectedReportingGroup,
} from 'state/slices/reporting/reporting-groups/reporting-groups.selectors';
import { cleanupSelectedReportingCategory, updateSelectedReportingCategoryProp } from 'state/slices/reporting/reporting.slice';
import ReportingGroupCategoryLocationsOfCare from './ReportingGroupCategoryLOC';
import ReportingGroupCategoryPayers from './ReportingGroupCategoryPayers';
import ReportingGroupCategoryProvider from './ReportingGroupCategoryProvider';
import { ValidationType, getValidationError } from 'hooks/useValidation';
import {
    createTenantReportingCategory,
    updateTenantReportingCategory,
} from 'state/slices/reporting/reporting-groups/reporting-groups.actions';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { useCallback } from 'react';

const componentLookup = {
    [ReportingGroupTypes.Payer]: ReportingGroupCategoryPayers,
    [ReportingGroupTypes.LocationOfCare]: ReportingGroupCategoryLocationsOfCare,
    [ReportingGroupTypes.TreatingProvider]: ReportingGroupCategoryProvider,
};

const headerText = {
    [ReportingGroupTypes.Payer]: 'Manage Payer',
    [ReportingGroupTypes.LocationOfCare]: 'Manage Location of Care',
    [ReportingGroupTypes.TreatingProvider]: 'Manage Provider',
};

export default function ReportingCategoryPanel() {
    const _selectedReportingGroup = useSelector(selectSelectedReportingGroup);

    const _selectedReportingGroupCategoriesList = useSelector(selectedReportingGroupCategoriesList);
    const _selectedReportingCategory = useSelector(selectSelectedReportingCategory);
    const _categoriesLookup = useSelector(reportingCategories);
    const _loading = useSelector(reportingSelectedCategoryLoading);

    const tenantId = useTenantId();
    const dispatch = useDispatch();

    const onSave = () => {
        if (_selectedReportingCategory) {
            if (!_categoriesLookup[_selectedReportingCategory.id]) {
                //create
                dispatch(createTenantReportingCategory({ tenantId, model: _selectedReportingCategory }));
            } else {
                //update
                dispatch(updateTenantReportingCategory({ tenantId, model: _selectedReportingCategory }));
            }
        }
    };

    const validationList = _selectedReportingGroupCategoriesList
        ?.filter((c) => c?.id !== _selectedReportingCategory?.id)
        .map((c) => c?.displayName ?? '');

    const [errors, onSubmit, cleanupErrors] = useValidation(
        [
            {
                fieldName: 'name',
                validation: [ValidationType.Required, ValidationType.NotDuplicateItem],
                value: _selectedReportingCategory?.displayName,
                itemOptions: {
                    itemList: validationList,
                },
            },
        ],
        onSave,
    );

    const onDismiss = () => {
        dispatch(cleanupSelectedReportingCategory());
        cleanupErrors();
    };

    const getNameValidationError = useCallback(() => {
        const error = getValidationError(errors, 'name')?.errorTypes[0];
        switch (error) {
            case ValidationType.Required:
                return 'Category Name is required.';
            case ValidationType.NotDuplicateItem:
                return 'Category Name is already being used for this group.';
            default:
                return '';
        }
    }, [errors]);

    const Component = _selectedReportingGroup?.type
        ? componentLookup[_selectedReportingGroup?.type as ReportingGroupTypes]
        : null;

    const disableSaveButton = _loading === LoadingStatus.Pending;

    return (
        <Panel
            headerText={headerText[_selectedReportingGroup?.type as ReportingGroupTypes]}
            isOpen={!!_selectedReportingCategory}
            onDismiss={onDismiss}
            type={PanelType.custom}
            customWidth="800px"
            isLightDismiss={false}
            isFooterAtBottom
            onRenderFooterContent={() => (
                <Stack tokens={{ childrenGap: 10 }}>
                    <Stack horizontal tokens={{ childrenGap: 12 }}>
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            {disableSaveButton && <Spinner />}
                            <PrimaryButton text="Save" onClick={onSubmit} disabled={disableSaveButton} />
                        </Stack>
                        <DefaultButton text="Cancel" onClick={onDismiss} />
                    </Stack>
                </Stack>
            )}
        >
            <Stack>
                <Stack.Item grow>
                    <TextField
                        label="Category Name"
                        value={_selectedReportingCategory?.displayName}
                        required
                        onChange={(e, v) => {
                            dispatch(updateSelectedReportingCategoryProp({ path: 'displayName', value: v ?? '' }));
                        }}
                        errorMessage={getNameValidationError()}
                    />
                </Stack.Item>

                {Component && <Component />}
            </Stack>
        </Panel>
    );
}
