import { TooltipHost } from '@fluentui/react';
import { IUpcomingAppointment, patientAppointmentStatusText } from 'api/models/Scheduling/patientAppointment.model';
import { IEncounterHistory } from 'api/models/encounter.model';
import UserDisplayName from 'components/UserDisplayName';
import { TrackerStatus, trackerStatusText } from 'pages/Scheduling/components/TrackerStatusDropdown';
import { classicDateOnly } from 'utils/dateOnly';

type Props = {
    item: IEncounterHistory | IUpcomingAppointment | undefined;
};

export default function DispositionTooltip({ item }: Props) {
    const isUpcomingAppointment = (item: IUpcomingAppointment | IEncounterHistory | undefined): item is IUpcomingAppointment => {
        return !!(item as IUpcomingAppointment)?.provider;
    };

    const value = item?.isDeleted
        ? 'Canceled'
        : !isUpcomingAppointment(item) && item?.trackerStatus === TrackerStatus.Completed
        ? trackerStatusText[item.trackerStatus]
        : '';

    const userDisplayName = item?.cancellationUserId ? <UserDisplayName userId={item.cancellationUserId} /> : 'Unknown';
    return (
        <TooltipHost
            content={
                item?.isDeleted
                    ? [
                          <div key={1}>Reason: {item?.cancellationReason ?? ''}</div>,
                          <div key={2}>{item?.cancellationNote ? `Note: ${item?.cancellationNote}` : ''}</div>,
                          <div key={3}>
                              Canceled by {userDisplayName} on {classicDateOnly(item.modifiedOn)}
                          </div>,
                      ]
                    : undefined
            }
        >
            <div>
                {item?.isDeleted ? (
                    <i>{value}</i>
                ) : isUpcomingAppointment(item) ? (
                    <span>{item.appointmentStatusId ? patientAppointmentStatusText[item.appointmentStatusId] : 'N/A'}</span>
                ) : (
                    <span>{value}</span>
                )}
            </div>
        </TooltipHost>
    );
}
