import { TaskTargets, TaskType } from "state/task-management/taskManagement.actions";

interface IUserTaskItem {
    id?: string;
    createdOn: string;
    completedOn?: string;
    dueDate?: string;
    service?: string;
    target?: TaskTargets;
    references?: Record<string, string>;
    status?: string;
    severity?: TaskSeverity;
    note?: string;
    assignedTo: string[];
    type?: TaskType;
}

interface IUserTask extends IUserTaskItem {
    activities?: IUserTaskActivity[];
}

interface IUserTaskActivity {
    id?: string;
    createdOn: string;
    createdBy?: string;
    status?: string;
    userNotes?: string;
}

export enum TaskSeverity {
    High = 'High',
    // I have no idea what the severities are below high, just guessing
    Medium = 'Medium',
    Low = 'Low',
}

export default IUserTask;
