import { ITheme, Stack, IconButton, useTheme } from '@fluentui/react';
import ActivityBarLink from './ActivityBarLink';
import './ActivityBar.scss';
import { useSelector } from 'hooks';
import { setActivityBarExpanded } from 'state/slices/ui/ui.slice';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import ActivityBarMenu from 'components/ActivityBarMenu/ActivityBarMenu';

type Props = {
    theme?: ITheme;
    activities: IActivity[];
};

interface IActivity {
    keyString: string;
    tooltip: string;
    path: string;
    iconName?: string;
    icon?: JSX.Element;
    exact: boolean;

    notifications?: number;
    hidden?: boolean;
}

function ActivityBar(props: Props): JSX.Element {
    const params = useParams<{ tenantId: string; patientId: string; encounterId: string }>();
    const { patientId } = params;
    const dispatch = useDispatch();

    const { activityBarExpanded } = useSelector((state) => state.ui);

    const activityLinks = props.activities
        .filter((al) => (!patientId ? al.keyString === 'scheduling' : true))
        .map((activity) => <ActivityBarLink key={activity.keyString} expanded={activityBarExpanded} {...activity} />);

    const _setExpanded = (expanded: boolean) => {
        dispatch(setActivityBarExpanded(expanded));
    };
    if (activityLinks.length > 0) {
        return (
            <ActivityBarMenu isExpanded={activityBarExpanded} onToggleExpanded={_setExpanded}>
                <div style={{ flex: 1 }}>{activityLinks}</div>
            </ActivityBarMenu>
        );
    }
    return <></>;
}

export default ActivityBar;
