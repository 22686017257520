import { createSlice, createSelector, PayloadAction, Dictionary } from '@reduxjs/toolkit';
import { IPalette, ITheme } from '@fluentui/react';
import { ITooltipCard } from 'pages/components/TooltipCards/TooltipCard';

import { RootState } from 'state/store';
import appLocalStorage from 'utils/appLocalStorage';
import { darkTheme } from './themes/dark-theme';
import { athenaTheme } from './themes/athena-theme';
import { lightTheme } from './themes/light-theme';

type IPaletteCustom = Partial<IPalette> & { navbar: string; navbarLighter: string };
type EventColors = {
    trackerStatus: {
        [key: string]: {
            highlight: string;
            backgroundColor: string;
            buttonColor: string;
        };
    };
    trackerEncounterStatus: {
        [key: string]: {
            highlight: string;
            backgroundColor: string;
            buttonColor: string;
            color: string;
        };
    };
};

export type CustomThemeProps = {
    palette: IPaletteCustom;
    eventColors: EventColors;
};
export type IThemeCustom = ITheme & CustomThemeProps;

const themes: Dictionary<CustomThemeProps> = {
    athena: athenaTheme,
    default: lightTheme,
};

const themeToUse = themes['#{AppTheme}#'] ? themes['#{AppTheme}#'] : athenaTheme;

const initialState: UiState = {
    tooltipCards: [],
    theme: themeToUse,
    patientBannerExpanded: appLocalStorage.patientBannerExpanded,
    selectedTheme: appLocalStorage.theme,
    activityBarExpanded: appLocalStorage.activityBarExpanded,
    schedulingToolBarExpanded: appLocalStorage.schedulingToolbarExpanded,
    newPatientDemographicsIsOpen: false,
    editPatientDemographicsIsOpen: false,
};

const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        setTheme(state, action: PayloadAction<'dark' | 'light'>) {
            const { payload } = action;
            state.selectedTheme = payload;
        },
        setPatientBannerExpanded(state, action: PayloadAction<boolean>) {
            state.patientBannerExpanded = action.payload;
        },
        setActivityBarExpanded(state, action: PayloadAction<boolean>) {
            state.activityBarExpanded = action.payload;
        },
        setSchedulingToolBarExpanded(state, action: PayloadAction<boolean>) {
            state.schedulingToolBarExpanded = action.payload;
        },
        addTooltipCard(state, action: PayloadAction<ITooltipCard>) {
            state.tooltipCards = [action.payload, ...state.tooltipCards];
        },
        removeTooltipCard(state, action) {
            const id = action.payload;
            state.tooltipCards = state.tooltipCards.filter((card) => card.id !== id);
        },
        setNewPatientDemographicsPanelIsOpen: (state, action: PayloadAction<boolean>) => {
            state.newPatientDemographicsIsOpen = action.payload;
        },
        setEditPatientDemographicsPanelIsOpen: (state, action: PayloadAction<boolean>) => {
            state.editPatientDemographicsIsOpen = action.payload;
        },
    },
});

const { reducer, actions } = uiSlice;

export const {
    addTooltipCard,
    removeTooltipCard,
    setTheme,
    setPatientBannerExpanded,
    setActivityBarExpanded,
    setSchedulingToolBarExpanded,
    setNewPatientDemographicsPanelIsOpen,
    setEditPatientDemographicsPanelIsOpen,
} = actions;

export const selectTheme = (state: RootState) => state.ui.selectedTheme;
export const getSelectedTheme = createSelector(selectTheme, (theme) => (theme === 'dark' ? darkTheme : athenaTheme));

export const selectNewPatientDemographicsIsOpen = (state: RootState): boolean => state.ui.newPatientDemographicsIsOpen;
export const selectEditPatientDemographicsIsOpen = (state: RootState): boolean => state.ui.editPatientDemographicsIsOpen;

export default reducer;

type UiState = {
    theme: CustomThemeProps;
    selectedTheme: 'dark' | 'light';
    patientBannerExpanded: boolean;
    activityBarExpanded: boolean;
    schedulingToolBarExpanded: boolean;
    tooltipCards: ITooltipCard[];
    newPatientDemographicsIsOpen: boolean;
    editPatientDemographicsIsOpen: boolean;
};
