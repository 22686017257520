import { ChangeAuditSupport, ETagSupport, IdSupport, SoftDeleteSupport } from 'models/contracts';
import { TaskType } from 'state/task-management/taskManagement.actions';

type GroupedUserTasksSupports = IdSupport & ChangeAuditSupport & SoftDeleteSupport & ETagSupport;

export enum TaskGroup {
    Checkout = 'checkout',
}
export default interface IUserTasksByGroup extends GroupedUserTasksSupports {
    group: TaskGroup;
    types?: TaskType[];
}
