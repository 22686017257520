import { IColumn, Text, Link, CommandBar, SelectionMode, Stack, IconButton } from '@fluentui/react';
import { ActivityPage, ContentPane, SortableDetailsList } from 'components';
import { AccessDenied, PatientDetailsBanner } from 'pages/components';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { classicDateOnly } from 'utils/dateOnly';
import './TreatmentPlans.scss';
import usePermissions, { DentalPermissions } from 'hooks/store/usePermissions';
import useEncounterId from 'hooks/useEncounterId';
import { useChartProcedures, usePatientId, useTenantId } from 'hooks';
import { ChartTreatmentPlanStatus, IChartTreatmentPlan } from 'api/models/treatment-plan.model';
import { useDispatch, useSelector } from 'react-redux';
import { selectTreatmentPlans } from 'state/slices/charting/treatmentPlans/treatmentPlans.selectors';
import { RouteParams } from 'interfaces/route-params';
import { getChartTreatmentPlans } from 'state/slices/charting/treatmentPlans/treatmentPlans.actions';
import ReportModal from 'pages/Reporting/ReportModal';
import { allPatientEncounters } from 'state/slices/patient/patient.selectors';
import reportActionLookup from 'state/slices/reporting/reportActionLookup';
import { ReportType } from 'api/models/embed-report.model';
import PrintPredeterminationPanel from './components/PrintPredeterminations/PrintPredeterminationPanel';
import { openTreatmentPlanPredetermination } from 'state/slices/charting/treatmentPlans/predetermination/predeterminations.actions';

const defaultPageCss: React.CSSProperties = {
    padding: 10,
};

export default function TreatmentPlans(): JSX.Element {
    const dispatch = useDispatch();
    const { push } = useHistory();
    const { tenantId, patientId, encounterId } = useParams<RouteParams>();
    const { getChartProcedures } = useChartProcedures();

    const treatmentPlans = useSelector(selectTreatmentPlans);
    const _allPatientEncounters = useSelector(allPatientEncounters);

    useEffect(() => {
        getChartProcedures();
        if (patientId) dispatch(getChartTreatmentPlans({ tenantId, patientId }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientId, tenantId]);

    const _onItemInvoked = (item?: IChartTreatmentPlan) => {
        if (!item) return;
        if (item.status === ChartTreatmentPlanStatus.Signed) {
            dispatch(reportActionLookup[ReportType.TreatmentPlan]({ tenantId, chartTreatmentPlanId: item.id }));
        } else if (encounterId) {
            push(`/${tenantId}/patient/${patientId}/encounter/${encounterId}/treatment-plans/${item.id}`);
        } else {
            push(`/${tenantId}/patient/${patientId}/treatment-plans/${item.id}`);
        }
    };

    const _renderPresentedDate = (item: IChartTreatmentPlan) => {
        const date = _allPatientEncounters.find((encounter) => encounter.id === item.encounterId)?.encounterDate;

        return <Text variant="smallPlus">{date ? classicDateOnly(date) : item?.createdOn ? classicDateOnly(item.createdOn) : 'N/A'}</Text>;
    };
    const _renderSignDate = (item: IChartTreatmentPlan) => {
        if (item.signedDate && item.status === ChartTreatmentPlanStatus.Signed) {
            return <Text variant="smallPlus">{classicDateOnly(item.signedDate)}</Text>;
        } else {
            return '';
        }
    };
    const _renderStatus = (item: IChartTreatmentPlan) => {
        if (item.status === ChartTreatmentPlanStatus.Signed) {
            return <Text variant="smallPlus">Signed</Text>;
        }
        if (item.refusedToSign === true) {
            return <Text variant="smallPlus">Refused to Sign</Text>;
        } else {
            return <Text variant="smallPlus">Pending</Text>;
        }
    };
    const _renderDisplayName = (item: IChartTreatmentPlan, index?: number) => (
        <Link onClick={() => _onItemInvoked(item)}>Treatment Plan {index !== undefined ? index + 1 : ''}</Link>
    );
    const _renderLaunchPredetermination = (item: IChartTreatmentPlan) => {
        const treatmentPlan = item as IChartTreatmentPlan | undefined;

        return (
            <IconButton
                iconProps={{ iconName: 'EntitlementPolicy' }}
                title="Print Predetermination Form"
                styles={{ root: { height: 20, width: 20 } }}
                onClick={() => _openPrintPredetermination(treatmentPlan)}
            />
            // <DefaultButton
            //     iconProps={{ iconName: 'EntitlementPolicy' }}
            //     title="Print Predetermination Form"
            //     styles={{ root: { height: 25 } }}
            //     onClick={() => _openPrintPredetermination(treatmentPlan)}
            //     text="Print Form"
            // />
        );
    };

    const _openPrintPredetermination = (treatmentPlan: IChartTreatmentPlan | undefined) => {
        dispatch(openTreatmentPlanPredetermination(treatmentPlan));
    };

    const columns: IColumn[] = [
        {
            key: 'presented-date',
            name: 'Presented Date',
            minWidth: 100,
            maxWidth: 100,
            fieldName: 'createdOn',
            onRender: _renderPresentedDate,
        },
        {
            key: 'launch-predetermination',
            name: 'Predetermination Form',
            minWidth: 100,
            maxWidth: 200,
            onRender: _renderLaunchPredetermination,
        },
        {
            key: 'treatmentPlanDisplayName',
            name: 'Name',
            minWidth: 100,
            maxWidth: 200,
            onRender: _renderDisplayName,
        },
        {
            key: 'status',
            name: 'Status',
            minWidth: 100,
            maxWidth: 200,
            fieldName: 'status',
            onRender: _renderStatus,
        },
        {
            key: 'sign-date',
            name: 'Sign Date',
            minWidth: 100,
            maxWidth: 200,
            fieldName: 'modifiedOn',
            onRender: _renderSignDate,
        },
    ];

    const [hasTreatmentPlan] = usePermissions([DentalPermissions.TreatmentPlan]);

    return (
        <>
            <ReportModal />
            <ActivityPage title="Treatment Plans">
                <PatientDetailsBanner />
                {/* <PatientInsuranceBanner /> */}
                {hasTreatmentPlan ? (
                    <ContentPane style={defaultPageCss}>
                        <Stack tokens={{ childrenGap: 10 }}>
                            <TreatmentPlanCommandBar />
                            <SortableDetailsList
                                selectionMode={SelectionMode.none}
                                onItemInvoked={_onItemInvoked}
                                items={treatmentPlans}
                                columns={columns}
                                sortOnMount={true}
                                sortColumns={['createdOn']}
                            />
                        </Stack>
                        <PrintPredeterminationPanel />
                    </ContentPane>
                ) : (
                    <AccessDenied />
                )}
            </ActivityPage>
        </>
    );
}

function TreatmentPlanCommandBar() {
    const encounterId = useEncounterId();
    const patientId = usePatientId();
    const tenantId = useTenantId();
    const { push } = useHistory();

    const _newTreatmentPlan = () => {
        if (encounterId && patientId)
            push(`/${tenantId}/patient/${patientId}/encounter/${encounterId}/treatment-plans/new-treatment-plan`);
    };

    return (
        <CommandBar
            styles={{ root: { paddingLeft: 0 } }}
            items={[
                {
                    key: 'new-treatment-plan',
                    text: 'New Treatment Plan',
                    iconProps: { iconName: 'Add' },
                    onClick: _newTreatmentPlan,
                    disabled: !encounterId,
                },
            ]}
        />
    );
}
