import { SharedColors, Stack, Text, useTheme } from '@fluentui/react';
import { Banner } from 'components';
import { BannerItem } from 'components/Banner/Banner';
import { usePatientId, useSelector, useTenantId } from 'hooks';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { selectPatientEncounter } from 'state/slices/encounter/encounter.selectors';
import { selectSelectedPatient } from 'state/slices/patient/patient.selectors';
import { getSlidingFeeProgramByPatient, selectPatientSlidingFeeProgramsData } from 'state/slices/tenant/sliding-fee.slice';
import { selectTenantPayersData } from 'state/slices/tenant/tenant-payers.slice';
import currencyFormatter from 'utils/currencyFormatter';
import { isDateBetween } from 'utils/isDateBetween';

function PatientInsuranceBanner(): JSX.Element {
    const theme = useTheme();

    const patient = useSelector(selectSelectedPatient);
    const dispatch = useDispatch();
    const tenantId = useTenantId();
    const patientId = usePatientId();
    const payersLookup = useSelector(selectTenantPayersData);
    const patientSlidingFee = useSelector(selectPatientSlidingFeeProgramsData);
    const patientEncounter = useSelector(selectPatientEncounter);

    const patientInsurance = patient?.insurances?.find((insurance) => {
        const patientInsuranceIsActive = isDateBetween({
            dateToCheck: patientEncounter?.createdOn,
            start: insurance?.effectiveDate,
            end: insurance?.expirationDate,
        });
        return patientInsuranceIsActive && !insurance?.isDeleted;
    });

    useEffect(() => {
        if (patientId) dispatch(getSlidingFeeProgramByPatient({ tenantId, patientId }));
    }, [tenantId, patientId, dispatch]);

    const eligibility = patientInsurance ? patientInsurance.eligibility : undefined;
    const currentInsurance = patientInsurance?.insuranceId ? payersLookup[patientInsurance.insuranceId] : undefined;

    eligibility;
    const bannerTextStyles = {
        root: { color: theme.semanticColors.bodyText },
    };
    const bannerLabelStyles = {
        root: { color: theme.semanticColors.bodyText },
    };
    const Eligibility = () => {
        return (
            <Stack
                style={{
                    backgroundColor: SharedColors.gray10,
                    // border: `2px solid ${SharedColors.red10}`,
                    padding: 6,
                }}
            >
                <Text variant="xLarge">
                    {patientInsurance
                        ? 'Insured'
                        : patient?.slidingFees?.length && patient?.slidingFees?.[0]?.slidingFeeProgram
                            ? `Sliding Fee | ${patient?.slidingFees?.[0]?.slidingFeeProgram}`
                            : 'Self Pay'}
                </Text>
            </Stack>
        );
    };

    return (
        <>
            {patientInsurance ? (
                <Banner styles={{ backgroundColor: theme.palette.neutralLighterAlt }}>
                    <Stack style={{ paddingLeft: 10 }} tokens={{ childrenGap: 15 }} grow={1} horizontal wrap>
                        <Eligibility />
                        {patientInsurance ? (
                            <BannerItem
                                label="Insurance"
                                value={currentInsurance?.name ?? "Unknown Insurance"}
                                textStyles={bannerTextStyles}
                                labelStyles={bannerLabelStyles}
                            />
                        ) : null}
                        <BannerItem
                            label="Annual Max"
                            value={eligibility?.annualMax ? currencyFormatter.format(eligibility.annualMax) : ' '}
                            textStyles={bannerTextStyles}
                            labelStyles={bannerLabelStyles}
                        />
                        <BannerItem
                            label="INDV Deductible"
                            value={eligibility?.deductible ? currencyFormatter.format(eligibility.deductible) : ' '}
                            textStyles={bannerTextStyles}
                            labelStyles={bannerLabelStyles}
                        />
                        <BannerItem
                            label="DED Amt Remaining"
                            value={
                                eligibility?.deductibleAmountRemaining
                                    ? currencyFormatter.format(eligibility.deductibleAmountRemaining)
                                    : ' '
                            }
                            textStyles={bannerTextStyles}
                            labelStyles={bannerLabelStyles}
                        />
                        <BannerItem
                            label="Preventive"
                            value={eligibility?.preventativeBenefits ? eligibility.preventativeBenefits : ' '}
                            textStyles={bannerTextStyles}
                            labelStyles={bannerLabelStyles}
                        />
                        <BannerItem
                            label="Basic"
                            value={eligibility?.basicBenefits ? eligibility.basicBenefits : ' '}
                            textStyles={bannerTextStyles}
                            labelStyles={bannerLabelStyles}
                        />
                        <BannerItem
                            label="Major"
                            value={eligibility?.majorBenefits ? eligibility.majorBenefits : ' '}
                            textStyles={bannerTextStyles}
                            labelStyles={bannerLabelStyles}
                        />
                        <BannerItem
                            label="Basic Waiting Period"
                            value={eligibility?.waitingPeriod ? eligibility.waitingPeriod : ' '}
                            textStyles={bannerTextStyles}
                            labelStyles={bannerLabelStyles}
                        />
                        <BannerItem
                            label="Major Waiting Period"
                            value={eligibility?.majorPeriod ? eligibility.majorPeriod : ' '}
                            textStyles={bannerTextStyles}
                            labelStyles={bannerLabelStyles}
                        />
                        <BannerItem
                            label="Prophy/Exam"
                            value={eligibility?.prophyBenefits ? eligibility.prophyBenefits : ' '}
                            textStyles={bannerTextStyles}
                            labelStyles={bannerLabelStyles}
                        />
                        <BannerItem
                            label="Bitewing"
                            value={eligibility?.bitewingBenefits ? eligibility.bitewingBenefits : ' '}
                            textStyles={bannerTextStyles}
                            labelStyles={bannerLabelStyles}
                        />
                        <BannerItem
                            label="FMX"
                            value={eligibility?.fullXrayBenefits ? eligibility.fullXrayBenefits : ' '}
                            textStyles={bannerTextStyles}
                            labelStyles={bannerLabelStyles}
                        />
                        <BannerItem
                            label="Fluoride Varnish"
                            value={eligibility?.fluorideBenefits ? eligibility.fluorideBenefits : ' '}
                            textStyles={bannerTextStyles}
                            labelStyles={bannerLabelStyles}
                        />
                        <BannerItem
                            label="Missing Tooth Clause"
                            value={eligibility?.missingToothClause ? (eligibility.missingToothClause ? 'Yes' : 'No') : ' '}
                            textStyles={bannerTextStyles}
                            labelStyles={bannerLabelStyles}
                        />
                    </Stack>
                </Banner>
            ) : patient?.slidingFees?.[0]?.slidingFeeProgram?.length ? (
                <Banner styles={{ backgroundColor: theme.palette.neutralLighterAlt }}>
                    <Stack
                        style={{ paddingLeft: 10 }}
                        tokens={{ childrenGap: 15 }}
                        grow={1}
                        verticalAlign="center"
                        horizontal
                        wrap
                    >
                        <Eligibility />
                        {/* <BannerItem
                            label="Sliding Fee Program"
                            value={patientSlidingFee ? 'Dental' : ''}
                            textStyles={bannerTextStyles}
                            labelStyles={bannerLabelStyles}
                        /> */}
                        <BannerItem
                            label="Sliding Fee Plan"
                            value={patientSlidingFee?.name ? patientSlidingFee?.name : ''}
                            textStyles={bannerTextStyles}
                            labelStyles={bannerLabelStyles}
                        />
                    </Stack>
                </Banner>
            ) : (
                <Banner styles={{ backgroundColor: theme.palette.neutralLighterAlt }}>
                    <Stack
                        style={{ paddingLeft: 10 }}
                        tokens={{ childrenGap: 15 }}
                        grow={1}
                        verticalAlign="center"
                        horizontal
                        wrap
                    >
                        <Eligibility />
                        <BannerItem
                            label=""
                            value={
                                'Patient has no active insurance. Guarantor will be responsible for 100% of services rendered.'
                            }
                            textStyles={bannerTextStyles}
                            labelStyles={bannerLabelStyles}
                        />
                    </Stack>
                </Banner>
            )}
        </>
    );
}

export default PatientInsuranceBanner;
