import { PayloadAction } from '@reduxjs/toolkit';
import { IProviderScheduleTimeBlock, IProviderPeriod, IProviderTimetable, Timetable, WeekDays } from 'api/models/provider.model';
import ILocationOfCare from 'api/models/Scheduling/locationsOfCare.model';
import { cloneDeep } from 'lodash';
import { v4 as uuid } from 'uuid';
import { SettingsState } from '../settings.slice';

/**
 * edit expection
 *
 * update the exception in the state
 */
const newTimeTable = {
    [WeekDays.Sunday]: [],
    [WeekDays.Monday]: [],
    [WeekDays.Tuesday]: [],
    [WeekDays.Wednesday]: [],
    [WeekDays.Thursday]: [],
    [WeekDays.Friday]: [],
    [WeekDays.Saturday]: [],
};

export const providerSettingReducers = {
    addProviderSchedule: (state: SettingsState, action: PayloadAction<{ periodId: string }>): void => {
        const { periodId } = action.payload;
        const newPeriod: IProviderTimetable = {
            locationOfCareId: '',
            locationOfCareName: '',
            timeTables: newTimeTable,
        };

        if (state.providers.editPeriods) {
            const indexOfPeriod = state.providers.editPeriods.findIndex((period) => period.id === periodId);
            if (!state.providers.editPeriods[indexOfPeriod].schedules) state.providers.editPeriods[indexOfPeriod].schedules = [];
            state.providers.editPeriods[indexOfPeriod].schedules?.push({ ...newPeriod });
        }
    },
    openProviderModel: (state: SettingsState, action: PayloadAction<string>): void => {
        state.providers.providerPeriodId = action.payload;
    },
    addPeriod: (state: SettingsState): void => {
        const endDate = new Date();
        endDate.setMonth(endDate.getMonth() + 6);
        endDate.setDate(-1);

        state.providers.startDate = new Date().toISOString();
        state.providers.endDate = endDate.toISOString();

        const id = uuid();

        const newPeriod: IProviderPeriod = {
            id,
            startDate: '',
            endDate: '',
            exceptions: [],
            schedules: [{ locationOfCareId: '', locationOfCareName: '', timeTables: newTimeTable }],
        };
        
        state.providers.providerPeriodId = id;
        state.providers.editPeriods = [...(state.providers.editPeriods ?? []), newPeriod]
    },
    removeProviderSchedule: (
        state: SettingsState,
        action: PayloadAction<{ periodId: string; scheduleIndex: number }>,
    ): void => {
        const { periodId, scheduleIndex } = action.payload;
        if (state.providers.editPeriods) {
            const indexOfPeriod = state.providers.editPeriods.findIndex((period) => period.id === periodId)
            state.providers.editPeriods[indexOfPeriod].schedules = state.providers.editPeriods[indexOfPeriod].schedules?.filter(
                (_, index) => index !== scheduleIndex,
            );
        }
    },
    removeProviderException: (state: SettingsState, action: PayloadAction<{ periodId: string; index: number }>): void => {
        const { periodId, index } = action.payload;
        if (state.providers.editPeriods) {
            const indexOfPeriod = state.providers.editPeriods.findIndex((period) => period.id === periodId);
            state.providers.editPeriods[indexOfPeriod].exceptions = state.providers.editPeriods[indexOfPeriod].exceptions?.filter(
                (exception, i) => i !== index,
            );
        }
    },
    setLOC: (state: SettingsState, action: PayloadAction<{ periodId: string; index: number; loc: ILocationOfCare }>): void => {
        const { periodId, index, loc } = action.payload;
        if (state.providers.editPeriods) {
            const indexOfPeriod = state.providers.editPeriods.findIndex((period) => period.id === periodId);
            (state.providers.editPeriods[indexOfPeriod].schedules as IProviderTimetable[])[index] = {
                ...(state.providers.editPeriods[indexOfPeriod].schedules as IProviderTimetable[])[index],
                locationOfCareId: loc.id,
                locationOfCareName: loc.displayName,
            };
        }
    },
    clearPeriods: (state: SettingsState): void => {
        state.providers.editPeriods = [];
        state.providers.startDate = '';
        state.providers.endDate = '';
    },
    editTimetable: (
        state: SettingsState,
        action: PayloadAction<{ periodId: string; locationOfCareId: string; timetables: Timetable }>,
    ): void => {
        const { periodId, timetables, locationOfCareId } = action.payload;

        const indexOfPeriod = state.providers.editPeriods?.findIndex((period) => period.id === periodId);
        if (indexOfPeriod !== undefined && state.providers.editPeriods && state.providers.editPeriods[indexOfPeriod]) {
            const indexOfLoc = state.providers.editPeriods[indexOfPeriod].schedules?.findIndex(
                (schedule) => schedule.locationOfCareId === locationOfCareId,
            );
            if (state.providers.editPeriods[indexOfPeriod].schedules && indexOfLoc !== undefined)
                (state.providers.editPeriods[indexOfPeriod].schedules as IProviderTimetable[])[indexOfLoc].timeTables =
                    timetables;
        }
    },
    setInitialPeriods: (state: SettingsState, action: PayloadAction<IProviderPeriod[]>): void => {
        const periods = action.payload;

        state.providers.editPeriods = periods;
        state.providers.startDate = periods[0].startDate;
        state.providers.endDate = periods[0].endDate;
        state.providers.providerPeriodId = periods[0].id;
    },
    setStartDate: (state: SettingsState, action: PayloadAction<string>): void => {
        state.providers.startDate = action.payload;
    },
    setEndDate: (state: SettingsState, action: PayloadAction<string>): void => {
        state.providers.endDate = action.payload;
    },
    addRange: (
        state: SettingsState,
        action: PayloadAction<{ periodId: string; locationOfCareId: string; day: WeekDays }>,
    ): void => {
        const { locationOfCareId, periodId, day } = action.payload;
        const indexOfPeriod = state.providers.editPeriods?.findIndex((period) => period.id === periodId);
        if (
            indexOfPeriod !== undefined &&
            state.providers.editPeriods &&
            state.providers.editPeriods[indexOfPeriod] &&
            state.providers.editPeriods[indexOfPeriod].schedules
        ) {
            const indexOfLoc = state.providers.editPeriods[indexOfPeriod].schedules?.findIndex(
                (schedule) => schedule.locationOfCareId === locationOfCareId,
            );
            const timetables =
                indexOfLoc !== undefined
                    ? cloneDeep((state.providers.editPeriods[indexOfPeriod].schedules ?? [])[indexOfLoc].timeTables)
                    : undefined;
            const defaultRange = { startTime: '08:00', endTime: '18:00' };
            if (timetables) {
                if (timetables[day]?.length) {
                    // Timetable and day already exist

                    const newTimetable = {
                        ...timetables,
                        [day]: [
                            ...timetables[day],
                            {
                                startTime: timetables[day] ? timetables[day][timetables[day].length - 1].endTime : '08:00',
                                endTime: '18:00',
                            },
                        ],
                    };
                    if (indexOfLoc !== undefined && state.providers.editPeriods[indexOfPeriod].schedules?.length)
                        (state.providers.editPeriods[indexOfPeriod].schedules as IProviderTimetable[])[indexOfLoc].timeTables =
                            newTimetable;
                } else {
                    //Add default start/end time to payload day
                    if (indexOfLoc !== undefined)
                        (state.providers.editPeriods[indexOfPeriod].schedules ?? [])[indexOfLoc].timeTables = {
                            ...timetables,
                            [day]: [defaultRange],
                        };
                }
            }
        }
    },
    updateProviderPeriodProp: (
        state: SettingsState,
        action: PayloadAction<{ periodId: string; path: keyof IProviderPeriod; value: any }>,
    ): void => {
        const { path, value, periodId } = action.payload;

        if (state.providers.editPeriods) {
            const indexOfPeriod = state.providers.editPeriods?.findIndex((period) => period.id === periodId);
            state.providers.editPeriods[indexOfPeriod][path] = value;
        }
    },
    updateProviderExceptionProp: (
        state: SettingsState,
        action: PayloadAction<{ path: keyof IProviderScheduleTimeBlock; value: unknown }>,
    ): void => {
        const { path, value } = action.payload;

        if (state.providers.editException) {
            (state.providers.editException[path] as unknown) = value;
        }
    },
    saveScheduleException: (
        state: SettingsState,
        action: PayloadAction<{
            periodId: string;

            exception: IProviderScheduleTimeBlock;
        }>,
    ): void => {
        const { periodId, exception } = action.payload;

        const indexOfPeriod = state.providers.editPeriods?.findIndex((period) => period.id === periodId);
        if (indexOfPeriod !== undefined && state.providers.editPeriods && state.providers.editPeriods[indexOfPeriod]) {
            if (!state.providers.editPeriods[indexOfPeriod].schedules) state.providers.editPeriods[indexOfPeriod].exceptions = [];
            (state.providers.editPeriods[indexOfPeriod].exceptions as IProviderScheduleTimeBlock[]).push(exception);
        }
    },

    editExpection: (
        state: SettingsState,
        action: PayloadAction<{ exception: IProviderScheduleTimeBlock; index: number }>,
    ): void => {
        const { exception, index } = action.payload;
        state.providers.editException = exception;
        state.providers.providerExceptionIndex = index;
        state.providers.isAddException = false;
    },

    updateExpceptionOnPeriod: (
        state: SettingsState,
        action: PayloadAction<{ periodId: string; index: number; exception: IProviderScheduleTimeBlock }>,
    ): void => {
        const { periodId, index, exception } = action.payload;
        const indexOfPeriod = state.providers.editPeriods?.findIndex((period) => period.id === periodId);
        if (indexOfPeriod !== undefined && state.providers.editPeriods && state.providers.editPeriods[indexOfPeriod]) {
            (state.providers.editPeriods[indexOfPeriod].exceptions as IProviderScheduleTimeBlock[])[index] = exception;
        }
    },

    addExceptionToPeriod: (
        state: SettingsState,
        action: PayloadAction<{ periodId: string; exception: IProviderScheduleTimeBlock }>,
    ): void => {
        const { periodId, exception } = action.payload;

        if (state.providers.editPeriods) {
            const indexOfPeriod = state.providers.editPeriods.findIndex((period) => period.id === periodId);
            if (!state.providers.editPeriods[indexOfPeriod].exceptions)
                state.providers.editPeriods[indexOfPeriod].exceptions = [];

            state.providers.editPeriods[indexOfPeriod].exceptions = [
                ...(state.providers.editPeriods[indexOfPeriod].exceptions as IProviderScheduleTimeBlock[]),
                exception,
            ];
        }
    },
    createNewExpections: (state: SettingsState, action: PayloadAction<IProviderScheduleTimeBlock>): void => {
        const exception = action.payload;

        state.providers.editException = exception;
        state.providers.isAddException = true;
    },

    openExceptionModal: (state: SettingsState, action: PayloadAction<boolean>): void => {
        state.providers.openExceptionModal = action.payload;
    },
    openEmergencyExceptionModal: (state: SettingsState, action: PayloadAction<boolean>): void => {
        state.providers.openEmergencyModal = action.payload;
    },
    // clearExceptionModal: (state: SettingsState, action: PayloadAction<{ periodId: string }>): void => {
    //     const { periodId } = action.payload;

    //     if (state.providers.editPeriods) {
    //         const indexOfPeriod = state.providers.editPeriods.findIndex((period) => period.id === periodId);
    //         state.providers.editPeriods[indexOfPeriod].exceptions?.filter((ex) => ex.type === 'exception');
    //     }
    // },
};
