export type FinancialSummaryReportQuery = {
    dateType?: FinancialSummaryDateType;
    groupCol?: string;
    startDate?: string;
    endDate?: string;
    dateGroup?: FinancialSummaryDateGroup;
    locationOfCareIds?: string[];
    treatingProviderIds?: string[];
    insuranceCarrierIds?: string[];
    payerType?: string;
    procedureIds?: string[];
};

export enum FinancialSummaryDateType {
    Entry = 'Entry',
    Service = 'Service',
}

export enum FinancialSummaryDateGroup {
    Entry = 'Day',
    Service = 'Month',
}

export enum FinancialSummaryGroup {
    TreatingProvider = 'TreatingProviderId',
    LocationOfCare = 'LocationOfCareId',
    PrimaryInsurance = 'PrimaryInsuranceId',
}

export const lookupFinancialSummaryGroupDisplayName = {
    [FinancialSummaryGroup.LocationOfCare]: 'Location of Care',
    [FinancialSummaryGroup.TreatingProvider]: 'Treating Provider',
    [FinancialSummaryGroup.PrimaryInsurance]: 'Primary Insurance',
};
