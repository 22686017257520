import { Dictionary } from '@reduxjs/toolkit';
import { IBillingProcedure } from 'api/models/billing-procedure.model';
import IAppointmentAllocations from 'api/models/Scheduling/allocation.model';
import IBlockAppointment from 'api/models/Scheduling/blockAppointment.model';
import IOperatoryCalendarLayout from 'api/models/Scheduling/layout.model';
import ILocationOfCare from 'api/models/Scheduling/locationsOfCare.model';
import IOperatory from 'api/models/Scheduling/operatory.model';
import IPatientAppointment from 'api/models/Scheduling/patientAppointment.model';
import { IChartTreatmentPlan } from 'api/models/treatment-plan.model';
import IUserTask from 'api/models/user-task.model';
import { LoadingStatus, LoadingStatuses } from 'interfaces/loading-statuses';
import AppointmentToCancelState, { appointmentToCancelInitialState } from './appointment-to-cancel/appointment-to-cancel.state';
import schedulingInitialState, { ISelectedAppointmentState } from './schedule-appointment/schedule-appointment.state';
import { IUpcomingTreatmentPlanPhaseData } from './scheduling.selectors';
import { CheckInSections, IQuickViewSchedule } from './scheduling.slice';
import ErrorTypes from 'state/errorTypes';

const initialState: SchedulingState = {
    loading: LoadingStatus.Idle,
    selectedAppointmentType: undefined,
    selectedDate: new Date(),
    selectedLOC: undefined,
    tracker: undefined,
    selectedAppointment: schedulingInitialState,
    appointmentToCancel: appointmentToCancelInitialState,
    selectedOperatories: {},
    selectedOperatoryTags: {},
    ui: {
        expandSchedule: false,
        toolbarButtonIsDisabled: true,
        setPatientOverviewOpen: false,
        isCheckoutPanelOpen: false,
        isScheduleAppointmentPanelOpen: false,
        disableHIPAAView: false,
    },
    checkin: {
        isCheckInPanelOpen: false,
        visibleSections: [],
        finishCheckinLoading: LoadingStatus.Idle,
    },
    layouts: {
        data: undefined,
        loading: LoadingStatus.Idle,
    },
    allocations: {
        data: undefined,
        loading: LoadingStatus.Idle,
        draggedPatient: [],
        draggedBlock: [],
    },
    locationsOfCare: {
        data: undefined,
        loading: LoadingStatus.Idle,
    },
    quickViewSchedules: {
        data: undefined,
        loading: LoadingStatus.Idle,
    },
    treatmentPlans: {
        data: undefined,
        loading: LoadingStatus.Idle,
    },

    appointmentCheckout: {
        data: undefined,
        loading: LoadingStatus.Idle,
        finishCheckoutLoading: LoadingStatus.Idle,
        selectedUpcomingPhases: [],
    },
    patientCheckoutTasks: {
        data: [],
        loading: LoadingStatus.Idle,
    },
};

export default initialState;

export type TrackerCount = Record<string, number>;

export enum AppointmentType {
    Patient = 'patient',
    Block = 'block',
}

export type SchedulingState = {
    providerPatientAppointments?: IPatientAppointment[];
    selectedAppointmentType?: AppointmentType;
    selectedAppointment: ISelectedAppointmentState;
    selectedDate: Date;
    selectedLOC?: ILocationOfCare;
    selectedOperatories: Record<string, string[]>;
    selectedOperatoryTags: Dictionary<Dictionary<string[]>>;
    tracker?: TrackerCount;
    alertDialogMessage?: string;
    loading: LoadingStatuses;
    appointmentToCancel: AppointmentToCancelState;
    ui: {
        expandSchedule: boolean;
        toolbarButtonIsDisabled: boolean;
        setPatientOverviewOpen: boolean;
        isCheckoutPanelOpen: boolean;
        isScheduleAppointmentPanelOpen: boolean;
        disableHIPAAView: boolean;
    };
    checkin: {
        isCheckInPanelOpen: boolean;
        visibleSections: CheckInSections[];
        finishCheckinLoading: LoadingStatuses;
        error?: ErrorTypes;
    };
    layouts: {
        data?: IOperatoryCalendarLayout[];
        loading: LoadingStatuses;
    };
    allocations: {
        data?: IAppointmentAllocations;
        loading: LoadingStatuses;
        draggedPatient: IPatientAppointment[];
        draggedBlock: IBlockAppointment[];
    };
    locationsOfCare: {
        data?: ILocationOfCare[];
        loading: LoadingStatuses;
    };
    quickViewSchedules: {
        data?: IQuickViewSchedule[];
        loading: LoadingStatuses;
    };
    treatmentPlans: {
        data?: IChartTreatmentPlan[];
        loading: LoadingStatuses;
    };
    appointmentCheckout: {
        data?: IBillingProcedure[];
        error?: string;
        selectedUpcomingPhases: IUpcomingTreatmentPlanPhaseData[];
        loading: LoadingStatuses;
        finishCheckoutLoading: LoadingStatuses;
    };
    patientCheckoutTasks: {
        data: IUserTask[];
        loading: LoadingStatuses;
    };
};
