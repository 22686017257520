import {
    Label,
    Link,
    mergeStyles,
    PrimaryButton,
    Separator,
    Stack,
    StackItem,
    Text,
    TooltipHost,
    useTheme,
} from '@fluentui/react';
import { IChartPerioExamRecord, SurfacesNumber } from 'api/models/perio-exam.model';
import { usePerioExams, useSelector } from 'hooks';
import { RouteParams } from 'interfaces/route-params';
import { map, orderBy } from 'lodash';
import { toothSpriteReferences } from 'pages/Charting/components/ToothCanvas/spriteList';
import { MouseEvent, useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { classicDateOnly } from 'utils/dateOnly';
import { LOWER_POSITIONS, UPPER_POSITIONS } from '../perio-settings';
import { allPatientEncounters } from 'state/slices/patient/patient.selectors';
import usePatient from 'hooks/store/usePatient';
import { EncounterStatus, IPatientEncounter } from 'api/models/encounter.model';
import { useDispatch } from 'react-redux';
import { resetPerioExamPanel } from 'state/slices/charting/chart/chart.slice';
import { selectPerioExamsWithEncounterDate } from 'state/slices/charting/perio-exams/perio-exams.selectors';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { convertFromMilitaryTime } from 'utils/convertFromMilitaryTime';

const MIN_COL_WIDTH = 50;
const LABEL_COL_WIDTH = 45;
const LABEL_TABLE_GAP = 8;
const CELL_PADDING = 2;

type PerioHistoryRowProps = {
    isMaxillary: boolean;
    rowData: (PerioHistoryRowData | undefined)[];
    index: number;
};

export function PerioHistoryRow({ rowData, index, isMaxillary }: PerioHistoryRowProps): JSX.Element {
    const { palette } = useTheme();
    const { push } = useHistory();
    const { tenantId, patientId, encounterId } = useParams<RouteParams>();

    const className = mergeStyles({
        padding: CELL_PADDING,
        backgroundColor: `${palette.white}`,
        borderTop: `1px solid ${palette.neutralPrimaryAlt}`,
        borderLeft: `1px solid ${palette.neutralPrimaryAlt}`,
        ':nth-child(17)': {
            borderRight: `1px solid ${palette.neutralPrimaryAlt}`,
        },
        ':nth-child(9)': {
            borderRight: `2px dotted ${palette.neutralPrimaryAlt}`,
        },
        ':nth-child(10)': {
            borderLeft: `none`,
        },
        borderBottom: index === 2 ? `1px solid ${palette.neutralPrimaryAlt}` : 'none',
    });

    const firstRow = rowData.find((r) => r !== undefined);
    const encounter = firstRow?.encounter;
    const id = firstRow?.id;

    const isExamDisabled = encounter?.status === EncounterStatus.Billed || encounter?.status === EncounterStatus.Completed;
    const isConversionImported = encounter?.status === EncounterStatus.ConversionImported;

    const row = (isMaxillary ? rowData : rowData.reverse()).map((row, index) => {
        const position = row?.position;
        const flipDistalMesial = position ? position < 9 || position > 24 : false;
        const mesialProperty = flipDistalMesial ? 'mesial' : 'distal';
        const distalProperty: keyof SurfacesNumber = flipDistalMesial ? 'distal' : 'mesial';

        const toothRef = toothSpriteReferences.find((ref) => ref.id === row?.toothId);
        const toothLabel = toothRef ? toothRef.displayName : isMaxillary ? index + 1 : 32 - index;

        return (
            <Stack key={index} className={className} style={{ minWidth: MIN_COL_WIDTH }}>
                <Stack grow>
                    {row && (
                        <>
                            <Stack horizontalAlign="center" grow>
                                <Stack
                                    style={{
                                        border: `1px solid gray`,
                                        borderRadius: '50%',
                                        width: 16,
                                        height: 16,
                                        fontSize: 10,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <span>{toothLabel}</span>
                                </Stack>
                            </Stack>
                            <Stack tokens={{ childrenGap: 8 }} horizontalAlign="center" horizontal grow>
                                {row.probing && (
                                    <>
                                        <StackItem>
                                            <Text>{row.probing[distalProperty]}</Text>
                                        </StackItem>
                                        <StackItem>
                                            <Text>{row.probing['middle']}</Text>
                                        </StackItem>
                                        <StackItem>
                                            <Text>{row.probing[mesialProperty]}</Text>
                                        </StackItem>
                                    </>
                                )}
                            </Stack>
                        </>
                    )}
                </Stack>
            </Stack>
        );
    });

    const _navigateToPerioExam = (event: MouseEvent) => {
        event.preventDefault();
        if (encounterId) {
            push(`/${tenantId}/patient/${patientId}/encounter/${encounterId}/perio/${id}`);
        } else {
            push(`/${tenantId}/patient/${patientId}/perio/${id}`);
        }
    };
    const encounterDate = encounter?.encounterDate ? classicDateOnly(encounter.encounterDate) : '';
    const appointmentStartTime = firstRow?.appointmentStartTime ? ` - ${convertFromMilitaryTime(firstRow.appointmentStartTime)}` : '';
    const dateTimeText = `${encounterDate}${appointmentStartTime}`
    return (
        <Stack horizontal grow>
            <StackItem
                className={className}
                style={{ minWidth: 140, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <TooltipHost
                    content={isConversionImported ? 'This exam is conversion imported and can only be viewed.' : undefined}
                >
                    <Link disabled={isExamDisabled} onClick={_navigateToPerioExam}>
                        {dateTimeText}
                        {isConversionImported ? '*' : ''}
                    </Link>
                </TooltipHost>
            </StackItem>
            {row}
        </Stack>
    );
}

type PerioHistoryRowData = {
    probing?: SurfacesNumber;
    toothId?: number;
    position?: number;
    encounter?: IPatientEncounter;
    id: string;
    appointmentStartTime?: string;
};

type PerioHistoryGridProps = {
    getRowsData: (isMaxillary: boolean, isFacial: boolean) => (PerioHistoryRowData | undefined)[][];
    isMaxillary: boolean;
    isFacial: boolean;
};

export function PerioHistoryGrid({ isMaxillary, isFacial, getRowsData }: PerioHistoryGridProps): JSX.Element {
    const rowsData = getRowsData(isMaxillary, isFacial);
    const rows = rowsData.map((row, index) => (
        <PerioHistoryRow key={index} isMaxillary={isMaxillary} index={index} rowData={row} />
    ));

    return (
        <Stack grow>
            <Stack horizontal grow>
                <div style={{ minWidth: LABEL_COL_WIDTH + LABEL_TABLE_GAP, maxWidth: LABEL_COL_WIDTH + LABEL_TABLE_GAP }}></div>
                <StackItem style={{ minWidth: 140 }}>
                    <Label>Date/Time</Label>
                </StackItem>
                <StackItem style={{ marginLeft: CELL_PADDING * 2 + 1 }} grow>
                    <Label>Probing Depths</Label>
                </StackItem>
            </Stack>
            <Stack tokens={{ childrenGap: LABEL_TABLE_GAP }} verticalAlign="center" horizontal grow>
                <StackItem style={{ minWidth: LABEL_COL_WIDTH }}>
                    <Label>{isFacial ? 'Facial' : 'Lingual'}</Label>
                </StackItem>
                <Stack grow>{rows}</Stack>
            </Stack>
        </Stack>
    );
}

export default function PerioHistory(): JSX.Element {
    const { getPerioExams, saving } = usePerioExams();
    const { palette } = useTheme();
    const { createPerioExam } = usePerioExams();
    const { perioId, encounterId } = useParams<{ perioId: string; encounterId: string }>();
    const perioExams = useSelector(selectPerioExamsWithEncounterDate);
    const dispatch = useDispatch();

    const existingExam = () => (perioExams.find((res) => res.encounterId === encounterId) ? true : false);

    useEffect(() => {
        getPerioExams();
        dispatch(resetPerioExamPanel(false));
    }, [getPerioExams, dispatch]);

    const facialLingual = (isFacial: boolean) => (isFacial ? 'facial' : 'lingual');

    const getRowsData = useCallback(
        (isMaxillary: boolean, isFacial: boolean) => {
            return perioExams.slice(0, 3).map((exam) => {
                const teethDataByPosition: (PerioHistoryRowData | undefined)[] = [];
                (isMaxillary ? UPPER_POSITIONS : LOWER_POSITIONS).forEach((position) => {
                    const perioToothIdList = map(exam.teeth, (tooth, key) => +key);
                    const findToothWithPosition = toothSpriteReferences.find(
                        (ref) => ref.position === position && perioToothIdList.indexOf(ref.id) > -1,
                    );
                    const perioTooth: IChartPerioExamRecord | undefined =
                        findToothWithPosition && exam.teeth
                            ? (exam.teeth[findToothWithPosition.id] as any)[facialLingual(isFacial)]
                            : undefined;
                    if (perioTooth && findToothWithPosition && exam.encounterId) {
                        teethDataByPosition.push({
                            position,
                            probing: perioTooth.probing,
                            toothId: findToothWithPosition.id,
                            id: exam.id,
                            encounter: exam.encounter,
                            appointmentStartTime: exam.appointmentStartTime,
                        });
                    } else {
                        teethDataByPosition.push(undefined);
                    }
                });

                return isMaxillary ? teethDataByPosition : teethDataByPosition.reverse();
            });
        },
        [perioExams],
    );

    if (perioExams.length)
        return (
            <Stack style={{ backgroundColor: `${palette.white}`, padding: 10 }}>
                <Separator alignContent="start">
                    <Label>Maxillary</Label>
                </Separator>
                <Stack horizontalAlign="center">
                    <PerioHistoryGrid getRowsData={getRowsData} isMaxillary={true} isFacial={true} />
                    <PerioHistoryGrid getRowsData={getRowsData} isMaxillary={true} isFacial={false} />
                </Stack>

                <Separator alignContent="start">
                    <Label>Mandibular</Label>
                </Separator>
                <Stack horizontalAlign="center">
                    <PerioHistoryGrid getRowsData={getRowsData} isMaxillary={false} isFacial={true} />
                    <PerioHistoryGrid getRowsData={getRowsData} isMaxillary={false} isFacial={false} />
                </Stack>
            </Stack>
        );

    return (
        <Stack tokens={{ childrenGap: 20 }} style={{ backgroundColor: `${palette.white}`, padding: 10 }} horizontalAlign="center">
            <Text variant="large">No exam history found.</Text>
            <PrimaryButton
                text="Create New Exam"
                iconProps={{ iconName: 'Add' }}
                disabled={
                    perioId || !encounterId ? true : false || existingExam() || saving === LoadingStatus.Pending ? true : false
                }
                onClick={createPerioExam}
            />
        </Stack>
    );
}
