import { mergeStyles, MessageBar, MessageBarType, Spinner, Stack, Text, useTheme } from '@fluentui/react';
import dentalApi from 'api/dental.api';
import IChartNote, { IChartNoteWithData, IChartNoteWithValueData } from 'api/models/chart-note.model';
import IWorkListEncounterView from 'api/models/worklist-encounter-view';
import axios from 'axios';
import { Loading } from 'components';
import useUserIdentities from 'hooks/store/useUserIdentities';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { RouteParams } from 'interfaces/route-params';
import PanelSectionHeader from 'pages/components/PanelSectionHeader';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { classicDateOnly } from 'utils/dateOnly';
import RenderNote from 'utils/renderChartNote';

export default function VisitSummaryNotes(props: {
    selectedEncounterWorklistView: IWorkListEncounterView | undefined;
}): JSX.Element {
    const { tenantId } = useParams<RouteParams>();
    const { selectedEncounterWorklistView } = props;
    const { getMissingUserIdentities } = useUserIdentities();

    /**State used for get latest chart note api call */
    const [chartNote, setChartNote] = useState<IChartNote>();
    const [loadingChartNote, setLoadingChartNote] = useState<LoadingStatus>(LoadingStatus.Idle);
    const [errorMessage, setErrorMessage] = useState<string>();

    /**Get the latest chart note. */
    const getChartNoteForVisitSummary = useCallback(() => {
        setLoadingChartNote(LoadingStatus.Pending);
        if (selectedEncounterWorklistView?.encounterId && selectedEncounterWorklistView?.patientId)
            dentalApi
                .getChartNoteByEncounterId(
                    tenantId,
                    selectedEncounterWorklistView.patientId,
                    selectedEncounterWorklistView?.encounterId,
                )
                .then((res) => {
                    const note = res.data;
                    setChartNote(note);
                    setLoadingChartNote(LoadingStatus.Completed);
                    if (note.createdBy) getMissingUserIdentities([note.createdBy]);
                })
                .catch((e) => {
                    if (axios.isAxiosError(e)) setErrorMessage(`Something went wrong. (${e.message})`);
                    setLoadingChartNote(LoadingStatus.Failed);
                });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenantId, selectedEncounterWorklistView?.encounterId, selectedEncounterWorklistView?.patientId]);

    useEffect(() => {
        getChartNoteForVisitSummary();
    }, [getChartNoteForVisitSummary]);

    return (
        <Stack tokens={{ childrenGap: 5 }}>
            <PanelSectionHeader text="Chart Note" />
            <Stack styles={{ root: { overflowY: 'auto', maxHeight: 500 } }} grow>
                {loadingChartNote === LoadingStatus.Failed && errorMessage && (
                    <MessageBar messageBarType={MessageBarType.error}>{errorMessage}</MessageBar>
                )}
                {loadingChartNote === LoadingStatus.Pending && <Spinner label="Loading Chart Note..." />}
                {chartNote && loadingChartNote === LoadingStatus.Completed ? (
                    <Note note={chartNote as IChartNoteWithData<IChartNoteWithValueData>} />
                ) : (
                    <MessageBar>No notes have been added</MessageBar>
                )}
            </Stack>
        </Stack>
    );
}

type NoteProps = {
    note: IChartNoteWithData<IChartNoteWithValueData>;
};

function Note({ note }: NoteProps) {
    const { palette } = useTheme();
    const { userIdentitiesData } = useUserIdentities();
    const date = note.createdOn ? classicDateOnly(note.createdOn) : '';

    const className = mergeStyles({
        transition: '.1s all',
        padding: 5,
        borderRadius: 3,
    });

    return (
        <Stack
            verticalAlign="center"
            className={className}
            styles={{ root: { position: 'relative' } }}
            tokens={{ childrenGap: 4 }}
            grow
        >
            <div
                style={{ color: palette.neutralSecondary }}
                dangerouslySetInnerHTML={{
                    __html: note.data.value ? new RenderNote(note).buildNoteString() : '',
                }}
            ></div>
            <Text variant="small" styles={{ root: { color: palette.neutralSecondary } }}>
                {date} by {note.createdBy ? userIdentitiesData[note.createdBy]?.displayName : 'Unknown'}
            </Text>
        </Stack>
    );
}
