import { Dropdown, Stack } from '@fluentui/react';
import { useSelector } from 'hooks';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { selectWorkListFilters } from 'state/slices/admin-huddle/worklists/worklist.selectors';
import { setWorkListFilter } from 'state/slices/admin-huddle/worklists/worklist.slice';

export default function RCMFilter() {
    const dispatch = useDispatch();
    const { isRcm } = useSelector(selectWorkListFilters<any>);

    const value = useMemo(() => {
        if (isRcm) return 'billingServicesOnly';
        if (isRcm === false) return 'nonBillingServices';
        return '';
    }, [isRcm]);

    return (
        <Stack.Item grow>
            <Dropdown
                label="Billing Services"
                selectedKey={value}
                options={[
                    {
                        key: '',
                        text: 'Show All',
                    },
                    {
                        key: 'billingServicesOnly',
                        text: 'Billing Services Only',
                    },
                    {
                        key: 'nonBillingServices',
                        text: 'Non-Billing Services Only',
                    },
                ]}
                onChange={(e, option) => {
                    if (option) {
                        switch (option.key) {
                            case 'billingServicesOnly': {
                                dispatch(setWorkListFilter({ path: 'isRcm', value: true }));
                                break;
                            }
                            case 'nonBillingServices': {
                                dispatch(setWorkListFilter({ path: 'isRcm', value: false }));
                                break;
                            }
                            default:
                                dispatch(setWorkListFilter({ path: 'isRcm', value: undefined }));
                        }
                    }
                }}
            />
        </Stack.Item>
    );
}
