import { FormEvent, useEffect, useRef } from 'react';
import { TextField, ITextFieldProps } from '@fluentui/react';
import { StateType } from './StateOptions';

export type ZipCodeFieldProps = {
    handleError?: (error: string | undefined) => void;
    stateType?: StateType;
    onChange?: (event?: FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string | undefined) => void;
} & Omit<ITextFieldProps, 'onChange'>;

export const stateTypeZipFormatLookup: Record<
    StateType,
    { allowedCharsRegex: RegExp; validRegex: RegExp; spacerChar: string; numberCharsToInsertSpacer: number; maxLength: number }
> = {
    [StateType.Canada]: {
        allowedCharsRegex: new RegExp(/^[A-Z.0-9. ]+$/),
        validRegex: new RegExp(/^[A-Z.0-9]{3}( )?[A-Z.0-9]{3}$/i),
        spacerChar: ' ',
        numberCharsToInsertSpacer: 3,
        maxLength: 7,
    },
    [StateType.US]: {
        allowedCharsRegex: new RegExp(/^[0-9.-]+$/),
        validRegex: new RegExp(/^\d{5}(?:[-\s]\d{4})?$/),
        spacerChar: '-',
        numberCharsToInsertSpacer: 5,
        maxLength: 10,
    },
};

export default function ZipCodeField({
    value,
    onChange,
    handleError,
    stateType = StateType.US,
    required,
    ...props
}: ZipCodeFieldProps): JSX.Element {
    const { allowedCharsRegex, numberCharsToInsertSpacer, spacerChar, validRegex, maxLength } =
        stateTypeZipFormatLookup[stateType];
    const stateTypePrev = useRef(stateType);

    useEffect(() => {
        if (onChange && stateTypePrev.current !== stateType) onChange(undefined, '');
        stateTypePrev.current = stateType;
    }, [stateType]);

    const onGetErrorMessage = (value: string) => {
        if (required && value === '') return 'Zip is required.';
        const errorZipMessage = validRegex.test(value) ? undefined : 'Invalid Zip';
        if (handleError) handleError(errorZipMessage);
        return errorZipMessage;
    };

    const formatZipCode = (value: string | undefined) => {
        if (value === undefined) return undefined;
        let upperCaseValue = value.toUpperCase();
        const valueLength = value.length;
        if (value[numberCharsToInsertSpacer] !== spacerChar && valueLength === numberCharsToInsertSpacer + 1) {
            //Insert spacer into the string
            upperCaseValue = `${value.slice(0, valueLength - 1)}${spacerChar}${value[valueLength - 1]}`;
        } else if (value[valueLength - 1] === spacerChar) {
            //Remove spacer from the string
            upperCaseValue = value.replace(spacerChar, '');
        }

        return upperCaseValue;
    };

    const handleChange = (e: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>, value?: string | undefined) => {
        value = formatZipCode(value);
        //Are the characters being entered valid?
        if (value) if (!allowedCharsRegex.test(value) && value.length) return;
        if (onChange) onChange(e, value);
    };

    return (
        <TextField
            label="Zip Code"
            {...props}
            onChange={handleChange}
            onGetErrorMessage={onGetErrorMessage}
            maxLength={maxLength}
            value={value}
            required={required}
            validateOnFocusOut
            validateOnLoad={value ? true : false}
        />
    );
}
