import { IconButton, IStackItemProps, IStyleFunctionOrObject, ITextFieldStyles, Stack, TextField } from '@fluentui/react';
import { Field } from 'components';
import { useSelector } from 'hooks';
import { FormEvent } from 'react';
import { useDispatch } from 'react-redux';
import {
    selectAdjustmentHistoryFilters,
    selectAdjustmentHistoryFiltersExist,
} from 'state/slices/ledger/adjustment-history/adjustment-history.selectors';
import {
    clearAdjustmentHistoryFilters,
    setAdjustmentHistoryAdjustmentReason,
    setAdjustmentHistoryAmount,
    setAdjustmentHistoryDateOfEntry,
    setAdjustmentHistoryEncounterDate,
    setAdjustmentHistoryEncounterNumber,
    setAdjustmentHistoryTransactionDate,
} from 'state/slices/ledger/ledger.slice';
import { AdjustmentReasonDropdown } from '../components/AdjustmentReasonDropdown';
import { renderHistoryFilterLabel } from '../PaymentHistory/PaymentHistoryFilters';

export default function AdjustmentHistoryFilters() {
    const dispatch = useDispatch();

    const {
        adjustmentReasonId: adjustmentReason,
        amount,
        transactionDate,
        dateOfEntry,
        encounterDate,
        encounterNumber,
    } = useSelector(selectAdjustmentHistoryFilters);

    const filtersExist = useSelector(selectAdjustmentHistoryFiltersExist);

    const _onChangeAdjustmentReason = (adjustmentReasonId?: string | number) => {
        dispatch(setAdjustmentHistoryAdjustmentReason(adjustmentReasonId));
    };

    const _onChangeTransactionDate = (e?: FormEvent, date?: string) => {
        dispatch(setAdjustmentHistoryTransactionDate(date));
    };
    const _onChangeEncounterDate = (e?: FormEvent, date?: string) => {
        dispatch(setAdjustmentHistoryEncounterDate(date));
    };
    const _onChangeDateOfEntry = (e?: FormEvent, date?: string) => {
        dispatch(setAdjustmentHistoryDateOfEntry(date));
    };

    const _onChangeAdjustmentAmount = (e?: FormEvent, amount?: number) => {
        dispatch(setAdjustmentHistoryAmount(amount));
    };

    const _onChangeEncounterNumber = (e?: FormEvent, encounterNumber?: string) => {
        dispatch(setAdjustmentHistoryEncounterNumber(encounterNumber));
    };

    const _onClearAdjustmentFilters = () => {
        dispatch(clearAdjustmentHistoryFilters());
    };

    const isClearFiltersDisabled = !filtersExist;

    const fieldStyles: IStyleFunctionOrObject<IStackItemProps, ITextFieldStyles> = {
        root: { maxWidth: 150 },
    };

    return (
        <Stack wrap styles={{ root: { padding: 5 } }} verticalAlign="end" horizontal tokens={{ childrenGap: 10 }}>
            <Stack.Item styles={fieldStyles}>
                <TextField
                    title="Encounter Number"
                    label="Encounter Number"
                    onRenderLabel={renderHistoryFilterLabel}
                    onChange={_onChangeEncounterNumber}
                    value={encounterNumber}
                    autoComplete="off"
                    placeholder="Enter Enc. #"
                />
            </Stack.Item>
            <Stack.Item styles={fieldStyles}>
                <Field.Date
                    hasDatePicker
                    title="Encounter Date"
                    label="Encounter Date"
                    onRenderLabel={renderHistoryFilterLabel}
                    onChange={_onChangeEncounterDate}
                    value={encounterDate}
                />
            </Stack.Item>
            <Stack.Item styles={fieldStyles}>
                <Field.Date
                    hasDatePicker
                    title="Transaction Date"
                    label="Transaction Date"
                    onRenderLabel={renderHistoryFilterLabel}
                    onChange={_onChangeTransactionDate}
                    value={transactionDate}
                />
            </Stack.Item>
            <Stack.Item styles={fieldStyles}>
                <Field.Date
                    hasDatePicker
                    title="Batch Date"
                    label="Batch Date"
                    onRenderLabel={renderHistoryFilterLabel}
                    onChange={_onChangeDateOfEntry}
                    value={dateOfEntry}
                />
            </Stack.Item>
            <Stack.Item styles={fieldStyles}>
                <Field.Currency
                    title="Amount"
                    label="Amount"
                    onRenderLabel={renderHistoryFilterLabel}
                    prefix="$"
                    onChange={_onChangeAdjustmentAmount}
                    value={amount}
                />
            </Stack.Item>
            <Stack.Item>
                <AdjustmentReasonDropdown
                    onRenderLabel={renderHistoryFilterLabel}
                    placeholder="Select Adjustment Reason"
                    onChange={_onChangeAdjustmentReason}
                    selectedKey={adjustmentReason}
                />
            </Stack.Item>
            <Stack.Item>
                <IconButton
                    disabled={isClearFiltersDisabled}
                    onClick={_onClearAdjustmentFilters}
                    iconProps={{ iconName: 'Cancel' }}
                />
            </Stack.Item>
        </Stack>
    );
}
