import { IPatientAudit } from 'api/models/patient.model';
import { groupBy, orderBy } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'state/store';
export const selectPatientAuditLog = (state: RootState): IPatientAudit | undefined => state.patient.auditLog.data;
export const addPatientAuditLoggingasList = createSelector(selectPatientAuditLog, (state) =>
    state?.audits ? orderBy(state.audits, ['modifiedOn'], ['asc']) : [],
);

export const groupAuditLogByUser = groupBy(addPatientAuditLoggingasList, 'modifiedOn');
