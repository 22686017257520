import { Checkbox, Stack, TextField } from '@fluentui/react';
import { useSelector } from 'hooks';
import { getValidationError } from 'hooks/useValidation';
import { useDispatch } from 'react-redux';

import {
    clinicalAlertValidation,
    selectCurrentClinicalAlertData,
    selectIsEditingCurrentClinicalAlertData,
} from 'state/slices/clinical-alert/clinical-alerts.selectors';
import { setClinicalAlertDataProp } from 'state/slices/clinical-alert/clinical-alerts.slice';

export type BaseClinicalAlertProps = {
    notesRequired?: boolean;
};

const errorMessageLookup: Record<string, string> = {
    required: 'Notes is required',
    characterLimit: 'Note length must be below 150 characters.',
};

export default function BaseClinicalAlert({ notesRequired }: BaseClinicalAlertProps) {
    const currentClinicalAlert = useSelector(selectCurrentClinicalAlertData);
    const error = useSelector(clinicalAlertValidation);

    const isEditing = useSelector(selectIsEditingCurrentClinicalAlertData);

    const noteValidationError = getValidationError(error, 'Note');
    const dispatch = useDispatch();

    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <Stack.Item>
                <TextField
                    label="Notes"
                    value={currentClinicalAlert?.note}
                    multiline
                    rows={5}
                    required={notesRequired}
                    errorMessage={noteValidationError ? errorMessageLookup[noteValidationError.errorTypes[0]] : undefined}
                    onChange={(ev, value) => {
                        dispatch(setClinicalAlertDataProp({ path: 'note', value }));
                    }}
                    description={`${currentClinicalAlert?.note?.length ?? 0} of 150 characters used.`}
                />
            </Stack.Item>
            {isEditing && (
                <Stack.Item>
                    <Checkbox
                        label="Delete "
                        checked={currentClinicalAlert?.isDeleted}
                        onChange={(ev, checked) => {
                            dispatch(setClinicalAlertDataProp({ path: 'isDeleted', value: checked ?? false }));
                        }}
                    />
                </Stack.Item>
            )}
        </Stack>
    );
}
