import { DefaultButton, Panel, PanelType, PrimaryButton, Stack } from '@fluentui/react';
import { usePatientPrescriptionsPanel, useSelector, useValidation } from 'hooks';
import { IValidationConfig, ValidationType } from 'hooks/useValidation';
import { useEffect } from 'react';
import { selectSelectedPatient } from 'state/slices/patient/patient.selectors';
import PatientDetailsCard from '../PatientDetailsCard';
import Pam from './Pam';
import PrescriptionsMedications from './PrescriptionsMedications';

export default function PrescriptionsPanel(): JSX.Element {
    const {
        isOpen,
        cleanupPrescriptionsPanelState,
        setPrescriptionsPanelOpen,
        selectedPatientPrescription,
        addCurrentPatientPrescription,
    } = usePatientPrescriptionsPanel();

    const _selectedPatient = useSelector(selectSelectedPatient);

    const validationConfig: IValidationConfig = [
        {
            fieldName: 'Unstructured Sig',
            validation: [ValidationType.Required],
            value: selectedPatientPrescription?.unstructuredSig,
        },
        {
            fieldName: 'Associated Diagnosis',
            validation: [ValidationType.Required],
            value: selectedPatientPrescription?.snomedDiagnosisCode,
        },
    ];

    function onSendToQueue() {
        addCurrentPatientPrescription();
    }

    const [validationErrors, submit, cleanupErrors] = useValidation(validationConfig, onSendToQueue);

    const sendToQueueDisabled = !selectedPatientPrescription;

    function _onDismiss() {
        setPrescriptionsPanelOpen(false);
    }

    function _onDimissed() {
        cleanupPrescriptionsPanelState();
        cleanupErrors();
    }
    useEffect(() => {
        return () => {
            cleanupErrors();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const _onRenderFooterContent = () => (
        <Stack tokens={{ childrenGap: 10 }} grow>
            <Stack horizontal tokens={{ childrenGap: 12 }} verticalAlign="center">
                <PrimaryButton text="Send to queue" onClick={submit} disabled={sendToQueueDisabled} />
                <DefaultButton text="Cancel" onClick={_onDismiss} />
            </Stack>
        </Stack>
    );

    return (
        <Panel
            headerText="Add Prescription"
            styles={{
                content: { overflowY: 'auto', overflowX: 'hidden', flex: 1, position: 'relative' },
                root: { overflow: 'hidden' },
                scrollableContent: { overflow: 'hidden', display: 'flex', flexDirection: 'column' },
            }}
            onDismiss={_onDismiss}
            onDismissed={_onDimissed}
            isOpen={isOpen}
            type={PanelType.custom}
            customWidth={'45vw'}
            isFooterAtBottom
            onRenderFooterContent={_onRenderFooterContent}
        >
            <PatientDetailsCard onPageNavigationClick={_onDismiss} patient={_selectedPatient} showHeightWeight />
            <Stack tokens={{ childrenGap: 2 }} grow>
                <Pam />
                <PrescriptionsMedications validationErrors={validationErrors} />
            </Stack>
        </Panel>
    );
}
