import { useDispatch } from 'react-redux';
import { msalInstance } from 'api/auth/authProvider';
import { useSelector, useTenantId } from 'hooks';
import { setTheme } from '../../state/slices/ui/ui.slice';
import Avatar from './Avatar';
import { IContextualMenuProps, ContextualMenuItemType, Toggle, IContextualMenuItem } from '@fluentui/react';
import { setSettingsExpanded } from 'state/slices/settings/settings.slice';
import usePermissions, { DentalPermissions, GlobalPermissions } from 'hooks/store/usePermissions';
import { batchActions } from 'state/slices/tenant/batches.slice';
import { useAccount } from '@azure/msal-react';
import { imagingSourceActions } from 'state/slices/tenant/imaging-source.slice';

type Props = {
    menuItems?: IContextualMenuItem[];
};

export default function AvatarContainer({ menuItems }: Props): JSX.Element {
    const dispatch = useDispatch();
    const tenantId = useTenantId();
    const account = useAccount();

    const { photo, profile } = useSelector((state) => state.user);
    const selectedTheme = useSelector((state) => state.ui.selectedTheme);

    const _logout = () => msalInstance.logoutRedirect({ account });
    const _onThemeChange = () => {
        if (selectedTheme === 'light') {
            dispatch(setTheme('dark'));
        } else {
            dispatch(setTheme('light'));
        }
    };

    const menuItemList = menuItems ? menuItems : [];

    const [hasSettingsPermissions] = usePermissions([DentalPermissions.ChartSettings, DentalPermissions.PracticeSettings]);
    const [hasImagingPermissions] = usePermissions([DentalPermissions.ConfigureImaging]);

    const contextMenu: IContextualMenuProps = {
        items: [
            {
                key: 'userName',
                name: 'userName',
                text: account ? account.name : 'User Name',
                itemType: ContextualMenuItemType.Header,
            },
            {
                key: 'divider1',
                itemType: ContextualMenuItemType.Divider,
            },
            ...menuItemList,
            {
                key: 'theme',
                onRender: function RenderThemeSwitcher() {
                    return (
                        <Toggle
                            styles={{ root: { marginLeft: 10, marginTop: 8 } }}
                            onChange={_onThemeChange}
                            label="Theme"
                            inlineLabel
                            checked={selectedTheme === 'light' ? true : false}
                            onText="Light"
                            offText="Dark"
                        />
                    );
                },
            },
            {
                key: 'divider2',
                itemType: ContextualMenuItemType.Divider,
            },
            {
                key: 'batches',
                text: 'Batches',
                iconProps: { iconName: 'Bank' },
                onClick: () => {
                    dispatch(batchActions.setBatchPanelOpen(true));
                },
            },
            {
                key: 'imagingSource',
                text: 'Manage Imaging Bridge',
                iconProps: { iconName: 'Bridge' },
                onClick: () => {
                    dispatch(imagingSourceActions.setImagingSourcePanelOpen(true));
                },
            },
            {
                key: 'settings',
                text: 'Settings',
                iconProps: { iconName: 'Settings' },
                onClick: () => {
                    dispatch(setSettingsExpanded(true));
                },
                disabled: !hasSettingsPermissions || !tenantId,
            },
            {
                key: 'logout',
                text: 'Logout',
                iconProps: { iconName: 'SignOut' },
                onClick: _logout,
            },
        ],
    };

    return <Avatar {...{ photo, profile, selectedTheme, contextMenu }} />;
}
