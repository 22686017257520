import IWorkListEncounterView from 'api/models/worklist-encounter-view';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';

const primaryPayerSource: ISortableColumn<IWorkListEncounterView> = {
    key: 'primaryPayerSource',
    minWidth: 150,
    maxWidth: 220,
    isResizable: true,
    name: 'Primary Payer Source',
    onRender: (item) => {
        const text = getPrimaryPayerSource(item);
        return <span title={text}>{text}</span>;
    },
    getValueKey: (item) => getPrimaryPayerSource(item),
};

//TODO: Once Kristy implements the worklist encounter view model for all worklists, this will not be needed. Just ref primaryPayerName field.
function getPrimaryPayerSource(item?: IWorkListEncounterView): string {
    if (item) {
        let text: string | undefined = 'N/A';

        if (item.primaryPayerName === '') {
            if (item.slidingFeePlan === '') {
                text = 'N/A';
            } else {
                text = item.slidingFeePlan;
            }
        } else {
            text = item.primaryPayerName;
        }
        return text ?? 'Self Pay';
    }
    return '';
}

export default primaryPayerSource;
