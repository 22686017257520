import IChartNote, { IChartNoteWithData, NoteType } from 'api/models/chart-note.model';
import { IChartNoteBrowserViews } from './note-browser.models';
import { LoadingStatus } from 'interfaces/loading-statuses';

export interface NoteBrowserState {
    isOpen: boolean;
    newNoteIsOpen: boolean;
    signAndSaveIsOpen: boolean;
    cancelConfirmationIsOpen: boolean;

    selectedChartNote?: IChartNote | IChartNoteWithData<any>;

    newNote?: NoteBrowserNewNote;

    saveError?: string;

    clinicalNotes?: IChartNoteBrowserViews;
    clinicalNotesLoading: LoadingStatus;
}

export const initialState: NoteBrowserState = {
    isOpen: false,
    newNoteIsOpen: false,
    signAndSaveIsOpen: false,
    cancelConfirmationIsOpen: false,
    clinicalNotesLoading: LoadingStatus.Idle
};

export type NoteBrowserNewNote = {
    note: string;
    noteType?: NoteType;
    noteBrowserType: NoteBrowserType;
};

export enum NoteBrowserType {
    Note,
    Addendum,
}
