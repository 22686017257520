import { ETagSupport } from 'api/models/support-types';
import { GeneralSupports } from '../../models/contracts';

export enum FormName {
    CariesRiskAdult = 'CariesRiskAdult',
    CariesRiskChild = 'CariesRiskChild',
    SoftTissueExam = 'SoftTissueExam',
    DentalHistory = 'DentalHistory',
    PatientProblem = 'PatientProblem',
}

type FormSupports = GeneralSupports & ETagSupport;
export enum FormStatus {
    Pending = 'Pending',
    Completed = 'Completed',
    Reviewed = 'Reviewed',
}
export default interface IForm extends FormSupports {
    patientId: string;
    appointmentId: string;
    encounterId?: string;
    formName: FormName;
    status?: FormStatus;
    completedDate?: string;
    data: any;
}
