import { IComboBoxOption, Stack, TextField } from '@fluentui/react';
import { VitalNameType } from 'api/models/patient-vital.model';
import { SubSection, Field } from 'components';
import useSelector from 'hooks/useSelector';
import { useDispatch } from 'react-redux';
import { updateReading } from 'state/slices/patient/patient.slice';
import { NonBPNameTypes } from 'state/slices/patient/vitals/vitals.reducers';

const SatVitalsProperties = () => {
    const dispatch = useDispatch();
    const newVitals = useSelector((state) => state.patient.vitals.vitalPanelData);

    const O2Sat = newVitals[VitalNameType.Saturation];
    const O2SatAirtype = newVitals[VitalNameType.SaturationAirtype];

    const handleOnChange = (vitalType: NonBPNameTypes, value?: string): void => {
        dispatch(updateReading({ vitalType, value: value ?? '' }));
    };

    const saturationTypeOptions: IComboBoxOption[] = [
        {
            key: 'Room Air at Rest',
            text: 'Room Air at Rest',
        },
        {
            key: 'Oxygen',
            text: 'Oxygen',
        },
        {
            key: 'Breathing Treatment',
            text: 'Breathing Treatment',
        },
        {
            key: 'Room Air with Oxygen',
            text: 'Room Air with Oxygen',
        },
        {
            key: 'Room Air with Exercise',
            text: 'Room Air with Exercise',
        },
        {
            key: 'Ambulating with Oxygen',
            text: 'Ambulating with Oxygen',
        },
        {
            key: 'Ambulating on Room Air',
            text: 'Ambulating on Room Air',
        },
        {
            key: 'CPAP',
            text: 'CPAP',
        },
    ];

    return (
        <Stack tokens={{ childrenGap: 5 }}>
            <SubSection title="O2 Saturation">
                <Stack tokens={{ childrenGap: 10 }}>
                    <Stack tokens={{ childrenGap: 10 }} horizontal verticalAlign="center" grow>
                        <Stack.Item>
                            <TextField
                                label=""
                                autoComplete="off"
                                suffix="%"
                                value={O2Sat}
                                maxLength={3}
                                max={100}
                                onChange={(ev, value) => {
                                    handleOnChange(VitalNameType.Saturation, value);
                                }}
                            />
                        </Stack.Item>
                        <Stack.Item grow>
                            <Field.Dropdown
                                options={saturationTypeOptions}
                                label=""
                                selectedKey={O2SatAirtype}
                                onChange={(event, option) =>
                                    handleOnChange(VitalNameType.SaturationAirtype, option?.key as string)
                                }
                            />
                        </Stack.Item>
                    </Stack>
                </Stack>
            </SubSection>
        </Stack>
    );
};

export default SatVitalsProperties;
