import { LoadingStatus } from 'interfaces/loading-statuses';
import { RootState } from 'state/store';
import { CheckInSections } from '../scheduling.slice';
import ErrorTypes from 'state/errorTypes';

//Check In Selectors
export const selectIsCheckInPanelOpen = (state: RootState): boolean => state.scheduling.checkin.isCheckInPanelOpen;

export const selectIsCheckInPanelError = (state: RootState): ErrorTypes | undefined => state.scheduling.checkin.error;

export const selectfinishCheckinLoading = (state: RootState): boolean =>
    state.scheduling.checkin.finishCheckinLoading === LoadingStatus.Pending;
export const selectIsBasicDetailsSectionOpen = (state: RootState): boolean =>
    state.scheduling.checkin.visibleSections.includes(CheckInSections.BasicDetails);
export const selectIsUDSSectionOpen = (state: RootState): boolean =>
    state.scheduling.checkin.visibleSections.includes(CheckInSections.uds);
export const selectIsFinancialSectionOpen = (state: RootState): boolean =>
    state.scheduling.checkin.visibleSections.includes(CheckInSections.Financial);
