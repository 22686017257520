import { IdSupport } from 'api/models/support-types';

type RejectionSupports = IdSupport;

export interface IRejection extends RejectionSupports {
    carc?: string;
    rarc?: string;
    dateOfService?: string;
    deniedDate?: string;
    encounterId?: string;
    locationOfCareId?: string;
    appointmentId?: string;
    patientId?: string;
    mrn?: string;
    claimBalance?: number;
    currentPayer?: string;
    billingOrder?: string;
    firstFiledDate?: string;
    lastFiledDate?: string;
    tags?: RejectionTag[];
}

export enum RejectionTag {
    Denied = 'Denied',
}

export const testRejections: IRejection[] = [
    {
        id: 'c6ee6465-7bc3-4102-8aca-dd6d747fc212',
        carc: 'PR297',
        rarc: 'XYZO',
        deniedDate: '06/20/2022',
        dateOfService: '06/13/2022',
        claimBalance: 450.0,
        currentPayer: 'Blue Cross Blue Shield of TX',
        billingOrder: 'Primary',
        firstFiledDate: '06/14/2022',
        lastFiledDate: '06/15/2022',
        tags: [RejectionTag.Denied],
        locationOfCareId: '57',
    },
    {
        id: 'c6ee6465-7bc3-4102-8aca-dd6d747fc214',
        carc: 'PR296',
        rarc: 'XYZZ',
        deniedDate: '06/20/2022',
        dateOfService: '06/13/2022',
        claimBalance: 450.0,
        currentPayer: 'Blue Cross Blue Shield of TX',
        billingOrder: 'Primary',
        firstFiledDate: '06/14/2022',
        lastFiledDate: '06/15/2022',
        tags: [RejectionTag.Denied],
        locationOfCareId: '57',
    },
];
