import {
    ContextualMenuItemType,
    DefaultButton,
    Icon,
    IContextualMenuItem,
    IContextualMenuProps,
    PrimaryButton,
    Stack,
    Text,
} from '@fluentui/react';
import { NoteType, noteTypeText } from 'api/models/chart-note.model';
import { KeyboardEvent, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openNoteModal } from 'state/slices/chart-notes/note-browser/note-browser.actions';
import {
    selectAddendumDisabled,
    selectCurrentNote,
    selectMainActionsVisible,
    selectNewNote,
} from 'state/slices/chart-notes/note-browser/note-browser.selectors';
import { noteBrowserActions, NoteBrowserModal } from 'state/slices/chart-notes/note-browser/note-browser.slice';
import { NoteBrowserType } from 'state/slices/chart-notes/note-browser/note-browser.state';
import { convertFromMilitaryTime } from 'utils/convertFromMilitaryTime';
import { classicDateOnly } from 'utils/dateOnly';

function NoteActions() {
    const mainActionsVisible = useSelector(selectMainActionsVisible);
    return (
        <Stack horizontal>
            <Stack style={{ flex: 1 }} verticalAlign="center">
                <NoteActionsTitle />
            </Stack>
            {!mainActionsVisible ? <NoteBrowserActions /> : <NoteViewerActions />}
        </Stack>
    );
}

function NoteBrowserActions() {
    const dispatch = useDispatch();
    const openConfirmation = () => dispatch(openNoteModal(NoteBrowserModal.CreateNote, true));
    const addendumDisabled = useSelector(selectAddendumDisabled);
    const createNewNote = (noteType?: NoteType) => dispatch(noteBrowserActions.createNewNote(noteType));

    const addendumTitle = addendumDisabled ? 'Please select a note first' : 'Add addendum to selected note';
    const careCoordinationClick = () => {
        createNewNote(NoteType.CareCoordination);
    };
    const followUpClick = () => {
        createNewNote(NoteType.FollowUp);
    };
    const patientCommClick = () => {
        createNewNote(NoteType.PatientCommunication);
    };

    const menuProps: IContextualMenuProps = {
        items: [
            {
                key: 'new-note',
                itemType: ContextualMenuItemType.Header,
                text: 'Select note type:',
            },
            {
                key: NoteType.PatientCommunication,
                text: 'Patient Communication',
                onClick: patientCommClick,
            },
            {
                key: NoteType.CareCoordination,
                text: 'Care Coordination',
                onClick: careCoordinationClick,
            },
            {
                key: NoteType.FollowUp,
                text: 'Follow-up',
                onClick: followUpClick,
            },
        ],
    };

    return (
        <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 10 }}>
            <DefaultButton
                text="Note"
                iconProps={{ iconName: 'EditNote' }}
                onClick={openConfirmation}
                title="Create a new chart note"
                split
                menuProps={menuProps}
            />
            <DefaultButton
                text="Addendum"
                iconProps={{ iconName: 'AddNotes' }}
                onClick={() => createNewNote()}
                disabled={addendumDisabled}
                title={addendumTitle}
            />
        </Stack>
    );
}

function NoteViewerActions() {
    const dispatch = useDispatch();
    const cancelAddendum = () => dispatch(noteBrowserActions.cancelNewNote());
    const signAndSave = () => dispatch(openNoteModal(NoteBrowserModal.SignAndSave, true));

    return (
        <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 10 }}>
            <PrimaryButton text="Sign & Save" onClick={signAndSave} />
            <DefaultButton text="Cancel" onClick={cancelAddendum} />
        </Stack>
    );
}

function NoteActionsTitle() {
    const newNote = useSelector(selectNewNote);
    const currentNote = useSelector(selectCurrentNote);
    console.log('currentNote', currentNote);
    const renderText = () => {
        if (!currentNote && !newNote)
            return (
                <>
                    <Icon iconName="ArrowDownRightMirrored8" />
                    &nbsp;&nbsp; Select a note to view
                </>
            );
        const noteTime = currentNote?.appointmentStartTime ? ` - ${convertFromMilitaryTime(currentNote.appointmentStartTime)} ` : '';
        const currentNoteText = `${
            currentNote?.encounterDate
                ? classicDateOnly(currentNote.encounterDate)
                : currentNote?.createdOn
                ? classicDateOnly(currentNote.createdOn)
                : ''
        }${noteTime} - ${currentNote?.noteType ? noteTypeText[currentNote.noteType] : ''}`;
        if (!newNote) return `Preview: ${currentNoteText}`;
        if (newNote.noteBrowserType === NoteBrowserType.Note)
            return `Adding New ${newNote?.noteType ? noteTypeText[newNote.noteType] : ''} Note`;
        return `Adding addendum for ${currentNoteText}`;
    };
    return <Text variant="large">{renderText()}</Text>;
}

export default NoteActions;
