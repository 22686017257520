import IChartNote, { IChartNoteAddendum, IChartNoteWithData, NoteType } from 'api/models/chart-note.model';
import { AppThunk, CreateAsyncThunkOptions, RootState } from 'state/store';
import { noteBrowserActions, NoteBrowserModal } from './note-browser.slice';
import { NoteBrowserNewNote, NoteBrowserType } from './note-browser.state';
import { v4 as uuid } from 'uuid';
import { upsertChartNote } from '../chart-notes.actions';
import { format } from 'date-fns';
import { selectSignalRIsConnected } from 'state/slices/signalr.slice';
import { deselectPatientEncounter } from 'state/slices/encounter/encounter.actions';
import { selectPatientEncounter } from 'state/slices/encounter/encounter.selectors';
import { EncounterStatus } from 'api/models/encounter.model';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { noteBrowserApi } from './note-browser.api';
import { IChartNoteBrowserViews } from './note-browser.models';

export const openNoteModal =
    (modal: NoteBrowserModal, isOpen: boolean): AppThunk =>
    (dispatch) => {
        dispatch(noteBrowserActions.setNoteModalOpen({ modal, isOpen }));
    };

export const addAddendumAndPreviewNote = (): AppThunk<void> => (dispatch, getState) => {
    const state = getState();
    const note = state.chartNotes.currentClinicalNote;
    if (!note) return;
    dispatch(noteBrowserActions.createNewNote());
    dispatch(noteBrowserActions.setChartNote(note));
    dispatch(noteBrowserActions.setNoteModalOpen({ modal: NoteBrowserModal.NoteBrowser, isOpen: true }));
};

export const getNoteBrowserClinicalNotes = createAsyncThunk<
    IChartNoteBrowserViews,
    { tenantId: string; patientId: string },
    CreateAsyncThunkOptions
>('noteBrowser/getNoteBrowserClinicalNotes', async ({ tenantId, patientId }, { rejectWithValue }) => {
    try {
        const { data } = await noteBrowserApi.getNoteBrowserClinicalNotes(tenantId, patientId);
        return data;
    } catch (e) {
        return rejectWithValue(`Could not load patient's clinical notes`);
    }
});

export const signAndSaveNewNote =
    (tenantId: string, patientId: string): AppThunk<void> =>
    async (dispatch, getState) => {
        const state = getState();

        const noteHelper = new NoteBrowserHelper(state);
        const noteToSave = noteHelper.getNoteToSave;
        const saveMethod = noteHelper.getSaveMethod;

        if (!noteToSave) return;

        try {
            const newNote = await dispatch(saveMethod({ tenantId, patientId, chartNote: noteToSave })).unwrap();

            // Cleanup [Close modal | set newly saved note as selected | clear out note editor]
            dispatch(openNoteModal(NoteBrowserModal.SignAndSave, false));
            dispatch(noteBrowserActions.setChartNote(newNote));
            dispatch(noteBrowserActions.cancelNewNote());

            //Check if we are connected to signalR, the selected encounter matches the note encounter, and is correction amend.
            //If we are we will let signalR handle pushing the user out of the encounter.
            //Otherwise we can just push the user out of the encounter here.
            const patientEncounter = selectPatientEncounter(state);
            const isSignalRConnected = selectSignalRIsConnected(state);
            if (
                !isSignalRConnected &&
                patientEncounter?.id === noteToSave.encounterId &&
                patientEncounter?.status === EncounterStatus.CorrectionAmend
            ) {
                dispatch(deselectPatientEncounter());
            }
        } catch (e) {
            dispatch(
                noteBrowserActions.setSaveError(
                    `Unable to save ${noteToSave.noteType === NoteType.ClinicalNote ? 'addendum.' : 'Note.'}`,
                ),
            );
        }
    };

class NoteBrowserHelper {
    state: RootState;
    newNote: NoteBrowserNewNote | undefined;
    currentNote: IChartNote | IChartNoteWithData<any> | undefined;
    isAddendum: boolean;

    constructor(state: RootState) {
        this.state = state;
        this.newNote = state.noteBrowser.newNote;
        this.currentNote = state.noteBrowser.selectedChartNote;
        this.isAddendum = this.newNote?.noteBrowserType === NoteBrowserType.Addendum;
    }

    private makeAddendum(): IChartNoteWithData<any> | undefined {
        if (!this.currentNote) return;

        const newAddendum: IChartNoteAddendum = {
            id: uuid(),
            note: this.newNote?.note,
            signature: this.makeSignature(),
        };

        const noteToUpdate = {
            ...this.currentNote,
            data: {
                ...this.currentNote.data,
                addendums:
                    this.currentNote.data.addendums && this.currentNote.data.addendums.length
                        ? [...this.currentNote.data.addendums, newAddendum]
                        : [newAddendum],
            },
        };
        return noteToUpdate;
    }

    private makeNote(): IChartNoteWithData<any> | undefined {
        const newNote: IChartNoteWithData<any> = {
            id: uuid(),
            data: {
                value: this.newNote?.note,
                signatures: [this.makeSignature()],
            },
            noteType: this.newNote?.noteType,
            isDeleted: false,
        };

        return newNote;
    }

    public get getNoteToSave() {
        return this.isAddendum ? this.makeAddendum() : this.makeNote();
    }
    public get getSaveMethod() {
        return upsertChartNote;
    }

    private makeSignature() {
        const signature = `${this.isAddendum ? 'Addendum: ' : ''}Electronically signed by ${this.userDisplayName} on ${format(
            new Date(),
            'Pp',
        )}`;
        return {
            date: new Date().toISOString(),
            signature,
            treatingProviderId: this.state.account.data?.identity.id,
        };
    }

    private get userDisplayName(): string {
        const identity = this.state.account.data?.identity;
        if (!identity) return 'Unknown provider';

        return `${identity.firstName} ${identity.lastName} ${identity.professionalSuffix ?? ""}`;
    }
}

export const setCurrentNoteById = (noteId: string): AppThunk => (dispatch, getState) => {
    const state = getState();
    const note = state.chartNotes.data?.find((note) => note.id === noteId);
    if (!note) return;
    dispatch(noteBrowserActions.setChartNote(note));
}