import { INavLink, INavLinkGroup, IRenderGroupHeaderProps, Nav, PrimaryButton, Stack, useTheme } from '@fluentui/react';
import React from 'react';

type Props = {
    selectedPage: string;
    closeSettings: () => void;
    setSelectedPage: (page: string) => void;
    navItems: INavLinkGroup[];
};

function SettingsNav({ setSelectedPage, closeSettings, selectedPage, navItems }: Props) {
    const theme = useTheme();

    const _onLinkClick = (ev?: React.MouseEvent<HTMLElement | MouseEvent>, item?: INavLink) => {
        ev?.preventDefault();
        if (item) {
            setSelectedPage(item.url);
        }
    };

    const _onRenderGroupHeader = (headerProps?: IRenderGroupHeaderProps) => {
        if (!headerProps) return null;

        return (
            <h5 style={{ marginBottom: 0, color: theme.palette.neutralPrimaryAlt, textTransform: 'uppercase', fontSize: 11 }}>
                {headerProps.name}
            </h5>
        );
    };

    return (
        <Stack
            style={{
                background: theme.palette.neutralLighter,
                borderRight: `1px solid ${theme.semanticColors.bodyDivider}`,
                height: '100%',
                padding: 20,
                paddingTop: 0,
            }}
        >
            <Stack.Item grow>
                <Nav
                    styles={{
                        group: { borderBottom: `1px solid ${theme.palette.neutralQuaternary}` },
                        groupContent: { marginBottom: 0 },
                    }}
                    onRenderGroupHeader={_onRenderGroupHeader}
                    onLinkClick={_onLinkClick}
                    selectedKey={selectedPage}
                    groups={navItems}
                />
            </Stack.Item>
            <Stack>
                <PrimaryButton text="Close [ESC]" iconProps={{ iconName: 'ChromeClose' }} onClick={closeSettings} />
            </Stack>
        </Stack>
    );
}

export default SettingsNav;
