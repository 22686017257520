import { IDropdownOption, Stack } from '@fluentui/react';
import { IUACReportQuery } from 'api/models/uac-report.model';
import { Field } from 'components';
import SearchComboField from 'components/Field/SearchComboField';
import { useSelector, useTenantId } from 'hooks';
import ReportWrapper from 'pages/Reporting/ReportWrapper';
import React from 'react';
import { useDispatch } from 'react-redux';
import reportActionLookup from 'state/slices/reporting/reportActionLookup';
import { selectReportProperties } from 'state/slices/reporting/reporting.selectors';
import { setUACProp } from 'state/slices/reporting/reporting.slice';

type Props = {
    _onViewReport: () => void;
};

const UACBase = ({ _onViewReport }: Props) => {
    const query = useSelector(selectReportProperties) as IUACReportQuery;

    const dispatch = useDispatch();

    const options: IDropdownOption[] = [
        { key: 'All', text: 'All' },
        { key: 'Overages', text: 'Overages' },
        { key: 'UAC', text: 'UAC' },
    ];

    return (
        <ReportWrapper
            validationConfig={[
                {
                    fieldName: 'Start Date',
                    validation: ['required'],
                    value: query?.reportOption,
                },
            ]}
            onViewReport={_onViewReport}
        >
            <Stack tokens={{ childrenGap: 10 }} grow horizontal wrap>
                <Stack.Item>
                    <Field.SearchCombo
                        placeholder="(Select Report Option)"
                        label="Report Option"
                        style={{ minWidth: 300 }}
                        options={options}
                        onChange={(ev, option) => {
                            if (option?.key) dispatch(setUACProp({ value: option.key as string, path: 'reportOption' }));
                        }}
                        selectedKey={query?.reportOption}
                    />
                </Stack.Item>
            </Stack>
        </ReportWrapper>
    );
};

export default UACBase;
