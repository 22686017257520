/**
 * Prefix of content types
 */
export enum CompatibleContentTypePrefix {

    /**
     * Text type prefix
     */
    Text = 'text/',

    /**
     * Image type prefix
     */
    Image = 'image/',
}


/**
 * Known content types
 */
export enum CompatibleContentType {

    /**
     * Plain text content type
     */
    PlainText = 'text/plain',

    /**
     * HTML content type
     */
    HTML = 'text/html',
}
