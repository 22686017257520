import { Stack, Text, useTheme, IStyleFunctionOrObject, ITextFieldStyles, DefaultEffects, IStackProps } from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import { EncounterStatus } from 'api/models/encounter.model';
import usePatient from 'hooks/store/usePatient';
import { find } from 'lodash';
import { toothSpriteReferences } from 'pages/Charting/components/ToothCanvas/spriteList';
import { TOOTH_CONTAINER_WIDTH } from 'pages/Perio/perio-settings';
import { useSelector } from 'react-redux';
import {
    currentPerioExamTeeth,
    currentPerioExam,
    selectCurrentPerioExamEncounter,
} from 'state/slices/charting/perio-exams/perio-exams.selectors';
import { selectPerioPanel } from 'state/slices/charting/perio-panel/perio-panel.selectors';
import DepthField from './DepthField';
import FuractionField from './FurcationField';
import IconBooleanField from './IconBooleanInput';
import MobilityField from './MobilityField';
import SuppurationField from './SuppurationField';
import useEncounterId from 'hooks/useEncounterId';
type Props = {
    isUpper?: boolean;
    position: number;
};
function ToothContainer({ isUpper, position }: Props): JSX.Element {
    const [focused, { setTrue: setFocusedTrue, setFalse: setFocusedFalse }] = useBoolean(false);
    const [hovered, { setTrue: setHoveredTrue, setFalse: setHoveredFalse }] = useBoolean(false);
    const { palette, semanticColors } = useTheme();
    const teeth = useSelector(currentPerioExamTeeth);
    const { isComparing, examsToCompare } = useSelector(selectPerioPanel);
    const encounterId = useEncounterId();

    const tooth = find(teeth, (tooth) => tooth.position === position);
    const _currentPerioExam = useSelector(currentPerioExam);
    const currentPerioExamEncounterId = _currentPerioExam.encounterId;

    const comparedExam = isComparing ? examsToCompare?.find((res) => res.id != _currentPerioExam.id) : _currentPerioExam;
    const comparedTooth = tooth?.id && comparedExam?.teeth ? comparedExam.teeth[tooth?.id] : tooth;

    const perioExamEncounter = useSelector(selectCurrentPerioExamEncounter);
    const isExamEncounterConversionImported = perioExamEncounter?.status === EncounterStatus.ConversionImported;

    const { currentPatientEncounters } = usePatient();
    const isEncounterCompleted =
        currentPatientEncounters.find((r) => r.id === currentPerioExamEncounterId)?.status ===
        (EncounterStatus.Billed || EncounterStatus.Completed);

    const _isHoveredOrFocused = focused || hovered;
    const _styles: IStyleFunctionOrObject<IStackProps, ITextFieldStyles> = {
        root: {
            padding: '10px 0px',
            background: _isHoveredOrFocused ? semanticColors.bodyBackgroundHovered : '',
            boxShadow: _isHoveredOrFocused ? DefaultEffects.elevation16 : 'none',
            zIndex: _isHoveredOrFocused ? 10 : 0,
            boxSizing: 'border-box',
            width: TOOTH_CONTAINER_WIDTH,
            transition: 'background-color .15s',
        },
    };

    if (!tooth) return <Stack styles={_styles}></Stack>;

    const _tokens = { childrenGap: 10 };

    const toothDisplayName = toothSpriteReferences.find((t) => t.id === tooth.id)?.displayName;

    const fieldsDisabled =
        isEncounterCompleted || isComparing || isExamEncounterConversionImported || encounterId !== currentPerioExamEncounterId;

    return (
        <Stack
            reversed={!isUpper}
            tokens={_tokens}
            styles={_styles}
            onMouseOver={setHoveredTrue}
            onMouseOut={setHoveredFalse}
            onFocus={setFocusedTrue}
            onBlur={setFocusedFalse}
        >
            <Stack horizontalAlign="center">
                <Text>{toothDisplayName}</Text>
            </Stack>
            <MobilityField tooth={tooth} isUpper={isUpper} position={position} isDisabled={fieldsDisabled} />
            <SuppurationField tooth={tooth} isUpper={isUpper} isDisabled={fieldsDisabled} />
            <FuractionField tooth={tooth} isUpper={isUpper} isDisabled={fieldsDisabled} />
            <IconBooleanField
                perioField="plaque"
                isUpper={isUpper}
                icon={{ iconName: 'CircleFill', activeColor: palette.yellowDark, hoverColor: palette.yellow }}
                position={position}
                tooth={tooth}
                isDisabled={fieldsDisabled}
            />
            <IconBooleanField
                perioField="bleeding"
                isUpper={isUpper}
                icon={{ iconName: 'Drop', activeColor: palette.redDark, hoverColor: palette.red }}
                position={position}
                tooth={tooth}
                isDisabled={fieldsDisabled}
            />
            <DepthField
                perioField="gingivalMargin"
                isUpper={isUpper}
                position={position}
                tooth={tooth}
                allowNegativeValues
                isDisabled={fieldsDisabled}
                comparedTooth={comparedTooth}
            />
            <DepthField
                perioField="probing"
                isUpper={isUpper}
                position={position}
                tooth={tooth}
                isDisabled={fieldsDisabled}
                comparedTooth={comparedTooth}
            />
        </Stack>
    );
}

export default ToothContainer;
