import { IChartProcedure } from 'api/models/chart.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { ChartEndpoint } from '../chart/chart.types';

export const chartProceduresInitialState: ChartProceduresState = {
    data: [],
    loading: LoadingStatus.Idle,
    saving: LoadingStatus.Idle,
};

export type ChartProceduresState = ChartEndpoint<IChartProcedure>;
