import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import {
    selectAllergies,
    selectAllergiesList,
    selectAllergiesLoading,
    selectFilteredMedicationsList,
    selectImmunizations,
    selectImmunizationsList,
    selectImmunizationsLoading,
    selectMedications,
    selectMedicationsLoading,
    selectPatientOverviewTasksState,
    selectProblemAsList,
    selectProblems,
    selectProblemsLoading,
    selectSelectedOverviewItem,
} from 'state/slices/patient/overview/overview.selectors';
import { cleanupSelectedOverviewItem, setSelectedOverviewItem } from 'state/slices/patient/patient.slice';
import {
    createOverviewItem,
    setOverviewDataPropAndSave,
    updateOverviewItem,
    updateReviewedCheckbox,
} from 'state/slices/patient/overview/overview.actions';
import { useParams } from 'react-router';
import { RouteParams } from 'interfaces/route-params';
import { selectVitalsLoading, selectVitals } from 'state/slices/patient/vitals/vitals.selectors';
import { fetchPatientOverviewTasks, fetchPatientCRA } from 'state/slices/patient/patient.actions';

function useOverview() {
    const dispatch = useDispatch();
    const { tenantId, patientId } = useParams<RouteParams>();

    const _selectedOverviewItem = useSelector(selectSelectedOverviewItem);
    const _problemsLoading = useSelector(selectProblemsLoading);
    const _problems = useSelector(selectProblems);
    const _selectProblemAsList = useSelector(selectProblemAsList);

    const _allergiesLoading = useSelector(selectAllergiesLoading);
    const _allergies = useSelector(selectAllergies);
    const _allergiesList = useSelector(selectAllergiesList);
    const _medicationsLoading = useSelector(selectMedicationsLoading);
    const _medications = useSelector(selectMedications);
    const _medicationsList = useSelector(selectFilteredMedicationsList);
    const _vitalsLoading = useSelector(selectVitalsLoading);
    const _vitals = useSelector(selectVitals);
    const _immunizationsLoading = useSelector(selectImmunizationsLoading);
    const _immunizations = useSelector(selectImmunizations);
    const _immunizationsList = useSelector(selectImmunizationsList);
    const _patientOverviewTasks = useSelector(selectPatientOverviewTasksState);

    const _setSelectedOverviewItem = useCallback(
        (item: any) => {
            dispatch(setSelectedOverviewItem(item));
        },
        [dispatch],
    );

    const _cleanupSelectedOverviewItem = useCallback(() => {
        dispatch(cleanupSelectedOverviewItem());
    }, [dispatch]);

    const _createOverviewItem = useCallback(
        (item: any, type: string) => {
            dispatch(createOverviewItem({ tenantId, item, type }));
        },
        [dispatch, tenantId],
    );

    const _updateOverviewItem = useCallback(
        (item: any, type: string) => {
            dispatch(updateOverviewItem({ tenantId, item, type }));
        },
        [dispatch, tenantId],
    );

    const _updateReviewedCheckbox = useCallback(
        (reviewed: boolean) => {
            dispatch(updateReviewedCheckbox(tenantId, patientId, reviewed));
        },
        [dispatch, tenantId, patientId],
    );

    const _setOverviewPropDataAndSave = useCallback(
        (type: string, path: string, value: string | boolean | undefined) => {
            dispatch(setOverviewDataPropAndSave(type, path, value, tenantId, patientId));
        },
        [dispatch, tenantId, patientId],
    );

    return {
        selectedOverviewItem: _selectedOverviewItem,
        problemsLoading: _problemsLoading,
        problems: _problems,
        selectProblemAsList: _selectProblemAsList,

        allergiesLoading: _allergiesLoading,
        allergies: _allergies,
        allergiesList: _allergiesList,

        medicationsLoading: _medicationsLoading,
        medications: _medications,
        medicationsList: _medicationsList,

        vitalsLoading: _vitalsLoading,
        vitals: _vitals,

        immunizationsLoading: _immunizationsLoading,
        immunizations: _immunizations,
        immunizationsList: _immunizationsList,
        patientOverviewTasks: _patientOverviewTasks,

        setSelectedOverviewItem: _setSelectedOverviewItem,
        cleanupSelectedOverviewItem: _cleanupSelectedOverviewItem,
        createOverviewItem: _createOverviewItem,
        updateOverviewItem: _updateOverviewItem,
        updateReviewedCheckbox: _updateReviewedCheckbox,
        setOverviewPropDataAndSave: _setOverviewPropDataAndSave,
    };
}

export default useOverview;
