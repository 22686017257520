import { createSlice } from '@reduxjs/toolkit';
import overviewReducers, { overviewExtraReducers } from './overview/overview.reducers';
import ledgerReducers, { ledgerExtraReducers } from './ledger/ledger.reducers';
import { vitalsExtraReducers, vitalsReducers } from './vitals/vitals.reducers';
import { allergiesExtraReducers, allergiesReducers } from './allergies/allergies.reducers';
import medicationReducers, { medicationsExtraReducers } from './medications/medications.reducers';
import { patientExtraReducers, patientReducers } from './patient.reducers';
import { patientInitialState } from './patient.state';
import auditLogReducers, { auditLogExtraReducers } from './audit-log/auditLog.reducers';

const patientSlice = createSlice({
    name: 'patient',
    initialState: patientInitialState,
    reducers: {
        ...patientReducers,
        ...overviewReducers,
        ...ledgerReducers,
        ...auditLogReducers,
        ...vitalsReducers,
        ...allergiesReducers,
        ...medicationReducers,
    },

    extraReducers: (builder) => {
        patientExtraReducers(builder);
        overviewExtraReducers(builder);
        ledgerExtraReducers(builder);
        auditLogExtraReducers(builder);
        vitalsExtraReducers(builder);
        allergiesExtraReducers(builder);
        medicationsExtraReducers(builder);
    },
});

const { reducer, actions } = patientSlice;

export const {
    //General Patient
    clearPatients,
    clearSelectedPatient,
    setSelectedPatient,
    setFindPatientOpen,
    setSelectedOverviewItem,
    cleanupSelectedOverviewItem,
    setOverviewItemDataProp,
    setLoadingCreateEncounter,
    // clearPatientPanel,
    setPatientNote,
    //Vitals
    addBloodPressureReading,
    bpRefuesed,
    heightRefused,
    setVitalPanelOpen,
    updateBloodPressure,
    updateReading,
    weightRefused,
    setVitalsPanelData,
    removeBloodPressure,
    // Allergies
    addAllergy,
    addReactionToAllergy,
    deleteAllergy,
    setAllergiesPanelOpen,
    setAllergyOnsetDate,
    setPatientLedger,
    updateAllergyReaction,
    updateAllergyReactionSeverity,
    deleteReactionToAllergy,
    updateAllergyCriticality,
    toggleShowAllergyHistory,
    clearAllergiesErrors,
    // Medications
    addChartMedication,
    setPatientMedicationsPanelOpen,
    setMedicationModal,
    updateMedicationField,
    updateChartMedicationField,
    removeChartMedication,
    setPatientMrn,
    setShowMedications,
    updateMedicationsView,
    //Audit Log
    setPatientAuditLog,
} = actions;

export default reducer;
