import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import { AxiosResponse, AxiosError } from 'axios';
import { LoadingStatus, LoadingStatuses } from 'interfaces/loading-statuses';
import { RootState } from 'state/store';
import { ITenantPayer } from 'api/models/payer.model';
import { map } from 'lodash';
import { IDropdownOption } from '@fluentui/react';

type PayerDetailsState = {
    initialLoad: LoadingStatuses;
    data?: Record<string, ITenantPayer>;
    errors?: string;
};

const initialState: PayerDetailsState = {
    initialLoad: LoadingStatus.Idle,
};

export const getTenantPayers = createAsyncThunk<
    AxiosResponse<Record<string, ITenantPayer>>,
    { tenantId: string },
    {
        rejectValue: AxiosError;
    }
>('getTenantPayers', async ({ tenantId }) => {
    const res = await dentalApi.getTenantPayers(tenantId);
    return res;
});

const tenantPayersSlice = createSlice({
    name: 'tenantPayer',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getTenantPayers.pending, (state) => {
                state.initialLoad = LoadingStatus.Pending;
            })
            .addCase(getTenantPayers.fulfilled, (state, action) => {
                state.initialLoad = LoadingStatus.Completed;
                state.data = action.payload.data;
            })
            .addCase(getTenantPayers.rejected, (state, action) => {
                state.initialLoad = LoadingStatus.Failed;
                state.errors = action.payload?.message;
            });
    },
});

const { reducer } = tenantPayersSlice;
export const selectTenantPayers = (state: RootState): PayerDetailsState => state.tenant.tenantPayers;
export const selectTenantPayersData = createSelector(selectTenantPayers, (state) => state?.data ?? {});
export const tenantPayersList = createSelector(
    [selectTenantPayersData],
    (payers) => map(payers).filter((insurance) => insurance !== undefined) as ITenantPayer[],
);

export const filteredTenantPayersList = createSelector(tenantPayersList, (payers) => payers.filter((payer) => !payer.isDeleted));

export const selectTenantPayerOptions = createSelector([filteredTenantPayersList], (payers) => {
    const options: IDropdownOption[] = payers.map((p) => ({
        key: p.id ?? '',
        data: p.payerId,
        text: p.name ?? 'Unknown Payer',
    }));

    return options;
});

export default reducer;
