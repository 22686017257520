import { Stack, TextField } from '@fluentui/react';
import { IPatientEligibility, IPatientInsurance } from 'api/models/patient.model';
import { Field, SubSectionHeader } from 'components';
import { getValidationError, IValidationError, validateErrorMessage } from 'hooks/useValidation';

type EligibilityProps = {
    insurance: IPatientInsurance | undefined;
    newEligibilityPropChange: (path: keyof IPatientEligibility, value: unknown) => void;
    validationErrors: IValidationError[];
};

function EligibilityCoinsurance(props: EligibilityProps): JSX.Element {
    const { insurance, newEligibilityPropChange, validationErrors } = props;

    const validNumberInput = (value?: string): boolean => {
        const res = value ? /^[0-9]*$/.test(value) : true;
        return res;
    };

    const onChange = (fieldName: keyof IPatientEligibility, value: string | undefined) => {
        if (validNumberInput(value))
            newEligibilityPropChange(fieldName, value !== undefined && value !== '' ? parseInt(value) : 0);
    };

    return (
        <Stack tokens={{ childrenGap: 10 }} grow>
            <SubSectionHeader text="Insurance Coverage" />
            <Stack tokens={{ childrenGap: 10 }} horizontal grow>
                <Stack.Item grow>
                    <TextField
                        label="Preventive"
                        prefix="%"
                        value={insurance?.eligibility?.preventativeBenefits?.toString() ?? ''}
                        onChange={(ev, value) => {
                            onChange('preventativeBenefits', value);
                        }}
                        autoComplete="off"
                        required
                        errorMessage={getValidationError(validationErrors, 'Preventive') ? 'Preventive is required.' : undefined}
                    />
                </Stack.Item>
                <Stack.Item grow>
                    <TextField
                        label="Basic"
                        prefix="%"
                        value={insurance?.eligibility?.basicBenefits?.toString() ?? ''}
                        onChange={(ev, value) => onChange('basicBenefits', value)}
                        autoComplete="off"
                        errorMessage={getValidationError(validationErrors, 'Basic') ? 'Basic is required.' : undefined}
                        required
                    />
                </Stack.Item>
                <Stack.Item grow>
                    <TextField
                        label="Major"
                        prefix="%"
                        value={insurance?.eligibility?.majorBenefits?.toString() ?? ''}
                        onChange={(ev, value) => onChange('majorBenefits', value)}
                        errorMessage={getValidationError(validationErrors, 'Major') ? 'Major is required.' : undefined}
                        autoComplete="off"
                        required
                    />
                </Stack.Item>
            </Stack>
            <Stack tokens={{ childrenGap: 10 }} horizontal grow>
                <Stack.Item grow>
                    <Field.Dropdown
                        label="Waiting Period for Basic Services"
                        placeholder="(Select)"
                        options={[
                            {
                                key: 'Yes',
                                text: 'Yes',
                            },
                            {
                                key: 'No',
                                text: 'No',
                            },
                        ]}
                        selectedKey={insurance?.eligibility?.waitingPeriod ?? null}
                        onChange={(e, option) => {
                            if (option) newEligibilityPropChange('waitingPeriod', option?.key);
                        }}
                    />
                </Stack.Item>
                <Stack.Item grow>
                    <Field.Date
                        label="Effective Date"
                        value={insurance?.eligibility?.basicWaitingPeriod ?? ''}
                        onChange={(ev, value) => newEligibilityPropChange('basicWaitingPeriod', value)}
                        hasDatePicker
                        errorMessage={validateErrorMessage(getValidationError(validationErrors, 'Basic Effective Date'))}
                    />
                </Stack.Item>
            </Stack>
            <Stack tokens={{ childrenGap: 10 }} horizontal grow>
                <Stack.Item grow>
                    <Field.Dropdown
                        label="Waiting Period for Major Services"
                        placeholder="(Select)"
                        options={[
                            {
                                key: 'Yes',
                                text: 'Yes',
                            },
                            {
                                key: 'No',
                                text: 'No',
                            },
                        ]}
                        selectedKey={insurance?.eligibility?.majorPeriod ?? null}
                        onChange={(e, option) => {
                            if (option) newEligibilityPropChange('majorPeriod', option?.key);
                        }}
                    />
                </Stack.Item>
                <Stack.Item grow>
                    <Field.Date
                        label="Effective Date"
                        value={insurance?.eligibility?.majorWaitingPeriod ?? ''}
                        onChange={(ev, value) => newEligibilityPropChange('majorWaitingPeriod', value)}
                        hasDatePicker
                        errorMessage={validateErrorMessage(getValidationError(validationErrors, 'Major Effective Date'))}
                    />
                </Stack.Item>
            </Stack>
        </Stack>
    );
}

export default EligibilityCoinsurance;
