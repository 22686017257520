import { MessageBarType } from '@fluentui/react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import { IBillingProcedure } from 'api/models/billing-procedure.model';
import { IEncounterCorrespondence } from 'api/models/encounter-claim-summary.model';
import { IEncounterSummary } from 'api/models/encounter-ledger.model';
import { EncounterStatus, IPatientEncounter, ProviderIdType } from 'api/models/encounter.model';
import axios from 'axios';
import { cloneDeep, isArray } from 'lodash';
import { batch } from 'react-redux';
import { selectAccountData } from 'state/slices/account.slice';
import { updatePatientEncounter } from 'state/slices/encounter/encounter.actions';
import { setClaimHistoryEncounterNoteModalOpen } from 'state/slices/ledger/ledger.slice';
import { AppThunk, RootState } from 'state/store';
import { getWorkListData, removeCurrentWorkListRecentItems } from '../worklist.actions';
import {
    selectWorkListEditItem,
    selectCurrentWorkList,
    selectWorkListReadOnly,
    selectWorkListSaving,
} from '../worklist.selectors';
import { setEncounterWorkListModalOpen, setWorkListMessageBar } from '../worklist.slice';
import { WorkList } from '../worklist.state';
import { selectEncounterWorkListNoteModalNote, selectEncounterWorkListNoteModalType } from './encounter-worklists.selectors';
import { EncounterWorkList, EncounterWorkListModalType, EncounterWorkLists } from './encounter-worklists.state';
import { v4 as uuid } from 'uuid';
import { setVisitSummaryPanelOpen } from '../../admin-huddle.slice';
import IWorkListEncounterView, { WorkListPayerSource } from 'api/models/worklist-encounter-view';
import { IAmendmentNote } from 'pages/Landing/AdminHuddle/components/LayerComponents/VisitSummary/VisitSummaryPanel';
import IPatient from 'api/models/patient.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import IServiceUserAccount from 'api/models/account.model';

export const getEncounterCorrespondenceEncounterWorkList = createAsyncThunk<
    IEncounterCorrespondence[],
    {
        tenantId: string;
        encounterId: string;
    }
>('getEncounterWorkListCorrespondence', async ({ tenantId, encounterId }) => {
    const { data } = await dentalApi.getEncounterCorrespondence(tenantId, encounterId);
    return data;
});

function createEncounterCorrespondence(
    account: IServiceUserAccount | undefined,
    note: string | undefined,
): IEncounterCorrespondence {
    const correspondence: IEncounterCorrespondence = {
        date: new Date().toISOString(),
        id: uuid(),
        message: note,
        user: account?.identity.id,
    };

    return correspondence;
}

export const saveEncounterCorrespondenceEncounterWorkList = createAsyncThunk<
    IEncounterCorrespondence[] | undefined,
    {
        tenantId: string;
        encounterId?: string;
        closeModalOnSave?: boolean;
    },
    { state: RootState }
>(
    'saveEncounterCorrespondenceEncounterWorkList',
    async ({ tenantId, encounterId, closeModalOnSave = true }, { getState, dispatch }) => {
        const view = selectWorkListEditItem<IWorkListEncounterView>(getState());
        const note = selectEncounterWorkListNoteModalNote(getState());
        const account = selectAccountData(getState());

        const encounterIdToUse = encounterId ?? view?.encounter?.id;

        const correspondence = createEncounterCorrespondence(account, note);

        if (encounterIdToUse) {
            const { data } = await dentalApi.saveEncounterCorrespondence(tenantId, encounterIdToUse, correspondence);

            if (closeModalOnSave) dispatch(setEncounterWorkListModalOpen({ isOpen: false }));
            return data;
        }
    },
);

export const updateEncounterWorkListMappings: Record<string, string> = {
    [EncounterWorkList.BilledPatient]: 'UpdateOpenBilled',
    [EncounterWorkList.BilledInsurance]: 'UpdateOpenBilled',
    [EncounterWorkList.ReadyToReview]: 'UpdateReadyToReview',
    [EncounterWorkList.ReadyToReviewOnHold]: 'UpdateReadyToReview',
};

export type WorkListEncounterUpdateParams = {
    tenantId: string;
    recordId: string;
    patient?: IPatient;
    encounter?: IPatientEncounter;
    workList: WorkList;
};

export const updateWorkListEncounterData = createAsyncThunk<IWorkListEncounterView, WorkListEncounterUpdateParams>(
    'updateWorkListEncounterData',
    async (params, { rejectWithValue }) => {
        try {
            const updateMethod = updateEncounterWorkListMappings[params.workList];
            const view = await dentalApi.updateEncounterWorkListItem({ ...params, updateMethod });
            return view.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

//When saving an encounter on an encounter work list, use this action. Handles removal of recent items from state.
export const saveEncounterWorkListPatientEncounter = createAsyncThunk<
    IPatientEncounter | IWorkListEncounterView | undefined,
    {
        tenantId: string;
        patientId: string;
        patientEncounter: IPatientEncounter;
    },
    { state: RootState }
>(
    'saveEncounterWorkListPatientEncounter',
    async ({ tenantId, patientId, patientEncounter }, { rejectWithValue, dispatch, getState }) => {
        try {
            const workList = selectCurrentWorkList(getState());

            let data: IPatientEncounter | IWorkListEncounterView | undefined;

            //The save method we use will depend on the work list. For the newer/updated worklists we have update mappings for each endpoint.
            //Older worklists will just use the encounter put endpoint.

            if (workList && updateEncounterWorkListMappings[workList]) {
                data = await dispatch(
                    updateWorkListEncounterData({
                        tenantId,
                        workList,
                        encounter: patientEncounter,
                        recordId: patientEncounter.id,
                    }),
                ).unwrap();
            } else {
                data = await dispatch(updatePatientEncounter({ tenantId, patientId, encounter: patientEncounter })).unwrap();
            }

            //In the context of encounter work lists, if the encounter is modified at all from a work list, it is removed from the current work list.
            dispatch(removeCurrentWorkListRecentItems(tenantId, [patientEncounter.id]));
            return data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const createAmendmentNote = createAsyncThunk<
    IAmendmentNote,
    {
        tenantId: string;
        queryObject: IAmendmentNote;
    },
    {
        state: RootState;
        rejectValue: string;
    }
>('createAmendmentNote', async ({ tenantId, queryObject }, { rejectWithValue, dispatch }) => {
    const { data } = await dentalApi.createAmendmentNote(tenantId, queryObject);
    if (data) {
        dispatch(removeCurrentWorkListRecentItems(tenantId, [queryObject.encounterId]));
        return queryObject;
    } else {
        return rejectWithValue(JSON.stringify(data));
    }
});

export const updateEncounterProviderByProviderIdType = createAsyncThunk<
    IPatientEncounter | undefined,
    {
        tenantId: string;
        patientId: string;
        encounterId: string;
        providerIdType: ProviderIdType;
        providerId: string;
    },
    {
        state: RootState;
        rejectValue: string;
    }
>(
    'updateEncounterProviderByProviderIdType',
    async ({ tenantId, patientId, encounterId, providerId, providerIdType }, { rejectWithValue }) => {
        try {
            const { data } = await dentalApi.updatePatientEncounterProviderIdByProviderIdType(
                tenantId,
                patientId,
                encounterId,
                providerIdType,
                providerId,
            );

            return data;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 412) {
                    return rejectWithValue(encounterModifiedBlockedMessage);
                } else {
                    return rejectWithValue(JSON.stringify(e));
                }
            }
        }
    },
);

const encounterStatusWorkFlowMappings: Partial<Record<WorkList, EncounterStatus>> = {
    BilledInsurance: EncounterStatus.CorrectionsNeeded,
    BilledPatient: EncounterStatus.CorrectionsNeeded,
    ReadyToRebillOnHold: EncounterStatus.CorrectionsNeeded,
    ReadyToRebill: EncounterStatus.CorrectionsNeeded,
    EditEncounters: EncounterStatus.ReBillOnHold,
};

/**
 * Determine if the passed data is an IWorkListEncounterView or IPatientEncounter.
 *
 * @param {(IPatientEncounter | IWorkListEncounterView)} data
 * @return {*}  {data is IWorkListEncounterView}
 */
export function isWorkListEncounterView(data: IPatientEncounter | IWorkListEncounterView): data is IWorkListEncounterView {
    return !!(data as IWorkListEncounterView)?.encounter;
}

export const saveEncounterStatusEncounterWorkList = createAsyncThunk<
    IPatientEncounter | IWorkListEncounterView | undefined,
    {
        tenantId: string;
        patientId: string;
        patientEncounter?: IPatientEncounter;
        encounterStatus?: EncounterStatus;
    },
    { state: RootState; rejectValue: string }
>(
    'saveEncounterStatusEncounterWorkList',
    async (
        { tenantId, patientId, patientEncounter: patientEncounter, encounterStatus },
        { getState, rejectWithValue, dispatch },
    ) => {
        try {
            const currentWorkList = selectCurrentWorkList(getState());
            const status = encounterStatus ?? (currentWorkList ? encounterStatusWorkFlowMappings[currentWorkList] : undefined);
            const encounter =
                patientEncounter !== undefined ? patientEncounter : selectWorkListEditItem<IWorkListEncounterView>(getState());

            if (encounter?.patientId && status) {
                const newEncounter: IPatientEncounter | undefined = isWorkListEncounterView(encounter)
                    ? encounter.encounter
                        ? { ...encounter.encounter, status }
                        : undefined
                    : { ...encounter, status };

                if (newEncounter) {
                    const data = await dispatch(
                        saveEncounterWorkListPatientEncounter({ tenantId, patientId, patientEncounter: newEncounter }),
                    ).unwrap();
                    return data;
                }
            }
        } catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 412) {
                    dispatch(setEncounterWorkListModalOpen({ isOpen: false }));
                    return rejectWithValue(encounterModifiedBlockedMessage);
                } else {
                    return rejectWithValue(JSON.stringify(e));
                }
            }
        }
    },
);

export const toggleEncounterWorkListItemIsRcm = createAsyncThunk<
    IWorkListEncounterView | undefined,
    {
        tenantId: string;
    },
    {
        state: RootState;
        rejectValue: string;
    }
>('toggleEncounterWorkListItemIsRcm', async ({ tenantId }, { rejectWithValue, dispatch, getState }) => {
    try {
        //const isRcm = selectEncounterWorkListNoteModalIsRCM(getState());
        const encounterView = selectWorkListEditItem<IWorkListEncounterView>(getState());

        //Only fire the network request to update isRcm if the current state differs from the actual data.
        if (encounterView) {
            const { data } = await dentalApi.updateCorrectionsNeededIsRcm(tenantId, encounterView.id, !encounterView.isRcm);
            return data;
        }
    } catch (e) {
        if (axios.isAxiosError(e)) {
            if (e.response?.status === 412) {
                dispatch(setEncounterWorkListModalOpen({ isOpen: false }));
                return rejectWithValue(encounterModifiedBlockedMessage);
            } else {
                return rejectWithValue(JSON.stringify(e));
            }
        }
    }
});

export const saveEncounterCorrespondenceAndSend =
    ({
        tenantId,
        patientId,
        encounterStatus,
        encounter,
    }: {
        tenantId: string;
        patientId: string;
        encounterStatus?: EncounterStatus;
        encounter?: IPatientEncounter;
    }): AppThunk<void> =>
    async (dispatch, getState) => {
        try {
            await dispatch(
                saveEncounterCorrespondenceEncounterWorkList({ tenantId, encounterId: encounter?.id, closeModalOnSave: false }),
            ).unwrap();

            await dispatch(
                saveEncounterStatusEncounterWorkList({ tenantId, patientId, encounterStatus, patientEncounter: encounter }),
            ).unwrap();

            dispatch(setEncounterWorkListModalOpen({ isOpen: false }));
            const currentEncounterWorkList = selectCurrentWorkList(getState());
            if (currentEncounterWorkList)
                await dispatch(getWorkListData({ tenantId, workList: currentEncounterWorkList })).unwrap();
        } catch (e) {
            //We can just return here because the individual thunks will specifically handle the caught error.
            return;
        }
    };

export const setOnHoldStatus = createAsyncThunk<IWorkListEncounterView, string, { state: RootState; rejectedValue: string }>(
    'setOnHoldStatus',
    async (tenantId, { getState, rejectWithValue }) => {
        const { currentNote } = getState().adminHuddle.workLists.encounterWorkListNoteModal;
        const selectedEncounter = getState().adminHuddle.workLists.editWorkListItem as IWorkListEncounterView;
        if (selectedEncounter.encounter && currentNote !== undefined) {
            const { data } = await dentalApi.setEncounterOnHoldStatus(tenantId, selectedEncounter.encounter.id, {
                hold: !selectedEncounter.encounter.isOnAdministrativeHold,
                note: currentNote,
            });
            return data;
        } else {
            return rejectWithValue('Something went wrong...');
        }
    },
);

export const markEncountersAsBilled = createAsyncThunk<
    IWorkListEncounterView[],
    {
        tenantId: string;
        encounterIds: string[];
    },
    { state: RootState }
>('markEncountersAsBilled', async ({ tenantId, encounterIds }, { dispatch }) => {
    const { data } = await dentalApi.markEncountersAsBilled(tenantId, encounterIds);
    dispatch(removeCurrentWorkListRecentItems(tenantId, encounterIds));
    return data;
});

export const markEncountersAsRebilled = createAsyncThunk<
    string[],
    {
        tenantId: string;
        encounterIds: string[];
        batchId?: string;
    },
    { state: RootState }
>('markEncountersAsRebilled', async ({ tenantId, encounterIds, batchId }, { dispatch }) => {
    let data: string[] = [];
    if (!batchId) {
        const { data: responseData } = await dentalApi.markEncountersAsRebilled(tenantId, encounterIds);
        data = responseData;
    } else {
        const { data: responseData } = await dentalApi.markEncountersAsRebilledWithBatch(tenantId, encounterIds, batchId);
        data = responseData;
    }

    dispatch(removeCurrentWorkListRecentItems(tenantId, encounterIds));
    return data;
});

export const updateEncounterWorkListEncounterNote = createAsyncThunk<
    IPatientEncounter | IWorkListEncounterView | undefined,
    string,
    { state: RootState }
>('updateEncounterNote', async (tenantId, { getState, dispatch }) => {
    const { currentNote, type } = getState().adminHuddle.workLists.encounterWorkListNoteModal;
    const selectedEncounter = selectWorkListEditItem<IWorkListEncounterView>(getState());

    const encounter = cloneDeep(selectedEncounter?.encounter);
    if (encounter && encounter.patientId) {
        switch (type) {
            case 'administrativeHold': {
                encounter[type] = { ...encounter[type], note: currentNote };
                break;
            }
            case 'billingNote': {
                encounter[type] = currentNote;
                break;
            }
        }

        const data = await dispatch(
            saveEncounterWorkListPatientEncounter({ tenantId, patientId: encounter.patientId, patientEncounter: encounter }),
        ).unwrap();

        return data;
    }
});

export const recalculateEncounter = createAsyncThunk<
    IWorkListEncounterView,
    { tenantId: string; encounter: IWorkListEncounterView },
    { state: RootState; rejectedValue: string }
>('recalculateEncounter', async ({ tenantId, encounter }, { rejectWithValue }) => {
    const patientId = encounter.patientId;
    const encounterId = encounter.encounter?.id;
    if (patientId && encounterId) {
        const { data } = await dentalApi.recalculateEncounter(tenantId, patientId, encounterId);
        return data;
    }
    return rejectWithValue('Something went wrong...');
});

export const voidEncounterWorkListNonClinicalProcedure = createAsyncThunk<
    IBillingProcedure,
    { tenantId: string; billingProcedureId: string; encounterId: string; patientId: string },
    { state: RootState; rejectedValue: string }
>(
    'voidEncounterWorkListNonClinicalProcedure',
    async ({ tenantId, billingProcedureId, encounterId, patientId }, { rejectWithValue }) => {
        try {
            const response = await dentalApi.voidNonClinicalProcedure(tenantId, patientId, encounterId, billingProcedureId);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const encounterModifiedBlockedMessage = 'Another user has sent/moved the encounter you were viewing/editing.';

/**
 * Determine if the passed data is an IWorkListEncounterView or IEncounterSummary.
 *
 * @param {(IEncounterSummary | IWorkListEncounterView)} data
 * @return {*}  {data is IWorkListEncounterView}
 */
function isAdminEncounterViewOrIEncounterSummary(
    data: IWorkListEncounterView | IEncounterSummary,
): data is IWorkListEncounterView {
    return !!(data as IWorkListEncounterView)?.encounter;
}

export const handleShouldCloseEncounterNoteModal =
    (
        data: IWorkListEncounterView | { tenantId: string; id: string },
        editEncounter: IWorkListEncounterView | IEncounterSummary | undefined,
    ): AppThunk<void> =>
    (dispatch, getState) => {
        if (!editEncounter) return;

        const noteModalType = selectEncounterWorkListNoteModalType(getState());
        const readOnly = selectWorkListReadOnly(getState());
        const saveStatus = selectWorkListSaving(getState());

        //Check type of edit data.
        const isAdminEncounterView = isAdminEncounterViewOrIEncounterSummary(editEncounter);
        //Get encounter id based on the type.
        const editEncounterId = isAdminEncounterView ? editEncounter.encounter?.id : editEncounter.encounterId;

        //If the user is currently editing the updated encounter.
        if (
            data?.id === editEncounterId &&
            saveStatus !== LoadingStatus.Pending &&
            !readOnly &&
            noteModalType === EncounterWorkListModalType.CorrectionNote
        ) {
            //Close the encounter correspondence note panel and display a message.
            batch(() => {
                // Depending on what type of editEncounter we have, we know whether we are needing to close the claim
                // history modal or the encounter worklist modal
                if (isAdminEncounterView) {
                    dispatch(setEncounterWorkListModalOpen({ isOpen: false }));
                    //In the case of being on a worklist we need to close the visit summary panel as well.
                    dispatch(setVisitSummaryPanelOpen(false));
                } else {
                    dispatch(setClaimHistoryEncounterNoteModalOpen(false));
                }
                dispatch(
                    setWorkListMessageBar({
                        message: encounterModifiedBlockedMessage,
                        messageBarType: MessageBarType.blocked,
                    }),
                );
            });
        }
    };
