import { Dictionary } from '@reduxjs/toolkit';
import IMedication from 'api/models/medications.model';
import IPatientMedicationsView, { IPatientChartMedication } from 'api/models/patient-medication.model';
import { LoadingStatus, LoadingStatuses } from 'interfaces/loading-statuses';

export type MedicationsState = {
    data?: IPatientMedicationsView;
    loading: LoadingStatuses;
    saving: LoadingStatuses;
    isMedicationPanelOpen: boolean;
    isMedicationStopModalOpen: boolean;
    medicationToUpdate?: IPatientChartMedication;
    patientMedicationsToAdd: IPatientChartMedication[];
    medicationList: Dictionary<IMedication>;
    showMedicationHistory: boolean;
};

export const initialMedicationsState: MedicationsState = {
    loading: LoadingStatus.Idle,
    saving: LoadingStatus.Idle,
    isMedicationPanelOpen: false,
    isMedicationStopModalOpen: false,
    patientMedicationsToAdd: [],
    medicationList: {},
    showMedicationHistory: false,
};
