import { ComboBox, IComboBoxOption, IDropdownOption, Stack } from '@fluentui/react';
import { IPatientPhoneNumber } from 'api/models/patient.model';
import { TModal, TText } from 'components';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectEditPatient } from 'state/slices/edit-patient/edit-patient.selectors';

type Props = {
    title: string;
    confirmationMessage: string;
    isOpen: boolean;
    onContinue: (value: IPatientPhoneNumber[]) => void;
    onDismiss: () => void;
    dropdownShown?: boolean;
    editingPhoneNumber?: IPatientPhoneNumber;
};

export default function ConfirmationModal({
    title,
    confirmationMessage,
    isOpen,
    onDismiss,
    onContinue,
    dropdownShown,
    editingPhoneNumber,
}: Props): JSX.Element {
    const [patientPhoneNumber, setPatientPhoneNumber] = useState<IPatientPhoneNumber[]>([]);

    const patient = useSelector(selectEditPatient);

    useEffect(() => {
        if (patient?.phoneNumbers) {
            setPatientPhoneNumber(patient?.phoneNumbers);
        }
    }, [patient?.phoneNumbers]);

    const editingPhoneNumberTypes: any = patient?.phoneNumbers
        ?.filter((res) => res.number?.length)
        .filter((res) => res.type != editingPhoneNumber?.type)
        .map((res) => {
            return {
                key: res.type,
                text: res.type,
            };
        });

    const restOfPatientNumbers = patientPhoneNumber.filter((res) => res.type != editingPhoneNumber?.type);

    const checkPrimary = () => {
        if (editingPhoneNumber?.isPrimary === false) {
            return false;
        } else {
            return restOfPatientNumbers?.find((res) => res.isPrimary === true);
        }
    };

    const setPrimary = (primary: IComboBoxOption) => {
        if (primary) {
            let specificNumber = restOfPatientNumbers?.find((res) => res.type === primary.text);

            if (specificNumber) {
                const index = patientPhoneNumber?.indexOf(specificNumber);
                const toChange = [...patientPhoneNumber];
                specificNumber = { ...specificNumber, isPrimary: true };
                if (toChange) {
                    toChange?.splice(index, 1, specificNumber);
                    setPatientPhoneNumber(toChange);
                }
                checkPrimary();
            }
        }
    };

    return (
        <TModal
            title={title}
            modalProps={{
                isBlocking: false,
                isOpen,
                onDismiss,
            }}
            isDraggable
            mainButtons={[
                {
                    type: 'PrimaryButton',
                    text: 'Continue',
                    onClick: () => onContinue(patientPhoneNumber),
                    disabled: checkPrimary() === undefined,
                },
                {
                    type: 'DefaultButton',
                    text: 'Cancel',
                    onClick: onDismiss,
                },
            ]}
        >
            <Stack styles={{ root: { padding: 10 } }} grow>
                <TText>{confirmationMessage}</TText>
                {dropdownShown ? (
                    <Stack style={{ paddingLeft: 20 }}>
                        <ComboBox
                            label="Primary Phone"
                            style={{ width: 120 }}
                            //selectedKey={checkPrimary()}
                            allowFreeform
                            autoComplete="on"
                            onChange={(e, option) => {
                                if (option) setPrimary(option);
                            }}
                            options={editingPhoneNumberTypes}
                        />
                    </Stack>
                ) : undefined}
            </Stack>
        </TModal>
    );
}
