import { useTheme, mergeStyles, Stack, Shimmer, MessageBar, MessageBarType, Text, Link } from '@fluentui/react';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { PropsWithChildren } from 'react';

export type UACDistributionCardProps = {
    onLinkClick?: (event?: React.MouseEvent) => void;
    title?: string;
    loading?: LoadingStatus;
};

export default function UACDistributionCard({
    title,
    children,
    onLinkClick,
    loading,
}: PropsWithChildren<UACDistributionCardProps>) {
    const { palette } = useTheme();
    const UACCardStyles = mergeStyles({
        backgroundColor: palette.neutralLighterAlt,
        padding: 10,
        borderRadius: '2px',
        minWidth: 100,
    });

    return (
        <Stack tokens={{ childrenGap: 5 }} className={UACCardStyles}>
            <Text variant="mediumPlus">
                <b>
                    {onLinkClick ? (
                        <Link styles={{ root: { textDecoration: 'underline' } }} onClick={onLinkClick}>
                            {title}
                        </Link>
                    ) : (
                        title
                    )}
                </b>
            </Text>
            {loading === LoadingStatus.Pending ? (
                <Stack grow tokens={{ childrenGap: 5 }}>
                    <Shimmer width={70} />
                    <Shimmer width={120} />
                    <Shimmer width={140} />
                </Stack>
            ) : loading === LoadingStatus.Failed ? (
                <MessageBar messageBarType={MessageBarType.error}>Something went wrong.</MessageBar>
            ) : (
                <Stack>{children}</Stack>
            )}
        </Stack>
    );
}
