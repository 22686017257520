import AllergiesDetailsList from './AllergiesDetailsList';
import { Stack, IconButton, IButtonStyles, Text, Checkbox, TooltipHost, Toggle, Spinner, Label } from '@fluentui/react';
import { Section } from 'components';
import AllergiesPanel from './AllergiesPanel';
import { useDispatch, useSelector } from 'react-redux';
import { setAllergiesPanelOpen, toggleShowAllergyHistory } from 'state/slices/patient/patient.slice';
import {
    selectPatientAllergies,
    selectSavingAllergies,
    showAllergyHistory,
} from 'state/slices/patient/allergies/allergies.selectors';
import { updateNKDACheckbox } from 'state/slices/patient/allergies/allergies.actions';
import { useParams } from 'react-router';
import { RouteParams } from 'interfaces/route-params';
import { useEncounter, useAccount } from 'hooks';
import useEncounterId from 'hooks/useEncounterId';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { selectPatientEncounterSaving } from 'state/slices/encounter/encounter.selectors';
import { SyncStatus } from 'interfaces/syncing-statuses';

function Allergies(): JSX.Element {
    const dispatch = useDispatch();
    const { tenantId, patientId, encounterId } = useParams<RouteParams>();
    const showHistory = useSelector(showAllergyHistory);
    const saving = useSelector(selectSavingAllergies);

    const isSaving = saving === LoadingStatus.Pending;

    const menuButtonStyles: IButtonStyles = {
        root: {
            height: 25,
            maxWidth: 25,
        },
        flexContainer: {
            selectors: {
                '.ms-Button-menuIcon': {
                    display: 'none',
                },
            },
        },
    };

    const handleAddAllergies = () => {
        dispatch(setAllergiesPanelOpen(true));
    };
    const _toggleShowAllergyHistory = () => {
        dispatch(toggleShowAllergyHistory());
    };

    const _handleOnChange = (ev?: React.FormEvent, checked?: boolean) => {
        if (checked !== undefined) dispatch(updateNKDACheckbox(tenantId, patientId, checked));
    };

    return (
        <Section
            heading={
                <Stack tokens={{ childrenGap: 10 }} horizontal grow>
                    <Text variant="xLarge">Allergies</Text>
                    {encounterId && (
                        <IconButton styles={menuButtonStyles} iconProps={{ iconName: 'Add' }} onClick={handleAddAllergies} />
                    )}
                </Stack>
            }
            headingRightContent={
                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }}>
                    <Toggle
                        styles={{ root: { marginBottom: 0 } }}
                        inlineLabel
                        label={showHistory ? 'Hide history' : 'Show history'}
                        checked={showHistory}
                        onClick={_toggleShowAllergyHistory}
                    />
                    <AllergiesReviewed />
                    {isSaving ? (
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <Spinner />
                            <Label>NKDA</Label>
                        </Stack>
                    ) : (
                        <NKDACheckbox onChange={_handleOnChange} />
                    )}
                </Stack>
            }
        >
            <AllergiesPanel />
            <AllergiesDetailsList />
        </Section>
    );
}

export function AllergiesReviewed(): JSX.Element | null {
    const encounterId = useEncounterId();
    const { setCurrentEncounterDataProp, patientEncounter, saveCurrentEncounter } = useEncounter();
    const savingPatientEncounter = useSelector(selectPatientEncounterSaving);
    const { account } = useAccount();

    const isSaving = savingPatientEncounter === SyncStatus.Pending;

    if (!encounterId || !patientEncounter) return null;
    function onProblemsListReviewedClick() {
        if (!patientEncounter?.isAllergiesReviewed === true) {
            setCurrentEncounterDataProp('allergiesReviewedDate', new Date().toISOString());
            setCurrentEncounterDataProp('allergiesReviewedBy', account.data?.identity.id);
        } else {
            setCurrentEncounterDataProp('allergiesReviewedDate', undefined);
            setCurrentEncounterDataProp('allergiesReviewedBy', undefined);
        }
        setCurrentEncounterDataProp('isAllergiesReviewed', !patientEncounter?.isAllergiesReviewed);
        saveCurrentEncounter();
    }
    return isSaving ? (
        <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Spinner />
            <Label>Allergy list reviewed</Label>
        </Stack>
    ) : (
        <Checkbox
            label="Allergy list reviewed"
            onChange={onProblemsListReviewedClick}
            checked={!!patientEncounter?.isAllergiesReviewed}
        />
    );
}

export function NKDACheckbox({ onChange }: { onChange: (ev?: React.FormEvent, checked?: boolean) => void }): JSX.Element {
    const patientAllergies = useSelector(selectPatientAllergies);

    return (
        <TooltipHost content="No known drug allergies">
            <Checkbox checked={patientAllergies?.nkda ?? false} label="NKDA" onChange={onChange} />
        </TooltipHost>
    );
}

export default Allergies;
