import { Dropdown, IDropdownOption, Stack } from '@fluentui/react';
import { ReportType } from 'api/models/embed-report.model';
import { Field } from 'components';
import { useSelector, useTenantId } from 'hooks';
import { getValidationError } from 'hooks/useValidation';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import reportActionLookup from 'state/slices/reporting/reportActionLookup';
import { getAllLocationsOfCareForReports } from 'state/slices/reporting/reporting.actions';
import {
    selectLocationsOfCareFromReporting,
    selectReportProperties,
    selectReportingValidationErrors,
} from 'state/slices/reporting/reporting.selectors';
import { setAppointmentsDetailsProp, toggleAppointmentId } from 'state/slices/reporting/reporting.slice';
import { DailyScheduleReportProperties } from 'state/slices/reporting/reporting.state';
import { selectHygienistOptions, selectTreatingProviderOptions } from 'state/slices/tenant/providers.slice';
import { classicDateOnly } from 'utils/dateOnly';
import ReportWrapper from '../ReportWrapper';

export default function DailySchedule() {
    const dispatch = useDispatch();
    const tenantId = useTenantId();

    const errors = useSelector(selectReportingValidationErrors);
    const _providerOptions = useSelector(selectTreatingProviderOptions);
    const _hygientistOptions = useSelector(selectHygienistOptions);
    const locationsOfCare = useSelector(selectLocationsOfCareFromReporting);
    const reportProperties = useSelector(selectReportProperties) as DailyScheduleReportProperties | undefined;

    const locationsOfCareOptions: IDropdownOption[] = locationsOfCare.map((loc) => ({
        key: loc.id,
        text: loc.displayName,
    }));

    const viewReport = () => {
        if (reportProperties && tenantId)
            dispatch(
                reportActionLookup[ReportType.DailySchedule]({
                    tenantId,
                    ...reportProperties,
                }),
            );
    };

    useEffect(() => {
        dispatch(getAllLocationsOfCareForReports({ tenantId }));
    }, []);

    const groupCol: IDropdownOption[] = [
        { key: '', text: '(Select Group Column)' },
        { key: 'Confirmation Status', text: 'Confirmation Status' },
        { key: 'Hygienist', text: 'Hygienist' },
        { key: 'Location Of Care', text: 'Location of Care' },
        { key: 'Appointment Reason', text: 'Reason for Encounter' },
        { key: 'Treating Provider', text: ' Treating Provider' },
    ];

    return (
        <ReportWrapper
            validationConfig={[
                {
                    fieldName: 'Start Date',
                    validation: ['required'],
                    value: reportProperties?.apptStart,
                },
                {
                    fieldName: 'End Date',
                    validation: ['required'],
                    value: reportProperties?.apptEnd,
                },
                {
                    fieldName: 'Location of Care',
                    validation: ['required'],
                    value: reportProperties?.locationOfCareIds,
                },
            ]}
            onViewReport={viewReport}
        >
            <Stack tokens={{ childrenGap: 10 }} grow>
                <Stack grow horizontal wrap tokens={{ childrenGap: 10 }}>
                    <Stack.Item grow>
                        <Field.Date
                            label="Start Date"
                            required
                            hasDatePicker
                            onChange={(ev, value) => {
                                const newDate = value ? classicDateOnly(value, 'yyyy-MM-dd') : '';
                                dispatch(
                                    setAppointmentsDetailsProp({
                                        path: 'apptStart',
                                        value: newDate,
                                    }),
                                );
                            }}
                            value={reportProperties?.apptStart ? classicDateOnly(reportProperties?.apptStart, 'MM/dd/yyyy') : ''}
                            errorMessage={getValidationError(errors, 'Start Date') ? 'Start date is required.' : undefined}
                        />
                    </Stack.Item>
                    <Stack.Item grow>
                        <Field.Date
                            label="End Date"
                            required
                            isReasonable
                            hasDatePicker
                            value={reportProperties?.apptEnd ? classicDateOnly(reportProperties?.apptEnd, 'MM/dd/yyyy') : ''}
                            onChange={(ev, value) => {
                                const newDate = value ? classicDateOnly(value, 'yyyy-MM-dd') : undefined;
                                dispatch(
                                    setAppointmentsDetailsProp({
                                        path: 'apptEnd',
                                        value: newDate,
                                    }),
                                );
                            }}
                            disabled={!reportProperties?.apptStart}
                            minReasonableErrorMessage="End date must be after start date."
                            minReasonableDate={
                                reportProperties?.apptStart ? new Date(classicDateOnly(reportProperties?.apptStart)) : undefined
                            }
                            errorMessage={getValidationError(errors, 'End Date') ? 'End date is required.' : undefined}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <Field.SearchCombo
                            placeholder="(Select Locations of Care)"
                            label="Locations of Care"
                            multiSelect
                            required
                            options={locationsOfCareOptions}
                            onChange={(ev, option) => {
                                if (option?.key)
                                    dispatch(
                                        toggleAppointmentId({
                                            id: option.key as string,
                                            path: 'locationOfCareIds',
                                        }),
                                    );
                            }}
                            selectedKey={reportProperties?.locationOfCareIds}
                            errorMessage={
                                getValidationError(errors, 'Location of Care') ? 'Location of Care is required.' : undefined
                            }
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <Field.SearchCombo
                            placeholder="(Select Treating Provider)"
                            label=" Treating Providers"
                            multiSelect
                            options={_providerOptions}
                            onChange={(ev, option) => {
                                if (option?.key)
                                    dispatch(
                                        toggleAppointmentId({
                                            id: option.key as string,
                                            path: 'treatingProviderIds',
                                        }),
                                    );
                            }}
                            selectedKey={reportProperties?.treatingProviderIds}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <Field.SearchCombo
                            placeholder="(Select Hygienist)"
                            label="Hygienists"
                            multiSelect
                            options={_hygientistOptions}
                            onChange={(ev, option) => {
                                if (option?.key)
                                    dispatch(
                                        toggleAppointmentId({
                                            id: option.key as string,
                                            path: 'hygienistIds',
                                        }),
                                    );
                            }}
                            selectedKey={reportProperties?.hygienistIds}
                        />
                    </Stack.Item>

                    <Stack.Item grow>
                        <Dropdown
                            label="Group Column 1"
                            placeholder="(Select Group Column)"
                            options={groupCol}
                            onChange={(ev, option) => {
                                if (option) {
                                    dispatch(
                                        setAppointmentsDetailsProp({
                                            path: 'groupCol1',
                                            value: option.key as string,
                                        }),
                                    );
                                }
                            }}
                            selectedKey={reportProperties?.groupCol1}
                        />
                    </Stack.Item>
                    <Stack.Item grow>
                        <Dropdown
                            label="Group Column 2"
                            placeholder="(Select Group Column)"
                            options={groupCol}
                            onChange={(ev, option) => {
                                if (option) {
                                    dispatch(
                                        setAppointmentsDetailsProp({
                                            path: 'groupCol2',
                                            value: option.key as string,
                                        }),
                                    );
                                }
                            }}
                            selectedKey={reportProperties?.groupCol2}
                        />
                    </Stack.Item>
                </Stack>
            </Stack>
        </ReportWrapper>
    );
}
