import { useCallback, useState } from 'react';
import { useSelector } from 'hooks';
import { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { deselectTeeth, setAssetsLoaded } from 'state/slices/charting/chart/chart.slice';
import ChartApp from './ChartApp';
import { IStyleFunctionOrObject, ITheme, styled } from '@fluentui/react';
import { useParams } from 'react-router';
import { useResizeDetector } from 'react-resize-detector';
import { assetsLoadedSelector } from 'state/slices/charting/ui/chartingUi.selectors';
import { chartRenderDataSelector } from 'state/slices/charting/chart/chart.selectors';
import { fetchChart } from 'state/slices/charting/chart/chart.actions';

type Props = {
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<any, any>;
};

function ToothCanvas({ theme }: Props) {
    const _dispatch = useDispatch();
    const themeMode = useSelector((state) => state.ui.selectedTheme);

    const onResize = useCallback((width, height) => {
        ChartApp.resizeView(width, height);
    }, []);

    const { width, height, ref } = useResizeDetector({ onResize });
    const [hasInitViewSize, setHasInitViewSize] = useState(false);
    const [isHoveringOverTooth, setIsHoveringOverTooth] = useState(false);

    const { loading } = useSelector((state) => state.charting);
    const assetsLoaded = useSelector(assetsLoadedSelector);
    const { tenantId, patientId } = useParams<{ tenantId: string; patientId: string }>();

    const teeth = useSelector(chartRenderDataSelector);

    const refCanvasDiv = useRef<HTMLDivElement>(null);

    const _setAssetsLoaded = () => _dispatch(setAssetsLoaded(true));

    const _onToothHover = (isHovering: boolean) => {
        setIsHoveringOverTooth(isHovering);
    };

    // * Load assets, setup window resize and stop/start app
    useEffect(() => {
        // * Fetch teeth data
        _dispatch(fetchChart({ tenantId, patientId }));

        // * Load assets and start pixi app
        ChartApp.startApp(_setAssetsLoaded, _onToothHover);

        // * Cleanup and stop app
        return () => {
            ChartApp.stopApp();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // * Fire resizeView when the teeth have been added to the canvas to calculate main container size.
    useEffect(() => {
        if (width && height && !hasInitViewSize && teeth.length) {
            ChartApp._onTeethInitialized = () => ChartApp.resizeView(width, height);
            setHasInitViewSize(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width, height]);

    // * Initialize ref and div
    useEffect(
        onInitView,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [ChartApp, refCanvasDiv],
    );

    // * Render teeth
    useEffect(
        render,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [teeth, assetsLoaded, loading],
    );

    useEffect(
        onChangeTheme,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [themeMode, loading],
    );

    function render() {
        if (teeth && teeth.length && assetsLoaded && loading === 'completed') {
            ChartApp.renderChart(teeth, { width, height });
        }
    }

    function onChangeTheme() {
        if (teeth && teeth.length && assetsLoaded && loading === 'completed') ChartApp.changeTheme(theme, themeMode);
    }

    function onInitView() {
        if (refCanvasDiv.current) refCanvasDiv.current.innerHTML = '';
        const view = ChartApp.view;
        refCanvasDiv.current?.appendChild(view);
    }

    return (
        <>
            <div id="tooth-canvas" style={{ flex: 1, minWidth: 650, position: 'relative' }} ref={ref}></div>
            <div
                style={{
                    position: 'absolute',
                    width,
                    height,
                    backgroundColor: themeMode === 'dark' ? '#B8B8B8' : '#D9D9D9',
                }}
                //check if tooth is hovered here;
                onClick={(e) => {
                    if (!isHoveringOverTooth) {
                        _dispatch(deselectTeeth());
                    }
                }}
                className="ms-motion-slideUpIn"
                ref={refCanvasDiv}
            />
        </>
    );
}

export default styled<Props, any, any>(ToothCanvas, {});
