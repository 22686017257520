import { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    payAllPatientPayments,
    clearPayments,
    updatePatientPaymentTransaction,
    setUACDistributionSectionOpen,
    toggleAllowOveradjustmentOrPayment,
} from 'state/slices/ledger/ledger.slice';
import {
    selectUACDistributionPaymentSource,
    selectUACDistributionPatientBalanceAmounts,
    selectUACDistributionOpenSections,
    selectUACDistributionTotalRemaining,
    selectUACPatientBalanceAllEncounterSummaries,
    selectUACPatientBalanceEncounterSummariesWithFee,
} from 'state/slices/ledger/uac-distribution/uac-distribution.selectors';
import PaymentsAndAdjustmentsList from '../PaymentsAndAdjustments/PaymentsAndAdjustmentsList';
import UACDistributionSection from './UACDistributionSection';
import {
    selectPatientOverpaymentOrOverAdjustment,
    selectPatientPaymentTransactions,
    selectPatientPaymentsAndAdjustmentsBillingProceduresLoadingStatus,
} from 'state/slices/ledger/patient-payments-and-adjustments/patient-payments-and-adjustments.selectors';

const UACDistributionPatientBalanceSection = forwardRef<HTMLDivElement, { onLinkClick?: () => void }>(({ onLinkClick }, ref) => {
    const dispatch = useDispatch();

    const totalAmountRemaining = useSelector(selectUACDistributionTotalRemaining);
    const data = useSelector(selectUACDistributionPaymentSource);
    const { patientBalanceOpen } = useSelector(selectUACDistributionOpenSections);

    const allSummaries = useSelector(selectUACPatientBalanceAllEncounterSummaries);
    const summariesWithAmount = useSelector(selectUACPatientBalanceEncounterSummariesWithFee);

    const transactions = useSelector(selectPatientPaymentTransactions);
    const { patientBalanceTotal, patientBalancePaymentAmount } = useSelector(selectUACDistributionPatientBalanceAmounts);

    const loadingTotals = useSelector(selectPatientPaymentsAndAdjustmentsBillingProceduresLoadingStatus);

    const overpaymentOrAdjustment = useSelector(selectPatientOverpaymentOrOverAdjustment);

    const _onPayAll = () => {
        dispatch(payAllPatientPayments({ maxAmount: totalAmountRemaining }));
    };

    const _onClearPayments = () => {
        dispatch(clearPayments());
    };

    const _toggleExpandSection = () => {
        dispatch(setUACDistributionSectionOpen('patientBalanceOpen'));
    };

    const _onToggleOverpaymentOrAdjustment = () => {
        dispatch(toggleAllowOveradjustmentOrPayment());
    };

    return (
        <UACDistributionSection
            onClear={_onClearPayments}
            onDistribute={_onPayAll}
            ref={ref}
            loading={loadingTotals}
            total={patientBalanceTotal}
            title="Patient Balance"
            isExpanded={!!patientBalanceOpen}
            disableDistribute={overpaymentOrAdjustment}
            disableClear={patientBalancePaymentAmount <= 0}
            onLinkClick={onLinkClick}
            toggleExpanded={_toggleExpandSection}
            onToggleAllowOverpayment={_onToggleOverpaymentOrAdjustment}
            allowOverpayment={overpaymentOrAdjustment}
        >
            <PaymentsAndAdjustmentsList
                transactions={transactions}
                onChangePayment={(encounterId, transactionId, amount) => {
                    dispatch(updatePatientPaymentTransaction({ encounterId, transactionId, amount }));
                }}
                remainingPaymentAmount={totalAmountRemaining}
                paymentAmount={data?.amount ?? 0}
                allSummaries={allSummaries}
                summariesWithAmount={summariesWithAmount}
                makingPaymentOrAdjustment
                overpayment={overpaymentOrAdjustment}
                hideMakeAdjustment
            />
        </UACDistributionSection>
    );
});

UACDistributionPatientBalanceSection.displayName = 'UACDistributionPatientBalanceSection';

export default UACDistributionPatientBalanceSection;
