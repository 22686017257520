import { IPatientEncounterWithAppointment, IPatientEncounter } from 'api/models/encounter.model';
import { Dictionary } from '@reduxjs/toolkit';
import { IUserIdentity } from 'api/models/account.model';
import IPatientPrescription from 'api/models/patient-prescription';
import IPatientAppointment from 'api/models/Scheduling/patientAppointment.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { SyncStatus } from 'interfaces/syncing-statuses';
import IUserTask from 'api/models/user-task.model';

export const encounterInitialState: EncounterState = {
    loading: LoadingStatus.Idle,
    loadingPatientEncounterAppointment: LoadingStatus.Idle,
    loadingPatientEncounter: LoadingStatus.Idle,
    loadingPatientEncounterPrescriptions: LoadingStatus.Idle,
    loadingIncompletePatientEncounters: LoadingStatus.Idle,
    savingPatientEncounterProviders: LoadingStatus.Idle,
    savingPatientEncounter: SyncStatus.Idle,
    patientEncounterUserIdentities: {},
    patientEncounterPrescriptions: [],
};

export interface EncounterState {
    loading: LoadingStatus;
    loadingPatientEncounterAppointment: LoadingStatus;
    loadingPatientEncounterPrescriptions: LoadingStatus;
    loadingPatientEncounter: LoadingStatus;
    loadingIncompletePatientEncounters: LoadingStatus;
    savingPatientEncounter: SyncStatus;
    savingPatientEncounterProviders: LoadingStatus;
    patientEncounterAppointment?: IPatientAppointment;
    patientEncounter?: IPatientEncounter;
    patientEncounterTasks?: IUserTask[];
    patientIncompleteEncounters?: IPatientEncounterWithAppointment[];
    patientEncounterUserIdentities: Dictionary<IUserIdentity>;
    patientEncounterPrescriptions: IPatientPrescription[];
}
