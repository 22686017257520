import { MessageBar, MessageBarType, SelectionMode } from '@fluentui/react';
import { IMostRecentTreatmentPlanView } from 'api/models/most-recent-treatment-plan-view.model';
import { ExpandableDetailsList } from 'components';
import { useSelector } from 'react-redux';
import {
    selectSignedTreatmentPlanViewHasError,
    selectSignedTreatmentPlanIsLoading,
    selectSignedTreatmentPlanView,
    selectSignedTreatmentPlanViewPhasePrepaymentTotal,
} from 'state/slices/ledger/treatment-plan-credit-and-uac/treatment-plan-credit-and-uac.selectors';
import TreatmentPlanPhaseViewList from './TreatmentPlanCreditPhaseViewList';
import { usdCurrencyFormatter } from 'utils';

export default function TreatmentPlanCreditList() {
    const view = useSelector(selectSignedTreatmentPlanView);
    const loading = useSelector(selectSignedTreatmentPlanIsLoading);
    const hasError = useSelector(selectSignedTreatmentPlanViewHasError);

    const prepaymentTotal = useSelector(selectSignedTreatmentPlanViewPhasePrepaymentTotal);

    if (hasError && !loading)
        return <MessageBar messageBarType={MessageBarType.error}>Something went wrong while fething data.</MessageBar>;
    if (!loading && !view)
        return <MessageBar>There are no signed treatment plans with pending procedures for this patient.</MessageBar>;

    return (
        <ExpandableDetailsList<IMostRecentTreatmentPlanView>
            columns={[
                {
                    key: 'treatmentPlanName',
                    minWidth: 100,
                    name: 'Treatment Plan',
                    fieldName: 'displayName',
                },
                {
                    key: 'totalPrepayments',
                    minWidth: 150,
                    maxWidth: 180,
                    name: 'Total TP Prepayments',
                    onRender: () => {
                        return usdCurrencyFormatter.format(prepaymentTotal);
                    },
                },
            ]}
            compact
            onRenderExpandedRowContent={(rowProps) => {
                if (rowProps) {
                    return <TreatmentPlanPhaseViewList phases={rowProps.item.phases} />;
                }
                return null;
            }}
            enableShimmer={loading}
            firstRowExpanded
            items={[view]}
            selectionMode={SelectionMode.none}
            canExpandAll
            showGrid
        />
    );
}
