import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import IChartNote, { ClinicalNote, IChartNoteWithData, IClinicalNoteData, NoteType } from 'api/models/chart-note.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { fetchPatient } from '../patient/patient.actions';
import { getChartNoteByEncounterId, getChartNoteById, getChartNotes, upsertChartNote } from './chart-notes.actions';
import { IChartNotesState } from './chart-notes.state';

export const chartNotesReducers = {
    setClincalNoteFilter: (
        state: IChartNotesState,
        action: PayloadAction<{ path: 'filterToothId' | 'filterCreatedDate'; value: string | number }>,
    ): void => {
        const { path, value } = action.payload;
        if (state) state[path] = value as any;
    },
    setClinicalNote: (state: IChartNotesState, action: PayloadAction<ClinicalNote | undefined>): void => {
        state.currentClinicalNote = action.payload;
    },
    setClincalNoteData: (state: IChartNotesState, action: PayloadAction<{ path: keyof IChartNote; value: any }>): void => {
        const { path, value } = action.payload;
        if (state.currentClinicalNote) {
            (state.currentClinicalNote[path] as any) = value;
            state.modifiedClincalNote = true;
        }
    },
    // addClinicalNoteAttestSignature: (state: IChartNotesState, action: PayloadAction<AttestSignature>): void => {
    //     if (state.currentClinicalNote) {
    //         state.currentClinicalNote.signatures = [...(state.currentClinicalNote.signatures ?? []), action.payload];
    //         state.modifiedClincalNote = true;
    //     }
    // },
    setPartialClinicalNote: (state: IChartNotesState, action: PayloadAction<Partial<ClinicalNote> | undefined>): void => {
        const clinicalNoteUpdate = action.payload;
        if (!clinicalNoteUpdate || !state.currentClinicalNote) return;

        state.currentClinicalNote = { ...state.currentClinicalNote, ...clinicalNoteUpdate };
    },
    setClincalNoteDataProp: (
        state: IChartNotesState,
        action: PayloadAction<{ path: string | number | symbol; value: any }>,
    ): void => {
        const { path, value } = action.payload;
        if (state.currentClinicalNote) {
            ((state.currentClinicalNote.data as any)[path] as any) = value;
            state.modifiedClincalNote = true;
        }
    },
};

export const chartNotesExtraReducers = (builder: ActionReducerMapBuilder<IChartNotesState>): void => {
    builder
        .addCase(getChartNotes.pending, (state) => {
            state.loading = LoadingStatus.Pending;
        })
        .addCase(getChartNotes.fulfilled, (state, action) => {
            state.data = action.payload;
            state.loading = LoadingStatus.Completed;
        })
        .addCase(getChartNotes.rejected, (state) => {
            state.loading = LoadingStatus.Failed;
        })

        .addCase(getChartNoteById.pending, (state) => {
            state.loadingCurrentNote = LoadingStatus.Pending;
        })
        .addCase(getChartNoteById.fulfilled, (state, action) => {
            if (action.payload.noteType === NoteType.ClinicalNote) {
                state.currentClinicalNote = action.payload as IChartNoteWithData<IClinicalNoteData>;
            }
            state.loadingCurrentNote = LoadingStatus.Completed;
        })
        .addCase(getChartNoteById.rejected, (state) => {
            state.loadingCurrentNote = LoadingStatus.Failed;
        })

        .addCase(getChartNoteByEncounterId.pending, (state) => {
            state.loadingCurrentNote = LoadingStatus.Pending;
        })
        .addCase(getChartNoteByEncounterId.fulfilled, (state, action) => {
            if (action.payload.noteType === NoteType.ClinicalNote) {
                state.currentClinicalNote = action.payload as IChartNoteWithData<IClinicalNoteData>;
            }
            state.loadingCurrentNote = LoadingStatus.Completed;
        })
        .addCase(getChartNoteByEncounterId.rejected, (state) => {
            state.loadingCurrentNote = LoadingStatus.Failed;
        })
        .addCase(upsertChartNote.pending, (state) => {
            state.saving = LoadingStatus.Pending;
        })
        .addCase(upsertChartNote.fulfilled, (state, action) => {
            state.saving = LoadingStatus.Completed;
            state.modifiedClincalNote = false;

            if (action.payload.noteType === NoteType.ClinicalNote && state.currentClinicalNote?.id === action.payload.id) {
                const currentNoteDataValue = state.currentClinicalNote.data.value;
                const incomingNoteData: IClinicalNoteData = (action.payload.data as IClinicalNoteData | undefined) ?? {};

                state.currentClinicalNote = {
                    ...action.payload,
                    data: {
                        ...incomingNoteData,
                        value: currentNoteDataValue,
                    },
                } as IChartNoteWithData<IClinicalNoteData>;
            }

            if (state.data) {
                const indexOfNote = state.data.findIndex((note) => note.id === action.payload.id);
                if (indexOfNote > -1) {
                    state.data[indexOfNote] = action.payload;
                } else {
                    if (state?.data?.length) {
                        state.data = [...state.data, action.payload];
                    } else {
                        state.data = [action.payload];
                    }
                }
            }
        })
        .addCase(upsertChartNote.rejected, (state) => {
            state.saving = LoadingStatus.Failed;
        })
        .addCase(fetchPatient.pending, (state) => {
            state.currentClinicalNote = undefined;
        });
};
