import { createAsyncThunk } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import { IPatientLedger } from 'api/models/patient-ledger.model';

export const getPatientLedger = createAsyncThunk<
    IPatientLedger,
    {
        tenantId: string;
        patientId: string;
    },
    { rejectValue: string }
>('getPatientLedger', async ({ tenantId, patientId }, { rejectWithValue }) => {
    const response = await dentalApi.getPatientLedger(tenantId, patientId);
    return response.data;
});

export const addPatientLedger = createAsyncThunk<
    IPatientLedger,
    {
        tenantId: string;
        model: IPatientLedger;
    },
    { rejectValue: string }
>('addPatientLedger', async ({ tenantId, model }, { rejectWithValue }) => {
    const response = await dentalApi.createPatientLedger(tenantId, model);
    return response.data;
});

export const updatePatientLedger = createAsyncThunk<
    IPatientLedger,
    {
        tenantId: string;
        model: IPatientLedger;
    },
    { rejectValue: string }
>('updatePatientLedger', async ({ tenantId, model }, { rejectWithValue }) => {
    const response = await dentalApi.updatePatientLedger(tenantId, model);
    return response.data;
});
