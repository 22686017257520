import { IComboBoxOption, IDropdownOption } from '@fluentui/react';
import { createAsyncThunk, createSelector, createSlice, Dictionary } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import ILanguage from 'api/models/languages.model';
import IProvider from 'api/models/provider.model';
import { AxiosError, AxiosResponse } from 'axios';
import { LoadingStatus, LoadingStatuses } from 'interfaces/loading-statuses';
import { LookupState } from 'interfaces/lookup-state';
import { map, orderBy, uniqBy } from 'lodash';
import { RootState } from 'state/store';

const initialState: LanguagesState = {
    initialLoad: LoadingStatus.Idle,
    loading: LoadingStatus.Idle,
    data: {},
};

// export const getLanguages = createAsyncThunk<ILanguages>('getLanguages', async () => {
//     const res = await dentalApi.getLanguages();
//     return res;
// });

export const getLanguages = createAsyncThunk<AxiosResponse<Dictionary<ILanguage>>>('getLanguages', async () => {
    const res = await dentalApi.getLanguages();
    return res;
});

const languagesSlice = createSlice({
    name: 'languages',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getLanguages.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(getLanguages.fulfilled, (state, action) => {
                state.loading = LoadingStatus.Completed;
                state.data = action.payload.data;
            })
            .addCase(getLanguages.rejected, (state, action) => {
                state.loading = LoadingStatus.Failed;
            });
    },
});

const { reducer } = languagesSlice;

export const selectLanguages = (state: RootState): Dictionary<ILanguage> => state.language.data;

export const languagesData = createSelector(selectLanguages, (state) =>
    state ? (map(state, (dx) => dx) as ILanguage[]).filter((dx) => !dx.isDeleted).filter((dx) => dx.displayName) : [],
);

export const languagesCategories = createSelector(
    languagesData,
    (language) =>
        language.map((dx) => ({
            key: dx?.code ?? '',
            text: dx?.displayName ?? '',
        })) as IComboBoxOption[],
);

type LanguagesState = {
    initialLoad: LoadingStatuses;
    loading: LoadingStatuses;
    data: Dictionary<ILanguage>;
};

export default reducer;
