import { IComboBoxOption } from '@fluentui/react';
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import LookupBase from 'api/models/lookup-base.model';

import { AxiosError } from 'axios';
import { LoadingStatus } from 'interfaces/loading-statuses';

import { orderBy } from 'lodash';
import { RootState } from 'state/store';

export interface IDosageFrequency extends LookupBase {
    isActive: boolean;
    order: number;
    references?: Record<string, string>;
}

type DosageFrequencyState = {
    initialLoad: LoadingStatus;
    loading: LoadingStatus;
    data?: Record<string, IDosageFrequency>;
    errors?: string;
};

const initialState: DosageFrequencyState = {
    initialLoad: LoadingStatus.Idle,
    loading: LoadingStatus.Idle,
    data: {},
};

export const getDosageFrequency = createAsyncThunk<
    Record<string, IDosageFrequency>,
    void,
    {
        rejectValue: AxiosError;
    }
>('getDosageFrequency', async () => {
    const res = await dentalApi.getDosageFrequency();
    return res.data;
});

const dosageFrequencySlice = createSlice({
    name: 'dosageFrequency',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDosageFrequency.pending, (state) => {
                state.initialLoad = LoadingStatus.Pending;
            })
            .addCase(getDosageFrequency.fulfilled, (state, action) => {
                state.initialLoad = LoadingStatus.Completed;
                state.data = action.payload;
            })
            .addCase(getDosageFrequency.rejected, (state, action) => {
                state.initialLoad = LoadingStatus.Failed;
                state.errors = action.payload?.message;
            });
    },
});

const { reducer } = dosageFrequencySlice;

export const selectDosageFrequencyObject = (state: RootState): Record<string, IDosageFrequency> | undefined =>
    state.tenant.dosageFrequency.data;
export const selectDosageFrequencyData = createSelector(selectDosageFrequencyObject, (data) => (data ? data : {}));

export const selectDosageFrequency = createSelector(selectDosageFrequencyData, (lookups) => {
    return lookups;
});

export const dosageFrequencyList = createSelector(selectDosageFrequency, (lookups) => {
    if (lookups) {
        const list = Object.keys(lookups).map((key) => lookups[key]);
        return list.filter((lookup) => !lookup?.isDeleted).filter((lookup) => lookup?.isActive) as LookupBase[];
    }
    return [];
});

export const dosageFrequencyOptionsList = createSelector(
    dosageFrequencyList,
    (lookupsList) =>
        orderBy(lookupsList, 'order').map((dx) => ({
            key: dx?.id ?? '',
            text: dx?.displayName ? `${dx.displayName.length > 35 ? `${dx.displayName.slice(0, 35)}...` : dx.displayName}` : '',
            title: `${dx.displayName}`,
        })) as IComboBoxOption[],
);

export default reducer;
