import { ChartProcedurePreAuth, ChartProcedureStatus, IChartProcedure } from 'api/models/chart.model';
import useEncounterId from 'hooks/useEncounterId';
import useSelector from 'hooks/useSelector';
import { RouteParams } from 'interfaces/route-params';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { canSetProceduresCompleted, getSelectedChartActions } from 'state/slices/charting/chart/chart.selectors';
import { IChartAction } from 'state/slices/charting/chartActionsList.pipeline';
import {
    addProcedures,
    createChartProcedure,
    createChartProcedures,
    deleteChartProcedures,
    getChartProcedureById,
    getChartProcedures,
    IAddProceduresParams,
    updateChartProcedure,
    updateChartProcedures,
    updateProcedurePreAuths,
    updateProcedureStatuses,
} from 'state/slices/charting/procedures/procedures.actions';
import { chartProceduresData, selectChartProcedures } from 'state/slices/charting/procedures/procedures.selectors';

function useChartProcedures() {
    const { tenantId, patientId } = useParams<RouteParams>();
    const encounterId = useEncounterId();

    const _dispatch = useDispatch();

    //Actions
    const _getChartProcedures = useCallback(() => {
        _dispatch(getChartProcedures({ tenantId, patientId }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_dispatch, tenantId, patientId]);

    const _getChartProcedureById = useCallback(
        (procedureId: string) => {
            _dispatch(getChartProcedureById({ tenantId, patientId, procedureId }));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [_dispatch, tenantId, patientId],
    );

    const _updateChartProcedure = useCallback(
        (procedure: IChartProcedure) => {
            _dispatch(updateChartProcedure({ tenantId, patientId, procedure }));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [_dispatch, tenantId, patientId],
    );

    const _updateChartProcedures = useCallback(
        (procedures: IChartProcedure[]) => {
            _dispatch(updateChartProcedures({ tenantId, patientId, procedures }));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [_dispatch, tenantId, patientId],
    );

    const _createChartProcedure = useCallback(
        (procedure: IChartProcedure) => {
            _dispatch(createChartProcedure({ tenantId, patientId, procedure }));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [_dispatch, tenantId, patientId],
    );

    const _createChartProcedures = useCallback(
        (procedures: IChartProcedure[]) => {
            _dispatch(createChartProcedures({ tenantId, patientId, procedures }));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [_dispatch, tenantId, patientId],
    );

    const _addProcedures = useCallback(
        ({
            procedureIds,
            toothAreas,
            addAutoApplyDiagnoses,
            elementId,
        }: Omit<IAddProceduresParams, 'tenantId' | 'patientId'>) => {
            _dispatch(
                addProcedures({ tenantId, patientId, procedureIds, toothAreas, addAutoApplyDiagnoses, encounterId, elementId }),
            );
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [_dispatch, tenantId, patientId, encounterId],
    );

    const _deleteChartProcedures = useCallback(
        (procedures: IChartProcedure[]) => {
            _dispatch(deleteChartProcedures(tenantId, patientId, procedures));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [_dispatch, tenantId, patientId],
    );

    const _updateProcedureStatuses = useCallback(
        (chartActions: IChartAction | IChartAction[], status: ChartProcedureStatus, updateTreatmentPlanStatuses?: boolean) => {
            _dispatch(
                updateProcedureStatuses(tenantId, patientId, chartActions, status, encounterId, updateTreatmentPlanStatuses),
            );
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [_dispatch, tenantId, patientId, encounterId],
    );

    const _updateProcedurePreAuths = useCallback(
        (chartActions: IChartAction | IChartAction[], preAuth: ChartProcedurePreAuth) => {
            _dispatch(updateProcedurePreAuths(tenantId, patientId, chartActions, preAuth));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [_dispatch, tenantId, patientId],
    );

    //State
    const { loading, saving, data: _allChartProcedures } = useSelector(selectChartProcedures);
    const _getSelectedChartActions = useSelector(getSelectedChartActions);
    const _chartProceduresList = useSelector(chartProceduresData);
    const _canSetProceduresCompleted = useSelector(canSetProceduresCompleted);

    return {
        // Actions
        getChartProcedures: _getChartProcedures,
        updateChartProcedure: _updateChartProcedure,
        updateChartProcedures: _updateChartProcedures,
        getChartProcedureById: _getChartProcedureById,
        createChartProcedure: _createChartProcedure,
        createChartProcedures: _createChartProcedures,
        deleteChartProcedures: _deleteChartProcedures,
        addProcedures: _addProcedures,
        updateProcedureStatuses: _updateProcedureStatuses,
        updateProcedurePreAuths: _updateProcedurePreAuths,

        // State
        loading,
        saving,
        allChartProcedures: _allChartProcedures,
        chartProceduresList: _chartProceduresList,
        getSelectedChartActions: _getSelectedChartActions,
        canSetProceduresCompleted: _canSetProceduresCompleted,
    };
}

export default useChartProcedures;
