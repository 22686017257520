import IPatient from 'api/models/patient.model';
import IUserTask from 'api/models/user-task.model';
import { LoadingStatuses, LoadingStatus } from 'interfaces/loading-statuses';
import { IWorkListsMenu } from 'api/models/worklist-menu.model';
import { MessageBarType } from '@fluentui/react';

export type AdminHuddleState = {
    beacon?: unknown;
    adminHuddleTasks: {
        data: IUserTask[];
        loading: LoadingStatuses;
    };

    encounterPatient: {
        data?: IPatient;
        loading?: LoadingStatuses;
    };
    visitSummary: {
        isPanelOpen: boolean;
        amendmentId?: string;
        amendmentNote?: string;
        amendmentType?: string;
        amendmentTreatingProvider?:  string;
        savingAmendment?: LoadingStatus;

        savingBillingProvider?: LoadingStatus;

        messageBarMessage?: string;
        messageBarType?: MessageBarType;
    };
    menu: {
        workLists: {
            data?: IWorkListsMenu;
            loading: LoadingStatuses;
        };
    };
};

export const initialState: AdminHuddleState = {
    adminHuddleTasks: {
        data: [],
        loading: LoadingStatus.Idle,
    },
    encounterPatient: {
        data: undefined,
        loading: LoadingStatus.Idle,
    },
    visitSummary: {
        isPanelOpen: false,
    },
    menu: {
        workLists: {
            loading: LoadingStatus.Idle,
        },
    },
};
