import { ITooth } from 'api/models/tooth.model';
import ToothSprite from './ToothSprite';
import Root from './Root';
import Tooth from './Tooth';
import { ToothAreaGroup } from './ToothAreaGroup';
import Crown from './Crown';

/**
 * Container instance of the front of a tooth
 *
 * @class Front
 * @extends {PIXI.Container}
 */
class Front extends ToothAreaGroup {
    public _crownSprite: ToothSprite;
    public _rootSprite: ToothSprite;

    constructor(tooth: Tooth) {
        super(tooth, 'front');

        this.sortableChildren = true;
        this._crownSprite = this._createCrownSprite();
        this._rootSprite = this._createRootSprite();

        this.addChild(this._crownSprite, this._rootSprite);

        this.y = !this.getIsBottomRow ? -350 : 450;

        this.pivot.x = this._rootSprite.width / 2;
        this.pivot.y = this._rootSprite.height / 2;
        this.position.x = this._rootSprite.width / 2;

        this.setRotation(tooth.data.rotation);
    }

    /**
     * Create individual root sprites
     *
     * @private
     * @returns {ToothSprite[]}
     * @memberof Front
     */
    private _createRootSprite(): ToothSprite {
        const root = new Root(this._tooth);
        return root;
    }

    /**
     * Create individual crown sprite
     *
     * @private
     * @param {string} _crownSpriteName
     * @returns {ToothSprite}
     * @memberof Front
     */
    private _createCrownSprite(): ToothSprite {
        const sprite = new Crown(this._tooth);
        return sprite;
    }
}

export default Front;
