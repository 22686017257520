import { Stack, Text } from '@fluentui/react';
import { IBillingProcedure } from 'api/models/billing-procedure.model';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import currencyFormatter from 'utils/currencyFormatter';

const insuranceFee: ISortableColumn<IBillingProcedure> = {
    key: 'insuranceFee',
    name: 'Insurance Est.',
    fieldName: 'insuranceFee',
    minWidth: 100,
    maxWidth: 200,
    onRender: (item) => {
        return (
            <Stack grow>
                <Text variant="smallPlus">{currencyFormatter.format(item?.insuranceFee ?? 0)}</Text>
            </Stack>
        );
    },
};

export default insuranceFee;
