import { Stack, IconButton, IButtonStyles, Text } from '@fluentui/react';
import { Section } from 'components';
import { useDispatch } from 'react-redux';
import { setPatientMedicationsPanelOpen } from 'state/slices/patient/patient.slice';
import MedicationsDetailsList from './MedicationsDetailsList';
import MedicationsPanel from './MedicationsPanel';
import MedicationsReviewed from './MedicationsReviewed';
import { ToggleMedicationHistory } from './ToggleMedicationHistory';
import MedicationStopModal from './MediationStopModal';
import { SignalRMessage, useSignalR } from 'hooks/signalr/useSignalr';
import { useEffect } from 'react';
import { insertMedications } from 'state/slices/patient/medications/medications.actions';

function Medications(): JSX.Element {
    const dispatch = useDispatch();

    const { registerSignalRMessage } = useSignalR();

    useEffect(() => {
        registerSignalRMessage(SignalRMessage.UpdatedMedication, insertMedications);
    }, []);

    const menuButtonStyles: IButtonStyles = {
        root: {
            height: 25,
            maxWidth: 25,
        },
        flexContainer: {
            selectors: {
                '.ms-Button-menuIcon': {
                    display: 'none',
                },
            },
        },
    };

    const handleAddMedications = () => {
        dispatch(setPatientMedicationsPanelOpen(true));
    };

    return (
        <Section
            heading={
                <Stack tokens={{ childrenGap: 10 }} horizontal verticalAlign="center" grow>
                    <Text variant="xLarge">Medications</Text>

                    <IconButton styles={menuButtonStyles} iconProps={{ iconName: 'Add' }} onClick={handleAddMedications} />
                </Stack>
            }
            headingRightContent={
                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }}>
                    <ToggleMedicationHistory />
                    <MedicationsReviewed />
                </Stack>
            }
        >
            <MedicationsPanel />
            <MedicationsDetailsList />
            <MedicationStopModal />
        </Section>
    );
}

export default Medications;
