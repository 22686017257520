import { Stack } from '@fluentui/react';
import Questionnaire from './Questionnaire';

export default function PMHEditProperties() {
    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <Questionnaire />
        </Stack>
    );
}
