import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { IChartCondition } from 'api/models/chart.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { ChartingState } from '../chart/chart.slice';
import {
    createChartCondition,
    createChartConditions,
    getChartConditionById,
    getChartConditions,
    updateChartCondition,
    updateChartConditions,
} from './conditions.actions';

export const conditionsExtraReducers = (builder: ActionReducerMapBuilder<ChartingState>) =>
    builder
        // [GET] Chart Conditions
        .addCase(getChartConditions.pending, (state) => {
            state['conditions'].loading = LoadingStatus.Pending;
        })
        .addCase(getChartConditions.fulfilled, (state, action) => {
            state.conditions.loading = LoadingStatus.Completed;
            state.conditions.data = action.payload;
        })
        // .addCase(getChartConditions.rejected, (state) => {})

        // [GET] Chart Condition by Id
        .addCase(getChartConditionById.pending, (state) => {
            state.conditions.loading = LoadingStatus.Pending;
        })
        // .addCase(getChartConditionById.fulfilled, (state) => {})
        // .addCase(getChartConditionById.rejected, (state) => {})

        // [POST] Chart Condition
        .addCase(createChartCondition.pending, (state) => {
            state.conditions.saving = LoadingStatus.Pending;
        })
        .addCase(createChartCondition.fulfilled, (state, action) => {
            state.conditions.saving = LoadingStatus.Completed;
            state.conditions.data = state.conditions.data ? [...state.conditions.data, action.payload] : [action.payload];
        })
        .addCase(createChartCondition.rejected, (state, action) => {
            state.conditions.saving = LoadingStatus.Failed;
            state.conditions.errors = action.payload;
        })
        .addCase(createChartConditions.pending, (state) => {
            state.conditions.saving = LoadingStatus.Pending;
        })
        .addCase(createChartConditions.fulfilled, (state, action) => {
            state.conditions.saving = LoadingStatus.Completed;
            state.conditions.data = state.conditions.data ? [...state.conditions.data, ...action.payload] : [...action.payload];
        })
        .addCase(createChartConditions.rejected, (state, action) => {
            state.conditions.saving = LoadingStatus.Failed;
            state.conditions.errors = action.payload;
        })
        // [PUT] Chart Condition
        .addCase(updateChartCondition.pending, (state) => {
            state.conditions.saving = LoadingStatus.Pending;
        })
        .addCase(updateChartCondition.fulfilled, (state, action) => {
            const conditionIndex = state.conditions.data.findIndex((p) => p.id === action.payload.id);
            if (conditionIndex > -1) state.conditions.data[conditionIndex] = action.payload;
            state.conditions.saving = LoadingStatus.Completed;
        })
        .addCase(updateChartCondition.rejected, (state, action) => {
            state.conditions.errors = action.payload;
            state.conditions.saving = LoadingStatus.Failed;
        })
        .addCase(updateChartConditions.pending, (state) => {
            state.conditions.saving = LoadingStatus.Pending;
        })
        .addCase(updateChartConditions.fulfilled, (state, action: PayloadAction<IChartCondition[]>) => {
            action.payload.forEach((item) => {
                const conditionIndex = state.conditions.data.findIndex((p) => p.id === item.id);
                if (conditionIndex > -1) state.conditions.data[conditionIndex] = item;
            });
            state.selectedChartActions = undefined;
            state.conditions.saving = LoadingStatus.Completed;
        })
        .addCase(updateChartConditions.rejected, (state, action: PayloadAction<any>) => {
            state.conditions.errors = action.payload;
            state.conditions.saving = LoadingStatus.Failed;
        });
