import { IEncounterSummary, ProcedureSummaryWithEncounterIdAndTransactionId } from 'api/models/encounter-ledger.model';
import { isEmpty } from 'lodash';
import { FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updatePatientAdjustmentTransaction } from 'state/slices/ledger/ledger.slice';
import {
    selectPatientAdjustmentTransactions,
    selectPatientOverpaymentOrOverAdjustment,
    selectPatientPaymentTransactions,
    selectPaymentAndAdjustmentAmounts,
} from 'state/slices/ledger/patient-payments-and-adjustments/patient-payments-and-adjustments.selectors';
import BaseTransactionField from '../components/BaseTransactionField';
import { getMaxPayment, getTransactionAmount } from '../LedgerUtils';

type Props = {
    encounterSummary: IEncounterSummary | undefined;
    procedureSummary: ProcedureSummaryWithEncounterIdAndTransactionId | undefined;
};

export default function PaymentTransactionField({ encounterSummary, procedureSummary }: Props) {
    const dispatch = useDispatch();

    const paymentTransactions = useSelector(selectPatientPaymentTransactions);
    const adjustmentTransactions = useSelector(selectPatientAdjustmentTransactions);

    const overadjustment = useSelector(selectPatientOverpaymentOrOverAdjustment);

    const { adjustmentAmount, remainingAdjustmentAmount } = useSelector(selectPaymentAndAdjustmentAmounts);

    const encounterId = encounterSummary?.encounterId;
    const procedureTransactionId = procedureSummary?.patientTransactionId;
    const adjustmentTransactionId = procedureSummary?.adjustmentTransactionId;

    const adjustmentTransaction =
        adjustmentTransactionId && encounterId && !isEmpty(adjustmentTransactions)
            ? adjustmentTransactions[encounterId][adjustmentTransactionId]
            : undefined;
    const paymentTransaction =
        procedureTransactionId && encounterId && !isEmpty(paymentTransactions)
            ? paymentTransactions[encounterId][procedureTransactionId]
            : undefined;

    const _onSetMaxAmount = () => {
        if (adjustmentTransaction && procedureSummary && encounterId !== undefined) {
            const paymentAmount = paymentTransaction?.amount ?? 0;
            const newFee = procedureSummary.commonPatientFee - paymentAmount;
            const nonNegativeFee = newFee < 0 ? 0 : newFee;
            dispatch(
                updatePatientAdjustmentTransaction({
                    encounterId,
                    transactionId: adjustmentTransaction.id,
                    amount: getTransactionAmount({
                        input: procedureSummary.commonPatientFee,
                        patientFee: nonNegativeFee,
                        remainingPaymentAmount: remainingAdjustmentAmount,
                        transactionAmount: adjustmentTransaction.amount,
                    }),
                }),
            );
        }
    };

    const _onChange = (e?: FormEvent, input?: number | undefined) => {
        if (adjustmentTransaction && procedureSummary && encounterId !== undefined) {
            const inputValue = input ?? 0;
            const paymentAmount = paymentTransaction?.amount ?? 0;
            const newFee = procedureSummary.commonPatientFee - paymentAmount;
            const nonNegativeFee = newFee < 0 ? 0 : newFee;
            dispatch(
                updatePatientAdjustmentTransaction({
                    encounterId,
                    transactionId: adjustmentTransaction.id,
                    amount: overadjustment
                        ? inputValue
                        : getTransactionAmount({
                              input,
                              patientFee: nonNegativeFee,
                              remainingPaymentAmount: remainingAdjustmentAmount,
                              transactionAmount: adjustmentTransaction.amount,
                          }),
                }),
            );
        }
    };

    const disabled = !adjustmentAmount && !adjustmentTransaction?.amount;

    return (
        <BaseTransactionField
            disabled={disabled}
            max={getMaxPayment({ overpayment: overadjustment, paymentAmount: procedureSummary?.commonPatientFee })}
            transactionAmount={adjustmentTransaction?.amount}
            onChange={_onChange}
            maxAmountButtonDisabled={overadjustment}
        />
    );
}
