import { PayloadAction } from '@reduxjs/toolkit';
import { SlidingFeeEncounterDetailsQuery } from 'api/models/sliding-fee-encounter-details-report.model';
import { isArray } from 'lodash';
import { IReportingState } from '../reporting.state';
import { IDentalSealantsDetailQuery, IDentalSealantsSummaryQuery } from 'api/models/dental-sealants-report.model';

const dentalSealantReducers = {
    setDentalSealantDetailsProp: (
        state: IReportingState,
        action: PayloadAction<{ path: keyof IDentalSealantsDetailQuery; value: string | object | undefined }>,
    ): void => {
        const { path, value } = action.payload;
        ((state.reportProperties as IDentalSealantsDetailQuery)[path] as string | object | undefined) = value;
    },
    updateDentalSealantDetailQueryProp: (
        state: IReportingState,
        action: PayloadAction<{ path: keyof IDentalSealantsDetailQuery; id: string }>,
    ): void => {
        const { id, path } = action.payload;
        if (!(state.reportProperties as IDentalSealantsDetailQuery)[path])
            ((state.reportProperties as IDentalSealantsDetailQuery)[path] as unknown[]) = [];

        const currentIds = (state.reportProperties as IDentalSealantsDetailQuery)[path] as string[];

        if (currentIds)
            if (currentIds?.includes(id)) {
                ((state.reportProperties as IDentalSealantsDetailQuery)[path] as string[]) = currentIds.filter(
                    (_id) => _id !== id,
                );
            } else {
                ((state.reportProperties as IDentalSealantsDetailQuery)[path] as string[]) = [...currentIds, id];
            }
    },
    setDentalSealantSummaryProp: (
        state: IReportingState,
        action: PayloadAction<{ path: keyof IDentalSealantsSummaryQuery; value: string | object | undefined }>,
    ): void => {
        const { path, value } = action.payload;
        ((state.reportProperties as IDentalSealantsSummaryQuery)[path] as string | object | undefined) = value;
    },
    updateDentalSealantSummaryQueryProp: (
        state: IReportingState,
        action: PayloadAction<{ path: keyof IDentalSealantsSummaryQuery; id: string }>,
    ): void => {
        const { id, path } = action.payload;
        if (!(state.reportProperties as IDentalSealantsSummaryQuery)[path])
            ((state.reportProperties as IDentalSealantsSummaryQuery)[path] as unknown[]) = [];

        const currentIds = (state.reportProperties as IDentalSealantsSummaryQuery)[path] as string[];

        if (currentIds)
            if (currentIds?.includes(id)) {
                ((state.reportProperties as IDentalSealantsSummaryQuery)[path] as string[]) = currentIds.filter(
                    (_id) => _id !== id,
                );
            } else {
                ((state.reportProperties as IDentalSealantsSummaryQuery)[path] as string[]) = [...currentIds, id];
            }
    },
};

export default dentalSealantReducers;
