import { createSelector } from 'reselect';
import { RootState } from 'state/store';
import { ChartProceduresState } from './procedures.state';
import { ChartProcedureStatus, IChartProcedure } from 'api/models/chart.model';

export const selectChartProcedures = (state: RootState): ChartProceduresState => state.charting.procedures;

export function getIsActiveChartProcedure(chartProcedure: IChartProcedure) {
    return !chartProcedure.isDeleted && chartProcedure.status !== ChartProcedureStatus.Void;
}

export const chartProceduresData = createSelector(selectChartProcedures, (procedures) =>
    procedures.data.filter(getIsActiveChartProcedure),
);
