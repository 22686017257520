import IWorkListAppointmentView from 'api/models/worklist-appointment-view';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';

const amendNotes: ISortableColumn<IWorkListAppointmentView> = {
    key: 'amendNotes',
    minWidth: 140,
    maxWidth: 140,
    isResizable: true,
    name: 'Amend Notes',
    fieldName: 'amendNotes',
    onRender: AmendNotesColumn,
};

function AmendNotesColumn(item?: IWorkListAppointmentView) {
    const amendNoteText = item?.amendNotes ?? '';

    return <span title={amendNoteText}>{amendNoteText}</span>;
}

export default amendNotes;
