import { IContextualMenuItem, Stack } from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import dentalApi from 'api/dental.api';
import { Field } from 'components';
import { IAsyncSearchComboField } from 'components/Field/TypeaheadSearchField';
import { useState } from 'react';
import { Dictionary, sortBy, map } from 'lodash';
import { useSelector } from 'hooks';
import IMedication from 'api/models/medications.model';
import { selectPatientMedications, selectPatientMedicationsToAdd } from 'state/slices/patient/medications/medications.selectors';

function MedicationDropdown(
    props: Partial<IAsyncSearchComboField> & { addMedication: (item?: IMedication) => void },
): JSX.Element {
    const medicationsToAdd = useSelector(selectPatientMedicationsToAdd);
    const patientMedications = useSelector(selectPatientMedications);

    const [searchQuery, setSearchQuery] = useState<string>('');
    const [results, setResults] = useState<Dictionary<IMedication | undefined>>({});
    const [loading, { setFalse, setTrue }] = useBoolean(false);

    // Return a list excluding already added or about to be added allergies (PatientAllergies || allergiesToAdd)
    const menuItems = (): IContextualMenuItem[] => {
        const medicationList = (map(results) as IMedication[]) ?? [];

        if (!medicationList.length) return [];

        const medications = patientMedications?.medications;
        const options = medicationList.filter(notInMedicationsToAdd).filter(notInPatientMedications).map(mapMedicationOptions);

        return sortBy(options, ['text'], ['desc']);

        function mapMedicationOptions(medication: IMedication): IContextualMenuItem {
            return {
                key: medication.id,
                text: medication.displayName,
                onClick: () => {
                    props.addMedication(medication);
                    setSearchQuery('');
                    setResults({});
                },
            };
        }

        function notInMedicationsToAdd(medication: IMedication) {
            if (medicationsToAdd.length) {
                const medicationToAddExists = medicationsToAdd?.find(
                    (chartMedication) => chartMedication.medicationId === medication.id,
                );
                return medicationToAddExists ? false : true;
            } else {
                return true;
            }
        }
        function notInPatientMedications(medication: IMedication) {
            if (medications?.length) {
                const medicationExists = medications.find(
                    (chartMedication) =>
                        chartMedication.medicationId === medication.references?.athenaOne || chartMedication.id === medication.id,
                );
                return medicationExists ? false : true;
            } else {
                return true;
            }
        }
    };

    const _onSearch = async () => {
        if (searchQuery.length > 1) {
            setTrue();
            const results = await searchMedications(searchQuery);
            if (results) setResults(results);
            setFalse();
        }
    };

    return (
        <Stack style={{ position: 'relative' }}>
            <Field.TypeaheadSearch
                {...props}
                placeholder="Search medications..."
                menuItems={menuItems()}
                onSearch={_onSearch}
                value={searchQuery}
                onChange={(ev, value) => setSearchQuery(value ?? '')}
                canClear
                onClear={() => setResults({})}
                autoComplete="off"
                loading={loading}
                autoSearch
                autoSearchDelay={1000}
                noItemsFoundMessage="No matching medications found."
            />
        </Stack>
    );
}

async function searchMedications(query: string) {
    const { data: medications } = await dentalApi.searchMedications(query);

    return medications;
}

export default MedicationDropdown;
