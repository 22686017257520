import IPatientVitals, { VitalType, IVitalReading, VitalNameType } from 'api/models/patient-vital.model';
import { LoadingStatuses } from 'interfaces/loading-statuses';
import { groupBy, map, orderBy, sortBy } from 'lodash';
import { createSelector } from 'reselect';
import { selectPatientEncounter } from 'state/slices/encounter/encounter.selectors';
import { RootState } from 'state/store';

export const selectVitalsLoading = (state: RootState): LoadingStatuses => state.patient.vitals.loading;
export const selectVitalsPanelOpen = (state: RootState): boolean => state.patient.vitals.vitalPanelOpen;
export const selectVitalsSaving = (state: RootState): LoadingStatuses => state.patient.vitals.saving;
export const selectVitals = (state: RootState): IPatientVitals | undefined => state.patient.vitals.data;
export const selectVitalsReadings = createSelector(selectVitals, (vital) => (vital ? vital.readings : []));

type MappedVitalSupports = Record<VitalType, string>;

export interface MappedVital extends MappedVitalSupports {
    date: string;
    encounterId?: string;
    data: IVitalReading[];
    appointmentStartTime?: string;
}

/**
 * selectPatientVitalTableData
 * @description Creates a viewmodel from the readings based on date, that's easier to display.
 */
export const selectPatientVitalTableData = createSelector(selectVitalsReadings, (readings) => {
    const readingsWithEncounterId = readings?.filter((reading) => reading.encounterId) ?? [];
    const readingsWithSourceEncounterId = readings?.filter((reading) => reading?.sourceEncounterId) ?? [];

    const byEncounterId = groupBy(readingsWithEncounterId, 'encounterId');
    const bySourceEncounterId = groupBy(readingsWithSourceEncounterId, 'sourceEncounterId');

    const groupedVitals = { ...byEncounterId, ...bySourceEncounterId };
    const mappedVitalsData = orderBy(map(groupedVitals, mapReadingsToMappedVital), [(vital) => new Date(vital.date)], ['desc']);

    return mappedVitalsData;

    function mapReadingsToMappedVital(readings: IVitalReading[]): MappedVital {
        const mostRecentReading = sortBy(readings, 'readingTaken')[0];
        const readingWithEncounterId = readings.find((r) => r.encounterId !== undefined);
        const vitals: MappedVital = {
            date: mostRecentReading.readingTaken ?? '',
            '02Sat': getReadingByVitalType(readings, VitalNameType.Saturation, '%'),
            BP: getBPString(readings),
            Ht: getReadingByVitalType(readings, VitalNameType.Height),
            Pulse: getReadingByVitalType(readings, VitalNameType.PulseRate, ' bpm'),
            RR: getReadingByVitalType(readings, VitalNameType.RespirationRate),
            T: getReadingByVitalType(readings, VitalNameType.Temperature),
            Wt: getReadingByVitalType(readings, VitalNameType.Weight),
            encounterId: readingWithEncounterId?.encounterId ?? '',
            data: readings,
            appointmentStartTime: readingWithEncounterId?.appointmentStartTime,
        };
        return vitals;
    }
});

export function getBPString(readings: IVitalReading[]): string {
    const bloodPressureReadings = readings.filter(
        (reading) => reading.vitalName === VitalNameType.Diastolic || reading.vitalName === VitalNameType.Systolic,
    );
    const bpsByReadingId = groupBy(bloodPressureReadings, 'readingId');

    const groupedBloodPressureReadings = map(bpsByReadingId, (reading: IVitalReading[], key) => {
        const bloodPressureObj = bpsByReadingId[key];
        const diastolic = bloodPressureObj.find((r) => r.vitalName === VitalNameType.Diastolic);
        const systolic = bloodPressureObj.find((r) => r.vitalName === VitalNameType.Systolic);
        if (!systolic?.value && !diastolic?.value) return '';
        return `${systolic ? systolic.value : ''}/${diastolic ? diastolic.value : ''}`;
    });

    return groupedBloodPressureReadings.join(' | ');
}

export function getReadingByVitalType(readings: IVitalReading[], vitalName: VitalNameType, suffix?: string): string {
    const reading = readings.find((reading) => reading.vitalName === vitalName);
    const readingUnit = reading?.unit ? ` ${reading.unit}` : '';
    switch (vitalName) {
        case VitalNameType.Height: {
            const cm = readings.find((reading) => reading.vitalName === vitalName)?.value ?? '0';

            return toFeet(parseFloat(cm));
        }
        case VitalNameType.Weight: {
            const grams = readings.find((reading) => reading.vitalName === vitalName)?.value ?? '0';
            return grams !== '0' ? `${Math.round(parseFloat(grams) * 0.0022046)} lb` : '';
        }
        case VitalNameType.Temperature: {
            const temp = readings.find((reading) => reading.vitalName === vitalName)?.value ?? '0';
            return temp !== '0' ? `${parseFloat(temp).toFixed(1)} F` : '';
        }
        default: {
            return reading?.value !== undefined ? `${reading.value}${suffix ? suffix : readingUnit}` : '';
        }
    }
    function toFeet(cm: number) {
        const totalInches = cm * 0.3937;
        let inches = Math.round(totalInches % 12);
        let feet = Math.floor(totalInches / 12);
        if (inches === 12) {
            feet += 1;
            inches = 0;
        }
        return feet || inches ? feet + "'" + inches + '"' : '';
    }
}

export const selectHasVitalsData = (state: RootState): boolean => (state.patient.vitals.data ? true : false);

export const selectHasVitalsToUpdate = (state: RootState): boolean =>
    state.patient.vitals.vitalsToUpdate && state.patient.vitals.vitalsToUpdate.length ? true : false;

export const vitalsForEncounterAlreadyExist = createSelector(
    selectPatientEncounter,
    selectVitalsReadings,
    (encounter, readings) => {
        const vitalsExistForEncounter = readings?.some((reading) => reading.encounterId === encounter?.id);
        return vitalsExistForEncounter ?? false;
    },
);
