/* eslint-disable no-console */
import {
    HttpTransportType,
    HubConnection,
    HubConnectionBuilder,
    HubConnectionState,
    IHttpConnectionOptions,
    LogLevel,
} from '@microsoft/signalr';
import { getIdToken } from 'api/auth/authProvider';
import { useTenantId } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import isDev from 'utils/isDev';
import { AppThunk } from 'state/store';
import { AnyAction } from 'redux';
import { DentalApiConfig } from 'api/api.config';
import { useDispatch } from 'react-redux';
import { SignalRGroupSubscriptionService } from './signalRGroupSubscriptionService';

const hubConnectionString = isDev ? `${process.env.REACT_APP_AppApiUri}/notifications` : `#{AppApiUri}#/notifications`;
const options: IHttpConnectionOptions = {
    logMessageContent: isDev,
    logger: isDev ? LogLevel.Debug : LogLevel.Error,
    transport: HttpTransportType.WebSockets,
    accessTokenFactory: async () => await getIdToken(DentalApiConfig.scopes),
};
export const connection = new HubConnectionBuilder().withUrl(hubConnectionString, options).withAutomaticReconnect().build();
export const signalRGroupSubscriptionService = new SignalRGroupSubscriptionService();

export enum SignalRMessage {
    NewAppointment = 'NewAppointment',
    UpdatedPatientAppointmentAllocation = 'UpdatedPatientAppointmentAllocation',
    CancelledAppointment = 'CancelledAppointment',

    //Worklist messages:
    //Predeterminations
    DeletedPredeterminationProcedurePlanView = 'DeletedPredeterminationProcedurePlanView',
    UpdatedPredeterminationProcedurePlanView = 'UpdatedPredeterminationProcedurePlanView',

    //Missing slips
    UpdatedAmendRequiredEncounterView = 'UpdatedAmendRequiredEncounterView',
    DeletedAmendRequiredEncounterView = 'DeletedAmendRequiredEncounterView',
    // Awaiting sig/attestation
    UpdatedAwaitingAttestationAppointmentView = 'UpdatedAwaitingAttestationAppointmentView',
    DeletedAwaitingAttestationAppointmentView = 'DeletedAwaitingAttestationAppointmentView',

    // Outstanding checkouts
    UpdatedOutstandingCheckoutAppointmentView = 'UpdatedOutstandingCheckoutAppointmentView',
    DeletedOutstandingCheckoutAppointmentView = 'DeletedOutstandingCheckoutAppointmentView',

    // Outstanding checkin
    UpdatedOutstandingCheckInAppointmentView = 'UpdatedOutstandingCheckInAppointmentView',
    DeletedOutstandingCheckInAppointmentView = 'DeletedOutstandingCheckInAppointmentView',

    //Encounter worklists:
    // Open billed worklist (patient/insurance)
    UpdatedOpenBilledEncounterView = 'UpdatedOpenBilledEncounterView',
    DeletedOpenBilledEncounterView = 'DeletedOpenBilledEncounterView',

    //RTR worklist
    UpdatedReadyToReviewEncounterView = 'UpdatedReadyToReviewEncounterView',
    DeletedReadyToReviewEncounterView = 'DeletedReadyToReviewEncounterView',

    //RTRe Worklist
    UpdatedCorrectionsCompletedEncounterView = 'UpdatedCorrectionsCompletedEncounterView',
    DeletedCorrectionsCompletedEncounterView = 'DeletedCorrectionsCompletedEncounterView',

    //RTRe on hold worklist
    UpdatedReBillOnHoldEncounterView = 'UpdatedReBillOnHoldEncounterView',
    DeletedReBillOnHoldEncounterView = 'DeletedReBillOnHoldEncounterView',

    //Edit encounters worklist
    UpdatedCorrectionsNeededEncounterView = 'UpdatedCorrectionsNeededEncounterView',
    DeletedCorrectionsNeededEncounterView = 'DeletedCorrectionsNeededEncounterView',

    //Edit encounters correction amend
    UpdatedCorrectionsAmendEncounterView = 'UpdatedCorrectionsAmendEncounterView',
    DeletedCorrectionsAmendEncounterView = 'DeletedCorrectionsAmendEncounterView',

    UpdatedEncounterCorrespondence = 'UpdatedEncounterCorrespondence',

    //Encounter Messages
    UpdatedEncounter = 'UpdatedEncounter',
    UpdatedEncounterStatus = 'UpdatedEncounterStatus',

    NewPatientSourceId = 'NewPatientSourceId',
    UpdatedAudit = 'UpdatedAudit',
    UpdatedMedication = 'UpdatedMedication',
    LedgerCalculationUpdate = 'LedgerCalculationUpdate',
    UpdatedClinicalAlert = 'UpdatedClinicalAlert',
    ClosedBatch = 'ClosedBatch',
    HardCloseBatch = 'HardClosedBatch',
    UpdatedPatientContact = 'UpdatedPatientContact',
    UpdatedChartTreatmentPlan = 'UpdatedChartTreatmentPlan',

    //Refund worklist messages
    UpdatedRefundView = 'UpdatedRefundView',
    DeletedRefundView = 'DeletedRefundView',
}

export type SignalRMessageAction = {
    message: SignalRMessage;
    action: SignalRAction;
};

export type SignalRAction = (data: any, args: any[]) => AnyAction | AppThunk<void> | void;

export type SignalRActionConfig = SignalRMessageAction[];

export type SignalROptions = {
    disableAutoMessageCleanup?: boolean;
};

export function useSignalR(
    options?: SignalROptions,
    _connection = connection,
    _signalrGroupService = signalRGroupSubscriptionService,
) {
    const dispatch = useDispatch();
    const tenantId = useTenantId();
    const [registeredMessages, setRegisteredMessages] = useState<string[]>([]);

    /**
     * Registers a new signalR action handler to the global connection hub.
     *
     * @param {string} signalRMessage
     * @param {((data: any) => AnyAction | AppThunk<void>)} action
     */
    const registerMessage = useCallback(
        (signalRMessage: string, action: SignalRAction) => {
            _connection.off(signalRMessage); // Ensuring only ever one handler
            _connection.on(signalRMessage, (data, ...args) => {
                dispatch(action(data, args)); //action is treated like a thunk.
            });
        },
        [_connection, dispatch],
    );

    const invokeMessage = useCallback(
        (signalRMessage: SignalRMessage, data: unknown) => {
            _connection.on(signalRMessage, (data) => {
                dispatch(data);
            });
            _connection.invoke(signalRMessage, data);
        },
        [_connection, dispatch],
    );

    //Handle disposing of only one message
    const disposeSignalRMessage = useCallback(
        (signalRMessage: SignalRMessage) => {
            _connection.off(signalRMessage);
            const indexOfMessage = registeredMessages.indexOf(signalRMessage);
            if (indexOfMessage > -1)
                setRegisteredMessages([
                    ...registeredMessages.slice(0, indexOfMessage),
                    ...registeredMessages.slice(indexOfMessage + 1),
                ]);
        },
        [registeredMessages, _connection],
    );

    //Handle registering only one message
    const registerSignalRMessage = useCallback(
        (signalRMessage: SignalRMessage, action: SignalRAction) => {
            registerMessage(signalRMessage, action);
            if (registeredMessages.indexOf(signalRMessage) === -1) {
                setRegisteredMessages([...registeredMessages, signalRMessage]);
            }
            _signalrGroupService.subscribeToGroupsByMessages(tenantId, [signalRMessage]);
        },
        [registeredMessages, registerMessage, tenantId],
    );

    const cleanupMessages = useCallback(() => {
        registeredMessages.forEach((message) => {
            _connection.off(message);
        });
    }, [registeredMessages, _connection]);

    const registerSignalRConfig = useCallback(
        (config: SignalRActionConfig) => {
            //Ensure all connections are turned off.
            cleanupMessages();
            //overwrite all messages.
            setRegisteredMessages(
                config.map((m) => {
                    registerMessage(m.message, m.action);
                    return m.message;
                }),
            );
            _signalrGroupService.subscribeToGroupsByMessages(
                tenantId,
                config.map((ci) => ci.message),
            );
        },
        [registerMessage, cleanupMessages, tenantId],
    );

    useEffect(() => {
        return () => {
            if (registeredMessages.length && !options?.disableAutoMessageCleanup) {
                cleanupMessages();
                setRegisteredMessages([]);
            }
        };
    }, [cleanupMessages, options, registeredMessages]);

    return {
        registerSignalRConfig: registerSignalRConfig,
        disposeSignalRMessage: disposeSignalRMessage,
        registerSignalRMessage: registerSignalRMessage,
        invokeMessage: invokeMessage,
    };
}

/**
 * Start/Stop the provided hub connection (on connection change or when the component is unmounted)
 * @param {HubConnection} hubConnection The signalR hub connection
 * @return {HubConnection} the current signalr connection
 * @return {any} the signalR error in case the start does not work
 */
function useHub(hubConnection?: HubConnection): { hubConnectionState: HubConnectionState; error?: string } {
    const tenantId = useTenantId();
    const [hubConnectionState, setHubConnectionState] = useState<HubConnectionState>(
        hubConnection?.state ?? HubConnectionState.Disconnected,
    );
    const [error, setError] = useState();

    const currentHubState = hubConnection?.state ?? HubConnectionState.Disconnected;

    const updateConnectionState = (state: HubConnectionState) => {
        if (currentHubState !== hubConnectionState) setHubConnectionState(state);
    };

    useEffect(() => {
        updateConnectionState(currentHubState);
    }, [currentHubState]);

    useEffect(() => {
        if (!hubConnection) return;
        hubConnection.onclose(() => updateConnectionState(HubConnectionState.Disconnected));
        hubConnection.onreconnected(() => updateConnectionState(HubConnectionState.Connected));
        hubConnection.onreconnecting(() => updateConnectionState(HubConnectionState.Reconnecting));
    }, []);

    useEffect(() => {
        if (!hubConnection) return;

        setError(undefined);

        if (currentHubState === HubConnectionState.Disconnected && tenantId) {
            const start = hubConnection
                .start()
                .then(() => {
                    console.assert(hubConnection.state === HubConnectionState.Connected);
                    console.log('Websocket connection established');
                    console.log('hubConnection.connectionId:', hubConnection.connectionId);
                    if (hubConnection.connectionId) signalRGroupSubscriptionService.setConnectionId = hubConnection.connectionId;
                    //dentalApi.getSubscribeToGroup(tenantId, hubConnection.connectionId);
                })
                .catch((reason) => {
                    setError(reason);
                    signalRGroupSubscriptionService.setConnectionId = undefined;
                });

            return () => {
                start.then(() => {
                    hubConnection.stop();
                    signalRGroupSubscriptionService.setConnectionId = undefined;
                    //signalRGroupSubscriptionService.unsubscribeFromAllGroups(tenantId);
                });
            };
        }

        return () => {
            hubConnection.stop();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hubConnection, tenantId]);

    return { hubConnectionState, error };
}

export default useHub;
