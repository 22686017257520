import { DefaultButton, Panel, PanelType, PrimaryButton, Stack, StackItem } from '@fluentui/react';
import { FormStatus } from 'api/models/form';
import forms from 'forms/forms';
import useForms from 'hooks/store/useForms';
import { SyncStatus } from 'interfaces/syncing-statuses';
import { SaveStatusIcon } from '..';

export function DefaultFormPanelFooter(): JSX.Element {
    const { currentlySelectedForm, syncing, setBaseFormDataPropAndSave, cleanupSelectedForm, editingCompletedForm } = useForms();

    const _onFinish = () => {
        setBaseFormDataPropAndSave('completedDate', new Date().toISOString());
    };

    const isCompleted = currentlySelectedForm?.status === FormStatus.Completed;
    const completeDisabled = (isCompleted || syncing === SyncStatus.Pending) && !editingCompletedForm;

    const _onDismiss = () => {
        cleanupSelectedForm();
    };

    return (
        <Stack horizontal tokens={{ childrenGap: 12 }} verticalAlign="center">
            <PrimaryButton text="Complete" onClick={_onFinish} disabled={completeDisabled} />
            <DefaultButton text="Close" onClick={_onDismiss} />
            <StackItem>
                <SaveStatusIcon saveStatus={syncing} />
            </StackItem>
        </Stack>
    );
}

export default function FormPanel(): JSX.Element {
    const { currentlySelectedForm, cleanupSelectedForm } = useForms();

    const _onDismiss = () => {
        cleanupSelectedForm();
    };

    let FormComponent: (() => JSX.Element | null) | null;
    let FooterComponent: (() => JSX.Element | null) | null;

    if (currentlySelectedForm?.formName) {
        FormComponent = forms[currentlySelectedForm?.formName].component;
        FooterComponent = forms[currentlySelectedForm?.formName].footerComponent;
    } else {
        FormComponent = null;
        FooterComponent = null;
    }

    const headerText =
        currentlySelectedForm && forms[currentlySelectedForm.formName] ? forms[currentlySelectedForm.formName]?.title : '';
    return (
        <Panel
            headerText={headerText}
            styles={{
                content: { overflowY: 'auto', overflowX: 'hidden', flex: 1, position: 'relative' },
                root: { overflow: 'hidden' },
                scrollableContent: { overflow: 'hidden', display: 'flex', flexDirection: 'column' },
            }}
            customWidth={'50vw'}
            onDismiss={_onDismiss}
            isOpen={currentlySelectedForm ? true : false}
            type={PanelType.custom}
            isFooterAtBottom
            onRenderFooterContent={() => (currentlySelectedForm && FooterComponent ? <FooterComponent /> : null)}
        >
            {currentlySelectedForm && FormComponent ? <FormComponent /> : null}
        </Panel>
    );
}
