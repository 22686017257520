import { PowerBIEmbed } from 'powerbi-client-react';
import { models, Embed } from 'powerbi-client';
import './Report.scss';
import { IEmbedReportModel } from 'api/models/embed-report.model';

function Report(report: IEmbedReportModel): JSX.Element {
    if (report) {
        return (
            <PowerBIEmbed
                cssClassName="report"
                embedConfig={{
                    type: 'report',
                    id: report?.embeddedReport.reportId,
                    embedUrl: report?.embeddedReport.embedUrl,
                    accessToken: report?.embedToken.token,
                    tokenType: models.TokenType.Embed,
                    settings: {
                        panes: {
                            filters: { visible: false },
                            fields: {},
                        },
                        bars: {
                            actionBar: {
                                visible: false,
                            },
                        },
                        background: models.BackgroundType.Transparent,
                    },
                }}
                eventHandlers={
                    new Map([
                        [
                            'loaded',
                            function () {
                                console.log('Report loaded');
                            },
                        ],
                        [
                            'rendered',
                            function () {
                                console.log('Report rendered');
                            },
                        ],
                        [
                            'error',
                            function (event) {
                                console.log(event?.detail);
                            },
                        ],
                    ])
                }
                getEmbeddedComponent={(embedObject: Embed) => {
                    console.log(embedObject);
                }}
            />
        );
    } else {
        return <></>;
    }
}
export default Report;
