import { Link } from '@fluentui/react';
import { IPredeterminationProcedure } from 'api/models/predeterminations.model';
import IWorkListAppointmentView from 'api/models/worklist-appointment-view';
import IWorkListEncounterView from 'api/models/worklist-encounter-view';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import { useTenantId } from 'hooks';
import { batch, useDispatch } from 'react-redux';
import { setSelectedWorkListEditItem, setWorkListLayerOpen } from 'state/slices/admin-huddle/worklists/worklist.slice';
import { getPatientToEdit, getPatientToEditAndOpenPanel } from 'state/slices/edit-patient/edit-patient.actions';

//This column will use the global edit patient panel
export const patientNameGlobalEditPatient: ISortableColumn<IWorkListAppointmentView | IWorkListEncounterView> = {
    key: 'patientName',
    minWidth: 120,
    maxWidth: 120,
    isResizable: true,
    name: 'Patient Name',
    fieldName: 'patientLastName',
    data: 'global',
    onRender: (item, _, column) => <PatientNameColumn column={column} item={item} />,
};

//This column will use the worklist edit patient panel. (Uses different save endpoints)
export const patientNameWorkList: ISortableColumn<IWorkListAppointmentView | IWorkListEncounterView> = {
    ...patientNameGlobalEditPatient,
    data: 'worklist',
};

export function PatientNameColumn({
    item,
    column,
}: {
    item: IWorkListAppointmentView | IWorkListEncounterView | IPredeterminationProcedure | undefined;
    column?: ISortableColumn<IWorkListAppointmentView | IWorkListEncounterView>;
}) {
    const dispatch = useDispatch();
    const tenantId = useTenantId();

    if (!item) return null;

    const _handleEditPatient = (patientId?: string) => {
        if (patientId) {
            if (column?.data === 'global') {
                dispatch(getPatientToEditAndOpenPanel({ tenantId, patientId }));
            } else {
                batch(() => {
                    dispatch(getPatientToEdit({ tenantId, patientId }));
                    dispatch(setWorkListLayerOpen({ layer: 'EditPatientPanel', isOpen: true }));
                });
            }
            //Need to set this so recent items gets populated...
            dispatch(setSelectedWorkListEditItem(item));
        }
    };

    const columnText = `${item.patientLastName}, ${item.patientFirstName}${item.mrn ? ` (${item.mrn})` : ''}`;

    return (
        <Link
            styles={{ root: { cursor: 'pointer' } }}
            onClick={() => {
                _handleEditPatient(item.patientId);
            }}
            title={columnText}
        >
            {columnText}
        </Link>
    );
}
