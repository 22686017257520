import { createAsyncThunk } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import { IChartProcedure, ChartProcedureStatus, ChartProcedurePreAuth } from 'api/models/chart.model';
import IForm, { FormName, FormStatus } from 'api/models/form';
import IPatientAllergies from 'api/models/patient-allergy.model';
import IPatientMedicationsView from 'api/models/patient-medication.model';
import IPatientProblems from 'api/models/patient-problem.model';
import IPatientVitals from 'api/models/patient-vital.model';
import IAppointmentAllocations from 'api/models/Scheduling/allocation.model';
import IPatientAppointment from 'api/models/Scheduling/patientAppointment.model';
import schedulingApi from 'api/scheduling.api';
import axios, { AxiosResponse } from 'axios';
import { isAfter, isBefore } from 'date-fns';
import { orderBy } from 'lodash';
import { ProcedureActionType } from 'state/slices/charting/chart/chart.slice';
import { AppThunk, RootState } from 'state/store';
import { classicDateOnly } from 'utils/dateOnly';
import getQuickNoteFragment from './getQuickNoteFragment';
import { getIsActiveChartProcedure } from 'state/slices/charting/procedures/procedures.selectors';
import { setCurrentChartNoteDataAndSave } from '../chart-notes.actions';
import { setClincalNoteDataProp } from '../chart-notes.slice';

export enum NoteParser {
    EncounterReasons = 'EncounterReasons',
    Problems = 'Problems',
    Allergies = 'Allergies',
    Medications = 'Medications',
    DentalHistory = 'DentalHistory',
    CariesRisk = 'CariesRisk',
    Vitals = 'Vitals',
    SoftTissueExam = 'SoftTissueExam',
    TreatmentPlannedProcedures = 'TreatmentPlannedProcedures',
    CompletedProcedures = 'CompletedProcedures',
    ProcedureNoteTemplates = 'ProcedureNoteTemplates',
    ReferredProcedures = 'ReferredProcedures',
    AdditionalComments = 'AdditionalComments',
    //PostOpInstructions = 'PostOpInstructions',
    Rx = 'Rx',
    NextAppointment = 'NextAppointment',
    RDA = 'RDA',
    Hygienist = 'Hygienist',
    DDS = 'DDS',
    //PreAuthRequirement = 'PreAuthRequirement',
}

export const noteParserDisplayName: Record<NoteParser, string> = {
    AdditionalComments: 'Additional Comments',
    Allergies: 'Allergies',
    CariesRisk: 'Caries Risk',
    CompletedProcedures: 'Completed Procedures',
    DDS: 'Treating Provider',
    DentalHistory: 'Dental History',
    EncounterReasons: 'Encounter Reasons',
    Hygienist: 'Hygienist',
    Medications: 'Medications',
    NextAppointment: 'Next Appointment',
    Problems: 'Problems',
    ProcedureNoteTemplates: 'Procedure Note Templates',
    RDA: 'RDA',
    ReferredProcedures: 'Referred Procedures',
    Rx: 'Rx',
    SoftTissueExam: 'Soft Tissue Exam',
    TreatmentPlannedProcedures: 'Treatment Planned Procedures',
    Vitals: 'Vitals',
};

export const getQuickNoteNextAppointment = createAsyncThunk<
    IPatientAppointment | undefined,
    {
        tenantId: string;
        patientId: string;
    },
    { state: RootState }
>('quickNotes/getQuickNoteNextAppointment', async ({ tenantId, patientId }, { getState }) => {
    const encounterDate = getState().encounter?.patientEncounter?.encounterDate;
    const currentEncounterDate = encounterDate ? classicDateOnly(encounterDate) : undefined;

    if (!currentEncounterDate) return undefined;

    let pastNextAppt: IPatientAppointment | undefined;
    let futureNextAppt: IPatientAppointment | undefined;

    const getNextAppointment = (allocation: IAppointmentAllocations) =>
        orderBy(
            allocation.patients
                ?.filter((appt) => !appt.isDeleted)
                .filter((appt) => isAfter(new Date(appt.date), new Date(currentEncounterDate))),
            ['date', 'startTime'],
            ['asc'],
        )[0];

    //If encounter is in the past look to see if there are any other appts in the past that are before this encounterApptDate.
    if (currentEncounterDate && isBefore(new Date(currentEncounterDate), new Date())) {
        const { data: pastAllocation } = await schedulingApi.getPatientAppointmentsByParameters(tenantId, {
            patientId,
            pastDatesOnly: true,
        });

        pastNextAppt = getNextAppointment(pastAllocation);
    }

    //If we can't find a next appointment in the past. Fetch the future appts.
    if (!pastNextAppt) {
        const { data: futureAllocation } = await schedulingApi.getPatientAppointmentsByParameters(tenantId, {
            patientId,
            currentAndFutureDatesOnly: true,
        });
        futureNextAppt = getNextAppointment(futureAllocation);
    }

    const nextAppointment = pastNextAppt ?? futureNextAppt;
    return nextAppointment;
});

export const getQuickNoteProcedures = createAsyncThunk<
    {
        completed: IChartProcedure[];
        treatmentPlanned: IChartProcedure[];
        preAuthRequired: boolean;
        referred: IChartProcedure[];
    },
    {
        tenantId: string;
        patientId: string;
        encounterId: string;
    }
>('quickNotes/fetchQuickNoteProcedures', async ({ tenantId, patientId, encounterId }) => {
    const chartProceduresResponse = await dentalApi.getChartProcedures(tenantId, patientId);

    const proceduresMatchingEncounterId = chartProceduresResponse.data
        .filter(getIsActiveChartProcedure)
        .filter((p) => p.encounterId === encounterId);

    const completed = proceduresMatchingEncounterId
        .filter((proc) => proc.status === ChartProcedureStatus.Completed)
        .filter((proc) => proc.type === ProcedureActionType.Treatment);
    const treatmentPlanned = proceduresMatchingEncounterId.filter(
        (proc) => proc.type === ProcedureActionType.Treatment && proc.status === ChartProcedureStatus.Pending,
    );
    const referred = proceduresMatchingEncounterId.filter((proc) => proc.type === ProcedureActionType.Referred);

    const preAuthRequired =
        proceduresMatchingEncounterId
            .filter((proc) => proc.type === ProcedureActionType.Treatment || proc.type === ProcedureActionType.Referred)
            .filter(
                (proc) =>
                    proc.preAuthorization === ChartProcedurePreAuth.Required ||
                    proc.preAuthorization === ChartProcedurePreAuth.NotRequired,
            ).length > 0;

    return { completed, treatmentPlanned, referred, preAuthRequired };
});

export const getQuickNoteForms = createAsyncThunk<
    {
        cariesRisk?: IForm;
        dentalHistory?: IForm;
        softTissueExam?: IForm;
        patientProblem?: IForm;
    },
    {
        tenantId: string;
        patientId: string;
        encounterId: string;
    }
>('quickNotes/fetchQuickNoteforms', async ({ tenantId, patientId, encounterId }) => {
    const forms = await dentalApi.getAllPatientForms(tenantId, patientId);

    const formsMatchingEncounterId = forms.data.filter((p) => p.encounterId === encounterId);

    const patientProblem = forms.data.find((f) => f.formName === FormName.PatientProblem);

    const getForm = (formNames: FormName[]) => {
        const isCompletedOrReviewed = (item: IForm) =>
            item.status === FormStatus.Reviewed || item.status === FormStatus.Completed;

        const newFormList = orderBy(
            formsMatchingEncounterId
                .filter((item) => formNames.findIndex((f) => f === item.formName) > -1)
                .filter(isCompletedOrReviewed),
            (item) => (item.status === FormStatus.Reviewed ? item.modifiedOn : item.completedDate),
            ['desc'],
        );

        return newFormList?.length ? newFormList[0] : undefined;
    };

    const cariesRisk = getForm([FormName.CariesRiskAdult, FormName.CariesRiskChild]);
    const dentalHistory = getForm([FormName.DentalHistory]);
    const softTissueExam = getForm([FormName.SoftTissueExam]);

    return { cariesRisk, softTissueExam, dentalHistory, patientProblem };
});

export interface IPAMVAssetPayload {
    problems: IPatientProblems;
    allergies?: IPatientAllergies;
    medications?: IPatientMedicationsView;
    vitals: IPatientVitals;
}

export const fetchPAMV = createAsyncThunk<
    IPAMVAssetPayload,
    {
        tenantId: string;
        patientId: string;
    }
>('quickNotes/fetchPAMV', async ({ tenantId, patientId }) => {
    const requests = [
        dentalApi.getPatientProblems(tenantId, patientId),
        dentalApi.getPatientAllergies(tenantId, patientId),
        dentalApi.getPatientMedications(tenantId, patientId),
        dentalApi.getPatientVitals(tenantId, patientId),
    ];
    const [{ data: problems }, { data: allergies }, { data: medications }, { data: vitals }] =
        await axios.all<AxiosResponse<IPatientProblems | IPatientAllergies | IPatientMedicationsView | IPatientVitals>>(requests);

    const patientMedications = medications as IPatientMedicationsView;
    const patientAllergies = allergies as IPatientAllergies;
    const patientVitals = vitals as IPatientVitals;

    const payload: IPAMVAssetPayload = {
        problems: problems as IPatientProblems,
        allergies: patientAllergies,
        medications: patientMedications,
        vitals: patientVitals,
    };

    return payload;
});

export const parseAndSetClincalNoteFragment =
    ({ tenantId, patientId, parser }: { tenantId: string; patientId?: string; parser?: NoteParser }): AppThunk<void> =>
        (dispatch, getState) => {
            if (patientId) {
                const state = getState();
                const fragmentValue = getQuickNoteFragment(state, parser, tenantId);
                const clinicalNote = `\n<div>${state.chartNotes.currentClinicalNote?.data.value}</div>` ?? '';

                dispatch(
                    setClincalNoteDataProp({
                        path: 'value',
                        value: clinicalNote + fragmentValue,
                    }),
                );
            }
        };

export const parseAndSetClincalNoteFragmentNew =
    ({ tenantId, parser }: { tenantId: string; parser?: NoteParser }): AppThunk<string | undefined> =>
        (dispatch, getState) => {
            const state = getState();
            const fragmentValue = getQuickNoteFragment(state, parser, tenantId);

            return fragmentValue;
        };
