import { useSelector, useTenantId } from 'hooks';
import { useDispatch } from 'react-redux';
import { selectUserIdentitiesData, selectUserIdentitiesState } from 'state/slices/users-identities/user-identities.selectors';
import { useCallback } from 'react';
import { getMissingUserIdentities } from 'state/slices/users-identities/user-identities.actions';
import { cleanupUserIdentitiesData } from 'state/slices/users-identities/user-indentities.slice';

function useUserIdentities() {
    const _dispatch = useDispatch();
    const tenantId = useTenantId();

    const { loading } = useSelector(selectUserIdentitiesState);
    const _userIdentitiesData = useSelector(selectUserIdentitiesData);

    const _getMissingUserIdentities = useCallback(
        (userIds: string[]) => {
            _dispatch(getMissingUserIdentities({ tenantId, userIds }));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [tenantId, _dispatch],
    );

    const _cleanupUserIdentitiesData = useCallback(() => {
        _dispatch(cleanupUserIdentitiesData());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_dispatch]);

    return {
        loadingUserIdentities: loading,
        userIdentitiesData: _userIdentitiesData,
        getMissingUserIdentities: _getMissingUserIdentities,
        cleanupUserIdentitiesData: _cleanupUserIdentitiesData,
    };
}

export default useUserIdentities;
