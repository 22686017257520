import { CompoundButton, Link, mergeStyleSets, Spinner, SpinnerSize, Stack, Text, useTheme } from '@fluentui/react';
import { Dictionary } from '@reduxjs/toolkit';
import IUserTask, { TaskSeverity } from 'api/models/user-task.model';
import { LoadingStatus, LoadingStatuses } from 'interfaces/loading-statuses';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useDispatch } from 'react-redux';
import { executeTask } from 'state/task-management/taskManagement.actions';

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 2560 },
        items: 8,
    },
    desktop: {
        breakpoint: { max: 2560, min: 1024 },
        items: 6,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};

const classNames = mergeStyleSets({
    container: {
        margin: '0 10px',
    },
    card: {},
});

type PatientBeaconProps = {
    items?: IUserTask[];
    loading: LoadingStatuses;
    itemsLoadingLookup?: Dictionary<LoadingStatus>;
    loadingReferenceKey?: string;
};

function Beacon(props: PatientBeaconProps): JSX.Element | null {
    const { items } = props;
    if (props.loading !== LoadingStatus.Completed)
        return <Spinner size={SpinnerSize.large} label="Loading..." labelPosition="right" />;
    if (!items?.length) return null;
    return (
        <Carousel responsive={responsive} itemClass={classNames.card} containerClass={classNames.container}>
            {items &&
                items.map((item, index) => {
                    return <BeaconTaskCard key={index} task={item} itemsLoadingLookup={props.itemsLoadingLookup} />;
                })}
        </Carousel>
    );
}

function BeaconTaskCard({
    task,
    itemsLoadingLookup,
    loadingReferenceKey,
}: {
    task: IUserTask;
    itemsLoadingLookup?: Dictionary<LoadingStatus>;
    loadingReferenceKey?: string;
}) {
    const dispatch = useDispatch();
    const theme = useTheme();
    const _handleExecuteTask = () => {
        dispatch(executeTask(task));
    };

    const loadingKey = task.references ? task.references[loadingReferenceKey ?? ''] : '';
    const isTaskLoading = itemsLoadingLookup ? itemsLoadingLookup[loadingKey] === LoadingStatus.Pending : false;

    const _severityColor: { [key in TaskSeverity]: string } = {
        [TaskSeverity.High]: theme.palette.red,
        [TaskSeverity.Medium]: theme.palette.yellow,
        [TaskSeverity.Low]: theme.palette.green,
    };

    const _styles = mergeStyleSets({
        root: {
            background: theme.palette.neutralLighterAlt,
            padding: 10,
            border: `1px solid ${theme.palette.neutralSecondary}`,
            borderLeft: task.severity ? `5px solid ${_severityColor[task.severity]}` : '',
            borderRadius: 3,
            marginRight: 10,
            minHeight: 120,
            cursor: 'pointer',
            ':hover': {
                background: theme.palette.neutralLighter,
            },
        },
    });

    return (
        <Stack
            grow
            horizontal
            verticalAlign="center"
            onClick={() => {
                if (!isTaskLoading) _handleExecuteTask();
            }}
            horizontalAlign="center"
            styles={_styles}
        >
            <Stack.Item grow>
                <Text variant="mediumPlus" block>
                    {task.note ? task.note : `Execute Task ${task.type}`}
                </Text>
            </Stack.Item>
        </Stack>
    );
}

export default Beacon;
