import { IDropdownOption } from '@fluentui/react';
import { createAsyncThunk, createSelector, createSlice, Dictionary } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import { IProcedure } from 'api/models/procedure.model';
import { AxiosResponse, AxiosError } from 'axios';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { LookupState } from 'interfaces/lookup-state';
import { RootState } from 'state/store';
import { isDateBetween } from 'utils/isDateBetween';

type ProcedureState = LookupState<IProcedure>;

const initialState: ProcedureState = {
    initialLoad: LoadingStatus.Idle,
    loading: LoadingStatus.Idle,
    data: {},
};

export const getProcedures = createAsyncThunk<
    AxiosResponse<Dictionary<IProcedure>>,
    string,
    {
        rejectValue: AxiosError;
    }
>('getProcedures', async (tenantId) => {
    const res = await dentalApi.getProcedures(tenantId);
    return res;
});

const proceduresSlice = createSlice({
    name: 'procedures',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getProcedures.pending, (state) => {
                state.initialLoad = LoadingStatus.Pending;
            })
            .addCase(getProcedures.fulfilled, (state, action) => {
                state.initialLoad = LoadingStatus.Completed;
                state.data = action.payload.data;
            })
            .addCase(getProcedures.rejected, (state, action) => {
                state.initialLoad = LoadingStatus.Failed;
                state.errors = action.payload?.message;
            });
    },
});

const { reducer } = proceduresSlice;

export const selectProceduresObject = (state: RootState): Dictionary<IProcedure> => state.tenant.procedures.data;
export const selectProceduresData = createSelector(selectProceduresObject, (data) => (data ? data : {}));

export const selectProcedures = createSelector(selectProceduresData, (procedures) => {
    return procedures;
});

export const proceduresList = createSelector(selectProcedures, (procedures) => {
    if (procedures)
        return Object.keys(procedures)
            .map((key) => procedures[key])
            .filter((procedure) => !procedure?.isDeleted) as IProcedure[];
    return [];
});

export const selectEffectiveProceduresAsList = createSelector(proceduresList, (procedures) => {
    return procedures
        .filter((proc) => {
            return isDateBetween({ dateToCheck: new Date().toISOString(), start: proc.effectiveDate, end: proc.endDate });
        })
        .filter((proc) => proc.category !== 'System');
});

export const proceduresOptions = createSelector(selectEffectiveProceduresAsList, (procedures) => {
    return procedures
        .filter((p) => !p.isDeleted)
        .map((p) => {
            const optionText = `${p.code} - ${p.displayName}`;
            const option = { key: p.id, text: optionText, title: optionText } as IDropdownOption;
            return option;
        });
});

/// filter out list of procedures from procedureIds and make a options list
export const completionCodesOption = createSelector(
    proceduresList,
    (_: RootState, procedureIds: string[]) => procedureIds,
    (procedures, procedureIds) => {
        return procedures
            .filter((p) => procedureIds.includes(p.code))
            .map((p) => {
                const optionText = `${p.code} - ${p.displayName}`;
                const option = { key: p.id, text: optionText, title: optionText } as IDropdownOption;
                return option;
            });
    },
);

export default reducer;
