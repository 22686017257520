import { Stack } from '@fluentui/react';
import IWorkListView from 'api/models/worklist-view.model';
import PanelSectionHeader from 'pages/components/PanelSectionHeader';
import { useSelector } from 'react-redux';
import {
    EditEncounterWorkList,
    EncounterWorkList,
} from 'state/slices/admin-huddle/worklists/encounter-worklists/encounter-worklists.state';
import { selectCurrentWorkList, selectWorkListEditItem } from 'state/slices/admin-huddle/worklists/worklist.selectors';
import { MissingSlipsWorkList } from 'state/slices/admin-huddle/worklists/worklist.state';
import { classicDateOnly } from 'utils/dateOnly';
import BannerInfoDetailItem from './VisitSummaryEncounterDate';
import VisitSummaryGoToClaimHistoryButton from './VisitSummaryGoToClaimHistoryButton';
import VisitSummaryUpdateProvider from './VisitSummaryUpdateProvider';

type Props = {
    readOnly?: boolean;
};

export function VisitSummaryEncounterInfo({ readOnly }: Props) {
    const selectedView = useSelector(selectWorkListEditItem<IWorkListView>);

    const currentWorkList = useSelector(selectCurrentWorkList);

    const canEditBillingProvider =
        currentWorkList === EncounterWorkList.ReadyToReview ||
        currentWorkList === EditEncounterWorkList.EditEncounters ||
        currentWorkList === MissingSlipsWorkList.AwaitingAttestation ||
        currentWorkList === MissingSlipsWorkList.OutstandingCheckoutAppointments;

    const canEditAttendingProvider =
        currentWorkList === MissingSlipsWorkList.AwaitingAttestation ||
        currentWorkList === MissingSlipsWorkList.OutstandingCheckoutAppointments;

    const canEditTreatingProvider =
        currentWorkList === MissingSlipsWorkList.AwaitingAttestation ||
        currentWorkList === MissingSlipsWorkList.OutstandingCheckoutAppointments;
    //look for the encounterDate at the root, otherwise look at the chart note
    const encounterDate = selectedView?.encounterDate
        ? selectedView.encounterDate
        : selectedView?.chartNote?.encounterDate
        ? selectedView.chartNote.encounterDate
        : undefined;
    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <PanelSectionHeader text="Encounter Information" />
            <Stack verticalAlign="center" horizontalAlign="space-between" grow horizontal tokens={{ childrenGap: 10 }}>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <BannerInfoDetailItem
                        label="Encounter Date"
                        text={encounterDate ? `${classicDateOnly(encounterDate)}` : 'N/A'}
                    />
                    <BannerInfoDetailItem label="Encounter Reason" text={selectedView?.encounterReason ?? 'N/A'} />
                </Stack>
                <VisitSummaryGoToClaimHistoryButton />
            </Stack>
            <VisitSummaryUpdateProvider
                canEdit={canEditAttendingProvider}
                readOnly={readOnly}
                providerIdType="supervisingProviderId"
                label="Attending Provider"
            />
            <VisitSummaryUpdateProvider
                readOnly={readOnly}
                canEdit={canEditBillingProvider}
                providerIdType="billingProviderId"
                label="Billing Provider"
            />
            <VisitSummaryUpdateProvider
                readOnly={readOnly}
                canEdit={canEditTreatingProvider}
                providerIdType="treatingProviderId"
                label="Treating Provider"
            />
        </Stack>
    );
}
