import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { IStackTokens, Link, Stack, Toggle } from '@fluentui/react';
import PanelSectionHeader from 'pages/components/PanelSectionHeader';
import { Field } from 'components';
import PaymentMethods from './PaymentMethods';
import { useSelector } from 'hooks';
import {
    selectPatientOverpaymentOrOverAdjustment,
    selectPatientPaymentInfoSaving,
    selectPatientPaymentsAndAdjustmentsValidationErrors,
    selectPatientPaymentsModified,
    selectPatientPaymentSource,
    selectPaymentAndAdjustmentAmounts,
    selectPaymentsAndAdjustmentTotalPayment,
} from 'state/slices/ledger/patient-payments-and-adjustments/patient-payments-and-adjustments.selectors';
import { getValidationError } from 'hooks/useValidation';
import { useDispatch } from 'react-redux';
import { cleanupPaymentInformation, updatePatientPaymentSource } from 'state/slices/ledger/ledger.slice';
import { useCallback, useEffect } from 'react';
import { usdCurrencyFormatter } from 'utils';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { getMaxPayment } from '../LedgerUtils';

type Props = {
    isMakingPaymentAndAdjustment: boolean;
};

const stackTokens: IStackTokens = {
    childrenGap: 10,
};

export default function PaymentInfoSection({ isMakingPaymentAndAdjustment }: Props) {
    const dispatch = useDispatch();
    const { push } = useHistory();

    const overpayment = useSelector(selectPatientOverpaymentOrOverAdjustment);
    const errors = useSelector(selectPatientPaymentsAndAdjustmentsValidationErrors);
    const { remainingPaymentAmount, paymentAmount, adjustmentAmount } = useSelector(selectPaymentAndAdjustmentAmounts);
    const totalPaymentDue = useSelector(selectPaymentsAndAdjustmentTotalPayment);
    const paymentsModified = useSelector(selectPatientPaymentsModified);
    const savingPaymentInfo = useSelector(selectPatientPaymentInfoSaving);

    const makeAdjustment = () => {
        const shouldConfirm =
            savingPaymentInfo !== LoadingStatus.Completed && savingPaymentInfo !== LoadingStatus.Failed && paymentsModified;
        const confirm = shouldConfirm
            ? window.confirm('Are you sure that you want to remove payment info? Any unsaved information will be lost.')
            : true;
        if (confirm) push({ search: queryString.stringify({ hideMakePayment: true }) });
    };

    useEffect(() => {
        return () => {
            dispatch(cleanupPaymentInformation());
        };
    }, [dispatch]);

    const _onChangeTodaysPayment = (e: React.FormEvent, value: number | undefined) => {
        dispatch(updatePatientPaymentSource({ path: 'amount', value }));
    };

    return (
        <Stack>
            <PanelSectionHeader
                text="Payment Info"
                rightContent={isMakingPaymentAndAdjustment ? <Link onClick={makeAdjustment}>Remove Payment</Link> : undefined}
                leftContent={<span>(Unallocated Amount: {usdCurrencyFormatter.format(remainingPaymentAmount)})</span>}
            />
            <Stack horizontal tokens={stackTokens} wrap>
                <Field.Currency
                    styles={{ field: { marginTop: 0 } }}
                    prefix="$"
                    label="Today's Payment"
                    required
                    onChange={_onChangeTodaysPayment}
                    max={getMaxPayment({ overpayment, paymentAmount: totalPaymentDue - adjustmentAmount })}
                    value={paymentAmount}
                    errorMessage={getValidationError(errors, "Today's Payment") ? "Today's Payment is required." : undefined}
                />
                <PaymentMethods />
            </Stack>
        </Stack>
    );
}
