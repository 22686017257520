import { LookupCodeBase } from './lookup-base.model';

export enum ReportType {
    TreatmentPlan = 'TreatmentPlan',
    PrintPredetermination = 'PredeterminationForm',
    PatientRouteSlip = 'PatientRouteSlip',
    ClinicalNote = 'ClinicalNote',
    DailySchedule = 'DailySchedule',
    PatientReceipt = 'PatientReceipt',
    Walkout = 'Walkout',
    SpecificBatch = 'SpecificBatch',
    PerioChart = 'PerioChart',
    ProviderProductivity = 'ProviderProductivity',
    FinancialSummary = 'FinancialSummary',
    AdjustmentDetails = 'AdjustmentDetails',
    AdjustmentSummary = 'AdjustmentSummary',
    Batch = 'Batch',
    DailyBalance = 'DailyBalance',
    ChargeAndPaymentSummary = 'ChargeAndPaymentSummary',
    ChargeAndPaymentDetails = 'ChargeAndPaymentDetails',
    SlidingFeeEncounterDetails = 'SlidingFeeEncounterDetails',
    TimeOfServiceCollectionSummary = 'TimeOfServiceCollectionSummary',
    TimeOfServiceCollectionDetails = 'TimeOfServiceCollectionDetails',

    EncountersDetails = 'EncountersDetails',
    EncountersSummary = 'EncountersSummary',

    ProcedureDateAging = 'ProcedureDateAging',
    ClinicalMeasureDetails = 'ClinicalMeasureDetails',
    ClinicalMeasureSummary = 'ClinicalMeasureSummary',
    TreatmentPlanPhaseOneSummary = 'TreatmentPlanPhaseOneSummary',
    TreatmentPlanPhaseOneDetails = 'TreatmentPlanPhaseOneDetails',
    PatientStatement = 'PatientStatement',
    UDSTable9DDetails = 'UDSTable9DDetails',
    UDSTable9DCore = 'UDSTable9DCore',

    DepositSlipDetails = 'DepositSlipDetails',
    DepositSlipSummary = 'DepositSlipSummary',

    EncountersDetailsReport = 'EncountersDetailsReport',
    EncountersSummaryReport = 'EncountersSummaryReport',

    BillingStatusAnalysisDetails = 'BillingStatusAnalysisDetails',
    PaymentReceipt = 'PaymentReceipt',
    BillingStatusAnalysis = 'BillingStatusAnalysis',

    Wrap = 'Wrap',
    EncounterStatus = 'EncounterStatus',
    ToothLedger = 'ToothLedger',

    AppointmentsReportNoEncounterAssociated = 'AppointmentsReportNoEncounterAssociated',

    DentalSealantsForChildrenDetails = 'DentalSealantsForChildrenDetails',
    DentalSealantsForChildrenSummary = 'DentalSealantsForChildrenSummary',

    PatientStatementForBulk = 'PatientStatementForBulk',
    ClaimHistory = 'ClaimHistory',

    UDSTable6A = 'UDSTable6A',
    UDSTable5 = 'UDSTable5',

    UACandOveragesDetailsReport = 'UACandOveragesDetailsReport',
    UACandOveragesSummaryReport = 'UACandOveragesSummaryReport',
    StatementValidation = 'StatementValidation',
    FinancialTransactionDetails = 'FinancialTransactionDetailsReport',
}

export const financialReports: (ReportType | string)[] = [
    ReportType.DailySchedule,
    ReportType.ProviderProductivity,
    ReportType.Batch,
    ReportType.FinancialSummary,
    ReportType.DailyBalance,
    ReportType.ChargeAndPaymentDetails,
    ReportType.ChargeAndPaymentSummary,
    ReportType.AdjustmentDetails,
    ReportType.AdjustmentSummary,
    ReportType.SlidingFeeEncounterDetails,
    ReportType.TimeOfServiceCollectionDetails,
    ReportType.TimeOfServiceCollectionSummary,

    ReportType.ProcedureDateAging,
    ReportType.ClinicalMeasureDetails,
    ReportType.ClinicalMeasureSummary,
    ReportType.TreatmentPlanPhaseOneSummary,
    ReportType.TreatmentPlanPhaseOneDetails,
    ReportType.EncountersDetails,
    ReportType.EncountersSummary,
    ReportType.UDSTable9DCore,
    ReportType.UDSTable9DDetails,

    ReportType.DepositSlipDetails,
    ReportType.DepositSlipSummary,

    ReportType.BillingStatusAnalysisDetails,

    ReportType.Wrap,
    ReportType.ToothLedger,
    ReportType.EncounterStatus,

    ReportType.AppointmentsReportNoEncounterAssociated,
    ReportType.DentalSealantsForChildrenDetails,
    ReportType.DentalSealantsForChildrenSummary,

    ReportType.PatientStatementForBulk,

    ReportType.UDSTable5,
    ReportType.UDSTable6A,

    ReportType.UACandOveragesDetailsReport,
    ReportType.UACandOveragesSummaryReport,

    ReportType.FinancialTransactionDetails,
    ReportType.StatementValidation,
];

export interface IReport extends LookupCodeBase {
    key?: ReportType;
    workspaceId?: string;
    service?: string;
}

export interface IEmbedReportModel {
    type?: 'report';
    embeddedReport: IEmbedReport;
    embedToken: IEmbedToken;
}

export interface IEmbedReport {
    reportId: string;
    reportName?: string;
    embedUrl?: string;
}

export interface IEmbedToken {
    token?: string;
    tokenId: string;
    expiration: string;
}
