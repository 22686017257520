/**
 * Type of all possible regions. Currently we only support region of Table
 */
export enum CompatibleRegionType {

    /**
     * Region split by Table
     */
    Table,
}
