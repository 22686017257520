import { Stack, IconButton, mergeStyles } from '@fluentui/react';
import { Section } from 'components';
import { useSelector } from 'hooks';
import PanelSectionHeader from 'pages/components/PanelSectionHeader';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    selectIsBasicDetailsSectionOpen,
    selectIsUDSSectionOpen,
    selectIsFinancialSectionOpen,
} from 'state/slices/scheduling/check-in/check-in.selectors';
import { toggleCheckInSecitonVisible, CheckInSections } from 'state/slices/scheduling/scheduling.slice';
import {
    selectBasicInfoTasks,
    selectFinancialCheckoutCheckinTasks,
    selectRootFinancialInfoTasksAsList,
} from 'state/task-management/taskManagement.slice';

type Props = {
    section: CheckInSections;
    children: React.ReactNode;
    leftContent?: JSX.Element;
    animation?: boolean;
};

const sectionTitleLookup: { [key in CheckInSections]: string } = {
    [CheckInSections.BasicDetails]: 'Patient Info',
    [CheckInSections.uds]: 'Household Income',
    [CheckInSections.Financial]: 'Payers',
};

export default function CheckInSection({ section, children, animation = true, leftContent }: Props): JSX.Element {
    const dispatch = useDispatch();
    const isBasicDetailsOpen = useSelector(selectIsBasicDetailsSectionOpen);
    const isUDSOpen = useSelector(selectIsUDSSectionOpen);
    const isFinancialOpen = useSelector(selectIsFinancialSectionOpen);

    const _selectBasicInfoCheckInTasks = useSelector(selectBasicInfoTasks);
    const _selectFinanceInfoCheckInTasks = useSelector(selectFinancialCheckoutCheckinTasks);
    const _selectPayersInfoCheckInTasks = useSelector(selectRootFinancialInfoTasksAsList);

    const targetRef = useRef<HTMLDivElement>(null);
    const [sectionContentHeight, setSectionContentHeight] = useState(0);

    useLayoutEffect(() => {
        if (targetRef.current) setSectionContentHeight(targetRef.current.offsetHeight);
    }, [children]);

    const tasksForSectionLookup: { [key in CheckInSections]?: number } = {
        [CheckInSections.BasicDetails]: _selectBasicInfoCheckInTasks.length,
        [CheckInSections.uds]: _selectFinanceInfoCheckInTasks.length,
        [CheckInSections.Financial]: _selectPayersInfoCheckInTasks.length,
    };

    const isOpenDictionary: { [key in CheckInSections]: boolean } = {
        [CheckInSections.BasicDetails]: isBasicDetailsOpen,
        [CheckInSections.uds]: isUDSOpen,
        [CheckInSections.Financial]: isFinancialOpen,
    };

    const tasksForSectionCount = tasksForSectionLookup[section];
    const isOpen = isOpenDictionary[section];

    useEffect(() => {
        if (!isOpen && tasksForSectionCount) {
            dispatch(toggleCheckInSecitonVisible(section));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tasksForSectionCount]);

    const sectionClass = mergeStyles({
        height: !isOpen ? 0 : animation ? sectionContentHeight : 'auto',
        transition: 'height 100ms ease-in',
        overflow: 'hidden',
    });

    return (
        <Stack grow>
            <PanelSectionHeader
                text={sectionTitleLookup[section]}
                leftContent={leftContent}
                rightContent={
                    <Stack horizontal verticalAlign="center" grow>
                        <IconButton
                            onClick={() => {
                                dispatch(toggleCheckInSecitonVisible(section));
                            }}
                            style={{ maxHeight: 24, maxWidth: 24 }}
                            iconProps={{ iconName: isOpen ? 'ChevronUp' : 'ChevronDown' }}
                        />
                    </Stack>
                }
            />
            <Stack className={sectionClass}>
                <div ref={targetRef}>
                    <Section>{children}</Section>
                </div>
            </Stack>
        </Stack>
    );
}
