import { IChartCondition } from 'api/models/chart.model';
import ConditionConflictRulesPipeline from './conditionConflictRules.pipeline';
import Rule from './rule';
import RulesGenerator, { IRule } from './ruleGenerator';
import { ConflictRuleType } from 'api/models/lookup.model';
import { isArray } from 'lodash';

type Args = {
    conditionPipeline: ConditionConflictRulesPipeline;
    chartCondition: IChartCondition;
    rules: { [key: string]: IRule<ConditionConflictRulesPipeline, IChartCondition> };
};
/**
 * Generates and runs rules based on conflictRules list for the passed chartCondition
 *
 * @export
 * @class ConditionConflictRuleGenerator
 */
export default class ConditionConflictRuleGenerator extends RulesGenerator<ConditionConflictRulesPipeline, IChartCondition> {
    constructor({ conditionPipeline, chartCondition, rules }: Args) {
        super({ pipeline: conditionPipeline, item: chartCondition, rules, ruleTypes: ConflictRuleType });
    }

    protected _runRules(pipeline: ConditionConflictRulesPipeline): void {
        function getCondition(chartCondition: IChartCondition) {
            return pipeline.conditions.find((p) => p.id === chartCondition.conditionId);
        }

        if (!isArray(this._item))
            getCondition(this._item)?.conflictRules?.forEach((r) => {
                const rule = this._rules[r];
                if (rule !== undefined) {
                    const { getErrors, getItem, getShouldRemoveItem } = new Rule<ConditionConflictRulesPipeline, IChartCondition>(
                        { item: this._item as IChartCondition, pipeline, rule },
                    );
                    if (getErrors) this.addErrors(getErrors);

                    this.setShouldRemoveItem = getShouldRemoveItem;
                    this._item = getItem;
                }
            });
    }
}
