function BrokenToothIcon(): JSX.Element {
    return (
        <svg version="1.1" viewBox="0 -40 240 280" style={{ height: 22 }}>
            <path
                d="M236,42.4c-2.7-8.1-6.5-15.4-11.6-21.7S213.1,9.4,205.7,5.6S189.8,0,180.2,0c-5.7,0-10.9,0.8-15.7,2.3s-9.6,3.3-14.4,5.2
	s-9.7,3.6-14.5,5.2S125.5,15,120,15c-5.7,0-10.9-0.8-15.7-2.3s-9.6-3.3-14.4-5.2s-9.7-3.6-14.5-5.2S65.3,0,59.8,0
	c-9.5,0.1-18,2-25.4,5.7s-13.7,8.8-18.8,15.1S6.6,34.4,4,42.5S0,59.1,0,67.9c-0.1,13.9,0.9,28,3,42.3s5.1,28.5,9,42.5
	s8.8,27.7,14.6,40.9s12.6,25.6,20.3,37.3c1.9,2.8,4.3,5,7.1,6.7s6.1,2.5,9.5,2.5c4.3-0.1,8.2-1.3,11.6-3.8s5.8-5.7,7.1-9.7
	l20.9-62.3c2.7,0.4,5.5,0.6,8.3,0.7s5.6,0.1,8.4,0.1c2.8-0.1,5.6-0.2,8.4-0.2s5.6-0.3,8.3-0.6l20.9,62.3c1.3,4.1,3.7,7.4,7.1,9.8
	s7.3,3.6,11.6,3.6c3.4,0,6.6-0.8,9.5-2.5s5.3-3.9,7.1-6.7c7.6-11.7,14.3-24.2,20.3-37.4s10.9-26.8,14.8-40.9s6.9-28.2,8.9-42.5
	s3-28.4,3-42.2C240,59,238.7,50.5,236,42.4z M223.6,92.9c-0.9,8.9-2.3,18-4,27.4s-3.9,18.8-6.4,28.2s-5.5,18.6-8.8,27.5
	s-7,17.3-11,25.3s-8.4,15.1-13.1,21.3c-1.2,1.5-2.7,2.2-4.5,2.2c-0.8,0-2-1.4-3.6-4.3s-3.2-6.5-4.8-10.9s-3.3-9.3-5.2-14.8
	s-3.6-10.7-5.2-15.7s-3-9.6-4.2-13.7s-2.2-7.1-2.8-9c-0.7-2.1-1.9-3.9-3.6-5.4s-3.7-2.2-6-2.2c-3.4,0.1-6.8,0.3-10.2,0.6
	s-6.8,0.5-10.2,0.5c-3.4-0.1-6.8-0.3-10.2-0.6s-6.8-0.5-10.2-0.5c-2.3,0.1-4.4,0.9-6.1,2.3s-2.9,3.2-3.5,5.3c-0.5,1.9-1.4,4.9-2.8,9
	s-2.9,8.8-4.3,13.8s-3.2,10.3-5,15.6s-3.6,10.2-5.3,14.8s-3.2,8.2-4.7,11s-2.7,4.2-3.6,4.2c-1.8,0-3.3-0.7-4.5-2.2
	c-4.7-6.4-9.1-13.5-13.1-21.3s-7.8-16.2-11.1-25.3s-6.3-18.3-8.8-27.7s-4.6-18.8-6.4-28.2s-3.1-18.6-4-27.4S15,75.7,15,68.1
	c-0.1-7,0.9-13.6,2.8-19.9s4.8-12,8.6-16.9s8.5-8.9,14.2-11.8S52.9,15,60.5,15c5.2,0,10,0.8,14.3,2.3s8.8,3.3,13.4,5.2
	c2.1,0.9,4.2,1.7,6.4,2.5L129,49.9L96.6,77l35.9,40l16-1.9L115.8,78l32.7-29.9l-24.1-18.2c4.7-0.3,9-1,12.9-2.2
	c5.1-1.6,9.9-3.3,14.5-5.2s9-3.6,13.1-5.2s8.5-2.3,13-2.3c7.7,0,14.6,1.4,20.5,4.2s10.9,6.6,14.8,11.5s6.8,10.5,8.8,16.9
	s2.9,13.2,2.9,20.5C225,75.7,224.5,84,223.6,92.9z"
            />
        </svg>
    );
}

export default BrokenToothIcon;
