import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { IChartDentition } from 'api/models/chart-dentition.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { ChartingState } from '../chart/chart.slice';
import {
    createChartDentition,
    createChartDentitions,
    getChartDentitions,
    updateChartDentition,
    updateChartDentitions,
} from './dentition.actions';

const dentitionReducers = {};

export const dentitionExtraReducers = (builder: ActionReducerMapBuilder<ChartingState>) => {
    // Get chart dentitions
    return (
        builder
            .addCase(getChartDentitions.pending, (state) => {
                state.dentitions.loading = LoadingStatus.Pending;
            })
            .addCase(getChartDentitions.fulfilled, (state, action: PayloadAction<IChartDentition[]>) => {
                state.dentitions.loading = LoadingStatus.Completed;
                state.dentitions.data = action.payload;
            })
            .addCase(getChartDentitions.rejected, (state, action: PayloadAction<any>) => {
                state.dentitions.loading = LoadingStatus.Failed;
                state.dentitions.errors = action.payload;
            })
            // Create chart dentition
            .addCase(createChartDentition.pending, (state) => {
                state.dentitions.saving = LoadingStatus.Pending;
            })
            .addCase(createChartDentition.fulfilled, (state, action: PayloadAction<IChartDentition>) => {
                state.dentitions.saving = LoadingStatus.Completed;
                state.dentitions.data = [...state.dentitions.data, action.payload];
            })
            .addCase(createChartDentition.rejected, (state, action: PayloadAction<any>) => {
                state.dentitions.saving = LoadingStatus.Failed;
                state.dentitions.errors = action.payload;
            })
            .addCase(createChartDentitions.pending, (state) => {
                state.dentitions.savingDentitions = LoadingStatus.Pending;
            })
            .addCase(createChartDentitions.fulfilled, (state, action: PayloadAction<IChartDentition[]>) => {
                state.dentitions.savingDentitions = LoadingStatus.Completed;
                state.dentitions.data = [...state.dentitions.data, ...action.payload];
            })
            .addCase(createChartDentitions.rejected, (state, action: PayloadAction<any>) => {
                state.dentitions.savingDentitions = LoadingStatus.Failed;
                state.dentitions.errors = action.payload;
            })
            // Update chart dentition
            .addCase(updateChartDentition.pending, (state) => {
                state.dentitions.saving = LoadingStatus.Pending;
            })
            .addCase(updateChartDentition.fulfilled, (state, action: PayloadAction<IChartDentition>) => {
                state.dentitions.saving = LoadingStatus.Completed;
                const indexOfData = state.dentitions.data.findIndex((d) => d.id === action.payload.id);
                state.dentitions.data[indexOfData] = action.payload;
            })
            .addCase(updateChartDentition.rejected, (state, action: PayloadAction<any>) => {
                state.dentitions.saving = LoadingStatus.Failed;
                state.dentitions.errors = action.payload;
            })
            .addCase(updateChartDentitions.pending, (state) => {
                state.dentitions.saving = LoadingStatus.Pending;
            })
            .addCase(updateChartDentitions.fulfilled, (state, action: PayloadAction<IChartDentition[]>) => {
                state.dentitions.saving = LoadingStatus.Completed;
                action.payload.forEach((dentition) => {
                    const indexOfData = state.dentitions.data.findIndex((d) => d.id === dentition.id);
                    state.dentitions.data[indexOfData] = dentition;
                });
            })
            .addCase(updateChartDentitions.rejected, (state, action: PayloadAction<any>) => {
                state.dentitions.saving = LoadingStatus.Failed;
                state.dentitions.errors = action.payload;
            })
    );
};

export default dentitionReducers;
