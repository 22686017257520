import { IButtonStyles, IconButton, IContextualMenuItem, Link, MessageBar, SelectionMode, Stack, Text } from '@fluentui/react';
import { Section, SortableDetailsList } from 'components';
import forms, { formsList } from 'forms/forms';
import { usePatientId, useSelector, useTenantId } from 'hooks';
import useForms from 'hooks/store/useForms';
import IForm, { FormName, FormStatus } from 'api/models/form';
import dateOnly, { calculateAgeInYears } from 'utils/dateOnly';
import UserTasksBadge from 'components/UserTasks/UserTasksBadge';
import { useEffect } from 'react';
import useEncounterId from 'hooks/useEncounterId';
import { useDispatch } from 'react-redux';
import { formSyncing } from 'state/slices/forms/forms.selectors';
import { selectPatientOverviewQuestionnaireTasks } from 'state/slices/patient/overview/overview.selectors';
import { fetchPatientCRA } from 'state/slices/patient/patient.actions';

function Questionnaires(): JSX.Element {
    const { setNewForm, patientFormsAsList, getPatientFormById } = useForms();

    const patient = useSelector((state) => state.patient);
    const syncing = useSelector(formSyncing);
    const questionnaireTasks = useSelector(selectPatientOverviewQuestionnaireTasks);

    const encounterId = useEncounterId();
    const tenantId = useTenantId();
    const patientId = usePatientId();
    const dispatch = useDispatch();

    const patientAge = calculateAgeInYears(patient.selectedPatient?.dateOfBirth);
    const patientIsChild = patientAge <= 6;

    useEffect(() => {
        if (encounterId && patientId) {
            dispatch(fetchPatientCRA({ tenantId, encounterId, patientId }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [encounterId, syncing]);

    const menuItems: IContextualMenuItem[] = formsList
        .filter((f) => {
            if (patientIsChild && f.formName === FormName.CariesRiskAdult) return false;
            if (!patientIsChild && f.formName === FormName.CariesRiskChild) return false;
            if (f.formName === FormName.SoftTissueExam) return false;
            if (f.formName === FormName.PatientProblem) return false;
            return true;
        })
        .map((f) => ({
            key: f.formName,
            text: f.title,
            onClick: () => setNewForm(f.formName),
        }));

    const menuButtonStyles: IButtonStyles = {
        root: {
            height: 25,
            maxWidth: 25,
        },
        flexContainer: {
            selectors: {
                '.ms-Button-menuIcon': {
                    display: 'none',
                },
            },
        },
    };

    const _renderDate = (date?: string) => {
        if (date) {
            return <Text variant="mediumPlus">{dateOnly(date)}</Text>;
        }
        return '';
    };

    const _renderFormName = (item?: IForm) =>
        item && forms[item.formName] ? (
            <Text variant="mediumPlus">
                <Link onClick={() => getPatientFormById(item.id)}>{forms[item.formName]?.title}</Link>
            </Text>
        ) : (
            <Text variant="mediumPlus">{'Uknown Form'}</Text>
        );

    const _renderFormStatus = (item?: IForm, form?: FormStatus, names?: FormName) => {
        if (item && form) {
            if (
                item.status === FormStatus.Completed &&
                (names === FormName.CariesRiskAdult || names === FormName.CariesRiskChild)
            ) {
                return (
                    <Text variant="mediumPlus">
                        {item.status} - {item.data.score} Risk
                    </Text>
                );
            } else {
                return <Text variant="mediumPlus">{item.status}</Text>;
            }
        }
    };

    return (
        <Section
            heading={
                <Stack tokens={{ childrenGap: 10 }} horizontal grow>
                    <Stack horizontal tokens={{ childrenGap: 5 }} verticalAlign="center">
                        <Text variant="xLarge">Questionnaires</Text>
                        {questionnaireTasks.length ? (
                            <UserTasksBadge
                                size="small"
                                showTaskCount={false}
                                style={{ paddingBottom: 2 }}
                                userTasks={questionnaireTasks}
                            />
                        ) : null}
                    </Stack>
                    {encounterId && (
                        <IconButton styles={menuButtonStyles} iconProps={{ iconName: 'Add' }} menuProps={{ items: menuItems }} />
                    )}
                </Stack>
            }
        >
            {patientFormsAsList.length ? (
                <SortableDetailsList<IForm>
                    selectionMode={SelectionMode.none}
                    compact
                    columns={[
                        {
                            key: 'createdOn',
                            name: 'Created Date',
                            minWidth: 100,
                            maxWidth: 100,
                            fieldName: 'createdOn',
                            onRender: (item) => (item ? _renderDate(item.createdOn) : null),
                        },
                        {
                            key: 'formName',
                            name: 'Form',
                            minWidth: 250,
                            maxWidth: 250,
                            fieldName: 'formName',
                            onRender: _renderFormName,
                        },
                        {
                            key: 'status',
                            name: 'Status',
                            minWidth: 200,
                            maxWidth: 200,
                            fieldName: 'status',
                            onRender: (item) => (item ? _renderFormStatus(item, item.status, item.formName) : null),
                        },
                        {
                            key: 'completedDate',
                            name: 'Completed Date',
                            minWidth: 200,
                            fieldName: 'completedDate',
                            onRender: (item) => (item ? _renderDate(item.completedDate) : null),
                        },
                    ]}
                    onItemInvoked={(item) => {
                        if (item) forms[item.formName] ? getPatientFormById(item.id) : undefined;
                    }}
                    items={patientFormsAsList}
                    sortOnMount={true}
                    sortColumns={['createdOn']}
                />
            ) : (
                <MessageBar>No forms have been added.</MessageBar>
            )}
        </Section>
    );
}

export default Questionnaires;
