import { AnyAction, combineReducers } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { ThunkAction } from 'redux-thunk';
import {
    ActionReducerMapBuilder,
    CaseReducer,
    configureStore,
    PayloadActionCreator,
    SliceCaseReducers,
    ValidateSliceCaseReducers,
} from '@reduxjs/toolkit';
import * as reducersFromSlices from './slices';
import * as middleware from './middleware';

import { baseApi } from 'state/api/baseApi';
import { TypedActionCreator } from '@reduxjs/toolkit/dist/mapBuilders';

export const history = createBrowserHistory();

const rootReducer = combineReducers({
    ...reducersFromSlices,
    router: connectRouter(history),
    [baseApi.reducerPath]: baseApi.reducer,
});

export const setupStore = (preloadedState?: Partial<RootState>) => {
    return configureStore({
        reducer: rootReducer,
        preloadedState,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false,
            })
                .concat(middleware.localStorageMiddleware)
                .concat(routerMiddleware(history))
                .concat(baseApi.middleware),
        devTools: process.env.NODE_ENV === 'development',
    });
}

const store = setupStore();

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;
export type CaseReducerFunction<S, T extends TypedActionCreator<string> = PayloadActionCreator<any | undefined>> = CaseReducer<
    S,
    ReturnType<T>
>;
export type ExtraReducer<T> = (builder: ActionReducerMapBuilder<T>) => ActionReducerMapBuilder<T>;
export type ReducerObject<T> = ValidateSliceCaseReducers<T, SliceCaseReducers<T>>;
export type CreateAsyncThunkOptions<RejectValueType = string> = {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: RejectValueType;
};

export default store;
