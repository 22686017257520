import { Text } from '@fluentui/react';
import { IBillingProcedure } from 'api/models/billing-procedure.model';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import { getTeethDisplayName } from 'utils';

const tooth: ISortableColumn<IBillingProcedure> = {
    key: 'tooth',
    name: 'Tooth',
    minWidth: 60,
    maxWidth: 100,
    isResizable: true,
    onRender: (item) => {
        if (item && item.toothIds) {
            const teethDisplayName = getTeethDisplayName(item.toothIds);
            return (
                <Text title={teethDisplayName} variant="smallPlus">
                    {teethDisplayName}
                </Text>
            );
        }
    },
};

export default tooth;
