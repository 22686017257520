import { MessageBar, MessageBarType, Spinner, Stack, Text, Toggle } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    insertContact,
    selectAllPatientContacts,
    selectContactsLoading,
} from 'state/slices/contacts/contacts.slice';
import SubSection from 'components/SubSection';
import { SignalRMessage, useSignalR } from 'hooks/signalr/useSignalr';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { selectEditPatient } from 'state/slices/edit-patient/edit-patient.selectors';
import ContactCards from './components/ContactCards';
import ContactModal from './components/ContactModal';
import { useSelector } from 'hooks';

function Contacts(): JSX.Element {
    const contacts = useSelector(selectAllPatientContacts);
    const loading = useSelector(selectContactsLoading);
    const patient = useSelector(selectEditPatient);

    const isLoading = loading === LoadingStatus.Pending;

    const dispatch = useDispatch();
    const [showActiveOnly, setShowActiveOnly] = useState(true);

    const { registerSignalRMessage } = useSignalR();

    useEffect(() => {
        registerSignalRMessage(SignalRMessage.UpdatedPatientContact, (data) => {
            if (patient) dispatch(insertContact(data, patient.id));
        });
    }, [dispatch, patient, registerSignalRMessage]);

    return (
        <SubSection
            title="Contacts"
            headingCenterContent={
                <Stack horizontal verticalAlign="center" style={{ justifyContent: 'flex-end', width: '100%' }} grow>
                    <Stack horizontal verticalAlign="center">
                        <Text variant="medium" styles={{ root: { fontWeight: 'bold', marginRight: 8 } }}>
                            Active Only
                        </Text>
                        <Toggle checked={showActiveOnly} onChange={() => setShowActiveOnly((prev) => !prev)} />
                    </Stack>
                </Stack>
            }
        >
            {contacts && contacts.every((contact) => !contact.isGuarantor) && (
                <Stack tokens={{ childrenGap: 2 }}>
                    <MessageBar messageBarType={MessageBarType.warning}>
                        Patient is guarantor until guarantor is assigned.
                    </MessageBar>
                </Stack>
            )}
            <MessageBar>Updates to the patient guarantor or emergency contact must be done in athenaOne.</MessageBar>
            {isLoading ? (
                <Spinner label="Loading patient..." labelPosition="right" styles={{ label: { color: '#FFF' } }} />
            ) : (
                <ContactCards showActiveOnly={showActiveOnly} />
            )}
            <ContactModal  />
        </SubSection>
    );
}

export default Contacts;
