import { IEmbedReportModel } from 'api/models/embed-report.model';
import { IPerioExam, SurfacesNumber } from 'api/models/perio-exam.model';
import { LoadingStatus, LoadingStatuses } from 'interfaces/loading-statuses';
import { ChartEndpoint } from '../chart/chart.types';
import { ArchType } from './perio-exams.selectors';

export const chartPerioExamsInitialState: ChartPerioExamsState = {
    data: [],
    loading: LoadingStatus.Idle,
    saving: LoadingStatus.Idle,
    selectedArchType: 'Maxillary',
    modified: false,
    historySearch: '',
    printExam: { loading: LoadingStatus.Idle },
};

export interface ChartPerioExamsState extends ChartEndpoint<IPerioExam> {
    currentPerioExam?: IPerioExam;
    selectedPerioExam?: string;
    selectedPerioDepthFieldData?: ISelectedPerioDepthField;
    selectedArchType: ArchType;
    modified: boolean;
    historySearch: string | undefined;
    printExam: IPrintPerioExam;
}

export interface ISelectedPerioDepthField {
    facialLingual: string;
    toothId: number;
    surface: keyof SurfacesNumber;
}

export interface IPrintPerioExam {
    mrn?: string;
    dates?: string[];
    loading: LoadingStatuses;
    data?: IEmbedReportModel;
}
