import { createAsyncThunk, createSelector, createSlice, Dictionary } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import { IDiagnosis } from 'api/models/diagnosis.model';
import { AxiosError, AxiosResponse } from 'axios';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { LookupState } from 'interfaces/lookup-state';
import { RootState } from 'state/store';
import { map, orderBy } from 'lodash';
import { IComboBoxOption } from '@fluentui/react';
import { ChartProcedureDiagnosis } from 'api/models/chart.model';

type DiagnosesState = LookupState<IDiagnosis>;

const initialState: DiagnosesState = {
    initialLoad: LoadingStatus.Idle,
    loading: LoadingStatus.Idle,
    data: {},
};

export const getDiagnoses = createAsyncThunk<
    AxiosResponse<Dictionary<IDiagnosis>>,
    {
        tenantId: string;
    },
    {
        rejectValue: AxiosError;
    }
>('getDiagnoses', async () => {
    const res = await dentalApi.getDiagnoses();
    return res;
});

const diagnosesSlice = createSlice({
    name: 'diagnoses',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDiagnoses.pending, (state) => {
                state.initialLoad = LoadingStatus.Pending;
            })
            .addCase(getDiagnoses.fulfilled, (state, action) => {
                state.initialLoad = LoadingStatus.Completed;
                state.data = action.payload.data;
            })
            .addCase(getDiagnoses.rejected, (state, action) => {
                state.initialLoad = LoadingStatus.Failed;
                state.errors = action.payload?.message;
            });
    },
});

const { reducer } = diagnosesSlice;

export const selectDiagnoses = (state: RootState): Dictionary<IDiagnosis> => state.tenant.diagnoses.data;
export const diagnosesList = createSelector(selectDiagnoses, (data) =>
    data ? (map(data, (dx) => dx) as IDiagnosis[]).filter((dx) => !dx.isDeleted) : [],
);
export const diagnosesOptionsList = createSelector(diagnosesList, (diagnosesList) =>
    orderBy(
        diagnosesList.map((dx) => ({
            key: dx?.id ?? '',
            text: `${dx.code} - ${dx.displayName.length > 35 ? `${dx.displayName.slice(0, 35)}...` : dx.displayName}`,
            title: `${dx.code} - ${dx.displayName}`,
            data: { id: dx.id, displayName: dx.code, description: dx.description } as ChartProcedureDiagnosis,
        })) as IComboBoxOption[],
        (option) => option.title,
    ),
);

export default reducer;
