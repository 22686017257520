import {
    DefaultButton,
    IButtonStyles,
    IconButton,
    IContextualMenuItem,
    IPanelProps,
    Panel,
    PanelType,
    SearchBox,
    SelectionMode,
    Spinner,
    Stack,
    Text,
    Toggle,
    TooltipHost,
} from '@fluentui/react';
import { IBatch } from 'api/models/batch.model';
import { ReportType } from 'api/models/embed-report.model';
import { Field, SortableDetailsList } from 'components';
import { EditDetailsColumn } from 'components/EditDetailsColumn';
import { useTenantId } from 'hooks';
import { useFuseSearch } from 'hooks/useFuseSearch';
import { Cookies } from 'interfaces/cookies';
import ReportModal from 'pages/Reporting/ReportModal';

import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccountData } from 'state/slices/account.slice';
import reportActionLookup from 'state/slices/reporting/reportActionLookup';
import {
    batchActions,
    filterBatchList,
    getBatches,
    selectBatchesFilters,
    selectBatchesFiltersDisabled,
    selectBatchesLoading,
    toggleActiveBatches,
} from 'state/slices/tenant/batches.slice';
import { BatchModal } from './BatchModal';
import UserDisplayName from 'components/UserDisplayName';
import usePermissions, { DentalPermissions } from 'hooks/store/usePermissions';
import { format } from 'date-fns';
import BatchHardCloseDate from './BatchHardCloseDate';

const midnight = new Date();
midnight.setHours(23, 59, 59, 0);
export const batchCookieOptions = { path: '/', expires: midnight };

export function BatchPanel({ isOpen, onDismiss, onDismissed, ...props }: IPanelProps): JSX.Element {
    const dispatch = useDispatch();
    const tenantId = useTenantId();

    const batches = useSelector(filterBatchList);
    const batchesFiltersDisabled = useSelector(selectBatchesFiltersDisabled);
    const isActiveToggle = useSelector(toggleActiveBatches);
    const loading = useSelector(selectBatchesLoading);
    const filters = useSelector(selectBatchesFilters);
    const account = useSelector(selectAccountData);
    const [canReopenBatches] = usePermissions([DentalPermissions.ReopenBatches]);

    const [cookies, setCookies] = useCookies([Cookies.SelectedBatch]);

    const { results, onSearch, search, onSearchChange } = useFuseSearch<IBatch>({
        fuseOptions: { keys: ['displayName'] },
        list: batches,
    });

    useEffect(() => {
        if (isOpen) {
            dispatch(getBatches(tenantId));
        }
    }, [isOpen, dispatch, tenantId]);

    const _createBatch = () => dispatch(batchActions.createNewBatch(`${account?.identity?.lastName}_${format(new Date(), 'P')}`));

    const _updateBatch = (batch?: IBatch) => dispatch(batchActions.setSelectedModalBatch({ batch, modalState: 'Update' }));
    const _closeBatch = (batch?: IBatch) => dispatch(batchActions.setSelectedModalBatch({ batch, modalState: 'Close' }));
    const _reopenBatch = (batch?: IBatch) => dispatch(batchActions.setSelectedModalBatch({ batch, modalState: 'Reopen' }));

    const _onDismissed = () => {
        dispatch(batchActions.cleanupBatchFilters());
        dispatch(batchActions.setDisableBatchesFilters(false));
        if (onDismissed) onDismissed();
    };

    const selectBatch = (batch?: IBatch) => {
        setCookies(Cookies.SelectedBatch, batch?.id, batchCookieOptions);
        if (onDismiss) onDismiss();
    };

    const disableAthenaBatches = (item: IBatch) => {
        if (item.references && Object.keys(item.references).length > 0) {
            return true;
        }
        return false;
    };

    const cardMenuItems = (item: IBatch) => {
        const defaultBatch = cookies[Cookies.SelectedBatch] === item?.id;
        const closeBatch = item?.status === 'Closed';
        const isHardClosed = item?.status === 'Hard-Closed'; // Assuming 'Hard-Closed' is the status
        const menuItems: IContextualMenuItem[] = [
            {
                key: 'selectedBatch',
                disabled: isHardClosed || defaultBatch || closeBatch || disableAthenaBatches(item),
                text: defaultBatch ? 'Selected' : 'Use batch',
                onClick: () => {
                    if (item) selectBatch(item);
                },
            },
            {
                key: 'close',
                text: 'Close',
                disabled: isHardClosed || closeBatch || disableAthenaBatches(item),
                onClick: () => {
                    if (item) _closeBatch(item);
                },
            },
            {
                key: 'print',
                text: 'Print',
                onClick: () => {
                    if (item)
                        dispatch(
                            reportActionLookup[ReportType.SpecificBatch]({
                                tenantId,
                                batchId: item.id,
                            }),
                        );
                },
            },
            {
                key: 'reopen',
                text: 'Reopen',
                disabled: !canReopenBatches || !closeBatch || isHardClosed || disableAthenaBatches(item),
                onClick: () => {
                    if (item) _reopenBatch(item);
                },
            },
        ];

        return menuItems;
    };

    const menuButtonStyles: IButtonStyles = {
        flexContainer: {
            selectors: {
                '.ms-Button-menuIcon': {
                    display: 'none',
                },
            },
        },
    };

    return (
        <>
            <ReportModal />
            <Panel
                headerText="Manage Batches"
                isOpen={isOpen}
                type={PanelType.custom}
                onDismiss={onDismiss}
                customWidth="800px"
                isLightDismiss={false}
                {...props}
                onDismissed={_onDismissed}
            >
                <Stack tokens={{ childrenGap: 10 }}>
                    <BatchHardCloseDate />
                    <Stack verticalAlign="end" horizontal tokens={{ childrenGap: 10 }}>
                        <Stack verticalAlign="end" horizontal tokens={{ childrenGap: 5 }} grow>
                            <Stack.Item grow>
                                <SearchBox
                                    title="Search Batches"
                                    value={search}
                                    onChange={onSearchChange}
                                    onSearch={onSearch}
                                    placeholder="Search batches by name..."
                                />
                            </Stack.Item>
                            <Stack.Item grow>
                                <Field.Date
                                    value={filters.dateOfEntry ?? ''}
                                    onChange={(ev, value) => {
                                        dispatch(batchActions.setBatchFilters({ path: 'dateOfEntry', value: value }));
                                    }}
                                    hasDatePicker
                                    label="Search by Date"
                                    disabled={batchesFiltersDisabled}
                                />
                            </Stack.Item>
                        </Stack>
                        <DefaultButton
                            title="Create batch"
                            iconProps={{ iconName: 'Add' }}
                            text="Create batch"
                            onClick={_createBatch}
                        />
                        <Toggle
                            label="Active Only"
                            inlineLabel
                            styles={{ root: { marginBottom: 0 } }}
                            checked={!isActiveToggle}
                            onChange={() => dispatch(batchActions.toggleActiveBatches())}
                        />
                    </Stack>
                    {loading ? (
                        <Spinner label="Loading batches..." style={{ marginTop: 10 }} />
                    ) : (
                        <SortableDetailsList<IBatch>
                            selectionMode={SelectionMode.none}
                            sortColumns={['dateOfEntry']}
                            initialSortDirection="desc"
                            sortOnMount={true}
                            columns={[
                                {
                                    key: 'dateOfEntry',
                                    minWidth: 80,
                                    maxWidth: 80,
                                    fieldName: 'dateOfEntry',
                                    name: 'Date',
                                    onRender: (item) => {
                                        if (item) {
                                            return <Text variant="smallPlus">{format(new Date(item.dateOfEntry), 'P')}</Text>;
                                        }
                                    },
                                },
                                {
                                    key: 'displayName',
                                    minWidth: 100,
                                    fieldName: 'displayName',
                                    name: 'Name',
                                    onRender: (item) => {
                                        const isSelectedBatch = cookies[Cookies.SelectedBatch] === item?.id;
                                        const titleText = isSelectedBatch
                                            ? `${item?.displayName} (Selected) `
                                            : item?.displayName;
                                        if (item) {
                                            return (
                                                <EditDetailsColumn
                                                    title={titleText ?? ''}
                                                    titleProps={{ variant: 'small' }}
                                                    disabled={item.status === 'Hard-Closed'}
                                                    editOnClick={() => {
                                                        _updateBatch(item);
                                                    }}
                                                />
                                            );
                                        }
                                    },
                                },
                                {
                                    key: 'createdBy',
                                    minWidth: 150,
                                    fieldName: 'createdBy',
                                    name: 'Created By',
                                    onRender: (item) => <UserDisplayName userId={item?.createdBy} />,
                                },
                                { key: 'status', minWidth: 80, fieldName: 'status', name: 'Status' },

                                {
                                    key: 'Actions',
                                    minWidth: 80,
                                    fieldName: 'closeBatch',
                                    name: 'Actions',

                                    onRender: (item) => {
                                        return (
                                            <Stack>
                                                <TooltipHost>
                                                    <IconButton
                                                        iconProps={{ iconName: 'MoreVertical' }}
                                                        styles={menuButtonStyles}
                                                        menuProps={{ items: item ? cardMenuItems(item) : [] }}
                                                    />
                                                </TooltipHost>
                                            </Stack>
                                        );
                                    },
                                },
                            ]}
                            items={results && search ? results : batches}
                        />
                    )}
                    <BatchModal />
                </Stack>
            </Panel>
        </>
    );
}
