import { IconButton } from '@fluentui/react';
import IWorkListAppointmentView from 'api/models/worklist-appointment-view';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import { useDispatch } from 'react-redux';
import { setEditPredeterminationModalOpen } from 'state/slices/admin-huddle/worklists/predeterminations/predetermination.slice';
import { setSelectedWorkListEditItem } from 'state/slices/admin-huddle/worklists/worklist.slice';

const editAuthorizationColumn: ISortableColumn<IWorkListAppointmentView> = {
    key: 'editAuthorization',
    minWidth: 20,
    maxWidth: 20,
    isResizable: true,
    name: '',
    onRender: EditAuthorizationColumnButton,
};

export default editAuthorizationColumn;

function EditAuthorizationColumnButton(item: IWorkListAppointmentView | undefined) {
    const dispatch = useDispatch();

    if (!item) return null;

    return (
        <IconButton
            title='Edit Authorization'
            styles={{ root: { height: 20, width: 20 }, menuIcon: { fontWeight: 'bolder !important' } }}
            onClick={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                dispatch(setSelectedWorkListEditItem(item));
                dispatch(setEditPredeterminationModalOpen(true));
            }}
            iconProps={{ iconName: 'Edit' }}
        />
    );
}
