import { DefaultButton, Panel, PanelType, PrimaryButton, ProgressIndicator, Stack } from '@fluentui/react';
import IBlockAppointment from 'api/models/Scheduling/blockAppointment.model';
import IPatientAppointment from 'api/models/Scheduling/patientAppointment.model';
import { useSelector, useValidation } from 'hooks';
import { RouteParams } from 'interfaces/route-params';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    selectAppointmentType,
    selectIsScheduleAppointmentPanelOpen,
    selectPreviousAppointmentData,
    selectSelectedAppointmentPatient,
} from 'state/slices/scheduling/scheduling.selectors';
import { getChartTreatmentPlans, updateBlockAppointment } from 'state/slices/scheduling/scheduling.slice';
import {
    selectCurrentScheduleAppointment,
    selectCurrentScheduleAppointmentLoading,
} from 'state/slices/scheduling/schedule-appointment/schedule-appointment.selectors';

import { updateOrCreateAppointment } from 'state/slices/scheduling/scheduling.actions';

import BlockAppointmentFields from './ScheduleAppointment/BlockAppointmentFields';
import PatientAppointmentFields from './ScheduleAppointment/PatientAppointmentFields';
import DateTimeFields from './ScheduleAppointment/DateTimeFields';
import ScheduleApptPatientCard from './ScheduleAppointment/ScheduleApptPatientCard';
import { AppointmentType } from 'state/slices/scheduling/scheduling.state';
import { selectCurrentScheduleAppointmentValidationErrors } from 'state/slices/scheduling/schedule-appointment/schedule-appointment.selectors';
import { ValidationBar } from 'components';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { IValidationConfig } from 'hooks/useValidation';
type Props = {
    onDismiss: () => void;
};

function AppointmentPanel(props: Props): JSX.Element {
    const dispatch = useDispatch();
    const { tenantId } = useParams<RouteParams>();

    const appointment = useSelector(selectCurrentScheduleAppointment);
    const appointmentType = useSelector(selectAppointmentType);
    const isAppointmentPanelOpen = useSelector(selectIsScheduleAppointmentPanelOpen);
    const _selectPreviousAppointmentData = useSelector(selectPreviousAppointmentData);
    const loading = useSelector(selectCurrentScheduleAppointmentLoading) === LoadingStatus.Pending;

    const blockAppt: IBlockAppointment | undefined = appointmentType === AppointmentType.Block ? appointment : undefined;
    const patientAppt: IPatientAppointment | undefined =
        appointmentType === AppointmentType.Patient ? (appointment as IPatientAppointment) : undefined;

    const selectedAppointmentPatient = useSelector(selectSelectedAppointmentPatient);
    const _validationErrors = useSelector(selectCurrentScheduleAppointmentValidationErrors);

    useEffect(() => {
        if (isAppointmentPanelOpen && patientAppt) {
            dispatch(getChartTreatmentPlans({ tenantId, patientId: patientAppt.patientId }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAppointmentPanelOpen, patientAppt?.patientId, tenantId, dispatch]);

    const onSave = () => {
        _validationErrors.length ? null : dispatch(updateOrCreateAppointment(tenantId));
    };

    const validationConfig: IValidationConfig = [
        {
            fieldName: 'Treating Provider',
            validation: ['required'],
            value: patientAppt?.treatingProviderId,
        },
        {
            fieldName: 'Start',
            validation: ['required'],
            value: patientAppt?.startTime,
        },
        {
            fieldName: 'End',
            validation: ['required'],
            value: patientAppt?.endTime,
        },
        {
            fieldName: 'Reason For Encounter',
            validation: ['required'],
            value: patientAppt?.encounterReason,
        },
    ];

    const [errors, submit, cleanupErrors] = useValidation(validationConfig, onSave);
    const panelHeader = loading ? 'Appointment' : blockAppt ? 'Block Operatory' : 'Schedule Appointment';

    return (
        <Panel
            type={PanelType.medium}
            isOpen={isAppointmentPanelOpen}
            onDismiss={props.onDismiss}
            onDismissed={cleanupErrors}
            headerText={panelHeader}
            closeButtonAriaLabel="Close"
            onRenderFooterContent={() => <FooterContent />}
        >
            <Stack tokens={{ childrenGap: 10 }} grow>
                {loading ? (
                    <ProgressIndicator />
                ) : (
                    <>
                        {appointmentType === AppointmentType.Patient && (
                            <ScheduleApptPatientCard patient={selectedAppointmentPatient} />
                        )}
                        {_validationErrors && _validationErrors.length ? <ValidationBar errors={_validationErrors} /> : null}
                        <DateTimeFields appointment={appointment} errors={errors} />
                        <BlockAppointmentFields blockAppt={blockAppt} />
                        <PatientAppointmentFields patientAppt={patientAppt} errors={errors} />
                    </>
                )}
            </Stack>
        </Panel>
    );

    function FooterContent(): JSX.Element {
        const cancelBlockAppointment = () => {
            const deleteAppt = window.confirm('Do you want to cancel this appointment?');
            if (deleteAppt) {
                if (appointment) {
                    const updatedAppt: IBlockAppointment = { ...appointment, isDeleted: true };
                    dispatch(updateBlockAppointment({ tenantId, appointment: updatedAppt }));
                }
            }
        };

        return (
            <Stack tokens={{ childrenGap: 10 }} horizontal horizontalAlign="space-between">
                <Stack.Item>
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <PrimaryButton
                            text={_selectPreviousAppointmentData ? 'Save and Return to Checkout' : 'Save'}
                            onClick={appointmentType === AppointmentType.Patient ? submit : onSave}
                        />
                        <DefaultButton
                            text={_selectPreviousAppointmentData ? 'Discard and Return to Checkout' : 'Discard Changes'}
                            onClick={props.onDismiss}
                        />
                    </Stack>
                </Stack.Item>
                {blockAppt && blockAppt._etag && (
                    <Stack.Item>
                        <DefaultButton text="Cancel Block" onClick={cancelBlockAppointment} />
                    </Stack.Item>
                )}
            </Stack>
        );
    }
}

export default AppointmentPanel;
