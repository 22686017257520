import { Dictionary } from '@reduxjs/toolkit';
import IPatientAllergies, { IAllergen, IAllergy, IAllergyReaction } from 'api/models/patient-allergy.model';
import { LoadingStatus, LoadingStatuses } from 'interfaces/loading-statuses';

export type AllergiesState = {
    data?: IPatientAllergies;
    loading: LoadingStatuses;
    saving: LoadingStatuses;
    error?: unknown;
    isAllergyPanelOpen: boolean;
    showHistory: boolean;

    loadingLookups: LoadingStatuses;
    allergyReactions: Dictionary<IAllergyReaction>;
    allergiesToAdd: IAllergy[];
};

export const initalAllergiesState: AllergiesState = {
    loading: LoadingStatus.Idle,
    saving: LoadingStatus.Idle,
    isAllergyPanelOpen: false,
    showHistory: false,
    loadingLookups: LoadingStatus.Idle,
    allergyReactions: {},
    allergiesToAdd: [],
};
