import { DefaultButton, IStackTokens, PrimaryButton, Stack, StackItem } from '@fluentui/react';
import { FormStatus } from 'api/models/form';
import { useEncounter } from 'hooks';
import useForms from 'hooks/store/useForms';
import { SyncStatus } from 'interfaces/syncing-statuses';
import { DefaultFormPanelFooter } from 'pages/components/FormPanel/FormPanel';

export default function DentalHistoryFooter(): JSX.Element | null {
    const {
        currentlySelectedForm,
        syncing,
        cleanupSelectedForm,
        createFormFromSelectedForm,
        setEditingCompletedForm,
        editingCompletedForm,
    } = useForms();
    const { patientEncounter } = useEncounter();

    const isCompleted = currentlySelectedForm?.completedDate ? true : false;

    const _onMarkAsReviewed = () => {
        createFormFromSelectedForm(FormStatus.Reviewed, true);
    };

    const _onDismiss = () => {
        cleanupSelectedForm();
    };

    const disableMarkAsReviewed =
        syncing === SyncStatus.Pending || currentlySelectedForm?.status === FormStatus.Reviewed || !patientEncounter;

    const stackTokens: IStackTokens = { childrenGap: 10 };

    return (
        <>
            {isCompleted && !editingCompletedForm ? (
                <Stack tokens={stackTokens} grow horizontal>
                    <Stack tokens={stackTokens} grow horizontal>
                        <PrimaryButton text="Mark as Reviewed" onClick={_onMarkAsReviewed} disabled={disableMarkAsReviewed} />
                        <DefaultButton
                            text="Update History"
                            onClick={() => {
                                createFormFromSelectedForm(FormStatus.Completed);
                                setEditingCompletedForm(true);
                            }}
                            disabled={!patientEncounter || syncing === SyncStatus.Pending}
                        />
                    </Stack>
                    <StackItem>
                        <DefaultButton text="Close" onClick={_onDismiss} disabled={!patientEncounter} />
                    </StackItem>
                </Stack>
            ) : (
                <DefaultFormPanelFooter />
            )}
        </>
    );
}
