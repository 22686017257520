import { ChoiceGroup, IChoiceGroupOption, Stack, Text, TextField, useTheme } from '@fluentui/react';
import { PaymentMethod } from 'api/models/patient.model';
import { getValidationError, IValidationError } from 'hooks/useValidation';
import { FormEvent, useCallback } from 'react';

type Props = {
    selectedKey?: string;
    methodIdentifier?: string;
    onChange?: (event?: FormEvent, option?: IChoiceGroupOption) => void;
    onChangeIdentifier?: (event?: FormEvent, value?: string) => void;
    errors?: IValidationError[];
};

export function shouldHideMethodIdentifier(method: string | undefined) {
    return method === undefined || method === PaymentMethod.Cash;
}

export function getPaymentMethodCheckOrAuth(method: string | undefined) {
    return method === PaymentMethod.Check ? 'Check' : 'Auth';
}

export default function PaymentMethods({ selectedKey, onChange, onChangeIdentifier, errors, methodIdentifier }: Props) {
    const { palette } = useTheme();

    const _shouldHideMethodIdentifier = useCallback(() => {
        return shouldHideMethodIdentifier(selectedKey);
    }, [selectedKey]);

    const _getPaymentMethodCheckOrAuth = useCallback(() => {
        return getPaymentMethodCheckOrAuth(selectedKey);
    }, [selectedKey]);

    const _onChange = (event?: FormEvent, option?: IChoiceGroupOption) => {
        if (onChange) onChange(event, option);
    };
    const _onChangeIdentifier = (event?: FormEvent, value?: string) => {
        if (onChangeIdentifier) onChangeIdentifier(event, value);
    };
    const paymentMethodStyles = {
        root: {
            display: 'inline-flex',
            paddingLeft: 10,
            marginTop: 0,
        },
    };

    return (
        <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Stack>
                <ChoiceGroup
                    label="Payment Method"
                    required
                    selectedKey={selectedKey}
                    onChange={_onChange}
                    options={[
                        {
                            key: PaymentMethod.Visa,
                            text: PaymentMethod.Visa,
                            styles: {
                                root: {
                                    display: 'inline-flex',
                                    marginTop: 0,
                                },
                            },
                        },
                        {
                            key: PaymentMethod.Mastercard,
                            text: PaymentMethod.Mastercard,
                            styles: paymentMethodStyles,
                        },
                        {
                            key: PaymentMethod.AmEx,
                            text: PaymentMethod.AmEx,
                            styles: paymentMethodStyles,
                        },
                        {
                            key: PaymentMethod.Discover,
                            text: PaymentMethod.Discover,
                            styles: paymentMethodStyles,
                        },
                        {
                            key: PaymentMethod.Cash,
                            text: PaymentMethod.Cash,
                            styles: paymentMethodStyles,
                        },
                        {
                            key: PaymentMethod.Check,
                            text: 'Check/ACH',
                            styles: paymentMethodStyles,
                        },
                        {
                            key: PaymentMethod.Credit,
                            text: PaymentMethod.Credit,
                            styles: paymentMethodStyles,
                        },
                    ]}
                />
                {getValidationError(errors ?? [], 'Payment Method') ? (
                    <Text variant="small" style={{ color: palette.redDark }}>
                        Payment Method is required.
                    </Text>
                ) : null}
            </Stack>
            {!_shouldHideMethodIdentifier() && (
                <Stack grow>
                    <TextField
                        required
                        label={_getPaymentMethodCheckOrAuth()}
                        prefix="#"
                        value={methodIdentifier}
                        onChange={_onChangeIdentifier}
                        errorMessage={
                            getValidationError(errors ?? [], _getPaymentMethodCheckOrAuth())
                                ? `${_getPaymentMethodCheckOrAuth()} is required.`
                                : undefined
                        }
                    />
                </Stack>
            )}
        </Stack>
    );
}
