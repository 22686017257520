import { Dropdown, IDropdownOption, PrimaryButton, Stack, Text } from '@fluentui/react';
import { DocumentContext } from 'api/models/document.model';
import IPatient, { IPatientUDS, IPovertyPercentage } from 'api/models/patient.model';
import { ContentCard, Section, SubSection } from 'components';
import Upload from 'components/Upload';
import { useSelector } from 'hooks';
import TaskFieldLabel from 'pages/Scheduling/components/Checkin/TaskFieldLabel';
import { useEffect, useRef } from 'react';
import { batch, useDispatch } from 'react-redux';
import { selectEditPatient } from 'state/slices/edit-patient/edit-patient.selectors';
import { editPatientPropChange, editPatientUDSPropChange } from 'state/slices/edit-patient/edit-patient.slice';
import { TaskType } from 'state/task-management/taskManagement.actions';
import { selectFinanceInfoByTaskTypeLookup } from 'state/task-management/taskManagement.slice';
import PatientIncomePercent from './Financial/components/PatientIncomePercent';
import SlidingFee from './Financial/components/SlidingFee';
import { usdCurrencyFormatter } from 'utils';
import { payPeriodsList } from 'state/slices/tenant/payPeriod.slice';

export type UDSProps = {
    showDocuments?: boolean;
};

export enum HouseholdIncomeFields {
    Income = 'Income',
    FamilySize = 'Family Size',
}

function Finance({ showDocuments }: UDSProps): JSX.Element | null {
    const patient = useSelector(selectEditPatient);

    const dispatch = useDispatch();

    const _selectFinanceInfoByTaskTypeLookup = useSelector(selectFinanceInfoByTaskTypeLookup);
    const updateDate = new Date().toISOString();

    const hasPercentOfPoverty = patient?.uds?.incomePercentage !== undefined && patient?.uds?.incomePercentage < 200;

    /**
     * We need to determine whether or not to show the user task badge on a field based on the existence of a task type
     * Probably don't want to maintain the code in each comp.
     *
     * Maybe a selector that returns an object that is keyed off of the user task type.
     *
     * udsByTaskType[TaskType.BLAH]
     */
    const isFirstRun = useRef(true);
    useEffect(
        () => {
            if (isFirstRun.current) {
                return;
            }
            if (patient) {
                if (patient?.uds?.incomePercentage && patient?.uds?.incomePercentage > 200) {
                    _onPropChange('slidingFees', []);
                }
            }
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [patient?.uds?.slidingFeeIncomeStatus, patient?.uds?.incomePercentage],
    );

    const _onPropChange = (key: keyof IPatient, value: unknown) => {
        dispatch(editPatientPropChange({ key, value }));
    };

    useEffect(
        () => {
            if (isFirstRun.current) {
                return;
            }
            if (patient) {
                if (patient.uds?.familySize === 0 && patient.uds?.income === 0) {
                    _updatePatientUDSProps('familySize', undefined);
                    _updatePatientUDSProps('familySizeLastUpdated', updateDate);
                    _updatePatientUDSProps('incomePercentage', undefined);
                    _updatePatientUDSProps('incomeLastUpdated', updateDate);
                    _updatePatientUDSProps('income', undefined);
                }
                if (patient.uds?.income === 0) {
                    _updatePatientUDSProps('incomePercentage', 0);
                }
            }
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [patient?.uds?.familySize, patient?.uds?.income],
    );

    useEffect(
        () => {
            if (isFirstRun.current) {
                isFirstRun.current = false;
                return;
            }
            if (patient) {
                if (patient?.uds?.incomePercentage && patient?.uds?.incomePercentage > 200) {
                    _onPropChange('slidingFees', []);
                }
            }
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [patient?.uds?.slidingFeeIncomeStatus, patient?.uds?.incomePercentage],
    );

    const _updatePatientUDSProps = (key: keyof IPatientUDS, value: string | number | boolean | string[] | undefined) => {
        dispatch(editPatientUDSPropChange({ key, value }));
    };

    const options: IDropdownOption[] = [
        { key: 'Declined', text: 'Patient declined to provide income information' },

        { key: 'Slide', text: 'Patient requests & provides proper documentation to apply for sliding fee scale discount' },
    ];

    const onIncomeChange = () => {
        if (patient?.uds?.slidingFeeIncomeStatus === 'Slide') {
            return (
                <Stack>
                    <PatientIncomePercent />
                    {hasPercentOfPoverty && <SlidingFee />}
                </Stack>
            );
        }
        return null;
    };

    const onChange = (e?: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
        _updatePatientUDSProps('slidingFeeIncomeStatus', item?.key);
        if (item?.key === 'Declined') {
            _updatePatientUDSProps('familySizeLastUpdated', updateDate);
            _updatePatientUDSProps('incomeLastUpdated', updateDate);

            _updatePatientUDSProps('familySizeDeclined', true);
            _onPropChange('slidingFees', []);
        }

        if (item?.key !== 'Declined') {
            _updatePatientUDSProps('familySizeDeclined', false);
        }
    };

    const getIncomeReference = () => {
        _updatePatientUDSProps('income', patient?.uds?.incomeLevelReference?.income);
        _updatePatientUDSProps('familySize', patient?.uds?.incomeLevelReference?.familySize);
        _updatePatientUDSProps('incomePayPeriod', patient?.uds?.incomeLevelReference?.incomePayPeriod);
    };
    return (
        <Section>
            <Stack tokens={{ childrenGap: 10 }}>
                <IncomeReferenceCard patient={patient} />
                <Dropdown
                    selectedKey={patient?.uds?.slidingFeeIncomeStatus}
                    label="Designate one of the following"
                    placeholder="Select"
                    onChange={onChange}
                    options={options}
                    onRenderLabel={(data) => (
                        <TaskFieldLabel
                            label={data?.label}
                            lookup={_selectFinanceInfoByTaskTypeLookup}
                            taskType={TaskType.IncomeVerificationDropdown}
                        />
                    )}
                />
                <Stack
                    styles={{
                        root: {
                            width: 200,
                        },
                    }}
                >
                    <PrimaryButton onClick={getIncomeReference}>Use Income Reference</PrimaryButton>
                </Stack>

                {onIncomeChange()}
                {showDocuments && patient && (
                    <SubSection title="Documents">
                        <Upload context={DocumentContext.HouseholdIncome} patientId={patient.id} />
                    </SubSection>
                )}
            </Stack>
        </Section>
    );
}

export default Finance;

type IncomeReferenceCardProps = {
    patient?: IPatient;
};

function IncomeReferenceCard({ patient }: IncomeReferenceCardProps) {
    const payPeriodList = useSelector(payPeriodsList);

    const getPayPeriodText = () => {
        const payPeriod = payPeriodList.find((p) => p.id === patient?.uds?.incomeLevelReference?.incomePayPeriod);
        return payPeriod?.displayName ? payPeriod?.displayName : '';
    };

    return (
        <ContentCard title="">
            <Stack tokens={{ childrenGap: 5 }}>
                <Stack>
                    <Text variant="large">Income Level Reference</Text>
                </Stack>
                <Stack>
                    <Text variant="medium">
                        Information below is taken from the patient medical record and is used for UDS Table 4 reporting. This
                        should be verified prior to adding sliding fee plan.
                    </Text>
                </Stack>

                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Stack.Item>
                        <Text variant="medium">Family Size:</Text>
                    </Stack.Item>
                    <Stack.Item>
                        <Text variant="medium">
                            {patient?.uds?.incomeLevelReference?.familySizeDeclined !== true &&
                            patient?.uds?.incomeLevelReference?.familySize
                                ? patient?.uds?.incomeLevelReference?.familySize
                                : patient?.uds?.incomeLevelReference?.familySizeDeclined === true
                                ? 'Declined'
                                : '0'}
                        </Text>
                    </Stack.Item>
                </Stack>

                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Stack.Item>
                        <Text variant="medium">Income:</Text>
                    </Stack.Item>
                    <Stack.Item>
                        <Text variant="medium">
                            {patient?.uds?.incomeLevelReference?.incomeDeclined !== true
                                ? `
                            ${usdCurrencyFormatter.format(patient?.uds?.incomeLevelReference?.income ?? 0)} 
                            ${getPayPeriodText()}`
                                : 'Decline'}
                        </Text>
                    </Stack.Item>
                </Stack>

                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Stack.Item>
                        <Text variant="medium">Income Percentage:</Text>
                    </Stack.Item>
                    <Stack.Item>
                        <Text variant="medium">
                            {patient?.uds?.incomeLevelReference?.incomePercentage &&
                            (patient?.uds?.incomeLevelReference?.incomeDeclined !== true ||
                                patient?.uds?.incomeLevelReference?.familySizeDeclined !== true)
                                ? `${patient?.uds?.incomeLevelReference?.incomePercentage}${'%'}`
                                : patient?.uds?.incomeLevelReference?.incomeDeclined === true ||
                                  patient?.uds?.incomeLevelReference?.familySizeDeclined === true
                                ? 'Declined'
                                : '0'}
                        </Text>
                    </Stack.Item>
                </Stack>
            </Stack>
        </ContentCard>
    );
}
