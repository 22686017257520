import React from 'react';
import { Stack, styled, ITheme } from '@fluentui/react';

interface IComponentSelfProps {
    title?: JSX.Element;
    className?: string;
    style?: React.CSSProperties;
    theme?: ITheme;
}

const InnerCard: React.FC<IComponentSelfProps> = (props) => {
    return (
        <Stack
            style={{
                padding: 10,
                backgroundColor: props.theme?.palette.white,
                borderRadius: 2,
                ...props.style,
            }}
            className={`ms-depth-4 ${props.className ? props.className : ''}`}
        >
            {props.title ? <div style={{ width: '100%' }}>{props.title}</div> : null}
            {props.children}
        </Stack>
    );
};

export default styled<any, any, any>(InnerCard, {});
