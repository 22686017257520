import { ToothArea } from 'api/models/tooth-area';
import { IPipelineError } from '../pipeline';
import { ChartingSection } from './ui.types';

export const uiInitialState: ChartingUiState = {
    assetsLoaded: false,
    selectedAreas: [],
    selectedTeeth: [],
    menu: 'quickActions',
    section: ChartingSection.ToothLedger,
    isProcedureBrowserOpen: false,
    isDeleteModalOpen: false,
    isSectionChooserExpanded: false,
    chartingPipelineErrorData: undefined,
};

export interface IChartingPipelineErrorData {
    buttonId: string;
    errors?: IPipelineError[];
}

export type ChartingUiState = {
    assetsLoaded: boolean;
    selectedTeeth: number[];
    selectedAreas: (keyof typeof ToothArea)[];
    section: ChartingSection;
    chartingPipelineErrorData?: IChartingPipelineErrorData;
    menu: 'quickActions' | 'procedure' | 'xrays' | 'notes';
    isProcedureBrowserOpen: boolean;
    isDeleteModalOpen: boolean;
    isSectionChooserExpanded: boolean;
};
