import { IChartCondition } from 'api/models/chart.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { ChartEndpoint } from '../chart/chart.types';

export const chartConditionsInitialState = {
    data: [],
    loading: LoadingStatus.Idle,
    saving: LoadingStatus.Idle,
};

export type ChartConditionsState = ChartEndpoint<IChartCondition>
