import { DefaultEffects, Label, Link, Panel, PanelType, Spinner, SpinnerSize, Stack, Text, useTheme } from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import dentalApi from 'api/dental.api';
import { encounterStatusText } from 'api/models/encounter.model';
import IWorkListAppointmentView from 'api/models/worklist-appointment-view';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import { useAccount } from 'hooks';
import { RouteParams } from 'interfaces/route-params';
import encounterStatus from 'pages/Landing/AdminHuddle/components/ColumnComponents/EncounterStatusColumn';
import { MissingSlipsList } from 'pages/Landing/AdminHuddle/components/MissingSlipsWorkLists/MissingSlips';
import WorkListBuilder from 'pages/Landing/AdminHuddle/components/WorkListBuilder';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { encounter } from 'state/slices';
import { selectAccount } from 'state/slices/account.slice';
import { setWorkListFilter, setWorkListReadOnly } from 'state/slices/admin-huddle/worklists/worklist.slice';
import { MissingSlipsWorkList } from 'state/slices/admin-huddle/worklists/worklist.state';
import convertDashedDateString from 'utils/convertDateStringToLocal';
import { convertFromMilitaryTime } from 'utils/convertFromMilitaryTime';
import { classicDateOnly } from 'utils/dateOnly';
import { useHistory } from 'react-router-dom';

function ReadyForAttestation(): JSX.Element {
    const theme = useTheme();
    const { tenantId } = useParams<RouteParams>();

    const account = useSelector(selectAccount);
    const userId = account.data?.identity.id;
    const [count, setCount] = useState<number>();
    const [countLoading, { setTrue: setIsLoadingTrue, setFalse: setIsLoadingFalse }] = useBoolean(true);
    const [isOpen, { setTrue: setIsOpen, setFalse: setIsClosed }] = useBoolean(false);

    useEffect(() => {
        if (tenantId && userId) {
            setIsLoadingTrue();
            dentalApi
                .getWorkListDataByWorkList<number>({
                    tenantId,
                    method: 'AwaitingAttestationCount',
                    filters: {
                        providerIdType: 'signingProviderId',
                        providers: [userId],
                    },
                })
                .then((res) => {
                    setCount(res.data);
                    setIsLoadingFalse();
                })
                .catch(() => {
                    setIsLoadingFalse();
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenantId, userId]);

    const boxStyles = {
        root: {
            background: theme.palette.green,
            width: 120,
            height: 120,
            color: '#FFF',
            cursor: 'pointer',
            selectors: { ':hover': { boxShadow: DefaultEffects.elevation8 } },
        },
    };

    const textStyles: React.CSSProperties = {
        cursor: 'pointer',
        background: theme.palette.greenDark,
        width: '100%',
        textAlign: 'center',
        paddingTop: 5,
        paddingBottom: 5,
    };
    const _handleOpenPanel = () => {
        setIsOpen();
    };

    return (
        <Stack styles={boxStyles} horizontalAlign="center" onClick={_handleOpenPanel}>
            <Stack grow style={{ fontSize: 48 }} verticalAlign="center">
                {countLoading ? <Spinner size={SpinnerSize.large} /> : <span>{count}</span>}
            </Stack>
            <Stack horizontalAlign="center" style={textStyles}>
                <Label style={{ color: 'white' }}>Awaiting Signature /Attestation</Label>
            </Stack>
            <ReadyForAttestPanel isOpen={isOpen} onDismiss={setIsClosed} />
        </Stack>
    );
}

type ReadyForAttestPanelProps = {
    onDismiss: () => void;
    isOpen: boolean;
};

function ReadyForAttestPanel({ isOpen, onDismiss }: ReadyForAttestPanelProps) {
    const dispatch = useDispatch();
    const { tenantId } = useParams<RouteParams>();
    const { account } = useAccount();
    const { push } = useHistory();

    useEffect(() => {
        if (isOpen) {
            //Treating provider should only be able to read the notes, but not add.
            dispatch(setWorkListReadOnly(true));
            //Load only this treating provider's correction amend encounters:
            if (account.data?.identity) {
                dispatch(setWorkListFilter({ path: 'providers', value: [account.data?.identity.id] as string[] }));
                dispatch(setWorkListFilter({ path: 'providerIdType', value: 'signingProviderId' }));
            }
        }
    }, [isOpen, dispatch, tenantId, account]);

    const _handlePush = (item?: IWorkListAppointmentView) => {
        if (item) {
            push(`/${tenantId}/patient/${item?.patientId}/encounter/${item.encounterId}/charting`);
        }
    };

    const columns: ISortableColumn<IWorkListAppointmentView>[] = [
        {
            key: 'startDateTime',
            name: 'Appt. Date/Time',
            fieldName: 'startDateTime',
            minWidth: 60,
            maxWidth: 100,
            isResizable: true,
            onRender: (item) => {
                if (item) {
                    return (
                        <Link onClick={() => _handlePush(item)}>
                            {item.date ? (
                                <span>
                                    {convertDashedDateString(item.date)}
                                    {item.startTime ? `, ${convertFromMilitaryTime(item.startTime)}` : ''}
                                </span>
                            ) : (
                                'Unknown Date/Time'
                            )}
                        </Link>
                    );
                }
            },
        },
        {
            key: 'encounterReason',
            name: 'Reason for Encounter',
            fieldName: 'encounterReason',
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
        },
        {
            key: 'patientName',
            name: 'Patient',
            fieldName: 'patientName',
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            onRender: (item) => {
                if (!item) return null;
                const _patientName = `${item.patientLastName}, ${item.patientFirstName}`;
                return <Text variant="small">{_patientName}</Text>;
            },
            getValueKey: (item) => {
                return item?.patientLastName ?? '';
            },
        },
        {
            key: 'DOB',
            name: 'DOB',
            fieldName: 'DOB',
            minWidth: 60,
            maxWidth: 100,
            isResizable: true,
            onRender: (item) => {
                if (item) return <span>{item.dob ? classicDateOnly(item.dob) : null}</span>;
            },
        },
        encounterStatus,
    ];

    return (
        <Panel
            type={PanelType.custom}
            customWidth={'50vw'}
            isOpen={isOpen}
            onDismiss={onDismiss}
            styles={{
                contentInner: { flex: 1 },
                scrollableContent: { display: 'flex', flex: 1, flexDirection: 'column' },
                content: { display: 'flex', flexDirection: 'column', flex: 1 },
            }}
            headerText="Awaiting Signature/Attestation"
        >
            <Stack grow>
                {isOpen && (
                    <WorkListBuilder<IWorkListAppointmentView>
                        hideFilters
                        hideRecentItems
                        workLists={[MissingSlipsWorkList.AwaitingAttestation]}
                    >
                        {(detailsListData) => {
                            if (!detailsListData) return null;

                            return <MissingSlipsList {...detailsListData} columns={columns} />;
                        }}
                    </WorkListBuilder>
                )}
            </Stack>
        </Panel>
    );
}

export default ReadyForAttestation;
