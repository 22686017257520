import { Dropdown, IDropdownOption, Stack, TextField } from '@fluentui/react';
import { IDepositSlipDetailQuery, IDepositSlipSummaryQuery } from 'api/models/deposit-slip.model';
import { ReportType } from 'api/models/embed-report.model';
import { Field } from 'components';
import { useTenantId } from 'hooks';
import { getValidationError } from 'hooks/useValidation';
import ReportWrapper from 'pages/Reporting/ReportWrapper';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import reportActionLookup from 'state/slices/reporting/reportActionLookup';
import { selectReportProperties, selectReportingValidationErrors } from 'state/slices/reporting/reporting.selectors';
import { setDepositSlipDetailProp, toggleDepositSlipDetailListId } from 'state/slices/reporting/reporting.slice';
import { selectTreatingProviderOptions } from 'state/slices/tenant/providers.slice';
import { classicDateOnly } from 'utils/dateOnly';

function DepositSlipSummary() {
    const tenantId = useTenantId();
    const dispatch = useDispatch();

    const errors = useSelector(selectReportingValidationErrors);
    const _providerOptions = useSelector(selectTreatingProviderOptions);

    const query = useSelector(selectReportProperties) as IDepositSlipSummaryQuery;

    const _onViewReport = () => {
        if (query) dispatch(reportActionLookup[ReportType.DepositSlipSummary]({ tenantId, ...query }));
    };

    const dateTypeOptions: IDropdownOption[] = [
        { key: 'DepositDate', text: 'Deposit Date' },
        { key: 'EntryDate', text: 'Entry Date' },
    ];

    useEffect(() => {
        if (!query?.dateType) dispatch(setDepositSlipDetailProp({ path: 'dateType', value: 'EntryDate' }));
    }, []);

    return (
        <ReportWrapper
            validationConfig={[
                {
                    fieldName: 'Date Type',
                    validation: ['required'],
                    value: query.dateType,
                },
                {
                    fieldName: 'Start Date',
                    validation: ['required'],
                    value: query.startDate,
                },
                {
                    fieldName: 'End Date',
                    validation: ['required'],
                    value: query.endDate,
                },
            ]}
            onViewReport={_onViewReport}
        >
            <Stack grow horizontal wrap tokens={{ childrenGap: 10 }}>
                <Stack.Item>
                    <Dropdown
                        label="Date Type"
                        options={dateTypeOptions}
                        required
                        style={{ minWidth: 150 }}
                        selectedKey={query?.dateType}
                        placeholder="(Select)"
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(
                                    setDepositSlipDetailProp({
                                        path: 'dateType',
                                        value: option.key as string,
                                    }),
                                );
                        }}
                        errorMessage={getValidationError(errors, 'Date Type') ? 'Date type is required.' : undefined}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.Date
                        label="Start Date"
                        required
                        value={query?.startDate ? classicDateOnly(query?.startDate, 'MM/dd/yyyy') : ''}
                        onChange={(ev, value) => {
                            const newDate = value ? classicDateOnly(value, 'yyyy-MM-dd') : undefined;
                            dispatch(setDepositSlipDetailProp({ path: 'startDate', value: newDate }));
                        }}
                        hasDatePicker
                        errorMessage={getValidationError(errors, 'Start Date') ? 'Start date is required.' : undefined}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.Date
                        label="End Date"
                        required
                        value={query?.endDate ? classicDateOnly(query?.endDate, 'MM/dd/yyyy') : ''}
                        onChange={(ev, value) => {
                            const newDate = value ? classicDateOnly(value, 'yyyy-MM-dd') : undefined;
                            dispatch(setDepositSlipDetailProp({ path: 'endDate', value: newDate }));
                        }}
                        isReasonable
                        disabled={!query?.startDate}
                        minReasonableErrorMessage="End date must be after start date."
                        minReasonableDate={query?.startDate ? new Date(classicDateOnly(query?.startDate)) : undefined}
                        hasDatePicker
                        errorMessage={getValidationError(errors, 'End Date') ? 'End date is required.' : undefined}
                    />
                </Stack.Item>

                <Stack.Item>
                    <TextField
                        label="Check Number"
                        style={{ minWidth: 200 }}
                        value={query?.checkNumber}
                        onChange={(ev, value) => {
                            dispatch(setDepositSlipDetailProp({ path: 'checkNumber', value }));
                        }}
                    />
                </Stack.Item>
            </Stack>
        </ReportWrapper>
    );
}

export default DepositSlipSummary;
