import { createAsyncThunk, Dictionary } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import { INegativeReversal } from 'api/models/negative-reversal-reason.model';
import { IPatientPaymentHistoryView } from 'api/models/payment-history-model';
import { IPaymentSource } from 'api/models/payment-source.model';
import IPaymentSourceReversal from 'api/models/paymentSourceReversal.model';
import { RootState } from 'state/store';
import { getLedgerTotalsView } from '../ledger.actions';
import {
    selectPaymentHistorySelectedViews,
    selectPaymentHistorySelectedViewsPaymentsPatientIds,
    selectSelectedPaymentHistoryNegativeReversalNote,
    selectSelectedPaymentHistoryNegativeReversalReasonId,
} from './payment-history.selectors';
import { v4 as uuid } from 'uuid';
import { batch } from 'react-redux';
import { selectSignalRIsConnected } from 'state/slices/signalr.slice';
import {
    getPatientMostRecentTreatmentPlanCreditView,
    getPatientTreatmentPlanCreditUnappliedPayments,
} from '../treatment-plan-credit-and-uac/treatment-plan-credit-and-uac.actions';
import { some } from 'lodash';
import axios from 'axios';

export const getPaymentHistory = createAsyncThunk<
    IPatientPaymentHistoryView,
    { tenantId: string; patientId: string },
    { rejectValue: unknown }
>('getPaymentHistory', async ({ tenantId, patientId }, { rejectWithValue, signal }) => {
    try {
        const source = axios.CancelToken.source();
        signal.addEventListener('abort', () => {
            source.cancel();
        });

        const { data } = await dentalApi.getPatientPaymentHistory(tenantId, patientId, source.token);
        return data;
    } catch (e) {
        return rejectWithValue(e);
    }
});

export const reverseSelectedPaymentHistoryPaymentSource = createAsyncThunk<
    IPatientPaymentHistoryView[],
    { tenantId: string; patientId: string; batchId: string; dateOfEntry: string; paymentSource: IPaymentSource },
    { rejectValue: unknown; state: RootState }
>(
    'reversePaymentHistoryPaymentSource',
    async ({ tenantId, patientId, batchId, dateOfEntry, paymentSource }, { rejectWithValue, dispatch, getState }) => {
        try {
            const negativePaymentReasonId = selectSelectedPaymentHistoryNegativeReversalReasonId(getState());
            const note = selectSelectedPaymentHistoryNegativeReversalNote(getState());
            const patientIds = selectPaymentHistorySelectedViewsPaymentsPatientIds(getState());

            const newPaymentSource: IPaymentSourceReversal = {
                ...paymentSource,
                id: uuid(),
                negativePaymentReasonId,
                note,
                reversedPaymentSourceId: paymentSource.id,
                patientIds,
                batchId,
                dateOfEntry
            };

            const { data } = await dentalApi.reversePatientPaymentSource(tenantId, newPaymentSource);
            const selectedView = selectPaymentHistorySelectedViews(getState());

            const isSignalRConnected = selectSignalRIsConnected(getState());
            if (!isSignalRConnected)
                batch(() => {
                    dispatch(getLedgerTotalsView({ tenantId, patientId }));

                    if (!selectedView[0]?.payments.length) {
                        dispatch(getPatientTreatmentPlanCreditUnappliedPayments({ patientId, tenantId }));
                    } else {
                        if (!some(selectedView[0].payments, ['encounterId'])) {
                            dispatch(getPatientMostRecentTreatmentPlanCreditView({ patientId, tenantId }));
                        }
                    }
                });
            return data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const getNegativePaymentReversalReasons = createAsyncThunk<
    Dictionary<INegativeReversal>,
    { tenantId: string },
    { rejectValue: unknown }
>('getNegativePaymentReversalReasons', async ({ tenantId }, { rejectWithValue }) => {
    try {
        const { data } = await dentalApi.getNegativePaymentReasons(tenantId);
        return data;
    } catch (e) {
        return rejectWithValue(e);
    }
});
