import { IDropdownProps, IDropdownOption, Dropdown } from '@fluentui/react';
import { Dictionary, map } from 'lodash';

export enum RelativeDateType {
    Today = 'Today',
    Yesterday = 'Yesterday',
    PreviousWeek = 'PreviousWeek',
    MonthToDate = 'MonthToDate',
    PreviousMonth = 'PreviousMonth',
    QuarterToDate = 'QuarterToDate',
    PreviousQuarter = 'PreviousQuarter',
    YearToDate = 'YearToDate',
    PreviousYear = 'PreviousYear',
    YearThruPreviousMonth = 'YearThruPreviousMonth',
}

export const relativeDateTypeTitle: { [key in RelativeDateType]: string } = {
    [RelativeDateType.Today]: 'Today',
    [RelativeDateType.Yesterday]: 'Yesterday',
    [RelativeDateType.PreviousWeek]: 'Previous Week',
    [RelativeDateType.MonthToDate]: 'Month-to-Date',
    [RelativeDateType.PreviousMonth]: 'Previous Month',
    [RelativeDateType.QuarterToDate]: 'Quarter-to-Date',
    [RelativeDateType.PreviousQuarter]: 'Previous Quarter',
    [RelativeDateType.YearToDate]: 'Year-to-Date',
    [RelativeDateType.PreviousYear]: 'Previous Year',
    [RelativeDateType.YearThruPreviousMonth]: 'Year thru Previous Month',
};

export interface IRelativeDateDropdownProps extends Omit<IDropdownProps, 'onChange' | 'options'> {
    onChange?: (
        ev: React.FormEvent<HTMLDivElement>,
        startDate: string | undefined,
        endDate: string | undefined,
        value: RelativeDateType,
    ) => void;
}

function getQuarter(date: Date) {
    return Math.floor(date.getMonth() / 3 + 1);
}

function getQuarterDateRange(quarter: number) {
    const lookupQuarterStartEndDates: Dictionary<{ startDate: Date; endDate: Date }> = {
        '1': {
            startDate: new Date(new Date().getFullYear(), 0, 1),
            endDate: new Date(new Date().getFullYear(), 3, 0),
        },
        '2': {
            startDate: new Date(new Date().getFullYear(), 3, 1),
            endDate: new Date(new Date().getFullYear(), 6, 0),
        },
        '3': {
            startDate: new Date(new Date().getFullYear(), 6, 1),
            endDate: new Date(new Date().getFullYear(), 9, 0),
        },
        '4': {
            startDate: new Date(new Date().getFullYear(), 9, 1),
            endDate: new Date(new Date().getFullYear(), 12, 0),
        },
    };

    return lookupQuarterStartEndDates[quarter.toString()] as { startDate: Date; endDate: Date };
}

function getRelativeDateRange(selectedItem: RelativeDateType): { startDate: string | undefined; endDate: string | undefined } {
    const today = new Date();

    switch (selectedItem) {
        case RelativeDateType.Today:
            return { startDate: today.toISOString(), endDate: today.toISOString() };
        case RelativeDateType.Yesterday: {
            const yesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1).toISOString();
            return { startDate: yesterday, endDate: yesterday };
        }
        case RelativeDateType.PreviousWeek: {
            const day = today.getDay() + 8;
            const difference = today.getDate() - day + (day == 0 ? -6 : 1);
            const previousWeek = new Date(today.getFullYear(), today.getMonth(), difference).toISOString();
            const previousWeekLastDay = new Date(today.getFullYear(), today.getMonth(), difference + 6).toISOString();
            return { startDate: previousWeek, endDate: previousWeekLastDay };
        }
        case RelativeDateType.QuarterToDate: {
            const { startDate } = getQuarterDateRange(getQuarter(today));
            return { startDate: startDate.toISOString(), endDate: today.toISOString() };
        }
        case RelativeDateType.PreviousQuarter: {
            const { startDate } = getQuarterDateRange(getQuarter(today));
            const previousQuarter = new Date(startDate.getFullYear(), startDate.getMonth() - 2);
            const lastQuarter = getQuarterDateRange(getQuarter(previousQuarter));
            return { startDate: lastQuarter.startDate.toISOString(), endDate: lastQuarter.endDate.toISOString() };
        }
        case RelativeDateType.MonthToDate: {
            const monthToDate = new Date(today.getFullYear(), today.getMonth());
            return { startDate: monthToDate.toISOString(), endDate: today.toISOString() };
        }
        case RelativeDateType.PreviousMonth: {
            const previousMonth = new Date(today.getFullYear(), today.getMonth() - 1).toISOString();
            const previousMonthLastDay = new Date(today.getFullYear(), today.getMonth(), 0).toISOString();
            return { startDate: previousMonth, endDate: previousMonthLastDay };
        }
        case RelativeDateType.YearToDate: {
            const yearToDate = new Date(today.getFullYear(), 0, 1);
            return { startDate: yearToDate.toISOString(), endDate: today.toISOString() };
        }
        case RelativeDateType.PreviousYear: {
            const startOfPreviousYear = new Date(today.getFullYear() - 1, 0, 1);
            const endOfPreviousYear = new Date(today.getFullYear() - 1, 12, 0);
            return { startDate: startOfPreviousYear.toISOString(), endDate: endOfPreviousYear.toISOString() };
        }
        case RelativeDateType.YearThruPreviousMonth: {
            const startOfPreviousYear = new Date(today.getFullYear(), 0, 1);
            const previousMonthLastDay = new Date(today.getFullYear(), today.getMonth(), 0);
            return { startDate: startOfPreviousYear.toISOString(), endDate: previousMonthLastDay.toISOString() };
        }
        default:
            return { startDate: undefined, endDate: undefined };
    }
}

export default function RelativeDateRangeField(props: IRelativeDateDropdownProps) {
    const { onChange } = props;

    function _onChange(ev: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) {
        if (option && onChange) {
            const { startDate, endDate } = getRelativeDateRange(option.key as RelativeDateType);
            onChange(ev, startDate, endDate, option.key as RelativeDateType);
        }
    }

    const options: IDropdownOption[] = map(RelativeDateType, (type, key) => ({
        key,
        text: relativeDateTypeTitle[type],
    }));

    return <Dropdown {...props} options={options} onChange={_onChange} />;
}
