import { ChartProcedureDiagnosis, IChartProcedure } from 'api/models/chart.model';
import { IProcedure } from 'api/models/procedure.model';
import { find } from 'lodash';
import { AppThunk } from 'state/store';
import { chartingActions } from '../chart/chart.slice';
import { IChartAction } from '../chartActionsList.pipeline';
import { updateChartProcedure, updateChartProcedures } from '../procedures/procedures.actions';

export type OpenDiagnosesPanelPayload = {
    selectedChartProcedure?: IChartProcedure | IChartProcedure[];
    selectedProcedure?: IProcedure | IProcedure[];
    selectedDiagnoses?: ChartProcedureDiagnosis[];
};
export const openDiagnosesPanel =
    (chartAction: IChartAction | IChartAction[]): AppThunk<void> =>
    (dispatch, getState) => {
        const state = getState();
        const chartProcedures = state.charting.procedures.data;
        const procedures = state.tenant.procedures.data;
        if (!Array.isArray(chartAction)) {
            const selectedChartProcedure: IChartProcedure | undefined = find(chartProcedures, ['id', chartAction.id]);
            const selectedProcedure: IProcedure | undefined = find(procedures, ['id', selectedChartProcedure?.procedureId]);
            const selectedDiagnoses: ChartProcedureDiagnosis[] | undefined = selectedChartProcedure?.diagnosisCodes;

            dispatch(
                chartingActions.setDiagnosesPanelOpen({
                    selectedChartProcedure,
                    selectedDiagnoses,
                    selectedProcedure,
                }),
            );
        } else {
            /**
             * TODO: Get all chart procedures
             * TODO: Get all procedures
             * TODO: Get all diagnoses and concat them to the selected diagnoses
             * TODO: Dispatch chartingActions.setDiagnosesPanelOpen with all the data
             */
            const selectedChartProcedures: IChartProcedure[] | undefined = chartAction
                .filter((action) => action.actionType === 'Procedure')
                .map((action) => find(chartProcedures, ['id', action.id]))
                .filter((action) => action !== undefined) as IChartProcedure[];

            const selectedProcedures: IProcedure[] | undefined = selectedChartProcedures.map((chartProcedure) =>
                find(procedures, ['id', chartProcedure.procedureId]),
            ) as IProcedure[];

            const selectedDiagnoses: ChartProcedureDiagnosis[] | undefined = selectedChartProcedures
                .map((chartProcedure) => chartProcedure.diagnosisCodes)
                .flat()
                .filter((diagnosisId) => !diagnosisId) as ChartProcedureDiagnosis[];
            // [[1],[2],[3]] => [1,2,3]
            dispatch(
                chartingActions.setDiagnosesPanelOpen({
                    selectedChartProcedure: selectedChartProcedures,
                    selectedDiagnoses,
                    selectedProcedure: selectedProcedures,
                }),
            );
        }
    };

export const updateChartProcedureDiagnoses =
    (tenantId: string, patientId: string): AppThunk<void> =>
    (dispatch, getState) => {
        const state = getState().charting.diagnosesPanel;
        const diagnosisCodes = state.selectedDiagnoses;
        const chartProcedures = state.selectedChartProcedures;
        if (!Array.isArray(chartProcedures)) {
            if (chartProcedures && diagnosisCodes?.length) {
                const newChartProcedure: IChartProcedure = { ...chartProcedures, diagnosisCodes };
                dispatch(updateChartProcedure({ tenantId, patientId, procedure: newChartProcedure }));
            }
        } else {
            const newChartProcedures: IChartProcedure[] = chartProcedures.map((chartProcedure) => ({
                ...chartProcedure,
                diagnosisCodes,
            }));
            dispatch(updateChartProcedures({ tenantId, patientId, procedures: newChartProcedures }));
        }
    };
