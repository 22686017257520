import { useSelector } from 'hooks';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getHealthHistoryProblems } from 'state/slices/problems/problems.actions';
import {
    selectCriticalHealthHistoryProblemsAsList,
    selectHealthHistoryProblemsAsList,
    selectHealthHistoryProblemsData,
    selectHealthHistoryProblemsLoading,
} from 'state/slices/problems/problems.selectors';

export default function useProblems() {
    const _dispatch = useDispatch();

    const _selectHealthHistoryProblemsData = useSelector(selectHealthHistoryProblemsData);
    const _selectHealthHistoryProblemsAsList = useSelector(selectHealthHistoryProblemsAsList);
    const _selectCriticalHealthHistoryProblemsAsList = useSelector(selectCriticalHealthHistoryProblemsAsList);
    const _selectHealthHistoryProblemsLoading = useSelector(selectHealthHistoryProblemsLoading);

    const _getHealthHistoryProblems = useCallback(() => {
        _dispatch(getHealthHistoryProblems());
    }, [_dispatch]);

    return {
        // SELECTORS
        selectHealthHistoryProblemsAsList: _selectHealthHistoryProblemsAsList,
        selectCriticalHealthHistoryProblemsAsList: _selectCriticalHealthHistoryProblemsAsList,
        selectHealthHistoryProblemsData: _selectHealthHistoryProblemsData,
        selectHealthHistoryProblemsLoading: _selectHealthHistoryProblemsLoading,

        // ACTIONS
        getHealthHistoryProblems: _getHealthHistoryProblems,
    };
}
