import { AxiosError } from 'axios';
import { LoadingStatus, LoadingStatuses } from 'interfaces/loading-statuses';
import { useState } from 'react';

export type AxiosErrorTest = { code?: string } & Omit<AxiosError, 'config' | 'code'>;

export default function useLoadTestDataAsync<T>(
    data: T,
    delay = 300,
    failedData?: AxiosErrorTest,
): [T | undefined, LoadingStatus, () => void, () => void, AxiosErrorTest | undefined] {
    const [loadingTestData, setLoadingTestData] = useState<LoadingStatuses>(LoadingStatus.Pending);
    const [testData, setTestData] = useState<T | undefined>(undefined);
    const [testError, setTestError] = useState<AxiosErrorTest | undefined>(undefined);

    function loadTestData() {
        setLoadingTestData(LoadingStatus.Pending);
        setTimeout(() => {
            if (!failedData) {
                setTestData(data);
                setLoadingTestData(LoadingStatus.Completed);
            } else {
                setTestError(failedData);
                setLoadingTestData(LoadingStatus.Failed);
            }
        }, delay);
    }

    function cleanupTestData() {
        setLoadingTestData(LoadingStatus.Pending);
        setTestData(undefined);
    }

    return [testData, loadingTestData, loadTestData, cleanupTestData, testError];
}
