import { Stack, TextField } from '@fluentui/react';
import { VitalNameType } from 'api/models/patient-vital.model';
import { SubSection, Field } from 'components';
import { useSelector } from 'hooks';
import { useDispatch } from 'react-redux';
import { updateReading } from 'state/slices/patient/patient.slice';
import { NonBPNameTypes } from 'state/slices/patient/vitals/vitals.reducers';

const TVitalsProperties = (): JSX.Element => {
    const dispatch = useDispatch();
    const newVitals = useSelector((state) => state.patient.vitals.vitalPanelData);

    const temperature = newVitals[VitalNameType.Temperature];
    const temperatureType = newVitals[VitalNameType.TemperatureType];

    const tOptions = [
        {
            key: 'oral',
            text: 'oral',
        },
        {
            key: 'ear',
            text: 'ear',
        },
        {
            key: 'axillary',
            text: 'axillary',
        },
        {
            key: 'rectal',
            text: 'rectal',
        },
        {
            key: 'temporal artery',
            text: 'temporal artery',
        },
        {
            key: 'None',
            text: 'None',
        },
    ];

    const handleOnChange = (vitalType: NonBPNameTypes, value?: string): void => {
        dispatch(updateReading({ vitalType, value: value ?? '' }));
    };

    const isValueEmpty = (value?: string) => value === '';

    return (
        <Stack tokens={{ childrenGap: 5 }}>
            <SubSection title="Temperature">
                <Stack tokens={{ childrenGap: 10 }}>
                    <Stack tokens={{ childrenGap: 10 }} horizontal verticalAlign="center" grow>
                        <Stack.Item>
                            <TextField
                                label=""
                                autoComplete="off"
                                suffix="F"
                                maxLength={5}
                                value={temperature}
                                onChange={(ev, value) => {
                                    if (value !== null && value !== '' && !isValueEmpty(value)) {
                                        const temp3Regex = /(\d{3})(\d)/;
                                        const temp2Regex = /(\d{2})(\d)/;
                                        const rawValue = value ? value.split('.').join('') : value;
                                        let newValue = rawValue ? rawValue : '';
                                        if (rawValue && rawValue.length > 2) newValue = rawValue.replace(temp2Regex, '$1.$2');
                                        if (rawValue && rawValue.length > 3) newValue = rawValue.replace(temp3Regex, '$1.$2');
                                        handleOnChange(VitalNameType.Temperature, newValue ?? '');
                                    }
                                    if (value === '') {
                                        handleOnChange(VitalNameType.Temperature, value ?? '');
                                    }
                                }}
                            />
                        </Stack.Item>
                        <Stack.Item grow>
                            <Field.Dropdown
                                options={tOptions}
                                label=""
                                selectedKey={temperatureType}
                                onChange={(event, option) => handleOnChange(VitalNameType.TemperatureType, option?.key as string)}
                            />
                        </Stack.Item>
                    </Stack>
                </Stack>
            </SubSection>
        </Stack>
    );
};

export default TVitalsProperties;
