import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IQuickActions, IQuickAction } from 'api/models/quick-action.model';
import { IQuickActionCategories, IQuickActionCategory } from 'api/models/quick-action-category-model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { filter, indexOf } from 'lodash';
import { ProcedureActionType } from 'state/slices/charting/chart/chart.slice';
import { RootState } from 'state/store';
import { createAsyncThunk, Dispatch } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import { AxiosResponse, AxiosError } from 'axios';
import { AppThunk } from 'state/store';

// Quick Actions ============================
export const getQuickActions = createAsyncThunk<
    AxiosResponse<IQuickActions>,
    {
        tenantId: string;
    },
    {
        dispatch: Dispatch;
        rejectValue: AxiosError;
    }
>('getQuickActionItems', async ({ tenantId }) => {
    const res = await dentalApi.getQuickActions(tenantId);
    return res;
});

export const createQuickAction = createAsyncThunk<
    AxiosResponse<IQuickActions>,
    {
        tenantId: string;
        entity: IQuickActions;
    },
    {
        dispatch: Dispatch;
        rejectValue: AxiosError;
    }
>('createQuickAction', async ({ tenantId, entity }) => {
    const res = await dentalApi.createQuickAction(tenantId, entity);
    return res;
});

export const updateQuickAction = createAsyncThunk<
    AxiosResponse<IQuickActions>,
    {
        tenantId: string;
        entity: IQuickActions;
    },
    {
        dispatch: Dispatch;
        rejectValue: AxiosError;
    }
>('updateQuickAction', async ({ tenantId, entity }) => {
    const res = await dentalApi.updateQuickAction(tenantId, entity);
    return res;
});

// Quick Action Categories ============================
export const getQuickActionCategories = createAsyncThunk<
    IQuickActionCategories,
    {
        tenantId: string;
    }
>('getQuickActionCategories', async ({ tenantId }) => {
    const res = await dentalApi.getQuickActionCategories(tenantId);
    //We don't want any deleted items in our state.
    const newCategoryData = res.data.categories?.filter((category) => !category.isDeleted);
    return { ...res.data, categories: newCategoryData };
});

export const updateQuickActionCategories = createAsyncThunk<
    IQuickActionCategories,
    { tenantId: string; quickActionCategories: IQuickActionCategories }
>('updateQuickActionCategory', async ({ tenantId, quickActionCategories }) => {
    //get the latest data from the server and filter only deleted items, and add those back to the list of categories.
    const newEntity: IQuickActionCategories = {
        ...quickActionCategories,
        categories: [
            ...(quickActionCategories.categories ?? []).filter((category) => !category.isDeleted),
            ...(quickActionCategories.categories ?? []).filter((category) => category.isDeleted),
        ],
    };
    const res = await dentalApi.updateQuickActionCategory(tenantId, newEntity);
    return res.data;
});

let timer: NodeJS.Timeout | null = null;
export const saveQuickActionCategories =
    (tenantId: string, categoryToSave: IQuickActionCategories): AppThunk<void> =>
    (dispatch): void => {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            dispatch(updateQuickActionCategories({ tenantId, quickActionCategories: categoryToSave }));
        }, 2000);
    };

export const setCategoryOrderAndSave =
    (tenantId: string, draggableId: string, destinationIndex: number): AppThunk<void> =>
    (dispatch, getState) => {
        dispatch(setCategoryIndex({ destinationIndex, draggableId }));
        const entity = getState().tenant.quickActions.categories.data;
        if (entity) dispatch(saveQuickActionCategories(tenantId, entity));
    };

export const deleteCategoryAndSave =
    (tenantId: string, quickActionCategory: IQuickActionCategory): AppThunk<void> =>
    (dispatch, getState) => {
        const categoryData = getState().tenant.quickActions.categories.data;
        if (categoryData?.categories) {
            const deletedCategory: IQuickActionCategory = {
                ...quickActionCategory,
                isDeleted: true,
            };
            const newCategoryData = [...categoryData.categories];
            const indexOfCategory = newCategoryData.findIndex((category) => category.id === quickActionCategory.id);
            if (indexOfCategory > -1) {
                newCategoryData[indexOfCategory] = deletedCategory;

                const newCategories: IQuickActionCategories = {
                    ...categoryData,
                    categories: newCategoryData,
                };

                dispatch(updateQuickActionCategories({ tenantId, quickActionCategories: newCategories }));
            }
        }
    };

type CategoriesState = { initialLoad: LoadingStatus; loading: LoadingStatus; data?: IQuickActionCategories };
type QuickActionDataState = {
    initialLoad: LoadingStatus;
    loading: LoadingStatus;
    data?: IQuickActions;
    error: string | undefined;
};

export type QuickActionState = {
    items: QuickActionDataState;
    categories: CategoriesState;
    selectedCategory?: string;
    selectedQuickAction?: string;
    selectedQuickActionType: ProcedureActionType;
    selectedQuickActionListMode: 'Icon' | 'Text';
};

const initialState: QuickActionState = {
    items: { initialLoad: LoadingStatus.Idle, loading: LoadingStatus.Idle, data: undefined, error: LoadingStatus.Idle },
    categories: { initialLoad: LoadingStatus.Idle, loading: LoadingStatus.Idle },
    selectedQuickActionListMode: 'Text',
    selectedQuickActionType: ProcedureActionType.Treatment,
};

const quickActionsSlice = createSlice({
    name: 'quickActions',
    initialState,
    reducers: {
        setQuickActionType: (state, action: PayloadAction<ProcedureActionType>) => {
            state.selectedQuickActionType = action.payload;
        },
        setQuickActionListMode: (state, action: PayloadAction<'Icon' | 'Text'>) => {
            state.selectedQuickActionListMode = action.payload;
        },
        setSelectedQuickActionId: (state, action: PayloadAction<string>) => {
            state.selectedQuickAction = action.payload;
        },
        setSelectedCategoryId: (state, action: PayloadAction<string>) => {
            state.selectedCategory = action.payload;
        },
        cleanupQuickActions: (state) => {
            state.selectedCategory = undefined;
            state.selectedQuickAction = undefined;
        },
        setCategoryIndex: (state, action: PayloadAction<{ draggableId: string; destinationIndex: number }>) => {
            const { draggableId, destinationIndex } = action.payload;
            if (state.categories.data?.categories) {
                const entity = state.categories.data.categories.find((e) => e.id === draggableId);
                const indexOfCategory = state.categories.data.categories.findIndex((c) => c.id === entity?.id);
                if (entity) {
                    const orderedCategoriesList = [...state.categories.data.categories];
                    orderedCategoriesList.splice(indexOfCategory, 1);
                    orderedCategoriesList.splice(destinationIndex, 0, entity);
                    state.categories.data.categories = orderedCategoriesList;
                }
            }
        },
    },
    extraReducers: (builder) => {
        // Quick Action Items
        builder
            .addCase(getQuickActions.pending, (state) => {
                state.items.initialLoad = LoadingStatus.Pending;
            })
            .addCase(getQuickActions.fulfilled, (state, action) => {
                state.items.initialLoad = LoadingStatus.Completed;
                state.items.data = action.payload.data;
            })
            .addCase(getQuickActions.rejected, (state, action) => {
                state.items.initialLoad = LoadingStatus.Failed;
                state.items.error = action.payload?.message;
            })

            // CREATE Quick Action ====================
            .addCase(createQuickAction.pending, (state) => {
                state.items.loading = LoadingStatus.Pending;
            })
            .addCase(createQuickAction.fulfilled, () => {
                // const newQuickAction = action.payload.data;
                //state.items.data = { ...state.items.data, [newQuickAction.id]: newQuickAction };
            })
            .addCase(createQuickAction.rejected, (state) => {
                state.items.loading = LoadingStatus.Pending;
            })

            // UPDATE Quick Action ====================
            .addCase(updateQuickAction.pending, (state) => {
                state.items.loading = LoadingStatus.Pending;
            })
            .addCase(updateQuickAction.fulfilled, (state, action) => {
                state.items.loading = LoadingStatus.Completed;
                state.items.data = action.payload.data;
            })
            .addCase(updateQuickAction.rejected, (state) => {
                state.items.loading = LoadingStatus.Failed;
            });

        // Quick Action Categories
        builder
            // GET Categories ====================
            .addCase(getQuickActionCategories.pending, (state) => {
                state.categories.initialLoad = LoadingStatus.Pending;
            })
            .addCase(getQuickActionCategories.fulfilled, (state, action) => {
                state.categories.initialLoad = LoadingStatus.Completed;
                state.categories.data = action.payload;
            })
            .addCase(getQuickActionCategories.rejected, (state) => {
                state.categories.initialLoad = LoadingStatus.Failed;
            })

            // UPDATE Category ====================
            .addCase(updateQuickActionCategories.pending, (state) => {
                state.categories.loading = LoadingStatus.Pending;
            })
            .addCase(updateQuickActionCategories.fulfilled, (state, action) => {
                state.categories.loading = LoadingStatus.Completed;
                const newCategory = action.payload;

                if (state.categories.data) {
                    state.categories.data = { ...state.categories.data, _etag: action.payload._etag };
                }

                if (newCategory.isDeleted) {
                    state.selectedCategory = undefined;
                }
                state.categories.data = action.payload;
            })
            .addCase(updateQuickActionCategories.rejected, (state) => {
                state.categories.loading = LoadingStatus.Failed;
            });

        builder.addCase<string, PayloadAction<boolean>>('charting/toggleSelectionMode', (state, action) => {
            if (!action.payload && state.selectedQuickAction) {
                state.selectedQuickAction = undefined;
            }
        });
    },
});

const { reducer, actions } = quickActionsSlice;

export const {
    setQuickActionListMode,
    setSelectedCategoryId,
    setSelectedQuickActionId,
    cleanupQuickActions,
    setQuickActionType,
    setCategoryIndex,
} = actions;

export const selectedQuickActionListMode = (state: RootState): 'Icon' | 'Text' =>
    state.tenant.quickActions.selectedQuickActionListMode;
export const selectedQuickActionId = (state: RootState): string | undefined => state.tenant.quickActions.selectedQuickAction;
export const selectedQuickActionType = (state: RootState): string | undefined =>
    state.tenant.quickActions.selectedQuickActionType;
export const selectQuickActions = (state: RootState): IQuickActions | undefined => state.tenant.quickActions.items.data;
export const selectQuickActionCategories = (state: RootState): IQuickActionCategories | undefined =>
    state.tenant.quickActions.categories.data;
export const selectQuickActionCategoryList = (state: RootState): IQuickActionCategory[] =>
    state.tenant.quickActions.categories.data?.categories
        ? state.tenant.quickActions.categories.data.categories.filter((c) => !c.isDeleted)
        : [];

export const selectSelectedQuickAction = createSelector([selectQuickActions, selectedQuickActionId], (items, actionId) => {
    if (items && actionId) {
        return items.actions.filter((res) => res.id === actionId);
    } else {
        return null;
    }
});

export const selectSelectedCategoryId = (state: RootState): string | undefined => state.tenant.quickActions.selectedCategory;

export const selectSelectedCategory = createSelector(
    [selectQuickActionCategoryList, selectSelectedCategoryId],
    (categories, selectedCategory) => {
        const category = categories.find((c) => c.id === selectedCategory);
        return categories && selectedCategory ? category || null : null;
    },
);
export const unselectedCategories = createSelector(
    [selectQuickActionCategoryList, selectSelectedCategoryId],
    (categories, selectedCategory) => {
        return filter(categories, (item) => item?.id !== selectedCategory);
    },
);

export const selectQuickActionItems = (state: RootState): IQuickActions | undefined => state.tenant.quickActions.items.data;
export const selectQuickActionActionItems = (state: RootState): IQuickAction[] | undefined =>
    state.tenant.quickActions.items.data?.actions;
export const selectQuickActionsLoading = (state: RootState): LoadingStatus => state.tenant.quickActions.items.loading;

export const selectFilteredQuickActionItems = createSelector(
    [selectSelectedCategoryId, selectQuickActionActionItems],
    (selectedCategory, quickActionItems) => {
        if (quickActionItems && selectedCategory) {
            return filter<IQuickAction>(
                quickActionItems,
                (item) => item?.categoryId === selectedCategory && !item.isDeleted && item.categoryId !== undefined,
            );
        } else {
            return [];
        }
    },
);

export default reducer;
