import { MessageBar, MessageBarType } from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import { IPatientEncounter, IPatientEncounterWithAppointment } from 'api/models/encounter.model';
import { usePatientId, useSelector, useTenantId } from 'hooks';
import useEncounterId from 'hooks/useEncounterId';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { tenant } from 'state/slices';
import { deselectPatientEncounter } from 'state/slices/encounter/encounter.actions';
import { selectHasPermissionToAccessCurrentEncounter } from 'state/slices/encounter/encounter.selectors';

export function isEncounterWithAppointment(
    data: IPatientEncounter | IPatientEncounterWithAppointment,
): data is IPatientEncounterWithAppointment {
    return !!(data as IPatientEncounterWithAppointment)?.encounter;
}

export default function EncounterAccessPermissionHandler() {
    const dispatch = useDispatch();
    const encounterId = useEncounterId();
    const tenantId = useTenantId();
    const patientId = usePatientId();
    const location = useLocation();
    const pathname = location.pathname;

    const _hasPermissionToAccessCurrentEncounter = useSelector((state) =>
        selectHasPermissionToAccessCurrentEncounter(state, tenantId),
    );

    const [messageBar, setMessageBar] = useState<{ message: string; messageType: MessageBarType }>();

    const [canAutoClearMessage, { setTrue: setCanAutoClearMessage, setFalse: setCannotAutoClearMessage }] = useBoolean(true);

    function onDismissMessageBar() {
        setMessageBar(undefined);
    }

    const _deselectPatientEncounter = useCallback(() => {
        dispatch(deselectPatientEncounter());
    }, [dispatch]);

    //Drops the user from the encounter if they do not have permission.
    const handleHasPermission = useCallback(
        async (hasPermission: boolean) => {
            if (!hasPermission) {
                if (!messageBar)
                    setMessageBar({
                        message: 'You do not currently have access to the specified patient encounter.',
                        messageType: MessageBarType.blocked,
                    });

                //Probably a better way to do this. Don't really like it:
                setCannotAutoClearMessage();
                await _deselectPatientEncounter();
                setCanAutoClearMessage();
            }

            if (messageBar) onDismissMessageBar();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [_deselectPatientEncounter, messageBar],
    );

    //Auto remove the error after navigation away to a different path.
    useEffect(() => {
        if (canAutoClearMessage && messageBar) {
            setMessageBar(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    useEffect(() => {
        if (!patientId && messageBar) onDismissMessageBar();
    }, [patientId, messageBar]);

    useEffect(() => {
        if (encounterId) handleHasPermission(_hasPermissionToAccessCurrentEncounter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_hasPermissionToAccessCurrentEncounter, encounterId]);

    return messageBar?.message && messageBar?.messageType ? (
        <MessageBar onDismiss={onDismissMessageBar} messageBarType={messageBar.messageType}>
            {messageBar.message}
        </MessageBar>
    ) : null;
}
