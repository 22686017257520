import { IDropdownOption, Stack, Text } from '@fluentui/react';
import { Field, TModal } from 'components';
import { tr } from 'date-fns/locale';
import { useEncounter, usePatientId, useSelector, useTenantId } from 'hooks';
import useEncounterId from 'hooks/useEncounterId';
import { sortBy } from 'lodash';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setClinicalNoteAttested } from 'state/slices/chart-notes/chart-notes.actions';
import { isAttestModalOpen } from 'state/slices/charting/chart/chart.selectors';
import { toggleAttestModal } from 'state/slices/charting/chart/chart.slice';
import { selectProvidersAsList } from 'state/slices/tenant/providers.slice';

export default function BillingProviderModal(): JSX.Element {
    const dispatch = useDispatch();
    const _providers = useSelector(selectProvidersAsList);
    const { patientEncounterAppointment: appointment } = useEncounter();
    const tenantId = useTenantId();
    const patientId = usePatientId();
    const isOpen = useSelector(isAttestModalOpen);

    const treatingProviderOptions: IDropdownOption[] = sortBy(
        _providers
            .filter((p) => p?.isTreatingProvider && !p.isResident)
            .map((p) => {
                return {
                    key: p?.id ?? '',
                    text: `${p?.lastName} ${p?.suffix ? `${p?.suffix}` : ''}, ${p?.firstName}`,
                };
            }) ?? [],
        'text',
    );

    const [selectedBillingProvider, setSelectedBillingProvider] = useState<string | null>(null);

    const onDismiss = () => {
        dispatch(toggleAttestModal());
    };

    return (
        <TModal
            title={'Select Billing Provider'}
            mainButtons={[
                {
                    type: 'PrimaryButton',
                    text: 'Continue',
                    onClick: () => {
                        if (tenantId && patientId && selectedBillingProvider) {
                            dispatch(
                                setClinicalNoteAttested(tenantId, patientId, selectedBillingProvider),
                            );
                            dispatch(toggleAttestModal());
                        }
                    },
                    disabled: !selectedBillingProvider,
                },
                {
                    text: 'Cancel',
                    onClick: onDismiss,
                },
            ]}
            modalProps={{
                isOpen,
                onDismiss,
                onDismissed: () => {
                    setSelectedBillingProvider(null);
                },
                isBlocking: true,
            }}
        >
            <Stack grow>
                <Text styles={{ root: { padding: 10, color: 'red', fontStyle: 'italic' } }}>
                    Select Billing Provider before attesting.
                </Text>
                <Stack styles={{ root: { paddingLeft: 10 } }}>
                    <Field.SearchCombo
                        selectedKey={selectedBillingProvider}
                        styles={{ root: { width: 250 } }}
                        label="Billing Provider"
                        placeholder="(Select)"
                        options={treatingProviderOptions}
                        required={true}
                        onChange={(e, option) => {
                            if (option) {
                                setSelectedBillingProvider(option.key as string);
                            }
                        }}
                    />
                </Stack>
            </Stack>
        </TModal>
    );
}
