import { DefaultButton, Icon, IDropdownOption, Label, MessageBar, Selection, Stack, Text, useTheme } from '@fluentui/react';
import {
    ChartTreatmentPlanPhaseStatus,
    IChartTreatmentPlanPhase,
    IChartTreatmentPlanPhaseProcedure,
} from 'api/models/treatment-plan.model';
import { Field, SubSection } from 'components';
import { useSelector } from 'hooks';
import { RouteParams } from 'interfaces/route-params';
import { FormEvent, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { onDeleteTreatmentPlanPhase, onSetPatientReturnDate } from 'state/slices/charting/treatmentPlans/treatmentPlans.actions';
import {
    makeSelectPhaseProcedures,
    selectCurrentTreatmentPlan,
    selectCurrentTreatmentPlanSignedOrSigning,
} from 'state/slices/charting/treatmentPlans/treatmentPlans.selectors';
import EditBillingProcedureTable from './EditBillingProcedureTable';

type Props = {
    phase: IChartTreatmentPlanPhase;
    readonly?: boolean;
};

enum ReturnDate {
    OneWeek = '7-10',
    TwoToThreeWeeks = '14-21',
    OneMonth = '30',
    ThreeToFourMonths = '90-120',
    SixMonths = '180',
    TwelveMonth = '365',
}

const returnDateOptions: IDropdownOption[] = [
    {
        key: '',
        text: '(Select)',
    },
    {
        key: ReturnDate.OneWeek,
        text: '7-10 days',
    },
    {
        key: ReturnDate.TwoToThreeWeeks,
        text: '2-3 weeks',
    },
    {
        key: ReturnDate.OneMonth,
        text: '1 month',
    },
    {
        key: ReturnDate.ThreeToFourMonths,
        text: '3-4 months',
    },
    {
        key: ReturnDate.SixMonths,
        text: '6 months',
    },
    {
        key: ReturnDate.TwelveMonth,
        text: '12 months',
    },
];

export default function TreatmentPlanPhase({ phase }: Props): JSX.Element {
    const [selectedChartProcedures, setSelectedChartProcedures] = useState<IChartTreatmentPlanPhaseProcedure[]>([]);
    const { palette } = useTheme();
    // These are memoized since we need a unique copy for each TreatmentPlanPhase component
    const selectProcedures = useMemo(() => makeSelectPhaseProcedures, []);
    const procedures = useSelector((state) => selectProcedures(state, phase.id));

    const selection = useMemo(() => {
        return new Selection({
            onSelectionChanged: () => {
                const procedures = getSelectionDetails();
                setSelectedChartProcedures(procedures);
            },
            items: selectedChartProcedures,
            getKey: (item) => item.id,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getSelectionDetails = () => {
        return selection.getSelection() as IChartTreatmentPlanPhaseProcedure[];
    };

    return (
        <SubSection
            title={phase.displayName ?? 'Phase'}
            headingCenterContent={
                phase.status === ChartTreatmentPlanPhaseStatus.Completed ? (
                    <Stack tokens={{ childrenGap: 3 }} verticalAlign="center" horizontal>
                        <Icon
                            style={{ fontSize: 20, paddingBottom: 3, color: palette.themePrimary, fontWeight: 'bold' }}
                            iconName="CheckMark"
                        />
                        <Text style={{ color: palette.themePrimary, fontWeight: 'bold' }}>Completed</Text>
                    </Stack>
                ) : undefined
            }
            headerStyle={{ paddingLeft: 0 }}
            sectionBodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
            headingEndContent={<PhaseReturnDateAndRemove phase={phase} />}
        >
            {procedures?.length ? (
                <EditBillingProcedureTable showTotalsRow phaseId={phase.id} procedures={procedures} />
            ) : (
                <MessageBar>No procedures have been added to this phase yet.</MessageBar>
            )}
        </SubSection>
    );
}
type PhaseReturnDateAndRemovedProps = {
    phase: IChartTreatmentPlanPhase;
};
function PhaseReturnDateAndRemove({ phase }: PhaseReturnDateAndRemovedProps): JSX.Element | null {
    const { tenantId, patientId } = useParams<RouteParams>();
    const dispatch = useDispatch();
    const isSigned = useSelector(selectCurrentTreatmentPlanSignedOrSigning);
    const currentPlan = useSelector(selectCurrentTreatmentPlan);
    const encounterId = currentPlan?.encounterId;
    const isDisabled = isSigned || currentPlan?.isDeleted;

    if (isDisabled) return null;

    const _deletePhase = () => {
        if (encounterId) dispatch(onDeleteTreatmentPlanPhase({ tenantId, patientId, phase }));
    };

    const _setPhaseReturnDate = (event?: FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
        if (option && option.key && encounterId) {
            dispatch(
                onSetPatientReturnDate({ value: option.key as string, phaseId: phase.id, tenantId, patientId, encounterId }),
            );
        }
    };
    return (
        <Stack tokens={{ childrenGap: 10 }} horizontal>
            <Label>Return Date:</Label>
            <Field.Dropdown
                placeholder="(Select)"
                selectedKey={phase.returnDate}
                style={{ minWidth: 110 }}
                options={returnDateOptions}
                onChange={_setPhaseReturnDate}
                disabled={isDisabled}
            />
            <DefaultButton text="Remove" iconProps={{ iconName: 'Trash' }} onClick={_deletePhase} />
        </Stack>
    );
}
