import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { IWorkListsState } from '../worklist.state';
import { updateWorkListAppointmentData } from './appointment-worklists.actions';
import IWorkListAppointmentView from 'api/models/worklist-appointment-view';
import { finishCheckout, hasFinishCheckoutSucceeded } from 'state/slices/scheduling/scheduling.actions';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { MessageBarType } from '@fluentui/react';
import { finishCheckin } from 'state/slices/scheduling/check-in/check-in.actions';

export const appointmentWorkListsExtraReducers = (builder: ActionReducerMapBuilder<IWorkListsState>) => {
    builder
        .addCase(updateWorkListAppointmentData.pending, (state) => {
            state.saving = LoadingStatus.Pending;
        })
        .addCase(updateWorkListAppointmentData.fulfilled, (state, action) => {
            if (state.data) {
                //If we only updated the appointment and there is no returned payload, then a removal must have happened.
                if (!action.payload && action.meta.arg.appointment && !action.meta.arg.patient) {
                    const indexOfData = (state.data as IWorkListAppointmentView[]).findIndex(
                        (item) => item?.id === action.meta.arg.appointment?.id,
                    );
                    state.data = [...state.data.slice(0, indexOfData), ...state.data.slice(indexOfData + 1)];

                    state.messageBarMessage = 'Appointment has been saved and removed from worklist.';
                    state.messageBarType = MessageBarType.success;
                } else {
                    //Otherwise we find and update the worklist item
                    const indexOfData = (state.data as IWorkListAppointmentView[]).findIndex(
                        (item) => item?.id === action.payload?.id,
                    );
                    if (indexOfData > -1) state.data[indexOfData] = action.payload;

                    state.messageBarMessage = 'Patient/appointment has been updated successfully.';
                    state.messageBarType = MessageBarType.success;
                }
            }
            state.saving = LoadingStatus.Completed;
            if (state.openLayers.EditPatientPanel) state.openLayers.EditPatientPanel = false;
            if (state.openLayers.CancelAppointmentModal) state.openLayers.CancelAppointmentModal = false;
        })
        .addCase(updateWorkListAppointmentData.rejected, (state, action) => {
            state.saving = LoadingStatus.Failed;
            state.messageBarMessage = 'Something went wrong.';
            state.messageBarType = MessageBarType.error;
        })
        .addCase(finishCheckin.pending, (state) => {
            if (state.currentWorkList) state.editWorkListItem = undefined;
        })
        .addCase(finishCheckout.pending, (state) => {
            if (state.currentWorkList) state.editWorkListItem = undefined;
        })
        //Successful finish checkout should remove the view from the worklist data.
        .addCase(finishCheckout.fulfilled, (state, { payload }) => {
            if (state.currentWorkList && hasFinishCheckoutSucceeded(payload)) {
                if (state.data) {
                    const { patientAppointment } = payload;
                    if (patientAppointment) {
                        const indexOfData = (state.data as IWorkListAppointmentView[]).findIndex(
                            (item) => item?.id === patientAppointment?.id,
                        );
                        if (indexOfData > -1)
                            state.data = [...state.data.slice(0, indexOfData), ...state.data.slice(indexOfData + 1)];
                    }
                }

                state.messageBarMessage = 'Patient appointment checked out successfully.';
                state.messageBarType = MessageBarType.success;
            }
        })
        .addCase(finishCheckin.fulfilled, (state, { payload }) => {
            if (state.currentWorkList && state.data) {
                const patientAppointment = payload;
                if (patientAppointment) {
                    const indexOfData = (state.data as IWorkListAppointmentView[]).findIndex(
                        (item) => item?.id === patientAppointment?.id,
                    );
                    if (indexOfData > -1)
                        state.data = [...state.data.slice(0, indexOfData), ...state.data.slice(indexOfData + 1)];
                }
                state.messageBarMessage = 'Patient appointment checked in successfully.';
                state.messageBarType = MessageBarType.success;
            }
        });
};
