import { Stack, useTheme } from '@fluentui/react';
import { CSSProperties } from 'react';
import ToothToolButtons from './ToothToolButtons';
import TypeSelectorButtons from './TypeSelectorButtons';

function ChartingMenuToolbar() {
    const { palette } = useTheme();

    const containerStyle: CSSProperties = {
        background: palette.neutralQuaternary,
        padding: 5,
    };
    const sectionTokens = { childrenGap: 3 };
    return (
        <Stack tokens={{ childrenGap: 5 }}>
            <Stack style={containerStyle} tokens={sectionTokens}>
                <TypeSelectorButtons />
            </Stack>
            <Stack style={containerStyle} tokens={sectionTokens}>
                <ToothToolButtons />
            </Stack>
        </Stack>
    );
}

export default ChartingMenuToolbar;
