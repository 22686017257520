import { ActionReducerMapBuilder, Dictionary, PayloadAction } from '@reduxjs/toolkit';
import IReportingGroup, { IReportingCategory, IReportingType } from 'api/models/reporting-group.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { IReportingState } from '../reporting.state';
import {
    createTenantReportingGroup,
    createTenantReportingCategory,
    getReportingTypes,
    getTenantReportingCategories,
    getTenantReportingGroups,
    updateTenantReportingGroup,
    updateTenantReportingCategory,
    deleteTenantReportingCategory,
} from './reporting-groups.actions';

const reportingGroupsReducers = {
    setSelectedReportingGroup: (state: IReportingState, action: PayloadAction<IReportingGroup>): void => {
        state.reportingGroups.selectedGroup = action.payload;
    },
    setSelectedReportingGroupFromId: (state: IReportingState, action: PayloadAction<string>): void => {
        const item = (state.reportingGroups.groups ?? {})[action.payload];

        state.reportingGroups.selectedGroup = item;
    },
    setSelectedReportingCategory: (state: IReportingState, action: PayloadAction<IReportingCategory>): void => {
        state.reportingGroups.selectedCategory = action.payload;
    },
    setSelectedReportingCategoryFromId: (state: IReportingState, action: PayloadAction<string>): void => {
        const item = (state.reportingGroups.categories ?? {})[action.payload];
        state.reportingGroups.selectedCategory = item;
    },
    cleanupSelectedReportingCategory: (state: IReportingState): void => {
        state.reportingGroups.selectedCategory = undefined;
    },

    //Edit Reporting Group
    updateSelectedReportingGroupProp: (
        state: IReportingState,
        action: PayloadAction<{ path: 'displayName' | 'description' | 'type'; value: string }>,
    ): void => {
        const { path, value } = action.payload;
        if (state.reportingGroups.selectedGroup) {
            state.reportingGroups.selectedGroup[path] = value;
            state.reportingGroups.selectedReportingGroupModified = true;
        }
    },
    //Edit Reporting Category
    updateSelectedReportingCategoryProp: (
        state: IReportingState,
        action: PayloadAction<{ path: 'displayName' | 'description'; value: string }>,
    ): void => {
        const { path, value } = action.payload;
        if (state.reportingGroups.selectedCategory) state.reportingGroups.selectedCategory[path] = value;
    },
    setSelectedReportingCategoryItems: (state: IReportingState, action: PayloadAction<string[]>): void => {
        if (state.reportingGroups.selectedCategory) state.reportingGroups.selectedCategory.items = action.payload;
    },
    addSelectedReportingCategoryItem: (state: IReportingState, action: PayloadAction<string>): void => {
        if (state.reportingGroups.selectedCategory) {
            const currentItems = state.reportingGroups.selectedCategory.items ?? [];
            state.reportingGroups.selectedCategory.items = [...currentItems, action.payload];
        }
    },
    removeSelectedReportingCategoryItem: (state: IReportingState, action: PayloadAction<string>): void => {
        if (state.reportingGroups.selectedCategory) {
            const currentItems = state.reportingGroups.selectedCategory.items ?? [];
            const indexOfItem = currentItems.findIndex((item) => item === action.payload);
            if (indexOfItem > -1)
                state.reportingGroups.selectedCategory.items = [
                    ...currentItems.slice(0, indexOfItem),
                    ...currentItems.slice(indexOfItem + 1),
                ];
        }
    },
    removeSelectedReportingCategory: (state: IReportingState, action: PayloadAction<IReportingCategory>): void => {
        if (state.reportingGroups.selectedGroup) {
            const currentCategories = state.reportingGroups.selectedGroup.reportingCategories ?? [];
            const indexOfCategory = currentCategories.findIndex((item) => item === action.payload.id);
            if (indexOfCategory > -1)
                state.reportingGroups.selectedGroup.reportingCategories = [
                    ...currentCategories.slice(0, indexOfCategory),
                    ...currentCategories.slice(indexOfCategory + 1),
                ];
        }
    },

    cleanupReportingGroup: (state: IReportingState): void => {
        state.reportingGroups.selectedGroup = undefined;
        state.reportingGroups.selectedReportingGroupModified = false;
    },
};

export default reportingGroupsReducers;

export const reportingGroupsExtraReducers = (
    builder: ActionReducerMapBuilder<IReportingState>,
): ActionReducerMapBuilder<IReportingState> =>
    builder
        // Reporting Groups
        // [GET]
        .addCase(getTenantReportingGroups.pending, (state: IReportingState) => {
            state.reportingGroups.loadingGroups = LoadingStatus.Pending;
        })
        .addCase(
            getTenantReportingGroups.fulfilled,
            (state: IReportingState, action: PayloadAction<Dictionary<IReportingGroup>>) => {
                state.reportingGroups.loadingGroups = LoadingStatus.Completed;
                state.reportingGroups.groups = action.payload;
            },
        )
        .addCase(getTenantReportingGroups.rejected, (state: IReportingState) => {
            state.reportingGroups.loadingGroups = LoadingStatus.Failed;
        })
        // [CREATE]
        .addCase(createTenantReportingGroup.pending, (state: IReportingState) => {
            state.reportingGroups.loadingGroup = LoadingStatus.Pending;
        })
        .addCase(createTenantReportingGroup.fulfilled, (state: IReportingState, action: PayloadAction<IReportingGroup>) => {
            const { id } = action.payload;
            state.reportingGroups.loadingGroup = LoadingStatus.Completed;
            if (state.reportingGroups.groups) state.reportingGroups.groups[id] = action.payload;
        })
        .addCase(createTenantReportingGroup.rejected, (state: IReportingState) => {
            state.reportingGroups.loadingGroup = LoadingStatus.Failed;
        })
        // [UPDATE]
        .addCase(updateTenantReportingGroup.pending, (state: IReportingState) => {
            state.reportingGroups.loadingGroup = LoadingStatus.Pending;
        })
        .addCase(updateTenantReportingGroup.fulfilled, (state: IReportingState, action: PayloadAction<IReportingGroup>) => {
            const { id } = action.payload;
            state.reportingGroups.loadingGroup = LoadingStatus.Completed;
            if (state.reportingGroups.selectedGroup?.id === id) {
                state.reportingGroups.selectedGroup = action.payload;
                state.reportingGroups.selectedReportingGroupModified = false;
            }
            if (state.reportingGroups.groups) state.reportingGroups.groups[id] = action.payload;
        })
        .addCase(updateTenantReportingGroup.rejected, (state: IReportingState) => {
            state.reportingGroups.loadingGroup = LoadingStatus.Failed;
        })
        // Reporting Categories
        // [GET]
        .addCase(getTenantReportingCategories.pending, (state: IReportingState) => {
            state.reportingGroups.loadingCategories = LoadingStatus.Pending;
        })
        .addCase(
            getTenantReportingCategories.fulfilled,
            (state: IReportingState, action: PayloadAction<Dictionary<IReportingCategory>>) => {
                state.reportingGroups.loadingCategories = LoadingStatus.Completed;
                state.reportingGroups.categories = action.payload;
            },
        )
        .addCase(getTenantReportingCategories.rejected, (state: IReportingState) => {
            state.reportingGroups.loadingCategories = LoadingStatus.Failed;
        })
        // [CREATE]
        .addCase(createTenantReportingCategory.pending, (state: IReportingState) => {
            state.reportingGroups.loadingCategory = LoadingStatus.Pending;
        })
        .addCase(
            createTenantReportingCategory.fulfilled,
            (state: IReportingState, action: PayloadAction<{ category: IReportingCategory; group: IReportingGroup }>) => {
                const { category, group } = action.payload;
                state.reportingGroups.loadingCategory = LoadingStatus.Completed;

                if (state.reportingGroups.groups) state.reportingGroups.groups[group.id] = group;
                if (state.reportingGroups.selectedGroup?.id === group.id) {
                    const { displayName, description } = state.reportingGroups.selectedGroup; //user modified data
                    const newGroup: IReportingGroup = {
                        ...group, //non-user modified data - this has the latest etag
                        //We only want to replace the data that the can edit with the data from state.
                        //This is in case the user has made edits they don't want to lose when creating a category.
                        displayName,
                        description,
                    };
                    state.reportingGroups.selectedGroup = newGroup;
                }

                if (state.reportingGroups.categories) state.reportingGroups.categories[category.id] = category;
                if (state.reportingGroups.selectedCategory?.id === category.id) state.reportingGroups.selectedCategory = category;
            },
        )
        .addCase(createTenantReportingCategory.rejected, (state: IReportingState) => {
            state.reportingGroups.loadingCategory = LoadingStatus.Failed;
        })
        .addCase(deleteTenantReportingCategory.pending, (state: IReportingState) => {
            state.reportingGroups.loadingCategory = LoadingStatus.Pending;
        })
        .addCase(
            deleteTenantReportingCategory.fulfilled,
            (state: IReportingState, action: PayloadAction<{ category: IReportingCategory; group: IReportingGroup }>) => {
                const { category, group } = action.payload;
                state.reportingGroups.loadingCategory = LoadingStatus.Completed;

                if (state.reportingGroups.groups) state.reportingGroups.groups[group.id] = group;
                if (state.reportingGroups.selectedGroup?.id === group.id) {
                    const { displayName, description } = state.reportingGroups.selectedGroup; //user modified data
                    const newGroup: IReportingGroup = {
                        ...group, //non-user modified data - this has the latest etag
                        //We only want to replace the data that the can edit with the data from state.
                        //This is in case the user has made edits they don't want to lose when creating a category.
                        displayName,
                        description,
                    };
                    state.reportingGroups.selectedGroup = newGroup;
                }

                if (state.reportingGroups.categories) state.reportingGroups.categories[category.id] = category;
                if (state.reportingGroups.selectedCategory?.id === category.id) state.reportingGroups.selectedCategory = category;
            },
        )
        .addCase(deleteTenantReportingCategory.rejected, (state: IReportingState) => {
            state.reportingGroups.loadingCategory = LoadingStatus.Failed;
        })
        // [UPDATE]
        .addCase(updateTenantReportingCategory.pending, (state: IReportingState) => {
            state.reportingGroups.loadingCategory = LoadingStatus.Pending;
        })
        .addCase(updateTenantReportingCategory.fulfilled, (state: IReportingState, action: PayloadAction<IReportingCategory>) => {
            const { id } = action.payload;
            state.reportingGroups.loadingCategory = LoadingStatus.Completed;
            if (state.reportingGroups.selectedCategory?.id === id) state.reportingGroups.selectedCategory = action.payload;
            if (state.reportingGroups.categories) state.reportingGroups.categories[id] = action.payload;
        })
        .addCase(updateTenantReportingCategory.rejected, (state: IReportingState) => {
            state.reportingGroups.loadingCategory = LoadingStatus.Failed;
        })

        // Reporting Types
        .addCase(getReportingTypes.pending, (state: IReportingState) => {
            state.reportingGroups.loadingTypes = LoadingStatus.Pending;
        })
        .addCase(getReportingTypes.fulfilled, (state: IReportingState, action: PayloadAction<Dictionary<IReportingType>>) => {
            state.reportingGroups.loadingTypes = LoadingStatus.Completed;
            state.reportingGroups.types = action.payload;
        })
        .addCase(getReportingTypes.rejected, (state: IReportingState) => {
            state.reportingGroups.loadingTypes = LoadingStatus.Failed;
        });
