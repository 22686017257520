import * as PIXI from 'pixi.js-legacy';
import Tooth from './Tooth';
import ToothOverlay from './ToothOverlay';

export default class OpenContact extends ToothOverlay {
    private line1 = new PIXI.Graphics();
    private line2 = new PIXI.Graphics();
    private _toothWidth: number;
    private _position: 'Mesial' | 'Distal';

    constructor(tooth: Tooth, position: 'Mesial' | 'Distal') {
        super(tooth);
        this._toothWidth = tooth.getToothWidth;
        this._position = position;
        this.zIndex = 3;
        this._createGraphics();
    }

    private _createGraphics() {
        const lineLength = 100;
        const linePadding = 9;

        const { dentitionMode } = this.tooth.data;

        const dentitionY =
            dentitionMode === 'Primary' || dentitionMode === 'PrimarySupernumerary' ? (this.getIsBottomRow ? 10 : -10) : 0;
        const yPosition = this.getIsBottomRow
            ? this.tooth.height / 2 + 100 + dentitionY
            : -this.tooth.height / 2 - 70 + dentitionY;

        const xPositionDistal = 0 + linePadding;
        const xPositionMesial = this._toothWidth - linePadding;

        const isRightSideOfMouth = !this.tooth.getIsLeftMouth;

        const xPosition =
            this._position === 'Distal'
                ? isRightSideOfMouth
                    ? xPositionMesial
                    : xPositionDistal
                : isRightSideOfMouth
                ? xPositionDistal
                : xPositionMesial;

        this.line1.position.set(xPosition, yPosition);
        this.line1.lineStyle(3, 0x000000).lineTo(0, lineLength);
        this.line2.position.set(xPosition + linePadding, yPosition);
        this.line2.lineStyle(3, 0x000000).lineTo(0, lineLength);

        this.addChild(this.line1, this.line2);
    }
}
