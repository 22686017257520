import { IStackTokens, Stack } from '@fluentui/react';
import { useAccount, useSelector } from 'hooks';
import usePermissions from 'hooks/store/usePermissions';
import useProviders from 'hooks/store/useProviders';
import { useCallback } from 'react';
import { FeatureFlag, selectActiveFeatureFlagsLookup } from 'state/slices/tenant/feature-flags.slice';
import Amendments from './Amendments';
import CorrectionAmendments from './CorrectionAmendments';
import ReadyForAttestation from './ReadyForAttestation';

function ProviderTaskTiles() {
    const { account } = useAccount();
    const { providersAsList } = useProviders();
    const [_, isGlobalAdmin] = usePermissions();
    const featureFlags = useSelector(selectActiveFeatureFlagsLookup);
    const providerId = account.data?.identity?.id;

    const stackTokens: IStackTokens = {
        childrenGap: 10,
    };

    const hasECRAccess = useCallback(() => {
        if (isGlobalAdmin) return true;
        return !!featureFlags.has(FeatureFlag.ECR);
    }, [isGlobalAdmin, featureFlags]);

    const provider = providersAsList.find((provider) => provider?.id === providerId);
    const isTreatingProvider = provider?.isTreatingProvider || provider?.isAttestingHygienist;

    if (!isTreatingProvider) return null;

    return (
        <Stack wrap horizontal tokens={stackTokens}>
            <Stack.Item grow>
                <ReadyForAttestation />
            </Stack.Item>
            <Stack.Item grow>
                <Amendments />
            </Stack.Item>
            {hasECRAccess() && (
                <Stack.Item grow>
                    <CorrectionAmendments />
                </Stack.Item>
            )}
        </Stack>
    );
}

export default ProviderTaskTiles;
