import {
    SelectionMode,
    DetailsRow,
    IDetailsRowProps,
    IDetailsHeaderProps,
    DetailsHeader,
    MessageBar,
    MessageBarType,
    Stack,
    IStyle,
    useTheme,
} from '@fluentui/react';
import { IBillingProcedure } from 'api/models/billing-procedure.model';
import { SortableDetailsList } from 'components';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';

function EncounterWorkListProceduresDetailsList({
    items,
    columns,
}: {
    items?: IBillingProcedure[];
    columns?: ISortableColumn<IBillingProcedure>[];
}): JSX.Element {
    const theme = useTheme();
    const onRenderProcedureRow = (props: IDetailsRowProps | undefined): JSX.Element | null => {
        if (props) {
            return (
                <DetailsRow
                    {...props}
                    styles={{
                        root: {
                            backgroundColor: theme.palette.neutralLighterAlt,
                        },
                    }}
                />
            );
        }
        return null;
    };

    const onRenderProcedureHeader = (props: IDetailsHeaderProps | undefined): JSX.Element | null => {
        if (props) {
            return (
                <DetailsHeader
                    {...props}
                    styles={{ root: { padding: 0, borderTop: `1px solid ${theme.palette.neutralTertiaryAlt}` } }}
                />
            );
        }
        return null;
    };

    const hasProcedures = items?.length ? true : false;
    const spacingStyle: IStyle = { marginBottom: 15 };

    if (!hasProcedures)
        return (
            <Stack styles={{ root: spacingStyle }}>
                <MessageBar messageBarType={MessageBarType.info}>
                    This encounter does not have any associated procedures
                </MessageBar>
            </Stack>
        );
    return (
        <SortableDetailsList
            items={items ?? []}
            selectionMode={SelectionMode.none}
            onRenderDetailsHeader={onRenderProcedureHeader}
            onRenderRow={onRenderProcedureRow}
            columns={columns ?? []}
            styles={{ root: spacingStyle }}
            sortOnMount={true}
            sortColumns={['authNumber']}
            showGrid
        />
    );
}

export default EncounterWorkListProceduresDetailsList;
