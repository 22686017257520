import { PayloadAction } from '@reduxjs/toolkit';
import { IPerioExam } from 'api/models/perio-exam.model';
import { ChartingState } from '../chart/chart.slice';

const perioPanelReducers = {
    setPerioPanelOpen: (state: ChartingState, action: PayloadAction<boolean>): void => {
        state.perioPanel.isOpen = action.payload;
        state.perioPanel.isComparing = false;
    },
    setPerioComparePanelOpen: (state: ChartingState, action: PayloadAction<boolean>): void => {
        state.perioPanel.isOpen = action.payload;
        state.perioPanel.isComparing = true;
    },
    setPerioExamsToCompare: (state: ChartingState, action: PayloadAction<IPerioExam[]>): void => {
        state.perioPanel.examsToCompare = action.payload;
        state.perioPanel.isComparing = true;
    },
    resetPerioExamPanel: (state: ChartingState, action: PayloadAction<boolean>): void => {
        state.perioPanel.isComparing = action.payload;
    },
};

export default perioPanelReducers;
