import { Section } from 'components';
import PatientInfo from './components/PatientInfo';
import PatientPhoneNumbers from './components/PatientPhoneNumbers';

import PatientDetails from './components/PatientDetails';
import PatientDocuments from './components/PatientDocuments';
import MailingAddress from './components/MailingAddress';

export enum BasicDetailsFields {
    FirstName = 'First Name',
    LastName = 'Last Name',
    DateOfBirth = 'Date of Birth',
    StreetAddress1 = 'Street Address 1',
    LegalSex = 'Legal Sex',
    City = 'City',
    State = 'State',
    Zip = 'Zip',
    MailingStreet1 = 'Mailing Street 1',
    MailingCity = 'Mailing City',
    MailingState = 'Mailing State',
    MailingZip = 'Mailing Zip',
    PhoneNumbers = ' Phone Numbers',
}

function BasicDetails(): JSX.Element {
    return (
        <Section>
            <PatientInfo />
            <MailingAddress />
            <PatientPhoneNumbers />
            <PatientDetails />
            <PatientDocuments />
        </Section>
    );
}

export default BasicDetails;
