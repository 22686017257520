import {
    CommandBar,
    DefaultButton,
    ICommandBarItemProps,
    IDropdownOption,
    Stack,
    StackItem,
    TooltipHost,
    useTheme,
} from '@fluentui/react';
import { Field } from 'components';

import { usePerioExams, useSelector } from 'hooks';

import { RouteParams } from 'interfaces/route-params';
import { orderBy } from 'lodash';
import { ToothButton } from 'pages/components';

import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { currentPerioExam, selectPerioExamsWithEncounterDate } from 'state/slices/charting/perio-exams/perio-exams.selectors';
import { setPerioComparePanelOpen, setPerioPanelOpen } from 'state/slices/charting/chart/chart.slice';
import { allPatientEncounters } from 'state/slices/patient/patient.selectors';

import { LoadingStatus } from 'interfaces/loading-statuses';
import { convertFromMilitaryTime } from 'utils/convertFromMilitaryTime';
function ProbingDepthButtons() {
    const { setProbingDepth } = usePerioExams();

    const probingDepths = [10, 11, 12, 13, 14, 15];
    const depthButtons = probingDepths.map((depth) => (
        <TooltipHost key={depth} content={`Enter probing depth of ${depth}mm`}>
            <ToothButton
                onClick={() => {
                    setProbingDepth({ value: depth });
                }}
                toothId={depth}
            />
        </TooltipHost>
    ));

    return (
        <Stack style={{ paddingLeft: 10, paddingRight: 10 }} tokens={{ childrenGap: 3 }} verticalAlign="center" horizontal>
            {depthButtons}
        </Stack>
    );
}

export default function PerioToolbar(): JSX.Element {
    const { createPerioExam, selectUniqPerioExamsDates, setPerioExamHistorySearch, saving } = usePerioExams();
    const { perioId, encounterId, tenantId, patientId } = useParams<RouteParams>();
    const { palette } = useTheme();
    const { push } = useHistory();
    const dispatch = useDispatch();
    const perioExams = useSelector(selectPerioExamsWithEncounterDate);

    const _allPatientEncounters = useSelector(allPatientEncounters);
    const _currentPerioExam = useSelector(currentPerioExam);
    const selectedTheme = useSelector((state) => state.ui.selectedTheme);
    const existingExam = () => (perioExams.find((res) => res.encounterId === encounterId) ? true : false);

    const openPanel = () => {
        dispatch(setPerioPanelOpen(true));
    };

    const openComparePanel = () => {
        dispatch(setPerioComparePanelOpen(true));
    };

    const backToPerioExam = () => {
        if (encounterId) {
            push(`/${tenantId}/patient/${patientId}/encounter/${encounterId}/perio`);
        } else {
            push(`/${tenantId}/patient/${patientId}/perio`);
        }
    };

    const dropdownOptions: IDropdownOption[] = selectUniqPerioExamsDates.map((date) => ({
        key: date.key,
        text: date.text,
    }));

    const searchComponent = () => {
        return (
            <Field.SearchCombo
                onChange={(ev, option) => {
                    if (option) {
                        const perioExamDate = option.key.toString();
                        const perioExamsWithDate = perioExams.filter((exam) => {
                            return exam?.encounter?.encounterDate
                                ? exam.encounter.encounterDate === perioExamDate // NOTE: Come back to refactor perio date hist later.
                                : false;
                        });

                        if (encounterId) {
                            push(`/${tenantId}/patient/${patientId}/encounter/${encounterId}/perio/${perioExamsWithDate[0].id}`);
                        } else {
                            push(`/${tenantId}/patient/${patientId}/perio/${perioExamsWithDate[0].id}`);
                        }
                    }
                }}
                maxResults={20}
                placeholder="Exam History"
                style={{ minWidth: 120, paddingTop: 6 }}
                options={dropdownOptions}
                threshold={0.4}
            />
        );
    };

    const _landingPerio: ICommandBarItemProps[] = [
        {
            key: 'newExam',
            text: 'New Exam',
            iconProps: { iconName: 'Add' },
            disabled: perioId || !encounterId ? true : false || existingExam() || saving === LoadingStatus.Pending ? true : false,
            onClick: () => createPerioExam(),
        },
        {
            key: 'search',
            disabled: perioId ? true : false,
            commandBarButtonAs: () => searchComponent(),
        },
    ];

    const encounter = _allPatientEncounters.find((encounter) => encounter.id === _currentPerioExam.encounterId);
    const date = encounter && encounter?.encounterDate ? encounter.encounterDate : '';
    const time = _currentPerioExam && _currentPerioExam?.appointmentStartTime ? ` (${convertFromMilitaryTime(_currentPerioExam.appointmentStartTime)})` : '';

    const examPerio: ICommandBarItemProps[] = [
        {
            key: 'newExam',
            text: 'New Exam',
            iconProps: { iconName: 'Add' },
            disabled: perioId || !encounterId ? true : false || existingExam() || saving === LoadingStatus.Pending ? true : false,
            onClick: () => createPerioExam(),
        },
        {
            key: 'perioExam',
            text: 'Perio. Exam',
            iconProps: { iconName: 'Back' },
            onClick: () => {
                backToPerioExam();
            },
            disabled: !perioExams.length,
        },
        {
            key: 'perio Date',
            text: `Exam date: ${date ? `${date}${time}` : 'Unkown Date'} `,
            style: { color: selectedTheme === 'light' ? 'black' : 'white' },
            disabled: true,
        },
    ];

    const getItems = !perioId ? _landingPerio : examPerio;

    return (
        <>
            <Stack
                style={{ background: palette.white, height: 44, maxHeight: 44, paddingRight: 5 }}
                horizontal
                verticalAlign="center"
                horizontalAlign="space-between"
                tokens={{ childrenGap: 10 }}
            >
                <StackItem grow>
                    <CommandBar styles={{ root: { paddingLeft: 0, maxWidth: '15rem' } }} items={getItems} />
                </StackItem>
                {perioId ? (
                    <StackItem>
                        <ProbingDepthButtons />
                    </StackItem>
                ) : (
                    <>
                        <StackItem>
                            <DefaultButton text="Compare Exams" onClick={openComparePanel} disabled={perioExams.length < 2} />
                        </StackItem>
                        <StackItem>
                            <DefaultButton
                                text="Print"
                                iconProps={{ iconName: 'Print' }}
                                onClick={openPanel}
                                disabled={!perioExams.length}
                            />
                        </StackItem>
                    </>
                )}
            </Stack>
        </>
    );
}
