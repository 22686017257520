import { IComboBox, IComboBoxOption } from '@fluentui/react';
import { ChartProcedureDiagnosis } from 'api/models/chart.model';
import { Field } from 'components';
import { useSelector } from 'hooks';
import { FormEvent } from 'react';
import { diagnosesOptionsList } from 'state/slices/tenant/diagnoses.slice';

type Props = {
    /**
     *
     * @description Selected diagnosis ids.
     * @type {ChartProcedureDiagnosis[]}
     */
    selectedDiagnoses?: ChartProcedureDiagnosis[];
    /**
     *
     * @description Callback to update id list of diagnoses.
     * @type {(items: ChartProcedureDiagnosis[]) => void}
     */
    updateDiagnoses?: (items: ChartProcedureDiagnosis[]) => void;
    /**
     *
     * @description To Disable the dropdown if certain criteria are met.
     * @type {boolean}
     */
    isDisabled?: boolean;
};
/**
 *
 * @description Search and add DX dropdown
 * @export
 * @param {Props} { selectedDiagnoses, updateDiagnoses }
 * @return {*}  {JSX.Element}
 */
export default function DiagnosisDropdown({ selectedDiagnoses, updateDiagnoses, isDisabled }: Props): JSX.Element {
    const diagnosisOptions = useSelector(diagnosesOptionsList);

    const isSelected = (id: string) => (selectedDiagnoses ? selectedDiagnoses.findIndex((dx) => dx.id === id) > -1 : false);

    function _updateDiagnoses(event: FormEvent<IComboBox>, option?: IComboBoxOption | undefined) {
        if (option) toggleDiagnosisItem(option.data as ChartProcedureDiagnosis);
    }

    const toggleDiagnosisItem = (diagnosis: ChartProcedureDiagnosis) => {
        if (updateDiagnoses)
            if (isSelected(diagnosis.id)) {
                const items = selectedDiagnoses ? selectedDiagnoses.filter((dx) => dx.id !== diagnosis.id) : [];
                updateDiagnoses(items);
            } else {
                updateDiagnoses(selectedDiagnoses ? [...selectedDiagnoses, diagnosis] : [diagnosis]);
            }
    };

    //Make sure our options are not the same as DX that are already selected.
    const filteredOptions = diagnosisOptions.filter((option) =>
        selectedDiagnoses?.length ? selectedDiagnoses.findIndex((dx) => dx.id === option.key) === -1 : true,
    );

    return (
        <Field.SearchCombo
            disabled={isDisabled}
            onChange={_updateDiagnoses}
            virutalized
            //Keeps the selectedKey of the dropdown empty. Even when the user selects an option
            selectedKey={null}
            maxResults={1000}
            threshold={0.2}
            options={filteredOptions}
            styles={{ optionsContainerWrapper: { maxHeight: '50vw' } }}
            placeholder="Search and add Dx"
        />
    );
}
