import { MessageBar, SelectionMode, Stack } from '@fluentui/react';
import { SortableDetailsList } from 'components';
import ClaimsHistoryHeader from './ClaimsHistoryHeader';
import { useSelector } from 'react-redux';
import {
    selectEncounterClaimsSummaryCorrespondence,
    selectEncounterClaimsSummaryLoadingStatus,
} from 'state/slices/ledger/claim-history/claim-history.selectors';
import { LoadingStatus } from 'interfaces/loading-statuses';
import UserDisplayName from 'components/UserDisplayName';
import { IEncounterCorrespondence } from 'api/models/encounter-claim-summary.model';
import { format } from 'date-fns';

export default function EncounterCorrespondence() {
    const loading = useSelector(selectEncounterClaimsSummaryLoadingStatus) === LoadingStatus.Pending;
    const correspondenceItems = useSelector(selectEncounterClaimsSummaryCorrespondence);

    return (
        <Stack grow>
            <ClaimsHistoryHeader heading="Encounter Correspondence" />
            {!loading && !correspondenceItems.length ? (
                <MessageBar>No encounter correspondence items to show.</MessageBar>
            ) : (
                <SortableDetailsList<IEncounterCorrespondence>
                    selectionMode={SelectionMode.none}
                    items={correspondenceItems}
                    showGrid
                    enableShimmer={loading}
                    shimmerLines={2}
                    sortColumns={['date']}
                    sortOnMount
                    initialSortDirection={['desc']}
                    compact
                    columns={[
                        {
                            key: 'date',
                            fieldName: 'date',
                            name: 'Date',
                            minWidth: 80,
                            onRender: (item) => {
                                return <span>{item?.date ? format(new Date(item.date), 'MM/dd/yyyy h:mm a') : ''}</span>;
                            },
                            maxWidth: 120,
                        },
                        {
                            key: 'user',
                            fieldName: 'user',
                            name: 'User',
                            minWidth: 80,
                            onRender: (item) => {
                                return <UserDisplayName userId={item?.user} />;
                            },
                            maxWidth: 180,
                        },
                        {
                            key: 'message',
                            fieldName: 'message',
                            name: 'Correspondence',
                            minWidth: 80,
                            onRender: (item) => {
                                const message = item?.message ?? '';
                                return <span title={message}>{message}</span>;
                            },
                        },
                    ]}
                />
            )}
        </Stack>
    );
}
