import Tooth from './Tooth';
import * as PIXI from 'pixi.js-legacy';
import ToothOverlay from './ToothOverlay';
import { IChartRenderProcedure } from 'api/models/tooth.model';
import { ProcedureActionType } from 'state/slices/charting/chart/chart.slice';
import { ChartProcedureStatus } from 'api/models/chart.model';

export default class Pontic extends ToothOverlay {
    private line1 = new PIXI.Graphics();
    private line2 = new PIXI.Graphics();

    private line3 = new PIXI.Graphics();
    private line4 = new PIXI.Graphics();

    constructor(tooth: Tooth) {
        super(tooth);
        this.name = 'Pontic';

        const supportedProcedures = [tooth.isPontic()].filter((item) => !item !== undefined) as IChartRenderProcedure[];

        if (supportedProcedures.length) {
            this._createGraphics(tooth, supportedProcedures);
        }

        this.zIndex = 1;
    }

    private _createGraphics(tooth: Tooth, procs: IChartRenderProcedure[]) {
        const proc = procs[0];

        this._setColor(proc);

        if (tooth._frontContainer && tooth._surfacesContainer && tooth._backContainer) {
            this.tooth.addColorOverlayToToothPart(tooth._surfacesContainer, proc);
            this.tooth.addColorOverlayToToothPart(tooth._backContainer, proc);

            const linePadding = 8;

            const surfacesX = tooth._surfacesContainer.x;
            const surfacesHeight = tooth._surfacesContainer.height;
            const surfacesWidth = tooth._surfacesContainer.width;
            const surfacesY = tooth._surfacesContainer.y;
            const surfacesTopY = surfacesY - linePadding;
            const surfacesBottomY = surfacesY + surfacesHeight + linePadding;

            this.line1.position.set(surfacesX, surfacesTopY);
            this.line1.lineStyle(8, this.color).lineTo(surfacesWidth, 0);

            this.line2.position.set(surfacesX, surfacesBottomY);
            this.line2.lineStyle(8, this.color).lineTo(surfacesWidth, 0);

            if (proc.type === ProcedureActionType.Treatment && proc.status !== ChartProcedureStatus.Completed) {
                const toothExtraction = this.tooth.getToothExtraction();
                if (this.tooth.isMissing() || toothExtraction?.status === 'Completed') {
                    tooth._frontContainer._rootSprite.alpha = 0;
                } else if (toothExtraction?.status === 'Pending') {
                    const rootToHeight = this.getIsBottomRow
                        ? tooth._frontContainer._rootSprite.height / 4
                        : tooth._frontContainer._rootSprite.height / 4;

                    const rootY = this.getIsBottomRow ? this.tooth.y - 170 : -this.tooth.y + 130;

                    this.line3.position.set(tooth._frontContainer._rootSprite.x + linePadding, rootY);
                    this.line3
                        .lineStyle(4, this.color)
                        .lineTo(tooth._frontContainer._rootSprite.width - linePadding, rootToHeight);

                    this.line4.position.set(
                        tooth._frontContainer._rootSprite.x + tooth._frontContainer._rootSprite.width - linePadding,
                        rootY,
                    );
                    this.line4
                        .lineStyle(4, this.color)
                        .lineTo(-tooth._frontContainer._rootSprite.width + linePadding, rootToHeight);
                }
            } else {
                tooth._frontContainer._rootSprite.alpha = 0;
            }

            this.addChild(this.line1, this.line2, this.line3, this.line4);
        }
    }
}
