import { useSelector } from 'react-redux';
import UACDistributionCard, { UACDistributionCardProps } from './UACDistributionCard';
import { selectUACDistributionPatientEstimateAmounts } from 'state/slices/ledger/uac-distribution/uac-distribution.selectors';
import { selectPatientPaymentsAndAdjustmentsBillingProceduresLoadingStatus } from 'state/slices/ledger/patient-payments-and-adjustments/patient-payments-and-adjustments.selectors';
import { usdCurrencyFormatter } from 'utils';

export default function UACDistributionPatientEstimateCard({ onLinkClick }: Pick<UACDistributionCardProps, 'onLinkClick'>) {
    const loading = useSelector(selectPatientPaymentsAndAdjustmentsBillingProceduresLoadingStatus);

    const { estimateTransactionsTotal, totalPatientEstimate, patientEstimateRemainingAmount } = useSelector(
        selectUACDistributionPatientEstimateAmounts,
    );

    return (
        <UACDistributionCard loading={loading} title="Patient Estimate" onLinkClick={onLinkClick}>
            <span>Total: {usdCurrencyFormatter.format(totalPatientEstimate)}</span>
            <span>Distributed: {usdCurrencyFormatter.format(estimateTransactionsTotal)}</span>
            <span>Remaining: {usdCurrencyFormatter.format(patientEstimateRemainingAmount)}</span>
        </UACDistributionCard>
    );
}
