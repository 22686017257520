import { createAsyncThunk, Dictionary } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import { ITenantUser } from 'api/models/tenant.model';

export const getUsers = createAsyncThunk<
    Dictionary<ITenantUser>,
    {
        tenantId: string;
    }
>('getUsers', async ({ tenantId }) => {
    const { data: users } = await dentalApi.getTenantUsers(tenantId);
    return users;
});

export const getUserById = createAsyncThunk<
    ITenantUser,
    {
        tenantId: string;
        userId: string;
    }
>('getUserById', async ({ tenantId, userId: UserId }) => {
    const result = await dentalApi.getTenantUserById(tenantId, UserId);
    return result.data;
});
