import { upsertPatientClinicalAlertAction } from 'state/slices/clinical-alert/clincal-alerts.actions';
import {
    upsertPatientAppointmentAllocationEncounterData,
    upsertPatientAppointmentAllocations,
} from 'state/slices/clinical-huddle/clinical-huddle.actions';
import { insertEncounter } from 'state/slices/encounter/encounter.actions';
import { insertAppointment } from 'state/slices/scheduling/scheduling.actions';
import { SignalRActionConfig, SignalRMessage } from './useSignalr';

export const schedulingSignalR: SignalRActionConfig = [
    {
        message: SignalRMessage.NewAppointment,
        action: insertAppointment,
    },
    {
        message: SignalRMessage.CancelledAppointment,
        action: insertAppointment,
    },
    {
        message: SignalRMessage.UpdatedPatientAppointmentAllocation,
        action: insertAppointment,
    },
    {
        message: SignalRMessage.UpdatedClinicalAlert,
        action: upsertPatientClinicalAlertAction,
    },
];

export const clinicalHuddleSignalR: SignalRActionConfig = [
    {
        message: SignalRMessage.NewAppointment,
        action: upsertPatientAppointmentAllocations,
    },
    {
        message: SignalRMessage.CancelledAppointment,
        action: upsertPatientAppointmentAllocations,
    },
    {
        message: SignalRMessage.UpdatedPatientAppointmentAllocation,
        action: upsertPatientAppointmentAllocations,
    },
    {
        message: SignalRMessage.UpdatedEncounter,
        action: upsertPatientAppointmentAllocationEncounterData,
    },
    {
        message: SignalRMessage.UpdatedClinicalAlert,
        action: upsertPatientClinicalAlertAction,
    },
];
// or the reducer just handles instead of firing multiple actions, it's all the same state so if we send it the patientId we should be able to decipher the state logic change
