export type AdjustmentsSummaryReportQuery = {
    startDate?: string;
    endDate?: string;
    adjustmentSource?: AdjustmentSource;
    adjustmentTypes?: string[];
    treatingProviderIds?: string[];
    locationOfCareIds?: string[];
    procedureIds?: string[];
    insuranceCarrierIds?: string[];
    groupCol1?: AdjustmentDetailsGroup;
    groupCol2?: AdjustmentDetailsGroup;
};

export enum AdjustmentSource {
    All = 0,
    Patient = 1,
    Insurance = 2,
}

export const adjustmentSources = [AdjustmentSource.All, AdjustmentSource.Insurance, AdjustmentSource.Patient];

export enum AdjustmentDetailsGroup {
    LocationOfCare = 'Location Of Care',
    TreatingProvider = 'Treating Provider',
    InsuranceCarrier = 'Insurance Carrier',
    AdjustmentType = 'Adjustment Type',
    CPTCode = 'CPT Code',
}
