import { Checkbox, Label, Spinner, Stack } from '@fluentui/react';
import { useAccount, useEncounter, useSelector } from 'hooks';
import useEncounterId from 'hooks/useEncounterId';
import { SyncStatus } from 'interfaces/syncing-statuses';
import { selectPatientEncounterSaving } from 'state/slices/encounter/encounter.selectors';

export default function ProblemsReviewed(): JSX.Element | null {
    const encounterId = useEncounterId();
    const { setCurrentEncounterDataProp, patientEncounter, saveCurrentEncounter } = useEncounter();
    const savingPatientEncounter = useSelector(selectPatientEncounterSaving);
    const { account } = useAccount();

    const isSaving = savingPatientEncounter === SyncStatus.Pending;

    if (!encounterId || !patientEncounter) return null;

    function onProblemsListReviewedClick() {
        if (!patientEncounter?.isProblemsReviewed === true) {
            setCurrentEncounterDataProp('problemsReviewedDate', new Date().toISOString());
            setCurrentEncounterDataProp('problemsReviewedBy', account.data?.identity?.id);
        } else {
            setCurrentEncounterDataProp('problemsReviewedDate', undefined);
            setCurrentEncounterDataProp('problemsReviewedBy', undefined);
        }
        setCurrentEncounterDataProp('isProblemsReviewed', !patientEncounter?.isProblemsReviewed);
        saveCurrentEncounter();
    }

    return (
        <Stack grow>
            {isSaving ? (
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Spinner />
                    <Label>Problems list reviewed</Label>
                </Stack>
            ) : (
                <Checkbox
                    label="Problems list reviewed"
                    checked={!!patientEncounter?.isProblemsReviewed}
                    onChange={onProblemsListReviewedClick}
                />
            )}
        </Stack>
    );
}
