import { createAsyncThunk } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import IPatient, { IAnnualIncome, IPovertyPercentage } from 'api/models/patient.model';
import { IPendingChartTreatmentPlanPhaseProcedure } from 'api/models/treatment-plan.model';
import axios from 'axios';
import ErrorTypes from 'state/errorTypes';
import { AppThunk, RootState } from 'state/store';
import { setSelectedPatient } from '../patient/patient.slice';
import { v4 as uuid } from 'uuid';
import { cleanupEditPatientPanel, setEditPatient, setEditPatientPanelOpen, setIsNewPatient } from './edit-patient.slice';
import { IUpcomingAppointment } from 'api/models/Scheduling/patientAppointment.model';
import schedulingApi from 'api/scheduling.api';
import { addPatient } from '../patient/patient.actions';
import { cleanupDocumentData } from '../documents/documents.slice';
import { push } from 'connected-react-router';
import { IAppointmentHistory } from 'api/models/appointment-history.model';
import { getPatientDocuments } from '../documents/documents.actions';
import { batch } from 'react-redux';

type EditPatientPayload = {
    patient: IPatient;
    unscheduledTreatmentPlanProcedures: IPendingChartTreatmentPlanPhaseProcedure[];
    appointmentHistory: IAppointmentHistory[];
    upcomingAppointments: IUpcomingAppointment[];
};

export const getEditPatient = createAsyncThunk<IPatient, { tenantId: string; patientId: string }>(
    'getEditPatient',
    async ({ tenantId, patientId }) => {
        const { data: patient } = await dentalApi.getPatient(tenantId, patientId);
        return patient;
    },
);

//Created thunk to handle automatic opening of panel when getting the edit patient.
//This is so the panel being opened can be controlled independently of the getPatientToEdit action.
export const getPatientToEditAndOpenPanel =
    (props: { tenantId: string; patientId: string }): AppThunk =>
    (dispatch) => {
        batch(() => {
            dispatch(setEditPatientPanelOpen(true));
            dispatch(getPatientToEdit(props));
        });
    };

export const getPatientToEdit = createAsyncThunk<EditPatientPayload, { tenantId: string; patientId: string }>(
    'getPatientToEdit',
    async ({ tenantId, patientId }, { dispatch }) => {
        const { data: patient } = await dentalApi.getPatient(tenantId, patientId);
        const { data: unscheduledTreatmentPlanProcedures } = await dentalApi.getPendingChartTreatmentPlanPhaseProcedures(
            tenantId,
            patientId,
        );
        const { data: appointmentHistory } = await dentalApi.getPatientPastAppointments(tenantId, patientId);
        const { data: upcomingAppointments } = await schedulingApi.getUpcomingPatientAppointments(tenantId, patientId);

        dispatch(getPatientDocuments({ tenantId, patientId: patientId }));

        const result: EditPatientPayload = {
            patient,
            unscheduledTreatmentPlanProcedures,
            appointmentHistory,
            upcomingAppointments,
        };

        return result;
    },
);

export const updatePatient = createAsyncThunk<
    IPatient,
    {
        tenantId: string;
        model: IPatient;
    },
    { rejectValue: string; state: RootState }
>('updatePatient', async ({ tenantId, model }, { rejectWithValue, dispatch, getState }) => {
    try {
        await dentalApi.updatePatient(tenantId, model);
        const { data: patient } = await dentalApi.getPatient(tenantId, model.id);

        const selectedPatientId = getState().patient.selectedPatient?.id;
        if (selectedPatientId === model.id) {
            dispatch(setSelectedPatient(patient));
        }

        return patient;
    } catch (err) {
        if (axios.isAxiosError(err) && err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        }
        if (axios.isAxiosError(err) && err.response && err.response.status === 412) {
            return rejectWithValue(ErrorTypes.ModifiedOutside);
        } else {
            return rejectWithValue('Unkonwn error');
        }
    }
});

export const upsertEditPatient =
    (tenantId: string): AppThunk =>
    (dispatch, getState) => {
        const state = getState();
        const patient = state.editPatient.patient;
        const isNewPatient = state.editPatient.isNewPatient;

        if (patient) {
            if (isNewPatient) {
                dispatch(addPatient({ tenantId, patient: patient }));
            } else {
                dispatch(updatePatient({ tenantId, model: patient }));
            }
        }
    };

export const editNewPatient =
    (tenantId: string): AppThunk<void> =>
    (dispatch) => {
        const newPatientModel: IPatient = {
            id: uuid(),
            isDeleted: false,
            mrn: '',
            title: '',
            lastName: '',
            firstName: '',
            middleName: '',
            suffix: '',
            dateOfBirth: '',
            chosenName: '',
            patientStatus: 'active',
            maidenName: '',
            dateOfDeath: '',
            ssn: '',
            legalSexId: '',
            genderIdentityId: '',
            sexualOrientationId: '',
            pronoun: '',
            preferredLanguage: '',

            isInterpreterNeeded: false,
            raceIds: [],
            ethnicityId: '',
            preferredContactMethod: '',
            phoneNumbers: [],
            emailAddress: '',
            guarantorAddressSameAsPatient: false,
            physicalAddress: {
                streetAddress1: '',
                streetAddress2: '',
                city: '',
                state: '',
                zip: '',
            },
            mailingAddress: {
                streetAddress1: '',
                streetAddress2: '',
                city: '',
                state: '',
                zip: '',
            },
            mailingAddressSameAsPhysicalAddress: true,
            guarantors: [],
            insurances: [],
            slidingFees: [],
            isSelfPay: false,
            selfPayAdjustmentType: '',
            references: {},
            uds: {
                id: uuid(),
                createdOn: '',
                createdBy: '',
                modifiedOn: '',
                modifiedBy: '',
                isDeleted: false,
                agriculturalWorker: '',
                agriculturalWorkerDetail: '',
                homelessStatus: '',
                homelessStatusDetail: '',
                schoolbasedPatient: '',
                veteranStatus: '',
                publicHousing: '',
                familySize: 0,
                familySizeDeclined: false,
                income: 0,
                slidingFeeIncomeStatus: '',
                incomePercentage: 0,
                documents: [],
            },
            signature: {
                privacyNoticeSignedDate: '',
                billingReleaseSignedDate: '',
                billingReleaseExpirationDate: '',
                benefitAssignmentSignedDate: '',
                benefitAssignmentExpirationDate: '',
                signatureSourceId: '',
                benefitAssignmentSigned: false,
                billingReleaseSigned: false,
                privacyNoticeSigned: false,
            },
            documents: [],
            clinicalDocuments: [],
        };
        dispatch(cleanupDocumentData());
        dispatch(push(`/${tenantId}/scheduling`));
        dispatch(setEditPatient(newPatientModel));
        dispatch(setIsNewPatient(true));
        dispatch(setEditPatientPanelOpen(true));
    };

export const addMissingPatientSignature = (): AppThunk<void> => (dispatch, getState) => {
    const patient = getState().editPatient.patient;
    if (patient) {
        const data: IPatient = {
            ...patient,
            signature: {
                privacyNoticeSignedDate: '',
                billingReleaseSignedDate: '',
                billingReleaseExpirationDate: '',
                benefitAssignmentSignedDate: '',
                benefitAssignmentExpirationDate: '',
                signatureSourceId: '',
                benefitAssignmentSigned: false,
                billingReleaseSigned: false,
                privacyNoticeSigned: false,
            },
        };
        dispatch(setEditPatient(data));
    }
};

export const discardPatientChanges = (): AppThunk<void> => (dispatch) => {
    // TODO: delete contacts that were created during new patient creation
    dispatch(cleanupEditPatientPanel());
    dispatch(setEditPatientPanelOpen(false));
};

export const getPovertyPercentage = createAsyncThunk<
    number,
    {
        model: IPovertyPercentage;
        tenantId: string;
    }
>('getPovertyPercentage', async ({ model, tenantId }) => {
    const result = await dentalApi.getPovertyPercentage(model, tenantId);
    return result.data;
});

export const getAnnualIncome = createAsyncThunk<
    number,
    {
        model: IAnnualIncome;
        tenantId: string;
    }
>('getAnnualIncome', async ({ model, tenantId }) => {
    const result = await dentalApi.getAnnualIncome(model, tenantId);
    return result.data;
});
