import IUserTask from 'api/models/user-task.model';
import { LoadingStatus, LoadingStatuses } from 'interfaces/loading-statuses';

export enum TaskView {
    PatientOverview = 'patient-overview',
    CheckIn = 'check-in',
}

export type TaskManagementState = {
    patientTasks: IUserTask[];
    patientAppointmentTasks: IUserTask[];
    checkoutCheckinTasks: IUserTask[];
    loadingCheckoutTasks: LoadingStatuses;
    loadingPatientTasks: LoadingStatuses;
    loadingPatientAppointmentTasks: LoadingStatuses;
};

const initialState: TaskManagementState = {
    patientTasks: [],
    patientAppointmentTasks: [],
    checkoutCheckinTasks: [],
    loadingCheckoutTasks: LoadingStatus.Idle,
    loadingPatientTasks: LoadingStatus.Idle,
    loadingPatientAppointmentTasks: LoadingStatus.Idle,
};

export default initialState;
