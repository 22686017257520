import { IDropdownOption, Stack } from '@fluentui/react';
import { Timetable, WeekDays } from 'api/models/provider.model';
import { TimeTableRange } from 'api/models/Scheduling/operatory.model';
import { cloneDeep } from 'lodash';
import EditRange from './EditRange';

type TimetableProps = {
    day: WeekDays;
    timetables: Timetable;
    setTimetables: (timetables: Record<WeekDays, TimeTableRange[]>) => void;
    setHasConflictError: (value: boolean) => void;
    index?: number;
    overrideTimeOptions?: IDropdownOption[];
};

export default function EditTimetables({
    day,
    timetables,
    setTimetables,
    setHasConflictError,
    overrideTimeOptions,
}: TimetableProps): JSX.Element {
    const ranges =
        timetables[day] &&
        timetables[day]?.map((range, index) => {
            const updateRange = (range: TimeTableRange) => {
                const timetablesClone = cloneDeep(timetables);
                const rangeDay = timetablesClone[day] ?? [];
                if (rangeDay.length) {
                    rangeDay[index] = range;
                    timetablesClone[day] = rangeDay;

                    setTimetables(timetablesClone);
                }
            };

            const deleteRange = () => {
                const timetablesClone = { ...timetables };
                if (timetablesClone[day]) {
                    timetablesClone[day] = (timetablesClone[day] as TimeTableRange[]).filter(
                        (_, currentIndex) => index !== currentIndex,
                    );
                    setTimetables(timetablesClone);
                }
            };

            const hasTimeConflict = (startTime: string): boolean => {
                const indexBefore = (index -= 1);
                if (timetables[day]) {
                    const rangeBefore = (timetables[day] as TimeTableRange[]).find((x, i) => indexBefore === i);
                    if (rangeBefore?.endTime && rangeBefore.endTime > startTime) {
                        setHasConflictError(true);
                        return true;
                    }
                }
                setHasConflictError(false);
                return false;
            };

            return (
                <EditRange
                    key={index}
                    rangeIndex={index}
                    ranges={timetables[day] ?? []}
                    range={range}
                    setRange={updateRange}
                    deleteRange={deleteRange}
                    hasTimeConflict={hasTimeConflict}
                    overrideTimeOptions={overrideTimeOptions}
                />
            );
        });

    return <Stack tokens={{ childrenGap: 5 }}>{ranges}</Stack>;
}
