import { Link } from '@fluentui/react';
import IWorkListEncounterView from 'api/models/worklist-encounter-view';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import { useDispatch, useSelector } from 'react-redux';
import { EncounterWorkListModalType } from 'state/slices/admin-huddle/worklists/encounter-worklists/encounter-worklists.state';
import { selectWorkListReadOnly } from 'state/slices/admin-huddle/worklists/worklist.selectors';
import { setEncounterWorkListModalOpen } from 'state/slices/admin-huddle/worklists/worklist.slice';

const onHold: ISortableColumn<IWorkListEncounterView> = {
    key: 'onHold',
    name: 'Hold',
    minWidth: 100,
    isResizable: true,
    onRender: OnRenderOnHold,
};

function OnRenderOnHold(encounter?: IWorkListEncounterView): JSX.Element | null {
    const dispatch = useDispatch();
    const readOnly = useSelector(selectWorkListReadOnly);

    if (!encounter) return null;

    const _openModal = () =>
        dispatch(
            setEncounterWorkListModalOpen({
                isOpen: true,
                type: EncounterWorkListModalType.AdministrativeHold,
                editEncounter: encounter,
            }),
        );

    const _holdNote = encounter.encounter?.administrativeHold?.note ?? 'Add';

    return (
        <Link
            disabled={readOnly}
            variant="small"
            title={_holdNote}
            onClick={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                _openModal();
            }}
        >
            {_holdNote}
        </Link>
    );
}

export default onHold;
