import { createAsyncThunk } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import { IAdjustmentCommonTransactionView, IPatientAdjustmentHistoryView } from 'api/models/adjustment-history.model';
import { ICommonTransaction, TransactionSource } from 'api/models/encounter-ledger.model';
import { RootState } from 'state/store';
import { selectAdjustmentHistorySelectedViews } from './adjustment-history.selectors';
import { v4 as uuid } from 'uuid';
import { IBatch } from 'api/models/batch.model';
import { batch } from 'react-redux';
import axios from 'axios';

export const getPatientAdjustmentHistory = createAsyncThunk<
    IPatientAdjustmentHistoryView,
    { tenantId: string; patientId: string },
    { rejectValue: string }
>('getPatientAdjustmentHistory', async ({ tenantId, patientId }, { rejectWithValue, signal }) => {
    try {
        const source = axios.CancelToken.source();
        signal.addEventListener('abort', () => {
            source.cancel();
        });
        const { data } = await dentalApi.getPatientAdjustmentHistory(tenantId, patientId, source.token);
        return data;
    } catch (e) {
        return rejectWithValue(e as string);
    }
});

export const reverseSelectedPatientAdjustmentTransactions = createAsyncThunk<
    ICommonTransaction[],
    { tenantId: string; patientId: string; selectedBatch: IBatch },
    { rejectValue: string; state: RootState }
>(
    'reverseSelectedPatientAdjustmentTransactions',
    async ({ tenantId, patientId, selectedBatch }, { rejectWithValue, getState, dispatch }) => {
        try {
            const transactions = createCommonTransactionFromAdjustmentViews(
                selectAdjustmentHistorySelectedViews(getState()),
                selectedBatch,
            );

            const { data } = await dentalApi.createLedgerTransaction(tenantId, transactions);

            batch(() => {
                dispatch(getPatientAdjustmentHistory({ tenantId, patientId }));
            });
            return data;
        } catch (e) {
            return rejectWithValue(e as string);
        }
    },
);

function createCommonTransactionFromAdjustmentViews(
    views: IAdjustmentCommonTransactionView[],
    batch: IBatch,
): ICommonTransaction[] {
    return views.map((v) => ({
        id: uuid(),
        amount: v.amount * -1,
        batchId: batch.id,
        dateOfEntry: batch.dateOfEntry,
        patientId: v.patientId,
        reversedTransactionId: v.id,
        adjustmentReasonId: v.adjustmentReasonId,
        chartProcedureId: v.chartProcedureId,
        treatingProviderId: v.treatingProviderId,
        treatmentPlanId: v.treatmentPlanId,
        treatmentPlanPhaseId: v.treatmentPlanPhaseId,
        source: TransactionSource.Patient,
        type: v.type,
        procedureCode: v.procedureCode,
        encounterId: v.encounterId,
        createdOn: new Date().toISOString(),
    }));
}
