import { Stack, Text } from '@fluentui/react';
import { IPatientInsurance } from 'api/models/patient.model';
import Field from 'components/Field/Field';
import TModal from 'components/TModal';
import { useValidation } from 'hooks';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { getValidationError } from 'hooks/useValidation';
import { useEffect, useState } from 'react';
import { saveInsurance } from 'state/slices/edit-patient/edit-patient.slice';

type ExpireModalProps = {
    insurance: IPatientInsurance | undefined;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
};

export default function ExpireModal({ insurance, isOpen, setIsOpen }: ExpireModalProps) {
    const dispatch = useAppDispatch();
    const [expirationDate, setExpirationDate] = useState(insurance?.expirationDate);

    function _onSave() {
        if (insurance && expirationDate) {
            dispatch(saveInsurance({ ...insurance, expirationDate }));
            _onDismiss();
        }
    }

    const [errors, onSubmit, cleanupValidation] = useValidation(
        [{ value: expirationDate, fieldName: 'Expiration Date', validation: ['required'] }],
        _onSave,
    );

    useEffect(() => {
        if (!isOpen) cleanupValidation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    function _onDismiss() {
        setIsOpen(false);
    }

    return (
        <TModal
            title="Expire Insurance"
            modalProps={{ isOpen, onDismiss: _onDismiss }}
            mainButtons={[
                { type: 'PrimaryButton', text: 'Update Insurance', onClick: onSubmit },
                { text: 'Cancel', onClick: _onDismiss },
            ]}
            isDraggable
        >
            <Stack styles={{ root: { padding: 10 } }} tokens={{ childrenGap: 10 }} grow>
                <Text>
                    {"To expire this insurance, enter an expiration date in the field below and then click 'Update Insurance.'"}
                </Text>
                <Field.Date
                    onChange={(ev, value) => {
                        setExpirationDate(value);
                    }}
                    errorMessage={
                        getValidationError(errors, 'Expiration Date') ? 'Expiration Date is a required field.' : undefined
                    }
                    label="Expiration Date"
                    required
                    hasDatePicker
                    minReasonableDate={insurance?.effectiveDate ? new Date(insurance.effectiveDate) : undefined}
                    value={expirationDate}
                />
            </Stack>
        </TModal>
    );
}
