import { Stack, DefaultButton, Checkbox, Label, PrimaryButton, Text, Spinner } from '@fluentui/react';
import { ReportType } from 'api/models/embed-report.model';
import { Field } from 'components';
import { format } from 'date-fns';
import { useSelector } from 'hooks';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { RouteParams } from 'interfaces/route-params';
import ReportModal from 'pages/Reporting/ReportModal';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import SignatureCanvas from 'react-signature-canvas';
import { setSignature, setPatientRefusedToSign, setSignedDate } from 'state/slices/charting/chart/chart.slice';
import { signOrDeclineTreatmentPlan } from 'state/slices/charting/treatmentPlans/treatmentPlans.actions';
import {
    selectCurrentTreatmentPlan,
    selectTreatmentPlanSigned,
} from 'state/slices/charting/treatmentPlans/treatmentPlans.selectors';
import { selectReportingState } from 'state/slices/reporting/reporting.selectors';
import reportActionLookup from 'state/slices/reporting/reportActionLookup';

function TreatmentPlanSignature(): JSX.Element {
    const dispatch = useDispatch();

    const { patientId, tenantId, treatmentPlanId } = useParams<RouteParams>();
    const { loadingSelectedReport } = useSelector(selectReportingState);
    const currentTreatmentPlan = useSelector(selectCurrentTreatmentPlan);
    const encounterId = currentTreatmentPlan?.encounterId;
    const treatmentPlansSigned = useSelector(selectTreatmentPlanSigned);

    const saveButtonText = treatmentPlansSigned ? 'Saved' : 'Save Plan';

    const loadingTreatmentPlanReport = loadingSelectedReport === LoadingStatus.Pending;

    const sigRef = useRef<SignatureCanvas>(null);

    const _setSignature = (value: string) => {
        dispatch(setSignature(value));
    };
    const _setPatientRefusedToSign = (checked: boolean) => {
        dispatch(setPatientRefusedToSign(checked));
    };

    useEffect(() => {
        if (!currentTreatmentPlan?.signedDate) onChangeSignedDate(undefined, new Date().toISOString());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTreatmentPlan]);

    const _onPatientRefuse = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        if (typeof checked === 'boolean') _setPatientRefusedToSign(checked);
        _setSignature('');
        if (sigRef.current && !sigRef.current.isEmpty()) {
            sigRef.current.clear();
        }
    };

    const _clearSignature = () => {
        if (sigRef.current && !sigRef.current.isEmpty()) {
            _setSignature('');
            sigRef.current.clear();
        }
    };

    const _onDrawingEnd = () => {
        if (sigRef.current) _setSignature(sigRef.current.getTrimmedCanvas().toDataURL());
    };

    const onChangeSignedDate = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement> | undefined,
        newValue?: string | undefined,
    ) => {
        if (newValue) {
            const chosenDate: string = newValue ? newValue : '';
            const formattedDate = chosenDate ? format(new Date(chosenDate), 'MM/dd/yyyy') : '';
            dispatch(setSignedDate(formattedDate));
        }
    };

    const _saveTreatmentPlan = () => {
        if (encounterId) dispatch(signOrDeclineTreatmentPlan({ patientId, tenantId, encounterId }));
    };

    const _printTreatmentPlan = () => {
        dispatch(reportActionLookup[ReportType.TreatmentPlan]({ tenantId, chartTreatmentPlanId: treatmentPlanId }));
    };

    // https://localhost:3000/4a318e3e/patient/6c427343-7568-4bbb-9aac-73a7caa2bcbc/encounter/d69adc0e-60f1-42e9-9e44-fb0bfc61cd66/treatment-plans/new-treatment-plan

    const hasSignedDate = !!currentTreatmentPlan?.signedDate;
    const hasSignature = !!currentTreatmentPlan?.signature;
    const refusedToSign = !!currentTreatmentPlan?.refusedToSign;
    const _saveDisabled = treatmentPlansSigned ? true : refusedToSign ? hasSignature : !hasSignature || !hasSignedDate;

    return (
        <>
            <ReportModal />
            <Stack.Item className="disclaimer-txt" align="center">
                <Text variant="mediumPlus">
                    If you have dental insurance, please be aware that this is an estimate only. Coverage may be different if your
                    deductible has not been met, annual maximum has been met, or if your coverage table is lower than average.
                    Signing this states you have been made aware of the dental treatment that is needed for your oral care.
                </Text>
            </Stack.Item>
            <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 15 }}>
                <Stack>
                    <Label>Patient/Legal Guardian Signature</Label>
                    <Stack tokens={{ childrenGap: 10 }}>
                        <SignatureCanvas
                            backgroundColor={'#EEE'}
                            canvasProps={{ width: 600, height: 200, className: 'sigCanvas' }}
                            ref={sigRef}
                            onEnd={_onDrawingEnd}
                        />
                        <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign="center" horizontalAlign="end" grow>
                            <Stack horizontal grow={1}>
                                <Stack>
                                    <DefaultButton className="clearSignButton" text="Clear Signature" onClick={_clearSignature} />
                                </Stack>
                            </Stack>
                            <Stack grow={1} horizontalAlign="end">
                                <Checkbox
                                    label="Patient refused to sign"
                                    checked={currentTreatmentPlan?.refusedToSign}
                                    onChange={_onPatientRefuse}
                                />
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack tokens={{ childrenGap: 10 }} horizontalAlign="end" horizontal verticalAlign="end">
                    <Field.Date
                        label="Date"
                        hasDatePicker
                        value={currentTreatmentPlan?.signedDate ?? ''}
                        onChange={onChangeSignedDate}
                    />
                    <PrimaryButton text={saveButtonText} onClick={_saveTreatmentPlan} disabled={_saveDisabled} />
                    <DefaultButton
                        text="Print"
                        iconProps={{ iconName: 'Print' }}
                        onClick={_printTreatmentPlan}
                        disabled={loadingTreatmentPlanReport}
                        onRenderText={(props) => {
                            return (
                                <Stack tokens={{ childrenGap: 10 }} horizontal>
                                    <span>{props?.text ?? ''}</span> {loadingTreatmentPlanReport && <Spinner />}
                                </Stack>
                            );
                        }}
                    />
                </Stack>
            </Stack>
        </>
    );
}

export default TreatmentPlanSignature;
