import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { IPatientLedger } from 'api/models/patient-ledger.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { PatientState } from '../patient.state';
import { addPatientLedger, getPatientLedger, updatePatientLedger } from './ledger.actions';

const ledgerReducers = {
    setPatientLedger: (state: PatientState, action: PayloadAction<IPatientLedger | undefined>): void => {
        state.ledger.data = action.payload;
    },
};

export default ledgerReducers;

export const ledgerExtraReducers = (builder: ActionReducerMapBuilder<PatientState>): ActionReducerMapBuilder<PatientState> =>
    builder
        .addCase(getPatientLedger.pending, (state) => {
            state.ledger.loading = LoadingStatus.Pending;
        })
        .addCase(getPatientLedger.fulfilled, (state, action) => {
            state.ledger.loading = LoadingStatus.Completed;
            state.ledger.data = action.payload;
        })
        .addCase(getPatientLedger.rejected, (state) => {
            state.ledger.loading = LoadingStatus.Failed;
        })
        .addCase(addPatientLedger.pending, (state) => {
            state.ledger.loading = LoadingStatus.Pending;
        })
        .addCase(addPatientLedger.fulfilled, (state, action) => {
            state.ledger.loading = LoadingStatus.Completed;
            state.ledger.data = action.payload;
        })
        .addCase(addPatientLedger.rejected, (state) => {
            state.ledger.loading = LoadingStatus.Failed;
        })
        .addCase(updatePatientLedger.pending, (state) => {
            state.ledger.loading = LoadingStatus.Pending;
        })
        .addCase(updatePatientLedger.fulfilled, (state, action) => {
            state.ledger.loading = LoadingStatus.Completed;
            state.ledger.data = action.payload;
        })
        .addCase(updatePatientLedger.rejected, (state) => {
            state.ledger.loading = LoadingStatus.Failed;
        });
