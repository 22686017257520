import { Dictionary } from '@reduxjs/toolkit';
import { IProblem } from 'api/models/patient-problem.model';
import { LoadingStatus, LoadingStatuses } from 'interfaces/loading-statuses';

export type ProblemsState = {
    healthHistoryProblemsData: Dictionary<IProblem>;
    loadingHealthHistoryProblems: LoadingStatuses;
};

export const problemsInitialState: ProblemsState = {
    healthHistoryProblemsData: {},
    loadingHealthHistoryProblems: LoadingStatus.Idle,
};
