import IPatient from 'api/models/patient.model';
import { useSelector } from 'hooks';
import PatientDetailsCard from 'pages/components/PatientDetailsCard';
import { selectSelectedPatient } from 'state/slices/patient/patient.selectors';

interface IScheduleApptPatientCard {
    patient: IPatient | undefined;
}

function ScheduleApptPatientCard({ patient }: IScheduleApptPatientCard): JSX.Element {
    const currentPatient = useSelector(selectSelectedPatient);
    const selectedPatient = patient ?? currentPatient;
    return <PatientDetailsCard showClinicalAlerts patient={selectedPatient} />;
}

export default ScheduleApptPatientCard;
