import { Stack, TextField, useTheme } from '@fluentui/react';
import { Field } from 'components';

export default function ClaimRejectionFields() {
    const { palette } = useTheme();
    return (
        <Stack
            style={{ backgroundColor: palette.neutralLighterAlt, padding: 10 }}
            tokens={{ childrenGap: 5 }}
            wrap
            verticalAlign="end"
            horizontal
        >
            <TextField label="CARC Code" style={{ maxWidth: 75 }} autoComplete="off" />
            <TextField label="RARC Code" style={{ maxWidth: 75 }} autoComplete="off" />
            <Field.Date label="DOS" style={{ maxWidth: 85 }} hasDatePicker autoComplete="off" />
            <Field.Date label="Denied Date" style={{ maxWidth: 85 }} hasDatePicker autoComplete="off" />
            <Field.SearchCombo options={[]} label="LOC" />
            <Field.SearchCombo options={[]} label="Treating Provider" />
            <TextField label="Patient ID" style={{ maxWidth: 75 }} autoComplete="off" />
            <Field.Currency
                onChange={() => {
                    // console.log('test');
                }}
                label="Claim Balance"
                style={{ maxWidth: 75 }}
                autoComplete="off"
            />
            <Field.SearchCombo options={[]} label="Current Payer" />
            <Field.Dropdown options={[]} label="Billing Order" />
            <Field.Date label="First Filed Date" style={{ maxWidth: 85 }} hasDatePicker autoComplete="off" />
            <Field.Date label="Last Filed Date" style={{ maxWidth: 85 }} hasDatePicker autoComplete="off" />
            <Field.SearchCombo options={[]} label="Tag(s)" multiSelect />
        </Stack>
    );
}
