import { IDocument, IFile } from 'api/models/document.model';
import IDocumentType from 'api/models/documentType.model';
import { LoadingStatus, LoadingStatuses } from 'interfaces/loading-statuses';
import { Dictionary } from 'lodash';

export const initialState: DocumentsState = {
    loading: LoadingStatus.Idle,
    uploading: LoadingStatus.Idle,
    saving: LoadingStatus.Idle,
    pendingDocuments: [],
    pendingFiles: [],
};

export type DocumentsState = {
    loading: LoadingStatuses;
    saving: LoadingStatuses;
    uploading: LoadingStatuses;

    data?: IDocument[];
    documentType?: Dictionary<IDocumentType>;

    pendingDocuments: IDocument[];
    pendingFiles: IFile[];
    errors?: unknown;
};
