import {
    Stack,
    DetailsListLayoutMode,
    ConstrainMode,
    TooltipHost,
    DirectionalHint,
    Text,
    Selection,
    MessageBar,
    MessageBarType,
    Link,
    Icon,
} from '@fluentui/react';
import { SelectionMode, ISelection, IObjectWithKey } from '@fluentui/utilities';
import { ChartProcedureStatus } from 'api/models/chart.model';
import { ChartTreatmentPlanStatus } from 'api/models/treatment-plan.model';
import { SortableDetailsList } from 'components';
import { useTenant } from 'hooks';
import { toothSpriteReferences } from 'pages/Charting/components/ToothCanvas/spriteList';
import { MouseEvent, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
    cleanupPrintPredetermination,
    ProcedureActionType,
    selectPrintPredeterminationChartActions,
} from 'state/slices/charting/chart/chart.slice';
import { IChartAction } from 'state/slices/charting/chartActionsList.pipeline';
import {
    IChartActionWithPhase,
    selectChartActionsFromTreatmentPlan,
    selectPredeterminationSelectedTreatmentPlan,
    selectPrintPredeterminationSelectedChartActions,
} from 'state/slices/charting/treatmentPlans/predetermination/predetermination.selectors';
import { getShorthandToothAreas } from 'utils';
import currencyFormatter from 'utils/currencyFormatter';

function PrintPredeterminationTable() {
    const dispatch = useDispatch();
    const chartActionsList = useSelector(selectChartActionsFromTreatmentPlan);
    const selectedChartActions = useSelector(selectPrintPredeterminationSelectedChartActions);
    const { procedures, getDiagnosesData } = useTenant();
    const chartProceduresSelection = useMemo(() => {
        return new Selection({
            onSelectionChanged: () => {
                const chartActions = _getChartActionSelectionDetails();
                dispatch(selectPrintPredeterminationChartActions(chartActions));
            },
            getKey: (item) => item.actionId,
            items: selectedChartActions,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function _getChartActionSelectionDetails(): IChartAction[] | undefined {
        return chartProceduresSelection.getSelection() as IChartAction[];
    }

    const _renderActionDescription = (item?: IChartAction) => {
        if (item) {
            const dataSource = procedures.data;
            const itemData = dataSource ? dataSource[item.actionId] : undefined;
            if (itemData) {
                const displayText = `${
                    item.actionType === 'Procedure'
                        ? itemData.displayName
                            ? itemData.displayName
                            : item.procedureDescription
                        : `${itemData.displayName}${item.conditionDescription ? ` - ${item.conditionDescription}` : ''}`
                }`;
                return <Text title={displayText}>{displayText}</Text>;
            }
        }
        return '';
    };
    return (
        <Stack style={{ position: 'relative', display: 'flex', flex: 1 }}>
            {selectedChartActions && selectedChartActions?.length > 10 && (
                <MessageBar messageBarType={MessageBarType.warning}>
                    Too many procedures selected, <strong>deselect {selectedChartActions.length - 10} procedure(s)</strong>.
                </MessageBar>
            )}
            <SortableDetailsList<IChartActionWithPhase>
                setKey="chartActionsList"
                selectionPreservedOnEmptyClick
                selectionMode={SelectionMode.multiple}
                styles={{ root: { minHeight: 500 } }}
                items={chartActionsList}
                selection={chartProceduresSelection as unknown as ISelection<IObjectWithKey>}
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible
                compact
                sortColumns={['phase', 'code']}
                initialSortDirection={['asc', 'asc']}
                sortOnMount
                constrainMode={ConstrainMode.unconstrained}
                columns={[
                    {
                        key: 'phase',
                        name: 'Phase',
                        minWidth: 70,
                        maxWidth: 70,
                        fieldName: 'phase',
                        onRender: (item) => <Text>{item?.phase}</Text>,
                    },
                    {
                        key: 'code',
                        name: 'Code',
                        minWidth: 50,
                        maxWidth: 50,
                        fieldName: 'code',
                        onRender: (item) => <Text>{item?.actionType === 'Procedure' ? <Text>{item?.code}</Text> : ''}</Text>,
                    },
                    {
                        key: 'description',
                        name: 'Description',
                        minWidth: 150,
                        maxWidth: 300,
                        fieldName: 'description',
                        onRender: _renderActionDescription,
                        styles: {},
                        getValueKey: (item) => {
                            if (item) {
                                const dataSource = procedures.data;
                                const itemData = dataSource ? dataSource[item.actionId] : undefined;

                                return itemData?.displayName
                                    ? itemData?.displayName
                                    : itemData?.description
                                    ? itemData?.description
                                    : '';
                            }

                            return '';
                        },
                    },
                    {
                        key: 'tooth',
                        name: 'Tooth',
                        minWidth: 80,
                        maxWidth: 80,
                        fieldName: 'toothIds',
                        getValueKey: (item) => {
                            if (item?.toothIds?.length) {
                                return item.toothIds[0]?.toString();
                            } else {
                                return '0';
                            }
                        },
                        onRender: (item) => {
                            const teeth = item?.toothIds
                                ?.map((id) => {
                                    return toothSpriteReferences.find((tooth) => tooth.id === id)?.displayName;
                                })
                                ?.join(', ');
                            return (
                                <TooltipHost content={teeth} directionalHint={DirectionalHint.topRightEdge}>
                                    <Text>{teeth}</Text>
                                </TooltipHost>
                            );
                        },
                    },
                    {
                        key: 'areas',
                        name: 'Area(s)',
                        minWidth: 50,
                        maxWidth: 50,
                        onRender: (item) => {
                            if (item && item.areas && item.areas.length) {
                                const shortHandText = getShorthandToothAreas(item.areas);
                                return (
                                    <TooltipHost delay={0} content={shortHandText}>
                                        <Text>{shortHandText}</Text>
                                    </TooltipHost>
                                );
                            }
                            return null;
                        },
                    },

                    {
                        key: 'dx',
                        name: 'Dx',
                        minWidth: 80,
                        maxWidth: 80,
                        onRender: (item) => {
                            if (
                                item?.actionType === 'Condition' ||
                                item?.procedureType === ProcedureActionType.Existing ||
                                item?.procedureType === ProcedureActionType.ExistingOther
                            ) {
                                return '';
                            }
                            const diagnoses = item?.diagnosisCodes
                                ?.map((dx) => {
                                    return getDiagnosesData ? getDiagnosesData[dx.id]?.code : '';
                                })
                                ?.join(', ');
                            return (
                                <>
                                    {item?.diagnosisCodes?.length ? <Text title={diagnoses}>{diagnoses}</Text> : <Text>N/A</Text>}
                                </>
                            );
                        },
                    },
                    {
                        key: 'fee',
                        name: 'Fee',
                        minWidth: 70,
                        maxWidth: 70,
                        onRender: (item) => {
                            if (
                                item &&
                                item.actionType === 'Procedure' &&
                                item.procedureType !== ProcedureActionType.Existing &&
                                item.procedureType !== ProcedureActionType.ExistingOther &&
                                item.procedureType !== ProcedureActionType.Referred &&
                                item.status === ChartProcedureStatus.Pending
                            ) {
                                if (item) return <Text>{currencyFormatter.format(item?.fee ?? 0)}</Text>;
                            }
                            if (item && item.stage) {
                                return <Text>{currencyFormatter.format(0)}</Text>;
                            } else {
                                return null;
                            }
                        },
                        fieldName: 'fee',
                    },
                    {
                        key: 'preAuth',
                        name: 'Pre-Auth',
                        minWidth: 90,
                        maxWidth: 90,
                        onRender: (item) => {
                            return <Text>{item?.preAuthorization ?? ''}</Text>;
                        },
                        fieldName: 'preAuthorization',
                    },
                ]}
                stickyHeader={true}
            />
            <NoChartActionListMessage />
        </Stack>
    );
}

export default PrintPredeterminationTable;

function NoChartActionListMessage() {
    const dispatch = useDispatch();
    const { push } = useHistory();
    const chartActionsList = useSelector(selectChartActionsFromTreatmentPlan);
    const selectedTreatmentPlan = useSelector(selectPredeterminationSelectedTreatmentPlan);
    const isPending = selectedTreatmentPlan?.status === ChartTreatmentPlanStatus.Pending;

    if (chartActionsList.length) return null;

    const EditTreatmentPlanLink = () => {
        if (!isPending) return null;
        const _onClick = (event: MouseEvent<HTMLElement | HTMLAnchorElement | HTMLButtonElement>) => {
            event.preventDefault();
            dispatch(cleanupPrintPredetermination());
            push(`treatment-plans/${selectedTreatmentPlan.id}`);
        };
        return (
            <Link href="" onClick={_onClick} title="Edit treatment-plan">
                Please ensure you have correctly setup the treatment-plan. <Icon iconName="Link" />
            </Link>
        );
    };

    return (
        <MessageBar messageBarType={MessageBarType.blocked}>
            No procedures found. Procedures found in the Treatment Plan must be:
            <ul>
                <li>Billable</li>
                <li>Pending</li>
                <li>Treatment Planned</li>
            </ul>
            <EditTreatmentPlanLink />
        </MessageBar>
    );
}
