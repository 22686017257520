import { IChartDentition } from 'api/models/chart-dentition.model';
import { toothSpriteReferences } from 'pages/Charting/components/ToothCanvas/spriteList';

type MovementsState = IChartDentition[];

enum MovementsActionTypes {
    TranslateTeethX = 'translateTeethX',
    TranslateTeethY = 'translateTeethY',
    RotateOcclusalTeeth = 'rotateOcclusalTeeth',
    RotateTeeth = 'rotateTeeth',
    PopulateDentitions = 'populateDentitions',
}

type MovementsAction = {
    type: MovementsActionTypes;
    payload: any;
};

export const movementsInitialState: MovementsState = [];

export const populateTeethDentitions = (dentitions: IChartDentition[]): MovementsAction => ({
    type: MovementsActionTypes.PopulateDentitions,
    payload: dentitions,
});

export const translateTeethX = (direction: 'increment' | 'decrement', selectedTeeth: number[]): MovementsAction => ({
    type: MovementsActionTypes.TranslateTeethX,
    payload: { direction, selectedTeeth },
});

export const translateTeethY = (direction: 'increment' | 'decrement', selectedTeeth: number[]): MovementsAction => ({
    type: MovementsActionTypes.TranslateTeethY,
    payload: { direction, selectedTeeth },
});

export const rotateOcclusalTeeth = (direction: 'increment' | 'decrement', selectedTeeth: number[]): MovementsAction => ({
    type: MovementsActionTypes.RotateOcclusalTeeth,
    payload: { direction, selectedTeeth },
});

export const rotateTeeth = (direction: 'increment' | 'decrement', selectedTeeth: number[]): MovementsAction => ({
    type: MovementsActionTypes.RotateTeeth,
    payload: { direction, selectedTeeth },
});

export default function reducer(state: MovementsState, action: MovementsAction): MovementsState {
    const newState = [...state];

    switch (action.type) {
        case MovementsActionTypes.PopulateDentitions:
            return [...state, ...action.payload];
        case MovementsActionTypes.TranslateTeethX: {
            const { selectedTeeth, direction } = action.payload;
            selectedTeeth.forEach((tooth: number) => {
                const position = toothSpriteReferences.find((t) => t.id === tooth)?.position.toString();
                const indexOfDentition = state.findIndex((d) => d.id === position);
                const value = direction === 'increment' ? 1 : -1;
                if (indexOfDentition !== -1) {
                    const currentDentition = newState[indexOfDentition];
                    newState[indexOfDentition] = {
                        ...currentDentition,
                        xPosition: currentDentition?.xPosition ? currentDentition.xPosition + value : value,
                    };
                } else {
                    if (position !== undefined)
                        newState.push({
                            id: position,
                            isDeleted: false,
                            xPosition: value,
                            facialDegree: 0,
                            occlusalDegree: 0,
                            yPosition: 0,
                        });
                }
            });
            return [...newState];
        }
        case MovementsActionTypes.TranslateTeethY: {
            const { selectedTeeth, direction } = action.payload;
            selectedTeeth.forEach((tooth: number) => {
                const position = toothSpriteReferences.find((t) => t.id === tooth)?.position.toString();
                const indexOfDentition = state.findIndex((d) => d.id === position);
                const value = direction === 'increment' ? 1 : -1;
                if (indexOfDentition !== -1) {
                    const currentDentition = newState[indexOfDentition];
                    newState[indexOfDentition] = {
                        ...currentDentition,
                        yPosition: currentDentition?.yPosition ? currentDentition.yPosition + value : value,
                    };
                } else {
                    if (position !== undefined)
                        newState.push({
                            id: position,
                            isDeleted: false,
                            yPosition: value,
                            facialDegree: 0,
                            occlusalDegree: 0,
                            xPosition: 0,
                        });
                }
            });
            return [...newState];
        }
        case MovementsActionTypes.RotateOcclusalTeeth: {
            const { selectedTeeth, direction } = action.payload;
            selectedTeeth.forEach((tooth: number) => {
                const position = toothSpriteReferences.find((t) => t.id === tooth)?.position.toString();
                const indexOfDentition = state.findIndex((d) => d.id === position);
                const value = direction === 'increment' ? 1 : -1;
                if (indexOfDentition !== -1) {
                    const currentDentition = newState[indexOfDentition];
                    newState[indexOfDentition] = {
                        ...currentDentition,
                        occlusalDegree: currentDentition?.occlusalDegree ? currentDentition.occlusalDegree + value : value,
                    };
                } else {
                    if (position !== undefined)
                        newState.push({
                            id: position,
                            isDeleted: false,
                            occlusalDegree: value,
                            xPosition: 0,
                            facialDegree: 0,
                            yPosition: 0,
                        });
                }
            });
            return [...newState];
        }
        case MovementsActionTypes.RotateTeeth: {
            const { selectedTeeth, direction } = action.payload;
            selectedTeeth.forEach((tooth: number) => {
                const position = toothSpriteReferences.find((t) => t.id === tooth)?.position.toString();
                const indexOfDentition = state.findIndex((d) => d.id === position);
                const value = direction === 'increment' ? 1 : -1;
                if (indexOfDentition !== -1) {
                    const currentDentition = newState[indexOfDentition];
                    newState[indexOfDentition] = {
                        ...currentDentition,
                        facialDegree: currentDentition?.facialDegree ? currentDentition.facialDegree + value : value,
                    };
                } else {
                    if (position !== undefined)
                        newState.push({
                            id: position,
                            isDeleted: false,
                            facialDegree: value,
                            occlusalDegree: 0,
                            xPosition: 0,
                            yPosition: 0,
                        });
                }
            });
            return [...newState];
        }
        default:
            return state;
    }
}
