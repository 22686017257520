import { Stack, Text, Checkbox, MessageBar, Spinner, IDropdownOption } from '@fluentui/react';
import { Field, TModal } from 'components';
import { useDispatch } from 'react-redux';
import { usePatientId, useSelector, useTenantId } from 'hooks';
import {
    selectPatientMedicationStopModalOpen,
    selectPatientMedicationToUpdate,
} from 'state/slices/patient/medications/medications.selectors';
import { setMedicationModal, updateMedicationField } from 'state/slices/patient/patient.slice';
import { IPatientChartMedication } from 'api/models/patient-medication.model';
import { isAfter } from 'date-fns';
import { useState } from 'react';
import { medicationStopReasonsOption } from 'state/slices/tenant/medications-stop-reasons-slice';
import useEncounterId from 'hooks/useEncounterId';
import { updatePatientMedication } from 'state/slices/patient/medications/medications.actions';
import useOverview from 'hooks/store/useOverview';
import { LoadingStatus } from 'interfaces/loading-statuses';

export default function MedicationStopModal(): JSX.Element {
    const dispatch = useDispatch();
    const encounterId = useEncounterId();
    const patientId = usePatientId();
    const tenantId = useTenantId();
    const { medicationsLoading } = useOverview();

    const isOpen = useSelector(selectPatientMedicationStopModalOpen);
    const medication = useSelector(selectPatientMedicationToUpdate);
    const stopReasonsOptions = useSelector(medicationStopReasonsOption);
    const stopReasonOptions: IDropdownOption[] = [{ key: '', text: '(Select Reason)' }, ...stopReasonsOptions];

    const closeMedicationModal = () => {
        dispatch(setMedicationModal({ setModal: false }));
    };

    const updateMedication = (field: keyof IPatientChartMedication, value: string | boolean | number) => {
        dispatch(updateMedicationField({ key: field, value }));
    };
    const [dateErrorMessage, setDateErrorMessage] = useState('');

    const saveMedication = () => {
        // Medication shouldn't be stopped after it's start date
        if (
            medication?.stopDate &&
            medication?.startDate &&
            !medication?.isDeleted &&
            isAfter(new Date(medication?.startDate), new Date(medication?.stopDate))
        ) {
            setDateErrorMessage('Stop Date has to be after Start Date');
        } else {
            if (patientId) {
                dispatch(updatePatientMedication({ tenantId, patientId, encounterId }));
            }
        }
    };

    return (
        <TModal
            title={'Stop Medication'}
            mainButtons={[
                {
                    type: 'PrimaryButton',
                    text: 'Continue',
                    disabled: !medication?.stopDate?.length || !!dateErrorMessage || medicationsLoading === LoadingStatus.Pending,
                    onClick: () => saveMedication(),
                },
                {
                    text: 'Cancel',
                    onClick: () => closeMedicationModal(),
                },
            ]}
            modalProps={{
                isOpen: isOpen,
                onDismiss: () => closeMedicationModal(),
                isBlocking: true,
            }}
            isDraggable
        >
            <Stack grow styles={{ root: { padding: 10 } }} verticalAlign="start" tokens={{ childrenGap: 10 }}>
                <Text>{medication?.medicationName} </Text>
                <Stack.Item>
                    <Field.Date
                        value={medication?.stopDate}
                        label="Stop Date"
                        style={{ minWidth: 215 }}
                        required={true}
                        onChange={(ev, value) => {
                            dispatch(updateMedication('stopDate', value ? value : ''));
                            setDateErrorMessage('');
                        }}
                        hasDatePicker
                        errorMessage={dateErrorMessage}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.Dropdown
                        selectedKey={medication?.stopReason}
                        label="Stop Reason"
                        placeholder="(Select Reason)"
                        options={stopReasonOptions}
                        onChange={(ev, option) => {
                            if (option) dispatch(updateMedication('stopReason', option.key));
                        }}
                        disabled={medication?.isDeleted}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Checkbox
                        label="Delete (added in error)"
                        onChange={(ev, checked) => {
                            dispatch(updateMedication('isDeleted', checked ?? false));
                            dispatch(updateMedication('stopReason', ''));
                        }}
                    />
                </Stack.Item>
                {!medication?.stopDate?.length ? <MessageBar>Stop date is required.</MessageBar> : null}
                {medicationsLoading === LoadingStatus.Pending ? <Spinner label="Saving" labelPosition="right" /> : null}
            </Stack>
        </TModal>
    );
}
