import { IStackTokens, Stack } from '@fluentui/react';
import { useChart, useChartProcedures, useTenant } from 'hooks';
import { useSelector } from 'react-redux';
import { selectFirstToothPosteriorAnterior } from 'state/slices/charting/ui/chartingUi.selectors';
import SmallDefaultButton from './SmallDefaultButton';

export default function PosteriorComposite(): JSX.Element {
    const { addProcedures } = useChartProcedures();
    const { getProcedureIdByCode } = useTenant();
    const { selectedTeeth } = useChart();
    const noneSelected = selectedTeeth.length <= 0;
    const firstToothPosteriorAnterior = useSelector(selectFirstToothPosteriorAnterior);
    const _buttonDisabled = noneSelected || firstToothPosteriorAnterior === 'Anterior';

    /**
     * variable: {buttonId: string, errors: IPipelineError[]}
     */

    function addMO(e: React.MouseEvent<HTMLDivElement>) {
        const buttonId = e.currentTarget?.id;
        addProcedures({
            procedureIds: [getProcedureIdByCode('D2392')],
            toothAreas: ['Mesial', 'Occlusal'],
            elementId: buttonId,
        });
    }

    function addDO(e: React.MouseEvent<HTMLDivElement>) {
        const buttonId = e.currentTarget?.id;
        addProcedures({
            procedureIds: [getProcedureIdByCode('D2392')],
            toothAreas: ['Distal', 'Occlusal'],
            elementId: buttonId,
        });
    }

    function addO(e: React.MouseEvent<HTMLDivElement>) {
        const buttonId = e.currentTarget?.id;
        addProcedures({
            procedureIds: [getProcedureIdByCode('D2391')],
            toothAreas: ['Occlusal'],
            elementId: buttonId,
        });
    }

    function addMOD(e: React.MouseEvent<HTMLDivElement>) {
        const buttonId = e.currentTarget?.id;
        addProcedures({
            procedureIds: [getProcedureIdByCode('D2393')],
            toothAreas: ['Mesial', 'Occlusal', 'Distal'],
            elementId: buttonId,
        });
    }

    function addMODL(e: React.MouseEvent<HTMLDivElement>) {
        const buttonId = e.currentTarget?.id;
        addProcedures({
            procedureIds: [getProcedureIdByCode('D2394')],
            toothAreas: ['Mesial', 'Occlusal', 'Distal', 'Lingual'],
            elementId: buttonId,
        });
    }

    function addMODB(e: React.MouseEvent<HTMLDivElement>) {
        const buttonId = e.currentTarget?.id;
        addProcedures({
            procedureIds: [getProcedureIdByCode('D2394')],
            toothAreas: ['Mesial', 'Occlusal', 'Distal', 'Facial'],
            elementId: buttonId,
        });
    }

    function addOL(e: React.MouseEvent<HTMLDivElement>) {
        const buttonId = e.currentTarget?.id;
        addProcedures({
            procedureIds: [getProcedureIdByCode('D2392')],
            toothAreas: ['Occlusal', 'Lingual'],
            elementId: buttonId,
        });
    }

    function addOB(e: React.MouseEvent<HTMLDivElement>) {
        const buttonId = e.currentTarget?.id;
        addProcedures({
            procedureIds: [getProcedureIdByCode('D2392')],
            toothAreas: ['Occlusal', 'Facial'],
            elementId: buttonId,
        });
    }

    function addB(e: React.MouseEvent<HTMLDivElement>) {
        const buttonId = e.currentTarget?.id;
        addProcedures({
            procedureIds: [getProcedureIdByCode('D2391')],
            toothAreas: ['Facial'],
            elementId: buttonId,
        });
    }

    const stackTokens: IStackTokens = {
        childrenGap: 5,
    };

    return (
        <Stack tokens={stackTokens} horizontalAlign="center" grow>
            <Stack tokens={stackTokens} horizontal grow>
                <SmallDefaultButton text="MO" disabled={_buttonDisabled} onClick={addMO} />
                <SmallDefaultButton text="DO" disabled={_buttonDisabled} onClick={addDO} />
                <SmallDefaultButton text="O" disabled={_buttonDisabled} onClick={addO} />
            </Stack>
            <Stack tokens={stackTokens} horizontal grow>
                <SmallDefaultButton text="MOD" disabled={_buttonDisabled} onClick={addMOD} />
                <SmallDefaultButton text="MODL" disabled={_buttonDisabled} onClick={addMODL} />
                <SmallDefaultButton text="MODB" disabled={_buttonDisabled} onClick={addMODB} />
            </Stack>
            <Stack tokens={stackTokens} horizontal grow>
                <SmallDefaultButton text="OL" disabled={_buttonDisabled} onClick={addOL} />
                <SmallDefaultButton text="OB" disabled={_buttonDisabled} onClick={addOB} />
                <SmallDefaultButton text="B" disabled={_buttonDisabled} onClick={addB} />
            </Stack>
        </Stack>
    );
}
