import useSelector from 'hooks/useSelector';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTenantId, usePatientId } from 'hooks';
import IChartNote, { ClinicalNote } from 'api/models/chart-note.model';
import {
    clinicalNotesList,
    filteredClinicalNotesList,
    uniqueClinicalNoteDates,
    selectChartNoteSaveStatus,
    selectChartNotesState,
} from 'state/slices/chart-notes/chart-notes.selectors';
import { setClincalNoteFilter, setClinicalNote } from 'state/slices/chart-notes/chart-notes.slice';
import {
    getChartNoteByEncounterId,
    getChartNoteById,
    getChartNotes,
    upsertChartNote,
} from 'state/slices/chart-notes/chart-notes.actions';
import useEncounterId from 'hooks/useEncounterId';

function useChartNotes() {
    const dispatch = useDispatch();
    const tenantId = useTenantId();
    const patientId = usePatientId();
    const encounterId = useEncounterId();

    const {
        loading,
        data: clincalNotes,
        saving,
        filterCreatedDate,
        filterToothId,
        currentClinicalNote: currentNote,
        loadingCurrentNote: loadingCurrentNote,
    } = useSelector(selectChartNotesState);

    const _clinicalNotesList = useSelector(clinicalNotesList);
    const _filteredClinicalNotesList = useSelector(filteredClinicalNotesList);
    const _uniqueClinicalNoteDates = useSelector(uniqueClinicalNoteDates);
    const _chartNoteSaveStatus = useSelector(selectChartNoteSaveStatus);

    const _setChartNoteFilter = useCallback(
        ({ path, value }: { path: 'filterToothId' | 'filterCreatedDate'; value: string | number }) => {
            dispatch(setClincalNoteFilter({ path, value }));
        },
        [dispatch],
    );

    const _getChartNotes = useCallback(() => {
        if (patientId) dispatch(getChartNotes({ tenantId, patientId }));
    }, [dispatch, tenantId, patientId]);

    const _getChartNoteById = useCallback(
        (noteId: string) => {
            if (patientId) dispatch(getChartNoteById({ tenantId, patientId, noteId }));
        },
        [dispatch, tenantId, patientId],
    );

    const _getChartNoteByEncounterId = useCallback(() => {
        if (patientId && encounterId) dispatch(getChartNoteByEncounterId({ tenantId, patientId, encounterId }));
    }, [dispatch, tenantId, patientId, encounterId]);

    const _upsertChartNote = useCallback(
        (chartNote: IChartNote) => {
            if (patientId) dispatch(upsertChartNote({ tenantId, patientId, chartNote }));
        },
        [dispatch, tenantId, patientId],
    );

    const _setClincalNote = useCallback(
        (chartNote: ClinicalNote | undefined) => {
            dispatch(setClinicalNote(chartNote));
        },
        [dispatch],
    );

    return {
        clincalNotes,
        loading,
        saving,
        filterCreatedDate,
        filterToothId,
        currentNote,
        loadingCurrentNote,
        chartNoteSaveStatus: _chartNoteSaveStatus,

        clinicalNotesList: _clinicalNotesList,
        filteredClinicalNotesList: _filteredClinicalNotesList,
        uniqueClinicalNoteDates: _uniqueClinicalNoteDates,

        setClincalNoteFilter: _setChartNoteFilter,
        setClinicalNote: _setClincalNote,

        getChartNotes: _getChartNotes,
        getChartNoteById: _getChartNoteById,
        getChartNoteByEncounterId: _getChartNoteByEncounterId,
        upsertChartNote: _upsertChartNote,
    };
}

export default useChartNotes;
