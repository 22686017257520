import { IChartProcedure } from 'api/models/chart.model';
import { ConflictRuleType } from 'api/models/lookup.model';
import { every } from 'lodash';
import { IPipelineError, PipelineError } from '../pipeline';
import ProcedureConflictRulesPipeline from '../procedureConflictRules.pipeline';
import { createRule } from '../ruleGenerator';

const ProcedureMaxillaryOnly = createRule<ProcedureConflictRulesPipeline, IChartProcedure>({
    ruleTypes: [ConflictRuleType.maxillaryOnly],
    rule: (pipeline, item) => {
        const _errors: IPipelineError[] = [];
        const teeth = item.toothIds ? item.toothIds : [];

        if (teeth.length) {
            const isMaxillary = every(teeth, (tooth) => pipeline.getIsToothMaxillary(tooth));
            if (!isMaxillary) _errors.push({ type: PipelineError.MaxillaryOnly, data: pipeline.getProcedure(item) });
        }

        return { shouldRemoveItem: !!_errors.length, errors: _errors };
    },
});

export default ProcedureMaxillaryOnly;
