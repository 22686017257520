import { RiskType } from 'forms/CariesRiskAdult/types';
import { some } from 'lodash';
import { ICariesRiskChildQuestions } from './types';

export default function cariesRiskChildScore(data: ICariesRiskChildQuestions): string {
    const dataToFilter = data;
    delete dataToFilter.score;

    const highRiskQuestions = some(dataToFilter, (d) => d === RiskType.High);
    const moderateRiskQuestions = some(dataToFilter, (d) => d === RiskType.Moderate);
    const lowRiskQuestions = some(dataToFilter, (d) => d === RiskType.Low);
    if (highRiskQuestions) return RiskType.High;
    if (moderateRiskQuestions) return RiskType.Moderate;
    if (lowRiskQuestions) return RiskType.Low;
    return RiskType.None;
}
