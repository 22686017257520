import { Label, Stack, useTheme, Text, Separator, DefaultEffects, MessageBar, MessageBarType } from '@fluentui/react';
import IPatient from 'api/models/patient.model';
import PanelSectionHeader from 'pages/components/PanelSectionHeader';
import dateOnly from 'utils/dateOnly';

function Insurance(props: { patient: IPatient | undefined }): JSX.Element {
    const { patient } = props;
    const { palette } = useTheme();
    const insurance = patient?.insurances ? patient?.insurances[0] : undefined;
    const signature = patient?.signature;
    const slidingFee = patient?.slidingFees ? patient?.slidingFees[0] : undefined;
    const hasPercentOfPoverty = patient?.uds?.incomePercentage && patient.uds.incomePercentage !== undefined;

    const _infoStyles = {
        root: {
            backgroundColor: palette.white,
            borderRadius: DefaultEffects.roundedCorner4,
        },
    };

    const InformationItem = (props: { label: string; text?: string }) => {
        return (
            <Stack horizontal tokens={{ childrenGap: 5 }} styles={{ root: { alignItems: 'center' } }}>
                <Label style={{ paddingBottom: 0 }}>{props.label} </Label>
                {props.text && <Text>{props.text}</Text>}
            </Stack>
        );
    };

    const hasInsurances = (): boolean => {
        if (patient?.insurances) {
            return patient?.insurances.length > 0;
        }
        return false;
    };

    function isPovertyPercentageLess(): boolean {
        if (patient) {
            const povertyPercentage = patient?.uds?.incomePercentage ? patient?.uds?.incomePercentage : 0;
            return povertyPercentage < 200;
        }
        return false;
    }

    const currencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <Stack title="Notice of Privacy Practices and Signature on File">
                <PanelSectionHeader text="Notice of privacy practices and signature on file" />
                <Stack tokens={{ childrenGap: 10 }} horizontal wrap>
                    <Stack.Item grow styles={_infoStyles}>
                        <InformationItem
                            label="Privacy Notice:"
                            text={`(given: ${
                                signature?.privacyNoticeSignedDate && signature?.privacyNoticeSignedDate !== '0001-01-01T00:00:00'
                                    ? dateOnly(signature?.privacyNoticeSignedDate)
                                    : 'N/A'
                            })`}
                        />

                        <InformationItem
                            label="Release of Billing Information:"
                            text={`(effective: ${
                                signature?.billingReleaseSignedDate &&
                                signature?.billingReleaseSignedDate !== '0001-01-01T00:00:00'
                                    ? dateOnly(signature?.billingReleaseSignedDate)
                                    : 'N/A'
                            }, expiration: ${
                                signature?.billingReleaseExpirationDate &&
                                signature?.billingReleaseExpirationDate !== '0001-01-01T00:00:00'
                                    ? dateOnly(signature?.billingReleaseExpirationDate)
                                    : 'N/A'
                            })`}
                        />
                        <InformationItem
                            label="Assignment of Benefits:"
                            text={`(effective: ${
                                signature?.benefitAssignmentSignedDate &&
                                signature?.benefitAssignmentSignedDate !== '0001-01-01T00:00:00'
                                    ? dateOnly(signature?.benefitAssignmentSignedDate)
                                    : 'N/A'
                            }, expiration: ${
                                signature?.benefitAssignmentExpirationDate &&
                                signature?.benefitAssignmentExpirationDate !== '0001-01-01T00:00:00'
                                    ? dateOnly(signature?.benefitAssignmentExpirationDate)
                                    : 'N/A'
                            })`}
                        />
                    </Stack.Item>
                </Stack>
            </Stack>
            <Stack title="Insurance">
                <PanelSectionHeader text="Insurance" />
                {hasInsurances() ? (
                    <Stack tokens={{ childrenGap: 10 }}>
                        <Stack horizontal>
                            <Stack.Item grow styles={_infoStyles}>
                                <InformationItem
                                    label="Annual Max:"
                                    text={
                                        insurance?.eligibility?.annualMax
                                            ? currencyFormatter.format(insurance?.eligibility?.annualMax)
                                            : 'N/A'
                                    }
                                />
                                <InformationItem
                                    label="Individual Deductible:"
                                    text={
                                        insurance?.eligibility?.deductible
                                            ? currencyFormatter.format(insurance?.eligibility?.deductible)
                                            : 'N/A'
                                    }
                                />

                                <InformationItem
                                    label="Preventive:"
                                    text={
                                        insurance?.eligibility?.preventativeBenefits
                                            ? `${insurance?.eligibility?.preventativeBenefits.toString()}`
                                            : 'N/A'
                                    }
                                />
                                <InformationItem
                                    label="Basic:"
                                    text={
                                        insurance?.eligibility?.basicBenefits
                                            ? `${insurance?.eligibility?.basicBenefits.toString()}`
                                            : 'N/A'
                                    }
                                />
                                <InformationItem
                                    label="Basic Waiting Period:"
                                    text={
                                        insurance?.eligibility?.waitingPeriod
                                            ? `${insurance?.eligibility?.waitingPeriod.toString()}`
                                            : 'N/A'
                                    }
                                />
                                <InformationItem
                                    label="Major:"
                                    text={
                                        insurance?.eligibility?.majorBenefits
                                            ? `${insurance?.eligibility?.majorBenefits.toString()}`
                                            : 'N/A'
                                    }
                                />
                                <InformationItem
                                    label="Major Waiting Period:"
                                    text={
                                        insurance?.eligibility?.waitingPeriod
                                            ? `${insurance?.eligibility?.waitingPeriod.toString()}`
                                            : 'N/A'
                                    }
                                />
                            </Stack.Item>
                            <Stack.Item grow styles={_infoStyles}>
                                <InformationItem
                                    label="Prophy/Exam per year:"
                                    text={
                                        insurance?.eligibility?.prophyBenefits
                                            ? insurance?.eligibility?.prophyBenefits.toString()
                                            : 'N/A'
                                    }
                                />
                                <InformationItem
                                    label="Bitewing per year:"
                                    text={
                                        insurance?.eligibility?.bitewingBenefits
                                            ? insurance?.eligibility?.bitewingBenefits.toString()
                                            : 'N/A'
                                    }
                                />
                                <InformationItem
                                    label="Full Mouth Xray once every:"
                                    text={
                                        insurance?.eligibility?.fullXrayBenefits
                                            ? insurance?.eligibility?.fullXrayBenefits.toString()
                                            : 'N/A'
                                    }
                                />
                                <InformationItem
                                    label="Fluoride Varnish per year:"
                                    text={
                                        insurance?.eligibility?.fluorideBenefits
                                            ? insurance?.eligibility?.fluorideBenefits.toString()
                                            : 'N/A'
                                    }
                                />
                                <InformationItem
                                    label="Missing Tooth Clause:"
                                    text={
                                        insurance?.eligibility?.missingToothClause
                                            ? insurance?.eligibility?.missingToothClause
                                                ? 'true'
                                                : 'false'
                                            : 'N/A'
                                    }
                                />
                            </Stack.Item>
                        </Stack>
                    </Stack>
                ) : (
                    <MessageBar messageBarType={MessageBarType.info}>
                        Patient has no active insurance. Guarantor will be responsible for 100% of services rendered.
                    </MessageBar>
                )}
            </Stack>
            <Stack title="Sliding Fee">
                <PanelSectionHeader text="Sliding fee" />
                <Stack tokens={{ childrenGap: 10 }}>
                    {!hasPercentOfPoverty ? (
                        <MessageBar>
                            Patient has declined to provide income for an income-based Sliding Fee plan. Guarantor will be
                            responsible for 100% of services rendered.
                        </MessageBar>
                    ) : (
                        <>
                            {(isPovertyPercentageLess() === true && !slidingFee?.adjustmentType) ||
                            slidingFee?.adjustmentType === 'None' ? (
                                <MessageBar>Patient may qualify for an income-based Sliding Fee plan.</MessageBar>
                            ) : isPovertyPercentageLess() === false ? (
                                <MessageBar>
                                    Patient is over income and not eligible for an income-based Sliding Fee plan. Guarantor will
                                    be responsible for 100% of services rendered.
                                </MessageBar>
                            ) : null}
                        </>
                    )}
                    {slidingFee?.adjustmentType ? (
                        <Stack horizontal tokens={{ childrenGap: 10 }} styles={_infoStyles}>
                            <Stack.Item grow>
                                {slidingFee?.adjustmentType && (
                                    <InformationItem label="Adjustment Type:" text={slidingFee?.adjustmentType} />
                                )}
                                {slidingFee?.slidingFeeProgram && (
                                    <InformationItem label="Program:" text={slidingFee?.slidingFeeProgram} />
                                )}
                                {/* <InformationItem label="FPL Year:" text="N/A" /> */}
                                {slidingFee?.slidingFeePlan && (
                                    <InformationItem label="Sliding Fee Plan:" text={slidingFee?.slidingFeePlan} />
                                )}
                            </Stack.Item>
                            <Separator vertical />
                            <Stack.Item grow>
                                {slidingFee?.effectiveDate && (
                                    <InformationItem label="Effective Date:" text={dateOnly(slidingFee.effectiveDate)} />
                                )}
                                {slidingFee?.expirationDate && (
                                    <InformationItem label="Expiration Date:" text={dateOnly(slidingFee.expirationDate)} />
                                )}
                            </Stack.Item>
                        </Stack>
                    ) : null}
                </Stack>
            </Stack>
        </Stack>
    );
}

export default Insurance;
