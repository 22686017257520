import IPatient from 'api/models/patient.model';
import Field from 'components/Field/Field';
import SubSection from 'components/SubSection';
import { useSelector } from 'hooks';
import { useDispatch } from 'react-redux';
import { selectEditPatient } from 'state/slices/edit-patient/edit-patient.selectors';
import { editPatientPropChange } from 'state/slices/edit-patient/edit-patient.slice';
import { holdingStatementList } from 'state/slices/tenant/holding-statement.slice';
import { statementConfig } from 'state/slices/tenant/statementConfig.slice';

const HoldingStatement = () => {
    const holdStatementOptions = useSelector(holdingStatementList);
    const patient = useSelector(selectEditPatient);

    const dispatch = useDispatch();

    const _propChange = (key: keyof IPatient, value: unknown): void => {
        dispatch(editPatientPropChange({ key, value }));
    };

    return (
        <SubSection title="Billing">
            <Field.Dropdown
                label="Hold Statement"
                placeholder="Do not hold statements"
                options={holdStatementOptions}
                selectedKey={patient?.holdStatement}
                required
                style={{ width: 400 }}
                onChange={(_e, option) => {
                    _propChange('holdStatement', option?.key);
                }}
            />
        </SubSection>
    );
};

export default HoldingStatement;
