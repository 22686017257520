import { Calendar, ICalendarProps, Stack, useTheme } from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import { selectPatientOverviewOpen, selectSelectedDate } from 'state/slices/scheduling/scheduling.selectors';
import { closeAppointmentOverview, setSelectedDate } from 'state/slices/scheduling/scheduling.slice';
import DayPickerStrings from '../../../components/Field/DateFieldCalendar.config';

function CalendarDatepicker(): JSX.Element {
    const theme = useTheme();
    const dispatch = useDispatch();
    const selectedDate = useSelector(selectSelectedDate);

    const isApptOverviewOpen = useSelector(selectPatientOverviewOpen);

    const calendarProps: ICalendarProps = {
        showMonthPickerAsOverlay: false,
        showSixWeeksByDefault: true,
        isDayPickerVisible: true,
        isMonthPickerVisible: false,
        showGoToToday: false,
        highlightCurrentMonth: true,
        highlightSelectedMonth: true,
        strings: DayPickerStrings,
        value: selectedDate,
        // onDismiss: toggleShowCalendar,
        // minDate: isReasonable ? (minDate ? minDate : getDefaultReasonableDate()) : undefined,
        // maxDate: isReasonable ? (allowFutureDates ? undefined : maxDate ? maxDate : new Date()) : undefined,
        onSelectDate: (date: Date) => {
            dispatch(setSelectedDate(date));

            if (isApptOverviewOpen) dispatch(closeAppointmentOverview());
        },
    };

    return (
        <Stack
            styles={{
                root: { backgroundColor: theme.palette.neutralLighterAlt, maxHeight: 250 },
            }}
            horizontalAlign="center"
        >
            <Calendar {...calendarProps} />
        </Stack>
    );
}

export default CalendarDatepicker;
