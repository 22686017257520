import { IconButton, Modal, Stack } from '@fluentui/react';
import Report from 'components/Report';
import { useSelector } from 'hooks';
import { useDispatch } from 'react-redux';
import { selectReportingState } from 'state/slices/reporting/reporting.selectors';
import { cleanupSelectedReport } from 'state/slices/reporting/reporting.slice';

type Props = {
    upperContent?: React.ReactNode;
    closeDisabled?: boolean;
};

export default function ReportModal({ upperContent, closeDisabled }: Props): JSX.Element | null {
    const { selectedReport } = useSelector(selectReportingState);
    const dispatch = useDispatch();

    const closeModal = () => {
        dispatch(cleanupSelectedReport());
    };

    return (
        <Modal
            styles={{ main: { width: 1368, height: 768, overflowY: 'hidden' } }}
            isOpen={!!selectedReport}
            onDismiss={closeModal}
            isBlocking={false}
        >
            <Stack horizontalAlign="end">
                <IconButton
                    disabled={closeDisabled}
                    iconProps={{ iconName: 'cancel' }}
                    ariaLabel="Close popup modal"
                    onClick={closeModal}
                />
            </Stack>
            {upperContent ?? null}
            {selectedReport && <Report {...selectedReport} />}
        </Modal>
    );
}
