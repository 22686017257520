import { createAsyncThunk } from '@reduxjs/toolkit';
import IPatient from 'api/models/patient.model';
import IPatientAppointment from 'api/models/Scheduling/patientAppointment.model';
import schedulingApi from 'api/scheduling.api';
import { TrackerStatus } from 'pages/Scheduling/components/TrackerStatusDropdown';
import { updatePatient } from 'state/slices/edit-patient/edit-patient.actions';
import { RootState } from 'state/store';
import { updatePatientAppointment } from '../scheduling.slice';

export const finishCheckin = createAsyncThunk<
    IPatientAppointment | undefined,
    { tenantId: string; appointment: IPatientAppointment; patient: IPatient },
    { state: RootState }
>('finishCheckin', async ({ tenantId, appointment, patient }, { dispatch }) => {
    if (patient && appointment) {
        const { data: patientApptData } = await schedulingApi.getPatientAppointment(tenantId, appointment.id);
        const newApptData: IPatientAppointment = {
            ...patientApptData,
            trackerStatusId: TrackerStatus.Ready,
            lastVerifiedMailingAddress: appointment.lastVerifiedMailingAddress ?? patientApptData.lastVerifiedMailingAddress,
            lastVerifiedPrimaryPhoneNumber:
                appointment.lastVerifiedPrimaryPhoneNumber ?? patientApptData.lastVerifiedPrimaryPhoneNumber,
            lastVerifiedResidentialAddress:
                appointment.lastVerifiedResidentialAddress ?? patientApptData.lastVerifiedResidentialAddress,
            lastVerifiedHomelessDetail: appointment.lastVerifiedHomelessDetail ?? patientApptData.lastVerifiedHomelessDetail,
            lastVerifiedHomelessStatus: appointment.lastVerifiedHomelessStatus ?? patientApptData.lastVerifiedHomelessStatus,
            lastVerifiedIncome: appointment.lastVerifiedIncome ?? patientApptData.lastVerifiedIncome,
            lastVerifiedInsurance: appointment.lastVerifiedInsurance ?? patientApptData.lastVerifiedInsurance,
        };
        await dispatch(updatePatient({ tenantId, model: patient }));
        const appointmentResponse = await dispatch(
            updatePatientAppointment({
                tenantId,
                appointment: newApptData,
            }),
        ).unwrap();

        return appointmentResponse;
    }
});

export const returnToScheduleFromCheckin = createAsyncThunk<
    void,
    { tenantId: string; appointment: IPatientAppointment; patient: IPatient },
    { state: RootState }
>('returnToScheduleFromCheckin', async ({ tenantId, appointment, patient }, { dispatch }) => {
    if (patient && appointment) {
        const { data: patientApptData } = await schedulingApi.getPatientAppointment(tenantId, appointment.id);

        const newApptData: IPatientAppointment = {
            ...patientApptData,
            lastVerifiedMailingAddress: appointment.lastVerifiedMailingAddress ?? patientApptData.lastVerifiedMailingAddress,
            lastVerifiedPrimaryPhoneNumber:
                appointment.lastVerifiedPrimaryPhoneNumber ?? patientApptData.lastVerifiedPrimaryPhoneNumber,
            lastVerifiedResidentialAddress:
                appointment.lastVerifiedResidentialAddress ?? patientApptData.lastVerifiedResidentialAddress,
            lastVerifiedHomelessDetail: appointment.lastVerifiedHomelessDetail ?? patientApptData.lastVerifiedHomelessDetail,
            lastVerifiedHomelessStatus: appointment.lastVerifiedHomelessStatus ?? patientApptData.lastVerifiedHomelessStatus,
            lastVerifiedIncome: appointment.lastVerifiedIncome ?? patientApptData.lastVerifiedIncome,
            lastVerifiedInsurance: appointment.lastVerifiedInsurance ?? patientApptData.lastVerifiedInsurance,
        };

        await dispatch(updatePatient({ tenantId, model: patient }));
        dispatch(
            updatePatientAppointment({
                tenantId,
                appointment: newApptData,
            }),
        );
    }
});
