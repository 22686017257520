import { Separator, Stack, TooltipHost } from '@fluentui/react';
import { IMostRecentTreatmentPlanPhaseView } from 'api/models/most-recent-treatment-plan-view.model';
import useUserIdentities from 'hooks/store/useUserIdentities';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectSignedTreatmentPlanViewGroupedProceduresByPhaseId } from 'state/slices/ledger/treatment-plan-credit-and-uac/treatment-plan-credit-and-uac.selectors';

type Props = {
    phase: IMostRecentTreatmentPlanPhaseView | undefined;
};

export default function TreatmentPlanCreditPhaseViewTooltip({ phase }: Props) {
    const { getMissingUserIdentities, userIdentitiesData } = useUserIdentities();
    const groupedPhaseProcedures = useSelector(selectSignedTreatmentPlanViewGroupedProceduresByPhaseId)[phase?.id ?? ''];

    const getTreatingProviderIdsAndHygienistIds = useCallback(() => {
        return {
            treatingProviderIds:
                (groupedPhaseProcedures?.map((p) => p.treatingProviderId).filter((p) => p !== undefined) as string[]) ?? [],
            hygienistIds: (groupedPhaseProcedures?.map((p) => p.hygienistId).filter((p) => p !== undefined) as string[]) ?? [],
        };
    }, [groupedPhaseProcedures]);

    useEffect(() => {
        const { hygienistIds, treatingProviderIds } = getTreatingProviderIdsAndHygienistIds();
        getMissingUserIdentities([...hygienistIds, ...treatingProviderIds]);
    }, [getTreatingProviderIdsAndHygienistIds()]);

    return (
        <TooltipHost
            content={
                groupedPhaseProcedures?.length
                    ? groupedPhaseProcedures.map((procedure, index) => {
                          const treatingProviderId = procedure.treatingProviderId ?? '';
                          const treatingProvider = userIdentitiesData[treatingProviderId]?.displayName;
                          return (
                              <div key={index}>
                                  <Stack horizontal tokens={{ childrenGap: 3 }}>
                                      <div>{`${procedure.procedureCode}${
                                          procedure.count > 1 ? ` (x${procedure.count})` : ''
                                      }`}</div>
                                      {treatingProvider ? <div>| {treatingProvider}</div> : ''}
                                  </Stack>
                                  {index !== groupedPhaseProcedures.length - 1 && <Separator />}
                              </div>
                          );
                      })
                    : undefined
            }
        >
            <span>{phase?.displayName ?? ''}</span>
        </TooltipHost>
    );
}
