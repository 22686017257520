import { useDispatch, useSelector } from 'react-redux';
import { batchActions, selectBatchPanelOpen } from 'state/slices/tenant/batches.slice';
import { BatchPanel } from './BatchPanel';

export default function GlobalBatchPanel(): JSX.Element {
    const dispatch = useDispatch();
    const isOpen = useSelector(selectBatchPanelOpen);

    const onDismiss = () => dispatch(batchActions.setBatchPanelOpen(false));

    return <BatchPanel isOpen={isOpen} onDismiss={onDismiss} />;
}
