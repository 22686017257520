import {
    Checkbox,
    DefaultButton,
    Label,
    mergeStyles,
    MessageBar,
    Panel,
    PanelType,
    PrimaryButton,
    ProgressIndicator,
    Separator,
    Spinner,
    Stack,
    Text,
    TooltipHost,
    useTheme,
} from '@fluentui/react';
import { IChartNoteWithData, IChartNoteWithValueData, IClinicalNoteData, NoteType } from 'api/models/chart-note.model';
import { ReportType } from 'api/models/embed-report.model';
import { useSelector, useTenantId } from 'hooks';
import useChartNotes from 'hooks/store/useChartNotes';
import useUserIdentities from 'hooks/store/useUserIdentities';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { orderBy } from 'lodash';
import PanelSectionHeader from 'pages/components/PanelSectionHeader';
import ReportModal from 'pages/Reporting/ReportModal';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    clinicalNotesList,
    filteredClinicalNotesList,
    selectChartNotesState,
    selectClinicalNotesWithEncounterDates,
} from 'state/slices/chart-notes/chart-notes.selectors';
import { openNoteModal } from 'state/slices/chart-notes/note-browser/note-browser.actions';
import { noteBrowserActions, NoteBrowserModal } from 'state/slices/chart-notes/note-browser/note-browser.slice';
import { clinicalNoteReportPanel } from 'state/slices/reporting/clinical-notes-reports/clinical-notes-reports.selectors';
import reportActionLookup from 'state/slices/reporting/reportActionLookup';
import { selectReportingState } from 'state/slices/reporting/reporting.selectors';
import { setClinicalPanelOpen } from 'state/slices/reporting/reporting.slice';
import { classicDateOnly } from 'utils/dateOnly';
import RenderNote from 'utils/renderChartNote';

type NoteProps = {
    note: IChartNoteWithData<IChartNoteWithValueData>;
    index: number;
};

function MissingAttestation({ tooltipContent }: { tooltipContent: string }) {
    const { palette } = useTheme();
    return (
        <TooltipHost content={tooltipContent}>
            <span
                style={{
                    width: 20,
                    height: 20,
                    backgroundColor: `${palette.orange}`,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: `${palette.white}`,
                    borderRadius: '50%',
                    fontWeight: 'bold',
                    cursor: 'default',
                }}
            >
                !
            </span>
        </TooltipHost>
    );
}

function Note({ note, index }: NoteProps) {
    const { palette } = useTheme();
    const { currentNote } = useChartNotes();
    const { userIdentitiesData } = useUserIdentities();
    const date = note?.encounterDate
        ? classicDateOnly(note.encounterDate)
        : note?.createdOn
        ? classicDateOnly(note.createdOn)
        : '';
    const dispatch = useDispatch();

    const isFirstNote = index === 0;

    function onClick() {
        dispatch(openNoteModal(NoteBrowserModal.NoteBrowser, true));
        dispatch(noteBrowserActions.setChartNote(note));
    }

    const noteSelected = currentNote?.id === note.id;

    const className = mergeStyles({
        transition: '.1s all',
        padding: 5,
        borderRadius: 3,
        borderLeft: !noteSelected ? '3px solid transparent' : `3px solid ${palette.themePrimary}`,
        ':hover': {
            backgroundColor: palette.neutralQuaternaryAlt,
            cursor: 'pointer',
        },
    });

    return (
        <Stack onClick={onClick}>
            {isFirstNote && <Separator />}
            <Stack
                verticalAlign="center"
                className={className}
                styles={{ root: { position: 'relative' } }}
                tokens={{ childrenGap: 4 }}
                grow
            >
                {!note.attestationDate && note.noteType === NoteType.ClinicalNote && (
                    <div
                        style={{
                            position: 'absolute',
                            right: 0,
                            top: isFirstNote ? -7 : -15,
                        }}
                    >
                        <MissingAttestation tooltipContent={'This note is missing attestation.'} />
                    </div>
                )}
                <div
                    dangerouslySetInnerHTML={{
                        __html: note.data.value ? new RenderNote(note).buildNoteString() : '',
                    }}
                ></div>
                <Text variant="small" styles={{ root: { color: palette.neutralSecondary } }}>
                    {date} by{' '}
                    {note.createdBy
                        ? `${userIdentitiesData[note.createdBy]?.firstName ?? ''} ${
                              userIdentitiesData[note.createdBy]?.lastName ?? ''
                          }${
                              userIdentitiesData[note.createdBy]?.professionalSuffix
                                  ? `, ${userIdentitiesData[note.createdBy]?.professionalSuffix}`
                                  : ''
                          }`
                        : 'Unknown'}
                </Text>
            </Stack>
            <Separator />
        </Stack>
    );
}

export default function ChartNotes(): JSX.Element {
    const dispatch = useDispatch();

    const { loading } = useSelector(selectChartNotesState);

    const _clinicalNotesList = useSelector(clinicalNotesList);
    const _filteredClinicalNotesList = useSelector(filteredClinicalNotesList);

    const { getMissingUserIdentities, loadingUserIdentities } = useUserIdentities();

    useEffect(() => {
        if (clinicalNotesList.length && loadingUserIdentities !== LoadingStatus.Pending) {
            const createdByUsers = _clinicalNotesList.map((n) => n.createdBy).filter((id) => id !== undefined) as string[];
            getMissingUserIdentities(createdByUsers);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_clinicalNotesList.length]);

    const notesList = _filteredClinicalNotesList.map((note, index) => <Note key={index} note={note} index={index} />);

    const openNoteBrowser = () => dispatch(openNoteModal(NoteBrowserModal.NoteBrowser, true));

    return (
        <Stack tokens={{ childrenGap: 5 }} style={{ overflow: 'hidden' }} grow>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
                <DefaultButton text="Print" iconProps={{ iconName: 'Print' }} onClick={() => dispatch(setClinicalPanelOpen())} />
                <PrimaryButton text="Note Browser" iconProps={{ iconName: 'QuickNote' }} onClick={openNoteBrowser} />
            </Stack>
            {loading === 'completed' && _clinicalNotesList.length && (
                <>
                    <Stack styles={{ root: { overflowY: 'auto' } }} tokens={{ childrenGap: 8 }} grow>
                        {notesList?.length ? notesList : <MessageBar>No notes have been added</MessageBar>}
                    </Stack>
                </>
            )}
            {loading === 'pending' && <ProgressIndicator label="Loading Notes..." />}
            {!_clinicalNotesList.length ? (
                <MessageBar>No notes have been added.</MessageBar>
            ) : (
                !_filteredClinicalNotesList.length && <MessageBar>No notes found.</MessageBar>
            )}
            <PrintChartNotesPanel />
        </Stack>
    );
}

export function PrintChartNotesPanel() {
    const dispatch = useDispatch();
    const tenantId = useTenantId();

    const _filteredClinicalNotesList = useSelector(selectClinicalNotesWithEncounterDates);

    const _clinicalNoteReportPanel = useSelector(clinicalNoteReportPanel);

    const { loadingSelectedReport } = useSelector(selectReportingState);
    const isLoading = loadingSelectedReport === LoadingStatus.Pending;

    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState<string[]>([]);
    const [list, setList] = useState<IChartNoteWithData<IClinicalNoteData>[]>([]);

    useEffect(() => {
        setList(_filteredClinicalNotesList);
    }, [_filteredClinicalNotesList]);

    const clinicalNotesList = _filteredClinicalNotesList.filter((note) => note.noteType === NoteType.ClinicalNote);
    const patientCommunicationNotesList = _filteredClinicalNotesList.filter(
        (note) => note.noteType === NoteType.PatientCommunication,
    );
    const careCoordinationNotesList = _filteredClinicalNotesList.filter((note) => note.noteType === NoteType.CareCoordination);
    const followUpNotesList = _filteredClinicalNotesList.filter((note) => note.noteType === NoteType.FollowUp);

    const maxPrintLimit = list.length > 30;
    const maxCheckLimit = isCheck.length >= 30;

    const handleSelectAll = () => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(
            list.map((li) => {
                return li.id;
            }),
        );

        if (isCheckAll) {
            setIsCheck([]);
        }
    };

    const handleClick = (e: any) => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, id]);

        if (!checked) {
            setIsCheck(isCheck.filter((item) => item !== id));
        }
    };

    const viewReport = () => {
        dispatch(reportActionLookup[ReportType.ClinicalNote]({ tenantId, chartNoteId: isCheck }));
    };

    const _onRenderFooterContent = () => {
        return (
            <Stack horizontal tokens={{ childrenGap: 10 }}>
                <PrimaryButton
                    onRenderText={(props) => (
                        <Stack horizontal tokens={{ childrenGap: 5 }}>
                            <span>{props?.text} </span>
                            {isLoading && <Spinner />}
                        </Stack>
                    )}
                    text="Print"
                    iconProps={{ iconName: 'Print' }}
                    onClick={viewReport}
                />
                <DefaultButton text="Cancel" onClick={() => dispatch(setClinicalPanelOpen())} />
            </Stack>
        );
    };

    const onDismiss = () => {
        setIsCheck([]);
        setIsCheckAll(false);
    };

    return (
        <Panel
            headerText="Print Chart Notes"
            type={PanelType.smallFixedFar}
            isOpen={_clinicalNoteReportPanel}
            onRenderFooterContent={_onRenderFooterContent}
            isFooterAtBottom
            onDismiss={() => dispatch(setClinicalPanelOpen())}
            onDismissed={() => onDismiss()}
        >
            <Stack tokens={{ childrenGap: 5 }} grow>
                <PanelSectionHeader text="Select  note date(s) to print chart note(s)."></PanelSectionHeader>

                {_filteredClinicalNotesList.length ? (
                    <Stack tokens={{ childrenGap: 5 }} grow>
                        {maxPrintLimit ? (
                            <MessageBar>You can only print up to 30 notes at a time.</MessageBar>
                        ) : (
                            <Checkbox label={'Select All'} onChange={handleSelectAll} checked={isCheckAll} />
                        )}

                        <Stack tokens={{ childrenGap: 5 }}>
                            <ChartNotesList
                                title="Encounter Notes"
                                noteList={clinicalNotesList}
                                handleClick={handleClick}
                                isCheck={isCheck}
                                disabled={maxCheckLimit}
                            />
                        </Stack>
                        <Stack tokens={{ childrenGap: 5 }}>
                            <ChartNotesList
                                title="Patient Communication"
                                noteList={patientCommunicationNotesList}
                                handleClick={handleClick}
                                isCheck={isCheck}
                                disabled={maxCheckLimit}
                            />
                        </Stack>
                        <Stack tokens={{ childrenGap: 5 }}>
                            <ChartNotesList
                                title="Care Coordination"
                                noteList={careCoordinationNotesList}
                                handleClick={handleClick}
                                isCheck={isCheck}
                                disabled={maxCheckLimit}
                            />
                        </Stack>
                        <Stack tokens={{ childrenGap: 5 }}>
                            <ChartNotesList
                                title="Follow Up Notes"
                                noteList={followUpNotesList}
                                handleClick={handleClick}
                                isCheck={isCheck}
                                disabled={maxCheckLimit}
                            />
                        </Stack>
                    </Stack>
                ) : (
                    <MessageBar>No notes have been added.</MessageBar>
                )}
            </Stack>
            <ReportModal closeDisabled={isLoading} />
        </Panel>
    );
}

type ChartNoteListsProps = {
    title: string;
    noteList?: IChartNoteWithData<any>[];
    handleClick: (e: any) => void;
    isCheck: string[];
    disabled?: boolean;
};
function ChartNotesList({ title, noteList, handleClick, isCheck, disabled }: ChartNoteListsProps) {
    if (!noteList) return null;

    return (
        <Stack tokens={{ childrenGap: 5 }}>
            {noteList.length ? (
                <Stack tokens={{ childrenGap: 5 }}>
                    <Label> {title}</Label>

                    {orderBy(
                        noteList.map((note) => {
                            const dateDisplay = note?.encounterDate
                                ? classicDateOnly(note.encounterDate)
                                : note?.createdOn
                                ? classicDateOnly(note.createdOn)
                                : '';

                            return (
                                <Checkbox
                                    key={note.id}
                                    label={dateDisplay}
                                    id={note.id}
                                    onChange={handleClick}
                                    checked={isCheck.includes(note.id)}
                                    disabled={disabled && !isCheck.includes(note.id)}
                                />
                            );
                        }),
                        ['encounterDate', 'createdOn'],
                        ['desc', 'desc'],
                    )}
                </Stack>
            ) : null}
        </Stack>
    );
}
