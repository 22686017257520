import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import IPatient from 'api/models/patient.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { fetchAdminHuddleTasks, getEncounterPatient, getWorkListsMenu } from './admin-huddle.actions';
import { AdminHuddleState } from './admin-huddle.state';
import {
    createAmendmentNote,
    updateEncounterProviderByProviderIdType,
} from './worklists/encounter-worklists/encounter-worklists.actions';
import { MessageBarType } from '@fluentui/react';
import { ProviderIdType } from 'api/models/encounter.model';

export const adminHuddleReducers = {
    setEncounterPatient: (state: AdminHuddleState, action: PayloadAction<IPatient | undefined>): void => {
        const patient = action.payload;
        state.encounterPatient.data = patient;
    },
    setAmendmentId: (state: AdminHuddleState, action: PayloadAction<string | undefined>): void => {
        const type = action.payload;
        state.visitSummary.amendmentId = type;
    },
    setAmendmentNote: (state: AdminHuddleState, action: PayloadAction<string | undefined>): void => {
        const note = action.payload;
        state.visitSummary.amendmentNote = note;
    },
    setAmendmentType: (state: AdminHuddleState, action: PayloadAction<string | undefined>): void => {
        const displayName = action.payload;
        state.visitSummary.amendmentType = displayName;
    },
    setAmendmentTreatingProvider: (state: AdminHuddleState, action: PayloadAction<string | undefined>): void => {
        const treatingProviderId = action.payload;
        state.visitSummary.amendmentTreatingProvider = treatingProviderId;
    },
    setVisitSummaryPanelOpen: (state: AdminHuddleState, action: PayloadAction<boolean>): void => {
        state.visitSummary.isPanelOpen = action.payload;
    },
    cleanupAmendment: (state: AdminHuddleState): void => {
        state.visitSummary.amendmentId = undefined;
        state.visitSummary.amendmentNote = undefined;
        state.visitSummary.amendmentType = undefined;
        state.visitSummary.amendmentTreatingProvider = undefined;
    },
    cleanupWorkListsMenu: (state: AdminHuddleState): void => {
        state.menu.workLists.data = undefined;
        state.menu.workLists.loading = LoadingStatus.Idle;
    },
    cleanupVisitSummaryMessageBar: (state: AdminHuddleState): void => {
        state.visitSummary.messageBarMessage = undefined;
        state.visitSummary.messageBarType = undefined;
    },
};

export const adminHuddleExtraReducers = (
    builder: ActionReducerMapBuilder<AdminHuddleState>,
): ActionReducerMapBuilder<AdminHuddleState> =>
    builder
        .addCase(getEncounterPatient.pending, (state) => {
            state.encounterPatient.loading = LoadingStatus.Pending;
        })
        .addCase(getEncounterPatient.fulfilled, (state, action: PayloadAction<IPatient>) => {
            const patient = action.payload;
            state.encounterPatient.loading = LoadingStatus.Completed;
            state.encounterPatient.data = patient;
        })
        .addCase(getEncounterPatient.rejected, (state) => {
            state.encounterPatient.loading = LoadingStatus.Failed;
        })
        .addCase(fetchAdminHuddleTasks.pending, (state) => {
            state.adminHuddleTasks.loading = LoadingStatus.Pending;
        })
        .addCase(fetchAdminHuddleTasks.fulfilled, (state, action) => {
            const tasks = action.payload;
            state.adminHuddleTasks.data = tasks;
            state.adminHuddleTasks.loading = LoadingStatus.Completed;
        })
        .addCase(fetchAdminHuddleTasks.rejected, (state) => {
            state.adminHuddleTasks.loading = LoadingStatus.Failed;
        })

        .addCase(getWorkListsMenu.pending, (state) => {
            state.menu.workLists.loading = LoadingStatus.Pending;
        })
        .addCase(getWorkListsMenu.fulfilled, (state, action) => {
            state.menu.workLists.data = action.payload;
            state.menu.workLists.loading = LoadingStatus.Completed;
        })
        .addCase(getWorkListsMenu.rejected, (state) => {
            state.menu.workLists.loading = LoadingStatus.Failed;
        })
        .addCase(createAmendmentNote.fulfilled, (state) => {
            state.visitSummary.amendmentId = undefined;
            state.visitSummary.amendmentNote = undefined;
            state.visitSummary.amendmentType = undefined;

            state.visitSummary.messageBarMessage = 'Appointment encounter sent for amendment successfully.';
            state.visitSummary.messageBarType = MessageBarType.success;
            state.visitSummary.savingAmendment = LoadingStatus.Completed;
        })
        .addCase(createAmendmentNote.rejected, (state) => {
            state.visitSummary.messageBarMessage = 'Something went wrong.';
            state.visitSummary.messageBarType = MessageBarType.error;
            state.visitSummary.savingAmendment = LoadingStatus.Failed;
        })
        .addCase(createAmendmentNote.pending, (state) => {
            state.visitSummary.messageBarMessage = undefined;
            state.visitSummary.messageBarType = undefined;

            state.visitSummary.savingAmendment = LoadingStatus.Pending;
        })
        .addCase(updateEncounterProviderByProviderIdType.pending, (state) => {
            state.visitSummary.savingBillingProvider = LoadingStatus.Pending;
        })
        .addCase(updateEncounterProviderByProviderIdType.fulfilled, (state, { meta }) => {
            const providerMessageBarMessageMapping: Record<ProviderIdType, string> = {
                supervisingProviderId: 'attending',
                billingProviderId: 'billing',
                treatingProviderId: 'treating',
                attestingProviderId: 'attesting',
                hygienistId: 'hygienist',
                registeredDentalAssistantId: 'RDA',
            };

            state.visitSummary.messageBarMessage = `Updated ${
                providerMessageBarMessageMapping[meta.arg.providerIdType]
            } provider successfully.`;
            state.visitSummary.messageBarType = MessageBarType.success;
            state.visitSummary.savingBillingProvider = LoadingStatus.Completed;
        })
        .addCase(updateEncounterProviderByProviderIdType.rejected, (state) => {
            state.visitSummary.messageBarMessage = "Something went wrong when updating this encounter's billing provider.";
            state.visitSummary.messageBarType = MessageBarType.error;
            state.visitSummary.savingBillingProvider = LoadingStatus.Failed;
        });
