import * as PIXI from 'pixi.js-legacy';
import Tooth from './Tooth';
import ToothOverlay from './ToothOverlay';

export default class AbscessPeriodontal extends ToothOverlay {
    private graphics = new PIXI.Graphics();
    private _position: 'Mesial' | 'Distal';
    private _toothWidth: number;

    constructor(tooth: Tooth, position: 'Mesial' | 'Distal') {
        super(tooth);
        this._toothWidth = tooth.getToothWidth;
        this._position = position;
        this._createGraphics();
    }

    private _createGraphics() {
        const { dentitionMode } = this.tooth.data;
        const dentitionY =
            dentitionMode === 'Primary' || dentitionMode === 'PrimarySupernumerary' ? (this.getIsBottomRow ? 10 : -10) : 0;

        this.graphics.beginFill(0x000000);

        const xPositionMesial = this._toothWidth - 5;
        const xPositionDistal = 10;

        const isRightSideOfMouth = !this.tooth.getIsLeftMouth;

        this.graphics.drawCircle(
            this._position === 'Mesial'
                ? isRightSideOfMouth
                    ? xPositionDistal
                    : xPositionMesial
                : isRightSideOfMouth
                ? xPositionMesial
                : xPositionDistal,
            this.getIsBottomRow ? this.tooth.height / 2 + 160 + dentitionY : -this.tooth.height / 2 + -40 + dentitionY,
            8,
        );
        this.graphics.endFill();

        this.addChild(this.graphics);
    }
}
