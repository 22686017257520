import CheckInPanel from 'pages/Scheduling/components/Checkin/CheckInPanel';
import CheckoutPanel from 'pages/Scheduling/components/Checkout/CheckoutPanel';
import EditEncountersConfirmationModal from './EncounterWorkLists/EditEncountersConfirmationModal';
import { EncounterNoteModal } from './EncounterWorkLists/EncounterNoteModal';
import { VoidProcedureConfirmation } from './EncounterWorkLists/VoidProcedureConfirmation';
import VisitSummaryPanel, { ReadOnlyVisitSummaryPanel } from './LayerComponents/VisitSummary/VisitSummaryPanel';
import DOSThroughFilter from './FilterComponents/DOSThroughFilter';
import FindPatientFilter from './FilterComponents/FindPatientFilter';
import LocationOfCareFilter from './FilterComponents/LocationOfCareFilter';
import PrimaryPayerSourceFilter from './FilterComponents/PrimaryPayerSourceFilter';
import ProvidersFilter from './FilterComponents/ProvidersFilter';
import ReasonCodesFilter from './FilterComponents/ReasonCodesFilter';
import DOSFromEncounterWorkListFilter from './FilterComponents/DOSFromEncounterWorkListFilter';
import appointmentConfirmationStatus from './ColumnComponents/AppointmentConfirmationStatusColumn';
import { IColumn } from '@fluentui/react';
import appointmentDateLink from './ColumnComponents/AppointmentDateLinkColumn';
import appointmentDate from './ColumnComponents/AppointmentDateColumn';
import appointmentTrackerStatus, { appointmentTrackerStatusReadOnly } from './ColumnComponents/AppointmentTrackerStatusColumn';
import attendingProvider from './ColumnComponents/AttendingProvider';
import attestingProvider from './ColumnComponents/AttestingProviderColumn';
import authNumber from './ColumnComponents/AuthNumberColumn';
import billingNote from './ColumnComponents/BillingNoteColumn';
import billingProvider from './ColumnComponents/BillingProviderColumn';
import cancelAppointment from './ColumnComponents/CancelAppointmentColumn';
import completingProvider from './ColumnComponents/CompletingProviderColumn';
import diagnosisCodes from './ColumnComponents/DiagnosisCodesColumn';
import documentationStatus from './ColumnComponents/DocumentationStatus';
import encounterCorrespondence from './ColumnComponents/EncounterCorrespondenceColumn';
import encounterStatus from './ColumnComponents/EncounterStatusColumn';
import insuranceFee from './ColumnComponents/InsuranceFeeColumn';
import lastUpdated from './ColumnComponents/LastUpdatedColumn';
import onHold from './ColumnComponents/OnHoldColumn';
import patientFee from './ColumnComponents/PatientFeeColumn';
import locationOfCareName from './ColumnComponents/LocationOfCareColumn';
import patientPrimaryPhone from './ColumnComponents/PatientPrimaryPhoneColumn';
import primaryPayerId from './ColumnComponents/PrimaryPayerIdColumn';
import primaryPayerSource from './ColumnComponents/PrimaryPayerSourceColumn';
import procedureCode from './ColumnComponents/ProcedureCodeColumn';
import procedureDescription from './ColumnComponents/ProcedureDescriptionColumn';
import reasonCodes from './ColumnComponents/ReasonCodesColumn';
import reasonForEncounter from './ColumnComponents/ReasonForEncounterColumn';
import recalculateEncounterButton from './ColumnComponents/RecalculateColumn';
import secondaryPayerId from './ColumnComponents/SecondaryPayerIdColumn';
import secondaryPayerSource from './ColumnComponents/SecondaryPayerSourceColumn';
import tooth from './ColumnComponents/ToothColumn';
import toothArea from './ColumnComponents/ToothAreaColumn';
import totalFee from './ColumnComponents/TotalFeeColumn';
import visitSummary from './ColumnComponents/VisitSummaryColumn';
import voidProcedure from './ColumnComponents/VoidProcedureColumn';
import encounterDate from './ColumnComponents/EncounterDateColumn';
import WorkListEditPatientPanel from './LayerComponents/WorkListEditPatientPanel';
import WorkListAppointmentOverview from './LayerComponents/WorkListAppointmentOverview';
import WorkListCancelAppointmentModal from './LayerComponents/WorkListCancelAppointmentModal';
import { patientNameGlobalEditPatient, patientNameWorkList } from './ColumnComponents/PatientNameColumn';
import TrackerStatusFilter from './FilterComponents/TrackerStatusFilter';
import outstandingCheckinAction from './ColumnComponents/OustsandingCheckinActionsColumn';
import amendReason from './ColumnComponents/AmendReasonColumn';
import amendNotes from './ColumnComponents/AmendNoteColumn';
import RCMFilter from './FilterComponents/RCMFilter';
import treatingProvider from './ColumnComponents/TreatingProviderColumn';
import PreAuthorizationColumn from './ColumnComponents/PreAuthoriztionColumn';
import PreAuthorizationCodeColumn from './ColumnComponents/PreAuthorizationCodeColumn';
import { EditPredeterminationModal } from './Predeterminations/EditPredetermination';
import SignedDateColumn from './ColumnComponents/SignedDateColumn';
import editAuthorizationColumn from './ColumnComponents/EditAuthorizationColumn';
import PreAuthorizationStatusFilter from './FilterComponents/PreAuthorizationStatusFilter';
import SignedDateRangeFilter from './FilterComponents/SignedDateRangeFilter';
import amendingProviderColumn from './ColumnComponents/AmendingProviderColumn';
import attestingProviderName from './ColumnComponents/AttestingProviderNameColumn';
import billingProviderName from './ColumnComponents/BillingProviderNameColumn';

export type WorkListFilterComponents =
    | 'DOSFromEncounterWorkListFilter'
    | 'DOSThroughFilter'
    | 'RCMFilter'
    | 'FindPatientFilter'
    | 'LocationOfCareFilter'
    | 'PrimaryPayerSourceFilter'
    | 'ProvidersFilter'
    | 'TrackerStatusFilter'
    | 'ReasonCodesFilter'
    | 'PreAuthStatusFilter'
    | 'SignedDateRangeFilter';

export const workListFilterComponents: Record<WorkListFilterComponents, React.FunctionComponent> = {
    DOSFromEncounterWorkListFilter: DOSFromEncounterWorkListFilter,
    DOSThroughFilter: DOSThroughFilter,
    RCMFilter: RCMFilter,
    FindPatientFilter: FindPatientFilter,
    LocationOfCareFilter: LocationOfCareFilter,
    PrimaryPayerSourceFilter: PrimaryPayerSourceFilter,
    ProvidersFilter: ProvidersFilter,
    ReasonCodesFilter: ReasonCodesFilter,
    TrackerStatusFilter: TrackerStatusFilter,
    PreAuthStatusFilter: PreAuthorizationStatusFilter,
    SignedDateRangeFilter: SignedDateRangeFilter,
};

export type WorkListLayerComponents =
    | 'EncounterNoteModal'
    | 'VisitSummaryPanel'
    | 'EditEncountersConfirmationModal'
    | 'VoidProcedureConfirmation'
    | 'CheckoutPanel'
    | 'CheckInPanel'
    | 'AppointmentOverview'
    | 'ReadOnlyVisitSummaryPanel'
    | 'CancelAppointmentModal'
    | 'EditPatientPanel'
    | 'EditPredetermination';

export const workListLayerComponents: Record<WorkListLayerComponents, React.FunctionComponent<{ readOnly: boolean }>> = {
    EncounterNoteModal: EncounterNoteModal,
    EditEncountersConfirmationModal: EditEncountersConfirmationModal,
    VisitSummaryPanel: VisitSummaryPanel,
    VoidProcedureConfirmation: VoidProcedureConfirmation,
    CheckInPanel: CheckInPanel,
    AppointmentOverview: WorkListAppointmentOverview,
    CheckoutPanel: CheckoutPanel,
    ReadOnlyVisitSummaryPanel: ReadOnlyVisitSummaryPanel,
    EditPatientPanel: WorkListEditPatientPanel,
    CancelAppointmentModal: WorkListCancelAppointmentModal,
    EditPredetermination: EditPredeterminationModal,
};

export type WorkListColumnComponents =
    | 'appointmentConfirmationStatus'
    | 'appointmentDate'
    | 'appointmentDateLink'
    | 'appointmentTrackerStatus'
    | 'appointmentTrackerStatusReadOnly'
    | 'attendingProvider'
    | 'authNumber'
    | 'billingNote'
    | 'billingProvider'
    | 'billingProviderName'
    | 'cancelAppointment'
    | 'completingProvider'
    | 'diagnosisCodes'
    | 'documentationStatus'
    | 'encounterCorrespondence'
    | 'encounterStatus'
    | 'encounterDate'
    | 'insuranceFee'
    | 'lastUpdated'
    | 'locationOfCareName'
    | 'onHold'
    | 'patientFee'
    | 'patientNameGlobal'
    | 'patientNameWorkList'
    | 'patientPrimaryPhone'
    | 'primaryPayerId'
    | 'primaryPayerSource'
    | 'procedureCode'
    | 'procedureDescription'
    | 'reasonCodes'
    | 'reasonForEncounter'
    | 'recalculateEncounterButton'
    | 'secondaryPayerId'
    | 'secondaryPayerSource'
    | 'toothArea'
    | 'tooth'
    | 'totalFee'
    | 'treatingProvider'
    | 'visitSummary'
    | 'voidProcedure'
    | 'outstandingCheckinActions'
    | 'amendReason'
    | 'amendNotes'
    | 'attestingProvider'
    | 'attestingProviderName'
    | 'preAuth'
    | 'preAuthCode'
    | 'signedDate'
    | 'editAuthorzation'
    | 'amendingProvider';

export const workListColumnComponents: Record<WorkListColumnComponents, IColumn> = {
    appointmentConfirmationStatus: appointmentConfirmationStatus,
    appointmentDate: appointmentDate,
    appointmentDateLink: appointmentDateLink,
    appointmentTrackerStatus: appointmentTrackerStatus,
    appointmentTrackerStatusReadOnly: appointmentTrackerStatusReadOnly,
    attendingProvider: attendingProvider,
    attestingProvider: attestingProvider,
    authNumber: authNumber,
    billingNote: billingNote,
    billingProvider: billingProvider,
    cancelAppointment: cancelAppointment,
    completingProvider: completingProvider,
    diagnosisCodes: diagnosisCodes,
    documentationStatus: documentationStatus,
    encounterCorrespondence: encounterCorrespondence,
    encounterStatus: encounterStatus,
    encounterDate: encounterDate,
    insuranceFee: insuranceFee,
    lastUpdated: lastUpdated,
    locationOfCareName: locationOfCareName,
    onHold: onHold,
    patientFee: patientFee,
    patientNameGlobal: patientNameGlobalEditPatient,
    patientNameWorkList: patientNameWorkList,
    patientPrimaryPhone: patientPrimaryPhone,
    primaryPayerId: primaryPayerId,
    primaryPayerSource: primaryPayerSource,
    procedureCode: procedureCode,
    procedureDescription: procedureDescription,
    reasonCodes: reasonCodes,
    reasonForEncounter: reasonForEncounter,
    recalculateEncounterButton: recalculateEncounterButton,
    secondaryPayerId: secondaryPayerId,
    secondaryPayerSource: secondaryPayerSource,
    tooth: tooth,
    toothArea: toothArea,
    totalFee: totalFee,
    treatingProvider: treatingProvider,
    visitSummary: visitSummary,
    voidProcedure: voidProcedure,
    amendReason: amendReason,
    amendNotes: amendNotes,
    outstandingCheckinActions: outstandingCheckinAction,
    preAuth: PreAuthorizationColumn,
    preAuthCode: PreAuthorizationCodeColumn,
    signedDate: SignedDateColumn,
    editAuthorzation: editAuthorizationColumn,
    amendingProvider: amendingProviderColumn,
    attestingProviderName: attestingProviderName,
    billingProviderName: billingProviderName
};
