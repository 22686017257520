import { PayloadAction } from '@reduxjs/toolkit';
import { IReportingState } from '../reporting.state';
import { IWrapReport } from 'api/models/wrap.model';

export const wrapReducers = {
    setWrapProp: (
        state: IReportingState,
        action: PayloadAction<{ path: keyof IWrapReport; value: string | number | undefined }>,
    ): void => {
        const { path, value } = action.payload;
        ((state.reportProperties as IWrapReport)[path] as string | number | undefined) = value;
    },
    toggleWrapId: (state: IReportingState, action: PayloadAction<{ path: keyof IWrapReport; id: string }>): void => {
        const { id, path } = action.payload;
        if (!(state.reportProperties as IWrapReport)[path]) ((state.reportProperties as IWrapReport)[path] as unknown[]) = [];

        const currentIds = (state.reportProperties as IWrapReport)[path] as string[];

        if (currentIds)
            if (currentIds?.includes(id)) {
                ((state.reportProperties as IWrapReport)[path] as string[]) = currentIds.filter((_id) => _id !== id);
            } else {
                ((state.reportProperties as IWrapReport)[path] as string[]) = [...currentIds, id];
            }
    },
};
