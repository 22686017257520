import useSelector from 'hooks/useSelector';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { chartActionProcedures, chartActions, selectCharting } from 'state/slices/charting/chart/chart.selectors';
import { chartingActions, deselectTeeth, generateTeethFromDentition, setMenu } from 'state/slices/charting/chart/chart.slice';
import { chartConditionsAsList } from 'state/slices/charting/conditions/conditions.selectors';
import { chartDentitionsData } from 'state/slices/charting/dentition/dentition.selectors';
import { selectedTeethSelector } from 'state/slices/charting/ui/chartingUi.selectors';
import { selectConditionsData } from 'state/slices/tenant/conditions.slice';
import { filteredChartActions } from 'pages/Charting/components/ChartActionFilter/selectors';

function useChart() {
    const _dispatch = useDispatch();

    const { ui } = useSelector(selectCharting);
    const _selectedTeeth = useSelector(selectedTeethSelector);
    const _chartActions = useSelector(chartActions);
    const _chartActionProcedures = useSelector(chartActionProcedures);
    const _chartConditions = useSelector(chartConditionsAsList);
    const _conditions = useSelector(selectConditionsData);
    const _dentitions = useSelector(chartDentitionsData);
    const _filteredChartActions = useSelector(filteredChartActions);

    const _generateTeethFromDentition = useCallback(() => {
        return generateTeethFromDentition(_dentitions, _chartConditions, _conditions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_dentitions, _chartConditions]);

    const _setMenu = useCallback(
        (menu: 'quickActions' | 'procedure' | 'xrays' | 'notes') => {
            _dispatch(setMenu(menu));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [_dispatch],
    );

    const _deselectAllTeeth = useCallback(() => {
        _dispatch(deselectTeeth());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_dispatch]);

    const _isDeleteModalOpen = useSelector((state) => state.charting.ui.isDeleteModalOpen);
    const _setDeleteModalOpen = useCallback((isOpen: boolean) => {
        _dispatch(chartingActions.setDeleteModalOpen(isOpen));
    }, []);

    return {
        deselectAllTeeth: _deselectAllTeeth,
        setMenu: _setMenu,
        generateTeethFromDentition: _generateTeethFromDentition,
        selectedTeeth: _selectedTeeth,
        chartActionsList: _chartActions,
        chartActionProcedures: _chartActionProcedures,
        menu: ui.menu,
        isDeleteModalOpen: _isDeleteModalOpen,
        setDeleteModalOpen: _setDeleteModalOpen,
        filteredChartActions: _filteredChartActions,
    };
}

export default useChart;
