import {
    IColumn,
    ShimmeredDetailsList,
    IContextualMenuItem,
    IDragDropContext,
    IDragDropEvents,
    mergeStyles,
    useTheme,
} from '@fluentui/react';
import { Selection } from '@fluentui/utilities';
import { IQuickAction } from 'api/models/quick-action.model';
import { EditDetailsColumn } from 'components/EditDetailsColumn';
import { useQuickActions, useTenantId } from 'hooks';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    moveQuickActionsToAnotherCategory,
    updateQuickActionsDocument,
} from 'state/slices/tenant/quickActions/quickActions.actions';
import { selectQuickActionsLoading } from 'state/slices/tenant/quickActions/quickActions.slice';

type Props = {
    onItemsSelected: (items: IQuickAction[]) => void;
    onEdit: (item: IQuickAction) => void;
    toggleHideDialog: () => void;
};

export default function QuickActionsList({ onItemsSelected, onEdit, toggleHideDialog }: Props): JSX.Element {
    const { filteredQuickActionItems, unselectedCategories, quickActionItems } = useQuickActions();

    const _loading = useSelector(selectQuickActionsLoading);
    const moveToItems: IContextualMenuItem[] = unselectedCategories.map((item) => ({
        text: item.displayName,
        key: item.id,
    }));
    const dispatch = useDispatch();
    const tenantId = useTenantId();
    const theme = useTheme();
    const dragEnterClass = mergeStyles({
        backgroundColor: theme.palette.neutralLight,
    });

    let _draggedItem: IQuickAction | undefined = undefined;
    let _draggedIndex = 0;

    const quickActionsSelection = useRef(
        new Selection({
            onSelectionChanged: () => {
                onItemsSelected(_getQuickActionsSelected());
            },
        }),
    );

    function _getQuickActionsSelected() {
        return quickActionsSelection.current.getSelection() as IQuickAction[];
    }

    const _deleteQuickAction = () => {
        toggleHideDialog();
    };
    const _insertBeforeItem = (item: IQuickAction) => {
        const draggedItems = [_draggedItem!];
        const insertIndex = quickActionItems?.actions.indexOf(item);
        const items = quickActionItems?.actions.filter((itm) => draggedItems.indexOf(itm) === -1);
        items?.splice(insertIndex as number, 0, ...draggedItems);
        if (items) {
            dispatch(updateQuickActionsDocument(tenantId, items));
        }
    };

    const _getDragDropEvents = () => {
        return {
            canDrop: (dropContext?: IDragDropContext, dragContext?: IDragDropContext) => {
                return true;
            },
            canDrag: (item?: any) => {
                return true;
            },
            onDragEnter: (item?: any, event?: DragEvent) => {
                // return string is the css classes that will be added to the entering element.
                return dragEnterClass;
            },
            onDragLeave: (item?: any, event?: DragEvent) => {
                return;
            },
            onDrop: (item?: any, event?: DragEvent) => {
                if (_draggedItem) {
                    _insertBeforeItem(item);
                }
            },
            onDragStart: (item?: any, itemIndex?: number, selectedItems?: any[], event?: MouseEvent) => {
                _draggedItem = item;
                _draggedIndex = itemIndex!;
            },
            onDragEnd: (item?: any, event?: DragEvent) => {
                _draggedItem = undefined;
                _draggedIndex = 0;
            },
        };
    };
    const dragDropEvents: IDragDropEvents = _getDragDropEvents();

    const _moveActionToCategory = (action: IQuickAction, category: string) => {
        dispatch(moveQuickActionsToAnotherCategory(tenantId, action, category));
    };

    const columns: IColumn[] = [
        {
            key: 'editQuickAction',
            name: 'Name',
            minWidth: 70,
            onRender: (item: IQuickAction) => (
                <EditDetailsColumn
                    editOnClick={() => onEdit(item)}
                    menuProps={{
                        items: [
                            {
                                key: 'edit',
                                iconProps: { iconName: 'Edit' },
                                text: 'Edit',
                                onClick: () => onEdit(item),
                            },
                            {
                                key: 'moveTo',
                                iconProps: { iconName: 'MoveToFolder' },
                                text: 'Move To',
                                subMenuProps: {
                                    items: moveToItems,

                                    onItemClick: (e, category) => {
                                        _moveActionToCategory(item, category?.key as string);
                                    },
                                },
                            },
                            {
                                key: 'delete',
                                iconProps: { iconName: 'Delete' },
                                text: 'Delete',
                                onClick: _deleteQuickAction,
                            },
                        ],
                    }}
                    title={`${item.displayName}`}
                />
            ),
        },
    ];

    return (
        <ShimmeredDetailsList
            enableShimmer={_loading === LoadingStatus.Pending}
            selection={quickActionsSelection.current}
            onItemInvoked={onEdit}
            columns={columns}
            items={filteredQuickActionItems}
            dragDropEvents={dragDropEvents}
            selectionPreservedOnEmptyClick={true}
        />
    );
}
