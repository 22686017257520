import { MessageBar, Stack, Text } from '@fluentui/react';
import { Section } from 'components';
import PanelSectionHeader from 'pages/components/PanelSectionHeader';
import { selectGroupedWorkLists } from 'state/slices/admin-huddle/admin-huddle.selectors';
import { WorkListCategoryCard } from './WorkListCategoryCard';
import { useParams } from 'react-router-dom';
import { RouteParams } from 'interfaces/route-params';
import { useSelector } from 'hooks';
import DashboardLastUpdated from './DashboardLastUpdated';

export function AdminDashboard(): JSX.Element | null {
    const { tenantId } = useParams<RouteParams>();
    const groupedWorkLists = useSelector((state) => selectGroupedWorkLists(state, tenantId));
    if (!groupedWorkLists) return null;
    const dashboardCards = groupedWorkLists
        ?.map((category) => {
            if (!category.workLists.length) return null;
            const categoryCards = category.workLists.map((workList) => <WorkListCategoryCard key={workList.id} {...workList} />);
            return (
                <Stack key={category.id}>
                    <PanelSectionHeader text={category.displayName} style={{ marginBottom: 10 }} />
                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                        {categoryCards}
                    </Stack>
                </Stack>
            );
        })
        .filter((card) => card !== null);

    return (
        <Stack style={{ padding: 10 }}>
            {/* <Section>
                <Text variant="mediumPlus">KPIs go here...</Text>
            </Section> */}
            <Stack tokens={{ childrenGap: 10 }} style={{ flex: 1 }}>
                <Section heading="Work Lists">
                    <DashboardLastUpdated />
                    <Stack style={{ padding: 10 }} tokens={{ childrenGap: 10 }}>
                        {dashboardCards.length ? (
                            dashboardCards
                        ) : (
                            <MessageBar>None of the work lists are currently available.</MessageBar>
                        )}
                    </Stack>
                </Section>
                {/* <Section heading="My favorites" grow></Section> */}
            </Stack>
        </Stack>
    );
}
