import { AnyAction, createAsyncThunk, ThunkDispatch } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import { ChartConditionStatus, ChartProcedureStatus, IChartCondition } from 'api/models/chart.model';
import { ICondition } from 'api/models/lookup.model';
import { ToothArea } from 'api/models/tooth-area';
import axios from 'axios';
import { map } from 'lodash';
import { AppThunk, RootState } from 'state/store';
import { deselectTeeth } from '../chart/chart.slice';
import { IChartAction } from '../chartActionsList.pipeline';
import ChartingConditionsPipeline from '../chartingConditions.pipeline';
import ConditionConflictRulesPipeline from '../conditionConflictRules.pipeline';

export const getChartConditions = createAsyncThunk<
    IChartCondition[],
    {
        tenantId: string;
        patientId: string;
    }
>('getChartConditions', async ({ tenantId, patientId }) => {
    const result = await dentalApi.getChartConditions(tenantId, patientId);
    return result.data;
});

export const getChartConditionById = createAsyncThunk<
    IChartCondition,
    {
        tenantId: string;
        patientId: string;
        conditionId: string;
    }
>('getChartConditionById', async ({ tenantId, patientId, conditionId }) => {
    const result = await dentalApi.getChartConditionById(tenantId, patientId, conditionId);
    return result.data;
});

export const createChartCondition = createAsyncThunk<
    IChartCondition,
    {
        tenantId: string;
        patientId: string;
        condition: IChartCondition;
    }
>('createChartCondition', async ({ tenantId, patientId, condition }, { dispatch }) => {
    dispatch(deselectTeeth());
    const result = await dentalApi.createChartCondition(tenantId, patientId, condition);
    return result.data;
});

export const createChartConditions = createAsyncThunk<
    IChartCondition[],
    {
        tenantId: string;
        patientId: string;
        conditions: IChartCondition[];
    }
>('createChartConditions', async ({ tenantId, patientId, conditions }, { dispatch }) => {
    dispatch(deselectTeeth());
    const createResponses = conditions.map((c) => dentalApi.createChartCondition(tenantId, patientId, c));
    const results = await axios.all(createResponses);
    return results.map((r) => r.data);
});

export const updateChartCondition = createAsyncThunk<
    IChartCondition,
    {
        tenantId: string;
        patientId: string;
        condition: IChartCondition;
    }
>('updateChartCondition', async ({ tenantId, patientId, condition }) => {
    const result = await dentalApi.updateChartCondition(tenantId, patientId, condition);
    return result.data;
});

export const updateChartConditions = createAsyncThunk<
    IChartCondition[],
    {
        tenantId: string;
        patientId: string;
        conditions: IChartCondition[];
    }
>('updateChartConditions', async ({ tenantId, patientId, conditions }) => {
    const createResponses = conditions.map((c) => dentalApi.updateChartCondition(tenantId, patientId, c));
    const result = await axios.all(createResponses);
    return result.map((r) => r.data);
});

export const updateConditionStatuses =
    (
        tenantId: string,
        patientId: string,
        chartActions: IChartAction | IChartAction[],
        status: ChartConditionStatus,
    ): AppThunk<void> =>
    (dispatch, getState): void => {
        const chartConditions = getState().charting.conditions.data;
        if (chartActions) {
            if (Array.isArray(chartActions)) {
                const chartConditionsFromActions = chartActions
                    .map((chartAction) => chartConditions.find((chartCond) => chartCond.id === chartAction.id))
                    .filter((chartProcs) => chartProcs !== undefined) as IChartCondition[];

                if (chartConditionsFromActions && chartConditionsFromActions.length) {
                    const conditions: IChartCondition[] = chartConditionsFromActions.map((p) => ({
                        ...p,
                        status,
                    }));
                    dispatch(updateChartConditions({ tenantId, patientId, conditions }));
                }
            } else {
                const chartProcedure = chartConditions.find((proc) => proc.id === chartActions.id);
                if (chartProcedure) {
                    const conditions = [{ ...chartProcedure, status }];
                    dispatch(updateChartConditions({ tenantId, patientId, conditions }));
                }
            }
        }
    };

export const deleteConditions =
    (tenantId: string, patientId: string, conditons: IChartCondition[]) =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>): Promise<void> => {
        const updatedConditions = conditons.map((c) => ({ ...c, isDeleted: true, status: ChartConditionStatus.Removed }));
        dispatch(updateChartConditions({ tenantId, patientId, conditions: updatedConditions }));
    };

export const addConditions =
    (tenantId: string, patientId: string, conditions: IChartCondition[]) =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>): Promise<void> => {
        dispatch(createChartConditions({ tenantId, patientId, conditions }));
        dispatch(deselectTeeth());
    };

export const addConditionByIds =
    (
        tenantId: string,
        patientId: string,
        conditionIds: string[],
        encounterId?: string,
        surfaces?: (keyof typeof ToothArea)[],
    ): AppThunk<void> =>
    async (dispatch, getState): Promise<void> => {
        const chartConditionsAsList = getState().charting.conditions.data.filter((item) => !item.isDeleted);
        const conditionLookup = getState().tenant.conditions.data;
        const conditionsAsList = map(conditionLookup, (c) => c) as ICondition[];
        const selectedTeeth = getState().charting.ui.selectedTeeth;
        const newConditions = conditionLookup
            ? (conditionIds.map((id) => conditionLookup[id]).filter((condition) => condition !== undefined) as ICondition[])
            : [];

        new ChartingConditionsPipeline(newConditions, selectedTeeth, surfaces, undefined, encounterId).next((conditions) => {
            new ConditionConflictRulesPipeline({
                chartConditions: conditions,
                currentConditions: chartConditionsAsList,
                conditions: conditionsAsList,
                selectedTeeth,
            }).next((conditions) => {
                if (conditions.length) dispatch(addConditions(tenantId, patientId, conditions));
            });
        });
    };
