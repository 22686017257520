import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { getHealthHistoryProblems } from './problems.actions';
import { ProblemsState } from './problems.state';

export const problemsExtraReducers = (builder: ActionReducerMapBuilder<ProblemsState>): ActionReducerMapBuilder<ProblemsState> =>
    builder
        .addCase(getHealthHistoryProblems.pending, (state) => {
            state.loadingHealthHistoryProblems = LoadingStatus.Pending;
        })
        .addCase(getHealthHistoryProblems.fulfilled, (state, action) => {
            state.healthHistoryProblemsData = action.payload;
            state.loadingHealthHistoryProblems = LoadingStatus.Completed;
        })
        .addCase(getHealthHistoryProblems.rejected, (state) => {
            state.loadingHealthHistoryProblems = LoadingStatus.Failed;
        });
