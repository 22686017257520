import {
    ConstrainMode,
    DefaultButton,
    MessageBar,
    MessageBarType,
    SelectionMode,
    Shimmer,
    Stack,
    Text,
    useTheme,
} from '@fluentui/react';
import { SortableDetailsList } from 'components';
import { useSelector } from 'hooks';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
    UACAppliedProcedureCreditView,
    selectSignedTreatmentPlanIsLoading,
    selectSignedTreatmentPlanViewHasError,
    selectTreamentPlanCreditUnappliedPaymentsError,
    selectTreamentPlanCreditUnappliedPaymentsLoading,
    selectTreamentPlanCreditUnappliedPaymentsViews,
    selectTreatmentPlanCreditUnappliedPaymentsTotals,
} from 'state/slices/ledger/treatment-plan-credit-and-uac/treatment-plan-credit-and-uac.selectors';
import { usdCurrencyFormatter } from 'utils';
import { classicDateOnly } from 'utils/dateOnly';

export default function UnappliedCreditList() {
    const { palette } = useTheme();
    const { push } = useHistory();
    const { url } = useRouteMatch();

    const views = useSelector(selectTreamentPlanCreditUnappliedPaymentsViews);
    const loadingStatus = useSelector(selectTreamentPlanCreditUnappliedPaymentsLoading);
    const loadingRecentlySignedTp = useSelector(selectSignedTreatmentPlanIsLoading);
    const uacError = useSelector(selectTreamentPlanCreditUnappliedPaymentsError);
    const signedTpViewError = useSelector(selectSignedTreatmentPlanViewHasError);
    const uacTotal = useSelector(selectTreatmentPlanCreditUnappliedPaymentsTotals);

    const error = uacError || signedTpViewError;

    const loading = loadingStatus === LoadingStatus.Pending || loadingRecentlySignedTp;

    return (
        <Stack grow>
            <Stack
                styles={{
                    root: { minHeight: 44, backgroundColor: palette.neutralLighterAlt, paddingLeft: 10, paddingRight: 10 },
                }}
                verticalAlign="center"
                horizontal
                horizontalAlign="space-between"
            >
                <Text variant="mediumPlus">Patient Unapplied Credits (UACs)</Text>
                {loading ? (
                    <Shimmer width={130} />
                ) : (
                    <Text variant="mediumPlus">Total UACs: {usdCurrencyFormatter.format(uacTotal)}</Text>
                )}
            </Stack>

            <Stack styles={{ root: { overflowY: 'auto', maxHeight: 600 } }}>
                {!loading && error && (
                    <Stack.Item>
                        <MessageBar messageBarType={MessageBarType.error}>Something went wrong while fetching data.</MessageBar>
                    </Stack.Item>
                )}
                {!loading && !views.length && !error && (
                    <Stack.Item>
                        <MessageBar>No patient unapplied credits exist.</MessageBar>
                    </Stack.Item>
                )}
                {loading || (!loading && views.length && !error) ? (
                    <SortableDetailsList<UACAppliedProcedureCreditView>
                        selectionMode={SelectionMode.none}
                        constrainMode={ConstrainMode.unconstrained}
                        enableShimmer={loading}
                        items={views}
                        compact
                        showGrid
                        columns={[
                            {
                                key: 'distributeActionButton',
                                name: '',
                                minWidth: 100,
                                maxWidth: 120,
                                onRender: (item) => (
                                    <DefaultButton
                                        text="Distribute"
                                        onClick={() => {
                                            if (item) {
                                                push(`${url}/uac-distribution/${item.id}`);
                                            }
                                        }}
                                        styles={{ root: { padding: 0, paddingLeft: 5, paddingRight: 5, height: 24 } }}
                                    />
                                ),
                            },
                            {
                                key: 'paymentDate',
                                name: 'Payment Date',
                                minWidth: 100,
                                maxWidth: 120,
                                fieldName: 'paymentDate',
                                onRender: (item) => <span>{item?.paymentDate ? classicDateOnly(item.paymentDate) : ''}</span>,
                            },
                            {
                                key: 'amount',
                                name: 'Amount',
                                minWidth: 100,
                                maxWidth: 120,
                                fieldName: 'amount',
                                onRender: (item) => <span>{usdCurrencyFormatter.format(item?.amount ?? 0)}</span>,
                            },
                            {
                                key: 'method',
                                name: 'Method',
                                minWidth: 100,
                                maxWidth: 120,
                                fieldName: 'method',
                            },
                            {
                                key: 'identifier',
                                name: 'Identifier',
                                minWidth: 100,
                                maxWidth: 120,
                                fieldName: 'identifier',
                            },
                            {
                                key: 'note',
                                name: 'Note',
                                minWidth: 100,
                                fieldName: 'note',
                                onRender: (item) => <span title={item?.note ?? ''}>{item?.note ?? ''}</span>,
                            },
                        ]}
                    />
                ) : null}
            </Stack>
        </Stack>
    );
}
