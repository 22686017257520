import { Dictionary } from '@reduxjs/toolkit';
import { DentitionMode } from 'api/models/chart-dentition.model';
import { TEETH_REFS } from 'pages/Charting/components/ToothCanvas/spriteList';
import { LOWER_POSITIONS, UPPER_POSITIONS } from 'pages/Perio/perio-settings';
import { createSelector } from 'reselect';
import { RootState } from 'state/store';
import { chartProceduresData } from '../procedures/procedures.selectors';
import { selectProcedures } from 'state/slices/tenant/procedures.slice';
import { filter } from 'lodash';
import { extractionCodes } from '../procedureCodes';
import { ChartDentitionState } from './dentitions.state';
import { LoadingStatus } from 'interfaces/loading-statuses';

export const selectChartDentitions = (state: RootState): ChartDentitionState => state.charting.dentitions;
export const selectChartDentitionsSaving = (state: RootState): boolean =>
    state.charting.dentitions.savingDentitions === LoadingStatus.Pending;
export const chartDentitionsData = createSelector([selectChartDentitions], (state) => {
    return state.data;
});

function getToothDetails(position: number, isPrimary?: boolean): ToothReference {
    if (isPrimary) {
        const tooth = TEETH_REFS.primary.find((tooth) => tooth.position === position);

        return {
            id: tooth?.id,
            displayName: tooth?.displayName,
            position: position,
        };
    } else {
        return {
            id: position,
            displayName: `${position}`,
            position: position,
        };
    }
}

export type ToothReference = { id?: number; displayName?: string; position: number; missingOrExtracted?: boolean };
export type TeethByReference = { maxillary: Dictionary<ToothReference>; mandibular: Dictionary<ToothReference> };
export const getTeethReference = createSelector(
    [chartDentitionsData, chartProceduresData, selectProcedures],
    (dentition, chartProcedures, procedures) => {
        const result = filter(procedures, (p: any) => {
            return extractionCodes.includes(p.code);
        });
        const filteredChartProcedures =
            chartProcedures && chartProcedures.length
                ? chartProcedures.filter((chartProcedures) => {
                      if (chartProcedures.procedureId) {
                          return result.some((p) => chartProcedures.procedureId?.includes(p?.id ?? ''));
                      } else {
                          return false;
                      }
                  })
                : [];

        const extractedCompletedProcedures = filteredChartProcedures
            .filter((proc) => proc.status === 'Completed')
            .map((proc) => proc.toothIds)
            .flat() as number[];

        const teethByPosition: TeethByReference = { maxillary: {}, mandibular: {} };
        UPPER_POSITIONS.forEach((p) => mapTeethObjects(p));
        LOWER_POSITIONS.forEach((p) => mapTeethObjects(p, true));

        function mapTeethObjects(position: number, isMandibular?: boolean) {
            const dentitionOverride = dentition.find((ref) => ref.id === position.toString());

            const isPrimary =
                dentitionOverride?.dentitionMode === 'Primary' || dentitionOverride?.dentitionMode === 'PrimarySupernumerary';
            const tooth = getToothDetails(position, isPrimary);
            const maxilaryOrMandibular = isMandibular ? 'mandibular' : 'maxillary';
            const isExtracted =
                tooth && tooth.id && extractedCompletedProcedures && extractedCompletedProcedures.length
                    ? extractedCompletedProcedures.indexOf(tooth.id) > -1
                    : false;
            if (isExtracted) tooth['missingOrExtracted'] = isExtracted;
            // const isMissing = false; // !Based on condition, for now just removing this filter.

            teethByPosition[maxilaryOrMandibular][position] = tooth;
        }

        return teethByPosition;
    },
);

export type DentitionPosition = {
    position: number;
    dentitionMode: keyof typeof DentitionMode;
    displayName?: string;
    toothId?: number;
};
export const fullMouthDentition = createSelector([selectChartDentitions, getTeethReference], (state, references) => {
    const dentitionByPosition: Dictionary<DentitionPosition> = {};

    const combinedLookup: Dictionary<ToothReference> = { ...references.maxillary, ...references.mandibular };
    const positions = [...UPPER_POSITIONS, ...LOWER_POSITIONS];

    positions.forEach((position) => {
        const ref = combinedLookup[position];
        if (ref) {
            const currDentition = state.data.filter((d) => !d.isDeleted).find((d) => d.id === ref.id?.toString());
            const dentitionMode = currDentition?.dentitionMode ? currDentition.dentitionMode : 'Primary';
            dentitionByPosition[position] = { dentitionMode, position, displayName: ref.displayName, toothId: ref.id };
        } else {
            // dentitionByPosition[position] = { dentitionMode: 'Missing', position, displayName: 'Missing', toothId: position };
        }
    });

    return dentitionByPosition;
});
