import { IconButton, useTheme } from '@fluentui/react';
import { usePatientId, useTenantId } from 'hooks';
import usePermissions, { DentalPermissions } from 'hooks/store/usePermissions';
import { useHistory } from 'react-router-dom';

function SchedulingButton(): JSX.Element | null {
    const tenantId = useTenantId();
    const patientId = usePatientId();
    const { push } = useHistory();
    const theme = useTheme();

    const hasScheduling = usePermissions([DentalPermissions.Scheduling]) && tenantId;

    const noPatientPath = `/${tenantId}/scheduling`;
    const patientPath = `/${tenantId}/scheduling/${patientId}`;

    const urlPath = patientId ? patientPath : noPatientPath;
    const _handleSchedulingClick = (path: string) => {
        push(path);
    };
    if (!hasScheduling) {
        return null;
    }
    return (
        <IconButton
            styles={{
                root: {
                    color: '#FFF',
                },
                rootHovered: {
                    color: theme.palette.themePrimary,
                },
            }}
            iconProps={{ iconName: 'Calendar' }}
            style={{ maxWidth: 32, minWidth: 32 }}
            onClick={() => _handleSchedulingClick(urlPath)}
            title="Scheduling"
        />
    );
}

export default SchedulingButton;
