import IPatientAllergies, { IAllergy } from 'api/models/patient-allergy.model';
import IPatientImmunizations, { IImmunization } from 'api/models/patient-immunization.model';
import IPatientMedicationsView, { IPatientChartMedication } from 'api/models/patient-medication.model';
import IUserTask from 'api/models/user-task.model';
import { LoadingStatuses } from 'interfaces/loading-statuses';
import { IComboBoxOption, IGroup } from '@fluentui/react';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'state/store';
import { OverviewItem } from '../patient.state';
import {
    patientOverviewQuestionnaireTaskLookupByForm,
    patientOverviewQuestionnaireTasks,
    TaskType,
} from 'state/task-management/taskManagement.actions';
import { patientFormsAsList, patientProblemsFormValuesAsList } from 'state/slices/forms/forms.selectors';
import { intersectionBy, sortBy, uniqBy } from 'lodash';
import { isAfter, isBefore, isEqual } from 'date-fns';
import { IPatientProblemsQuestion } from 'forms/Problems/types';
import { IPatientProblem } from 'api/models/patient-problem.model';
import {
    selectActivePatientClinicalAlertsByPatientIdByType,
    selectCurrentClinicalAlertData,
} from 'state/slices/clinical-alert/clinical-alerts.selectors';

// Patient Problems
export const selectProblemsLoading = (state: RootState): LoadingStatuses => state.patient.problems.loading;
export const selectProblems = (state: RootState) => state.patient.problems;
export const selectProblemsData = createSelector(selectProblems, (problems) => problems.data);

export const selectProblemAsList = createSelector(selectProblems, (problems) => (problems.data?.problems ?? []).map(problem => ({
    ...problem,
    // Sort events by newest date first
    events: sortBy(problem.events, ['startDate'])
})));

function isPatientProblem(item: IPatientProblem | IPatientProblemsQuestion): item is IPatientProblem {
    return item && !!(item as IPatientProblem)?.code;
}

export const selectPatientProblemsPremedOptions = createSelector(
    selectProblemAsList,
    patientProblemsFormValuesAsList,
    selectActivePatientClinicalAlertsByPatientIdByType,
    selectCurrentClinicalAlertData,
    (existingProblems, patientReportProblems, activeAlerts, selectedData) => {
        const options: IComboBoxOption[] = uniqBy(
            sortBy(
                [...existingProblems, ...patientReportProblems].map((problem) => {
                    if (isPatientProblem(problem)) {
                        return {
                            key: problem.code ?? '',
                            text: problem.name ?? '',
                        };
                    } else {
                        return {
                            key: problem.snomedCode ?? '',
                            text: problem.displayName ?? '',
                        };
                    }
                }),
                'text',
            ),
            'key',
        ).filter((option) => {
            const indexOfAlertWithSnomed = activeAlerts.findIndex(
                (alert) => alert?.references?.snomedCode === option?.key && selectedData?.references?.snomedCode !== option?.key,
            );
            return indexOfAlertWithSnomed === -1;
        });

        options.unshift({ key: '', text: '(Select)' });

        return options;
    },
);
export const selectAllUniqueProblemsSnomedCodes = createSelector(
    selectProblemAsList,
    patientProblemsFormValuesAsList,
    (existingProblems, patientReportProblems) => {
        return Array.from(
            new Set([...existingProblems.map((p) => p.code ?? ''), ...patientReportProblems.map((p) => p.snomedCode ?? '')]),
        );
    },
);

// Patient Allergies
export const selectAllergiesLoading = (state: RootState): LoadingStatuses => state.patient.allergies.loading;
export const selectAllergies = (state: RootState): IPatientAllergies | undefined => state.patient.allergies.data;
export const selectAllergiesList = (state: RootState): IAllergy[] | undefined => state.patient.allergies.data?.allergies;

// Patient Medications
export const selectMedicationsLoading = (state: RootState): LoadingStatuses => state.patient.medications.loading;
export const selectMedications = (state: RootState): IPatientMedicationsView | undefined => state.patient.medications.data;
export const selectMedicationsList = (state: RootState): IPatientChartMedication[] | undefined =>
    state.patient.medications.data?.medications;
export const selectShowMedicationHistory = (state: RootState): boolean => state.patient.medications.showMedicationHistory;
/**
 * Determines if a patient chart medication is active.
 *
 * @export
 * @param {IPatientChartMedication} medication
 * @param {Date} dateToCheck
 * @return {*}
 */
export function isActivePatientMedication(medication: IPatientChartMedication, dateToCheck: Date) {
    if (medication.isDeleted) return false;
    if (!medication.stopDate) return true;

    const stopDate = new Date(medication.stopDate);
    return isAfter(stopDate, dateToCheck) && !isEqual(dateToCheck, stopDate);
}

export const selectFilteredMedicationsList = createSelector(
    selectMedicationsList,
    selectShowMedicationHistory,
    (medications, showHistory) => {
        const approvedMedications = medications?.filter((medication) => !!medication.enterDate);

        if (showHistory) return approvedMedications?.filter((medication) => !medication.isDeleted);

        return approvedMedications?.filter((medication) => isActivePatientMedication(medication, new Date()));
    },
);

// Patient Immunizations
export const selectImmunizationsLoading = (state: RootState): LoadingStatuses => state.patient.immunizations.loading;
export const selectImmunizationsSaving = (state: RootState): LoadingStatuses => state.patient.immunizations.saving;
export const selectImmunizations = (state: RootState): IPatientImmunizations | undefined => state.patient.immunizations.data;
export const selectImmunizationsList = (state: RootState): IImmunization[] | undefined =>
    state.patient.immunizations.data?.immunizations;

// Patient Overview
export const selectSelectedOverviewItem = (state: RootState): OverviewItem => state.patient.selectedOverviewItem;

// Tasks
export const selectPatientOverviewTasksState = (state: RootState): { data: IUserTask[]; loading: LoadingStatuses } =>
    state.patient.patientOverviewTasks;

export const selectPatientOverviewNonEncounterTasks = createSelector(selectPatientOverviewTasksState, (tasks) => {
    const isNotCreateEncounterTask = (task: IUserTask) => task.type !== TaskType.CreateEncounter;
    return [...tasks.data.filter(isNotCreateEncounterTask)];
});

export const selectPatientOverviewQuestionnaireTasks = createSelector(
    selectPatientOverviewNonEncounterTasks,
    patientFormsAsList,
    (tasks, forms) => {
        const formTasks = tasks.filter((task) => (task.type ? patientOverviewQuestionnaireTasks.includes(task.type) : false));
        const encounterFormTasks = formTasks.filter((task) => {
            if (!task.references?.encounterId) return true;
            const taskEncounterForms = forms.filter(
                (form) =>
                    form.encounterId === task.references?.encounterId &&
                    patientOverviewQuestionnaireTaskLookupByForm[form.formName] === task.type,
            );

            return !taskEncounterForms.length;
        });

        return encounterFormTasks;
    },
);

export const selectPatientOverviewTasks = createSelector(
    selectPatientOverviewQuestionnaireTasks,
    selectPatientOverviewNonEncounterTasks,
    (questionnaireTasks, overviewTasks) => {
        return intersectionBy(questionnaireTasks, overviewTasks, 'id');
    },
);

export const selectFilteredPAMMedicationsList = createSelector(
    selectMedicationsList,

    (medications) => {
        const tomorrowsDate = new Date().setDate(new Date().getDate() + 1);
        const approvedMedications = medications?.filter((medication) => !!medication.enterDate);

        return sortBy(
            approvedMedications?.filter(
                (medication) =>
                    (medication.stopDate ? (isBefore(new Date(medication.stopDate), tomorrowsDate) ? false : true) : true) &&
                    !medication.isDeleted,
            ),
            'medicationName',
        );
    },
);

export const selectPrescriptionsPAM = createSelector(
    [selectProblemAsList, selectAllergiesList, selectFilteredPAMMedicationsList, patientProblemsFormValuesAsList],
    (problems, allergies, medications, patientProblemForm): { items: any[]; groups: IGroup[] } => {
        const items: any[] = [];
        const group: IGroup[] = [];
        const existingProblems = problems?.map((item) => ({ key: item.problemId, name: item.name })) as any[];
        const patientReportedProblems = patientProblemForm?.map((item) => ({ key: item.name, name: item.name })) as any[];
        const patientProblems = existingProblems?.concat(patientReportedProblems);
        const problemItems =
            (problems && problems.length) || (patientProblemForm && patientProblemForm.length) ? patientProblems : [];
        const allergyItems =
            allergies && allergies?.length
                ? (allergies
                    ?.filter((item) => !item.isDeleted)
                    .map((item) => ({ key: item.allergenId, name: item.allergenName })) as any[])
                : [];
        const medicationItems =
            medications && medications?.length
                ? (medications?.map((item) => ({
                    key: item.medicationId,
                    name: item.medicationName,
                })) as any[])
                : [];

        if (problemItems) {
            items.push(...problemItems);
            const problemsStartingIndex = items.indexOf(problemItems[0]);
            group.push({
                key: 'problems',
                name: 'Problems',
                startIndex: problemsStartingIndex,
                count: problemItems?.length,
                isCollapsed: true,
            });
        }

        if (allergyItems && allergies?.length) {
            items.push(...allergyItems);
            const allergyStartingIndex = items.indexOf(allergyItems[0]);
            group.push({
                key: 'allergies',
                name: 'Allergies',
                startIndex: allergyStartingIndex,
                count: allergyItems?.length,
            });
        }

        if (medicationItems && medications?.length) {
            items.push(...medicationItems);
            const medicationsStartingIndex = items.indexOf(medicationItems[0]);
            group.push({
                key: 'medications',
                name: 'Active Medications',
                startIndex: medicationsStartingIndex,
                count: medicationItems?.length,
            });
        }
        if (items.length && group.length) {
            return { items, groups: group };
        } else {
            return {
                items: [],
                groups: [],
            };
        }
    },
);
