import { IStackTokens, Stack } from '@fluentui/react';
import ProviderTaskTiles from './components/ProviderTaskTiles';
import ProviderWeekSchedule from './components/ProviderWeekSchedule';
import { useEffect } from 'react';
import { cleanupPatientEncounter } from 'state/slices/encounter/encounter.slice';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { signalRGroupSubscriptionService } from 'hooks/signalr/useSignalr';
import { SignalRGroup } from 'hooks/signalr/signalRGroupSubscriptionService';
import { useTenantId } from 'hooks';

function ClinicalHuddle(): JSX.Element {
    const dispatch = useDispatch();
    const tenantId = useTenantId();
    const stackTokens: IStackTokens = {
        childrenGap: 10,
    };

    useEffect(() => {
        dispatch(cleanupPatientEncounter());

        return function() {
            signalRGroupSubscriptionService.unsubscribeFromGroups(tenantId, [SignalRGroup.Clinical]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Stack style={{ padding: 10, flex: 1, maxHeight: 'inherit', overflow: 'auto' }} tokens={stackTokens} horizontal wrap grow>
            <Helmet title="Clinical"></Helmet>
            <Stack.Item style={{ maxHeight: 'inherit', display: 'flex' }} grow>
                <ProviderWeekSchedule />
            </Stack.Item>
            <Stack style={{ height: 'auto' }} grow tokens={stackTokens} wrap>
                <ProviderTaskTiles />
            </Stack>
        </Stack>
    );
}

export default ClinicalHuddle;
