import { Stack } from '@fluentui/react';
import IPatient, { IPatientSlidingFee } from 'api/models/patient.model';
import { addMonths } from 'date-fns';
import { useTenant } from 'hooks';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectedEditPatientSlidingFeePlans, selectEditPatient } from 'state/slices/edit-patient/edit-patient.selectors';
import { editPatientPropChange, editPatientSlidingFeePropChange } from 'state/slices/edit-patient/edit-patient.slice';
import { isDateBetween } from 'utils/isDateBetween';
import { SlidingFeeItem } from './SlidingFeeItem';

export default function SlidingFee(): JSX.Element {
    const patient = useSelector(selectEditPatient);
    const { slidingFeeProgramsList } = useTenant();
    const patientSlidingFees = useSelector(selectedEditPatientSlidingFeePlans);
    const dispatch = useDispatch();

    const _onPropChange = (key: keyof IPatient, value: unknown, shouldNotChange?: boolean) => {
        dispatch(editPatientPropChange({ key, value, shouldNotChange }));
    };

    const isFirstRun = useRef(true);

    useEffect(() => {
        const isSlidingFee = patient?.slidingFees?.length;
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }
        if (patient && !isSlidingFee && patient.slidingFees?.[0].adjustmentType) {
            _onPropChange('slidingFees', []);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patient?.slidingFees]);

    const defaultSlidingFeeProgram = slidingFeeProgramsList?.find((program) => program?.isDefaultSlidingFeeProgram);

    useEffect(() => {
        if (patient && patient?.uds?.incomePercentage !== undefined && patient?.uds?.incomePercentage < 200) {
            const povertyPercentage = patient?.uds?.incomePercentage;

            const plan = defaultSlidingFeeProgram?.plans?.find((p) => {
                if (
                    p.povertyPercentageFrom !== undefined &&
                    p.povertyPercentageTo !== undefined &&
                    povertyPercentage !== undefined
                ) {
                    return (
                        povertyPercentage >= p.povertyPercentageFrom &&
                        povertyPercentage <= p.povertyPercentageTo &&
                        isDateBetween({
                            dateToCheck: new Date().toISOString(),
                            start: p.effectiveDate,
                            end: p.expirationDate,
                        })
                    );
                }
            });
            const today = new Date();
            const monthsToAdd = defaultSlidingFeeProgram?.monthsForUpdate;
            const addMonthsToExpirationDate = monthsToAdd ? addMonths(today, monthsToAdd) : undefined;
            const effectiveDate = patient?.slidingFees?.[0]?.effectiveDate ?? today.toISOString();
            const expirationDate = patient?.slidingFees?.[0]?.expirationDate
                ? patient?.slidingFees?.[0]?.expirationDate
                : addMonthsToExpirationDate?.toISOString();

            const newPlan: IPatientSlidingFee = {
                adjustmentType: plan?.adjustmentType,
                slidingFeeProgram: defaultSlidingFeeProgram?.name,
                slidingFeePlan: plan ? plan.name : 'There is no current active Sliding Fee Plan available for this % of Poverty.',

                effectiveDate: effectiveDate,
                expirationDate: expirationDate,
                isRyanWhite: false,
            };

            _onPropChange('slidingFees', [newPlan], true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patient?.uds?.incomePercentage]);

    const slidingFeeItems = patientSlidingFees?.length
        ? patientSlidingFees?.map((sf, index) => <SlidingFeeItem key={index} index={index} slidingFee={sf} />)
        : null;

    return (
        <>
            <Stack tokens={{ childrenGap: 10 }}>{slidingFeeItems}</Stack>
        </>
    );
}
