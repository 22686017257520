import { Text } from '@fluentui/react';

export const getIsLastIndex = (itemListLength: number, index?: number) => index === itemListLength - 1;

export const onRenderColumn = (
    data: string | number,
    itemListLength: number,
    index?: number,
    boldedColumns?: string[],
    columnKey?: string,
) => {
    const isBoldedColumn = boldedColumns && columnKey && boldedColumns.indexOf(columnKey) > -1;
    return (
        <Text
            style={{ fontWeight: getIsLastIndex(itemListLength, index) || isBoldedColumn ? 'bold' : undefined }}
            variant="smallPlus"
        >
            {data}
        </Text>
    );
};

export function getTransactionAmount({
    input,
    transactionAmount,
    patientFee,
    remainingPaymentAmount,
}: {
    input: number | undefined;
    transactionAmount: number | undefined;
    patientFee: number;
    remainingPaymentAmount: number;
}) {
    let amount: number;

    const localRemaining = Number(((transactionAmount ?? 0) + remainingPaymentAmount).toFixed(2));

    if (input !== undefined) {
        if (input <= localRemaining) {
            if (input > patientFee) {
                amount = patientFee;
            } else {
                amount = input;
            }
        } else {
            // input > localRemaining
            if (remainingPaymentAmount > patientFee) {
                amount = patientFee;
            } else {
                amount = localRemaining;
            }
        }
    } else {
        amount = 0;
    }
    return amount;
}

export function getMaxPayment({ overpayment, paymentAmount }: { overpayment?: boolean; paymentAmount?: number }) {
    if (overpayment) return undefined;
    if (paymentAmount !== undefined && paymentAmount < 0) {
        return 0;
    } else {
        return paymentAmount;
    }
}
