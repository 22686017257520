import { IChartProcedure } from 'api/models/chart.model';
import { createRule } from '../../ruleGenerator';
import ProcedureCodes from '../../procedureCodes';
import ProcedureBusinessRulesPipeline from '../procedureBusinessRules.pipeline';

const surfaceOnlyBusinessRule = createRule<ProcedureBusinessRulesPipeline, IChartProcedure>({
    ruleTypes: [
        ProcedureCodes.D1551,
        ProcedureCodes.D1552,

        ProcedureCodes.D1557,
        ProcedureCodes.D1558,

        ProcedureCodes.D5110,
        ProcedureCodes.D5120,

        ProcedureCodes.D5130,
        ProcedureCodes.D5140,

        // Partial Dentures disabled to prevent rewriting the code to something else
        // TODO: Figure out a way to create a separate group of codes for max/man at the same time
        
        // ProcedureCodes.D5211,
        // ProcedureCodes.D5212,

        // ProcedureCodes.D5213,
        // ProcedureCodes.D5214,

        // ProcedureCodes.D5221,
        // ProcedureCodes.D5222,

        // ProcedureCodes.D5223,
        // ProcedureCodes.D5224,

        // ProcedureCodes.D5225,
        // ProcedureCodes.D5226,

        // ProcedureCodes.D5720,
        // ProcedureCodes.D5721,

        // ProcedureCodes.D5740,
        // ProcedureCodes.D5741,

        // ProcedureCodes.D5760,
        // ProcedureCodes.D5761,

        // ProcedureCodes.D5820,
        // ProcedureCodes.D5821,
        
        // ProcedureCodes.D5864,
        // ProcedureCodes.D5866,

        ProcedureCodes.D5410,
        ProcedureCodes.D5411,

        ProcedureCodes.D5421,
        ProcedureCodes.D5422,

        ProcedureCodes.D5670,
        ProcedureCodes.D5671,

        ProcedureCodes.D5710,
        ProcedureCodes.D5711,

        ProcedureCodes.D5730,
        ProcedureCodes.D5731,

        ProcedureCodes.D5750,
        ProcedureCodes.D5751,

        ProcedureCodes.D5810,
        ProcedureCodes.D5811,

        ProcedureCodes.D5850,
        ProcedureCodes.D5851,

        ProcedureCodes.D5863,
        ProcedureCodes.D5865,

        ProcedureCodes.D5995,
        ProcedureCodes.D5996,

        ProcedureCodes.D6110,
        ProcedureCodes.D6111,

        ProcedureCodes.D6112,
        ProcedureCodes.D6113,

        ProcedureCodes.D6114,
        ProcedureCodes.D6115,

        ProcedureCodes.D6116,
        ProcedureCodes.D6117,

        ProcedureCodes.D7472,
        ProcedureCodes.D7473,

        ProcedureCodes.D7560,

        ProcedureCodes.D7610,
        ProcedureCodes.D7630,

        ProcedureCodes.D7620,
        ProcedureCodes.D7640,

        ProcedureCodes.D7710,
        ProcedureCodes.D7730,

        ProcedureCodes.D7720,
        ProcedureCodes.D7740,

        ProcedureCodes.D8696,
        ProcedureCodes.D8697,

        ProcedureCodes.D8698,
        ProcedureCodes.D8699,

        ProcedureCodes.D8701,
        ProcedureCodes.D8702,

        ProcedureCodes.D8703,
        ProcedureCodes.D8704,

        ProcedureCodes.D9932,
        ProcedureCodes.D9933,

        ProcedureCodes.D9934,
        ProcedureCodes.D9935,
    ],
    rule: (pipeline, item) => {
        const procedure = pipeline.getValidArchProcedureForArch(item);
        if (procedure) return { updatedItems: procedure };
        return { updatedItems: item };
    },
});


export default surfaceOnlyBusinessRule;
