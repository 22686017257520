import IWorkListEncounterView from 'api/models/worklist-encounter-view';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import { classicDateOnly } from 'utils/dateOnly';

const lastUpdated: ISortableColumn<IWorkListEncounterView> = {
    key: 'lastUpdated',
    name: 'Last Updated',
    fieldName: 'encounter.statusChangeDate',
    minWidth: 75,
    maxWidth: 75,
    isResizable: true,
    onRender: (item) => {
        if (item)
            return <span>{item.encounter?.statusChangeDate ? classicDateOnly(item.encounter.statusChangeDate) : 'N/A'}</span>;
    },
};

export default lastUpdated;
