import { IDropdownOption } from '@fluentui/react';
import { createAsyncThunk, createSelector, createSlice, Dictionary } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import { IMedicationStopReason } from 'api/models/patient-medication.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { LookupState } from 'interfaces/lookup-state';
import { RootState } from 'state/store';

type MedicationStopReasonsState = LookupState<IMedicationStopReason>;

const initialState: MedicationStopReasonsState = {
    initialLoad: LoadingStatus.Idle,
    loading: LoadingStatus.Idle,
    data: {},
};

export const getMedicationsStopReasons = createAsyncThunk<
    Dictionary<IMedicationStopReason>,
    void,
    {
        rejectValue: string;
    }
>('getMedicationsStopReasons', async () => {
    const res = await dentalApi.getMedicationStopReasons();
    return res.data;
});

const medicationStopReasonsSlice = createSlice({
    name: 'medicationStopReasons',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getMedicationsStopReasons.pending, (state) => {
                state.initialLoad = LoadingStatus.Pending;
            })
            .addCase(getMedicationsStopReasons.fulfilled, (state, action) => {
                state.initialLoad = LoadingStatus.Completed;
                state.data = action.payload;
            })
            .addCase(getMedicationsStopReasons.rejected, (state, action) => {
                state.initialLoad = LoadingStatus.Failed;
                state.errors = action.payload;
            });
    },
});

const { reducer } = medicationStopReasonsSlice;

export const medicationStopReasonsObject = (state: RootState): Dictionary<IMedicationStopReason> =>
    state.tenant.medicationStopReasons.data;

export const selectMedicationStopReasons = createSelector(medicationStopReasonsObject, (data) => (data ? data : {}));

export const medicationStopReasonsList = createSelector(medicationStopReasonsObject, (reason) => {
    if (reason) {
        const list = Object.keys(reason).map((key) => reason[key]);
        return list.filter((reason) => !reason?.isDeleted);
    }
    return [];
});

export const medicationStopReasonsOption = createSelector(medicationStopReasonsList, (stopReasons) => {
    if (stopReasons) {
        return stopReasons.filter((p) => !p?.isDeleted).map((p) => ({ key: p?.id, text: p?.displayName } as IDropdownOption));
    }
    return [];
});

export default reducer;
