import { createSlice } from '@reduxjs/toolkit';
import { reducers, extraReducers, getLookups, createLookup, updateLookup } from './lookups.reducers';
import { LookupsState } from './lookupsStateTypes';

const name = 'lookups';

const initialState: LookupsState = {
    tenants: {},
};

const { actions, reducer } = createSlice({ name, initialState, reducers, extraReducers });
export const { setSelectedLookup, updateLookupItem, setSelectedLookupItemProp } = actions;
export { getLookups, createLookup, updateLookup };

export default reducer;
