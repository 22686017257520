import * as PIXI from 'pixi.js-legacy';
import PixiText from './PixiText';
import Tooth from './Tooth';
import ToothOverlay from './ToothOverlay';

export default class Drifted extends ToothOverlay {
    private _toothWidth: number;

    constructor(tooth: Tooth) {
        super(tooth);
        this._toothWidth = tooth.getToothWidth;
        this._createDrifted();
    }

    private getDriftedDirection(value?: number): 'Mesial' | 'Distal' | '' {
        if (value) {
            const isNegative = value < 0;
            if (!this.tooth.getIsLeftMouth) {
                return isNegative ? 'Mesial' : 'Distal';
            } else {
                return !isNegative ? 'Mesial' : 'Distal';
            }
        }
        return '';
    }

    private _createRightArrow(thickness: number, value: number) {
        const y = 60;
        const x = this.tooth._positionTextContainer.width / 2 + this.tooth._positionTextContainer.x - 15;

        const text = new PixiText(`${value}`, { fontSize: 26 });
        const length = 15 * value.toString().length;

        text.position.set(x + length, y - text.height / 2);

        const line2 = new PIXI.Graphics();
        line2.position.set(text.x + 25, y);
        line2.lineStyle(thickness, 0x000000).lineTo(0, 14).rotation = Math.PI / 4;

        const line3 = new PIXI.Graphics();
        line3.position.set(text.x + 25, y);
        line3.lineStyle(thickness, 0x000000).lineTo(0, 14).rotation = (-Math.PI / 4) * 5;

        const container = new PIXI.Container();
        container.addChild(line2, line3);

        this.addChild(container, text);
    }

    private _createLeftArrow(thickness: number, value: number) {
        const y = 60;
        const x = this.tooth._positionTextContainer.x - this.tooth._positionTextContainer.width / 2;

        const text = new PixiText(`${value}`, { fontSize: 26 });
        const length = 15 * value.toString().length;

        text.position.set(x - length, y - text.height / 2);

        const line2 = new PIXI.Graphics();
        line2.position.set(text.x - 12, y);
        line2.lineStyle(thickness, 0x000000).lineTo(0, 14).rotation = -Math.PI / 4;

        const line3 = new PIXI.Graphics();
        line3.position.set(text.x - 12, y);
        line3.lineStyle(thickness, 0x000000).lineTo(0, 14).rotation = (Math.PI / 4) * 5;

        const container = new PIXI.Container();
        container.addChild(line2, line3);

        this.addChild(container, text);
    }

    private _createDrifted() {
        const value = this.tooth.data.x ? Math.abs(this.tooth.data.x) : 0;
        if (value) {
            const mesialDistal = this.getDriftedDirection(this.tooth.data.x);

            if (mesialDistal === 'Mesial' && this.tooth.getIsLeftMouth) this._createRightArrow(3, value);
            if (mesialDistal === 'Mesial' && !this.tooth.getIsLeftMouth) this._createLeftArrow(3, value);
            if (mesialDistal === 'Distal' && !this.tooth.getIsLeftMouth) this._createRightArrow(3, value);
            if (mesialDistal === 'Distal' && this.tooth.getIsLeftMouth) this._createLeftArrow(3, value);
        }
    }
}
