import { Dictionary } from '@reduxjs/toolkit';
import { ChangeAuditSupport, DisplayNameSupport, ETagSupport, IdSupport, SoftDeleteSupport } from 'models/contracts';

type AppointmentCancellationReaonSupports = IdSupport & ChangeAuditSupport & SoftDeleteSupport & ETagSupport & DisplayNameSupport;

export enum AppointmentCancellationDepartmentType {
    Dental = 'dental',
}

interface IAppointmentCancellationReason extends AppointmentCancellationReaonSupports {
    references?: Dictionary<string>;
    departmentType?: AppointmentCancellationDepartmentType;
}

export default IAppointmentCancellationReason;
