import { Stack, DefaultButton, Dropdown } from '@fluentui/react';
import { useSelector } from 'hooks';
import { every, find, findKey, flatten, forEach, range, sortBy, uniq } from 'lodash';
import { ToothButton } from 'pages/components';
import { LOWER_POSITIONS, UPPER_POSITIONS } from 'pages/Perio/perio-settings';
import { useEffect, useState } from 'react';
import { getTeethReference, ToothReference } from 'state/slices/charting/dentition/dentition.selectors';
import { PERM_SUPERNUM_TEETH_REFS, PRIM_SUPERNUM_TEETH_REFS, SpriteReference } from '../ToothCanvas/spriteList';

type Props = {
    selectedTeeth: number[];
    onChange?: (selectedTeeth: number[]) => void;
    multiselect?: boolean;
    canSelectSupernumerary?: boolean;
};

// enum ToothSelectorDentition = 'Chart' | 'Supernumerary' | 'PrimarySupernumerary';
enum ToothSelectorDentition {
    Chart = 'Chart',
    Supernumerary = 'Supernumerary',
    PrimarySupernumerary = 'PrimarySupernumerary',
}

enum Quad {
    UR,
    UL,
    LR,
    LL,
}

enum Arch {
    Maxillary = 'maxillary',
    Mandibular = 'mandibular',
}

const quadIds: { [key in Quad]: number[] } = {
    [Quad.UL]: [9, 10, 11, 12, 13, 14, 15, 16],
    [Quad.UR]: [1, 2, 3, 4, 5, 6, 7, 8],
    [Quad.LL]: [17, 18, 19, 20, 21, 22, 23, 24],
    [Quad.LR]: [25, 26, 27, 28, 29, 30, 31, 32],
};

const upperRightQuadIdsSuperPerm = range(53, 61);
const upperLeftQuadIdsSuperPerm = range(61, 69);
const lowerLeftQuadIdsSuperPerm = range(69, 77);
const lowerRightQuadIdsSuperPerm = range(77, 85);
const upperRightQuadIdsSuperPrim = range(85, 90);
const upperLeftQuadIdsSuperPrim = range(90, 95);
const lowerLeftQuadIdsSuperPrim = range(95, 100);
const lowerRightQuadIdsSuperPrim = range(100, 105);

function ToothSelector({ selectedTeeth, onChange, multiselect = true, canSelectSupernumerary }: Props): JSX.Element {
    //We only care about quads, and we should make the component controlled by the selectedTeeth prop
    //upper arch being selected would be when selectedQuads = [Quad.UR, QUAD.UL]

    useEffect(() => {
        if (onChange) onChange(selectedTeeth);
    }, []);

    const [dentition, setDentition] = useState<ToothSelectorDentition>(ToothSelectorDentition.Chart);
    const teethRef = useSelector(getTeethReference);

    const getQuadIds = (quad: Quad, arch: Arch) =>
        quadIds[quad]
            .map((position) => find(teethRef[arch], (ref) => ref?.position === position)?.id)
            .filter((id) => id !== undefined) as number[];

    const upperLeftQuadIds = getQuadIds(Quad.UL, Arch.Maxillary);
    const upperRightQuadIds = getQuadIds(Quad.UR, Arch.Maxillary);
    const lowerRightQuadIds = getQuadIds(Quad.LR, Arch.Mandibular);
    const lowerLeftQuadIds = getQuadIds(Quad.LL, Arch.Mandibular);

    const isSupernumerary = dentition === 'Supernumerary' || dentition === 'PrimarySupernumerary';
    const isPrimarySuperNumerary = dentition === 'PrimarySupernumerary';

    const teethByQuad: { [key in Quad]: number[] } = {
        [Quad.UR]: isSupernumerary
            ? isPrimarySuperNumerary
                ? upperRightQuadIdsSuperPrim
                : upperRightQuadIdsSuperPerm
            : upperRightQuadIds,
        [Quad.UL]: isSupernumerary
            ? isPrimarySuperNumerary
                ? upperLeftQuadIdsSuperPrim
                : upperLeftQuadIdsSuperPerm
            : upperLeftQuadIds,
        [Quad.LL]: isSupernumerary
            ? isPrimarySuperNumerary
                ? lowerLeftQuadIdsSuperPrim
                : lowerLeftQuadIdsSuperPerm
            : lowerLeftQuadIds,
        [Quad.LR]: isSupernumerary
            ? isPrimarySuperNumerary
                ? lowerRightQuadIdsSuperPrim
                : lowerRightQuadIdsSuperPerm
            : lowerRightQuadIds,
    };

    const teethByArch: { [key in Arch]: number[] } = {
        [Arch.Maxillary]: [...teethByQuad[Quad.UR], ...teethByQuad[Quad.UL]],
        [Arch.Mandibular]: [...teethByQuad[Quad.LR], ...teethByQuad[Quad.LL]],
    };

    function getInitialQuads(): Quad[] {
        const quadsToReturn: Quad[] = [];
        forEach(Quad, (q) => {
            const teethForQuad = teethByQuad[q];
            const everyToothInQuadExists = every(teethForQuad, (tooth) => selectedTeeth.includes(tooth));
            if (everyToothInQuadExists) quadsToReturn.push(q);
        });
        return quadsToReturn;
    }

    const [selectedQuads, setSelectedQuads] = useState<{ [key in ToothSelectorDentition]?: Quad[] }>({
        Chart: getInitialQuads(),
    });

    const renderToothButtons = ({
        positions,
        isSupernumerary,
        isPrimary,
        arch,
    }: {
        positions: number[];
        isSupernumerary: boolean;
        isPrimary?: boolean;
        arch?: Arch;
    }) => {
        return positions.map((position) => {
            const refs = isSupernumerary
                ? isPrimary
                    ? PRIM_SUPERNUM_TEETH_REFS
                    : PERM_SUPERNUM_TEETH_REFS
                : arch
                ? teethRef[arch][position]
                : [];
            const tooth = isSupernumerary
                ? (refs as SpriteReference[]).find((t) => t.position === position)
                : (refs as ToothReference);
            const isSelected = selectedTeeth.findIndex((t) => t === tooth?.id) > -1;

            return (
                <ToothButton
                    key={position}
                    toothId={tooth?.id}
                    displayName={tooth?.displayName}
                    isSelected={isSelected}
                    onClick={toggleTeeth}
                    missingOrExtracted={(tooth as ToothReference)?.missingOrExtracted}
                />
            );
        });
    };

    const quadsBySelectedDentition = selectedQuads[dentition] ?? [];

    const upperArchSelected = quadsBySelectedDentition.includes(Quad.UL) && quadsBySelectedDentition?.includes(Quad.UR);
    const lowerArchSelected = quadsBySelectedDentition?.includes(Quad.LL) && quadsBySelectedDentition?.includes(Quad.LR);

    const getQuadSelected = (quad: Quad) => selectedQuads[dentition]?.includes(quad);

    function updateQuadsForCurrentDentition(quads: Quad[]) {
        setSelectedQuads({
            ...selectedQuads,
            [dentition]: quads,
        });
    }

    function toggleTeeth(toothId: number) {
        if (onChange)
            if (multiselect) {
                const toothQuad = +(findKey(teethByQuad, (ids) => ids.includes(toothId)) as string) as Quad;
                if (selectedTeeth.indexOf(toothId) > -1) {
                    updateQuadsForCurrentDentition(quadsBySelectedDentition.filter((quad) => quad !== toothQuad));
                    onChange(selectedTeeth.filter((tooth) => tooth !== toothId));
                } else {
                    const newTeeth = [...selectedTeeth, toothId];
                    const teethForQuad = teethByQuad[toothQuad];
                    const quadSelected = every(teethForQuad, (tooth) => newTeeth.includes(tooth));
                    if (quadSelected) updateQuadsForCurrentDentition([toothQuad]);
                    onChange(newTeeth);
                }
            } else {
                onChange([toothId]);
            }
    }

    const toggleQuad = (quad: Quad) => {
        if (quadsBySelectedDentition.includes(quad)) {
            updateQuadsForCurrentDentition([...quadsBySelectedDentition.filter((q) => q !== quad)]);
        } else {
            updateQuadsForCurrentDentition([...quadsBySelectedDentition, quad]);
        }

        let selectedTeethFromQuads = [...selectedTeeth];
        if (!quadsBySelectedDentition.includes(quad)) {
            selectedTeethFromQuads.push(...teethByQuad[quad]);
        } else {
            selectedTeethFromQuads = selectedTeethFromQuads.filter((tooth) => !teethByQuad[quad].includes(tooth));
        }
        if (onChange) onChange(uniq(selectedTeethFromQuads));
    };

    const toggleArch = (arch: Arch) => {
        const quadsToSelect = arch === Arch.Maxillary ? [Quad.UR, Quad.UL] : [Quad.LR, Quad.LL];
        const archSelected = every(quadsToSelect, (quad) => quadsBySelectedDentition.includes(quad));

        let selectedTeethFromArches = [...selectedTeeth];
        if (!archSelected) {
            selectedTeethFromArches.push(...teethByArch[arch]);
            updateQuadsForCurrentDentition(uniq([...quadsBySelectedDentition, ...quadsToSelect]));
        } else {
            selectedTeethFromArches = selectedTeethFromArches.filter((tooth) => !teethByArch[arch].includes(tooth));
            updateQuadsForCurrentDentition(quadsBySelectedDentition.filter((q) => !quadsToSelect.includes(q)));
        }
        if (onChange) onChange(uniq(selectedTeethFromArches));
    };

    const deselectTeeth = () => {
        if (onChange) {
            onChange([]);
            setSelectedQuads({});
        }
    };

    return (
        <Stack tokens={{ childrenGap: 12 }} styles={{ root: { padding: 12 } }} horizontalAlign="center" grow>
            {canSelectSupernumerary && (
                <Dropdown
                    options={[
                        {
                            key: 'Chart',
                            text: 'Chart',
                        },
                        {
                            key: 'Supernumerary',
                            text: 'Supernumerary',
                        },
                        {
                            key: 'PrimarySupernumerary',
                            text: 'Primary Supernumerary',
                        },
                    ]}
                    styles={{ root: { minWidth: 155, maxWidth: 155 } }}
                    selectedKey={dentition}
                    onChange={(ev, option) => {
                        if (option) {
                            setDentition(option.key.toString() as ToothSelectorDentition);
                        }
                    }}
                />
            )}
            <Stack horizontalAlign="space-around" horizontal grow>
                {multiselect && (
                    <Stack tokens={{ childrenGap: 12 }} horizontal grow>
                        <DefaultButton text="UR" onClick={() => toggleQuad(Quad.UR)} primary={getQuadSelected(Quad.UR)} />
                        <DefaultButton text="UL" onClick={() => toggleQuad(Quad.UL)} primary={getQuadSelected(Quad.UL)} />
                    </Stack>
                )}
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 12 }}>
                {multiselect && (
                    <DefaultButton text="UA" onClick={() => toggleArch(Arch.Maxillary)} primary={upperArchSelected} />
                )}
                <Stack horizontal tokens={{ childrenGap: 2 }} verticalAlign="center">
                    {renderToothButtons({
                        isSupernumerary,
                        positions: UPPER_POSITIONS,
                        isPrimary: isPrimarySuperNumerary ?? undefined,
                        arch: Arch.Maxillary,
                    })}
                </Stack>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 12 }}>
                {multiselect && (
                    <DefaultButton text="LA" onClick={() => toggleArch(Arch.Mandibular)} primary={lowerArchSelected} />
                )}
                <Stack horizontal tokens={{ childrenGap: 2 }} verticalAlign="center">
                    {renderToothButtons({
                        isSupernumerary,
                        positions: LOWER_POSITIONS,
                        isPrimary: isPrimarySuperNumerary ?? undefined,
                        arch: Arch.Mandibular,
                    })}
                </Stack>
            </Stack>
            {multiselect && (
                <>
                    <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: 12 }}>
                        <DefaultButton text="LR" onClick={() => toggleQuad(Quad.LR)} primary={getQuadSelected(Quad.LR)} />
                        <DefaultButton text="LL" onClick={() => toggleQuad(Quad.LL)} primary={getQuadSelected(Quad.LL)} />
                    </Stack>
                    <Stack horizontal horizontalAlign="end">
                        <DefaultButton text="Deselect all" disabled={selectedTeeth.length === 0} onClick={deselectTeeth} />
                    </Stack>
                </>
            )}
        </Stack>
    );
}

export default ToothSelector;
