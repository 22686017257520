import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import {
    FinancialSummaryDateGroup,
    FinancialSummaryDateType,
    FinancialSummaryReportQuery,
} from 'api/models/financial-summary-report-query.model';
import { IValidationError } from 'hooks/useValidation';
import { LoadingStatus } from 'interfaces/loading-statuses';
import {
    getAdjustmentReasonsForReports,
    getAllLocationsOfCareForReports,
    getAllReports,
    buildQueryReportAction,
    getEncounterStatusOptions,
    getPatientTrackerOptions,
} from './reporting.actions';
import { DailyScheduleReportProperties, IProviderProductivityReportProperties, IReportingState } from './reporting.state';

export const reportingReducers = {
    setAppointmentsDetailsProp: (
        state: IReportingState,
        action: PayloadAction<{ path: keyof DailyScheduleReportProperties; value: string | number | undefined }>,
    ): void => {
        const { path, value } = action.payload;
        ((state.reportProperties as DailyScheduleReportProperties)[path] as string | number | undefined) = value;
    },
    toggleAppointmentId: (
        state: IReportingState,
        action: PayloadAction<{ path: keyof DailyScheduleReportProperties; id: string }>,
    ): void => {
        const { id, path } = action.payload;
        if (!(state.reportProperties as DailyScheduleReportProperties)[path])
            ((state.reportProperties as DailyScheduleReportProperties)[path] as unknown[]) = [];

        const currentIds = (state.reportProperties as DailyScheduleReportProperties)[path] as string[];

        if (currentIds)
            if (currentIds?.includes(id)) {
                ((state.reportProperties as DailyScheduleReportProperties)[path] as string[]) = currentIds.filter(
                    (_id) => _id !== id,
                );
            } else {
                ((state.reportProperties as DailyScheduleReportProperties)[path] as string[]) = [...currentIds, id];
            }
    },
    setFinanceSummaryStartDate: (state: IReportingState, action: PayloadAction<string | undefined>): void => {
        (state.reportProperties as FinancialSummaryReportQuery).startDate = action.payload;
    },
    setFinanceSummaryEndDate: (state: IReportingState, action: PayloadAction<string | undefined>): void => {
        (state.reportProperties as FinancialSummaryReportQuery).endDate = action.payload;
    },
    setFinanceSummaryDateType: (state: IReportingState, action: PayloadAction<FinancialSummaryDateType>): void => {
        (state.reportProperties as FinancialSummaryReportQuery).dateType = action.payload;
    },
    setFinanceSummaryDateGroup: (state: IReportingState, action: PayloadAction<FinancialSummaryDateGroup>): void => {
        (state.reportProperties as FinancialSummaryReportQuery).dateGroup = action.payload;
    },
    setFinanceSummaryGroupCol: (state: IReportingState, action: PayloadAction<string>): void => {
        (state.reportProperties as FinancialSummaryReportQuery).groupCol = action.payload;
    },

    toggleProceduresFinancialSummaryId: (
        state: IReportingState,
        action: PayloadAction<{ path: keyof FinancialSummaryReportQuery; id: string }>,
    ): void => {
        const { id, path } = action.payload;
        if (!(state.reportProperties as FinancialSummaryReportQuery)[path])
            ((state.reportProperties as FinancialSummaryReportQuery)[path] as unknown[]) = [];

        const currentIds = (state.reportProperties as FinancialSummaryReportQuery)[path] as string[];

        if (currentIds)
            if (currentIds?.includes(id)) {
                ((state.reportProperties as FinancialSummaryReportQuery)[path] as string[]) = currentIds.filter(
                    (_id) => _id !== id,
                );
            } else {
                ((state.reportProperties as FinancialSummaryReportQuery)[path] as string[]) = [...currentIds, id];
            }
    },

    setProviderProductivityDateRange: (
        state: IReportingState,
        action: PayloadAction<{ startDate?: string | undefined; endDate?: string | undefined }>,
    ): void => {
        const { endDate, startDate } = action.payload;
        if (startDate) (state.reportProperties as IProviderProductivityReportProperties).startDate = startDate;
        if (endDate) (state.reportProperties as IProviderProductivityReportProperties).endDate = endDate;
    },
    toggleProviderProductivityTreatingProvider: (state: IReportingState, action: PayloadAction<string>): void => {
        const treatingProviderId = action.payload;
        const treatingProviders = (state.reportProperties as IProviderProductivityReportProperties).treatingProvider ?? [];
        if (!treatingProviders.length) (state.reportProperties as IProviderProductivityReportProperties).treatingProvider = [];

        const indexOfProvider = (
            (state.reportProperties as IProviderProductivityReportProperties).treatingProvider as string[]
        ).indexOf(treatingProviderId);
        if (indexOfProvider > -1) {
            (state.reportProperties as IProviderProductivityReportProperties).treatingProvider = treatingProviders.filter(
                (providerId) => providerId !== treatingProviderId,
            );
        } else {
            (state.reportProperties as IProviderProductivityReportProperties).treatingProvider = [
                ...treatingProviders,
                treatingProviderId,
            ];
        }
    },
    cleanupProviderProductivityDates: (state: IReportingState): void => {
        (state.reportProperties as IProviderProductivityReportProperties).endDate = undefined;
        (state.reportProperties as IProviderProductivityReportProperties).startDate = undefined;
    },
    cleanupSelectedReport: (state: IReportingState): void => {
        state.selectedReport = undefined;
        state.loadingSelectedReport = LoadingStatus.Idle;
    },
    cleanupSelectedReportProperties: (state: IReportingState): void => {
        state.reportProperties = {};
    },
    setReportValidationErrors: (state: IReportingState, action: PayloadAction<IValidationError[]>): void => {
        state.reportValidationErrors = action.payload;
    },
};

export const reportingExtraReducers = (
    builder: ActionReducerMapBuilder<IReportingState>,
): ActionReducerMapBuilder<IReportingState> =>
    builder
        .addCase(getAllReports.pending, (state) => {
            state.loading = LoadingStatus.Pending;
        })
        .addCase(getAllReports.fulfilled, (state, action) => {
            state.loading = LoadingStatus.Completed;
            state.reports = action.payload;
        })
        .addCase(getAllReports.rejected, (state) => {
            state.loading = LoadingStatus.Failed;
        })
        .addCase(getAllLocationsOfCareForReports.pending, (state) => {
            if (!state.extraReportData) state.extraReportData = {};
            state.extraReportData.locationsOfCareLoading = LoadingStatus.Pending;
        })
        .addCase(getAllLocationsOfCareForReports.fulfilled, (state, action) => {
            if (state.extraReportData) {
                state.extraReportData.locationsOfCareLoading = LoadingStatus.Completed;
                state.extraReportData.locationsOfCare = action.payload;
            }
        })
        .addCase(getAllLocationsOfCareForReports.rejected, (state) => {
            if (state.extraReportData) {
                state.extraReportData.locationsOfCareLoading = LoadingStatus.Failed;
            }
        })
        .addCase(getAdjustmentReasonsForReports.pending, (state) => {
            if (!state.extraReportData) state.extraReportData = {};
            state.extraReportData.adjustmentReasonsLoading = LoadingStatus.Pending;
        })
        .addCase(getAdjustmentReasonsForReports.fulfilled, (state, action) => {
            if (state.extraReportData) {
                state.extraReportData.adjustmentReasonsLoading = LoadingStatus.Completed;
                state.extraReportData.adjustmentReasons = action.payload;
            }
        })
        .addCase(getAdjustmentReasonsForReports.rejected, (state) => {
            if (state.extraReportData) {
                state.extraReportData.adjustmentReasonsLoading = LoadingStatus.Failed;
            }
        })
        .addCase(buildQueryReportAction().pending, (state) => {
            state.loadingSelectedReport = LoadingStatus.Pending;
        })
        .addCase(buildQueryReportAction().fulfilled, (state, action) => {
            state.loadingSelectedReport = LoadingStatus.Completed;
            state.selectedReport = action.payload;
        })
        .addCase(buildQueryReportAction().rejected, (state) => {
            state.loadingSelectedReport = LoadingStatus.Failed;
        })

        .addCase(getEncounterStatusOptions.pending, (state) => {
            state.loadingEncounterStatus = LoadingStatus.Pending;
        })
        .addCase(getEncounterStatusOptions.fulfilled, (state, action) => {
            state.loadingEncounterStatus = LoadingStatus.Completed;
            state.encounterStatus = action.payload.data;
        })
        .addCase(getEncounterStatusOptions.rejected, (state, action) => {
            state.loadingEncounterStatus = LoadingStatus.Failed;
        })
        .addCase(getPatientTrackerOptions.pending, (state) => {
            state.loadingPatientTracker = LoadingStatus.Pending;
        })
        .addCase(getPatientTrackerOptions.fulfilled, (state, action) => {
            state.loadingPatientTracker = LoadingStatus.Completed;
            state.patientTracker = action.payload.data;
        })
        .addCase(getPatientTrackerOptions.rejected, (state, action) => {
            state.loadingPatientTracker = LoadingStatus.Failed;
        });
