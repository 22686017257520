import ConfirmationModal from 'components/ConfirmationModal';
import { RouteParams } from 'interfaces/route-params';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { voidEncounterWorkListNonClinicalProcedure } from 'state/slices/admin-huddle/worklists/encounter-worklists/encounter-worklists.actions';
import {
    selectEncounterWorkListVoidProcedurePatientId,
    selectEncounterWorkListVoidProcedureConfirmationIsOpen,
    selectEncounterWorkListVoidProcedureSaving,
    selectEncounterWorkListVoidProcedure,
} from 'state/slices/admin-huddle/worklists/encounter-worklists/encounter-worklists.selectors';
import { setBillingProcedureToVoid } from 'state/slices/admin-huddle/worklists/worklist.slice';

export function VoidProcedureConfirmation() {
    const dispatch = useDispatch();
    const { tenantId } = useParams<RouteParams>();

    const patientId = useSelector(selectEncounterWorkListVoidProcedurePatientId);
    const isVoidConfirmationOpen = useSelector(selectEncounterWorkListVoidProcedureConfirmationIsOpen);
    const savingVoidProcedure = useSelector(selectEncounterWorkListVoidProcedureSaving);
    const billingProcedure = useSelector(selectEncounterWorkListVoidProcedure);

    const handleVoidProcedure = () => {
        if (billingProcedure?.encounterId && patientId) {
            dispatch(
                voidEncounterWorkListNonClinicalProcedure({
                    tenantId,
                    patientId,
                    billingProcedureId: billingProcedure.id,
                    encounterId: billingProcedure.encounterId,
                }),
            );
        }
    };

    const onDismiss = () => {
        dispatch(setBillingProcedureToVoid(undefined));
    };

    return (
        <ConfirmationModal
            title="Void Procedure Confirmation"
            onDismiss={onDismiss}
            onContinue={handleVoidProcedure}
            saving={savingVoidProcedure}
            isOpen={isVoidConfirmationOpen}
            confirmationMessage="Are you sure you would like to void this procedure?"
        />
    );
}
