import { Stack, Text, IContextualMenuItem, Spinner } from '@fluentui/react';
import IPatient from 'api/models/patient.model';
import { ContentCard, TText } from 'components';
import { useEffect } from 'react';
import { useSelector } from 'hooks';
import { RouteParams } from 'interfaces/route-params';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import {
    cleanupPatientContacts,
    getAllPatientContacts,
    selectAllPatientContacts,
    selectContactsLoading,
} from 'state/slices/contacts/contacts.slice';
import formatPhoneNumber from 'utils/formatPhoneNumber';
import { LoadingStatus } from 'interfaces/loading-statuses';
import PanelSectionHeader from 'pages/components/PanelSectionHeader';

function ContactInfo(props: { patient: IPatient | undefined }): JSX.Element {
    const { patient } = props;
    const dispatch = useDispatch();
    const { tenantId } = useParams<RouteParams>();
    const allPatientContacts = useSelector(selectAllPatientContacts);
    const contactsLoading = useSelector(selectContactsLoading);

    const isLoading = contactsLoading === LoadingStatus.Pending;

    useEffect(() => {
        if (patient?.id && tenantId)
            dispatch(
                getAllPatientContacts({
                    tenantId,
                    patientId: patient.id,
                }),
            );
        return () => {
            dispatch(cleanupPatientContacts());
        };
    }, [patient, tenantId, dispatch]);

    const phoneCards =
        patient?.phoneNumbers &&
        patient?.phoneNumbers.length &&
        patient.phoneNumbers.map((item, index) => {
            const permissions = [];
            if (item.hasPermissionToText) permissions.push('Can Text');
            if (item.hasPermissionToCall) permissions.push('Can Leave Message');

            return (
                <ContentCard key={index} isActive={!item.isDeleted} title={''}>
                    <Stack grow verticalAlign="center">
                        <Text variant="mediumPlus" block>
                            {item.type}: {formatPhoneNumber(item.number)}
                        </Text>
                        <TText variant="small">{permissions.join(', ')}</TText>
                    </Stack>
                </ContentCard>
            );
        });

    const contactCards =
        allPatientContacts &&
        allPatientContacts.length &&
        allPatientContacts.map((item, index) => {
            const permissions = [];
            if (item.canText) permissions.push('Can Text');
            if (item.canLeaveMessage) permissions.push('Can Leave Message');
            if (item.canShareClinicalInformation) permissions.push('Can Share Clinical Information');
            // if (item.isEmergencyContact) permissions.push('Is Emergency Contact');
            // if (item.isGuarantor) permissions.push('Is Guarantor');
            // if (item.isCaregiver) permissions.push('Is Caregiver');
            // if (item.canEmail) permissions.push('Can Email');

            const cardMenuItems: IContextualMenuItem[] = [
                {
                    key: 'editItem',
                    text: 'Edit',
                },
                {
                    key: 'deactivateItem',
                    text: !item.isDeleted ? 'Deactivate' : 'Activate',
                },
            ];

            if (index !== 0 && !item.isDeleted)
                cardMenuItems.push({
                    key: 'updatePrimaryPhone',
                    text: 'Set as Primary',
                });

            return (
                <ContentCard key={index} isActive={!item.isDeleted} title={''}>
                    <Stack grow verticalAlign="center" styles={{ root: { maxWidth: 250 } }}>
                        <Text variant="mediumPlus" block>
                            {`${item.lastName}, ${item.firstName}`}
                        </Text>
                        {item.homePhoneNumber && (
                            <Text variant="small" block>
                                phone: {formatPhoneNumber(item.homePhoneNumber)}
                            </Text>
                        )}
                        {item.email && (
                            <Text variant="small" block>
                                email: {item.email}
                            </Text>
                        )}
                        {permissions && permissions.length && (
                            <TText variant="small">permissions: {permissions.join(', ')}</TText>
                        )}
                    </Stack>
                </ContentCard>
            );
        });
    const emailCards = () => {
        return (
            <Stack styles={{ root: { maxWidth: '60%' } }}>
                <ContentCard key={1} isActive={true} title={''}>
                    <Stack grow verticalAlign="center">
                        <Text variant="mediumPlus" block>
                            {patient?.emailAddress}
                        </Text>
                    </Stack>
                </ContentCard>
            </Stack>
        );
    };
    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <Stack title="Phone Numbers" tokens={{ childrenGap: 10 }}>
                <PanelSectionHeader text="Phone numbers" />
                <Stack horizontal wrap tokens={{ childrenGap: 10 }}>
                    {phoneCards}
                </Stack>
            </Stack>
            <Stack title="Contacts" tokens={{ childrenGap: 10 }}>
                <PanelSectionHeader text="Contacts" />
                <Stack horizontal wrap tokens={{ childrenGap: 10 }}>
                    {contactCards}
                    {isLoading && <Spinner labelPosition="right" label="Loading contacts..."></Spinner>}
                </Stack>
            </Stack>
            <Stack title="Email" tokens={{ childrenGap: 10 }}>
                <PanelSectionHeader text="Email" />
                {patient?.emailAddress ? <Stack.Item>{emailCards()}</Stack.Item> : null}
            </Stack>
        </Stack>
    );
}

export default ContactInfo;
