import {
    Calendar,
    Callout,
    ComboBox,
    DefaultButton,
    DefaultEffects,
    DirectionalHint,
    FocusTrapZone,
    IComboBoxOption,
    IconButton,
    IDropdownOption,
    mergeStyles,
    MessageBar,
    Persona,
    PersonaSize,
    PrimaryButton,
    ScrollablePane,
    SearchBox,
    SelectableOptionMenuItemType,
    Spinner,
    Stack,
    Text,
    Toggle,
    TooltipHost,
    useTheme,
} from '@fluentui/react';
import { Dictionary } from '@reduxjs/toolkit';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { EncounterStatus } from 'api/models/encounter.model';
import IPatientAppointment, {
    IAppointmentProcedure,
    IPatientAppointmentPatient,
} from 'api/models/Scheduling/patientAppointment.model';
import { Badge, Section, SubSection, TText } from 'components';
import Field from 'components/Field/Field';
import { push } from 'connected-react-router';
import {
    addDays,
    addWeeks,
    differenceInMinutes,
    endOfWeek,
    format,
    formatDuration,
    startOfDay,
    startOfWeek,
    subWeeks,
} from 'date-fns';
import { useSelector, useTenant } from 'hooks';
import useTrackerBackgroundColor, { useTrackerButtonColor } from 'hooks/useEventBackgroundColor';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { RouteParams } from 'interfaces/route-params';
import { map } from 'lodash';
import ExpandableSectionHeader from 'pages/components/ExpandableSectionHeader';
import { appointmentStatusOptions } from 'pages/Scheduling/components/AppointmentOverview/components/AppointmentInfo';
import { getTrackerStatusText, TrackerStatus } from 'pages/Scheduling/components/TrackerStatusDropdown';
import { ChangeEvent, CSSProperties, useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { getWeekOfAppointments, onStartEncounter } from 'state/slices/clinical-huddle/clinical-huddle.actions';
import {
    filteredWeekOfAppointmentsByLOC,
    selectExpandedLocIds,
    selectLocationOfCareArray,
    selectPatientSearch,
    selectTreatingProviders,
    selectWeekOfDate,
    selectWeekOfFilteredAppointments,
    selectWeekOfLoading,
    selectWeekOfProviderArray,
    showActiveOnlyAppointment,
} from 'state/slices/clinical-huddle/clinical-huddle.selectors';
import {
    setDate,
    setExpandedLocationOfCares,
    setLocationArray,
    setPatientSearch,
    setProviderArray,
    toggleActiveAppointment,
    toggleExpandedLocationOfCare,
} from 'state/slices/clinical-huddle/clinical-huddle.slice';
import { getLookups } from 'state/slices/lookups/lookups.reducers';
import { selectOperatoriesAsList } from 'state/slices/lookups/operatories/operatories.selectors';
import { createNewEncounterLoadingLookup, isAnyCreateNewEncounterLoadingPending } from 'state/slices/patient/patient.selectors';
import { selectLocationsOfCare } from 'state/slices/scheduling/scheduling.selectors';
import { getLocationsOfCare } from 'state/slices/scheduling/scheduling.slice';
import { calculateFullAge, classicDateOnly } from 'utils/dateOnly';
import getFullName from 'utils/getPatientFullName';
import { timeOptions } from 'utils/getTimeOptions';
import { useSignalR } from 'hooks/signalr/useSignalr';
import { clinicalHuddleSignalR } from 'hooks/signalr/signalr.config';
import appLocalStorage from 'utils/appLocalStorage';
import StaticClinicalAlertCallout from 'pages/components/ClinicalAlerts/StaticClinicalAlertCallout';
import IProvider from 'api/models/provider.model';
import { BadgeSize } from 'components/Badge';

const canceledStyle: React.CSSProperties = {
    fontStyle: 'italic',
};

function getWeekOfText(weekOfDate?: string) {
    const today = new Date(weekOfDate as string);

    const getStartOfWeek = (date: Date): Date | number => startOfWeek(new Date(date), { weekStartsOn: 0 });
    const getNextWeek = (date: Date): Date | number => endOfWeek(new Date(date), { weekStartsOn: 0 });

    const startingWeek = getStartOfWeek(today);
    const nextWeek = getNextWeek(new Date(startingWeek));

    const endingDay = format(nextWeek, 'd');
    const endingMonth = format(nextWeek, 'LLLL');
    const endingYear = format(nextWeek, 'yyyy');

    const startingDay = format(startingWeek, 'd');
    const startingMonth = format(startingWeek, 'LLLL');
    const startingYear = format(startingWeek, 'yyyy');

    return `${startingMonth} ${startingDay}${startingYear !== endingYear ? `, ${startingYear}` : ''} - ${endingMonth !== startingMonth ? `${endingMonth} ` : ''
        }${endingDay}, ${endingYear}`;
}

export default function ProviderWeekSchedule(): JSX.Element {
    const dispatch = useDispatch();
    const { tenantId } = useParams<RouteParams>();
    const weekOfDate = useSelector(selectWeekOfDate);
    const weekText = getWeekOfText(weekOfDate);

    const { registerSignalRConfig } = useSignalR();

    useEffect(() => {
        dispatch(getLookups({ tenantId, lookupType: 'Operatories' }));
        dispatch(getLocationsOfCare({ tenantId }));

        registerSignalRConfig(clinicalHuddleSignalR);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sectionClass = mergeStyles({
        minWidth: 720,
        display: 'flex',
    });

    return (
        <Section
            styleContent={{ height: '100%', display: 'flex', flexDirection: 'column' }}
            grow
            style={{ height: 'inherit', marginBottom: 0 }}
            className={sectionClass}
            heading=""
        >
            <SubSection title={`Week of ${weekText}`}>
                <Stack tokens={{ childrenGap: 10 }} grow>
                    <ProviderWeekScheduleToolBar />
                </Stack>
            </SubSection>
            <Stack style={{ position: 'relative', maxHeight: 'inherit' }} grow>
                <ScrollablePane scrollContainerFocus={true}>
                    <ProviderWeekScheduledAppointments />
                </ScrollablePane>
            </Stack>
        </Section>
    );
}

function ProviderWeekScheduleToolBar(): JSX.Element {
    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <Stack horizontal horizontalAlign="space-between">
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <ProviderSearch />
                    <LocationOfCareSearch />
                    <PatientSearch />
                </Stack>
                <CalenderButton />
            </Stack>

            <AppointmentDateSelector />
        </Stack>
    );
}

function PatientSearch() {
    const dispatch = useDispatch();
    const patientSearch = useSelector(selectPatientSearch);
    const onChange = (e?: ChangeEvent, value?: string) => {
        dispatch(setPatientSearch(value ?? ''));
    };
    return <SearchBox placeholder="Search Patient" value={patientSearch} onChange={onChange} />;
}

function LocationOfCareSearch(): JSX.Element {
    const dispatch = useDispatch();
    const allLocationsOfCare = useSelector(selectLocationsOfCare);
    const locationsOfCare = useSelector(selectLocationOfCareArray);
    const locationOfCareOptions: IComboBoxOption[] = allLocationsOfCare.map((res) => ({
        key: res.id,
        text: res.displayName,
    }));

    const handleMultiSelect = (locationId: string) => {
        const newLOC = locationsOfCare ?? [];
        const indexOfLOC = newLOC.indexOf(locationId);
        if (indexOfLOC > -1) {
            dispatch(setLocationArray([...newLOC.slice(0, indexOfLOC), ...newLOC.slice(indexOfLOC + 1)]));
        } else {
            dispatch(setLocationArray([...newLOC, locationId]));
        }
    };

    useEffect(() => {
        dispatch(setLocationArray(appLocalStorage.getClinicHuddleLocation));
    }, [dispatch]);

    return (
        <Stack.Item>
            <Field.SearchCombo
                onRenderOption={(props) => <TText>{props?.text}</TText>}
                multiSelect
                placeholder="All Locations"
                options={locationOfCareOptions}
                onChange={(e, option) => {
                    if (option) handleMultiSelect(option.key as string);
                }}
                selectedKey={locationsOfCare}
                useComboBoxAsMenuWidth
                optionName="Locations"
            ></Field.SearchCombo>
        </Stack.Item>
    );
}

function ProviderSearch(): JSX.Element {
    const allProviders = useSelector(selectTreatingProviders);
    const providers = useSelector(selectWeekOfProviderArray);

    const dispatch = useDispatch();

    //When component loads. Get and set local storage providers in state:
    useEffect(() => {
        dispatch(setProviderArray(appLocalStorage.getClinicHuddleProvider));
    }, [dispatch]);

    const handleMultiSelect = (providerId: string) => {
        const newProviders = providers ?? [];
        const indexOfProvider = newProviders.indexOf(providerId);
        if (indexOfProvider > -1) {
            dispatch(setProviderArray([...newProviders.slice(0, indexOfProvider), ...newProviders.slice(indexOfProvider + 1)]));
        } else {
            dispatch(setProviderArray([...newProviders, providerId]));
        }
    };

    const createProviderOptions = useCallback(({ optionsHeader, filterBy }: { optionsHeader: string, filterBy: (provider: IProvider) => boolean }) => {
        const options: IDropdownOption[] = allProviders
            .filter((p) => filterBy(p) && !p.isDeleted)
            .map((provider) => ({
                key: provider?.id ?? '',
                text: `${provider?.lastName} ${provider?.suffix ? `${provider?.suffix}` : ''}, ${provider?.firstName}`,
            }))
            .sort((a, b) => {
                const lastNameA = a.text.split(',')[0].trim();
                const lastNameB = b.text.split(',')[0].trim();
                return lastNameA.localeCompare(lastNameB);
            })

        if (options.length)
            options.unshift({
                key: optionsHeader,
                text: optionsHeader,
                itemType: SelectableOptionMenuItemType.Header,
            });
        return options
    }, [allProviders])

    const treatingProviderComboOptions: IComboBoxOption[] = useMemo(() =>
        createProviderOptions({ optionsHeader: "Treating Providers", filterBy: (provider: IProvider) => provider.isTreatingProvider })
        , [createProviderOptions]);

    const hygienistsComboOptions: IComboBoxOption[] = useMemo(() =>
        createProviderOptions({ optionsHeader: "Hygienists", filterBy: (provider: IProvider) => provider.isHygienist })
        , [createProviderOptions]);

    return (
        <Stack.Item>
            <ComboBox
                placeholder="All Providers"
                multiSelect={true}
                selectedKey={providers}
                options={[...treatingProviderComboOptions, ...hygienistsComboOptions]}
                onChange={(e, option) => {
                    if (option) handleMultiSelect(option.key as string);
                }}
                onRenderOption={(props) => (props?.itemType ? <span>{props.text}</span> : <TText>{props?.text}</TText>)}
            ></ComboBox>
        </Stack.Item>
    );
}

export function CalenderButton(): JSX.Element {
    const weekOfDate = useSelector(selectWeekOfDate);
    const [showCalendar, { toggle: toggleShowCalendar, setFalse: hideCalendar }] = useBoolean(false);
    const showActiveOnly = useSelector(showActiveOnlyAppointment);
    const calendarButtonElement = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();

    const onSelectedDate = useCallback(
        (date: Date) => {
            const selectDate = date.toISOString();
            dispatch(setDate(selectDate));
            hideCalendar();
        },
        [dispatch, hideCalendar],
    );

    const getTodayday = () => {
        const today = startOfDay(new Date());
        dispatch(setDate(today.toISOString()));
    };

    return (
        <>
            <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign="center">
                <Stack.Item>
                    <Toggle
                        checked={!showActiveOnly}
                        inlineLabel
                        label="Active Only"
                        onChange={() => {
                            dispatch(toggleActiveAppointment());
                        }}
                    />
                </Stack.Item>
                <TooltipHost content="Go to today" directionalHint={DirectionalHint.topCenter}>
                    <Stack.Item>
                        <IconButton
                            iconProps={{ iconName: 'GotoToday' }}
                            styles={{ icon: { fontSize: 30 } }}
                            onClick={getTodayday}
                        />
                    </Stack.Item>
                </TooltipHost>
                <Stack.Item>
                    <div ref={calendarButtonElement}>
                        <IconButton
                            text="Calendar"
                            iconProps={{ iconName: 'Calendar' }}
                            styles={{ icon: { fontSize: 30 } }}
                            onClick={toggleShowCalendar}
                        />
                    </div>
                </Stack.Item>
            </Stack>

            {showCalendar && (
                <Callout
                    isBeakVisible={false}
                    gapSpace={0}
                    doNotLayer={false}
                    target={calendarButtonElement}
                    directionalHint={DirectionalHint.topCenter}
                    onDismiss={hideCalendar}
                    setInitialFocus
                >
                    <FocusTrapZone isClickableOutsideFocusTrap>
                        <Calendar
                            onSelectDate={onSelectedDate}
                            onDismiss={hideCalendar}
                            isMonthPickerVisible={false}
                            value={new Date(weekOfDate ?? '')}
                            isDayPickerVisible
                            showGoToToday
                        />
                    </FocusTrapZone>
                </Callout>
            )}
        </>
    );
}

export function AppointmentDateSelector(): JSX.Element {
    const weekOfDate = useSelector(selectWeekOfDate);
    const { tenantId } = useParams<RouteParams>();

    const dispatch = useDispatch();

    useEffect(() => {
        if (weekOfDate) dispatch(getWeekOfAppointments({ tenantId }));
    }, [weekOfDate, dispatch, tenantId]);

    const today = new Date(weekOfDate as string);
    const _startOfWeek = startOfWeek(new Date(today), { weekStartsOn: 0 });
    const monday = addDays(_startOfWeek, 1);
    const mondayText = getDayText(1);
    const tuesday = addDays(_startOfWeek, 2);
    const tuesdayText = getDayText(2);
    const wednesday = addDays(_startOfWeek, 3);
    const wednesdayText = getDayText(3);
    const thursday = addDays(_startOfWeek, 4);
    const thursdayText = getDayText(4);
    const friday = addDays(_startOfWeek, 5);
    const fridayText = getDayText(5);
    const saturday = addDays(_startOfWeek, 6);
    const saturdayText = getDayText(6);
    const sunday = _startOfWeek;
    const sundayText = getDayText();

    const captText: CSSProperties = {
        textTransform: 'uppercase',
    };

    const onPreviousWeek = () => {
        const previousWeek = subWeeks(today, 1);
        dispatch(setDate(previousWeek.toISOString()));
    };
    const onNextWeek = () => {
        const nextWeek = addWeeks(today, 1);
        dispatch(setDate(nextWeek.toISOString()));
    };

    return (
        <Stack horizontal tokens={{ childrenGap: 5 }} horizontalAlign="center">
            <IconButton iconProps={{ iconName: 'Back' }} onClick={onPreviousWeek} />
            <DefaultButton
                style={captText}
                primary={sunday.toISOString() === weekOfDate}
                text={sundayText}
                onClick={() => dispatch(setDate(sunday.toISOString()))}
            />
            <DefaultButton
                style={captText}
                primary={monday.toISOString() === weekOfDate}
                text={mondayText}
                onClick={() => dispatch(setDate(monday.toISOString()))}
            />
            <DefaultButton
                style={captText}
                primary={tuesday.toISOString() === weekOfDate}
                text={tuesdayText}
                onClick={() => dispatch(setDate(tuesday.toISOString()))}
            />
            <DefaultButton
                style={captText}
                primary={wednesday.toISOString() === weekOfDate}
                text={wednesdayText}
                onClick={() => dispatch(setDate(wednesday.toISOString()))}
            />
            <DefaultButton
                style={captText}
                primary={thursday.toISOString() === weekOfDate}
                text={thursdayText}
                onClick={() => dispatch(setDate(thursday.toISOString()))}
            />
            <DefaultButton
                style={captText}
                primary={friday.toISOString() === weekOfDate}
                text={fridayText}
                onClick={() => dispatch(setDate(friday.toISOString()))}
            />
            <DefaultButton
                style={captText}
                primary={saturday.toISOString() === weekOfDate}
                text={saturdayText}
                onClick={() => dispatch(setDate(saturday.toISOString()))}
            />
            <IconButton iconProps={{ iconName: 'Forward' }} onClick={onNextWeek} />
        </Stack>
    );

    function getDayText(daysToAdd?: number) {
        if (daysToAdd) {
            return `${format(addDays(_startOfWeek, daysToAdd), 'E')} ${format(addDays(_startOfWeek, daysToAdd), 'MM/dd')}`;
        } else {
            return `${format(_startOfWeek, 'E')} ${format(_startOfWeek, 'MM/dd')}`;
        }
    }
}

function ProviderWeekScheduledAppointments(): JSX.Element {
    const locationArray = useSelector(selectLocationOfCareArray);
    const _selectExpandedLocIds = useSelector(selectExpandedLocIds);
    const locationsOfCare = useSelector(selectLocationsOfCare);
    const _loading = useSelector(selectWeekOfLoading);

    const _filteredWeekOfAppointmentsByLOC = useSelector(filteredWeekOfAppointmentsByLOC);

    const { tenantId } = useParams<RouteParams>();
    const dispatch = useDispatch();

    function isLocIncluded(locId: string) {
        if (_selectExpandedLocIds.includes(locId)) {
            return true;
        } else {
            return false;
        }
    }

    function getLocDisplayName(locId: string) {
        return locationsOfCare.find((loc) => loc.id === locId)?.displayName;
    }

    useEffect(() => {
        if (locationArray) {
            const existingLoc = map(_filteredWeekOfAppointmentsByLOC, (appointments, locId) => locId);
            const expandedLocIds = locationArray.filter((locId) => existingLoc.includes(locId));
            dispatch(setExpandedLocationOfCares(expandedLocIds));
        }
    }, [locationArray, dispatch]);

    /**
     *
     * {
     *  'idofLOC': [...appt => filtered by date time]
     *  'idofLOC': [...appt]
     *  'idofLOC': [...appt]
     *
     * [...loc ids that determine if a section is expanded...]
     *
     * initially we have to loop through all the locs that exist push the ids to the array above;
     *
     *
     * }
     */
    if (_loading) return <Spinner label="Loading appointments..." />;
    const _appointmentItems = map(_filteredWeekOfAppointmentsByLOC, (appointments, locId) => {
        return (
            //write code here for sectioning
            <Stack
                styles={{
                    root: { overflow: 'hidden' },
                }}
                key={locId}
            >
                <ExpandableSectionHeader
                    toggleExpanded={() => {
                        dispatch(toggleExpandedLocationOfCare(locId));
                    }}
                    title={getLocDisplayName(locId)}
                    isOpen={isLocIncluded(locId)}
                >
                    {appointments?.map((appointment) => (
                        <WeekOfAppointmentInfo
                            locationOfCareId={locId}
                            appointment={appointment}
                            key={appointment.id}
                            tenantId={tenantId}
                        />
                    ))}
                </ExpandableSectionHeader>
            </Stack>
        );
    });

    return (
        <div
            style={{
                overflow: 'auto',
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
            }}
        >
            {_appointmentItems?.length ? (
                <Stack tokens={{ childrenGap: 5 }}>{_appointmentItems}</Stack>
            ) : (
                <MessageBar>There are no appointments scheduled for the selected filters.</MessageBar>
            )}
        </div>
    );
}

// type WeekOfAppointmentInfoProps = {
//     patientAppointment: IPatientAppointment;
// };

function WeekOfAppointmentInfo({
    appointment,
    tenantId,
    locationOfCareId,
}: {
    appointment?: IPatientAppointment;
    tenantId: string;
    locationOfCareId: string;
}): JSX.Element {
    // current date
    // somehow get the week of +/- some days
    const theme = useTheme();
    const dispatch = useDispatch();
    const locationsOfCare = useSelector(selectLocationsOfCare);
    const providers = useSelector(selectTreatingProviders);
    const operatoriesList = useSelector((state) => selectOperatoriesAsList(state, tenantId));
    const operatory = operatoriesList?.find((op) => op.id === appointment?.operatoryId);
    function ProviderName(provider?: IProvider) {
        return provider ? `${provider?.lastName} ${provider?.suffix ? `${provider?.suffix}` : ''}, ${provider?.firstName}` : '';
    }

    const encounterStatusLookup: Dictionary<string> = {
        [EncounterStatus.Created]: 'In Progress',
        [EncounterStatus.ReadyForAttestation]: 'Ready For Attestation',
        [EncounterStatus.Attested]: 'Attested',
        [EncounterStatus.AmendRequired]: 'Amend Required',
        [EncounterStatus.Completed]: 'Completed',
        [EncounterStatus.Billed]: 'Completed',
        [EncounterStatus.ConversionImported]: 'Conversion Imported',
        [EncounterStatus.CorrectionAmend]: 'Correction Addendum',
        [EncounterStatus.CorrectionsNeeded]: 'Corrections Needed',
        [EncounterStatus.CorrectionsCompleted]: 'Corrections Completed',
        [EncounterStatus.ReBillOnHold]: 'Rebill on Hold',
        canceled: 'Canceled',
    };

    function DurationBadge({ appointment }: { appointment?: IPatientAppointment }) {
        const theme = useTheme();

        const start = timeOptions.find((option) => option.key === appointment?.startTime)?.text;
        const end = timeOptions.find((option) => option.key === appointment?.endTime)?.text;

        const startDateText = `${appointment?.date} ${appointment?.startTime}`;
        const endDateText = `${appointment?.date} ${appointment?.endTime}`;

        const startDate = new Date(startDateText);
        const endDate = new Date(endDateText);

        const startEndDifference = differenceInMinutes(endDate, startDate);
        const duration = formatDuration({ minutes: startEndDifference });

        const _createNewEncounterLoadingLookup = useSelector(createNewEncounterLoadingLookup);
        const _isAnyCreateNewEncounterLoadingPending = useSelector(isAnyCreateNewEncounterLoadingPending);

        const isLoadingCreateEncounter = _createNewEncounterLoadingLookup[appointment?.id ?? ''] === LoadingStatus.Pending;

        const _statusText = () => {
            if (appointment) {
                const { trackerStatusId, appointmentStatusId } = appointment;

                const trackerStatusText = getTrackerStatusText(trackerStatusId as TrackerStatus);
                const patientStatusText = appointmentStatusOptions.find((option) => option.key === appointmentStatusId)?.text;
                if (trackerStatusText || patientStatusText) {
                    return trackerStatusText ? trackerStatusText : patientStatusText;
                } else {
                    return '';
                }
            } else {
                return '';
            }
        };
        const isCanceled = appointment?.isDeleted;
        const _encounterReasonText = appointment?.encounterReason ? appointment?.encounterReason : '';
        const [button, color] = useTrackerButtonColor(isCanceled ? 'canceled' : appointment?.encounterStatus);

        const _styles = {
            root: {
                border: `1px solid ${button}`,
                width: 170,
                borderRadius: 5,
                background: theme.palette.white,
            },
        };

        const patientHasEncounter = appointment?.encounterId !== undefined;

        const checkAppointmentTrackerStatusForStartEncounter = () => {
            if (!appointment?.trackerStatusId) {
                return false;
            }
            if (
                appointment?.trackerStatusId === TrackerStatus.Arrived ||
                appointment?.trackerStatusId === TrackerStatus.CheckIn
            ) {
                return false;
            }
            return true;
        };

        const buttonId = useId();
        const negotiatedEncounterStatus = isCanceled ? 'canceled' : appointment?.encounterStatus;

        return (
            <TooltipHost
                delay={0}
                content={
                    isCanceled ? (
                        <Stack tokens={{ childrenGap: 10 }}>
                            <b key={'cancellation-title'}>Cancellation Reason:</b>
                            <span key={'cancellation-note'}>{appointment?.cancellationReason ?? 'N/A'} </span>
                            {appointment?.cancellationNote && (
                                <>
                                    <b key={'cancellation-title'}>Cancellation Note:</b>
                                    <span key={'cancellation-note'}>{appointment?.cancellationNote ?? 'N/A'} </span>
                                </>
                            )}
                        </Stack>
                    ) : undefined
                }
            >
                <Stack styles={_styles} verticalAlign="center">
                    <div style={{ padding: 5 }}>
                        <Text variant="mediumPlus">
                            {start} - {end}
                        </Text>
                        <Text variant="smallPlus" block>
                            <span style={{ fontWeight: 600 }}>{_statusText()}</span>
                        </Text>
                        <Text variant="smallPlus" block>
                            <span style={{ fontWeight: 600 }}>{_encounterReasonText}</span>
                        </Text>
                        <Text variant="small">{duration}</Text>
                    </div>
                    {!appointment?.encounterId &&
                        !negotiatedEncounterStatus &&
                        checkAppointmentTrackerStatusForStartEncounter() &&
                        !patientHasEncounter ? (
                        <PrimaryButton
                            id={buttonId}
                            style={{ maxHeight: 25 }}
                            text="Start Encounter"
                            onRenderText={(props) => {
                                return (
                                    <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign="center">
                                        <Text style={{ fontWeight: 600, color: 'white' }}>{props?.text ?? ''}</Text>
                                        {isLoadingCreateEncounter && <Spinner />}
                                    </Stack>
                                );
                            }}
                            disabled={patientHasEncounter || isLoadingCreateEncounter || _isAnyCreateNewEncounterLoadingPending}
                            onClick={(ev) => {
                                ev.stopPropagation();
                                if (patientId && appointment && !isLoadingCreateEncounter)
                                    dispatch(
                                        onStartEncounter({
                                            tenantId,
                                            patientId,
                                            appointmentId: appointment.id,
                                            locationOfCareId,
                                        }),
                                    );
                            }}
                        />
                    ) : negotiatedEncounterStatus ? (
                        <PrimaryButton
                            id={buttonId}
                            style={{
                                maxHeight: 25,
                                backgroundColor: button,
                                color: color,
                                border: button,
                                fontStyle: isCanceled ? 'italic' : undefined,
                            }}
                            disabled={encounterStatusLookup[negotiatedEncounterStatus] === 'Completed'}
                            text={
                                encounterStatusLookup[negotiatedEncounterStatus]
                                    ? encounterStatusLookup[negotiatedEncounterStatus]
                                    : 'In Progress'
                            }
                        />
                    ) : null}
                </Stack>
            </TooltipHost>
        );
    }

    const isCanceled = appointment?.isDeleted;

    const [background, highlight] = useTrackerBackgroundColor(isCanceled ? 'canceled' : appointment?.trackerStatusId);

    const patientId = appointment?.patientId;
    const encounterId = appointment?.encounterId;
    const getPatientPath = () => {
        const patientOverview = `/${tenantId}/patient/${patientId}`;
        const patientOverviewWithEncounter = `/${tenantId}/patient/${patientId}/encounter/${encounterId}`;

        if (patientId && encounterId) {
            if (appointment?.encounterStatus === 'ConversionImported') {
                return patientOverview;
            }
            if (encounterStatusLookup[appointment?.encounterStatus ?? ''] !== 'Completed') {
                return patientOverviewWithEncounter;
            } else {
                return patientOverview;
            }
        } else if (patientId) {
            return patientOverview;
        }
    };

    const _handleAppointmentClick = (e: React.MouseEvent<HTMLAnchorElement | HTMLElement | HTMLButtonElement>): void => {
        e.preventDefault();
        const path = getPatientPath();
        if (path) {
            dispatch(push(path));
        }
    };

    const textStyle = isCanceled ? canceledStyle : undefined;

    return (
        <Stack
            horizontal
            tokens={{ childrenGap: 10 }}
            styles={{
                root: {
                    border: '1px solid' + theme.palette.neutralQuaternaryAlt,
                    cursor: 'pointer',
                    borderRadius: 5,
                    paddingRight: 5,
                    paddingTop: 5,
                    paddingBottom: 5,
                    paddingLeft: 0,
                    background: background,
                    transition: 'background .15s, box-shadow .15s',
                    overflow: 'hidden',
                    '&:hover': { background: theme.palette.neutralLight, boxShadow: DefaultEffects.elevation8 },
                },
            }}
            grow
            onClick={_handleAppointmentClick}
        >
            <Stack>
                <div
                    style={{
                        background: highlight,
                        width: 10,
                        height: '100%',
                        borderTopRightRadius: 3,
                        borderBottomRightRadius: 3,
                    }}
                >
                    &nbsp;
                </div>
            </Stack>

            <Stack verticalAlign="center" style={{ width: '35%' }} horizontal>
                <PatientInfo patient={appointment?.patient} />
                <StaticClinicalAlertCallout alertTypes={appointment?.patient?.alerts} patientId={patientId ?? ''} iconSize={23} />
                <AppointmentCount patient={appointment?.patient} />
            </Stack>

            <Stack style={{ width: '33%', ...textStyle }} horizontal horizontalAlign="center">
                <DurationBadge appointment={appointment} />
            </Stack>
            <Stack.Item style={{ width: '31%', maxWidth: 250, ...textStyle }}>
                <ProcedureInfo procedures={appointment?.procedures} />
                <Text variant="small" block>
                    {appointment?.locationOfCareId
                        ? locationsOfCare.find((locId) => locId.id === appointment.locationOfCareId)?.displayName
                        : ''}
                </Text>
                <Text variant="small" block>
                    {operatory?.displayName ?? ''}
                </Text>
                <Text variant="small" block>
                    {appointment?.treatingProviderId
                        ? ProviderName(providers.find((provider) => provider?.id === appointment?.treatingProviderId))
                        : appointment?.hygienistId
                            ? ProviderName(providers.find((provider) => provider?.id === appointment?.hygienistId))
                            : ''}
                </Text>
                <Text variant="small" block>
                    {appointment?.notes ? appointment.notes : ''}
                </Text>
            </Stack.Item>
        </Stack>
    );

    function PatientInfo({ patient }: { patient?: IPatientAppointmentPatient }): JSX.Element | null {
        if (!patient) return <MessageBar>Error loading patient information.</MessageBar>;
        const age = patient.dateOfBirth ? calculateFullAge(patient.dateOfBirth) : 'N/A';
        const dob = patient.dateOfBirth ? classicDateOnly(patient.dateOfBirth) : 'N/A';
        const displayName = `${getFullName(patient)}`;
        const gender = `${patient.genderIdentity ? patient.genderIdentity : patient.sexAtBirthId}`;
        const textToDisplay = `${displayName} (${patient.mrn ? patient.mrn.substr(0, 10) : 'N/A'})`;
        return (
            <Persona
                text={`${displayName} (${patient.mrn ? patient.mrn.substr(0, 10) : 'N/A'})`}
                secondaryText={`${age} Years | ${gender} | DOB: ${dob}`}
                tertiaryText="hello"
                size={PersonaSize.size48}
                style={{ maxWidth: 276 }}
                onRenderPrimaryText={() => <Text style={{ whiteSpace: 'pre-wrap' }}>{textToDisplay}</Text>}
            // Taking out for now but will possibly be re added later on
            //presence={appointment?.trackerStatusId ? PersonaPresence.online : PersonaPresence.none}
            />
        );
    }

    function ProcedureInfo({ procedures: appointmentProcedures }: { procedures?: IAppointmentProcedure[] }) {
        const { procedures } = useTenant();
        const procedureItems = appointmentProcedures?.length
            ? appointmentProcedures.map((procedure) => {
                const currentProcedure =
                    procedures.data && procedure.procedureId ? procedures.data[procedure.procedureId] : null;
                if (currentProcedure) {
                    return currentProcedure.code;
                }
            })
            : [];

        return (
            <Stack horizontal tokens={{ childrenGap: 5 }}>
                {procedureItems.length ? (
                    <Text variant="small">{procedureItems.length ? procedureItems.join(', ') : 'N/A'}</Text>
                ) : (
                    <Text variant="small">No procedures</Text>
                )}
            </Stack>
        );
    }

    function AppointmentCount({ patient }: { patient: IPatientAppointmentPatient | undefined }) {
        const _filteredWeekOfAppointmentsByLOC = useSelector(selectWeekOfFilteredAppointments);
        const theme = useTheme();
        const patientAppointments = _filteredWeekOfAppointmentsByLOC.filter((appt) => appt.patient?.mrn === patient?.mrn);
        if (patientAppointments.length <= 1) {
            return null;
        }
        return (
            <TooltipHost content={`${patientAppointments.length} scheduled appointments today`} calloutProps={{ gapSpace: 0 }}>
                <Badge value={patientAppointments.length} size={BadgeSize.size16} backgroundColor={theme.palette.themeLight} />
            </TooltipHost>
        );
    }
}
