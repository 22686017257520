import { ToothArea } from 'api/models/tooth-area';

const shorthandAreas: { [key: string]: string } = {
    [ToothArea.Distal]: 'D',
    [ToothArea.Mesial]: 'M',
    [ToothArea.Lingual]: 'L',
    [ToothArea.Crown]: '',
    [ToothArea.Buccal]: 'B',
    [ToothArea.Incisal]: 'I',
    [ToothArea.Occlusal]: 'O',
    [ToothArea.Vestibular]: 'V',
    [ToothArea.Facial]: 'F',
    [ToothArea.Root]: '',
    [ToothArea.BL]: 'BL',
    [ToothArea.FL]: 'FL',
    [ToothArea.LR]: 'LR',
    [ToothArea.LL]: 'LL',
    [ToothArea.UR]: 'UR',
    [ToothArea.UL]: 'UL',
    [ToothArea.UA]: 'UA',
    [ToothArea.LA]: 'LA',
    [ToothArea.LingualVestibular]: 'LV',
    [ToothArea.BuccalVestibular]: 'BV',
    [ToothArea.FacialVestibular]: 'FV',
};

export default function getShorthandToothAreas(areas?: (keyof typeof ToothArea)[]): string {
    const areasArray = areas?.map((a) => shorthandAreas[a]);
    return areasArray && areasArray.length ? areasArray.join('') : '';
}
