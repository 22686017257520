import { AdjustmentsSummaryReportQuery } from 'api/models/adjustments-summary-report-query.model';
import { ChargesAndPaymentsReportQuery } from 'api/models/charges-and-payments-report-query.model';
import {
    IClinicalMeasureDetailsReportProperties,
    IClinicalMeasureSummaryReportProperties,
} from 'api/models/clinical-measure-detail.model';
import { DailyBalanceReporyQuery } from 'api/models/daily-balance-report-query.model';
import { ReportType } from 'api/models/embed-report.model';
import {
    IEncounterDetailsReportQuery,
    IEncounterStatusReportQuery,
    IEncounterSummaryReportQuery,
} from 'api/models/encounter-reports.model';
import { FinancialSummaryReportQuery } from 'api/models/financial-summary-report-query.model';
import { ProceduresDateAgingReportQuery } from 'api/models/procedure-date-aging.model';
import { SlidingFeeEncounterDetailsQuery } from 'api/models/sliding-fee-encounter-details-report.model';
import { TimeOfServiceReportQuery } from 'api/models/time-of-service-report-query.model';
import { buildQueryReportAction, ReportQuery } from './reporting.actions';
import {
    DailyScheduleReportProperties,
    IBatchReportProperties,
    IChartNoteReportProperties,
    IEncounterReportProperties,
    IPatientRouteSlipReportProperties,
    IPatientStatementReportProperties,
    IPredeterminationFormProperties,
    IProviderProductivityReportProperties,
    ISpecificBatchReportProperties,
    IToothLedgerReportProperties,
    ITreatmentPlanReportProperties,
} from './reporting.state';
import { IUDSTable9DReportQuery } from 'api/models/udsTable.model';
import { IDepositSlipDetailQuery as IDepositSlipDetailQuery, IDepositSlipSummaryQuery } from 'api/models/deposit-slip.model';
import { IBillingStatusReportQuery } from 'api/models/billing-status.model';
import { IWrapReport } from 'api/models/wrap.model';
import { IAppointmentNoEncounter } from 'api/models/appointment-no-encounter.model';
import {
    TreatmentPlanPhaseOneBaseProperties,
    TreatmentPlanPhaseOneDetailProperties,
    TreatmentPlanPhaseOneSummaryProperties,
} from 'api/models/tx-plan-phase-1.model';
import { IDentalSealantsDetailQuery, IDentalSealantsSummaryQuery } from 'api/models/dental-sealants-report.model';
import { BulkPatientStatementReportQuery } from 'api/models/bulk-patient-statement';
import { IUACReportQuery } from 'api/models/uac-report.model';
import { FinancialTransactionDetailsReportQuery } from 'pages/Reporting/components/financial-transaction-details/model';
import { StatementValidationQuery } from 'pages/Reporting/components/statement-validation/statement-validation.model';
import { PatientReceiptReportQuery } from 'api/models/patient-receipt-report-query.model';

const reportActionLookup = {
    AdjustmentDetails: buildQueryReportAction<ReportQuery<AdjustmentsSummaryReportQuery>>(ReportType.AdjustmentDetails),
    AdjustmentSummary: buildQueryReportAction<ReportQuery<AdjustmentsSummaryReportQuery>>(ReportType.AdjustmentSummary),
    PatientReceipt: buildQueryReportAction<ReportQuery<PatientReceiptReportQuery>>(ReportType.PatientReceipt),
    SlidingFeeEncounterDetails: buildQueryReportAction<ReportQuery<SlidingFeeEncounterDetailsQuery>>(
        ReportType.SlidingFeeEncounterDetails,
    ),
    ChargeAndPaymentDetails: buildQueryReportAction<ReportQuery<ChargesAndPaymentsReportQuery>>(
        ReportType.ChargeAndPaymentDetails,
    ),
    ChargeAndPaymentSummary: buildQueryReportAction<ReportQuery<ChargesAndPaymentsReportQuery>>(
        ReportType.ChargeAndPaymentSummary,
    ),
    TimeOfServiceCollectionDetails: buildQueryReportAction<ReportQuery<TimeOfServiceReportQuery>>(
        ReportType.TimeOfServiceCollectionDetails,
    ),
    TimeOfServiceCollectionSummary: buildQueryReportAction<ReportQuery<TimeOfServiceReportQuery>>(
        ReportType.TimeOfServiceCollectionSummary,
    ),
    Batch: buildQueryReportAction<ReportQuery<IBatchReportProperties>>(ReportType.Batch),
    SpecificBatch: buildQueryReportAction<ReportQuery<ISpecificBatchReportProperties>>(ReportType.SpecificBatch),
    DailyBalance: buildQueryReportAction<ReportQuery<DailyBalanceReporyQuery>>(ReportType.DailyBalance),
    FinancialSummary: buildQueryReportAction<ReportQuery<FinancialSummaryReportQuery>>(ReportType.FinancialSummary),
    ProviderProductivity: buildQueryReportAction<ReportQuery<IProviderProductivityReportProperties>>(
        ReportType.ProviderProductivity,
    ),
    DailySchedule: buildQueryReportAction<ReportQuery<DailyScheduleReportProperties>>(ReportType.DailySchedule),
    ClinicalNote: buildQueryReportAction<ReportQuery<IChartNoteReportProperties>>(ReportType.ClinicalNote),
    ClinicalMeasureDetails: buildQueryReportAction<ReportQuery<IClinicalMeasureDetailsReportProperties>>(
        ReportType.ClinicalMeasureDetails,
    ),
    ClinicalMeasureSummary: buildQueryReportAction<ReportQuery<IClinicalMeasureSummaryReportProperties>>(
        ReportType.ClinicalMeasureSummary,
    ),
    TreatmentPlanPhaseOneSummary: buildQueryReportAction<ReportQuery<TreatmentPlanPhaseOneSummaryProperties>>(
        ReportType.TreatmentPlanPhaseOneSummary,
    ),
    TreatmentPlanPhaseOneDetails: buildQueryReportAction<ReportQuery<TreatmentPlanPhaseOneDetailProperties>>(
        ReportType.TreatmentPlanPhaseOneDetails,
    ),
    PatientStatement: buildQueryReportAction<ReportQuery<IPatientStatementReportProperties>>(ReportType.PatientStatement),

    TreatmentPlan: buildQueryReportAction<ReportQuery<ITreatmentPlanReportProperties>>(ReportType.TreatmentPlan),
    PredeterminationForm: buildQueryReportAction<ReportQuery<IPredeterminationFormProperties>>(ReportType.PrintPredetermination),

    PatientRouteSlip: buildQueryReportAction<ReportQuery<IPatientRouteSlipReportProperties>>(ReportType.PatientRouteSlip),
    ProcedureDateAging: buildQueryReportAction<ReportQuery<ProceduresDateAgingReportQuery>>(ReportType.ProcedureDateAging),
    Walkout: buildQueryReportAction<ReportQuery<IEncounterReportProperties>>(ReportType.Walkout),
    EncountersDetails: buildQueryReportAction<ReportQuery<IEncounterDetailsReportQuery>>(ReportType.EncountersDetails),
    EncountersSummary: buildQueryReportAction<ReportQuery<IEncounterSummaryReportQuery>>(ReportType.EncountersSummary),
    UDSTable9DDetails: buildQueryReportAction<ReportQuery<IUDSTable9DReportQuery>>(ReportType.UDSTable9DDetails),
    UDSTable9DCore: buildQueryReportAction<ReportQuery<IUDSTable9DReportQuery>>(ReportType.UDSTable9DCore),

    DepositSlipDetails: buildQueryReportAction<ReportQuery<IDepositSlipDetailQuery>>(ReportType.DepositSlipDetails),
    DepositSlipSummary: buildQueryReportAction<ReportQuery<IDepositSlipSummaryQuery>>(ReportType.DepositSlipSummary),
    BillingStatusAnalysisDetails: buildQueryReportAction<ReportQuery<IBillingStatusReportQuery>>(
        ReportType.BillingStatusAnalysisDetails,
    ),

    Wrap: buildQueryReportAction<ReportQuery<IWrapReport>>(ReportType.Wrap),
    ToothLedger: buildQueryReportAction<ReportQuery<IToothLedgerReportProperties>>(ReportType.ToothLedger),
    EncounterStatus: buildQueryReportAction<ReportQuery<IEncounterStatusReportQuery>>(ReportType.EncounterStatus),
    AppointmentsReportNoEncounterAssociated: buildQueryReportAction<ReportQuery<IAppointmentNoEncounter>>(
        ReportType.AppointmentsReportNoEncounterAssociated,
    ),
    DentalSealantsForChildrenDetails: buildQueryReportAction<ReportQuery<IDentalSealantsDetailQuery>>(
        ReportType.DentalSealantsForChildrenDetails,
    ),
    DentalSealantsForChildrenSummary: buildQueryReportAction<ReportQuery<IDentalSealantsSummaryQuery>>(
        ReportType.DentalSealantsForChildrenSummary,
    ),
    PatientStatementForBulk: buildQueryReportAction<ReportQuery<BulkPatientStatementReportQuery>>(
        ReportType.PatientStatementForBulk,
    ),
    ClaimHistory: buildQueryReportAction<ReportQuery<{ id: string }>>(ReportType.ClaimHistory),
    UDSTable6A: buildQueryReportAction<ReportQuery<IUDSTable9DReportQuery>>(ReportType.UDSTable6A),
    UDSTable5: buildQueryReportAction<ReportQuery<IUDSTable9DReportQuery>>(ReportType.UDSTable5),
    UACandOveragesDetailsReport: buildQueryReportAction<ReportQuery<IUACReportQuery>>(ReportType.UACandOveragesDetailsReport),
    UACandOveragesSummaryReport: buildQueryReportAction<ReportQuery<IUACReportQuery>>(ReportType.UACandOveragesSummaryReport),

    FinancialTransactionDetailsReport: buildQueryReportAction<ReportQuery<FinancialTransactionDetailsReportQuery>>(
        ReportType.FinancialTransactionDetails,
    ),
    StatementValidation: buildQueryReportAction<ReportQuery<StatementValidationQuery>>(ReportType.StatementValidation),
};

export default reportActionLookup;
