import { useState, useEffect } from 'react';

export const useWindowHeight = (): number => {
    // Initialize state with current window height
    const [height, setHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => {
            // Update the height state on window resize
            setHeight(window.innerHeight);
        };

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty array ensures the effect runs only on mount and unmount

    return height;
};
