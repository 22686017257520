import { AdjustmentDetailsGroup } from './adjustments-summary-report-query.model';

export type ChargesAndPaymentsReportQuery = {
    startDate?: string;
    endDate?: string;
    treatingProviderIds?: string[];
    locationOfCareIds?: string[];
    procedureIds?: string[];
    insuranceCarrierIds?: string[];
    groupCol1?: AdjustmentDetailsGroup;
    groupCol2?: AdjustmentDetailsGroup;
};

export enum ChargesAndPaymentsGroup {
    LocationOfCare = 'Location Of Care',
    TreatingProvider = 'Treating Provider',
    InsuranceCarrier = 'Insurance Carrier',
    TransactionCarrier = 'Transaction Carrier',
}
