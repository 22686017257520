
import { Link, MessageBar, MessageBarType, Spinner, Stack } from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import { ChartTreatmentPlanStatus } from 'api/models/treatment-plan.model';
import { ActivityPage, Section } from 'components';

import { IActionButton } from 'components/ActionButton';

import { SignalRMessage, useSignalR } from 'hooks/signalr/useSignalr';
import { LoadingStatus } from 'interfaces/loading-statuses';

import { RouteParams } from 'interfaces/route-params';
import { PatientDetailsBanner } from 'pages/components';
import PatientInsuranceBanner from 'pages/components/PatientInsuranceBanner';
import { MouseEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { setTreatmentPlanIsSigning } from 'state/slices/charting/chart/chart.slice';
import {

    getChartTreatmentPlanById,
    insertTreatmentPlan,
    onDiscardCurrentTreatmentPlan,
} from 'state/slices/charting/treatmentPlans/treatmentPlans.actions';
import {

    declinedPhasedProcedures,
    preauthPendingPhasedProceduresFiltered,
    referredTreatmentsInPhases,
    selectCurrentTreatmentPlan,
    selectCurrentTreatmentPlanSignedOrSigning,
    selectTreatmentPlanError,
    selectTreatmentPlanIsSigning,
    selectTreatmentPlanLoading,
    selectTreatmentPlanPhasesHaveProcedures,
    selectTreatmentPlanSigned,
} from 'state/slices/charting/treatmentPlans/treatmentPlans.selectors';
import dateOnly from 'utils/dateOnly';


import CustomTreatmentTable from './CustomTreatmentTable';
import PendingProceduresTable from './PendingProceduresTable';
import TreatmentPlanPhases from './TreatmentPlanPhases';
import TreatmentPlanSignature from './TreatmentPlanSignature';

import ConfirmationModal from 'components/ConfirmationModal';

import ErrorTypeMessageBar from 'components/ErrorTypeMessageBar';


function TreatmentPlan(): JSX.Element {
    const dispatch = useDispatch();
    const { push } = useHistory();
    const { patientId, tenantId, encounterId, treatmentPlanId } = useParams<RouteParams>();
    const { registerSignalRMessage } = useSignalR();
    const treatmentPlan = useSelector(selectCurrentTreatmentPlan);
    const isSignedOrSigning = useSelector(selectCurrentTreatmentPlanSignedOrSigning);
    const isSigning = useSelector(selectTreatmentPlanIsSigning);
    const isSigned = useSelector(selectTreatmentPlanSigned);
    const treatmentPlanHasPhasedProcedures = useSelector(selectTreatmentPlanPhasesHaveProcedures);
    const loadingCurrentTreatmentPlan = useSelector(selectTreatmentPlanLoading);
    const errors = useSelector(selectTreatmentPlanError);

    const loading = loadingCurrentTreatmentPlan === LoadingStatus.Pending;

    const [isOpen, { setTrue: setIsOpenTrue, setFalse: setIsOpenFalse }] = useBoolean(false);

    useEffect(() => {
        return () => {
            dispatch(setTreatmentPlanIsSigning(false));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        registerSignalRMessage(SignalRMessage.UpdatedChartTreatmentPlan, insertTreatmentPlan);
    }, []);

    const _backToTreatmentPlans = (ev?: MouseEvent<unknown>) => {
        ev?.preventDefault();
        push(`/${tenantId}/patient/${patientId}/encounter/${encounterId}/treatment-plans`);
    };

    const pendingTreatmentPlanButtons: IActionButton[] = [
        {
            text: 'Treatment Plans',
            type: 'DefaultButton',
            iconProps: { iconName: 'ChromeBack' },
            onClick: _backToTreatmentPlans,
        },
        {
            text: 'Sign treatment plan',
            type: 'PrimaryButton',
            iconProps: { iconName: 'InsertSignatureLine' },
            onClick: () => dispatch(setTreatmentPlanIsSigning(true)),
            disabled: (treatmentPlan?.phases && treatmentPlan.phases.length <= 0) || !treatmentPlanHasPhasedProcedures,
        },
    ];

    if (treatmentPlan?.status === ChartTreatmentPlanStatus.Pending)
        pendingTreatmentPlanButtons.push({
            text: 'Discard',
            type: 'DefaultButton',
            iconProps: { iconName: 'Cancel' },
            onClick: () => setIsOpenTrue(),
            disabled: treatmentPlan?.phases && treatmentPlan.phases.length <= 0,
        });

    const signedTreatmentPlanButtons: IActionButton[] = [
        {
            text: 'Treatment Plans',
            type: 'DefaultButton',
            iconProps: { iconName: 'ChromeBack' },
            onClick: _backToTreatmentPlans,
        },
    ];

    const onClickRefresh = () => {
        if (patientId) dispatch(getChartTreatmentPlanById({ patientId, tenantId, treatmentPlanId }));
    };

    return (
        <ActivityPage
            title={
                isSignedOrSigning
                    ? `Signed Treatment Plan ${
                          treatmentPlan?.modifiedOn ? dateOnly(treatmentPlan.modifiedOn, '(MM/dd/yyyy)') : ''
                      }`
                    : 'Edit Treatment Plan'
            }
            mainButtons={
                !loading
                    ? isSignedOrSigning || treatmentPlan?.isDeleted
                        ? signedTreatmentPlanButtons
                        : pendingTreatmentPlanButtons
                    : []
            }
        >
            <TreatmentPlanBanners />
            {loading ? (
                <Spinner style={{ padding: 12 }} label="Loading..." labelPosition="right" />
            ) : (
                <Stack styles={{ root: { padding: 12 } }}>
                    <Stack tokens={{ childrenGap: 12 }}>
                        {/* <TreatmentPlanCoordinator /> */}
                        {!isSignedOrSigning && <PendingProceduresTable />}
                        <Stack.Item>
                            <TreatmentPlanPhases />
                        </Stack.Item>
                    </Stack>
                    <TreatmentPlanFooterTables />
                    {isSigning && <TreatmentPlanSignature />}
                    <ConfirmationModal
                        title="Discard Treatment Plan"
                        confirmationMessage="Are you sure you want to continue discarding the current treatment plan?"
                        isOpen={isOpen}
                        onDismiss={setIsOpenFalse}
                        onContinue={() => {
                            //Pass the encounterId from the route here to keep the user in their current encounter if they are in one.
                            dispatch(onDiscardCurrentTreatmentPlan({ tenantId, patientId, encounterId }));
                            setIsOpenFalse();
                        }}
                    />

                    <Stack tokens={{ childrenGap: 12 }}>
                        {errors && (
                            <ErrorTypeMessageBar error={errors}>
                                {' '}
                                <Link onClick={onClickRefresh}> Refresh </Link> to view the most current information.
                            </ErrorTypeMessageBar>
                        )}

                        {isSigned ? (
                            <MessageBar messageBarIconProps={{ iconName: 'Lock' }} messageBarType={MessageBarType.severeWarning}>
                                Treatment Plan has been signed and locked.
                            </MessageBar>
                        ) : null}
                        {!treatmentPlanHasPhasedProcedures && !isSignedOrSigning && (
                            <MessageBar messageBarType={MessageBarType.blocked}>
                                To sign a treatment plan, at least one phase with at least one procedure assigned to it must be
                                created. Any additional phases must also have at least one procedure assigned to them.
                            </MessageBar>
                        )}
                    </Stack>
                </Stack>
            )}
        </ActivityPage>
    );
}

function TreatmentPlanBanners() {
    return (
        <Stack style={{ position: 'sticky', top: 0, zIndex: 1 }}>
            <PatientDetailsBanner />
            <PatientInsuranceBanner />
        </Stack>
    );
}

function TreatmentPlanFooterTables() {
    const referred = useSelector(referredTreatmentsInPhases);
    const declined = useSelector(declinedPhasedProcedures);
    const preauthedFiltered = useSelector(preauthPendingPhasedProceduresFiltered);

    return (
        <Section>
            <Stack>
                <CustomTreatmentTable displayName="Referred Treatment" items={referred} />
                <CustomTreatmentTable displayName="Declined Treatment" items={declined} />
                <CustomTreatmentTable displayName="Preauth Treatment" items={preauthedFiltered} />
            </Stack>
        </Section>
    );
}

export default TreatmentPlan;
