import { useState } from 'react';
import LookupSettingPage from '../Shared/LookupSettingPage';
import DetailsList from './DetailsList';
import EditPanel from './EditPanel';
import IOperatory from 'api/models/Scheduling/operatory.model';
import SettingsSearchBox from '../components/SettingsSearchBox';
import { Stack, Toggle } from '@fluentui/react';

const newItem: IOperatory = {
    id: '',
    displayName: '',
    description: '',
    locationOfCareId: '',
    isDeleted: false,
};

const ManageDiagnoses = (): JSX.Element => {
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [activeOnly, setActiveOnly] = useState<boolean>(true);

    const toggleActiveOnly = () => {
        setActiveOnly(!activeOnly);
    };

    return (
        <LookupSettingPage<IOperatory>
            lookupType="Operatories"
            displayName="Operatory"
            newItem={newItem}
            listComponent={<DetailsList searchTerm={searchTerm} active={activeOnly} />}
            editComponent={<EditPanel />}
            searchBoxComponent={
                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 10 }}>
                    <SettingsSearchBox searchTerm={searchTerm} placeholder="Search Operatories" onSearchChange={setSearchTerm} />
                    <Toggle inlineLabel checked={activeOnly} label={'Active Only'} onChange={() => toggleActiveOnly()} />
                </Stack>
            }
        />
    );
};

export default ManageDiagnoses;
