import { Dropdown, IDropdownOption, Stack, TextField } from '@fluentui/react';
import { PhaseOneBaseFilterType, TreatmentPlanPhaseOneBaseProperties } from 'api/models/tx-plan-phase-1.model';
import { Field } from 'components';
import { useSelector, useTenantId } from 'hooks';
import { IValidationError, getValidationError } from 'hooks/useValidation';
import { map } from 'lodash';
import ReportWrapper from 'pages/Reporting/ReportWrapper';
import { PropsWithChildren, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getAllLocationsOfCareForReports } from 'state/slices/reporting/reporting.actions';
import {
    selectLocationsOfCareFromReporting,
    selectReportProperties,
    selectReportingValidationErrors,
} from 'state/slices/reporting/reporting.selectors';
import {
    setTreatmentPlanPhase1BaseDenominatorCodes,
    setTreatmentPlanPhase1BaseProp,
    toggleTreatmentPlanPhase1BaseIds,
} from 'state/slices/reporting/reporting.slice';
import { selectReportingProviderWithGroupNameOptions } from 'state/slices/tenant/providers.slice';
import { classicDateOnly } from 'utils/dateOnly';

type Props = {
    onViewReport: () => void;
};

const filterTypeOptions: IDropdownOption[] = map(PhaseOneBaseFilterType, (type) => ({
    key: type,
    text: type,
}));

export default function TXPlanPhase1Base({ onViewReport, children }: PropsWithChildren<Props>) {
    const dispatch = useDispatch();
    const tenantId = useTenantId();

    const query = useSelector(selectReportProperties) as TreatmentPlanPhaseOneBaseProperties;
    const errors = useSelector(selectReportingValidationErrors);
    const _locationsOfCare = useSelector(selectLocationsOfCareFromReporting);
    const _providerOptions = useSelector(selectReportingProviderWithGroupNameOptions);

    useEffect(() => {
        dispatch(getAllLocationsOfCareForReports({ tenantId }));
        dispatch(
            setTreatmentPlanPhase1BaseProp({
                path: 'lookBackDays',
                value: 180,
            }),
        );
        dispatch(
            setTreatmentPlanPhase1BaseProp({
                path: 'filterType',
                value: PhaseOneBaseFilterType.AllEncounters,
            }),
        );
        dispatch(
            setTreatmentPlanPhase1BaseProp({
                path: 'ageStart',
                value: 0,
            }),
        );
        dispatch(
            setTreatmentPlanPhase1BaseProp({
                path: 'ageEnd',
                value: 125,
            }),
        );
        dispatch(setTreatmentPlanPhase1BaseDenominatorCodes());
    }, []);

    const locationsOfCareOptions: IDropdownOption[] = _locationsOfCare.map((loc) => ({
        key: loc.id,
        text: loc.displayName,
    }));

    const errorsMessage = (error: IValidationError | undefined) => {
        if (!error) return undefined;
        if (error.errorTypes.includes('required')) {
            return 'Ending Age Is Required';
        }
        if (error.errorTypes.includes('numberComparison')) {
            return 'Invalid Number';
        }
    };

    return (
        <ReportWrapper
            validationConfig={[
                { fieldName: 'Start Date', validation: ['required'], value: query?.startDate },
                { fieldName: 'End Date', validation: ['required'], value: query?.endDate },
                { fieldName: 'Number of Days to Complete', validation: ['required'], value: query?.lookBackDays },
                { fieldName: 'Denominator Codes', validation: ['required'], value: query?.denominatorCodes },
                { fieldName: 'Starting Age', validation: ['required'], value: query?.ageStart },
                {
                    fieldName: 'Ending Age',
                    validation: ['required', 'numberComparison'],
                    itemOptions: { numberFrom: query?.ageStart },
                    value: query?.ageEnd,
                },
            ]}
            onViewReport={onViewReport}
        >
            <Stack grow horizontal wrap tokens={{ childrenGap: 10 }}>
                <Stack.Item>
                    <Field.Date
                        label="Start Date"
                        required
                        hasDatePicker
                        onChange={(ev, value) => {
                            const newDate = value ? classicDateOnly(value, 'yyyy-MM-dd') : undefined;
                            dispatch(setTreatmentPlanPhase1BaseProp({ path: 'startDate', value: newDate }));
                        }}
                        value={query?.startDate ? classicDateOnly(query?.startDate, 'MM/dd/yyyy') : ''}
                        errorMessage={getValidationError(errors, 'Start Date') ? 'Start date is required.' : undefined}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.Date
                        label="End Date"
                        required
                        isReasonable
                        hasDatePicker
                        value={query?.endDate ? classicDateOnly(query?.endDate, 'MM/dd/yyyy') : ''}
                        onChange={(ev, value) => {
                            const newDate = value ? classicDateOnly(value, 'yyyy-MM-dd') : undefined;
                            dispatch(setTreatmentPlanPhase1BaseProp({ path: 'endDate', value: newDate }));
                        }}
                        disabled={!query?.startDate}
                        minReasonableErrorMessage="End date must be after start date."
                        minReasonableDate={query?.startDate ? new Date(classicDateOnly(query?.startDate)) : undefined}
                        errorMessage={getValidationError(errors, 'End Date') ? 'End date is required.' : undefined}
                    />
                </Stack.Item>
                <Stack.Item>
                    <TextField
                        label="Number of Days to Complete"
                        required
                        onChange={(ev, value) => {
                            if (value !== undefined && isNaN(+value)) return;
                            dispatch(
                                setTreatmentPlanPhase1BaseProp({
                                    path: 'lookBackDays',
                                    value: value ? (+value > 365 ? 365 : +value) : undefined,
                                }),
                            );
                        }}
                        value={query.lookBackDays?.toString() ?? ''}
                        errorMessage={
                            getValidationError(errors, 'Number of Days to Complete')
                                ? 'Number of Days to Complete is required.'
                                : undefined
                        }
                    />
                </Stack.Item>
                <Stack.Item>
                    <Dropdown
                        options={filterTypeOptions}
                        label="Filter Type"
                        required
                        onChange={(ev, option) => {
                            if (option?.key !== undefined)
                                dispatch(
                                    setTreatmentPlanPhase1BaseProp({
                                        path: 'filterType',
                                        value: option.key as string,
                                    }),
                                );
                        }}
                        selectedKey={query?.filterType ?? ''}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.SearchCombo
                        placeholder="(Select Provider)"
                        label="Providers"
                        multiSelect
                        options={_providerOptions}
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(toggleTreatmentPlanPhase1BaseIds({ id: option.key as string, path: 'providerIds' }));
                        }}
                        selectedKey={query?.providerIds}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.SearchCombo
                        placeholder="(Select Locations of Care)"
                        label="Locations of Care"
                        multiSelect
                        options={locationsOfCareOptions}
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(
                                    toggleTreatmentPlanPhase1BaseIds({ id: option.key as string, path: 'locationOfCareIds' }),
                                );
                        }}
                        selectedKey={query?.locationOfCareIds}
                    />
                </Stack.Item>
                {children}
            </Stack>
            <Stack grow horizontal wrap tokens={{ childrenGap: 10 }}>
                <Stack.Item>
                    <Field.SearchCombo
                        placeholder="Denominator Codes"
                        label="Denominator Codes"
                        multiSelect
                        required
                        options={[
                            { key: 'D0120', text: 'D0120' },
                            { key: 'D0145', text: 'D0145' },
                            { key: 'D0150', text: 'D0150' },
                        ]}
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(
                                    toggleTreatmentPlanPhase1BaseIds({ id: option.key as string, path: 'denominatorCodes' }),
                                );
                        }}
                        selectedKey={query?.denominatorCodes ?? ''}
                        errorMessage={
                            getValidationError(errors, 'Denominator Codes') ? 'Denominator Codes is required.' : undefined
                        }
                    />
                </Stack.Item>
                <Stack.Item>
                    <TextField
                        label="Starting Age"
                        required
                        onChange={(ev, value) => {
                            if (value !== undefined && isNaN(+value)) return;
                            dispatch(
                                setTreatmentPlanPhase1BaseProp({
                                    path: 'ageStart',
                                    value: value ? (+value > 365 ? 365 : +value) : undefined,
                                }),
                            );
                        }}
                        value={query.ageStart?.toString() ?? ''}
                        errorMessage={getValidationError(errors, 'Starting Age') ? 'Starting Age is required.' : undefined}
                    />
                </Stack.Item>
                <Stack.Item>
                    <TextField
                        label="Ending Age"
                        required
                        onChange={(ev, value) => {
                            if (value !== undefined && isNaN(+value)) return;
                            dispatch(
                                setTreatmentPlanPhase1BaseProp({
                                    path: 'ageEnd',
                                    value: value ? (+value > 365 ? 365 : +value) : undefined,
                                }),
                            );
                        }}
                        value={query.ageEnd?.toString() ?? ''}
                        errorMessage={errorsMessage(getValidationError(errors, 'Ending Age'))}
                    />
                </Stack.Item>
            </Stack>
        </ReportWrapper>
    );
}
