import { DetailsRow, DirectionalHint, MessageBar, SelectionMode, Stack, Text, TooltipHost } from '@fluentui/react';
import { IEncounterHistory } from 'api/models/encounter.model';
import { Section, SortableDetailsList } from 'components';
import UserDisplayName from 'components/UserDisplayName';
import { useSelector } from 'hooks';
import { getGroupedProcedureCodes, selectLocationsOfCare } from 'state/slices/scheduling/scheduling.selectors';

import { classicDateOnly } from 'utils/dateOnly';
import { timeOptions } from 'utils/getTimeOptions';
import DispositionTooltip from './DispositionTooltip';
import { IUpcomingAppointment } from 'api/models/Scheduling/patientAppointment.model';
import { IAppointmentHistory } from 'api/models/appointment-history.model';
import { convertFromMilitaryTime } from 'utils/convertFromMilitaryTime';

type Props = {
    items?: IAppointmentHistory[];
};

function ApptEncounterHistory({ items }: Props): JSX.Element {
    const locationsOfCare = useSelector(selectLocationsOfCare);

    if (!items?.length) return <MessageBar>There is no appointment history.</MessageBar>;
    console.log(items);
    const tooltipContent = (item: IEncounterHistory) => (
        <Stack grow>
            <div>Reason for Encounter: {item.encounterReason ? item.encounterReason : 'N/A'}</div>
        </Stack>
    );

    const getGroupedCodes = (encounter?: IAppointmentHistory) =>
        encounter?.procedureCodes
            ? getGroupedProcedureCodes(encounter.procedureCodes.split(','))
                  .map((obj) => `${obj.code}${obj.count > 1 ? ` x${obj.count}` : ''}`)
                  .join(', ')
            : '';

    const getLocationOfCareContent = (item: IUpcomingAppointment | undefined) => {
        return locationsOfCare.find((res) => (res.id ? res.id === item?.locationOfCareId : ''))?.displayName ?? '';
    };

    return (
        <Section>
            <SortableDetailsList<IAppointmentHistory>
                compact
                selectionMode={SelectionMode.none}
                items={items || []}
                sortOnMount={true}
                sortColumns={['provider']}
                onRenderRow={(props) => {
                    if (props) {
                        const { item } = props;
                        if (!item) return null;
                        return (
                            <TooltipHost delay={0} directionalHint={DirectionalHint.leftCenter} content={tooltipContent(item)}>
                                <DetailsRow {...props} />
                            </TooltipHost>
                        );
                    }

                    return null;
                }}
                columns={[
                    {
                        key: 'date',
                        minWidth: 110,
                        maxWidth: 120,
                        name: 'Date/Time',
                        fieldName: 'date',
                        onRender: (item) => {
                            const time = item?.startTime ? ` | ${convertFromMilitaryTime(item.startTime)}` : '';
                            const date = item?.date ? classicDateOnly(item?.date) : '';
                            const dateTimeText = `${date}${time}`;
                            return item?.isDeleted ? <i>{dateTimeText}</i> : <Text variant="small">{dateTimeText}</Text>;
                        },
                    },

                    {
                        key: 'provider',
                        minWidth: 125,
                        maxWidth: 125,
                        name: 'Treating Provider',
                        fieldName: 'provider',
                        onRender: (item) => {
                            if (item?.providerId) {
                                return item?.isDeleted ? (
                                    <i>
                                        <UserDisplayName userId={item.providerId} />
                                    </i>
                                ) : (
                                    <UserDisplayName userId={item.providerId} />
                                );
                            }
                        },
                    },
                    {
                        key: 'locationOfCare',
                        minWidth: 130,
                        maxWidth: 130,
                        name: 'Location of Care',
                        getValueKey: (item) => getLocationOfCareContent(item),

                        onRender: (item) => {
                            if (item) {
                                const locationDisplayName = getLocationOfCareContent(item);
                                return item.isDeleted ? <i> {locationDisplayName} </i> : <span> {locationDisplayName} </span>;
                            }
                        },
                    },
                    {
                        key: 'encounterReason',
                        minWidth: 135,
                        maxWidth: 135,
                        name: 'Reason for Encounter',
                        fieldName: 'encounterReason',
                        onRender: (item) =>
                            item ? item?.isDeleted ? <i>{item.encounterReason}</i> : <span>{item.encounterReason}</span> : '',
                    },
                    {
                        key: 'procedures',
                        minWidth: 150,
                        maxWidth: 150,
                        name: 'Procedures',
                        fieldName: 'procedureCodes',
                        onRender: (item) => (
                            <TooltipHost content={getGroupedCodes(item)}>
                                {item?.isDeleted ? <i>{getGroupedCodes(item)}</i> : <span>{getGroupedCodes(item)}</span>}
                            </TooltipHost>
                        ),
                    },
                    {
                        key: 'canceledOrTracker',
                        minWidth: 100,
                        maxWidth: 100,
                        name: 'Disposition',
                        onRender: (item) => {
                            return <DispositionTooltip item={item} />;
                        },
                    },
                    {
                        key: 'reasonForCancellation',
                        minWidth: 140,
                        maxWidth: 150,
                        name: 'Cancellation Reason',
                        fieldName: 'cancellationReason',
                        onRender: (item) => {
                            return <span title={item?.cancellationReason ?? ''}>{item?.cancellationReason ?? ''}</span>;
                        },
                    },
                ]}
            />
        </Section>
    );
}

export default ApptEncounterHistory;
