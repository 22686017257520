import { Stack, TextField, DefaultButton, MessageBar, MessageBarType, Spinner } from '@fluentui/react';
import IPatientCommunication, { PatientCommunicationNoteType } from 'api/models/patient-communication.model';
import Section from 'components/Section';
import ValidationBar from 'components/ValidationBar';
import { useTenantId, useValidation } from 'hooks';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { TextEditor } from 'pages/Charting/components/TextEditor';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    addOrUpdateCommunication,
    editNewCommunication,
    updatePatientCommunication,
} from 'state/slices/communication/communication.actions';
import { selectEditCommunication, selectEditCommunicationsLoading } from 'state/slices/communication/communication.selectors';
import { cleanupEditCommunication, editCommunicationChangeProp } from 'state/slices/communication/communication.slice';
import { selectEditPatient } from 'state/slices/edit-patient/edit-patient.selectors';

function EditCommunication() {
    const dispatch = useDispatch();

    const editCommunication = useSelector(selectEditCommunication);
    const isNew = !editCommunication?._etag;
    const loading = useSelector(selectEditCommunicationsLoading);
    const tenantId = useTenantId();

    const patient = useSelector(selectEditPatient);

    const [errors, submit, cleanupErrors] = useValidation(
        [
            {
                fieldName: 'Subject',
                validation: ['required'],
                value: editCommunication?.subject,
            },
            {
                fieldName: 'Notes',
                validation: ['required'],
                value: editCommunication?.message,
            },
        ],
        addOrSaveEditCommunication,
    );

    useEffect(() => {
        return () => {
            cleanupErrors();
            _cleanupEditCommunication();
        };
    }, []);

    function editCommunicationProp(key: keyof IPatientCommunication, value: unknown) {
        dispatch(editCommunicationChangeProp({ key, value }));
    }

    function addCommunication() {
        if (patient) dispatch(editNewCommunication(patient.id, PatientCommunicationNoteType.Patient));
    }

    function _cleanupEditCommunication() {
        dispatch(cleanupEditCommunication({}));
    }

    function addOrSaveEditCommunication() {
        dispatch(addOrUpdateCommunication(tenantId, patient?.id));
    }

    return (
        <Section
            sectionButtons={[
                {
                    style: { display: editCommunication ? 'none' : 'inherit' },
                    iconProps: { iconName: 'Add' },
                    text: 'Communication',
                    onClick: addCommunication,
                    type: 'PrimaryButton',
                },
            ]}
            footer={
                <>
                    {editCommunication && (
                        <Stack tokens={{ childrenGap: 5 }}>
                            <ValidationBar errors={errors}></ValidationBar>
                            <Stack grow horizontal horizontalAlign="end" tokens={{ childrenGap: 10 }}>
                                {loading === LoadingStatus.Pending && (
                                    <Spinner labelPosition="right" label="Loading..."></Spinner>
                                )}
                                <DefaultButton
                                    primary
                                    style={{ maxWidth: '150px', float: 'right' }}
                                    text={isNew ? 'Add to log' : 'Update'}
                                    onClick={submit}
                                />
                                <DefaultButton
                                    style={{ maxWidth: '150px', float: 'right' }}
                                    text="Cancel"
                                    onClick={_cleanupEditCommunication}
                                />
                            </Stack>
                        </Stack>
                    )}
                </>
            }
            heading={isNew ? 'Add Communication' : 'Edit Communication'}
        >
            {editCommunication && (
                <Stack>
                    <TextField
                        label="Subject"
                        placeholder="Enter Subject"
                        value={editCommunication?.subject}
                        required
                        autoComplete="off"
                        onChange={(ev, value) => editCommunicationProp('subject', value)}
                    />
                    <TextEditor
                        placeholder="Enter notes..."
                        onChange={(value) => editCommunicationProp('message', value)}
                        value={editCommunication?.message}
                    />
                </Stack>
            )}
        </Section>
    );
}

export default EditCommunication;
