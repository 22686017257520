import { DefaultButton, DirectionalHint, IButtonProps, Icon, TooltipHost, useTheme } from '@fluentui/react';
import { CSSProperties } from 'react';
import { useId } from '@uifabric/react-hooks';

export type ChartingMenuToolbarButton = {
    displayName?: string;
    key: string;
    tooltipContent: string;
    onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
    icon?: string;
    buttonDisabled?: boolean;
    allDisabled?: boolean;
};

function ChartMenuToolbarButton({
    displayName,
    onClick,
    icon: iconName,
    tooltipContent,
    allDisabled,
    buttonDisabled,
    ...props
}: ChartingMenuToolbarButton & Omit<IButtonProps, 'disabled'>): JSX.Element {
    const { semanticColors } = useTheme();
    const MENU_BUTTON_SIZE = 32;
    const buttonStyle: CSSProperties = {
        width: MENU_BUTTON_SIZE,
        minWidth: MENU_BUTTON_SIZE,
        padding: 0,
        height: MENU_BUTTON_SIZE,
    };
    const id = useId();

    return (
        <TooltipHost content={tooltipContent} directionalHint={DirectionalHint.leftCenter}>
            <DefaultButton
                text={displayName}
                disabled={allDisabled || buttonDisabled}
                {...props}
                style={buttonStyle}
                onClick={onClick}
                id={id}
            >
                {iconName ? (
                    <Icon
                        iconName={iconName}
                        styles={{
                            root: {
                                // Fix for custom SVG icons, force color to the defaults
                                fill:
                                    allDisabled || buttonDisabled ? semanticColors.buttonTextDisabled : semanticColors.buttonText,
                            },
                        }}
                    />
                ) : null}
            </DefaultButton>
        </TooltipHost>
    );
}

export default ChartMenuToolbarButton;
