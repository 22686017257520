import { Dictionary } from '@reduxjs/toolkit';
import { IBillingProcedure } from 'api/models/billing-procedure.model';
import { ChartProcedurePreAuth, ChartProcedureStatus } from 'api/models/chart.model';
import {
    IChartTreatmentPlanPhaseProcedure,
    IChartTreatmentPlan,
    IChartTreatmentPlanPhase,
    ChartTreatmentPlanStatus,
} from 'api/models/treatment-plan.model';
import { LoadingStatus, LoadingStatuses } from 'interfaces/loading-statuses';
import { flatten, sum } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'state/store';
import { ProcedureActionType } from '../chart/chart.slice';
import ErrorTypes from 'state/errorTypes';

export const selectAllTreatmentPlans = (state: RootState): IChartTreatmentPlan[] => state.charting.treatmentPlans.data ?? [];
export const selectTreatmentPlans = (state: RootState): IChartTreatmentPlan[] =>
    state.charting.treatmentPlans.data.filter((plan) => !plan.isDeleted) ?? [];
export const selectTreatmentPlansSaving = (state: RootState): LoadingStatuses => state.charting.treatmentPlans.saving;
export const selectTreatmentPlanSigned = (state: RootState): boolean => state.charting.treatmentPlans.signedOrRefused;
export const selectTreatmentPlanLoading = (state: RootState): LoadingStatuses => state.charting.treatmentPlans.loadingCurrentPlan;
export const selectTreatmentPlanIsSigning = (state: RootState): boolean => state.charting.treatmentPlans.isSigning;
export const selectTreatmentPlanError = (state: RootState): ErrorTypes | undefined => state.charting.treatmentPlans.error;
//Returns all treatment plans that have the status of signed.
export const selectSignedTreatmentPlans = createSelector(selectTreatmentPlans, (treatmentPlans) =>
    treatmentPlans.filter((tp) => tp.status === ChartTreatmentPlanStatus.Signed),
);

//Returns all procedures that have been phased on signed treatmentplans as an object. The key is the chart procedure id.
export const selectSignedTreatmentPlanProceduresLookup = createSelector(selectSignedTreatmentPlans, (signedPlans) => {
    const lookup: Dictionary<IChartTreatmentPlanPhaseProcedure> = {};
    (flatten(signedPlans.map((plan) => plan.procedures)) as IChartTreatmentPlanPhaseProcedure[]).forEach((procedure) => {
        lookup[`${procedure.id}`] = procedure;
    });
    return lookup;
});

// Current Treatment Plan to Create/Update
export const selectCurrentTreatmentPlan = (state: RootState): IChartTreatmentPlan | undefined =>
    state.charting.treatmentPlans.currentTreatmentPlan;

export const selectCurrentTreatmentPlanPhases = createSelector(selectCurrentTreatmentPlan, (treatmentPlan) => {
    return treatmentPlan?.phases ?? [];
});

export const selectCurrentTreatmentPlanProcedures = createSelector(selectCurrentTreatmentPlan, (treatmentPlan) => {
    return treatmentPlan?.procedures ?? [];
});

export const selectPendingProcedures = (state: RootState): IBillingProcedure[] => state.charting.treatmentPlans.pendingProcedures;

//Selects all current TP procedures, but overwritten by the calculated procedures...
export const calculatedTreatmentPlanProcedures = createSelector(
    selectCurrentTreatmentPlan,
    selectCurrentTreatmentPlanProcedures,
    selectPendingProcedures,
    (treatmentPlan, tpProcedures, calculatedProcedures) => {
        const procedures =
            treatmentPlan?.status === ChartTreatmentPlanStatus.Pending
                ? tpProcedures.map((p) => {
                      const calculatedProcedure = calculatedProcedures.find((tp) => tp.id === p.id);
                      if (calculatedProcedure) {
                          return { ...p, ...calculatedProcedure };
                      }
                      return p;
                  })
                : tpProcedures;
        return procedures;
    },
);

export const selectTreatmentPlanPhasesHaveProcedures = createSelector(
    calculatedTreatmentPlanProcedures,
    selectCurrentTreatmentPlanPhases,
    (proceduresFromPhases, phases) => {
        if (!phases.length) return false;
        //Lookup of phases that exist for the current TP keyed off the phase id.
        const phaseLookup = new Set(phases.map((p) => p.id));
        //Keeps track of phases with procedures associated.
        const phasesWithProcedures = new Set();

        //Loop through all procedures that are on any given phase.
        proceduresFromPhases.forEach((procedure) => {
            // If our lookup has the given procedure phaseId.
            if (procedure.phaseId && phaseLookup.has(procedure.phaseId)) {
                //Add to phases with procedures lookup. Using set avoids duplicates.
                phasesWithProcedures.add(procedure.phaseId);
            }
        });

        return phases.length === phasesWithProcedures.size;
    },
);

export const makeSelectPhaseProcedures = createSelector(
    calculatedTreatmentPlanProcedures,
    (_: RootState, phaseId: string) => phaseId,
    (procedures, phaseId) => {
        return procedures.filter((procedure) => procedure.phaseId === phaseId);
    },
);

export const selectCurrentTreatmentPlanSignedOrSigning = createSelector(
    selectCurrentTreatmentPlan,
    selectTreatmentPlanIsSigning,
    (tp, isSigning) => {
        return tp?.status === ChartTreatmentPlanStatus.Declined || tp?.status === ChartTreatmentPlanStatus.Signed || isSigning;
    },
);
export const selectCurrentTreatmentPlanSigned = createSelector(selectCurrentTreatmentPlan, (tp) => {
    return tp?.status === ChartTreatmentPlanStatus.Declined || tp?.status === ChartTreatmentPlanStatus.Signed;
});

export const selectedFinancialNotes = (state: RootState): string | undefined =>
    state.charting.treatmentPlans.currentTreatmentPlan?.financialOptionsNote;

// Pending Procedures (BillingProcedure) from API
export const selectPendingProceduresIsLoading = (state: RootState): boolean =>
    state.charting.treatmentPlans.loadingPendingProcedures === LoadingStatus.Pending;

const isDeclined = (procedure: IChartTreatmentPlanPhaseProcedure) => procedure.status === 'Declined';
const isReferred = (procedure: IChartTreatmentPlanPhaseProcedure) => procedure.type === 'Referred';
const isTreatment = (procedure: IChartTreatmentPlanPhaseProcedure) => procedure.type === 'Treatment';
const isStatus = (procedure: IChartTreatmentPlanPhaseProcedure) =>
    procedure.status === 'Pending' || procedure.status === 'Declined';
const isPreAuth = (procedure: IChartTreatmentPlanPhaseProcedure) =>
    procedure.preAuthorization && procedure.preAuthorization !== 'Not Required';
const isTreatmentOrReferred = (procedure: IChartTreatmentPlanPhaseProcedure) =>
    procedure.type === 'Treatment' || procedure.type === 'Referred';

export const pendingProcedures = createSelector(
    [selectPendingProcedures, selectCurrentTreatmentPlanProcedures],
    (pendingProcedureList, proceduresInPhases) => {
        const notInPhase = (procedure: IBillingProcedure) =>
            proceduresInPhases.findIndex((proc) => proc.id === procedure.id) === -1;

        const procedureReferredOrTreatmnet = (procedure: IBillingProcedure) =>
            procedure.type === ProcedureActionType.Treatment || procedure.type === ProcedureActionType.Referred;
        const procedureNotCompletedOrDeclined = (procedure: IBillingProcedure) =>
            procedure.status !== 'Completed' && procedure.status !== 'Declined';

        const pendingProcedures = pendingProcedureList
            .filter(notInPhase)
            .filter(procedureReferredOrTreatmnet)
            .filter(procedureNotCompletedOrDeclined);

        return pendingProcedures;
    },
);

export const treatmentOrReferredTreatmentPlanProcedures = createSelector([calculatedTreatmentPlanProcedures], (procedures) =>
    procedures.filter(isTreatmentOrReferred),
);

export const referredTreatmentsInPhases = createSelector([treatmentOrReferredTreatmentPlanProcedures], (procedures) => {
    const isReferred = (procedure: IChartTreatmentPlanPhaseProcedure) =>
        procedure.type === ProcedureActionType.Referred && procedure.phaseId;
    return procedures.filter(isReferred);
});

export const preauthPendingPhasedProceduresFiltered = createSelector(
    [treatmentOrReferredTreatmentPlanProcedures],
    (procedures) => {
        return [...procedures.filter(isReferred), ...procedures.filter(isTreatment)]
            .filter(isPreAuth)
            .filter(isStatus)
            .filter((pre) => pre.preAuthorization != ChartProcedurePreAuth.NotRequired);
    },
);

export const declinedPhasedProcedures = createSelector([treatmentOrReferredTreatmentPlanProcedures], (procedures) => {
    return procedures.filter(isDeclined);
});

export const getTreatmentPlanFeeTotals = createSelector([calculatedTreatmentPlanProcedures], (procedures) => {
    const phasedPendingProcedures = procedures.filter((procedure) => procedure.status === ChartProcedureStatus.Pending);

    const patientFees = phasedPendingProcedures.map((procedure) => procedure.commonPatientFee);
    const insuranceFees = phasedPendingProcedures.map((procedure) => procedure.insuranceFee);
    const fees = phasedPendingProcedures.map((procedure) => procedure.ucrFee);

    const totalPatientFee = sum(patientFees);

    const feeTotal = sum(fees);
    const patientFeeTotal = totalPatientFee;
    const insuranceFeeTotal = sum(insuranceFees);

    return { feeTotal, insuranceFeeTotal, patientFeeTotal };
});
