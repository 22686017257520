import { IProgressIndicatorProps, ProgressIndicator } from '@fluentui/react';
import { EditPatientMenuItem } from 'components/EditPatientPanel';
import { usePatientId, useSelector, useTenantId } from 'hooks';
import { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    selectEditPatienIsNewPatient,
    selectEditPatient,
    selectEditPatientPastAppointments,
    selectEditPatientUpcomingAppointments,
} from 'state/slices/edit-patient/edit-patient.selectors';
import { getLocationsOfCare } from 'state/slices/scheduling/scheduling.slice';
import { getSlidingFeeProgramByPatientPercentage } from 'state/slices/tenant/sliding-fee.slice';
import BasicDetails from './BasicDetails/BasicDetails';
import Communication from './Communication/Communication';
import Contacts from './Contacts/Contacts';
import Finance, { UDSProps } from './Finance';
import Payers from './Financial/Payers';
import VisitHistory, { VisitHistoryProps } from './VisitHistory/VisitHistory';

type Props = {
    selectedPivotKey: EditPatientMenuItem;
};

function PatientDemographics({ selectedPivotKey }: Props): JSX.Element | null {
    const isNewPatient = useSelector(selectEditPatienIsNewPatient);
    const upcomingPatientAppointments = useSelector(selectEditPatientUpcomingAppointments);
    const pastEncounters = useSelector(selectEditPatientPastAppointments);
    const patient = useSelector(selectEditPatient);

    const tenantId = useTenantId();
    const patientId = usePatientId();
    const dispatch = useDispatch();

    useEffect(() => {
        if (patient && patientId) {
            const percentage = patient?.uds?.incomePercentage ? patient?.uds?.incomePercentage : 0;
            if (percentage)
                dispatch(
                    getSlidingFeeProgramByPatientPercentage({
                        tenantId,
                        patientId,
                        percentage,
                    }),
                );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patient?.id, tenantId, patientId, isNewPatient, patient?.uds?.incomePercentage, dispatch]);

    useEffect(() => {
        dispatch(getLocationsOfCare({ tenantId }));
    }, [tenantId, dispatch]);

    const MenuComponent: {
        [key: string]:
            | FunctionComponent
            | FunctionComponent<UDSProps>
            | FunctionComponent<IProgressIndicatorProps>
            | FunctionComponent<VisitHistoryProps>;
    } = {
        [EditPatientMenuItem.BasicDetails]: patient ? BasicDetails : ProgressIndicator,
        [EditPatientMenuItem.Finance]: Finance,
        [EditPatientMenuItem.Contacts]: Contacts,
        [EditPatientMenuItem.Payers]: Payers,
        [EditPatientMenuItem.VisitHistory]: VisitHistory,
        [EditPatientMenuItem.Communincation]: Communication,
    };

    const Component = MenuComponent[selectedPivotKey];

    return (
        <Component
            showDocuments={true}
            upcomingPatientAppointments={upcomingPatientAppointments}
            pastAppointments={pastEncounters}
            isNewPatient={isNewPatient}
        />
    );
}

export default PatientDemographics;
