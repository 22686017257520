import { Stack, Text } from '@fluentui/react';
import IProvider, { IProviderPeriod } from 'api/models/provider.model';

import { TModal } from 'components';
import { RouteParams } from 'interfaces/route-params';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectAllocations, selectProviderAppointments, selectSelectedLOC } from 'state/slices/scheduling/scheduling.selectors';
import { getProviderAppointments } from 'state/slices/scheduling/scheduling.slice';
import { selectEditPeriodExceptions, selectSelectedPeriod } from 'state/slices/settings/providers/providers.selectors';

import { openEmergencyExceptionModal } from 'state/slices/settings/settings.slice';
import { classicDateOnly } from 'utils/dateOnly';
import { isDateBetween } from 'utils/isDateBetween';

type Props = {
    isOpens: boolean;
    setIsOpen: (value: boolean) => void;
    provider: IProvider | undefined;
    onSave: (periods: IProviderPeriod) => void;
};

export default function EmergencyExceptionConfirmationModal({ isOpens, setIsOpen, provider, onSave }: Props): JSX.Element {
    const selectedEditPeriod = useSelector(selectSelectedPeriod);
    const filteredAppointment = useSelector(selectProviderAppointments);
    const editPeriodExceptions = useSelector(selectEditPeriodExceptions);
    const selectedLoc = useSelector(selectSelectedLOC);
    const { tenantId } = useParams<RouteParams>();
    const dispatch = useDispatch();
    useEffect(() => {
        if (provider) dispatch(getProviderAppointments({ tenantId, providerId: provider?.id }));
    }, [dispatch, tenantId, provider]);

    const providerAppointment = filteredAppointment
        .filter(
            (x) =>
                x.hygienistId === provider?.id ||
                x.registeredDentalAssistantId === provider?.id ||
                x.treatingProviderId === provider?.id,
        )
        .filter((x) => x.locationOfCareId === selectedLoc?.id)
        .filter((x) =>
            isDateBetween({
                dateToCheck: x.date ? classicDateOnly(x.date) : '',
                start: editPeriodExceptions?.startDate,
                end: editPeriodExceptions?.endDate,
            }),
        );

    const onConfirmSave = () => {
        if (selectedEditPeriod) onSave(selectedEditPeriod);
        setIsOpen(false);
        dispatch(openEmergencyExceptionModal(false));
    };

    return (
        <TModal
            title={'Warning'}
            modalProps={{
                isBlocking: false,
                isOpen: isOpens,
                onDismiss: () => setIsOpen(false),
            }}
            isDraggable
            mainButtons={[
                {
                    type: 'PrimaryButton',
                    text: 'Continue',
                    onClick: () => {
                        onConfirmSave();
                    },
                },
                {
                    type: 'DefaultButton',
                    text: 'Cancel',
                    onClick: () => setIsOpen(false),
                },
            ]}
        >
            <Stack styles={{ root: { padding: 10 } }} grow>
                <Text
                    variant="mediumPlus"
                    styles={{
                        root: {
                            color: 'red',
                        },
                    }}
                >
                    This emergency exception will effect {providerAppointment.length} scheduled appointment. Do you wish to apply
                    exceptions?
                </Text>
            </Stack>
        </TModal>
    );
}
