import { ContextualMenu, IContextualMenuItem, IContextualMenuProps } from '@fluentui/react';
import { ReportType } from 'api/models/embed-report.model';
import IPatientAppointment, {
    TrackerStatusIds,
    patientAppointmentStatusText,
} from 'api/models/Scheduling/patientAppointment.model';
import { formatISO } from 'date-fns';
import { map } from 'lodash';
import { KeyboardEvent, MouseEvent, useCallback } from 'react';
import { useDispatch, batch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { getPatientDocuments } from 'state/slices/documents/documents.actions';
import { getPatientToEditAndOpenPanel } from 'state/slices/edit-patient/edit-patient.actions';
import reportActionLookup from 'state/slices/reporting/reportActionLookup';
import {
    checkIn,
    getAppointmentData,
    updatePatientAppointmentProperty,
    withCheckOut,
} from 'state/slices/scheduling/scheduling.actions';
import { IPatientAppointmentView } from 'state/slices/scheduling/scheduling.selectors';
import { setCancelAppointmentModalOpen } from 'state/slices/scheduling/scheduling.slice';
import { AppointmentType } from 'state/slices/scheduling/scheduling.state';
import { TrackerStatus, trackerStatusText } from './TrackerStatusDropdown';
import { RouteParams } from 'interfaces/route-params';
import { getDisableCancelAppointment } from 'pages/components/CancelAppointmentModal/CancelAppointmentModal';

type TrackerStatusMenuItemsHookProps = {
    onClickSubMenuItem: (key: keyof IPatientAppointment, item: IContextualMenuItem | undefined) => void;
    encounterId?: string;
    trackerStatus?: TrackerStatusIds;
    patientId?: string;
    appointmentId?: string;
};

export function useTrackerStatusMenuItems({
    onClickSubMenuItem,
    encounterId,
    trackerStatus,
    appointmentId,
    patientId,
}: TrackerStatusMenuItemsHookProps): IContextualMenuItem[] {
    const { tenantId } = useParams<RouteParams>();
    const dispatch = useDispatch();
    const isCheckoutDisabled = !trackerStatus;
    const arrival = trackerStatus === TrackerStatus.Arrived;
    const checkedIn = trackerStatus === TrackerStatus.CheckIn;
    const ready = trackerStatus === TrackerStatus.Ready;
    const intake = trackerStatus === TrackerStatus.Intake;
    const exam = trackerStatus === TrackerStatus.Exam;
    const xray = trackerStatus === TrackerStatus.Xray;
    const prophy = trackerStatus === TrackerStatus.Prophy;
    const treatment = trackerStatus === TrackerStatus.Treatment;
    const readyForCheckout = trackerStatus === TrackerStatus.ReadyForCheckout;
    const dismissed = trackerStatus === TrackerStatus.Dismissed;
    const completed = trackerStatus === TrackerStatus.Completed;

    const noPatientAppointmentEncounter = !encounterId;

    const onWithCheckout = () => {
        if (patientId && appointmentId) dispatch(withCheckOut({ tenantId, patientId, appointmentId }));
    };

    return [
        {
            key: TrackerStatus.Arrived,
            text: trackerStatusText[TrackerStatus.Arrived],
            onClick: (ev, item) => onClickSubMenuItem('trackerStatusId', item),
            disabled: !isCheckoutDisabled && !checkedIn,
        },
        {
            key: TrackerStatus.CheckIn,
            text: trackerStatusText[TrackerStatus.CheckIn],
            onClick: (ev, item) => onClickSubMenuItem('trackerStatusId', item),
            disabled: !arrival && !checkedIn,
        },

        {
            key: TrackerStatus.Ready,
            text: trackerStatusText[TrackerStatus.Ready],
            onClick: (ev, item) => onClickSubMenuItem('trackerStatusId', item),
            disabled:
                isCheckoutDisabled ||
                checkedIn ||
                ready ||
                xray ||
                treatment ||
                prophy ||
                exam ||
                intake ||
                readyForCheckout ||
                dismissed ||
                arrival ||
                completed,
        },
        {
            key: TrackerStatus.Intake,
            text: trackerStatusText[TrackerStatus.Intake],
            onClick: (ev, item) => onClickSubMenuItem('trackerStatusId', item),

            disabled: !ready && !xray && !treatment && !prophy && !exam && !readyForCheckout,
        },
        {
            key: TrackerStatus.Exam,
            text: trackerStatusText[TrackerStatus.Exam],
            onClick: (ev, item) => onClickSubMenuItem('trackerStatusId', item),
            disabled: !ready && !xray && !treatment && !prophy && !intake && !readyForCheckout,
        },
        {
            key: TrackerStatus.Prophy,
            text: trackerStatusText[TrackerStatus.Prophy],
            onClick: (ev, item) => onClickSubMenuItem('trackerStatusId', item),
            disabled: !ready && !xray && !treatment && !exam && !intake && !readyForCheckout,
        },
        {
            key: TrackerStatus.Xray,
            text: trackerStatusText[TrackerStatus.Xray],
            onClick: (ev, item) => onClickSubMenuItem('trackerStatusId', item),
            disabled: !ready && !prophy && !treatment && !exam && !intake && !readyForCheckout,
        },
        {
            key: TrackerStatus.Treatment,
            text: trackerStatusText[TrackerStatus.Treatment],
            onClick: (ev, item) => onClickSubMenuItem('trackerStatusId', item),
            disabled: !ready && !xray && !prophy && !exam && !intake && !readyForCheckout,
        },
        {
            key: TrackerStatus.ReadyForCheckout,
            text: trackerStatusText[TrackerStatus.ReadyForCheckout],
            onClick: (ev, item) => onClickSubMenuItem('trackerStatusId', item),
            disabled: (!xray && !treatment && !prophy && !exam && !intake && !readyForCheckout) || noPatientAppointmentEncounter,
        },
        {
            key: TrackerStatus.Dismissed,
            text: trackerStatusText[TrackerStatus.Dismissed],
            onClick: (ev, item) => {
                onClickSubMenuItem('trackerStatusId', item);
                onWithCheckout();
            },
            disabled: isCheckoutDisabled || !dismissed || noPatientAppointmentEncounter,
        },
        {
            key: TrackerStatus.Completed,
            text: trackerStatusText[TrackerStatus.Completed],
            onClick: (ev, item) => onClickSubMenuItem('trackerStatusId', item),
            disabled: isCheckoutDisabled || !dismissed || !completed,
        },
    ];
}

export const useCheckinCheckout = () => {
    const dispatch = useDispatch();
    const { tenantId } = useParams<RouteParams>();

    const handleCheckinCheckout = useCallback(
        (status: TrackerStatusIds, appointment: IPatientAppointment | undefined) => {
            if (appointment) {
                if (status === TrackerStatus.CheckIn) {
                    dispatch(checkIn({ tenantId, patientId: appointment.patientId, appointmentId: appointment.id }));
                    return true;
                }

                if (status === TrackerStatus.ReadyForCheckout && appointment.trackerStatusId !== TrackerStatus.Dismissed) {
                    dispatch(withCheckOut({ tenantId, patientId: appointment.patientId, appointmentId: appointment.id }));
                    return true;
                }
            }
            return false;
        },
        [dispatch, tenantId],
    );

    return handleCheckinCheckout;
};

type Props = {
    appointment: IPatientAppointmentView;
} & Partial<IContextualMenuProps>;

export function CalendarEventContextMenu({ appointment, ...props }: Props): JSX.Element {
    const dispatch = useDispatch();
    const { push } = useHistory();

    const appointmentDate = appointment['date'];
    const today = formatISO(new Date(), { representation: 'date' });

    const { tenantId } = useParams<{ tenantId: string }>();

    const handleCheckinCheckout = useCheckinCheckout();

    const onClickSubMenuItem = async (key: keyof IPatientAppointment, item: IContextualMenuItem | undefined) => {
        if (item && appointment) {
            await dispatch(
                updatePatientAppointmentProperty({
                    tenantId,
                    appointment,
                    prop: key,
                    value: item.key,
                }),
            );

            handleCheckinCheckout(item.key as TrackerStatusIds, appointment);
        }
    };

    const trackerStatusMenuItems = useTrackerStatusMenuItems({
        trackerStatus: appointment.trackerStatusId,
        encounterId: appointment.encounterId,
        appointmentId: appointment.id,
        patientId: appointment.patientId,
        onClickSubMenuItem,
    });

    const appointmentStatusMenuItems: IContextualMenuItem[] = map(patientAppointmentStatusText, (text, key) => {
        const onClick = (_?: MouseEvent | KeyboardEvent, item?: IContextualMenuItem) => {
            if (item)
                dispatch(
                    updatePatientAppointmentProperty({
                        tenantId,
                        appointment,
                        prop: 'appointmentStatusId',
                        value: item.key,
                    }),
                );
        };

        return {
            key,
            text,
            onClick,
        };
    });

    const onEditProfile = () => {
        batch(() => {
            dispatch(getPatientToEditAndOpenPanel({ tenantId, patientId: appointment.patientId }));
            dispatch(getPatientDocuments({ tenantId, patientId: appointment.patientId }));
        });
    };

    const onPrintPatientRouteSlip = () => {
        dispatch(reportActionLookup[ReportType.PatientRouteSlip]({ tenantId, appointmentId: appointment.id }));
    };
    const _printWalkoutStatement = () => {
        if (appointment?.encounterId)
            dispatch(
                reportActionLookup[ReportType.Walkout]({
                    tenantId,
                    encounterId: appointment.encounterId,
                }),
            );
    };

    const onCancelAppointment = () => {
        dispatch(setCancelAppointmentModalOpen({ isOpen: true, appointmentToRemoveId: appointment.id }));
    };

    const onEditAppointment = () => {
        const { type, id } = appointment;
        dispatch(getAppointmentData(tenantId, id, type));
    };

    const disablePatientTracker = appointmentDate > today;

    const menuItems: IContextualMenuItem[] = [
        {
            key: 'appointment-status',
            text: 'Confirmation Status',
            subMenuProps: {
                items: appointment.type === AppointmentType.Patient ? appointmentStatusMenuItems : [],
            },
        },
        {
            key: 'tracker',
            text: 'Patient Tracker',
            disabled: disablePatientTracker,
            subMenuProps: {
                items: trackerStatusMenuItems,
            },
        },
        {
            key: 'edit-appt',
            text: 'Edit Appointment',
            onClick: onEditAppointment,
        },
        {
            key: 'edit-patient-profile',
            text: 'Edit Patient Profile',
            onClick: onEditProfile,
        },
        {
            key: 'navigate-to',
            text: 'Navigate To',
            subMenuProps: {
                items: [
                    {
                        key: 'charting',
                        text: 'Charting',
                        onClick: () => push(`/${tenantId}/patient/${appointment.patientId}/charting`),
                    },
                    {
                        key: 'treatment-plan',
                        text: 'Treatment Plan',
                        onClick: () => push(`/${tenantId}/patient/${appointment.patientId}/treatment-plans`),
                    },
                    {
                        key: 'patient-ledger',
                        text: 'Ledger',
                        onClick: () => push(`/${tenantId}/patient/${appointment.patientId}/ledger`),
                    },
                ],
            },
        },

        {
            key: 'print',
            text: 'Print',
            subMenuProps: {
                items: [
                    {
                        key: 'print-route-slip',
                        text: 'Route Slip',
                        onClick: onPrintPatientRouteSlip,
                    },
                    {
                        key: 'walkoutStatement',
                        text: 'Walkout Statement',
                        onClick: _printWalkoutStatement,
                    },
                ],
            },
        },

        {
            key: 'cancel-appointment',
            text: 'Cancel Appointment',
            onClick: onCancelAppointment,
            disabled: getDisableCancelAppointment(appointment?.encounterId, appointment?.trackerStatusId),
        },
    ];
    return <ContextualMenu {...props} items={menuItems} />;
}
