import { IProviderScheduleTimeBlock, IProviderPeriod } from 'api/models/provider.model';

export const providerSettingsInitalState: ProviderSettingsState = {
    editPeriods: [],
    openExceptionModal: false,
    openEmergencyModal: false,
};

//Period is the highest level, inside of that we have schedules and exceptions.
export interface ProviderSettingsState {
    editPeriods?: IProviderPeriod[];
    startDate?: string;
    endDate?: string;
    providerPeriodId?: string;
    openExceptionModal: boolean;
    openEmergencyModal: boolean;
    providerExceptionIndex?: number;
    isAddException?: boolean;
    editException?: IProviderScheduleTimeBlock;
}
//we change the boolean above to be a string => an id.
//We build a selector that returns true/false when the id exists.
