import { Stack, useTheme } from '@fluentui/react';
import IWorkListAppointmentView from 'api/models/worklist-appointment-view';
import IWorkListEncounterView from 'api/models/worklist-encounter-view';
import UserDisplayName from 'components/UserDisplayName';
import PanelSectionHeader from 'pages/components/PanelSectionHeader';
import { getBannerCardStyles } from 'pages/components/PatientDetailsCard';
import { useSelector } from 'react-redux';
import { selectWorkListEditItem } from 'state/slices/admin-huddle/worklists/worklist.selectors';
import { getTimeTextFromValue } from 'utils/getTimeOptions';
import BannerInfoDetailItem from './VisitSummaryEncounterDate';
import { classicDateOnly } from 'utils/dateOnly';

export const isVisitSummaryWorklistEncounterView = (
    item?: IWorkListEncounterView | IWorkListAppointmentView,
): item is IWorkListEncounterView => {
    return !!(item as IWorkListEncounterView)?.appointments;
};

export default function VisitSummaryAppointments() {
    const selectedView = useSelector(selectWorkListEditItem<IWorkListEncounterView | IWorkListAppointmentView>);

    const appointments = isVisitSummaryWorklistEncounterView(selectedView) ? (
        selectedView?.appointments?.map((appt) => (
            <VisitSummaryAppointmentCard
                key={`appt-${appt.id}`}
                treatingProviderId={appt.treatingProviderId}
                encounterReason={appt.encounterReason}
                appointmentStartTime={appt.startTime}
                appointmentDate={appt.date}
            />
        ))
    ) : (
        <VisitSummaryAppointmentCard
            appointmentStartTime={selectedView?.startTime}
            encounterReason={selectedView?.encounterReason}
            treatingProviderId={selectedView?.appointmentTreatingProviderId}
            appointmentDate={selectedView?.date?.toString() ?? ''}
        />
    );

    return (
        <Stack tokens={{ childrenGap: 10 }} grow>
            <PanelSectionHeader text={'Appointment Information'} />
            <Stack
                styles={{ root: { minHeight: 119, maxHeight: 240, overflowY: 'auto', overflowX: 'hidden' } }}
                tokens={{ childrenGap: 20 }}
                horizontal
                wrap
                grow
            >
                {appointments}
            </Stack>
        </Stack>
    );
}

type VisitSummaryAppointmentCardProps = {
    encounterReason: string | undefined;
    appointmentStartTime: string | undefined;
    treatingProviderId: string | undefined;
    appointmentDate: string;
};

function VisitSummaryAppointmentCard({
    appointmentDate,
    appointmentStartTime,
    treatingProviderId,
    encounterReason,
}: VisitSummaryAppointmentCardProps) {
    const { palette } = useTheme();
    return (
        <Stack style={{ padding: 8 }} styles={getBannerCardStyles(palette)}>
            <Stack styles={{ root: { justifyContent: 'space-between' } }} horizontal tokens={{ childrenGap: 10 }}>
                <BannerInfoDetailItem label="Appt. Date" text={classicDateOnly(appointmentDate) ?? 'Unknown Date'} />
                <BannerInfoDetailItem
                    label="Appt. Start Time"
                    text={getTimeTextFromValue(appointmentStartTime) ?? 'Unknown Time'}
                />
            </Stack>
            <Stack
                styles={{
                    root: { paddingTop: 5, marginTop: '5px !important', borderTop: `1px solid ${palette.neutralTertiaryAlt}` },
                }}
                horizontal
                tokens={{ childrenGap: 10 }}
            >
                <BannerInfoDetailItem label="Encounter Reason" text={encounterReason ?? 'Unknown Reason'} />
                <BannerInfoDetailItem label="Treating Provider" text={<UserDisplayName userId={treatingProviderId} />} />
            </Stack>
        </Stack>
    );
}
