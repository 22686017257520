import React, { ReactNode } from 'react';
import { Stack, StackItem, Text, useTheme } from '@fluentui/react';
import { ActionButton } from 'components';
import { IActionButton } from './ActionButton';

type Props = {
    heading: string | JSX.Element;
    style?: React.CSSProperties;
    sectionButtons?: IActionButton[];
    headingCenterContent?: ReactNode;
    headingRightContent?: ReactNode;
};

const ActivitySubHeading = (props: Props): JSX.Element => {
    const theme = useTheme();
    const headerProperties: React.CSSProperties = {
        padding: '5px 10px 0 10px',
        backgroundColor: theme.palette.neutralLighterAlt,
        display: 'flex',
        alignItems: 'center',
        minHeight: 50,
    };

    const _hasSectionButtons = props.sectionButtons && props.sectionButtons.length;
    const _sectionButtons = _hasSectionButtons
        ? props.sectionButtons?.map((button, index) => {
              return <ActionButton {...button} key={index} />;
          })
        : null;

    return (
        <Stack style={{ ...headerProperties }} horizontal tokens={{ childrenGap: 20 }}>
            <StackItem>
                <Text variant="large" style={{ ...props.style }} block>
                    {props.heading}
                </Text>
            </StackItem>
            <StackItem grow={1}>
                <Stack>{props.headingCenterContent}</Stack>
            </StackItem>
            <StackItem>
                <Stack horizontal style={{ justifyContent: 'flex-end', display: 'flex' }} tokens={{ childrenGap: 10 }}>
                    {_sectionButtons}
                    {props.headingRightContent}
                </Stack>
            </StackItem>
        </Stack>
    );
};

export default ActivitySubHeading;
