import { IChartCondition } from 'api/models/chart.model';
import { ICondition } from 'api/models/lookup.model';
import ConditionConflictRuleGenerator from './conditionConflictRuleGenerator';
import * as conflictRules from './conditionConflictRules';
import Pipeline from './pipeline';

type PipelineArgs = {
    chartConditions: IChartCondition[];
    currentConditions: IChartCondition[];
    conditions: ICondition[];
    selectedTeeth: number[];
};

export default class ConditionConflictRulesPipeline extends Pipeline<IChartCondition> {
    public selectedTeeth: number[] = [];
    public conditions: ICondition[];
    public currentConditions: IChartCondition[];

    constructor({ conditions, chartConditions, selectedTeeth, currentConditions }: PipelineArgs) {
        super(chartConditions);
        this.selectedTeeth = selectedTeeth;
        this.conditions = conditions;
        this.currentConditions = currentConditions;

        this.generateValidConditions();
    }

    public getConditionsWithSameProcedureId(condition: IChartCondition): IChartCondition[] {
        return this.currentConditions.filter((p) => p.conditionId === condition.conditionId);
    }

    protected _chartHasCondition(condition: IChartCondition): boolean {
        return this.currentConditions.findIndex((c) => c.conditionId === condition.conditionId) > -1;
    }

    public getCondition(chartCondition: IChartCondition): ICondition | undefined {
        return this.conditions.find((p) => p.id === chartCondition.conditionId);
    }

    private _getValidConditionForSelectedTeeth(chartCondition: IChartCondition) {
        const conditionResult = new ConditionConflictRuleGenerator({
            conditionPipeline: this,
            chartCondition,
            rules: conflictRules,
        });
        this.addErrors(conditionResult.getErrors);
        if (!conditionResult.shouldRemoveItem) return conditionResult.getItem;
    }

    private generateValidConditions() {
        if (this.items && this.selectedTeeth.length) {
            const conditions: IChartCondition[] = [];
            this.items.forEach((chartProcedure: IChartCondition) => {
                const newCondition = this._getValidConditionForSelectedTeeth(chartProcedure);
                if (newCondition) conditions.push(newCondition as IChartCondition);
            });
            this.setItems(conditions);
        }
    }
}
