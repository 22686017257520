import Front from './Front';
import Tooth from './Tooth';
import * as PIXI from 'pixi.js-legacy';
import ToothOverlay from './ToothOverlay';
import { IChartRenderProcedure } from 'api/models/tooth.model';

type EquilateralTriangleParams = {
    x: number;
    y: number;
    lineLength: number;
    rotation?: number;
};

//removal code: D2955
export default class PostCore extends ToothOverlay {
    private rootLine = new PIXI.Graphics();
    private triangle = new PIXI.Graphics();

    constructor(tooth: Tooth, front: Front, procedure: IChartRenderProcedure) {
        super(tooth);
        this.setScaleToTooth();
        this._setColor(procedure);
        this.createGraphics(front);
    }

    private _createEquilateralTriangle({ x, y, lineLength, rotation }: EquilateralTriangleParams): PIXI.Graphics {
        const triangle = new PIXI.Graphics();

        const tan = Math.tan(Math.PI / 4);
        const rightYPoint = y + tan * lineLength;
        const rightXPoint = x + tan * lineLength;
        const rightPoint = [rightXPoint, rightYPoint];
        const bottomPoint = [rightXPoint - lineLength * 2, rightYPoint];
        const firstPoint = [x, y];

        triangle.beginFill(this.color);
        triangle.drawPolygon([...firstPoint, ...rightPoint, ...bottomPoint, ...firstPoint]);
        triangle.endFill();
        if (rotation) triangle.rotation = rotation;
        return triangle;
    }

    public createGraphics(front: Front): void {
        const lineLength = 100;
        const fromY = this.getIsBottomRow ? this.tooth.height / this.scale.y : (front.y - front.height / 2 + 50) / this.scale.y;
        const toY = this.getIsBottomRow ? -lineLength / this.scale.y : lineLength / this.scale.y;

        const x = this.tooth.getToothWidth / 2 / this.scale.y;

        this.rootLine.position.set(x, fromY);
        this.rootLine.lineStyle(6, this.color).lineTo(0, toY);

        this.triangle = this._createEquilateralTriangle({
            x: !this.getIsBottomRow ? x : -x,
            y: !this.getIsBottomRow ? fromY + toY - 5 : -fromY - toY - 5,
            lineLength: 19,
            rotation: this.getIsBottomRow ? Math.PI : 0,
        });

        this.addChild(this.rootLine, this.triangle);
    }
}
