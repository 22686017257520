import { Checkbox, IconButton, Stack, TooltipHost } from '@fluentui/react';
import { IPatientInsurance } from 'api/models/patient.model';
import ConfirmationModal from 'components/ConfirmationModal';
import IUserTask from 'api/models/user-task.model';
import { ContentCard, SubSection } from 'components';
import UserTasksBadge from 'components/UserTasks/UserTasksBadge';
import { format, isAfter, isBefore } from 'date-fns';
import { useSelector } from 'hooks';
import TaskFieldLabel from 'pages/Scheduling/components/Checkin/TaskFieldLabel';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { selectEditPatient, selectEditPatientActiveInsurances } from 'state/slices/edit-patient/edit-patient.selectors';
import { saveInsurance } from 'state/slices/edit-patient/edit-patient.slice';
import { selectSelectedAppointmentData } from 'state/slices/scheduling/scheduling.selectors';
import { updatePatientAppointmentProp } from 'state/slices/scheduling/scheduling.slice';
import { TaskType } from 'state/task-management/taskManagement.actions';
import { selectPatientAppointmentBasicInfoByTaskTypeLookup } from 'state/task-management/taskManagement.slice';
import { v4 as uuid } from 'uuid';
import EligibilityModal from './EligibilityModal';
import ExpireModal from './ExpireModal';
import InsuranceCardViews from './InsuranceCardViews';
import InsuranceModal from './InsuranceModal';

interface IInsuranceProps {
    isCheckinOrCheckout?: boolean;
}

function Insurance({ isCheckinOrCheckout }: IInsuranceProps): JSX.Element {
    const dispatch = useDispatch();

    const patient = useSelector(selectEditPatient);

    const [insurance, setInsurance] = useState<IPatientInsurance>();

    const [showInsuranceModal, setShowInsuranceModal] = useState<boolean>(false);
    const [showEligibilityModal, setShowEligibilityModal] = useState<boolean>(false);
    const [showExpireModal, setShowExpireModal] = useState<boolean>(false);

    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
    const [confirmationModalProps, setConfirmationModalProps] = useState<{
        text: string;
        title: string;
        action: 'delete' | 'activate';
    }>();

    const [isNew, setIsNew] = useState(false);

    const onConfirm = () => {
        if (insurance) {
            dispatch(saveInsurance({ ...insurance, isDeleted: confirmationModalProps?.action === 'delete' }));
            setShowConfirmationModal(false);
        }
    };

    const expireInsurance = (insurance: IPatientInsurance) => {
        setShowExpireModal(true);
        setInsurance(insurance);
        setIsNew(false);
    };

    const activatePatientInsurance = (insurance: IPatientInsurance) => {
        setShowConfirmationModal(true);
        setConfirmationModalProps({
            title: 'Activate Insurance Confirmation',
            text: 'Are you sure you want to ACTIVATE this insurance for the patient? Confirm or Cancel.',
            action: 'activate',
        });
        setInsurance(insurance);
        setIsNew(false);
    };

    const deletePatientInsurance = (insurance: IPatientInsurance) => {
        setShowConfirmationModal(true);
        setConfirmationModalProps({
            title: 'Delete Insurance Confirmation',
            text: "Are you sure you want to DELETE this insurance from the patient's record? Confirm or Cancel.",
            action: 'delete',
        });
        setInsurance(insurance);
        setIsNew(false);
    };

    const editPatientInsurance = (insurance: IPatientInsurance) => {
        setShowInsuranceModal(true);
        setInsurance(insurance);
        setIsNew(false);
    };

    const editPatientInsuranceEligibility = (insurance: IPatientInsurance) => {
        setShowEligibilityModal(true);
        setInsurance(insurance);
        setIsNew(false);
    };

    const activeInsurances = useSelector(selectEditPatientActiveInsurances);
    const _currentAppointment = useSelector(selectSelectedAppointmentData);
    const _lookupPatientAppointmentBasicDetailTaskByType = useSelector(selectPatientAppointmentBasicInfoByTaskTypeLookup);

    const disableAddInsurance = (activeInsurances?.length ?? 0) >= 4;

    const addInsuranceType = () => {
        if (activeInsurances.length) {
            if (activeInsurances.length < 2) {
                return ' Add Secondary';
            }
            if (activeInsurances.length < 3) {
                return 'Add Tertiary';
            }
            if (activeInsurances.length < 4) {
                return 'Add Quaternary';
            }
            if (activeInsurances.length < 5) {
                return 'Please deactivate an insurance to add another';
            }
        }
        return 'Add Primary';
    };

    return (
        <>
            <SubSection
                title="Insurance"
                headingCenterContent={
                    <Stack horizontal horizontalAlign="space-between">
                        <Stack.Item>
                            <TooltipHost content={addInsuranceType()}>
                                <IconButton
                                    iconProps={{ iconName: 'Add' }}
                                    onClick={() => {
                                        setInsurance(getNewInsurance());
                                        setShowInsuranceModal(true);
                                        setIsNew(true);
                                    }}
                                    disabled={disableAddInsurance}
                                />
                            </TooltipHost>
                        </Stack.Item>
                    </Stack>
                }
            >
                <Stack tokens={{ childrenGap: 10 }}>
                    <InsuranceCardViews
                        deleteInsurance={deletePatientInsurance}
                        expireInsurance={expireInsurance}
                        editInsurance={editPatientInsurance}
                        editEligibility={editPatientInsuranceEligibility}
                        activateInsurance={activatePatientInsurance}
                    />
                </Stack>
            </SubSection>
            {_lookupPatientAppointmentBasicDetailTaskByType[TaskType.insuranceVerification] && isCheckinOrCheckout && (
                <Checkbox
                    label={`Verify insurance${_currentAppointment?.lastVerifiedInsurance
                            ? ` (${format(new Date(_currentAppointment.lastVerifiedInsurance), 'MM/dd/yyyy h:mm a')})`
                            : ''
                        }`}
                    onRenderLabel={(data) => (
                        <TaskFieldLabel
                            label={data?.label}
                            lookup={_lookupPatientAppointmentBasicDetailTaskByType}
                            taskType={TaskType.insuranceVerification}
                        />
                    )}
                    onChange={() => {
                        dispatch(
                            updatePatientAppointmentProp({
                                path: 'lastVerifiedInsurance',
                                value: !_currentAppointment?.lastVerifiedInsurance ? new Date().toISOString() : undefined,
                            }),
                        );
                    }}
                    checked={!!_currentAppointment?.lastVerifiedInsurance}
                />
            )}
            <ExpireModal setIsOpen={setShowExpireModal} insurance={insurance} isOpen={showExpireModal} />
            <ConfirmationModal
                confirmationMessage={confirmationModalProps?.text ?? ''}
                onContinue={onConfirm}
                title={confirmationModalProps?.title ?? ''}
                isOpen={showConfirmationModal}
                confirmButtonText="Confirm"
                onDismiss={() => setShowConfirmationModal(false)}
            />
            <EligibilityModal
                setInsurance={setInsurance}
                insurance={insurance}
                patient={patient}
                setShowEligibilityModal={setShowEligibilityModal}
                showEligibilityModal={showEligibilityModal}
            />
            <InsuranceModal
                setInsurance={setInsurance}
                insurance={insurance}
                patient={patient}
                showInsuranceModal={showInsuranceModal}
                setShowInsuranceModal={setShowInsuranceModal}
                isNew={isNew}
            />
        </>
    );
}

function getNewInsurance(): IPatientInsurance {
    return {
        id: uuid(),
        createdOn: '',
        createdBy: '',
        modifiedOn: '',
        modifiedBy: '',
        isDeleted: false,
        insuranceId: '',
        planId: '',
        memberId: '',
        groupNumber: '',
        effectiveDate: '',
        expirationDate: '',
        allocationSet: '',
        type: '',
        policyHolderType: '',
        employer: '',
        policyHolder: {
            id: uuid(),
            lastName: '',
            firstName: '',
            middleName: '',
            chosenName: '',
            suffix: '',
            title: '',
            dateOfBirth: '',
            sexAtBirthId: '',
            ssn: '',
            relationship: '',
            policyHolderAddressSameAsPatient: false,
            streetAddress1: '',
            city: '',
            state: '',
            zip: '',
        },
        eligibility: {},
        documents: [],
    };
}

export default Insurance;
