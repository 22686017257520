import { IContextualMenuItem, Stack, ContextualMenu, ContextualMenuItemType, mergeStyles } from '@fluentui/react';
import { useId } from '@uifabric/react-hooks';
import IPatientAppointment, { TrackerStatusIds } from 'api/models/Scheduling/patientAppointment.model';
import IWorkListAppointmentView from 'api/models/worklist-appointment-view';
import schedulingApi from 'api/scheduling.api';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import { useSelector, useTenantId } from 'hooks';
import { TrackerStatusLink } from 'pages/Scheduling/components/CalendarEvent';
import { useCheckinCheckout, useTrackerStatusMenuItems } from 'pages/Scheduling/components/CalendarEventContextMenu';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateWorkListAppointmentData } from 'state/slices/admin-huddle/worklists/appointment-worklists/appointment-worklists.actions';
import { selectCurrentWorkList } from 'state/slices/admin-huddle/worklists/worklist.selectors';
import { setSelectedWorkListEditItem } from 'state/slices/admin-huddle/worklists/worklist.slice';

const appointmentTrackerStatus: ISortableColumn<IWorkListAppointmentView> = {
    key: 'appointmentTrackerStatus',
    minWidth: 135,
    maxWidth: 135,
    isResizable: true,
    name: 'Pt. Tracker Status',
    fieldName: 'trackerStatus',
    onRender: (item) => <AppointmentTrackerStatus item={item} />,
};

export const appointmentTrackerStatusReadOnly: ISortableColumn<IWorkListAppointmentView> = {
    key: 'appointmentTrackerStatus',
    minWidth: 135,
    maxWidth: 135,
    isResizable: true,
    name: 'Pt. Tracker Status',
    fieldName: 'trackerStatus',
    onRender: (item) => <AppointmentTrackerStatus item={item} readOnly />,
};

function AppointmentTrackerStatus({ item, readOnly }: { item?: IWorkListAppointmentView; readOnly?: boolean }) {
    const dispatch = useDispatch();
    const tenantId = useTenantId();
    const [showContextMenu, setShowContextMenu] = useState<boolean>(false);
    const appointmentTrackerStatusComponentId = useId();

    const workList = useSelector(selectCurrentWorkList);

    const handleCheckinCheckout = useCheckinCheckout();

    async function onClickSubMenuItem(key: keyof IPatientAppointment, contextItem: IContextualMenuItem | undefined) {
        if (contextItem && item) {
            const { data: appointment } = await schedulingApi.getPatientAppointment(tenantId, item.id);
            if (appointment) {
                if (workList) {
                    const updatedAppointment: IPatientAppointment = {
                        ...appointment,
                        [key]: contextItem.key,
                    };

                    dispatch(
                        updateWorkListAppointmentData({
                            tenantId,
                            workList,
                            appointment: updatedAppointment,
                            recordId: updatedAppointment.id,
                        }),
                    );

                    const isCheckinInOrOut = handleCheckinCheckout(contextItem.key as TrackerStatusIds, updatedAppointment);
                    if (isCheckinInOrOut) dispatch(setSelectedWorkListEditItem(item));
                }
            }
        }
    }

    const menuItems = useTrackerStatusMenuItems({
        encounterId: item?.encounterId,
        trackerStatus: item?.trackerStatus,
        onClickSubMenuItem,
    });

    const columnStyles = mergeStyles({
        ':hover': {
            cursor: readOnly ? undefined : 'pointer',
        },
    });

    if (!item?.patientId) return null;

    return (
        <Stack
            onContextMenu={(e) => {
                e.preventDefault();
                if (!readOnly) setShowContextMenu(true);
            }}
            className={columnStyles}
            title={!readOnly ? 'Right click to change tracker status.' : undefined}
            id={appointmentTrackerStatusComponentId}
        >
            <TrackerStatusLink
                onLinkClick={() => {
                    dispatch(setSelectedWorkListEditItem(item));
                }}
                appointmentId={item.id}
                patientId={item.patientId}
                trackerStatusId={item.trackerStatus}
                disabled={readOnly}
            />
            {!item.trackerStatus ? '(None)' : ''}
            {!readOnly && (
                <ContextualMenu
                    target={`#${appointmentTrackerStatusComponentId}`}
                    hidden={!showContextMenu}
                    onDismiss={() => setShowContextMenu(false)}
                    items={[
                        { key: 'Header', itemType: ContextualMenuItemType.Header, text: 'Tracker Status' },
                        { key: 'Divider', itemType: ContextualMenuItemType.Divider },
                        ...menuItems,
                    ]}
                />
            )}
        </Stack>
    );
}

export default appointmentTrackerStatus;
