import { RootState } from 'state/store';
import { IWorkListsState } from './worklist.state';
import { createSelector } from 'reselect';
import { isArray, isEmpty, isEqual, map } from 'lodash';
import { EditEncounterWorkList, EncounterWorkList } from './encounter-worklists/encounter-worklists.state';

/**Returns entire worklist state. */
export const selectWorkListState = (state: RootState): IWorkListsState => state.adminHuddle.workLists as IWorkListsState;

// Work list data
/**Returns all worklist currently data visible to the user. */
export const selectWorkListData = <T>(state: RootState) => (state.adminHuddle.workLists.data ?? []) as T[];

// Currently selected work list
/**Returns the current worklist being viewed by a user. */
export const selectCurrentWorkList = createSelector(selectWorkListState, (state) => state.currentWorkList);
/**Returns a list of continuation tokens. Null tokens are filtered. */
export const selectWorkListContinuationTokens = createSelector(selectWorkListState, (state) =>
    (state.continuationTokens ?? []).filter((token) => token !== null),
);
/**Returns open layers state object. Worklist layers are modals/panels controlled by the worklist state. Open layers will contain a record of layers and whether the layer is open. */
export const selectWorkListOpenLayers = createSelector(selectWorkListState, (state) => state.openLayers);

/**Returns true if the current worklist is an edit encounter worklist (Currently only visible to global-admin users) */
export const selectIsCurrentWorkListEditEncounterWorkList = createSelector(selectCurrentWorkList, (workList) =>
    workList ? !!EditEncounterWorkList[workList as EditEncounterWorkList] : false,
);
/**Returns true if the current worklist is a post billed (Now post approved) worklist. */
export const selectIsPostBilledWorkList = createSelector(
    selectCurrentWorkList,
    selectIsCurrentWorkListEditEncounterWorkList,
    (workList, editWorklist) =>
        editWorklist ||
        (workList
            ? workList !== EncounterWorkList.ReadyToReview &&
            workList !== EncounterWorkList.ReadyToReviewOnHold &&
            !!EncounterWorkList[workList as EncounterWorkList]
            : false),
);
/**Returns a list of the recently viewed worklist items. */
export const selectRecentWorkListItems = createSelector(selectWorkListState, (state) => state.recentWorkListItems ?? []);
/**Returns the loading status of getting a recent worklist item. */
export const selectRecentWorkListItemLoading = createSelector(selectWorkListState, (state) => state.loadingRecentWorkListItem);

// Selected items
/**Returns a list of the selected worklist items. */
export const selectSelectedItemsWorkList = <T>(state: RootState) =>
    (state.adminHuddle.workLists.selectedWorkListItems ?? []) as T[];

// Edit encounter state
/**Returns the current worklist item being edited. */
export const selectWorkListEditItem = <T>(state: RootState) => state.adminHuddle.workLists.editWorkListItem as T | undefined;

// Filters
/**Returns the entire worklist filters record state. */
export const selectWorkListFilters = <T = Record<string, unknown>>(state: RootState) =>
    (state.adminHuddle.workLists.filters ?? {}) as T;
export const selectWorklistInitialFilters = <T = Record<string, unknown>>(state: RootState) =>
    (state.adminHuddle.workLists.initialFilters ?? {}) as T;

// Pagination
/**Returns the current page number the user is viewing */
export const selectWorkListCurrentPageNumber = createSelector(selectWorkListState, (state) => state.currentPageNumber);
/**Returns the worklist items per page. Currently limited to options of 25/50 items */
export const selectWorkListItemsPerPage = createSelector(selectWorkListState, (state) => state.itemsPerPage);
/**Returns the total items across all pages. */
export const selectWorkListTotalItems = createSelector(selectWorkListState, (state) => state.totalItems ?? 0);
/**Returns the loading status of total items across all pages. */
export const selectWorkListTotalItemsLoading = createSelector(selectWorkListState, (state) => state.loadingTotalItems);
/**Returns the total number of pages for the currently loaded worklist. */
export const selectWorkListPageCount = createSelector(
    selectWorkListState,
    selectWorkListItemsPerPage,
    selectWorkListTotalItems,
    //If total items is not falsy, then do math to figure out the number of pages, otherwise use page count (Page count is for non-view worklists only).
    (state, itemsPerPage, totalItems) => (totalItems ? Math.ceil(totalItems / itemsPerPage) : state.pageCount),
);

// Booleans

/**Returns true if the worklist is read only. */
export const selectWorkListReadOnly = createSelector(selectWorkListState, (state) => state.readOnly);
/**Returns true if the user has searched with filters for the current worklist */
export const selectWorkListHasSearched = createSelector(selectWorkListState, (state) => state.hasSearched);
/**Returns true if any filters have been populated in state */
export const selectWorkListHasFilters = createSelector(
    selectWorkListFilters,
    selectWorklistInitialFilters,
    (filters, initialFilters) => {
        if (!filters || isEmpty(filters) || isEqual(filters, initialFilters)) return false;
        const filtersMapped = map(filters as Record<string, unknown>, (prop) =>
            isArray(prop) ? !!prop.length : prop !== undefined,
        ).filter((item) => !!item);
        return !!filtersMapped.length;
    },
);

// Loading/Saving
/**Returns the current worklist loading state for getting worklist data */
export const selectWorkListLoading = createSelector(selectWorkListState, (state) => state.loading);
/**Returns the current worklist saving state for saving a worklist data item */
export const selectWorkListSaving = createSelector(selectWorkListState, (state) => state.saving);

// Message Bar
/**Returns the worklist message bar state. Includes message and messageType. */
export const selectWorkListMessageBar = createSelector(selectWorkListState, (state) => ({
    message: state.messageBarMessage,
    messageBarType: state.messageBarType,
}));

/**Returns detail data state. An object which contains the financial data about each worklist. */
export const selectWorkListDetailData = createSelector(selectWorkListState, (state) => state.detailData);
export const selectWorkListDetailDataLoading = createSelector(selectWorkListState, (state) => state.detailDataLoading);

// Confirmation modal selectors.
/**Confirmation modal type is based on the status of an encounter currently. Determines what message the confirmation modal displays as text and what action is fired on confirm. */
export const selectWorkListConfirmationModalType = createSelector(selectWorkListState, (state) => state.confirmationModalType);
/**Returns true if the confirmation modal is open, and false if the modal is closed. */
export const selectWorkListConfirmationModalIsOpen = createSelector(
    selectWorkListState,
    (state) => state.confirmationModalIsOpen,
);
