import { AnyAction } from '@reduxjs/toolkit';

import { IChartCondition, IChartProcedure } from 'api/models/chart.model';
import { ICondition } from 'api/models/lookup.model';
import { ToothArea } from 'api/models/tooth-area';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from 'state/store';

import { chartingActions } from '../chart/chart.slice';
import { IChartAction } from '../chartActionsList.pipeline';
import { createChartConditions, updateChartCondition } from '../conditions/conditions.actions';

export type EditConditionPanelPayload = {
    condition: ICondition;
    chartCondition: IChartCondition;
    areas?: (keyof typeof ToothArea)[];
    date?: string;
    note?: string;
};

export type ConditionToEdit = IChartProcedure & {
    condition: ICondition;
};

export const handleConditionPanelSave =
    (tenantId: string, patientId: string, condition: IChartCondition[], encounterId?: string) =>
    (dispatch: ThunkDispatch<unknown, unknown, AnyAction>, getState: () => RootState): void => {
        const { isEditing, selectedChartCondition, note } = getState().charting.conditionPanel;
        const encounterConditions = condition.map((p) => ({ ...p, encounterId, note }));
        if (isEditing && selectedChartCondition) {
            const newChartConditions: IChartCondition = {
                ...selectedChartCondition,
                ...encounterConditions[0],
                id: selectedChartCondition.id,
            };
            dispatch(updateChartCondition({ tenantId, patientId, condition: newChartConditions }));
        } else {
            dispatch(createChartConditions({ tenantId, patientId, conditions: encounterConditions }));
        }
    };

export const handleEditCondition =
    (chartAction: IChartAction) =>
    (dispatch: ThunkDispatch<unknown, unknown, AnyAction>, getState: () => RootState): void => {
        const state = getState();

        const { data: conditions } = state.tenant.conditions;
        const { data: chartConditions } = state.charting.conditions;

        if (chartAction.actionType === 'Condition') {
            const chartCondition = chartConditions.find((c) => c.id === chartAction.id);
            if (conditions && chartCondition?.conditionId) {
                const condition = conditions[chartCondition.conditionId];
                if (condition) {
                    const payload: EditConditionPanelPayload = {
                        condition,
                        chartCondition,
                        areas: chartCondition.areas,
                        date: chartCondition.createdOn,
                        note: chartCondition.note,
                    };

                    dispatch(chartingActions.setConditionPanelEditItem(payload));
                }
            }
        }
    };
