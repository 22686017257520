import { Dropdown, IDropdownOption, Stack, TextField } from '@fluentui/react';
import { TModal } from 'components';
import { usePatientId, useTenantId, useValidation } from 'hooks';
import { getValidationError } from 'hooks/useValidation';
import { BatchField } from 'pages/components/Batch/BatchField';
import { FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    cleanupConfirmNegativeReversal,
    setNegativePaymentReversalId,
    setNegativeReversalNote,
    setPaymentHistoryConfirmModalOpen,
} from 'state/slices/ledger/ledger.slice';
import { reverseSelectedPaymentHistoryPaymentSource } from 'state/slices/ledger/payment-history/payment-history.actions';
import {
    selectNegativeReversalsAsList,
    selectNegativeReversalSaving,
    selectPaymentHistoryConfirmModalOpen,
    selectPaymentHistorySelectedPaymentSource,
    selectSelectedPaymentHistoryNegativeReversalNote,
    selectSelectedPaymentHistoryNegativeReversalReasonId,
} from 'state/slices/ledger/payment-history/payment-history.selectors';
import { selectSelectedBatch } from 'state/slices/tenant/batches.slice';

export default function PaymentHistoryReversalConfirmation() {
    const dispatch = useDispatch();
    const tenantId = useTenantId();
    const patientId = usePatientId();
    const _isOpen = useSelector(selectPaymentHistoryConfirmModalOpen);
    const reversalReasonId = useSelector(selectSelectedPaymentHistoryNegativeReversalReasonId);
    const reversalNote = useSelector(selectSelectedPaymentHistoryNegativeReversalNote);
    const saving = useSelector(selectNegativeReversalSaving);

    const negativeReversalReasons = useSelector(selectNegativeReversalsAsList);
    const selectedPaymentSource = useSelector(selectPaymentHistorySelectedPaymentSource);

    const selectedBatch = useSelector(selectSelectedBatch);

    const _onChangeReversalReason = (e?: FormEvent, option?: IDropdownOption) => {
        if (option) dispatch(setNegativePaymentReversalId(option.key as string));
    };
    const _onChangeReversalNote = (e?: FormEvent, value?: string | undefined) => {
        dispatch(setNegativeReversalNote(value));
    };

    const _onDismiss = () => {
        dispatch(setPaymentHistoryConfirmModalOpen(false));
    };

    const onSubmit = () => {
        if (patientId && selectedPaymentSource && selectedBatch)
            dispatch(
                reverseSelectedPaymentHistoryPaymentSource({
                    tenantId,
                    patientId,
                    batchId: selectedBatch.id,
                    dateOfEntry: selectedBatch.dateOfEntry,
                    paymentSource: selectedPaymentSource,
                }),
            );
    };

    const [errors, submit, cleanupValidation] = useValidation(
        [
            {
                fieldName: 'Reversal Reason',
                validation: ['required'],
                value: reversalReasonId,
            },
            {
                fieldName: 'Batch',
                validation: ['required'],
                value: selectedBatch,
            },
            {
                fieldName: 'Notes',
                validation: ['characterLimit'],
                value: reversalNote,
                itemOptions: {
                    characterLimit: 150,
                },
            },
        ],
        onSubmit,
    );

    const _onDismissed = () => {
        //clear the selected history reversal.
        cleanupValidation();
        dispatch(cleanupConfirmNegativeReversal());
    };

    return (
        <TModal
            title="Payment Reversal"
            modalProps={{ isOpen: _isOpen, onDismiss: _onDismiss, onDismissed: _onDismissed }}
            isDraggable
            mainButtons={[
                {
                    type: 'PrimaryButton',
                    text: 'Save',
                    onClick: submit,
                    disabled: saving,
                },
                {
                    text: 'Cancel',
                    onClick: _onDismiss,
                },
            ]}
        >
            <Stack styles={{ root: { padding: 10 } }}>
                <BatchField error={errors} textfieldProps={{ label: 'Batch' }} />
                <Dropdown
                    required
                    label="Reversal Reason"
                    placeholder="Select Reversal Reason"
                    onChange={_onChangeReversalReason}
                    options={negativeReversalReasons.map((reason) => ({ key: reason.id, text: reason.displayName }))}
                    errorMessage={getValidationError(errors, 'Reversal Reason') ? 'Reversal Reason is required.' : undefined}
                />
                <TextField
                    label="Note"
                    multiline
                    value={reversalNote}
                    onChange={_onChangeReversalNote}
                    description={`${reversalNote?.length ?? 0} of 150 characters used.`}
                    errorMessage={getValidationError(errors, 'Notes') ? 'Character limit must be below 150.' : undefined}
                />
            </Stack>
        </TModal>
    );
}
