import { Dictionary } from '@reduxjs/toolkit';
import { DocumentBase } from 'api/document-base.model';

export interface IPerioExam extends DocumentBase {
    encounterId?: string;
    patientId?: string;
    teeth?: Dictionary<IChartPerioExamTooth>;
    appointmentStartTime?: string;
}

export interface IChartPerioExamTooth {
    facial: IChartPerioExamRecord;
    lingual: IChartPerioExamRecord;
}

export interface IChartPerioExamRecord {
    mobility?: number;
    furcation?: keyof typeof Furcation;
    suppuration?: boolean;
    probing?: SurfacesNumber;
    gingivalMargin?: SurfacesNumber;
    bleeding?: SurfacesBoolean;
    plaque?: SurfacesBoolean;
}

export type SurfacesBoolean = {
    distal: boolean;
    middle: boolean;
    mesial: boolean;
};

export type SurfacesNumber = {
    distal?: number;
    middle?: number;
    mesial?: number;
};

export enum Furcation {
    None = '',
    Class1 = 'class1',
    Class2 = 'class2',
    Class3 = 'class3',
}
