import { IBillingProcedure } from './billing-procedure.model';
import { IPatientEncounter } from './encounter.model';
import IPatientAppointment from './Scheduling/patientAppointment.model';
import IWorkListView from './worklist-view.model';

interface IWorkListEncounterView extends IWorkListView {
    date?: string;
    billingProviderId?: string;
    selfPay: boolean;
    isRecalculation: boolean;
    isOnHold: boolean;
    encounter?: IPatientEncounter;
    appointments?: IPatientAppointment[];
    appointmentCount: number;
    procedures: IBillingProcedure[];
    isChartNoteSigned: boolean;
    worklistPayerSource?: WorkListPayerSource;
    isRcm: boolean;
}

export enum WorkListPayerSource {
    Insurance = 'Insurance',
    Patient = 'Patient',
    Denial = 'Denial',
}

export default IWorkListEncounterView;
