import { Link } from '@fluentui/react';
import IWorkListAppointmentView, { IWorkListAwaitingAttestationView } from 'api/models/worklist-appointment-view';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import { format } from 'date-fns';
import { useTenantId } from 'hooks';
import { useDispatch } from 'react-redux';
import { setSelectedWorkListEditItem } from 'state/slices/admin-huddle/worklists/worklist.slice';
import { getAppointmentData } from 'state/slices/scheduling/scheduling.actions';
import { AppointmentType } from 'state/slices/scheduling/scheduling.state';
import { convertFromMilitaryTime } from 'utils/convertFromMilitaryTime';

const appointmentDateLink: ISortableColumn<IWorkListAwaitingAttestationView | IWorkListAppointmentView> = {
    key: 'appointmentDateLink',
    minWidth: 130,
    maxWidth: 130,
    isResizable: true,
    name: 'Appt. Date/Time',
    fieldName: 'startDateTime',
    onRender: AppointmentDateTimeLinkColumn,
};

const isAwaitingAttestationView = (
    item?: IWorkListAwaitingAttestationView | IWorkListAppointmentView,
): item is IWorkListAwaitingAttestationView => {
    return !!(item as IWorkListAwaitingAttestationView).appointmentId;
};

function AppointmentDateTimeLinkColumn(item?: IWorkListAwaitingAttestationView | IWorkListAppointmentView) {
    const dispatch = useDispatch();
    const tenantId = useTenantId();

    return item?.startDateTime ? (
        <Link
            onClick={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                
                const id = isAwaitingAttestationView(item) ? item.appointmentId : item.id;

                if (id) {
                    dispatch(getAppointmentData(tenantId, id, AppointmentType.Patient));
                    dispatch(setSelectedWorkListEditItem(item));
                }
            }}
        >
            {format(new Date(item.startDateTime), 'P')}, {convertFromMilitaryTime(item.startTime)}
        </Link>
    ) : null;
}

export default appointmentDateLink;

