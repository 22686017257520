import { IconButton, TooltipHost } from '@fluentui/react';
import { IBillingProcedure } from 'api/models/billing-procedure.model';
import { ChartProcedureStatus } from 'api/models/chart.model';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import { useDispatch } from 'react-redux';
import { setBillingProcedureToVoid } from 'state/slices/admin-huddle/worklists/worklist.slice';

const voidProcedure: ISortableColumn<IBillingProcedure> = {
    key: 'voidProcedure',
    name: '',
    fieldName: '',
    minWidth: 25,
    maxWidth: 25,
    onRender: VoidProcedureColumn,
};

function VoidProcedureColumn(item: IBillingProcedure | undefined) {
    const dispatch = useDispatch();

    return (
        <TooltipHost content="Void this procedure">
            <IconButton
                styles={{ root: { height: 20, width: 20 } }}
                disabled={item?.hasChartProcedure || item?.status === ChartProcedureStatus.Void || !item?.isBilled}
                iconProps={{ iconName: 'Blocked' }}
                onClick={() => {
                    if (item) dispatch(setBillingProcedureToVoid(item));
                }}
            />
        </TooltipHost>
    );
}

export default voidProcedure;
