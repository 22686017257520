import {
    Callout,
    DefaultButton,
    DirectionalHint,
    IconButton,
    Persona,
    PersonaSize,
    Spinner,
    Stack,
    TooltipHost,
    useTheme,
} from '@fluentui/react';
import { useBoolean, useId } from '@uifabric/react-hooks';
import MergedPatientWarning from 'components/MergedPatientWarning';
import { usePatientId, useSelector, useTenantId } from 'hooks';
import { LoadingStatus } from 'interfaces/loading-statuses';
import PatientDetailsCard from 'pages/components/PatientDetailsCard';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getPatientDocuments } from 'state/slices/documents/documents.actions';
import { getPatientToEditAndOpenPanel } from 'state/slices/edit-patient/edit-patient.actions';
import { selectPatientIsMergedOrDeleted, selectSelectedPatient } from 'state/slices/patient/patient.selectors';
import getFullName from 'utils/getPatientFullName';

function PatientButton(): JSX.Element {
    const dispatch = useDispatch();
    const { push } = useHistory();
    const buttonId = useId('patient-button');
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const theme = useTheme();

    const tenantId = useTenantId();
    const patientId = usePatientId();

    const _selectedPatient = useSelector(selectSelectedPatient);
    const patientIsMergedOrDeleted = useSelector(selectPatientIsMergedOrDeleted);

    const _patientLoading = useSelector((state) => state.patient.loading);

    const _handlePatientButtonClick = () => {
        toggleIsCalloutVisible();
    };

    const _handleEditPatient = () => {
        if (patientId) {
            dispatch(getPatientToEditAndOpenPanel({ tenantId, patientId }));
            dispatch(getPatientDocuments({ tenantId, patientId }));
            toggleIsCalloutVisible();
        }
    };
    const _clearSelectedPatient = () => {
        push(`/${tenantId}/scheduling`);
    };

    if (_patientLoading === LoadingStatus.Pending)
        return <Spinner label="Loading patient..." labelPosition="right" styles={{ label: { color: '#FFF' } }} />;
    return (
        <div>
            {_selectedPatient ? (
                <Stack horizontal verticalAlign="center">
                    <Persona
                        styles={{
                            root: {
                                cursor: 'pointer',
                            },
                            primaryText: {
                                color: '#FFF',
                                '&:hover': {
                                    color: theme.palette.themeTertiary,
                                },
                            },
                        }}
                        id={buttonId}
                        onClick={_handlePatientButtonClick}
                        size={PersonaSize.size24}
                        text={getFullName(_selectedPatient)}
                    />
                    <TooltipHost content="Clear selected patient" directionalHint={DirectionalHint.leftCenter}>
                        <IconButton
                            styles={{
                                root: {
                                    color: '#FFF',
                                },
                                rootHovered: {
                                    color: theme.palette.themePrimary,
                                },
                            }}
                            iconProps={{ iconName: 'Cancel' }}
                            onClick={_clearSelectedPatient}
                        />
                    </TooltipHost>
                </Stack>
            ) : null}
            {isCalloutVisible && (
                <Callout
                    role="alertdialog"
                    gapSpace={0}
                    target={`#${buttonId}`}
                    onDismiss={toggleIsCalloutVisible}
                    setInitialFocus
                >
                    <PatientDetailsCard
                        onPageNavigationClick={_handlePatientButtonClick}
                        patient={_selectedPatient}
                        showClinicalAlerts
                        showInsurances={false}
                    />
                    <MergedPatientWarning />

                    <Stack style={{ padding: 5 }} horizontal horizontalAlign="end" tokens={{ childrenGap: 5 }}>
                        <DefaultButton
                            text="Edit Patient"
                            iconProps={{ iconName: 'Edit' }}
                            onClick={_handleEditPatient}
                            disabled={patientIsMergedOrDeleted}
                        />
                    </Stack>
                </Callout>
            )}
        </div>
    );
}

export default PatientButton;
