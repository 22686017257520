import {
    Stack,
    IPanelProps,
    IPanelStyles,
    PrimaryButton,
    DefaultButton,
    IColumn,
    Panel,
    PanelType,
    useTheme,
    Text,
    Link,
    MessageBar,
    MessageBarType,
} from '@fluentui/react';
import { IDiagnosis } from 'api/models/diagnosis.model';
import { EditDetailsColumn } from 'components/EditDetailsColumn';
import { useTenant, useSelector } from 'hooks';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { RouteParams } from 'interfaces/route-params';
import { DiagnosisSelector, CategoryItemBrowser } from 'pages/components';
import PanelSectionHeader from 'pages/components/PanelSectionHeader';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { chartingActions } from 'state/slices/charting/chart/chart.slice';
import {
    selectDiagnosesPanel,
    selectDiagnosisPanelConflictErrors,
} from 'state/slices/charting/diagnoses-panel/diagnoses-panel.selectors';
import { updateChartProcedureDiagnoses } from 'state/slices/charting/diagnoses-panel/diagonses-panel.actions';
import { selectChartProcedures } from 'state/slices/charting/procedures/procedures.selectors';
import { useState, useEffect } from 'react';
import { IProcedure, IProcedureDiagnosis } from 'api/models/procedure.model';
import { uniqBy } from 'lodash';
import { ChartProcedureDiagnosis } from 'api/models/chart.model';

function DianosesPanel(props: IPanelProps): JSX.Element {
    const theme = useTheme();
    const dispatch = useDispatch();

    const { tenantId, patientId } = useParams<RouteParams>();
    const { getDiagnosesList, getDiagnosesData } = useTenant();
    const { saving } = useSelector(selectChartProcedures);
    const { selectedDiagnoses, selectedProcedures: selectedProcedure, isOpen } = useSelector(selectDiagnosesPanel);

    const dxConflicts = useSelector(selectDiagnosisPanelConflictErrors);

    const [extraDiagnoses, setExtraDiagnoses] = useState<IProcedureDiagnosis[]>([]);

    useEffect(() => {
        if (getDiagnosesData && isOpen) {
            const extraSelectedDiagnoses: IProcedureDiagnosis[] = selectedDiagnoses
                ? (selectedDiagnoses
                      .map((dx) => ({ code: getDiagnosesData[dx.id]?.code }))
                      .filter((c) => c !== undefined)
                      .filter((data) => {
                          if (Array.isArray(selectedProcedure)) {
                              // Search through each selected procedure to find existing diagnosis
                              return true;
                          } else {
                              return selectedProcedure?.diagnoses
                                  ? selectedProcedure.diagnoses.findIndex((d) => data.code === d.code) === -1
                                  : true;
                          }
                      }) as IProcedureDiagnosis[])
                : [];

            setExtraDiagnoses(extraSelectedDiagnoses);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const _onPanelDismiss = () => {
        dispatch(chartingActions.setDiagnosesPanelClosed());
    };

    const _onPanelDismissed = () => {
        dispatch(chartingActions.clearDiagnosesPanel());
        setExtraDiagnoses([]);
    };

    const _onSelectDiagnoses = (diagnosisCodes: ChartProcedureDiagnosis[]) => {
        dispatch(chartingActions.updateDiagonsisCodes(diagnosisCodes));
    };

    const _saveDiagnoses = () => {
        dispatch(updateChartProcedureDiagnoses(tenantId, patientId));
    };

    const _panelStyles: Partial<IPanelStyles> = {
        scrollableContent: { overflow: 'hidden', height: '100%' },
        content: { height: '100%', paddingRight: 0, overflow: 'hidden' },
    };
    const _proceduresSaving = saving === LoadingStatus.Pending;
    const _saveButtonText = _proceduresSaving ? 'Saving...' : 'Save';
    const _footerContent = () => (
        <Stack tokens={{ childrenGap: 10 }}>
            <ConflictMessages />
            <Stack horizontal tokens={{ childrenGap: 12 }}>
                <PrimaryButton
                    text={_saveButtonText}
                    onClick={_saveDiagnoses}
                    disabled={_proceduresSaving || dxConflicts.length > 0}
                />
                <DefaultButton text="Cancel" onClick={_onPanelDismiss} />
            </Stack>
        </Stack>
    );

    const addDiagnosisToList = (item?: IDiagnosis) => {
        if (item?.code) {
            setExtraDiagnoses([...extraDiagnoses, { code: item.code }]);
            const newDx: ChartProcedureDiagnosis = { displayName: item.code, description: item.description, id: item.id };
            const diagnoses = selectedDiagnoses ? [...selectedDiagnoses, newDx] : [newDx];
            _onSelectDiagnoses(diagnoses);
        }
    };

    const _columns: IColumn[] = [
        {
            key: 'code',
            minWidth: 50,
            maxWidth: 50,
            name: 'Code',
            fieldName: 'code',
            onRender: (item: IDiagnosis) => <Link onClick={() => addDiagnosisToList(item)}>{item.code ? item.code : ''}</Link>,
        },
        {
            key: 'description',
            minWidth: 200,
            name: 'Description',
            fieldName: 'displayName',
            onRender: (item: IDiagnosis) => (
                <EditDetailsColumn
                    editOnClick={() => addDiagnosisToList(item)}
                    variant='smallPlus'
                    title={`${item.displayName ? item.displayName : ''}`}
                />
            ),
        },
    ];

    const filteredDiagnoses = () => {
        if (Array.isArray(selectedProcedure)) {
            const selectedProcedureDiagnoses = selectedProcedure
                .filter((procedure) => procedure.diagnoses?.length)
                .map((procedure) => procedure.diagnoses)
                .flat() as IProcedureDiagnosis[];

            return selectedProcedureDiagnoses?.length || extraDiagnoses.length
                ? getDiagnosesList
                      .filter((d) =>
                          selectedProcedureDiagnoses
                              ? selectedProcedureDiagnoses.findIndex((diag) => diag.code === d.code) === -1
                              : true,
                      )
                      .filter((d) => extraDiagnoses.findIndex((diag) => diag.code === d.code) === -1)
                : getDiagnosesList;
        } else {
            return selectedProcedure?.diagnoses?.length || extraDiagnoses.length
                ? getDiagnosesList
                      .filter((d) =>
                          selectedProcedure?.diagnoses
                              ? selectedProcedure.diagnoses.findIndex((diag) => diag.code === d.code) === -1
                              : true,
                      )
                      .filter((d) => extraDiagnoses.findIndex((diag) => diag.code === d.code) === -1)
                : getDiagnosesList;
        }
    };

    const _getProcedureDiagnoses = () => {
        if (Array.isArray(selectedProcedure)) {
            return uniqBy(selectedProcedure.map((p) => p.diagnoses).flat() as IProcedureDiagnosis[], 'code');
        } else {
            return selectedProcedure?.diagnoses;
        }
    };
    const maxDiagnosesAssociated = selectedDiagnoses?.length;

    return (
        <Panel
            {...props}
            isOpen={isOpen}
            isFooterAtBottom
            onRenderFooterContent={_footerContent}
            type={PanelType.medium}
            styles={_panelStyles}
            headerText="Assign diagnoses"
            onDismiss={_onPanelDismiss}
            onDismissed={_onPanelDismissed}
        >
            <Stack tokens={{ childrenGap: 10 }}>
                <PanelSectionHeader text="Procedure" />
                <div
                    style={{
                        padding: `0px 12px 12px 12px`,
                        background: theme.palette.neutralLighterAlt,
                        border: `1px solid ${theme.palette.neutralLighter}`,
                    }}
                >
                    <RenderProcedureText procedures={selectedProcedure} />
                </div>
                <PanelSectionHeader text="Diagnosis Suggestions" />
                <DiagnosisSelector
                    suggestionDiagnoses={_getProcedureDiagnoses()}
                    selectedDiagnoses={selectedDiagnoses}
                    updateDiagnoses={_onSelectDiagnoses}
                />
                {extraDiagnoses?.length && (
                    <DiagnosisSelector
                        suggestionDiagnoses={extraDiagnoses}
                        selectedDiagnoses={selectedDiagnoses}
                        updateDiagnoses={_onSelectDiagnoses}
                        shouldAutoApply={false}
                    />
                )}
            </Stack>
            <div style={{ height: 20 }}></div>
            <PanelSectionHeader text="Find Diagnosis" />
            <div style={{ height: 10 }}></div>
            <CategoryItemBrowser<IDiagnosis>
                items={filteredDiagnoses()}
                columns={_columns}
                searchKeys={['code', 'displayName']}
                searchPlaceholder={'Find diagnoses (Code or Description)'}
                onItemInvoked={addDiagnosisToList}
                threshold={0.1}
                isDisabled={maxDiagnosesAssociated! >= 4}
            />
        </Panel>
    );
}

export default DianosesPanel;

function ConflictMessages() {
    const dxConflicts = useSelector(selectDiagnosisPanelConflictErrors);
    const _conflictMessages = dxConflicts.length
        ? dxConflicts.map((conflict, index) => <li key={`conflic-${index}`}>{conflict.message}</li>)
        : null;
    const classNames = _conflictMessages?.length ? 'ms-motion-fadeIn' : 'ms-motion-fadeOut';
    return (
        <Stack>
            <MessageBar className={classNames} messageBarType={MessageBarType.blocked}>
                <ul style={{ margin: 0 }}>{_conflictMessages}</ul>
            </MessageBar>
        </Stack>
    );
}

function RenderProcedureText({ procedures }: { procedures?: IProcedure | IProcedure[] }): JSX.Element {
    if (!procedures) return <></>;

    if (Array.isArray(procedures)) {
        const procedureItems = procedures.map((procedure, index) => (
            <Text block key={index}>
                {procedure.code} - {procedure.displayName}
            </Text>
        ));

        return <>{procedureItems}</>;
    } else {
        return (
            <Text>
                {procedures.code} - {procedures.displayName}
            </Text>
        );
    }
}
