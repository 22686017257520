import {
    DefaultButton,
    IChoiceGroupOption,
    IStackTokens,
    PrimaryButton,
    Spinner,
    Stack,
    TextField,
} from '@fluentui/react';
import { Field, Section } from 'components';
import { useValidation } from 'hooks';
import { getValidationError, IValidationConfig } from 'hooks/useValidation';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { RouteParams } from 'interfaces/route-params';
import { BatchField } from 'pages/components/Batch/BatchField';
import PanelSectionHeader from 'pages/components/PanelSectionHeader';
import { FormEvent, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import {
    cleanupTreatmentPlanCreditPaymentSource,
    updateTreatmentPlanCreditPaymentSource,
} from 'state/slices/ledger/ledger.slice';
import {
    saveTreatmentPlanCreditPaymentSourceAndTransactions,
    updateTreatmentPlanCreditPaymentSourceAndTransactionsBatchId,
} from 'state/slices/ledger/treatment-plan-credit-and-uac/treatment-plan-credit-and-uac.actions';
import {
    selectSelectedTreatmentPlanCreditPhaseBalanceRemaining,
    selectTreamentPlanCreditPaymentSource,
    selectTreamentPlanCreditTransactionsExist,
    selectTreatmentPlanCreditAllowOverpayment,
    selectTreamtentPlanCreditModified,
    selectTreatmentPlanCreditSaving,
} from 'state/slices/ledger/treatment-plan-credit-and-uac/treatment-plan-credit-and-uac.selectors';
import { selectSelectedBatch } from 'state/slices/tenant/batches.slice';
import { usdCurrencyFormatter } from 'utils';
import PaymentMethods, { getPaymentMethodCheckOrAuth, shouldHideMethodIdentifier } from '../components/PaymentMethods';
import { getMaxPayment } from '../LedgerUtils';

const stackTokens: IStackTokens = { childrenGap: 10 };

type Props = {
    heading: string;
    headingRightContent?: JSX.Element | null;
    balance?: number;
    hasNotes?: boolean;
};

export default function TreatmentPlanCreditAndUACSection({ heading, balance, headingRightContent, hasNotes }: Props) {
    const dispatch = useDispatch();
    const { push } = useHistory();

    const { encounterId, tenantId, patientId } = useParams<RouteParams>();

    const selectedBatch = useSelector(selectSelectedBatch);

    const paymentSource = useSelector(selectTreamentPlanCreditPaymentSource);
    const modified = useSelector(selectTreamtentPlanCreditModified);
    const overpayment = useSelector(selectTreatmentPlanCreditAllowOverpayment);
    const transactionsExist = useSelector(selectTreamentPlanCreditTransactionsExist);
    const remainingAmount = useSelector(selectSelectedTreatmentPlanCreditPhaseBalanceRemaining);
    const saving = useSelector(selectTreatmentPlanCreditSaving);

    const _onChangeNote = (event?: FormEvent, value?: string) => {
        dispatch(updateTreatmentPlanCreditPaymentSource({ path: 'note', value }));
    };

    const _onSave = () => {
        if (patientId && selectedBatch)
            dispatch(
                saveTreatmentPlanCreditPaymentSourceAndTransactions({
                    tenantId,
                    patientId,
                    encounterId,
                    batchId: selectedBatch?.id,
                }),
            );
    };

    const _onChangeMethod = (e?: FormEvent, option?: IChoiceGroupOption) => {
        dispatch(updateTreatmentPlanCreditPaymentSource({ path: 'method', value: option?.key }));
    };

    const _onChangeMethodIdentifier = (e?: FormEvent, value?: string) => {
        dispatch(updateTreatmentPlanCreditPaymentSource({ path: 'methodIdentifier', value }));
    };

    const _onChangeTodaysPayment = (e?: FormEvent, value?: number | undefined) => {
        dispatch(updateTreatmentPlanCreditPaymentSource({ path: 'amount', value }));
    };

    useEffect(() => {
        if (selectedBatch) dispatch(updateTreatmentPlanCreditPaymentSourceAndTransactionsBatchId(selectedBatch));
    }, [selectedBatch]);

    const getValdiationConfig = useCallback((): IValidationConfig => {
        const validationConfig: IValidationConfig = [
            {
                fieldName: 'Batch',
                value: selectedBatch,
                validation: ['required'],
            },
            {
                fieldName: 'Payment Method',
                value: paymentSource?.method,
                validation: ['required'],
            },
            {
                fieldName: "Today's Payment",
                value: paymentSource?.amount,
                validation: ['required'],
            },
        ];

        if (!shouldHideMethodIdentifier(paymentSource?.method)) {
            validationConfig.push({
                fieldName: getPaymentMethodCheckOrAuth(paymentSource?.method),
                value: paymentSource?.methodIdentifier,
                validation: ['required'],
            });
        }

        if (hasNotes) {
            validationConfig.push({
                fieldName: 'Notes',
                validation: ['characterLimit'],
                value: paymentSource?.note,
                itemOptions: { characterLimit: 150 },
            });
        }

        return validationConfig;
    }, [paymentSource, selectedBatch, hasNotes]);

    const [errors, submit, cleanupErrors] = useValidation(getValdiationConfig(), _onSave, {
        shouldZerosNotFulfillRequired: true,
    });

    useEffect(() => {
        return () => {
            cleanupErrors();
            dispatch(cleanupTreatmentPlanCreditPaymentSource());
        };
    }, []);

    const cancelAndGoBack = () => {
        let urlPath = `/${tenantId}/patient/${patientId}`;
        if (encounterId) urlPath += `/encounter/${encounterId}`;
        push(`${urlPath}/ledger/treatment-plan-prepayment-uac`);
    };

    const disableSave = (transactionsExist && !!remainingAmount && !!paymentSource?.amount) || saving === LoadingStatus.Pending;

    return (
        <>
            <Helmet title={`Ledger - Prepayment and UAC - ${heading}`} />
            <Prompt
                when={modified && saving !== LoadingStatus.Completed}
                message={'Are you sure you want to leave? Any unsaved information will be lost.'}
            />
            <Section
                heading={
                    <Stack horizontal tokens={stackTokens}>
                        <span>{heading}</span>
                        <span>{balance ? `(Estimated Pt. Balance: ${usdCurrencyFormatter.format(balance)})` : ''}</span>
                        {headingRightContent}
                    </Stack>
                }
                footer={
                    <Stack horizontal horizontalAlign="end" grow tokens={stackTokens}>
                        {saving === LoadingStatus.Pending && <Spinner label="Saving..." labelPosition="right" />}
                        <DefaultButton iconProps={{ iconName: 'Cancel' }} onClick={cancelAndGoBack}>
                            Cancel
                        </DefaultButton>
                        <PrimaryButton disabled={disableSave} iconProps={{ iconName: 'Save' }} onClick={submit}>
                            Save
                        </PrimaryButton>
                    </Stack>
                }
            >
                <Stack styles={{ root: { maxHeight: 290, overflowY: 'auto', overflowX: 'hidden' } }} tokens={stackTokens}>
                    <Stack horizontal tokens={stackTokens} verticalAlign="start">
                        <BatchField error={errors} textfieldProps={{ label: 'Batch' }} />
                        <Field.Date label="Entry date" value={paymentSource?.dateOfEntry} autoComplete="off" readOnly />
                    </Stack>
                    <Stack>
                        <PanelSectionHeader
                            text="Payment Info"
                            leftContent={
                                transactionsExist ? (
                                    <span>(Unallocated Amount: {usdCurrencyFormatter.format(remainingAmount)})</span>
                                ) : undefined
                            }
                        />
                        <Stack tokens={stackTokens} horizontal>
                            <Stack.Item>
                                <Field.Currency
                                    styles={{ field: { marginTop: 0 } }}
                                    prefix="$"
                                    label="Today's Payment"
                                    required
                                    onChange={_onChangeTodaysPayment}
                                    max={getMaxPayment({ overpayment, paymentAmount: balance })}
                                    value={paymentSource?.amount}
                                    errorMessage={
                                        getValidationError(errors, "Today's Payment") ? "Today's Payment is required." : undefined
                                    }
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <PaymentMethods
                                    selectedKey={paymentSource?.method}
                                    methodIdentifier={paymentSource?.methodIdentifier}
                                    onChange={_onChangeMethod}
                                    onChangeIdentifier={_onChangeMethodIdentifier}
                                    errors={errors}
                                />
                            </Stack.Item>
                        </Stack>
                        {hasNotes && (
                            <TextField
                                multiline
                                rows={1}
                                label="Notes"
                                value={paymentSource?.note}
                                onChange={_onChangeNote}
                                description={`Used ${paymentSource?.note?.length ?? 0} of 150 characters.`}
                                errorMessage={getValidationError(errors, 'Notes') ? 'Character limit must be below 150.' : ''}
                            />
                        )}
                    </Stack>
                </Stack>
            </Section>
        </>
    );
}
