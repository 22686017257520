import { Checkbox, Stack } from '@fluentui/react';
import { IImmunization } from 'api/models/patient-immunization.model';
import { Field } from 'components';
import SearchComboField from 'components/Field/SearchComboField';
import useOverview from 'hooks/store/useOverview';
import { classicDateOnly } from 'utils/dateOnly';
import { v4 as uuid } from 'uuid';

export default function ImmunizationsEditProperties() {
    const { selectedOverviewItem, setSelectedOverviewItem } = useOverview();
    const isNewItem = selectedOverviewItem.isNewItem;
    const item: IImmunization =
        !isNewItem &&
        selectedOverviewItem &&
        selectedOverviewItem!.item.immunizations &&
        selectedOverviewItem!.item.immunizations.length &&
        selectedOverviewItem!.item.immunizations.find(
            (immunization: IImmunization) => immunization.id === selectedOverviewItem.key,
        );

    const updateSelectedPatientOverview = (updatedItem: any) => setSelectedOverviewItem(updatedItem);

    const handleOnChange = (key: keyof IImmunization, value: any) => {
        const updatedImmunization: IImmunization = {
            ...item,
            [key]: value,
        };
        const insertIndex = selectedOverviewItem.item.immunizations.indexOf(item);
        const immunizations = selectedOverviewItem.item.immunizations.filter(
            (immunization: IImmunization) => immunization.id !== selectedOverviewItem.key,
        );
        if (updatedImmunization) {
            immunizations.splice(insertIndex, 0, updatedImmunization);
        }
        const updatedItem = {
            ...selectedOverviewItem,
            item: {
                ...selectedOverviewItem.item,
                immunizations,
            },
        };
        updateSelectedPatientOverview(updatedItem);
    };

    return (
        <Stack tokens={{ childrenGap: 5 }}>
            <SearchComboField
                options={[
                    { key: '158', text: 'influenza, injectable, quadrivalent, contains preservative', data: undefined },
                    { key: '123', text: 'test vaccine', data: undefined },
                ]}
                placeholder="Select Vaccine"
                useComboBoxAsMenuWidth={true}
                selectedKey={item && item.vaccineCode ? item.vaccineCode : null}
                onItemClick={(event, option, index) => {
                    if (option) {
                        const id = uuid();
                        const newImmunization: IImmunization = {
                            date: '',
                            expirationDate: '',
                            explanation: undefined,
                            id: id,
                            reported: false,
                            resourceType: '',
                            status: '',
                            vaccineCode: option.key as string,
                            vaccineDisplay: option.text,
                            vaccineSystem: '',
                            wasNotGiven: false,
                        };

                        const updatedItem = {
                            ...selectedOverviewItem,
                            key: id,
                            item: {
                                ...selectedOverviewItem.item,
                                immunizations: [...selectedOverviewItem.item.immunizations, newImmunization],
                            },
                        };
                        updateSelectedPatientOverview(updatedItem);
                    }
                }}
            />
            {item && (
                <Stack tokens={{ childrenGap: 10 }}>
                    <Stack tokens={{ childrenGap: 10 }} horizontal verticalAlign="end" grow>
                        <Stack.Item grow>
                            <Field.Dropdown
                                options={[
                                    { key: 'Immunization', text: 'Immunization' },
                                    { key: 'Other', text: 'Other' },
                                ]}
                                label="Resource Type"
                                placeholder="(Select Type)"
                                style={{ minWidth: 215 }}
                                selectedKey={item.resourceType ? item.resourceType : undefined}
                                onChange={(event, option, index) =>
                                    option && handleOnChange('resourceType', option.key as string)
                                }
                            />
                        </Stack.Item>
                        <Stack.Item grow>
                            <Field.Dropdown
                                options={[
                                    { key: 'completed', text: 'completed' },
                                    { key: 'Other', text: 'Other' },
                                ]}
                                label="Status"
                                placeholder="(Select Status)"
                                style={{ minWidth: 215 }}
                                selectedKey={item.status ? item.status : undefined}
                                onChange={(event, option, index) => option && handleOnChange('status', option.key as string)}
                            />
                        </Stack.Item>
                    </Stack>
                    <Stack tokens={{ childrenGap: 10 }} horizontal verticalAlign="end" grow>
                        <Stack.Item grow>
                            <Field.Date
                                label="Date"
                                value={item.date ? classicDateOnly(item.date) : undefined}
                                onChange={(event, newValue) => handleOnChange('date', newValue ? newValue : '')}
                            />
                        </Stack.Item>
                        <Stack.Item grow>
                            <Field.Date
                                label="Expiration Date"
                                value={item.expirationDate ? classicDateOnly(item.expirationDate) : undefined}
                                onChange={(event, newValue) => handleOnChange('expirationDate', newValue ? newValue : '')}
                            />
                        </Stack.Item>
                    </Stack>
                    <Stack tokens={{ childrenGap: 10 }} horizontal>
                        <Stack.Item>
                            <Checkbox
                                label="Was Not Given"
                                checked={item.wasNotGiven}
                                onChange={(ev, checked) => handleOnChange('wasNotGiven', checked)}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <Checkbox
                                label="Reported"
                                checked={item.reported}
                                onChange={(ev, checked) => handleOnChange('reported', checked)}
                            />
                        </Stack.Item>
                    </Stack>
                </Stack>
            )}
        </Stack>
    );
}
