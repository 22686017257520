import IPatientCommunication from 'api/models/patient-communication.model';
import { LoadingStatuses, LoadingStatus } from 'interfaces/loading-statuses';

export type CommunicationState = {
    communications?: IPatientCommunication[];
    loading: LoadingStatuses;
    editCommunication?: IPatientCommunication;
    loadingEditCommunication: LoadingStatuses;
};

export const initialState: CommunicationState = {
    loading: LoadingStatus.Idle,
    loadingEditCommunication: LoadingStatus.Idle,
};
