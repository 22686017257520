import { Stack, Pivot, PivotItem, StackItem, useTheme } from '@fluentui/react';
// import { Section } from 'components';
import { useChart, useSelector } from 'hooks';
import InstantQuickMenu from './InstantQuickMenu/InstantQuickMenu';
import ChartNotes from './InstantQuickMenu/ChartNotes';
import Xrays from './InstantQuickMenu/Xrays';
import ProcedureMenu from './ProcedureMenu/ProcedureMenu';
import ChartingMenuToolbar from './ChartingMenuToolbar/ChartingMenulToolbar';
import { useDispatch } from 'react-redux';

export default function ChartingMenu() {
    const { setMenu, menu } = useChart();
    const theme = useTheme();
    const height = 403;
    const dispatch = useDispatch();

    function _onLinkClick(item?: PivotItem | undefined) {
        if (item) setMenu(item.props.itemKey as any);
    }

    function renderMenu() {
        switch (menu) {
            case 'procedure':
                return <ProcedureMenu />;
            case 'quickActions':
                return <InstantQuickMenu />;
            case 'notes':
                return <ChartNotes />;
            case 'xrays':
                return <Xrays />;
            default:
                return null;
        }
    }

    return (
        <Stack
            id="charting-menu-wrapper"
            style={{ overflow: 'hidden', maxWidth: 414, height: '100%' }}
            tokens={{ childrenGap: 10 }}
            grow
        >
            <div style={{ display: 'flex', flex: 1, marginTop: '0px !important' }}>
                <ChartingMenuToolbar />
                <Stack style={{ marginBottom: 0, background: theme.palette.white }} grow>
                    <Pivot style={{ marginBottom: 10 }} onLinkClick={_onLinkClick} selectedKey={menu}>
                        <PivotItem headerText="Quick Actions" itemKey="quickActions" />
                        <PivotItem headerText="Procedures" itemKey="procedure" />
                        <PivotItem headerText="Notes Hx" itemKey="notes" />
                        <PivotItem headerText="X-Rays" itemKey="xrays" />
                    </Pivot>
                    <StackItem
                        style={{
                            maxHeight: height,
                            minHeight: height,
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            padding: 10,
                            paddingTop: 0,
                        }}
                        grow
                    >
                        {renderMenu()}
                    </StackItem>
                </Stack>
            </div>
        </Stack>
    );
}
