import {
    Stack,
    Spinner,
    SpinnerSize,
    MessageBar,
    MessageBarType,
    useTheme,
    IconButton,
    TooltipHost,
    PanelType,
} from '@fluentui/react';
import { SubSection } from 'components';
import useOverview from 'hooks/store/useOverview';
import { LoadingStatus, LoadingStatuses } from 'interfaces/loading-statuses';

type CommonListParameters = {
    type: string;
    displayName: string;
    newItem: any;
    hasItems?: boolean;
    listComponent?: JSX.Element;
    CompletedComponent?: JSX.Element;
    loadingStatus: LoadingStatuses;
    headingEndContent?: JSX.Element;
    panelType?: PanelType;
    addDisabled?: boolean;
    addShown?: boolean;
};

function SharedSubSection(props: CommonListParameters): JSX.Element {
    const { type, displayName, newItem, hasItems, listComponent, CompletedComponent, loadingStatus, panelType } = props;
    const { setSelectedOverviewItem } = useOverview();
    const theme = useTheme();

    const onBuildLoadingSpinner = () => (
        <Stack style={{ paddingBottom: 12 }}>
            <Spinner size={SpinnerSize.large} label="Loading..." labelPosition="right" />
        </Stack>
    );

    const onBuildErrors = () => (
        <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
            <strong>Error occurred while loading:</strong>
        </MessageBar>
    );

    const listComponentTooltip = hasItems ? `Add ${displayName}` : newItem.id ? `Edit ${displayName}` : `Create ${displayName}`;
    const QuestionnaireComponentTooltip = hasItems ? `Start ${displayName}` : `Continue ${displayName}`;
    const onBuildTopButtons = () => (
        <Stack horizontal>
            <TooltipHost content={listComponent ? listComponentTooltip : QuestionnaireComponentTooltip}>
                <IconButton
                    iconProps={{ iconName: 'Add' }}
                    disabled={props.addDisabled}
                    onClick={() =>
                        setSelectedOverviewItem({
                            item: newItem,
                            savingStatus: LoadingStatus.Idle,
                            isNewItem: newItem.id ? false : true,
                            key: '',
                            displayName: displayName,
                            type: type,
                            panelType: panelType,
                        })
                    }
                />
            </TooltipHost>
        </Stack>
    );
    return (
        <SubSection
            title={type}
            headingCenterContent={props.addShown ? onBuildTopButtons() : false}
            headingEndContent={props.headingEndContent}
            style={{ backgroundColor: theme.palette.neutralLighterAlt, padding: 10, marginBottom: 10 }}
        >
            <Stack grow>
                {loadingStatus === LoadingStatus.Pending && onBuildLoadingSpinner()}
                {loadingStatus === LoadingStatus.Failed && onBuildErrors()}
                {listComponent && loadingStatus === LoadingStatus.Completed && listComponent}
                {CompletedComponent && loadingStatus === LoadingStatus.Completed && CompletedComponent}
            </Stack>
        </SubSection>
    );
}

export default SharedSubSection;
