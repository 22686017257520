import { ContextualMenuItemType, DefaultButton, IContextualMenuItem, MessageBar, Panel, PanelType, Stack } from '@fluentui/react';
import { format } from 'date-fns';
import { usePatientId, useSelector, useTenantId } from 'hooks';
import { SignalRMessage, useSignalR } from 'hooks/signalr/useSignalr';
import { useEffect, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { editNewPatient } from 'state/slices/edit-patient/edit-patient.actions';
import { selectEditPatientLoading } from 'state/slices/edit-patient/edit-patient.selectors';
import { fetchPatient, switchPatient, updatePatientMrn } from 'state/slices/patient/patient.actions';
import { clearPatients, clearSelectedPatient, setFindPatientOpen } from 'state/slices/patient/patient.slice';
import { selectNewPatientDemographicsIsOpen } from 'state/slices/ui/ui.slice';
import appLocalStorage from 'utils/appLocalStorage';
import getFullName from 'utils/getPatientFullName';
import FindPatient from './FindPatient';
import PatientButton from './PatientButton';

function PatientSelector(): JSX.Element {
    const dispatch = useDispatch();
    const { registerSignalRMessage } = useSignalR({ disableAutoMessageCleanup: true });

    const isRouteReporting = useRouteMatch({ path: '/:tenantId/reporting' });

    const patientId = usePatientId();
    const tenantId = useTenantId();

    useEffect(() => {
        if (patientId)
            //Updates the patient mrn for the selected patient only, so we can register this only when a patientId exists.
            registerSignalRMessage(SignalRMessage.NewPatientSourceId, updatePatientMrn);
    }, [patientId]);

    useEffect(() => {
        if (patientId && tenantId && !isRouteReporting) {
            dispatch(fetchPatient({ tenantId, patientId }));
        }
        return function cleanup() {
            dispatch(clearSelectedPatient());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientId, tenantId, dispatch]);

    const { findPatientOpen } = useSelector((state) => state.patient);
    const setSearchClosed = () => dispatch(setFindPatientOpen(false));
    const setSearchOpen = () => dispatch(setFindPatientOpen(true));

    const isEditNewPatientOpen = useSelector(selectNewPatientDemographicsIsOpen);
    const _onDismissSearch = () => {
        batch(() => {
            dispatch(clearPatients());
        });
    };

    const onRenderFooter = () => {
        return <MessageBar>New patients may be registered in athenaOne.</MessageBar>;
    };
    const patientLoading = useSelector(selectEditPatientLoading);
    const [recentPatients, setRecentPatients] = useState<IContextualMenuItem[]>([]);

    useEffect(() => {
        function checkRecentPatients() {
            const recentPatients = appLocalStorage.recentPatients;
            const _recentPatientMenuItems: IContextualMenuItem[] = recentPatients.length
                ? recentPatients
                    .filter((p) => p.tenantId === tenantId)
                    .map((patient) => ({
                        key: patient.id,
                        text: getFullName(patient),
                        secondaryText: patient.lastAccessed ? format(new Date(patient.lastAccessed), 'M/dd/yyyy h:mm a') : '',
                        onClick: () => {
                            dispatch(switchPatient(patient));
                        },
                    }))
                : [];
            if (_recentPatientMenuItems?.length) setRecentPatients(_recentPatientMenuItems);
        }
        checkRecentPatients();
    }, [patientLoading, tenantId, dispatch]);

    const _panelTitle = isEditNewPatientOpen ? 'Add new patient' : 'Find a patient';
    return (
        <>
            <PatientButton />
            <DefaultButton
                primary
                iconProps={{ iconName: 'Search' }}
                text="Find patient"
                splitButtonAriaLabel="See 2 options"
                split
                menuProps={{
                    items: [
                        {
                            key: 'heading',
                            text: 'Recent Patients',
                            itemType: ContextualMenuItemType.Header,
                            secondaryText: recentPatients.length ? '' : 'No recent patients',
                        },
                        ...recentPatients,
                    ],
                }}
                onClick={setSearchOpen}
            />

            <Panel
                headerText={_panelTitle}
                isOpen={findPatientOpen}
                onDismiss={setSearchClosed}
                onDismissed={_onDismissSearch}
                type={PanelType.medium}
                onRenderFooterContent={onRenderFooter}
                isFooterAtBottom
                styles={{
                    content: { overflowY: 'auto', overflowX: 'hidden', flex: 1, position: 'relative' },
                    root: { overflow: 'hidden' },
                    scrollableContent: { overflow: 'hidden', display: 'flex', flexDirection: 'column' },
                }}
            >
                <Stack>
                    <FindPatient setSearchClosed={setSearchClosed} />
                </Stack>
            </Panel>
        </>
    );
}

export default PatientSelector;
