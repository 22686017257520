import {
    useChart,
    useChartProcedures,
    useTenant,
    useChartDentitions,
    useConditionsPanel,
    useSelector,
    useTenantId,
    usePatientId,
} from 'hooks';
import useConditions from 'hooks/store/useConditions';
import { useDispatch } from 'react-redux';
import ConditionCodes from 'state/slices/charting/conditionCodes';
import { addConditionByIds } from 'state/slices/charting/conditions/conditions.actions';
import { selectFirstToothDentition } from 'state/slices/charting/ui/chartingUi.selectors';
import ConditionsPanel from '../InstantQuickMenu/ConditionsPanel';
import ChartMenuToolbarButton, { ChartingMenuToolbarButton } from './ChartMenuToolbarButton';
import useEncounterId from 'hooks/useEncounterId';

function ToothToolButtons(): JSX.Element {
    const dispatch = useDispatch();
    const tenantId = useTenantId();
    const patientId = usePatientId();
    const encounterId = useEncounterId();

    const { selectedTeeth } = useChart();
    const { addProcedures } = useChartProcedures();
    const { getProcedureIdByCode, getConditionIdByCode } = useTenant();
    const { setSelectedTeethMode } = useChartDentitions();
    const { setConditionPanelOpen, setCondition } = useConditionsPanel();
    const { selectConditionsData } = useConditions();

    const firstToothDentition = useSelector(selectFirstToothDentition);
    const noneSelected = selectedTeeth.length <= 0;
    function disablePermanent() {
        if ((!noneSelected && firstToothDentition === 'Permanent') || firstToothDentition === 'Missing') {
            return true;
        }
        return false;
    }
    function disablePrimary() {
        if ((!noneSelected && firstToothDentition === 'Primary') || firstToothDentition === 'Missing') {
            return true;
        }
        return false;
    }

    const chartingToolButtons: ChartingMenuToolbarButton[] = [
        {
            key: 'add-condition',
            onClick: () => setConditionPanelOpen(true),
            tooltipContent: 'Add condition',
            icon: 'BrokenTooth',
        },
        {
            key: 'swith-permanent',
            onClick: () => setSelectedTeethMode('Permanent'),
            tooltipContent: 'Switch to permanent',
            icon: 'DoubleChevronUp8',
            buttonDisabled: disablePermanent(),
        },
        {
            key: 'switch-to-primary',
            onClick: () => setSelectedTeethMode('Primary'),
            tooltipContent: 'Switch to primary',
            icon: 'DoubleChevronDown8',
            buttonDisabled: disablePrimary(),
        },
        {
            key: 'set-missing',
            onClick: () => {
                if (patientId)
                    dispatch(addConditionByIds(tenantId, patientId, [getConditionIdByCode(ConditionCodes.Missing)], encounterId));
            },
            tooltipContent: 'Remove tooth',
            icon: 'StatusCircleErrorX',
        },
        {
            key: 'sealant',
            onClick: (e) => {
                const buttonId = e.currentTarget?.id;

                addProcedures({
                    procedureIds: [getProcedureIdByCode('D1351')],
                    toothAreas: ['Occlusal'],
                    addAutoApplyDiagnoses: true,
                    elementId: buttonId,
                });
            },
            tooltipContent: 'Add sealant(s)',
            displayName: 'S',
        },
        /*
        {
            key: 'deselect-all',
            onClick: () => deselectAllTeeth(),
            tooltipContent: 'Deselect teeth',
            icon: 'ClearSelection',
        },
        */
        {
            key: 'watch',
            onClick: () => {
                setConditionPanelOpen(true);
                //Specific Watch condition id Hope this doesn't change
                setCondition(selectConditionsData['dee53cda-baf1-45b6-95e3-bc734ac06c22']);
            },
            tooltipContent: 'Add Watch',
            displayName: 'W',
        },
    ];
    const buttonElements = chartingToolButtons.map((button) => {
        return <ChartMenuToolbarButton {...button} allDisabled={noneSelected} key={`menu-button-${button.key}`} />;
    });
    return (
        <>
            <ConditionsPanel />
            {buttonElements}
        </>
    );
}

export default ToothToolButtons;
