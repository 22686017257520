import {
    Stack,
    SearchBox,
    ScrollablePane,
    IColumn,
    IStackTokens,
    useTheme,
    TooltipHost,
    DirectionalHint,
    MessageBar,
} from '@fluentui/react';
import { SelectionMode } from '@fluentui/utilities';
import { SortableDetailsList } from 'components';
import { CSSProperties, useEffect, useState } from 'react';
import { useFuseSearch } from 'hooks/useFuseSearch';

type Props<T> = {
    columns: IColumn[];
    items: T[];
    searchKeys: string[];
    categories?: string[];
    searchPlaceholder: string;
    mountSortColumns?: string[];
    threshold?: number;
    onItemInvoked?: (item?: T) => void;
    onCategoryChange?: (cat: string[]) => void;
    isDisabled?: boolean;
};

export default function CategoryItemBrowser<T>({
    columns,
    items,
    searchKeys,
    categories,
    searchPlaceholder,
    onItemInvoked,
    onCategoryChange,
    mountSortColumns: sortOnMountColumns,
    threshold = 0.4,
    isDisabled,
}: Props<T>): JSX.Element {
    const _tokens: IStackTokens = { childrenGap: 10 };
    const _wrapperStyles: CSSProperties = { flex: 1, overflow: 'hidden', height: '100%' };
    const _categoriesStyles: CSSProperties = { minWidth: 200 };

    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const { semanticColors, palette } = useTheme();

    const { search, results, onSearch, onSearchChange } = useFuseSearch<T>({
        list: items,
        fuseOptions: { threshold, keys: searchKeys, ignoreLocation: true },
    });

    useEffect(() => {
        if (onCategoryChange) onCategoryChange(selectedCategories);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCategories]);

    const _onRenderCell = categories?.map((item: string) => {
        const _isAll = item === 'All';
        const _isActive = _isAll ? selectedCategories.length === 0 : selectedCategories.indexOf(item) > -1;

        const _activeStyle: CSSProperties = {
            backgroundColor: semanticColors.primaryButtonBackground,
            color: semanticColors.primaryButtonText,
        };
        const style = _isActive ? _activeStyle : undefined;
        const _onClick = () => {
            if (item !== 'All') {
                setSelectedCategories([item]);
            } else {
                setSelectedCategories([]);
            }
        };
        return (
            <div
                key={`procedure-category-${item}`}
                className="ms-motion-fadeIn"
                style={{
                    cursor: 'pointer',
                    borderBottom: `1px solid ${semanticColors.bodyDivider}`,
                    padding: '5px 10px',
                    transition: 'background-color .15s',
                    color: palette.black,
                    ...style,
                }}
                onClick={_onClick}
            >
                {item}
            </div>
        );
    });

    const noResults = search.length > 0 && results.length === 0;

    return (
        <Stack horizontal tokens={_tokens} style={_wrapperStyles}>
            {_onRenderCell && (
                <Stack style={_categoriesStyles}>
                    <div>{_onRenderCell}</div>
                </Stack>
            )}
            <Stack style={{ position: 'relative', flex: 1 }}>
                <Stack style={{ height: '100%' }}>
                    {isDisabled ? (
                        <TooltipHost
                            directionalHint={DirectionalHint.leftCenter}
                            delay={0}
                            content={`No more than 4 diagnoses may be associated to this procedure.`}
                        >
                            {' '}
                            <SearchBox
                                styles={{ root: { marginRight: 10 } }}
                                placeholder={searchPlaceholder}
                                value={search}
                                onChange={onSearchChange}
                                onSearch={onSearch}
                                disabled={isDisabled}
                            />
                        </TooltipHost>
                    ) : (
                        <SearchBox
                            styles={{ root: { marginRight: 10 } }}
                            placeholder={searchPlaceholder}
                            value={search}
                            onChange={onSearchChange}
                            onSearch={onSearch}
                            disabled={isDisabled}
                        />
                    )}

                    <div style={{ position: 'relative', flex: 1, height: '100%' }}>
                        <ScrollablePane>
                            {!noResults && (
                                <SortableDetailsList<T>
                                    items={isDisabled ? [] : search.length > 0 ? results : items}
                                    columns={columns}
                                    selectionMode={SelectionMode.none}
                                    initialSortDirection={['asc']}
                                    onItemInvoked={onItemInvoked}
                                    sortOnMount
                                    sortColumns={sortOnMountColumns}
                                    compact
                                />
                            )}
                            {noResults && (
                                <MessageBar styles={{ root: { marginTop: 10 } }}>Sorry, no results match your search.</MessageBar>
                            )}
                        </ScrollablePane>
                    </div>
                </Stack>
            </Stack>
        </Stack>
    );
}
