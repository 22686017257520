import { createAsyncThunk } from '@reduxjs/toolkit';
import IPatient from 'api/models/patient.model';
import dentalApi from 'api/dental.api';
import IUserTask from 'api/models/user-task.model';
import { TaskTargets } from 'state/task-management/taskManagement.actions';
import { v5 as uuid } from 'uuid';
import { IWorkListsMenu } from 'api/models/worklist-menu.model';
import { DentalPermissions, GlobalPermissions } from 'hooks/store/usePermissions';
import { FeatureFlag } from '../tenant/feature-flags.slice';

export const getEncounterPatient = createAsyncThunk<
    IPatient,
    {
        tenantId: string;
        patientId: string;
    }
>('getEncounterPatient', async ({ tenantId, patientId }) => {
    const req = await dentalApi.getPatient(tenantId, patientId);
    return req.data;
});

export const fetchAdminHuddleTasks = createAsyncThunk<
    IUserTask[],
    {
        tenantId: string;
    }
>('fetchAdminHuddleTasks', async ({ tenantId }) => {
    const adminHuddleTasks = await dentalApi.getTasksByTarget(tenantId, TaskTargets.AdminHuddle);
    return adminHuddleTasks.data;
});

const MY_NAMESPACE = '1b671a64-40d5-491e-99b0-da01ff1f3341';
const cat1Id = uuid('cat1', MY_NAMESPACE);
const cat2Id = uuid('cat2', MY_NAMESPACE);

const testMenu: IWorkListsMenu = {
    categories: [
        {
            id: cat1Id,
            displayName: 'Before Approval',
        },
        {
            id: cat2Id,
            displayName: 'After Approval',
        },
    ],
    workLists: [
        {
            id: uuid('4', MY_NAMESPACE),
            categoryId: cat1Id,
            displayName: 'Predetermination Requests',
            component: 'predeterminedRequests',
        },
        {
            id: uuid('11', MY_NAMESPACE),
            categoryId: cat1Id,
            displayName: 'Missing Slips',
            component: 'missingSlips',
        },
        {
            id: uuid('5', MY_NAMESPACE),
            categoryId: cat1Id,
            displayName: 'Ready to Review',
            component: 'readyToReview',
        },
        {
            id: uuid('6', MY_NAMESPACE),
            categoryId: cat2Id,
            displayName: 'Approved - Insurance',
            component: 'billedInsurance',
            featureFlag: FeatureFlag.ECR,
        },
        {
            id: uuid('7', MY_NAMESPACE),
            categoryId: cat2Id,
            displayName: 'Approved - Slide/Self-Pay',
            component: 'billedPatient',
            featureFlag: FeatureFlag.ECR,
        },
        {
            id: uuid('8', MY_NAMESPACE),
            categoryId: cat2Id,
            displayName: 'Ready to Reapprove',
            component: 'readyToRebill',
            permissions: [DentalPermissions.ReadyToRebill, GlobalPermissions.GlobalAdmin],
            featureFlag: FeatureFlag.ECR,
        },
        {
            id: uuid('9', MY_NAMESPACE),
            categoryId: cat2Id,
            displayName: 'Denials',
            component: 'denials',
            featureFlag: FeatureFlag.ECR,
        },
        {
            id: uuid('10', MY_NAMESPACE),
            categoryId: cat2Id,
            displayName: 'Edit Encounters',
            component: 'editEncounters',
            permissions: [GlobalPermissions.GlobalAdmin, DentalPermissions.Coder],
            featureFlag: FeatureFlag.ECR,
        },
    ],
};

async function fetchWorkListsMenu(): Promise<IWorkListsMenu> {
    const wait = (timeToDelay: number) => new Promise((resolve) => setTimeout(resolve, timeToDelay));
    await wait(100); // wait 1s
    return testMenu;
}

export const getWorkListsMenu = createAsyncThunk('getWorkListsMenu', async () => {
    const res = await fetchWorkListsMenu();
    return res;
});
