import { createAsyncThunk } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import IEncounterClaimSummary from 'api/models/encounter-claim-summary.model';
import axios from 'axios';
import { AppThunk } from 'state/store';
import { selectSelectedClaimHistoryEncounterSummary } from './claim-history.selectors';
import { updateSelectedEncounterSummaryStatus } from '../ledger.slice';
import { batch } from 'react-redux';
import { handleShouldCloseEncounterNoteModal } from 'state/slices/admin-huddle/worklists/encounter-worklists/encounter-worklists.actions';
import IWorkListEncounterView from 'api/models/worklist-encounter-view';

export const getEncounterClaimsSummary = createAsyncThunk<
    IEncounterClaimSummary,
    {
        tenantId: string;
        encounterId: string;
    },
    { rejectValue: string }
>('getEncounterClaimsSummary', async ({ tenantId, encounterId }, { rejectWithValue, signal }) => {
    try {
        const source = axios.CancelToken.source();
        signal.addEventListener('abort', () => {
            source.cancel();
        });
        const { data } = await dentalApi.getClaimStatusSummary(tenantId, encounterId, source.token);
        return data;
    } catch (e) {
        if (axios.isAxiosError(e) && e.response) {
            return rejectWithValue(e.toString());
        } else {
            return rejectWithValue(JSON.stringify(e));
        }
    }
});

export const handleUpdateClaimHistoryEncounterStatus =
    (data: IWorkListEncounterView): AppThunk<void> =>
        (dispatch, getState) => {
            const selectedEncounterSummary = selectSelectedClaimHistoryEncounterSummary(getState());

            //If the encounter status has changed we need to update it.
            if (selectedEncounterSummary?.encounterStatus !== data.encounter?.status) {
                batch(() => {
                    if (data.encounter?.status) {
                        dispatch(updateSelectedEncounterSummaryStatus(data.encounter.status));
                        dispatch(handleShouldCloseEncounterNoteModal(data, selectedEncounterSummary));
                    }
                });
            }
        };
