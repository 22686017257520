import { Label, Stack } from '@fluentui/react';
import { Dictionary } from '@reduxjs/toolkit';
import IUserTask from 'api/models/user-task.model';
import UserTasksBadge from 'components/UserTasks/UserTasksBadge';
import { TaskType } from 'state/task-management/taskManagement.actions';

type Props = {
    label?: string;
    lookup: Dictionary<IUserTask>;
    taskType: TaskType;
    required?: boolean;
    style?: React.CSSProperties;
};

export default function TaskFieldLabel({ label, required, lookup, taskType, style }: Props): JSX.Element | null {
    const task = taskType && lookup ? lookup[taskType] : undefined;

    if (!task) return label ? <Label required={required}>{label}</Label> : null;

    return (
        <Label required={required} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', ...style }}>
            <span style={{ marginRight: 5 }}>{label}</span>
            <UserTasksBadge size="small" showTaskCount={false} style={{ paddingBottom: 2 }} userTasks={[task]} />
        </Label>
    );
}
