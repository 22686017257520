import { IChartProcedure } from 'api/models/chart.model';
import ProcedureDiagnosisConflictRuleGenerator from './procedureDiagnosisConflictRuleGenerator';
import { toothSpriteReferences } from 'pages/Charting/components/ToothCanvas/spriteList';
import { map } from 'lodash';
import * as conflictRules from './procedure-diagnosis-conflict-rules';
import Pipeline from '../pipeline';
import { Dictionary } from '@reduxjs/toolkit';
import { IDiagnosis } from 'api/models/diagnosis.model';

type PipelineArgs = {
    chartProcedures: IChartProcedure[];
    currentProcedures: IChartProcedure[];
    diagnosisData: Dictionary<IDiagnosis>;
    selectedTeeth: number[];
};

export default class ProcedureDiagnosisConflictRulesPipeline extends Pipeline<IChartProcedure> {
    public selectedTeeth: number[] = [];
    public currentProcedures: IChartProcedure[];
    public diagnosisData: Dictionary<IDiagnosis>;

    constructor({ chartProcedures, selectedTeeth, currentProcedures, diagnosisData }: PipelineArgs) {
        super(chartProcedures);
        this.selectedTeeth = selectedTeeth;
        this.currentProcedures = currentProcedures;
        this.diagnosisData = diagnosisData;

        this.generateValidProceduresWithDx();
    }

    /**
     * Geth all the IChartProcedures that are the same procedure as the specified IChartProcedure
     *
     * @param {IChartProcedure} procedure
     * @return {*}  {IChartProcedure[]}
     * @memberof ProcedureDiagnosisConflictRulesPipeline
     */
    public getSameProcedures(procedure: IChartProcedure): IChartProcedure[] {
        return this.currentProcedures.filter((p) => p.id === procedure.id);
    }

    /**
     * Geth all the IChartProcedures that are the same procedure as the specified IChartProcedure
     *
     * @param {IChartProcedure} procedure
     * @return {*}  {IChartProcedure[]}
     * @memberof ProcedureDiagnosisConflictRulesPipeline
     */
    public getProceduresWithSameProcedureId(procedure: IChartProcedure): IChartProcedure[] {
        return this.currentProcedures.filter((p) => p.procedureId === procedure.procedureId);
    }

    /**
     * Get IChartProcedure from currentProcedures data
     *
     * @param {IChartProcedure} procedure
     * @return {*}  {(IChartProcedure | undefined)}
     * @memberof ProcedureDiagnosisConflictRulesPipeline
     */
    public getChartProcedure(procedure: IChartProcedure): IChartProcedure | undefined {
        return this.currentProcedures.find((p) => p.id === procedure.id);
    }

    /**
     * Determine if the specified IChartProcedure exists already in current chart procedures
     *
     * @param {IChartProcedure} procedure
     * @return {*}  {boolean}
     * @memberof ProcedureDiagnosisConflictRulesPipeline
     */
    public chartHasProcedure(procedure: IChartProcedure): boolean {
        return this.currentProcedures.findIndex((p) => p.procedureId === procedure.procedureId) > -1;
    }

    /**
     * Get list of quads for the specified teeth
     *
     * @param {number[]} selectedTeeth
     * @return {*}  {(('ur' | 'ul' | 'll' | 'lr')[])}
     * @memberof ProcedureDiagnosisConflictRulesPipeline
     */
    public getTeethQuads(selectedTeeth: number[]): ('ur' | 'ul' | 'll' | 'lr')[] {
        const quads = {
            ul: [1, 2, 3, 4, 5, 6, 7, 8],
            ur: [9, 10, 11, 12, 13, 14, 15, 16],
            lr: [17, 18, 19, 20, 21, 22, 23, 24],
            ll: [25, 26, 27, 28, 29, 30, 31, 32],
        };

        const listOfQuads: ('ur' | 'ul' | 'll' | 'lr')[] = [];
        const positions = this.getTeethPosition(selectedTeeth);

        positions.forEach((position) => {
            if (position) {
                const q = map(quads, (q, key) => (q.indexOf(position) > -1 ? key : undefined)).filter((q) => q !== undefined)[0];
                if (q) listOfQuads.push(q as 'ur' | 'ul' | 'll' | 'lr');
            }
        });

        return listOfQuads;
    }

    /**
     * Get tooth position based on specified tooth numbers
     *
     * @param {number[]} selectedTeeth
     * @return {*}  {((number | undefined)[])}
     * @memberof ProcedureDiagnosisConflictRulesPipeline
     */
    public getTeethPosition(selectedTeeth: number[]): (number | undefined)[] {
        const positions = selectedTeeth.map((toothId) => toothSpriteReferences.find((ref) => ref.id === toothId)?.position);
        return positions;
    }

    /**
     *
     * Removes a tooth number from IChartProcedure and returns the IChartProcedure
     * @param {IChartProcedure} chartProcedure
     * @param {number} toothId
     * @return {*}  {IChartProcedure}
     * @memberof ProcedureDiagnosisConflictRulesPipeline
     */
    public removeToothIdFromChartProcedure(chartProcedure: IChartProcedure, toothId: number): IChartProcedure {
        const toothIds = chartProcedure.toothIds?.filter((id) => id !== toothId);
        return { ...chartProcedure, toothIds };
    }

    /**
     * Generates and runs relevant conflict rules on the specified IChartProcedure
     *
     * @private
     * @param {IChartProcedure} chartProcedure
     * @return {*}
     * @memberof ProcedureDiagnosisConflictRulesPipeline
     */
    private _getProceduresWithValidDiagnoses(chartProcedure: IChartProcedure) {
        const procedureResult = new ProcedureDiagnosisConflictRuleGenerator({
            diagnosisPipeline: this,
            chartProcedure: chartProcedure,
            rules: conflictRules,
        });
        this.addErrors(procedureResult.getErrors);
        if (!procedureResult.shouldRemoveItem) return procedureResult.getItem;
    }

    /**
     * Handles setting pipeline data based on rules
     *
     * @private
     * @memberof ProcedureDiagnosisConflictRulesPipeline
     */
    private generateValidProceduresWithDx() {
        if (this.items) {
            const procedures: IChartProcedure[] = [];
            this.items.forEach((chartProcedure: IChartProcedure) => {
                const newProcedure = this._getProceduresWithValidDiagnoses(chartProcedure);
                if (newProcedure) procedures.push(newProcedure as IChartProcedure);
            });
            this.setItems(procedures);
        }
    }
}
