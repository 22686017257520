import IWorkListView from 'api/models/worklist-view.model';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';

const billingProviderName: ISortableColumn<IWorkListView> = {
    key: 'billingProviderName',
    name: 'Billing Provider',
    fieldName: 'billingProviderName',
    minWidth: 100,
    maxWidth: 150,
};

export default billingProviderName;

