import { Label, SearchBox, Stack, useTheme } from '@fluentui/react';
import { Field } from 'components';

export default function ClaimRejectionsFilters() {
    const { palette } = useTheme();

    return (
        <Stack style={{ backgroundColor: palette.neutralLighterAlt, padding: 10 }} tokens={{ childrenGap: 10 }} wrap horizontal>
            <Stack tokens={{ childrenGap: 10 }} wrap horizontal grow>
                <Field.DateRange
                    label={'DOS Range'}
                    startDateFieldProps={{ style: { maxWidth: 90 } }}
                    endDateFieldProps={{ style: { maxWidth: 90 } }}
                ></Field.DateRange>
                <Field.DateRange
                    label={'Last Filed Range'}
                    startDateFieldProps={{ style: { maxWidth: 90 } }}
                    endDateFieldProps={{ style: { maxWidth: 90 } }}
                ></Field.DateRange>
                <Field.DateRange
                    label={'Denied/Rejection Range'}
                    startDateFieldProps={{ style: { maxWidth: 90 } }}
                    endDateFieldProps={{ style: { maxWidth: 90 } }}
                ></Field.DateRange>
                <Stack.Item grow>
                    <Label styles={{ root: { paddingTop: 0, paddingBottom: 4 } }}>Tags</Label>
                    <SearchBox placeholder="Search Tags" />
                </Stack.Item>
            </Stack>
        </Stack>
    );
}
