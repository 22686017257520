import { DocumentContext } from 'api/models/document.model';
import { SubSection } from 'components';
import Upload from 'components/Upload';
import { useSelector } from 'hooks';
import { selectEditPatient } from 'state/slices/edit-patient/edit-patient.selectors';

function PayersDocuments(): JSX.Element | null {
    const patient = useSelector(selectEditPatient);

    if (!patient) return null;

    return (
        <SubSection title="Documents">
            <Upload context={DocumentContext.Payer} patientId={patient.id} />
        </SubSection>
    );
}

export default PayersDocuments;
