import { EncounterWorkList } from 'state/slices/admin-huddle/worklists/encounter-worklists/encounter-worklists.state';
import OpenBilledEncounters from './OpenBilledEncounters';

export default function BilledInsurance() {
    return (
        <OpenBilledEncounters
            workLists={[
                EncounterWorkList.BilledInsurance,
                EncounterWorkList.BilledInsuranceCorrectionNeeded,
                EncounterWorkList.BilledInsuranceCorrectionAmend,
            ]}
        />
    );
}
