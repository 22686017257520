import { Stack, Label, SelectionMode, Text, IconButton, DefaultButton, useTheme } from '@fluentui/react';
import { Section, SortableDetailsList } from 'components';

export default function SelectedClaimRejection() {
    const { palette } = useTheme();
    return (
        <Stack
            tokens={{ childrenGap: 10 }}
            style={{ backgroundColor: palette.neutralLighterAlt, padding: 10, overflow: 'auto' }}
            grow
        >
            <Stack tokens={{ childrenGap: 10 }} horizontal wrap grow>
                <Stack.Item>
                    <Label>Patient</Label>
                    <Text>Johnathon K. (48, Male)</Text>
                </Stack.Item>
                <Stack.Item>
                    <Label>ICN</Label>
                    <Text>16545115151</Text>
                </Stack.Item>
                <Stack.Item>
                    <Label>Payer</Label>
                    <Text>Blue Cross of TX</Text>
                </Stack.Item>
                <Stack.Item>
                    <Label>Billing Order</Label>
                    <Text>Primary</Text>
                </Stack.Item>
                <Stack horizontal verticalAlign="center">
                    <IconButton iconProps={{ iconName: 'Mail' }} />
                    <DefaultButton styles={{ root: { minWidth: 0 } }} text="EOS" style={{ paddingRight: 0, paddingLeft: 0 }} />
                </Stack>
            </Stack>
            <Stack.Item style={{ overflow: 'hidden' }} grow>
                <SortableDetailsList
                    selectionMode={SelectionMode.none}
                    compact
                    items={[]}
                    columns={[
                        { key: 'carc', name: 'CARC', minWidth: 75, maxWidth: 80 },
                        { key: 'rarc', name: 'RARC', minWidth: 75, maxWidth: 80 },
                        { key: 'Code', name: 'Code', minWidth: 120, maxWidth: 130 },
                        { key: 'Fee', name: 'Allowed', minWidth: 120, maxWidth: 130 },
                        { key: 'eobAllowed', name: 'EOB Allowed', minWidth: 120, maxWidth: 130 },
                        { key: 'paid', name: 'Paid', minWidth: 120, maxWidth: 130 },
                        { key: 'conractual', name: 'Contractual ADL.', minWidth: 120, maxWidth: 130 },
                        { key: 'additionalAdjustment', name: "Add'l Adjustment", minWidth: 120 },
                    ]}
                />
            </Stack.Item>
        </Stack>
    );
}
