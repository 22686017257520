import {
    Stack,
    Dropdown,
    TextField,
    IDropdownOption,
    Label,
    ITextFieldProps,
    IDropdownProps,
    IconButton,
    TooltipHost,
} from '@fluentui/react';
import { PaymentMethod } from 'api/models/patient.model';
import { Field } from 'components';
import { useSelector } from 'hooks';
import { FormEvent } from 'react';
import { useDispatch } from 'react-redux';
import {
    setPaymentHistoryMethod,
    setPaymentHistoryMethodIdentifier,
    setPaymentHistoryDateOfEntry,
    clearPaymentHistoryFilters,
    setPaymentHistoryTransactionDate,
    setPaymentHistoryDateOfService,
} from 'state/slices/ledger/ledger.slice';
import { selectPaymentHistoryFilters } from 'state/slices/ledger/payment-history/payment-history.selectors';

export const renderHistoryFilterLabel = (props: ITextFieldProps | IDropdownProps | undefined) => {
    return <Label styles={{ root: { padding: 0 } }}>{props?.label}</Label>;
};

export default function PaymentHistoryFilterInputs() {
    const dispatch = useDispatch();

    const filters = useSelector(selectPaymentHistoryFilters);

    const { dateOfEntry, method, methodIdentifier, transactionDate, dateOfService } = filters;

    const _onMethodChange = (e?: FormEvent, option?: IDropdownOption) =>
        dispatch(setPaymentHistoryMethod(option?.key as PaymentMethod | ''));
    const _onMethodIdentifierChange = (e?: FormEvent, value?: string) => dispatch(setPaymentHistoryMethodIdentifier(value));
    const _onDateOfEntryChange = (e?: FormEvent, value?: string) => dispatch(setPaymentHistoryDateOfEntry(value));
    const _onTransactionDateChange = (e?: FormEvent, value?: string) => dispatch(setPaymentHistoryTransactionDate(value));
    const _onDateOfServiceChange = (e?: FormEvent, value?: string) => dispatch(setPaymentHistoryDateOfService(value));

    const _clearFilters = () => {
        dispatch(clearPaymentHistoryFilters());
    };

    const disableClearFilters = !dateOfEntry && !method && !methodIdentifier && !transactionDate && !dateOfService;

    return (
        <Stack styles={{ root: { padding: 5 } }} verticalAlign="end" horizontal tokens={{ childrenGap: 10 }}>
            <Field.Date
                hasDatePicker
                title="Encounter Date"
                label="Encounter Date"
                onRenderLabel={renderHistoryFilterLabel}
                value={dateOfService}
                onChange={_onDateOfServiceChange}
            />
            <Field.Date
                hasDatePicker
                title="Transaction Date"
                label="Transaction Date"
                onRenderLabel={renderHistoryFilterLabel}
                value={transactionDate}
                onChange={_onTransactionDateChange}
            />
            <Field.Date
                hasDatePicker
                title="Batch Date"
                label="Batch Date"
                onRenderLabel={renderHistoryFilterLabel}
                value={filters.dateOfEntry}
                onChange={_onDateOfEntryChange}
            />
            <Stack.Item>
                <Dropdown
                    title="Method"
                    placeholder="Select Method"
                    selectedKey={filters?.method}
                    onChange={_onMethodChange}
                    label="Method"
                    styles={{ root: { minWidth: 110 } }}
                    onRenderLabel={renderHistoryFilterLabel}
                    options={[
                        {
                            key: '',
                            text: 'Select Method',
                        },
                        {
                            key: PaymentMethod.Visa,
                            text: PaymentMethod.Visa,
                        },
                        {
                            key: PaymentMethod.Mastercard,
                            text: PaymentMethod.Mastercard,
                        },
                        {
                            key: PaymentMethod.AmEx,
                            text: PaymentMethod.AmEx,
                        },
                        {
                            key: PaymentMethod.Discover,
                            text: PaymentMethod.Discover,
                        },
                        {
                            key: PaymentMethod.Cash,
                            text: PaymentMethod.Cash,
                        },
                        {
                            key: PaymentMethod.Check,
                            text: 'Check/ACH',
                        },
                    ]}
                />
            </Stack.Item>
            <Stack.Item>
                <TextField
                    placeholder="Enter Identifier"
                    title="Identifier"
                    label="Identifier"
                    onRenderLabel={renderHistoryFilterLabel}
                    value={filters.methodIdentifier}
                    onChange={_onMethodIdentifierChange}
                />
            </Stack.Item>
            <Stack.Item>
                <TooltipHost content="Clear Filters">
                    <IconButton
                        disabled={disableClearFilters}
                        iconProps={{ iconName: 'Cancel' }}
                        onClick={_clearFilters}
                    ></IconButton>
                </TooltipHost>
            </Stack.Item>
        </Stack>
    );
}
