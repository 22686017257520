import { createAsyncThunk } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import forms from 'forms/forms';
import IForm, { FormName, FormStatus } from 'api/models/form';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { AppThunk, RootState } from 'state/store';
import { v4 as uuid } from 'uuid';
import { setFormFieldData, setSelectedForm, setBaseFormDataProp, setSelectedFormData, cleanupSelectedForm } from './forms.slice';
import { addProcedures } from '../charting/procedures/procedures.actions';
import { map } from 'lodash';
import { RiskType } from 'forms/CariesRiskAdult/types';
import { ProcedureActionType } from '../charting/chart/chart.slice';
import { ChartProcedureStatus } from 'api/models/chart.model';

export const getPatientFormById = createAsyncThunk(
    'getPatientFormById',
    async ({ tenantId, formId, patientId }: { tenantId: string; formId: string; patientId: string }) => {
        const res = await dentalApi.getPatientFormById(tenantId, patientId, formId);
        return res.data;
    },
);

export const addPatientForm = createAsyncThunk(
    'addPatientForm',
    async ({ tenantId, patientId, model }: { tenantId: string; patientId: string; model: IForm }) => {
        const res = await dentalApi.addPatientForm(tenantId, patientId, model);
        return res.data;
    },
);

export const updatePatientForm = createAsyncThunk(
    'updatePatientForm',
    async ({ tenantId, patientId, model }: { tenantId: string; patientId: string; model: IForm }) => {
        const res = await dentalApi.updatePatientForm(tenantId, patientId, model);
        return res.data;
    },
);

export const getAllPatientForms = createAsyncThunk(
    'getAllPatientForms',
    async ({ tenantId, patientId }: { tenantId: string; patientId: string }) => {
        const res = await dentalApi.getAllPatientForms(tenantId, patientId);
        return res.data;
    },
);

export const searchPatientForms = createAsyncThunk(
    'searchPatientForms',
    async ({
        tenantId,
        patientId,
        query,
    }: {
        tenantId: string;
        patientId: string;
        query: { encounterId?: string; appointmentId?: string; formName?: FormName; status?: string };
    }) => {
        const res = await dentalApi.searchPatientForms(tenantId, patientId, query);
        return res.data;
    },
);

export const setNewForm =
    (formName: FormName, patientId: string, encounterId?: string) =>
    (dispatch: ThunkDispatch<RootState, Record<string, never>, AnyAction>): void => {
        const formData = forms[formName];
        const model: IForm = {
            appointmentId: '',
            formName,
            patientId,
            encounterId,
            id: uuid(),
            status: FormStatus.Pending,
            isDeleted: false,
            data: formData.questions,
        };
        dispatch(setSelectedForm(model));
    };

export const createFormFromSelectedForm =
    (tenantId: string, patientId: string, encounterId?: string, status?: FormStatus, save?: boolean) =>
    (dispatch: ThunkDispatch<RootState, Record<string, never>, AnyAction>, getState: () => RootState): void => {
        const currentEncounterDate = getState().encounter.patientEncounter?.createdOn;
        const selectedForm = getState().forms.selectedForm;
        const newStatus = status ? status : FormStatus.Pending;
        if (selectedForm) {
            const model: IForm = {
                ...selectedForm,
                patientId,
                encounterId,
                completedDate: status === FormStatus.Completed ? currentEncounterDate : undefined,
                status: newStatus,
                id: uuid(),
                _etag: undefined,
            };
            dispatch(setSelectedForm(model));
            if (save) {
                dispatch(addPatientForm({ tenantId, patientId, model }));
                dispatch(cleanupSelectedForm());
            }
        }
    };

let timer: NodeJS.Timeout | null = null;
export const saveSelectedForm =
    (tenantId: string, patientId: string, cleanupOnSave?: boolean): AppThunk =>
    (dispatch, getState) => {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            const { selectedForm, data } = getState().forms;
            if (selectedForm) {
                const indexOfSelectedForm = data.findIndex((f) => f.id === selectedForm.id);
                if (indexOfSelectedForm > -1) {
                    dispatch(updatePatientForm({ tenantId, patientId, model: selectedForm }));
                } else {
                    dispatch(addPatientForm({ tenantId, patientId, model: selectedForm }));
                }
                if (cleanupOnSave) dispatch(cleanupSelectedForm());
            }
        }, 2000);
    };

export const setFormFieldDataAndSave =
    (path: any, value: any, tenantId: string, patientId: string) =>
    async (dispatch: ThunkDispatch<RootState, null, AnyAction>): Promise<void> => {
        await dispatch(setFormFieldData({ path, value }));
        dispatch(saveSelectedForm(tenantId, patientId));
    };

export const setSelectedFormDataAndSave =
    (value: any, tenantId: string, patientId: string) =>
    async (dispatch: ThunkDispatch<RootState, null, AnyAction>): Promise<void> => {
        await dispatch(setSelectedFormData(value));
        dispatch(saveSelectedForm(tenantId, patientId));
    };

export const setBaseFormDataPropAndSave =
    (path: keyof IForm, value: any, tenantId: string, patientId: string, encounterId?: string) =>
    async (dispatch: ThunkDispatch<RootState, null, AnyAction>, getState: () => RootState): Promise<void> => {
        await dispatch(setBaseFormDataProp({ path, value }));
        const selectedForm = getState().forms.selectedForm;
        let cleanupOnSave = false;
        if (path === 'status' && value === FormStatus.Reviewed) {
            cleanupOnSave = true;
        }
        if (path === 'completedDate') {
            await dispatch(setBaseFormDataProp({ path: 'status', value: FormStatus.Completed }));
            cleanupOnSave = true;
            if (
                selectedForm?.data?.score &&
                (selectedForm.formName === FormName.CariesRiskAdult || selectedForm.formName === FormName.CariesRiskChild)
            ) {
                const proceduresAsList = map(getState().tenant.procedures.data, (t) => t);
                const getProcedureIdWithCode = (code: string) => proceduresAsList.find((p) => p?.code === code)?.id || '';

                if (selectedForm.data.score === RiskType.High)
                    await dispatch(
                        addProcedures({
                            tenantId,
                            patientId,
                            procedureIds: [getProcedureIdWithCode('D0603')],
                            encounterId,
                            typeOverride: ProcedureActionType.Treatment,
                            status: ChartProcedureStatus.Completed,
                        }),
                    );
                if (selectedForm.data.score === RiskType.Moderate)
                    await dispatch(
                        addProcedures({
                            tenantId,
                            patientId,
                            procedureIds: [getProcedureIdWithCode('D0602')],
                            encounterId,
                            typeOverride: ProcedureActionType.Treatment,
                            status: ChartProcedureStatus.Completed,
                        }),
                    );
                if (selectedForm.data.score === RiskType.Low)
                    await dispatch(
                        addProcedures({
                            tenantId,
                            patientId,
                            procedureIds: [getProcedureIdWithCode('D0601')],
                            encounterId,
                            typeOverride: ProcedureActionType.Treatment,
                            status: ChartProcedureStatus.Completed,
                        }),
                    );
            }
        }
        dispatch(saveSelectedForm(tenantId, patientId, cleanupOnSave));
    };
