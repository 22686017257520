import { MessageBar, MessageBarType, Shimmer, Stack, Text, fitContentToBounds, useTheme } from '@fluentui/react';
import { useSelector, useTenantId } from 'hooks';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    batchActions,
    getHardCloseThroughDate,
    selectHardCloseThroughDate,
    selectHardCloseThroughDateLoading,
} from 'state/slices/tenant/batches.slice';
import { classicDateOnly } from 'utils/dateOnly';

export default function BatchHardCloseDate(): JSX.Element {
    const { palette } = useTheme();

    const dispatch = useDispatch();
    const tenantId = useTenantId();

    const hardClosedDate = useSelector(selectHardCloseThroughDate);
    const hardCloseDateLoading = useSelector(selectHardCloseThroughDateLoading);
    const loadingPending = hardCloseDateLoading === LoadingStatus.Pending;
    const loadingFailed = hardCloseDateLoading === LoadingStatus.Failed;
    const loadingCompleted = hardCloseDateLoading === LoadingStatus.Completed;

    useEffect(() => {
        if (tenantId) dispatch(getHardCloseThroughDate(tenantId));
        return () => {
            dispatch(batchActions.cleanupHardCloseDate());
        };
    }, [dispatch, tenantId]);

    return (
        <Stack
            style={{ backgroundColor: palette.neutralLighter, borderRadius: '8px', padding: '5px', inlineSize: 'fit-content' }}
            verticalAlign="center"
            tokens={{ childrenGap: 8 }}
            horizontal
        >
            <Text>System Hard-Closed Through: </Text>
            <Text>
                {loadingPending ? (
                    <div style={{ width: 80 }}>
                        <Shimmer />
                    </div>
                ) : loadingFailed ? (
                    <MessageBar messageBarType={MessageBarType.error}>Error</MessageBar>
                ) : loadingCompleted ? (
                    hardClosedDate ? (
                        classicDateOnly(hardClosedDate)
                    ) : (
                        'N/A'
                    )
                ) : null}
            </Text>
        </Stack>
    );
}
