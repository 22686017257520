import { useState } from 'react';
import {
    IconButton,
    Icon,
    SelectionMode,
    DetailsListLayoutMode,
    TooltipHost,
    Sticky,
    StickyPositionType,
    DetailsHeader,
    IContextualMenuItem,
    IButtonStyles,
    Link,
    MessageBar,
    ProgressIndicator,
    ConstrainMode,
    IColumn,
    Text,
} from '@fluentui/react';
import { TModal } from 'components';
import SortableDetailsList from '../SortableDetailsList/SortableDetailsList';
import { classicDateOnly } from 'utils/dateOnly';
import { IDocument, DocumentContext } from 'api/models/document.model';
import { renderFileIcon, DocumentItemReference } from 'components/Upload';
import { useDispatch } from 'react-redux';
import { deleteUploadedDocument } from 'state/slices/documents/documents.actions';

import { useDownloadFile } from 'hooks/useDocumentLink';
import {
    documentsLoading,
    selectPatientDocumentsByContext,
    selectDocumentTypesByContext,
} from 'state/slices/documents/documents.selectors';
import { useSelector, useTenantId } from 'hooks';

type Props = {
    context: DocumentContext;
    compact?: boolean;
    disabled?: boolean;
    isFileNameReadOnly?: boolean;
    reference?: DocumentItemReference;
};

const UploadedFiles = ({ compact, disabled, isFileNameReadOnly, context, reference }: Props): JSX.Element => {
    const dispatch = useDispatch();
    const tenantId = useTenantId();

    const [open, setOpen] = useState(false);
    const [fileToRemove, setFileToRemove] = useState<IDocument>();

    const { downloadDocumentById } = useDownloadFile();

    const documents = useSelector((state) => selectPatientDocumentsByContext(state, { context, reference }));
    const documentTypes = useSelector((state) => selectDocumentTypesByContext(state, context));
    const loading = useSelector(documentsLoading);

    function onRemoveUploadedFile(document: IDocument) {
        dispatch(deleteUploadedDocument(tenantId, document));
    }

    function openModal(fileToRemove: IDocument) {
        setFileToRemove(fileToRemove);
        setOpen(true);
    }

    function closeModal() {
        setOpen(false);
    }

    function onFinish() {
        if (fileToRemove) onRemoveUploadedFile(fileToRemove);
        closeModal();
    }

    function onDismissed() {
        setFileToRemove(undefined);
    }

    const getDocumentTypeName = (id: string | undefined) => documentTypes?.find((docType) => docType.id === id)?.displayName;

    const columns: IColumn[] = [
        {
            key: 'column0',
            name: 'Document Type',
            data: 'string',
            iconName: '',
            isIconOnly: true,
            minWidth: 35,
            maxWidth: 35,
            isPadded: true,
            onRender: (item: IDocument) => (
                <TooltipHost content={`Remove '${item.displayName}${item.extension ? `.${item.extension}` : ''}'`}>
                    <IconButton
                        onClick={() => {
                            openModal(item);
                        }}
                        iconProps={{ iconName: 'PageRemove' }}
                        disabled={disabled}
                    />
                </TooltipHost>
            ),
        },
        {
            key: 'column1',
            name: 'Document Type',
            data: 'string',
            iconName: 'Page',
            isIconOnly: true,
            minWidth: 18,
            maxWidth: 18,
            isPadded: true,
            onRender: (item: IDocument) => (
                <Icon style={{ fontSize: '16px' }} title={item.extension} iconName={renderFileIcon(item.extension)} />
            ),
        },
        {
            key: 'column2',
            name: 'File Name',
            data: 'string',
            fieldName: 'displayName',
            minWidth: 100,
            maxWidth: 150,
            isPadded: true,
            getValueKey: (item: IDocument) => `${item.displayName}.${item.extension}`,
            onRender: (item: IDocument) => (
                <>
                    {isFileNameReadOnly ? (
                        <span>
                            {item.displayName}.{item.extension}
                        </span>
                    ) : (
                        <TooltipHost content={`Download '${item.displayName}.${item.extension}'`}>
                            <Link onClick={() => downloadDocumentById(item.id)}>
                                {item.displayName}.{item.extension}
                            </Link>
                        </TooltipHost>
                    )}
                </>
            ),
        },
        {
            key: 'column3',
            name: 'Document Type',
            data: 'object',
            minWidth: 100,
            maxWidth: 170,
            isPadded: true,
            fieldName: 'typeId',
            getValueKey: (item: IDocument) => {
                const name = getDocumentTypeName(item.typeId);
                return name ? name : '';
            },
            onRender: (item: IDocument) => {
                const name = getDocumentTypeName(item.typeId);
                return <span>{name ? name : "Couldn't find document type"}</span>;
            },
        },
        {
            key: 'column5',
            name: 'Upload Date',
            data: 'string',
            fieldName: 'uploadedDate',
            minWidth: 120,
            maxWidth: 120,
            isPadded: true,
            onRender: (item: IDocument) => <span>{item.uploadedDate ? classicDateOnly(item.uploadedDate) : ''}</span>,
        },
        {
            key: 'column4',
            name: 'Expiration Date',
            data: 'string',
            fieldName: 'expirationDate',
            minWidth: 120,
            maxWidth: 120,
            isPadded: true,
            onRender: (item: IDocument) => <span>{item.expirationDate ? classicDateOnly(item.expirationDate) : ''}</span>,
        },
    ];

    const menuItems: IContextualMenuItem[] = documents.map((d) => ({
        key: d.id,
        onClick: () => downloadDocumentById(d.id),
        name: `${d.displayName}${d.extension ? `.${d.extension}` : ''}`,
        iconProps: { iconName: renderFileIcon(d.extension) },
        secondaryText: getDocumentTypeName(d.typeId),
    }));

    const menuButtonStyles: IButtonStyles = {
        flexContainer: {
            selectors: {
                '.ms-Button-menuIcon': {
                    display: 'none',
                },
            },
        },
    };

    return (
        <>
            <TModal
                title="Delete File"
                modalProps={{ isOpen: open, onDismissed, onDismiss: closeModal }}
                mainButtons={[
                    { type: 'PrimaryButton', text: 'Delete', onClick: onFinish },
                    { text: 'Cancel', onClick: closeModal },
                ]}
                isDraggable
            >
                <Text style={{ padding: 10 }}>Are you sure you want to delete this file?</Text>
            </TModal>
            {!compact ? (
                <>
                    {loading === 'pending' && <ProgressIndicator />}
                    {documents && documents.length && loading === 'completed' ? (
                        <SortableDetailsList
                            onRenderDetailsHeader={(detailsHeaderProps) =>
                                detailsHeaderProps ? (
                                    <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
                                        <DetailsHeader {...detailsHeaderProps} />
                                    </Sticky>
                                ) : null
                            }
                            constrainMode={ConstrainMode.horizontalConstrained}
                            items={documents}
                            columns={columns}
                            sortOnMount={true}
                            sortColumns={['column5']}
                            initialSortDirection={'desc'}
                            selectionMode={SelectionMode.none}
                            layoutMode={DetailsListLayoutMode.justified}
                        />
                    ) : loading === 'completed' && !documents?.length ? (
                        <MessageBar>No documents have been added.</MessageBar>
                    ) : null}
                </>
            ) : (
                <TooltipHost delay={0} content={documents && documents.length > 0 ? 'View attachments' : 'No attachments found.'}>
                    <IconButton
                        disabled={!documents || !documents.length}
                        type="DefaultButton"
                        iconProps={{ iconName: 'Attach' }}
                        menuProps={{
                            items: menuItems,
                            styles: menuButtonStyles,
                        }}
                        styles={{
                            flexContainer: {
                                '.ms-Button-menuIcon': {
                                    display: 'none',
                                },
                            },
                        }}
                    />
                </TooltipHost>
            )}
        </>
    );
};

export default UploadedFiles;
