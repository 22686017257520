import { IMessageBarProps, MessageBar, MessageBarButton, MessageBarType } from '@fluentui/react';
import ErrorTypes, { errorTypeMessages } from 'state/errorTypes';

type ErrorTypeMessageBarProps = {
    error?: ErrorTypes;
} & IMessageBarProps;

function ErrorTypeMessageBar(props: ErrorTypeMessageBarProps) {
    const { error, children } = props;

    if (!error) return null;

    return (
        <MessageBar {...props} messageBarType={MessageBarType.error}>
            {errorTypeMessages[error] ?? 'Something went wrong.'}
            {children}
        </MessageBar>
    );
}

export default ErrorTypeMessageBar;
