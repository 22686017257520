import IAdjustmentReason from 'api/models/adjustment-reason.model';
import { financialReports, IReport } from 'api/models/embed-report.model';
import ILocationOfCare, { LocationOfCareDepartmentType } from 'api/models/Scheduling/locationsOfCare.model';
import { map, orderBy, sortBy } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'state/store';
import { IReportingState } from './reporting.state';
import LookupBase, { LookupCodeBase } from 'api/models/lookup-base.model';
import { IDropdownOption } from '@fluentui/react';

export const selectReportingState = (state: RootState): IReportingState => state.reporting;

export const selectReportingValidationErrors = createSelector(selectReportingState, (state) => state.reportValidationErrors);

export const selectReportList = createSelector(selectReportingState, (state) =>
    state.reports
        ? (map(state.reports) as IReport[]).filter((report) => report.service === 'dental').filter((report) => !report.isDeleted)
        : [],
);
export const selectFinancialReportsList = createSelector(selectReportList, (reports) =>
    orderBy(
        reports.filter((report) => (report.key ? financialReports.includes(report.key) : false)),
        (report) => report.displayName,
        'asc',
    ),
);

export const selectReportProperties = createSelector(selectReportingState, (state) => state.reportProperties ?? {});

export const selectExtraReportData = createSelector(selectReportingState, (state) => state.extraReportData ?? {});
export const selectLocationsOfCareFromReporting = createSelector(selectExtraReportData, (data) => {
    const locs = sortBy(
        (map(data.locationsOfCare ?? {}).filter((loc) => !loc?.isDeleted) as ILocationOfCare[]).filter(
            (loc) => loc.departmentType === LocationOfCareDepartmentType.Dental,
        ),
        'displayName',
    );
    return locs;
});

export const selectAdjustmentReasonsFromReporting = createSelector(
    selectExtraReportData,
    (data) => map(data.adjustmentReasons ?? {}).filter((adjustmentReason) => !adjustmentReason?.isDeleted && !adjustmentReason?.isSystemUseOnly) as IAdjustmentReason[],
);

export const encounterStatuses = createSelector(selectReportingState, (data) => data.encounterStatus ?? {});

export const encounterStatusesList = createSelector(encounterStatuses, (data) => {
    return map(data).filter((es) => !es?.isDeleted) as LookupCodeBase[];
});

export const encounterStatusOption = createSelector(encounterStatusesList, (data) => {
    const option: IDropdownOption[] = sortBy(
        map(data).map((es) => ({
            key: es.code,
            text: es.displayName,
        })) as IDropdownOption[],
        'text',
    );
    return option;
});

export const patientTrackerStatus = createSelector(selectReportingState, (data) => data.patientTracker ?? {});

export const patientTrackerStatusList = createSelector(patientTrackerStatus, (data) => {
    return map(data).filter((es) => !es?.isDeleted) as LookupCodeBase[];
});

export const patientTrackerStatusOption = createSelector(patientTrackerStatusList, (data) => {
    const option: IDropdownOption[] = map(data).map((es) => ({
        key: es.code,
        text: es.displayName,
    }));
    return option;
});
