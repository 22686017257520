import { Callout, DirectionalHint, Icon, Spinner, Stack, useTheme, MessageBar, MessageBarType } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { useBoolean } from '@uifabric/react-hooks';
import { ClinicalAlertType, IClinicalAlerts } from 'api/models/clinical-alert.model';
import { map } from 'lodash';
import { MouseEvent, useState, useEffect } from 'react';
import AlertCalloutSection from './ClinicalAlertCalloutSection';
import dentalApi from 'api/dental.api';
import { useTenantId } from 'hooks';
import { LoadingStatus } from 'interfaces/loading-statuses';

type ClinicalAlertCalloutProps = {
    patientId: string;
    iconSize?: number;
    alertTypes?: ClinicalAlertType[];
};

export default function StaticClinicalAlertCallout({ patientId, iconSize, alertTypes }: ClinicalAlertCalloutProps) {
    const { semanticColors } = useTheme();
    const tenantId = useTenantId();
    const [isCalloutVisible, { setFalse: setCalloutNotVisible, toggle }] = useBoolean(false);
    const [alerts, setAlerts] = useState<IClinicalAlerts | undefined>();
    const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.Idle);
    const id = useId();

    const mappedClinicalAlerts = alerts ? map(alerts, (alert) => alert).filter((alert) => !alert.isDeleted) : [];

    useEffect(() => {
        if (!isCalloutVisible) {
            setAlerts(undefined);
            setLoading(LoadingStatus.Idle);
        }
    }, [isCalloutVisible]);

    if (!alertTypes?.length) return null;

    const toggleCallout = async (ev?: MouseEvent<HTMLElement>) => {
        ev?.stopPropagation();
        toggle();

        //State hasn't updated yet, so if toggling visible true must check if isCalloutVisible is false.
        if (!isCalloutVisible) {
            try {
                setLoading(LoadingStatus.Pending);
                const { data } = await dentalApi.getPatientClinicalAlerts(tenantId, patientId);
                setAlerts(data);
                setLoading(LoadingStatus.Completed);
            } catch (e) {
                setLoading(LoadingStatus.Failed);
            }
        }
    };

    const calloutSections = mappedClinicalAlerts.length
        ? map(ClinicalAlertType, (type) => (
              <AlertCalloutSection alerts={mappedClinicalAlerts} type={type} key={`${type}-${patientId}`} />
          ))
        : null;

    return (
        <Stack id={id} onClick={toggleCallout} style={{ cursor: 'pointer' }}>
            <StaticClinicalAlertCalloutIcon iconSize={iconSize} visible={alertTypes.length > 0} />
            {isCalloutVisible && (
                <Callout
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    gapSpace={0}
                    backgroundColor={semanticColors.warningBackground}
                    isBeakVisible={true}
                    onDismiss={setCalloutNotVisible}
                    target={`#${id}`}
                    calloutMaxHeight={500}
                >
                    <Stack tokens={{ childrenGap: 10 }} styles={{ root: { padding: 5, overflowY: 'auto' } }}>
                        {loading === LoadingStatus.Pending ? <Spinner label="Loading..." /> : null}
                        {loading === LoadingStatus.Failed ? (
                            <MessageBar messageBarType={MessageBarType.error}>Failed to load alerts.</MessageBar>
                        ) : null}
                        {calloutSections}
                    </Stack>
                </Callout>
            )}
        </Stack>
    );
}

export function StaticClinicalAlertCalloutIcon({ iconSize = 20, visible }: { iconSize?: number; visible?: boolean }) {
    const { semanticColors } = useTheme();
    if (!visible) return null;
    return (
        <Icon
            styles={{
                root: {
                    color: semanticColors.errorIcon,
                    fontSize: iconSize,
                    padding: 5,
                    userSelect: 'none',
                    cursor: 'pointer',
                },
            }}
            iconName={'WarningSolid'}
        />
    );
}
