import { DocumentBase } from 'api/document-base.model';

export default interface IChartNote extends DocumentBase {
    encounterId?: string;
    encounterDate?: string;
    noteType?: NoteType;
    attestationDate?: string;
    data?: unknown;
    appointmentId?: string;
    appointmentStartTime?: string;
    appointmentEndTime?: string;
}

export interface IChartNoteWithData<T> extends IChartNote {
    data: T;
}

export enum NoteType {
    ClinicalNote = 'clinical',
    ToothNote = 'tooth',
    CareCoordination = 'care-coordination',
    FollowUp = 'follow-up',
    PatientCommunication = 'patient-communication',
}

export const noteTypeText: Partial<Record<NoteType, string>> = {
    [NoteType.ClinicalNote]: 'Encounter',
    [NoteType.CareCoordination]: 'Care Coordination',
    [NoteType.FollowUp]: 'Follow-up',
    [NoteType.PatientCommunication]: 'Patient Comm.',
};

export interface IChartNoteWithValueData {
    value?: string;
    signatures?: AttestSignature[];
}

export interface IClinicalNoteData extends IChartNoteWithValueData {
    toothId?: number;
}

export type ClinicalNote = IChartNoteWithData<ClinicalNoteData>;
export type ClinicalNoteData = {
    value?: string;
    signatures?: AttestSignature[];
    appointmentId?: string;
    addendums?: IChartNoteAddendum[];
    billingProviderId?: string;
    supervisingProviderId?: string;
};
export type AttestSignature = {
    treatingProviderId?: string;
    signatureType?: ChartNoteSignatureType;
    signature?: string;
    date?: string;
    isHygieneAttesting?: boolean;
    billingProviderIdFullName?: string;
};

export interface IChartNoteAddendum {
    id: string;
    note?: string;
    signature?: AttestSignature;
}

export enum ChartNoteSignatureType {
    Attest = 'Attest',
    Amend = 'Amend',
    Sign = 'Sign',
}
