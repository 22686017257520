import { IQuickActions } from 'api/models/quick-action.model';
import { IQuickActionCategories, IQuickActionCategory } from 'api/models/quick-action-category-model';
import { useSelector } from 'hooks';
import useTenantId from 'hooks/useTenantId';
import { RouteParams } from 'interfaces/route-params';
import { filter } from 'lodash';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { ProcedureActionType } from 'state/slices/charting/chart/chart.slice';

import {
    cleanupQuickActions,
    selectedQuickActionId,
    selectedQuickActionType,
    selectFilteredQuickActionItems,
    selectQuickActionCategories,
    selectQuickActionCategoryList,
    selectQuickActionItems,
    selectSelectedCategory,
    selectSelectedCategoryId,
    selectSelectedQuickAction,
    setQuickActionListMode,
    setQuickActionType,
    setSelectedCategoryId,
    setSelectedQuickActionId,
    unselectedCategories,
    createQuickAction,
    updateQuickAction,
    updateQuickActionCategories,
} from 'state/slices/tenant/quickActions/quickActions.slice';
import { selectedQuickActionListMode } from '../../state/slices/tenant/quickActions/quickActions.slice';

function useQuickAction() {
    const dispatch = useDispatch();
    const { tenantId } = useParams<RouteParams>();
    const routeTenantId = useTenantId();
    const negotiatedTenantId = tenantId ? tenantId : routeTenantId;

    // General ===============================
    const _cleanupQuickActions = useCallback(() => {
        dispatch(cleanupQuickActions());
    }, [dispatch]);

    // Quick Action Items ==========================
    const _quickActionItems = useSelector(selectQuickActionItems);
    const _selectFilteredQuickActionItems = useSelector(selectFilteredQuickActionItems);
    const _selectedQuickActionType = useSelector(selectedQuickActionType);
    const _selectedQuickActionId = useSelector(selectedQuickActionId);
    const _selectedQuickActionListMode = useSelector(selectedQuickActionListMode);
    const _selectedQuickAction = useSelector(selectSelectedQuickAction);
    const _setSelectedQuickAction = useCallback(
        (selectedQuickActionId: string) => {
            dispatch(setSelectedQuickActionId(selectedQuickActionId));
        },
        [dispatch],
    );
    const _createQuickQAction = useCallback(
        (entity: IQuickActions) => {
            dispatch(createQuickAction({ tenantId: negotiatedTenantId, entity }));
        },
        [dispatch, negotiatedTenantId],
    );

    const _updateQuickQAction = useCallback(
        (entity: IQuickActions) => {
            dispatch(updateQuickAction({ tenantId: negotiatedTenantId, entity }));
        },
        [dispatch, negotiatedTenantId],
    );

    const _setQuickActionListMode = useCallback(
        (selectedQuickActionId: 'Icon' | 'Text') => {
            dispatch(setQuickActionListMode(selectedQuickActionId));
        },
        [dispatch],
    );

    const _setQuickActionType = useCallback(
        (selectedQuickActionId: ProcedureActionType) => {
            dispatch(setQuickActionType(selectedQuickActionId));
        },
        [dispatch],
    );

    // Quick Action Categories ==========================
    const _quickActionCategoryList = useSelector(selectQuickActionCategoryList);
    const _quickActionCategories = useSelector(selectQuickActionCategories);
    const _selectedCategoryId = useSelector(selectSelectedCategoryId);
    const _selectedCategory = useSelector(selectSelectedCategory);
    const _unselectedCategories = useSelector(unselectedCategories);

    const _searchCategories = (search: string | undefined) =>
        search
            ? filter(_quickActionCategoryList, (c) => c.displayName.toLowerCase().indexOf(search.toLowerCase()) > -1)
            : _quickActionCategoryList;

    const _setSelectedCategory = useCallback(
        (selectedCategoryId: string) => {
            dispatch(setSelectedCategoryId(selectedCategoryId));
        },
        [dispatch],
    );
    const _createQuickActionCategory = useCallback(
        (entity: IQuickActionCategory) => {
            if (_quickActionCategories) {
                const newEntity: IQuickActionCategories = {
                    ..._quickActionCategories,
                    displayName: _quickActionCategories.displayName ?? 'QuickActionCategories',
                    categories: _quickActionCategories.categories ? [entity, ..._quickActionCategories.categories] : [entity],
                };

                dispatch(updateQuickActionCategories({ tenantId: negotiatedTenantId, quickActionCategories: newEntity }));
            }
        },
        [dispatch, negotiatedTenantId, _quickActionCategories],
    );

    const _updateQuickActionCategory = useCallback(
        (entity: IQuickActionCategory) => {
            const indexOfCategory = _quickActionCategories?.categories?.findIndex((c) => c.id === entity.id);

            if (_quickActionCategories && indexOfCategory !== undefined && indexOfCategory > -1) {
                const newCategories = [...(_quickActionCategories.categories ?? [])];
                newCategories[indexOfCategory] = entity;
                const newEntity: IQuickActionCategories = {
                    ..._quickActionCategories,
                    displayName: _quickActionCategories.displayName ?? 'QuickActionCategories',
                    categories: newCategories,
                };

                dispatch(updateQuickActionCategories({ tenantId: negotiatedTenantId, quickActionCategories: newEntity }));
            }
        },
        [dispatch, negotiatedTenantId, _quickActionCategories],
    );

    return {
        // General
        cleanupQuickActions: _cleanupQuickActions,

        // Quick Action Items
        quickActionItems: _quickActionItems,
        filteredQuickActionItems: _selectFilteredQuickActionItems,
        selectedQuickActionId: _selectedQuickActionId,
        selectedQuickAction: _selectedQuickAction,
        selectedQuickActionListMode: _selectedQuickActionListMode,
        selectedQuickActionType: _selectedQuickActionType,
        setQuickActionListMode: _setQuickActionListMode,
        setSelectedQuickAction: _setSelectedQuickAction,
        setQuickActionType: _setQuickActionType,
        createQuickAction: _createQuickQAction,
        updateQuickAction: _updateQuickQAction,

        // Categories
        quickActionCategories: _quickActionCategoryList,
        searchCategories: _searchCategories,
        selectedCategoryId: _selectedCategoryId,
        selectedCategory: _selectedCategory,
        unselectedCategories: _unselectedCategories,
        setSelectedCategory: _setSelectedCategory,
        createQuickActionCategory: _createQuickActionCategory,
        updateQuickActionCategory: _updateQuickActionCategory,
    };
}

export default useQuickAction;
