import EditProperties from './EditProperties';
import LookupEditPanel, { EditComponentProps } from '../Shared/LookupEditPanel';
import IOperatory from 'api/models/Scheduling/operatory.model';

const builder = ({ selectedLookup, setSelectedLookup, setHasError, setSelectedLookupItemProp }: EditComponentProps) => (
    <EditProperties
        setSelectedLookupItemProp={setSelectedLookupItemProp}
        selectedLookup={selectedLookup}
        setSelectedLookup={setSelectedLookup}
        setHasError={setHasError}
    />
);

const EditPanel = () => (
    <LookupEditPanel<IOperatory> lookupType="Operatories" displayName="Operatory" editComponentBuilder={builder} />
);

export default EditPanel;
