import { Dropdown, IDropdownOption, Stack } from '@fluentui/react';
import { ReportType } from 'api/models/embed-report.model';
import { IEncounterStatusReportQuery } from 'api/models/encounter-reports.model';
import { Field } from 'components';
import { useTenantId } from 'hooks';
import { getValidationError } from 'hooks/useValidation';
import ReportWrapper from 'pages/Reporting/ReportWrapper';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import reportActionLookup from 'state/slices/reporting/reportActionLookup';
import {
    getAllLocationsOfCareForReports,
    getEncounterStatusOptions,
    getPatientTrackerOptions,
} from 'state/slices/reporting/reporting.actions';
import {
    encounterStatusOption,
    patientTrackerStatusOption,
    selectLocationsOfCareFromReporting,
    selectReportProperties,
    selectReportingValidationErrors,
} from 'state/slices/reporting/reporting.selectors';
import { setEncounterStatusProp, toggleEncounterStatusId } from 'state/slices/reporting/reporting.slice';
import { selectReportingProviderWithOptions, selectTreatingProviderOptions } from 'state/slices/tenant/providers.slice';
import { classicDateOnly } from 'utils/dateOnly';

function EncounterStatus() {
    const tenantId = useTenantId();
    const dispatch = useDispatch();

    const errors = useSelector(selectReportingValidationErrors);
    const locationsOfCare = useSelector(selectLocationsOfCareFromReporting);
    const _providerOptions = useSelector(selectTreatingProviderOptions);
    const _treatingProviderOptions = useSelector(selectReportingProviderWithOptions);
    const statusOption = useSelector(encounterStatusOption);
    const apptTrackingStatusOption = useSelector(patientTrackerStatusOption);

    const query = useSelector(selectReportProperties) as IEncounterStatusReportQuery;
    useEffect(() => {
        dispatch(getAllLocationsOfCareForReports({ tenantId }));
        dispatch(getEncounterStatusOptions());
        dispatch(getPatientTrackerOptions());
    }, []);

    const locationsOfCareOptions: IDropdownOption[] = locationsOfCare.map((loc) => ({
        key: loc.id,
        text: loc.displayName,
    }));

    const _onViewReport = () => {
        if (query) dispatch(reportActionLookup[ReportType.EncounterStatus]({ tenantId, ...query }));
    };

    return (
        <ReportWrapper
            validationConfig={[
                {
                    fieldName: 'Start Date',
                    validation: ['required'],
                    value: query.startDate,
                },
                {
                    fieldName: 'End Date',
                    validation: ['required'],
                    value: query.endDate,
                },
            ]}
            onViewReport={_onViewReport}
        >
            <Stack grow horizontal wrap tokens={{ childrenGap: 10 }}>
                <Stack.Item>
                    <Field.Date
                        label="Start Date"
                        required
                        value={query?.startDate ? classicDateOnly(query?.startDate, 'MM/dd/yyyy') : ''}
                        onChange={(ev, value) => {
                            const newDate = value ? classicDateOnly(value, 'yyyy-MM-dd') : undefined;
                            dispatch(setEncounterStatusProp({ path: 'startDate', value: newDate }));
                        }}
                        hasDatePicker
                        errorMessage={getValidationError(errors, 'Start Date') ? 'Start date is required.' : undefined}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.Date
                        label="End Date"
                        required
                        value={query?.endDate ? classicDateOnly(query?.endDate, 'MM/dd/yyyy') : ''}
                        onChange={(ev, value) => {
                            const newDate = value ? classicDateOnly(value, 'yyyy-MM-dd') : undefined;
                            dispatch(setEncounterStatusProp({ path: 'endDate', value: newDate }));
                        }}
                        isReasonable
                        disabled={!query?.startDate}
                        minReasonableErrorMessage="End date must be after start date."
                        minReasonableDate={query?.startDate ? new Date(classicDateOnly(query?.startDate)) : undefined}
                        hasDatePicker
                        errorMessage={getValidationError(errors, 'End Date') ? 'End date is required.' : undefined}
                    />
                </Stack.Item>

                <Stack.Item>
                    <Field.SearchCombo
                        placeholder="(Select Locations of Care)"
                        label="Locations of Care"
                        multiSelect
                        style={{ minWidth: 300 }}
                        options={locationsOfCareOptions}
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(toggleEncounterStatusId({ id: option.key as string, path: 'locationOfCare' }));
                        }}
                        selectedKey={query?.locationOfCare}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.SearchCombo
                        placeholder="(Select Providers)"
                        label="Treating Providers"
                        multiSelect
                        style={{ minWidth: 300 }}
                        options={_treatingProviderOptions}
                        selectedKey={query?.treatingProvider}
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(toggleEncounterStatusId({ id: option.key as string, path: 'treatingProvider' }));
                        }}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.SearchCombo
                        placeholder="(Select Providers)"
                        label="Attending Providers"
                        multiSelect
                        style={{ minWidth: 300 }}
                        options={_providerOptions}
                        selectedKey={query?.attendingProvider}
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(toggleEncounterStatusId({ id: option.key as string, path: 'attendingProvider' }));
                        }}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.SearchCombo
                        label="Encounter Status"
                        options={statusOption}
                        selectedKey={query?.status}
                        placeholder="(Select Encounter Status)"
                        multiSelect
                        style={{ minWidth: 300 }}
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(
                                    toggleEncounterStatusId({
                                        path: 'status',
                                        id: option.key as string,
                                    }),
                                );
                        }}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Field.SearchCombo
                        label="Patient Tracker Status"
                        options={apptTrackingStatusOption}
                        selectedKey={query?.apptTrackerStatus}
                        placeholder="(Select Patient Tracker Status)"
                        multiSelect
                        style={{ minWidth: 300 }}
                        onChange={(ev, option) => {
                            if (option?.key)
                                dispatch(
                                    toggleEncounterStatusId({
                                        path: 'apptTrackerStatus',
                                        id: option.key as string,
                                    }),
                                );
                        }}
                    />
                </Stack.Item>
            </Stack>
        </ReportWrapper>
    );
}

export default EncounterStatus;
