import { IChartProcedure } from 'api/models/chart.model';
import IForm from 'api/models/form';
import IPatientProblems from 'api/models/patient-problem.model';
import IPatientMedicationsView from 'api/models/patient-medication.model';
import IPatientVitals from 'api/models/patient-vital.model';
import IPatientAppointment from 'api/models/Scheduling/patientAppointment.model';
import { LoadingStatuses, LoadingStatus } from 'interfaces/loading-statuses';
import IPatientAllergies from 'api/models/patient-allergy.model';

export interface IQuickNotesState {
    assets: {
        schedule: {
            nextAppointment?: IPatientAppointment;
            loading: LoadingStatuses;
        };
        chartProcedures: {
            completed: IChartProcedure[];
            treatmentPlanned: IChartProcedure[];
            referred: IChartProcedure[];
            preAuthRequired: boolean;
            loading: LoadingStatuses;
        };
        pamv: {
            problems?: IPatientProblems;
            allergies?: IPatientAllergies;
            medications?: IPatientMedicationsView;
            vitals?: IPatientVitals;
            loading: LoadingStatuses;
        };
        forms: {
            cariesRisk?: IForm;
            dentalHistory?: IForm;
            softTissueExam?: IForm;
            patientProblem?: IForm;
            loading: LoadingStatuses;
        };
    };
}

const quickNotesInitialState: IQuickNotesState = {
    assets: {
        pamv: {
            loading: LoadingStatus.Idle,
        },
        schedule: {
            loading: LoadingStatus.Idle,
        },
        chartProcedures: {
            completed: [],
            treatmentPlanned: [],
            referred: [],
            preAuthRequired: false,
            loading: LoadingStatus.Pending,
        },
        forms: {
            loading: LoadingStatus.Pending,
        },
    },
};

export default quickNotesInitialState;
