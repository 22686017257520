import { useTheme, Stack } from '@fluentui/react';
import { PropsWithChildren } from 'react';
import { PatientDetailsHeader } from './PatientDetailsHeader';
import { PatientDetailsLabel, PatientDetailsLabelProps } from './PatientDetailsLabel';

const getLabelWidth = (labelVariant: 'small' | 'medium' | 'large') => {
    switch (labelVariant) {
        case 'small':
            return 60;
        case 'medium':
            return 120;
        case 'large':
            return 170;
    }
};

type PatientDetailsSectionProps = {
    sectionLabel?: string;
    items?: PatientDetailsLabelProps[] | PatientDetailsLabelProps[][];
    labelVariant?: 'small' | 'medium' | 'large';
};

export function PatientDetailSection({
    sectionLabel,
    items,
    labelVariant = 'medium',
    children,
}: PropsWithChildren<PatientDetailsSectionProps>) {
    const theme = useTheme();

    //Setup singular section or mulitple sections?
    let labels = null;
    if (hasMultipleColumns(items)) {
        const sections = items.map((section, index) => {
            const labelsWithText = section.map((item) => (
                <PatientDetailsLabel key={item.label} {...item} labelWidth={getLabelWidth(labelVariant)} />
            ));
            return (
                <Stack key={`section-${index}`} grow={1}>
                    {labelsWithText}
                </Stack>
            );
        });
        labels = (
            <Stack horizontal tokens={{ childrenGap: 10 }} grow={1}>
                {sections}
            </Stack>
        );
    } else {
        labels = items?.map((item) => (
            <PatientDetailsLabel key={item.label} {...item} labelWidth={getLabelWidth(labelVariant)} />
        ));
    }

    return (
        <Stack grow={1} styles={{ root: { backgroundColor: theme.semanticColors.bodyBackground, overflow: 'hidden' } }}>
            {sectionLabel && <PatientDetailsHeader text={sectionLabel} />}
            {labels !== null ? <Stack>{labels}</Stack> : null}
            {children ? <>{children}</> : null}
        </Stack>
    );
    function hasMultipleColumns(
        items: PatientDetailsLabelProps[] | PatientDetailsLabelProps[][] | undefined,
    ): items is PatientDetailsLabelProps[][] {
        return items !== undefined && Array.isArray(items[0]) && items[0].length > 1;
    }
}
