import { VitalNameType } from 'api/models/patient-vital.model';
import { getBPString, getReadingByVitalType } from 'state/slices/patient/vitals/vitals.selectors';
import { RootState } from 'state/store';

export default function Vitals({ state }: { state: RootState }): JSX.Element | null {
    const encounterId = state.encounter.patientEncounter?.id;
    const vitals = state.chartNotes.quickNotes.assets.pamv.vitals;
    const readingsForEncounter = vitals?.readings?.filter((reading) => reading.encounterId === encounterId);

    if (!vitals || !encounterId || !readingsForEncounter?.length) return null;

    const bpReadingValue = getBPString(readingsForEncounter);

    const height = getReadingByVitalType(readingsForEncounter, VitalNameType.Height);
    const weight = getReadingByVitalType(readingsForEncounter, VitalNameType.Weight);
    const o2Saturation = getReadingByVitalType(readingsForEncounter, VitalNameType.Saturation, '%');
    const pulse = getReadingByVitalType(readingsForEncounter, VitalNameType.PulseRate, ' bpm');
    const temperature = getReadingByVitalType(readingsForEncounter, VitalNameType.Temperature);
    const respirationRate = getReadingByVitalType(readingsForEncounter, VitalNameType.RespirationRate);

    const heightRefused = readingsForEncounter.find((v) => v.vitalName === VitalNameType.HeightRefused);
    const weightRefused = readingsForEncounter.find((v) => v.vitalName === VitalNameType.WeightRefused);
    const bpRefused = readingsForEncounter.find((v) => v.vitalName === VitalNameType.Refused);

    return (
        <>
            <h3>Vitals</h3>
            <ul>
                {heightRefused?.value !== 'true' ? height && <li>Height: {height}</li> : <li>Height: Not Performed</li>}
                {weightRefused?.value !== 'true' ? weight && <li>Weight: {weight}</li> : <li>Weight: Not Performed</li>}
                {(bpReadingValue) && (
                    <li>
                        Blood Pressure: {bpReadingValue}
                        {bpRefused?.value === 'true' ? 'Not Performed' : ''}
                    </li>
                )}
                {pulse && <li>Pulse: {pulse}</li>}
                {temperature && <li>Temperature: {temperature}</li>}
                {respirationRate && <li>Respiratory Rate: {respirationRate}</li>}
                {o2Saturation && <li>O2 Saturation: {o2Saturation}</li>}
            </ul>
        </>
    );
}
