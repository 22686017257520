import { useTheme } from '@fluentui/react';

function PreMedIcon(): JSX.Element {
    const { semanticColors } = useTheme();

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048.33 2048">
            <g id="Layer_1" data-name="Layer 1" focusable="false">
                <g>
                    <path
                        style={{ fill: '#fff' }}
                        d="m2007.33,315c-26-62-62.67-116.67-110-164-47.33-47.33-102.33-84.33-165-111C1669.67,13.33,1602.67,0,1531.33,0s-135.33,13-198,39c-62.67,26-118.33,63.33-167,112l-460.14,459.8,89.96,91.02-.16.18,550,550-.04-.38.38.38,90.02,89.36,460.98-459.36c48.67-49.33,86-105.33,112-168s39-128.33,39-197c-1.33-72.67-15-140-41-202Z"
                    />
                    <path d="m1536,832c17.33,0,32.33-6.33,45-19s19-27.67,19-45-6.33-32.33-19-45-27.67-19-45-19-32.33,6.33-45,19-19,27.67-19,45,6.33,32.33,19,45,27.67,19,45,19Z" />
                    <path d="m1152,704c-17.33,0-32.33,6.33-45,19s-19,27.67-19,45,6.33,32.33,19,45,27.67,19,45,19,32.33-6.33,45-19,19-27.67,19-45-6.33-32.33-19-45-27.67-19-45-19Z" />
                    <path
                        style={{ fill: '#d13438' }}
                        d="m1346,1252h0l-550-550h0l-89.8-91.2L151,1166c-48.67,49.33-86,105.33-112,168S0,1462.33,0,1531c1.33,72.67,15,140,41,202s62.67,116.67,110,164,102.33,84.33,165,111,129.67,40,201,40,135.33-13,198-39,118.33-63.33,167-112l554.36-555.64-90.36-89.36Z"
                    />
                    <path d="m1389,1005c12.67-12.67,19-27.67,19-45s-6.33-32.33-19-45-27.67-19-45-19c-18.67.67-34,7-46,19s-18,27-18,45,6.33,32.33,19,45,27.67,19,45,19,32.33-6.33,45-19Z" />
                </g>
                <g>
                    <path
                        style={{ fill: 'none' }}
                        d="m811.83,686.39l14.46,14.69-.05.06,490.59,490.59-.04-.38,44.72,44.72.33.33,74.18,73.36.33-.4-.33.4.6.6,445.12-443.88c46.4-47.06,82.5-101.2,107.27-160.91,24.74-59.63,37.29-123,37.32-188.37-1.3-69.45-14.52-134.62-39.29-193.69-24.77-59.06-60.19-111.87-105.27-156.95-45.11-45.11-98.29-80.88-158.06-106.31-29.8-12.68-61.07-22.33-92.93-28.67-31.93-6.35-65.39-9.58-99.46-9.58-66.1,0-129.88,12.56-189.57,37.32-59.7,24.77-113.49,60.85-159.87,107.24l-444.37,444.37"
                    />
                    <path d="m2007.33,315c-26-62-62.67-116.67-110-164-47.33-47.33-102.33-84.33-165-111C1669.67,13.33,1602.67,0,1531.33,0s-135.33,13-198,39c-62.67,26-118.33,63.33-167,112l-460.14,459.8,15.33,15.89,16-15.77h0l444.37-444.37c46.39-46.39,100.18-82.47,159.87-107.24,59.69-24.76,123.47-37.32,189.57-37.32,34.06,0,67.52,3.22,99.46,9.58,31.86,6.34,63.13,15.99,92.93,28.67,59.77,25.43,112.95,61.2,158.06,106.31,45.08,45.08,80.5,97.89,105.27,156.95,24.77,59.07,37.99,124.24,39.29,193.69-.03,65.37-12.58,128.74-37.32,188.37-24.77,59.71-60.87,113.85-107.27,160.91l-445.12,443.88h0l-15.38,16.06,15.11,14.95,460.98-459.36c48.67-49.33,86-105.33,112-168s39-128.33,39-197c-1.33-72.67-15-140-41-202Z" />
                </g>
            </g>
        </svg>
    );
}

export default PreMedIcon;
