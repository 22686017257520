import { ChartProcedureStatus, IChartCondition, IChartProcedure } from 'api/models/chart.model';
import { IProcedure } from 'api/models/procedure.model';
import Pipeline from './pipeline';
import * as conflictRules from './procedureConflictRules';
import ProcedureConflictRuleGenerator from './procedureConflictRuleGenerator';
import {
    PERMANENT_TEETH_REFS,
    PRIMARY_TEETH_REFS,
    toothSpriteReferences,
} from 'pages/Charting/components/ToothCanvas/spriteList';
import { find, flatten, indexOf, intersection, isEqual, map, range } from 'lodash';
import { Dictionary, current } from '@reduxjs/toolkit';
import { ConflictRuleType, ICondition } from 'api/models/lookup.model';
import ProcedureCodes, { extractionCodes } from './procedureCodes';
import ConditionCodes from './conditionCodes';
import { TeethByReference, ToothReference } from './dentition/dentition.selectors';
import { surfaceProcedureCodeLookup } from './surfaceProcedureCodeLookup';

type PipelineArgs = {
    chartProcedures: IChartProcedure[];
    currentProcedures: IChartProcedure[];
    newProcedures: IProcedure[];
    procedureData: Dictionary<IProcedure>;
    conditionsData: Dictionary<ICondition>;
    currentConditions: IChartCondition[];
    selectedTeeth: number[];
    statusOverride?: ChartProcedureStatus;
    teethReference: TeethByReference;
    patientEncounterId?: string;
};

export default class ProcedureConflictRulesPipeline extends Pipeline<IChartProcedure> {
    public selectedTeeth: number[] = [];
    public currentProcedures: IChartProcedure[];
    public newProcedures: IProcedure[];
    public procedureData: Dictionary<IProcedure>;
    public conditionsData: Dictionary<ICondition>;
    public currentConditions: IChartCondition[];
    public teethReference: TeethByReference;
    public statusOverride?: ChartProcedureStatus;
    public patientEncounterId?: string;

    constructor({
        newProcedures,
        chartProcedures,
        selectedTeeth,
        currentProcedures,
        procedureData,
        statusOverride,
        currentConditions,
        conditionsData,
        teethReference,
        patientEncounterId,
    }: PipelineArgs) {
        super(chartProcedures);
        this.selectedTeeth = selectedTeeth;
        this.newProcedures = newProcedures;
        this.currentProcedures = currentProcedures;
        this.procedureData = procedureData;
        this.statusOverride = statusOverride;
        this.conditionsData = conditionsData;
        this.currentConditions = currentConditions;
        this.teethReference = teethReference;
        this.patientEncounterId = patientEncounterId;

        this.generateValidProcedures();
    }

    /**
     * Get IProcedure from specified code
     *
     * @param {string} [code]
     * @return {*}  {(IProcedure | undefined)}
     * @memberof ProcedureBusinessRulesPipeline
     */
    public getProcedureFromCode(code?: string): IProcedure | undefined {
        return find(this.procedureData, (p) => p?.code === code);
    }

    public getEncounterChartProcedures() {
        return [...this.currentProcedures].filter((p) => p.encounterId === this.patientEncounterId);
    }
    // only pass the procedures we need to into this function. using intersected tooth ids.
    public getRelatedProcedures(chartProcedure: IChartProcedure): IChartProcedure[] {
        const currentProcedure = this.getProcedure(chartProcedure);

        const keyedProcArray = find(
            surfaceProcedureCodeLookup,
            (codes) => codes.findIndex((obj) => obj.code === currentProcedure?.code) > -1,
        );

        const relatedProcs =
            keyedProcArray?.map((data) => {
                const proc = this.getProcedureFromCode(data.code);
                const existingRelatedProcedures = this.getProceduresWithSameProcedureId(proc?.id);
                return existingRelatedProcedures;
            }) ?? [];

        return flatten(relatedProcs);
    }

    /**
     * Geth all the IChartProcedures that are the same procedure as the specified IChartProcedure
     *
     * @param {IChartProcedure} procedure
     * @return {*}  {IChartProcedure[]}
     * @memberof ProcedureConflictRulesPipeline
     */
    public getSameProcedures(procedure: IChartProcedure): IChartProcedure[] {
        return this.currentProcedures.filter((p) => p.id === procedure.id);
    }

    public allArchTeethSelected(procedure: IProcedure) {
        const { mandibular, maxillary } = this.teethReference;
        const { conflictRules } = procedure;

        const isMaxillaryOnly = indexOf(conflictRules, ConflictRuleType.maxillaryOnly) > -1;
        const isMandibularOnly = indexOf(conflictRules, ConflictRuleType.mandibularOnly) > -1;
        const arch = isMaxillaryOnly ? 'maxillary' : isMandibularOnly ? 'mandibular' : undefined;

        switch (arch) {
            case 'mandibular': {
                return allTeethSelected(mandibular, this.selectedTeeth);
            }
            case 'maxillary': {
                return allTeethSelected(maxillary, this.selectedTeeth);
            }
            default: {
                return false;
            }
        }

        function allTeethSelected(teeth: Dictionary<ToothReference>, selectedTeeth: number[]) {
            const teethIds = map(teeth, (tooth) => tooth?.id);
            return isEqual(intersection(teethIds, selectedTeeth), teethIds);
        }
    }

    /**
     * Geth all the IChartProcedures that are the same procedure as the specified IChartProcedure
     *
     * @param {IChartProcedure} procedure
     * @return {*}  {IChartProcedure[]}
     * @memberof ProcedureConflictRulesPipeline
     */
    public getProceduresWithSameProcedureId(procedureId?: string): IChartProcedure[] {
        return this.currentProcedures.filter((p) => p.procedureId === procedureId);
    }

    /**
     * Get IChartProcedure from currentProcedures data
     *
     * @param {IChartProcedure} procedure
     * @return {*}  {(IChartProcedure | undefined)}
     * @memberof ProcedureConflictRulesPipeline
     */
    public getChartProcedure(procedure: IChartProcedure): IChartProcedure | undefined {
        return this.currentProcedures.find((p) => p.id === procedure.id);
    }

    /**
     * Determine if the specified IChartProcedure exists already in current chart procedures
     *
     * @param {IChartProcedure} procedure
     * @return {*}  {boolean}
     * @memberof ProcedureConflictRulesPipeline
     */
    public chartHasProcedure(procedure: IChartProcedure): boolean {
        return this.currentProcedures.findIndex((p) => p.procedureId === procedure.procedureId) > -1;
    }

    /**
     * Determine if a specified tooth has a missing condition on it
     *
     * @param {number} toothId
     * @return {*}  {boolean}
     * @memberof ProcedureConflictRulesPipeline
     */
    public missingConditionOnTooth(toothId: number): boolean {
        const conditionsOnTooth = this.currentConditions
            .filter((c) => c.toothId === toothId)
            .map((c) => (c.conditionId ? this.conditionsData[c.conditionId] : undefined))
            .filter((c) => c !== undefined) as ICondition[];
        return conditionsOnTooth.findIndex((c) => c.code === ConditionCodes.Missing) > -1;
    }
    /**
     * Determine if a completed extraction exists on a tooth.
     *
     * @param {number} toothId
     * @return {*}  {boolean}
     * @memberof ProcedureConflictRulesPipeline
     */
    public completedExtractionExistsOnTooth(toothId: number): boolean {
        const proceduresOnTooth = this.currentProcedures
            .filter((p) => p.toothIds?.includes(toothId))
            .filter((p) => p.status === ChartProcedureStatus.Completed)
            .map((p) => (p.procedureId ? this.procedureData[p.procedureId] : undefined))
            .filter((p) => p !== undefined) as IProcedure[];
        return proceduresOnTooth.findIndex((p) => (p.code ? extractionCodes.includes(p.code as ProcedureCodes) : false)) > -1;
    }

    /**
     * Determine if all the specified teeth are pimary teeth.
     *
     * @param {number[]} teeth
     * @return {*}  {boolean}
     * @memberof ProcedureConflictRulesPipeline
     */
    public getAreTeethPrimary(teeth: number[]): boolean {
        const teethRefs = flatten(PRIMARY_TEETH_REFS.map((ref) => ref.id));
        const primaryTeeth = teeth.filter((tooth) => teethRefs.includes(tooth));
        return primaryTeeth.length === teeth.length;
    }

    /**
     * Determine if all the specified teeth are permanent teeth
     *
     * @param {number[]} teeth
     * @return {*}  {boolean}
     * @memberof ProcedureConflictRulesPipeline
     */
    public getAreTeethPermanent(teeth: number[]): boolean {
        const teethRefs = flatten(PERMANENT_TEETH_REFS.map((ref) => ref.id));
        const permTeeth = teeth.filter((tooth) => teethRefs.includes(tooth));
        return permTeeth.length === teeth.length;
    }

    /**
     * Determine if the spcified tooth is posterior
     *
     * @param {number} selectedTooth
     * @return {*}  {boolean}
     * @memberof ProcedureConflictRulesPipeline
     */
    public getIsToothPosterior(selectedTooth: number): boolean {
        const position = this.getTeethPosition([selectedTooth])[0];
        return position ? position <= 5 || position >= 28 || (position >= 12 && position <= 21) : false;
    }

    /**
     * Determine if the specified tooth is a molar
     *
     * @param {number} selectedTooth
     * @return {*}  {boolean}
     * @memberof ProcedureConflictRulesPipeline
     */
    public getIsToothMolar(selectedTooth: number): boolean {
        const position = this.getTeethPosition([selectedTooth])[0];
        return position ? position <= 3 || position >= 30 || (position >= 14 && position <= 19) : false;
    }

    /**
     * Determine if the specified tooth is a bicuspid
     *
     * @param {number} selectedTooth
     * @return {*}  {boolean}
     * @memberof ProcedureConflictRulesPipeline
     */
    public getIsToothBicuspid(selectedTooth: number): boolean {
        const position = this.getTeethPosition([selectedTooth])[0];
        const bicuspidPositions = [4, 5, 12, 13, 20, 21, 28, 29];
        return position ? bicuspidPositions.indexOf(position) > -1 : false;
    }

    /**
     * Determine if the spcified tooth is a maxillary tooth
     *
     * @param {number} selectedTooth
     * @return {*}  {boolean}
     * @memberof ProcedureConflictRulesPipeline
     */
    public getIsToothMaxillary(selectedTooth: number): boolean {
        const position = this.getTeethPosition([selectedTooth])[0];
        return position ? range(0, 17).indexOf(position) > -1 : false;
    }
    /**
     * Get list of quads for the specified teeth
     *
     * @param {number[]} selectedTeeth
     * @return {*}  {(('ur' | 'ul' | 'll' | 'lr')[])}
     * @memberof ProcedureConflictRulesPipeline
     */
    public getTeethQuads(selectedTeeth: number[]): ('ur' | 'ul' | 'll' | 'lr')[] {
        const quads = {
            ul: [1, 2, 3, 4, 5, 6, 7, 8],
            ur: [9, 10, 11, 12, 13, 14, 15, 16],
            lr: [17, 18, 19, 20, 21, 22, 23, 24],
            ll: [25, 26, 27, 28, 29, 30, 31, 32],
        };

        const listOfQuads: ('ur' | 'ul' | 'll' | 'lr')[] = [];
        const positions = this.getTeethPosition(selectedTeeth);

        positions.forEach((position) => {
            if (position) {
                const q = map(quads, (q, key) => (q.indexOf(position) > -1 ? key : undefined)).filter((q) => q !== undefined)[0];
                if (q) listOfQuads.push(q as 'ur' | 'ul' | 'll' | 'lr');
            }
        });

        return listOfQuads;
    }

    /**
     * Get tooth position based on specified tooth numbers
     *
     * @param {number[]} selectedTeeth
     * @return {*}  {((number | undefined)[])}
     * @memberof ProcedureConflictRulesPipeline
     */
    public getTeethPosition(selectedTeeth: number[]): (number | undefined)[] {
        const positions = selectedTeeth.map((toothId) => toothSpriteReferences.find((ref) => ref.id === toothId)?.position);
        return positions;
    }

    /**
     *
     * Removes a tooth number from IChartProcedure and returns the IChartProcedure
     * @param {IChartProcedure} chartProcedure
     * @param {number} toothId
     * @return {*}  {IChartProcedure}
     * @memberof ProcedureConflictRulesPipeline
     */
    public removeToothIdFromChartProcedure(chartProcedure: IChartProcedure, toothId: number): IChartProcedure {
        const toothIds = chartProcedure.toothIds?.filter((id) => id !== toothId);
        return { ...chartProcedure, toothIds };
    }

    /**
     * Get IProcedure from IChartProcedure
     *
     * @param {IChartProcedure} chartProcedure
     * @return {*}  {(IProcedure | undefined)}
     * @memberof ProcedureConflictRulesPipeline
     */
    public getProcedure(chartProcedure: IChartProcedure): IProcedure | undefined {
        return chartProcedure?.procedureId ? this.procedureData[chartProcedure.procedureId] : undefined;
    }

    /**
     * Generates and runs relevant conflict rules on the specified IChartProcedure
     *
     * @private
     * @param {IChartProcedure} chartProcedure
     * @return {*}
     * @memberof ProcedureConflictRulesPipeline
     */
    private _getValidProcedureForSelectedTeeth(chartProcedure: IChartProcedure) {
        const procedureResult = new ProcedureConflictRuleGenerator({
            procedurePipeline: this,
            chartCondition: chartProcedure,
            rules: conflictRules,
        });
        this.addErrors(procedureResult.getErrors);
        if (!procedureResult.shouldRemoveItem) return procedureResult.getItem;
    }

    /**
     * Handles setting pipeline data based on rules
     *
     * @private
     * @memberof ProcedureConflictRulesPipeline
     */
    private generateValidProcedures() {
        if (this.items && this.selectedTeeth.length) {
            const procedures: IChartProcedure[] = [];
            this.items.forEach((chartProcedure: IChartProcedure) => {
                const newProcedure = this._getValidProcedureForSelectedTeeth(chartProcedure);
                if (newProcedure) procedures.push(newProcedure as IChartProcedure);
            });
            this.setItems(procedures);
        }
    }
}
