import { DefaultButton, PrimaryButton, Stack } from '@fluentui/react';
import useForms from 'hooks/store/useForms';

export default function ProblemFooter(): JSX.Element | null {
    const { patientProblemsFormValuesAsList, currentlySelectedForm, setBaseFormDataPropAndSave, cleanupSelectedForm } =
        useForms();

    const onAddOrSave = () => {
        if (currentlySelectedForm) {
            setBaseFormDataPropAndSave('completedDate', new Date().toISOString());
        }
    };

    const _onDismiss = () => {
        cleanupSelectedForm();
    };

    return (
        <>
            <Stack horizontal tokens={{ childrenGap: 12 }} verticalAlign="center">
                <PrimaryButton text="Complete" onClick={onAddOrSave} />
                <DefaultButton text="Close" onClick={_onDismiss} />
            </Stack>
        </>
    );
}
