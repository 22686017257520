import { SerializedError } from '@reduxjs/toolkit';
import { IAdjustmentCommonTransactionView, IPatientAdjustmentHistoryView } from 'api/models/adjustment-history.model';
import { LoadingStatus } from 'interfaces/loading-statuses';

export type LedgerAdjustmentHistoryState = {
    loading: LoadingStatus;
    reversalSaving: LoadingStatus;
    filters: AdjustmentHistoryFilters;
    data?: IPatientAdjustmentHistoryView;
    selectedAdjustmentViews?: IAdjustmentCommonTransactionView[];
    adjustmentHistoryError?: SerializedError;
    confirmModalOpen: boolean;
    showReversedAdjustments: boolean;
};

export type AdjustmentHistoryFilters = {
    transactionDate: string;
    encounterDate: string;
    dateOfEntry: string;
    encounterNumber: string;
    amount: number | undefined;
    adjustmentReasonId: string | number;
};

export const adjustmentHistoryInitialState: LedgerAdjustmentHistoryState = {
    loading: LoadingStatus.Idle,
    reversalSaving: LoadingStatus.Idle,
    filters: {
        adjustmentReasonId: '',
        amount: undefined,
        transactionDate: '',
        encounterDate: '',
        dateOfEntry: '',
        encounterNumber: '',
    },
    confirmModalOpen: false,
    showReversedAdjustments: false,
};
