import { createAsyncThunk } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import IPatientAppointment from 'api/models/Scheduling/patientAppointment.model';
import IPatient from 'api/models/patient.model';
import IWorkListAppointmentView from 'api/models/worklist-appointment-view';
import { RootState } from 'state/store';
import { MissingSlipsWorkList, WorkList } from '../worklist.state';

export type WorkListAppointmentUpdateParams = {
    tenantId: string;
    recordId: string;
    patient?: IPatient;
    appointment?: IPatientAppointment;
    workList: WorkList;
};

export const updateAppointmentWorkListMappings: Record<string, string> = {
    [MissingSlipsWorkList.OutstandingCheckoutAppointments]: 'UpdateOutstandingCheckout',
    [MissingSlipsWorkList.OutstandingCheckInAppointments]: 'UpdateOutstandingCheckIn',
    [MissingSlipsWorkList.AwaitingAttestation]: 'UpdateAwaitingAttestation',
};

/**Thunk action handles updating appointment worklist view and source data.*/
export const updateWorkListAppointmentData = createAsyncThunk<
    IWorkListAppointmentView,
    WorkListAppointmentUpdateParams,
    {
        state: RootState;
    }
>('updateWorkListAppointmentData', async (params, { rejectWithValue }) => {
    try {
        //Find update method from mapping
        const updateMethod = updateAppointmentWorkListMappings[params.workList];
        //Call update endpoint with update method.
        const viewResponse = await dentalApi.updateAppointmentWorkListItem({ ...params, updateMethod });

        return viewResponse.data;
    } catch (e) {
        return rejectWithValue(e);
    }
});
