import { getCode, keyboardKey } from '@fluentui/keyboard-key';
import { Stack, TextField, useTheme } from '@fluentui/react';
import { Dictionary } from '@reduxjs/toolkit';
import { IChartPerioExamTooth, IChartPerioExamRecord, SurfacesNumber } from 'api/models/perio-exam.model';
import { usePerioExams, useSelector } from 'hooks';
import { find, forEach } from 'lodash';
import React, { FormEvent, useEffect, useState } from 'react';
import { createSelector } from 'reselect';

import {
    currentPerioExamTeeth,
    IExtendedPerioTooth,
    selectArchType,
} from 'state/slices/charting/perio-exams/perio-exams.selectors';
import { TOOTH_CONTAINER_WIDTH, SPRITE_WIDTH, UPPER_POSITIONS, LOWER_POSITIONS } from '../../perio-settings';

const teethIdSelector = createSelector(
    currentPerioExamTeeth,
    selectArchType,
    (_: any, perioField: string) => perioField,
    (perioTeeth, archType, perioField) => {
        const positions = archType === 'Maxillary' ? UPPER_POSITIONS : LOWER_POSITIONS;
        const upperIds: string[] = [];
        const lowerIds: string[] = [];
        forEach(positions, (position) => {
            const tooth = find(perioTeeth, ['position', position]);
            if (tooth) {
                upperIds.push(`${perioField}-facial-${tooth.id}`);
                lowerIds.push(`${perioField}-lingual-${tooth.id}`);
            }
        });
        return [...upperIds, ...lowerIds.reverse()];
    },
);

function DepthField({
    position,
    isUpper,
    perioField,
    tooth,
    allowNegativeValues,
    isDisabled,
    comparedTooth,
}: {
    tooth: IExtendedPerioTooth;
    position: number;
    isUpper?: boolean;
    allowNegativeValues?: boolean;
    perioField: keyof IChartPerioExamRecord;
    isDisabled?: boolean;
    comparedTooth?: IChartPerioExamTooth;
}): JSX.Element {
    const teethIds = useSelector((state) => teethIdSelector(state, perioField));
    const { setToothFieldAndUpdate, setFocusedDepthFieldData } = usePerioExams();
    const themeMode = useSelector((state) => state.ui.selectedTheme);
    const theme = useTheme();
    const facialOrLingual = isUpper ? 'facial' : 'lingual';

    const dataField = tooth[facialOrLingual] ? (tooth[facialOrLingual][perioField] as SurfacesNumber | undefined) : undefined;
    const comparedDataField =
        comparedTooth && comparedTooth[facialOrLingual]
            ? (comparedTooth[facialOrLingual][perioField] as SurfacesNumber | undefined)
            : undefined;
    const flipDistalMesial = position < 9 || position > 24;

    const distalProperty: keyof SurfacesNumber = flipDistalMesial ? 'distal' : 'mesial';
    const mesialProperty = flipDistalMesial ? 'mesial' : 'distal';

    const field1Value = dataField && dataField[distalProperty] !== undefined ? dataField[distalProperty]?.toString() : undefined;
    const field2Value = dataField && dataField.middle !== undefined ? dataField.middle.toString() : undefined;
    const field3Value = dataField && dataField[mesialProperty] !== undefined ? dataField[mesialProperty]?.toString() : undefined;

    const initialNegativeValueState = { [distalProperty]: '', middle: '', [mesialProperty]: '' };
    const [negativeSurfaceValue, setNegativeSurfaceValue] = useState<Dictionary<string>>(initialNegativeValueState);
    const [values, setValues] = useState<(string | undefined)[]>([field1Value, field2Value, field3Value]);

    const value1 = values[0] ? values[0] : negativeSurfaceValue[distalProperty] ?? '';
    const value2 = values[1] ? values[1] : negativeSurfaceValue['middle'] ?? '';
    const value3 = values[2] ? values[2] : negativeSurfaceValue[mesialProperty] ?? '';

    const comparedField1Value =
        comparedDataField && comparedDataField[distalProperty] !== undefined
            ? comparedDataField[distalProperty]?.toString()
            : undefined;
    const comparedField2Value =
        comparedDataField && comparedDataField.middle !== undefined ? comparedDataField.middle.toString() : undefined;
    const comparedField3Value =
        comparedDataField && comparedDataField[mesialProperty] !== undefined
            ? comparedDataField[mesialProperty]?.toString()
            : undefined;

    const [comparedValues, setComparedValues] = useState<(string | undefined)[]>([
        comparedField1Value,
        comparedField2Value,
        comparedField3Value,
    ]);

    function compareTwoFields(input1: string, input2: string) {
        if (parseInt(input1) > parseInt(input2)) {
            return 'better';
        }
        if (parseInt(input1) < parseInt(input2)) {
            return 'worse';
        }
        if (parseInt(input1) === parseInt(input2)) {
            return 'same';
        }
    }

    useEffect(() => {
        setValues([field1Value, field2Value, field3Value]);
        if (comparedTooth) {
            const betterWorseSameField1 =
                comparedField1Value && field1Value ? compareTwoFields(comparedField1Value, field1Value) : undefined;
            const betterWorseSameField2 =
                comparedField2Value && field2Value ? compareTwoFields(comparedField2Value, field2Value) : undefined;
            const betterWorseSameField3 =
                comparedField3Value && field3Value ? compareTwoFields(comparedField3Value, field3Value) : undefined;

            setComparedValues([betterWorseSameField1, betterWorseSameField2, betterWorseSameField3]);
        }
    }, [field1Value, field2Value, field3Value, comparedTooth, comparedField1Value, comparedField2Value, comparedField3Value]);
    if (!tooth) return <Stack style={{ width: 65 }} horizontal></Stack>;

    const currentToothId = `${perioField}-${facialOrLingual}-${tooth.id}`;
    const toothIndex = teethIds.findIndex((id) => id === currentToothId);

    const _handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const inputId = e.currentTarget.id;
        const code = getCode(e);
        if (code === keyboardKey.ArrowRight || code === keyboardKey.Tab) {
            e.preventDefault();
        }

        switch (code) {
            case keyboardKey.ArrowRight: {
                focusNextElement(inputId);
                break;
            }
            case keyboardKey.ArrowLeft: {
                focusPreviousElement(inputId);
                break;
            }
            case keyboardKey.Tab: {
                if (e.shiftKey && code === keyboardKey.Tab) {
                    focusPreviousElement(inputId);
                } else {
                    focusNextElement(inputId);
                }
                break;
            }
        }
    };

    const onChange = (e?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string | undefined) => {
        const numericValue = +(value ?? '');
        if (e && value !== undefined && (value === '-' || value === '' || !isNaN(numericValue))) {
            const inputId = e.currentTarget.id;
            const surface = e.currentTarget.name as keyof SurfacesNumber;

            const inputList = inputId.split('-');
            const inputIndex = +inputList[inputList.length - 1] - 1;

            const isWithinNegativeRange = numericValue < 0 && numericValue > -10;
            const isWithinPositiveRange = numericValue >= 0 && numericValue < 10;

            const newValues = [...values];
            newValues[inputIndex] = value;
            if (isWithinPositiveRange || isWithinNegativeRange) setValues(newValues);

            const newValue = value === '' ? undefined : numericValue;

            const updateDepthField = (value: number | undefined) => {
                setToothFieldAndUpdate({
                    toothId: tooth.id,
                    value,
                    surface,
                    arch: facialOrLingual,
                    perioField,
                });
            };

            if (allowNegativeValues) {
                //If a current value exists remove it. Ensures we can re-enter a negative number.
                if (values[inputIndex]) updateDepthField(undefined);
                //Always handle the negative first.
                if (value === '-' && value.length === 1) setNegativeSurfaceValue({ ...negativeSurfaceValue, [surface]: value });
                //Not a number then do nothing for updating the actual field.
                if (isNaN(numericValue)) return;
                //Limit numeric value to a negative range
                if (isWithinNegativeRange) {
                    setNegativeSurfaceValue(initialNegativeValueState);
                    updateDepthField(newValue);
                    focusNextElement(inputId);
                    //Limit numeric value to a positive range
                } else if (isWithinPositiveRange) {
                    if (negativeSurfaceValue[surface]) setNegativeSurfaceValue(initialNegativeValueState);
                    updateDepthField(newValue);
                    focusNextElement(inputId);
                }
                //Handle numerical values normally if we don't allow negative values
            } else if (!isNaN(numericValue)) {
                if (isWithinPositiveRange) {
                    setToothFieldAndUpdate({
                        toothId: tooth.id,
                        value: newValue,
                        surface,
                        arch: facialOrLingual,
                        perioField,
                    });
                    focusNextElement(inputId);
                }
            }
        }
    };

    function focusPreviousElement(inputId: string) {
        let nextElementId = '';
        const previousIndexId = toothIndex - 1;

        if (teethIds[previousIndexId]) {
            const currentToothIsfacial = currentToothId.includes('facial');
            const currentToothIsLingual = currentToothId.includes('lingual');
            const prevToothIsfacial = teethIds[previousIndexId].includes('facial');
            const prevToothIsLingual = teethIds[previousIndexId].includes('lingual');

            if (currentToothIsfacial && prevToothIsfacial) {
                // facial teeth patterns
                switch (inputId) {
                    case `${currentToothId}-1`: {
                        nextElementId = `${teethIds[previousIndexId]}-3`;
                        break;
                    }
                    case `${currentToothId}-2`: {
                        nextElementId = `${currentToothId}-1`;
                        break;
                    }
                    default: {
                        nextElementId = `${currentToothId}-2`;
                    }
                }
            } else if (currentToothIsLingual && prevToothIsLingual) {
                // Lingual teeth patterns
                switch (inputId) {
                    case `${currentToothId}-1`: {
                        nextElementId = `${currentToothId}-2`;
                        break;
                    }
                    case `${currentToothId}-2`: {
                        nextElementId = `${currentToothId}-3`;
                        break;
                    }
                    default: {
                        nextElementId = `${teethIds[previousIndexId]}-1`;
                    }
                }
            } else {
                // First of lingal, switch to facial
                switch (inputId) {
                    case `${currentToothId}-1`: {
                        nextElementId = `${currentToothId}-2`;
                        break;
                    }
                    case `${currentToothId}-2`: {
                        nextElementId = `${currentToothId}-3`;
                        break;
                    }
                    default: {
                        nextElementId = `${teethIds[previousIndexId]}-3`;
                    }
                }
            }
        } else {
            // Last lingual input, switch to start
            switch (inputId) {
                case `${currentToothId}-1`: {
                    nextElementId = `${teethIds[teethIds.length - 1]}-1`;
                    break;
                }
                case `${currentToothId}-2`: {
                    nextElementId = `${currentToothId}-1`;
                    break;
                }
                default: {
                    nextElementId = `${currentToothId}-2`;
                }
            }
        }

        const nextElement = document.getElementById(nextElementId);
        if (nextElement) {
            (nextElement as HTMLInputElement).select();
        }
    }

    function focusNextElement(inputId: string) {
        let nextElementId = '';
        const nextIndexId = toothIndex + 1;

        if (teethIds[nextIndexId]) {
            const currentToothIsfacial = currentToothId.includes('facial');
            const currentToothIsLingual = currentToothId.includes('lingual');
            const nextToothIsfacial = teethIds[nextIndexId].includes('facial');
            const nextToothIsLingual = teethIds[nextIndexId].includes('lingual');

            if (currentToothIsfacial && nextToothIsfacial) {
                // facial teeth patterns
                switch (inputId) {
                    case `${currentToothId}-1`: {
                        nextElementId = `${currentToothId}-2`;
                        break;
                    }
                    case `${currentToothId}-2`: {
                        nextElementId = `${currentToothId}-3`;
                        break;
                    }
                    default: {
                        nextElementId = `${teethIds[nextIndexId]}-1`;
                    }
                }
            } else if (currentToothIsLingual && nextToothIsLingual) {
                // Lingual teeth patterns
                switch (inputId) {
                    case `${currentToothId}-1`: {
                        nextElementId = `${teethIds[nextIndexId]}-3`;
                        break;
                    }
                    case `${currentToothId}-2`: {
                        nextElementId = `${currentToothId}-1`;
                        break;
                    }
                    default: {
                        nextElementId = `${currentToothId}-2`;
                    }
                }
            } else {
                // End of facial, switch to lingual
                switch (inputId) {
                    case `${currentToothId}-1`: {
                        nextElementId = `${currentToothId}-2`;
                        break;
                    }
                    case `${currentToothId}-2`: {
                        nextElementId = `${currentToothId}-3`;
                        break;
                    }
                    default: {
                        nextElementId = `${teethIds[nextIndexId]}-3`;
                    }
                }
            }
        } else {
            // Last lingual input, switch to start
            switch (inputId) {
                case `${currentToothId}-1`: {
                    nextElementId = `${teethIds[0]}-1`;

                    break;
                }
                case `${currentToothId}-2`: {
                    nextElementId = `${currentToothId}-1`;
                    break;
                }
                default: {
                    nextElementId = `${currentToothId}-2`;
                }
            }
        }

        const nextElement = document.getElementById(nextElementId);
        if (nextElement) {
            (nextElement as HTMLInputElement).focus();
            (nextElement as HTMLInputElement).select();
        }
    }

    const _textFieldStyles: any = (comparisonString: string) => {
        if (comparedTooth) {
            return {
                root: { width: SPRITE_WIDTH / 3 },
                field: {
                    paddingLeft: 0,
                    paddingRight: 0,
                    fontSize: 12,
                    textAlign: 'center',
                    height: 20,
                    lineHeight: 14,
                    color: comparedTooth
                        ? comparisonString === 'better'
                            ? theme.palette.green
                            : comparisonString === 'worse'
                            ? theme.palette.red
                            : comparisonString === 'same'
                            ? themeMode === 'dark'
                                ? 'white'
                                : 'black'
                            : themeMode === 'dark'
                            ? 'white'
                            : 'black'
                        : themeMode === 'dark'
                        ? 'white'
                        : 'black',
                },
                fieldGroup: { height: 20 },
            };
        } else {
            return {
                root: { width: SPRITE_WIDTH / 3 },
                field: {
                    paddingLeft: 0,
                    paddingRight: 0,
                    fontSize: 12,
                    textAlign: 'center',
                    height: 20,
                    lineHeight: 14,
                    color: isDisabled && themeMode === 'dark' ? 'white' : !isDisabled && themeMode === 'dark' ? 'white' : 'black',
                },
                fieldGroup: { height: 20 },
            };
        }
    };
    const focusOnClick = (e?: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (e) {
            e.currentTarget.select();
            const inputId = e.currentTarget.id;
            const splitId = inputId.split('-');
            const toothId = +splitId[2];
            const facialLingual = splitId[1];
            const surface = e.currentTarget.name as keyof SurfacesNumber;

            if (perioField === 'probing') {
                setFocusedDepthFieldData({ surface, facialLingual, toothId });
            }
        }
    };

    const onInputBlur = (ev: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>, surface: string) => {
        const value = ev.target.value;
        if (isNaN(+(value ?? ''))) {
            setNegativeSurfaceValue({ ...negativeSurfaceValue, [surface]: '' });
        }
    };

    return (
        <Stack style={{ width: TOOTH_CONTAINER_WIDTH }} horizontal horizontalAlign="space-around">
            <TextField
                // label={flipDistalMesial ? 'd' : 'm'}
                name={distalProperty}
                styles={_textFieldStyles(comparedValues[0])}
                value={value1}
                onChange={onChange}
                id={`${currentToothId}-1`}
                tabIndex={perioField === 'probing' ? 1 : 2}
                onFocus={focusOnClick}
                onBlur={(ev) => onInputBlur(ev, distalProperty)}
                onKeyDownCapture={(e) => {
                    _handleKeyPress(e);
                }}
                autoComplete="off"
                disabled={isDisabled}
            />
            <TextField
                // label="M"
                name="middle"
                styles={_textFieldStyles(comparedValues[1])}
                value={value2}
                onChange={onChange}
                id={`${currentToothId}-2`}
                tabIndex={perioField === 'probing' ? 1 : 2}
                onFocus={focusOnClick}
                onClick={focusOnClick}
                onKeyDownCapture={(e) => {
                    _handleKeyPress(e);
                }}
                onBlur={(ev) => onInputBlur(ev, 'middle')}
                autoComplete="off"
                disabled={isDisabled}
            />
            <TextField
                // label={flipDistalMesial ? 'm' : 'd'}
                name={mesialProperty}
                styles={_textFieldStyles(comparedValues[2])}
                value={value3}
                id={`${currentToothId}-3`}
                tabIndex={perioField === 'probing' ? 1 : 2}
                onFocus={focusOnClick}
                onChange={onChange}
                onKeyDownCapture={(e) => {
                    _handleKeyPress(e);
                }}
                onBlur={(ev) => onInputBlur(ev, mesialProperty)}
                autoComplete="off"
                disabled={isDisabled}
            />
        </Stack>
    );
}

export default DepthField;
