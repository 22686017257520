import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { getAllEncounterReasons, updateEncounter } from './encounter-reasons.actions';
import { EncounterReasonsState } from './encounter-reasons.slice';

const encountersReducers = {
    setEncountersSearch: (state: EncounterReasonsState, action: PayloadAction<string | undefined>) => {
        state.encountersSearch = action.payload;
    },
};

export default encountersReducers;

export const encountersExtraReducers = (builder: ActionReducerMapBuilder<EncounterReasonsState>) => {
    return (
        builder
            // [GET]  ==================================
            .addCase(getAllEncounterReasons.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(getAllEncounterReasons.fulfilled, (state, action) => {
                state.loading = LoadingStatus.Completed;
                state.data = action.payload;
            })
            .addCase(getAllEncounterReasons.rejected, (state) => {
                state.loading = LoadingStatus.Failed;
            })
            .addCase(updateEncounter.pending, (state) => {
                state.saving = LoadingStatus.Pending;
            })
            .addCase(updateEncounter.fulfilled, (state, action) => {
                state.saving = LoadingStatus.Completed;
            })
            .addCase(updateEncounter.rejected, (state) => {
                state.saving = LoadingStatus.Failed;
            })
    );
};
