import { ChartProcedureStatus, IChartProcedure } from 'api/models/chart.model';
import { createRule } from '../../ruleGenerator';
import ProcedureCodes, { cariesRiskExamCodes } from '../../procedureCodes';
import { Dictionary } from '@reduxjs/toolkit';
import ProcedureDiagnosisPipeline, { IProcedureWithDiagnoses } from '../procedureDiagnosisPipeline.pipeline';

const procedureDXlookup: Dictionary<string> = {
    D0601: 'Z91.841',
    D0602: 'Z91.842',
    D0603: 'Z91.843',
};
/**
 * If a sealant is being added, check if a caries assessment exists for the current encounter.
 * Then change the sealant dx based on the score of the exam (low, moderate, high)
 */
const D1351BusinessRule = createRule<ProcedureDiagnosisPipeline, IProcedureWithDiagnoses>({
    ruleTypes: [ProcedureCodes.D1351],
    rule: (pipeline, item) => {
        // We need to find all the complete D060X procedures that have the same encounterId as the the procedure
        // item being added. If the procedure exists grab the dx code that matches the corresponding D060X procedure.
        const completedCariesExamProcedureForEncounter: IChartProcedure | undefined = pipeline.chartProceduresList
            .filter((p) => {
                const procedure = pipeline.getProcedure(p);
                return procedure?.code ? cariesRiskExamCodes.includes(procedure.code as ProcedureCodes) : false;
            })
            .filter((p) => p.status === ChartProcedureStatus.Completed)
            .filter((p) => p.encounterId === pipeline.encounterId)[0];

        if (completedCariesExamProcedureForEncounter) {
            const procedure = pipeline.getProcedure(completedCariesExamProcedureForEncounter);
            if (procedure?.code) {
                const dx = pipeline.getDXFromCode(procedureDXlookup[procedure.code]);
                if (dx) {
                    const newProcedures: IProcedureWithDiagnoses[] = [
                        { ...item, diagnosisItems: item.diagnosisItems ? [...item.diagnosisItems, dx] : [dx] },
                    ];
                    return {
                        updatedItems: newProcedures,
                    };
                }
            }
        } else {
            const dx = pipeline.getDXFromCode('Z98.810');
            if (dx) {
                const newProcedures: IProcedureWithDiagnoses[] = [
                    { ...item, diagnosisItems: item.diagnosisItems ? [...item.diagnosisItems, dx] : [dx] },
                ];
                return {
                    updatedItems: newProcedures,
                };
            }
        }
        return { updatedItems: item };
    },
});

export default D1351BusinessRule;
