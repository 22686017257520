import { Stack } from '@fluentui/react';
import { Field, TText } from 'components';
import { useSelector } from 'hooks';
import { useDispatch } from 'react-redux';
import { selectWorkListFilters } from 'state/slices/admin-huddle/worklists/worklist.selectors';
import { toggleWorkListFilterPropItem } from 'state/slices/admin-huddle/worklists/worklist.slice';
import { selectLocationOfCareOptions } from 'state/slices/scheduling/scheduling.selectors';

export default function LocationOfCareFilter() {
    const dispatch = useDispatch();

    const { locationsOfCare } = useSelector(selectWorkListFilters<any>);
    const locList = useSelector(selectLocationOfCareOptions);

    const handleLOCMultiSelect = (locationId: string) => {
        dispatch(toggleWorkListFilterPropItem({ path: 'locationsOfCare', value: locationId }));
    };

    return (
        <Stack.Item grow>
            <Field.SearchCombo
                onRenderOption={(props) => <TText>{props?.text ?? ''}</TText>}
                multiSelect
                label="Location of Care"
                selectedKey={locationsOfCare ?? []}
                placeholder="Select"
                options={locList}
                useComboBoxAsMenuWidth
                optionName="Options"
                maxResults={50}
                onChange={(e, value) => {
                    handleLOCMultiSelect(value?.key as string);
                }}
            ></Field.SearchCombo>
        </Stack.Item>
    );
}
