import {
    IPanelProps,
    IPanelStyles,
    mergeStyles,
    MessageBar,
    Panel,
    Persona,
    PersonaSize,
    Spinner,
    Stack,
    useTheme,
    Text,
} from '@fluentui/react';
import { useSelector, useTenant, useTenantId } from 'hooks';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { useEffect } from 'react';
import { BentoBoxTenantApp, selectAccountTenantApps } from 'state/slices/account.slice';

export default function BentoBoxPanel(props: IPanelProps): JSX.Element {
    const { getTenantSubscriptions, selectTenantSubscriptionsLoading } = useTenant();
    const tenantId = useTenantId();

    const accountTenantApps = useSelector((state) => selectAccountTenantApps(state, tenantId));

    const _panelStyles: Partial<IPanelStyles> = {
        scrollableContent: { overflow: 'hidden', height: '100%' },
        content: { height: '100%', overflow: 'hidden' },
        main: { left: 0 },
    };

    useEffect(() => {
        if (props.isOpen) getTenantSubscriptions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isOpen]);

    return (
        <Panel {...props} isFooterAtBottom styles={_panelStyles} headerText="Applications">
            {accountTenantApps?.length ? (
                accountTenantApps?.map((app, i) => (
                    <BentoBoxAppLink key={`${app.displayName}${app.url}`} app={app} index={i}></BentoBoxAppLink>
                ))
            ) : selectTenantSubscriptionsLoading === LoadingStatus.Completed ? (
                <MessageBar>User only has access to the current application.</MessageBar>
            ) : (
                <Spinner label="Loading..." labelPosition="left" />
            )}
        </Panel>
    );
}

type Props = {
    app: BentoBoxTenantApp;
    index: number;
};

function BentoBoxAppLink({ app, index }: Props) {
    const { palette } = useTheme();
    const tenantId = useTenantId();
    const accountTenantApps = useSelector((state) => selectAccountTenantApps(state, tenantId));

    const subClassName = mergeStyles({
        cursor: 'pointer',
        ':hover': {
            backgroundColor: palette.neutralLighter,
        },
    });

    return (
        <a
            style={{ textDecoration: 'none' }}
            href={app?.url}
            target="_blank"
            rel="noreferrer"
            key={`${app?.displayName}${app?.url}`}
        >
            <Stack
                className={subClassName}
                style={{
                    minHeight: 50,
                    borderBottom:
                        index !== (accountTenantApps?.length ? accountTenantApps.length - 1 : 0)
                            ? `1px solid ${palette.neutralPrimaryAlt}`
                            : '',
                }}
                verticalAlign="center"
            >
                <Stack horizontal verticalAlign="center" grow>
                    <Persona imageInitials={app?.displayName[0].toUpperCase()} size={PersonaSize.size32} />
                    <Text>{app?.displayName}</Text>
                </Stack>
            </Stack>
        </a>
    );
}
