import {
    ComboBox,
    DefaultButton,
    IComboBoxOption,
    IconButton,
    PrimaryButton,
    SelectionMode,
    Stack,
    TooltipHost,
} from '@fluentui/react';
import { IPatientChartMedication } from 'api/models/patient-medication.model';
import { Field, SortableDetailsList, SubSection } from 'components';
import SearchComboField from 'components/Field/SearchComboField';
import useOverview from 'hooks/store/useOverview';
import { useState } from 'react';

export default function MedicationsEditProperties() {
    return <></>;
    // const { selectedOverviewItem, setSelectedOverviewItem } = useOverview();
    // const isNewItem = selectedOverviewItem.isNewItem;
    // const item: IPatientChartMedication =
    //     !isNewItem &&
    //     selectedOverviewItem &&
    //     selectedOverviewItem!.item.medications &&
    //     selectedOverviewItem!.item.medications.length &&
    //     selectedOverviewItem!.item.medications.find(
    //         (medication: IPatientChartMedication) => medication.medicationId === selectedOverviewItem.key,
    //     );
    // const [showFields, setShowFields] = useState<boolean>(false);
    // // const [medicationEvent, setMedicationEvent] = useState<IEvent | undefined>(undefined);

    // const updateSelectedPatientOverview = (updatedItem: any) => setSelectedOverviewItem(updatedItem);

    // const typeOptions = [
    //     {
    //         key: 'FILL',
    //         text: 'FILL',
    //     },
    //     {
    //         key: 'ENTER',
    //         text: 'ENTER',
    //     },
    // ];

    // const displayNameOptions = [
    //     {
    //         key: 'surescripts',
    //         text: 'surescripts',
    //     },
    //     {
    //         key: 'INTERFACE',
    //         text: 'INTERFACE',
    //     },
    // ];

    // const filteredMedications: IPatientChartMedication[] =
    //     !isNewItem &&
    //     selectedOverviewItem &&
    //     selectedOverviewItem.item.medications?.filter(
    //         (medication: IPatientChartMedication) => medication.medicationId !== selectedOverviewItem.key,
    //     );

    // const setEventDate = (key?: any, value?: string) => {
    //     const date = value ? value : undefined;
    //     // setMedicationEvent({ ...medicationEvent, eventDate: date });
    // };

    // // const getUpdatedMedication = (events: IEvent[]) => ({
    // //     ...item,
    // //     events,
    // // });

    // const getUpdatedItem = (medications: IPatientChartMedication[]) => ({
    //     ...selectedOverviewItem,
    //     item: {
    //         ...selectedOverviewItem.item,
    //         medications,
    //     },
    // });

    // // const handleOnChange = (option: IComboBoxOption | undefined, event: IEvent, key: keyof IEvent) => {
    // //     if (option && item?.events) {
    // //         const insertIndex = item.events.indexOf(event);
    // //         const updatedEvent: IEvent = {
    // //             ...event,
    // //             [key]: option.key as string,
    // //         };
    // //         const events = item.events.filter((value: any, i: number) => i !== insertIndex);
    // //         if (updatedEvent) {
    // //             events.splice(insertIndex, 0, updatedEvent);
    // //         }
    // //         const updatedMedication = getUpdatedMedication(events);
    // //         const updatedItem = getUpdatedItem([...filteredMedications, updatedMedication]);
    // //         updateSelectedPatientOverview(updatedItem);
    // //     }
    // // };

    // return (
    //     <Stack tokens={{ childrenGap: 5 }}>
    //         <SearchComboField
    //             options={[{ key: 'cetirizine 10 mg tablet', text: 'cetirizine 10 mg tablet', data: item }]}
    //             placeholder="Select Medication"
    //             useComboBoxAsMenuWidth={true}
    //             selectedKey={item && item.medicationName ? item.medicationName : null}
    //             onItemClick={(event, option, index) => {
    //                 alert('We need Medications from Athena');
    //                 // dispatch(
    //                 //     setSelectedOverviewItem({
    //                 //         item: patientAllergies,
    //                 //         savingStatus: LoadingStatus.Idle,
    //                 //         isNewItem: false,
    //                 //         key: item?.allergenId,
    //                 //     }),
    //                 // );
    //                 setShowFields(true);
    //             }}
    //         />
    //         {item && (
    //             <SubSection
    //                 title="Events"
    //                 headingCenterContent={
    //                     <Stack horizontal>
    //                         <TooltipHost content="Add Event">
    //                             <IconButton
    //                                 iconProps={{ iconName: 'Add' }}
    //                                 disabled={showFields}
    //                                 onClick={() => {
    //                                     // setMedicationEvent({
    //                                     //     eventDate: '',
    //                                     //     type: '',
    //                                     //     userDisplayName: '',
    //                                     // });
    //                                     setShowFields(true);
    //                                 }}
    //                             />
    //                         </TooltipHost>
    //                     </Stack>
    //                 }
    //             >
    //                 {showFields && (
    //                     <Stack tokens={{ childrenGap: 10 }}>
    //                         <Stack tokens={{ childrenGap: 10 }} horizontal verticalAlign="end" grow>
    //                             <Stack.Item grow>
    //                                 {/* <Field.Date label="Date" value={medicationEvent?.eventDate} onChange={setEventDate} /> */}
    //                             </Stack.Item>
    //                             <Stack.Item grow>
    //                                 <Field.Dropdown
    //                                     options={typeOptions}
    //                                     label="Type"
    //                                     placeholder="(Select Type)"
    //                                     style={{ minWidth: 215 }}
    //                                     // onChange={(event, option, index) => {
    //                                     //     if (option && medicationEvent)
    //                                     //         setMedicationEvent({ ...medicationEvent, type: option.key as string });
    //                                     // }}
    //                                 />
    //                             </Stack.Item>
    //                             <Stack.Item grow>
    //                                 <Field.Dropdown
    //                                     options={displayNameOptions}
    //                                     label="Display Name"
    //                                     placeholder="(Select Display Name)"
    //                                     style={{ minWidth: 215 }}
    //                                     // onChange={(event, option, index) => {
    //                                     //     if (option && medicationEvent)
    //                                     //         setMedicationEvent({ ...medicationEvent, userDisplayName: option.key as string });
    //                                     // }}
    //                                 />
    //                             </Stack.Item>
    //                         </Stack>
    //                         <Stack tokens={{ childrenGap: 5 }} horizontal horizontalAlign="end">
    //                             <PrimaryButton
    //                                 text="Save Event"
    //                                 // onClick={() => {
    //                                 //     if (item && item?.events && medicationEvent) {
    //                                 //         const updatedMedication = getUpdatedMedication([...item?.events, medicationEvent]);
    //                                 //         const updatedItem = getUpdatedItem([...filteredMedications, updatedMedication]);
    //                                 //         updateSelectedPatientOverview(updatedItem);
    //                                 //         setMedicationEvent(undefined);
    //                                 //         setShowFields(false);
    //                                 //     }
    //                                 // }}
    //                             />
    //                             <DefaultButton
    //                                 text="Discard Event"
    //                                 onClick={() => {
    //                                     // setMedicationEvent(undefined);
    //                                     setShowFields(false);
    //                                 }}
    //                             />
    //                         </Stack>
    //                     </Stack>
    //                 )}
    //             </SubSection>
    //         )}
    //         {/* {item && item.events && item.events.length && (
    //             <SortableDetailsList<IEvent>
    //                 selectionMode={SelectionMode.none}
    //                 items={item.events}
    //                 columns={[
    //                     {
    //                         key: 'eventDate',
    //                         minWidth: 100,
    //                         maxWidth: 150,
    //                         fieldName: 'eventDate',
    //                         name: 'Date',
    //                         isPadded: true,
    //                     },
    //                     {
    //                         key: 'type',
    //                         minWidth: 100,
    //                         maxWidth: 150,
    //                         fieldName: 'type',
    //                         name: 'Type',
    //                         isPadded: true,
    //                         onRender: (medicationEvent) =>
    //                             medicationEvent ? (
    //                                 <ComboBox
    //                                     placeholder="(Select Type)"
    //                                     options={typeOptions}
    //                                     onChange={(e, option) => handleOnChange(option, medicationEvent, 'type')}
    //                                     selectedKey={medicationEvent.type}
    //                                 />
    //                             ) : null,
    //                     },
    //                     {
    //                         key: 'userDisplayName',
    //                         minWidth: 100,
    //                         maxWidth: 150,
    //                         fieldName: 'userDisplayName',
    //                         name: 'Display Name',
    //                         isPadded: true,
    //                         onRender: (medicationEvent) =>
    //                             medicationEvent ? (
    //                                 <ComboBox
    //                                     placeholder="(Select Display Name)"
    //                                     options={displayNameOptions}
    //                                     onChange={(e, option) => handleOnChange(option, medicationEvent, 'userDisplayName')}
    //                                     selectedKey={medicationEvent.userDisplayName}
    //                                 />
    //                             ) : null,
    //                     },
    //                 ]}
    //             />
    //         )} */}
    //     </Stack>
    // );
}
