import { IDentalHistoryQuestions } from './types';

export const questions: { [key in keyof IDentalHistoryQuestions]: string | boolean } = {
    doYouHaveBleedingOrSoreGumsWhenYouBrushOrFloss: '',
    areYourTeethSensitiveToColdHotSweetsOrPressBiting: '',
    doYouHaveFoodImpactionOrFlossCatchBetweenYourTeeth: '',
    doYouHaveDryMouth: '',
    doYouHaveAnUnpleasantTasteOrBadBreath: '',
    doYouHaveBurningTongueOrLips: '',
    doYouHaveFrequentBlistersSoresOrUlcersOnLipsInMouth: '',
    doYouHaveSwellingOrLumpsInYourMouth: '',
    doYouHaveClikcingPoppingOrDiscomfortInTheJaw: '',
    doYouHaveDifficultyOpeningOrClosingYourJaw: '',
    doYouHaveLooseTeeth: '',
    doYouHaveAnyChippedOrBrokenTeeth: '',
    doYouHaveAnyShiftingOfYourTeeth: '',
    doYouHaveAnyChangeInYourBite: '',
    doYouHaveEarachesHeadachesOrNeckPain: '',
    haveYouEverHadOrthodonticTreatment: '',
    areYouCurrentlyExperiencingDentalPainOrDiscomfort: '',
    pleaseDescribeDentalPainOrDisomfort: '',
    doYouClenchOrGrindYourTeeth: '',
    doYouBiteYourCheeksOrLips: '',
    doYouWearAnyRemovableDentalAppliances: '',
    dateTheyWereMade: '',
    haveYouEverHadASeriousInjuryToYourHeadOrMouth: '',
    pleaseDescribeSeriousInjuryToYourHeadOrMouth: '',
    doYouParticipateInActiveRecreationalActivities: '',
    haveYouEverHadAnyProblemsAssociatedWithPreviousDentalTreatment: '',
    haveYouEverHadAnyProblemsWithDentalAnesthetic: '',
    haveYouHadAnyPeriodontalTreatments: '',
    haveYouEverBeenToldYouNeededAntibioticPreMedicationForDentalTreatment: '',
    isYourHomeWaterSupplyFluoridated: '',
    doYouDrinkBottledOrFilteredWater: '',
    ifYesHowOften: '',
    dateOfLastDentalExam: '',
    dateOfLastDentalXrays: '',
    previousDentist: '',
    whatWasDoneAtThisTime: '',
    pleaseRateYourComfortLevelWithReceivingDentalTreatment: '',
    howDoYouFeelAboutYourSmile: '',
    whatTypeOfTreatmentDoYouFeelYouNeed: '',

    // * Oral Hygiene
    brush: '',
    myBrushIs: '',
    electricBrush: '',
    dentalFloss: '',
    fluorideRinse: '',
    other: false,
    explainOther: '',
    bleaching: false,
    cosmeticDentistry: false,
    dentures: false,
    implants: false,
    endodontics: false,
    specialLearningNeedsIdentified: false,
    otherAdditionalInformation: false,
};

export const noteTransalations: { [key in keyof IDentalHistoryQuestions]: string } = {};
