import { DirectionalHint, Stack, StackItem, Text, TooltipHost, useTheme } from '@fluentui/react';
import { ChartProcedureDiagnosis } from 'api/models/chart.model';
import { IDiagnosis } from 'api/models/diagnosis.model';
import { IProcedureDiagnosis } from 'api/models/procedure.model';
import { ActionButton } from 'components';
import { useTenant } from 'hooks';
import { useEffect } from 'react';

type Props = {
    /**
     *
     * @description Selected diagnosis ids.
     * @type {ChartProcedureDiagnosis[]}
     */
    selectedDiagnoses?: ChartProcedureDiagnosis[];
    /**
     *
     * @description Callback to update id list of diagnoses.
     * @type {(items: ChartProcedureDiagnosis[]) => void}
     */
    updateDiagnoses?: (items: ChartProcedureDiagnosis[]) => void;
    /**
     *
     * @description?: List of IProcedure associated diagnoses
     * @type {IProcedureDiagnosis[]}
     */
    suggestionDiagnoses?: IProcedureDiagnosis[];

    shouldAutoApply?: boolean;
    noDiagnosisPlaceholder?: string;
};

export default function DiagnosisSelector({
    selectedDiagnoses,
    suggestionDiagnoses,
    updateDiagnoses,
    shouldAutoApply = true,
    noDiagnosisPlaceholder,
}: Props): JSX.Element {
    const { getDiagnosesList } = useTenant();
    const { palette } = useTheme();

    const isSelected = (id: string) => (selectedDiagnoses ? selectedDiagnoses.findIndex((dx) => dx.id === id) > -1 : false);
    const maxAssociated = selectedDiagnoses ? selectedDiagnoses?.length >= 4 : false;
    useEffect(() => {
        if (suggestionDiagnoses && shouldAutoApply) {
            const autoApplyDiagnosesCodeIds = (
                suggestionDiagnoses
                    .filter((dx) => dx.mode === 'auto-apply')
                    .map((dx) => {
                        const diagnosis = getDiagnosesList.find((d) => d.code === dx.code);
                        if (diagnosis)
                            return { id: diagnosis.id, description: diagnosis.description, displayName: diagnosis.code };
                    })
                    .filter((dx) => dx !== undefined) as ChartProcedureDiagnosis[]
            ).filter((dx) => !isSelected(dx.id));

            if (autoApplyDiagnosesCodeIds?.length && updateDiagnoses && !selectedDiagnoses?.length)
                updateDiagnoses(autoApplyDiagnosesCodeIds);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldAutoApply]);

    const toggleDiagnosisItem = (diagnosis: IDiagnosis) => {
        if (updateDiagnoses)
            if (isSelected(diagnosis.id)) {
                const items = selectedDiagnoses ? selectedDiagnoses.filter((dx) => dx.id !== diagnosis.id) : [];
                updateDiagnoses(items);
            } else {
                const newDx: ChartProcedureDiagnosis = {
                    id: diagnosis.id,
                    description: diagnosis.description,
                    displayName: diagnosis.code,
                };
                updateDiagnoses(selectedDiagnoses ? [...selectedDiagnoses, newDx] : [newDx]);
            }
    };

    const truncateText = (text?: string, maxChars?: number) =>
        text && maxChars ? (text.length >= maxChars ? `${text.substr(0, maxChars)}...` : text) : text;

    const buttons = suggestionDiagnoses
        ? suggestionDiagnoses.map((dx) => {
              const diagnosis = getDiagnosesList.find((d) => d.code === dx.code);
              if (!diagnosis) return null;
              return (
                  <StackItem key={`dx-suggestion-${dx.code}`}>
                      <TooltipHost
                          directionalHint={DirectionalHint.leftCenter}
                          delay={0}
                          content={`${diagnosis.code} - ${diagnosis.displayName}`}
                      >
                          <ActionButton
                              styles={{
                                  root: {
                                      background: isSelected(diagnosis.id) ? palette.neutralQuaternaryAlt : 'transparent',
                                  },
                              }}
                              onClick={() => {
                                  toggleDiagnosisItem(diagnosis);
                              }}
                              disabled={!isSelected(diagnosis.id) && maxAssociated}
                              type="DefaultButton"
                              text={truncateText(`${diagnosis.code} - ${diagnosis.displayName}`, 35)}
                          />
                      </TooltipHost>
                  </StackItem>
              );
          })
        : [];

    return (
        <Stack horizontal tokens={{ childrenGap: 5 }} wrap>
            {buttons.length ? (
                buttons
            ) : (
                <Text>{noDiagnosisPlaceholder ? noDiagnosisPlaceholder : 'No diagnosis suggestions found.'}</Text>
            )}
        </Stack>
    );
}
