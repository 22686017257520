import { IChartProcedure } from 'api/models/chart.model';
import ProcedureDiagnosisConflictRulesPipeline from './procedureDiagnosisConflictRules.pipeline';
import { isArray } from 'lodash';
import Rule from '../rule';
import RulesGenerator, { IRule } from '../ruleGenerator';
import DiagnosisCodes from '../DiagnosisCodes';

type Args = {
    diagnosisPipeline: ProcedureDiagnosisConflictRulesPipeline;
    chartProcedure: IChartProcedure;
    rules: { [key: string]: IRule<ProcedureDiagnosisConflictRulesPipeline, IChartProcedure> };
};
/**
 * Generates and runs rules based on conflictRules list for the passed chartCondition
 *
 * @export
 * @class ConditionConflictRuleGenerator
 */
export default class ProcedureDiagnosisConflictRuleGenerator extends RulesGenerator<
    ProcedureDiagnosisConflictRulesPipeline,
    IChartProcedure
> {
    constructor({ diagnosisPipeline, chartProcedure: chartCondition, rules }: Args) {
        super({ pipeline: diagnosisPipeline, item: chartCondition, rules, ruleTypes: DiagnosisCodes });
    }

    protected _runRules(pipeline: ProcedureDiagnosisConflictRulesPipeline): void {
        if (!isArray(this._item))
            this._item?.diagnosisCodes?.forEach((dx) => {
                const dxCode = pipeline.diagnosisData[dx.id]?.code;
                const rule = dxCode ? this._rules[dxCode] : undefined;
                if (rule !== undefined) {
                    const { getErrors, getItem, getShouldRemoveItem } = new Rule<
                        ProcedureDiagnosisConflictRulesPipeline,
                        IChartProcedure
                    >({ item: this._item as IChartProcedure, pipeline, rule });
                    if (getErrors) this.addErrors(getErrors);
                    this.setShouldRemoveItem = getShouldRemoveItem;
                    this._item = getItem;
                }
            });
    }
}
