import { useQuickActions } from 'hooks';
import { ProcedureActionType } from 'state/slices/charting/chart/chart.slice';
import ChartMenuToolbarButton, { ChartingMenuToolbarButton } from './ChartMenuToolbarButton';

function TypeSelectorButtons(): JSX.Element {
    const { setQuickActionType, selectedQuickActionType } = useQuickActions();

    const typeSelectorButtons: ChartingMenuToolbarButton[] = [
        {
            displayName: 'E',
            key: ProcedureActionType.Existing,
            tooltipContent: ProcedureActionType.Existing,
            onClick: () => setQuickActionType(ProcedureActionType.Existing),
        },
        {
            displayName: 'EO',
            key: ProcedureActionType.ExistingOther,
            tooltipContent: ProcedureActionType.ExistingOther,
            onClick: () => setQuickActionType(ProcedureActionType.ExistingOther),
        },
        {
            displayName: 'TP',
            key: ProcedureActionType.Treatment,
            tooltipContent: ProcedureActionType.Treatment,
            onClick: () => setQuickActionType(ProcedureActionType.Treatment),
        },
        {
            displayName: 'R',
            key: ProcedureActionType.Referred,
            tooltipContent: ProcedureActionType.Referred,
            onClick: () => setQuickActionType(ProcedureActionType.Referred),
        },
    ];
    const buttonElements = typeSelectorButtons.map((button) => {
        const isActive = button.key === selectedQuickActionType;
        return <ChartMenuToolbarButton {...button} primary={isActive} key={`menu-button-${button.key}`} />;
    });
    return <>{buttonElements}</>;
}

export default TypeSelectorButtons;
