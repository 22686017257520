import { EditEncounterWorkList } from 'state/slices/admin-huddle/worklists/encounter-worklists/encounter-worklists.state';
import EncounterWorkListComponent from './EncounterWorkList';

export default function EditEncounters() {
    return (
        <EncounterWorkListComponent
            workLists={[EditEncounterWorkList.EditEncounters, EditEncounterWorkList.EditEncountersCorrectionAmend]}
        />
    );
}
