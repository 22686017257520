import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DirectionalHint, ITooltipHost, Link, Persona, PersonaSize, Stack, Text, TooltipHost } from '@fluentui/react';
import { useSelector, useTenantId } from 'hooks';
import { calculateFullAge, classicDateOnly } from 'utils/dateOnly';
import { TrackerStatusIds } from 'api/models/Scheduling/patientAppointment.model';
import useTrackerBackgroundColor from 'hooks/useEventBackgroundColor';
import { TrackerStatus, trackerStatusText } from './TrackerStatusDropdown';
import {
    IAppointmentContentArg,
    IBlockAppointmentView,
    IPatientAppointmentView,
    selectDisableHIPAAView,
    selectSelectedAppointmentData,
} from 'state/slices/scheduling/scheduling.selectors';
import { checkIn, getAppointmentData, withCheckOut } from 'state/slices/scheduling/scheduling.actions';
import formatPhoneNumber from 'utils/formatPhoneNumber';
import { AppointmentType } from 'state/slices/scheduling/scheduling.state';
import { useId } from '@uifabric/react-hooks';
import { CalendarEventContextMenu } from './CalendarEventContextMenu';
import getFullName from 'utils/getPatientFullName';
import StaticClinicalAlertCallout from 'pages/components/ClinicalAlerts/StaticClinicalAlertCallout';
import useUserIdentities from 'hooks/store/useUserIdentities';
import { selectTenantPayersData } from 'state/slices/tenant/tenant-payers.slice';

type CalendarEvenProps = {
    eventInfo: IAppointmentContentArg;
};

function CalendarEvent(props: CalendarEvenProps): JSX.Element {
    const dispatch = useDispatch();
    const tenantId = useTenantId();
    const eventContainerId = useId();
    const selectedAppointment = useSelector(selectSelectedAppointmentData);

    const [showContextMenu, setShowContextMenu] = useState<boolean>(false);

    const { extendedProps } = props.eventInfo.event;
    const appointment = extendedProps as IPatientAppointmentView;

    const selected = selectedAppointment?.id === appointment.id;

    useEffect(() => {
        return () => {
            setShowContextMenu(false);
        };
    }, [props.eventInfo]);

    const onEditEvent = () => {
        tooltipRef.current?.dismiss();

        const { type, id } = appointment;
        dispatch(getAppointmentData(tenantId, id, type));
    };

    const patientAppointmentType = appointment.type !== AppointmentType.Block;

    const [background] = useTrackerBackgroundColor(appointment.trackerStatusId);

    const RenderEvent = patientAppointmentType ? EventInfo : BlockInfo;
    const tooltipRef = useRef<ITooltipHost>(null);

    return (
        <TooltipHost
            componentRef={tooltipRef}
            closeDelay={0}
            content={<RenderEvent appointment={appointment} eventInfo={props.eventInfo} hover={true} />}
            directionalHint={DirectionalHint.leftCenter}
            styles={{ root: { margin: 0 } }}
            style={{ padding: 0 }}
        >
            <Stack
                styles={{
                    root: {
                        height: '100%',
                        overflow: 'hidden',
                        backgroundColor: background,
                        cursor: 'pointer',
                        borderRadius: 3,
                    },
                }}
                onClick={onEditEvent}
                className={selected ? 'selected' : ''}
            >
                <Stack
                    id={eventContainerId}
                    onContextMenu={(e) => {
                        e.preventDefault();
                        if (patientAppointmentType) setShowContextMenu(true);
                        tooltipRef.current?.dismiss();
                    }}
                    style={{ height: '100%' }}
                >
                    <RenderEvent eventInfo={props.eventInfo} appointment={appointment} />
                </Stack>
            </Stack>
            <CalendarEventContextMenu
                hidden={!showContextMenu}
                onDismiss={() => setShowContextMenu(false)}
                target={`#${eventContainerId}`}
                directionalHint={DirectionalHint.rightCenter || DirectionalHint.leftCenter}
                appointment={appointment}
            />
        </TooltipHost>
    );
}

type AppointmentViewProps = {
    appointment: IPatientAppointmentView;
};

function isBlockView(event: IBlockAppointmentView | IPatientAppointmentView): event is IBlockAppointmentView {
    return event.type === AppointmentType.Block;
}

function GetUserDisplayName(userId: string): string {
    const { getMissingUserIdentities, userIdentitiesData } = useUserIdentities();
    useEffect(() => {
        if (userId && !userIdentitiesData[userId]) {
            getMissingUserIdentities([userId]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);
    const user = userIdentitiesData[userId];
    return user ? `${user.firstName} ${user.lastName}` : 'N/A';
}

function BlockInfo(props: CalendarEvenProps): JSX.Element | null {
    const { extendedProps } = props.eventInfo.event;
    if (!isBlockView(extendedProps)) return null;

    const appointment = extendedProps;
    const providerDisplayName =
        extendedProps.providers?.length &&
        extendedProps.providers.map((provider, key) => GetUserDisplayName(provider)).join(',  ');

    return (
        <>
            <Text variant="small" className="tracker-status-text">
                {props.eventInfo.event.title}
            </Text>
            {extendedProps.providers?.length ? (
                <Text variant="small" className="tracker-status-text">
                    {providerDisplayName}
                </Text>
            ) : null}

            <PatientInfoItem title={appointment.notes ? 'Notes' : ''} value={appointment.notes ? appointment.notes : ''} />
        </>
    );
}

function EventInfo({ hover, appointment }: { hover?: boolean } & AppointmentViewProps): JSX.Element {
    const disableHIPAAView = useSelector(selectDisableHIPAAView);

    const [highlight] = useTrackerBackgroundColor(appointment.trackerStatusId);
    const blColor = highlight;

    const patientLabel = disableHIPAAView
        ? `${getFullName(appointment.patient)} (${appointment.patient?.mrn ?? ''})`
        : (appointment.patient?.mrn ?? '');

    const hygienistDisplayName = appointment['hygienistDisplayName'];
    const providerDisplayName = appointment['providerDisplayName'];

    const naHygienist = hygienistDisplayName === 'N/A';
    const naProvider = providerDisplayName === 'N/A';

    const initials = !naHygienist || naProvider ? hygienistDisplayName : providerDisplayName;

    const border = !hover ? { borderLeft: `5px solid ${blColor}` } : {};

    const providerColor = appointment.providerColor;
    return (
        <Stack tokens={{ childrenGap: 5 }} style={{ ...border, height: '100%', position: 'relative' }}>
            <Stack style={{ padding: 5 }}>
                <Stack horizontal tokens={{ childrenGap: 5 }}>
                    {!hover && (
                        <Stack.Item>
                            <Persona
                                hidePersonaDetails={true}
                                text={initials}
                                initialsColor={providerColor}
                                size={PersonaSize.size24}
                                initialsTextColor="black"
                            />
                        </Stack.Item>
                    )}
                    <Stack.Item>
                        <Stack>
                            <Text variant="small" className="tracker-status-text">
                                {patientLabel}
                            </Text>
                            <TrackerStatusLink
                                appointmentId={appointment.id}
                                patientId={appointment.patientId}
                                trackerStatusId={appointment.trackerStatusId}
                            />
                            <EncounterReasonText encounterReason={appointment.encounterReason} />
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <div style={{ position: 'absolute', top: -5, right: 0 }}>
                            <StaticClinicalAlertCallout
                                patientId={appointment.patientId}
                                iconSize={20}
                                alertTypes={appointment.patient?.alerts}
                            />
                        </div>
                    </Stack.Item>
                </Stack>
                <Stack>
                    <PatientInfoItem value={appointment['appointmentStatus']} />
                </Stack>

                {disableHIPAAView && <HIIPAContent appointment={appointment} />}
            </Stack>
        </Stack>
    );
}

function HIIPAContent({ appointment }: AppointmentViewProps): JSX.Element {
    const patientAge = appointment.patient?.dateOfBirth ? (calculateFullAge(appointment.patient?.dateOfBirth) ?? '') : '';
    const payersLookup = useSelector(selectTenantPayersData);

    const currentInsurance =
        appointment.patient?.insuranceId && payersLookup ? payersLookup[appointment.patient?.insuranceId] : undefined;

    return (
        <Stack tokens={{ childrenGap: 2 }}>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
                <PatientInfoItem
                    title={'DOB'}
                    value={appointment.patient?.dateOfBirth ? classicDateOnly(appointment.patient.dateOfBirth, 'M/d/yyyy') : ''}
                />
                <Text variant="small">|</Text>
                <PatientInfoItem value={patientAge} />
            </Stack>

            <PatientInfoItem value={formatPhoneNumber(appointment.patient?.phoneNumber)} />

            <PatientInfoItem value={currentInsurance?.name ? currentInsurance.name : "Unknown Insurance"} />
            <PatientInfoItem value={appointment.procedureCodeList?.length ? appointment.procedureCodeList.join(', ') : ''} />
            <PatientInfoItem title={'Notes'} value={appointment['notes']} />
        </Stack>
    );
}

export function TrackerStatusLink({
    trackerStatusId,
    appointmentId,
    patientId,
    onLinkClick,
    disabled,
}: {
    trackerStatusId?: TrackerStatusIds;
    appointmentId: string;
    patientId: string;
    onLinkClick?: () => void;
    disabled?: boolean;
}): JSX.Element | null {
    const dispatch = useDispatch();
    const tenantId = useTenantId();
    if (!trackerStatusId) return null;

    const text = trackerStatusText[trackerStatusId as TrackerStatus] ?? null;

    const onCheckIn = () => {
        dispatch(checkIn({ tenantId, patientId, appointmentId }));
    };

    const onWithCheckout = () => {
        dispatch(withCheckOut({ tenantId, patientId, appointmentId }));
    };

    const clickHandlers: { [key: string]: (() => void) | undefined } = {
        [TrackerStatus.CheckIn]: onCheckIn,
        [TrackerStatus.Dismissed]: onWithCheckout,
        [TrackerStatus.ReadyForCheckout]: onWithCheckout,
    };
    const _onClickFunction: (() => void) | undefined = clickHandlers[trackerStatusId]; //This got removed at some point?
    if (_onClickFunction) {
        return (
            <Text variant="small">
                <Link
                    disabled={disabled}
                    onClick={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        _onClickFunction();
                        if (onLinkClick) onLinkClick();
                    }}
                >
                    {text}
                </Link>
            </Text>
        );
    } else {
        return <Text variant="small">{text}</Text>;
    }
}

function EncounterReasonText({ encounterReason }: { encounterReason?: string }): JSX.Element | null {
    if (!encounterReason) return null;
    return <Text variant="smallPlus">{encounterReason}</Text>;
}

function PatientInfoItem({
    title,
    value,
}: {
    title?: string;
    value: string | number | string[] | undefined;
}): JSX.Element | null {
    if (title && !value) return null;
    return (
        <Stack.Item>
            <Text variant="small" block>
                {title ? `${title}:` : ''} <strong>{value}</strong>
            </Text>
        </Stack.Item>
    );
}

export default CalendarEvent;
