import { PayloadAction } from '@reduxjs/toolkit';

import {
    IClinicalMeasureDetailsReportProperties,
    IClinicalMeasureSummaryReportProperties,
} from 'api/models/clinical-measure-detail.model';
import { IReportingState } from '../reporting.state';

export const clinicalMeasureDetailsSummaryReducers = {
    setClinicalMeasureDetailsProp: (
        state: IReportingState,
        action: PayloadAction<{ path: keyof IClinicalMeasureDetailsReportProperties; value: string | number | undefined }>,
    ): void => {
        const { path, value } = action.payload;
        ((state.reportProperties as IClinicalMeasureDetailsReportProperties)[path] as string | number | undefined) = value;
    },
    setClinicalMeasureSummaryProp: (
        state: IReportingState,
        action: PayloadAction<{ path: keyof IClinicalMeasureSummaryReportProperties; value: string | number | undefined }>,
    ): void => {
        const { path, value } = action.payload;
        ((state.reportProperties as IClinicalMeasureSummaryReportProperties)[path] as string | number | undefined) = value;
    },
};
