import { createAsyncThunk } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import { ChartProcedurePreAuth } from 'api/models/chart.model';
import { IPredeterminationProcedure  } from 'api/models/predeterminations.model';
import { cloneDeep } from 'lodash';
import { RootState } from 'state/store';
import { selectWorkListEditItem } from '../worklist.selectors';

export const savePredetermination = createAsyncThunk<
    IPredeterminationProcedure | undefined,
    {
        tenantId: string;
        preAuth: ChartProcedurePreAuth;
        preAuthCode?: string;
    },
    {
        state: RootState;
        rejectedValue: string;
    }
>('savePredetermination', async ({ tenantId, preAuth, preAuthCode }, { getState, rejectWithValue }) => {
    try {
        const predetermination = selectWorkListEditItem<IPredeterminationProcedure>(getState());
        if (predetermination && predetermination.patientId) {
            // Fetch original chart procedure
            const { data: chartProcedure } = await dentalApi.getChartProcedureById(
                tenantId,
                predetermination.patientId,
                predetermination.id,
            );

            // Create the update chart procedure with new prop values
            const newChartProcedure = cloneDeep(chartProcedure);
            newChartProcedure['preAuthorization'] = preAuth;
            newChartProcedure['preAuthorizationCode'] = preAuthCode;

            // Save updated chart procedure
            await dentalApi.updateChartProcedure(tenantId, predetermination.patientId, newChartProcedure);

            // Return updated predetermination model
            const newPredetermination: IPredeterminationProcedure = {
                ...predetermination,
                preAuthorization: preAuth,
                preAuthorizationCode: preAuthCode,
            };

            return newPredetermination;
        }
        return undefined;
    } catch (e) {
        return rejectWithValue(e as string);
    }
});
