import React, { useEffect, useRef, useState } from 'react';
import {
    Dropdown,
    Stack,
    TextField,
    Toggle,
    Label,
    SelectionMode,
    DetailsListLayoutMode,
    IDropdownOption,
    CommandBarButton,
    TooltipHost,
    IconButton,
    MessageBar,
    MessageBarType,
    Link,
    IComboBox,
    IComboBoxOption,
    ITextField,
} from '@fluentui/react';
import { Field, SortableDetailsList, TText } from 'components';
import EditOperatoryPeriod, { toCapitalize } from '../../../components/Period/EditOperatoryPeriod';
import { cloneDeep, uniqBy } from 'lodash';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import { validateForm, validateProperty } from 'pages/Settings/pages/Shared/formValidator';
import { EditComponentProps } from 'pages/Settings/pages/Shared/LookupEditPanel';
import { useSelector } from 'hooks';
import useTenantId from 'hooks/useTenantId';
import { getLocationsOfCare } from 'state/slices/scheduling/scheduling.slice';
import { useDispatch } from 'react-redux';
import { selectLocationOfCareOptions } from 'state/slices/scheduling/scheduling.selectors';
import { WeekDays } from 'api/models/provider.model';
import IOperatory, { IOperatoryPeriod, IOperatoryRange } from 'api/models/Scheduling/operatory.model';
import { selectOperatoryTagOptions } from 'state/slices/lookups/operatories/operatories.selectors';
import convertDashedDateString from 'utils/convertDateStringToLocal';
import { v4 as uuid } from 'uuid'

export default function EditProperties(props: EditComponentProps): JSX.Element {
    const { selectedLookup, setSelectedLookup, setHasError } = props;
    const item = selectedLookup?.item as IOperatory;
    const tenantId = useTenantId();
    const dispatch = useDispatch();
    const locationsOfCare = useSelector(selectLocationOfCareOptions);

    const [selectedPeriod, setSelectedPeriod] = useState<IOperatoryPeriod>();
    const [newOption, setNewOption] = useState<string>('');

    useEffect(() => {
        dispatch(getLocationsOfCare({ tenantId }));
    }, [dispatch, tenantId]);

    const firstInputRef = useRef<ITextField>(null);

    const displayOrder = item.displayOrder || item.displayOrder === 0 ? item.displayOrder.toString() : '';

    const operatoryTagOptions = useSelector((state) => selectOperatoryTagOptions(state, tenantId));

    const updateSelectedLookup = (updatedItem: unknown) => setSelectedLookup({ ...selectedLookup, item: updatedItem });

    const displayNameChangeHandler = (_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) =>
        updateSelectedLookup({ ...item, displayName: value });

    const displayOrderChangeHandler = (_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
        const parsedValue = value === undefined ? undefined : Number.parseInt(value);
        const displayOrder = Number.isNaN(parsedValue) ? null : parsedValue;
        updateSelectedLookup({ ...item, displayOrder: displayOrder });
    };

    const descriptionChangeHandler = (_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) =>
        updateSelectedLookup({ ...item, description: value });

    const tagsChangeHandler = (_: React.FormEvent<IComboBox>, value?: string[]) => updateSelectedLookup({ ...item, tags: value });

    const locationOfCareChangeHandler = (_: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) =>
        updateSelectedLookup({ ...item, locationOfCareId: option?.key });

    const statusChangeHandler = (_: React.MouseEvent<HTMLElement, MouseEvent>, value?: boolean) =>
        updateSelectedLookup({ ...item, isDeleted: !value });

    const onOperatoryTagChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
        if (option) {
            let newTagIds: string[] = [...(item?.tags ?? [])];
            const optionValue = option.key as string;

            if (item?.tags?.length) {
                const isTagsExist = item?.tags.includes(optionValue);
                if (isTagsExist) {
                    newTagIds = item?.tags.filter((id) => id !== optionValue);
                } else {
                    newTagIds.push(optionValue);
                }
            } else {
                newTagIds = [optionValue];
            }
            if (newTagIds.length < 4) tagsChangeHandler(event, newTagIds);
        }
    };

    const onOperatoryTagKeyChange = (event: React.KeyboardEvent<IComboBox>) => {
        if (event.code === 'Enter' || event.code === 'NumpadEnter' || event.code === 'Tab') {
            const newOptionValue = newOption.trim();
            if (!operatoryTagOptions.map((op) => op.key).includes(newOptionValue) && newOptionValue) {
                const newTags = [...(item.tags ?? []), newOption];
                if (newTags.length < 4) {
                    tagsChangeHandler(event, newTags);
                }
                event.stopPropagation();
            }
            if (newOptionValue) setNewOption('');
        }
    };

    const isActiveChecked = item.isDeleted === false ? true : false;

    const validators = [
        {
            property: 'displayName',
            isInvalid: () => !item.displayName,
            errorMessage: 'Name is required.',
        },
        {
            property: 'locationOfCareId',
            isInvalid: () => !item.locationOfCareId,
            errorMessage: 'Location of care is required.',
        },
        {
            property: 'operatory hours',
            isInvalid: () => !item.periods || !item.periods.length,
            errorMessage: 'Operatory hours are required.',
        },
    ];

    validateForm(validators, setHasError);

    const onPeriodRowClicked = (period: IOperatoryPeriod) => {
        const firstInput = firstInputRef?.current;
        if (firstInput)
            firstInput.focus();
        setSelectedPeriod(period);
    };

    const onRemovePeriod = (period: IOperatoryPeriod) => {
        if (period?.id) {
            //Can't compare objects to remove because there is no id. Don't use isEqual to compare objects.
            const periods = item.periods?.filter((p) => p.id !== period?.id);
            setSelectedLookup({ ...selectedLookup, item: { ...item, periods } });
        }
    };

    const isDeleted = item.isDeleted;

    const periodColumns: ISortableColumn<IOperatoryPeriod>[] = [
        {
            key: 'startDate',
            fieldName: 'startDate',
            name: 'Start',
            minWidth: 80,
            maxWidth: 80,

            onRender: (item) =>
                item && !isDeleted ? (
                    <Link
                        onClick={() => {
                            if (item) onPeriodRowClicked(item);
                        }}
                    >
                        {convertDashedDateString(item.startDate)}
                    </Link>
                ) : (
                    convertDashedDateString(item?.startDate ?? '')
                ),
        },
        {
            key: 'endDate',
            fieldName: 'endDate',
            name: 'End',
            minWidth: 80,
            maxWidth: 80,

            onRender: (item) =>
                item && !isDeleted ? (
                    <Link
                        onClick={() => {
                            if (item) onPeriodRowClicked(item);
                        }}
                    >
                        {convertDashedDateString(item.endDate)}
                    </Link>
                ) : (
                    convertDashedDateString(item?.endDate ?? '')
                ),
        },
        {
            key: 'timetables',
            fieldName: 'timetables',
            name: 'Timetables',
            minWidth: 100,
            onRender: (period) => {
                if (period) {
                    const timeTables: string[] = [];
                    for (const key in period.timetables) {
                        if (
                            period?.timetables[key as WeekDays] &&
                            (period.timetables[key as WeekDays] as IOperatoryRange[]).length > 0
                        ) {
                            timeTables.push(toCapitalize(key.substring(0, 3)));
                        }
                    }

                    return !isDeleted ? (
                        <Link
                            onClick={() => {
                                if (period) onPeriodRowClicked(period);
                            }}
                        >
                            {timeTables.join(', ')}
                        </Link>
                    ) : (
                        timeTables.join(', ')
                    );
                }
                return null;
            },
        },
        {
            key: 'remove',
            name: '',
            minWidth: 24,
            maxWidth: 24,
            onRender: (period) =>
                period && !isDeleted ? (
                    <TooltipHost content="Remove period.">
                        <IconButton iconProps={{ iconName: 'Cancel' }} onClick={() => onRemovePeriod(period)} />
                    </TooltipHost>
                ) : (
                    <IconButton iconProps={{ iconName: 'Cancel' }} disabled />
                ),
        },
    ];

    const onAddPeriod = () => {
        setSelectedPeriod({
            id: uuid(),
            startDate: '',
            endDate: '',
        });
    };

    const onPeriodSave = () => {
        // Set period to lookupList if any or set as an empty array
        const periods = cloneDeep(item.periods ?? []);

        if (selectedPeriod?.id) {
            const indexOfPeriod = periods.findIndex((p) => p.id === selectedPeriod.id)
            if (indexOfPeriod > -1) {
                periods[indexOfPeriod] = selectedPeriod;
            } else {
                periods.push(selectedPeriod);
            }
        }

        setSelectedLookup({ ...selectedLookup, item: { ...item, periods } });
        setSelectedPeriod(undefined);
    };

    const onSetPeriod = (period?: IOperatoryPeriod) => {
        return setSelectedPeriod(period);
    };

    return (
        <Stack tokens={{ childrenGap: 5 }}>
            <TextField
                componentRef={firstInputRef}
                label="Name"
                value={item.displayName}
                autoFocus
                required
                maxLength={100}
                onChange={displayNameChangeHandler}
                errorMessage={validateProperty(validators, 'displayName')}
            />

            <Stack horizontal tokens={{ childrenGap: 10 }}>
                <Stack.Item grow>
                    <Dropdown
                        label="Location of Care"
                        required
                        options={locationsOfCare}
                        selectedKey={item.locationOfCareId}
                        onChange={locationOfCareChangeHandler}
                        errorMessage={validateProperty(validators, 'locationOfCareId')}
                    />
                </Stack.Item>

                <Stack.Item grow>
                    <TextField
                        label="Display Order"
                        value={displayOrder}
                        onChange={displayOrderChangeHandler}
                        autoComplete="off"
                    />
                </Stack.Item>
            </Stack>
            <Field.SearchCombo
                label="Tag(s) - (Select up to 3)"
                multiSelect
                allowFreeform
                selectedKey={item?.tags ?? []}
                // Selector for options was glitchy so doing options combination here.
                options={uniqBy([...operatoryTagOptions, ...(item.tags?.map((op) => ({ text: op, key: op })) ?? [])], 'key')}
                placeholder="Select Tag(s)"
                threshold={0.3}
                onRenderOption={(props) => <TText>{props?.text}</TText>}
                onInputValueChange={setNewOption}
                dir={newOption}
                inputMode="text"
                onKeyDownCapture={onOperatoryTagKeyChange}
                onChange={onOperatoryTagChange}
                useComboBoxAsMenuWidth
            />
            <TextField
                label="Description"
                value={item.description}
                multiline
                maxLength={400}
                onChange={descriptionChangeHandler}
            />

            <Toggle label="Status" onText="Active" offText="Disabled" checked={isActiveChecked} onChange={statusChangeHandler} />

            <Stack>
                <Stack>
                    <Stack.Item>
                        <Label>Operatory Hours</Label>
                        {validateProperty(validators, 'operatory hours') && (
                            <MessageBar messageBarType={MessageBarType.error} truncated={true}>
                                {validateProperty(validators, 'operatory hours')}
                            </MessageBar>
                        )}
                    </Stack.Item>

                    <Stack.Item>
                        <CommandBarButton
                            style={{ height: 30 }}
                            iconProps={{ iconName: 'Add' }}
                            text="Add Operatory Hours"
                            onClick={onAddPeriod}
                            disabled={item.isDeleted}
                        />
                    </Stack.Item>
                </Stack>
                {item.periods && item.periods.length > 0 && (
                    <SortableDetailsList<IOperatoryPeriod>
                        compact={true}
                        items={item.periods}
                        columns={periodColumns}
                        layoutMode={DetailsListLayoutMode.justified}
                        selectionMode={SelectionMode.none}
                        sortOnMount={true}
                        sortColumns={['startDate']}
                    />
                )}
            </Stack>

            <EditOperatoryPeriod period={selectedPeriod} setPeriod={onSetPeriod} onSave={onPeriodSave} />
        </Stack>
    );
}
