
import { IChartProcedure } from 'api/models/chart.model';
import { ConflictRuleType } from 'api/models/lookup.model';
import { PipelineError } from '../pipeline';
import ProcedureCodes from '../procedureCodes';
import ProcedureConflictRulesPipeline from '../procedureConflictRules.pipeline';
import { createRule } from '../ruleGenerator';

const codeRequirements: Partial<Record<string, Set<string>>> = {
    [ProcedureCodes.D0230]: new Set([ProcedureCodes.D0220, ProcedureCodes.D0240, ProcedureCodes.D0274, ProcedureCodes.D0330])
}

const ProcedureRequiredToChartProcedure = createRule<ProcedureConflictRulesPipeline, IChartProcedure>({
    ruleTypes: [ConflictRuleType.procedureRequiredToChartProcedure],
    rule: (pipeline, item) => {
        const procedure = pipeline.getProcedure(item);

        // If the procedure has a code and the code requirements has a defined set of procedures that may be charted.
        if (procedure?.code && codeRequirements[procedure.code]) {
            const encChartProcedures = pipeline.getEncounterChartProcedures()
            const requiredProcedureCodes = codeRequirements[procedure.code];

            for (let i = 0; i <= encChartProcedures.length - 1; i++) {
                const code = pipeline.getProcedure(encChartProcedures[i])?.code ?? "";
                if (requiredProcedureCodes?.has(code)) {
                    return { updatedItems: item };
                }
            }

            return {
                shouldRemoveItem: true,
                errors: [
                    { 
                        type: PipelineError.ProcedureRequiresToChartProcedure,
                        data: { code: procedure.code, codeRequirements: [...(requiredProcedureCodes ?? [])] } 
                    }
                ],
                updatedItems: item
            }
        }

        return { updatedItems: item };
    },
});

export default ProcedureRequiredToChartProcedure;
