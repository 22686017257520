import { Dictionary, createAsyncThunk } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import IReportingGroup, { IReportingCategory, IReportingType } from 'api/models/reporting-group.model';
import axios from 'axios';
import ErrorTypes from 'state/errorTypes';
import { RootState } from 'state/store';
import { cleanupSelectedReportingCategory } from '../reporting.slice';
import { selectReportingGroupLookup, selectSelectedReportingGroup } from './reporting-groups.selectors';

//Reporting Group Actions
export const getReportingGroups = createAsyncThunk<Dictionary<IReportingCategory>, { rejectValue: string }>(
    'getReportingGroups',
    async (_, { rejectWithValue }) => {
        try {
            const response = await dentalApi.getReportingGroups();
            return response.data;
        } catch (err) {
            if (axios.isAxiosError(err) && err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue('Unknown error');
            }
        }
    },
);

export const getTenantReportingGroups = createAsyncThunk<
    Dictionary<IReportingGroup>,
    { tenantId: string },
    { rejectValue: string }
>('getTenantReportingGroups', async ({ tenantId }, { rejectWithValue }) => {
    try {
        const response = await dentalApi.getTenantReportingGroups(tenantId);
        return response.data;
    } catch (err) {
        if (axios.isAxiosError(err) && err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue('Unknown error');
        }
    }
});

export const createTenantReportingGroup = createAsyncThunk<
    IReportingGroup,
    { tenantId: string; model: IReportingGroup },
    { rejectValue: string }
>('createTenantReportingGroup', async ({ tenantId, model }, { rejectWithValue }) => {
    try {
        const response = await dentalApi.createTenantReportingGroup(tenantId, model);
        return response.data;
    } catch (err) {
        if (axios.isAxiosError(err) && err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue('Unknown error');
        }
    }
});

export const updateTenantReportingGroup = createAsyncThunk<
    IReportingGroup,
    { tenantId: string; model: IReportingGroup },
    { rejectValue: string }
>('updateTenantReportingGroup', async ({ tenantId, model }, { rejectWithValue }) => {
    try {
        const response = await dentalApi.updateTenantReportingGroup(tenantId, model);
        return response.data;
    } catch (err) {
        if (axios.isAxiosError(err) && err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue('Unknown error');
        }
    }
});

//Reporting Category Actions
export const getReportingCategories = createAsyncThunk<Dictionary<IReportingCategory>, { rejectValue: string }>(
    'getReportingCategories',
    async (_, { rejectWithValue }) => {
        try {
            const response = await dentalApi.getReportingCategories();
            return response.data;
        } catch (err) {
            if (axios.isAxiosError(err) && err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue('Unknown error');
            }
        }
    },
);

export const getTenantReportingCategories = createAsyncThunk<
    Dictionary<IReportingCategory>,
    { tenantId: string },
    { rejectValue: string }
>('getTenantReportingCategories', async ({ tenantId }, { rejectWithValue }) => {
    try {
        const categoryResponse = await dentalApi.getTenantReportingCategories(tenantId);
        return categoryResponse.data;
    } catch (err) {
        if (axios.isAxiosError(err) && err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue('Unknown error');
        }
    }
});

export const createTenantReportingCategory = createAsyncThunk<
    { category: IReportingCategory; group: IReportingGroup },
    { tenantId: string; model: IReportingCategory },
    { rejectValue: string; getState: RootState }
>('createTenantReportingCategory', async ({ tenantId, model }, { rejectWithValue, getState, dispatch }) => {
    try {
        //save category
        const categoryResponse = await dentalApi.createTenantReportingCategories(tenantId, model);

        //save group
        const selectedGroupId = (selectSelectedReportingGroup(getState() as RootState) as IReportingGroup).id;

        const groupFromLookup = selectReportingGroupLookup(getState() as RootState)[selectedGroupId] as IReportingGroup;
        const selectedGroupReportingCategoryIds = [...(groupFromLookup.reportingCategories ?? []), model.id];
        const groupResponse = await dentalApi.updateTenantReportingGroup(tenantId, {
            ...groupFromLookup,
            reportingCategories: selectedGroupReportingCategoryIds,
        });

        dispatch(cleanupSelectedReportingCategory());
        return { category: categoryResponse.data, group: groupResponse.data };
    } catch (err) {
        if (axios.isAxiosError(err) && err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue('Unknown error');
        }
    }
});

export const deleteTenantReportingCategory = createAsyncThunk<
    { category: IReportingCategory; group: IReportingGroup },
    { tenantId: string; model: IReportingCategory },
    { rejectValue: string; getState: RootState }
>('deleteTenantReportingCategory', async ({ tenantId, model }, { rejectWithValue, getState, dispatch }) => {
    try {
        //delete category

        const selectedGroupId = (selectSelectedReportingGroup(getState() as RootState) as IReportingGroup).id;
        const groupFromLookup = selectReportingGroupLookup(getState() as RootState)[selectedGroupId] as IReportingGroup;
        const selectedGroupReportingCategoryIds = groupFromLookup.reportingCategories?.filter((id) => id !== model.id) ?? [];
        const groupResponse = await dentalApi.updateTenantReportingGroup(tenantId, {
            ...groupFromLookup,
            reportingCategories: selectedGroupReportingCategoryIds,
        });

        return { category: model, group: groupResponse.data };
    } catch (err) {
        if (axios.isAxiosError(err) && err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue('Unknown error');
        }
    }
});

export const updateTenantReportingCategory = createAsyncThunk<
    IReportingCategory,
    { tenantId: string; model: IReportingCategory },
    { rejectValue: string }
>('updateTenantReportingCategory', async ({ tenantId, model }, { rejectWithValue, dispatch }) => {
    try {
        const response = await dentalApi.updateTenantReportingCategories(tenantId, model);
        dispatch(cleanupSelectedReportingCategory());
        return response.data;
    } catch (err) {
        if (axios.isAxiosError(err) && err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue('Unknown error');
        }
    }
});

//Reporting Types Actions
export const getReportingTypes = createAsyncThunk<Dictionary<IReportingType>, { rejectValue: string }>(
    'getReportingTypes',
    async (_, { rejectWithValue }) => {
        try {
            const response = await dentalApi.getReportingTypes();
            return response.data;
        } catch (err) {
            if (axios.isAxiosError(err) && err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue('Unknown error');
            }
        }
    },
);

export const getTenantReportingType = createAsyncThunk<Dictionary<IReportingType>, { tenantId: string }, { rejectValue: string }>(
    'getTenantReportingType',
    async ({ tenantId }, { rejectWithValue }) => {
        try {
            const response = await dentalApi.getTentantReportingTypes(tenantId);
            return response.data;
        } catch (err) {
            if (axios.isAxiosError(err) && err.response && err.response.status === 503) {
                return rejectWithValue(ErrorTypes.ServiceUnavailable);
            } else {
                return rejectWithValue('Unknown error');
            }
        }
    },
);

export const createTenantReportingType = createAsyncThunk<
    IReportingType,
    { tenantId: string; model: IReportingType },
    { rejectValue: string }
>('createTenantReportingType', async ({ tenantId, model }, { rejectWithValue }) => {
    try {
        const response = await dentalApi.createTentantReportingTypes(tenantId, model);
        return response.data;
    } catch (err) {
        if (axios.isAxiosError(err) && err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue('Unknown error');
        }
    }
});

export const updateTentantReportingType = createAsyncThunk<
    IReportingType,
    { tenantId: string; model: IReportingType },
    { rejectValue: string }
>('updateTentantReportingType', async ({ tenantId, model }, { rejectWithValue }) => {
    try {
        const response = await dentalApi.updateTentantReportingTypes(tenantId, model);
        return response.data;
    } catch (err) {
        if (axios.isAxiosError(err) && err.response && err.response.status === 503) {
            return rejectWithValue(ErrorTypes.ServiceUnavailable);
        } else {
            return rejectWithValue('Unknown error');
        }
    }
});
