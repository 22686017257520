import { IComboBoxOption } from '@fluentui/react';
import { createAsyncThunk, createSelector, createSlice, Dictionary } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import IAdjustmentReason from 'api/models/adjustment-reason.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { LookupState } from 'interfaces/lookup-state';
import { map, orderBy } from 'lodash';
import { RootState } from 'state/store';

type AdjustmentReasonsState = LookupState<IAdjustmentReason>;

const initialState: AdjustmentReasonsState = {
    initialLoad: LoadingStatus.Idle,
    loading: LoadingStatus.Idle,
    data: {},
};

export const getAllAdjustmentReasons = createAsyncThunk<Dictionary<IAdjustmentReason>, void, { rejectValue: unknown }>(
    'getAllAdjustmentReasons',
    async (_, { rejectWithValue }) => {
        try {
            const { data } = await dentalApi.getAllAdjustmentReasons();
            return data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

const adjustmentReasonsSlice = createSlice({
    name: 'adjustmentReasons',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllAdjustmentReasons.pending, (state) => {
                state.initialLoad = LoadingStatus.Pending;
            })
            .addCase(getAllAdjustmentReasons.fulfilled, (state, action) => {
                state.initialLoad = LoadingStatus.Completed;
                state.data = action.payload;
            })
            .addCase(getAllAdjustmentReasons.rejected, (state, action) => {
                state.initialLoad = LoadingStatus.Failed;
                state.errors = action.payload as string;
            });
    },
});

const { reducer } = adjustmentReasonsSlice;

export const selectAdjustmentReasonsData = (state: RootState): Dictionary<IAdjustmentReason> => state.tenant.adjustmentReasons.data;

export const selectAdjustmentReasonsAsList = createSelector(selectAdjustmentReasonsData, (lookups) => {
    return map(lookups).filter((reason) => !reason?.isDeleted && !reason?.isSystemUseOnly) as IAdjustmentReason[];
});

export const adjustmentReasonOptions = createSelector(
    selectAdjustmentReasonsAsList,
    (lookupsList) =>
        orderBy(
            lookupsList.map((adjustment) => ({
                key: adjustment?.id ?? '',
                text: `${
                    adjustment.displayName.length > 35 ? `${adjustment.displayName.slice(0, 35)}...` : adjustment.displayName
                }`,
                title: `${adjustment.displayName}`,
            })),
            'title',
            'asc',
        ) as IComboBoxOption[],
);

export default reducer;
