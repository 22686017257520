import type { CustomThemeProps } from '../ui.slice';

export const darkTheme: CustomThemeProps = {
    palette: {
        themePrimary: '#48a7fa',
        themeLighterAlt: '#03070a',
        themeLighter: '#0c1b28',
        themeLight: '#16324b',
        themeTertiary: '#2b6496',
        themeSecondary: '#4093dc',
        themeDarkAlt: '#5ab0fa',
        themeDark: '#74bcfb',
        themeDarker: '#98cdfc',
        neutralLighterAlt: '#1c1c1c',
        neutralLighter: '#252525',
        neutralLight: '#343434',
        neutralQuaternaryAlt: '#3d3d3d',
        neutralQuaternary: '#454545',
        neutralTertiaryAlt: '#656565',
        neutralTertiary: '#858585',
        neutralSecondary: '#ececec',
        neutralPrimaryAlt: '#f0f0f0',
        neutralPrimary: '#dddddd',
        neutralDark: '#f7f7f7',
        black: '#fbfbfb',
        white: '#111111',
        navbar: '#1d375a',
        navbarLighter: '#28538e',
    },
    eventColors: {
        trackerStatus: {
            arrived: {
                backgroundColor: '#8169A7',
                highlight: '#EDEAF3',
                buttonColor: '#387D8C',
            },
            ready: {
                backgroundColor: '#437a37',
                highlight: '#EEF5ED',
                buttonColor: '#387D8C',
            },
            inTreatment: {
                backgroundColor: '#3B7D8C',
                highlight: '#E6F3F7',
                buttonColor: '#E6F3F7',
            },
            checkout: {
                backgroundColor: '#68696A',
                highlight: '#EFF1F1',
                buttonColor: '#107C10',
            },
            attested: {
                backgroundColor: '#68696A',
                highlight: '#EFF1F1',
                buttonColor: '#8169A7',
            },
            amendRequired: {
                backgroundColor: '#68696A',
                highlight: '#EFF1F1',
                buttonColor: '#FFB900',
            },
            completed: {
                backgroundColor: '#444',
                highlight: '#8D8D8D',
                buttonColor: '#8D8D8D',
            },

            canceled: {
                highlight: '#FFDBDB',
                backgroundColor: '#7A0A04',
                buttonColor: '#FFDBDB',
            },
        },
        trackerEncounterStatus: {
            created: {
                backgroundColor: '#8169A7',
                highlight: '#EDEAF3',
                buttonColor: '#E6F3F7',
                color: 'black',
            },
            readyForAttestation: {
                backgroundColor: '#437a37',
                highlight: '#EEF5ED',
                buttonColor: '#107C10',
                color: 'white',
            },
            attested: {
                backgroundColor: '#3B7D8C',
                highlight: '#E6F3F7',
                buttonColor: '#8169A7',
                color: 'white',
            },
            amendRequired: {
                backgroundColor: '#3B7D8C',
                highlight: '#E6F3F7',
                buttonColor: '#FFB900',
                color: 'white',
            },
            completed: {
                backgroundColor: '#3B7D8C',
                highlight: '#E6F3F7',
                buttonColor: '#8D8D8D',
                color: 'white',
            },
            conversionImported: {
                backgroundColor: '#3B7D8C',
                highlight: '#E6F3F7',
                buttonColor: '#8D8D8D',
                color: 'white',
            },
            canceled: {
                color: 'black',
                highlight: '#FFDBDB',
                backgroundColor: '#7A0A04',
                buttonColor: '#FFDBDB',
            },
        },
    },
};
