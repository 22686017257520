import { CommandBar, ScrollablePane, SearchBox, Separator, Stack, Toggle } from '@fluentui/react';
import { IEncounterSummary } from 'api/models/encounter-ledger.model';
import { useSelector } from 'hooks';
import { useFuseSearch } from 'hooks/useFuseSearch';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
    selectClaimsHistoryActiveFiltersCount,
    selectFilteredClaimsHistoryEncounterSummaries,
    selectShowClaimsHistoryFilters,
} from 'state/slices/ledger/claim-history/claim-history.selectors';
import { toggleShowClaimHistoryFilters } from 'state/slices/ledger/ledger.slice';
import { classicDateOnly } from 'utils/dateOnly';
import EncounterLedger from '../LedgerView/EncounterLedger';
import ClaimHistoryFilters from './ClaimHistoryFilters';

export default function ClaimHistory() {
    const dispatch = useDispatch();

    const _selectFilteredClaimsHistorySummaries = useSelector(selectFilteredClaimsHistoryEncounterSummaries);
    const filtersCount = useSelector(selectClaimsHistoryActiveFiltersCount);

    const { url } = useRouteMatch();

    const { push } = useHistory();

    const { setSearch, results, search } = useFuseSearch<IEncounterSummary>({
        list: _selectFilteredClaimsHistorySummaries,
        fuseOptions: {
            threshold: 0.1,
            keys: ['encounterNumber', 'dateOfService'],
            getFn: (obj, [value]) => {
                if (value === 'dateOfService') return classicDateOnly(obj.dateOfService);
                return obj[value as keyof IEncounterSummary] as string;
            },
        },
    });

    const showFilters = useSelector(selectShowClaimsHistoryFilters);

    const _toggleShowClaimsHistoryFilters = () => {
        dispatch(toggleShowClaimHistoryFilters());
    };

    const _onSearchChange = (ev?: React.ChangeEvent, value?: string) => {
        setSearch(value ?? '');
    };

    const _onEncounterClick = (ev?: React.MouseEvent, item?: IEncounterSummary) => {
        if (item?.encounterId) {
            push(`${url}/${item.encounterId}`);
        }
    };

    return (
        <Stack grow tokens={{ childrenGap: 10 }}>
            <Helmet title="Ledger - Claim History" />
            <CommandBar
                styles={{ root: { paddingLeft: 5 } }}
                items={[
                    {
                        key: 'search',
                        onRender: () => {
                            return (
                                <Stack verticalAlign="center" grow>
                                    <SearchBox
                                        onChange={_onSearchChange}
                                        styles={{ root: { width: 260 } }}
                                        placeholder="Search encounter date or encounter #"
                                    />
                                </Stack>
                            );
                        },
                    },
                    {
                        key: 'divider',
                        onRender: () => <Separator styles={{ root: { paddingLeft: 20, paddingRight: 20 } }} vertical />,
                    },
                    {
                        key: 'showFilters',
                        onRender: () => {
                            return (
                                <Toggle
                                    checked={showFilters}
                                    onClick={_toggleShowClaimsHistoryFilters}
                                    label={`Show Filters${filtersCount > 0 ? ` (${filtersCount} active)` : ''}`}
                                    inlineLabel
                                    styles={{ root: { margin: 0 } }}
                                />
                            );
                        },
                    },
                ]}
            />
            <ClaimHistoryFilters />
            <div style={{ position: 'relative', flex: 1, display: 'flex' }}>
                <ScrollablePane>
                    <EncounterLedger
                        onEncounterClick={_onEncounterClick}
                        views={search ? results : _selectFilteredClaimsHistorySummaries}
                    />
                </ScrollablePane>
            </div>
        </Stack>
    );
}
