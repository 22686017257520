import { IServiceUserAccountTenant } from 'api/models/account.model';
import { useSelector } from 'hooks';
import { LoadingStatuses } from 'interfaces/loading-statuses';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { AccountState, getAccount, selectAccount, selectTenantsAsList, selectTenantsLoading } from 'state/slices/account.slice';

type UseAccountType = {
    account: AccountState,
    getAccount: () => void,
    tenantsList: IServiceUserAccountTenant[],
    loading: LoadingStatuses
}

function useAccount(): UseAccountType {
    const dispatch = useDispatch();
    const _account = useSelector(selectAccount);
    const _getAccount = useCallback(() => dispatch(getAccount()), [dispatch]);
    const _tenants = useSelector(selectTenantsAsList);
    const _loading = useSelector(selectTenantsLoading);

    return {
        account: _account,
        getAccount: _getAccount,
        tenantsList: _tenants,
        loading: _loading
    };
}

export default useAccount;
