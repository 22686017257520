import { Stack, DatePicker, DayOfWeek, Dropdown } from '@fluentui/react';
import IBlockAppointment from 'api/models/Scheduling/blockAppointment.model';
import IPatientAppointment from 'api/models/Scheduling/patientAppointment.model';
import { useSelector, useTenantId } from 'hooks';
import { getValidationError, IValidationError } from 'hooks/useValidation';
import { useDispatch } from 'react-redux';
import { selectTimeOptionWithinRangeBusinessHours } from 'state/slices/scheduling/schedule-appointment/schedule-appointment.selectors';
import { setScheduleAppointmentSelectedDate, setScheduleAppointmentTime } from 'state/slices/scheduling/scheduling.slice';

type Props = {
    appointment?: IBlockAppointment | IPatientAppointment;
    errors: IValidationError[];
};

function DateTimeFields({ appointment, errors }: Props): JSX.Element {
    const dispatch = useDispatch();
    const tenantId = useTenantId();

    const operatoryTimeOptions = useSelector((state) => selectTimeOptionWithinRangeBusinessHours(state, tenantId));

    const startTime = appointment?.startTime;
    const endTime = appointment?.endTime;

    const onDatepickerSelect = (date: Date | null | undefined) => {
        dispatch(setScheduleAppointmentSelectedDate(date));
    };

    const onTimeChange = (type: 'startTime' | 'endTime', timeValue: string) => {
        dispatch(setScheduleAppointmentTime({ type, value: timeValue }));
    };

    const endTimeOptions = startTime
        ? operatoryTimeOptions.filter((option) => option.key > startTime ?? '00:00')
        : operatoryTimeOptions;

    return (
        <Stack horizontal tokens={{ childrenGap: 10 }} grow>
            <Stack.Item grow>
                <DatePicker
                    label="Date"
                    firstDayOfWeek={DayOfWeek.Sunday}
                    placeholder="Select a date..."
                    ariaLabel="Select a date"
                    value={new Date(`${appointment?.date}T00:00:00`)}
                    onSelectDate={onDatepickerSelect}
                />
            </Stack.Item>
            <Stack.Item grow>
                <Dropdown
                    label="Start"
                    options={operatoryTimeOptions}
                    selectedKey={startTime?.substring(0, 5) ?? ''}
                    styles={{ root: { minWidth: 100 } }}
                    dropdownWidth={100}
                    onChange={(event, option) => onTimeChange('startTime', option?.key as string)}
                    required
                    errorMessage={getValidationError(errors, 'Start') ? 'Start time is required.' : undefined}
                />
            </Stack.Item>
            <Stack.Item grow>
                <Dropdown
                    label="End"
                    options={endTimeOptions}
                    selectedKey={endTime?.substring(0, 5) ?? ''}
                    styles={{ root: { minWidth: 100 } }}
                    dropdownWidth={100}
                    onChange={(event, option) => onTimeChange('endTime', option?.key as string)}
                    required
                    errorMessage={getValidationError(errors, 'End') ? 'End time is required.' : undefined}
                />
            </Stack.Item>
        </Stack>
    );
}

export default DateTimeFields;
