export type TreatmentPlanPhaseOneBaseProperties = {
    lookBackDays?: number;
    startDate?: string;
    endDate?: string;
    filterType?: PhaseOneBaseFilterType;
    locationOfCareIds?: string[];
    providerIds?: string[];
    denominatorCodes?: string[];
    ageStart?: number;
    ageEnd?: number;
};

export enum PhaseOneBaseFilterType {
    AllEncounters = 'All Encounters',
    FirstEncounters = 'First Encounters',
}

export type TreatmentPlanPhaseOneDetailProperties = TreatmentPlanPhaseOneBaseProperties & {
    pendingCodes?: string[];
};

export type TreatmentPlanPhaseOneSummaryProperties = TreatmentPlanPhaseOneBaseProperties & {
    groupCol?: string;
    groupCol2?: string;
};
