import SurfaceContainer, { IChartSurfaceData } from './SurfaceContainer';
import Tooth from './Tooth';
import { ToothAreaGroup } from './ToothAreaGroup';

export class Surfaces extends ToothAreaGroup {
    private _surfacesData: IChartSurfaceData[];

    constructor(tooth: Tooth, surfacesData: IChartSurfaceData[]) {
        super(tooth, 'surfaces');
        this._surfacesData = surfacesData;
        this._createAndAddSurfaces();

        this.y = this.getIsBottomRow ? 200 : -200;
        this.sortableChildren = true;
        // For ease of use, the surfaces should always have a constant scale
        this.setScale(1);
        if (this._tooth.data.dentitionMode === 'Primary') {
            this.setScale(this.isChildMolar ? tooth._toothScale : tooth._toothScale + 0.17);
        }

        this.setRotation(tooth.data.rotationOcclusal);
    }

    private _createAndAddSurfaces() {
        const surfaces = this._surfacesData.map((surfaceData) => {
            const surfaceContainer = new SurfaceContainer(this._tooth, surfaceData, this);
            return surfaceContainer;
        });
        if (!this._tooth.missingRetainedRoot && !this._tooth.hasDenture) this.addChild(...surfaces);
    }
}
