import React, { PropsWithChildren } from 'react';
import {
    IStyleFunctionOrObject,
    Stack,
    ScrollablePane,
    ScrollbarVisibility,
    IScrollablePaneProps,
    useTheme,
} from '@fluentui/react';
import ActionButton, { IActionButton } from 'components/ActionButton';
import { TText } from 'components';

interface Props {
    styles?: IStyleFunctionOrObject<any, any>;
    style?: React.CSSProperties;
    title?: string | JSX.Element;
    titleBar?: JSX.Element;
    sideMenu?: JSX.Element;
    contentBelowTitle?: JSX.Element;
    mainButtons?: IActionButton[];
    hideTitleBar?: boolean;
    scrollablePaneProps?: IScrollablePaneProps;
    hasNoFlex?: boolean;
}

function SubPage({
    mainButtons,
    title,
    titleBar,
    children,
    styles,
    style,
    sideMenu,
    contentBelowTitle,
    hideTitleBar,
    hasNoFlex,
}: PropsWithChildren<Props>): JSX.Element {
    const _mainButtons = mainButtons?.map((button, index) => (
        <ActionButton key={index} {...button} />
    ));
    const theme = useTheme();

    return (
        <Stack styles={styles} style={style} grow>
            {!hideTitleBar && title && (
                <Stack
                    horizontal
                    tokens={{ childrenGap: 10 }}
                    className="ms-depth-8"
                    styles={{
                        root: {
                            backgroundColor: theme.palette.neutralLighterAlt,
                            justifyContent: 'space-between',
                            borderBottom: `1px solid ${theme.palette.neutralLight}`,
                            alignContent: 'center',
                        },
                    }}
                    verticalAlign="center"
                >
                    <div style={{ padding: 5, paddingBottom: 9, paddingTop: 9 }}>
                        <TText variant="large" block>
                            {title}
                        </TText>
                    </div>
                    <Stack horizontal>{_mainButtons}</Stack>
                </Stack>
            )}
            {!hideTitleBar && titleBar && titleBar}
            <Stack grow>
                <Stack styles={{ root: { display: 'flex' } }} horizontal grow>
                    <Stack.Item>{sideMenu}</Stack.Item>
                    <Stack grow>
                        <Stack.Item>{contentBelowTitle}</Stack.Item>
                        <div
                            style={{
                                display: 'flex',
                                flex: 1,
                                height: '100%',
                                position: 'relative',
                            }}
                        >
                            <ScrollablePane
                                styles={
                                    !hasNoFlex
                                        ? { contentContainer: { display: 'flex' } }
                                        : undefined
                                }
                                scrollbarVisibility={ScrollbarVisibility.auto}
                            >
                                {children}
                            </ScrollablePane>
                        </div>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
}

export default SubPage;
