import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import IWorkListEncounterView from 'api/models/worklist-encounter-view';

const secondaryPayerSource: ISortableColumn<IWorkListEncounterView> = {
    key: 'secondaryPayerSource',
    name: 'Secondary Payer Source',
    minWidth: 150,
    maxWidth: 220,
    isResizable: true,
    onRender: (item) => {
        const text = getSecondaryPayerSource(item);
        return <span title={text}>{text}</span>;
    },
    getValueKey: (item) => getSecondaryPayerSource(item),
};

//TODO: Once Kristy implements the worklist encounter view model for all worklists, this will not be needed. Just ref secondaryPayerName field.
function getSecondaryPayerSource(item: IWorkListEncounterView | undefined): string {
    let text: string | undefined = undefined;

    if (item?.secondaryPayerName === '') {
        if (item?.slidingFeePlan !== '') {
            if (item?.primaryPayerName !== '') {
                text = item.slidingFeePlan;
            }
        }
    } else {
        text = item?.secondaryPayerName;
    }
    return text ?? '';
}

export default secondaryPayerSource;
