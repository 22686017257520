import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { RouteParams } from 'interfaces/route-params';
import { getChartTreatmentPlanById, getChartTreatmentPlans } from 'state/slices/charting/treatmentPlans/treatmentPlans.actions';
import { useDispatch } from 'react-redux';
import TreatmentPlan from './components/TreatmentPlan';
import { cleanupCurrentTreatmentPlan } from 'state/slices/charting/chart/chart.slice';

function EditTreatmentPlan(): JSX.Element {
    const { patientId, tenantId, treatmentPlanId } = useParams<RouteParams>();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getChartTreatmentPlanById({ patientId, tenantId, treatmentPlanId }));
        dispatch(getChartTreatmentPlans({ patientId, tenantId }));
        return () => {
            dispatch(cleanupCurrentTreatmentPlan());
        };
    }, [treatmentPlanId, dispatch, patientId, tenantId]);

    return <TreatmentPlan />;
}

export default EditTreatmentPlan;
