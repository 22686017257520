import { DirectionalHint, IStyleFunctionOrObject, Stack, styled, Text, TooltipHost, useTheme } from '@fluentui/react';
import IUserTask from 'api/models/user-task.model';
import React from 'react';

export interface IUserTasksBadgeProps {
    styles?: IStyleFunctionOrObject<any, any>;
    style?: React.CSSProperties;
    label?: string;
    size?: 'normal' | 'small';
    userTasks?: IUserTask[];
    directionalHint?: DirectionalHint;
    isColumn?: boolean;
    showTaskCount?: boolean;
}

function UserTasksBadge({
    label,
    userTasks,
    size = 'normal',
    directionalHint = DirectionalHint.topCenter,
    showTaskCount = true,
    style,
}: IUserTasksBadgeProps) {
    const theme = useTheme();
    const backgroundColor = theme?.palette.orangeLight;

    let badgeSize = 0;
    let badgeFontSize = 16;
    if (size === 'normal') {
        badgeSize = 21;
        badgeFontSize = 16;
    }
    if (size === 'small') {
        badgeSize = 16;
        badgeFontSize = 11;
    }

    function userTasksContent() {
        return (
            <Stack tokens={{ childrenGap: 2 }}>
                {userTasks?.map((task, index) => (
                    <Text variant="small" key={index}>
                        {task.note ?? 'Unknown task'}
                    </Text>
                ))}
            </Stack>
        );
    }

    if (!userTasks?.length && !userTasks?.length) return null;
    return (
        <Stack
            style={{
                backgroundColor: 'transparent',
                borderRadius: '50%',
                border: `1px solid ${backgroundColor}`,
                width: badgeSize,
                height: badgeSize,
                cursor: 'pointer',
                ...style,
            }}
            horizontalAlign="center"
            verticalAlign="center"
        >
            <TooltipHost
                directionalHint={directionalHint}
                styles={{
                    root: { display: 'flex', flex: 1, height: badgeSize },
                }}
                delay={0}
                content={
                    userTasks?.length > 4 ? `${userTasks?.length ?? 0} ${label ? `${label} ` : ''}task(s).` : userTasksContent()
                }
            >
                <span
                    style={{
                        color: backgroundColor,
                        fontSize: badgeFontSize,
                    }}
                >
                    {userTasks?.length && userTasks.length < 10 && showTaskCount ? userTasks?.length : '!'}
                </span>
            </TooltipHost>
        </Stack>
    );
}

export default styled<IUserTasksBadgeProps, any, any>(UserTasksBadge, {});
