import {
    DefaultButton,
    Dropdown,
    IComboBox,
    IDropdownOption,
    Link,
    mergeStyles,
    MessageBar,
    MessageBarType,
    Panel,
    PanelType,
    SelectionMode,
    Stack,
    Text,
    TextField,
    TooltipHost,
    useTheme,
} from '@fluentui/react';
import { Field, SortableDetailsList } from 'components';
import { useChartProcedures, useSelector, useTenant } from 'hooks';
import { chartingActions, ProcedureActionType } from 'state/slices/charting/chart/chart.slice';
import { ProviderDropdown } from 'pages/components';
import { useDispatch } from 'react-redux';
import {
    ConflictError,
    selectAllSectionsOpen,
    selectChartProcedures,
    selectOpenProcedureSections,
    selectProcedurePanel,
    selectProcedurePanelConflicts,
    selectProcedurePanelSelectedAreas,
    selectProcedurePanelTeethValues,
    selectSelectedChartProcedure,
} from 'state/slices/charting/procedure-panel/procedure-panel.selectors';
import ToothSelector from './ToothSelector';
import { ApplicableArea } from 'api/models/lookup.model';
import { ChartProcedurePreAuth, ChartProcedureStatus, IChartProcedure } from 'api/models/chart.model';
import dateOnly from 'utils/dateOnly';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { useParams } from 'react-router';
import { RouteParams } from 'interfaces/route-params';
import {
    handleProcedurePanelSave,
    handleSetSelectedAreas,
    handleSetSelectedTeeth,
} from 'state/slices/charting/procedure-panel/procedure-panel.actions';
import DebouncedTextField from './DebouncedTextField';
import { ProcedureRenderRule } from 'api/models/procedure.model';
import useEncounterId from 'hooks/useEncounterId';
import React, { createRef, FormEvent, useEffect, useRef } from 'react';
import { Dictionary } from '@reduxjs/toolkit';
import { map } from 'lodash';
import PanelProcedure from './ProcedureSection';
import { selectProceduresData } from 'state/slices/tenant/procedures.slice';
import { IProcedure } from 'models/procedure';
import PanelSectionHeader from 'pages/components/PanelSectionHeader';
import {
    createsManyProceduresPerArea,
    createsManyProcedures,
    createsProceduresWithCombinedToothIds,
} from 'state/slices/charting/chartingProcedures.pipeline';
import getShorthandToothAreas from 'utils/getShorthandToothArea';
import { getTeethDisplayName } from 'utils';
import GeneralAreaSelector from './GeneralAreaSelector';
import { EncounterStatus } from 'api/models/encounter.model';
import { selectPatientEncounter } from 'state/slices/encounter/encounter.selectors';

const typeOptions: IDropdownOption[] = Object.keys(ProcedureActionType)
    .filter((k) => k !== 'Completed')
    .map((k) => ({
        key: (ProcedureActionType as Dictionary<string>)[k] as string,
        text: (ProcedureActionType as Dictionary<string>)[k] as string,
    }));

const statusOptions = (
    procedureType?: ProcedureActionType,
    encounterId?: string,
    currentProcedureStatus?: ChartProcedureStatus,
    encounterStatus?: EncounterStatus,
): IDropdownOption[] =>
    Object.keys(ChartProcedureStatus)
        .map((k) => ({
            key: (ChartProcedureStatus as Dictionary<string>)[k] as string,
            text: (ChartProcedureStatus as Dictionary<string>)[k] as string,
            disabled:
                (ChartProcedureStatus as Dictionary<string>)[k] === ChartProcedureStatus.Completed &&
                !encounterId &&
                procedureType !== ProcedureActionType.Referred,
        }))
        .filter(
            (option) => {
                if (
                    encounterStatus === EncounterStatus.CorrectionAmend ||
                    encounterStatus === EncounterStatus.CorrectionsNeeded
                ) {
                    return option.key === 'Void' || option.key === currentProcedureStatus;
                } else {
                    return option.key !== 'Removed' && option.key !== 'Void';
                }
            },
        );

const preAuthOptions: IDropdownOption[] = map(ChartProcedurePreAuth, (preAuth, key) => ({
    key: key,
    text: preAuth,
}));

function ProcedurePanel(): JSX.Element {
    const { tenantId, patientId } = useParams<RouteParams>();
    const dispatch = useDispatch();
    const {
        isOpen,
        procedures,
        selectedActionType,
        providerId,
        date,
        selectedTeeth,
        isEditing,
        selectedStatus,
        selectedPreAuth,
        selectedAuthorization,
        notes,
    } = useSelector(selectProcedurePanel);
    const { saving } = useChartProcedures();
    const { getDiagnosesData, getProceduresData } = useTenant();
    const encounterId = useEncounterId();

    const chartProcedures = useSelector(selectChartProcedures);
    const selectedChartProcedure = useSelector(selectSelectedChartProcedure);
    const { generalErrors, procedureIdsWithErrors } = useSelector(selectProcedurePanelConflicts);
    const selectedAreas = useSelector(selectProcedurePanelSelectedAreas);
    const _allSectionsOpen = useSelector(selectAllSectionsOpen);
    const teethValues = useSelector(selectProcedurePanelTeethValues);
    const patientEncounterStatus = useSelector(selectPatientEncounter)?.status;

    const hasExtraction = procedures.findIndex((p) => p.renderRule === ProcedureRenderRule.Extraction) > -1;

    const _closePanel = () => dispatch(chartingActions.setProcedurePanelOpen(false));
    const _onDismissed = () => {
        dispatch(chartingActions.cleanupProcedurePanelState());
    };
    const cannotSelectStatusCorrectionAmend =
        patientEncounterStatus === EncounterStatus.CorrectionAmend && selectedChartProcedure?.encounterId !== encounterId;

    const statusIsExisting =
        selectedActionType === ProcedureActionType.Existing || selectedActionType === ProcedureActionType.ExistingOther;

    const setActionNotes = (value?: string) => {
        dispatch(chartingActions.setProcPanelNotes(value));
    };

    const setActionType = (event?: FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        if (option?.key) dispatch(chartingActions.setProcPanelActionType(option.key as ProcedureActionType));
    };

    const setActionStatus = (event?: FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        if (option?.key) dispatch(chartingActions.setProcPanelStatus(option.key as ChartProcedureStatus));
    };

    const setActionPreauth = (event?: FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        if (option?.key) dispatch(chartingActions.setProcPanelPreAuth(option.key as ChartProcedurePreAuth));
    };

    const setActionPreauthCode = (event?: FormEvent, value?: string) => {
        dispatch(chartingActions.setProcPanelPreAuthCode(value));
    };

    const setProvider = (event?: FormEvent<IComboBox>, option?: IDropdownOption) => {
        const value: string = option ? (option.key as string) : '';
        dispatch(chartingActions.setProcPanelProviderId(value));
    };

    const setOnsetDate = (event?: FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
        const date = value ? value : undefined;
        dispatch(chartingActions.setProcPanelOnSetDate(date));
    };

    const setTeeth = (teeth: number[]) => {
        dispatch(handleSetSelectedTeeth(teeth));
    };

    const saveProcedures = () => {
        if (chartProcedures) {
            dispatch(handleProcedurePanelSave(tenantId, patientId, chartProcedures, encounterId));
        }
    };

    const needsSurfaceSelector = procedures.some((proc) => proc.applicableArea && proc.applicableArea === ApplicableArea.Surface);
    const needsGeneralAreaSelector = procedures.some((proc) => proc.applicableArea && createsManyProceduresPerArea(proc));

    const needsToothSelector = procedures.some(
        (proc) => proc.applicableArea && (createsManyProcedures(proc) || createsProceduresWithCombinedToothIds(proc)),
    );

    const showSelectedTeeth = needsSurfaceSelector || needsToothSelector;
    const proceduresSaving = saving === LoadingStatus.Pending;
    const saveDisabled = generalErrors.length > 0 || procedureIdsWithErrors.length > 0 || proceduresSaving;
    const saveButtonText = proceduresSaving ? 'Saving...' : 'Save';
    const headerText = isEditing ? 'Edit procedure' : 'Add procedures';

    useEffect(() => {
        if (statusIsExisting) dispatch(chartingActions.setProcPanelProviderId(''));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusIsExisting]);

    const canChangeMultipleTeethValues = !isEditing;

    const refs = useRef<Dictionary<React.RefObject<HTMLDivElement>>>({});

    const panelProcedures = procedures.map((proc, index) => {
        refs.current[proc.id] = createRef();
        return <PanelProcedure ref={refs.current[proc.id]} key={index} procedureId={proc.id} />;
    });

    const getToothAreasForChartProcedure = (item?: IChartProcedure) => {
        return <Text>{getShorthandToothAreas(item?.areas)}</Text>;
    };

    return (
        <Panel
            headerText={headerText}
            isOpen={isOpen}
            customWidth={'700px'}
            type={PanelType.custom}
            onDismiss={_closePanel}
            onDismissed={_onDismissed}
            styles={{
                content: { overflowY: 'auto', overflowX: 'hidden', flex: 1 },
                root: { overflow: 'hidden' },
                scrollableContent: { overflow: 'hidden', display: 'flex', flexDirection: 'column' },
            }}
            onRenderFooterContent={() => (
                <Stack tokens={{ childrenGap: 5 }}>
                    <ConflictMessages errors={generalErrors} procedureIdsWithErrors={procedureIdsWithErrors} refs={refs} />
                    <Stack horizontal tokens={{ childrenGap: 12 }}>
                        <DefaultButton
                            primary={!saveDisabled}
                            text={saveButtonText}
                            disabled={saveDisabled}
                            onClick={saveProcedures}
                        />
                        <DefaultButton text="Cancel" disabled={proceduresSaving} onClick={_closePanel} />
                    </Stack>
                </Stack>
            )}
            isFooterAtBottom
        >
            {isOpen && (
                <Stack tokens={{ childrenGap: 20 }}>
                    <Stack>
                        <PanelSectionHeader text="Procedure Details" />
                        <Stack horizontal tokens={{ childrenGap: 12 }}>
                            <Stack.Item grow={1}>
                                <Dropdown
                                    label="Type"
                                    options={typeOptions}
                                    selectedKey={selectedActionType}
                                    onChange={setActionType}
                                />
                            </Stack.Item>
                            {isEditing && (
                                <Stack.Item grow={1}>
                                    <Dropdown
                                        label="Status"
                                        options={statusOptions(
                                            selectedActionType,
                                            encounterId,
                                            selectedStatus,
                                            patientEncounterStatus,
                                        )}
                                        selectedKey={selectedStatus ?? ''}
                                        disabled={statusIsExisting || cannotSelectStatusCorrectionAmend}
                                        onChange={setActionStatus}
                                        placeholder="(Select Status)"
                                    />
                                </Stack.Item>
                            )}
                            <Stack.Item grow={1}>
                                <Dropdown
                                    label="Pre-Auth"
                                    placeholder="(Select Pre-Auth)"
                                    options={preAuthOptions}
                                    selectedKey={selectedPreAuth ?? ''}
                                    onChange={setActionPreauth}
                                    disabled={!isEditing || statusIsExisting}
                                />
                            </Stack.Item>
                        </Stack>

                        <Stack horizontal tokens={{ childrenGap: 12 }}>
                            <Stack.Item grow={1}>
                                <ProviderDropdown required={!statusIsExisting} selectedKey={providerId} onChange={setProvider} />
                            </Stack.Item>
                            <Stack.Item grow={1}>
                                <Field.Date
                                    label="Onset Date"
                                    disabled={!statusIsExisting}
                                    value={date}
                                    onChange={setOnsetDate}
                                />
                            </Stack.Item>
                            <Stack.Item grow={1}>
                                <TextField
                                    label="Authorization #"
                                    value={selectedAuthorization}
                                    disabled={selectedPreAuth !== ChartProcedurePreAuth.Approved}
                                    onChange={setActionPreauthCode}
                                />
                            </Stack.Item>
                        </Stack>

                        {showSelectedTeeth && (
                            <Stack style={{ marginTop: 10 }}>
                                <TextField prefix="Selected Teeth" value={teethValues} readOnly />
                            </Stack>
                        )}
                    </Stack>

                    {needsToothSelector && (
                        <Stack tokens={{ childrenGap: 5 }}>
                            <PanelSectionHeader text={canChangeMultipleTeethValues ? 'Select teeth' : 'Select tooth'} />
                            <ToothSelector
                                selectedTeeth={selectedTeeth}
                                canSelectSupernumerary={hasExtraction}
                                onChange={setTeeth}
                                multiselect={canChangeMultipleTeethValues}
                            />
                        </Stack>
                    )}
                    {needsGeneralAreaSelector && (
                        <Stack tokens={{ childrenGap: 5 }}>
                            <PanelSectionHeader text={canChangeMultipleTeethValues ? 'Select areas' : 'Select area'} />
                            <GeneralAreaSelector
                                onChange={(areas) => {
                                    dispatch(handleSetSelectedAreas(areas));
                                }}
                                selectedAreas={selectedAreas}
                                multiselect={canChangeMultipleTeethValues}
                            />
                        </Stack>
                    )}
                    <Stack tokens={{ childrenGap: 5 }}>
                        <PanelSectionHeader
                            text={!isEditing ? 'Procedures to Add' : 'Procedures to Update'}
                            rightContent={
                                <Link
                                    onClick={() => {
                                        dispatch(chartingActions.toggleExpandAllProcedureSections());
                                    }}
                                >
                                    {_allSectionsOpen ? 'Collapse All' : 'Expand All'}
                                </Link>
                            }
                        />
                        {panelProcedures}
                    </Stack>
                    <DebouncedTextField defaultValue={notes} label="Notes" onValueChange={setActionNotes} multiline />
                    <Stack>
                        <PanelSectionHeader text="Results" isPrimary />
                        <SortableDetailsList<IChartProcedure>
                            compact
                            selectionMode={SelectionMode.none}
                            items={chartProcedures}
                            columns={[
                                {
                                    key: 'date',
                                    minWidth: 80,
                                    maxWidth: 80,
                                    name: 'Date',
                                    onRender: (item) => {
                                        return <Text>{item?.createdOn ? dateOnly(item.createdOn) : ''}</Text>;
                                    },
                                    fieldName: 'createdOn',
                                },
                                {
                                    key: 'type',
                                    minWidth: 80,
                                    maxWidth: 80,
                                    name: 'Type',
                                    fieldName: 'type',
                                },
                                {
                                    key: 'procedure-code',
                                    minWidth: 50,
                                    maxWidth: 50,
                                    name: 'Code',
                                    onRender: (item) => {
                                        const code = item?.procedureId ? getProceduresData[item.procedureId]?.code : '';
                                        const description = item?.procedureId
                                            ? getProceduresData[item.procedureId]?.description
                                            : '';
                                        return <Text title={`${code} - ${description}`}>{code}</Text>;
                                    },
                                },
                                {
                                    key: 'tooth',
                                    minWidth: 50,
                                    maxWidth: 50,
                                    name: 'Tooth',
                                    fieldName: 'toothIds',
                                    onRender: (item) => {
                                        const teethDisplayNames = getTeethDisplayName(item?.toothIds);
                                        return <Text title={teethDisplayNames}>{teethDisplayNames}</Text>;
                                    },
                                },
                                {
                                    key: 'areas',
                                    minWidth: 50,
                                    maxWidth: 50,
                                    name: 'Areas',
                                    onRender: (item) => (
                                        <TooltipHost delay={0} content={item?.areas?.join(', ')}>
                                            <Text>{getToothAreasForChartProcedure(item)}</Text>
                                        </TooltipHost>
                                    ),
                                    fieldName: 'areas',
                                },
                                {
                                    key: 'stage',
                                    minWidth: 50,
                                    maxWidth: 50,
                                    name: 'Stage',
                                    fieldName: 'procedureStage',
                                    onRender: (item) => (
                                        <Text title={item?.stage ?? ''} variant="smallPlus">
                                            {item?.stage ?? ''}
                                        </Text>
                                    ),
                                    isResizable: true,
                                },
                                {
                                    key: 'diagnosisCodes',
                                    minWidth: 50,
                                    maxWidth: 50,
                                    name: 'Dx',
                                    fieldName: 'diagnosisCodes',
                                    onRender: (item) => {
                                        return (
                                            <Text>
                                                {item?.diagnosisCodes
                                                    ?.map((dx) => {
                                                        return getDiagnosesData ? getDiagnosesData[dx.id]?.code : '';
                                                    })
                                                    ?.join(', ')}
                                            </Text>
                                        );
                                    },
                                },
                            ]}
                        />
                    </Stack>
                </Stack>
            )}
        </Panel>
    );
}

// This is dirty code. Needs refactoring - James
export function ConflictMessages({
    errors,
    procedureIdsWithErrors,
    refs,
}: {
    errors: ConflictError[];
    procedureIdsWithErrors?: string[];
    refs?: React.MutableRefObject<Dictionary<React.RefObject<HTMLDivElement>>>;
}): JSX.Element {
    const dispatch = useDispatch();
    const proceduresData = useSelector(selectProceduresData);
    const { procedureErrors } = useSelector(selectProcedurePanelConflicts);
    const _openProcedureSections = useSelector(selectOpenProcedureSections);
    const { palette } = useTheme();
    const getProcedureSectionErrorsList = (procedure: IProcedure) => (procedure ? procedureErrors[procedure.id] ?? [] : []);
    const getConflictMessages = (errs: ConflictError[]) =>
        errs.length ? errs.map((conflict, index) => <li key={`conflic-${index}`}>{conflict.message}</li>) : null;
    const proceduresWithErrors = procedureIdsWithErrors
        ? (procedureIdsWithErrors.map((procedureId) => proceduresData[procedureId]) as IProcedure[])
        : [];

    const onProcedureCodeClick = (procedureId: string) => {
        refs?.current[procedureId]?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'start',
        });

        if (!_openProcedureSections.includes(procedureId))
            dispatch(chartingActions.setOpenProcedureSections([..._openProcedureSections, procedureId]));

        const transitionClass = mergeStyles({
            transition: 'all 1s',
        });

        const highlightClass = mergeStyles({
            boxShadow: `0px 0px 8px 2px ${palette.themePrimary}`,
        });

        if (refs?.current[procedureId]) {
            ((refs.current[procedureId] as React.RefObject<HTMLDivElement>).current as HTMLDivElement).className =
                `${transitionClass} ${highlightClass}`;
            setTimeout(() => {
                ((refs.current[procedureId] as React.RefObject<HTMLDivElement>).current as HTMLDivElement).className =
                    `${transitionClass}`;
            }, 1000);
        }
    };

    const classNames =
        getConflictMessages(errors)?.length || proceduresWithErrors.length ? 'ms-motion-fadeIn' : 'ms-motion-fadeOut';
    return (
        <Stack>
            <MessageBar className={classNames} messageBarType={MessageBarType.blocked}>
                <ul style={{ margin: 0 }}>
                    {proceduresWithErrors.length ? (
                        <li>
                            {`${proceduresWithErrors.length === 1 ? 'Procedure ' : 'Procedures '}`}
                            {proceduresWithErrors.map((procedure, index) => (
                                <span key={procedure.id}>
                                    {index + 1 === proceduresWithErrors.length && proceduresWithErrors.length !== 1 ? 'and ' : ''}
                                    <TooltipHost
                                        content={
                                            <div>
                                                {getProcedureSectionErrorsList(procedure).map((error, index) => (
                                                    <span key={index}>- {error.message}</span>
                                                ))}
                                            </div>
                                        }
                                    >
                                        <Link onClick={() => onProcedureCodeClick(procedure.id)}>{procedure.code ?? ''}</Link>
                                    </TooltipHost>
                                    {index + 1 !== proceduresWithErrors.length ? ', ' : ''}
                                </span>
                            ))}
                            {`${proceduresWithErrors.length === 1
                                    ? ' has a conflict error/missing requirement.'
                                    : ' have conflict errors/missing requirements.'
                                }`}
                        </li>
                    ) : null}
                    {getConflictMessages(errors)}
                </ul>
            </MessageBar>
        </Stack>
    );
}

export default ProcedurePanel;
