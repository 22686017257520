import { ActivityItem, IActivityItemProps, Stack, Text, useTheme, Link } from '@fluentui/react';
import { formatDistanceToNow } from 'date-fns';
import { useSelector, useTenantId } from 'hooks';
import useUserIdentities from 'hooks/store/useUserIdentities';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { selectAccountData } from 'state/slices/account.slice';
import { getPatientCommunication } from 'state/slices/communication/communication.actions';
import { selectAllCommunications } from 'state/slices/communication/communication.selectors';
import { selectEditPatient } from 'state/slices/edit-patient/edit-patient.selectors';

function CommunicationNotes(): JSX.Element {
    const dispatch = useDispatch();
    const { getMissingUserIdentities, loadingUserIdentities, userIdentitiesData } = useUserIdentities();
    const communications = useSelector(selectAllCommunications);
    const patient = useSelector(selectEditPatient);
    const accountData = useSelector(selectAccountData);
    const tenantId = useTenantId();
    const { palette } = useTheme();

    useEffect(() => {
        if (communications?.length && loadingUserIdentities !== LoadingStatus.Pending) {
            const createdByUsers = communications.map((n) => n.createdBy).filter((id) => id !== undefined) as string[];
            getMissingUserIdentities(createdByUsers);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [communications]);

    function editCommunication(communicationId: string) {
        if (patient) dispatch(getPatientCommunication({ tenantId, patientId: patient.id, communicationId }));
    }

    const communicationActivity = communications?.map((communication, index) => {
        const createdTimeAgo = communication.createdOn ? formatDistanceToNow(new Date(communication.createdOn)) : '';
        const modifiedTimeAgo = communication.modifiedOn ? formatDistanceToNow(new Date(communication.modifiedOn)) : '';
        const createdByDisplayName = communication.createdBy ? `${userIdentitiesData[communication.createdBy]?.displayName}` : '';

        const disableEdit = accountData?.identity?.id !== communication?.createdBy;

        const props: IActivityItemProps = {
            activityDescription: [
                <Link disabled={disableEdit} key={1} onClick={() => editCommunication(communication.id)}>
                    <strong>{communication.subject}</strong>
                </Link>,
            ],
            comments: [
                <Text key={1}>
                    <span dangerouslySetInnerHTML={{ __html: communication.message ?? '' }}></span>
                </Text>,
            ],
            activityPersonas: [
                {
                    imageInitials: createdByDisplayName
                        .split(', ')
                        .map((name) => name[0])
                        .reverse()
                        .join(''),
                    hidePersonaDetails: true,
                },
            ],
            timeStamp: `by ${createdByDisplayName} ${createdTimeAgo} ago ${
                modifiedTimeAgo !== createdTimeAgo ? '- Edited ' + `${modifiedTimeAgo} ago` : ''
            }`,
        };
        return (
            <ActivityItem
                key={index}
                styles={{
                    commentText: { color: undefined },
                    root: { margin: 5, padding: 5, borderBottom: `1px solid ${palette.neutralLighter}` },
                }}
                {...props}
            />
        );
    });
    return <Stack>{communicationActivity}</Stack>;
}

export default CommunicationNotes;
