import { PayloadAction, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { DocumentContext, IDocument, IFile } from 'api/models/document.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import {
    getPatientDocuments,
    getDocumentsType,
    createDocumentsAndUploadFiles,
    updateDocument,
    updateDocuments,
} from './documents.actions';
import { DocumentsState } from './documents.state';
import { v4 as uuid } from 'uuid';
import { format } from 'date-fns';
import { DocumentItemReference } from 'components/Upload';

export type UpdatePendingDocumentPath = keyof Pick<IDocument, 'displayName' | 'expirationDate' | 'typeId'>;

export const getFileExt = (fileName: string) => fileName.split('.').pop();
export const getFileName = (fileName: string) => fileName.substring(0, fileName.lastIndexOf('.'));

export const reducers = {
    updatePendingDocument(
        state: DocumentsState,
        action: PayloadAction<{
            id: string;
            path: UpdatePendingDocumentPath;
            value: string | undefined;
        }>,
    ) {
        if (state.pendingDocuments.length) {
            const { id, path, value } = action.payload;
            const indexOfDocument = state.pendingDocuments.findIndex((doc) => doc.id === id);
            if (indexOfDocument > -1) {
                state.pendingDocuments[indexOfDocument][path] = value;

                // Handle updating the file name/extension
                if (path === 'displayName') {
                    const document = state.pendingDocuments[indexOfDocument];
                    const indexOfFile = state.pendingFiles.findIndex((doc) => doc.id === id);
                    if (indexOfFile > -1 && state.pendingFiles.length && document) {
                        const { id, file } = state.pendingFiles[indexOfFile];
                        const newFile: IFile = {
                            id,
                            file: new File([file], `${value}.${document.extension}`, {
                                type: file.type,
                            }),
                        };
                        state.pendingFiles[indexOfFile] = newFile;
                    }
                }
            }
        }
    },
    deletePendingDocumentAndFile(state: DocumentsState, action: PayloadAction<string>) {
        if (state.pendingDocuments.length) {
            const id = action.payload;
            state.pendingDocuments = state.pendingDocuments.filter((doc) => doc.id !== id);
            state.pendingFiles = state.pendingFiles.filter((file) => file.id !== id);
        }
    },
    addPendingDocuments(
        state: DocumentsState,
        action: PayloadAction<{ patientId: string; context: DocumentContext; files: File[]; reference?: DocumentItemReference }>,
    ) {
        const { context, files, patientId, reference } = action.payload;

        const fileData: IFile[] = files.map((file) => {
            const id = uuid();
            return { id, file };
        });

        const documents: IDocument[] = fileData.map(({ id, file }) => ({
            id,
            displayName: getFileName(file.name),
            extension: getFileExt(file.name),
            isDeleted: false,
            expirationDate: '',
            uploadedDate: format(new Date(), 'MM/dd/yyyy'),
            context,
            typeId: '',
            resourceId: patientId,
            resourceType: 'Patient',
            references: reference ? { [reference.type]: reference.id } : undefined,
        }));

        state.pendingDocuments = [...state.pendingDocuments, ...documents];
        state.pendingFiles = [...state.pendingFiles, ...fileData];
    },
    cleanupPendingDocumentsAndFiles(state: DocumentsState) {
        state.pendingDocuments = [];
        state.pendingFiles = [];

        // state.saving = LoadingStatus.Idle;
        // state.uploading = LoadingStatus.Idle;
    },
    cleanupDocumentData(state: DocumentsState) {
        state.data = undefined;
    },
};

export const documentsExtraReducers = (
    builder: ActionReducerMapBuilder<DocumentsState>,
): ActionReducerMapBuilder<DocumentsState> =>
    builder
        // [GET] Patient Documents
        .addCase(getPatientDocuments.pending, (state) => {
            state.loading = LoadingStatus.Pending;
        })
        .addCase(getPatientDocuments.fulfilled, (state, action) => {
            state.loading = LoadingStatus.Completed;
            state.data = action.payload;
        })
        .addCase(getPatientDocuments.rejected, (state, action) => {
            state.loading = LoadingStatus.Failed;
            state.errors = action.payload;
        })

        // [GET] Document Types
        .addCase(getDocumentsType.pending, (state) => {
            state.loading = LoadingStatus.Pending;
        })
        .addCase(getDocumentsType.fulfilled, (state, action) => {
            state.loading = LoadingStatus.Completed;
            state.documentType = action.payload;
        })
        .addCase(getDocumentsType.rejected, (state, action) => {
            state.loading = LoadingStatus.Failed;
            state.errors = action.payload;
        })

        // [POST] Create documents and uploade files
        .addCase(createDocumentsAndUploadFiles.pending, (state) => {
            state.uploading = LoadingStatus.Pending;
        })
        .addCase(createDocumentsAndUploadFiles.rejected, (state, action) => {
            state.uploading = LoadingStatus.Failed;
            state.errors = action.payload;
        })
        .addCase(createDocumentsAndUploadFiles.fulfilled, (state, action) => {
            state.uploading = LoadingStatus.Completed;
            state.data = state.data ? [...state.data, ...action.payload] : [...action.payload];

            state.pendingDocuments = [];
            state.pendingFiles = [];
        })

        // [PUT] Update single document
        .addCase(updateDocument.pending, (state) => {
            state.saving = LoadingStatus.Pending;
        })
        .addCase(updateDocument.fulfilled, (state, action) => {
            state.saving = LoadingStatus.Completed;
            if (state.data) state.data = state.data.filter((item) => item.id !== action.payload.id);
        })
        .addCase(updateDocument.rejected, (state, action) => {
            state.saving = LoadingStatus.Failed;
            state.errors = action.payload;
        })

        // [PUT] Update multiple documents
        .addCase(updateDocuments.pending, (state) => {
            state.saving = LoadingStatus.Pending;
        })
        .addCase(updateDocuments.fulfilled, (state, action) => {
            state.saving = LoadingStatus.Completed;
            const data = action.payload;
            if (state.data) {
                data.forEach((file) => {
                    const indexOfFile = state.data?.findIndex((f) => f.id === file.id);
                    if (indexOfFile) data[indexOfFile] = file;
                });
            }
        })
        .addCase(updateDocuments.rejected, (state, action) => {
            state.saving = LoadingStatus.Failed;
            state.errors = action.payload;
        });
