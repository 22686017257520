import { ChartProcedureStatus } from 'api/models/chart.model';
import { isEqual } from 'lodash';
import { createSelector } from 'reselect';
import { chartActionDateLookup, chartActions } from 'state/slices/charting/chart/chart.selectors';
import { IChartAction } from 'state/slices/charting/chartActionsList.pipeline';
import { RootState } from 'state/store';
import { isDateBetween } from 'utils/isDateBetween';
import { initialState } from './state';

export const selectChartActionFilters = (state: RootState) => state.charting.chartActionFilters;

export const selectChartActionFilterType = createSelector(selectChartActionFilters, (filters) => filters.type);
export const selectChartActionFilterStatus = createSelector(selectChartActionFilters, (filters) => filters.status);
export const selectChartActionFilterProvider = createSelector(selectChartActionFilters, (filters) => filters.providers);
export const selectChartActionFilterVisibile = createSelector(selectChartActionFilters, (filters) => filters.filtersVisible);
export const selectChartActionFilterDates = createSelector(selectChartActionFilters, (filters) => ({
    startDate: filters.startDate,
    endDate: filters.endDate,
}));
export const selectChartActionFilterTeeth = createSelector(selectChartActionFilters, (filters) => filters.teeth);
export const selectChartActiionFilterClearDisabled = createSelector(selectChartActionFilters, (filters) =>
    isEqual(filters, initialState),
);

export const filteredChartActions = createSelector(
    [chartActions, selectChartActionFilters, chartActionDateLookup],
    (actions, filters, dates) => {
        if (actions === undefined) {
            return [];
        }
        return actions.filter(filterActions);

        function filterActions(action: IChartAction) {
            const { status, procedureType, treatingProviderId } = action;
            return (
                matchesStatus(status) &&
                matchesType(action) &&
                matchesProvider(treatingProviderId) &&
                dateBetween(action) &&
                matchesTeeth(action)
            );
        }
        function matchesStatus(status?: keyof typeof ChartProcedureStatus) {
            return filters.status.length === 0 || (status !== undefined && filters.status.includes(status));
        }
        function matchesType(action: IChartAction) {
            if (action.actionType === 'Condition' && (filters.type as string[]).includes('Condition')) {
                return true;
            }
            return (
                filters.type.length === 0 || (action.procedureType !== undefined && filters.type.includes(action.procedureType))
            );
        }
        function matchesProvider(providerId?: string) {
            return filters.providers.length === 0 || (providerId && filters.providers.includes(providerId));
        }

        function dateBetween(action: IChartAction) {
            if (!filters.startDate && !filters.endDate) return true;
            return isDateBetween({
                start: filters.startDate,
                end: filters.endDate,
                dateToCheck: dates[action.id],
                classic: true,
            });
        }

        function matchesTeeth(action: IChartAction) {
            return (
                filters.teeth.length === 0 || (action.toothIds && action.toothIds.some((tooth) => filters.teeth.includes(tooth)))
            );
        }
    },
);
