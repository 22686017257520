import EditProperties from './EditProperties';
import LookupEditPanel, { EditComponentProps } from '../Shared/LookupEditPanel';
import { IProcedureDetail } from 'api/models/procedure-detail.model';
import useLookupsStore from 'hooks/store/useLookupsStore';
import { useValidation } from 'hooks';
import { IValidationConfig } from 'hooks/useValidation';
import { DefaultButton } from '@fluentui/react';
import { LoadingStatus } from 'interfaces/loading-statuses';

const builder = (props: EditComponentProps) => <EditProperties {...props} />;

const EditPanel = () => {
    const { createLookup, updateLookup, selectedLookup, lookups } = useLookupsStore<IProcedureDetail>('ProcedureDetails');

    const item = selectedLookup?.item as IProcedureDetail | undefined;

    const onSave = () => {
        if (selectedLookup && selectedLookup.item?.id) {
            if (lookups.items[selectedLookup.item.id]) {
                updateLookup(selectedLookup);
            } else {
                createLookup(selectedLookup);
            }
        }
    };

    const onDeleteTemplate = () => {
        if (selectedLookup) {
            updateLookup({ ...selectedLookup, item: { ...selectedLookup.item, template: '' } });
        }
    };

    const validationConfig: IValidationConfig = [];

    if (selectedLookup?.item?.id)
        validationConfig.push({
            fieldName: 'Procedure Template',
            validation: ['required'],
            value: item?.template === '<br>' ? '' : item?.template,
        });

    if (selectedLookup?.isNew)
        validationConfig.push({
            fieldName: 'Selected Procedure',
            validation: ['required'],
            value: item?.id,
        });

    const [errors, onSubmit] = useValidation(validationConfig, onSave);
    const isSaving = selectedLookup?.savingStatus === LoadingStatus.Pending;

    return (
        <LookupEditPanel<IProcedureDetail>
            lookupType="ProcedureDetails"
            displayName="Procedure Template"
            editComponentBuilder={builder}
            onSave={onSubmit}
            errors={errors}
            footerRightContent={
                !selectedLookup?.isNew ? (
                    <DefaultButton text="Delete" disabled={isSaving} onClick={onDeleteTemplate} />
                ) : undefined
            }
        />
    );
};

export default EditPanel;
