import { ChartProcedurePreAuth } from './chart.model';
import { LookupCodeBase } from './lookup-base.model';
import { ApplicableArea, ConflictRuleType } from './lookup.model';

export interface IProcedure extends LookupCodeBase {
    description: string;
    applicableArea?: ApplicableArea;
    conflictRules?: ConflictRuleType[];
    class?: string;
    renderRule?: ProcedureRenderRule;
    parentId?: string;
    category?: string;
    fee?: number;
    diagnoses?: IProcedureDiagnosis[];
    preAuthStatus?: ChartProcedurePreAuth;
    template?: string;
    billingStatus?: ProcedureBillingStatus;
    procedureStages?: IProcedureStage[];
    stages?: number;
    effectiveDate?: string;
    endDate?: string;
}

export enum ProcedureBillingStatus {
    Billed = 'Billed',
    NotBillable = 'Not Billable',
    Billable = 'Billable',
}

export enum ProcedureStage {
    One = 'One',
    Two = 'Two',
    Three = 'Three',
    Four = 'Four',
    Final = 'Final',
}

export interface IProcedureDiagnosis {
    code: string;
    mode?: string;
}

export interface IProcedureStage {
    stage?: ProcedureStage;
    billableStatus?: ProcedureBillingStatus;
}

export enum ProcedureRenderRule {
    PostCore = 'post-core',
    LabialVeneer = 'labial-veneer',
    PostRemoval = 'post-removal',
    PartialDenture = 'partial-denture',
    MaxillaryDenture = 'maxillary-denture',
    MandibularDenture = 'mandibular-denture',
    SealantPerTooth = 'sealant-per-tooth',
    StainlessSteelCrown = 'stainless-steel-crown',
    RootCanal = 'root-canal',
    Restoration = 'restoration',
    Pontic = 'pontic',
    Crown = 'crown',
    
    AbutmentSupportedCrown = 'abutment-supported-crown',
    AbutmentRetainerCrown = 'abutment-retainer-crown',
    AbutmentThreeQuarterCrown = 'abutment-3/4-retainer-crown',
    AbutmentMarylandBridge = 'abutment-maryland-bridge-retainer-crown',

    SealantRepairPerTooth = 'sealant-repair-per-tooth',
    Extraction = 'extraction',
    ImplantSupportedCrown = 'implant-supported-crown',
    Implant = 'implant',
    ClassV = 'class-v',
}
