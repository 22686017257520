import { IPredeterminationProcedure } from 'api/models/predeterminations.model';
import WorkListBuilder from '../WorkListBuilder';
import { PredeterminationTable } from './PredeterminationTable';

export default function Predeterminations(): JSX.Element {
    return (
        <WorkListBuilder<IPredeterminationProcedure> workLists={['Predeterminations']}>
            {(detailsListData) => {
                if (!detailsListData) return null;
                return <PredeterminationTable {...detailsListData} />;
            }}
        </WorkListBuilder>
    );
}
