import { IComboBoxOption } from '@fluentui/react';
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import { LookupCodeBase } from 'api/models/lookup-base.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { LookupRecordState } from 'interfaces/lookup-state';
import { map, orderBy } from 'lodash';
import { RootState } from 'state/store';

type SexualOrientationState = LookupRecordState<LookupCodeBase>;

const initialState: SexualOrientationState = {
    initialLoad: LoadingStatus.Idle,
    loading: LoadingStatus.Idle,
    data: {},
};

export const getSexualOrientations = createAsyncThunk<
    Record<string, LookupCodeBase>,
    void,
    {
        rejectValue: string;
    }
>('getSexualOrientations', async (_, { rejectWithValue }) => {
    try {
        const { data: sexualOrientations } = await dentalApi.getSexualOrientations();
        return sexualOrientations;
    } catch (e) {
        return rejectWithValue(JSON.stringify(e));
    }
});

const sexualOrientationSlice = createSlice({
    name: 'sexualOrientations',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getSexualOrientations.pending, (state) => {
                state.initialLoad = LoadingStatus.Pending;
            })
            .addCase(getSexualOrientations.fulfilled, (state, action) => {
                state.initialLoad = LoadingStatus.Completed;
                state.data = action.payload;
            })
            .addCase(getSexualOrientations.rejected, (state, action) => {
                state.initialLoad = LoadingStatus.Failed;
                state.errors = action.payload;
            });
    },
});

const { reducer } = sexualOrientationSlice;

export const selectSexualOrientationObject = (state: RootState): Record<string, LookupCodeBase> | undefined =>
    state.tenant.sexualOrientations.data;
export const selectSexualOrientationData = createSelector(selectSexualOrientationObject, (data) => (data ? data : {}));

export const selectSexualOrientation = createSelector(selectSexualOrientationData, (lookups) => {
    return lookups;
});

export const sexualOrientationList = createSelector(selectSexualOrientation, (lookups) => {
    if (lookups) {
        return map(lookups).filter((lookup) => !lookup?.isDeleted);
    }
    return [];
});

export const sexualOrientationOptionsList = createSelector(
    sexualOrientationList,
    (lookupsList) =>
        orderBy(lookupsList, 'code').map((dx) => ({
            key: dx?.id ?? '',
            text: `${dx.displayName.length > 35 ? `${dx.displayName.slice(0, 35)}...` : dx.displayName}`,
            title: `${dx.displayName}`,
        })) as IComboBoxOption[],
);

export default reducer;
