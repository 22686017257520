import { ScrollablePane, ConstrainMode } from '@fluentui/react';
import { SortableDetailsList } from 'components';
import WorkListDetailListRow from '../WorkListDetailListRow';
import { selectWorkListData, selectWorkListLoading } from 'state/slices/admin-huddle/worklists/worklist.selectors';
import { useSelector } from 'hooks';
import { LoadingStatus } from 'interfaces/loading-statuses';
import IWorkListAppointmentView from 'api/models/worklist-appointment-view';
import { WorkListDetailsListDataForChildren } from '../WorkListBuilder';

export default function MissingSlipsBaseDetailsList(props: WorkListDetailsListDataForChildren) {
    const workListData = useSelector(selectWorkListData);
    const loading = useSelector(selectWorkListLoading);
    const loadingPending = loading === LoadingStatus.Pending;

    const { sortColumns = ['apptDateTime'], initialSortDirection = ['asc'] } = props;
    return (
        <div style={{ position: 'relative', flex: 1, display: 'flex' }}>
            <ScrollablePane>
                <SortableDetailsList<IWorkListAppointmentView>
                    onRenderRow={(props) => {
                        if (!props) return null;
                        return <WorkListDetailListRow {...props} id={props.item?.id} />;
                    }}
                    shimmerLines={3}
                    enableShimmer={loadingPending}
                    stickyHeader
                    constrainMode={ConstrainMode.unconstrained}
                    selectionMode={props.selectionMode}
                    sortColumns={sortColumns}
                    sortOnMount
                    initialSortDirection={initialSortDirection}
                    columns={props.columns}
                    items={workListData}
                />
            </ScrollablePane>
        </div>
    );
}
