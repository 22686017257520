import { PayloadAction } from '@reduxjs/toolkit';
import { IReportingState } from '../reporting.state';

export const clinicalNoteReportReducers = {
    cleanupClinicalNoteReportQueue: (state: IReportingState): void => {
        state.clinicalNoteReports.queuedClincalNoteReports = undefined;
    },
    cleanupCurrentlySelectedClinicalNoteReport: (state: IReportingState): void => {
        state.clinicalNoteReports.currentlySelectedClinicalNoteReport = undefined;
    },
    setClinicalPanelOpen: (state: IReportingState): void => {
        state.clinicalNoteReports.clinicalNoteReportPanel = !state.clinicalNoteReports.clinicalNoteReportPanel;
    },
    setCurrentlySelectedClinicalNote: (state: IReportingState, action: PayloadAction<string>): void => {
        state.clinicalNoteReports.currentlySelectedClinicalNoteReport = action.payload;
    },
    toggleSelectedClinicalNoteInQueue: (state: IReportingState, action: PayloadAction<string>): void => {
        const indexOfNote = state.clinicalNoteReports.queuedClincalNoteReports?.findIndex((noteId) => noteId === action.payload);

        if (indexOfNote !== undefined && indexOfNote > -1) {
            state.clinicalNoteReports.queuedClincalNoteReports = state.clinicalNoteReports.queuedClincalNoteReports?.filter(
                (noteId) => noteId !== action.payload,
            );
        } else {
            state.clinicalNoteReports.queuedClincalNoteReports = [
                ...(state.clinicalNoteReports.queuedClincalNoteReports ?? []),
                action.payload,
            ];
        }
    },
};
