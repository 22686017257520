import { createAsyncThunk, createSelector, createSlice, Dictionary } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import { IEncounterStatus } from 'api/models/encounter.model';
import { AxiosResponse } from 'axios';
import { LoadingStatus, LoadingStatuses } from 'interfaces/loading-statuses';
import { map } from 'lodash';
import { RootState } from 'state/store';

type EncounterStatusState = {
    loading: LoadingStatuses;
    data: Dictionary<IEncounterStatus>;
};

const initialState: EncounterStatusState = {
    loading: LoadingStatus.Idle,
    data: {},
};

export const getPatientEncounterStatus = createAsyncThunk<AxiosResponse<Dictionary<IEncounterStatus>>>(
    'getPatientEncounterStatus',
    async () => {
        const res = await dentalApi.getPatientEncounterStatuses();
        return res;
    },
);

const encounterStatusSlice = createSlice({
    name: 'encounterStatus',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getPatientEncounterStatus.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(getPatientEncounterStatus.fulfilled, (state, action) => {
                state.loading = LoadingStatus.Completed;
                state.data = action.payload.data;
            })
            .addCase(getPatientEncounterStatus.rejected, (state, action) => {
                state.loading = LoadingStatus.Failed;
            });
    },
});

const { reducer } = encounterStatusSlice;

export const selectEncounterStatus = (state: RootState): Dictionary<IEncounterStatus> => state.tenant.encounterStatus.data;

export const selectEncounterStatusList = createSelector(selectEncounterStatus, (data) =>
    data ? (map(data, (res) => res) as IEncounterStatus[]) : [],
);

export default reducer;
