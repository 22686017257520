import { IComboBoxOption } from '@fluentui/react';
import { createAsyncThunk, createSelector, createSlice, Dictionary } from '@reduxjs/toolkit';
import dentalApi from 'api/dental.api';
import { LookupCodeBase } from 'api/models/lookup-base.model';
import { AxiosError } from 'axios';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { LookupState } from 'interfaces/lookup-state';
import { map, orderBy } from 'lodash';
import { RootState } from 'state/store';

export interface IGenderIdentity extends LookupCodeBase {
    order: number;
}

type GenderIdentitiesState = LookupState<IGenderIdentity>;

const initialState: GenderIdentitiesState = {
    initialLoad: LoadingStatus.Idle,
    loading: LoadingStatus.Idle,
    data: {},
};

export const getGenderIdentities = createAsyncThunk<
    Dictionary<IGenderIdentity>,
    void,
    {
        rejectValue: AxiosError;
    }
>('getGenderIdentities', async () => {
    const res = await dentalApi.getGenderIdentities();
    return res.data;
});

const genderIdentitiesSlice = createSlice({
    name: 'genderIdentities',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getGenderIdentities.pending, (state) => {
                state.initialLoad = LoadingStatus.Pending;
            })
            .addCase(getGenderIdentities.fulfilled, (state, action) => {
                state.initialLoad = LoadingStatus.Completed;
                state.data = action.payload;
            })
            .addCase(getGenderIdentities.rejected, (state, action) => {
                state.initialLoad = LoadingStatus.Failed;
                state.errors = action.payload?.message;
            });
    },
});

const { reducer } = genderIdentitiesSlice;

export const selectGenderIdentitiesObject = (state: RootState): Dictionary<IGenderIdentity> => state.tenant.genderIdentities.data;
export const selectGenderIdentitiesData = createSelector(selectGenderIdentitiesObject, (data) => (data ? data : {}));

export const selectGenderIdentities = createSelector(selectGenderIdentitiesData, (lookups) => {
    return lookups;
});

export const genderIdentitiesList = createSelector(selectGenderIdentities, (lookups) => {
    if (lookups) {
        return map(lookups).filter((lookup) => !lookup?.isDeleted);
    }
    return [];
});

export const genderIdentitiesOptionsList = createSelector(
    genderIdentitiesList,
    (lookupsList) =>
        orderBy(lookupsList, 'order')
            .map((dx) => ({
                key: dx?.id ?? '',
                text: dx?.displayName
                    ? `${dx.displayName.length > 35 ? `${dx.displayName.slice(0, 35)}...` : dx.displayName}`
                    : '',
                title: `${dx?.displayName ?? ''}`,
            }))
            .filter((gender) => gender.title !== 'Unknown') as IComboBoxOption[],
);

export default reducer;
