import useSelector from 'hooks/useSelector';
import { conditionsAsList, selectConditions, selectConditionsData } from 'state/slices/tenant/conditions.slice';

function useConditions() {
    const { data: conditions, loading } = useSelector(selectConditions);
    const _conditionsAsList = useSelector(conditionsAsList);
    const _selectConditionsData = useSelector(selectConditionsData);

    const getConditionById = (id: string) => {
        return conditions ? conditions[id] : undefined;
    };

    const searchConditionsList = (search: string | undefined) =>
        search
            ? _conditionsAsList.filter(
                  (c) =>
                      c.displayName?.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                      c.description?.toLowerCase().indexOf(search.toLowerCase()) > -1,
              )
            : _conditionsAsList;

    return {
        conditions,
        loading,
        conditionsAsList: _conditionsAsList,
        selectConditionsData: _selectConditionsData,
        getConditionById,
        searchConditionsList,
    };
}

export default useConditions;
