import React from 'react';
import { Text, ITextProps, IPalette, useTheme } from '@fluentui/react';

type Props = {
    children: React.ReactNode;
    color?: keyof IPalette;
    bold?: boolean;
} & ITextProps;

const TText = ({ children, color, bold = false, ...props }: Props): JSX.Element => {
    const theme = useTheme();
    const getColor = () => {
        return color ? theme.palette[color] : theme?.palette.neutralPrimary;
    };
    return (
        <Text
            styles={{
                root: { color: `${getColor()} !important`, fontWeight: bold ? 'bold' : 'normal' },
            }}
            {...props}
        >
            {children}
        </Text>
    );
};

export default TText;
