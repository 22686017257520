import { DefaultButton, IconButton, Label, Spinner, Stack, TooltipHost } from '@fluentui/react';
import { useTenantId } from 'hooks';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { PropsWithChildren, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWorkListData } from 'state/slices/admin-huddle/worklists/worklist.actions';
import {
    selectWorkListLoading,
    selectWorkListHasFilters,
    selectCurrentWorkList,
    selectWorkListHasSearched,
} from 'state/slices/admin-huddle/worklists/worklist.selectors';
import {
    cleanupWorkListFilters,
    cleanupWorkListPagination,
    setWorkListCurrentPageNumber,
    setWorkListHasSearched,
} from 'state/slices/admin-huddle/worklists/worklist.slice';

type Props = PropsWithChildren<unknown>;

/**Wraps worklist related filters. It's children are typically worklist filter components*/
export default function WorkListFilterWrapper({ children }: Props) {
    const dispatch = useDispatch();
    const tenantId = useTenantId();

    const loading = useSelector(selectWorkListLoading) === LoadingStatus.Pending;

    const hasSearched = useSelector(selectWorkListHasSearched);
    const hasFilters = useSelector(selectWorkListHasFilters);

    const workList = useSelector(selectCurrentWorkList);

    const _resetFilters = () => {
        dispatch(cleanupWorkListFilters());
    };

    /**Get current worklist data */
    const _getEncounterWorkListData = () => {
        if (workList) dispatch(getWorkListData({ tenantId, workList: workList }));
    };

    /**Resets the worklist current page number to 1 */
    const _resetPageNumber = () => {
        dispatch(setWorkListCurrentPageNumber(1));
    };

    /**Handles refetching the worklist and sets hasSearched to true in state */
    const _onSearch = () => {
        _refetchWorkList(true);
    };

    /**Refetch the current worklist, reset pagination, current page number and has searched */
    const _refetchWorkList = (hasSearched: boolean) => {
        dispatch(cleanupWorkListPagination());
        _getEncounterWorkListData();
        _resetPageNumber();
        dispatch(setWorkListHasSearched(hasSearched));
    };

    useEffect(() => {
        //If the user has searched and there are no more filters. This must mean we need to refetch the worklist data.
        if (hasSearched && !hasFilters) {
            _refetchWorkList(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasSearched, hasFilters]);

    return (
        <Stack tokens={{ childrenGap: 10 }} horizontal horizontalAlign="space-between" wrap>
            {children}
            <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign="end">
                <TooltipHost content="Clear Filters">
                    <IconButton disabled={!hasFilters || loading} iconProps={{ iconName: 'Cancel' }} onClick={_resetFilters} />
                </TooltipHost>
                <DefaultButton
                    iconProps={{ iconName: 'Search' }}
                    text="Search"
                    disabled={!hasFilters || loading}
                    onClick={_onSearch}
                    onRenderText={(props) => {
                        return (
                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                                <Label>{props?.text}</Label>
                                {hasFilters && loading && <Spinner />}
                            </Stack>
                        );
                    }}
                ></DefaultButton>
            </Stack>
        </Stack>
    );
}
