import React, { useState } from 'react';
import {
    ILabelStyleProps,
    ILabelStyles,
    IStackTokens,
    IStyleFunctionOrObject,
    Label,
    PrimaryButton,
    Stack,
    Text,
    TooltipHost,
} from '@fluentui/react';
import { ReportType } from 'api/models/embed-report.model';
import { useSelector } from 'hooks';
import { RouteParams } from 'interfaces/route-params';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectTotalPatientBalance, selectUnscheduledProceduresCreditAmount } from 'state/slices/ledger/ledger.selectors';
import { selectTreatmentPlanCreditUnappliedPaymentsTotals } from 'state/slices/ledger/treatment-plan-credit-and-uac/treatment-plan-credit-and-uac.selectors';
import { selectSelectedPatient } from 'state/slices/patient/patient.selectors';
import reportActionLookup from 'state/slices/reporting/reportActionLookup';
import { usdCurrencyFormatter } from 'utils';
import PrintReceiptsPanel from './PrintReceiptsPanel'; // Add this line

const labelStyles: IStyleFunctionOrObject<ILabelStyleProps, ILabelStyles> = {
    root: { padding: 0, fontSize: 15 },
};

const childStackTokens: IStackTokens = {
    childrenGap: 5,
};

const OverallLedgerTotals = () => {
    const [isPanelOpen, setIsPanelOpen] = useState(false);

    const _selectTotalPatientBalance = useSelector(selectTotalPatientBalance);
    const patientReservePayments = useSelector(selectUnscheduledProceduresCreditAmount);
    const unappliedCreditTotal = useSelector(selectTreatmentPlanCreditUnappliedPaymentsTotals);
    const selectedPatient = useSelector(selectSelectedPatient);

    const { tenantId } = useParams<RouteParams>();
    const dispatch = useDispatch();

    const printClaimHistory = () => {
        if (selectedPatient?.references?.patient_athenaOne)
            dispatch(
                reportActionLookup[ReportType.ClaimHistory]({
                    tenantId,
                    id: selectedPatient?.references.patient_athenaOne,
                    extraParamsQueryString: true,
                }),
            );
    };

    return (
        <Stack
            styles={{
                root: { paddingLeft: 10, height: '100%' },
            }}
            verticalAlign="center"
            grow
        >
            <Stack tokens={{ childrenGap: 30 }} horizontal>
                <PrimaryButton
                    iconProps={{ iconName: 'Print' }}
                    menuProps={{
                        items: [
                            { key: 'printClaimHistory', text: 'Claim History', onClick: printClaimHistory },
                            { key: 'printReceiptsPanel', text: 'Print Receipts', onClick: () => setIsPanelOpen(true) },
                        ],
                    }}
                >
                    Print
                </PrimaryButton>
                <Stack tokens={{ childrenGap: 10 }} horizontal>
                    <Stack tokens={{ childrenGap: -2 }} styles={{ root: { marginTop: -12 } }}>
                        <TooltipHost content="Current patient responsibility for billed encounters.">
                            <Stack tokens={childStackTokens} horizontal>
                                <Label styles={labelStyles}>Patient Balance:</Label>{' '}
                                <b>{usdCurrencyFormatter.format(_selectTotalPatientBalance)}</b>
                            </Stack>
                        </TooltipHost>
                        <Text>TP Prepayments: {usdCurrencyFormatter.format(patientReservePayments)}</Text>
                        <Text>Unapplied Credits: {usdCurrencyFormatter.format(unappliedCreditTotal)}</Text>
                    </Stack>
                </Stack>
            </Stack>
            <PrintReceiptsPanel isOpen={isPanelOpen} onDismiss={() => setIsPanelOpen(false)} paymentData={[]} />
            {/* Add this line */}
        </Stack>
    );
};

export default OverallLedgerTotals;
