import { PayloadAction } from '@reduxjs/toolkit';
import { ToothArea } from 'api/models/tooth-area';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { dentition } from 'pages/Charting/components/ToothCanvas/settings';
import { ChartingState } from '../chart/chart.slice';
import { IChartingPipelineErrorData } from './ui.state';
import { ChartingSection } from './ui.types';

const uiReducers = {
    toggleSectionChooserExpanded: (state: ChartingState): void => {
        state.ui.isSectionChooserExpanded = !state.ui.isSectionChooserExpanded;
    },
    setMenu: (state: ChartingState, action: PayloadAction<'quickActions' | 'procedure' | 'xrays' | 'notes'>): void => {
        state.ui.menu = action.payload;
    },
    setAssetsLoaded: (state: ChartingState, action: PayloadAction<boolean>): void => {
        state.ui.assetsLoaded = action.payload;
    },
    selectTooth: (state: ChartingState, action: PayloadAction<{ position: number }>): void => {
        if (state.ui.selectedTeeth) {
            const { position } = action.payload;
            const toothIndex = state.ui.selectedTeeth.indexOf(position);
            if (toothIndex === -1) {
                state.ui.selectedTeeth = [...state.ui.selectedTeeth, position];
            } else {
                state.ui.selectedTeeth = state.ui.selectedTeeth.filter((tooth) => tooth !== position);
            }
        }
    },
    cleanupChart: (state: ChartingState): void => {
        state.chart = undefined;
        state.loading = LoadingStatus.Idle;
    },

    deselectAllTeeth: (state: ChartingState): void => {
        state.ui.selectedTeeth = [];
    },
    selectAllTeeth: (state: ChartingState): void => {
        if (state.ui.selectedTeeth) state.ui.selectedTeeth = dentition.permanent;
    },
    toggleSelectedAreas: (
        state: ChartingState,
        action: PayloadAction<keyof typeof ToothArea | (keyof typeof ToothArea)[]>,
    ): void => {
        if (Array.isArray(action.payload)) {
            state.ui.selectedAreas = action.payload;
        } else {
            const indexOfArea = state.ui.selectedAreas.indexOf(action.payload);

            if (indexOfArea > -1) {
                state.ui.selectedAreas = state.ui.selectedAreas.filter((area) => area !== action.payload);
            } else {
                state.ui.selectedAreas = [...state.ui.selectedAreas, action.payload];
            }
        }
    },
    clearSelectedAreas: (state: ChartingState): void => {
        state.ui.selectedAreas = [];
    },
    setChartingSection: (state: ChartingState, action: PayloadAction<ChartingSection>): void => {
        state.ui.section = action.payload;
    },
    setProcedureBrowserOpen: (state: ChartingState, action: PayloadAction<boolean>): void => {
        const isOpen = action.payload;
        state.ui.isProcedureBrowserOpen = isOpen;
    },
    setDeleteModalOpen: (state: ChartingState, action: PayloadAction<boolean>): void => {
        const isOpen = action.payload;
        state.ui.isDeleteModalOpen = isOpen;
    },
    setChartingPipelineError: (state: ChartingState, action: PayloadAction<IChartingPipelineErrorData | undefined>): void => {
        state.ui.chartingPipelineErrorData = action.payload;
    },
};

export default uiReducers;
