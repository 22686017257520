import { DirectionalHint, IconButton, IDropdownOption, Stack, TooltipHost } from '@fluentui/react';
import { TimeTableRange } from 'api/models/Scheduling/operatory.model';
import { Field } from 'components';
import { useState } from 'react';
import { timeOptions as originalTimeOptions } from 'utils/getTimeOptions';

type RangeProps = {
    range: TimeTableRange;
    setRange: (range: TimeTableRange) => void;
    deleteRange: (range: TimeTableRange) => void;
    hasTimeConflict: (startTime: string) => boolean;
    rangeIndex: number;
    ranges: TimeTableRange[];
    overrideTimeOptions?: IDropdownOption[];
};

export default function EditRange({
    range,
    setRange,
    deleteRange,
    hasTimeConflict,
    ranges,
    rangeIndex,
    overrideTimeOptions,
}: RangeProps): JSX.Element {
    const [conflictError, setConflictError] = useState('');

    const startTimeChangeHandler = (value: string) => {
        setRange({ ...range, startTime: value ?? '' });
        if (hasTimeConflict(value)) {
            setConflictError('Start time can not be before the previous end time.');
        } else {
            setConflictError('');
        }
    };

    const endTimeChangeHandler = (value?: string) => setRange({ ...range, endTime: value ?? '' });

    const timeValidation =
        range.startTime && range.endTime && range.startTime > range.endTime ? ' Start time must be before end time ' : '';

    const rangeKey = `${range.startTime}-${range.endTime}`;

    const timeOptions = overrideTimeOptions ?? originalTimeOptions;
    /**
     * Need a way to pass custom time options that are filtered if needed.
     *
     * For the filter:
     * Map all schedules from all locs and they need to be same day.
     * Sort the list of end times by greatest to least then get first index.
     */

    const startTimeOptions =
        ranges.length >= 2 && rangeIndex >= 1 // {08:00 - '12:00'} {11:00 - }
            ? timeOptions.filter((option) => option.key >= (ranges[rangeIndex - 1]?.endTime ?? '00:00'))
            : timeOptions;

    const endTimeOptions = range.startTime ? timeOptions.filter((option) => option.key >= (range?.startTime ?? '00:00')) : timeOptions;

    return (
        <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 10 }}>
            <Stack.Item grow>
                <Field.Dropdown
                    label="Start Time"
                    options={startTimeOptions}
                    selectedKey={range.startTime}
                    styles={{ dropdown: { width: 100 } }}
                    onChange={(_, option) => {
                        if (option) startTimeChangeHandler(option.key as string);
                    }}
                    errorMessage={conflictError}
                />
            </Stack.Item>

            <Stack.Item grow>
                <Field.Dropdown
                    label="End Time"
                    options={endTimeOptions}
                    selectedKey={range.endTime}
                    required
                    styles={{ dropdown: { width: 100 } }}
                    // styles={{ optionsContainer: { width: 160 }, root: { borderColor: 'transparent' } }}
                    onChange={(_, option) => {
                        if (option) endTimeChangeHandler(option.key as string);
                    }}
                    errorMessage={timeValidation ? timeValidation : ''}
                />
            </Stack.Item>
            <Stack.Item align="end">
                <TooltipHost content="Remove Range." directionalHint={DirectionalHint.rightCenter} id={rangeKey}>
                    <IconButton text="Remove" iconProps={{ iconName: 'Cancel' }} onClick={() => deleteRange(range)} />
                </TooltipHost>
            </Stack.Item>
        </Stack>
    );
}
