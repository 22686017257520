import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus, LoadingStatuses } from 'interfaces/loading-statuses';
import { SyncStatus } from 'interfaces/syncing-statuses';
import IForm from 'api/models/form';
import { addPatientForm, getAllPatientForms, getPatientFormById, searchPatientForms, updatePatientForm } from './forms.actions';
import forms from 'forms/forms';
import { isEqual } from 'lodash';

export type FormState = {
    data: IForm[];
    searchResults?: IForm[];
    syncing: SyncStatus;
    loading: LoadingStatuses;
    loadingSelectedForm: LoadingStatuses;
    selectedForm?: IForm;
    editingCompletedForm?: boolean;
    error?: any;
};

const initialState: FormState = {
    data: [],
    loading: LoadingStatus.Idle,
    loadingSelectedForm: LoadingStatus.Idle,
    syncing: SyncStatus.Idle,
};

const formsSlice = createSlice({
    name: 'forms',
    initialState,
    reducers: {
        setEditingCompletedForm: (state, action: PayloadAction<boolean>) => {
            state.editingCompletedForm = action.payload;
        },
        cleanupFormsSearch: (state) => {
            state.searchResults = undefined;
        },
        cleanupSelectedForm: (state) => {
            state.selectedForm = undefined;
            state.loadingSelectedForm = LoadingStatus.Idle;
            state.syncing = SyncStatus.Idle;
            state.editingCompletedForm = false;
        },
        setBaseFormDataProp: (state, action: PayloadAction<{ path: keyof IForm; value: any }>) => {
            const { path, value } = action.payload;
            if (state.selectedForm) (state.selectedForm as any)[path] = value;
        },
        setSelectedForm: (state, action: PayloadAction<IForm>) => {
            state.selectedForm = action.payload;
        },
        setSelectedFormData: (state, action: PayloadAction<any>) => {
            if (state.selectedForm) state.selectedForm.data = action.payload;
        },
        setFormFieldData: (state, action: PayloadAction<{ path: any; value: any }>) => {
            if (state.selectedForm) {
                const { path, value } = action.payload;
                if (!state.selectedForm.data) state.selectedForm.data = {};
                state.selectedForm.data[path] = value;

                if (forms[state.selectedForm.formName].calculateScore) {
                    state.selectedForm.data.score = (forms[state.selectedForm.formName] as any).calculateScore(
                        state.selectedForm.data,
                    );
                }
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPatientFormById.pending, (state) => {
                state.loadingSelectedForm = LoadingStatus.Pending;
            })
            .addCase(getPatientFormById.fulfilled, (state, action) => {
                state.loadingSelectedForm = LoadingStatus.Completed;
                state.selectedForm = action.payload;
            })
            .addCase(getPatientFormById.rejected, (state, action) => {
                state.loadingSelectedForm = LoadingStatus.Failed;
                state.error = action.payload;
            })
            .addCase(getAllPatientForms.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(getAllPatientForms.fulfilled, (state, action) => {
                state.loading = LoadingStatus.Completed;
                state.data = action.payload;
            })
            .addCase(getAllPatientForms.rejected, (state, action) => {
                state.loading = LoadingStatus.Failed;
                state.error = action.payload;
            })
            .addCase(updatePatientForm.pending, (state) => {
                state.syncing = SyncStatus.Pending;
            })
            .addCase(updatePatientForm.fulfilled, (state, action) => {
                state.syncing = SyncStatus.Saved;
                if (isEqual(state.selectedForm, action.payload)) {
                    state.selectedForm = action.payload;
                } else if (state.selectedForm) {
                    state.selectedForm = { ...state.selectedForm, _etag: action.payload._etag };
                }

                if (state.data) {
                    const indexOfForm = state.data.findIndex((f) => f.id === action.payload.id);
                    state.data[indexOfForm] = action.payload;
                }
            })
            .addCase(updatePatientForm.rejected, (state, action) => {
                state.syncing = SyncStatus.Failed;
                state.error = action.payload;
            })
            .addCase(addPatientForm.pending, (state) => {
                state.syncing = SyncStatus.Pending;
            })
            .addCase(addPatientForm.fulfilled, (state, action) => {
                state.syncing = SyncStatus.Saved;
                state.data = [...state.data, action.payload];
            })
            .addCase(addPatientForm.rejected, (state, action) => {
                state.syncing = SyncStatus.Failed;
                state.error = action.payload;
            })
            .addCase(searchPatientForms.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(searchPatientForms.fulfilled, (state, action) => {
                state.loading = LoadingStatus.Completed;
                state.searchResults = action.payload;
            })
            .addCase(searchPatientForms.rejected, (state, action) => {
                state.loading = LoadingStatus.Failed;
                state.error = action.payload;
            });
    },
});

const { reducer, actions } = formsSlice;
export const {
    setFormFieldData,
    setSelectedForm,
    cleanupSelectedForm,
    setBaseFormDataProp,
    setSelectedFormData,
    setEditingCompletedForm,
} = actions;

export default reducer;
