import { PayloadAction } from '@reduxjs/toolkit';
import { ProceduresDateAgingReportQuery } from 'api/models/procedure-date-aging.model';
import { IReportingState } from '../reporting.state';

export const proceduresDateAgingReducers = {
    setProceduresDateAgingProp: (
        state: IReportingState,
        action: PayloadAction<{ path: keyof ProceduresDateAgingReportQuery; value: string | number | boolean | undefined }>,
    ): void => {
        const { path, value } = action.payload;
        ((state.reportProperties as ProceduresDateAgingReportQuery)[path] as string | number | boolean | undefined) = value;
    },
    toggleProceduresDateAgingId: (
        state: IReportingState,
        action: PayloadAction<{ path: keyof ProceduresDateAgingReportQuery; id: string }>,
    ): void => {
        const { id, path } = action.payload;
        if (!(state.reportProperties as ProceduresDateAgingReportQuery)[path])
            ((state.reportProperties as ProceduresDateAgingReportQuery)[path] as unknown[]) = [];

        const currentIds = (state.reportProperties as ProceduresDateAgingReportQuery)[path] as string[];

        if (currentIds)
            if (currentIds?.includes(id)) {
                ((state.reportProperties as ProceduresDateAgingReportQuery)[path] as string[]) = currentIds.filter(
                    (_id) => _id !== id,
                );
            } else {
                ((state.reportProperties as ProceduresDateAgingReportQuery)[path] as string[]) = [...currentIds, id];
            }
    },
};
