import { ITextFieldProps, TextField } from '@fluentui/react';
import { FormEvent, useEffect, useState } from 'react';

export interface ICurrencyFieldProps extends Omit<ITextFieldProps, 'value' | 'onChange'> {
    max?: number;
    value?: number | undefined;
    onChange?: (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: number | undefined) => void;
}

function numberWithCommas(number?: string) {
    return number?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function convertIntToCurrency(value: number | undefined) {
    return Number(value ? value / 100 : 0).toFixed(2);
}

function convertCurrencyToInt(value: string | undefined) {
    return value ? +value.toString().replace('.', '') : 0;
}

function getCurrencyValueAsString(value: number | undefined) {
    if (value === undefined) return '';
    return numberWithCommas(convertIntToCurrency(value).toString());
}

export default function CurrencyField(props: ICurrencyFieldProps) {
    const { value, onChange, max } = props;
    const [intValue, setIntValue] = useState<number | undefined>(value);

    useEffect(() => {
        const newVal = value !== undefined ? convertIntToCurrency(value ? value * 100 : 0) : '';
        const newInt = convertCurrencyToInt(newVal);
        if (intValue !== newInt) setIntValue(newInt);
    }, [value, intValue]);

    const formattedValue = getCurrencyValueAsString(intValue);

    const getOutboundValueFromInt = (intValue: number | undefined) => {
        return intValue !== undefined ? +convertIntToCurrency(intValue) : undefined;
    };

    const _onChange = (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>, val: string | undefined) => {
        const newValue = val?.replace(/,/g, '');
        let intVal = newValue !== undefined ? convertCurrencyToInt(newValue) : undefined;
        let outboundValue = getOutboundValueFromInt(intVal);

        if (outboundValue !== undefined && max !== undefined && outboundValue > max) {
            intVal = max * 100;
            outboundValue = getOutboundValueFromInt(intVal);
        }

        setIntValue(intVal);
        if (onChange) {
            onChange(e, outboundValue);
        }
    };

    return (
        <TextField
            {...props}
            autoComplete="off"
            onChange={_onChange}
            value={formattedValue}
            onClick={(e) => {
                const valueLength = e.currentTarget.value.length;
                e.currentTarget.setSelectionRange(valueLength, valueLength);
            }}
        />
    );
}
