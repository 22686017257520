import useSelector from 'hooks/useSelector';
import {
    selectConditionsPanel,
    selectConditionValidationErrors,
    selectNewPanelConditions,
} from 'state/slices/charting/condition-panel/condition-panel.selectors';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
    cleanupConditionPanel,
    setCondition,
    setConditionPanelOpen,
    setConditionPanelSelectedSurfaces,
    setConditionPanelSelectedTeeth,
} from 'state/slices/charting/chart/chart.slice';
import { ICondition } from 'api/models/lookup.model';
import { ToothArea } from 'api/models/tooth-area';
import { handleConditionPanelSave } from 'state/slices/charting/condition-panel/condition-panel.actions';
import { IChartCondition } from 'api/models/chart.model';
import { usePatientId, useTenantId } from 'hooks';
import useEncounterId from 'hooks/useEncounterId';

function useConditionsPanel() {
    const dispatch = useDispatch();
    const { isOpen, panelTeethData, selectedTeeth, isEditing, condition, note } = useSelector(selectConditionsPanel);
    const _selectConditionValidationErrors = useSelector(selectConditionValidationErrors);
    const _selectNewPanelConditions = useSelector(selectNewPanelConditions);

    const tenantId = useTenantId();
    const patientId = usePatientId();
    const encounterId = useEncounterId();

    const _setCondition = useCallback(
        (condition: ICondition | undefined) => {
            dispatch(setCondition(condition));
        },
        [dispatch],
    );

    const _setConditionPanelOpen = useCallback(
        (isOpen: boolean) => {
            dispatch(setConditionPanelOpen(isOpen));
        },
        [dispatch],
    );

    const _setConditionPanelSelectedTeeth = useCallback(
        (selectedTeeth: number[]) => {
            dispatch(setConditionPanelSelectedTeeth(selectedTeeth));
        },
        [dispatch],
    );

    const _setConditionPanelSelectedSurfaces = useCallback(
        (params: { surfaces: (keyof typeof ToothArea)[]; tooth: number }) => {
            dispatch(setConditionPanelSelectedSurfaces({ ...params }));
        },
        [dispatch],
    );

    const _handleConditionPanelSave = useCallback(
        (conditions: IChartCondition[]) => {
            if (patientId) dispatch(handleConditionPanelSave(tenantId, patientId, conditions, encounterId));
        },
        [dispatch, tenantId, patientId, encounterId],
    );
    //setConditionPanelSelectedSurfaces

    const _cleanupConditionPanel = useCallback(() => {
        dispatch(cleanupConditionPanel());
    }, [dispatch]);

    return {
        isOpen,
        panelTeethData,
        selectedTeeth,
        condition,
        isEditing,
        note,
        selectConditionValidationErrors: _selectConditionValidationErrors,
        selectNewPanelConditions: _selectNewPanelConditions,

        //Actions
        setCondition: _setCondition,
        setConditionPanelOpen: _setConditionPanelOpen,
        setConditionPanelSelectedTeeth: _setConditionPanelSelectedTeeth,
        cleanupConditionPanel: _cleanupConditionPanel,
        setConditionPanelSelectedSurfaces: _setConditionPanelSelectedSurfaces,
        handleConditionPanelSave: _handleConditionPanelSave,
    };
}

export default useConditionsPanel;
