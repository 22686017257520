import { Stack, Text, useTheme } from '@fluentui/react';
import { IPatientInsurance } from 'api/models/patient.model';
import { SubSection } from 'components';
import ChoiceGroupField from 'components/Field/ChoiceGroupField';
import { getValidationError, IValidationError } from 'hooks/useValidation';

type Props = {
    insurance?: IPatientInsurance;
    onPolicyHolderPropChange: (option: string) => void;
    validationErrors: IValidationError[];
};

function PolicyHolderInformation({ insurance, onPolicyHolderPropChange, validationErrors }: Props): JSX.Element {
    const theme = useTheme();

    return (
        <SubSection title="Policy Holder Information">
            <Stack tokens={{ childrenGap: 5 }} grow>
                <ChoiceGroupField
                    label="Policy Holder is the"
                    options={[
                        {
                            key: 'Patient',
                            text: 'Patient',
                        },
                        {
                            key: 'Other Contact',
                            text: 'Other Contact',
                        },
                    ]}
                    selectedKey={insurance?.policyHolderType}
                    required
                    onChange={(e, option) => {
                        if (option) {
                            onPolicyHolderPropChange(option.key);
                        }
                    }}
                    inline={true}
                />
                {getValidationError(validationErrors, 'Policy Holder') && (
                    <Text variant="small" styles={{ root: { color: theme.palette.redDark } }}>
                        Policy Holder is required.
                    </Text>
                )}
            </Stack>
        </SubSection>
    );
}

export default PolicyHolderInformation;
