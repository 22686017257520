import { Stack, SelectionMode, IconButton } from '@fluentui/react';
import { Field, SortableDetailsList } from 'components';
import { useSelector } from 'hooks';
import { useDispatch } from 'react-redux';
import {
    selectGroupedPayersForReportingGroups,
    selectSelectedReportingCategoryItems,
} from 'state/slices/reporting/reporting-groups/reporting-groups.selectors';
import {
    addSelectedReportingCategoryItem,
    removeSelectedReportingCategoryItem,
    setSelectedReportingCategoryItems,
} from 'state/slices/reporting/reporting.slice';
import { selectTenantPayersData } from 'state/slices/tenant/tenant-payers.slice';

export default function ReportingGroupCategoryPayers() {
    const _payerOptions = useSelector(selectGroupedPayersForReportingGroups);
    const _payer = useSelector(selectTenantPayersData);

    const _selectSelectedReportingCategoryItems = useSelector(selectSelectedReportingCategoryItems);

    const dispatch = useDispatch();

    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <Stack.Item grow>
                <Field.SearchCombo
                    label="Search Payers"
                    selectedKey={_selectSelectedReportingCategoryItems}
                    options={_payerOptions}
                    onChange={(ev, option) => {
                        if (option) {
                            const itemId = option.key as string;
                            if (_selectSelectedReportingCategoryItems.includes(itemId)) {
                                //remove
                                dispatch(removeSelectedReportingCategoryItem(itemId));
                            } else {
                                //add
                                dispatch(addSelectedReportingCategoryItem(itemId));
                            }
                        }
                    }}
                    onClear={() => {
                        dispatch(setSelectedReportingCategoryItems([]));
                    }}
                    virutalized
                    threshold={0.3}
                    maxResults={100}
                    multiSelect
                />
            </Stack.Item>
            {_selectSelectedReportingCategoryItems.length && (
                <SortableDetailsList<string>
                    columns={[
                        {
                            key: 'payer',
                            name: 'Payer',
                            fieldName: 'payer',
                            minWidth: 70,
                            maxWidth: 200,
                            onRender: (payerId) => {
                                if (payerId) {
                                    const name = _payer[payerId];
                                    return name?.name ?? '';
                                }
                            },
                            getValueKey: (payerId) => {
                                if (payerId) {
                                    const name = _payer[payerId];
                                    return name?.name ?? '';
                                }
                                return '';
                            },
                        },
                        {
                            key: 'payerId',
                            name: 'Payer ID',
                            minWidth: 70,
                            maxWidth: 100,
                            onRender: (payerId) => {
                                if (payerId) {
                                    const name = _payer[payerId];
                                    return name?.payerId ?? '';
                                }
                            },
                            getValueKey: (payerId) => {
                                if (payerId) {
                                    const name = _payer[payerId];
                                    return name?.payerId ?? '';
                                }
                                return '';
                            },
                        },
                        {
                            key: 'displayName',
                            name: 'Display Name',
                            minWidth: 70,
                            onRender: (payerId) => {
                                if (payerId) {
                                    const name = _payer[payerId];
                                    return name?.name ?? '';
                                }
                            },
                        },
                        {
                            key: 'delete',
                            name: 'Delete',
                            minWidth: 70,
                            maxWidth: 200,
                            onRender: (item) => {
                                return (
                                    <IconButton
                                        onClick={() => {
                                            if (item) dispatch(removeSelectedReportingCategoryItem(item));
                                        }}
                                        iconProps={{ iconName: 'Delete' }}
                                    />
                                );
                            },
                        },
                    ]}
                    items={_selectSelectedReportingCategoryItems}
                    selectionMode={SelectionMode.none}
                    sortColumns={['payer']}
                    initialSortDirection={['asc']}
                    sortOnMount
                />
            )}
        </Stack>
    );
}
