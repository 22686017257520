import { PayloadAction } from '@reduxjs/toolkit';
import { ChartingState } from 'state/slices/charting/chart/chart.slice';

export const chartActionFilterReducers = {
    setChartActionFilterStatus: (state: ChartingState, action: PayloadAction<string>) => {
        if (!state.chartActionFilters.status.includes(action.payload)) {
            state.chartActionFilters.status = [...state.chartActionFilters.status, action.payload];
        } else {
            state.chartActionFilters.status = state.chartActionFilters.status.filter((status) => status !== action.payload);
        }
    },
    setChartActionFilterType: (state: ChartingState, action: PayloadAction<string>) => {
        if (!state.chartActionFilters.type.includes(action.payload)) {
            state.chartActionFilters.type = [...state.chartActionFilters.type, action.payload];
        } else {
            state.chartActionFilters.type = state.chartActionFilters.type.filter((type) => type !== action.payload);
        }
    },
    setChartActionFilterProvider: (state: ChartingState, action: PayloadAction<string>) => {
        if (!state.chartActionFilters.providers.includes(action.payload)) {
            state.chartActionFilters.providers = [...state.chartActionFilters.providers, action.payload];
        } else {
            state.chartActionFilters.providers = state.chartActionFilters.providers.filter(
                (providers) => providers !== action.payload,
            );
        }
    },
    setChartActionFilterTeeth: (state: ChartingState, action: PayloadAction<number>) => {
        if (!state.chartActionFilters.teeth.includes(action.payload)) {
            state.chartActionFilters.teeth = [...state.chartActionFilters.teeth, action.payload];
        } else {
            state.chartActionFilters.teeth = state.chartActionFilters.teeth.filter((teeth) => teeth !== action.payload);
        }
    },
    setChartActionFilterDates: (
        state: ChartingState,
        action: PayloadAction<{ path: 'startDate' | 'endDate'; value?: string }>,
    ) => {
        const { path, value } = action.payload;
        if (!value) {
            state.chartActionFilters[path] = '';
        } else {
            state.chartActionFilters[path] = value;
        }
    },
    cleanupChartActionFilters: (state: ChartingState) => {
        state.chartActionFilters.type = [];
        state.chartActionFilters.status = [];
        state.chartActionFilters.providers = [];
        state.chartActionFilters.search = null;
        state.chartActionFilters.startDate = '';
        state.chartActionFilters.endDate = '';
        state.chartActionFilters.teeth = [];
    },
    toggleChartActionFilterVisibility: (state: ChartingState) => {
        state.chartActionFilters.filtersVisible = !state.chartActionFilters.filtersVisible;
    },
};
