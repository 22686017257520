import { IPanelStyles, Panel, PanelType } from '@fluentui/react';
import { useSelector } from 'hooks';
import { useDispatch } from 'react-redux';
import { chartingActions } from 'state/slices/charting/chart/chart.slice';
import { isProcedureBrowserOpen } from 'state/slices/charting/ui/chartingUi.selectors';
import ProcedureBrowserList from './ProcedureBrowserList';

function ProcedureBrowserPanel(): JSX.Element {
    const dispatch = useDispatch();
    const isOpen = useSelector(isProcedureBrowserOpen);
    const _closePanel = () => dispatch(chartingActions.setProcedureBrowserOpen(false));

    const _panelStyles: Partial<IPanelStyles> = {
        content: { overflowY: 'auto', overflowX: 'hidden', flex: 1, height: '100%', paddingRight: 0 },
        root: { overflow: 'hidden' },
        scrollableContent: { overflow: 'hidden', display: 'flex', flex: 1, flexDirection: 'column' },
    };

    return (
        <Panel
            headerText="Procedure Browser"
            styles={_panelStyles}
            isOpen={isOpen}
            onDismiss={_closePanel}
            type={PanelType.custom}
            customWidth="888px"
        >
            <ProcedureBrowserList />
        </Panel>
    );
}

export default ProcedureBrowserPanel;
