export default function formatPhoneNumber(value?: string): string {
    if (value) {
        if (value.length === 10) {
            return value.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
        } else {
            console.error(`Value for phone number [${value}] is incorrect, unable to format.`)
            return value;
        }
    } else {
        return ''
    }
}