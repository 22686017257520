import { IBillingProcedure } from 'api/models/billing-procedure.model';
import { SortableDetailsList } from 'components';
import { useSelector } from 'hooks';
import {
    Text,
    Stack,
    SelectionMode,
    Selection,
    IObjectWithKey,
    useTheme,
    CommandBar,
    ICommandBarItemProps,
    IContextualMenuItem,
    TooltipHost,
    DirectionalHint,
    DetailsRow,
    DetailsRowCheck,
    IDetailsRowBaseProps,
    IDetailsRowCheckStyles,
} from '@fluentui/react';
import {
    selectCurrentTreatmentPlan,
    selectCurrentTreatmentPlanPhases,
    selectCurrentTreatmentPlanSignedOrSigning,
} from 'state/slices/charting/treatmentPlans/treatmentPlans.selectors';
import { getTeethDisplayName, getShorthandToothAreas, usdCurrencyFormatter } from 'utils';
import { ToothArea } from 'api/models/tooth-area';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import ConfirmationModal from '../../../components/ConfirmationModal';
import { ChartProcedureStatus } from 'api/models/chart.model';
import { updateProcedureStatuses } from 'state/slices/charting/procedures/procedures.actions';
import { RouteParams } from 'interfaces/route-params';
import { useParams } from 'react-router';
import { IChartTreatmentPlanPhaseProcedure } from 'api/models/treatment-plan.model';
import { createSelector } from 'reselect';
import { RootState } from 'state/store';
import { onMoveProceduresToPhase, onSetPhaseProceduresStatus } from 'state/slices/charting/treatmentPlans/treatmentPlans.actions';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import { selectActivePrimaryPatientInsurance, selectSelectedPatient } from 'state/slices/patient/patient.selectors';
import IPatient, { IPatientInsurance } from 'api/models/patient.model';
import { classicDateOnly } from 'utils/dateOnly';
import { chartActionDateLookup } from 'state/slices/charting/chart/chart.selectors';
import { isValid } from 'date-fns';

type BillingProcedureTableProps = {
    procedures: IBillingProcedure[] | IChartTreatmentPlanPhaseProcedure[];
    phaseId?: string;
    showTotalsRow?: boolean;
};

type BillingProcedureSelection = IBillingProcedure & IObjectWithKey;

/**
 * General billing procedure table component that handles both phased procedures and pending procedures.
 *
 * @export
 * @param {BillingProcedureTableProps} { phaseId, procedures }
 * @return {*}  {JSX.Element}
 */
export default function EditBillingProcedureTable({
    phaseId,
    procedures,
    showTotalsRow,
}: BillingProcedureTableProps): JSX.Element {
    const theme = useTheme();
    const [selectedProcedures, setSelectedProcedures] = useState<BillingProcedureSelection[]>([]);
    const isSigned = useSelector(selectCurrentTreatmentPlanSignedOrSigning);
    const currentPlan = useSelector(selectCurrentTreatmentPlan);
    const patient = useSelector(selectSelectedPatient);
    const patientInsurance = useSelector(selectActivePrimaryPatientInsurance);

    const procedureSelection = useMemo(() => {
        return new Selection({
            onSelectionChanged: () => {
                const procedures = getProceduresSelectionDetails();
                setSelectedProcedures(procedures);
            },
            items: selectedProcedures,
            getKey: (item) => item.id,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getProceduresSelectionDetails = () => {
        return procedureSelection.getSelection();
    };

    return (
        <Stack style={{ backgroundColor: theme.semanticColors.bodyBackground }}>
            <BillingProcedureTableCommandBar selectedProcedures={selectedProcedures} phaseId={phaseId} />
            <BillingProcedureDetailsList
                selectionMode={isSigned || currentPlan?.isDeleted ? SelectionMode.none : undefined}
                procedures={procedures}
                selection={procedureSelection}
                patient={patient}
                activePatientInsurance={patientInsurance}
                showTotalsRow={showTotalsRow}
            />
        </Stack>
    );
}

type BillingProcedureTableCommandBarProps = {
    selectedProcedures: IBillingProcedure[] | IChartTreatmentPlanPhaseProcedure[];
    phaseId?: string;
};

const makeSelectOtherPhases = () =>
    createSelector(
        selectCurrentTreatmentPlanPhases,
        (_: RootState, phaseId: string | undefined) => phaseId,
        (phases, phaseId) => {
            // if !phaseId = [all phases] else [pending, ...filteredPhases]
            return phases.filter((phases) => phases.id !== phaseId);
        },
    );

/**
 * General command bar that handles both pending procedures and procedures in phases logic.
 *
 * @param {BillingProcedureTableCommandBarProps} {
 *     selectedProcedures,
 *     phaseId,
 * }
 * @return {*}  {(JSX.Element | null)}
 */
function BillingProcedureTableCommandBar({
    selectedProcedures,
    phaseId,
}: BillingProcedureTableCommandBarProps): JSX.Element | null {
    const { tenantId, patientId } = useParams<RouteParams>();
    const dispatch = useDispatch();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const phases = useSelector(selectCurrentTreatmentPlanPhases);
    const isSigned = useSelector(selectCurrentTreatmentPlanSignedOrSigning);

    const selectOtherPhases = useMemo(makeSelectOtherPhases, []);
    const otherPhases = useSelector((state) => selectOtherPhases(state, phaseId));
    const currentPlan = useSelector(selectCurrentTreatmentPlan);

    //Get encounter id from current TP.
    const encounterId = currentPlan?.encounterId;

    if (isSigned || currentPlan?.isDeleted) return null;

    function onDelete() {
        setIsDeleteModalOpen(true);
    }

    function onDismissDeleteModal() {
        setIsDeleteModalOpen(false);
    }

    function onContinueDelete() {
        // dispatch(deleteActions(selectedProcedures));
        setIsDeleteModalOpen(false);
    }

    const patientActionsCommandBarDisabled = selectedProcedures.length === 0 || currentPlan?.isDeleted;

    function _updateProcedureStatuses(status: ChartProcedureStatus) {
        if (encounterId) {
            dispatch(updateProcedureStatuses(tenantId, patientId, selectedProcedures, status, encounterId, false));
            dispatch(
                onSetPhaseProceduresStatus({
                    procedures: selectedProcedures,
                    status,
                    patientId,
                    tenantId,
                    encounterId,
                }),
            );
        }
    }

    const statusMenuItems: IContextualMenuItem[] = Object.keys(ChartProcedureStatus)
        .filter((status) => status !== 'Completed')
        .map((status) => ({
            key: status,
            text: status,
            onClick: () => {
                _updateProcedureStatuses(status as ChartProcedureStatus);
            },
        }));

    const subMenuItems: IContextualMenuItem[] = (phaseId ? otherPhases : phases ? phases : []).map((phase) => ({
        key: phase.id,
        name: phase.displayName,
        onClick: () => {
            if (encounterId)
                dispatch(
                    onMoveProceduresToPhase({
                        procedures: selectedProcedures,
                        phaseId: phase.id,
                        tenantId,
                        patientId,
                        encounterId,
                    }),
                );
        },
    }));
    if (phaseId)
        subMenuItems.push({
            key: 'pending',
            name: 'Pending',
            onClick: () => {
                if (encounterId)
                    dispatch(
                        onMoveProceduresToPhase({
                            procedures: selectedProcedures,
                            phaseId: 'pending',
                            tenantId,
                            patientId,
                            encounterId,
                        }),
                    );
            },
        });

    const _createPhase: ICommandBarItemProps = {
        key: 'create-phase',
        text: selectedProcedures.length ? 'New phase with selection' : 'New phase',
        iconProps: { iconName: 'Add' },
        onClick: () => {
            if (encounterId)
                dispatch(onMoveProceduresToPhase({ procedures: selectedProcedures, tenantId, patientId, encounterId }));
        },
        disabled: currentPlan?.isDeleted,
    };
    const _moveToPhase: ICommandBarItemProps = {
        key: 'moveTo',
        text: 'Move To',
        iconProps: { iconName: 'MoveToFolder' },
        subMenuProps: {
            items: subMenuItems,
        },
        tooltipHostProps: {
            content: 'Move to Treatment Plan',
        },
        disabled: patientActionsCommandBarDisabled || !subMenuItems.length,
    };
    const _deleteProcedures: ICommandBarItemProps = {
        key: 'delete',
        text: 'Delete',
        iconProps: { iconName: 'Delete' },
        tooltipHostProps: {
            content: 'Delete Treatment(s)',
        },
        onClick: onDelete,
        disabled: patientActionsCommandBarDisabled,
    };
    const _setProcedureStatus: ICommandBarItemProps = {
        key: 'setStatus',
        text: 'Set Status',
        iconProps: { iconName: 'BulletedList2' },
        subMenuProps: {
            items: statusMenuItems,
        },
        tooltipHostProps: {
            content: 'Update procedure status(es)',
        },
        disabled: patientActionsCommandBarDisabled,
    };
    const patientActionsCommandBarItems: ICommandBarItemProps[] = phaseId
        ? [_moveToPhase, _setProcedureStatus]
        : [_createPhase, _moveToPhase, _deleteProcedures];

    return (
        <>
            <ConfirmationModal
                {...{
                    isOpen: isDeleteModalOpen,
                    onContinue: onContinueDelete,
                    onDismiss: onDismissDeleteModal,
                    title: 'Delete Procedure(s)',
                    confirmationMessage: 'Are you sure you want to continue and delete the selected procedure(s)?',
                }}
            />
            <CommandBar styles={{ root: { padding: 0 } }} items={patientActionsCommandBarItems} />
        </>
    );
}

type BillingProcedureDetailsList = {
    procedures: IBillingProcedure[] | IChartTreatmentPlanPhaseProcedure[];
    selectionMode?: SelectionMode;
    selection?: Selection<IObjectWithKey>;
    hiddenColumns?: string[];
    overrideColumns?: ISortableColumn<IBillingProcedure>[];
    dateFieldName?: 'onCompletedDate' | 'createdOn';
    patient?: IPatient | undefined;
    showTotalsRow?: boolean;
    activePatientInsurance?: IPatientInsurance | undefined;
};
export function BillingProcedureDetailsList({
    procedures,
    selection,
    overrideColumns,
    selectionMode = SelectionMode.multiple,
    hiddenColumns,
    patient, // !Do NOT use patient selector selectSelectedPatient! We need to use this component in places where the patient may not be the selected one.
    activePatientInsurance,
    showTotalsRow,
}: BillingProcedureDetailsList): JSX.Element {
    const { palette } = useTheme();
    const _chartActionDateLookup = useSelector(chartActionDateLookup);

    const _getChartActionDate = (item?: IBillingProcedure | IChartTreatmentPlanPhaseProcedure): string => {
        if (item) return _chartActionDateLookup[item.id] ? (_chartActionDateLookup[item.id] as string) : (item.createdOn ?? '');
        return '';
    };

    const _renderDate = (item?: IBillingProcedure | IChartTreatmentPlanPhaseProcedure) => {
        if (item) {
            const dateToUse = _getChartActionDate(item);
            return <Text variant="smallPlus">{dateToUse ? classicDateOnly(dateToUse, 'MM/dd/yyyy') : dateToUse}</Text>; // NOTE Stop using date-fns format for dates.
        }
        return '';
    };

    const nonSlideColumns = (
        [
            {
                key: 'date',
                minWidth: 100,
                maxWidth: 130,
                name: 'Date',
                fieldName: 'onCompletedDate',
                onRender: _renderDate,
            },
            {
                key: 'tooth',
                minWidth: 50,
                maxWidth: 50,
                name: 'Tooth',
                getValueKey: (item) => getTeethDisplayName(item?.toothIds),
                onRender: (item) => <Text variant="smallPlus">{getTeethDisplayName(item?.toothIds)}</Text>,
            },
            {
                key: 'code',
                minWidth: 65,
                maxWidth: 65,
                name: 'Code',
                fieldName: 'procedureCode',
                onRender: (item) => {
                    return (
                        <TooltipHost directionalHint={DirectionalHint.leftCenter} content={item?.procedureDescription}>
                            <span>{item?.procedureCode}</span>
                        </TooltipHost>
                    );
                },
            },
            {
                key: 'description',
                minWidth: 100,
                name: 'Description',
                fieldName: 'procedureDescription',
                onRender: (item) => (
                    <Text title={item?.procedureDescription ?? ''} variant="smallPlus">
                        {item?.procedureDescription ?? ''}
                    </Text>
                ),
                isResizable: true,
            },
            {
                key: 'stage',
                minWidth: 50,
                name: 'Stage',
                fieldName: 'procedureStage',
                onRender: (item) => (
                    <Text title={item?.stage ?? ''} variant="smallPlus">
                        {item?.stage ?? ''}
                    </Text>
                ),
                isResizable: true,
            },
            {
                key: 'area',
                minWidth: 100,
                name: 'Area',
                getValueKey: (item) => (item?.areas ? getShorthandToothAreas(item.areas as (keyof typeof ToothArea)[]) : ''),
                onRender: (item) => (
                    <Text variant="smallPlus">
                        {item?.areas ? getShorthandToothAreas(item.areas as (keyof typeof ToothArea)[]) : ''}
                    </Text>
                ),
            },
            {
                key: 'fee',
                minWidth: 100,
                name: 'Fee',
                fieldName: 'ucrFee',
                onRender: (item) => {
                    return (
                        <Stack grow>
                            <Text variant="smallPlus">{usdCurrencyFormatter.format(item?.ucrFee ?? 0)}</Text>
                        </Stack>
                    );
                },
            },
            {
                key: 'insuranceEst',
                minWidth: 100,
                name: 'Insurance Est.',
                fieldName: 'insuranceEstimate',
                onRender: (item) => {
                    return (
                        <Stack grow>
                            <Text variant="smallPlus">{usdCurrencyFormatter.format(item?.insuranceEstimate ?? 0)}</Text>
                        </Stack>
                    );
                },
            },

            {
                key: 'patient-est',
                minWidth: 100,
                name: 'Patient Est.',
                fieldName: 'commonPatientFee',
                onRender: (item) => {
                    return (
                        <Stack grow>
                            <Text variant="smallPlus">{usdCurrencyFormatter.format(item?.commonPatientFee ?? 0)}</Text>
                        </Stack>
                    );
                },
            },
            {
                key: 'status',
                minWidth: 100,
                name: 'Status',
                fieldName: 'status',
            },
            {
                key: 'type',
                minWidth: 100,
                name: 'Type',
                fieldName: 'type',
            },
        ] as ISortableColumn<IBillingProcedure>[]
    ).filter((column) => (column.fieldName && hiddenColumns ? !hiddenColumns.includes(column.fieldName) : true));

    const slideColumns = (
        [
            {
                key: 'date',
                minWidth: 100,
                maxWidth: 130,
                name: 'Date',
                fieldName: 'onCompletedDate',
                onRender: _renderDate,
            },
            {
                key: 'tooth',
                minWidth: 50,
                maxWidth: 50,
                name: 'Tooth',
                getValueKey: (item) => getTeethDisplayName(item?.toothIds),
                onRender: (item) => <Text variant="smallPlus">{getTeethDisplayName(item?.toothIds)}</Text>,
            },
            {
                key: 'code',
                minWidth: 65,
                maxWidth: 65,
                name: 'Code',
                fieldName: 'procedureCode',
            },
            {
                key: 'description',
                minWidth: 100,
                name: 'Description',
                fieldName: 'procedureDescription',
                onRender: (item) => (
                    <Text title={item?.procedureDescription ?? ''} variant="smallPlus">
                        {item?.procedureDescription ?? ''}
                    </Text>
                ),
                isResizable: true,
            },
            {
                key: 'stage',
                minWidth: 50,
                name: 'Stage',
                fieldName: 'procedureStage',
                onRender: (item) => (
                    <Text title={item?.stage ?? ''} variant="smallPlus">
                        {item?.stage ?? ''}
                    </Text>
                ),
                isResizable: true,
            },
            {
                key: 'area',
                minWidth: 100,
                name: 'Area',
                getValueKey: (item) => (item?.areas ? getShorthandToothAreas(item.areas as (keyof typeof ToothArea)[]) : ''),
                onRender: (item) => (
                    <Text variant="smallPlus">
                        {item?.areas ? getShorthandToothAreas(item.areas as (keyof typeof ToothArea)[]) : ''}
                    </Text>
                ),
            },
            {
                key: 'fee',
                minWidth: 100,
                name: 'Fee',
                fieldName: 'ucrFee',
                onRender: (item) => {
                    return <Text variant="smallPlus">{usdCurrencyFormatter.format(item?.ucrFee ?? 0)}</Text>;
                },
            },
            {
                key: 'additionalOrLabFee',
                minWidth: 100,
                name: 'Lab/Material Fee.',
                fieldName: 'additionalOrLabFee',
                onRender: (item) => {
                    return <Text variant="smallPlus">{usdCurrencyFormatter.format(item?.additionalOrLabFee ?? 0)}</Text>;
                },
            },
            {
                key: 'patient-est',
                minWidth: 100,
                name: 'Patient Est.',
                fieldName: 'patientFee',
                onRender: (item) => {
                    return <Text variant="smallPlus">{usdCurrencyFormatter.format(item?.commonPatientFee ?? 0)}</Text>;
                },
            },
            {
                key: 'status',
                minWidth: 100,
                name: 'Status',
                fieldName: 'status',
            },
            {
                key: 'type',
                minWidth: 100,
                name: 'Type',
                fieldName: 'type',
            },
        ] as ISortableColumn<IBillingProcedure>[]
    ).filter((column) => (column.fieldName && hiddenColumns ? !hiddenColumns.includes(column.fieldName) : true));

    const getTotalOfBillingProcedureProp = (
        prop: keyof Pick<IBillingProcedure, 'ucrFee' | 'additionalOrLabFee' | 'commonPatientFee' | 'insuranceEstimate'>,
    ): string => {
        return usdCurrencyFormatter.format(procedures.map((p) => p[prop]).reduce((a, b) => a + b, 0));
    };

    const _renderDetailsFooterItemColumn: IDetailsRowBaseProps['onRenderItemColumn'] = useCallback(
        (item, index, column) => {
            if (column) {
                if (column.key === 'date') {
                    return (
                        <div>
                            <b>Totals:</b>
                        </div>
                    );
                }

                const columnTotals: Record<string, string> = {
                    fee: getTotalOfBillingProcedureProp('ucrFee'),
                    additionalOrLabFee: getTotalOfBillingProcedureProp('additionalOrLabFee'),
                    insuranceEst: getTotalOfBillingProcedureProp('insuranceEstimate'),
                    'patient-est': getTotalOfBillingProcedureProp('commonPatientFee'),
                };

                if (
                    column.key === 'fee' ||
                    column.key === 'additionalOrLabFee' ||
                    column.key === 'insuranceEst' ||
                    column.key === 'patient-est' ||
                    column.key === 'commonPatientFee'
                ) {
                    return (
                        <div>
                            <b>{columnTotals[column.key]}</b>
                        </div>
                    );
                }
            }

            return undefined;
        },
        [procedures],
    );

    const columns = useMemo(() => {
        const cols = activePatientInsurance || !patient?.slidingFees?.length ? nonSlideColumns : slideColumns;

        if (overrideColumns) {
            //Replace column with override column if it exists
            overrideColumns.forEach((overrideColumn) => {
                const indexOfColumn = cols.findIndex((col) => col.key === overrideColumn.key);
                if (indexOfColumn > -1) {
                    cols[indexOfColumn] = overrideColumn;
                }
            });
        }

        return cols;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activePatientInsurance, patient?.slidingFees, overrideColumns]);

    return (
        <SortableDetailsList<IBillingProcedure>
            items={procedures} // Can't filter treatment/referred only here, do this in selectors...
            selectionMode={selectionMode}
            selection={selection}
            compact
            sortOnMount={true}
            sortColumns={['datePerformed']}
            columns={columns}
            onRenderDetailsFooter={
                showTotalsRow
                    ? (props) => {
                        return (
                            <DetailsRow
                                {...props}
                                columns={props?.columns.map((col) => ({ ...col, onRender: undefined }))}
                                onRenderCheck={_onRenderCheckForFooterRow}
                                onRenderItemColumn={_renderDetailsFooterItemColumn}
                                item={{}}
                                itemIndex={procedures.length}
                                styles={{
                                    root: {
                                        background: palette.neutralQuaternaryAlt,
                                        borderTop: `1px solid ${palette.neutralLighterAlt}`,
                                    },
                                }}
                            />
                        );
                    }
                    : undefined
            }
        />
    );
}

const detailsRowCheckStyles: Partial<IDetailsRowCheckStyles> = { root: { visibility: 'hidden' } };

const _onRenderCheckForFooterRow: IDetailsRowBaseProps['onRenderCheck'] = (props): JSX.Element => {
    return <DetailsRowCheck {...props} styles={detailsRowCheckStyles} selected={true} />;
};
