import { RootState } from 'state/store';
import CompletedProcedures from './templates/CompletedProcedures';
import { renderToStaticMarkup } from 'react-dom/server';
import TreatmentPlannedProcedures from './templates/TreatmentPlannedProcedures';
import ReferredProcedures from './templates/ReferredProcedures';
import CariesRisk from './templates/CariesRisk';
import SoftTissueExam from './templates/SoftTissueExam';
import AdditionalComments from './templates/AdditionalComments';
import NextAppointment from './templates/NextAppointment';
import Hygienist from './templates/Hygienist';
import RDA from './templates/RDA';
import ProcedureNoteTemplates from './templates/ProcedureNoteTemplates';
import DDS from './templates/DDS';
import Problems from './templates/Problems';
import Medications from './templates/Medications';
import Allergies from './templates/Allergies';
import Rx from './templates/Rx';
import { map } from 'lodash';
import Vitals from './templates/Vitals';
import { NoteParser } from './quick-notes.actions';
import DentalHistory from './templates/DentalHistory';
import EncounterReasons from './templates/EncounterReasons';

/**
 *
 *
 * @param {NoteParser} parser
 * @param {RootState} state
 * @description Returns a dynamically created react component, based on the NoteParser, rendered as a string.
 * @return {*}
 */
const getQuickNoteFragment = (
    state: RootState,
    parser?: NoteParser,
    tenantId?: string,
): string => {
    const lookup: {
        [key in keyof typeof NoteParser]: (
            { state }: { state: RootState; tenantId?: string;  },
            { identity }: { identity: any },
        ) => JSX.Element | null;
    } = {
        [NoteParser.EncounterReasons]: EncounterReasons,
        [NoteParser.Problems]: Problems,
        [NoteParser.Allergies]: Allergies,
        [NoteParser.Medications]: Medications,
        [NoteParser.DentalHistory]: DentalHistory,
        [NoteParser.CariesRisk]: CariesRisk,
        [NoteParser.Vitals]: Vitals,
        [NoteParser.SoftTissueExam]: SoftTissueExam,
        [NoteParser.TreatmentPlannedProcedures]: TreatmentPlannedProcedures,
        [NoteParser.CompletedProcedures]: CompletedProcedures,
        [NoteParser.ProcedureNoteTemplates]: ProcedureNoteTemplates,
        [NoteParser.ReferredProcedures]: ReferredProcedures,
        [NoteParser.AdditionalComments]: AdditionalComments,
        [NoteParser.Rx]: Rx,
        [NoteParser.NextAppointment]: NextAppointment,
        [NoteParser.RDA]: RDA,
        [NoteParser.Hygienist]: Hygienist,
        [NoteParser.DDS]: DDS,
    };

    if (parser) {
        const Component = lookup[parser];
        return renderToStaticMarkup(<Component state={state} tenantId={tenantId} />);
    } else {
        const noteStrings = map(lookup, (Component) =>
            renderToStaticMarkup(<Component state={state} tenantId={tenantId} />),
        );
        return noteStrings.join('');
    }
};

export default getQuickNoteFragment;
