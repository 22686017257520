import { DefaultButton, MessageBar, SelectionMode, Shimmer, Stack, Text, useTheme } from '@fluentui/react';
import { SortableDetailsList } from 'components';
import { useSelector } from 'hooks';
import { RouteParams } from 'interfaces/route-params';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { setPaymentSourceIdToConvertToUAC } from 'state/slices/ledger/ledger.slice';
import { getProcedureCreditTransactions } from 'state/slices/ledger/treatment-plan-credit-and-uac/treatment-plan-credit-and-uac.actions';
import {
    selectAppliedCreditBalanceViews,
    selectTreatmentPlanCrediAppliedCreditBalanceTotals,
    selectselectAppliedCreditBalanceTransactionsLoading,
} from 'state/slices/ledger/treatment-plan-credit-and-uac/treatment-plan-credit-and-uac.selectors';
import { usdCurrencyFormatter } from 'utils';
import { classicDateOnly } from 'utils/dateOnly';
import ConvertCreditUACConfirmation from '../UACDistribution/ConvertCreditUACConfirmation';
import { LoadingStatus } from 'interfaces/loading-statuses';
import IProcedureCreditTransaction from 'api/models/procedure-credit-transaction';
import { useAppDispatch } from 'hooks/useAppDispatch';

export default function Overages() {
    const dispatch = useAppDispatch();
    const { palette } = useTheme();
    const { patientId, tenantId } = useParams<RouteParams>();

    const loading = useSelector(selectselectAppliedCreditBalanceTransactionsLoading);
    const views = useSelector(selectAppliedCreditBalanceViews);

    const overageTotals = useSelector(selectTreatmentPlanCrediAppliedCreditBalanceTotals);

    useEffect(() => {
        const _getProcedureCreditTransactions = dispatch(getProcedureCreditTransactions({ patientId, tenantId }));

        return () => {
            _getProcedureCreditTransactions.abort('CancelGetProcedureCreditTransactionsPageUnloaded');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Stack grow>
            <ConvertCreditUACConfirmation />
            <Stack
                styles={{
                    root: { minHeight: 44, backgroundColor: palette.neutralLighterAlt, paddingLeft: 10, paddingRight: 10 },
                }}
                verticalAlign="center"
                horizontal
                horizontalAlign="space-between"
            >
                <Text variant="mediumPlus">Applied Credit Balance (Overages)</Text>
                {loading === LoadingStatus.Pending ? (
                    <Shimmer width={130} />
                ) : (
                    <Text variant="mediumPlus">Total Overages: {usdCurrencyFormatter.format(overageTotals)}</Text>
                )}
            </Stack>
            <Stack styles={{ root: { overflowY: 'auto', maxHeight: 600 } }}>
                {loading === LoadingStatus.Completed && !views.length ? (
                    <MessageBar>No overages exist.</MessageBar>
                ) : (
                    <SortableDetailsList<IProcedureCreditTransaction>
                        columns={[
                            {
                                key: 'convertActionButton',
                                name: '',
                                minWidth: 100,
                                maxWidth: 120,
                                onRender: (item) => (
                                    <DefaultButton
                                        text="Convert"
                                        onClick={() => {
                                            if (item) dispatch(setPaymentSourceIdToConvertToUAC(item.id));
                                        }}
                                        styles={{ root: { padding: 0, paddingLeft: 5, paddingRight: 5, height: 24 } }}
                                    />
                                ),
                            },

                            {
                                key: 'dateOfService',
                                name: 'Date of Service',
                                minWidth: 100,
                                maxWidth: 120,
                                fieldName: 'dateOfService',
                                onRender: (item) => <span>{item?.dateOfService ? classicDateOnly(item.dateOfService) : ''}</span>,
                            },
                            {
                                key: 'procedureCode',
                                name: 'Code',
                                minWidth: 65,
                                maxWidth: 120,
                                fieldName: 'procedureCode',
                                onRender: (item) => <span>{item?.procedureCode ?? ''}</span>,
                            },
                            {
                                key: 'paymentDate',
                                name: 'Payment Date',
                                minWidth: 100,
                                maxWidth: 120,
                                fieldName: 'paymentDate',
                                onRender: (item) => <span>{item?.dateOfEntry ? classicDateOnly(item.dateOfEntry) : ''}</span>,
                            },
                            {
                                key: 'amount',
                                name: 'Amount',
                                minWidth: 100,
                                maxWidth: 120,
                                fieldName: 'creditAmount',
                                onRender: (item) => <span>{usdCurrencyFormatter.format(item?.creditAmount ?? 0)}</span>,
                            },
                            {
                                key: 'method',
                                name: 'Method',
                                minWidth: 100,
                                maxWidth: 120,
                                fieldName: 'method',
                            },
                            {
                                key: 'identifier',
                                name: 'Identifier',
                                minWidth: 100,
                                maxWidth: 120,
                                fieldName: 'paymentIdentifier',
                            },
                            {
                                key: 'note',
                                name: 'Note',
                                minWidth: 100,
                                fieldName: 'note',
                                onRender: (item) => <span title={item?.note ?? ''}>{item?.note ?? ''}</span>,
                            },
                        ]}
                        compact
                        showGrid
                        enableShimmer={loading === LoadingStatus.Pending}
                        selectionMode={SelectionMode.none}
                        items={views}
                    />
                )}
            </Stack>
        </Stack>
    );
}
