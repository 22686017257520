import { IChartProcedure } from 'api/models/chart.model';
import { ConflictRuleType } from 'api/models/lookup.model';
import { IPipelineError, PipelineError } from '../pipeline';
import ProcedureConflictRulesPipeline from '../procedureConflictRules.pipeline';
import { createRule } from '../ruleGenerator';

const ProcedureTwoSurfacesOnly = createRule<ProcedureConflictRulesPipeline, IChartProcedure>({
    ruleTypes: [ConflictRuleType.twoSurfacesOnly],
    rule: (pipeline, item) => {
        const _errors: IPipelineError[] = [];

        if (item.areas?.length !== 2) {
            _errors.push({ type: PipelineError.TwoSurfacesOnly, data: pipeline.getProcedure(item) });
        }

        return { shouldRemoveItem: _errors.length ? true : false, errors: _errors };
    },
});

export default ProcedureTwoSurfacesOnly;
